import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchAccountingTransactions,
  fetchFailedToSync,
  resetAccountingTransactionsList,
  updateTagAndVendor,
} from "@/store/reducers/accounting_transactions";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  accountingPayrollFilterSelector,
  accountingTransactionsHasMoreSelector,
  accountingTransactionsSelector,
  accountingTransactionsTotalSelector,
  availableToSyncSelector,
  failedToSyncSelector,
  fetchingAvailableToSyncSelector,
  fetchingFailedToSyncSelector,
  isFetchingTransactionsSelector,
} from "@/store/selectors/accounting_transactions";
import {
  accountingIntegrationSoftwareSelector,
  isFetchingClientDetailsSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  accountingCategoryTagSelector,
  nonAccountingCategoryTagSelector,
  payrollCustomTagsSelector,
} from "@/store/selectors/tags";

import AccountingTransactionSearchFilter from "@/components/Accounting/Transactions/AccountingTransactionSearchFilter";
import TransactionsTable from "@/components/Accounting/Transactions/TransactionsTable";
import FailedSyncCard from "@/components/Accounting/Transactions/common/FailedSyncCard";
import { convertFilters } from "@/utils/filters";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_HEADER_IDS,
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_SOFTWARES_ICONS,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
  TAB_STATUS_MAP,
} from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import {
  accountingActionHandler,
  accountingBulkAction,
  formatAccountingStatusFilter,
  getSortingParams,
  stickyRightColumn,
} from "../../common/util";
import { formatDataForTable } from "./helper";

export default function PayrollPageHelper({ tab }) {
  const dispatch = useDispatch();

  // search params
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useState({
    type: null,
    category: SORTING_CATEGORY.AMOUNT,
  });
  // Transactions
  const transactions = useSelector(accountingTransactionsSelector);
  const isFetchingTransactions = useSelector(isFetchingTransactionsSelector);
  const hasMore = useSelector(accountingTransactionsHasMoreSelector);
  const totalTransactions = useSelector(accountingTransactionsTotalSelector);

  const isFetchingClient = useSelector(isFetchingClientDetailsSelector);

  // Available to Sync
  const { syncableCount, syncableIds } = useSelector(availableToSyncSelector);
  const fetchingAvailableToSync = useSelector(fetchingAvailableToSyncSelector);

  // Failed to sync
  const failedToSync = useSelector(failedToSyncSelector);
  const fetchingFailedToSync = useSelector(fetchingFailedToSyncSelector);

  // Accounting Software
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = accountingSoftware || false;

  // Filters
  const filters = useSelector(accountingPayrollFilterSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const requiredFilters = formatAccountingStatusFilter(
    filters,
    tab,
    accountingEnabled
  );

  // Tags
  const accountingCategoryTags = useSelector(accountingCategoryTagSelector);
  const nonAccountingTags = useSelector(nonAccountingCategoryTagSelector);
  const customTags = useSelector(payrollCustomTagsSelector);

  // Vendors
  const vendors = useSelector(accountingVendorsSelector);

  // Setting headers
  const headers = [
    {
      id: ACCOUNTING_HEADER_IDS.VENDOR,
      label: "accounting.transactions.cards.listingFields.employee.header",
      classes: "text-left",
      checkbox: true,
      colWidth: 320,
    },
    {
      id: ACCOUNTING_HEADER_IDS.AMOUNT,
      label: "accounting.transactions.cards.listingFields.amount.header",
      classes: "text-right justify-end",
      colWidth: 200,
      sortable: true,
    },
    {
      id: ACCOUNTING_HEADER_IDS.ACCOUNTING_TAG,
      label: accountingCategoryTags?.name,
      tagId: accountingCategoryTags?.id,
      classes: "text-left",
      options: accountingCategoryTags?.options,
      colWidth: 300,
      showColumn: accountingEnabled && (accountingCategoryTags ?? false),
    },

    {
      id: ACCOUNTING_HEADER_IDS.NOTE,
      label: "accounting.transactions.cards.listingFields.note.header",
      classes: "text-center flex-1",
      colWidth: 120,
    },
    {
      id: ACCOUNTING_HEADER_IDS.STATUS,
      label: "accounting.transactions.cards.listingFields.status.header",
      classes: "text-center flex-1",
      colWidth: 200,
    },
    vendors
      ? {
          id: ACCOUNTING_HEADER_IDS.VENDORS,
          label: `${ACCOUNTING_SOFTWARES_ICONS[accountingSoftware]} vendor`,
          vendorId: 1,
          classes: "text-left",
          options: vendors,
          colWidth: 300,
        }
      : [],
    ...(nonAccountingTags?.map((tag) => ({
      ...tag,
      actualId: tag?.id,
      id: ACCOUNTING_HEADER_IDS.NON_ACCOUNTING_TAG,
      label: tag.name,
      tagId: tag.id,
      classes: "text-left",
      options: tag.options,
      colWidth: 300,
      showColumn: accountingEnabled,
    })) ?? []),
    ...(customTags?.map((tag) => ({
      ...tag,
      actualId: tag?.id,
      id: ACCOUNTING_HEADER_IDS.CUSTOM_TAG,
      label: tag.name,
      tagId: tag.id,
      classes: "text-left",
      options: tag.options,
      colWidth: 300,
      showColumn: customTags?.length,
    })) ?? []),
    {
      id: ACCOUNTING_HEADER_IDS.ACTION,
      label: "accounting.transactions.cards.listingFields.action.header",
      classes: "text-center justify-center items-center flex-1",
      showColumn: tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED,
      colWidth: 145,
    },
    {
      id: ACCOUNTING_HEADER_IDS.OPEN_IN,
      label: "accounting.transactions.cards.listingFields.openIn",
      classes: "text-center justify-center",
      showColumn:
        tab === ACCOUNTING_TRANSACTION_STATUS.SYNCED &&
        ![
          ACCOUNTING_SOFTWARES.UNIVERSAL_CSV,
          ACCOUNTING_SOFTWARES.TALLY,
        ].includes(accountingSoftware),
      colWidth: 145,
    },
  ].filter((item) => item?.showColumn ?? true);

  // Selected transactions
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedTransactionObjects, setSelectedTransactionObjects] = useState(
    []
  );
  const [bulkApproveSelectAll, setBulkApproveSelectAll] = useState(false);

  // Infinite Scroll
  const onReset = () => {
    dispatch(resetAccountingTransactionsList());
  };

  const loadMore = (pageNumber = 1, onSuccess = () => {}) => {
    // we have 2 cases, connected to accounting and NOT connected to accounting software
    // Not connected: we need to ensure that we're calling the API on first load
    // Connected: only call the API if tab is passed to prevent duplicate calls when refreshing on pending and synced tabs
    if ((tab || !accountingEnabled) && !isFetchingClient) {
      dispatch(
        fetchAccountingTransactions({
          type: ACCOUNTING_TRANSACTION_PAGES.PAYROLLS,
          accounting_status: TAB_STATUS_MAP[tab],
          page: pageNum || pageNumber,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          ...getSortingParams(sorting),
          ...convertFilters(appliedFilters),
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (!isFetchingClient) {
      loadMore();
    }
  }, [isFetchingClient]);

  const fetchTransactions = ({ pageNumbers }) => {
    if (pageNumbers) pageNumbers.forEach((pageNumber) => loadMore(pageNumber));
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...getSortingParams(sorting),
      ...convertFilters(appliedFilters),
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.25,
  });

  // First load
  useEffect(() => {
    dispatch(
      fetchFailedToSync({ transaction: ACCOUNTING_TRANSACTION_PAGES.PAYROLL })
    );
    return () => {
      dispatch(resetAccountingTransactionsList());
    };
  }, []);

  // Converting transactions to data format
  const formattedTransactions = useMemo(() => {
    return transactions?.map((transaction) => formatDataForTable(transaction));
  }, [transactions]);

  const handleUpdateTagAndVendor = (payload) => {
    payload.transaction_type = ACCOUNTING_TRANSACTION_PAGES.PAYROLL;
    dispatch(updateTagAndVendor({ payload }));
  };

  const openRules = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.id, "true");
    setSearchParams(searchParams);
  };

  // handle slider open
  const handleSliderOpen = (id) => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.cards.id, id);
    setSearchParams(searchParams);
  };
  // Setting props for table
  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    numberOfStickyColsRight: stickyRightColumn(accountingSoftware, tab) ? 1 : 0,
    colWidths: headers.map((header) => header.colWidth),
    emptyDataIconSrc: "emptyScreen.svg",
  };

  const handleViewPayrollTransaction = (id) => {
    if (!searchParams.has(SLIDERS_SEARCH_PARAMS.accounting.payroll.id, id)) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.payroll.id, id);
      setSearchParams(searchParams);
    }
  };

  const handleFailedToSyncSliderOpen = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.failedToSync,
      ACCOUNTING_TRANSACTION_PAGES.PAYROLL
    );
    setSearchParams(searchParams);
  };
  return (
    <>
      {accountingEnabled ? (
        <div className="flex items-center gap-4 mt-6 mb-1">
          {accountingSoftware !== ACCOUNTING_SOFTWARES.UNIVERSAL_CSV ? (
            <FailedSyncCard
              count={failedToSync}
              isFetching={fetchingFailedToSync}
              handleClick={() => handleFailedToSyncSliderOpen()}
            />
          ) : null}
        </div>
      ) : null}
      <div className="mb-2">
        <AccountingTransactionSearchFilter
          filters={requiredFilters}
          showSyncAll={
            accountingEnabled && tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED
          }
          availableToSync={syncableCount}
          fetchingAvailableToSync={fetchingAvailableToSync}
          syncAll={accountingBulkAction}
          syncableIds={syncableIds}
          page={ACCOUNTING_TRANSACTION_PAGES.PAYROLL}
        />
      </div>

      {formattedTransactions && (
        <TransactionsTable
          pageNum={pageNum}
          tab={tab}
          headers={headers}
          hasMore={hasMore}
          tableArgs={tableArgs}
          originalTransactions={transactions}
          actionDisabled={!accountingSoftware}
          isFetching={isFetchingTransactions}
          transactions={formattedTransactions}
          totalTransactions={totalTransactions}
          dateToBeGrouped="transactionDate"
          page={ACCOUNTING_TRANSACTION_PAGES.PAYROLLS}
          selectedTransactions={selectedTransactions}
          selectedTransactionObjects={selectedTransactionObjects}
          handleRefChange={handleRefChange}
          handleBulkAction={accountingBulkAction}
          fetchTransactions={fetchTransactions}
          onActionClick={accountingActionHandler}
          onViewTransactionClick={handleViewPayrollTransaction}
          handleSliderOpen={handleSliderOpen}
          handleUpdateTagAndVendor={handleUpdateTagAndVendor}
          bulkApproveSelectAll={bulkApproveSelectAll}
          setSelectedTransactions={setSelectedTransactions}
          setSelectedTransactionObjects={setSelectedTransactionObjects}
          setBulkApproveSelectAll={setBulkApproveSelectAll}
          sorting={sorting}
          setSorting={setSorting}
        />
      )}
    </>
  );
}

PayrollPageHelper.propTypes = {
  tab: PropTypes.string,
};
