import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import InvoiceInboxPageHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/ListHelper";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";

export default function PaymentsInvoiceInbox({
  page = BILL_PAYROLL_CONTEXT.BILLPAY,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const inPayrollContext = page === BILL_PAYROLL_CONTEXT.PAYROLL;
  const openSliderHandler = (id, extraParams = {}) => {
    setSearchParams({
      [SLIDERS_SEARCH_PARAMS.payments.reviewBill]: id,
      [SLIDERS_SEARCH_PARAMS.payments.sliderType]: "invoice-inbox",
      [SLIDERS_SEARCH_PARAMS.payments.pageType]: "all", // there's just one tab btw
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]:
        SLIDERS_SEARCH_PARAMS.payments.reviewBill,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
      ...extraParams,
    });
  };

  return (
    <InvoiceInboxPageHelper page={page} openSliderHandler={openSliderHandler} />
  );
}

PaymentsInvoiceInbox.propTypes = {
  page: PropTypes.string,
};
