import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { availableModulesSelector } from "@/store/selectors/user";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  APPROVAL_POLICY_PAYLOADS,
  DEFAULT_APPROVAL_POLICY_CONFIG,
  DEFAULT_SUBMISSION_POLICY_CONFIG,
  POLICY_TYPE,
  SUBMISSION_POLICY_LIST,
} from "@/constants/policy";

export default function CustomPolicySlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const availableModules = useSelector(availableModulesSelector);

  const customPolicy = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.customPolicy
  );
  const isSubmission = customPolicy === POLICY_TYPE.SUBMISSION;

  const [sliderHeading, setSliderHeading] = useState({
    title: "settings.spendControls.customPolicy.title",
    description: "settings.spendControls.customPolicy.description",
  });

  const policyDetailConfig = isSubmission
    ? DEFAULT_SUBMISSION_POLICY_CONFIG
    : DEFAULT_APPROVAL_POLICY_CONFIG;

  const currentPolicy = isSubmission
    ? SUBMISSION_POLICY_LIST
    : APPROVAL_POLICY_PAYLOADS;

  useEffect(() => {
    let sliderHeader = sliderHeading;

    if (isSubmission) {
      sliderHeader = {
        title: "settings.spendControls.customSubmissionPolicy.title",
        description:
          "settings.spendControls.customSubmissionPolicy.description",
      };
    }
    setSliderHeading(sliderHeader);
  }, []);

  const handleSliderClick = (type) => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.settings[
        isSubmission ? "customSubmissionModule" : "customApprovalModule"
      ],
      type
    );
    setSearchParams(searchParams);
  };

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-7">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1 mb-8">
            <Text
              classes="text-2xl font-semibold"
              translationKey={sliderHeading.title}
            />

            <Text
              classes="text-sm text-neutral-500"
              translationKey={sliderHeading.description}
            />
          </div>
          {availableModules.map((item) =>
            policyDetailConfig[currentPolicy[item]] ? (
              <div
                key={item}
                className="relative flex items-center justify-between p-4 overflow-hidden card-wrapper hover"
                onClick={() =>
                  handleSliderClick(
                    policyDetailConfig[currentPolicy[item]]?.customSliderId
                  )
                }
              >
                <div className="flex items-center w-11/12 mr-3">
                  <div className="flex items-center justify-center p-3 mr-3 rounded-full bg-primary-50">
                    <Icon
                      name={policyDetailConfig[currentPolicy[item]]?.icon}
                      className="w-5 h-5 text-primary-500"
                    />
                  </div>
                  <div>
                    <div className="flex">
                      <Text
                        classes="text-neutral-800 font-semibold mr-4"
                        translationKey={
                          policyDetailConfig[currentPolicy[item]]?.title
                        }
                      />
                    </div>
                    <Text
                      classes="text-neutral-500 text-sm font-semibold"
                      translationKey={
                        policyDetailConfig[currentPolicy[item]]?.description
                      }
                    />
                  </div>
                </div>
                <div className="flex justify-end w-1/12">
                  <Icon name="ArrowForward" />
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
}
