import PropTypes from "prop-types";

import EmptyData from "@/components/core/EmptyData";
import Text from "@/components/core/Text";

export default function BudgetsTabEmptyState({
  hideButton = false,
  title = "company.department.departmentDetails.tabs.budgets.budgetEmptyTitle",
  desc = "company.department.departmentDetails.tabs.budgets.budgetEmptyDescription",
  handleClick = () => {},
}) {
  return (
    <div className="pt-12 text-neutral-800">
      <EmptyData
        buttonLabel="company.department.departmentDetails.tabs.budgets.defineNewBudgetLabel"
        title={title}
        buttonProps={{ classes: "w-[182px]" }}
        handleClick={handleClick}
        hideButton={hideButton}
      >
        <Text
          classes="font-medium text-neutral-500 text-sm"
          translationKey={desc}
        />
      </EmptyData>
    </div>
  );
}

BudgetsTabEmptyState.propTypes = {
  hideButton: PropTypes.bool,
  title: PropTypes.string,
  desc: PropTypes.string,
  handleClick: PropTypes.func,
};
