import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchAccountWallets } from "@/store/reducers/client";
import { fetchBillingDetails } from "@/store/reducers/company";
import {
  fetchQuickActions,
  fetchUsersPendingActions,
} from "@/store/reducers/user";

import {
  accountFreezeSelector,
  cashbackSelector,
  creditAccountSelector,
  creditEnabledSelector,
  currentPaymentProviderSelector,
  defaultCurrencySelector,
  entityNameSelector,
  isFetchingClientSelector,
  isFetchingWalletListSelector,
  paymentWalletListSelector,
  payrollAccountSelector,
} from "@/store/selectors/client";
import {
  isFetchingQuickActionsSelector,
  usersIsFetchingPendingActionsSelector,
  usersPendingActionsSelector,
} from "@/store/selectors/user";

import CompanyOverviewPageTitle from "@/components/Company/Overview/CompanyOverviewPageTitle";
import CompanyOverviewMyAccounts from "@/components/Company/Overview/MyAccounts";
import PendingActionsOverview from "@/components/Company/Overview/PendingActionsOverview";
import DashboardQuickAccessLinks from "@/components/Company/Overview/QuickAccessLinks";
import SpendAnalytics from "@/components/Company/Overview/SpendAnalytics";

import { PAYMENT_PROVIDERS } from "@/constants/provider";

import "./style.scss";

export default function DashboardPage() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const usersPendingActions = useSelector(usersPendingActionsSelector);

  const walletAccountsCompanyStore =
    useSelector(paymentWalletListSelector) ?? [];
  const isFetchingClient = useSelector(isFetchingClientSelector);

  const defaultCurrency = useSelector(defaultCurrencySelector);
  const isCreditEnabled = useSelector(creditEnabledSelector);
  const creditDetails = useSelector(creditAccountSelector);
  const cashback = useSelector(cashbackSelector);
  const companyName = useSelector(entityNameSelector);
  const payroll = useSelector(payrollAccountSelector);
  const accountFreezed = useSelector(accountFreezeSelector);
  const currentProvider = useSelector(currentPaymentProviderSelector);

  const showBanner = currentProvider === PAYMENT_PROVIDERS.WALLEX;
  const isFetchingAccountWallets = useSelector(isFetchingWalletListSelector);
  const isFetchingQuickActions = useSelector(isFetchingQuickActionsSelector);
  const isFetchingUsersPendingActions = useSelector(
    usersIsFetchingPendingActionsSelector
  );

  const isFetching =
    isFetchingAccountWallets ||
    isFetchingQuickActions ||
    isFetchingUsersPendingActions;

  useEffect(() => {
    dispatch(fetchAccountWallets());
    dispatch(fetchQuickActions());
    dispatch(fetchUsersPendingActions());
    if (accountFreezed) dispatch(fetchBillingDetails());
  }, []);

  return (
    <div className="company-overview-page-container">
      <div className="company-overview-main">
        <div className="company-overview-left">
          <CompanyOverviewPageTitle />

          {/* Kept this for future, if ever needed */}
          {/* {!isCreditEnabled && showBanner ? (
            <div className="flex items-center justify-between w-full gap-4 mt-4 font-semibold card-wrapper border-primary-50 bg-primary-50">
              <div className="flex flex-col">
                <Text
                  translationKey="company.credit.volopayCredit"
                  classes="ml-3 text-xl"
                />
                <Text
                  translationKey="company.credit.volopayCreditDesc"
                  classes="ml-3 text-sm text-neutral-500"
                />
                <div className="flex items-center mt-8">
                  <Button
                    variant="primary"
                    label="company.credit.requestCredit"
                    onClick={() => {
                      setSearchParams({
                        [SLIDERS_SEARCH_PARAMS.company.credit.creditLimit]:
                          FORM_TYPE.request,
                      });
                    }}
                    classes="w-20"
                  />
                  <Button
                    variant="tertiary"
                    label="common.learnMore"
                    classes="ml-4 w-15"
                  />
                </div>
              </div>
              <img src={VolopayCard} alt="" className="w-20" />
            </div>
          ) : null} */}
          <div className="mt-6">
            <CompanyOverviewMyAccounts
              isFetching={isFetching}
              defaultCurrency={defaultCurrency}
              companyName={companyName}
              isCreditEnabled={isCreditEnabled}
              walletAccounts={walletAccountsCompanyStore}
              creditDetails={creditDetails}
              cashbacks={cashback}
              payroll={payroll}
            />
          </div>
          <div
            className={`mt-10 relative ${
              accountFreezed ? "fixed bg-neutral-50 opacity-30 z-50" : ""
            }`}
          >
            {accountFreezed ? (
              <div className="fixed z-50 w-full h-full opacity-50" />
            ) : null}
            <SpendAnalytics />
          </div>
        </div>
        <div
          className={`company-overview-right relative ${
            accountFreezed ? "fixed bg-neutral-50 opacity-30 z-50" : ""
          }`}
        >
          {accountFreezed ? (
            <div className="fixed z-50 w-full h-full opacity-50" />
          ) : null}

          {/* TODO: Add media query to collapse this section for small windows */}
          <DashboardQuickAccessLinks companyPage />
          <PendingActionsOverview
            usersPendingActionsCounts={usersPendingActions}
          />
        </div>
      </div>
    </div>
  );
}
