import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { Picker } from "emoji-mart";

/**
 *
 * @param {function} onEmojiSelect - Function to handle emoji selection
 * Params supported by emoji-picker library
 *
 * @param {function} [onEmojiSelect=null] - Callback when an emoji is selected
 * @param {function} [onClickOutside=null] - Callback when a click outside of the picker happens
 * @param {function} [onAddCustomEmoji=null] - Callback when the Add custom emoji button is clicked. The button will only be displayed if this callback is provided. It is displayed when search returns no results.
 * @param {boolean} [autoFocus=false] - Whether the picker should automatically focus on the search input
 * @param {object} [categoryIcons={}] - Custom category icons
 * @param {boolean} [dynamicWidth=false] - Whether the picker should calculate perLine dynamically based on the width of <em-emoji-picker>. When enabled, perLine is ignored
 * @param {Array<string>} [emojiButtonColors=[]] - An array of color that affects the hover background color. i.e. #f00, pink, rgba(155,223,88,.7)
 * @param {string} [emojiButtonRadius="100%"] - The radius of the emoji buttons. i.e. 6px, 1em, 100%
 * @param {number} [emojiButtonSize=36] - The size of the emoji buttons
 * @param {number} [emojiSize=24] - The size of the emojis (inside the buttons)
 * @param {number|string} [emojiVersion=14] - The version of the emoji data to use. Latest version supported in @emoji-mart/data is currently 14. Accepted values: 1, 2, 3, 4, 5, 11, 12, 12.1, 13, 13.1, 14
 * @param {Array<string>} [exceptEmojis=[]] - List of emoji IDs that will be excluded from the picker
 * @param {string} [icons="auto"] - The type of icons to use for the picker. outline with light theme and solid with dark theme. Accepted values: auto, outline, solid
 * @param {string} [locale="en"] - The locale to use for the picker. Accepted values: en, ar, be, cs, de, es, fa, fi, fr, hi, it, ja, kr, nl, pl, pt, ru, sa, tr, uk, vi, zh
 * @param {number} [maxFrequentRows=4] - The maximum number of frequent rows to show. 0 will disable frequent category
 * @param {string} [navPosition="top"] - The position of the navigation bar. Accepted values: top, bottom, none
 * @param {boolean} [noCountryFlags=false] - Whether to show country flags or not. If not provided, this is handled automatically (Windows doesn’t support country flags)
 * @param {string} [noResultsEmoji="cry"] - The id of the emoji to use for the no results emoji
 * @param {number} [perLine=9] - The number of emojis to show per line
 * @param {string} [previewEmoji="point_up"] - The id of the emoji to use for the preview when not hovering any emoji. point_up when preview position is bottom and point_down when preview position is top.
 * @param {string} [previewPosition="bottom"] - The position of the preview. Accepted values: top, bottom, none
 * @param {string} [searchPosition="sticky"] - The position of the search input. Accepted values: sticky, static, none
 * @param {string} [set="native"] - The set of emojis to use for the picker. native being the most performant, others rely on spritesheets. Accepted values: native, apple, facebook, google, twitter
 * @param {number} [skin=1] - The emojis skin tone. Accepted values: 1, 2, 3, 4, 5, 6
 * @param {string} [skinTonePosition="preview"] - The position of the skin tone selector. Accepted values: preview, search, none
 * @param {string} [theme="auto"] - The color theme of the picker. Accepted values: auto, light, dark
 * @param {function} [getSpritesheetURL=null] - A function that returns the URL of the spritesheet to use for the picker. It should be compatible with the data provided.
 * @returns {JSX.Element}
 */
export default function EmojiPicker({ onEmojiSelect, ...props }) {
  const ref = useRef(null);
  const instance = useRef(null);

  if (instance.current) {
    instance.current.update({ ...props, onEmojiSelect });
  }

  useEffect(() => {
    instance.current = new Picker({ ...props, onEmojiSelect, ref });

    return () => {
      instance.current = null;
    };
  }, []);

  return React.createElement("div", { ref });
}

EmojiPicker.propTypes = {
  onEmojiSelect: PropTypes.func,
};
