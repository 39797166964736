import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { t } from "i18next";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import vToast from "@/utils/vToast";
import API from "@/api";

import { setIsFormSubmissionProgress } from "./loadersError";

const accountInitialState = {
  globalAccounts: [],
  selectedGlobalAccountCurrency: null,
  selectedGlobalAccountLocation: null,
  globalCountryCurrencies: [],
  currentGlobalAccount: {},
  globalAccountTransactions: [],
  isFetching: false,
  isFetchingAccounts: false,
  isFetchingTransactions: false,
  isRequestingCollection: false,
};

// Account Reducers
const accountSlice = createSlice({
  name: "account",
  initialState: accountInitialState,
  reducers: {
    setState(state, action) {
      state[action.payload.key] = action.payload.value;
    },
    setCurrentGlobalAccount(state, action) {
      state.currentGlobalAccount = action.payload;
    },
    setSelectedGlobalAccountCurrency(state, action) {
      state.selectedGlobalAccountCurrency = action.payload;
    },

    setSelectedGlobalAccountLocation(state, action) {
      state.selectedGlobalAccountLocation = action.payload;
    },
  },
});

// Account Actions

export const fetchGlobalAccounts = createAsyncThunk(
  "account/fetchGlobalAccounts",
  async (params, { dispatch }) => {
    dispatch(setState({ key: "isFetchingAccounts", value: true }));
    const [err, response] = await to(API.Account.globalAccounts());

    if (response) {
      dispatch(setState({ key: "globalAccounts", value: response.data }));
    }
    dispatch(setState({ key: "isFetchingAccounts", value: false }));
  }
);

export const requestCollection = createAsyncThunk(
  "account/requestCollection",
  async (payload, { dispatch }) => {
    dispatch(setState({ key: "isRequestingCollection", value: true }));
    const [err, response] = await to(API.Account.requestCollection());

    if (response) {
      vToast(getSuccessToastMessage(response?.data));
      if (payload?.onSuccess) payload?.onSuccess();
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setState({ key: "isRequestingCollection", value: false }));
  }
);

export const fetchGlobalCountryCurrencies = createAsyncThunk(
  "account/fetchGlobalCountryCurrencies",
  async (params, { dispatch }) => {
    dispatch(setState({ key: "isFetching", value: true }));
    const [err, response] = await to(API.Account.globalCountryCurrencies());

    if (response) {
      dispatch(
        setState({ key: "globalCountryCurrencies", value: response.data })
      );
    }
    dispatch(setState({ key: "isFetching", value: false }));
  }
);

export const createGlobalAccount = createAsyncThunk(
  "account/createGlobalAccount",
  async (payload, { dispatch }) => {
    dispatch(setState({ key: "isFetching", value: true }));
    dispatch(setIsFormSubmissionProgress(true));

    const [err, response] = await to(
      API.Account.createGlobalAccount(payload.params)
    );

    if (response) {
      vToast(
        getSuccessToastMessage({
          message: t("company.globalAccounts.createdSuccess"),
        })
      );
      dispatch(fetchGlobalAccounts());

      if (payload?.onSuccess) payload?.onSuccess();
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setState({ key: "isFetching", value: false }));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const fetchGlobalAccountTransactions = createAsyncThunk(
  "account/fetchGlobalAccountTransactions",
  async (params, { dispatch }) => {
    dispatch(setState({ key: "isFetchingTransactions", value: true }));
    const [err, response] = await to(
      API.Account.fetchGlobalAccountTransactions(params)
    );

    if (response) {
      dispatch(
        setState({
          key: "globalAccountTransactions",
          value: response.data,
        })
      );
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setState({ key: "isFetchingTransactions", value: false }));
  }
);

// Exporting Modules

export const {
  setState,
  setCurrentGlobalAccount,
  setSelectedGlobalAccountCurrency,
  setSelectedGlobalAccountLocation,
} = accountSlice.actions;

export default accountSlice.reducer;
