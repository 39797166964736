import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { dateToString } from "@/utils/common";

export default function BillPayRequestTypeCell({
  vendor,
  duplicateBillId,
  invoiceRequestDate,
  showDescriptiveSection,
}) {
  return (
    <div className="flex items-center gap-3 text-neutral-800">
      <ProfileWidget
        className="text-sm font-semibold "
        name={vendor?.name}
        img={vendor?.avatarUrl}
      >
        <div className="flex flex-col justify-center gap-1">
          {showDescriptiveSection ? (
            <div className="flex flex-row items-center gap-1">
              {invoiceRequestDate ? (
                <Text
                  translationKey={dateToString(invoiceRequestDate)}
                  classes="text-neutral-500 font-semibold"
                />
              ) : null}

              <div className="flex flex-row items-center gap-1">
                {invoiceRequestDate ? (
                  <span className="text-xs font-semibold profile-widget-dot text-neutral-500" />
                ) : null}

                <Text
                  translationKey="billPay.bill.common.billPayment"
                  classes="text-warning-500 font-semibold text-sm"
                />
              </div>
            </div>
          ) : null}

          {duplicateBillId ? (
            <div className="flex items-center gap-1 text-xs font-medium text-warning-600">
              <Icon name="Error" className="w-4 h-4 p-0.5" />
              <Text translationKey="billPay.bill.common.duplicateBillText" />
            </div>
          ) : null}
        </div>
      </ProfileWidget>
    </div>
  );
}

BillPayRequestTypeCell.propTypes = {
  vendor: PropTypes.object,
  duplicateBillId: PropTypes.bool,
  invoiceRequestDate: PropTypes.string,
  showDescriptiveSection: PropTypes.bool,
};
