import PropsTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { fetchPeopleInvited } from "@/store/reducers/company";

import { peopleInvitedCountSelector } from "@/store/selectors/company";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_TABS,
  INVITATION_STATUS,
  INVITE_OPTIONS,
  PENDING_INVITES_TAB_PARAMS,
} from "@/constants/company";
import {
  INITIAL_PAGE_NUM,
  PAGINATION_PER_REQUEST_LIMIT,
} from "@/constants/pagination";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

export default function UsersPage() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const total = useSelector(peopleInvitedCountSelector);

  useEffect(() => {
    if (!total)
      dispatch(
        fetchPeopleInvited({
          status: [PENDING_INVITES_TAB_PARAMS.PENDING],
          invitation_status: [INVITATION_STATUS.NOT_EXPIRED],
          page: INITIAL_PAGE_NUM,
          limit: PAGINATION_PER_REQUEST_LIMIT,
        })
      );
  }, [total]);

  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <HeaderText
        setSliderHandler={(slider_search_param) => {
          searchParams.append(slider_search_param, true);
          setSearchParams(searchParams);
        }}
        count={total}
      />

      <div className="mt-9">
        <Tabs
          items={COMPANY_PEOPLE_TABS}
          selectedTab={COMPANY_PEOPLE_TABS.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
          classes="!font-semibold"
        />
      </div>
    </div>
  );
}

function HeaderText({ setSliderHandler, count }) {
  const popupRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col">
        <Text
          translationKey="company.title"
          classes="text-sm font-semibold text-neutral-500"
        />
        <Text
          translationKey="company.people.title"
          classes="text-4xl font-extrabold text-neutral-800"
        />
      </div>
      <div className="flex gap-6">
        {checkIfUserActionAllowed(
          allNavUrls,
          userAllowedActions,
          USER_ALLOWED_CTA_KEYS.PENDING_INVITES
        ) ? (
          <Button
            variant="tertiary"
            label="company.people.buttonLabels.pending"
            classes="w-fit text-btn-lg font-semibold px-5 py-4"
            onClick={() =>
              setSliderHandler(
                SLIDERS_SEARCH_PARAMS.company.people.pendingInvite
              )
            }
            innerClasses="gap-2.5"
            counter={count || null}
            counterClasses="rounded-lg bg-primary-50 text-xs font-bold"
          />
        ) : null}
        {checkIfUserActionAllowed(
          allNavUrls,
          userAllowedActions,
          USER_ALLOWED_CTA_KEYS.INVITE_PEOPLE
        ) ? (
          <Popup
            ref={popupRef}
            trigger={
              <div>
                <Button
                  variant="primary"
                  label="company.people.buttonLabels.invite"
                  preIcon="Add"
                  classes="w-fit text-btn-lg font-semibold px-5 py-4"
                />
              </div>
            }
            closeOnDocumentClick
            position="bottom right"
            className="filter-popup"
          >
            <div className="flex flex-col w-full">
              {INVITE_OPTIONS.map((option) => (
                <div
                  key={`invite-options-${option?.title}`}
                  className={`flex gap-2  ml-0 py-5 text-left pl-3  active:bg-red pr-10 ${
                    !option.isUpcomingFeature && "hover:bg-neutral-100"
                  }  ${
                    option.isUpcomingFeature
                      ? "cursor-not-allowed pointer-events-none"
                      : "cursor-pointer"
                  }`}
                  onClick={() => {
                    searchParams.set(
                      SLIDERS_SEARCH_PARAMS.company.people.mainInvite,
                      true
                    );
                    setSearchParams(searchParams);
                    popupRef.current.close();
                  }}
                >
                  <div
                    className={
                      option.isUpcomingFeature
                        ? "text-neutral-500"
                        : "text-neutral-900"
                    }
                  >
                    <Text translationKey={option.title} />
                  </div>
                  {option.isUpcomingFeature && (
                    <Chip
                      label="common.comingSoon"
                      classes="text-primary-400 border border-primary-200"
                    />
                  )}
                </div>
              ))}
            </div>
          </Popup>
        ) : null}
      </div>
    </div>
  );
}

HeaderText.propTypes = {
  setSliderHandler: PropsTypes.func,
  count: PropsTypes.number,
};
