import Text from "@/components/core/Text";

import AnalyticsBillPayHelper from "@/components/Analytics/Transactions/Billpay";

import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";

export default function AnalyticsBillPay() {
  return (
    <div>
      <div className="flex flex-col mx-12 mb-3">
        <Text
          classes="text-neutral-500 text-sm font-semibold"
          translationKey="analytics.header"
        />
        <Text
          classes="text-neutral-800 text-4xl font-extrabold"
          translationKey="analytics.transactions.billPay.title"
        />
      </div>
      <AnalyticsBillPayHelper
        type={ACCOUNTING_TRANSACTION_PAGES.ANALYTICS_BILLPAY}
      />
    </div>
  );
}
