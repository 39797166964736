import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { capitalizeFirstLetter } from "@/utils/common";

/**
 * @param {Array} list list of all the entity
 * @param {String} title title of the wrapper  ex: list of custom roles:
 * @param {Function} handleDeleteIconClick function for deleting an entity from the list
 * @param {Boolean} isCustomRole for entity listing UI of custom roles is different then co-pilot
 */

function EntityListWithDelete({
  list = [],
  title,
  handleDeleteIconClick,
  isCustomRole,
}) {
  const showSection = list.length > 0;

  return showSection ? (
    <div className="flex flex-col gap-3 border-0 card-wrapper bg-neutral-50">
      <Text
        translationKey={title}
        classes="text-sm font-semibold text-neutral-500"
      />

      <div className="flex flex-col gap-3">
        {list.map((listElement, index) => {
          return (
            <div
              className="flex flex-row items-center justify-between"
              key={`${index}-${listElement?.id}`}
            >
              <Text
                translationKey={capitalizeFirstLetter(listElement.name)}
                noTranslate
                classes={
                  isCustomRole
                    ? "border text-sm rounded-full px-2 text-neutral-600 px-2 border-neutral-200"
                    : ""
                }
              />
              <div onClick={() => handleDeleteIconClick(listElement?.id)}>
                <Icon
                  name="Delete"
                  className="cursor-pointer text-neutral-400 hover:text-neutral-600"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}

EntityListWithDelete.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  handleDeleteIconClick: PropTypes.func,
  isCustomRole: PropTypes.bool,
};

export default EntityListWithDelete;
