import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchCountryCurrenciesForMail,
  fetchVendorCountryCurrencyMappings,
} from "@/store/reducers/vendors";

import {
  isFetchingCountriesForMailSelector,
  isFetchingVendorCountryCurrencyMappingsSelector,
  vendorCountryCurrencyMappingsSelector,
} from "@/store/selectors/vendors";

import VpSelect from "@/components/core/VpSelect";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { MAIL_CONTEXT } from "@/utils/constants/common";

import { VENDOR_CREATE_REQUIREMENTS_PARAMS } from "@/constants/vendors";

export default function BeneficiaryCurrency({
  name = VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY,
  values,
  errors,
  handleChange,
  relevantValues = [],
  setValues,
  isEditMode,
  provider,
  disabled,
  context,
}) {
  const dispatch = useDispatch();
  const isFetchingCountryCurrenciesForMail = useSelector(
    isFetchingCountriesForMailSelector
  );
  const countryCurrencyMappings =
    useSelector(vendorCountryCurrencyMappingsSelector) ?? {};

  const isFetchingCountryCurrencies = useSelector(
    isFetchingVendorCountryCurrencyMappingsSelector
  );
  const isFetching =
    isFetchingCountryCurrenciesForMail || isFetchingCountryCurrencies;

  const currencyOptions =
    relevantValues?.map((currCode) => ({ name: currCode, value: currCode })) ??
    [];

  useEffect(() => {
    if (relevantValues.length <= 1) {
      setValues?.((prev) => ({ ...prev, [name]: relevantValues.at(0) ?? "" }));
    }
  }, [values[name]]);

  useEffect(() => {
    if (!context) return;
    if (context === MAIL_CONTEXT) {
      dispatch(fetchCountryCurrenciesForMail(provider?.toLowerCase()));
    } else if (!Object.keys(countryCurrencyMappings).length)
      dispatch(
        fetchVendorCountryCurrencyMappings({
          context: BILL_PAYROLL_CONTEXT.BILLPAY,
        })
      );
  }, [provider, context]);

  return (
    <VpSelect
      name={name}
      value={values[name]}
      error={errors[name]}
      handleChange={handleChange}
      insideForm
      menuPosition="absolute"
      options={currencyOptions}
      optionsDisplayKey="name"
      valueKey="value"
      label="billPay.vendors.createVendor.beneficiaryCurrencyLabel"
      labelStyleClasses="text-neutral-600 text-xs font-semibold"
      placeholder="billPay.vendors.createVendor.beneficiaryCurrencyPlaceholder"
      helperText="billPay.vendors.createVendor.beneficiaryCurrencyHelperText"
      clearable
      isOptionsLoading={isFetching}
      disabled={!!isEditMode || disabled}
    />
  );
}

BeneficiaryCurrency.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  relevantValues: PropTypes.array,
  isEditMode: PropTypes.bool,
  context: PropTypes.string,
  disabled: PropTypes.bool,
  provider: PropTypes.string,
  setValues: PropTypes.func, // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough)
};
