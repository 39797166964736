import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

const ClaimPolicyHeader = ({ val }) => {
  return (
    <div className="flex items-center gap-3">
      <div className={`grow ${val.classes} text-base text-neutral-500`}>
        {val.title ? <Text translationKey={val.title} /> : "-"}
      </div>
    </div>
  );
};
ClaimPolicyHeader.propTypes = {
  val: PropTypes.shape({
    title: PropTypes.string,
    classes: PropTypes.string,
  }),
};

export default ClaimPolicyHeader;
