import PropsTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { isFetchingBillingTableDataSelector } from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Table from "@/components/core/Table";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import DueDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/DueDateCell";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";

import BillingBulkAction from "@/pages/billing/BillingBulkAction";
import BillingActionCell from "@/pages/billing/Cells/BillingActionCell";
import BillingAmountCell from "@/pages/billing/Cells/BillingAmountCell";
import BillingDateCell from "@/pages/billing/Cells/BillingDateCell";
import BillingInvoiceNumberCell from "@/pages/billing/Cells/BillingInvoiceNumberCell";
import BillingPaymentMethodCell from "@/pages/billing/Cells/BillingPaymentMethodCell";
import BillingPaymentStatuscell from "@/pages/billing/Cells/BillingPaymentStatuscell";
import { handleExport } from "@/utils/exports";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";
import { BILLING_TABLE_HEADING } from "@/utils/constants/company";
import { EXPORT_PAGE } from "@/constants/exports";

const COMPANY_BILLING_TABLE_HEADERS = {
  tableSetting: {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    numberOfStickyColsRight: 1,
    colWidths: [200, 200, 200, 150, 100, 300],
    rightColWidths: [145],
    styleId: "payments-table",
  },
  tableHeading: [
    {
      id: BILLING_TABLE_HEADING.INVOICE_NUMBER,
      title: "company.billing.invoicesTableHeaders.invoiceNumberHeading",
      classes: "text-left",
      cellComponent: BillingInvoiceNumberCell,
      haveSort: false,
    },
    {
      id: BILLING_TABLE_HEADING.AMOUNT,
      title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
      classes: "text-right justify-end",
      cellComponent: BillingAmountCell,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
      haveSort: true,
    },
    {
      id: BILLING_TABLE_HEADING.BILLING_DATE,
      title: "company.billing.invoicesTableHeaders.billingDate",
      classes: "text-center justify-center",
      cellComponent: BillingDateCell,
      haveSort: false,
    },
    {
      id: BILLING_TABLE_HEADING.PAYMENT_STATUS,
      title: "company.billing.invoicesTableHeaders.paymentStatus",
      classes: "text-center justify-center",
      cellComponent: BillingPaymentStatuscell,
      haveSort: false,
    },
    {
      id: BILLING_TABLE_HEADING.DUE_DATE,
      title: "company.billing.invoicesTableHeaders.dueDate",
      classes: "text-left",
      cellComponent: DueDateCell,
      haveSort: false,
    },
    {
      id: BILLING_TABLE_HEADING.PAYMENT_METHOD,
      title: "company.billing.invoicesTableHeaders.paymentMethod",
      classes: "text-center justify-center",
      cellComponent: BillingPaymentMethodCell,
      haveSort: false,
    },
    {
      id: BILLING_TABLE_HEADING.ACTION,
      title: "company.billing.invoicesTableHeaders.action",
      classes: "text-center justify-center",
      cellComponent: BillingActionCell,
      haveSort: false,
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
  tableDataRowClasses:
    "text-sm font-semibold text-center cursor-pointer text-neutral-800",
};

const BillingInvoiceTable = ({
  billingTableData,
  handleRowSelection,
  selectedInvoices,
  totalInvoicesSelected,
  deSelectedIds,
  setDeSelectedIds,
  headerSelectAll,
  bulkSelectAll,
  setBulkSelectAll,
  setSelectedInvoices,
  setHeaderSelectAll,
  headerSelectionHandler,
  sorting,
  setSorting,
  handleRefChange,
  hasMore,
}) => {
  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
  } = COMPANY_BILLING_TABLE_HEADERS;

  const dispatch = useDispatch();
  const appliedFilters = useSelector(appliedFilterSelector);
  const isFetching = useSelector(isFetchingBillingTableDataSelector);

  return (
    <div>
      <Table
        {...tableSetting}
        bulkApproveVisible={selectedInvoices?.length > 0}
        bulkApproveHeight="100px"
        bulkApproveContent={
          <BulkActionComponent
            selectedRows={
              deSelectedIds.length
                ? totalInvoicesSelected - deSelectedIds.length
                : selectedInvoices?.length
            }
            totalRows={totalInvoicesSelected}
            selectedRowsText="common.multipleInvoiceSelected"
            selectedSingleRowText="common.singleInvoiceSelected"
            descriptionText="common.exportDesc"
            showSelectAllButton={headerSelectAll}
            bulkApproveSelectAll={
              (bulkSelectAll && !deSelectedIds.length) ||
              selectedInvoices?.length === totalInvoicesSelected
            }
            selectAllHandler={(val) => {
              setDeSelectedIds([]);
              setBulkSelectAll(val);
            }}
            handleExport={() => {
              handleExport(
                false,
                selectedInvoices?.length,
                appliedFilters,
                dispatch,
                {
                  ...(bulkSelectAll
                    ? {
                        deselected_ids: deSelectedIds,
                      }
                    : {
                        selected_ids: selectedInvoices,
                      }),
                  export_type: EXPORT_PAGE.INVOICE,
                }
              );
            }}
            showExport
            clearSelection={() => {
              setBulkSelectAll(false);
              setHeaderSelectAll(false);
              setSelectedInvoices([]);
              setDeSelectedIds([]);
            }}
          />
        }
      >
        <tr className={tableHeadingRowClasses}>
          {tableHeading.map((headVal) => (
            <th className={headVal.classes} key={headVal.id}>
              <HeaderCell
                val={{
                  ...headVal,
                  checkedValue: headerSelectAll && billingTableData !== 0,
                  onCheckboxClick: headerSelectionHandler,
                  showCheckbox:
                    headVal.id === BILLING_TABLE_HEADING.INVOICE_NUMBER,
                  sorting,
                  setSorting,
                }}
              />
            </th>
          ))}
        </tr>
        {/* processing row */}

        {!isFetching || billingTableData?.length
          ? billingTableData.map((val, index, arr) => (
              <tr key={val.id} className={`${tableDataRowClasses}`}>
                {tableHeading.map(
                  ({ cellComponent: Component, classes, id }) => (
                    <td
                      className={`${classes} ${
                        selectedInvoices?.includes(val?.id)
                          ? "selected-row-cell"
                          : ""
                      }`}
                      ref={(ref) => {
                        if (index === arr.length - 1 && hasMore)
                          handleRefChange(ref);
                      }}
                      key={id}
                    >
                      <Component
                        handleRowSelection={handleRowSelection}
                        val={{
                          ...val,

                          onCheckboxClick: () => handleRowSelection(val),
                          checkedValue: selectedInvoices?.includes(val?.id),
                        }}
                        key={id}
                      />
                    </td>
                  )
                )}
              </tr>
            ))
          : null}
        {isFetching &&
          [...Array(10)].map((val, i) => (
            <tr className="text-center " key={i}>
              <td className="py-2 my-0.5 ml-4 text-left">
                <LoaderSkeleton size="lg" />
              </td>
              <td className="text-right">
                <LoaderSkeleton size="lg" />
              </td>
              <td className="text-center">
                <LoaderSkeleton size="lg" />
              </td>
              <td className="text-center">
                <LoaderSkeleton />
              </td>
              <td className="text-left">
                <LoaderSkeleton />
              </td>
              <td className="text-center">
                <LoaderSkeleton />
              </td>
              <td className="text-center">
                <LoaderSkeleton />
              </td>
            </tr>
          ))}
      </Table>
    </div>
  );
};

export default BillingInvoiceTable;
BillingInvoiceTable.propTypes = {
  billingTableData: PropsTypes.array,
  handleRowSelection: PropsTypes.func,
  selectedInvoices: PropsTypes.object,
  totalInvoicesSelected: PropsTypes.number,
  deSelectedIds: PropsTypes.array,
  setDeSelectedIds: PropsTypes.func,
  headerSelectAll: PropsTypes.bool,
  handleBulkAction: PropsTypes.func,
  allSelectedRow: PropsTypes.array,
  bulkApproveSelectAll: PropsTypes.bool,
  clearSelection: PropsTypes.func,
  headerSelectionHandler: PropsTypes.func,
  handleRefChange: PropsTypes.object,
  sorting: PropsTypes.object,
  setSorting: PropsTypes.func,
  hasMore: PropsTypes.bool,
};
