import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  declineDetails,
  fetchExpenses,
  resetExpensesListAndPagination,
} from "@/store/reducers/expense";
import {
  failureDetails,
  fetchQrPayments,
  resetQrPaymentsListAndPagination,
} from "@/store/reducers/qr-payments";

import { defaultCurrencySelector } from "@/store/selectors/client";
import {
  declineDetailsSelector,
  expensesHasMoreSelector,
  expensesListSelector,
  isDeclineDetailsFetchingSelectors,
  isExpensesFetchingSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  failureDetailsSelector,
  isFetchingQrPayments,
  qrPaymentsHasMoreSelector,
  qrPaymentsListSelector,
} from "@/store/selectors/qr-payments";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import CardHolder from "@/components/Cards/CardsTable/CardTableComponents/CardHolder";
import CardUsageType from "@/components/common/QrPayAndExpense/common/Cells/CardUsageType";
import DeclineTableLoader from "@/components/common/QrPayAndExpense/common/ExpenseLoaders/DeclineTableLoader";
import DeclineDetails from "@/components/common/QrPayAndExpense/common/NeedsReview/DeclineDetails";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import { convertFilters } from "@/utils/filters";
import {
  SORTING_CATEGORY,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";
import { FAILURES_TABLE_HEADERS } from "@/utils/constants/qr-payments";
import { DECLINES_TABLE_HEADERS } from "@/utils/constants/expenses";
import { amountToCurrency, dateToString } from "@/utils/common";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function DeclinesList({ viewTransaction, page, filteringParams = {} }) {
  const dispatch = useDispatch();
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;

  const expenseSelectors = {
    hasMore: useSelector(expensesHasMoreSelector),
    transaction: useSelector(expensesListSelector),
    isFetching: useSelector(isExpensesFetchingSelector),
    declineOrFailureDetailsObj: useSelector(declineDetailsSelector),
    isDeclineOrFailureDetailsFetching: useSelector(
      isDeclineDetailsFetchingSelectors
    ),
    transaction_type: "declined",
    tableHeaders: DECLINES_TABLE_HEADERS,
    supplier: "merchant",
    date: "expenseDate",
  };
  const paymentsSelector = {
    hasMore: useSelector(qrPaymentsHasMoreSelector),
    transaction: useSelector(qrPaymentsListSelector),
    isFetching: useSelector(isFetchingQrPayments),
    declineOrFailureDetailsObj: useSelector(failureDetailsSelector),
    isDeclineOrFailureDetailsFetching: useSelector(failureDetailsSelector),
    transaction_type: "failed",
    tableHeaders: FAILURES_TABLE_HEADERS,
    supplier: "vendor",
    date: "paymentDate",
  };
  const currentSelectors = isExpensesPage ? expenseSelectors : paymentsSelector;

  // common selectors for qrpay and expenses
  const appliedFilters = useSelector(appliedFilterSelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const isEmpty = currentSelectors?.transaction?.length === 0;
  const [sorting, setSorting] = useState({
    type: SORTING_TYPE.DEC,
    category: SORTING_CATEGORY.DATE,
  });

  const handleSorting = (category) => {
    setSorting((prev) => {
      return {
        category,
        type:
          prev.type === SORTING_TYPE.INC ? SORTING_TYPE.DEC : SORTING_TYPE.INC,
      };
    });
  };
  const onReset = () => {
    const resetTransaction = isExpensesPage
      ? resetExpensesListAndPagination
      : resetQrPaymentsListAndPagination;
    dispatch(resetTransaction());
  };
  const loadMore = () => {
    const fetchAction = isExpensesPage ? fetchExpenses : fetchQrPayments;

    const fetchDeclineDetails = isExpensesPage
      ? declineDetails
      : failureDetails;

    dispatch(
      fetchAction({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        transaction_type: currentSelectors?.transaction_type,
        ...convertFilters(appliedFilters),
        ...filteringParams,
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
      })
    );

    dispatch(fetchDeclineDetails(filteringParams));
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore: currentSelectors?.hasMore,
    loadMore,
    onReset,
    filterOptions: {
      filteringParams,
      ...convertFilters(appliedFilters),
      category: sorting?.category,
      type: sorting?.type,
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  function getAccountHolder(transaction) {
    return isExpensesPage ? transaction.cardHolder : transaction.walletHolder;
  }

  return (
    <>
      <DeclineDetails
        declineOrFailureData={currentSelectors?.declineOrFailureDetailsObj}
        currency={defaultCurrency}
        isFetching={currentSelectors?.isDeclineOrFailureDetailsFetching}
        page={page}
      />
      <div className="mt-6">
        <Table
          headerSticky
          colWidths={[260, 200, 250, 200, 300]}
          emptyDataTitle="expenses.declines.tableEmptyStates.title"
          emptyDataDescription="expenses.declines.tableEmptyStates.description"
        >
          <tr className="text-xs font-semibold text-left text-neutral-800">
            {currentSelectors?.tableHeaders.map((header, index) => (
              <th
                onClick={
                  header.sortingCategory
                    ? () => handleSorting(header.sortingCategory)
                    : null
                }
                key={`decline-header-${index}`}
                className={header.sortingCategory ? "cursor-pointer" : ""}
              >
                {header?.haveArrow ? (
                  <div className={`flex items-center ${header.className}`}>
                    <div className="flex flex-col justify-center ">
                      <Icon
                        className={
                          header?.sortingCategory === sorting.category &&
                          sorting.type === SORTING_TYPE.INC
                            ? "text-neutral-500"
                            : "text-neutral-300"
                        }
                        name="ArrowDropUp"
                      />
                      <Icon
                        className={
                          header?.sortingCategory === sorting.category &&
                          sorting.type === SORTING_TYPE.DEC
                            ? "text-neutral-500"
                            : "text-neutral-300"
                        }
                        name="ArrowDropDown"
                      />
                    </div>
                    <Text translationKey={header?.label} />
                  </div>
                ) : (
                  <Text translationKey={header?.label} />
                )}
              </th>
            ))}
          </tr>
          {(!currentSelectors?.isFetching || !isEmpty) &&
            currentSelectors?.transaction?.map((transaction, index, arr) => (
              <tr
                key={`expenses--declines-${index}`}
                ref={(ref) => {
                  if (index === arr.length - 1 && currentSelectors?.hasMore)
                    handleRefChange(ref);
                }}
              >
                <td className="cursor-pointer">
                  <div onClick={() => viewTransaction(transaction?.id)}>
                    <ProfileWidget
                      img={transaction?.[currentSelectors?.supplier]?.avatarUrl}
                      avatarColor={transaction?.merchant?.avatarColor}
                      name={transaction?.[currentSelectors?.supplier]?.name}
                      textClasses="text-sm text-neutral-800 font-semibold"
                    >
                      <div className="flex items-center text-xs truncate text-neutral-500 gap-0.5">
                        <span>
                          {dateToString(transaction?.[currentSelectors?.date])}
                        </span>
                        {transaction?.[currentSelectors?.date] &&
                        transaction?.projectName ? (
                          <span className="profile-widget-dot text-neutral-500" />
                        ) : null}
                        <span>{transaction?.projectName}</span>
                      </div>
                    </ProfileWidget>
                  </div>
                </td>
                <td className="text-sm font-semibold text-right">
                  {amountToCurrency(transaction?.amount, transaction?.currency)}
                </td>
                <td>
                  <CardUsageType
                    cardName={transaction?.cardName}
                    cardType={transaction?.cardType}
                    cardUsageType={transaction?.cardUsageType}
                    cardNumber={transaction?.cardNumber}
                    cardId={transaction?.cardId}
                  />
                </td>
                <td>
                  <CardHolder
                    displayName={getAccountHolder(transaction)?.displayName}
                    role={getAccountHolder(transaction)?.roles}
                    department={getAccountHolder(transaction)?.departmentName}
                    location={getAccountHolder(transaction)?.locationName}
                    showIconComponent={false}
                    classes="text-left"
                  />
                </td>
                <td>
                  <div className="flex items-center justify-between gap-1">
                    <Text
                      translationKey={transaction?.declineDetails?.title}
                      toolTipText={transaction?.declineDetails?.message}
                      showTooltip
                      id={transaction?.id}
                      classes="text-sm font-semibold"
                    />
                    {transaction?.declineDetails?.title ? (
                      <>
                        <Tooltip
                          id={`declineMessage-${index}`}
                          direction="bottom"
                        >
                          <Text
                            translationKey={
                              transaction?.declineDetails?.message
                            }
                          />
                        </Tooltip>
                        <div id={`declineMessage-${index}`}>
                          <Icon className="text-danger-400" name="Error" />
                        </div>
                      </>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
          {currentSelectors?.isFetching && <DeclineTableLoader />}
        </Table>
      </div>
    </>
  );
}

export default DeclinesList;
DeclinesList.propTypes = {
  viewTransaction: PropTypes.func,
  page: PropTypes.string,
  filteringParams: PropTypes.object,
};
