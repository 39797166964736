import PropTypes from "prop-types";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { openChatWindow } from "@/utils/chatbox";

export default function BottomNav({ links, activeNavItemIndex }) {
  const location = useLocation();
  const isActive = () => location.pathname.startsWith(); // addpath

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      {links
        ? links.map((linkObject, index) => (
            <div
              id={`bottom-nav-icon-number-${index}`}
              key={`bottom-nav-icon-number-${index}`}
              className={`  ${isActive() ? "bg-primary-50" : ""}
               ${
                 linkObject?.key === "chat"
                   ? "bg-primary-500 rounded-full text-white p-3 shadow-md"
                   : "p-4 rounded hover:bg-primary-50"
               }`}
            >
              {linkObject.path ? (
                linkObject.isExternalLink ? (
                  <a href={linkObject.path} target="_blank" rel="noreferrer">
                    <Icon name={linkObject.icon} />
                  </a>
                ) : (
                  <NavLink
                    to={linkObject.path}
                    onClick={() => openChatWindow()}
                  >
                    <Icon name={linkObject.icon} />
                  </NavLink>
                )
              ) : (
                <Icon
                  handleClick={() => openChatWindow()}
                  name={linkObject.icon}
                />
              )}
              <Tooltip id={`bottom-nav-icon-number-${index}`}>
                <Text translationKey={linkObject?.title} />
              </Tooltip>
            </div>
          ))
        : null}
    </div>
  );
}
BottomNav.propTypes = {
  links: PropTypes.array,
  activeNavItemIndex: PropTypes.number,
};
