import to from "await-to-js";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import API from "@/api";

const ledgerInitialState = {
  ledgers: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  unsyncedAmount: null,
  closingAmount: null,
  openingAmount: null,
  isFetchingLedgerDetails: false,
  unsyncedSlider: {
    page: 0,
    pageType: null,
    selectediId: null,
  },
  isFetchingSelected: false,
  selectedLedger: null,
  unSyncStatusNumber: 0,
  changesMade: [],
  filterKeys: {
    currency: [
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.ledgerType,
    ],
    all: [AVAILABLE_FILTER_KEYS.datePeriods, AVAILABLE_FILTER_KEYS.ledgerType],
    creditLedgerType: [
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.creditLedgerType,
    ],
    payrollLedgerType: [
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.payrollLedgerType,
    ],
  },
};

// Ledger Reducer
const ledgerSlice = createSlice({
  name: "ledgers",
  initialState: ledgerInitialState,
  reducers: {
    setLedgers(state, action) {
      state.ledgers.list = action.payload;
    },
    addLedgers(state, action) {
      state.ledgers.list = [...state.ledgers.list, ...action.payload];
    },
    setPageNumber(state, action) {
      state.ledgers.page = action.payload;
    },
    setTotal(state, action) {
      state.ledgers.total = action.payload;
    },
    setLedgersIsFetching(state, action) {
      state.ledgers.isFetching = action.payload;
    },
    setHasMore(state) {
      state.ledgers.hasMore = state.ledgers.list.length < state.ledgers.total;
    },
    setLedgerDetails(state, action) {
      const { closingBalance, openingBalance, totalUnsyncedAmount } =
        action.payload;

      state.closingAmount = closingBalance;
      state.openingAmount = openingBalance;
      state.unsyncedAmount = totalUnsyncedAmount;
    },
    setIsFetchingLedgerDetails(state, action) {
      state.isFetchingLedgerDetails = action.payload;
    },

    updateSyncStatus(state, action) {
      const { condition, status } = action.payload;
      state.ledgers.list = state.ledgers.list.map((ledger) => {
        let conditionStatus = true;
        if (!condition)
          conditionStatus = Math.floor(Math.random() * 10) % 2 === 0;
        const newdata = {
          ...ledger,
          accountingStatus:
            ledger.accountingStatus !== "synced" && !conditionStatus
              ? "sync_failed"
              : ledger.accountingStatus !== "synced" && conditionStatus
                ? status
                : ledger.accountingStatus,
        };
        if (ledger.accountingStatus !== "synced" && status !== "") {
          state.changesMade = state.changesMade.filter(
            (val) => val.id !== newdata.id
          );
          state.changesMade = [...state.changesMade, newdata];
        }
        return newdata;
      });
    },
    setTotalUnsyncLedger(state, action) {
      state.totalUnsynced.list = action.payload;
    },
    addTotalUnsyncLedger(state, action) {
      state.totalUnsynced.list = [...state.totalUnsynced.list, action.payload];
    },
    setIsTotalUnsyncedFetching(state, action) {
      state.totalUnsynced.isFetching = action.payload;
    },
    setTotalUnsyncedPageNumber(state, action) {
      state.totalUnsynced.page = action.payload;
    },
    setIsFetchingSelectedLedger(state, action) {
      state.isFetchingSelected = action.payload;
    },
    setSelectedLedger(state, action) {
      state.selectedLedger = action.payload;
    },
    setTotalUnsyncedTotal(state, action) {
      state.totalUnsynced.total = action.payload;
    },
    setTotalUnsyncedHasMore(state) {
      state.totalUnsynced.hasMore =
        state.ledgers.list.length < state.ledgers.total;
    },
    setUnsyncedSlider(state, action) {
      state.unsyncedSlider.page = action.payload.page;
      state.unsyncedSlider.pageType = action.payload.type;
      state.unsyncedSlider.selectediId = action.payload.id;
    },
    resetLedgers(state, aciton) {
      state.ledgers.list = [];
      state.ledgers.page = 1;
      state.ledgers.total = null;
      state.ledgers.hasMore = true;
    },
  },
});
export const fetchLedgers = createAsyncThunk(
  "ledgers/fetchLedgers",
  async (params, { dispatch }) => {
    dispatch(setLedgersIsFetching(true));
    const [err, response] = await to(API.Ledger.all(params));
    if (response) {
      if (response.data.page === 1) dispatch(setLedgers(response.data.list));
      else {
        dispatch(addLedgers(response.data.list));
      }
      dispatch(setTotal(response.data.total));
    }
    dispatch(setPageNumber(params.page));
    dispatch(setHasMore());
    dispatch(setLedgersIsFetching(false));
  }
);

export const fetchLedgerDetails = createAsyncThunk(
  "ledgers/fetchLedgerDetails",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingLedgerDetails(true));
    const [err, response] = await to(API.Ledger.getLedgerMetrics(params));
    if (response) {
      dispatch(setLedgerDetails(response.data));
    }
    dispatch(setIsFetchingLedgerDetails(false));
  }
);

export const exportLedger = createAsyncThunk(
  "ledgers/exportLedger",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Ledger.exportLedger({
        ...params,
      })
    );

    if (err) vToast(getErrorToastMessage(err));

    if (response) vToast(getSuccessToastMessage(response));
  }
);

export const fetchAndSelectLedger = createAsyncThunk(
  "ledger/fetchAndSelectLedger",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSelectedLedger(true));
    const [err, response] = await to(API.Ledger.get(params));
    if (response) {
      dispatch(setSelectedLedger(response));
    }
    dispatch(setIsFetchingSelectedLedger(false));
  }
);
export const {
  setLedgers,
  addLedgers,
  setPageNumber,
  setTotal,
  setLedgersIsFetching,
  setHasMore,
  setLedgerDetails,
  setIsFetchingLedgerDetails,
  resetLedgers,
  setUnSyncStatusNumber,
  updateSyncStatus,
  setTotalUnsyncLedger,
  addTotalUnsyncLedger,
  setIsTotalUnsyncedFetching,
  setTotalUnsyncedpage,
  setTotalUnsyncedTotal,
  setTotalUnsyncedHasMore,
  setUnsyncedSlider,
  setIsFetchingSelectedLedger,
  setSelectedLedger,
} = ledgerSlice.actions;
export default ledgerSlice.reducer;
