import { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { checkIfUserActionAllowed } from "@/utils/common";

import { CARDS_CTA_KEYS } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";

import "./styles.scss";

const selectedTabs = [
  {
    name: "cards.cardStatuses.all",
    count: null,
    path: ROUTES.cards.virtualCards.all.absolutePath,
    key: 1,
  },
  {
    name: "cards.cardStatuses.blocked",
    count: null,
    path: ROUTES.cards.virtualCards.blocked.absolutePath,
    key: 2,
  },
];

export default function VirtualCards() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(selectedTabs[0]);

  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  function handleVirtualCardButtonClick() {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage,
      true
    );
    setSearchParams(searchParams);
  }

  return (
    <div className="pr-11.5 pl-11">
      <div className="flex items-end justify-between pb-10">
        <div className="flex flex-col">
          <Text
            translationKey="cards.title.plural"
            color="neutral-500"
            classes="text-sm font-semibold"
          />

          <Text
            classes="text-4xl font-extrabold"
            color="neutral-800"
            translationKey="cards.vCards.title"
          />
        </div>

        {checkIfUserActionAllowed(
          allNavUrls,
          userAllowedActions,
          CARDS_CTA_KEYS.virtualCard
        ) ? (
          <Button
            label="cards.vCards.orderButtonLabel"
            preIcon="Plus"
            classes="h-11 w-[calc(theme(spacing.16)+theme(spacing.5))] text-btn-lg font-semibold"
            onClick={handleVirtualCardButtonClick}
          />
        ) : null}
      </div>
      <Tabs
        items={selectedTabs}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => setSelectedTab(tab)}
      />
    </div>
  );
}
