import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import useOnClickOutside from "@/hooks/useClickOutside";

import DateInputTextBox from "@/components/common/DateInputTextBox";
import { dateToString } from "@/utils/common";

import { DATEINPUT_MODES } from "@/constants/date";

/**
 * Month and year picker
 *
 * Tip/future ENHANCEMENT: can be used as a serial picker,
 * i.e. show date, month and year in the order you want.
 *
 * - Reopens picker after each mode
 * - Exiting the flow is possible, no value changed
 *
 * {@link https://www.figma.com/file/nYb1B3o06dNJPuFhW7fLWP/v2-Payroll?type=design&node-id=4802-404257&mode=design&t=Q9B0OOaIOxAbhzeN-4}
 * + two frames on the right
 */
export default function MonthAndYearInput({
  label,
  description,

  name,
  value,
  error,
  onChange,
  firstMode = DATEINPUT_MODES.YEAR,
  allModes = [DATEINPUT_MODES.YEAR, DATEINPUT_MODES.MONTH],
  allowPartialFlow = false,
  classes = "",
  ...rest
}) {
  const [mode, setMode] = useState(firstMode);
  const [valueInner, setValueInner] = useState(value || ""); // to hold value until all pickers have been used

  const [open, setOpen] = useState(undefined); // gymnastics needed
  // popup - proper flow end
  const onBlur = () => {
    setOpen(undefined);
    setMode(firstMode);
  };

  // popup - forced flow end (user clicks outside)
  const ref = useRef();
  useOnClickOutside(ref, (e) => {
    if (allowPartialFlow) {
      onChange(e);
    } else {
      setValueInner(value);
    }
    onBlur(e);
  });

  const onChangeInner = (e) => {
    setValueInner(e.target.value);

    setMode((prevMode) => {
      if (prevMode === allModes.at(-1)) {
        // last mode, commit value to parent
        onChange(e);
      }

      return prevMode; // mode not affected
    });
  };

  const switchModeHandler = (e) => {
    onChangeInner({ target: { value: e, name, ...rest } }); // needed since onChangeInner is not called if value is same
    setMode((prevMode) => {
      if (prevMode === allModes.at(-1)) {
        // last mode, commit value to parent
        onBlur();
      } else {
        // continue the flow
        setOpen(true);
      }

      const newMode =
        allModes[(allModes.indexOf(prevMode) + 1) % allModes.length];

      return newMode;
    });
  };

  useEffect(() => {
    // checking on value came initial value is not setting up corrctly in case of api call that's why setting it corectly
    if (!valueInner && value) setValueInner(value);
  }, [value]);

  return (
    <div className={classes} ref={ref}>
      <DateInputTextBox
        name={name}
        value={valueInner}
        handleChange={onChangeInner}
        label={label}
        valueToShow={dateToString(valueInner, {
          year: "numeric",
          day: undefined,
        })}
        error={error}
        description={description}
        clearable
        autoComplete="off"
        mode={mode}
        dateInputExtraProp={{
          open,
          onBlur,
          popperClassName: "react-date-year-month-picker",
          popperPlacement: "bottom-start",
          onSelect: switchModeHandler,
          ...rest,
        }}
        onChange={(e) => {
          if (!e?.target?.value) {
            return onChange(e);
          } // only allow clearing, otheriwse ignore input
        }}
      />
    </div>
  );
}

MonthAndYearInput.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,

  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  error: PropTypes.string,

  onChange: PropTypes.func,
  classes: PropTypes.string,

  firstMode: PropTypes.string,
  allModes: PropTypes.string,
  allowPartialFlow: PropTypes.bool,
};
