import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { setIsFormSubmissionProgress } from "@/store/reducers/loadersError";
import {
  deepCamelToSnake,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "@/utils/common";
import vToast from "@/utils/vToast";

import { POLICY_LAYER } from "@/constants/policy";
import API from "@/api";

const initialState = {
  policies: null,
  submissionPolicies: {},
  approvalPolicies: {},
  isFetching: false,
  isUpdating: false,
  currentPolicy: null,
  currentPolicyModule: null,
  currentPolicies: null,
  tempCurrentPolicy: null,
  submitable: true,
  currentClaimSubmissionType: null,
};

const policySlice = createSlice({
  name: "Policy",
  initialState,
  reducers: {
    setSubmissionPolicies(state, action) {
      state.policies = action.payload;
    },
    setCurrentPolicy(state, action) {
      state.currentPolicy = action.payload;
    },
    setCurrentPolicies(state, action) {
      state.currentPolicies = action.payload;
    },
    setCurrentPolicyModule(state, action) {
      state.currentPolicyModule = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setState(state, action) {
      state[action.payload.key] = action.payload.value;
    },
    setIsUpdating(state, action) {
      state.isUpdating = action.payload;
    },
    setSubmissionPolicyObject(state, action) {
      state.submissionPolicies.list = action.payload;
    },

    setApprovalPolicies(state, action) {
      state.policies = action.payload;
    },

    setApprovalPolicyObject(state, action) {
      state.approvalPolicies.object = action.payload;
    },

    setSubmitable(state, action) {
      state.submitable = action.payload;
    },

    setCurrentClaimSubmissionType(state, action) {
      state.currentClaimSubmissionType = action.payload;
    },
  },
});

export const fetchSubmissionPolicies = createAsyncThunk(
  "policy/fetchSubmissionPolicies",
  async (params, { dispatch }) => {
    dispatch(setState({ key: "isFetching", value: true }));

    const [err, response] = await to(API.SubmissionPolicy.all(params));

    if (response?.data) {
      dispatch(setCurrentPolicies(response?.data));
      dispatch(setSubmissionPolicyObject(response?.data));
    }
    dispatch(setState({ key: "isFetching", value: false }));
  }
);

export const fetchApprovalPolicies = createAsyncThunk(
  "policy/fetchApprovalPolicies",
  async (params, { dispatch }) => {
    dispatch(setState({ key: "isFetching", value: true }));

    const [err, response] = await to(
      API.ApprovalPolicy.getPolicyGroups(params)
    );

    if (response?.data) {
      dispatch(setCurrentPolicies(response?.data));
      dispatch(setApprovalPolicyObject(response?.data));
    }
    dispatch(setState({ key: "isFetching", value: false }));
  }
);

export const fetchApprovalPolicy = createAsyncThunk(
  "policy/fetchApprovalPolicy",
  async (params, { dispatch }) => {
    dispatch(setState({ key: "isFetching", value: true }));

    const [err, response] = await to(API.ApprovalPolicy.getPolicy(params));

    if (response?.data) {
      dispatch(setCurrentPolicy(response.data));
      // setting a tempCurrentPolicy for referal of currentPolicy since currentPolicy will be updated in form.
      dispatch(setState({ key: "tempCurrentPolicy", value: response.data }));
    }

    dispatch(setState({ key: "isFetching", value: false }));
  }
);

export const fetchSubmissionPolicy = createAsyncThunk(
  "policy/fetchSubmissionPolicy",
  async (params, { dispatch }) => {
    dispatch(setState({ key: "isFetching", value: true }));

    const [err, response] = await to(API.SubmissionPolicy.getPolicy(params));

    if (response?.data) {
      dispatch(setCurrentPolicy(response.data));
      // setting a tempCurrentPolicy for referal of currentPolicy since currentPolicy will be updated in form.
      dispatch(setState({ key: "tempCurrentPolicy", value: response.data }));
    }

    dispatch(setState({ key: "isFetching", value: false }));
  }
);

export const upsertSubmissionPolicyGroup = createAsyncThunk(
  "policy/upsertSubmissionPolicyGorup",
  async (params, { dispatch, getState }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const updatedState = await getState();

    const curtPolicy = updatedState.policy.currentPolicy;

    const payload = {
      id: curtPolicy?.id,
      receipt_required: curtPolicy?.receipt?.required,
      minimum_amount_for_receipt: curtPolicy?.receipt?.amount || 0,
      note_required: curtPolicy?.memo?.required,
      minimum_amount_for_note: curtPolicy?.memo?.amount || 0,
      sub_category_required: curtPolicy?.subCategory?.required,
      minimum_amount_for_sub_category: curtPolicy?.subCategory?.amount || 0,
      enable_review_min_amount: curtPolicy?.review?.required,
      minimum_amount_for_review: curtPolicy?.review?.amount || 0,
    };

    const tags = [
      ...(curtPolicy?.accountingTags ?? []),
      ...(curtPolicy?.customTags ?? []),
    ];

    payload.submission_policy_tags_attributes = [];

    tags.forEach((tag) => {
      if (tag.id || (!tag.id && tag.required))
        payload.submission_policy_tags_attributes.push({
          tag_id: tag.tagId,
          id: tag.id,
          required: tag.required,
          minimum_amount: tag.amount,
        });
    });

    if (params.projectId) {
      payload.project_id = params.projectId;
      payload.policy_type = POLICY_LAYER.custom;
      delete payload.id;
      delete payload.name;
      payload.submission_policy_tags_attributes.forEach((policy) => {
        delete policy.id;
      });
    }

    payload.submission_policy_type = params.submissionPolicyType;

    const [err, response] = await to(
      API.SubmissionPolicy.upsertPolicyGroup(payload)
    );

    if (response) {
      vToast(getSuccessToastMessage({ message: "Policy updated sucessfully" }));
      params.closeSlider(response.data.id);
    } else if (err) vToast(getErrorToastMessage(err));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const archivePolicy = createAsyncThunk(
  "policy/archivePolicy",
  async (params, { dispatch, getState }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setState({ key: "isUpdating", value: true }));
    const updatedState = await getState();

    const curtPolicy = updatedState.policy.currentPolicy;
    const [err, response] = await to(
      API.ApprovalPolicy.archivePolicyGroup({
        id: curtPolicy.id,
        policy_group_type: params.policyGroupType,
      })
    );

    if (response) {
      vToast(
        getSuccessToastMessage({
          message: "policy.submissionModules.revertToCompanyPolicyToast",
        })
      );
      dispatch(setCurrentPolicy(response.data));
      params.closeSlider(response.data.id);
    } else if (err) vToast(getErrorToastMessage(err));

    dispatch(setState({ key: "isUpdating", value: false }));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const archiveClaimPolicy = createAsyncThunk(
  "policy/archiveClaimPolicy",
  async (params, { dispatch }) => {
    dispatch(setState({ key: "isUpdating", value: true }));

    const [err, response] = await to(
      API.ClaimPolicies.archive({
        id: params.id,
        policy_group_type: params.policyGroupType,
      })
    );

    if (response) {
      vToast(
        getSuccessToastMessage({
          message: "policy.submissionModules.revertToCompanyPolicyToast",
        })
      );
      dispatch(setCurrentPolicy(response.data));
      params.closeSlider(response.data.id);
    } else if (err) vToast(getErrorToastMessage(err));

    dispatch(setState({ key: "isUpdating", value: false }));
  }
);
export const archiveSubmissionPolicy = createAsyncThunk(
  "policy/archiveSubmissionPolicy",
  async (params, { dispatch, getState }) => {
    dispatch(setState({ key: "isUpdating", value: true }));
    const updatedState = await getState();

    const curtPolicy = updatedState.policy.currentPolicy;
    const [err, response] = await to(
      API.SubmissionPolicy.archiveSubmissionPolicy({
        id: curtPolicy?.id,
        project_id: params.projectId,
        submission_policy_type: params.submissionPolicyType,
      })
    );

    if (response) {
      vToast(
        getSuccessToastMessage({
          message: "policy.submissionModules.revertToCompanyPolicyToast",
        })
      );
      dispatch(setCurrentPolicy(response.data));
      params.closeSlider(response.data.id);
    } else if (err) vToast(getErrorToastMessage(err));

    dispatch(setState({ key: "isUpdating", value: false }));
  }
);

export const upsertApprovalPolicyGroup = createAsyncThunk(
  "policy/upsertApprovalPolicyGroup",
  async (params, { dispatch, getState }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setState({ key: "isUpdating", value: true }));

    const updatedState = await getState();

    const curtPolicy = updatedState.policy.currentPolicy;
    const tempCurtPolicy = updatedState.policy.tempCurrentPolicy;

    const payload = deepCamelToSnake(curtPolicy);
    const policies_attributes = payload.policies;

    const tempPolciyAttributes = payload.policies.filter(
      (policy) => !policy._destroy
    );

    policies_attributes.forEach((a, i) => {
      let apprs = Object.values(policies_attributes[i].approvers)
        .map((policy) => policy.approvers)
        .flat();
      delete policies_attributes[i].approvers;
      apprs.forEach((appr) => {
        appr.user_id = appr.user_info.id;
        delete appr.user_info;
      });
      policies_attributes[i].default_level =
        apprs.find((appr) => appr.user_id === -1 && !appr._destroy)
          ?.approver_level || "";
      policies_attributes[i].default_admin_level =
        apprs.find((appr) => appr.user_id === 0 && !appr._destroy)
          ?.approver_level || "";
      apprs = apprs.filter((appr) => ![0, -1].includes(appr.user_id));

      policies_attributes[i].amount_to = tempPolciyAttributes[i + 1]
        ? Number(tempPolciyAttributes[i + 1].amount_from) - 1
        : null;

      policies_attributes[i].approvers_attributes = apprs;
    });

    policies_attributes.forEach((policy, idx) => {
      if (policy.updating) {
        const tempPolicy = tempCurtPolicy.policies.find(
          (pol) => pol.id === policy.id
        );
        let tempApprs = Object.values(tempPolicy.approvers)
          .map((pol) => pol.approvers)
          .flat();

        tempApprs = deepCamelToSnake(tempApprs);

        tempApprs.forEach((appr) => {
          appr.user_id = appr.user_info.id;
          delete appr.user_info;
          appr._destroy = true;
        });

        policies_attributes[idx].approvers_attributes = [
          ...policies_attributes[idx].approvers_attributes,
          ...tempApprs,
        ];
      }
    });

    delete payload.policies;

    if (params.projectId) {
      payload.project_id = params.projectId;
      payload.policy_group_type = params.policyGroupType;
      payload.policy_type = POLICY_LAYER.custom;
      delete payload.id;
      delete payload.name;
      policies_attributes.forEach((policy) => {
        delete policy.id;
        policy.approvers_attributes.forEach((approver) => {
          delete approver.id;
          delete approver.policy_id;
        });
      });
    }

    payload.policies_attributes = policies_attributes;

    const [err, response] = await to(
      API.ApprovalPolicy.upsertPolicyGroup(payload)
    );

    if (response) {
      vToast(getSuccessToastMessage({ message: "Policy updated sucessfully" }));
      dispatch(setCurrentPolicy(response.data));
      if (params.type === POLICY_LAYER.company) {
        params.closeSlider(response.data.id);
      }
    } else if (err) vToast(getErrorToastMessage(err));

    dispatch(setState({ key: "isUpdating", value: false }));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const {
  setState,
  setCurrentPolicy,
  setCurrentPolicies,
  setCurrentPolicyModule,
  setSubmissionPolicies,
  setSubmissionPolicyObject,
  setApprovalPolicies,
  setApprovalPolicyObject,
  setSubmitable,
  setCurrentClaimSubmissionType,
} = policySlice.actions;

export default policySlice.reducer;
