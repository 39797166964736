import {
  ACCOUNTING_TRANSACTION_TYPES,
  OTHER_TRANSACTION_STATUSES,
} from "./accounting";

export const OTHER_TRANSACTION_STATUS_TO_COLOR_MAP = {
  [OTHER_TRANSACTION_STATUSES.SETTLED]: {
    color: "success",
    text: "accounting.transactions.others.transactionStatus.settled",
  },
  [OTHER_TRANSACTION_STATUSES.REJECTED]: {
    color: "danger",
    text: "reimbursement.transactionStatus.rejected",
  },
  [OTHER_TRANSACTION_STATUSES.PENDING]: {
    // design has this, although it makes no sense
    color: "warning",
    text: "pending",
  },
};

export const OTHER_TYPES_ICON_AND_TITLE_MAP = {
  [ACCOUNTING_TRANSACTION_TYPES.CREDIT]: {
    icon: "Add",
    title: "accounting.transactions.others.typesTitle.credit",
  },
  [ACCOUNTING_TRANSACTION_TYPES.DEBIT]: {
    icon: "Minus",
    title: "accounting.transactions.others.typesTitle.debit",
  },
  [ACCOUNTING_TRANSACTION_TYPES.INVOICE_PAYMENT]: {
    icon: "InvoicePayment",
    title: "accounting.transactions.others.typesTitle.invoice_payment",
  },
  [ACCOUNTING_TRANSACTION_TYPES.CREDIT_PAYMENT]: {
    icon: "InvoicePayment",
    title: "accounting.transactions.others.typesTitle.credit_payment",
  },
  [ACCOUNTING_TRANSACTION_TYPES.CREDIT_FEE_PAYMENT]: {
    icon: "InvoicePayment",
    title: "accounting.transactions.others.typesTitle.credit_fee_payment",
  },
  [ACCOUNTING_TRANSACTION_TYPES.ADJUSTMENT]: {
    icon: "Adjustment",
    title: "accounting.transactions.others.typesTitle.adjustment",
  },
  [ACCOUNTING_TRANSACTION_TYPES.CASHBACK]: {
    icon: "Replay",
    title: "accounting.transactions.others.typesTitle.cashback",
  },
  [ACCOUNTING_TRANSACTION_TYPES.DECLINED]: {
    icon: "Close",
    title: "accounting.transactions.others.typesTitle.declined",
  },
  [ACCOUNTING_TRANSACTION_TYPES.INTERNAL_CREDIT]: {
    icon: "Add",
    title: "accounting.transactions.others.typesTitle.internal_credit",
  },
  [ACCOUNTING_TRANSACTION_TYPES.INTERNAL_DEBIT]: {
    icon: "Minus",
    title: "accounting.transactions.others.typesTitle.internal_debit",
  },
  [ACCOUNTING_TRANSACTION_TYPES.TRANSFER_FEES]: {
    icon: "Share",
    title: "accounting.transactions.others.typesTitle.allocation",
  },
  [ACCOUNTING_TRANSACTION_TYPES.CREDIT_TRANSFER_CREDIT]: {
    icon: "Add",
    title: "accounting.transactions.others.typesTitle.credit_transfer_credit",
  },
  [ACCOUNTING_TRANSACTION_TYPES.CREDIT_TRANSFER_DEBIT]: {
    icon: "Minus",
    title: "accounting.transactions.others.typesTitle.credit_transfer_debit",
  },
};
