import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// reducers
import {
  fetchSupportedExportFormats,
  setAccountingExportFormatType,
  setExportFormat,
} from "@/store/reducers/accounting";

// selectors
import {
  accountingIntegrationExportFormatOptionsSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";

// core components
import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";
import VpLink from "@/components/core/vp-link";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import { useForm } from "@/utils/useForm";
import { INTEGRATION_UCSV_HELP_URL } from "@/utils/constants/integrations";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function UCSVConnectSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isLoadingConnect = useSelector(isLoadingAccountingIntegrationsSelector);
  const softwareFormatOptions = useSelector(
    accountingIntegrationExportFormatOptionsSelector
  );

  useEffect(() => {
    dispatch(fetchSupportedExportFormats());
  }, []);

  const handleContinue = async () => {
    const action = await dispatch(
      setExportFormat({ export_format: values.accountingSoftwareFormat })
    );

    if (setExportFormat.fulfilled.match(action)) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.accounting.integrations.importDocuments,
        true
      );
      setSearchParams(searchParams);
      dispatch(setAccountingExportFormatType(values.accountingSoftwareFormat));
    }
  };

  const initialValue = {
    accountingSoftwareFormat: {
      value: "",
      validate: {
        required: true,
      },
    },
  };

  const { handleChange, handleSubmit, values, errors, isFormButtonDisabled } =
    useForm(initialValue, () => {
      handleContinue();
    });

  return (
    <div className="slider-content-container flex flex-col gap-11.5">
      <div className="slider-core-container px-10 flex flex-col gap-8 ">
        <SliderHeader
          heading="accounting.integrations.universalCsv.title"
          headingClasses="text-4xl font-bold text-neutral-800"
          refFlag
        />
        <form
          onSubmit={handleSubmit}
          id="ucsv-setup-form"
          className="flex flex-col gap-9"
        >
          <div className="flex flex-col justify-center gap-3 p-4 bg-neutral-50 border border-neutral-300 rounded-xl">
            <SliderHeader
              heading="accounting.integrations.support.pleaseNote"
              subText="accounting.integrations.support.description"
              headingClasses="text-base font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <VpLink
              onClickHandler={() => {
                window.open(INTEGRATION_UCSV_HELP_URL, "_blank");
              }}
              text="accounting.integrations.linkLabel.learnMore"
              textClasses="text-sm underline font-semibold"
            />
          </div>

          <div className="flex flex-col justify-center gap-6 mb-10">
            <SliderHeader
              heading="accounting.integrations.labels.setup"
              subText="accounting.integrations.universalCsv.setup.description"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />

            <VpSelect
              label="accounting.integrations.universalCsv.setup.selectSoftwareFormat"
              name="accountingSoftwareFormat"
              menuPosition="absolute"
              options={softwareFormatOptions}
              optionsDisplayKey="label"
              valueKey="value"
              value={values.accountingSoftwareFormat}
              handleChange={handleChange}
              insideForm
              classes="vp-tooltip"
            />
          </div>
        </form>
      </div>
      <div className="slider-footer sticky p-6 ">
        <div className="flex justify-end ">
          <Button
            label="accounting.integrations.buttonLabel.continue"
            classes="w-14.75 px-5 py-3 text-btn-lg font-semibold"
            btnType="submit"
            showLoader={isLoadingConnect}
            disabled={isFormButtonDisabled}
            id={`UCSV-continue-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
            form="ucsv-setup-form"
          />
          {isFormButtonDisabled ? (
            <Tooltip
              direction="top"
              id={`UCSV-continue-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
            >
              <Text translationKey="accounting.integrations.toolTipText.softwareFormatMandatory" />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}
