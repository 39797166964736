import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchPeopleShallow } from "@/store/reducers/company";
import { setCurrentPolicy, setSubmitable } from "@/store/reducers/policy";

import {
  expenseMultiLayerReviewEnabledSelector,
  maxApproverLevelsSelector,
  maxPoliciesInPolicyGroupSelector,
} from "@/store/selectors/client";
import { allPeopleSelector } from "@/store/selectors/company";
import { currentPolicySelector } from "@/store/selectors/policy";

import Button from "@/components/core/Button";
import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import PillsUpperSection from "@/components/core/CheckboxDropdown/PillsUpperSection";
import {
  __TOGGLE_MODE_GROUP_ENTRY,
  __TOGGLE_MODE_SINGLE_ENTRY,
} from "@/components/core/CheckboxDropdown/utils";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Loader from "@/components/core/Loader";
import Note from "@/components/core/Note";
import ProfileWidget from "@/components/core/ProfileWidget";
import RangeBar from "@/components/core/RangeBar";
import Text from "@/components/core/Text";

import AddLayerPopup from "@/components/Settings/Sliders/AddLayerPopup";
import AppliedCountCards from "@/components/Settings/Sliders/common/AppliedCountCards";
import { PAYROLL } from "@/utils/constants/payments";
import { MODULES } from "@/utils/constants/app";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_TAB_PARAMS,
  ROLE_TITLE,
  USER_ROLE,
} from "@/constants/company";
import { POLICY_GROUP_MAPPING, POLICY_LAYER } from "@/constants/policy";
import {
  APPROVAL_LEVEL,
  LAYER_TYPE_HEADING,
  LAYER_TYPE_LABEL,
  USER_LAYER_TYPES,
} from "@/constants/settings";
import { PERMISSIONS } from "@/constants/user";

export default function ApprovalWorkflow({ type, state }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const expenseMultiLayerReviewEnabled = useSelector(
    expenseMultiLayerReviewEnabledSelector
  );

  const [approvalAmountInput, setApprovalAmountInput] = useState([]);
  const curtPolicy = useSelector(currentPolicySelector);
  const maxLevel = useSelector(maxApproverLevelsSelector);
  const maxPoliciesInPolicyGroup = useSelector(
    maxPoliciesInPolicyGroupSelector
  );

  const [policies, setPolicies] = useState(curtPolicy?.policies);

  const editState = searchParams.get(SLIDERS_SEARCH_PARAMS.settings.editState);
  const approvalPolicyType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.approvalPolicy
  );

  const projectOrDepartmentId =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.department.id) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.project.id);

  const approvalPolicyModule = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.policyModule
  );

  const isCompany = approvalPolicyType === POLICY_LAYER.company;

  const [approvalDataChangesCount, setApprovalDataChangesCount] = useState(0);

  useEffect(() => {
    dispatch(setSubmitable(true)); // making sure next time when they open form is submitable
    dispatch(
      fetchPeopleShallow({
        status: COMPANY_PEOPLE_TAB_PARAMS.ACTIVE,
        ...(approvalPolicyModule === PAYROLL
          ? { with_permissions: [PERMISSIONS.PAYROLL_ACCESS] }
          : null),
      })
    );
  }, []);
  useEffect(() => {
    setPolicies(curtPolicy?.policies);
  }, [curtPolicy]);

  useEffect(() => {
    setApprovalAmountInput(policies?.map((policy) => policy.amountFrom));
  }, [policies]);

  const allUsers = useSelector(allPeopleSelector)?.map((item) => ({
    id: item.id,
    name: item.displayName,
    role: item.role,
  }));

  const getRangeData = () => {
    return (
      policies &&
      policies
        ?.filter((pol) => !pol?._destroy)
        .map((policy) => policy.amountFrom)
    );
  };

  const handleAmountChange = (newValue, index) => {
    setApprovalAmountInput((prevState) => {
      const temp = [...prevState];
      temp[index] = parseInt(newValue, 10);
      return temp;
    });
    const currentPolicy = JSON.parse(JSON.stringify(curtPolicy));
    currentPolicy.policies[index].amountFrom = newValue;

    // checking whether the form is submitable or not based on the previous range amount
    const amounts = currentPolicy.policies.map((pol) =>
      parseFloat(pol.amountFrom)
    );

    const submitable = amounts.every(
      (currentValue, arrInd) =>
        arrInd === 0 || currentValue > amounts[arrInd - 1]
    );

    dispatch(setSubmitable(submitable));

    dispatch(setCurrentPolicy(currentPolicy));
  };

  const handleAddNewRange = () => {
    if (policies?.length > 0) {
      const newValue = { ...(policies.at(-1) ?? {}) };
      newValue.id = null;
      newValue.approvers = { first_level: { approvers: [], type: "approver" } };
      newValue.amountFrom = parseFloat(newValue.amountFrom) + 1;
      newValue.amountTo = null;
      const updatedApprovalPolicy = JSON.parse(JSON.stringify(curtPolicy));

      updatedApprovalPolicy.policies.push(newValue);

      dispatch(setCurrentPolicy(updatedApprovalPolicy));
    }
  };

  const handleDeletePolicy = (index, id) => {
    const currentPolicy = JSON.parse(JSON.stringify(curtPolicy));
    const tempPolicy = currentPolicy?.policies[index];

    tempPolicy._destroy = true;

    currentPolicy.policies[index] = tempPolicy;
    setPolicies(currentPolicy?.policies);
    dispatch(setCurrentPolicy(currentPolicy));
  };

  const handleDeletePeople = (policyIndex, level) => {
    const currentPolicy = JSON.parse(JSON.stringify(curtPolicy));
    const tempPolicy = currentPolicy?.policies[policyIndex];

    if (tempPolicy.id) tempPolicy.updating = true;

    delete tempPolicy?.approvers[level];

    const newApprovers = {};

    Object.keys(tempPolicy?.approvers).forEach((key, idx) => {
      newApprovers[APPROVAL_LEVEL[idx]] = tempPolicy?.approvers[key];

      const newUsers = [];

      newApprovers[APPROVAL_LEVEL[idx]]?.approvers.forEach((user) => {
        const currentUser = {
          id: null,
          approverLevel: APPROVAL_LEVEL[idx],
          approverType: user.approverType,
          userInfo: user.userInfo,
          policyId: user.policyId,
        };
        newUsers.push(currentUser);
      });

      newApprovers[APPROVAL_LEVEL[idx]].approvers = newUsers;
    });

    tempPolicy.approvers = newApprovers;
    currentPolicy.policies[policyIndex] = tempPolicy;

    setPolicies(currentPolicy?.policies);
    dispatch(setCurrentPolicy(currentPolicy));
  };

  const handleSelectedPeople = (val, policy, level, aprtype) => {
    const currentPolicy = JSON.parse(JSON.stringify(curtPolicy));
    const newUser = {
      id: null,
      approverLevel: level,
      approverType: aprtype,
      userInfo: {},
      policyId: policy.id,
    };
    const tempPolicy = currentPolicy.policies.find((a) =>
      policy.id ? policy.id === a.id : policy.amountFrom === a.amountFrom
    );
    const approvers = [];

    if (val.selectedGroups.length) {
      tempPolicy.approvers[level].approvers.forEach((user) => {
        if (!val.selectedIds.includes(user.userInfo.id)) {
          user._destroy = true;
          approvers.push(user);
        }
      });
      let nwUser = JSON.parse(JSON.stringify(newUser));
      const userInfoId = nwUser.userInfo.id;
      const idToSet = val.selectedGroups[0] === USER_ROLE.Admin ? 0 : -1;
      const currentUser = tempPolicy.approvers[level].approvers.find(
        (user) => user.userInfo.id === idToSet
      );
      if (currentUser) {
        if (currentUser._destroy) delete currentUser._destroy;
        nwUser = JSON.parse(JSON.stringify(currentUser));
      } else if (userInfoId !== idToSet) {
        nwUser.userInfo = {
          id: val.selectedGroups[0] === USER_ROLE.Admin ? 0 : -1,
        };
      }
      approvers.push(nwUser);
    } else {
      tempPolicy.approvers[level].approvers.forEach((user) => {
        if (!val.selectedIds.includes(user.userInfo.id)) {
          user._destroy = true;
          approvers.push(user);
        }
      });

      val.selectedIds.forEach((id) => {
        const currentUser = tempPolicy.approvers[level].approvers.find(
          (user) => user.userInfo.id === id
        );
        if (!currentUser) {
          const nwUser = JSON.parse(JSON.stringify(newUser));
          nwUser.userInfo = { id };
          approvers.push(nwUser);
        } else {
          if (currentUser._destroy) delete currentUser._destroy;
          approvers.push(currentUser);
        }
      });
    }

    tempPolicy.approvers[level].approvers = approvers;

    const policyIndex = currentPolicy.policies.findIndex((a) =>
      policy.id ? policy.id === a.id : policy.amountFrom === a.amountFrom
    );

    currentPolicy.policies[policyIndex] = tempPolicy;

    setPolicies(currentPolicy?.policies);

    dispatch(setCurrentPolicy(currentPolicy));
    setApprovalDataChangesCount((prev) => prev + 1);
  };

  const isNoifyLayer = (curType) => curType === USER_LAYER_TYPES.notification;

  const removeLayerItem = (policy) =>
    Object.keys(policy.approvers).some((a) =>
      isNoifyLayer(policy.approvers[a].type)
    );

  const userGroupingFunction = (approvers, level) => {
    if (isNoifyLayer(approvers[level].type)) {
      return [];
    }
    let adminPresent = false;
    let dpmPresent = false;
    const groupValues = [];

    let previousApprovers = [];
    Object.keys(approvers).forEach((key) => {
      if (isNoifyLayer(approvers[key].type)) return;
      if (key !== level) {
        const ids = approvers[key].approvers
          ?.filter((ap) => !ap._destroy)
          ?.map((ap) => ap.userInfo?.id);
        previousApprovers = previousApprovers.concat(ids);
      }
    });
    adminPresent = adminPresent || previousApprovers.includes(0);
    dpmPresent = dpmPresent || previousApprovers.includes(-1);

    if (!adminPresent)
      groupValues.push({
        groupKey: USER_ROLE.Admin,
        label: ROLE_TITLE.ADMIN,
      });

    if (!dpmPresent && approvalPolicyModule !== MODULES.PHYSICAL_CARD)
      groupValues.push({
        groupKey: USER_ROLE.DEPARTMENT_MANAGER,
        label: ROLE_TITLE.DEPARTMENT_MANAGER,
      });

    return groupValues;
  };

  function getHeaderNodeUI({ groupKey }) {
    switch (groupKey) {
      case USER_ROLE.Admin:
        return {
          label: USER_ROLE.Admin,
        };
      case USER_ROLE.DEPARTMENT_MANAGER:
        return {
          label: ROLE_TITLE.DEPARTMENT_MANAGER,
        };

      default:
        break;
    }
  }

  const handleSelectedPeopleRemoval = (id, policy, level) => {
    const currentPolicy = JSON.parse(JSON.stringify(curtPolicy));
    const tempPolicy = currentPolicy.policies.find((a) => policy.id === a.id);
    tempPolicy.approvers[level].approvers.map((user) => {
      if (user?.userInfo?.id === id) {
        user._destroy = true;
      }
      return user;
    });

    const policyIndex = currentPolicy.policies.findIndex(
      (a) => policy.id === a.id
    );

    currentPolicy.policies[policyIndex] = tempPolicy;

    setPolicies(currentPolicy?.policies);

    dispatch(setCurrentPolicy(currentPolicy));
  };

  const toggleCheckboxHandler = (
    {
      id = null,
      value,
      groupKey = null,
      toggleMode = "",
      setSelectedStuff,
      fromPill = false,
    },
    policy = null,
    level = null
  ) => {
    if (toggleMode === __TOGGLE_MODE_SINGLE_ENTRY) {
      setSelectedStuff((prev) => {
        // handle individual ids
        if (value) {
          prev.ids.add(id);
          prev.individualIds.add(id);
        } else {
          prev.ids.delete(id);
          prev.individualIds.delete(id);
        }

        // handle groups contents
        prev._groupContents.forEach((_, groupKey_) => {
          prev.groups.delete(groupKey_);
        });

        return { ...prev };
      });
    } else if (toggleMode === __TOGGLE_MODE_GROUP_ENTRY) {
      // a group was toggled
      setSelectedStuff((prev) => {
        const relevantGroup = prev._groupContents.get(groupKey);

        // handle individual ids
        if (value) {
          relevantGroup.allIds.forEach((_id) => prev.ids.add(_id)); // add all ids to the outer ids set (used in flat view)
          relevantGroup.selectedIds = new Set([...relevantGroup.allIds]); // all applicable are selected now
          prev.ids = new Set();
          prev.individualIds = new Set();
        } else {
          relevantGroup.allIds.forEach((_id) => prev.ids.delete(_id)); // remove all ids from the outer ids Set (used in flat view)
          relevantGroup.selectedIds.clear(); // all applicable are deselected now
        }

        // handle groups contents
        prev._groupContents.forEach(({ allIds, selectedIds }, groupKey_) => {
          if (groupKey !== groupKey_) {
            prev.groups.delete(groupKey_);
          }
          if (value) {
            prev.groups.add(groupKey);
          } else {
            prev.groups.delete(groupKey);
          }
        });

        return { ...prev };
      });
    }

    if (!value && fromPill) {
      setApprovalDataChangesCount((prev) => prev + 1);
      const idValue = id || (groupKey === USER_ROLE.Admin ? 0 : -1);
      handleSelectedPeopleRemoval(idValue, policy, level);
    }
  };

  const orderedKeys = (keys) => {
    const ordKeys = [];
    for (let i = 0; i < 5; i += 1) {
      if (Object.keys(keys).includes(APPROVAL_LEVEL[i]))
        ordKeys.push(APPROVAL_LEVEL[i]);
    }
    return ordKeys;
  };

  const checkLayerButton = (approvers) => {
    const orderKey = orderedKeys(approvers);
    const lastLevel = orderKey.at(-1);
    const layersOptionAvail = layerOptions(approvers).length;

    if (!Object.keys(approvers)?.length) return true; // added to show add layer for auto approved section.

    return (
      approvers[lastLevel]?.approvers?.length > 0 &&
      parseFloat(maxLevel) > Object.keys(approvers)?.length &&
      layersOptionAvail
    );
  };
  const checkAdminManager = (approvers) => {
    if (approvers?.find((appr) => !appr._destroy)?.userInfo?.id === 0)
      return [USER_ROLE.Admin];

    if (approvers?.find((appr) => !appr._destroy)?.userInfo?.id === -1)
      return [USER_ROLE.DEPARTMENT_MANAGER];
  };

  const checkUser = (approvers) => {
    return !approvers._destroy && ![0, -1].includes(approvers?.userInfo?.id);
  };

  const isExpenseReview =
    approvalPolicyModule === POLICY_GROUP_MAPPING.expenseReview;

  const layerLabel = (apprType) => {
    let tempApprType = apprType;
    if (isExpenseReview && apprType === USER_LAYER_TYPES.approver)
      tempApprType = USER_LAYER_TYPES.review;
    return LAYER_TYPE_HEADING[tempApprType];
  };

  const layerOptions = (layers) => {
    const options = [];

    const notify = Object.values(layers)
      .map((layer) => layer.type)
      .includes(USER_LAYER_TYPES.notification);

    const appr = Object.values(layers)
      .map((layer) => layer.type)
      .includes(USER_LAYER_TYPES.approver);

    if (!notify)
      options.push({
        id: 0,
        option: USER_LAYER_TYPES.notification,
        label:
          "settings.spendControls.approvalPolicy.approvalWorkFlow.addLayer.notificationLayer",
      });

    // will add client config condition so it will be client specific feature for expense review
    if (expenseMultiLayerReviewEnabled || !isExpenseReview || !appr)
      options.push({
        id: 1,
        option: USER_LAYER_TYPES.approver,
        label: `settings.spendControls.approvalPolicy.approvalWorkFlow.addLayer.${
          isExpenseReview ? "reviewLayer" : "approvalLayer"
        }`,
      });

    return options;
  };

  const getCurrentUsersOptions = (policy, level) => {
    const usersInOtherLevel = Object.values(policy.approvers)
      .flatMap((a) => a.approvers)
      .filter((b) => b.approverLevel !== level)
      .map((b) => b.userInfo.id);

    return allUsers.flatMap((user) =>
      usersInOtherLevel.includes(user?.id)
        ? []
        : [{ ...user, label: user?.name }]
    );
  };

  return (
    <div className="flex flex-col">
      {!editState &&
      isCompany &&
      ![
        MODULES.PHYSICAL_CARD,
        MODULES.PAYROLL,
        POLICY_GROUP_MAPPING.expenseReview,
      ].includes(approvalPolicyModule) ? (
        <div className="flex items-center w-full gap-5 mt-9">
          <AppliedCountCards
            departmentCount={curtPolicy?.linkedDepartments?.total || 0}
            projectCount={curtPolicy?.linkedProjects?.total || 0}
          />
        </div>
      ) : null}

      {editState && projectOrDepartmentId ? (
        <Note
          titleText="settings.spendControls.customPolicy.customisePolicyTitle"
          descriptionText="settings.spendControls.customPolicy.customisePolicyDesc"
          backgroundColorClass="bg-warning-50"
          borderColorClass="border border-warning-300 border-solid"
          externalDivClasses="mt-8"
        />
      ) : null}

      {approvalPolicyModule !== MODULES.PHYSICAL_CARD ? (
        <div className="px-4 py-6 mt-6 rounded-md bg-neutral-50">
          {policies ? <RangeBar data={getRangeData()} /> : null}
        </div>
      ) : null}

      {approvalPolicyModule !== MODULES.PHYSICAL_CARD ? (
        <div className="flex flex-col my-6 gap-0.5">
          <span className="text-lg font-medium">
            <Text
              translationKey={
                editState
                  ? "common.setRanges"
                  : "settings.spendControls.approvalPolicy.approvalWorkFlow.rangesTitle"
              }
            />
          </span>
          <span className="text-sm text-neutral-500">
            <Text translationKey="settings.spendControls.approvalPolicy.approvalWorkFlow.rangesDescription" />
          </span>
        </div>
      ) : null}

      {state === "edit"
        ? policies?.map((policy, index) => {
            return policy._destroy ? null : (
              <div
                className="p-3 mt-4 border rounded-lg border-neutral-200"
                key={`edit-policy-${policy.id}-${index}`}
              >
                {approvalPolicyModule !== MODULES.PHYSICAL_CARD ? (
                  <div className="flex px-2 my-6">
                    <div className={index !== 0 ? "w-11/12" : "w-full"}>
                      <Input
                        type="number"
                        showNumberInvalidError
                        value={
                          approvalAmountInput && approvalAmountInput[index]
                        }
                        label="settings.spendControls.approvalPolicy.approvalWorkFlow.amountCondition.label"
                        placeholder="settings.spendControls.approvalPolicy.approvalWorkFlow.amountCondition.placeholder"
                        disabled={policy.amountFrom === 0}
                        onChange={(e) => {
                          handleAmountChange(e.target.value, index);
                        }}
                        error={`${
                          index !== 0 &&
                          policy?.amountFrom <= policies[index - 1]?.amountFrom
                            ? "errorMessage.lessThanPreviousRange"
                            : ""
                        }`}
                      />
                    </div>
                    {index !== 0 ? (
                      <div className="flex justify-end w-1/12">
                        <Icon
                          name="Delete"
                          className="w-4 h-4 cursor-pointer text-neutral-300 hover:text-danger"
                          handleClick={() =>
                            handleDeletePolicy(index, policy.id)
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="p-4 rounded-md bg-neutral-50">
                  {Object.keys(policy.approvers)?.length
                    ? orderedKeys(policy.approvers)?.map((level, idx) => (
                        <div
                          className="flex flex-col gap-2 px-2 mb-4"
                          key={`edit-approval-level-${idx}-${level}`}
                        >
                          <div className="flex items-center justify-between gap-2 my-3">
                            <div className="flex">
                              <div className="flex items-center justify-center bg-white rounded-full w-7 h-7">
                                <span className="approver-index-pill">
                                  {idx + 1}
                                </span>
                              </div>
                              <div>
                                <span className="text-sm font-medium text-neutral-800">
                                  {layerLabel(policy?.approvers[level].type)}
                                </span>
                              </div>
                            </div>
                            <Icon
                              name="Delete"
                              className="w-4 h-4 cursor-pointer text-neutral-300 hover:text-danger"
                              handleClick={() =>
                                handleDeletePeople(index, level)
                              }
                            />
                          </div>

                          {policy?.approvers[level].type ? (
                            <CheckboxDropdown
                              name={`edit-approval-level-${idx}-${level}`}
                              dropdownLabelFastView={
                                approvalPolicyModule === PAYROLL
                                  ? ""
                                  : "User groups: "
                              }
                              dropdownLabelFlatView="All users"
                              groupingFunction={
                                approvalPolicyModule === PAYROLL
                                  ? () => {}
                                  : () =>
                                      userGroupingFunction(
                                        policy?.approvers,
                                        level
                                      )
                              }
                              mode={
                                isNoifyLayer(policy?.approvers[level].type)
                                  ? ""
                                  : "grouped"
                              }
                              label={
                                LAYER_TYPE_LABEL[policy?.approvers[level].type]
                              }
                              selectedIdsArray={policy?.approvers[
                                level
                              ].approvers
                                ?.filter((approverInfo) =>
                                  checkUser(approverInfo)
                                )
                                .map(
                                  (approverInfo) => approverInfo.userInfo.id
                                )}
                              selectedGroupsArray={
                                approvalPolicyModule === PAYROLL
                                  ? null
                                  : checkAdminManager(
                                      policy?.approvers[level].approvers
                                    )
                              }
                              toggleCheckboxHandler={({
                                id = null,
                                value,
                                groupKey = null,
                                toggleMode = "",
                                setSelectedStuff,
                                fromPill = false,
                              }) =>
                                toggleCheckboxHandler(
                                  {
                                    id,
                                    value,
                                    groupKey,
                                    toggleMode,
                                    setSelectedStuff,
                                    fromPill,
                                  },
                                  policy,
                                  level,
                                  policy?.approvers[level].type
                                )
                              }
                              handleSubmit={(val) => {
                                handleSelectedPeople(
                                  val,
                                  policy,
                                  level,
                                  policy?.approvers[level].type
                                );
                              }}
                              triggerComponent={PillsUpperSection}
                              options={getCurrentUsersOptions(policy, level)}
                              headerLabelsMap={{
                                [USER_ROLE.Admin]: {
                                  label: ROLE_TITLE.ADMIN,
                                  id: 0,
                                },
                                [USER_ROLE.DEPARTMENT_MANAGER]: {
                                  label: ROLE_TITLE.DEPARTMENT_MANAGER,
                                  id: -1,
                                },
                              }}
                              isLoading={!allUsers}
                              loader={<Loader />}
                              getHeaderNodeUI={getHeaderNodeUI}
                              supportExplicitControls
                              forciblyUpdateGroupedOptionsUI={
                                approvalDataChangesCount
                              }
                            />
                          ) : null}
                        </div>
                      ))
                    : null}
                  {Object.keys(policy?.approvers).length === 0 ? (
                    <div className="flex items-center mb-4 text-success-600">
                      <Icon name="CircleCheck" />
                      <Text
                        translationKey="common.autoApproved"
                        classes="ml-2"
                      />
                    </div>
                  ) : null}
                  {checkLayerButton(policy?.approvers) ? (
                    <AddLayerPopup
                      id={policy.id}
                      policyIndex={index}
                      removeItems={removeLayerItem(policy)}
                      options={layerOptions(policy?.approvers)}
                    />
                  ) : null}
                </div>
              </div>
            );
          })
        : null}
      {state !== "edit"
        ? policies?.map((policy, i) => {
            return (
              <div
                className="p-3 mt-4 border rounded-lg border-neutral-200"
                key={`policy-${policy.id}-${i}`}
              >
                {approvalPolicyModule !== MODULES.PHYSICAL_CARD ? (
                  <div className="px-4 py-6 rounded-md bg-neutral-50">
                    <RangeBar
                      from={policy.amountFrom}
                      to={
                        policy.amountTo ||
                        (policies[i + 1]?.amountFrom ?? 0) - 1
                      }
                      data={getRangeData()}
                    />
                  </div>
                ) : null}

                {Object.keys(policy?.approvers).length === 0 ? (
                  <div className="flex items-center my-4 text-success-600">
                    <Icon name="CircleCheck" />
                    <Text translationKey="common.autoApproved" classes="ml-2" />
                  </div>
                ) : (
                  orderedKeys(policy?.approvers)?.map((approver, idx) => {
                    return (
                      <div
                        className="flex flex-col gap-2 px-2 my-4"
                        key={`approval-level-${idx}-${approver}`}
                      >
                        <div className="flex items-center gap-2 mt-3">
                          <div className="flex items-center justify-center rounded-full bg-neutral-100 w-7 h-7">
                            <span className="approver-index-pill">
                              {idx + 1}
                            </span>
                          </div>
                          <div>
                            <span className="text-sm font-medium text-neutral-500">
                              {layerLabel(policy?.approvers[approver].type)}
                            </span>
                          </div>
                        </div>
                        {policy.approvers[approver].approvers?.map((users) => {
                          return (
                            <div
                              className="flex items-center justify-between w-full px-2 mt-2 text-sm"
                              key={users?.userInfo?.id}
                            >
                              <div className="flex w-1/2">
                                <ProfileWidget
                                  name={users?.userInfo?.displayName}
                                  avatarColor={users?.userInfo?.colorCode}
                                  img={users?.userInfo?.avatarUrl}
                                  avatarSize="xs"
                                  textClasses="font-light"
                                />
                              </div>
                              {users?.userInfo?.departmentName ||
                              users?.userInfo?.locationName ? (
                                <span className="flex justify-end w-1/2 text-right text-neutral-500">
                                  {users?.userInfo?.departmentName} •{" "}
                                  {users?.userInfo?.locationName}
                                </span>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                )}
              </div>
            );
          })
        : null}

      {state === "edit" &&
      approvalPolicyModule !== MODULES.PHYSICAL_CARD &&
      parseFloat(maxPoliciesInPolicyGroup) > policies?.length ? (
        <div className="mt-4">
          <Button
            label="settings.spendControls.approvalPolicy.approvalWorkFlow.addNewRange"
            variant="tertiary"
            preIcon="Plus"
            classes="w-18 px-3 h-10 text-sm font-medium"
            onClick={handleAddNewRange}
          />
        </div>
      ) : null}
    </div>
  );
}

ApprovalWorkflow.propTypes = {
  type: PropTypes.string,
  state: PropTypes.string,
};
