import PropTypes from "prop-types";

import parse from "html-react-parser";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function Toast({
  variant = "success",
  title,
  titleTranslationProp,
  description,
  descriptionTranslationProp,
  link,
  outerSectionClasses,
}) {
  const variantIconMap = {
    success: "Done",
    danger: "Close",
    warning: "Info",
  };

  return (
    <div
      className={`border flex items-center bg-white justify-between relative py-4 px-5 min-w-[500px] min-h-12 rounded-xl toast--${variant} shadow-md ${outerSectionClasses}`}
    >
      <div className="flex items-center">
        <div
          className={`toast-icon--${variant} rounded-full flex items-center justify-center text-white `}
        >
          <Icon
            name={variantIconMap[variant]}
            className="w-5 h-5 m-5 font-bold rounded-full "
          />
        </div>

        <div className="flex flex-col px-4">
          <span className="text-base font-bold first-letter:uppercase text-neutral-800">
            {title ? (
              <Text
                translationKey={title}
                translationProps={titleTranslationProp}
              />
            ) : (
              variant
            )}
          </span>
          {description && !description.includes("</") ? (
            <Text
              classes="text-sm font-medium text-neutral-500"
              translationKey={description}
              translationProps={descriptionTranslationProp}
            />
          ) : (
            <span className="text-sm font-medium text-neutral-500">
              {description && typeof description === "string"
                ? parse(description)
                : ""}
            </span>
          )}
          {link}
        </div>
      </div>

      <div className="pt-1 ml-6 cursor-pointer">
        <Icon name="Close" className="w-5 h-5 text-neutral-500" />
      </div>
    </div>
  );
}

Toast.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  titleTranslationProp: PropTypes.object,
  description: PropTypes.string,
  descriptionTranslationProp: PropTypes.object,
  link: PropTypes.node,
  outerSectionClasses: PropTypes.string,
};
