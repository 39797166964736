import PropsTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import { dateToString } from "@/utils/common";

export default function MissingDetailsCard({ data }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col mb-6 border rounded-lg border-neutral-200">
      <div className="flex items-center justify-between p-4 border-b rounded-t-lg owner bg-neutral-50 border-neutral-100">
        <ProfileWidget name={data.name} img={data.avatarUrl} />
        <button className="border border-neutral-300 text-primary-500 text-xs rounded-[6px] px-6 py-2 font-bold">
          <span className="flex items-center">
            <Icon name="Remind" className="mr-2" />
            {`${t("misc.businessMissingDetails.remind")} ${data.name}`}
          </span>
        </button>
      </div>
      {data.expenses.map((expense) => (
        <div key={`missing-detail-card-expense-${expense.id}`}>
          <div className="flex items-center justify-between p-4 ">
            <ProfileWidget
              name={expense.name}
              img={expense.avatarUrl}
              avatarSize="sm"
            >
              <div className="flex items-center gap-2 mt-1 text-xs font-medium">
                <span className=" text-danger-500">
                  {`${expense.missingDetail.count} ${t("missingDetail")}`}
                </span>
                <div className="w-1 h-1 rounded-full bg-neutral-300" />
                <span className="text-neutral-500">
                  {dateToString(expense.date)}
                </span>
              </div>
            </ProfileWidget>
            <span className="font-medium text-neutral-800">
              {expense.amount} {expense.currency}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

MissingDetailsCard.propTypes = {
  data: PropsTypes.object,
};
