import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { bulkUploadCountSelector } from "@/store/selectors/reimbursement";

import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";
import { CREATE_REIMBURSEMENT_TYPE } from "@/utils/constants/reimbursement";

export default function MerchantTypeCell({ val }) {
  const count = useSelector(bulkUploadCountSelector);

  const merchantNameNotFound = !val?.merchant?.name;

  return val?.bulkProcessing ? (
    <div className="flex items-center gap-2 font-semibold text-warning-600">
      <Icon name="Schedule" />
      <Text
        translationKey={
          count > 1
            ? "myVolopay.reimbursements.drafts.multipleProcessingClaim"
            : "myVolopay.reimbursements.drafts.processingClaim"
        }
        translationProps={{ count }}
      />
    </div>
  ) : (
    <div
      onClick={
        val?.handleRowClick ? () => val?.handleRowClick(val?.id) : () => {}
      }
      className="flex items-center gap-3 text-neutral-800"
    >
      {val?.showCheckbox ? (
        <Checkbox
          disabled={val?.disabled ?? false}
          checked={val?.checkedValue ?? false}
          onClickHandler={val?.onCheckboxClick ?? (() => {})}
        />
      ) : null}
      <ProfileWidget
        iconComponent={
          val?.type === CREATE_REIMBURSEMENT_TYPE?.mileage?.value ? (
            <Icon
              name="Location"
              className="flex items-center justify-center p-2 text-sm border-2 rounded-full w-9 h-9 bg-success-50 text-success-500 border-success-100"
            />
          ) : merchantNameNotFound ? (
            <div className="rounded-full w-9 h-9 bg-danger-200" />
          ) : null
        }
        img={
          val?.type !== CREATE_REIMBURSEMENT_TYPE?.mileage?.value
            ? val?.merchant?.avatarUrl
            : null
        }
        textClasses={
          merchantNameNotFound &&
          val?.type === CREATE_REIMBURSEMENT_TYPE?.outOfPocket?.value
            ? "text-neutral-400"
            : "font-semibold"
        }
        tooltipClasses="w-17"
        name={
          val?.type === CREATE_REIMBURSEMENT_TYPE?.mileage?.value
            ? "reimbursement.createReimbursement.kms"
            : merchantNameNotFound
              ? "reimbursement.createReimbursement.merchantNotFound"
              : val?.merchant?.name
        }
        nameTranslationProps={{ kms: val?.distanceTravelled || 0 }}
        tooltipDirection="right"
        id={val?.id}
      >
        {val?.duplicateReimbursementId ? (
          <div className="flex items-center gap-1 font-normal -ml-1.5">
            <TextWithTooltipIcon
              tooltipClasses="text-neutral-800 font-sm"
              iconClasses="text-warning-600"
              tooltipText="reimbursement.createReimbursement.duplicateClaimTitle"
            />
            <Text
              translationKey="billPay.bill.common.duplicateBillText"
              classes="text-xs text-warning-600 "
            />
          </div>
        ) : null}
      </ProfileWidget>
    </div>
  );
}

MerchantTypeCell.propTypes = {
  val: PropTypes.object,
};
