import PropTypes from "prop-types";
import { useState } from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { capitalizeFirstLetter } from "@/utils/common";

export default function CustomFieldCard({
  id,
  name,
  fieldType,
  modules,
  options,
  editCustomField,
}) {
  const [showList, setShowList] = useState(false);

  const toggleShowList = () => {
    setShowList((prev) => !prev);
  };

  return (
    <div className="flex flex-col p-4 mb-4 w-full border border-neutral-300 rounded-lg">
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <span className="text-md">{name}</span>
            <span className="text-sm text-neutral-400">
              <Text
                translationKey="accounting.settings.customFields.fieldTypeVisibleFor"
                translationProps={{ Type: capitalizeFirstLetter(fieldType) }}
              />
              {modules.map((module, index) => (
                <span key={index}>{`${module} `}</span>
              ))}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <div onClick={() => editCustomField(id)} className="cursor-pointer">
              <Icon name="Edit" />
            </div>
            {/* {fieldType === "list" ? (
              <div onClick={() => toggleShowList()} className="cursor-pointer">
                <Icon name={showList ? "ChevronUp" : "ChevronDown"} />
              </div>
            ) : null} */}
          </div>
        </div>
        {showList && (
          <div className="flex flex-col gap-2 mt-2">
            <span className="text-sm text-neutral-500">
              <Text translationKey="accounting.settings.customFields.listOfOptionFields" />
              :
            </span>
            {options.map((option, index) => (
              <span key={index} className="font-medium">
                {option}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

CustomFieldCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  fieldType: PropTypes.string,
  modules: PropTypes.array,
  options: PropTypes.array,
  editCustomField: PropTypes.func,
};
