import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { onboardingSectionSelector } from "@/store/selectors/onboarding";
import { selectRoles } from "@/store/selectors/user";

import Button from "@/components/core/Button";

import CommonHeaderComponent from "@/components/Onboarding/OnboardingForm/CommonHeaderComponent";
import AadharNotfier from "@/components/Onboarding/PineLabs/AadharNotfier";

import { ROLE_TITLE } from "@/constants/company";
import {
  ONBOARDING_SLIDER_PARAMS,
  ONBOARDING_STEPS,
} from "@/constants/onboarding";

export default function LivquikIdentityDetails({
  onboardingFormComponent,
  disabled,
}) {
  const UI_TYPE = {
    MAIN_PAGE: "main_page",
    ALTERNATE_PAGE: "aadhar",
  };
  const loggedInUserRoles = useSelector(selectRoles);
  const hasExternalAccountantRole = loggedInUserRoles?.find(
    (role) => role === ROLE_TITLE.EXTERNAL_ACCOUNTANT
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [toggleUi, setToggleUi] = useState(
    hasExternalAccountantRole ? UI_TYPE.MAIN_PAGE : UI_TYPE.ALTERNATE_PAGE
  );
  const [formId, setFormId] = useState("nonGenericForm");

  const onboardingFormResponse = useSelector(onboardingSectionSelector);
  const onboardingSection = onboardingFormResponse?.sections[0];
  const { fields, ...commonHeaderProps } = onboardingSection;

  function handleClick() {
    if (toggleUi === UI_TYPE.ALTERNATE_PAGE) {
      setToggleUi(UI_TYPE.MAIN_PAGE);
    }
  }

  useEffect(() => {
    searchParams.set(
      ONBOARDING_SLIDER_PARAMS,
      ONBOARDING_STEPS.ADDRESS_DETAILS
    );

    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    setFormId(
      UI_TYPE.ALTERNATE_PAGE === toggleUi ? "nonGenericForm" : "onboardingFrom"
    );
  }, [toggleUi]);

  return (
    <div>
      {toggleUi !== UI_TYPE.ALTERNATE_PAGE ? (
        onboardingFormComponent
      ) : (
        <div className="slider-content-core py-9">
          <CommonHeaderComponent headerProps={commonHeaderProps} />
          <AadharNotfier />
        </div>
      )}

      <div className="absolute bottom-0 flex items-center justify-between gap-6 p-4 slider-footer h-14">
        <Button
          onClick={toggleUi === UI_TYPE.MAIN_PAGE ? null : handleClick}
          form={formId}
          label="Continue"
          disabled={toggleUi === UI_TYPE.MAIN_PAGE ? disabled : false}
          classes="w-84 h-11  items-self-end justify-self-end ml-auto"
          btnType={toggleUi === UI_TYPE.MAIN_PAGE ? "submit" : null}
        />
      </div>
    </div>
  );
}

LivquikIdentityDetails.propTypes = {
  onboardingFormComponent: PropTypes.node,
  disabled: PropTypes.bool,
};
