import { PropTypes } from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import { amountToCurrency, numberFormatter } from "@/utils/common";

import { DECLINE_DETAILS_KEYS } from "@/constants/expense";
import { FAILURE_DETAILS_KEYS } from "@/constants/qrPayments";

export default function DeclineDetails({
  isFetching,
  declineOrFailureData,
  currency,
  page,
}) {
  const EXPENSE_DECLINE_CONFIG = [
    {
      title: "expenses.declines.declineDetails.totalAmount",
      isAmount: true,
      key: DECLINE_DETAILS_KEYS.TOTAL_AMOUNT,
    },
    {
      title: "expenses.declines.declineDetails.totalDeclines",
      isAmount: false,
      key: DECLINE_DETAILS_KEYS.TOTAL_DECLINE,
    },
    {
      title: "expenses.declines.declineDetails.mostCommonReason",
      isAmount: false,
      key: DECLINE_DETAILS_KEYS.MOST_COMMON_REASON,
      iconClass: "text-danger-400",
      icon: "Info",
    },
  ];
  const QRPAY_FAILURE_CONFIG = [
    {
      title: "expenses.declines.declineDetails.totalAmount",
      isAmount: true,
      key: FAILURE_DETAILS_KEYS.TOTAL_AMOUNT,
    },
    {
      title: "QRPay.failures.totalFailures",
      isAmount: false,
      key: FAILURE_DETAILS_KEYS.TOTAL_FAILURES,
    },
    {
      title: "QRPay.failures.mostCommonReason",
      isAmount: false,
      key: FAILURE_DETAILS_KEYS.MOST_COMMON_REASON,
      icon: "Error",
      iconClass: "text-danger-400",
    },
  ];
  const currentConfig =
    page === QRPAY_EXPENSES_CONTEXT.EXPENSES
      ? EXPENSE_DECLINE_CONFIG
      : QRPAY_FAILURE_CONFIG;

  return (
    <>
      {isFetching && <DeclineDetailsLoader />}
      {!isFetching && declineOrFailureData ? (
        <div className="flex gap-6">
          {currentConfig.map((val, index) => (
            <div
              key={`expense-overview-details-${index}`}
              className={`${
                index === 2 ? "w-120" : "min-w-17"
              } cursor-pointer relative card-wrapper rounded-xl `}
            >
              <div>
                <Text
                  color="neutral-500"
                  classes="text-sm  font-medium"
                  translationKey={val.title}
                />
                <div className="">
                  <div
                    className={`${
                      val?.key === FAILURE_DETAILS_KEYS.MOST_COMMON_REASON
                        ? "text-sm mt-1"
                        : "text-lg"
                    } font-medium flex items-center justify-between text-neutral-800`}
                  >
                    {val.isAmount
                      ? amountToCurrency(
                          parseInt(declineOrFailureData[val.key], 10),
                          currency
                        )
                      : (numberFormatter(declineOrFailureData[val.key]) ?? "-")}

                    {val?.icon ? (
                      <div className={val?.iconWrapper}>
                        <Icon className={val?.iconClass} name={val?.icon} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}

DeclineDetails.propTypes = {
  isFetching: PropTypes.bool,
  declineOrFailureData: PropTypes.object,
  currency: PropTypes.string,
  page: PropTypes.string,
};

function DeclineDetailsLoader() {
  return (
    <div className="flex gap-4">
      {[...new Array(3).fill("0")].map((val, index) => (
        <div
          key={`expense-overview-loader-${index}`}
          className="flex flex-col justify-center card-wrapper rounded-xl"
        >
          <LoaderSkeleton size={index === 2 ? [10, 400] : [10, 100]} />
          <LoaderSkeleton />
        </div>
      ))}
    </div>
  );
}
