import PropTypes from "prop-types";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import usePagination from "@/hooks/usePagination";

import {
  resetSliderExpensesListAndPagination,
  retrieveExpenseSliderTransactionList,
  setSelectedExpenseSliderTab,
  setSliderOnResetBoolean,
} from "@/store/reducers/expense";
import {
  fetchQrPayments,
  resetQrPaymentsListAndPagination,
} from "@/store/reducers/qr-payments";

import { selectedCardSelector } from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";
import {
  expensesSliderHasMoreSelector,
  expensesSliderListIsFetchingSelector,
  expensesSliderListSelector,
  expensesSliderOnResetBooleanSelector,
  expensesSliderSelectedTabSelector,
} from "@/store/selectors/expense";
import {
  isFetchingQrPayments,
  qrPaymentsHasMoreSelector,
  qrPaymentsListSelector,
} from "@/store/selectors/qr-payments";

import Alert from "@/components/core/Alert";
import Tabs from "@/components/core/Tabs";

// component
import CardDetailMask from "@/components/Cards/Sliders/CardSlider/OverviewTab/CardDetailMask";
import SpendOverview from "@/components/Cards/Sliders/CardSlider/OverviewTab/SpendOverview";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import { groupByDate } from "@/utils/common";

import { CARDS_ALL_SLIDER_MENU_KEYS } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import CardSliderOverviewCards from "./CardSliderOverviewCards";
import ExpenseTableSlider from "./ExpenseTableSlider";
// styles
import "./styles.scss";

const SELECTED_EXPENSES_STATS_TAB = [
  {
    name: "cards.cardDetails.overview.policyDetails.expenses.recent",
    count: null,
    key: 1,
    dispatchFuncParams: {
      sort_column: "expense_date",
      sort_direction: "desc",
      transaction_type: ["purchase", "purchase_reversal"],
    },
  },
  {
    name: "cards.cardDetails.overview.policyDetails.expenses.largest",
    count: null,
    key: 2,
    dispatchFuncParams: {
      sort_column: "amount",
      sort_direction: "desc",
      transaction_type: ["purchase", "purchase_reversal"],
    },
  },
];

export default function OverviewTab({ page }) {
  const card = useSelector(selectedCardSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTabSecond = useSelector(expensesSliderSelectedTabSelector);
  const onResetSliderExpensesListBoolean = useSelector(
    expensesSliderOnResetBooleanSelector
  );
  const showSwitchButton = card?.allowedCtas?.find(
    (sliderCta) => sliderCta === CARDS_ALL_SLIDER_MENU_KEYS.LINKED_TO
  );
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;
  const expenseSelectors = {
    transaction: useSelector(expensesSliderListSelector),
    isFetching: useSelector(expensesSliderListIsFetchingSelector),
    hasMore: useSelector(expensesSliderHasMoreSelector),
    date: "expenseDate",
  };
  const qrpaySelectors = {
    transaction: useSelector(qrPaymentsListSelector),
    isFetching: useSelector(isFetchingQrPayments),
    hasMore: useSelector(qrPaymentsHasMoreSelector),
    date: "paymentDate",
  };

  const currentSelectors = isExpensesPage ? expenseSelectors : qrpaySelectors;

  const categories = useSelector(categoriesListSelector);
  const dispatch = useDispatch();

  const showCardLimitBtn =
    card?.allowedCtas?.findIndex(
      (cta) => cta === CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD
    ) >= 0;

  const loadMore = () => {
    if (card.id) {
      const fetchAction = isExpensesPage
        ? retrieveExpenseSliderTransactionList
        : fetchQrPayments;

      dispatch(
        fetchAction({
          page: pageNum,
          limit: 5,
          card: card.id,
          ...selectedTabSecond?.dispatchFuncParams,
        })
      );
    }
  };

  const onReset = () => {
    if (onResetSliderExpensesListBoolean) {
      const resetTransaction = isExpensesPage
        ? resetSliderExpensesListAndPagination
        : resetQrPaymentsListAndPagination;
      dispatch(resetTransaction());
    } else {
      dispatch(setSliderOnResetBoolean(true));
    }
  };

  const groupedTransaction = useMemo(
    () => groupByDate(currentSelectors?.transaction, currentSelectors?.date),
    [currentSelectors?.transaction]
  );

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore: currentSelectors?.hasMore,
    loadMore,
    onReset,
    filterOptions: { ...selectedTabSecond },
  });

  return (
    <>
      {card?.reissuedCard ? (
        <div className="mb-8">
          <Alert
            variant="warning"
            title="cards.cardSlider.reissuedCard.sliderAlertTitle"
            description="cards.cardSlider.reissuedCard.sliderAlertDesc"
            descriptionClasses="text-neutral-800"
            primaryAction={{
              callback: () => {
                searchParams.append(
                  SLIDERS_SEARCH_PARAMS.cards.activation,
                  card?.id
                );

                setSearchParams(searchParams);
              },
              label: "company.people.cardSnapshot.activateNow",
            }}
          />
        </div>
      ) : null}

      <SpendOverview
        card={card}
        showSwitchButton={showSwitchButton}
        showCardLimitBtn={showCardLimitBtn}
      />
      {isExpensesPage ? (
        <CardDetailMask
          slug={card?.slug}
          status={card.status}
          cardId={card?.id}
          card={card}
        />
      ) : (
        ""
      )}

      <CardSliderOverviewCards />

      <Tabs
        items={SELECTED_EXPENSES_STATS_TAB}
        selectedTab={selectedTabSecond?.key}
        setCurrentTab={(tab) => {
          dispatch(setSelectedExpenseSliderTab(tab));
        }}
        mode
      />
      <div className="mt-5 mb-9">
        <ExpenseTableSlider
          hasMore={currentSelectors?.hasMore}
          isFetching={currentSelectors?.isFetching}
          show
          groupedExpenses={groupedTransaction}
          categories={categories}
          handleShowMore={() => setPageNum((prev) => prev + 1)}
        />
      </div>
    </>
  );
}

OverviewTab.propTypes = {
  page: PropTypes.string,
};
