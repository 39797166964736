import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { fetchAllEntities } from "@/store/reducers/app";
import { setLoggingUserCred } from "@/store/reducers/user";

import { isFetchingAccountsSelector } from "@/store/selectors/app";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import { useForm } from "@/utils/useForm";
import { REGEX_VALUE } from "@/utils/constantUseForm";

import SelectEntity from "./SelectLoginEntity";

export default function AppLoginForm({ showEntities, setShowEntities }) {
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingAccountsSelector);

  const initialValue = {
    email: {
      value: currentUser?.email || "",
      validate: {
        required: true,
        regex: REGEX_VALUE.email,
      },
    },
  };

  const handleShowEntites = () => {
    setShowEntities(true);
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, (e) => {
      dispatch(
        fetchAllEntities({
          email: currentUser?.email,
          onSuccess: handleShowEntites,
        })
      );
    });

  return !showEntities ? (
    <form className="flex flex-col pt-6 gap-9 pb-[60%]" onSubmit={handleSubmit}>
      <Input
        value={currentUser?.email || values?.email}
        placeholder="login.email"
        name="email"
        error={errors?.email}
        label="login.email"
        onChange={(e) => {
          dispatch(setLoggingUserCred({ email: e.target.value }));
          handleChange(e);
        }}
      />

      <Button
        btnType="submit"
        name="formSubmit"
        showLoader={isFetching}
        variant="primary"
        disabled={isFormButtonDisabled}
        classes="w-full"
        label="login.title"
      />
    </form>
  ) : (
    <SelectEntity />
  );
}

AppLoginForm.propTypes = {
  showEntities: PropTypes.bool,
  setShowEntities: PropTypes.func,
};
