import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  defaultCurrencySelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";

import Icon from "@/components/core/Icon";
import { amountToCurrency } from "@/utils/common";

export default function AmountCell({ val }) {
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  const isSameCurrency =
    val?.unsettledAmount?.currency === defaultCurrency ||
    val?.claimAmount?.currency === defaultCurrency ||
    val?.fromAmount?.currency === defaultCurrency ||
    val?.totalAmount?.currency === defaultCurrency;

  // TECH_DEBT: too much fallback here, add comment about what attribute does what
  const amount = (
    val?.unsettledAmount ||
    val?.fromAmount ||
    val?.claimAmount ||
    val?.totalAmount
  )?.value;

  const currency = (
    val?.unsettledAmount ||
    val?.fromAmount ||
    val?.claimAmount ||
    val?.totalAmount
  )?.currency;

  return (
    <div className="text-right">
      {val?.bulkProcessing ? null : (
        <>
          {amount ? (
            reportEnabled ? (
              <p>{amountToCurrency(val?.amount || amount, currency)}</p>
            ) : (
              <p>{amountToCurrency(val?.amountToBePaid, defaultCurrency)}</p>
            )
          ) : (
            "-"
          )}
          {isSameCurrency || !amount ? null : (
            <div className="flex items-center justify-end gap-1 text-xs font-medium text-neutral-500">
              <Icon name="World" className="w-3 h-3" />
              <p className="font-normal ">
                {amountToCurrency(amount, currency)}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

AmountCell.propTypes = {
  val: PropTypes.object,
};
