import { ACCOUNTING_SOFTWARES } from "@/constants/accounting";

export const INTEGRATION_TALLY_PLUGIN_URL =
  "https://volo-videos.s3.ap-southeast-1.amazonaws.com/tally_plugins/tally_plugin_v10.tcp";

export const INTEGRATION_TALLY_TUTORIAL_VIDEO_URL =
  "https://volo-videos.s3.ap-southeast-1.amazonaws.com/Tally_Setup_Video.mp4";

export const INTEGRATION_UCSV_CHART_OF_ACCOUNGTS_TEMPLATE_URL =
  "https://volo-videos.s3.ap-southeast-1.amazonaws.com/Chart+of+Accounts+Import+Format.csv";

export const INTEGRATION_UCSV_HELP_URL =
  "https://help.volopay.co/en/articles/6600998-universal-csv-connection-and-setup";

export const INTEGRATION_UCSV_TAX_CODE_TEMPLATE_URL =
  "https://volo-videos.s3.ap-southeast-1.amazonaws.com/Tax+Code+Import+Format.csv";

export const INTEGRATION_UCSV_VENDORS_TEMPLATE_URL =
  "https://volo-videos.s3.ap-southeast-1.amazonaws.com/Vendor+Import+Format.csv";

export const INTEGRATION_NETSUITE_CONNECT_HELP_URL =
  "https://help.volopay.co/en/articles/5275367-netsuite-setup-in-volopay";

export const FILE_IMPORT_VARIANTS = {
  CHART_OF_ACCOUNTS: "chart_of_account",
  VENDORS: "vendor",
  TAXCODES: "tax",
};

export const FILE_IMPORT_VARIANTS_LABEL = {
  [FILE_IMPORT_VARIANTS.CHART_OF_ACCOUNTS]: "Category",
  [FILE_IMPORT_VARIANTS.VENDORS]: "Vendors",
  [FILE_IMPORT_VARIANTS.TAXCODES]: "Tax codes",
};

export const EXECUTION_STATUS = {
  SUCCESS: "success",
  FAILURE: "failure",
};

export const DOWNLOAD_MODES = {
  download: "download",
};

export const DOWNLOAD_PLUGIN_TOAST = {
  [DOWNLOAD_MODES.download]: {
    title: "Success",
    variant: "success",
    message: "Plugin downloaded successfully",
  },
};

export const UCSV_DOWNLOAD_TEMPLATE_TOAST = {
  [DOWNLOAD_MODES.download]: {
    title: "Success",
    variant: "success",
    message: "Template downloaded successfully",
  },
};

export const NETSUITE_SYNC_MODE = {
  JOURNAL: "journal",
  EXPENSE: "expense_report",
};

export const INTEGRATION_CONNECTION_TOAST = {
  [ACCOUNTING_SOFTWARES.TALLY]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Successfully connected to Tally",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message:
        "Error while connecting to Tally. Please contact Volopay support for assistance. ",
    },
  },
  [ACCOUNTING_SOFTWARES.XERO]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Successfully connected to Xero",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message:
        "Error while connecting to Xero. Please contact Volopay support for assistance. ",
    },
  },
  [ACCOUNTING_SOFTWARES.DESKERA]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Successfully connected to Deskera",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message:
        "Error while connecting to Deskera. Please contact Volopay support for assistance. ",
    },
  },
  [ACCOUNTING_SOFTWARES.UNIVERSAL_CSV]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Successfully connected to Universal CSV",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message:
        "Error while connecting to Universal CSV. Please contact Volopay support for assistance. ",
    },
  },
  [ACCOUNTING_SOFTWARES.QBO]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Successfully connected to Quickbooks",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message:
        "Error while connecting to Quickbooks. Please contact Volopay support for assistance. ",
    },
  },
  [ACCOUNTING_SOFTWARES.NETSUITE]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Successfully connected to Netsuite",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message:
        "Error while connecting to Netsuite. Please contact Volopay support for assistance. ",
    },
  },
  [ACCOUNTING_SOFTWARES.MYOB]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Successfully connected to MYOB",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message:
        "Error while connecting to MYOB. Please contact Volopay support for assistance. ",
    },
  },
  [ACCOUNTING_SOFTWARES.ZOHO]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Successfully connected to ZohoBooks",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message:
        "Error while connecting to ZohoBooks. Please contact Volopay support for assistance. ",
    },
  },
};

export const INTEGRATION_SYNC_TOAST = {
  [ACCOUNTING_SOFTWARES.NETSUITE]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Netsuite category synced successfully",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message:
        "Error while syncing to Netsuite. Please contact Volopay support for assistance. ",
    },
  },
};

export const INTEGRATION_SETTINGS_TOAST = {
  [ACCOUNTING_SOFTWARES.TALLY]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Tally integration settings updated",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message: "Tally integration settings updation failure",
    },
  },
  [ACCOUNTING_SOFTWARES.XERO]: {
    [EXECUTION_STATUS.SUCCESS]: {
      title: "Success",
      variant: "success",
      message: "Xero integration settings updated",
    },
    [EXECUTION_STATUS.FAILURE]: {
      title: "Failure",
      variant: "danger",
      message: "Xero integration settings updation failure",
    },
  },
};

export const INTEGRATION_TAGS_TYPE = {
  CATEGORIES: "categories",
  VENDORS: "vendors",
  TAXCODES: "tax codes",
  TRACKING_CATEGORIES: "tracking categories",
  TAGS: "tags",
};

export const INTEGRATION_SYNC_OPTIONS = {
  [INTEGRATION_TAGS_TYPE.CATEGORIES]: {
    id: 1,
    label: "Category",
    sync: false,
  },
  [INTEGRATION_TAGS_TYPE.VENDORS]: {
    id: 2,
    label: "Vendors",
    sync: false,
  },
  [INTEGRATION_TAGS_TYPE.TAXCODES]: {
    id: 3,
    label: "Tax codes",
    sync: false,
  },
  [INTEGRATION_TAGS_TYPE.TRACKING_CATEGORIES]: {
    id: 4,
    label: "Tracking categories",
    sync: false,
  },
};

export const NETSUITE_INPUT = {
  ACCOUNT_ID: "accountID",
  CONSUMER_KEY: "consumerKey",
  CONSUMER_SECRET: "consumerSecret",
  TOKEN_ID: "tokenID",
  TOKEN_SECRET: "tokenSecret",
  DEPLOY_VERSION: "deployVersion",
  SCRIPT_ID: "scriptID",
};
export const NETSUITE_INTEGRATIONS_INPUT_CONFIG = {
  [NETSUITE_INPUT.ACCOUNT_ID]: {
    label: "accounting.integrations.netsuite.input.netsuiteAccountId.label",
    placeholder:
      "accounting.integrations.netsuite.input.netsuiteAccountId.placeholder",
  },
  [NETSUITE_INPUT.CONSUMER_KEY]: {
    label: "accounting.integrations.netsuite.input.consumerKey.label",
    placeholder:
      "accounting.integrations.netsuite.input.consumerKey.placeholder",
  },
  [NETSUITE_INPUT.CONSUMER_SECRET]: {
    label: "accounting.integrations.netsuite.input.consumerSecret.label",
    placeholder:
      "accounting.integrations.netsuite.input.consumerSecret.placeholder",
  },
  [NETSUITE_INPUT.TOKEN_ID]: {
    label: "accounting.integrations.netsuite.input.tokenId.label",
    placeholder: "accounting.integrations.netsuite.input.tokenId.placeholder",
  },
  [NETSUITE_INPUT.TOKEN_SECRET]: {
    label: "accounting.integrations.netsuite.input.tokenSecret.label",
    placeholder:
      "accounting.integrations.netsuite.input.tokenSecret.placeholder",
  },
  [NETSUITE_INPUT.DEPLOY_VERSION]: {
    label: "accounting.integrations.netsuite.input.deployVersion.label",
    placeholder:
      "accounting.integrations.netsuite.input.deployVersion.placeholder",
  },
  [NETSUITE_INPUT.SCRIPT_ID]: {
    label: "accounting.integrations.netsuite.input.scriptId.label",
    placeholder: "accounting.integrations.netsuite.input.scriptId.placeholder",
  },
};

export const NETSUITE_INTEGRATIONS_HIDDEN_INPUT = ["consumer", "token"];

export const INTEGRATION_CURRENCY_TYPE = {
  SINGLE: "single",
  MULTI: "multi",
};

export const INTEGRATION_CURRENCY_OPTIONS = [
  {
    title: "accounting.integrations.currencyOptions.singleCurrency.title",
    description:
      "accounting.integrations.currencyOptions.singleCurrency.description",
    value: INTEGRATION_CURRENCY_TYPE.SINGLE,
  },
  {
    title: "accounting.integrations.currencyOptions.multiCurrency.title",
    description:
      "accounting.integrations.currencyOptions.multiCurrency.description",
    value: INTEGRATION_CURRENCY_TYPE.MULTI,
  },
];

export const INTEGRATION_SYNC_FREQUENCY_OPTIONS = [
  "none",
  "daily",
  "weekly",
  "monthly",
];

export const INTEGRATION_ACCOUNTING_FIELDS_TABLE_HEADERS = [
  "accounting.integrations.accountingFields.table.fieldName",
  "accounting.integrations.accountingFields.table.fieldType",
  "accounting.integrations.accountingFields.table.tags",
  "accounting.integrations.accountingFields.table.action",
];

export const INTEGRATION_ACCOUNTING_FIELDS_VENDORS_TABLE_HEADERS = [
  "accounting.integrations.accountingFields.table.name",
  "accounting.integrations.accountingFields.table.alias",
  "accounting.integrations.accountingFields.table.currency",
  "accounting.integrations.accountingFields.table.visibility",
];
export const INTEGRATION_ACCOUNTING_FIELDS_DEFAULT_TABLE_HEADERS = [
  "accounting.integrations.accountingFields.table.name",
  "accounting.integrations.accountingFields.table.alias",
  "accounting.integrations.accountingFields.table.visibility",
];

export const INTEGRATION_ACCOUNTING_FIELDS_CATEGORY_TABLE_HEADERS = [
  "accounting.integrations.accountingFields.table.code",
  "accounting.integrations.accountingFields.table.name",
  "accounting.integrations.accountingFields.table.alias",
  "accounting.integrations.accountingFields.table.type",
  "accounting.integrations.accountingFields.table.visibility",
];

export const INTEGRATION_ACCOUNTING_FIELDS_NON_CATEGORY_TABLE_HEADERS = [
  "accounting.integrations.accountingFields.table.taxCode",
  "accounting.integrations.accountingFields.table.alias",
  "accounting.integrations.accountingFields.table.taxRate",
  "accounting.integrations.accountingFields.table.visibility",
];
export const TAG_CATEGORY_TAX_CODE = "taxcode";
export const EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY = {
  DEFAULT: "default",
  VENDOR: "vendor",
  CATEGORY: "category",
  TAX_CODES: "taxCodes",
};
export const EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG = {
  ID: "id",
  NAME: "name",
  EDITABLE_ACCOUNTING: "editableAccounting",
  TYPE: "type",
  CURRENCY: "currency",
  ALIAS: "alias",
  TAX_RATE: "tax_rate",
  SWITCH: "switch",
};
export const EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG = {
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.CATEGORY]: [
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.ID,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.NAME,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.EDITABLE_ACCOUNTING,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.TYPE,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.SWITCH,
  ],
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.VENDOR]: [
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.NAME,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.EDITABLE_ACCOUNTING,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.CURRENCY,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.SWITCH,
  ],
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.TAX_CODES]: [
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.ALIAS,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.EDITABLE_ACCOUNTING,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.TAX_RATE,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.SWITCH,
  ],
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.DEFAULT]: [
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.NAME,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.EDITABLE_ACCOUNTING,
    EDIT_ACCOUNTING_SLIDER_COMPONENT_CONFIG.SWITCH,
  ],
};
export const EDIT_ACCOUNTING_FIELD_TABLE_HEADER_CONFIG = {
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.CATEGORY]:
    INTEGRATION_ACCOUNTING_FIELDS_CATEGORY_TABLE_HEADERS,
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.VENDOR]:
    INTEGRATION_ACCOUNTING_FIELDS_VENDORS_TABLE_HEADERS,
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.TAX_CODES]:
    INTEGRATION_ACCOUNTING_FIELDS_NON_CATEGORY_TABLE_HEADERS,
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.DEFAULT]:
    INTEGRATION_ACCOUNTING_FIELDS_DEFAULT_TABLE_HEADERS,
};
export const EDIT_ACCOUNTING_FIELD_COL_WIDTHS_CONFIG = {
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.CATEGORY]: [120, 200, 200, 120, 120],
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.VENDOR]: [243, 243, 120],
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.TAX_CODES]: [200, 250, 120, 120],
  [EDIT_ACCOUNTING_FIELD_SLIDER_CONFIG_KEY.DEFAULT]: [243, 243, 120],
};

export const INTEGRATION_DOMAIN_OPTIONS = ["com", "in", "eu", "com.eu"];

export const UCSV_UPDATE_MODE_OPTIONS = {
  OVERWRITE: "overwrite",
  MERGE: "merge",
};

export const UCSV_UPDATE_MODE_OPTIONS_CONFIG = [
  {
    mode: UCSV_UPDATE_MODE_OPTIONS.OVERWRITE,
    label: "accounting.integrations.labels.updateExistingData",
    toolTipText: "accounting.integrations.toolTipText.updateExistingData",
  },
  {
    mode: UCSV_UPDATE_MODE_OPTIONS.MERGE,
    label: "accounting.integrations.labels.mergeWithExistingData",
    toolTipText: "accounting.integrations.toolTipText.mergeWithExistingData",
  },
];

export const UCSV_UPDATE_OPTIONS_CONFIG = [
  { label: "Category", value: { template: "chart_of_account" } },
  { label: "Tax codes", value: { template: "tax" } },
  { label: "Vendors", value: { template: "vendor" } },
];
