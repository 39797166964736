import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchAndSelectAccountingTransaction,
  setSelectedAccountingTransaction,
  setUploadedReceipts,
  updateAccountingData,
} from "@/store/reducers/accounting_transactions";
import { deleteAttachment } from "@/store/reducers/app";
import { addComments } from "@/store/reducers/comments";
import {
  fetchShallowDepartments,
  fetchShallowLocations,
} from "@/store/reducers/company";
import {
  dismissSubmissionPolicy,
  downloadExpenseAttachment,
  remindExpense,
} from "@/store/reducers/expense";
import { fetchTags } from "@/store/reducers/tags";

import {
  isFetchingSelectedAccountingTransactionSelector,
  reloadSelectedTransactionSelector,
  selectedAccountingTransactionSelector,
} from "@/store/selectors/accounting_transactions";
import { accountingEnabledSelector } from "@/store/selectors/client";
import { commentsSelector } from "@/store/selectors/comments";
import {
  isFetchingExpenseActivitySelector,
  selectedExpenseIsApprovedSelector,
} from "@/store/selectors/expense";
import { userSelector } from "@/store/selectors/user";

import { tagsSelector } from "@/store/selectors/tags";
import Alert from "@/components/core/Alert";
import Tabs from "@/components/core/Tabs";

import AccountingSliderSubmitButton from "@/components/Accounting/Transactions/common/Slider/AccountingSliderSubmitButton";
import { accountingActionHandler } from "@/components/Accounting/Transactions/common/util";
import QRPayAndExpenseSliderFooter from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayFooter";
import { ExpenseAndQRPayHeaderJsx } from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayHeader";
import { capitalizeFirstLetter, createFileFormData } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";
import {
  DECLINED,
  DECLINE_CODES,
  DECLINE_SLIDER_ALERT,
  EXPENSE_TYPE,
  HISTORY,
  OVERVIEW,
  SUBMISSION_POLICY_STATUS_KEYS,
  TABS,
} from "@/constants/expense";
import AccountingTransactionCardsOverviewTab from "./AccountingTransactionCardsOverviewTab";

export default function AccountingTransactionCardsSlider({
  isAccountingSlider = true,
  setOnClose,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [files, setFiles] = useState([]);
  const [memo, setMemo] = useState("");

  const expense = useSelector(selectedAccountingTransactionSelector);
  const tags = useSelector(tagsSelector);
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const isFetchingExpense = useSelector(
    isFetchingSelectedAccountingTransactionSelector
  );
  const reloadExpense = useSelector(reloadSelectedTransactionSelector);
  const isFetching = useSelector(isFetchingExpenseActivitySelector);
  const isApproved = useSelector(selectedExpenseIsApprovedSelector);
  const commentsList = useSelector(commentsSelector);
  const currentUser = useSelector(userSelector);

  const splitExpense = expense?.lineItems || [];
  const submissionPolicy = expense?.submissionPolicy;

  const accountingId = parseInt(
    searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.cards.id),
    10
  );
  const expenseId = expense?.accountableId;
  const isDeclineExpense = expense?.transactionType === DECLINED;

  const fileUploadHandler = (latestFileArray) => {
    const filesToUpload = latestFileArray?.filter((item) => {
      return item instanceof File && item.type !== "image/svg+xml";
    });

    if (filesToUpload?.length === 0) return;

    const formPayload = createFileFormData(filesToUpload, "receipts[]");
    dispatch(
      updateAccountingData({
        accounting_id: accountingId,
        payload: formPayload,
        onSuccess: (data) => {
          dispatch(setUploadedReceipts({ receipts: data?.data }));
          setFiles(data?.data);
        },
      })
    );
  };

  const primaryAction = {
    handler: (index) => {
      if (files[index]?.id) {
        dispatch(
          deleteAttachment({
            id: files[index]?.id,
            onSuccess: () => {
              dispatch(
                setUploadedReceipts({
                  receipts: files.filter((item, idx) => idx !== index),
                })
              );
            },
          })
        );
      }
    },
    label: "misc.delete",
    icon: "Delete",
    iconClasses: "text-danger-600 bg-danger-50",
  };

  const secondaryAction = {
    handler: (index) => {
      const file = files[index];
      if (file instanceof File) return;
      dispatch(
        downloadExpenseAttachment({
          file,
          receiptId: file?.id,
        })
      );
    },
    label: "misc.download",
    icon: "Download",
    iconClasses: "!text-primary-500 !bg-primary-50",
  };

  // const initData = () => {
  //   dispatch(
  //     fetchExpenseSplit({
  //       expenseId,
  //     })
  //   );
  // };

  const handleUpdateExpense = (payload) => {
    dispatch(
      updateAccountingData({
        accounting_id: expense?.accountingId,
        payload: { note: memo },
      })
    );
  };

  const remindPeople = (key, expense_id) => {
    dispatch(remindExpense({ payload: { [key]: expense_id } }));
  };

  const dismissPolicy = (id) => {
    dispatch(
      dismissSubmissionPolicy({
        expenseId: id,
        onSuccess: () => {
          dispatch(
            setSelectedAccountingTransaction({
              ...expense,
              submissionPolicyStatus: SUBMISSION_POLICY_STATUS_KEYS.OVERRIDDEN,
              missingFields: {},
            })
          );
        },
      })
    );
  };

  const createNewComment = (val) => {
    dispatch(
      addComments({
        payload: {
          owner_type: capitalizeFirstLetter(EXPENSE_TYPE.EXPENSE),
          owner_id: expense?.id,
          comment: val?.comment,
        },
      })
    );
  };

  const getTransalationPropKey = (key) => {
    switch (key) {
      case DECLINE_CODES.FROZEN_CARD:
      case DECLINE_CODES.CARD_FROZEN_BY_EMPLOYEE:
        return {
          date: expense?.freezedAt,
          role: expense?.cardHolder?.roles[0],
          firstName: expense?.cardHolder?.firstName,
        };
      case DECLINE_CODES.BLOCKED_CARD:
      case DECLINE_CODES.AUTO_BLOCKED:
        return {
          date: expense?.blockedAt,
          role: expense?.cardHolder?.roles[0],
          firstName: expense?.cardHolder?.firstName,
        };

      default:
        return {};
    }
  };

  useEffect(() => {
    if (!expense) {
      dispatch(fetchAndSelectAccountingTransaction({ accountingId }));
    } else {
      dispatch(fetchShallowDepartments());
      dispatch(fetchShallowLocations());
      setMemo(expense?.memo);
      setFiles(expense?.receipts);
    }
  }, [expense]);

  useEffect(() => {
    setOnClose((searchParamArray) => {
      if (
        !searchParamArray?.includes(SLIDERS_SEARCH_PARAMS.accounting.cards.id)
      ) {
        dispatch(setSelectedAccountingTransaction(null));
        setMemo("");
      }
    });
  }, []);
  useEffect(() => {
    if (reloadExpense) {
      dispatch(fetchAndSelectAccountingTransaction({ accountingId }));
    }
  }, [reloadExpense]);

  return expense ? (
    <>
      <div className="slider-content-core">
        <div className="pb-6 mb-6">
          <div className="flex items-center justify-between">
            <ExpenseAndQRPayHeaderJsx
              isHeader
              exchangeRate={expense?.exchangeRate}
              transactionAmount={expense?.transactionAmount}
              transactionCurrencyCode={expense?.transactionCurrencyCode}
              settlementStatus={expense?.settlementStatus}
              img={expense?.vendor?.avatarUrl}
              colorCode={expense?.vendor?.colorCode}
              name={expense?.merchant?.name}
              amount={expense?.amount}
              currency={expense?.currency}
              transactionStatus={expense?.transactionStatus}
              updatedAt={expense?.expenseDate}
              merchantName={expense?.merchant?.name}
            />
          </div>
          {isDeclineExpense || expense?.accountingStatus === "sync_failed" ? (
            <div className="mt-6">
              <Alert
                wrapperDivClass="justify-start"
                iconClasses="w-6 h-6 text-neutral-500"
                variant="warning"
                title={expense?.accountingFailReason}
                description={
                  DECLINE_SLIDER_ALERT[expense?.declineDetails?.code]?.message
                }
                descriptionTransalationProp={getTransalationPropKey(
                  expense?.declineDetails?.code
                )}
              />
            </div>
          ) : null}
          <div className="mt-5">
            <Tabs
              items={TABS}
              selectedTab={selectedTab?.key}
              setCurrentTab={setSelectedTab}
              mode
            />
          </div>
          {selectedTab.name === HISTORY ? (
            <div className="mb-5">
              <QRPayAndExpenseSliderFooter
                isAccountingSlider
                expenseId={expenseId}
                isFetching={isFetching}
                accountingFooterCtas={expense.expenseCta}
                onSubmit={() => {}}
              />
            </div>
          ) : null}
          {selectedTab.name === OVERVIEW && (
            <AccountingTransactionCardsOverviewTab
              isExpenseStatusHidden={isDeclineExpense} // for declined transactionType
              expense={expense}
              memo={memo}
              isAccountingSlider={isAccountingSlider}
              handleMemoChange={setMemo}
              updateExpense={handleUpdateExpense}
              splitExpenses={splitExpense}
              remindPeople={remindPeople}
              dismissPolicy={dismissPolicy}
              x
              submissionPolicy={submissionPolicy}
              commentsList={commentsList}
              isApproved={isApproved}
              files={files}
              fileUploadHandler={fileUploadHandler}
              primaryAction={primaryAction}
              secondaryAction={secondaryAction}
              createNewComment={createNewComment}
              currentUser={currentUser}
            />
          )}
        </div>
      </div>

      <div className="slider-footer">
        {accountingEnabled ? (
          <div className="flex items-center h-full px-6 py-4 bg-white">
            <AccountingSliderSubmitButton
              verifiable={expense?.verifiable}
              syncable={expense?.syncable}
              accountingStatus={expense?.accountingStatus}
              onClick={accountingActionHandler}
              accountingId={accountingId}
              accountingCtas={expense?.accountingCtas}
              notActionableReason={expense?.notActionableReason}
              page={ACCOUNTING_TRANSACTION_PAGES.CARDS}
            />
          </div>
        ) : null}
      </div>
    </>
  ) : null;
}

AccountingTransactionCardsSlider.propTypes = {
  isAccountingSlider: PropTypes.bool,
};
