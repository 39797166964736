import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  mergeIntoCreateBillForm,
  resetCreateBillRef,
  setCreateBillForm,
  setCreateBillFormLineItems,
  setLineItems,
  setSelectedPayment,
} from "@/store/reducers/payments";
import { setPurchaseBillQuote } from "@/store/reducers/purchase-bills";
import { setTags } from "@/store/reducers/tags";
import { setSelectedVendor } from "@/store/reducers/vendors";

import { createBillFormSelector } from "@/store/selectors/payments";
import { userSelector } from "@/store/selectors/user";
import { selectedVendorSelector } from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import LinkVendorTo from "@/components/common/BillPayAndPayroll/VendorOrEmployee/CreateVendor/CreateVendorInit/LinkVendorTo";
import { useForm } from "@/utils/useForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { VENDOR_LINKED_TO_TYPES } from "@/constants/vendors";

export default function BillLinkedTo({
  searchParamKey,
  searchParamValue,
  setOnClose,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentUser = useSelector(userSelector);

  const currentDetails = useSelector(createBillFormSelector);

  const titleText =
    "billPay.bill.invoiceInbox.createBill.sections.billLinkedTo.title";
  const titleRef = useLeftHeaderTitle({ title: titleText });
  const vendor = useSelector(selectedVendorSelector);
  const ownerDepartmentId = vendor?.owner?.departmentId;
  const initialValues = {
    // radio value (one is always selected so no validaion required)
    linkedTo: {
      value:
        currentDetails?.projectId === ownerDepartmentId
          ? VENDOR_LINKED_TO_TYPES.DEPARTMENT
          : VENDOR_LINKED_TO_TYPES.PROJECT,
      // prefill in create, edit mode will be fine. Ok.
      validate: {
        required: true,
      },
    },
    // dropdown, will be hidden if radio type department is selected
    // if visible it is required
    projectId: {
      value: currentDetails?.projectId, // prefill in create, edit mode will be fine. Ok.
      validate: {
        required: true,
      },
    },
    projectName: {
      value: vendor?.projectName,
      validate: {
        required: true,
      },
    },
  };

  const discardButtonHandler = () => {
    searchParams.delete(searchParamKey);
    setSearchParams(searchParams);
  };

  const onSubmit = (event, latestValues) => {
    dispatch(mergeIntoCreateBillForm({ ...currentDetails, ...latestValues }));
    discardButtonHandler();
  };

  const {
    handleChange,
    values,
    errors,
    _setValues: setValues,
    isFormButtonDisabled,
    handleSubmit,
  } = useForm(initialValues, onSubmit, {
    isFetchingInitialValue: false,
    formId: "create-vendor-main-slider-form",
  });

  useEffect(() => {
    // onBack will retain the prefilling
    setOnClose((searchParamsArray) => {
      if (
        !searchParamsArray.includes(SLIDERS_SEARCH_PARAMS.payments.billLinkedTo)
      ) {
        dispatch(setTags([]));
        dispatch(setCreateBillForm(null));
        dispatch(setCreateBillFormLineItems([]));
        dispatch(setLineItems([]));
        dispatch(setPurchaseBillQuote(null));
        dispatch(setSelectedPayment(null));
        dispatch(setSelectedVendor(null));
        dispatch(resetCreateBillRef());
      }
    });
  }, []);

  return (
    <>
      <div className="slider-content-core">
        <form onSubmit={handleSubmit} id="bill-linked-to">
          <Text
            refProp={titleRef}
            translationKey={titleText}
            classes="text-3xl font-bold text-neutral-800"
          />

          {/* description */}
          <Text
            translationKey="billPay.bill.invoiceInbox.createBill.sections.billLinkedTo.description"
            classes="inline-block text-neutral-500 text-sm font-medium"
          />

          <LinkVendorTo
            classes="mt-8"
            values={values}
            errors={errors}
            setValues={setValues}
            handleChange={handleChange}
            metaData={{
              departmentHelperText:
                "billPay.bill.invoiceInbox.createBill.sections.billLinkedTo.departmentLabel",
              projectHelperText:
                "billPay.bill.invoiceInbox.createBill.sections.billLinkedTo.projectLabel",
              departmentName: vendor?.owner?.departmentName,
              disabledTooltipText:
                "billPay.vendors.createVendor.disabledProjectTooltipText",
            }}
            personId={vendor?.owner?.id}
            isVendorOwnerSelected={!!vendor?.owner?.id}
            showDepartmentNameInLabel
          />

          <div className="flex mt-4">
            <Icon
              name="LightBulb"
              className="mt-1.5 w-4 h-4  text-warning-500"
            />
            &nbsp; &nbsp;
            <div>
              <Text
                translationKey="billPay.bill.invoiceInbox.createBill.tipColon"
                classes="text-xs font-medium text-neutral-800"
              />
              &nbsp;
              <Text
                translationKey="billPay.bill.invoiceInbox.createBill.sections.billLinkedTo.tip"
                classes="text-neutral-500 text-xs font-medium"
              />
            </div>
          </div>
        </form>
      </div>

      <div className="sticky slider-footer-right-buttons slider-footer">
        <Button
          label="billPay.vendors.createVendor.discardChanges"
          classes="px-5 py-3"
          labelStyleClasses="text-neutral-500 font-semibold"
          variant="tertiary"
          compact
          onClick={discardButtonHandler}
        />

        <Button
          form="bill-linked-to"
          label="billPay.vendors.createVendor.saveChanges"
          classes="px-5 py-3"
          labelStyleClasses="text-white font-semibold"
          variant="primary"
          btnType="submit"
          compact
          disabled={isFormButtonDisabled}
        />
      </div>
    </>
  );
}

BillLinkedTo.propTypes = {
  searchParamKey: PropTypes.string,
  searchParamValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
