export const COLOR_CODE_LIST = [
  "16, 24, 39",
  "20, 83, 45",
  "120, 53, 15",
  "127, 29, 29",
  "158, 25, 86",
  "51, 31, 128",
  "187, 71, 36",
  "232, 105, 21",
  "72, 66, 167",
  "53, 122, 91",
  "2, 5, 141",
];
