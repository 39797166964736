import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Tooltip from "@/components/core/Tooltip";
import { dateToTimeStampString } from "@/utils/common";
import { APPROVAL_STATUS_TO_ICON_MAP } from "@/utils/constants/payments";

import Text from "../Text";

export default function ApproversPopup({
  children,
  id,
  totalApprovalSteps,
  approvers = [], // may be (appovers at different levels) or (approvers of the same level)
  approversLevelIsSame = false,
  tooltipProps = {},
  currentStep,
  isExpense,
}) {
  const noPreviousApprovers = !approvers?.length;
  return (
    <span id={`tooltip-${id}`}>
      {children ?? "Approvers_Popup_"}
      <Tooltip id={`tooltip-${id}`} direction="right" {...tooltipProps}>
        {currentStep ? (
          <div
            className={`flex flex-col gap-1 rounded ${
              approversLevelIsSame ? "" : "w-[320px]"
            }`}
          >
            {/* 16px padding already provided by core/Tooltip */}
            {approvers.map((approval, idx) =>
              approversLevelIsSame ? (
                <span
                  className="text-xs font-medium text-neutral-800"
                  key={idx}
                >
                  {approval.userName}
                </span>
              ) : (
                <div key={idx} className="flex items-center justify-between">
                  {/* left part */}

                  <div className="flex items-center gap-1">
                    <Icon
                      name={
                        APPROVAL_STATUS_TO_ICON_MAP[approval.status]?.iconName
                      }
                      className={`rounded-full text-white w-3 h-3 p-[2px] ${
                        APPROVAL_STATUS_TO_ICON_MAP[approval.status]?.classes
                      }`}
                    />
                    <span className="text-xs font-medium">
                      <span className="text-neutral-800">
                        {approval.userName}
                      </span>
                      &nbsp;
                      <span className="text-neutral-500">
                        ({idx + 1}/{totalApprovalSteps})
                      </span>
                    </span>
                  </div>

                  <span className="text-xs font-medium text-neutral-500">
                    {dateToTimeStampString(approval.updatedAt)}
                  </span>
                </div>
              )
            )}
          </div>
        ) : null}
        <div
          className={`flex  text-neutral-200 flex-col gap-1  ${
            approversLevelIsSame || noPreviousApprovers ? "w-full" : "w-[320px]"
          }`}
        >
          {noPreviousApprovers ? (
            <Text
              classes="text-neutral-500 text-sm font-medium"
              translationKey={
                isExpense
                  ? "expenses.overview.overviewTable.awaitingReview"
                  : "billPay.bill.payments.paymentStatus.status.awaiting"
              }
            />
          ) : null}
        </div>
      </Tooltip>
    </span>
  );
}

ApproversPopup.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  totalApprovalSteps: PropTypes.number,
  approvers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      userName: PropTypes.string,
      updatedAt: PropTypes.any,
    })
  ),
  approversLevelIsSame: PropTypes.bool,
  tooltipProps: PropTypes.object,
  currentStep: PropTypes.number,
  isExpense: PropTypes.bool,
};
