import axios from "axios";
import qs from "qs";
import { shutdownChatBox } from "@/utils/chatbox";
import { AXIOS_API_METHOD } from "@/utils/constants/app";
import {
  DEBIT_IS_MORE_THAN_BALANCE,
  INTERNAL_SERVER_ERROR,
  UNAUTHORIZED,
} from "@/utils/constants/response-status";

import { PUBLIC_LAYOUTS, ROUTES } from "@/constants/routes";

const $axios = axios.create();

let store = null;

export const injectStore = (_store) => {
  store = _store;
};

const CancelToken = axios.CancelToken; // eslint-disable-line

const pending = {};

const removePending = ({
  config = undefined,
  cancel = undefined,
  isRemovePending = false,
}) => {
  const { url } = config;

  const flagUrl = `${url}&${config.method}&${JSON.stringify(config.params)}`;

  if (flagUrl in pending) {
    if (cancel) {
      cancel();
    } else if (isRemovePending) {
      delete pending[flagUrl];
    }
  } else {
    // eslint-disable-next-line
    if (cancel) {
      pending[flagUrl] = cancel;
    }
  }
};

const notify = (error) => {
  let message = error?.response?.data?.message || error?.response?.statusText;

  if (
    error?.response?.status !== INTERNAL_SERVER_ERROR &&
    (error?.response?.data?.messages || error?.response?.data?.message)
  ) {
    if (error?.response?.data?.messages) {
      if (Array.isArray(error?.response?.data?.messages)) {
        message = `Invalid request: ${error?.response?.data?.messages?.join(
          "\n"
        )}`;
      } else {
        message = `Invalid request: ${error?.response?.data?.messages}`;
      }
    } else {
      message = `Invalid request: ${error?.response?.data?.message}`;
    }
  } else {
    message =
      "Uh oh! Something went wrong, Please contact Volopay support for assistance.";

    if (error?.response?.data?.code === DEBIT_IS_MORE_THAN_BALANCE) {
      message = error?.response?.data?.message;
    }
  }
};

const processError = (error) => {
  const access_token = localStorage.getItem("access-token");

  if (error?.code === "ERR_CANCELED") {
    return;
  }

  if (error?.config) {
    removePending({ config: error?.config, isRemovePending: true });
  }

  if (error?.response?.status === UNAUTHORIZED) {
    if (access_token) {
      localStorage.removeItem("access-token");
    }
    store?.dispatch({
      type: "urlMap/clearAllUrlMaps",
      payload: {},
    });
    //  no  matter what happens user should be kicked out if on PRIVATE ROUTES
    const pathWithoutTrailingSlash = window.location.pathname.replace(
      /\/$/,
      ""
    );
    if (!PUBLIC_LAYOUTS[pathWithoutTrailingSlash]) {
      const redirectUri = encodeURIComponent(
        `${window.location.pathname}${window.location.search || ""}`
      );

      window.location.href = `${ROUTES.login.base.absolutePath}?redirect=${redirectUri}`;
      shutdownChatBox();
    }
  } else {
    notify(error);
    store?.dispatch({
      type: "urlMap/setUrlMap",
      payload: {
        url: error?.config?.apiKey,
        data: {
          isFetching: false,
          isError: true,
          error: error?.response?.data,
        },
      },
    });
  }

  return Promise.reject(error);
};

$axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "brackets" });
};

$axios.interceptors.request.use((config) => {
  if (config.method === AXIOS_API_METHOD.GET) {
    // Set the timeout for GET requests
    config.timeout = 20000; // 20 seconds
  }

  store?.dispatch({
    type: "urlMap/setUrlMap",
    payload: {
      url: config?.apiKey,
      data: { isFetching: true, isError: false },
    },
  });
  try {
    config.cancelToken = new CancelToken((cancel) => {
      removePending({ config, cancel });
    });

    return config;
  } catch (error) {
    return processError(error);
  }
});

$axios.interceptors.response.use((response) => {
  try {
    removePending({ config: response.config, isRemovePending: true });

    const currentCodeVersion = window.localStorage.getItem("code_version");

    const newCodeVerison = response.headers["code-version"];

    if (newCodeVerison && currentCodeVersion !== newCodeVerison) {
      window.localStorage.setItem("code_version", newCodeVerison);
    }
    if (response) {
      store?.dispatch({
        type: "urlMap/setUrlMap",
        payload: {
          url: response?.config?.apiKey,
          data: { isFetching: false, isError: false },
        },
      });
    }

    return response;
  } catch (error) {
    return processError(error);
  }
}, processError);

export default $axios;
