import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  archiveLocation,
  createLocations,
  editLocations,
  fetchAndSelectLocation,
  fetchLocations,
  fetchShallowLocations,
  setSelectedLocation,
} from "@/store/reducers/company";

import {
  allLocationSelector,
  selectedLocationSelector,
} from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import MultipleInput from "@/components/core/MultipleInput";
import Text from "@/components/core/Text";
import { MULTIPLE_INPUT_CONFIG } from "@/utils/constants/multipleInput";
import { REGEX_VALUE } from "@/utils/constantUseForm";
import { formatNames } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { LOCATION_CONFIG } from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function AddLocation({ isInEditMode = false }) {
  const [locations, setLocations] = useState([]);
  const [errorArray, setErrorArray] = useState([false]);
  const allLocations = useSelector(allLocationSelector);
  const locationSet = new Set(
    allLocations?.filter((i) => i?.name)?.map((loc) => loc?.name?.toLowerCase())
  );

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.location.edit
  );

  const type = isInEditMode ? "edit" : "create";
  const location = useSelector(selectedLocationSelector);

  const handleChange = (values) => {
    setLocations(values);
  };

  const validate = (text, arr) => {
    let key = "NORMAL";
    // TODO: Ask for Validations required
    if (!text) {
      key = "ERROR";
    }
    const set = new Set(arr?.map((i) => i?.toLowerCase()));
    if (set.has(text?.toLowerCase()) || locationSet?.has(text?.toLowerCase()))
      key = "DUPLICATE";

    return key;
  };

  const handleErrors = (err) => {
    setErrorArray(err);
  };
  const handleCloseSlider = (response) => {
    setLocations([]);
    if (isInEditMode) {
      dispatch(setSelectedLocation(response));
    }
    dispatch(fetchLocations({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT }));
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.company.location[isInEditMode ? "edit" : "add"]
    );
    setSearchParams(searchParams);
  };
  const handleSubmit = () => {
    if (isInEditMode) {
      // edit
      const apipayload = { name: locations };
      dispatch(
        editLocations({
          id: locationId,
          params: apipayload,
          onSuccess: handleCloseSlider,
        })
      );
    } else {
      const _locations = locations.map((it) => ({ name: it }));
      dispatch(
        createLocations({ locations: _locations, onSuccess: handleCloseSlider })
      );
    }
  };
  useEffect(() => {
    if (location?.name) {
      setLocations(location?.name);
    }
  }, [location]);

  const handleCloseOfSlider = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.location.edit);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.location.id);

    setSearchParams(searchParams);
  };

  const handleArchive = () => {
    const id = searchParams.get(SLIDERS_SEARCH_PARAMS.company.location.edit);
    const onSuccess = () => {
      setSearchParams(searchParams);
      handleCloseOfSlider();
      dispatch(
        fetchLocations({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT })
      );
    };

    dispatch(archiveLocation({ id, onSuccess }));
  };
  const isValueChanged = isInEditMode && location?.name === locations;

  const isEditEmpty =
    isInEditMode &&
    typeof locations === typeof "" &&
    !locations?.trim()?.length;

  useEffect(() => {
    dispatch(fetchShallowLocations());
  }, []);
  return (
    <>
      <div className="slider-content-core">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col">
            {LOCATION_CONFIG[type]?.label ? (
              <Text
                classes="text-3xl font-bold"
                translationKey={LOCATION_CONFIG[type]?.label}
              />
            ) : null}
            {LOCATION_CONFIG[type]?.description ? (
              <Text
                classes="text-sm font-medium text-neutral-500"
                translationKey="company.department.departmentDetails.tabs.budgets.locationEmptyDescription"
              />
            ) : null}
          </div>
          {isInEditMode ? (
            <Input
              label="company.location.name"
              value={locations}
              onChange={(e) => handleChange(e.target.value)}
            />
          ) : (
            <div className="mt-5">
              <MultipleInput
                emptyErrorMessage="misc.addRequiredField"
                label="dashboard.quickAccess.company.addLocations"
                handlerKeys={["Enter", ","]}
                handleChange={(values) => {
                  handleChange(values);
                }}
                MultipleInputConfig={MULTIPLE_INPUT_CONFIG}
                formatNamesOnBlur={formatNames}
                validate={validate}
                handleError={(err) => {
                  handleErrors(err);
                }}
              />
              <Text
                classes="text-xs font-normal text-neutral-500"
                translationKey="dashboard.quickAccess.company.locationDesc"
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end gap-3 px-5 py-5 slider-footer ">
        <Button
          variant="tertiary"
          classes="border-neutral-200 text-neutral-500 w-15.5"
          label="misc.cancel"
          onClick={handleCloseSlider}
        />
        <Button
          disabled={
            isValueChanged || !locations || !locations?.length || isEditEmpty
          }
          classes="w-15.5"
          label={LOCATION_CONFIG[type]?.btnLabel}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}

export default AddLocation;
AddLocation.propTypes = {
  isInEditMode: PropTypes.bool,
};
