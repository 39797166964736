import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAndSelectUser } from "@/store/reducers/user";

import { overviewMyVolopayExpenseFilters } from "@/store/selectors/expense";
import { userSelector } from "@/store/selectors/user";

import DeclinesOrFailuresPageHelper from "@/components/common/QrPayAndExpense/DeclinesOrFailuresPageHelper";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";

function MyVolopayExpensesDeclinePages() {
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const myVolopayFilter = useSelector(overviewMyVolopayExpenseFilters);

  useEffect(() => {
    dispatch(fetchAndSelectUser({ userId: currentUser?.id }));
  }, []);

  return (
    <DeclinesOrFailuresPageHelper
      page={QRPAY_EXPENSES_CONTEXT.EXPENSES}
      filteringParams={{ user: currentUser?.id }}
    />
  );
}

export default MyVolopayExpensesDeclinePages;
