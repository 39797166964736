import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { ACCOUNTING_SOFTWARES } from "@/constants/accounting";
import { TRANSACTION_ACTIONS } from "@/constants/transactions";

export default function BulkActionComponent({
  selectedRows,
  totalRows,
  handleBulkAction,
  handleExport,
  clearSelection,
  bulkSelectAll,
  selectAllHandler,
  showApprove,
  showVerify,
  showExport = true,
  showSync,
  bulkApproveSelectAll,
  selectedRowsText = "common.multipleTransactionSelected",
  selectedSingleRowText = "common.singleTransactionSelected",
  descriptionText,
  showSelectAllButton,
  isLoading = false,
  approveInProgress,
  titleText = "accounting.transactions.bulkExport",
}) {
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const isTally = accountingSoftware === ACCOUNTING_SOFTWARES.TALLY;

  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between w-full p-6 bg-white">
      <div className="flex flex-col">
        <Text
          color="neutral-800"
          classes="font-semibold"
          translationKey={titleText}
          translationProps={{
            selectedRows: bulkApproveSelectAll ? "All" : selectedRows,
            selectedRowsText:
              selectedRows === 1
                ? t(selectedSingleRowText)
                : t(selectedRowsText),
          }}
        />

        {descriptionText ? (
          <Text
            translationKey={descriptionText}
            classes="text-neutral-500 text-sm font-medium"
          />
        ) : null}

        {showSelectAllButton ? (
          !bulkApproveSelectAll ? (
            <div onClick={() => selectAllHandler(true)}>
              <Text
                color="primary-500 cursor-pointer"
                classes="font-normal text-sm"
                translationKey="accounting.transactions.selectAll"
                translationProps={{ totalRows }}
              />
            </div>
          ) : (
            <div onClick={() => clearSelection()}>
              <Text
                color="primary-500 cursor-pointer"
                classes="font-normal text-sm"
                translationKey="accounting.transactions.clear"
              />
            </div>
          )
        ) : null}
      </div>
      <div className="flex items-center gap-7">
        {showExport ? (
          <Button
            type="neutral"
            classes="w-14.7"
            label="accounting.transactions.cards.listingFields.action.actions.export"
            preIcon="OpenInNew"
            onClick={() => handleExport()}
          />
        ) : (
          ""
        )}
        {accountingEnabled ? (
          <>
            {showVerify ? (
              <Button
                type="success"
                classes="w-16.35"
                label="accounting.transactions.cards.listingFields.action.actions.verify"
                counter={
                  bulkSelectAll
                    ? null
                    : selectedRows < 100
                      ? selectedRows
                      : "99+"
                }
                onClick={() => handleBulkAction(TRANSACTION_ACTIONS.VERIFY)}
                showLoader={isLoading}
              />
            ) : null}
            {showSync && !isTally ? (
              <Button
                type="success"
                classes="w-16.35"
                label="accounting.transactions.cards.listingFields.action.actions.sync"
                counter={
                  bulkSelectAll
                    ? null
                    : selectedRows < 100
                      ? selectedRows
                      : "99+"
                }
                onClick={() => handleBulkAction(TRANSACTION_ACTIONS.SYNC)}
                showLoader={isLoading}
              />
            ) : null}
          </>
        ) : null}

        {showApprove ? (
          <Button
            type="success"
            classes="w-16.35"
            showLoader={approveInProgress}
            label="accounting.transactions.cards.listingFields.action.actions.approve"
            counter={selectedRows < 100 ? selectedRows : "99+"}
            onClick={() => handleBulkAction(TRANSACTION_ACTIONS.APPROVE)}
          />
        ) : null}
        <div onClick={clearSelection} className="cursor-pointer">
          <Icon name="Close" className="text-neutral-400" />
        </div>
      </div>
    </div>
  );
}
BulkActionComponent.propTypes = {
  selectedRows: PropTypes.number,
  totalRows: PropTypes.number,
  handleBulkAction: PropTypes.func,
  handleExport: PropTypes.func,
  clearSelection: PropTypes.func,
  selectAllHandler: PropTypes.func,
  showVerify: PropTypes.bool,
  showApprove: PropTypes.bool,
  showSync: PropTypes.bool,
  showExport: PropTypes.bool,
  bulkApproveSelectAll: PropTypes.bool,
  selectedRowsText: PropTypes.string,
  selectedSingleRowText: PropTypes.string,
  descriptionText: PropTypes.string,
  showSelectAllButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  approveInProgress: PropTypes.bool,
  bulkSelectAll: PropTypes.bool,
};
