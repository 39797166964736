import PropsTypes from "prop-types";
import { useSelector } from "react-redux";

import { primaryCardProviderSelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import PremiumContactSupport from "@/components/common/PremiumContactSupport";

import { CARD_PROVIDER } from "@/constants/Cards";

function AddControlsSection({ openControlsHandler, showAddControlsSection }) {
  const primaryCardProviderInfo = useSelector(primaryCardProviderSelector);
  const slug = primaryCardProviderInfo?.slug;
  const showComingSoonChip = [
    CARD_PROVIDER.NIUM,
    CARD_PROVIDER.LIVQUIK,
    CARD_PROVIDER.PINELABS,
  ].includes(slug);

  return showAddControlsSection ? (
    <div
      className={`flex flex-col gap-4 p-3 mb-2 bg-neutral-50 rounded-2 ${
        showComingSoonChip ? "cursor-not-allowed" : ""
      }`}
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex gap-3">
            <Text
              translationKey="cards.cardDetails.editCardDetails.lowCardBalance.fields.spendRestrictions.label"
              classes="text-base font-semibold text-neutral-800"
            />

            {showComingSoonChip ? <PremiumContactSupport /> : null}
          </div>
          <Text
            translationKey="cards.cardDetails.editCardDetails.lowCardBalance.fields.spendRestrictions.help"
            classes="text-xs font-semibold text-neutral-500"
          />
        </div>

        {showComingSoonChip ? null : (
          <Button
            onClick={openControlsHandler}
            preIcon="Add"
            iconClasses="-mr-2"
            label={
              <Text
                translationKey="cards.vCards.createCardSlider.addControls"
                classes="text-xs font-bold"
              />
            }
            variant="default"
            classes="w-fit h-fit py-1.5 px-5 bg-neutral-50 border border-neutral-300 text-primary-500 hover:bg-neutral-100 active:bg-neutral-200"
          />
        )}
      </div>
    </div>
  ) : null;
}

AddControlsSection.propTypes = {
  openControlsHandler: PropsTypes.func,
  showAddControlsSection: PropsTypes.bool,
};

export default AddControlsSection;
