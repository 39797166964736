import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addPeople,
  addTravelPeople,
  deleteTravelPeople,
  fetchPeopleShallow,
  fetchTravelPeople,
} from "@/store/reducers/company";

import { clientSelector } from "@/store/selectors/client";
import {
  allPeopleSelector,
  isFetchingPeopleSelector,
  travelFetchAgainSelector,
  travelOffsetSelector,
  travelPeopleListSelector,
} from "@/store/selectors/company";

import AddAndEditDumbPeople from "@/components/common/AddAndEditDumbPeople";

import { TRUTRIP_SUPER_ADMIN } from "@/constants/company";

function ManagePeople() {
  const dispatch = useDispatch();
  const allPeople = useSelector(allPeopleSelector);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const isFetching = useSelector(isFetchingPeopleSelector);
  const client = useSelector(clientSelector);
  const travelFtechAgain = useSelector(travelFetchAgainSelector);
  const traveloffset = useSelector(travelOffsetSelector);
  const travelPeople = useSelector(travelPeopleListSelector);

  const [isLoading, setIsLoading] = useState(true);
  const [superAdmin, setSuperAdmin] = useState("");
  const handleAddAndRemoveApiCall = ({
    addedPeople,
    removedPeople,
    allUserIdMap,
    onSuccess,
  }) => {
    if (addedPeople?.length)
      dispatch(
        addTravelPeople({
          onSuccess: () => {
            onSuccess();
            setSelectedPeople((prev) => [...prev, ...addedPeople]);
          },
          client_id: client?.id,
          user_ids: addedPeople?.map((i) => allUserIdMap[i]?.volopayUserId),
        })
      );
    else if (removedPeople?.length)
      dispatch(
        deleteTravelPeople({
          onSuccess: () => {
            onSuccess();
            setSelectedPeople((prev) =>
              prev?.filter((i) => !removedPeople?.includes(i))
            );
          },
          client_id: client?.id,
          user_ids: removedPeople?.map((i) => allUserIdMap[i]?.deleteId),
        })
      );
  };
  useEffect(() => {
    dispatch(fetchPeopleShallow());
  }, []);

  useEffect(() => {
    if (travelFtechAgain && client?.id) {
      dispatch(
        fetchTravelPeople({
          client_id: client?.id,
          offset: Number.isNaN(traveloffset) ? 1 : traveloffset,
        })
      );
    } else if (!travelFtechAgain && isFetching) {
      setIsLoading(false);
    }
  }, [travelFtechAgain, isFetching, client?.id]);

  useEffect(() => {
    if (!travelFtechAgain && !isFetching) {
      const _selectedPeople = travelPeople
        ?.filter((item) => item.customId)
        ?.map((item) => {
          return item.customId;
        });

      setSelectedPeople(_selectedPeople);
    }
  }, [travelFtechAgain, isFetching, travelPeople]);
  useEffect(() => {
    const _superAdmin = travelPeople?.find(
      (i) => i?.role === TRUTRIP_SUPER_ADMIN
    );
    if (_superAdmin) {
      setSuperAdmin(_superAdmin);
    }
  }, [travelPeople]);
  return !isFetching && !travelFtechAgain ? (
    <AddAndEditDumbPeople
      groupRoles={false}
      handleSubmit={({ selectedIds, ...rest }) => {
        handleAddAndRemoveApiCall({ ...rest });
      }}
      staticPeopleId={superAdmin?.customId ? [superAdmin?.customId] : []}
      setSelectedPeople={setSelectedPeople}
      selectedUsers={selectedPeople}
      allUsers={allPeople?.map((item) => {
        const selectedUser = travelPeople?.find(
          (i) => i?.customId === item?.uuid
        );
        return {
          ...item,
          id: item.uuid,
          deleteId: selectedUser?.id,
          volopayUserId: item.id,
        };
      })}
      metaData={{
        title:
          "company.department.departmentDetails.tabs.people.managePeopleLabel",
        description: "company.travel.managePeople.meta.description",
        editableTitle: "company.travel.managePeople.meta.editableTitle",
        editableDescription:
          "company.travel.managePeople.meta.editableDescription",
        listHeading: "company.travel.managePeople.meta.listHeading",
        emptyTitle: "company.travel.managePeople.meta.emptyTitle",
        emptyDescription: "company.travel.managePeople.meta.emptyDescription",
        emptyBtnLabel: "company.travel.managePeople.meta.editableTitle",
        emptyBtnProps: { preIcon: "Add", classes: "w-fit px-4" },
      }}
      isFetching={{
        isFetchingSelectedPeople: isLoading,
        isFetchingAllUser: isFetching,
      }}
    />
  ) : null;
}

export default ManagePeople;
