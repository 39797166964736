import PropTypes from "prop-types";

import Button from "@/components/core/Button";

/**
 * Grid of buttons
 *
 * (works with incomplete config too,
 * but respects `columns` over `rows` in edge cases)
 *
 * @param {Array<Object>} buttonConfigs
 * @param {Number} columns
 * @param {Number} rows
 */
export default function GridOfButtons({ buttonConfigs, columns, rows }) {
  if (rows * columns !== buttonConfigs.length) {
    if (rows && columns) {
      rows = Math.ceil(buttonConfigs.length / columns);
    } else {
      columns ??= Math.ceil(buttonConfigs.length / rows);
      rows ??= Math.ceil(buttonConfigs.length / columns);
    }
  }
  const _buttonConfigs = [
    ...buttonConfigs,
    ...new Array(columns * rows - (buttonConfigs.length ?? 0)).fill({}),
  ];

  return (
    <div
      className={`grid grid-cols-${columns} grid-rows-${rows} w-fit bg-neutral-200 rounded-lg gap-[1px] `}
    >
      {_buttonConfigs.map(({ icon, label, onClick }, index, arr) => {
        const isTopRight = index === columns - 1;
        const isBottomLeft = index === columns * (rows - 1);
        const isLastItemAtCorner =
          index === arr.length - 1 && rows * columns === arr.length;

        return (
          <Button
            key={index}
            variant="tertiary"
            label={label}
            preIcon={icon}
            onClick={onClick}
            size="mdx"
            type="default"
            classes={`py-4 px-4 border-none rounded-none first:rounded-tl-lg border border-neutral-200 bg-white ${
              isTopRight ? "rounded-tr-lg" : ""
            } ${isBottomLeft ? "rounded-bl-lg" : ""} ${
              isLastItemAtCorner ? "last:rounded-br-lg" : ""
            } ${!label && !onClick ? "pointer-events-none" : ""}`}
            innerClasses="flex flex-col gap-0"
            iconClasses="text-neutral-500 w-[14px] h-[14px]"
            labelStyleClasses="min-w-[120px] text-neutral-600 text-xs font-semibold"
          />
        );
      })}
    </div>
  );
}

GridOfButtons.propTypes = {
  columns: PropTypes.number,
  rows: PropTypes.number,
  buttonConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export const GridOfButtonsExample = () => (
  <GridOfButtons
    columns={2}
    buttonConfigs={[
      { label: "Virtual cards", icon: "Cloud" },
      { label: "Physical cards", icon: "CreditCard" },

      { label: "Expenses", icon: "Expense" },
      { label: "Missing items", icon: "MissingBadge" },

      { label: "Bill payments", icon: "Send" },
      { label: "Reimbursements", icon: "Cloud" },
    ]}
  />
);
