import PropTypes from "prop-types";

export default function BillingPaymentMethodCell({ val }) {
  return (
    <div className="flex items-center justify-center text-neutral-800">
      <span>{val.paymentMethod}</span>
    </div>
  );
}

BillingPaymentMethodCell.propTypes = {
  val: PropTypes.object,
};
