import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { cardProviderWithInternationalCardOrderEnabledSelector } from "@/store/selectors/client";

import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { dateToString } from "@/utils/common";

import { CARD_TYPE, CARD_USAGE_TYPES } from "@/constants/Cards";
import { CARD_REQUEST_TYPES } from "@/constants/company";

export default function CardRequestTypeCell({
  cardType,
  cardRequestType,
  name,
  nameTextClasses,
  requestDate,
  handleCheckboxClick,
  isChecked,
  cardUsageType,
}) {
  const hasCardProvidersWithInternationalCardOrder = useSelector(
    cardProviderWithInternationalCardOrderEnabledSelector
  );

  const isCardUsageTypeInternational =
    hasCardProvidersWithInternationalCardOrder &&
    cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL;

  const CARD_REQUEST_TYPE_CELL_CONFIG = {
    [CARD_REQUEST_TYPES.PHYSICAL_CARD_REQUEST]: {
      icon: isCardUsageTypeInternational
        ? "InternationalCards"
        : "RoundedCreditCard",
      iconClasses: isCardUsageTypeInternational
        ? "bg-integrations-50 p-2 text-integrations-500"
        : "",
      cardRequestTypeConfig: {
        label: "myVolopay.actionCentre.table.request.newCard",
        classes: "text-success-600",
      },
    },
    [CARD_REQUEST_TYPES.VIRTUAL_CARD_REQUEST]: {
      icon: "RoundedCloud",
      cardRequestTypeConfig: {
        label: "New Card",
        classes: "text-success-600",
      },
    },
    [CARD_REQUEST_TYPES.TOP_UP_REQUEST]: {
      icon: isCardUsageTypeInternational
        ? "EditInternationalCard"
        : cardType === CARD_TYPE.PHYSICAL
          ? "PhysicalCardEditLimit"
          : "VirtualCardEditRequest",
      iconClasses:
        hasCardProvidersWithInternationalCardOrder &&
        cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
          ? "bg-integrations-50 px-2 py-2.5 text-integrations-500"
          : "",
      cardRequestTypeConfig: {
        label: "Card Limit",
        classes: "text-warning-600",
      },
    },
  };

  const requestDateText = dateToString(requestDate);
  const cardRequestTypeLabel =
    CARD_REQUEST_TYPE_CELL_CONFIG[cardRequestType]?.cardRequestTypeConfig
      ?.label;
  const iconName = CARD_REQUEST_TYPE_CELL_CONFIG[cardRequestType]?.icon;
  const iconClasses =
    CARD_REQUEST_TYPE_CELL_CONFIG[cardRequestType]?.iconClasses;
  const nameClasses =
    CARD_REQUEST_TYPE_CELL_CONFIG[cardRequestType]?.cardRequestTypeConfig
      ?.classes;

  return (
    <div
      className={` ${handleCheckboxClick ? "flex flex-row items-center" : ""}`}
    >
      {handleCheckboxClick ? (
        <Checkbox onClickHandler={handleCheckboxClick} checked={isChecked} />
      ) : null}
      <ProfileWidget
        className="text-sm font-semibold "
        iconComponent={
          <div className={`${iconClasses} rounded-full`}>
            <Icon name={iconName} />
          </div>
        }
        name={name}
        textClasses={nameTextClasses}
      >
        <div className="flex flex-row gap-1">
          {requestDateText ? (
            <Text
              translationKey={requestDateText}
              classes="text-neutral-500 font-semibold"
            />
          ) : null}

          {cardRequestTypeLabel ? (
            <div className="flex flex-row items-center gap-1">
              {cardRequestTypeLabel ? (
                <span className="text-sm font-semibold profile-widget-dot text-neutral-500" />
              ) : null}
              <Text
                translationKey={cardRequestTypeLabel}
                classes={nameClasses}
              />
            </div>
          ) : null}
        </div>
      </ProfileWidget>
    </div>
  );
}

CardRequestTypeCell.propTypes = {
  cardType: PropTypes.string,
  cardRequestType: PropTypes.string,
  name: PropTypes.string,
  requestDate: PropTypes.string,
  handleCheckboxClick: PropTypes.func,
  isChecked: PropTypes.bool,
  nameTextClasses: PropTypes?.string,
  cardUsageType: PropTypes.string,
};
