import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getCardsListForSelectedUser } from "@/store/reducers/user";

import {
  cardProviderWithInternationalCardOrderEnabledSelector,
  primaryCardProviderSelector,
} from "@/store/selectors/client";
import {
  isFetchingSelectedUserCardList,
  selectedUserPhysicalCardsListSelector,
  selectedUserSelector,
  selectedUserVirtualCardsListSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Carousel from "@/components/core/Carousel";
import Text from "@/components/core/Text";

import {
  CARD_PROVIDER,
  CARD_STATUS,
  CARD_TYPE,
  CARD_TYPES,
  ORDER_PHYSICAL_CARDS_TYPE,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { CAROUSEL_MODE } from "@/constants/common";
import {
  CARDS_BASE_ROUTE,
  PHYSICAL_CARDS_ALL_PATH_NAME,
  PHYSICAL_CARDS_PATH_NAME,
  VIRTUAL_CARDS_ALL_PATH_NAME,
  VIRTUAL_CARDS_PATH_NAME,
} from "@/constants/routes";

import CardSnapshot from "./CardSnapshot";

export default function ShowCardsSection({ classes }) {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const userId = searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id);

  const selectedUser = useSelector(selectedUserSelector);

  const isFetchingCards = useSelector(isFetchingSelectedUserCardList);

  const physicalCards = useSelector(selectedUserPhysicalCardsListSelector);
  const primaryCardProvider = useSelector(primaryCardProviderSelector);
  const primaryCardSupportedCardTypes =
    primaryCardProvider?.config?.supportedCardTypes ?? [];
  const internationalCardProvider = useSelector(
    cardProviderWithInternationalCardOrderEnabledSelector
  );
  const internationalCardProviderSupportedCardTypes =
    internationalCardProvider?.config?.supportedCardTypes ?? [];
  const virtualCards = useSelector(
    selectedUserVirtualCardsListSelector
  )?.filter((card, index) => index < 5);

  const virtualCardsLength = virtualCards?.length;

  const [showPhysicalCardsSection, showVirtualCardsSection] = useMemo(() => {
    // Create a Set to combine and deduplicate all supported card types
    const supportedCardTypes = new Set([
      ...primaryCardSupportedCardTypes,
      ...internationalCardProviderSupportedCardTypes,
    ]);

    // Determine if "physical" and "virtual" card types are present
    const showPhysical = supportedCardTypes.has(CARD_TYPES.PHYSICAL);
    const showVirtual = supportedCardTypes.has(CARD_TYPES.VIRTUAL);

    return [showPhysical, showVirtual];
  }, [
    primaryCardSupportedCardTypes,
    internationalCardProviderSupportedCardTypes,
  ]);

  useEffect(() => {
    getCardsList();
  }, []);

  function handleCardClick(cardId) {
    searchParams.append(SLIDERS_SEARCH_PARAMS.cards.id, cardId);
    setSearchParams(searchParams);
  }

  function getCardsList() {
    dispatch(
      getCardsListForSelectedUser({
        page: 1,
        card_type: [CARD_TYPE.PHYSICAL, CARD_TYPE.VIRTUAL],
        status: [CARD_STATUS.ACTIVE, CARD_STATUS.FROZEN],
        user: userId,
      })
    );
  }

  return (
    <div className={classes}>
      <CardsSection
        cardList={physicalCards}
        handleCardClick={handleCardClick}
        stepPaginationTitle="cards.usersPhysicalCard"
        stepPaginationTitleProps={{ user: selectedUser?.displayName }}
        isStepPaginationButtonsDisabled
        isFetching={isFetchingCards}
        cardsType={CARD_TYPE.PHYSICAL}
        show={showPhysicalCardsSection}
      />

      <CardsSection
        stepPaginationTitle="cards.usersVirtualCard"
        stepPaginationTitleProps={{
          user: selectedUser?.displayName,
          s: virtualCards?.length > 1 ? "s" : "",
        }}
        cardList={virtualCards}
        handleCardClick={handleCardClick}
        isStepPaginationButtonsDisabled={virtualCardsLength <= 0}
        showViewAllCardsButton={virtualCardsLength > 5}
        isFetching={isFetchingCards}
        cardsType={CARD_TYPE.VIRTUAL}
        outerSectionClasses="mt-8"
        show={showVirtualCardsSection}
      />
    </div>
  );
}

ShowCardsSection.propTypes = {
  classes: PropTypes.string,
};

const CardsSection = ({
  cardList,
  handleCardClick,
  stepPaginationTitle,
  stepPaginationTitleProps,
  showViewAllCardsButton,
  isStepPaginationButtonsDisabled,
  outerSectionClasses,
  cardsType,
  show = true,
}) => {
  const carouselRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedUser = useSelector(selectedUserSelector);
  function handleButtonClick() {
    const baseOriginUrl = window.location.origin;

    const cardSpecificRoute =
      cardsType === CARD_TYPE.VIRTUAL
        ? VIRTUAL_CARDS_PATH_NAME
        : PHYSICAL_CARDS_PATH_NAME;

    const tabSpecificRoute =
      cardsType === CARD_TYPE.VIRTUAL
        ? VIRTUAL_CARDS_ALL_PATH_NAME
        : PHYSICAL_CARDS_ALL_PATH_NAME;

    const targetUrl = `${CARDS_BASE_ROUTE}/${cardSpecificRoute}/${tabSpecificRoute}`;

    const navigateUrl = `${baseOriginUrl}${targetUrl}`;

    window.open(navigateUrl, "_blank");
  }

  const handleOrderCard = () => {
    const searchParamsConfig =
      cardsType === CARD_TYPE.PHYSICAL
        ? {
            searchKey:
              SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
            searchValue:
              ORDER_PHYSICAL_CARDS_TYPE.PERSONALISED_PHYSICAL_CARD_ORDER.key,
          }
        : {
            searchKey: SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage,
            searchValue: true,
          };
    searchParams.append(
      searchParamsConfig?.searchKey,
      searchParamsConfig?.searchValue
    );
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.selectedUserCard,
      selectedUser?.id
    );
    setSearchParams(searchParams);
  };

  return show ? (
    <div className={outerSectionClasses}>
      <div className="flex flex-row justify-between mb-4">
        <Text
          translationKey={stepPaginationTitle}
          translationProps={stepPaginationTitleProps}
          classes="text-lg font-semibold text-neutral-80"
        />

        {showViewAllCardsButton ? (
          <Button
            label="company.people.showCardsSection.viewButtons"
            size="sm"
            variant="tertiary"
            onClick={handleButtonClick}
          />
        ) : null}
      </div>

      {cardList?.length > 0 ? (
        <Carousel
          mode={CAROUSEL_MODE.MULTI_VIEW}
          isIndicatorsEnabled={false}
          isStepPaginationButtonsDisabled={isStepPaginationButtonsDisabled}
          refProp={carouselRef}
        >
          {cardList?.map((card, index) => {
            return (
              <CardSnapshot
                availableBalance={card?.availableBalance}
                cardNumber={card?.cardNumber}
                cardType={card?.type}
                currency={card?.currency}
                limit={card?.limit}
                cardStatus={card?.status}
                cardProvider={card?.slug}
                onClick={() => handleCardClick(card?.id)}
                allowedCtas={card?.allowedCtas}
                cardId={card?.id}
                frequency={card?.frequency}
                key={`card-snapshot-${card?.type}-${index}`}
                cardUsageType={card?.cardUsageType}
              />
            );
          })}
        </Carousel>
      ) : (
        <div className="flex flex-row justify-start w-full first-letter:capitalize">
          <CardSnapshot
            cardStatus={CARD_STATUS.INACTIVE}
            cardType={cardsType}
            onClick={() => handleOrderCard()}
            emptyCard
          />
        </div>
      )}
    </div>
  ) : null;
};

CardsSection.propTypes = {
  cardList: PropTypes.array,
  handleCardClick: PropTypes.func,
  stepPaginationTitle: PropTypes.string,
  stepPaginationTitleProps: PropTypes.object,
  showViewAllCardsButton: PropTypes.bool,
  isStepPaginationButtonsDisabled: PropTypes.bool,
  outerSectionClasses: PropTypes.string,
  cardsType: PropTypes.string,
  show: PropTypes.bool,
};
