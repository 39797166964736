import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/core/Button";
import EmptyData from "@/components/core/EmptyData";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import { calculateAddsAndDeletes } from "@/utils/common";

import { ROLE_TITLES_LABELS_PLURAL } from "@/constants/user";

import PeopleList from "../BillPayAndPayroll/Settings/Sliders/common/PeopleList";
import { ROLES_TYPE } from "../BillPayAndPayroll/Settings/Sliders/common/SettingsType";
import UserDropdown from "../BillPayAndPayroll/Settings/Sliders/common/UserDropdown";

const ADD_AND_EDIT_PEOPLE_MODE = {
  READ: "read",
  EDIT: "edit",
};
const HEADER_LABELS_CONFIG = {
  [ROLES_TYPE.ADMIN]: { label: ROLES_TYPE.ADMIN },
  [ROLES_TYPE.DEPARTMENT_MANAGER]: { label: ROLES_TYPE.DEPARTMENT_MANAGER },
};
function AddAndEditDumbPeople({
  mode = ADD_AND_EDIT_PEOPLE_MODE.READ,
  selectedUsers = [],
  allUsers = [],
  handleSubmit,
  groupRoles = true,
  setSelectedPeople = () => {},
  staticPeopleId = [],
  metaData = {
    title: "",
    description: "",
    editableTitle: "",
    editableDescription: "",
    emptyTitle: "",
    emptyDescription: "",
    emptyBtnLabel: "",
    emptyBtnProps: "",
    listHeading: "",
  },
  isFetching = { isFetchingSelectedPeople: false, isFetchingAllUser: false },
}) {
  const {
    title = "",
    description = "",
    editableTitle = "",
    editableDescription = "",
    emptyTitle = "",
    emptyDescription = "",
    emptyBtnLabel = "",
    emptyBtnProps = "",
    listHeading = "",
  } = metaData;
  const { t } = useTranslation();
  const { isFetchingSelectedPeople = false, isFetchingAllUser = false } =
    isFetching;
  const [componentMode, setComponentMode] = useState(
    ADD_AND_EDIT_PEOPLE_MODE.READ
  );
  const [preSelectedRoles, setPreSelectedRole] = useState({
    [ROLES_TYPE.ADMIN]: false,
    [ROLES_TYPE.DEPARTMENT_MANAGER]: false,
  });

  const _allUsers = useMemo(
    () =>
      allUsers?.map((item) => ({
        ...item,
        disabled: staticPeopleId?.includes(item?.id)
          ? true
          : [ROLES_TYPE.ADMIN, ROLES_TYPE.DEPARTMENT_MANAGER]?.includes(
              item?.role
            ) && groupRoles,
      })),
    [allUsers, groupRoles, staticPeopleId?.length]
  );

  const [tempList, setTempList] = useState(selectedUsers);

  const [tempListId, setTempListId] = useState([]);

  const isEmpty = selectedUsers.length === 0;

  const isEmptyViewIsNeeded =
    isEmpty &&
    componentMode !== ADD_AND_EDIT_PEOPLE_MODE.EDIT &&
    !preSelectedRoles?.[ROLES_TYPE.ADMIN]?.flag &&
    !preSelectedRoles?.[ROLES_TYPE.DEPARTMENT_MANAGER]?.flag;
  const getTranslatedUserCount = (transalation, translationProps) =>
    t(transalation, translationProps);
  const isNonEmptyViewIsInEditMode =
    componentMode === ADD_AND_EDIT_PEOPLE_MODE.EDIT;

  const allUserIdMap = useMemo(
    () =>
      _allUsers?.reduce((acc, curr) => {
        acc[curr?.id] = curr;
        return acc;
      }, {}),
    [_allUsers]
  );
  const allUserListStructureForPeopleListComponent = useMemo(() => {
    const adminObj = {
      itemData: {
        mainText: ROLE_TITLES_LABELS_PLURAL[ROLES_TYPE.ADMIN],
        subText: getTranslatedUserCount("company.travel.peopleCount", {
          count: preSelectedRoles[ROLES_TYPE.ADMIN]?.total,
        }),
        icon: "Admin",
      },
      groupSelfKey: ROLES_TYPE.ADMIN,
      deletable: false,
      isGroup: true,
    };
    const deptManagerObj = {
      isGroup: true,
      itemData: {
        mainText: ROLE_TITLES_LABELS_PLURAL[ROLES_TYPE.DEPARTMENT_MANAGER],
        subText: getTranslatedUserCount("company.travel.peopleCount", {
          count: preSelectedRoles[ROLES_TYPE.DEPARTMENT_MANAGER]?.total,
        }),
      },
      groupSelfKey: ROLES_TYPE.DEPARTMENT_MANAGER,
      deletable: false,
    };
    const staticPeopleList = staticPeopleId.map((i) => allUserIdMap[i]);
    const _tempListCleaned = [
      ...new Set([
        ...staticPeopleList.map(({ id }) => id),
        ...tempList.map(({ id }) => id),
      ]),
    ]?.map((i) => allUserIdMap[i]);

    const all = _tempListCleaned
      ?.filter(
        (item) =>
          !(
            [ROLES_TYPE.ADMIN, ROLES_TYPE.DEPARTMENT_MANAGER]?.includes(
              item?.role
            ) && groupRoles
          )
      )
      ?.map((item) => ({
        ...item,
        itemId: item?.id,
        deletable: staticPeopleId?.includes(item?.id)
          ? false
          : componentMode === ADD_AND_EDIT_PEOPLE_MODE.EDIT,
        itemData: item,
      }));

    let result = [];
    if (groupRoles) {
      if (preSelectedRoles[ROLES_TYPE.ADMIN]?.flag) {
        result.push(adminObj);
      }
      if (preSelectedRoles[ROLES_TYPE.DEPARTMENT_MANAGER]?.flag) {
        result.push(deptManagerObj);
      }
    }
    result = [...result, ...all];
    return result;
  }, [
    JSON.stringify(tempList),
    componentMode,
    JSON.stringify(preSelectedRoles),
    staticPeopleId?.length,
  ]);

  useEffect(() => {
    if (mode !== componentMode) setComponentMode(mode);
  }, [mode]);

  const handleInnerSubmit = ({ selectedIds, ...rest }) => {
    const selectUserList = selectedIds?.map((item) => allUserIdMap[item]);

    const selectUserIdList = selectedIds
      ?.filter((i) => !staticPeopleId?.includes(i))
      ?.map((item) => item);

    setTempList(selectUserList);
    setTempListId(selectUserIdList);
  };

  const handleEditModeSubmit = () => {
    const newSelectedIds = [...new Set(tempListId?.map((item) => item))];

    const selectedUserId = selectedUsers?.map((item) => item?.id ?? item);
    const { added: addedPeople, removed: removedPeople } =
      calculateAddsAndDeletes(newSelectedIds, selectedUserId);

    handleSubmit({
      selectedIds: newSelectedIds,
      removedPeople,
      addedPeople,
      allUserIdMap,
      onSuccess: () => {
        setComponentMode(ADD_AND_EDIT_PEOPLE_MODE.READ);
      },
    });
  };

  const handleEditDiscard = () => {
    resetData();
    setComponentMode(ADD_AND_EDIT_PEOPLE_MODE.READ);
  };

  const handleEditReadModeSubmit = () => {
    setComponentMode(ADD_AND_EDIT_PEOPLE_MODE.EDIT);
  };

  const resetData = () => {
    const _cloneSelectedUsers = structuredClone(selectedUsers);
    const _selectedUser = _cloneSelectedUsers?.filter(
      (i) => !staticPeopleId?.includes(i)
    );

    const selectUserList = _cloneSelectedUsers?.map((item) => {
      return typeof item !== typeof {} ? allUserIdMap[item] : item;
    });
    const selectUserIdList = _selectedUser?.map((item) =>
      typeof item === typeof {} ? item?.id : item
    );

    setTempList(selectUserList);
    setTempListId(selectUserIdList);
  };
  useEffect(() => {
    resetData();
  }, [
    JSON.stringify(selectedUsers),
    allUserIdMap,
    JSON.stringify(staticPeopleId),
  ]);

  useEffect(() => {
    if (staticPeopleId?.length) {
      const selectedPeople = staticPeopleId?.map((item) =>
        typeof item !== typeof {} ? allUserIdMap[item] : item
      );
      setSelectedPeople(selectedPeople);
    }

    if (groupRoles) {
      const preSelectedAdmin = _allUsers?.filter(
        (item) => item?.role === ROLES_TYPE.ADMIN
      );
      const totalAdminPreSelected = preSelectedAdmin?.length;
      const preSelectedDepartmentManager = _allUsers?.filter(
        (item) => item?.role === ROLES_TYPE.DEPARTMENT_MANAGER
      );
      const totalDepartmentManagerPreSelected =
        preSelectedDepartmentManager?.length;

      if (totalAdminPreSelected > 0) {
        setTempList((prev) => [...prev, ...preSelectedAdmin]);
      }
      if (totalDepartmentManagerPreSelected > 0) {
        setTempList((prev) => [...prev, ...preSelectedDepartmentManager]);
      }

      setPreSelectedRole({
        [ROLES_TYPE.ADMIN]: {
          flag: totalAdminPreSelected > 0,
          total: totalAdminPreSelected,
        },
        [ROLES_TYPE.DEPARTMENT_MANAGER]: {
          flag: totalDepartmentManagerPreSelected > 0,
          total: totalDepartmentManagerPreSelected,
        },
      });
    }
  }, [JSON.stringify(_allUsers), JSON.stringify(preSelectedRoles)]);

  return (
    <>
      <div className="slider-content-core pb-[150px] text-neutral-800 font-medium">
        {/* Slider heading text, description */}
        <div className="flex flex-col w-full mb-3">
          <Text
            // refProp={ref}
            translationKey={title}
            classes="text-3xl font-bold text-neutral-800"
          />
          <Text
            translationKey={description}
            classes="text-sm text-neutral-500 mt-1 font-medium"
          />
        </div>

        {isEmptyViewIsNeeded && !isFetchingSelectedPeople ? (
          <div className="absolute w-full h-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 mt-9">
            <EmptyData
              buttonLabel={emptyBtnLabel}
              buttonProps={emptyBtnProps}
              handleClick={() =>
                setComponentMode(ADD_AND_EDIT_PEOPLE_MODE.EDIT)
              }
              title={emptyTitle}
            >
              <Text
                classes="text-center w-4/5"
                translationKey={emptyDescription}
              />
            </EmptyData>
          </div>
        ) : isFetchingSelectedPeople ? (
          <LoaderSkeleton
            size="lg"
            borderRadius="200"
            fullWidth
            count={5}
            classes="py-4 my-2"
          />
        ) : (
          <div className="flex flex-col gap-4">
            {isNonEmptyViewIsInEditMode ? (
              <>
                <div className="flex flex-col gap-1">
                  <Text
                    translationKey={editableTitle}
                    classes="text-lg font-semibold font-bold text-neutral-800"
                  />
                  <Text
                    translationKey={editableDescription}
                    classes="text-xs font-medium text-neutral-500 mt-1 font-medium"
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <UserDropdown
                    groupAdminAndManager={groupRoles}
                    handleSubmit={handleInnerSubmit}
                    hideDisplayLabel
                    users={_allUsers}
                    headerLabels={groupRoles ? HEADER_LABELS_CONFIG : {}}
                    selectedIdsArray={tempListId}
                    isLoading={isFetchingAllUser}
                    {...(groupRoles ? {} : { dropdownLabelFastView: "" })}
                  />
                  {allUserListStructureForPeopleListComponent?.length ? (
                    <PeopleList
                      list={allUserListStructureForPeopleListComponent}
                      onDeleteHandler={(id) => {
                        setTempList((prev) =>
                          prev?.filter((item) => item?.id !== id)
                        );
                        setTempListId((prev) =>
                          prev?.filter((item) => item !== id)
                        );
                      }}
                      {...(allUserListStructureForPeopleListComponent?.length ===
                      0
                        ? { titleText: "" }
                        : { titleText: listHeading })}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <div className="mt-9">
                <PeopleList
                  list={allUserListStructureForPeopleListComponent}
                  titleText={listHeading}
                  readOnly
                />
              </div>
            )}
          </div>
        )}
      </div>
      {isEmptyViewIsNeeded ? null : (
        <div className="flex justify-end gap-3 px-4 py-4 slider-footer">
          {isNonEmptyViewIsInEditMode ? (
            <Button
              label="cardSetting.cardManagers.ctas.discard"
              variant="tertiary"
              classes="text-neutral-500 w-[150px]"
              onClick={handleEditDiscard}
            />
          ) : null}

          <Button
            label={
              isNonEmptyViewIsInEditMode
                ? "cardSetting.cardManagers.ctas.save"
                : "cardSetting.cardManagers.ctas.edit"
            }
            classes="w-[150px]"
            onClick={() =>
              isNonEmptyViewIsInEditMode
                ? handleEditModeSubmit()
                : handleEditReadModeSubmit()
            }
          />
        </div>
      )}
    </>
  );
}

export default AddAndEditDumbPeople;
AddAndEditDumbPeople.propTypes = {
  mode: PropTypes.string, // Mode of the component
  selectedUsers: PropTypes.array, // Array of selected users
  allUsers: PropTypes.array, // Array of all users
  handleSubmit: PropTypes.func, // Function to handle form submission
  groupRoles: PropTypes.bool, // Flag to indicate if roles should be grouped
  setSelectedPeople: PropTypes.func, // Function to set selected people
  staticPeopleId: PropTypes.array, // Array of static people IDs
  metaData: PropTypes.shape({
    title: PropTypes.string, // Title of the component
    description: PropTypes.string, // Description of the component
    editableTitle: PropTypes.string, // Editable title
    editableDescription: PropTypes.string, // Editable description
    emptyTitle: PropTypes.string, // Title for empty state
    emptyDescription: PropTypes.string, // Description for empty state
    emptyBtnLabel: PropTypes.string, // Label for empty state button
    emptyBtnProps: PropTypes.string, // Props for empty state button
    listHeading: PropTypes.string, // Heading for the list
  }),
  isFetching: PropTypes.shape({
    isFetchingSelectedPeople: PropTypes.bool, // Flag for fetching selected people
    isFetchingAllUser: PropTypes.bool, // Flag for fetching all users
  }),
};
