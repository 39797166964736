import PropTypes from "prop-types";

import Flag from "@/components/core/Flag";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import { FIXED_SIDE } from "@/constants/currency";

/**
 * @param {Array<Object>} mappedCurrencies
 * @param {Number} exchangeRate
 * @param {Boolean} sender
 * @param {Number} senderAmount
 * @param {Function} setSenderAmount
 * @param {String} senderCurrency
 * @param {Function} setSenderCurrency
 * @param {String} receiverCurrency
 * @param {Function} setReceiverCurrency
 */

function ExchangeInput({
  sender,
  senderCurrency,
  setSenderCurrency,
  receiverCurrency,
  setReceiverCurrency,
  setFixedSide,
  disabledState,
  handleSetSenderInputCurrencyChange,
  handleSetReceiverInputCurrencyChange,
  groupedOptions,
}) {
  return (
    <div>
      <div>
        <Text
          translationKey={
            sender
              ? "payroll.exchangeRate.senderTitle"
              : "payroll.exchangeRate.receipentTitle"
          }
          classes=" text-lg font-semibold"
        />
        <div className={`mt-8 ${disabledState ? "cursor-not-allowed" : ""}`}>
          <VpSelect
            label="payroll.exchangeRate.selectCurrency"
            value={sender ? senderCurrency : receiverCurrency}
            optionsDisplayKey="currency"
            valueKey="currency"
            disabled={disabledState}
            menuPosition="absolute"
            options={groupedOptions}
            isGroupedMode
            handleChange={(e) => {
              if (sender) {
                setSenderCurrency(e.currency);
                setFixedSide(FIXED_SIDE.SELL);
                handleSetSenderInputCurrencyChange();
              } else {
                setReceiverCurrency(e.currency);
                setFixedSide(FIXED_SIDE.BUY);
                handleSetReceiverInputCurrencyChange();
              }
            }}
            customUIForOptionWhenDropdownOpen={customEntryWhenDropdownIsOpen}
          />
        </div>
      </div>
    </div>
  );
}

export default ExchangeInput;

function customEntryWhenDropdownIsOpen(displayValue, option) {
  const { currency, fullName } = option;

  return (
    <div className="flex items-center gap-1 py-1">
      <Flag code={currency} size="md" classes="border border-neutral-200" />
      <Text
        classes="text-sm text-neutral-800 font-medium ml-2"
        translationKey={currency}
      />
      <Text
        classes="text-sm text-neutral-800 font-medium ml-2"
        translationKey={fullName}
      />
    </div>
  );
}
ExchangeInput.propTypes = {
  sender: PropTypes.bool,
  senderCurrency: PropTypes.string,
  setSenderCurrency: PropTypes.func,
  receiverCurrency: PropTypes.string,
  setReceiverCurrency: PropTypes.func,
  disabledState: PropTypes.bool,
  handleSetSenderInputCurrencyChange: PropTypes.func,
  handleSetReceiverInputCurrencyChange: PropTypes.func,
  setFixedSide: PropTypes.func,
  groupedOptions: PropTypes.object,
};
