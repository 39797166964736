import PropTypes from "prop-types";

import ReimbursementClaimList from "../ReimbursementClaimList";

export default function ReimbursementClaimHelper({ tabType }) {
  return <ReimbursementClaimList tabType={tabType} />;
}
ReimbursementClaimHelper.propTypes = {
  tabType: PropTypes.string,
};
