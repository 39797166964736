import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useOnClickOutside from "@/hooks/useClickOutside";
import useLoadingErrorInjector from "@/hooks/useErrorLoader";

import { fetchPaymentInfo } from "@/store/reducers/cards";

import { paymentInfoSelector } from "@/store/selectors/cards";
import { userSelector } from "@/store/selectors/user";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import {
  CARD_PROVIDER,
  CARD_STATUS,
  CARD_STATUS_ICON,
  CARD_TYPE,
} from "@/constants/Cards";
import { API_KEYS_MAPPING } from "@/api/apiKeys";

import AirwallexIframeComponent from "./AirwallexIframeComponent";
import CardDetailViewComponent from "./CardDetailViewComponent";
import LivquikIframeComponent from "./LivquikIframeComponent";

export default function CardDetailMask({
  slug,
  status,
  cardId,
  card,
  hideInitially = true,
}) {
  const ref = useRef(null);
  const [hide, setHide] = useState(hideInitially);
  const [pinIframeNumber, setPinIframeNumber] = useState(null);
  const dispatch = useDispatch();
  const onClickHandler = () => {
    setHide((_) => !_);
  };
  const loggedInUserInfo = useSelector(userSelector);
  const loggedInUserId = loggedInUserInfo?.id;
  const cardHoldInfo = card?.cardHolder;
  const cardType = card?.type;
  const cardHolderId = cardHoldInfo?.id;
  const paymentInfo = useSelector(paymentInfoSelector);
  const [showPin, setShowPin] = useState(false);
  const showCardDetailsButton =
    slug === CARD_PROVIDER.PINELABS
      ? status === CARD_STATUS.ACTIVE && hide
      : status === CARD_STATUS.ACTIVE;
  const showIssuedByText = slug === CARD_PROVIDER.PINELABS;
  const showShowPinSection =
    slug === CARD_PROVIDER.AIRWALLEX && loggedInUserId === cardHolderId;
  const enableClickOutside = slug === CARD_PROVIDER.LIVQUIK && hide;

  useEffect(() => {
    if (
      !hide &&
      [CARD_PROVIDER.PINELABS, CARD_PROVIDER.AYOCONNECT].includes(slug) &&
      paymentInfo?.result
    ) {
      window.open(paymentInfo?.result, "_blank");
    } else if (!hide && slug === CARD_PROVIDER.ICICI) {
      window.open(card?.cardActionLink, "_blank");
    } else if (!hide && slug === CARD_PROVIDER.LIVQUIK) {
      getPaymentInfo();
    }
  }, [hide]);

  useEffect(() => {
    if (slug !== CARD_PROVIDER.LIVQUIK) {
      getPaymentInfo();
    }
  }, []);

  useEffect(() => {
    if (slug === CARD_PROVIDER.AIRWALLEX) {
      getPinNumberForAwx(paymentInfo?.token);
    }
  }, [paymentInfo]);

  const getComponentBasedOnCardProvider = (currSlug, paymentInformation) => {
    switch (currSlug) {
      case CARD_PROVIDER.NIUM:
      case CARD_PROVIDER.UOB:
      case CARD_PROVIDER.PINELABS:
      case CARD_PROVIDER.AYOCONNECT:
      case CARD_PROVIDER.ICICI: {
        const { number = null, cvv = null } = paymentInformation;
        const expiry = `${paymentInformation?.cardExpiryMonth}/${paymentInformation?.cardExpiryYear}`;

        const cardNumber = number
          ? currSlug === CARD_PROVIDER.PINELABS ||
            (currSlug === CARD_PROVIDER.NIUM && cardType === CARD_TYPE.VIRTUAL)
            ? number
            : `XXXX XXXX XXXX ${number?.slice(-4)}`
          : "XXXXXXXXXXXXXXXX";

        return (
          <CardDetailViewComponent
            slug={currSlug}
            cardNumber={cardNumber}
            cvv={cvv}
            expiry={expiry}
            hide={hide}
            cardHolderName={card?.cardHolder?.displayName}
          />
        );
      }
      case CARD_PROVIDER.LIVQUIK: {
        const { result } = paymentInformation;
        return <LivquikIframeComponent iFrameUrl={result} />;
      }

      case CARD_PROVIDER.AIRWALLEX: {
        const { token = "" } = paymentInformation;

        return <AirwallexIframeComponent token={token} cards={card} />;
      }
      default:
        return null;
    }
  };

  const getPinNumberForAwx = (token) => {
    const hash = {
      token,
      rules: {
        ".pin__value": {
          textAlign: "right",
          fontSize: "14px",
          fontWeight: "600",
          color: "#1F2937",
          fontFamily: "Manrope",
        },
      },
    };

    const hashUri = encodeURIComponent(JSON.stringify(hash));

    setPinIframeNumber(
      `${import.meta.env.VITE_AIRWALLEX_CARD_HOST}/issuing/pci/v2/${card?.providerCardId}/pin#${hashUri}`
    );
  };

  function toggleShowPinUi() {
    setShowPin(!showPin);
  }

  function getPaymentInfo() {
    if (cardId) {
      dispatch(fetchPaymentInfo(cardId));
    }
  }

  const allState = useLoadingErrorInjector({
    apiKey: enableClickOutside ? null : API_KEYS_MAPPING.FETCH_PAYMENT_INFO,
    showLoader: false,
    error: {
      header: "Card",
    },
  });

  useOnClickOutside(ref, () => {
    if (slug === CARD_PROVIDER.LIVQUIK) {
      setHide(true);
    }
  });

  return (
    <div ref={allState?.attach}>
      <div ref={ref}>
        <div className="mb-5 rounded-2xl overflow-clip w-114">
          <div
            className={`relative my-5 card-wrapper border-b-0 rounded-2xl w-114 p-0 ${
              !showCardDetailsButton ? "h-fit" : "h-76.5"
            }`}
          >
            <div
              className={`h-62.5 flex flex-col ${
                !hide &&
                [
                  CARD_PROVIDER.NIUM,
                  CARD_PROVIDER.PINELABS,
                  CARD_PROVIDER.UOB,
                ].includes(slug)
                  ? "justify-end"
                  : "justify-between"
              }`}
            >
              <div className="h-full">
                {getComponentBasedOnCardProvider(slug, paymentInfo)}
              </div>

              <div
                className={
                  CARD_STATUS_ICON[status]
                    ? "absolute translate-y-1/2 -translate-x-1/2 text-neutral-400 bottom-1/2 left-1/2"
                    : "absolute left-[50%] -translate-x-1/2 bottom-1 h-[54px]"
                }
              >
                {showCardDetailsButton ? (
                  <div
                    className="flex items-center justify-center gap-2 border border-t-0 rounded-t-none cursor-pointer w-114 bg-neutral-50 h-11 rounded-2xl border-neutral-200"
                    onClick={onClickHandler}
                  >
                    <Icon
                      name={`${hide ? "Eye" : "SlashEye"}`}
                      className="text-primary-500"
                    />

                    <Text
                      translationKey={`${
                        hide ? "cards.showCardDetails" : "cards.hideCardDetails"
                      }`}
                      classes="text-primary-500"
                    />
                  </div>
                ) : CARD_STATUS_ICON[status] ? (
                  <Icon
                    className="w-11 h-11 text-neutral-500"
                    name={CARD_STATUS_ICON[status]}
                  />
                ) : null}
              </div>
            </div>
          </div>

          {showIssuedByText ? (
            <div className="flex items-center justify-center">
              <Text
                classes="text-neutral-500 text-sm font-semibold"
                translationKey="cards.cardSlider.cardMaskDetail.pinelabsIssuedTitle"
              />
            </div>
          ) : null}
        </div>

        {showShowPinSection ? (
          <div
            className="flex flex-row items-center justify-between mb-10 cursor-pointer card-wrapper w-114"
            onClick={toggleShowPinUi}
          >
            <div className="flex items-center gap-3">
              <Icon
                name={showPin ? "Eye" : "SlashEye"}
                className="text-neutral-500"
              />
              <Text
                translationKey={
                  showPin
                    ? "cards.cardSlider.cardMaskDetail.hidePin"
                    : "cards.cardSlider.cardMaskDetail.showPin"
                }
                classes="text-sm font-medium text-neutral-500"
              />
            </div>

            <div className={showPin ? "" : "invisible"}>
              <iframe
                src={pinIframeNumber}
                title="airwallecx card frame"
                className="w-10 h-4"
              />
            </div>

            {!showPin ? (
              <div className="flex">
                {[...Array(4)].map((dot, index) => (
                  <span
                    className="profile-widget-dot text-neutral-900"
                    key={index}
                  />
                ))}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

CardDetailMask.propTypes = {
  slug: PropTypes.string,
  cardId: PropTypes.number,
  hideInitially: PropTypes.bool,
  status: PropTypes.string,
  card: PropTypes.object,
};
