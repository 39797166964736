import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

export default function MainRoute() {
  if (
    import.meta.env.VITE_SHOW_DOWN_TIME === "true" ||
    (import.meta.env.VITE_START_DOWN_TIME && import.meta.env.VITE_END_DOWN_TIME)
  ) {
    return <Navigate to={ROUTES.downtime.base.absolutePath} replace />;
  }

  return <Outlet />;
}
