import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { activateMFA } from "@/store/reducers/user";

import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { SECURITY_SETTINGS_VERIFICATION_MODES } from "@/constants/user";

import TwoStepVerification from "./TwoStepVerification";

function TwoStepVerficationPage() {
  const dispatch = useDispatch();
  const { mfaMode: currentMFAMode = null } = useSelector(userSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRadioValueId, setSelectedRadioValueId] =
    useState(currentMFAMode);
  const handleClose = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.security.twoStepVerification);
    setSearchParams(searchParams);
  };

  const onSuccess = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.security.twoStepVerification);
    setSearchParams(searchParams);
  };

  const handleMFAChange = (mfaMode) => {
    // api call with values
    const payload = {
      mfa_mode: mfaMode,
    };

    dispatch(
      activateMFA({
        payload,
        onSuccess,
      })
    );
  };
  const cancelMFAChange = () => {
    handleClose();
  };
  const verificationConfig = [
    {
      id: 1,
      key: SECURITY_SETTINGS_VERIFICATION_MODES.MOBILE,
      icon: "Mobile",
      verificationMode: "security.mobileVerificationMode",
      description: "security.mobileVerificationModeDesc",
      handleChange: (key) => {
        setSelectedRadioValueId(key);
      },
    },
    {
      id: 2,
      key: SECURITY_SETTINGS_VERIFICATION_MODES.EMAIL,
      icon: "Email",
      verificationMode: "security.email",
      description: "security.emailVerificationModeDesc",
      handleChange: (key) => {
        setSelectedRadioValueId(key);
      },
    },
    {
      id: 3,
      key: SECURITY_SETTINGS_VERIFICATION_MODES.GAUTH,
      icon: "Authenticator",
      verificationMode: "security.googleAuthenticator",
      description: "security.googleAuthenticatorDesc",
      handleChange: (key) => {
        setSelectedRadioValueId(key);
      },
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-10 pb-6 slider-content-core ">
        <h1>
          <Text
            translationKey="security.twoStepVerification"
            classes="text-3xl text-neutral-800 font-bold"
          />
        </h1>
        <TwoStepVerification
          verificationConfig={verificationConfig}
          selectedRadioValueId={selectedRadioValueId}
          showRadioButton
        />
      </div>
      <div className="flex items-center justify-end gap-6 p-6 slider-footer">
        <Button
          label="cards.vCards.createCardSlider.cancel"
          variant="tertiary"
          classes="w-16 text-neutral-500 text-btn-lg bg-white hover:bg-neutral-300 border border-neutral-300"
          onClick={() => cancelMFAChange(selectedRadioValueId)}
        />
        <Button
          label="misc.saveChanges"
          classes="w-16 text-white text-btn-lg"
          disabled={[
            currentMFAMode,
            SECURITY_SETTINGS_VERIFICATION_MODES.GAUTH,
          ].includes(selectedRadioValueId)}
          onClick={() => handleMFAChange(selectedRadioValueId)}
        />
      </div>
    </>
  );
}

export default TwoStepVerficationPage;
