import PropTypes from "prop-types";

import Text from "@/components/core/Text";

export default function DescriptionCell({ val }) {
  return (
    <span
      id={`payroll-payments-description-${val?.id}`}
      className="inline-block w-full truncate"
    >
      <Text
        translationKey={val?.memo || val?.description || "-"}
        showTooltip
        noTranslate
      />
    </span>
  );
}

DescriptionCell.propTypes = {
  val: PropTypes.shape({
    id: PropTypes.any,

    vendor: PropTypes.object,
    memo: PropTypes.string,
    openSliderHandler: PropTypes.func,
    duplicateBillId: PropTypes.any,

    disabled: PropTypes.bool,
    checkedValue: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
  }),
};
