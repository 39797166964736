import PropTypes from "prop-types";

export default function LevelPill({
  children,
  containerClasses = "",
  innerClasses = "",
}) {
  return (
    <div
      className={`grid place-items-center px-2 py-[2px] min-w-[29px] rounded-full border-[1px] border-neutral-200 ${containerClasses}`}
    >
      <div className={innerClasses}>{children}</div>
    </div>
  );
}

LevelPill.propTypes = {
  children: PropTypes.any,
  containerClasses: PropTypes.string,
  innerClasses: PropTypes.string,
};
