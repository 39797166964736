import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function SpendTableLoader() {
  return Array(5)
    .fill(null)
    .map((_, idx) => (
      <tr key={idx}>
        <td>
          <div className="flex gap-3 items-center h-[64px]">
            <LoaderSkeleton type="circle" size="lg" />
            <LoaderSkeleton size={[20, 180]} />
          </div>
        </td>
        {/* <td className="text-center">
          <LoaderSkeleton size="lg" />
        </td> */}
        <td className="text-center">
          <LoaderSkeleton size="lg" />
        </td>
        <td className="text-right">
          <LoaderSkeleton size="lg" />
        </td>
        <td className="text-right">
          <LoaderSkeleton size="lg" />
        </td>
      </tr>
    ));
}
