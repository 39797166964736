import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchTags } from "@/store/reducers/tags";

import { isFetchingTagsSelector } from "@/store/selectors/tags";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import DateInputTextBox from "@/components/common/DateInputTextBox";
import { toCamelCase } from "@/utils/common";

import { TAG_FIELD_TYPES } from "@/constants/tags";

/**
 * TagInput component for handling tag inputs.
 *
 * @param {Object} values - The initial values for the input fields.
 * @param {Object} errors - The error object for the input fields.
 * @param {Function} handleChange - The function to handle input field changes.
 * @param {Function} setValues - The function to update the input field values.
 * @param {Boolean} disabled - A flag to disable the input fields.
 * @param {String} [dropdownInputKey="dropdownInputKey"] - The key for the dropdown input field.
 * @param {String} [textInputKey="textInputKey"] - The key for the text input field.
 * @param {Object} [vpSelectProps={}] - The props for the VpSelect component.
 * @param {Object} [inputProps={}] - The props for the Input component.
 * @param {Object} [tag={}] - The initial tag object.
 * @param {String} [label] - The label for the input fields.
 * @param {Boolean} [converKeyToCamelCase=false] - A flag to convert the input field keys to camel case.
 * @param {Boolean} [isDirectValue=false] - A flag to indicate if the input field values are direct values.
 * @param {Boolean} [wantValueOnBlur=false] - A flag to indicate if the input field values should be updated on blur.
 * @param {Boolean} [callTags=true] - A flag to indicate if tags should be fetched.
 * @param {Boolean} [wantEditable=false] - A flag to indicate if the text input field should be editable.
 *
 * @returns {JSX.Element} - The rendered TagInput component.
 */
export default function TagInput({
  values,
  errors,
  handleChange,
  setValues,
  disabled,

  // use generalized enums, not feature specific
  dropdownInputKey = "dropdownInputKey",
  textInputKey = "textInputKey",
  vpSelectProps = {}, // dropdown component
  dateInputProps = {}, // dropdown component
  inputProps = {}, // text input component
  tag = {},
  label,
  converKeyToCamelCase = false,
  isDirectValue = false,
  wantValueOnBlur = false,
  callTags = true,
  wantEditable = false,
  inputClasses = "",
  vpSelectClasses = "",
  labelStyleClasses = "",
  insideTable = false,
}) {
  const dispatch = useDispatch();
  const isFetchingTags = useSelector(isFetchingTagsSelector);

  const {
    id: tagId = -1,
    name: tagName,
    fieldType: tagFieldType,
    options: accountingCategoryOptions = [],
    textValue: accountingCategoryTextValue = "",
  } = tag ?? {};

  const _textInputKey = converKeyToCamelCase
    ? toCamelCase(textInputKey)
    : textInputKey;
  const _dropdownInputKey = converKeyToCamelCase
    ? toCamelCase(dropdownInputKey)
    : dropdownInputKey;

  const accountingTagTypeIsText = tagFieldType === TAG_FIELD_TYPES.TEXT;
  const accountingTagTypeIsDate = tagFieldType === TAG_FIELD_TYPES.DATE;
  const isFetchingTagOptions = useSelector(isFetchingTagsSelector);

  const [textInputValue, setTextValue] = useState(values?.[_textInputKey]);
  const [isFocus, setIsFocus] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (callTags && !isFetchingTags) dispatch(fetchTags({ visible: true })); // accounting category
  }, []);
  //
  useEffect(() => {
    if (wantValueOnBlur)
      setTextValue(isDirectValue ? values : values?.[_textInputKey]);
  }, [isDirectValue ? values : null]);

  const getTextComponent = () => {
    return !wantEditable ? (
      <Input
        onClick={(e) => {
          e?.stopPropagation();
        }}
        name={_textInputKey}
        classes={inputClasses}
        value={isDirectValue ? textInputValue : values?.[_textInputKey]}
        error={errors?.[_textInputKey]}
        label={label || tagName}
        onChange={(e) => {
          if (isDirectValue) {
            setTextValue(e?.target?.value);
          } else return handleChange(e);
        }}
        onFocus={(e) => {
          setIsFocus(true);
        }}
        onBlur={(e) => {
          if (isDirectValue && wantValueOnBlur && isFocus) {
            handleChange({
              textValue: textInputValue,
              ...tag,
              id: tag?.actualId || tag?.id,
            });
            setIsFocus(false);
          }
        }}
        placeholder="tags.enterCustomTagValue"
        disabled={disabled}
        showOptional={!tag?.required}
        {...inputProps}
      />
    ) : wantEditable && isEditing ? (
      <Input
        onClick={(e) => {
          e?.stopPropagation();
        }}
        name={_textInputKey}
        classes={inputClasses}
        value={isDirectValue ? textInputValue : values?.[_textInputKey]}
        error={errors?.[_textInputKey]}
        label={label || tagName}
        isFocus
        onChange={(e) => {
          if (isDirectValue) {
            setTextValue(e?.target?.value);
          } else return handleChange(e);
        }}
        onFocus={(e) => {
          setIsFocus(true);
        }}
        onBlur={(e) => {
          if (isDirectValue && wantValueOnBlur && isFocus) {
            handleChange({
              textValue: textInputValue,
              ...tag,
              id: tag?.actualId || tag?.id,
            });
            setIsFocus(false);
            setIsEditing((prev) => !prev);
          }
        }}
        placeholder="tags.enterCustomTagValue"
        disabled={disabled}
        showOptional={!tag?.required}
        {...inputProps}
      />
    ) : (
      <div
        onClick={(e) => {
          e?.stopPropagation();
          if (!disabled) setIsEditing((prev) => !prev);
        }}
      >
        <Text
          classes="w-full"
          translationKey={
            textInputValue || values?.[_textInputKey] || label || tagName
          }
        />
      </div>
    );
  };
  const getListComponent = () => {
    return (
      <VpSelect
        name={_dropdownInputKey}
        value={isDirectValue ? values : values?.[_dropdownInputKey]}
        error={errors?.[_dropdownInputKey]}
        handleChange={handleChange}
        insideForm
        menuPosition="absolute"
        classes={vpSelectClasses}
        labelStyleClasses={labelStyleClasses}
        optionsDisplayKey="name"
        valueKey="id"
        options={accountingCategoryOptions}
        isOptionsLoading={isFetchingTagOptions}
        label={label || tagName}
        placeholder="tags.selectTagOption"
        disabled={disabled}
        showOptional={!tag?.required}
        insideTable={insideTable}
        {...vpSelectProps}
      />
    );
  };
  const getDateComponent = () => {
    return (
      <DateInputTextBox
        name={_dropdownInputKey}
        value={isDirectValue ? values : values?.[_dropdownInputKey]}
        error={errors?.[_dropdownInputKey]}
        handleChange={(e) => handleChange({ ...tag, ...e })}
        label={label || tagName}
        disabled={disabled}
        showOptional={!tag?.required}
        {...dateInputProps}
      />
    );
  };
  const getComponent = () => {
    if (accountingTagTypeIsText) return getTextComponent();
    if (accountingTagTypeIsDate) return getDateComponent();
    return getListComponent();
  };
  return getComponent();
}

TagInput.propTypes = {
  values: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,

  dropdownInputKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textInputKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  vpSelectProps: PropTypes.object,
  inputProps: PropTypes.object,
  tag: PropTypes.object,
  converKeyToCamelCase: PropTypes.bool,
  disabled: PropTypes.bool,
  isDirectValue: PropTypes.bool,
  wantValueOnBlur: PropTypes.bool,
  label: PropTypes.string,
  callTags: PropTypes.bool,
  classes: PropTypes.string,
  wantEditable: PropTypes.bool,
  labelStyleClasses: PropTypes.string,
  vpSelectClasses: PropTypes.string,
  insideTable: PropTypes.bool,
};
