import PropTypes from "prop-types";
import { Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";

import { isAdminSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

/**
 * Popup that renders a kebab menu.
 * has two variations for each entry: icon or (icon + text)
 *
 * Usage: It's shown on secondary button click (the button on the left, in the right portion of the footer)
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=11743-248820&mode=design&t=4DeyLXcykPfdVAZQ-4}
 */
export default function MoreOptions({
  ctaConfigs = [],
  iconOnly = true,
  buttonProps = {},
  data = null,
  disabled = false,
  componentProvidedStuff,
  context,
}) {
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const isAdmin = useSelector(isAdminSelector);

  const popupRef = useRef();
  // provided to CTA handler
  const handlePopupToggle = () => {
    popupRef.current.toggle();
  };

  const groupLabelIndex = Array.isArray(ctaConfigs)
    ? ctaConfigs.length -
      ctaConfigs.reduce(
        (accum, item) => accum + (item?.isShowInAdminGroup ? 1 : 0),
        0
      )
    : -1;

  return (
    <Popup
      ref={popupRef}
      repositionOnResize
      trigger={(isPopupOpen) => (
        <div>
          <Button
            {...buttonProps}
            label={iconOnly ? "" : "billPay.bill.moreOptionsLabel"}
            classes="w-fit px-3 font-base text-primary-500 font-semibold"
            variant="tertiary"
            onClick={handlePopupToggle}
            disabled={disabled}
            preIcon="MoreVerticle"
            iconClasses={`h-6 w-6 ${iconOnly ? "text-neutral-500" : ""}`}
            innerClasses={iconOnly ? "gap-[0px]" : "gap-[2px]"}
          />
        </div>
      )}
      closeOnDocumentClick
      position="top right"
      className="border-0 filter-popup"
    >
      <div className="flex flex-col min-w-[251px]">
        {ctaConfigs
          .toSorted(
            (a, b) =>
              Number(a?.isShowInAdminGroup || false) -
              Number(b?.isShowInAdminGroup || false)
          )
          .map((ctaConfig, index) => {
            const label =
              typeof ctaConfig?.label === typeof ""
                ? ctaConfig?.label
                : ctaConfig?.label?.(inPayrollContext);

            const hoverText =
              typeof ctaConfig?.hoverText === typeof ""
                ? ctaConfig?.hoverText
                : ctaConfig?.hoverText?.({
                    ...data,
                    isAdmin,
                    context,
                  });

            return (
              <Fragment key={label}>
                {isAdmin &&
                ctaConfig?.isShowInAdminGroup &&
                groupLabelIndex === index ? (
                  <Text
                    classes="text-neutral-500 font-semibold text-xs px-3 pt-2 pb-1"
                    translationKey={
                      inPayrollContext
                        ? "reimbursement.ctaGroupLabelPayroll"
                        : "reimbursement.ctaGroupLabelBillpay"
                    }
                  />
                ) : null}

                <Button
                  id={`footer-menu-item-tooltip-${data.id}-${label}`}
                  key={label}
                  // fine key, since it's not going to change per request
                  variant="tertiary"
                  classes="border-0 w-full p-4 pl-3 pr-6 font-medium text-neutral-500 hover:text-neutral-900"
                  textAlignment="text-left"
                  label={label}
                  onClick={() => {
                    popupRef.current.close(); // close the context menu
                    ctaConfig.onClick(data, componentProvidedStuff);
                  }}
                />
                {hoverText ? (
                  <Tooltip
                    id={`footer-menu-item-tooltip-${data.id}-${label}`}
                    direction="top-right"
                    maxWidth={450}
                  >
                    <Text
                      translationKey={hoverText}
                      classes="text-neutral-500 text-sm font-medium"
                    />
                  </Tooltip>
                ) : null}
              </Fragment>
            );
          })}
      </div>
    </Popup>
  );
}

MoreOptions.propTypes = {
  options: PropTypes.array,
  onOptionClick: PropTypes.func,
  buttonProps: PropTypes.object, // passed as is to core/Button
  iconOnly: PropTypes.bool,
  ctaConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      // for more info, see payment cta configs in the constants file
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      icon: PropTypes.string,
      type: PropTypes.string,
      variant: PropTypes.string,
      classes: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  data: PropTypes.object, // the target entity,
  // Example: in slider, this is the slider's object as fetched from BE
  // in a listing, it's the object corresponding to the row
  componentProvidedStuff: PropTypes.object,
  context: PropTypes.string,
};
