import PropTypes from "prop-types";
import React, { useCallback } from "react";

import CopytoClipboard from "@/components/core/CopyToClipboard";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import AyoConnectCardPreview from "@/assets/images/AyoConnectCardPreview.png";
import Chip from "@/assets/images/Chip.png";
import InternationalCardChip from "@/assets/images/InternationalCardChip.png";
import MasterCardImage from "@/assets/images/MC.png";
import VisaCorporateIcon from "@/assets/images/VISA_Corporate_1.svg";
import VisaPlatinumIcon from "@/assets/images/VISA_platinum_2.svg";
import niumIcon from "@/assets/images/nium.png";

function CardDetailViewComponent({
  slug,
  cardNumber,
  cvv,
  expiry,
  hide,
  cardHolderName,
}) {
  const ICON_COMPONENT_TYPES = {
    ICON: "icon",
    COMPONENT: "component",
    IMAGE: "img",
  };

  const CARD_PROVIDER_ENUM = {
    icici: {
      headerLeftIcon: {
        innerContent: "VolopayLogoOnCard",
        type: ICON_COMPONENT_TYPES.ICON,
      },
      chip: {
        innerContent: InternationalCardChip,
        type: ICON_COMPONENT_TYPES.IMAGE,
        classes: "w-9 ml-6 text-white",
      },
      cardNumberClasses: "text-neutral-800",
      configSecurityDetailsMainContainerClasses: "gap-6",
      showCardNumberCopyIcon: true,
      footerRightIcon: {
        innerContent: (
          <div className="flex flex-col items-end mt-3">
            <Icon
              name="VisaCorporateSvg"
              className="w-14 text-neutral-800 h-min"
            />

            <Text
              translationKey="cards.cardSlider.purchasing"
              classes="text-xs text-neutral-600"
            />
          </div>
        ),
        type: ICON_COMPONENT_TYPES.COMPONENT,
      },
      footerLeftIcon: {
        innerContent: (
          <div className="flex flex-col justify-start">
            <Text
              translationKey={cardHolderName}
              classes="text-neutral-800 uppercase font-normal text-sm"
            />

            <Text
              translationKey="cards.cardSlider.cardMaskDetail.volopayIndia"
              classes="text-neutral-800 uppercase font-normal text-sm"
            />
          </div>
        ),
        type: ICON_COMPONENT_TYPES.COMPONENT,
      },
      cardCopyIconClasses: "text-primary-500",
    },
    nium: {
      headerLeftIcon: {
        innerContent: niumIcon,
        type: ICON_COMPONENT_TYPES.IMAGE,
      },
      headerRightIcon: {
        innerContent: "VolopayLogoOnCard",
        type: ICON_COMPONENT_TYPES.ICON,
      },
      footerRightIcon: {
        innerContent: VisaPlatinumIcon,
        type: ICON_COMPONENT_TYPES.IMAGE,
        classes: "h-9",
      },
      expiry: {
        value: expiry,
        showCopyIcon: true,
      },
      cvv: {
        value: cvv,
        showCopyIcon: true,
      },
      showCardNumberCopyIcon: true,
      footerBackgroundClass: "bg-neutral-100",
      showTopCardHolderTitle: true,
    },
    ayoconnect: {
      headerLeftIcon: {
        innerContent: "VolopayLogoOnCard",
        type: ICON_COMPONENT_TYPES.ICON,
        classes: "text-white",
      },
      expiry: {
        value: "XX / XX",
        configSecurityDetailsClassses: "flex flex-row gap-4",
        securityDetailsTitleClasses: "text-white",
        classes: "text-white",
      },
      cvv: {
        value: "***",
        showCopyIcon: false,
        securityDetailsTitleClasses: "text-white",
        configSecurityDetailsClassses: "flex flex-row gap-4",
        classes: "text-white",
      },
      showCardNumberCopyIcon: false,
      cardBgImage: VisaCorporateIcon,
      cardNumberClasses: "text-white mb-9",
      cardPreviewBgImage: AyoConnectCardPreview,
      configSecurityDetailsMainContainerClasses: "gap-13",
      footerBackgroundClass: "mb-3",
      footerRightIcon: {
        innerContent: MasterCardImage,
        type: ICON_COMPONENT_TYPES.IMAGE,
        classes: "h-9",
      },
      footerLeftIcon: {
        innerContent: (
          <Text
            translationKey={cardHolderName}
            classes="text-xs font-bold tracking-[0.3em] uppercase ${bottomCardHolderTextClasses text-white"
          />
        ),
        type: ICON_COMPONENT_TYPES.COMPONENT,
      },
    },
    uob: {
      headerRightIcon: {
        innerContent: "VolopayLogoOnCard",
        type: ICON_COMPONENT_TYPES.ICON,
      },
      footerRightIcon: {
        innerContent: VisaCorporateIcon,
        type: ICON_COMPONENT_TYPES.IMAGE,
        classes: "h-9",
      },
      expiry: {
        value: "XX / XX",
      },
      cvv: {
        value: "XX",
      },
      showCvvCopyIcon: true,
      showExpiryCopyIcon: true,
      showCardNumberCopyIcon: false,
      footerBackgroundClass: "bg-neutral-100",
      showCardHolderTitle: true,
    },
    pinelabs: {
      cardBgColor: hide
        ? "bg-neutral-500 opacity-60"
        : "bg-pinelabsCardDetailMask",
      cardHeight: "h-62.5",
      footerRightIcon: {
        innerContent: (
          <div className="flex flex-col items-end">
            <Icon name="Rupay" />

            <Text
              translationKey="cards.cardSlider.cardMaskDetail.prepaid"
              classes="text-xs text-white uppercase italic"
            />
          </div>
        ),
        type: ICON_COMPONENT_TYPES.COMPONENT,
      },
      headerLeftIcon: {
        innerContent: "VolopayLogoOnCard",
        type: ICON_COMPONENT_TYPES.ICON,
        classes: "text-white",
      },
      headerRightIcon: {
        innerContent: "PineLabs",
        type: ICON_COMPONENT_TYPES.ICON,
        classes: "h-6 w-fit text-white",
      },
      expiry: {
        value: (
          <div className="flex flex-col justify-between">
            <Text
              translationKey="cards.cardSlider.maskedNumber"
              classes="text-white"
            />
            <Text
              translationKey="cards.cardSlider.corporate"
              classes="text-2xs font-semibold text-neutral-500 uppercase"
            />
          </div>
        ),
        showCopyIcon: false,
        classes: "text-white",
      },
      chip: {
        innerContent: Chip,
        type: ICON_COMPONENT_TYPES.IMAGE,
        classes: "w-9 ml-6",
      },
      cvv: {
        value: "XXX",
        showCopyIcon: false,
        classes: "text-white",
      },
      cardNumberClasses: "text-white",
      showTopCardHolderTitle: false,
      showCardNumberCopyIcon: false,
    },
  };

  const {
    headerLeftIcon = null,
    headerRightIcon = null,
    showCardNumberCopyIcon = true,
    cardCopyIconClasses,
    expiry: cardExpiry,
    cvv: cardCvv,
    chip,
    footerBackgroundClass,
    footerRightIcon,
    footerLeftIcon,
    cardNumberClasses,
    showTopCardHolderTitle,
    cardBgColor,
    cardHeight,
    cardPreviewBgImage = null,
    configSecurityDetailsMainContainerClasses = null,
  } = CARD_PROVIDER_ENUM[slug] || {};

  const CONFIG_SECURITY_DETAILS = [
    {
      label: "cards.paymentDetails.valid",
      dataConfig: cardExpiry,
      show: !!cardExpiry,
    },
    {
      label: "cards.paymentDetails.cvv",
      dataConfig: cardCvv,
      show: !!cardCvv,
    },
  ];

  const { bannerBgColor = null } = CARD_PROVIDER_ENUM[slug] || {};

  function getIconComponent(iconConfig) {
    if (!iconConfig) return null;

    const { innerContent, type, classes } = iconConfig;

    if (type === ICON_COMPONENT_TYPES.ICON) {
      return (
        <Icon
          name={innerContent}
          className={`h-10 w-15 text-neutral-700 ${classes}`}
        />
      );
    }

    if (type === ICON_COMPONENT_TYPES.COMPONENT) {
      return innerContent;
    }

    return <img src={innerContent} alt="icon" className={classes} />;
  }

  const getCardNumberWithCss = useCallback(() => {
    const formattedCardNumber = cardNumber.split("");

    return (
      <div className="flex flex-row gap-2">
        {formattedCardNumber?.map((digit, key) => (
          <Text
            key={digit + key}
            classes={`text-lg ${cardNumberClasses}`}
            translationKey={digit === "*" ? "X" : digit}
          />
        ))}
      </div>
    );
  }, [cardNumber]);

  return (
    <div
      className={`flex flex-col rounded-lg w-full relative ${cardBgColor} ${
        hide ? "blur-sm" : ""
      } ${cardHeight ? cardHeight : "h-full"}`}
    >
      {cardPreviewBgImage ? (
        <img
          src={cardPreviewBgImage}
          alt=""
          style={{ position: "absolute", zIndex: "-1" }}
        />
      ) : null}

      <div
        className={`p-4 grow flex flex-col justify-between pb-1 ${bannerBgColor}`}
      >
        <div className="flex items-center justify-between">
          <div>{getIconComponent(headerLeftIcon)}</div>

          <div>{getIconComponent(headerRightIcon)}</div>
        </div>

        {getIconComponent(chip)}

        <div className="flex items-center gap-2 justify-left mt-9.5">
          {getCardNumberWithCss()}

          {showCardNumberCopyIcon ? (
            <CopytoClipboard
              text={cardNumber}
              iconClasses={`text-business-accounts-500 ${cardCopyIconClasses}`}
            />
          ) : null}
        </div>
      </div>

      <div className={`${footerBackgroundClass}`}>
        <div className="flex items-end justify-between p-4 pt-2">
          <div>
            {showTopCardHolderTitle ? (
              <Text
                translationKey={cardHolderName}
                classes="text-xs font-bold tracking-[0.3em] uppercase"
              />
            ) : null}

            {CONFIG_SECURITY_DETAILS.length > 0 ? (
              <div
                className={`flex items-start pt-2 justify-left ${configSecurityDetailsMainContainerClasses}`}
              >
                {CONFIG_SECURITY_DETAILS.map((card, index) => {
                  const {
                    showCopyIcon = null,
                    value = null,
                    classes = null,
                    configSecurityDetailsClassses = null,
                    securityDetailsTitleClasses = null,
                  } = card?.dataConfig ?? {};

                  return card?.show ? (
                    <div
                      key={`${card?.label}-${index}`}
                      className={configSecurityDetailsClassses}
                    >
                      <Text
                        translationKey={card.label}
                        classes={`text-3xs text-neutral-500 uppercase ${securityDetailsTitleClasses}`}
                      />
                      <div className="flex items-center gap-1">
                        {typeof value === typeof "" ? (
                          <Text translationKey={value} classes={classes} />
                        ) : (
                          value
                        )}

                        {showCopyIcon ? (
                          <CopytoClipboard
                            iconClasses="text-business-accounts-500 p-0"
                            text={value}
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            ) : null}

            {getIconComponent(footerLeftIcon)}
          </div>
          <div>{getIconComponent(footerRightIcon)}</div>
        </div>
      </div>
    </div>
  );
}

export default CardDetailViewComponent;
CardDetailViewComponent.propTypes = {
  slug: PropTypes.string,
  cardNumber: PropTypes.string || PropTypes.number,
  cvv: PropTypes.number,
  expiry: PropTypes.string,
  hide: PropTypes.bool,
  cardHolderName: PropTypes.string,
};
