import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";

import { setIndexApiReload } from "@/store/reducers/app";
import {
  blockCard,
  fetchAndSelectCard,
  fetchCards,
  freezeCard,
  resetCard,
  resetCardSettingsFormInfo,
  resetCardsListAndPagination,
  resetPinUrl,
  setCardSliderTab,
  setMerchantRestrictions,
  setPaymentInfo,
  unfreezeCard,
} from "@/store/reducers/cards";
import { setRules } from "@/store/reducers/rules";
import { fetchUserPhysicalCard } from "@/store/reducers/user";

import { indexApiReloadSelector } from "@/store/selectors/app";
import {
  cardSliderSelectedTabSelector,
  cardSliderTabsListSelector,
  isFetchingSelectedCardSelector,
  resetPinUrlSelector,
  selectedCardCtaBtnsSelector,
  selectedCardProviderDataSelector,
  selectedCardSelector,
} from "@/store/selectors/cards";
import { userSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Tabs from "@/components/core/Tabs";

import { freezeUnfreezeBlockHandler } from "@/components/Cards/util";
import {
  amountToCurrency,
  dateToString,
  dateToTimeStampString,
} from "@/utils/common";

import {
  CARDS_ALL_SLIDER_MENU_CONFIG,
  CARDS_ALL_SLIDER_MENU_KEYS,
  CARDS_SLIDER_ACTION_TYPES,
  CARD_FREQUENCY_LABEL,
  CARD_OPERATION_TYPES,
  CARD_PENDING_REQUEST,
  CARD_PROVIDER,
  CARD_SLIDER_SELECTED_TABS_CARD,
  CARD_SLIDER_TABS_KEY,
  CARD_STATUS,
  CARD_TYPE,
  CHANGE_PIN_METHODS_TYPE,
  FREEZE_TYPE_LABELS,
  MENU_KEY_ACTION_TYPES,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import { API_KEYS_MAPPING } from "@/api/apiKeys";

import CardSliderMenu from "./Common/CardSliderMenu";
import CardSummaryShort from "./Common/CardSumaryShort";
import HistoryTab from "./HistoryTab";
import OverviewTab from "./OverviewTab";
import CardSettings from "./SettingsTab";

// const inAU = false; // handle later

export default function CardSlider({ setOnClose, setIsSliderLoading }) {
  const { t } = useTranslation();

  const CARD_SLIDER_TABS_KEY_SPECIFIC_COMPONENT = {
    [CARD_SLIDER_TABS_KEY.OVERVIEW]: OverviewTab,
    [CARD_SLIDER_TABS_KEY.CARD_SETTINGS]: CardSettings,
    [CARD_SLIDER_TABS_KEY.HISTORY]: HistoryTab,
  };
  const locationObject = useLocation();
  const pagePathName = locationObject?.pathname;

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const sliderId = searchParams.get(SLIDERS_SEARCH_PARAMS.cards.id);

  const card = useSelector(selectedCardSelector);
  const topUpRequests = card?.topUpRequests;
  const currency = topUpRequests?.currency;
  const amount = topUpRequests?.amount;
  const inSufficientFunds = topUpRequests?.insufficientFunds;
  const isFetching = useSelector(isFetchingSelectedCardSelector);
  const currentUser = useSelector(userSelector);

  const cardSlug = card?.slug;

  const cardId = searchParams.get(SLIDERS_SEARCH_PARAMS.cards.id);

  const sliderBtns = useSelector(selectedCardCtaBtnsSelector);

  const cardProviderData = useSelector(selectedCardProviderDataSelector);
  const selectedCardProviderActions = cardProviderData?.config?.actions;

  const selectedTab = useSelector(cardSliderSelectedTabSelector);

  const tabsList = useSelector(cardSliderTabsListSelector);

  const pendingRequest = card?.pendingRequest;
  const showPendingLimitRequestAlert =
    pendingRequest === "card_limit_update" && cardSlug === CARD_PROVIDER.UOB;

  const changePinMethod =
    card?.changePinMethod && card?.changePinMethod?.length > 0
      ? card?.changePinMethod
      : null;

  const changePinToolTip =
    card?.changePinTooltip && card?.changePinTooltip?.length > 0
      ? card?.changePinTooltip
      : null;

  const [ctasConfig, setCtasConfig] = useState([]);

  const resetPinLinkInfo = useSelector(resetPinUrlSelector);
  const resetPinLink = resetPinLinkInfo?.result;

  const cardType = card?.type;

  const cardStatus = card?.status;

  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const cardPendingRequest = card?.pendingRequest;

  const noteConfig = getNoteByCardStatus();

  const cardSummaryShortHeading =
    cardType === CARD_TYPE.PHYSICAL
      ? card?.cardHolder?.displayName
      : card?.name;

  const TabComponent =
    CARD_SLIDER_TABS_KEY_SPECIFIC_COMPONENT[selectedTab?.key];

  useEffect(() => {
    if (sliderId) {
      fetchSelectedCardData();
    }

    setOnClose((searchParamsArray) => {
      if (!searchParamsArray.includes(SLIDERS_SEARCH_PARAMS.cards.id)) {
        dispatch(resetCardSettingsFormInfo());
        dispatch(setMerchantRestrictions({}));
        dispatch(setRules([]));
        dispatch(setCardSliderTab(CARD_SLIDER_SELECTED_TABS_CARD[0]));
        if (
          pagePathName !==
          ROUTES.myVolopay.cards.physicalCards.active.absolutePath
        ) {
          dispatch(setPaymentInfo({}));
        }
        dispatch(resetCard());
      }
    });
  }, [sliderId]);

  useEffect(() => {
    generateCardsSliderCtaBtnsConfig();
  }, [sliderBtns]);

  useEffect(() => {
    if (resetPinLink) {
      window.open(resetPinLink, "_blank");
    }
  }, [resetPinLink]);

  useEffect(() => {
    setIsSliderLoading(isFetching);
  }, [isFetching]);

  function handleMenuClick(menuKey) {
    const menuKeyActionType =
      selectedCardProviderActions?.[MENU_KEY_ACTION_TYPES?.[menuKey]];

    switch (menuKey) {
      case CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN:
        handleChangePinBtnClick(menuKeyActionType, menuKey);
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD:
      case CARDS_ALL_SLIDER_MENU_KEYS.BLOCK:
      case CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE:
        handleFreezeUnFreezeAndBlockBtnClick(menuKeyActionType, menuKey);
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD:
        searchParams.append(SLIDERS_SEARCH_PARAMS.cards.editLimit, card.id);
        setSearchParams(searchParams);
        break;
      default:
        break;
    }
  }

  const _config = {
    physical: {
      [ROUTES.cards.physicalCards.all.absolutePath]: [
        CARD_STATUS.ACTIVE,
        CARD_STATUS.FROZEN,
      ],
      [ROUTES.cards.physicalCards.yetToActivate.absolutePath]: [
        CARD_STATUS.INACTIVE,
      ],
      [ROUTES.cards.physicalCards.blocked.absolutePath]: [CARD_STATUS.BLOCKED],
    },
    virtual: {
      [ROUTES.cards.virtualCards.all.absolutePath]: [
        CARD_STATUS.ACTIVE,
        CARD_STATUS.FROZEN,
      ],
      [ROUTES.cards.virtualCards.blocked.absolutePath]: [CARD_STATUS.BLOCKED],
    },
  };

  function toggleIndexApiBool() {
    dispatch(setIndexApiReload(!reloadIndexApi));
  }

  function handleCardStatusChange() {
    toggleIndexApiBool();

    fetchSelectedCardData();

    if (
      window.location.pathname ===
      ROUTES.myVolopay.cards.physicalCards.active.absolutePath
    ) {
      dispatch(fetchUserPhysicalCard({ id: currentUser?.id }));
    }
  }

  function handleChangePinBtnClick(menuKeyActionType, menuKey) {
    switch (menuKeyActionType) {
      case CARDS_SLIDER_ACTION_TYPES.INTENT_API:
        searchParams.append(SLIDERS_SEARCH_PARAMS.cards.changePin, card?.id);
        setSearchParams(searchParams);
        break;

      case CARDS_SLIDER_ACTION_TYPES.INTENT_URL:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.internationalCardOperationSlider,
          menuKey
        );
        setSearchParams(searchParams);
        break;

      case CARDS_SLIDER_ACTION_TYPES.URL:
        dispatch(
          resetPinUrl({
            cardId,
            onSuccess: toggleIndexApiBool,
          })
        );
        break;

      default:
        break;
    }
  }

  function handleFreezeUnFreezeAndBlockBtnClick(menuKeyActionType, menuKey) {
    switch (menuKeyActionType) {
      case CARDS_SLIDER_ACTION_TYPES.API:
        freezeUnfreezeBlockHandler({
          dispatch,
          menuKey,
          cardId,
          handleCardStatusChange,
        });
        break;

      case CARDS_SLIDER_ACTION_TYPES.INTENT_URL:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.internationalCardOperationSlider,
          menuKey
        );
        setSearchParams(searchParams);
        break;

      default:
        break;
    }
  }

  function fetchSelectedCardData() {
    dispatch(fetchAndSelectCard({ cardId: parseInt(sliderId, 10) }));
  }

  function getNoteByCardStatus() {
    const alertConfig = { variant: "", title: null };

    if (cardSlug === CARD_PROVIDER.UOB) {
      if (
        cardStatus === CARD_STATUS.ACTIVE &&
        cardPendingRequest === CARD_PENDING_REQUEST.FREEZE_CARD
      ) {
        alertConfig.title = "cards.cardSlider.statusTimeLine.forzen.inProgress";
        alertConfig.variant = "warning";
      } else if (cardStatus === CARD_STATUS.FROZEN) {
        if (cardPendingRequest === CARD_PENDING_REQUEST.UNFREEZE_CARD) {
          alertConfig.title =
            "cards.cardSlider.statusTimeLine.unfreezed.inProgress";
          alertConfig.variant = "warning";
        } else if (cardPendingRequest === CARD_PENDING_REQUEST.BLOCK_CARD) {
          alertConfig.title =
            "cards.cardSlider.statusTimeLine.blocked.inProgress";
          alertConfig.variant = "warning";
        } else if (cardPendingRequest === CARD_PENDING_REQUEST.NONE) {
          alertConfig.title =
            "cards.cardSlider.statusTimeLine.forzen.fulfilled";
          alertConfig.titleTransalationProp = {
            freezedAt: dateToTimeStampString(card?.freezedAt) ?? "",
            actionTakenBy: t(
              FREEZE_TYPE_LABELS?.[card?.freezeType]
            )?.toLowerCase(),
          };
        }
      } else if (cardStatus === CARD_STATUS.BLOCKED) {
        alertConfig.title = "cards.cardSlider.statusTimeLine.blocked.fulfilled";
        alertConfig.titleTransalationProp = {
          blockedAt: dateToString(card?.blockedAt) ?? "",
        };
        alertConfig.variant = "danger";
      }
    } else if (cardStatus === CARD_STATUS.FROZEN) {
      alertConfig.title = "cards.cardSlider.statusTimeLine.forzen.fulfilled";
      alertConfig.titleTransalationProp = {
        freezedAt: dateToTimeStampString(card?.freezedAt) ?? "",
        actionTakenBy: t(FREEZE_TYPE_LABELS?.[card?.freezeType])?.toLowerCase(),
      };

      alertConfig.variant = "danger";
    } else if (cardStatus === CARD_STATUS.BLOCKED) {
      alertConfig.title = "cards.cardSlider.statusTimeLine.blocked.fulfilled";
      alertConfig.titleTransalationProp = {
        blockedAt: dateToString(card?.blockedAt) ?? "",
      };
      alertConfig.variant = "danger";
    }

    return alertConfig;
  }

  function generateCardsSliderCtaBtnsConfig() {
    const config = sliderBtns
      ?.filter(
        (btnName) =>
          btnName !== CARDS_ALL_SLIDER_MENU_KEYS.SETTINGS &&
          btnName !== CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD &&
          btnName !== CARDS_ALL_SLIDER_MENU_KEYS.LINKED_TO
      )
      ?.map((btnName) => {
        const buttonKey = CARDS_ALL_SLIDER_MENU_CONFIG[btnName]?.key;

        const disabled = getDisableStatusOfSliderButtons(buttonKey);

        const tooltipConfig = checkButtonsTooltip(buttonKey);

        return {
          ...CARDS_ALL_SLIDER_MENU_CONFIG[btnName],
          disabled,
          tooltipConfig,
        };
      });

    setCtasConfig(config);
  }

  function checkButtonsTooltip(key) {
    const config = { text: "", hasTooltip: false, tooltipId: "" };

    switch (key) {
      case CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN:
        if (changePinMethod === CHANGE_PIN_METHODS_TYPE.CUSTOMER_SUPPORT) {
          config.text = changePinToolTip;
          config.hasTooltip = true;
          config.tooltipId = key;
        }
        break;

      default:
        break;
    }
    return config;
  }

  function getDisableStatusOfSliderButtons(key) {
    let disabled = false;

    switch (key) {
      case CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN:
        if (changePinMethod === CHANGE_PIN_METHODS_TYPE.CUSTOMER_SUPPORT)
          disabled = true;
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD:
        disabled =
          card?.status === CARD_STATUS.ACTIVE &&
          cardPendingRequest === "freeze_card";
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE:
        disabled =
          card?.status === CARD_STATUS.FROZEN &&
          cardPendingRequest === "unfreeze_card";
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.BLOCK:
        disabled =
          card?.status === CARD_STATUS.FROZEN &&
          cardPendingRequest === "block_card";
        break;

      default:
        break;
    }
    return disabled || card?.status === CARD_STATUS.BLOCKED;
  }

  const cardState = useLoadingErrorInjector({
    apiKey: API_KEYS_MAPPING.CARDS_GET,
    showLoader: false,
    error: {
      header: "",
      classes: "h-full flex items-center justify-center",
    },
  });

  // Have to change the Loading text when loaders are available
  if (!card) {
    return <div ref={cardState?.attach} className="slider-content-core" />;
  }

  return card ? (
    <div className="px-9 grow">
      <CardSummaryShort
        heading={cardSummaryShortHeading}
        status={card?.status}
        cardType={card?.type}
        topUpRequestTooltip={{
          amount: card?.topUpRequests?.amount,
          currency: card?.topUpRequests?.currency,
          frequency: CARD_FREQUENCY_LABEL[card?.topUpRequests?.frequency],
        }}
        temporaryLimitTooltip={{
          limit: card?.limit,
          createdOn: card?.createdAt,
          currency: card?.currency,
          freqeuncy: card?.frequency,
        }}
        isTemporaryLimit={card?.isTemporaryLimit}
        topUpRequests={card?.anyEditRequests}
        cardId={card?.id}
        insufficientFunds={card?.topUpRequests?.insufficientFunds}
      />

      <CardSliderMenu
        handleMenuClick={handleMenuClick}
        ctasConfig={ctasConfig}
      />

      {inSufficientFunds ? (
        <div className="mt-7">
          <Alert
            variant="warning"
            description="cards.cardSlider.awaitingFundsAlert"
            descriptionTransalationProp={{ currency, amount }}
            descriptionClasses="text-neutral-800"
          />
        </div>
      ) : null}

      {noteConfig?.title ? (
        <div className="mt-7">
          <Alert
            variant={noteConfig?.variant}
            title={noteConfig?.title}
            titleTransalationProp={noteConfig?.titleTransalationProp}
          />
        </div>
      ) : null}

      {showPendingLimitRequestAlert ? (
        <Alert
          variant="warning"
          title="Edit limit request pending"
          description="cards.cardSlider.limitRequestPending.description"
          descriptionTransalationProp={{
            amountCurrency: amountToCurrency(
              card?.topUpRequests?.amount,
              card?.topUpRequests?.currency
            ),
            frequency: t(
              CARD_FREQUENCY_LABEL?.[card?.topUpRequests?.frequency]
            ),
          }}
          classes="mt-7"
        />
      ) : null}

      {tabsList.length > 0 ? (
        <Tabs
          items={tabsList}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => {
            dispatch(setCardSliderTab(tab));
          }}
          mode
          classes="mt-8"
        />
      ) : null}

      <div className="pt-8">
        {TabComponent ? (
          <TabComponent {...selectedTab?.tabComponentProps} />
        ) : null}
      </div>
    </div>
  ) : null;
}

CardSlider.propTypes = {
  setOnClose: PropTypes.func,
};
