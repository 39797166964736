import PropTypes from "prop-types";
import { Fragment } from "react";
import { useSelector } from "react-redux";

import {
  subtractivePurchaseTaxChildrenSelector,
  subtractivePurchaseTaxParentSelector,
} from "@/store/selectors/purchase-bills";

import ApplyAtLineItemLevel from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/ApplyAtLineItemLevel";
import BoltOnTheLeft from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/BoltOnTheLeft";
import TaxField from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TaxField";
import TextWithTooltipAndAmount from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipAndAmount";
import {
  CREATE_BILL_FLOW_CONTEXT_KEYS,
  CREATE_BILL_TAX_RESPONSE,
  TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE,
  TAX_KEYS,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";

/**
 * In the UI, this is between the invoice subtotal and invoice total
 */
export default function TaxSectionSubtractive({
  values,
  errors,
  handleChange,
  setValues,
  beneficiaryCurrency,
  subtotal,
  tds = 0,
  hideLineItemLevel = false,
  classes = "mt-8", // used in payroll context, generally not needed
  gapClass, // used in payroll context, generally not needed
  createBillFlowContext,
  ocrMode,
  billOCRSuccess,
}) {
  const parentTax = useSelector(subtractivePurchaseTaxParentSelector); // always shown (non-optional) tax, may be line item level applicable
  const childrenTax = useSelector(subtractivePurchaseTaxChildrenSelector); // optional taxes, move to line item level with the parent.
  // Their individual line item level value is irrelevant

  if (!parentTax) return null;

  const lineItemLevelEnabled = values[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE]; // for subtractives
  const isParentLineItemLevelApplicable =
    parentTax[CREATE_BILL_TAX_RESPONSE.LINE_ITEM_LEVEL_APPLICABLE];

  return lineItemLevelEnabled ? (
    <div className="mt-1">
      <TextWithTooltipAndAmount
        text={parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}
        currency={beneficiaryCurrency}
        amount={tds}
        isTax
        isSubtractive
      />
    </div>
  ) : (
    // line item level not enable or not applicable
    <div className={classes}>
      <div className="flex flex-col gap-4 bg-neutral-50">
        <BoltOnTheLeft
          showBolt={
            ocrMode &&
            billOCRSuccess &&
            (() => {
              const keyName = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
              const categoryDropdownName = `${keyName}-category-dropdown`;
              const dropdownName = `${keyName}-dropdown`;
              const valueName = `${keyName}-value`;
              // const dropdownLabelName = `${keyName}-dropdown-label`; // not needed

              return (
                [categoryDropdownName, dropdownName, valueName]
                  .filter(
                    (key) =>
                      values[key] || !Number.isNaN(parseFloat(values[key]))
                  )
                  .filter(
                    (key) =>
                      createBillFlowContext.current[
                        CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
                      ]?.[key]?.toString() === values[key]?.toString()
                  ).length >= 2
              );
            })()
          }
        >
          <TaxField
            gapClass={gapClass}
            keyName={parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}
            values={values}
            errors={errors}
            handleChange={handleChange}
            setValues={setValues}
            amount={subtotal}
            isSubtractive
            currency={beneficiaryCurrency}
            taxName={parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}
            text={parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}
            placeholder="billPay.bill.invoiceInbox.createBill.sections.lineItems.taxAmountOrCategory"
            labelStyleClasses="font-semibold text-xs text-neutral-500"
            helperText={parentTax?.[CREATE_BILL_TAX_RESPONSE.DESCRIPTION_TEXT]}
          />
        </BoltOnTheLeft>

        {/* Optional ones: PPnBM etc, may be applicable at line item level too */}
        {childrenTax.map((childTax) => {
          const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
          const childVisibleKey = `${taxKeyName}-visible`;
          const optionalSwitchKey = `${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;

          const optionalSwitchEnabled = values[optionalSwitchKey];
          const isChildLineItemLevelApplicable =
            childTax[CREATE_BILL_TAX_RESPONSE.LINE_ITEM_LEVEL_APPLICABLE];

          return (
            <Fragment key={taxKeyName}>
              {isChildLineItemLevelApplicable && values[childVisibleKey] ? (
                <div className={`px-4 ${optionalSwitchEnabled ? "" : "mb-4"}`}>
                  {/* padding same as TaxField's horizontal */}
                  {/* Actually just the optional switch, not line item level switch */}
                  <ApplyAtLineItemLevel
                    keyName={optionalSwitchKey}
                    values={values}
                    errors={errors}
                    text="billPay.bill.invoiceInbox.createBill.sections.lineItems.applyXYZTaxOptional"
                    textProps={{ taxName: taxKeyName }}
                    handleChange={handleChange}
                  />
                </div>
              ) : null}
              {optionalSwitchEnabled && values[childVisibleKey] ? (
                <BoltOnTheLeft
                  showBolt={
                    ocrMode &&
                    billOCRSuccess &&
                    (() => {
                      const keyName =
                        childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
                      const categoryDropdownName = `${keyName}-category-dropdown`;
                      const dropdownName = `${keyName}-dropdown`;
                      const valueName = `${keyName}-value`;
                      // const dropdownLabelName = `${keyName}-dropdown-label`; // not needed

                      return (
                        [categoryDropdownName, dropdownName, valueName]
                          .filter(
                            (key) =>
                              values[key] ||
                              !Number.isNaN(parseFloat(values[key]))
                          )
                          .filter(
                            (key) =>
                              createBillFlowContext.current[
                                CREATE_BILL_FLOW_CONTEXT_KEYS
                                  .OCR_DETECTED_SIMPLE_VALUES
                              ]?.[key]?.toString() === values[key]?.toString()
                          ).length >= 2
                      );
                    })()
                  }
                >
                  <TaxField
                    keyName={taxKeyName}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    setValues={setValues}
                    amount={subtotal}
                    isSubtractive
                    currency={beneficiaryCurrency}
                    text={taxKeyName}
                    taxName={taxKeyName}
                    tooltipText="billPay.bill.invoiceInbox.createBill.sections.lineItems.createBillWalletTooltip"
                    placeholder="billPay.bill.invoiceInbox.createBill.sections.lineItems.taxAmountOrCategory"
                    labelStyleClasses="font-semibold text-xs text-neutral-500"
                    helperText={
                      childTax?.[CREATE_BILL_TAX_RESPONSE.DESCRIPTION_TEXT]
                    }
                  />
                </BoltOnTheLeft>
              ) : null}
            </Fragment>
          );
        })}
      </div>
      {/* Apply TDS (switch) at line item level (when it's OFF) */}
      {hideLineItemLevel || !isParentLineItemLevelApplicable ? null : (
        <div className="mt-2">
          <ApplyAtLineItemLevel
            keyName={TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE}
            values={values}
            errors={errors}
            text="billPay.bill.invoiceInbox.createBill.sections.lineItems.applyXYZAtLineItemLevel"
            textProps={{
              taxName: parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME],
            }}
            handleChange={handleChange}
          />
        </div>
      )}
    </div>
  );
}

TaxSectionSubtractive.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  beneficiaryCurrency: PropTypes.string,
  hideLineItemLevel: PropTypes.bool,

  subtotal: PropTypes.number,
  tds: PropTypes.number,
  classes: PropTypes.string,
  gapClass: PropTypes.string,
  createBillFlowContext: PropTypes.any,
  ocrMode: PropTypes.string,
  billOCRSuccess: PropTypes.bool,
};
