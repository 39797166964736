import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

function BudgetListingLoader() {
  return (
    <div className="flex flex-col gap-5">
      {[...Array(11)].map((item, index) => (
        <div key={index} className="flex justify-between card-wrapper">
          <div className="flex flex-col gap-1">
            <LoaderSkeleton size={[10, 200]} />
            <LoaderSkeleton size={[10, 500]} />
          </div>
          <div className="flex flex-col gap-1">
            <LoaderSkeleton size={[10, 150]} />
            <LoaderSkeleton size={[10, 100]} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default BudgetListingLoader;
