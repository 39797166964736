import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import Text from "@/components/core/Text";

export default function Checkbox({
  label = "",
  labelClasses = "text-neutral-800 font-medium",
  labelTranslationProp = {},
  description = "",
  descriptionClasses = "text-xs text-neutral-500",
  errorMsg,
  checked = false,
  onClickHandler = () => {},
  disabled,
  name,
  insideForm = false,
  translateLabel = true,
  preventPropagation = true,
  classes = "",
  ...props
}) {
  const [isChecked, setIsChecked] = useState(checked);
  const onChecked = () => {
    setIsChecked(checked);
  };

  const handleSelection = (prev) => {
    setIsChecked(!prev);
    if (insideForm)
      onClickHandler({ target: { value: !prev, name, type: "checkbox" } });
    else onClickHandler(!prev);
  };

  const getLabelBefore = () => {
    return `before:appearance-none before:p-2 before:inline-block before:align-middle before:border-[1px] before:rounded-sm before:border-neutral-300 before:relative ${
      isChecked
        ? `${
            disabled
              ? "before:bg-neutral-200 before:border-neutral-200 "
              : "before:bg-primary-500 before:border-primary-500 "
          }`
        : `before:border-neutral-300 ${
            disabled ? "before:bg-neutral-200" : "before:bg-white "
          }`
    }`;
  };

  const getLabelAfter = () => {
    return `${
      isChecked
        ? "after:block after:absolute after:left-1.5 after:w-1.5 after:h-[11px] after:mt-0.75 after:border-white after:border-t-0 after:border-r-2 after:border-b-2 after:border-l-0 after:rotate-45 after:mb-1"
        : ""
    }`;
  };

  const getCursorClass = () =>
    disabled ? "cursor-not-allowed" : "cursor-pointer";

  useEffect(() => {
    onChecked();
  }, [checked]);

  return (
    <div
      className={`inline-block shrink-0 ${classes}`}
      onClick={(event) => (preventPropagation ? event.stopPropagation() : null)}
    >
      <label
        className={`relative ${getCursorClass()} ${getLabelBefore()} ${getLabelAfter()}  flex gap-3 justify-start items-center`}
      >
        {label || description ? (
          <div className="flex flex-col gap-1">
            {typeof label === typeof "" && translateLabel ? (
              <Text
                translationKey={label}
                classes={labelClasses}
                translationProps={labelTranslationProp}
              />
            ) : (
              label
            )}
            {!errorMsg && description && (
              <Text
                classes={`pl-0 ${descriptionClasses}`}
                translationKey={description}
              />
            )}
          </div>
        ) : null}

        <input
          className="hidden"
          name={name}
          type="checkbox"
          checked={isChecked}
          onChange={() => handleSelection(isChecked)}
          disabled={disabled}
          {...props}
        />
      </label>
      {errorMsg && <p className="px-1 text-sm text-danger">{errorMsg}</p>}
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]),
  labelTranslationProp: PropTypes.object,
  type: PropTypes.string,
  errorMsg: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickHandler: PropTypes.func,
  name: PropTypes.string,
  insideForm: PropTypes.bool,
  translateLabel: PropTypes.bool,
  description: PropTypes.string,
  labelClasses: PropTypes.string,
  descriptionClasses: PropTypes.string,
  preventPropagation: PropTypes.bool,
  classes: PropTypes.string,
};
