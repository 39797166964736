import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import { PAYMENT_SCROLL_TO_KEYS } from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function CommmentCell({ val }) {
  const openSliderWithCommentsRolledUp = () => {
    val?.openSliderHandler?.(val.id, {
      [SLIDERS_SEARCH_PARAMS.scrollTo]: PAYMENT_SCROLL_TO_KEYS.COMMENT_SECTION,
    });
  };

  return (
    <span>
      {val.hasComments || val?.comments?.length ? (
        <Icon
          className="mx-auto text-neutral-500"
          name="Comments"
          handleClick={openSliderWithCommentsRolledUp}
        />
      ) : (
        "-"
      )}
    </span>
  );
}

CommmentCell.propTypes = {
  val: PropTypes.object,
};
