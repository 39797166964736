export const injectArrayPolyfill = () => {
  /* eslint-disable no-extend-native */
  /**
   * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at}
   */

  if (!Array.prototype.at) {
    Object.defineProperty(Array.prototype, "at", {
      value(idx) {
        // Handle out-of-bounds index if needed
        return this[idx < 0 ? this.length + idx : idx];
      },
      configurable: true,
      writable: true,
    });
  }

  /**
   * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toSorted}
   */
  if (!Array.prototype.toSorted) {
    Object.defineProperty(Array.prototype, "toSorted", {
      value(compareFn) {
        const shallowCopy = [...this];
        shallowCopy.sort(compareFn);
        return shallowCopy;
      },
      configurable: true,
      writable: true,
    });
  }
  /**
   * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replaceAll}
   */
  if (!String.prototype.replaceAll) {
    Object.defineProperty(String.prototype, "replaceAll", {
      configurable: true,
      writable: true,
      value(searchValue, replaceValue) {
        // If searchValue is a regular expression, escape it properly
        if (Object.prototype.toString.call(searchValue) === "[object RegExp]") {
          searchValue = searchValue.source.replace(
            /[$()*+?.[\]^{|}-]/g,
            "\\$&"
          );
        } else {
          // If searchValue is a string, escape special characters
          searchValue = searchValue.replace(/[$()*+?.[\]^{|}-]/g, "\\$&");
        }

        // Create a new regular expression with global and unicode flags
        const regex = new RegExp(searchValue, "gu");

        // Return the result of replacing all occurrences of the searchValue with replaceValue
        return this.replace(regex, replaceValue);
      },
    });
  }
};
