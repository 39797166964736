import PropTypes from "prop-types";

import { dateToString } from "@/utils/common";

export default function TransactionDateCell({ val }) {
  return (
    <div>
      {val?.bulkProcessing
        ? null
        : val.transactionDate || val.travelDate
          ? dateToString(val.transactionDate || val.travelDate)
          : "-"}
    </div>
  );
}

TransactionDateCell.propTypes = {
  val: PropTypes.object,
};
