// react imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// utils, constant file imports

import {
  orderCard,
  setOrderCardStatus,
  setOrderPhysicalCardFormData,
} from "@/store/reducers/cards";
// reducers
import { fetchProjectApprovers } from "@/store/reducers/company";
import { fetchCurrentUserDetails } from "@/store/reducers/user";

import {
  orderCardStatusSelector,
  orderPhysicalCardFormDataSelector,
} from "@/store/selectors/cards";
import {
  cardProviderWithInternationalCardOrderEnabledSelector,
  clientSelector,
  primaryCardProviderSelector,
} from "@/store/selectors/client";
// selectors
import { allPeopleSelector } from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isAdminSelector,
  userIdSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Note from "@/components/core/Note";
// core components
import Text from "@/components/core/Text";

import { triggerCardsTableComponent } from "@/components/Cards/util";
// page relevant components
import FormSummaryInfo from "@/components/common/FormSummaryInfo";
import { convertFilters } from "@/utils/filters";
import { camelToSnake, removeSubstringFromString } from "@/utils/common";

import {
  BULK_PHYSICAL_CARD_TYPE,
  CARD_PROVIDER,
  ORDER_PHYSICAL_CARDS_TYPE,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { COUNTRY_STATES } from "@/constants/onboarding";
import { ROUTES } from "@/constants/routes";

import ApproversSectionComponent from "./ApproversSection";

function ReviewCardDetails() {
  const locationObject = useLocation();
  const dispatch = useDispatch();
  const orderPhysicalCardFormDetails = useSelector(
    orderPhysicalCardFormDataSelector
  );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const createPhysicalCardParam = searchParams.get(
    SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard
  );
  const activeCardProvider = useSelector(primaryCardProviderSelector);
  const providerName = activeCardProvider?.name;
  const people = useSelector(allPeopleSelector);
  const orderPhysicalCardFormKeys = Object.keys(orderPhysicalCardFormDetails);
  const orderPhyscialCardFormValues = Object.values(
    orderPhysicalCardFormDetails
  );
  const orderCardStatus = useSelector(orderCardStatusSelector);
  const loggedInUserId = useSelector(userIdSelector);
  const isAdmin = useSelector(isAdminSelector);
  const clientInfo = useSelector(clientSelector);
  const cardsMakerCheckerEnabled = clientInfo?.cardsMakerCheckerEnabled;
  const internationalPhysicalCardOrderProviderInfo = useSelector(
    cardProviderWithInternationalCardOrderEnabledSelector
  );
  const pageAppliedFilters = convertFilters(useSelector(appliedFilterSelector));
  const currentUser = useSelector(userSelector);
  const selectedUserId = currentUser?.id;
  const _pageAppliedFilters = { ...pageAppliedFilters, user: selectedUserId };
  const showCreatePhysicalCardDetailsSummary =
    createPhysicalCardParam !==
    ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key;

  const CREATE_PHYSICAL_CARD_FIELDS_CATEGORY = {
    DELIVERY: "delivery",
    BILLING: "billing",
    NO_CATEGORY: "no_category",
  };

  const ORDER_PHYSICAL_CARD_SUMMARY_CONFIG = [
    {
      name: "noOfCards",
      label: "cards.pCards.sliders.createCardSlider.fields.noOfCards.title",
      value: undefined,
      show: true,
    },
    {
      name: "userIds",
      label: "cards.pCards.sliders.createCardSlider.fields.userIds.multiple",
      value: undefined,
      valueType: "array",
      show: orderPhysicalCardFormDetails?.userIds?.length > 0,
    },
    {
      name: "deliveryAddress_1",
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryAddressLine1.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY,
    },
    {
      name: "deliveryAddress_2",
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryAddressLine2.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY,
    },
    {
      name: "deliveryCity",
      label: "cards.pCards.sliders.createCardSlider.fields.deliveryCity.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY,
    },
    {
      name: "deliveryState",
      label: "cards.pCards.sliders.createCardSlider.fields.deliveryState.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY,
    },
    {
      name: "deliveryCountry",
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryCountry.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY,
    },
    {
      name: "deliveryZipcode",
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryPostalCode.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY,
    },
    {
      name: "bulkPhysicalCardOrderType",
      label:
        "cards.pCards.sliders.createCardSlider.fields.bulkPhysicalCardOrderType.title",
      value: undefined,
      show: false,
    },
    {
      name: "userId",
      label: "cards.pCards.sliders.createCardSlider.fields.userId.title",
      value: undefined,
      show: true,
    },
    {
      name: "postBoxAddress",
      label:
        "cards.pCards.sliders.createCardSlider.fields.postBoxAddress.title",
      value: undefined,
      show: false,
    },
    {
      name: "billingAddress_1",
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryAddressLine1.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.BILLING,
    },
    {
      name: "billingAddress_2",
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryAddressLine2.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.BILLING,
    },
    {
      name: "billingCity",
      label: "cards.pCards.sliders.createCardSlider.fields.deliveryCity.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.BILLING,
    },
    {
      name: "billingState",
      label: "cards.pCards.sliders.createCardSlider.fields.deliveryState.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.BILLING,
    },
    {
      name: "billingCountry",
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryCountry.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.BILLING,
    },
    {
      name: "billingZipcode",
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryPostalCode.title",
      value: undefined,
      show: true,
      category: CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.BILLING,
    },
  ];

  const [formFields, setFormFields] = useState([]);

  useEffect(() => {
    dispatch(fetchProjectApprovers());
  }, []);

  useEffect(() => {
    if (orderCardStatus) {
      dispatch(setOrderPhysicalCardFormData({}));
      closeReviewCardDetailsSlider();
      closeOrderPhysicalCardSlider();
      dispatch(setOrderCardStatus(false));
    }
  }, [orderCardStatus]);

  useEffect(() => {
    const formFieldsVariables = [];
    ORDER_PHYSICAL_CARD_SUMMARY_CONFIG.forEach((field) => {
      if (
        (field.category === CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.BILLING &&
          orderPhysicalCardFormDetails.postBoxAddress === "No") ||
        field.category === CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY ||
        !field.category
      ) {
        formFieldsVariables.push(field);
      }
    });

    setFormFields(
      formFieldsVariables.map((field) => ({
        ...field,
        value:
          field.name === "userIds"
            ? getCardOwnersNamesByIds()
            : field?.name === "deliveryState"
              ? getStateName()
              : orderPhysicalCardFormDetails[field.name],
      }))
    );
  }, [orderPhysicalCardFormDetails]);

  const formatFields = () => {
    const submitVariables = {
      delivery_address_attributes: {},
      card_provider_id:
        createPhysicalCardParam ===
        ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key
          ? internationalPhysicalCardOrderProviderInfo?.id
          : activeCardProvider?.id,
    };

    if (
      providerName === CARD_PROVIDER.NIUM &&
      orderPhysicalCardFormDetails.postBoxAddress === "No"
    ) {
      submitVariables.billing_address_attributes = {};
    }

    orderPhysicalCardFormKeys.forEach((fieldName, index) => {
      let category = "";
      const formattedFieldName = camelToSnake(fieldName);

      const isFieldCategorizedToDeliveryOrBilling =
        fieldName.includes(CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY) ||
        fieldName.includes(CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.BILLING);

      if (isFieldCategorizedToDeliveryOrBilling) {
        category = fieldName.includes(
          CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY
        )
          ? CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.DELIVERY
          : CREATE_PHYSICAL_CARD_FIELDS_CATEGORY.BILLING;

        if (submitVariables[`${category}_address_attributes`]) {
          const removeCategoryFromFieldNames = removeSubstringFromString(
            formattedFieldName,
            `${category}_`
          );

          submitVariables[`${category}_address_attributes`][
            removeCategoryFromFieldNames
          ] = orderPhyscialCardFormValues[index];
        }
      }
    });

    if (
      createPhysicalCardParam ===
      ORDER_PHYSICAL_CARDS_TYPE.BULK_PHYSICAL_CARD_ORDER.key
    ) {
      if (
        orderPhysicalCardFormDetails.bulkPhysicalCardOrderType ===
        BULK_PHYSICAL_CARD_TYPE.PERSONALISED_ORDER
      ) {
        submitVariables.user_ids = orderPhysicalCardFormDetails?.userIds;
        submitVariables.no_of_cards =
          orderPhysicalCardFormDetails?.userIds?.length;
      }
      if (
        orderPhysicalCardFormDetails.bulkPhysicalCardOrderType ===
        BULK_PHYSICAL_CARD_TYPE.NON_PERSONALISED_ORDER
      ) {
        submitVariables.no_of_cards = Number(
          orderPhysicalCardFormDetails?.noOfCards || 0
        );
      }
    } else if (
      createPhysicalCardParam ===
      ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key
    ) {
      delete submitVariables?.delivery_address_attributes;
      submitVariables.user_ids = orderPhysicalCardFormDetails?.userIds;
      submitVariables.no_of_cards =
        orderPhysicalCardFormDetails?.userIds?.length;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isAdmin) {
        submitVariables.user_ids = Array.isArray(
          orderPhysicalCardFormDetails?.userIds
        )
          ? orderPhysicalCardFormDetails?.userIds
          : [orderPhysicalCardFormDetails?.userIds];
      } else {
        submitVariables.user_ids = [loggedInUserId];
      }
    }

    submitVariables.request_id = loggedInUserId;

    submitVariables.type = "PhysicalCardRequest";

    return submitVariables;
  };

  const closeReviewCardDetailsSlider = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.reviewDetails
    );
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.selectedUserCard);
    setSearchParams(searchParams);
  };

  function handleOrderCardSuccess() {
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id)) {
      dispatch(fetchCurrentUserDetails({ userId: currentUser?.id }));
    } else {
      triggerCardsTableComponent(dispatch, _pageAppliedFilters, locationObject);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const onSuccess = () => {};

    const action = dispatch(
      orderCard({
        payload: formatFields(),
        onSuccess: handleOrderCardSuccess,
      })
    );

    if (orderCard.fulfilled.match(action)) {
      closeReviewCardDetailsSlider();
    }
  };

  const getCardOwnersNamesByIds = () => {
    const list = [];
    const isTypeList = Array.isArray(orderPhysicalCardFormDetails?.userIds);
    const userIdsArray = isTypeList
      ? orderPhysicalCardFormDetails?.userIds
      : [orderPhysicalCardFormDetails?.userIds];

    userIdsArray?.forEach((id) => {
      people.forEach((userInfo) => {
        if (userInfo.id === id) {
          list.push(userInfo.displayName);
        }
      });
    });
    return list;
  };

  function closeOrderPhysicalCardSlider() {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard
    );
    setSearchParams(searchParams);
  }

  function getStateName() {
    if (orderPhysicalCardFormDetails?.deliveryCountry === "Australia") {
      return COUNTRY_STATES?.AUS?.find(
        (state) => state?.value === orderPhysicalCardFormDetails?.deliveryState
      )?.name;
    }

    return orderPhysicalCardFormDetails?.deliveryState;
  }

  return (
    <>
      <div className="flex flex-col gap-4 mt-0 my-9 slider-content-core">
        <div className="flex flex-col">
          <Text
            translationKey="cards.pCards.sliders.createCardSlider.title"
            classes="text-3xl not-italic font-bold leading-[56px] tracking-[-0.36px] text-neutral-800"
          />

          <Text
            translationKey="cards.pCards.sliders.createCardSlider.review.desc"
            classes=" text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500"
          />
        </div>

        <FormSummaryInfo formFields={formFields} />

        <ApproversSectionComponent
          params={{ policy_group_type: "physical_cards" }}
          approvalDescription="cards.pCards.sliders.createPhysicalCardsDetailsSummary.approvers.approvalDescription"
          preApprovalDescription="cards.pCards.sliders.createPhysicalCardsDetailsSummary.approvers.preApprovalDescription"
          descriptionTextClasses="text-neutral-500"
          makerCheckerStatus={cardsMakerCheckerEnabled}
        />

        {showCreatePhysicalCardDetailsSummary ? (
          <Note
            titleText="cards.pCards.sliders.createPhysicalCardsDetailsSummary.note.titleText"
            actionText=""
            descriptionText=""
            descriptionTextClasses="text-base not-italic font-semibold leading-6"
          />
        ) : null}
      </div>

      <div className="px-3 py-5 slider-footer">
        <div className="flex items-center justify-end gap-3">
          <Button
            label="cards.pCards.sliders.createPhysicalCardsDetailsSummary.buttons.cancel"
            classes="w-[160px] text-neutral-500 font-medium"
            variant="tertiary"
            onClick={closeReviewCardDetailsSlider}
          />
          <Button
            label="cards.pCards.sliders.createPhysicalCardsDetailsSummary.buttons.submit"
            classes="w-[160px] text-white font-medium"
            variant="primary"
            btnType="submit"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}

export default ReviewCardDetails;
