import React from "react";
import { useSelector } from "react-redux";

import { payrollJobStatusForBulkUploadSelector } from "@/store/selectors/payments";

import JobStatusBanner from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/BulkUploadPayroll/JobStatusBanner";
import BillpayAndPayrollInvoiceInbox from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/List";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { PAYROLL_BULK_UPLOAD_SLIDER } from "@/utils/constants/payments";

export default function SuccessRowsTable() {
  const bulkUploadJobStatusInfo = useSelector(
    payrollJobStatusForBulkUploadSelector
  );

  const successRows = bulkUploadJobStatusInfo?.successRows;

  return (
    <>
      <JobStatusBanner
        listCount={successRows}
        mainTitle="payroll.bulkUpload.tables.successData.mainTitle"
        tableDataInfoNote="payroll.bulkUpload.tables.successData.tableDataInfoNote"
        extraClasses="p-3"
        bannerBgClasses="bg-neutral-50"
        tableDateInfoClasses="w-84 grow truncate"
      />

      <BillpayAndPayrollInvoiceInbox
        page={BILL_PAYROLL_CONTEXT.PAYROLL}
        inPageSlider={PAYROLL_BULK_UPLOAD_SLIDER}
        externalClasses="mx-0"
        tableSectionClasses="pt-0"
      />
    </>
  );
}
