import PropTypes from "prop-types";
import React from "react";

import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function MissingDetailsItemRow({
  name,
  missingDetailLabel,
  handleRemindExpenses,
  expenseDate,
  categoryName,
  expenseAmountLabel,
  handleDismissSubmissionPolicy,
  onClick,
}) {
  return (
    <div
      className="flex flex-row items-center justify-between gap-3"
      onClick={onClick}
    >
      <div className="w-7/10">
        <ProfileWidget name={name} textClasses="text-neutral-800 text-sm">
          <div className="flex flex-row items-center justify-between ">
            <div className="flex flex-row items-center gap-1">
              <Text
                translationKey={missingDetailLabel}
                classes="text-danger-600 text-xs w-max"
              />

              <div className="flex flex-row items-center gap-2">
                <div className="profile-widget-dot bg-neutral-500" />

                <Text
                  translationKey={expenseDate}
                  classes="text-neutral-500 text-xs"
                />
              </div>

              <div className="flex flex-row items-center justify-between gap-2">
                {categoryName && (expenseDate || missingDetailLabel) ? (
                  <div className="profile-widget-dot bg-neutral-500" />
                ) : null}

                {categoryName ? (
                  <Text
                    translationKey={categoryName}
                    classes="text-neutral-500 text-xs "
                  />
                ) : null}
              </div>
            </div>
          </div>
        </ProfileWidget>
      </div>

      <div
        className="flex flex-row items-center gap-4 grow"
        style={{ justifyContent: "flex-end" }}
      >
        <Text
          translationKey={expenseAmountLabel}
          classes="text-sm font-semibold"
        />
        <div id="dismiss-subPolicy-requirement-tooltip">
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleDismissSubmissionPolicy();
            }}
          >
            <Icon
              name="CircleCheck"
              className="w-4 cursor-pointer text-neutral-500"
            />
          </div>

          <Tooltip
            id="dismiss-subPolicy-requirement-tooltip"
            direction="bottom"
          >
            <Text translationKey="cards.cardDetails.overview.policyDetails.expenses.dismissSubmissionPolicyReq" />
          </Tooltip>
        </div>
        <Icon
          name="Notification"
          className="cursor-pointer text-neutral-500"
          handleClick={handleRemindExpenses}
        />
      </div>
    </div>
  );
}

MissingDetailsItemRow.propTypes = {
  name: PropTypes.string,
  missingDetailLabel: PropTypes.string,
  handleRemindExpenses: PropTypes.func,
  expenseDate: PropTypes.string,
  categoryName: PropTypes.string,
  expenseAmountLabel: PropTypes.string,
  handleDismissSubmissionPolicy: PropTypes.func,
  onClick: PropTypes.func,
};
