import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import AuthLayoutSvg from "@/components/Auth/AuthLayoutSvg";
import VolopayCard from "@/assets/images/Volopay_Card.png";

export default function AuthLayout() {
  return (
    <div className="flex min-w-full min-h-screen bg-primary-50">
      <div className="flex flex-col items-center w-1/2">
        <div className="bg-primary-100 w-[456px] h-[524px] rounded-b-full">
          <div className="absolute top-13 left-14">
            <AuthLayoutSvg />
          </div>
          <img src={VolopayCard} alt="" className="mt-14 left-11" />
        </div>
        <div className="mt-8">
          <div className="text-6xl font-medium text-center text-primary-500">
            <Text translationKey="loginPageFirstLine" />
            <br />
            <Text translationKey="loginPageSecondLine" />
          </div>
        </div>
        <div className="mt-5 font-medium text-center text-neutral-500">
          <Text translationKey="loginPageDesc" />
        </div>
      </div>
      <div className="flex items-center w-1/2">
        <div className="flex items-center justify-center w-full">
          <div className="bg-white h-full w-[560px] rounded-3xl p-10 my-1/10">
            <Icon
              className="w-16 h-8.5 text-3xl text-neutral-700 mb-8 "
              name="VolopayLogoOnCard"
            />
            <Outlet className="w-1/2" />
          </div>
        </div>
      </div>
    </div>
  );
}
