import PropTypes from "prop-types";

import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";

import useGetCountry from "@/components/Company/Overview/AccountOverview/Slider/shared/useGetCountry";

export default function SwitchEntityRadioComponent({
  selectedEntity,
  setSelectedEntity,
  entity,
}) {
  return (
    <div className="flex gap-3 items-center w-full h-full mb-5">
      <div className="flex h-3/4 place-items-start">
        <Radio
          name="selectEntity"
          isChecked={entity?.subdomain === selectedEntity?.subdomain}
          value={selectedEntity?.subdomain}
          handleChange={() => {
            setSelectedEntity(entity);
          }}
          radioButtonClasses="mt-3"
        />
      </div>

      <div className="p-3 flex flex-col border border-neutral-200 w-full rounded-lg">
        <Text
          translationKey={entity?.subdomain}
          classes="text-base font-semibold text-neutral-800"
        />
        <Text
          translationKey={useGetCountry(entity?.country)}
          classes="text-sm font-semibold text-neutral-500"
        />
      </div>
    </div>
  );
}

SwitchEntityRadioComponent.propTypes = {
  selectedEntity: PropTypes.object,
  setSelectedEntity: PropTypes.func,
  entity: PropTypes.object,
};
