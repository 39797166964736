import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  accountingContinuousBillSyncSelector,
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
  currentPaymentProviderSelector,
} from "@/store/selectors/client";
import { lineItemsSelector } from "@/store/selectors/payments";
import {
  isFetchingDuplicateBillSelector,
  recentDuplicateBillSelector,
} from "@/store/selectors/purchase-bills";
import { userSelector } from "@/store/selectors/user";

import Comments from "@/components/core/Comments";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Timeline from "@/components/core/Timeline";

import DuplicatePaymentAlert from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/DuplicatePaymentAlert";
import EditRecurringPaymentBox from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/EditRecurringPaymentBox";
import InvoiceDetails from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/InvoiceDetails";
import LineItemsTable from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/LineItemsTable";
import PaymentTotalBreakdown from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentTotalBreakdown";
import TransactionDetails from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/TransactionDetails";
import VendorDetails from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/VendorDetails";
import WalletDetails from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/WalletDetails";
import { TYPES_TIMELINE } from "@/utils/constants/timeline";
import { PAYMENT_MODES } from "@/utils/constants/reimbursement";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  PAYMENT_APPROVAL_STATUSES,
  PAYMENT_SCROLL_TO_KEYS,
  PAYMENT_STATUSES,
  getPaymentSliderInfo,
} from "@/utils/constants/payments";
import "@/components/common/BillPayAndPayroll/style.scss";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import { PAYMENT_PROVIDERS } from "@/constants/provider";

export default function OverviewTab({
  payment,
  isFetching,
  context,
  isAccounting = false,
  createNewComment,
  commentsList,
  showLoadMoreCta,
  loadMoreComments,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const lineItems = useSelector(lineItemsSelector);
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

  const approvalStatus = payment?.status;
  const paymentStatus = payment?.transactionStatus;
  const status = paymentStatus || approvalStatus;
  const onPaymentPage =
    payment?.transactionStatus &&
    payment?.transactionStatus !== PAYMENT_STATUSES.paid; // for warnings
  const onPaidPage = payment?.transactionStatus === PAYMENT_STATUSES.paid; // to avoid duplicate bill message
  const isPayOutsideVolopay =
    payment?.paymentMode === PAYMENT_MODES.payOutsideVolopay;

  const isFetchingDuplicateBill = useSelector(isFetchingDuplicateBillSelector);
  const duplicateBill = useSelector(recentDuplicateBillSelector);
  const currentUser = useSelector(userSelector);

  const currentProvider = useSelector(currentPaymentProviderSelector);
  const senderCurrency = payment?.quote?.fromCurrency;
  const beneficiaryCurrency = payment?.quote?.toCurrency;
  const isYSTRPotentiallyApplicable =
    senderCurrency !== beneficiaryCurrency &&
    [PAYMENT_PROVIDERS.WALLEX, PAYMENT_PROVIDERS.AIRWALLEX].includes(
      currentProvider
    );
  const isYSTRApplicable = isYSTRPotentiallyApplicable && lineItems?.length < 2;

  const [accountingTag, setAccountingTag] = useState(payment?.accountingTags);
  const [customTag, setCustomTag] = useState(payment?.customTag);

  const accountingTagOptions = useMemo(() => accountingTag, []);
  const customTagOptions = useMemo(() => customTag, []);
  const accountingForm = {
    accountingTag: {
      value: accountingTag,
      options: accountingTagOptions,
      handleChange: setAccountingTag,
    },
    customTag: {
      value: customTag,
      options: customTagOptions,
      handleChange: setCustomTag,
    },
  };

  const scrollToId = searchParams.get(SLIDERS_SEARCH_PARAMS.scrollTo);
  useEffect(() => {
    if (!isFetching && scrollToId) {
      document?.getElementById(scrollToId)?.scrollIntoView?.({
        behavior: "smooth",
      });
    }
  }, [isFetching, scrollToId]);

  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );

  const accountingSoftwareName =
    ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingIntegrationSoftware];
  const showAccountingURL =
    useSelector(accountingContinuousBillSyncSelector) &&
    accountingEnabled &&
    ![
      PAYMENT_APPROVAL_STATUSES.rejected,
      PAYMENT_APPROVAL_STATUSES.denied,
    ].includes(payment?.status);

  const accountingSoftwareLink = payment?.accountingUrl;

  if (isFetching) return "Loading";

  return (
    <div className="flex flex-col gap-10">
      {/* Duplicate payment alert */}
      {!isFetchingDuplicateBill && duplicateBill && !onPaidPage ? (
        <div className="slider-item-section">
          <DuplicatePaymentAlert
            existingDuplicateId={duplicateBill?.invoiceNumber}
            inPayrollContext={inPayrollContext}
            classes="mt-4 pointer-events-auto"
            handleClick={() => {
              const { sliderKey, sliderValue, sliderType } =
                getPaymentSliderInfo(duplicateBill, inPayrollContext);

              const paramsToAppend = {
                [sliderKey]: sliderValue,
                [SLIDERS_SEARCH_PARAMS.payments.sliderType]: [sliderType],
                [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]:
                  sliderKey,
                [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
                  SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
                [SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled]: true,
              };
              Object.entries(paramsToAppend).forEach(([k, v]) => {
                searchParams.append(k, v);
              });
              //
              setSearchParams(searchParams);
            }}
          />
        </div>
      ) : null}
      {!isAccounting ? (
        paymentStatus === PAYMENT_STATUSES.outsideVolopay ? (
          <div className="slider-item-section">
            <Text
              classes="card-wrapper bg-neutral-50 text-neutral-800"
              translationKey="billPay.bill.payments.outsideVolopay"
            />
          </div>
        ) : (
          <Timeline
            id={payment?.owner?.id || payment?.id}
            status={
              payment?.transactionStatus ? payment?.transactionStatus : status
            }
            currentApprovers={
              payment?.currentApprovers ? payment?.currentApprovers : []
            }
            rejectedReason={payment?.rejectedReason}
            failureReason={payment?.failureReasonDescription}
            currentApproverLevel={payment?.currentApprovalLevel}
            totalApprovers={payment?.approvalLevels}
            type={
              inPayrollContext
                ? TYPES_TIMELINE.PAYROLL
                : TYPES_TIMELINE.PURCHASE_BILL
            }
          />
        )
      ) : null}
      {payment?.recurringPayment ? (
        <div className="slider-item-section">
          <EditRecurringPaymentBox
            payment={payment}
            context={context}
            onPaymentOrPaidPage={onPaymentPage || onPaidPage}
          />
        </div>
      ) : null}
      <div className="slider-item-section">
        <Text
          classes="payment-heading"
          translationKey={
            context === BILL_PAYROLL_CONTEXT.BILLPAY
              ? "billPay.bill.payments.vendorDetails.heading"
              : "payroll.employeeDetails.slider.employeeDetails"
          }
        />
        <VendorDetails
          paymentId={payment?.owner?.id || payment?.id}
          vendor={payment?.vendor}
          senderCurrency={senderCurrency}
          inPayrollContext={inPayrollContext}
          context={context}
        />
      </div>
      <div className="slider-item-section">
        <div className="flex flex-row justify-between">
          <Text
            classes="payment-heading"
            translationKey={
              context === BILL_PAYROLL_CONTEXT.BILLPAY
                ? "billPay.bill.payments.invoiceDetails.heading"
                : "payroll.salaryPayment.payments.salaryDetails.heading"
            }
          />
          {showAccountingURL ? (
            <a
              className="font-medium underline text-primary-500"
              href={accountingSoftwareLink}
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="OpenInNew"
                className="inline-block w-4 h-4 mr-1 text-primary-500"
              />
              <Text
                translationKey="billPay.bill.payments.openInXYZSoftware"
                translationProps={{ softwareName: accountingSoftwareName }}
              />
            </a>
          ) : null}
        </div>

        <InvoiceDetails context={context} payment={payment} />

        <LineItemsTable
          lineItems={lineItems}
          context={context}
          accountingStatus={payment?.accountingStatus}
        />

        {/* Breakdown (taxes, totals) */}
        {/* If taxes are at line items, then taxes here are the sums. The table will have line item level taxes. */}
        {/* This is UI from below line items table TO Payment details heading */}
        <PaymentTotalBreakdown
          context={context}
          payment={payment}
          isYSTRApplicable={isYSTRPotentiallyApplicable}
        />
      </div>
      <div className="slider-item-section">
        <WalletDetails
          payment={payment}
          isPayOutsideVolopay={isPayOutsideVolopay}
          senderCurrency={senderCurrency}
          context={context}
        />
      </div>

      <div className="slider-item-section">
        <TransactionDetails payment={payment} context={context} />
      </div>
      <div id={PAYMENT_SCROLL_TO_KEYS.COMMENT_SECTION}>
        <Text
          translationKey="comments.commentHeading"
          classes="font-semibold text-lg"
        />
        <Comments
          comments={commentsList}
          loadMoreLabel="comments.showOlderComments"
          isVisible
          createNewComment={(val) => {
            createNewComment(val);
          }}
          currentUser={currentUser}
          showLoadMoreCta={showLoadMoreCta}
          loadMoreComments={loadMoreComments}
        />
      </div>
    </div>
  );
}

OverviewTab.propTypes = {
  payment: PropTypes.object,
  isFetching: PropTypes.bool,
  context: PropTypes.string,
  isAccounting: PropTypes.bool,
  createNewComment: PropTypes.func,
  commentsList: PropTypes.array,
  showLoadMoreCta: PropTypes.bool,
  loadMoreComments: PropTypes.func,
};
