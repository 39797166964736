import PeopleTable from "@/components/Company/PeopleTable";

import { COMPANY_PEOPLE_TAB_PARAMS } from "@/constants/company";

export default function PeoplePageAll() {
  return (
    <div className="mt-5">
      <PeopleTable tab={COMPANY_PEOPLE_TAB_PARAMS.ALL} />
    </div>
  );
}
