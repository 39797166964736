import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import { fetchBudgets, resetBudgetsStoreState } from "@/store/reducers/company";

import {
  budgetFiltersSelector,
  budgetSelector,
  budgetsHasMoreSelector,
  isFetchingbudgetSelector,
} from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { convertFilters } from "@/utils/filters";

import { PROJECT } from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS } from "@/constants/user";

import BudgetListing from "./BudgetListing";

function BudgetsPageHelper({ type }) {
  const dispatch = useDispatch();
  const budgets = useSelector(budgetSelector);
  const isFetching = useSelector(isFetchingbudgetSelector);
  const hasMore = useSelector(budgetsHasMoreSelector);
  const isEmpty = !budgets?.length;
  const [searchText, setSearchText] = useState("");
  const filters = useSelector(budgetFiltersSelector);
  const appliedFilters = useSelector(appliedFilterSelector);

  const allNavUrls = useSelector(allNavUrlsSelector);
  const condition = [
    USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_DEPARTMENTS,
    USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_PROJECTS,
  ];

  const budgetPermission = allNavUrls?.filter((data) =>
    condition?.includes(data?.key)
  );

  const departmentBudgetPermission = budgetPermission?.some(
    (d) =>
      d.key ===
      USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_DEPARTMENTS
  );

  const projectBudgetPermission = budgetPermission?.some(
    (d) =>
      d.key ===
      USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_PROJECTS
  );

  const onlyManageProjectPermission =
    projectBudgetPermission && !departmentBudgetPermission;

  const onReset = () => {
    dispatch(resetBudgetsStoreState());
  };
  const filterOptions = {
    q: searchText,
    budget_type: onlyManageProjectPermission ? PROJECT : type,
    ...convertFilters(appliedFilters),
  };
  const loadMore = () => {
    dispatch(
      fetchBudgets({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...filterOptions,
      })
    );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  return (
    <BudgetListing
      isEmpty={isEmpty}
      hasMore={hasMore}
      isFetching={isFetching}
      handleRefChange={handleRefChange}
      data={budgets}
      filters={filters}
      type={type}
    />
  );
}

export default BudgetsPageHelper;

BudgetsPageHelper.propTypes = {
  type: PropTypes.string,
};
