import PropTypes from "prop-types";
import React from "react";

import { amountToCurrency } from "@/utils/common";

function AvailableBalance({ availableBalance, currency }) {
  return (
    <div className="text-sm font-semibold text-neutral-800">
      {amountToCurrency(availableBalance, currency)}
    </div>
  );
}

export default AvailableBalance;
AvailableBalance.propTypes = {
  availableBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
};
