import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  createGlobalAccount,
  setSelectedGlobalAccountCurrency,
  setSelectedGlobalAccountLocation,
} from "@/store/reducers/account";
import { fetchAccountWallets } from "@/store/reducers/client";

import {
  globalCountryCurrenciesSelector,
  selectedGlobalAccountCurrencySelector,
  selectedGlobalAccountLocationSelector,
} from "@/store/selectors/account";
import { defaultCurrencySelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { COUNTRY_CURRENCY } from "@/constants/currency";

function ReviewGlobalAccountSlider() {
  const dispatch = useDispatch();

  const currency = useSelector(selectedGlobalAccountCurrencySelector);

  const location = useSelector(selectedGlobalAccountLocationSelector);

  const globalCountryCurrencies = useSelector(globalCountryCurrenciesSelector);

  const details = [
    {
      label: "company.globalAccounts.bankLocation",
      value: COUNTRY_CURRENCY[location]?.country,
    },
    { label: "common.currency", value: currency },
    {
      label: "reimbursement.createPayment.paymentMethod",
      value:
        location && globalCountryCurrencies[currency]?.[location]?.join(", "),
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const isPrimaryAccountSlider = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.primaryAccount
  );

  const onSuccess = () => {
    dispatch(setSelectedGlobalAccountLocation(null));
    dispatch(setSelectedGlobalAccountCurrency(null));

    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.reviewGlobalAccount);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.addAccountLocation);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.primaryAccount);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.globalAccounts);

    setSearchParams(searchParams);

    dispatch(fetchAccountWallets());
  };

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        {" "}
        <div className="flex flex-col">
          <Text
            classes="payment-slider-heading"
            translationKey="company.globalAccounts.reviewTitle"
          />
          <Text
            classes="text-neutral-500 text-sm"
            translationKey="company.globalAccounts.reviewDesc"
          />
        </div>
        <div className="gap-4 py-1 mt-10 mb-4 border rounded-lg h-fit border-neutral-200">
          {details?.map((data, idx) => {
            return (
              <div
                className={`flex flex-row justify-between p-4 ${
                  idx === details.length - 1 ? "" : "border-b"
                } border-solid border-b-neutral-200`}
                key={idx}
              >
                <div className="flex flex-row justify-between flex-1">
                  <Text
                    translationKey={data?.label}
                    classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500"
                  />
                  <Text
                    translationKey={data?.value}
                    classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <Text
          classes="text-neutral-500 text-sm "
          translationKey="company.globalAccounts.reviewNote"
          translationProps={{
            country: COUNTRY_CURRENCY[location]?.country,
          }}
        />
      </div>
      <div className="sticky p-6 slider-footer ">
        <div className="flex justify-end ">
          <Button
            classes="w-16 text-neutral-500 mr-3"
            label="company.globalAccounts.cancel"
            onClick={() => onSuccess()}
            variant="tertiary"
            type="default"
          />
          <Button
            label="company.globalAccounts.addAccount"
            classes="w-18 px-5 py-3 text-base font-semibold"
            id="add-account-button"
            onClick={() => {
              const params = {
                country_code: location,
                currency,
                ...(isPrimaryAccountSlider ? { primary_account: true } : {}),
              };
              dispatch(createGlobalAccount({ params, onSuccess }));
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ReviewGlobalAccountSlider;
