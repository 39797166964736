import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { setIsFormSubmissionProgress } from "@/store/reducers/loadersError";
import { TAX_DETAILS_QUERY_PARAM } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import {
  INVOICE_INBOX_LISTING_PARAMS,
  INVOICE_INBOX_RECURRING_LISTING_PARAMS,
} from "@/utils/constants/payments";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import vToast from "@/utils/vToast";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  APPROVAL_PAYLOAD_MAPPING,
  SUBMISSION_PAYLOAD_MAPPING,
} from "@/constants/policy";
import API from "@/api";

export const PURCHASE_BILLS_SLICE_ATTRIBUTE_KEY = "purchaseBills";
export const INVOICE_TABLE_SLICE_ATTRIBUTE_KEY = "invoiceBills";
export const PAYROLL_SLICE_ATTRIBUTE_KEY = "payrolls";
export const RECURRING_PAYMENTS_SLICE_ATTRIBUTE_KEY = "recurringPayments";

/**
 * To be used inside thunk or slice's reducer
 *
 * @param {{Object=}} actionPayload  thunk's first param or `action.payload` (`action` is the second param of the slice reducer)
 *
 * @returns {{key: PURCHASE_BILLS_SLICE_ATTRIBUTE_KEY | RECURRING_PAYMENTS_SLICE_ATTRIBUTE_KEY, value: Object=}}
 */
const getListKeyAndPayload = (actionPayload) => {
  actionPayload ??= {};

  // Note: if 'key' is absent, 'value' is the same as payload
  // and it is assumed the operation is meant for purchaseBill, not recurring payments
  // Why do this: keeps existing code simple, i.e. `dispatch(someReducer(valueToSet))` works,
  // as opposed to forcing `dispatch(someReducer({value: valueToSet, key: ...}))` for purchaseBill ops

  return {
    key: actionPayload?.key || PURCHASE_BILLS_SLICE_ATTRIBUTE_KEY,
    value: actionPayload?.key ? actionPayload?.value : actionPayload,
  };
};

const purchaseBillsInitialState = {
  purchaseBills: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: 0,
    isFetching: false,
    hasMore: true,
    ctas: [],
  },
  invoiceBills: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: 0,
    isFetching: false,
    hasMore: true,
  },
  recurringPayments: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: 0,
    isFetching: false,
    hasMore: true,
  },
  isProcessingCount: 0,
  selectedPurchaseBill: null,
  isPurchaseBillFetching: false,

  inIndonesia: false, // temporary (toggle here for testing) used if PPnBM tax is available in line item during create bill

  quote: null,
  isFetchingQuote: false,

  purchaseBillTaxDetails: [],
  isFetchingPurchaseBillTaxDetails: false,
  isFetchedPurchaseBillTaxDetails: false,
  isFetchingApiCall: false,
  isLoading: false,

  isFetchingDuplicateBill: false,
  duplicateBill: null,

  billPayrollCreationSubmissionPolicy: null,
  isFetchingBillPayrollCreationSubmissionPolicy: false,

  billPayrollCreationApprovalPolicy: null,
  isFetchingbillPayrollCreationApprovalPolicy: false,
};

const purchaseBillsSlice = createSlice({
  name: "purchaseBills",
  initialState: purchaseBillsInitialState,
  reducers: {
    setIntialState: () => purchaseBillsInitialState,
    setInPurchaseBillSlice(state, action) {
      // for handling multiplicity, and avoiding initial undefined slice state
      // usage: run in the feat component once at the beginning
      // selector usage: use callback notation, useSelector((store) => store.payments[yourKey]);
      // tip: don't add anything to selector file for dynamic keys, nor reducers file. just specify keys, and use callback for selectorors
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] = value;
    },

    // these are used for both purchaseBills, and recurringPayments parts of the slice
    setPurchaseBillsIntialState(state, action) {
      const { key } = getListKeyAndPayload(action.payload);

      state[key] = purchaseBillsInitialState[key];
    },
    setPurchaseBillsList(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].list = value;
    },
    appendToPurchaseBillsList(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      const listToAppend = value;
      state[key] ||= {};
      state[key].list ||= [];
      state[key].list = state[key].list.concat(listToAppend);
    },
    prependToPurchaseBillsList(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      const listOrObj = value;
      state[key] ||= {};
      state[key].list ||= [];
      state[key].list = [
        ...(Array.isArray(listOrObj) ? listOrObj : [listOrObj]),
        ...state[key].list,
      ];
    },
    setPurchaseBillsPage(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].page = value;
    },
    setPurchaseBillsLimit(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].limit = value;
    },
    setPurchaseBillsTotal(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].total = value;
    },
    setIsPurchaseBillsFetching(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].isFetching = value;
    },
    setPurchaseBillsHasMore(state, action) {
      const { key } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].list ||= [];
      state[key].hasMore = state[key].list.length < state[key].total;
    },
    setPurchaseBillCtas(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].ctas = value;
    },
    removePurchaseBill(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);

      const id = value;
      state[key] ||= {};
      state[key].list ||= [];
      state[key].list = state[key].list.filter(
        (val) => Number(val.id) !== Number(id)
      );
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPurchaseBill(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);

      const { id, value: valueToBeSet } = value;

      state[key] ||= {};
      state[key].list ||= [];
      const idx = state[key].list.findIndex(
        (item) => Number(item.id) === Number(id)
      );
      if (idx !== -1) state[key].list[idx] = valueToBeSet;
    },

    setIsProcessingCount(state, action) {
      state.isProcessingCount = action.payload;
    },

    setIsFetchingPurchaseBillQuote(state, action) {
      state.isFetchingQuote = action.payload;
    },
    setPurchaseBillQuote(state, action) {
      state.quote = action.payload;
    },

    setPurchaseBillTaxDetails(state, action) {
      state.purchaseBillTaxDetails = action.payload;
    },
    setIsFetchingPurchaseBillTaxDetails(state, action) {
      state.isFetchingPurchaseBillTaxDetails = action.payload;
    },
    setIsFetchedPurchaseBillTaxDetails(state, action) {
      state.isFetchedPurchaseBillTaxDetails = action.payload;
    },
    resetPurchaseBillTaxDetails(state) {
      state.purchaseBillTaxDetails =
        purchaseBillsInitialState.purchaseBillTaxDetails;
      state.isFetchingPurchaseBillTaxDetails =
        purchaseBillsInitialState.isFetchingPurchaseBillTaxDetails;
      state.isFetchedPurchaseBillTaxDetails =
        purchaseBillsInitialState.isFetchedPurchaseBillTaxDetails;
    },
    setIsFetchingApiCall(state, action) {
      state.isFetchingApiCall = action.payload;
    },
    setIsFetchingDuplicateBill(state, action) {
      state.isFetchingDuplicateBill = action.payload;
    },
    setDuplicateBill(state, action) {
      state.duplicateBill = action.payload;
    },
    setBillPayrollCreationSubmissionPolicy(state, action) {
      state.billPayrollCreationSubmissionPolicy = action.payload;
    },
    setIsFetchingBillPayrollCreationSubmissionPolicy(state, action) {
      state.isFetchingBillPayrollCreationSubmissionPolicy = action.payload;
    },
    setBillPayrollCreationApprovalPolicy(state, action) {
      state.billPayrollCreationApprovalPolicy = action.payload;
    },
    setIsFetchingBillPayrollCreationApprovalPolicy(state, action) {
      state.isFetchingbillPayrollCreationApprovalPolicy = action.payload;
    },
    setIsActionPendingForPurchaseBill(state, action) {
      state.isActionPendingFor = action.payload;
    },
  },
});

// async action creators
// for both context - purchasebill/payrolls
export const fetchPurchaseBills = createAsyncThunk(
  "purchaseBills/fetchPurchaseBills",
  async (params, { dispatch }) => {
    const { context, listingParams } = params;
    const { key, value } = getListKeyAndPayload(params);

    const listingQueryParams = listingParams
      ? listingParams
      : key === RECURRING_PAYMENTS_SLICE_ATTRIBUTE_KEY
        ? INVOICE_INBOX_RECURRING_LISTING_PARAMS
        : INVOICE_INBOX_LISTING_PARAMS;

    dispatch(setIsPurchaseBillsFetching({ value: true, key }));

    const [err, response] = await to(
      context === BILL_PAYROLL_CONTEXT.BILLPAY
        ? API.PurchaseBills.all({ ...value, ...listingQueryParams })
        : API.Payrolls.all({ ...value, ...listingQueryParams })
    );

    if (!err && response) {
      const { data } = response;

      if (params.value.page === 1) {
        dispatch(setPurchaseBillsList({ value: data.list, key }));
      } else {
        dispatch(appendToPurchaseBillsList({ value: data.list, key }));
      }

      dispatch(setPurchaseBillsLimit({ value: data.limit, key }));
      dispatch(setPurchaseBillsTotal({ value: data.total, key }));
      dispatch(setPurchaseBillsPage({ value: data.page, key }));
      dispatch(setPurchaseBillCtas({ value: data.ctas, key }));
      dispatch(setPurchaseBillsHasMore({ key }));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsPurchaseBillsFetching({ value: false, key }));
  }
);

export const fetchQuoteForPurchaseBill = createAsyncThunk(
  "purchaseBills/fetchQuoteForPurchaseBill",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPurchaseBillQuote(true));
    const { payload, onSuccess = () => {}, onFail = () => {} } = params;

    const [error, response] = await to(API.PurchaseBills.getQuote(payload));
    if (response?.data && !error) {
      dispatch(setPurchaseBillQuote(response.data));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } else if (error) {
      vToast(getErrorToastMessage(error));
      if (onFail) {
        onFail();
      }
    }

    dispatch(setIsFetchingPurchaseBillQuote(false));
  }
);

export const fetchPurchaseBillTaxDetails = createAsyncThunk(
  "purchaseBills/fetchPurchaseBillTaxDetails",
  async (params, { dispatch }) => {
    const { context, [TAX_DETAILS_QUERY_PARAM]: vendor_id } = params;
    dispatch(setIsFetchingPurchaseBillTaxDetails(true));

    const [err, response] = await to(API.Vendors.getTaxDetails({ vendor_id }));
    if (response) {
      dispatch(setPurchaseBillTaxDetails(response.data));
      dispatch(setIsFetchedPurchaseBillTaxDetails(true));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingPurchaseBillTaxDetails(false));
  }
);

export const createPurchaseBill = createAsyncThunk(
  "purchaseBills/createPurchaseBill",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const {
      payload,
      context,
      noToast = false,
      onSuccess = () => {},
      onError = () => {},
    } = params;
    dispatch(setIsFetchingApiCall(true));

    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.BILLPAY
        ? API.PurchaseBills.create(payload)
        : API.Payrolls.create(payload)
    );

    if (!error && response) {
      const bill = response?.data?.data || response?.data; // TODO response-nesting
      dispatch(setIsFetchingApiCall(false));
      onSuccess(bill);
      if (!noToast) {
        vToast(getSuccessToastMessage(response));
      }
    } else {
      dispatch(setIsFetchingApiCall(false));
      onError();
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const fetchDuplicateBill = createAsyncThunk(
  "purchaseBills/fetchDuplicateBill",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingDuplicateBill(true));

    const { context, onSuccess = () => {}, ...rest } = params;

    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.BILLPAY
        ? API.PurchaseBills.getDuplicate(rest)
        : API.Payrolls.getDuplicate(rest)
    );

    if (!error && response) {
      dispatch(setDuplicateBill(response.data?.data ?? null));
      onSuccess();
    } else {
      vToast(getErrorToastMessage(error?.message));
    }

    dispatch(setIsFetchingDuplicateBill(false));
  }
);

// Edit bill CTA
export const updatePurchaseBill = createAsyncThunk(
  "purchaseBills/edit",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const {
      id,
      payload,
      context,
      noToast,
      onSuccess = () => {},
      onError = () => {},
    } = params;

    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.BILLPAY
        ? API.PurchaseBills.update(id, payload)
        : API.Payrolls.update(id, payload)
    );

    if (!error && response) {
      const bill = response?.data?.data || response?.data; // TODO response-nesting

      dispatch(
        setPurchaseBill({
          key:
            context === BILL_PAYROLL_CONTEXT.BILLPAY
              ? PURCHASE_BILLS_SLICE_ATTRIBUTE_KEY
              : PAYROLL_SLICE_ATTRIBUTE_KEY,
          value: { id: bill.id, value: bill },
        })
      );
      onSuccess(bill);
      if (!noToast) {
        vToast(getSuccessToastMessage(response));
      }
    } else {
      vToast(getErrorToastMessage(error));
      onError();
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const performActionThunk = createAsyncThunk(
  "purchaseBills/performActionThunk",
  async (params, { dispatch }) => {
    const {
      id,
      context,
      onSuccess = () => {},
      onError = () => {},
      ...rest
    } = params;

    dispatch(setIsLoading(true));
    dispatch(setIsActionPendingForPurchaseBill(id));

    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.PAYROLL
        ? API.Payrolls.performAction(id, rest)
        : API.PurchaseBills.performAction(id, rest)
    );

    const billOrPayment = response?.data?.data || response?.data; // TODO response-nesting
    if (response && !error) {
      vToast(getSuccessToastMessage(response));
      onSuccess(billOrPayment);
    } else if (error) {
      vToast(getErrorToastMessage(error));
      onError();
    }

    dispatch(setIsLoading(false));
    dispatch(setIsActionPendingForPurchaseBill(null));
  }
);

// "delete_draft" CTA
export const deleteDraft = createAsyncThunk(
  "purchaseBills/deleteDraft",
  async (params, { dispatch }) => {
    const { context, id, onSuccess = () => {} } = params;
    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.PAYROLL
        ? API.Payrolls.deleteDraft(id)
        : API.PurchaseBills.deleteDraft(id)
    );

    const deletedBill = response?.data?.data || response?.data; // TODO response-nesting
    if (!error && response) {
      dispatch(removePurchaseBill(id));
      onSuccess(deletedBill);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);

// Archive CTA
export const archive = createAsyncThunk(
  "purchaseBills/archive",
  async (params, { dispatch }) => {
    const { context, id, onSuccess = () => {}, ...rest } = params;

    dispatch(setIsActionPendingForPurchaseBill(id));

    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.BILLPAY
        ? API.PurchaseBills.archive(id, rest)
        : API.Payrolls.archive(id, rest)
    );
    const deletedBill = response?.data?.data || response?.data; // TODO response-nesting

    if (!error && response) {
      dispatch(removePurchaseBill(id));
      onSuccess(deletedBill);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsActionPendingForPurchaseBill(null));
  }
);

export const fetchBillPayrollCreationSubmissionPolicy = createAsyncThunk(
  "purchaseBills/fetchBillPayrollCreationSubmissionPolicy",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingBillPayrollCreationSubmissionPolicy(true));

    const { context, projectId } = params;
    const [error, response] = await to(
      API.SubmissionPolicy.getPolicy({
        project_id: projectId,
        submission_policy_type:
          context === BILL_PAYROLL_CONTEXT.BILLPAY
            ? SUBMISSION_PAYLOAD_MAPPING.payments
            : SUBMISSION_PAYLOAD_MAPPING.payroll,
      })
    );
    if (!error && response) {
      dispatch(setBillPayrollCreationSubmissionPolicy(response.data));
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsFetchingBillPayrollCreationSubmissionPolicy(false));
  }
);

export const fetchBillPayrollCreationApprovalPolicy = createAsyncThunk(
  "purchaseBills/fetchBillPayrollCreationSubmissionPolicy",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingBillPayrollCreationApprovalPolicy(true));

    const { context } = params;
    const [error, response] = await to(
      API.ApprovalPolicy.getPolicy({
        policy_group_type:
          context === BILL_PAYROLL_CONTEXT.PAYROLL
            ? APPROVAL_PAYLOAD_MAPPING.payroll
            : APPROVAL_PAYLOAD_MAPPING.payments,
      })
    );
    if (!error && response) {
      dispatch(setBillPayrollCreationApprovalPolicy(response.data));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingBillPayrollCreationApprovalPolicy(false));
  }
);

export const {
  setInPurchaseBillSlice,
  setIntialState,
  setPurchaseBillsIntialState,
  setPurchaseBillsList,
  setIsPurchaseBillsFetching,
  appendToPurchaseBillsList,
  prependToPurchaseBillsList,
  setPurchaseBillsPage,
  setPurchaseBillsTotal,
  setPurchaseBillsHasMore,
  setPurchaseBillsLimit,
  setPurchaseBillCtas,
  removePurchaseBill,
  setPurchaseBill,
  setIsProcessingCount,

  setIsFetchingPurchaseBillQuote,
  setPurchaseBillQuote,

  setPurchaseBillTaxDetails,
  setIsFetchingPurchaseBillTaxDetails,
  setIsFetchedPurchaseBillTaxDetails,
  resetPurchaseBillTaxDetails,
  setIsFetchingApiCall,
  setIsLoading,
  setIsFetchingDuplicateBill,
  setDuplicateBill,
  setBillPayrollCreationSubmissionPolicy,
  setIsFetchingBillPayrollCreationSubmissionPolicy,
  setBillPayrollCreationApprovalPolicy,
  setIsFetchingBillPayrollCreationApprovalPolicy,
  setIsActionPendingForPurchaseBill,
} = purchaseBillsSlice.actions;

export default purchaseBillsSlice.reducer;
