import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { cardProviderWithInternationalCardOrderEnabledSelector } from "@/store/selectors/client";

import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import { getCardRequestTypeTableDataConfig } from "@/components/Cards/util";
import { dateToString } from "@/utils/common";

import { CARD_REQUEST_TYPE_CELL_TOOLTIPS_CONFIG } from "@/constants/ActionCentre";
import { CARD_REQUEST_TYPES } from "@/constants/company";

export default function CardRequestTypeCell({
  cardType,
  requestType,
  name,
  requestDate,
  handleCheckboxClick,
  isChecked,
  showCheckbox,
  cardRequestId,
  disabled,
  cardUsageType,
  status,
}) {
  const { t } = useTranslation();
  const hasCardProvidersWithInternationalCardOrder = useSelector(
    cardProviderWithInternationalCardOrderEnabledSelector
  );
  const config = getCardRequestTypeTableDataConfig(
    hasCardProvidersWithInternationalCardOrder,
    cardUsageType
  );

  const _config =
    CARD_REQUEST_TYPES.TOP_UP_REQUEST === requestType
      ? config?.[requestType]?.[cardType]
      : config?.[requestType];

  const requestDateText = dateToString(requestDate);
  const cardRequestStatusToolTipConfig =
    CARD_REQUEST_TYPE_CELL_TOOLTIPS_CONFIG?.[status];
  const tooltipText = cardRequestStatusToolTipConfig?.text;
  const tooltipClasses = cardRequestStatusToolTipConfig?.classes;
  const tooltipIcon = cardRequestStatusToolTipConfig?.icon;

  return (
    <div className="flex flex-row items-center gap-3">
      {showCheckbox ? (
        <Checkbox
          onClickHandler={handleCheckboxClick}
          checked={isChecked}
          disabled={disabled}
        />
      ) : null}
      <ProfileWidget
        textClasses="font-bold text-sm"
        iconComponent={
          <div className={`font-normal rounded-full ${_config?.iconClasses}`}>
            <Icon name={_config?.iconName} />
          </div>
        }
        name={name}
      >
        <div className="flex flex-row items-center gap-1 text-xs text-neutral-500">
          <Text
            translationKey={requestDateText}
            classes="text-neutral-500 font-normal"
          />
          {requestDateText ? (
            <span className="w-1 h-1 rounded-full bg-neutral-500" />
          ) : null}
          <Text translationKey={_config?.label} classes={_config?.classes} />
        </div>
      </ProfileWidget>

      {cardRequestStatusToolTipConfig ? (
        <div>
          <div id={`card-schedule-tooltip-${cardRequestId}`}>
            <Icon name={tooltipIcon} className={tooltipClasses} />
          </div>

          <Tooltip
            id={`card-schedule-tooltip-${cardRequestId}`}
            direction="left"
          >
            <Text translationKey={tooltipText} classes="text-neutral-500" />
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
}

CardRequestTypeCell.propTypes = {
  cardType: PropTypes.string,
  requestType: PropTypes.string,
  name: PropTypes.string,
  requestDate: PropTypes.string,
  handleCheckboxClick: PropTypes.func,
  isChecked: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  status: PropTypes.string,
  cardRequestId: PropTypes.number,
  disabled: PropTypes.bool,
  cardUsageType: PropTypes.string,
};
