import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchOnboardingStepApi,
  setOnboardingSection,
} from "@/store/reducers/onboarding";
import {
  ckycConsentChange,
  fetchUserKycStatusInfo,
  kycStatusUpdate,
  vkycConsentChange,
} from "@/store/reducers/user";

import { onboardingSectionSelector } from "@/store/selectors/onboarding";
import {
  isFetchingUserKycInfoSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Note from "@/components/core/Note";
import NoteLister from "@/components/core/NoteLister";
import StepWithExternalLink from "@/components/core/StepWithExternalLink";

import CommonHeaderComponent from "@/components/Onboarding/OnboardingForm/CommonHeaderComponent";

import {
  KYC_STEPS_STATUS,
  ONBOARDING_SLIDER_PARAMS,
  ONBOARDING_STEPS,
  PINELABS_CKYC_CONFIG,
} from "@/constants/onboarding";

import AadharNotfier from "./AadharNotfier";
import PanNotifier from "./PanNotifier";

function PineLabsIdentityDetailsForm() {
  const UI = {
    MAIN_PAGE: "main_page",
    AADHAR: "aadhar",
    PAN: "pan",
  };

  const TOOGLE_UI_CONFIG = {
    [ONBOARDING_STEPS.PINE_LABS_C_KYC]: {
      MAIN_PAGE: UI.MAIN_PAGE,
      ALTERNATE_PAGE: {
        key: UI.PAN,
        component: <PanNotifier />,
      },
    },
    [ONBOARDING_STEPS.PINE_LABS_V_KYC]: {
      MAIN_PAGE: UI.MAIN_PAGE,
      ALTERNATE_PAGE: {
        key: UI.AADHAR,
        component: <AadharNotfier />,
      },
    },
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const onboardingFormResponse = useSelector(onboardingSectionSelector);
  const onboardingSection = onboardingFormResponse?.sections[0];
  const showSectionInstruction = onboardingSection?.showSectionInstruction;
  const vkycInitiated = onboardingSection?.vkycInitiated;
  const formKey = onboardingSection?.key || onboardingSection?.step;
  const { fields, ...commonHeaderProps } = onboardingSection;
  const errorNote = onboardingSection?.errorNote;
  const errorTitleText = errorNote?.titleText;
  const errorDescriptionText = errorNote?.descriptionText || "";
  const errorActionText = errorNote?.actionText || "";
  const errorBackgroundClasses = errorNote?.backgroundColorClass;
  const errorBorderClass = errorNote?.borderColorClass;
  const currentUser = useSelector(userSelector);
  const userId = currentUser?.id;
  const [toggleUi, setToogleUi] = useState(
    TOOGLE_UI_CONFIG?.[formKey]?.MAIN_PAGE
  );
  const [primaryButtonClicked, setPrimaryButtonClicked] = useState(false);
  const AlternateComponent =
    TOOGLE_UI_CONFIG?.[formKey]?.ALTERNATE_PAGE?.component;
  const { buttonLabel, handleClick } = getButtonConfig();
  const isFetchingUserKycInfo = useSelector(isFetchingUserKycInfoSelector);

  useEffect(() => {
    searchParams.set(ONBOARDING_SLIDER_PARAMS, formKey);
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && primaryButtonClicked) {
        handleCheckStatusButtonClick();
        togglePrimaryButtonClickedBool();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [primaryButtonClicked]);

  function togglePrimaryButtonClickedBool() {
    setPrimaryButtonClicked(!primaryButtonClicked);
  }

  function getButtonConfig() {
    const buttonConfig = {
      disabled: false,
      handleClick: () => {},
      buttonLabel: "",
    };

    if (toggleUi === TOOGLE_UI_CONFIG?.[formKey].MAIN_PAGE) {
      if (
        formKey === ONBOARDING_STEPS.PINE_LABS_V_KYC ||
        (formKey === ONBOARDING_STEPS.PINE_LABS_C_KYC && !vkycInitiated)
      ) {
        if (showSectionInstruction) {
          buttonConfig.buttonLabel = "Continue";
          buttonConfig.handleClick = () => {
            setToogleUi(TOOGLE_UI_CONFIG?.[formKey]?.ALTERNATE_PAGE?.key);
          };
        } else {
          const areAllPendingSteps = allStepsPending(); // Checking if all the steps status are pending.
          const isAnyStepFailed = isStepFailed(); // Checking if any step has pending status

          if (areAllPendingSteps) {
            buttonConfig.buttonLabel = "Getting Started";
          } else if (isAnyStepFailed) {
            buttonConfig.buttonLabel = "Retry";
          } else {
            buttonConfig.buttonLabel = "Continue";
          }
          buttonConfig.handleClick = () => {
            redirectToProviderPage(onboardingSection?.fields?.[0]?.linkUrl);
          };
        }
      } else {
        buttonConfig.buttonLabel = "Continue";
        buttonConfig.handleClick = () => {
          dispatch(setOnboardingSection(null));

          dispatch(
            kycStatusUpdate({
              onSuccess: () => {
                dispatch(fetchOnboardingStepApi());
              },
            })
          );
        };
      }

      return buttonConfig;
    }

    return {
      buttonLabel: "Getting Started",
      handleClick: () => {
        const dispatcherFunc =
          formKey === ONBOARDING_STEPS.PINE_LABS_C_KYC
            ? ckycConsentChange
            : vkycConsentChange;

        dispatch(
          dispatcherFunc({
            id: userId,
            onSuccess: onConsentChangeSuccess,
          })
        );
      },
    };
  }

  function onConsentChangeSuccess(consentInfo) {
    const redirectionLink = consentInfo?.kycLink;
    redirectToProviderPage(redirectionLink);
    setToogleUi(TOOGLE_UI_CONFIG?.[formKey]?.MAIN_PAGE);
  }

  function redirectToProviderPage(linkUrl) {
    togglePrimaryButtonClickedBool();
    window.open(linkUrl, "_blank");
  }

  function allStepsPending() {
    return (
      fields?.filter(
        (field) =>
          field?.status === KYC_STEPS_STATUS.PENDING || field?.status === null
      )?.length === fields?.length
    );
  }

  function isStepFailed() {
    return (
      fields?.filter((field) => field?.status === KYC_STEPS_STATUS.FAILED)
        ?.length > 0
    );
  }

  function generateStepsWithLinkByFormKey() {
    return (
      <div className="flex flex-col gap-5">
        {fields?.map((field, index) => {
          const stepWithLinkProps = {
            label: field?.label,
            hasBorderBottom: index < fields.length - 1,
          };

          if (formKey === ONBOARDING_STEPS.PINE_LABS_C_KYC) {
            const stepStatus = field?.status;

            stepWithLinkProps.stepStatusIcon = stepStatus
              ? PINELABS_CKYC_CONFIG?.[stepStatus]?.iconConfig?.name
              : null;

            stepWithLinkProps.stepStatusName = stepStatus
              ? PINELABS_CKYC_CONFIG?.[stepStatus]?.nameConfig?.text
              : null;

            stepWithLinkProps.iconClasses = stepStatus
              ? PINELABS_CKYC_CONFIG?.[stepStatus]?.iconConfig?.classes
              : null;

            stepWithLinkProps.stepNameClasses = stepStatus
              ? PINELABS_CKYC_CONFIG?.[stepStatus]?.nameConfig?.classes
              : null;

            stepWithLinkProps.iconName = stepStatus
              ? PINELABS_CKYC_CONFIG?.[stepStatus]?.iconConfig?.name
              : null;

            stepWithLinkProps.stepName = `Step ${index + 1}`;
          } else {
            stepWithLinkProps.description = field?.helperText;
            stepWithLinkProps.iconName = field?.iconName;
            stepWithLinkProps.iconClasses = "text-neutral-500";
          }

          return (
            <StepWithExternalLink
              {...stepWithLinkProps}
              key={`${field?.key}-${field?.label}`}
            />
          );
        })}
      </div>
    );
  }

  function handleCheckStatusButtonClick() {
    dispatch(fetchUserKycStatusInfo());
  }

  return (
    <div className="slider-content-core py-9">
      <CommonHeaderComponent headerProps={{ ...commonHeaderProps }} />

      {errorDescriptionText && toggleUi !== UI.PAN && toggleUi !== UI.AADHAR ? (
        <Note
          titleText={errorTitleText}
          descriptionText={errorDescriptionText}
          actionText={errorActionText}
          backgroundColorClass={errorBackgroundClasses}
          borderColorClass={errorBorderClass}
          externalDivClasses="mb-5"
        />
      ) : null}

      {toggleUi === UI.MAIN_PAGE ? (
        <div>
          <NoteLister
            noteList={onboardingSection?.helperModal}
            externalCss="mb-5"
          />

          {/* The below note is for showing the error */}

          {generateStepsWithLinkByFormKey()}
        </div>
      ) : (
        AlternateComponent
      )}

      <div className="absolute bottom-0 flex items-center justify-end gap-6 p-4 slider-footer h-14">
        <Button
          label="onboarding.onboardingSteps.pineLabs.checkStatus"
          onClick={handleCheckStatusButtonClick}
          disabled={isFetchingUserKycInfo}
          classes="items-self-end justify-self-end h-11 w-18.5"
        />

        <Button
          disabled={isFetchingUserKycInfo}
          onClick={handleClick}
          form="onboardingFrom"
          label={buttonLabel}
          classes="w-84 h-11 items-self-end justify-self-end"
        />
      </div>
    </div>
  );
}

export default PineLabsIdentityDetailsForm;
