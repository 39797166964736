import PropTypes from "prop-types";

// import "@/assets/styles/theme.scss";

import Text from "../Text";

export default function Chip({
  label,
  variant = "primary",
  type = "default",
  muted = false,
  classes = "",
  labelTranslationProps = {},
}) {
  return (
    <Text
      classes={`px-2 py-[2px] rounded-full font-semibold text-xs flex align-center cursor-pointer chip-${type}-${variant}${
        muted ? "-muted" : ""
      } ${!classes?.includes("w-") ? "w-max" : ""} ${classes}`}
      translationKey={label}
      translationProps={labelTranslationProps}
    />
  );
}

Chip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  type: PropTypes.string,
  muted: PropTypes.bool,
  classes: PropTypes.string,
  labelTranslationProps: PropTypes.object,
};
