import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import Badge from "@/components/core/Badge";
import { capitalizeFirstAndLowercaseRest } from "@/utils/common";
import { TRANSACTION_STATUS_KEY } from "@/utils/constants/common";
import { TRANSACTION_STATUS_BADGE } from "@/utils/constants/expenses";

export default function ExpenseStatusBadge({ transactionStatus }) {
  const config =
    TRANSACTION_STATUS_BADGE[TRANSACTION_STATUS_KEY[transactionStatus]];
  const { t } = useTranslation();
  const badgeKey = t(config?.text);
  return (
    <Badge
      translationKey={capitalizeFirstAndLowercaseRest(badgeKey)}
      classes="px-6"
      variant={config?.color}
    />
  );
}
ExpenseStatusBadge.propTypes = {
  transactionStatus: PropTypes.string,
};
