import PropTypes from "prop-types";

import Alert from "@/components/core/Alert";

export default function DuplicatePaymentAlert({
  existingDuplicateId,
  inPayrollContext = false,
  classes = "",
  handleClick = () => {},
}) {
  return (
    <Alert
      classes={classes}
      title={
        inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.duplicateSalary.title"
          : "billPay.bill.invoiceInbox.createBill.alert.duplicateBill.title"
      }
      description={
        inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.duplicateSalary.description"
          : "billPay.bill.invoiceInbox.createBill.alert.duplicateBill.description"
      }
      variant="warning"
      icon="Info"
      wrapperDivClass="items-start"
      primaryAction={{
        callback: (e) => {
          e?.stopPropagation();
          handleClick();
        },
        label: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.duplicateSalary.label"
          : "billPay.bill.invoiceInbox.createBill.alert.duplicateBill.label",
        labelTranslationProps: inPayrollContext
          ? null
          : { billId: existingDuplicateId },
        classes: "underline",
      }}
      iconClasses="mt-1"
    />
  );
}

DuplicatePaymentAlert.propTypes = {
  existingDuplicateId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  inPayrollContext: PropTypes.bool,
  classes: PropTypes.string,
  handleClick: PropTypes.func,
};
