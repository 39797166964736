import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setCompanyFilters, setDurationLabel } from "@/store/reducers/company";

import {
  billingDaySelector,
  clientCreatedAtSelector,
  currentBillingStartAndEndDateSelector,
  previousBillingStartAndEndDateSelector,
} from "@/store/selectors/client";
import { companyFiltersSelectors } from "@/store/selectors/company";

import { dashboardAnalyticsSpendTypeFilterSelector } from "@/store/selectors/user";
import DateRangeFilter from "@/components/core/Filters/DateRangeFilter";
import ListFilter from "@/components/core/Filters/ListFilter";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import { DATE_RANGE_FILTER_MODE } from "@/utils/constants/filters";
import { dateDifferenceInfo, getDateInPattern, getRange } from "@/utils/common";

import {
  DURATION,
  SPEND_DURATION_VALID_FREQUENCY,
  SPEND_FILTER_DURATION,
  SPEND_FILTER_DURATION_LABELS,
  SPEND_FREQUENCY_LABELS,
  SPEND_FREQUENCY_MODULE_WISE,
  SPEND_TYPE,
  SPEND_TYPE_LABELS,
} from "@/constants/company";
import { DATE_RANGE_KEYS } from "@/constants/date";

export default function SpendFilters({
  onAccountPage = false,
  disableFilters,
}) {
  const filterValue = useSelector(companyFiltersSelectors);
  const dispatch = useDispatch();
  const clientDate = useSelector(clientCreatedAtSelector);
  const dayTogoForBilllingDay = useSelector(billingDaySelector);
  const previousBillingCylceDate = useSelector(
    previousBillingStartAndEndDateSelector
  );
  const currentBillingCycleDate = useSelector(
    currentBillingStartAndEndDateSelector
  );
  const spentTypeFilter = useSelector(
    dashboardAnalyticsSpendTypeFilterSelector
  );

  const spendTypeOptions = (spentTypeFilter ?? []).map((spendTypeValue) => ({
    value: spendTypeValue,
    label: SPEND_TYPE_LABELS[spendTypeValue],
  }));

  const durationOptions = Object.entries(SPEND_FILTER_DURATION).map(
    ([keys, durationTypeValue]) => ({
      label: SPEND_FILTER_DURATION_LABELS[durationTypeValue],
      durationKey: keys,
      value: SPEND_FILTER_DURATION[keys],
    })
  );

  const frequencyOptions = Object.values(
    SPEND_FREQUENCY_MODULE_WISE.ANALYTICS
  ).map((frequencyValue) => ({
    label: SPEND_FREQUENCY_LABELS[frequencyValue],
    frequencyKey: frequencyValue,
    value: frequencyValue,
  }));
  // Can't use before initailization thats called it here
  const [frequencyFilteredOption, setFrequencyFilteredOptions] =
    useState(frequencyOptions);

  const [filterLabel, setFilterLabel] = useState({
    duration: durationOptions[0]?.label,
    spendType: spendTypeOptions[0]?.label,
    frequency: frequencyOptions[0]?.label,
  });

  const getDurationRange = (object) => {
    const res = object;
    const pattern = "yyyy-mm-dd";
    switch (object?.value) {
      case DATE_RANGE_KEYS.allTime: {
        res.from = clientDate;
        res.to = getDateInPattern(new Date(), pattern);
        break;
      }
      case DATE_RANGE_KEYS.currentStatement:
        res.from = currentBillingCycleDate?.startDate;
        res.to = currentBillingCycleDate?.endDate;
        break;
      case DATE_RANGE_KEYS.prevStatement:
        res.from = previousBillingCylceDate?.startDate;
        res.to = previousBillingCylceDate?.endDate;
        break;
      default: {
        const tempRes = { ...res };
        if (tempRes.to) res.to = tempRes.to.split("-").reverse().join("-");
        if (tempRes.from)
          res.from = tempRes.from.split("-").reverse().join("-");
        break;
      }
    }

    return res;
  };

  const handleChange = (filterKey, object) => {
    let params = filterValue;

    if (filterKey === DURATION) {
      const label = object?.label ?? object;

      const selectedDurationValidFrequencyKeys = [
        SPEND_FILTER_DURATION_LABELS?.[SPEND_FILTER_DURATION.CURRENT_STATEMENT],
        SPEND_FILTER_DURATION_LABELS?.[
          SPEND_FILTER_DURATION.PREVIOUS_STATEMENT
        ],
        SPEND_FILTER_DURATION_LABELS?.[SPEND_FILTER_DURATION.CUSTOM_RANGE],
        SPEND_FILTER_DURATION_LABELS?.[SPEND_FILTER_DURATION.ALL_TIME],
      ]?.includes(label)
        ? null
        : SPEND_DURATION_VALID_FREQUENCY[label];

      const dateRange = getDurationRange(object);

      dispatch(setDurationLabel(label));

      const { durationType, ...rest } = dateDifferenceInfo(
        new Date(dateRange.from),
        new Date(dateRange?.to)
      );

      let firstOption = null;
      if (selectedDurationValidFrequencyKeys) {
        setFrequencyFilteredOptions(selectedDurationValidFrequencyKeys);
        firstOption = selectedDurationValidFrequencyKeys?.[0];
      } else {
        const spendDurationOptions =
          SPEND_DURATION_VALID_FREQUENCY[durationType];
        setFrequencyFilteredOptions(spendDurationOptions);
        firstOption = spendDurationOptions?.[0];
      }
      setFilterLabel((prev) => ({
        ...prev,
        duration: label,
        frequency: firstOption?.label,
      }));

      params = {
        ...filterValue,
        ...dateRange,
        ...{ frequency: firstOption?.value },
      };
    } else
      params = {
        ...filterValue,
        [filterKey]: object[filterKey]?.value,
      };

    if (filterKey !== DURATION) {
      setFilterLabel((prev) => {
        return { ...prev, [filterKey]: object[filterKey]?.label };
      });
    }
    dispatch(setCompanyFilters(params));
  };

  useEffect(() => {
    const frequencyOption =
      SPEND_DURATION_VALID_FREQUENCY[
        SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.THIS_MONTH]
      ];

    const firstOption = frequencyOption[0];
    setFrequencyFilteredOptions(frequencyOption);
    setFilterLabel((prev) => ({
      ...prev,
      frequency: firstOption?.label,
    }));
  }, []);
  useEffect(() => {
    setFilterLabel((prev) => ({
      ...prev,
      spendType: spendTypeOptions[0]?.label,
    }));
  }, [JSON.stringify(spendTypeOptions)]);

  return (
    <div className="flex flex-wrap items-center justify-between">
      <Text
        classes="text-lg text-neutral-500 font-bold"
        translationKey={
          onAccountPage
            ? "company.accountOverview.accountAnalytics"
            : "dashboard.spendAnalytics.title"
        }
      />
      <div className="flex gap-4">
        {onAccountPage ? null : (
          <div className=" min-h-9.5 min-w-[218px]">
            <ListFilter
              defaultValues={filterValue?.spendType}
              id="list-filter-spendType"
              label=""
              filterKey="spendType"
              options={spendTypeOptions}
              callback={(object) => handleChange(SPEND_TYPE, object)}
              popupTriggerComponent={() => (
                <button
                  className="flex items-center flex-1 gap-2 py-3 card-wrapper"
                  disabled={disableFilters}
                >
                  <Text translationKey="company.overview.spendAnalytics.filters.spendTypeLabel" />
                  <Text translationKey={filterLabel?.spendType} />
                  <Icon name="ChevronDown" />
                </button>
              )}
            />
          </div>
        )}
        <div className="min-h-9.5 min-w-[180px]">
          <DateRangeFilter
            type={DATE_RANGE_FILTER_MODE.dropDown}
            options={durationOptions}
            callback={(object) => {
              handleChange(DURATION, object?.dateRange);
            }}
            defaultValues={
              typeof filterValue?.duration === typeof ""
                ? getRange(filterValue?.duration, [])
                : filterValue?.duration
            }
            popupTriggerComponent={() => (
              <button
                className="flex items-center justify-between flex-1 w-full gap-2 py-3 card-wrapper"
                disabled={disableFilters}
              >
                <Text
                  translationKey={
                    typeof filterLabel?.duration === typeof ""
                      ? filterLabel?.duration
                      : SPEND_FILTER_DURATION_LABELS[
                          SPEND_FILTER_DURATION.CUSTOM_RANGE
                        ]
                  }
                />
                <Icon name="ChevronDown" />
              </button>
            )}
          />
        </div>
        {onAccountPage ? null : (
          <div className="min-h-9.5 min-w-[117px]">
            {filterValue?.frequency ? (
              <ListFilter
                defaultValues={filterValue?.frequency}
                id="list-filter-frequency"
                label=""
                filterKey="frequency"
                options={frequencyFilteredOption}
                callback={(object) => handleChange("frequency", object)}
                popupTriggerComponent={() => (
                  <button
                    className="flex items-center flex-1 gap-2 py-3 card-wrapper"
                    disabled={disableFilters}
                  >
                    <Text translationKey={filterLabel?.frequency} />
                    <Icon name="ChevronDown" />
                  </button>
                )}
              />
            ) : (
              <LoaderSkeleton />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

SpendFilters.propTypes = {
  onAccountPage: PropTypes.bool,
  disableFilters: PropTypes.bool,
};
