import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import usePagination from "@/hooks/usePagination";

import {
  fetchPaymentApprovals,
  fetchPayments,
  fetchPayrollApprovals,
} from "@/store/reducers/payments";
import { fetchPurchaseBills } from "@/store/reducers/purchase-bills";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Table from "@/components/core/Table";

import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  PAYMENT_APPROVAL_STATUSES,
  PAYMENT_STATUSES,
  openRelevantPaymentSlider,
} from "@/utils/constants/payments";

import { PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT } from "@/constants/pagination";

/**
 * 3 columned table inside accordion
 *
 * @param {String} context  billpay | payroll enum
 * @param {Object} tableConfig
 * @param {Number} paginationLimit number of rows to render per page
 * @param {Object} params GET api params
 * @param {String} status status of payment, describes type of the table
 *
 * {@link https://www.figma.com/file/nYb1B3o06dNJPuFhW7fLWP/v2-Payroll?type=design&node-id=4815-261904&mode=dev}
 *
 */
export default function DeleteEmployeeTable({
  context = BILL_PAYROLL_CONTEXT.PAYROLL,
  tableConfig = null,
  paginationLimit = PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
  params,
  status,
}) {
  const dispatch = useDispatch();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const [searchParams, setSearchParams] = useSearchParams();

  const listObj = useSelector((store) =>
    status === PAYMENT_STATUSES.inDraft
      ? store.purchaseBills[status]
      : store.payments[status]
  );
  // eslint-disable-next-line one-var
  let list, page, limit, total, isFetching, hasMore;
  if (listObj) {
    ({ list, page, limit, total, isFetching, hasMore } = listObj);
  }
  const isEmpty = !list?.length;
  const onReset = () => {
    // Deliberately empty, moved to ImportantToKnowSection.section?[].cleaup
  };

  const loadMore = () => {
    // DEBT, prefer single thunk for purchase_bill and payroll since both are just staging models

    let chosenThunk;
    if (status === PAYMENT_STATUSES.inDraft) {
      chosenThunk = fetchPurchaseBills;
    } else if (Object.values(PAYMENT_APPROVAL_STATUSES).includes(status)) {
      chosenThunk =
        context === BILL_PAYROLL_CONTEXT.BILLPAY
          ? fetchPaymentApprovals
          : fetchPayrollApprovals;
    } else {
      chosenThunk = fetchPayments;
    }

    dispatch(
      chosenThunk({
        context,
        value: {
          page: pageNum,
          limit: paginationLimit,
          payroll: context === BILL_PAYROLL_CONTEXT.PAYROLL,
          status,
          ...params,
        },
        key: status,
      })
    );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
  });

  // step pagination - keep one state for current view
  // next-pagintation works indepdendently
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / limit);
  const prevDisabled = currentPage === 1;
  const nextDisabled = isFetching || currentPage === totalPages;
  const prevPageHandler = () => setCurrentPage((p) => (p > 1 ? p - 1 : p));
  const nextPageHandler = () => {
    if (currentPage === page) {
      // on the last loaded page
      if (hasMore) {
        setCurrentPage((p) => p + 1); // show loader page
        setPageNum((p) => p + 1); // trigger pagination to fetch more
      }
    } else if (currentPage < page) {
      setCurrentPage((p) => p + 1); // move navigation forward, no fetch
    }
    // currentPage > page case is absent, since button is disabled
  };

  // set the page by fronend
  const currentList =
    list?.slice((currentPage - 1) * limit, currentPage * limit) ?? [];

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
    emptyDataTitle,
    emptyDataDescription,
    emptySubHeadingDataTitle,
  } = typeof tableConfig === typeof (() => {}) ? tableConfig() : tableConfig;

  return (
    <div className="mt-2">
      <Table
        {...{
          ...tableSetting,
          emptyDataTitle,
          emptyDataDescription: emptySubHeadingDataTitle,
        }}
      >
        <tr className={tableHeadingRowClasses}>
          {tableHeading.map((headVal, index) => (
            <th className={headVal.classes} key={headVal.id}>
              <HeaderCell
                val={{
                  ...headVal,
                  showCheckbox: false,
                  disabled: isEmpty,
                }}
              />
            </th>
          ))}
        </tr>

        {(!isFetching || !isEmpty) &&
          currentList?.map((val, index, arr) => (
            <tr
              key={val.id}
              className={`${tableDataRowClasses} p-1`}
              onClick={() => {
                openRelevantPaymentSlider(val, inPayrollContext, {
                  searchParams,
                  setSearchParams,
                });
              }}
            >
              {tableHeading.map(({ cellComponent: Component, classes, id }) => (
                <td className={classes} key={id}>
                  <Component
                    val={{
                      ...val,
                      context,
                      checkedValue: val.selected,
                    }}
                    key={id}
                  />
                </td>
              ))}
            </tr>
          ))}
        {isFetching && <Loader paginationLimit={paginationLimit} />}
      </Table>

      {isEmpty ? null : (
        <StepPaginationButtons
          currentPage={currentPage}
          total={total}
          limit={paginationLimit}
          handleLeftButton={prevPageHandler}
          handleRightButton={nextPageHandler}
          leftDisabled={prevDisabled}
          rightDisabled={nextDisabled}
        />
      )}
    </div>
  );
}

// Loader for current file
function Loader({ paginationLimit }) {
  return Array(paginationLimit)
    .fill(null)
    .map((_, idx) => (
      <tr className="text-center" key={`loader-${idx}`}>
        <td>
          <div className="flex items-center gap-3">
            <LoaderSkeleton type="circle" size={[40, 40]} />
            <LoaderSkeleton size={[20, 140]} />
          </div>
        </td>
        <td className="text-right">
          <LoaderSkeleton size="lg" />
        </td>
        <td>
          <LoaderSkeleton borderRadius="200px" size={[20, 128]} />
        </td>
      </tr>
    ));
}

DeleteEmployeeTable.propTypes = {
  context: PropTypes.string,
  tableConfig: PropTypes.object,
  paginationLimit: PropTypes.number,
  params: PropTypes.object,
  status: PropTypes.string,
};
