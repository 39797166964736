import PropTypes from "prop-types";
import React from "react";

import Note from "../Note";
import Text from "../Text";

export default function NoteLister({ noteList, externalCss, showInfoIcon }) {
  function generateDescriptionOfTypeArray(note) {
    return (
      <div className="flex flex-col">
        {note?.map((text, index) => {
          return (
            <div className="flex flex-row items-center gap-3" key={index}>
              <span className="profile-widget-dot" />
              <Text translationKey={text} classes="text-xs font-medium" />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={externalCss}>
      {noteList?.map((noteConfig, index) => {
        const pointerComponent = generateDescriptionOfTypeArray(
          noteConfig?.list
        );
        const titleText = noteConfig?.titleText;

        return (
          <Note
            titleText={titleText || ""}
            descriptionText={pointerComponent}
            actionText=""
            key={index}
            showIcon={showInfoIcon}
          />
        );
      })}
    </div>
  );
}

NoteLister.propTypes = {
  noteList: PropTypes.array,
  externalCss: PropTypes.string,
  showInfoIcon: PropTypes.bool,
};

// [{titleText:"", list: ["first_note" ,"second_note","third_note"]}]
