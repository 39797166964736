import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  companyAccountBalanceChartDataSelector,
  companyDashboardSpendChartDataSelector,
  companyDashboardSpendsSelector,
  isFetchingCompanyAccountBalanceChartDataSelector,
  isFetchingCompanyDashboardSpendChartData,
} from "@/store/selectors/analytics";
import { selectedWalletAccountSelector } from "@/store/selectors/company";
import { availableModulesSelector } from "@/store/selectors/user";
import { amountToCurrency, dateToString } from "@/utils/common";

import {
  SPEND_CHART_VISIBLE,
  SPEND_FREQUENCY,
  SPEND_TYPES,
  SPEND_TYPE_LABELS,
} from "@/constants/company";

export default function SpendChart({
  spendType,
  duration,
  frequency,
  onAccountPage,
}) {
  const { t } = useTranslation();
  const spendChartData = useSelector(companyDashboardSpendChartDataSelector);
  const availableModule = useSelector(availableModulesSelector);
  const balanceChartData = useSelector(companyAccountBalanceChartDataSelector);
  const getSpendChartVisible = useMemo(
    () => (key) =>
      (spendType === SPEND_TYPES.TOTAL && key === SPEND_TYPES.TOTAL) ||
      (availableModule?.length &&
        availableModule?.includes(SPEND_CHART_VISIBLE[spendType][key])),
    [spendType, JSON.stringify(availableModule)]
  );
  const currency = useSelector(selectedWalletAccountSelector)?.currency;

  const isFetchingChartData = useSelector(
    onAccountPage
      ? isFetchingCompanyAccountBalanceChartDataSelector
      : isFetchingCompanyDashboardSpendChartData
  );

  const xAxisDates =
    (onAccountPage ? balanceChartData?.xaxis : spendChartData?.xaxis) ?? [];

  // declare curve
  const [series, setSeries] = useState([
    {
      name: "Current month",
      data: [],
      type: "area",
    },
  ]);

  const spendValues = useSelector(companyDashboardSpendsSelector);

  const calculateReimbursement = (value) => {
    const outsidePaid =
      value?.reimbursementpaidoutside && value?.reimbursementpaidoutside?.length
        ? value?.reimbursementpaidoutside
        : new Array(value?.reimbursements?.length).fill(0);

    return value?.reimbursements?.map(
      (item, index) => item + (outsidePaid?.[index] ?? 0)
    );
  };

  const calculateBillPay = (value) => {
    const outsidePaid =
      value?.billpaymentpaidoutside && value?.billpaymentpaidoutside?.length
        ? value?.reimbursementpaidoutside
        : new Array(value?.reimbursements?.length).fill(0);

    return value?.billpay?.map(
      (item, index) => item + (outsidePaid?.[index] ?? 0)
    );
  };

  const getDataBasedOnSpentType = (_spendType, data) => {
    switch (_spendType) {
      case SPEND_TYPES.BILL_PAY:
        return calculateBillPay(data);
      case SPEND_TYPES.REIMBURSEMENT:
        return calculateReimbursement(data);
      default:
        return data?.[spendType];
    }
  };
  // declare curve-onHover tooltip
  const tooltipEl = ({ dataPointIndex, w }) => {
    if (onAccountPage) {
      let monthLabel = w.globals.categoryLabels[dataPointIndex];
      monthLabel =
        monthLabel[0].toUpperCase() + monthLabel.toLowerCase().slice(1);

      const balanceAmount = balanceChartData?.billPayBalances?.[dataPointIndex];

      return `
        <div class="rounded-lg py-3 pr-6 pl-4 bg-neutral-700 box-content">
        <div id="total">
          <div class="text-neutral-300 font-medium font-xs">${monthLabel}</div>
          <span class="text-white font-bold font-sm">${amountToCurrency(
            balanceAmount,
            currency
          )}</span>
        </div>
      </div>
      `;
    }

    return `
    <div class="rounded-lg py-3 pr-10 pl-4 bg-neutral-700">
    ${
      getSpendChartVisible(SPEND_TYPES.TOTAL)
        ? `<div id="total">
            <div class="text-neutral-300 font-medium font-xs">
              ${t(SPEND_TYPE_LABELS[SPEND_TYPES.TOTAL])}
            </div>
            <div class="text-white font-bold font-sm">
              ${amountToCurrency(
                spendChartData?.[SPEND_TYPES.TOTAL]?.[dataPointIndex] ?? 0,
                currency
              )}
            </div>
          </div>`
        : ""
    }

        ${
          getSpendChartVisible(SPEND_TYPES.CARDS)
            ? `<div class="flex flex-col gap-1
              ${!getSpendChartVisible(SPEND_TYPES.CARDS) ? "mt-3" : ""}
                ">
                  <div class="flex gap-1">
                    <div class="bg-corporate-cards-500 rounded-[20px] w-[3px] my-1"></div>
                  <div>
                  <div class="text-neutral-300 font-medium font-xs">
                  ${t(SPEND_TYPE_LABELS[SPEND_TYPES.CARDS])}
                  </div>
                  <div class="text-white font-bold font-sm">
                    ${amountToCurrency(
                      spendChartData?.[SPEND_TYPES.CARDS]?.[dataPointIndex] ??
                        0,
                      currency
                    )}
                  </div>
                </div>
         </div>`
            : ""
        }


        ${
          getSpendChartVisible(SPEND_TYPES.BILL_PAY)
            ? `<div class="flex gap-1">
                <div class="bg-accounts-payable-500 rounded-[20px] w-[3px] my-1"></div>
               <div>
                <div class="text-neutral-300 font-medium font-xs">${t(
                  SPEND_TYPE_LABELS[SPEND_TYPES.BILL_PAY]
                )}</div>
                <div class="text-white font-bold font-sm">
                ${amountToCurrency(
                  calculateBillPay(spendChartData)?.[dataPointIndex] ?? 0,
                  currency
                )}
                </div>
                </div>
              </div>`
            : ""
        }


        ${
          getSpendChartVisible(SPEND_TYPES.REIMBURSEMENT)
            ? `<div class="flex gap-1">
                <div class="bg-expense-management-500 rounded-[20px] w-[3px] my-1"></div><div>
                <div class="text-neutral-300 font-medium font-xs">${t(
                  SPEND_TYPE_LABELS[SPEND_TYPES.REIMBURSEMENT]
                )}</div>
                <div class="text-white font-bold font-sm">${amountToCurrency(
                  calculateReimbursement(spendChartData)?.[dataPointIndex] ?? 0,
                  currency
                )}</div>
              </div>
            </div>
          </div>`
            : ""
        }

      </div>
  `;
  };

  // configure chart looks - DONE (except dotted lines at the first horizontal line)
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "area",
      toolbar: {
        show: false,
        autoSelected: "selection",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "smooth",
    },
    colors: ["#4B4EFC", "#E5E6EA"], // text-primary-500, text-neutral-200
    fill: {
      type: "gradient",
      gradient: {
        gradientToColors: ["#676CFF", "#C3C4FE", "#F5F5FF"],
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.2,
        stops: [0, 60, 100],
      },
    },
    xaxis: {
      type: "datetime",
      categories: [],
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      borderColor: "#E5E6EA",
      width: 10,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    marker: false,
  });

  const [refreshKey, setRefreshKey] = useState(1);

  const setupGraph = async () => {
    const calculateData = () => {
      return onAccountPage
        ? balanceChartData?.billPayBalances
        : getDataBasedOnSpentType(spendType, spendChartData);
    };
    // Added 01 format so firefox doesn't throw error as invalid Date while conversion. // TODO: does this matter now?
    setSeries([
      {
        name: "Current month",
        data: calculateData(),

        type: "area",
      },
    ]);

    setChartOptions((options) => ({
      ...options,
      tooltip: {
        custom: tooltipEl,
        x: {
          format: "dd/MM/yy",
        },
        y: {
          marker: false,
          formatter(value, { seriesIndex, dataPointIndex, w }) {
            return value;
          },

          title: {
            marker: false,
            formatter() {
              return "";
            },
          },
        },
      },
      ...{
        xaxis: {
          labels: {
            style: {
              colors: "#6B7281",
              fontSize: "14px",
              fontFamily: "Manrope, Helvetica, Arial, sans-serif",
              fontWeight: 600,
              lineHeight: "24px",
            },
          },
          categories: xAxisDates
            ? xAxisDates?.map((date, index) => {
                const [day, month, year] = dateToString(
                  date,
                  (options = { year: "numeric" })
                ).split(" ");

                switch (frequency) {
                  case SPEND_FREQUENCY.DAILY:
                    return `${day} ${month}`;
                  case SPEND_FREQUENCY.MONTHLY:
                    return month; // removing upcase has css issue on rendering sept on all time
                  case SPEND_FREQUENCY.WEEKLY:
                    return `${day} ${month}`;
                  case SPEND_FREQUENCY.YEARLY:
                    return `${year}`;
                  default:
                    return "";
                }
              })
            : [],
          axisBorder: {
            show: true,
            color: "#E5E6EA",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: (num) => {
              if (num < 1e3) return num;
              if (num < 1e6) return `${(num / 1e3).toLocaleString()}k`;
              if (num < 1e9) return `${(num / 1e6).toLocaleString()}M`;
              return `${(num / 1e6).toLocaleString()}B`;
            },
            style: {
              colors: ["#6B7281"],
              fontSize: "14px",
              fontFamily: "Manrope, Helvetica, Arial, sans-serif",
              fontWeight: 600,
              lineHeight: "24px",
            },
          },
          axisBorder: {
            show: true,
            color: "#E5E6EA",
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    }));

    refreshChart();
  };

  const refreshChart = () => {
    setRefreshKey(refreshKey + 1);
  };

  useEffect(() => {
    setupGraph();
  }, [isFetchingChartData, duration, frequency, spendType]);

  return (
    <Chart
      options={chartOptions}
      height="500"
      series={series}
      key={refreshKey}
    />
  );
}

SpendChart.propTypes = {
  spendType: PropTypes.string,
  duration: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  ]),
  frequency: PropTypes.string,
  onAccountPage: PropTypes.bool,
};
