import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCountries } from "@/store/reducers/app";

import {
  countriesSelector,
  isFetchingCountriesSelector,
} from "@/store/selectors/app";
import {
  countriesForMailSelector,
  isFetchingCountriesForMailSelector,
} from "@/store/selectors/vendors";

import VpSelect from "@/components/core/VpSelect";
import { MAIL_CONTEXT } from "@/utils/constants/common";

import { VENDOR_CREATE_REQUIREMENTS_PARAMS } from "@/constants/vendors";

export default function BeneficiaryCountry({
  name = VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY,
  values,
  errors,
  handleChange,
  relevantValues = [],
  setValues,
  isEditMode,
  disabled,
  context,
}) {
  const dispatch = useDispatch();
  const appCountries = useSelector((state) =>
    context === MAIL_CONTEXT
      ? countriesForMailSelector(state)
      : countriesSelector(state)
  );
  const isFetchingCountries = useSelector(isFetchingCountriesSelector);
  const isFetchingCountriesForMail = useSelector(
    isFetchingCountriesForMailSelector
  );
  const isFetching = isFetchingCountries || isFetchingCountriesForMail;

  const countryOptions = useMemo(
    () =>
      appCountries
        ?.map((country) => ({
          name: country.name,
          value: country.iso2Code,
        }))
        .filter((item) => relevantValues.includes(item.value)),
    [appCountries, relevantValues]
  ); // common of (all, available vendor countries)

  // auto select if only one thing is relevant
  // delete if there's no relevant value
  useEffect(() => {
    if (relevantValues.length <= 1) {
      setValues?.((prev) => ({ ...prev, [name]: relevantValues.at(0) ?? "" }));
    }
  }, [values[name]]);

  useEffect(() => {
    if (!context) return;

    if (context !== MAIL_CONTEXT)
      // mail context is handled in its own component
      dispatch(fetchCountries());
  }, [context]);

  return (
    <VpSelect
      name={name}
      value={values[name]}
      error={errors[name]}
      handleChange={handleChange}
      insideForm
      menuPosition="absolute"
      options={countryOptions}
      optionsDisplayKey="name"
      valueKey="value"
      label="billPay.vendors.createVendor.beneficiaryCountryLabel"
      labelStyleClasses="text-neutral-600 text-xs font-semibold"
      placeholder="billPay.vendors.createVendor.beneficiaryCountryPlaceholder"
      helperText="billPay.vendors.createVendor.beneficiaryCountryHelperText"
      clearable
      isOptionsLoading={isFetching}
      disabled={!!isEditMode || disabled}
    />
  );
}

BeneficiaryCountry.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  relevantValues: PropTypes.array,
  isEditMode: PropTypes.bool,
  disabled: PropTypes.bool,
  context: PropTypes.string,
  setValues: PropTypes.func, // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough)
};
