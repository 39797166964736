import PropsTypes from "prop-types";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function RolesPermissionsLoader() {
  return [...new Array(5)].map((index) => (
    <tr className="text-center flex items-center gap-3" key={index}>
      <td className="text-left">
        <LoaderSkeleton type="circle" />
      </td>
      <td className="text-left">
        <LoaderSkeleton />
      </td>
    </tr>
  ));
}

RolesPermissionsLoader.propTypes = {
  type: PropsTypes.string,
};
