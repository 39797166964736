import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  deleteExports,
  fetchExports,
  resetExportsStoreState,
  retryExports,
} from "@/store/reducers/exports";

import {
  exportFiltersSelector,
  exportIsFetchingSelector,
  exportsHasMoreSelector,
  exportsSelector,
} from "@/store/selectors/exports";
import { appliedFilterSelector } from "@/store/selectors/filters";

import Filters from "@/components/core/Filters";
import Icon from "@/components/core/Icon";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import { convertFilters } from "@/utils/filters";
import {
  capitalizeFirstLetter,
  dateToString,
  dateToTimeStampString,
  debounce,
} from "@/utils/common";

import {
  EXPORTS_EMAIL_STATUS_CONFIG,
  EXPORT_EMAIL_STATUS,
  EXPORT_STATUS,
} from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import ExportTableLoader from "./ExportTableLoader";

const EXPORTS_CONFIG = {
  [EXPORT_STATUS.ready]: {
    chipClasses: "bg-success-50 text-success-600 border-success-200",
    chipText: "Ready",
    actionIcon: <Icon name="Download" className="w-4 h-4" />,
  },
  [EXPORT_STATUS.processing]: {
    chipClasses: "bg-warning-50 text-warning-600 border-warning-200",
    chipIcon: <Icon name="WarningCircle" className="w-2.5 h-2.5" />,
    chipText: "Processing",
    actionIcon: (
      <Icon
        name="Download"
        className="w-4 h-4 text-neutral-300 cursor-not-allowed"
      />
    ),
    deleteDisabled: true,
  },
  [EXPORT_STATUS.initiated]: {
    chipClasses: "bg-warning-50 text-warning-600 border-warning-200",
    chipIcon: <Icon name="WarningCircle" className="w-2.5 h-2.5" />,
    chipText: "Processing",
    actionIcon: (
      <Icon
        name="Download"
        className="w-4 h-4 text-neutral-300 cursor-not-allowed"
      />
    ),
    deleteDisabled: true,
  },
  [EXPORT_STATUS.failed]: {
    chipClasses: "bg-danger-50 text-danger-600 border-danger-200",
    chipText: "Failed",
    actionIcon: <Icon name="Sync" className="w-4 h-4" />,
  },
};

export default function ExportTable() {
  const dispatch = useDispatch();

  const isFetching = useSelector(exportIsFetchingSelector);
  const hasMore = useSelector(exportsHasMoreSelector);
  const exports = useSelector(exportsSelector);
  const [searchText, setSearchText] = useState("");
  const [selectedExports, setSelectedExports] = useState([]);
  const filters = useSelector(exportFiltersSelector);
  const appliedFilters = useSelector(appliedFilterSelector);

  const searchHandler = debounce((newSearchText) => {
    setSearchText(newSearchText);
  }, 500);

  const onReset = () => {
    dispatch(resetExportsStoreState());
  };

  const loadMore = () => {
    dispatch(
      fetchExports({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...convertFilters(appliedFilters),
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...convertFilters(appliedFilters),
    },
  });

  const handleRefChange = useInfiniteScroll(() => {
    setPageNum((prev) => prev + 1);
  });

  const handleClick = (key, options) => {
    switch (key) {
      case EXPORT_STATUS.ready: {
        const link = document.createElement("a");
        link.href = options?.url;
        link.setAttribute("download", options?.fileName);
        document.body.appendChild(link);
        link.click();
        break;
      }
      case EXPORT_STATUS.failed: {
        dispatch(
          retryExports({
            id: options?.id,
            onSuccess: () => {
              dispatch(fetchExports());
            },
          })
        );
        break;
      }
      default:
        break;
    }
  };
  return (
    <>
      <div className="flex items-center gap-3 ">
        <div className="w-full text-sm">
          <Filters filters={filters} />
        </div>
      </div>

      <div className="mt-8">
        <Table
          headerSticky
          colWidths={[400, 200, 200, 200, 120]}
          rightColWidths={[150]}
          numberOfStickyColsRight={1}
          emptyDataTitle="exports.emptyState"
        >
          <tr className="text-xs text-center text-neutral-800 bg-neutral-50 ">
            <th className="font-semibold">
              <div className="flex gap-2 ">
                <Text translationKey="exports.fileName" />
              </div>
            </th>
            <th className="font-semibold text-left">
              <Text translationKey="exports.date" />
            </th>
            <th className="font-semibold text-left">
              <Text translationKey="exports.fileType" />
            </th>
            <th className="font-semibold text-left">
              <Text translationKey="exports.status" />
            </th>
            <th className="font-semibold text-center">
              <Text translationKey="exports.action" />
            </th>
          </tr>
          {exports?.length
            ? exports?.map((data, index, arr) => (
                <tr
                  ref={(itemRef) => {
                    if (hasMore && index === arr.length - 1 && !isFetching)
                      handleRefChange(itemRef);
                  }}
                  className={`cursor-pointer text-neutral-800 font-medium text-sm ${
                    selectedExports?.includes(index) ? "selected-row-cell" : ""
                  }`}
                  key={`exports-${index}`}
                >
                  <td>
                    <div className="flex items-center gap-2 text-neutral-800">
                      <div className="flex flex-col w-full">
                        <div className="text-sm font-semibold text-neutral-800 w-full">
                          <Text
                            id={`export-name-${data?.id}`}
                            toolTipDirection="right"
                            showTooltip
                            translationKey={data?.fileName}
                            classes="w-full inline-block truncate"
                          />
                        </div>
                        <div className="flex text-xs font-medium text-neutral-500">
                          <div>
                            {capitalizeFirstLetter(
                              data?.fileType?.replaceAll("_", " ")
                            )}
                          </div>
                          {!data.emailStatus ? (
                            ""
                          ) : (
                            <>
                              <span>•</span>
                              <div
                                className={
                                  EXPORTS_EMAIL_STATUS_CONFIG[
                                    EXPORT_EMAIL_STATUS[data?.emailStatus]
                                  ]?.textClasses
                                }
                              >
                                {
                                  EXPORTS_EMAIL_STATUS_CONFIG[
                                    EXPORT_EMAIL_STATUS[data?.emailStatus]
                                  ]?.text
                                }
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-col ">
                      <div className="text-sm font-semibold text-neutral-800">
                        {dateToString(data?.createdAt)}
                      </div>
                      <div className="text-xs font-medium text-neutral-500">
                        {dateToTimeStampString(data?.createdAt, false, {
                          hour: "numeric",
                          minute: "numeric",
                          replace: true,
                        })}
                      </div>
                    </div>
                  </td>
                  <td>{data?.downloadType}</td>
                  <td>
                    <div
                      className={`${
                        EXPORTS_CONFIG[EXPORT_STATUS[data?.status]]?.chipClasses
                      } rounded-full border w-14 text-xs font-bold gap-1 flex justify-center items-center`}
                    >
                      {EXPORTS_CONFIG[EXPORT_STATUS[data?.status]]?.chipIcon}
                      {EXPORTS_CONFIG[EXPORT_STATUS[data?.status]]?.chipText}
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-center gap-6 text-neutral-500">
                      <div
                        onClick={() =>
                          handleClick(EXPORT_STATUS[data.status], {
                            id: data.id,
                            url: data.downloadableFile.url,
                            fileName: data.fileName,
                            format: data.downloadableFile.contentType,
                          })
                        }
                      >
                        {EXPORTS_CONFIG[EXPORT_STATUS[data.status]]?.actionIcon}
                      </div>
                      <Icon
                        name="Delete"
                        className={`${
                          EXPORTS_CONFIG[EXPORT_STATUS[data.status]]
                            ?.deleteDisabled
                            ? "text-neutral-300 cursor-not-allowed"
                            : ""
                        } w-4 h-4`}
                        handleClick={() => {
                          if (
                            !EXPORTS_CONFIG[EXPORT_STATUS[data.status]]
                              ?.deleteDisabled
                          ) {
                            dispatch(
                              deleteExports({
                                id: data.id,
                                onSuccess: () => {
                                  dispatch(fetchExports());
                                },
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))
            : null}
          {isFetching ? <ExportTableLoader /> : null}
        </Table>
      </div>
    </>
  );
}
