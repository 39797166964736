import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { toggleModal } from "@/store/reducers/onboarding";

import {
  currentOnboardingStepSelector,
  onboardingModalBoolSelector,
} from "@/store/selectors/onboarding";

import Modal from "@/components/core/Modal";

import { ONBOARDING_STEPS } from "@/constants/onboarding";

import VideoKycProcessModal from "./VideoKycProcess";

export default function OnboardingModal({
  handleVideoKycForLq,
  getToDashboard,
}) {
  const dispatch = useDispatch();
  const onboardingModalBool = useSelector(onboardingModalBoolSelector);

  const currentOnboardingStep = useSelector(currentOnboardingStepSelector);

  const RenderComponent = getChildComponent();

  function toggleOnboardingModalBool() {
    dispatch(toggleModal());
  }

  function getChildComponent() {
    if (onboardingModalBool) {
      switch (currentOnboardingStep) {
        case ONBOARDING_STEPS.VIDEO_KYC_PROCESS:
          return (
            <VideoKycProcessModal
              handleVideoKycForLq={handleVideoKycForLq}
              getToDashboard={getToDashboard}
            />
          );

        default:
          return null;
      }
    }
  }

  return (
    <Modal
      open={onboardingModalBool}
      onClose={toggleOnboardingModalBool}
      innerContainerClasses="w-126"
    >
      {RenderComponent}
    </Modal>
  );
}

OnboardingModal.propTypes = {
  handleVideoKycForLq: PropTypes.func,
  getToDashboard: PropTypes.func,
};
