import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import BalanceCard from "@/components/Company/Overview/MyAccounts/shared/BalanceCard";
import Tooltip from "@/components/core/Tooltip";
import { amountToCurrency } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";

export default function PayrollAccountWidget({ payroll }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between" id="upper">
        <BalanceCard
          iconName="Payments"
          iconClasses="text-primary-500 bg-primary-50"
          iconBgClasses="text-primary-500 bg-primary-50"
          label={
            <span>
              {payroll?.currency} <Text translationKey="common.balance" />
            </span>
          }
          amount={payroll.balance}
          currency={payroll.currency}
        />
        <div id="right">
          <div
            className="min-w-[100px] flex flex-col gap-2 items-center hover:cursor-pointer select-none"
            onClick={() => {
              setSearchParams({
                [SLIDERS_SEARCH_PARAMS.company.allocate]: FORM_TYPE.edit,
                [SLIDERS_SEARCH_PARAMS.company.currency]: payroll?.currency,
                sendingToAccountKey: payroll?.accountType,
              });
            }}
          >
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary-50">
              <Icon name="Plus" className="text-primary-600 " />
            </div>
            <Text
              translationKey="company.allocateFunds.title"
              classes="text-xs font-semibold text-neutral-500"
            />
          </div>
        </div>
      </div>
      <div
        id="lower"
        className="flex flex-col gap-2 p-4 rounded-md bg-neutral-50"
      >
        {[
          {
            id: "payroll-lineitem-0",
            icon: "Send",
            label: "dashboard.myAccounts.processingPayments",
            infoText: "dashboard.myAccounts.infoText.payments",
            value: amountToCurrency(
              (payroll?.balance ?? 0) - (payroll?.availableBalance ?? 0),
              payroll?.currency
            ),
          },
        ].map(({ icon, label, value, infoText, id }) => (
          <div className="flex items-center justify-between" key={label}>
            <div className="flex items-center gap-2">
              <Icon name={icon} className="w-3 text-neutral-500" />
              <Text
                translationKey={label}
                classes="text-neutral-500 text-sm font-medium"
              />
              <div id={id} className="-ml-1">
                <Icon
                  name="Info"
                  className="text-neutral-300 p-[1.33px] h-4 w-4 hover:cursor-pointer"
                />
                <Tooltip id={id} direction="right">
                  <Text
                    translationKey={infoText}
                    classes="text-sm font-medium text-neutral-500"
                  />
                </Tooltip>
              </div>
            </div>
            <span className="text-sm font-semibold text-neutral-800">
              {value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

PayrollAccountWidget.propTypes = {
  payroll: PropTypes.object,
};
