import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchRoles } from "@/store/reducers/company";
import {
  setReimbursementAutomatedPaymentToggleSwitch,
  updateReimbursementSetting,
} from "@/store/reducers/reimbursement";

import { cardsClaimSettlementEnabledSelector } from "@/store/selectors/client";
import {
  reimbursementAutoClaimSettlementToCardEnabledSelector,
  reimbursementAutomatedPaymentToggleSwitchSelector,
  reimbursementAutopayEnabledSelector,
  reimbusementSettingExtraKeys,
} from "@/store/selectors/reimbursement";

import SettingsList from "@/components/common/BillPayAndPayroll/Settings/common/SettingsList";
import vToast from "@/utils/vToast";
import { REIMBURSEMENT_SETTING_ENTRIES } from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { REIMBURSEMENT_SETTINGS } from "@/constants/reimbursement";

export default function ReimbursementSettingsGeneral() {
  const reimbursementAutoPayEnabled = useSelector(
    reimbursementAutopayEnabledSelector
  );

  const reimbursementAutopayToggleSwitch = useSelector(
    reimbursementAutomatedPaymentToggleSwitchSelector
  );

  const autoClaimSettlementState = useSelector(
    reimbursementAutoClaimSettlementToCardEnabledSelector
  );

  const isShowPayViaCard = useSelector(cardsClaimSettlementEnabledSelector); // consolidated customization flag

  const isLoadingAutoClaimSettlment = useSelector(
    (state) =>
      reimbusementSettingExtraKeys(state)[
        REIMBURSEMENT_SETTINGS.AUTO_CLAIM_SETTLEMENT_TO_CARD_ENABLED
      ]
  ); // API operation happening

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const handleAutoPaymentToggle = (val) => {
    if (!val) {
      dispatch(
        updateReimbursementSetting({
          payload: {
            [REIMBURSEMENT_SETTINGS.REIMBURSEMENT_AUTOPAY_ENABLED]: false,
          },
          onSuccess: () => {
            vToast({
              description:
                "payroll.payrollSettings.payrollSliders.automatedPayments.successDisableToast",
              variant: "success",
            });
          },
        })
      );
      dispatch(setReimbursementAutomatedPaymentToggleSwitch(val));
    } else {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.reimbursements.settings.general
          .reimbursementsAutomatedPayments,
        true
      );
      setSearchParams(searchParams);
      dispatch(setReimbursementAutomatedPaymentToggleSwitch(val));
    }
  };
  const handleAutoClaimSettlementToggle = (val) => {
    dispatch(
      updateReimbursementSetting({
        payload: {
          // TECH_DEBT store in enum
          [REIMBURSEMENT_SETTINGS.AUTO_CLAIM_SETTLEMENT_TO_CARD_ENABLED]: val,
        },
        isHandleIndividualLoading: true,
        onSuccess: () => {
          vToast({
            description: val
              ? "reimbursement.createPayment.autoClaimSettleToCardEnabled"
              : "reimbursement.createPayment.autoClaimSettleToCardDisabled",
            variant: "success",
          });
        },
      })
    );
  };
  useEffect(() => {
    if (reimbursementAutoPayEnabled)
      dispatch(
        setReimbursementAutomatedPaymentToggleSwitch(
          reimbursementAutoPayEnabled
        )
      );
  }, [reimbursementAutoPayEnabled, reimbursementAutopayToggleSwitch]);

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);

  const ReimbursementGeneralTabContent = [
    {
      id: "automated-payments",
      title: "reimbursement.settings.rows.automatedPayments.title",
      description: "reimbursement.settings.rows.automatedPayments.description",
      rightChildren: [
        {
          id: REIMBURSEMENT_SETTING_ENTRIES.AUTOMATED_PAYMENTS,
          title:
            "reimbursement.settings.rows.automatedPayments.rightSide.autoPayment.title",
          description:
            "reimbursement.settings.rows.automatedPayments.rightSide.autoPayment.description",

          showSwitch: true,
          isSwitchLoading: isLoadingAutoClaimSettlment,
          isSwitchOn: reimbursementAutopayToggleSwitch,

          sliderType:
            SLIDERS_SEARCH_PARAMS.reimbursements.settings.general
              .reimbursementsAutomatedPayments,
          sliderId: null,
          handleChange: handleAutoPaymentToggle,
        },
        isShowPayViaCard
          ? {
              id: REIMBURSEMENT_SETTING_ENTRIES.AUTO_SETTLEMENT,
              title:
                "reimbursement.settings.rows.automatedPayments.rightSide.autoClaimSettlement.title",
              description:
                "reimbursement.settings.rows.automatedPayments.rightSide.autoClaimSettlement.description",

              showSwitch: true,
              isSwitchOn: autoClaimSettlementState,

              sliderType: null,
              sliderId: null,
              handleChange: handleAutoClaimSettlementToggle,
            }
          : null,
      ].filter(Boolean),
    },
    {
      id: "payment-initiators-section",
      title: "reimbursement.settings.rows.paymentInitiators.title",
      description: "reimbursement.settings.rows.paymentInitiators.description",
      rightChildren: [
        {
          id: REIMBURSEMENT_SETTING_ENTRIES.PAYMENT_INITIATION,
          title:
            "reimbursement.settings.rows.paymentInitiators.rightSide.addPaymentInitiators.title",
          description:
            "reimbursement.settings.rows.paymentInitiators.rightSide.addPaymentInitiators.description",

          showSwitch: false,
          isSwitchOn: false,

          sliderType:
            SLIDERS_SEARCH_PARAMS.reimbursements.settings.general
              .paymentInitiators,
          sliderId: null,
        },
      ],
    },
    {
      id: "mileage-rate",
      title: "reimbursement.settings.rows.mileageRate.title",
      description: "reimbursement.settings.rows.mileageRate.description",
      rightChildren: [
        {
          id: REIMBURSEMENT_SETTING_ENTRIES.MILEAGE_RATE,
          title:
            "reimbursement.settings.rows.mileageRate.rightSide.setMileageRate.title",
          description:
            "reimbursement.settings.rows.mileageRate.rightSide.setMileageRate.description",

          showSwitch: false,
          isSwitchOn: false,

          sliderType:
            SLIDERS_SEARCH_PARAMS.reimbursements.settings.general.mileageRate
              .title,
          sliderId: "preview",
        },
      ],
    },
  ];

  return (
    <div>
      <SettingsList tabRows={ReimbursementGeneralTabContent} />
    </div>
  );
}
