export const REGEX_VALUE = {
  password: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  integer: {
    negative: /^-\d+$/,
    positive: /^\d+$/,
    all: /^-?\d+$/,
  },
  number: {
    all: /^-?\d*\.{0,1}\d+$/,
    negative: /^-\d*\.?\d+$/,
    positive: /^\d*\.?\d+$/,
  },
  alphaSpaceHyphen: /^[a-zA-Z\- ]+$/,
  alphaNumericSpaceHyphen: /^[a-zA-Z0-9\- ]+$/,
  zipcode: /^\d{5}([-]|s*)?(\d{4})?$/,
};
// using this so we'll only check strenth for new password
export const REST_PASSWORD_INPUT_NAME = {
  currentPassword: "currentPassword",
  newPassword: "newPassword",
  confirmPassword: "confirmPassword",
};
export const USE_FORM_TYPE_OF_VALIDATION = {
  regex: "regex",
  number: "number",
  positive: "positive",
  negative: "negative",
  string: "string",
  text: "text",
  max: "max",
  min: "min",
  minDecimal: "minDecimal",
  maxDecimal: "maxDecimal",
  minNumber: "minNumber", // (minNumber, max]
  maxNumber: "maxNumber", // (minNumber, max]
  oneOf: "oneOf",
  compare: "compare",
  onOrAfterDate: "onOrAfterDate",
  onOrBeforeDate: "onOrBeforeDate",
  afterDate: "afterDate",
  beforeDate: "beforeDate",
  dateBetween: "dateBetween", // inclusive on both side,
  phoneNumber: "phoneNumber",
  email: "email",
};

export const COUNTRY_VS_DIAL_CODE = {
  "+93": {
    name: "Afghanistan",
    dialCode: "+93",
    countryCode: "AF",
  },
  "+358": {
    name: "Finland",
    dialCode: "+358",
    countryCode: "FI",
  },
  "+355": {
    name: "Albania",
    dialCode: "+355",
    countryCode: "AL",
  },
  "+213": {
    name: "Algeria",
    dialCode: "+213",
    countryCode: "DZ",
  },
  "+1684": {
    name: "AmericanSamoa",
    dialCode: "+1684",
    countryCode: "AS",
  },
  "+376": {
    name: "Andorra",
    dialCode: "+376",
    countryCode: "AD",
  },
  "+244": {
    name: "Angola",
    dialCode: "+244",
    countryCode: "AO",
  },
  "+1264": {
    name: "Anguilla",
    dialCode: "+1264",
    countryCode: "AI",
  },
  "+672": {
    name: "Norfolk Island",
    dialCode: "+672",
    countryCode: "NF",
  },
  "+1268": {
    name: "Antigua and Barbuda",
    dialCode: "+1268",
    countryCode: "AG",
  },
  "+54": {
    name: "Argentina",
    dialCode: "+54",
    countryCode: "AR",
  },
  "+374": {
    name: "Armenia",
    dialCode: "+374",
    countryCode: "AM",
  },
  "+297": {
    name: "Aruba",
    dialCode: "+297",
    countryCode: "AW",
  },
  "+61": {
    name: "Cocos (Keeling) Islands",
    dialCode: "+61",
    countryCode: "CC",
  },
  "+43": {
    name: "Austria",
    dialCode: "+43",
    countryCode: "AT",
  },
  "+994": {
    name: "Azerbaijan",
    dialCode: "+994",
    countryCode: "AZ",
  },
  "+1242": {
    name: "Bahamas",
    dialCode: "+1242",
    countryCode: "BS",
  },
  "+973": {
    name: "Bahrain",
    dialCode: "+973",
    countryCode: "BH",
  },
  "+880": {
    name: "Bangladesh",
    dialCode: "+880",
    countryCode: "BD",
  },
  "+1246": {
    name: "Barbados",
    dialCode: "+1246",
    countryCode: "BB",
  },
  "+375": {
    name: "Belarus",
    dialCode: "+375",
    countryCode: "BY",
  },
  "+32": {
    name: "Belgium",
    dialCode: "+32",
    countryCode: "BE",
  },
  "+501": {
    name: "Belize",
    dialCode: "+501",
    countryCode: "BZ",
  },
  "+229": {
    name: "Benin",
    dialCode: "+229",
    countryCode: "BJ",
  },
  "+1441": {
    name: "Bermuda",
    dialCode: "+1441",
    countryCode: "BM",
  },
  "+975": {
    name: "Bhutan",
    dialCode: "+975",
    countryCode: "BT",
  },
  "+591": {
    name: "Bolivia, Plurinational State of",
    dialCode: "+591",
    countryCode: "BO",
  },
  "+387": {
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    countryCode: "BA",
  },
  "+267": {
    name: "Botswana",
    dialCode: "+267",
    countryCode: "BW",
  },
  "+55": {
    name: "Brazil",
    dialCode: "+55",
    countryCode: "BR",
  },
  "+246": {
    name: "British Indian Ocean Territory",
    dialCode: "+246",
    countryCode: "IO",
  },
  "+673": {
    name: "Brunei Darussalam",
    dialCode: "+673",
    countryCode: "BN",
  },
  "+359": {
    name: "Bulgaria",
    dialCode: "+359",
    countryCode: "BG",
  },
  "+226": {
    name: "Burkina Faso",
    dialCode: "+226",
    countryCode: "BF",
  },
  "+257": {
    name: "Burundi",
    dialCode: "+257",
    countryCode: "BI",
  },
  "+855": {
    name: "Cambodia",
    dialCode: "+855",
    countryCode: "KH",
  },
  "+237": {
    name: "Cameroon",
    dialCode: "+237",
    countryCode: "CM",
  },
  "+1": {
    name: "United States",
    dialCode: "+1",
    countryCode: "US",
  },
  "+238": {
    name: "Cape Verde",
    dialCode: "+238",
    countryCode: "CV",
  },
  "+ 345": {
    name: "Cayman Islands",
    dialCode: "+ 345",
    countryCode: "KY",
  },
  "+236": {
    name: "Central African Republic",
    dialCode: "+236",
    countryCode: "CF",
  },
  "+235": {
    name: "Chad",
    dialCode: "+235",
    countryCode: "TD",
  },
  "+56": {
    name: "Chile",
    dialCode: "+56",
    countryCode: "CL",
  },
  "+86": {
    name: "China",
    dialCode: "+86",
    countryCode: "CN",
  },
  "+57": {
    name: "Colombia",
    dialCode: "+57",
    countryCode: "CO",
  },
  "+269": {
    name: "Comoros",
    dialCode: "+269",
    countryCode: "KM",
  },
  "+242": {
    name: "Congo",
    dialCode: "+242",
    countryCode: "CG",
  },
  "+243": {
    name: "Congo, The Democratic Republic of the Congo",
    dialCode: "+243",
    countryCode: "CD",
  },
  "+682": {
    name: "Cook Islands",
    dialCode: "+682",
    countryCode: "CK",
  },
  "+506": {
    name: "Costa Rica",
    dialCode: "+506",
    countryCode: "CR",
  },
  "+225": {
    name: "Cote d'Ivoire",
    dialCode: "+225",
    countryCode: "CI",
  },
  "+385": {
    name: "Croatia",
    dialCode: "+385",
    countryCode: "HR",
  },
  "+53": {
    name: "Cuba",
    dialCode: "+53",
    countryCode: "CU",
  },
  "+357": {
    name: "Cyprus",
    dialCode: "+357",
    countryCode: "CY",
  },
  "+420": {
    name: "Czech Republic",
    dialCode: "+420",
    countryCode: "CZ",
  },
  "+45": {
    name: "Denmark",
    dialCode: "+45",
    countryCode: "DK",
  },
  "+253": {
    name: "Djibouti",
    dialCode: "+253",
    countryCode: "DJ",
  },
  "+1767": {
    name: "Dominica",
    dialCode: "+1767",
    countryCode: "DM",
  },
  "+1849": {
    name: "Dominican Republic",
    dialCode: "+1849",
    countryCode: "DO",
  },
  "+593": {
    name: "Ecuador",
    dialCode: "+593",
    countryCode: "EC",
  },
  "+20": {
    name: "Egypt",
    dialCode: "+20",
    countryCode: "EG",
  },
  "+503": {
    name: "El Salvador",
    dialCode: "+503",
    countryCode: "SV",
  },
  "+240": {
    name: "Equatorial Guinea",
    dialCode: "+240",
    countryCode: "GQ",
  },
  "+291": {
    name: "Eritrea",
    dialCode: "+291",
    countryCode: "ER",
  },
  "+372": {
    name: "Estonia",
    dialCode: "+372",
    countryCode: "EE",
  },
  "+251": {
    name: "Ethiopia",
    dialCode: "+251",
    countryCode: "ET",
  },
  "+500": {
    name: "South Georgia and the South Sandwich Islands",
    dialCode: "+500",
    countryCode: "GS",
  },
  "+298": {
    name: "Faroe Islands",
    dialCode: "+298",
    countryCode: "FO",
  },
  "+679": {
    name: "Fiji",
    dialCode: "+679",
    countryCode: "FJ",
  },
  "+33": {
    name: "France",
    dialCode: "+33",
    countryCode: "FR",
  },
  "+594": {
    name: "French Guiana",
    dialCode: "+594",
    countryCode: "GF",
  },
  "+689": {
    name: "French Polynesia",
    dialCode: "+689",
    countryCode: "PF",
  },
  "+241": {
    name: "Gabon",
    dialCode: "+241",
    countryCode: "GA",
  },
  "+220": {
    name: "Gambia",
    dialCode: "+220",
    countryCode: "GM",
  },
  "+995": {
    name: "Georgia",
    dialCode: "+995",
    countryCode: "GE",
  },
  "+49": {
    name: "Germany",
    dialCode: "+49",
    countryCode: "DE",
  },
  "+233": {
    name: "Ghana",
    dialCode: "+233",
    countryCode: "GH",
  },
  "+350": {
    name: "Gibraltar",
    dialCode: "+350",
    countryCode: "GI",
  },
  "+30": {
    name: "Greece",
    dialCode: "+30",
    countryCode: "GR",
  },
  "+299": {
    name: "Greenland",
    dialCode: "+299",
    countryCode: "GL",
  },
  "+1473": {
    name: "Grenada",
    dialCode: "+1473",
    countryCode: "GD",
  },
  "+590": {
    name: "Saint Martin",
    dialCode: "+590",
    countryCode: "MF",
  },
  "+1671": {
    name: "Guam",
    dialCode: "+1671",
    countryCode: "GU",
  },
  "+502": {
    name: "Guatemala",
    dialCode: "+502",
    countryCode: "GT",
  },
  "+44": {
    name: "United Kingdom",
    dialCode: "+44",
    countryCode: "GB",
  },
  "+224": {
    name: "Guinea",
    dialCode: "+224",
    countryCode: "GN",
  },
  "+245": {
    name: "Guinea-Bissau",
    dialCode: "+245",
    countryCode: "GW",
  },
  "+595": {
    name: "Paraguay",
    dialCode: "+595",
    countryCode: "PY",
  },
  "+509": {
    name: "Haiti",
    dialCode: "+509",
    countryCode: "HT",
  },
  "+379": {
    name: "Holy See (Vatican City State)",
    dialCode: "+379",
    countryCode: "VA",
  },
  "+504": {
    name: "Honduras",
    dialCode: "+504",
    countryCode: "HN",
  },
  "+852": {
    name: "Hong Kong",
    dialCode: "+852",
    countryCode: "HK",
  },
  "+36": {
    name: "Hungary",
    dialCode: "+36",
    countryCode: "HU",
  },
  "+354": {
    name: "Iceland",
    dialCode: "+354",
    countryCode: "IS",
  },
  "+91": {
    name: "India",
    dialCode: "+91",
    countryCode: "IN",
  },
  "+62": {
    name: "Indonesia",
    dialCode: "+62",
    countryCode: "ID",
  },
  "+98": {
    name: "Iran, Islamic Republic of Persian Gulf",
    dialCode: "+98",
    countryCode: "IR",
  },
  "+964": {
    name: "Iraq",
    dialCode: "+964",
    countryCode: "IQ",
  },
  "+353": {
    name: "Ireland",
    dialCode: "+353",
    countryCode: "IE",
  },
  "+972": {
    name: "Israel",
    dialCode: "+972",
    countryCode: "IL",
  },
  "+39": {
    name: "Italy",
    dialCode: "+39",
    countryCode: "IT",
  },
  "+1876": {
    name: "Jamaica",
    dialCode: "+1876",
    countryCode: "JM",
  },
  "+81": {
    name: "Japan",
    dialCode: "+81",
    countryCode: "JP",
  },
  "+962": {
    name: "Jordan",
    dialCode: "+962",
    countryCode: "JO",
  },
  "+77": {
    name: "Kazakhstan",
    dialCode: "+77",
    countryCode: "KZ",
  },
  "+254": {
    name: "Kenya",
    dialCode: "+254",
    countryCode: "KE",
  },
  "+686": {
    name: "Kiribati",
    dialCode: "+686",
    countryCode: "KI",
  },
  "+850": {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: "+850",
    countryCode: "KP",
  },
  "+82": {
    name: "Korea, Republic of South Korea",
    dialCode: "+82",
    countryCode: "KR",
  },
  "+965": {
    name: "Kuwait",
    dialCode: "+965",
    countryCode: "KW",
  },
  "+996": {
    name: "Kyrgyzstan",
    dialCode: "+996",
    countryCode: "KG",
  },
  "+856": {
    name: "Laos",
    dialCode: "+856",
    countryCode: "LA",
  },
  "+371": {
    name: "Latvia",
    dialCode: "+371",
    countryCode: "LV",
  },
  "+961": {
    name: "Lebanon",
    dialCode: "+961",
    countryCode: "LB",
  },
  "+266": {
    name: "Lesotho",
    dialCode: "+266",
    countryCode: "LS",
  },
  "+231": {
    name: "Liberia",
    dialCode: "+231",
    countryCode: "LR",
  },
  "+218": {
    name: "Libyan Arab Jamahiriya",
    dialCode: "+218",
    countryCode: "LY",
  },
  "+423": {
    name: "Liechtenstein",
    dialCode: "+423",
    countryCode: "LI",
  },
  "+370": {
    name: "Lithuania",
    dialCode: "+370",
    countryCode: "LT",
  },
  "+352": {
    name: "Luxembourg",
    dialCode: "+352",
    countryCode: "LU",
  },
  "+853": {
    name: "Macao",
    dialCode: "+853",
    countryCode: "MO",
  },
  "+389": {
    name: "Macedonia",
    dialCode: "+389",
    countryCode: "MK",
  },
  "+261": {
    name: "Madagascar",
    dialCode: "+261",
    countryCode: "MG",
  },
  "+265": {
    name: "Malawi",
    dialCode: "+265",
    countryCode: "MW",
  },
  "+60": {
    name: "Malaysia",
    dialCode: "+60",
    countryCode: "MY",
  },
  "+960": {
    name: "Maldives",
    dialCode: "+960",
    countryCode: "MV",
  },
  "+223": {
    name: "Mali",
    dialCode: "+223",
    countryCode: "ML",
  },
  "+356": {
    name: "Malta",
    dialCode: "+356",
    countryCode: "MT",
  },
  "+692": {
    name: "Marshall Islands",
    dialCode: "+692",
    countryCode: "MH",
  },
  "+596": {
    name: "Martinique",
    dialCode: "+596",
    countryCode: "MQ",
  },
  "+222": {
    name: "Mauritania",
    dialCode: "+222",
    countryCode: "MR",
  },
  "+230": {
    name: "Mauritius",
    dialCode: "+230",
    countryCode: "MU",
  },
  "+262": {
    name: "Reunion",
    dialCode: "+262",
    countryCode: "RE",
  },
  "+52": {
    name: "Mexico",
    dialCode: "+52",
    countryCode: "MX",
  },
  "+691": {
    name: "Micronesia, Federated States of Micronesia",
    dialCode: "+691",
    countryCode: "FM",
  },
  "+373": {
    name: "Moldova",
    dialCode: "+373",
    countryCode: "MD",
  },
  "+377": {
    name: "Monaco",
    dialCode: "+377",
    countryCode: "MC",
  },
  "+976": {
    name: "Mongolia",
    dialCode: "+976",
    countryCode: "MN",
  },
  "+382": {
    name: "Montenegro",
    dialCode: "+382",
    countryCode: "ME",
  },
  "+1664": {
    name: "Montserrat",
    dialCode: "+1664",
    countryCode: "MS",
  },
  "+212": {
    name: "Morocco",
    dialCode: "+212",
    countryCode: "MA",
  },
  "+258": {
    name: "Mozambique",
    dialCode: "+258",
    countryCode: "MZ",
  },
  "+95": {
    name: "Myanmar",
    dialCode: "+95",
    countryCode: "MM",
  },
  "+264": {
    name: "Namibia",
    dialCode: "+264",
    countryCode: "NA",
  },
  "+674": {
    name: "Nauru",
    dialCode: "+674",
    countryCode: "NR",
  },
  "+977": {
    name: "Nepal",
    dialCode: "+977",
    countryCode: "NP",
  },
  "+31": {
    name: "Netherlands",
    dialCode: "+31",
    countryCode: "NL",
  },
  "+599": {
    name: "Netherlands Antilles",
    dialCode: "+599",
    countryCode: "AN",
  },
  "+687": {
    name: "New Caledonia",
    dialCode: "+687",
    countryCode: "NC",
  },
  "+64": {
    name: "New Zealand",
    dialCode: "+64",
    countryCode: "NZ",
  },
  "+505": {
    name: "Nicaragua",
    dialCode: "+505",
    countryCode: "NI",
  },
  "+227": {
    name: "Niger",
    dialCode: "+227",
    countryCode: "NE",
  },
  "+234": {
    name: "Nigeria",
    dialCode: "+234",
    countryCode: "NG",
  },
  "+683": {
    name: "Niue",
    dialCode: "+683",
    countryCode: "NU",
  },
  "+1670": {
    name: "Northern Mariana Islands",
    dialCode: "+1670",
    countryCode: "MP",
  },
  "+47": {
    name: "Svalbard and Jan Mayen",
    dialCode: "+47",
    countryCode: "SJ",
  },
  "+968": {
    name: "Oman",
    dialCode: "+968",
    countryCode: "OM",
  },
  "+92": {
    name: "Pakistan",
    dialCode: "+92",
    countryCode: "PK",
  },
  "+680": {
    name: "Palau",
    dialCode: "+680",
    countryCode: "PW",
  },
  "+970": {
    name: "Palestinian Territory, Occupied",
    dialCode: "+970",
    countryCode: "PS",
  },
  "+507": {
    name: "Panama",
    dialCode: "+507",
    countryCode: "PA",
  },
  "+675": {
    name: "Papua New Guinea",
    dialCode: "+675",
    countryCode: "PG",
  },
  "+51": {
    name: "Peru",
    dialCode: "+51",
    countryCode: "PE",
  },
  "+63": {
    name: "Philippines",
    dialCode: "+63",
    countryCode: "PH",
  },
  "+872": {
    name: "Pitcairn",
    dialCode: "+872",
    countryCode: "PN",
  },
  "+48": {
    name: "Poland",
    dialCode: "+48",
    countryCode: "PL",
  },
  "+351": {
    name: "Portugal",
    dialCode: "+351",
    countryCode: "PT",
  },
  "+1939": {
    name: "Puerto Rico",
    dialCode: "+1939",
    countryCode: "PR",
  },
  "+974": {
    name: "Qatar",
    dialCode: "+974",
    countryCode: "QA",
  },
  "+40": {
    name: "Romania",
    dialCode: "+40",
    countryCode: "RO",
  },
  "+7": {
    name: "Russia",
    dialCode: "+7",
    countryCode: "RU",
  },
  "+250": {
    name: "Rwanda",
    dialCode: "+250",
    countryCode: "RW",
  },
  "+290": {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dialCode: "+290",
    countryCode: "SH",
  },
  "+1869": {
    name: "Saint Kitts and Nevis",
    dialCode: "+1869",
    countryCode: "KN",
  },
  "+1758": {
    name: "Saint Lucia",
    dialCode: "+1758",
    countryCode: "LC",
  },
  "+508": {
    name: "Saint Pierre and Miquelon",
    dialCode: "+508",
    countryCode: "PM",
  },
  "+1784": {
    name: "Saint Vincent and the Grenadines",
    dialCode: "+1784",
    countryCode: "VC",
  },
  "+685": {
    name: "Samoa",
    dialCode: "+685",
    countryCode: "WS",
  },
  "+378": {
    name: "San Marino",
    dialCode: "+378",
    countryCode: "SM",
  },
  "+239": {
    name: "Sao Tome and Principe",
    dialCode: "+239",
    countryCode: "ST",
  },
  "+966": {
    name: "Saudi Arabia",
    dialCode: "+966",
    countryCode: "SA",
  },
  "+221": {
    name: "Senegal",
    dialCode: "+221",
    countryCode: "SN",
  },
  "+381": {
    name: "Serbia",
    dialCode: "+381",
    countryCode: "RS",
  },
  "+248": {
    name: "Seychelles",
    dialCode: "+248",
    countryCode: "SC",
  },
  "+232": {
    name: "Sierra Leone",
    dialCode: "+232",
    countryCode: "SL",
  },
  "+65": {
    name: "Singapore",
    dialCode: "+65",
    countryCode: "SG",
  },
  "+421": {
    name: "Slovakia",
    dialCode: "+421",
    countryCode: "SK",
  },
  "+386": {
    name: "Slovenia",
    dialCode: "+386",
    countryCode: "SI",
  },
  "+677": {
    name: "Solomon Islands",
    dialCode: "+677",
    countryCode: "SB",
  },
  "+252": {
    name: "Somalia",
    dialCode: "+252",
    countryCode: "SO",
  },
  "+27": {
    name: "South Africa",
    dialCode: "+27",
    countryCode: "ZA",
  },
  "+211": {
    name: "South Sudan",
    dialCode: "+211",
    countryCode: "SS",
  },
  "+34": {
    name: "Spain",
    dialCode: "+34",
    countryCode: "ES",
  },
  "+94": {
    name: "Sri Lanka",
    dialCode: "+94",
    countryCode: "LK",
  },
  "+249": {
    name: "Sudan",
    dialCode: "+249",
    countryCode: "SD",
  },
  "+597": {
    name: "Suriname",
    dialCode: "+597",
    countryCode: "SR",
  },
  "+268": {
    name: "Swaziland",
    dialCode: "+268",
    countryCode: "SZ",
  },
  "+46": {
    name: "Sweden",
    dialCode: "+46",
    countryCode: "SE",
  },
  "+41": {
    name: "Switzerland",
    dialCode: "+41",
    countryCode: "CH",
  },
  "+963": {
    name: "Syrian Arab Republic",
    dialCode: "+963",
    countryCode: "SY",
  },
  "+886": {
    name: "Taiwan",
    dialCode: "+886",
    countryCode: "TW",
  },
  "+992": {
    name: "Tajikistan",
    dialCode: "+992",
    countryCode: "TJ",
  },
  "+255": {
    name: "Tanzania, United Republic of Tanzania",
    dialCode: "+255",
    countryCode: "TZ",
  },
  "+66": {
    name: "Thailand",
    dialCode: "+66",
    countryCode: "TH",
  },
  "+670": {
    name: "Timor-Leste",
    dialCode: "+670",
    countryCode: "TL",
  },
  "+228": {
    name: "Togo",
    dialCode: "+228",
    countryCode: "TG",
  },
  "+690": {
    name: "Tokelau",
    dialCode: "+690",
    countryCode: "TK",
  },
  "+676": {
    name: "Tonga",
    dialCode: "+676",
    countryCode: "TO",
  },
  "+1868": {
    name: "Trinidad and Tobago",
    dialCode: "+1868",
    countryCode: "TT",
  },
  "+216": {
    name: "Tunisia",
    dialCode: "+216",
    countryCode: "TN",
  },
  "+90": {
    name: "Turkey",
    dialCode: "+90",
    countryCode: "TR",
  },
  "+993": {
    name: "Turkmenistan",
    dialCode: "+993",
    countryCode: "TM",
  },
  "+1649": {
    name: "Turks and Caicos Islands",
    dialCode: "+1649",
    countryCode: "TC",
  },
  "+688": {
    name: "Tuvalu",
    dialCode: "+688",
    countryCode: "TV",
  },
  "+256": {
    name: "Uganda",
    dialCode: "+256",
    countryCode: "UG",
  },
  "+380": {
    name: "Ukraine",
    dialCode: "+380",
    countryCode: "UA",
  },
  "+971": {
    name: "United Arab Emirates",
    dialCode: "+971",
    countryCode: "AE",
  },
  "+598": {
    name: "Uruguay",
    dialCode: "+598",
    countryCode: "UY",
  },
  "+998": {
    name: "Uzbekistan",
    dialCode: "+998",
    countryCode: "UZ",
  },
  "+678": {
    name: "Vanuatu",
    dialCode: "+678",
    countryCode: "VU",
  },
  "+58": {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dialCode: "+58",
    countryCode: "VE",
  },
  "+84": {
    name: "Vietnam",
    dialCode: "+84",
    countryCode: "VN",
  },
  "+1284": {
    name: "Virgin Islands, British",
    dialCode: "+1284",
    countryCode: "VG",
  },
  "+1340": {
    name: "Virgin Islands, U.S.",
    dialCode: "+1340",
    countryCode: "VI",
  },
  "+681": {
    name: "Wallis and Futuna",
    dialCode: "+681",
    countryCode: "WF",
  },
  "+967": {
    name: "Yemen",
    dialCode: "+967",
    countryCode: "YE",
  },
  "+260": {
    name: "Zambia",
    dialCode: "+260",
    countryCode: "ZM",
  },
  "+263": {
    name: "Zimbabwe",
    dialCode: "+263",
    countryCode: "ZW",
  },
};

export const DROPDOWN_TYPE = "vp-dropdown";
