import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import vToast from "@/utils/vToast";

import { RULES_TABS } from "@/constants/accounting";
import { RULES_TOAST_CONFIG } from "@/constants/rules";
import API from "@/api";

import { setIsFormSubmissionProgress } from "./loadersError";

const initialState = {
  rules: {
    list: null,
    isFetching: false,
  },
  mappedTo: [],
  selectedRule: null,
  selectedMappings: [],
  ruleItemTypes: [],
  ruleItems: [],
  rulePresent: null,
  isFetchingRule: false,
  isFetchingMappings: false,
  isFetchingRuleItemTypes: false,
  rulesCount: null,
  isFetchingRulesCount: false,
  isLoading: false,
  rulesSliderTabsInfo: {
    selectedTab: RULES_TABS[0],
    tabList: RULES_TABS,
  },
  transactionsCount: null,
  isFetchingTransactionsCount: false,
};

const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    resetRuleStore: () => initialState,
    setRules(state, action) {
      state.rules.list = action.payload;
    },
    setSelectedRule(state, action) {
      state.selectedRule = action.payload;
    },
    setFetchingRules(state, action) {
      state.rules.isFetching = action.payload;
    },
    setFetchingRule(state, action) {
      state.isFetchingRule = action.payload;
    },
    setFetchingMappings(state, action) {
      state.isFetchingMappings = action.payload;
    },
    setSelectedMappings(state, action) {
      state.selectedMappings = action.payload;
    },
    resetSelectedMappings(state) {
      state.selectedMappings = [];
    },
    setRuleItemTypes(state, action) {
      state.ruleItemTypes = action.payload;
    },
    setIsFetchingRuleItemTypes(state, action) {
      state.isFetchingRuleItemTypes = action.payload;
    },
    setRuleItems(state, action) {
      state.ruleItems = action.payload.data.ruleItems;
    },
    setMappedTo(state, action) {
      state.mappedTo = action.payload.mappedTo;
    },
    setRulePresent(state, action) {
      state.rulePresent = action.payload;
    },
    setRulesCount(state, action) {
      state.rulesCount = action.payload;
    },
    setIsLoadingRules(state, action) {
      state.isLoading = action.payload;
    },
    setRulesSliderTab(state, action) {
      state.rulesSliderTabsInfo.selectedTab = action.payload;
    },
    setTransactionsCount(state, action) {
      state.transactionsCount = action.payload;
    },
    setIsFetchingTransactionsCount(state, action) {
      state.isFetchingTransactionsCount = action.payload;
    },
    setIsFetchingRulesCount(state, action) {
      state.isFetchingRulesCount = action.payload;
    },
  },
});

export const fetchRules = createAsyncThunk(
  "rules/fetchRules",
  async (params, { dispatch }) => {
    dispatch(setFetchingRules(true));
    const [err, response] = await to(API.Rules.all(params));
    if (!err && response?.data) {
      dispatch(setRules(response));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setFetchingRules(false));
  }
);
export const fetchRule = createAsyncThunk(
  "rules/fetchRule",
  async (params, { dispatch }) => {
    dispatch(setFetchingRule(true));
    const [err, response] = await to(API.Rules.get(params.id));
    if (response?.data) {
      dispatch(setSelectedRule(response?.data));
      dispatch(setMappedTo(response?.data));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setFetchingRule(false));
  }
);

export const fetchRulesCount = createAsyncThunk(
  "rules/fetchRulesCount",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingRulesCount(true));
    const [err, response] = await to(API.Rules.fetchRulesCount());
    dispatch(setIsFetchingRulesCount(false));

    if (response?.data) {
      dispatch(setRulesCount(response?.data?.count));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchMappings = createAsyncThunk(
  "rules/fetchMappings",
  async (params, { dispatch }) => {
    dispatch(setFetchingMappings(true));
    const [err, response] = await to(API.Rules.mappings(params.id));
    if (response?.data) {
      dispatch(setSelectedMappings(response?.data));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setFetchingMappings(false));
  }
);

export const fetchRuleItemTypes = createAsyncThunk(
  "rules/fetchRuleItemTypes",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingRuleItemTypes(true));
    const [err, response] = await to(API.Rules.ruleItemTypes(params ?? {}));
    if (response?.data) {
      dispatch(setRuleItemTypes(response?.data));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingRuleItemTypes(false));
  }
);

export const fetchRuleTransactionsCount = createAsyncThunk(
  "rules/fetchRuleTransactionsCount",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingTransactionsCount(true));
    const [err, response] = await to(API.Rules.getTransactionsCount(params));
    if (response?.data) {
      dispatch(setTransactionsCount(response?.data?.counts));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingTransactionsCount(false));
  }
);

export const createRule = createAsyncThunk(
  "rules/createRule",
  async (params, { dispatch }) => {
    const { payload, onSuccess, onSlider = false } = params;

    dispatch(setIsFormSubmissionProgress(true));
    const [err, response] = await to(API.Rules.create(payload));

    if (response) {
      const ruleCreationMessage = params?.payload?.all_applicable
        ? RULES_TOAST_CONFIG.CREATE_ALL_APPLICABLE_SUCCESS
        : onSlider
          ? RULES_TOAST_CONFIG.CREATE_ON_SLIDER_SUCCESS
          : RULES_TOAST_CONFIG.CREATE_SUCCESS;

      vToast(ruleCreationMessage);
      if (response?.data && onSuccess) {
        onSuccess();
      }
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const editRule = createAsyncThunk(
  "rules/editRule",
  async (params, { dispatch }) => {
    const { payload, onSuccess } = params;
    dispatch(setIsFormSubmissionProgress(true));
    const [err, response] = await to(API.Rules.edit(payload));

    if (response) {
      vToast(RULES_TOAST_CONFIG.EDIT_SUCCESS);
      if (response?.data && onSuccess) {
        onSuccess();
      }
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const deleteRule = createAsyncThunk(
  "rules/deleteRule",
  async (params, { dispatch }) => {
    const { payload, onSuccess } = params;
    dispatch(setIsLoadingRules(true));
    dispatch(setIsFormSubmissionProgress(true));
    const [err, response] = await to(API.Rules.delete(payload));

    if (response) {
      vToast(getSuccessToastMessage(response));
      if (response?.data && onSuccess) {
        onSuccess();
      }
    } else if (err) {
      vToast(
        RULES_TOAST_CONFIG[
          `DELETE_${payload.type === "rule" ? "RULE" : "MAPPING"}_FAIL`
        ]
      );
    }
    dispatch(setIsLoadingRules(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const rulePresent = createAsyncThunk(
  "rules/rulePresent",
  async (params, { dispatch }) => {
    const { payload, onSuccess } = params;
    const [err, response] = await to(API.Rules.ruleCheck(payload));
    if (response.data) {
      dispatch(setRulePresent(response.data.exists));
    } else if (err) {
      dispatch(setRulePresent(false));
    }
  }
);

export const getCardRulesByRuleId = createAsyncThunk(
  "cards/getCardRules",
  async (id, { dispatch }) => {
    dispatch(setFetchingRules(true));
    const [err, response] = await to(API.Rules.fetchCardRulesByRuleId(id));

    if (response?.data) {
      dispatch(
        setRules(
          response?.data?.ruleItemGroups?.[0]?.ruleActions.map((rule) => ({
            tagId: rule?.tagId,
            tagValueId: rule?.ownerId,
          })) || []
        )
      );
    }

    dispatch(setFetchingRules(false));
  }
);

export const {
  setRules,
  setSelectedRule,
  setFetchingRules,
  setFetchingRule,
  setFetchingMappings,
  setSelectedMappings,
  resetSelectedMappings,
  setRuleItemTypes,
  setIsFetchingRuleItemTypes,
  setMappedTo,
  resetRuleStore,
  setRulePresent,
  setRulesCount,
  setIsFetchingRulesCount,
  setIsLoadingRules,
  setRulesSliderTab,
  setTransactionsCount,
  setIsFetchingTransactionsCount,
} = rulesSlice.actions;

export default rulesSlice.reducer;
