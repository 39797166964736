import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchCreditBills,
  fetchCreditDetails,
  triggerCreditPayment,
} from "@/store/reducers/credit";

import {
  creditAccountSelector,
  defaultCurrencySelector,
  defaultPaymentAccountSelector,
} from "@/store/selectors/client";
import {
  creditBillsSelector,
  creditDetailsSelector,
} from "@/store/selectors/credit";

import Accordion from "@/components/core/Accordion";
import Alert from "@/components/core/Alert";
import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Flag from "@/components/core/Flag";
import Input from "@/components/core/Input";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import {
  CREDIT_PAYMENT_STATUS,
  REPAYMENT_TYPE,
} from "@/utils/constants/credit";
import { amountToCurrency, dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

const RepaymentSlider = () => {
  const dispatch = useDispatch();
  const creditDetail = useSelector(creditDetailsSelector);
  const creditBills = useSelector(creditBillsSelector) || { list: [] };
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const defaultAccount = useSelector(defaultPaymentAccountSelector);
  const creditAccount = useSelector(creditAccountSelector);

  const [repaymentType, setRepaymentType] = useState(REPAYMENT_TYPE.TOTAL);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(null);
  const [totalPaymentAmounts, setTotalPaymentAmounts] = useState([]);
  const [totalDue, setTotalDue] = useState(0);
  const [generatedBills, setGeneratedBills] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchCreditBills());
  }, []);

  const triggerPayment = () => {
    const params = { amount: totalPaymentAmount };
    dispatch(triggerCreditPayment({ payload: params, onSuccess }));
  };

  const onSuccess = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.credit.repayment);
    setSearchParams(searchParams);
    dispatch(fetchCreditBills());
    dispatch(fetchCreditDetails());
  };

  useEffect(() => {
    const genBills = creditBills?.list?.filter((a) =>
      [
        CREDIT_PAYMENT_STATUS.GENERATED,
        CREDIT_PAYMENT_STATUS.PARTIALLY_PAID,
        CREDIT_PAYMENT_STATUS.MANUAL_PARTIALLY_PAID,
      ].includes(a.status)
    );
    setGeneratedBills(genBills);
    setRepaymentType(
      genBills.length ? REPAYMENT_TYPE.TOTAL : REPAYMENT_TYPE.OTHER
    );
  }, [creditBills]);

  useEffect(() => {
    calculatePaymentAmount();
  }, [defaultAccount, creditAccount, creditBills]);

  useEffect(() => {
    calculatePaymentAmounts();
    setTotalDue(
      parseFloat(generatedBillAmount) - parseFloat(totalPaymentAmount)
    );
  }, [totalPaymentAmount, generatedBills]);

  const generatedBillAmount = generatedBills.reduce(
    (total, bill) => total + parseFloat(bill?.totalOutstandingAmount),
    0
  );

  const calculatePaymentAmount = () => {
    const totalAmount =
      defaultAccount?.availableBalance > generatedBillAmount
        ? generatedBillAmount
        : parseFloat(defaultAccount?.availableBalance);

    if (generatedBills.length) setTotalPaymentAmount(totalAmount);
  };

  const calculatePaymentAmounts = () => {
    const paymentAmounts = generatedBills.map((a) => a.totalOutstandingAmount);
    const payableAmounts = [];

    let tpa = parseFloat(totalPaymentAmount);
    paymentAmounts.forEach((a) => {
      const amount = parseFloat(a);
      if (tpa === 0) {
        payableAmounts.push({
          amount: 0,
          variant: "danger",
          text: "common.badgeStatus.lowAmount",
        });
      } else if (tpa < amount) {
        payableAmounts.push({
          amount: tpa,
          variant: "warning",
          text: "common.badgeStatus.partialAmount",
        });
        tpa = 0;
      } else {
        payableAmounts.push({
          amount,
          variant: "success",
          text: "common.badgeStatus.fullAmount",
        });
        tpa -= amount;
      }
    });
    setTotalPaymentAmounts(payableAmounts);
  };
  const lowAccountBalance = () => {
    return (
      (repaymentType === REPAYMENT_TYPE.TOTAL
        ? generatedBillAmount > parseFloat(defaultAccount?.availableBalance)
        : false) ||
      (defaultAccount?.availableBalance ?? 0) - totalPaymentAmount < 0
    );
  };

  const OutstandingAccordion = ({ type, totalAmount }) => {
    const title = `company.credit.repayment.${
      type === "billed"
        ? "totalBilledOutstandingAmount"
        : "totalUnbilledOutstandingAmount"
    }`;
    const rightLabelText = amountToCurrency(
      totalAmount,
      creditDetail?.currency
    );

    return (
      <div className="mb-4">
        {totalAmount <= 0 ? (
          <div className="flex justify-between w-full">
            <div className="flex items-center">
              <Text translationKey={title} />
            </div>
            <Text translationKey={rightLabelText} />
          </div>
        ) : (
          <Accordion
            items={[
              {
                id: 1,
                label: title,
                rightLabelText,
                renderContent: () => (
                  <div className="w-full p-4 pt-3 mt-4 bg-white rounded">
                    {type === "billed" ? (
                      generatedBills?.map((data, i) => (
                        <div
                          key={`generatedBill-${i}`}
                          className={
                            i !== generatedBills.length - 1
                              ? "border-b border-neutral-200 mb-4 pb-4"
                              : ""
                          }
                        >
                          <Text
                            classes="text-neutral-500 text-xs"
                            translationKey="company.credit.billGenerated"
                          />
                          <div className="flex items-center justify-between w-full">
                            <div className="flex items-center justify-center">
                              <span>
                                {dateToString(data.startDate)} -
                                {dateToString(data.endDate)}
                              </span>
                            </div>
                            <span>
                              {amountToCurrency(
                                data.totalOutstandingAmount,
                                data.totalAmount.currency
                              )}
                            </span>
                          </div>
                          <div className="flex items-center justify-between w-full mt-1">
                            <div className="flex items-center justify-center">
                              <Text
                                translationKey="misc.paying"
                                classes="text-neutral-500"
                              />
                              <Badge
                                variant={totalPaymentAmounts[i]?.variant}
                                translationKey={totalPaymentAmounts[i]?.text}
                                classes="px-2 ml-2"
                              />
                            </div>
                            <span className="text-danger-500">
                              {amountToCurrency(
                                totalPaymentAmounts[i]?.amount,
                                data.totalAmount.currency
                              )}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <Text
                          classes="text-neutral-500 text-xs"
                          translationKey="company.credit.billNotGenerated"
                        />
                        <div className="flex items-center justify-between w-full">
                          <div className="flex items-center justify-center">
                            <span>
                              {`${dateToString(
                                creditDetail?.currentCycle[0]
                              )} - ${dateToString(
                                creditDetail?.currentCycle[1]
                              )}`}
                            </span>
                          </div>
                          <span>
                            {amountToCurrency(
                              totalAmount,
                              creditDetail?.currency
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ),
              },
            ]}
            keepOthersOpen={false}
          />
        )}
      </div>
    );
  };

  const sliderHeader = "company.credit.repayment.title";

  const totalOutstandingAmount =
    parseFloat(creditDetail?.creditLimit) -
    parseFloat(creditDetail?.creditAvailableBalance?.value) -
    generatedBillAmount;

  const titleRef = useLeftHeaderTitle({ title: sliderHeader });

  return (
    <div className="slider-content-container">
      <div className="pb-8 slider-content-core">
        <div className="mb-8">
          <Text
            classes="payment-slider-heading"
            translationKey={sliderHeader}
            refProp={titleRef}
          />
        </div>
        <Text
          classes="text-xl font-bold"
          translationKey="company.credit.repayment.whatDoYouWantToPay"
          isChecked
        />
        <div className="flex gap-4 my-4">
          <Radio
            variant="box"
            disabled={!generatedBills.length}
            name="repaymentType"
            label="company.credit.repayment.payTotal"
            description="company.credit.repayment.payTotalDescription"
            value={REPAYMENT_TYPE.TOTAL}
            isChecked={repaymentType === REPAYMENT_TYPE.TOTAL}
            handleChange={(e) => {
              calculatePaymentAmount();
              setRepaymentType(e);
            }}
          />
          <Radio
            variant="box"
            name="repaymentType"
            label="company.credit.repayment.payOther"
            description="company.credit.repayment.payOtherDescription"
            isChecked={repaymentType === REPAYMENT_TYPE.OTHER}
            value={REPAYMENT_TYPE.OTHER}
            handleChange={(e) => {
              setRepaymentType(e);
            }}
          />
        </div>
        <div className="mt-8">
          <Text
            classes="text-xl font-bold mt-4"
            translationKey="company.credit.repayment.payingFrom"
            isChecked
          />
        </div>

        <div className="flex justify-between my-4 card-wrapper">
          <div className="flex">
            <Flag
              code={defaultAccount?.currency || defaultCurrency} // During initial rendering total_amount?.currency will be undefined so that a fallcack is added as SGD.
              classes="h-10 w-10 border-1 border-neutral-200 "
            />
            <div className="flex flex-col ml-4">
              <Text
                translationKey="company.credit.repayment.payingFrom"
                classes="text-neutral-500"
              />
              <Text
                classes="font-semibold text-lg"
                translationKey={`${
                  defaultAccount?.currency || defaultCurrency
                } account`}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <Text
              translationKey="common.balance"
              classes="text-neutral-500 text-right"
            />
            <div className="text-right">
              <span className="mr-1 text-lg font-semibold">
                {defaultAccount?.availableBalance || 0}
              </span>
              <Text
                classes="text-neutral-500"
                translationKey={`${
                  defaultAccount?.currency || defaultCurrency
                }`}
              />
            </div>
          </div>
        </div>
        {repaymentType === REPAYMENT_TYPE.OTHER ? (
          <div>
            <Text
              classes="text-xl font-bold my-4"
              translationKey="company.credit.repayment.howMuchPayment"
              isChecked
            />
            <div className="my-8">
              <Input
                name="amount"
                label="common.amount"
                type="number"
                value={totalPaymentAmount}
                onChange={(e) => setTotalPaymentAmount(e.target.value)}
                rightText={creditDetail?.currency}
              />
            </div>
          </div>
        ) : null}
        {lowAccountBalance() && repaymentType === REPAYMENT_TYPE.TOTAL ? (
          <Alert
            variant="danger"
            title="company.credit.repayment.lowAccountBalance"
            description="company.credit.repayment.lowAccountBalanceDescription"
            descriptionTransalationProp={{ currency: defaultCurrency }}
            primaryAction={{ label: "Add Fund" }} // need to connect account related slider
            secondaryAction={{ label: "Transfer Fund" }} // need to connect account related slider
          />
        ) : null}
        <div className="mt-8">
          <Text
            classes="text-xl font-bold mt-4"
            translationKey="company.credit.repayment.paymentDetails"
            isChecked
          />
        </div>

        <div className="mt-4 card-wrapper bg-neutral-50 border-neutral-50">
          <OutstandingAccordion
            type="billed"
            totalAmount={generatedBillAmount}
          />
          <OutstandingAccordion
            totalAmount={
              totalOutstandingAmount > 0 ? totalOutstandingAmount : 0
            }
          />
          <div className="flex justify-between mt-4">
            <Text translationKey="company.credit.repayment.totalPaymentAmount" />
            <Text
              classes="text-danger-500"
              translationKey={`${amountToCurrency(
                totalPaymentAmount || 0,
                creditAccount?.currency || defaultCurrency
              )}`}
            />
          </div>
          {totalDue > 0 ? (
            <div className="flex justify-between mt-4">
              <Text translationKey="company.credit.repayment.totalDue" />
              <Text
                classes="text-warning-500"
                translationKey={amountToCurrency(
                  totalDue,
                  creditAccount?.currency || defaultCurrency
                )}
              />
            </div>
          ) : null}
          {totalDue < 0 ? (
            <div className="flex justify-between pt-4 mt-4 border-t border-neutral-200">
              <Text translationKey="company.credit.repayment.advancePaymentAmount" />
              <Text
                classes="text-success-500"
                translationKey={amountToCurrency(
                  Math.abs(totalDue),
                  creditAccount?.currency || defaultCurrency
                )}
              />
            </div>
          ) : null}
        </div>

        <div className="mt-4 card-wrapper bg-neutral-50 border-neutral-50">
          <div className="flex justify-between mb-4">
            <Text
              translationKey="company.credit.repayment.accountBalanceAfterPayment"
              translationProps={{
                currency: creditAccount?.currency,
              }}
            />
            <Text
              translationKey={amountToCurrency(
                (defaultAccount?.availableBalance || 0) - totalPaymentAmount ||
                  0,
                creditAccount?.currency || defaultCurrency
              )}
            />
          </div>

          <div className="flex justify-between">
            <Text translationKey="company.credit.repayment.creditBalanceAfterPayment" />
            <Text
              translationKey={amountToCurrency(
                parseFloat(creditAccount?.creditAvailableBalance?.value) +
                  parseFloat(totalDue > 0 ? 0 : totalPaymentAmount || 0),
                creditAccount?.currency || defaultCurrency
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 px-3 pt-4 pb-2 slider-footer">
        <Button
          label="company.credit.buttonLabels.cancel"
          variant="tertiary w-[120px] h-10"
          onClick={() => null}
        />
        <Button
          label="company.credit.buttonLabels.confirmPayment"
          variant="primary w-[270px] h-10"
          onClick={() => triggerPayment()}
          disabled={
            lowAccountBalance() || !totalPaymentAmount || totalPaymentAmount < 1
          }
        />
      </div>
    </div>
  );
};
export default RepaymentSlider;
