import { Route, Routes } from "react-router-dom";

import Bill from "@/pages/bill-pay/bill";
import PaymentsApprovals from "@/pages/bill-pay/bill/approvals";
import PaymentsPageApprovalAll from "@/pages/bill-pay/bill/approvals/all";
import PaymentsPageApprovalNeedsYourApproval from "@/pages/bill-pay/bill/approvals/needs-your-approval";
import PaymentsPageApprovalPending from "@/pages/bill-pay/bill/approvals/pending";
import PaymentsInvoiceInbox from "@/pages/bill-pay/bill/invoice-inbox";
import PaidPayments from "@/pages/bill-pay/bill/paid";
import Payments from "@/pages/bill-pay/bill/payments";
import PaymentsPageAll from "@/pages/bill-pay/bill/payments/all";
import PaymentsPageFailed from "@/pages/bill-pay/bill/payments/failed";
import { ROUTES } from "@/constants/routes";

export default function BillPaymentsBillRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Bill />} />

      <Route
        path={`${ROUTES.billpay.bill.invoiceInbox.pathName}`}
        element={<PaymentsInvoiceInbox />}
      />

      <Route
        path={`${ROUTES.billpay.bill.approvals.pathName}`}
        element={<PaymentsApprovals />}
      >
        <Route
          path={`${ROUTES.billpay.bill.approvals.all.pathName}`}
          element={<PaymentsPageApprovalAll />}
        />
        <Route
          path={`${ROUTES.billpay.bill.approvals.needsApproval.pathName}`}
          element={<PaymentsPageApprovalNeedsYourApproval />}
        />
        <Route
          path={`${ROUTES.billpay.bill.approvals.pending.pathName}`}
          element={<PaymentsPageApprovalPending />}
        />
      </Route>
      <Route
        path={`${ROUTES.billpay.bill.payments.pathName}`}
        element={<Payments />}
      >
        <Route
          path={`${ROUTES.billpay.bill.payments.all.pathName}`}
          element={<PaymentsPageAll />}
        />
        <Route
          path={`${ROUTES.billpay.bill.payments.failed.pathName}`}
          element={<PaymentsPageFailed />}
        />
      </Route>

      <Route
        path={`${ROUTES.billpay.bill.paid.pathName}`}
        element={<PaidPayments />}
      />
    </Routes>
  );
}
