export const getSpendColor = (v) => {
  if (v > 0) return "positive-spend";
  if (v < 0) return "negative-spend";
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getSpendPercentage = (curr, prev) => {
  return ((prev - curr) / curr).toFixed(2);
};

export const getStatusClass = (status) => {
  if (status === "active") return "active-class";
  if (status === "inactive") return "activate-class";
};

export const getPhysicalCardText = (status) => {
  if (status === "active") return "Active";
  if (status === "inactive") return "Activate Now";
};
