import { useState } from "react";

import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import ControlView from "@/components/Cards/common/ControlView";

import { MERCHANT_CATEGORY_TYPES } from "@/constants/Cards";
import { ROUTES } from "@/constants/routes";

const SELECTED_TABS = [
  {
    name: "cards.settings.general.title",
    count: null,
    path: ROUTES.QRPay.settings.general.pathName,
    key: 1,
  },
  {
    name: "cards.settings.spendControl.title",
    count: null,
    path: ROUTES.QRPay.settings.spendControl.pathName,
    key: 2,
  },
];
export default function QRPaySettings() {
  const [selectedTab, setSelectedTab] = useState(SELECTED_TABS[1]);
  return (
    <div className="px-11">
      <Text translationKey="QRPay.title" />
      <div className="flex justify-between">
        <Text
          classes="payment-page-heading font-extrabold"
          translationKey="cards.settings.title"
        />
      </div>
      <Tabs
        items={SELECTED_TABS}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => setSelectedTab(tab)}
      />
    </div>
  );
}
