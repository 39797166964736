import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";

import { setDirectionResponseData } from "@/store/reducers/reimbursement";

import {
  directionResponseDataSelector,
  mileageRateListSelector,
} from "@/store/selectors/reimbursement";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import vToast from "@/utils/vToast";
import { amountToCurrency, getErrorToastMessage } from "@/utils/common";

import { MILEAGE_NOT_SET } from "@/constants/reimbursement";

const center = { lat: 12.7409, lng: 77.8253 };

function MileageMap({
  values,
  handleChange,
  setValues,
  errors,
  preview,
  selectedValues,
  disabled,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [search1, setSearch1] = useState();
  const [search2, setSearch2] = useState();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  const [map, setMap] = useState(/** @type google.maps.Map */ null);
  const [distance, setDistance] = useState("");
  const mileageRateList = useSelector(mileageRateListSelector);
  const [noMileageCountryList, setNoMileageCountryList] = useState([]);
  const [mapStyle, setMapStyle] = useState({
    borderRadius: "1rem",
    height: "15.5rem",
  });

  const directionResponse = useSelector(directionResponseDataSelector);

  const calculateRoute = async (address1, address2) => {
    const directionsService = new window.google.maps.DirectionsService();

    const results = await directionsService
      .route({
        origin: selectedValues?.start || address1,
        destination: selectedValues?.end || address2,
        travelMode: window.google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      })
      .catch((err) => {
        /**
         * @param {funtion} getErrorToastMessage
         * @description
         *   the first argument should be error, if error object is not there or you just want to pass title and desc, pass an empty string,
         *   the second argument is error title,
         *   the third argument is error description
         */
        vToast(
          getErrorToastMessage(
            {},
            "errorMessage.locationNotSelected",
            "errorMessage.locationDescription"
          )
        );
      });

    dispatch(setDirectionResponseData(results));
    setDistance(results?.routes?.[0]?.legs?.[0]?.distance?.text);
    return results?.routes?.[0]?.legs?.[0]?.distance?.text;
  };

  function onAutoCompleteForStartLoc(autocomplete) {
    setSearch1(autocomplete);
  }
  function onAutoCompleteForEndLoc(autocomplete) {
    setSearch2(autocomplete);
  }
  function getCountryFromPlace(place) {
    if (!place || !place.address_components) return null;
    const countryComponent = place.address_components.find((component) =>
      component.types.includes("country")
    );
    return countryComponent ? countryComponent.long_name : null;
  }

  const calculateAndUpdateCountryList = () => {
    const place1 = search1?.getPlace();
    const place2 = search2?.getPlace();
    const country2 = getCountryFromPlace(place2);
    const formattedAddress1 = place1?.formatted_address;
    const formattedAddress2 = place2?.formatted_address;
    if (formattedAddress1 && formattedAddress2) {
      const currDitance = calculateRoute(formattedAddress1, formattedAddress2);
      updateMileageMap(formattedAddress1, formattedAddress2, currDitance);
    }
    return { formattedAddress1, country2, formattedAddress2 };
  };

  function onStartLocChanged() {
    // this should not be done but to get the location from the autocomplete and to set it the value in the form we need to use handlechange like this
    const { formattedAddress1 } = calculateAndUpdateCountryList();
    if (formattedAddress1) {
      handleChange({
        target: {
          name: "startLocation",
          value: formattedAddress1,
          type: "text",
        },
      });
    }
  }

  function onEndLocChange() {
    const { formattedAddress2, country2 } = calculateAndUpdateCountryList();
    setValues({
      ...values,
      travelCountry: country2,
    });

    handleChange({
      target: {
        name: "endLocation",
        value: formattedAddress2,
        type: "text",
      },
    });
  }
  useEffect(() => {
    // here we are spliting the distance because it is in this form '0.7 km'
    if (values?.startLocation && values?.endLocation && !preview) {
      setValues({
        ...values,
        totalMiles: distance ? distance?.split(" ")?.[0]?.replace(",", "") : 0,
      });
    }
  }, [distance, values?.startLocation, values?.endLocation]);

  useEffect(() => {
    if (directionResponse || (distance && values?.travelCountry)) {
      // Set a different style when there is a directionResponse
      setMapStyle({
        borderRadius: "1rem",
        height: "248px",
        opacity: preview ? 0.2 : 1,
        position: "relative",
      });
    } else {
      // Set a different style when there is no directionResponse (loading)
      setMapStyle({
        borderRadius: "1rem",
        height: "248px",
        opacity: 0.2,
        position: "relative",
      });
    }
  }, [directionResponse]);

  useEffect(() => {
    if (values?.startLocation === "" || values?.endLocation === "") {
      dispatch(setDirectionResponseData(null));
      setDistance("");
      setValues({
        ...values,
        travelCountry: "",
      });
    }
  }, [values?.startLocation, values?.endLocation]);

  const mileage = mileageRateList?.find(
    (data) => data.countryName === values?.travelCountry
  );
  const mileageRate = mileage?.rate;

  const countryPresent = preview ?? mileageRate;

  const groupedOptions = [
    {
      label: t("reimbursement.createReimbursement.mileageSet"),
      options: mileageRateList,
    },
    {
      label: t("reimbursement.createReimbursement.mileageNotSet"),
      options: noMileageCountryList,
    },
  ];
  const checkIfExistAndAppend = (
    options,
    notOptions,
    currValue,
    setter = () => {}
  ) => {
    if (
      !options?.some((item) => item.countryName === currValue) &&
      !notOptions?.some((item) => item.countryName === currValue)
    ) {
      const createOption = {
        countryName: currValue,
        type: MILEAGE_NOT_SET,
        disabled: true,
      };
      setter((prev) => [...prev, createOption]);
    }
  };

  const updateMileageMap = (
    formattedAddress1,
    formattedAddress2,
    currDistance
  ) => {
    const startlocCountry = formattedAddress1?.split(", ").at(-1);
    const endlocCountry = formattedAddress2?.split(", ").at(-1);

    // if something exists in mileageList then donot update notMileage set
    //  if something doesnot exist
    //  then check if it exist in not mileage
    //  if not then append

    checkIfExistAndAppend(
      mileageRateList,
      noMileageCountryList,
      startlocCountry,
      setNoMileageCountryList
    );

    if (startlocCountry !== endlocCountry) {
      checkIfExistAndAppend(
        mileageRateList,
        noMileageCountryList,
        endlocCountry,
        setNoMileageCountryList
      );
    }
  };

  return (
    isLoaded && (
      <div>
        <div className="flex items-center gap-8 my-4">
          <div className="w-1/2">
            <Autocomplete
              onPlaceChanged={onStartLocChanged}
              onLoad={onAutoCompleteForStartLoc}
            >
              <Input
                name="startLocation"
                type="text"
                label="reimbursement.approvals.sliderHeadings.requestDetailsComponent.startLocation"
                value={preview ? selectedValues?.start : values?.startLocation}
                onChange={handleChange}
                disabled={disabled || preview}
              />
            </Autocomplete>
          </div>
          <div className="w-1/2">
            <Autocomplete
              onPlaceChanged={onEndLocChange}
              onLoad={onAutoCompleteForEndLoc}
            >
              <Input
                name="endLocation"
                type="text"
                label="reimbursement.approvals.sliderHeadings.requestDetailsComponent.endLocation"
                value={preview ? selectedValues?.end : values?.endLocation}
                onChange={handleChange}
                disabled={disabled || preview}
              />
            </Autocomplete>
          </div>
        </div>

        <div className="relative">
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={mapStyle}
            options={{
              zoomControl: false,
              scrollwheel: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(data) => setMap(data)}
          >
            {directionResponse ? (
              <DirectionsRenderer directions={directionResponse} />
            ) : null}
          </GoogleMap>
          {directionResponse || (distance && values?.travelCountry) ? null : (
            <div className="absolute z-10 text-center align-middle w-116 bottom-3 left-[10%]">
              <Text translationKey="reimbursement.approvals.sliderHeadings.requestDetailsComponent.LoadingMaps" />
            </div>
          )}
        </div>

        <div className="flex items-center gap-8 mt-8 ">
          <div className="w-1/2">
            <Input
              name="totalMiles"
              type="number"
              step="any"
              label="reimbursement.approvals.sliderHeadings.requestDetailsComponent.kms"
              value={
                preview
                  ? (selectedValues?.miles ?? selectedValues?.kms) // Product team is informed to do this
                  : values?.totalMiles
              }
              onChange={(e) => {
                handleChange(e);
                setDistance(e ? e.target.value : values?.totalMiles);
              }}
              disabled={disabled || preview}
            />
          </div>
          <div className="w-1/2">
            <VpSelect
              name="travelCountry"
              haveAboluteDescription
              label="reimbursement.approvals.sliderHeadings.requestDetailsComponent.travelledCountry"
              menuPosition="absolute"
              optionsDisplayKey="countryName"
              valueKey="countryName"
              options={groupedOptions}
              checkIsOptionDisabled={(option) => option?.disabled}
              value={
                preview ? selectedValues?.travelCountry : values?.travelCountry
              }
              handleChange={(e) => {
                handleChange(e);
              }}
              insideForm
              helperText={
                mileageRate
                  ? ""
                  : distance
                    ? "reimbursement.createReimbursement.contactAdmin"
                    : ""
              }
              helperTextVariant="danger"
              customUIForOptionWhenDropdownOpen={
                customUIForMileageRateWhenDropdownOpen
              }
              isGroupedMode
              disabled={disabled || preview}
              customNoOptionComponent={
                <Text translationKey="reimbursement.createReimbursement.contactAdmin" />
              }
            />
          </div>
        </div>
      </div>
    )
  );
}

MileageMap.propTypes = {
  values: PropTypes.object,
  setValues: PropTypes.func,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  preview: PropTypes.bool,
  selectedValues: PropTypes.object,
  disabled: PropTypes.bool,
};
export default MileageMap;

function customUIForMileageRateWhenDropdownOpen(displayValue, option) {
  const { currency, rate, countryName, type, disabled } = option;
  return (
    <div
      className={`flex flex-col py-1 ${
        type === MILEAGE_NOT_SET ? "cursor-disabled" : ""
      }`}
    >
      <Text classes=" font-medium " translationKey={countryName} />
      {type === MILEAGE_NOT_SET ? (
        <Text
          classes="text-sm"
          translationKey="reimbursement.createReimbursement.noMileageRateForCountry"
        />
      ) : (
        <Text
          classes="text-sm"
          translationProps={{ amount: amountToCurrency(rate, currency) }}
          translationKey="reimbursement.createReimbursement.ratePerMile"
        />
      )}
    </div>
  );
}
