import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  blockCard,
  freezeCard,
  resetPinUrl,
  setCardSliderTab,
  setResetPinUrl,
  setSelectedCard,
  unfreezeCard,
} from "@/store/reducers/cards";
import { fetchUserPhysicalCard } from "@/store/reducers/user";

import { resetPinUrlSelector } from "@/store/selectors/cards";
import {
  defaultCurrencySelector,
  primaryCardProviderSelector,
} from "@/store/selectors/client";
import {
  currentUserPhysicalCardSelector,
  isFetchingCurrentUserPhysicalCardSelector,
  userSelector,
} from "@/store/selectors/user";

import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Progress from "@/components/core/Progress";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import CardSnapshot from "@/components/Company/Slider/People/UserDetail/ShowCardsSection/CardSnapshot";
import { amountToCurrency } from "@/utils/common";

import {
  CARDS_ALL_SLIDER_MENU_CONFIG,
  CARDS_ALL_SLIDER_MENU_KEYS,
  CARD_SLIDER_SELECTED_TABS_CARD,
  CARD_SLIDER_TABS_KEY,
  CARD_STATUS,
  CHANGE_PIN_METHODS_TYPE,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function AllPhysicalCards() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(userSelector);
  const card = useSelector(currentUserPhysicalCardSelector);
  const isFetching = useSelector(isFetchingCurrentUserPhysicalCardSelector);
  const currency = useSelector(defaultCurrencySelector);
  const {
    totalSpent = 0,
    frequency = null,
    limit = 0,
    availableBalance: available = 0,
    linkedTo = {},
    allowedCtas,
  } = card ?? {};
  const cardProviderData = useSelector(primaryCardProviderSelector);

  const resetPinInfo = useSelector(resetPinUrlSelector);
  const resetPinLink = resetPinInfo?.result;

  const changePinMethod =
    card?.changePinMethod && card?.changePinMethod?.length > 0
      ? card?.changePinMethod
      : null;
  const DETAILS_CARD_OVERVIEW = {
    spent: {
      color: "bg-corporate-cards-500",
      label: "cards.spendOverview.spent",
      currency,
      amount: totalSpent,
    },
    limit: {
      color: "bg-neutral-200",
      label: "cards.spendOverview.limitOnly",
      frequency,
      currency,
      amount: limit,
      translationProps: { amount: limit, currency },
    },
    availableBalance: {
      color: "bg-neutral-200",
      label: "cards.spendOverview.available",
      frequency,
      currency,
      amount: available,
    },
  };
  const configCardHeader = [
    DETAILS_CARD_OVERVIEW.spent,
    DETAILS_CARD_OVERVIEW.availableBalance,
  ];

  useEffect(() => {
    fetchSelectedCardData();
  }, []);

  useEffect(() => {
    if (resetPinLink) {
      window.open(resetPinLink);
      dispatch(setSelectedCard(null));
    }
  }, [resetPinLink]);

  const handleCardClick = (cardId) => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.cards.id, cardId);
    setSearchParams(searchParams);
  };
  function fetchSelectedCardData() {
    dispatch(fetchUserPhysicalCard({ id: currentUser?.id }));
  }

  function freezeUnfreezeBlockHandler(menuKey) {
    if (cardProviderData && cardProviderData?.config?.askFreezeBlockReason) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.cards[menuKey], card?.id);
      setSearchParams(searchParams);
    } else {
      switch (menuKey) {
        case CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD:
          dispatch(
            freezeCard({ cardId: card?.id, onSuccess: fetchSelectedCardData })
          );
          break;

        case CARDS_ALL_SLIDER_MENU_KEYS.BLOCK:
          dispatch(
            blockCard({ cardId: card?.id, onSuccess: fetchSelectedCardData })
          );
          break;

        case CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE:
          dispatch(
            unfreezeCard({ cardId: card?.id, onSuccess: fetchSelectedCardData })
          );
          break;

        default:
          break;
      }
    }
  }
  function fetchResetPinUrl() {
    switch (changePinMethod) {
      case CHANGE_PIN_METHODS_TYPE.URL:
        dispatch(resetPinUrl({ cardId: card?.id }));
        break;

      case CHANGE_PIN_METHODS_TYPE.API:
        searchParams.append(SLIDERS_SEARCH_PARAMS.cards.changePin, card?.id);
        setSearchParams(searchParams);
        break;

      default:
        break;
    }
  }
  function handleMenuClick(menuKey) {
    switch (menuKey) {
      case CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN:
        if (!card?.changePinTooltip) {
          fetchResetPinUrl();
        }

        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD:
      case CARDS_ALL_SLIDER_MENU_KEYS.BLOCK:
      case CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE:
        freezeUnfreezeBlockHandler(menuKey);

        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.SETTINGS: {
        const findConfig = CARD_SLIDER_SELECTED_TABS_CARD?.find(
          (i) => i.key === CARD_SLIDER_TABS_KEY.CARD_SETTINGS
        );
        dispatch(setCardSliderTab(findConfig));
        searchParams.append(SLIDERS_SEARCH_PARAMS.cards.id, card?.id);
        setSearchParams(searchParams);

        break;
      }
      default:
        break;
    }
  }

  return isFetching ? (
    <div className="flex flex-row gap-8 mt-8 ">
      <div className="card-wrapper w-[480px] h-[342px] items-center flex flex-col justify-between">
        <div className="flex justify-between w-full">
          <LoaderSkeleton />
          <LoaderSkeleton />
        </div>
        <LoaderSkeleton size={[30, 250]} />
      </div>
      <div className="flex flex-col flex-1 p-0 card-wrapper">
        <div className="flex items-center pl-4 h-1/3 bg-neutral-50">
          <LoaderSkeleton />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-row flex-wrap w-full gap-8 mt-8">
      <CardSnapshot
        classes="!w-[480px] !h-[342px]"
        imgClasses="!w-122 !h-[342px]"
        availableBalance={card?.availableBalance}
        cardNumber={card?.cardNumber}
        cardType={card?.type}
        currency={card?.currency}
        limit={card?.limit}
        cardStatus={card?.status}
        cardProvider={card?.slug}
        onClick={() => handleCardClick(card?.id)}
        allowedCtas={card?.allowedCtas}
        cardId={card?.id}
        frequency={card?.frequency}
        cardProviderId={card?.providerCardId}
        cardUsageType={card?.cardUsageType}
        card={card}
        showMaskCard
      />
      {/* My Physical Card informations */}
      <div className="flex-1 !p-0 card-wrapper">
        <div className="flex items-center justify-between px-4 h-1/3 bg-neutral-50">
          <div className="flex flex-col">
            <Text
              classes="font-bold text-lg text-neutral-800"
              translationKey="cards.myPhysicalCard"
            />

            {card?.reissuedCard ? (
              <div className="flex items-center gap-2">
                <Icon
                  name="WarningTriangle"
                  className="w-4 h-4 text-warning-500"
                />
                <Text
                  classes=" text-sm font-medium text-warning-500"
                  translationKey="cards.cardSlider.reissuedCard.activationAlert"
                />
              </div>
            ) : null}
          </div>
          <div className="flex flex-row gap-4">
            {allowedCtas
              ?.filter(
                (item) =>
                  ![
                    CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD,
                    CARDS_ALL_SLIDER_MENU_KEYS.ACTIVATE,
                    CARDS_ALL_SLIDER_MENU_KEYS.ORDER_CARD,
                    CARDS_ALL_SLIDER_MENU_KEYS.LINKED_TO,
                  ]?.includes(item)
              )
              ?.map((item) => {
                const selectedConfig = CARDS_ALL_SLIDER_MENU_CONFIG[item];
                return (
                  <div
                    key={selectedConfig?.key}
                    onClick={() => handleMenuClick(selectedConfig?.key)}
                    className="flex flex-col items-center cursor-pointer"
                    id={selectedConfig?.key}
                  >
                    <Icon
                      bgClassName="bg-white "
                      className="w-4 h-4 text-neutral-500"
                      variant="rounded"
                      name={selectedConfig?.icon}
                    />
                    <Text
                      classes="text-sm font-bold  text-neutral-500"
                      translationKey={selectedConfig?.label}
                    />

                    {card?.changePinTooltip?.length > 0 ? (
                      <Tooltip id="change-pin" direction="bottom">
                        <Text translationKey={card?.changePinTooltip} />
                      </Tooltip>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
        <div>
          <div className="flex justify-between px-5 pt-5">
            {configCardHeader?.map((cardInfo, index) => (
              <div key={index} className="last:text-right">
                <div className="flex items-center gap-3">
                  <div
                    className={`inline-block w-2 h-2 rounded-full ${cardInfo.color}`}
                  />
                  <Text translationKey={cardInfo.label} />
                </div>
                <div>
                  <span className="text-xl font-bold text-neutral-800">
                    {amountToCurrency(cardInfo.amount)}
                  </span>
                  <span className="text-base font-bold text-neutral-500">
                    {cardInfo.currency}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="px-5 pt-3 pb-5 mt-2 ">
            <Progress
              classes="mb-6"
              data={{
                total: limit,
                items: [
                  {
                    variant: "corporate-cards",
                    value:
                      card?.status === CARD_STATUS.BLOCKED
                        ? card?.totalSpent
                        : limit - available,
                  },
                ],
              }}
              neutralTrack="neutral-track"
            />
            <div className="flex items-center justify-between pb-8">
              <div className="flex flex-col">
                <Text
                  translationKey={DETAILS_CARD_OVERVIEW.limit.label}
                  classes="text-base font-medium text-neutral-500"
                />
                <Text
                  classes="font-bold text-neutral-800 text-lg"
                  translationKey={amountToCurrency(
                    DETAILS_CARD_OVERVIEW.limit.translationProps?.amount,
                    DETAILS_CARD_OVERVIEW.limit.translationProps?.currency
                  )}
                />
              </div>
              <div className="flex flex-col items-end">
                <Text
                  translationKey="cards.spendOverview.linkedTo"
                  translationProps={{
                    projectType: linkedTo?.type?.toLowerCase(),
                  }}
                  classes="text-base font-medium text-neutral-500"
                />
                <Text
                  classes="font-bold text-neutral-800 text-lg"
                  translationKey={linkedTo?.name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllPhysicalCards;
