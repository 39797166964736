import PropTypes from "prop-types";

import { dateToString } from "@/utils/common";

export default function PaymentDateCell({ val }) {
  return <span>{val.paymentDate ? dateToString(val.paymentDate) : "-"}</span>;
}

PaymentDateCell.propTypes = {
  val: PropTypes.object,
};
