import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";

import { fetchBudgetData } from "@/store/reducers/company";

import { defaultCurrencySelector } from "@/store/selectors/client";
import { budgetDataSelector } from "@/store/selectors/company";

import Badge from "@/components/core/Badge";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import ProfileWidget from "@/components/core/ProfileWidget";
import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import {
  API_END_PAGE_TYPE,
  REIMBURSEMENT_KEY,
  REIMBURSEMENT_PAGE_TYPE,
  REIMBURSEMENT_STATUS,
  STATUS_COLORS,
} from "@/utils/constants/reimbursement";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";
import { amountToCurrency, dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { REIMBURSEMENT_TYPE } from "@/constants/reimbursement";

export default function ReimbursementPaymentsSliderTable({
  type,
  data = [],
  isFetchingData,
  reportEnabled,
  setTotalAmount = () => {},
  updateCount = () => {},
  onScroll,
  setDisablePayCta = () => {},
  hasMore,
  paymentHistory,
  reimbursementIds,
  setReimbursementIds = () => {},
  userReimbursementFilterOptions,
  userId,
  pageType,
  showStatus,
  showMultiSelect,
  multiSelectData,
  stepPaginationEnable = false,
  paginationData = {},
  headerSettings = {},
  sorting,
  setSorting = () => {},
  containerClasses = "",
  hideCheckBox = true,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ischeckedHeader, setIsCheckedHeader] = useState(false);
  const defaultCurrency = useSelector(defaultCurrencySelector);

  const budgetData = useSelector(budgetDataSelector);

  const notProcessingOrFailedClaim = (d) => {
    return d.status !== PAYMENT_STATUSES.processing;
  };

  const {
    headerSelected,
    totalReimbursementsSelected,
    selectedReimbursements,
    isEmpty,
    bulkSelectAll,
    setBulkSelectAll,
    headerSelectionHandler,
    rowSelectionHandler,
    exportHandler,
    clearSelection,
  } = multiSelectData || {};

  const statusData = {
    [PAYMENT_STATUSES.processing]: {
      icon: "Schedule",
      tooltipText:
        "billPay.bill.payments.paymentFooter.sliderStatusText.processing",
      iconColor: "warning",
    },
    [PAYMENT_STATUSES.failed]: {
      icon: "Cancel",
      tooltipText:
        "billPay.bill.payments.paymentFooter.sliderStatusText.failed",
      iconColor: "danger",
    },
  };

  if (type !== REIMBURSEMENT_PAGE_TYPE.paid) {
    // dont show processing icon in paid page
    statusData[PAYMENT_STATUSES.settledViaCard] = {
      icon: "Schedule",
      tooltipText:
        "billPay.bill.payments.paymentFooter.sliderStatusText.processing",
      iconColor: "warning",
    };
  }

  const [projectIds, setProjectIds] = useState([]);
  const [projectMap, setProjectMap] = useState({});

  const [disabledIds, setDisabledIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const selectedClaimIdHasInsufficientBudget = selectedIds?.some((id) =>
    disabledIds?.includes(id)
  );

  useEffect(() => {
    const newDisabledIds = [];

    if (selectedIds?.length && selectedClaimIdHasInsufficientBudget) return;

    data.forEach((item) => {
      const projectId = item?.linkedTo?.id;
      const project = budgetData?.projects?.find(
        (b) => b.projectId === projectId
      );
      const remainingBalance =
        (projectMap[projectId]?.balance ?? 0) -
        (projectMap[projectId]?.sumOfSelectedClaims ?? 0);

      if (project?.flexible) return;

      if (project?.availableBalance === "Infinity") return;

      if (item?.status === REIMBURSEMENT_STATUS.processing) return;

      if (
        ((Number(item?.amountToBePaid) > remainingBalance ||
          (item?.approvedAmount?.value ?? 0) > remainingBalance) &&
          !selectedIds.includes(item.id)) ||
        project?.balance < 0
      ) {
        newDisabledIds.push(item.id);
      }
    });
    setDisabledIds(newDisabledIds);
  }, [budgetData, projectMap]);

  useEffect(() => {
    if (paymentHistory) return;

    updateCount(selectedIds?.length);

    setDisablePayCta(selectedClaimIdHasInsufficientBudget);

    const amount = data
      ?.filter((o) => selectedIds?.includes(o?.id))
      ?.reduce(
        (a, c) =>
          a + (Number(c?.amountToBePaid) || c?.approvedAmount?.value || 0),
        0
      );

    setTotalAmount(amount);

    setReimbursementIds(selectedIds);
  }, [selectedIds]);

  useEffect(() => {
    if (data) {
      const projectIdsObject = new Set();
      data?.forEach((f) => projectIdsObject?.add(f?.linkedTo?.id));
      const projectIdsArray = [...projectIdsObject];

      if (projectIdsArray?.length) {
        dispatch(
          fetchBudgetData({
            module: REIMBURSEMENT_KEY,
            project_ids: projectIdsArray,
          })
        );
      }

      setProjectIds(projectIdsArray);
    }
  }, [data, selectedIds]);

  useEffect(() => {
    if (budgetData?.projects?.length) {
      const projectMapData = projectIds.reduce((acc, id) => {
        const foundProjectBudget = budgetData?.projects?.find(
          (a) => a?.projectId === id
        );
        acc[id] = {
          balance: foundProjectBudget?.availableBalance || 0,
          sumOfSelectedClaims: projectMap?.[id]?.sumOfSelectedClaims || 0,
          flexible: foundProjectBudget?.flexible,
        };
        return acc;
      }, {});

      setProjectMap(projectMapData);
    }
  }, [budgetData]);

  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.25,
  });

  const columnRequired = (column) => {
    const header = tableHeading.filter((heading) => {
      return heading?.id === column;
    });
    return header && header?.length > 0;
  };

  const handleHeaderCheckboxChange = (checked) => {
    let updatedSelectedIds = [];
    const updatedProjectMap = { ...projectMap };

    // Initialize sumOfSelectedClaims for all projects
    budgetData?.projects?.forEach((budgetItem) => {
      updatedProjectMap[budgetItem.projectId].sumOfSelectedClaims = 0;
    });

    data.forEach((item) => {
      const projectId = item?.linkedTo?.id;
      if (item?.status === REIMBURSEMENT_STATUS.processing) {
        return;
      }

      const projectBalance = updatedProjectMap[projectId]?.balance;
      const amount =
        (item?.approvedAmount?.value ?? 0) || Number(item?.amountToBePaid) || 0;
      const currentSum = updatedProjectMap[projectId]?.sumOfSelectedClaims;

      if (checked) {
        updatedSelectedIds.push(item.id);
      } else {
        updatedSelectedIds = [];
      }

      if (
        checked &&
        (currentSum + amount <= projectBalance ||
          updatedProjectMap[projectId]?.flexible ||
          updatedProjectMap[projectId]?.availableBalance === "Infinity")
      ) {
        updatedProjectMap[projectId].sumOfSelectedClaims += amount;
      }
    });

    setSelectedIds(updatedSelectedIds);
    setProjectMap(updatedProjectMap);
  };

  const insufficientBalanceUi = () => {
    return (
      <div className="flex items-center ">
        <Text
          translationKey="reimbursement.payments.sliderHeadings.inSufficientBalance"
          classes="text-xs text-warning-600 "
        />
        <TextWithTooltipIcon
          tooltipClasses="w-84 text-neutral-800 font-sm"
          tooltipText="reimbursement.payments.sliderHeadings.inSufficientBalanceTooltip"
        />
      </div>
    );
  };

  const duplicateReimbursementUi = () => {
    return (
      <div className="flex items-center gap-1 -ml-1.5">
        <TextWithTooltipIcon
          tooltipClasses="text-neutral-800 font-sm"
          iconClasses="text-warning-600"
          tooltipText="reimbursement.createReimbursement.duplicateClaimTitle"
        />
        <Text
          translationKey="billPay.bill.common.duplicateBillText"
          classes="text-xs text-warning-600 "
        />
      </div>
    );
  };

  useEffect(() => {
    if (ischeckedHeader && type === REIMBURSEMENT_PAGE_TYPE.payments)
      handleHeaderCheckboxChange(true);
  }, [data]);

  const handleCheckboxChange = (singleData, checked) => {
    const updatedSelectedIds = checked
      ? [...selectedIds, singleData?.id]
      : selectedIds?.filter((itemId) => itemId !== singleData?.id);
    setSelectedIds(updatedSelectedIds);

    const updatedProjectMap = { ...projectMap };
    const item = data?.find((i) => i.id === singleData?.id);
    const projectId = item?.linkedTo?.id;

    if (checked) {
      updatedProjectMap[projectId].sumOfSelectedClaims +=
        Number(item?.amountToBePaid) || (item?.approvedAmount?.value ?? 0);
    } else {
      updatedProjectMap[projectId].sumOfSelectedClaims -=
        Number(item?.amountToBePaid) || (item?.approvedAmount?.value ?? 0);
    }

    setProjectMap(updatedProjectMap);
  };

  useEffect(() => {
    setIsCheckedHeader(
      selectedIds.length &&
        !isFetchingData &&
        data?.filter((d) => notProcessingOrFailedClaim(d)).length ===
          selectedIds.length
    );
  }, [data, selectedIds, disabledIds]);

  const handleRowClick = (id) => {
    if (reportEnabled) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.reports.reportId, id);
      setSearchParams(searchParams);
    } else if (pageType === API_END_PAGE_TYPE.history) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.reimbursements.historyClaimId,
        id
      );
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.reimbursements.pageType,
        REIMBURSEMENT_PAGE_TYPE.history
      );
      setSearchParams(searchParams);
    } else {
      searchParams.append(SLIDERS_SEARCH_PARAMS.reimbursements.claimId, id);

      setSearchParams(searchParams);
    }
  };

  const limit = paginationData?.limit ?? 0;
  const pageNum = paginationData?.pageNum ?? 1;
  const rows = data?.slice(limit * (pageNum - 1), limit * pageNum) ?? [];

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
  } = headerSettings;

  const prevDisabled = paginationData?.pageNum === 1;
  const nextDisabled =
    !paginationData?.hasMore &&
    paginationData?.pageNum === paginationData?.page;

  return (
    <div className={containerClasses}>
      <Table
        {...tableSetting}
        {...(showMultiSelect
          ? {
              ...{
                bulkApproveVisible: selectedReimbursements?.length > 0,
                bulkApproveHeight: "100px",
                bulkApproveContent: (
                  <BulkActionComponent
                    selectedRows={selectedReimbursements?.length}
                    totalRows={totalReimbursementsSelected}
                    descriptionText="common.exportDesc"
                    bulkApproveSelectAll={
                      bulkSelectAll ||
                      selectedReimbursements?.length ===
                        totalReimbursementsSelected
                    }
                    showSelectAllButton
                    selectAllHandler={(val) => {
                      setBulkSelectAll(val);
                    }}
                    handleExport={exportHandler}
                    showExport
                    clearSelection={clearSelection}
                  />
                ),
              },
            }
          : {})}
      >
        <tr className={tableHeadingRowClasses}>
          {tableHeading?.map((headVal, index) => (
            <th className={headVal.classes} key={headVal.id}>
              {headVal.id === "status" && !showStatus ? null : (
                <HeaderCell
                  val={{
                    ...headVal,
                    onCheckboxClick:
                      headerSelectionHandler ?? handleHeaderCheckboxChange,
                    checkedValue:
                      (headerSelected || ischeckedHeader) && !isEmpty,
                    showCheckbox:
                      type === REIMBURSEMENT_PAGE_TYPE.paid
                        ? false
                        : index === 0 && !hideCheckBox && headVal?.showCheckbox,
                    sorting,
                    setSorting,
                  }}
                />
              )}
            </th>
          ))}
        </tr>

        {[...(stepPaginationEnable ? rows : data)]?.map(
          (unsettledItem, index, arr) => {
            const isCheckedCondition =
              (selectedIds?.includes(unsettledItem?.id) &&
                unsettledItem?.status !== REIMBURSEMENT_STATUS.processing) ||
              selectedReimbursements?.includes(unsettledItem?.id)
                ? "selected-row-cell"
                : "";

            const disableRowCondition =
              disabledIds?.includes(unsettledItem?.id) &&
              unsettledItem?.status !== REIMBURSEMENT_STATUS.processing &&
              type === REIMBURSEMENT_PAGE_TYPE.payments;

            const isDisabledRowConditionCheck = disableRowCondition
              ? "disabled-row-cell"
              : "";

            return (
              <tr
                key={unsettledItem?.id}
                className="text-sm reimbursement-table"
                ref={(ref) => {
                  if (index === arr.length - 1 && hasMore) {
                    handleRefChange(ref);
                  }
                }}
                onClick={() => {
                  handleRowClick(unsettledItem?.id);
                }}
              >
                {reportEnabled && columnRequired("reportName") ? (
                  <td className={isCheckedCondition}>
                    <div className="flex items-center">
                      {(type === REIMBURSEMENT_PAGE_TYPE.payments &&
                        !paymentHistory &&
                        !hideCheckBox) ||
                      showMultiSelect ? (
                        <Checkbox
                          checked={
                            showMultiSelect
                              ? selectedReimbursements?.includes(
                                  unsettledItem?.id
                                )
                              : selectedIds.includes(unsettledItem.id)
                          }
                          classes={`pr-3 ${
                            [
                              PAYMENT_STATUSES.processing,
                              PAYMENT_STATUSES.settledViaCard,
                            ].includes(unsettledItem?.status)
                              ? "invisible"
                              : ""
                          }`}
                          onClickHandler={(e) =>
                            showMultiSelect
                              ? rowSelectionHandler(unsettledItem)
                              : handleCheckboxChange(unsettledItem, e)
                          }
                        />
                      ) : null}

                      <div className="flex items-center justify-between w-full">
                        <div className="">
                          <Text translationKey={unsettledItem?.name} />

                          {disableRowCondition ? insufficientBalanceUi() : null}
                        </div>

                        {statusData[unsettledItem?.status] ? (
                          <TextWithTooltipIcon
                            classes="ml-auto"
                            icon={statusData[unsettledItem?.status].icon}
                            tooltipText={
                              statusData[unsettledItem?.status].tooltipText
                            }
                            text=""
                            direction="left"
                            tooltipClasses="text-neutral-500"
                            iconClasses={`w-8 h-8 p-2 text-${
                              statusData[unsettledItem?.status].iconColor
                            }-500 bg-${
                              statusData[unsettledItem?.status].iconColor
                            }-50 rounded-xl border border-${
                              statusData[unsettledItem?.status].iconColor
                            }-100 cursor-pointer`}
                          />
                        ) : null}
                      </div>
                    </div>
                  </td>
                ) : null}

                {columnRequired("claimType") ? (
                  <td className={isCheckedCondition}>
                    <div className="flex items-center">
                      {(type === REIMBURSEMENT_PAGE_TYPE.payments &&
                        !paymentHistory &&
                        !hideCheckBox) ||
                      showMultiSelect ? (
                        <Checkbox
                          checked={
                            showMultiSelect
                              ? selectedReimbursements?.includes(
                                  unsettledItem?.id
                                )
                              : selectedIds?.includes(unsettledItem?.id)
                          }
                          classes={`pr-3 ${
                            [
                              PAYMENT_STATUSES.processing,
                              PAYMENT_STATUSES.settledViaCard,
                            ].includes(unsettledItem?.status)
                              ? "invisible"
                              : ""
                          }`}
                          onClickHandler={(e) =>
                            showMultiSelect
                              ? rowSelectionHandler(unsettledItem)
                              : handleCheckboxChange(unsettledItem, e)
                          }
                        />
                      ) : null}

                      {unsettledItem.type === REIMBURSEMENT_TYPE.MILEAGE ? (
                        <ProfileWidget
                          iconComponent={
                            <Icon
                              name="Location"
                              className="flex items-center justify-center p-2 text-sm border-2 rounded-full w-9 h-9 bg-success-50 text-success-500 border-success-100"
                            />
                          }
                          avatarSize="sm"
                          textClasses="font-bold "
                          name="reimbursement.createReimbursement.distanceTravelledWithMiles"
                          nameTranslationProps={{
                            distance: unsettledItem.distanceTravelled,
                          }}
                        >
                          {disableRowCondition
                            ? insufficientBalanceUi()
                            : unsettledItem?.duplicateReimbursementId
                              ? duplicateReimbursementUi()
                              : null}
                        </ProfileWidget>
                      ) : (
                        <ProfileWidget
                          img={unsettledItem.merchant?.avatarUrl}
                          textClasses="font-semibold "
                          avatarSize="md"
                          tooltipClasses="w-15.5"
                          name={unsettledItem?.merchant?.name}
                        >
                          {" "}
                          {disableRowCondition
                            ? insufficientBalanceUi()
                            : unsettledItem?.duplicateReimbursementId
                              ? duplicateReimbursementUi()
                              : null}
                        </ProfileWidget>
                      )}

                      {statusData[unsettledItem?.status] ? (
                        <TextWithTooltipIcon
                          classes="ml-auto"
                          icon={statusData[unsettledItem?.status].icon}
                          tooltipText={
                            statusData[unsettledItem?.status].tooltipText
                          }
                          text=""
                          direction="left"
                          tooltipClasses="text-neutral-500"
                          iconClasses={`w-8 h-8 p-2 text-${
                            statusData[unsettledItem?.status].iconColor
                          }-500 bg-${
                            statusData[unsettledItem?.status].iconColor
                          }-50 rounded-xl border border-${
                            statusData[unsettledItem?.status].iconColor
                          }-100 cursor-pointer`}
                        />
                      ) : null}
                    </div>
                  </td>
                ) : null}
                {columnRequired("amount") ? (
                  <td className={`text-right ${isCheckedCondition} `}>
                    <div className="font-semibold">
                      {amountToCurrency(
                        unsettledItem?.approvedAmount?.value ??
                          unsettledItem?.amountToBePaid,
                        unsettledItem?.approvedAmount?.currency ??
                          defaultCurrency
                      )}
                    </div>
                  </td>
                ) : null}
                {columnRequired("noOfClaims") && reportEnabled ? (
                  <td className={isCheckedCondition}>
                    <Text
                      translationKey={
                        (unsettledItem?.totalClaims ?? 0) -
                        (unsettledItem?.rejectedClaims ?? 0)
                      }
                      classes="text-sm font-semibold text-neutral-800"
                    />
                  </td>
                ) : null}
                {columnRequired("submissionDate") ? (
                  <td className={isCheckedCondition}>
                    <Text
                      translationKey={dateToString(
                        unsettledItem?.submissionDate ||
                          unsettledItem?.createdAt
                      )}
                      classes="text-sm font-semibold text-neutral-800"
                    />
                  </td>
                ) : null}

                {columnRequired("transactionDate") ? (
                  <td className={isCheckedCondition}>
                    <Text
                      translationKey={dateToString(
                        unsettledItem?.transactionDate ||
                          unsettledItem?.travelDate
                      )}
                      classes="text-sm font-semibold text-neutral-800"
                    />
                  </td>
                ) : null}
                {showStatus && columnRequired("status") ? (
                  <td className={isCheckedCondition}>
                    <div>
                      <Badge
                        variant={
                          unsettledItem
                            ? STATUS_COLORS[
                                unsettledItem?.status?.toLowerCase()
                              ]?.color
                            : "success"
                        }
                        translationKey={
                          STATUS_COLORS[unsettledItem?.status?.toLowerCase()]
                            ?.name
                        }
                        classes="w-15 h-6 text-xs"
                      />
                    </div>
                  </td>
                ) : null}

                {columnRequired("linkedTo") ? (
                  <td className={isCheckedCondition}>
                    <div className="text-sm font-semibold text-neutral-800">
                      {unsettledItem?.linkedTo?.name}
                    </div>
                    <div className="text-xs font-medium text-neutral-500">
                      {unsettledItem?.linkedTo?.title}
                    </div>
                  </td>
                ) : null}

                {columnRequired("description") && reportEnabled ? (
                  <td className={isCheckedCondition}>
                    <Text translationKey={unsettledItem?.description} />
                  </td>
                ) : null}
              </tr>
            );
          }
        )}
        {paginationData.isFetching ? (
          <tr className="flex items-center text-center">
            {!reportEnabled ? (
              <td className="flex items-center gap-2 ">
                <LoaderSkeleton size={[20, 20]} />
                <LoaderSkeleton type="circle" />
                <LoaderSkeleton size={[20, 80]} />
              </td>
            ) : (
              <td>
                <LoaderSkeleton fullWidth />
              </td>
            )}

            <td>
              <LoaderSkeleton fullWidth />
            </td>
            <td>
              <LoaderSkeleton fullWidth />
            </td>
          </tr>
        ) : null}
      </Table>

      {stepPaginationEnable && data.length ? (
        <StepPaginationButtons
          currentPage={paginationData.pageNum}
          total={paginationData.total}
          limit={paginationData.limit}
          handleLeftButton={() => {
            paginationData.updatePageNum(-1);
          }}
          handleRightButton={() => {
            paginationData.updatePageNum(1);
          }}
          leftDisabled={prevDisabled}
          rightDisabled={nextDisabled}
        />
      ) : null}
    </div>
  );
}

ReimbursementPaymentsSliderTable.propTypes = {
  headerSettings: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  setTotalAmount: PropTypes.func,
  updateCount: PropTypes.func,
  reportEnabled: PropTypes.bool,
  type: PropTypes.string,
  paymentHistory: PropTypes.bool,
  userReimbursementFilterOptions: PropTypes.object,
  userId: PropTypes.number,
  reimbursementIds: PropTypes.array,
  setReimbursementIds: PropTypes.func,
  onScroll: PropTypes.func,
  pageType: PropTypes.string,
  showMultiSelect: PropTypes.bool,
  multiSelectData: PropTypes.object,
  stepPaginationEnable: PropTypes.bool,
  paginationData: PropTypes.object,
  sorting: PropTypes.object,
  setSorting: PropTypes.func,
  containerClasses: PropTypes.string,
  hasMore: PropTypes.bool,
  setDisablePayCta: PropTypes.func,
};
