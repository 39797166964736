import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchAndSelectCard,
  fetchCardBudgetSpent,
  resetEditedLimitData,
  setEditedLimitData,
} from "@/store/reducers/cards";

import {
  editedLimitDataSelector,
  isFetchingSelectedCardSelector,
  selectedCardProviderDataSelector,
  selectedCardSelector,
} from "@/store/selectors/cards";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";
import { useForm } from "@/utils/useForm";
import { amountToCurrency, dateToString } from "@/utils/common";

import { CARD_FREQUENCY_CORE, CARD_USAGE_TYPES } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { API_KEYS_MAPPING } from "@/api/apiKeys";

import FrequencyOrderNote from "./EditLimitReviewSlider/FrequencyOrderNote";

function CardEditLimit({ setOnClose }) {
  const dispatch = useDispatch();

  const ref = useLeftHeaderTitle({
    title: "cards.listingFields.limit.tooltip",
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const cardId = searchParams.get(SLIDERS_SEARCH_PARAMS.cards.editLimit);

  const card = useSelector(selectedCardSelector);
  const cardUsageType = card?.cardUsageType;
  const isFetchingCardDetails = useSelector(isFetchingSelectedCardSelector);

  const cardProviderInfo = useSelector(selectedCardProviderDataSelector);
  const supportedFrequencies =
    cardProviderInfo?.config?.supportedFrequencies || {};

  const allState = useLoadingErrorInjector({
    apiKey: API_KEYS_MAPPING.CARDS_GET,
    showLoader: false,
    error: {
      classes: "h-full flex items-center justify-center",
    },
  });

  const budgetTypeOptions = useMemo(() => {
    return Object.entries(supportedFrequencies)?.map((frequencyEntries) => ({
      label: frequencyEntries[0],
      value: frequencyEntries[1],
    }));
  }, [supportedFrequencies]);

  const budgetType = card?.frequency;
  const tempLimit = card?.tempLimit;
  const amount = card?.limit;
  const totalSpent = card?.totalSpent;
  const cardBudgetId = card?.cardBudgetId;
  const editedData = useSelector(editedLimitDataSelector);
  const selectedCardProvider = useSelector(selectedCardProviderDataSelector);
  const selectedCardProviderConfig = selectedCardProvider?.config;
  const supportsTempLimit = selectedCardProviderConfig?.supportsTempLimit;

  const initialFormValue = {
    amount: {
      value: editedData?.amount || amount,
      errorStatement: {
        minNumber: "cards.cardDetails.editLimitDetails.minAmountError",
      },
    },
    budgetType: {
      value: editedData?.budgetType || budgetType,
      validate: {
        required: true,
      },
      errorStatement: {
        required: "cards.cardDetails.editLimitDetails.errorStatement",
      },
    },
    tempLimit: {
      value: editedData?.tempLimit || tempLimit,
    },
    reason: {
      value: editedData?.reason || "",
      validate: {
        required: true,
      },
      errorStatement: {
        required: "cards.cardDetails.editLimitDetails.errorStatement",
      },
    },
  };

  useEffect(() => {
    dispatch(fetchAndSelectCard({ cardId }));

    setOnClose((searchParamsArray) => {
      if (!searchParamsArray.includes(SLIDERS_SEARCH_PARAMS.cards.editLimit)) {
        dispatch(resetEditedLimitData());
      }
    });
  }, []);

  const editLimitReviewHandler = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.cards.editLimitReview, cardId);
    setSearchParams(searchParams);
  };

  const onSave = () => {
    if (values.frequency === CARD_FREQUENCY_CORE.ONE_TIME) {
      values.tempLimit = false;
    }
    const updatedValues = {
      ...editedData,
      ...values,
      amount: parseInt(values?.amount, 10),
      limit: values.tempLimit
        ? parseInt(card?.limit, 10)
        : parseInt(values?.amount, 10),
      card_provider_id: selectedCardProvider?.id,
    };

    dispatch(setEditedLimitData(updatedValues));
    editLimitReviewHandler();
  };

  function onCancel() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.editLimit);
    setSearchParams(searchParams);
  }

  const { handleChange, values, errors, handleSubmit } = useForm(
    initialFormValue,
    onSave
  );
  const displayAmountAllocationDescription =
    selectedCardProviderConfig?.showAmountUnallocationDescription &&
    card?.limit > values?.amount;

  const disableContinueButton =
    !values?.amount ||
    (cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
      ? values?.amount < card?.limit
      : values?.amount < totalSpent);

  function getLimitChangeTextByFrequency() {
    const limitChangeFrequencyText = "Apply the limit change for ";

    switch (values?.budgetType) {
      case CARD_FREQUENCY_CORE.MONTHLY:
        return limitChangeFrequencyText.concat("this month");

      case CARD_FREQUENCY_CORE.ONE_TIME:
        return limitChangeFrequencyText.concat("one time");

      case CARD_FREQUENCY_CORE.DAILY:
        return limitChangeFrequencyText.concat("today");

      case CARD_FREQUENCY_CORE.WEEKLY:
        return limitChangeFrequencyText.concat("this week");

      default:
        break;
    }
  }

  useEffect(() => {
    if (cardBudgetId) {
      dispatch(
        fetchCardBudgetSpent({ cardBudgetId, window: values?.budgetType })
      );
    }
  }, [values?.budgetType]);

  if (isFetchingCardDetails) {
    return <Text translationKey="cards.editLimit.loadingText" classes="px-9" />;
  }

  return (
    <div ref={allState?.attach} className="grow">
      <div className="pb-16 slider-content-core">
        <form onSubmit={handleSubmit} id="edit-limit-slider-form">
          <div className="flex flex-col">
            <Text
              refProp={ref}
              translationKey="cards.listingFields.limit.tooltip"
              classes="text-2xl font-bold text-neutral-800"
            />
          </div>

          <div className="flex flex-col w-full gap-1 mt-8 text-neutral-600">
            <Input
              placeholder="Amount"
              onChange={handleChange}
              value={values?.amount}
              isFocus
              type="number"
              classes="flex items-center justify-between cursor-pointer text-base font-medium text-neutral-800"
              rightText={editedData?.currency}
              name="amount"
              label="cards.cardDetails.editCardDetails.basicDetails.amount.label"
              labelExtraClasses="text-xs font-semibold"
              error={errors?.amount}
            />

            <Text
              translationKey={
                cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
                  ? "cards.cardDetails.editLimitDetails.amountDescInternationalCard"
                  : "cards.cardDetails.editLimitDetails.amountDesc"
              }
              translationProps={
                cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
                  ? { limit: card?.limit, currency: card?.currency }
                  : {
                      spentAmount: card?.totalSpent,
                      currency: card?.currency,
                    }
              }
              classes={`text-xs font-medium  ${
                disableContinueButton ? "text-danger-600" : "text-neutral-800"
              } `}
            />

            {displayAmountAllocationDescription ? (
              <Text
                translationKey="cards.cardDetails.editLimitDetails.amountFieldDescription"
                translationProps={{
                  amount: (card?.limit ?? 0) - (card?.totalSpent ?? 0),
                  currency: card?.currency,
                }}
                classes="font-medium text-xs text-neutral-700"
              />
            ) : null}
          </div>
          <div className="flex flex-col w-full gap-6 mt-5">
            <div className="mt-[3px]">
              <VpSelect
                options={budgetTypeOptions}
                name="budgetType"
                menuPosition="absolute"
                valueKey="value"
                insideForm
                optionsDisplayKey="label"
                handleChange={handleChange}
                disabled={budgetTypeOptions?.length === 1}
                value={values?.budgetType}
                translate
                error={errors?.budgetType}
                label="cards.cardDetails.editLimitDetails.frequency"
                labelStyleClasses="text-xs font-semibold"
              />
            </div>

            <FrequencyOrderNote
              fromFrequency={card?.frequency}
              toFrequency={values?.budgetType}
            />

            {supportsTempLimit ? (
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <Text translationKey={getLimitChangeTextByFrequency()} />
                  {values?.tempLimit ? (
                    <Text
                      translationKey="cards.editLimit.recurMessage"
                      translationProps={{
                        amountCurrency: amountToCurrency(
                          card?.refreshLimit !== 0
                            ? card?.refreshLimit
                            : card?.limit,
                          card?.currency
                        ),
                        frequency: card?.frequency,
                        nextRecurAt: dateToString(card?.nextRecurAt),
                      }}
                      classes="text-xs font-medium text-neutral-500"
                    />
                  ) : null}
                </div>

                <Switch
                  name="tempLimit"
                  value={
                    values.budgetType === CARD_FREQUENCY_CORE.NON_RECURRING
                      ? false
                      : values?.tempLimit
                  }
                  handleChange={handleChange}
                  insideForm
                  disabled={
                    card?.frequency !== values?.budgetType ||
                    values?.budgetType === CARD_FREQUENCY_CORE.ONE_TIME
                  }
                  id="note-disabled"
                />

                {card?.frequency !== values?.budgetType ||
                values?.budgetType === CARD_FREQUENCY_CORE.ONE_TIME ? (
                  <Tooltip direction="top" id="note-disabled">
                    <Text
                      translationKey={
                        card?.frequency !== values?.budgetType
                          ? "cards.editLimit.tooltipTexts.budgetTypeChanged"
                          : "cards.editLimit.tooltipTexts.oneTime"
                      }
                    />
                  </Tooltip>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-5 mt-11">
            <Text
              classes="text-lg"
              translationKey="cards.cardDetails.editLimitDetails.increaseLimitQuestionnaire"
            />

            <Input
              name="reason"
              value={values?.reason}
              onChange={handleChange}
              label="cards.cardDetails.editLimitDetails.enterComment"
              placeholder="cards.cardDetails.editLimitDetails.enterComment"
              classes="text-xs font-semibold text-neutral-600"
              error={errors?.reason}
            />
          </div>
        </form>
      </div>
      <div className="fixed px-3 py-5 slider-footer">
        <div className="flex items-center justify-end gap-3">
          <Button
            label="cards.vCards.createCardSlider.cancel"
            classes="w-[160px] text-neutral-500 font-medium"
            variant="tertiary"
            onClick={onCancel}
          />

          <Button
            label="cards.cardDetails.editLimitDetails.request"
            classes="w-[160px] text-white font-medium"
            variant="primary"
            btnType="submit"
            form="edit-limit-slider-form"
            disabled={disableContinueButton}
          />
        </div>
      </div>
    </div>
  );
}

CardEditLimit.propTypes = {
  setOnClose: PropTypes.func,
};

export default CardEditLimit;
