import { MODULES } from "@/utils/constants/app";

import { CARD_TYPE } from "./Cards";
import { ROUTES } from "./routes";

export const ORDER_NEW_CARD_REQUST_STATUS = {
  APPROVED: "approved",
  PENDING: "pending",
  REJECT: "rejected",
};

export const ORDER_NEW_CARD_REQUEST_STATUS_CONFIG = {
  [ORDER_NEW_CARD_REQUST_STATUS.APPROVED]: {
    statusText: {
      label: "Approved",
      labelColor: "text-success-600",
      appendText: "approvals done",
    },
    progressVariant: "success",
  },
  [ORDER_NEW_CARD_REQUST_STATUS.REJECT]: {
    statusText: {
      label: "Rejected",
      labelColor: "text-danger-500",
      appendText: "level rejected",
    },
    progressVariant: "danger",
    note: {
      titleText: "The amount request for the card is too high",
      bgColor: "bg-danger-50",
      borderColor: "border-danger-300",
      iconName: "",
    },
  },
  [ORDER_NEW_CARD_REQUST_STATUS.PENDING]: {
    statusText: {
      label: "Awaiting approvals",
      labelColor: "text-warning-600",
      appendText: "approvals done",
    },
    progressVariant: "warning",
  },
};

export const CARDS_REQUEST_TABLE_KEYS = {
  REQUEST_TYPE: "requestType",
  AMOUNT: "request.amount",
  REQUEST_REASON: "request.reason",
  APPROVAL_STATUS: "approvalStatus",
  LINKED_TO: "linkedTo",
  ACTIONS: "actions",
};

export const CARDS_REQUEST_TABLE_HEADER_CONFIG = {
  [CARDS_REQUEST_TABLE_KEYS.REQUEST_TYPE]: {
    id: CARDS_REQUEST_TABLE_KEYS.REQUEST_TYPE,
    label: "Request type",
    colWidth: 350,
  },
  [CARDS_REQUEST_TABLE_KEYS.AMOUNT]: {
    id: CARDS_REQUEST_TABLE_KEYS.AMOUNT,
    label: "Amount",
    colWidth: 200,
  },
  [CARDS_REQUEST_TABLE_KEYS.REQUEST_REASON]: {
    id: CARDS_REQUEST_TABLE_KEYS.REQUEST_REASON,
    label: "Reason for request",
    colWidth: 315,
  },
  [CARDS_REQUEST_TABLE_KEYS.APPROVAL_STATUS]: {
    id: CARDS_REQUEST_TABLE_KEYS.APPROVAL_STATUS,
    label: "Approval status",
    colWidth: 200,
  },
  [CARDS_REQUEST_TABLE_KEYS.LINKED_TO]: {
    id: CARDS_REQUEST_TABLE_KEYS.LINKED_TO,
    label: "Linked to",
    colWidth: 200,
  },
  [CARDS_REQUEST_TABLE_KEYS.ACTIONS]: {
    id: CARDS_REQUEST_TABLE_KEYS.ACTIONS,
    label: "Actions",
    colWidth: 200,
  },
};

export const CARD_REQUEST_TYPE = {
  CARD_REQUEST: "CardRequest",
  TOP_UP_REQUEST: "TopUpRequest",
  PHYSICAL_CARD_ORDER: "PhysicalCardRequest",
};

export const REQUEST_TIMELINE_ITEM_TYPE = {
  REQUESTED: "requested",
  APPROVAL: "approval",
};

// When activityType is approval

export const REQUEST_TIMELINE_ITEM_APPROVAL_STATUS_BASED_UI_CONFIG = {
  approved: {
    iconName: "CircleCheck",
    iconBgColor: "bg-success-100",
    iconStrokeColor: "text-success-600",
  },
  pending: {
    iconName: "Pace",
    iconBgColor: "bg-warning-100",
    iconStrokeColor: "text-warning-500",
  },
  rejected: {
    iconName: "Close",
    iconBgColor: "bg-danger-100",
    iconStrokeColor: "text-danger-500",
  },
};

export const REQUEST_TIMELINE_ITEM_TYPE_BASED_UI_CONFIG = {
  [REQUEST_TIMELINE_ITEM_TYPE.REQUESTED]: {
    iconName: "CallReceived",
    iconBgColor: "bg-neutral-100",
    iconStrokeColor: "text-neutral-500",
  },
  [REQUEST_TIMELINE_ITEM_TYPE.APPROVAL]: {
    iconName: null,
    iconBgColor: null,
    iconStrokeColor: null,
    variations: REQUEST_TIMELINE_ITEM_APPROVAL_STATUS_BASED_UI_CONFIG,
  },
};

export const CARD_APPROVAL_REQUEST_TYPE = {
  PHYSICAL: "PhysicalCardRequest",
  VIRTUAL: "VirtualCardRequest",
  TOP_UP: "TopUpRequest",
};

export const BE_FE_KEY_ORDER_TYPE_MAPPING = {
  bulkOrderNonPersonalized: "BULK_NON_PERSONALISED_ORDER",
  bulkOrderPersonalized: "BULK_PERSONALISED_ORDER",
  personalized: "PERSONALISED",
  virtualCard: "VIRTUAL_CARD",
};

export const ORDER_TYPE_CONFIG = {
  [BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderPersonalized]:
    "Bulk order: Personalised",
  [BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderNonPersonalized]:
    "Bulk order: Non-personalised",
  [BE_FE_KEY_ORDER_TYPE_MAPPING.personalized]: "Personalised card",
  [BE_FE_KEY_ORDER_TYPE_MAPPING.virtualCard]: "Virtual card",
};

export const CARD_REQUEST_TYPE_CONFIG = {
  [CARD_APPROVAL_REQUEST_TYPE.PHYSICAL]: {
    label: "New Card",
    classes: "text-success-600 font-normal",
    iconName: "RoundedCreditCard",
  },
  [CARD_APPROVAL_REQUEST_TYPE.VIRTUAL]: {
    label: "New Card",
    classes: "text-success-600 font-normal",
    iconName: "RoundedCloud",
  },
  [CARD_APPROVAL_REQUEST_TYPE.TOP_UP]: {
    [CARD_TYPE.PHYSICAL]: {
      label: "Card Limit",
      classes: "text-warning-500 font-normal",
      iconName: "RoundedEditCreditCard",
    },
    [CARD_TYPE.VIRTUAL]: {
      label: "Card Limit",
      classes: "text-warning-500 font-normal",
      iconName: "RoundedEditCloud",
    },
  },
};

export const ACTION_CENTRE_APPROVAL_TYPE = {
  card: "card_approval",
};

export const ACTION_CENTRE_PAGE_CONTEXT = {
  BILL_PAY: "billPay-actionCentre",
  PAYROLL: "payroll-actionCentre",
  REIMBURSEMT: "reimbursement-actionCentre",
  REPORT: "report-actionCentre",
  CARD: "card-actionCentre",
};

export const ACTION_CENTRE_TABS = [
  {
    name: "dashboard.quickAccess.headings.cards",
    count: 0,
    path: ROUTES.myVolopay.actionCentre.cards.absolutePath,
    currKey: "CardRequest",
    module: MODULES.CARDS,
    key: 1,
  },
  {
    name: "dashboard.quickAccess.headings.payments",
    count: 0,
    path: ROUTES.myVolopay.actionCentre.billPay.absolutePath,
    currKey: "PurchaseBill",
    module: MODULES.BILL_PAY,
    key: 2,
  },
  {
    name: "dashboard.quickAccess.headings.reimbursements",
    count: 0,
    path: ROUTES.myVolopay.actionCentre.reimbursement.absolutePath,
    currKey: "Reimbursement",
    module: MODULES.REIMBURSEMENTS,
    key: 3,
  },
  {
    name: "accounting.transactions.payroll.title",
    count: 0,
    path: ROUTES.myVolopay.actionCentre.payroll.absolutePath,
    currKey: "Payroll",
    module: MODULES.PAYROLL,
    key: 4,
  },
];

export const CARD_REQUEST_STATUS = {
  PROCESSING: "processing",
  AWAITING_FUNDS: "awaiting_funds",
  FAILED: "failed",
};

export const CARD_REQUEST_TYPE_CELL_TOOLTIPS_CONFIG = {
  [CARD_REQUEST_STATUS.PROCESSING]: {
    icon: "Schedule",
    classes:
      "w-6 h-6 p-1 border rounded-lg text-warning-300 bg-warning-50 border-warning-100",
    text: "myVolopay.actionCentre.sliders.requestSliders.requestProcessing",
  },
  [CARD_REQUEST_STATUS.AWAITING_FUNDS]: {
    icon: "MintMark",
    classes:
      "w-6 h-6 p-1 border rounded-lg text-warning-300 bg-warning-50 border-warning-100",
    text: "myVolopay.actionCentre.sliders.requestSliders.limit.inSufficientFundsText",
  },
  [CARD_REQUEST_STATUS.FAILED]: {
    icon: "ReportFailed",
    classes:
      "w-6 h-6 p-1 border rounded-lg text-danger-300 bg-danger-50 border-danger-100",
    text: "myVolopay.actionCentre.sliders.requestSliders.limit.failedLimitRequest",
  },
};
