import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Alert from "@/components/core/Alert";
import Icon from "@/components/core/Icon";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

/**
 * This is most reused component on the settings page.
 *
 * It's job is to render:
 * 1. title
 * 2. description
 * 3. switch (if applicable)
 * 4. clickable slider icon (if applicable)
 *
 * It's used for both the left and right side parts a setting row
 */
export default function SettingText({
  title = "",
  description = "",
  titleClasses = "text-neutral-800 font-semibold text-base",
  descriptionClasses = "text-neutral-500 font-medium text-sm",
  key = "",
  permissionType = "",
  showSwitch = false,
  isSwitchOn = false,
  isSwitchLoading = false,
  disableMessage = "",

  // if both of these are null, then no slider icon will be rendered
  sliderId,
  sliderType,
  handleChange = () => {},
  sectionClasses,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSliderClick = () => {
    searchParams.set(sliderType, sliderId ?? true);
    if (permissionType) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.payrollPayments.permissionType,
        permissionType
      );
    }
    if (sliderType || sliderId) setSearchParams(searchParams);
  };

  const showSliderIcon =
    (sliderType || sliderId) && (!showSwitch || isSwitchOn); // don't show if switch is applicable but off

  return (
    <div>
      <div
        className={`flex items-center justify-between gap-6 ${
          showSwitch && !isSwitchOn
            ? ""
            : sectionClasses?.includes("cursor-not-allowed")
              ? "cursor-not-allowed"
              : "cursor-pointer"
        } ${sectionClasses ? sectionClasses : ""}`}
        key={key}
        onClick={showSwitch && !isSwitchOn ? null : handleSliderClick}
      >
        {/* left side - text, switch */}
        <div className="flex flex-col gap-1">
          {/* title with? switch */}
          <div className="flex items-center gap-2">
            {showSwitch ? (
              <>
                <Switch
                  disabled={isSwitchLoading}
                  value={isSwitchOn}
                  handleChange={(e, event) => {
                    event.stopPropagation();
                    handleChange(e);
                  }}
                />
                {/* {isSwitchLoading ? (
                // TECH_DEBT animate this
                <Icon name="SpinLoader" className="w-4 h-4" />
              ) : null} */}
              </>
            ) : null}

            {typeof title === typeof (<></>) ? (
              title
            ) : (
              <Text translationKey={title} classes={titleClasses} />
            )}
          </div>

          {/* description */}
          <Text translationKey={description} classes={descriptionClasses} />
        </div>

        {/* slider icon (if applicable) */}
        {showSliderIcon ? (
          <Icon name="ArrowForward" className="hover:cursor-pointer shrink-0" />
        ) : null}
      </div>
      {disableMessage ? (
        <Alert
          wrapperDivClass="justify-start"
          iconClasses="w-6 h-6 text-neutral-500"
          variant="warning"
          classes="mt-5"
          description={disableMessage}
        />
      ) : null}
    </div>
  );
}

SettingText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  titleClasses: PropTypes.string,
  descriptionClasses: PropTypes.string,
  showSwitch: PropTypes.bool,
  isSwitchOn: PropTypes.bool,
  isSwitchLoading: PropTypes.bool,
  key: PropTypes.string,
  permissionType: PropTypes.string,
  sliderId: PropTypes.string,
  sliderType: PropTypes.string,
  handleChange: PropTypes.func,
  disableMessage: PropTypes.string,
  sectionClasses: PropTypes.string,
};
