import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  fetchPendingRequestList,
  resetPendingListAndPagination,
} from "@/store/reducers/company";
import { fetchAndSelectUser } from "@/store/reducers/user";

import { actionCentreTabsSelector } from "@/store/selectors/actionCentre";
import { reimbursementReportsEnabledSelector } from "@/store/selectors/client";
import {
  hasMorePendingRequestSelector,
  isFetchingPeopleListSelector,
  pendingCardRequestFilterKeys,
  pendingReimbursementFilterKeys,
  pendingRequestListSelector,
  totalPendingCardsRequestSelector,
} from "@/store/selectors/company";
import { sliderAppliedFilterSelector } from "@/store/selectors/filters";
import {
  availableModulesSelector,
  selectedUserSelector,
  userSelector,
} from "@/store/selectors/user";

import Filters from "@/components/core/Filters";
import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Table from "@/components/core/Table";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import Limit from "@/components/Cards/CardsTable/CardTableComponents/Limit";
import CardTableLoader from "@/components/Cards/CardsTable/CardTableLoader";
import ApprovalProgressCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ApprovalProgressCell";
import { convertFilters } from "@/utils/filters";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { GET_KEY_PAGE_TYPE, PAGE_TYPE } from "@/utils/constants/payments";
import {
  AVAILABLE_FILTER_KEYS,
  FILTER_TRIGGER_CONTEXT,
  FILTER_TYPES,
} from "@/utils/constants/filters";
import { amountToCurrency, dateToString } from "@/utils/common";

import { MODULES } from "@/utils/constants/app";
import { CARD_REQUEST_TYPE } from "@/constants/ActionCentre";
import { CARDS_TABLE_HEADER_KEYS } from "@/constants/Cards";
import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import {
  BE_FE_REQUEST_TYPE_CARD_REQUEST,
  CARD_REQUEST_TYPES,
  MODULE_REQUEST_TYPES,
  PENDING_REQUEST_TABS_KEYS,
} from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import AmountCellWithConvervesion from "./Cells/AmountCellWithConvervesion";
import BillPayRequestTypeCell from "./Cells/BillPayRequestTypeCell";
import CardRequestTypeCell from "./Cells/CardRequestTypeCell";
import PayrollRequestTypeCell from "./Cells/PayrollRequestTypeCell";
import ReimbursementRequestTypeCell from "./Cells/ReimbursementRequestTypeCell";

export default function PendingRequest({
  showTabs = true,
  stepPaginationEnable,
  stepPaginationLimit = 3,
  tableExternalClasses,
  showDescriptiveSection,
  sliderId,
  blockFlow,
  departmentChangeFlow,
}) {
  const cardsModuleFilterKeysConfig = useSelector(pendingCardRequestFilterKeys);
  const reimbursementFilterKeysConfig = useSelector(
    pendingReimbursementFilterKeys
  );
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);
  const availableModules = useSelector(availableModulesSelector);

  const availTabs = {
    [PENDING_REQUEST_TABS_KEYS.CARDS]: {
      name: "Cards",
      request: { name: MODULE_REQUEST_TYPES.CARD_REQUEST, type: null },
      key: PENDING_REQUEST_TABS_KEYS.CARDS,
      module: MODULES.CARDS,
      filters: cardsModuleFilterKeysConfig,
    },
    [PENDING_REQUEST_TABS_KEYS.BILL_PAY]: {
      name: "Billpay",
      request: { name: MODULE_REQUEST_TYPES.BILL_PAY_REQUEST, type: null },
      key: PENDING_REQUEST_TABS_KEYS.BILL_PAY,
      module: MODULES.BILL_PAY,
    },
    [PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT]: {
      name: reportEnabled ? "Report" : "Reimbursement",
      request: { name: MODULE_REQUEST_TYPES.REIMBURSEMENT_REQUEST },
      key: PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT,
      filters: reportEnabled
        ? reimbursementFilterKeysConfig?.filter(
            (data) => data?.type === FILTER_TYPES.searchAndFilter
          )
        : reimbursementFilterKeysConfig,
      module: MODULES.REIMBURSEMENTS,
    },
    [PENDING_REQUEST_TABS_KEYS.PAYROLL]: {
      name: "Payroll",
      request: { name: MODULE_REQUEST_TYPES.PAYROLL_REQUEST },
      key: PENDING_REQUEST_TABS_KEYS.PAYROLL,
      module: MODULES.PAYROLL,
    },
  };

  const allTab = {
    name: "All",
    request: { name: "all", type: null },
    key: "all",
  };

  const loggedInUser = useSelector(userSelector);
  const actionCentreTabs = useSelector(actionCentreTabsSelector);

  const [tabs, setTabs] = useState(availTabs);
  const [currentTab, setCurrentTab] = useState(
    showTabs ? Object.values(tabs)[0] : allTab
  );
  const [tabKey, setTabKey] = useState(currentTab?.key);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const viewedUserId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.people.id
  );

  const selectedUser = useSelector(selectedUserSelector);

  const myPendingRequests =
    !viewedUserId || selectedUser?.id === loggedInUser?.id;

  useEffect(() => {
    if (viewedUserId)
      dispatch(
        fetchAndSelectUser({
          userId: viewedUserId,
        })
      );
  }, []);

  useEffect(() => {
    const checkTabs = availTabs;

    Object.keys(availTabs).forEach((tab) => {
      if (!actionCentreTabs.map((tempTab) => tempTab.currKey).includes(tab)) {
        delete checkTabs[tab];
      }
    });
    Object.keys(checkTabs)?.forEach((key) => {
      if (!availableModules?.includes(checkTabs[key]?.module))
        delete checkTabs[key];
    });

    setTabs(checkTabs);
    setCurrentTab(showTabs ? Object.values(checkTabs)[0] : allTab);
    setTabKey(Object.values(checkTabs)[0]?.key);
  }, [actionCentreTabs, JSON.stringify(availableModules)]);

  const pendingList = useSelector(pendingRequestListSelector);

  const updatedPendingList = useMemo(() => {
    return pendingList.map((pendingRow) => pendingRow);
  }, [pendingList]);

  const hasMore = useSelector(hasMorePendingRequestSelector);

  const isFetching = useSelector(isFetchingPeopleListSelector);

  const total = useSelector(totalPendingCardsRequestSelector);

  const appliedFilter = useSelector(sliderAppliedFilterSelector);

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: { currentTab, ...convertFilters(appliedFilter) },
  });

  const showTableRows = stepPaginationEnable ? !isFetching : true;

  const useInfiniteRef = useInfiniteScroll(onScroll);
  const handleRefChange = stepPaginationEnable ? () => {} : useInfiniteRef;

  useEffect(() => {
    return () => {
      onReset();
    };
  }, [currentTab]);

  function loadMore() {
    fetchPendingRequest();
  }

  function onScroll() {
    setPageNum((prev) => prev + 1);
  }

  function onReset() {
    dispatch(resetPendingListAndPagination());
  }

  const billpayPayrollClickHandler = (key, id, _extraParams = {}) => {
    const paramName = [
      BILL_PAYROLL_CONTEXT.BILLPAY,
      MODULE_REQUEST_TYPES.BILL_PAY_REQUEST,
    ]?.includes(key)
      ? SLIDERS_SEARCH_PARAMS.payments.id
      : SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId;
    Object.entries({
      [paramName]: id,
      [SLIDERS_SEARCH_PARAMS.payments.sliderType]: PAGE_TYPE.approvals,
      [SLIDERS_SEARCH_PARAMS.payments.pageType]: GET_KEY_PAGE_TYPE.pending,
      [SLIDERS_SEARCH_PARAMS.payments.sliderType]: PAGE_TYPE.approvals,
      [SLIDERS_SEARCH_PARAMS.payments.pageType]: GET_KEY_PAGE_TYPE.pending,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]: paramName,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled]: true,
    }).forEach(([_key, value]) => searchParams.append(_key, value));
    setSearchParams(searchParams);
  };

  const cardsClickHandler = (fieldInfo) => {
    const searchParamKey =
      fieldInfo?.requestType === CARD_REQUEST_TYPE.TOP_UP_REQUEST
        ? SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest
        : SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest?.[
            BE_FE_REQUEST_TYPE_CARD_REQUEST[fieldInfo?.requestType ?? ""]
          ];

    searchParams.append(searchParamKey, fieldInfo?.id);
    setSearchParams(searchParams);
  };

  const reimbursementClickHandler = (id) => {
    searchParams.append(
      reportEnabled
        ? SLIDERS_SEARCH_PARAMS.reports.reportId
        : SLIDERS_SEARCH_PARAMS.reimbursements.approveId,
      id
    );
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.reimbursements.pageType,
      GET_KEY_PAGE_TYPE.pending
    );

    setSearchParams(searchParams);
  };

  const allPendingRequestClickHandler = (key, id, fieldInfo) => {
    switch (key) {
      case CARD_REQUEST_TYPES.PHYSICAL_CARD_REQUEST:
      case CARD_REQUEST_TYPES.TOP_UP_REQUEST:
      case CARD_REQUEST_TYPES.VIRTUAL_CARD_REQUEST:
        cardsClickHandler(fieldInfo);
        break;

      case MODULE_REQUEST_TYPES.BILL_PAY_REQUEST:
      case MODULE_REQUEST_TYPES.PAYROLL_REQUEST:
        billpayPayrollClickHandler(key, id);
        break;

      case MODULE_REQUEST_TYPES.REIMBURSEMENT_REQUEST:
        reimbursementClickHandler(id);
        break;

      default:
        break;
    }
  };

  function fetchPendingRequest() {
    const params = { ...convertFilters(appliedFilter) };
    const selectedTabKey = currentTab?.key;
    const loggedInUserId = loggedInUser?.id;

    if (viewedUserId) {
      params.id = viewedUserId;
    } else {
      params.id = loggedInUserId;
    }

    if (departmentChangeFlow) {
      params.department_change_flow = true;
    }

    if (blockFlow) {
      params.block_flow = true;
    }

    if (showTabs) {
      switch (selectedTabKey) {
        case PENDING_REQUEST_TABS_KEYS.CARDS:
          params.request_type = MODULE_REQUEST_TYPES.CARD_REQUEST;
          if (appliedFilter[AVAILABLE_FILTER_KEYS.cardRequestType]) {
            params.card_request_type =
              appliedFilter[AVAILABLE_FILTER_KEYS.cardRequestType]?.value;
          }
          break;

        case PENDING_REQUEST_TABS_KEYS.BILL_PAY:
          params.request_type = MODULE_REQUEST_TYPES?.BILL_PAY_REQUEST;
          break;

        case PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT:
          params.request_type = MODULE_REQUEST_TYPES.REIMBURSEMENT_REQUEST;
          if (appliedFilter[AVAILABLE_FILTER_KEYS?.claimType]) {
            params.reimbursement_claim_type =
              appliedFilter[AVAILABLE_FILTER_KEYS?.claimType]?.value;
          }
          break;

        case PENDING_REQUEST_TABS_KEYS.PAYROLL:
          params.request_type = MODULE_REQUEST_TYPES.PAYROLL_REQUEST;
          break;

        default:
          break;
      }
    }

    if (stepPaginationEnable) {
      params.stepPagination = true;
    }

    if (appliedFilter?.q) {
      params.q = appliedFilter?.q?.value;
    }

    params.page = pageNum;
    params.limit = stepPaginationEnable
      ? stepPaginationLimit
      : PAGINATION_PER_REQUEST_LIMIT - 10;

    dispatch(fetchPendingRequestList(params));
  }

  const headerKeys = useMemo(() => {
    return getRespectiveHeadersByTabKey();
  }, [currentTab]);

  function getRespectiveHeadersByTabKey() {
    switch (tabKey) {
      case PENDING_REQUEST_TABS_KEYS.CARDS:
        return [
          "company.people.pendingRequestTable.cards.requestType",
          "company.people.pendingRequestTable.cards.amount",
        ];

      case PENDING_REQUEST_TABS_KEYS.BILL_PAY:
        return [
          "company.people.pendingRequestTable.billpay.vendor",
          "company.people.pendingRequestTable.billpay.amount",
        ];

      case PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT:
        if (reportEnabled) {
          return [
            "company.people.pendingRequestTable.report.reportName",
            "company.people.pendingRequestTable.report.amount",
          ];
        }
        return [
          "company.people.pendingRequestTable.reimbursement.merchant",
          "company.people.pendingRequestTable.reimbursement.amount",
        ];

      case PENDING_REQUEST_TABS_KEYS.PAYROLL:
        return [
          "company.people.pendingRequestTable.payroll.user",
          "company.people.pendingRequestTable.payroll.amount",
        ];

      default:
        return [
          "company.people.pendingRequestTable.default.pendingRequest",
          "company.people.pendingRequestTable.default.approvers",
        ];
    }
  }

  function getRowsByTabKey(fieldInfo, index, customTab) {
    const key = customTab || tabKey;
    const currentApprovalLevel = fieldInfo?.currentApprovalLevel;
    const previousApprovers = fieldInfo?.previousApprovers;

    const currentApprovalLevelStatus = fieldInfo?.currentApprovalLevelStatus;
    const approvalLevels =
      fieldInfo?.totalNumberOfApprovals || fieldInfo?.approvalLevels;
    const approvalColumn = (
      <td>
        <ApprovalProgressCell
          val={{
            approvalLevels,
            currentApprovalLevel,
            currentApprovalLevelStatus,
            previousApprovers,
          }}
        />
      </td>
    );

    switch (key) {
      case PENDING_REQUEST_TABS_KEYS.CARDS:
        return (
          <tr
            ref={(ref) => {
              if (index === pendingList.length - 1 && hasMore) {
                handleRefChange(ref);
              }
            }}
            onClick={() => {
              cardsClickHandler(fieldInfo);
            }}
          >
            <td className="text-left">
              <CardRequestTypeCell
                cardType={fieldInfo?.cardType}
                cardRequestType={fieldInfo?.requestType}
                name={fieldInfo?.cardName}
                requestDate={fieldInfo?.requestDate}
                cardUsageType={fieldInfo?.cardUsageType}
                nameTextClasses="text-sm font-semibold text-neutral-800"
              />
            </td>

            <td className="text-right">
              <Limit
                amount={fieldInfo?.newLimit ?? fieldInfo?.amount}
                currency={fieldInfo?.currency}
                frequency={fieldInfo?.frequency}
                showFrequency
                amountClasses="text-sm font-semibold text-neutral-800"
                frequencyClasses="text-xs font-medium text-neutral-500"
              />
            </td>
          </tr>
        );

      case PENDING_REQUEST_TABS_KEYS.BILL_PAY:
        return (
          <tr
            ref={(ref) => {
              if (index === pendingList.length - 1 && hasMore) {
                handleRefChange(ref);
              }
            }}
            onClick={() =>
              billpayPayrollClickHandler(currentTab?.key, fieldInfo?.id)
            }
            className={showTabs ? "cursor-pointer" : ""}
          >
            <td className="text-left">
              <BillPayRequestTypeCell
                vendor={fieldInfo.vendor}
                invoiceRequestDate={fieldInfo?.requestDate}
                invoiceRequestType={fieldInfo?.requestType}
                duplicateBillId={fieldInfo?.duplicateBillId}
                showDescriptiveSection={showDescriptiveSection}
              />
            </td>

            <td className="text-right">
              <AmountCellWithConvervesion
                amountTo={fieldInfo?.quote?.totalAmount}
                amountFrom={fieldInfo?.invoiceAmountFrom}
              />
            </td>
          </tr>
        );

      case PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT:
        if (reportEnabled) {
          return (
            <tr
              ref={(ref) => {
                if (index === pendingList.length - 1 && hasMore) {
                  handleRefChange(ref);
                }
              }}
              className="text-sm font-semibold"
              onClick={() => {
                reimbursementClickHandler(fieldInfo?.id);
              }}
            >
              <td>
                <Text translationKey={fieldInfo?.name} />
              </td>
              <td className="text-right">
                <Text
                  translationKey={amountToCurrency(fieldInfo?.totalAmount)}
                />
              </td>
            </tr>
          );
        }
        return (
          <tr
            ref={(ref) => {
              if (index === pendingList.length - 1 && hasMore) {
                handleRefChange(ref);
              }
            }}
            onClick={() => {
              reimbursementClickHandler(fieldInfo?.id);
            }}
            className={showTabs ? "cursor-pointer" : ""}
          >
            <td className="text-left">
              <ReimbursementRequestTypeCell
                distanceTravelled={fieldInfo?.mileage}
                merchant={fieldInfo?.merchant}
                reimbursementRequestDate={dateToString(fieldInfo?.requestDate)}
                reimbursementRequestType={fieldInfo?.type}
                showDescriptiveSection={showDescriptiveSection}
              />
            </td>
            <td className="text-right">
              <AmountCellWithConvervesion
                amountTo={fieldInfo?.amount}
                amountFrom={fieldInfo?.claimAmount}
              />
            </td>
          </tr>
        );

      case PENDING_REQUEST_TABS_KEYS.PAYROLL:
        return (
          <tr
            ref={(ref) => {
              if (index === pendingList.length - 1 && hasMore) {
                handleRefChange(ref);
              }
            }}
            className={showTabs ? "cursor-pointer" : ""}
            onClick={() =>
              billpayPayrollClickHandler(currentTab?.key, fieldInfo?.id)
            }
          >
            <td className="text-left">
              <PayrollRequestTypeCell
                user={fieldInfo?.vendor || fieldInfo?.user}
                payrollRequestDate={fieldInfo?.requestDate}
                showDescriptiveSection={showDescriptiveSection}
              />
            </td>

            <td className="text-right">
              <AmountCellWithConvervesion
                amountTo={fieldInfo?.quote?.totalAmount}
                amountFrom={fieldInfo?.payrollAmountFrom}
              />
            </td>
          </tr>
        );

      case PENDING_REQUEST_TABS_KEYS.ALL:
        return (
          <tr
            ref={(ref) => {
              if (index === pendingList.length - 1 && hasMore) {
                handleRefChange(ref);
              }
            }}
            onClick={() => {
              allPendingRequestClickHandler(
                fieldInfo?.requestType,
                fieldInfo?.id,
                fieldInfo
              );
            }}
          >
            {fieldInfo?.requestType ===
            MODULE_REQUEST_TYPES.REIMBURSEMENT_REQUEST ? (
              <td className="text-left">
                <ReimbursementRequestTypeCell
                  distanceTravelled={fieldInfo?.mileage}
                  merchant={fieldInfo?.merchant}
                  reimbursementRequestDate={dateToString(
                    fieldInfo?.requestDate
                  )}
                  reimbursementRequestType={fieldInfo?.type}
                  showDescriptiveSection
                />
              </td>
            ) : null}
            {fieldInfo?.requestType ===
            MODULE_REQUEST_TYPES.BILL_PAY_REQUEST ? (
              <td className="text-left">
                <BillPayRequestTypeCell
                  vendor={fieldInfo.vendor}
                  invoiceRequestDate={fieldInfo?.requestDate}
                  invoiceRequestType={fieldInfo?.requestType}
                  showDescriptiveSection
                />
              </td>
            ) : null}

            {[
              CARD_REQUEST_TYPES.TOP_UP_REQUEST,
              CARD_REQUEST_TYPES.PHYSICAL_CARD_REQUEST,
              CARD_REQUEST_TYPES.VIRTUAL_CARD_REQUEST,
            ]?.includes(fieldInfo?.requestType) ? (
              <td className="text-left">
                <CardRequestTypeCell
                  cardType={fieldInfo?.cardType}
                  cardRequestType={fieldInfo?.requestType}
                  name={fieldInfo?.cardName}
                  requestDate={fieldInfo?.requestDate}
                  cardUsageType={fieldInfo?.cardUsageType}
                  nameTextClasses="text-sm font-semibold text-neutral-800"
                />
              </td>
            ) : null}
            {approvalColumn}
          </tr>
        );
      default:
        break;
    }
  }

  function handleCurrentTabChange(e) {
    if (stepPaginationEnable || pageNum > 1) {
      setPageNum(1);
    }
    setCurrentTab(e);
    setTabKey(e?.key);
  }

  function getEmptyDataConfigByTabType() {
    const emptyDataConfig = {
      emptyDataTitle: "reimbursement.emptyScreen.approval.title",
      emptyDataDescription: "",
    };

    switch (currentTab?.key) {
      case PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT:
        emptyDataConfig.emptyDataDescription =
          "reimbursement.emptyScreen.approval.desc";
        break;

      case PENDING_REQUEST_TABS_KEYS.BILL_PAY:
        emptyDataConfig.emptyDataDescription =
          "billPay.bill.approvals.emptyTexts.description";
        break;

      case PENDING_REQUEST_TABS_KEYS.PAYROLL:
        emptyDataConfig.emptyDataDescription =
          "billPay.bill.approvals.emptyTexts.descriptionPayroll";
        break;

      default:
        emptyDataConfig.emptyDataTitle =
          "myVolopay.actionCentre.emptyScreen.emptyDataTitle";
        emptyDataConfig.emptyDataDescription =
          "myVolopay.actionCentre.emptyScreen.emptyDataDescription";
        break;
    }

    return emptyDataConfig;
  }

  const headerRef = useLeftHeaderTitle({
    title: "company.people.pendingRequestTable.sliderName",
  });

  return (
    <div
      className={`flex flex-col ${
        tableExternalClasses
          ? tableExternalClasses
          : `${tabKey !== PENDING_REQUEST_TABS_KEYS.ALL ? "p-10" : ""} pt-4`
      } `}
    >
      {showTabs ? (
        <div className="flex flex-col">
          <div ref={headerRef} className="flex flex-col gap-3 mb-10">
            <Text
              translationKey={
                myPendingRequests
                  ? "company.people.pendingRequestTable.sliderName"
                  : "company.people.pendingRequestTable.personSliderName"
              }
              translationProps={{ name: selectedUser?.displayName }}
              classes="text-3xl font-bold"
            />

            <Text
              translationKey="company.people.pendingRequestTable.pendingList"
              classes="text-neutral-500 text-sm"
            />
          </div>
          <div className="mb-7">
            <Tabs
              items={Object.values(tabs)}
              selectedTab={currentTab?.key}
              setCurrentTab={(e) => handleCurrentTabChange(e)}
              mode
            />
          </div>
        </div>
      ) : null}

      {tabs[currentTab?.key]?.filters && showTabs ? (
        <div className="mb-4">
          <Filters
            filters={tabs[currentTab?.key].filters}
            context={FILTER_TRIGGER_CONTEXT.SLIDER}
            sliderConfig={{
              sliderId,
              tabName: currentTab?.key,
            }}
          />
        </div>
      ) : null}

      <Table headerSticky {...getEmptyDataConfigByTabType()}>
        <tr>
          {headerKeys.map((header, index) => {
            return (
              <th
                key={index}
                className={index === 0 ? "text-left" : "text-right"}
              >
                <Text
                  translationKey={header}
                  classes="font-semibold text-xs text-neutral-800"
                />
              </th>
            );
          })}
        </tr>

        {showTableRows
          ? updatedPendingList?.map((fieldInfo, index) => {
              return getRowsByTabKey(fieldInfo, index, currentTab?.key);
            })
          : null}

        {isFetching ? (
          <CardTableLoader
            header={[
              { id: CARDS_TABLE_HEADER_KEYS.CARDS },
              { id: CARDS_TABLE_HEADER_KEYS.CARDS },
            ]}
            loaderCount={
              stepPaginationEnable
                ? stepPaginationLimit
                : PAGINATION_PER_REQUEST_LIMIT - 10
            }
          />
        ) : null}
      </Table>
      {stepPaginationEnable && pendingList?.length > 0 ? (
        <StepPaginationButtons
          currentPage={pageNum}
          total={total}
          limit={stepPaginationLimit}
          handleLeftButton={() => setPageNum((prev) => prev - 1)}
          handleRightButton={() => setPageNum((prev) => prev + 1)}
          leftDisabled={pageNum <= 1}
          rightDisabled={total / pageNum <= stepPaginationLimit}
        />
      ) : null}
    </div>
  );
}

PendingRequest.propTypes = {
  showTabs: PropTypes.bool,
  stepPaginationEnable: PropTypes.bool,
  stepPaginationLimit: PropTypes.number,
  tableExternalClasses: PropTypes.string,
  showDescriptiveSection: PropTypes.bool,
  departmentChangeFlow: PropTypes.bool,
  blockFlow: PropTypes.bool,
};
