import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import MemoNoteIcon from "@/components/common/MemoNoteIcon";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import ReceiptIcon from "@/components/common/ReceiptIcon";
import SubmissionPolicyIcon from "@/components/common/SubmissionPolicyIcon";
import TransactionStatusIcon from "@/components/common/TransactionStatusIcon";
import { amountToCurrency } from "@/utils/common";

import {
  EXPENSE_SETTLEMENT_STATUS,
  GROUPED_EXPENSES_TABLE,
} from "@/constants/expense";

export default function GroupedTableRow({
  setGroupItemRef,
  tableHeaderKey,
  value,
  show = true,
  index,
  UnsettledApplied,
  paginationCallback = () => {},
  lastKeyIndex = null,
  category,
  viewTransactionItem = () => {},
  showTransactionCell = true,
  page,
}) {
  const isReversalExpense =
    value?.settlementStatus === EXPENSE_SETTLEMENT_STATUS.RELEASED ||
    value?.amount < 0;

  return (
    <tr
      key={`grouped-data-show-all-${index}`}
      className="cursor-pointer vp-core-table-row vp-core-table-body-row"
      onClick={(e) => {
        viewTransactionItem(value?.id);
      }}
      ref={(ref) => {
        if (lastKeyIndex) paginationCallback(ref);
        if (setGroupItemRef) {
          setGroupItemRef(ref);
        }
      }}
    >
      <td className="vp-core-table-cell vp-core-table-body-cell">
        <ProfileWidget
          opacityChangeRatio={
            tableHeaderKey === GROUPED_EXPENSES_TABLE?.pending ||
            UnsettledApplied
              ? 4.5
              : null
          }
          img={value?.vendor?.avatarUrl}
          name={value?.merchant?.name ?? value?.vendor?.name}
          className="gap-2"
          textClasses="text-sm font-semibold"
        >
          <div className="flex items-center gap-0.5">
            {value?.cardHolder?.displayName ? (
              <Text
                translationKey={value?.cardHolder?.displayName}
                classes="text-xs truncate text-neutral-500"
              />
            ) : null}

            {page === QRPAY_EXPENSES_CONTEXT.EXPENSES ? (
              <>
                {value?.cardHolder?.displayName &&
                (value?.cardName || category?.value) ? (
                  <span className="profile-widget-dot text-neutral-500" />
                ) : null}

                {value?.cardName ? (
                  <>
                    <Text
                      translationKey={value?.cardName}
                      classes="text-xs truncate text-neutral-500"
                    />
                    {value?.cardNumber ? (
                      <span className="text-xs truncate text-neutral-500">
                        {` (${value?.cardNumber.slice(-4)})`}
                      </span>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}

            {value?.cardName && value?.projectName ? (
              <span className="profile-widget-dot text-neutral-500" />
            ) : null}

            {value?.projectName ? (
              <Text
                translationKey={value?.projectName}
                classes="text-xs truncate text-neutral-500"
              />
            ) : null}
          </div>
        </ProfileWidget>
      </td>
      {showTransactionCell ? (
        <td className="vp-core-table-cell vp-core-table-body-cell">
          <TransactionStatusIcon
            value={value?.transactionStatus}
            index={value?.id}
          />
        </td>
      ) : null}
      <td className="vp-core-table-cell vp-core-table-body-cell">
        <div className="flex items-center justify-between w-full">
          <SubmissionPolicyIcon
            value={value?.submissionPolicyStatus}
            index={value?.id}
          />

          <ReceiptIcon index={value?.id} receiptStatus={value?.receiptStatus} />

          <MemoNoteIcon
            index={value?.id}
            value={value?.memo}
            required={value?.memoRequired}
          />
        </div>
      </td>
      <td className="vp-core-table-cell vp-core-table-body-cell">
        <div className="flex items-center justify-end font-semibold text-left text-neutral-800">
          <span>
            <div className="flex items-center">
              {value?.cashback?.amount !== 0 && (
                <span id={`cashback-${value.id}`}>
                  <Icon
                    name="Exchange"
                    className="w-3.5 h-3.5 text-success-500"
                  />

                  <Tooltip id={`cashback-${value.id}`} direction="top">
                    <Text
                      translationKey="expenses.cashbackEarned"
                      translationProps={{
                        cashback: amountToCurrency(
                          value?.cashback?.amount,
                          value?.currency
                        ),
                        cashbackPercent: value?.cashback?.rate,
                      }}
                      classes="ml-2"
                    />
                  </Tooltip>
                </span>
              )}
              <span
                className={`ml-2 text-sm w-full flex justify-end ${
                  isReversalExpense
                    ? "text-success-600"
                    : UnsettledApplied
                      ? "text-neutral-500"
                      : "first-letter:"
                }`}
              >
                {isReversalExpense ? " + " : ""}
                {amountToCurrency(Math.abs(value?.amount), value?.currency)}
              </span>
            </div>

            {value?.amount !== value?.transactionAmount &&
            value?.currency !== value?.transactionCurrencyCode ? (
              <span className="flex items-center justify-end text-xs font-medium text-neutral-400">
                <Icon name="World" className="w-3 h-3" />
                <span className="ml-1">
                  {amountToCurrency(
                    value?.transactionAmount,
                    value?.transactionCurrencyCode
                  )}
                </span>
              </span>
            ) : null}
          </span>
        </div>
      </td>
    </tr>
  );
}
GroupedTableRow.propTypes = {
  tableHeaderKey: PropTypes.string,
  value: PropTypes.object,
  show: PropTypes.bool,
  paginationCallback: PropTypes.func,
  category: PropTypes.object,
  viewTransactionItem: PropTypes.func,
  index: PropTypes.number,
  lastKeyIndex: PropTypes.bool,
  UnsettledApplied: PropTypes.bool,
  showTransactionCell: PropTypes.bool,
  page: PropTypes.string,
};
