import PropsTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchLocations,
  resetLocationsStoreState,
} from "@/store/reducers/company";

import {
  isFetchingLocationsSelector,
  locationsFiltersSelector,
  locationsHasMoreSelector,
  locationsSelector,
} from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { convertFilters } from "@/utils/filters";

import { LOCATION } from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import LocationProjectAndDepartmentList from "./common/LocationProjectAndDepartmentList";
import "./styles.scss";

export default function LocationsTable({ onClickHandler }) {
  const dispatch = useDispatch();
  const locations = useSelector((state) => locationsSelector(state));
  const isFetching = useSelector((state) => isFetchingLocationsSelector(state));
  const isEmpty = !locations?.length;
  const filters = useSelector(locationsFiltersSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const formattedAppliedFilters = convertFilters(appliedFilters);
  const hasMore = useSelector(locationsHasMoreSelector);

  const onReset = () => {
    dispatch(resetLocationsStoreState());
  };
  const loadMore = () => {
    dispatch(
      fetchLocations({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...formattedAppliedFilters,
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: formattedAppliedFilters,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  return (
    <div className="flex flex-col">
      <LocationProjectAndDepartmentList
        isEmpty={isEmpty}
        hasMore={hasMore}
        isFetching={isFetching}
        handleRefChange={handleRefChange}
        data={locations}
        type={LOCATION}
        filters={filters}
      />
    </div>
  );
}

LocationsTable.propTypes = {
  onClickHandler: PropsTypes.func,
};
