import PropTypes from "prop-types";
import { useMemo, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setExportData } from "@/store/reducers/exports";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { fetchTags } from "@/store/reducers/tags";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  isLoadingBulkActionSelector,
  isTransactionStatusLoadingSelector,
} from "@/store/selectors/accounting_transactions";
import {
  accountingContinuousBillSyncSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { tagsSelector } from "@/store/selectors/tags";

import Badge from "@/components/core/Badge";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import VirtualizedTable from "@/components/core/VirtualizedTable";
import VpSelect from "@/components/core/VpSelect";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import Owner from "@/components/Accounting/Transactions/common/Tab/Owner";
import TagInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/TagInput";
import "@/components/common/BillPayAndPayroll/style.scss";
import EmptyRow from "@/components/common/EmptyRow";
import MemoNoteIcon from "@/components/common/MemoNoteIcon";
import ReceiptIcon from "@/components/common/ReceiptIcon";
import { convertFilters } from "@/utils/filters";
import { handleExport } from "@/utils/exports";
import { CONST_TYPE_REIMBURSEMENT } from "@/utils/constants/reimbursement";
import { INTEGRATION_TAGS_TYPE } from "@/utils/constants/integrations";
import {
  amountToCurrency,
  dateToString,
  groupByDateFlat,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_HEADER_IDS,
  ACCOUNTING_PAGES_TO_PARAMS_MAP,
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
  ACCOUNTING_TRANSACTION_TABS,
  TAB_STATUS_MAP,
  TRANSACTION_STATUS_CTA_MAP,
} from "@/constants/accounting";
import { RECEIPT_STATUS_NAMES } from "@/constants/common";
import { EXPORT_PAGE } from "@/constants/exports";
import { OTHER_TYPES_ICON_AND_TITLE_MAP } from "@/constants/other";
import { ACCOUNTING_OWNER_TYPE } from "@/constants/rules";
import { TAG_FIELD_TYPES } from "@/constants/tags";
import { TRANSACTION_ACTIONS } from "@/constants/transactions";

import Action from "../common/Action";
import {
  isRuleApplied,
  renderCustomUIForOptionWhenDropdownClosed,
  renderRuleAppliedIconForOptionWhenDropdownClosed,
  sortAndUnique,
  truncateString,
} from "../common/util";

export default function TransactionsTable({
  pageNum,
  page,
  tab,
  tableArgs,
  headers,
  transactions,
  totalTransactions,
  isFetching,
  hasMore,
  actionDisabled = false,
  handleRefChange,
  selectedTransactions,
  selectedTransactionObjects,
  onActionClick,
  onViewTransactionClick = () => {},
  handleBulkAction,
  handleUpdateTagAndVendor,
  bulkApproveSelectAll,
  setSelectedTransactions,
  setSelectedTransactionObjects,
  setBulkApproveSelectAll,
  originalTransactions,
  dateToBeGrouped = "transactionDate",
  isGrouped = true,
  setSorting = () => {},
  loadMore = () => {},
}) {
  const tableRef = useRef(null);
  const actionCurrent = useRef();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const appliedFilters = useSelector(appliedFilterSelector);
  const isLoadingBulkAction = useSelector(isLoadingBulkActionSelector);

  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);

  const vendors = useSelector(accountingVendorsSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );
  const accountingEnabled = !!accountingIntegrationSoftware;
  const tags = useSelector(tagsSelector);
  const isActionLoading = useSelector(isTransactionStatusLoadingSelector);
  const accountingContinuousBillSync = useSelector(
    accountingContinuousBillSyncSelector
  );

  const groupedTransactionFlat = useMemo(
    () => groupByDateFlat(transactions, dateToBeGrouped),
    [JSON.stringify(transactions), isActionLoading]
  );

  const isOthersPage = page === ACCOUNTING_TRANSACTION_PAGES.OTHERS;
  const billPayWithContinuousSync =
    page === ACCOUNTING_TRANSACTION_PAGES.BILL_PAY &&
    accountingContinuousBillSync;
  const filteredHeaders = actionDisabled
    ? headers.filter((header) => header.id !== ACCOUNTING_HEADER_IDS.ACTION)
    : headers;
  const colWidths = filteredHeaders?.map(
    (header) => header?.colWidth || header?.colWidths
  );

  const isSyncedTab = tab === ACCOUNTING_TRANSACTION_TABS.SYNCED;

  const isEmpty = transactions?.length === 0;
  const createNewRuleOption = {
    id: -2,
    name: "accounting.rules.createNewRule.title",
    alias: "accounting.rules.createNewRule.title",
  };
  const pageIsNotOthers = page !== ACCOUNTING_TRANSACTION_PAGES.OTHERS;

  const areVerifiable = () => {
    const selectedTransactionsIds = selectedTransactions?.map(
      (item) => item?.accountingId
    );
    const verifyStatuses = originalTransactions?.filter((singleTransaction) => {
      return selectedTransactionsIds?.find(
        (selectedTransaction) =>
          selectedTransaction === singleTransaction.accountingId &&
          singleTransaction.verifiable &&
          singleTransaction.accountingCtas.includes(TRANSACTION_ACTIONS.VERIFY)
      );
    });
    return verifyStatuses.length === selectedTransactionsIds.length;
  };

  const areSyncable = () => {
    const selectedTransactionsIds = selectedTransactions?.map(
      (item) => item?.accountingId
    );
    const syncStatuses = originalTransactions?.filter((singleTransaction) => {
      return selectedTransactionsIds?.find(
        (selectedTransaction) =>
          selectedTransaction === singleTransaction.accountingId &&
          singleTransaction.syncable &&
          singleTransaction.accountingCtas.includes(TRANSACTION_ACTIONS.SYNC)
      );
    });

    return (
      syncStatuses.length === selectedTransactionsIds.length &&
      accountingIntegrationSoftware !== ACCOUNTING_SOFTWARES.UNIVERSAL_CSV
    );
  };

  const findPageNumbers = () => {
    const mappedPageNumbers = selectedTransactions.map((selectedTransaction) =>
      findPageNumber(selectedTransaction)
    );

    return sortAndUnique(mappedPageNumbers);
  };
  const getLabel = ({ isText, isDate }) => {
    if (isText) return "common.enterText";
    if (isDate) return "common.enterDate";
    return "misc.select";
  };
  const findPageNumber = (selectedTransactionId) => {
    const index = transactions.findIndex(
      (transaction) => transaction.accountingId === selectedTransactionId
    );

    if (index === -1) return null;

    // Determine the group number based on the index
    return Math.ceil((index + 1) / transactions.length);
  };

  const fetchTransactions = ({ pageNumbers, onSuccess }) => {
    if (pageNumbers?.length)
      pageNumbers.forEach((pageNumber) => loadMore(pageNumber, onSuccess));
    else loadMore(1, onSuccess);
  };

  const bulkAction = async (type) => {
    const payload = {
      operation: type,
      transaction: ACCOUNTING_PAGES_TO_PARAMS_MAP[page],
      accounting_status: TAB_STATUS_MAP[tab],
      ...convertFilters(appliedFilters),
      ...(bulkSelectAll
        ? { fetch_all: true, deselected_ids: deSelectedIds }
        : selectedTransactions.length > 0
          ? {
              accounting_ids: selectedTransactions?.map(
                (transaction) => transaction.accountingId
              ),
            }
          : {}),
    };

    await handleBulkAction(
      dispatch,
      payload,
      fetchTransactions,
      findPageNumbers()
    );

    tableRef.current.clearSelection();
  };

  const disableAction = (sync, verify, status) => {
    switch (status) {
      case ACCOUNTING_TRANSACTION_STATUS.VERIFIED:
        return !sync;
      case ACCOUNTING_TRANSACTION_STATUS.PENDING:
      case ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED:
        return !verify;
      default:
        return !sync && !verify;
    }
  };

  const exportHandler = () => {
    const conditionCheckBasedOnStatus =
      originalTransactions?.filter((singleTransaction) => {
        return selectedTransactions?.find(
          (selectedTransaction) =>
            selectedTransaction.accountingId ===
              singleTransaction.accountingId &&
            singleTransaction.accountingStatus === TRANSACTION_ACTIONS.VERIFIED
        );
      })?.length === selectedTransactions?.length;
    const selectedTransactionIds = selectedTransactions?.map(
      (item) => item?.accountingId
    );
    dispatch(
      setExportData({
        data: selectedTransactionIds,
        selectedFilters: appliedFilters,
        ucsvPayload: {
          operation: TRANSACTION_ACTIONS.SYNC,
          transaction: ACCOUNTING_PAGES_TO_PARAMS_MAP[page],
          ...(bulkSelectAll
            ? { fetch_all: true }
            : selectedTransactionIds.length > 0
              ? { accounting_ids: selectedTransactionIds }
              : {}),
        },
        hidePdf: [ACCOUNTING_TRANSACTION_PAGES.PAYROLLS]?.includes(page),
        additionalFilters: {
          ...(bulkSelectAll
            ? {
                accountable_type: page,
                deselected_ids: deSelectedIds,
                ...(appliedFilters?.accountingStatus
                  ? {}
                  : { accounting_status: TAB_STATUS_MAP[tab] }),
              }
            : { selected_ids: selectedTransactionIds }),
          export_type: EXPORT_PAGE.ACCOUNTING_TRANSACTION,
        },
        cancelHandler: () => {
          searchParams.delete(SLIDERS_SEARCH_PARAMS.export);
          setSearchParams(searchParams);
        },
        csvExportDesc: "payroll.export.csvTransactionDesc",
        pdfExportDesc: "payroll.export.pdfTransactionDesc",
        csvExportTitle: "payroll.export.csvPaymentTitle",
        pdfExportTitle: "payroll.export.pdfPaymentTitle",

        ...(accountingIntegrationSoftware ===
          ACCOUNTING_SOFTWARES.UNIVERSAL_CSV && conditionCheckBasedOnStatus
          ? {
              ucsvExportTitle: "payroll.export.ucsvTransactionTitle",
              ucsvExportDesc: "payroll.export.ucsvTransactionDesc",
            }
          : null),
      })
    );

    if ([ACCOUNTING_TRANSACTION_PAGES.OTHERS]?.includes(page)) {
      handleExport(
        false,
        selectedTransactions?.length,
        appliedFilters,
        dispatch,
        {
          ...(bulkSelectAll
            ? {
                accountable_type: page,
                deselected_ids: deSelectedIds,
                accounting_status: TAB_STATUS_MAP[tab],
              }
            : { selected_ids: selectedTransactionIds }),
          export_type: EXPORT_PAGE.ACCOUNTING_TRANSACTION,
        }
      );
    } else {
      searchParams.append(SLIDERS_SEARCH_PARAMS.export, true);
      setSearchParams(searchParams);
    }
  };

  const updateTagAndVendor = ({
    params,
    accountingId,
    type,
    isText = false,
    isDate = false,
  }) => {
    let payload = {
      accounting_id: accountingId,
      transaction_type: page,
    };

    payload =
      type === INTEGRATION_TAGS_TYPE.VENDORS
        ? {
            ...payload,
            accounting_payee_id: params.id === -1 ? null : params.id,
          }
        : {
            ...payload,
            tag_id: params?.tagId,
            ...(isText || isDate
              ? {
                  custom_text_value: isDate
                    ? params?.target?.value
                    : params?.textValue,
                }
              : { tag_value_id: params?.id }),
          };
    handleUpdateTagAndVendor(payload);
  };

  const SplitAppliedTextIcon = () => {
    return (
      <div className="flex items-center gap-2 ">
        <Icon name="CallSplit" className="w-6 h-6 text-neutral-500" />
        <Text
          translationKey="accounting.transactions.splitApplied"
          classes="text-sm font-semibold text-neutral-800"
        />
      </div>
    );
  };

  const getComponent = (
    id,
    _data,
    index,
    tagId,
    transaction,
    _isActionLoader
  ) => {
    if (transaction?.isDate) {
      if (headers?.[0]?.id === id)
        return (
          <div className="my-auto text-sm font-semibold">
            {dateToString(transaction?.date)}
          </div>
        );
      return null;
    }

    const { accountingPayeeId, editable, rule } = transaction;
    const { accountingId } = transaction;
    const isSplit = !!transaction?.lineItems?.length;
    switch (id) {
      case ACCOUNTING_HEADER_IDS.VENDOR:
      case ACCOUNTING_HEADER_IDS.MERCHANT:
        return (
          <div className="flex items-center w-full gap-3 overflow-hidden">
            <ProfileWidget
              name={_data?.name}
              img={_data?.avatarUrl}
              avatarSize="md"
              tooltipClasses="w-16"
              textClasses="font-semibold text-sm"
            >
              {transaction?.transactionLinkedTo?.project ? (
                <Text
                  classes="text-xs text-neutral-500 font-medium"
                  translationKey={transaction?.transactionLinkedTo?.project}
                  truncate
                  showTooltip
                />
              ) : null}
            </ProfileWidget>
            {_data?.status === ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED ? (
              <Icon
                name="SyncFailed"
                className="p-1 ml-auto border rounded-lg shrink-0 w-7 h-7 text-danger-500 bg-danger-50 border-danger-100"
              />
            ) : null}
          </div>
        );
      case ACCOUNTING_HEADER_IDS.MERCHANT_OR_MILEAGE:
        /* eslint-disable no-case-declarations */
        const isMileage =
          transaction?.type === CONST_TYPE_REIMBURSEMENT.mileage;
        const isOutOfPocket =
          transaction?.type === CONST_TYPE_REIMBURSEMENT.outOfPocket;
        const profileName = isMileage
          ? "reimbursement.createReimbursement.kms"
          : _data?.name;
        const profileNameTranslationProps = isMileage
          ? { kms: transaction?.distanceTravelled }
          : {};

        const iconComponent = isMileage ? (
          <Icon
            name="Location"
            className="bg-warning-50 text-success-500 p-2 border-[1px] border-success-100 w-9 h-9 flex text-sm rounded-full"
          />
        ) : null;
        /* eslint-enable no-case-declarations */

        return (
          <div className="flex items-center gap-3 overflow-hidden">
            <ProfileWidget
              name={profileName}
              nameTranslationProps={profileNameTranslationProps}
              img={_data?.avatarUrl}
              avatarSize="md"
              iconComponent={iconComponent}
              tooltipClasses="w-16"
              textClasses="font-semibold text-sm"
            >
              {transaction?.transactionLinkedTo?.project ? (
                <Text
                  classes="text-xs text-neutral-500 font-medium"
                  translationKey={transaction?.transactionLinkedTo?.project}
                  truncate
                  showTooltip
                />
              ) : null}
            </ProfileWidget>

            {_data?.status === ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED ? (
              <Icon
                name="SyncFailed"
                className="p-1 border rounded-lg w-7 h-7 text-danger-500 bg-danger-50 border-danger-100"
              />
            ) : null}
          </div>
        );
      case ACCOUNTING_HEADER_IDS.AMOUNT:
        return (
          <div className="flex items-center">
            <Text
              classes={`${
                isOthersPage
                  ? _data?.amount > 0
                    ? "text-success-600"
                    : "text-neutral-800"
                  : ""
              } font-semibold text-sm`}
              translationKey={`${amountToCurrency(
                isOthersPage
                  ? _data?.amount > 0
                    ? _data?.amount
                    : -(_data?.amount || 0)
                  : _data?.amount,
                _data?.currency
              )}`}
            />
          </div>
        );
      case ACCOUNTING_HEADER_IDS.CARD_OWNER:
      case ACCOUNTING_HEADER_IDS.VENDOR_OWNER:
        return (
          <Owner
            className="text-sm font-semibold"
            name={`${_data?.displayName}`}
            department={_data?.departmentName}
            location={_data?.locationName}
          />
        );
      case ACCOUNTING_HEADER_IDS.OWNER:
        return (
          <Owner
            className="text-sm font-semibold "
            name={_data?.displayName}
            department={_data?.departmentName}
            location={_data?.locationName}
          />
        );
      case ACCOUNTING_HEADER_IDS.INVOICE:
      case ACCOUNTING_HEADER_IDS.RECEIPT:
        if (_data?.receiptStatus) {
          return (
            <div className="flex-1">
              <ReceiptIcon
                type={id}
                index={accountingId}
                receiptStatus={_data?.receiptStatus}
              />
            </div>
          );
        }

        // eslint-disable-next-line no-case-declarations
        const receiptStatus = _data?.required
          ? _data?.attached
            ? RECEIPT_STATUS_NAMES.SUBMITTED
            : RECEIPT_STATUS_NAMES.MISSING
          : RECEIPT_STATUS_NAMES.NOT_REQUIRED;

        return (
          <div className="flex-1">
            <ReceiptIcon
              type={id}
              index={accountingId}
              receiptStatus={receiptStatus}
            />
          </div>
        );
      case ACCOUNTING_HEADER_IDS.MEMO:
      case ACCOUNTING_HEADER_IDS.NOTE:
        return (
          <div className="flex-1">
            <MemoNoteIcon
              required={_data?.required}
              value={_data?.value}
              index={accountingId}
            />
          </div>
        );
      case ACCOUNTING_HEADER_IDS.TRANSACTION_STATUS:
        return (
          <div className="flex justify-center">
            <Badge
              variant={TRANSACTION_STATUS_CTA_MAP[_data]?.color}
              translationKey={TRANSACTION_STATUS_CTA_MAP[_data]?.text}
              classes="w-[calc(theme(spacing.3)*9)]"
            />
          </div>
        );
      case ACCOUNTING_HEADER_IDS.STATUS:
        return (
          <div className="flex justify-center">
            <Badge
              variant={TRANSACTION_STATUS_CTA_MAP[_data]?.color}
              translationKey={TRANSACTION_STATUS_CTA_MAP[_data]?.text}
              classes="w-[calc(theme(spacing.3)*9)]"
            />
          </div>
        );
      case ACCOUNTING_HEADER_IDS.LINKED_TO:
        return (
          <div className="w-full overflow-hidden">
            <Text
              truncate
              translationKey={_data?.project}
              showTooltip
              classes="text-sm font-semibold block text-neutral-800 text-left "
            />
            <Text
              truncate
              showTooltip
              translationKey={_data?.linkedTo}
              classes="text-xs font-medium text-neutral-500"
            />
          </div>
        );
      case ACCOUNTING_HEADER_IDS.ACTION:
        return !actionDisabled ? (
          <Action
            syncable={_data.syncable}
            verifiable={_data.verifiable}
            status={_data?.status}
            disabledToolTipText={transaction.notActionableReason}
            id={accountingId}
            onClick={(_dispatch, action, arg) => {
              actionCurrent.current = accountingId;
              const onSuccess = () => {
                actionCurrent.current = null;
              };
              onActionClick(_dispatch, action, arg, onSuccess);
            }}
            disabled={
              billPayWithContinuousSync
                ? !_data.syncable
                : disableAction(_data.syncable, _data.verifiable, _data?.status)
            }
            exportable={
              accountingIntegrationSoftware ===
              ACCOUNTING_SOFTWARES.UNIVERSAL_CSV
            }
            classes="font-semibold"
            isLoading={
              _isActionLoader && actionCurrent.current === accountingId
            }
            billPayWithContinousSync={billPayWithContinuousSync}
            transactionType={ACCOUNTING_PAGES_TO_PARAMS_MAP[page]}
            ctas={_data.ctas}
          />
        ) : null;
      case ACCOUNTING_HEADER_IDS.TRANSACTION_TYPE:
        return (
          <div className="flex flex-wrap items-center gap-3">
            <div className="flex items-center gap-2">
              <div className="flex items-center justify-center rounded-full bg-neutral-100 w-9 h-9">
                <Icon
                  name={OTHER_TYPES_ICON_AND_TITLE_MAP[_data?.name]?.icon ?? ""}
                  className="w-5 h-5 text-neutral-500"
                />
              </div>
              <div className="flex flex-col">
                <Text
                  classes="text-sm font-semibold text-neutral-800"
                  translationKey={
                    OTHER_TYPES_ICON_AND_TITLE_MAP[_data?.name]?.title
                  }
                />
                <span className="text-xs font-medium text-neutral-500">
                  {dateToString(_data?.date)}
                </span>
              </div>
              {_data?.status === ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED ? (
                <Icon
                  name="SyncFailed"
                  className="p-1 border rounded-lg text-danger-500 bg-danger-50 border-danger-100"
                />
              ) : null}
            </div>
          </div>
        );
      case ACCOUNTING_HEADER_IDS.VENDORS:
        /* eslint-disable no-case-declarations */
        let selectedVendor = accountingPayeeId
          ? transaction?.accountingVendorName
            ? {
                id: accountingPayeeId,
                name: transaction?.accountingVendorName,
                alias: truncateString(transaction?.accountingVendorName, 23),
              }
            : null
          : {
              id: -1,
              name: _data?.name,
              alias: truncateString(_data?.alias, 23),
            };

        const updatedVendorOptions = [
          createNewRuleOption,
          {
            id: -1,
            name: _data?.name,
            alias: truncateString(_data?.alias, 23),
          },
          ...vendors,
        ];
        /* eslint-enable no-case-declarations */
        return !editable && !selectedVendor ? (
          <EmptyRow
            key={`empty-accounting-vendors-tags-${transaction?.id}-${tagId}`}
            index={index}
          />
        ) : (
          <div className="table-tapinput grow">
            <VpSelect
              key={`accounting-vendors-tags-${transaction?.id}-${tagId}`}
              label="misc.select"
              placeholder="misc.select"
              options={updatedVendorOptions}
              optionsDisplayKey="alias"
              hideLabelAfterSelect
              insideTable
              value={selectedVendor?.id}
              valueKey="id"
              handleChange={(params) => {
                if (params?.id !== -2) {
                  updateTagAndVendor({
                    params,
                    type: "vendors",
                    accountingId: transaction.accountingId,
                  });
                  selectedVendor = params;
                }
              }}
              disabled={!editable}
              customUIForOptionWhenDropdownClosed={
                isRuleApplied(
                  ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE,
                  selectedVendor?.id,
                  rule
                )
                  ? (label) =>
                      renderRuleAppliedIconForOptionWhenDropdownClosed(
                        label,
                        rule?.ruleType
                      )
                  : selectedVendor?.id === -1
                    ? (label) =>
                        renderCustomUIForOptionWhenDropdownClosed(label)
                    : null
              }
              classes=" relative grid grid-cols-1 !text-neutral-800 !opacity-100"
              onStickyOptionSelected={() => {
                handleOpenCreateNewRule(
                  accountingId,
                  ACCOUNTING_HEADER_IDS.VENDOR
                );
              }}
              customUIForFirstOption={customUIForFirstOption}
              firstOptionSticky
            />
          </div>
        );

      case id?.includes(ACCOUNTING_HEADER_IDS.CUSTOM_TAG):
        /* eslint-disable no-case-declarations */
        const currentCustomTag = filteredHeaders.find(
          (header) => header.tagId === tagId
        );
        const selectedCustomTag = transaction?.customTag?.arr?.find(
          (option) => option.tagId === currentCustomTag.tagId
        );

        const customTagOptions = [
          ...(pageIsNotOthers ? [createNewRuleOption] : []),
          ...(selectedCustomTag ? [{ tagId: selectedCustomTag?.tagId }] : []),
          ...(currentCustomTag?.options ?? []),
        ];
        const isText = TAG_FIELD_TYPES.TEXT === currentCustomTag?.fieldType;
        const isDate = TAG_FIELD_TYPES.DATE === currentCustomTag?.fieldType;

        const tagValue =
          isText || isDate
            ? selectedCustomTag?.customTextValue
            : selectedCustomTag?.tagValueId;
        /* eslint-enable no-case-declarations */

        if (isSplit) return <SplitAppliedTextIcon />;

        return !editable && !tagValue ? (
          <EmptyRow
            key={`custom-tags-empty-${transaction?.id}-${currentCustomTag?.fieldType}-${tagId}`}
            index={index}
          />
        ) : (
          <div
            onClick={(e) => e?.stopPropagation()}
            className="table-tapinput grow"
          >
            <TagInput
              key={`custom-tags-${transaction?.id}-${currentCustomTag?.fieldType}-${tagId}`}
              disabled={!editable}
              wantValueOnBlur
              wantEditable
              callTags={false}
              dateInputProps={{
                hideLabelAfterValueAdded: true,
                hideLabelAfterFocussed: true,
              }}
              insideTable
              inputProps={{
                hideLabelAfterValueAdded: true,
                hideLabelAfterFocussed: true,
              }}
              vpSelectProps={{
                customUIForFirstOption: pageIsNotOthers
                  ? customUIForFirstOption
                  : null,
                firstOptionSticky: pageIsNotOthers,
                hideLabelAfterSelect: true,

                customUIForOptionWhenDropdownClosed: isRuleApplied(
                  ACCOUNTING_OWNER_TYPE.TAG_VALUE,
                  selectedCustomTag?.tagValueId,
                  rule
                )
                  ? (label) =>
                      renderRuleAppliedIconForOptionWhenDropdownClosed(
                        label,
                        rule?.ruleType
                      )
                  : null,
                onStickyOptionSelected: () => {
                  if (pageIsNotOthers) {
                    handleOpenCreateNewRule(
                      accountingId,
                      ACCOUNTING_HEADER_IDS.TAG,
                      tagId
                    );
                  }
                },
              }}
              classes="grid grid-cols-1 !text-neutral-800 !opacity-100 w-full"
              vpSelectClasses="grid grid-cols-1 !text-neutral-800 !opacity-100 w-full"
              values={tagValue}
              handleChange={(params) => {
                if (params?.id !== -2) {
                  updateTagAndVendor({
                    params,
                    type: INTEGRATION_TAGS_TYPE.TAGS,
                    accountingId,
                    isText: params?.fieldType === TAG_FIELD_TYPES.TEXT,
                    isDate: params?.fieldType === TAG_FIELD_TYPES.DATE,
                  });
                }
              }}
              label={getLabel({ isText, isDate })}
              tag={{ ...currentCustomTag, options: customTagOptions }}
              isDirectValue
            />
          </div>
        );

      case ACCOUNTING_HEADER_IDS.ACCOUNTING_TAG:
      case ACCOUNTING_HEADER_IDS.NON_ACCOUNTING_TAG: {
        const currentTag = filteredHeaders?.find(
          (header) => header.tagId === tagId
        );

        const selectedAccountingTag = transaction?.accountingTag?.arr?.find(
          (option) => option.tagId === currentTag.tagId
        );
        const currentTagOptions = [
          // ...(selectedAccountingTag ? [{ tagId: currentTag?.tagId }] : []),
          ...(pageIsNotOthers ? [createNewRuleOption] : []),
          ...(currentTag?.options ?? []),
        ];

        const updatedTagOptions = currentTagOptions.map((option) => {
          if (
            option.tagId === selectedAccountingTag?.tagId &&
            option.name !== "accounting.rules.createNewRule.title"
          ) {
            return { ...option, alias: truncateString(option.alias, 24) };
          }
          return option;
        });

        if (isSplit) return <SplitAppliedTextIcon />;
        // if (!editable) return <EmptyRow index={index} />;
        return (
          <div className="table-tapinput grow">
            <VpSelect
              key={`accounting-tags-${id}-${transaction?.id}-${tagId}`}
              label="misc.select"
              placeholder="misc.select"
              options={updatedTagOptions}
              optionsDisplayKey="alias"
              valueKey="id"
              translate
              hideLabelAfterSelect
              insideTable
              value={selectedAccountingTag?.tagValueId}
              handleChange={(params) => {
                if (params?.id !== -2) {
                  updateTagAndVendor({
                    params,
                    type: INTEGRATION_TAGS_TYPE.TAGS,
                    accountingId,
                  });
                }
              }}
              disabled={!editable}
              classes="grid grid-cols-1 !text-neutral-800 !opacity-100"
              firstOptionSticky={pageIsNotOthers}
              onStickyOptionSelected={() => {
                if (pageIsNotOthers) {
                  handleOpenCreateNewRule(
                    accountingId,
                    ACCOUNTING_HEADER_IDS.TAG,
                    currentTag.tagId
                  );
                }
              }}
              customUIForOptionWhenDropdownClosed={
                isRuleApplied(
                  ACCOUNTING_OWNER_TYPE.TAG_VALUE,
                  selectedAccountingTag?.tagValueId,
                  rule
                )
                  ? (label) =>
                      renderRuleAppliedIconForOptionWhenDropdownClosed(
                        label,
                        rule?.ruleType
                      )
                  : null
              }
              customUIForFirstOption={
                pageIsNotOthers ? customUIForFirstOption : null
              }
            />
          </div>
        );
      }

      case ACCOUNTING_HEADER_IDS.OPEN_IN:
        return (
          <div
            className="flex items-center justify-center"
            onClick={() => _data && window.open(_data, "_blank")}
          >
            <div
              className={`flex items-center justify-center w-14.75 h-8 px-5 py-1.5 border border-neutral-300 rounded-md text-neutral-400 hover:text-primary-500 cursor-pointer ${
                !_data ? "disabled" : ""
              }`}
            >
              <Icon name="Link" className="w-5 h-5" />
            </div>
          </div>
        );
      case ACCOUNTING_HEADER_IDS.LEDGER_DATE:
        return (
          <span className="max-w-full text-sm font-semibold truncate">
            {_data ? dateToString(_data) : "-"}
          </span>
        );
      case ACCOUNTING_HEADER_IDS.PAYMENT_DATE:
        return (
          <div className="text-sm font-semibold">
            {_data ? dateToString(_data) : "-"}
          </div>
        );
      default:
        return "-";
    }
  };

  function handleOpenCreateNewRule(accountingId, type, id) {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.rules.createNewRuleFor,
      accountingId
    );
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.fieldType, type);
    if (type === ACCOUNTING_HEADER_IDS.TAG)
      searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.fieldId, id);
    setSearchParams(searchParams);
  }

  function customUIForFirstOption(displayValue, option) {
    return (
      <div className="flex items-center gap-3 bg-primary-50 px-4 py-2 text-primary-500 !w-full ">
        <Icon name="Plus" className="mr-2 " />
        <Text translationKey={displayValue} classes="text-sm font-semibold" />
      </div>
    );
  }

  useEffect(() => {
    dispatch(fetchTags({ visible: true }));
  }, []);

  useEffect(() => {
    if (!vendors?.length && accountingEnabled)
      dispatch(fetchAccountingVendors());
  }, [accountingEnabled]);

  return (
    <VirtualizedTable
      ref={tableRef}
      isActionLoading={isActionLoading}
      id="transaction-Table"
      isLoading={isFetching}
      onRowClick={(rowData) => {
        if (!rowData?.isDate) onViewTransactionClick(rowData.accountingId);
      }}
      handleRefChange={(ref, index) => {
        if (
          ((isGrouped && groupedTransactionFlat.length - 1 === index) ||
            (!isGrouped && transactions.length - 1 === index)) &&
          hasMore
        ) {
          handleRefChange(ref);
        }
      }}
      tableRowClasses={(row) => (row?.isDate ? "h-9" : "")}
      data={isGrouped ? groupedTransactionFlat : transactions}
      headerConfig={filteredHeaders}
      selectAllRows={bulkSelectAll}
      {...tableArgs}
      colWidths={colWidths}
      getCellComponent={(rowData, headerId, header, isActionLoader) => {
        return getComponent(
          headerId,
          rowData?.getValue(),
          rowData?.row?.index,
          header.tagId,
          rowData?.row?.original,
          isActionLoader
        );
      }}
      onSortingChange={(args) => setSorting(args)}
      getEnableRowSelection={(row) => !row?.original?.isDate}
      getRowId={(row) => (row?.isDate ? row?.date : row?.accountingId)}
      onRowSelectionChange={({
        selectedRows,
        selectedRowsArrayOfObject,
        deselectedRowsIds,
        deselectedRowsIdsSet,
        selectAllRows,
        isHeaderSelected,
      }) => {
        setDeSelectedIds(deselectedRowsIds);
        setSelectedTransactions(selectedRowsArrayOfObject);
        setHeaderSelectAll(isHeaderSelected);
      }}
      // Bulk approve
      bulkApproveVisible={selectedTransactions?.length > 0}
      bulkApproveHeight="100px"
      bulkApproveContent={
        <BulkActionComponent
          selectedRows={
            bulkSelectAll
              ? deSelectedIds?.length
                ? totalTransactions - deSelectedIds.length
                : totalTransactions
              : selectedTransactions?.length
          }
          totalRows={totalTransactions}
          handleBulkAction={bulkAction}
          isLoading={isLoadingBulkAction}
          descriptionText="common.exportDesc"
          bulkApproveSelectAll={
            (bulkSelectAll && !deSelectedIds?.length) ||
            selectedTransactions?.length === totalTransactions
          }
          bulkSelectAll={bulkSelectAll}
          showSelectAllButton={headerSelectAll}
          selectAllHandler={(val) => {
            setDeSelectedIds([]);
            setBulkSelectAll(val);
          }}
          handleExport={exportHandler}
          showExport
          showVerify={areVerifiable() || bulkSelectAll}
          showSync={areSyncable() || bulkSelectAll}
          clearSelection={() => {
            setBulkSelectAll(false);
            tableRef.current?.clearSelection();
          }}
        />
      }
    />
  );
}

TransactionsTable.propTypes = {
  pageNum: PropTypes.number,
  headers: PropTypes.array,
  transactions: PropTypes.array,
  totalTransactions: PropTypes.number,
  isFetching: PropTypes.bool,
  hasMore: PropTypes.bool,
  actionDisabled: PropTypes.bool,
  // handleAllRows: PropTypes.func, // add handleAllRows in table only not needed
  handleRefChange: PropTypes.func,
  // handleRowSelection: PropTypes.func, // this is also added in table only
  selectedTransactions: PropTypes.array,
  // clearSelectedTransactions: PropTypes.func, // this is also added in table only
  page: PropTypes.string,
  tab: PropTypes.string,
  tableArgs: PropTypes.object,
  onActionClick: PropTypes.func,
  onViewTransactionClick: PropTypes.func,
  selectedTransactionObjects: PropTypes.array,
  setSelectedTransactions: PropTypes.func,
  setSelectedTransactionObjects: PropTypes.func,
  handleBulkAction: PropTypes.func,
  handleUpdateTagAndVendor: PropTypes.func,
  bulkApproveSelectAll: PropTypes.bool,
  setBulkApproveSelectAll: PropTypes.func,
  originalTransactions: PropTypes.array,
  dateToBeGrouped: PropTypes.string,
  isGrouped: PropTypes.bool,
  sorting: PropTypes.object,
  setSorting: PropTypes.func,
};
