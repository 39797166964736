import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import { downloadFile } from "@/utils/actions";

export default function BillingActionCell({ val }) {
  const invoicePdf = val?.invoicePdf;
  return (
    <div className="flex items-center justify-center">
      <Icon
        name="Download"
        handleClick={() =>
          downloadFile(
            invoicePdf?.url,
            invoicePdf?.fileName,
            invoicePdf?.contentType
          )
        }
      />
    </div>
  );
}

BillingActionCell.propTypes = {
  val: PropTypes.object,
};
