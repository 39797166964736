import PropTypes from "prop-types";

import Avatar from "@/components/core/Avatar";

import "./style.scss";

function AvatarWithCount({
  firstUserImageUrl,
  firstUserColorCode,
  firstUserName,
  totalUsers,
  size = "md",
}) {
  return (
    <div className="avatar-count-parent">
      <Avatar
        name={firstUserName}
        imgSrc={firstUserImageUrl}
        colorCode={firstUserColorCode}
        size={size}
      />
      <div className={`avatar-count--feat avatar-count--feat-${size}`}>
        {totalUsers}
      </div>
    </div>
  );
}

export default AvatarWithCount;
AvatarWithCount.propTypes = {
  firstUserImageUrl: PropTypes.string,
  firstUserName: PropTypes.string,
  firstUserColorCode: PropTypes.string,
  totalUsers: PropTypes.number,
  size: PropTypes.string,
};
