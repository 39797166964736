import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function DosAndDontsList({ dosAndDontsArray }) {
  const iconList = {
    success: {
      icon: "Done",
      classes: "bg-success-500 text-white p-1",
    },
    error: {
      icon: "Close",
      classes: "bg-danger-400 text-white p-1",
    },
    info: {
      icon: "WarningCircleFill",
      classes: "text-neutral-400",
    },
    flag: {
      icon: "Flag",
      classes: "text-primary-400",
    },
    pace: {
      icon: "Pace",
      classes: "text-primary-400",
    },
    exchange: {
      icon: "Exchange",
      classes: "text-primary-400",
    },
    arrows: {
      icon: "Expense",
      classes: "text-primary-400",
    },
  };

  return (
    <div aria-label="dos-donts-pros-cons" className="flex flex-col gap-2">
      {dosAndDontsArray?.map(({ text, action, textProp }) => (
        <div className="flex items-center gap-1" key={text}>
          <Icon
            name={iconList[action]?.icon}
            className={`rounded-full ml-1 w-4 h-4 ${iconList[action]?.classes}`}
          />
          <Text
            translationKey={text}
            translationProps={textProp}
            classes="font-semibold text-xs w-11/12 ml-2"
          />
        </div>
      ))}
    </div>
  );
}

DosAndDontsList.propTypes = {
  dosAndDontsArray: PropTypes.array,
};
