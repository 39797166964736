import PropTypes from "prop-types";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { fetchCurrencies } from "@/store/reducers/client";
import { fetchQuoteForPurchaseBill } from "@/store/reducers/purchase-bills";

import {
  currenciesSelector,
  defaultCurrencySelector,
} from "@/store/selectors/client";
import {
  isFetchingPurchaseBillQuoteSelector,
  purchaseBillQuoteSelector,
} from "@/store/selectors/purchase-bills";

import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import ExchangeInput from "@/components/common/BillPayAndPayroll/ExchangeRate/common/ExchangeInput";
import { GENERATE_QUOTE_API_BODY_KEYS_REQUEST } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import { CURRENCYOBJ } from "@/utils/constants/reimbursement";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { EXCHANGE_RATE } from "@/utils/constants/payments";
import { limitDecimalsWithRounding } from "@/utils/common";

import {
  CURRENCY_CURRENCYNAME_ARRAY,
  FIXED_SIDE,
  POPULAR_CURRENCIES,
} from "@/constants/currency";

/**
 * @param {String} context
 */

const TYPE_CONSTANT = {
  SET_SENDER_INPUT_CHANGE: "SET_SENDER_INPUT_CHANGE",
  SET_RECIEVER_INPUT_CHANGE: "SET_RECIEVER_INPUT_CHANGE",
  SET_SENDER_INPUT_CURRENCY_CHANGE: "SET_SENDER_INPUT_CURRENCY_CHANGE",
  SET_RECIEVER_INPUT_CURRENCY_CHANGE: "SET_RECIEVER_INPUT_CURRENCY_CHANGE",
  RESET: "RESET",
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPE_CONSTANT.SET_SENDER_INPUT_CHANGE:
      return {
        ...state,
        senderInputChange: action.payload,
      };

    case TYPE_CONSTANT.SET_RECIEVER_INPUT_CHANGE:
      return {
        ...state,
        receiverInputChange: action.payload,
      };

    case TYPE_CONSTANT.SET_SENDER_INPUT_CURRENCY_CHANGE:
      return {
        ...state,
        senderInputCurrencyChange: action.payload,
      };

    case TYPE_CONSTANT.SET_RECIEVER_INPUT_CURRENCY_CHANGE:
      return {
        ...state,
        receiverInputCurrencyChange: action.payload,
      };

    case TYPE_CONSTANT.RESET:
      return {
        senderInputChange: false,
        receiverInputChange: false,
        senderInputCurrencyChange: false,
        receiverInputCurrencyChange: false,
      };

    default:
      return state;
  }
};
function ExchangeRate({ context }) {
  const { t } = useTranslation();
  const isFetchingQuote = useSelector(isFetchingPurchaseBillQuoteSelector);
  const quoteFromAPI = useSelector(purchaseBillQuoteSelector);

  const [senderAmount, setSenderAmount] = useState();
  const [receiverAmount, setReceiverAmount] = useState();
  const [fixedSide, setFixedSide] = useState(FIXED_SIDE.SELL);

  const defaultCurrency = useSelector(defaultCurrencySelector);

  const [senderCurrency, setSenderCurrency] = useState(defaultCurrency);
  const [receiverCurrency, setReceiverCurrency] = useState(defaultCurrency);

  useEffect(() => {
    if (defaultCurrency) {
      setSenderCurrency(defaultCurrency);
      setReceiverCurrency(defaultCurrency);
    }
  }, [defaultCurrency]);

  const currencyOptions = useSelector(currenciesSelector);

  useEffect(() => {
    dispatch(fetchCurrencies({ type: EXCHANGE_RATE }));
  }, []);

  const [state, customReducerDispatch] = useReducer(reducer, {
    senderInputChange: false,
    receiverInputChange: false,
    senderInputCurrencyChange: false,
    receiverInputCurrencyChange: false,
  });

  const handleSetSenderInputChange = () => {
    customReducerDispatch({
      type: TYPE_CONSTANT.SET_SENDER_INPUT_CHANGE,
      payload: true,
    });
  };
  const handleSetReceiverInputChange = () => {
    customReducerDispatch({
      type: TYPE_CONSTANT.SET_RECIEVER_INPUT_CHANGE,
      payload: true,
    });
  };

  const handleSetSenderInputCurrencyChange = () => {
    customReducerDispatch({
      type: TYPE_CONSTANT.SET_SENDER_INPUT_CURRENCY_CHANGE,
      payload: true,
    });
  };
  const handleSetReceiverInputCurrencyChange = () => {
    customReducerDispatch({
      type: TYPE_CONSTANT.SET_RECIEVER_INPUT_CURRENCY_CHANGE,
      payload: true,
    });
  };
  const handleReset = () => {
    customReducerDispatch({ type: TYPE_CONSTANT.RESET });
  };

  const mappedCurrencies = currencyOptions
    ?.map((data) => {
      let result = {};
      const findedObj = CURRENCY_CURRENCYNAME_ARRAY?.find((item) => {
        return item.value === data.value;
      });
      result = {
        ...result,
        value: findedObj?.value,
        currency: findedObj?.value,
        fullName: findedObj?.name,
      };

      return result;
    })
    .filter((item) => item.value);

  const groupedOptions = [
    {
      label: t("payroll.exchangeRate.popularCurrencies"),
      options: POPULAR_CURRENCIES,
    },
    {
      label: t("payroll.exchangeRate.allCurrencies"),
      options: mappedCurrencies,
    },
  ];

  const exchangeRate =
    senderCurrency === receiverCurrency ? 1 : quoteFromAPI?.rate;

  const calculateAmount = ({ toAmount, fromAmount }) => {
    if (fixedSide === FIXED_SIDE.SELL) {
      setReceiverAmount(limitDecimalsWithRounding(toAmount, 2));
    } else {
      setSenderAmount(limitDecimalsWithRounding(fromAmount, 2));
    }
  };

  // 123.00 -> 123.0 -> 123.00

  const dispatch = useDispatch();

  const onFail = () => {
    setSenderAmount(0);
    setReceiverAmount(0);
    setSenderCurrency(defaultCurrency);
    setReceiverCurrency(defaultCurrency);
  };

  const onSuccess = (quoteData) => {
    calculateAmount({
      toAmount: quoteData.toAmount,
      fromAmount: quoteData.fromAmount,
    });

    handleReset();
  };

  const quoteUseEffectCall = () => {
    // explicit avoid, when: partial edit, like duplicate ID edit (other fields are disabled)

    let generatedPayload = {
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.BUY_CURRENCY]: receiverCurrency,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.SELL_CURRENCY]: senderCurrency,
      simple_quote: true,
    };
    if (fixedSide === FIXED_SIDE.SELL) {
      generatedPayload = {
        ...generatedPayload,
        [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.AMOUNT]: senderAmount,
        fixed_side: fixedSide,
      };
    } else if (fixedSide === FIXED_SIDE.BUY) {
      generatedPayload = {
        ...generatedPayload,
        [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.AMOUNT]: receiverAmount,
        fixed_side: fixedSide,
      };
    }

    const senderChangeCondition =
      senderAmount > 0 &&
      (state.senderInputChange || state.senderInputCurrencyChange) &&
      senderAmount;

    const recieverChangeCondition =
      receiverAmount > 0 &&
      (state.receiverInputChange || state.receiverInputCurrencyChange) &&
      receiverAmount;

    if (senderChangeCondition || recieverChangeCondition) {
      dispatch(
        fetchQuoteForPurchaseBill({
          payload: generatedPayload,
          onSuccess,
          onFail,
        })
      );
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      quoteUseEffectCall();
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [
    fixedSide,
    state.senderInputChange,
    state.receiverInputChange,
    state.senderInputCurrencyChange,
    state.receiverInputCurrencyChange,
    senderAmount,
    receiverAmount,
    senderCurrency,
    receiverCurrency,
  ]);

  const loaderUI = (
    <svg
      className="w-5 h-5 animate-spin "
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );

  return (
    <div className="flex flex-col gap-5 px-12">
      <HeaderText context={context} />
      <div className="flex items-center gap-10 mt-4 ">
        <div
          className={`w-[450px] px-6 py-8 border rounded-lg border-neutral-200 ${
            isFetchingQuote ? "opacity-50" : ""
          }`}
        >
          <ExchangeInput
            mappedCurrencies={mappedCurrencies}
            senderCurrency={senderCurrency}
            setSenderCurrency={setSenderCurrency}
            receiverCurrency={receiverCurrency}
            setReceiverCurrency={setReceiverCurrency}
            sender
            disabledState={isFetchingQuote}
            setFixedSide={setFixedSide}
            handleSetSenderInputCurrencyChange={
              handleSetSenderInputCurrencyChange
            }
            handleSetReceiverInputCurrencyChange={
              handleSetReceiverInputCurrencyChange
            }
            groupedOptions={groupedOptions}
          />
          <div className="flex items-center mt-8">
            <div className="w-full">
              <Input
                value={senderAmount}
                type="number"
                label="payroll.exchangeRate.enterAmount"
                onChange={(e) => {
                  setFixedSide(FIXED_SIDE.SELL);
                  setSenderAmount(e.target.value);
                  handleSetSenderInputChange();
                }}
                showNumberInvalidError
                rightText={senderCurrency}
                // labelExtraClasses="font-semibold text-xs"
                disabled={isFetchingQuote}
                classes={`${isFetchingQuote ? "cursor-not-allowed" : ""}`}
              />
            </div>

            {senderCurrency ? (
              <Flag
                code={senderCurrency}
                size="lg"
                classes="border border-neutral-200 ml-6 "
              />
            ) : null}
          </div>
        </div>
        <div className="p-3 rounded-md bg-primary-400">
          {isFetchingQuote ? (
            <div className="text-white"> {loaderUI}</div>
          ) : (
            <Icon name="ArrowForward" className="text-white " />
          )}
        </div>
        <div
          className={`w-[450px] px-6 py-8 border rounded-lg border-neutral-200 ${
            isFetchingQuote ? "opacity-50" : ""
          }`}
        >
          <ExchangeInput
            mappedCurrencies={mappedCurrencies}
            senderCurrency={senderCurrency}
            setSenderCurrency={setSenderCurrency}
            receiverCurrency={receiverCurrency}
            setFixedSide={setFixedSide}
            setReceiverCurrency={setReceiverCurrency}
            disabledState={isFetchingQuote}
            handleSetSenderInputCurrencyChange={
              handleSetSenderInputCurrencyChange
            }
            handleSetReceiverInputCurrencyChange={
              handleSetReceiverInputCurrencyChange
            }
            groupedOptions={groupedOptions}
          />
          <div className="flex items-center mt-8 ">
            <div className="w-full ">
              <Input
                value={receiverAmount}
                label="payroll.exchangeRate.enterAmount"
                type="number"
                onChange={(e) => {
                  setFixedSide(FIXED_SIDE.BUY);
                  setReceiverAmount(e.target.value);
                  handleSetReceiverInputChange();
                }}
                showNumberInvalidError
                rightText={receiverCurrency}
                disabled={isFetchingQuote}
                classes={`${isFetchingQuote ? "cursor-not-allowed" : ""}`}
              />
            </div>

            {receiverCurrency ? (
              <Flag
                code={receiverCurrency}
                size="lg"
                classes="border border-neutral-200 ml-6 "
              />
            ) : null}
          </div>
        </div>
      </div>

      {exchangeRate && !isFetchingQuote ? (
        <div
          className={`flex flex-col mt-6 ${
            isFetchingQuote ? "opacity-50" : ""
          }`}
        >
          <div className="flex items-center gap-2 px-2 py-1 rounded-sm bg-neutral-50 w-fit">
            <span>
              <Icon name="Exchange" className="w-5 h-5 text-neutral-500" />
            </span>
            <Text
              classes="text-neutral-500"
              translationKey="payroll.exchangeRate.title"
            />
            <Text
              classes="text-neutral-500"
              translationKey="payroll.exchangeRate.exchangeRateValue"
              translationProps={{
                senderCurrency,
                exchangeRate,
                receiverCurrency,
              }}
            />
          </div>

          <Text
            classes="text-sm mt-3 text-neutral-500"
            translationKey="payroll.exchangeRate.information"
          />
        </div>
      ) : null}
    </div>
  );
}

function HeaderText({ context }) {
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col">
        <Text
          translationKey={inPayrollContext ? "payroll.title" : "billPay.title"}
          classes="breadcrumb-text"
        />
        <Text
          translationKey="payroll.exchangeRate.title"
          classes="listing-page-title"
        />
      </div>
    </div>
  );
}

export default ExchangeRate;

ExchangeRate.propTypes = {
  context: PropTypes.string,
};

HeaderText.propTypes = {
  context: PropTypes.string,
};
