import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { amountToCurrency } from "@/utils/common";

/**
 * Two pills - Exchange rate and transfer fees
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=3974-221787&mode=dev}
 */
export default function QuoteExchangeRate({
  senderCurrency,
  beneficiaryCurrency,

  exchangeRate,
  transferFees,
  isShowTransferFees = true,

  isFetching,

  classes = "",
}) {
  return (
    <div
      className={`flex items-center gap-2 text-neutral-500 text-sm ${classes}`}
    >
      <Item
        icon="Exchange"
        firstText="misc.exchangeRate"
        secondText={`${amountToCurrency({
          value: 1,
          currency: senderCurrency,
        })} = ${amountToCurrency({
          value: exchangeRate?.value ?? exchangeRate,
          currency: beneficiaryCurrency,
        })}`} // preview: 1 SGD = 0.76 USD
      />

      {isShowTransferFees && transferFees ? (
        <Item
          icon="Expense"
          firstText="misc.transferFees"
          secondText={amountToCurrency(
            transferFees?.value ?? transferFees,
            senderCurrency
          )}
        />
      ) : null}
    </div>
  );
}

QuoteExchangeRate.propTypes = {
  senderCurrency: PropTypes.string,
  beneficiaryCurrency: PropTypes.string,
  exchangeRate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.number,
      currency: PropTypes.string,
    }),
  ]),
  transferFees: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.number,
      currency: PropTypes.string,
    }),
  ]),
  isFetching: PropTypes.bool,
  classes: PropTypes.string,
};

function Item({ icon, firstText, secondText }) {
  return (
    <div className="flex items-center gap-2 px-2 py-1 rounded-lg bg-neutral-50">
      <Icon name={icon} className="w-5 h-5" />
      <Text translationKey={firstText} classes="font-medium" />
      <span className="font-semibold text-neutral-800">{secondText}</span>
    </div>
  );
}

Item.propTypes = {
  icon: PropTypes.string,
  firstText: PropTypes.string,
  secondText: PropTypes.string,
};
