import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useEffectSlider from "@/hooks/useEffectSlider";

import { deleteAttachment, downloadAttachment } from "@/store/reducers/app";
import { fetchPayments, setInPaymentSlice } from "@/store/reducers/payments";
import { setTags } from "@/store/reducers/tags";
import {
  clearBankDetailsForm,
  fetchAndSelectVendor,
  fetchVendorCreateRequirements,
  mergeIntoBankDetailsForm,
  mergeIntoVendorListItem,
  resendVendorMail,
  setBankDetailsForm,
  setSelectedVendor,
  setSelectedVendorAttachment,
  setVendorCreateRequirements,
  updateVendor,
  verifyVendor,
} from "@/store/reducers/vendors";

import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";
import { accountingCategoryTagSelector } from "@/store/selectors/tags";
import {
  bankDetailsFormSelector,
  isFetchingSelectedVendorSelector,
  isFetchingVendorCreateRequirementsSelector,
  isLoadingSelector,
  isResendVendorMailInProgressSelector,
  isVerifyingVendorSelector,
  selectedVendorSelector,
  vendorCreateRequirementsSelector,
} from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import CopytoClipboard from "@/components/core/CopyToClipboard";
import FileUpload from "@/components/core/FileUpload";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";

import GenericForm from "@/components/GenericForm";
import { generatePayloadFromFormValue } from "@/components/GenericForm/common";
import InvoiceTable from "@/components/common/BillPayAndPayroll/VendorOrEmployee/InvoiceTable";
import RecurringPaymentsTable from "@/components/common/BillPayAndPayroll/VendorOrEmployee/RecurringPaymentsTable";
import AccountingCategoryInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/AccountingCategoryInput";
import AccountingVendorInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/AccountingVendorInput";
import DocumentInfo from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/DocumentInfo";
import OwnerInfo from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/OwnerInfo";
import VendorSliderHeader from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/VendorSliderHeader";
import { ERROR_VALUE, useForm } from "@/utils/useForm";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { BILLPAY_FILTERS, PAYMENT_STATUSES } from "@/utils/constants/payments";
import {
  REGEX_VALUE,
  USE_FORM_TYPE_OF_VALIDATION,
} from "@/utils/constantUseForm";
import {
  createFileFormData,
  deepCamelToSnake,
  flattenObject,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import { ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP } from "@/constants/company";
import { DEFAULT_ACCEPT_WITH_PDF } from "@/constants/fileViewer";
import { TAG_FIELD_TYPES } from "@/constants/tags";
import {
  ALL_VENDOR_CTAS,
  PAYMENT_PROVIDER_DATA_KEYS,
  VENDOR_CREATE_REQUIREMENTS_PARAMS,
  VENDOR_SHOW_RESPONSE,
  VENDOR_SLIDER_INVOICES_TABS,
  VENDOR_STATUS,
  VENDOR_TYPES,
  VENDOR_TYPE_LABELS,
  VENDOR_UPDATE_REQUEST_PARAMS,
  VENDOR_VERIFY_REQUEST_KEY,
} from "@/constants/vendors";

/**
 * Vendor/Employee detail slider (opens on row click)
 */
export default function VendorSlider({ context, searchParamKey, setOnClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const [apiCallFailed, setApiCallFailed] = useState(false);
  const [outerFooterDisableState, setOuterFooterDisableState] = useState(true);
  const documentSectionRef = useRef();
  const bankDetailsSectionRef = useRef();

  // for duplicate email detect, to avoid js cyclic code issue,
  // useForm outputs something that I need to use in next render cycle
  const dataRef = useRef();

  const isResendLoading = useSelector(isResendVendorMailInProgressSelector);
  const id = inPayrollContext
    ? parseInt(searchParams.get(SLIDERS_SEARCH_PARAMS.employees.id), 10)
    : parseInt(searchParams.get(SLIDERS_SEARCH_PARAMS.vendors.id), 10);

  const vendor = useSelector(selectedVendorSelector);
  const isFetching = useSelector(isFetchingSelectedVendorSelector);
  const {
    id: tagId = -1,
    fieldType: accountingCategoryType,
    options: accountingCategoryOptions = [],
    textValue: accountingCategoryTextValue = "",
  } = useSelector(accountingCategoryTagSelector) ?? {};
  const accountingCategoryTypeIsText =
    accountingCategoryType === TAG_FIELD_TYPES.TEXT;
  const isVendorUpdating = useSelector(isLoadingSelector);
  const isVendorVerifying = useSelector(isVerifyingVendorSelector);
  const ctas = vendor?.ctas ?? [];
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );
  const isFetchingRequirements = useSelector(
    isFetchingVendorCreateRequirementsSelector
  );
  const bankDetailsRequirements =
    useSelector(vendorCreateRequirementsSelector) ?? []; // used for bank details
  const isBeneficiaryDetailsPresent = vendor?.bankDetailsPresent;
  const isMandatoryDetailsPresent = !!vendor?.otherDetails;
  const isBankDetailsUnVerified = vendor?.status === VENDOR_STATUS.UNVERIFIED;
  // derived
  const showVerifyUI = isBeneficiaryDetailsPresent && isBankDetailsUnVerified;
  const showMandatorySection =
    (isBeneficiaryDetailsPresent && !isMandatoryDetailsPresent) || showVerifyUI;
  // we update mandatory details, and verify together in order, so show both until API calls are finished
  const contactDetails = vendor?.contactDetails ?? [];

  const [isEditable, setIsEditable] = useState(false);
  const [selectedInvoiceTab, setSelectedInvoiceTab] = useState(
    VENDOR_SLIDER_INVOICES_TABS[0]
  );
  const vendorTypeOptions = [
    {
      value: VENDOR_TYPES.INDIVIDUAL,
      label: VENDOR_TYPE_LABELS[VENDOR_TYPES.INDIVIDUAL],
    },
    {
      value: VENDOR_TYPES.CORPORATE,
      label: VENDOR_TYPE_LABELS[VENDOR_TYPES.CORPORATE],
    },
  ];

  // for UI use only, bank details is a object with multiple parts in BE
  const bankDetailsNestedData = flattenObject({
    ...vendor?.bankDetails,
    ...vendor?.otherDetails,
  });
  // this variable should be added for all diff variations of firstName and lastName
  const firstNameKeys = ["firstName", "first_name"];
  const lastNameKeys = ["lastName", "last_name"];
  function sortKeysBankDetail(data) {
    const sortedKeys = [...data];
    sortedKeys.sort(([a, _], [b, __]) => {
      if (firstNameKeys?.includes(a)) {
        return -1;
      }
      if (firstNameKeys?.includes(b)) {
        return 1;
      }
      if (lastNameKeys?.includes(a)) {
        return -1;
      }
      if (lastNameKeys?.includes(b)) {
        return 1;
      }
      return 0;
    });

    return sortedKeys;
  }

  const bankDetailsArray = sortKeysBankDetail(
    Object.entries(bankDetailsNestedData)
  )
    .map(([k, v]) => ({
      name: k,
      value: v,
      label: t(ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP[k]),
    }))
    .filter((item) => {
      return item.name !== "entityType" && typeof item.value !== typeof {};
    });

  const files = vendor?.attachments ?? [];

  const CONTACT_ENUMS =
    VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.RESPONSE_VALUES;

  const [contacts, setContacts] = useState([]);

  const addContactsHandler = () => {
    const lastContact = contacts?.at(-1);
    const lastContactId =
      lastContact && `${lastContact?.id}`?.startsWith("new")
        ? +`${lastContact?.id}`.split("-").at(-1) // `212` or `new-1` => 212 or 1
        : 0;
    const newContactId = `new-${lastContactId + 1}`;

    setContacts((prev) => [
      ...prev,
      {
        [CONTACT_ENUMS.ID]: newContactId,
        [CONTACT_ENUMS.NAME]: "",
        [CONTACT_ENUMS.EMAIL]: "",
        [CONTACT_ENUMS.PHONE]: "",
      },
    ]);
  };
  const deleteContactHandler = (contactId) => {
    setContacts((prev) => {
      const isLastContact =
        prev.reduce((accum, item) => accum + (item?._destroy ? 0 : 1), 0) === 1;
      return prev.map((contact) => {
        if (contact?.[CONTACT_ENUMS.ID] !== contactId) return contact;
        return isLastContact
          ? {
              [CONTACT_ENUMS.ID]: contactId,
              [CONTACT_ENUMS.NAME]: "",
              [CONTACT_ENUMS.EMAIL]: "",
              [CONTACT_ENUMS.PHONE]: "",
            }
          : { ...contact, _destroy: true };
      });
    });
  };

  useEffect(() => {
    setContacts(contactDetails);
  }, [contactDetails.length]);

  // form
  const initialFormValue = {
    vendorName: {
      value: vendor?.name ?? "",
      validate: {
        required: true,
      },
    },

    ...contacts?.reduce((accum, detail) => {
      const contactId = detail?.id;

      const prefix = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${contactId}`;
      accum = {
        ...accum,
        [`${prefix}.${CONTACT_ENUMS.ID}`]: {
          value: detail[CONTACT_ENUMS.ID],
          validate: {
            required: true,
          },
        },
        [`${prefix}.${CONTACT_ENUMS.NAME}`]: {
          value: detail[CONTACT_ENUMS.NAME],
          validate: {
            required: false,
          },
        },
        [`${prefix}.${CONTACT_ENUMS.EMAIL}`]: {
          value: detail[CONTACT_ENUMS.EMAIL],
          validate: {
            regex: REGEX_VALUE.email,
            required: true,
          },
          errorStatement: {
            regex: ERROR_VALUE.email.regex,
          },
        },
        [`${prefix}.${CONTACT_ENUMS.PHONE}`]: {
          value: detail[CONTACT_ENUMS.PHONE],
          validate: {
            required: false,
          },
        },
        [`${prefix}._destroy`]: {
          value: detail?._destroy,
          validate: {
            required: false,
          },
        },
      };

      return accum;
    }, {}),

    ...(accountingEnabled
      ? {
          // dropdown, when software is ON
          [VENDOR_UPDATE_REQUEST_PARAMS.SELECTED_VENDOR]: {
            value: vendor?.[VENDOR_SHOW_RESPONSE.SELECTED_VENDOR] || -1,
            validate: {
              required: true,
            },
          },
          //
          // array
          accountingCategory: {
            value:
              (accountingCategoryTypeIsText
                ? vendor?.accountingTags?.[0]?.textValue
                : vendor?.accountingTags?.[0]?.tagValueId) ||
              (accountingCategoryTypeIsText ? accountingCategoryTextValue : ""),
            validate: { required: false },
          },
          accountingCategoryId: {
            // BE key
            value: vendor?.accountingTags?.[0]?.id,
            validate: { required: false },
          },
        }
      : {}),
    vendorType: {
      // entity type
      value: inPayrollContext ? VENDOR_TYPES.INDIVIDUAL : (vendor?.type ?? ""),
      validate: {
        required: true,
        ...(inPayrollContext
          ? {}
          : {
              [USE_FORM_TYPE_OF_VALIDATION.oneOf]: [
                VENDOR_TYPES.CORPORATE,
                VENDOR_TYPES.INDIVIDUAL,
              ],
            }),
      },
      errorStatement: {
        ...(inPayrollContext
          ? {}
          : {
              [USE_FORM_TYPE_OF_VALIDATION.oneOf]:
                "billPay.vendors.slider.form.errors.notASupportedValue",
            }),
      },
    },
    employeeId: {
      value: vendor?.employeeId,
      validate: { required: inPayrollContext },
    },
  };

  const onSimpleValuesSubmit = () => {
    const isDuplicateEmailPresent =
      dataRef.current.duplicateEmailStates.length &&
      dataRef.current.duplicateEmailStates.some(Boolean);
    if (isDuplicateEmailPresent) return;

    const payload = {
      [VENDOR_UPDATE_REQUEST_PARAMS.VENDOR_TYPE]: values.vendorType,
      [VENDOR_UPDATE_REQUEST_PARAMS.NAME]: values.vendorName || null,
      [VENDOR_UPDATE_REQUEST_PARAMS.EMPLOYEE_ID]: values.employeeId,
    };

    if (accountingEnabled) {
      // accounting vendor
      payload[VENDOR_UPDATE_REQUEST_PARAMS.SELECTED_VENDOR] =
        values[VENDOR_SHOW_RESPONSE.SELECTED_VENDOR]?.toString() ===
        (-1).toString()
          ? null
          : values[VENDOR_UPDATE_REQUEST_PARAMS.SELECTED_VENDOR];

      // accounting category
      const fetchedValue =
        (accountingCategoryTypeIsText
          ? vendor?.accountingTags?.[0]?.textValue
          : vendor?.accountingTags?.[0]?.tagValueId) ||
        (accountingCategoryTypeIsText ? accountingCategoryTextValue : "");
      const currentValue = values?.accountingCategory;
      const isValueChanged = fetchedValue !== currentValue;

      if (isValueChanged) {
        payload[VENDOR_UPDATE_REQUEST_PARAMS.ACCOUNTING_VENDOR_TAG.KEY] = [
          // current selected value
          {
            [VENDOR_UPDATE_REQUEST_PARAMS.ACCOUNTING_VENDOR_TAG.TAG_ID]: tagId,
            [VENDOR_UPDATE_REQUEST_PARAMS.ACCOUNTING_VENDOR_TAG.TAG_ID]: tagId,
            [accountingCategoryTypeIsText
              ? VENDOR_UPDATE_REQUEST_PARAMS.ACCOUNTING_VENDOR_TAG
                  .TAG_TEXT_VALUE
              : VENDOR_UPDATE_REQUEST_PARAMS.ACCOUNTING_VENDOR_TAG
                  .TAG_VALUE_ID]: values.accountingCategory,
            // no id, creation
          },

          {
            [VENDOR_UPDATE_REQUEST_PARAMS.ACCOUNTING_VENDOR_TAG.ID]:
              values?.accountingCategoryId,
            [VENDOR_UPDATE_REQUEST_PARAMS.ACCOUNTING_VENDOR_TAG._DESTROY]: true,
            // delete previously selected one
          },
        ];
      }
    }

    if (
      values[VENDOR_UPDATE_REQUEST_PARAMS.SELECTED_VENDOR]?.toString() ===
      (-1).toString()
    ) {
      payload[VENDOR_UPDATE_REQUEST_PARAMS.SELECTED_VENDOR] = null;
    }

    const payloadNested = generatePayloadFromFormValue(values); // linear `values` -> nested `object` based on dot path

    // add contact details array
    payload[VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY] = (() => {
      const CONTACT_ENUMS_SENDING =
        VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES;
      const contactDetailsArrayPayload = Object.values(
        payloadNested[VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY]
      ).map((contactDetail) => ({
        [CONTACT_ENUMS_SENDING.NAME]: contactDetail[CONTACT_ENUMS.NAME],
        [CONTACT_ENUMS_SENDING.EMAIL]: contactDetail[CONTACT_ENUMS.EMAIL],
        [CONTACT_ENUMS_SENDING.PHONE]: contactDetail[CONTACT_ENUMS.PHONE],
        [CONTACT_ENUMS_SENDING.ID]: `${
          contactDetail[CONTACT_ENUMS.ID]
        }`?.startsWith("new")
          ? null
          : contactDetail[CONTACT_ENUMS.ID],
        _destroy: contactDetail?._destroy ?? false,
      }));
      return contactDetailsArrayPayload;
    })();

    const onSuccess = () => {
      setIsEditable(false);
    };
    const onError = () => {
      setIsEditable(true);
    };

    dispatch(updateVendor({ id, payload, context, onSuccess, onError })); // update simple value
  };

  const {
    handleChange,
    values,
    _setValues: setValues,
    _setErrors: setErrors,
    errors,
    handleSubmit,
  } = useForm(initialFormValue, onSimpleValuesSubmit, {
    isFetchingInitialValue: isFetching,
  });

  const contactsEmails = contacts
    ?.filter((data) => !data?._destroy)
    .map((detail) => {
      const contactId = detail?.id;
      const prefix = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${contactId}`;
      return values[`${prefix}.${CONTACT_ENUMS.EMAIL}`]?.trim?.();
    });
  const duplicateEmailStates = useMemo(() => {
    const _duplicateEmailStates = contacts
      ?.filter((data) => !data?._destroy)
      .map((detail, index) => {
        const contactId = detail?.id;
        const prefix = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${contactId}`;
        const emailKey = `${prefix}.${CONTACT_ENUMS.EMAIL}`;
        const value = values[emailKey]?.trim?.();
        const error = errors[emailKey];

        if (!value) return false;

        return contactsEmails.some(
          (email, index2) =>
            email && !error && email === value && index > index2
        );
      });
    dataRef.current = {
      ...dataRef.current,
      duplicateEmailStates: _duplicateEmailStates,
    };
    return _duplicateEmailStates;
  }, [JSON.stringify(contactsEmails)]);

  // file stuff
  const fileUploadHandler = (latestFileArray) => {
    const filesToUpload = latestFileArray.filter(
      (item) => item instanceof File
    );

    if (filesToUpload?.length === 0) return;

    const formPayload = createFileFormData(
      filesToUpload,
      VENDOR_UPDATE_REQUEST_PARAMS.ATTACHMENTS
    );
    dispatch(updateVendor({ id, payload: formPayload, context })); // update files
  };
  const onSuccessDelete = (index) => {
    const remainingFiles = files?.filter((_, i) => i !== index);
    dispatch(
      setSelectedVendorAttachment({
        attachments: remainingFiles,
      })
    );
    dispatch(
      mergeIntoVendorListItem({
        id: vendor?.id,
        value: { hasContract: !!remainingFiles?.length },
      })
    );
  };

  const primaryAction = {
    handler: (index) => {
      dispatch(
        deleteAttachment({
          id: files[index]?.id,
          onSuccess: onSuccessDelete(index),
        })
      );
    },
    label: t("billPay.vendors.slider.form.actions.delete"),
    icon: "Delete",
    iconClasses: "text-danger-600 bg-danger-50",
  };

  const secondaryAction = {
    handler: (index) => {
      const file = files[index];
      if (file instanceof File) return;
      dispatch(downloadAttachment({ file, id: file?.id }));
    },
    label: t("billPay.vendors.slider.form.actions.download"),
    icon: "Download",
    className: "text-neutral-500",
    bgClassName: "bg-neutral-50",
  };

  // form CTAs (appear if editable)
  const [valueStoredForAfterDiscard, setValueStoredForAfterDiscard] =
    useState(null);
  const [errorStoredForAfterDiscard, setErrorStoredForAfterDiscard] =
    useState(null);

  const discardHandler = () => {
    setIsEditable(false);
    setValues(valueStoredForAfterDiscard);
    setContacts(contactDetails);
    setErrors(errorStoredForAfterDiscard);
    setValueStoredForAfterDiscard(null);
    setErrorStoredForAfterDiscard(null);
  };

  // verify vendor, payload is mandatory details
  const onSubmitMandatoryDetails = (bankDetails) => {
    const payload = {
      [VENDOR_VERIFY_REQUEST_KEY]: JSON.stringify(
        deepCamelToSnake(
          generatePayloadFromFormValue(
            bankDetails[PAYMENT_PROVIDER_DATA_KEYS.REQUEST.OTHER]
          )
        ) // payload === { other_details: "{}" }
      ),
    };

    dispatch(verifyVendor({ id, payload, context }));
  };

  const openAddBankDetailsPage = () => {
    // meant to render when coming back from bank form, they'll click save here
    searchParams.append(
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.employees.overrideEdit
        : SLIDERS_SEARCH_PARAMS.vendors.overrideEdit,
      vendor?.id
    );

    // page that'll open on add bank
    searchParams.append(
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.employees.preAddBankDetails
        : SLIDERS_SEARCH_PARAMS.vendors.preAddBankDetails,
      true
    );
    setSearchParams(searchParams);
  };

  const editVendorDetailsHandler = () => {
    setIsEditable(true);
    setValueStoredForAfterDiscard(values);
    setErrorStoredForAfterDiscard(errors);
  };

  const editBankDetailsHandler = () => {
    searchParams.append(
      showMandatorySection
        ? inPayrollContext
          ? SLIDERS_SEARCH_PARAMS.employees.overrideEdit
          : SLIDERS_SEARCH_PARAMS.vendors.overrideEdit
        : inPayrollContext
          ? SLIDERS_SEARCH_PARAMS.employees.edit
          : SLIDERS_SEARCH_PARAMS.vendors.edit,
      id
    );

    setSearchParams(searchParams);
  };

  const onError = () => {
    setApiCallFailed(true);
  };

  const resendEmailHandler = () => {
    dispatch(resendVendorMail({ id }));
  };

  useEffectSlider(
    () => {
      dispatch(
        fetchAndSelectVendor(
          inPayrollContext ? { id, flag: true } : { id, flag: false }
        )
      );

      return () => {
        dispatch(setTags([]));
        dispatch(setSelectedVendor(null));
        dispatch(clearBankDetailsForm(null));
      };
    },
    [],
    { searchParamKey, setOnClose }
  );

  useEffect(() => {
    // scroll to bank details

    if (
      !isFetching &&
      vendor &&
      searchParams.get(SLIDERS_SEARCH_PARAMS.focus) === "bankDetailsSection"
    ) {
      bankDetailsSectionRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [!!bankDetailsSectionRef?.current?.scrollIntoView, isFetching, vendor]);

  // fill mandatory details. params here were already stored in system in mail flow
  useEffect(() => {
    if (isFetching || !vendor || !showMandatorySection) return;

    const beneficiaryCountry =
      vendor?.beneficiaryCountry || vendor?.bankDetails?.country;
    const beneficiaryBankCountry =
      vendor?.beneficiaryBankCountry ||
      vendor?.bankDetails?.bankAccount?.country;

    const requirementsPayload = generatePayloadFromFormValue({
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.PAYOUT_METHOD_TYPE]:
        vendor?.paymentMethodType,
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.SENDER_CURRENCY]:
        vendor?.senderCurrency,
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]:
        beneficiaryCountry,
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]:
        vendor?.beneficiaryCurrency,
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]:
        beneficiaryBankCountry,
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE]: vendor?.type,
      // to get only 'mandatory details section'
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_DETAIL_CONTROL_FIELD]: false,
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.OTHER_DETAILS_CONTROL_FIELD]: true,
    });
    dispatch(fetchVendorCreateRequirements({ payload: requirementsPayload }));

    return () => {
      dispatch(setVendorCreateRequirements(null));
    };
  }, [isFetching, vendor, showMandatorySection]);

  const nameLabel = (() => {
    if (inPayrollContext && accountingEnabled)
      return t(
        "payroll.payrollEmployees.addEmployee.employeeNameInAccountingSoftware",
        {
          accountingSoftware:
            ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingIntegrationSoftware],
        }
      );

    if (inPayrollContext && !accountingEnabled)
      return t("payroll.payrollEmployees.addEmployee.employeeName");

    if (!inPayrollContext && accountingEnabled)
      return t("billPay.vendors.createVendor.vendorNameInAccountingSoftware", {
        accountingSoftware:
          ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingIntegrationSoftware],
      });

    return t("billPay.vendors.createVendor.vendorName");
  })();

  // delete button in footer
  const deleteButtonHandler = () => {
    searchParams.set(
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.employees.delete
        : SLIDERS_SEARCH_PARAMS.vendors.delete,
      id
    );
    setSearchParams(searchParams);
  };

  // check processing payments if any from vendor
  const PAYMENT_PROCESSING_SLICE_KEY = "PAYMENT_PROCESSING_SLICE_KEY";
  const isPaymentsProcessing = !!useSelector(
    (store) => store.payments[PAYMENT_PROCESSING_SLICE_KEY]?.total
  );

  useEffect(() => {
    if (!id) return;
    dispatch(
      setInPaymentSlice({
        key: PAYMENT_PROCESSING_SLICE_KEY,
        value: {},
      })
    );
    dispatch(
      fetchPayments({
        key: PAYMENT_PROCESSING_SLICE_KEY,
        value: {
          [BILLPAY_FILTERS.PAYMENT]: id,
          page: 1,
          limit: 1,
          status: PAYMENT_STATUSES.processing,
        },
      })
    );
  }, [id]);

  // useEffect(() => {
  //   setOnClose((searchParamsArray) => {
  //     if (!searchParamsArray.includes(SLIDERS_SEARCH_PARAMS.vendors.id)) {
  //       dispatch(setSelectedVendor(null));
  //     }
  //   });
  // }, []);

  // TODO slider loader
  if (isFetching) return "isFetching";
  if (!vendor) return "Error";

  return (
    <>
      <div className="pb-16 slider-content-core">
        <VendorSliderHeader
          vendor={vendor}
          isFetching={isFetching}
          inPayrollContext={inPayrollContext}
        />
        {/* Owner, Document Cards */}
        <div className="flex items-stretch w-full gap-5 mt-5">
          {inPayrollContext ? null : (
            <OwnerInfo vendor={vendor} isFetching={isFetching} />
          )}
          <DocumentInfo
            vendor={vendor}
            isFetching={isFetching}
            onClick={() =>
              documentSectionRef.current.scrollIntoView({
                behavior: "smooth",
              })
            }
          />
        </div>
        {/* Recent + largest invoices, OR Recent salary payments */}
        <div className="mt-9">
          {inPayrollContext ? (
            <Text
              translationKey="payroll.employeeDetails.slider.recentSalaryPaymentHeading"
              classes="text-lg font-semibold text-neutral-800"
            />
          ) : (
            <Tabs
              items={VENDOR_SLIDER_INVOICES_TABS}
              selectedTab={selectedInvoiceTab?.key}
              setCurrentTab={(tab) => {
                setSelectedInvoiceTab(tab);
              }}
              mode
            />
          )}

          <InvoiceTable
            vendorId={vendor?.id}
            tab={selectedInvoiceTab}
            context={context}
            sliderTable
          />
        </div>
        {/* Recurring Payments Table */}
        <div className="flex flex-col font-medium mt-9">
          <Text
            translationKey="billPay.vendors.slider.recurringPayments"
            classes="text-lg font-semibold text-neutral-800"
          />
          <RecurringPaymentsTable vendorId={vendor?.id} context={context} />
        </div>
        {/* Contract & Documents */}
        <div
          className="flex flex-col font-medium mt-9"
          ref={documentSectionRef}
        >
          <span className="text-lg text-neutral-800">
            <Text
              translationKey="billPay.vendors.slider.contractDocuments.title"
              classes="text-lg font-semibold text-neutral-800"
            />
          </span>
          <Text
            translationKey={
              inPayrollContext
                ? "billPay.vendors.slider.contractDocuments.employeeSubText"
                : "billPay.vendors.slider.contractDocuments.subtext"
            }
            classes="text-sm mb-3 text-neutral-500"
          />
          <FileUpload
            acceptText="common.defaultUploadTextWithPdf"
            accept={DEFAULT_ACCEPT_WITH_PDF}
            files={files}
            handleFileChange={fileUploadHandler}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
          />
        </div>
        <form onSubmit={handleSubmit}>
          {/* Vendor Details */}
          <div className="flex flex-col mt-10 font-medium">
            <div className="flex items-center justify-between">
              <Text
                translationKey={
                  inPayrollContext
                    ? "payroll.employeeDetails.slider.employeeDetails"
                    : "billPay.vendors.slider.vendorDetails"
                }
                classes="text-2xl font-semibold text-neutral-800"
              />
              {!isEditable && ctas?.includes(ALL_VENDOR_CTAS.EDIT) ? (
                <button onClick={editVendorDetailsHandler}>
                  <Icon name="Edit" className="text-neutral-500" />
                </button>
              ) : null}
            </div>
            <div className="mt-8">
              {isFetching ? (
                <LoaderSkeleton fullWidth />
              ) : (
                <AccountingVendorInput
                  dropdownInputKey={
                    VENDOR_UPDATE_REQUEST_PARAMS.SELECTED_VENDOR
                  }
                  textInputKey="vendorName"
                  creatable
                  label={nameLabel}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setValues={setValues}
                  vpSelectProps={{ disabled: !isEditable }}
                  inputProps={{ disabled: !isEditable }}
                  inPayrollContext={inPayrollContext}
                />
              )}
            </div>
            {accountingEnabled ? (
              <div className="mt-9">
                {isFetching ? (
                  <LoaderSkeleton fullWidth />
                ) : (
                  <AccountingCategoryInput
                    dropdownInputKey="accountingCategory"
                    textInputKey="accountingCategory"
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    setValues={setValues}
                    vpSelectProps={{ disabled: !isEditable }}
                    inputProps={{ disabled: !isEditable }}
                  />
                )}
              </div>
            ) : null}

            {inPayrollContext ? (
              <div className="mt-9">
                {isFetching ? (
                  <LoaderSkeleton fullWidth />
                ) : (
                  <Input
                    name="employeeId"
                    label="payroll.payrollEmployees.addEmployee.employeeId"
                    labelExtraClasses="text-xs text-neutral-500"
                    error={errors.employeeId}
                    value={values.employeeId}
                    onChange={(e) => handleChange(e)}
                    disabled={!isEditable}
                  />
                )}
              </div>
            ) : null}

            <div className="mt-9">
              {isFetching ? (
                <LoaderSkeleton fullWidth />
              ) : inPayrollContext ? (
                <Input
                  label="payroll.payrollEmployees.addEmployee.employeeType"
                  name="vendorType"
                  // fixed, so value being human text is fine
                  value={t(VENDOR_TYPE_LABELS[VENDOR_TYPES.INDIVIDUAL])}
                  error={errors?.vendorType}
                  labelExtraClasses="text-neutral-400 text-xs"
                  disabled // always disabled
                />
              ) : (
                <VpSelect
                  name="vendorType"
                  label={
                    inPayrollContext
                      ? "payroll.employeeDetails.slider.employeeType"
                      : "billPay.vendors.createVendor.vendorType"
                  }
                  value={values.vendorType}
                  error={errors?.vendorType}
                  handleChange={handleChange}
                  insideForm
                  placeholder="billPay.vendors.createVendor.selectVendorType"
                  options={vendorTypeOptions}
                  valueKey="value"
                  optionsDisplayKey="label"
                  menuPosition="absolute"
                  translate
                  disabled // always disabled
                />
              )}
            </div>
          </div>
          {/* Contact Details */}
          <div className="font-medium mt-9">
            <Text
              translationKey="billPay.vendors.slider.contactDetails"
              classes="text-lg font-semibold text-neutral-800 mb-3"
            />

            {contacts
              ?.filter((data) => !data?._destroy)
              ?.map((data, index, { length }) => {
                const contactId = data.id;
                const prefix = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${contactId}`;
                return (
                  <div
                    className="grid grid-cols-2 px-3 py-5 mt-3 gap-x-8 gap-y-9 bg-neutral-50 rounded-xl"
                    key={contactId}
                  >
                    <div className="col-span-2">
                      {isEditable ? (
                        <div className="flex justify-end pr-2">
                          <span
                            onClick={() =>
                              isEditable
                                ? deleteContactHandler(data?.[CONTACT_ENUMS.ID])
                                : null
                            }
                            className={`rounded-lg p-1 text-danger-500 ${
                              isEditable
                                ? "cursor-pointer hover:bg-neutral-200"
                                : "opacity-50 cursor-not-allowed"
                            }`}
                          >
                            <Icon name="Close" />
                          </span>
                        </div>
                      ) : null}

                      {isFetching ? (
                        <LoaderSkeleton size={[28, 279]} />
                      ) : (
                        <Input
                          label="billPay.vendors.slider.form.labels.emailID"
                          name={`${prefix}.${CONTACT_ENUMS.EMAIL}`}
                          value={values[`${prefix}.${CONTACT_ENUMS.EMAIL}`]}
                          error={`${
                            duplicateEmailStates[index]
                              ? t(
                                  "billPay.vendors.slider.form.labels.duplicateEmailIdError"
                                )
                              : ""
                          }${duplicateEmailStates[index] ? " " : ""}${
                            errors[`${prefix}.${CONTACT_ENUMS.EMAIL}`]
                              ? errors[`${prefix}.${CONTACT_ENUMS.EMAIL}`]
                              : ""
                          }`}
                          onChange={(e) => handleChange(e)}
                          disabled={!isEditable}
                          type="email"
                        />
                      )}
                    </div>
                    {/* 8px padding to compensate input out of bounds issue */}
                    {isFetching ? (
                      <LoaderSkeleton size={[28, 279]} />
                    ) : inPayrollContext ? null : (
                      <Input
                        label="billPay.vendors.slider.form.labels.name"
                        value={values[`${prefix}.${CONTACT_ENUMS.NAME}`]}
                        name={`${prefix}.${CONTACT_ENUMS.NAME}`}
                        error={errors[`${prefix}.${CONTACT_ENUMS.NAME}`]}
                        onChange={(e) => handleChange(e)}
                        disabled={!isEditable}
                        type="default"
                        showOptional
                      />
                    )}

                    {isFetching ? (
                      <LoaderSkeleton size={[28, 279]} />
                    ) : (
                      <div
                        className={`${inPayrollContext ? "col-span-2" : ""}`}
                      >
                        <Input
                          label="billPay.vendors.slider.form.labels.contactNumber"
                          name={`${prefix}.${CONTACT_ENUMS.PHONE}`}
                          value={values[`${prefix}.${CONTACT_ENUMS.PHONE}`]}
                          error={errors[`${prefix}.${CONTACT_ENUMS.PHONE}`]}
                          onChange={(e) => handleChange(e)}
                          disabled={!isEditable}
                          classes="w-full"
                          type="number"
                          showOptional
                          showNumberInvalidError
                        />
                      </div>
                    )}
                  </div>
                );
              })}

            {inPayrollContext || !isEditable ? null : (
              <>
                <div className="mt-3" />
                <Button
                  className={`text-primary-600 border border-neutral-300 py-1.5 px-5
                  text-sm font-medium rounded-lg flex items-center gap-2 cursor-pointer ${
                    isEditable
                      ? ""
                      : "disabled:opacity-50 disabled:cursor-not-allowed"
                  }`}
                  onClick={addContactsHandler}
                  disabled={!isEditable}
                  variant="tertiary"
                  label="billPay.vendors.createVendor.addAnotherContact"
                  preIcon="Add"
                  iconClasses=" p-0.5 -mr-1"
                  innerClasses="gap-1 font-bold text-xs"
                />
              </>
            )}
          </div>

          {/* Save Changes CTA */}
          {isEditable && (
            <div className="flex items-center gap-4 mt-8">
              <Button
                label="billPay.vendors.createVendor.cancel"
                variant="tertiary"
                classes="w-fit px-5  py-1.5 h-8 text-neutral-500 text-xs font-bold"
                onClick={discardHandler}
              />
              <Button
                label="billPay.vendors.slider.cta.save"
                btnType="submit"
                variant="primary"
                showLoader={isVendorUpdating}
                classes="w-[134px] h-8 text-white text-xs font-bold"
              />
            </div>
          )}
        </form>
        {/* Bank Details */}
        <div className="font-medium mt-9" ref={bankDetailsSectionRef}>
          <div className="flex items-center gap-3" id="bankDetailsSection">
            <Text
              translationKey="billPay.vendors.slider.bankDetails"
              classes="text-lg font-semibold text-neutral-800"
            />
            {isBeneficiaryDetailsPresent ? (
              <CopytoClipboard
                disabled={isFetching}
                text={[t("billPay.vendors.slider.bankDetailsCopiedHeading"), ""]
                  .concat(
                    bankDetailsArray.map(
                      ({ label, value }) => `${t(label)}: ${value}`
                    )
                  )
                  .join("\n")}
                iconClasses="text-neutral-500"
              />
            ) : null}

            {/* right side (beside section title) */}
            {showVerifyUI ? (
              <>
                <div id="verify_bank_details">
                  <Icon
                    name="Error"
                    className="w-full text-white border-none rounded-full cursor-pointer bg-warning-500"
                  />
                </div>
                <Tooltip
                  id="verify_bank_details"
                  direction="top-right"
                  maxWidth={450}
                >
                  <Text translationKey="billPay.vendors.slider.verifyBankDetailsTooltip" />
                </Tooltip>
              </>
            ) : null}
          </div>

          {/* All bank details present, show preview */}
          {isBeneficiaryDetailsPresent ? (
            <div className="grid grid-cols-2 pt-2 mt-3 gap-x-8 gap-y-6">
              {/* 8px padding to compensate input out of bounds issue */}
              {isFetching
                ? Array(6)
                    .fill(null)
                    .map((item, idx) => (
                      <LoaderSkeleton key={idx} size={[28, 279]} />
                    ))
                : bankDetailsArray.map(({ name, label, value }) => (
                    <div className="flex flex-col" key={label}>
                      <Text
                        translationKey={label}
                        classes="font-xs text-neutral-400"
                      />
                      <Text
                        translationKey={value || "-"}
                        classes=" max-w-md break-words whitespace-normal text-neutral-400 border-b border-neutral-200 py-2"
                      />
                    </div>
                  ))}
            </div>
          ) : null}

          {/* Add missing details start flow CTA */}
          {!isBeneficiaryDetailsPresent ? (
            <div>
              <Text
                translationKey={
                  inPayrollContext
                    ? "billPay.vendors.slider.missingBankDetailsDescriptionPayroll"
                    : "billPay.vendors.slider.missingBankDetailsDescription"
                }
                classes="text-neutral-500 font-medium text-sm inline-block"
              />
              <div className="flex items-center gap-4 mt-4">
                {ctas?.includes(ALL_VENDOR_CTAS.RESEND_EMAIL) ? (
                  <Button
                    label="misc.resendEmail"
                    onClick={resendEmailHandler}
                    btnType="button"
                    variant="tertiary"
                    showLoader={isResendLoading}
                    classes="w-[134px] h-8 text-neutral-500 text-xs font-bold"
                  />
                ) : null}
                {ctas?.includes(ALL_VENDOR_CTAS.ADD_BANK_DETAILS) ? (
                  <Button
                    label="billPay.vendors.createVendor.addBankDetails"
                    onClick={openAddBankDetailsPage}
                    btnType="button"
                    preIcon="Add"
                    iconClasses="p-0.5 -mr-1"
                    innerClasses="font-bold text-xs"
                    variant="primary"
                    classes="w-[164px] h-8 text-white text-xs font-bold"
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          {/* Details missing, edit flow ON */}
          {isFetchingRequirements ? (
            <div className="mt-8 gap-9">
              <LoaderSkeleton fullWidth />
              <LoaderSkeleton fullWidth />
              <LoaderSkeleton fullWidth />
            </div>
          ) : null}
          {showMandatorySection &&
          bankDetailsRequirements?.length &&
          !isFetchingRequirements ? (
            <>
              <GenericForm
                onSubmit={onSubmitMandatoryDetails}
                showFooter={false}
                pages={bankDetailsRequirements}
                storeSetterReducer={setBankDetailsForm}
                storeClearReducer={clearBankDetailsForm}
                storeMergeReducer={mergeIntoBankDetailsForm}
                storeSelector={bankDetailsFormSelector}
                isFetching={isFetchingRequirements}
                formId="mandatory-details-form"
                genericFormClasses="font-medium"
                restrictClosingSliderAction
                inSlider={false}
                renderSliderHeader={false} // fixes the slider header not coming bug
                setOuterFooterDisableState={setOuterFooterDisableState}
              />
              {showVerifyUI ? (
                <div className="flex items-center gap-4 mt-8">
                  {ctas?.includes(ALL_VENDOR_CTAS.RESEND_EMAIL) ? (
                    <Button
                      label="misc.resendEmail"
                      onClick={resendEmailHandler}
                      btnType="button"
                      variant="tertiary"
                      showLoader={isResendLoading}
                      classes="w-[134px] h-8 text-neutral-500 text-xs font-bold"
                    />
                  ) : null}

                  <Button
                    label="billPay.vendors.slider.cta.verify"
                    form="mandatory-details-form"
                    disabled={outerFooterDisableState}
                    showLoader={isVendorVerifying}
                    variant="primary"
                    btnType="submit"
                    classes="w-[134px] h-8 text-white text-xs font-bold"
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      <div className="p-6 slider-footer">
        <div className="flex items-center justify-end gap-3">
          {ctas?.includes(ALL_VENDOR_CTAS.ARCHIVE) ? (
            <Button
              label={
                inPayrollContext
                  ? "billPay.vendors.slider.cta.deleteEmployee"
                  : "billPay.vendors.slider.cta.deleteVendor"
              }
              classes="px-5 py-3 text-danger-600 text-btn-lg font-semibold"
              variant="tertiary"
              onClick={deleteButtonHandler}
              disabled={isEditable}
              compact
            />
          ) : null}

          <Button
            id="processing-payments-cannot-edit"
            label={
              inPayrollContext
                ? "billPay.vendors.slider.cta.editEmployee"
                : "billPay.vendors.slider.cta.editVendor"
            }
            classes="px-5 py-3 text-btn-lg font-semibold"
            variant="primary"
            onClick={editBankDetailsHandler}
            disabled={isPaymentsProcessing}
            compact
          />

          {isPaymentsProcessing ? (
            <Tooltip
              id="processing-payments-cannot-edit"
              direction="top-right"
              maxWidth={450}
            >
              <Text
                translationKey={
                  inPayrollContext
                    ? "billPay.vendors.isProcessing.employee"
                    : "billPay.vendors.isProcessing.vendor"
                }
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </>
  );
}

VendorSlider.propTypes = {
  context: PropTypes.string,
  searchParamKey: PropTypes.string,
  setOnClose: PropTypes.func,
};
