import PropTypes from "prop-types";
import React, { useId, useRef } from "react";

import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

/**
 * ViewMoreComponent is a React component that displays a portion of a text,
 * truncates the remaining part, and provides a tooltip showing the non-visible content.
 *
 * @component
 * @example
 * // Example usage of ViewMoreComponent
 * <ViewMoreComponent
 *   renderArray={['Lorem', 'ipsum', 'dolor', 'sit', 'amet']}
 *   containerCss="custom-container-style"
 * />
 *
 * @param {Object} props - The properties of the component.
 * @param {string[]} props.renderArray - An array of strings to be rendered.
 * @param {string} props.containerCss - Additional CSS class for the container element.
 *
 * @returns {JSX.Element} React component.
 */
export default function ViewMoreCompoent({
  renderArray = [],
  containerCss = "",
}) {
  const textRef = useRef();
  const id = useId();
  const _id = id?.replaceAll(":", "");

  return (
    <div className="w-full">
      <div id={`tooltip-${_id}`} className="flex justify-end gap-3">
        <div ref={textRef} className={containerCss}>
          {renderArray?.[0]}
        </div>

        {renderArray?.length > 1 ? (
          <Text
            classes="block text-primary-500 text-end"
            translationKey={`+ ${renderArray.length - 1} more`}
          />
        ) : null}
      </div>

      {renderArray?.length > 1 ? (
        <Tooltip direction="bottom" id={`tooltip-${_id}`}>
          {renderArray.map((item, index) =>
            index > 0 ? (
              <div key={`$${item}-${index}`}>
                <Text classes="font-semibold" translationKey={item} />
              </div>
            ) : null
          )}
        </Tooltip>
      ) : null}
    </div>
  );
}

ViewMoreCompoent.propTypes = {
  renderArray: PropTypes.array,
  containerCss: PropTypes.string,
};
