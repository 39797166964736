import { Route, Routes } from "react-router-dom";

// components
import ProtectedRoute from "@/components/ProtectedRoute";

// card pages
import PhysicalCards from "@/pages/cards/physical-cards";
import AllPhysicalCards from "@/pages/cards/physical-cards/all";
import BlockedPhysicalCards from "@/pages/cards/physical-cards/blocked";
import YetToActivatePhysicalCards from "@/pages/cards/physical-cards/yet-to-activate";
import CardRequests from "@/pages/cards/requests";
import AllTab from "@/pages/cards/requests/all";
import NeedsYourAction from "@/pages/cards/requests/needs-your-action";
import PendingTab from "@/pages/cards/requests/pending";
import CardSettings from "@/pages/cards/settings";
import GeneralPage from "@/pages/cards/settings/general";
import SpendControlPage from "@/pages/cards/settings/spend-control";
import VirtualCards from "@/pages/cards/virtual-cards";
import VirtualCardsAll from "@/pages/cards/virtual-cards/all";
import VirtualBlockedCard from "@/pages/cards/virtual-cards/blocked";
import { ROUTES } from "@/constants/routes";
import ExpensesRoutes from "@/routes/expenses";

export default function CardRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<ExpensesRoutes />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={`${ROUTES.cards.expenses.pathName}/*`}
          element={<ExpensesRoutes />}
        />
        <Route
          path={ROUTES.cards.physicalCards.pathName}
          element={<PhysicalCards />}
        >
          <Route
            path={ROUTES.cards.physicalCards.all.pathName}
            element={<AllPhysicalCards />}
          />
          <Route
            path={ROUTES.cards.physicalCards.blocked.pathName}
            element={<BlockedPhysicalCards />}
          />
          <Route
            path={ROUTES.cards.physicalCards.yetToActivate.pathName}
            element={<YetToActivatePhysicalCards />}
          />
        </Route>
        <Route
          path={ROUTES.cards.virtualCards.pathName}
          element={<VirtualCards />}
        >
          <Route
            path={ROUTES.cards.virtualCards.all.pathName}
            element={<VirtualCardsAll />}
          />
          <Route
            path={ROUTES.cards.virtualCards.blocked.pathName}
            element={<VirtualBlockedCard />}
          />
        </Route>
        <Route path={ROUTES.cards.requests.pathName} element={<CardRequests />}>
          <Route
            path={ROUTES.cards.requests.needsYourAction.pathName}
            element={<NeedsYourAction />}
          />

          <Route
            path={ROUTES.cards.requests.pending.pathName}
            element={<PendingTab />}
          />

          <Route
            path={ROUTES.cards.requests.all.pathName}
            element={<AllTab />}
          />
        </Route>
        <Route path={ROUTES.cards.settings.pathName} element={<CardSettings />}>
          <Route
            path={ROUTES.cards.settings.general.pathName}
            element={<GeneralPage />}
          />
          <Route
            path={ROUTES.cards.settings.spendControl.pathName}
            element={<SpendControlPage />}
          />
        </Route>
      </Route>
    </Routes>
  );
}
