import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { closeAppModal } from "@/store/reducers/app";
import {
  fetchAndSelectPayment,
  removePayment,
} from "@/store/reducers/payments";
import { archive } from "@/store/reducers/purchase-bills";
import { fetchAndSelectVendor } from "@/store/reducers/vendors";

import { appModalDataSelector, appModalSelector } from "@/store/selectors/app";
import { isActionPendingForPurchaseBillSelector } from "@/store/selectors/purchase-bills";

import { BILL_PAYROLL_CONTEXT } from "@/components/common/BillPayAndPayroll";
import RadioModal from "@/components/common/RadioModal";
import {
  DELETE_PAY_MODAL_OPTION_VALUES,
  PAYMENT_STATUSES,
} from "@/utils/constants/payments";
import { VP_MODALS } from "@/utils/constants/app";

export default function ArchivePaymentModal({ val, target }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const appModal = useSelector(appModalSelector);
  const appModalData = useSelector(appModalDataSelector);

  const isActionPending = useSelector(isActionPendingForPurchaseBillSelector);
  const isPaidOrProcessing = [
    PAYMENT_STATUSES.paid,
    PAYMENT_STATUSES.processing,
  ]?.includes(val?.transactionStatus);

  return (
    <RadioModal
      title="billPay.bill.payments.paymentFooter.archiveRecurringPayment.title"
      description="billPay.bill.payments.paymentFooter.archiveRecurringPayment.description"
      visible={
        [
          VP_MODALS.ARCHIVE_RECURRING_PAYMENT,
          VP_MODALS.ARCHIVE_RECURRING_PAYROLL,
        ].includes(appModal) &&
        appModalData?.id === val?.id &&
        target === appModalData?.target
      }
      onSubmit={(selectedOptionId) => {
        const onSuccess = () => {
          dispatch(closeAppModal());
          if (
            (val?.destroySchedulerOnArchive ||
              val?.recurringPayment?.destroySchedulerOnArchive) &&
            isPaidOrProcessing
          ) {
            dispatch(
              fetchAndSelectPayment({ id: val?.id, isSetListItem: true })
            );
          } else {
            dispatch(removePayment(val?.id));
          }
          dispatch(
            fetchAndSelectVendor({
              id: val?.vendor?.id,
              isSetListItem: true,
            })
          );
          setSearchParams({});
        };

        dispatch(
          archive({
            context: val?.context,
            id: val?.owner?.id ?? val?.id,
            onSuccess,
            ...(val?.destroySchedulerOnArchive ||
            val?.recurringPayment?.destroySchedulerOnArchive
              ? isPaidOrProcessing
                ? { [DELETE_PAY_MODAL_OPTION_VALUES.UPCOMING]: true }
                : { [DELETE_PAY_MODAL_OPTION_VALUES.ARCHIVE_ALL]: true }
              : {}),
          })
        );
      }}
      submitBtnProps={{
        disabled: isActionPending,
        type:
          appModalData?.context === BILL_PAYROLL_CONTEXT.PAYROLL
            ? "success"
            : undefined,
      }}
      configs={[]}
      onClose={() => dispatch(closeAppModal())}
      submitLabel="misc.saveChanges"
    />
  );
}
