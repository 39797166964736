export const formatDataForTable = (transaction) => {
  const formatted = {
    accountingId: transaction?.accountingId,
    id: transaction?.id,
    accountingPayeeId: transaction?.accountingPayeeId,
    accountingStatus: transaction?.accountingStatus,
    editable: transaction?.editable,
    transactionType: {
      name: transaction?.transactionType,
      date: transaction?.transactionDate,
      status: transaction?.accountingStatus,
    },
    amount: transaction?.amount,
    action: {
      status: transaction?.accountingStatus,
      verifiable: transaction?.verifiable,
      syncable: transaction?.syncable,
      ctas: transaction?.accountingCtas,
    },
    verifiable: transaction?.verifiable,
    syncable: transaction?.syncable,
    notActionableReason: transaction?.notActionableReason,
    openIn: transaction?.accountingUrl,
  };

  return formatted;
};
