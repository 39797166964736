import PropTypes from "prop-types";
import React from "react";

import Badge from "@/components/core/Badge";
import Text from "@/components/core/Text";
import { dateToTimeStampString } from "@/utils/common";

export default function ApprovalSliderHeader({
  header,
  submitedDate,
  badgeText,
  badgeColor,
}) {
  return (
    <div>
      <div className="flex items-center gap-2">
        <Text classes="text-3xl font-bold" translationKey={header} />
        <div>
          <Badge
            classes={`p-2 bg-${badgeColor}-50 border-${badgeColor}-200 text-${badgeColor}-600`}
            variant={badgeColor}
            translationKey={badgeText}
          />
        </div>
      </div>
      <Text
        classes="text-neutral-500 text-sm "
        translationKey={`${dateToTimeStampString(submitedDate, false)}`}
      />
    </div>
  );
}
ApprovalSliderHeader.propTypes = {
  header: PropTypes.string,
  submitedDate: PropTypes.string,
  badgeText: PropTypes.string,
  badgeColor: PropTypes.string,
};
