import PropTypes from "prop-types";

import { dateToString } from "@/utils/common";

export default function SubmissionDateCell({ val }) {
  return (
    <div>
      {val?.bulkProcessing
        ? null
        : val.createdAt || val.submissionDate
          ? dateToString(val.createdAt || val.submissionDate)
          : "-"}
    </div>
  );
}

SubmissionDateCell.propTypes = {
  val: PropTypes.object,
};
