import { createSelector } from "@reduxjs/toolkit";

export const selectCreditStore = (store) => store.credit;

export const creditDetailsSelector = createSelector(
  selectCreditStore,
  (credit) => credit.creditDetails
);

export const creditBillsSelector = createSelector(
  selectCreditStore,
  (credit) => credit.creditBills
);

export const isFetchingCreditDetailsSelector = createSelector(
  selectCreditStore,
  (credit) => credit.isFetchingCreditDetails
);

export const isFetchingCreditBillsSelector = createSelector(
  selectCreditStore,
  (credit) => credit.isFetchingCreditBills
);
