import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import BalanceCard from "@/components/Company/Overview/MyAccounts/shared/BalanceCard";
import { amountToCurrency } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";

export default function CashbackAccountWidget({ cashbacks, currency }) {
  console.log("cashbacks", cashbacks);
  const [searchParams, setSearchParams] = useSearchParams();
  const showRedeemCta = cashbacks?.ctas?.length;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between" id="upper">
        <BalanceCard
          iconName="Cashback"
          iconClasses="text-success-500 bg-success-50"
          iconBgClasses="text-success-500 bg-success-50"
          label="dashboard.cashback.availableRedeem"
          amount={cashbacks?.values?.available?.value}
          currency={cashbacks?.values?.available?.currency}
        />
        {showRedeemCta ? (
          <div id="right">
            <div
              className="min-w-[100px] flex flex-col gap-2 items-center hover:cursor-pointer select-none"
              onClick={() => {
                setSearchParams({
                  [SLIDERS_SEARCH_PARAMS.company.cashback]: FORM_TYPE.edit,
                });
              }}
            >
              <div className="flex items-center justify-center w-10 h-10 rounded-full bg-success-50">
                <Icon name="Redeem" className="text-success-600 " />
              </div>
              <Text
                translationKey="dashboard.cashback.redeemNow"
                classes="text-xs font-semibold text-neutral-500"
              />
            </div>
          </div>
        ) : null}
      </div>
      <div
        id="lower"
        className="flex flex-col gap-2 p-4 rounded-md bg-neutral-50"
      >
        {[
          {
            id: 1,
            hint:
              cashbacks?.cashbackType === "monthly"
                ? "dashboard.cashback.previewMonthInfo"
                : "",
            label:
              cashbacks?.cashbackType === "monthly"
                ? "dashboard.cashback.previousMonth"
                : "dashboard.cashback.billingCycle",
            value: amountToCurrency(
              (cashbacks?.cashbackType === "monthly"
                ? cashbacks?.values?.prevMonth?.value
                : cashbacks?.values?.currentCycle?.value) ?? 0,
              cashbacks?.values?.currentCycle?.currency
            ),
          },
          {
            id: 2,
            hint:
              cashbacks?.cashbackType === "monthly"
                ? "dashboard.cashback.TotalCashbackInfo2"
                : "",
            label: "dashboard.cashback.totalCashbackAccrued",
            value: amountToCurrency(cashbacks?.values?.total?.value, currency),
          },
        ].map(({ hint, label, value, id }) => (
          <div className="flex items-center justify-between" key={label}>
            <div className="flex items-center gap-2">
              {/* <Icon name={icon} className="w-3 text-neutral-500" /> */}
              <Text
                translationKey={label}
                classes="text-neutral-500 text-sm font-medium"
              />
              {hint ? (
                <div id={`cashback_${id}`} className="-ml-1">
                  <Icon
                    name="Info"
                    className="text-neutral-300 p-[1.33px] h-4 w-4 hover:cursor-pointer"
                  />
                  <Tooltip id={`cashback_${id}`} direction="right">
                    <Text
                      translationKey={hint}
                      classes="text-sm font-medium text-neutral-500"
                    />
                  </Tooltip>
                </div>
              ) : null}
            </div>
            <span className="text-sm font-semibold text-neutral-800">
              {value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

CashbackAccountWidget.propTypes = {
  cashbacks: PropTypes.object,
  currency: PropTypes.string,
};
