import PropTypes from "prop-types";

import { amountToCurrency } from "@/utils/common";

export default function AmountCell({ val }) {
  const amount =
    val?.amountToBePaid || val.totalAmount || val?.amount || val?.invoiceAmount;

  const text =
    amount?.value && amount?.currency ? amountToCurrency(amount) : null;

  return <span>{text || "-"}</span>;
}

AmountCell.propTypes = {
  val: PropTypes.object,
};
