import PropTypes from "prop-types";
import { useRef } from "react";
import { NavLink } from "react-router-dom";

import Text from "@/components/core/Text";

export default function NavChildLinks({
  links,
  activeNavItemIndex,
  handleHorizontalMovement,
  onNavItemClick,
}) {
  const childRef = useRef(null);
  return activeNavItemIndex !== -1 ? (
    <div
      ref={childRef}
      className="absolute top-0 z-10 h-full left-11 w-19 bg-neutral-50"
      // onMouseLeave={(e) => {
      //   handleHorizontalMovement(e, childRef, false);
      // }}
    >
      <div className="px-3 pt-6 text-left">
        <div className="text-xl font-semibold text-neutral-800">
          <Text translationKey={links[activeNavItemIndex].title} />
        </div>

        <div className="flex flex-col px-2 mt-8">
          {links?.[activeNavItemIndex]?.childLinks &&
            links[activeNavItemIndex].childLinks.map(
              (childLink, childLinkIndex) => (
                <div className="mb-4" key={`child-link-${childLinkIndex}`}>
                  {childLink.path ? (
                    <NavLink
                      to={!childLink?.disabled ? childLink.path : null}
                      className={({ isActive }) =>
                        `text-sm font-semibold ${
                          !childLink?.disabled
                            ? isActive
                              ? "text-primary-500"
                              : "text-neutral-500 hover:text-neutral-800"
                            : "opacity-40 cursor-not-allowed"
                        }`
                      }
                      onClick={!childLink?.disabled ? onNavItemClick : () => {}}
                    >
                      <Text translationKey={childLink.title} />
                    </NavLink>
                  ) : (
                    <div className="text-sm font-semibold text-neutral">
                      <Text translationKey={childLink.title} />
                    </div>
                  )}
                  {childLink.childLinks && childLink.childLinks.length ? (
                    <div className="flex flex-col px-2 mt-2 rounded-lg bg-neutral-100">
                      {childLink.childLinks.map((childrenLink, linkIndex) => (
                        <div
                          className="py-2"
                          key={`child-link-${childrenLink.key}-${linkIndex}`}
                        >
                          {childrenLink.path ? (
                            <NavLink
                              to={
                                !childrenLink?.disabled
                                  ? childrenLink.path
                                  : null
                              }
                              className={({ isActive }) =>
                                `text-sm font-medium ${
                                  !childrenLink?.disabled
                                    ? isActive
                                      ? "text-neutral-800"
                                      : "text-neutral-400 hover:text-neutral-800"
                                    : "opacity-40 cursor-not-allowed"
                                }`
                              }
                              onClick={
                                !childrenLink?.disabled
                                  ? onNavItemClick
                                  : () => {}
                              }
                            >
                              <Text translationKey={childrenLink.title} />
                            </NavLink>
                          ) : (
                            <div className="text-sm font-semibold text-neutral-400">
                              <Text translationKey={childrenLink.title} />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )
            )}
        </div>
      </div>
    </div>
  ) : null;
}

NavChildLinks.propTypes = {
  links: PropTypes.array,
  activeNavItemIndex: PropTypes.number,
  handleHorizontalMovement: PropTypes.func,
  onNavItemClick: PropTypes.func,
};
