import ActionCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ActionCell";
import AmountCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/AmountCell";
import CommmentCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/CommentCell";
import DescriptionCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/DescriptionCell";
import DueDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/DueDateCell";
import InvoiceCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceCell";
import InvoiceDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceDateCell";
import InvoiceNumberCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceNumberCell";
import MonthAndYearCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/MonthAndYearCell";
import PaymentDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentDateCell";
import PaymentStatusCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentStatusCell";
import VendorCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/VendorCell";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  PAYMENTS_TABS,
  PAYROLL_APPROVALS_TABS,
} from "@/utils/constants/payments";

export const getPaymentsTableDefaultHeader = (page, tabKey) => {
  const BILLPAY_PAYMENT_DEFAULT_HEADER = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      numberOfStickyColsRight: 1,
      colWidths: [300, 200, 200, 120, 120, 120, 120, 120, 120, 300, 200],
      rightColWidths: [200],
      styleId: "payments-table",
    },
    emptyDataTitle:
      PAYMENTS_TABS[0]?.key === tabKey
        ? "No payments to be made yet!"
        : "No failed payments as of now!",
    emptySubHeadingDataTitle:
      PAYMENTS_TABS[0]?.key === tabKey
        ? "There are no payments for you to process"
        : "There are no failed bill payments",
    tableHeading: [
      {
        id: "vendor",
        title: "billPay.bill.invoiceInbox.tableHeaders.vendorHeading",
        classes: "text-left",
        cellComponent: VendorCell,
      },
      {
        id: "amount",
        title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },

      {
        id: "paymentstatus",
        title: "billPay.bill.invoiceInbox.tableHeaders.paymentStatus",
        classes: "justify-center",
        cellComponent: PaymentStatusCell,
      },
      {
        id: "paymentDate",
        title: "billPay.bill.invoiceInbox.tableHeaders.paymentDate",
        classes: "text-left",
        cellComponent: PaymentDateCell,
      },
      {
        id: "dueDate",
        title: "billPay.bill.invoiceInbox.tableHeaders.dueDate",
        classes: "text-left",
        cellComponent: DueDateCell,
      },
      {
        id: "invoiceDate",
        title: "billPay.bill.invoiceInbox.tableHeaders.invoiceDate",
        classes: "text-left",
        cellComponent: InvoiceDateCell,
      },
      {
        id: "invoiceNumber",
        title: "billPay.bill.invoiceInbox.tableHeaders.invoiceNumber",
        classes: "text-left",
        cellComponent: InvoiceNumberCell,
      },
      {
        id: "comments",
        title: "billPay.bill.invoiceInbox.tableHeaders.comments",
        classes: "justify-center",
        cellComponent: CommmentCell,
      },
      {
        id: "invoice",
        title: "billPay.bill.invoiceInbox.tableHeaders.invoice",
        classes: "justify-center",
        cellComponent: InvoiceCell,
      },
      {
        id: "description",
        title: "billPay.bill.invoiceInbox.tableHeaders.description",
        classes: "text-left",
        cellComponent: DescriptionCell,
      },

      {
        id: "action",
        title: "billPay.bill.invoiceInbox.tableHeaders.actionHeading",
        classes: "justify-center",
        cellComponent: ActionCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };
  const PAYROLL_PAYMENT_DEFAULT_HEADER = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      numberOfStickyColsRight: 1,
      colWidths: [300, 200, 200, 200, 200, 215, 200],
      rightColWidths: [200],
      styleId: "payments-table",
    },
    emptyDataTitle:
      PAYROLL_APPROVALS_TABS[0]?.key === tabKey
        ? "No salary payments to be made yet!"
        : "No failed salary payments as of now!",
    emptySubHeadingDataTitle:
      PAYROLL_APPROVALS_TABS[0]?.key === tabKey
        ? "There are no salary payments for you to process"
        : "There are no failed salary payments",
    tableHeading: [
      {
        id: "employee",
        title:
          "payroll.salaryPayment.payrollInbox.tableHeaders.employeeHeading",
        classes: "text-left",
        cellComponent: VendorCell,
      },
      {
        id: "amount",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },

      {
        id: "paymentstatus",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.paymentStatus",
        classes: "justify-center",
        cellComponent: PaymentStatusCell,
      },
      {
        id: "forMonth",
        title:
          "payroll.salaryPayment.payrollInbox.tableHeaders.paymentMonthAndYear",
        classes: "justify-center",
        cellComponent: MonthAndYearCell,
      },
      {
        id: "paymentDate",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.paymentDate",
        classes: "text-center justify-center",
        cellComponent: PaymentDateCell,
      },
      {
        id: "description",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.description",
        classes: "text-left",
        cellComponent: DescriptionCell,
      },

      {
        id: "action",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.actionHeading",
        classes: "justify-center",
        cellComponent: ActionCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  const BILLPAY_PAYMENT_BLOCK_USER_HEADER = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 0,
      numberOfStickyColsRight: 0,
      colWidths: [300, 200, 200, 120, 120, 120, 120, 120, 120, 300, 200],
      rightColWidths: [200],
      styleId: "payments-table",
    },
    emptyDataTitle: "misc.noDataAvailable",
    tableHeading: [
      {
        id: "vendor",
        title: "billPay.bill.invoiceInbox.tableHeaders.vendorHeading",
        classes: "text-left",
        cellComponent: VendorCell,
      },
      {
        id: "amount",
        title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
        classes: "text-right",
        cellComponent: AmountCell,
        haveSort: true,
      },
      {
        id: "paymentstatus",
        title: "billPay.bill.invoiceInbox.tableHeaders.paymentStatus",
        classes: "justify-center",
        cellComponent: PaymentStatusCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  switch (page) {
    case BILL_PAYROLL_CONTEXT.BILLPAY:
      return BILLPAY_PAYMENT_DEFAULT_HEADER;
    case BILL_PAYROLL_CONTEXT.PAYROLL:
      return PAYROLL_PAYMENT_DEFAULT_HEADER;
    case BILL_PAYROLL_CONTEXT.BLOCK_USER:
      return BILLPAY_PAYMENT_BLOCK_USER_HEADER;
    default:
      break;
  }
};
