import { Route, Routes } from "react-router-dom";

import ReimbursementSettingsGeneral from "@/components/Reimbursement/Settings/General";
import ReimbursementSettingsSpendControls from "@/components/Reimbursement/Settings/SpendControls";

import ReimbursementSettings from "@/pages/reimbursement/settings";
import { ROUTES } from "@/constants/routes";

export default function ReimbursementSettingsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ReimbursementSettings />}>
        <Route
          path={ROUTES.reimbursement.settings.general.pathName}
          element={<ReimbursementSettingsGeneral />}
        />

        <Route
          path={ROUTES.reimbursement.settings.spendControl.pathName}
          element={<ReimbursementSettingsSpendControls />}
        />
      </Route>
    </Routes>
  );
}
