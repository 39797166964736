import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import usePagination from "@/hooks/usePagination";

import {
  fetchTopCountItems,
  resetTopCountItems,
} from "@/store/reducers/company";

import { totalSpendsSelector } from "@/store/selectors/analytics";
import {
  topCountItemsHasMoreSelector,
  topCountItemsIsFetchingSelector,
  topCountItemsListSelector,
} from "@/store/selectors/company";

import Button from "@/components/core/Button";
import CircleProgress from "@/components/core/CircleProgress";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import SpendTableLoader from "@/components/Company/Overview/SpendAnalytics/SpendListing/SpendTableLoader";
import { amountToCurrency, distributeProportionally } from "@/utils/common";

import {
  SPEND_CATEGORY_REQUEST_VALUES,
  SPEND_TYPE_REQUEST_KEY,
} from "@/constants/company";
import { SHOW_MORE_TABLE_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function SpendTable({ tab, filters }) {
  const dispatch = useDispatch();
  const totalSpend = useSelector(totalSpendsSelector);
  const hasMore = useSelector(topCountItemsHasMoreSelector);
  const list = useSelector(topCountItemsListSelector);
  const isFetching = useSelector(topCountItemsIsFetchingSelector);

  const filterOptions =
    filters?.spendType && filters.to && filters?.from
      ? {
          [SPEND_TYPE_REQUEST_KEY]:
            SPEND_CATEGORY_REQUEST_VALUES[tab?.entityType],
          ...(filters?.to && filters?.from
            ? { before: filters?.to, after: filters?.from }
            : {}),
        }
      : {};

  const loadMore = () => {
    if (Object.keys(filterOptions).length)
      dispatch(
        fetchTopCountItems({
          page: pageNum,
          limit: SHOW_MORE_TABLE_PER_REQUEST_LIMIT, // page specific
          ...filterOptions,
        })
      );
  };
  const onReset = () => {
    dispatch(resetTopCountItems());
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: { ...filterOptions, tab },
  });

  const showMoreHandler = () => {
    setPageNum((prev) => prev + 1);
  };

  const isEmpty = !list?.length;

  return (
    <div>
      <Table
        headerSticky
        colWidths={distributeProportionally([333, 100, 200, 200], 920)}
        styleId="company-overview-spends-table"
      >
        <tr className="text-xs font-semibold text-left text-neutral-800">
          <th>
            <Text
              translationKey={tab.entityDisplayName}
              classes="font-semibold"
            />
          </th>
          <th className="-mx-4 text-left">
            <div className="flex items-center gap-2.5 -mr-4">
              <span>
                {"% "}
                <Text
                  translationKey="cards.listingFields.spent.header"
                  classes="font-semibold"
                />
              </span>
              <div id="cards-listingFields-spent-header">
                <Icon
                  name="Info"
                  className="w-4 h-4 text-neutral-300 hover:cursor-pointer"
                />
                <Tooltip id="cards-listingFields-spent-header" direction="top">
                  <Text
                    translationKey="dashboard.spentTooltip"
                    classes="text-sm font-medium text-neutral-500"
                  />
                </Tooltip>
              </div>
            </div>
          </th>
          <th>
            <div className="flex items-center justify-end gap-3">
              <Text
                translationKey="billPay.bill.payments.paymentListTable.amountHeading"
                classes="font-semibold"
              />
            </div>
          </th>
          <th>
            <div className="flex items-center justify-end gap-3">
              <Text
                translationKey="billPay.bill.payments.paymentListTable.changeFromLastMonth"
                classes="font-semibold"
              />
            </div>
          </th>
        </tr>

        {(!isFetching || !isEmpty) &&
          list.map((val, index) => (
            <tr
              key={`payments-${index}`}
              className="text-sm font-semibold text-center cursor-pointer text-neutral-800"
            >
              <td className="text-center">
                <div className="flex items-center gap-2 text-neutral-800">
                  <ProfileWidget
                    textClasses="font-semibold self-start"
                    name={val?.name}
                    truncateBasedOnSize={false}
                    profileContentSectionClassNames="flex items-start"
                    img={val?.imgUrl}
                  />
                </div>
              </td>
              <td className="text-center">
                <CircleProgress
                  value={val?.percentageSpent ?? 0.0}
                  variant="sm"
                  className="gap-[5.28px]"
                  textClasses="text-xs text-neutral-500 font-medium p-0"
                  iconClasses="w-[11.43px] h-[11.43px]"
                  blankCircleClasses="stroke-[6px]"
                  progressCircleClasses="stroke-[6px]"
                  viewBox="2 -4 28 40"
                />
              </td>
              <td className="text-right">
                <Text
                  classes="text-neutral-800"
                  translationKey={amountToCurrency(
                    val?.currentSpent,
                    val?.currency
                  )}
                />
              </td>
              <td className="text-right">
                <Text
                  classes="text-neutral-800"
                  translationKey={amountToCurrency(
                    (val?.previousSpent ?? 0) - (val?.currentSpent ?? 0),
                    val?.currency
                  )}
                />
              </td>
            </tr>
          ))}
        {isFetching && SpendTableLoader()}
      </Table>
      <div className="mt-6">
        <Button
          label="misc.showMore"
          disabled={!hasMore}
          preIcon="Down"
          onClick={showMoreHandler}
          classes="px-5 py-3 text-primary-500 font-semibold rounded-lg border-neutral-300"
          size="0"
          variant="tertiary"
        />
      </div>
    </div>
  );
}

SpendTable.propTypes = {
  tab: PropTypes.object,
  filters: PropTypes.object,
};
