import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  setCardSettingsFormInfo,
  setCardSliderTab,
  setCreateVirtualCardInput,
} from "@/store/reducers/cards";
import { fetchTags } from "@/store/reducers/tags";

import {
  cardSettingsFormInfoSelector,
  createVirtualCardInputSelector,
} from "@/store/selectors/cards";
import {
  accountingTagsSelector,
  customTagsSelector,
  isFetchingTagsSelector,
} from "@/store/selectors/tags";

import Button from "@/components/core/Button";
import EmptyData from "@/components/core/EmptyData";
import Input from "@/components/core/Input";
import SliderLoader from "@/components/core/SliderLoader";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import {
  FIELD_TYPE_DATE,
  FIELD_TYPE_TEXT,
} from "@/components/GenericForm/common";
import { useForm } from "@/utils/useForm";

import {
  CARD_SETTINGS_FORM_FIELD_KEYS,
  CARD_SLIDER_SELECTED_TABS_CARD,
  COMMON_SLIDERS_TRIGGER_KEYS,
  CREATE_VIRTUAL_CARD_FIELD_KEYS,
} from "@/constants/Cards";
import { TAG_FIELD_TYPES } from "@/constants/tags";

/**
 * cardRules initialValues is considered in this form  {rule_attributes : {tagId: selectedValueId, tagId: value}}
 *
 */

export default function RulesPage({ triggerSlider, sliderParam }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const ref = useLeftHeaderTitle({
    title: "cards.vCards.createCardSlider.cardRules.title",
  });

  const CARD_RULES_CONFIG = {
    [COMMON_SLIDERS_TRIGGER_KEYS.CARD_SLIDER]: {
      formResponseSelector: cardSettingsFormInfoSelector,
      key: CARD_SETTINGS_FORM_FIELD_KEYS.CARD_RULES,
      handleContinueBtnFunc: setCardSettingsFormInfo,
      additionalActionsOnContinue: () => {
        dispatch(setCardSliderTab(CARD_SLIDER_SELECTED_TABS_CARD[1]));
        handleCancel();
      },
    },
    [COMMON_SLIDERS_TRIGGER_KEYS.CREATE_VIRTUAL_CARD]: {
      formResponseSelector: createVirtualCardInputSelector,
      key: CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES,
      handleContinueBtnFunc: setCreateVirtualCardInput,
      additionalActionsOnContinue: () => {
        handleCancel();
      },
    },
  };

  const isFetchingTags = useSelector(isFetchingTagsSelector);

  const accountingTags = useSelector(accountingTagsSelector);
  const customTags = useSelector(customTagsSelector);
  const cardRules = [...accountingTags, ...customTags]?.filter(
    (field) =>
      field?.fieldType !== FIELD_TYPE_TEXT &&
      field?.fieldType !== FIELD_TYPE_DATE
  );
  const disableSetRulesButton = cardRules?.length < 1;
  const selectedSliderRulesConfig = CARD_RULES_CONFIG?.[triggerSlider];
  const formResponseSelector = selectedSliderRulesConfig?.formResponseSelector;
  const cardRulesKey = selectedSliderRulesConfig?.key;
  const addtionalFunc = selectedSliderRulesConfig?.additionalActionsOnContinue;
  const dispatchFunc = selectedSliderRulesConfig?.handleContinueBtnFunc;
  const cardRulesFormInformation = useSelector(formResponseSelector);
  const cardRulesFormResponse = getCardRulesFormInfomation();

  const [formIntialValues, formFieldsSchema] = useMemo(() => {
    const initialValues = {};
    const formSchema = [];
    cardRules.forEach((field, index) => {
      initialValues[field?.id] = {
        value: cardRulesFormResponse?.[field?.id],
      };

      formSchema.push({
        name: field.id,
        type: field?.fieldType,
        options: field?.options,
        label: field.name,
        placeholder: field.name,
        mainHeading:
          index === 0 && accountingTags.length > 0
            ? "Accounting tags"
            : index === accountingTags.length && customTags.length > 0
              ? "Custom tags"
              : "",
      });
    });

    return [initialValues, formSchema];
  }, [accountingTags, customTags]);

  useEffect(() => {
    dispatch(fetchTags({ visible: true }));
  }, []);

  const onSubmit = () => {
    const valuesKey = Object.keys(values);
    const requiredValues = valuesKey.reduce((val, currentKey) => {
      if (values?.[currentKey]) {
        val[`${currentKey}`] = values[currentKey];
      }
      return val;
    }, {});

    setFormReponse(requiredValues);
    addtionalFunc();
  };

  function getCardRulesFormInfomation() {
    return cardRulesFormInformation?.[cardRulesKey];
  }

  function setFormReponse(requiredValues) {
    dispatch(dispatchFunc({ [cardRulesKey]: requiredValues }));
  }

  function handleCancel() {
    searchParams.delete(sliderParam || "");
    setSearchParams(searchParams);
  }

  const { handleChange, values, errors, handleSubmit } = useForm(
    formIntialValues,
    onSubmit,
    {
      isFetchingInitialValue: true,
    }
  );

  return (
    <>
      {!isFetchingTags ? (
        <div className="slider-content-core h-min">
          <div className="flex flex-col gap-3 mb-12">
            <Text
              refProp={ref}
              translationKey="cards.vCards.createCardSlider.cardRules.title"
              classes="text-3xl text-neutral-800 font-bold"
            />
            <Text
              translationKey="cards.vCards.createCardSlider.cardRules.description"
              classes="text-sm text-neutral-500 font-medium"
            />
          </div>

          <form onSubmit={handleSubmit} id="rules_page_form">
            {formFieldsSchema.map((formField, index) => {
              const { name, placeholder, options, label, type } = formField;
              const mainHeading = formField?.mainHeading;

              let FeildComponent = <></>;

              switch (type) {
                case TAG_FIELD_TYPES.LIST:
                  FeildComponent = (
                    <VpSelect
                      name={name}
                      options={options}
                      placeholder={placeholder}
                      label={label}
                      value={values[name]}
                      error={errors[name]}
                      handleChange={(e) => handleChange(e)}
                      menuPosition="absolute"
                      optionsDisplayKey="name"
                      insideForm
                      clearable
                    />
                  );
                  break;

                case FIELD_TYPE_TEXT:
                  FeildComponent = (
                    <Input
                      name={name}
                      value={values[name]}
                      error={errors[name]}
                      placeholder={placeholder}
                      label={label}
                      onChange={(e) => handleChange(e)}
                    />
                  );
                  break;

                default:
                  break;
              }

              return (
                <div className="mb-8" key={`${name}-${index}`}>
                  {mainHeading ? (
                    <div className="mb-5">
                      <Text
                        translationKey={mainHeading}
                        classes="text-neutral-800 font-medium text-lg"
                      />
                    </div>
                  ) : null}
                  {FeildComponent}
                </div>
              );
            })}
          </form>
        </div>
      ) : (
        <div className="absolute z-30 w-full h-full bg-white cursor-progress">
          <SliderLoader />
        </div>
      )}

      {!isFetchingTags && disableSetRulesButton ? (
        <EmptyData
          title="cards.cardDetails.overview.cardRules.emptyData.title"
          description="cards.cardDetails.overview.cardRules.emptyData.description"
        />
      ) : null}

      <div className="flex items-center justify-end gap-6 p-6 slider-footer">
        <Button
          label="cards.vCards.createCardSlider.cancel"
          variant="tertiary"
          classes="w-[150px] text-neutral-500 text-btn-lg bg-white hover:bg-neutral-300 border border-neutral-300"
          onClick={handleCancel}
        />
        <Button
          btnType="submit"
          label="cards.vCards.createCardSlider.cardRules.submitButtonText"
          classes="w-[150px] text-white text-btn-lg"
          form="rules_page_form"
          disabled={disableSetRulesButton}
        />
      </div>
    </>
  );
}
