import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  expenseDeclinesPageFiltersSelector,
  expensesTotalSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  qrPaymentsFailuresPageFiltersSelector,
  qrPaymentsTotalSelector,
} from "@/store/selectors/qr-payments";

import Text from "@/components/core/Text";

import Export from "@/components/Exports";
import DeclinesList from "@/components/common/QrPayAndExpense/common/DeclinesList";
import QrPayAndExpenseFilter from "@/components/common/QrPayAndExpense/common/QRPayAndExpenseFilter";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants/index";
import { convertFilters } from "@/utils/filters";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  EXPORT_FILTER_STATUS,
  EXPORT_PAGE_TYPE,
  EXPORT_TAB_TYPE,
} from "@/constants/exports";

export default function DeclinesOrFailuresPageHelper({
  page,
  filteringParams = {},
  filterSelectorFromParent,
}) {
  const expenseSelectors = {
    filters: useSelector(expenseDeclinesPageFiltersSelector),
    searchParam: SLIDERS_SEARCH_PARAMS.expenses.id,
    totalTransactionSelected: useSelector(expensesTotalSelector),
    pageSubTitle: "expenses.title",
    pageTitle: "expenses.declines.title",
    storeName: "expenses",
  };
  const qrPaymentsSelectors = {
    filters: useSelector(qrPaymentsFailuresPageFiltersSelector),
    searchParam: SLIDERS_SEARCH_PARAMS.qrPayments.payments.id,
    totalTransactionSelected: useSelector(qrPaymentsTotalSelector),
    pageSubTitle: "QRPay.payments.routeTitle",
    pageTitle: "QRPay.failures.title",
    storeName: "qrPayments",
  };
  const currentSelectors =
    page === QRPAY_EXPENSES_CONTEXT.EXPENSES
      ? expenseSelectors
      : qrPaymentsSelectors;

  const [searchParams, setSearchParams] = useSearchParams();
  const appliedFilters = useSelector(appliedFilterSelector);
  const viewTransaction = (id) => {
    searchParams.append(currentSelectors?.searchParam, id);
    setSearchParams(searchParams);
  };
  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <div>
        <Text
          translationKey="cards.title.plural"
          classes="text-neutral-500 text-sm font-semibold"
        />
        /
        <Text
          translationKey={currentSelectors?.pageSubTitle}
          classes="text-neutral-500 text-sm font-semibold"
        />
        <Text
          translationKey={currentSelectors?.pageTitle}
          classes="text-neutral-800 text-3xl font-bold block"
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="w-11/12 ">
          <QrPayAndExpenseFilter
            filters={
              filterSelectorFromParent
                ? filterSelectorFromParent
                : currentSelectors?.filters
            }
          />
        </div>
        <div className="flex justify-end ">
          <Export
            totalExports={currentSelectors?.totalTransactionSelected}
            storeName={currentSelectors?.storeName}
            sectionName={currentSelectors?.storeName}
            additionalFilters={{
              ...convertFilters(appliedFilters),
              ...EXPORT_PAGE_TYPE.EXPENSE,
              ...EXPORT_FILTER_STATUS.EXPENSE.DECLINED,
              tab: EXPORT_TAB_TYPE.EXPENSE.DECLINED,
              ...filteringParams,
            }}
          />
        </div>
      </div>
      <DeclinesList
        filteringParams={filteringParams}
        viewTransaction={viewTransaction}
        page={page}
      />
    </div>
  );
}
DeclinesOrFailuresPageHelper.propTypes = {
  page: PropTypes.string,
  filteringParams: PropTypes.object,
  filterSelectorFromParent: PropTypes.array,
};
