// React imports
import PropTypes from "prop-types";
import React from "react";
import { useSearchParams } from "react-router-dom";

// Core component imports
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

// Constants imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function LinkedToFrame({ showSwitchButton, linkedTo, cardBudgetId }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleLinkedToComponentSlider = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.cards.linkedTo, cardBudgetId);
    setSearchParams(searchParams);
  };

  return (
    <div className="flex justify-between p-4 bg-corporate-cards-50 text-corporate-cards-700">
      {linkedTo?.type || linkedTo?.name ? (
        <div className="flex items-center gap-4">
          <Icon name="Link" className="p-2 bg-white rounded-full w-9 h-9" />

          <Text
            translationKey="cards.cardSlider.linkedToFrame"
            translationProps={{
              type: linkedTo?.type,
              name: linkedTo?.name,
            }}
            classes="text-base font-semibold"
          />
        </div>
      ) : null}

      {showSwitchButton ? (
        <Button
          label="cards.cardSlider.switch"
          classes="btn-default-tertiary btn-md flex py-2 justify-center items-center w-14"
          onClick={handleLinkedToComponentSlider}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default LinkedToFrame;
LinkedToFrame.propTypes = {
  showSwitchButton: PropTypes.bool,
  linkedTo: PropTypes.object,
  cardBudgetId: PropTypes.number,
};
