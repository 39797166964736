import PropTypes from "prop-types";
import React from "react";

import Loader from "@/components/core/Loader";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function OverviewTableLoader({ showTransacrionCell = true }) {
  return [...Array(10)].map((idx, index) => (
    <tr key={`overview-table-loader-${index}`}>
      <td className="pl-5">
        <div className="flex">
          <LoaderSkeleton type="circle" />

          <div className="flex flex-col">
            <LoaderSkeleton size={[10, 130]} />
            <LoaderSkeleton size={[10, 240]} />
          </div>
        </div>
      </td>
      {/* {showTransacrionCell ? (
        <td className="text-center">
          <LoaderSkeleton size="sm" />
        </td>
      ) : null} */}

      <td>
        <div className="flex items-center justify-around">
          <LoaderSkeleton size={[15, 15]} />
          <LoaderSkeleton size={[15, 15]} />
          <LoaderSkeleton size={[15, 15]} />
        </div>
      </td>
      <td className="text-right" style={{ width: "127px" }}>
        <LoaderSkeleton size={[20, 100]} />
      </td>
    </tr>
  ));
}
Loader.propTypes = {
  showTransacrionCell: PropTypes.bool,
};
