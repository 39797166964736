import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchShallowProjects } from "@/store/reducers/company";

import {
  isFetchingProjectsSelector,
  projectsSelector,
} from "@/store/selectors/company";
import { selectedUserSelector, userSelector } from "@/store/selectors/user";
import { selectedVendorSelector } from "@/store/selectors/vendors";

import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";

import { PROJECT_API_FILTERS } from "@/constants/company";
import { VENDOR_LINKED_TO_TYPES } from "@/constants/vendors";

export default function LinkTo({
  errors,
  values,
  setValues,
  handleChange,
  isEditMode,
  metaData = {},
  isVendorOwnerSelected = true, // setting to true for reuse. false will disable projects radio + dropdown.
  // Usage (false) is for create vendor where the dropdown options come filtered according
  // to vendor owner field selected in the same form before
  personId,
  classes,
  showDepartmentNameInLabel = false,
  disabled,
}) {
  const projectOptions = useSelector(projectsSelector);
  const isFetchingProjects = useSelector(isFetchingProjectsSelector);
  const {
    title,
    desc,
    departmentName,
    departmentHelperText,
    projectHelperText,
    disabledTooltipText,
  } = metaData;

  const dispatch = useDispatch();
  const selectedUser = useSelector(selectedUserSelector);

  const { departmentId } = useSelector(userSelector);

  const vendor = useSelector(selectedVendorSelector);
  const ownerDepartmentId =
    selectedUser?.departmentId || vendor?.owner?.departmentId || departmentId;
  useEffect(() => {
    if (personId) {
      dispatch(
        fetchShallowProjects({
          scope: "all",
          [PROJECT_API_FILTERS.PROJECT_MEMBER]: personId,
        })
      );
    }
  }, [personId]);

  const disableProjectRadioInput = projectOptions.length === 0;
  const projectToolTipText =
    disableProjectRadioInput && !isEditMode && isVendorOwnerSelected
      ? disabledTooltipText
      : null;

  return (
    <div className={`flex flex-col  p-4 rounded-lg bg-neutral-50 ${classes}`}>
      {title || desc ? (
        <div className="flex flex-col gap-1">
          {title ? (
            <Text
              translationKey={title}
              classes="text-base text-neutral-800 font-semibold"
            />
          ) : null}
          {desc ? (
            <Text
              translationKey={desc}
              translationProps={departmentName}
              classes="text-xs font-semibold text-neutral-500"
            />
          ) : null}
        </div>
      ) : null}
      <div className="flex items-center mt-4 mb-3">
        <Radio
          name="linkedTo"
          isChecked={
            isVendorOwnerSelected
              ? values?.linkedTo === VENDOR_LINKED_TO_TYPES.DEPARTMENT
              : false
          }
          value={VENDOR_LINKED_TO_TYPES.DEPARTMENT}
          handleChange={(e) => {
            setValues((prev) => ({
              ...prev,
              linkedTo: e,
              projectId: ownerDepartmentId,
              projectName: departmentName,
            }));
          }}
          disabled={disabled || isEditMode || !isVendorOwnerSelected}
          id="department"
          label={
            <div className="flex flex-col ml-1">
              <Text
                translationKey={
                  showDepartmentNameInLabel
                    ? "billPay.vendors.createVendor.departmentOptionTitleWithName"
                    : "billPay.vendors.createVendor.departmentOptionTitle"
                }
                translationProps={{ departmentName }}
                classes="text-base text-neutral-800 font-semibold"
              />
              <Text
                translationKey={departmentHelperText}
                classes="text-sm font-medium text-neutral-500"
              />
            </div>
          }
        />
      </div>
      {/* Link to Project */}
      <div>
        <div className="flex items-center" id="link-to-project-row">
          <Radio
            name="linkedTo"
            isChecked={
              isVendorOwnerSelected
                ? values?.linkedTo === VENDOR_LINKED_TO_TYPES.PROJECT
                : false
            }
            value={VENDOR_LINKED_TO_TYPES.PROJECT}
            handleChange={(e) => {
              setValues((prev) => ({ ...prev, linkedTo: e, projectId: "" }));
            }}
            disabled={
              disabled ||
              isEditMode ||
              !isVendorOwnerSelected ||
              disableProjectRadioInput
            }
            label={
              <div className="flex flex-col ml-1 ">
                <Text
                  translationKey="billPay.vendors.createVendor.projectOptionTitle"
                  classes="text-base text-neutral-800 font-semibold"
                />
                <Text
                  translationKey={projectHelperText}
                  classes="text-sm font-medium text-neutral-500"
                />
              </div>
            }
          />

          {projectToolTipText ? (
            <Tooltip
              id="link-to-project-row"
              direction="bottom-start"
              offset={4}
            >
              <Text
                translationKey={projectToolTipText}
                classes="text-neutral-500 text-sm font-normal"
              />
            </Tooltip>
          ) : null}
        </div>
        {/* select project dropdown */}
        {isVendorOwnerSelected &&
        values?.linkedTo === VENDOR_LINKED_TO_TYPES.PROJECT ? (
          <div className="w-full mt-2 pt-3.5 pl-7">
            <VpSelect
              name="projectId"
              value={values?.projectId}
              error={errors?.projectId}
              handleChange={(e) => {
                const { name = "" } = e;
                handleChange(e);
                setValues((prev) => ({ ...prev, projectName: name })); // record project name
              }}
              menuPosition="absolute"
              options={projectOptions}
              optionsDisplayKey="name"
              valueKey="id"
              disabled={disabled || isEditMode || !isVendorOwnerSelected}
              isOptionsLoading={isFetchingProjects}
              isOptionsFetched={isFetchingProjects}
              classes="py-1"
              label="billPay.vendors.createVendor.selectProject"
              insideForm
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

LinkTo.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  isEditMode: PropTypes.bool,
  metaData: PropTypes.object,
  isVendorOwnerSelected: PropTypes.bool,
  personId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.string,
  showDepartmentNameInLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};
