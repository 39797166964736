export const DATEINPUT_MODES = {
  DATE: "date",
  DATE_RANGE: "date-range",
  MONTH: "month",
  YEAR: "year",
};

// fiscal dates keys half yearly quarter current year
// from current date "last3Month", "last6Month", "next3Month", "next6Month", "today", "yesterday", "tomorrow", "currentMonth", "lastMonth"
export const DATE_RANGE_KEYS = {
  today: "today",
  yesterday: "yesterday",
  tomorrow: "tomorrow",
  currentWeek: "currentWeek",
  last7Days: "last7Days",
  last15Days: "last15Days",
  last30Days: "last30Days",
  currentMonth: "currentMonth",
  lastMonth: "lastMonth",
  last3Month: "last3Month",
  last6Month: "last6Month",
  last12Month: "last12Month",
  next3Month: "next3Month",
  next6Month: "next6Month",
  next12Month: "next12Month",
  currentYear: "currentYear",
  allTime: "allTime",
  currentStatement: "currentStatement",
  prevStatement: "prevStatement",
  quarter: "quarter",
  halfYearly: "halfYearly",
};
export const DATE_RANGE_DAYS_COUNT_MAP = {
  [DATE_RANGE_KEYS.last15Days]: 15,
  [DATE_RANGE_KEYS.last30Days]: 30,
  [DATE_RANGE_KEYS.last7Days]: 7,
};

export const DATE_RANGE_MONTHS_COUNT_MAP = {
  [DATE_RANGE_KEYS.last3Month]: 3,
  [DATE_RANGE_KEYS.last6Month]: 6,
  [DATE_RANGE_KEYS.last12Month]: 12,
  [DATE_RANGE_KEYS.next3Month]: 3,
  [DATE_RANGE_KEYS.next6Month]: 6,
  [DATE_RANGE_KEYS.next12Month]: 12,
};
