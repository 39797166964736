import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { resetRuleStore, setRulePresent } from "@/store/reducers/rules";

import NoRule from "@/components/Accounting/Rules/Slider/NoRule";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { RULE_TYPES } from "@/constants/rules";

import NewRule from "../NewRule";
import RuleCard from "../RuleCard";
import RulesListLoader from "./RulesListLoader";

export default function RulesDetails({
  rules,
  showNewRule,
  handleShowNewRule,
  isFetching,
}) {
  const dispatch = useDispatch();
  const [showNew, setShowNew] = useState(false);
  const close = () => {
    setShowNew(false);
    handleShowNewRule(false);
    dispatch(setRulePresent(null));
    resetRuleStore();
  };

  useEffect(() => setShowNew(showNewRule), [showNewRule]);

  return (
    <div className="flex flex-col">
      {!rules?.length && !showNew && !isFetching ? (
        <NoRule
          type={RULE_TYPES.NORMAL}
          createNewRule={() => {
            setShowNew(true);
          }}
        />
      ) : null}

      {showNew || showNewRule ? <NewRule cancel={close} /> : null}
      <div className="rules-cards my-8 flex flex-col gap-6">
        {rules?.map((rule) => {
          return <RuleCard key={rule.id} rule={rule} />;
        })}
        {isFetching && !rules?.length ? <RulesListLoader /> : null}
      </div>
    </div>
  );
}

RulesDetails.propTypes = {
  rules: PropTypes.array,
  showNewRule: PropTypes.bool,
  handleShowNewRule: PropTypes.func,
  isFetching: PropTypes.bool,
};
