import {
  fetchRequestTableInfo,
  resetPaginationActionCentre,
} from "@/store/reducers/actionCentre";
import {
  fetchReimbursements,
  fetchReportReimbursements,
  fetchReports,
  setReimbursementInitialState,
  setReportInitialState,
} from "@/store/reducers/reimbursement";

import {
  actionCentreTableHasMoreSelector,
  actionCentreTableIsFetchingSelector,
  actionCentreTableListSelector,
  actionCentreTableTotalSelector,
} from "@/store/selectors/actionCentre";
import {
  isFetchingReportsSelector,
  isReimbursementsFetchingSelector,
  reimbursementHasMoreSelector,
  reimbursementTotalSelector,
  reimbursementsListSelector,
  reportsHasMoreSelector,
  reportsListSelector,
  reportsTotalSelector,
} from "@/store/selectors/reimbursement";
import {
  REIMBURSEMENT_KEY,
  REIMBURSEMENT_TYPE_KEY,
  REPORT_KEY,
} from "@/utils/constants/reimbursement";
import { REIMBURSEMENT, REIMBURSEMENT_LABEL } from "@/utils/constants/payments";

import { ACTION_CENTRE_PAGE_CONTEXT } from "./ActionCentre";

export const REIMBURSEMENT_ACTION_CENTRE_CONFIG = {
  list: actionCentreTableListSelector,
  isFetching: actionCentreTableIsFetchingSelector,
  total: actionCentreTableTotalSelector,
  hasMore: actionCentreTableHasMoreSelector,
  resetMethod: () => ({
    func: resetPaginationActionCentre,
    args: {},
  }),
  loadMoreMethod: ({ request_type, page, limit, ...rest }) => ({
    func: fetchRequestTableInfo,
    args: {
      request_type,
      page,
      limit,
      ...rest,
    },
  }),
};

export const REIMBURSEMENT_CONFIG = {
  list: reimbursementsListSelector,
  isFetching: isReimbursementsFetchingSelector,
  total: reimbursementTotalSelector,
  hasMore: reimbursementHasMoreSelector,
  resetMethod: () => ({
    func: setReimbursementInitialState,
    args: {},
  }),
  loadMoreMethod: ({ page, limit, ...rest }) => ({
    func: fetchReimbursements,
    args: {
      page,
      limit,
      ...rest,
    },
  }),
};

export const REPORT_CONFIG = {
  list: reportsListSelector,
  isFetching: isFetchingReportsSelector,
  total: reportsTotalSelector,
  hasMore: reportsHasMoreSelector,
  resetMethod: () => ({
    func: setReportInitialState,
    args: {},
  }),
  loadMoreMethod: ({ page, limit, ...rest }) => ({
    func: fetchReports,
    args: {
      page,
      limit,
      ...rest,
    },
  }),
};

export const REIMBURSEMENT_ACTION_CENTER_PAGE_CONFIG = {
  [REIMBURSEMENT_KEY]: REIMBURSEMENT_CONFIG,
  [REPORT_KEY]: REPORT_CONFIG,
  [ACTION_CENTRE_PAGE_CONTEXT.REIMBURSEMT]: REIMBURSEMENT_ACTION_CENTRE_CONFIG,
  [ACTION_CENTRE_PAGE_CONTEXT.REPORT]: REIMBURSEMENT_ACTION_CENTRE_CONFIG,
};
