import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";

// reducers
import {
  activateCard,
  fetchAndSelectCard,
  getPhysicalCardActivationSections,
  mergePhysicalCardActivationFormData,
  resetActivateCardConfig,
  resetPhysicalCardsActivationFields,
  resetPhysicalCardsActivationFormData,
  resetPinUrl,
  setPhysicalCardActivationFieldsStep,
  setPhysicalCardActivationFormFields,
  setResetPinUrl,
  setSelectedCard,
} from "@/store/reducers/cards";
import { fetchUserPhysicalCard } from "@/store/reducers/user";

// selectors
import {
  activateCardInprogressSelector,
  activateCardStatusSelector,
  physicalCardActivationFieldsSelector,
  physicalCardsActionFromDataSelector,
  resetPinUrlSelector,
  selectedCardSelector,
  selectedCardSlugSelector,
} from "@/store/selectors/cards";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
// core components
import Note from "@/components/core/Note";
import Text from "@/components/core/Text";

import { triggerCardsTableComponent } from "@/components/Cards/util";
import GenericForm from "@/components/GenericForm";
import { generatePayloadFromFormValue } from "@/components/GenericForm/common";
import { convertFilters } from "@/utils/filters";
import { capitalizeFirstLetter } from "@/utils/common";

import {
  CARD_ACTIVATION_STATUSES,
  CARD_PROVIDER,
  CARD_USAGE_TYPES,
} from "@/constants/Cards";
// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import GlobalCard from "@/assets/images/GlobalCard.png";
import ActivationCard from "@/assets/images/ActivationCard.png";
import { API_KEYS_MAPPING } from "@/api/apiKeys";

export default function PhysicalCardActivation() {
  const selectedCardInfo = useSelector(selectedCardSelector);
  const cardHolder = selectedCardInfo?.cardHolder?.displayName;
  const cardStatus = capitalizeFirstLetter(selectedCardInfo?.status);
  const currentUser = useSelector(userSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const cardId = searchParams.get(SLIDERS_SEARCH_PARAMS.cards.activation);

  const selectedCard = useSelector(selectedCardSelector);

  const selectedCardCurrency = selectedCard?.currency;

  const cardActivationStatus = selectedCard?.activationStatus;

  const slug = useSelector(selectedCardSlugSelector);

  const physicalCardActivationFields = useSelector(
    physicalCardActivationFieldsSelector
  );

  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const hideLimitNote = slug === CARD_PROVIDER.UOB;

  const showGenericForm =
    slug !== CARD_PROVIDER.UOB
      ? physicalCardActivationFields[0]?.sections?.length > 0
      : cardActivationStatus !== CARD_ACTIVATION_STATUSES.IN_PROGRESS;

  const hideActivateCtaButton =
    slug === CARD_PROVIDER.UOB &&
    (physicalCardActivationFields[0]?.sections?.length === 0 ||
      cardActivationStatus === CARD_ACTIVATION_STATUSES.IN_PROGRESS);

  const activateCardStatus = useSelector(activateCardStatusSelector);

  const activateCardInProgress = useSelector(activateCardInprogressSelector);

  const resetPinLink = useSelector(resetPinUrlSelector);

  const [pageConfig = {}] = physicalCardActivationFields;
  const { sections } = pageConfig;
  const appliedFilters = convertFilters(useSelector(appliedFilterSelector));
  const locationObject = useLocation();
  const selectedUserId = currentUser?.id;
  const cardUsageType = selectedCard?.cardUsageType;
  const cardActivationImageDescription =
    getPhysicalCardActivationImageDescriptionText();
  const cardImage =
    cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
      ? GlobalCard
      : ActivationCard;

  useEffect(() => {
    if (slug && sections?.length === 0) {
      dispatch(getPhysicalCardActivationSections({ provider: slug }));
    }
  }, [slug]);

  useEffect(() => {
    return () => {
      dispatch(resetPhysicalCardsActivationFormData());
      dispatch(resetActivateCardConfig());
      dispatch(resetPhysicalCardsActivationFields());
      dispatch(setResetPinUrl(null));
      dispatch(setSelectedCard(null));
    };
  }, []);

  useEffect(() => {
    if (Number(cardId) && !selectedCard) {
      dispatch(fetchAndSelectCard({ cardId: parseInt(cardId, 10) }));
    }
  }, [cardId]);

  useEffect(() => {
    if (cardId) dispatch(setPhysicalCardActivationFieldsStep(cardId));
  }, [physicalCardActivationFields]);

  useEffect(() => {
    if (activateCardStatus && !activateCardInProgress) {
      if (slug === CARD_PROVIDER.LIVQUIK) {
        dispatch(resetPinUrl({ cardId }));
      }
    }
  }, [activateCardStatus, activateCardInProgress]);

  useEffect(() => {
    if (resetPinLink) {
      window.open(resetPinLink);
      closeSlider();
    }
  }, [resetPinLink]);

  const getRequiredNotesPropsBySlugType = () => {
    const notesVariables = {
      textTtile: "",
      descriptionText: "",
      actionText: "",
      show: true,
    };

    switch (slug) {
      case CARD_PROVIDER.SBM:
        notesVariables.titleText =
          "cards.pCards.sliders.physicalCardActivation.note.topNotes.sbm.titleText";
        break;

      case CARD_PROVIDER.LIVQUIK:
        notesVariables.titleText =
          "cards.pCards.sliders.physicalCardActivation.note.topNotes.livquik.titleText";
        break;

      case CARD_PROVIDER.UOB:
        if (physicalCardActivationFields[0]?.sections?.length === 0) {
          notesVariables.titleText =
            "cards.pCards.sliders.physicalCardActivation.note.topNotes.uob.isShipping.titleText";
        } else if (cardActivationStatus === CARD_ACTIVATION_STATUSES.PENDING) {
          notesVariables.titleText =
            "cards.pCards.sliders.physicalCardActivation.note.topNotes.uob.isShipped.titleText";
        } else {
          notesVariables.titleText =
            "cards.pCards.sliders.physicalCardActivation.note.topNotes.uob.activated.titleText";
        }

        break;

      default:
        notesVariables.show = false;
        break;
    }

    notesVariables.descriptionText = "";
    notesVariables.actionText = "";
    return notesVariables;
  };

  function getPhysicalCardActivationImageDescriptionText() {
    if (cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL) {
      return "cards.pCards.sliders.physicalCardActivation.internationalCardActivateDescription";
    }

    if (slug === CARD_PROVIDER.NIUM) {
      return "cards.pCards.sliders.physicalCardActivation.niumActivateCardDescription";
    }

    return "cards.pCards.sliders.physicalCardActivation.commonActivateCardDescription";
  }

  function closeSlider() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.activation);
    setSearchParams(searchParams);
  }

  function reloadCardActivationTab() {
    triggerCardsTableComponent(
      dispatch,
      { ...appliedFilters, user: selectedUserId },
      locationObject
    );
  }

  function handleCardActivationSuccess() {
    if (slug === CARD_PROVIDER.LIVQUIK) {
      dispatch(resetPinUrl({ cardId }));
    } else {
      reloadCardActivationTab();
      closeSlider();
    }

    dispatch(fetchUserPhysicalCard({ id: currentUser?.id }));
  }

  const activationStatusNotes = getRequiredNotesPropsBySlugType();
  const allState = useLoadingErrorInjector({
    apiKey: API_KEYS_MAPPING.PHSYICAL_CARDS_ACTIVATION_FORM_FIELDS,
    showLoader: false,
    error: {
      header: "Card",
    },
  });

  return (
    <div className="p-8 pt-0" ref={allState}>
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-6">
          <Text
            translationKey={cardHolder}
            classes="text-neutral-800 text-3xl not-italic font-bold"
          />

          <div>
            <Chip
              label={cardStatus}
              classes="border border-neutral-200 rounded-[100px] border-solid text-neutral-400 font-bold"
            />
          </div>
        </div>

        <div className="mb-5">
          <Text
            translationKey="cards.pCards.sliders.physicalCardActivation.physicalCard"
            classes="text-sm not-italic font-medium leading-6 text-neutral-500"
          />
        </div>
      </div>

      {activationStatusNotes.show && (
        <div className="mt-4 mb-10">
          <Note
            {...activationStatusNotes}
            borderColorClass="border-warning-300"
            backgroundColorClass="bg-warning-50"
          />
        </div>
      )}

      <div className="flex flex-col items-center justify-center w-full">
        <img src={cardImage} alt="ActivationCard" />
        <div className="max-w-[402px] mt-3">
          <Text
            translationKey={cardActivationImageDescription}
            classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500"
          />
        </div>
      </div>

      {showGenericForm && (
        <GenericForm
          showFooter={false}
          pages={physicalCardActivationFields || {}}
          storeSetterReducer={setPhysicalCardActivationFormFields}
          storeClearReducer={resetPhysicalCardsActivationFormData}
          storeSelector={physicalCardsActionFromDataSelector}
          storeMergeReducer={mergePhysicalCardActivationFormData}
          onSubmit={(payload) => {
            const updatedPayload = generatePayloadFromFormValue(payload);

            dispatch(
              activateCard({
                cardId,
                payload: updatedPayload,
                onSuccess: handleCardActivationSuccess,
              })
            );
          }}
          genericFormClasses="font-medium"
          formId="activate_physical_card"
          inSlider
          noBottomMargin
          inSliderClasses="mb-0"
          setIsFormDisabled={setIsFormDisabled}
        />
      )}

      {!hideLimitNote && (
        <div className="py-8 pb-13">
          <Note
            titleText="cards.pCards.sliders.physicalCardActivation.note.bottomNotes.titleText"
            descriptionText="cards.pCards.sliders.physicalCardActivation.note.bottomNotes.descriptionText"
            descriptionTextTranslationProps={{ selectedCardCurrency }}
            actionText=""
            borderColorClass="border-primary-300"
            backgroundColorClass="bg-primary-50"
          />
        </div>
      )}

      {!hideActivateCtaButton && (
        <div className="fixed bottom-0 flex items-center w-full px-6 py-3 bg-white slider-footer">
          <Button
            btnType="submit"
            form="activate_physical_card"
            label="cards.pCards.sliders.physicalCardActivation.buttons.activateCard"
            classes="w-16 h-9  items-center justify-center ml-auto flex-shrink-0"
            disabled={isFormDisabled}
          />
        </div>
      )}
    </div>
  );
}

/**
 * When slug is sbm or livquik then post calling Activation Api, resetPinUrl needs to called and cardId needs to passed as paylod to get url
 * After getting the url switch to a new tab with the url , which we got from resetPinUrl.
 */
