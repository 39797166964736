import PropsTypes from "prop-types";
import React from "react";

import Filters from "@/components/core/Filters";

const BillingFilter = ({ filters }) => {
  return (
    <div className="flex items-start my-2">
      <div className="flex flex-col flex-1 w-11/12">
        {filters ? <Filters filters={filters} /> : null}
      </div>
    </div>
  );
};

export default BillingFilter;
BillingFilter.propTypes = {
  filters: PropsTypes.arrayOf(Object),
};
