import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import vToast from "@/utils/vToast";
import API from "@/api";

const initialState = {
  preferences: {
    companyPreferences: [],
    personalPreferences: [],
  },
  isFetchingPreferences: null,
  isLoading: false,
};

const notificationPreferences = createSlice({
  name: "notificationPreferences",
  initialState,
  reducers: {
    setPreferencesData(state, action) {
      state.preferences = action.payload;
    },
    setIsFetchingPreferences(state, action) {
      state.isFetchingPreferences = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const fetchNotificationPreferences = createAsyncThunk(
  "notificationPreferences/fetchNotificationPreferences",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPreferences(true));

    const [err, response] = await to(API.NotificationPreferences.all());

    if (err) {
      vToast(getErrorToastMessage(err));
      return;
    }

    if (response?.data) {
      dispatch(setPreferencesData(response.data));
    }
    dispatch(setIsFetchingPreferences(false));
  }
);

export const resetDefaultNotificationPreferences = createAsyncThunk(
  "notificationPreferences/resetDefaultNotificationPreferences",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));

    const [err, response] = await to(
      API.NotificationPreferences.resetDefaultSettings(params)
    );

    if (err) {
      vToast(getErrorToastMessage(err));
      return;
    }

    if (response?.data) {
      vToast(getSuccessToastMessage(response, response?.data));
    }
    dispatch(setIsLoading(false));
  }
);

export const bulkUpdateNotificationPreferences = createAsyncThunk(
  "notificationPreferences/bulkUpdateNotificationPreferences",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));

    const [err, response] = await to(
      API.NotificationPreferences.bulkUpdate(params)
    );

    if (err) {
      vToast(getErrorToastMessage(err));
      return;
    }

    if (response?.data) {
      vToast(getSuccessToastMessage(response, response?.data));
    }

    dispatch(setIsLoading(false));
  }
);

export const { setPreferencesData, setIsFetchingPreferences, setIsLoading } =
  notificationPreferences.actions;

export default notificationPreferences.reducer;
