import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

export default function People() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.manage.people.users.absolutePath);
  }, []);
  return (
    <div>
      <span className="text-2xl font-semibold">People</span>
    </div>
  );
}
