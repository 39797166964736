import PropTypes from "prop-types";

import BeneficiaryCountry from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/BeneficiaryCountry";
import BeneficiaryCurrency from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/BeneficiaryCurrency";
import PaymentAccount from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/PaymentAccount";
import PaymentMethod from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/PaymentMethod";

export default function RapydBankDetails({
  values,
  errors,
  handleChange,
  paymentMethods,
  isFetchingPaymentMethods,
  setValues,
  fieldsFilters,
  isEditMode,
  inPayrollContext,
}) {
  return (
    <div>
      <div className="flex flex-col gap-6 mt-6">
        <PaymentAccount
          {...{
            values,
            errors,
            handleChange,
            setValues,
            isEditMode,
            inPayrollContext,
          }}
        />
        <BeneficiaryCountry
          {...{
            values,
            errors,
            handleChange,
            relevantValues: fieldsFilters.relevantCountries,
            setValues,
            isEditMode,
          }}
        />
        <BeneficiaryCurrency
          {...{
            values,
            errors,
            handleChange,
            relevantValues: fieldsFilters.relevantCurrencies,
            setValues,
            isEditMode,
          }}
        />
        <PaymentMethod
          {...{
            values,
            errors,
            handleChange,
            paymentMethods,
            isFetchingPaymentMethods,
            relevantValues: fieldsFilters.relevantPaymentMethods,
            setValues,
            isEditMode,
          }}
        />
      </div>
    </div>
  );
}

RapydBankDetails.propTypes = {
  values: PropTypes.object, // 'values' returned by useForm
  errors: PropTypes.object, // 'errors' returned by useForm
  handleChange: PropTypes.func, // 'handleChange' returned by useForm  isEditMode: PropTypes.bool,
  fieldsFilters: PropTypes.object,
  paymentMethods: PropTypes.array,
  isFetchingPaymentMethods: PropTypes.bool,
  setValues: PropTypes.func, // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough)
  inPayrollContext: PropTypes.bool,
};
