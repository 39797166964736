import PropsTypes from "prop-types";

import CreateVendorInit from "@/components/common/BillPayAndPayroll/VendorOrEmployee/CreateVendor/CreateVendorInit";

// TECH_DEBT: there's no need for this component, pass CreateVendorInit to global slider directly, and rename it to CreateVendor
export default function CreateVendor({ context, searchParamKey, setOnClose }) {
  return (
    <div className="slider-content-container">
      <CreateVendorInit
        context={context}
        searchParamKey={searchParamKey}
        setOnClose={setOnClose}
      />
    </div>
  );
}

CreateVendor.propTypes = {
  context: PropsTypes.string,
  searchParamKey: PropsTypes.string,
  setOnClose: PropsTypes.func,
};
