import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";

import Reimbursement from "@/pages/reimbursement";
import Approvals from "@/pages/reimbursement/approvals";
import NeedsYourAction from "@/pages/reimbursement/approvals/needs-your-action";
import OthersActionAwaiting from "@/pages/reimbursement/approvals/others-action-awaiting";
import Rejected from "@/pages/reimbursement/approvals/rejected";
import Paid from "@/pages/reimbursement/paid";
import Payments from "@/pages/reimbursement/payments";
import { ROUTES } from "@/constants/routes";
import ReimbursementSettingsRoutes from "@/routes/reimbursement/settings";

export default function ReimbursementRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Reimbursement />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.reimbursement.approvals.pathName}
          element={<Approvals />}
        >
          <Route
            path={`${ROUTES.reimbursement.approvals.needYourAttention.pathName}`}
            element={<NeedsYourAction />}
          />
          <Route
            path={`${ROUTES.reimbursement.approvals.pending.pathName}`}
            element={<OthersActionAwaiting />}
          />
          <Route
            path={`${ROUTES.reimbursement.approvals.all.pathName}`}
            element={<Rejected />}
          />
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.reimbursement.payments.pathName}
          element={<Payments />}
        />
      </Route>

      <Route path={ROUTES.reimbursement.paid.pathName} element={<Paid />} />
      <Route
        path={`${ROUTES.reimbursement.settings.pathName}/*`}
        element={<ReimbursementSettingsRoutes />}
      />
    </Routes>
  );
}
