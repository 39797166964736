import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export const renderingListType = {
  CATEGORIZED: "categorized",
  PLAIN: "plain",
};

export default function EntityListView({
  type = "category",
  mainHeading,
  headingDescription,
  count = 0,
  showCount,
  showEditIcon,
  list,
  handleEditClick,
}) {
  function getRequiredRenderedListComponent() {
    if (type === renderingListType.CATEGORIZED) {
      return (
        <div>
          {list?.map((currentCategory, index) => {
            const categoryConfig = currentCategory?.categoryConfig;
            const text = categoryConfig?.textConfig.text;
            const textClasses = categoryConfig?.textConfig?.classes;
            const icon = categoryConfig?.iconConfig?.icon;
            const iconClasses = categoryConfig?.iconConfig?.classes;

            return (
              <div className="p-3 bg-white rounded" key={index}>
                <div className="flex flex-row items-center gap-2 mb-3">
                  <Icon name={icon} className={iconClasses} />
                  <Text translationKey={text} classes={textClasses} />
                </div>
                {getNormalRenderedListComponent(currentCategory?.list, type)}
              </div>
            );
          })}
        </div>
      );
    }
    return getNormalRenderedListComponent(list, type);
  }

  function getNormalRenderedListComponent(renderList, requiredType) {
    return (
      <div
        className={`flex flex-col gap-2 ${
          requiredType === renderingListType.PLAIN ? "p-3 bg-white rounded" : ""
        } `}
      >
        {renderList?.map((listElement, index) => {
          return (
            <div className="flex flex-row justify-between" key={index}>
              <Text
                translationKey={listElement?.leftText}
                classes="text-sm font-medium text-neutral-500"
              />
              <Text
                translationKey={listElement?.rightText}
                classes="text-sm font-semibold text-neutral-800"
              />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3 border-0 card-wrapper bg-neutral-50">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2">
            <Text
              translationKey={mainHeading}
              classes="text-base font-semibold text-neutral-800"
            />

            {showCount ? (
              <div className="text-xs text-white bg-neutral-500 px-2 py-0.5 rounded-lg w-fit">
                <Text translationKey={count} />{" "}
              </div>
            ) : null}
          </div>

          <Text
            translationKey={headingDescription}
            classes="text-xs font-semibold text-neutral-500"
          />
        </div>

        {showEditIcon ? (
          <Button
            label="company.people.peopleMyProfileSlider.edit"
            size="sm"
            classes="w-fit py-1.5 px-5"
            preIcon="Edit"
            iconClasses="p-0.75 -mr-2"
            variant="tertiary"
            innerClasses="gap-1 font-bold text-xs"
            onClick={handleEditClick}
          />
        ) : null}
      </div>

      {getRequiredRenderedListComponent()}
    </div>
  );
}

/*
 1. type category or plain
 2. when type is categorized : [{list:[ {leftText, rightText}] , categoryConfig: {textConfig: {text,classes} , iconConfig: {text,classes} }}]
 3. when type is plain : list:[ {leftText, rightText}]
 */

EntityListView.propTypes = {
  type: PropTypes.string,
  mainHeading: PropTypes.string,
  headingDescription: PropTypes.string,
  count: PropTypes.number,
  showCount: PropTypes.bool,
  showEditIcon: PropTypes.string,
  handleEditClick: PropTypes.func,
  list: PropTypes.array,
};
