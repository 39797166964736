import PropTypes from "prop-types";
import React from "react";

import Filters from "@/components/core/Filters";

import { PROJECT_TYPE_TITLE } from "@/constants/company";

import BudgetsTabEmptyState from "../Slider/LocationDepartmentDetails/BudgetsTab/BudgetsTabEmptyState";
import BudgetListingLoader from "../common/BudgetListingLoader";
import BudgetsListingRow from "./BudgetsListingRow";

function BudgetListing({
  hasMore,
  isFetching,
  handleRefChange,
  data = [],
  type,
  filters,
}) {
  return (
    <>
      <div className="mb-2">
        <Filters filters={filters} />
      </div>

      <div className="flex flex-col gap-4 mt-6 pb-9">
        {data?.map((budget, index, arr) => (
          <BudgetsListingRow
            key={`budget-listing-${budget?.id}`}
            isLastRow={index === arr.length - 1}
            hasMore={hasMore}
            handleRefChange={handleRefChange}
            data={budget}
            type={type}
          />
        ))}

        {isFetching ? <BudgetListingLoader /> : null}

        {!isFetching && data.length <= 0 ? (
          <BudgetsTabEmptyState
            title="company.department.departmentDetails.tabs.budgets.budgetEmptyTitle"
            desc="company.department.departmentDetails.tabs.budgets.budgetEmptyDescription"
            hideButton
          />
        ) : null}
      </div>
    </>
  );
}

export default BudgetListing;
BudgetListing.propTypes = {
  hasMore: PropTypes.bool,
  isFetching: PropTypes.bool,
  handleRefChange: PropTypes.func,
  data: PropTypes.array,
  filters: PropTypes.array,
  type: PropTypes.string,
};
