import PropTypes from "prop-types";
import { useEffect, useId, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import Creatable from "react-select/creatable";
import { FixedSizeList as List } from "react-window";

// import "@/assets/styles/theme.scss";

import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import SelectLoader from "@/components/core/VpSelect/SelectLoader";
import { DROPDOWN_TYPE } from "@/utils/constantUseForm";
import { getTooltipFriendlyId, isEllipsisActive } from "@/utils/common";

export default function VpSelect({
  description,
  options,
  handleChange,
  handleCreate,
  label,
  optionsDisplayKey = "label",
  optionsDisplaySubtextKey = "",
  valueKey = "id",
  placeholder,
  searchable = true,
  multi = false,
  isFocused = false,
  helperText = "",
  helperTextVariant,
  clearable,
  error = "",
  value = "",
  name,
  disabled = false,
  showOptional = false,
  showLabelOnSelect = true,
  insideForm = false, // to use the component inside <form /> with useForm hook
  classes = "", // for VpSelect's outermost container
  labelStyleClasses = "",
  placeholderStyleClasses = "text-sm font-medium",
  styles = {},
  required = false,
  hideLabelAfterSelect = false,
  translate = false,
  subTextTranslate = false,
  creatable = false,
  menuPosition = "fixed",
  isOptionsFetched = true,
  labelTranslate = "",
  fetchOptions = () => {},
  isOptionsLoading = false,
  leftProp = null,
  labelTranslationProp = {},
  placeholderTranslationProp = {},
  checkIsOptionDisabled = (option) => option?.disabled, // should return true or false against options, to be made disable or enable.
  helperTextTransalationProp = {},
  customUIForOptionWhenDropdownOpen = null,
  customUIForOptionWhenDropdownClosed = null,
  noborder = false,
  rightText = "",
  rightTextClasses = "text-neutral-800 text-sm font-medium",
  rightTextNoTranslate = false,
  isGroupedMode = false,
  customNoOptionComponent = null,
  autoSelectIfSingleOption = false, // auto-select option if there's just one
  onMenuScrollToBottom = () => {}, // for paginated dropdowns
  openMenuOnFocus, // react-select's prop, non-blocking
  formatCreateLabel, // creatable dropdown helper text (open state). Structure: (typedText) => `Create ${typedText}`
  haveAboluteDescription = false,
  lastOptionSticky = false, // to make the last option sticky
  firstOptionSticky = false,
  onStickyOptionSelected, // to run this the value of the selected option should be -2
  customUIForLastOption,
  customUIForFirstOption,
  descriptionTextClasses,
  title = "", // right tooltip
  titleNoTranslate = false,
  onInputChange = () => {},
  insideTable,
  // Common usage: show dropdown in open state when form loads, set `props.isFocused={!selectedValue}`
}) {
  const { t } = useTranslation();
  const vpSelectRef = useRef();
  const creatableVPSelectRef = useRef();
  const optionRef = useRef(null);
  // custom keys don't work in 'creatable' mode. Resetting them to the react-select default
  // if (creatable) {
  //   optionsDisplayKey = "label";
  //   valueKey = "value";
  // }
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [optionHeight, setOptionHeight] = useState(0);
  // const [promptHeight, setPromptHeight] = useState(0);
  const [isShowLabelTooltip, setIsShowLabelTooltip] = useState(false);
  const id = useId();
  const _id = `tooltips-${id.replaceAll(":", "")}`;

  // keeping it inner function to avoid complex arguments
  function customEntryWhenDropdownIsOpenSubtext(displayValue, option) {
    // displays text and subtext
    return (
      <div className="flex flex-col font-medium">
        <Text
          classes="text-base text-neutral-500"
          translationKey={displayValue}
          noTranslate={!translate}
        />
        {option[optionsDisplaySubtextKey] ? (
          <Text
            classes="text-xs text-neutral-400"
            translationKey={option[optionsDisplaySubtextKey]}
            noTranslate={!subTextTranslate}
          />
        ) : null}
      </div>
    );
  }
  customUIForOptionWhenDropdownOpen = optionsDisplaySubtextKey
    ? customEntryWhenDropdownIsOpenSubtext
    : customUIForOptionWhenDropdownOpen;

  const [shouldDisplayLabel, toggleLabelDisplay] = useState(true);

  const [focused, setFocused] = useState(isFocused);
  const customStyle = {
    indicatorSeparator: () => ({
      display: "none",
    }),
    control: (base) =>
      hideLabelAfterSelect
        ? {
            ...base,

            border: 0,
            boxShadow: "none",
          }
        : {
            ...base,
            ...(noborder ? { borderBottom: 0 } : {}),
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderRadius: 0,
            boxShadow: "none",
          },
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: ({ position, fontWeight, ...provided }) => ({
      ...provided,
      position: "static",
    }),
  };
  const IndicatorsContainer = ({ children, clearValue, ...props }) => {
    return (
      components.IndicatorsContainer && (
        <components.IndicatorsContainer {...props}>
          {leftProp}
          {children}
        </components.IndicatorsContainer>
      )
    );
  };
  const placeholderNode =
    typeof placeholder === typeof "" ? (
      <Text
        translationKey={placeholder}
        translationProps={placeholderTranslationProp}
        classes={placeholderStyleClasses}
      />
    ) : (
      placeholder
    );
  const labelNode =
    typeof label === typeof "" ? (
      <>
        <Text
          translationKey={label}
          classes={`${
            !labelStyleClasses?.includes("text-")
              ? hideLabelAfterSelect &&
                required &&
                !disabled &&
                !(value || typeof value === typeof 0)
                ? "text-danger-500"
                : ""
              : ""
          } ${labelStyleClasses} ${isShowLabelTooltip ? "max-w-[90%]" : ""}`}
          translationProps={labelTranslationProp}
        />

        {showOptional ? (
          <Text
            translationKey="misc.optionalInBrackets"
            classes="text-neutral-400 text-xs font-semibold"
          />
        ) : null}

        {isShowLabelTooltip ? (
          <span>
            <Tooltip id={_id} direction="bottom">
              <Text
                translationKey={label}
                classes="text-sm font-medium text-neutral-800"
                translationProps={labelTranslationProp}
              />
            </Tooltip>
          </span>
        ) : null}
      </>
    ) : (
      <div
        className={required && !disabled ? "is-required-vp-select-text" : ""}
      >
        {label}
      </div>
    );

  const handleSelectChange = (e) => {
    let customEvent = e;
    // added for useForm
    if (insideForm) {
      customEvent = {
        ...e,
        target: {
          name,
          value: e?.[valueKey] ?? "", // e is `null` for `clearable` prop
          type: DROPDOWN_TYPE,
        },
      };
    }
    const runStickyOptionSelected = e?.[valueKey] === -2;
    if (onStickyOptionSelected && runStickyOptionSelected) {
      // Call onStickyOptionSelected for the last option or for those whose value is -1
      onStickyOptionSelected(customEvent);
    } else {
      // Call handleChange for other options
      handleChange(customEvent);
    }

    if (hideLabelAfterSelect && !runStickyOptionSelected) {
      toggleLabelDisplay(false);
    }
  };

  const handleNew = (e) => {
    handleCreate(e);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    if ((focused && !isOptionsFetched) || disabled) {
      fetchOptions();
    }
  }, [focused]);

  useEffect(() => {
    if (hideLabelAfterSelect && (value || typeof value === typeof 0)) {
      toggleLabelDisplay(false);
    }
  }, [hideLabelAfterSelect]);

  const getSelectedValue = (_value) => {
    const selectedOption = options?.find(
      (option) => option[valueKey] === _value
    );

    if (!selectedOption) return _value;

    return { ...selectedOption, label: selectedOption[optionsDisplayKey] };
  };

  let finalValue =
    value || typeof value === typeof true || typeof value === typeof 0
      ? typeof value === typeof {}
        ? value[optionsDisplayKey]
        : getSelectedValue(value)
      : null;

  if (translate) {
    finalValue = Array.isArray(finalValue)
      ? finalValue.map((option) => ({
          ...option,
          label: t(option?.[optionsDisplayKey]),
        }))
      : { ...finalValue, label: t(finalValue?.[optionsDisplayKey]) };
  }

  const firstOption = options?.[0];
  useEffect(() => {
    if (autoSelectIfSingleOption && options?.length === 1) {
      handleSelectChange({
        // mimicking react-select event
        [valueKey]: firstOption?.[valueKey],
        [optionsDisplayKey]: firstOption?.[optionsDisplayKey],
      });
    }
  }, [autoSelectIfSingleOption, firstOption?.[valueKey]]);

  const MenuList = (props) => {
    const { options: _options, children, maxHeight, getValue } = props;
    const height = optionRef?.current?.offsetHeight || 50;
    const searchText = props?.selectProps?.inputValue;
    if (!optionHeight && optionRef?.current?.offsetHeight)
      setOptionHeight(height);

    const [_value] = getValue();
    const initialOffset = options.indexOf(_value) * height;

    const childrenHeight =
      ((children?.length ?? 0) + (searchText ? 1 : 0)) * height;
    const _maxHeight = childrenHeight > maxHeight ? maxHeight : childrenHeight;

    const firstChild = children[0];
    const otherChildren = firstOptionSticky
      ? !children?.length
        ? []
        : children?.slice(1)
      : children;

    // Function to handle the scroll event
    const handleScroll = (args) => {
      const { scrollOffset = 0 } = args;
      const distanceFromBelow =
        optionHeight * children.length - scrollOffset - _maxHeight;
      if (Math.abs(distanceFromBelow) < height) {
        // Scrolls to top if new data page is added (TECH_DEBT)
        onMenuScrollToBottom();
      }
    };

    return (
      <div>
        <List
          height={_maxHeight}
          itemCount={otherChildren.length}
          itemSize={optionHeight || height}
          initialScrollOffset={initialOffset}
          onScroll={handleScroll}
        >
          {({ index, style }) => (
            <div style={style}>{otherChildren[index]}</div>
          )}
        </List>

        {/* Render the first child (sticky) at the bottom */}
        {firstOptionSticky ? (
          <div className="sticky bottom-0 p-0 m-0">{firstChild}</div>
        ) : null}
      </div>
    );
  };

  return (
    <div
      className={`relative flex justify-between ${classes} ${
        disabled ? "opacity-50" : ""
      } ${focused && !value && insideTable ? "mt-5" : ""}`}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div className="grow">
        {!creatable ? (
          <Select
            onInputChange={onInputChange}
            ref={vpSelectRef}
            // onMenuScrollToBottom={onMenuScrollToBottom} DOESNT WORK FROM HERE, but works in general
            styles={customStyle}
            placeholder={focused ? placeholderNode : null}
            options={options}
            isSearchable={searchable}
            isMulti={multi}
            autoFocus={isFocused}
            onChange={handleSelectChange}
            onFocus={onFocus}
            onBlur={onBlur}
            closeMenuOnScroll={(e) => {
              // close menu of select if outside container is scrolled
              const isClosed = e?.target?.className
                ? !e?.target?.className?.includes("vp-input-select__menu-list")
                : false;
              if (isClosed) {
                // bluring that perticular vp select
                vpSelectRef.current?.blur();
              }
              return isClosed;
            }}
            isClearable={clearable}
            menuPortalTarget={document.querySelector("#vp-select-portal")}
            isOptionDisabled={checkIsOptionDisabled}
            name={name}
            menuShouldScrollIntoView={false}
            isDisabled={disabled}
            value={
              finalValue && customUIForOptionWhenDropdownClosed
                ? {
                    ...finalValue,
                    label: customUIForOptionWhenDropdownClosed?.(
                      finalValue?.label,
                      finalValue
                    ),
                  }
                : isGroupedMode
                  ? {
                      [valueKey]: finalValue,
                    }
                  : finalValue
            }
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            getOptionValue={(option) => option?.[valueKey]}
            {...(customUIForOptionWhenDropdownClosed && !isMenuOpen
              ? {}
              : { getOptionLabel: (option) => option?.[optionsDisplayKey] })}
            components={{
              // Handling virtualized list for non-grouped options since grouped data is not supported properly.
              ...(!isGroupedMode ? { MenuList } : {}),
              NoOptionsMessage: (props) => (
                <components.NoOptionsMessage {...props}>
                  {customNoOptionComponent ? (
                    customNoOptionComponent
                  ) : (
                    <Text
                      translationKey={
                        options?.length
                          ? "vpSelectNotFound"
                          : "vpSelectNoOptionsAvailable"
                      }
                    />
                  )}
                </components.NoOptionsMessage>
              ),
              Option: ({ children, data, ...rest }) => {
                const index =
                  rest.innerProps && rest.innerProps.id
                    ? parseInt(rest.innerProps.id.split("-").pop(), 10)
                    : null;
                const isLastOption = index === options.length - 1;
                const isFirstOption = index === 0;

                return (
                  <div ref={optionRef}>
                    <components.Option
                      {...rest}
                      className={
                        isLastOption
                          ? `${
                              lastOptionSticky
                                ? "!sticky !bottom-0 !bg-white  !p-0"
                                : ""
                            }`
                          : ""
                      }
                    >
                      {isFirstOption && customUIForFirstOption ? (
                        customUIForFirstOption(
                          data[optionsDisplayKey],
                          data,
                          finalValue
                        )
                      ) : customUIForOptionWhenDropdownOpen ? (
                        customUIForOptionWhenDropdownOpen(
                          data[optionsDisplayKey],
                          data,
                          finalValue
                        )
                      ) : isLastOption && customUIForLastOption ? (
                        customUIForLastOption(
                          data[optionsDisplayKey],
                          data,
                          finalValue
                        )
                      ) : (
                        <div className="`flex items-center">
                          {data.flag && <Flag code={data.flag} />}
                          {data.icon && <Icon name={data.icon} />}
                          {translate ? (
                            <Text
                              translationKey={data[optionsDisplayKey]}
                              classes="ml-2"
                            />
                          ) : (
                            data[optionsDisplayKey]
                          )}
                        </div>
                      )}
                    </components.Option>
                  </div>
                );
              },
              ...(rightText
                ? {
                    DropdownIndicator: (props) => (
                      <div className="flex items-center">
                        {typeof rightText === typeof "" ? (
                          rightTextNoTranslate ? (
                            <span className={rightTextClasses}>
                              {rightText}
                            </span>
                          ) : (
                            <Text
                              classes={rightTextClasses}
                              translationKey={rightText}
                            />
                          )
                        ) : (
                          rightText
                        )}
                        <components.DropdownIndicator {...props} />
                      </div>
                    ),
                  }
                : {}),
            }}
            className="react-select"
            classNamePrefix={`vp-input-select${
              required && !(value || typeof value === typeof 0)
                ? "-required"
                : ""
            }`}
            menuPosition={menuPosition}
            isLoading={isOptionsLoading}
            loadingMessage={() => <SelectLoader />}
            openMenuOnFocus={openMenuOnFocus}
          />
        ) : (
          <Creatable
            ref={creatableVPSelectRef}
            onInputChange={onInputChange}
            menuPortalTarget={document.querySelector("#vp-select-portal")}
            closeMenuOnScroll={(e) => {
              // close menu of select if outside container is scrolled
              const isClosed = e?.target?.className
                ? !e?.target?.className?.includes("vp-input-select__menu-list")
                : false;
              if (isClosed) {
                // bluring that perticular vp select
                creatableVPSelectRef.current?.blur();
              }
              return isClosed;
            }}
            // onMenuScrollToBottom={onMenuScrollToBottom} DOESNT WORK FROM HERE, but works in general
            formatCreateLabel={formatCreateLabel}
            styles={customStyle}
            placeholder={focused ? placeholderNode : null}
            options={options}
            isSearchable={searchable}
            isMulti={multi}
            autoFocus={isFocused}
            onChange={handleSelectChange}
            onCreateOption={handleNew}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            getOptionValue={(option) => option?.[valueKey]}
            {...(customUIForOptionWhenDropdownClosed && !isMenuOpen
              ? {}
              : { getOptionLabel: (option) => option?.[optionsDisplayKey] })}
            onFocus={onFocus}
            onBlur={onBlur}
            isClearable={clearable}
            name={name}
            isDisabled={disabled}
            value={
              finalValue && customUIForOptionWhenDropdownClosed
                ? {
                    ...finalValue,
                    label: customUIForOptionWhenDropdownClosed?.(
                      finalValue?.label,
                      finalValue
                    ),
                  }
                : isGroupedMode
                  ? {
                      [valueKey]: finalValue,
                    }
                  : finalValue
            }
            components={{
              MenuList,
              NoOptionsMessage: (props) => (
                <components.NoOptionsMessage {...props}>
                  {customNoOptionComponent || (
                    <Text
                      translationKey={
                        options?.length
                          ? "vpSelectNotFound"
                          : "vpSelectNoOptionsAvailable"
                      }
                    />
                  )}
                </components.NoOptionsMessage>
              ),
              Option: ({ children, data, ...rest }) => (
                <div ref={data?.__isNew__ ? null : optionRef}>
                  <components.Option {...rest}>
                    {customUIForOptionWhenDropdownOpen ? (
                      customUIForOptionWhenDropdownOpen(
                        data[optionsDisplayKey],
                        data,
                        finalValue,
                        rest
                      )
                    ) : (
                      <div className="flex items-center">
                        {data.flag && <Flag code={data.flag} />}
                        {data.icon && <Icon name={data.icon} />}
                        {translate ? (
                          <Text
                            translationKey={data[optionsDisplayKey]}
                            classes="ml-2"
                          />
                        ) : (
                          data[optionsDisplayKey]
                        )}
                      </div>
                    )}
                  </components.Option>
                </div>
              ),
              ...(rightText
                ? {
                    DropdownIndicator: (props) => (
                      <div className="flex items-center">
                        {typeof rightText === typeof "" ? (
                          rightTextNoTranslate ? (
                            <span className={rightTextClasses}>
                              {rightText}
                            </span>
                          ) : (
                            <Text
                              classes={rightTextClasses}
                              translationKey={rightText}
                            />
                          )
                        ) : (
                          rightText
                        )}
                        <components.DropdownIndicator {...props} />
                      </div>
                    ),
                  }
                : {}),
            }}
            className="react-select"
            classNamePrefix={`vp-input-select${
              required && !(value || typeof value === typeof 0) !== false
                ? "-required"
                : ""
            }`}
            menuPosition={menuPosition}
            isLoading={isOptionsLoading}
            loadingMessage={() => <SelectLoader />}
            openMenuOnFocus={openMenuOnFocus}
          />
        )}

        {shouldDisplayLabel && (
          <label
            htmlFor={name}
            ref={(ref) => setIsShowLabelTooltip(isEllipsisActive(ref))}
            id={_id}
            className={`ellipsis-easy-dynamic max-w-[85%] absolute transition-all duration-150 ${
              labelTranslate ? labelTranslate : "-translate-y-5"
            }
            ${
              focused || value || typeof value === typeof 0 || value === false
                ? "left-0 top-2 text-xs text-neutral-500 font-semibold"
                : "left-0 top-7 text-neutral-800"
            }
            ${insideTable ? "font-semibold text-xs" : "font-medium "}
          `}
          >
            {labelNode}
          </label>
        )}

        {helperText && !error ? (
          typeof helperText === typeof "" ? (
            <Text
              translationKey={helperText}
              translationProps={helperTextTransalationProp}
              classes={`text-xs ${
                haveAboluteDescription ? "absolute" : ""
              } font-medium ml-0.5 mt-1 ${
                helperTextVariant
                  ? `text-${helperTextVariant}-500`
                  : "text-neutral-500"
              }`}
            />
          ) : (
            helperText
          )
        ) : null}

        {error && (
          <Text classes="absolute text-xs text-danger" translationKey={error} />
        )}

        {!error &&
          description &&
          (typeof description === typeof "" ? (
            <Text
              classes={`text-xs  pl-0 ${
                descriptionTextClasses
                  ? descriptionTextClasses
                  : "text-neutral-500"
              }`}
              translationKey={description}
            />
          ) : (
            <div>{description}</div>
          ))}
      </div>

      {title ? (
        <span
          className="pt-3 ml-1 cursor-pointer shrink-0"
          id={getTooltipFriendlyId(`vpselect-tooltip-${name}-${label}`)}
        >
          <Icon name="Info" className="w-4 h-4 text-neutral-500" />
          <Tooltip
            direction="top-right"
            id={getTooltipFriendlyId(`vpselect-tooltip-${name}-${label}`)}
            maxWidth={450}
          >
            <Text translationKey={title} noTranslate={titleNoTranslate} />
          </Tooltip>
        </span>
      ) : null}
    </div>
  );
}

VpSelect.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ]),
  options: PropTypes.array,
  handleChange: PropTypes.func,
  handleCreate: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  searchable: PropTypes.bool,
  multi: PropTypes.bool,
  isFocused: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextVariant: PropTypes.string,
  clearable: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  showOptional: PropTypes.bool,
  styles: PropTypes.object,
  insideForm: PropTypes.bool,
  labelStyleClasses: PropTypes.string,
  classes: PropTypes.string,
  hideLabelAfterSelect: PropTypes.bool,
  required: PropTypes.bool,
  translate: PropTypes.bool,
  menuPosition: PropTypes.string,
  isOptionsFetched: PropTypes.bool,
  fetchOptions: PropTypes.func,
  isOptionsLoading: PropTypes.bool,
  leftProp: PropTypes.element,
  labelTranslationProp: PropTypes.object,
  labelTranslate: PropTypes.bool,
  placeholderTranslationProp: PropTypes.object,
  firstOptionSticky: PropTypes.bool,
  helperTextTransalationProp: PropTypes.object,
  label: PropTypes.string,
  noborder: PropTypes.bool,
  /**
   * this function will return boolean value against each option and define that it should be disabled or not
   */
  checkIsOptionDisabled: PropTypes.func,

  /**
   * this key is used for showing option label
   */
  optionsDisplayKey: PropTypes.string,
  optionsDisplaySubtextKey: PropTypes.string, // subtext key, UI for text-subtext is like this:
  subTextTranslate: PropTypes.bool,
  // https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=2702-49813&mode=design&t=6vWcjsbczelYqF2n-4

  /** this key is used for showing the selected option */
  valueKey: PropTypes.string,

  /**
   * Custom UI for options, in listing
   *
   * Params made available in the function
   * @param {String} displayValue final display value for the option
   * @param {Object} option       option object, i.e. element from the options array
   *
   * @returns JSX/component
   */
  customUIForOptionWhenDropdownOpen: PropTypes.func,

  /**
   * Custom UI for options, when selected (listing is closed)
   *
   * Same structure as 'in dropdown'
   */
  customUIForOptionWhenDropdownClosed: PropTypes.func,

  // text on the right, but left of the arrow icon
  rightText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightTextClasses: PropTypes.string,
  rightTextNoTranslate: PropTypes.bool,
  isGroupedMode: PropTypes.bool,
  customNoOptionComponent: PropTypes.func,
  autoSelectIfSingleOption: PropTypes.bool,
  onMenuScrollToBottom: PropTypes.func,
  openMenuOnFocus: PropTypes.bool,
  description: PropTypes.string,
  formatCreateLabel: PropTypes.func,
  lastOptionSticky: PropTypes.bool,
  onStickyOptionSelected: PropTypes.func,
  customUIForFirstOption: PropTypes.func,
  customUIForLastOption: PropTypes.func,
  title: PropTypes.string,
  titleNoTranslate: PropTypes.bool,
  onInputChange: PropTypes.func,
  insideTable: PropTypes.bool,
};
