import { createSelector } from "@reduxjs/toolkit";

export const categoriesStore = (store) => store.categories;

export const categoriesSelector = createSelector(
  categoriesStore,
  (categories) => categories.categories
);
export const categoriesListSelector = createSelector(
  categoriesSelector,
  (categories) => categories.list
);
export const isFetchingCategoriesSelector = createSelector(
  categoriesSelector,
  (categories) => categories.isFetching
);

export const categoriesHasMoreSelector = createSelector(
  categoriesSelector,
  (categories) => categories.hasMore
);
