import PropTypes from "prop-types";

import ReimbursementReportList from "@/components/ReimbursementReport/ReimbursementReportList";

export default function ReimbursementReportHelper({ tabType }) {
  return <ReimbursementReportList tabType={tabType} />;
}
ReimbursementReportHelper.propTypes = {
  tabType: PropTypes.string,
};
