import PropTypes from "prop-types";
import React from "react";

import RightColumn from "@/components/common/BillPayAndPayroll/AddBankDetailsComponent/RightColumn";

/**
 * Email vendor add bank flow, aka 'Vendor details email flow'
 * Story: vendor gets URL from Volopay customer. And visits the URL.
 * This is that URL page.
 *
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=8994-210328&mode=design&t=zqSCPAfS8HBhSBbw-4}
 */
function AddBankDetailsComponent({ context }) {
  return (
    <div className="h-screen">
      <RightColumn context={context} />
    </div>
  );
}

AddBankDetailsComponent.propTypes = {
  context: PropTypes.string,
};

export default AddBankDetailsComponent;
