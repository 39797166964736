import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchDetailsForBank } from "@/store/reducers/vendors";

import {
  isFetchingOnboardingMailDetailsSelector,
  onboardingMailDetailsSelector,
} from "@/store/selectors/vendors";

import Badge from "@/components/core/Badge";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import BankDetailMethodComponent from "@/components/common/BillPayAndPayroll/AddBankDetailsComponent/RightColumn/BankDetailMethodComponent";
import { BANK_DETAILS_VIA_MAIL_SEARCH_PARAM } from "@/components/common/BillPayAndPayroll/AddBankDetailsComponent/enum";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { MAIL_CONTEXT, WHITELIST_URL } from "@/utils/constants/common";
import { getToken, subdomain } from "@/utils/common";

/**
 * @param {String} context
 * @description
 *    depending on the context, whether it is bank details for adding employee or bank details for creating vendor
 *    the text in the UI changes
 */
function RightColumn({ context }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const isFetchingOnboardingMailDetails = useSelector(
    isFetchingOnboardingMailDetailsSelector
  );
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const onboardingMailDetails = useSelector(onboardingMailDetailsSelector);

  const onFailFetchDetailsApi = () => {
    searchParams.set(
      BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.KEY,
      BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.SUCCESS
    );
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const whiteListedUrl = WHITELIST_URL.find(
      (url) => url === window.location.pathname.split("/")[1]
    );

    if (whiteListedUrl) {
      dispatch(
        fetchDetailsForBank({
          payload: {
            token: getToken(),
            account: subdomain(),
          },
          onFail: onFailFetchDetailsApi,
        })
      );
    }
  }, []);

  return (
    <div className="flex flex-col h-full gap-11">
      {/* top section, 'VOLOPAY_CLIENT_XYZ wants to pay you' */}
      <div className="flex flex-col gap-5 mt-10 px-11">
        {!isFetchingOnboardingMailDetails ? (
          <div className="flex flex-col gap-1">
            <Text
              translationKey={t(
                "bankDetailsViaMail.rightColumn.clientHeading",
                {
                  entityName: onboardingMailDetails?.clientName ?? subdomain(),
                }
              )}
              classes="text-neutral-800 text-3xl font-semibold"
            />
            <Text
              translationKey={
                inPayrollContext
                  ? t("bankDetailsViaMail.rightColumn.Payroll.clientDesc", {
                      entityName:
                        onboardingMailDetails?.clientName ?? subdomain(),
                    })
                  : t("bankDetailsViaMail.rightColumn.clientDesc", {
                      entityName:
                        onboardingMailDetails?.clientName ?? subdomain(),
                    })
              }
              classes="text-neutral-500 text-base font-semibold"
            />
          </div>
        ) : (
          <div className="flex flex-col">
            <LoaderSkeleton classes="py-5 " fullWidth />
            <LoaderSkeleton fullWidth />
          </div>
        )}

        {!isFetchingOnboardingMailDetails ? (
          <Badge
            preIcon="LockClock"
            translationKey="bankDetailsViaMail.rightColumn.badgeDesc"
            variant="success"
            iconClasses="w-4 h-4 mr-1"
            classes="w-fit px-2 text-success-600"
          />
        ) : null}
      </div>

      {/* Form content */}
      {!isFetchingOnboardingMailDetails ? (
        <BankDetailMethodComponent
          classes="grow-0 overflow-auto"
          context={MAIL_CONTEXT}
        />
      ) : (
        <LoaderSkeleton classes="py-3" fullWidth />
      )}
    </div>
  );
}

RightColumn.propTypes = {
  context: PropTypes.string,
};

export default RightColumn;
