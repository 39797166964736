import LoaderSkeleton from "@/components/core/LoaderSkeleton";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function VendorTableLoader() {
  return Array(PAGINATION_PER_REQUEST_LIMIT)
    .fill(null)
    .map((_, idx) => (
      <tr key={`VendorTableLoader-${idx}`}>
        <td className="flex items-center gap-3 ml-4">
          <LoaderSkeleton type="circle" />
          <LoaderSkeleton size={[20, 180]} />
        </td>
        <td>
          <LoaderSkeleton />
        </td>
        <td>
          <LoaderSkeleton />
        </td>
        <td className="text-right">
          <LoaderSkeleton size={[20, 120]} />
        </td>
        <td className="text-right">
          <LoaderSkeleton size={[20, 120]} />
        </td>
        <td className="text-center">
          <LoaderSkeleton size={[20, 20]} />
        </td>
      </tr>
    ));
}
