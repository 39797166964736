import PropTypes from "prop-types";
import { IMAGES } from "@/assets/images";

export default function Image({
  name, //  `EmptyScreen`, exported from src/assets/images/index.jsx, this file must exist in `assets/images/`
  src = "", // `https://www.google.com/icon.png`
  alt,
  ...rest
}) {
  if (!name) console.warn("core/Image name prop is empty", { name });
  if (!IMAGES[name]) console.warn("Image name not found", { name });

  return (
    <img src={src || IMAGES[name]} alt={alt || `alt-text-${name}`} {...rest} />
  );
}

Image.Example = () => <Image name="PayNow" className="h-[125px] w-[190px]" />;

Image.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string,
  alt: PropTypes.string,
};
