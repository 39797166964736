import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { fetchPeopleShallow } from "@/store/reducers/company";
import { fetchAndSelectUser } from "@/store/reducers/user";

import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";
import {
  allPeopleSelector,
  isFetchingPeopleSelector,
} from "@/store/selectors/company";
import { isFetchingSelectedUserSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Input from "@/components/core/Input";
import VpSelect from "@/components/core/VpSelect";

import AccountingCategoryInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/AccountingCategoryInput";
import AccountingVendorInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/AccountingVendorInput";

import {
  ACCOUNTING_SOFTWARES_ICONS,
  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP,
} from "@/constants/accounting";
import {
  VENDOR_CREATE_FINAL_PAYLOAD_PARAMS,
  VENDOR_CREATE_PARAMS,
  VENDOR_LINKED_TO_TYPES,
  VENDOR_TYPES,
  VENDOR_TYPE_DESCRIPTION,
  VENDOR_TYPE_LABELS,
  VENDOR_UPDATE_REQUEST_PARAMS,
} from "@/constants/vendors";

export default function InitialInputFields({
  values,
  errors,
  handleChange,
  setValues,
  inPayrollContext,
  isEditMode,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isFetchingSelectedUser = useSelector(isFetchingSelectedUserSelector);
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );

  const isFetchingPeople = useSelector(isFetchingPeopleSelector);
  const people = useSelector(allPeopleSelector);

  useEffect(() => {
    dispatch(fetchPeopleShallow());
  }, []);

  const vendorTypeOptions = [
    {
      value: VENDOR_TYPES.CORPORATE,
      label: VENDOR_TYPE_LABELS[VENDOR_TYPES.CORPORATE],
      description: VENDOR_TYPE_DESCRIPTION[VENDOR_TYPES.CORPORATE],
    },
    {
      value: VENDOR_TYPES.INDIVIDUAL,
      label: VENDOR_TYPE_LABELS[VENDOR_TYPES.INDIVIDUAL],
      description: VENDOR_TYPE_DESCRIPTION[VENDOR_TYPES.INDIVIDUAL],
    },
  ];

  let label;

  if (inPayrollContext && accountingEnabled) {
    label = t(
      "payroll.payrollEmployees.addEmployee.employeeNameInAccountingSoftware",
      {
        accountingSoftware:
          ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingIntegrationSoftware],
      }
    );
  } else if (inPayrollContext && !accountingEnabled) {
    label = t("payroll.payrollEmployees.addEmployee.employeeName");
  } else if (!inPayrollContext && accountingEnabled) {
    label = t("billPay.vendors.createVendor.vendorNameInAccountingSoftware", {
      accountingSoftware:
        ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingIntegrationSoftware],
    });
  } else {
    label = t("billPay.vendors.titleSingularTitleCase");
  }

  let placeholder;

  if (inPayrollContext && accountingEnabled) {
    placeholder = t("payroll.payrollEmployees.addEmployee.selectEmployee");
  } else if (inPayrollContext && !accountingEnabled) {
    placeholder = t("payroll.payrollEmployees.addEmployee.employeeName");
  } else if (!inPayrollContext && accountingEnabled) {
    placeholder = t("billPay.vendors.createVendor.selectVendor");
  } else {
    placeholder = t("billPay.vendors.createVendor.vendorName");
  }

  let helperText;

  if (inPayrollContext && accountingEnabled) {
    helperText = t(
      "payroll.payrollEmployees.addEmployee.addEmployeeHelperText",
      {
        accountingSoftware:
          ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingIntegrationSoftware],
      }
    );
  } else {
    helperText = t("billPay.vendors.createVendor.selectVendorHelperText", {
      accountingSoftwareName:
        ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingIntegrationSoftware],
    });
  }

  return (
    <div className="flex flex-col gap-8.5 font-medium">
      <div>
        <AccountingVendorInput
          dropdownInputKey={VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.SELECTED_VENDOR}
          textInputKey={VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.VENDOR_NAME}
          creatable
          values={values}
          errors={errors}
          handleChange={handleChange}
          setValues={setValues}
          label={label}
          helperText={helperText}
          placeholder={placeholder}
          isEditMode={isEditMode}
          inPayrollContext={inPayrollContext}
        />
        {/* Existing/Creating Alert */}
        {values[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.SELECTED_VENDOR] ? (
          <div className="mt-2">
            <Alert
              variant="neutral"
              title={t(
                values[
                  VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.SELECTED_VENDOR
                ]?.toString() === (-1).toString()
                  ? inPayrollContext
                    ? "billPay.vendors.createVendor.creatingEmployeeInXYZAlert"
                    : "billPay.vendors.createVendor.creatingVendorInXYZAlert"
                  : inPayrollContext
                    ? "billPay.vendors.createVendor.existingEmployeeInXYZAlert"
                    : "billPay.vendors.createVendor.existingVendorInXYZAlert",
                {
                  software:
                    ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                      accountingIntegrationSoftware
                    ],
                }
              )}
              titleClasses="text-neutral-800 text-base font-semibold"
              icon={ACCOUNTING_SOFTWARES_ICONS[accountingIntegrationSoftware]}
            />
          </div>
        ) : null}
      </div>

      {inPayrollContext ? (
        <Input
          label={t("payroll.payrollEmployees.addEmployee.employeeId")}
          name={VENDOR_CREATE_PARAMS.EMPLOYEE_ID}
          value={values[VENDOR_CREATE_PARAMS.EMPLOYEE_ID]}
          onChange={handleChange}
          error={errors[VENDOR_CREATE_PARAMS.EMPLOYEE_ID]}
          disabled={!!isEditMode}
        />
      ) : null}

      <VpSelect
        disabled={inPayrollContext || isEditMode}
        name={VENDOR_UPDATE_REQUEST_PARAMS.VENDOR_TYPE}
        value={values[VENDOR_UPDATE_REQUEST_PARAMS.VENDOR_TYPE]}
        error={errors[VENDOR_UPDATE_REQUEST_PARAMS.VENDOR_TYPE]}
        handleChange={handleChange}
        insideForm
        label={
          inPayrollContext
            ? "payroll.payrollEmployees.addEmployee.employeeType"
            : "billPay.vendors.createVendor.vendorType"
        }
        placeholder="billPay.vendors.createVendor.selectVendorType"
        options={vendorTypeOptions}
        optionsDisplaySubtextKey="description"
        subTextTranslate
        menuPosition="absolute"
        translate
        valueKey="value"
        optionsDisplayKey="label"
      />

      {accountingEnabled ? (
        <AccountingCategoryInput
          dropdownInputKey="accountingCategory"
          textInputKey="accountingCategory"
          values={values}
          errors={errors}
          handleChange={handleChange}
          setValues={setValues}
          inPayrollContext={inPayrollContext}
          disabled={!!isEditMode}
        />
      ) : null}
      {inPayrollContext ? null : (
        <VpSelect
          name={VENDOR_CREATE_PARAMS.VENDOR_OWNER_ID}
          value={values[VENDOR_CREATE_PARAMS.VENDOR_OWNER_ID]}
          error={errors[VENDOR_CREATE_PARAMS.VENDOR_OWNER_ID]}
          handleChange={(e) => {
            // create/edit vendor linked to
            // when new owner selected, set project to user's own department and set type to department
            // i.e. to override project dropdown selection and radio (to avoid payload wrong project id bug)
            // harmless/ignorable elsewhere

            const newValue = e?.target?.value || e?.id || e;
            const oldValue = values[VENDOR_CREATE_PARAMS.VENDOR_OWNER_ID];

            if (newValue !== oldValue) {
              dispatch(
                fetchAndSelectUser({
                  userId: newValue,
                  onSuccess: (selectedUser) => {
                    const ownerDepartmentId = selectedUser?.departmentId;
                    // TECH_DEBT the if condition is needed since VpSelect handleChange event is not proper, it runs even if value didnt change, i.e. behaves like onSelect
                    // set department only if vendor owner changes, otherwise make no change (i.e. so if project was selected, it can stay)
                    setValues((prev) => ({
                      ...prev,
                      linkedTo: VENDOR_LINKED_TO_TYPES.DEPARTMENT,
                      projectId: ownerDepartmentId,
                    }));
                  },
                })
              );
            }
            handleChange(e);
          }}
          insideForm
          menuPosition="absolute"
          optionsDisplayKey="displayName"
          valueKey="id"
          options={people}
          isOptionsLoading={isFetchingPeople}
          label="billPay.vendors.createVendor.vendorOwner"
          placeholder="billPay.vendors.createVendor.selectVendorOwner"
          helperText="billPay.vendors.createVendor.selectVendorOwnerHelperText"
          disabled={isFetchingSelectedUser}
          // disabled={!!isEditMode} // always enabled, so link to project/dept can be edited
        />
      )}
    </div>
  );
}

InitialInputFields.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  inPayrollContext: PropTypes.bool,
  isEditMode: PropTypes.bool,
};
