import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  isFetchingVendorCountryCurrencyMappingsSelector,
  vendorCountryCurrencyMappingsSelector,
} from "@/store/selectors/vendors";

import AirwallexBankDetails from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/AirwallexBankDetails";
import AllBankDetails from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/AllBankDetails";
import DecentroBankDetails from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/DecentroBankDetails";
import RapydBankDetails from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/RapydBankDetails";
import WallexBankDetails from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/WallexBankDetails";
import XenditBankDetails from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/XenditBankDetails";
import { paymentMethodAndPaymentMethodCategoryMatches } from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/util";
import {
  generateAllPossibleNodesFromNestedObject,
  getRelevantValuesFromAllPossibleNodes,
} from "@/utils/common";

import { PAYMENT_PROVIDERS } from "@/constants/provider";
import {
  VENDOR_CREATE_FINAL_PAYLOAD_PARAMS,
  VENDOR_CREATE_REQUIREMENTS_PARAMS,
} from "@/constants/vendors";

// it's important that these values stay this way.
// Since BankDetailsBasedOnProvider
const BENEFICIARY_COUNTRY_ENUM =
  VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY;
const BENEFICIARY_CURRENCY_ENUM =
  VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY;
const PAYMENT_METHOD_ENUM = "local_or_swift_payment_method_hidden_dropdown";
const PAYMENT_METHOD_EXTENDED_ENUM =
  VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_METHOD;

/**
 * Bank details input component
 *
 * Renders max 5 fields (order may vary) -
 *  - Payment account
 *  - Bene country
 *  - Bene currency
 *  - Bene bank country
 *  - Payment method (extended one, any arbitrary string)
 *
 *
 * See internals for field names <Input name />
 */
export default function BankDetailsBasedOnProvider({
  provider,
  values,
  errors,
  handleChange,
  paymentMethods, // 'BANK_OF_CHINA'
  isFetchingPaymentMethods,
  isEditMode,
  setValues,
  context,
  inPayrollContext,
}) {
  const isFetchingCountryCurrencyMappings = useSelector(
    isFetchingVendorCountryCurrencyMappingsSelector
  );
  const countryCurrencyMappings =
    useSelector(vendorCountryCurrencyMappingsSelector) ?? {};

  // dropdown filtering logic based on existing values

  const allPossibilities = useMemo(() => {
    if (isFetchingCountryCurrencyMappings || !countryCurrencyMappings)
      return [];

    const data = countryCurrencyMappings;
    const keys = [
      BENEFICIARY_COUNTRY_ENUM,
      PAYMENT_METHOD_ENUM,
      BENEFICIARY_CURRENCY_ENUM,
    ];
    return generateAllPossibleNodesFromNestedObject(data, keys);
  }, [countryCurrencyMappings]);

  const fieldsFilters = useMemo(() => {
    const formObject = {};
    const formObjectCriterias = {};

    formObject[BENEFICIARY_COUNTRY_ENUM] = values[BENEFICIARY_COUNTRY_ENUM];
    formObject[BENEFICIARY_CURRENCY_ENUM] = values[BENEFICIARY_CURRENCY_ENUM];
    formObject[PAYMENT_METHOD_ENUM] = ""; // local/swift dropdown is hidden (unselected always)

    // PAYMENT_METHOD_ENUM doesn't participate in dropdown (assume hidden and always unselected)
    formObjectCriterias[PAYMENT_METHOD_ENUM] = () => {
      return true;
      // payment method is a little weird. It could be a custon string like 'BANK_OF_AMERICA', instead
      // of local/swift
      // So we assume with local/swift. Instead assume custom string only.
      // Use the utils to get/check local/swift equivalent if needed

      // possibleValue is local/swift in our case, since ccMapping returns these two
      // filledValue a custom string like 'BANK_OF_AMERICA', but could be local/swift too.
    };

    const relevantValuesObject = getRelevantValuesFromAllPossibleNodes(
      allPossibilities,
      formObject,
      formObjectCriterias
    );

    return {
      relevantCountries: relevantValuesObject[BENEFICIARY_COUNTRY_ENUM], // example: 'SG'
      relevantCurrencies: relevantValuesObject[BENEFICIARY_CURRENCY_ENUM], // example: 'SGD'
      relevantPaymentMethods: paymentMethods
        // ['BANK_OF_CHINA']
        .filter((item) => {
          const extendedPaymentMethod = item[PAYMENT_METHOD_EXTENDED_ENUM]; // 'BANK_OF_CHINA'
          const relevantShortPaymentMethods =
            relevantValuesObject[PAYMENT_METHOD_ENUM]; // ['local', 'swift], aka category

          return relevantShortPaymentMethods.some((pmc) =>
            paymentMethodAndPaymentMethodCategoryMatches(
              extendedPaymentMethod,
              pmc
            )
          );
        })
        .map((item) => item[PAYMENT_METHOD_EXTENDED_ENUM]), // needs to be array of strings, ['BANK_OF_CHINA']
    };
  }, [
    allPossibilities,
    values,
    values[BENEFICIARY_COUNTRY_ENUM],
    values[BENEFICIARY_CURRENCY_ENUM],
    values[PAYMENT_METHOD_ENUM],
    paymentMethods,
  ]);

  useEffect(() => {
    if (provider === PAYMENT_PROVIDERS.DECENTRO) {
      setValues((prev) => ({
        ...prev,
        [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]: "IN",
        [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]: "INR",
        [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]: "IN",
      }));
    } else if (provider === PAYMENT_PROVIDERS.XENDIT) {
      setValues((prev) => ({
        ...prev,
        [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]: "ID",
        [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]: "IDR",
        [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]: "ID",
      }));
    }
  }, [provider, values[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]]);

  switch (provider) {
    case PAYMENT_PROVIDERS.AIRWALLEX:
      return (
        <AirwallexBankDetails
          {...{
            values,
            errors,
            handleChange,
            setValues,
            paymentMethods,
            isFetchingPaymentMethods,
            fieldsFilters,
            isEditMode,
            context,
            provider,
            inPayrollContext,
          }}
        />
      );
    case PAYMENT_PROVIDERS.WALLEX:
      return (
        <WallexBankDetails
          {...{
            values,
            errors,
            handleChange,
            paymentMethods,
            isFetchingPaymentMethods,
            setValues,
            fieldsFilters,
            isEditMode,
            provider,
            context,
            inPayrollContext,
          }}
        />
      );
    case PAYMENT_PROVIDERS.RAPYD:
      return (
        <RapydBankDetails
          {...{
            values,
            errors,
            handleChange,
            paymentMethods,
            isFetchingPaymentMethods,
            setValues,
            fieldsFilters,
            isEditMode,
            inPayrollContext,
          }}
        />
      );
    case PAYMENT_PROVIDERS.DECENTRO:
      return (
        <DecentroBankDetails
          {...{
            values,
            errors,
            handleChange,
            setValues,
            paymentMethods,
            isFetchingPaymentMethods,
            fieldsFilters,
            isEditMode,
            context,
            inPayrollContext,
          }}
        />
      );
    case PAYMENT_PROVIDERS.XENDIT:
      return (
        <XenditBankDetails
          {...{
            values,
            errors,
            handleChange,
            disabled: true,
            setValues,
            fieldsFilters,
            isEditMode,
            provider,
            context,
            inPayrollContext,
          }}
        />
      );
    default:
      return (
        <AllBankDetails
          {...{
            values,
            errors,
            handleChange,
            setValues,
            paymentMethods,
            isFetchingPaymentMethods,
            fieldsFilters,
            isEditMode,
            context,
            inPayrollContext,
          }}
        />
      );
  }
}

BankDetailsBasedOnProvider.propTypes = {
  provider: PropTypes.oneOf(Object.values(PAYMENT_PROVIDERS)),
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  isEditMode: PropTypes.bool,
  paymentMethods: PropTypes.array,
  isFetchingPaymentMethods: PropTypes.bool,
  context: PropTypes.string,
  setValues: PropTypes.func, // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough)
  inPayrollContext: PropTypes.bool,
};
