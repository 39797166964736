import React from "react";

import Text from "@/components/core/Text";

export default function OrDivider() {
  return (
    <div className="w-full flex items-center gap-2">
      <span className="inline-block w-1/2 border-neutral-200 border-b-2" />
      <Text classes="text-neutral-500" translationKey="or" />
      <span className="inline-block w-1/2 border-neutral-200 border-b-2" />
    </div>
  );
}
