import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Icon from "@/components/core/Icon";
import Progress from "@/components/core/Progress";
import Text from "@/components/core/Text";
import { BULK_UPLOAD_DOCUMENT_ICONS } from "@/utils/constants/payments";

/**
 * Render the custom file previewer for bulk upload.
 *
 * @param {object} _file - The file object
 * @param {number} index - The index of the file
 * @param {object} primaryAction - The primary action object
 * @param {object} secondaryAction - The secondary action object
 * @param {function} handleFileOpen - The function to handle file open
 * @param {string} fileSize - The size of the file
 * @param {number} isFetchingIndex - The index being fetched
 * @param {function} onFilePreviewer - The function to preview the file
 * @param {object} rest - The rest of the parameters
 * @return {JSX.Element} The custom file previewer component
 */
function BulkUploadCustomFilePreviewer({
  file: _file,
  index,
  primaryAction,
  secondaryAction,
  handleFileOpen,
  fileSize,
  isFetchingIndex,
  onFilePreviewer,
  ...rest
}) {
  const [number, setNumber] = useState(0);

  const isThisFileFetching =
    (index || index === 0) &&
    (isFetchingIndex || isFetchingIndex === 0) &&
    index === isFetchingIndex;
  const [fileSizeValue, fileSizeUnit] = fileSize?.split(" ")?.[0] ?? [];
  const isProcessingFileItem = Number.parseInt(
    (fileSizeValue ?? 0) * (number ?? 0),
    10
  );

  // mimicking reload of files
  useEffect(() => {
    let interval = null;
    if (isThisFileFetching) {
      const randomMiliseconds = Math.floor(Math.random() * 501);

      interval = setInterval(() => {
        setNumber((prev) => {
          if (prev <= 80) return prev + 5;
          clearInterval(interval);
          return prev;
        });
      }, randomMiliseconds);
    } else clearInterval(interval);

    return () => {
      if (isThisFileFetching) clearInterval(interval);
    };
  }, [isFetchingIndex]);

  return (
    <div className="flex cursor-pointer flex-col items-center px-9 justify-center w-16.35 h-16.5 relative bg-neutral-50 rounded-xl">
      <div
        className="absolute p-2 rounded-lg cursor-pointer -top-2 -right-2 bg-primary-50 "
        onClick={(e) => {
          primaryAction?.handler?.(index);
        }}
      >
        <Icon name="Close" className="rounded-lg text-primary-500 " />
      </div>
      <div
        onClick={() => {
          onFilePreviewer();
        }}
        className="flex cursor-pointer flex-col items-center px-9 justify-center w-16.35 h-16.5  bg-neutral-50 rounded-xl"
      >
        <div className="flex items-center justify-center mb-2 bg-white rounded-lg w-13 h-13 ">
          <Icon
            name={BULK_UPLOAD_DOCUMENT_ICONS[_file.type]?.iconName}
            className="w-5 h-5 text-neutral-500"
          />
        </div>
        <div className="flex flex-col gap-1">
          <Text
            classes="cursor-pointer  text-sm w-15 text-center inline-block truncate text-neutral-800 font-semibold"
            translationKey={_file.name}
          />
          {isThisFileFetching ? (
            <Progress value={number} classes="w-full" />
          ) : null}
          <Text
            classes="cursor-pointer  text-sm w-15 text-xs  inline-block truncate text-neutral-500 font-normal"
            translationKey={
              isThisFileFetching
                ? `${isProcessingFileItem} ${fileSizeUnit}/${fileSize}`
                : fileSize
            }
          />
        </div>
      </div>
    </div>
  );
}

export default BulkUploadCustomFilePreviewer;
BulkUploadCustomFilePreviewer.propTypes = {
  _file: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  primaryAction: PropTypes.object.isRequired,
  secondaryAction: PropTypes.object.isRequired,
  handleFileOpen: PropTypes.func.isRequired,
  fileSize: PropTypes.string.isRequired,
  isFetchingIndex: PropTypes.number.isRequired,
  onFilePreviewer: PropTypes.func.isRequired,
};
