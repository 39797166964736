import get from "get-value";
import {
  BILL_FORM_MODES,
  LINE_ITEMS_RESPONSE_KEYS,
  LINE_ITEM_KEY,
  LINE_ITEM_KEYS,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";

/**
 * @param {Number} amount bill amount (exclusive of tax)
 * @param {String | Number} categoryNameOrAmountOrRate tax category name or tax amount
 *
 * @returns {Number} tax amount
 */
export function getTaxAmount(
  amount,
  categoryNameOrAmountOrRate, // if rate is passed, it's a number between 0 and 100
  isRate = false
) {
  categoryNameOrAmountOrRate ||= 0;
  const isCategory = isRate;

  if (isCategory) {
    const rate = removeTaxNotation(categoryNameOrAmountOrRate);
    return (amount * rate) / 100;
  }

  return parseFloat(categoryNameOrAmountOrRate);
}

/**
 * Returns value from notation form
 * Is idempotent
 * @param {String} categoryNameOrRate tax category name
 *
 * @returns {Number} percentage, between 0 and 1
 */
export function removeTaxNotation(categoryNameOrRate) {
  const isInNotation = !!`${categoryNameOrRate}`?.includes("#");
  return parseFloat(
    isInNotation
      ? categoryNameOrRate.split("#").at(-1)
      : (categoryNameOrRate ?? 0)
  );
}

export const convertOptionsToRequiredOptionsStructure = (options) => {
  return options?.map((item) => ({
    ...item,
    label: item?.name,
    value: item?.id,
  }));
};
/**
 * A helper that gets values from a prefill source
 * Does not contain validators, errorStatements
 *
 * Intent: enforce consistent structure in payment form, and avoid one-off code in the component
 *
 * @param { Object } prefillData source to prefill form data from (may be OCR response, slider response)
 * @param { Object } formKeyToPrefillDataMap formKey: prefillDataKeyOrFunction map used to generate form data
 *        the key is a string
 *        the value may be a string (a valid dot seprated object path)
 *          or a function, the output will be the value.
 *        The form value is determined by the path or the function output
 * @param {Function} postMapMutation optional, change the value generated until the point (from map)
 *
 *
 */
export const getInitalFormValues = (
  prefillData = {},
  formKeyToPrefillDataMap = {},
  postMapMutation = (valueUntilNow, data, mapp) => valueUntilNow
) => {
  const mapOutput = Object.entries(formKeyToPrefillDataMap).reduce(
    (accum, [formKey, prefillKeyOrFunc]) => {
      accum[formKey] =
        typeof prefillKeyOrFunc === typeof (() => {})
          ? prefillKeyOrFunc(prefillData, formKeyToPrefillDataMap)
          : get(prefillData, prefillKeyOrFunc);

      return accum;
    },
    {}
  );

  return postMapMutation(mapOutput, prefillData, formKeyToPrefillDataMap);
};

/**
 * Is bill form in edit mode
 */
export const isEditMode = (formMode) => formMode === BILL_FORM_MODES.EDIT;
/**
 * @param {Object} tax TaxMini object from BE
 * @param {Object} amount { value, currency } object of the item
 *
 */

export function getTaxValues(tax, amount) {
  const currency = amount?.currency ?? "";
  const name = tax[LINE_ITEMS_RESPONSE_KEYS.TAX_ITEM.NAME];
  const rate = tax[LINE_ITEMS_RESPONSE_KEYS.TAX_ITEM.RATE];
  const value = tax[LINE_ITEMS_RESPONSE_KEYS.TAX_ITEM.VALUE];
  const category = tax[LINE_ITEMS_RESPONSE_KEYS.TAX_ITEM.CATEGORY];

  const description = name
    ? `${rate}% (${[name, category].filter(Boolean).join(", ")})`
    : `${rate}% ${category ?? ""}`;

  return { value, currency, description };
}

export function getTagItemWithData(lineItem, lineItemId) {
  return (tag) => {
    const tagData = lineItem?.[
      LINE_ITEMS_RESPONSE_KEYS.LINE_ITEM_TAG_VALUES.selfKey
    ]?.find?.((tagDataItem) => tagDataItem.tagId === tag.id);

    const prefix = `${LINE_ITEM_KEY}.${lineItemId}.${LINE_ITEM_KEYS.ACCCOUNTING_TAGS}`;
    const tagValueKey = `${prefix}.tag-id-${tagData?.tagId || tag.id}`;
    const tagBEIDKey = `${prefix}.tag-be-id-${tagData?.tagId || tag.id}`;

    return {
      ...(tagData?.[LINE_ITEMS_RESPONSE_KEYS.LINE_ITEM_TAG_VALUES.ID]
        ? {
            [tagBEIDKey]:
              tagData?.[LINE_ITEMS_RESPONSE_KEYS.LINE_ITEM_TAG_VALUES.ID],
          }
        : {}),
      [tagValueKey]:
        tagData?.[LINE_ITEMS_RESPONSE_KEYS.LINE_ITEM_TAG_VALUES.TAG_VALUE_ID] ||
        tagData?.[LINE_ITEMS_RESPONSE_KEYS.LINE_ITEM_TAG_VALUES.TAG_VALUE] ||
        "",
    };
  };
}
