import { PERMISSION_DENIED, VP_ERROR_COMPONENT_TYPE } from "@/constants/common";

import Icon from "../Icon";
import Text from "../Text";

/**
 * CompactUI component renders a compact version of the error display.
 * @param {string} header - The header text for the error.
 * @param {string} description - The description text for the error.
 * @param {string} iconClasses - Additional classes for the icon element.
 * @param {string} titleClasses - Additional classes for the title element.
 * @param {string} descriptionClasses - Additional classes for the description element.
 * @returns {JSX.Element} The JSX element for the compact error display.
 */
const CompactUI = ({
  header,
  description,
  iconClasses,
  titleClasses,
  descriptionClasses,
}) => {
  return (
    <div className="flex  flex-row items-center gap-1">
      <Icon name="Lock" className={`text-neutral-800 ${iconClasses}`} />
      <div className="flex flex-col">
        {header ? (
          <Text
            classes={`text-neutral-500 text-sm font-semibold ${titleClasses}`}
            translationKey={header}
          />
        ) : null}
        <Text
          classes={`text-2xs text-neutral-800 font-semibold ${descriptionClasses}`}
          translationKey={description || "common.errorDescription"}
        />
      </div>
    </div>
  );
};

/**
 * FullUI component renders a full version of the error display.
 * @param {string} header - The header text for the error.
 * @param {string} description - The description text for the error.
 * @param {string} iconClasses - Additional classes for the icon element.
 * @param {string} titleClasses - Additional classes for the title element.
 * @param {string} descriptionClasses - Additional classes for the description element.
 * @param {boolean} overrideTitle - Whether to override the title.
 * @param {boolean} overrideDescription - Whether to override the description.
 * @param {string} code - The error code.
 * @returns {JSX.Element} The JSX element for the full error display.
 */
export const FullUI = ({
  header,
  description,
  iconClasses,
  headerClasses,
  titleClasses,
  descriptionClasses,
  overrideTitle,
  overrideDescription,
  code,
}) => {
  const isPermissionDenied = code === PERMISSION_DENIED;
  const iconName = isPermissionDenied ? "AccessDeniedError" : "Error404";
  const title = isPermissionDenied
    ? "common.accessDenied"
    : "common.failedToLoad";

  const _description = overrideDescription
    ? description
    : isPermissionDenied
      ? "common.accessDeniedDescription"
      : "common.notFoundDescription";

  return (
    <div className="flex flex-col gap-6 items-start">
      {header ? (
        <Text
          classes={`text-neutral-800 text-lg font-bold ${headerClasses}`}
          translationKey={header}
        />
      ) : null}
      <div className="flex flex-col items-center h-full justify-center gap-1">
        <Icon name={iconName} className={`mb-4 ${iconClasses}`} />
        <Text
          classes={`text-neutral-800 text-center text-lg font-bold ${titleClasses}`}
          translationKey={title}
        />
        <Text
          classes={`text-sm text-neutral-800 text-center  font-medium ${descriptionClasses}`}
          translationKey={_description}
        />
      </div>
    </div>
  );
};
/**
 * Renders a component to display errors based on the provided error object.
 * @param {Object} props - The props object containing element and errorObject.
 * @param {HTMLElement} props.element - The HTML element where the error component will be rendered.
 * @param {Object} props.errorObject - The error object containing information about the error.
 * @returns {JSX.Element} The error component JSX element.
 */
const VPErrors = ({ element, errorObject }) => {
  const newErrorObject = { type: VP_ERROR_COMPONENT_TYPE.FULL, ...errorObject };
  return (
    <div
      style={{ border: "none", gap: 2 }}
      className={`${element.classList.value} cursor-not-allowed border-none shadow-none gap-2 ${errorObject?.classes}`}
    >
      {newErrorObject?.type === VP_ERROR_COMPONENT_TYPE.FULL ? (
        <FullUI {...newErrorObject} />
      ) : (
        <CompactUI {...newErrorObject} />
      )}
    </div>
  );
};

export default VPErrors;
