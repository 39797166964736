import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateCompanyAlert } from "@/store/reducers/company";

import { isLoadingAlertsSelector } from "@/store/selectors/company";

import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";
import { ALERTS_TYPES_TEXT_CONFIG } from "@/utils/constants/client";

import AlertAmountToggleItem from "./AlertAmountToggleItem";

export default function AlertToggleAndAmount({ alert }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingAlertsSelector);
  const [isEnabled, setIsEnabled] = useState(alert?.enabled);

  const [selectedAmount, setSelectedAmount] = useState(alert?.amounts[0]);

  const initialValue = alert.amounts.reduce((acc, amount) => {
    const { id, value } = amount;
    return {
      ...acc,
      [id]: {
        value,
        validate: { required: true },
      },
    };
  }, {});

  const handleAlertToggle = (val) => {
    setIsEnabled(val);
    dispatch(
      updateCompanyAlert({
        key: alert?.key,
        enabled: val,
        amounts: alert?.amounts,
      })
    );
  };

  const handleAmountUpdate = () => {
    dispatch(
      updateCompanyAlert({
        key: alert?.key,
        enabled: true,
        amounts: [
          {
            id: selectedAmount?.id,
            currency: selectedAmount?.currency,
            value: values[selectedAmount?.id],
          },
        ],
      })
    );
  };

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    _setValues: setValues,
  } = useForm(initialValue, () => {
    handleAmountUpdate();
  });

  return (
    <div className="flex w-full justify-between items-start">
      <div className="flex flex-col gap-2 w-1/3">
        <div className="flex items-center gap-2">
          <Switch
            value={isEnabled}
            handleChange={(val) => handleAlertToggle(val)}
          />
          <Text
            translationKey={ALERTS_TYPES_TEXT_CONFIG[alert?.key].switch.title}
            classes="text-lg font-semibold"
          />
        </div>
        <Text
          translationKey={
            ALERTS_TYPES_TEXT_CONFIG[alert?.key].switch.description
          }
          classes="text-sm font-medium text-neutral-500"
        />
      </div>
      <div className="flex flex-col w-1/2 gap-6">
        <div className="flex flex-col gap-1">
          <Text
            translationKey={
              ALERTS_TYPES_TEXT_CONFIG[alert?.key].amountInput.title
            }
            classes="text-base font-semibold"
          />
          <Text
            translationKey={
              ALERTS_TYPES_TEXT_CONFIG[alert?.key].amountInput.description
            }
            classes="text-sm font-medium text-neutral-500"
          />
        </div>

        {isEnabled
          ? alert?.amounts?.map((amount, index) => {
              return (
                <AlertAmountToggleItem
                  key={`alert-amount-${amount?.id}`}
                  index={index}
                  alert={alert}
                  amount={amount}
                  isLoading={isLoading}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  setSelectedAmount={setSelectedAmount}
                  values={values}
                  errors={errors}
                  setValues={setValues}
                />
              );
            })
          : null}
      </div>
    </div>
  );
}

AlertToggleAndAmount.propTypes = {
  alert: PropTypes.object,
};
