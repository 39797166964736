// utils, constant file imports
import PropTypes from "prop-types";

// custom hooks
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

// core components
import Text from "@/components/core/Text";

/**
 * params: {cardId: Number}
 * @param {String}              heading                         main title
 * @param {String}              subText                         sub text below main title
 * @param {String}              headingClasses                  css classes for heading
 * @param {String}              headingTranslationProps         translation props for heading
 * @param {String}              subTextClasses                  css classes for subText
 * @param {String}              subTextTranslationProps         translation props for subtext
 * @param {Boolean}             refFlag                         a boolean to decide if refProp is required or not
 *
 * Usage
 * A dumb UI component to render a heading along with a sub-heading or sub-text with the classes passed as desired
 */

export default function SliderHeader({
  heading,
  subText,
  headingClasses,
  headingTranslationProps,
  subTextClasses,
  subTextTranslationProps,
  refFlag,
}) {
  const titleRef = useLeftHeaderTitle({
    title: heading,
    titleTranslationProps: headingTranslationProps,
  });
  const ref = refFlag ? titleRef : null;

  return (
    <div className="flex flex-col gap-1">
      {heading ? (
        <Text
          refProp={ref}
          translationKey={heading}
          translationProps={headingTranslationProps}
          classes={headingClasses}
        />
      ) : null}
      {subText ? (
        <Text
          translationKey={subText}
          translationProps={subTextTranslationProps}
          classes={subTextClasses}
        />
      ) : null}
    </div>
  );
}

SliderHeader.propTypes = {
  heading: PropTypes.string,
  subText: PropTypes.string,
  headingClasses: PropTypes.string,
  headingTranslationProps: PropTypes.object,
  subTextClasses: PropTypes.string,
  subTextTranslationProps: PropTypes.object,
  refFlag: PropTypes.bool,
};
