import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  addAccountingIntegrationImportFileFields,
  addAccountingIntegrationImportedFiles,
  importUCSVFiles,
  setAccountingIntegrationImportedFiles,
} from "@/store/reducers/accounting";

// selectors
import {
  accountingIntegrationImportedFilesSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";

// core components
import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";
import Input from "@/components/core/Input";

// page relevant components
import CSVFileUploadPreview from "@/components/Accounting/Integrations/common/CSVFileUploadPreview";
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import { useForm } from "@/utils/useForm";
import {
  FILE_IMPORT_VARIANTS,
  INTEGRATION_UCSV_CHART_OF_ACCOUNGTS_TEMPLATE_URL,
} from "@/utils/constants/integrations";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function CreateFieldSlider() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [file, setFile] = useState([]);
  const [hasFieldName, setHasFieldName] = useState(false);
  const importedFiles = useSelector(accountingIntegrationImportedFilesSelector);
  const isLoadingConnect = useSelector(isLoadingAccountingIntegrationsSelector);

  const initialValue = {
    fieldName: {
      value: "",
      validate: {
        required: true,
      },
    },
    file: {
      value: null,
      validate: {
        required: true,
      },
    },
  };

  const { handleChange, values, errors, handleSubmit, isFormButtonDisabled } =
    useForm(initialValue, () => {
      handleContinue();
    });

  useEffect(() => {
    if (values?.fieldName?.trim() !== "") setHasFieldName(true);
    else setHasFieldName(false);
  }, [values]);

  const handleCancel = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.createNewField
    );
    setSearchParams(searchParams);
  };

  const handleDownloadTemplate = () => {
    window.open(INTEGRATION_UCSV_CHART_OF_ACCOUNGTS_TEMPLATE_URL, "_blank");
  };

  const handleContinue = async () => {
    const action = await dispatch(
      importUCSVFiles({
        csv_file: values?.file,
        template: FILE_IMPORT_VARIANTS.CHART_OF_ACCOUNTS,
        override_values: false,
        field_name: values?.fieldName,
      })
    );

    if (importUCSVFiles.fulfilled.match(action)) onSuccessfulUpload();
  };

  const onSuccessfulUpload = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.createNewField
    );
    setSearchParams(searchParams);

    dispatch(
      addAccountingIntegrationImportFileFields({
        name: values?.fieldName,
        icon: "AddOnField",
        value: values?.fieldName.replace(" ", "_"),
      })
    );

    if (!importedFiles)
      dispatch(
        setAccountingIntegrationImportedFiles({
          [values?.fieldName.replace(" ", "_")]: values?.file,
        })
      );
    else
      dispatch(
        addAccountingIntegrationImportedFiles({
          [values?.fieldName.replace(" ", "_")]: values?.file,
        })
      );
  };

  return (
    <div className="slider-content-container flex flex-col gap-11.5">
      <div className="flex flex-col gap-8 px-10 slider-core-container ">
        <SliderHeader
          heading="accounting.integrations.universalCsv.createField.title"
          headingClasses="text-4xl font-bold text-neutral-800"
          refFlag
        />

        <form
          onSubmit={handleSubmit}
          id="ucsv-create-field-form"
          className="flex flex-col gap-9"
        >
          <div className="flex flex-col justify-center gap-6">
            <SliderHeader
              heading="accounting.integrations.universalCsv.createField.fieldName.label"
              subText="accounting.integrations.universalCsv.createField.fieldName.description"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <Input
              label="accounting.integrations.labels.name"
              value={values.fieldName}
              error={errors.fieldName}
              name="fieldName"
              onChange={handleChange}
            />
          </div>

          <div
            className={`flex flex-col justify-center gap-4 ${
              !hasFieldName ? "opacity-50" : ""
            }`}
          >
            <SliderHeader
              heading="accounting.integrations.universalCsv.downloadTemplate.title"
              subText="accounting.integrations.universalCsv.downloadTemplate.description"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <Button
              variant="tertiary"
              label="accounting.integrations.buttonLabel.downloadTemplate"
              preIcon="Download"
              classes="w-1/3 px-5 py-1 text-xs font-bold"
              disabled={!hasFieldName}
              onClick={() => {
                handleDownloadTemplate();
              }}
            />
          </div>

          <div
            className={` flex flex-col justify-center gap-4 ${
              !hasFieldName ? "opacity-40 pointer-events-none" : ""
            }`}
          >
            <SliderHeader
              heading="accounting.integrations.universalCsv.uploadTemplate.title"
              subText="accounting.integrations.universalCsv.uploadTemplate.description"
              subTextTranslationProps={{ text: "tag name and ID" }}
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />

            <FileUpload
              accept={{ "text/csv": [".csv"] }}
              name="file"
              files={values.file || []}
              handleFileChange={handleChange}
              acceptText="common.csvFormatFileUploadAccept"
              primaryAction={{
                handler: () => setFile([]),
                label: "Delete",
                icon: "Delete",
                iconClasses: "text-danger-600 bg-danger-50",
              }}
              previewCustomComponent={(props) => (
                <CSVFileUploadPreview
                  file={props.file}
                  primaryAction={props.primaryAction}
                />
              )}
              maxFiles={1}
              hideCustomButtonAfterUpload
              insideForm
            />
          </div>
        </form>
      </div>
      <div className="sticky px-6 py-4 slider-footer">
        <div className="flex items-center justify-end gap-6 text-base font-semibold">
          <Button
            label="accounting.integrations.buttonLabel.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            onClick={() => handleCancel()}
          />
          <Button
            label="accounting.integrations.buttonLabel.saveContinue"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            btnType="submit"
            disabled={isFormButtonDisabled}
            showLoader={isLoadingConnect}
            form="ucsv-create-field-form"
          />
        </div>
      </div>
    </div>
  );
}
