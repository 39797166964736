import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import PaymentsApprovalList from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Approvals/ApprovalList";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";

export default function PaymentsApprovalsPageHelper({
  tabType,
  page,
  isActionCentrePage,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const inPayrollContext = page === BILL_PAYROLL_CONTEXT.PAYROLL;
  const openSliderHandler = (id, extraParams = {}) => {
    const paramName = [
      BILL_PAYROLL_CONTEXT.BILLPAY,
      BILL_PAYROLL_CONTEXT.ACTION_CENTER_BILLPAY,
    ]?.includes(page)
      ? SLIDERS_SEARCH_PARAMS.payments.id
      : SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId;
    setSearchParams({
      [paramName]: id,
      [SLIDERS_SEARCH_PARAMS.payments.sliderType]: "approvals",
      [SLIDERS_SEARCH_PARAMS.payments.pageType]: tabType,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]: paramName,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled]: true,
      ...extraParams,
    });
  };

  return (
    <PaymentsApprovalList
      isActionCentrePage={isActionCentrePage}
      tabType={tabType} // type of the current tab,(needs your approval, pending ,all)
      page={page}
      openSliderHandler={openSliderHandler}
    />
  );
}
PaymentsApprovalsPageHelper.propTypes = {
  tabType: PropTypes.string,
  page: PropTypes.string,
  isActionCentrePage: PropTypes.bool,
};
