import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import Alert from "@/components/core/Alert";

import { CARD_FREQUENCY_LABEL, frequencyOrder } from "@/constants/Cards";

export default function FrequencyOrderNote({ fromFrequency, toFrequency }) {
  const { t } = useTranslation();

  return frequencyOrder[fromFrequency] < frequencyOrder[toFrequency] ? (
    <div className="pt-4 pb-4">
      <Alert
        variant="warning"
        titleTransalationProp={{
          from: t(CARD_FREQUENCY_LABEL[fromFrequency]),
          to: t(CARD_FREQUENCY_LABEL[toFrequency]),
        }}
        title="cards.cardDetails.editLimitDetails.dailyToMonthlyChange"
        wrapperDivClass="items-start"
      />
    </div>
  ) : null;
}

FrequencyOrderNote.propTypes = {
  fromFrequency: PropTypes.string,
  toFrequency: PropTypes.string,
};
