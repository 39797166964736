import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import {
  USER_BLOCK_FREEZE_APPROVAL_POLICIES_REQUIREMENTS_CONFIG,
  USER_BLOCK_FREEZE_POLICY_KEYS,
} from "@/constants/company";

export default function UserRequirements({ data }) {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="bg-white border border-neutral-300 py-4 px-3 rounded-lg flex flex-col gap-6">
      {data
        ? Object.entries(data)?.map(([key, values], index) =>
            values.length > 0 ? (
              <div
                key={`user-requirements-${key}-${index}-block-freeze-user`}
                className="flex flex-col gap-6"
              >
                {values?.map((item, idx) => (
                  <div
                    key={`user-requirements-block-freeze-${key}-${idx}`}
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() => {
                      const paramsObject =
                        key === USER_BLOCK_FREEZE_POLICY_KEYS.GENERIC
                          ? USER_BLOCK_FREEZE_APPROVAL_POLICIES_REQUIREMENTS_CONFIG[
                              USER_BLOCK_FREEZE_POLICY_KEYS.GENERIC
                            ][item?.key]
                          : USER_BLOCK_FREEZE_APPROVAL_POLICIES_REQUIREMENTS_CONFIG[
                              key
                            ];
                      searchParams.append(
                        paramsObject?.searchParam,
                        paramsObject?.searchValue
                      );
                      setSearchParams(searchParams);
                    }}
                  >
                    <div>
                      <Text
                        translationKey={item?.name}
                        classes="text-lg font-semibold text-neutral-800 flex flex-row items-center"
                      />
                      <div className="flex items-center gap-1">
                        {item &&
                          item?.modules?.map((module, indx) => (
                            <div
                              className="flex flex-row items-center gap-1"
                              key={`${module}-user-requirements-block-freeze-user`}
                            >
                              <Text
                                translationKey={`${module?.name} `}
                                classes="text-sm font-medium text-neutral-500"
                              />
                              {indx !== (item?.modules?.length ?? 0) - 1 ? (
                                <Icon
                                  name="Dot"
                                  className="w-1 h-1 text-neutral-500"
                                />
                              ) : null}
                            </div>
                          ))}
                      </div>
                    </div>
                    <Icon name="EArrow" className="w-6 h-6 text-neutral-500" />
                  </div>
                ))}
              </div>
            ) : null
          )
        : null}
    </div>
  );
}

UserRequirements.propTypes = {
  data: PropTypes.array,
};
