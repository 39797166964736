import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { actionInProgressIdSelector } from "@/store/selectors/actionCentre";
import { newCardRequestDetailsSelector } from "@/store/selectors/cards";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";

import MoreOptions from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentSliderFooter/MoreOptions";
import { convertFilters } from "@/utils/filters";

import { CTA_CONFIG_CARD_REQUEST } from "@/constants/cardRequest";

function RequestSliderFooter({
  handleReject,
  handleApprove,
  scrollToComments,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(userSelector);

  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);
  const allowedCtas = cardRequestDetails?.allowedCtas ?? [];
  const actionInProgress = useSelector(actionInProgressIdSelector);

  const haveAppoveAndRejectBtn = ["approve", "reject", "skipApprovals"].every(
    (value) => allowedCtas?.includes(value)
  );

  const nonMainCTAConfigs = cardRequestDetails?.allowedCtas?.length
    ? cardRequestDetails?.allowedCtas
        ?.map((cta) => CTA_CONFIG_CARD_REQUEST?.[cta]?.[cta])
        ?.filter((ctaConfig) => ctaConfig && !ctaConfig?.isPrimary)
    : [];
  const appliedFilters = convertFilters(useSelector(appliedFilterSelector));

  const displayMoreOptionsButton = nonMainCTAConfigs?.length > 0;

  const componentProvidedStuff = {
    dispatch,
    searchParams,
    setSearchParams,
    currentUser,
    appliedFilters,
  };

  return haveAppoveAndRejectBtn ? (
    <div className="flex flex-row justify-end gap-6 px-3 py-5 slider-footer">
      <Button
        preIcon="ModeComment"
        variant="tertiary"
        classes="w-fit text-neutral-500 px-5 py-3"
        onClick={scrollToComments}
      />

      {displayMoreOptionsButton && (
        <MoreOptions
          ctaConfigs={nonMainCTAConfigs}
          context={cardRequestDetails}
          componentProvidedStuff={componentProvidedStuff}
          data={cardRequestDetails}
        />
      )}
      <Button
        classes="w-16"
        label="reimbursement.approvals.sliderHeadings.footer.reject"
        showLoader={actionInProgress}
        onClick={handleReject}
        disabled={actionInProgress}
        type="danger"
        variant="tertiary"
      />
      <Button
        classes="w-15.5 h-10"
        type="success"
        onClick={handleApprove}
        disabled={actionInProgress}
        showLoader={actionInProgress}
        label="misc.approve"
        form="approve-limit-request"
      />
    </div>
  ) : null;
}

RequestSliderFooter.propTypes = {
  handleReject: PropTypes.func,
  handleApprove: PropTypes.func,
  scrollToComments: PropTypes.func,
};

export default RequestSliderFooter;
