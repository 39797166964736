import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import { BUTTON_KEYS } from "@/utils/constants/payments";

import { ROUTES } from "@/constants/routes";

const BillingBannerDetails = ({ bannerDetails }) => {
  const bannerColor = bannerDetails?.type ?? "warning";
  const navigate = useNavigate();

  return (
    <div
      className={`px-8 py-6 mb-3 rounded-b-lg -mt-9  bg-${bannerColor}-500 `}
    >
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-col gap-2">
          <Text
            classes="text-white text-base font-bold"
            translationKey={bannerDetails.title}
          />
          <Text
            classes="text-white text-sm font-normal"
            translationKey={bannerDetails.description}
          />
        </div>
        <Button
          label={`billPay.bill.ctas.${bannerDetails?.ctas?.[0]}`}
          classes={`bg-${bannerColor}-100 border border-warning-600 w-15`}
          variant={bannerColor}
          labelStyleClasses={`text-${bannerColor}-600 font-bold text-xs`}
          onClick={() =>
            bannerDetails?.ctas?.[0] === BUTTON_KEYS.addFunds
              ? navigate(ROUTES.dashboard.base.absolutePath)
              : null
          }
        />
      </div>
    </div>
  );
};
BillingBannerDetails.propTypes = {
  bannerDetails: PropTypes.shape({
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};

export default BillingBannerDetails;
