import PropTypes from "prop-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { availableModulesSelector } from "@/store/selectors/user";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import BalanceCard from "@/components/Company/Overview/MyAccounts/shared/BalanceCard";

import { amountToCurrency } from "@/utils/common";
import { MODULES } from "@/utils/constants/app";
import { ACCOUNT_CTAS, ACCOUNT_CTAS_CONFIG } from "@/constants/company";

export default function WalletAccountWidget({ account, globalAccounts }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const availableModules = useSelector(availableModulesSelector);
  const lineItems = useMemo(() => {
    return [
      {
        icon: "Send",
        label: "dashboard.myAccounts.processingPayments",
        modules: [MODULES.BILL_PAY, MODULES.REIMBURSEMENTS],
        infoText: "dashboard.myAccounts.infoText.payments",
        value: amountToCurrency(
          account?.assignedToPayments?.value,
          account?.assignedToPayments?.currency
        ),
      },
      {
        icon: "CreditCard",
        label: "dashboard.myAccounts.unsettledExpenses",
        modules: [MODULES.CARDS],
        infoText: "dashboard.myAccounts.infoText.expense",
        value: account?.unsettledAmount
          ? amountToCurrency(
              account?.unsettledAmount?.value,
              account?.unsettledAmount?.currency
            )
          : null,
        valueStyleClass: "font-semibold text-sm text-danger-600",
      },
    ]?.filter((item) => {
      return availableModules?.some((module) =>
        item?.modules?.includes(module)
      );
    });
  }, [
    JSON.stringify(availableModules),
    JSON.stringify(account?.assignedToPayments),
    JSON.stringify(account?.unsettledAmount),
  ]);
  return (
    <div className="flex flex-col gap-6">
      <div
        className={globalAccounts ? "" : "flex items-center justify-between"}
        id="upper"
      >
        <BalanceCard
          flagCode={account?.currency}
          label={
            <span>
              {account?.currency} <Text translationKey="common.balance" />
            </span>
          }
          amount={account?.availableBalance}
          currency={account?.currency}
        />
        <div
          id="right"
          className={
            globalAccounts ? "flex justify-center mt-6" : "flex items-start"
          }
        >
          {Object?.values(ACCOUNT_CTAS)?.map((item) =>
            account?.ctas?.includes(item) ? (
              <div
                className={`flex flex-col items-center hover:cursor-pointer ${
                  globalAccounts ? "w-full" : "w-13.5"
                }`}
                onClick={() =>
                  ACCOUNT_CTAS_CONFIG[item]?.onClick({
                    navigate,
                    setSearchParams,
                    account,
                  })
                }
                key={`${ACCOUNT_CTAS_CONFIG[item]?.icon}-${ACCOUNT_CTAS_CONFIG[item]?.text}`}
              >
                <Icon
                  name={ACCOUNT_CTAS_CONFIG[item]?.icon}
                  variant="rounded"
                  bgClassName={
                    ACCOUNT_CTAS_CONFIG[item]?.disabled
                      ? "text-neutral-300 bg-neutral-50  "
                      : "text-primary-500 bg-primary-50"
                  }
                  className={
                    ACCOUNT_CTAS_CONFIG[item]?.disabled
                      ? "text-netural-400 bg-neutral-50 w-5 h-5"
                      : "bg-primary-50 text-primary-400 w-5 h-5"
                  }
                />
                <Text
                  translationKey={ACCOUNT_CTAS_CONFIG[item]?.text}
                  classes={`text-xs font-semibold mt-2  ${
                    ACCOUNT_CTAS_CONFIG[item]?.disabled
                      ? "text-neutral-300"
                      : "text-neutral-500"
                  } text-center`}
                />
              </div>
            ) : null
          )}
        </div>
      </div>
      {lineItems?.length ? (
        <div
          id="lower"
          className="flex flex-col gap-2 p-4 rounded-md bg-neutral-50"
        >
          {lineItems.map(
            (
              {
                icon,
                label,
                value,
                valueStyleClass = "font-semibold text-sm text-neutral-800",
                infoText,
              },
              index
            ) =>
              value && (
                <div className="flex items-center justify-between" key={label}>
                  <div className="flex items-center gap-2">
                    <Icon name={icon} className="w-3 text-neutral-500" />
                    <Text
                      translationKey={label}
                      classes="text-neutral-500 text-sm font-medium"
                    />
                    <div id={`line-items-${lineItems.length}-${index}`}>
                      <Icon
                        className="cursor-pointer text-neutral-300"
                        name="Info"
                      />
                    </div>
                    <Tooltip
                      maxWidth="500px"
                      id={`line-items-${lineItems.length}-${index}`}
                    >
                      <Text translationKey={infoText} />
                    </Tooltip>
                  </div>
                  <span className={valueStyleClass}>{value}</span>
                </div>
              )
          )}
        </div>
      ) : null}
    </div>
  );
}

WalletAccountWidget.propTypes = {
  account: PropTypes.object,
  globalAccounts: PropTypes.bool,
};
