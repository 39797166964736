import PropTypes from "prop-types";

import Filters from "@/components/core/Filters";

export default function MasterHistorySearchAndFilters({ filters = [] }) {
  return (
    <div className="flex items-baseline justify-between">
      <div className="flex flex-col items-start w-full gap-4 text-center">
        <Filters filters={filters} classes="w-full" />
      </div>
    </div>
  );
}

MasterHistorySearchAndFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      filterId: PropTypes.string.isRequired,
      filterContent: PropTypes.node,
      iconName: PropTypes.string,
      tooltipText: PropTypes.string,
    })
  ),
};
