import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

/**
 * VerticalStepper component displays a vertical stepper list with titles and descriptions.
 *
 * @component
 * @example
 * const stepperList = [
 *   {
 *     title: "Step 1",
 *     description: "This is the first step",
 *     descriptionList: ["Detail 1", "Detail 2"]
 *   },
 *   {
 *     title: "Step 2",
 *     description: "This is the second step"
 *   }
 * ];
 * return (
 *   <VerticalStepper stepperList={stepperList} />
 * );
 *
 * @param {Object} props - The component props
 * @param {Array} props.stepperList - The list of steps, each step containing title, description, and descriptionList
 * @param {String} props.sectionHeaderTitle - header title
 * @param {String} props.sectionFooterText - footer text
 * @param {Boolean} props.show - boolean to show or hide the complete section
 * @param {String} props.sectionClasses - wrapper section classes
 * @param {string} props.stepperList[].title - The title of the step
 * @param {Object} [props.stepperList[].titleTranslationProps] - Translation props for the title
 * @param {string} [props.stepperList[].description] - The description of the step
 * @param {Object} [props.stepperList[].descriptionTranslationProps] - Translation props for the description
 * @param {String} [props.stepperList[].descriptionListHeader] - heading for the description list
 * @param {Array<string>} [props.stepperList[].descriptionList] - Additional description details for the step
 * @returns {JSX.Element} The rendered VerticalStepper component
 */
function VerticalStepper({
  sectionHeaderTitle,
  sectionFooterText,
  stepperList,
  show = true,
  sectionClasses,
}) {
  return show ? (
    <div
      className={`${sectionClasses} border card-wrapper border-primary-300 bg-primary-50`}
    >
      {sectionHeaderTitle ? (
        <Text
          translationKey={sectionHeaderTitle}
          classes="text-sm font-semibold"
        />
      ) : null}

      <div className="flex flex-col mt-5">
        {stepperList?.map((stepper, index) => {
          const {
            description = "",
            descriptionTranslationProps = "",
            titleTranslationProps = "",
            title = "",
            descriptionList = "",
            descriptionListHeader = "",
          } = stepper;

          return (
            <div key={index} className="relative">
              <div
                className={`flex flex-col pl-4 ${
                  stepperList.length - 1 !== index
                    ? "border-2 border-t-0 border-b-0 border-r-0 border-primary-300 pb-5"
                    : ""
                }`}
              >
                <div className="absolute border-primary-300 bg-primary-100 w-2.75 h-2.75 rounded-md -left-1 border-0.5 z-[1]">
                  {" "}
                </div>

                <div className="absolute "> </div>

                <Text
                  translationKey={title}
                  translationProps={titleTranslationProps}
                  classes="text-xs font-medium text-neutral-800 relative -top-1"
                />

                {stepper?.description ? (
                  <Text
                    translationKey={description}
                    translationProps={descriptionTranslationProps}
                    classes="text-xs font-medium text-neutral-500"
                  />
                ) : null}

                {stepper?.descriptionList?.length ? (
                  <div className="flex flex-col gap-1 ml-1">
                    {descriptionListHeader ? (
                      <div className="flex flex-row items-center gap-1">
                        <div className="profile-widget-dot bg-neutral-500" />

                        <Text
                          translationKey={descriptionListHeader}
                          classes="text-xs font-medium text-neutral-800"
                        />
                      </div>
                    ) : null}

                    <div className={descriptionListHeader ? "ml-6" : ""}>
                      {descriptionList?.map((_description, ind) => {
                        return (
                          <div
                            key={ind}
                            className="flex flex-row items-start gap-2"
                          >
                            <div className="mt-2 profile-widget-dot bg-neutral-500" />

                            <Text
                              translationKey={_description}
                              classes="text-xs font-medium text-neutral-500"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>

      {sectionFooterText ? (
        <Text
          translationKey={sectionFooterText}
          classes="text-xs font-semibold text-warning-600"
        />
      ) : null}
    </div>
  ) : null;
}

VerticalStepper.propTypes = {
  stepperList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      titleTranslationProps: PropTypes.object,
      description: PropTypes.string,
      descriptionTranslationProps: PropTypes.object,
      descriptionList: PropTypes.arrayOf(PropTypes.string),
      descriptionListHeader: PropTypes.string,
    })
  ),
  show: PropTypes.bool,
  sectionHeaderTitle: PropTypes.string,
  sectionFooterText: PropTypes.string,
  sectionClasses: PropTypes.string,
};

export default VerticalStepper;
