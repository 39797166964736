import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";
import Alerts from "@/components/Settings/Alerts";
import Developers from "@/components/Settings/Developers";
import SpendControls from "@/components/Settings/SpendControls";

import BillingPage from "@/pages/billing";
import ManagePage from "@/pages/manage";
import MasterHistoryPage from "@/pages/manage/master-history";
import GlobalSettings from "@/pages/manage/settings";
import TravelPage from "@/pages/travel";
import { ROUTES } from "@/constants/routes";
import CompanyRoutes from "@/routes/company";
import PeopleRoutes from "@/routes/people";

export default function ManageRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={ManagePage} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={`${ROUTES.manage.company.pathName}/*`}
          element={<CompanyRoutes />}
        />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={`${ROUTES.manage.people.pathName}/*`}
          element={<PeopleRoutes />}
        />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={ROUTES.manage.travel.pathName} element={<TravelPage />} />
      </Route>

      <Route element={<ProtectedRoute allowChild={false} />}>
        <Route
          path={ROUTES.manage.billing.pathName}
          element={<BillingPage />}
        />
      </Route>

      <Route element={<ProtectedRoute allowChild={false} />}>
        <Route
          path={ROUTES.manage.masterHistory.pathName}
          element={<MasterHistoryPage />}
        />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.manage.settings.pathName}
          element={<GlobalSettings />}
        >
          <Route
            path={ROUTES.manage.settings.spendControls.pathName}
            element={<SpendControls />}
          />
          <Route
            path={ROUTES.manage.settings.alerts.pathName}
            element={<Alerts />}
          />
          <Route
            path={ROUTES.manage.settings.developers.pathName}
            element={<Developers />}
          />
        </Route>
      </Route>
    </Routes>
  );
}
