import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { MAPPING_TYPE } from "@/utils/constants/rules";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import { RULE_TYPES, VP_FIELD_MAPPING } from "@/constants/rules";

export default function RuleCard({ rule, type = "" }) {
  const ruleType = rule?.ruleType;
  const count = rule?.ruleItemGroups?.length;
  const from = rule?.mappedTo?.volopayFields
    .map((field) => VP_FIELD_MAPPING[field])
    .join(", ");
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const to = rule?.mappedTo?.accountingValues
    .map((acValue) =>
      acValue === "AccountingPayee"
        ? `${ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingSoftware]} vendor`
        : acValue
    )
    .join(", ");

  const [searchParams, setSearchParams] = useSearchParams();

  const openEditRule = () => {
    if (ruleType === RULE_TYPES.NORMAL) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.accounting.rules.newEditRule,
        MAPPING_TYPE.EDIT_RULE
      );
    } else {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.accounting.rules.newEditAdvancedRule,
        MAPPING_TYPE.EDIT_RULE
      );
    }
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.ruleId, rule.id);
    searchParams.append("volopayField", "");
    searchParams.append("accountingField", "");
    setSearchParams(searchParams);
  };

  return (
    <div
      className="flex items-center justify-between w-full p-6 border rounded-lg cursor-pointer border-neutral-200 h-fit min-h-10/12"
      onClick={openEditRule}
    >
      <div className="flex items-center gap-4">
        <div
          className={`${
            count > 0 ? "bg-primary-50" : "bg-neutral-50 text-neutral-400"
          } flex items-center justify-center rounded-full p-2 `}
        >
          <Icon
            className={`rounded-full ${
              type === RULE_TYPES.ADVANCED
                ? "text-primary-500"
                : "bg-primary-500 border-primary-500 text-primary-50 border"
            }`}
            name={
              type === RULE_TYPES.ADVANCED
                ? "LightningBoltCustom"
                : "LightiningBolt"
            }
          />
        </div>
        <div className="flex flex-col">
          <div
            className={`flex items-center gap-2 ${
              type === RULE_TYPES.ADVANCED ? "flex-col !items-start" : ""
            }`}
          >
            <span className="text-lg font-semibold">{from}</span>
            <div
              className={`${
                type === RULE_TYPES.ADVANCED
                  ? "w-full flex items-center justify-start gap-1"
                  : ""
              }`}
            >
              <Icon name="MapArrow" className="w-6 h-6 text-neutral-500" />
              {type === RULE_TYPES.ADVANCED ? (
                <Text
                  translationKey="accounting.rules.editRuleMapping.mappedTo"
                  classes="text-sm font-medium text-neutral-500"
                />
              ) : null}
            </div>

            <div
              className={`${
                type === RULE_TYPES.ADVANCED
                  ? "w-full flex items-center justify-start gap-1"
                  : ""
              }`}
            >
              <Text translationKey={to} classes="text-lg font-semibold" />
            </div>
          </div>
          {type !== RULE_TYPES.ADVANCED ? (
            <Text
              translationKey="accounting.rules.editRuleMapping.mappingCount"
              translationProps={{ count }}
              classes={`${
                count > 0 ? "text-success-600" : "text-neutral-500"
              } text-sm`}
            />
          ) : null}
        </div>
      </div>
      <div>
        <Icon name="RightArrow" className="w-5 h-5 text-neutral-500" />
      </div>
    </div>
  );
}

RuleCard.propTypes = {
  rule: PropTypes.object,
  type: PropTypes.string,
};
