// react imports
import PropTypes from "prop-types";
import React from "react";

// core components
import Text from "@/components/core/Text";

// page relevant components
import RadioWithLabelField from "@/components/Cards/Sliders/OrderPhysicalCardSlider/RadioWithLabelField";

export default function BulkPhysicalCard({
  classes,
  radioOptions,
  name,
  handleChange,
  error,
  value,
}) {
  return (
    <div className={classes}>
      <Text
        translationKey="cards.pCards.sliders.createCardSlider.fields.bulkPhysicalCardOrderType.title"
        classes="text-xl not-italic font-bold leading-9 tracking-[-0.24px]"
      />
      <div className="flex flex-row gap-4 mt-7">
        {radioOptions?.map((radioOption, index) => (
          <RadioWithLabelField
            key={index}
            radioLabel={radioOption.heading}
            radioDescription={radioOption.description}
            radioValue={radioOption.value}
            name={name}
            handleChange={handleChange}
            error={error}
            sectionValue={value}
          />
        ))}
      </div>
    </div>
  );
}

BulkPhysicalCard.propTypes = {
  classes: PropTypes.string,
  radioOptions: PropTypes.array,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  error: PropTypes.string,
  value: PropTypes.string,
};
