import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { availableModulesSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import FieldTypeCard from "@/components/Accounting/Settings/CustomFields/FieldTypeCard";

import { MODULE_NAMES } from "@/constants/settings";

export default function CreateEditFieldForm({
  form,
  setFormData,
  payrollEnabled = false,
}) {
  const { t } = useTranslation();
  const availableModule = useSelector(availableModulesSelector);
  const optionsAvailableModule = useMemo(
    () =>
      MODULE_NAMES.filter((option) => {
        return availableModule.includes(option.module);
      }).map((module) => ({
        name: module.name,
        id: module.id,
        value: module.value,
        checked: form?.modules?.includes(module?.value),
      })),
    []
  );
  const [modules, setModules] = useState([]);

  const selectedList = form?.field_type === "list";
  const selectedText = form?.field_type === "text";

  const handleFieldNameChange = (e) =>
    setFormData({ ...form, value: e.target.value });

  const handleChange = (name, index) => {
    const updatedValuesAttributes = [...form.values_attributes];
    updatedValuesAttributes[index] = {
      ...updatedValuesAttributes[index],
      value: name,
    };

    setFormData((prevForm) => ({
      ...prevForm,
      values_attributes: updatedValuesAttributes,
    }));
  };

  const addField = () => {
    const newField = { tempId: new Date().getTime().toString(), value: "" };

    setFormData((prevForm) => ({
      ...prevForm,
      values_attributes: [...prevForm.values_attributes, newField],
    }));
  };
  const handleDelete = (option) => {
    if (option?.id)
      setFormData((prev) => ({
        ...prev,
        values_attributes: prev.values_attributes.map((_, i) =>
          _?.tempId === option?.tempId ? { ..._, _destroy: true } : _
        ),
      }));
    else
      setFormData((prev) => ({
        ...prev,
        values_attributes: prev.values_attributes.filter(
          (_, i) => _?.tempId !== option?.tempId
        ),
      }));
  };
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      modules: modules
        ?.filter((module) => module.checked)
        ?.map((module) => module.value),
    }));
  }, [modules]);
  useEffect(() => {
    setModules(optionsAvailableModule);
  }, [JSON.stringify(optionsAvailableModule)]);
  return (
    <div className="flex flex-col gap-8 mt-10">
      <Input
        label={<Text translationKey="accounting.settings.customFields.label" />}
        placeholder={t("accounting.settings.customFields.placeholder")}
        value={form.value}
        onChange={handleFieldNameChange}
      />
      <div className="flex flex-col">
        <span className="mb-4">
          <Text translationKey="accounting.settings.customFields.fieldType" />
        </span>
        <div className="flex items-center justify-between gap-4">
          <FieldTypeCard
            name={
              <Text translationKey="accounting.settings.customFields.optionList" />
            }
            selected={selectedList}
            desc={
              <Text translationKey="accounting.settings.customFields.optionListDescription" />
            }
            handleValueChange={() =>
              setFormData({ ...form, field_type: "list" })
            }
          />
          <FieldTypeCard
            name={
              <Text translationKey="accounting.settings.customFields.textField" />
            }
            selected={selectedText}
            desc={
              <Text translationKey="accounting.settings.customFields.textFieldDescription" />
            }
            handleValueChange={() =>
              setFormData({
                ...form,
                field_type: "text",
                values_attributes: [],
              })
            }
          />
        </div>
      </div>
      {selectedList && (
        <div className="flex flex-col">
          <span className="font-medium">
            <Text translationKey="accounting.settings.customFields.addOptionField" />
          </span>
          <div className="flex flex-col gap-3 p-4 py-6 mt-4 border rounded-lg border-neutral-300">
            {form.values_attributes
              ?.filter((item) => !item?._destroy)
              .map((option, index, array) => (
                <div
                  key={`custom-field-options-${index}`}
                  className="flex flex-row items-center gap-4"
                >
                  <div className="flex-grow mt-2">
                    <Input
                      label={
                        <Text
                          translationKey="accounting.settings.customFields.fieldOptionsInputLabel"
                          translationProps={{ number: index + 1 }}
                        />
                      }
                      value={option.value}
                      onChange={(e) => handleChange(e.target.value, index)}
                    />
                  </div>
                  {array.length !== 1 ? (
                    <Icon
                      name="Delete"
                      className="cursor-pointer text-neutral-500"
                      handleClick={() => {
                        handleDelete(option);
                      }}
                    />
                  ) : null}
                </div>
              ))}

            <Button
              classes="w-[122px] h-[32px] text-primary-500 text-xs font-medium mt-2"
              variant="tertiary"
              label={
                <Text translationKey="accounting.settings.customFields.addField" />
              }
              preIcon="Add"
              iconClasses="w-4 h-4"
              onClick={addField}
            />
          </div>
        </div>
      )}

      <div className="flex flex-col p-4 rounded-lg bg-neutral-50">
        <span className="mb-2 text-sm font-medium text-neutral-500">
          <Text translationKey="accounting.settings.customFields.helper" />
        </span>
        {modules?.map((module, index) => {
          return (
            <div
              className="flex items-center justify-between mb-2 font-medium"
              key={index}
            >
              <span>
                <Text translationKey={module.name} />
              </span>
              <Switch
                value={module.checked}
                handleChange={() =>
                  setModules((prev) =>
                    prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, checked: !item.checked };
                      }
                      return item;
                    })
                  )
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

CreateEditFieldForm.propTypes = {
  form: PropTypes.object,
  setFormData: PropTypes.func,
};
