import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { downloadPaymentReceipt } from "@/store/reducers/payments";

import { currentPaymentProviderSelector } from "@/store/selectors/client";
import { isDownloadingPaymentReceiptSelector } from "@/store/selectors/payments";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import VpLink from "@/components/core/vp-link";

import SingleColumnDataRows from "@/components/Accounting/Transactions/common/Slider/SingleColumnDataRows";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";

import { DEPARTMENT } from "@/constants/company";
import { PAYMENT_PROVIDERS } from "@/constants/provider";

/**
 *
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=4008-225166&mode=dev}
 * payroll {@link https://www.figma.com/file/nYb1B3o06dNJPuFhW7fLWP/v2-Payroll?type=design&node-id=857-195531&mode=design&t=zvZOgDFzWghB6ZDW-4}
 */
export default function TransactionDetails({
  payment,
  context,
  isReimbursement = false,
}) {
  const dispatch = useDispatch();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const isDownloadingPaymentReceipt = useSelector(
    isDownloadingPaymentReceiptSelector
  );
  const currentPaymentProvider = useSelector(currentPaymentProviderSelector);

  const downloadHandler = () => {
    dispatch(
      downloadPaymentReceipt({
        id: isReimbursement ? payment.transactionDetails.paymentId : payment.id,
        payroll: inPayrollContext,
      })
    );
  };

  // Adding isReimbursement flag since it is been reused by billpay, payroll and reimbursement with minor changes
  const inPaidSlider =
    payment?.transactionStatus === PAYMENT_STATUSES.paid ||
    (isReimbursement && payment?.status === PAYMENT_STATUSES.paid);

  const isPayOutsideVolopay =
    payment?.payOutsideVolopay ||
    payment?.status === PAYMENT_STATUSES.outsideVolopay;
  const isShowReceiptDownload =
    (inPaidSlider ||
      payment?.transactionStatus === PAYMENT_STATUSES.processing) &&
    !isPayOutsideVolopay;

  return (
    <div className="flex flex-col gap-4">
      <Text
        classes="text-neutral-800 font-semibold text-lg"
        translationKey="accounting.transactions.cards.slider.transactionDetails"
      />
      <SingleColumnDataRows
        hideBlanks={false}
        dataRows={[
          inPaidSlider && !isPayOutsideVolopay
            ? {
                label: "billPay.bill.paid.transactionDetails.transactionId",
                value: payment?.transactionDetails?.transactionId,
              }
            : null,
          inPaidSlider &&
          currentPaymentProvider === PAYMENT_PROVIDERS.DECENTRO &&
          !isPayOutsideVolopay
            ? {
                label: "billPay.bill.paid.transactionDetails.bankUTR",
                value: payment?.transactionDetails?.referenceNumber,
              }
            : null,
          inPaidSlider
            ? {
                label: "billPay.bill.paid.transactionDetails.paymentMethod",
                value: isPayOutsideVolopay
                  ? "myVolopay.actionCentre.sliders.requestHistorySlider.pills.outsideVolopay"
                  : payment?.transactionDetails?.paymentMethod?.name ||
                    payment?.paymentMethod?.name ||
                    payment.quote?.paymentMethod ||
                    payment?.transactionDetails?.paymentMethod,
              }
            : null,
          isPayOutsideVolopay
            ? null
            : {
                label: "billPay.bill.paid.transactionDetails.paymentReference",
                value: payment?.paymentReference,
              },
          inPayrollContext
            ? null
            : {
                label: [
                  payment?.transactionDetails?.departmentType,
                  payment?.linkedTo?.type,
                ]
                  .map((i) => (i || "").toLowerCase())
                  .includes(DEPARTMENT.toLowerCase())
                  ? "billPay.bill.paid.transactionDetails.department"
                  : "billPay.bill.paid.transactionDetails.project",
                value:
                  payment?.transactionDetails?.department ||
                  payment?.linkedTo?.name,
              },
        ]}
      />
      {isShowReceiptDownload ? (
        <div className="flex items-center justify-end gap-1 mt-4">
          <VpLink
            href=""
            textClasses="text-sm text-primary-500 font-semibold"
            onClickHandler={downloadHandler}
            disabled={isDownloadingPaymentReceipt}
            text="billPay.bill.paid.transactionDetails.downloadProofOfPayment"
          />
          {isDownloadingPaymentReceipt ? (
            // TECH_DEBT animate this
            <Icon name="SpinLoader" className="w-4 h-4" />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

TransactionDetails.propTypes = {
  payment: PropTypes.object,
  context: PropTypes.string,
  isReimbursement: PropTypes.bool,
};
