import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  bulkUploadRefresh,
  fetchReimbursements,
  setBulkUploadCount,
  setBulkUploadRecordIds,
  setBulkUploadResponseData,
  setReimbursementInitialState,
  updateReimbursement,
} from "@/store/reducers/reimbursement";
import { fetchAndSelectVendor } from "@/store/reducers/vendors";

import {
  reimbursementReportsEnabledSelector,
  subdomainSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  approvalsFiltersSelector,
  bulkUploadRecordIdsSelector,
  bulkUploadRefreshDataSelector,
  historyPaymentStatusFiltersSelector,
  isReimbursementsFetchingSelector,
  reimbursementBulkUploadResponseSelector,
  reimbursementHasMoreSelector,
  reimbursementTotalAmountSelector,
  reimbursementTotalSelector,
  reimbursementsListSelector,
} from "@/store/selectors/reimbursement";
import { userIdSelector, userSelector } from "@/store/selectors/user";
import { selectedVendorBankDetailsPresentSelector } from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import CopytoClipboard from "@/components/core/CopyToClipboard";
import Filters from "@/components/core/Filters";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import VpLink from "@/components/core/vp-link";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import Export from "@/components/Exports";
import PaymentsTableLoader from "@/components/Reimbursement/PaymentsTableLoader/index";
import {
  REIMBURSEMENT_CLAIM_TABLE_HEADER,
  REPORT_ENABLED_CLAIM_TABLE_HEADER,
} from "@/components/Reimbursement/ReimbursementClaimList/tableConfig";
import "@/components/Reimbursement/style.scss";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";

import BulkUploadButton from "@/pages/my-volopay/reimbursements/claims/common/BulkUploadButton";
import { convertFilters } from "@/utils/filters";
import { handleExport } from "@/utils/exports";
import {
  SORTING_CATEGORY_REIMBURSEMENT,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";
import {
  REIMBURSEMENT_KEY,
  REIMBURSEMENT_STATUS,
} from "@/utils/constants/reimbursement";
import { TAB_TYPES } from "@/utils/constants/myVolopay";
import { debounce } from "@/utils/common";
import { REIMBURSEMENT_CONTEXT } from "@/constants/reimbursement";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { EXPORT_PAGE, EXPORT_PAGE_TYPE } from "@/constants/exports";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

const status = {
  active: [
    REIMBURSEMENT_STATUS.pending_approval,
    REIMBURSEMENT_STATUS.approved,
    REIMBURSEMENT_STATUS.processing,
  ],
  drafts: REIMBURSEMENT_STATUS.in_draft,
  history: [
    REIMBURSEMENT_STATUS.paid,
    REIMBURSEMENT_STATUS.denied,
    REIMBURSEMENT_STATUS.failed,
    REIMBURSEMENT_STATUS.paid_outside_volopay,
  ],
};
const filterConfig = {
  active: approvalsFiltersSelector,
  drafts: approvalsFiltersSelector,
  history: historyPaymentStatusFiltersSelector,
};
function ReimbursementClaimList({ tabType }) {
  const dispatch = useDispatch();

  const filters = useSelector(filterConfig[tabType]);
  const reimbursements = useSelector(reimbursementsListSelector);
  const isFetching = useSelector(isReimbursementsFetchingSelector);
  const totalRows = useSelector(reimbursementTotalSelector);
  const totalAmount = useSelector(reimbursementTotalAmountSelector);
  const hasMore = useSelector(reimbursementHasMoreSelector);
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);
  const isEmpty = !reimbursements?.length;
  const bulkUploadResponseData = useSelector(
    reimbursementBulkUploadResponseSelector
  );

  const bulkUploadRefreshData = useSelector(bulkUploadRefreshDataSelector);
  const bankDetailsPresent = useSelector(
    selectedVendorBankDetailsPresentSelector
  );

  const subdomain = useSelector(subdomainSelector);

  const { t } = useTranslation();

  const { vendorId } = useSelector(userSelector);
  const userId = useSelector(userIdSelector);

  useEffect(() => {
    if (vendorId && !bankDetailsPresent)
      dispatch(fetchAndSelectVendor({ id: vendorId }));
  }, [vendorId, bankDetailsPresent]);

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
    emptyDataTitle,
    emptySubHeadingDataTitle,
    emptyDataTitleHavingAccount,
    emptySubHeadingDataTitleHavingAccount,
  } =
    (reportEnabled
      ? REPORT_ENABLED_CLAIM_TABLE_HEADER?.[tabType]
      : REIMBURSEMENT_CLAIM_TABLE_HEADER?.[tabType]) ?? {};

  const [files, setFiles] = useState([]);
  const [sorting, setSorting] = useState({
    type: null,
    category: null,
    defaultCategory:
      tabType !== TAB_TYPES.HISTORY
        ? null
        : SORTING_CATEGORY_REIMBURSEMENT.UPDATED_AT,
    defaultType: tabType !== TAB_TYPES.HISTORY ? null : SORTING_TYPE.DEC,
  });

  const currentUser = useSelector(userSelector);
  const [searchtext, setSearchText] = useState("");
  const [isExportReady, setIsExportReady] = useState(false);
  const appliedFilters = useSelector(appliedFilterSelector);
  const [refreshing, setRefreshing] = useState(false);

  const [selectedReimbursements, setSelectedReimbursements] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);
  const [statusConfig, setStatusConfig] = useState(status[tabType]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpened, setIsModalOpened] = useState(false);

  const updateApi = debounce((text) => {
    setSearchText(text);
  }, 500);

  const filterOptions = {
    status: statusConfig,
    user: currentUser?.id,
    total_amount_required: true,
    q: searchtext,
  };

  const onReset = () => {
    dispatch(setReimbursementInitialState());
  };

  const loadMore = () => {
    if (pageNum > 0)
      dispatch(
        fetchReimbursements({
          page: pageNum,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          ...filterOptions,
          [SORT_KEYS.COLUMN]: sorting?.category || sorting?.defaultCategory,
          [SORT_KEYS.DIRECTION]: sorting?.type || sorting?.defaultType,
          ...convertFilters(appliedFilters),
        })
      );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...convertFilters(appliedFilters),
      ...sorting,
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  // multi-select

  const headerSelected = headerSelectAll; // all selected or else unchecked

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedReimbursements(
          reimbursements.map((reimbursement) => reimbursement.id)
        );
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedReimbursements([]);
    }
  };

  const rowSelectionHandler = (reimbursement) => {
    if (selectedReimbursements?.includes(reimbursement?.id)) {
      setSelectedReimbursements((prev) =>
        prev.filter((id) => id !== reimbursement?.id)
      );
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, reimbursement?.id]);
      }
    } else {
      setSelectedReimbursements((prev) => [...prev, reimbursement?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== reimbursement?.id));
    }

    dispatch(
      updateReimbursement({
        id: reimbursement.id,
        value: {
          ...reimbursement,
          selected: !reimbursement.selected,
        },
      })
    );
  };

  const bulkUploadRecordIdsData = useSelector(bulkUploadRecordIdsSelector);

  const onSuccess = (resp) => {
    setTimeout(() => {
      setRefreshing(false);
    }, 4000);

    if (!resp?.length) {
      if (bulkUploadResponseData || bulkUploadRecordIdsData?.length) {
        onReset();
        loadMore();
      }

      dispatch(setBulkUploadResponseData(null));
      dispatch(setBulkUploadRecordIds([]));
      dispatch(setBulkUploadCount(0));
    } else {
      const ids = resp?.map((f) => f?.id);
      const count = resp?.reduce((a, c) => a + (c?.totalRecords ?? 0), 0);

      if (
        bulkUploadRecordIdsData?.length &&
        ids?.length &&
        ids?.length !== bulkUploadRecordIdsData?.length
      ) {
        onReset();
        loadMore();
      }

      dispatch(setBulkUploadRecordIds(ids));
      dispatch(setBulkUploadCount(count));
    }
  };

  useEffect(() => {
    if (tabType === TAB_TYPES.DRAFTS) refreshFunction();
  }, []);

  const refreshFunction = () => {
    setRefreshing(true);
    dispatch(
      bulkUploadRefresh({
        payload: {
          user: userId,
          type: REIMBURSEMENT_KEY,
          status: REIMBURSEMENT_STATUS.pending,
        },
        onSuccess,
      })
    );
  };

  const showModal = () => {
    setIsModalOpened(true);
  };

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);
      if (!deSelectedIds.length) {
        setSelectedReimbursements(reimbursements?.map((data) => data.id));
      } else {
        const filteredIds = reimbursements
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedReimbursements(filteredIds);
      }
    }
  }, [bulkSelectAll, reimbursements, deSelectedIds]);

  useEffect(() => {
    // When user selects checkbox from header
    setHeaderSelectAll(
      (selectedReimbursements.length ===
        pageNum * PAGINATION_PER_REQUEST_LIMIT ||
        selectedReimbursements.length === reimbursements?.length) &&
        !isEmpty
    );
  }, [selectedReimbursements, pageNum, reimbursements]);

  const getEmptyChildrenNode = (_bankDetailsPresent) =>
    _bankDetailsPresent ? (
      <BulkUploadButton
        showModal={showModal}
        isModalVisible={isModalOpened}
        handleModalClose={() => setIsModalOpened(false)}
      />
    ) : (
      <Button
        label="myVolopay.reimbursements.active.addBankAccountCta"
        variant="secondary"
        classes="w-fit px-8 mt-4"
        onClick={() => {
          searchParams.append(
            [SLIDERS_SEARCH_PARAMS.company.people.preAddBankDetails],
            true
          );
          setSearchParams(searchParams);
        }}
      />
    );

  const handleRowClick = (id) => {
    if (tabType === TAB_TYPES.ACTIVE) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.reimbursements.activeClaimId,
        id
      );
    } else if (tabType === TAB_TYPES.HISTORY) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.reimbursements.historyClaimId,
        id
      );
    } else {
      searchParams.append(SLIDERS_SEARCH_PARAMS.reimbursements.review, id);
    }

    searchParams.append(SLIDERS_SEARCH_PARAMS.reimbursements.pageType, tabType);
    setSearchParams(searchParams);
  };

  const getNewInvoiceUploadText = () => (
    <div className="flex items-center text-sm font-medium text-center text-neutral-500">
      <div className="flex items-center gap-1">
        <Text
          classes="text-neutral-800 font-semibold "
          translationKey="myVolopay.reimbursements.drafts.newClaim"
        />

        <VpLink text="upload" onClickHandler={showModal} />

        <Text
          classes="inline-block"
          translationKey="myVolopay.reimbursements.drafts.dropStatement"
        />

        <div className="flex items-center justify-center gap-1">
          <Text
            classes="text-neutral-800 font-semibold"
            translationKey="myVolopay.reimbursements.drafts.emailToText"
            translationProps={{ subdomain }}
          />
          <CopytoClipboard
            text={t("myVolopay.reimbursements.drafts.emailToText", {
              subdomain,
            })}
          />
        </div>
      </div>
    </div>
  );

  const data =
    (bulkUploadResponseData || bulkUploadRefreshData?.length) &&
    tabType === TAB_TYPES.DRAFTS
      ? [{ bulkProcessing: true }, ...reimbursements]
      : reimbursements;

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="w-full">
          <Filters filters={filters} />
        </div>

        <div className="flex justify-end">
          <Export
            totalExports={totalRows}
            storeName="reimbursement"
            sectionName="reimbursements"
            selectedFilter={appliedFilters}
            additionalFilters={{
              ...EXPORT_PAGE_TYPE.REIMBURSEMENT,
              ...filterOptions,
              tab: tabType,
            }}
          />
        </div>
      </div>
      {getNewInvoiceUploadText(true)}
      <div className="mt-6">
        <Table
          {...{
            ...tableSetting,
            emptyDataTitle: bankDetailsPresent
              ? emptyDataTitle
              : emptyDataTitleHavingAccount,
            emptyDataDescription: bankDetailsPresent
              ? emptySubHeadingDataTitle
              : emptySubHeadingDataTitleHavingAccount,
            emptyDataChildren: getEmptyChildrenNode(bankDetailsPresent),
            emptyDataDescriptionProps: { subdomain },
          }}
          bulkApproveVisible={selectedReimbursements?.length > 0}
          bulkApproveHeight="100px"
          bulkApproveContent={
            <BulkActionComponent
              selectedRows={
                deSelectedIds.length
                  ? totalRows - deSelectedIds.length
                  : selectedReimbursements?.length
              }
              totalRows={totalRows}
              descriptionText="common.exportDesc"
              bulkApproveSelectAll={
                (bulkSelectAll && !deSelectedIds.length) ||
                selectedReimbursements?.length === totalRows
              }
              showSelectAllButton={headerSelectAll}
              selectAllHandler={(val) => {
                setDeSelectedIds([]);
                setBulkSelectAll(val);
              }}
              handleExport={() => {
                handleExport(
                  false,
                  selectedReimbursements?.length,
                  appliedFilters,
                  dispatch,
                  {
                    ...(bulkSelectAll
                      ? {
                          status: statusConfig,
                          tab: tabType,
                          deselected_ids: deSelectedIds,
                        }
                      : { selected_ids: selectedReimbursements, tab: tabType }),
                    export_type: EXPORT_PAGE.CLAIM,
                  }
                );
              }}
              showExport
              clearSelection={() => {
                setBulkSelectAll(false);
                setHeaderSelectAll(false);
                setSelectedReimbursements([]);
                setDeSelectedIds([]);
                dispatch(
                  updateReimbursement(
                    reimbursements.map((reimbursement) => {
                      return {
                        ...reimbursement,
                        selected: !reimbursement.selected,
                      };
                    })
                  )
                );
              }}
            />
          }
        >
          <tr className={tableHeadingRowClasses}>
            {tableHeading.map((headVal, index) => (
              <th className={headVal.classes} key={headVal.id}>
                <HeaderCell
                  val={{
                    ...headVal,
                    checkedValue: headerSelected && !isEmpty,
                    handleRowClick,
                    onCheckboxClick: headerSelectionHandler,
                    showCheckbox: index === 0 && !isFetching,
                    disabled: isEmpty,
                    sorting,
                    setSorting,
                  }}
                />
              </th>
            ))}
          </tr>
          {!isFetching || !isEmpty
            ? data?.map((val, index) => {
                return (
                  <tr
                    key={val?.id}
                    className={`${tableDataRowClasses} ${
                      val?.bulkProcessing ? "bg-neutral-50 " : ""
                    } ${val?.selected ? "selected-row-cell" : ""} `}
                    ref={(ref) => {
                      if (index === reimbursements.length - 1 && hasMore) {
                        handleRefChange(ref);
                      }
                    }}
                  >
                    {tableHeading.map(
                      ({ cellComponent: Component, classes, id }) => (
                        <td
                          className={`${classes} ${
                            val?.bulkProcessing ? "processing-row-cell" : ""
                          }`}
                          key={id}
                        >
                          <Component
                            val={{
                              ...val,
                              showCheckbox: true,
                              context: REIMBURSEMENT_CONTEXT,
                              handleRowClick,
                              onCheckboxClick: () => rowSelectionHandler(val),
                              checkedValue: selectedReimbursements?.includes(
                                val?.id
                              ),
                              refreshFunction,
                              refreshing,
                            }}
                            key={id}
                          />
                        </td>
                      )
                    )}
                  </tr>
                );
              })
            : null}
          {isFetching && <PaymentsTableLoader />}
        </Table>
      </div>

      {/* when user clicks on Upload from description below filters section */}
      {isModalOpened ? (
        <div className="hidden">
          <BulkUploadButton
            showModal={showModal}
            isModalVisible={isModalOpened}
            handleModalClose={() => setIsModalOpened(false)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ReimbursementClaimList;

ReimbursementClaimList.propTypes = {
  tabType: PropTypes.string,
};
