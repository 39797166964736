import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function ExpensesTableLoaderHelper() {
  return (
    <tr className="text-center">
      <td className="flex items-center gap-3 ml-4">
        <LoaderSkeleton size={[20, 20]} />
        <LoaderSkeleton type="circle" />
        <LoaderSkeleton size={[20, 180]} />
      </td>
      <td>
        <LoaderSkeleton size="lg" />
      </td>

      <td>
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-start gap-1">
            <LoaderSkeleton size={[15, 150]} />
            <LoaderSkeleton size={[10, 50]} />
          </div>
          <LoaderSkeleton type="circle" />
        </div>
      </td>

      <td className="flex items-center gap-3 ml-4">
        <LoaderSkeleton type="circle" />
        <div className="flex flex-col items-start gap-1">
          <LoaderSkeleton size={[15, 100]} />
          <LoaderSkeleton size={[10, 50]} />
        </div>
      </td>
      <td>
        <LoaderSkeleton size={[15, 100]} />
      </td>
    </tr>
  );
}
