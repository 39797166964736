import Icon from "@/components/core/Icon";

export default function RecurringPayIcon() {
  return (
    <Icon
      name="Cached"
      className="text-warning-500 border border-warning-100 bg-warning-50 rounded-lg w-6 h-6 ml-auto shrink-0 p-0.5"
    />
  );
}
