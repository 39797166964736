import PropTypes from "prop-types";

import { getColor } from "./helper";

export default function CircleProgress({
  value,
  variant,
  className,
  textClasses,
  iconClasses,
  blankCircleClasses,
  progressCircleClasses,
  viewBox = "2 -2 28 36",
  noText,
}) {
  return (
    <div
      className={`${
        variant === "md" ? "relative" : "flex items-center"
      } ${className}`}
    >
      <svg
        className={`circle--${variant} -rotate-90 ${iconClasses}`}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={`circle-background ${blankCircleClasses}`}
          r="16"
          cx="16"
          cy="16"
        />
        <circle
          className={`circle-progress circle-progress--${getColor(
            value
          )} ${progressCircleClasses}`}
          style={{ strokeDashoffset: `${100 - value}` }}
          r="16"
          cx="16"
          cy="16"
        />
      </svg>
      {noText ? null : (
        <span
          className={` ${variant === "md" ? "center-text font-medium" : ""}
          } ${textClasses}`}
        >
          {new Intl.NumberFormat("en-US").format(value)}%
        </span>
      )}
    </div>
  );
}

CircleProgress.propTypes = {
  value: PropTypes.number,
  variant: PropTypes.string,
  className: PropTypes.string,
  textClasses: PropTypes.string,
  iconClasses: PropTypes.string,
  blankCircleClasses: PropTypes.string,
  progressCircleClasses: PropTypes.string,
  viewBox: PropTypes.string, // needed if thickness is changed
  noText: PropTypes.bool,
};
