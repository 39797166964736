import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// reducers
import {
  addAccountingIntegrationImportedFiles,
  importUCSVFiles,
} from "@/store/reducers/accounting";
import { fetchTags } from "@/store/reducers/tags";

// selectors
import {
  accountingIntegrationImportedFilesSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";
import {
  accountingCategoryTagsSelector,
  accountingNonCategoryTags,
  accountingTaxCodeTagsSelector,
} from "@/store/selectors/tags";

// core components
import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";
import Icon from "@/components/core/Icon";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";

import CSVFileUploadPreview from "@/components/Accounting/Integrations/common/CSVFileUploadPreview";
// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import {
  INTEGRATION_UCSV_CHART_OF_ACCOUNGTS_TEMPLATE_URL,
  INTEGRATION_UCSV_TAX_CODE_TEMPLATE_URL,
  INTEGRATION_UCSV_VENDORS_TEMPLATE_URL,
  UCSV_UPDATE_MODE_OPTIONS,
  UCSV_UPDATE_MODE_OPTIONS_CONFIG,
  UCSV_UPDATE_OPTIONS_CONFIG,
} from "@/utils/constants/integrations";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function UpdateFieldsSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [file, setFile] = useState([]);
  const importedFiles = useSelector(accountingIntegrationImportedFilesSelector);
  const categoryTag = useSelector(accountingCategoryTagsSelector);
  const taxCodeTag = useSelector(accountingTaxCodeTagsSelector);
  const nonCategoryTags = useSelector(accountingNonCategoryTags).filter(
    (tag) => tag.id !== taxCodeTag?.id
  );
  const isLoading = useSelector(isLoadingAccountingIntegrationsSelector);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedUpdateMode, setSelectedUpdateMode] = useState(null);
  const updateModeOptions = UCSV_UPDATE_MODE_OPTIONS_CONFIG;
  const enableUpdateButton = selectedField && file.length && selectedUpdateMode;

  const updateFieldOptions = [
    ...UCSV_UPDATE_OPTIONS_CONFIG,
    ...nonCategoryTags.map((tag) => ({
      label: tag.name,
      value: { template: "chart_of_account", fieldName: tag.name },
    })),
  ];

  const handleCancel = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.updateField
    );
    setSearchParams(searchParams);
  };

  const handleDownloadTemplate = () => {
    if (selectedField?.template === "chart_of_account") {
      window.open(INTEGRATION_UCSV_CHART_OF_ACCOUNGTS_TEMPLATE_URL, "_blank");
    } else if (selectedField?.template === "tax") {
      window.open(INTEGRATION_UCSV_TAX_CODE_TEMPLATE_URL, "_blank");
    } else if (selectedField?.template === "vendor") {
      window.open(INTEGRATION_UCSV_VENDORS_TEMPLATE_URL, "_blank");
    }
  };

  const handleUpdate = async () => {
    const action = await dispatch(
      importUCSVFiles({
        csv_file: file,
        template: selectedField?.template,
        override_values:
          selectedUpdateMode === UCSV_UPDATE_MODE_OPTIONS.OVERWRITE,
        ...(selectedField?.fieldName && {
          field_name: selectedField?.fieldName,
        }),
      })
    );

    if (importUCSVFiles.fulfilled.match(action)) onSuccessfulUpdate();
  };

  const onSuccessfulUpdate = () => {
    dispatch(
      addAccountingIntegrationImportedFiles({
        [selectedField]: file,
      })
    );
    dispatch(fetchTags());

    handleCancel();
  };

  return (
    <div className="slider-content-container flex flex-col gap-11.5">
      <div className="slider-core-container px-10 flex flex-col gap-8 ">
        <SliderHeader
          heading="accounting.integrations.universalCsv.updateField.title"
          headingClasses="text-3xl font-bold text-neutral-800"
          refFlag
        />
        <div className="flex flex-col gap-9">
          <div className="flex flex-col justify-center gap-3 p-4 bg-neutral-50 border border-neutral-300 rounded-xl">
            <SliderHeader
              heading="accounting.integrations.labels.pleaseNote"
              subText="accounting.integrations.updateSupport.description"
              headingClasses="text-base font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
          </div>
          <div className="flex flex-col justify-center gap-6">
            <VpSelect
              label="accounting.integrations.labels.accountingField"
              optionsDisplayKey="label"
              valueKey="value"
              value={selectedField}
              options={updateFieldOptions}
              handleChange={(option) => setSelectedField(option.value)}
              menuPosition="absolute"
            />
          </div>

          <div
            className={`flex flex-col justify-center gap-4 ${
              !selectedField ? "opacity-50" : ""
            }`}
          >
            <SliderHeader
              heading="accounting.integrations.universalCsv.downloadTemplate.title"
              subText="accounting.integrations.universalCsv.downloadTemplate.description"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <Button
              variant="tertiary"
              label="accounting.integrations.buttonLabel.downloadTemplate"
              preIcon="Download"
              classes="w-1/3 px-5 py-1 text-xs font-bold"
              disabled={!selectedField}
              onClick={() => {
                handleDownloadTemplate();
              }}
            />
          </div>

          <div
            className={` flex flex-col justify-center gap-4 ${
              !selectedField ? "opacity-40 pointer-events-none" : ""
            }`}
          >
            <SliderHeader
              heading="accounting.integrations.universalCsv.uploadTemplate.title"
              subText="accounting.integrations.universalCsv.uploadTemplate.description"
              subTextTranslationProps={{ text: "tag name and ID" }}
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />

            <FileUpload
              accept={{ "text/csv": [".csv"] }}
              name="file"
              files={file}
              handleFileChange={setFile}
              acceptText="common.csvFormatFileUploadAccept"
              primaryAction={{
                handler: () => setFile([]),
                label: "Delete",
                icon: "Delete",
                iconClasses: "text-danger-600 bg-danger-50",
              }}
              previewCustomComponent={(props) => (
                <CSVFileUploadPreview
                  file={props.file}
                  primaryAction={props.primaryAction}
                />
              )}
              maxFiles={1}
              hideCustomButtonAfterUpload
            />
          </div>

          <div className="flex flex-col gap-3">
            <Text translationKey="accounting.integrations.universalCsv.updateField.accountingField.updateType" />
            {updateModeOptions.map((item, index) => (
              <div
                className="flex items-center gap-3"
                key={`update-ucsv-field-option-${index}`}
              >
                <Radio
                  name="updateMode"
                  isChecked={selectedUpdateMode === item.mode}
                  label={item.label}
                  value={selectedUpdateMode || ""}
                  handleChange={() => setSelectedUpdateMode(item.mode)}
                  radioButtonClasses="flex gap-3"
                  textClases="text-sm font-medium text-neutral-800"
                />
                <div id={`update-ucsv-field-option-${index}-tooltip`}>
                  <Icon
                    name="Info"
                    className="w-5 h-5 text-neutral-300 cursor-pointer"
                  />
                </div>
                <Tooltip
                  id={`update-ucsv-field-option-${index}-tooltip`}
                  direction="top"
                >
                  <Text
                    translationKey={item.toolTipText}
                    classes="text-sm font-medium text-neutral-800"
                  />
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="slider-footer sticky px-6 py-4">
        <div className="flex justify-end items-center gap-6 text-base font-semibold">
          <Button
            label="accounting.integrations.buttonLabel.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            onClick={() => handleCancel()}
          />
          <Button
            label="accounting.integrations.buttonLabel.update"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            disabled={!enableUpdateButton}
            showLoader={isLoading}
            onClick={() => {
              handleUpdate();
            }}
          />
        </div>
      </div>
    </div>
  );
}
