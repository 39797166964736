import PropTypes from "prop-types";

import Text from "@/components/core/Text";
import { CREATE_REIMBURSEMENT_TYPE } from "@/utils/constants/reimbursement";

export default function TypeCell({ val }) {
  return (
    <span
      id={`table-mileage-type-${val?.id}`}
      className="inline-block w-full overflow-hidden truncate cursor-pointer"
    >
      <Text
        translationKey={
          val?.type === CREATE_REIMBURSEMENT_TYPE?.mileage?.value
            ? "reimbursement.createReimbursement.kms"
            : val?.merchant?.name
        }
        translationProps={{ kms: val?.distanceTravelled }}
        showTooltip
        id={`table-mileage-type-${val?.id}`}
        toolTipDirection="right"
      />
    </span>
  );
}
TypeCell.propTypes = {
  val: PropTypes.object,
};
