import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateClientSettings } from "@/store/reducers/client";

import {
  approvalPolicyEnabledSelector,
  claimPolicyEnabledSelector,
  customApprovalPolicyEnabledSelector,
  customClaimPolicyEnabledSelector,
  customSubmissionPolicyEnabledSelector,
  reimbursementMakerCheckerEnabledSelector,
  submissionPolicyEnabledSelector,
} from "@/store/selectors/client";

import DisableConfirmation from "@/components/Settings/Modals/DisableConfirmation";
import EnableConfirmation from "@/components/Settings/Modals/EnableConfirmation";
import SettingsList from "@/components/common/BillPayAndPayroll/Settings/common/SettingsList";
import { REIMBURSEMENT_SETTING_ENTRIES } from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";
import { POLICY_GROUP_MAPPING } from "@/constants/policy";
import { SETTINGS_KEY, SETTINGS_PARAMS } from "@/constants/settings";

export default function ReimbursementSettingsSpendControls() {
  const dispatch = useDispatch();

  const [modalType, setModalType] = useState("");
  const [showEnableModal, setShowEnableModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);

  const claimPolicySwitch = useSelector(claimPolicyEnabledSelector);
  const customClaimPolicySwitch = useSelector(customClaimPolicyEnabledSelector);
  const submissionPolicyEnabled = useSelector(submissionPolicyEnabledSelector);
  const approvalPolicyEnabled = useSelector(approvalPolicyEnabledSelector);
  const customApprovalPolicyEnabled = useSelector(
    customApprovalPolicyEnabledSelector
  );

  const customSubmissionPolicyEnabled = useSelector(
    customSubmissionPolicyEnabledSelector
  );

  const reimbursementMakerCheckerEnabled = useSelector(
    reimbursementMakerCheckerEnabledSelector
  );

  const submitSettings = (key, val) => {
    dispatch(
      updateClientSettings({
        setting: SETTINGS_PARAMS[key],
        value: val,
      })
    );
  };

  const handlePopUp = (key, val) => {
    setModalType(key);
    if (val) {
      setShowEnableModal(true);
    } else {
      setShowDisableModal(true);
    }
  };

  const MODAL_DISABLE_CONFIG = {
    [SETTINGS_KEY.claimPolicy]: {
      header: "settings.spendControls.claimPolicy.disableModalHeader",
      title: "settings.spendControls.claimPolicy.disableModalTitle",
      subtitle: "settings.spendControls.claimPolicy.disableModalSubtitle",
      desc: "settings.spendControls.claimPolicy.disableModalDesc",
      inputLabelText: "DISABLE",
      inputNote: "",
      submitBtnType: "danger",
      submitBtnLabel: "common.btnLabel.yesDisable",
    },
    [SETTINGS_KEY.customClaimPolicy]: {
      header: "settings.spendControls.customClaimPolicy.disableModalHeader",
      title: "settings.spendControls.customClaimPolicy.disableModalTitle",
      subtitle: "settings.spendControls.customClaimPolicy.disableModalSubtitle",
      desc: "settings.spendControls.customClaimPolicy.disableModalDesc",
      inputLabelText: "DISABLE",
      inputNote: "",
      submitBtnType: "danger",
      submitBtnLabel: "common.btnLabel.yesDisable",
    },
  };

  const MODAL_ENABLE_CONFIG = {
    [SETTINGS_KEY.claimPolicy]: {
      title: "settings.spendControls.claimPolicy.enableModalTitle",
      subtitle: "settings.spendControls.claimPolicy.enableModalSubtitle",
      description: "settings.spendControls.claimPolicy.enableModalDescription",
      key: SETTINGS_KEY.claimPolicy,
    },
    [SETTINGS_KEY.customClaimPolicy]: {
      title: "settings.spendControls.customClaimPolicy.enableModalTitle",
      subtitle: "settings.spendControls.customClaimPolicy.enableModalSubtitle",
      description:
        "settings.spendControls.customClaimPolicy.enableModalDescription",
      key: SETTINGS_KEY.customClaimPolicy,
    },
  };

  const spendControlsTabContent = [
    {
      id: "approval-policies",
      title: "settings.approvalPolicies",
      description: "settings.approvalPolicyDescription",
      rightChildren: [
        {
          id: REIMBURSEMENT_SETTING_ENTRIES.APPROVAL_POLICY,
          title: "settings.spendControls.approvalPolicy.title",
          description: "settings.spendControls.approvalPolicy.description",

          showSwitch: false,
          isSwitchOn: false,

          sliderType: approvalPolicyEnabled
            ? SLIDERS_SEARCH_PARAMS.settings.policyModule
            : null,
          sliderId: approvalPolicyEnabled
            ? POLICY_GROUP_MAPPING.reimbursements
            : null,
          disableMessage: approvalPolicyEnabled
            ? null
            : "settings.spendControls.approvalPolicy.alertDisabledModuleDesc",
        },
        {
          id: REIMBURSEMENT_SETTING_ENTRIES.CUSTOM_APPROVAL_POLICIES,
          title: "settings.spendControls.customPolicy.title",
          description: "settings.spendControls.customPolicy.description",

          showSwitch: false,
          isSwitchOn: false,

          sliderType: customApprovalPolicyEnabled
            ? SLIDERS_SEARCH_PARAMS.settings.customApprovalModule
            : null,
          sliderId: customApprovalPolicyEnabled
            ? POLICY_GROUP_MAPPING.reimbursement
            : null,
          showItem: approvalPolicyEnabled,
          disableMessage: customApprovalPolicyEnabled
            ? null
            : "settings.spendControls.customPolicy.alertDisabledModuleDesc",
        },
        {
          id: "makerChecker",
          title: "settings.spendControls.makerChecker.title",
          description: "settings.spendControls.makerChecker.payment",
          showSwitch: true,
          isSwitchOn: reimbursementMakerCheckerEnabled,
          showItem: approvalPolicyEnabled,
          handleChange: () =>
            dispatch(
              updateClientSettings({
                setting: SETTINGS_PARAMS.reimbursementMakerCheckerEnabled,
                value: !reimbursementMakerCheckerEnabled,
              })
            ),
        },
      ],
    },

    {
      id: "submission-policies",
      title: "settings.submissionPolicies",
      description: "settings.submissionPolicyDescription",
      rightChildren: [
        {
          id: REIMBURSEMENT_SETTING_ENTRIES.SUBMISSION_POLICY,
          title: "settings.spendControls.submissionPolicy.title",
          description: "settings.spendControls.submissionPolicy.description",

          showSwitch: false,
          isSwitchOn: false,

          sliderType: submissionPolicyEnabled
            ? SLIDERS_SEARCH_PARAMS.settings.submissionModule
            : null,
          sliderId: submissionPolicyEnabled
            ? POLICY_GROUP_MAPPING.reimbursements
            : null,
          disableMessage: submissionPolicyEnabled
            ? null
            : "settings.spendControls.submissionPolicy.alertDisabledModuleDesc",
        },
        {
          id: REIMBURSEMENT_SETTING_ENTRIES.CUSTOM_SUBMISSION_POLICIES,
          title: "settings.spendControls.customSubmissionPolicy.title",
          description:
            "settings.spendControls.customSubmissionPolicy.description",

          showSwitch: false,
          isSwitchOn: false,

          sliderType: customSubmissionPolicyEnabled
            ? SLIDERS_SEARCH_PARAMS.settings.customSubmissionModule
            : null,
          sliderId: customSubmissionPolicyEnabled
            ? POLICY_GROUP_MAPPING.reimbursements
            : null,
          showItem: submissionPolicyEnabled,
          disableMessage: customSubmissionPolicyEnabled
            ? null
            : "settings.spendControls.customSubmissionPolicy.alertDisabledModuleDesc",
        },
      ],
    },
    {
      id: "claim-policies",
      title: "reimbursement.settings.rows.claimPolicies.title",
      description: "reimbursement.settings.rows.claimPolicies.description",
      showSwitch: true,
      isSwitchOn: claimPolicySwitch,
      handleChange: () =>
        handlePopUp(SETTINGS_KEY.claimPolicy, !claimPolicySwitch),

      rightChildren: claimPolicySwitch
        ? [
            {
              id: REIMBURSEMENT_SETTING_ENTRIES.COMPANY_CLAIM_POLICIES,
              title:
                "reimbursement.settings.rows.claimPolicies.rightSide.companyClaimPolicy.title",
              description:
                "reimbursement.settings.rows.claimPolicies.rightSide.companyClaimPolicy.description",

              showSwitch: false,

              sliderType:
                SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls
                  .claimPolicy,
              sliderId: FORM_TYPE.preview,
            },
            {
              id: REIMBURSEMENT_SETTING_ENTRIES.CUSTOM_CLAIM_POLICIES,
              title:
                "reimbursement.settings.rows.claimPolicies.rightSide.customClaimPolicies.title",
              description:
                "reimbursement.settings.rows.claimPolicies.rightSide.customClaimPolicies.description",

              showSwitch: true,
              isSwitchOn: customClaimPolicySwitch,
              handleChange: () =>
                handlePopUp(
                  SETTINGS_KEY.customClaimPolicy,
                  !customClaimPolicySwitch
                ),

              sliderType: SLIDERS_SEARCH_PARAMS.settings.customClaimModule,
              sliderId: POLICY_GROUP_MAPPING.claim,
            },
          ]
        : [],
    },
  ];
  return (
    <div>
      <SettingsList tabRows={spendControlsTabContent} />
      <DisableConfirmation
        header={MODAL_DISABLE_CONFIG[modalType]?.header}
        title={MODAL_DISABLE_CONFIG[modalType]?.title}
        subtitle={MODAL_DISABLE_CONFIG[modalType]?.subtitle}
        desc={MODAL_DISABLE_CONFIG[modalType]?.desc}
        inputNote={MODAL_DISABLE_CONFIG[modalType]?.inputNote}
        showModal={showDisableModal}
        setShowModal={setShowDisableModal}
        labelText={MODAL_DISABLE_CONFIG[modalType]?.inputLabelText}
        submitBtnLabel={MODAL_DISABLE_CONFIG[modalType]?.submitBtnLabel}
        submitBtnType={MODAL_DISABLE_CONFIG[modalType]?.submitBtnType}
        onSubmitClick={() => {
          submitSettings(MODAL_ENABLE_CONFIG[modalType]?.key, false);
          setShowDisableModal(false);
        }}
      />
      <EnableConfirmation
        title={MODAL_ENABLE_CONFIG[modalType]?.title}
        subtitle={MODAL_ENABLE_CONFIG[modalType]?.subtitle}
        showModal={showEnableModal}
        description={MODAL_ENABLE_CONFIG[modalType]?.description}
        setShowModal={setShowEnableModal}
        onSubmitClick={() => {
          submitSettings(MODAL_ENABLE_CONFIG[modalType]?.key, true);
          setShowEnableModal(false);
        }}
        onCancelClick={() => {
          setShowEnableModal(false);
        }}
      />
    </div>
  );
}
