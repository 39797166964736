import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { approveRequest, setIndexApiReload } from "@/store/reducers/app";
import { fetchCategories } from "@/store/reducers/categories";
import {
  bulkApprove,
  fetchExpenses,
  requestReceived,
  resetExpensesListAndPagination,
  updateExpense,
} from "@/store/reducers/expense";
import {
  fetchQrPayments,
  qrPaymentsBulkApprove,
  resetQrPaymentsListAndPagination,
  updateQrPayments,
} from "@/store/reducers/qr-payments";
import { fetchTags } from "@/store/reducers/tags";
import { fetchVendors } from "@/store/reducers/vendors";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  dataActionLoadingIdSelector,
  indexApiReloadSelector,
} from "@/store/selectors/app";
import { categoriesListSelector } from "@/store/selectors/categories";
import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
  expenseMultiLayerReviewEnabledSelector,
} from "@/store/selectors/client";
import {
  dataActionInProgressIdSelector,
  expensesHasMoreSelector,
  expensesListSelector,
  expensesTotalSelector,
  isBulkApproveInProgressSelector,
  isExpensesFetchingSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isFetchingQrPayments,
  qrPaymentsBulkApproveSelector,
  qrPaymentsHasMoreSelector,
  qrPaymentsListSelector,
  qrPaymentsTotalSelector,
} from "@/store/selectors/qr-payments";
import {
  accountingCategoryTagsSelector,
  isTagsFetchedSelector,
} from "@/store/selectors/tags";
import { userSelector } from "@/store/selectors/user";

import ApprovalProgress from "@/components/core/ApprovalProgress";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import ProfileWidget from "@/components/core/ProfileWidget";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import CardHolder from "@/components/Cards/CardsTable/CardTableComponents/CardHolder";
import MemoNoteIcon from "@/components/common/MemoNoteIcon";
import ProfileWidgetWithCheckbox from "@/components/common/ProfileWidgetWithCheckbox";
import ActionCell from "@/components/common/QrPayAndExpense/common/Cells/ActionCell";
import CardUsageType from "@/components/common/QrPayAndExpense/common/Cells/CardUsageType";
import TableLoader from "@/components/common/QrPayAndExpense/common/ExpenseLoaders/TableLoader";
import BulkApproveComponent from "@/components/common/QrPayAndExpense/common/NeedsReview/NeedsReviewList/BulkApproveComponent";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import ReceiptIcon from "@/components/common/ReceiptIcon";
import SubmissionPolicyIcon from "@/components/common/SubmissionPolicyIcon";
import { convertFilters } from "@/utils/filters";
import {
  SORTING_CATEGORY,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";
import {
  EXPENSES_NEEDS_REVIEW_TABLE_HEADERS,
  QRPAY_NEEDS_REVIEW_TABLE_HEADERS,
} from "@/utils/constants/qr-payments";
import { MEMO_NOTE_VARAINT } from "@/utils/constants/common";
import { amountToCurrency, dateToString, debounce } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EXPENSE_CTA_KEYS, EXPENSE_TYPE } from "@/constants/expense";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import "./styles.scss";

export default function NeedsReviewList({ viewTransactionItem, page, tab }) {
  const dispatch = useDispatch();
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;
  const multipleExpenseReviewLayersSupported = useSelector(
    expenseMultiLayerReviewEnabledSelector
  );
  const expenseSelectors = {
    transaction: useSelector((state) => expensesListSelector(state)),
    isFetching: useSelector((state) => isExpensesFetchingSelector(state)),
    totalTransactions: useSelector(expensesTotalSelector),
    isApprovalInProgress: useSelector(isBulkApproveInProgressSelector),
    hasMore: useSelector(expensesHasMoreSelector),
    tableHeaders: EXPENSES_NEEDS_REVIEW_TABLE_HEADERS.filter((item) =>
      item.id === "reviewStatusMultipleReviewLayers"
        ? multipleExpenseReviewLayersSupported
        : true
    ),
    tableColWidths: [300, 200, 200, 200, 150, 350],
    acoountingEnabledTableColWidths: [300, 200, 220, 200, 150, 350, 300, 300],
    date: "expenseDate",
  };
  const qrPaymentsSelectors = {
    transaction: useSelector((state) => qrPaymentsListSelector(state)),
    isFetching: useSelector((state) => isFetchingQrPayments(state)),
    totalTransactions: useSelector(qrPaymentsTotalSelector),
    hasMore: useSelector(qrPaymentsHasMoreSelector),
    isApprovalInProgress: useSelector(qrPaymentsBulkApproveSelector),
    tableHeaders: QRPAY_NEEDS_REVIEW_TABLE_HEADERS,
    tableColWidths: [300, 200, 300, 200, 150, 350],
    acoountingEnabledTableColWidths: [300, 200, 220, 200, 150, 350, 300, 300],
    date: "paymentDate",
  };

  // common selectors for qrPay and expenses
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const isFetchedAccountingTags = useSelector(isTagsFetchedSelector);
  const isApprovalInProgress = useSelector(isBulkApproveInProgressSelector);
  const accountingVendorOptions = useSelector(accountingVendorsSelector);
  const accountingCategory = useSelector(accountingCategoryTagsSelector);
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const appliedFilter = useSelector(appliedFilterSelector);
  const currentUser = useSelector(userSelector);
  const categories = useSelector(categoriesListSelector);

  const dataActionInProgressId = useSelector(dataActionInProgressIdSelector);
  const approveActionInProgressId = useSelector(dataActionLoadingIdSelector);
  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const currentSelectors = isExpensesPage
    ? expenseSelectors
    : qrPaymentsSelectors;
  const isEmpty = !currentSelectors?.transaction?.length;

  // use states
  const [searchText, setSearchText] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedVendors, setSelectedVendors] = useState({});
  const appliedFilters = useSelector(appliedFilterSelector);
  // for  multi-select
  const [headerSelected, setHeaderSelected] = useState(false);
  // select total Expense
  const [totalExpenseSelected, setTotalExpenseSelected] = useState(false);
  const [buttonHovered, setButtonHovered] = useState({
    flag: null,
    approve: null,
  });
  const [sorting, setSorting] = useState({
    type: SORTING_TYPE.DEC,
    category: SORTING_CATEGORY.DATE,
  });

  const handleSorting = (category) => {
    setSorting((prev) => {
      return {
        category,
        type:
          prev.type === SORTING_TYPE.INC ? SORTING_TYPE.DEC : SORTING_TYPE.INC,
      };
    });
  };
  const searchHandler = debounce((text) => {
    setSearchText(text);
  }, 500);

  const onReset = () => {
    const resetTransaction = isExpensesPage
      ? resetExpensesListAndPagination
      : resetQrPaymentsListAndPagination;
    dispatch(resetTransaction());
  };
  const loadMore = () => {
    const fetchAction = isExpensesPage ? fetchExpenses : fetchQrPayments;
    dispatch(
      fetchAction({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...convertFilters(appliedFilter),
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
        ...(tab?.defaultQueryParams ?? {}),
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore: currentSelectors?.hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...appliedFilter,
      page: 1,
      category: sorting?.category,
      type: sorting?.type,
      reloadIndexApi,
    },
  });
  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  const headerSelectionHandler = (action) => {
    if (action && currentSelectors?.transaction) {
      setSelectedRows(currentSelectors?.transaction?.map((val) => val.id));
    } else {
      setSelectedRows([]);
    }
    setHeaderSelected((prev) => !prev);
  };

  const refreshExpenseData = () => {
    dispatch(setIndexApiReload(!reloadIndexApi));
  };

  const bulkApproveHandler = (transactionIds) => {
    const fetchAction = isExpensesPage ? bulkApprove : qrPaymentsBulkApprove;

    const payloadKey = isExpensesPage ? "expense_ids" : "qrPayment_ids";

    const payload = {
      payload: {
        [payloadKey]: transactionIds,
      },
    };

    dispatch(
      fetchAction({
        payload: {
          [payloadKey]: transactionIds,
        },
        onSuccess: () => {
          refreshExpenseData();
        },
      })
    );
    setSelectedRows([]);
  };

  const rowSelectionHandler = (transactionId, action) => {
    if (!action) setSelectedRows((prev) => [...prev, transactionId]);
    else
      setSelectedRows((prev) => prev.filter((curr) => curr !== transactionId));
  };

  const handleAccountingCategorySelection = (e, index, val) => {
    // updating UI with Value
    setSelectedCategory((prev) => {
      return { ...prev, [index]: e?.id };
    });
    // getting tag id
    const id = val?.accountingTags?.find(
      (_) => _?.tagId === accountingCategory?.id
    )?.id;
    //  sending api update request
    const fetchAction = isExpensesPage ? updateExpense : updateQrPayments;

    const payloadKey = isExpensesPage ? "expenseId" : "qrPayId";

    const payload = {
      [payloadKey]: val?.id,
      payload: {
        expense_tag_values_attributes: [
          id
            ? {
                id,
                tag_id: accountingCategory?.id,
                tag_value_id: e?.id,
              }
            : {
                tag_id: accountingCategory?.id,
                tag_value_id: e?.id,
              },
        ],
      },
    };

    dispatch(fetchAction(payload));
  };

  function getActionButtons(key, val) {
    switch (key) {
      case EXPENSE_CTA_KEYS.APPROVE:
        dispatch(
          approveRequest({
            payload: {
              target_id: val?.id,
              type: isExpensesPage
                ? EXPENSE_TYPE.EXPENSE
                : EXPENSE_TYPE.QR_PAYMENT,
            },
            onSuccess: () => {
              dispatch(setIndexApiReload(!reloadIndexApi));
            },
          })
        );
        break;

      case EXPENSE_CTA_KEYS.FLAG:
        searchParams.append(
          isExpensesPage ? SLIDERS_SEARCH_PARAMS.expenses.flagTransaction : "",
          val?.id
        );
        setSearchParams(searchParams);
        break;

      case EXPENSE_CTA_KEYS.REQUEST_REPAYMENT:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.expenses.requestRepayment,
          val?.id
        );
        setSearchParams(searchParams);
        break;

      case EXPENSE_CTA_KEYS.REPAYMENT_RECEIVED:
        dispatch(
          requestReceived({
            id: val?.id,
            onSuccess: () => {
              dispatch(setIndexApiReload(!reloadIndexApi));
            },
          })
        );
        break;

      case EXPENSE_CTA_KEYS.REPAYMENT_CANCELLED:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.expenses.cancelRepayment,
          val?.id
        );
        setSearchParams(searchParams);
        break;

      default:
        break;
    }
  }
  const handleVendorSelection = (e, index, val) => {
    // update UI
    setSelectedVendors((prev) => {
      return { ...prev, [index]: e?.id };
    });
    // sending request to backend
    const fetchAction = isExpensesPage ? updateExpense : updateQrPayments;

    const payloadKey = isExpensesPage ? "expenseId" : "qrPayId";

    const payload = {
      [payloadKey]: val?.id,
      payload: { accounting_payee_id: e?.id },
    };
    dispatch(fetchAction(payload));
  };
  function getAccountHolder(transaction) {
    return isExpensesPage ? transaction.cardHolder : transaction.walletHolder;
  }

  useEffect(() => {
    // adding this additional condition because at very first initial load when no data is populted to currentSelectors?.transaction
    // for a second the headerSelected gets true and shows selected in ui
    /* selectedRows.length === 0
        ? false
     */
    setHeaderSelected(
      selectedRows.length === 0
        ? false
        : selectedRows.length === currentSelectors?.transaction.length
    );
  }, [selectedRows, currentSelectors?.transaction]);
  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchVendors({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT }));
    dispatch(fetchAccountingVendors());
    dispatch(fetchTags({ visble: true }));
  }, []);

  return (
    <div className="mt-2">
      <Table
        headerSticky
        numberOfStickyColsLeft={1}
        numberOfStickyColsRight={1}
        colWidths={
          accountingEnabled
            ? currentSelectors?.acoountingEnabledTableColWidths
            : currentSelectors?.tableColWidths
        }
        rightColWidths={[200]}
        styleId="expenses-needs-review-table"
        bulkApproveVisible={selectedRows.length > 0}
        bulkApproveContent={
          <BulkApproveComponent
            selectAllVisible={
              currentSelectors?.totalTransactions >=
              PAGINATION_PER_REQUEST_LIMIT
            }
            totalApprove={currentSelectors?.totalTransactions}
            selectTotalExpense={totalExpenseSelected}
            selectedRows={
              totalExpenseSelected
                ? currentSelectors?.totalTransactions
                : selectedRows.length
            }
            handleTotalExpenseSelection={(val) => {
              setTotalExpenseSelected(val);
              if (!val) {
                setHeaderSelected(false);
                setSelectedRows([]);
              }
            }}
            handleApprove={() =>
              bulkApproveHandler(selectedRows, totalExpenseSelected)
            }
            isApproveInProgress={isApprovalInProgress}
          />
        }
        bulkApproveHeight="100px"
        bulkApproveWidth="60%"
        emptyDataTitle="expenses.flagged.tableEmptyStates.title"
        emptyDataDescription="expenses.flagged.tableEmptyStates.description"
      >
        <tr className="text-xs font-semibold text-left text-neutral-800">
          {currentSelectors?.tableHeaders
            ?.filter((val) =>
              val?.isAccountingTag ? !!accountingSoftware : true
            )
            ?.map((val, index) =>
              !val?.isAccountingTag ? (
                <th
                  onClick={
                    val?.sortingCategory
                      ? () => handleSorting(val?.sortingCategory)
                      : null
                  }
                  key={`table-row-flagged-${index}`}
                  className={val?.class && !val?.haveArrow ? val?.class : ""}
                >
                  {val?.haveArrow ? (
                    <div
                      className={`flex items-center gap-2 ${val?.extraClasses}`}
                    >
                      {tab?.key !== 3 && index === 0 ? (
                        !currentSelectors?.isFetching ? (
                          <span onClick={(event) => event?.stopPropagation()}>
                            <Checkbox
                              checked={headerSelected}
                              onClickHandler={() =>
                                headerSelectionHandler(!headerSelected)
                              }
                            />
                          </span>
                        ) : (
                          <LoaderSkeleton size={[20, 20]} />
                        )
                      ) : null}
                      <Text translationKey={val?.label} />
                      <div className="flex flex-col justify-center ">
                        <Icon
                          className={
                            val?.sortingCategory === sorting.category &&
                            sorting.type === SORTING_TYPE.INC
                              ? "text-neutral-500"
                              : "text-neutral-300"
                          }
                          name="ArrowDropUp"
                        />
                        <Icon
                          className={
                            val?.sortingCategory === sorting.category &&
                            sorting.type === SORTING_TYPE.DEC
                              ? "text-neutral-500"
                              : "text-neutral-300"
                          }
                          name="ArrowDropDown"
                        />
                      </div>
                    </div>
                  ) : (
                    <Text translationKey={val?.label} />
                  )}
                </th>
              ) : accountingEnabled ? (
                <th key={`table-row-flagged-${index}`}>
                  <Text
                    translationKey={val?.label}
                    translationProps={{ accounting: accountingSoftware }}
                  />
                </th>
              ) : null
            )}
        </tr>

        {(!currentSelectors?.isFetching || !isEmpty) &&
          currentSelectors?.transaction?.map((val, index, arr) => {
            const disableCheck = Boolean(
              (approveActionInProgressId &&
                approveActionInProgressId === val.id) ||
                (dataActionInProgressId && dataActionInProgressId === val.id)
            );
            return (
              <tr
                key={`transaction-${index}`}
                className={`text-sm font-medium  cursor-pointer text-neutral-800 ${
                  disableCheck
                    ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                    : ""
                }`}
                ref={(ref) => {
                  if (index === arr.length - 1 && currentSelectors?.hasMore)
                    handleRefChange(ref);
                }}
              >
                <td
                  className={` ${
                    selectedRows.includes(val?.id) || totalExpenseSelected
                      ? "selected-row-cell"
                      : ""
                  }  ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  {tab?.key === 3 ? (
                    <div onClick={() => viewTransactionItem(val?.id)}>
                      <ProfileWidget
                        img={val?.vendor?.avatarUrl ?? val?.merchant?.avatarUrl}
                        textClasses="font-semibold "
                        avatarSize="md"
                        tooltipClasses="w-15.5"
                        name={val?.vendor?.name ?? val?.merchant?.name}
                      >
                        <div className="flex text-xs text-left truncate text-neutral-500">
                          <span>
                            {dateToString(val?.[currentSelectors?.date])}
                          </span>
                          {isExpensesPage ? (
                            <div
                              className="w-3/5 truncate"
                              id={`projectName-${val.id}`}
                            >
                              <span className="profile-widget-dot text-neutral-500" />
                              <Text translationKey={val?.projectName} />

                              <Tooltip id={`projectName-${val.id}`}>
                                <Text translationKey={val?.projectName} />
                              </Tooltip>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </ProfileWidget>
                    </div>
                  ) : (
                    <ProfileWidgetWithCheckbox
                      onRowClickHandler={() => viewTransactionItem(val?.id)}
                      onCheckboxClickHandler={() =>
                        rowSelectionHandler(
                          val?.id,
                          selectedRows.includes(val?.id)
                        )
                      }
                      checkBoxValue={
                        selectedRows.includes(val?.id) || totalExpenseSelected
                      }
                      ProfileWidgetName={
                        val?.vendor?.name ?? val?.merchant?.name
                      }
                      ProfileWidgetImg={
                        val?.vendor?.avatarUrl ?? val?.merchant?.avatarUrl
                      }
                      disabled={disableCheck}
                    >
                      <div className="flex items-center text-xs truncate text-neutral-500">
                        <span>
                          {dateToString(val?.[currentSelectors?.date])}
                        </span>
                        {isExpensesPage ? (
                          <div
                            className="w-3/5 truncate"
                            id={`projectName-${val.id}`}
                          >
                            <span className="profile-widget-dot text-neutral-500" />
                            <Text translationKey={val?.projectName} />

                            <Tooltip id={`projectName-${val.id}`}>
                              <Text translationKey={val?.projectName} />
                            </Tooltip>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </ProfileWidgetWithCheckbox>
                  )}
                </td>
                <td
                  className={`${
                    selectedRows.includes(val?.id) || totalExpenseSelected
                      ? "selected-row-cell"
                      : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <div className="flex items-center justify-end gap-2 font-semibold">
                    {val?.transactionCurrencyCode !== val?.currency ? (
                      <>
                        <div id={`transaction-needs-review-${index}`}>
                          <Icon
                            name="swapHorizontal"
                            className="text-neutral-400"
                          />
                        </div>
                        <Tooltip
                          id={`transaction-needs-review-${index}`}
                          direction="top"
                        >
                          <span>
                            {amountToCurrency(
                              val?.transactionAmount,
                              val?.transactionCurrencyCode
                            )}
                          </span>
                        </Tooltip>
                      </>
                    ) : null}
                    <span>{amountToCurrency(val?.amount, val?.currency)}</span>
                  </div>
                </td>
                {multipleExpenseReviewLayersSupported ? (
                  <td
                    className={`text-left ${
                      selectedRows.includes(val?.id) || totalExpenseSelected
                        ? "selected-row-cell"
                        : ""
                    }
                      ${
                        disableCheck
                          ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                          : ""
                      }`}
                  >
                    <div className="flex items-center justify-between">
                      <ApprovalProgress
                        id={val?.id}
                        disabled={val?.disabled}
                        totalApprovalSteps={
                          val?.approvalLevels ?? val?.approverLevels
                        }
                        currentStep={val?.currentApprovalLevel}
                        currentStatus={val?.currentApprovalLevelStatus}
                        previousApprovers={val?.previousApprovers}
                        isExpense
                      />
                    </div>
                  </td>
                ) : null}
                <td
                  className={`text-left ${
                    selectedRows.includes(val?.id) || totalExpenseSelected
                      ? "selected-row-cell"
                      : ""
                  }
                      ${
                        disableCheck
                          ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                          : ""
                      }`}
                >
                  <CardUsageType
                    cardName={val?.cardName}
                    cardType={val?.cardType}
                    cardUsageType={val?.cardUsageType}
                    cardNumber={val?.cardNumber}
                    cardId={val?.cardId}
                  />
                </td>

                <td
                  className={`text-left ${
                    selectedRows.includes(val?.id) || totalExpenseSelected
                      ? "selected-row-cell"
                      : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <CardHolder
                    displayName={getAccountHolder(val)?.displayName}
                    role={getAccountHolder(val)?.roles}
                    department={getAccountHolder(val)?.departmentName}
                    location={getAccountHolder(val)?.locationName}
                    showIconComponent={false}
                    classes="text-left"
                  />
                </td>
                <td
                  className={`${
                    selectedRows.includes(val?.id) || totalExpenseSelected
                      ? "selected-row-cell"
                      : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <div className="flex justify-center text-center">
                    <SubmissionPolicyIcon
                      value={val?.submissionPolicyStatus}
                      index={val?.id}
                    />
                  </div>
                </td>
                <td
                  className={`${
                    selectedRows.includes(val?.id) || totalExpenseSelected
                      ? "selected-row-cell"
                      : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <div className="flex justify-center text-center">
                    <ReceiptIcon
                      index={index}
                      receiptStatus={val?.receiptStatus}
                    />
                  </div>
                </td>
                <td
                  className={`text-center font-semibold ${
                    selectedRows.includes(val?.id) || totalExpenseSelected
                      ? "selected-row-cell"
                      : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <MemoNoteIcon
                    index={index}
                    required={val.memoRequired}
                    variant={MEMO_NOTE_VARAINT.TEXT}
                    value={val.memo}
                  />
                </td>
                {/* {accountingEnabled ? (
                  <td
                    className={`text-left ${
                      selectedRows.includes(val?.id) || totalExpenseSelected
                        ? "selected-row-cell"
                        : ""
                    }`}
                  >
                    <VpSelect
                      required
                      hideLabelAfterSelect
                      value={
                        selectedCategory[index]
                          ? selectedCategory[index]
                          : val?.accountingTags?.find(
                              (_) => _?.tagId === accountingCategory?.id
                            )?.tagValueId
                      }
                      placeholder="expenses.slider.accountingMerchant"
                      label="expenses.slider.select"
                      placeholderTranslationProp={{
                        accounting: accountingSoftware,
                      }}
                      handleChange={(e) =>
                        handleAccountingCategorySelection(e, index, val)
                      }
                      valueKey="id"
                      options={accountingCategory?.options}
                      isOptionsFetched={isFetchedAccountingTags}
                      isOptionsLoading={!isFetchedAccountingTags}
                      fetchOptions={() => dispatch(fetchTags({ visble: true }))}
                      optionsDisplayKey="alias"
                    />
                  </td>
                ) : null}
                {accountingEnabled ? (
                  <td
                    className={`text-left ${
                      selectedRows.includes(val?.id) || totalExpenseSelected
                        ? "selected-row-cell"
                        : ""
                    }`}
                  >
                    <VpSelect
                      hideLabelAfterSelect
                      value={
                        selectedVendors[index]
                          ? selectedVendors[index]
                          : val?.accountingPayeeId
                      }
                      handleChange={(e) => handleVendorSelection(e, index, val)}
                      label="expenses.slider.select"
                      placeholder="expenses.slider.accountingCategory"
                      placeholderTranslationProp={{
                        accounting: accountingSoftware,
                      }}
                      options={accountingVendorOptions}
                      optionsDisplayKey="alias"
                    />
                  </td>
                ) : null} */}
                <td
                  className={`text-center ${
                    selectedRows.includes(val?.id) || totalExpenseSelected
                      ? "selected-row-cell"
                      : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <div className="flex items-center justify-center gap-6 text-neutral-500">
                    <ActionCell
                      expenseFooterCtas={val?.expenseCta}
                      onSubmit={getActionButtons}
                      val={val}
                      disabled={
                        approveActionInProgressId || dataActionInProgressId
                      }
                      showLoader={disableCheck}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        {currentSelectors?.isFetching && <TableLoader />}
      </Table>
    </div>
  );
}

NeedsReviewList.propTypes = {
  viewTransactionItem: PropTypes.func,
  page: PropTypes.string,
  tab: PropTypes.object,
};
