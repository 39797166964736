import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  approveCardRequest,
  fetchRequestTableInfo,
} from "@/store/reducers/actionCentre";
import { setIndexApiReload } from "@/store/reducers/app";
import {
  getRequestApprovalInfo,
  resetSliderInfo,
  setSelectedTabForCardSpecificRequestSliders,
} from "@/store/reducers/cards";

import { indexApiReloadSelector } from "@/store/selectors/app";
import {
  cardSpecificRequestSlidersSelectedTabSelector,
  newCardRequestDetailsSelector,
} from "@/store/selectors/cards";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import MoreOptions from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentSliderFooter/MoreOptions";
import { useForm } from "@/utils/useForm";
import { convertFilters } from "@/utils/filters";

import { ACTION_CENTRE_APPROVAL_TYPE } from "@/constants/ActionCentre";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { CTA_CONFIG_CARD_REQUEST } from "@/constants/cardRequest";
import { BE_FE_REQUEST_TYPE_CARD_REQUEST } from "@/constants/company";
import { APPROVAL_REQUEST_TYPE } from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import HistoryTab from "./HistoryTab";
import OverviewTab from "./OverviewTab/OverviewTab";

function CardLimitSlider({ setOnClose }) {
  const currentUser = useSelector(userSelector);
  const tabs = [
    {
      name: "cards.cardDetails.overview.navigationLabel",
      count: null,
      key: 1,
    },
    {
      name: "cards.cardDetails.overview.history.navigationLabel",
      count: null,
      key: 2,
      showComingSoon: true,
    },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const ref = useLeftHeaderTitle(
    {
      title: "Card limit request",
    },

    true
  );
  const selectedTab = useSelector(
    cardSpecificRequestSlidersSelectedTabSelector
  );

  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);

  const nonMainCTAConfigs = cardRequestDetails?.allowedCtas?.length
    ? cardRequestDetails?.allowedCtas
        ?.map((cta) => CTA_CONFIG_CARD_REQUEST?.[cta]?.[cta])
        ?.filter((ctaConfig) => ctaConfig && !ctaConfig?.isPrimary)
    : [];

  const displayMoreOptionsButton = nonMainCTAConfigs?.length > 0;
  const selectedTabKey = selectedTab?.key;
  const showFooterCtas = cardRequestDetails?.allowedCtas?.length > 0;
  const appliedFilters = convertFilters(useSelector(appliedFilterSelector));
  const reloadIndexApi = useSelector(indexApiReloadSelector);

  useEffect(() => {
    handleTabChange(tabs[0]);
  }, []);

  const initialValues = {
    comment: {
      value: "",
      validate: {
        required: true,
      },
    },
  };

  const formSchema = [
    {
      name: "comment",
      type: "text",
      label: "Reason",
      placeholder: "Reason",
      mainHeading: "Why do you want to increase the limit?",
    },
  ];

  const {
    handleChange,
    values,
    errors,
    _setValues: setValues,
    handleSubmit,
  } = useForm(initialValues, () => {}, {
    isFetchingInitialValue: true,
  });

  const componentProvidedStuff = {
    dispatch,
    searchParams,
    setSearchParams,
    currentUser,
    appliedFilters,
  };

  function getComponentByTabType() {
    let Component = <></>;

    switch (selectedTabKey) {
      case 1:
        Component = (
          <OverviewTab
            formSchema={formSchema}
            handleChange={handleChange}
            values={values}
            errors={errors}
          />
        );
        break;

      case 2:
        Component = <HistoryTab />;
        break;

      default:
        break;
    }

    return Component;
  }

  function handleTabChange(tab) {
    dispatch(setSelectedTabForCardSpecificRequestSliders(tab));
  }

  function handleReject() {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest,
      cardRequestDetails?.id
    );
    setSearchParams(searchParams);
  }

  const onSucess = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest
    );
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest
    );
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest?.[
        BE_FE_REQUEST_TYPE_CARD_REQUEST[cardRequestDetails?.requestType]
      ]
    );
    dispatch(setIndexApiReload(!reloadIndexApi));
    setSearchParams(searchParams);
  };

  const handleApprove = () => {
    const id = searchParams.get(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest
    );
    dispatch(
      approveCardRequest({
        target_id: id,
        type: ACTION_CENTRE_APPROVAL_TYPE.card,
        onSucess,
        inSlider: true,
      })
    );
  };

  useEffect(() => {
    const id = searchParams.get(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest
    );
    if (id) dispatch(getRequestApprovalInfo({ id }));
  }, [searchParams]);

  useEffect(() => {
    setOnClose((searchParam) => {
      const sliderKey =
        SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
          .physicalCardRequest;
      const isClosed = !searchParam?.includes(sliderKey);
      if (isClosed) {
        dispatch(resetSliderInfo());
      }
    });
  }, []);

  return (
    <>
      <div className="p-8 pt-0 m-0 slider-content-core">
        <div ref={ref}>
          <Text
            translationKey="user.notificationsAndMessages.messages.messageTypeTexts.topUpRequest"
            classes="text-4xl font-bold non-italic"
          />
        </div>

        <div className="mt-6">
          <Tabs
            items={tabs}
            selectedTab={selectedTabKey}
            setCurrentTab={handleTabChange}
            mode
          />
        </div>

        {getComponentByTabType(selectedTabKey)}
      </div>

      {showFooterCtas ? (
        <div className="flex flex-row justify-end gap-6 px-3 py-5 slider-footer">
          {displayMoreOptionsButton && (
            <MoreOptions
              ctaConfigs={nonMainCTAConfigs}
              context={cardRequestDetails}
              componentProvidedStuff={componentProvidedStuff}
              data={cardRequestDetails}
            />
          )}
          <Button
            classes="w-16"
            label="reimbursement.approvals.sliderHeadings.footer.reject"
            onClick={handleReject}
            type="danger"
            variant="tertiary"
          />
          <Button
            classes="w-15.5 h-10"
            type="success"
            onClick={handleApprove}
            label="misc.approve"
            form="approve-limit-request"
          />
        </div>
      ) : null}
    </>
  );
}

export default CardLimitSlider;

CardLimitSlider.propTypes = {
  setOnClose: PropTypes.func,
};
