import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

import Icon from "@/components/core/Icon";

export default function SecondaryAuthLayout() {
  return (
    <div className="flex mt-11.5 justify-center h-screen">
      <div className="w-1/3">
        <div className="flex flex-col">
          <Icon
            className="w-16 h-8.5 text-3xl text-neutral-700 mb-10 "
            name="VolopayLogoOnCard"
          />
          <Outlet />
        </div>
      </div>
    </div>
  );
}
