import {
  camelToSnake,
  getDateInPattern,
  validateAndConvertToISO8601,
} from "@/utils/common";

import { REIMBURSEMENT_PAYLOAD_KEY } from "@/constants/reimbursement";
import { TAG_FIELD_TYPES } from "@/constants/tags";

export const convertReimbursmentPayload = (object) => {
  const formData = new FormData();
  Object.entries(object).forEach(([key, val]) => {
    if (key === REIMBURSEMENT_PAYLOAD_KEY.RECEIPTS) {
      if (val?.length)
        val?.forEach((file) => {
          formData.append(`${key}[]`, file);
        });
    } else if (key === REIMBURSEMENT_PAYLOAD_KEY.TAG_VALUE_MAIN_KEY) {
      const _val = val?.filter(
        (item) =>
          item?.[REIMBURSEMENT_PAYLOAD_KEY.TAG_VALUE_ID] ||
          item?.[REIMBURSEMENT_PAYLOAD_KEY.CUSTOM_TEXT_VALUE]
      );
      if (_val?.length > 0)
        _val?.forEach((value, index) => {
          Object.entries(value).forEach(([objectKey, ObjectVal]) => {
            formData.append(`${key}[${index}]${objectKey}`, ObjectVal);
          });
        });
    } else {
      const isTransactionDate =
        key === REIMBURSEMENT_PAYLOAD_KEY.TRANSACTION_DATE ||
        key === REIMBURSEMENT_PAYLOAD_KEY.TRAVEL_DATE;
      formData.append(
        key,
        isTransactionDate
          ? typeof val === typeof "" && val?.includes("-")
            ? getDateInPattern(
                new Date(validateAndConvertToISO8601(val)),
                "yyyy-mm-dd"
              )
            : val
          : val
      );
    }
  });
  return formData;
};

export const getCorrectedPayload = (data, allKeysAccounting, apiData = {}) => {
  const correctedEditedData = {};
  let preselectedTagsValue = [];
  if (apiData?.accountingTags?.length)
    preselectedTagsValue = [...preselectedTagsValue, ...apiData.accountingTags];
  if (apiData?.customTags?.length)
    preselectedTagsValue = [...preselectedTagsValue, ...apiData.customTags];
  Object.entries(data).forEach(([key, val]) => {
    const selectedTag = allKeysAccounting[key];

    if (selectedTag) {
      const config = REIMBURSEMENT_PAYLOAD_KEY;
      const selectedTagBEObject = preselectedTagsValue?.find(
        (tag) => tag?.tagId === selectedTag.id
      );

      const isText = selectedTag?.fieldType === TAG_FIELD_TYPES.TEXT;
      const isDate = selectedTag?.fieldType === TAG_FIELD_TYPES.DATE;
      // in case of edit only passing changed value
      if (preselectedTagsValue?.length) {
        const isValueChanged = isText
          ? selectedTagBEObject?.customTextValue !== val
          : selectedTagBEObject?.tagValueId !== val;
        if (isValueChanged) {
          const tagObject = {
            ...(isValueChanged && selectedTagBEObject?.id
              ? { id: selectedTagBEObject?.id }
              : {}),
            [config.TAG_ID]: allKeysAccounting[key]?.id,
            ...(isText || isDate
              ? { [config.CUSTOM_TEXT_VALUE]: val }
              : { [config.TAG_VALUE_ID]: val }),
          };
          if (correctedEditedData?.[config.TAG_VALUE_MAIN_KEY]?.length) {
            correctedEditedData[config.TAG_VALUE_MAIN_KEY] = [
              ...correctedEditedData[config.TAG_VALUE_MAIN_KEY],
              tagObject,
            ];
          } else correctedEditedData[config.TAG_VALUE_MAIN_KEY] = [tagObject];
        }
      } else {
        const tagObject = {
          [config.TAG_ID]: allKeysAccounting[key]?.id,
          ...(isText || isDate
            ? { [config.CUSTOM_TEXT_VALUE]: val }
            : { [config.TAG_VALUE_ID]: val }),
        };
        if (correctedEditedData?.[config.TAG_VALUE_MAIN_KEY]?.length) {
          correctedEditedData[config.TAG_VALUE_MAIN_KEY] = [
            ...correctedEditedData[config.TAG_VALUE_MAIN_KEY],
            tagObject,
          ];
        } else correctedEditedData[config.TAG_VALUE_MAIN_KEY] = [tagObject];
      }
    } else {
      correctedEditedData[camelToSnake(key)] = val;
    }
  });
  return correctedEditedData;
};

export const getAllKeysAccounting = ({
  accountingEnabled,
  accountingCategory,
  nonAccountingCategory,
  customTags,
}) => ({
  ...(accountingEnabled
    ? { [accountingCategory?.id]: accountingCategory }
    : {}),
  ...(accountingEnabled
    ? nonAccountingCategory
        ?.map((tag) => ({
          [tag?.id]: tag,
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
    : {}),
  ...customTags
    ?.map((tag) => ({
      [tag?.id]: tag,
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
});

export const getTagsPreview = ({ values, selectedReimbursement, allTags }) => {
  const allTagsData = Object.groupBy(
    [
      ...(selectedReimbursement?.accountingTags ?? []),
      ...(selectedReimbursement?.customTags ?? []),
    ],
    (item) => item.tagId
  );

  const tagsPayload = allTags
    .map((tag) => {
      const tagValue = values[tag.id];
      const tagExistingData = allTagsData[tag.id]?.[0];
      const tagExistingValue =
        tag.fieldType === TAG_FIELD_TYPES.LIST
          ? tagExistingData?.tagValueId
          : tagExistingData?.customTextValue;

      if (!tagValue) return null; // value not selected, or unchanged

      const obj = {
        [REIMBURSEMENT_PAYLOAD_KEY.TAG_ID]: tag.id,
        tagType: tag.tagType,
        name: tag.name,
      };

      if (tagExistingValue === tagValue) {
        obj.skipPayload = true; // to avoid sending unchanged values in API call
      }

      if (tagExistingData) {
        obj.id = tagExistingData.id;
      }

      if (tag?.fieldType === TAG_FIELD_TYPES.TEXT) {
        obj[REIMBURSEMENT_PAYLOAD_KEY.CUSTOM_TEXT_VALUE] = tagValue;
        obj.label = tagValue;
      } else if (tag?.fieldType === TAG_FIELD_TYPES.DATE) {
        obj[REIMBURSEMENT_PAYLOAD_KEY.CUSTOM_TEXT_VALUE] =
          getDateInPattern(tagValue);
        obj.label = tagValue;
      } else {
        obj[REIMBURSEMENT_PAYLOAD_KEY.TAG_VALUE_ID] = tagValue;
        obj.label = tag.options.find((item) => item.id === tagValue)?.name;
      }

      return obj;
    })
    .filter(Boolean); // to hide unfilled rows in claim form

  return tagsPayload;
};
