import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchOnboardingStepApi,
  setOnboardingSection,
  toggleConfirmReviewModal,
  updateReviewDetails,
} from "@/store/reducers/onboarding";

import {
  confirmReviewDetailsModalBoolSelector,
  isFetchingOnboardingDataSelector,
} from "@/store/selectors/onboarding";

import DisableConfirmation from "@/components/Settings/Modals/DisableConfirmation";

import { ONBOARDING_SLIDER_PARAMS } from "@/constants/onboarding";

function ConfirmReviewDetailsModal() {
  const dispatch = useDispatch();
  const isLoading = useSelector(isFetchingOnboardingDataSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  const confirmReviewDetailsBool = useSelector(
    confirmReviewDetailsModalBoolSelector
  );

  const toggleFunction = () => {
    dispatch(toggleConfirmReviewModal());
  };

  const submitHandler = () => {
    const onSuccess = () => {
      searchParams.delete(ONBOARDING_SLIDER_PARAMS);
      setSearchParams(searchParams);

      toggleFunction();
      dispatch(setOnboardingSection(null));
      dispatch(fetchOnboardingStepApi());
    };
    dispatch(updateReviewDetails({ onSuccess }));
  };

  return (
    <DisableConfirmation
      header="onboarding.reviewDetailsModal.heading"
      title="onboarding.reviewDetailsModal.title"
      subtitle="onboarding.reviewDetailsModal.subtitle"
      desc="onboarding.reviewDetailsModal.desc"
      labelText="CONFIRM"
      showModal={confirmReviewDetailsBool}
      setShowModal={toggleFunction}
      submitBtnLabel="onboarding.reviewDetailsModal.submitBtnLabel"
      onSubmitClick={submitHandler}
      showLoader={isLoading}
    />
  );
}

export default ConfirmReviewDetailsModal;
