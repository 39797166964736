import { useEffect } from "react";

import Lottie from "lottie-web";

export default function PageLoader() {
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#page-loader"),
      path: "/full-page-animation.json",
    });
  }, []);

  return (
    <div className="flex items-center justify-center w-screen h-screen ">
      <div id="page-loader" className="w-[500px] h-[500px]" />
    </div>
  );
}
