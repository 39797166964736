import PaymentsApprovalsPageHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Approvals/PaymentsApprovalPageHelper";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import { TAB_TYPES } from "@/utils/constants/payments";

export default function PaymentsPageApprovalPending() {
  return (
    <PaymentsApprovalsPageHelper
      tabType={TAB_TYPES.PENDING}
      page={BILL_PAYROLL_CONTEXT.BILLPAY}
    />
  );
}
