import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { fetchShallowCards, getMerchantShallow } from "@/store/reducers/cards";
import { fetchCategories } from "@/store/reducers/categories";
import {
  fetchShallowDepartments,
  fetchShallowLocations,
  fetchShallowProjects,
} from "@/store/reducers/company";
import {
  createRule,
  editRule,
  fetchMappings,
  fetchRule,
  fetchRules,
  resetRuleStore,
} from "@/store/reducers/rules";
import { fetchTags } from "@/store/reducers/tags";
import { fetchVendors, fetchVendorsShallow } from "@/store/reducers/vendors";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  cardsListSelector,
  shallowMerchantListSelector,
} from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";
import {
  allDepartmentSelector,
  allLocationSelector,
  projectsSelector,
} from "@/store/selectors/company";
import {
  mappedToSelector,
  ruleItemGroupsSelector,
  selectedRuleSelector,
} from "@/store/selectors/rules";
import {
  accountingCategoryTagsSelector,
  accountingTaxCodeTagsSelector,
  isTagsFetchedSelector,
  listTypeTagsSelector,
} from "@/store/selectors/tags";
import { vendorsListSelector } from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Icon from "@/components/core/Icon";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import { capitalizeFirstLetter } from "@/components/Company/helper";
import { MAPPING_TYPE } from "@/utils/constants/rules";
import { DOT_SYMBOL } from "@/utils/constants/common";
import { deepCamelToSnake } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_FIELD_TYPE,
  ACCOUNTING_OWNER_TYPE,
  DELETE_RULE_TYPES,
  RULE_TYPES,
  VP_ITEM_TYPE,
} from "@/constants/rules";
import { ACCOUNTING_TAG_TYPES, TAG_TYPES } from "@/constants/tags";

import DeleteRuleModal from "../DeleteRuleModal";
import "./index.scss";

export default function EditRuleMapping() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const type = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.newEditRule
  );
  const ruleId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.ruleId
  );
  const searchVolopayField = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.volopayField
  );
  const searchAccountingField = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.accountingField
  );

  const rule = useSelector(selectedRuleSelector);

  const count = rule?.ruleItemGroups?.length;

  const accountingValue = rule?.mappedTo?.accountingValues?.[0];

  const [volopayField, setVolopayField] = useState(null);

  const [accountingField, setAccountingField] = useState(null);

  const mappingsBySelector = useSelector(ruleItemGroupsSelector);

  const [mappings, setMappings] = useState([]);

  const mappedTo = useSelector(mappedToSelector);

  const isFetchedAccountingTags = useSelector(isTagsFetchedSelector);

  const accountingCategories = useSelector(accountingCategoryTagsSelector);

  const accountingTaxCodes = useSelector(accountingTaxCodeTagsSelector);

  const accountingVendorOptions = useSelector(accountingVendorsSelector);

  const listTypeTags = useSelector(listTypeTagsSelector);

  const [actionType, setActionType] = useState(type);
  const [showDeleteRuleModal, setShowDeleteRuleModal] = useState(false);

  const [temporaryId, setTemporaryId] = useState(0);

  const generateTemporaryId = () => {
    const id = temporaryId + 1;
    setTemporaryId(id);
    return id;
  };

  useEffect(() => {
    setVolopayField(mappedTo?.volopayFields?.[0] ?? searchVolopayField);
    setAccountingField(mappedTo?.accountingFields?.[0]);
  }, [rule]);

  useEffect(() => {
    const _mappings = structuredClone(mappingsBySelector) ?? [];
    const mapped = _mappings?.map((item) => {
      const { ruleItems, ruleActions } = item;
      return {
        id: item.id,
        ruleItems,
        ruleActions,
      };
    });
    if (MAPPING_TYPE.NEW_RULE) {
      mapped?.push({
        tempId: generateTemporaryId(),
        ruleItems: [{ itemIds: [], itemType: volopayField }],
        ruleActions: [
          {
            ownerId: null,
            ownerType:
              (accountingField ??
              (searchAccountingField.includes(ACCOUNTING_TAG_TYPES.CATEGORY) ||
                searchAccountingField.includes(ACCOUNTING_TAG_TYPES.TAX_CODE)))
                ? ACCOUNTING_OWNER_TYPE.TAG_VALUE
                : searchAccountingField.includes(ACCOUNTING_FIELD_TYPE.VENDOR)
                  ? ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE
                  : ACCOUNTING_OWNER_TYPE.TAG_VALUE,
          },
        ],
      });
    }
    setMappings(mapped);
  }, [mappingsBySelector]);

  useEffect(() => {
    if (type === MAPPING_TYPE.NEW_RULE) {
      dispatch(resetRuleStore());
    }
  }, [type]);

  const getVolopayFieldSelector = (vpField) => {
    switch (vpField) {
      case VP_ITEM_TYPE.MERCHANT:
        return shallowMerchantListSelector;
      case VP_ITEM_TYPE.PROJECT:
        return projectsSelector;
      case VP_ITEM_TYPE.DEPARTMENT:
        return allDepartmentSelector;
      case VP_ITEM_TYPE.CATEGORY:
        return categoriesListSelector;
      case VP_ITEM_TYPE.CARD:
        return cardsListSelector;
      case VP_ITEM_TYPE.VENDOR:
        return vendorsListSelector;
      case VP_ITEM_TYPE.LOCATION:
        return allLocationSelector;
      default:
        return () => {};
    }
  };

  const volopayFieldData = useSelector(getVolopayFieldSelector(volopayField));

  const [acData, setAcData] = useState([]);

  useEffect(() => {
    const acVariable = accountingValue ?? searchAccountingField;
    const accountingData = acVariable?.includes(ACCOUNTING_TAG_TYPES.CATEGORY)
      ? accountingCategories?.options
      : acVariable?.includes(ACCOUNTING_TAG_TYPES.TAX_CODE)
        ? accountingTaxCodes?.options
        : acVariable?.includes(ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE) ||
            acVariable?.includes(ACCOUNTING_FIELD_TYPE.VENDOR)
          ? accountingVendorOptions
          : listTypeTags.filter((tag) => tag.name === acVariable)?.[0]?.options;
    setAcData(accountingData);
  }, [accountingValue, searchAccountingField]);

  useEffect(() => {
    if (ruleId) {
      dispatch(fetchRule({ id: ruleId }));
      dispatch(fetchMappings({ id: ruleId }));
    }
  }, [ruleId]);

  useEffect(() => {
    switch (volopayField) {
      case VP_ITEM_TYPE.MERCHANT:
        dispatch(getMerchantShallow());
        break;
      case VP_ITEM_TYPE.CATEGORY:
        dispatch(fetchCategories());
        break;
      case VP_ITEM_TYPE.PROJECT:
        dispatch(fetchShallowProjects());
        break;
      case VP_ITEM_TYPE.DEPARTMENT:
        dispatch(fetchShallowDepartments());
        break;
      case VP_ITEM_TYPE.CARD:
        dispatch(fetchShallowCards());
        break;
      case VP_ITEM_TYPE.VENDOR:
        dispatch(fetchVendorsShallow());
        break;
      case VP_ITEM_TYPE.LOCATION:
        dispatch(fetchShallowLocations());
        break;
      default:
        break;
    }
    dispatch(fetchTags({ visible: true }));
    dispatch(fetchAccountingVendors());
  }, [volopayField]);

  const handleDelete = () => {
    setShowDeleteRuleModal(true);
  };

  const [removedMappings, setRemovedMappings] = useState([]);

  const removeMapping = (mapping) => {
    if (mapping.id) {
      setRemovedMappings((prev) => [...prev, id]);
    }
    const id = mapping.id ?? mapping.tempId;
    const updatedMappings = mappings.filter(
      (_mapping) => ![_mapping.id, _mapping.tempId].includes(id)
    );
    setMappings(updatedMappings);
  };

  const handleSubmit = () => {
    if (type === MAPPING_TYPE.NEW_RULE) {
      handleCreate();
    } else {
      handleEdit();
    }
  };

  const onSuccess = () => {
    if (ruleId) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.ruleId);
    }
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.newEditRule);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.volopayField);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.accountingField);
    setSearchParams(searchParams);
    dispatch(resetRuleStore(null));
    dispatch(fetchRules());
  };

  const handleEdit = () => {
    const payload = {
      id: ruleId,
      rule_type: RULE_TYPES.NORMAL,
      rule_item_groups_attributes: mappings
        .map((mapping) => {
          const id = !isValueUndefinedOrNull(mapping.id)
            ? { id: mapping.id }
            : {};
          const ruleItemsAttributes =
            mapping.ruleItems[0].itemIds.length === 0 ||
            isValueUndefinedOrNull(mapping.ruleItems[0].itemType)
              ? {}
              : { rule_items_attributes: deepCamelToSnake(mapping.ruleItems) };
          const ruleActionsAttributes =
            isValueUndefinedOrNull(mapping.ruleActions[0].ownerId) ||
            isValueUndefinedOrNull(mapping.ruleActions[0].ownerType)
              ? {}
              : {
                  rule_actions_attributes: deepCamelToSnake(
                    mapping.ruleActions
                  ),
                };

          const attributes = {
            ...id,
            ...ruleItemsAttributes,
            ...ruleActionsAttributes,
          };

          return Object.keys(attributes).length > 0 ? attributes : null;
        })
        .concat(
          removedMappings.map((id) => ({
            id,
            _destroy: true,
          }))
        )
        .filter((attributes) => attributes != null),
    };
    dispatch(editRule({ payload, onSuccess }));
  };

  const handleCreate = () => {
    const payload = {
      rule_type: RULE_TYPES.NORMAL,
      rule_item_groups_attributes: mappings
        .map((mapping) => {
          const ruleItemsAttributes =
            mapping.ruleItems[0].itemIds.length === 0 ||
            isValueUndefinedOrNull(mapping.ruleItems[0].itemType)
              ? {}
              : {
                  rule_items_attributes: deepCamelToSnake(mapping.ruleItems),
                };
          const ruleActionsAttributes =
            isValueUndefinedOrNull(mapping.ruleActions[0].ownerId) ||
            isValueUndefinedOrNull(mapping.ruleActions[0].ownerType)
              ? {}
              : {
                  rule_actions_attributes: deepCamelToSnake(
                    mapping.ruleActions
                  ),
                };
          const attributes = {
            ...ruleItemsAttributes,
            ...ruleActionsAttributes,
          };
          return Object.keys(attributes).length > 0 ? attributes : null;
        })
        .filter((attributes) => attributes !== null),
    };
    dispatch(createRule({ payload, onSuccess }));
  };

  useEffect(() => {
    if (Object.keys(mappings).length !== 0) {
      if (
        !mappings
          .flatMap((mapping) => mapping.ruleItems)
          .map((ruleItem) => ruleItem.itemIds)
          .some((x) => x.length === 0) &&
        !mappings
          .flatMap((mapping) => mapping.ruleActions)
          .map((ruleAction) => ruleAction.ownerId)
          .some((x) => x === null)
      ) {
        const newMapping = {
          tempId: generateTemporaryId(),
          ruleItems: [{ itemIds: [], itemType: volopayField }],
          ruleActions: [
            {
              ownerId: null,
              ownerType:
                (accountingField ??
                (searchAccountingField.includes(
                  ACCOUNTING_TAG_TYPES.CATEGORY
                ) ||
                  searchAccountingField.includes(
                    ACCOUNTING_TAG_TYPES.TAX_CODE
                  )))
                  ? ACCOUNTING_OWNER_TYPE.TAG_VALUE
                  : searchAccountingField.includes(ACCOUNTING_FIELD_TYPE.VENDOR)
                    ? ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE
                    : ACCOUNTING_OWNER_TYPE.TAG_VALUE,
            },
          ],
        };
        setMappings((prev) => [...prev, newMapping]);
      }
    }
  }, [mappings]);

  const mappedVolopayData = (data) => {
    switch (volopayField) {
      case VP_ITEM_TYPE.CARD:
        return data?.map((vpData) => ({
          id: vpData.id,
          label: vpData.name,
          subLabel: `${vpData.userName} ${DOT_SYMBOL} ${vpData.cardNumber}`,
        }));
      default:
        return data?.map((vpData) => ({
          id: vpData.id,
          label: vpData.name,
        }));
    }
  };

  const getVolopayOptions = (index) => {
    const ruleItems = mappings
      .filter((_, idx) => idx !== index)
      .map((mp) => mp.ruleItems)
      .flat();

    const itemIds = ruleItems.map((ri) => ri.itemIds).flat();

    const filteredVolopayData = volopayFieldData?.filter(
      (category) => !itemIds.includes(category.id)
    );
    return mappedVolopayData(filteredVolopayData);
  };

  const getAccountingOptions = (index) => {
    const ruleActions = mappings
      .filter((_, idx) => idx !== index)
      .map((ra) => ra.ruleActions)
      .flat();

    const ownerIds = ruleActions.map((ra) => ra.ownerId);

    const filteredAccountingData = acData?.filter(
      (category) => !ownerIds.includes(category.id)
    );
    return filteredAccountingData;
  };

  const setIds = (
    { selectedIds, ...rest },
    mapping_id,
    id,
    isAccounting,
    index
  ) => {
    setMappings((prev) => {
      const updated = prev?.map((item, idx) => {
        if (item.id === mapping_id) {
          if (isAccounting) {
            item.ruleActions = item.ruleActions?.map((ruleAction) =>
              ruleAction?.id === id && rest.id && index === idx
                ? {
                    ...ruleAction,
                    ownerId: rest.id,
                    ownerType: ruleAction.ownerType ?? accountingField,
                  }
                : ruleAction
            );
          }
          item.ruleItems = item.ruleItems?.map((ruleItem) =>
            ruleItem?.id === id && selectedIds && index === idx
              ? {
                  ...ruleItem,
                  itemIds: selectedIds?.map((_id) => parseInt(_id, 10)),
                  itemType: ruleItem.itemType || volopayField,
                }
              : ruleItem
          );
        }
        return item;
      });
      return updated;
    });
  };

  return (
    <>
      <div className="slider-content-core !px-0">
        <span className="text-2xl font-bold px-9 text-neutral-800 first-letter:uppercase">
          {capitalizeFirstLetter(actionType)} rule
        </span>
        <div className="flex items-center gap-3 mt-8 px-9">
          <div
            className={`${
              count > 0
                ? "bg-primary-50 text-primary-500"
                : "bg-neutral-50 text-neutral-400"
            } flex items-center justify-center rounded-full p-2`}
          >
            <Icon name="LightiningBolt" className="text-neutral-400" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <span className="text-lg font-medium">
                <Text
                  translationKey={`accounting.rules.editRuleMapping.${
                    type === MAPPING_TYPE.NEW_RULE
                      ? searchVolopayField
                      : volopayField
                  }`}
                />
              </span>
              <Icon name="MapArrow" className="text-neutral-500" />

              <span className="text-lg font-medium">
                {type === MAPPING_TYPE.NEW_RULE
                  ? searchAccountingField
                  : accountingValue}
              </span>
            </div>
            <span className="text-sm text-neutral-500">
              {type === MAPPING_TYPE.EDIT_RULE
                ? `${count} active mappings`
                : null}
            </span>
          </div>
        </div>
        <div className="h-full pr-3 pl-9 my-9 headers">
          <Table colWidths={[320, 200]}>
            <tr>
              <th className="text-xs font-medium text-left">
                <div className="px-4 py-2">
                  <Text
                    translationKey={`accounting.rules.editRuleMapping.${
                      type === MAPPING_TYPE.NEW_RULE
                        ? searchVolopayField
                        : volopayField
                    }`}
                  />
                </div>
              </th>
              <th className="text-xs font-medium text-left">
                {type === MAPPING_TYPE.NEW_RULE
                  ? searchAccountingField
                  : accountingValue}
              </th>
              <th>
                <span aria-label="render-empty-space" />
              </th>
            </tr>
            {mappings?.map((mapping, index) => {
              const { ruleItems = [], ruleActions = [] } = mapping;
              return (
                <tr key={mapping.id}>
                  {ruleItems?.map((ruleItem) => (
                    <td key={ruleItem.id}>
                      <div className="relative w-full px-4 py-3 ">
                        <CheckboxDropdown
                          id={ruleItem.id}
                          label="Select"
                          options={getVolopayOptions(index)}
                          selectedIdsArray={ruleItem?.itemIds?.map((id) =>
                            parseInt(id, 10)
                          )}
                          handleSubmit={(data) =>
                            setIds(data, mapping.id, ruleItem.id, false, index)
                          }
                          isLoading={!volopayFieldData}
                          hideLabelAfterSelect
                        />
                      </div>
                    </td>
                  ))}
                  {ruleActions?.map((ruleAction) => {
                    const currentTag = listTypeTags?.find(
                      (accountingData) =>
                        accountingData?.name === searchAccountingField
                    );
                    const optionsDisplayKey =
                      currentTag?.tagType === TAG_TYPES.CUSTOM
                        ? "name"
                        : "alias";

                    return (
                      <td key={ruleAction.id}>
                        <div className="h-full">
                          <VpSelect
                            label="Select"
                            optionsDisplayKey={optionsDisplayKey}
                            valueKey="id"
                            value={ruleAction.ownerId}
                            hideLabelAfterSelect
                            options={getAccountingOptions(index)}
                            handleChange={(data) =>
                              setIds(
                                data,
                                mapping.id,
                                ruleAction.id,
                                true,
                                index
                              )
                            }
                            isOptionsFetched={isFetchedAccountingTags}
                            isOptionsLoading={!isFetchedAccountingTags}
                            menuPosition="absolute"
                          />
                        </div>
                      </td>
                    );
                  })}
                  <td>
                    {showDelete(mapping) && (
                      <Icon
                        name="Delete"
                        className="text-neutral-400"
                        handleClick={(data) => removeMapping(mapping)}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </Table>
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 p-4 slider-footer">
        {type !== MAPPING_TYPE.NEW_RULE && (
          <Button
            variant="tertiary"
            label="accounting.rules.deleteRule.title"
            classes="w-fit px-4 text-danger-500 text-base font-semibold"
            onClick={handleDelete}
          />
        )}
        <Button
          variant="primary"
          label="accounting.rules.deleteRule.btnLabels.saveChanges"
          classes="w-fit px-4 text-base font-semibold"
          onClick={handleSubmit}
        />
      </div>

      {showDeleteRuleModal ? (
        <DeleteRuleModal
          showModal={showDeleteRuleModal}
          setShowModal={setShowDeleteRuleModal}
        />
      ) : null}
    </>
  );

  function isValueUndefinedOrNull(value) {
    return value === undefined || value === null;
  }

  function showDelete(mapping) {
    const { ruleItems = [], ruleActions = [] } = mapping;
    return ruleItems[0].itemIds.length > 0 && !!ruleActions[0].ownerId;
  }
}
