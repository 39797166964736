// react imports
import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { disconnectAccountingSoftware } from "@/store/reducers/accounting";
import client, { fetchClient, setClient } from "@/store/reducers/client";

import { isLoadingAccountingIntegrationsSelector } from "@/store/selectors/accounting";

import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";

export default function DisconnectAccountingSoftwareModal({
  showModal,
  setShowModal,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const onCloseModal = () => {
    setShowModal(false);
  };
  const [removeConfig, setRemoveConfig] = useState(false);
  const isLoading = useSelector(isLoadingAccountingIntegrationsSelector);

  const currentSoftwareLabel =
    ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
      searchParams.get(
        SLIDERS_SEARCH_PARAMS.accounting.integrations.softwareSettings
      )
    ];

  const handleDisconnect = async () => {
    const action = await dispatch(
      disconnectAccountingSoftware({ remove_settings: removeConfig })
    );

    if (disconnectAccountingSoftware.fulfilled.match(action)) {
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.accounting.integrations.softwareSettings
      );
      setSearchParams(searchParams);
      dispatch(fetchClient());
    }
  };

  return (
    <Modal
      onClose={onCloseModal}
      open={showModal}
      className="flex items-center justify-center z-[1000]"
    >
      <div className="py-2 w-134 h-fit flex flex-col gap-5 bg-white border border-neutral-200 rounded-2xl">
        <div className="px-6">
          <div className="pt-4 pb-2">
            <Icon name="WarningCircle" className="w-8 h-8 text-warning-500" />
          </div>
          <div className="flex flex-col gap-0.5">
            <Text
              translationKey="accounting.integrations.integrationSettings.disconnect.sureAboutDisconnect.title"
              translationProps={{
                software: currentSoftwareLabel,
              }}
              classes="text-lg font-bold text-neutral-800 py-2"
            />
            <Text
              translationKey="accounting.integrations.integrationSettings.disconnect.sureAboutDisconnect.description"
              translationProps={{
                software: currentSoftwareLabel,
              }}
              classes="text-sm font-semibold text-neutral-500 break-words py-0.5"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 px-6">
          <div className="flex items-center !gap-3 ">
            <Checkbox
              label="accounting.integrations.integrationSettings.disconnect.removeConfiguration"
              labelClasses=" gap-3 text-base font-semibold text-neutral-800"
              checked={removeConfig}
              onClickHandler={(val) => setRemoveConfig(val)}
              classes="flex gap-3"
            />
            <div id={`remove-config-${currentSoftwareLabel}`}>
              <Icon
                name="Info"
                className="w-5 h-5 text-neutral-300 cursor-pointer"
              />
            </div>
            <Tooltip
              direction="top"
              id={`remove-config-${currentSoftwareLabel}`}
              maxWidth="494px"
            >
              <Text
                translationKey="accounting.integrations.toolTipText.removeAccountingConfig"
                classes="flex flex-wrap text-sm font-medium text-neutral-800 break-words"
              />
            </Tooltip>
          </div>
          <div className="flex items-center gap-6 py-6">
            <Button
              label="accounting.integrations.buttonLabel.cancel"
              classes="px-5 py-3 text-base font-semibold text-neutral-500"
              variant="tertiary"
              onClick={onCloseModal}
            />
            <Button
              label="accounting.integrations.buttonLabel.disconnect"
              type="danger"
              classes="px-5 py-3 text-base font-semibold "
              onClick={() => handleDisconnect()}
              showLoader={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

DisconnectAccountingSoftwareModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};
