import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import { MAPPING_TYPE } from "@/utils/constants/rules";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import NewRuleInit from "./NewRuleInit";

export default function NewRule({ cancel }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const confirm = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.rules.newEditRule,
      MAPPING_TYPE.NEW_RULE
    );
    searchParams.append("volopayField", "volopayCategory");
    searchParams.append("accountingField", "quickbooksCategory");
    setSearchParams(searchParams);
  };

  return <NewRuleInit confirmCreate={confirm} cancelCreate={cancel} />;
}

NewRule.propTypes = {
  cancel: PropTypes.func,
};
