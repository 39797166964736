import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import { toggleClientSetting } from "@/store/reducers/client";
import {
  fetchPeople,
  fetchPeopleShallow,
  fetchRoleDetails,
  resetPeopleStoreState,
} from "@/store/reducers/company";

import {
  departmentManagerEditEnabledSelector,
  projectManagerEditEnabledSelector,
  volopayAssistantEnabledSelector,
} from "@/store/selectors/client";
import {
  allPeopleSelector,
  isFetchingPeopleSelector,
  isFetchingRoleDetailsSelector,
  peopleHasMoreSelector,
  peopleSelector,
  peopleTotalSelector,
  roleDetailsSelector,
} from "@/store/selectors/company";
import { sliderAppliedFilterSelector } from "@/store/selectors/filters";
import {
  availableModulesSelector,
  isAdminSelector,
  userPermissionSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Loader from "@/components/core/Loader";
import ProfileWidget from "@/components/core/ProfileWidget";
import Switch from "@/components/core/Switch";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import RolesPermissionsLoader from "@/components/Company/Loaders/RolesPermissionsLoader";
import PeopleTableSearchAndFilter from "@/components/Company/PeopleTableSearchAndFilters";
import DeleteRoleModal from "@/components/Company/common/DeleteRoleModal";
import EditAddPeopleSmartComponent from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/EditAddPeopleSmartComponent";
import { convertFilters } from "@/utils/filters";
import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
  FILTER_TRIGGER_CONTEXT,
} from "@/utils/constants/filters";
import {
  capitalizeFirstLetter,
  debounce,
  joinArrayOfSentences,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_TAB_PARAMS,
  PAYMENT_INITIATOR_MODULE,
  PAYMENT_INITIATOR_ROLES,
  PERMISSIONS_ACCESSIBILITY_TYPE,
  PERMISSIONS_CTA_CONFIG,
  PERMISSIONS_HEADERS_DESCRIPTION_CONFIG,
  PERMISSIONS_TYPE_MAP,
  ROLE_PERMISSIONS_PEOPLE_TABS,
  ROLE_PERMISSIONS_PEOPLE_TAB_PARAMS,
  ROLE_PERMISSIONS_TABS,
  ROLE_TITLE,
  ROLE_TYPE,
  ROLE_TYPE_TITLE,
  VOLOPAY_ASSISTANT_TOGGLE,
} from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import PermissionsSlider from "../PermissionsSlider";

export default function ViewPermissionsPeople() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(
    ROLE_PERMISSIONS_PEOPLE_TABS[0]
  );
  const roleId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.rolesPermissions.role
  );
  const { t } = useTranslation();

  const isAssistantRole = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.rolesPermissions.assistant
  );
  const volopayAssistantEnabled = useSelector(volopayAssistantEnabledSelector);
  const roleDetails = useSelector(roleDetailsSelector);
  const allRolePeople = useSelector(allPeopleSelector);
  const totalPeople = useSelector(peopleTotalSelector);
  const departmentManagerEditEnabled = useSelector(
    departmentManagerEditEnabledSelector
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const projectManagerEditEnabled = useSelector(
    projectManagerEditEnabledSelector
  );
  const appliedFilters = useSelector(sliderAppliedFilterSelector);
  AVAILABLE_FILTERS[AVAILABLE_FILTER_KEYS.searchAndFilter].props.classes =
    "w-91.5";
  const filters = [AVAILABLE_FILTERS[AVAILABLE_FILTER_KEYS.searchAndFilter]];
  const isFetchingPeople = useSelector(isFetchingPeopleSelector);
  const isFetchingRoleDetails = useSelector(isFetchingRoleDetailsSelector);
  const hasMore = useSelector(peopleHasMoreSelector);
  const filteredPeople = useSelector(peopleSelector);
  const isSettlementInitiator =
    roleDetails?.name === PAYMENT_INITIATOR_ROLES.SETTLEMENT_INITIATOR;
  const roleName = isSettlementInitiator
    ? PAYMENT_INITIATOR_ROLES.PAYMENT_INITIATOR
    : roleDetails?.name;
  const canManagePeople =
    roleDetails?.roleType !== ROLE_TYPE.PRIMARY &&
    ![ROLE_TITLE.DEPARTMENT_MANAGER, ROLE_TITLE.PROJECT_MANAGER].includes(
      roleDetails?.name
    );

  const permissionsList = useSelector(userPermissionSelector);

  const hidePeopleTab =
    permissionsList?.some(
      (data) =>
        data.name === PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_PEOPLE_LOCATIONS &&
        data.accaccessibility === PERMISSIONS_ACCESSIBILITY_TYPE.EDIT
    ) || isAssistantRole;

  const [showManagePeople, setShowManagePeople] = useState(false);
  const isAdmin = useSelector(isAdminSelector);
  const [searchText, setSearchText] = useState("");
  const ref = useLeftHeaderTitle({
    title: capitalizeFirstLetter(roleName),
  });

  const filterOptions = {
    ...(searchText ? { q: searchText } : {}),
    selectedTab,
    ...convertFilters(appliedFilters),
  };
  useEffect(() => {
    dispatch(fetchRoleDetails({ roleId }));
  }, []);

  const loadMore = () => {
    dispatch(
      fetchPeople({
        role: [roleId],
        status: COMPANY_PEOPLE_TAB_PARAMS.ACTIVE,
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...filterOptions,
      })
    );
    dispatch(
      fetchPeopleShallow({
        status: COMPANY_PEOPLE_TAB_PARAMS.ACTIVE,
      })
    );
  };

  useEffect(() => {
    const timeout = debouncedFetchData();
    return () => {
      clearTimeout(timeout);
    };
  }, [searchParams]);

  const debouncedFetchData = debounce(() => {
    setSearchText(searchParams.get("search_query"));
  }, 300);

  const onReset = () => {
    dispatch(resetPeopleStoreState());
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    inSlider: true,
    filterOptions: { filterOptions },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  const handleViewPermissions = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.company.rolesPermissions.viewPermissions,
      roleId
    );
    setSearchParams(searchParams);
  };
  const handleEditPermissions = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.company.rolesPermissions.editCustomRole,
      roleId
    );
    setSearchParams(searchParams);
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };
  const availableModule = useSelector(availableModulesSelector);
  const permissions = useMemo(
    () =>
      PERMISSIONS_HEADERS_DESCRIPTION_CONFIG[roleDetails?.name]?.permissions
        ?.filter(
          (item) =>
            !item?.modules ||
            availableModule?.some((module) => item?.modules?.includes(module))
        )
        ?.map((permission) => {
          const moduleBasedPermissionsHeaderConfig = permission?.headerConfig
            ?.filter(
              (item) =>
                !item?.modules ||
                availableModule?.some((module) =>
                  item?.modules?.includes(module)
                )
            )
            ?.map((item) => t(item.text));
          const moduleBasedPermissionsDescriptionConfig =
            permission?.descriptionConfig
              ?.filter(
                (item) =>
                  !item?.modules ||
                  availableModule?.some((module) =>
                    item?.modules?.includes(module)
                  )
              )
              ?.map((item) => t(item.text));
          const headerSentence =
            Array.isArray(permission?.headerConfig) &&
            permission?.headerConfig?.length
              ? joinArrayOfSentences(moduleBasedPermissionsHeaderConfig, t)
              : "";
          const descriptionSentence =
            Array.isArray(permission?.descriptionConfig) &&
            permission?.descriptionConfig?.length
              ? joinArrayOfSentences(moduleBasedPermissionsDescriptionConfig, t)
              : "";
          return { ...permission, headerSentence, descriptionSentence };
        }),
    [roleDetails?.name, JSON.stringify(availableModule)]
  );

  const handleToggleSettings = (val, role) => {
    dispatch(
      toggleClientSetting({
        setting:
          role === ROLE_TITLE.DEPARTMENT_MANAGER
            ? "department_manager_edit_enabled"
            : "project_manager_edit_enabled",
        value: val,
      })
    );
  };

  return !isFetchingRoleDetails ? (
    <div className="slider-content-container">
      <div className="flex flex-col pb-10 slider-content-core gap-9">
        <div className="flex flex-col justify-center gap-5">
          <div className="flex flex-col justify-center gap-1">
            <Text
              translationKey={roleName}
              classes="text-3xl font-bold text-neutral-800 lowercase first-letter:uppercase"
              refProp={ref}
            />
            {isAssistantRole ? (
              <Text
                classes="text-sm text-neutral-500"
                translationKey="company.rolesPermissions.assistant.descriptionLong"
              />
            ) : PAYMENT_INITIATOR_MODULE[roleName] ? (
              <div className="flex flex-row items-center gap-1">
                <Text
                  translationKey={
                    PAYMENT_INITIATOR_MODULE[
                      isSettlementInitiator
                        ? PAYMENT_INITIATOR_ROLES.SETTLEMENT_INITIATOR
                        : PAYMENT_INITIATOR_ROLES.PAYMENT_INITIATOR
                    ]
                  }
                  classes="text-sm font-medium text-neutral-500 lowercase first-letter:capitalize"
                />
                <div className="flex flex-row items-end">
                  <Icon name="Dot" className="w-1 h-1 text-neutral-500" />
                </div>
                <Text
                  translationKey="company.rolesPermissions.roles.people"
                  translationProps={{ count: roleDetails?.totalPeople }}
                  classes="text-sm font-medium text-neutral-500"
                />
              </div>
            ) : (
              <Text
                translationKey="company.rolesPermissions.roles.people"
                translationProps={{ count: roleDetails?.totalPeople }}
                classes="text-sm font-medium text-neutral-500"
              />
            )}
          </div>
          {!isAssistantRole ? (
            <div className="w-full">
              <Text
                translationKey={roleDetails?.description}
                classes="text-base font-semibold text-neutral-500 break-words"
              />
            </div>
          ) : null}

          {isAssistantRole ? (
            <div className="flex items-center justify-between gap-4 mt-8 mb-8">
              <div className="flex flex-col">
                <Text
                  translationKey="company.rolesPermissions.assistant.accountSupportSwitch.title"
                  classes="text-base font-semibold text-neutral-800"
                />
                <Text
                  translationKey="company.rolesPermissions.assistant.accountSupportSwitch.description"
                  classes="text-sm font-semibold text-neutral-500"
                />
              </div>
              <div className="flex items-baseline">
                <Switch
                  name="volopayAssistantEnabled"
                  value={volopayAssistantEnabled}
                  handleChange={(e) => {
                    dispatch(
                      toggleClientSetting({
                        setting: VOLOPAY_ASSISTANT_TOGGLE.setting,
                        value: e,
                      })
                    );
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex flex-col w-full gap-9">
          {!isAssistantRole ? (
            <Tabs
              items={
                hidePeopleTab
                  ? ROLE_PERMISSIONS_TABS
                  : ROLE_PERMISSIONS_PEOPLE_TABS
              }
              selectedTab={selectedTab?.key}
              setCurrentTab={(tab) => {
                setSelectedTab(tab);
                setShowManagePeople(false);
              }}
              mode
              classes="!font-semibold"
            />
          ) : null}
          {selectedTab.key === 0 ? (
            roleDetails?.roleType === ROLE_TYPE_TITLE.CUSTOM ||
            isAssistantRole ? (
              <PermissionsSlider custom />
            ) : (
              <div className="flex flex-col justify-center gap-6">
                <div className="flex">
                  <Text
                    translationKey="company.rolesPermissions.tabs.permissions.description"
                    translationProps={{
                      name: roleName,
                    }}
                    classes="text-lg font-semibold text-neutral-800"
                  />
                </div>
                {permissions?.map((item, index) => (
                  <div
                    className="flex items-center gap-4 "
                    key={`${roleDetails?.name}-${index}-permissions`}
                  >
                    <div className="flex items-center">
                      <Icon
                        name={item?.icon}
                        className="w-6 h-6 text-primary-600"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Text
                        translationKey={item?.heading}
                        translationProps={{
                          heading: item?.headerSentence,
                        }}
                        classes="text-base first-letter:uppercase font-semibold text-neutral-800"
                      />
                      <Text
                        translationKey={item?.description}
                        translationProps={{
                          description: item?.descriptionSentence,
                        }}
                        classes="text-xs font-semibold text-neutral-500"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )
          ) : isAdmin ? (
            !showManagePeople ? (
              <div className="flex flex-col w-full gap-4">
                <div className="flex flex-row items-center justify-between">
                  <div>
                    <PeopleTableSearchAndFilter
                      filters={filters}
                      context={FILTER_TRIGGER_CONTEXT.SLIDER}
                      sliderConfig={{
                        sliderId:
                          SLIDERS_SEARCH_PARAMS.company.rolesPermissions.role,
                      }}
                    />
                  </div>

                  {canManagePeople ? (
                    <div className="flex justify-end">
                      <Button
                        label="company.department.departmentDetails.tabs.people.managePeopleLabel"
                        classes="w-fit px-5 !py-1.5 text-xs font-bold"
                        variant="tertiary"
                        onClick={() => setShowManagePeople(true)}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="flex flex-col gap-4 p-4 border rounded-lg border-neutral-200 bg-neutral-50 -z-10">
                  <Text
                    translationKey="company.rolesPermissions.tabs.people.listOfPeople"
                    translationProps={{ people: roleName }}
                    classes="text-sm font-semibold text-neutral-500"
                  />

                  {filteredPeople?.length ? (
                    <div className="flex flex-col gap-6">
                      {filteredPeople?.map((item, index) => (
                        <div
                          className="flex items-center"
                          key={`${item.name}-${index}-role`}
                        >
                          <div
                            ref={(reff) => {
                              if (
                                index === (filteredPeople?.length ?? 0) - 1 &&
                                hasMore
                              ) {
                                handleRefChange(reff);
                              }
                            }}
                          >
                            <ProfileWidget
                              avatarColor={item?.colorCode}
                              img={item?.avatarUrl}
                              className="w-fit"
                              name={item?.displayName}
                              textClasses=" text-transform: capitalize font-semibold text-base "
                            >
                              <div className="flex items-center gap-1 text-sm font-medium text-neutral-500">
                                <Text
                                  translationKey={item?.role[0]}
                                  classes="lowercase first-letter:capitalize"
                                />
                                <Icon name="Dot" className="w-1 h-1" />
                                <Text translationKey={item?.locationName} />
                              </div>
                            </ProfileWidget>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                {isFetchingPeople ? <RolesPermissionsLoader /> : null}
              </div>
            ) : (
              <EditAddPeopleSmartComponent
                type={
                  isSettlementInitiator
                    ? PAYMENT_INITIATOR_ROLES.SETTLEMENT_INITIATOR
                    : roleName
                }
                peoplePermission
                context={ROLE_PERMISSIONS_PEOPLE_TAB_PARAMS.PEOPLE} // to group admins and manager, context(any of [BILL_PAYROLL_CONTEXT.BILLPAY,REIMBURSEMENT_CONTEXT,QRPAY_EXPENSES_CONTEXT.EXPENSES]) is passed as required in the component
                showTitleAndDescription={false}
                showCancelButton
                shallow={false}
                useCancelForDiscardEditing
                handleCancel={() => {
                  setShowManagePeople(false);
                }}
              />
            )
          ) : null}
        </div>

        {selectedTab.key === 0 &&
        [ROLE_TITLE.DEPARTMENT_MANAGER, ROLE_TITLE.PROJECT_MANAGER].includes(
          roleDetails?.name
        ) ? (
          <div className="flex flex-col gap-6">
            <Text
              translationKey="company.rolesPermissions.tabs.permissions.additionalPermissions.title"
              classes="text-lg font-semibold text-neutral-800"
            />
            <div className="flex items-center gap-4">
              <Switch
                value={
                  roleDetails?.name === ROLE_TITLE.DEPARTMENT_MANAGER
                    ? departmentManagerEditEnabled
                    : projectManagerEditEnabled
                }
                handleChange={(val) => {
                  handleToggleSettings(val, roleDetails?.name);
                }}
              />
              <Text
                translationKey="company.rolesPermissions.tabs.permissions.additionalPermissions.description"
                translationProps={{ name: roleName }}
                classes="text-base font-semibold text-neutral-800"
              />
            </div>
          </div>
        ) : null}
      </div>
      {selectedTab.key === 0
        ? roleDetails?.name === capitalizeFirstLetter(ROLE_TITLE.ADMIN) && (
            <div className="px-6 py-4 slider-footer">
              <div className="flex items-center justify-end">
                <Button
                  label="company.rolesPermissions.tabs.permissions.viewAllPermissions"
                  classes="w-fit px-5 py-3 text-btn-lg font-semibold"
                  onClick={() => handleViewPermissions()}
                />
              </div>
            </div>
          )
        : null}

      {volopayAssistantEnabled && isAssistantRole ? (
        <div className="flex justify-end px-6 py-4 slider-footer">
          <Button
            label="company.rolesPermissions.buttonLabels.editPermissions"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            onClick={() => {
              handleEditPermissions();
            }}
          />
        </div>
      ) : null}

      {roleDetails?.ctas[0] === PERMISSIONS_CTA_CONFIG.EDIT_PERMISSIONS &&
      isAdmin &&
      selectedTab.key === 0 ? (
        <div className="px-6 py-4 slider-footer">
          <div className="flex items-center justify-end gap-6">
            <Button
              label="company.rolesPermissions.buttonLabels.deleteRole"
              classes="w-fit px-5 py-3 text-btn-lg font-semibold text-danger-500"
              variant="tertiary"
              onClick={handleDelete}
            />

            <Button
              label="company.rolesPermissions.buttonLabels.editPermissions"
              classes="w-fit px-5 py-3 text-btn-lg font-semibold"
              onClick={() => {
                handleEditPermissions();
              }}
            />
          </div>
        </div>
      ) : null}

      {showDeleteModal ? (
        <DeleteRoleModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
        />
      ) : null}
    </div>
  ) : (
    <Loader />
  );
}
