import { useRef, useState } from "react";

/**
 * What - useState but with reset to original state feature
 * Useful when - if an interaction is cancellable
 * Syntax - const [someState, setSomeState, clear, save]
 *
 * @param {any | Function} initState just like useState
 *
 * @returns
 *  @type {any} currentState
 *  @type {setCurrentState} currentState
 *  @type {Function} setCurrentState
 *  @type {Function} reset
 *  @type {Function} save
 */
export default function useStateResettable(initState) {
  const [currentState, setCurrentState] = useState(initState);
  const backupState = useRef(currentState);

  const reset = () => {
    setCurrentState(structuredClone(backupState.current));
  };

  const save = (_currentState = currentState) => {
    backupState.current = _currentState;
  };

  return [currentState, setCurrentState, reset, save];
}
