import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function DepartmentProjectCard({
  type,
  id,
  name,
  adminCount,
  memberCount,
  handleClick,
  projectType,
  policyId,
}) {
  const handleCardClick = () => {
    handleClick(policyId);
  };

  return (
    <div
      className="flex items-center justify-between p-4 border rounded-md cursor-pointer border-neutral-200"
      onClick={handleCardClick}
    >
      <div className="flex flex-col ">
        <span className="text-lg font-medium">
          <Text translationKey={name} />
        </span>
        <span className="text-sm font-light text-neutral-600">
          <Text
            translationKey="settings.customApprovalPolicies.departmentProjectCard.adminCountMemberCount"
            translationProps={{
              projectType,
              adminCount,
              memberCount,
            }}
          />
        </span>
      </div>
      <div>
        <Icon name="ArrowForward" className="hover:cursor-pointer shrink-0" />
      </div>
    </div>
  );
}

DepartmentProjectCard.propTypes = {
  type: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  adminCount: PropTypes.number,
  memberCount: PropTypes.number,
  handleClick: PropTypes.func,
  projectType: PropTypes.string,
  policyId: PropTypes.string,
};
