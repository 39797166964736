import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchCustomPolicy } from "@/store/reducers/company";

import {
  isFetchingDepartmentsSelector,
  isFetchingProjectsSelector,
  selectDepartmentsStore,
  selectProjectStore,
} from "@/store/selectors/company";

import Button from "@/components/core/Button";
import EmptyData from "@/components/core/EmptyData";
import Loader from "@/components/core/Loader";
import Note from "@/components/core/Note";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import DepartmentProjectCard from "@/components/Settings/DepartmentProjectCard";
import { MODULES } from "@/utils/constants/app";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";
import {
  CUSTOM_PAYLOAD_MAPPING,
  CUSTOM_SUBMISSION_POLICY_PARAM,
  POLICY_TYPE,
  REIMBURSEMENTS_TYPES_TABS,
} from "@/constants/policy";
import { CUSTOM_POLICY_TABS } from "@/constants/settings";

export default function CustomModule() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedReimbursementType, setSelectedReimbursementType] = useState(
    REIMBURSEMENTS_TYPES_TABS[0]
  );

  const policyType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.customPolicy
  );

  const submissionPolicy = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.customSubmissionModule
  );

  const claimPolicy = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.customClaimModule
  );

  const approvalPolicy = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.customApprovalModule
  );

  const [selectedTab, setSelectedTab] = useState(CUSTOM_POLICY_TABS[0]);
  const [currentPolicyType, setCurrentPolicyType] = useState(policyType);

  const types = {
    [POLICY_TYPE.CLAIM]: {
      title: "settings.sliders.claimPolicies.customClaimPolicies.title",
      description:
        "settings.sliders.claimPolicies.customClaimPolicies.description",
    },
    [POLICY_TYPE.APPROVAL]: {
      title: "settings.customApprovalPolicies.label",
      description: "settings.customApprovalPolicies.description",
    },
    [POLICY_TYPE.SUBMISSION]: {
      title: "settings.spendControls.customSubmissionPolicy.title",
      description: "settings.spendControls.customSubmissionPolicy.description",
    },
  };

  const changeReimbursementsPolicy = (type) => {
    setSelectedReimbursementType(type);
    searchParams.set(
      SLIDERS_SEARCH_PARAMS.settings.customSubmissionModule,
      type.id
    );
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const filterParams = {};

    if (claimPolicy) {
      filterParams.with_custom_claim_policies = true;
      setCurrentPolicyType(POLICY_TYPE.CLAIM);
    } else if (!currentPolicyType) {
      if (submissionPolicy) setCurrentPolicyType(POLICY_TYPE.SUBMISSION);
      else if (approvalPolicy) setCurrentPolicyType(POLICY_TYPE.APPROVAL);
    }

    const params = {
      ...filterParams,
      type: selectedTab.key === 0 ? "Department" : "Project",
    };

    if (approvalPolicy)
      params.policy_group_type = [CUSTOM_PAYLOAD_MAPPING[approvalPolicy]];
    else if (submissionPolicy)
      params.submission_policy_type = [
        CUSTOM_SUBMISSION_POLICY_PARAM[submissionPolicy],
      ];

    dispatch(fetchCustomPolicy(params));
  }, [selectedTab, selectedReimbursementType]);

  const data =
    useSelector(
      selectedTab.key === 0 ? selectDepartmentsStore : selectProjectStore
    ).list ?? [];

  const isFetching = useSelector(
    selectedTab.key === 0
      ? isFetchingDepartmentsSelector
      : isFetchingProjectsSelector
  );

  const handleClick = (id) => {
    if (currentPolicyType === POLICY_TYPE.CLAIM) {
      if (selectedTab.key === 0) {
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls
            .departmentID,
          id
        );
      } else {
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.projectID,
          id
        );
      }
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.claimPolicy,
        FORM_TYPE.preview
      );
    } else {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.settings[
          submissionPolicy ? "submissionModule" : "policyModule"
        ],
        submissionPolicy ? submissionPolicy : approvalPolicy
      );

      searchParams.append(SLIDERS_SEARCH_PARAMS.settings.policyId, id);
    }
    setSearchParams(searchParams);
  };

  const ref = useLeftHeaderTitle({ title: types[currentPolicyType]?.title });

  return (
    <div className="slider-content-core pb-7">
      <div className="flex flex-col gap-1" ref={ref}>
        <Text
          classes="text-2xl font-semibold"
          translationKey={types[currentPolicyType]?.title}
        />
        <Text
          classes="text-sm text-neutral-500"
          translationKey={types[currentPolicyType]?.description}
        />
      </div>
      <div className="mt-10 mb-8">
        <Note
          titleText="settings.customApprovalPolicies.alertTitle"
          descriptionText="settings.customApprovalPolicies.alertDescription"
          backgroundColorClass="bg-neutral-50"
          borderColorClass="border border-neutral-300 border-solid"
        />
      </div>
      {submissionPolicy &&
      [MODULES.REIMBURSEMENTS, MODULES.OUT_OF_POCKET, MODULES.MILEAGE].includes(
        submissionPolicy
      ) ? (
        <div className="flex">
          {REIMBURSEMENTS_TYPES_TABS.map((type) => {
            return (
              <Button
                key={type}
                label={<Text translationKey={type.name} />}
                classes="w-16 text-sm mr-4 my-6"
                variant={
                  selectedReimbursementType === type ? "primary" : "tertiary"
                }
                onClick={() => changeReimbursementsPolicy(type)}
              />
            );
          })}
        </div>
      ) : null}
      <Tabs
        items={CUSTOM_POLICY_TABS}
        selectedTab={selectedTab.key}
        setCurrentTab={(tab) => setSelectedTab(tab)}
        mode
      />

      <div className="mt-4">
        {isFetching ? (
          <Loader />
        ) : data.length ? (
          data?.map((option, index) => (
            <div id={option?.id} className="mt-4" key={index}>
              <DepartmentProjectCard
                type="department-project"
                id={option?.id}
                projectType={option?.type}
                name={option?.name}
                adminCount={option?.userCount?.admins}
                memberCount={option?.userCount?.members}
                policyId={claimPolicy ? option?.id : option?.policyId}
                handleClick={handleClick}
              />
            </div>
          ))
        ) : (
          <EmptyData
            title={`${
              claimPolicy
                ? "settings.spendControls.claimPolicy.emptyTitle"
                : submissionPolicy
                  ? "settings.spendControls.customSubmissionPolicy.emptyTitle"
                  : "settings.customApprovalPolicies.emptyTitle"
            }`}
            description={`${
              claimPolicy
                ? "settings.spendControls.claimPolicy.emptyDesc"
                : submissionPolicy
                  ? "settings.spendControls.customSubmissionPolicy.emptyDesc"
                  : "settings.customApprovalPolicies.emptyDesc"
            }`}
            imgSrc="emptyScreen.svg"
            classes="mt-14"
          />
        )}
      </div>
    </div>
  );
}
