import PropTypes from "prop-types";
import { useEffect, useState } from "react";

/**
 * Displays data as a horizontal stacked bar with multiple ranges
 *
 * @param {Array} data - List containing the data to render
 * @param {Number} from - The starting number in the bar,representing a specific range
 * @param {Number} to   - The ending number in the bar,representing a specific range
 * @param {Array<String>} colors - An array of colors to represent multiple ranges
 *
 */
export default function RangeBar({
  data,
  from,
  to,
  colors = [
    "bg-integrations-500",
    "bg-accounts-payable-500",
    "bg-expense-management-500",
    "bg-corporate-cards-500",
    "bg-accounting-500",
  ],
}) {
  const labels = [...data];
  labels.push("∞");

  const getBorderStyle = (id) => {
    let border = "";
    if (id === 0) border = "rounded-s-lg";
    if (id === data.length - 1) border += " rounded-e-lg";
    return border;
  };

  return !from && !to ? (
    <>
      <div className="flex items-center justify-between">
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className={`h-[10px] w-full ${colors[index]} ${getBorderStyle(
                index
              )}`}
            />
          );
        })}
      </div>
      <div className="flex items-center justify-between">
        {labels.map((label, index) => {
          return <span key={index}>{label}</span>;
        })}
      </div>
    </>
  ) : (
    <div className="flex flex-col">
      <div
        className={`h-[10px] w-full ${colors[data.indexOf(from)]} rounded-lg`}
      />
      <div className="flex items-center justify-between mt-1">
        <span>{from}</span>
        <span>{to || "∞"}</span>
      </div>
    </div>
  );
}

RangeBar.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.number),
  colors: PropTypes.arrayOf(PropTypes.string),
};
