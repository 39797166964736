import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { updateTagAndVendor } from "@/store/reducers/accounting_transactions";

import {
  accountingIntegrationSoftwareSelector,
  clientSelector,
} from "@/store/selectors/client";
import {
  accountingTagsSelector,
  billPayCustomTagsSelector,
} from "@/store/selectors/tags";

import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import TagInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/TagInput";
import { INTEGRATION_TAGS_TYPE } from "@/utils/constants/integrations";
import { amountToCurrency, debounce } from "@/utils/common";

import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_PAYMENTS_TAG_TYPES,
  ACCOUNTING_TRANSACTION_PAGES,
} from "@/constants/accounting";
import { TAG_FIELD_TYPES } from "@/constants/tags";

export default function AccountingTransactionLineItemsTable({
  payment,
  editableState,
  context,
}) {
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );
  const accountingEnabled = !!accountingIntegrationSoftware;
  const accountingTags = useSelector(accountingTagsSelector);

  const customTags = useSelector(billPayCustomTagsSelector);

  const accountingId = parseInt(
    searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.billPay.id),
    10
  );

  const transactionLevelTags =
    useSelector(clientSelector)?.transactionLevelTags;

  const netsuiteLineItemLevelTags = accountingTags?.filter(
    (nonCategoryAccountingTag) => {
      const netsuiteTransactionLevelTagIds = transactionLevelTags?.map(
        (transactionLevelTag) => transactionLevelTag?.id
      );
      return !netsuiteTransactionLevelTagIds?.includes(
        nonCategoryAccountingTag?.id
      );
    }
  );

  const accountingLineItemTags = netsuiteLineItemLevelTags?.length
    ? netsuiteLineItemLevelTags
    : accountingTags;

  const newVendor = {
    id: -1,
    name: payment?.vendor?.name,
    alias: payment?.vendor?.name,
  };
  const handleUpdateTagsAndVendor = ({
    type = "tags",
    params,
    isText = false,
    isDate = false,
  }) => {
    let payload = {
      accounting_id: accountingId,
      line_item_id: params?.lineItemId,
      accountable_type: inPayrollContext
        ? ACCOUNTING_TRANSACTION_PAGES.PAYROLL
        : ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL,
      transaction_type: ACCOUNTING_PAYMENTS_TAG_TYPES.LINE_ITEM,
    };

    payload =
      type === INTEGRATION_TAGS_TYPE.VENDORS
        ? {
            ...payload,
            accounting_payee_id:
              params?.id === newVendor.id ? null : params?.id,
          }
        : {
            ...payload,
            tag_id: params?.tagId || params?.id,
            ...(isText || isDate
              ? {
                  custom_text_value: isText
                    ? params?.currentTextValue
                    : params?.currentDateValue,
                }
              : { tag_value_id: params?.id }),
          };

    if (isText) {
      debouncedUpdate({ payload });
    } else dispatch(updateTagAndVendor({ payload }));
  };

  const debouncedUpdate = debounce(
    (param) => (param ? dispatch(updateTagAndVendor(param)) : null),
    500
  );

  return (
    <Table
      colWidths={[
        70,
        300,
        150,
        ...Array(accountingLineItemTags?.length ?? 0).fill(250),
        ...Array(customTags?.length ?? 0).fill(250),
      ]}
    >
      <tr className="text-xs font-semibold text-left text-neutral-800">
        <th>
          <Text translationKey="company.billing.slider.invoiceDetails.table.slNo" />
        </th>
        <th>
          <Text translationKey="company.billing.slider.invoiceDetails.table.description" />
        </th>
        <th>
          <Text translationKey="company.billing.slider.invoiceDetails.table.amount" />
        </th>
        {accountingLineItemTags?.map((tag, index) => (
          <th key={`billpay-invoice-details-table-${index}-${tag?.id}`}>
            <Text translationKey={tag?.name} />
          </th>
        ))}

        {customTags?.map((tag, index) => (
          <th key={`billpay-invoice-details-table-${index}-${tag?.id}`}>
            <Text translationKey={tag?.name} />
          </th>
        ))}
      </tr>
      {payment?.lineItems?.map((item, index) => (
        <tr
          key={`accounting-transactions-billpay-invoice-items-details-${index}`}
        >
          <td className="w-12">
            <Text
              translationKey={index + 1}
              classes="text-sm font-semibold text-neutral-800"
            />
          </td>
          <td>
            <Text
              translationKey={item?.description}
              classes="text-sm font-semibold text-neutral-800"
            />
          </td>
          <td>
            <Text
              translationKey={amountToCurrency(
                item?.amount?.value,
                payment?.quote?.toCurrency
              )}
              classes="text-sm font-semibold text-neutral-800"
            />
          </td>

          {accountingLineItemTags?.map((tagItem, idx) => {
            const currentTag = accountingLineItemTags[idx];

            const selectedAccountingTag = item?.accountingTags?.find(
              (tag) => tag?.tagId === currentTag?.id
            );

            return (
              <td
                key={`acounting-transaction-line-item-table-tag-${idx}-${tagItem?.id}`}
                className="text-left"
              >
                <VpSelect
                  key={`billpay-invoice-details-accounting-tag-${idx}`}
                  label="Select"
                  placeholder="Select"
                  options={tagItem?.options}
                  optionsDisplayKey="alias"
                  valueKey="id"
                  hideLabelAfterSelect
                  value={selectedAccountingTag?.tagValueId}
                  handleChange={(params) => {
                    handleUpdateTagsAndVendor({
                      params: { ...params, lineItemId: item?.id },
                    });
                  }}
                  menuPosition="absolute"
                  classes="grid grid-cols-1"
                  disabled={!editableState}
                />
              </td>
            );
          })}

          {customTags?.map((tag, idx) => {
            const currentTag = customTags[idx];

            const selectedCustomTag = item?.customTags?.find(
              (tagItem) => tagItem?.tagId === currentTag?.id
            );

            const selectedCustomTagOptions = [...(tag?.options ?? [])];
            const isText = tag?.fieldType === TAG_FIELD_TYPES.TEXT;
            const isDate = tag?.fieldType === TAG_FIELD_TYPES.DATE;
            const selectedCustomTagValue =
              isText || isDate
                ? selectedCustomTag?.customTextValue
                : selectedCustomTag?.tagValueId;

            return (
              <td
                key={`acounting-transaction-line-item-table-tag-${idx}-${tag?.id}`}
                className="text-left"
              >
                <TagInput
                  tag={tag}
                  values={selectedCustomTagValue}
                  isDirectValue
                  wantValueOnBlur
                  vpSelectProps={{
                    label: "",
                    placeholder: "",
                    noborder: true,
                  }}
                  inputProps={{
                    label: "",
                  }}
                  disabled={!editableState}
                  inputClasses="border-none"
                  vpSelectClasses="text-xs "
                  options={selectedCustomTagOptions}
                  menuPosition="absolute"
                  dropdownInputKey="tagValueId"
                  handleChange={(params) => {
                    handleUpdateTagsAndVendor({
                      ...(isText || isDate
                        ? {
                            params: {
                              ...(isText
                                ? { currentTextValue: params?.textValue }
                                : {}),
                              ...(isDate
                                ? { currentDateValue: params?.target?.value }
                                : {}),
                              ...tag,
                              lineItemId: item?.id,
                            },
                          }
                        : {
                            params: { ...params, lineItemId: item?.id },
                          }),
                      isText,
                      isDate,
                    });
                  }}
                />
              </td>
            );
          })}
        </tr>
      ))}
    </Table>
  );
}

AccountingTransactionLineItemsTable.propTypes = {
  payment: PropTypes.object,
  editableState: PropTypes.bool,
  context: PropTypes.string,
};
