import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { isFetchingRoleDetailsSelector } from "@/store/selectors/company";

import Loader from "@/components/core/Loader";

import RolesPermissionsCheckbox from "@/components/Company/common/RolesPermissionsCheckbox";
import RolesPermissionsSwitch from "@/components/Company/common/RolesPermissionsSwitch";

import {
  PERMISSIONS_ACCESSIBILITY_TYPE,
  SETTING_PERMISSIONS_ACTION_TYPE,
} from "@/constants/company";

export default function SetPermissions({
  permissionKey,
  permissions,
  setPermissions,
  selectedPermissionList,
  setSelectedPermissionList,
  disabled,
  type,
  isViewPermissions = false,
  fromAssistantRole = false,
}) {
  const [parentKey, setParentKey] = useState(null);
  const isFetching = useSelector(isFetchingRoleDetailsSelector);
  useEffect(() => {
    enableParentPermissionSwitch();
  }, [selectedPermissionList]);

  const checkMultiplePermissions = (arr, key) => {
    const withNoDuplicate = new Set();
    return (arr || []).some((item) => {
      if (withNoDuplicate.has(item[key])) {
        return true;
      }
      withNoDuplicate.add(item[key]);
      return false;
    });
  };

  const enableParentPermissionSwitch = (val) => {
    setPermissions((prev) => {
      const updatedList = { ...prev };
      Object.entries(updatedList)?.forEach(([key, value]) => {
        if (key === parentKey) {
          const anyPermissionSelected = selectedPermissionList
            .filter((permission) => permission?.parent === parentKey)
            .some((item) => !!item.id);
          updatedList[parentKey].default = anyPermissionSelected;
        }
      });
      return updatedList;
    });
  };

  const updateSelectedPermissionList = (permission, key) => {
    if (type === SETTING_PERMISSIONS_ACTION_TYPE.EDIT)
      if (
        selectedPermissionList?.find(
          (item) => item?.permissionId === permission?.id
        )
      ) {
        setSelectedPermissionList((prev) =>
          prev.filter((item) => {
            if (item?.permissionId !== permission?.id)
              return { ...item, accessibility: item?.accessibility };
          })
        );
      } else {
        setSelectedPermissionList((prev) => [
          ...prev,
          {
            id: permission.id + 1,
            permissionId: permission?.id,
            accessibility: permission?.accessibility,
            name: permission?.name,
            parent: key,
            newAddition: true,
          },
        ]);
      }
    else if (
      selectedPermissionList?.find((item) => item?.id === permission?.id)
    ) {
      setSelectedPermissionList((prev) =>
        prev.filter((item) => {
          if (item?.id !== permission?.id)
            return { ...item, accessibility: item?.accessibility };
        })
      );
    } else {
      setSelectedPermissionList((prev) => [
        ...prev,
        {
          id: permission?.id,
          name: permission?.name,
          accessibility: permission?.accessibility,
          parent: key,
        },
      ]);
    }
  };

  return !isFetching ? (
    <div className="flex flex-col justify-center gap-6">
      {Object.entries(permissions)?.map(([key, value]) => {
        if (key === permissionKey) {
          return value?.permissions?.map((permission, index) => {
            if (value?.permissions?.length === 1) {
              return (
                <div
                  className="flex flex-col justify-center gap-2"
                  key={`${index}-set-permission-${permission?.id}`}
                >
                  <RolesPermissionsSwitch
                    permission={permission}
                    setPermissions={setPermissions}
                    selectedPermissionList={selectedPermissionList}
                    updateSelectedPermissionList={updateSelectedPermissionList}
                    componentKey={key}
                    setParentKey={setParentKey}
                    disabled={disabled}
                    type={type}
                  />
                </div>
              );
            }
            if (
              value?.permissions?.length > 1 &&
              checkMultiplePermissions(value?.permissions, "name")
            ) {
              if (
                !permission.isEditOnly &&
                permission.accessibility ===
                  PERMISSIONS_ACCESSIBILITY_TYPE.VIEW &&
                isViewPermissions
              ) {
                return (
                  <Fragment key={`view-${index}`}>
                    <RolesPermissionsSwitch
                      permission={permission}
                      setPermissions={setPermissions}
                      selectedPermissionList={selectedPermissionList}
                      updateSelectedPermissionList={
                        updateSelectedPermissionList
                      }
                      componentKey={key}
                      setParentKey={setParentKey}
                      disabled={disabled}
                      type={type}
                    />
                    <RolesPermissionsCheckbox
                      permission={permission}
                      selectedPermissionList={selectedPermissionList}
                      updateSelectedPermissionList={
                        updateSelectedPermissionList
                      }
                      componentKey={key}
                      setParentKey={setParentKey}
                      disabled={disabled}
                      type={type}
                    />
                  </Fragment>
                );
              }
              return (
                <Fragment key={`edit-${index}`}>
                  {permission.accessibility ===
                  PERMISSIONS_ACCESSIBILITY_TYPE.EDIT ? (
                    <RolesPermissionsSwitch
                      permission={permission}
                      permissionList={value?.permissions}
                      setPermissions={setPermissions}
                      selectedPermissionList={selectedPermissionList}
                      updateSelectedPermissionList={
                        updateSelectedPermissionList
                      }
                      componentKey={key}
                      setParentKey={setParentKey}
                      disabled={disabled}
                      type={type}
                    />
                  ) : !permission.isEditOnly ? (
                    <RolesPermissionsCheckbox
                      permission={permission}
                      selectedPermissionList={selectedPermissionList}
                      updateSelectedPermissionList={
                        updateSelectedPermissionList
                      }
                      componentKey={key}
                      setParentKey={setParentKey}
                      disabled={fromAssistantRole}
                      type={type}
                    />
                  ) : null}
                </Fragment>
              );
            }
            return (
              <div
                className="flex flex-col justify-center gap-2"
                key={`${index}-permission-${permission?.id}`}
              >
                <RolesPermissionsSwitch
                  permission={permission}
                  setPermissions={setPermissions}
                  selectedPermissionList={selectedPermissionList}
                  updateSelectedPermissionList={updateSelectedPermissionList}
                  componentKey={key}
                  setParentKey={setParentKey}
                  disabled={disabled}
                  type={type}
                />
              </div>
            );
          });
        }
      })}
    </div>
  ) : (
    <Loader />
  );
}

SetPermissions.propTypes = {
  permissionKey: PropTypes.string,
  permissions: PropTypes.object,
  setPermissions: PropTypes.func,
  selectedPermissionList: PropTypes.object,
  setSelectedPermissionList: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isViewPermissions: PropTypes.string,
  fromAssistantRole: PropTypes.bool,
};
