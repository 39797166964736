import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { payInvoice } from "@/store/reducers/company";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { dateToString } from "@/utils/common";

import { BE_CTA_RESPONSE } from "@/constants/user";

const InvoiceBox = ({ upcomingDetails, pendingDetails, openSliderHandler }) => {
  const dispatch = useDispatch();

  const INVOICE_BOX_DETAILS = {
    upcomingInvoice: {
      id: upcomingDetails?.id,
      title: "company.billing.upcomingInvoice.title",
      subTitle: "company.billing.upcomingInvoice.subTitle",
      amount: upcomingDetails?.totalAmount.value,
      currency: upcomingDetails?.totalAmount.currency,
      upcomingDateTitle:
        "company.billing.upcomingInvoice.upcomingGenerationDateTitle",
      upcomingDateValue: upcomingDetails?.billingDate,
      invoiceNumber: upcomingDetails?.invoiceNumber,
      showUpcomingDate: true,
      showBadge: false,
    },
    pendingInvoice: {
      id: pendingDetails?.id,
      title: "company.billing.pendingInvoice.title",
      subTitle: "company.billing.upcomingInvoice.subTitle",
      amount: pendingDetails?.totalAmount.value,
      currency: pendingDetails?.totalAmount.currency,
      dueDateTitle: "company.billing.pendingInvoice.dueDateTitle",
      dueDateValue: pendingDetails?.dueDate,
      invoiceNumber: pendingDetails?.invoiceNumber,
      showUpcomingDate: false,
      showBadge: true,
    },
  };

  const showPayNowCta = pendingDetails?.ctas?.some(
    (cta) => cta === BE_CTA_RESPONSE.PAY_NOW
  );

  const createInvoiceBoxData = (upcomingInvoice, pendingInvoice) => {
    const result = [upcomingInvoice];

    if (pendingDetails !== undefined) {
      result.push(pendingInvoice);
    }
    return result;
  };
  const invoiceBoxData = createInvoiceBoxData(
    INVOICE_BOX_DETAILS.upcomingInvoice,
    INVOICE_BOX_DETAILS.pendingInvoice
  );
  return (
    <>
      {invoiceBoxData.map((data, index) => {
        return (
          <div className="flex flex-col flex-auto gap-4" key={index}>
            <Text
              classes="text-base font-bold text-neutral-500"
              translationKey={data.title}
            />
            <div className="flex flex-col justify-between gap-4 p-0 h-19 card-wrapper">
              <div className="flex items-center justify-between px-5 pt-5">
                <div>
                  <div className="flex items-center gap-2">
                    <Text
                      translationKey={data.subTitle}
                      classes="text-base text-neutral-500"
                    />
                  </div>
                  <div className="flex items-baseline gap-1">
                    <span className="text-3xl font-bold text-neutral-800">
                      {data.amount}
                    </span>
                    <span className="pl-1 text-xl font-bold text-neutral-500">
                      {data.currency}
                    </span>
                    <span>
                      {data.showBadge ? (
                        <Badge
                          classes="w-14 "
                          variant="warning"
                          translationKey="company.billing.pendingInvoice.badgeLabel"
                        />
                      ) : null}
                    </span>
                  </div>
                </div>
                <div>
                  {data.showUpcomingDate ? (
                    <div className="flex flex-col items-center justify-center gap-2">
                      <div className="flex justify-center items-center p-[14.5px] h-10 w-10 rounded-full bg-primary-50">
                        <Icon
                          name="ArrowForward"
                          className="text-primary-500"
                          handleClick={() => openSliderHandler(data.id)}
                        />
                      </div>
                      <Text
                        classes="font-semibold text-xs text-neutral-500"
                        translationKey="common.viewDetails"
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="flex items-center justify-end gap-2">
                        <Text
                          translationKey="company.billing.pendingInvoice.dueDateTitle"
                          classes=" text-neutral-500"
                        />
                      </div>
                      <div>
                        <span className="text-3xl font-bold text-neutral-800">
                          {dateToString(data.dueDateValue)}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="px-5 pb-5">
                {data.showUpcomingDate ? (
                  <div className="flex items-center justify-between p-4 rounded-md bg-neutral-50">
                    <Text
                      classes="text-base font-normal text-neutral-500"
                      translationKey="company.billing.upcomingInvoice.upcomingGenerationDateTitle"
                    />
                    <span className="pl-1 text-base font-semibold text-neutral-800">
                      {dateToString(data.upcomingDateValue)}
                    </span>
                  </div>
                ) : (
                  <div className="flex">
                    <Button
                      classes="text-neutral-500 font-normal w-full"
                      label="company.billing.pendingInvoice.buttonLabel"
                      size="md"
                      variant="tertiary"
                      onClick={() => openSliderHandler(data.id)}
                    />
                    {showPayNowCta ? (
                      <Button
                        classes="ml-2 font-normal w-full"
                        label="common.payNow"
                        size="md"
                        variant="primary"
                        onClick={() => dispatch(payInvoice({ id: data.id }))}
                      />
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default InvoiceBox;
InvoiceBox.propTypes = {
  upcomingDetails: PropTypes.object,
  pendingDetails: PropTypes.object,
  openSliderHandler: PropTypes.func,
};
