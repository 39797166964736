import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  cardRequestDetailsIsErrorSelector,
  cardRequestDetailsIsFetchingSelector,
} from "@/store/selectors/cards";

import OverviewCardsSection from "@/components/common/OverviewCardsSection";
import { capitalizeFirstLetter, dateToString } from "@/utils/common";

import { CARD_TYPE, CARD_USAGE_TYPES } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function CardLimitRequestOverviewCards({
  cardName,
  autoBlockDate,
  cardHolder,
  cardNumber,
  linkedTo,
  project,
  cardType,
  cardUsageType,
}) {
  const { t } = useTranslation();
  const nonFormattedBlockingDate = autoBlockDate;
  const blockingDate = nonFormattedBlockingDate
    ? dateToString(nonFormattedBlockingDate)
    : "-";
  const isError = useSelector(cardRequestDetailsIsErrorSelector);

  const isFetching = useSelector(cardRequestDetailsIsFetchingSelector);

  const cardHolderName = cardHolder?.displayName;

  const cardHolderRole = cardHolder?.roles?.[0] || "";
  const cardHolderDepartment = cardHolder?.departmentName;
  const cardHolderLocationName = cardHolder?.locationName;

  const cardTypeText = t(`filters.cardType.options.${cardType}`);

  const trimmedCardNumber = cardNumber ? cardNumber.slice(-4) : "";
  const [searchParams, setSearchParams] = useSearchParams();
  const linkedToText =
    project?.name && linkedTo
      ? `${capitalizeFirstLetter(linkedTo)}: ${project?.name}`
      : "";
  const handleClick = (obj) => {
    if (obj?.searchParam && obj?.searchParamValue) {
      searchParams.append(obj?.searchParam, obj?.searchParamValue);
      setSearchParams(searchParams);
    }
  };

  const CONFIG = [
    {
      rows: [
        {
          id: "card-holder-row-1",
          text: {
            id: "card-holder-title",
            translationKey: "Cardholder",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6 ",
          },
        },
        {
          id: "card-holder-row-2",
          text: {
            id: "card-holder-value",
            translationKey: cardHolderName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
          icon: {
            searchParam: SLIDERS_SEARCH_PARAMS.company.people.id,
            searchParamValue: cardHolder?.id,
            name: "NEArrow",
            type: "icon",
            handleClick,
          },
        },
        {
          renderArray: [
            { label: cardHolderRole },
            {
              label: cardHolderDepartment,
              showTooltip: cardHolderDepartment?.length > 18,
              tooltipClasses: "w-14.75",
            },
            {
              label: cardHolderLocationName,
              showTooltip: cardHolderLocationName?.length > 18,
              tooltipClasses: "w-14.75",
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          id: "card-type-row-1",
          text: {
            id: "card-type-title",
            translationKey: `${cardTypeText} ${
              trimmedCardNumber ? `• ${trimmedCardNumber}` : ""
            }`,
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6",
          },
        },
        {
          id: "card-holder-row-2",
          text: {
            id: "card-type-value",
            translationKey:
              cardType === CARD_TYPE.PHYSICAL
                ? cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
                  ? t(
                      "myVolopay.actionCentre.sliders.requestSliders.limit.globalCard"
                    )
                  : cardHolder?.displayName
                : cardName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
          icon: {
            searchParam: SLIDERS_SEARCH_PARAMS.cards.id,
            searchParamValue: null,
            name: "NEArrow",
            type: "icon",
            handleClick,
          },
        },
      ],
    },
    ...(CARD_TYPE.VIRTUAL === cardType
      ? [
          {
            rows: [
              {
                id: "auto-block-date-row-1",
                text: {
                  id: "auto-block-date-title",
                  translationKey: "Auto-block date",
                  color: "neutral-500",
                  classes: "text-sm not-italic font-semibold leading-6",
                },
                icon: {
                  id: "auto-block-date-icon",
                  name: "Info",
                  type: "icon",
                  className: "text-neutral-700",
                },
              },

              {
                id: "auto-block-date-row-1",
                text: {
                  id: "auto-block-date-value",
                  translationKey: blockingDate,
                  color: "neutral-800",
                  classes: "text-base not-italic font-semibold leading-6 ",
                },
              },
            ],
          },
        ]
      : []),
    {
      rows: [
        linkedToText
          ? {
              id: "auto-block-date-row-1",
              text: {
                id: "linked-to-title",
                translationKey: "Linked to",
                color: "neutral-500",
                classes: "text-sm not-italic font-semibold leading-6",
              },
            }
          : null,
        linkedToText
          ? {
              id: "auto-block-date-row-1",
              text: {
                id: "linked-to-value",
                translationKey: linkedToText,
                color: "neutral-800",
                classes: "text-base not-italic font-semibold leading-6 ",
                className: "w-full",
              },
              icon: {
                searchParam: linkedTo
                  ? SLIDERS_SEARCH_PARAMS.company?.[linkedTo?.toLowerCase()]?.id
                  : null,
                searchParamValue: project?.id,
                name: "NEArrow",
                type: "icon",
                handleClick,
              },
            }
          : null,
      ].filter(Boolean),
    },
  ].filter((item) => item?.rows?.length);

  return (
    <div>
      {!isError && !isFetching ? (
        <div className="mt-5">
          <OverviewCardsSection config={CONFIG} />
        </div>
      ) : null}
      {isError ? "Error" : null}
      {isFetching ? "Fetching..." : null}
    </div>
  );
}

export default CardLimitRequestOverviewCards;

CardLimitRequestOverviewCards.propTypes = {
  cardName: PropTypes.string,
  autoBlockDate: PropTypes.string,
  cardHolder: PropTypes.object,
  cardNumber: PropTypes.string,
  cardType: PropTypes.string,
  linkedTo: PropTypes.object,
  project: PropTypes.object,
  cardUsageType: PropTypes.string,
};
