import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchAccountingVendors,
  getTallyAccessToken,
} from "@/store/reducers/accounting";
// reducers
import { fetchClient } from "@/store/reducers/client";
import { fetchTags } from "@/store/reducers/tags";

import { tallyAccessTokenPayloadSelector } from "@/store/selectors/accounting";
// selectors
import { clientSelector } from "@/store/selectors/client";
import { getAccountingIntegrationsToastMessage } from "@/components/Accounting/Integrations/common/utils";

// core components
import Button from "@/components/core/Button";
import CopytoClipboard from "@/components/core/CopyToClipboard";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import {
  DOWNLOAD_MODES,
  DOWNLOAD_PLUGIN_TOAST,
  INTEGRATION_TALLY_PLUGIN_URL,
  INTEGRATION_TALLY_TUTORIAL_VIDEO_URL,
} from "@/utils/constants/integrations";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function TallyConnectSlider() {
  const dispatch = useDispatch();
  const [isPluginSet, setIsPlugInSet] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const tallyPayload = useSelector(tallyAccessTokenPayloadSelector);
  const client = useSelector(clientSelector);

  const handlePluginDownload = async () => {
    window.open(INTEGRATION_TALLY_PLUGIN_URL, "_blank");
    setIsPlugInSet(true);
    getAccountingIntegrationsToastMessage(
      DOWNLOAD_PLUGIN_TOAST[DOWNLOAD_MODES.download]
    );
  };

  useEffect(() => {
    dispatch(getTallyAccessToken());
  }, []);

  const handleContinue = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.integrations.software);
    setSearchParams(searchParams);
    dispatch(fetchClient());
    dispatch(fetchTags({ visible: true }));
    dispatch(fetchAccountingVendors());
  };

  return (
    <div className="flex flex-col gap-11.5">
      <div className="px-10 flex flex-col gap-8 slider-content-container">
        <SliderHeader
          heading="accounting.integrations.tally.setup.title"
          headingClasses="text-4xl font-bold text-neutral-800 "
          refFlag
        />
        <div className="flex flex-col gap-9 ">
          <div className="flex flex-col gap-6 ">
            {!isVideoPlaying ? (
              <div className="w-full h-61 bg-neutral-100 bg-blend-overlay flex items-center justify-center rounded-lg ">
                <Icon
                  name="PlayCircle"
                  className="cursor-pointer"
                  handleClick={() => {
                    setIsVideoPlaying(true);
                  }}
                />
              </div>
            ) : (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video
                width="750"
                height="500"
                controls
                className="rounded-lg"
                onEnded={() => setIsVideoPlaying(false)}
              >
                <source
                  src={INTEGRATION_TALLY_TUTORIAL_VIDEO_URL}
                  type="video/mp4"
                />
              </video>
            )}

            <div className="flex flex-col items-center justify-center">
              <Button
                variant="tertiary"
                label="accounting.integrations.buttonLabel.downloadPlugin"
                preIcon="Download"
                classes="rounded-lg w-fit px-5 py-3 text-base font-semibold"
                onClick={handlePluginDownload}
              />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <Text
                translationKey="accounting.integrations.tally.setup.tallyKey"
                classes="text-neutral-800 text-lg font-semibold"
              />
              <Text
                translationKey="accounting.integrations.tally.setup.description"
                classes="text-neutral-500 text-sm font-medium"
              />
            </div>

            <div className="flex items-center justify-between p-4 border border-neutral-200 gap-4 rounded-lg">
              <Text
                translationKey={tallyPayload?.secret}
                classes="w-auto text-neutral-500 text-sm"
              />

              <CopytoClipboard
                text={tallyPayload?.secret}
                iconClasses="w-6 h-6 text-neutral-500"
              />
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <Text
              translationKey="accounting.integrations.tally.setup.integrateTalley.label"
              classes="text-base font-semibold text-neutral-500"
            />
            <div className="rounded-xl border border-neutral-300 bg-neutral-50 p-4">
              <Text
                translationKey="accounting.integrations.tally.setup.integrateTalley.descriptionList.label"
                classes="text-base font-semibold text-neutral-800 "
              />
              <ul className="text-neutral-500 list-disc px-4">
                <li>
                  <Text translationKey="accounting.integrations.tally.setup.integrateTalley.descriptionList.listItem1" />
                </li>
                <li>
                  <Text translationKey="accounting.integrations.tally.setup.integrateTalley.descriptionList.listItem2" />
                </li>
                <li>
                  <Text translationKey="accounting.integrations.tally.setup.integrateTalley.descriptionList.listItem3" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-footer sticky p-6 ">
        <div className="flex justify-end ">
          <Button
            label="accounting.integrations.buttonLabel.continue"
            classes="w-14.75 px-5 py-3 text-btn-lg font-semibold"
            disabled={!isPluginSet}
            onClick={() => {
              handleContinue();
            }}
            id={`tally-connect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
          />
          {!isPluginSet ? (
            <Tooltip
              direction="top"
              id={`tally-connect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
            >
              <Text translationKey="accounting.integrations.toolTipText.downloadPluginMandatory" />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}
