export const BILL_FORM_MODES = {
  CREATE: "create",
  EDIT: "edit",
  OCR: "ocr",
};
export const ACC_VALUE = "acc_";

export const BILL_FORM_EDIT_MODES = {
  // default is fully editable
  DUPLICATE_ID: "duplicateId",
};

// structure for frontend enums used in line items code
export const LINE_ITEM_KEY = "lineItem";
export const LINE_ITEM_KEYS = {
  FEID: "FEid", // meant for frontend, behaves like index, for managing line item list in UI
  BEID: "id", // by, for backend
  _DESTROY: "_destroy",
  AMOUNT: "amount",
  ACCOUNTING_DESCRIPTION: "accountingDescription",
  ACCOUNTING_CATEGORY_TAG: "accountingCategoryTag",
  ACCCOUNTING_TAGS: "accountingTags", // accountingTag.myAccuralTag : optionValue
  DESCRIPTION: "description", // used if accounting software is absent
  IS_ARTIFICIAL: "isArtificial", // frontend only thing
  TAX: "taxes", // to avoid confusion if this reaches the BE
};

// (at line item level is OFF or no applicables)r
export const TAX_KEYS = {
  OPTIONAL_ENABLED: "OPTIONAL_ENABLED",
};
// for GST/PPN (with or without PPnBM)
export const TAX_AT_LINE_ITEM_LEVEL_ADDITIVE =
  "applyTaxAtLineItemLevelAdditive";
export const TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE =
  "applyTaxAtLineItemLevelSubtractive";
/**
 * tax form fields
 *
 * lineitem level switches (keys) - TAX_AT_LINE_ITEM_LEVEL_ADDITIVE, TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE
 *
 * Form Values
 *      written input - as is (number stored as string)
 *      dropdown - #taxIdx#categoryIdx#taxPercentage, and have a util to filter this out before sending
 *  lineItems is ON (TAX_AT_LINE_ITEM_LEVEL_ADDITIVE=true || TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE=true)
 *     Form field keys for
 *        value - `${LINE_ITEM_KEY}.${id}.TAX_KEY.${keyName}` (value)
 *        dropdown value - `${LINE_ITEM_KEY}.${id}.TAX_KEY.SELECTED_VALUE.${keyName}`
 *        optionalEnabled - `${LINE_ITEM_KEY}.${id}.TAX_KEY.${keyName}.optionalEnabled` (optional ON or OFF)
 *          - false (OFF), true (ON), and undefined (ON - for parent taxes, just in case)
 *  lineItems if OFF (TAX_AT_LINE_ITEM_LEVEL_ADDITIVE=false || TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE=false)
 *        value - keyName
 *        dropdown value - `${LINE_ITEM_KEY}.${id}.TAX_KEY.SELECTED_VALUE.${keyName}`
 *        optionalEnabled - keyName.optionalEnabled (optional ON or OFF)
 *          - false (OFF), true (ON), and undefined (ON - for parent taxes, just in case)
 *
 * How to calculate - traverse over `addParent, addChildren[], subtrParent, subtrChildren[]` and
 * also use TAX_AT_LINE_ITEM_LEVEL_ADDITIVE, TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE variables
 *
 *
 * Philosophy -
 * 1. keep the input field names as close to the real names as possible.
 * 2. Use the selectors as the starting point for calculations.
 * 3. To calculate stuff for a tax (keyName), check out possible encodings - is O(1)
 */

export const GENERATE_QUOTE_API_BODY_KEYS_REQUEST = {
  AMOUNT: "amount", // 1000,
  BUY_CURRENCY: "buy_currency", // "INR"
  FIXED_SIDE: "fixed_side", // not needed for v2, set to "buy"
  PAYMENT_CHANNEL: "payment_channel", // "IMPS"
  SELL_CURRENCY: "sell_currency", // "INR"
  VENDOR_ID: "vendor_id", // 1
  USER_ID: "user_id",
  TYPE: "type",
  QUOTE_ID: "quote_id",
  PAYMENT_TYPE: "payment_type",
  FROM_ACCOUNT_ID: "from_account_id",
  TO_ACCOUNT_ID: "to_account_id",
  CURRENCY: "currency",
  SIMPLE_QUOTE: "simple_quote", // quote won't be saved in DB
  PAY_OUTSIDE_VOLOPAY: "pay_outside_volopay",
};

export const PAYMENT_TYPE = {
  CONVERSION: "conversion",
  CARD: "card",
};

export const CREATE_BILL_TAX_RESPONSE = {
  KEY_NAME: "keyName",
  ADDITIVE: "additive",
  LINE_ITEM_LEVEL_APPLICABLE: "lineItemLevelApplicable",
  NESTED: "nested",
  DISPLAY: "display",
  CATEGORIES: "categories",
  NESTED_CATEGORIES: "options",
  TAG_VALUE_ID: "tagValueId",

  // tax options structure, structure remains the same for nested categories
  LABEL: "label",
  VALUE: "value",
  DESCRIPTION_TEXT: "descriptionText",
};

// avoiding adding taxes here in general, this enum is used for ad-hoc PPN 11% then enable PPNBM feature
export const TAX_NAMES = {
  PPN: "PPN",
  PPNBM: "PPnBM",
};

export const TAX_DISPLAY_VALUES = {
  SHOW_ALWAYS: "default",
  OPTIONAL: "optional",
};

export const GENERATE_QUOTE_API_BODY_KEYS_RESPONSE = {
  ID: "id", // 3
  FROM_CURRENCY: "fromCurrency", // "INR"
  TO_CURRENCY: "toCurrency", // "SGD"
  FROM_AMOUNT: "fromAmount", // "1000.0"
  TO_AMOUNT: "toAmount", // "1000.0"
  STATUS: "status", // "active"
  FIXED_SIDE: "fixedSide", // "buy"
  PAYMENT_CHANNEL: "paymentChannel", // "IMPS"
  RATE: "rate", // "1.0
  TRANSFER_FEES: "transferFees",
  TOTAL_AMOUNT: "totalAmount",
};

export const TAX_DETAILS_QUERY_PARAM = "vendor_id";

export const CREATE_BILL_REQUEST_BODY_KEYS = {
  ACCURACY: "accuracy",
  // vendor info
  VENDOR_ID: "vendor_id",
  VENDOR_CONTACT: "vendor_contact",

  // purpose section
  INVOICE_NUMBER: "invoice_number",
  INVOICE_DATE: "invoice_date",
  DUE_DATE: "due_date",
  PAYMENT_FOR_THE_MONTH_OF: "payment_for_the_month_of",
  MEMO: "memo",
  EMPLOYEE_ID: "employeeId",

  AMOUNT: "amount", // for payroll
  // line items section
  LINE_ITEMS_ATTRIBUTES: {
    selfKey: "line_items_attributes",
    ID: "id",
    _DESTROY: "_destroy",
    DESCRIPTION: "description",
    AMOUNT: "amount",
    ACCOUNTING_TAGS: {
      // accountingCategoryTag is also sent in this
      selfKey: "line_item_tag_values_attributes",
      selfKeyPayroll: "payroll_tag_values_attributes",
      ID: "id",
      _DESTROY: "_destroy",
      TAG_ID: "tag_id",
      TAG_VALUE_ID: "tag_value_id",
      TAG_TEXT_VALUE: "custom_text_value",
    },
    TAXES_ATTRIBUTES: {
      // optional, if tax item for line item level is ON
      selfKey: "taxes_attributes",
      NAME: "name",
      ID: "id",
      _DESTROY: "_destroy",
      TAX_RATE: "tax_rate",
      TAX_VALUE: "tax_value",
      CATEGORY: "category",
      TAG_VALUE_ID: "tag_value_id",
    },
  },
  TAXES_ATTRIBUTES: {
    // singular taxes, i.e. taxes that are awlays applicable on the whole bill
    selfKey: "taxes_attributes",
    NAME: "name",
    ID: "id",
    _DESTROY: "_destroy",
    TAX_RATE: "tax_rate",
    TAX_VALUE: "tax_value",
    CATEGORY: "category",
    TAG_VALUE_ID: "tag_value_id",
  },

  // payment section
  PAYMENT_DATE: "payment_date",
  CURRENCY: "currency",
  PROJECT_ID: "project_id",
  PAYMENT_METHOD: "payout_method_type",
  PAYROLL_MONTH: "payroll_month",
  // payment section (optional)
  PAY_ONCE_APPROVED: "payment_on_approve",
  PAYMENT_REFERENCE: "payment_reference",
  SCHEDULE_FIELDS: {
    selfKey: "schedule_fields",
    FREQUENCY: "frequency",
    NEXT_GENERATION_DATE: "next_generation_date",
    NEXT_DUE_DATE: "next_due_date",
    NEXT_PAYMENT_DATE: "next_payment_date",
    END_DATE: "end_date",
  },

  PAYMENT_MODE: "payment_mode",
  // misc
  USER_ID: "user_id",
  QUOTE_ID: "quote_id",
  PAYROLL_TAG_VALUE_ATTRIBUTES: "payroll_tag_values_attributes",
  ID: "id",
  INVOICES: "invoice_files",
  PAYSLIPS: "payslips",
  ONLY_DRAFT: "only_draft",
};

export const UPDATE_BILL_REQUEST_BODY_KEYS = {
  EDIT_ALL: "edit_all",
};

export const CREATE_BILL_APPROVERS_RESPONSE = {
  AVATAR_URL: "avatarUrl",
  NAME: "displayName",
  ROLES: "roles",
};

export const BILL_RESPONSE_KEYS = {
  TAXES_KEY: "taxes",
  TAX_ITEM: {
    ID: "id",
    NAME: "name",
    DEDUCTIVE_TAX: "deductionTax",
    RATE: "taxRate",
    VALUE: "taxValue",
    CATEGORY: "category",
    TAG_VALUE_ID: "tagValueId",
  },
  INVOICES: "invoices",
  PAYSLIPS: "payslips",
};

// structure for line item APIs response
export const LINE_ITEMS_RESPONSE_KEYS = {
  ID: "id",
  DESCRIPTION: "description",
  AMOUNT: "amount",
  CURRENCY: "currency",
  LINE_ITEM_TAG_VALUES: {
    selfKey: "lineItemTagValues",
    ID: "id",
    TAG_ID: "tagId",
    TAG_LABEL: "tagLabel",
    TAG_VALUE: "tagValue",
    TAG_VALUE_ID: "tagValueId",
    IS_ACCOUNTING_CATEGORY_TAG: "isAccountingCategoryTag",
  },
  TAXES_KEY: BILL_RESPONSE_KEYS.TAXES_KEY,
  TAX_ITEM: BILL_RESPONSE_KEYS.TAX_ITEM,
};

// TECH_DEBT collect keys here and use them in the code
// currently, most keys are hardcoded in the code
// tax keys are the same as BE, so reusing
export const OCR_RESPONSE_KEYS = {
  TAXES_KEY: BILL_RESPONSE_KEYS.TAXES_KEY,
  TAX_ITEM: BILL_RESPONSE_KEYS.TAX_ITEM,
};

export const CREATE_BILL_FLOW_CONTEXT_KEYS = {
  FINAL_AMOUNT: "FINAL_AMOUNT",
  INVOICE_SUBTOTAL: "INVOICE_SUBTOTAL",
  QUOTE_ARGUMENT: "QUOTE_ARGUMENT",
  VENDOR_IDENTIFIED_BUT_NOT_FOUND: "VENDOR_IDENTIFIED_BUT_NOT_FOUND",
  ADD_PAYMENT_DATE: "ADD_PAYMENT_DATE",
  OCR_DETECTED_SIMPLE_VALUES: "OCR_DETECTED_SIMPLE_VALUES",
  OCR_DETECTED_LINE_ITEMS: "OCR_DETECTED_LINE_ITEMS",
  EDIT_BILL_RESET_APPROVAL_POLICY_MODAL_IS_OPEN:
    "EDIT_BILL_RESET_APPROVAL_POLICY_MODAL_IS_OPEN",
};
