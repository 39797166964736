import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchInvoiceSliderDetails } from "@/store/reducers/company";

import { defaultCurrencySelector } from "@/store/selectors/client";
import {
  billingPaymentDetailsSelector,
  billingPlanDetailsSelector,
  invoiceSliderDetailsSelector,
} from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Note from "@/components/core/Note";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import BillingDetailsTable from "@/pages/billing/BillingDetailsTable";
import { SLIDER_INVOICE_DETAILS_TABLE } from "@/utils/constants/company";
import { downloadFile } from "@/utils/actions";
import { dateToString } from "@/utils/common";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

const SLIDER_INVOICE_DETAILS_TABLE_HEADER = {
  tableSetting: {
    colWidths: [30, 150, 40],
  },
  tableHeading: [
    {
      title: "company.billing.slider.invoiceDetails.table.slNo",
      classes: "text-left",
    },
    {
      title: "company.billing.slider.invoiceDetails.table.description",
      classes: "text-left",
    },
    {
      title: "company.billing.slider.invoiceDetails.table.amount",
    },
  ],
};
const InvoiceSlider = () => {
  const { tableSetting, tableHeading } = SLIDER_INVOICE_DETAILS_TABLE_HEADER;
  const dispatch = useDispatch();
  const invoiceSliderDetails = useSelector(invoiceSliderDetailsSelector);
  const planDetails = useSelector(billingPlanDetailsSelector);
  const paymentDetails = useSelector(billingPaymentDetailsSelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const [searchParams] = useSearchParams();
  const text = invoiceSliderDetails?.invoiceNumber;
  const invoiceDetails = invoiceSliderDetails?.invoiceItems;
  const ref = useLeftHeaderTitle({ title: text });
  const invoiceItemDetails = [
    {
      key: SLIDER_INVOICE_DETAILS_TABLE.INVOICE_NUMBER,
      label: "company.billing.slider.invoiceDetails.invoiceNumber",
      value: invoiceSliderDetails?.invoiceNumber,
    },
    {
      key: SLIDER_INVOICE_DETAILS_TABLE.INVOICE_GENERATION_DATE,
      label: "company.billing.slider.invoiceDetails.invoiceGenerationDate",
      value: dateToString(invoiceSliderDetails?.invoiceDate),
    },
    {
      key: SLIDER_INVOICE_DETAILS_TABLE.INVOICE_DUE_DATE,
      label: "company.billing.slider.invoiceDetails.invoiceDueDate",
      value: dateToString(invoiceSliderDetails?.dueDate),
    },
    {
      key: SLIDER_INVOICE_DETAILS_TABLE.INVOICE_DESCRIPTION,
      label: "company.billing.slider.invoiceDetails.description",
      value: invoiceSliderDetails?.description,
    },
  ];
  useEffect(() => {
    const invoiceId = searchParams.get(
      SLIDERS_SEARCH_PARAMS.company.billing.invoiceId
    );
    dispatch(fetchInvoiceSliderDetails({ id: invoiceId }));
  }, []);
  const downloadInvoice = () => {
    const invoicePdf = invoiceSliderDetails?.invoicePdf;
    downloadFile(
      invoicePdf?.url,
      invoicePdf?.fileName,
      invoicePdf?.contentType
    );
  };

  return invoiceSliderDetails ? (
    <div className="slider-content-container">
      <div className="pb-8 slider-content-core">
        <div>
          <div>
            <Text
              ref={ref}
              classes="payment-slider-heading"
              translationKey={text}
            />
          </div>
          {invoiceSliderDetails?.errorMessage ? (
            <div className="my-8">
              <Note
                titleText={invoiceSliderDetails?.errorMessage}
                descriptionText=""
                actionText=""
                iconName="Error"
                backgroundColorClass="bg-warning-50"
                borderColorClass="border-warning-300"
              />
            </div>
          ) : null}

          <div className="flex flex-col gap-3 my-8">
            <Text
              translationKey="company.billing.slider.invoiceDetails.title"
              classes="font-bold text-lg"
            />

            <div className="p-0 border-b-0 card-wrapper">
              {invoiceItemDetails?.map((details) => {
                return (
                  <div
                    key={details?.key}
                    className="flex flex-row justify-between w-full p-4 border-b border-solid border-b-neutral-200"
                  >
                    <Text
                      translationKey={details?.label}
                      classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500"
                    />
                    <Text
                      translationKey={details?.value}
                      classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="my-4">
            <Table {...tableSetting}>
              <tr className="text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200">
                {tableHeading.map((data, idx) => {
                  return (
                    <th className={data?.classes} key={idx}>
                      <Text translationKey={data?.title} />
                    </th>
                  );
                })}
              </tr>
              {invoiceDetails?.map((data, index) => (
                <tr className="text-sm " key={index}>
                  <td>{data?.id}</td>
                  <td>{data?.invoiceDescription}</td>
                  <td className="text-center">
                    <span className="mr-1">{data?.totalAmount?.value}</span>
                    <span>{data?.totalAmount?.currency}</span>
                  </td>
                </tr>
              ))}
            </Table>
          </div>
          <div className="flex flex-col gap-4 p-0 mb-10 card-wrapper">
            <div className="flex items-center justify-between px-6 py-4">
              <div>
                <div className="flex items-center gap-2">
                  <Text
                    translationKey="company.billing.slider.invoiceDetails.invoiceTotal"
                    classes="text-base"
                  />
                  <Icon name="Info" className="w-5 h-5 text-neutral-300" />
                </div>
                <div className="flex items-center gap-1">
                  <span className="text-xl font-bold text-neutral-800">
                    {invoiceSliderDetails?.totalAmount?.value}
                  </span>
                  <span className="pl-1 text-base font-bold text-neutral-500">
                    {invoiceSliderDetails?.totalAmount?.currency}
                  </span>
                </div>
              </div>
              <Flag
                code={
                  invoiceSliderDetails?.totalAmount?.currency || defaultCurrency
                } // During initial rendering total_amount?.currency will be undefined so that a fallcack is added as SGD.
                classes="h-9 w-9 border-1 border-neutral-200 "
              />
            </div>
          </div>
          <div className="w-full">
            <BillingDetailsTable
              planDetails={planDetails}
              paymentDetails={paymentDetails}
            />
          </div>
        </div>
      </div>
      {invoiceSliderDetails?.invoicePdf &&
      Object?.keys(invoiceSliderDetails?.invoicePdf)?.length ? (
        <div className="flex items-center justify-end gap-4 px-3 pt-4 pb-2 slider-footer">
          <Button
            label="company.billing.slider.footerCta"
            variant="secondary w-[230px] h-10"
            onClick={downloadInvoice}
          />
        </div>
      ) : null}
    </div>
  ) : null;
};
export default InvoiceSlider;
