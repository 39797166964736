import { createSelector } from "@reduxjs/toolkit";

export const quickGuideStore = (store) => store.quickGuide;

export const quickGuideListSelector = createSelector(
  quickGuideStore,
  (quickGuide) => quickGuide?.quickGuideList
);

export const quickGuideSearchOptionsSelector = createSelector(
  quickGuideStore,
  (quickGuide) => quickGuide?.searchOptions
);
