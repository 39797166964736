import {
  blockCard,
  fetchCards,
  freezeCard,
  resetCardsListAndPagination,
  unfreezeCard,
} from "@/store/reducers/cards";
import { MODULES } from "@/utils/constants/app";
import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
} from "@/utils/constants/filters";

import { CARD_APPROVAL_REQUEST_TYPE } from "@/constants/ActionCentre";
import {
  CARDS_ALL_SLIDER_MENU_KEYS,
  CARD_PROVIDER,
  CARD_STATUS,
  CARD_TYPE,
  CARD_TYPES,
  CARD_USAGE_TYPES,
} from "@/constants/Cards";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";

export function getCardsPageFiltersConfig({
  filterKeys,
  clientInfo,
  departmentId,
  hasCardWithInternationCardOrderEnabled,
}) {
  const frequencyOptions = clientInfo?.config?.supportedFrequencies;
  const frequencyKeys = Object?.keys(frequencyOptions || []);
  const frequencyValues = Object.values(frequencyOptions || []);
  const requiredFrequencyOptions = [];
  const _filters = [];

  frequencyKeys?.forEach((frequencyKey, index) =>
    requiredFrequencyOptions.push({
      label: frequencyKey,
      value: frequencyValues?.[index],
    })
  );

  filterKeys?.forEach((key) => {
    if (key === AVAILABLE_FILTER_KEYS.department) {
      _filters.push({
        ...AVAILABLE_FILTERS[key],
        props: { ...AVAILABLE_FILTERS[key].props, value: [departmentId] },
      });
    } else if (key === AVAILABLE_FILTER_KEYS.cardFrequency) {
      _filters.push({
        ...AVAILABLE_FILTERS[key],
        props: {
          ...AVAILABLE_FILTERS[key].props,
          options: requiredFrequencyOptions,
          show: requiredFrequencyOptions?.length > 1,
        },
      });
    } else if (
      key !== AVAILABLE_FILTER_KEYS.cardUsageType ||
      hasCardWithInternationCardOrderEnabled
    ) {
      _filters.push(AVAILABLE_FILTERS[key]);
    }
  });

  return _filters;
}

export function getCardsModuleRequestFilters(
  filterKeys,
  clientInfo,
  availableModule
) {
  const slug = clientInfo?.slug;

  const config = {
    [CARD_TYPE.PHYSICAL]: "Physical card",
    [CARD_TYPE.VIRTUAL]: "Virtual card",
  };

  const suggestedCardTypes = clientInfo?.config?.supportedCardTypes;
  const cardTypeOptions = [];

  suggestedCardTypes?.forEach((cardTypeItem) =>
    cardTypeOptions.push({ label: config?.[cardTypeItem], value: cardTypeItem })
  );

  return slug
    ? filterKeys
        ?.map((key) => {
          if (
            key === AVAILABLE_FILTER_KEYS.cardModuleRequestType ||
            key === AVAILABLE_FILTER_KEYS.cardRequestType
          ) {
            return {
              ...AVAILABLE_FILTERS[key],
              props: {
                ...AVAILABLE_FILTERS[key].props,
                show: slug !== CARD_PROVIDER.UOB,
              },
            };
          }

          if (
            key === AVAILABLE_FILTER_KEYS.cardModuleCardType ||
            key === AVAILABLE_FILTER_KEYS.cardType
          ) {
            return {
              ...AVAILABLE_FILTERS[key],
              props: {
                ...AVAILABLE_FILTERS[key].props,
                show: slug !== CARD_PROVIDER.UOB && cardTypeOptions?.length > 0,
                options: cardTypeOptions,
              },
            };
          }

          return AVAILABLE_FILTERS[key];
        })
        ?.filter((item) => {
          return (
            ![
              AVAILABLE_FILTER_KEYS.cardModuleCardType,
              AVAILABLE_FILTER_KEYS.cardType,
            ]?.includes(item?.props?.filterKey) ||
            availableModule?.includes(MODULES.CARDS)
          );
        })
    : [];
}

export function triggerCardsTableComponent(
  dispatch,
  pageAppliedFilters,
  locationObject
) {
  const pageRoute = locationObject?.pathname;
  let cardType = "";
  let cardStatus = "";

  switch (pageRoute) {
    case ROUTES.cards.physicalCards.all.absolutePath:
    case ROUTES.cards.physicalCards.yetToActivate.absolutePath:
    case ROUTES.cards.physicalCards.blocked.absolutePath:
    case ROUTES.myVolopay.cards.physicalCards.all.absolutePath:
    case ROUTES.myVolopay.cards.physicalCards.yetToActivate.absolutePath:
    case ROUTES.myVolopay.cards.physicalCards.blocked.absolutePath:
      cardType = CARD_TYPES.PHYSICAL;

      if (
        [
          ROUTES.cards.physicalCards.all.absolutePath,
          ROUTES.myVolopay.cards.physicalCards.all.absolutePath,
        ]?.includes(pageRoute)
      ) {
        cardStatus = [CARD_STATUS.ACTIVE, CARD_STATUS.FROZEN];
      } else if (
        [
          ROUTES.myVolopay.cards.physicalCards.yetToActivate.absolutePath,
          ROUTES.cards.physicalCards.yetToActivate.absolutePath,
        ]?.includes(pageRoute)
      ) {
        cardStatus = [CARD_STATUS.INACTIVE];
      } else {
        cardStatus = CARD_STATUS.BLOCKED;
      }

      if (
        ![
          ROUTES.myVolopay.cards.physicalCards.all.absolutePath,
          ROUTES.myVolopay.cards.physicalCards.yetToActivate.absolutePath,
          ROUTES.myVolopay.cards.physicalCards.blocked.absolutePath,
        ].includes(pageRoute)
      ) {
        delete pageAppliedFilters?.user;
        delete pageAppliedFilters?.user_ids;
      }
      break;

    case ROUTES.cards.virtualCards.all.absolutePath:
    case ROUTES.cards.virtualCards.blocked.absolutePath:
      cardType = CARD_TYPES.VIRTUAL;

      if (pageRoute === ROUTES.cards.virtualCards.all.absolutePath) {
        cardStatus = [CARD_STATUS.ACTIVE, CARD_STATUS.FROZEN];
      } else {
        cardStatus = [CARD_STATUS.BLOCKED];
      }
      break;

    default:
      break;
  }

  dispatch(resetCardsListAndPagination());

  dispatch(
    fetchCards({
      page: 1,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      card_type: cardType,
      status: cardStatus,
      ...pageAppliedFilters,
    })
  );
}

/**
 * Generates a configuration object for card table data based on card providers and usage type.
 *
 * @param {boolean} hasCardProvidersWithInternationalCardOrder - Flag indicating if there are card providers with international card order.
 * @param {string} cardUsageType - The type of card usage, should be one of CARD_USAGE_TYPES.
 * @returns {Object} The configuration object for card table data.
 */
export function getCardTableDataConfig(
  hasCardProvidersWithInternationalCardOrder,
  cardUsageType
) {
  const isCardUsageTypeInternational =
    hasCardProvidersWithInternationalCardOrder &&
    cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL;

  return {
    [CARD_STATUS.ACTIVE]: {
      classes: isCardUsageTypeInternational
        ? "bg-integrations-50"
        : "bg-corporate-cards-50",
      [CARD_TYPE.PHYSICAL]: {
        icon:
          hasCardProvidersWithInternationalCardOrder &&
          cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
            ? "InternationalCards"
            : "CreditCard",
      },
      [CARD_TYPE.VIRTUAL]: {
        icon: "Cloud",
      },
      textClasses: isCardUsageTypeInternational
        ? "text-integrations-500"
        : "text-corporate-cards-700",
    },
    [CARD_STATUS.BLOCKED]: {
      classes: "bg-neutral-100",
      [CARD_TYPE.PHYSICAL]: {
        icon:
          hasCardProvidersWithInternationalCardOrder &&
          cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
            ? "InternationalCards"
            : "CreditCard",
      },
      [CARD_TYPE.VIRTUAL]: {
        icon: "Cloud",
      },
      textClasses: "text-neutral-400",
    },
    [CARD_STATUS.INACTIVE]: {
      classes: "bg-corporate-cards-50",
      [CARD_TYPE.PHYSICAL]: {
        icon:
          hasCardProvidersWithInternationalCardOrder &&
          cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
            ? "InternationalCards"
            : "CreditCard",
      },
      [CARD_TYPE.VIRTUAL]: {
        icon: "Cloud",
      },
      textClasses: "text-corporate-cards-700",
    },
    [CARD_STATUS.FROZEN]: {
      classes: "bg-neutral-100",
      [CARD_TYPE.PHYSICAL]: {
        icon:
          hasCardProvidersWithInternationalCardOrder &&
          cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
            ? "InternationalCards"
            : "FreezedPhysicalCard",
      },
      [CARD_TYPE.VIRTUAL]: {
        icon: "FreezeVirtualCard",
      },
      textClasses: "text-neutral-400",
    },
  };
}

/**
 * Generates a configuration object for card request type table data.
 *
 * @param {boolean} hasCardProvidersWithInternationalCardOrder - Flag indicating if there are card providers with international card order.
 * @param {string} cardUsageType - The type of card usage, should be one of CARD_USAGE_TYPES.
 * @returns {Object} The configuration object for card request type table data.
 */
export function getCardRequestTypeTableDataConfig(
  hasCardProvidersWithInternationalCardOrder,
  cardUsageType
) {
  const isCardUsageTypeInternational =
    hasCardProvidersWithInternationalCardOrder &&
    cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL;

  return {
    [CARD_APPROVAL_REQUEST_TYPE.PHYSICAL]: {
      label: "myVolopay.actionCentre.table.request.newCard",
      iconClasses: isCardUsageTypeInternational
        ? "bg-integrations-50 p-2 py-2 text-integrations-500"
        : "",
      classes: "text-success-600 font-normal",
      iconName: isCardUsageTypeInternational
        ? "InternationalCards"
        : "RoundedCreditCard",
    },
    [CARD_APPROVAL_REQUEST_TYPE.VIRTUAL]: {
      label: "myVolopay.actionCentre.table.request.newCard",
      classes: "text-success-600 font-normal",
      iconName: "RoundedCloud",
    },
    [CARD_APPROVAL_REQUEST_TYPE.TOP_UP]: {
      [CARD_TYPE.PHYSICAL]: {
        label: "myVolopay.actionCentre.table.request.cardLimit",
        iconClasses: isCardUsageTypeInternational
          ? "bg-integrations-50 p-2 py-2 text-integrations-500"
          : "",
        classes: "text-success-600 font-normal",
        iconName: isCardUsageTypeInternational
          ? "InternationalCardTopUpRequest"
          : "RoundedEditCreditCard",
      },
      [CARD_TYPE.VIRTUAL]: {
        label: "myVolopay.actionCentre.table.request.cardLimit",
        classes: "text-warning-500 font-normal",
        iconClasses:
          hasCardProvidersWithInternationalCardOrder &&
          cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
            ? "bg-integrations-50 p-1.5 py-2 text-integrations-500"
            : "",
        iconName:
          hasCardProvidersWithInternationalCardOrder &&
          cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
            ? "EditInternationalCard"
            : "RoundedEditCloud",
      },
    },
  };
}

/**
 * Handles freezing, unfreezing, and blocking of cards based on the provided menu key.
 *
 * @param {Object} params - The parameters for the handler.
 * @param {Function} params.dispatch - The dispatch function for dispatching actions.
 * @param {string} params.menuKey - The key representing the action to be taken (freeze, block, unfreeze).
 * @param {string} params.cardId - The ID of the card to be acted upon.
 * @param {Function} params.handleCardStatusChange - Callback function to handle status change.
 * @param {Function} params.showToaster - Function to show toaster notifications.
 */
export function freezeUnfreezeBlockHandler({
  dispatch,
  menuKey,
  cardId,
  handleCardStatusChange,
  showToaster,
}) {
  switch (menuKey) {
    case CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD:
      dispatch(
        freezeCard({ cardId, onSuccess: handleCardStatusChange, showToaster })
      );
      break;

    case CARDS_ALL_SLIDER_MENU_KEYS.BLOCK:
      dispatch(
        blockCard({ cardId, onSuccess: handleCardStatusChange, showToaster })
      );
      break;

    case CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE:
      dispatch(
        unfreezeCard({ cardId, onSuccess: handleCardStatusChange, showToaster })
      );
      break;

    default:
      break;
  }
}
