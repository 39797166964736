import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setCreateClaimFormDetails } from "@/store/reducers/reimbursement";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { REIMBURSEMENT_CLAIM_TABS } from "@/utils/constants/myVolopay";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

import BulkUploadButton from "./common/BulkUploadButton";

function MyVolopayReimbursementClaimPage() {
  const [selectedTab, setSelectedTab] = useState(
    REIMBURSEMENT_CLAIM_TABS[0].key
  );
  const dispatch = useDispatch();

  const [file, setFile] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  const showModal = () => {
    setIsModalOpened(true);
  };

  const openSlider = () => {
    dispatch(setCreateClaimFormDetails(null));
    searchParams.append(SLIDERS_SEARCH_PARAMS.reimbursements.create, true);
    setSearchParams(searchParams);
  };

  return (
    <div className="px-10">
      <div className="flex items-center justify-between pb-6">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <Text
              classes="text-neutral-500 text-sm"
              translationKey="myVolopay.title"
            />
            <div>/</div>
            <Text
              classes="text-neutral-500 text-sm"
              translationKey="reimbursement.title"
            />
          </div>
          <Text
            classes="text-neutral-800 text-4xl font-extrabold"
            translationKey="reimbursement.claims"
          />
        </div>
        <div className="flex items-center gap-6">
          {checkIfUserActionAllowed(
            allNavUrls,
            userAllowedActions,
            USER_ALLOWED_CTA_KEYS.UPLOAD_RECEIPTS
          ) ? (
            <BulkUploadButton
              showModal={showModal}
              isModalVisible={isModalOpened}
              handleModalClose={() => setIsModalOpened(false)}
            />
          ) : null}
          {checkIfUserActionAllowed(
            allNavUrls,
            userAllowedActions,
            USER_ALLOWED_CTA_KEYS.CREATE_CLAIM
          ) ? (
            <Button
              classes="h-11 font-medium w-16"
              iconClasses="h-6 w-6"
              preIcon="Add"
              onClick={openSlider}
              label="myVolopay.reimbursements.drafts.ctas.createClaim"
            />
          ) : null}
        </div>
      </div>
      <div>
        <Tabs
          items={REIMBURSEMENT_CLAIM_TABS}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
        />
      </div>
    </div>
  );
}

export default MyVolopayReimbursementClaimPage;
