import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// custom hooks
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

// reducers
import {
  changeCardLinkedTo,
  getProjectList,
  setIsLinkedToChanged,
  setIsLinkedToInprogress,
  setProjectList,
} from "@/store/reducers/cards";
import { changeLinkedTo } from "@/store/reducers/expense";

// selectors
import {
  isLinkedToChangedInpgressSelector,
  isLinkedToChangedSelector,
  projectListSelector,
  selectedCardSelector,
} from "@/store/selectors/cards";
import { cardProviderWithInternationalCardOrderEnabledSelector } from "@/store/selectors/client";
import {
  isLinkedToChangedExpenseInpgressSelector,
  isLinkedToChangedExpenseSelector,
  selectedExpenseSelector,
} from "@/store/selectors/expense";

import Alert from "@/components/core/Alert";
// core components
import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

// utils, constant file imports
import { LINKED_TO_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

// page relevant components
import LinkedToWidget from "../Common/LinkedToWidget";

function LinkedToComponentSlider({ setOnClose }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const expense = useSelector(selectedExpenseSelector);
  const cardTransactionSlug = expense?.slug;
  const projectList = useSelector(projectListSelector);
  const selectedCardInfo = useSelector(selectedCardSelector);

  const expenseId = searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.id);

  const cardId = getCardIdBySearchParams();

  const isTriggerExpenseSlider = cardId && expenseId ? true : false;

  const projectId = searchParams.get(SLIDERS_SEARCH_PARAMS.cards.linkedTo);

  const ref = useLeftHeaderTitle({
    title: "cards.linkedTo",
  });

  const dispatch = useDispatch();

  const isExpenseSwitch = searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.id);

  const expenseAmount = isExpenseSwitch ? expense?.amount : null;

  const [projectIdValue, setProjectIdValue] = useState({});

  const expenseLinkedToInprogress = useSelector(
    isLinkedToChangedExpenseInpgressSelector
  );

  const expenseLinkedToChanged = useSelector(isLinkedToChangedExpenseSelector);

  const isCardLinkedToInprogress = useSelector(
    isLinkedToChangedInpgressSelector
  );

  const isCardLinkedToChanged = useSelector(isLinkedToChangedSelector);

  const cardProviderWithInternationalCardOrderEnabled = useSelector(
    cardProviderWithInternationalCardOrderEnabledSelector
  );
  const internationalCardOrderProviderSlug =
    cardProviderWithInternationalCardOrderEnabled?.slug;
  const selectedCardSlug = selectedCardInfo?.slug;

  const { inProgress, isLinkedToChanged } = getLinkedToStatusConfig();

  const showInternationalCardsBudgetNote =
    internationalCardOrderProviderSlug ===
    (isTriggerExpenseSlider ? cardTransactionSlug : selectedCardSlug);

  const departmentSection = projectList
    ?.filter((project) => project.type === LINKED_TO_TYPE.DEPARTMENT)
    ?.map((project) =>
      generateLinkedToWidget(project, LINKED_TO_TYPE.DEPARTMENT)
    );

  const projectSection = projectList
    .filter((project) => project.type === LINKED_TO_TYPE.PROJECT)
    .map((project) => generateLinkedToWidget(project, LINKED_TO_TYPE.PROJECT));

  const onCancel = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.linkedTo);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.id);
    setSearchParams(searchParams);
  };

  const onSave = () => {
    if (!isTriggerExpenseSlider) {
      dispatch(changeCardLinkedTo(projectIdValue?.id));
    } else {
      dispatch(
        changeLinkedTo({
          id: searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.id),
          card_budget_id: projectIdValue?.id,
        })
      );
    }
    if (expenseLinkedToChanged && !isCardLinkedToInprogress) {
      onCancel();
    }
  };

  useEffect(() => {
    const filteredObject = projectList?.find(
      (project) => project?.id === Number(projectId)
    );
    if (filteredObject) {
      setProjectIdValue(filteredObject);
    }
  }, [projectId, projectList]);

  useEffect(() => {
    setOnClose(() => {
      dispatch(setProjectList([]));
    });
  }, [searchParams.size]);

  function getCardIdBySearchParams() {
    if (expenseId) {
      return expense?.cardId;
    }
    return searchParams.get(SLIDERS_SEARCH_PARAMS.cards.id);
  }

  function generateLinkedToWidget(project, widgetType) {
    return (
      <div className="mt-2 mb-2" key={project?.id}>
        <LinkedToWidget
          widgetType={widgetType}
          project={project}
          key={project?.projectId}
          projectId={project?.projectId}
          linkedToName={project?.name}
          currency={project?.currency}
          limit={project?.cardBudgetLimit}
          cardAvailableBalance={project?.cardAvailableBalance}
          budgetAvailableBalance={project?.budgetAvailableBalance}
          cardBudgetId={project?.id}
          values={projectIdValue}
          onClick={(id) => {
            setProjectIdValue(id);
          }}
          disableWidget={
            expenseAmount > project?.budgetAvailableBalance ||
            expenseAmount > project?.cardAvailableBalance
          }
          showBudgetAvailableText={!showInternationalCardsBudgetNote}
        />
      </div>
    );
  }

  useEffect(() => {
    dispatch(getProjectList({ card_id: cardId }));

    return () => {
      dispatch(setIsLinkedToChanged(false));
      dispatch(setIsLinkedToInprogress(false));
    };
  }, []);

  useEffect(() => {
    if (isLinkedToChanged) {
      closeLinkedToSlider();
    }
  }, [isLinkedToChanged]);
  function getLinkedToStatusConfig() {
    if (!isTriggerExpenseSlider) {
      return {
        inProgress: isCardLinkedToInprogress,
        isLinkedToChanged: isCardLinkedToChanged,
      };
    }

    return {
      inProgress: expenseLinkedToInprogress,
      isLinkedToChanged: expenseLinkedToChanged,
    };
  }

  function closeLinkedToSlider() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.linkedTo);
    setSearchParams(searchParams);
  }

  return (
    <>
      <div className="pb-6 slider-content-core">
        <div>
          <h1>
            <Text
              ref={ref}
              translationKey="cards.linkedTo"
              classes="text-3xl text-neutral-800 font-bold"
            />
          </h1>
        </div>

        {showInternationalCardsBudgetNote ? (
          <div className="mt-8">
            <Alert
              title="cards.pCards.linkedTo.internationalCardsBudgetNote.title"
              description="cards.pCards.linkedTo.internationalCardsBudgetNote.description"
              variant="warning"
            />
          </div>
        ) : null}

        {departmentSection?.length > 0 ? (
          <div className="flex flex-col gap-2 mt-7">
            <Text
              translationKey="filters.department"
              classes="font-semibold text-neutral-500"
            />
            {departmentSection}
          </div>
        ) : null}

        {projectSection?.length > 0 ? (
          <div className="flex flex-col gap-2 mt-7">
            <Text
              translationKey="filters.project"
              classes="font-semibold text-neutral-500"
            />
            {projectSection}
          </div>
        ) : null}
      </div>

      <div className="flex items-center justify-end gap-6 p-6 slider-footer">
        <Button
          label="cards.vCards.createCardSlider.cancel"
          variant="tertiary"
          classes="w-[150px] text-neutral-500 text-btn-lg bg-white hover:bg-neutral-300 border border-neutral-300"
          onClick={onCancel}
        />
        <Button
          label="misc.saveChanges"
          classes="w-[150px] text-white text-btn-lg"
          onClick={onSave}
          disabled={
            (inProgress && Number(projectId) === Number(projectIdValue?.id)) ||
            Number(projectId) === Number(projectIdValue?.id)
          }
        />
      </div>
    </>
  );
}

LinkedToComponentSlider.propTypes = {
  setOnClose: propTypes.func,
};

export default LinkedToComponentSlider;
