import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";

import { setCurrentPolicy } from "@/store/reducers/policy";

import { currentPolicySelector } from "@/store/selectors/policy";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { APPROVAL_LEVEL, USER_LAYER_TYPES } from "@/constants/settings";

export default function AddLayerPopup({ id, options, policyIndex }) {
  const dispatch = useDispatch();
  const popupRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const curtPolicy = useSelector(currentPolicySelector);

  const handleAddLayer = (selectedValue) => {
    const currentPolicy = JSON.parse(JSON.stringify(curtPolicy));

    const policy = id
      ? currentPolicy.policies.find((a) => a.id === id)
      : currentPolicy.policies[policyIndex];

    const newValue = {
      approvers: [],
      type: selectedValue.option,
    };

    policy.approvers[APPROVAL_LEVEL[Object.keys(policy.approvers).length]] =
      newValue;

    setShowOptions(false);
    dispatch(setCurrentPolicy(currentPolicy));
  };

  return (
    <Popup
      ref={popupRef}
      closeOnDocumentClick
      on="click"
      onOpen={() => setShowOptions(true)}
      trigger={
        <button
          id="add-layer"
          className="flex items-center justify-center h-8 gap-2 px-6 py-4 text-sm font-medium bg-white w-fit text-primary-600 card-wrapper"
        >
          <Icon className="text-primary-600 w-2.5 h-2.5" name="Add" />
          <Text translationKey="settings.spendControls.approvalPolicy.approvalWorkFlow.addLayer.title" />
        </button>
      }
      position="bottom left"
      className="filter-popup"
    >
      {showOptions ? (
        <div className="flex flex-col w-84">
          {options?.map((item, index) => (
            <div
              key={index}
              className="p-4 cursor-pointer hover:bg-neutral-50 text-neutral-500 hover:text-neutral-800"
              onClick={() => {
                handleAddLayer(item);
              }}
            >
              <Text translationKey={item.label} />
            </div>
          ))}
        </div>
      ) : null}
    </Popup>
  );
}

AddLayerPopup.propTypes = {
  id: PropTypes.number,
  options: PropTypes.array,
  policyIndex: PropTypes.number,
};
