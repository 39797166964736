import PropTypes from "prop-types";

import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { SORTING_TYPE } from "@/utils/constants/sorting";

export default function HeaderCell({ val }) {
  const showCheckbox = val.showCheckbox ?? true;

  const currentSortType = val?.sorting?.type;
  const sortActive =
    val?.haveSort &&
    (!val?.sorting?.category ||
      val?.sortingCategory === val?.sorting?.category);

  const sortOnClickHandler = () => {
    val?.setSorting((prev) => {
      const isNewColumn =
        !prev.cycles || prev.category !== val?.sortingCategory;

      const defaultCondition = {
        defaultCategory: val?.sorting?.defaultCategory,
        defaultType: val?.sorting?.defaultType,
      };

      if (isNewColumn) {
        if (currentSortType === SORTING_TYPE.DEC) {
          return {
            type: SORTING_TYPE.INC,
            category: val?.sortingCategory,
            cycles: [SORTING_TYPE.INC, null, SORTING_TYPE.DEC],
            ...defaultCondition,
          };
        }
        if (currentSortType === SORTING_TYPE.INC) {
          return {
            type: SORTING_TYPE.DEC,
            category: val?.sortingCategory,
            cycles: [SORTING_TYPE.DEC, null, SORTING_TYPE.INC],
            ...defaultCondition,
          };
        }

        return {
          type: SORTING_TYPE.INC,
          category: val?.sortingCategory,
          cycles: [SORTING_TYPE.INC, SORTING_TYPE.DEC, null],
          ...defaultCondition,
        };
      }

      const newType =
        prev.cycles[(prev.cycles.indexOf(currentSortType) + 1) % 3];

      if (!newType) {
        return {
          type: null,
          category: null,
          defaultCategory: val?.sorting?.defaultCategory,
          ...defaultCondition,
        };
      }

      return {
        type: newType,
        category: val?.sortingCategory,
        ...defaultCondition,
        cycles: prev.cycles,
      };
    });
  };

  return (
    <div
      onClick={val?.haveSort ? sortOnClickHandler : () => {}}
      className={`flex items-center gap-2 ${
        val?.haveSort ? "cursor-pointer" : ""
      } ${val?.classes}`}
    >
      {showCheckbox ? (
        <div onClick={(event) => event.stopPropagation()} className="-mb-2">
          {/* -mb-2 is an adjustment to keep Checkbox in the center, since there is some inherent extra padding with Checkbox */}
          <Checkbox
            disabled={val.disabled ?? false}
            checked={val.checkedValue ?? false}
            onClickHandler={val.onCheckboxClick ?? (() => {})}
          />
        </div>
      ) : null}

      {val?.haveSort ? (
        <div className="flex flex-col justify-center ">
          <Icon
            className={
              sortActive && currentSortType === SORTING_TYPE.INC
                ? "text-neutral-500"
                : "text-neutral-300"
            }
            name="ArrowDropUp"
          />
          <Icon
            className={
              sortActive && currentSortType === SORTING_TYPE.DEC
                ? "text-neutral-500"
                : "text-neutral-300"
            }
            name="ArrowDropDown"
          />
        </div>
      ) : null}

      <Text translationKey={val.title} />
    </div>
  );
}

HeaderCell.propTypes = {
  val: PropTypes.shape({
    title: PropTypes.string,
    disabled: PropTypes.bool,
    showCheckbox: PropTypes.bool,
    haveSort: PropTypes.bool,
    sorting: PropTypes.object,
    checkedValue: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
    handleSorting: PropTypes.func,
    classes: PropTypes.string,
  }),
};
