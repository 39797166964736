import PropTypes from "prop-types";

import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { CONST_TYPE_REIMBURSEMENT } from "@/utils/constants/reimbursement";

export default function ReimbursementRequestTypeCell({
  distanceTravelled,
  merchant,
  reimbursementRequestDate,
  reimbursementRequestType,
  showDescriptiveSection,
}) {
  const REIMBURSEMENT_REQUEST_TYPE_LABELS = {
    [CONST_TYPE_REIMBURSEMENT.mileage]: "Mileage",
    [CONST_TYPE_REIMBURSEMENT.outOfPocket]: "Out of Pocket",
  };

  const merchantLabel = distanceTravelled
    ? `${distanceTravelled} miles`
    : merchant?.name;

  const img = merchant?.img;

  return (
    <ProfileWidget
      name={merchantLabel}
      img={img}
      className="text-sm font-semibold"
      textClasses="truncate w-18 text-ellipsis"
    >
      {showDescriptiveSection ? (
        <div className="flex flex-row items-center gap-1">
          {reimbursementRequestDate ? (
            <Text
              translationKey={reimbursementRequestDate}
              classes="text-neutral-500 font-semibold"
            />
          ) : null}

          {reimbursementRequestType ? (
            <div className="flex flex-row items-center gap-1">
              {reimbursementRequestDate ? (
                <span className="text-sm font-semibold profile-widget-dot text-neutral-500" />
              ) : null}

              <Text
                translationKey={
                  REIMBURSEMENT_REQUEST_TYPE_LABELS[reimbursementRequestType]
                }
                classes="text-warning-500 font-semibold text-sm"
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </ProfileWidget>
  );
}

ReimbursementRequestTypeCell.propTypes = {
  distanceTravelled: PropTypes.number,
  merchant: PropTypes.object,
  reimbursementRequestDate: PropTypes.string,
  reimbursementRequestType: PropTypes.string,
  showDescriptiveSection: PropTypes.bool,
};
