import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { getMBs } from "@/utils/common";

export default function CSVFileUploadPreview({ file, primaryAction }) {
  return (
    <div className="flex items-center gap-5 p-4 border rounded-lg border-neutral-200">
      <div className="relative">
        <Icon name="CSVFile" className="relative w- text-success-600" />
        <Icon
          name="CheckCircle"
          className="absolute bottom-0 right-0 w-3 h-3 text-success-600"
        />
      </div>
      <div className="flex flex-col justify-center w-full gap-1">
        <Text
          translationKey={file?.file?.path ?? file?.path}
          classes="text-base font-semibold text-neutral-800"
        />
        <Text
          translationKey={Object.values(
            getMBs(file?.file?.size ?? file?.size)
          )?.join(" ")}
          classes="text-sm font-medium text-neutral-500"
        />
      </div>
      <div onClick={primaryAction?.handler}>
        <Icon
          name={primaryAction?.icon}
          className="w-8 h-8 p-2 mr-3 rounded-lg cursor-pointer text-neutral-700 bg-neutral-100"
        />
      </div>
    </div>
  );
}

CSVFileUploadPreview.propTypes = {
  file: PropTypes.object,
  primaryAction: PropTypes.object,
};
