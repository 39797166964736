import { POLICY_GROUP_MAPPING } from "@/constants/policy";
import {
  USER_WALLETS_ALL_PATH_NAME,
  USER_WALLETS_YET_TO_ACTIVATE_PATH_NAME,
} from "@/constants/routes";

import { SLIDERS_SEARCH_PARAMS } from "./SearchParams";

export const API_KEYS = {
  STATUS: "status",
};
export const USER_WALLET_FREQUENCY = {
  ONE_TIME: "misc.oneTime",
  MONTHLY: "misc.monthly",
};
export const WALLET_FREQUENCY_CORE = {
  RECURRING: "recurring",
  NON_RECURRING: "non_recurring",
};
export const USER_WALLET_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  FROZEN: "freezed",
};
export const USER_WALLET_TAB_STATUS_MAP = {
  [USER_WALLETS_ALL_PATH_NAME]: {
    [API_KEYS.STATUS]: [USER_WALLET_STATUS.ACTIVE, USER_WALLET_STATUS.FROZEN],
  },
  [USER_WALLETS_YET_TO_ACTIVATE_PATH_NAME]: {
    [API_KEYS.STATUS]: [USER_WALLET_STATUS.INACTIVE],
  },
};
export const LINKED_TO_TYPE = {
  PROJECT: "project",
  DEPARTMENT: "department",
};

export const USER_WALLET_TABLE_HEADER_KEYS = {
  WALLET_HOLDER: "walletHolder",
  LIMIT: "limit",
  SPENT: "spent",
  AVAILABLE: "available",
  LINKED_TO: "linkedTo",
  ACTIVATION_DATE: "activationDate",
};
export const USER_WALLET_HEADER_LABEL = {
  [USER_WALLET_TABLE_HEADER_KEYS.WALLET_HOLDER]:
    "QRPay.userWallets.tableHeaders.walletHolder",
  [USER_WALLET_TABLE_HEADER_KEYS.LIMIT]: "QRPay.userWallets.tableHeaders.limit",
  [USER_WALLET_TABLE_HEADER_KEYS.SPENT]: "QRPay.userWallets.tableHeaders.spent",
  [USER_WALLET_TABLE_HEADER_KEYS.AVAILABLE]:
    "QRPay.userWallets.tableHeaders.available",
  [USER_WALLET_TABLE_HEADER_KEYS.LINKED_TO]:
    "QRPay.userWallets.tableHeaders.linkedTo",
  [USER_WALLET_TABLE_HEADER_KEYS.ACTIVATION_DATE]:
    "QRPay.userWallets.tableHeaders.activationDate",
};
export const USER_WALLET_HEADERS_CONFIG = {
  [USER_WALLET_TABLE_HEADER_KEYS.WALLET_HOLDER]: {
    id: USER_WALLET_TABLE_HEADER_KEYS.WALLET_HOLDER,
    label:
      USER_WALLET_HEADER_LABEL[USER_WALLET_TABLE_HEADER_KEYS.WALLET_HOLDER],
    colWidth: 300,
  },
  [USER_WALLET_TABLE_HEADER_KEYS.LIMIT]: {
    id: USER_WALLET_TABLE_HEADER_KEYS.LIMIT,
    label: USER_WALLET_HEADER_LABEL[USER_WALLET_TABLE_HEADER_KEYS.LIMIT],
    colWidth: 200,
  },
  [USER_WALLET_TABLE_HEADER_KEYS.SPENT]: {
    id: USER_WALLET_TABLE_HEADER_KEYS.SPENT,
    label: USER_WALLET_HEADER_LABEL[USER_WALLET_TABLE_HEADER_KEYS.SPENT],
    colWidth: 200,
  },
  [USER_WALLET_TABLE_HEADER_KEYS.AVAILABLE]: {
    id: USER_WALLET_TABLE_HEADER_KEYS.AVAILABLE,
    label: USER_WALLET_HEADER_LABEL[USER_WALLET_TABLE_HEADER_KEYS.AVAILABLE],
    colWidth: 200,
  },
  [USER_WALLET_TABLE_HEADER_KEYS.LINKED_TO]: {
    id: USER_WALLET_TABLE_HEADER_KEYS.LINKED_TO,
    label: USER_WALLET_HEADER_LABEL[USER_WALLET_TABLE_HEADER_KEYS.LINKED_TO],
    colWidth: 300,
  },
  [USER_WALLET_TABLE_HEADER_KEYS.ACTIVATION_DATE]: {
    id: USER_WALLET_TABLE_HEADER_KEYS.ACTIVATION_DATE,
    label:
      USER_WALLET_HEADER_LABEL[USER_WALLET_TABLE_HEADER_KEYS.ACTIVATION_DATE],
    colWidth: 200,
  },
};
export const USER_WALLET_TABLE_HEADERS = {
  USER_WALLET: [
    USER_WALLET_HEADERS_CONFIG[USER_WALLET_TABLE_HEADER_KEYS.WALLET_HOLDER],
    USER_WALLET_HEADERS_CONFIG[USER_WALLET_TABLE_HEADER_KEYS.LIMIT],
    USER_WALLET_HEADERS_CONFIG[USER_WALLET_TABLE_HEADER_KEYS.SPENT],
    USER_WALLET_HEADERS_CONFIG[USER_WALLET_TABLE_HEADER_KEYS.AVAILABLE],
    USER_WALLET_HEADERS_CONFIG[USER_WALLET_TABLE_HEADER_KEYS.LINKED_TO],
    USER_WALLET_HEADERS_CONFIG[USER_WALLET_TABLE_HEADER_KEYS.ACTIVATION_DATE],
  ],
};
export const QRPAY_FORM_VIEWS = {
  ACCOUNTING: "accounting",
  MEMBER: "member",
};
export const SELECTED_TABS_USER_WALLET = [
  {
    name: "QRPay.userWallets.slider.navigationLabels.overview",
    count: null,
    key: 1,
  },
  {
    name: "QRPay.userWallets.slider.navigationLabels.history",
    count: null,
    key: 2,
  },
];

export const WALLET_TYPE_KEY = {
  WALLET: "wallet",
};
export const WALLET_TYPE_LABEL = {
  [WALLET_TYPE_KEY.WALLET]: "QRPay.userWallets.slider.walletType",
};
export const USER_WALLET_ALL_SLIDER_MENU_KEYS = {
  FREEZE_WALLET: "freeze",
  UNFREEZE: "unfreeze",
};
export const USER_WALLET_ALL_SLIDER_MENU_CONFIG = {
  [USER_WALLET_ALL_SLIDER_MENU_KEYS.FREEZE_WALLET]: {
    label: "QRPay.userWallets.ctas.freeze",
    key: USER_WALLET_ALL_SLIDER_MENU_KEYS.FREEZE_CARD,
    icon: "AcUnits",
  },

  [USER_WALLET_ALL_SLIDER_MENU_KEYS.UNFREEZE]: {
    label: "QRPay.userWallets.ctas.unfreeze",
    key: USER_WALLET_ALL_SLIDER_MENU_KEYS.UNFREEZE,
    icon: "AcUnits",
  },
};
export const QRPAY_GENERAL_SETTINGS_CONFIG = [
  {
    title: "Split Payments",
    description: "Allow employees to split payemnts",
    child: {
      type: "switch",
      title: "Enable split payments ",
      description:
        "Allow admins the ability to enable/disable splitting payments",
    },
  },
];

export const FAILURE_DETAILS_KEYS = {
  TOTAL_AMOUNT: "totalAmount",
  TOTAL_FAILURES: "totalFailures",
  MOST_COMMON_REASON: "mostCommonReason",
};
export const CATEGORY_TYPES = {
  SET_ACCOUNTING_CATEGORY: "SET_ACCOUNTING_CATEGORY",
  SET_ACCOUNTING_VENDOR: "SET_ACCOUNTING_VENDOR",
  SET_ACCOUNTING_DATE: "SET_ACCOUNTING_DATE",
  SET_VOLOPAY_CATEGORY: "SET_VOLOPAY_CATEGORY",
  SET_NON_CATEGORY: "SET_NON_CATEGORY",
};
