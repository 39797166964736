import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Text from "@/components/core/Text";

import {
  ROLE_TITLES,
  ROLE_TITLES_LABELS,
  ROLE_TITLES_LABELS_PLURAL,
} from "@/constants/user";

export const groupingFunction = (user, t = (a) => a) => {
  const retVal = [ROLE_TITLES.ADMIN, ROLE_TITLES.DEPARTMENT_MANAGER]
    .map((role) => {
      if (!(user?.role === role || user?.roles?.includes(role))) return null;

      return {
        groupKey: role,
        label: user.displayName || user.name,
        icon: "Admin",
        subLabel: [t(ROLE_TITLES_LABELS[role]), user.department, user.location]
          .filter((textItem) => textItem?.length > 0)
          .join(" • "),
      };
    })
    .filter((item) => item !== null);

  return retVal;
};

function getHeaderNodeUI({
  groupKey,
  selectedIds,
  allIds,
  isGroupFull,
  t = (a) => a,
}) {
  return {
    label: t(ROLE_TITLES_LABELS_PLURAL[groupKey]), // since groupkey is the `role`, here
    // subLabel: `${allIds.size} people${isGroupFull ? " (full)" : ""}`,
    subLabel: (
      <div className="text-xs font-medium text-neutral-400">
        {allIds.size === 1 ? (
          <Text translationKey="billPay.settings.sliders.misc.onePerson" />
        ) : (
          <Text
            translationKey="billPay.settings.sliders.misc.xPeople"
            translationProps={{ peopleCount: allIds.size }}
          />
        )}
        {selectedIds.size > 0 ? (
          <>
            {" • "}
            <Text
              classes="text-success-600 font-medium text-xs"
              translationKey={
                isGroupFull
                  ? "billPay.settings.sliders.misc.allSelected"
                  : "billPay.settings.sliders.misc.xSelected"
              }
              translationProps={
                isGroupFull ? {} : { selectedCount: selectedIds.size }
              }
            />
          </>
        ) : null}
      </div>
    ),
  };
}
export default function UserDropdown({
  users,
  dropdownLabelFastView = "User groups:",
  dropdownLabelFlatView = "All users:",
  selectedIdsArray = [],
  label,
  groupAdminAndManager,
  showPills = true,
  hideDisplayLabel,
  ...rest
}) {
  const { t } = useTranslation();
  return (
    <CheckboxDropdown
      mode="grouped"
      showPills={showPills}
      label={label || "misc.select"}
      options={users?.map((user) => ({
        ...user,
        label: user.displayName || user.name,
        subLabel: [
          t(ROLE_TITLES_LABELS[user?.role || user?.roles?.at(-1)]),
          user.departmentName,
          user.locationName,
        ]
          .filter((textItem) => textItem?.length > 0)
          .join(" • "),
      }))}
      hideDisplayLabel={hideDisplayLabel}
      groupingFunction={groupAdminAndManager ? groupingFunction : () => []}
      selectedIdsArray={selectedIdsArray}
      getHeaderNodeUI={groupAdminAndManager ? getHeaderNodeUI : null}
      dropdownLabelFastView={dropdownLabelFastView}
      dropdownLabelFlatView={dropdownLabelFlatView}
      headerLabelsMap={{
        Admin: {
          label: ROLE_TITLES_LABELS_PLURAL[ROLE_TITLES.ADMIN],
          pillClasses: "bg-neutral-200 text-neutral-500",
          showDeleteHandler: false,
        },
        department_manager: {
          label: ROLE_TITLES_LABELS_PLURAL[ROLE_TITLES.DEPARTMENT_MANAGER],
        },
      }}
      {...rest}
    />
  );
}

UserDropdown.propTypes = {
  users: PropTypes.array.isRequired,
  selectedIdsArray: PropTypes.array,
  dropdownLabelFastView: PropTypes.string,
  dropdownLabelFlatView: PropTypes.string,
  label: PropTypes.string,
  showPills: PropTypes.bool,
};
