import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// custom hooks,utils & constants

// reducers
import { connectToNetsuite } from "@/store/reducers/accounting";

// selectors
import { isLoadingAccountingIntegrationsSelector } from "@/store/selectors/accounting";

// core components
import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpLink from "@/components/core/vp-link";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import { useForm } from "@/utils/useForm";
import {
  INTEGRATION_NETSUITE_CONNECT_HELP_URL,
  NETSUITE_INTEGRATIONS_HIDDEN_INPUT,
  NETSUITE_INTEGRATIONS_INPUT_CONFIG,
} from "@/utils/constants/integrations";

// constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARES } from "@/constants/accounting";

export default function NetsuiteConnectSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isLoading = useSelector(isLoadingAccountingIntegrationsSelector);

  const initialValue = {
    accountID: {
      value: "",
      validate: {
        required: true,
      },
    },
    consumerKey: {
      value: "",
      validate: {
        required: true,
      },
    },
    consumerSecret: {
      value: "",
      validate: {
        required: true,
      },
    },
    tokenID: {
      value: "",
      validate: {
        required: true,
      },
    },
    tokenSecret: {
      value: "",
      validate: {
        required: true,
      },
    },
    deployVersion: {
      value: "",
      validate: {
        required: true,
      },
    },
    scriptID: {
      value: "",
      validate: {
        required: true,
      },
    },
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, () => {
      handleConnect();
    });

  const handleConnect = async () => {
    const action = await dispatch(
      connectToNetsuite({
        slug: ACCOUNTING_SOFTWARES.NETSUITE,
        company_id: values.accountID,
        consumer_key: values.consumerKey,
        consumer_secret: values.consumerSecret,
        access_token: values.tokenID,
        token_secret: values.tokenSecret,
        deploy_version: values.deployVersion,
        script_id: values.scriptID,
      })
    );

    if (connectToNetsuite.fulfilled.match(action)) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.accounting.integrations.sync,
        true
      );
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="flex flex-col gap-11.5 slider-content-container">
      <div className="flex flex-col gap-8 px-10">
        <SliderHeader
          heading="accounting.integrations.netsuite.title"
          headingClasses="text-4xl font-bold"
          refFlag
        />
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-9"
          id="accounting-connect-netsuite"
        >
          <div className="flex flex-col gap-3 p-4 border bg-neutral-50 border-neutral-300 rounded-xl">
            <SliderHeader
              heading="accounting.integrations.labels.pleaseNote"
              subText="accounting.integrations.support.description"
              headingClasses="text-base font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <VpLink
              text="accounting.integrations.linkLabel.learnMore"
              onClickHandler={() => {
                window.open(INTEGRATION_NETSUITE_CONNECT_HELP_URL, "_blank");
              }}
            />
          </div>

          <div className="flex flex-col gap-4 ">
            <Text
              translationKey="accounting.integrations.labels.setup"
              classes="text-xl font-bold text-neutral-800"
            />
            {Object.entries(values)?.map(([key, value], index) => (
              <Input
                key={index}
                insideForm
                name={key}
                label={NETSUITE_INTEGRATIONS_INPUT_CONFIG[key].label}
                placeholder={
                  NETSUITE_INTEGRATIONS_INPUT_CONFIG[key].placeholder
                }
                value={values[key]}
                error={errors[key]}
                allowPasswordEye={NETSUITE_INTEGRATIONS_HIDDEN_INPUT.some(
                  (substring) => key.toLowerCase().includes(substring)
                )}
                onChange={handleChange}
              />
            ))}
          </div>
        </form>
      </div>
      <div className="sticky p-6 slider-footer ">
        <div className="flex justify-end ">
          <Button
            label="accounting.integrations.buttonLabel.connect"
            classes="w-14.75 px-5 py-3 text-btn-lg font-semibold"
            btnType="submit"
            showLoader={isLoading}
            disabled={isFormButtonDisabled}
            form="accounting-connect-netsuite"
            id={`connect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
          />
          {isFormButtonDisabled ? (
            <Tooltip
              direction="top"
              id={`connect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
            >
              <Text translationKey="accounting.integrations.toolTipText.allInputFieldsMandatory" />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}
