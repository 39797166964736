import PropTypes from "prop-types";
import React, { useState } from "react";

import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import { CATEGORY, CURRENCY_ARRAY } from "@/utils/constants/reimbursement";
import { dateToString } from "@/utils/common";

export default function ApprovalMerchantRequestDetails({
  amount,
  category,
  transactionDate,
  merchant,
}) {
  return (
    <>
      <div className="w-full mt-5">
        <Text
          classes="text-sm text-neutral-500 font-normal"
          translationKey="reimbursement.approvals.sliderHeadings.requestDetailsComponent.merchant"
        />
        <Input
          name="merchant"
          onChange={() => {}}
          type="default"
          disabled
          classes="text-black"
          value={merchant?.name}
        />
      </div>

      <div className="flex items-center justify-between gap-10 mt-6">
        <div className="w-full">
          <Text
            classes="text-sm text-neutral-500 font-normal"
            translationKey="reimbursement.approvals.sliderHeadings.requestDetailsComponent.amount"
          />
          <Input
            name="amount"
            onChange={() => {}}
            type="default"
            disabled
            classes="text-black"
            value={amount.value}
          />
        </div>
        <div className="w-full">
          <Text
            classes="text-sm text-neutral-500 font-normal"
            translationKey="reimbursement.approvals.sliderHeadings.requestDetailsComponent.currency"
          />

          <Input
            name="currency"
            onChange={() => {}}
            type="default"
            disabled
            value={amount.currency}
            classes="text-black"
          />
        </div>
      </div>
      <div className="mt-5">
        <Text
          classes="text-neutral-500 font-normal"
          translationKey="reimbursement.approvals.sliderHeadings.requestDetailsComponent.volopayCategory"
        />

        <Input
          name="volopay_category"
          onChange={() => {}}
          type="default"
          disabled
          value={category.label}
          classes="text-black"
        />
      </div>
      <div className="mt-5">
        <Text
          classes="text-sm text-neutral-500 font-normal"
          translationKey="reimbursement.approvals.sliderHeadings.requestDetailsComponent.transactionDate"
        />
        <Input
          name="transaction_date"
          onChange={() => {}}
          type="default"
          value={dateToString(transactionDate)}
          disabled
          classes="text-black"
          description="reimbursement.approvals.sliderHeadings.requestDetailsComponent.transactionDateDesc"
          rightText={<Icon className="text-neutral-500" name="Calendar" />}
        />
      </div>
    </>
  );
}
ApprovalMerchantRequestDetails.propTypes = {
  amount: PropTypes.object,
  category: PropTypes.object,
  transactionDate: PropTypes.string,
  merchant: PropTypes.object,
};
