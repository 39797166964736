import PropTypes from "prop-types";

import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Image from "@/components/core/Image";

import { PAYMENT_OPTION_TYPE } from "@/constants/company";

export default function PaymentOptionIcon({ paymentOptionType, currency }) {
  let pictureComponent = null;
  switch (paymentOptionType) {
    case PAYMENT_OPTION_TYPE.ADDFUND:
      pictureComponent = (
        <span className="grid w-10 h-10 rounded-full bg-integrations-50 place-items-center">
          <Icon name="AddBusiness" className="text-integrations-500" />
        </span>
      );
      break;
    case PAYMENT_OPTION_TYPE.LOCAL:
    case PAYMENT_OPTION_TYPE.GLOBAL:
      pictureComponent = (
        <Flag
          code={currency || "USD"}
          classes="h-10 w-10 border-2 border-neutral-100"
        />
      );
      break;
    case PAYMENT_OPTION_TYPE.PAYNOW:
      pictureComponent = (
        <Image
          name="PayNOW"
          alt="pay-now-icon"
          className="w-10 h-10 border-2 rounded-full border-neutral-100"
        />
      );
      break;
    case PAYMENT_OPTION_TYPE.SWIFT:
      pictureComponent = (
        <span className="grid w-10 h-10 rounded-full bg-primary-100 place-items-center">
          <Icon name="SwiftLogo" className="text-primary-500" />
        </span>
      );
      break;
    default:
      break;
  }
  return pictureComponent;
}

PaymentOptionIcon.propTypes = {
  currency: PropTypes.string,
  paymentOptionType: PropTypes.string.isRequired,
};
