import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  PAYMENTS_SLICE_ATTRIBUTE_KEY,
  fetchPayments,
  resetPaymentStore,
  setPayment,
  setPayments,
} from "@/store/reducers/payments";

import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  countChangesSelector,
  isPaymentsFetchingSelector,
  paidPageFilterKeysSelector,
  paymentsHasMoreSelector,
  paymentsListSelector,
  paymentsTotalSelector,
} from "@/store/selectors/payments";

import Table from "@/components/core/Table";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import { getPaidTableDefaultHeader } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Paid/tableConfig";
import PaymentsTableLoader from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentsTableLoader";
import BillPayFilters from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/BillPayFilters";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import { convertFilters } from "@/utils/filters";
import { handleExport } from "@/utils/exports";
import {
  SORTING_CATEGORY_BILLPAY,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { PAYMENTS_TABS } from "@/utils/constants/payments";
import { debounce } from "@/utils/common";

import { EXPORT_PAGE } from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function PaidList({ pageType, page, openSliderHandler }) {
  const dispatch = useDispatch();
  const payments = useSelector((state) => paymentsListSelector(state));
  const isFetching = useSelector((state) => isPaymentsFetchingSelector(state));
  const hasMore = useSelector((state) => paymentsHasMoreSelector(state));
  const totalPayments = useSelector(paymentsTotalSelector);

  const countChanges = useSelector((state) => countChangesSelector(state));
  const isEmpty = !payments?.length;
  const [showMenu, setShowMenu] = useState(false);

  const [searchText, setSearchText] = useState("");
  const filters = useSelector(paidPageFilterKeysSelector);
  const appliedFilters = useSelector(appliedFilterSelector);

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
    defaultCategory: SORTING_CATEGORY_BILLPAY.PAYMENT_DATE,
    defaultType: SORTING_TYPE.DEC,
  });
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchHandler = debounce((text) => {
    setSearchText(text);
  }, 1000);

  const filterOptions = {
    status: pageType,
    q: searchText,
    ...convertFilters(appliedFilters),
  };
  const onReset = () => {
    dispatch(resetPaymentStore());
  };

  const { pathname: currentPath } = useLocation();
  const tabListingQueryParams = PAYMENTS_TABS.find(
    (tab) => tab.path === currentPath
  )?.defaultQueryParams;

  const loadMore = () => {
    dispatch(
      fetchPayments({
        context: page,
        value: {
          page: pageNum,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          payroll: page === BILL_PAYROLL_CONTEXT.PAYROLL,
          ...tabListingQueryParams,
          ...filterOptions,
          [SORT_KEYS.COLUMN]: sorting?.category || sorting?.defaultCategory,
          [SORT_KEYS.DIRECTION]: sorting?.type || sorting?.defaultType,
        },
        key: PAYMENTS_SLICE_ATTRIBUTE_KEY,
      })
    );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...filterOptions,
      ...sorting,
    },
  });

  const headerSelected = headerSelectAll; // all selected or else unchecked

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedPayments(payments.map((payment) => payment.id));
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedPayments([]);
    }
  };

  const rowSelectionHandler = (payment) => {
    if (selectedPayments?.includes(payment?.id)) {
      setSelectedPayments((prev) => prev.filter((id) => id !== payment?.id));
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, payment?.id]);
      }
    } else {
      setSelectedPayments((prev) => [...prev, payment?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== payment?.id));
    }
    dispatch(
      setPayment({
        id: payment.id,
        value: {
          ...payment,
          selected: !payment.selected,
        },
      })
    );
  };

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);
      if (!deSelectedIds.length) {
        setSelectedPayments(payments?.map((data) => data.id));
      } else {
        const filteredIds = payments
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedPayments(filteredIds);
      }
    }
  }, [bulkSelectAll, payments, deSelectedIds]);

  useEffect(() => {
    // When user selects checkbox from header
    setHeaderSelectAll(
      (selectedPayments.length === pageNum * PAGINATION_PER_REQUEST_LIMIT ||
        selectedPayments.length === payments?.length) &&
        !isEmpty
    );
  }, [selectedPayments, pageNum, payments]);

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.25,
  });

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
    emptyDataTitle,
    emptySubHeadingDataTitle,
  } = getPaidTableDefaultHeader(page);

  return (
    <>
      <BillPayFilters filters={filters} page={page} tab={pageType} payment />
      <div>
        <Table
          {...{
            ...tableSetting,
            emptyDataTitle,
            emptyDataDescription: emptySubHeadingDataTitle,
          }}
          bulkApproveVisible={selectedPayments?.length > 0}
          bulkApproveHeight="100px"
          bulkApproveContent={
            <BulkActionComponent
              selectedRows={
                deSelectedIds.length
                  ? totalPayments - deSelectedIds.length
                  : selectedPayments?.length
              }
              totalRows={totalPayments}
              descriptionText="common.exportDesc"
              bulkApproveSelectAll={
                (bulkSelectAll && !deSelectedIds.length) ||
                selectedPayments?.length === totalPayments
              }
              showSelectAllButton={headerSelectAll}
              selectAllHandler={(val) => {
                setDeSelectedIds([]);
                setBulkSelectAll(val);
              }}
              handleExport={() => {
                handleExport(
                  false,
                  selectedPayments?.length,
                  appliedFilters,
                  dispatch,
                  {
                    export_type: EXPORT_PAGE.PAYMENT,

                    tab: pageType,
                    ...(page === BILL_PAYROLL_CONTEXT.PAYROLL
                      ? { payroll: true }
                      : {}),

                    ...(bulkSelectAll
                      ? page === BILL_PAYROLL_CONTEXT.PAYROLL
                        ? {
                            payroll: true,
                            status: pageType,
                            deselected_ids: deSelectedIds,
                          }
                        : {
                            status: pageType,
                            deselected_ids: deSelectedIds,
                          }
                      : { selected_ids: selectedPayments }),
                  }
                );
              }}
              showExport
              clearSelection={() => {
                setBulkSelectAll(false);
                setHeaderSelectAll(false);
                setSelectedPayments([]);
                setDeSelectedIds([]);
                dispatch(
                  setPayments(
                    payments.map((payment) => {
                      return { ...payment, selected: !payment.selected };
                    })
                  )
                );
              }}
            />
          }
        >
          <tr className={tableHeadingRowClasses}>
            {tableHeading.map((headVal, index) => (
              <th className={headVal.classes} key={headVal.id}>
                <HeaderCell
                  val={{
                    ...headVal,
                    onCheckboxClick: headerSelectionHandler,
                    checkedValue: headerSelected && !isEmpty,
                    showCheckbox: index === 0 && !isFetching,
                    disabled: isEmpty,
                    sorting,
                    setSorting,
                  }}
                />
              </th>
            ))}
          </tr>

          {(!isFetching || !isEmpty) &&
            payments.map((val, index, arr) => (
              <tr
                key={val.id}
                className={`${tableDataRowClasses}`}
                onClick={() => openSliderHandler(val?.id)}
                ref={(ref) => {
                  if (index === arr.length - 1) handleRefChange(ref);
                }}
              >
                {tableHeading.map(
                  ({ cellComponent: Component, classes, id }) => (
                    <td
                      className={`${classes} ${
                        selectedPayments?.includes(val)
                          ? "selected-row-cell"
                          : ""
                      }`}
                      key={id}
                    >
                      <Component
                        val={{
                          ...val,
                          tabType: pageType,
                          context: page,
                          showCheckbox: true,
                          openSliderHandler,
                          onCheckboxClick: () => rowSelectionHandler(val),
                          checkedValue: selectedPayments?.includes(val?.id),
                        }}
                        key={id}
                      />
                    </td>
                  )
                )}
              </tr>
            ))}
          {isFetching && <PaymentsTableLoader />}
        </Table>
      </div>
    </>
  );
}

PaidList.propTypes = {
  openSliderHandler: PropTypes.func,
  pageType: PropTypes.string,
  page: PropTypes.string,
};
