import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  setSelectedEditFormDetails,
  setSelectedUserRoleChangeConfirmation,
} from "@/store/reducers/user";

import { primaryRolesSelector } from "@/store/selectors/company";
import { selectedUserSelector } from "@/store/selectors/user";

import Text from "@/components/core/Text";

import ImportantToKnowSection from "@/components/Company/common/ImportantToKnowSection";
import { formatAndCleanUpCaptchaText } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import InputCaptchaCheck from "./InputCaptchaCheck";

function SwitchRole({ setOnBack }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedUser = useSelector(selectedUserSelector);

  const displayName = selectedUser?.displayName;
  const switchRoleDescription =
    "company.people.switchRoleSlider.subjectiveWarningText";
  const captchaMatchText = `SWITCH ${
    formatAndCleanUpCaptchaText(selectedUser?.displayName) ||
    selectedUser?.email
  }`;

  const switchRoleToId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.people.switchRoleTo
  );

  const primaryRolesOptions = useSelector(primaryRolesSelector);

  const roleName = primaryRolesOptions
    .find((a) => {
      return a.id === parseInt(switchRoleToId, 10);
    })
    ?.name?.toLowerCase();

  const config = [
    {
      verticalExpandable: false,
      labelConfig: {
        label:
          roleName === "admin"
            ? "company.people.switchRoleSlider.captchaConfig.admin.actions"
            : "company.people.switchRoleSlider.captchaConfig.member.permissions",
        classes: "text-sm font-semibold text-neutral-800",
      },
      key: "expandable-1",
    },
    {
      verticalExpandable: false,
      labelConfig: {
        label:
          roleName === "admin"
            ? "company.people.switchRoleSlider.captchaConfig.admin.customRole"
            : "company.people.switchRoleSlider.captchaConfig.member.myVolopay",
        classes: "text-sm font-semibold text-neutral-800",
      },
      key: "expandable-2",
    },
    {
      verticalExpandable: false,
      labelConfig: {
        label:
          roleName === "admin"
            ? "company.people.switchRoleSlider.captchaConfig.admin.copilot"
            : "company.people.switchRoleSlider.captchaConfig.member.approvalPolicy",
        classes: "text-sm font-semibold text-neutral-800",
      },
      key: "expandable-3",
    },
  ];

  function closeRoleChange() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.switchRole);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.switchRoleTo);
    setSearchParams(searchParams);
  }

  function handleAction(value) {
    dispatch(setSelectedUserRoleChangeConfirmation(value));
    if (!value) dispatch(setSelectedEditFormDetails({}));
    closeRoleChange();
  }

  function getAction(cta) {
    if (selectedUser.role.id === parseInt(switchRoleToId, 10)) {
      return false;
    }

    if (cta === "cancel") {
      return false;
    }
    return true;
  }
  useEffect(() => {
    // Clean up on back
    setOnBack(() => {
      dispatch(setSelectedEditFormDetails({}));
      dispatch(setSelectedUserRoleChangeConfirmation(false));
    });
  }, []);
  return (
    <>
      <div className="flex flex-col gap-10 p-10 pt-0">
        <div className="flex flex-col gap-3">
          <Text
            translationKey="company.people.switchRoleSlider.heading"
            classes="text-3xl font-bold"
          />

          <Text
            translationKey="company.people.switchRoleSlider.headingDescription"
            classes="text-neutral-500 text-sm"
          />
        </div>
        <div className="flex flex-col gap-3">
          <Text
            classes="text-xl font-semibold"
            translationKey={switchRoleDescription}
            translationProps={{ displayName, roleName }}
          />

          <div className="mb-4">
            <Text
              translationKey="company.people.switchRoleSlider.warning"
              classes="text-neutral-500 text-sm"
            />
          </div>

          <ImportantToKnowSection
            mainLabelConfig={{
              label: "company.people.switchRoleSlider.importantToKnow",
              classes: "text-warning-500 text-sm",
            }}
            config={config}
          />
        </div>
      </div>

      <InputCaptchaCheck
        handleConfirmButton={() => handleAction(getAction("confirm"))}
        handleCancelButton={() => handleAction(getAction("cancel"))}
        outsideInputDivClasses="m-10 mt-0"
        captchaMatchText={captchaMatchText}
      />
    </>
  );
}

export default SwitchRole;
