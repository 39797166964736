import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

function GettingStartedLoader() {
  return (
    <div className="py-5 bg-white border divide-y rounded-lg divide-neutral-200 border-neutral-200">
      {[...new Array(10)].map((_, index) => (
        <div className="flex gap-4 px-8" key={index}>
          <LoaderSkeleton type="circle" size={[60, 60]} />
          <div className="flex flex-col my-2">
            <LoaderSkeleton size={[30, 250]} />
            <LoaderSkeleton size={[10, 350]} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default GettingStartedLoader;
