import {
  SETTLEMENT_STATUS,
  TRANSACTION_TYPE_FILTER_VALUE,
} from "@/utils/constants/filters";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";

import { EXPENSE_STATUSES } from "./expense";

export const DATA_EXCEEDED = "DATA_EXCEEDED";
export const DATA_EMPTY = "DATA_EMPTY";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAILED = "DELETE_FAILED";

export const EXPORT_STATUS = {
  ucsvInitiated: "ucsvInitiated",
  initiated: "initiated",
  ready: "READY",
  processing: "PROCESSING",
  failed: "FAILED",
};

export const EXPORT_EMAIL_STATUS = {
  EMAIL_SENT: "SENT",
  EMAIL_NOT_SENT: "NOT_SENT",
};

export const EXPORTS_EMAIL_STATUS_CONFIG = {
  [EXPORT_EMAIL_STATUS.EMAIL_SENT]: {
    text: "Success",
    description:
      "The requested statement will be sent on email and will also be available for download once it is ready.",
  },
  [EXPORT_EMAIL_STATUS.EMAIL_NOT_SENT]: {
    text: "Statement could not be sent on email",
    textClasses: "text-danger-400",
  },
};

export const EXPORTS_TOAST_CONFIG = {
  [EXPORT_STATUS.initiated]: {
    title: "Export initiated!",
    description:
      "<a style='color:#8ab4f8;text-decoration: underline;' href='{{downloadsPagePath}}'>Click here</a> to download once processing is complete",
    variant: "success",
  },
  [EXPORT_STATUS.ucsvInitiated]: {
    title: "UCSV Export initiated!",
    description:
      "<a style='color:#8ab4f8;text-decoration: underline;' href='{{downloadsPagePath}}'>Click here</a> to download once processing is complete",
    variant: "success",
  },
  [EXPORT_STATUS.ready]: {
    title: "Success!",
    description:
      "The requested export of {{fileName}} is now ready to download.",
    variant: "success",
  },
  [EXPORT_STATUS.processing]: {
    title: "Processing export",
    description:
      "Your export is now processing & will be ready to download shortly",
    variant: "warning",
  },
  [EXPORT_STATUS.failed]: {
    title: "Export failed",
    description:
      "Failed! Your export couldn't be processed. Please <a style='color:#8ab4f8;text-decoration: underline;' href='{{linkName}}'>Click here</a> to retry",
    variant: "danger",
  },
  [DATA_EXCEEDED]: {
    title: "Export failed: Transaction limit exceeded",
    description: "Apply filter for date range and try again",
    variant: "danger",
  },
  [DATA_EMPTY]: {
    title: "Export failed",
    description: "The data limit is empty",
    variant: "danger",
  },
  [DELETE_SUCCESS]: {
    title: "Export deleted",
    variant: "success",
  },
  [DELETE_FAILED]: {
    title: "Failed to delete export",
    variant: "danger",
  },
};

export const EXPORT_DURATION_KEYS = {
  TODAY: "TODAY",
  YESTERDAY: "YESTERDAY",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  CURRENT_STATEMENT: "CURRENT_STATEMENT",
  PREVIOUS_STATEMENT: "PREVIOUS_STATEMENT",
};

export const EXPORT_PAGE = {
  EXPENSE: "Expense",
  CARDS: "Card",
  PURCHASE_BILL: "PurchaseBill",
  PAYROLL: "Payroll",
  REIMBURSEMENT: "Reimbursement",
  INVOICE: "Invoice",
  PAYMENT: "Payment",
  LEDGER: "Ledger",
  ACCOUNTING_TRANSACTION: "AccountingTransaction",
  VENDOR: "Vendor",
  REPORT: "Report",
  REIMBURSEMENT_SETTLEMENT: "ReimbursementSettlement",
  CLAIM: "Claim",
  CARD_REQUEST: "CardRequest",
  USER: "User",
};

export const EXPORT_PAGE_TYPE = {
  CARD_REQUEST: { export_type: EXPORT_PAGE.CARD_REQUEST },
  EXPENSE: { export_type: EXPORT_PAGE.EXPENSE },
  CARDS: { export_type: EXPORT_PAGE.CARDS },
  PURCHASE_BILL: { export_type: EXPORT_PAGE.PURCHASE_BILL },
  REIMBURSEMENT: { export_type: EXPORT_PAGE.CLAIM },

  REIMBURSEMENT_SETTLEMENT: {
    export_type: EXPORT_PAGE.REIMBURSEMENT_SETTLEMENT,
  },
  PAYMENT: { export_type: EXPORT_PAGE.PAYMENT },
  LEDGER: { export_type: EXPORT_PAGE.LEDGER },
  REPORT: { export_type: EXPORT_PAGE.REPORT },
  USER: { export_type: EXPORT_PAGE.USER },
};

export const EXPORT_TAB_TYPE = {
  EXPENSE: {
    OVERVIEW: "overview",
    NEEDS_REVIEW: "needs-review",
    FLAGGED: "flagged",
    DECLINED: "declined",
  },
};

export const FILE_EXPORT_TYPE = {
  CSV: "csv",
  PDF: "pdf",
  UCSV: "ucsv",
};

export const EXPORT_FILTER_STATUS = {
  EXPENSE: {
    OVERVIEW: {
      settlement_status: [
        SETTLEMENT_STATUS.SETTLED,
        SETTLEMENT_STATUS.UNSETTLED,
      ],
      transaction_type: [
        TRANSACTION_TYPE_FILTER_VALUE.PURCHASE,
        TRANSACTION_TYPE_FILTER_VALUE.PURCHASE_REVERSAL,
      ],
    },
    NEEDS_REVIEW: {
      status: [EXPENSE_STATUSES.PENDING, EXPENSE_STATUSES.INCOMPLETE],
    },
    FLAGGED: { status: [EXPENSE_STATUSES.NOT_OKAY] },
    DECLINED: { transaction_type: [EXPENSE_STATUSES.DECLINED] },
  },
  PAYMENT: {
    ALL: {
      status: [
        PAYMENT_STATUSES.scheduled,
        PAYMENT_STATUSES.unscheduled,
        PAYMENT_STATUSES.processing,
        PAYMENT_STATUSES.unverified,
        PAYMENT_STATUSES.awaitingFunds,
      ],
    },
    FAILED: { status: [PAYMENT_STATUSES.failed] },
    PAID: { status: [PAYMENT_STATUSES.paid] },
  },
};

export const TOTAL_EXPORT_LIMIT = 18000;
