import PropTypes from "prop-types";
import { createSearchParams, useNavigate } from "react-router-dom";

import Text from "@/components/core/Text";

import SliderDataCard from "@/components/Accounting/Transactions/common/Slider/SliderDataCard";

import { CARD_FREQUENCY_CORE, CARD_TYPE } from "@/constants/Cards";
import { ROUTES } from "@/constants/routes";

export default function ActivityTab({ card }) {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-2 gap-5">
      <SliderDataCard
        nameKey="cards.cardDetails.editCardDetails.basicDetails.label"
        value={card?.cardHolder?.name}
        onLinkClick={createNavigationHandler(
          navigate,
          ROUTES.manage.people.absolutePath,
          { personId: card?.cardHolder?.id }
        )}
      />
      <SliderDataCard
        nameKey="cards.listingFields.department.header"
        value={card?.cardHolder?.department?.name}
        onLinkClick={createNavigationHandler(
          navigate,
          ROUTES.manage.company.departments.absolutePath,
          { departmentId: card?.department?.id }
        )}
      />
      {card?.type === CARD_TYPE.VIRTUAL && (
        <SliderDataCard
          nameKey="cards.cardDetails.editCardDetails.basicDetails.frequency"
          value={
            <Text
              translationKey={
                card?.frequency === CARD_FREQUENCY_CORE.RECURRING
                  ? "cards.listingFields.limit.frequency.monthly.label"
                  : "cards.listingFields.limit.frequency.oneTime.label"
              }
            />
          }
          noLink
          valueTooltipText={
            card?.frequency === CARD_FREQUENCY_CORE.RECURRING
              ? "cards.listingFields.limit.frequency.monthly.tooltip"
              : "cards.listingFields.limit.frequency.oneTime.tooltip"
          }
        />
      )}
      {card?.type === CARD_TYPE.VIRTUAL && (
        <SliderDataCard
          nameKey="cards.cardDetails.editCardDetails.advancedControls.fields.autoLockDate.label"
          value={card?.blockingDate ?? "-"}
          noLink
          nameTooltipText="cards.cardDetails.editCardDetails.advancedControls.fields.autoLockDate.tooltip"
        />
      )}
    </div>
  );
}

ActivityTab.propTypes = {
  card: PropTypes.object,
};

function createNavigationHandler(navigate, destination, queryObject) {
  return () =>
    navigate({
      pathname: destination,
      search: createSearchParams(queryObject).toString(),
    });
}
