import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchCards,
  resetCardsListAndPagination,
  resetCreateVirtualCardInput,
} from "@/store/reducers/cards";

import { indexApiReloadSelector } from "@/store/selectors/app";
import {
  cardsHasMoreSelector,
  isFetchingCardsListSelector,
  totalCardsSelector,
  virtualCardsList,
} from "@/store/selectors/cards";
import { cardsProvidersListItemSelector } from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";

import Button from "@/components/core/Button";
import Filters from "@/components/core/Filters";

import Export from "@/components/Exports";
import { convertFilters } from "@/utils/filters";
import { dateToString } from "@/utils/common";

import {
  CARDS_TABLE_HEADERS,
  CARDS_TABLE_HEADER_KEYS,
  CARD_FREQUENCY_CORE,
  CARD_FREQUENCY_LABEL,
  CARD_PROVIDER,
  CARD_STATUS,
  CARD_TYPE,
  VIRTUAL_CARD_TAB_STATUS_MAP,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EXPORT_PAGE_TYPE } from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  VIRTUAL_CARDS_ALL_PATH_NAME,
  VIRTUAL_CARDS_BLOCKED_PATH_NAME,
} from "@/constants/routes";

import CardsTable from "../CardsTable";
import VirtualCardPoolWidget from "../VirtualCardPoolWidget";

export default function VirtualCardsPageHelper({
  tab = VIRTUAL_CARDS_ALL_PATH_NAME,
  filterArray = [],
  params = {},
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cards = useSelector(virtualCardsList);
  const isFetching = useSelector(isFetchingCardsListSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const hasMore = useSelector(cardsHasMoreSelector);
  const appliedFilter = useSelector(appliedFilterSelector);
  const totalCardsSelected = useSelector(totalCardsSelector);
  const reloadIndexApi = useSelector(indexApiReloadSelector);
  const filterOptions = {
    ...VIRTUAL_CARD_TAB_STATUS_MAP[tab],
    ...convertFilters(appliedFilter),
    ...params,
    reloadIndexApi,
  };
  const cardProviderDetails = useSelector(cardsProvidersListItemSelector)?.[0];

  const showVirtualCardPoolWidget =
    tab === VIRTUAL_CARDS_ALL_PATH_NAME &&
    cardProviderDetails?.slug === CARD_PROVIDER.UOB;

  useEffect(() => {
    if (
      !searchParams.get(
        SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage
      )
    ) {
      dispatch(resetCreateVirtualCardInput());
    }
  }, [searchParams]);

  const onReset = () => {
    dispatch(resetCardsListAndPagination());
  };

  const loadMore = () => {
    dispatch(
      fetchCards({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...filterOptions,
        ...params,
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  function getEmptyTableInfo(physical_tab) {
    let title = "";
    let description = "";

    let descriptionComponent = <></>;

    switch (physical_tab) {
      case VIRTUAL_CARDS_BLOCKED_PATH_NAME:
        title = "cards.vCards.table.emptyData.tab.blocked.title";
        description = "cards.vCards.table.emptyData.tab.blocked.description";
        break;

      default:
        title = "cards.vCards.table.emptyData.tab.all.title";
        description = "cards.vCards.table.emptyData.tab.all.description";
        descriptionComponent =
          cardProviderDetails?.slug !== CARD_PROVIDER.UOB ? (
            <div className="my-4">
              <Button
                label="cards.vCards.orderButtonLabel"
                preIcon="Plus"
                classes="h-11 w-[calc(theme(spacing.16)+theme(spacing.5))] text-btn-lg font-semibold"
                onClick={() => {
                  searchParams.append(
                    SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage,
                    true
                  );
                  setSearchParams(searchParams);
                }}
              />
            </div>
          ) : null;
        break;
    }

    return { title, description, descriptionComponent };
  }

  const {
    title: emptyTableTitle,
    description: emptyTableDescription,
    descriptionComponent: emptyDataChildren,
  } = getEmptyTableInfo(tab);

  const formatDataForTable = (card) =>
    card
      ? {
          id: card.id,
          [CARDS_TABLE_HEADER_KEYS.CARDS]: {
            id: card.id,
            type: card.type,
            name: card.name,
            currency: card.currency,
            status: card.status,
            cardNumber: card.cardNumber,
            isTemporaryLimit: card.isTemporaryLimit,
            topUpRequests: card?.anyEditRequests,
            insufficientFunds: card?.topUpRequests?.insufficientFunds,
            topUpRequestTooltip: {
              amount: card.topUpRequests.amount,
              currency: card.topUpRequests.currency,
              frequency: t(CARD_FREQUENCY_LABEL?.[card?.newFrequency]),
            },
            temporaryLimitTooltip: {
              limit: card?.refreshLimit,
              createdOn: card?.createdAt,
              currency: card.currency,
              freqeuncy: card?.frequency,
            },
          },
          [CARDS_TABLE_HEADER_KEYS.CARD_HOLDER]: {
            index: card.id,
            displayName: card.cardHolder.displayName,
            role: card.cardHolder.roles,
            avatarUrl: card.cardHolder.avatarUrl,
            department: card.cardHolder.departmentName,
            location: card.cardHolder.locationName,
            onIconClick: () => handleCardHolderIconClick(card?.cardHolder?.id),
          },
          [CARDS_TABLE_HEADER_KEYS.LIMIT]: {
            amount: card.limit,
            currency: card.currency,
            frequency: card.frequency,
            isEditable: card?.status === CARD_STATUS.ACTIVE,
            tempLimit: card.isTemporaryLimit,
            onIconClick: () => handleEditIconOnLimitRowCell(card?.id),
          },
          [CARDS_TABLE_HEADER_KEYS.SPENT]: {
            spent:
              card.frequency === CARD_FREQUENCY_CORE.RECURRING
                ? card.monthlySpent
                : card.totalSpent,
            total: card.limit,
            currency: card.currency,
          },
          [CARDS_TABLE_HEADER_KEYS.AVAILABLE]: {
            availableBalance: card.availableBalance,
            currency: card.currency,
          },
          [CARDS_TABLE_HEADER_KEYS.DEPARTMENT]: {
            translationKey: card.cardHolder?.departmentName,
          },
          [CARDS_TABLE_HEADER_KEYS.LOCATION]: {
            translationKey: card.cardHolder.locationName,
          },
          [CARDS_TABLE_HEADER_KEYS.LINKED_TO]: {
            linkedToType: card?.linkedTo?.type,
            linkedToName: card?.linkedTo?.name,
          },
          [CARDS_TABLE_HEADER_KEYS.CREATED_ON]: {
            translationKey: dateToString(card.createdAt),
          },
        }
      : {};
  const handleRowClick = (Id) => {
    if (!searchParams.get(SLIDERS_SEARCH_PARAMS.cards.id)) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.cards.id, Id);
      setSearchParams(searchParams);
    }
  };
  const dataArray = useMemo(() => {
    return cards.map((card) => formatDataForTable(card));
  }, [cards]);

  function handleCardHolderIconClick(cardHolderId) {
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id)) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.id);
    }
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.people.id, cardHolderId);
    setSearchParams(searchParams);
  }

  function handleEditIconOnLimitRowCell(cardId) {
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.cards.editLimit)) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.editLimit);
    }

    searchParams.append(SLIDERS_SEARCH_PARAMS.cards.editLimit, cardId);
    setSearchParams(searchParams);
  }

  return (
    <>
      {showVirtualCardPoolWidget ? (
        <div className="my-4">
          <VirtualCardPoolWidget tab={tab} />
        </div>
      ) : null}

      <div className="flex items-start ">
        <div className="flex flex-col flex-1 w-11/12">
          <Filters filters={filterArray} />
        </div>

        <div className="flex justify-end mt-4">
          <Export
            totalExports={totalCardsSelected}
            sectionName="cards"
            storeName="cards"
            additionalFilters={{
              ...filterOptions,
              ...EXPORT_PAGE_TYPE.CARDS,
              tab,
            }}
          />
        </div>
      </div>
      <div className="mt-1">
        <CardsTable
          handleRowClick={handleRowClick}
          hasMore={hasMore}
          emptyDataTitle={emptyTableTitle}
          emptyDataDescription={emptyTableDescription}
          emptyDataChildren={emptyDataChildren}
          handleRefChange={handleRefChange}
          headers={CARDS_TABLE_HEADERS[CARD_TYPE.VIRTUAL]}
          data={dataArray}
          // isDepartmentClient={isDepartmentClient}
          isFetchingData={isFetching}
          classes={
            tab === VIRTUAL_CARDS_BLOCKED_PATH_NAME ? "text-neutral-500" : ""
          }
        />
      </div>
    </>
  );
}

VirtualCardsPageHelper.propTypes = {
  tab: PropTypes.oneOf([
    VIRTUAL_CARDS_ALL_PATH_NAME,
    VIRTUAL_CARDS_BLOCKED_PATH_NAME,
  ]),
  filterArray: PropTypes.array,
  params: PropTypes.object,
};
