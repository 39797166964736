import PropTypes from "prop-types";
import { useMemo } from "react";

import { getMonth, getYear } from "date-fns";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import VpSelect from "@/components/core/VpSelect";
import { range } from "@/utils/common";
import { MONTH_FULL_NAME } from "@/utils/constants/date";

/**
 * CustomHeader component for selecting and navigating through months and years.
 *
 * @param {Object} props - The component props.
 * @param {Date} props.date - The currently selected date.
 * @param {Function} props.changeYear - Function to change the selected year.
 * @param {Function} props.changeMonth - Function to change the selected month.
 * @param {Function} props.decreaseMonth - Function to decrease the selected month.
 * @param {Function} props.increaseMonth - Function to increase the selected month.
 * @param {boolean} props.prevMonthButtonDisabled - Flag to disable the previous month button.
 * @param {boolean} props.nextMonthButtonDisabled - Flag to disable the next month button.
 * @param {Date} props.minDate - The minimum selectable date.
 * @param {Date} props.maxDate - The maximum selectable date.
 * @returns {JSX.Element} The CustomHeader component.
 */
function CustomHeader({
  monthDate: date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  minDate = null,
  maxDate = null,
}) {
  const currentYear = getYear(new Date());
  const minYear = minDate ? getYear(minDate) : 1990;
  const maxYear = maxDate ? getYear(maxDate) : currentYear + 10;

  const YEAR_OPTIONS = useMemo(
    () => range(minYear, maxYear + 1, 1).map((i) => ({ id: i, label: i })),
    [minYear, maxYear]
  );

  const minMonth = minDate && getYear(date) === minYear ? getMonth(minDate) : 0;
  const maxMonth =
    maxDate && getYear(date) === maxYear ? getMonth(maxDate) : 11;

  const MONTHS_OPTIONS = useMemo(
    () =>
      MONTH_FULL_NAME.slice(minMonth, maxMonth + 1).map((month, index) => ({
        id: minMonth + index,
        label: month,
      })),
    [minMonth, maxMonth]
  );

  return (
    <div className="flex flex-row items-center justify-between">
      <Icon
        handleClick={prevMonthButtonDisabled ? () => {} : decreaseMonth}
        className={`cursor-pointer ${
          prevMonthButtonDisabled ? "text-neutral-100 cursor-not-allowed" : ""
        }`}
        name="IosArrowBack"
      />
      {MONTHS_OPTIONS?.length === 1 ? (
        <Text
          classes="text-center font-bold px-5 rounded-lg py-1 bg-neutral-100"
          translationKey={MONTH_FULL_NAME[getMonth(date)]}
          noTranslate
        />
      ) : (
        <VpSelect
          value={getMonth(date)}
          options={MONTHS_OPTIONS}
          noborder
          classes="w-15 border-none bg-neutral-100 font-bold rounded-lg"
          handleChange={(option) => changeMonth(option.id)}
        />
      )}
      <div id="year-picker">
        {YEAR_OPTIONS?.length === 1 ? (
          <Text
            classes="text-center bg-neutral-100 px-5 rounded-lg py-1 font-bold"
            translationKey={getYear(date)}
            noTranslate
          />
        ) : (
          <VpSelect
            value={getYear(date)}
            options={YEAR_OPTIONS}
            noborder
            classes="border-none font bg-neutral-100 font-bold rounded-lg"
            handleChange={(option) => changeYear(option.id)}
            styles={{ valueContainer: { padding: 2 } }}
          />
        )}
      </div>
      <Icon
        handleClick={nextMonthButtonDisabled ? () => {} : increaseMonth}
        className={`cursor-pointer ${
          prevMonthButtonDisabled ? "text-neutral-100 cursor-not-allowed" : ""
        }`}
        name="IosArrowForward"
      />
    </div>
  );
}

CustomHeader.propTypes = {
  monthDate: PropTypes.instanceOf(Date).isRequired,
  changeYear: PropTypes.func.isRequired,
  changeMonth: PropTypes.func.isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default CustomHeader;
