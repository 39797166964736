import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { defaultCurrencySelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";

import {
  ACTION_BUTTONS,
  CHECKBOX_CARD_SETTING,
  RULES_AND_ALERT_TYPE,
  SETTINGS_SEARCH_PARAM_VALUE,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { USER_ROLE } from "@/constants/company";

function RulesAndAlertSettingEditSlider({
  title,
  description,
  heading,
  headingColor,
  headingClasses = "",
  bottomText,
  departmentOptions,
  categoryOptions,
  handleSubmit,
  settingValueArray,
  merchantOptions,
  peopleOptions,
  selectedPeople,
  handleSelectedPeople = () => {},
  mode = RULES_AND_ALERT_TYPE.RULES,
}) {
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const [rulesConditions, setRulesConditions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [amount, setAmount] = useState(0);
  const [selectedValues, setSelectedValues] = useState({
    [CHECKBOX_CARD_SETTING.CATEGORY]: [],
    [CHECKBOX_CARD_SETTING.DEPARTMENT]: [],
    [CHECKBOX_CARD_SETTING.MERCHANT]: [],
  });

  const [selectedValuesstateIsValid, setSelectedValuesstateIsValid] =
    useState();

  const id = searchParams.get(
    SLIDERS_SEARCH_PARAMS.cards.cardsettings.editSettingId
  );
  const editSelectedValue = settingValueArray?.find(
    (val) => val.id === parseInt(id, 10)
  );

  useEffect(() => {
    if (editSelectedValue) {
      setSelectedValues({
        merchant: editSelectedValue?.merchant || [],
        category: editSelectedValue?.category || [],
        project: editSelectedValue?.project || [],
      });
      setAmount(editSelectedValue?.amount);
      handleSelectedPeople(editSelectedValue?.ownerIds);
      setRulesConditions(
        ACTION_BUTTONS.filter(
          (item) => editSelectedValue[item.vpSelectKey].length > 0
        )
      );
    }
  }, [searchParams]);

  useEffect(() => {
    const allSelectedRulesValues = rulesConditions
      ?.map((item) => item?.vpSelectKey)
      .map((item) => !!selectedValues[item]?.length);

    const hasUsersInSelectedRules =
      allSelectedRulesValues.length &&
      allSelectedRulesValues?.every((item) => item);

    const selectedValuesIsValid = isAlertSlider
      ? hasUsersInSelectedRules && selectedPeople && selectedPeople?.length > 0
      : hasUsersInSelectedRules;

    setSelectedValuesstateIsValid(selectedValuesIsValid);
  }, [selectedValues, selectedPeople, rulesConditions]);

  const ALL_OPTIONS = {
    [CHECKBOX_CARD_SETTING.CATEGORY]: categoryOptions,
    [CHECKBOX_CARD_SETTING.DEPARTMENT]: departmentOptions,
    [CHECKBOX_CARD_SETTING.MERCHANT]: merchantOptions,
  };

  const handleClick = (item) => {
    if (!rulesConditions?.filter((itemVal) => itemVal.id === item.id)?.length)
      setRulesConditions((prev) => [...prev, item]);
  };

  const isAlertSlider = mode === RULES_AND_ALERT_TYPE.ALERT;

  const userGroupingFunction = (user) => {
    let retVal = [];

    switch (user.role) {
      case USER_ROLE.EMPLOYEE:
        retVal = [];
        break;

      case USER_ROLE.ADMIN:
        retVal = [
          {
            groupKey: USER_ROLE.ADMIN,
            label: user.name,
            subLabel: `${user.role}`,
          },
        ];
        break;

      case USER_ROLE.DEPARTMENT_MANAGER:
        retVal = [
          {
            groupKey: USER_ROLE.DEPARTMENT_MANAGER,
            label: (
              <ProfileWidget
                className="flex flex-row-reverse justify-between"
                name={user.name}
              >
                {user.role}
              </ProfileWidget>
            ),
            subLabel: `${user.role}`,
          },
        ];
        break;

      default:
        break;
    }

    return retVal;
  };

  function getHeaderNodeUI({ groupKey, isGroupFull, selectedIds, allIds }) {
    switch (groupKey) {
      case USER_ROLE.ADMIN:
        return {
          label: USER_ROLE.ADMIN,
          subLabel2: `${allIds.size} people${isGroupFull ? " (full)" : ""}`,
          subLabel: (
            <div className="text-xs font-medium text-neutral-400">
              <span>{`${allIds.size} people`}</span>
              {selectedIds.size > 0 ? (
                <>
                  {" • "}
                  <span className="text-xs font-medium text-success-600">
                    {`${isGroupFull ? "All" : selectedIds.size} selected`}
                  </span>
                </>
              ) : null}
            </div>
          ),
        };
      case USER_ROLE.DEPARTMENT_MANAGER:
        return {
          label: "Department Managers",
          subLabel: (
            <div className="text-xs font-medium text-neutral-400">
              <span>{`${allIds.size} people`}</span>
              {selectedIds.size > 0 ? (
                <>
                  {" • "}
                  <span className="text-xs font-medium text-success-600">
                    {`${isGroupFull ? "All" : selectedIds.size} selected`}
                  </span>
                </>
              ) : null}
            </div>
          ),
          icon: "Sync",
        };

      default:
        break;
    }
  }

  function checkSelectedIsValidByGivenSelectKey(selectKey) {
    return selectedValues?.[selectKey]?.length > 0;
  }

  function handleDiscardBtnClick() {
    if (mode === RULES_AND_ALERT_TYPE.ALERT) {
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.cards.cardsettings.editSettingId
      );
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings,
        SETTINGS_SEARCH_PARAM_VALUE.PREVIEW
      );
    } else {
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings
      );
      setSearchParams(searchParams);
    }
    setSearchParams(searchParams);
  }

  return (
    <div className="slider-content-container tooltipCheckboxDropdown">
      <div className="slider-content-core px-9">
        <div className="flex flex-col pb-5">
          <Text
            color="neutral-800"
            classes="text-3xl font-bold"
            translationKey={title}
          />

          <Text
            color="neutral-500"
            classes="text-sm font-medium"
            translationKey={description}
          />
        </div>

        <div className="flex flex-col card-wrapper">
          <Text
            classes={`mb-7 ${headingClasses}`}
            color={headingColor}
            translationKey={heading}
          />

          <Input
            label="Amount"
            type="number"
            rightText={defaultCurrency}
            value={amount}
            onChange={(e) => setAmount(e?.target?.value)}
            labelExtraClasses="text-xs font-semibold text-neutral-500"
            classes={
              rulesConditions?.length > 0
                ? checkSelectedIsValidByGivenSelectKey(
                    rulesConditions?.[0]?.vpSelectKey
                  )
                  ? "mb-10"
                  : "mb-6"
                : ""
            }
          />

          <div className="flex flex-col gap-6 mb-6">
            {rulesConditions.map((conditions, index, arr) => (
              <>
                <div
                  className={`flex items-center justify-center ${
                    index > 0 &&
                    checkSelectedIsValidByGivenSelectKey(
                      conditions?.vpSelectKey
                    )
                      ? "mt-5"
                      : ""
                  }`}
                >
                  <Icon
                    name="SubdirectoryArrowRight"
                    className="mr-2 text-neutral-300"
                  />

                  <div className="w-full">
                    <CheckboxDropdown
                      selectedIdsArray={selectedValues[conditions?.vpSelectKey]}
                      handleSubmit={({ selectedIds }) => {
                        setSelectedValues((prev) => ({
                          ...prev,
                          [conditions?.vpSelectKey]: selectedIds,
                        }));
                      }}
                      label={conditions?.componentTitle}
                      options={ALL_OPTIONS[conditions?.vpSelectKey]}
                      labelExtraClasses="text-xs font-semibold text-neutral-500"
                    />
                  </div>
                </div>

                {index !== arr.length - 1 ? (
                  <Text
                    translationKey="accounting.rules.andSeparator"
                    classes="px-4 ml-6 py-0.5 text-neutral-500 text-sm font-medium rounded-xl w-fit bg-neutral-100 font-semibold mt-4"
                  />
                ) : null}
              </>
            ))}
          </div>

          <div className="flex gap-4">
            {ACTION_BUTTONS.filter(
              (obj) =>
                !rulesConditions.some((filterObj) => obj.id === filterObj.id)
            ).map((item, index) => (
              <Button
                key={index}
                disabled={!amount}
                onClick={() => handleClick(item)}
                iconClasses={item.iconClasses}
                innerClasses={item.textClasses}
                classes={item.classes}
                variant={item.variant}
                label={item.title}
                preIcon={item.icon}
              />
            ))}
          </div>

          {bottomText ? (
            <Text
              classes="text-sm font-semibold text-neutral-500"
              translationKey={bottomText}
            />
          ) : null}
        </div>

        {isAlertSlider ? (
          <div className="flex flex-col w-full mt-5 card-wrapper">
            <div className={selectedPeople?.length > 0 ? "mb-6" : "mb-3"}>
              <Text
                translationKey="cardSetting.expenseRules.people"
                classes="text-base font-medium text-neutral-800"
              />
            </div>

            <div>
              <CheckboxDropdown
                dropdownLabelFastView="User groups:"
                dropdownLabelFlatView="All users"
                label="Select"
                selectedIdsArray={selectedPeople}
                handleSubmit={({ selectedIds }) =>
                  handleSelectedPeople(selectedIds)
                }
                groupingFunction={userGroupingFunction}
                options={peopleOptions?.map((val) => ({
                  ...val,
                  label: val.name,
                }))}
                headerLabelsMap={{
                  admin: { label: "Admin:" },
                  department_manager: {
                    label: "Department Managers:",
                  },
                }}
                getHeaderNodeUI={getHeaderNodeUI}
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className="flex justify-end gap-5 px-3 py-5 slider-footer">
        <Button
          variant="tertiary"
          classes="text-neutral-500 w-16.2 h-10"
          label="Discard"
          onClick={handleDiscardBtnClick}
        />

        <Button
          disabled={!selectedValuesstateIsValid}
          onClick={() => {
            if (amount && selectedValuesstateIsValid)
              handleSubmit({
                id: editSelectedValue?.id,
                amount,
                ...selectedValues,
                ...(isAlertSlider ? { people: selectedPeople } : {}),
              });
          }}
          classes="w-16.2 h-10"
          label="Save"
        />
      </div>
    </div>
  );
}
RulesAndAlertSettingEditSlider.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  bottomText: PropTypes.string,
  headingColor: PropTypes.string,
  heading: PropTypes.string,
  departmentOptions: PropTypes.array,
  categoryOptions: PropTypes.array,
  merchantOptions: PropTypes.array,
  mode: PropTypes.string,
  handleSubmit: PropTypes.func,
  peopleOptions: PropTypes.array,
  settingValueArray: PropTypes.array,
  selectedPeople: PropTypes.array,
  handleSelectedPeople: PropTypes.func,
  headingClasses: PropTypes.string,
};
export default RulesAndAlertSettingEditSlider;
