import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

export default function Analytics() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ROUTES.analytics.transactions.cards.absolutePath);
  }, []);
  return (
    <div>
      <span className="text-2xl font-semibold">Analytics Transactions</span>
    </div>
  );
}
