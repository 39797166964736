import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import { fetchProjects, setInitialProjects } from "@/store/reducers/company";

import {
  isFetchingProjectsSelector,
  projectsFiltersSelector,
  projectsHasMoreSelector,
  projectsSelector,
} from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";

import LocationProjectAndDepartmentList from "@/components/Company/common/LocationProjectAndDepartmentList";
import { convertFilters } from "@/utils/filters";

import { PROJECT } from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function ProjectsTable() {
  const dispatch = useDispatch();
  const projects = useSelector(projectsSelector);
  const isFetching = useSelector(isFetchingProjectsSelector);
  const hasMore = useSelector(projectsHasMoreSelector);
  const isEmpty = !projects?.length;
  const [searchText, setSearchText] = useState("");
  const filters = useSelector(projectsFiltersSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const filterOptions = {
    q: searchText,
    ...convertFilters(appliedFilters),
  };
  const onReset = () => {
    dispatch(setInitialProjects());
  };
  const loadMore = () => {
    dispatch(
      fetchProjects({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,

        ...filterOptions,
      })
    );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  return (
    <div className="flex flex-col">
      <LocationProjectAndDepartmentList
        isEmpty={isEmpty}
        hasMore={hasMore}
        isFetching={isFetching}
        handleRefChange={handleRefChange}
        data={projects}
        type={PROJECT}
        filters={filters}
      />
    </div>
  );
}

export default ProjectsTable;
