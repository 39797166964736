import PropTypes from "prop-types";

import BillpayAndPayrollInvoiceInbox from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/List";

export default function InvoiceInboxPageHelper({ page, openSliderHandler }) {
  return (
    <div>
      <BillpayAndPayrollInvoiceInbox
        page={page}
        openSliderHandler={openSliderHandler}
      />
    </div>
  );
}

InvoiceInboxPageHelper.propTypes = {
  page: PropTypes.string,
  openSliderHandler: PropTypes.func,
};
