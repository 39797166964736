import { useSearchParams } from "react-router-dom";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARES } from "@/constants/accounting";

import IntegrationSettingsSliderComponent from "./IntegrationsSettingsSliderComponent";
// page relevant components
import TallySettingsSlider from "./TallySettingsSlider";

export default function IntegrationsSettingsSlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearchParam = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.integrations.softwareSettings
  );
  switch (currentSearchParam) {
    case ACCOUNTING_SOFTWARES.XERO:
    case ACCOUNTING_SOFTWARES.MYOB:
    case ACCOUNTING_SOFTWARES.QBO:
    case ACCOUNTING_SOFTWARES.NETSUITE:
    case ACCOUNTING_SOFTWARES.DESKERA:
    case ACCOUNTING_SOFTWARES.ZOHO:
    case ACCOUNTING_SOFTWARES.UNIVERSAL_CSV:
      return (
        <IntegrationSettingsSliderComponent
          accountingSoftware={currentSearchParam}
        />
      );
    case ACCOUNTING_SOFTWARES.TALLY:
      return <TallySettingsSlider />;
    default:
      return null;
  }
}
