import PropTypes from "prop-types";

import SettingText from "@/components/common/BillPayAndPayroll/Settings/common/SettingsList/SettingRow/SettingText";

/**
 * This is a row in the SettingsList (Setting page)
 *
 * @param {Object} tabRow - contains left and right side data
 *
 */
export default function SettingRow({ tabRow }) {
  return (
    <div aria-label="tab-row" className="flex gap-15 grow">
      <div aria-label="row-left-side-portion" className="w-[404px] shrink-0">
        <SettingText
          title={tabRow?.title}
          description={tabRow?.description}
          showSwitch={tabRow?.showSwitch}
          isSwitchOn={tabRow.isSwitchOn}
          titleClasses="text-neutral-800 font-semibold text-lg"
          descriptionClasses="text-neutral-500 font-medium text-sm"
          handleChange={tabRow?.handleChange}
        />
      </div>
      <div
        aria-label="row-right-side-portion"
        className="flex flex-col gap-10 grow"
      >
        {tabRow?.rightChildren?.map((childRow) =>
          (childRow?.showItem ?? true) ? (
            <SettingText
              key={`${childRow?.id}-${childRow.isViewChange}`}
              title={childRow.title}
              description={childRow.description}
              showSwitch={childRow.showSwitch}
              isSwitchOn={childRow.isSwitchOn}
              isSwitchLoading={childRow.isSwitchLoading}
              permissionType={childRow.permissionType}
              sliderId={childRow.sliderId}
              sliderType={childRow.sliderType}
              handleChange={childRow?.handleChange}
              disableMessage={childRow?.disableMessage}
              sectionClasses={childRow?.sectionClasses}
            />
          ) : null
        )}
      </div>
    </div>
  );
}

SettingRow.propTypes = {
  tabRow: PropTypes.object,
};
