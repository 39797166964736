import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { approveRequest, setIndexApiReload } from "@/store/reducers/app";
import { fetchCategories } from "@/store/reducers/categories";
import {
  bulkApprove,
  fetchExpenses,
  requestReceived,
  resetExpensesListAndPagination,
  updateExpense,
} from "@/store/reducers/expense";
import {
  fetchQrPayments,
  qrPaymentsBulkApprove,
  resetQrPaymentsListAndPagination,
  updateQrPayments,
} from "@/store/reducers/qr-payments";
import { fetchTags } from "@/store/reducers/tags";
import { fetchVendors } from "@/store/reducers/vendors";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  dataActionLoadingIdSelector,
  indexApiReloadSelector,
} from "@/store/selectors/app";
import { categoriesListSelector } from "@/store/selectors/categories";
import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";
import {
  dataActionInProgressIdSelector,
  expensesHasMoreSelector,
  expensesListSelector,
  expensesTotalSelector,
  isBulkApproveInProgressSelector,
  isExpensesFetchingSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isFetchingQrPayments,
  qrPaymentsBulkApproveSelector,
  qrPaymentsHasMoreSelector,
  qrPaymentsListSelector,
  qrPaymentsTotalSelector,
} from "@/store/selectors/qr-payments";
import {
  accountingCategoryTagsSelector,
  isTagsFetchedSelector,
} from "@/store/selectors/tags";
import { userSelector } from "@/store/selectors/user";

import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";

import CardHolder from "@/components/Cards/CardsTable/CardTableComponents/CardHolder";
import MemoNoteIcon from "@/components/common/MemoNoteIcon";
import ProfileWidgetWithCheckbox from "@/components/common/ProfileWidgetWithCheckbox";
import ActionCell from "@/components/common/QrPayAndExpense/common/Cells/ActionCell";
import CardUsageType from "@/components/common/QrPayAndExpense/common/Cells/CardUsageType";
import TableLoader from "@/components/common/QrPayAndExpense/common/ExpenseLoaders/TableLoader";
import BulkApproveComponent from "@/components/common/QrPayAndExpense/common/NeedsReview/NeedsReviewList/BulkApproveComponent";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import ReceiptIcon from "@/components/common/ReceiptIcon";
import SubmissionPolicyIcon from "@/components/common/SubmissionPolicyIcon";
import { convertFilters } from "@/utils/filters";
import {
  SORTING_CATEGORY,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";
import { FLAGGED_QRPAY_HEADER } from "@/utils/constants/qr-payments";
import { FLAGGED_EXPENSE_HEADER } from "@/utils/constants/expenses";
import { MEMO_NOTE_VARAINT } from "@/utils/constants/common";
import { amountToCurrency, dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ALL_ALLOWED_CTAS,
  EXPENSE_CTA_KEYS,
  EXPENSE_STATUSES,
  EXPENSE_TYPE,
} from "@/constants/expense";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function FlaggedList({
  viewExpenseHandler,
  page,
  filteringParams,
}) {
  const dispatch = useDispatch();
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;

  const expenseSelectors = {
    transaction: useSelector((state) => expensesListSelector(state)),
    isFetching: useSelector((state) => isExpensesFetchingSelector(state)),
    totalTransactions: useSelector(expensesTotalSelector),
    isApprovalInProgress: useSelector(isBulkApproveInProgressSelector),
    hasMore: useSelector(expensesHasMoreSelector),
    tableHeaders: FLAGGED_EXPENSE_HEADER,
    tableColWidth: [300, 200, 300, 250, 200, 120, 300, 300, 300],
    supplier: "merchant",
    date: "expenseDate",
  };

  const [reloadApi, setReloadApi] = useState(false);
  const qrPaymentsSelectors = {
    transaction: useSelector((state) => qrPaymentsListSelector(state)),
    isFetching: useSelector((state) => isFetchingQrPayments(state)),
    totalTransactions: useSelector(qrPaymentsTotalSelector),
    hasMore: useSelector(qrPaymentsHasMoreSelector),
    isApprovalInProgress: useSelector(qrPaymentsBulkApproveSelector),
    tableHeaders: FLAGGED_QRPAY_HEADER,
    tableColWidth: [300, 200, 300, 200, 200, 120, 300, 300, 300],
    supplier: "vendor",
    date: "paymentDate",
  };

  // common selectors for qrPay and expenses
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const isFetchedAccountingTags = useSelector(isTagsFetchedSelector);
  const isApprovalInProgress = useSelector(isBulkApproveInProgressSelector);
  const accountingVendorOptions = useSelector(accountingVendorsSelector);
  const accountingCategory = useSelector(accountingCategoryTagsSelector);
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const appliedFilter = useSelector(appliedFilterSelector);
  const currentUser = useSelector(userSelector);
  const categories = useSelector(categoriesListSelector);

  const dataActionInProgressId = useSelector(dataActionInProgressIdSelector);
  const approveActionInProgressId = useSelector(dataActionLoadingIdSelector);

  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const currentSelectors = isExpensesPage
    ? expenseSelectors
    : qrPaymentsSelectors;

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedVendors, setSelectedVendors] = useState({});
  const isEmpty = !currentSelectors?.transaction?.length;
  const [selectedRows, setSelectedRows] = useState([]);
  const [headerSelected, setHeaderSelected] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(null);
  // select total Expense
  const [totalExpenseSelected, setTotalExpenseSelected] = useState(false);
  const _setButtonHover = useCallback((index) => setButtonHovered(index), []);
  const [sorting, setSorting] = useState({
    type: SORTING_TYPE.DEC,
    category: SORTING_CATEGORY.DATE,
  });

  const handleSorting = (category) => {
    setSorting((prev) => {
      return {
        category,
        type:
          prev.type === SORTING_TYPE.INC ? SORTING_TYPE.DEC : SORTING_TYPE.INC,
      };
    });
  };
  const onReset = () => {
    const resetTransaction = isExpensesPage
      ? resetExpensesListAndPagination
      : resetQrPaymentsListAndPagination;
    dispatch(resetTransaction());
  };

  function getActionButtons(key, val) {
    switch (key) {
      case ALL_ALLOWED_CTAS.APPROVE:
        dispatch(
          approveRequest({
            payload: {
              target_id: val?.id,
              type: isExpensesPage
                ? EXPENSE_TYPE.EXPENSE
                : EXPENSE_TYPE.QR_PAYMENT,
            },
            onSuccess: () => {
              dispatch(setIndexApiReload(!reloadIndexApi));
            },
          })
        );
        break;

      case EXPENSE_CTA_KEYS.REQUEST_REPAYMENT:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.expenses.requestRepayment,
          val?.id
        );
        setSearchParams(searchParams);
        break;

      case EXPENSE_CTA_KEYS.REPAYMENT_RECEIVED:
        dispatch(
          requestReceived({
            id: val?.id,
            onSuccess: () => {
              dispatch(setIndexApiReload(!reloadIndexApi));
            },
          })
        );
        break;
      case EXPENSE_CTA_KEYS.REPAYMENT_CANCELLED:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.expenses.cancelRepayment,
          val?.id
        );
        setSearchParams(searchParams);
        break;

      case EXPENSE_CTA_KEYS.REPAYMENT_DECLINED:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.expenses.repaymentDeclined,
          val?.id
        );
        setSearchParams(searchParams);
        break;
      default:
        break;
    }
  }
  const loadMore = () => {
    const fetchAction = isExpensesPage ? fetchExpenses : fetchQrPayments;

    dispatch(
      fetchAction({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        status: [EXPENSE_STATUSES.NOT_OKAY],
        ...convertFilters(appliedFilter),
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
        ...filteringParams,
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore: currentSelectors?.hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...convertFilters(appliedFilter),
      category: sorting?.category,
      type: sorting?.type,
      reloadIndexApi,
    },
  });
  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  const rowSelectionHandler = (transactionId, action) => {
    if (!action) setSelectedRows((prev) => [...prev, transactionId]);
    else
      setSelectedRows((prev) => prev.filter((curr) => curr !== transactionId));
  };

  const headerSelectionHandler = (action) => {
    if (action && currentSelectors?.transaction) {
      setSelectedRows(currentSelectors?.transaction?.map((val) => val.id));
    } else {
      setSelectedRows([]);
    }
    setHeaderSelected((prev) => !prev);
  };

  const bulkApproveHandler = (transactionIds) => {
    const fetchAction = isExpensesPage ? bulkApprove : qrPaymentsBulkApprove;

    const payloadKey = isExpensesPage ? "expense_ids" : "qrPayment_ids";

    const payload = {
      payload: {
        [payloadKey]: transactionIds,
      },
    };

    dispatch(fetchAction(payload));
    setSelectedRows([]);
  };
  const handleAccountingCategorySelection = (e, index, val) => {
    // updating UI with Value
    setSelectedCategory((prev) => {
      return { ...prev, [index]: e?.id };
    });
    // getting tag id
    const id = val?.accountingTags?.find(
      (_) => _?.tagId === accountingCategory?.id
    )?.id;
    //  sending api update request
    const fetchAction = isExpensesPage ? updateExpense : updateQrPayments;

    const payloadKey = isExpensesPage ? "expenseId" : "qrPayId";

    const payload = {
      [payloadKey]: val?.id,
      payload: {
        expense_tag_values_attributes: [
          id
            ? {
                id,
                tag_id: accountingCategory?.id,
                tag_value_id: e?.id,
              }
            : {
                tag_id: accountingCategory?.id,
                tag_value_id: e?.id,
              },
        ],
      },
    };

    dispatch(fetchAction(payload));
  };
  const handleVendorSelection = (e, index, val) => {
    // update UI
    setSelectedVendors((prev) => {
      return { ...prev, [index]: e?.id };
    });
    // sending request to backend
    const fetchAction = isExpensesPage ? updateExpense : updateQrPayments;

    const payloadKey = isExpensesPage ? "expenseId" : "qrPayId";

    const payload = {
      [payloadKey]: val?.id,
      payload: { accounting_payee_id: e?.id },
    };
    dispatch(fetchAction(payload));
  };
  function getAccountHolder(transaction) {
    return isExpensesPage ? transaction.cardHolder : transaction.walletHolder;
  }
  useEffect(() => {
    // adding this additional condition because at very first initial load when no data is populted to currentSelectors?.transaction
    // for a second the headerSelected gets true and shows selected in ui
    /* selectedRows.length === 0
        ? false
     */
    setHeaderSelected(
      selectedRows.length === 0
        ? false
        : selectedRows.length === currentSelectors?.transaction.length
    );
  }, [selectedRows, currentSelectors?.transaction]);
  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchVendors({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT }));
    dispatch(fetchAccountingVendors());
    dispatch(fetchTags({ visible: true }));
  }, []);

  const Tableref = useRef();
  return (
    <div ref={Tableref} className="mt-8">
      <Table
        headerSticky
        colWidths={currentSelectors?.tableColWidth}
        rightColWidths={[200]}
        styleId="expenses-needs-review-table"
        numberOfStickyColsLeft={1}
        numberOfStickyColsRight={1}
        bulkApproveVisible={selectedRows.length > 0}
        bulkApproveContent={
          <BulkApproveComponent
            totalApprove={currentSelectors?.totalTransactions}
            selectAllVisible={
              currentSelectors?.totalTransactions >=
              PAGINATION_PER_REQUEST_LIMIT
            }
            selectTotalExpense={totalExpenseSelected}
            handleTotalExpenseSelection={(val) => {
              setTotalExpenseSelected(val);
              if (!val) {
                setHeaderSelected(false);
                setSelectedRows([]);
              }
            }}
            handleApprove={() =>
              bulkApproveHandler(selectedRows, totalExpenseSelected)
            }
            selectedRows={selectedRows.length}
            isApproveInProgress={isApprovalInProgress}
          />
        }
        bulkApproveHeight="100px"
        bulkApproveWidth="60%"
        emptyDataTitle="expenses.flagged.tableEmptyStates.title"
        emptyDataDescription="expenses.flagged.tableEmptyStates.description"
      >
        <tr className="text-xs font-bold text-left text-neutral-800">
          {currentSelectors?.tableHeaders
            ?.filter((val) =>
              val?.isAccountingTag ? !!accountingSoftware : true
            )
            ?.map((val, index) =>
              !val?.isAccountingTag ? (
                <th
                  onClick={
                    val?.sortingCategory
                      ? () => handleSorting(val?.sortingCategory)
                      : null
                  }
                  key={`table-row-flagged-${index}`}
                  className={val?.class && !val?.haveArrow ? val?.class : ""}
                >
                  {val?.haveArrow ? (
                    <div
                      className={val?.class && val?.haveArrow ? val?.class : ""}
                    >
                      {index === 0 ? (
                        !currentSelectors?.isFetching ? (
                          <span onClick={(event) => event?.stopPropagation()}>
                            <Checkbox
                              checked={headerSelected}
                              onClickHandler={() =>
                                headerSelectionHandler(!headerSelected)
                              }
                            />
                          </span>
                        ) : (
                          <LoaderSkeleton size={[20, 20]} />
                        )
                      ) : null}
                      <Text translationKey={val?.label} />
                      <div className="flex flex-col justify-center ">
                        <Icon
                          className={
                            val?.sortingCategory === sorting.category &&
                            sorting.type === SORTING_TYPE.INC
                              ? "text-neutral-500"
                              : "text-neutral-300"
                          }
                          name="ArrowDropUp"
                        />
                        <Icon
                          className={
                            val?.sortingCategory === sorting.category &&
                            sorting.type === SORTING_TYPE.DEC
                              ? "text-neutral-500"
                              : "text-neutral-300"
                          }
                          name="ArrowDropDown"
                        />
                      </div>
                    </div>
                  ) : (
                    <Text translationKey={val?.label} />
                  )}
                </th>
              ) : accountingEnabled ? (
                <th key={`table-row-flagged-${index}`}>
                  <Text
                    translationKey={val?.label}
                    translationProps={{ accounting: accountingSoftware }}
                  />
                </th>
              ) : null
            )}
        </tr>
        {(!currentSelectors?.isFetching || !isEmpty) &&
          currentSelectors?.transaction?.map((val, index, arr) => {
            const disableCheck = Boolean(
              (approveActionInProgressId &&
                approveActionInProgressId === val.id) ||
                (dataActionInProgressId && dataActionInProgressId === val.id)
            );
            return (
              <tr
                key={`expenses--flagged-${index}`}
                className={`text-sm font-medium text-center cursor-pointer text-neutral-800

                ${
                  disableCheck
                    ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                    : ""
                }

                `}
                ref={(ref) => {
                  if (index === arr.length - 1 && currentSelectors?.hasMore)
                    handleRefChange(ref);
                }}
              >
                <td
                  className={`${
                    selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <ProfileWidgetWithCheckbox
                    onRowClickHandler={() => viewExpenseHandler(val?.id)}
                    onCheckboxClickHandler={() =>
                      rowSelectionHandler(
                        val?.id,
                        selectedRows?.includes(val?.id)
                      )
                    }
                    checkBoxValue={selectedRows?.includes(val?.id)}
                    ProfileWidgetName={val?.[currentSelectors?.supplier]?.name}
                    ProfileWidgetImg={
                      val?.[currentSelectors?.supplier]?.avatarUrl
                    }
                    disabled={disableCheck}
                  >
                    <div
                      className={`text-xs truncate text-neutral-500 flex items-center gap-0.5 ${
                        disableCheck
                          ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                          : ""
                      }`}
                    >
                      <span>{dateToString(val?.[currentSelectors?.date])}</span>
                      {categories ? (
                        <>
                          {val?.[currentSelectors?.date] &&
                            val?.projectName && (
                              <span className="profile-widget-dot text-neutral-500" />
                            )}
                          <span>{val?.projectName}</span>
                        </>
                      ) : null}
                    </div>
                  </ProfileWidgetWithCheckbox>
                </td>
                <td
                  className={`text-right ${
                    selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <div className="flex items-center justify-end gap-2 font-semibold">
                    {val?.transactionCurrencyCode !== val?.currency ? (
                      <>
                        <div id={`transaction-expenses-needs-review-${index}`}>
                          <Icon
                            name="swapHorizontal"
                            className="text-neutral-400"
                          />
                        </div>
                        <Tooltip
                          id={`transaction-expenses-needs-review-${index}`}
                          direction="top"
                        >
                          <span>
                            {amountToCurrency(
                              val?.transactionAmount,
                              val?.transactionCurrencyCode
                            )}
                          </span>
                        </Tooltip>
                      </>
                    ) : null}
                    <span>{amountToCurrency(val?.amount, val?.currency)}</span>
                  </div>
                </td>
                <td>
                  <CardUsageType
                    cardName={val?.cardName}
                    cardType={val?.cardType}
                    cardUsageType={val?.cardUsageType}
                    cardNumber={val?.cardNumber}
                    cardId={val?.cardId}
                  />
                </td>
                <td
                  className={`${
                    selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                  }

                    ${
                      disableCheck
                        ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                        : ""
                    }`}
                >
                  <CardHolder
                    displayName={getAccountHolder(val)?.displayName}
                    role={getAccountHolder(val)?.roles}
                    department={getAccountHolder(val)?.departmentName}
                    location={getAccountHolder(val)?.locationName}
                    showIconComponent={false}
                    classes="text-left"
                  />
                </td>
                <td
                  className={`${
                    selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <Text
                    classes="text-danger-600 flex justify-start text-left"
                    translationKey={
                      val?.flaggedBy
                        ? val?.flaggedBy
                        : "expenses.flagged.autoFlagged"
                    }
                  />
                </td>

                <td
                  className={`text-right ${
                    selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <div className="flex justify-center text-center">
                    <SubmissionPolicyIcon
                      value={val?.submissionPolicyStatus}
                      index={val?.id}
                    />
                  </div>
                </td>

                <td
                  className={` ${
                    selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <div className="flex justify-center text-center">
                    <ReceiptIcon
                      index={index}
                      receiptStatus={val?.receiptStatus}
                    />
                  </div>
                </td>
                <td
                  className={`text-left font-semibold ${
                    selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  }`}
                >
                  <MemoNoteIcon
                    index={index}
                    required={val.memoRequired}
                    variant={MEMO_NOTE_VARAINT.TEXT}
                    value={val.memo}
                  />
                </td>
                {accountingEnabled ? (
                  <td
                    className={`text-left ${
                      selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                    } ${
                      disableCheck
                        ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                        : ""
                    }`}
                  >
                    <VpSelect
                      required
                      hideLabelAfterSelect
                      value={
                        selectedCategory[index] ||
                        val?.accountingTags?.find(
                          (_) => _?.tagId === accountingCategory?.id
                        )?.tagValueId
                      }
                      placeholder="expenses.slider.accountingMerchant"
                      label="expenses.slider.select"
                      placeholderTranslationProp={{
                        accounting: accountingSoftware,
                      }}
                      handleChange={(e) =>
                        handleAccountingCategorySelection(e, index, val)
                      }
                      valueKey="id"
                      options={accountingCategory?.options}
                      isOptionsFetched={isFetchedAccountingTags}
                      isOptionsLoading={!isFetchedAccountingTags}
                      fetchOptions={() => dispatch(fetchTags({ visble: true }))}
                      optionsDisplayKey="alias"
                    />
                  </td>
                ) : null}
                {accountingEnabled ? (
                  <td
                    className={`text-left
                    ${
                      selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                    } ${
                      disableCheck
                        ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                        : ""
                    }`}
                  >
                    <VpSelect
                      hideLabelAfterSelect
                      value={
                        selectedVendors[index]
                          ? selectedVendors?.[index]
                          : val?.accountingPayeeId
                      }
                      handleChange={(e) => handleVendorSelection(e, index, val)}
                      label="expenses.slider.select"
                      placeholder="expenses.slider.accountingCategory"
                      placeholderTranslationProp={{
                        accounting: accountingSoftware,
                      }}
                      options={accountingVendorOptions}
                      optionsDisplayKey="alias"
                    />
                  </td>
                ) : null}
                <td
                  className={` ${
                    selectedRows?.includes(val?.id) ? "selected-row-cell" : ""
                  } ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                      : ""
                  } `}
                >
                  <div className="flex justify-center">
                    <ActionCell
                      expenseFooterCtas={val?.expenseCta}
                      onSubmit={getActionButtons}
                      val={val}
                      disabled={
                        approveActionInProgressId || dataActionInProgressId
                      }
                      showLoader={disableCheck}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        {currentSelectors?.isFetching && <TableLoader />}
      </Table>
    </div>
  );
}
FlaggedList.propTypes = {
  viewExpenseHandler: PropTypes.func,
  page: PropTypes.string,
  filteringParams: PropTypes.object,
};
