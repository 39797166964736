import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

import BudgetPill from "@/components/common/BudgetPill";

import { CARD_FREQUENCY, CARD_FREQUENCY_BE_MAPPING } from "@/constants/card";
import { BUDGET_PILL_CONFIG, REVIEW_LABELS } from "@/constants/common";

function ReviewBudgetFor({
  id,
  newContainerClasses,
  oldContainerClasses,
  oldValue,
  newValue,
}) {
  return (
    <div className="flex items-center gap-2">
      <div
        className={`text-xs font-semibold text-neutral-400 ${oldContainerClasses}`}
      >
        <div>
          <Text translationKey={REVIEW_LABELS.BUDGET_FOR.OLD} />
        </div>
        <div>
          <BudgetPill
            hideInfo
            id={`old-value-${id}`}
            data={oldValue?.length ? [{ modules: oldValue }] : []}
            showTogether={false}
            budgetPillClasses="flex flex-wrap justify-start w-full pt-3 overflow-hidden text-base capitalize text-neutral-500"
          />
        </div>
      </div>
      <div
        className={`flex flex-wrap flex-col items-end text-xs font-semibold text-neutral-400 ${newContainerClasses}`}
      >
        <Text translationKey={REVIEW_LABELS.BUDGET_FOR.NEW} />
        <div>
          <BudgetPill
            hideInfo
            id={`new-value-${id}`}
            data={newValue?.length ? [{ modules: newValue }] : []}
            showTogether={false}
            budgetPillClasses="flex flex-wrap justify-end w-full pt-3 overflow-hidden text-base capitalize text-neutral-800"
          />
        </div>
      </div>
    </div>
  );
}

export default ReviewBudgetFor;

ReviewBudgetFor.propTypes = {
  id: PropTypes.number,
  oldValue: PropTypes.string,
  newValue: PropTypes.string,
  newContainerClasses: PropTypes.string,
  oldContainerClasses: PropTypes.string,
};
