import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import useOnClickOutside from "@/hooks/useClickOutside";

import Button from "@/components/core/Button";
import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { ALERTS_TYPES } from "@/utils/constants/client";

export default function AlertAmountToggleItem({
  index,
  alert,
  amount,
  handleSubmit,
  values,
  errors,
  isLoading,
  handleChange,
  setSelectedAmount,
  setValues,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const ref = useRef(null);

  const handleAmountEdit = () => {
    setIsEdit(false);
    if (values[amount?.id] !== amount?.value)
      setValues((prev) => ({
        ...prev,
        [amount.id]: amount?.value,
      }));
  };
  useOnClickOutside(ref, () => handleAmountEdit());

  const handleEditAmountEnable = () => {
    setSelectedAmount(amount);
    setIsEdit((prev) => !prev);
  };

  return (
    <div
      className="px-6 py-5 bg-neutral-50 rounded-lg flex flex-col h-fit"
      key={index}
      ref={ref}
    >
      {alert?.key === ALERTS_TYPES.LOW_ACCOUNT ? (
        <div className="flex items-center gap-2">
          <Flag
            code={amount?.currency}
            classes="h-9 w-9 border-1 border-neutral-200"
          />
          <Text
            translationKey="settings.alerts.accountAmount"
            translationProps={{ currency: amount?.currency }}
            classes="text-lg font-bold"
          />
        </div>
      ) : null}
      <div className="flex flex-col gap-4 pt-3">
        <div className="flex items-center gap-6">
          <form
            className="w-full"
            onSubmit={handleSubmit}
            id={`update-amount-${amount?.id}-for-alert`}
          >
            <Input
              type="number"
              showNumberInvalidError
              key={index}
              insideForm
              name={`${amount?.id}`}
              label="settings.alerts.amountInput"
              placeholder="settings.alerts.amountInput"
              outsideDivClasses="w-full"
              value={values[amount?.id]}
              error={errors[amount?.id]}
              rightText={amount?.currency}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </form>
          <Icon
            name="Edit"
            className="w-5 h-5 text-neutral-500 cursor-pointer"
            handleClick={() => handleEditAmountEnable()}
          />
        </div>
        {isEdit ? (
          <div className="flex items-center justify-end gap-4">
            <Button
              label="settings.alerts.btnLabels.cancel"
              labelStyleClasses="text-xs font-bold text-neutral-500"
              classes="w-13 !px-5 !py-1"
              variant="tertiary"
              onClick={handleAmountEdit}
            />
            <Button
              label="settings.alerts.btnLabels.save"
              labelStyleClasses="text-xs font-bold"
              classes="w-13 !px-5 !py-1"
              btnType="submit"
              form={`update-amount-${amount?.id}-for-alert`}
              showLoader={isLoading}
              disabled={!values[amount?.id]}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

AlertAmountToggleItem.propTypes = {
  index: PropTypes.number,
  alert: PropTypes.object,
  amount: PropTypes.object,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  isLoading: PropTypes.bool,
  handleChange: PropTypes.func,
  setSelectedAmount: PropTypes.func,
  setValues: PropTypes.func,
};
