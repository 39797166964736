import PropTypes from "prop-types";

import Button from "@/components/core/Button";

import Text from "../Text";
import Image from "../Image";

export default function EmptyData({
  children,
  title,
  description,
  descriptionProps,
  buttonLabel,
  buttonProps = {},
  buttonIcon,
  handleClick,
  hideButton,
  imgSrc = "EmptyScreen",
  titleClasses,
  classes = "",
}) {
  return (
    <div
      className={`flex items-center justify-center w-full h-full ${classes}`}
    >
      <div className="flex flex-col items-center">
        <Image className="h-[125px] w-[190px]" name={imgSrc} alt="Empty data" />
        <div
          className={`text-lg mt-3 mb-1 font-bold  tracking-[-0.01em] ${titleClasses} ${
            titleClasses ? titleClasses : "text-neutral-800"
          }`}
        >
          <Text translationKey={title} />
        </div>
        {description ? (
          <Text
            classes="text-center"
            translationKey={description}
            translationProps={descriptionProps}
          />
        ) : null}
        {children}
        {buttonLabel && !hideButton ? (
          <div className="mt-6">
            <Button
              iconClasses="text-white"
              label={buttonLabel}
              type="default"
              variant="primary"
              preIcon={buttonIcon}
              onClick={handleClick}
              {...buttonProps}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

EmptyData.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionProps: PropTypes.object,
  handleClick: PropTypes.func,
  buttonLabel: PropTypes.string,
  imgSrc: PropTypes.string,
  buttonProps: PropTypes.object,
  buttonIcon: PropTypes.string,
  titleClasses: PropTypes.string,
  classes: PropTypes.string,
  hideButton: PropTypes.bool,
};
