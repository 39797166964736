import PropsTypes from "prop-types";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

import { COMPANY_PEOPLE_TAB_PARAMS } from "@/constants/company";

export default function PeopleTableLoader({ type, isCardsEnabled }) {
  return [...new Array(6)].map((_, index) => (
    <tr className="text-center" key={index}>
      <td className="flex items-center gap-3 ml-4">
        <LoaderSkeleton type="circle" />
        <LoaderSkeleton size={[20, 180]} />
      </td>
      <td className="text-left">
        <LoaderSkeleton />
      </td>
      {isCardsEnabled ? (
        <td className="text-left">
          <LoaderSkeleton />
        </td>
      ) : null}
      {isCardsEnabled ? (
        <td className="text-left">
          <LoaderSkeleton />
        </td>
      ) : null}
      {type === COMPANY_PEOPLE_TAB_PARAMS.ALL ? (
        <td className="text-center">
          <LoaderSkeleton />
        </td>
      ) : null}
      {type === COMPANY_PEOPLE_TAB_PARAMS.ALL ? (
        <td className="text-center">
          <LoaderSkeleton />
        </td>
      ) : null}
      <td className="text-center">
        <LoaderSkeleton />
      </td>
    </tr>
  ));
}

PeopleTableLoader.propTypes = {
  type: PropsTypes.string,
};
