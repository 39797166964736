import PropTypes from "prop-types";
import { useEffect } from "react";

import DateInput from "@/components/core/DateInput";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import OtpInput from "@/components/core/OtpInput";
import PhoneNumberInput from "@/components/core/PhoneInput";
import Radio from "@/components/core/Radio";
import StepWithExternalLink from "@/components/core/StepWithExternalLink";
import Text from "@/components/core/Text";
import VpAddress from "@/components/core/VpAddress";
import VpSelect from "@/components/core/VpSelect";

import {
  FEILD_DYNAMIC_OPTION_KEY,
  FIELD_AUTOFILL_MAPPING_OBJECT,
  FIELD_AUTOFILL_SOURCE_KEY,
  FIELD_DISABLED,
  FIELD_DROPDOWN_OPTIONS_ARRAY_KEY,
  FIELD_DROPDOWN_OPTION_LABEL_KEY,
  FIELD_DROPDOWN_OPTION_VALUE_KEY,
  FIELD_KEY_KEY,
  FIELD_LABEL_KEY,
  FIELD_MAX_LENGTH,
  FIELD_OPTION_VISIBLE_KEY,
  FIELD_PATH_KEY,
  FIELD_REGEX_KEY,
  FIELD_REQUIRED_KEY,
  FIELD_TOOLTIP_KEY,
  FIELD_TYPE_ADDRESS,
  FIELD_TYPE_DATE,
  FIELD_TYPE_EMAIL,
  FIELD_TYPE_KEY,
  FIELD_TYPE_MOBILE_NUMBER,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_OTP,
  FIELD_TYPE_PASSOWRD,
  FIELD_TYPE_RADIO,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_STEP_WITH_EXTERNAL_LINK,
  FIELD_TYPE_STRING,
  FIELD_TYPE_TEXT,
  fieldPropType,
  getCompleteFormKey,
} from "@/components/GenericForm/common";
import {
  dateToString,
  isLiteralRegex,
  removedTerminalsOfRegex,
} from "@/utils/common";

import { DATEINPUT_MODES } from "@/constants/date";
import { VP_ADDRESS_CONFIG } from "@/constants/onboarding";

/**
 * The "form field" in a GenericForm.
 * This is the smallest unit in a generic form, as in Requirements > [Pages] >  [Sections] > [Field]
 *
 * @param {String}    field 'name' prop used by Input, VpSelect
 * @param {Function}  onChange 'errors' object returned by useForm
 * @param {Object}    values 'values' object returned by useForm
 * @param {Object}    errors 'errors' object returned by useForm
 */
export default function GenericFormField({
  field,
  onChange,
  values,
  errors,
  setInitialFormValue = () => {},
  setValues,
  isChecked,
  setIschecked,
  cleanup = () => {},
  autofillValueFromRegexIfLiteral,
  autofillDropdownIfSingleOption,
  autoFillSource,
}) {
  const completeKey = getCompleteFormKey(
    field[FIELD_KEY_KEY],
    field[FIELD_PATH_KEY]
  );
  /**
   * Resolves a condition object and returns the result.
   *
   * @param {object} obj - The condition object to be resolved.
   * @return {boolean} The result of resolving the condition object.
   */
  function resolveConditionObject(obj) {
    if (!field?.showWhen) return true;
    const entries = Object.entries(obj)[0];
    const entrykey = entries[0];
    const entryvalue = entries[1];
    const currentCompleteKey = entrykey.includes("$")
      ? entrykey
      : getCompleteFormKey(entrykey);
    if (!Array.isArray(entryvalue)) {
      return `"${values[currentCompleteKey] ?? ""}"${
        obj?.equal ? "===" : "!=="
      }"${entryvalue}"`;
    }
    const con = entrykey === "$and" ? "&&" : "||";
    let string = "";
    entryvalue.forEach((currItem) => {
      string += resolveConditionObject(currItem) + con;
    });

    // Remove the trailing ' && ' or ' || ' at the end of the string
    string = string.slice(0, -con.length);
    // eslint-disable-next-line no-eval
    return eval(string);
  }

  const innerResolveConditionObject = (showWhen) => {
    const result = resolveConditionObject(showWhen);
    if (!result) cleanup(field, completeKey);
    return result;
  };

  useEffect(() => {
    setIschecked((prev) => ({
      ...prev,
      [completeKey]: !!field?.enabledSameAsCheckbox,
    }));
  }, [field]);

  const isDropdrown =
    !!field?.[FIELD_DROPDOWN_OPTIONS_ARRAY_KEY]?.length &&
    (field?.type === FIELD_TYPE_STRING || field?.type === FIELD_TYPE_SELECT);

  // Auto fill code

  // [FIELD_AUTOFILL_SOURCE_KEY] = "dependent" comes from BE
  const autoFillSourceKey = field[FIELD_AUTOFILL_SOURCE_KEY];
  const autoFillRegex = field[FIELD_REGEX_KEY];

  const autoFillPresent =
    autoFillSourceKey ||
    (!isDropdrown &&
      autofillValueFromRegexIfLiteral &&
      isLiteralRegex(autoFillRegex)); // regex auto-fill not applicable for dropdowns

  const autoFillValue = (() => {
    if (autoFillSourceKey) {
      const sourceValueCurrent =
        autoFillSource?.[autoFillSourceKey]?.value ??
        values?.[autoFillSourceKey] ??
        null;
      const autoFillSourceMappingObject =
        autoFillSource?.[autoFillSourceKey]?.[FIELD_AUTOFILL_MAPPING_OBJECT] ??
        field[FIELD_AUTOFILL_MAPPING_OBJECT] ??
        null;

      // simple copy prefill
      if (autoFillSourceMappingObject === null) return sourceValueCurrent;

      // mapping prefill
      return autoFillSourceMappingObject[sourceValueCurrent] ?? "";
    }
    if (autoFillRegex) {
      return removedTerminalsOfRegex(autoFillRegex);
    }
  })();

  const isFormReady = !!Object.keys(values).length;
  useEffect(() => {
    if (autoFillPresent) {
      setValues((prevValues) => {
        return {
          ...prevValues,
          [completeKey]: autoFillValue,
        };
      });

      if (!autoFillValue) {
        console.warn(
          "Auto filling failed, or empty value prefilled for",
          completeKey
        );
      }
    }
  }, [isFormReady, autoFillValue]); // note: this dependency is since useForm is not ready instantly
  const autoFillUIDisabled = autoFillPresent && autoFillValue; // if auto fill value is not found, let field be enabled

  const onCheckboxValueChange = (checked, copyToKey, copyFrom, currEvent) => {
    const currentValue = currEvent?.target?.value;
    const currentName = currEvent?.target?.name?.split(".").at(-1);

    if (checked) {
      setValues((prev) => {
        return {
          ...prev,
          [`${copyToKey}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_1}`]:
            currentName === VP_ADDRESS_CONFIG.ADDRESS_LINE_1 && currentValue
              ? currentValue
              : values[`${copyFrom}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_1}`],
          [`${copyToKey}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_2}`]:
            currentName === VP_ADDRESS_CONFIG.ADDRESS_LINE_2 && currentValue
              ? currentValue
              : values[`${copyFrom}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_2}`],
          [`${copyToKey}.${VP_ADDRESS_CONFIG.ZIPCODE}`]:
            currentName === VP_ADDRESS_CONFIG.ZIPCODE && currentValue
              ? currentValue
              : values[`${copyFrom}.${VP_ADDRESS_CONFIG.ZIPCODE}`],
          [`${copyToKey}.${VP_ADDRESS_CONFIG.CITY}`]:
            currentName === VP_ADDRESS_CONFIG.CITY && currentValue
              ? currentValue
              : values[`${copyFrom}.${VP_ADDRESS_CONFIG.CITY}`],
          [`${copyToKey}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`]:
            currentName === VP_ADDRESS_CONFIG.STATE_OR_PROVINCE && currentValue
              ? currentValue
              : values[`${copyFrom}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`],
          [`${copyToKey}.${VP_ADDRESS_CONFIG.COUNTRY}`]:
            currentName === VP_ADDRESS_CONFIG.COUNTRY && currentValue
              ? currentValue
              : values[`${copyFrom}.${VP_ADDRESS_CONFIG.COUNTRY}`],
        };
      });
    } else {
      setValues((prev) => {
        return {
          ...prev,
          [`${copyToKey}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_1}`]: "",
          [`${copyToKey}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_2}`]: "",
          [`${copyToKey}.${VP_ADDRESS_CONFIG.ZIPCODE}`]: "",
          [`${copyToKey}.${VP_ADDRESS_CONFIG.CITY}`]: "",
          [`${copyToKey}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`]: "",
          [`${copyToKey}.${VP_ADDRESS_CONFIG.COUNTRY}`]: "",
        };
      });
    }
  };

  const currentComponentClass = `generic_form_${completeKey.replace(".", "_")}`;

  switch (field?.type) {
    case FIELD_TYPE_SELECT:
    case FIELD_TYPE_STRING:
    case FIELD_TYPE_NUMBER:
    case FIELD_TYPE_TEXT:
    case FIELD_TYPE_EMAIL:
    case FIELD_TYPE_PASSOWRD: {
      if (isDropdrown && innerResolveConditionObject(field?.showWhen)) {
        const options = field?.[FEILD_DYNAMIC_OPTION_KEY]
          ? field?.[FIELD_DROPDOWN_OPTIONS_ARRAY_KEY]?.filter((item) =>
              item?.[FIELD_OPTION_VISIBLE_KEY]
                ? resolveConditionObject(item?.[FIELD_OPTION_VISIBLE_KEY])
                : true
            )
          : field?.[FIELD_DROPDOWN_OPTIONS_ARRAY_KEY];

        return (
          <VpSelect
            classes={field?.classes || currentComponentClass}
            name={completeKey}
            label={field?.label}
            value={values[completeKey]}
            handleChange={onChange}
            error={errors[completeKey]}
            options={options}
            optionsDisplayKey={FIELD_DROPDOWN_OPTION_LABEL_KEY}
            valueKey={FIELD_DROPDOWN_OPTION_VALUE_KEY}
            menuPosition="absolute"
            helperText={field?.description}
            insideForm
            showOptional={!field?.[FIELD_REQUIRED_KEY]}
            labelStyleClasses={field?.labelStyleClasses}
            autoSelectIfSingleOption={
              isFormReady &&
              autofillDropdownIfSingleOption &&
              field?.[FIELD_REQUIRED_KEY]
            }
            disabled={
              autofillDropdownIfSingleOption &&
              field?.[FIELD_REQUIRED_KEY] &&
              options.length === 1
            }
            title={field[FIELD_TOOLTIP_KEY]}
            titleNoTranslate
          />
        );
      }

      if (field?.type && innerResolveConditionObject(field?.showWhen)) {
        const props = field?.maskField
          ? {
              maskField: true,
              fallBackPasswordType: field?.type,
            }
          : { type: field?.type };

        const getLabel = () => {
          switch (field?.key) {
            case "account_routing_type1":
            case "account_routing_type2":
              return "billPay.vendors.createVendor.accountingRoutingType";

            case "account_routing_value1":
            case "account_routing_value2":
              return "billPay.vendors.createVendor.accountingRoutingValue";

            default:
              return field[FIELD_LABEL_KEY];
          }
        };

        const label = getLabel();

        return (
          <Input
            classes={currentComponentClass}
            name={completeKey}
            label={label}
            description={field?.description}
            onChange={onChange}
            value={values[completeKey]}
            error={errors[completeKey]}
            maxLength={field?.[FIELD_MAX_LENGTH]}
            disabled={!!(autoFillUIDisabled || field?.[FIELD_DISABLED])}
            labelExtraClasses={field?.labelExtraClasses}
            showOptional={!field?.[FIELD_REQUIRED_KEY]}
            title={field[FIELD_TOOLTIP_KEY]}
            titleNoTranslate
            {...props}
          />
        );
      }
      break;
    }

    case FIELD_TYPE_MOBILE_NUMBER:
      return field?.type && innerResolveConditionObject(field?.showWhen) ? (
        <PhoneNumberInput
          showEditIcon={field?.showEditIcon}
          preferredCountries={field?.preferredCountries}
          disableCountryCode={field?.disableCountryCode}
          onlyCountries={field?.onlyCountries}
          handleEditIcon={field?.handleEditIcon}
          value={values[completeKey]}
          error={errors[completeKey]}
          handleChange={onChange}
          inputProps={{ name: completeKey }}
          inputWidth={field?.inputWidth}
          inputHeight={field?.inputHeight}
          disabled={field?.[FIELD_DISABLED]}
          insideForm
        />
      ) : null;

    case FIELD_TYPE_RADIO:
      return innerResolveConditionObject(field?.showWhen) ? (
        <div className="flex flex-col gap-3">
          <Text
            classes={`${currentComponentClass}--title`}
            translationKey={field[FIELD_LABEL_KEY]}
          />
          <div className="flex gap-4">
            {field?.[FIELD_DROPDOWN_OPTIONS_ARRAY_KEY]?.map((item) => (
              <Radio
                key={item?.key}
                textClases={currentComponentClass}
                insideForm
                name={completeKey}
                label={item?.value}
                value={item?.key}
                handleChange={onChange}
                isChecked={values[completeKey] === item.key}
                error={errors[completeKey]}
              />
            ))}
          </div>
        </div>
      ) : null;

    case FIELD_TYPE_STEP_WITH_EXTERNAL_LINK:
      return field?.type ? (
        <StepWithExternalLink
          label={field?.label}
          iconName={field?.iconName}
          done={field?.finished}
          hasDivider={field?.hasDivider}
          stepName={`Step ${field.order + 1}`}
          hasBorderBottom={field?.hasBorderBottom}
        />
      ) : null;

    case FIELD_TYPE_DATE:
      return innerResolveConditionObject(field?.showWhen) ? (
        <div
          className={`${currentComponentClass} ${field?.wrapperComponentClasses}`}
        >
          {/* TECH_DEBT use <DateInputTextBox /> here */}
          <DateInput
            insideForm
            name={completeKey}
            value={values[completeKey]}
            onChange={onChange}
            isSelectable={field?.isSelectable}
            content={
              <Input
                name={completeKey}
                label={field[FIELD_LABEL_KEY]}
                value={dateToString(values[completeKey], { year: "numeric" })}
                error={errors[completeKey]}
                rightText={<Icon name="Calendar" />}
                labelExtraClasses={field?.labelExtraClasses}
                outsideDivClasses={field?.outsideDivClasses}
                clearable
                autoComplete="off"
                onChange={(e) => {
                  if (!e?.target?.value) onChange(e); // only allow clearing
                }}
                showOptional={!field?.[FIELD_REQUIRED_KEY]}
                title={field[FIELD_TOOLTIP_KEY]}
                titleNoTranslate
              />
            }
            mode={DATEINPUT_MODES.DATE}
          />
        </div>
      ) : null;

    case FIELD_TYPE_ADDRESS:
      return innerResolveConditionObject(field?.showWhen) ? (
        <VpAddress
          enabledSameAsCheckbox={field?.enabledSameAsCheckbox}
          labelCheckBox={field?.checkboxText}
          label={field?.label}
          name={completeKey}
          onChange={onChange}
          values={values}
          errors={errors}
          checkboxValueChange={onCheckboxValueChange}
          setInitialFormValue={setInitialFormValue}
          keyForSameAsCheckbox={completeKey?.replace(
            field?.key,
            field?.keyForSameAsCheckbox
          )}
          setIschecked={setIschecked}
        />
      ) : null;

    case FIELD_TYPE_OTP:
      return innerResolveConditionObject(field?.showWhen) ? (
        <OtpInput
          name={completeKey}
          type={FIELD_TYPE_TEXT}
          label={field[FIELD_LABEL_KEY]}
          description={field?.description}
          onChange={onChange}
          value={values[completeKey]}
          error={errors[completeKey]}
          classes={currentComponentClass}
          outsideDivClasses={field?.outsideDivClasses}
          labelExtraClasses={field?.labelExtraClasses}
          otpLimit={field?.otpLimit}
          otpSentCount={field?.otpSentCount}
          handleResendOtpButton={field?.handleResendOtpButton}
        />
      ) : null;

    default:
      break;
  }
}

GenericFormField.propTypes = {
  field: fieldPropType,
  onChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  setInitialFormValue: PropTypes.func,
  setValues: PropTypes.func,
  isChecked: PropTypes.any, // boolish
  setIschecked: PropTypes.func,
  cleanup: PropTypes.func,
  autofillValueFromRegexIfLiteral: PropTypes.bool,
  autofillDropdownIfSingleOption: PropTypes.bool,
  autoFillSource: PropTypes.object,
};
