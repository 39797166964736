import { createSelector } from "@reduxjs/toolkit";

import { baseCountrySelector } from "./client";

export const selectAppStore = (store) => store.app;

export const isDevDemoSelector = createSelector(
  selectAppStore,
  (app) => app.isDevDemo
);

export const countriesSelector = createSelector(
  selectAppStore,
  (app) => app.countries
);

export const isFetchingCountriesSelector = createSelector(
  selectAppStore,
  (app) => app.isFetchingCountries
);

export const baseCountryObjectSelector = createSelector(
  countriesSelector,
  baseCountrySelector,
  (countries, baseCountryCode) =>
    countries.find(({ isoCode }) => isoCode === baseCountryCode)
);
export const categoriesSelector = createSelector(
  selectAppStore,
  (app) => app.categories
);

export const isFetchingCategoriesSelector = createSelector(
  selectAppStore,
  (app) => app.isFetchingCategories
);
export const selectedCategoriesSelctor = createSelector(
  selectAppStore,
  (app, id) =>
    app.categories.find((val) => {
      return val.id === id;
    })
);
export const isInactive = createSelector(
  selectAppStore,
  (app) => app.isInactive
);

export const appModalSelector = createSelector(
  selectAppStore,
  (app) => app.appModal
);

export const appModalDataSelector = createSelector(
  selectAppStore,
  (app) => app.appModalData
);

export const accountsSelector = createSelector(
  selectAppStore,
  (app) => app.accounts
);

export const isFetchingAccountsSelector = createSelector(
  selectAppStore,
  (app) => app.isFetchingAccounts
);

export const dataActionLoadingIdSelector = createSelector(
  selectAppStore,
  (app) => app.dataActionLoadingId
);

export const indexApiReloadSelector = createSelector(
  selectAppStore,
  (app) => app.indexApiReload
);
