import { LEDGER_TABLE_HEADERS } from "@/utils/constants/ledger";

export const formatDataForTable = (ledger) => {
  const formatted = {
    [LEDGER_TABLE_HEADERS.ledgerDate]: {
      value: ledger?.createdAt,
    },

    [LEDGER_TABLE_HEADERS.ledgerType]: {
      value: ledger?.ledgerType,
    },

    [LEDGER_TABLE_HEADERS.amount]: {
      value: ledger?.amount,
      currency: ledger?.account?.currency,
    },

    [LEDGER_TABLE_HEADERS.balance]: {
      value: ledger?.currentOutstanding,
      currency: ledger?.account?.currency,
    },

    [LEDGER_TABLE_HEADERS.type]: {
      value: ledger?.type,
    },

    [LEDGER_TABLE_HEADERS.description]: {
      value: ledger?.description,
    },
  };

  return formatted;
};
