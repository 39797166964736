import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function ExpenseAndQRPaySplitHeader({
  amount,
  currency,
  splitAmount = 0,
  difference,
  showSuccessfullSplitIcon,
}) {
  return (
    <div className="flex items-center gap-10 my-5 card-wrapper">
      {[
        {
          label: "expenses.splitExpense.originalAmount",
          icon: "Info",
          amount,
          currency,
        },
        {
          label: "expenses.splitExpense.splitAmount",
          icon: "Info",
          amount: splitAmount.toFixed(2),
          currency,
          icons: {
            name: showSuccessfullSplitIcon ? "Done" : "Error",
            classes: showSuccessfullSplitIcon
              ? "text-success-600 cursor-pointer"
              : "text-danger-400 cursor-pointer",
            tooltipVal: Number(difference).toFixed(3),
            tooltipCurrency: currency,
          },
        },
      ].map((val, index) => (
        <div key={`split-expense-header-${index}`}>
          <div className="flex items-center gap-2">
            <Text
              classes="text-xs font-semibold text-neutral-800 "
              translationKey={val.label}
            />
            <Icon className="w-4 h-4 text-neutral-300" name={val.icon} />
          </div>
          <div className="flex gap-2">
            <Text classes="text-2xl font-bold" translationKey={val?.amount} />
            <div className="flex items-center gap-2">
              <Text
                classes="text-2xl font-bold"
                translationKey={val?.currency}
              />
              {val?.icons ? (
                <div id="complete-diff-tooltip" className={val?.icons?.classes}>
                  <Icon
                    className={val?.icons?.classes}
                    name={val?.icons?.name}
                  />
                  {val?.icon ? (
                    <Tooltip id="complete-diff-tooltip" direction="bottom">
                      <Text
                        translationKey={`Difference: ${val?.icons?.tooltipVal}
                    ${val?.icons?.tooltipCurrency}`}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
ExpenseAndQRPaySplitHeader.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  splitAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  difference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSuccessfullSplitIcon: PropTypes.bool,
};
