import PropTypes from "prop-types";

import Badge from "@/components/core/Badge";
import {
  PAYMENT_LIST_BADGE_TYPES,
  PAYMENT_STATUSES,
} from "@/utils/constants/payments";
import { PAYMENT_MODES } from "@/utils/constants/reimbursement";

export default function PaymentStatusCell({ val }) {
  let uiConfig = PAYMENT_LIST_BADGE_TYPES[val.transactionStatus || val.status];

  // since 'Pay Outside Volopay' is a non-standard status
  if (
    val?.payOutsideVolopay ||
    val?.paymentMode === PAYMENT_MODES.payOutsideVolopay
  )
    uiConfig = PAYMENT_LIST_BADGE_TYPES[PAYMENT_STATUSES?.outsideVolopay];

  return (
    <div className="m-auto text-center w-max">
      {val?.bulkProcessing ? (
        <></>
      ) : (
        <Badge
          variant={uiConfig?.color}
          preIcon={uiConfig?.icon || ""}
          translationKey={uiConfig?.text}
          classes="w-15 h-6 text-xs"
        />
      )}
    </div>
  );
}

PaymentStatusCell.propTypes = {
  val: PropTypes.object,
};
