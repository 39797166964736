import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  fetchDepartments,
  fetchProjects,
  resetDepartmentsStoreState,
  resetProjectsStoreState,
} from "@/store/reducers/company";

import {
  departmentsHasMoreSelector,
  departmentsSelector,
  isFetchingDepartmentsSelector,
  isFetchingProjectsSelector,
  projectsHasMoreSelector,
  projectsSelector,
} from "@/store/selectors/company";

import { availableModulesSelector } from "@/store/selectors/user";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import BudgetPill from "@/components/common/BudgetPill";
import { debounce } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  AVAILABLE_BUDGETS_MODULES,
  BUDGET,
  EXISTING_DEPARTMENT,
  PROJECT_DEPARTMENT_CREATE_LISTING,
} from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import SearchBar from "../LocationDepartmentDetails/common/SearchBar";
import CreateSliderProjectDepartmentLoader from "./CreateSliderProjectDepartmentLoader";

function CreateSliderProjectDepartmentListing() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const type =
    PROJECT_DEPARTMENT_CREATE_LISTING[
      searchParams.get(SLIDERS_SEARCH_PARAMS.company.budget.createExisting)
    ];
  const isDepartment =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.budget.createExisting) ===
    EXISTING_DEPARTMENT;

  const data =
    useSelector((state) =>
      isDepartment ? departmentsSelector(state) : projectsSelector(state)
    ) ?? [];

  const isFetching = useSelector((state) =>
    isDepartment
      ? isFetchingDepartmentsSelector(state)
      : isFetchingProjectsSelector(state)
  );

  const hasMore = useSelector((state) =>
    isDepartment
      ? departmentsHasMoreSelector(state)
      : projectsHasMoreSelector(state)
  );
  const isEmpty = !data.length;

  const [search, setSearch] = useState("");

  const onReset = () => {
    if (isDepartment) dispatch(resetDepartmentsStoreState());
    else dispatch(resetProjectsStoreState());
  };
  const loadMore = () => {
    if (isDepartment)
      dispatch(
        fetchDepartments({
          page: pageNum,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          department: isDepartment,
          q: debouncedSearchText,
        })
      );
    else
      dispatch(
        fetchProjects({
          page: pageNum,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          q: debouncedSearchText,
        })
      );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: { type, debouncedSearchText },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);
  const availableModules = useSelector(availableModulesSelector);
  const leftRef = useLeftHeaderTitle({
    title: (
      <Text
        classes="left-header-text"
        translationKey="company.department.addNewDepartment.budgets.existingDepartmentBudget.title"
        translationProps={{
          type,
        }}
      />
    ),
  });
  return (
    <div className="slider-content-core">
      <div
        ref={leftRef}
        className="flex flex-col text-3xl font-bold text-neutral-800"
      >
        <Text
          translationKey="company.department.addNewDepartment.budgets.existingDepartmentBudget.title"
          translationProps={{
            type,
          }}
        />
        <Text
          classes="text-sm text-neutral-500 font-semibold"
          translationKey="company.department.addNewDepartment.budgets.defineBudgetDescription"
        />
      </div>
      <div className="w-full">
        <SearchBar
          classes="mt-9"
          searchQuery={search}
          handleChange={(e) => {
            setSearch(e.target.value);
            const debouncedFunc = debounce(() => {
              setDebouncedSearchText(e.target.value);
            }, 1500);
            debouncedFunc();
          }}
        />
      </div>
      <div className="flex flex-col gap-4 mt-6 mb-4">
        {!isEmpty || !isFetching
          ? data?.map((item, index) => {
              const _preExistingBudgets = item.budgets
                ?.map((i) => i?.modules)
                .flat(2)
                ?.map((i) => AVAILABLE_BUDGETS_MODULES[i]);

              const isAllBudgetAssigned = _preExistingBudgets?.some((i) =>
                availableModules?.includes(i)
              );

              return (
                <div
                  onClick={() => {
                    if (isAllBudgetAssigned) {
                      searchParams.append(
                        SLIDERS_SEARCH_PARAMS.company[
                          isDepartment ? "department" : "project"
                        ].id,
                        item.id
                      );
                      searchParams.append(
                        SLIDERS_SEARCH_PARAMS.company
                          .openTabInBudgetLocationAndDepartment,
                        BUDGET
                      );
                    } else {
                      searchParams.append(
                        SLIDERS_SEARCH_PARAMS.company.budget.createForm?.[
                          isDepartment ? "newDeptBudget" : "newProjBudget"
                        ],
                        item.id
                      );
                      searchParams.append(
                        SLIDERS_SEARCH_PARAMS.company.budget.storeMode,
                        false
                      );
                    }
                    setSearchParams(searchParams);
                  }}
                  key={`listing-${type}-create-${item.id}`}
                  ref={(ref) => {
                    if (
                      hasMore &&
                      !isFetching &&
                      !isEmpty &&
                      index === data.length - 1
                    )
                      handleRefChange(ref);
                  }}
                  className="flex items-center justify-between cursor-pointer card-wrapper"
                >
                  <div>
                    <Text
                      color="neutral-800"
                      classes="text-lg font-semibold"
                      translationKey={item?.name}
                    />
                    <div className="">
                      {item.budgets?.length ? (
                        <div className="flex items-center gap-1">
                          <Text
                            classes="text-neutral-500 text-sm font-medium"
                            translationKey="company.budgets.budgetedFor"
                          />
                          <BudgetPill
                            keyForModules="modules"
                            id={index}
                            data={item.budgets}
                          />
                        </div>
                      ) : (
                        <Text
                          classes="text-neutral-500 text-xs font-medium"
                          translationKey="Misc.noBudgets"
                        />
                      )}
                    </div>
                  </div>
                  <Icon
                    className="cursor-pointer text-neutral-500"
                    name="RightArrow"
                  />
                </div>
              );
            })
          : null}
        {isFetching ? <CreateSliderProjectDepartmentLoader /> : null}
      </div>
    </div>
  );
}

export default CreateSliderProjectDepartmentListing;
