export const formatDataForTable = (transaction) => {
  const formatted = {
    accountingId: transaction?.accountingId,
    id: transaction?.id,
    paymentId: transaction?.paymentId,
    accountingPayeeId: transaction?.accountingPayeeId,
    editable: transaction?.editable,
    rule: transaction?.rule,
    accountingStatus: transaction?.accountingStatus,
    vendor: {
      name: transaction?.vendor?.name,
      transactionDate: transaction?.invoiceDate,
      status: transaction?.accountingStatus,
      avatarUrl: transaction?.vendor?.avatarUrl,
    },
    amount: {
      amount: transaction?.amount?.value,
      currency: transaction?.amount?.currency,
    },
    vendorOwner: transaction?.vendor?.owner,
    invoice: {
      hasReceipt: !!transaction?.receipts?.length,
      required: true,
      attached: !!transaction?.receipts?.length,
    },
    transactionLinkedTo: {
      linkedTo: transaction?.linkedTo?.type,
      project: transaction?.linkedTo?.name,
    },
    action: {
      status: transaction?.accountingStatus,
      verifiable: transaction?.verifiable,
      syncable: transaction?.syncable,
      ctas: transaction?.accountingCtas,
    },
    verifiable: transaction?.verifiable,
    syncable: transaction?.syncable,
    notActionableReason: transaction?.notActionableReason,
    openIn: transaction?.accountingUrl,
    selected: transaction?.selected,
    invoiceDate: transaction?.invoiceDate,
    paymentDate: transaction?.paymentDate,
  };

  return formatted;
};
