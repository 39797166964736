import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import {
  downloadFileFromResponse,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import { BILL_PAYROLL_ACTION_CENTER_APPROVE_TYPES } from "@/utils/constants/paymentsStore";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import API from "@/api";

const BILL_PAYROLL_CONTEXT = {
  BILLPAY: "billpay",
  PAYROLL: "payroll",
  PAID: "paid",
  ALL: "all",
  FAILED: "failed",
  ACTION_CENTER_BILLPAY: "billPay-actionCentre",
  ACTION_CENTER_PAYROLL: "payroll-actionCentre",
};

export const PAYMENTS_SLICE_ATTRIBUTE_KEY = "payments";
export const EMPLOYEE_SLIDER_PAYMENTS_SLICE_ATTRIBUTE_KEY =
  "employeeSliderPayments";

/**
 * To be used inside thunk or slice's reducer
 *
 * @param {{Object=}} actionPayload  thunk's first param or `action.payload` (`action` is the second param of the slice reducer)
 *
 * @returns {{key: PAYMENTS_SLICE_ATTRIBUTE_KEY | EMPLOYEE_SLIDER_PAYMENTS_SLICE_ATTRIBUTE_KEY, value: Object=}}
 */

const getListKeyAndPayload = (actionPayload) => {
  actionPayload ??= {};

  // Note: if 'key' is absent, 'value' is the same as payload
  // and it is assumed the operation is meant for purchaseBill, not recurring payments
  // Why do this: keeps existing code simple, i.e. `dispatch(someReducer(valueToSet))` works,
  // as opposed to forcing `dispatch(someReducer({value: valueToSet, key: ...}))` for purchaseBill ops

  return {
    key: actionPayload?.key || PAYMENTS_SLICE_ATTRIBUTE_KEY,
    value: actionPayload?.key ? actionPayload?.value : actionPayload,
  };
};

// For Billpay Approvals, Payments and Paid pages + utilities
const paymentsInitialState = {
  // payments
  [PAYMENTS_SLICE_ATTRIBUTE_KEY]: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  // employee slider payments
  [EMPLOYEE_SLIDER_PAYMENTS_SLICE_ATTRIBUTE_KEY]: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  changesMade: {
    approvals: [],
    approvalsLength: 0,
    invoicesFetched: 0,
    payments: [],
    paid: [],
    failed: [],
    processing: [],
    pending: [],
  },
  autoPaymentSwitch: false,
  filterKeys: {
    invoiceInbox: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
    ],
    approvals: {
      needsApproval: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.recurringStatus,
      ],
      pending: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.recurringStatus,
      ],
      allBillPay: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.recurringStatus,
      ],
      allPayroll: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.recurringStatus,
      ],
    },
    payments: {
      allBillPay: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.recurringStatus,
        AVAILABLE_FILTER_KEYS.billPaymentStatus,
      ],
      allPayroll: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.recurringStatus,
        AVAILABLE_FILTER_KEYS.payrollPaymentStatus,
      ],
      failed: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.recurringStatus,
      ],
    },
    paid: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.recurringStatus,
    ],
  },
  countChanges: 0,
  selectedPayment: null,
  isPaymentFetching: false,
  isApproveActionPendingForId: null,
  paymentAccounts: [],
  selectedPaymentAccount: null,
  isFetchingPaymentAccounts: false,

  createBillForm: null, // type useForm return values
  createBillFormLineItems: [], // for all modes - create, edit and OCR
  createBillRef: null,
  createBillTotalContext: null,

  lineItems: [],
  isFetchingLineItems: false,

  approvers: null,
  isFetchingApprovers: false,

  // isLoading we show when we click on a cta and it calls an API like Approve or create
  isLoading: false,

  paymentModeList: null,
  isFetchingPaymentModeList: false,

  isFetchingPayrollSetting: false,
  payrollSetting: {
    payroll_auto_pay_day: null,
    auto_payment_enabled: false,
  },
  payrollToggleSwitch: false,
  automatedPaymentToggleSwitch: false,
  bulkUploadPayrollJobStatusInfo: null,
  bulkUploadTableInfo: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: 0,
    isFetching: false,
    hasMore: true,
    ctas: [],
  },
  bulkFileRecordId: null,
  isSubmittingBulkUpload: false,

  isDownloadingReceipt: false,
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState: paymentsInitialState,
  reducers: {
    resetPaymentStore: () => paymentsInitialState,
    setInPaymentSlice(state, action) {
      // for handling multiplicity, and avoiding initial undefined slice state
      // usage: run in the feat component once at the beginning
      // selector usage: use callback notation, useSelector((store) => store.payments[yourKey]);
      // tip: don't add anything to selector file for dynamic keys, nor reducers file. just specify keys, and use callback for selectorors
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key] = value;
    },
    setPayments(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].list = value;
    },
    setInvoicesFetched(state, action) {
      state.changesMade.invoicesFetched = action.payload;
    },
    incrementApprovalLength(state, action) {
      state.changesMade.approvalsLength += action.payload;
    },
    removePayment(state, action) {
      state.payments.list = state.payments.list.filter(
        (val) => val.id !== action.payload
      );
      state.changesMade.invoicesFetched -= 1;
    },
    setPaymentModeList(state, action) {
      state.paymentModeList = action.payload;
    },
    resetPaymentModeList(state, action) {
      state.paymentModeList = paymentsInitialState.paymentModeList;
    },
    setisFetchingPaymentModeList(state, action) {
      state.isFetchingPaymentModeList = action.payload;
    },
    updateInvoicePageWithChangedData(state) {
      const idArray = state.changesMade.approvals.map((val) => val.id);
      state.payments.list = state.payments.list.filter(
        (val) => !idArray.includes(val.id)
      );
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPayment(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);

      const { id, value: valueToBeSet } = value;
      state[key] ||= {};
      state[key].list ||= [];
      const idx = state[key].list.findIndex(
        (item) => Number(item.id) === Number(id)
      );
      if (idx !== -1) state[key].list[idx] = valueToBeSet;
    },
    setIntialState(state, action) {
      const { key } = getListKeyAndPayload(action.payload);

      state[key] ||= {};
      state[key].list ||= [];
      state[key].page = 1;
      state[key].hasMore = true;
      state[key].total = 0;
    },
    setApprovals(state, action) {
      state.changesMade.approvals = [
        ...state.changesMade.approvals,
        ...action.payload,
      ];
    },
    updateApprovals(state, action) {
      const params = action.payload;
      if (params.status !== "pending")
        state.payments.list = [
          ...state.payments.list,
          ...state.changesMade.approvals,
        ];
      else state.payments.list = [...state.payments.list];
      if (params.status === "needs-your-approval") {
        state.payments.list = state.payments.list.filter((payment) => {
          const index = payment.approvers.findIndex(
            (val) => val.approvalStatus === "pending"
          );
          const userId = payment.approvers[index].users.findIndex(
            (val) => val === 1
          );
          return userId !== -1;
        });
      }
    },

    updatePaymentsWithChangesMade(state, action) {
      const { status, type, tab } = action.payload;

      if (state.changesMade && state.changesMade[status]) {
        if (type === "payments") {
          if (status === "paid" || status === "failed") {
            const idArray = [];
            state.changesMade[status].forEach((element) => {
              idArray.push(element.id);
            });
            state.payments.list = state.payments.list.filter(
              (item) => !idArray.includes(item.id)
            );
          }
          if (status === "processing") {
            const key = "id";
            state.payments.list = state.payments.list.map((el) => {
              const found = state.changesMade[status].find(
                (s) => s[key] === el[key]
              );
              if (found) {
                el = Object.assign(el, found);
              }
              return el;
            });
          }
        } else if (type === "paid" && status === "paid") {
          const key = "id";

          if (action.payload.q.length !== 0) {
            state.payments.list.push(
              ...state.changesMade[status].filter((value) =>
                value.vendor.name.toLowerCase().includes(action.payload.q)
              )
            );
          } else state.payments.list.push(...state.changesMade[status]);
        }
        if (type === "approvals") {
          const key = "id";
          state.payments.list = state.payments.list.map((el) => {
            const found = state.changesMade.pending.find(
              (s) => s[key] === el[key]
            );
            if (found) {
              el = Object.assign(el, found);
            }
            return el;
          });
        }
        if (type === "payments") {
          const key = "id";
          state.payments.list = state.payments.list.filter((el) => {
            const found = state.changesMade.payments.find(
              (s) => s[key] === el[key]
            );
            if (!found) {
              return el;
            }
          });
        }
      }
    },
    updateCountChanges(state) {
      state.countChanges += 1;
    },
    setPaymentspage(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].page = value;
    },
    setTotalPayments(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].total = value;
    },
    setPaymentsHasMore(state, action) {
      const { key } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].list ||= [];
      state[key].hasMore = state[key].list.length < state[key].total;
    },
    setPaymentsLimit(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].limit = value;
    },
    addPayments(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      const listToAppend = value;
      state[key] ||= {};
      state[key].list ||= [];
      state[key].list = state[key].list.concat(listToAppend);
    },
    prependPayments(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      const listOrObj = value;
      state[key] ||= {};
      state[key].list ||= [];
      state[key].list = [
        ...(Array.isArray(listOrObj) ? listOrObj : [listOrObj]),
        ...state[key].list,
      ];
    },
    setIsPaymentsFetching(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] ||= {};
      state[key].isFetching = value;
    },
    setSelectedPayment(state, action) {
      state.selectedPayment = action.payload;
    },
    removeInvoiceInboxApproval(state, action) {
      state.changesMade.approvals = state.changesMade.approvals.filter(
        (val) => val.id !== action.payload
      );
    },
    getUpdatedPayment(state, action) {
      const { id, value } = action.payload;
      const changedList = state.changesMade.paid;
      const changedvalue = changedList.findIndex(
        (changedPayment) => changedPayment.id === id
      );

      if (changedvalue !== -1) {
        state.selectedPayment = state.changesMade.paid[changedvalue];
      } else {
        state.selectedPayment = value;
      }
    },
    setIsPaymentFetching(state, action) {
      state.isPaymentFetching = action.payload;
    },
    updateApprovalwithChangesMade(state, action) {
      const { status } = action.payload;
      if (status === "pending") {
        state.payments.list.push(...state.changesMade.pending);
      }
      if (status === "needs-your-approval") {
        state.payments.list = state.payments.list.filter((payment) => {
          const index = state.changesMade.pending.findIndex(
            (val) => val.id === payment.id
          );
          return index === -1;
        });
      }
    },
    getApprovalPaymentwithChangesMade(state, action) {
      if (
        state.changesMade.approvals.length === 0 &&
        state.changesMade.pending.length === 0
      )
        return;

      const { paymentId, type } = action.payload;
      const index = state.changesMade.pending.findIndex(
        (val) => val.id === paymentId
      );

      const approvalIndex = state.changesMade.approvals.findIndex(
        (val) => val.id === paymentId
      );

      if (index !== -1 && approvalIndex === -1)
        state.selectedPayment = state.changesMade.pending[index];
      else if (index === -1 && approvalIndex !== -1) {
        state.selectedPayment = state.changesMade.approvals[approvalIndex];
      } else
        state.selectedPayment = state.payments.list.find(
          (val) => val.id === paymentId
        );
    },
    updateChangesMadeApproval(state, action) {
      const data = action.payload;
      state.changesMade.approvals = state.changesMade.approvals.map((val) =>
        val.id === data.id ? data : val
      );
    },
    setPaymentAccounts(state, action) {
      state.paymentAccounts = action.payload;
    },
    setIsApproveActionPendingForId(state, action) {
      state.isApproveActionPendingForId = action.payload;
    },
    setSelectedPaymentAccount(state, action) {
      if (
        typeof action.payload === typeof "" ||
        typeof action.payload === typeof 1
      ) {
        const id = +action.payload;
        const paymentAccount = state.paymentAccounts.find(
          (item) => item.id === id
        );
        state.selectedPaymentAccount = paymentAccount;
      } else {
        state.selectedPaymentAccount = action.payload;
      }
    },
    setIsFetchingPaymentAccounts(state, action) {
      state.isFetchingPaymentAccounts = action.payload;
    },

    // create bill form values
    setCreateBillForm(state, action) {
      state.createBillForm = action.payload;
    },
    mergeIntoCreateBillForm(state, action) {
      // add, update
      state.createBillForm = { ...state.createBillForm, ...action.payload };
    },
    clearCreateBillForm(state) {
      state.createBillForm = null;
    },
    resetCreateBillFormLineItems(state) {
      state.createBillFormLineItems = [];
    },
    setCreateBillFormLineItems(state, action) {
      state.createBillFormLineItems = action.payload;
    },
    appendToCreateBillFormLineItems(state, action) {
      state.createBillFormLineItems = [
        ...state.createBillFormLineItems,
        action.payload,
      ];
    },
    deleteFromCreateBillFormLineItems(state, action) {
      const id = action.payload ?? state.createBillFormLineItems.at(-1)?.id;
      state.createBillFormLineItems = state.createBillFormLineItems.filter(
        (item) => item.id !== id
      );
    },

    setPaymentApprovers(state, action) {
      state.approvers = action.payload;
    },
    setIsFetchingPaymentApprovers(state, action) {
      state.isFetchingApprovers = action.payload;
    },

    resetLineItems(state) {
      state.lineItems = [];
    },
    setLineItems(state, action) {
      state.lineItems = action.payload;
    },
    setIsFetchingLineItems(state, action) {
      state.isFetchingLineItems = action.payload;
    },

    updatePaymentInitiatorsPaginationParams(state, action) {
      // update strategy: merge
      state.paymentInitiators = {
        ...state.paymentInitiators,
        ...action.payload,
      };
    },

    setPayrollSetting(state, action) {
      state.payrollSetting = action.payload;
    },
    setIsFetchingPayrollSetting(state, action) {
      state.isFetchingPayrollSetting = action.payload;
    },

    setPayrollToggleSwitchState(state, action) {
      state.payrollToggleSwitch = action.payload;
    },

    setAutomatedPaymentToggleSwitch(state, action) {
      state.automatedPaymentToggleSwitch = action.payload;
    },
    setAutoPaymentSwitch(state, action) {
      state.autoPaymentSwitch = action.payload;
    },
    setJobStatusForPayrollBulkUpload(state, action) {
      state.bulkUploadPayrollJobStatusInfo = action.payload;
    },
    setBulkUploadFileRecordId(state, action) {
      state.bulkFileRecordId = action.payload;
    },
    setIsFetchingBulkUploadTableList(state, action) {
      state.bulkUploadTableInfo.isFetching = action.payload;
    },
    setBulkUploadTableList(state, action) {
      state.bulkUploadTableInfo.list = action.payload || [];
    },
    appendBulkUploadTableList(state, action) {
      state.bulkUploadTableInfo.list = [
        ...state.bulkUploadTableInfo.list,
        action.payload,
      ];
    },
    setBulkUploadLimit(state, action) {
      state.bulkUploadTableInfo.limit = action.payload;
    },
    setBulkUploadTotal(state, action) {
      state.bulkUploadTableInfo.total = action.payload;
    },
    setBulkUploadPage(state, action) {
      state.bulkUploadTableInfo.page = action.payload;
    },
    setBulkUploadHasMore(state) {
      state.bulkUploadTableInfo.hasMore =
        state?.bulkUploadTableInfo?.list?.length <
        state?.bulkUploadTableInfo?.total;
    },
    resetBulkUploadTableInfo(state) {
      state.bulkUploadTableInfo = {
        list: [],
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        total: 0,
        isFetching: false,
        hasMore: true,
        ctas: [],
      };
    },
    setIsSubmittingBulkUpload(state, action) {
      state.isSubmittingBulkUpload = action.payload;
    },
    setIsDownloadingPaymentReceipt(state, action) {
      state.isDownloadingReceipt = action.payload;
    },
    setCreateBillRef(state, action) {
      state.createBillRef = action.payload;
    },
    resetCreateBillRef(state, action) {
      state.createBillRef = paymentsInitialState.createBillRef;
    },
    setCreateBillTotalContext(state, action) {
      // for incidental data, and to avoid prop drilling.
      // invoice total, total and quote
      state.createBillTotalContext = action.payload;
    },
    mergeIntoCreateBillTotalContext(state, action) {
      state.createBillTotalContext = {
        ...state.createBillTotalContext,
        ...action.payload,
      };
    },
    resetCreateBillTotalContext(state, action) {
      state.createBillTotalContext =
        paymentsInitialState.createBillTotalContext;
    },
  },
});

// async action creators
// For billpay payments, paid
export const fetchPayments = createAsyncThunk(
  "payments/fetchPayments",
  async (params, { dispatch }) => {
    const { context, key, value } = params;
    dispatch(setIsPaymentsFetching({ value: true, key }));
    const [err, response] = await to(API.Payments.all(value));
    if (!err && response) {
      const { data } = response;

      if (params.value.page === 1) {
        dispatch(setPayments({ value: data.list, key }));
      } else {
        dispatch(addPayments({ value: data.list, key }));
      }

      dispatch(setPaymentsLimit({ value: data.limit, key }));
      dispatch(setTotalPayments({ value: data.total, key }));
      dispatch(setPaymentspage({ value: data.page, key }));
      dispatch(setPaymentsHasMore({ key }));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsPaymentsFetching({ value: false, key }));
  }
);

const onFetchPayrollResponse = ({ err, response, params, dispatch, key }) => {
  if (!err && response && response.data) {
    const { data } = response;
    if (params?.value?.page === 1) {
      dispatch(setPayments({ value: data.list, key }));
    } else {
      dispatch(addPayments({ value: data.list, key }));
    }

    dispatch(setPaymentsLimit({ value: data.limit, key }));
    dispatch(setTotalPayments({ value: data.total, key }));
    dispatch(setPaymentspage({ value: data.page, key }));
    dispatch(setPaymentsHasMore({ key }));
  } else {
    vToast(getErrorToastMessage(err));
  }
  dispatch(setIsPaymentsFetching({ value: false, key }));
};

// For billpay approvals
export const fetchPayrollApprovals = createAsyncThunk(
  "payments/fetchPayrollApprovals",
  async (params, { dispatch }) => {
    dispatch(setIsPaymentsFetching(true));
    const { key, value } = params;
    dispatch(setIsPaymentsFetching({ value: true, key }));

    const [err, response] = await to(API.Payrolls.allApprovals(value));

    onFetchPayrollResponse({ err, response, params, dispatch, key });
  }
);

// For billpay approvals
export const fetchPaymentApprovals = createAsyncThunk(
  "payments/fetchPaymentApprovals",
  async (params, { dispatch }) => {
    dispatch(setIsPaymentsFetching(true));
    const { key, value } = params;
    dispatch(setIsPaymentsFetching({ value: true, key }));

    const [err, response] = await to(API.Payments.allApprovals(value));

    onFetchPayrollResponse({ err, response, params, dispatch, key });
  }
);

// For billpay payment, paid
export const fetchAndSelectPayment = createAsyncThunk(
  "payments/fetchAndSelectPayment",
  async (params, { dispatch }) => {
    const {
      id,
      onSuccess = () => {},
      onError = () => {},
      isSetSelected = true,
      isSetListItem = false,
      ...rest
    } = params;
    dispatch(setIsPaymentFetching(true));
    const [err, response] = await to(API.Payments.get(id, rest));

    // TODO, in file src/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/index.js
    // the useEffect fetchAndSelectPayment fires twice even though dependency is not changing weird
    if (!err && !response) {
      dispatch(setIsPaymentFetching(false));
      return;
    }

    if (!err && response) {
      const payment = response?.data?.data || response?.data; // TODO response-nesting
      if (isSetSelected) dispatch(setSelectedPayment(payment));
      if (isSetListItem)
        dispatch(setPayment({ id: payment.id, value: payment }));
      onSuccess(payment);
    } else {
      vToast(getErrorToastMessage(err));
      onError();
    }

    dispatch(setIsPaymentFetching(false));
  }
);

// For billpay approval
export const fetchAndSelectPayrollApproval = createAsyncThunk(
  "payments/fetchAndSelectPayrollApproval",
  async (params, { dispatch }) => {
    const {
      id,
      onSuccess = () => {},
      onError = () => {},
      isSetSelected = true,
    } = params;

    dispatch(setIsPaymentFetching(true));
    const [err, response] = await to(API.Payrolls.getApproval(id));

    // TODO, in file src/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/index.js
    // the useEffect fetchAndSelectPayment fires twice even though dependency is not changing weird
    if (!err && !response) {
      dispatch(setIsPaymentFetching(false));
      return;
    }

    if (!err && response) {
      const approval = response?.data;
      if (isSetSelected) dispatch(setSelectedPayment(approval));
      onSuccess(approval);
    } else {
      vToast(getErrorToastMessage(err));
      onError();
    }

    dispatch(setIsPaymentFetching(false));
  }
);
export const fetchAndSelectPaymentApproval = createAsyncThunk(
  "payments/fetchAndSelectPayrollApproval",
  async (params, { dispatch }) => {
    const {
      id,
      onSuccess = () => {},
      onError = () => {},
      isSetSelected = true,
    } = params;

    dispatch(setIsPaymentFetching(true));
    const [err, response] = await to(API.Payments.getApproval(id));

    // TODO, in file src/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/index.js
    // the useEffect fetchAndSelectPayment fires twice even though dependency is not changing weird
    if (!err && !response) {
      dispatch(setIsPaymentFetching(false));
      return;
    }

    if (!err && response) {
      const approval = response?.data;
      if (isSetSelected) dispatch(setSelectedPayment(approval));
      onSuccess(approval);
    } else {
      vToast(getErrorToastMessage(err));
      onError();
    }

    dispatch(setIsPaymentFetching(false));
  }
);

// For billpay payment, paid
export const updatePayment = createAsyncThunk(
  "payments/updatePayment",
  async (params, { dispatch }) => {
    const { context, id, payload, onSuccess = () => {} } = params;
    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.PAYROLL
        ? API.Payrolls.update(id, payload)
        : API.Payments.update(id, payload)
    );

    if (!error && response) {
      const payment = response?.data?.data || response?.data; // TODO response-nesting
      onSuccess(payment);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);

// For billpay approval
export const updateApproval = createAsyncThunk(
  "payments/updateApproval",
  async (params, { dispatch }) => {
    const { context, id, payload, onSuccess = () => {} } = params;
    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.PAYROLL
        ? API.Payrolls.updateApproval(id, payload)
        : API.Payments.updateApproval(id, payload)
    );

    if (!error && response) {
      const approval = response?.data?.data || response?.data; // TODO response-nesting
      onSuccess(approval);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const fetchPaymentAccounts = createAsyncThunk(
  "payments/fetchPaymentAccounts",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPaymentAccounts(true));

    const [err, response] = await to(API.Wallets.all());

    if (!err && response?.data) {
      dispatch(setPaymentAccounts(response?.data));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingPaymentAccounts(false));
  }
);

export const fetchPaymentApprovers = createAsyncThunk(
  "payments/fetchPaymentApprovers",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPaymentApprovers(true));

    const [err, response] = await to(API.Payments.getApprovers(params));

    if (!err && response) {
      dispatch(setPaymentApprovers(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingPaymentApprovers(false));
  }
);

export const fetchLineItems = createAsyncThunk(
  "payments/fetchLineItems",
  async ({ id, context }, { dispatch }) => {
    dispatch(setIsFetchingLineItems(true));
    const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

    const [err, response] = await to(
      inPayrollContext
        ? API.Payrolls.getLineItems(id)
        : API.PurchaseBills.getLineItems(id)
    );
    if (!err && response) {
      dispatch(setLineItems(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingLineItems(false));
  }
);

// approve approval CTA
export const approve = createAsyncThunk(
  "payments/approve",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));
    const { context, id, onSuccess = () => {} } = params;
    dispatch(setIsApproveActionPendingForId(id));
    const { type } = BILL_PAYROLL_ACTION_CENTER_APPROVE_TYPES[context];
    const [error, response] = await to(
      API.Misc.approve({ target_id: id, type })
    );

    if (!error && response) {
      const approval = response?.data?.data || response?.data; // TODO response-nesting
      onSuccess(approval);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsApproveActionPendingForId(null));
    dispatch(setIsLoading(false));
  }
);

// reject approval CTA
export const rejectPayments = createAsyncThunk(
  "payments/rejectPayments",
  async (params, { dispatch }) => {
    const { context, id, comment, onSuccess = () => {} } = params;
    dispatch(setIsLoading(true));

    const { type } = BILL_PAYROLL_ACTION_CENTER_APPROVE_TYPES[context];
    const [error, response] = await to(
      API.Misc.reject({
        target_id: id,
        type,
        comment,
      })
    );

    if (!error && response) {
      const approval = response?.data?.data || response?.data; // TODO response-nesting
      dispatch(
        removePayment({
          value: id,
        })
      );
      onSuccess(approval);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsLoading(false));
  }
);

// Pay now CTA
export const payNow = createAsyncThunk(
  "payments/payNow",
  async (params, { dispatch }) => {
    const { id, payload, context, onSuccess = () => {} } = params;
    dispatch(setIsApproveActionPendingForId(id));

    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.BILLPAY
        ? API.Payments.payNow(id, payload)
        : API.Payments.payNow(id, { ...payload, payroll: true })
    );

    if (!error && response) {
      const payment = response?.data?.data || response?.data; // TODO response-nesting
      onSuccess(payment);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsApproveActionPendingForId(null));
  }
);

// Retry payment CTA
export const retryPayment = createAsyncThunk(
  "payments/retryPayment",
  async (params, { dispatch }) => {
    const { context, id, onSuccess = () => {} } = params;

    dispatch(setIsApproveActionPendingForId(id));

    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.BILLPAY
        ? API.Payments.retryPayment(id)
        : API.Payments.retryPayment(id, { payroll: true })
    );

    if (!error && response) {
      const payment = response?.data?.data || response?.data; // TODO response-nesting
      onSuccess(payment);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsApproveActionPendingForId(null));
  }
);

// Mark as paid CTA
export const markAsPaid = createAsyncThunk(
  "payments/markAsPaid",
  async (params, { dispatch }) => {
    const { context, id, onSuccess = () => {} } = params;

    dispatch(setIsApproveActionPendingForId(id));

    const [error, response] = await to(
      context === BILL_PAYROLL_CONTEXT.BILLPAY
        ? API.Payments.payNow(id)
        : API.Payments.payNow(id, { payroll: true })
    );

    if (!error && response) {
      const payment = response?.data?.data || response?.data; // TODO response-nesting
      onSuccess(payment);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsApproveActionPendingForId(null));
  }
);

export const fetchPaymentModes = createAsyncThunk(
  "reimbursements/fetchPaymentModes",
  async (params, { dispatch }) => {
    const { vendor_id } = params;
    dispatch(setisFetchingPaymentModeList(true));
    const [err, response] = await to(API.Vendors.getPaymentMode(vendor_id));
    if (response && !err) {
      dispatch(setPaymentModeList(response.data));
      dispatch(setisFetchingPaymentModeList(false));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchPayrollSetting = createAsyncThunk(
  "payroll/fetchPayrollSetting",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPayrollSetting(true));

    const [err, response] = await to(API.PayrollSetting.all());
    if (!err && response) {
      dispatch(setPayrollSetting(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingPayrollSetting(false));
  }
);

export const updatePayrollSetting = createAsyncThunk(
  "payroll/updatePayrollSetting",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, payload } = params;
    dispatch(setIsLoading(true));

    const [err, response] = await to(API.PayrollSetting.update(payload));
    if (response && !err) {
      const updatedSetting = response?.data?.data || response?.data; // TODO response-nesting
      dispatch(setPayrollSetting(updatedSetting));
      dispatch(setAutomatedPaymentToggleSwitch(payload?.auto_payment_enabled));
      onSuccess(updatedSetting);
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsLoading(false));
  }
);

// Setting payroll enabling
export const enablePayrollSettings = createAsyncThunk(
  "payrolls/enablePayroll",
  async (params, { dispatch }) => {
    const { onEnablePayrollSuccessfully = () => {} } = params;
    const [err, response] = await to(API.Payrolls.enablePayroll());
    if (response && !err) {
      const updatedSetting = response?.data?.data || response?.data; // TODO response-nesting
      dispatch(setPayrollSetting(updatedSetting));
      if (onEnablePayrollSuccessfully) {
        onEnablePayrollSuccessfully();
      }
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const bulkOperationPayments = createAsyncThunk(
  "payments/bulkOperation",
  async (params, { dispatch }) => {
    const { param, context, onSuccess = () => {} } = params;
    const [err, response] = await to(
      context === BILL_PAYROLL_CONTEXT.BILLPAY
        ? API.Payments.bulkOperation(param)
        : API.Payrolls.bulkOperation(param)
    );
    if (!err && response) {
      const responseData = response?.data?.data || response?.data; // TODO response-nesting
      vToast(getSuccessToastMessage(response));
      onSuccess(responseData);
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const getBulkUploadSampleSheetForPayroll = createAsyncThunk(
  "payments/getBulkUploadSampleSheetForPayroll",
  async () => {
    const [err, response] = await to(
      API.Payrolls.downloadSampleFileForBulkUploadForPayrollSheet()
    );

    let toasterConfig = {};

    if (response && !err) {
      toasterConfig = {
        title:
          "payroll.bulkUpload.toasterMessages.getSampleSheet.successMessage",
        description: `<a style='color:#8ab4f8;text-decoration: underline;' href="${ROUTES.exports.base.absolutePath}">click here</a> to download once processing is complete`,
      };
    } else {
      toasterConfig = getErrorToastMessage(err);
    }

    vToast(toasterConfig);
  }
);

export const bulkUploadPayrollSheet = createAsyncThunk(
  "payments/bulkUploadPayrollSheet",
  async ({ onSuccess, payload }, { dispatch }) => {
    const [err, response] = await to(API.Payrolls.bulkUploadPayrolls(payload));

    let toasterConfig = {};

    const responseData = response?.data?.data || response?.data; // TODO response-nesting
    if (responseData && !err) {
      if (onSuccess) {
        onSuccess(responseData?.id);
      }
      toasterConfig = getSuccessToastMessage(response);
    } else {
      toasterConfig = getErrorToastMessage(err);
    }

    vToast(toasterConfig);
  }
);

export const getBulkPayrollStatus = createAsyncThunk(
  "payments/getBulkPayrollStatus",
  async ({ payload, onSuccess }, { dispatch }) => {
    const [err, response] = await to(
      API.Payrolls.fetchBulkPayrollStatus(payload)
    );

    const responseData = response?.data?.data || response?.data; // TODO response-nesting
    if (response && !err) {
      dispatch(setJobStatusForPayrollBulkUpload(responseData));

      if (onSuccess) {
        onSuccess(responseData);
      }
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const modifyBulkPayrollAction = createAsyncThunk(
  "payments/modifyBulkPayrollAction",
  async (args, { dispatch }) => {
    dispatch(setIsSubmittingBulkUpload(true));
    const { payload, onSuccess, onError } = args;
    const [err, response] = await to(
      API.Payrolls.modifyActionBulkPayroll(payload)
    );
    let toasterConfig = {};

    const responseData = response?.data?.data || response?.data; // TODO response-nesting
    if (responseData && !err) {
      if (onSuccess) {
        onSuccess(responseData);
      }

      toasterConfig = getSuccessToastMessage(response);
    } else {
      if (onError) {
        onError(err);
      }
      toasterConfig = getErrorToastMessage(err);
    }

    vToast(toasterConfig);

    dispatch(setIsSubmittingBulkUpload(false));
  }
);

export const fetchBulkUploadForPayrollTableList = createAsyncThunk(
  "payments/fetchBulkUploadForPayrollTableList",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingBulkUploadTableList({ value: true }));

    const [err, response] = await to(API.Payrolls.all(params?.value));

    if (!err && response) {
      const data = response?.data;

      if (params.value.page === 1) {
        dispatch(setBulkUploadTableList(data.list));
      } else {
        dispatch(appendBulkUploadTableList(data.list));
      }

      dispatch(setBulkUploadLimit(data.limit));
      dispatch(setBulkUploadTotal(data.total));
      dispatch(setBulkUploadPage(data.page));
      dispatch(setBulkUploadHasMore());
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingBulkUploadTableList(false));
  }
);

export const downloadPaymentReceipt = createAsyncThunk(
  "payments/fetchBulkUploadForPayrollTableList",
  async (params, { dispatch }) => {
    dispatch(setIsDownloadingPaymentReceipt(true));

    const { id, onSuccess = () => {}, ...rest } = params;
    const [err, response] = await to(
      API.Payments.downloadReceiptEndpoint(id, rest)
    );

    const responseData = response?.data?.data || response?.data; // TODO response-nesting

    const fileNameMatch =
      response?.headers?.["content-disposition"]?.match(/filename="([^"]*)"/);

    const fileName = fileNameMatch ? fileNameMatch[1] : "Receipt.pdf";
    if (!err && responseData) {
      await downloadFileFromResponse(responseData, fileName, "application/pdf");
      onSuccess(responseData);
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsDownloadingPaymentReceipt(false));
  }
);

export const {
  setInPaymentSlice,
  setPayments,
  removePayment,
  addPayments,
  prependPayments,
  setPayment,
  setIsPaymentsFetching,
  setSelectedPayment,
  setIsPaymentFetching,
  setPaymentspage,
  setTotalPayments,
  setPaymentsHasMore,
  setPaymentsLimit,
  updateCountChanges,
  setIntialState,
  updatePaymentsWithChangesMade,
  getUpdatedPayment,
  updateApprovalwithChangesMade,
  getApprovalPaymentwithChangesMade,
  setApprovals,
  updateApprovals,
  setInvoicesFetched,
  updateInvoicePageWithChangedData,
  removeInvoiceInboxApproval,
  incrementApprovalLength,
  updateChangesMadeApproval,
  setPaymentAccounts,
  setSelectedPaymentAccount,
  setIsFetchingPaymentAccounts,
  setPaymentModeList,
  resetPaymentModeList,
  setisFetchingPaymentModeList,
  setCreateBillForm,
  mergeIntoCreateBillForm,
  clearCreateBillForm,
  resetCreateBillFormLineItems,
  setCreateBillFormLineItems,
  appendToCreateBillFormLineItems,
  deleteFromCreateBillFormLineItems,
  setPaymentApprovers,
  setIsFetchingPaymentApprovers,
  resetLineItems,
  setLineItems,
  setIsFetchingLineItems,
  setIsApproveActionPendingForId,
  setIsLoading,

  // settings
  updatePaymentInitiatorsPaginationParams,
  setPayrollSetting,
  setIsFetchingPayrollSetting,
  setEnablePayrollState,
  setAutoPaymentSwitch,
  setPayrollToggleSwitchState,
  setAutomatedPaymentToggleSwitch,
  resetPaymentStore,
  setJobStatusForPayrollBulkUpload,
  setIsFetchingBulkUploadTableList,
  setBulkUploadTableList,
  setBulkUploadLimit,
  setBulkUploadTotal,
  setBulkUploadPage,
  setBulkUploadCtas,
  setBulkUploadHasMore,
  appendBulkUploadTableList,
  resetBulkUploadTableInfo,
  setIsSubmittingBulkUpload,
  setBulkUploadFileRecordId,
  setIsDownloadingPaymentReceipt,
  setCreateBillRef,
  resetCreateBillRef,
  setCreateBillTotalContext,
  mergeIntoCreateBillTotalContext,
  resetCreateBillTotalContext,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
