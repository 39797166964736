export const ANALYTICS_EXPORT_FORMATS = [
  {
    id: 1,
    label: "UCSV",
    value: "ucsv",
  },
  {
    id: 2,
    label: "XLSX",
    value: "xlsx",
  },
];

export const ANALYTICS_LEDGER_EXPORT_FORMATS = [
  {
    id: 1,
    label: "CSV",
    value: "csv",
  },

  {
    id: 2,
    label: "PDF",
    value: "pdf",
  },

  {
    id: 3,
    label: "XLSX",
    value: "xlsx",
  },
];

export const EXPORT_TRANSACTION_TYPE = {
  expenses: "expense",
  bill_pay: "purchase_bill",
  reimbursements: "reimbursement",
  others: "other",
  payrolls: "payroll",
  ledger: "ledger",
};
