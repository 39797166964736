import LoaderSkeleton from "../LoaderSkeleton";

export default function SelectLoader() {
  return (
    <div className="flex flex-col">
      <LoaderSkeleton fullWidth classes="my-4" />
      <LoaderSkeleton fullWidth classes="my-4" />
      <LoaderSkeleton fullWidth classes="my-4" />
    </div>
  );
}
