import PropTypes from "prop-types";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { dateToString } from "@/utils/common";

import { REIMBURSEMENT_DATE_MAPPINGS } from "@/constants/reimbursement";

export default function RequestedDetailsSummary({ reimbursement }) {
  const reimbursementDate = REIMBURSEMENT_DATE_MAPPINGS[reimbursement.type];
  return (
    <div className="flex flex-col gap-5">
      <Text
        translationKey="reimbursement.approvals.sliderHeadings.requestDetails"
        classes="text-lg font-semibold text-neutral-800"
      />
      <div className="flex flex-col gap-6">
        <div className="grid items-center w-full grid-cols-2 gap-8">
          <div>
            <Input
              label="reimbursement.createReimbursement.amountHeading"
              value={reimbursement?.claimAmount?.value}
              disabled
              classes="text-xs font-semibold"
            />
          </div>
          <div>
            <Input
              label="reimbursement.createReimbursement.currency"
              value={reimbursement?.claimAmount?.currency}
              disabled
              classes="text-xs font-semibold"
            />
          </div>
        </div>
        <div>
          <Input
            label={`reimbursement.createReimbursement.${reimbursementDate}`}
            value={dateToString(reimbursement[reimbursementDate])}
            disabled
            classes="text-xs font-semibold"
          />
          <Text
            translationKey="reimbursement.approvals.sliderHeadings.requestDetailsComponent.transactionDateDesc"
            classes="text-xs font-medium text-neutral-400"
          />
        </div>
      </div>
    </div>
  );
}

RequestedDetailsSummary.propTypes = {
  reimbursement: PropTypes.object,
};
