import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import { dateToString } from "@/utils/common";

import { ACCOUNTING_PROVIDERS } from "@/constants/accounting";

export default function LastSyncedOn({
  date = new Date(),
  time = "",
  software = ACCOUNTING_PROVIDERS.QUICK_BOOKS,
}) {
  return (
    <div className="flex items-center gap-2">
      <Icon
        name={`${software}`}
        className="w-8 h-8 rounded-full overflow-hidden bg-lightgray text-white bg-no-repeat bg-center bg-contain relative border border-neutral-200"
      />
      <div className="text-neutral-500 text-sm font-medium flex items-center gap-1">
        <Text translationKey="accounting.transactions.cards.lastSyncedOn" />
        <Text
          translationKey={`${dateToString(date, { year: "numeric" })},`}
          classes="text-neutral-500 text-sm font-medium"
        />
        {time}
      </div>
    </div>
  );
}

export function LastSyncedOnLoader() {
  return (
    <div className="flex gap-2 items-center w-[290px]">
      <LoaderSkeleton size={[32, 32]} />
      <LoaderSkeleton size={[32, 250]} />
    </div>
  );
}

LastSyncedOn.propTypes = {
  date: PropTypes.instanceOf(Date),
  software: PropTypes.oneOf([
    ACCOUNTING_PROVIDERS.XERO,
    ACCOUNTING_PROVIDERS.QUICK_BOOKS,
  ]),
};
