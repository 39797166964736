import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { countriesSelector } from "@/store/selectors/app";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import PhoneNumberInput from "@/components/core/PhoneInput";
import Text from "@/components/core/Text";

import { PREFERRED_COUNTRIES } from "@/constants/common";
import { VENDOR_UPDATE_REQUEST_PARAMS } from "@/constants/vendors";

export default function ContactInformation({
  values,
  errors,
  handleChange,
  dataRef,
  inPayrollContext,
  isEditMode,
  numberOfContacts,
  disabled,

  onAdd = () => {},
  onRemove = () => {},
}) {
  const { t } = useTranslation();

  const countries = useSelector(countriesSelector)?.map(
    (country) => country?.iso2Code
  );

  const contactsEmails = Array(numberOfContacts)
    .fill(null)
    .map((detail, index) => {
      const emailKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.EMAIL}`;
      const destroyKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES._DESTROY}`;
      if (values[destroyKey]) return null;
      return values[emailKey]?.trim?.();
    });
  const duplicateEmailStates = useMemo(() => {
    const _duplicateEmailStates = Array(numberOfContacts)
      .fill(null)
      .map((detail, index) => {
        const emailKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.EMAIL}`;
        const error = errors[emailKey];
        const value = values[emailKey]?.trim?.();

        const destroyKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES._DESTROY}`;
        if (!value || values[destroyKey]) return false;

        return contactsEmails.some(
          (email, index2) =>
            email && !error && email === value && index > index2
        );
      });
    dataRef.current = {
      ...dataRef.current,
      duplicateEmailStates: _duplicateEmailStates,
    };
    return _duplicateEmailStates;
  }, [JSON.stringify(contactsEmails)]);

  const preferredCountries = PREFERRED_COUNTRIES;

  return (
    <div>
      <Text
        translationKey="billPay.vendors.createVendor.contactInformation"
        classes="text-xl font-bold text-neutral-800"
      />
      {Array(numberOfContacts)
        .fill(null)
        .map((_, index, { length }) => {
          const emailKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.EMAIL}`;
          const nameKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.NAME}`;
          const phoneKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.PHONE}`;
          const destroyKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES._DESTROY}`;

          if (values[destroyKey]) return null;

          return (
            <div
              className="px-3 py-5 mt-6 rounded-lg bg-neutral-50"
              key={index}
            >
              {/* TODO: Rudimentary deletion mechanism - not in design */}
              {index > 0 && !disabled ? (
                <div className="flex justify-end">
                  <span
                    onClick={() => onRemove(index)}
                    className={`rounded-lg p-2 text-danger-500 ${
                      disabled
                        ? "opacity-50 cursor-not-allowed"
                        : "cursor-pointer hover:bg-neutral-200"
                    }`}
                  >
                    <Icon name="Close" />
                  </span>
                </div>
              ) : null}
              <Input
                name={emailKey}
                label={
                  inPayrollContext
                    ? "payroll.payrollEmployees.addEmployee.employeeEmail"
                    : "billPay.vendors.createVendor.contactEmail"
                }
                value={values[emailKey]}
                error={`${
                  duplicateEmailStates[index]
                    ? t(
                        "billPay.vendors.slider.form.labels.duplicateEmailIdError"
                      )
                    : ""
                }${duplicateEmailStates[index] ? " " : ""}${
                  errors[emailKey] ? errors[emailKey] : ""
                }`}
                disabled={!!isEditMode}
                onChange={handleChange}
                description={
                  inPayrollContext
                    ? "billPay.vendors.createVendor.contactEmailHelperTextPayroll"
                    : "billPay.vendors.createVendor.contactEmailHelperTextBillpay"
                }
                type="email"
              />
              {inPayrollContext ? (
                <div className="mt-8">
                  <Input
                    name={phoneKey}
                    label="payroll.payrollEmployees.addEmployee.employeeContactNumber"
                    value={values[phoneKey]}
                    error={errors[phoneKey]}
                    type="number"
                    onChange={handleChange}
                    disabled={!!isEditMode}
                    showOptional
                    showNumberInvalidError
                  />
                </div>
              ) : (
                <div className="grid grid-cols-2 mt-8 gap-x-8">
                  <Input
                    name={nameKey}
                    label="billPay.vendors.createVendor.contactName"
                    value={values[nameKey]}
                    error={errors[nameKey]}
                    onChange={handleChange}
                    disabled={!!isEditMode}
                    showOptional
                  />

                  {/* TODO: Will optimize later */}
                  {/* <PhoneNumberInput
                    value={values?.[phoneKey]}
                    inputProps={{ name: phoneKey }}
                    error={errors?.[phoneKey]}
                    handleChange={handleChange}
                    insideForm
                    enableSearch
                    countriesList={countries}
                    preferredCountries={preferredCountries}
                    labelClass="text-xs !text-neutral-400"
                  /> */}

                  <Input
                    name={phoneKey}
                    label="billPay.vendors.createVendor.contactNumber"
                    // labelExtraClasses="text-neutral-600 text-xs font-semibold"
                    value={values[phoneKey]}
                    error={errors[phoneKey]}
                    onChange={handleChange}
                    type="number"
                    disabled={!!isEditMode}
                    showOptional
                    showNumberInvalidError
                  />
                </div>
              )}
            </div>
          );
        })}

      {inPayrollContext || disabled ? null : (
        <Button
          className={`mt-3 ${
            disabled
              ? "text-neutral-600 cursor-not-allowed opacity-50"
              : "text-primary-600"
          } border border-neutral-300 py-1.5 px-5 text-sm font-bold rounded-lg flex items-center gap-2`}
          onClick={onAdd}
          label="billPay.vendors.createVendor.addAnotherContact"
          preIcon="Add"
          iconClasses="-mr-1"
          innerClasses="gap-1 font-bold text-xs"
          disabled={!!isEditMode}
        />
      )}
    </div>
  );
}

ContactInformation.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  dataRef: PropTypes.any,
  inPayrollContext: PropTypes.bool,

  numberOfContacts: PropTypes.number,
  isEditMode: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
};
