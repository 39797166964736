import {
  CARDS_BASE_ROUTE,
  DECLINES_PATH_NAME,
  EXPENSES_BASE_ROUTE,
  EXPENSES_NEEDS_REVIEW_PATH_NAME,
  FLAGGED_PATH_NAME,
  OVERVIEW_PATH_NAME,
  ROUTES,
} from "./routes";

export const OVERVIEW = "Overview";
export const HISTORY = "History";
export const ACCOUNTING_VENDOR = "accountingVendor";
export const ACCOUNTING_CATEGORY = "accountingCategory";
export const SETTLED = "settled";
export const UNSETTLED = "unsettled";
export const AMOUNT = "amount";
export const DECLINED = "declined";
export const OKAY = "okay";
export const NEEDS_REVIEW = "NEEDS_REVIEW";
export const APPROVED = "APPROVED";
export const FLAGGED = "FLAGGED";
export const AUTO_APPROVED = "AUTO_APPROVED";
export const REPAYMENT_REQUESTED = "REPAYMENT_REQUESTED";
export const REPAYMENT_NOT_REQUESTED = "REPAYMENT_NOT_REQUESTED";
export const REPAYMENT_RECEIVED = "REPAYMENT_RECEIVED";
export const REPAYMENT_DECLINED = "REPAYMENT_DECLINED";
export const ACCOUNTING_PENDING = "ACCOUNTING_PENDING";
export const NEEDS_REVIEW_ACCOUNTING_VERIFIED =
  "NEEDS_REVIEW_ACCOUNTING_VERIFIED";
export const APPROVED_ACCOUNTING_VERIFIED = "APPROVED_ACCOUNTING_VERIFIED";
export const ACCOUNTING_SYNCED = "ACCOUNTING_SYNCED";
export const REPAYMENT_CANCELLED = "REPAYMENT_CANCELLED";
export const TRANSACTION_TYPE_LINE_ITEM = "line_item";
export const TABS = [
  { name: OVERVIEW, key: 2, path: OVERVIEW, showComingSoon: false },
  { name: HISTORY, key: 3, path: HISTORY, showComingSoon: true },
];

export const EXPENSE_STATUSES = {
  PENDING: "pending",
  NOT_OKAY: "not_okay",
  VERIFIED: "verified",
  SYNC_IN_PROGRESS: "sync_in_progress",
  SYNCED: "synced",
  INCOMPLETE: "incompleted",
  NEED_DETAILS: "details_required",
  OKAY: "okay",
  SYNC_FAILED: "sync_failed",
  REQUESTED: "requested",
  RECEIVED: "received",
  DECLINED: "declined",
};

export const EXPENSE_ACCOUNTING_STATUS_TYPES = {
  PENDING: "pending",
  VERIFIED: "verified",
  SYNCED: "synced",
};

export const PAGE_TYPE_EXPENSE = {
  needsReview: "needs-review",
  outOfPolicy: "out-of-policy",
};

export const EXPENSE_TYPE = {
  EXPENSE: "expense",
  QR_PAY: "qrpay",
  QR_PAYMENT: "qrPayment",
};

export const STATUS_TAG = {
  [EXPENSE_STATUSES.PENDING]: "REVIEW PENDING",
  [EXPENSE_STATUSES.NOT_OKAY]: "NOT OKAY",
  [EXPENSE_STATUSES.VERIFIED]: "VERIFIED",
  [EXPENSE_STATUSES.SYNC_IN_PROGRESS]: "SYNCING",
  [EXPENSE_STATUSES.SYNCED]: "SYNCED",
  [EXPENSE_STATUSES.INCOMPLETE]: "INCOMPLETE",
  [EXPENSE_STATUSES.NEED_DETAILS]: "NEED DETAILS",
  [EXPENSE_STATUSES.OKAY]: "OKAY",
  PAID: "PAID",
};

export const EXPENSE_FORM_VIEWS = {
  ACCOUNTING: "accounting",
  MEMBER: "member",
};

export const EXPENSE_SETTLEMENT_STATUS = {
  SETTLED: "settled",
  UNSETTLED: "unsettled",
  RELEASED: "released",
};

export const EXPENSE_RECEIPT_STATUSES = {
  WITH_RECEIPTS: "with_receipts",
  WITH_NO_RECEIPTS: "with_no_receipts",
};

export const EXPENSE_ACTIVITY_TYPES = {
  EXPENSE_ADDED: "expense_added",
  RECEIPT_ADDED: "receipt_added",
  NOTE_ADDED: "note_added",
  FLAGGED_NOT_OKAY: "flagged_not_okay",
  RESOLVED_OKAY: "resolved_okay",
  APPROVED_OKAY: "approved_okay",
  SUBMISSION_POLICY_COMPLETED: "submission_policy_completed",
};

export const EXPENSE_ACTIVITY_TYPES_TAGS = {
  [EXPENSE_ACTIVITY_TYPES.RECEIPT_ADDED]: "Added a receipt",
  [EXPENSE_ACTIVITY_TYPES.NOTE_ADDED]: "Added a memo",
  [EXPENSE_ACTIVITY_TYPES.FLAGGED_NOT_OKAY]: "Auto-flagged as out of policy",
  [EXPENSE_ACTIVITY_TYPES.RESOLVED_OKAY]: "Marked as resolved",
  [EXPENSE_ACTIVITY_TYPES.APPROVED_OKAY]: "Expense approved",
  [EXPENSE_ACTIVITY_TYPES.SUBMISSION_POLICY_COMPLETED]:
    "Completed policy requirements for General Expenses",
};

export const EXPENSE_ACTIVITY_CLASS = {
  [EXPENSE_ACTIVITY_TYPES.RECEIPT_ADDED]: "text-neutral-500",
  [EXPENSE_ACTIVITY_TYPES.NOTE_ADDED]: "Added a memo",
  [EXPENSE_ACTIVITY_TYPES.FLAGGED_NOT_OKAY]: "text-danger-500",
  [EXPENSE_ACTIVITY_TYPES.RESOLVED_OKAY]: "Marked as resolved",
  [EXPENSE_ACTIVITY_TYPES.APPROVED_OKAY]: "Expense approved",
  [EXPENSE_ACTIVITY_TYPES.SUBMISSION_POLICY_COMPLETED]: "text-success-600",
};

export const EXPENSE = "expense";

export const EXPENSES_POLICY_TABS = [
  { name: "Pending", path: "pending", key: 1 },
  {
    name: "Ongoing Discussion",
    path: "ongoing-discussion",
    key: 2,
  },
  { name: "Resolved", path: "resolved", key: 3 },
];

export const RECEIPT_STATUSES = {
  MISSING: "missing",
  SUBMITTED: "submitted",
};

export const MEMO_STATUSES = {
  NOT_PRESENT: "not_present",
  PRESENT: "present",
};

export const GROUPED_EXPENSES_TABLE = {
  pending: "Pending",
};
export const EXPENSES_RECEIPT_STATUS_KEYS = {
  SUBMITTED: "submitted",
  AUTO_VERIFIED: "autoVerified",
  AUTO_CAPTURE: "autoCapture",
  MISSING: "missing",
  NOT_REQUIRED: "notRequired",
};

export const SUBMISSION_POLICY_STATUS_KEYS = {
  MET: "met",
  NOT_MET: "unfulfilled",
  OVERRIDDEN: "overridden",
};

export const MEMO_STATUS_KEY = {
  PRESENT: "present",
  MISSING: "missing",
  NOT_REQUIRED: "notRequired",
};

export const EXPENSE_TYPE_OF_UI_DATA = {
  PROGRESS: "progress",
  LABEL: "label",
};
export const EXPENSE_CTA_KEYS = {
  APPROVE: "approve",
  FLAG: "flag",
  REQUEST_REPAYMENT: "repaymentRequested",
  REPAYMENT_RECEIVED: "repaymentReceived",
  REPAYMENT_CANCELLED: "repaymentCancelled",
  REPAYMENT_DECLINED: "repaymentDeclined",
  ACCOUNTING_VERIFY: "accounting_verify",
  ACCOUNTING_UNVERIFY: "accounting_unverify",
  ACCOUNTING_SYNC: "accountingSync",
  MARK_AS_PAID: "markAsPaid",
  MARK_AS_SYNCED: "markAsSynced",
  DECLINE: "decline",
  SKIP_APPROVALS: "skipApprovals",
  REJECT: "reject",
};

export const EXPENSE_FOOTER_STATUS_KEY = {
  incompleted: NEEDS_REVIEW,
  pending: NEEDS_REVIEW,
  okay: APPROVED,
  not_okay: FLAGGED,
  auto_approved: AUTO_APPROVED,
  requested: REPAYMENT_REQUESTED,
  not_requested: REPAYMENT_NOT_REQUESTED,
  received: REPAYMENT_RECEIVED,
  cancelled: REPAYMENT_NOT_REQUESTED,
  rejected: REPAYMENT_NOT_REQUESTED,
  okay_accounting_pending: ACCOUNTING_PENDING,
  pending_accounting_pending: ACCOUNTING_PENDING,
  incompleted_accounting_pending: ACCOUNTING_PENDING,
  okay_accounting_synced: ACCOUNTING_SYNCED,
  pending_accounting_synced: ACCOUNTING_SYNCED,
  incompleted_accounting_synced: ACCOUNTING_SYNCED,
  okay_accounting_verified: APPROVED_ACCOUNTING_VERIFIED,
  pending_accounting_verified: NEEDS_REVIEW_ACCOUNTING_VERIFIED,
  incompleted_accounting_verified: NEEDS_REVIEW_ACCOUNTING_VERIFIED,
};

export const SUBMISSION_POLICY_STYLE = {
  [SUBMISSION_POLICY_STATUS_KEYS.MET]: {
    background: "bg-success-50",
    color: "text-success-600",
    icon: "Sheild",
  },
  [SUBMISSION_POLICY_STATUS_KEYS.NOT_MET]: {
    background: "bg-danger-50",
    color: "text-danger-600",
    icon: "ShieldCross",
  },
  [SUBMISSION_POLICY_STATUS_KEYS.OVERRIDDEN]: {
    background: "bg-success-50",
    color: "text-success-600",
    icon: "Sheild",
  },
};
export const FLAGGED_EXPENSE_ALERT = {
  AUTO_FLAGGED: {
    label: "expenses.slider.flagAlert.autoFlag.label",
  },
  FLAGGED_BY_OTHERS: {
    label: "expenses.slider.flagAlert.flagByOthers.label",
    description: "expenses.slider.flagAlert.flagByOthers.description",
  },
  FLAGGED_BY_OTHERS_REPAYMENT: {
    label: "expenses.slider.flagAlert.flagByOthersRepayment.label",
    description: "expenses.slider.flagAlert.flagByOthersRepayment.description",
  },
  FLAGGED_BY_YOU: {
    label: "expenses.slider.flagAlert.flaggedByYou.label",
  },
};

export const EXPENSE_FLAG_REPAYMENT_SLIDER = {
  FLAGGED: {
    title: "expenses.flagTransaction.title",
    description: "expenses.flagTransaction.description",
    descriptionTranslationProps: (expense) => ({
      expenseOwner: expense?.cardHolder?.displayName,
    }),
    secondaryHeading: "expenses.flagTransaction.secondaryHeading",
    commentVisible: true,
    isSwitchThere: true,
    amountVisible: true,
  },
  REPAYMENT_REQUESTED: {
    title: "expenses.requestRepaymentFooter.title",
    description: "expenses.requestRepaymentFooter.description",
    secondaryHeading: "expenses.requestRepaymentFooter.secondaryHeading",
    commentVisible: false,
    isSwitchThere: false,
    amountVisible: true,
  },
  REPAYMENT_DECLINED: {
    title: "expenses.declineRepaymentFooter.title",
    description: "expenses.declineRepaymentFooter.description",
    secondaryHeading: "expenses.declineRepaymentFooter.secondaryHeading",
    commentVisible: true,
    isSwitchThere: false,
    amountVisible: false,
  },
  REPAYMENT_CANCELLED: {
    title: "expenses.cancelRepaymentFooter.title",
    description: "expenses.cancelRepaymentFooter.description",
    secondaryHeading: "expenses.cancelRepaymentFooter.secondaryHeading",
    commentVisible: true,
    isSwitchThere: false,
    amountVisible: false,
  },
};
export const FLAG_EXPENSE_CTA_KEYS = {
  CANCEL_FLAG: "cancelFlag",
  FLAG: "flag",
  FLAG_AND_REQUEST: "flagAndRequest",
  REQUEST_REPAYMENT: "requestRepayment",
  CANCEL_REPAYMENT: "cancelRepayment",
  CANCEL_AND_APPROVE_REPAYMENT: "cancelAndApproveRepayment",
  DECLINE_REPAYMENT: "declineRepayment",
};
export const FLAG_EXPENSE_FORM_BUTTON_TYPE = {
  SUBMIT: "submit",
  CANCEL: "reset",
};
export const FLAG_EXPENSE_FOOTER = {
  FLAGGED: {
    secondaryBtn: {
      btnType: FLAG_EXPENSE_FORM_BUTTON_TYPE.CANCEL,
      label: "expenses.flagTransaction.cancelFlag",
      type: "default",
      variant: "tertiary",
      class: "text-neutral-500",
      key: FLAG_EXPENSE_CTA_KEYS.CANCEL_FLAG,
    },
    primaryBtn: {
      label: "expenses.flagTransaction.flag",
      type: "danger",
      variant: "primary",
      btnType: FLAG_EXPENSE_FORM_BUTTON_TYPE.SUBMIT,
      key: FLAG_EXPENSE_CTA_KEYS.FLAG,
    },
    primaryBtnVaraint: {
      label: "expenses.flagTransaction.flagAndRequest",
      type: "danger",
      variant: "primary",
      btnType: FLAG_EXPENSE_FORM_BUTTON_TYPE.SUBMIT,
      key: FLAG_EXPENSE_CTA_KEYS.FLAG_AND_REQUEST,
    },
  },
  REPAYMENT_REQUESTED: {
    secondaryBtn: {
      label: "expenses.flagTransaction.cancelFlag",
      type: "default",
      variant: "tertiary",
      class: "text-neutral-500",
      btnType: FLAG_EXPENSE_FORM_BUTTON_TYPE.CANCEL,
      key: FLAG_EXPENSE_CTA_KEYS.CANCEL_FLAG,
    },
    primaryBtn: {
      label: "expenses.requestRepaymentFooter.footerBtn",
      type: "success",
      variant: "primary",
      class: "w-16.5",
      btnType: FLAG_EXPENSE_FORM_BUTTON_TYPE.SUBMIT,
      key: FLAG_EXPENSE_CTA_KEYS.REQUEST_REPAYMENT,
    },
  },
  REPAYMENT_CANCELLED: {
    secondaryBtn: {
      label: "expenses.cancelRepaymentFooter.secondaryBtn",
      type: "default",
      variant: "tertiary",
      class: "text-danger-500",
      activeClass: "text-danger-500",
      btnType: FLAG_EXPENSE_FORM_BUTTON_TYPE.SUBMIT,
      key: FLAG_EXPENSE_CTA_KEYS.CANCEL_REPAYMENT,
    },
    primaryBtn: {
      label: "expenses.cancelRepaymentFooter.primaryBtn",
      type: "success",
      variant: "primary",
      class: "w-19.9",
      btnType: FLAG_EXPENSE_FORM_BUTTON_TYPE.SUBMIT,
      key: FLAG_EXPENSE_CTA_KEYS.CANCEL_AND_APPROVE_REPAYMENT,
    },
  },
  REPAYMENT_DECLINED: {
    secondaryBtn: null,
    primaryBtn: {
      label: "expenses.declineRepaymentFooter.primaryButton",
      type: "danger",
      variant: "primary",
      btnType: FLAG_EXPENSE_FORM_BUTTON_TYPE.SUBMIT,
      key: FLAG_EXPENSE_CTA_KEYS.DECLINE_REPAYMENT,
    },
  },
};

export const SUBMISSION_POLICY_BADGE_STYLE = {
  [SUBMISSION_POLICY_STATUS_KEYS.MET]: {
    label: "expenses.slider.submissionPolicy.met",
    variant: "success",
    preIcon: "Done",
  },
  [SUBMISSION_POLICY_STATUS_KEYS.NOT_MET]: {
    label: "expenses.slider.submissionPolicy.notMet",
    variant: "danger",
  },
  [SUBMISSION_POLICY_STATUS_KEYS.OVERRIDDEN]: {
    label: "expenses.slider.submissionPolicy.met",
    variant: "success",
    preIcon: "Done",
  },
};
// receipt_required
export const MISSING_DETAILS_ALL_STYLES = {
  RECEIPT_REQUIRED: {
    label: "expenses.slider.missingReceipt",
    icon: "ReceiptMissing",
    color: "text-danger-400",
  },
  NOTE_REQUIRED: {
    label: "expenses.slider.missingMemo",
    icon: "Memo_NotPresent",
    color: "text-danger-400",
  },
  EXPENSE_TAGS_CHECK_SATISFIED: {
    label: "expenses.slider.missingCustomFields",
    icon: "DashboardCustomise",
    color: "text-danger-400",
  },
};
export const CARD_ICON = {
  physical: "Card",
  virtual: "Cloud",
};
export const DECLINE_CODES = {
  LOW_ACCOUNT_BALANCE: "LOW_ACCOUNT_BALANCE",
  LOW_BUDGET_BALANCE: "LOW_BUDGET_BALANCE",
  LOW_CARD_BALANCE: "LOW_CARD_BALANCE",
  CARD_NOT_SUPPORTED: "CARD_NOT_SUPPORTED",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  PROVIDER_ERROR: "PROVIDER_ERROR",
  INVALID_CARD: "INVALID_CARD",
  MERCHANT_NOT_ALLOWED: "MERCHANT_NOT_ALLOWED",
  LIMIT_BREACH: "LIMIT_BREACH",
  INVALID_PIN: "INVALID_PIN",
  TRANSACTION_LIMIT_BREACH: "TRANSACTION_LIMIT_BREACH",
  DAILY_LIMIT_BREACH: "DAILY_LIMIT_BREACH",
  MONTHLY_LIMIT_BREACH: "MONTHLY_LIMIT_BREACH",
  YEARLY_LIMIT_BREACH: "YEARLY_LIMIT_BREACH",
  CARD_INACTIVE: "CARD_INACTIVE",
  DOWNTIME: "DOWNTIME",
  CARD_EXPIRED: "CARD_EXPIRED",
  MCC_NOT_ALLOWED: "MCC_NOT_ALLOWED",
  MONTHLY_ATM_LIMIT_BREACH: "MONTHLY_ATM_LIMIT_BREACH",
  FROZEN_CARD: "FROZEN_CARD",
  CARD_FROZEN_BY_EMPLOYEE: "CARD_FROZEN_BY_EMPLOYEE",
  AUTO_BLOCKED: "AUTO_BLOCKED",
  BLOCKED_CARD: "BLOCKED_CARD",
  INSUFFICIENT_DEPT_LIMIT: "INSUFFICIENT_DEPT_LIMIT",
  INSUFFICIENT_PROJECT_LIMIT: "INSUFFICIENT_PROJECT_LIMIT",
  NO_CARD_BUDGET: "NO_CARD_BUDGET",
  CARD_NOT_FOUND: "CARD_NOT_FOUND",
  BAD_CVV2_OR_EXPIRY: "BAD_CVV2_OR_EXPIRY",
};

export const DECLINE_SLIDER_ALERT = {
  [DECLINE_CODES.LOW_ACCOUNT_BALANCE]: {
    code: "LOW_ACCOUNT_BALANCE",
    title: "accounting.slider.insufficientAccount.title",
    message: "accounting.slider.insufficientAccount.description",
  },
  [DECLINE_CODES.LOW_BUDGET_BALANCE]: {
    code: "LOW_BUDGET_BALANCE",
    title: "accounting.slider.insufficientBudget.title",
    message: "accounting.slider.insufficientBudget.description",
  },
  [DECLINE_CODES.LOW_CARD_BALANCE]: {
    code: "LOW_CARD_BALANCE",
    title: "accounting.slider.insufficientCards.title",
    message: "accounting.slider.insufficientCards.description",
  },
  [DECLINE_CODES.CARD_NOT_SUPPORTED]: {
    code: "CARD_NOT_SUPPORTED",
    title: "accounting.slider.CardNotSupported.title",
    message: "accounting.slider.CardNotSupported.description",
  },
  [DECLINE_CODES.INTERNAL_ERROR]: {
    code: "INTERNAL_ERROR",
    title: "accounting.slider.internalError.title",
    message: "accounting.slider.internalError.description",
  },
  [DECLINE_CODES.PROVIDER_ERROR]: {
    code: "PROVIDER_ERROR",
    title: "accounting.slider.providerError.title",
    message: "accounting.slider.providerError.description",
  },
  [DECLINE_CODES.INVALID_CARD]: {
    code: "INVALID_CARD",
    title: "accounting.slider.invalidCard.title",
    message: "accounting.slider.invalidCard.description",
  },
  [DECLINE_CODES.MERCHANT_NOT_ALLOWED]: {
    code: "MERCHANT_NOT_ALLOWED",
    title: "accounting.slider.merchantNotAllowed.title",
    message: "accounting.slider.merchantNotAllowed.description",
  },
  [DECLINE_CODES.LIMIT_BREACH]: {
    code: "LIMIT_BREACH",
    title: "accounting.slider.limitBreach.title",
    message: "accounting.slider.limitBreach.description",
  },
  [DECLINE_CODES.INVALID_PIN]: {
    code: "INVALID_PIN",
    title: "accounting.slider.invalidPin.title",
    message: "accounting.slider.invalidPin.description",
  },
  [DECLINE_CODES.TRANSACTION_LIMIT_BREACH]: {
    code: "TRANSACTION_LIMIT_BREACH",
    title: "accounting.slider.transactionLimitBreach.title",
    message: "accounting.slider.transactionLimitBreach.description",
  },
  [DECLINE_CODES.DAILY_LIMIT_BREACH]: {
    code: "DAILY_LIMIT_BREACH",
    title: "accounting.slider.dailyLimitBreach.title",
    message: "accounting.slider.dailyLimitBreach.description",
  },
  [DECLINE_CODES.MONTHLY_LIMIT_BREACH]: {
    code: "MONTHLY_LIMIT_BREACH",
    title: "accounting.slider.monthlyLimitBreach.title",
    message: "accounting.slider.monthlyLimitBreach.description",
  },
  [DECLINE_CODES.YEARLY_LIMIT_BREACH]: {
    code: "YEARLY_LIMIT_BREACH",
    title: "accounting.slider.yearlyLimitBreach.title",
    message: "accounting.slider.yearlyLimitBreach.description",
  },
  [DECLINE_CODES.CARD_INACTIVE]: {
    code: "CARD_INACTIVE",
    title: "accounting.slider.cardInactive.title",
    message: "accounting.slider.cardInactive.description",
  },
  [DECLINE_CODES.DOWNTIME]: {
    code: "DOWNTIME",
    title: "accounting.slider.downtime.title",
    message: "accounting.slider.downtime.description",
  },
  [DECLINE_CODES.CARD_EXPIRED]: {
    code: "CARD_EXPIRED",
    title: "accounting.slider.cardExpired.title",
    message: "accounting.slider.cardExpired.description",
  },
  [DECLINE_CODES.MCC_NOT_ALLOWED]: {
    code: "MCC_NOT_ALLOWED",
    title: "accounting.slider.mcc_not_allowed.title",
    message: "accounting.slider.mcc_not_allowed.description",
  },
  [DECLINE_CODES.MONTHLY_ATM_LIMIT_BREACH]: {
    code: "MONTHLY_ATM_LIMIT_BREACH",
    title: "accounting.slider.monthlyLimitBreach.title",
    message: "accounting.slider.monthlyLimitBreach.description",
  },
  // decline codes yet to finalize
  [DECLINE_CODES.FROZEN_CARD]: {
    code: "FROZEN_CARD",
    title: "accounting.slider.frozenCard.title",
    message: "accounting.slider.frozenCard.description",
  },
  [DECLINE_CODES.CARD_FROZEN_BY_EMPLOYEE]: {
    code: "CARD_FROZEN_BY_EMPLOYEE",
    title: "accounting.slider.cardFrozenByEmployee.title",
    message: "accounting.slider.cardFrozenByEmployee.description",
  },
  [DECLINE_CODES.AUTO_BLOCKED]: {
    code: "AUTO_BLOCKED",
    title: "accounting.slider.autoBlockedCard.title",
    message: "accounting.slider.autoBlockedCard.description",
  },
  [DECLINE_CODES.BLOCKED_CARD]: {
    code: "BLOCKED_CARD",
    title: "accounting.slider.blockedCard.title",
    message: "accounting.slider.blockedCard.description",
  },
  [DECLINE_CODES.INSUFFICIENT_DEPT_LIMIT]: {
    code: "INSUFFICIENT_DEPT_LIMIT",
    title: "accounting.slider.InsufficientDeptLimit.title",
    message: "accounting.slider.InsufficientDeptLimit.description",
  },
  [DECLINE_CODES.INSUFFICIENT_PROJECT_LIMIT]: {
    code: "INSUFFICIENT_PROJECT_LIMIT",
    title: "accounting.slider.InsufficientProjectLimit.title",
    message: "accounting.slider.InsufficientProjectLimit.description",
  },
  [DECLINE_CODES.NO_CARD_BUDGET]: {
    code: "NO_CARD_BUDGET",
    message: "accounting.slider.noCardBudget.description",
    title: "accounting.slider.noCardBudget.title",
  },
  [DECLINE_CODES.CARD_NOT_FOUND]: {
    code: "CARD_NOT_FOUND",
    title: "accounting.slider.cardNotFound.title",
    message: "accounting.slider.cardNotFound.message",
  },
  [DECLINE_CODES.BAD_CVV2_OR_EXPIRY]: {
    code: "BAD_CVV2_OR_EXPIRY",
    title: "accounting.slider.badCvv.title",
    message: "accounting.slider.badCvv.message",
  },
};
export const EDIT_LIMIT = "EDIT_LIMIT";
export const ADD_FUNDS = "ADD_FUNDS";
export const ACTIVATE_PHYSICAL_CARD = "ACTIVATE_PHYSICAL_CARD";
export const FREEZE_CARD = "FREEZE_CARD";
export const UNFREEZE_CARD = "UNFREEZE_CARD";
export const INSUFFICIENT_DEPT_LIMIT = "INSUFFICIENT_DEPT_LIMIT";
export const INSUFFICIENT_DEPT_LIMIT_ADMIN = "INSUFFICIENT_DEPT_LIMIT_ADMIN";
export const INSUFFICIENT_PROJECT_LIMIT = "INSUFFICIENT_PROJECT_LIMIT";
export const INSUFFICIENT_PROJECT_LIMIT_ADMIN =
  "INSUFFICIENT_PROJECT_LIMIT_ADMIN";
export const LOW_ACCOUNT_BALANCE = "LOW_ACCOUNT_BALANCE";

export const DECLINE_EXPENSE_ALERT_CTA_TEXT = {
  [EDIT_LIMIT]: "Edit limit",
  [ADD_FUNDS]: "Add Funds",
  [LOW_ACCOUNT_BALANCE]: "Notify admin",
  [ACTIVATE_PHYSICAL_CARD]: "Activate Physical card",
  [FREEZE_CARD]: "Freeze card",
  [UNFREEZE_CARD]: "Unfreeze card",
  [INSUFFICIENT_DEPT_LIMIT_ADMIN]: "Increase budget",
  [INSUFFICIENT_DEPT_LIMIT]: "Notify admin",
  [INSUFFICIENT_PROJECT_LIMIT_ADMIN]: "Increase budget",
  [INSUFFICIENT_PROJECT_LIMIT]: "Notify admin",
};

export const DECLINE_EXPENSE_ALERT_CTA = {
  [DECLINE_CODES.LOW_CARD_BALANCE]: DECLINE_EXPENSE_ALERT_CTA_TEXT[EDIT_LIMIT],
  [DECLINE_CODES.LOW_ACCOUNT_BALANCE]:
    DECLINE_EXPENSE_ALERT_CTA_TEXT[ADD_FUNDS],
  [DECLINE_CODES.CARD_INACTIVE]:
    DECLINE_EXPENSE_ALERT_CTA_TEXT[ACTIVATE_PHYSICAL_CARD],
  [DECLINE_CODES.FROZEN_CARD]: DECLINE_EXPENSE_ALERT_CTA_TEXT[FREEZE_CARD],
  [DECLINE_CODES.UNFREEZE_CARD]: DECLINE_EXPENSE_ALERT_CTA_TEXT[UNFREEZE_CARD],
  [DECLINE_CODES.INSUFFICIENT_DEPT_LIMIT]:
    DECLINE_EXPENSE_ALERT_CTA_TEXT[INSUFFICIENT_DEPT_LIMIT],
  [DECLINE_CODES.INSUFFICIENT_PROJECT_LIMIT]:
    DECLINE_EXPENSE_ALERT_CTA_TEXT[INSUFFICIENT_PROJECT_LIMIT],
};

// Expense Decline Detials

export const DECLINE_DETAILS_KEYS = {
  TOTAL_AMOUNT: "totalAmount",
  TOTAL_DECLINE: "totalDeclines",
  MOST_COMMON_REASON: "mostCommonReason",
};

export const ALL_ALLOWED_CTAS = {
  REMIND: "remind",
  APPROVE: "approve",
  FLAG: "flag",
  BULK_APPROVE: "bulk-approve",
  REQUEST_REPAYMENT: "request-repayment",
  CANCEL_REPAYMENT: "cancel-repayment",
  REPAYMENT_RECEIVED: "received-repayment",
  DECLINE_REPAYMENT: "decline-repayment",
  VERIFY: "verify",
  UNVERIFY: "unverify",
  SYNC: "sync",
  MARK_AS_SYNC: "mark-as-synced",
  SYNCED: "synced",
  SKIP_APPROVALS: "skipApprovals",
  REJECT: "reject",
};

export const EXPENSE_FOOTER_CTA = {
  [ALL_ALLOWED_CTAS.FLAG]: {
    label: "expenses.slider.flag",
    tableVariant: "tertiary",
    type: "danger",
    variant: "tertiary",
    configType: "secondary",
    key: EXPENSE_CTA_KEYS.FLAG,
    tableConfigType: "moreButtons",
    class: "",
  },
  [ALL_ALLOWED_CTAS.APPROVE]: {
    type: "success",
    variant: "primary",
    tableVariant: "tertiary",
    label: "expenses.slider.approve",
    key: EXPENSE_CTA_KEYS.APPROVE,
    preIcon: "CircleCheck",
    configType: "primary",
    tableConfigType: "primary",
    class: "",
  },

  [ALL_ALLOWED_CTAS.REQUEST_REPAYMENT]: {
    label: "expenses.slider.requestRepayment",
    type: "success",
    variant: "tertiary",
    tableVariant: "tertiary",
    key: EXPENSE_CTA_KEYS.REQUEST_REPAYMENT,
    configType: "secondary",
    tableConfigType: "moreButtons",
    class: "w-16.5",
  },
  [ALL_ALLOWED_CTAS.REPAYMENT_RECEIVED]: {
    label: "expenses.slider.repaymentReceived",
    type: "success",
    variant: "primary",
    tableVariant: "tertiary",
    key: EXPENSE_CTA_KEYS.REPAYMENT_RECEIVED,
    configType: "primary",
    tableConfigType: "primary",
    class: "w-17.5",
  },

  [ALL_ALLOWED_CTAS.CANCEL_REPAYMENT]: {
    label: "expenses.slider.cancelRepayment",
    type: "success",
    tableVariant: "tertiary",
    variant: "tertiary",
    key: EXPENSE_CTA_KEYS.REPAYMENT_CANCELLED,
    configType: "secondary",
    tableConfigType: "moreButtons",
    class: "w-17.5",
  },

  [ALL_ALLOWED_CTAS.DECLINE_REPAYMENT]: {
    label: "expenses.slider.declineRepayment",
    type: "danger",
    tableVariant: "tertiary",
    variant: "primary",
    key: EXPENSE_CTA_KEYS.REPAYMENT_DECLINED,
    configType: "",
    tableConfigType: "moreButtons",
    class: "w-17.5",
  },
  [ALL_ALLOWED_CTAS.REJECT]: {
    type: "danger",
    variant: "tertiary",
    tableVariant: "tertiary",
    label: "Reject",
    key: EXPENSE_CTA_KEYS.REJECT,
    configType: "primary",
    class: "w-15.5",
    tableConfigType: "moreButtons",
  },

  [ALL_ALLOWED_CTAS.SKIP_APPROVALS]: {
    type: "success",
    variant: "tertiary",
    tableVariant: "tertiary",
    label: "Skip approvals",
    key: EXPENSE_CTA_KEYS.SKIP_APPROVALS,
    configType: "moreButtons",
    class: "",
    tableConfigType: "moreButtons",
  },
};
export const EXPENSE_FOOTER_TEXT = {
  [ALL_ALLOWED_CTAS.DECLINE_REPAYMENT]: {
    text: "expenses.declineRepaymentFooter.footerText",
    classes: "text-sm text-neutral-600 font-semibold ",
  },
};
export const ACCOUNTING_CTAS = {
  [ALL_ALLOWED_CTAS.VERIFY]: {
    label: "accounting.slider.verify",
    type: "success",
    variant: "primary",
    configType: "primary",
    key: EXPENSE_CTA_KEYS.ACCOUNTING_VERIFY,
    class: "",
  },
  [ALL_ALLOWED_CTAS.SYNC]: {
    label: "accounting.slider.sync",
    type: "success",
    variant: "primary",
    configType: "primary",
    key: EXPENSE_CTA_KEYS.ACCOUNTING_SYNC,
    class: "",
  },
  [ALL_ALLOWED_CTAS.UNVERIFY]: {
    label: "accounting.slider.unverify",
    type: "default",
    variant: "tertiary",
    key: EXPENSE_CTA_KEYS.ACCOUNTING_UNVERIFY,
    configType: "secondary",
    class: "",
  },
  [ALL_ALLOWED_CTAS.MARK_AS_SYNC]: {
    label: "accounting.slider.markAsSynced",
    description: "accounting.slider.markAsSyncedDescription",
    type: "default",
    variant: "tertiary",
    key: EXPENSE_CTA_KEYS.MARK_AS_PAID,
    configType: "moreButtons",
    class: "",
  },
  [ALL_ALLOWED_CTAS.SYNCED]: {
    label: "accounting.slider.synced",
    type: "default",
    variant: "tertiary",
    configType: "primary",
    key: null,
    class: "text-neutral-300",
  },
};

export const EXPENSE_PRIMARY_SECONDARY_CTA = {
  [NEEDS_REVIEW]: {
    PRIMARY: EXPENSE_FOOTER_CTA.APPROVE,
    SECONDARY: EXPENSE_FOOTER_CTA.FLAG,
  },
  [REPAYMENT_NOT_REQUESTED]: {
    PRIMARY: EXPENSE_FOOTER_CTA.APPROVE,
    SECONDARY: EXPENSE_FOOTER_CTA.REQUEST_REPAYMENT,
  },
  [REPAYMENT_REQUESTED]: {
    PRIMARY: EXPENSE_FOOTER_CTA.REPAYMENT_RECEIVED,
    SECONDARY: EXPENSE_FOOTER_CTA.REPAYMENT_CANCELLED,
  },
  [REPAYMENT_RECEIVED]: {
    PRIMARY: EXPENSE_FOOTER_CTA.APPROVE,
    SECONDARY: null,
  },
  [REPAYMENT_DECLINED]: {
    PRIMARY: EXPENSE_FOOTER_CTA.REPAYMENT_DECLINED,
    SECONDARY: null,
    FOOTER_TEXT: EXPENSE_FOOTER_TEXT.REPAYMENT_DECLINED,
    OTHER_OPTIONS: null,
  },
  [ACCOUNTING_PENDING]: {
    PRIMARY: EXPENSE_FOOTER_CTA.VERIFY,
    SECONDARY: null,
    FOOTER_TEXT: null,
    OTHER_OPTIONS: null,
  },
  [ACCOUNTING_SYNCED]: {
    PRIMARY: EXPENSE_FOOTER_CTA.SYNCED,
    SECONDARY: null,
    FOOTER_TEXT: null,
    OTHER_OPTIONS: null,
  },

  [APPROVED_ACCOUNTING_VERIFIED]: {
    PRIMARY: EXPENSE_FOOTER_CTA.SYNC,
    SECONDARY: EXPENSE_FOOTER_CTA.UNVERIFY,
    OTHER_OPTIONS: [EXPENSE_FOOTER_CTA.MARK_AS_SYNC],
  },
  [NEEDS_REVIEW_ACCOUNTING_VERIFIED]: {
    PRIMARY: EXPENSE_FOOTER_CTA.SYNC,
    SECONDARY: EXPENSE_FOOTER_CTA.UNVERIFY,
    OTHER_OPTIONS: [
      EXPENSE_FOOTER_CTA.APPROVE,
      EXPENSE_FOOTER_CTA.FLAG,
      EXPENSE_FOOTER_CTA.MARK_AS_SYNC,
    ],
  },
};
export const EXPENSES_PATH_NAMES = {
  NEEDS_REVIEW: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${EXPENSES_NEEDS_REVIEW_PATH_NAME}`,
  OVERVIEW: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${OVERVIEW_PATH_NAME}`,
  FLAGGED: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${FLAGGED_PATH_NAME}`,
  DECLINED: `${CARDS_BASE_ROUTE}/${EXPENSES_BASE_ROUTE}/${DECLINES_PATH_NAME}`,
};

export const ACCOUNTING_CARDS_FOOTER_CTA = {
  [ALL_ALLOWED_CTAS.SYNC]: {
    type: "success",
    variant: "primary",
    label: "expenses.slider.approve",
    key: EXPENSE_STATUSES.SYNCED,
    configType: "primary",
    class: "w-15.5",
  },
  [ALL_ALLOWED_CTAS.FLAG]: {
    label: "expenses.slider.flag",
    type: "danger",
    variant: "tertiary",
    configType: "secondary",
    key: EXPENSE_CTA_KEYS.FLAG,
    class: "w-15.5",
  },
  [ALL_ALLOWED_CTAS.REQUEST_REPAYMENT]: {
    label: "expenses.slider.requestRepayment",
    type: "success",
    variant: "tertiary",
    key: EXPENSE_CTA_KEYS.REQUEST_REPAYMENT,
    configType: "secondary",
    class: "",
  },
  [ALL_ALLOWED_CTAS.REPAYMENT_RECEIVED]: {
    label: "expenses.slider.repaymentReceived",
    type: "success",
    variant: "primary",
    key: EXPENSE_CTA_KEYS.REPAYMENT_RECEIVED,
    configType: "primary",
    class: "w-17.1",
  },
  [ALL_ALLOWED_CTAS.CANCEL_REPAYMENT]: {
    label: "expenses.slider.cancelRepayment",
    type: "success",
    variant: "tertiary",
    key: EXPENSE_CTA_KEYS.REPAYMENT_CANCELLED,
    configType: "secondary",
    class: "w-17.5",
  },
  [ALL_ALLOWED_CTAS.DECLINE_REPAYMENT]: {
    label: "expenses.slider.declineRepayment",
    type: "danger",
    variant: "primary",
    key: EXPENSE_CTA_KEYS.REPAYMENT_DECLINED,
    configType: "primary",
    class: "w-17.5",
  },
};

export const REPAYMENT_REQUEST_STATUS = {
  UNSOUGHT: "unsought",
  REQUESTED: "requested",
  CANCELLED: "cancelled",
  RECIEVED: "received",
  REJECTED: "rejected",
};

export const TRANSACTION_TYPE_EXPENSES_OVERVIEW = {
  PURCHASE: "purchase",
  PURCHASE_REVERSAL: "purchase_reversal",
};

export const INFINITY_BUDGET_AVAILABLE_TEXT = "Infinity";

export const CARDS_MODULE_EXPENSE_SLIDER = "cardModuleExpenseSlider";

export const ACCOUNTING_EXPENSE_SLIDER_PAGE = {
  [CARDS_MODULE_EXPENSE_SLIDER]: "expense",
  [EXPENSE]: "expense",
};

export const UNSETTLED_EXPENSE_FILTER_CONFIG = {
  label: "expenses.unsettledFilterLabel",
  levelPosition: "L.0",
  param: "settlement_status",
  filterLabel: "Unsettled",
  values: "unsettled",
};

export const SELECTED_EXPENSES_STATS_TAB = [
  {
    name: "cards.cardDetails.overview.policyDetails.expenses.recent",
    count: null,
    key: 1,
    dispatchFuncParams: {
      sort_column: "expense_date",
      sort_direction: "desc",
      transaction_type: ["purchase", "purchase_reversal"],
    },
  },
  {
    name: "cards.cardDetails.overview.policyDetails.expenses.largest",
    count: null,
    key: 2,
    dispatchFuncParams: {
      sort_column: "amount",
      sort_direction: "desc",
      transaction_type: ["purchase", "purchase_reversal"],
    },
  },
];

export const NEEDS_REVIEW_TABS = [
  {
    name: "expenses.needsReview.tabNames.needsYourReview",
    count: null,
    path: ROUTES.cards.expenses.needsReview.needsYourReview.absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "needs-your-review",
    },
  },
  {
    name: "expenses.needsReview.tabNames.pending",
    count: null,
    path: ROUTES.cards.expenses.needsReview.pending.absolutePath,
    key: 2,
    defaultQueryParams: {
      tab: "pending",
    },
  },
  {
    name: "expenses.needsReview.tabNames.all",
    count: null,
    path: ROUTES.cards.expenses.needsReview.all.absolutePath,
    key: 3,
    defaultQueryParams: {
      tab: "all",
    },
  },
];
export const OVERVIEW_TABLE_HEADER_KEY = {
  DATE: "date",
  REVIEW_STATUS: "reviewStatus",
  POLICY_STATUS: "policyStatus",
  AMOUNT: "amount",
};
export const OVERVIEW_TABLE_EXPENSES = [
  {
    id: OVERVIEW_TABLE_HEADER_KEY.DATE,
    title: "expenses.overview.overviewTable.headers.date",
    classes: "text-xs font-semibold",
    sortable: true,
  },
  {
    id: OVERVIEW_TABLE_HEADER_KEY.REVIEW_STATUS,
    title: "expenses.overview.overviewTable.headers.reviewStatus",
    classes: "text-xs font-semibold text-center  flex-1 flex justify-center",
  },
  {
    id: OVERVIEW_TABLE_HEADER_KEY.POLICY_STATUS,
    title: "expenses.overview.overviewTable.headers.policyStatus",
    classes: "text-xs font-semibold text-center  flex-1 flex justify-center",
  },
  {
    id: OVERVIEW_TABLE_HEADER_KEY.AMOUNT,
    title: "expenses.overview.overviewTable.headers.amount",
    classes: "text-xs font-semibold text-right  flex-1 flex justify-end",
    sortable: true,
  },
];
