import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { fetchReceipts } from "@/store/reducers/accounting";

import {
  filesDataSelector,
  filesErrorSelector,
} from "@/store/selectors/accounting";

import SliderLoader from "@/components/core/SliderLoader";
import Text from "@/components/core/Text";
import { FullUI } from "@/components/core/VPError";

import ChatWithButton from "@/components/common/ChatWithButton";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function LoadingFiles() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const filesData = useSelector(filesDataSelector);
  const filesError = useSelector(filesErrorSelector);
  const navigate = useNavigate();
  const type = searchParams.get(SLIDERS_SEARCH_PARAMS.type);
  const spend = searchParams.get(SLIDERS_SEARCH_PARAMS.spend);
  const id = searchParams.get(SLIDERS_SEARCH_PARAMS.id);

  useEffect(() => {
    dispatch(fetchReceipts({ id, type, spend, navigate }));
  }, []);

  if (filesError)
    return (
      <div className="relative grid w-full h-screen place-items-center">
        <FullUI errorObject={filesError} />
        <ChatWithButton />
      </div>
    );
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen ">
      {!filesData ? (
        <div>
          <SliderLoader />
          <Text
            translationKey="common.fileDownloading"
            classes="text-neutral-500"
          />
        </div>
      ) : (
        <>
          <iframe
            className="w-full h-full bg-cover"
            src={filesData}
            id="iframe-files"
            title={type + spend + id}
          />
          <ChatWithButton />
        </>
      )}
    </div>
  );
}
