import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchCountries } from "@/store/reducers/app";
import { setSelectedEditFormDetails } from "@/store/reducers/user";

import { countriesSelector } from "@/store/selectors/app";
import {
  selectedUserSelector,
  selectedUserUpdatedDetailsSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import { useForm } from "@/utils/useForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { COUNTRY_CODE_ENUM, COUNTRY_STATES } from "@/constants/onboarding";
import {
  VP_ADDRESS_DETAILS_CONFIG,
  VP_ADDRESS_DETAILS_CONFIG_MAP,
} from "@/constants/user";

export default function AddAddressDetailsSlider({ setOnBack = () => {} }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedUser = useSelector(selectedUserSelector);
  const selectedUserUpdatedDetails = useSelector(
    selectedUserUpdatedDetailsSelector
  );

  const editMode = searchParams?.get(
    SLIDERS_SEARCH_PARAMS.company.people.editAddressDetails
  );

  const ref = useLeftHeaderTitle({
    title: "billPay.vendors.createVendor.addAddressDetailsPage.title",
  });
  const countryOptions = useSelector(countriesSelector);

  const currentCountry = countryOptions.find((country) => {
    return [
      selectedUser?.permanentAddress?.country,
      selectedUserUpdatedDetails?.permanentAddressAttributes?.country,
    ]?.includes(country?.name || country.isoCode);
  });

  const currentState = useMemo(() => {
    if (
      [COUNTRY_CODE_ENUM.AUS, COUNTRY_CODE_ENUM.USA].includes(
        currentCountry?.isoCode
      )
    ) {
      const matchingState = COUNTRY_STATES[currentCountry?.isoCode]?.find(
        (state) =>
          [
            selectedUser?.permanentAddress?.state,
            selectedUserUpdatedDetails?.permanentAddressAttributes?.state,
          ]?.includes(state?.name || state.value)
      );

      return matchingState?.value;
    }
    return selectedUser?.permanentAddress?.state;
  }, [currentCountry, selectedUser, selectedUserUpdatedDetails]);

  const initialFormValue = {
    address1: {
      value:
        selectedUser?.permanentAddress?.address1 ??
        selectedUserUpdatedDetails?.permanentAddressAttributes?.address1 ??
        "",
      validate: { required: true },
    },
    address2: {
      value:
        selectedUser?.permanentAddress?.address2 ??
        selectedUserUpdatedDetails?.permanentAddressAttributes?.address2 ??
        "",
      validate: { required: true },
    },
    country: {
      value:
        currentCountry?.isoCode ??
        selectedUserUpdatedDetails?.permanentAddressAttributes?.country ??
        "",
      validate: { required: true },
    },
    state: {
      value:
        currentState ??
        selectedUserUpdatedDetails?.permanentAddressAttributes?.state ??
        "",
      validate: { required: true },
    },
    city: {
      value:
        selectedUser?.permanentAddress?.city ??
        selectedUserUpdatedDetails?.permanentAddressAttributes?.city ??
        "",
      validate: { required: true },
    },
    zipcode: {
      value:
        selectedUser?.permanentAddress?.zipcode ??
        selectedUserUpdatedDetails?.permanentAddressAttributes?.zipcode ??
        "",
      validate: { required: true },
    },
  };

  const onSubmit = () => {
    const permanentAddressAttributes = {
      id: selectedUser?.permanentAddress?.id,
      address_1: values.address1,
      address_2: values.address2,
      city: values.city,
      state: [COUNTRY_CODE_ENUM.AUS, COUNTRY_CODE_ENUM.USA].includes(
        values.country
      )
        ? COUNTRY_STATES?.[values?.country]?.find(
            (country) => country.value === values.state
          )?.name
        : values.state,
      zipcode: values.zipcode,
      country: countryOptions.find(
        (country) => country?.isoCode === values.country
      ).name,
    };

    const payload = Object.entries(permanentAddressAttributes)
      .filter(([key, value]) => value !== undefined)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    dispatch(
      setSelectedEditFormDetails({
        permanentAddressAttributes: payload,
      })
    );

    if (editMode) {
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.company.people.editAddressDetails
      );
    } else {
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.company.people.addAddressDetails
      );
    }
    setSearchParams(searchParams);
  };

  const cancel = () => {
    searchParams.delete([
      SLIDERS_SEARCH_PARAMS.company.people.addAddressDetails,
    ]);
    searchParams.delete([SLIDERS_SEARCH_PARAMS.company.people.edit]);
    setSearchParams(searchParams);
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialFormValue, onSubmit);

  useEffect(() => {
    dispatch(fetchCountries());
    setOnBack(() => {
      if (editMode) {
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.company.people.editAddressDetails
        );
      } else {
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.company.people.addAddressDetails
        );
      }
      setSearchParams(searchParams);
    });
  }, []);

  return (
    <>
      <div className="slider-content-core">
        <div className="flex flex-col mb-8">
          <Text
            refProp={ref}
            translationKey="billPay.vendors.createVendor.addAddressDetailsPage.edit.title"
            classes="text-2xl font-bold text-neutral-800"
          />
          <Text
            translationKey="billPay.vendors.createVendor.addAddressDetailsPage.edit.description"
            classes="mt-1 text-sm text-neutral-500 font-medium"
          />
        </div>
        <form
          onSubmit={handleSubmit}
          id="add-or-edit-user-address-details"
          className="flex flex-col gap-6"
        >
          {Object.keys(initialFormValue)?.map((addressInput, index) => {
            if (addressInput === VP_ADDRESS_DETAILS_CONFIG.COUNTRY) {
              return (
                <VpSelect
                  name={addressInput}
                  key={index}
                  handleChange={handleChange}
                  options={countryOptions}
                  label={VP_ADDRESS_DETAILS_CONFIG_MAP[addressInput]}
                  menuPosition="absolute"
                  insideForm
                  value={values[addressInput]}
                  error={errors[addressInput]}
                  optionsDisplayKey="name"
                  valueKey="isoCode"
                />
              );
            }

            if (
              addressInput === VP_ADDRESS_DETAILS_CONFIG.STATE_OR_PROVINCE &&
              [COUNTRY_CODE_ENUM.AUS, COUNTRY_CODE_ENUM.USA].includes(
                values[VP_ADDRESS_DETAILS_CONFIG.COUNTRY]
              )
            ) {
              return (
                <VpSelect
                  key={index}
                  insideForm
                  name={addressInput}
                  handleChange={handleChange}
                  value={values[addressInput]}
                  error={errors[addressInput]}
                  label={VP_ADDRESS_DETAILS_CONFIG_MAP[addressInput]}
                  menuPosition="absolute"
                  valueKey="value"
                  optionsDisplayKey="name"
                  options={
                    COUNTRY_STATES?.[
                      values?.[VP_ADDRESS_DETAILS_CONFIG.COUNTRY]
                    ]
                  }
                />
              );
            }

            return (
              <Input
                name={addressInput}
                type={
                  addressInput === VP_ADDRESS_DETAILS_CONFIG.ZIPCODE
                    ? "number"
                    : "text"
                }
                onChange={handleChange}
                key={`addressInput-${index}`}
                label={VP_ADDRESS_DETAILS_CONFIG_MAP[addressInput]}
                labelExtraClasses="text-sm font-semibold"
                classes="text-sm font-semibold"
                value={values[addressInput]}
                error={errors[addressInput]}
              />
            );
          })}
        </form>
      </div>
      <div className="slider-footer-right-buttons slider-footer">
        <Button
          variant="tertiary"
          classes="px-5 py-3"
          label="company.people.peopleMyProfileSlider.cancel"
          labelStyleClasses="text-neutral-500 font-semibold"
          onClick={cancel}
          compact
        />
        <Button
          variant="primary"
          classes="px-5 py-3"
          label="misc.continue"
          labelStyleClasses="font-semibold"
          form="add-or-edit-user-address-details"
          btnType="submit"
          compact
          disabled={isFormButtonDisabled}
        />
      </div>
    </>
  );
}

AddAddressDetailsSlider.propTypes = {
  setOnBack: PropTypes.func,
};
