import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { fetchAccountingVendors } from "@/store/reducers/accounting";

import {
  accountingVendorsSelector,
  isFetchedAccountingVendorsSelector,
  isFetchingAccountingVendorsSelector,
} from "@/store/selectors/accounting";
import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";

import Badge from "@/components/core/Badge";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import { VENDOR_DEFAULT_PAYLOAD_KEYS } from "@/constants/vendors";

/**
 * from useForm returnage
 * @param {Object} values
 * @param {Object} errors
 * @param {Function} handleChange
 * @param {Function} setValues
 *
 * keys for dropdown/textbox component, also passed to useForm
 * @param {String} dropdownInputKey
 * @param {String} textInputKey
 *
 * is the dropdown a creatable one?
 * @param {Boolean} creatable
 *
 * UI extension props
 * @param {Object} vpSelectProps
 * @param {Object} inputProps
 * @param {Boolean} inPayrollContext
 */
export default function AccountingVendorInput({
  values,
  errors,
  handleChange,
  setValues,
  label,
  helperText,
  placeholder,
  isEditMode,

  // use generalized enums, not feature specific
  dropdownInputKey = VENDOR_DEFAULT_PAYLOAD_KEYS.SELECTED_ACCOUNTING_VENDOR,
  textInputKey = VENDOR_DEFAULT_PAYLOAD_KEYS.ACCOUNTING_VENDOR_NAME,

  creatable = false,

  vpSelectProps = {}, // dropdown component
  inputProps = {}, // text input component
  inPayrollContext = false,
  isFillTextFieldFromDropdown = true, // fixes 'Alias name is blank issue'. Previously this was false, and dropdown indicated selection from existing and name indicated creation (creatable dropdown).
}) {
  const dispatch = useDispatch();
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );
  const isFetchedAccountingVendors = useSelector(
    isFetchedAccountingVendorsSelector
  );
  const isFetchingAccountingVendors = useSelector(
    isFetchingAccountingVendorsSelector
  );
  const accountingVendorOptions = useSelector(accountingVendorsSelector);

  const createAccountingVendorHandler = (val) => {
    // select created
    if (!val.trim()) return;

    setValues((prevValues) => ({
      ...prevValues,
      // for the dropdown (to be selected immediately)
      [dropdownInputKey]: -1,

      // save in form state, for final payload, and also
      // helps retain the selected value when going forward and coming back in sliders
      [textInputKey]: val.trim(),
    }));
  };

  return accountingEnabled ? (
    <VpSelect
      name={dropdownInputKey}
      value={values[dropdownInputKey]}
      error={errors[dropdownInputKey]}
      handleChange={(e) => {
        if (isFillTextFieldFromDropdown) {
          // alias_name for dropdown selected value should be sent
          setValues((prev) => ({
            ...prev,
            [textInputKey]: e.label,
          }));
        }
        handleChange(e);
      }}
      insideForm
      rightText={
        values[textInputKey] &&
        values[dropdownInputKey]?.toString() === (-1).toString() ? (
          <Badge
            translationKey="billPay.vendors.createVendor.new"
            variant="success"
            classes="rounded-[100px] px-2 py-[2px]"
          />
        ) : null
      }
      label={label}
      placeholder={placeholder}
      creatable={creatable}
      options={
        // show created one, and at first place
        (values?.[textInputKey]
          ? [
              {
                // doing this since <VpSelect creatable /> doesn't support custom keys
                alias: values?.[textInputKey],
                id: -1,
              },
            ]
          : []
        )
          .concat(accountingVendorOptions)
          .map((item) => ({
            // doing this since <VpSelect creatable /> doesn't support custom keys
            label: item?.alias,
            value: item?.id,
          }))
      }
      isOptionsFetched={isFetchedAccountingVendors}
      isOptionsLoading={isFetchingAccountingVendors}
      fetchOptions={() => dispatch(fetchAccountingVendors())}
      menuPosition="absolute"
      valueKey="value"
      optionsDisplayKey="label"
      handleCreate={createAccountingVendorHandler}
      helperText={helperText}
      disabled={!!isEditMode}
      formatCreateLabel={(val) => (
        <div className="flex items-center gap-3">
          <Icon className="w-3 h-3 text-neutral-800" name="Add" />
          <Text
            translationKey={
              inPayrollContext
                ? "billPay.vendors.createVendor.createEmployeeXAndMapWithYSoftwareON"
                : "billPay.vendors.createVendor.createVendorXAndMapWithYSoftwareON"
            }
            translationProps={{
              newName: val,
              accountingSoftwareName:
                ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                  accountingIntegrationSoftware
                ],
            }}
            classes="text-base text-neutral-500 font-medium"
          />
        </div>
      )}
      {...vpSelectProps}
    />
  ) : (
    <Input
      name={textInputKey}
      label={label}
      placeholder={placeholder}
      value={values[textInputKey]}
      error={errors[textInputKey]}
      onChange={handleChange}
      disabled={!!isEditMode}
      {...inputProps}
    />
  );
}

AccountingVendorInput.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,

  dropdownInputKey: PropTypes.string,
  textInputKey: PropTypes.string,

  creatable: PropTypes.bool,

  vpSelectProps: PropTypes.object,
  inputProps: PropTypes.object,
  isEditMode: PropTypes.bool,
  inPayrollContext: PropTypes.bool,
  isFillTextFieldFromDropdown: PropTypes.bool,
};
