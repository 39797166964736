export const TRANSACTION_ACTIONS = {
  VERIFY: "verify",
  VERIFIED: "verified",
  SYNC: "sync",
  SYNCED: "synced",
  APPROVE: "approve",
  SYNC_IN_PROGRESS: "sync_in_progress",
  SYNC_FAILED: "sync_failed",
  EXPORT: "export",
  EXPORTED: "exported",
  UNVERIFY: "unverify",
};
