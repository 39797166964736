import {
  camelToSnake,
  convertRangeToText,
  convertToArrayGetRange,
  getRange,
} from "@/utils/common";

import { CARD_APPROVAL_REQUEST_TYPE } from "@/constants/ActionCentre";
import { CARD_TYPE } from "@/constants/Cards";
import { DATE_RANGE_KEYS } from "@/constants/date";
import { MASTER_HISTORY_ACTIVITY_TYPES } from "@/constants/settings";

import { MODULES } from "./app";
import { REIMBURSEMENT_TYPE_KEY } from "./reimbursement";

export const FILTER_TRIGGER_CONTEXT = {
  PAGE: "page",
  SLIDER: "slider",
};

export const SLIDER_PARAMS_PREFIX = "slider__";

export const MIN_AMOUNT = "minAmount";
export const MAX_AMOUNT = "maxAmount";
export const TO = "to";
export const FROM = "from";
export const UNSETTLED = "unsettled";
export const SETTLED = "settled";
export const MIN_UTILIZATION = "from_utilization";
export const MAX_UTILIZATION = "to_utilization";

export const SETTLEMENT_STATUS = {
  UNSETTLED: "unsettled",
  SETTLED: "settled",
};
export const BUDGET_TYPE_FILTER_VALUES = {
  FLEXIBLE: "flexible",
  NON_FLEXIBLE: "non_flexible",
};
export const SUBMISSION_POLICY_FILTER_VALUE = {
  MET: "met",
  NOT_MET: "unfulfilled",
};
export const BUDGETED_FOR_FILTER_VALUE = {
  cardSpends: ["expense"],
  billpaySpends: ["payment"],
  reimbursementSpends: ["reimbursement"],
};
export const TRANSACTION_STATUS_FILTER_VALUE = {
  PENDING: "pending",
  PENDING_TO_BE_TRIGGERED: "pending_to_be_triggered",
  INCOMPLETED: "incompleted",
  APPROVED: "approved",
  NOT_OKAY: "not_okay",
  OKAY: "okay",
  AUTO_APPROVED: "auto_approved",
  AUTO_FLAGGED: "auto_flagged",
  ALL: "all",
};
export const TRANSACTION_TYPE_FILTER_VALUE = {
  PURCHASE: "purchase",
  PURCHASE_REVERSAL: "purchase_reversal",
};
export const POLICY_FILTER_VALUES = {
  DEFAULT_POLICY: "default",
  CUSTOM_POLICY: "custom",
};

export const RECEIPT_STATUS_FILTER_VALUE = {
  MISSING: "missing",
  SUBMITTED: "submitted",
  VERIFIED: "verified",
  NOT_REQUIRED: "not_required",
  WITH_RECEIPTS: "with_receipts",
  WITH_NO_RECEIPTS: "with_no_receipts",
};

export const ACCOUNTING_STATUS_FILTER_VALUE = {
  PENDING: "pending",
  VERIFIED: "verified",
  SYNC_IN_PROGRESS: "sync_in_progress",
  SYNC_FAILED: "sync_failed",
  SYNCED: "synced",
};

export const FLAGGED_BY_FILTER_VALUE = {
  ADMIN: "admin",
  AUTO_FLAGGED: "auto_flagged",
};
export const CARD_FREQUENCY = {
  ONE_TIME: "non-recurring",
  MONTHLY: "recurring",
  WEEKLY: "recurring",
  DAILY: "recurring",
};
export const RECURRING_STATUS_FILTER_VALUE = {
  RECURRING: "recurring",
  NON_RECURRING: "non_recurring",
};
export const PAYMENT_APPROVE_STATUS_FILTER_VALUE = {
  APPROVED: "approved",
  PENDING_APPROVAL: "pending_approval",
  DENIED: "denied",
  PAID: "paid",
};

export const REPORT_OR_CLAIM_APPROVE_STATUS_FILTER_VALUE = {
  PENDING_APPROVAL: "pending_approval",
  DENIED: "denied",
  APPROVED: "approved",
  PAID: "paid",
};

export const PAYMENT_STATUS_FITLER_VALUE = {
  PAID: "paid",
  PAID_OUTSIDE_VOLOPAY: "paid_outside_volopay",
  FAILED: "failed",
  REJECTED: "not_approved",
  DENIED: "denied",
};
export const BANK_DETAILS_STATUS_FILTER_VALUE = {
  WITH_BANK_DETAILS: "with_bank_details",
  WITHOUT_BANK_DETAILS: "without_bank_details",
};
export const CARD_STATE = {
  ACTIVE: "active",
  FROZEN: "freezed",
};
export const CLAIM_TYPE = {
  OUT_OF_POCKET: "out_of_pocket",
  MILEAGE: "mileage",
};
export const DATE_RANGE_FILTER_MODE = {
  normal: "normal",
  dropDown: "dropDown",
};
export const LIST_FILTER_MODE = {
  withCheckbox: "withCheckbox",
  withoutCheckbox: "withoutCheckbox",
};
export const USER_STATE = {
  ACTIVE: "active",
  FREEZED: "freezed",
  PENDING: "pending",
};

export const FILTER_TYPES = {
  searchAndFilter: "searchAndFilter",
  amount: "amount",
  dateRange: "dateRange",
  datePeriods: "datePeriods",
  list: "list",
  button: "button",
  utilization: "utilization",
};

export const DATE_RANGE_PARAMS = {
  toDate: TO,
  fromDate: FROM,
};
export const AMOUNT_SEARCH_PARAMS = {
  minAmount: MIN_AMOUNT,
  maxAmount: MAX_AMOUNT,
};
export const UTILIZATION_SEARCH_PARAMS = {
  minUtilization: MIN_UTILIZATION,
  maxUtilization: MAX_UTILIZATION,
};
export const INVOICE_STATUS_FILTER_VALUE = {
  WITH_INVOICE: "with_invoice",
  WITH_NO_INVOICE: "with_no_invoice",
  AUTO_CAPTURE: "auto_capture",
};
export const TAX_STATUS_FILTER_VALUE = {
  WITH_GST: "with_gst",
  WITH_NO_GST: "with_no_gst",
  WITH_TDS: "with_tds",
  WITH_NO_TDS: "with_no_tds",
};
export const WALLET_FREQUENCY = {
  ONE_TIME: "non_recurring",
  MONTHLY: "recurring",
  WEEKLY: "recurring",
  DAILY: "recurring",
};
export const WALLET_STATE = {
  ACTIVE: "active",
  FROZEN: "freezed",
};
export const OTHER_TYPE_FILTER_VALUE = {
  CREDIT: "credit",
  EXTERNAL_CREDIT: "external_credit",

  DEBIT: "debit",
  EXTERNAL_DEBIT: "external_debit",
  INVOICE_DEBIT: "invoice_debit",

  ADJUSTMENT: "adjustment",

  CASHBACK: "cashback",
  INVOICE_CASHBACK: "invoice_cashback",

  CORPORATE_TRAVEL: "corporare_travel",

  CREDIT_PAYMENT: "credit_payment",
  CREDIT_FEE_PAYMENT: "credit_fee_payment",
  CREDIT_FEE: "credit_fee",

  TRANSFER_FEES: "transfer_fees",
  TRANSFER_FEES_REVERSAL: "transfer_fees_reversal",
};
export const DECLINE_REASON_FILTER_VALUES = {
  AUTO_BLOCKED_CARD: "auto_blocked_card",
  BLOCKED_CARD: "blocked_card",
  FROZEN_CARD: "frozen_card",
  MERCHANT_RESTRICTION: "merchant_restriction",
  CATEGORY_RESTRICTION: "category_restriction",
  POLICY_RESTRICTION: "policy_restriction",
  MERCHANT_NOT_SUPPORTED: "merchant_not_supported",
  INSUFFICIENT_CARD_BALANCE: "insufficient_card_balance",
  INSUFFICIENT_ACCOUNT_BALANCE: "insufficient_account_balance",
  INSUFFICIENT_CREDIT_BALANCE: "insufficient_credit_balance",
  TRANSACTION_AMOUNT_LIMIT: "transaction_amount_limit",
  INSUFFICIENT_PROJECT_LIMIT: "insufficient_project_limit",
  INSUFFICIENT_DEPARTMENT_LIMIT: "insufficient_department_limit",
  UNVERIFIED: "unverified",
  INTERNAL_ERROR: "internal_error",
  PROVIDER_ERROR: "provider_error",
  INVALID_CARD_DETAILS: "bad_cvv2_or_expiry",
  INSUFFICIENT_BUDGET_BALANCE: "low_budget_balance",
  INVALID_PIN: "invalid_pin",
};

export const PAYMENT_STATUS_FILTER_VALUE = {
  PAID: "paid",
  FAILED: "failed",
  PROCESSING: "processing",
  SCHEDULED: "scheduled",
  UNSCHEDULED: "unscheduled",
  AWAITING_FUNDS: "awaiting_funds",
  OUTSIDE_VOLOPAY: "outside_volopay",
};

export const TYPE_OF_INPUT = { minAmount: MIN_AMOUNT, maxAmount: MAX_AMOUNT };

export const AVAILABLE_FILTER_KEYS = {
  searchAndFilter: "searchAndFilter",
  dateRange: "dateRange",
  datePeriods: "datePeriods",
  cardRequestType: "cardRequestType",
  amount: "amount",
  submissionPolicyStatus: "submissionPolicyStatus",
  transactionStatus: "reviewStatus",
  receiptStatus: "receiptStatus",
  receiptStatusFilter: "receiptStatusFilter",
  accountingStatus: "accountingStatus",
  department: "department",
  recurringStatus: "recurringStatus",
  cardType: "cardType",
  settlementStatus: "settlementStatus",
  flaggedBy: "flaggedBy",
  vendorOwner: "vendorOwner",
  claimStatus: "claimStatus",
  reportStatus: "reportStatus",
  accountingClaimStatus: "accountingClaimStatus",
  claimType: "claimType",
  invoiceStatus: "invoiceStatus",
  taxation: "taxation",
  transactionType: "transactionType",
  ledgerType: "ledgerType",
  creditLedgerType: "creditLedgerType",
  payrollLedgerType: "payrollLedgerType",
  declineReason: "declineCode",
  spends: "spends",
  budgetedFor: "budgetedFor",
  haveDefaultPolicy: "haveDefaultPolicy",
  budgetType: "budgetFlexible",
  limit: "limit",
  budgetTypeDateRange: "budgeTypeDateRange",
  cardLimit: "cardLimit",
  cardState: "status",
  utilization: "utilization",
  walletLimit: "walletAmount",
  walletFrequency: "walletFrequency",
  walletState: "walletState",
  project: "project",
  cardFrequency: "frequency",
  location: "location",
  userState: "userState",
  status: "status",
  billStatus: "billStatus",
  payrollStatus: "payrollStatus",
  payrollPaymentStatus: "payrollPaymentStatus",
  billPaymentStatus: "billPaymentStatus",
  vendorBankDetailsStatus: "vendorBankDetailsStatus",
  employeeBankDetailsStatus: "employeeBankDetailsStatus",
  person: "person",
  cardModuleRequestType: "type",
  cardModuleCardType: "cardModuleCardType",
  activityType: "activityType",
  copilots: "copilots",
  paymentStatus: "paymentStatus",
  reimbursementPaymentDate: "reimbursementPaymentDate",
  reimbursementSubmissionDate: "reimbursementSubmissionDate",
  paymentHistoryStatus: "paymentHistoryStatus",
  settledAmount: "settledAmount",
  unsettledAmount: "unsettledAmount",
  analyticsLimit: "analyticsLimit",
  entity: "entity",
  analyticsExportDuration: "analyticsExportDuration",
  cardUsageType: "international_card",
};

export const SEARCH_PARAMS_AVAILABLE_FILTER_KEYS = [
  ...Object.values(AVAILABLE_FILTER_KEYS),
  ...Object.values(DATE_RANGE_PARAMS),
  ...Object.values(AMOUNT_SEARCH_PARAMS),
  ...Object.values(UTILIZATION_SEARCH_PARAMS),
];

export const AVAILABLE_FILTERS = {
  [AVAILABLE_FILTER_KEYS.cardUsageType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.cardUsageType,
      icon: "CreditCardGear",
      label: "filters.cardUsageType.label",
      options: [
        {
          label: "filters.cardUsageType.options.domesticCards",
          value: "false",
        },
        {
          label: "filters.cardUsageType.options.internationalCards",
          value: "true",
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.analyticsExportDuration]: {
    type: FILTER_TYPES.datePeriods,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.datePeriods,
      options: [
        {
          label: "filter.dateRange.currentMonth",
          value: DATE_RANGE_KEYS.currentMonth,
        },
        {
          label: "filter.dateRange.lastMonth",
          value: DATE_RANGE_KEYS.lastMonth,
        },
        {
          label: "filter.dateRange.last3Month",
          value: DATE_RANGE_KEYS.last3Month,
        },
        {
          label: "filter.dateRange.last6Month",
          value: DATE_RANGE_KEYS.last6Month,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.cardRequestType]: {
    type: FILTER_TYPES.list,
    props: {
      label: "filters.requestType.label",
      filterKey: AVAILABLE_FILTER_KEYS.cardRequestType,
      icon: "FilterList",
      showLabel: true,
      options: [
        {
          label: "filters.requestType.options.newCard",
          value: [
            CARD_APPROVAL_REQUEST_TYPE.PHYSICAL,
            CARD_APPROVAL_REQUEST_TYPE.VIRTUAL,
          ],
        },
        {
          label: "filters.requestType.options.cardLimit",
          value: [CARD_APPROVAL_REQUEST_TYPE.TOP_UP],
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.cardModuleRequestType]: {
    type: FILTER_TYPES.list,
    props: {
      label: "filters.requestType.label",
      filterKey: AVAILABLE_FILTER_KEYS.cardModuleRequestType,
      icon: "FilterList",
      showLabel: true,
      options: [
        {
          label: "filters.requestType.options.newCard",
          value: [
            CARD_APPROVAL_REQUEST_TYPE.PHYSICAL,
            CARD_APPROVAL_REQUEST_TYPE.VIRTUAL,
          ],
        },
        {
          label: "filters.requestType.options.cardLimit",
          value: CARD_APPROVAL_REQUEST_TYPE.TOP_UP,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.cardModuleCardType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: camelToSnake(AVAILABLE_FILTER_KEYS.cardRequestType),
      icon: "Card",
      label: "filters.cardType.label",
      options: [
        {
          label: "filters.cardType.options.physical",
          value: CARD_TYPE.PHYSICAL,
        },
        {
          label: "filters.cardType.options.virtual",
          value: CARD_TYPE.VIRTUAL,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.dateRange]: {
    type: FILTER_TYPES.dateRange,
  },
  [AVAILABLE_FILTER_KEYS.searchAndFilter]: {
    type: FILTER_TYPES.searchAndFilter,
    props: {
      query: "",
    },
  },
  [AVAILABLE_FILTER_KEYS.cardType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.cardType,
      icon: "Card",
      label: "filters.cardType.label",
      options: [
        {
          label: "filters.cardType.options.physical",
          value: "with_physical_card",
        },
        {
          label: "filters.cardType.options.virtual",
          value: "with_virtual_card",
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.datePeriods]: {
    type: FILTER_TYPES.datePeriods,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.datePeriods,
      options: [
        {
          label: "filters.today",
          value: DATE_RANGE_KEYS.today,
        },
        {
          label: "filters.yesterday",
          value: DATE_RANGE_KEYS.yesterday,
        },
        {
          label: "filters.thisMonth",
          value: DATE_RANGE_KEYS.currentMonth,
        },
        {
          label: "filters.lastMonth",
          value: DATE_RANGE_KEYS.lastMonth,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.amount]: {
    type: FILTER_TYPES.amount,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.amount,
      label: "filters.amount", // Amount
      icon: "Dollar",
      isLabelVisible: true,
    },
  },

  [AVAILABLE_FILTER_KEYS.unsettledAmount]: {
    type: FILTER_TYPES.amount,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.unsettledAmount,
      label: "filters.amount", // Amount
      icon: "Dollar",
      isLabelVisible: true,
    },
  },

  [AVAILABLE_FILTER_KEYS.settledAmount]: {
    type: FILTER_TYPES.amount,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.settledAmount,
      label: "filters.amount", // Amount
      icon: "Dollar",
      isLabelVisible: true,
    },
  },

  [AVAILABLE_FILTER_KEYS.submissionPolicyStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
      label: "filters.submissionPolicyStatus.title",
      icon: "SubmissionPolicy",
      options: [
        {
          label: "filters.submissionPolicyStatus.submissionPolicyComplete",
          value: SUBMISSION_POLICY_FILTER_VALUE.MET,
        }, // Submission policy complete
        {
          label: "filters.submissionPolicyStatus.submissionPolicyIncomplete",
          value: SUBMISSION_POLICY_FILTER_VALUE.NOT_MET,
        }, // Submission policy incomplete
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.transactionStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.transactionStatus,
      label: "filters.reviewStatus",
      icon: "SwapHorizontal",
      options: [
        {
          label: "filters.transactionStatus.needsReview", // Needs review
          value: [
            TRANSACTION_STATUS_FILTER_VALUE.PENDING,
            TRANSACTION_STATUS_FILTER_VALUE.INCOMPLETED,
          ],
        },
        {
          label: "filters.transactionStatus.approved",
          value: [TRANSACTION_STATUS_FILTER_VALUE.OKAY],
        }, // Approved
        {
          label: "filters.transactionStatus.flagged",
          value: [TRANSACTION_STATUS_FILTER_VALUE.NOT_OKAY],
        }, // Flagged by admin/manager
        {
          label: "filters.transactionStatus.autoApproved",
          value: [TRANSACTION_STATUS_FILTER_VALUE.AUTO_APPROVED],
        }, // Auto approved
        {
          label: "filters.transactionStatus.autoFlagged",
          value: [TRANSACTION_STATUS_FILTER_VALUE.AUTO_FLAGGED],
        }, // Auto flagged
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.receiptStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.receiptStatus,
      label: "filters.receiptStatus.title",
      icon: "Receipt",
      options: [
        {
          label: "filters.receiptStatus.missingReceipts",
          value: RECEIPT_STATUS_FILTER_VALUE.MISSING,
        },
        {
          label: "filters.receiptStatus.notVerified", // Submitted, not verified
          value: RECEIPT_STATUS_FILTER_VALUE.SUBMITTED,
        },
        {
          label: "filters.receiptStatus.autoVerified", // Submitted, auto verified
          value: RECEIPT_STATUS_FILTER_VALUE.VERIFIED,
        },
        {
          label: "filters.receiptStatus.notRequired", // Receipt not required
          value: RECEIPT_STATUS_FILTER_VALUE.NOT_REQUIRED,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.receiptStatusFilter]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.receiptStatusFilter,
      label: "filters.receiptStatus.title",
      icon: "Receipt",
      options: [
        {
          label: "With receipts",
          value: RECEIPT_STATUS_FILTER_VALUE.WITH_RECEIPTS,
        },
        {
          label: "With no receipts",
          value: RECEIPT_STATUS_FILTER_VALUE.WITH_NO_RECEIPTS,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.accountingStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.accountingStatus,
      label: "filters.accountingStatus.title",
      icon: "BookOpen",
      options: [
        {
          label: "filters.accountingStatus.pendingVerify",
          value: [ACCOUNTING_STATUS_FILTER_VALUE.PENDING],
        }, // Pending to verify
        {
          label: "filters.accountingStatus.pendingSync",
          value: [
            ACCOUNTING_STATUS_FILTER_VALUE.VERIFIED,
            ACCOUNTING_STATUS_FILTER_VALUE.SYNC_IN_PROGRESS,
          ],
        }, // Pending to sync
        {
          label: "filters.accountingStatus.synced",
          value: [ACCOUNTING_STATUS_FILTER_VALUE.SYNCED],
        }, // Synced
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.department]: {
    type: FILTER_TYPES.button,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.department,
      label: "filters.department",
      icon: "SupervisorAccount",
      value: "Department",
    },
  },
  [AVAILABLE_FILTER_KEYS.person]: {
    type: FILTER_TYPES.button,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.person,
      label: "person",
      icon: "SupervisorAccount",
      value: "Department",
    },
  },
  [AVAILABLE_FILTER_KEYS.recurringStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.recurringStatus,
      label: "Recurring status",
      icon: "Recurring",
      options: [
        {
          label: "Recurring",
          key: "recurring",
          value: "recurring",
        },
        {
          label: "Non recurring",
          key: "non_recurring",
          value: "non_recurring",
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.billStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.billStatus,
      icon: "Send",
      label: "filters.billStatus.title",
      options: [
        {
          label: "filters.claimStatus.options.pendingApproval",
          value: PAYMENT_APPROVE_STATUS_FILTER_VALUE.PENDING_APPROVAL,
        },
        {
          label: "filters.claimStatus.options.rejected",
          value: PAYMENT_APPROVE_STATUS_FILTER_VALUE.DENIED,
        },
        {
          label: "filters.claimStatus.options.approved",
          value: PAYMENT_APPROVE_STATUS_FILTER_VALUE.APPROVED,
        },
        {
          label: "filters.claimStatus.options.paid",
          value: PAYMENT_APPROVE_STATUS_FILTER_VALUE.PAID,
        },
      ],
    },
  },

  [AVAILABLE_FILTER_KEYS.claimStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.claimStatus,
      icon: "Send",
      label: "filters.claimStatus.title",
      options: [
        {
          label: "filters.claimStatus.options.pendingApproval",
          value: REPORT_OR_CLAIM_APPROVE_STATUS_FILTER_VALUE.PENDING_APPROVAL,
        },
        {
          label: "filters.claimStatus.options.rejected",
          value: REPORT_OR_CLAIM_APPROVE_STATUS_FILTER_VALUE.DENIED,
        },
        {
          label: "filters.claimStatus.options.approved",
          value: REPORT_OR_CLAIM_APPROVE_STATUS_FILTER_VALUE.APPROVED,
        },
        {
          label: "filters.claimStatus.options.paid",
          value: REPORT_OR_CLAIM_APPROVE_STATUS_FILTER_VALUE.PAID,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.accountingClaimStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.accountingClaimStatus,
      icon: "Send",
      label: "Claim status",
      options: [
        {
          label: "filters.claimStatus.options.approved",
          value: REPORT_OR_CLAIM_APPROVE_STATUS_FILTER_VALUE.APPROVED,
        },
        {
          label: "filters.claimStatus.options.paid",
          value: REPORT_OR_CLAIM_APPROVE_STATUS_FILTER_VALUE.PAID,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.reportStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.reportStatus,
      icon: "Send",
      label: "Report status",
      options: [
        {
          label: "Pending approval",
          value: REPORT_OR_CLAIM_APPROVE_STATUS_FILTER_VALUE.PENDING_APPROVAL,
        },
        {
          label: "Rejected",
          value: REPORT_OR_CLAIM_APPROVE_STATUS_FILTER_VALUE.DENIED,
        },
      ],
    },
  },

  [AVAILABLE_FILTER_KEYS.paymentStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.paymentStatus,
      icon: "Send",
      label: "Payment status",
      options: [
        {
          label: "Paid",
          value: PAYMENT_STATUS_FITLER_VALUE.PAID,
        },
        {
          label: "Failed",
          value: PAYMENT_STATUS_FITLER_VALUE.FAILED,
        },
        {
          label: "Paid outside volopay",
          value: PAYMENT_STATUS_FITLER_VALUE.PAID_OUTSIDE_VOLOPAY,
        },
      ],
    },
  },

  [AVAILABLE_FILTER_KEYS.paymentHistoryStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.paymentHistoryStatus,
      icon: "Send",
      label: "Payment status",
      options: [
        {
          label: "Paid",
          value: PAYMENT_STATUS_FITLER_VALUE.PAID,
        },
        {
          label: "Failed",
          value: PAYMENT_STATUS_FITLER_VALUE.FAILED,
        },
        {
          label: "Paid outside volopay",
          value: PAYMENT_STATUS_FITLER_VALUE.PAID_OUTSIDE_VOLOPAY,
        },
        {
          label: "Rejected",
          value: PAYMENT_STATUS_FITLER_VALUE.DENIED,
        },
      ],
    },
  },

  [AVAILABLE_FILTER_KEYS.payrollStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.payrollStatus,
      icon: "Send",
      label: "Payroll status",
      options: [
        {
          label: "Pending approval",
          value: PAYMENT_APPROVE_STATUS_FILTER_VALUE.PENDING_APPROVAL,
        },
        {
          label: "Rejected",
          value: PAYMENT_APPROVE_STATUS_FILTER_VALUE.DENIED,
        },
      ],
    },
  },

  [AVAILABLE_FILTER_KEYS.vendorBankDetailsStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.vendorBankDetailsStatus,
      icon: "AccountBalance",
      label: "Bank details status",
      options: [
        {
          label: "Vendor with bank details",
          key: "with_bank_details",
          value: BANK_DETAILS_STATUS_FILTER_VALUE.WITH_BANK_DETAILS,
        },
        {
          label: "Vendor without bank details",
          key: "without_bank_details",
          value: BANK_DETAILS_STATUS_FILTER_VALUE.WITHOUT_BANK_DETAILS,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.employeeBankDetailsStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.employeeBankDetailsStatus,
      icon: "AccountBalance",
      label: "Bank details status",
      options: [
        {
          label: "Employee with bank details",
          key: "with_bank_details",
          value: BANK_DETAILS_STATUS_FILTER_VALUE.WITH_BANK_DETAILS,
        },
        {
          label: "Employee without bank details",
          key: "without_bank_details",
          value: BANK_DETAILS_STATUS_FILTER_VALUE.WITHOUT_BANK_DETAILS,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.payrollPaymentStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.payrollPaymentStatus,
      icon: "Payments",
      iconClasses: "w-2 h-2",
      label: "Payroll status",
      options: [
        {
          label: "Processing",
          value: PAYMENT_STATUS_FILTER_VALUE.PROCESSING,
        },
        {
          label: "Scheduled",
          value: PAYMENT_STATUS_FILTER_VALUE.SCHEDULED,
        },
        {
          label: "Unscheduled",
          value: PAYMENT_STATUS_FILTER_VALUE.UNSCHEDULED,
        },
        {
          label: "Awaiting funds",
          value: PAYMENT_STATUS_FILTER_VALUE.AWAITING_FUNDS,
        },
        {
          label: "Outside Volopay",
          value: PAYMENT_STATUS_FILTER_VALUE.OUTSIDE_VOLOPAY,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.billPaymentStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.billPaymentStatus,
      icon: "Send",
      label: "Bill status",
      options: [
        {
          label: "Processing",
          value: PAYMENT_STATUS_FILTER_VALUE.PROCESSING,
        },
        {
          label: "Scheduled",
          value: PAYMENT_STATUS_FILTER_VALUE.SCHEDULED,
        },
        {
          label: "Unscheduled",
          value: PAYMENT_STATUS_FILTER_VALUE.UNSCHEDULED,
        },
        {
          label: "Awaiting funds",
          value: PAYMENT_STATUS_FILTER_VALUE.AWAITING_FUNDS,
        },
        {
          label: "Outside Volopay",
          value: PAYMENT_STATUS_FILTER_VALUE.OUTSIDE_VOLOPAY,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.cla]: {},
  [AVAILABLE_FILTER_KEYS.project]: {
    type: FILTER_TYPES.button,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.project,
      label: "filters.project",
      icon: "SupervisorAccount", // This doesnot exist Will have to replace with design icon
    },
  },
  [AVAILABLE_FILTER_KEYS.settlementStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.settlementStatus,
      label: "Settlement status",
      options: [
        {
          label: "filters.settlementStatus.settled",
          value: SETTLEMENT_STATUS.SETTLED,
        },
        {
          label: "filters.settlementStatus.unsettled",
          value: SETTLEMENT_STATUS.UNSETTLED,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.flaggedBy]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.flaggedBy,
      label: "filters.flaggedBy.title",
      icon: "Flag",
      options: [
        {
          label: "filters.flaggedBy.admin",
          value: FLAGGED_BY_FILTER_VALUE.ADMIN,
        }, // Pending to verify
        {
          label: "filters.flaggedBy.autoFlagged",
          value: FLAGGED_BY_FILTER_VALUE.AUTO_FLAGGED,
        }, // Pending to sync
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.vendorOwner]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.vendorOwner,
      label: "filters.vendorOwner.title",
      icon: "Person",
      options: [],
    },
  },
  [AVAILABLE_FILTER_KEYS.claimType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.claimType,
      label: "filters.claimType.title",
      icon: "Paid",
      showLabel: true,
      options: [
        {
          label: "filters.claimType.mileage",
          value: REIMBURSEMENT_TYPE_KEY.mileage,
        },
        {
          label: "filters.claimType.outOfPocket",
          value: REIMBURSEMENT_TYPE_KEY.out_of_pocket,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.invoiceStatus]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.invoiceStatus,
      label: "filters.invoiceStatus.title",
      icon: "Receipt",
      options: [
        {
          label: "With invoice",
          value: INVOICE_STATUS_FILTER_VALUE.WITH_INVOICE,
        },
        {
          label: "Auto captured by integration",
          value: INVOICE_STATUS_FILTER_VALUE.AUTO_CAPTURE,
        },
        {
          label: "Without invoice",
          value: INVOICE_STATUS_FILTER_VALUE.WITH_NO_INVOICE,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.taxation]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.taxation,
      label: "filters.taxation.title",
      icon: "Percentage",
      options: [
        { label: "With GST", value: TAX_STATUS_FILTER_VALUE.WITH_GST },
        { label: "Without GST", value: TAX_STATUS_FILTER_VALUE.WITH_NO_GST },
        { label: "With TDS", value: TAX_STATUS_FILTER_VALUE.WITH_TDS },
        { label: "Without TDS", value: TAX_STATUS_FILTER_VALUE.WITH_NO_TDS },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.transactionType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.transactionType,
      label: "filters.otherType.title",
      icon: "Type",
      options: [
        {
          label: "filters.otherType.credit",
          value: [
            OTHER_TYPE_FILTER_VALUE.CREDIT,
            OTHER_TYPE_FILTER_VALUE.EXTERNAL_CREDIT,
          ],
        },
        {
          label: "filters.otherType.debit",
          value: [
            OTHER_TYPE_FILTER_VALUE.DEBIT,
            OTHER_TYPE_FILTER_VALUE.EXTERNAL_DEBIT,
            OTHER_TYPE_FILTER_VALUE.INVOICE_DEBIT,
          ],
        },
        {
          label: "filters.otherType.invoicePayment",
          value: [OTHER_TYPE_FILTER_VALUE.INVOICE_DEBIT],
        },

        {
          label: "filters.otherType.adjustment",
          value: [OTHER_TYPE_FILTER_VALUE.ADJUSTMENT],
        },
        {
          label: "filters.otherType.cashback",
          value: [
            OTHER_TYPE_FILTER_VALUE.CASHBACK,
            OTHER_TYPE_FILTER_VALUE.INVOICE_CASHBACK,
          ],
        },
        {
          label: "filters.otherType.travel",
          value: [OTHER_TYPE_FILTER_VALUE.CORPORATE_TRAVEL],
        },
        {
          label: "filters.otherType.creditBillingPayment",
          value: [
            OTHER_TYPE_FILTER_VALUE.CREDIT_PAYMENT,
            OTHER_TYPE_FILTER_VALUE.CREDIT_FEE_PAYMENT,
            OTHER_TYPE_FILTER_VALUE.CREDIT_FEE,
          ],
        },

        {
          label: "filters.otherType.transferFees",
          value: [
            OTHER_TYPE_FILTER_VALUE.TRANSFER_FEES,
            OTHER_TYPE_FILTER_VALUE.TRANSFER_FEES_REVERSAL,
          ],
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.ledgerType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.ledgerType,
      label: "filters.ledgerType.title",
      icon: "PriceChange",
      options: [
        {
          label: "filters.ledgerType.expenses",
          value: "expense",
          modules: [MODULES.CARDS],
        },
        {
          label: "filters.ledgerType.payments",
          value: "payment",
          modules: [MODULES.BILL_PAY, MODULES.REIMBURSEMENTS],
        },
        {
          label: "filters.ledgerType.topups",
          value: "credit_prefunding",
          modules: [MODULES.BILL_PAY],
        },
        {
          label: "filters.ledgerType.invoicePayment",
          value: "invoice_payment",
          modules: [MODULES.BILL_PAY],
        },
        {
          label: "filters.ledgerType.payrollFundAllocation",
          value: "payroll_fund_allocation",
          modules: [MODULES.PAYROLL],
        },
        {
          label: "filters.ledgerType.creditRepayments",
          value: "credit_bill_payment",
          modules: [MODULES.CARDS],
        },
        {
          label: "filters.ledgerType.adjustments",
          value: "adjustment",
          modules: [MODULES.CARDS],
        },
        {
          label: "filters.ledgerType.cashbacks",
          value: "cashback",
          modules: [MODULES.CARDS],
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.creditLedgerType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.creditLedgerType,
      label: "filters.ledgerType.title",
      icon: "PriceChange",
      options: [
        { label: "filters.ledgerType.expenses", value: "expense" },
        {
          label: "filters.ledgerType.creditRepayments",
          value: ["credit_bill_payment", "credit_bill_payment_from_admin"],
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.payrollLedgerType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.payrollLedgerType,
      label: "filters.ledgerType.title",
      icon: "PriceChange",
      options: [
        { label: "filters.ledgerType.payments", value: "payment" },
        {
          label: "filters.ledgerType.payrollFundAllocation",
          value: "payroll_fund_allocation",
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.declineReason]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.declineReason,
      label: "filters.declineReason.title",
      icon: "DoNotDisturb",
      options: [
        {
          label: "filters.declineReason.insufficientBudgetBalance",
          value: DECLINE_REASON_FILTER_VALUES.INSUFFICIENT_BUDGET_BALANCE,
        },
        {
          label: "filters.declineReason.merchantRestriction",
          value: DECLINE_REASON_FILTER_VALUES.MERCHANT_RESTRICTION,
        },
        {
          label: "filters.declineReason.insufficientCardBalance",
          value: DECLINE_REASON_FILTER_VALUES.INSUFFICIENT_CARD_BALANCE,
        },
        {
          label: "filters.declineReason.invalidCardDetails",
          value: DECLINE_REASON_FILTER_VALUES.INVALID_CARD_DETAILS,
        },
        {
          label: "filters.declineReason.insufficientAccountBalance",
          value: DECLINE_REASON_FILTER_VALUES.INSUFFICIENT_ACCOUNT_BALANCE,
        },
        {
          label: "filters.declineReason.transactionAmountLimit",
          value: DECLINE_REASON_FILTER_VALUES.TRANSACTION_AMOUNT_LIMIT,
        },
        {
          label: "filters.declineReason.internalError",
          value: DECLINE_REASON_FILTER_VALUES.INTERNAL_ERROR,
        },
        {
          label: "filters.declineReason.providerError",
          value: DECLINE_REASON_FILTER_VALUES.PROVIDER_ERROR,
        },
        {
          label: "filters.declineReason.invalidPin",
          value: DECLINE_REASON_FILTER_VALUES.INVALID_PIN,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.spends]: {
    type: FILTER_TYPES.amount,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.spends,
      label: "filters.spends.title",
      icon: "Dollar",
    },
  },
  [AVAILABLE_FILTER_KEYS.budgetedFor]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.budgetedFor,
      label: "filters.budgetedFor.title",
      icon: "PieChart",
      showLabel: true,
      options: [
        {
          label: "filters.budgetedFor.cardSpends",
          value: BUDGETED_FOR_FILTER_VALUE.cardSpends,
          module: MODULES.CARDS,
        },
        {
          label: "filters.budgetedFor.billPaySpends",
          value: BUDGETED_FOR_FILTER_VALUE.billpaySpends,
          module: MODULES.BILL_PAY,
        },
        {
          label: "filters.budgetedFor.reimbursementSpends",
          value: BUDGETED_FOR_FILTER_VALUE.reimbursementSpends,
          module: MODULES.REIMBURSEMENTS,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.haveDefaultPolicy]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.haveDefaultPolicy,
      label: "filters.policy.title",
      icon: "Article",
      showLabel: true,
      options: [
        {
          label: "filters.policy.defaultPolicy",
          value: "true",
        },
        {
          label: "filters.policy.customPolicy",
          value: "false",
        },
      ],
    },
  },

  [AVAILABLE_FILTER_KEYS.analyticsLimit]: {
    type: FILTER_TYPES.amount,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.analyticsLimit,
      label: "filters.amount", // Amount
      icon: "Dollar",
      isLabelVisible: false,
    },
  },

  [AVAILABLE_FILTER_KEYS.entity]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.entity,
      label: "filters.entity", // Amount
      icon: "Entity",
      showLabel: true,
      options: [],
    },
  },
  [AVAILABLE_FILTER_KEYS.cardLimit]: {
    type: FILTER_TYPES.amount,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.cardLimit,
      label: "filters.cardLimit", // Amount
      icon: "AddCard",
      isLabelVisible: false,
    },
  },
  [AVAILABLE_FILTER_KEYS.cardFrequency]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.cardFrequency,
      label: "filters.cardFrequency.title",
      icon: "Frequency",
      options: [
        {
          label: "filters.cardFrequency.oneTime",
          value: "one_time",
        },
        {
          label: "filters.cardFrequency.monthly",
          value: "monthly",
        },
        {
          label: "filters.cardFrequency.weekly",
          value: "weekly",
        },
        {
          label: "filters.cardFrequency.daily",
          value: "daily",
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.budgetType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.budgetType,
      label: "filters.budgetType.title",
      icon: "FormatLetterSpacing",
      showLabel: true,
      options: [
        {
          label: "filters.budgetType.flexible",
          value: BUDGET_TYPE_FILTER_VALUES.FLEXIBLE,
        },
        {
          label: "filters.budgetType.nonFlexible",
          value: BUDGET_TYPE_FILTER_VALUES.NON_FLEXIBLE,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.cardState]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.cardState,
      label: "filters.cardState.title",
      icon: "CardState",
      options: [
        {
          label: "filters.cardState.active",
          value: [CARD_STATE.ACTIVE],
        },
        {
          label: "filters.cardState.frozen",
          value: [CARD_STATE.FROZEN],
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.limit]: {
    type: FILTER_TYPES.amount,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.amount,
      label: "filters.limit.title", // Amount
      icon: "Dollar",
    },
  },
  [AVAILABLE_FILTER_KEYS.budgetTypeDateRange]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.budgetTypeDateRange,
      icon: "Vector",
      label: "Frequency",
      showLabel: true,
      options: [
        {
          label: "filters.cardFrequency.oneTime",
          subLabel: "filters.frequency.oneTimeSubLabel",
          value: "one_time",
        },
        {
          label: "filters.cardFrequency.monthly",
          subLabel: "filters.frequency.monthlySubLabel",
          value: "monthly",
        },
        {
          label: "filters.frequency.quarterly",
          subLabel: "filters.frequency.quarterlySubLabel",
          value: "quarterly",
        },
        {
          label: "filters.frequency.halfYearly",
          subLabel: "filters.frequency.halfYearlySubLabel",
          value: "half_yearly",
        },
        {
          label: "filters.frequency.yearly",
          subLabel: "filters.frequency.yearlySubLabel",
          value: "yearly",
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.utilization]: {
    type: FILTER_TYPES.utilization,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.utilization,
      label: "filters.utilization.title",
      icon: "DonutLarge",
    },
  },
  [AVAILABLE_FILTER_KEYS.walletLimit]: {
    type: FILTER_TYPES.amount,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.walletLimit,
      label: "filters.walletLimit", // Amount
      icon: "WalletLimit",
      isLabelVisible: false,
    },
  },
  [AVAILABLE_FILTER_KEYS.walletFrequency]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.walletFrequency,
      label: "filters.walletFrequency.title",
      icon: "Frequency",
      options: [
        {
          label: "filters.walletFrequency.oneTime",
          value: WALLET_FREQUENCY.ONE_TIME,
        },
        {
          label: "filters.walletFrequency.monthly",
          value: WALLET_FREQUENCY.MONTHLY,
        },
        {
          label: "filters.walletFrequency.weekly",
          value: WALLET_FREQUENCY.WEEKLY,
        },
        {
          label: "filters.walletFrequency.daily",
          value: WALLET_FREQUENCY.DAILY,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.walletState]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.walletState,
      label: "filters.walletState.title",
      icon: "WalletState",
      options: [
        {
          label: "filters.walletState.active",
          value: WALLET_STATE.ACTIVE,
        },
        {
          label: "filters.walletState.frozen",
          value: WALLET_STATE.FROZEN,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.claimType]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.claimType,
      label: "filters.claimType.title",
      showLabel: true,
      icon: "Reimbursement",
      options: [
        {
          label: "filters.claimType.outOfPocket",
          value: CLAIM_TYPE.OUT_OF_POCKET,
        },
        {
          label: "filters.claimType.mileage",
          value: CLAIM_TYPE.MILEAGE,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.userState]: {
    type: FILTER_TYPES.list,
    props: {
      filterKey: AVAILABLE_FILTER_KEYS.status,
      label: "filters.userState.title",
      icon: "PersonWithCircle",
      options: [
        {
          label: "filters.userState.active",
          value: [USER_STATE.ACTIVE],
        },
        {
          label: "filters.userState.freezed",
          value: [USER_STATE.FREEZED],
        },
        {
          label: "filters.userState.pending",
          value: [USER_STATE.PENDING],
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.activityType]: {
    type: FILTER_TYPES.list,
    props: {
      label: "settings.masterHistory.activityType.title",
      filterKey: AVAILABLE_FILTER_KEYS.activityType,
      icon: "TeamDashboard",
      showLabel: true,
      options: [
        {
          label: "settings.masterHistory.activityType.cards",
          value: MASTER_HISTORY_ACTIVITY_TYPES.CARDS,
        },
        {
          label: "settings.masterHistory.activityType.billPay",
          value: MASTER_HISTORY_ACTIVITY_TYPES.BILL_PAY,
        },
        {
          label: "settings.masterHistory.activityType.expense",
          value: MASTER_HISTORY_ACTIVITY_TYPES.EXPENSE,
        },
        {
          label: "settings.masterHistory.activityType.reimbursement",
          value: MASTER_HISTORY_ACTIVITY_TYPES.REIMBURSEMENT,
        },
        {
          label: "settings.masterHistory.activityType.payroll",
          value: MASTER_HISTORY_ACTIVITY_TYPES.PAYROLL,
        },
        {
          label: "settings.masterHistory.activityType.qrPay",
          value: MASTER_HISTORY_ACTIVITY_TYPES.QR_PAY,
        },
        {
          label: "settings.masterHistory.activityType.accounting",
          value: MASTER_HISTORY_ACTIVITY_TYPES.ACCOUNTING,
        },
        {
          label: "settings.masterHistory.activityType.settings",
          value: MASTER_HISTORY_ACTIVITY_TYPES.SETTINGS,
        },
        {
          label: "settings.masterHistory.activityType.departments",
          value: MASTER_HISTORY_ACTIVITY_TYPES.DEPARTMENTS,
        },
        {
          label: "settings.masterHistory.activityType.project",
          value: MASTER_HISTORY_ACTIVITY_TYPES.PROJECTS,
        },
        {
          label: "settings.masterHistory.activityType.people",
          value: MASTER_HISTORY_ACTIVITY_TYPES.PEOPLE,
        },
        {
          label: "settings.masterHistory.activityType.budget",
          value: MASTER_HISTORY_ACTIVITY_TYPES.BUDGETS,
        },
        {
          label: "settings.masterHistory.activityType.credit",
          value: MASTER_HISTORY_ACTIVITY_TYPES.CREDIT,
        },
        {
          label: "settings.masterHistory.activityType.travel",
          value: MASTER_HISTORY_ACTIVITY_TYPES.TRAVEL,
        },
        {
          label: "settings.masterHistory.activityType.rolesAndPermissions",
          value: MASTER_HISTORY_ACTIVITY_TYPES.ROLES_AND_PERMISSIONS,
        },
        {
          label: "settings.masterHistory.activityType.billing",
          value: MASTER_HISTORY_ACTIVITY_TYPES.BILLING,
        },
        {
          label: "settings.masterHistory.activityType.other",
          value: MASTER_HISTORY_ACTIVITY_TYPES.OTHER,
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.copilots]: {
    type: FILTER_TYPES.list,
    props: {
      label: "company.people.peopleMyProfileSlider.copilot",
      filterKey: AVAILABLE_FILTER_KEYS.copilots,
      icon: "Communication",
      options: [],
    },
  },
  [AVAILABLE_FILTER_KEYS.reimbursementPaymentDate]: {
    type: FILTER_TYPES.list,
    props: {
      filterTagStartWith:
        "reimbursement.payments.payOutsideVolopay.paymentDateLabel",
      label: "reimbursement.payments.payOutsideVolopay.paymentDateLabel",
      filterKey: AVAILABLE_FILTER_KEYS.reimbursementPaymentDate,
      icon: "Calendar",
      showLabel: true,
      options: [
        {
          label: "dashboard.quickAccess.accounting.filters.today",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.today, [0, 0])
          ),
          subLabel: convertRangeToText(getRange(DATE_RANGE_KEYS.today, [0, 0])),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last7Day",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last7Days, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last7Days, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last15Day",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last15Days, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last15Days, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last30Day",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last30Days, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last30Days, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.monthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.currentMonth, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.currentMonth, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.lastMonthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.lastMonth, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.lastMonth, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last3Monthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last3Month, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last3Month, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last6Monthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last6Month, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last6Month, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last12Monthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last12Month, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last12Month, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.yearly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.currentYear, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.currentYear, [0, 0])
          ),
        },
      ],
    },
  },
  [AVAILABLE_FILTER_KEYS.reimbursementSubmissionDate]: {
    type: FILTER_TYPES.list,
    props: {
      filterTagStartWith:
        "reimbursement.payments.payOutsideVolopay.submissionDateLabel",
      label: "reimbursement.payments.payOutsideVolopay.submissionDateLabel",
      filterKey: AVAILABLE_FILTER_KEYS.reimbursementSubmissionDate,
      icon: "Calendar",
      showLabel: true,
      options: [
        {
          label: "dashboard.quickAccess.accounting.filters.today",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.today, [0, 0])
          ),
          subLabel: convertRangeToText(getRange(DATE_RANGE_KEYS.today, [0, 0])),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last7Day",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last7Days, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last7Days, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last15Day",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last15Days, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last15Days, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last30Day",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last30Days, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last30Days, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.monthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.currentMonth, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.currentMonth, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.lastMonthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.lastMonth, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.lastMonth, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last3Monthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last3Month, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last3Month, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last6Monthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last6Month, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last6Month, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.last12Monthly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.last12Month, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.last12Month, [0, 0])
          ),
        },
        {
          label: "dashboard.quickAccess.accounting.filters.yearly",
          value: convertToArrayGetRange(
            getRange(DATE_RANGE_KEYS.currentYear, [0, 0])
          ),
          subLabel: convertRangeToText(
            getRange(DATE_RANGE_KEYS.currentYear, [0, 0])
          ),
        },
      ],
    },
  },
};
