import { useState } from "react";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import {
  PAYMENTS_TABS,
  PAYROLL_PAYMENTS_TABS,
} from "@/utils/constants/payments";

import "./styles.scss";

export default function Payments() {
  const [selectedTab, setSelectedTab] = useState(PAYROLL_PAYMENTS_TABS[0]?.key);
  return (
    <>
      {/* page  */}
      <div className="flex flex-col pl-11 pr-11.5">
        <span className="breadcrumb-text">
          <Text translationKey="billPay.title" />
          &nbsp;/&nbsp;
          <Text translationKey="billPay.bill.title" />
        </span>
        <div className="flex justify-between">
          <Text
            classes="listing-page-title"
            translationKey="billPay.bill.payments.title"
          />
        </div>
        <div className="mt-6">
          <Tabs
            items={PAYMENTS_TABS}
            selectedTab={selectedTab?.key}
            setCurrentTab={(tab) => setSelectedTab(tab)}
          />
        </div>
      </div>
    </>
  );
}
