import { createSelector } from "@reduxjs/toolkit";

export const selectPolicyStore = (store) => store.policy;

export const submissionPoliciesSelector = createSelector(
  selectPolicyStore,
  (policy) => policy.submissionPolicies
);

export const approvalPoliciesSelector = createSelector(
  selectPolicyStore,
  (policy) => policy.approvalPolicies
);

export const currentPolicySelector = createSelector(
  selectPolicyStore,
  (policy) => policy.currentPolicy
);

export const tempCurrentPolicySelector = createSelector(
  selectPolicyStore,
  (policy) => policy.tempCurrentPolicy
);

export const isUpdatingSelector = createSelector(
  selectPolicyStore,
  (policy) => policy.isUpdating
);

export const currentPoliciesSelector = createSelector(
  selectPolicyStore,
  (policy) => policy.currentPolicies
);

export const submitableSelector = createSelector(
  selectPolicyStore,
  (policy) => policy.submitable
);

export const currentPolicyModuleSelector = createSelector(
  selectPolicyStore,
  (client) => {
    return client.currentPolicyModule;
  }
);

export const currentClaimSubmissionTypeSelector = createSelector(
  selectPolicyStore,
  (client) => {
    return client.currentClaimSubmissionType;
  }
);

export const submissionPolicyObjectSelector = createSelector(
  submissionPoliciesSelector,
  (submissionPolicies) => submissionPolicies.list
);
export const isFetchingSubmissionPoliciesSelector = createSelector(
  submissionPoliciesSelector,
  (submissionPolicies) => submissionPolicies.isFetching
);

export const approvalPolicyListSelector = createSelector(
  approvalPoliciesSelector,
  (approvalPolicies) => approvalPolicies.list
);

export const approvalPolicyObjectSelector = createSelector(
  approvalPoliciesSelector,
  (approvalPolicies) => approvalPolicies.object
);
