import PropTypes from "prop-types";

import { amountToCurrency } from "@/utils/common";

export default function TotalSpendCell({ val }) {
  return (
    <div className="flex items-center justify-end gap-2">
      {val?.vendor?.totalSpent
        ? amountToCurrency(val?.vendor?.totalSpent)
        : "-"}
    </div>
  );
}

TotalSpendCell.propTypes = {
  val: PropTypes.shape({
    id: PropTypes.any,

    vendor: PropTypes.object,
    openSliderHandler: PropTypes.func,
    duplicateBillId: PropTypes.any,

    disabled: PropTypes.bool,
    checkedValue: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
  }),
};
