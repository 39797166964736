import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { init } from "@/store/reducers/app";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import {
  isAdminSelector,
  isLoggedInSelector,
  userSelector,
} from "@/store/selectors/user";
import { onboardingMailDetailsSelector } from "@/store/selectors/vendors";

import Icon from "@/components/core/Icon";
import PageLoader from "@/components/core/PageLoader";

import LoginExceeded from "@/components/Login/LoginExceeded";
import OnboardingPageHelper from "@/components/Onboarding/OnboardingPageHelper";
// components
import PageNotFound from "@/components/PageNotFound";
import ProtectedRoute from "@/components/ProtectedRoute";
import AddBankDetailsComponent from "@/components/common/BillPayAndPayroll/AddBankDetailsComponent";
import LeftColumn from "@/components/common/BillPayAndPayroll/AddBankDetailsComponent/LeftColumn";

// routes

// layouts
import TwoFactorAuthPage from "@/pages/2fa";
import AcceptInvitation from "@/pages/accept-invitation";
import AccountLockedPage from "@/pages/account-locked";
import CompanyNotFound from "@/pages/company-not-found";
import DowntimeRoute from "@/pages/down-time";
import Exports from "@/pages/exports";
import Files from "@/pages/files";
import ForgotPasswordPage from "@/pages/forgot-password";
import VerifyInboxPage from "@/pages/forgot-password/verify";
// protected
import GettingStartedPage from "@/pages/getting-started";
import InactivtityLogout from "@/pages/inactivity-logout";
// pages
// public
import LoginPage from "@/pages/login";
import NotificationsPreferencesPage from "@/pages/manage/notifications";
import OauthLogin from "@/pages/oauth-login";
import ResetPasswordPage from "@/pages/reset-password";
import SwitchEntity from "@/pages/switch-entity";
import { getUrlWithRedirectUri } from "@/utils/routes";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { getRedirectionLinkForKycStatusApproved } from "@/utils/common";
import { ROUTES } from "@/constants/routes";
import { KYC_STEPS_STATUS } from "@/constants/onboarding";
import PublicLayout from "@/layouts/public";
import HomeLayout from "@/layouts/home";
import SecondaryAuthLayout from "@/layouts/auth/secondary";
import AuthLayout from "@/layouts/auth";
import OnboardingLayout from "@/layouts/Onboarding";
import QRPayments from "@/routes/QRPayPayments";
import AccountingRoutes from "@/routes/accounting";
import AnalyticsRoutes from "@/routes/analytics";
import BillPayRoutes from "@/routes/bill-pay";
import CardRoutes from "@/routes/cards";
import DashboardRoutes from "@/routes/dashboard";
import MainRoute from "@/routes/main-route";
import ManageRoutes from "@/routes/manage";
import MyVolopayRoutes from "@/routes/my-volopay";
import PayrollRoutes from "@/routes/payroll";
import ReimbursementRoutes from "@/routes/reimbursement";

export default function RoutesContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(userSelector);
  const currentUserKycStatus = currentUser?.kycState;
  const isAdmin = useSelector(isAdminSelector);
  const onboardingMailDetails = useSelector(onboardingMailDetailsSelector);
  const context =
    onboardingMailDetails?.vendorClass === BILL_PAYROLL_CONTEXT.PAYROLL
      ? BILL_PAYROLL_CONTEXT.PAYROLL
      : BILL_PAYROLL_CONTEXT.BILLPAY;
  const allNavUrls = useSelector(allNavUrlsSelector);

  const isAppLoading = localStorage.getItem("access-token") && !isLoggedIn;

  useEffect(() => {
    dispatch(init({ navigate }));
  }, []);
  useEffect(() => {
    // remove o-auth url as redirect uri
    const redirectParamValue = searchParams.get("redirect");
    const isOauthPageAsRedirect = redirectParamValue?.startsWith(
      ROUTES.oauthLogin.base.absolutePath
    );
    if (isOauthPageAsRedirect) {
      searchParams.delete("redirect");
      setSearchParams(searchParams);
    }
  }, [searchParams]);
  const getRedirectionUrl = (isLogged, user) => {
    if (isLogged && user) {
      if (currentUserKycStatus === KYC_STEPS_STATUS.APPROVED) {
        return getRedirectionLinkForKycStatusApproved(allNavUrls);
      }
      return ROUTES.onboarding.base.absolutePath;
    }
    return getUrlWithRedirectUri(ROUTES.login.base.absolutePath);
  };

  return isAppLoading ? (
    <PageLoader />
  ) : (
    <Routes>
      <Route path="/" element={<MainRoute />}>
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTES.auth.acceptInvitation}
            element={<AcceptInvitation />}
          />
          <Route
            path={ROUTES.login.base.absolutePath}
            element={<LoginPage />}
          />
          <Route
            path={ROUTES.companyNotFound.base.absolutePath}
            element={<CompanyNotFound />}
          />
        </Route>
        <Route element={<SecondaryAuthLayout />}>
          <Route
            path={ROUTES.twoFactor.base.absolutePath}
            element={<TwoFactorAuthPage />}
          />
          <Route
            path={ROUTES.loginExceeded.base.absolutePath}
            element={<LoginExceeded />}
          />
          <Route
            path={ROUTES.forgotPassword.base.absolutePath}
            element={<ForgotPasswordPage />}
          />
          <Route
            path={ROUTES.forgotPassword.verifyInbox.absolutePath}
            element={<VerifyInboxPage />}
          />
          <Route
            path={ROUTES.accountLocked.absolutePath}
            element={<AccountLockedPage />}
          />
          <Route
            path={ROUTES.resetPassword.base.absolutePath}
            element={<ResetPasswordPage />}
          />
          <Route
            path={ROUTES.inactivityLogout.base.absolutePath}
            element={<InactivtityLogout />}
          />
        </Route>
        <Route
          element={
            !isLoggedIn ? (
              <PublicLayout
                leftColumn={
                  <div className="min-h-screen pt-6 pl-10 bg-neutral-100">
                    <Icon
                      className="w-16 h-10 text-neutral-700 "
                      name="VolopayLogoOnCard"
                    />
                  </div>
                }
              />
            ) : (
              <Navigate to={getRedirectionUrl(isLoggedIn, currentUser)} />
            )
          }
        >
          <Route
            path={ROUTES.login.base.absolutePath}
            element={<LoginPage />}
          />

          <Route
            path={ROUTES.twoFactor.base.absolutePath}
            element={<TwoFactorAuthPage />}
          />
          <Route
            path={ROUTES.forgotPassword.base.absolutePath}
            element={<ForgotPasswordPage />}
          />
        </Route>
        <Route
          path={ROUTES.oauthLogin.base.absolutePath}
          element={<OauthLogin />}
        />
        <Route element={<OnboardingLayout />}>
          <Route
            path={ROUTES.onboarding.base.absolutePath}
            element={<OnboardingPageHelper />}
          />
        </Route>
        <Route element={<HomeLayout />}>
          <Route element={<ProtectedRoute />}>
            <Route
              path={ROUTES.myVolopay.gettingStarted.pathName}
              element={<GettingStartedPage />}
            />
            <Route
              path={`${ROUTES.dashboard.base.absolutePath}/*`}
              element={<DashboardRoutes />}
            />

            <Route
              path={`${ROUTES.cards.base.absolutePath}/*`}
              element={<CardRoutes />}
            />
            <Route
              path={`${ROUTES.QRPay.base.absolutePath}/*`}
              element={<QRPayments />}
            />

            <Route
              path={`${ROUTES.billpay.base.absolutePath}/*`}
              element={<BillPayRoutes />}
            />

            <Route
              path={`${ROUTES.myVolopay.base.absolutePath}/*`}
              element={<MyVolopayRoutes />}
            />

            <Route
              path={`${ROUTES.payroll.base.absolutePath}/*`}
              element={<PayrollRoutes />}
            />
            <Route
              path={`${ROUTES.reimbursement.base.absolutePath}/*`}
              element={<ReimbursementRoutes />}
            />
            <Route
              path={`${ROUTES.accounting.base.absolutePath}/*`}
              element={<AccountingRoutes />}
            />
            <Route
              path={`${ROUTES.analytics.base.absolutePath}/*`}
              element={<AnalyticsRoutes />}
            />
            <Route
              path={`${ROUTES.manage.base.absolutePath}/*`}
              element={<ManageRoutes />}
            />
          </Route>
          <Route
            path={ROUTES.exports.base.absolutePath}
            element={<Exports />}
          />
          <Route
            path={ROUTES.notifications.pathName}
            element={<NotificationsPreferencesPage />}
          />
        </Route>

        <Route
          element={
            <PublicLayout leftColumn={<LeftColumn context={context} />} />
          }
        >
          <Route
            path={`${ROUTES.billpay.addBankDetails.absolutePath}/:token`}
            element={<AddBankDetailsComponent context={context} />}
          />
        </Route>
        <Route path={ROUTES.files.base.absolutePath} element={<Files />} />
        <Route
          path="/"
          element={
            <Navigate
              to={
                isLoggedIn
                  ? getRedirectionUrl(isLoggedIn, currentUser)
                  : getUrlWithRedirectUri(ROUTES.login.base.absolutePath)
              }
            />
          }
        />

        <Route
          path={ROUTES.handleSwitchEntity.base.absolutePath}
          element={<SwitchEntity />}
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route
        path={ROUTES.downtime.base.absolutePath}
        element={<DowntimeRoute />}
      />
    </Routes>
  );
}
