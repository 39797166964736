import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  isFetchingPayrollWalletSelector,
  paymentWalletListSelector,
  payrollWalletAsArraySelector,
} from "@/store/selectors/client";
import { isFetchingPaymentAccountsSelector } from "@/store/selectors/payments";
import { selectedVendorSelector } from "@/store/selectors/vendors";

import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import {
  paymentAccountDropdownOption,
  paymentAccountDropdownOptionSelected,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/paymentAccountDropdownOption";
import { PAY_OUTSIDE_VOLOPAY_CURRENCY } from "@/utils/constants/common";

import "./index.scss";

export default function WalletAccountSection({
  values,
  errors,
  handleChange,
  setValues,
  senderCurrency,
  beneficiaryCurrency,
  inPayrollContext,
  isPayOutsideVolopay,
  disabled,
}) {
  const dispatch = useDispatch();
  const vendor = useSelector(selectedVendorSelector);

  // payment account (and default selection)
  // API call is in parent
  const paymentAccounts =
    useSelector(
      inPayrollContext
        ? payrollWalletAsArraySelector
        : paymentWalletListSelector
    ) ?? [];

  const isFetchingPaymentAccounts = useSelector(
    inPayrollContext
      ? isFetchingPayrollWalletSelector
      : isFetchingPaymentAccountsSelector
  );

  // auto fill Payment account needs this effect since we have an extra 'Pay outside volopay' option
  useEffect(() => {
    if (isPayOutsideVolopay) {
      setValues((prev) => ({
        ...prev,
        paymentAccount: PAY_OUTSIDE_VOLOPAY_CURRENCY,
        paymentAccountCurrency: PAY_OUTSIDE_VOLOPAY_CURRENCY,
      }));
    }
  }, [isPayOutsideVolopay]);

  const payOutsideVolopaySelectOption = {
    currency: PAY_OUTSIDE_VOLOPAY_CURRENCY,
    id: PAY_OUTSIDE_VOLOPAY_CURRENCY,
    // For now (dropdown select), need valid value here to avoid Flag breakage in other sections on dropdown
    specialLabel:
      "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.payoutSideVolopay.title",
    specialDescription: inPayrollContext
      ? "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.payoutSideVolopay.descriptionPayroll"
      : "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.payoutSideVolopay.descriptionBillpay",
    specialIcon: "PayOutsideVolopay",
  };

  const dropdownOptions = [
    ...paymentAccounts.map((item) =>
      inPayrollContext
        ? {
            ...item,
            specialLabel:
              "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentDetails.payrollAccount",
          }
        : item
    ),
    payOutsideVolopaySelectOption,
  ];
  useEffect(() => {
    if (
      dropdownOptions?.length &&
      !values?.paymentAccount &&
      !isPayOutsideVolopay
    ) {
      setValues((prev) => ({
        ...prev,
        paymentAccount: dropdownOptions?.[0]?.id,
        paymentAccountCurrency: dropdownOptions?.[0]?.currency,
      }));
    }
  }, [dropdownOptions]);
  return (
    <div className="flex flex-col gap-6 mt-12">
      <Text
        translationKey="billPay.bill.invoiceInbox.createBill.sections.paymentAccountSection.title"
        classes="text-xl font-bold"
      />

      <div
        id="billpay-create-payment-account-section"
        className="pl-6 pr-2 card-wrapper"
      >
        <VpSelect
          name="paymentAccount"
          value={values.paymentAccount}
          error={errors.paymentAccount}
          handleChange={(e) => {
            handleChange(e);
            setValues((prev) => ({
              ...prev,
              paymentAccountCurrency: e?.currency,
            }));
          }}
          insideForm
          menuPosition="absolute"
          options={dropdownOptions}
          optionsDisplayKey="currency"
          valueKey="id"
          isOptionsLoading={isFetchingPaymentAccounts}
          label={null}
          searchable={false}
          customUIForOptionWhenDropdownClosed={
            paymentAccountDropdownOptionSelected
          }
          customUIForOptionWhenDropdownOpen={paymentAccountDropdownOption}
          noborder
          disabled={disabled}
        />
      </div>
    </div>
  );
}

WalletAccountSection.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  senderCurrency: PropTypes.string,
  beneficiaryCurrency: PropTypes.string,
  inPayrollContext: PropTypes.bool,
  isPayOutsideVolopay: PropTypes.bool,
  disabled: PropTypes.bool,
};
