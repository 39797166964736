import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import ProjectsTable from "@/components/Company/ProjectsTable";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

function ProjectPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);
  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <Text classes="text-neutral-500" translationKey="company.title" />
          <Text
            classes="text-3xl font-bold text-neutral-800"
            translationKey="company.project.title"
          />
        </div>
        {checkIfUserActionAllowed(
          allNavUrls,
          userAllowedActions,
          USER_ALLOWED_CTA_KEYS.NEW_PROJECT
        ) ? (
          <Button
            variant="primary"
            label="company.project.addProject"
            preIcon="Add"
            classes="w-[212px]"
            onClick={() => {
              searchParams.append(
                SLIDERS_SEARCH_PARAMS.company.project.add,
                true
              );
              setSearchParams(searchParams);
            }}
          />
        ) : null}
      </div>
      <ProjectsTable />
    </div>
  );
}

export default ProjectPage;
