import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";

// [...95, ...85, ...] or pending
export default function OcrAccuracyCell({ val }) {
  let iconName = "OCRPending";
  if (val?.accuracy) {
    if (val.accuracy >= 95) {
      iconName = "OCRHigh";
    } else if (val.accuracy >= 85) iconName = "OCRMedium";
    else iconName = "OCRLow";
  }

  return (
    <div className="flex items-center justify-center">
      {Number.isNaN(parseFloat(val?.accuracy)) ? "-" : <Icon name={iconName} />}
    </div>
  );
}

OcrAccuracyCell.propTypes = {
  val: PropTypes.object,
};
