import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

function AddPeopleIcon({ disabled, id, handleClick, userId }) {
  return (
    <>
      <div
        id={`tooltip-people-add-${id}`}
        onClick={() => handleClick(id)}
        className={`flex cursor-pointer items-center justify-center w-8 h-8 p-2 rounded-full ${
          disabled
            ? "bg-neutral-50 text-neutral-500"
            : "bg-primary-50 text-primary-500"
        }`}
      >
        <Icon name="PersonAdd" />
      </div>

      <Tooltip direction="top" id={`tooltip-people-add-${id}`}>
        <Text
          classes="text-sm font-medium text-neutral-800 "
          translationKey={
            disabled
              ? "company.department.departmentDetails.tabs.people.tooltip"
              : "company.department.departmentDetails.tabs.people.enabledtooltip"
          }
        />
      </Tooltip>
    </>
  );
}

export default AddPeopleIcon;
AddPeopleIcon.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.number,
  handleClick: PropTypes.func,
};
