import ActionCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ActionCell";
import AmountCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/AmountCell";
import ApprovalProgressCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ApprovalProgressCell";
import CommmentCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/CommentCell";
import DescriptionCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/DescriptionCell";
import DueDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/DueDateCell";
import InvoiceCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceCell";
import InvoiceDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceDateCell";
import InvoiceNumberCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceNumberCell";
import MonthAndYearCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/MonthAndYearCell";
import PaymentDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentDateCell";
import VendorCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/VendorCell";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";

export const getApprovalTableDefaultHeader = (page) => {
  const BILLPAY_APPROVAL_DEFAULT_HEADER = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      numberOfStickyColsRight: 1,
      colWidths: [300, 200, 200, 120, 120, 120, 120, 120, 120, 300],
      rightColWidths: [200],
      styleId: "payments-table",
    },
    emptyDataTitle: "billPay.bill.approvals.emptyTexts.title",
    emptySubHeadingDataTitle: "billPay.bill.approvals.emptyTexts.description",
    tableHeading: [
      {
        id: "vendor",
        title: "billPay.bill.invoiceInbox.tableHeaders.vendorHeading",
        classes: "text-left",
        cellComponent: VendorCell,
      },
      {
        id: "amount",
        title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "approval",
        title: "payroll.salaryPayment.approvals.title",
        classes: "text-right",
        cellComponent: ApprovalProgressCell,
      },
      {
        id: "paymentDate",
        title: "billPay.bill.invoiceInbox.tableHeaders.paymentDate",
        classes: "text-left",
        cellComponent: PaymentDateCell,
      },
      {
        id: "dueDate",
        title: "billPay.bill.invoiceInbox.tableHeaders.dueDate",
        classes: "text-left",
        cellComponent: DueDateCell,
      },
      {
        id: "invoiceDate",
        title: "billPay.bill.invoiceInbox.tableHeaders.invoiceDate",
        classes: "text-left",
        cellComponent: InvoiceDateCell,
      },
      {
        id: "invoiceNumber",
        title: "billPay.bill.invoiceInbox.tableHeaders.invoiceNumber",
        classes: "text-left",
        cellComponent: InvoiceNumberCell,
      },
      {
        id: "comments",
        title: "billPay.bill.invoiceInbox.tableHeaders.comments",
        classes: "justify-center",
        cellComponent: CommmentCell,
      },
      {
        id: "invoice",
        title: "billPay.bill.invoiceInbox.tableHeaders.invoice",
        classes: "justify-center",
        cellComponent: InvoiceCell,
      },
      {
        id: "description",
        title: "billPay.bill.invoiceInbox.tableHeaders.description",
        classes: "justify-left text-left",
        cellComponent: DescriptionCell,
      },
      {
        id: "action",
        title: "billPay.bill.invoiceInbox.tableHeaders.actionHeading",
        classes: "justify-center",
        cellComponent: ActionCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };
  const PAYROLL_APPROVAL_DEFAULT_HEADER = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      numberOfStickyColsRight: 1,
      colWidths: [300, 200, 200, 200, 200, 120, 215, 200],
      rightColWidths: [200],
      styleId: "payments-table",
    },
    emptyDataTitle: "billPay.bill.approvals.emptyTexts.title",
    emptySubHeadingDataTitle:
      "billPay.bill.approvals.emptyTexts.descriptionPayroll",
    tableHeading: [
      {
        id: "employee",
        title:
          "payroll.salaryPayment.payrollInbox.tableHeaders.employeeHeading",
        classes: "text-left",
        cellComponent: VendorCell,
      },
      {
        id: "amount",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "approval",
        title: "payroll.salaryPayment.approvals.title",
        classes: "text-left",
        cellComponent: ApprovalProgressCell,
      },
      {
        id: "forTheMonth",
        title:
          "payroll.salaryPayment.payrollInbox.tableHeaders.paymentMonthAndYear",
        classes: "justify-center",
        cellComponent: MonthAndYearCell,
      },
      {
        id: "paymentDate",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.paymentDate",
        classes: "justify-center",
        cellComponent: PaymentDateCell,
      },
      {
        id: "comments",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.comments",
        classes: "justify-center",
        cellComponent: CommmentCell,
      },
      {
        id: "description",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.description",
        classes: "justify-left text-left",
        cellComponent: DescriptionCell,
      },
      {
        id: "action",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.actionHeading",
        classes: "justify-center",
        cellComponent: ActionCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  switch (page) {
    case BILL_PAYROLL_CONTEXT.BILLPAY:
    case ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY:
      return BILLPAY_APPROVAL_DEFAULT_HEADER;
    case BILL_PAYROLL_CONTEXT.PAYROLL:
    case ACTION_CENTRE_PAGE_CONTEXT.PAYROLL:
      return PAYROLL_APPROVAL_DEFAULT_HEADER;
    default:
      break;
  }
};
