import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export default function Switch({
  isError,
  name,
  value,
  handleChange = () => {},
  disabled,
  id,
  insideForm = false,
  disabledStyles = "",
}) {
  const toggleClass = " transform translate-x-3";

  const handleToggle = (prev, event) => {
    if (!disabled) {
      if (insideForm)
        handleChange({ target: { value: !prev, name, type: "switch" } }, event);
      else handleChange(!prev, event);
    }
  };

  return (
    <div className="inline-block">
      <div
        className={`w-8 h-5 flex items-center rounded-full p-[2px]  ${
          disabled
            ? `cursor-not-allowed ${
                value ? "bg-neutral-300" : "bg-neutral-200"
              } ${disabledStyles}`
            : `cursor-pointer ${value ? "bg-primary-500" : "bg-neutral-300"}`
        }`}
        onClick={(event) => {
          handleToggle(value, event);
        }}
        id={id}
      >
        <div
          className={`h-4 w-4 rounded-full bg-white  ${
            disabled
              ? `${value ? toggleClass : "bg-neutral-300"}`
              : `transform duration-300 ease-in-out ${
                  value ? toggleClass : null
                }`
          }`}
        />
      </div>
      {isError && <p className="px-1 text-sm text-danger">{isError}</p>}
    </div>
  );
}

Switch.propTypes = {
  value: PropTypes.bool,
  isError: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  disabledStyles: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  insideForm: PropTypes.bool,
};
