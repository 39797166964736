import PropsTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import VendorCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/VendorCell";
import VendorTable from "@/components/common/BillPayAndPayroll/VendorOrEmployee/VendorTable";
import BeneficiaryCurrCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/BeneficiaryCurrCell";
import ContractCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/ContractCell";
import EmployeeIDCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/EmployeeIDCell";
import Last30DaysCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/Last30DaysCell";
import TotalSpendCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/TotalSpendCell";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

function PayrollEmployees() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [sliderContent, setSliderContent] = useState(null);
  const EMPLOYEE_TABLE_DEFAULT_HEADERS = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 200, 200, 237, 120],
      emptyDataTitle: "payroll.payrollEmployees.emptyTableHeading",
      emptyDataDescription: "payroll.payrollEmployees.emptyTableDescription",
    },
    tableHeading: [
      {
        id: "employee",
        title: "payroll.payrollEmployees.tableHeaders.employee",
        classes: "text-left",
        cellComponent: VendorCell,
      },
      {
        id: "employeeId",
        title: "payroll.payrollEmployees.tableHeaders.employeeId",
        classes: "text-left",
        cellComponent: EmployeeIDCell,
      },
      {
        id: "beneficiaryCurrency",
        title: "payroll.payrollEmployees.tableHeaders.beneficiaryCurrency",
        classes: "text-left",
        cellComponent: BeneficiaryCurrCell,
      },
      {
        id: "totalSpend",
        title: "payroll.payrollEmployees.tableHeaders.totalSpend",
        classes: "text-right justify-end",
        haveSort: true,
        cellComponent: TotalSpendCell,
      },
      {
        id: "last30Days",
        title: "payroll.payrollEmployees.tableHeaders.last30Days",
        classes: "text-right justify-end",
        haveSort: true,
        cellComponent: Last30DaysCell,
      },
      {
        id: "documents",
        title: "payroll.payrollEmployees.tableHeaders.documents",
        classes: "justify-center",
        cellComponent: ContractCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses: "text-sm font-semibold text-center  text-neutral-800",
  };

  const handleAddEmployeeButton = () => {
    searchParams.set(SLIDERS_SEARCH_PARAMS.employees.create, true);
    setSearchParams(searchParams);
  };

  const openSliderHandler = (id, extraParams = {}) => {
    setSearchParams({
      [SLIDERS_SEARCH_PARAMS.employees.id]: id,
      ...extraParams,
    });
  };

  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <HeaderText addEmployee={handleAddEmployeeButton} />
      <div className="mt-1">
        <VendorTable
          context={BILL_PAYROLL_CONTEXT.PAYROLL}
          openSliderHandler={openSliderHandler}
          headerSettings={EMPLOYEE_TABLE_DEFAULT_HEADERS}
        />
      </div>
    </div>
  );
}

function HeaderText({ addEmployee }) {
  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);
  return (
    <div className="flex items-center justify-between mr-3">
      <div className="flex flex-col">
        <div className="breadcrumb-text">
          <Text translationKey="payroll.title" />
          &nbsp;/&nbsp;
          <Text translationKey="payroll.salaryPayment.title" />
        </div>
        <Text
          classes="listing-page-title"
          translationKey="payroll.payrollEmployees.employees"
        />
      </div>
      {checkIfUserActionAllowed(
        allNavUrls,
        userAllowedActions,
        USER_ALLOWED_CTA_KEYS.ADD_EMPLOYEE
      ) ? (
        <Button
          label="payroll.payrollEmployees.addEmployee.title"
          onClick={addEmployee}
          variant="primary"
          preIcon="Add"
          labelStyleClasses="font-semibold text-btn-lg"
          classes="px-6 py-4"
          compact
        />
      ) : null}
    </div>
  );
}

HeaderText.propTypes = {
  addEmployee: PropsTypes.func,
};

export default PayrollEmployees;
