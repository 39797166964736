import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import {
  fetchShallowCards,
  getMerchantShallow,
  getMerchantlist,
} from "@/store/reducers/cards";
import { fetchCategories } from "@/store/reducers/categories";
import {
  fetchShallowDepartments,
  fetchShallowLocations,
  fetchShallowProjects,
} from "@/store/reducers/company";
import {
  createRule,
  editRule,
  fetchMappings,
  fetchRule,
  fetchRuleItemTypes,
  fetchRules,
  resetRuleStore,
  resetSelectedMappings,
} from "@/store/reducers/rules";
import { fetchTags } from "@/store/reducers/tags";
import { fetchVendorsShallow } from "@/store/reducers/vendors";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  cardsListSelector,
  shallowMerchantListSelector,
} from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";
import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";
import {
  allDepartmentSelector,
  allLocationSelector,
  projectsSelector,
} from "@/store/selectors/company";
import {
  formattedRuleItemTypesSelector,
  isFetchingRuleItemTypesSelector,
  ruleItemGroupsSelector,
} from "@/store/selectors/rules";
import {
  accountingCategoryTagsSelector,
  accountingTaxCodeTagsSelector,
  customTagsSelector,
  listTypeTagsSelector,
  tagsSelector,
} from "@/store/selectors/tags";
import { vendorsListSelector } from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Icon from "@/components/core/Icon";
import Loader from "@/components/core/Loader";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import { capitalizeFirstLetter } from "@/components/Company/helper";
import { MAPPING_TYPE } from "@/utils/constants/rules";
import { DOT_SYMBOL } from "@/utils/constants/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import {
  ACCOUNTING_OWNER_TYPE,
  RULE_TYPES,
  VP_ITEM_TYPE,
} from "@/constants/rules";
import { ACCOUNTING_TAG_TYPES } from "@/constants/tags";

import DeleteRuleModal from "../DeleteRuleModal";

export default function CreateAdvancedRule({ setOnBack }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // New or Edit state
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.newEditAdvancedRule
  );
  const ruleId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.ruleId
  );

  const vpFields = useSelector(formattedRuleItemTypesSelector);

  const [removedVolopayFields, setRemovedVolopayFields] = useState([]);

  const [removedAccountingFields, setRemovedAccountingFields] = useState([]);

  const [actionType, setActionType] = useState(type);

  // All list Tags
  const listTypeTags = useSelector(listTypeTagsSelector);

  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const ref = useLeftHeaderTitle({
    title: "accounting.rules.createAdvancedRules.title",
    titleTranslationProps: { type: capitalizeFirstLetter(actionType) },
  });
  const formattedTags = [
    ...listTypeTags.map((tag) => {
      return {
        tagId: tag.id,
        tagName: tag.name,
        ownerType: ACCOUNTING_OWNER_TYPE.TAG_VALUE,
      };
    }),
    {
      tagName: t("accounting.rules.newRule.accountingVendor", {
        accountingSoftware:
          ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingSoftware],
      }),
      ownerType: ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE,
    },
  ];
  // Mappings > Input fields
  const [mappings, setMappings] = useState([]);
  const [displayCategories, setDisplayCategories] = useState(vpFields);
  const [showDeleteRuleModal, setShowDeleteRuleModal] = useState(false);

  useEffect(() => {
    setOnBack(() => {
      dispatch(resetSelectedMappings());
    });
  }, []);

  useEffect(() => {
    setDisplayCategories(vpFields);
  }, [vpFields]);

  const [accountingMappings, setAccountingMappings] = useState([]);
  const [displayAccountingTags, setDisplayAccountingTags] =
    useState(formattedTags);
  const isDisabled = !accountingMappings?.length && !mappings?.length;
  // Add mapping as input field
  const setFilteredDisplayCategories = (_mappings) => {
    let filteredCategories = displayCategories;
    _mappings?.map((mapping) => {
      switch (mapping.itemType) {
        case VP_ITEM_TYPE.CARD:
          filteredCategories = filteredCategories.filter(
            (category) =>
              ![
                VP_ITEM_TYPE.CARD,
                VP_ITEM_TYPE.PROJECT,
                VP_ITEM_TYPE.DEPARTMENT,
                VP_ITEM_TYPE.LOCATION,
                VP_ITEM_TYPE.VENDOR,
              ].includes(category.itemType)
          );
          break;
        case VP_ITEM_TYPE.PROJECT:
          filteredCategories = filteredCategories.filter(
            (category) =>
              ![
                VP_ITEM_TYPE.PROJECT,
                VP_ITEM_TYPE.DEPARTMENT,
                VP_ITEM_TYPE.CARD,
              ].includes(category.itemType)
          );
          break;
        case VP_ITEM_TYPE.DEPARTMENT:
          filteredCategories = filteredCategories.filter(
            (category) =>
              ![
                VP_ITEM_TYPE.DEPARTMENT,
                VP_ITEM_TYPE.PROJECT,
                VP_ITEM_TYPE.CARD,
              ].includes(category.itemType)
          );
          break;
        case VP_ITEM_TYPE.MERCHANT:
        case VP_ITEM_TYPE.VENDOR:
          filteredCategories = filteredCategories.filter(
            (category) =>
              ![VP_ITEM_TYPE.MERCHANT, VP_ITEM_TYPE.VENDOR].includes(
                category.itemType
              )
          );
          break;
        default:
          filteredCategories = filteredCategories.filter(
            (category) => mapping.itemType !== category.itemType
          );
          break;
      }
      setDisplayCategories(filteredCategories);
    });
  };

  const addMapping = (_mappings) => {
    setFilteredDisplayCategories(_mappings);
    setMappings((prev) => [...prev, ...(_mappings ?? [])]);
  };

  // Remove mapping from input field
  const removeMapping = (mapping) => {
    setRemovedVolopayFields((prev) => [...prev, mapping.id]);

    switch (mapping.itemType) {
      case VP_ITEM_TYPE.PROJECT:
        setDisplayCategories((prev) => [
          ...prev,
          ...vpFields.filter((category) =>
            [
              VP_ITEM_TYPE.PROJECT,
              VP_ITEM_TYPE.DEPARTMENT,
              VP_ITEM_TYPE.CARD,
            ].includes(category.itemType)
          ),
        ]);
        break;
      case VP_ITEM_TYPE.CARD:
        setDisplayCategories((prev) => [
          ...prev,
          ...vpFields.filter((category) =>
            [
              VP_ITEM_TYPE.CARD,
              VP_ITEM_TYPE.PROJECT,
              VP_ITEM_TYPE.DEPARTMENT,
              VP_ITEM_TYPE.LOCATION,
              VP_ITEM_TYPE.VENDOR,
            ].includes(category.itemType)
          ),
        ]);
        break;
      case VP_ITEM_TYPE.DEPARTMENT:
        setDisplayCategories((prev) => [
          ...prev,
          ...vpFields.filter((category) =>
            [
              VP_ITEM_TYPE.DEPARTMENT,
              VP_ITEM_TYPE.PROJECT,
              VP_ITEM_TYPE.CARD,
            ].includes(category.itemType)
          ),
        ]);
        break;
      default:
        setDisplayCategories((prev) => [
          ...prev,
          ...vpFields.filter(
            (category) => category.itemType === mapping.itemType
          ),
        ]);
        break;
    }

    const updatedMappings = mappings.filter((_mapping) =>
      !_mapping.tagId
        ? _mapping.id !== mapping.id
        : _mapping.tagId !== mapping.tagId
    );
    setMappings(updatedMappings);
  };

  // Add accounting tag as input field
  const addAccountingMapping = (accountingTag) => {
    const filteredAccountingTags = displayAccountingTags.filter(
      (displayAccountingTag) =>
        displayAccountingTag.tagName !== accountingTag.tagName
    );
    setDisplayAccountingTags(filteredAccountingTags);
    setAccountingMappings((prev) => [...prev, accountingTag]);
  };

  // Remove accounting tag as input field
  const removeAccountingMapping = (accountingTag) => {
    setRemovedAccountingFields((prev) => [...prev, accountingTag.id]);
    setDisplayAccountingTags((prev) => [
      ...prev,
      ...formattedTags.filter(
        (_accountingTag) => _accountingTag.tagName === accountingTag.tagName
      ),
    ]);

    const updatedMappings = accountingMappings.filter(
      (_accountingTag) => _accountingTag.tagName !== accountingTag.tagName
    );
    setAccountingMappings(updatedMappings);
  };

  useEffect(() => {
    dispatch(fetchRuleItemTypes({ advance: true }));
    dispatch(getMerchantShallow());
    dispatch(fetchVendorsShallow());
    dispatch(fetchCategories());
    dispatch(fetchShallowProjects());
    dispatch(fetchShallowDepartments());
    dispatch(fetchShallowCards());
    dispatch(fetchShallowLocations());
    dispatch(fetchTags());
    dispatch(fetchAccountingVendors());
  }, []);

  useEffect(() => {
    if (ruleId) {
      dispatch(fetchRule({ id: ruleId }));
      dispatch(fetchMappings({ id: ruleId }));
    }
  }, [ruleId]);

  useEffect(() => {
    if (type === MAPPING_TYPE.NEW_RULE) {
      dispatch(resetRuleStore());
    }
  }, [type]);

  const mappingsBySelector = useSelector(ruleItemGroupsSelector);
  const isFetchingMappings = useSelector(isFetchingRuleItemTypesSelector);
  useEffect(() => {
    const _mappings = structuredClone(mappingsBySelector)?.[0];
    const leftMapped = _mappings?.ruleItems;
    const _rightMapped = _mappings?.ruleActions;

    const rightMapped = _rightMapped?.map((mapping) =>
      mapping.ownerType === ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE
        ? {
            ...mapping,
            tagName: t("accounting.rules.newRule.accountingVendor", {
              accountingSoftware:
                ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingSoftware],
            }),
          }
        : mapping
    );
    addMapping(leftMapped);

    const filteredAccountingTags = displayAccountingTags.filter(
      (tag) => !rightMapped?.some((mp) => tag.tagName === mp.tagName)
    );
    setDisplayAccountingTags(filteredAccountingTags);
    setAccountingMappings(
      rightMapped?.map((rightMapping) => ({
        ...rightMapping,
      })) ?? []
    );
  }, [mappingsBySelector]);

  const merchants = useSelector(shallowMerchantListSelector);
  const vendors = useSelector(vendorsListSelector);
  const projects = useSelector(projectsSelector);
  const departments = useSelector(allDepartmentSelector);
  const categories = useSelector(categoriesListSelector);
  const cards = useSelector(cardsListSelector);
  const locations = useSelector(allLocationSelector);
  const accountingCategoryTag = useSelector(accountingCategoryTagsSelector);
  const accountingTaxCodeTag = useSelector(accountingTaxCodeTagsSelector);
  const accountingVendorsOptions = useSelector(accountingVendorsSelector);
  const customTags = useSelector(customTagsSelector);

  const getVpFieldData = (vpField) => {
    switch (vpField) {
      case VP_ITEM_TYPE.MERCHANT:
        return merchants;
      case VP_ITEM_TYPE.VENDOR:
        return vendors;
      case VP_ITEM_TYPE.PROJECT:
        return projects;
      case VP_ITEM_TYPE.DEPARTMENT:
        return departments;
      case VP_ITEM_TYPE.CATEGORY:
        return categories;
      case VP_ITEM_TYPE.CARD:
        return cards;
      case VP_ITEM_TYPE.LOCATION:
        return locations;
      default:
        break;
    }
  };

  const getAccountingFieldOptionsByLabel = (label) => {
    switch (label) {
      case ACCOUNTING_TAG_TYPES.CATEGORY:
        return accountingCategoryTag?.options;
      case ACCOUNTING_TAG_TYPES.TAX_CODE:
        return accountingTaxCodeTag?.options;
      case ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE:
        return accountingVendorsOptions;
      default:
        return listTypeTags.filter((tag) => tag.name === label)?.[0]?.options;
    }
  };

  const formattedVpFieldData = (vpData, vpField) => {
    switch (vpField) {
      case VP_ITEM_TYPE.CARD:
        return vpData?.map((_vpData) => ({
          id: _vpData.id,
          label: _vpData.name,
          subLabel: `${_vpData.userName} ${DOT_SYMBOL} ${_vpData.cardNumber}`,
        }));
      default:
        return vpData?.map((_vpData) => ({
          id: _vpData.id,
          label: _vpData.name,
        }));
    }
  };

  const getVpFieldOptions = (vpField) => {
    const vpData = getVpFieldData(vpField);
    return formattedVpFieldData(vpData, vpField);
  };

  const accountingFieldOptions = (accountingField) => {
    const accountingData = getAccountingFieldOptionsByLabel(
      accountingField?.includes(ACCOUNTING_TAG_TYPES.CATEGORY)
        ? ACCOUNTING_TAG_TYPES.CATEGORY
        : accountingField?.includes(ACCOUNTING_TAG_TYPES.TAX_CODE)
          ? ACCOUNTING_TAG_TYPES.TAX_CODE
          : accountingField?.includes(ACCOUNTING_TAG_TYPES.VENDOR)
            ? ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE
            : accountingField
    );
    return accountingData;
  };

  const handleClick = (category) => {
    addMapping([category]);
    switch (category.itemType) {
      case VP_ITEM_TYPE.MERCHANT:
        dispatch(getMerchantlist());
        break;
      case VP_ITEM_TYPE.CATEGORY:
        dispatch(fetchCategories());
        break;
      case VP_ITEM_TYPE.PROJECT:
        dispatch(fetchShallowProjects());
        break;
      case VP_ITEM_TYPE.CARD:
        dispatch(fetchShallowCards());
        break;
      case VP_ITEM_TYPE.LOCATION:
        dispatch(fetchShallowLocations());
        break;
      default:
        break;
    }
  };

  const handleEdit = () => {
    const payload = {
      id: ruleId,
      rule_type: RULE_TYPES.ADVANCED,
      rule_item_groups_attributes: [
        {
          id: mappingsBySelector?.[0]?.id,
          rule_items_attributes: mappings
            .map((_mapping) => ({
              id: _mapping.id,
              item_ids: _mapping.itemIds,
              item_type: _mapping.itemType,
            }))
            .concat(
              removedVolopayFields.map((id) => ({
                id,
                _destroy: true,
              }))
            ),
          rule_actions_attributes: accountingMappings
            .map((_mapping) => ({
              id: _mapping.id,
              owner_id: _mapping.ownerId,
              owner_type: _mapping.ownerType,
            }))
            .concat(
              removedAccountingFields.map((id) => ({
                id,
                _destroy: true,
              }))
            ),
        },
      ],
    };
    dispatch(editRule({ payload, onSuccess }));
  };

  const handleCreate = () => {
    const payload = {
      rule_type: RULE_TYPES.ADVANCED,
      rule_item_groups_attributes: [
        {
          rule_items_attributes: mappings.map((mapping) => {
            return {
              item_ids: mapping.itemIds,
              item_type: mapping.itemType,
            };
          }),
          rule_actions_attributes: accountingMappings.map((mapping) => {
            return {
              owner_id: mapping.ownerId,
              owner_type: mapping.ownerType,
            };
          }),
        },
      ],
    };
    dispatch(createRule({ payload, onSuccess }));
  };

  const onSuccess = () => {
    if (ruleId) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.ruleId);
    }
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.rules.newEditAdvancedRule
    );
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.rules.newEditAdvancedRule
    );
    setSearchParams(searchParams);
    dispatch(resetRuleStore(null));
    dispatch(fetchRules({ rule_type: RULE_TYPES.ADVANCED }));
  };

  const handleSubmit = () => {
    if (type === MAPPING_TYPE.NEW_RULE) {
      handleCreate();
    } else {
      handleEdit();
    }
  };

  const handleDelete = () => {
    setShowDeleteRuleModal(true);
  };

  const setIds = (
    { selectedIds, ...rest },
    mapping_id,
    mapping_type,
    id,
    isAccounting,
    accountingOwnerId
  ) => {
    if (isAccounting) {
      const updatedMappings = accountingMappings.map((mapping) =>
        mapping.tagId === mapping_id
          ? {
              ...mapping,
              ownerId: accountingOwnerId,
              ownerType: mapping.tagName.includes(ACCOUNTING_TAG_TYPES.VENDOR)
                ? ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE
                : ACCOUNTING_OWNER_TYPE.TAG_VALUE,
            }
          : mapping
      );
      setAccountingMappings(updatedMappings);
    } else {
      const updatedMappings = mappings.map((mapping) =>
        mapping.itemType === mapping_type
          ? { ...mapping, itemIds: selectedIds }
          : mapping
      );
      setMappings(updatedMappings);
    }
  };

  return (
    <div className="slider-content-container">
      <div className="slider-content-core">
        <span className="text-2xl font-bold text-neutral-800">
          <Text
            translationKey="accounting.rules.createAdvancedRules.title"
            translationProps={{ type: capitalizeFirstLetter(actionType) }}
            refProp={ref}
          />
        </span>
        {!isFetchingMappings ? (
          <div>
            {/* Left mapping section */}
            <div className="flex flex-col flex-wrap mt-8">
              <span className="text-lg">
                <Text translationKey="accounting.rules.createAdvancedRules.leftMapping" />
              </span>
              <div className="px-6 py-4 mt-6 border rounded-md border-neutral-200">
                {/* Map this */}
                {mappings?.map((mapping, index) => {
                  return (
                    <div className="flex flex-col gap-4" key={mapping.id}>
                      <div className="flex items-center justify-between w-full">
                        <div className="w-11/12 pt-4">
                          <CheckboxDropdown
                            options={getVpFieldOptions(mapping.itemType)}
                            label={t(
                              "accounting.rules.createAdvancedRules.mappingLabel",
                              {
                                label: mapping.itemType,
                              }
                            )}
                            handleSubmit={(data) =>
                              setIds(
                                data,
                                mapping.id,
                                mapping.itemType,
                                0,
                                false
                              )
                            }
                            selectedIdsArray={mapping?.itemIds?.map((id) =>
                              parseInt(id, 10)
                            )}
                          />
                        </div>
                        <div
                          onClick={() => removeMapping(mapping)}
                          className="cursor-pointer"
                        >
                          <Icon name="Delete" className="text-neutral-500" />
                        </div>
                      </div>
                      {index !== mappings.length - 1 && (
                        <div className="w-10 h-8 px-2 py-1 text-sm font-semibold rounded-lg text-neutral-500 bg-neutral-50 h-">
                          <Text translationKey="accounting.rules.andSeparator" />
                        </div>
                      )}
                    </div>
                  );
                })}
                {/* Map this */}
                <div className="flex flex-wrap items-center gap-3 my-2">
                  {displayCategories.map((category) => (
                    <Button
                      key={category.tagId}
                      variant="tertiary"
                      preIcon="Add"
                      label={t(
                        "accounting.rules.createAdvancedRules.btnLabel",
                        {
                          label: category.itemType.toLowerCase(),
                        }
                      )}
                      classes="w-fit px-3 h-8 text-xs font-bold"
                      iconClasses="p-0.75"
                      onClick={() => handleClick(category)}
                    />
                  ))}
                </div>
              </div>
            </div>
            {/* Right mapping section */}
            <div className="flex flex-col flex-wrap mt-8 mb-10">
              <span className="text-lg">
                <Text translationKey="accounting.rules.createAdvancedRules.rightMapping" />
              </span>
              <div className="flex flex-col gap-6 px-6 py-4 mt-6 border rounded-md border-neutral-200">
                {/* Map this */}
                {accountingMappings?.map((accountingTag) => {
                  return (
                    <div
                      className="flex items-center justify-between w-full py-4"
                      key={accountingTag.id}
                    >
                      <div className="w-11/12">
                        <VpSelect
                          label={t(
                            "accounting.rules.createAdvancedRules.rightMappingLabel",
                            {
                              label:
                                accountingTag.tagName ===
                                ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE
                                  ? t(
                                      "accounting.rules.newRule.accountingVendor",
                                      {
                                        accountingSoftware:
                                          ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                                            accountingSoftware
                                          ],
                                      }
                                    )
                                  : accountingTag.tagName,
                            }
                          )}
                          optionsDisplayKey="name"
                          valueKey="id"
                          value={accountingTag.ownerId}
                          options={accountingFieldOptions(
                            accountingTag.tagName
                          )}
                          handleChange={(data) =>
                            setIds(
                              data,
                              accountingTag.tagId,
                              null,
                              null,
                              true,
                              data.id
                            )
                          }
                          menuPosition="absolute"
                        />
                      </div>
                      <div
                        onClick={() => removeAccountingMapping(accountingTag)}
                      >
                        <Icon name="Delete" className="text-neutral-500" />
                      </div>
                    </div>
                  );
                })}
                {/* Map this */}
                <div className="flex flex-wrap items-center gap-3 my-2">
                  {displayAccountingTags.map((accountingTag) => (
                    <Button
                      key={accountingTag.tagId}
                      variant="tertiary"
                      preIcon="Add"
                      label={t(
                        "accounting.rules.createAdvancedRules.btnLabel",
                        {
                          label: accountingTag.tagName.toLowerCase(),
                        }
                      )}
                      classes="w-fit px-3 h-8 text-xs font-bold"
                      iconClasses="p-0.75"
                      onClick={() => addAccountingMapping(accountingTag)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <div className="flex items-center justify-end gap-4 p-4 slider-footer">
        {type !== MAPPING_TYPE.NEW_RULE && (
          <Button
            variant="tertiary"
            label="accounting.rules.deleteRule.title"
            classes="w-fit px-4 text-danger-500 text-base font-semibold"
            onClick={handleDelete}
          />
        )}
        <Button
          variant="primary"
          label={`${
            type !== MAPPING_TYPE.NEW_RULE
              ? "accounting.rules.deleteRule.btnLabels.saveChanges"
              : `accounting.rules.createAdvancedRules.${actionType}BtnLabel`
          }`}
          classes="w-fit px-4 text-base font-semibold"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
      {showDeleteRuleModal ? (
        <DeleteRuleModal
          showModal={showDeleteRuleModal}
          setShowModal={setShowDeleteRuleModal}
        />
      ) : null}
    </div>
  );
}

CreateAdvancedRule.propTypes = {
  setOnBack: PropTypes.func,
};
