import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout } from "@/store/reducers/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import { ROUTES } from "@/constants/routes";

function InactivtityLogout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout({}));
  }, []);

  return (
    <div>
      <div className="flex flex-col gap-9">
        <div className="flex gap-2 flex-col">
          <Text
            classes="text-2xl font-bold"
            translationKey="login.inactivityLogoutText"
          />
          <Text
            classes="text-sm font-semibold text-neutral-500"
            translationKey="login.inactivityLogoutSubtext"
          />
        </div>

        <Button
          btnType="submit"
          name="formSubmit"
          variant="primary"
          classes="w-full"
          label="login.loginAgainTitle"
          onClick={() => {
            navigate(ROUTES.login.base.absolutePath);
          }}
        />
      </div>
    </div>
  );
}

export default InactivtityLogout;
