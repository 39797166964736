import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchPaymentApprovals,
  fetchPayments,
  fetchPayrollApprovals,
  setInPaymentSlice,
} from "@/store/reducers/payments";
import {
  fetchPurchaseBills,
  setInPurchaseBillSlice,
} from "@/store/reducers/purchase-bills";
import {
  deleteVendor,
  removeVendor,
  setSelectedVendor,
} from "@/store/reducers/vendors";

import Text from "@/components/core/Text";

import InputCaptchaCheck from "@/components/Company/Slider/People/InputCaptchaCheck";
import ImportantToKnowSection from "@/components/Company/common/ImportantToKnowSection";
import AmountCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/AmountCell";
import ApprovalProgressCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ApprovalProgressCell";
import DueDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/DueDateCell";
import MonthAndYearCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/MonthAndYearCell";
import PaymentDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentDateCell";
import PaymentStatusCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentStatusCell";
import VendorCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/VendorCell";
import DeleteEmployeeTable from "@/components/common/BillPayAndPayroll/VendorOrEmployee/DeleteEmployee/DeleteEmployeeTable";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  BILLPAY_FILTERS,
  PAYMENT_APPROVAL_STATUSES,
  PAYMENT_STATUSES,
} from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { VENDOR_CLASS } from "@/constants/common";
import { PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT } from "@/constants/pagination";

// 3 table configs
const getTablesConfigs = (inPayrollContext) => {
  const approvedTableConfig = {
    tableSetting: {
      headerSticky: true,
      colWidths: [232],
      rightColWidths: [200],
      styleId: "delete-employee-approved-table",
    },
    emptyDataTitle: inPayrollContext
      ? "payroll.payrollEmployees.deleteEmployee.sections.emptyTableHeading.approved"
      : "billPay.vendors.createVendor.deleteVendor.sections.emptyTableHeading.approved",
    tableHeading: [
      {
        id: "vendor",
        title: inPayrollContext
          ? "payroll.payrollEmployees.tableHeaders.employee"
          : "billPay.bill.invoiceInbox.tableHeaders.vendorHeading",
        classes: "text-left",
        cellComponent: (props) => (
          <VendorCell showCheckbox={false} {...props} />
        ),
      },
      {
        id: "amount",
        title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },

      {
        id: "paymentstatus",
        title: "billPay.bill.invoiceInbox.tableHeaders.paymentStatus",
        classes: "justify-center",
        cellComponent: PaymentStatusCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  const pendingToApproveTableConfig = {
    tableSetting: {
      headerSticky: true,
      colWidths: [232],
      rightColWidths: [185],
      styleId: "delete-employee-pending-to-approve-table",
    },
    emptyDataTitle: inPayrollContext
      ? "payroll.payrollEmployees.deleteEmployee.sections.emptyTableHeading.pendingToApprove"
      : "billPay.vendors.createVendor.deleteVendor.sections.emptyTableHeading.pendingToApprove",
    tableHeading: [
      {
        id: "vendor",
        title: inPayrollContext
          ? "payroll.payrollEmployees.tableHeaders.employee"
          : "billPay.bill.invoiceInbox.tableHeaders.vendorHeading",
        classes: "text-left",
        cellComponent: (props) => (
          <VendorCell showCheckbox={false} {...props} />
        ),
      },
      {
        id: "amount",
        title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },

      {
        id: "approval",
        title: "payroll.salaryPayment.approvals.singularTitle",
        classes: "text-right",
        cellComponent: ApprovalProgressCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  const draftsTableConfig = {
    tableSetting: {
      headerSticky: true,
      colWidths: [232],
      rightColWidths: [185],
      styleId: "delete-employee-drafts-table",
    },
    emptyDataTitle: inPayrollContext
      ? "payroll.payrollEmployees.deleteEmployee.sections.emptyTableHeading.drafts"
      : "billPay.vendors.createVendor.deleteVendor.sections.emptyTableHeading.drafts",
    tableHeading: [
      {
        id: "vendor",
        title: inPayrollContext
          ? "payroll.payrollEmployees.tableHeaders.employee"
          : "billPay.bill.invoiceInbox.tableHeaders.vendorHeading",
        classes: "text-left",
        cellComponent: (props) => (
          <VendorCell showCheckbox={false} {...props} />
        ),
      },
      {
        id: "amount",
        title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      //
      inPayrollContext
        ? {
            id: "forMonth",
            title:
              "payroll.salaryPayment.payrollInbox.tableHeaders.paymentMonthAndYear",
            classes: "justify-center",
            cellComponent: MonthAndYearCell,
          }
        : {
            id: "dueDate",
            title: "billPay.bill.invoiceInbox.tableHeaders.dueDate",
            classes: "text-left",
            cellComponent: DueDateCell,
          },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  const processingTableConfig = {
    tableSetting: {
      headerSticky: true,
      colWidths: [232],
      rightColWidths: [164],
      styleId: "delete-employee-processing-table",
    },
    emptyDataTitle: inPayrollContext
      ? "payroll.payrollEmployees.deleteEmployee.sections.emptyTableHeading.processing"
      : "billPay.vendors.createVendor.deleteVendor.sections.emptyTableHeading.processing",
    tableHeading: [
      {
        id: "vendor",
        title: "billPay.bill.invoiceInbox.tableHeaders.vendorHeading",
        classes: "text-left",
        cellComponent: (props) => (
          <VendorCell showCheckbox={false} {...props} />
        ),
      },
      {
        id: "amount",
        title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },

      {
        id: "approval",
        title: "billPay.bill.invoiceInbox.tableHeaders.paymentDate",
        classes: "justify-center",
        cellComponent: PaymentDateCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  return {
    approvedTableConfig,
    pendingToApproveTableConfig,
    draftsTableConfig,
    processingTableConfig,
  };
};

/**
 * Delete employee/vendor slider
 * {@link https://www.figma.com/file/nYb1B3o06dNJPuFhW7fLWP/v2-Payroll?type=design&node-id=4771-269588&mode=dev}
 *
 * @param {String} context billpay | payroll enum
 * @param {String} searchParamKey   current slider query param key
 * @param {String} searchParamValue current slider query param value
 *
 */
export default function DeleteEmployee({
  context = BILL_PAYROLL_CONTEXT.PAYROLL,
  searchParamKey,
  searchParamValue: id,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    approvedTableConfig,
    pendingToApproveTableConfig,
    draftsTableConfig,
    processingTableConfig,
  } = getTablesConfigs(inPayrollContext);

  const titleText = inPayrollContext
    ? "payroll.payrollEmployees.deleteEmployee.title"
    : "billPay.vendors.createVendor.deleteVendor.title";
  const titleRef = useLeftHeaderTitle({ title: titleText });

  const approvedCount =
    useSelector(
      (store) => store.payments?.[PAYMENT_STATUSES.pendingToBeTriggered]?.total
    ) ?? 0;
  const isFetchingApprovedCount =
    useSelector(
      (store) =>
        store.payments?.[PAYMENT_STATUSES.pendingToBeTriggered]?.isFetching
    ) ?? false;
  //
  const pendingToApproveCount =
    useSelector(
      (store) => store.payments?.[PAYMENT_APPROVAL_STATUSES.pending]?.total
    ) ?? 0;
  const isFetchingPendingToApproveCount =
    useSelector(
      (store) => store.payments?.[PAYMENT_APPROVAL_STATUSES.pending]?.isFetching
    ) ?? false;
  //
  const draftsCount =
    useSelector(
      (store) => store.purchaseBills?.[PAYMENT_STATUSES.inDraft]?.total
    ) ?? 0;
  const isFetchingDraftsCount =
    useSelector(
      (store) => store.purchaseBills?.[PAYMENT_STATUSES.inDraft]?.isFetching
    ) ?? false;
  //
  const processingCount =
    useSelector(
      (store) => store.payments?.[PAYMENT_STATUSES.processing]?.total
    ) ?? 0;
  const isFetchingProcessingCount =
    useSelector(
      (store) => store.payments?.[PAYMENT_STATUSES.processing]?.isFetching
    ) ?? false;

  const importantToKnowConfig = [
    {
      verticalExpandable: true,
      labelConfig: {
        label: inPayrollContext
          ? "payroll.payrollEmployees.deleteEmployee.sections.approved"
          : "billPay.vendors.createVendor.deleteVendor.sections.approved",
        classes: "text-sm font-semibold text-neutral-800",
      },
      count: approvedCount,
      isFetching: Boolean(approvedCount === 0 && isFetchingApprovedCount),
      innerContent: (
        <DeleteEmployeeTable
          context={context}
          tableConfig={approvedTableConfig}
          paginationLimit={PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT}
          params={{ [BILLPAY_FILTERS.PAYMENT]: id }}
          status={PAYMENT_STATUSES.pendingToBeTriggered}
        />
      ),
      key: "important-1",
      runWhenStillCollapsed: () => {
        dispatch(
          fetchPayments({
            key: PAYMENT_STATUSES.pendingToBeTriggered,
            value: {
              page: 1,
              limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
              payroll: context === BILL_PAYROLL_CONTEXT.PAYROLL,
              status: PAYMENT_STATUSES.pendingToBeTriggered,
              [BILLPAY_FILTERS.PAYMENT]: id,
            },
          })
        );
      },
      cleanup: () => {
        dispatch(
          setInPaymentSlice({
            key: PAYMENT_STATUSES.pendingToBeTriggered,
            value: null,
          })
        );
      },
    },
    {
      verticalExpandable: true,
      labelConfig: {
        label: inPayrollContext
          ? "payroll.payrollEmployees.deleteEmployee.sections.pendingToApprove"
          : "billPay.vendors.createVendor.deleteVendor.sections.pendingToApprove",
        classes: "text-sm font-semibold text-neutral-800",
      },
      count: pendingToApproveCount,
      isFetching: Boolean(
        pendingToApproveCount === 0 && isFetchingPendingToApproveCount
      ),
      innerContent: (
        <DeleteEmployeeTable
          context={context}
          tableConfig={pendingToApproveTableConfig}
          paginationLimit={PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT}
          params={{ [BILLPAY_FILTERS.PURCHASE_BILL_AND_PAYROLL]: id }}
          status={PAYMENT_APPROVAL_STATUSES.pending}
        />
      ),
      key: "important-2",
      runWhenStillCollapsed: () => {
        const thunk =
          context === BILL_PAYROLL_CONTEXT.BILLPAY
            ? fetchPaymentApprovals
            : fetchPayrollApprovals;

        dispatch(
          thunk({
            key: PAYMENT_APPROVAL_STATUSES.pending,
            value: {
              page: 1,
              limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
              status: PAYMENT_APPROVAL_STATUSES.pending,
              [BILLPAY_FILTERS.PURCHASE_BILL_AND_PAYROLL]: id,
            },
          })
        );
      },
      cleanup: () => {
        dispatch(
          setInPaymentSlice({
            key: PAYMENT_APPROVAL_STATUSES.pending,
            value: null,
          })
        );
      },
    },
    {
      verticalExpandable: true,
      labelConfig: {
        label: inPayrollContext
          ? "payroll.payrollEmployees.deleteEmployee.sections.drafts"
          : "billPay.vendors.createVendor.deleteVendor.sections.drafts",
        classes: "text-sm font-semibold text-neutral-800",
      },
      count: draftsCount,
      isFetching: Boolean(draftsCount === 0 && isFetchingDraftsCount),
      innerContent: (
        <DeleteEmployeeTable
          context={context}
          tableConfig={draftsTableConfig}
          paginationLimit={PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT}
          params={{ [BILLPAY_FILTERS.PURCHASE_BILL_AND_PAYROLL]: id }}
          status={PAYMENT_STATUSES.inDraft}
        />
      ),
      key: "important-2",
      runWhenStillCollapsed: () => {
        const thunk = fetchPurchaseBills;

        dispatch(
          thunk({
            key: PAYMENT_STATUSES.inDraft,
            context,
            value: {
              page: 1,
              limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
              status: PAYMENT_STATUSES.inDraft,
              [BILLPAY_FILTERS.PURCHASE_BILL_AND_PAYROLL]: id,
            },
          })
        );
      },
      cleanup: () => {
        dispatch(
          setInPurchaseBillSlice({
            key: PAYMENT_STATUSES.inDraft,
            value: null,
          })
        );
      },
    },
  ];

  const mandatoryRequirementsConfig = [
    {
      verticalExpandable: !!processingCount,
      labelConfig: {
        label:
          processingCount === 0 && !isFetchingProcessingCount
            ? inPayrollContext // empty cases
              ? "payroll.payrollEmployees.deleteEmployee.processingTitleEmpty"
              : "billPay.vendors.createVendor.deleteVendor.processingTitleEmpty"
            : inPayrollContext
              ? "payroll.payrollEmployees.deleteEmployee.processingTitle"
              : "billPay.vendors.createVendor.deleteVendor.processingTitle",
        classes: "text-sm font-semibold text-neutral-800",
      },
      sectionClasses:
        processingCount === 0 && !isFetchingProcessingCount
          ? ""
          : "bg-danger-50 .delete-employee-slider-mandatory-table",
      count: 0, // hide count in accordion
      isFetching: false,
      innerContent:
        processingCount && !isFetchingProcessingCount ? (
          <DeleteEmployeeTable
            context={context}
            tableConfig={processingTableConfig}
            paginationLimit={PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT}
            inSlider
            params={{ [BILLPAY_FILTERS.PAYMENT]: id }}
            status={PAYMENT_STATUSES.processing}
          />
        ) : null,
      sectionDescription:
        processingCount === 0 && !isFetchingProcessingCount
          ? ""
          : inPayrollContext
            ? "payroll.payrollEmployees.deleteEmployee.processingDescription"
            : "billPay.vendors.createVendor.deleteVendor.processingDescription",
      key: "mandatory-1",
      runWhenStillCollapsed: () => {
        dispatch(
          fetchPayments({
            key: PAYMENT_STATUSES.processing,
            value: {
              page: 1,
              limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
              payroll: context === BILL_PAYROLL_CONTEXT.PAYROLL,
              status: PAYMENT_STATUSES.processing,
              [BILLPAY_FILTERS.PAYMENT]: id,
            },
          })
        );
      },
      cleanup: () => {
        dispatch(
          setInPaymentSlice({
            key: PAYMENT_STATUSES.processing,
            value: null,
          })
        );
      },
    },
  ];

  const captchaMatchText = inPayrollContext
    ? "DELETE EMPLOYEE"
    : "DELETE VENDOR";

  const onCancel = () => {
    searchParams.delete(searchParamKey);
    setSearchParams(searchParams);
  };

  const onSubmit = () => {
    const onSuccess = () => {
      searchParams.delete(searchParamKey);
      // delete usual preceding slider as well
      searchParams.delete(
        inPayrollContext
          ? SLIDERS_SEARCH_PARAMS.employees.id
          : SLIDERS_SEARCH_PARAMS.vendors.id
      );
      setSearchParams(searchParams);
      dispatch(removeVendor(id));
      dispatch(setSelectedVendor(null));

      setIsSubmitting(false);
    };
    const onError = () => {
      setIsSubmitting(false);
    };

    setIsSubmitting(true);
    dispatch(
      deleteVendor({
        id,
        vendor_class: inPayrollContext
          ? VENDOR_CLASS.PAYROLL
          : VENDOR_CLASS.USER_CREATED,
        onSuccess,
        onError,
      })
    );
  };

  return (
    <>
      <div className="slider-content-core">
        <Text
          refProp={titleRef}
          translationKey={titleText}
          classes="text-3xl font-bold text-neutral-800"
        />

        {/* slider title, description */}
        <Text
          translationKey={
            inPayrollContext
              ? "payroll.payrollEmployees.deleteEmployee.description"
              : "billPay.vendors.createVendor.deleteVendor.description"
          }
          classes="inline-block text-neutral-500 text-sm font-medium"
        />

        <div className="mt-8">
          <Text
            classes="text-xl font-semibold"
            translationKey={
              inPayrollContext
                ? "payroll.payrollEmployees.deleteEmployee.title2"
                : "billPay.vendors.createVendor.deleteVendor.title2"
            }
          />

          <div className="mt-1 mb-5">
            <Text
              translationKey={
                inPayrollContext
                  ? "payroll.payrollEmployees.deleteEmployee.description2"
                  : "billPay.vendors.createVendor.deleteVendor.description2"
              }
              classes="text-neutral-500 text-sm font-medium"
            />
          </div>

          <ImportantToKnowSection
            mainLabelConfig={{
              label: "company.people.blockUserSlider.importantToKnowLabel",
              classes: "text-warning-600 text-sm font-semibold",
            }}
            config={importantToKnowConfig}
          />

          <ImportantToKnowSection
            classes="mt-8"
            mainLabelConfig={{
              label:
                processingCount === 0 && !isFetchingProcessingCount
                  ? "company.people.blockUserSlider.mandatoryRequirementsCompletedLabel"
                  : "company.people.blockUserSlider.mandatoryRequirementsLabel",
              classes: `text-sm font-semibold ${
                processingCount === 0 && !isFetchingProcessingCount
                  ? "text-success-600"
                  : "text-danger-500"
              }`,
              icon:
                processingCount === 0 && !isFetchingProcessingCount
                  ? "CheckCircle"
                  : "",
              iconClasses: "text-success-600 w-5 h-5",
            }}
            config={mandatoryRequirementsConfig}
            showImportantSection
            allSectionContainerClasses={
              processingCount === 0 && !isFetchingProcessingCount
                ? "bg-neutral-50 border-none"
                : "bg-danger-50 border-none"
            }
            totalCount={processingCount}
            isFetchingTotalCount={isFetchingProcessingCount}
            totalCountClasses="text-white bg-danger-400 rounded-lg"
          />
        </div>

        <div>
          {/* div needed above to avoid h-full issue in below component */}
          <InputCaptchaCheck
            classes="mt-9 pb-16"
            handleConfirmButton={onSubmit}
            handleCancelButton={onCancel}
            captchaMatchText={captchaMatchText}
            inProgress={isSubmitting}
            portalSelector=".delete-employee-footer"
            disabled={!!processingCount}
          />
        </div>
      </div>

      <div className="slider-content delete-employee-footer" />
    </>
  );
}

DeleteEmployee.propTypes = {
  context: PropTypes.string,
  searchParamKey: PropTypes.string,
  searchParamValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
