import PropTypes from "prop-types";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  deleteAppliedFilter,
  deleteSearchAndFilterAppliedFilter,
  deleteSliderAppliedFilters,
  deleteSliderSearchAndFilterAppliedFilter,
  getCategoriesForSearchFilters,
  getCategoryOptions,
  popCurrentNavigationIds,
  popSliderCurrentNavigationIds,
  resetCurrentNavigationIds,
  resetSliderCurrentNavagationIds,
  setCurrentNavigationIds,
  setSearchAndFilterCommonStoreStructure,
  setSearchAndFilterOptions,
  setSearchAndFilterOptionsLevelCount,
  setSearchAndFilterParamKey,
  setSearchInputText,
  setSliderCurrentNavigationIds,
  setSliderSearchAndFilterCommonStoreStructure,
  setSliderSearchAndFilterOptions,
  setSliderSearchAndFilterOptionsLevelCount,
  setSliderSearchAndFilterParamKey,
  setSliderSearchInputText,
} from "@/store/reducers/filters";
import {
  isFetchingFiltersSelector,
  searchAndFilterCommonStructureSelector,
  searchAndFilterCurrentNavigationIdsSelector,
  searchAndFilterOptionsCurrentLevelSelector,
  searchAndFilterOptionsListSelector,
  searchAndFilterParamKeySelector,
  sliderSearchAndFilterCommonStructureSelector,
  sliderSearchAndFilterCurrentNavigationIdsSelector,
  sliderSearchAndFilterOptionsCurrentLevelSelector,
  sliderSearchAndFilterOptionsListSelector,
  sliderSearchAndFilterParamKeySelector,
} from "@/store/selectors/filters";
import Icon from "@/components/core/Icon";
import { camelToSnake, debounce } from "@/utils/common";
import {
  AVAILABLE_FILTER_KEYS,
  FILTER_TRIGGER_CONTEXT,
} from "@/utils/constants/filters";
import {
  BACKSPACE_KEY,
  CATEGORIES_LEVELS_CONFIG,
  ENTER_KEY,
  SEARCH_FILTER_OPTIONS_SELECT_TYPE,
  SEARCH_FILTER_OPTIONS_UI_TYPE,
  SEARCH_PARAM_KEY,
  SEARCH_PAYLOAD_PARAM_KEY,
  getCommonStructureForApiToParams,
  getModuleValueBasedOnPagePathName,
  isCurrentOptionLevelMultiselect,
} from "@/utils/constants/searchAndFilter";

import SearchAndFilterUpperPillSection from "./SearchAndFilterUpperPillSection";
import SearchFilterInputOptionsComponent from "./SearchFilterInputOptionsComponent";

export default function SearchInput({
  handleFilter,
  context = FILTER_TRIGGER_CONTEXT.PAGE,
  sliderConfig,
  classes = "",
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const locationObject = useLocation();
  const pagePathName = locationObject?.pathname;
  const inputRef = useRef(null);
  const {
    moduleValue,
    onlyTextSearch,
    status = null,
  } = getModuleValueBasedOnPagePathName(pagePathName, sliderConfig);

  const COMPONENT_CONTEXT_CONFIG = {
    [FILTER_TRIGGER_CONTEXT.PAGE]: {
      selectors: {
        currentOptionsList: searchAndFilterOptionsListSelector,
        currentLevel: searchAndFilterOptionsCurrentLevelSelector,
        paramKey: searchAndFilterParamKeySelector,
        searchAndFilterCommonStoreStructure:
          searchAndFilterCommonStructureSelector,
        currentNavigationIds: searchAndFilterCurrentNavigationIdsSelector,
      },
      dispatcherFunc: {
        dispatchSearchAndFilterOptionFunc: setSearchAndFilterOptions,
        dispatchSearchAndFilterOptionsLevelCountFunc:
          setSearchAndFilterOptionsLevelCount,
        dispatchSearchAndFilterParamKey: setSearchAndFilterParamKey,
        dispatchSetCurrentNavigationIds: setCurrentNavigationIds,
        dispatchSetCommonStructureFunc: setSearchAndFilterCommonStoreStructure,
        dispatchSearchInputTextFunc: setSearchInputText,
        dispatchResetCurrentNavigationsIds: resetCurrentNavigationIds,
        dispatchDeleteSearchAndFilterAppliedFilters:
          deleteSearchAndFilterAppliedFilter,
        dispatchDeleteAppliedFilter: deleteAppliedFilter,
        dispatchPopNavigationIds: popCurrentNavigationIds,
      },
    },
    [FILTER_TRIGGER_CONTEXT.SLIDER]: {
      selectors: {
        currentOptionsList: sliderSearchAndFilterOptionsListSelector,
        currentLevel: sliderSearchAndFilterOptionsCurrentLevelSelector,
        paramKey: sliderSearchAndFilterParamKeySelector,
        searchAndFilterCommonStoreStructure:
          sliderSearchAndFilterCommonStructureSelector,
        currentNavigationIds: sliderSearchAndFilterCurrentNavigationIdsSelector,
      },
      dispatcherFunc: {
        dispatchSearchAndFilterOptionFunc: setSliderSearchAndFilterOptions,
        dispatchSearchAndFilterOptionsLevelCountFunc:
          setSliderSearchAndFilterOptionsLevelCount,
        dispatchSearchAndFilterParamKey: setSliderSearchAndFilterParamKey,
        dispatchSetCurrentNavigationIds: setSliderCurrentNavigationIds,
        dispatchSetCommonStructureFunc:
          setSliderSearchAndFilterCommonStoreStructure,
        dispatchSearchInputTextFunc: setSliderSearchInputText,
        dispatchResetCurrentNavigationsIds: resetSliderCurrentNavagationIds,
        dispatchDeleteSearchAndFilterAppliedFilters:
          deleteSliderSearchAndFilterAppliedFilter,
        dispatchDeleteAppliedFilter: deleteSliderAppliedFilters,
        dispatchPopNavigationIds: popSliderCurrentNavigationIds,
      },
    },
  };

  const currentOptionsList = useSelector(
    COMPONENT_CONTEXT_CONFIG[context]?.selectors.currentOptionsList
  );

  const currentLevel = useSelector(
    COMPONENT_CONTEXT_CONFIG[context]?.selectors.currentLevel
  );

  const paramKey = useSelector(
    COMPONENT_CONTEXT_CONFIG[context]?.selectors.paramKey
  );

  const searchAndFilterCommonStoreStructure = useSelector(
    COMPONENT_CONTEXT_CONFIG[context]?.selectors
      .searchAndFilterCommonStoreStructure
  );
  const currentNavigationIds =
    useSelector(
      COMPONENT_CONTEXT_CONFIG[context]?.selectors.currentNavigationIds
    ) ?? [];
  const dispatchSearchAndFilterOptionFunc =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc
      .dispatchSearchAndFilterOptionFunc;

  const dispatchSearchAndFilterOptionsLevelCountFunc =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc
      .dispatchSearchAndFilterOptionsLevelCountFunc;

  const dispatchSearchAndFilterParamKey =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc
      .dispatchSearchAndFilterParamKey;

  const dispatchSetCurrentNavigationIds =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc
      .dispatchSetCurrentNavigationIds;

  const dispatchPopNavigationIds =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc.dispatchPopNavigationIds;

  const dispatchSetCommonStructureFunc =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc
      .dispatchSetCommonStructureFunc;

  const dispatchSearchInputTextFunc =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc
      .dispatchSearchInputTextFunc;

  const dispatchResetCurrentNavigationsIds =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc
      .dispatchResetCurrentNavigationsIds;

  const dispatchDeleteSearchAndFilterAppliedFilters =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc
      .dispatchDeleteSearchAndFilterAppliedFilters;

  const dispatchDeleteAppliedFilter =
    COMPONENT_CONTEXT_CONFIG[context]?.dispatcherFunc
      .dispatchDeleteAppliedFilter;

  const FilterComponent = useMemo(() => {
    return (
      <SearchFilterInputOptionsComponent
        handleOptionClick={handleOptionClick}
        handleClosePopup={handleClosePopup}
        currentNavigationIds={currentNavigationIds}
        handleFocusClick={fetchSearchAndFilterCategoriesOptions}
        triggerComponent={(args) => SearchInputComponent(args)}
        context={context}
        classes={classes}
      />
    );
  }, [currentLevel, currentNavigationIds, sliderConfig]);

  useEffect(() => {
    if (Object.values(searchAndFilterCommonStoreStructure)?.length > 0) {
      formatCommonStructureForApiParams();
    }
  }, [searchAndFilterCommonStoreStructure]);

  function onSuccess(data, key, parentNavInfo) {
    const {
      processOptions = false,
      uiType = SEARCH_FILTER_OPTIONS_UI_TYPE.DEFAULT,
      getDynamicOptionUi = () => {},
      getProcessedOptions = () => {},
    } = CATEGORIES_LEVELS_CONFIG?.[key]?.[currentLevel] || {
      processOptions: false,
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DEFAULT,
      getDynamicOptionUi: () => {},
      getProcessedOptions: () => {},
    };

    if (data) {
      const requiredOptions = getOptions(
        data,
        uiType === SEARCH_FILTER_OPTIONS_UI_TYPE.DYNAMIC,
        getDynamicOptionUi,
        parentNavInfo
      );

      if (!processOptions) {
        addNewLevelOptions(requiredOptions);
      } else {
        const processedOptions = getProcessedOptions(requiredOptions);
        addNewLevelOptions(processedOptions);
      }
    }
  }

  function getOptions(
    nextLevelOptions,
    isDynamicUi,
    getDynamicOptionUi,
    parentNavInfo
  ) {
    if (isDynamicUi) {
      return nextLevelOptions?.map((optionProps, index) => ({
        ...getDynamicOptionUi(optionProps, parentNavInfo, t),
        index,
      }));
    }
    return nextLevelOptions.map((option, index) => ({ ...option, index }));
  }

  function addNewLevelOptions(nextLevelOptions) {
    dispatch(dispatchSearchAndFilterOptionFunc(nextLevelOptions));
    dispatch(dispatchSearchAndFilterOptionsLevelCountFunc(currentLevel + 1));
  }

  function handleOptionClick(optionInfo) {
    const optionValues = optionInfo?.values ?? {};
    const isLevelMultiSelect = isCurrentOptionLevelMultiselect(
      currentLevel,
      currentNavigationIds
    );

    const optionKey = optionValues?.key;
    const optionUrl = optionValues?.url;
    const optionLabel = optionValues?.label || optionValues?.name;
    const params = optionValues?.params;
    const selectedOptionsArray = optionValues?.options;
    const optionIndex = optionInfo?.optionIndex;
    const hasNextNavOptions = selectedOptionsArray || optionUrl;
    const generateCustomParamsForAppliedFilters =
      CATEGORIES_LEVELS_CONFIG?.[paramKey]?.[currentLevel - 1]?.generateParams;

    if (hasNextNavOptions) {
      inputRef.current.focus();
    }

    if (generateCustomParamsForAppliedFilters) {
      dispatch(
        dispatchSearchAndFilterParamKey(
          generateCustomParamsForAppliedFilters(optionInfo)
        )
      );
    } else if (currentLevel === 1) {
      dispatch(dispatchSearchAndFilterParamKey(optionKey));
    }

    if (hasNextNavOptions) {
      if (selectedOptionsArray) {
        addNewLevelOptions(selectedOptionsArray);
      } else if (optionUrl) {
        dispatch(
          getCategoryOptions({
            params,
            url: optionUrl,
            onSuccess: (nextLevelOptions) => {
              onSuccess(nextLevelOptions, optionKey, optionValues);
            },
          })
        );
      }

      let dispatchObject = null;

      if (currentNavigationIds.length <= 0) {
        dispatchObject = {
          key: optionKey,
          levelPosition: `L.${optionIndex}`,
          label: optionLabel,
          isLastLevelMultiSelect:
            optionValues?.filter_type ===
            camelToSnake(SEARCH_FILTER_OPTIONS_SELECT_TYPE.MUTLI_SELECT),
          totalLevels: (optionValues.level ?? 0) + 1,
          options: currentOptionsList,
        };
        deleteSearchParam();
      } else {
        dispatchObject = {
          levelPosition: `${
            currentNavigationIds[currentNavigationIds.length - 1]?.levelPosition
          }.${optionIndex}`,
          label: optionLabel,
          options: currentOptionsList,
        };
      }
      if (dispatchObject) {
        dispatch(dispatchSetCurrentNavigationIds(dispatchObject));
      }
    } else {
      const selectedOptionValue = !isLevelMultiSelect
        ? optionValues?.id || optionValues?.value
        : optionValues;

      if (
        isLevelMultiSelect &&
        (!selectedOptionValue || Object.values(selectedOptionValue).length <= 0)
      ) {
        dispatch(dispatchDeleteAppliedFilter(paramKey));
        dispatch(
          dispatchDeleteSearchAndFilterAppliedFilters(
            `${currentNavigationIds[currentNavigationIds.length - 1]?.label}`
          )
        );
      } else {
        dispatch(
          dispatchSetCommonStructureFunc({
            [[
              `${currentNavigationIds[currentNavigationIds.length - 1]?.label}`,
            ]]: {
              levelPosition:
                currentNavigationIds[currentNavigationIds.length - 1]
                  ?.levelPosition,
              param: paramKey,
              filterLabel:
                optionValues?.label ||
                optionValues?.title ||
                optionValues?.name,
              values: !isLevelMultiSelect
                ? optionValues?.id || optionValues?.value
                : optionValues,
            },
          })
        );
      }
    }

    dispatch(dispatchSearchInputTextFunc(""));
  }

  function formatCommonStructureForApiParams() {
    const paramsStructure = getCommonStructureForApiToParams(
      searchAndFilterCommonStoreStructure
    );

    handleFilter({
      ...paramsStructure,
    });

    return {
      [AVAILABLE_FILTER_KEYS.searchAndFilter]: paramsStructure,
    };
  }

  function handleClosePopup() {
    dispatch(dispatchSearchAndFilterOptionFunc([]));
    dispatch(dispatchSearchAndFilterOptionsLevelCountFunc(0));
    dispatch(dispatchResetCurrentNavigationsIds());
    dispatch(dispatchSearchAndFilterParamKey(null));
  }

  function fetchSearchAndFilterCategoriesOptions() {
    if (currentNavigationIds.length <= 0) {
      const params = { module: moduleValue };
      if (status) {
        params.status = status;
      }

      dispatch(
        getCategoriesForSearchFilters({
          params,
          onSuccess: addNewLevelOptions,
        })
      );
    }
  }

  function handleInputChangeForFirstLevelOptions(value) {
    if (!value) {
      deleteSearchParam();
    } else {
      dispatch(
        dispatchSetCommonStructureFunc({
          searchParam: {
            levelPosition: null,
            values: value,
            param: SEARCH_PAYLOAD_PARAM_KEY,
            showPill: false,
          },
        })
      );
    }
  }

  function handleInputChangeForOtherLevels(value) {
    if (value?.length >= 0) {
      const requiredOptions = currentOptionsList?.map((levelOption) => {
        const optionSearchKey =
          typeof levelOption?.label !== typeof ""
            ? levelOption?.name || levelOption?.title
            : levelOption?.label;

        if (
          optionSearchKey?.toLowerCase()?.indexOf(value?.toLowerCase()) >= 0
        ) {
          return { ...levelOption, show: true };
        }
        return { ...levelOption, show: false };
      });

      dispatch(dispatchSearchAndFilterOptionFunc(requiredOptions));
    }
  }

  const debounceHandleInputChangeForFirstLevel = debounce(
    (value) => handleInputChangeForFirstLevelOptions(value),
    1000
  );

  const debounceHandleInputChangeForOtherLevels = debounce(
    (value) => handleInputChangeForOtherLevels(value),
    100
  );

  function handleInputChange(value) {
    if (currentLevel === 1 || (currentLevel <= 0 && onlyTextSearch)) {
      debounceHandleInputChangeForFirstLevel(value);
    } else {
      debounceHandleInputChangeForOtherLevels(value);
    }
  }

  function handleBackspaceClickEventOnInputTag(
    searchInputText,
    clearCategoryIds
  ) {
    if (currentLevel > 1 && searchInputText?.length <= 0) {
      const previousLevelOptions =
        currentNavigationIds?.[currentNavigationIds.length - 1]?.options;

      clearCategoryIds();
      dispatch(dispatchSearchAndFilterOptionFunc(previousLevelOptions));
      dispatch(dispatchSearchAndFilterOptionsLevelCountFunc(currentLevel - 1));

      dispatch(dispatchPopNavigationIds());
    }
  }

  function handleKeywordClickEventOnInputTag(
    event,
    clearCategoryIds,
    togglePopup
  ) {
    const searchInputText = event?.target?.value;

    if (event?.key === BACKSPACE_KEY && currentLevel > 1) {
      handleBackspaceClickEventOnInputTag(searchInputText, clearCategoryIds);
    } else if (event?.key === ENTER_KEY && currentLevel === 1) {
      togglePopup(!(searchInputText?.length > 0));
      dispatch(dispatchSearchAndFilterOptionsLevelCountFunc(0));
      inputRef?.current?.blur();
    }
  }

  function deleteSearchParam() {
    dispatch(dispatchDeleteSearchAndFilterAppliedFilters(SEARCH_PARAM_KEY));
    dispatch(dispatchDeleteAppliedFilter(SEARCH_PAYLOAD_PARAM_KEY));
  }

  function SearchInputComponent(props) {
    const {
      searchInputText,
      showBorderFocusedColor,
      clearCategoryIds,
      togglePopup,
    } = props;
    const showNavText = currentNavigationIds.length > 0;

    const isFetchingFilters = useSelector(isFetchingFiltersSelector);

    return (
      <div
        className={`flex flex-row mr-2 items-center  px-4 py-3 border rounded-lg ${
          !classes?.includes("w-") ? "w-115" : ""
        } ${classes} caret-primary search--inactive focus-within:search--active  ${
          showBorderFocusedColor ? "border-primary-500" : ""
        }`}
      >
        <div>
          <Icon name="Search" className="mr-2 text-neutral" />
        </div>

        {showNavText ? (
          <SearchAndFilterUpperPillSection
            currentNavigationIds={currentNavigationIds}
          />
        ) : null}

        {/* Disabling the input, when filters are getting fetched  */}
        <input
          ref={inputRef}
          type="text"
          disabled={isFetchingFilters}
          value={searchInputText}
          placeholder={
            isFetchingFilters
              ? t("cards.editLimit.fetchingText")
              : t("search.searchAndFilter")
          }
          className="w-full ml-1 text-sm tracking-wide focus:border-none focus:outline-none"
          onKeyDown={(e) => {
            handleKeywordClickEventOnInputTag(e, clearCategoryIds, togglePopup);
          }}
          onChange={(e) => {
            dispatch(dispatchSearchInputTextFunc(e?.target?.value));
            handleInputChange(e?.target?.value);
          }}
        />
      </div>
    );
  }

  return FilterComponent;
}

SearchInput.propTypes = {
  query: PropTypes.string,
  handleSearch: PropTypes.func,
  options: PropTypes.array,
  filterSetter: PropTypes.func,
  handleFilter: PropTypes.func,
  context: PropTypes.string,
  sliderConfig: PropTypes.object,
  classes: PropTypes.string,
};
