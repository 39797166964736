import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import Icon from "../Icon";
import Text from "../Text";

/**
 * Is for getting Accordion with content/body.
 * @param {array} items -  list of accordion you want to list with json {label/renderTitle: to display title, renderContent: body of accordion, id: key }
 * @param {boolean} keepOthersOpen - defaultValue "false" since accordion will close while other is opened.
 * @param {string} classes - passing any classes within the accordion
 * @param {string} type -  defaultValue "default" which will simply show title and arrow, card for showing it as card component and content within the card
 * @return {Component} Accordion component with title and content to show multiple accordion or only one depends on the array of items you pass.
 */

const Accordion = ({
  items = [],
  keepOthersOpen,
  classes,
  type = "default",
  accordionParentClass,
}) => {
  const [accordionItems, setAccordionItems] = useState(null);

  useEffect(() => {
    if (items) {
      setAccordionItems(items.map((item) => ({ ...item, toggled: false })));
    }
  }, [items]);

  function handleAccordionToggle(clickedItem) {
    setAccordionItems([
      ...accordionItems.map((item) => {
        let { toggled } = item;
        if (clickedItem.id === item.id) {
          toggled = !item.toggled;
        } else if (!keepOthersOpen) {
          toggled = false;
        }

        return { ...item, toggled };
      }),
    ]);
  }

  return (
    <div className={`accordion-parent ${accordionParentClass}`}>
      {accordionItems?.map((listItem, key) => {
        return (
          <div
            className={`accordion ${
              type === "card" ? "card-wrapper flex flex-col" : ""
            } ${listItem.toggled ? "toggled" : ""} ${classes}`}
            key={key}
          >
            <div
              className="w-full toggle"
              onClick={() => handleAccordionToggle(listItem)}
            >
              {listItem.label ? (
                <div
                  className={`flex w-full justify-between ${listItem.labelsClass}`}
                >
                  <div className="flex items-center labelClass">
                    <Text translationKey={listItem.label} />
                    <Icon
                      name={listItem.toggled ? "UpArrow" : "DownArrow"}
                      className="ml-2 "
                    />
                  </div>
                  {listItem?.rightLabelText ? (
                    <Text
                      translationKey={listItem?.rightLabelText}
                      classes={listItem?.rightLabelClasses}
                    />
                  ) : null}
                </div>
              ) : (
                <div className="w-full content">
                  {listItem ? listItem.renderTitle() : null}
                </div>
              )}
              {type === "card" && (
                <Icon
                  name={listItem.toggled ? "UpArrow" : "DownArrow"}
                  className=""
                />
              )}
            </div>
            <div className="content-parent">
              <div className="content">
                {listItem && listItem.renderContent()}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  items: PropTypes.array,
  keepOthersOpen: PropTypes.bool,
  classes: PropTypes.string,
  type: PropTypes.string,
  accordionParentClass: PropTypes.string,
};
