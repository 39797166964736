import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchQuoteForPurchaseBill } from "@/store/reducers/purchase-bills";

import { currentPaymentProviderSelector } from "@/store/selectors/client";
import {
  isFetchingPurchaseBillQuoteSelector,
  purchaseBillQuoteSelector,
} from "@/store/selectors/purchase-bills";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import AmountToBePaid from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/AmountToBePaid";
import QuoteExchangeRate from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/QuoteExchangeRate";
import {
  GENERATE_QUOTE_API_BODY_KEYS_REQUEST,
  GENERATE_QUOTE_API_BODY_KEYS_RESPONSE,
  LINE_ITEM_KEY,
  LINE_ITEM_KEYS,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";

import { FIXED_SIDE } from "@/constants/currency";
import { PAYMENT_PROVIDERS } from "@/constants/provider";

/**
 * Renders multiple things
 * 1. Exchange rate
 * 2. Amount to be paid box
 * 3. TnC for exchange rate
 *
 * Handles quote API calls, including usual "buy" and "sell" (you send they receive flow)
 */
export default function QuoteSection({
  isYSTRPotentiallyApplicable,
  isYSTRApplicable,
  values,
  errors,
  handleChange,
  setValues,
  senderCurrency,
  beneficiaryCurrency,
  vendor, // Vendor is not whole object in ReimbursementPayNow only id is there because that only used here and we don't have whole object in that page
  fromReimbursement = false,
  quoteAmount,
  preciseQuoteAmount,
  hideAsPerProvider = false,
  isPayOutsideVolopay,
  classes,
  tooltipText,
  tooltipTextProps,
  inPayrollContext = false,
}) {
  const dispatch = useDispatch();
  const isFetchingQuote = useSelector(isFetchingPurchaseBillQuoteSelector);

  /**
   * Quote (dependencies - i.e. need to call it when any of these change)
   *
   * 1. Quote (the `quote` variable above)
   * 2. Sender currency - derives from selected Payment account
   * 3. Beneficiary currency - derives from selected vendor
   * 4. Payment method (null is fine, initially - i.e. if nothing is selected)
   * 5. Vendor id
   */
  // const vendor = useSelector(selectedVendorSelector);
  const vendorSelected = vendor?.id;
  const currentPaymentProvider = useSelector(currentPaymentProviderSelector);
  const isSendPaymentChannel =
    currentPaymentProvider !== PAYMENT_PROVIDERS.XENDIT; // no need to send method in quote, for ID
  const isYSTRInCharge = values?.isYSTRInCharge;
  const quoteFromAPI = useSelector(purchaseBillQuoteSelector); // relevant on submit only

  const payloadAmount = isYSTRInCharge
    ? Number(values?.ystrSendAmount || 0)
    : Number(preciseQuoteAmount) || Number(quoteAmount);

  const exchangeRate =
    quoteFromAPI?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.RATE] ?? 0;
  const transferFees =
    quoteFromAPI?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.TRANSFER_FEES] ?? 0;
  const finalAmount =
    quoteFromAPI?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.TOTAL_AMOUNT] ?? 0;

  const isShowExchangeRate = true; // senderCurrency === beneficiaryCurrency; // always show

  // TODO: debounce line item inputs
  const quoteUseEffectCall = () => {
    if (!vendorSelected) return; // vendor not selected yet

    const payload = {
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.AMOUNT]: payloadAmount?.toFixed(3),
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.BUY_CURRENCY]: beneficiaryCurrency,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.SELL_CURRENCY]: senderCurrency,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.VENDOR_ID]: vendor?.id,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.FIXED_SIDE]: isYSTRInCharge
        ? FIXED_SIDE.SELL
        : FIXED_SIDE.BUY, // not needed for v2, leave as is
      ...(isSendPaymentChannel
        ? {
            [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.PAYMENT_CHANNEL]:
              values?.payoutMethodType,
          }
        : {}),
      ...(isPayOutsideVolopay && !fromReimbursement
        ? {
            [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.PAY_OUTSIDE_VOLOPAY]: true,
          }
        : {}),
    };

    if (
      payloadAmount &&
      +payloadAmount !== 0 &&
      senderCurrency &&
      beneficiaryCurrency &&
      (isSendPaymentChannel ? values.payoutMethodType : true) &&
      vendor?.id
    ) {
      const onQuoteSuccess = (data) => {
        if (!isYSTRInCharge) {
          setValues((prev) => ({
            ...prev,
            ystrSendAmount: 0, // to avoid YSTR sell quote calls. (it will display buy result instead of zero, so fine)
          }));
          return;
        }

        setValues((prev) => {
          const lineItemAmountKey = Object.keys(prev).find(
            (k) =>
              k?.startsWith(`${LINE_ITEM_KEY}.`) &&
              k?.endsWith(`.${LINE_ITEM_KEYS.AMOUNT}`) &&
              !prev[
                k.replace(
                  `.${LINE_ITEM_KEYS.AMOUNT}`,
                  `.${LINE_ITEM_KEYS._DESTROY}`
                )
              ] // _destroy should not be true
          ); // handles both billpay and payroll, both first amount field name is the same

          if (!lineItemAmountKey) {
            console.warn(
              "Cannot prefill sell quote into line item/payroll item. This should not happen."
            );
          }
          return {
            ...prev,
            ...(inPayrollContext
              ? {
                  [`${LINE_ITEM_KEY}.0.${LINE_ITEM_KEYS.AMOUNT}`]:
                    data?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.TO_AMOUNT],
                }
              : {
                  [lineItemAmountKey]:
                    data?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.TO_AMOUNT],
                }),
          };
        });
      };

      dispatch(
        fetchQuoteForPurchaseBill({
          payload,
          onSuccess: onQuoteSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (fromReimbursement) {
      quoteUseEffectCall(); // No debounce needed for reimb. quote API is not called at claim creation time, and we only call during payment time (and here input is fixed, i.e. disabled)
    } else {
      const delayDebounceFn = setTimeout(() => {
        quoteUseEffectCall();
      }, 1200);

      return () => (fromReimbursement ? null : clearTimeout(delayDebounceFn));
    }
  }, [
    isPayOutsideVolopay,
    payloadAmount,
    senderCurrency,
    beneficiaryCurrency,
    values.payoutMethodType,
    vendor?.id,
    isYSTRInCharge,
  ]);

  return hideAsPerProvider ? null : (
    <div className={classes}>
      {isShowExchangeRate ? (
        <div className="m-6">
          {isFetchingQuote ? (
            <LoaderSkeleton size={[30, 424]} />
          ) : quoteFromAPI ? (
            <QuoteExchangeRate
              senderCurrency={senderCurrency}
              beneficiaryCurrency={beneficiaryCurrency}
              exchangeRate={exchangeRate}
              transferFees={transferFees}
              isFetching={isFetchingQuote}
              isShowTransferFees={!isPayOutsideVolopay}
            />
          ) : null}
        </div>
      ) : null}
      <div className="mt-4">
        {isFetchingQuote ? (
          <div className="flex flex-col card-wrapper">
            <LoaderSkeleton />
            <LoaderSkeleton size={[30, 50]} />
          </div>
        ) : (
          <AmountToBePaid
            isPayOutsideVolopay={isPayOutsideVolopay}
            currency={fromReimbursement ? beneficiaryCurrency : senderCurrency}
            amount={
              fromReimbursement ? quoteAmount : finalAmount ? finalAmount : 0
            }
            titleText={
              fromReimbursement
                ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.amountReceived"
                : "billPay.bill.invoiceInbox.createBill.sections.lineItems.amountToBePaid"
            }
            tooltipText={tooltipText}
            tooltipTextProps={tooltipTextProps}
            descriptionText={
              isYSTRPotentiallyApplicable
                ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.youAreSendingThisAmount"
                : ""
            }
            isEditable={isYSTRPotentiallyApplicable}
            isEditableDisabled={!isYSTRApplicable}
            fieldName="ystrSendAmount"
            values={values}
            errors={errors}
            handleChange={handleChange}
            setValues={setValues}
          />
        )}
      </div>

      {hideAsPerProvider ? null : (
        <div className="mt-2">
          <Text
            translationKey="reimbursement.createPayment.exchangeRateDesc"
            classes="text-neutral-500 text-xs "
          />
        </div>
      )}
    </div>
  );
}

QuoteSection.propTypes = {
  isYSTRPotentiallyApplicable: PropTypes.bool,
  isYSTRApplicable: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  vendor: PropTypes.object,
  senderCurrency: PropTypes.string,
  beneficiaryCurrency: PropTypes.string,
  fromReimbursement: PropTypes.bool,
  quoteAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preciseQuoteAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideAsPerProvider: PropTypes.bool,
  isPayOutsideVolopay: PropTypes.bool,
  classes: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTextProps: PropTypes.object,
  inPayrollContext: PropTypes.bool,
};
