import PropTypes from "prop-types";
import React, { useMemo } from "react";

import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import CardTableComponent from "./CardTableComponents";
import CardTableLoader from "./CardTableLoader";

function CardsTable({
  hasMore,
  handleRefChange,
  isFetchingData,
  headers,
  isDepartmentClient,
  handleRowClick,
  emptyDataTitle,
  emptyDataDescription,
  emptyDataChildren,
  classes,
  data = [],
}) {
  const isEmpty = data.length === 0;
  const updatedHeaders = useMemo(
    () =>
      headers?.filter(
        (header) => !header?.dependsOnDepartmentClient && !isDepartmentClient
      ),
    [headers]
  );

  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: updatedHeaders?.map((header) => header?.colWidth),
    emptyDataTitle,
    emptyDataDescription,
    emptyDataChildren,
  };

  return (
    <div>
      <Table {...tableArgs}>
        <tr>
          {updatedHeaders?.map((header, index) => (
            <th
              className={`text-xs font-semibold text-left ${classes}`}
              key={`${header.label}-${index}`}
            >
              <Text translationKey={header.label} />
            </th>
          ))}
        </tr>

        {!isFetchingData || !isEmpty
          ? data?.map((dataRow, index) => (
              <tr
                onClick={() => handleRowClick(dataRow.id)}
                ref={(ref) => {
                  if (data.length - 1 === index && hasMore)
                    return handleRefChange(ref);
                }}
                className="cursor-pointer"
                key={dataRow.id}
              >
                {Object.keys(dataRow)
                  ?.filter((dataSingleColumnId) =>
                    updatedHeaders
                      ?.map((val) => val?.id)
                      ?.includes(dataSingleColumnId)
                  )
                  ?.map((dataSingleColumnId) => (
                    <td
                      key={`card-table-col-${dataSingleColumnId}`}
                      className="text-sm font-semibold text-neutral-800"
                    >
                      <CardTableComponent
                        componentId={dataSingleColumnId}
                        componentProps={dataRow[dataSingleColumnId]}
                      />
                    </td>
                  ))}
              </tr>
            ))
          : null}
        {isFetchingData && <CardTableLoader header={updatedHeaders} />}
      </Table>
    </div>
  );
}

export default CardsTable;
CardsTable.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFetchingData: PropTypes.bool,
  isDepartmentClient: PropTypes.bool,
  hasMore: PropTypes.bool,
  handleRefChange: PropTypes.func,
  handleRowClick: PropTypes.func,
  emptyDataTitle: PropTypes.string,
  classes: PropTypes.string,
  emptyDataDescription: PropTypes.string,
  emptyDataChildren: PropTypes.node,
};
