import { DEPARTMENT } from "./company";

export const ROLE_TITLES = {
  ADMIN: "Admin",
  PAYROLL_ADMIN: "payroll_admin",
  EMPLOYEE: "employee",
  INTERNAL_ACCOUNTANT: "internal_accountant",
  ACCOUNTANT: "accountant",
  AP_CLERK: "ap_clerk",
  DEPARTMENT_MANAGER: "department_manager",
  MEMBER: "member",
  PAYROLL_MANAGER: "payroll_manager",
  ADMIN_CAPS: "Admin",
  EMPLOYEE_CAPS: "Employee",
  MEMBER_CAPS: "Member",
};

export const OWNER_TYPE_USER = "user";
export const TRUSTED_DEVICE_ID = "trustedDeviceId";
export const LAST_ACTION_TIMESTAMP = "lastActionTimestamp";

export const CONSIDER_INACTIVITY_AFTER = 30; // minutes
export const LOGOUT_AFTER_INACTIVITY = 60; // seconds

export const DEVICES = {
  PC: "pc",
  MOBILE: "mobile",
};

export const TITLE = [
  { value: "Ms", key: "ms" },
  { value: "Mr", key: "mr" },
  { value: "Mrs", key: "mrs" },
];

export const GENDER = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const ROLE_TITLES_LABELS = {
  [ROLE_TITLES.ADMIN]: "user.roles.admin",
  [ROLE_TITLES.EMPLOYEE]: "user.roles.employee",
  [ROLE_TITLES.PAYROLL_ADMIN]: "user.roles.payrollAdmin",
  [ROLE_TITLES.PAYROLL_MANAGER]: "user.roles.payrollManager",
  [ROLE_TITLES.INTERNAL_ACCOUNTANT]: "user.roles.internal_accountant",
  [ROLE_TITLES.ACCOUNTANT]: "user.roles.accountant",
  [ROLE_TITLES.AP_CLERK]: "user.roles.ap_clerk",
  [ROLE_TITLES.DEPARTMENT_MANAGER]: "user.roles.department_manager",
  [ROLE_TITLES.MEMBER]: "user.roles.member",
  [ROLE_TITLES.ADMIN_CAPS]: "user.roles.admin",
  [ROLE_TITLES.MEMBER_CAPS]: "user.roles.member",
  [ROLE_TITLES.EMPLOYEE_CAPS]: "user.roles.employee",
};

export const ROLE_TITLES_LABELS_PLURAL = {
  [ROLE_TITLES.ADMIN]: "user.roles.adminPlural",
  [ROLE_TITLES.PAYROLL_ADMIN]: "user.roles.payrollAdminPlural",
  [ROLE_TITLES.PAYROLL_MANAGER]: "user.roles.payrollManagerPlural",
  [ROLE_TITLES.EMPLOYEE]: "user.roles.employeePlural",
  [ROLE_TITLES.INTERNAL_ACCOUNTANT]: "user.roles.internal_accountantPlural",
  [ROLE_TITLES.ACCOUNTANT]: "user.roles.accountantPlural",
  [ROLE_TITLES.AP_CLERK]: "user.roles.ap_clerkPlural",
  [ROLE_TITLES.DEPARTMENT_MANAGER]: "user.roles.department_managerPlural",
  [ROLE_TITLES.MEMBER]: "user.roles.memberPlural",
};
export const ROLE_TITLE = {
  [ROLE_TITLES.ADMIN]: "Admin",
  [ROLE_TITLES.EMPLOYEE]: "Employee",
  [ROLE_TITLES.INTERNAL_ACCOUNTANT]: "Internal accountant",
  [ROLE_TITLES.ACCOUNTANT]: "Accountant",
  [ROLE_TITLES.MEMBER]: "Member",
};

export const PERMISSIONS = {
  EXPENSE_REVIEW: "expense:review",
  CARD_MANAGE: "card:manage",
  CARD_SETTINGS_MANAGE: "card_settings",
  EXPENSE_MANAGE: "expense:manage",
  COMPANY_MANAGE_ACCOUNT: "company:manage_accounts",
  COMPANY_MANAGE_PEOPLE_AND_LOCATION: "company:manage_people_and_locations",
  COMPANY_MANAGE_DEPARTMENT: "company:manage_department",
  COMPANY_MANAGE_PROJECT: "company:manage_project",
  COMPANY_MANAGE_TRAVEL: "company:manage_travel",
  ACCOUNTING_MANAGE: "accounting:manage",
  REIMBURSEMENT_MANAGE: "reimbursement:manage",
  REIMBURSEMENT_SETTLE: "reimbursement:settle",
  REIMBURSEMENT_SETTINGS_MANAGE: "reimbursement_settings:manage",
  BILLPAY_MANAGE: "billpay:manage",
  BILLPAY_MAINTAIN: "billpay:maintain",
  BILLPAY_AUTHORIZE_PAYMENT: "billpay:authorize_payment",
  BILLPAY_SETTINGS_MANAGE: "billpay_settings:manage",
  PAYROLL_MANAGE: "payroll:manage",
  PAYROLL_AUTHORIZE_PAYMENT: "payroll:authorize_payment",
  PAYROLL_MAINTAIN: "payroll:maintain",
  PAYROLL_ADMINISTRATE: "payroll:administrate",
  PAYROLL_ACCESS: "payroll:access",
  GLOBAL_SETTINGS_MANAGE: "global_settings:manage",
  QRPAY_MANAGE: "qrpay:manage",
  QRWALLET_MANAGE: "qrwallet:manage",
  QRPAY_SETTINGS_MANAGE: "qrpay_settings:manage",
};

export const LOGIN_ERROR_TYPES = {
  INVALID_OTP: "INVALID_OTP",
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  INVALID_CURRENT_PASSWORD: "INVALID_CURRENT_PASSWORD",
  ALREADY_USED_PASSWORD: "ALREADY_USED_PASSWORD",
};

export const PASSWORD_UPDATE_ERROR = {
  [LOGIN_ERROR_TYPES.ALREADY_USED_PASSWORD]: "errorMessage.passwordAlreadyUsed",
  [LOGIN_ERROR_TYPES.TOKEN_EXPIRED]: "errorMessage.tokenExpired",
  [LOGIN_ERROR_TYPES.INVALID_CURRENT_PASSWORD]:
    "errorMessage.invalidCurrentPassword",
  [LOGIN_ERROR_TYPES.INVALID_OTP]: "errorMessage.invalidOtp",
};

export const SESSION_ERROR_TYPES = {
  ACCOUNT_LOCKED: "ACCOUNT_LOCKED",
  ADMIN_FREEZED: "ADMIN_FREEZED",
  ADMIN_UNFREEZED: "ADMIN_UNFREEZED",
  ACCOUNT_BLOCKED: "ACCOUNT_BLOCKED",
  INACTIVITY_LOCKED: "INACTIVITY_LOCKED",
  OTP_RETRY_COUNT_EXHAUSTED: "OTP_RETRY_COUNT_EXHAUSTED",
  INVALID_AUTH_CODE: "INVALID_AUTH_CODE",
  INVALID_OTP: "INVALID_OTP",
  COMPANY_ACCOUNT_LOCKED: "COMPANY_ACCOUNT_LOCKED",
};

export const SESSION_ERROR_MESSAGE = {
  [SESSION_ERROR_TYPES.ACCOUNT_LOCKED]: "errorMessage.accountLocked",
  [SESSION_ERROR_TYPES.ACCOUNT_BLOCKED]: "errorMessage.accountBlocked",
  [SESSION_ERROR_TYPES.ADMIN_FREEZED]: "errorMessage.adminFreezed",
  [SESSION_ERROR_TYPES.ADMIN_UNFREEZED]: "errorMessage.adminUnfreezed",
  [SESSION_ERROR_TYPES.INACTIVITY_LOCKED]: "errorMessage.inactivityLocked",
  [SESSION_ERROR_TYPES.OTP_RETRY_COUNT_EXHAUSTED]:
    "errorMessage.otpRetryCountExhausted",
  [SESSION_ERROR_TYPES.INVALID_AUTH_CODE]: "errorMessage.invalidAuthCode",
  [SESSION_ERROR_TYPES.INVALID_OTP]: "errorMessage.invalidOtp",
  [SESSION_ERROR_TYPES.COMPANY_ACCOUNT_LOCKED]:
    "errorMessage.companyAccountLocked",
};
export const USER_ACCESS_CONTANTS = {
  LOCKED: "locked",
  FREEZED: "freezed",
  BLOCKED: "blocked",
  UNFREEZED: "unfreezed",
  UNLOCKED: "unlocked",
};

export const USER_ACCESS_SETTINGS_KEYS = {
  [USER_ACCESS_CONTANTS.LOCKED]: "lockUser",
  [USER_ACCESS_CONTANTS.FREEZED]: "freezeUser",
  [USER_ACCESS_CONTANTS.UNFREEZED]: "unfreezeUser",
  [USER_ACCESS_CONTANTS.BLOCKED]: "blockUser",
  [USER_ACCESS_CONTANTS.UNLOCKED]: "unlockUser",
};

export const EDIT_PROFILE_CTA_KEY = "editProfile";

export const EDIT = "edit";
export const READ_ONLY = "readOnly";

export const MY_PROFILE_CONTEXT = "myProfile";

export const USER_STATUS = {
  ACTIVE: "active",
  BLOCKED: "blocked",
  PENDING: "pending",
  SUBMITTED: "submitted",
  REJECTED: "rejected",
  ARCHIVED: "archived",
  LOCKED: "locked",
  FREEZED: "freezed",
};

export const KYC_STATE = {
  APPROVED: "approved",
};

export const USER_STATUS_CTA_MAP = {
  [USER_STATUS.ACTIVE]: {
    text: "company.people.userStatus.active",
    color: "success",
  },
  [USER_STATUS.BLOCKED]: {
    text: "company.people.userStatus.blocked",
    color: "danger",
  },
  [USER_STATUS.PENDING]: {
    text: "company.people.userStatus.pending",
    color: "warning",
  },
  [USER_STATUS.LOCKED]: {
    text: "company.people.userStatus.locked",
    color: "warning",
  },
  [USER_STATUS.FREEZED]: {
    text: "company.people.userStatus.freezed",
    color: "warning",
  },
  [USER_STATUS.SUBMITTED]: {
    text: "company.people.userStatus.submitted",
    color: "warning",
  },
};

export const EDIT_USER_DEPARTMENT_LOCATION_TITLES = {
  DEPARTMENT: "department",
  LOCATION: "location",
};

export const SECURITY_SETTINGS_VERIFICATION_MODES = {
  MOBILE: "mobile",
  EMAIL: "email",
  GAUTH: "gauth",
};

export const USER_INBOX_ITEM_STATUS = {
  READ: "read",
  UNREAD: "unread",
};

export const USER_INBOX_TABS = [
  {
    name: "user.notificationsAndMessages.notification.title",
    key: 0,
    count: 0,
  },
  {
    name: "user.notificationsAndMessages.messages.title",
    key: 1,
    count: 0,
  },
];

export const VP_ADDRESS_DETAILS_CONFIG = {
  ADDRESS_LINE_1: "address_1",
  ADDRESS_LINE_2: "address_2",
  ADDRESS_LINE1: "address1",
  ADDRESS_LINE2: "address2",
  ZIPCODE: "zipcode",
  CITY: "city",
  STATE_OR_PROVINCE: "state",
  COUNTRY: "country",
};

export const VP_ADDRESS_DETAILS_CONFIG_MAP = {
  [VP_ADDRESS_DETAILS_CONFIG.ADDRESS_LINE1]: "form.label.addressLine1",
  [VP_ADDRESS_DETAILS_CONFIG.ADDRESS_LINE2]: "form.label.addressLine2",
  [VP_ADDRESS_DETAILS_CONFIG.ADDRESS_LINE_1]: "form.label.addressLine1",
  [VP_ADDRESS_DETAILS_CONFIG.ADDRESS_LINE_2]: "form.label.addressLine2",
  [VP_ADDRESS_DETAILS_CONFIG.ZIPCODE]: "form.label.zipcode",
  [VP_ADDRESS_DETAILS_CONFIG.CITY]: "form.label.city",
  [VP_ADDRESS_DETAILS_CONFIG.STATE_OR_PROVINCE]: "form.label.state",
  [VP_ADDRESS_DETAILS_CONFIG.COUNTRY]: "form.label.country",
};

export const USER_ALLOWED_CTA_KEYS = {
  REQUEST_STATEMENT: "requestStatement",
  CREATE_BILL: "createBill",
  UPLOAD_INVOICE: "uploadInvoice",
  ORDER_CARD: "orderCard",
  VIRTUAL_CARD: "requestVirtualCard",
  NEW_LOCATIONS: "newLocation",
  ADD_CUSTOM_ROLE: "addCustomRole",
  INVITE_PEOPLE: "invitePeople",
  PENDING_INVITES: "pendingInvites",
  ACTIVATE_CORPORATE_TRAVEL: "activeCorporateTravel",
  DEACTIVATE_CORPORATE_TRAVEL: "deactiveCorporateTravel",
  MANAGE_PEOPLE: "managePeople",
  MY_PENDING_REQUEST: "myPendingRequest",
  CREATE_CLAIM: "createClaim",
  UPLOAD_RECEIPTS: "uploadReceipts",
  ADD_EMPLOYEE: "addEmployee",
  CREATE_SALARY_PAYMENT: "createSalaryPayment",
  BULK_UPLOAD: "bulkUpload",
  NEW_DEPARTMENT: "newDepartment",
  NEW_PROJECT: "newProject",
  DEFINE_NEW_BUDGET: "defineNewBudget",

  DEFINE_NEW_PROJECT_BUDGET: "defineNewProjectBudget",
  DEFINE_NEW_DEPT_BUDGET: "defineNewDepartmentBudget",
  CREATE_VENDOR: "createVendor",
  REQUEST_WALLET: "requestWallet",
  GLOBAL_ACCOUNTS: "globalAccount",
  REQUEST_GLOBAL_ACCOUNT: "requestGlobalAccounts",
  MANAGE_AUTO_REPAYMENT: "manageAutoRepayment",
  INCREASE_CREDIT_LINE: "increaseCreditLine",
  PAY_NOW: "payNow",
};

export const USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS = {
  DASHBOARD: "dashboard",
  DASHBOARD_CREDIT: "dashboard:credit",
  DASHBOARD_GLOBAL_ACCOUNT: "dashboard:globalAccounts",
  MANAGE_COMPANY_DEPARTMENTS: "manage:company:departments",
  MANAGE_COMPANY_PROJECTS: "manage:company:projects",
  MANAGE_COMPANY_BUDGETS: "manage:company:budgets",
};

export const BE_CTA_RESPONSE = {
  EDIT_DETAILS: "editDetails",
  DEFINE_NEW_BUDGET: "defineNewBudget",
  MANAGE_PEOPLE: "managePeople",
  EDIT_BUDGET: "editBudget",
  DELETE_BUDGET: "deleteBudget",
  PAY_NOW: "payNow",
};

export const COMMENT_TYPE = {
  USER_CREATED: "user_created",
  REPAYMENT_REQUESTED: "repayment_requested",
  REPAYMENT_CANCELLED: "repayment_cancelled",
  REPAYMENT_RECEIVED: "repayment_received",
  REPAYMENT_REJECTED: "repayment_rejected",
  FLAGGED: "flagged",
};

export const MESSAGE_TYPE = {
  VIRTUAL_CARD_REQUEST: "VirtualCardRequest",
  PHYSICAL_CARD_REQUEST: "PhysicalCardRequest",
  TOPUP_REQUEST: "TopUpRequest",
  CLAIM_REQUEST: "Claim",
  PAYROLL_REQUEST: "Payroll",
  BILL_PAYMENT_REQUEST: "PurchaseBill",
  EXPENSE_FLAGGED: "Expense",
  CARD: "Card",
  PROJECT: "Project",
  DEPARTMENT: "Department",
};

export const MESSAGE_TYPE_KEY_VALUE_MAPPING = {
  [MESSAGE_TYPE.VIRTUAL_CARD_REQUEST]:
    "user.notificationsAndMessages.messages.messageTypeTexts.virtualCardRequest",
  [MESSAGE_TYPE.PHYSICAL_CARD_REQUEST]:
    "user.notificationsAndMessages.messages.messageTypeTexts.physicalCardRequest",
  [MESSAGE_TYPE.TOPUP_REQUEST]:
    "user.notificationsAndMessages.messages.messageTypeTexts.topUpRequest",
  [MESSAGE_TYPE.CLAIM_REQUEST]:
    "user.notificationsAndMessages.messages.messageTypeTexts.claimRequest",
  [MESSAGE_TYPE.PAYROLL_REQUEST]:
    "user.notificationsAndMessages.messages.messageTypeTexts.payrollRequest",
  [MESSAGE_TYPE.BILL_PAYMENT_REQUEST]:
    "user.notificationsAndMessages.messages.messageTypeTexts.billpaymentRequest",
  [MESSAGE_TYPE.EXPENSE_FLAGGED]:
    "user.notificationsAndMessages.messages.messageTypeTexts.expenseFlagged",
  [MESSAGE_TYPE.CARD]:
    "user.notificationsAndMessages.messages.messageTypeTexts.card",
  [MESSAGE_TYPE.PROJECT]:
    "user.notificationsAndMessages.messages.messageTypeTexts.project",
  [MESSAGE_TYPE.DEPARTMENT]:
    "user.notificationsAndMessages.messages.messageTypeTexts.department",
};

export const COMMENT_TYPE_KEY_VALUE_MAPPING = {
  [COMMENT_TYPE.USER_CREATED]:
    "user.notificationsAndMessages.messages.commentTypeTexts.commented",
  [COMMENT_TYPE.REPAYMENT_REQUESTED]:
    "user.notificationsAndMessages.messages.commentTypeTexts.requestedRepayment",
  [COMMENT_TYPE.REPAYMENT_CANCELLED]:
    "user.notificationsAndMessages.messages.commentTypeTexts.repaymentCancelled",
  [COMMENT_TYPE.REPAYMENT_RECEIVED]:
    "user.notificationsAndMessages.messages.commentTypeTexts.repaymentReceived",
  [COMMENT_TYPE.REPAYMENT_REJECTED]:
    "user.notificationsAndMessages.messages.commentTypeTexts.repaymentRejected",
  [COMMENT_TYPE.FLAGGED]:
    "user.notificationsAndMessages.messages.commentTypeTexts.flagged",
};
