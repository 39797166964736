import PropTypes from "prop-types";
import React from "react";

import DateInput from "@/components/core/DateInput";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import { dateToString, validateAndConvertToISO8601 } from "@/utils/common";

import { DATEINPUT_MODES } from "@/constants/date";

function DateInputTextBox({
  name,
  value,
  valueToShow,
  error,
  handleChange,
  label,
  classes,
  disabled,
  mode = DATEINPUT_MODES.DATE,
  dateInputExtraProp,
  isSelectable,
  clearable,
  autoComplete = "off",
  showOptional,
  ...extraProps
}) {
  return (
    <div className={classes}>
      <DateInput
        name={name}
        value={
          value ? new Date(validateAndConvertToISO8601(value)) : new Date()
        }
        // the false case prevents date-fns warning logs, preventing slowdown,
        // the UI doesn't set/show this date, so we're good
        onChange={handleChange}
        extraProps={dateInputExtraProp}
        isSelectable={isSelectable}
        disabled={disabled}
        content={
          <Input
            name={name}
            label={label}
            error={error}
            disabled={disabled}
            clearable={clearable}
            autoComplete={autoComplete}
            type="text"
            value={valueToShow || dateToString(value, { year: "numeric" })}
            rightText={
              <Icon
                name="Calendar"
                className={`mr-3 ${
                  disabled ? "text-neutral-400" : "text-neutral-500 mr-3"
                }`}
              />
            }
            onChange={(e) => {
              if (!e?.target?.value) handleChange(e); // only allow clearing
            }}
            showOptional={showOptional}
            {...extraProps}
          />
        }
        mode={mode}
        insideForm
      />
    </div>
  );
}

export default DateInputTextBox;
DateInputTextBox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  valueToShow: PropTypes.string,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  isSelectable: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(DATEINPUT_MODES)),
  dateInputExtraProp: PropTypes.object,
  //
  clearable: PropTypes.bool,
  autoComplete: PropTypes.string,
  showOptional: PropTypes.bool,
};
