// react imports
import PropTypes from "prop-types";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-labelled";
import "react-phone-input-labelled/dist/style.css";
import { useDispatch, useSelector } from "react-redux";

import { fetchCountries } from "@/store/reducers/app";

import { countriesSelector } from "@/store/selectors/app";
import { defaultCurrencySelector } from "@/store/selectors/client";

// core components
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import "./style.scss";

export default function PhoneNumberInput({
  countriesList,
  onlyCountries = [],
  preferredCountries = [],
  disabled,
  disableDropdown,
  disableCountryCode,
  value, // {dialCode,countryCode,name:CountryName,phoneNumber}
  handleChange,
  inputProps,
  label = "Mobile number",
  inputWidth = null,
  inputHeight = null,
  showEditIcon,
  disableInputField,
  error,
  insideForm,
  enableSearch,
  handleEditIcon,
  description,
  labelClass, // pass empty string to match core/Input style
  containerClasses,
}) {
  const handleEditIconClick = () => {
    handleEditIcon();
  };

  const dispatch = useDispatch();
  const countries = useSelector(countriesSelector);

  const defaultCurrency = useSelector(defaultCurrencySelector);
  const defaultBaseCountry = countries?.find(
    (country) => country.currency === defaultCurrency
  );

  const defaultCountry = defaultBaseCountry?.iso2Code?.toLowerCase();

  useEffect(() => {
    if (!countries?.length) dispatch(fetchCountries());
  }, [countries]);

  const innerHandleChange = (selectedValue) => {
    handleChange(
      insideForm
        ? {
            target: {
              value: selectedValue,
              name: inputProps.name,
              type: "phone-input",
            },
          }
        : selectedValue
    );
  };

  const styles = {
    input: {
      color: "var(--grey-400)", // Grey text color
      borderBottom: "1px solid var(--grey-200)", // Grey border color
      width: inputWidth || "100%",
      height: inputHeight || "12px !important",
    },
  };

  return (
    <div
      className={`relative flex border-neutral-600  flex-col mt-3 ${containerClasses}`}
      style={{ width: inputWidth || "100%" }}
    >
      <Text
        classes={`absolute text-xs font-semibold w-max -top-7 ${
          disabled ? "text-neutral-400" : ""
        }`}
        translationKey={label}
      />

      <div
        className={`flex flex-row items-center relative ${
          showEditIcon && handleEditIcon ? "mb-3" : ""
        }`}
      >
        <PhoneInput
          labelClass={labelClass}
          value={value}
          countryCodeEditable={false}
          countriesList={countriesList}
          onlyCountries={onlyCountries}
          defaultCountry={defaultCountry}
          preferredCountries={preferredCountries}
          disabled={disabled}
          disableDropdown={disableDropdown}
          disableCountryCode={disableCountryCode}
          onChange={innerHandleChange}
          inputExtraProps={{
            ...inputProps,
            disabled: disableInputField,
            autoComplete: "new-password",
          }}
          label=""
          inputStyle={
            disabled
              ? styles.input
              : {
                  width: inputWidth || "100%",
                  height: inputHeight || "12px !important",
                }
          }
          labelStyle={{ display: "none" }}
          enableSearchField={enableSearch}
        />

        {showEditIcon && handleEditIcon ? (
          <div className="absolute right-2" onClick={handleEditIconClick}>
            <Icon
              name="Edit"
              className="text-neutral-400 hover:text-neutral-700 hover:cursor-pointer"
            />
          </div>
        ) : null}
      </div>

      {error && (
        <Text
          classes="inline-block text-xs text-danger-600 w-max mt-7"
          translationKey={error}
        />
      )}

      {!error &&
        description &&
        (typeof description === typeof "" ? (
          <Text
            classes="text-xs text-neutral-500 pl-0 w-max mt-7"
            translationKey={description}
          />
        ) : (
          <div>{description}</div>
        ))}
    </div>
  );
}

PhoneNumberInput.propTypes = {
  enableSearch: PropTypes.bool,
  inputProps: PropTypes.object,
  value: PropTypes.string,
  countriesList: PropTypes.array,
  onlyCountries: PropTypes.array,
  preferredCountries: PropTypes.array,
  disabled: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  disableCountryCode: PropTypes.bool,
  disableInputField: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  inputWidth: PropTypes.string,
  inputHeight: PropTypes.string,
  error: PropTypes.string,
  showEditIcon: PropTypes.bool,
  insideForm: PropTypes.bool,
  handleEditIcon: PropTypes.func,
  description: PropTypes.string,
  containerClasses: PropTypes.string,
};
