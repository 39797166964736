import { useSelector } from "react-redux";

import { masterHistoryLogsFiltersSelector } from "@/store/selectors/master-history";

import Text from "@/components/core/Text";

import MasterHistoryList from "@/components/Settings/MasterHistoryList";
import MasterHistorySearchAndFilters from "@/components/Settings/MasterHistorySearchAndFilters";

export default function MasterHistoryPage() {
  const filters = useSelector(masterHistoryLogsFiltersSelector);

  return (
    <div className="px-11">
      <div className="flex flex-col">
        <Text
          translationKey="company.title"
          classes="text-neutral-500 text-sm font-semibold"
        />
        <Text
          translationKey="settings.masterHistory.title"
          classes="text-3xl font-bold text-neutral-800"
        />
      </div>
      <div className="flex flex-col gap-4">
        <MasterHistorySearchAndFilters filters={filters} />
        <div className="p-4 rounded-xl border border-neutral-300 bg-neutral-50">
          <Text
            translationKey="settings.masterHistory.misc.description"
            classes="text-base font-semibold text-neutral-800"
          />
        </div>
        <MasterHistoryList />
      </div>
    </div>
  );
}
