import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteReport, fetchReports } from "@/store/reducers/reimbursement";

import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";
import { REIMBURSEMENT_STATUS } from "@/utils/constants/reimbursement";
import { TAB_TYPES } from "@/utils/constants/myVolopay";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function DeleteDraftModal({ showModal, setShowModal, deleteId }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelector);

  const [includeClaims, setIncludeClaims] = useState(false);

  const deleteDraftHandler = () => {
    dispatch(
      deleteReport({
        id: deleteId,
        params: { include_claims: includeClaims },
        onSuccess: () => {
          setShowModal(false);
        },
      })
    );
  };

  return (
    <div>
      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
        className="flex items-center justify-center z-[1000] "
      >
        <div className="flex flex-col px-6 w-120">
          <div className="pt-4">
            <Icon name="Info" className="w-9 h-9 text-primary-500" />
          </div>
          <div className="py-4">
            <Text
              translationKey="myVolopay.reimbursements.reports.deleteModal.title"
              classes="font-bold text-lg"
            />
          </div>
          <div className="py-2">
            <Text
              translationKey="myVolopay.reimbursements.reports.deleteModal.desc"
              classes="text-md font-semibold"
            />
          </div>
          <div className="flex items-center gap-4 py-4">
            <Checkbox
              checked={includeClaims}
              onClickHandler={(val) => setIncludeClaims(val)}
            />
            <Text
              translationKey="myVolopay.reimbursements.reports.deleteModal.deleteClaims"
              classes="text-md text-neutral-900 font-semibold"
            />
          </div>
          <div className="flex items-center gap-2 pt-4 pb-6">
            <Button
              label="myVolopay.reimbursements.reports.deleteModal.keepCta"
              variant="tertiary"
              onClick={() => setShowModal(false)}
              classes="text-neutral-500"
            />
            <Button
              label="myVolopay.reimbursements.reports.deleteModal.deleteCta"
              type="danger"
              onClick={deleteDraftHandler}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteDraftModal;

DeleteDraftModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  deleteId: PropTypes.number,
};
