import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchTimelineData,
  setTimelineStatus,
} from "@/store/reducers/timeline";

import {
  timelineDataSelector,
  timelineResourceIdSelector,
  timelineStatusSelector,
} from "@/store/selectors/timeline";

import TimeLineItem from "@/components/core/Timeline/TimeLineItem";
import {
  ALL_TIMELINE_STATUSES,
  APPROVAL,
  TIMELINE_STATUS_CONFIG,
  TIME_HEADER_BASED_TYPE,
  TYPES_TIMELINE,
} from "@/utils/constants/timeline";
import { convertNumberToOrdinalNumber } from "@/utils/common";

import Alert from "../Alert";
import Icon from "../Icon";
import LoaderSkeleton from "../LoaderSkeleton";
import Text from "../Text";
import ApprovalItems from "./approvalItems";

export default function Timeline({
  id = null,
  status,
  type,
  currentApprovers,
  totalApprovers,
  currentApproverLevel,
  rejectedReason,
  failureReason,
}) {
  const dispatch = useDispatch();
  const timelineData = useSelector(timelineDataSelector);
  const timelineId = useSelector(timelineResourceIdSelector);
  const timelineStatus = useSelector(timelineStatusSelector);
  const [isFetching, setIsFetching] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const isAccordianApplicable = !(
    TYPES_TIMELINE.EXPENSE && status === ALL_TIMELINE_STATUSES.INCOMPLETED
  );

  const isApprovalPending =
    [
      ALL_TIMELINE_STATUSES.PENDING,
      ALL_TIMELINE_STATUSES.PENDING_APPROVAL,
    ]?.includes(status) && currentApproverLevel < totalApprovers;

  const configStatus = useMemo(() => {
    return TIMELINE_STATUS_CONFIG[status];
  }, [status]);

  const showAlert =
    configStatus?.showAlertText && (rejectedReason || failureReason);

  const handleApiCallAndOToggleAccordion = () => {
    if (!isAccordianApplicable) return;

    if ((id && id !== timelineId) || (status && timelineStatus !== status)) {
      setIsFetching(true);
      dispatch(
        fetchTimelineData({
          id,
          owner: type,
          onSuccess: () => {
            setIsFetching(false);
            dispatch(setTimelineStatus(status));
          },
        })
      );
    }
    setIsAccordionOpen((prev) => !prev);
  };

  const firstIndexOfApproval = timelineData.findIndex(
    (item) => item.activityType === APPROVAL
  );
  return (
    <div className="p-0 overflow-hidden cursor-pointer select-none card-wrapper">
      <div
        onClick={handleApiCallAndOToggleAccordion}
        className="flex flex-row items-center justify-between p-3 "
      >
        <div className="w-full">
          <Text
            classes="text-sm font-medium text-neutral-500"
            translationKey={TIME_HEADER_BASED_TYPE[type]}
          />
          <div className="flex flex-row gap-2 text-warning-600">
            <div>
              <Text
                classes={configStatus?.titleClasses}
                translationKey={
                  typeof configStatus?.title === typeof (() => {})
                    ? configStatus?.title({ timelineType: type })
                    : configStatus?.title
                }
              />
              {isApprovalPending ? (
                <span className="text-base font-semibold select-none text-neutral-800">
                  :
                </span>
              ) : null}
            </div>
            {isApprovalPending ? (
              <Text
                translationKey={`${convertNumberToOrdinalNumber(
                  currentApproverLevel + 1
                )} level`}
                classes="capitalize select-none text-base text-warning-600 font-semibold "
              />
            ) : null}
          </div>
          {isApprovalPending ? (
            <ApprovalItems
              approvers={currentApprovers}
              id={id}
              isExpense={type === TYPES_TIMELINE.EXPENSE}
            />
          ) : null}
        </div>
        {isAccordianApplicable ? (
          <Icon
            className="cursor-pointer text-neutral-500"
            name={isAccordionOpen ? "UpArrow" : "DownArrow"}
          />
        ) : null}
      </div>
      {showAlert ? (
        <Alert
          icon={null}
          classes="!p-4 m-4 mt-0"
          variant={configStatus?.alertVariant}
          titleClasses={configStatus?.alertClasses}
          title={rejectedReason || failureReason}
        />
      ) : null}
      {isAccordianApplicable && isAccordionOpen ? (
        <div className="flex flex-col gap-5 p-3 transition-all ease-in-out bg-neutral-50">
          {isFetching ? (
            <LoaderSkeleton classes="mt-6" count={3} size={[30, "full"]} />
          ) : (
            timelineData?.map((item, index) => (
              <div
                className={`relative ${index !== 0 ? "" : "pt-1"}`}
                key={`timeline-item-${index}`}
              >
                {index !== 0 ? (
                  <div className="absolute h-[25px] border-l-2 border-dashed -top-[calc(50%-4px)] left-5 border-neutral-200" />
                ) : null}
                <TimeLineItem
                  isFirstPending={
                    firstIndexOfApproval === index &&
                    currentApproverLevel !== totalApprovers
                  }
                  id={index}
                  isApprovalPendingActivityType={
                    item?.activityType === "approval"
                  }
                  timelineItem={item}
                  timelineType={type}
                />
              </div>
            ))
          )}
        </div>
      ) : null}
    </div>
  );
}

Timeline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  type: PropTypes.string,
  currentApprovers: PropTypes.array,
  rejectedReason: PropTypes.string,
  failureReason: PropTypes.string,
};
