import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import ProfileWidget from "@/components/core/ProfileWidget";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import AmountWithExchangeAndFee from "@/components/Company/common/AmountWithExchangeAndFee";
import { dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  BE_FE_TRANSACTION_TYPE_KEY,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_LABELS,
} from "@/constants/company";

const TRANSACTION_TIME_SEARCH_PARAMS_CONFIG = {
  BillPayment: SLIDERS_SEARCH_PARAMS.payments.id,
  Expense: SLIDERS_SEARCH_PARAMS.expenses.id,
  Reimbursement: SLIDERS_SEARCH_PARAMS.reimbursements.approveId,
};
export default function SpendsTabTable({
  list = [],
  isFetching = false,
  loaderFunction = () => {},
  isPersonTable = false,
  isExpenseTable = false,
}) {
  const { t } = useTranslation();
  const isEmpty = list.length === 0;
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Table
      headerSticky
      styleId="company-overview-spends-table"
      maxHeight="h-full"
    >
      <tr>
        <th className="text-left">
          <Text
            translationKey="date"
            classes="text-xs font-semibold text-neutral-800"
          />
        </th>
        <th className="text-right">
          <Text
            translationKey="amount"
            classes="text-xs font-semibold text-neutral-800"
          />
        </th>
      </tr>

      {(!isFetching || !isEmpty) &&
        list.map((val, index) => {
          return (
            <tr
              key={`payments-${index}`}
              className="text-sm font-semibold cursor-pointer text-neutral-800"
            >
              <td>
                <div
                  onClick={() => {
                    if (isPersonTable) {
                      searchParams.append(
                        SLIDERS_SEARCH_PARAMS.company.people.id,
                        val?.userId
                      );
                    } else {
                      searchParams.append(
                        TRANSACTION_TIME_SEARCH_PARAMS_CONFIG[
                          isExpenseTable
                            ? TRANSACTION_TYPE.EXPENSE
                            : val?.transactionType
                        ],
                        isExpenseTable ? val?.id : val?.transactionId
                      );
                    }
                    setSearchParams(searchParams);
                  }}
                >
                  <ProfileWidget
                    name={
                      isPersonTable
                        ? val?.name
                        : (val?.merchantName ?? val.merchant?.name)
                    }
                    img={isPersonTable ? val?.avatarUrl : val?.logoUrl}
                    textClasses="text-sm font-semibold cursor-pointer text-neutral-800"
                  >
                    <span className="text-xs font-medium text-neutral-500">
                      {(isPersonTable
                        ? [
                            val?.roles?.at(0).name || val?.roles?.at(0),
                            val?.departmentName,
                            val?.location ?? val?.locationName,
                          ]
                        : [
                            dateToString(
                              val?.transactionDate ?? val?.expenseDate
                            ),
                            isExpenseTable
                              ? val?.cardHolder?.displayName
                              : val?.userName,
                            isExpenseTable
                              ? val?.cardHolder?.locationName
                              : (val?.location ?? val?.locationName),
                            t(
                              TRANSACTION_TYPE_LABELS[
                                BE_FE_TRANSACTION_TYPE_KEY[
                                  isExpenseTable
                                    ? TRANSACTION_TYPE.EXPENSE
                                    : val?.transactionType
                                ]
                              ]
                            ),
                          ]
                      )
                        .filter((textItem) => textItem?.length > 0)
                        .join(" • ")}
                    </span>
                  </ProfileWidget>
                </div>
              </td>
              <td>
                <AmountWithExchangeAndFee
                  amount={{
                    value:
                      isPersonTable || isExpenseTable
                        ? val.amount
                        : val.homeAmount,
                    currency:
                      isPersonTable || isExpenseTable
                        ? val?.currency
                        : val.homeCurrency,
                  }}
                  transactionAmount={{
                    value:
                      isPersonTable || isExpenseTable
                        ? null
                        : val.transactionAmount,
                    currency: val.transactionCurrency,
                  }}
                  feeOrExchangeAmount={{
                    value: isExpenseTable ? null : val.fee,
                    currency: isPersonTable
                      ? val?.currency
                      : val.transactionCurrency,
                  }}
                />
              </td>
            </tr>
          );
        })}
      {isFetching ? loaderFunction?.() : null}
    </Table>
  );
}

SpendsTabTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  loaderFunction: PropTypes.func,
  isPersonTable: PropTypes.bool,
  isExpenseTable: PropTypes.bool,
};
