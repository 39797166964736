// eslint-disable-next-line import/no-cycle
import { fetchOnboardingSectionApi } from "@/store/reducers/onboarding";
// ^ this is valid escape

import {
  MOBILE_VERIFICATION_STEP_PAGES,
  ONBOARDING_STEPS,
  VKYC_STEPS_ICONS,
} from "@/constants/onboarding";

export function handleMobileVerificationSections(params, response, dispatch) {
  const [mobileNumberField, otpField] = response?.fields ?? [];
  let mobileVerficationSection = [];
  const { otpSentCount, handleSendOtpButton } = params;

  switch (params.subPage) {
    case MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_PHONE_NUMBER:
    case MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_EDIT:
      mobileVerficationSection = [
        {
          ...response,
          fields: [
            {
              ...mobileNumberField,
              inputWidth: "611px",
              disabled:
                params.subPage !==
                MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_EDIT,
            },
          ],
          buttonLabel:
            params.subPage ===
            MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_PHONE_NUMBER
              ? "onboarding.onboardingSteps.mobileVerification.pages.mobileNumber.buttonLabel"
              : "onboarding.onboardingSteps.mobileVerification.pages.mobileNumberEdit.buttonLabel",
        },
      ];
      break;

    case MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_OTP:
      mobileVerficationSection = [
        {
          ...response,
          fields: [
            {
              ...mobileNumberField,
              inputWidth: "611px",
              disabled: true,
              handleEditIcon: () => {
                dispatch(
                  fetchOnboardingSectionApi({
                    step: ONBOARDING_STEPS.PERSONAL_DETAILS,
                  })
                );
              },
            },
            {
              ...otpField,
              otpSentCount,
              handleResendOtpButton: handleSendOtpButton,
            },
          ],
          buttonLabel:
            "onboarding.onboardingSteps.mobileVerification.pages.mobileOtp.buttonLabel",
        },
      ];
      break;

    default:
      break;
  }
  return mobileVerficationSection;
}

export function handlePersonalDetailsPage(params, response) {
  const stylesByFieldType = {
    number: { labelExtraClasses: "text-xs font-semibold text-neutral-500" },
    text: { labelExtraClasses: "text-xs font-semibold text-neutral-500" },
    select: { labelStyleClasses: "text-xs font-semibold text-neutral-500 " },
    email: { labelExtraClasses: "text-xs font-semibold text-neutral-500 " },
    date: {
      labelExtraClasses: "text-xs font-semibold text-neutral-500",
      wrapperComponentClasses: "w-66",
    },
  };

  response?.fields?.forEach((field, index) => {
    if (stylesByFieldType?.[field?.type]) {
      response.fields[index] = {
        ...field,
        ...stylesByFieldType?.[field?.type],
        classes: field.key === "title" ? "w-66" : "",
      };
    }

    if (field?.type === "group") {
      field?.fields?.forEach((inlineGroupField, ind) => {
        if (field?.inlineGroup === "email_and_nationality") {
          if (inlineGroupField?.key === "nationality") {
            inlineGroupField.options = params?.countryOptions?.map(
              (country) => ({
                key: country?.name,
                value: country?.name,
              })
            );
          }
        }

        if (stylesByFieldType?.[inlineGroupField?.type]) {
          response.fields[index].fields[ind] = {
            ...inlineGroupField,
            ...stylesByFieldType?.[inlineGroupField?.type],
          };
        }
      });
    }
  });

  return [response];
}

export function handleVideoKycVerficationSection(params, response) {
  const { handleActionButtonClick } = params;

  response.fields = response?.fields?.map((field) => ({
    ...field,
    iconName: VKYC_STEPS_ICONS[field?.key],
  }));

  return [{ ...response, handleActionButtonClick }];
}

export function areSameAddress(fields) {
  const primaryAddress = fields?.[0]?.values;
  const primaryAddressKeys = Object.keys(primaryAddress ?? {});
  const secondaryAddres = fields?.[1]?.values;

  return Object.values(primaryAddress ?? {}).reduce(
    (hasSameAddressValues, currentValue, index) => {
      if (primaryAddressKeys[index] === "id")
        return hasSameAddressValues && true;

      return (
        hasSameAddressValues &&
        currentValue === secondaryAddres?.[primaryAddressKeys[index]]
      );
    },
    true
  );
}
