import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import Text from "@/components/core/Text";
import {
  CATEGORY_KEYS_LABEL_MAPPING,
  generateRedirectionUrlForFilters,
} from "@/utils/constants/searchAndFilter";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";

import PaginatedPeopleTable from "../common/PaginatedPeopleTable";

function PeopleTabLocation({
  people,
  isFetching,
  navigate,
  showManageMemberCta,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleManagePeople = () => {
    generateRedirectionUrlForFilters({
      parentRoute: ROUTES.manage.people.users.all.absolutePath,
      dispatch,
      categoryKey: CATEGORY_KEYS_LABEL_MAPPING.LOCATION,
      categoryValues: searchParams.get(
        SLIDERS_SEARCH_PARAMS.company.location.id
      ),
      navigate,
    });
  };

  return (
    <div className="text-neutral-800 pb-9">
      {/* Top - title and button the right (below) */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Text
            translationKey="company.department.departmentDetails.tabs.people.title"
            classes="text-xl font-bold"
          />
          {people.length === 0 && !isFetching ? (
            <Chip label={people?.length ?? 0} />
          ) : null}
        </div>

        {people.length !== 0 && !isFetching && showManageMemberCta ? (
          <Button
            label="company.department.departmentDetails.tabs.people.managePeopleLabel"
            variant="tertiary"
            iconClasses="text-xs"
            labelStyleClasses="text-xs font-bold"
            onClick={handleManagePeople}
            compact
            classes="shrink-0"
            id="manage-people-tooltip"
          />
        ) : null}
      </div>
      {/* Search, listing */}

      <div>
        <PaginatedPeopleTable
          emptyLabel="company.department.departmentDetails.tabs.people.emptyState.dept.people.title"
          emptyDescription="company.department.departmentDetails.tabs.people.emptyState.dept.people.description"
          emptyBtnLabel="company.department.departmentDetails.tabs.people.emptyBtnDescription"
          people={people ?? []}
          handleEmptyBtnClick={handleManagePeople}
          inEditMode={false}
          // no trash icon
          limit={5}
        />
      </div>
    </div>
  );
}

export default PeopleTabLocation;

PeopleTabLocation.propTypes = {
  people: PropTypes.array,
  isFetching: PropTypes.bool,
  navigate: PropTypes.any,
  showManageMemberCta: PropTypes.bool,
};
