import to from "await-to-js";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { quickGuideSearchOptions } from "@/components/PageTopSection/InAppGuide/contants";
import API from "@/api";

const quickGuidInitialState = {
  quickGuideList: [],
  searchOptions: quickGuideSearchOptions,
};

const quickGuideSlice = createSlice({
  name: "quickGuide",
  initialState: quickGuidInitialState,
  reducers: {
    setQuickGuideList(state, action) {
      state.quickGuideList = action.payload;
    },
  },
});

export const fetchQuickGuideList = createAsyncThunk(
  "quickGuide/fetchQuickGuideList",
  async (params, { dispatch }) => {
    const [error, response] = await to(
      API.QuickGuide.fetchQuickGuideList(params)
    );
    if (response) {
      dispatch(setQuickGuideList(response.data));
    }
  }
);

export const { setQuickGuideList } = quickGuideSlice.actions;

export default quickGuideSlice.reducer;
