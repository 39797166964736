import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { primaryCardProviderSelector } from "@/store/selectors/client";
import { adminRoleIdSelector, rolesSelector } from "@/store/selectors/company";

import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import { ROLES_TYPE } from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/SettingsType";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  PERMISSIONS_LABEL_DESCRIPTION_CONFIG,
  SETTING_PERMISSIONS_ACTION_TYPE,
} from "@/constants/company";

export default function RolesPermissionsSwitch({
  permission,
  permissionList,
  setPermissions,
  selectedPermissionList,
  updateSelectedPermissionList,
  disabled,
  componentKey,
  setParentKey,
  type = SETTING_PERMISSIONS_ACTION_TYPE.ADD,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const primaryCardProvider = useSelector(primaryCardProviderSelector);

  const roleId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.rolesPermissions.role
  );

  const adminRoleId = useSelector(adminRoleIdSelector);

  const isAdminSlider = adminRoleId === Number(roleId);

  const isValueSelected = (permissionItem) => {
    if (type === SETTING_PERMISSIONS_ACTION_TYPE.ADD) {
      return !!selectedPermissionList?.find(
        (item) => item.id === permissionItem?.id
      );
    }
    return !!selectedPermissionList?.find(
      (item) =>
        item.permissionId === permissionItem?.id ||
        selectedPermissionList?.some(
          (_) => _?.permissionId === permissionItem?.siblingId
        )
    );
  };

  useEffect(() => {
    if (
      selectedPermissionList.find(
        (item) => item?.permissionId === permission?.id
      )
    ) {
      handleUpdatePermissionAndCheckboxClickable(true);
    }
  }, []);

  const handleParentKeyUpdate = (updatedKey) => {
    setParentKey(updatedKey);
  };

  const handleUpdatePermissionAndCheckboxClickable = (val) => {
    const relatedPermission = permissionList?.find(
      (permissionItem) =>
        permissionItem?.id !== permission?.id &&
        permissionItem.name === permission?.name
    );

    setPermissions((prev) => {
      const updatedPermissions = {
        ...prev,
        [componentKey]: {
          ...prev[componentKey],
          permissions: prev[componentKey]?.permissions?.map((item) =>
            item.id === relatedPermission?.id
              ? { ...item, disabled: !val }
              : item
          ),
        },
      };
      return updatedPermissions;
    });
  };
  const handlePermissionUpdate = (val) => {
    handleUpdatePermissionAndCheckboxClickable(val);
    updateSelectedPermissionList(permission, componentKey, val);
    const relatedPermission = permissionList?.find(
      (permissionItem) =>
        permissionItem?.id !== permission?.id &&
        permissionItem.name === permission?.name
    );
    if (
      selectedPermissionList.find(
        (item) =>
          item?.permissionId === relatedPermission?.id ||
          item?.id === relatedPermission?.id
      )
    )
      updateSelectedPermissionList(relatedPermission, componentKey, val);
    handleParentKeyUpdate(componentKey);
  };

  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex flex-col">
        <Text
          translationKey={
            PERMISSIONS_LABEL_DESCRIPTION_CONFIG[permission?.name]?.name
          }
          classes="text-base font-semibold text-neutral-800"
        />

        <Text
          translationKey={
            typeof PERMISSIONS_LABEL_DESCRIPTION_CONFIG[permission?.name]
              ?.description === typeof (() => {})
              ? PERMISSIONS_LABEL_DESCRIPTION_CONFIG[
                  permission?.name
                ]?.description(primaryCardProvider)
              : PERMISSIONS_LABEL_DESCRIPTION_CONFIG[permission?.name]
                  ?.description
          }
          classes="text-sm font-semibold text-neutral-500"
        />
      </div>
      <div className="flex items-baseline">
        <Switch
          disabled={disabled}
          disabledStyles={
            disabled && (isAdminSlider || isValueSelected(permission))
              ? "bg-primary-200"
              : ""
          }
          value={isAdminSlider || isValueSelected(permission)}
          handleChange={(val) => handlePermissionUpdate(val)}
        />
      </div>
    </div>
  );
}

RolesPermissionsSwitch.propTypes = {
  permission: PropTypes.object,
  permissionList: PropTypes.array,
  selectedPermissionList: PropTypes.array,
  setPermissions: PropTypes.func,
  updateSelectedPermissionList: PropTypes.func,
  disabled: PropTypes.bool,
  setParentKey: PropTypes.func,
  componentKey: PropTypes.string,
  type: PropTypes.string,
};
