import { Route, Routes } from "react-router-dom";

import MyVolopayReimbursementPaymentHistory from "@/components/Reimbursement/MyVolopayReimbursementPaymentHistory";

import TravelPage from "@/pages/company/travel";
import MyVolopay from "@/pages/my-volopay";
import ActionCentre from "@/pages/my-volopay/actionCentre";
import ActionCentreBillPayTab from "@/pages/my-volopay/actionCentre/billpay";
import ActionCentreCardsTab from "@/pages/my-volopay/actionCentre/cards";
import ActionCentrePayrollTab from "@/pages/my-volopay/actionCentre/payroll";
import ActionCentreReimburshmentTab from "@/pages/my-volopay/actionCentre/reimburshment";
import MyVolopayCardsPage from "@/pages/my-volopay/cards";
import MyVolopayCardsPhysicalCardsPage from "@/pages/my-volopay/cards/physical-cards";
import MyVolopayCardsPhysicalCardsActive from "@/pages/my-volopay/cards/physical-cards/active";
import MyVolopayCardsPhysicalCardsAll from "@/pages/my-volopay/cards/physical-cards/all";
import MyVolopayCardsPhysicalCardsBlocked from "@/pages/my-volopay/cards/physical-cards/blocked";
import MyVolopayVirtualCardsPage from "@/pages/my-volopay/cards/virtual-cards";
import MyVolopayVirtualCardsAllPage from "@/pages/my-volopay/cards/virtual-cards/all";
import MyVolopayVirtualPageBlockedPage from "@/pages/my-volopay/cards/virtual-cards/blocked";
import MyVolopayCardsPhysicalCardsYetToActivate from "@/pages/my-volopay/cards/yet-to-activate";
import MyVolopayExpensesPage from "@/pages/my-volopay/expenses";
import MyVolopayExpensesDeclinePages from "@/pages/my-volopay/expenses/declines";
import MyVolopayExpensesFlaggedPage from "@/pages/my-volopay/expenses/flagged";
import MyVolopayExpensesOverviewPage from "@/pages/my-volopay/expenses/overview";
import MyVolopayGettingStartedPage from "@/pages/my-volopay/getting-started";
import MyVolopayReimbursementsPage from "@/pages/my-volopay/reimbursements";
import MyVolopayReimbursementHistoryPage from "@/pages/my-volopay/reimbursements/History";
import MyVolopayReimbursementClaimPage from "@/pages/my-volopay/reimbursements/claims";
import MyVolopayReimbursementActivePage from "@/pages/my-volopay/reimbursements/claims/Active";
import MyVolopayReimbursementDraftsPage from "@/pages/my-volopay/reimbursements/claims/drafts";
import MyVolopayReimbursementReportPage from "@/pages/my-volopay/reimbursements/reports";
import MyVolopayReimbursementReportsActivePage from "@/pages/my-volopay/reimbursements/reports/active";
import MyVolopayReimbursementReportsDraftsPage from "@/pages/my-volopay/reimbursements/reports/drafts";
import MyVolopayReimbursementReportsHistoryPage from "@/pages/my-volopay/reimbursements/reports/history";
import { ROUTES } from "@/constants/routes";

export default function MyVolopayRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MyVolopay />} />
      <Route
        path={ROUTES.myVolopay.gettingStarted.pathName}
        element={<MyVolopayGettingStartedPage />}
      />
      <Route path={ROUTES.myVolopay.reimbursement.pathName}>
        <Route index element={<MyVolopayReimbursementsPage />} />
        <Route
          path={ROUTES.myVolopay.reimbursement.claims.pathName}
          element={<MyVolopayReimbursementClaimPage />}
        >
          <Route
            path={ROUTES.myVolopay.reimbursement.claims.drafts.pathName}
            element={<MyVolopayReimbursementDraftsPage />}
          />

          <Route
            path={ROUTES.myVolopay.reimbursement.claims.active.pathName}
            element={<MyVolopayReimbursementActivePage />}
          />

          <Route
            path={ROUTES.myVolopay.reimbursement.claims.history.pathName}
            element={<MyVolopayReimbursementHistoryPage />}
          />
        </Route>

        <Route
          path={ROUTES.myVolopay.reimbursement.reports.pathName}
          element={<MyVolopayReimbursementReportPage />}
        >
          <Route
            path={ROUTES.myVolopay.reimbursement.reports.drafts.pathName}
            element={<MyVolopayReimbursementReportsDraftsPage />}
          />

          <Route
            path={ROUTES.myVolopay.reimbursement.reports.active.pathName}
            element={<MyVolopayReimbursementReportsActivePage />}
          />

          <Route
            path={ROUTES.myVolopay.reimbursement.reports.history.pathName}
            element={<MyVolopayReimbursementReportsHistoryPage />}
          />
        </Route>

        <Route
          path={ROUTES.myVolopay.reimbursement.paymentHistory.pathName}
          element={<MyVolopayReimbursementPaymentHistory />}
        />
      </Route>

      <Route
        path={ROUTES.myVolopay.actionCentre.pathName}
        element={<ActionCentre />}
      >
        <Route
          path={ROUTES.myVolopay.actionCentre.cards.pathName}
          element={<ActionCentreCardsTab />}
        />
        <Route
          path={ROUTES.myVolopay.actionCentre.billPay.pathName}
          element={<ActionCentreBillPayTab />}
        />
        <Route
          path={ROUTES.myVolopay.actionCentre.reimbursement.pathName}
          element={<ActionCentreReimburshmentTab />}
        />
        <Route
          path={ROUTES.myVolopay.actionCentre.payroll.pathName}
          element={<ActionCentrePayrollTab />}
        />
      </Route>
      <Route path={ROUTES.myVolopay.cards.pathName}>
        <Route index element={<MyVolopayCardsPage />} />
        <Route
          path={ROUTES.myVolopay.cards.physicalCards.pathName}
          element={<MyVolopayCardsPhysicalCardsPage />}
        >
          <Route
            index
            path={ROUTES.myVolopay.cards.physicalCards.all.pathName}
            element={<MyVolopayCardsPhysicalCardsAll />}
          />

          <Route
            index
            path={ROUTES.myVolopay.cards.physicalCards.active.pathName}
            element={<MyVolopayCardsPhysicalCardsActive />}
          />

          <Route
            index
            path={ROUTES.myVolopay.cards.physicalCards.yetToActivate.pathName}
            element={<MyVolopayCardsPhysicalCardsYetToActivate />}
          />

          <Route
            index
            path={ROUTES.myVolopay.cards.physicalCards.blocked.pathName}
            element={<MyVolopayCardsPhysicalCardsBlocked />}
          />
        </Route>
        <Route
          path={ROUTES.myVolopay.cards.virtualCards.pathName}
          element={<MyVolopayVirtualCardsPage />}
        >
          <Route
            index
            path={ROUTES.myVolopay.cards.virtualCards.all.pathName}
            element={<MyVolopayVirtualCardsAllPage />}
          />
          <Route
            index
            path={ROUTES.myVolopay.cards.virtualCards.blocked.pathName}
            element={<MyVolopayVirtualPageBlockedPage />}
          />
        </Route>
      </Route>

      <Route path={ROUTES.myVolopay.expenses.pathName}>
        <Route index element={<MyVolopayExpensesPage />} />
        <Route
          path={ROUTES.myVolopay.expenses.overView.pathName}
          element={<MyVolopayExpensesOverviewPage />}
        />
        <Route
          path={ROUTES.myVolopay.expenses.flagged.pathName}
          element={<MyVolopayExpensesFlaggedPage />}
        />
        <Route
          path={ROUTES.myVolopay.expenses.declines.pathName}
          element={<MyVolopayExpensesDeclinePages />}
        />
      </Route>
      <Route
        path={ROUTES.myVolopay.travel.pathName}
        element={<TravelPage isMyVolopayPage />}
      />
    </Routes>
  );
}
