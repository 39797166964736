import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setAnalyticsExportData } from "@/store/reducers/analytics";

import {
  analyticsBillPayTransactionsFiltersSelector,
  analyticsEntitesSelector,
  analyticsExpenseTransactionsFiltersSelector,
  analyticsLedgerFiltersSelector,
  analyticsOthersTransactionsFiltersSelector,
  analyticsPayrollTransactionsFiltersSelector,
  analyticsQrPayTransactionsFiltersSelector,
  analyticsReimbursementTransactionsFiltersSelector,
} from "@/store/selectors/analytics";
import { accountsSelector } from "@/store/selectors/app";

import Filters from "@/components/core/Filters";
import Text from "@/components/core/Text";

import Export from "@/components/Exports";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";

const filterConfig = {
  [ACCOUNTING_TRANSACTION_PAGES.EXPENSES]:
    analyticsExpenseTransactionsFiltersSelector,
  [ACCOUNTING_TRANSACTION_PAGES.ANALYTICS_BILLPAY]:
    analyticsBillPayTransactionsFiltersSelector,
  [ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENTS]:
    analyticsReimbursementTransactionsFiltersSelector,
  [ACCOUNTING_TRANSACTION_PAGES.OTHERS]:
    analyticsOthersTransactionsFiltersSelector,
  [ACCOUNTING_TRANSACTION_PAGES.QR_PAY]:
    analyticsQrPayTransactionsFiltersSelector,
  [ACCOUNTING_TRANSACTION_PAGES.PAYROLLS]:
    analyticsPayrollTransactionsFiltersSelector,
  [ACCOUNTING_TRANSACTION_PAGES.LEDGER]: analyticsLedgerFiltersSelector,
};

function HeaderAndFilterHelper({ type }) {
  const entities = useSelector(analyticsEntitesSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useSelector(filterConfig[type]);

  const dispatch = useDispatch();

  const [entityOptions, setEntityOptions] = useState(entities);
  const [finalFilters, setFinalFilters] = useState();

  const handleExportFunc = () => {
    dispatch(
      setAnalyticsExportData({
        transactionType: type,
      })
    );

    searchParams.append(SLIDERS_SEARCH_PARAMS.analytics.export, true);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (entities?.length) {
      setEntityOptions(
        entities?.map((data) => {
          return {
            label: data?.subdomain,
            value: data?.subdomain,
          };
        })
      );
    }
  }, [entities]);

  useEffect(() => {
    if (entityOptions?.length && filters?.length) {
      const options = entityOptions;

      const entityFilter = filters.find(
        (item) => item?.props?.filterKey === "entity"
      );

      if (entityFilter) {
        entityFilter.props.options = options;
      }

      setFinalFilters(filters);
    }
  }, [entityOptions, filters]);

  return finalFilters?.length ? (
    <div>
      <div className="flex items-center justify-between my-6">
        <div className="w-full">
          <Filters filters={finalFilters} />
        </div>
        {type === ACCOUNTING_TRANSACTION_PAGES.LEDGER ? null : (
          <div className="flex justify-end ">
            <Export handleExportFunc={handleExportFunc} />
          </div>
        )}
      </div>
    </div>
  ) : null;
}

export default HeaderAndFilterHelper;

HeaderAndFilterHelper.propTypes = {
  type: PropTypes.string,
};
