import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

function CardHolderCellLoader() {
  return (
    <div className="flex justify-around">
      <div className="flex flex-col">
        <LoaderSkeleton size={[20, 100]} />
        <LoaderSkeleton size={[10, 50]} />
      </div>
      <LoaderSkeleton type="circle" size={[30, 30]} />
    </div>
  );
}

export default CardHolderCellLoader;
