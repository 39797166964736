import PropsTypes from "prop-types";
import React, { useEffect, useState } from "react";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import Badge from "@/components/core/Badge";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { PAID_STATUS, STATUS_COLORS } from "@/utils/constants/reimbursement";
import { dateToString } from "@/utils/common";
import SliderExpansionHandler from "@/GlobalSliders/SliderExpansionHandler";

export default function ReimbursementPaymentsSliderHeader({
  name,
  userRole,
  department,
  location,
  colorCode,
  data,
  avatarUrl,
  amount,
  paymentDate,
  noOfClaims = 0,
  paymentHistory = false,
  status = PAID_STATUS.paid,
  reportEnabled,
}) {
  const ref = useLeftHeaderTitle({ title: name });
  return (
    <div className="flex items-center justify-start">
      {paymentHistory ? null : (
        <ProfileWidget
          avatarColor={colorCode}
          img={avatarUrl}
          textClasses="font-semibold"
          avatarSize="lg"
          className="flex-initial"
        />
      )}

      {paymentHistory ? (
        <div className="flex items-center justify-between w-full ">
          <div className="flex flex-col">
            <div className="flex items-center gap-3">
              <Text
                classes="text-3xl text-neutral-800 font-semibold capitalize"
                translationKey={amount}
              />
              <Badge
                variant={
                  status
                    ? STATUS_COLORS[status?.toLowerCase()]?.color
                    : "success"
                }
                translationKey={PAID_STATUS[status]}
                classes="w-15 h-6 text-xs"
              />
            </div>
            <div className="flex items-center gap-1">
              <Text
                classes="text-sm text-neutral-500 capitalize font-medium"
                translationKey={
                  reportEnabled
                    ? noOfClaims === 1
                      ? "reimbursement.payments.sliderHeadings.singleReport"
                      : "reimbursement.payments.sliderHeadings.noOfReport"
                    : noOfClaims === 1
                      ? "reimbursement.payments.sliderHeadings.singleClaim"
                      : "reimbursement.payments.sliderHeadings.noOfClaim"
                }
                translationProps={
                  reportEnabled
                    ? { noOfClaims: data?.reports?.length }
                    : { noOfClaims }
                }
              />
              <span className="flex items-center justify-center w-1 h-1 rounded-full bg-neutral-500">
                {"  "}
              </span>
              <Text
                classes="text-sm text-neutral-500 capitalize font-medium"
                translationKey={paymentDate}
              />
            </div>
          </div>
          <SliderExpansionHandler iconClasses="text-neutral-500" />
        </div>
      ) : (
        <div className="flex items-center justify-between w-full ">
          <div className="flex flex-col">
            <Text
              refProp={ref}
              classes="text-3xl  text-neutral-800 font-semibold capitalize"
              translationKey={name}
            />
            <div className="flex items-center gap-2 font-medium">
              <Text
                classes="text-sm text-neutral-500 capitalize"
                translationKey={userRole?.[0]}
              />
              <span className="flex items-center justify-center w-1 h-1 rounded-full bg-neutral-500">
                {"  "}
              </span>
              <Text
                classes="text-sm text-neutral-500 capitalize"
                translationKey={department}
              />
              <span className="flex items-center justify-center w-1 h-1 rounded-full bg-neutral-500">
                {"  "}
              </span>
              <Text
                classes="text-sm text-neutral-500 capitalize "
                translationKey={location}
              />
            </div>
          </div>
          <SliderExpansionHandler iconClasses="text-neutral-500" />
        </div>
      )}
    </div>
  );
}
ReimbursementPaymentsSliderHeader.propTypes = {
  name: PropsTypes.string,
  userRole: PropsTypes.array,
  department: PropsTypes.string,
  location: PropsTypes.string,
  avatarUrl: PropsTypes.string,
  colorCode: PropsTypes.string,
  data: PropsTypes.object,
  amount: PropsTypes.number,
  paymentDate: PropsTypes.string,
  noOfClaims: PropsTypes.number,
  paymentHistory: PropsTypes.bool,
  status: PropsTypes.string,
  reportEnabled: PropsTypes.bool,
};
