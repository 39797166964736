import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";

function FooterSection({
  handleCancel,
  handleConfirm,
  isConfirmButtonDisabled,
  inProgress,
  containerClasses,
}) {
  return (
    <div
      className={`slider-footer-right-buttons slider-footer sticky bottom-0 ${containerClasses}`}
    >
      <Button
        label="misc.cancel"
        type="neutral"
        onClick={handleCancel}
        classes="px-5 py-3"
        compact
        labelStyleClasses="text-neutral-500 font-semibold"
      />
      <Button
        label="misc.confirm"
        showLoader={inProgress}
        onClick={handleConfirm ? handleConfirm : () => {}}
        btnType="submit"
        form="captcha_verification"
        disabled={isConfirmButtonDisabled}
        classes="px-5 py-3 w-15.5"
        compact
        labelStyleClasses="font-semibold"
      />
    </div>
  );
}

FooterSection.propTypes = {
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  isConfirmButtonDisabled: PropTypes.bool,
  inProgress: PropTypes.bool,
  containerClasses: PropTypes.string,
};

export default FooterSection;
