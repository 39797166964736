import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { connectAccountingSoftware } from "@/store/reducers/accounting";
// reducers
import { fetchClient } from "@/store/reducers/client";

// selectors
import {
  accountingAuthURLSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";
import { clientSelector } from "@/store/selectors/client";

// core components
import Button from "@/components/core/Button";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";

// page relevant components
import CurrencyComponent from "@/components/Accounting/Integrations/common/CurrencyComponent";
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import { useForm } from "@/utils/useForm";
import { INTEGRATION_DOMAIN_OPTIONS } from "@/utils/constants/integrations";

//  constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_CURRENCY_TYPES,
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP,
} from "@/constants/accounting";

export default function CommonConnectSlider({ accountingSoftware }) {
  const dispatch = useDispatch();

  const [domainSelected, setDomainSelected] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const isZoho = accountingSoftware === ACCOUNTING_SOFTWARES.ZOHO;
  const accountingAuthURL = useSelector(accountingAuthURLSelector);
  const isLoadingConnect = useSelector(isLoadingAccountingIntegrationsSelector);
  const [continuousSync, setContinuousSync] = useState(false);

  const domainOptions = INTEGRATION_DOMAIN_OPTIONS.map((item) => ({
    label: `.${item}`,
    value: item,
  }));

  const client = useSelector(clientSelector);
  const currentSoftware = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.integrations.software
  );

  useEffect(() => {
    if (accountingAuthURL) {
      window.open(accountingAuthURL, "_self");
    }
  }, [accountingAuthURL]);

  const handleConnect = async () => {
    let action = null;
    switch (currentSoftware) {
      case ACCOUNTING_SOFTWARES.ZOHO:
        action = await dispatch(
          connectAccountingSoftware({
            slug: accountingSoftware,
            multi_currency_enabled:
              values?.currency === ACCOUNTING_CURRENCY_TYPES.MULTI,
            zohobooks_domain: values?.domain,
            continuous_bill_sync: continuousSync,
          })
        );
        break;
      default:
        action = await dispatch(
          connectAccountingSoftware({
            slug: accountingSoftware,
            multi_currency_enabled:
              values?.currency === ACCOUNTING_CURRENCY_TYPES.MULTI,
            continuous_bill_sync: continuousSync,
          })
        );
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.accounting.integrations.software
        );
        setSearchParams(searchParams);
    }

    if (connectAccountingSoftware.fulfilled.match(action))
      onAccountingIntegrationCompleted();
  };

  const onAccountingIntegrationCompleted = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.integrations.software);
    setSearchParams(searchParams);
    dispatch(fetchClient());
  };

  const initialValue = isZoho
    ? {
        currency: {
          value: "",
          validate: {
            required: true,
          },
        },
        domain: {
          value: domainSelected || "",
          validate: {
            required: true,
          },
        },
      }
    : {
        currency: {
          value: "",
          validate: {
            required: true,
          },
        },
      };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, () => {
      handleConnect();
    });

  return (
    <div className="flex flex-col slider-content-container ">
      <div className="flex flex-col gap-8 px-10 slider-content-core">
        <SliderHeader
          heading={`${ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingSoftware]} setup`}
          headingClasses="text-4xl font-bold"
          refFlag
        />
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-9 "
          id={`connect-${accountingSoftware}-software`}
        >
          <CurrencyComponent
            name="currency"
            handleChange={handleChange}
            value={values?.currency}
          />

          {isZoho ? (
            <div className="flex flex-col justify-center gap-3 ">
              <SliderHeader
                heading="accounting.integrations.selectDomain.title"
                subText="accounting.integrations.selectDomain.description"
                subTextTranslationProps={{
                  accountingSoftware:
                    ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingSoftware],
                }}
                headingClasses="text-lg font-semibold text-neutral-800"
                subTextClasses="text-sm font-medium text-neutral-500"
              />
              <VpSelect
                label="accounting.integrations.labels.selectAccount"
                placeholder="accounting.integrations.labels.selectAccount"
                value={values?.domain}
                error={errors?.domain}
                name="domain"
                optionsDisplayKey="label"
                valueKey="value"
                handleChange={handleChange}
                options={domainOptions}
                menuPosition="absolute"
                insideForm
              />
            </div>
          ) : null}

          <div className="flex flex-col gap-1 pb-9">
            <div className="flex items-center justify-between">
              <Text
                translationKey="accounting.integrations.continuouseSync.title"
                classes="text-lg font-semibold text-neutral-800"
              />
              <Switch
                value={continuousSync}
                handleChange={(val) => setContinuousSync(val)}
              />
            </div>
            <Text
              translationKey="accounting.integrations.continuouseSync.description"
              classes="text-sm font-medium text-neutral-500"
            />
          </div>
        </form>
      </div>
      <div className="sticky p-6 slider-footer ">
        <div className="flex justify-end ">
          <Button
            label="accounting.integrations.buttonLabel.connect"
            classes="w-14.75 px-5 py-3 text-base font-semibold"
            btnType="submit"
            disabled={isFormButtonDisabled}
            id={`connect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
            showLoader={isLoadingConnect}
            form={`connect-${accountingSoftware}-software`}
          />
          {isFormButtonDisabled ? (
            <Tooltip
              direction="top"
              id={`connect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
            >
              <Text
                translationKey={
                  isZoho
                    ? "accounting.integrations.toolTipText.allFieldsMandatory"
                    : "accounting.integrations.toolTipText.oneFieldMandatory"
                }
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}

CommonConnectSlider.propTypes = {
  accountingSoftware: PropTypes.string,
};
