import PropTypes from "prop-types";
import React from "react";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

function CardLimitCommentForm({
  formSchema,
  handleChange,
  outerSectionClasses,
  id,
  values,
  disabled = false,
  defaultValue,
  errors,
}) {
  return (
    <div className={outerSectionClasses}>
      <form id={id}>
        {formSchema.map((field, index) => {
          const { name, label, placeholder, type, mainHeading } = field;

          let FieldComponent = <></>;

          switch (type) {
            case "text":
              FieldComponent = (
                <div>
                  {mainHeading ? (
                    <div className="mb-9">
                      <Text
                        translationKey={mainHeading}
                        classes="text-lg font-semibold text-neutral-800"
                      />
                    </div>
                  ) : null}

                  <Input
                    name={name}
                    label={label}
                    disabled={disabled}
                    placeholder={placeholder}
                    type={type}
                    onChange={handleChange}
                    value={defaultValue ?? values[name]}
                    error={errors[name]}
                  />
                </div>
              );
              break;

            default:
              break;
          }

          return <div key={index}>{FieldComponent}</div>;
        })}
      </form>
    </div>
  );
}

export default CardLimitCommentForm;

CardLimitCommentForm.propTypes = {
  formSchema: PropTypes.array,
  handleChange: PropTypes.func,
  outerSectionClasses: PropTypes.string,
  id: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
};
