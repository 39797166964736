import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";

import Text from "../Text";

export default function Alert({
  icon = "Info",
  variant,
  title,
  titleTransalationProp,
  descriptionTransalationProp,
  titleClasses = "text-neutral-800",

  description,
  description2,
  description2TranslationProp,
  description2Classes,

  primaryAction,
  secondaryAction,
  sideAction,
  close,
  iconClasses,
  textClasses = "text-neutral-800 text-base font-semibold",
  descriptionClasses,
  wrapperDivClass = null,
  classes = "",
  actionSectionClasses,
}) {
  return (
    <div
      className={`${classes} flex border alert--${variant} justify-between px-4 py-3 rounded-lg`}
    >
      <div className="flex items-center justify-between w-full">
        <div
          className={`flex ${
            wrapperDivClass ? wrapperDivClass : "items-center"
          } gap-6`}
        >
          {icon ? (
            <div>
              <Icon
                name={icon}
                className={`w-6 h-6  ${
                  iconClasses?.includes("text")
                    ? iconClasses
                    : `text-neutral-500 ${iconClasses}`
                }`}
              />
            </div>
          ) : null}
          <div className="flex items-center justify-between">
            <div className={`flex flex-col ${textClasses}`}>
              {title ? (
                <Text
                  color="neutral-800"
                  classes={titleClasses}
                  translationKey={title}
                  translationProps={titleTransalationProp}
                />
              ) : null}
              {description ? (
                <Text
                  color="neutral-500"
                  classes={`text-sm ${
                    title ? "mt-1" : ""
                  } font-medium ${descriptionClasses}`}
                  translationKey={description}
                  translationProps={descriptionTransalationProp}
                />
              ) : null}

              {description2 ? (
                <Text
                  color="neutral-500"
                  classes={`text-sm mt-1 font-medium ${description2Classes}`}
                  translationKey={description2}
                  translationProps={description2TranslationProp}
                />
              ) : null}
              {(primaryAction || secondaryAction) && (
                <div
                  className={`flex items-center gap-4 mt-3 text-sm ${actionSectionClasses}`}
                >
                  {primaryAction ? (
                    <span onClick={primaryAction.callback}>
                      <Text
                        classes={`cursor-pointer primary-cta ${primaryAction?.classes}`}
                        translationKey={primaryAction.label}
                        translationProps={primaryAction?.labelTranslationProps}
                      />
                    </span>
                  ) : null}
                  {secondaryAction ? (
                    <span onClick={secondaryAction.callback}>
                      <Text
                        classes="cursor-pointer"
                        translationKey={secondaryAction.label}
                      />
                    </span>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
        {sideAction && (
          <div
            className={`flex items-center cursor-pointer gap-3 alert-text--${variant}`}
            onClick={sideAction.callback}
          >
            <Text translationKey={sideAction.label} />
            {/* <span>{sideAction.label}</span> */}
            <Icon name="EArrow" />
          </div>
        )}
      </div>
      {close && (
        <div onClick={close}>
          <Icon name="Close" className="w-3 h-3 mt-1 cursor-pointer " />
        </div>
      )}
    </div>
  );
}

Alert.propTypes = {
  icon: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string,
  titleClasses: PropTypes.string,
  description: PropTypes.string,
  description2: PropTypes.string,
  description2Classes: PropTypes.string,
  primaryAction: PropTypes.object,
  secondaryAction: PropTypes.object,
  sideAction: PropTypes.object,
  close: PropTypes.func,
  titleTransalationProp: PropTypes.object,
  descriptionTransalationProp: PropTypes.object,
  description2TranslationProp: PropTypes.object,
  iconClasses: PropTypes.string,
  textClasses: PropTypes.string,
  descriptionClasses: PropTypes.string,
  wrapperDivClass: PropTypes.string,
  classes: PropTypes.string,
  actionSectionClasses: PropTypes.string,
};
