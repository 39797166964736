import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function VendorNotSelected({ scrollToRef, inPayrollContext }) {
  return (
    <div className="p-4 flex gap-6 border-[1px] border-neutral-300 rounded-xl bg-neutral-50">
      <div>
        <Icon className="text-neutral-500 w-5 h-5 m-0.5" name="Info" />
      </div>
      <div>
        <Text
          translationKey={
            inPayrollContext
              ? "billPay.bill.invoiceInbox.createBill.sections.employeeNotPresent.title"
              : "billPay.bill.invoiceInbox.createBill.sections.vendorNotPresent.title"
          }
          classes="block font-semibold text-base text-neutral-800"
        />
        <Text
          translationKey={
            inPayrollContext
              ? "billPay.bill.invoiceInbox.createBill.sections.employeeNotPresent.text"
              : "billPay.bill.invoiceInbox.createBill.sections.vendorNotPresent.text"
          }
          classes="block mt-1 font-medium text-sm text-neutral-500"
        />
        <div
          onClick={() => {
            scrollToRef.current.scrollIntoView({ behavior: "smooth" });
          }}
          className="mt-3 text-sm font-semibold text-primary-500 underline cursor-pointer"
        >
          <Text
            translationKey={
              inPayrollContext
                ? "billPay.bill.invoiceInbox.createBill.sections.employeeNotPresent.chooseEmployeeLink"
                : "billPay.bill.invoiceInbox.createBill.sections.vendorNotPresent.chooseVendorLink"
            }
          />
        </div>
      </div>
    </div>
  );
}

VendorNotSelected.propTypes = {
  scrollToRef: PropTypes.any,
  inPayrollContext: PropTypes.bool,
};
