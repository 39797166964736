import {
  ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME,
  ACCOUNTING_TRANSACTIONS_CARDS_ALL_PATH_NAME,
  ACCOUNTING_TRANSACTIONS_CARDS_PENDING_PATH_NAME,
  ACCOUNTING_TRANSACTIONS_CARDS_SYNCED_PATH_NAME,
  ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME,
  ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME,
  ROUTES,
} from "./routes";
import { RULE_TYPES } from "./rules";

export const ACCOUNTING_TRANSACTION_STATUS = {
  PENDING: "pending", // Verify
  NOT_OKAY: "not_okay",
  VERIFIED: "verified", // Sync
  SYNC_IN_PROGRESS: "sync_in_progress",
  SYNCED: "synced",
  INCOMPLETE: "incompleted",
  NEED_DETAILS: "details_required",
  OKAY: "okay",
  SYNC_FAILED: "sync_failed", // Sync
  UNSYNCED: "unsynced", // Sync
  APPROVED: "approved",
  NOT_APPROVED: "not_approved",
  SETTLED: "settled",
  RESYNC: "resync",
  PAID: "paid",
  PAID_OUTSIDE_VOLOPAY: "paid_outside_volopay",
  PENDING_APPROVAL: "pending_approval",
  PROCESSING: "processing",
  DENIED: "denied",
};

export const ACCOUNTING_TRANSACTION_CTA = {
  PENDING: "pending", // Verify
  NOT_OKAY: "not_okay",
  VERIFIED: "verified", // Sync
  SYNC_IN_PROGRESS: "sync_in_progress",
  SYNCED: "synced",
  INCOMPLETE: "incompleted",
  NEED_DETAILS: "details_required",
  OKAY: "okay",
  SYNC_FAILED: "sync_failed", // Sync
  UNSYNCED: "unsynced", // Sync
  APPROVED: "approved",
  SETTLED: "settled",
  RESYNC: "resync",
  PAID: "paid",
};

export const ACCOUNTING_TRANSACTION_TABS = {
  ALL: "all",
  PENDING: "pending",
  SYNCED: "synced",
};

export const ACCOUNTING_PROVIDERS = {
  XERO: "Xero",
  XERO_CATEGORY: "Xero category",
  QUICK_BOOKS: "Quickbooks",
  QUICK_BOOKS_CATEGORY: "Quickbooks category",
};

export const ACCOUNTING_SOFTWARES = {
  XERO: "xero",
  QBO: "quickbooks",
  NETSUITE: "netsuite",
  DESKERA: "deskera",
  MYOB: "myob",
  TALLY: "tally",
  ZOHO: "zohobooks",
  UNIVERSAL_CSV: "universal_csv",
};

export const ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP = {
  [ACCOUNTING_SOFTWARES.XERO]: "Xero",
  [ACCOUNTING_SOFTWARES.QBO]: "QuickBooks",
  [ACCOUNTING_SOFTWARES.NETSUITE]: "NetSuite",
  [ACCOUNTING_SOFTWARES.DESKERA]: "Deskera",
  [ACCOUNTING_SOFTWARES.MYOB]: "MYOB",
  [ACCOUNTING_SOFTWARES.TALLY]: "Tally",
  [ACCOUNTING_SOFTWARES.ZOHO]: "ZohoBooks",
  [ACCOUNTING_SOFTWARES.UNIVERSAL_CSV]: "Universal CSV",
};

export const ACCOUNTING_SOFTWARES_ICONS = {
  [ACCOUNTING_SOFTWARES.XERO]: "Xero",
  [ACCOUNTING_SOFTWARES.QBO]: "Quickbooks",
  [ACCOUNTING_SOFTWARES.NETSUITE]: "Netsuite",
  [ACCOUNTING_SOFTWARES.DESKERA]: "Deskera",
  [ACCOUNTING_SOFTWARES.MYOB]: "MYOB",
  [ACCOUNTING_SOFTWARES.TALLY]: "Tally",
  [ACCOUNTING_SOFTWARES.ZOHO]: "Zohobooks",
  [ACCOUNTING_SOFTWARES.UNIVERSAL_CSV]: "UniversalCSV",
};

export const ACCOUNTING_SOFTWARES_TAG_TITLES = {
  [ACCOUNTING_SOFTWARES.XERO]: "Xero",
  [ACCOUNTING_SOFTWARES.QBO]: "Quickbooks",
  [ACCOUNTING_SOFTWARES.NETSUITE]: "Netsuite",
  [ACCOUNTING_SOFTWARES.DESKERA]: "Deskera",
  [ACCOUNTING_SOFTWARES.MYOB]: "MYOB",
  [ACCOUNTING_SOFTWARES.TALLY]: "Tally",
  [ACCOUNTING_SOFTWARES.ZOHO]: "Zohobooks",
  [ACCOUNTING_SOFTWARES.UNIVERSAL_CSV]: "Universal CSV",
};

export const ACCOUNTING_SOFTWARE_SLUG_TO_WEBSITE_MAP = {
  [ACCOUNTING_SOFTWARES.XERO]: "https://xero.com/",
  [ACCOUNTING_SOFTWARES.QBO]: "https://quickbooks.com",
  [ACCOUNTING_SOFTWARES.NETSUITE]: "https://netsuite.com",
  [ACCOUNTING_SOFTWARES.DESKERA]: "https://deskera.com",
  [ACCOUNTING_SOFTWARES.MYOB]: "https://myob.com",
  [ACCOUNTING_SOFTWARES.TALLY]: "https://tallysolutions.com",
  [ACCOUNTING_SOFTWARES.ZOHO]: "https://zoho.com/books",
  [ACCOUNTING_SOFTWARES.UNIVERSAL_CSV]: "https://www.volopay.com",
};

export const ACCOUNTING_SOFTWARE_CONNECT_SLIDER_SEARCHPARAM = {
  [ACCOUNTING_SOFTWARES.XERO]: "xero",
  [ACCOUNTING_SOFTWARES.QBO]: "quickbooks",
  [ACCOUNTING_SOFTWARES.NETSUITE]: "netsuite",
  [ACCOUNTING_SOFTWARES.DESKERA]: "deskera",
  [ACCOUNTING_SOFTWARES.MYOB]: "myob",
  [ACCOUNTING_SOFTWARES.TALLY]: "tally",
  [ACCOUNTING_SOFTWARES.ZOHO]: "zohobooks",
  [ACCOUNTING_SOFTWARES.UNIVERSAL_CSV]: "universal_csv",
};

export const ACCOUNTING_SOFTWARE_CONFIG = [
  {
    label: "accounting.integrations.xero.title",
    description: "accounting.integrations.xero.description",
    icon: ACCOUNTING_SOFTWARES_ICONS[ACCOUNTING_SOFTWARES.XERO],
    linkTo: ACCOUNTING_SOFTWARE_SLUG_TO_WEBSITE_MAP[ACCOUNTING_SOFTWARES.XERO],
    searchParam:
      ACCOUNTING_SOFTWARE_CONNECT_SLIDER_SEARCHPARAM[ACCOUNTING_SOFTWARES.XERO],
  },
  {
    label: "accounting.integrations.tally.title",
    description: "accounting.integrations.tally.description",
    icon: ACCOUNTING_SOFTWARES_ICONS[ACCOUNTING_SOFTWARES.TALLY],
    linkTo: ACCOUNTING_SOFTWARE_SLUG_TO_WEBSITE_MAP[ACCOUNTING_SOFTWARES.TALLY],
    searchParam:
      ACCOUNTING_SOFTWARE_CONNECT_SLIDER_SEARCHPARAM[
        ACCOUNTING_SOFTWARES.TALLY
      ],
  },
  {
    label: "accounting.integrations.myob.title",
    description: "accounting.integrations.myob.description",
    icon: ACCOUNTING_SOFTWARES_ICONS[ACCOUNTING_SOFTWARES.MYOB],
    linkTo:
      ACCOUNTING_SOFTWARE_SLUG_TO_WEBSITE_MAP[ACCOUNTING_SOFTWARES.NETSUITE],
    searchParam:
      ACCOUNTING_SOFTWARE_CONNECT_SLIDER_SEARCHPARAM[ACCOUNTING_SOFTWARES.MYOB],
  },
  {
    label: "accounting.integrations.quickbooks.title",
    description: "accounting.integrations.quickbooks.description",
    icon: ACCOUNTING_SOFTWARES_ICONS[ACCOUNTING_SOFTWARES.QBO],
    linkTo: ACCOUNTING_SOFTWARE_SLUG_TO_WEBSITE_MAP[ACCOUNTING_SOFTWARES.QBO],
    searchParam:
      ACCOUNTING_SOFTWARE_CONNECT_SLIDER_SEARCHPARAM[ACCOUNTING_SOFTWARES.QBO],
  },

  {
    label: "accounting.integrations.netsuite.title",
    description: "accounting.integrations.netsuite.description",
    icon: ACCOUNTING_SOFTWARES_ICONS[ACCOUNTING_SOFTWARES.NETSUITE],
    linkTo:
      ACCOUNTING_SOFTWARE_SLUG_TO_WEBSITE_MAP[ACCOUNTING_SOFTWARES.NETSUITE],
    searchParam:
      ACCOUNTING_SOFTWARE_CONNECT_SLIDER_SEARCHPARAM[
        ACCOUNTING_SOFTWARES.NETSUITE
      ],
  },
  {
    label: "accounting.integrations.deskera.title",
    description: "accounting.integrations.deskera.description",
    icon: ACCOUNTING_SOFTWARES_ICONS[ACCOUNTING_SOFTWARES.DESKERA],
    linkTo:
      ACCOUNTING_SOFTWARE_SLUG_TO_WEBSITE_MAP[ACCOUNTING_SOFTWARES.DESKERA],
    searchParam:
      ACCOUNTING_SOFTWARE_CONNECT_SLIDER_SEARCHPARAM[
        ACCOUNTING_SOFTWARES.DESKERA
      ],
  },
  {
    label: "accounting.integrations.universalCsv.title",
    description: "accounting.integrations.universalCsv.description",
    icon: ACCOUNTING_SOFTWARES_ICONS[ACCOUNTING_SOFTWARES.UNIVERSAL_CSV],
    linkTo:
      ACCOUNTING_SOFTWARE_SLUG_TO_WEBSITE_MAP[
        ACCOUNTING_SOFTWARES.UNIVERSAL_CSV
      ],
    searchParam:
      ACCOUNTING_SOFTWARE_CONNECT_SLIDER_SEARCHPARAM[
        ACCOUNTING_SOFTWARES.UNIVERSAL_CSV
      ],
  },
  {
    label: "accounting.integrations.zohobooks.title",
    description: "accounting.integrations.zohobooks.description",
    icon: ACCOUNTING_SOFTWARES_ICONS[ACCOUNTING_SOFTWARES.ZOHO],
    linkTo: ACCOUNTING_SOFTWARE_SLUG_TO_WEBSITE_MAP[ACCOUNTING_SOFTWARES.ZOHO],
    searchParam:
      ACCOUNTING_SOFTWARE_CONNECT_SLIDER_SEARCHPARAM[ACCOUNTING_SOFTWARES.ZOHO],
  },
];

export const ACCOUNTING_CURRENCY_TYPES = {
  SINGLE: "single",
  MULTI: "multi",
};

export const OTHER_TRANSACTION_STATUSES = {
  SETTLED: "settled",
  REJECTED: "rejected",
  PENDING: "pending",
};

export const ACCOUNTING_TRANSACTION_TYPES = {
  CREDIT: "external_credit",
  DEBIT: "external_debit",
  INVOICE_PAYMENT: "invoice_debit",
  CREDIT_PAYMENT: "credit_payment",
  CREDIT_FEE_PAYMENT: "credit_fee_payment",
  ADJUSTMENT: "adjustment",
  CASHBACK: "cashback",
  DECLINED: "declined",
  INTERNAL_CREDIT: "credit",
  INTERNAL_DEBIT: "debit",
  CREDIT_BILL_REPAYMENT: "credit_bill_repayment",
  CREDIT_LATE: "credit_late",
  ALLOCATION: "transfer_fees",
  TRANSFER_FEES: "transfer_fees",
  CREDIT_TYPE: "credit",
  CREDIT_TRANSFER_CREDIT: "credit_transfer_credit",
  CREDIT_TRANSFER_DEBIT: "credit_transfer_debit",
};

export const ACCOUNTING_TRANSACTION_ENTITY_TYPES = {
  CARD: "card",
  PAYMENT: "payment",
  REIMBURSEMENT: "reimbursement",
  OTHER: "other",
  QR_PAY: "qr-pay",
  PAYROLL: "payroll",
};

export const ACCOUNTING_TRANSACTION_PAGES = {
  EXPENSE: "expense",
  EXPENSES: "expenses",
  PURCHASE_BILL: "purchase_bill",
  REIMBURSEMENT: "reimbursement",
  REIMBURSEMENTS: "reimbursements",
  OTHERS: "others",
  OTHER: "other",
  BILL_PAY: "bill-pay",
  ANALYTICS_BILLPAY: "bill_pay",
  CARDS: "cards",
  QR_PAY: "qr_payments",
  QR_PAY_PARAM: "qrpay",
  PAYROLL: "payroll",
  PAYROLLS: "payrolls",
  QR_PAYMENTS: "QrPayments",
  LEDGER: "ledger",
};

export const ACCOUNTING_PAYMENTS_TAG_TYPES = {
  LINE_ITEM: "line_item",
  PAYROLL: "payroll",
};

export const ACCOUNTING_SYNC_ATTEMPT_ENTITY = {
  EXPENSE: "Expense",
  PURCHASE_BILL: "PurchaseBill",
  REIMBURSEMENT: "Reimbursement",
  OTHER: "AccountingTransaction",
  QR_PAY: "QrpayExpense",
  PAYROLL: "Payroll",
};

export const ACCOUTNING_TRANSACTION_SLIDER_SEARCH_PARAMS = {
  ACCOUNTING_REIMBURSEMENT: "accountingReimbursementId",
  ACCOUNTING_CARDS: "accountingCardsId",
  ACCOUNTING_BILLPAY: "accountingBillpayId",
  ACCOUNTING_OTHERS: "accountingOthersId",
  ACCOUNTING_QRPAY: "accountingQrpayId",
  ACCOUNTING_PAYROLL: "accountingPayrollId",
};

export const ACCOUTNING_TRANSACTION_SLIDER_SEARCH_PARAMS_TO_TRANSACTION_TYPE_MAP =
  {
    [ACCOUTNING_TRANSACTION_SLIDER_SEARCH_PARAMS.ACCOUNTING_CARDS]:
      ACCOUNTING_TRANSACTION_PAGES.EXPENSE,
    [ACCOUTNING_TRANSACTION_SLIDER_SEARCH_PARAMS.ACCOUNTING_BILLPAY]:
      ACCOUNTING_TRANSACTION_PAGES.BILL_PAY,
    [ACCOUTNING_TRANSACTION_SLIDER_SEARCH_PARAMS.ACCOUNTING_OTHERS]:
      ACCOUNTING_TRANSACTION_PAGES.OTHER,
    [ACCOUTNING_TRANSACTION_SLIDER_SEARCH_PARAMS.ACCOUNTING_REIMBURSEMENT]:
      ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT,
  };

// 'expense', 'purchase_bill', 'reimbursement', 'other', 'qrpay', 'payroll'

export const ACCOUNTING_TRANSACTIONS_CARDS_TAB_STATUS_MAP = {
  [ACCOUNTING_TRANSACTIONS_CARDS_ALL_PATH_NAME]: "", // default
  [ACCOUNTING_TRANSACTIONS_CARDS_PENDING_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
  [ACCOUNTING_TRANSACTIONS_CARDS_SYNCED_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.SYNCED,
};

export const ACCOUNTING_TRANSACTIONS_BILLPAY_TAB_STATUS_MAP = {
  [ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME]: "", // default
  [ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
  [ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.SYNCED,
};

export const ACCOUNTING_TRANSACTIONS_REIMBURSEMENT_TAB_STATUS_MAP = {
  [ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME]: "", // default
  [ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
  [ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.SYNCED,
};

export const ACCOUNTING_TRANSACTIONS_OTHERS_TAB_STATUS_MAP = {
  [ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME]: "", // default
  [ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
  [ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.SYNCED,
};

export const ACCOUNTING_TRANSACTIONS_QR_PAY_TAB_STATUS_MAP = {
  [ACCOUNTING_TRANSACTIONS_ALL_PATH_NAME]: "", // default
  [ACCOUNTING_TRANSACTIONS_PENDING_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
  [ACCOUNTING_TRANSACTIONS_SYNCED_PATH_NAME]:
    ACCOUNTING_TRANSACTION_STATUS.SYNCED,
};

export const ACCOUNTING_STATUS_CTA_MAP = {
  [ACCOUNTING_TRANSACTION_STATUS.PENDING]: {
    text: "accounting.transactions.cards.listingFields.action.actions.verify",
    color: "success",
    disabled: false,
    action: "verify",
  },
  [ACCOUNTING_TRANSACTION_STATUS.VERIFIED]: {
    text: "accounting.transactions.cards.listingFields.action.actions.sync",
    color: "success",
    disabled: false,
    action: "sync",
  },
  [ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED]: {
    text: "accounting.transactions.cards.listingFields.action.actions.verify",
    color: "success",
    disabled: false,
    action: "verify",
  },
  [ACCOUNTING_TRANSACTION_STATUS.SYNC_IN_PROGRESS]: {
    text: "accounting.transactions.cards.listingFields.action.actions.sync",
    color: "success",
    disabled: false,
    action: "sync",
  },
  [ACCOUNTING_TRANSACTION_STATUS.SYNCED]: {
    text: "accounting.transactions.cards.listingFields.action.actions.synced",
    color: "success",
    disabled: true,
    action: "sync",
  },
  [ACCOUNTING_TRANSACTION_STATUS.RESYNC]: {
    text: "accounting.transactions.cards.listingFields.action.actions.resync",
    color: "success",
    disabled: false,
    action: "sync",
  },
  [ACCOUNTING_TRANSACTION_STATUS.INCOMPLETE]: {
    text: "accounting.transactions.cards.listingFields.transactionStatus.status.pending",
    color: "warning",
    disabled: false,
    action: "verify",
  },
  [ACCOUNTING_TRANSACTION_STATUS.OKAY]: {
    text: "accounting.transactions.cards.listingFields.transactionStatus.status.okay",
    color: "success",
    disabled: true,
  },
  [ACCOUNTING_TRANSACTION_STATUS.SETTLED]: {
    text: "accounting.transactions.cards.listingFields.transactionStatus.status.settled",
    color: "success",
    disabled: true,
  },
  [ACCOUNTING_TRANSACTION_STATUS.PAID]: {
    text: "reimbursement.transactionStatus.paid",
    color: "success",
    disabled: true,
  },
  [ACCOUNTING_TRANSACTION_STATUS.APPROVED]: {
    text: "accounting.transactions.cards.listingFields.transactionStatus.status.approved",
    color: "success",
    disabled: true,
  },
  [ACCOUNTING_TRANSACTION_STATUS.NOT_OKAY]: {
    text: "accounting.transactions.cards.listingFields.transactionStatus.status.notOkay",
    color: "danger",
    disabled: true,
  },
};

export const TRANSACTION_STATUS_CTA_MAP = {
  [ACCOUNTING_TRANSACTION_STATUS.PENDING]: {
    text: "expenses.needsReview.badgeStatus.needsReview",
    color: "warning",
  },
  [ACCOUNTING_TRANSACTION_STATUS.PENDING_APPROVAL]: {
    text: "expenses.needsReview.badgeStatus.needsReview",
    color: "warning",
  },
  [ACCOUNTING_TRANSACTION_STATUS.PROCESSING]: {
    text: "billPay.bill.invoiceInbox.processing",
    color: "warning",
  },
  [ACCOUNTING_TRANSACTION_STATUS.VERIFIED]: {
    text: "accounting.ledger.unsyncTransaction.verified",
    color: "success",
  },
  [ACCOUNTING_TRANSACTION_STATUS.SYNCED]: {
    text: "accounting.transactions.cards.listingFields.action.actions.synced",
    color: "success",
  },
  [ACCOUNTING_TRANSACTION_STATUS.INCOMPLETE]: {
    text: "expenses.needsReview.badgeStatus.needsReview",
    color: "warning",
  },

  [ACCOUNTING_TRANSACTION_STATUS.OKAY]: {
    text: "expenses.needsReview.badgeStatus.approved",
    color: "success",
  },
  [ACCOUNTING_TRANSACTION_STATUS.SETTLED]: {
    text: "accounting.transactions.cards.listingFields.transactionStatus.status.settled",
    color: "success",
  },
  [ACCOUNTING_TRANSACTION_STATUS.PAID]: {
    text: "reimbursement.transactionStatus.paid",
    color: "success",
  },
  [ACCOUNTING_TRANSACTION_STATUS.PAID_OUTSIDE_VOLOPAY]: {
    text: "reimbursement.transactionStatus.paid",
    color: "success",
  },
  [ACCOUNTING_TRANSACTION_STATUS.APPROVED]: {
    text: "expenses.needsReview.badgeStatus.approved",
    color: "success",
  },
  [ACCOUNTING_TRANSACTION_STATUS.NOT_APPROVED]: {
    text: "accounting.transactions.cards.listingFields.transactionStatus.status.notApproved",
    color: "danger",
  },

  [ACCOUNTING_TRANSACTION_STATUS.DENIED]: {
    text: "accounting.transactions.cards.listingFields.transactionStatus.status.notApproved",
    color: "danger",
  },
  [ACCOUNTING_TRANSACTION_STATUS.NOT_OKAY]: {
    text: "accounting.transactions.cards.listingFields.transactionStatus.status.notOkay",
    color: "danger",
  },
  [ACCOUNTING_TRANSACTION_STATUS.NEED_DETAILS]: {
    text: "expenseStatuses.details_required",
    color: "warning",
  },
};

export const TAB_STATUS_MAP = {
  all: [
    ACCOUNTING_TRANSACTION_STATUS.VERIFIED,
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
    ACCOUNTING_TRANSACTION_STATUS.SYNC_IN_PROGRESS,
    ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED,
    ACCOUNTING_TRANSACTION_STATUS.SYNCED,
  ],
  pending: [
    ACCOUNTING_TRANSACTION_STATUS.VERIFIED,
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
    ACCOUNTING_TRANSACTION_STATUS.SYNC_IN_PROGRESS,
    ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED,
  ],
  synced: [ACCOUNTING_TRANSACTION_STATUS.SYNCED],
};

export const ACCOUNTING_HEADER_IDS = {
  MERCHANT: "merchant",
  MERCHANT_OR_MILEAGE: "merchantOrMileage",
  VENDOR: "vendor",
  EMPLOYEE: "employee",
  TRANSACTION_TYPE: "transactionType",
  TRANSACTION_STATUS: "transactionStatus",
  AMOUNT: "amount",
  OWNER: "owner",
  PAYMENT_DATE: "paymentDate",
  CARD_OWNER: "cardOwner",
  CREATED_BY: "createdBy",
  ACCOUNTING_TAG: "accountingTag",
  ACCOUNTING_PAYEE_ALIAS: "accountingPayeeAlias",
  ACCOUNTING_TAGS: "accountingTags",
  RECEIPT: "receipt",
  MEMO: "memo",
  NOTE: "note",
  INVOICE: "invoice",
  VENDOR_OWNER: "vendorOwner",
  NON_ACCOUNTING_TAG: "nonAccountingTag",
  VENDORS: "vendors",
  CUSTOM_TAG: "customTag",
  CUSTOM_TAGS: "customTags",
  ACTION: "action",
  OPEN_IN: "openIn",
  LINKED_TO: "transactionLinkedTo",
  STATUS: "status",
  TAG: "tag",
  LEDGER_DATE: "ledgerDate",
  ENTITY: "entity",
};

export const RULES_TABS = [
  {
    name: "accounting.rules.tabs.rules",
    count: null,
    key: 0,
    id: RULE_TYPES.NORMAL,
  },
  {
    name: "accounting.rules.tabs.advancedRules",
    count: null,
    key: 1,
    id: RULE_TYPES.ADVANCED,
  },
];

export const ACCOUNTING_TRANSACTION_CTA_KEYS = {
  APPROVE: "approve",
  FLAG: "flag",
  REQUEST_REPAYMENT: "repaymentRequested",
  REPAYMENT_RECEIVED: "repaymentReceived",
  REPAYMENT_CANCELLED: "repaymentCancelled",
  REPAYMENT_DECLINED: "repaymentDeclined",
  ACCOUNTING_VERIFY: "accountingVerify",
  ACCOUNTING_UNVERIFY: "accountingUnverify",
  ACCOUNTING_SYNC: "accountingSync",
  MARK_AS_PAID: "markAsPaid",
  MARK_AS_SYNCED: "mark-as-synced",
  SYNC: "sync",
  EXPORT: "export",
  VERIFY: "verify",
  UNVERIFY: "unverify",
};

export const ACCOUNTING_TRANSACTION_CTA_MAP = {
  [ACCOUNTING_TRANSACTION_CTA_KEYS.MARK_AS_SYNCED]: {
    label: "accounting.slider.markAsSynced",
    description: "accounting.slider.markAsSyncedDescriptions",
    action: ACCOUNTING_TRANSACTION_CTA_KEYS.MARK_AS_SYNCED,
  },
};

export const FAILED_TO_SYNC_TABLE_HEADERS = [
  "Sync attempted on",
  "Reason for failure",
];

export const FAILED_TO_SYNC_ACCOUNTING_TRANSACTION_MAP = {
  [ACCOUNTING_TRANSACTION_PAGES.CARDS]: "expense",
  [ACCOUNTING_TRANSACTION_PAGES.BILL_PAY]: "purchase_bill",
  [ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT]: "reimbursement",
  [ACCOUNTING_TRANSACTION_PAGES.OTHERS]: "other",
  [ACCOUNTING_TRANSACTION_PAGES.QR_PAY]: "qrpay",
  [ACCOUNTING_TRANSACTION_PAGES.PAYROLL]: "payroll",
};

export const ACCOUNTING_AMOUNT_SIGNS = {
  PLUS: "+",
  MINUS: "-",
};

export const ACCOUNTING_STATUS_FILTER_CONFIG = {
  [ACCOUNTING_TRANSACTION_TABS.PENDING]: [ACCOUNTING_TRANSACTION_STATUS.SYNCED],
  [ACCOUNTING_TRANSACTION_TABS.SYNCED]: [
    ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED,
    ACCOUNTING_TRANSACTION_STATUS.SYNC_IN_PROGRESS,
    ACCOUNTING_TRANSACTION_STATUS.VERIFIED,
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
  ],
};

export const ACCOUNTING_PAGES_TO_PARAMS_MAP = {
  [ACCOUNTING_TRANSACTION_PAGES.CARDS]: ACCOUNTING_TRANSACTION_PAGES.EXPENSE,
  [ACCOUNTING_TRANSACTION_PAGES.EXPENSES]: ACCOUNTING_TRANSACTION_PAGES.EXPENSE,
  [ACCOUNTING_TRANSACTION_PAGES.BILL_PAY]:
    ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL,
  [ACCOUNTING_TRANSACTION_PAGES.OTHERS]: ACCOUNTING_TRANSACTION_PAGES.OTHER,
  [ACCOUNTING_TRANSACTION_PAGES.PAYROLLS]: ACCOUNTING_TRANSACTION_PAGES.PAYROLL,
  [ACCOUNTING_TRANSACTION_PAGES.QR_PAY]:
    ACCOUNTING_TRANSACTION_PAGES.QR_PAY_PARAM,
  [ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT]:
    ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT,
  [ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENTS]:
    ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT,
};

export const ACCOUNTING_TAGS_CATEGORY_TITLE = {
  CATEGORY: "category",
};

export const ACCOUNTING_VENDOR_OPTIONS_CONFIG = {
  [ACCOUNTING_TRANSACTION_PAGES.EXPENSE]: "merchant",
};

export const ACCOUNTING_TAGS_VENDORS_LINE_ITEM_UPDATE_PARAMS = {
  LINE_ITEM: "line_item",
};

export const ACCOUNTING_TAB_TYPES = {
  BILL_PAY: "billpay",
  CARDS: "cards",
  OTHERS: "others",
  QR_PAY: "qrPay",
  REIMBURSEMENT: "reimbursement",
  PAYROLL: "payroll",
};

export const ACCOUNTING_LEDGER_CTA_KEYS = {
  REQUEST_STATEMENT: "requestStatement",
};

export const SYNC_TOAST_CONFIG = {
  sync: {
    description: "accounting.toaster.syncSuccess",
    variant: "success",
  },
  export: {
    // added it here cos when we move this to translation, the link is displaying the <a/> tag instead of the link itself
    description: `Transaction export is ready. Check your exports. <a style='color:#8ab4f8;text-decoration: underline;' href='${ROUTES.exports.base.absolutePath}'>Click here</a>`,
  },
};
