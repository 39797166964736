import { createSelector } from "@reduxjs/toolkit";

import { AVAILABLE_FILTERS } from "@/utils/constants/filters";

export const selectVendorsStore = (store) => store.vendors;

export const vendorsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.vendors
);

export const selectedVendorSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.selectedVendor
);
export const selectedVendorBankDetailsPresentSelector = createSelector(
  selectedVendorSelector,
  (vendors) => vendors?.bankDetailsPresent
);

export const vendorCreateRequirementsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.vendorCreateRequirements
);

export const filterKeysSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.filterKeys
);

export const vendorPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) => filterKeys.vendor.map((key) => AVAILABLE_FILTERS[key])
);
export const employeePageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) => filterKeys.employee.map((key) => AVAILABLE_FILTERS[key])
);

export const isUpdatedBankDetailsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isUpdatedBankDetails
);

export const bankDetailsFormSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.bankDetailsForm
);

export const bankDetailsFormReviewRowsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.bankDetailsFormReviewRows
);

export const vendorCreateFormSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.vendorCreateForm
);

export const isFetchingVendorCreateRequirementsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isFetchingVendorCreateRequirements
);

export const vendorsListSelector = createSelector(
  vendorsSelector,
  (vendors) => vendors.list
);

export const isFetchingVendorsSelector = createSelector(
  vendorsSelector,
  (vendors) => vendors.isFetching
);

export const apiCallFailedSelector = createSelector(
  vendorsSelector,
  (vendors) => vendors.apiCallFailed
);

export const isFetchingSelectedVendorSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isFetchingSelectedVendor
);

export const onboardingMailDetailsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.onboardingMailDetails
);

export const isFetchingOnboardingMailDetailsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isFetchingOnboardingMailDetails
);

export const vendorsTotalSelector = createSelector(
  vendorsSelector,
  (vendors) => vendors.total
);
export const vendorsTotalPagesSelector = createSelector(
  vendorsSelector,
  (vendors) => vendors.totalPages
);

export const vendorsPageSelector = createSelector(
  vendorsSelector,
  (vendors) => vendors.page
);

export const hasMoreVendorsSelector = createSelector(
  vendorsSelector,
  (vendors) => vendors.hasMore
);

export const vendorCountryCurrencyMappingsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.countryCurrencyMappings
);

export const isFetchingVendorCountryCurrencyMappingsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isFetchingCountryCurrencyMappings
);

export const vendorPaymentMethodsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.paymentMethods
);

export const isFetchingVendorPaymentMethodsSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isFetchingPaymentMethods
);

export const bankAndSwitfCodesSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.bankAndSwiftCodes
);

export const isFetchingBankAndSwitfCodesSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isFetchingBankAndSwiftCodes
);

export const cityAndRegionMappingSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.cityAndRegionMapping
);

export const isFetchingCityAndRegionMappingSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isFetchingCityAndRegionMapping
);

export const isLoadingSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isLoading
);

export const countriesForMailSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.countriesForMail
);

export const isFetchingCountriesForMailSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isFetchingCountriesForMailSelector
);

export const bankDetailsAlreadyAddedViaMailSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.bankDetailsAlreadyAddedViaMail
);

export const isResendVendorMailInProgressSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isResendVendorMailInProgress
);

export const isVerifyingVendorSelector = createSelector(
  selectVendorsStore,
  (vendors) => vendors.isVerifyingVendor
);
