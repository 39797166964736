import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import { amountToCurrency } from "@/utils/common";

export default function ApprovedAmount({ val }) {
  const isSame = val?.totalAmount?.currency === val?.approvedAmount?.currency;
  return (
    <div className="text-right">
      <p>{amountToCurrency(isSame ? val?.approvedAmount : val?.totalAmount)}</p>
      {isSame ? null : (
        <div className="flex items-center justify-end gap-1 text-neutral-500">
          <Icon name="World" className="w-3 h-3" />
          <p className="font-normal ">
            {amountToCurrency(val?.approvedAmount)}
          </p>
        </div>
      )}
    </div>
  );
}

ApprovedAmount.propTypes = {
  val: PropTypes.object,
};
