import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchChildLogs } from "@/store/reducers/master-history";

import { masterHistoryLogsListSelector } from "@/store/selectors/master-history";

import Text from "@/components/core/Text";

import MasterHistoryList from "@/components/Settings/MasterHistoryList";
import { getLocalTime } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { MASTER_HISTORY_ACTIVITY_TYPES_TITLES_MAP } from "@/constants/settings";

export default function MasterHistoryChildLogsSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const parentId = searchParams.get(SLIDERS_SEARCH_PARAMS.settings.logId);
  const parentLog = useSelector(masterHistoryLogsListSelector).find(
    (log) => log.id === parentId
  );

  const reff = useLeftHeaderTitle({
    title: parentLog?.title,
  });

  return (
    <div>
      <div className="flex flex-col px-9 gap-9">
        <div className="flex flex-col gap-1">
          <Text
            translationKey={parentLog?.title}
            classes="text-2xl font-bold text-neutral-800"
            refProp={reff}
          />
          <div className="flex items-center gap-1 text-sm font-medium text-neutral-500">
            <Text
              translationKey={
                MASTER_HISTORY_ACTIVITY_TYPES_TITLES_MAP[
                  parentLog?.activityType
                ]
              }
            />
            <Text translationKey="•" noTranslate />
            <Text translationKey={getLocalTime(parentLog?.createdAt)} />
          </div>
        </div>
        <MasterHistoryList parentId={parentId} />
      </div>
    </div>
  );
}
