export const REIMBURSEMENT_SETTINGS_TYPE = {
  REIM_PAYMENT_INITIATORS: "Settlement initiator",
};

export const ROLES_TYPE = {
  TOGGLE_PAYROLL: "TOGGLE_PAYROLL",
  PAYROLL_ADMINS: "Payroll admin",
  PAYROLL_MANAGERS: "Payroll manager",
  PAYROLL_MEMBERS: "Payroll member",
  PAYROLL_CLERKS: "Payroll clerk",
  PAYROLL_PAYMENT_INITIATORS: "Payroll initiator",
  ADMIN: "Admin",
  MEMBER: "Member",
  EXTERNAL_ACCOUNTANT: "External accountant",
  CARD_MANAGER: "Card manager",
  AP_CLERK: "AP clerk",
  PAYMENT_INITIATORS: "Payment initiator",
  CLAIM_MANAGER: "Claim manager",
  SETTLEMENT_INITIATOR: "Settlement initiator",
  DEPARTMENT_MANAGER: "Department manager",
  PROJECT_MANAGER: "Department manager",
  EXPENSE_MANAGER: "Expense manager",
};
