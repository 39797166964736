import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setIndexApiReload } from "@/store/reducers/app";
import {
  fetchAndSelectExpense,
  repaymentRequestCanceled,
} from "@/store/reducers/expense";

import { indexApiReloadSelector } from "@/store/selectors/app";
import { selectedExpenseSelector } from "@/store/selectors/expense";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import ExpenseSliderOverviewCards from "@/components/Expenses/ExpenseSliders/ExpenseSlider/ExpenseSliderOverviewCards";
import { ExpenseAndQRPayHeaderJsx } from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayHeader";
import FlagExpenseFooter from "@/components/common/QrPayAndExpense/common/Sliders/FlagExpenseAndQRPay/FlagExpenseAndQRPayFooter";
import FlagExpenseHeader from "@/components/common/QrPayAndExpense/common/Sliders/FlagExpenseAndQRPay/FlagExpenseAndQRPayHeader";
import { useForm } from "@/utils/useForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EXPENSE_FLAG_REPAYMENT_SLIDER } from "@/constants/expense";

export default function DeclineRepayment({ sliderType }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expenseId, setExpenseId] = useState(null);
  const dispatch = useDispatch();
  const expense = useSelector(selectedExpenseSelector);
  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const [submitKey, setSubmitKey] = useState("");
  const initialValue = {
    comment: {
      value: "",
      validate: {
        required: true,
      },
    },
  };
  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, (e, value, reset) => {
      const payload = {
        comment: value?.comment,
        approve: false,
      };
      const params = {
        params: { expenseId, payload, action_by_owner: true },
        reset: () => {
          dispatch(setIndexApiReload(!reloadIndexApi));
          removeSearchParams();
        },
      };
      dispatch(repaymentRequestCanceled(params));
    });
  const removeSearchParams = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.expenses.repaymentDeclined);
    setSearchParams(searchParams);
  };

  // use Effect
  useEffect(() => {
    const id = parseInt(
      searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.repaymentDeclined),
      10
    );

    if (id) setExpenseId(id);
  });
  useEffect(() => {
    if (expenseId) dispatch(fetchAndSelectExpense({ expenseId }));
  }, [expenseId]);

  const handleCtaClick = (ctaKey) => {
    setSubmitKey(ctaKey);
  };
  return expense ? (
    <div className=" slider-content-container">
      <div className="px-9 pb-15 slider-content-core">
        <FlagExpenseHeader
          title={EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.title}
          description={EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.description}
        />
        <ExpenseAndQRPayHeaderJsx
          transactionAmount={expense?.transactionAmount}
          transactionCurrencyCode={expense?.transactionCurrencyCode}
          settlementStatus={expense?.settlementStatus}
          amount={expense?.amount}
          currency={expense?.currency}
          transactionStatus={expense?.transactionStatus}
          updatedAt={expense?.expenseDate}
          merchantName={expense?.globalMerchant}
        />
        <ExpenseSliderOverviewCards />
        <form id="flag-expense-form" className="mt-13" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-8 pt-5">
            <Text
              classes="text-xl font-semibold  text-neutral-800"
              translationKey={
                EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.secondaryHeading
              }
            />

            <Input
              name="comment"
              value={values?.comment}
              onChange={handleChange}
              error={errors?.comment}
              label="expenses.flagTransaction.enterComment"
              placeholder="expenses.flagTransaction.enterComment"
            />
          </div>
        </form>
      </div>
      <div className="slider-footer">
        <FlagExpenseFooter
          onClick={handleCtaClick}
          type={sliderType}
          form="flag-expense-form"
          toggleSwitchTrue={
            EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.isSwitchThere
          }
          isSubmitButtonDisabled={isFormButtonDisabled}
          requestRepaymentTrue={values?.isRepaymentRequested}
        />
      </div>
    </div>
  ) : (
    "loading"
  );
}
DeclineRepayment.propTypes = {
  sliderType: PropTypes.string,
};
