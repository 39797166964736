import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import DistanceToAmount from "@/components/Reimbursement/CreateReimbursement/DistanceToAmount";
import MileageMap from "@/components/Reimbursement/CreateReimbursement/MileageMap";
import DateInputTextBox from "@/components/common/DateInputTextBox";
import { amountToCurrency, commaFormattedNumber } from "@/utils/common";

export default function ApprovalMileageRequestDetails({
  startLocation,
  endLocation,
  kms,
  mileageRate,
  totalAmount,
  travelDate,
  travelCountry,
  currency,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8 mt-7">
      <MileageMap
        preview
        selectedValues={{
          start: startLocation,
          end: endLocation,
          kms,
          travelCountry,
          totalAmount,
        }}
      />
      <DistanceToAmount
        leftValue={{
          translationKey: "distanceUnit",
          translationProps: { kms: commaFormattedNumber(kms) },
          distance: kms,
        }}
        rightValue={{
          translationKey: "reimbursement.createReimbursement.amountValue",
          translationProps: {
            amount: amountToCurrency(totalAmount, currency),
          },
          amount: totalAmount?.value,
          currency: totalAmount?.currency,
        }}
        leftText="reimbursement.createReimbursement.distanceTravelled"
        rightText="reimbursement.createReimbursement.amountHeading"
        description={{
          translationKey: "reimbursement.createReimbursement.mileageDesc",
          translationProps: {
            mileageRate,
            currency,
          },
        }}
      />

      <div className="mt-2">
        <DateInputTextBox
          name="travelDate"
          value={travelDate}
          disabled
          label="reimbursement.createReimbursement.travelDate"
        />
      </div>
    </div>
  );
}
ApprovalMileageRequestDetails.propTypes = {
  startLocation: PropTypes.string,
  endLocation: PropTypes.string,
  kms: PropTypes.object,
  mileageRate: PropTypes.object,
  totalAmount: PropTypes.object,
  travelDate: PropTypes.string,
  travelCountry: PropTypes.string,
  currency: PropTypes.string,
};
