import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

// eslint-disable-next-line import/no-cycle
import {
  areSameAddress,
  handleMobileVerificationSections,
  handlePersonalDetailsPage,
  handleVideoKycVerficationSection,
} from "@/components/Onboarding/util";
import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import vToast from "@/utils/vToast";

// ^ this is valid escape

import { ONBOARDING_STEPS } from "@/constants/onboarding";
import API from "@/api";

const initialState = {
  steps: [],
  currentOnboardingStep: null,
  isFetchingOnboardingSteps: false,
  isFetchingOnboardingSection: false,
  currentOnboardingSection: null,
  onboardingSection: null,
  formData: null,
  dataToset: null,
  isFetching: false,
  isFetchingOtp: false,
  otpSentCount: 0,
  isOtpVerified: false,
  isFetchingOtpVerfiedResponse: false,
  onboardingModal: false,
  confirmReviewModal: false,
  hasVkycLink: undefined,
  vkycInfo: {
    link: undefined,
    vkycLinkInProgress: false,
  },
  vkycState: "",
  vkycVerified: undefined,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setSteps(state, action) {
      state.steps = action.payload;
    },
    setCurrentOnboardingStep(state, action) {
      state.currentOnboardingStep = action.payload;
    },
    setCurrentOnboardingSection(state, action) {
      state.currentOnboardingSection = action.payload;
    },
    setOnboardingSection(state, action) {
      state.onboardingSection = action.payload;
    },
    setFormData(state, action) {
      state.formData = action.payload;
    },

    mergeIntoFormData(state, action) {
      if (
        state?.formData?.mobile_number &&
        state?.formData.mobile_number?.value !==
          action.payload?.mobile_number?.value
      ) {
        state.otpSentCount = 0;
      }
      state.formData = { ...state.formData, ...action.payload };
    },
    clearFormData(state) {
      state.formData = null;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsFetchingOtp(state, action) {
      state.isFetchingOtp = action.payload;
    },
    setOtpCount(state) {
      state.otpSentCount += 1;
    },
    setIsFetchingOtpVerifiedResponse(state, action) {
      state.isFetchingOtpVerfiedResponse = action.payload;
    },
    setIsOtpVerified(state, action) {
      state.isOtpVerified = action.payload;
    },
    toggleModal(state) {
      state.onboardingModal = !state.onboardingModal;
    },
    toggleConfirmReviewModal(state) {
      state.confirmReviewModal = !state.confirmReviewModal;
    },
    setPersonalDetailsInprogress(state, action) {
      state.inProgressPersonalDetails = action.payload;
    },
    setAddressDetailsInprogress(state, action) {
      state.addressDetailsInprogress = action.payload;
    },
    setIdentityDetailsInprogress(state, action) {
      state.identityDetailsInprogress = action.payload;
    },
    setvKycLinkInprogress(state, action) {
      state.vkycInfo.vkycLinkInProgress = action.payload;
    },
    setVKycLink(state, action) {
      state.vkycInfo.link = action.payload;
    },
    setVkycState(state, action) {
      state.vkycState = action.payload;
    },
    setIsFetchingOnboardingSteps(state, action) {
      state.isFetchingOnboardingSteps = action.payload;
    },
    setIsFetchingOnobardingSection(state, action) {
      state.isFetchingOnboardingSection = action.payload;
    },
    setVkycVerified(state, action) {
      state.vkycVerified = action.payload;
    },
  },
});

export const fetchOnboardingStepApi = createAsyncThunk(
  "onboarding/step",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingOnboardingSteps(true));
    const [error, response] = await to(API.Onboarding.onboardingStep());
    const [step = "", page = ""] =
      response?.data?.current_step?.split(".") ?? [];

    if (response) {
      dispatch(setSteps(response?.data?.steps));
      dispatch(setCurrentOnboardingStep(step));
      dispatch(setCurrentOnboardingSection(!page ? step : page));
    }
    if (error) {
      vToast({
        title: "reimbursement.toasterData.rejected.title",
        description: "reimbursement.toasterData.rejected.description",
        variant: "danger",
      });
    }
    dispatch(setIsFetchingOnboardingSteps(false));
  }
);

export const fetchOnboardingSectionApi = createAsyncThunk(
  "onboarding/section",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingOnobardingSection(true));

    const [error, response] = await to(
      API.Onboarding.onboardingSection({ section: params.step || params })
    );

    if (response?.data) {
      const sectionResponse = response?.data;
      const page = { label: null, step: sectionResponse?.key };

      if (params.subPage) {
        page.subPage = params.subPage;
      }

      if (page.step === ONBOARDING_STEPS.PERSONAL_DETAILS) {
        page.sections = handlePersonalDetailsPage(params, sectionResponse);
      } else if (page.step === ONBOARDING_STEPS.ADDRESS_DETAILS) {
        sectionResponse.fields[1].values.isSameChecked = areSameAddress(
          sectionResponse?.fields
        );
        page.sections = [sectionResponse];
      } else if (page.step === ONBOARDING_STEPS.IDENTITY_DETAILS) {
        page.sections = [sectionResponse];
      } else if (page.step === ONBOARDING_STEPS.MOBILE_VERIFICATION) {
        page.sections = handleMobileVerificationSections(
          params,
          sectionResponse,
          dispatch
        );
      } else if (page.step === ONBOARDING_STEPS?.VIDEO_KYC_PROCESS) {
        page.sections = handleVideoKycVerficationSection(
          params,
          sectionResponse
        );
      } else {
        page.sections = [sectionResponse];
      }
      page.isNonGeneric = sectionResponse?.isNonGeneric;

      dispatch(setOnboardingSection(page));
      if (params.onSuccess) {
        params.onSuccess(response?.data);
      }
    }
    dispatch(setIsFetchingOnobardingSection(false));
  }
);

export const verifyOtp = createAsyncThunk(
  "onboarding/verifyOtp",
  async (params, { dispatch }) => {
    const toast = {
      title: "",
      description: "",
      variant: "",
    };
    setIsFetchingOtpVerifiedResponse(true);
    setIsOtpVerified(false);

    const { param, onSuccess = () => {} } = params;
    const [error, response] = await to(API.Onboarding.submitKyc(param));

    if (response) {
      dispatch(setIsFetchingOtpVerifiedResponse(false));
      dispatch(setIsOtpVerified(true));

      if (onSuccess) {
        onSuccess();
      }

      toast.title =
        "onboarding.onboardingSteps.mobileVerification.toastMessages.verifyOtp.success.title";
      toast.description =
        "onboarding.onboardingSteps.mobileVerification.toastMessages.verifyOtp.success.description";
      toast.variant = "success";
    }
    if (error) {
      toast.title =
        "onboarding.onboardingSteps.mobileVerification.toastMessages.verifyOtp.fail.title";
      toast.description =
        "onboarding.onboardingSteps.mobileVerification.toastMessages.verifyOtp.fail.description";
      toast.variant = "danger";
    }
    vToast({ ...toast });
  }
);

export const submitKyc = createAsyncThunk(
  "onboarding/submitKyc",
  async (params, { dispatch }) => {
    const [error, response] = await to(API.Onboarding.submitKyc(params));
    if (response) {
      dispatch(setVkycState(response?.data?.kycState));
    }
  }
);

export const sendOtp = createAsyncThunk(
  "onboarding/sendOtp",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingOtp(true));
    const [error, response] = await to(API.Onboarding.sendOtp(params));
    const toast = { variant: "", title: "" };
    if (response) {
      dispatch(setOtpCount());
      toast.title =
        "onboarding.onboardingSteps.mobileVerification.toastMessages.sendOtp.success.title";
    }

    if (error) {
      toast.title =
        "onboarding.onboardingSteps.mobileVerification.toastMessages.sendOtp.fail.title";
      toast.variant = "danger";
    }
    dispatch(setIsFetchingOtp(false));

    vToast(toast);
  }
);

export const updateVkycVerified = createAsyncThunk(
  "onboarding/updateVkycVerfied",
  async (params, { dispatch }) => {
    dispatch(setVkycVerified(true));

    const { onSuccess } = params;
    const [error, response] = await to(API.Onboarding.vkycVerfied());
    const toast = { variant: "", title: "" };

    if (response) {
      if (onSuccess) {
        onSuccess();
      }
      toast.title =
        "onboarding.onboardingSteps.vkyc.toastMessages.updateVkycVerified.success";
      toast.variant = "success";
    } else if (error) {
      toast.title =
        "onboarding.onboardingSteps.vkyc.toastMessages.updateVkycVerified.fail";
      toast.variant = "danger";
    }

    dispatch(setVkycVerified(false));

    vToast(toast);
  }
);

export const updateReviewDetails = createAsyncThunk(
  "onboarding/updateReviewDetails",
  async (params, { dispatch }) => {
    dispatch(setIsFetching(true));
    const [error, response] = await to(API.Onboarding.reviewDetails());

    const { onSuccess = () => {} } = params;

    if (response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else if (error) {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsFetching(false));
  }
);

export const getVkycLink = createAsyncThunk(
  "onboarding/vKycLink",
  async (params, { dispatch }) => {
    dispatch(setvKycLinkInprogress(true));
    dispatch(setVKycLink(""));
    const [error, response] = await to(API.Onboarding.vkycLink(params));
    if (response) {
      dispatch(setVKycLink(response?.data));
      dispatch(setvKycLinkInprogress(false));
    }
  }
);

export const {
  setSteps,
  setCurrentOnboardingStep,
  setOnboardingSection,
  setFormData,
  mergeIntoFormData,
  clearFormData,
  setIsFetching,
  setPersonalDetailsInprogress,
  setIsFetchingOtp,
  setOtpCount,
  setIsFetchingOtpVerifiedResponse,
  setIsOtpVerified,
  toggleModal,
  toggleConfirmReviewModal,
  setCurrentOnboardingSection,
  setAddressDetailsInprogress,
  setIdentityDetailsInprogress,
  setvKycLinkInprogress,
  setVKycLink,
  setVkycState,
  setIsFetchingOnboardingSteps,
  setIsFetchingOnobardingSection,
  setVkycVerified,
} = onboardingSlice.actions;
export default onboardingSlice.reducer;
