import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

function ComingSoonBadge({ classes }) {
  return (
    <Text
      classes={`text-xs bg-primary-50 border rounded-full text-primary-300 px-2 font-semibold border-primary-200 ${classes}`}
      translationKey="common.comingSoon"
    />
  );
}

ComingSoonBadge.propTypes = {
  classes: PropTypes.string,
};

export default ComingSoonBadge;
