import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchExpenses,
  fetchStatistics,
  fetchUnsettledexpenses,
  resetExpensesListAndPagination,
} from "@/store/reducers/expense";
import { setSearchAndFilterCommonStoreStructure } from "@/store/reducers/filters";
import {
  fetchQrPayments,
  fetchQrPaymentsStatistics,
  fetchUnsettledQrPayments,
  resetQrPaymentsListAndPagination,
} from "@/store/reducers/qr-payments";

import { indexApiReloadSelector } from "@/store/selectors/app";
import { categoriesListSelector } from "@/store/selectors/categories";
import {
  expensesHasMoreSelector,
  expensesListSelector,
  isExpensesFetchingSelector,
  isFetchingUnsettledSelector,
  unsettledListSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isFetchingQrPayments,
  qrPaymentsHasMoreSelector,
  qrPaymentsListSelector,
} from "@/store/selectors/qr-payments";
import { userSelector } from "@/store/selectors/user";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VirtualizedTable from "@/components/core/VirtualizedTable";

import "@/components/Expenses/styles.scss";
import MemoNoteIcon from "@/components/common/MemoNoteIcon";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import ReceiptIcon from "@/components/common/ReceiptIcon";
import SubmissionPolicyIcon from "@/components/common/SubmissionPolicyIcon";
import TransactionStatusIcon from "@/components/common/TransactionStatusIcon";
import { convertFilters } from "@/utils/filters";
import {
  SORTING_CATEGORY,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";
import {
  AVAILABLE_FILTER_KEYS,
  TRANSACTION_TYPE_FILTER_VALUE,
  UNSETTLED,
} from "@/utils/constants/filters";
import {
  amountToCurrency,
  checkTwoObjectAreExactlySame,
  dateToString,
  groupByDateFlat,
} from "@/utils/common";

import {
  EXPENSE_SETTLEMENT_STATUS,
  OVERVIEW_TABLE_EXPENSES,
  OVERVIEW_TABLE_HEADER_KEY,
  SETTLED,
  UNSETTLED_EXPENSE_FILTER_CONFIG,
} from "@/constants/expense";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function OverviewTable({
  myVolopayContext,
  viewTransactionItem,
  page,
  filteringParams = {},
}) {
  const { t } = useTranslation();
  const prevAppliedFilter = useRef({});
  const dispatch = useDispatch();
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;
  const loggedInUser = useSelector(userSelector);
  const loggedInUserId = loggedInUser?.id;
  const reloadIndexApi = useSelector(indexApiReloadSelector);

  // Actions

  const EXPENSES_AND_QRPAYMENTS_CONFIG = {
    [QRPAY_EXPENSES_CONTEXT.EXPENSES]: {
      // selectors
      unsttledTransactions: useSelector(unsettledListSelector),
      transactionList: useSelector(expensesListSelector),
      isFetching: useSelector(isExpensesFetchingSelector),
      isFetchingUnsettled: useSelector(isFetchingUnsettledSelector),
      hasMore: useSelector(expensesHasMoreSelector),
      date: "expenseDate",

      // actions
      fetchTransactions: fetchExpenses,
      fetchTransactionStatistics: fetchStatistics,
      fetchUnsettledTransactions: fetchUnsettledexpenses,
      resetListAndPagination: resetExpensesListAndPagination,
    },
    [QRPAY_EXPENSES_CONTEXT.QRPAY]: {
      // selectors
      transactionList: useSelector(qrPaymentsListSelector),
      isFetching: useSelector(isFetchingQrPayments),
      isFetchingUnsettled: null,
      unsttledTransactions: null,
      hasMore: useSelector(qrPaymentsHasMoreSelector),
      date: "paymentDate",

      // actions
      fetchTransactions: fetchQrPayments,
      fetchTransactionStatistics: fetchQrPaymentsStatistics,
      fetchUnsettledTransactions: fetchUnsettledQrPayments,
      resetListAndPagination: resetQrPaymentsListAndPagination,
    },
  };

  const {
    transactionList,
    isFetching,
    isFetchingUnsettled,
    unsttledTransactions,
    hasMore,
    date,
    fetchTransactions,
    fetchTransactionStatistics,
    fetchUnsettledTransactions,
    resetListAndPagination,
  } = EXPENSES_AND_QRPAYMENTS_CONFIG[page];

  // common selectors for qrPay and expenses
  const categories = useSelector(categoriesListSelector);
  const appliedFilters = convertFilters(useSelector(appliedFilterSelector));

  const expenseTypeAppliedFilterValue = appliedFilters?.settlement_status;
  // useStates
  const [showUnSettled, setShowUnSettled] = useState(
    appliedFilters[AVAILABLE_FILTER_KEYS.settlementStatus] === UNSETTLED
  );

  const [showAllUnsettled, setShowAllUnsettled] = useState(
    appliedFilters[AVAILABLE_FILTER_KEYS.settlementStatus] === UNSETTLED
  );

  const [sorting, setSorting] = useState({
    type: SORTING_TYPE.DEC,
    category: SORTING_CATEGORY.DATE,
  });

  const filterOptions = {
    ...JSON.stringify(appliedFilters),
    ...filteringParams,
    type: sorting?.type,
    reloadIndexApi,
  };

  const showSection = !expenseTypeAppliedFilterValue
    ? !isFetchingUnsettled &&
      unsttledTransactions &&
      unsttledTransactions.length > 0
    : false;

  const handleSorting = (category) => {
    setSorting((prev) => {
      return {
        category,
        type:
          prev.type === SORTING_TYPE.INC ? SORTING_TYPE.DEC : SORTING_TYPE.INC,
      };
    });
  };

  // functions
  const loadMore = () => {
    const appliedFilterTransactionTypeValue = appliedFilters?.transaction_type;
    const transactionType = appliedFilterTransactionTypeValue
      ? appliedFilterTransactionTypeValue
      : [
          TRANSACTION_TYPE_FILTER_VALUE.PURCHASE,
          TRANSACTION_TYPE_FILTER_VALUE.PURCHASE_REVERSAL,
        ];

    const actionPayload = {
      page: pageNum,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      ...filteringParams,
      ...appliedFilters,
      [SORT_KEYS.COLUMN]: sorting?.category,
      [SORT_KEYS.DIRECTION]: sorting?.type,
    };

    if (isExpensesPage) {
      actionPayload.settlement_status =
        expenseTypeAppliedFilterValue ?? SETTLED;
      actionPayload.transaction_type = transactionType;
    }

    if (!showAllUnsettled && pageNum === 1) {
      dispatch(
        fetchUnsettledTransactions({
          ...appliedFilters,
          settlement_status: UNSETTLED,
          limit: 5,
          page: 1,
          transaction_type: transactionType,
          ...filteringParams,
        })
      );
    }

    const transactionStatistics = {
      ...(myVolopayContext ? { user: [loggedInUserId] } : {}),
      ...appliedFilters,
    };

    if (pageNum === 1) {
      dispatch(fetchTransactionStatistics(transactionStatistics));
    }

    dispatch(fetchTransactions(actionPayload));
  };

  const onReset = () => {
    dispatch(resetListAndPagination());
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  useEffect(() => {
    if (
      !checkTwoObjectAreExactlySame(appliedFilters, prevAppliedFilter.current)
    ) {
      setShowUnSettled(false);
      prevAppliedFilter.current = appliedFilters;
      setShowAllUnsettled(
        appliedFilters[AVAILABLE_FILTER_KEYS.settlementStatus] === UNSETTLED
      );
    }
  }, [appliedFilters]);

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  // get transactions (qrpayments and expenses) group by date
  const expensesGroupedByPendingAndDate = useMemo(() => {
    const groupedFlat = groupByDateFlat(transactionList, date);
    return showSection
      ? [{ isUnsettledShowRow: true, showUnSettled }]
          ?.concat(showUnSettled ? unsttledTransactions : [])
          ?.concat(
            unsttledTransactions?.length >= 5 && showUnSettled
              ? [{ isUnsettledShowRowBtn: true }]
              : []
          )
          ?.concat(groupedFlat)
      : groupedFlat;
  }, [
    JSON.stringify(transactionList),
    JSON.stringify(unsttledTransactions),
    showSection,
    showUnSettled,
  ]);

  const handleUnsettled = () => {
    dispatch(
      setSearchAndFilterCommonStoreStructure({
        [t(UNSETTLED_EXPENSE_FILTER_CONFIG.label)]: {
          levelPosition: UNSETTLED_EXPENSE_FILTER_CONFIG.levelPosition,
          param: UNSETTLED_EXPENSE_FILTER_CONFIG.param,
          filterLabel: UNSETTLED_EXPENSE_FILTER_CONFIG.filterLabel,
          values: UNSETTLED_EXPENSE_FILTER_CONFIG.values,
        },
      })
    );
  };
  const getComponent = (id, _data, index, tagId, value, _isActionLoader) => {
    if (value?.isDate)
      return id === OVERVIEW_TABLE_HEADER_KEY.DATE ? (
        <div className="text-xs font-semibold">{dateToString(value?.date)}</div>
      ) : (
        <div />
      );
    if (value?.isUnsettledShowRow) {
      switch (id) {
        case OVERVIEW_TABLE_HEADER_KEY.DATE:
          return (
            <div className="text-sm font-normal text-neutral-800">
              <div className="flex items-center gap-2">
                <Icon className="w-3 h-3 text-neutral-500" name="Hourglass" />
                <Text
                  color="neutral-800"
                  classes="text-xs font-medium"
                  translationKey="expenses.unsettled"
                />
              </div>
            </div>
          );
        case OVERVIEW_TABLE_HEADER_KEY.AMOUNT:
          return (
            <div className="flex flex-row justify-end flex-1 ">
              <Text
                classes="text-xs font-semibold text-neutral-500"
                translationKey={
                  value?.showUnSettled ? "misc.hide" : "misc.show"
                }
              />
              <Icon name={value?.showUnSettled ? "UpArrow" : "DownArrow"} />
            </div>
          );
        default:
          return "";
      }
    }
    if (value?.isUnsettledShowRowBtn) {
      switch (id) {
        case OVERVIEW_TABLE_HEADER_KEY.DATE:
          return (
            <div className="flex items-center gap-2 ">
              <Text translationKey="expenses.overview.showAllUnsettled" />
              <Icon name="ArrowForward" />
            </div>
          );

        default:
          return "";
      }
    }
    const isUnsettled =
      value?.settlementStatus === EXPENSE_SETTLEMENT_STATUS.UNSETTLED;
    switch (id) {
      case OVERVIEW_TABLE_HEADER_KEY.DATE:
        return (
          <ProfileWidget
            opacityChangeRatio={isUnsettled ? 4.5 : null}
            img={value?.vendor?.avatarUrl}
            name={value?.merchant?.name ?? value?.vendor?.name}
            className="gap-2"
            textClasses="text-sm font-semibold"
          >
            <div className="flex items-center gap-0.5">
              {value?.cardHolder?.displayName ? (
                <Text
                  translationKey={value?.cardHolder?.displayName}
                  classes="text-xs truncate text-neutral-500"
                />
              ) : null}

              {page === QRPAY_EXPENSES_CONTEXT.EXPENSES ? (
                <>
                  {value?.cardHolder?.displayName && value?.cardName ? (
                    <span className="profile-widget-dot text-neutral-500" />
                  ) : null}

                  {value?.cardName ? (
                    <>
                      <Text
                        translationKey={value?.cardName}
                        classes="text-xs truncate text-neutral-500"
                      />
                      {value?.cardNumber ? (
                        <span className="text-xs truncate text-neutral-500">
                          {` (${value?.cardNumber.slice(-4)})`}
                        </span>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}

              {value?.cardName && value?.projectName ? (
                <span className="profile-widget-dot text-neutral-500" />
              ) : null}

              {value?.projectName ? (
                <Text
                  translationKey={value?.projectName}
                  classes="text-xs truncate text-neutral-500"
                />
              ) : null}
            </div>
          </ProfileWidget>
        );
      case OVERVIEW_TABLE_HEADER_KEY.REVIEW_STATUS:
        return (
          <TransactionStatusIcon
            value={value?.transactionStatus}
            index={value?.id}
          />
        );

      case OVERVIEW_TABLE_HEADER_KEY.POLICY_STATUS:
        return (
          <div className="flex items-center justify-between flex-1 mx-5">
            <SubmissionPolicyIcon
              value={value?.submissionPolicyStatus}
              index={value?.id}
            />

            <ReceiptIcon
              index={value?.id}
              receiptStatus={value?.receiptStatus}
            />

            <MemoNoteIcon
              index={value?.id}
              value={value?.memo}
              required={value?.memoRequired}
            />
          </div>
        );

      case OVERVIEW_TABLE_HEADER_KEY.AMOUNT: {
        const isReversalExpense =
          value?.settlementStatus === EXPENSE_SETTLEMENT_STATUS.RELEASED ||
          value?.amount < 0;
        return (
          <div className="flex items-center justify-end flex-1 font-semibold text-left text-neutral-800">
            <span>
              <div className="flex items-center">
                {value?.cashback?.amount !== 0 && (
                  <span id={`cashback-${value.id}`}>
                    <Icon
                      name="Exchange"
                      className="w-3.5 h-3.5 text-success-500"
                    />

                    <Tooltip id={`cashback-${value.id}`} direction="top">
                      <Text
                        translationKey="expenses.cashbackEarned"
                        translationProps={{
                          cashback: amountToCurrency(
                            value?.cashback?.amount,
                            value?.currency
                          ),
                          cashbackPercent: value?.cashback?.rate,
                        }}
                        classes="ml-2"
                      />
                    </Tooltip>
                  </span>
                )}
                <span
                  className={`ml-2 text-sm w-full flex justify-end ${
                    isReversalExpense
                      ? "text-success-600"
                      : isUnsettled
                        ? "text-neutral-500"
                        : "first-letter:"
                  }`}
                >
                  {isReversalExpense ? " + " : ""}
                  {amountToCurrency(Math.abs(value?.amount), value?.currency)}
                </span>
              </div>

              {value?.amount !== value?.transactionAmount &&
              value?.currency !== value?.transactionCurrencyCode ? (
                <span className="flex items-center justify-end text-xs font-medium text-neutral-400">
                  <Icon name="World" className="w-3 h-3" />
                  <span className="ml-1">
                    {amountToCurrency(
                      value?.transactionAmount,
                      value?.transactionCurrencyCode
                    )}
                  </span>
                </span>
              ) : null}
            </span>
          </div>
        );
      }
      default:
        break;
    }
    return "-";
  };

  const getTableRowClasses = (row) => {
    if (row?.isDate || row?.isUnsettledShowRow) return "h-9 cursor-pointer";
    if (row?.isUnsettledShowRowBtn)
      return "!bg-neutral-100 text-primary-500 cursor-pointer";
  };
  const getExtraClasses = (row) =>
    row?.isUnsettledShowRow || row?.isUnsettledShowRowBtn
      ? "!bg-neutral-50"
      : "";

  return (
    <VirtualizedTable
      onSortingChange={setSorting}
      isLoading={isFetching || isFetchingUnsettled}
      data={expensesGroupedByPendingAndDate}
      handleRefChange={(ref, index) => {
        if (
          hasMore &&
          index === (expensesGroupedByPendingAndDate?.length ?? 0) - 1
        ) {
          handleRefChange(ref);
        }
      }}
      onRowClick={(rowData) => {
        if (rowData?.isUnsettledShowRow) {
          setShowUnSettled((prev) => !prev);

          if (unsttledTransactions && unsttledTransactions.length >= 5)
            setShowAllUnsettled((prev) => !prev);
        } else if (rowData?.isUnsettledShowRowBtn) {
          handleUnsettled();
        } else if (!rowData?.isDate) viewTransactionItem(rowData?.id);
      }}
      headerStick
      colWidths={[500, 100, 100, 100]}
      emptyDataTitle="expenses.declines.tableEmptyStates.title"
      emptyDataDescription="expenses.declines.tableEmptyStates.description"
      showCheckBoxRow={false}
      headerConfig={OVERVIEW_TABLE_EXPENSES}
      tableRowClasses={getTableRowClasses}
      extraCellClass={getExtraClasses}
      getCellComponent={(rowData, headerId, header, isActionLoader) => {
        return getComponent(
          headerId,
          rowData?.getValue(),
          rowData?.row?.index,
          header.tagId,
          rowData?.row?.original,
          isActionLoader
        );
      }}
    />
  );
}

OverviewTable.propTypes = {
  viewTransactionItem: PropTypes.func,
  page: PropTypes.string,
  filteringParams: PropTypes.object,
  myVolopayContext: PropTypes.bool,
};
