import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";

import PeoplePage from "@/pages/people";
import RolesPermissionsPage from "@/pages/people/rolesPermissions";
import UsersPage from "@/pages/people/users";
import PeoplePageAll from "@/pages/people/users/all";
import PeoplePageBlocked from "@/pages/people/users/blocked";
import { ROUTES } from "@/constants/routes";

export default function PeopleRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/*" element={<PeoplePage />} />

        <Route
          path={ROUTES.manage.people.users.pathName}
          element={<UsersPage />}
        >
          <Route
            path={ROUTES.manage.people.users.all.pathName}
            element={<PeoplePageAll />}
          />
          <Route
            path={ROUTES.manage.people.users.blocked.pathName}
            element={<PeoplePageBlocked />}
          />
        </Route>

        <Route
          path={ROUTES.manage.people.rolesPermissions.pathName}
          element={<RolesPermissionsPage />}
        />
      </Route>
    </Routes>
  );
}
