import PropTypes from "prop-types";
import { useRef } from "react";
import Popup from "reactjs-popup";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import SliderBar from "@/components/core/SliderBar/index";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { useForm } from "@/utils/useForm";
import { UTILIZATION_SEARCH_PARAMS } from "@/utils/constants/filters";

/**
 * A component for filtering utilization.
 *
 * @param {String} filterKey - The key for the filter.
 * @param {String} label - The label for the filter.
 * @param {String} icon - The icon for the filter.
 * @param {Object} defaultValues - The default values for the filter.
 * @param {Function} callback - A callback function to handle filter changes.
 * @param {Boolean} disabled - Indicates whether the filter is disabled.
 * @returns {JSX.Element} - The rendered UtilizationFilter component.
 */

export default function UtilizationFilter({
  filterKey,
  label,
  icon,
  defaultValues,
  callback,
  disabled,
}) {
  const popupRef = useRef();
  const initialValue = {
    [UTILIZATION_SEARCH_PARAMS.minUtilization]: {
      value: defaultValues?.utilization?.minUtilization?.value,
    },
    [UTILIZATION_SEARCH_PARAMS.maxUtilization]: {
      value: defaultValues?.utilization?.maxUtilization?.value,
    },
  };

  const {
    handleChange,
    values,
    errors,
    _setValues: handleReset,
    isFormButtonDisabled,
    handleSubmit,
  } = useForm(initialValue, (e) => {
    callback({
      [filterKey]: {
        [UTILIZATION_SEARCH_PARAMS.minUtilization]: {
          value: values?.[UTILIZATION_SEARCH_PARAMS.minUtilization],
        },
        [UTILIZATION_SEARCH_PARAMS.maxUtilization]: {
          value: values?.[UTILIZATION_SEARCH_PARAMS.maxUtilization],
        },
      },
    });
    popupRef.current.close();
  });

  return (
    <>
      <Popup
        ref={popupRef}
        trigger={
          <button
            id={`amount-filter-${filterKey}`}
            className="flex items-center h-10 gap-2 py-3 card-wrapper text-neutral-500"
          >
            <Icon name={icon} className="w-4 h-4" />
          </button>
        }
        closeOnDocumentClick
        position="bottom left"
        className="filter-popup"
        keepTooltipInside="#tooltip-inside"
      >
        <form
          onSubmit={handleSubmit}
          onReset={(e) => {
            handleReset({
              [UTILIZATION_SEARCH_PARAMS.minUtilization]:
                values?.[UTILIZATION_SEARCH_PARAMS.minUtilization],
              [UTILIZATION_SEARCH_PARAMS.maxUtilization]:
                values?.[UTILIZATION_SEARCH_PARAMS.maxUtilization],
            });
            popupRef.current.close();
          }}
          className="flex flex-col gap-5 py-3 "
        >
          <div className="flex items-center justify-between my-2 px-7">
            <SliderBar
              minUtilization={
                values?.[UTILIZATION_SEARCH_PARAMS.minUtilization]
              }
              maxUtilization={
                values?.[UTILIZATION_SEARCH_PARAMS.maxUtilization]
              }
              onChange={(newMin, newMax) => {
                handleReset({
                  [UTILIZATION_SEARCH_PARAMS.minUtilization]: newMin,
                  [UTILIZATION_SEARCH_PARAMS.maxUtilization]: newMax,
                });
              }}
              disabled={disabled}
              thumbClasses="top-11 w-10 text-xs font-semibold border rounded-lg border-neutral-300 bg-neutral-50"
              percentToSetLeftPositionFromLeft={1.17}
              percentToSetLeftPositionFromRight={1.17}
              size="w-[400px]"
              variant="bg-primary-500"
            />
          </div>

          <div className="pt-3 border-t mt-7 border-neutral-300">
            <div className="flex justify-end gap-3 px-7">
              <Button
                btnType="reset"
                label="filter.amountButton.cancel"
                type="default"
                variant="tertiary"
                classes="w-13.5 h-10 text-neutral-500"
              />
              <Button
                btnType="submit"
                name="formSubmit"
                label="filter.amountButton.apply"
                classes="w-13.5 h-10"
              />
            </div>
          </div>
        </form>
      </Popup>

      <Tooltip id={`amount-filter-${filterKey}`} direction="bottom">
        <Text translationKey={label} />
      </Tooltip>
    </>
  );
}

UtilizationFilter.propTypes = {
  filterKey: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  defaultValues: PropTypes.object,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
};
