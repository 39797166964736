import LoaderSkeleton from "@/components/core/LoaderSkeleton";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function ExportTableLoader() {
  return Array(PAGINATION_PER_REQUEST_LIMIT)
    .fill(null)
    .map((_, idx) => (
      <tr key={`ExportTableLoader-${idx}`}>
        <td>
          <div className="flex items-center gap-3 px-0">
            <LoaderSkeleton type="circle" />
            <div className="flex flex-col">
              <LoaderSkeleton size={[20, 120]} />
              <LoaderSkeleton size={[20, 120]} />
            </div>
          </div>
        </td>
        <td>
          <LoaderSkeleton />
        </td>
        <td>
          <LoaderSkeleton />
        </td>
        <td className="text-right">
          <LoaderSkeleton size={[20, 120]} />
        </td>
        <td className="text-right">
          <LoaderSkeleton size={[20, 120]} />
        </td>
      </tr>
    ));
}
