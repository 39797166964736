// react imports
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchProjects } from "@/store/reducers/company";

import { projectsSelector } from "@/store/selectors/company";

// core components
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import {
  CREATE_VIRTUAL_CARD_FIELD_KEYS,
  LINKED_TO_TYPE,
} from "@/constants/Cards";

export default function LinkCardToField({
  name,
  handleChange,
  value,
  linkedToFields,
  values,
  linkedToMainLabel = "Link card to",
  linkedToMainDescription = "This card spent will be linked to the selected department or project.",
  error,
  projectsList = [],
}) {
  const selectOption = linkedToFields?.selectOption;
  const radioOptions = linkedToFields?.radioOptions;
  const handleLinkedToRadioButtonsChange =
    linkedToFields?.handleLinkedToRadioButtonsChange;
  const showErrorStatement =
    error && !values[CREATE_VIRTUAL_CARD_FIELD_KEYS.PROJECT_ID];

  return (
    <div className="flex flex-col float-none w-full p-4 rounded-lg cursor-pointer mb-7 bg-neutral-50">
      <Text
        translationKey={linkedToMainLabel}
        classes="text-base font-semibold text-neutral-800"
      />
      <Text
        translationKey={linkedToMainDescription}
        classes="mb-4 text-xs font-semibold text-neutral-500"
      />
      <div className="flex flex-col gap-3">
        {radioOptions.map((option, index) => {
          const radioValue = option?.value;
          return (
            <LinkedToRadioField
              name={name}
              description={option?.description}
              label={option?.label}
              radioValue={radioValue}
              handleChange={handleChange}
              value={value}
              error={error}
              key={`${name}.${index}`}
            />
          );
        })}
      </div>

      {value === LINKED_TO_TYPE.PROJECT.toLowerCase() && (
        <div className="mt-5">
          <VpSelect
            disabled={!values[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER]}
            menuPosition="absolute"
            optionsDisplayKey="name"
            valueKey="id"
            options={projectsList}
            label={selectOption.label}
            placeholder={selectOption.label}
            handleChange={handleLinkedToRadioButtonsChange}
            name={selectOption.name}
            value={values[selectOption.name]}
            insideForm
          />
        </div>
      )}

      {showErrorStatement ? (
        <Text
          classes="inline-block text-xs text-danger-600"
          translationKey={error}
        />
      ) : null}
    </div>
  );
}

LinkCardToField.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  linkedToFields: PropTypes.object,
  values: PropTypes.object,
  linkedToMainLabel: PropTypes.string,
  linkedToMainDescription: PropTypes.string,
  error: PropTypes.string,
  projectsList: PropTypes.array,
};

const LinkedToRadioField = ({
  description,
  label,
  radioValue,
  handleChange,
  value,
  name,
}) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <Radio
        name={name}
        value={radioValue}
        isChecked={value === radioValue}
        handleChange={(e) => {
          handleChange(e);
        }}
        insideForm
      />
      <div className="flex flex-col self-start ">
        <Text
          translationKey={label}
          classes="text-base font-semibold text-neutral-800"
        />
        <Text
          translationKey={description}
          classes="text-sm font-semibold text-neutral-500"
        />
      </div>
    </div>
  );
};

LinkedToRadioField.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  radioValue: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
};
