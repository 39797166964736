import { Route, Routes } from "react-router-dom";

// components
import ProtectedRoute from "@/components/ProtectedRoute";

import Expenses from "@/pages/expenses";
import ExpenseDeclines from "@/pages/expenses/declines";
import ExpenseFlagged from "@/pages/expenses/flagged";
import ExpensesNeedsReview from "@/pages/expenses/needs-review";
import ExpensesNRAllTab from "@/pages/expenses/needs-review/all";
import ExpensesNRNRTab from "@/pages/expenses/needs-review/needs-your-review";
import ExpensesNRPendingTab from "@/pages/expenses/needs-review/pending";
import ExpensesOverview from "@/pages/expenses/overview";
import { ROUTES } from "@/constants/routes";

export default function ExpensesRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/*" element={<Expenses />} />
        <Route
          path={ROUTES.cards.expenses.overView.pathName}
          element={<ExpensesOverview />}
        />
        <Route
          path={ROUTES.cards.expenses.needsReview.pathName}
          element={<ExpensesNeedsReview />}
        >
          <Route
            path={`${ROUTES.cards.expenses.needsReview.needsYourReview.pathName}`}
            element={<ExpensesNRNRTab />}
          />
          <Route
            path={`${ROUTES.cards.expenses.needsReview.pending.pathName}`}
            element={<ExpensesNRPendingTab />}
          />
          <Route
            path={`${ROUTES.cards.expenses.needsReview.all.pathName}`}
            element={<ExpensesNRAllTab />}
          />
        </Route>
        <Route
          path={ROUTES.cards.expenses.flagged.pathName}
          element={<ExpenseFlagged />}
        />
        <Route
          path={ROUTES.cards.expenses.declines.pathName}
          element={<ExpenseDeclines />}
        />
      </Route>
    </Routes>
  );
}
