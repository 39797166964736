import PropTypes from "prop-types";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { ACCOUNT_TYPE } from "@/utils/constants/client";

import { ACCOUNT_CTAS, ACCOUNT_CTAS_CONFIG } from "@/constants/company";

import BalanceCard from "./BalanceCard";

export default function AyoConnectAccountWidget({ accountType, widgetInfo }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const config =
    accountType === ACCOUNT_TYPE.PAYMENTS
      ? [
          {
            rightContent: {
              classes: "text-sm font-medium text-neutral-500",
              text: "Processing payments",
              preIcon: "Timer",
            },
            leftContent: {
              text: `${widgetInfo?.assignedToPayments?.value} ${widgetInfo?.assignedToPayments?.currency}`,
              classes: "text-neutral-800 text-sm font-medium",
            },
          },
        ]
      : [
          {
            rightContent: {
              classes: "text-sm font-medium text-neutral-500",
              text: "Unallocated funds",
              preIcon: "InCompleteCircle",
            },
            leftContent: {
              text: `${widgetInfo?.unallocatedAmount?.value} ${widgetInfo?.unallocatedAmount?.currency}`,
              classes: "text-neutral-800 text-sm font-medium",
            },
          },
          {
            rightContent: {
              classes: "text-sm font-medium text-neutral-500",
              text: "Unsettled expenses",
              preIcon: "CreditCard",
            },
            leftContent: {
              text: `${widgetInfo?.unsettledAmount?.value} ${widgetInfo?.unsettledAmount?.currency}`,
              classes: "text-danger-600 text-sm font-medium",
            },
          },
        ];

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row justify-between">
        <BalanceCard
          label={
            <span>
              {widgetInfo?.currency} <Text translationKey="common.balance" />
            </span>
          }
          iconName={
            accountType === ACCOUNT_TYPE.CARDS ? "CreditCard" : "BillPay"
          }
          iconClasses="text-primary-500"
          iconBgClasses="bg-primary-50"
          amount={widgetInfo?.availableBalance}
          currency={widgetInfo?.currency}
        />

        <div id="right" className="flex items-start gap-2">
          {Object?.values(ACCOUNT_CTAS)?.map((item) =>
            widgetInfo?.ctas?.includes(item) ? (
              <div
                className="flex flex-col items-center hover:cursor-pointer w-13.5"
                onClick={() =>
                  ACCOUNT_CTAS_CONFIG[item]?.onClick({
                    navigate,
                    setSearchParams,
                    account: widgetInfo,
                  })
                }
                key={`${ACCOUNT_CTAS_CONFIG[item]?.icon}-${ACCOUNT_CTAS_CONFIG[item]?.text}`}
              >
                <Icon
                  name={ACCOUNT_CTAS_CONFIG[item]?.icon}
                  variant="rounded"
                  bgClassName={
                    ACCOUNT_CTAS_CONFIG[item]?.disabled
                      ? "text-neutral-300 bg-neutral-50  "
                      : "text-primary-500 bg-primary-50"
                  }
                  className={
                    ACCOUNT_CTAS_CONFIG[item]?.disabled
                      ? "text-netural-400 bg-neutral-50 w-5 h-5"
                      : "bg-primary-50 text-primary-400 w-5 h-5"
                  }
                />
                <Text
                  translationKey={ACCOUNT_CTAS_CONFIG[item]?.text}
                  classes={`text-xs font-semibold mt-2 ${
                    ACCOUNT_CTAS_CONFIG[item]?.disabled
                      ? "text-neutral-300"
                      : "text-neutral-500"
                  } text-center`}
                />
              </div>
            ) : null
          )}
        </div>
      </div>

      <div
        id="lower"
        className="flex flex-col gap-2 p-4 rounded-md bg-neutral-50"
      >
        {config?.map((rowInfo, index) => {
          const rightContent = rowInfo?.rightContent;
          const leftContent = rowInfo?.leftContent;

          return (
            <div
              className="flex items-center justify-between"
              key={`${index}-${rightContent?.text}`}
            >
              <div className="flex items-center gap-2">
                <Icon
                  name={rightContent?.preIcon}
                  className="w-3 text-neutral-500"
                />

                <Text
                  translationKey={rightContent?.text}
                  classes="text-neutral-500 text-sm font-medium"
                />
              </div>

              <Text
                translationKey={leftContent?.text}
                classes={leftContent?.classes}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

AyoConnectAccountWidget.propTypes = {
  accountType: PropTypes.string,
  widgetInfo: PropTypes.object,
};
