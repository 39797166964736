import { Route, Routes } from "react-router-dom";

// constants imports
import GeneralSettings from "@/components/common/BillPayAndPayroll/Settings/General";
import SpendControlsSettings from "@/components/common/BillPayAndPayroll/Settings/SpendControls";

// components imports
import PayrollSettings from "@/pages/payroll/payroll-settings";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { ROUTES } from "@/constants/routes";

export default function PayrollSettingsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PayrollSettings />}>
        <Route
          path={ROUTES.payroll.settings.general.pathName}
          element={<GeneralSettings context={BILL_PAYROLL_CONTEXT.PAYROLL} />}
        />

        <Route
          path={ROUTES.payroll.settings.spendControls.pathName}
          element={
            <SpendControlsSettings context={BILL_PAYROLL_CONTEXT.PAYROLL} />
          }
        />
      </Route>
    </Routes>
  );
}
