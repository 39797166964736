import ProfileWidget from "@/components/core/ProfileWidget";

export function vendorDropdownOption(displayValue, option) {
  const vendor = option;

  return (
    <ProfileWidget
      name={vendor?.name}
      className="text-neutral-500"
      textClasses="text-neutral-500"
    >
      <span className="text-xs font-medium text-neutral-400">
        {[vendor?.contactName, vendor?.contactEmail]
          .filter(Boolean)
          .join(" • ")}
      </span>
    </ProfileWidget>
  );
}
