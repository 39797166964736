import { createSelector } from "@reduxjs/toolkit";

import {
  isAdminSelector,
  navigationConfigSelector,
} from "@/store/selectors/user";

import { ROUTES } from "@/constants/routes";

import { accountFreezeSelector } from "./client";

const navLinks = {
  alerts: {
    title: "bottomNav.alerts",
    icon: "Notification",
    path: "alerts",
    key: "alerts",
    isExternalLink: false,
  },
  helpCenter: {
    title: "bottomNav.helpCenter",
    icon: "Help",
    path: "https://help.volopay.co/",
    key: "helpCenter",
    isExternalLink: true,
  },
  chat: {
    title: "bottomNav.chat",
    icon: "Chat",
    path: null,
    key: "chat",
    isExternalLink: false,
  },
};

export const selectNavigationStore = (store) => store.navigation;

// bottom links
export const alertsSelector = createSelector(isAdminSelector, (isAdmin) => {
  const { alerts } = navLinks;
  return alerts;
});
export const helpCenterSelector = createSelector(isAdminSelector, (isAdmin) => {
  const { helpCenter } = navLinks;
  return helpCenter;
});
export const chatSelector = createSelector(isAdminSelector, (isAdmin) => {
  const { chat } = navLinks;
  return chat;
});

const generateNavDataForGivenLevel = (
  navKey,
  navigationConfigSubset,
  level,
  routeObject,
  onlyAllowedPath
) => {
  const currentNavObj = navigationConfigSubset[navKey] || {};
  const hasChildren = currentNavObj ? Object.keys(currentNavObj)?.length : 0;
  const routeData =
    level === 0 ? routeObject?.[navKey]?.base : routeObject?.[navKey];

  const disabled = onlyAllowedPath
    ? !onlyAllowedPath.includes(routeData.key)
    : false;

  const basicNavData = {
    title: routeData?.title,
    icon: routeData?.icon,
    path: routeData?.absolutePath,
    key: routeData?.key,
    order: routeData?.order,
    borderBottom: routeData?.borderBottom || false,
    disabled,
  };

  if (hasChildren) {
    return {
      ...basicNavData,
      childLinks: Object?.keys(currentNavObj)
        .map((childNavKey) =>
          generateNavDataForGivenLevel(
            childNavKey,
            currentNavObj,
            1,
            routeObject?.[navKey],
            onlyAllowedPath
          )
        )
        .sort((a, b) => (a.order < b.order ? -1 : 1)),
    };
  }
  return basicNavData;
};

export const navLinksSelector = createSelector(
  navigationConfigSelector,
  accountFreezeSelector,
  (navigationConfig, accountFreeze) => {
    return navigationConfig
      ? Object.keys(navigationConfig)
          .map((navKey) =>
            generateNavDataForGivenLevel(
              navKey,
              navigationConfig,
              0,
              ROUTES,
              accountFreeze ? ["dashboard", "manage", "manage:billing"] : null
            )
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1))
      : [];
  }
);

const appendAbsolutePath = (navObject, arr) => {
  if (navObject.childLinks?.length) {
    navObject.childLinks.forEach((item) => {
      appendAbsolutePath(item, arr);
    });
  }
  arr.push({ path: navObject.path, key: navObject.key });
};

export const allNavUrlsSelector = createSelector(navLinksSelector, (links) => {
  const pathList = [];
  links.forEach((item) => appendAbsolutePath(item, pathList));
  return pathList;
});

export const bottomLinksSelector = createSelector(
  helpCenterSelector,
  chatSelector,

  (helpCenter, chat) => [helpCenter, chat].filter((item) => item)
);
