import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";

import Toast from "./Toast";
import { ToastContext } from "./ToastContext";
import generateID from "./helper";

export default function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  const open = ({ title, description, variant, persist, timeout }) =>
    setToasts((currentToasts) => [
      ...currentToasts,
      {
        id: generateID(),
        title,
        description,
        persist,
        variant,
        timeout,
      },
    ]);

  const close = (id) =>
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );

  const contextValue = useMemo(() => ({ open }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}

      {createPortal(
        <div className="toasts-wrapper">
          {toasts.map(
            ({ id, title, description, variant, persist, timeout }) => (
              <Toast
                key={id}
                close={() => close(id)}
                title={title}
                description={description}
                persist={persist}
                variant={variant}
                timeout={timeout}
              />
            )
          )}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
