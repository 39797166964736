import { useState } from "react";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import { CARD_MODULE_REQUEST_PAGE_TABS } from "@/constants/Cards";
import { ROUTES } from "@/constants/routes";

export default function CardRequests() {
  const tabs = [
    {
      name: "cards.requestPage.tabs.needsYourAction",
      path: ROUTES.cards.requests.needsYourAction.absolutePath,
      key: CARD_MODULE_REQUEST_PAGE_TABS.NEEDS_YOUR_ACTION,
    },
    {
      name: "cards.requestPage.tabs.pending",
      path: ROUTES.cards.requests.pending.absolutePath,
      key: CARD_MODULE_REQUEST_PAGE_TABS.PENDING,
    },
    {
      name: "cards.requestPage.tabs.all",
      path: ROUTES.cards.requests.all.absolutePath,
      key: CARD_MODULE_REQUEST_PAGE_TABS.HISTORY,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <div className="pr-11.5 pl-11">
      <div className="flex items-end justify-between pb-10">
        <div className="flex flex-col">
          <Text
            translationKey="cards.title.plural"
            color="neutral-500"
            classes="text-sm font-semibold"
          />
          <Text
            translationKey="cards.requests.title"
            classes="text-4xl font-extrabold text-neutral-800"
          />
        </div>
      </div>

      <Tabs
        items={tabs}
        selectedTab={selectedTab?.key}
        setCurrentTab={setSelectedTab}
      />
    </div>
  );
}
