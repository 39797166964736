import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";

import DashboardPage from "@/pages/dashboard";
import DashboardAccountOverviewPage from "@/pages/dashboard/account";
import CreditPage from "@/pages/dashboard/credit";
import DashboardGlobalAccountsPage from "@/pages/dashboard/global-accounts";
import { ROUTES } from "@/constants/routes";

export default function DashboardRoutes() {
  return (
    <Routes path="/" element={<ProtectedRoute />}>
      <Route path="" element={<DashboardPage />} />
      <Route
        path={ROUTES.dashboard.account.pathName}
        element={<DashboardAccountOverviewPage />}
      />
      <Route
        path={ROUTES.dashboard.globalAccounts.pathName}
        element={<DashboardGlobalAccountsPage />}
      />
      <Route path={ROUTES.dashboard.credit.pathName} element={<CreditPage />} />
    </Routes>
  );
}
