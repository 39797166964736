import { useState } from "react";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import { ROUTES } from "@/constants/routes";

const selectedTabs = [
  {
    name: "QRPay.userWallets.userWalletsStatus.all",
    count: null,
    path: ROUTES.QRPay.userWallets.all.pathName,
    key: 1,
  },
  {
    name: "QRPay.userWallets.userWalletsStatus.yetToActivate",
    count: null,
    path: ROUTES.QRPay.userWallets.yetToActivate.pathName,
    key: 2,
  },
];

export default function UserWallets() {
  const [selectedTab, setSelectedTab] = useState(selectedTabs[0]);

  const handleOptionChange = () => {
    console.log("request wallet");
  };

  return (
    <div className="px-8">
      <div className="flex items-end justify-between pb-10">
        <div className="flex flex-col">
          <Text
            translationKey="QRPay.title"
            color="neutral-500"
            classes="text-sm font-semibold"
          />
          <Text
            classes="text-4xl font-extrabold"
            translationKey="QRPay.userWallets.title"
          />
        </div>

        <Button
          label="QRPay.userWallets.requestWallet"
          preIcon="Wallet"
          iconClasses="h-6 w-6"
          classes="h-11 w-[calc(theme(spacing.16)+theme(spacing.5))] text-btn-lg font-semibold"
          onClick={handleOptionChange}
        />
      </div>

      <Tabs
        items={selectedTabs}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => setSelectedTab(tab)}
      />
    </div>
  );
}
