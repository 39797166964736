import PropsTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import usePagination from "@/hooks/usePagination";

import {
  RECURRING_PAYMENTS_SLICE_ATTRIBUTE_KEY,
  fetchPurchaseBills,
  setPurchaseBillsIntialState,
} from "@/store/reducers/purchase-bills";

import {
  isRecurringPaymentsFetchingSelector,
  recurringPaymentsHasMoreSelector,
  recurringPaymentsLimitSelector,
  recurringPaymentsListSelector,
  recurringPaymentsPageSelector,
  recurringPaymentsTotalSelector,
} from "@/store/selectors/purchase-bills";

import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Table from "@/components/core/Table";

import PaymentsTableLoader from "@/components/Reimbursement/PaymentsTableLoader";
import AmountCell from "@/components/common/BillPayAndPayroll//PaymentWorkflow/common/Cells/AmountCell";
import DueDateCell from "@/components/common/BillPayAndPayroll//PaymentWorkflow/common/Cells/DueDateCell";
import InvoiceDateCell from "@/components/common/BillPayAndPayroll//PaymentWorkflow/common/Cells/InvoiceDateCell";
import PaymentDateCell from "@/components/common/BillPayAndPayroll//PaymentWorkflow/common/Cells/PaymentDateCell";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import InvoiceCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceCell";
import DescriptionCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/DescriptionCell";
import { SORTING_CATEGORY, SORT_KEYS } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  BILLPAY_FILTERS,
  openRelevantPaymentSlider,
} from "@/utils/constants/payments";

import {
  INVOICE_SLIDER_TABLE_LIMIT,
  QUERY_PARAMS,
} from "@/constants/pagination";

import PaymentStatusCell from "../PaymentWorkflow/common/Cells/PaymentStatusCell";

// table cell configs
const VENDOR_TABLE_DEFAULT_HEADERS = {
  tableSetting: {
    rightColWidths: [100, 120, 100, 80, 150],
  },
  tableHeading: [
    {
      id: "amountHeading",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.amountHeading",
      classes: "text-left",
      cellComponent: AmountCell,
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },
    {
      id: "nextDueDate",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.nextDueDate",
      classes: "text-left",
      cellComponent: DueDateCell,
    },
    {
      id: "invoiceDate",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.invoiceDate",
      classes: "text-left",
      cellComponent: InvoiceDateCell,
    },
    {
      id: "invoice",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.invoice",
      classes: "justify-center",
      cellComponent: InvoiceCell,
    },
    {
      id: "description",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.description",
      classes: "text-left",
      cellComponent: DescriptionCell,
    },
    {
      id: "status",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.statusHeading",
      classes: "justify-center",
      cellComponent: PaymentStatusCell,
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
  tableDataRowClasses:
    "text-sm font-semibold text-center border-y-2 border-neutral-100",
};

const EMPLOYEE_TABLE_DEFAULT_HEADERS = {
  tableSetting: {
    rightColWidths: [120, 120, 80, 80, 150],
  },
  tableHeading: [
    {
      id: "amountHeading",
      title: "payroll.employeeDetails.accordian.EmployeeTable.amountHeading",
      classes: "text-left",
      cellComponent: AmountCell,
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },
    {
      id: "paymentDateHeading",
      title:
        "payroll.employeeDetails.accordian.EmployeeTable.paymentDateHeading",
      classes: "text-left",
      cellComponent: PaymentDateCell,
    },
    {
      id: "description",
      title: "payroll.employeeDetails.accordian.EmployeeTable.description",
      classes: "text-left",
      cellComponent: DescriptionCell,
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
  tableDataRowClasses:
    "text-sm font-semibold text-center border-y-2 border-neutral-100",
};

export default function RecurringPaymentsTable({ vendorId, context }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // explicit pagination
  const [currentPage, setCurrentPage] = useState(1); // visible in UI
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  // data fetching
  const filterOptions = {
    [QUERY_PARAMS.RECURRING_PAYMENTS.KEY_NAME]:
      QUERY_PARAMS.RECURRING_PAYMENTS.VALUES.TRUE,
  };

  const onReset = () => {
    dispatch(
      setPurchaseBillsIntialState({
        key: RECURRING_PAYMENTS_SLICE_ATTRIBUTE_KEY,
      })
    );

    setCurrentPage(1);
  };
  const hasMore = useSelector(recurringPaymentsHasMoreSelector);
  const loadMore = () => {
    dispatch(
      fetchPurchaseBills({
        key: RECURRING_PAYMENTS_SLICE_ATTRIBUTE_KEY,
        value: {
          page: pageNum,
          limit: INVOICE_SLIDER_TABLE_LIMIT,
          payroll: context === BILL_PAYROLL_CONTEXT.PAYROLL,
          [BILLPAY_FILTERS.PURCHASE_BILL_AND_PAYROLL]: vendorId,
          [SORT_KEYS.COLUMN]: sorting?.category,
          [SORT_KEYS.DIRECTION]: sorting?.type,
          ...filterOptions,
        },
        context,
      })
    );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...filterOptions,
      ...sorting,
    },
  });

  const list = useSelector(recurringPaymentsListSelector);
  const isFetching = useSelector(isRecurringPaymentsFetchingSelector);
  const limit = useSelector(recurringPaymentsLimitSelector);
  const total = useSelector(recurringPaymentsTotalSelector);
  const page = useSelector(recurringPaymentsPageSelector);

  const isEmpty = !list?.length;
  // Note (to avoid confusion)
  // 1. currentPage - for UI navigation, not related to pagination. Basically - where are you, in the UI
  // 2. pageNum - used for triggering fetch call. Simply - what page is being downloaded, or was downloaded last
  // 3. page - downloaded pages, a store state. Simply - how many pages have been downloaded

  // pagination actions
  const prevDisabled = currentPage === 1;
  const nextDisabled =
    (pageNum === 1 && isFetching) || (currentPage > page && isFetching);

  const prevPageHandler = () => setCurrentPage((p) => (p > 1 ? p - 1 : p));
  const nextPageHandler = () => {
    if (currentPage === page) {
      // on the last loaded page
      if (hasMore) {
        setCurrentPage((p) => p + 1); // show loader page
        setPageNum((p) => p + 1); // trigger pagination to fetch more
      }
    } else if (currentPage < page) {
      setCurrentPage((p) => p + 1); // move navigation forward, no fetch
    }
    // currentPage > page case is absent, since button is disabled
  };

  const rows = list.slice(
    INVOICE_SLIDER_TABLE_LIMIT * (currentPage - 1),
    INVOICE_SLIDER_TABLE_LIMIT * currentPage
  );

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
  } = inPayrollContext
    ? EMPLOYEE_TABLE_DEFAULT_HEADERS
    : VENDOR_TABLE_DEFAULT_HEADERS;

  return (
    <div className="mt-6">
      <Table {...tableSetting}>
        <tr className={tableHeadingRowClasses}>
          {tableHeading.map((headVal, index) => (
            <th className={headVal.classes} key={headVal.id}>
              <HeaderCell
                val={{
                  ...headVal,
                  sorting,
                  setSorting,
                  showCheckbox: false,
                }}
              />
            </th>
          ))}
        </tr>

        {(!isFetching || !isEmpty) &&
          rows.map((val, index, arr) => (
            <tr
              key={val.id}
              className={`${tableDataRowClasses} ${
                val.selected ? "selected-row-cell" : ""
              }`}
              onClick={() => {
                openRelevantPaymentSlider(val, inPayrollContext, {
                  searchParams,
                  setSearchParams,
                });
              }}
            >
              {tableHeading.map(({ cellComponent: Component, classes, id }) => (
                <td className={classes} key={id}>
                  <Component
                    val={{ ...val, context, openRow: true }}
                    key={id}
                  />
                </td>
              ))}
            </tr>
          ))}
        {isFetching ? (
          <PaymentsTableLoader count={INVOICE_SLIDER_TABLE_LIMIT} />
        ) : null}
      </Table>
      {isEmpty ? null : (
        <StepPaginationButtons
          currentPage={currentPage}
          total={total}
          limit={limit}
          handleLeftButton={prevPageHandler}
          handleRightButton={nextPageHandler}
          leftDisabled={prevDisabled}
          rightDisabled={nextDisabled}
        />
      )}
    </div>
  );
}

RecurringPaymentsTable.propTypes = {
  context: PropsTypes.string,
  vendorId: PropsTypes.number,
};
