import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { countriesSelector } from "@/store/selectors/app";
import { primaryCardProviderSelector } from "@/store/selectors/client";

import Checkbox from "@/components/core/Checkbox";
import Input from "@/components/core/Input";
import VpSelect from "@/components/core/VpSelect";

import {
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_TEXT,
} from "@/components/GenericForm/common";

import { CARD_PROVIDER } from "@/constants/Cards";
import { COUNTRY_STATES, VP_ADDRESS_CONFIG } from "@/constants/onboarding";

import Text from "../Text";

export default function VpAddress({
  name,
  onChange = () => {},
  values,
  errors,
  enabledSameAsCheckbox,
  labelCheckBox,
  checkboxValueChange = () => {},
  keyForSameAsCheckbox,
  label,
  disabled,
}) {
  const countries = useSelector(countriesSelector);
  const isChecked = values[`${name}.isSameChecked`];
  const primaryCardProviderInfo = useSelector(primaryCardProviderSelector);
  const slug = primaryCardProviderInfo?.slug;

  useEffect(() => {
    if (isChecked && enabledSameAsCheckbox) {
      onChange({
        target: {
          name: `${name}.isSameChecked`,
          value: !isChecked,
        },
      });

      checkboxValueChange(
        !isChecked,
        name,
        name?.replace(name, enabledSameAsCheckbox)
      );
    }
  }, [enabledSameAsCheckbox]);

  return (
    <div className="flex flex-col gap-6 mb-9">
      <div className="flex flex-col gap-2 mb-6">
        <Text
          translationKey={label}
          classes="text-lg font-bold text-neutral-500"
        />

        {enabledSameAsCheckbox ? (
          <Checkbox
            checked={isChecked}
            label={labelCheckBox}
            labelClasses="text-sm font-medium text-neutral-800"
            disabled={disabled}
            onClickHandler={(value) => {
              onChange({
                target: {
                  name: `${name}.isSameChecked`,
                  value: !isChecked,
                },
              });

              checkboxValueChange(
                value,
                name,
                name?.replace(name, enabledSameAsCheckbox)
              );
            }}
          />
        ) : null}
      </div>

      {!isChecked ? (
        <div className="flex flex-col gap-9">
          <div className="flex w-full gap-5">
            <Input
              name={`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_1}`}
              type="text"
              outsideDivClasses="w-1/2"
              label="form.label.addressLine1"
              onChange={(val) => {
                onChange(val);
                if (keyForSameAsCheckbox) {
                  checkboxValueChange(
                    values[`${keyForSameAsCheckbox}.isSameChecked`],
                    keyForSameAsCheckbox,
                    name,
                    val
                  );
                }
              }}
              disabled={disabled}
              value={values[`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_1}`]}
              error={errors?.[`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_1}`]}
              labelExtraClasses="text-xs font-semibold text-neutral-500"
            />
            <Input
              name={`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_2}`}
              type="text"
              outsideDivClasses="w-1/2"
              label="form.label.addressLine2"
              onChange={(val) => {
                onChange(val);
                if (keyForSameAsCheckbox)
                  checkboxValueChange(
                    values[`${keyForSameAsCheckbox}.isSameChecked`],
                    keyForSameAsCheckbox,
                    name,
                    val
                  );
              }}
              disabled={disabled}
              value={values[`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_2}`]}
              error={errors?.[`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_2}`]}
              labelExtraClasses="text-xs font-semibold text-neutral-500"
            />
          </div>
          <div className="flex items-center w-full gap-9">
            <Input
              name={`${name}.${VP_ADDRESS_CONFIG.ZIPCODE}`}
              type={
                slug === CARD_PROVIDER.NIUM
                  ? FIELD_TYPE_TEXT
                  : FIELD_TYPE_NUMBER
              }
              label="form.label.zipcode"
              onChange={(val) => {
                onChange(val);
                if (keyForSameAsCheckbox)
                  checkboxValueChange(
                    values[`${keyForSameAsCheckbox}.isSameChecked`],
                    keyForSameAsCheckbox,
                    name,
                    val
                  );
              }}
              disabled={disabled}
              outsideDivClasses="w-full"
              value={values[`${name}.${VP_ADDRESS_CONFIG.ZIPCODE}`]}
              error={errors?.[`${name}.${VP_ADDRESS_CONFIG.ZIPCODE}`]}
              labelExtraClasses="text-xs font-semibold text-neutral-500"
            />
            <Input
              name={`${name}.${VP_ADDRESS_CONFIG.CITY}`}
              type="text"
              label="form.label.city"
              outsideDivClasses="w-full"
              onChange={(val) => {
                onChange(val);
                if (keyForSameAsCheckbox)
                  checkboxValueChange(
                    values[`${keyForSameAsCheckbox}.isSameChecked`],
                    keyForSameAsCheckbox,
                    name,
                    val
                  );
              }}
              disabled={disabled}
              value={values[`${name}.${VP_ADDRESS_CONFIG.CITY}`]}
              error={errors?.[`${name}.${VP_ADDRESS_CONFIG.CITY}`]}
              labelExtraClasses="text-xs font-semibold text-neutral-500"
            />
            {COUNTRY_STATES[values[`${name}.${VP_ADDRESS_CONFIG.COUNTRY}`]] ? (
              <VpSelect
                name={`${name}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`}
                label="form.label.state"
                classes="w-full"
                handleChange={(val) => {
                  onChange(val);
                  if (keyForSameAsCheckbox)
                    checkboxValueChange(
                      values[`${keyForSameAsCheckbox}.isSameChecked`],
                      keyForSameAsCheckbox,
                      name,
                      val
                    );
                }}
                value={values[`${name}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`]}
                error={
                  errors?.[`${name}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`]
                }
                options={
                  COUNTRY_STATES[values[`${name}.${VP_ADDRESS_CONFIG.COUNTRY}`]]
                }
                optionsDisplayKey="name"
                valueKey="value"
                menuPosition="absolute"
                helperText=""
                insideForm
                disabled={disabled}
              />
            ) : (
              <Input
                name={`${name}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`}
                type="text"
                label="form.label.state"
                outsideDivClasses="w-full"
                onChange={(val) => {
                  onChange(val);

                  if (keyForSameAsCheckbox)
                    checkboxValueChange(
                      values[`${keyForSameAsCheckbox}.isSameChecked`],
                      keyForSameAsCheckbox,
                      name,
                      val
                    );
                }}
                disabled={disabled}
                value={values[`${name}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`]}
                error={
                  errors?.[`${name}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`]
                }
                labelExtraClasses="text-xs font-semibold text-neutral-500"
              />
            )}
          </div>

          <VpSelect
            name={`${name}.${VP_ADDRESS_CONFIG.COUNTRY}`}
            label="form.label.country"
            value={values[`${name}.${VP_ADDRESS_CONFIG.COUNTRY}`]}
            handleChange={(val) => {
              onChange(val);
              if (keyForSameAsCheckbox)
                checkboxValueChange(
                  values[`${keyForSameAsCheckbox}.isSameChecked`],
                  keyForSameAsCheckbox,
                  name,
                  val
                );
            }}
            error={errors?.[`${name}.${VP_ADDRESS_CONFIG.COUNTRY}`]}
            options={countries.map((c) => ({ ...c, value: c?.isoCode }))}
            classes="w-1/3 pr-3"
            optionsDisplayKey="name"
            valueKey="value"
            menuPosition="absolute"
            helperText=""
            insideForm
            disabled={disabled}
            labelStyleClasses="text-xs font-semibold text-neutral-500"
          />
        </div>
      ) : null}
    </div>
  );
}

VpAddress.propTypes = {
  name: PropTypes.any,
  onChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  enabledSameAsCheckbox: PropTypes.string,
  labelCheckBox: PropTypes.string,
  checkboxValueChange: PropTypes.string,
  keyForSameAsCheckbox: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
