import PropsTypes from "prop-types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import VendorCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/VendorCell";
import VendorTable from "@/components/common/BillPayAndPayroll/VendorOrEmployee/VendorTable";
import BeneficiaryCurrCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/BeneficiaryCurrCell";
import ContractCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/ContractCell";
import Last30DaysCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/Last30DaysCell";
import TotalSpendCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/TotalSpendCell";
import VendorOwnerCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/VendorOwnerCell";
import { SORTING_CATEGORY_BILLPAY } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

export default function Vendor() {
  const [searchParams, setSearchParams] = useSearchParams();
  const VENDOR_TABLE_DEFAULT_HEADERS = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 300, 200, 200, 200, 120],
      emptyDataTitle: "billPay.vendors.emptyTableHeading",
      emptyDataDescription: "billPay.vendors.emptyTableDescription",
    },
    tableHeading: [
      {
        id: "vendor",
        title: "billPay.vendors.tableHeaders.vendor",
        classes: "text-left",
        cellComponent: VendorCell,
      },
      {
        id: "owner",
        title: "billPay.vendors.tableHeaders.vendorOwner",
        classes: "text-left",
        cellComponent: VendorOwnerCell,
      },
      {
        id: "beneficiaryCurrency",
        title: "billPay.vendors.tableHeaders.beneficiaryCurrency",
        classes: "text-left",
        cellComponent: BeneficiaryCurrCell,
      },
      {
        id: "totalSpend",
        title: "billPay.vendors.tableHeaders.totalSpend",
        classes: "text-right justify-end",
        haveSort: true,
        cellComponent: TotalSpendCell,
        sortingCategory: SORTING_CATEGORY_BILLPAY.CURRENT_AMOUNT,
      },
      {
        id: "last30Days",
        title: "billPay.vendors.tableHeaders.last30Days",
        classes: "text-right justify-end",
        haveSort: true,
        cellComponent: Last30DaysCell,
        sortingCategory: SORTING_CATEGORY_BILLPAY.PREVIOUS_AMOUNT,
      },
      {
        id: "contract",
        title: "billPay.vendors.tableHeaders.contract",
        classes: "justify-center",
        cellComponent: ContractCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses: "text-sm font-semibold text-center  text-neutral-800",
  };

  const handleAddVendorButton = () => {
    searchParams.set(SLIDERS_SEARCH_PARAMS.vendors.create, true);
    setSearchParams(searchParams);
  };

  const handleVendorClick = (id) => {
    setSearchParams({ [SLIDERS_SEARCH_PARAMS.vendors.id]: id });
  };

  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <HeaderText createVendor={handleAddVendorButton} />
      <div className="mt-2">
        <VendorTable
          openSliderHandler={handleVendorClick}
          context={BILL_PAYROLL_CONTEXT.BILLPAY}
          headerSettings={VENDOR_TABLE_DEFAULT_HEADERS}
        />
      </div>
    </div>
  );
}

function HeaderText({ createVendor }) {
  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);
  return (
    <div className="flex items-center justify-between mr-2">
      <div className="flex flex-col">
        <Text classes="breadcrumb-text" translationKey="billPay.title" />
        <Text
          classes="listing-page-title"
          translationKey="billPay.vendors.title"
        />
      </div>
      {checkIfUserActionAllowed(
        allNavUrls,
        userAllowedActions,
        USER_ALLOWED_CTA_KEYS.CREATE_VENDOR
      ) ? (
        <Button
          label="billPay.vendors.newVendor"
          onClick={createVendor}
          variant="primary"
          preIcon="Add"
          iconClasses="w-6 h-6"
          labelStyleClasses="font-semibold text-btn-lg"
          classes="px-5 py-4"
          compact
        />
      ) : null}
    </div>
  );
}

HeaderText.propTypes = {
  createVendor: PropsTypes.func,
};
