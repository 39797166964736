import PropTypes from "prop-types";
import { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";

import Button from "@/components/core/Button";
import CustomHeader from "@/components/core/DateInput/CustomHeader";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { VP_DATE_INPUT_TYPE } from "@/constants/common";
import { DATEINPUT_MODES } from "@/constants/date";

import SelectableDatePicker from "../SelectableDatePicker";
import DateChangeInput from "./DateChangeInput";
import "./styles.scss";

export default function DateInput(props) {
  const {
    mode = DATEINPUT_MODES.DATE,
    value,
    onChange,
    inline,
    monthsShown = null,
    dateFormat = "dd/MM/yyyy",
    content = null,
    error,
    extraProps = {},
    name = "dateInput",
    customComponentClass,
    onCancelDateRange = () => {},
    disabled,
    isSelectable,
    disableDateRangeForOneValueSelected = false,
    ...rest
  } = props;

  const [errorString, setErrorString] = useState(error);
  useEffect(() => {
    setErrorString(error);
  }, [error]);
  const [rangeDates, setRangeDates] = useState(
    mode === DATEINPUT_MODES.DATE_RANGE ? [...value] : [null, null]
  ); // extra state needed to facilitate cancellation
  useEffect(() => {
    if (mode === DATEINPUT_MODES.DATE_RANGE && value?.length)
      setRangeDates(value.map((val) => new Date(val)));
  }, [value]);
  const onDatePickerChange = (dateOrDates) => {
    if (mode !== DATEINPUT_MODES.DATE_RANGE)
      onChange({
        target: { value: dateOrDates, name, type: VP_DATE_INPUT_TYPE },
      });
    else setRangeDates(dateOrDates);
  }; // simulate native HTML

  const datePickerRef = useRef();

  const onDateRangeSubmission = () => {
    onChange({ target: { value: rangeDates, name, type: VP_DATE_INPUT_TYPE } }); // write to external state

    datePickerRef.current.setOpen(false);
  };

  const onDateRangeCancellation = () => {
    setRangeDates([new Date(), new Date()]); // reset selector only
    onCancelDateRange();
    datePickerRef.current.setOpen(false);
  };

  // layer for the library, it accepts either '' or Date instance
  const getSafeDate = (givenDateOrDates) => {
    const isoDateOrEmptyString = (val) => (val ? new Date(val) : "");

    if (Array.isArray(givenDateOrDates))
      return givenDateOrDates.map(isoDateOrEmptyString);
    return isoDateOrEmptyString(givenDateOrDates);
  };

  if (isSelectable) {
    return <SelectableDatePicker {...props} />;
  }
  return (
    <div className="relative" id={inline ? "no-border" : ""}>
      {mode !== DATEINPUT_MODES.DATE_RANGE ? (
        <DatePicker
          name={name}
          // this handle overflow of date input
          popperModifiers={[
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
              },
            },
          ]}
          disabled={disabled}
          showMonthYearPicker={mode === DATEINPUT_MODES.MONTH}
          showYearPicker={mode === DATEINPUT_MODES.YEAR}
          onChange={onDatePickerChange}
          selected={getSafeDate(value)}
          monthsShown={monthsShown || 1}
          inline={inline}
          dateFormat={dateFormat}
          showPopperArrow={false}
          customInput={
            <CustomInputComponent
              value={getSafeDate(value)}
              content={content}
            />
          }
          {...(mode === DATEINPUT_MODES.DATE
            ? {
                renderCustomHeader: (_props) => (
                  <CustomHeader {..._props} {...extraProps} />
                ),
              }
            : {})}
          {...extraProps}
        />
      ) : (
        <DatePicker
          // this handle overflow of date input
          popperModifiers={[
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
              },
            },
          ]}
          name={name}
          disabled={disabled}
          selectsRange
          startDate={rangeDates[0]}
          endDate={rangeDates[1]}
          onChange={onDatePickerChange}
          selected={rangeDates[1]}
          monthsShown={monthsShown || 2}
          inline={inline}
          dateFormat={dateFormat}
          showPopperArrow={false}
          shouldCloseOnSelect={false}
          ref={datePickerRef}
          customInput={
            <CustomInputComponent
              customComponentClass={customComponentClass}
              value={rangeDates}
              content={content}
            />
          }
          renderCustomHeader={(_props) => (
            <CustomHeader {..._props} {...extraProps} />
          )}
          {...extraProps} // placed last for overridable extensibility
        >
          <div className="react-datepicker-vp-range-child-container">
            <div className="react-datepicker-vp-range-secure-child-container">
              <div className="react-datepicker-vp-range-direct-input-container ">
                <div className="!border-none react-datepicker-vp-range-date-start">
                  <DateChangeInput
                    inputDate={
                      rangeDates[0]
                        ? rangeDates[0].toLocaleDateString("en-in")
                        : ""
                    }
                    autoFocus
                    maxDate={rangeDates[1]}
                    checkMaxDate
                    onDateChange={(dateValue) => {
                      setRangeDates([dateValue, rangeDates[1]]);
                    }}
                  />
                </div>
                <span className="arrow-icon-container">
                  <Icon name="DateRangeArrow" />
                </span>
                <div className="!border-none react-datepicker-vp-range-date-end">
                  <DateChangeInput
                    inputDate={
                      rangeDates[1]
                        ? rangeDates[1].toLocaleDateString("en-in")
                        : ""
                    }
                    minDate={rangeDates[0]}
                    checkMinDate
                    onDateChange={(dateValue) => {
                      setRangeDates([rangeDates[0], dateValue]);
                    }}
                  />
                </div>
              </div>
              <div className="react-datepicker-vp-range-cta-container ">
                <Button
                  label="Cancel"
                  onClick={onDateRangeCancellation}
                  classes="w-13.5 text-neutral-500 font-semibold"
                  variant="tertiary"
                />
                <Button
                  disabled={
                    disableDateRangeForOneValueSelected &&
                    (!rangeDates[1] || !rangeDates[0])
                  }
                  label="Apply"
                  onClick={onDateRangeSubmission}
                  classes="w-13.5 font-semibold"
                  variant="secondary"
                />
              </div>
            </div>
          </div>
        </DatePicker>
      )}
      {errorString && errorString.length !== 0 ? (
        <Text
          classes="absolute  bottom-[-20px] left-[0px] text-xs text-danger-600"
          translationKey={error}
        />
      ) : null}
    </div>
  );
}

const CustomInputComponent = forwardRef(
  ({ value, onClick = () => {}, content = null }, ref) => (
    <button
      type="button"
      onClick={(e) => {
        onClick(e);

        e.preventDefault();
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      ref={ref}
      className="w-full text-left react-datepicker-vp-custom-content"
    >
      {content || value}
    </button>
  )
);

DateInput.propTypes = {
  mode: PropTypes.oneOf(Object.values(DATEINPUT_MODES)),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Date)), // range mode
    PropTypes.instanceOf(Date),
    PropTypes.any, // avoid warning, when no date should be selected by default
  ]),
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  monthsShown: PropTypes.number,
  dateFormat: PropTypes.string,
  content: PropTypes.any,
  extraProps: PropTypes.object,
  name: PropTypes.string,
  error: PropTypes.string,
  customComponentClass: PropTypes.string,
  onCancelDateRange: PropTypes.func,
  disabled: PropTypes.bool,
};

CustomInputComponent.propTypes = {
  value: PropTypes.any, // actually same DateInput.propTypes.values, but the libary type export has some problem
  onClick: PropTypes.func,
  content: PropTypes.any,
};
