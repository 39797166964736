import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import Popup from "reactjs-popup";

import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function ApprovalItems({ approvers, id, isExpense }) {
  const popupRef = useRef();
  const handleScroll = (e) => {
    popupRef?.current?.close();
  };
  //  TO close popup js when scrolled
  useEffect(() => {
    const sliderComponents = document.querySelectorAll(".slider-content-core");
    if (sliderComponents?.length) {
      sliderComponents?.forEach((sliderComponent) => {
        sliderComponent.addEventListener("scroll", handleScroll);
        return () => {
          sliderComponent.removeEventListener("scroll", handleScroll);
        };
      });
    }
  }, []);
  return (
    <Popup
      ref={popupRef}
      arrow={false}
      keepTooltipInside="#slider-core-container-id"
      trigger={
        <div className="w-fit">
          <Text
            id={`approver-status-tooltip-${id}`}
            translationKey={isExpense ? "viewReviewers" : "viewApprovers"}
            classes="text-neutral-500 select-none  decoration-neutral-500 underline text-sm cursor-pointer"
          />
        </div>
      }
      repositionOnResize={false}
      position="bottom left"
    >
      <div className="px-5 py-2 overflow-auto bg-white border rounded-md border-neutral-100 max-h-19">
        {approvers?.map((item) => (
          <div
            className="text-base font-medium text-neutral-800"
            key={`approvers-status-tooltip-${id}-${item.id}`}
          >
            {item?.displayName ?? item?.userName}
          </div>
        ))}
      </div>
    </Popup>
  );
}
ApprovalItems.propTypes = {
  approvers: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isExpense: PropTypes.bool,
};
