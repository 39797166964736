import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  connectToUCSV,
  fetchSupportedExportFormats,
} from "@/store/reducers/accounting";
// reducers
import { fetchClient } from "@/store/reducers/client";

// selectors
import {
  accountingIntegrationImportFileFieldsSelector,
  accountingIntegrationImportedFilesSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";
import { clientSelector } from "@/store/selectors/client";

// core components
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpLink from "@/components/core/vp-link";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import {
  FILE_IMPORT_VARIANTS,
  INTEGRATION_UCSV_HELP_URL,
} from "@/utils/constants/integrations";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function UCSVImportSlider() {
  const dispatch = useDispatch();
  const [chartOfAccounts, setChartOfAccounts] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const client = useSelector(clientSelector);
  const [ucsvFields, setUcsvfields] = useState(
    useSelector(accountingIntegrationImportFileFieldsSelector)
  );
  const importedFiles = useSelector(accountingIntegrationImportedFilesSelector);
  const isLoadingConnect = useSelector(isLoadingAccountingIntegrationsSelector);

  useEffect(() => {
    dispatch(fetchSupportedExportFormats());
  }, []);

  const handleConnect = async () => {
    const action = await dispatch(connectToUCSV());
    if (connectToUCSV.fulfilled.match(action)) onSuccessfulSave();
  };

  const onSuccessfulSave = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.importDocuments
    );
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.integrations.software);

    setSearchParams(searchParams);
    dispatch(fetchClient());
  };

  const handleImport = (value) => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.document,
      value
    );
    setSearchParams(searchParams);
  };

  const handleCreateNewField = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.createNewField,
      true
    );
    setSearchParams(searchParams);
  };

  return (
    <div className="slider-content-container flex flex-col gap-11.5">
      <div className="flex flex-col gap-8 px-10 slider-core-container">
        <SliderHeader
          heading="accounting.integrations.universalCsv.title"
          headingClasses="text-4xl font-bold text-neutral-800"
          refFlag
        />

        <div className="flex flex-col gap-9">
          <div className="flex flex-col justify-center gap-3 p-4 border bg-neutral-50 border-neutral-300 rounded-xl">
            <SliderHeader
              heading="accounting.integrations.labels.note"
              subText="accounting.integrations.universalCsv.setup.title"
              headingClasses="text-base font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <VpLink
              onClickHandler={() => {
                window.open(INTEGRATION_UCSV_HELP_URL, "_blank");
              }}
              text="accounting.integrations.linkLabel.learnMore"
              textClasses="text-sm underline font-semibold"
            />
          </div>

          <div className="flex flex-col justify-center gap-6">
            <SliderHeader
              heading="accounting.integrations.labels.required"
              subText="accounting.integrations.universalCsv.setup.description"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />

            <div className="flex items-center gap-4 p-4 border rounded-lg border-neutral-200">
              <div className="">
                <Icon
                  name="AssignmentAddOn"
                  handleClick={() => setChartOfAccounts(1)}
                  className="w-10 h-10 rounded-full text-primary-500 bg-primary-50"
                />
              </div>

              <div className="flex items-center justify-between w-full">
                <div>
                  <div className="flex items-center gap-2">
                    <SliderHeader
                      heading="accounting.integrations.imports.importChartOfAccount.title"
                      headingClasses="text-base font-semibold text-neutral-800"
                    />
                    {importedFiles?.chart_of_account?.length ? (
                      <Text
                        translationKey="accounting.integrations.labels.defined"
                        classes="w-fit h-fit py-0.5 px-2 text-xs text-success-600 font-bold bg-success-50 border border-success-200 rounded-full"
                      />
                    ) : null}
                  </div>

                  <SliderHeader
                    subText="accounting.integrations.imports.importChartOfAccount.description"
                    subTextClasses="text-sm font-medium text-neutral-500"
                  />
                </div>

                <Icon
                  name="ArrowForward"
                  className="text-neutral-500 hover:cursor-pointer"
                  handleClick={() =>
                    handleImport(FILE_IMPORT_VARIANTS.CHART_OF_ACCOUNTS)
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center gap-6">
            <SliderHeader
              heading="accounting.integrations.optionalFields.title"
              subText="accounting.integrations.optionalFields.description"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            {ucsvFields?.map((item, index) =>
              item.value !== FILE_IMPORT_VARIANTS.CHART_OF_ACCOUNTS ? (
                <div
                  className="flex items-center gap-4 p-4 border rounded-lg border-neutral-200"
                  key={`import-${index}`}
                >
                  <div className="flex items-center justify-center h-10 rounded-full w-11 bg-primary-50">
                    <Icon
                      name={item.icon}
                      className="w-fit h-fit text-primary-500"
                    />
                  </div>

                  <div className="flex items-center justify-between w-full">
                    <div>
                      <div className="flex items-center gap-2">
                        <SliderHeader
                          heading="accounting.integrations.imports.title"
                          headingTranslationProps={{ file: item.name }}
                          headingClasses="text-base font-semibold text-neutral-800"
                        />
                        {importedFiles?.[item.value]?.length ? (
                          <Text
                            translationKey="accounting.integrations.labels.defined"
                            classes="w-fit h-fit py-0.5 px-2 text-xs text-success-600 font-bold bg-success-50 border border-success-200 rounded-full"
                          />
                        ) : null}
                      </div>

                      <SliderHeader
                        subText="accounting.integrations.imports.description"
                        subTextTranslationProps={{ file: item.name }}
                        subTextClasses="text-sm font-medium text-neutral-500"
                      />
                    </div>

                    <Icon
                      name="ArrowForward"
                      className=" text-neutral-500 hover:cursor-pointer"
                      handleClick={() => handleImport(item.value)}
                    />
                  </div>
                </div>
              ) : null
            )}

            <Button
              label="accounting.integrations.buttonLabel.createNewField"
              variant="tertiary"
              size="sm"
              classes="px-1.5 py-5 text-xs font-bold"
              onClick={() => handleCreateNewField()}
            />
          </div>
        </div>
      </div>
      <div className="sticky p-6 slider-footer ">
        <div
          className="flex justify-end "
          id={`USVconnect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
        >
          <Button
            label="accounting.integrations.buttonLabel.connect"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            disabled={!importedFiles?.chart_of_account}
            showLoader={isLoadingConnect}
            onClick={() => {
              handleConnect();
            }}
          />
          {!importedFiles ? (
            <Tooltip
              direction="top"
              id={`USVconnect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
            >
              <Text translationKey="accounting.integrations.toolTipText.chartOfAccountMandatory" />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}
