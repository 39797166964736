import PropTypes from "prop-types";
import React from "react";

import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function EntityListWithDelete({
  list = [],
  title,
  handleDeleteIconClick,
  classes,
  hideDeleteIcon = false,
}) {
  const showSection = list.length > 0;

  if (!showSection) return null;

  return (
    <div
      className={`flex flex-col gap-3 border-0 card-wrapper bg-white ${classes}`}
    >
      {typeof title !== typeof "" ? (
        title
      ) : (
        <Text
          translationKey={title}
          classes="text-sm font-semibold text-neutral-500"
        />
      )}

      <div className="flex flex-col gap-3 p-4">
        {list.map((listElement, index) => {
          return (
            <div
              className="flex flex-row items-center justify-between"
              key={`${index}-${listElement?.id}`}
            >
              <div className="flex flex-row items-center gap-1">
                {listElement?.nameIconConfig ? (
                  <Icon
                    name={listElement?.nameIconConfig?.icon}
                    className={listElement?.nameIconConfig?.iconClasses}
                  />
                ) : null}

                <Chip
                  label={listElement.name}
                  classes={`text-neutral-600 ${listElement?.chipClassess}`}
                />
              </div>

              {!hideDeleteIcon && !listElement?.disabled ? (
                <div onClick={() => handleDeleteIconClick(listElement?.id)}>
                  <Icon
                    name="Delete"
                    className="cursor-pointer text-neutral-400 hover:text-neutral-600"
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

EntityListWithDelete.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  list: PropTypes.array,
  handleDeleteIconClick: PropTypes.func,
  classes: PropTypes.string,
  hideDeleteIcon: PropTypes.bool,
};
