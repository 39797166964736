export const ACCOUNTING = "accounting";
export const VENDOR_ID = "vendorId123";
export const VALUE_VENDOR_ID = "value-vendorId123";
export const TAG_TYPES = {
  ACCOUNTING: "accounting",
  CUSTOM: "custom",
  USER: "user",
};

export const TAG_FIELD_TYPES = {
  LIST: "list",
  TEXT: "text",
  DATE: "date",
};
export const ACCOUNTING_TAG_TYPES = {
  CATEGORY: "category",
  TAX_CODE: "tax codes",
  VENDOR: "vendor",
  EXPENSE_CATEGORY: "Expense Category",
};

export const TAG_MODULE_TYPES = {
  CARDS: "cards",
  BILL_PAY: "bill-pay",
  REIMBURSEMENTS: "reimbursements",
  PAYROLL: "payrolls",
  QR_PAY: "qr-pay",
};

export const TAG_MODULE_TITLES = {
  CARD: "Card",
  BILL_PAY: "BillPay",
  REIMBURSEMENT: "Reimbursement",
  PAYROLL: "Payroll",
  QR_PAY: "Qrpay",
};

export const LINE_ITEM_TAG_CATEGORIES = [
  "amortization_start_date",
  "amortization_end_date",
];

export const TAGS_TYPE_CONFIG = {
  ACCOUNTING: {
    groupTitle: "search.categories.accounting.accountingFields",
    key: "accounting",
  },
  CUSTOM: {
    groupTitle: "search.categories.accounting.customFields",
    key: "custom",
  },
};
