import PropTypes from "prop-types";

import Filters from "@/components/core/Filters";

import SyncAll from "../common/SyncAll";

export default function AccountingTransactionSearchFilter({
  filters,
  showSyncAll,
  availableToSync,
  fetchingAvailableToSync,
  syncAll,
  page,
  syncableIds,
}) {
  return (
    <div className="flex items-baseline justify-between">
      <div className="flex flex-col items-start w-full gap-4 text-center">
        <Filters filters={filters} classes="w-full" />
      </div>
      {/* {showSyncAll ? (
          <SyncAll
            count={availableToSync}
            isFetching={fetchingAvailableToSync}
            handleSyncAll={syncAll}
            page={page}
            syncableIds={syncableIds}
          />
        ) : null} */}
    </div>
  );
}

AccountingTransactionSearchFilter.propTypes = {
  filters: PropTypes.array,
  showSyncAll: PropTypes.bool,
  availableToSync: PropTypes.number,
  fetchingAvailableToSync: PropTypes.bool,
  syncAll: PropTypes.func,
  page: PropTypes.string,
  syncableIds: PropTypes.array,
};
