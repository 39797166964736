import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addComments, fetchComments } from "@/store/reducers/comments";

import { newCardRequestDetailsSelector } from "@/store/selectors/cards";
import {
  commentsHasMoreSelector,
  commentsListSelector,
} from "@/store/selectors/comments";
import { userSelector } from "@/store/selectors/user";

import Comments from "@/components/core/Comments";
import Text from "@/components/core/Text";
import { COMMENTS_PAGINATION_LIMIT_PER_REQUEST } from "@/utils/constants/payments";
import { TRANSACTION_STATUS_KEY } from "@/utils/constants/common";
import { COMMENT_TYPE, COMMENT_TYPE_PAYLOAD } from "@/utils/constants/comments";

export default function CardsModuleRequestSection() {
  const dispatch = useDispatch();
  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);
  const commentsList = useSelector(commentsListSelector);

  const commentHasMore = useSelector(commentsHasMoreSelector);

  const currentUser = useSelector(userSelector);

  const [commentPage, setCommentPageNum] = useState(1);

  const notApprovedStatus =
    cardRequestDetails?.currentApprovalLevelStatus !==
    TRANSACTION_STATUS_KEY.okay;

  const showCommentsSection = notApprovedStatus || commentsList?.length;

  useEffect(() => {
    if (cardRequestDetails?.id)
      dispatch(
        fetchComments({
          type: COMMENT_TYPE_PAYLOAD.CARD_REQUEST,
          id: cardRequestDetails?.id,
          page: 1,
          limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
          comment_type: COMMENT_TYPE.USER_CREATED,
        })
      );
  }, [cardRequestDetails]);

  const createNewComment = (formData) => {
    formData.append("owner_type", COMMENT_TYPE_PAYLOAD.CARD_REQUEST);
    formData.append("owner_id", cardRequestDetails?.id);

    dispatch(
      addComments({
        payload: formData,
        onSuccess: () => {
          setCommentPageNum(1);
          dispatch(
            fetchComments({
              type: COMMENT_TYPE_PAYLOAD.CARD_REQUEST,
              id: cardRequestDetails?.id,
              comment_type: COMMENT_TYPE.USER_CREATED,
              page: 1,
              limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
            })
          );
        },
      })
    );
  };

  function loadMoreComments() {
    setCommentPageNum((prev) => prev + 1);
    dispatch(
      fetchComments({
        type: COMMENT_TYPE_PAYLOAD.CARD_REQUEST,
        id: cardRequestDetails?.id,
        page: commentPage + 1,
        limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
        comment_type: COMMENT_TYPE.USER_CREATED,
      })
    );
  }

  return showCommentsSection ? (
    <div className="mt-9">
      <Text
        translationKey="comments.commentHeading"
        classes="font-semibold text-lg"
      />
      <Comments
        comments={commentsList}
        loadMoreLabel="comments.showOlderComments"
        isVisible={notApprovedStatus}
        createNewComment={(val) => {
          createNewComment(val);
        }}
        currentUser={currentUser}
        showLoadMoreCta={commentHasMore}
        loadMoreComments={loadMoreComments}
      />
    </div>
  ) : null;
}
