import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchPayrollSetting,
  setAutomatedPaymentToggleSwitch,
  updatePayrollSetting,
} from "@/store/reducers/payments";
import {
  fetchReimbursementSetting,
  setReimbursementAutomatedPaymentToggleSwitch,
  updateReimbursementSetting,
} from "@/store/reducers/reimbursement";

import {
  autoPaymentDaySelector,
  autoPaymentEnabledSelector,
  isLoadingPaymentsSelector,
} from "@/store/selectors/payments";
import {
  reimbursementAutopayDaySelector,
  reimbursementAutopayEnabledSelector,
} from "@/store/selectors/reimbursement";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import SettingFooter from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/Footer";
import vToast from "@/utils/vToast";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  REIMBURSEMENT_CONTEXT,
  REIMBURSEMENT_SETTINGS,
} from "@/constants/reimbursement";

function getConfig(context) {
  switch (context) {
    case BILL_PAYROLL_CONTEXT.PAYROLL:
      return {
        headerTitle:
          "payroll.payrollSettings.payrollSliders.automatedPayments.title",
        headerDescription:
          "payroll.payrollSettings.payrollSliders.automatedPayments.description",
        subHeaderTitle:
          "payroll.payrollSettings.payrollSliders.automatedPayments.addPaymentDate.title",
        subHeaderDesc:
          "payroll.payrollSettings.payrollSliders.automatedPayments.addPaymentDate.description",
        daySelector: autoPaymentDaySelector,
        autoPayEnableSelector: autoPaymentEnabledSelector,
        fetchSetting: fetchPayrollSetting(),
        setAutoPayToggleSwitch: setAutomatedPaymentToggleSwitch,
        sliderSearchParam:
          SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general
            .automatedPayments,
      };

    case BILL_PAYROLL_CONTEXT.BILLPAY:
      return {
        headerTitle: "billPay.settings.sliders.automatedPayments.title",
        headerDescription:
          "billPay.settings.sliders.automatedPayments.description",
        subHeaderTitle:
          "billPay.settings.sliders.automatedPayments.addPaymentDate.title",
        subHeaderDesc:
          "billPay.settings.rows.automatedPayments.rightSide.automatedPaymentsSwitch.sliderDescription",
        daySelector: autoPaymentDaySelector,
        autoPayEnableSelector: autoPaymentEnabledSelector,
        fetchSetting: fetchPayrollSetting(),
        setAutoPayToggleSwitch: setAutomatedPaymentToggleSwitch,

        sliderSearchParam:
          SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general
            .automatedPayments,
      };

    case REIMBURSEMENT_CONTEXT:
      return {
        headerTitle: "reimbursement.settings.sliders.automatedPayments.title",
        headerDescription:
          "reimbursement.settings.sliders.automatedPayments.description",
        subHeaderTitle:
          "reimbursement.settings.sliders.automatedPayments.addPaymentDate.title",
        subHeaderDesc:
          "reimbursement.settings.sliders.automatedPayments.addPaymentDate.description",
        daySelector: reimbursementAutopayDaySelector,
        autoPayEnableSelector: reimbursementAutopayEnabledSelector,
        fetchSetting: fetchReimbursementSetting(),
        setAutoPayToggleSwitch: setReimbursementAutomatedPaymentToggleSwitch,

        sliderSearchParam:
          SLIDERS_SEARCH_PARAMS.reimbursements.settings.general
            .reimbursementsAutomatedPayments,
      };

    default:
      break;
  }
}

export default function AutoPayments({ context, closeDrawer, setOnClose }) {
  const config = getConfig(context);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);
  const isLoading = useSelector(isLoadingPaymentsSelector);

  const autoPaymentEnabled = useSelector(config.autoPayEnableSelector);

  const autoPayDaySelectorValue = useSelector(config.daySelector);

  const autoPayDay = autoPaymentEnabled ? autoPayDaySelectorValue : null;

  const onDiscard = () => {
    closeDrawer();
  };

  useEffect(() => {
    dispatch(config?.fetchSetting);
  }, [context]);

  const onSuccess = () => {
    searchParams.delete(config?.sliderSearchParam);

    vToast({
      description:
        "payroll.payrollSettings.payrollSliders.automatedPayments.successEnableToast",
      variant: "success",
    });

    setSearchParams(searchParams);
    setSubmitted(true);
  };

  const onSubmit = () => {
    if (context === REIMBURSEMENT_CONTEXT) {
      dispatch(
        updateReimbursementSetting({
          payload: {
            [REIMBURSEMENT_SETTINGS.REIMBURSEMENT_AUTOPAY_ENABLED]: true,
            [REIMBURSEMENT_SETTINGS.REIMBURSEMENT_AUTOPAY_DAY]: date,
          },
          onSuccess,
        })
      );
    } else {
      dispatch(
        updatePayrollSetting({
          payload: {
            payroll_auto_pay_day: date,
            auto_payment_enabled: true,
          },
          onSuccess,
        })
      );
    }
  };

  const ref = useLeftHeaderTitle({
    title: config.headerTitle,
  });

  const [date, setDate] = useState();

  useEffect(() => {
    if (autoPayDay) setDate(autoPayDay);
  }, [autoPayDay]);

  const handleChange = (e) => {
    setDate(e.target.value);
  };

  useEffect(() => {
    setOnClose((searchParam) => {
      const isClosed = !searchParam?.includes(config.sliderSearchParam);

      if (isClosed && !submitted) {
        dispatch(config.setAutoPayToggleSwitch(false));
        //   if (context === REIMBURSEMENT_CONTEXT) {
        //     dispatch(setReimbursementAutomatedPaymentToggleSwitch(false));
        //   } else {
        //     dispatch(setAutomatedPaymentToggleSwitch(false));
        //   }
      }
    });
  }, []);

  const validDate = [...Array(28)].map((_val, i) => i + 1);
  const showDesc = !validDate?.includes(parseInt(date, 10)) && date !== "";

  return (
    <>
      <div className="slider-content-core pb-[150px] text-neutral-800 font-medium">
        <div className="flex flex-col w-11/12">
          <Text
            refProp={ref}
            translationKey={config.headerTitle}
            classes="text-3xl font-bold text-neutral-800"
          />
          <Text
            translationKey={config.headerDescription}
            classes="text-sm text-neutral-500 mt-1 font-medium"
          />
        </div>

        {/* Add payment date section */}
        <div className="w-11/12 mt-9">
          <Text
            translationKey={config.subHeaderTitle}
            classes="text-xl font-bold text-neutral-800"
          />
          <br />
          <Text
            translationKey={config.subHeaderDesc}
            classes="text-sm text-neutral-500 mt-1 font-medium"
          />
        </div>

        {/* date picker */}
        <div className="font-medium mt-9 text-neutral-800">
          <Input
            name="autopaymentDate"
            label="billPay.settings.sliders.automatedPayments.addPaymentDate.selectDate"
            value={date}
            type="number"
            rightText="of the month"
            autoComplete="off"
            onChange={handleChange}
            description={
              showDesc ? "Date entered should be between 1-28" : null
            }
            descriptionClasses="text-danger-500 text-xs"
          />
        </div>
      </div>

      <SettingFooter
        onDiscard={onDiscard}
        onSubmit={onSubmit}
        submitDisabled={date === "" || showDesc}
        showLoader={isLoading}
      />
    </>
  );
}

AutoPayments.propTypes = {
  context: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
};
