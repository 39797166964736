// utils, constant file imports
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchGlobalAccountTransactions } from "@/store/reducers/account";

import {
  globalAccountTransactionsSelector,
  globalAccountsSelector,
} from "@/store/selectors/account";

// core components
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import {
  amountToCurrency,
  dateToString,
  dateToTimeStampString,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import SliderExpansionHandler from "@/GlobalSliders/SliderExpansionHandler";

export default function Transactions() {
  const dispatch = useDispatch();

  const transactions = useSelector(globalAccountTransactionsSelector);
  const accounts = useSelector(globalAccountsSelector);

  const [searchParam, setSearchParams] = useSearchParams();
  const [tableData, setTableData] = useState(transactions);
  const ref = useRef(null);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableColsWidth, setTableColsWidth] = useState([]);
  const [currentAccount, setCurrentAccount] = useState({});

  const currentAccountId = searchParam.get(
    SLIDERS_SEARCH_PARAMS.company.transactions
  );

  useEffect(() => {
    setTableHeaders(["date", "common.status", "common.description", "amount"]);
    setTableColsWidth([250, 120, 300, 120]);
    dispatch(fetchGlobalAccountTransactions({ account_id: currentAccountId }));
  }, []);

  useEffect(() => {
    setCurrentAccount(accounts.find((acc) => acc.id === currentAccountId));
  }, [accounts]);

  useEffect(() => {
    setTableData(transactions);
  }, [transactions]);

  return (
    <div className="slider-content-container pb-11">
      <div className="slider-content-core flex flex-col px-9 gap-6">
        <div className="flex items-center justify-between">
          <SliderHeader
            heading="accounting.transactions.title"
            subText="company.globalAccounts.transactionsDesc"
            subTextTranslationProps={{ currency: currentAccount?.currency }}
            headingClasses="text-3xl font-bold text-neutral-800"
            subTextClasses="mb-6"
            refFlag
          />
          <SliderExpansionHandler iconClasses="text-neutral-500" />
        </div>

        <Table
          numberOfStickyColsLeft={1}
          colWidths={tableColsWidth}
          rightColWidths={[120]}
          headerSticky
        >
          <tr className="text-xs !text-left">
            {tableHeaders.map((item, index) => (
              <th
                key={`edit-accounting-fields-slider-table-${index}`}
                className="font-semibold"
              >
                <Text translationKey={item} />
              </th>
            ))}
          </tr>

          {tableData?.map((item, index) => (
            <tr className="text-sm font-semibold " key={`edit-fields-${index}`}>
              <td>{dateToTimeStampString(item?.create_time)}</td>

              <td ref={ref}>{item?.status}</td>

              <td>
                <Text translationKey={item?.description} />
              </td>

              <td className="text-center">
                {amountToCurrency(item?.amount, item?.currency)}
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
