import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";
import { accountingCategoryTagSelector } from "@/store/selectors/tags";

import TagInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/TagInput";

import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import { VENDOR_DEFAULT_PAYLOAD_KEYS } from "@/constants/vendors";

/**
 * from useForm returnage
 * @param {Object} values
 * @param {Object} errors
 * @param {Function} handleChange
 * @param {Function} setValues
 *
 * keys for dropdown/textbox component, also passed to useForm
 * @param {String} dropdownInputKey
 * @param {String} textInputKey
 *
 * UI extension props
 * @param {Object} vpSelectProps
 * @param {Object} inputProps
 * @param {String} inPayrollContext
 */
export default function AccountingCategoryInput({
  values,
  errors,
  handleChange,
  setValues,

  // use generalized enums, not feature specific
  dropdownInputKey = VENDOR_DEFAULT_PAYLOAD_KEYS.SELECTED_ACCOUNTING_VENDOR,
  textInputKey = VENDOR_DEFAULT_PAYLOAD_KEYS.ACCOUNTING_VENDOR_NAME,

  vpSelectProps = {}, // dropdown component
  inputProps = {}, // text input component
  inPayrollContext,
  disabled,
}) {
  const { t } = useTranslation();
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );
  const accountingCategoryTag =
    useSelector(accountingCategoryTagSelector) ?? {};

  if (!accountingEnabled) return null;

  return (
    <TagInput
      dropdownInputKey={dropdownInputKey}
      textInputKey={textInputKey}
      values={values}
      errors={errors}
      handleChange={handleChange}
      setValues={setValues}
      tag={accountingCategoryTag}
      vpSelectProps={{
        label: `${
          ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingIntegrationSoftware]
        } ${t("category.singularSmall")}`,
        placeholder: "billPay.vendors.createVendor.selectCategory",
        helperText: inPayrollContext
          ? "payroll.payrollEmployees.addEmployee.selectCategoryHelperText"
          : "billPay.vendors.createVendor.selectCategoryHelperText",
        ...vpSelectProps,
      }}
      inputProps={{
        label: `${
          ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingIntegrationSoftware]
        } ${t("category.singularSmall")}`,
        placeholder: "billPay.vendors.createVendor.enterCategory",
        description: "billPay.vendors.createVendor.enterCategoryHelperText",
        ...inputProps,
      }}
      disabled={disabled}
    />
  );
}

AccountingCategoryInput.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,

  dropdownInputKey: PropTypes.string,
  textInputKey: PropTypes.string,

  vpSelectProps: PropTypes.object,
  inputProps: PropTypes.object,
  inPayrollContext: PropTypes.bool,
  disabled: PropTypes.bool,
};
