import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import {
  PHYSICAL_CARD_STATUSES_BE,
  PHYSICAL_CARD_STATUSES_CONFIG,
} from "@/utils/constants/common";

export default function PhysicalCardIcon({ status, index }) {
  let config = {};
  const tempStatus =
    PHYSICAL_CARD_STATUSES_BE[status] ?? PHYSICAL_CARD_STATUSES_BE.notIssued;
  config = PHYSICAL_CARD_STATUSES_CONFIG[tempStatus];

  return config ? (
    <>
      <div
        className="flex items-center justify-center "
        id={`physical-card-tooltip-${index}`}
      >
        <Icon name={config.icon} className={config.class} />
      </div>
      <Tooltip id={`physical-card-tooltip-${index}`} direction="top">
        <Text translationKey={config.label} />
      </Tooltip>
    </>
  ) : null;
}

PhysicalCardIcon.propTypes = {
  status: PropTypes.string,
  index: PropTypes.number,
};
