import PropTypes from "prop-types";

import {
  APPROVAL_STATUS_TO_PILL_COLOR_MAP,
  PAYMENT_APPROVAL_STATUSES,
} from "@/utils/constants/payments";

import { EXPENSE_STATUSES } from "@/constants/expense";

import ApproversPopup from "../ApproversPopup";
import Icon from "../Icon";
import Text from "../Text";

export default function ApprovalProgress({
  id,
  totalApprovalSteps,
  currentStep,
  currentStatus,
  rejectionText = "",
  previousApprovers = [],
  disabled = false,
  isExpense = false, // say approve instead of review in text
}) {
  currentStatus ||= isExpense
    ? EXPENSE_STATUSES.OKAY
    : PAYMENT_APPROVAL_STATUSES.approved;
  const getBackgroundColor = (idx) => {
    const currentStepIndex = currentStep - 1;
    if (idx < currentStepIndex)
      // TODO, add isExpense case
      return (
        APPROVAL_STATUS_TO_PILL_COLOR_MAP.approved ||
        APPROVAL_STATUS_TO_PILL_COLOR_MAP.processing
      );
    if (idx === currentStepIndex) {
      // TODO, add isExpense case
      return APPROVAL_STATUS_TO_PILL_COLOR_MAP[currentStatus]; // something w.r.t currentStatus
    }
    return APPROVAL_STATUS_TO_PILL_COLOR_MAP.blank;
  };

  const isRejected =
    [
      PAYMENT_APPROVAL_STATUSES.rejected,
      PAYMENT_APPROVAL_STATUSES.denied,
    ].includes(currentStatus) || rejectionText;
  if (isRejected) currentStep += 1; // BE won't update currentStep if currentStep is a rejection, so need to do this on FE

  const isAutoApproved =
    currentStep === 0 && totalApprovalSteps === 0 && !previousApprovers?.length;

  return (
    <div className="flex items-center w-full">
      {/* Left - data */}
      <div className="grow">
        {/* Top - text */}
        <div className="text-left">
          {isRejected ? (
            <Text
              translationKey={
                rejectionText ||
                (isExpense
                  ? "expenses.needsReview.badgeStatus.flagged"
                  : "billPay.bill.approvals.approvalProgress.rejectedTitleCase")
              }
              classes="text-danger-500 font-semibold text-sm"
            />
          ) : isAutoApproved ? (
            <Text
              translationKey={
                isExpense
                  ? "expenses.needsReview.autoReviewed"
                  : "billPay.bill.approvals.approvalProgress.autoApprovedText"
              }
              classes="text-sm"
            />
          ) : (
            <span className="text-sm">
              {totalApprovalSteps ? (
                <span
                  className={`font-semibold ${
                    disabled ? "text-neutral-500" : "text-neutral-800"
                  } `}
                >
                  {currentStep}/{totalApprovalSteps}
                  &nbsp;
                </span>
              ) : null}
              <Text
                classes={`font-medium text-neutral-500 ${
                  totalApprovalSteps ? "" : "capitalize"
                }`}
                translationKey={
                  isExpense
                    ? "expenses.needsReview.reviewedSuffix"
                    : "billPay.bill.approvals.approvalProgress.approved"
                }
              />
            </span>
          )}
        </div>
        {/* Bottom - pills */}
        {totalApprovalSteps ? (
          <div className="flex gap-1 py-2">
            {Array(totalApprovalSteps)
              .fill(null)
              .map((_, idx) => (
                <span
                  className={`rounded-3xl w-6 h-1.5 mr-0.5 ${getBackgroundColor(
                    idx
                  )}`}
                  key={idx}
                >
                  &nbsp;
                </span>
              ))}
          </div>
        ) : null}
      </div>
      {/* Right - hover icon */}

      {currentStep === 0 &&
      totalApprovalSteps === 0 &&
      (currentStatus === PAYMENT_APPROVAL_STATUSES.approved ||
        isExpense) ? null : (
        <ApproversPopup
          id={id}
          approvers={previousApprovers}
          totalApprovalSteps={totalApprovalSteps}
          currentStep={currentStep}
          isExpense
        >
          <Icon
            name="Info"
            className="text-neutral-300 p-[1.5px] h-5 w-5 hover:cursor-pointer"
          />
        </ApproversPopup>
      )}
    </div>
  );
}

ApprovalProgress.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalApprovalSteps: PropTypes.number,
  currentStep: PropTypes.number,
  currentStatus: PropTypes.oneOf(Object.values(PAYMENT_APPROVAL_STATUSES)),
  rejectionText: PropTypes.string,
  disabled: PropTypes.bool,
  previousApprovers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      userName: PropTypes.string,
      updatedAt: PropTypes.string,
      status: PropTypes.oneOf(Object.values(PAYMENT_APPROVAL_STATUSES)),
    })
  ),
  isExpense: PropTypes.bool,
};
