import PropTypes from "prop-types";

import Badge from "@/components/core/Badge";
import { BILLING_BADGE_TYPES } from "@/utils/constants/company";

export default function BillingPaymentStatuscell({ val }) {
  const uiConfig = BILLING_BADGE_TYPES[val.status];

  return (
    <div className="m-auto text-center w-max">
      <Badge
        variant={uiConfig?.color}
        preIcon={uiConfig?.icon || ""}
        translationKey={uiConfig?.text}
        classes="w-15 h-6 text-xs"
      />
    </div>
  );
}

BillingPaymentStatuscell.propTypes = {
  val: PropTypes.object,
};
