import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import {
  isFetchingExpenseAlertsSettingSelector,
  isFetchingFlagRulesSelector,
} from "@/store/selectors/cards";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { amountToCurrency } from "@/utils/common";

import {
  CREATE_NEW_CONFIG,
  LABEL_SELECTED_VALUES,
  RULES_AND_ALERT_TYPE,
  SUBMIT_BUTTON,
} from "@/constants/Cards";

import CreateNewPage from "./CreateNewPage";

function RulesAndAlertPreviewSlider({
  title,
  description,
  headingColor,
  amount,
  heading,
  departmentOptions,
  categoryOptions,
  merchantOptions,
  currency,
  selectedValueArray,
  selectedPeopleArray,
  peopleOptions,
  onSubmit,
  handleEdit,
  handleDelete,
  handleReset,
  isEmpty = false,
  mode = RULES_AND_ALERT_TYPE.RULES,
}) {
  const OPTIONS_VALUES = {
    category: categoryOptions,
    department: departmentOptions,
    merchant: merchantOptions,
  };
  const isAlertSlider = mode === RULES_AND_ALERT_TYPE.ALERT;
  const isFetchingFlagRules = useSelector(isFetchingFlagRulesSelector);
  const isFetchingExpenseAlert = useSelector(
    isFetchingExpenseAlertsSettingSelector
  );

  if (isFetchingFlagRules || isFetchingExpenseAlert) {
    return <Text translationKey="cards.editLimit.loadingText" classes="px-9" />;
  }

  function getViewMoreFields(sectionKey, selectedIds) {
    return OPTIONS_VALUES[sectionKey]
      ?.filter((obj) => selectedIds.includes(obj.id))
      ?.map((curritem) => curritem?.label);
  }

  return !isEmpty ? (
    <div className="slider-content-container tooltipCheckboxDropdown">
      <div className="pb-12 slider-content-core px-9">
        <div className="flex flex-col pb-5">
          <Text
            color="neutral-800"
            classes="text-3xl font-bold"
            translationKey={title}
          />

          <Text
            color="neutral-500"
            classes="text-sm font-medium"
            translationKey={description}
          />
        </div>

        {selectedValueArray?.map((selectedValues, index) => {
          const selectedPeopleNamesArray = peopleOptions
            ?.filter((obj) => {
              const currentPeopleArray = selectedPeopleArray.at(index);
              return currentPeopleArray.includes(obj.id);
            })
            ?.map((filteredObjects) => filteredObjects?.displayName);

          const slicedPeopleNames = selectedPeopleNamesArray
            ?.filter((name, peopleNameIndex) => peopleNameIndex < 3)
            ?.join(", ");

          const unslicedPeopleNames = (
            selectedPeopleNamesArray?.slice(3) ?? []
          ).map((names, nameIndex) => {
            return (
              <Text translationKey={names} key={`${nameIndex}-${index}`} />
            );
          });

          const showUnslicedPeopleNames =
            selectedPeopleNamesArray.length - 3 > 0;

          return (
            <div
              className="flex gap-3 my-5"
              key={`${index}-${selectedValues.id}`}
            >
              <div className="relative w-full card-wrapper">
                <Text color={headingColor} translationKey={heading} />

                <div className="flex gap-2 mt-3">
                  <Icon
                    className="ml-3 text-neutral-200"
                    name="SubdirectoryArrowRight"
                  />

                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1 row">
                      <Text
                        classes="text-neutral-500 font-semibold"
                        translationKey="cards.editLimit.amount"
                      />

                      <Text
                        translationKey={amountToCurrency(
                          amount[index],
                          currency
                        )}
                      />
                    </div>

                    {/* category merchants department */}
                    {Object.keys(selectedValues).map((item, id) => {
                      const selectedValuesNamesArray = getViewMoreFields(
                        item,
                        selectedValues[item]
                      );

                      const joinedNames = selectedValuesNamesArray
                        ?.filter((names, nameIndex) => nameIndex < 3)
                        ?.join(", ");

                      const unJoinedNames = (
                        selectedValuesNamesArray?.slice(3) ?? []
                      ).map((names, nameIndex) => {
                        return (
                          <Text
                            translationKey={names}
                            key={`${nameIndex}-${index}-${id}`}
                          />
                        );
                      });

                      const showUnjoinedNames = unJoinedNames?.length > 0;

                      return selectedValues[item]?.length ? (
                        <div className="flex gap-2" key={id}>
                          <div>
                            <Text
                              classes="text-neutral-500 font-semibold"
                              translationKey={LABEL_SELECTED_VALUES[item]}
                            />
                            :
                          </div>

                          <Text
                            classes="text-neutral-800 font-semibold"
                            translationKey={joinedNames}
                          />

                          {showUnjoinedNames ? (
                            <>
                              <Text
                                classes="text-primary-500"
                                translationKey="Misc.more"
                                translationProps={{
                                  length: unJoinedNames?.length,
                                }}
                                id={`${item}-${index}-${id}`}
                              />

                              <Tooltip
                                direction="bottom"
                                id={`${item}-${index}-${id}`}
                              >
                                <div className="flex flex-col gap-2">
                                  {unJoinedNames}
                                </div>
                              </Tooltip>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : null;
                    })}

                    {/* peoples section */}

                    <div>
                      {isAlertSlider && selectedPeopleNamesArray?.length ? (
                        <div>
                          <Text
                            classes="text-neutral-500 font-semibold"
                            translationKey="cards.editLimit.toThesePeople"
                          />

                          <Text
                            classes="text-neutral-800 font-semibold"
                            translationKey={`${slicedPeopleNames}  `}
                          />

                          {showUnslicedPeopleNames ? (
                            <div className="flex flex-row flex-nowrap">
                              <Text
                                classes="text-primary-500 ml-2"
                                translationKey="Misc.more"
                                translationProps={{
                                  length: unslicedPeopleNames?.length,
                                }}
                                id={`people-${index}`}
                              />

                              <Tooltip
                                direction="bottom"
                                id={`people-${index}`}
                              >
                                <div className="flex flex-col gap-2">
                                  {unslicedPeopleNames}
                                </div>
                              </Tooltip>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  className="absolute z-50 p-2 cursor-pointer top-3 right-3 text-neutral-500"
                  onClick={() => handleEdit(selectedValues)}
                >
                  <Icon name="Edit" />
                </div>
              </div>
              <Icon
                className="mt-5 cursor-pointer text-neutral-300"
                name="Delete"
                handleClick={() => handleDelete(selectedValues)}
              />
            </div>
          );
        })}
      </div>
      <div className="flex justify-end px-4 py-5 slider-footer">
        <Button
          classes="w-60"
          preIcon="Flag"
          label={SUBMIT_BUTTON[mode]}
          onClick={onSubmit}
        />
      </div>
    </div>
  ) : (
    <CreateNewPage
      {...CREATE_NEW_CONFIG[mode]}
      onClickHandler={handleReset}
      innerSectionClasses="px-9"
    />
  );
}
RulesAndAlertPreviewSlider.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  headingColor: PropTypes.string,
  heading: PropTypes.string,
  currency: PropTypes.string,
  amount: PropTypes.string,
  departmentOptions: PropTypes.array,
  categoryOptions: PropTypes.array,
  merchantOptions: PropTypes.array,
  selectedValueArray: PropTypes.array,
  onSubmit: PropTypes.func,
  mode: PropTypes.string,
  selectedPeopleArray: PropTypes.array,
  peopleOptions: PropTypes.array,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleReset: PropTypes.func,
  isEmpty: PropTypes.bool,
};
export default RulesAndAlertPreviewSlider;
