import React from "react";
import { useSelector } from "react-redux";

import { blockedVirtualCardPageFiltersSelector } from "@/store/selectors/cards";

import VirtualCardsPageHelper from "@/components/Cards/VirtualCardsPageHelper";

import { ROUTES } from "@/constants/routes";

function VirtualBlockedCard() {
  const filters = useSelector(blockedVirtualCardPageFiltersSelector);
  return (
    <VirtualCardsPageHelper
      tab={ROUTES.cards.virtualCards.blocked.pathName}
      filterArray={filters}
    />
  );
}

export default VirtualBlockedCard;
