import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchAccountingTransactions,
  fetchFailedToSync,
  resetAccountingTransactionsList,
  updateTagAndVendor,
} from "@/store/reducers/accounting_transactions";
import { fetchRulesCount } from "@/store/reducers/rules";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  accountingCardsFilterSelector,
  accountingTransactionsHasMoreSelector,
  accountingTransactionsSelector,
  accountingTransactionsTotalSelector,
  availableToSyncSelector,
  failedToSyncSelector,
  fetchingAvailableToSyncSelector,
  fetchingFailedToSyncSelector,
  isFetchingTransactionsSelector,
} from "@/store/selectors/accounting_transactions";
import {
  accountingIntegrationSoftwareSelector,
  isFetchingClientDetailsSelector,
} from "@/store/selectors/client";
import {
  isFetchingMissingDetailsSelector,
  missingDetailsSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { rulesCountSelector } from "@/store/selectors/rules";
import {
  accountingCategoryTagSelector,
  cardsCustomTagsSelector,
  nonAccountingCategoryTagSelector,
} from "@/store/selectors/tags";

import AccountingTransactionSearchFilter from "@/components/Accounting/Transactions/AccountingTransactionSearchFilter";
import { formatDataForTable } from "@/components/Accounting/Transactions/Cards/PageHelper/helper";
import TransactionsTable from "@/components/Accounting/Transactions/TransactionsTable";
import FailedSyncCard from "@/components/Accounting/Transactions/common/FailedSyncCard";
import RulesCard from "@/components/Accounting/Transactions/common/RulesCard";
import MissingItemsCard from "@/components/common/QrPayAndExpense/common/Overview/MissingItemsCard";
import { convertFilters } from "@/utils/filters";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_HEADER_IDS,
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_SOFTWARES_ICONS,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
  TAB_STATUS_MAP,
} from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import {
  accountingActionHandler,
  accountingBulkAction,
  formatAccountingStatusFilter,
  getSortingParams,
  stickyRightColumn,
} from "../../common/util";

export default function CardsPageHelper({ tab }) {
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState({
    type: null,
    category: SORTING_CATEGORY.AMOUNT,
  });
  // search params
  const [searchparams, setSearchParams] = useSearchParams();
  // Missing Data
  const missingData = useSelector((state) => missingDetailsSelector(state));
  const isFetchingMissingDetails = useSelector((state) =>
    isFetchingMissingDetailsSelector(state)
  );

  const isFetchingClient = useSelector(isFetchingClientDetailsSelector);

  // Transactions
  const transactions = useSelector(accountingTransactionsSelector);

  const isFetchingTransactions = useSelector(isFetchingTransactionsSelector);
  const hasMore = useSelector(accountingTransactionsHasMoreSelector);
  const totalTransactions = useSelector(accountingTransactionsTotalSelector);

  // Available to Sync
  const { syncableCount, syncableIds } = useSelector(availableToSyncSelector);
  const fetchingAvailableToSync = useSelector(fetchingAvailableToSyncSelector);

  // Failed to sync
  const failedToSync = useSelector(failedToSyncSelector);
  const fetchingFailedToSync = useSelector(fetchingFailedToSyncSelector);

  // Accounting Software
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = accountingSoftware || false;

  // Filters
  const filters = useSelector(accountingCardsFilterSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const requiredFilters = formatAccountingStatusFilter(
    filters,
    tab,
    accountingEnabled
  );

  // Tags
  const accountingCategoryTags = useSelector(accountingCategoryTagSelector);
  const nonAccountingTags = useSelector(nonAccountingCategoryTagSelector);
  const customTags = useSelector(cardsCustomTagsSelector);

  // Rules
  const rulesCount = useSelector(rulesCountSelector);
  // Vendors
  const vendors = useSelector(accountingVendorsSelector);

  // Setting headers
  const headers = [
    {
      id: ACCOUNTING_HEADER_IDS.MERCHANT,
      label: "accounting.transactions.cards.listingFields.merchant.header",
      classes: "text-left",
      checkbox: true,
      colWidth: 320,
    },
    {
      id: ACCOUNTING_HEADER_IDS.CARD_OWNER,
      label: "accounting.transactions.cards.listingFields.owner.header",
      classes: "text-left",
      colWidth: 300,
    },
    {
      id: ACCOUNTING_HEADER_IDS.AMOUNT,
      label: "accounting.transactions.cards.listingFields.amount.header",
      classes: "text-right justify-end",
      sortable: true,
      colWidth: 150,
    },
    {
      id: ACCOUNTING_HEADER_IDS.LEDGER_DATE,
      label: "accounting.transactions.cards.listingFields.ledgerDate.header",
      classes: "text-right justify-end",
      sortable: false,
      colWidth: 150,
    },
    {
      id: ACCOUNTING_HEADER_IDS.ACCOUNTING_TAG,
      label: accountingCategoryTags?.name,
      tagId: accountingCategoryTags?.id,
      classes: "text-left",
      options: accountingCategoryTags?.options,
      colWidth: 300,
      showColumn: accountingEnabled && (accountingCategoryTags ?? false),
    },
    {
      id: ACCOUNTING_HEADER_IDS.RECEIPT,
      label: "accounting.transactions.cards.listingFields.receipt.header",
      classes: "text-center flex-1",
      colWidth: 120,
    },
    {
      id: ACCOUNTING_HEADER_IDS.NOTE,
      label: "accounting.transactions.cards.listingFields.memo.header",
      classes: "text-center flex-1",
      colWidth: 120,
    },
    {
      id: ACCOUNTING_HEADER_IDS.TRANSACTION_STATUS,
      label: "accounting.transactions.cards.listingFields.reviewStatus.header",
      classes: "text-center flex-1",
      colWidth: 200,
    },
    vendors
      ? {
          id: ACCOUNTING_HEADER_IDS.VENDORS,
          label: `${ACCOUNTING_SOFTWARES_ICONS[accountingSoftware]} vendor`,
          vendorId: 1,
          classes: "text-left",
          options: vendors,
          colWidth: 300,
          showColumn: !!accountingSoftware,
        }
      : [],
    ...(nonAccountingTags?.map((tag) => ({
      ...tag,
      actualId: tag?.id,
      id: ACCOUNTING_HEADER_IDS.NON_ACCOUNTING_TAG,
      label: tag.name,
      tagId: tag.id,
      classes: "text-left",
      options: tag.options,
      colWidth: 300,
      showColumn: accountingEnabled,
    })) ?? []),
    ...(customTags?.map((tag) => ({
      ...tag,
      actualId: tag?.id,
      id: ACCOUNTING_HEADER_IDS.CUSTOM_TAG,
      label: tag.name,
      tagId: tag.id,
      classes: "text-left",
      options: tag.options,
      colWidth: 300,
      showColumn: customTags?.length,
    })) ?? []),
    {
      id: ACCOUNTING_HEADER_IDS.LINKED_TO,
      label: "accounting.transactions.cards.listingFields.linkedTo.header",
      classes: "text-right",
      showColumn: true,
      colWidth: 300,
    },
    {
      id: ACCOUNTING_HEADER_IDS.ACTION,
      label: "accounting.transactions.cards.listingFields.action.header",
      // Component:
      classes: "text-center justify-center items-center flex-1",
      showColumn: tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED,
      colWidth: 200,
    },
    {
      id: ACCOUNTING_HEADER_IDS.OPEN_IN,
      label: "Open in",
      classes: "text-center justify-center",
      showColumn:
        tab === ACCOUNTING_TRANSACTION_STATUS.SYNCED &&
        ![
          ACCOUNTING_SOFTWARES.UNIVERSAL_CSV,
          ACCOUNTING_SOFTWARES.TALLY,
        ].includes(accountingSoftware),
      colWidth: 145,
    },
  ].filter((item) => item?.showColumn ?? true);

  // Selected transactions
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedTransactionObjects, setSelectedTransactionObjects] = useState(
    []
  );
  const [bulkApproveSelectAll, setBulkApproveSelectAll] = useState(false);

  // Infinite Scroll
  const onReset = () => {
    dispatch(resetAccountingTransactionsList());
  };

  const loadMore = (pageNumber = 1, onSuccess = () => {}) => {
    // we have 2 cases, connected to accounting and NOT connected to accounting software
    // Not connected: we need to ensure that we're calling the API on first load
    // Connected: only call the API if tab is passed to prevent duplicate calls when refreshing on pending and synced tabs
    if ((tab || !accountingEnabled) && !isFetchingClient) {
      dispatch(
        fetchAccountingTransactions({
          type: ACCOUNTING_TRANSACTION_PAGES.EXPENSES,
          accounting_status: TAB_STATUS_MAP[tab],
          page: pageNum || pageNumber,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          ...getSortingParams(sorting),
          ...convertFilters(appliedFilters),
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (!isFetchingClient) {
      loadMore();
    }
  }, [isFetchingClient]);

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...getSortingParams(sorting),
      ...convertFilters(appliedFilters),
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.15,
  });

  // First load
  useEffect(() => {
    dispatch(
      fetchFailedToSync({ transaction: ACCOUNTING_TRANSACTION_PAGES.EXPENSE })
    );

    dispatch(fetchRulesCount());
    return () => {
      dispatch(resetAccountingTransactionsList());
    };
  }, []);

  // Converting transactions to data format
  const formattedTransactions = useMemo(() => {
    return transactions?.map((transaction) => formatDataForTable(transaction));
  }, [transactions]);

  const handleViewExpense = (id) => {
    if (!searchparams.has(SLIDERS_SEARCH_PARAMS.accounting.cards.id, id)) {
      searchparams.append(SLIDERS_SEARCH_PARAMS.accounting.cards.id, id);
      setSearchParams(searchparams);
    }
  };

  const handleUpdateTagandVendor = (payload) => {
    payload.transaction_type = ACCOUNTING_TRANSACTION_PAGES.EXPENSE;
    dispatch(updateTagAndVendor({ payload }));
  };

  const openRules = () => {
    searchparams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.id, "true");
    setSearchParams(searchparams);
  };

  const handleFailedToSyncSliderOpen = () => {
    searchparams.append(
      SLIDERS_SEARCH_PARAMS.accounting.failedToSync,
      ACCOUNTING_TRANSACTION_PAGES.CARDS
    );
    setSearchParams(searchparams);
  };

  // Setting props for table
  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    numberOfStickyColsRight: stickyRightColumn(accountingSoftware, tab) ? 1 : 0,
    colWidths: headers.map((header) => header.colWidth),
    rightColWidths: !accountingSoftware ? [] : [145],
    emptyDataIconSrc: "emptyScreen.svg",
  };

  return (
    <>
      <div>
        <MissingItemsCard
          data={{ count: missingData?.incomplete }}
          isFetching={isFetchingMissingDetails}
        />
        {accountingEnabled ? (
          <div className="flex items-center gap-4 mt-6 mb-1">
            {accountingSoftware !== ACCOUNTING_SOFTWARES.UNIVERSAL_CSV ? (
              <FailedSyncCard
                count={failedToSync}
                isFetching={fetchingFailedToSync}
                handleClick={() => handleFailedToSyncSliderOpen()}
              />
            ) : null}
            <RulesCard rulesCount={rulesCount} viewRules={openRules} />
          </div>
        ) : null}
      </div>
      <div className="mb-2">
        <AccountingTransactionSearchFilter
          filters={requiredFilters}
          showSyncAll={
            accountingEnabled && tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED
          }
          availableToSync={syncableCount}
          fetchingAvailableToSync={fetchingAvailableToSync}
          syncAll={accountingBulkAction}
          syncableIds={syncableIds}
          page={ACCOUNTING_TRANSACTION_PAGES.EXPENSE}
        />
      </div>

      {formattedTransactions && (
        <TransactionsTable
          pageNum={pageNum}
          tab={tab}
          headers={headers}
          hasMore={hasMore}
          tableArgs={tableArgs}
          originalTransactions={transactions}
          actionDisabled={!accountingSoftware}
          isFetching={isFetchingTransactions}
          transactions={formattedTransactions}
          totalTransactions={totalTransactions}
          page={ACCOUNTING_TRANSACTION_PAGES.CARDS}
          selectedTransactions={selectedTransactions}
          selectedTransactionObjects={selectedTransactionObjects}
          handleRefChange={handleRefChange}
          handleBulkAction={accountingBulkAction}
          loadMore={loadMore}
          onActionClick={accountingActionHandler}
          onViewTransactionClick={handleViewExpense}
          handleUpdateTagAndVendor={handleUpdateTagandVendor}
          bulkApproveSelectAll={bulkApproveSelectAll}
          setSelectedTransactions={setSelectedTransactions}
          setSelectedTransactionObjects={setSelectedTransactionObjects}
          setBulkApproveSelectAll={setBulkApproveSelectAll}
          sorting={sorting}
          setSorting={setSorting}
        />
      )}
    </>
  );
}

CardsPageHelper.propTypes = {
  tab: PropTypes.string,
};
