import { ROUTES } from "@/constants/routes";

export const REIMBURSEMENT_CLAIM_TABS = [
  {
    name: "myVolopay.reimbursements.claimTabs.drafts",
    count: null,
    path: ROUTES.myVolopay.reimbursement.claims.drafts.absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "drafts",
    },
  },
  {
    name: "myVolopay.reimbursements.claimTabs.active",
    count: null,
    path: ROUTES.myVolopay.reimbursement.claims.active.absolutePath,
    key: 2,
    defaultQueryParams: {
      tab: "active",
    },
  },
  {
    name: "myVolopay.reimbursements.claimTabs.history",
    count: null,
    path: ROUTES.myVolopay.reimbursement.claims.history.absolutePath,
    key: 3,
    defaultQueryParams: {
      tab: "history",
    },
  },
];

export const REIMBURSEMENT_REPORTS_TABS = [
  {
    name: "myVolopay.reimbursements.reports.drafts.title",
    count: null,
    path: ROUTES.myVolopay.reimbursement.reports.drafts.absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "drafts",
    },
  },
  {
    name: "myVolopay.reimbursements.reports.active.title",
    count: null,
    path: ROUTES.myVolopay.reimbursement.reports.active.absolutePath,
    key: 2,
    defaultQueryParams: {
      tab: "active",
    },
  },
  {
    name: "myVolopay.reimbursements.reports.history.title",
    count: null,
    path: ROUTES.myVolopay.reimbursement.reports.history.absolutePath,
    key: 3,
    defaultQueryParams: {
      tab: "history",
    },
  },
];

export const TAB_TYPES = {
  DRAFTS: "drafts",
  ACTIVE: "active",
  HISTORY: "history",
};

export const CREATE_REPORT_KEYS = {
  NAME: "name",
  DESCRIPTION: "description",
  PROJECT_ID: "projectId",
  CLAIMS_ID: "claimIds",
  NO_OF_CLAIMS: "noOfClaims",
  TOTAL_AMOUNT: "totalAmount",
};

export const SORT_COLUMN_BY_MISSING_DETAILS = "missing_details";

export const REPORT_CONTEXT = "report";

export const REPORT_ACTION_PARAMS = {
  TYPE_REIMBURSEMENT: "report",
  DO: {
    ADD_PAYMENT_DATE: "add_payment_date",
    VERIFIED: "verified",
    SKIP_APPROVALS: "skip_approvals",
    UNSCHEDULE_PAYMENT: "unschedule_payment",
  },
};
