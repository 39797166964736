import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { sliderHeaderDataSelector } from "@/store/selectors/slider";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import GenericFormSection from "@/components/GenericForm/GenericFormSection";
import {
  GENERIC_FORM_PAGE_KEY,
  SECTIONS_KEY_PLURAL,
  SECTION_KEY,
  formValueFromExactValue,
  formValueFromSkeleton,
  generatePayloadFromFormValue,
  getReviewRows,
  removeSkipFields,
  stepPropType,
} from "@/components/GenericForm/common";
import { useForm } from "@/utils/useForm";

/**
 *
 * @param {String}        globalSliderQueryParam query param used to register the component in GlobalSlider
 * @param {Function}      setOnBack  set a callback that will run when back arrow (slider arrow) is pressed,
 *                                    useful in multipage forms, exposed by the GlobalSlider component
 *
 * @param {boolean}       isFetching fetching requirements (skeleton) from BE
 * @param {Array<Object>} pages      form skeleton of pages, the BE response
 *
 * @param {Function}      onSubmit   callback that runs after final submission. Payload and 'filledInitialFormValue' object are available as params
 * @param {Function}      onChange   to read changes. Not intended for writes. For writing to form values, use Redux dispatch
 *
 * 'store' here  means an object in a Redux slice. Example: store > vendors (slice) > bankDetailsForm (object)
 * @param {Function} storeSelector      // Example: vendors/bankDetailsFormSelector
 * @param {Function} storeSetterReducer // Example: vendors/storeMergeReducer
 * @param {Function} storeMergeReducer  // Example: vendors/storeClearReducer
 * @param {Function} storeClearReducer  // Example: vendors/storeClearReducer
 * @param {ReactNode} sectionHeaderComponent // to customise section header. fallback to string
 *                   - Note: except `fields` all section props is passed to component
 *
 * @param {Function} wantCleanUp // TODO, add purpose
 * @param {String} genericFormClasses container classes
 *
 * @param {String}  negativeLabel footer left  cta label, e.g. cancel/reset
 * @param {String}  positiveLabel footer right cta label, e.g. continue
 * @param {String}  positiveLabelFinal footer right cta label, of last page e.g. Submit
 *
 *
 * @param {Boolean} generateReviewRows generate array of rows,
 *    for use in review part of your feature. Use with `DetailRows` src/components/Common/DetailRows.js
 *
 * @param {String} hideFields hide fields with `hidden` key
 * @param {String} hideEmptySections hide section if all fields inside are `hidden`
 *      needs hideFields prop to be true to work
 * @param {String} autofillValueFromRegexIfLiteral value of string fields will be prefilled
 *     if the `rule` key regex is actually just a literal
 * @param {String} autofillDropdownIfSingleOption
 *    single entry 'required' true dropdowns will be autoFill
 *
 * @param {Object} autoFillSource { key1: { value: 'value1' }, key2: { value: 'KO', dependentMapping: { KO: 'val1' } }...}
 *    BE field having `dependent` key, will be prefilled with from this object
 *
 * @param {Boolean} renderSliderHeader render slider header data or not
 *  (recommend setting this to false for inSlider generic forms, otherwise it creates a bug where already existing header data is lost)
 *  notable use: VendorSlider unverified vendor was not rendering header title, since it has a partial generic form, setting to `false` resolved the bug.
 */

export default function GenericForm({
  globalSliderQueryParam,
  onBackFromFirstPage = null,
  setOnBack = () => {},
  showFooter = true,
  formId = "vendor-add-bank-details-form",
  sectionHeaderComponent = null,
  isFetching = false,
  pages = [],
  inSlider = true,
  renderSliderHeader = true,
  onSubmit = (
    payload,
    filledInitialFormValue,
    { generatedPayloadSkipsRemoved, reviewRows }
  ) => {}, // 'filledInitialFormValue' exposed here to make GenericForms composable. // this runs once
  onPageSubmit = (currentPageData, completeFormData) => {}, // for form with `n` pages, this runs `n-1` times
  isRunPageSubmitAtFinalSubmit = false, // if true, this will make onPageSubmit run `n` times for n page form. And then `onSubmit` will run.
  onChange = (e) => {}, //
  setIsFormDisabled = () => {},
  storeSetterReducer,
  storeClearReducer,
  storeMergeReducer,
  storeSelector,
  wantCleanUp,
  genericFormClasses,
  restrictClosingSliderAction = false,
  setOuterFooterDisableState = () => {},

  negativeLabel = "billPay.vendors.createVendor.discardChanges",
  positiveLabel = "billPay.vendors.createVendor.saveChanges",
  positiveLabelFinal = "billPay.vendors.createVendor.saveChanges",

  generateReviewRows = false,
  hideFields = false,
  hideEmptySections = false,
  autofillValueFromRegexIfLiteral = false,
  autofillDropdownIfSingleOption = false,
  autoFillSource = null,
  headingTextBase = false,

  editMode = false,
  noBottomMargin = false,
  inSliderClasses,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const formInStore = useSelector(storeSelector);

  // set current page
  let currentPageIdx = searchParams.getAll(globalSliderQueryParam).length - 1;
  if (currentPageIdx === -1) currentPageIdx = 0;

  const currentPage = pages?.[currentPageIdx];

  const [initialFormValue, setInitialFormValue] = useState(
    formValueFromSkeleton(pages?.[currentPageIdx], formInStore)
  ); // initial validation input save it in state, since useForm and UI (<form />) need to have same keys
  const [stepComplete, setStepComplete] = useState(false);
  // solution for: stale value due to JS closure vs hook (useSelector) cannot be called inside callback.
  // assumption - globalSlider remounts components, so 'stepComplete' state is reset for each step

  // CTAs
  // proceed - moved up, because needed by useForm
  // go back - handled by GlobalSlider
  const closeSlider = () => {
    searchParams.delete(globalSliderQueryParam);
    setSearchParams(searchParams);
  };

  const discardButtonHandler = () => {
    closeSlider();
    dispatch(storeClearReducer());
  };
  const proceed = () => {
    // merge current page's form data
    dispatch(
      storeMergeReducer(formValueFromExactValue(values, initialFormValue))
    );

    onPageSubmit(formValueFromExactValue(values, initialFormValue), {
      ...formInStore,
      ...formValueFromExactValue(values, initialFormValue),
    });

    if (!inSlider) {
      if (currentPageIdx === pages.length - 1) {
        // generate final payload
        closeSlider();
      } else {
        searchParams.append(
          globalSliderQueryParam,
          pages?.[currentPageIdx + 1][GENERIC_FORM_PAGE_KEY]
        );
        setSearchParams(searchParams);
      }
    }
    setStepComplete(true);
  };

  // GOTCHA: due to store issues, we signal submission using this
  // but actual onSubmit runs in a useEffect
  const innerOnSubmit = () => {
    // merge final page's form data
    dispatch(
      storeMergeReducer(formValueFromExactValue(values, initialFormValue))
    );

    if (isRunPageSubmitAtFinalSubmit) {
      onPageSubmit(formValueFromExactValue(values, initialFormValue), {
        ...formInStore,
        ...formValueFromExactValue(values, initialFormValue),
      });
    }

    setStepComplete(true);
  };

  // form validation hook
  const {
    handleChange,
    values,
    errors,
    isFormButtonDisabled,
    handleSubmit,
    _setValues: setValues,
  } = useForm(
    initialFormValue,
    currentPageIdx === pages.length - 1 ? innerOnSubmit : proceed,
    {
      isFetchingInitialValue: isFetching,
      formId,
    }
  );

  const innerOnChange = (e) => {
    onChange(e);
    handleChange(e);
  };

  useEffect(() => {
    setIsFormDisabled(isFormButtonDisabled);
    setOuterFooterDisableState(isFormButtonDisabled);
  }, [isFormButtonDisabled]);
  // latest store state after submit has been clicked, but UI is yet to be removed

  useEffect(() => {
    if (stepComplete) {
      if (currentPageIdx === pages.length - 1) {
        // generate final payload

        const generatedPayload = generatePayloadFromFormValue(formInStore);
        const generatedPayloadSkipsRemoved = generatePayloadFromFormValue(
          removeSkipFields(formInStore, pages)
        );
        const reviewRows = generateReviewRows
          ? getReviewRows(formInStore, pages)
          : null;

        onSubmit(generatedPayload, formInStore, {
          generatedPayloadSkipsRemoved,
          reviewRows,
        });

        if (!restrictClosingSliderAction) {
          closeSlider();
        }
        if (editMode) {
          dispatch(storeClearReducer());
        }
      } else {
        searchParams.append(
          globalSliderQueryParam,
          pages?.[currentPageIdx + 1][GENERIC_FORM_PAGE_KEY]
        );
        setSearchParams(searchParams);
      }
    }
  }, [stepComplete]);
  const GoToCorrectPage = () => {
    // PROTECTION - form opened directly in the middle (probably using manual URL). Go to the first page.
    if (currentPageIdx !== 0 && !formInStore) {
      searchParams.delete(globalSliderQueryParam);
      searchParams.set(
        globalSliderQueryParam,
        pages?.[0]?.[GENERIC_FORM_PAGE_KEY]
      );
      setSearchParams(searchParams);
      return;
    }

    // user of this component doesn't have to set 'step' (i.e. first page), the component will set it.
    // they can just set 'true' to trigger the global slider
    if (currentPageIdx === 0) {
      searchParams.set(
        globalSliderQueryParam,
        pages?.[0]?.[GENERIC_FORM_PAGE_KEY]
      );
      setSearchParams(searchParams);
    }

    // for "time-travel" form filling, i.e. user should be able to go back and forth between pages,
    // without losing filled data (of course, only if they pressed the page's save button)

    const formValueForCurrentPage = formValueFromSkeleton(
      pages?.[currentPageIdx],
      formInStore
    );
    setInitialFormValue(formValueForCurrentPage);

    // setting up the form data when the form is loading for the first time.
    if (!formInStore) {
      // slider opened first time, set up form object
      dispatch(storeSetterReducer(formValueForCurrentPage));
    } else {
      dispatch(storeMergeReducer(formValueForCurrentPage));
    }
  };
  // go to correct page
  useEffect(() => {
    if (!inSlider) GoToCorrectPage();
  }, [currentPage]);
  useEffect(() => {
    if (!inSlider) {
      const formValueForCurrentPage = formValueFromSkeleton(
        pages?.[currentPageIdx],
        formInStore
      );

      setInitialFormValue(formValueForCurrentPage);
    }
  }, [formInStore]);

  // slider left arrow click on first page
  useEffect(() => {
    if (currentPageIdx === 0) {
      setOnBack(onBackFromFirstPage);
    }

    return () => {
      if (currentPageIdx === 0) {
        setOnBack(null);
      }
    };
  }, []);

  // slider head
  const pageLabel = currentPage?.label;
  const pageDescription = currentPage?.description;
  const sliderHeaderData = useSelector(sliderHeaderDataSelector);
  const ref = useLeftHeaderTitle(
    renderSliderHeader ? { title: pageLabel } : sliderHeaderData
  ); // if in slider, essentially don't affect header title
  const cleanUp = (field, completeKey) => {
    if (wantCleanUp && field?.showWhen && values[completeKey])
      setValues((prev) => ({ ...prev, [completeKey]: "" }));
  };
  return (
    <>
      <div
        className={
          genericFormClasses
            ? genericFormClasses
            : "font-medium px-9 pb-9 slider-content-core"
        }
      >
        <form onSubmit={handleSubmit} id={formId}>
          <div className="font-medium">
            {/* Header */}
            <div className="flex flex-col">
              <div className="flex">
                {pageLabel ? (
                  <Text
                    {...(renderSliderHeader ? { refProp: ref } : {})}
                    translationKey={pageLabel}
                    classes="text-2xl font-bold text-neutral-800"
                  />
                ) : null}
              </div>
              {pageDescription ? (
                <Text
                  translationKey={pageDescription}
                  classes="text-sm text-neutral-500 font-medium"
                />
              ) : null}
            </div>
            {/* Page content - sections */}
            <div
              className={`flex flex-col gap-6 mt-8 ${
                inSlider &&
                (!noBottomMargin || !inSliderClasses?.includes("mb-"))
                  ? "mb-74"
                  : ""
              }  ${inSliderClasses}`}
            >
              {currentPage?.[SECTIONS_KEY_PLURAL]?.map((section) => (
                <GenericFormSection
                  sectionHeaderComponent={sectionHeaderComponent}
                  onChange={innerOnChange}
                  key={section[SECTION_KEY]}
                  section={section}
                  values={values}
                  errors={errors}
                  setInitialFormValue={setInitialFormValue}
                  setValues={setValues}
                  cleanUp={cleanUp}
                  hideFields={hideFields}
                  hideEmptySections={hideEmptySections}
                  autofillValueFromRegexIfLiteral={
                    autofillValueFromRegexIfLiteral
                  }
                  autofillDropdownIfSingleOption={
                    autofillDropdownIfSingleOption
                  }
                  autoFillSource={autoFillSource}
                  headingTextBase={headingTextBase}
                />
              ))}
            </div>
          </div>
        </form>
      </div>
      {showFooter ? (
        <div className="sticky slider-footer-right-buttons slider-footer">
          <Button
            label={negativeLabel}
            classes="px-5 py-3"
            labelStyleClasses="text-neutral-500 font-semibold"
            variant="tertiary"
            compact
            onClick={discardButtonHandler}
          />

          <Button
            label={
              currentPageIdx === pages.length - 1
                ? positiveLabelFinal
                : positiveLabel
            }
            classes="px-5 py-3"
            labelStyleClasses="text-white font-semibold"
            variant="primary"
            btnType="submit"
            compact
            form={formId}
            disabled={isFormButtonDisabled}
          />
        </div>
      ) : null}
    </>
  );
}

GenericForm.propTypes = {
  globalSliderQueryParam: PropTypes.string,
  onBackFromFirstPage: PropTypes.func,
  setOnBack: PropTypes.func,

  pages: PropTypes.arrayOf(stepPropType),
  isFetching: PropTypes.bool,
  showFooter: PropTypes.bool,
  formId: PropTypes.any,
  sectionHeaderComponent: PropTypes.any,
  onSubmit: PropTypes.func,
  onPageSubmit: PropTypes.func,
  onChange: PropTypes.func,
  setIsFormDisabled: PropTypes.func,
  inSlider: PropTypes.bool,
  storeSelector: PropTypes.any,
  storeSetterReducer: PropTypes.any,
  storeMergeReducer: PropTypes.any,
  storeClearReducer: PropTypes.any,
  wantCleanUp: PropTypes.bool,
  genericFormClasses: PropTypes.string,
  restrictClosingSliderAction: PropTypes.bool,
  negativeLabel: PropTypes.string,
  positiveLabel: PropTypes.string,
  positiveLabelFinal: PropTypes.string,
  generateReviewRows: PropTypes.bool,
  hideFields: PropTypes.bool,
  hideEmptySections: PropTypes.bool,
  setOuterFooterDisableState: PropTypes.func,
  autofillValueFromRegexIfLiteral: PropTypes.bool,
  autofillDropdownIfSingleOption: PropTypes.bool,
  autoFillSource: PropTypes.object,
  headingTextBase: PropTypes.bool,
  isRunPageSubmitAtFinalSubmit: PropTypes.bool,
  editMode: PropTypes.bool,
  renderSliderHeader: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  inSliderClasses: PropTypes.string,
};
