/* eslint-disable func-names */
import { MONTH_FULL_NAME, MONTH_NAME } from "@/utils/constants/date";

/**
 * Injects polyfills for the `Date` prototype to extend its functionality.
 */
export default function injectPolyfills() {
  /* eslint no-extend-native: ["error", { "exceptions": ["Date"] }] */

  /**
   * Modifies the date by subtracting a specified number of years, months, or days.
   * @param {number} ago - The number of units to subtract.
   * @param {('year'|'month'|'date')} by - The unit of time to subtract.
   * @returns {Date} The modified date.
   */
  Date.prototype.ago = function (ago, by) {
    const current = new Date();
    if (by === "year") {
      this.setFullYear(current.getFullYear() - ago);
    }

    if (by === "month") {
      this.setMonth(current.getMonth() - ago);
    }

    if (by === "date") {
      this.setDate(current.getDate() - ago);
    }

    return this;
  };

  /**
   * Formats the date as `dd-mm-yyyy`.
   * @returns {string} The formatted date string.
   */
  Date.prototype.ddmmyyyy = function () {
    const date = new Date(this);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to month and ensure it's two digits
    const day = date.getDate().toString().padStart(2, "0"); // Ensure day is two digits
    return `${day}-${month}-${year}`;
  };

  /**
   * Formats the date as `dd mmm` (e.g., `25 Dec`).
   * @returns {string} The formatted date string.
   */
  Date.prototype.ddmmm = function () {
    const date = this.toDateString().split(" ");
    return [date[2], date[1]].join().replace(",", " ");
  };

  /**
   * Returns the abbreviated month name (e.g., `Jan`).
   * @returns {string} The abbreviated month name.
   */
  Date.prototype.mmm = function () {
    const date = this.toDateString().split(" ");
    return date[1];
  };

  /**
   * Returns the full name of the current month.
   * @returns {string} The full name of the current month.
   */
  Date.prototype.currentMonth = () => {
    const today = new Date();
    return MONTH_FULL_NAME[today.getMonth()];
  };
  /**
   * Returns the full name of the month.
   * @returns {string} The full name of the current month.
   */
  Date.prototype.getMonthFullName = () => {
    const today = this;
    return MONTH_FULL_NAME[today.getMonth()];
  };

  /**
   * Returns the short name of the month.
   * @returns {string} The full name of the current month.
   */
  Date.prototype.getMonthShortName = () => {
    const today = this;
    return MONTH_NAME[today.getMonth()];
  };

  /**
   * Returns all the days in the month of the current date, formatted according to the provided options.
   * @param {Object} [format={ day: "numeric", month: "short" }] - The options for formatting the date.
   * @returns {string[]} An array of formatted date strings representing each day of the month.
   */
  Date.prototype.getAllDaysInMonth = function (
    format = { day: "numeric", month: "short" }
  ) {
    const date = new Date(this.toDateString());
    date.setDate(1);
    const month = date.getMonth();
    const days = [];

    while (date.getMonth() === month) {
      days.push(date.toLocaleDateString("en-GB", format));
      date.setDate(date.getDate() + 1);
    }

    return days;
  };

  /**
   * Calculates the difference in months between two dates.
   * @param {Date} d1 - The start date.
   * @param {Date} d2 - The end date.
   * @returns {number} The difference in months.
   */
  Date.prototype.monthDiff = function (d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  /**
   * Converts the date to an ISO string in the local timezone.
   * @returns {string} The ISO formatted date string with local timezone.
   */
  Date.prototype.toLocaleISOString = function toIsoString() {
    const date = this;
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? "+" : "-";
    const pad = function (num) {
      return (num < 10 ? "0" : "") + num;
    };

    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`;
  };
  Date.prototype.isBefore = function (minDate) {
    if (!(minDate instanceof Date)) {
      console.warn("minDate must be an instance of Date");
      return false;
    }
    const _this = new Date(this);
    _this.setHours(0, 0, 0, 0);
    const _minDate = new Date(minDate);
    _minDate.setHours(0, 0, 0, 0);
    return minDate && _this <= _minDate;
  };

  Date.prototype.isAfter = function (maxDate) {
    if (!(maxDate instanceof Date)) {
      console.warn("maxDate must be an instance of Date");
      return false;
    }
    const _this = new Date(this);
    _this.setHours(0, 0, 0, 0);
    const _maxDate = new Date(maxDate);
    _maxDate.setHours(0, 0, 0, 0);
    return maxDate && _this >= _maxDate;
  };
  Date.prototype.isValid = function () {
    return this instanceof Date;
  };
}
