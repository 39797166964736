import { createSelector } from "@reduxjs/toolkit";

export const selectOnboardingStore = (store) => store.onboarding;

export const stepsSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.steps
);

export const currentOnboardingStepSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.currentOnboardingStep
);
export const currentOnboardingSectionSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.currentOnboardingSection
);

export const formDataSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.formData
);
export const onboardingSectionSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.onboardingSection
);
export const selectedStepSelector = createSelector(
  stepsSelector,
  currentOnboardingStepSelector,
  (steps, selectedStep) =>
    steps?.find(
      (item) =>
        item.step === selectedStep ||
        item?.sections?.find((section) => section?.key === selectedStep)
    )
);
export const selectedSectionSelector = createSelector(
  selectedStepSelector,
  (steps) => steps?.sections
);

export const selectedStepsWithSectionSelector = createSelector(
  selectedSectionSelector,
  onboardingSectionSelector,
  (selectedSteps, selectedSectionData) =>
    selectedSteps?.map((item) =>
      item.step === selectedSectionData?.key ? selectedSectionData : item
    )
);

export const isFetchingOnboardingDataSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.isFetching
);

export const isFetchingOtpSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.isFetchingOtp
);

export const otpSentCountSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.otpSentCount
);

export const onboardingModalBoolSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.onboardingModal
);

export const confirmReviewDetailsModalBoolSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.confirmReviewModal
);

export const isOtpVerifiedSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.isOtpVerified
);

export const vKycLinkInprogressSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.vkycInfo.vkycLinkInProgress
);

export const vKycLinkSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.vkycInfo.link
);

export const vkycStateSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding.vkycState
);

export const isFetchingOnboardingStepsSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding?.isFetchingOnboardingSteps
);

export const isFetchingOnboardingSectionSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding?.isFetchingOnboardingSection
);

export const isVkycVerifiedSelector = createSelector(
  selectOnboardingStore,
  (onboarding) => onboarding?.vkycVerified
);
