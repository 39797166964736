import React from "react";

import Text from "@/components/core/Text";

export default function RequestStatementSliderHeader() {
  return (
    <div className="flex flex-col">
      <Text
        classes="text-3xl font-bold"
        translationKey="accounting.ledger.requestStatementSlider.header"
      />
      <Text
        classes="text-sm text-neutral-500 mt-1"
        translationKey="accounting.ledger.requestStatementSlider.description"
      />
    </div>
  );
}
