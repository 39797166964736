import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchAnalyticsTags,
  setAnalyticsExportData,
} from "@/store/reducers/analytics";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  analyticsEntitesSelector,
  analyticsTagsSelector,
} from "@/store/selectors/analytics";
import { defaultCurrencySelector } from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";

import Badge from "@/components/core/Badge";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import TableLoader from "@/components/Accounting/Transactions/TransactionsTable/TableLoader";
import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import Owner from "@/components/Accounting/Transactions/common/Tab/Owner";
import { truncateString } from "@/components/Accounting/Transactions/common/util";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import { CONST_TYPE_REIMBURSEMENT } from "@/utils/constants/reimbursement";
import {
  LEDGER_PAGE_TYPE,
  LEDGER_TABLE_HEADERS,
} from "@/utils/constants/ledger";
import {
  amountToCurrency,
  dateToString,
  dateToTimeStampString,
  groupByDate,
  subdomain,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_HEADER_IDS,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
  TRANSACTION_STATUS_CTA_MAP,
} from "@/constants/accounting";

function AnalyticsTableHelper({
  handleRefChange = () => {},
  transactions = [],
  headers = [],
  selectedTransactions = [],
  setSelectedTransactions = () => {},
  tableArgs = {},
  totalTransactions = null,
  isFetching = false,
  sorting = {},
  setSorting = () => {},
  isGrouped = true,
  dateToBeGrouped = "transactionDate",
  page = 1,
  hasMore = false,
  type,
  tab,
}) {
  const dispatch = useDispatch();

  const entities = useSelector(analyticsEntitesSelector);
  const vendors = useSelector(accountingVendorsSelector);
  const tags = useSelector(analyticsTagsSelector);

  const [selectedEntity, setSelectedEntity] = useState(
    entities?.[0]?.subdomain
  );

  const appliedFilters = useSelector(appliedFilterSelector);

  useEffect(() => {
    dispatch(
      fetchAnalyticsTags({
        subdomain: appliedFilters?.entity?.label ?? subdomain(),
      })
    );
  }, [appliedFilters?.entity]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (appliedFilters?.entity) {
      setSelectedEntity(appliedFilters?.entity?.label);
    } else {
      setSelectedEntity(subdomain());
    }
  }, [appliedFilters]);

  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);
  const headerSelected = headerSelectAll; // all selected or else unchecked

  const isEmpty = transactions?.length === 0;
  const isOthersPage = type === ACCOUNTING_TRANSACTION_PAGES.OTHERS;

  const groupedTransaction = groupByDate(transactions, dateToBeGrouped);

  const defaultCurrency = useSelector(defaultCurrencySelector);

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedTransactions(
          transactions.map((transaction) => transaction.accountingId)
        );
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedTransactions([]);
      setBulkSelectAll(false);
    }
  };

  const rowSelectionHandler = (transaction) => {
    if (selectedTransactions?.includes(transaction?.accountingId)) {
      setSelectedTransactions((prev) =>
        prev.filter((id) => id !== transaction?.accountingId)
      );
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, transaction?.id]);
      }
    } else {
      setSelectedTransactions((prev) => [...prev, transaction?.accountingId]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== transaction?.id));
    }
  };

  const SplitAppliedTextIcon = () => {
    return (
      <div className="flex items-center gap-2 ">
        <Icon name="CallSplit" className="w-6 h-6 text-neutral-500" />
        <Text
          translationKey="accounting.transactions.splitApplied"
          classes="text-sm font-semibold text-neutral-800"
        />
      </div>
    );
  };

  const getComponent = (id, _data, index, tagId, transaction) => {
    const isSplit = !!transaction?.lineItems?.length;
    switch (id) {
      case ACCOUNTING_HEADER_IDS.VENDOR:
      case ACCOUNTING_HEADER_IDS.MERCHANT:
        return (
          <div className="flex items-center justify-start gap-3">
            <Checkbox
              onClickHandler={() => rowSelectionHandler(transaction)}
              checked={selectedTransactions?.includes(transaction.accountingId)}
            />
            <div className="w-full">
              <ProfileWidget
                textClasses="font-semibold text-neutral-800"
                name={_data?.name}
                img={_data?.avatarUrl}
              >
                {(_data?.projectName ?? _data?.transactionDate) ? (
                  <Text
                    classes="text-xs text-neutral-500 font-medium"
                    translationKey={
                      _data?.projectName ?? dateToString(_data?.transactionDate)
                    }
                  />
                ) : null}
                {_data?.employeeId ? (
                  <Text
                    classes="text-xs text-neutral-500 font-medium"
                    translationKey="payroll.salaryPayment.payrollInbox.employeeId"
                    translationProps={{ employeeId: _data?.employeeId }}
                  />
                ) : null}
              </ProfileWidget>
            </div>
          </div>
        );
      case ACCOUNTING_HEADER_IDS.MERCHANT_OR_MILEAGE: {
        const isOutOfPocket =
          transaction?.type === CONST_TYPE_REIMBURSEMENT.outOfPocket;
        const profileName =
          transaction?.type === CONST_TYPE_REIMBURSEMENT.mileage
            ? "reimbursement.createReimbursement.kms"
            : transaction?.merchant?.name;
        const profileNameTranslationProps =
          transaction?.type === CONST_TYPE_REIMBURSEMENT.mileage
            ? { kms: transaction?.distanceTravelled }
            : {};

        const iconComponent =
          transaction?.type === CONST_TYPE_REIMBURSEMENT.mileage ? (
            <Icon
              name="Location"
              className="flex p-2 text-sm border rounded-full w-9 h-9 bg-warning-50 text-success-500 border-success-100"
            />
          ) : null;

        return (
          <div className="flex items-center gap-3 ">
            <Checkbox
              onClickHandler={() => rowSelectionHandler(transaction)}
              checked={selectedTransactions?.includes(transaction.accountingId)}
            />
            <div className="w-full">
              <ProfileWidget
                name={profileName}
                nameTranslationProps={profileNameTranslationProps}
                img={_data?.avatarUrl}
                iconComponent={iconComponent}
                textClasses="font-semibold  text-neutral-800"
              >
                {(_data?.category ?? _data?.transactionDate) ? (
                  <Text
                    classes="text-xs text-neutral-500 font-medium"
                    translationKey={
                      _data?.category ?? dateToString(_data?.transactionDate)
                    }
                  />
                ) : null}
                {_data?.employeeId && isOutOfPocket ? (
                  <Text
                    classes="text-xs text-neutral-500 font-medium"
                    translationKey="payroll.salaryPayment.payrollInbox.employeeId"
                    translationProps={{ employeeId: _data?.employeeId }}
                  />
                ) : null}
              </ProfileWidget>
            </div>
            {_data?.status === ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED ? (
              <Icon
                name="SyncFailed"
                className="p-1 border rounded-lg text-danger-500 bg-danger-50 border-danger-100"
              />
            ) : null}
          </div>
        );
      }

      case ACCOUNTING_HEADER_IDS.ACCOUNTING_PAYEE_ALIAS: {
        return (
          <Text
            id={transaction?.id}
            translationKey={_data ? truncateString(_data, 23) : "-"}
            showTooltip
            toolTipText={_data}
          />
        );
      }
      case ACCOUNTING_HEADER_IDS.AMOUNT:
        return (
          <Text
            classes={`${
              isOthersPage
                ? _data?.amount > 0
                  ? "text-success-600"
                  : "text-neutral-800"
                : ""
            } font-semibold`}
            translationKey={`${amountToCurrency(
              isOthersPage
                ? _data?.amount > 0
                  ? _data?.amount
                  : -(_data?.amount ?? 0)
                : _data?.amount,
              _data?.currency
            )}`}
          />
        );

      case ACCOUNTING_HEADER_IDS.CARD_OWNER:
      case ACCOUNTING_HEADER_IDS.VENDOR_OWNER:
      case ACCOUNTING_HEADER_IDS.OWNER:
      case ACCOUNTING_HEADER_IDS.CREATED_BY:
        return (
          <Owner
            name={_data?.displayName}
            department={_data?.departmentName}
            location={_data?.locationName}
          />
        );

      case ACCOUNTING_HEADER_IDS.ENTITY:
        return (
          <div className="">
            <Text translationKey={selectedEntity} />
          </div>
        );

      case ACCOUNTING_HEADER_IDS.TRANSACTION_TYPE:
        return (
          <div className="flex items-center justify-start gap-3">
            <Checkbox
              onClickHandler={() => rowSelectionHandler(transaction)}
              checked={selectedTransactions?.includes(transaction.accountingId)}
            />
            <Text translationKey={_data} />
          </div>
        );

      case ACCOUNTING_HEADER_IDS.TRANSACTION_STATUS:
        return (
          <div className="flex items-center justify-center">
            <Badge
              variant={TRANSACTION_STATUS_CTA_MAP[_data]?.color}
              translationKey={TRANSACTION_STATUS_CTA_MAP[_data]?.text}
              classes="w-[calc(theme(spacing.3)*9)]"
            />
          </div>
        );
      case ACCOUNTING_HEADER_IDS.STATUS:
        return (
          <div className="flex items-center justify-center">
            <Badge
              variant={TRANSACTION_STATUS_CTA_MAP[_data]?.color}
              translationKey={TRANSACTION_STATUS_CTA_MAP[_data]?.text}
              classes="w-[calc(theme(spacing.3)*9)]"
            />
          </div>
        );

      case ACCOUNTING_HEADER_IDS.CUSTOM_TAGS: {
        const currentCustomTag = headers.find(
          (header) => header.tagId === tagId
        );
        const selectedCustomTag = transaction?.customTags?.find(
          (option) => option.tag === currentCustomTag.label
        );

        const tagValue = selectedCustomTag?.tagValue;

        if (isSplit) return <SplitAppliedTextIcon />;

        return <Text id={index} translationKey={tagValue ?? "-"} />;
      }
      case ACCOUNTING_HEADER_IDS.ACCOUNTING_TAGS:
      case ACCOUNTING_HEADER_IDS.NON_ACCOUNTING_TAG: {
        const currentAccountingTag = headers.find(
          (header) => header.tagId === tagId
        );
        const seletedAccountingTag = transaction?.accountingTags?.find(
          (option) => option.tag === currentAccountingTag.label
        );

        const tagValue = seletedAccountingTag?.tagValue;

        if (isSplit) return <SplitAppliedTextIcon />;
        return <Text translationKey={tagValue ?? "-"} />;
      }

      case LEDGER_TABLE_HEADERS.createdAt:
        return (
          <Text
            classes="font-medium"
            translationKey={dateToTimeStampString(_data)}
          />
        );
      case LEDGER_TABLE_HEADERS.ledgerAmount:
      case LEDGER_TABLE_HEADERS.currentOutstanding:
        return (
          <Text
            classes="font-medium"
            translationKey={amountToCurrency(
              _data,
              tab?.name?.toLowerCase() === LEDGER_PAGE_TYPE.credit
                ? defaultCurrency
                : tab?.name
            )}
          />
        );
      case LEDGER_TABLE_HEADERS.description:
        return (
          <Text
            classes="font-medium w-full text-neutral-800"
            showTooltip
            translationKey={_data}
          />
        );
      case LEDGER_TABLE_HEADERS.ledgerTypeCamelCase:
        return (
          <Text
            classes="font-medium"
            translationKey={`accounting.ledger.ledgerType.${_data}`}
          />
        );

      case LEDGER_TABLE_HEADERS.type:
        return <Text classes="font-medium" translationKey={_data} />;

      default:
        return "-";
    }
  };

  const getTransactionRow = (transaction, index, _transactions, lastIndex) => {
    return (
      <tr
        className="text-sm font-semibold text-center border-y-2 border-neutral-100"
        key={page + index}
        id={page + index}
        ref={(ref) => {
          if (lastIndex && _transactions.length - 1 === index && hasMore) {
            handleRefChange(ref);
          }
        }}
      >
        {headers?.map((header, idx) => (
          <td
            className={`${header?.classes} ${
              selectedTransactions?.includes(transaction?.accountingId)
                ? "selected-row-cell"
                : ""
            }`}
            key={header?.id ?? idx}
          >
            {getComponent(
              header?.id,
              transaction[header?.id],
              index,
              header?.tagId,
              transaction
            )}
          </td>
        ))}
      </tr>
    );
  };

  return (
    <Table
      {...tableArgs}
      bulkApproveVisible={selectedTransactions?.length > 0}
      bulkApproveHeight="100px"
      bulkApproveContent={
        <BulkActionComponent
          selectedRows={
            bulkSelectAll
              ? deSelectedIds.length
                ? totalTransactions - deSelectedIds.length
                : totalTransactions
              : selectedTransactions?.length
          }
          totalRows={totalTransactions}
          descriptionText="common.exportDesc"
          bulkApproveSelectAll={
            (bulkSelectAll && !deSelectedIds.length) ||
            selectedTransactions?.length === totalTransactions
          }
          bulkSelectAll={bulkSelectAll}
          showSelectAllButton={headerSelectAll}
          selectAllHandler={(val) => {
            setDeSelectedIds([]);
            setBulkSelectAll(val);
          }}
          handleExport={() => {
            dispatch(
              setAnalyticsExportData({
                transactionType: type,
                selectedTransactions,
                deSelectedIds,
                bulkSelectAll,
              })
            );

            searchParams.append(SLIDERS_SEARCH_PARAMS.analytics.export, true);
            setSearchParams(searchParams);
          }}
          showExport
          clearSelection={() => {
            setBulkSelectAll(false);
            setHeaderSelectAll(false);
            setSelectedTransactions([]);
            setDeSelectedIds([]);
          }}
        />
      }
    >
      <tr className="text-xs ">
        {headers?.map((header, index) => {
          return (
            <th className={`${header?.classes} font-medium`} key={header?.id}>
              <div className={`flex items-center w-full ${header?.classes}`}>
                <HeaderCell
                  val={{
                    ...header,
                    onCheckboxClick: headerSelectionHandler,
                    checkedValue: headerSelected && !isEmpty,
                    showCheckbox:
                      type !== ACCOUNTING_TRANSACTION_PAGES.LEDGER &&
                      index === 0 &&
                      !isFetching,
                    disabled: isEmpty || isFetching,
                    sorting,
                    haveSort: header?.sortable ?? false,
                    title: header?.label,
                    setSorting,
                  }}
                />
              </div>
            </th>
          );
        })}
      </tr>

      {isGrouped
        ? !isFetching || groupedTransaction.length !== 0
          ? Object.entries(groupedTransaction)?.map(
              ([key, _transactions], idx) => {
                const arrayChildren = [
                  <tr className="h-9" key="date-row">
                    <td className="text-xs font-semibold text-neutral-500">
                      {dateToString(key)}
                    </td>
                    {headers
                      ?.filter((_, i) => i !== 0)
                      ?.map((transaction, index) => (
                        <td key={`${transaction?.id}-${index}`}>
                          <div aria-label="render-empty-space" />
                        </td>
                      ))}
                  </tr>,

                  ..._transactions.map((transaction, i) => {
                    return getTransactionRow(
                      transaction,
                      i,
                      _transactions,
                      Object.entries(groupedTransaction).length - 1 === idx
                    );
                  }),
                ];

                return arrayChildren;
              }
            )
          : null
        : !isFetching || transactions?.length !== 0
          ? transactions.map((transaction, index) => {
              return getTransactionRow(transaction, index, transactions, true);
            })
          : null}

      {isFetching ? <TableLoader columns={headers} /> : null}
    </Table>
  );
}

export default AnalyticsTableHelper;

AnalyticsTableHelper.propTypes = {
  handleRefChange: PropTypes.func,
  headers: PropTypes.array,
  transactions: PropTypes.array,
  selectedTransactions: PropTypes.array,
  setSelectedTransactions: PropTypes.func,
  tableArgs: PropTypes.object,
  totalTransactions: PropTypes.number,
  isFetching: PropTypes.bool,
  sorting: PropTypes.object,
  setSorting: PropTypes.func,
  isGrouped: PropTypes.bool,
  dateToBeGrouped: PropTypes.string,
  page: PropTypes.number,
  hasMore: PropTypes.bool,
  type: PropTypes.string,
  tab: PropTypes.string,
};
