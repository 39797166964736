import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Progress from "@/components/core/Progress";
import Text from "@/components/core/Text";
import { amountToCurrency } from "@/utils/common";

export default function HeaderSpendCreditOverview({
  spentAmount,
  spentCurrency,
  creditAmount,
  creditCurrency,
}) {
  return (
    <div className="flex justify-between">
      <div>
        <div className="flex items-center">
          <Text
            classes="text-neutral-800 text-sm text-normal"
            translationKey="expenses.overview.totalSpent.label"
          />
          <Icon name="Info" className="ml-2 w-4 h-4 text-neutral-300" />
        </div>

        <span className="text-xl font-bold text-neutral-800">
          {amountToCurrency(spentAmount, spentCurrency)}
        </span>
      </div>
      <div>
        <div className="flex items-center">
          <Text
            classes="text-neutral-800 text-sm text-normal"
            translationKey="expenses.overview.monthlyCreditLimit.label"
          />
          <Icon name="Info" className="ml-2 w-4 h-4 text-neutral-300" />
        </div>
        <span className="text-xl font-bold text-neutral-800">
          {amountToCurrency(creditAmount, creditCurrency)}
        </span>
      </div>
    </div>
  );
}
HeaderSpendCreditOverview.propTypes = {
  spentAmount: PropTypes.number,
  spentCurrency: PropTypes.string,
  creditAmount: PropTypes.number,
  creditCurrency: PropTypes.string,
};
