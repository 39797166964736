import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { deleteAttachment, downloadAttachment } from "@/store/reducers/app";
import { getMerchantShallow } from "@/store/reducers/cards";
import { fetchCategories } from "@/store/reducers/categories";
import { fetchCurrencies } from "@/store/reducers/client";
import { fetchShallowProjects } from "@/store/reducers/company";
import {
  fetchMileageRateList,
  setCreateClaimFormDetails,
} from "@/store/reducers/reimbursement";
import { fetchVendors } from "@/store/reducers/vendors";

import { shallowMerchantListSelector } from "@/store/selectors/cards";
import {
  categoriesListSelector,
  isFetchingCategoriesSelector,
} from "@/store/selectors/categories";
import {
  claimAmountEditEnabledSelector,
  currenciesSelector,
  defaultCurrencySelector,
  reimbursementReportsEnabledSelector,
  reimbursementTransactionAllowedDateRangeSelector,
} from "@/store/selectors/client";
import {
  createClaimFormDetailsSelector,
  editAmountSelector,
  mileageRateListSelector,
  selectedReimbursementSelector,
} from "@/store/selectors/reimbursement";
import { isLoadingSelector, userSelector } from "@/store/selectors/user";
import { isFetchingVendorsSelector } from "@/store/selectors/vendors";

import Alert from "@/components/core/Alert";
import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Memo from "@/components/core/Memo";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";

import { NewVendorChip } from "@/components/Accounting/Transactions/common/util";
import DistanceToAmount from "@/components/Reimbursement/CreateReimbursement/DistanceToAmount";
import MileageMap from "@/components/Reimbursement/CreateReimbursement/MileageMap";
import AmountToBePaid from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/AmountToBePaid";
import LinkTo from "@/components/common/BillPayAndPayroll/VendorOrEmployee/CreateVendor/CreateVendorInit/LinkVendorTo";
import TagInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/TagInput";
import DateInputTextBox from "@/components/common/DateInputTextBox";
import { CREATE_REIMBURSEMENT_TYPE } from "@/utils/constants/reimbursement";
import {
  amountToCurrency,
  commaFormattedNumber,
  dateToString,
  downloadFileFromBlob,
  getRange,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import { DANGER, VENDOR_CLASS, WARNING } from "@/constants/common";
import { DEFAULT_ACCEPT_WITH_PDF } from "@/constants/fileViewer";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  FLEXIBLE,
  NON_FLEXIBLE,
  REIMBURSEMENT_CONTEXT,
} from "@/constants/reimbursement";
import { VENDOR_LINKED_TO_TYPES } from "@/constants/vendors";

export default function OutOfThePocketForm({
  handleChange,
  values,
  errors,
  setValues,
  reimbursementType,
  claimCategoryLimitDetails,
  accountingSoftware,
  accountingEnabled,
  customTags,
  accountingCategory,
  nonAccountingCategory,
  isThisReimbursementIsDuplicate,
  isReceiptError,
  isMemoError,
  isApproverEdit,
  saveCompleteForm,
  editMode,
}) {
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [isNewMerchant, setIsNewMerchant] = useState(false);
  const merchants = useSelector(shallowMerchantListSelector); // merchant control options
  const isFetchingMerchants = useSelector(isFetchingVendorsSelector);
  const categories = useSelector(categoriesListSelector); // category control options
  const isFetchingCategories = useSelector(isFetchingCategoriesSelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const mileageRateList = useSelector(mileageRateListSelector);
  const currencyOptions = useSelector(currenciesSelector);
  const currentClaimFormDetails = useSelector(createClaimFormDetailsSelector);
  const selectedReimbursement = useSelector(selectedReimbursementSelector);
  const claimAmountEditEnabled = useSelector(claimAmountEditEnabledSelector);
  const reimbursementTransactionAllowedDateRange = useSelector(
    reimbursementTransactionAllowedDateRangeSelector
  );
  const transactionDateProps = useMemo(() => {
    const dateInputExtraProp = { maxDate: new Date() };
    if (reimbursementTransactionAllowedDateRange) {
      const { from, to } = getRange(
        reimbursementTransactionAllowedDateRange,
        []
      );
      if (from) {
        const [day, month, year] = from.split("-");
        const date = new Date();
        date.setFullYear(year, month - 1, day);
        dateInputExtraProp.minDate = date;
      }
    }
    return dateInputExtraProp;
  }, [reimbursementTransactionAllowedDateRange]);
  const editAmount = useSelector(editAmountSelector);

  const currentUser = useSelector(userSelector);
  const countryName = values?.travelCountry;
  const isLoading = useSelector(isLoadingSelector);
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  const [searchParam, setSearchParam] = useSearchParams();

  const [merchantList, setMerchantList] = useState([]);

  const categoryLimitType = claimCategoryLimitDetails?.type;
  const catetogryLimitExceeded =
    claimCategoryLimitDetails?.categoryLimitExceeded;

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchCurrencies({ type: REIMBURSEMENT_CONTEXT }));
  }, []);
  const helperText = () => {
    switch (categoryLimitType) {
      case FLEXIBLE:
        return t(
          `reimbursement.createReimbursement.${
            reportEnabled
              ? "reportFlexibleLimitExceeded"
              : "flexibleLimitExceeded"
          }`,
          {
            amount: amountToCurrency({
              value: claimCategoryLimitDetails?.categoryLimit,
              currency: defaultCurrency,
            }),
          }
        );
      case NON_FLEXIBLE:
        return t(
          `reimbursement.createReimbursement.${
            reportEnabled
              ? "reportNonFlexibleLimitExceeded"
              : "nonFlexibleLimitExceeded"
          }`,
          {
            amount: amountToCurrency({
              value: claimCategoryLimitDetails?.categoryLimit,
              currency: defaultCurrency,
            }),
          }
        );
      default:
        return null;
    }
  };

  const mileage = mileageRateList?.find(
    (data) => data.countryName === countryName
  );
  const currency = mileage?.currency || defaultCurrency;

  const mileageRate = mileage?.rate;
  const kms = values?.totalMiles ? values?.totalMiles : 0;

  const distanceToAmountCalc =
    kms === 0 ? 0 : mileageRate ? (mileageRate * kms).toFixed(2) : 0;

  const dispatch = useDispatch();

  useEffect(() => {
    if (reimbursementType === CREATE_REIMBURSEMENT_TYPE.mileage.value) {
      setValues({
        ...values,
        transactionAmount: distanceToAmountCalc,
        transactionCurrency: defaultCurrency,
      });
    }
  }, [distanceToAmountCalc]);

  useEffect(() => {
    if (!currentClaimFormDetails) return;

    const merchantIdObj = merchants?.find(
      (item) => item.name === values?.merchant
    );

    if (!merchantIdObj) {
      setIsNewMerchant(true);
      const _newOption = {
        label: values?.merchant,
        name: values?.merchant,
        value: values?.merchant,
      };

      setMerchantList((prev) => [...prev, _newOption]);
    } else {
      setIsNewMerchant(false);
    }
  }, [values?.merchant, merchantList, !!currentClaimFormDetails]);

  useEffect(() => {
    setMerchantList(merchants?.map((item) => ({ ...item, label: item.name })));
  }, [merchants]);
  useEffect(() => {
    dispatch(
      fetchVendors({
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        vendor_class: VENDOR_CLASS.EMPLOYEE,
      })
    );
    dispatch(
      fetchCategories({
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        noRepeat: true,
      })
    );
    dispatch(fetchMileageRateList());
    dispatch(getMerchantShallow({ shallow: true }));
  }, []);

  useEffect(() => {
    setValues({
      ...values,
      receipts: filesUploaded,
    });
  }, [filesUploaded]);

  const handleCreateMerchant = (val) => {
    const _newOption = {
      label: val,
      name: val,
      value: val,
    };
    setIsNewMerchant(true);
    setValues((prev) => ({
      ...prev,
      ...{ merchant: _newOption.name },
    }));
    setMerchantList((prev) => [...prev, _newOption]);
  };

  const primaryAction = {
    handler: (index) => {
      const file = values?.receipts?.[index];
      const onSuccess = () => {
        setValues((prev) => ({
          ...prev,
          ...{
            receipts:
              prev?.receipts?.filter((item, idx) => index !== idx) ?? [],
          },
        }));
      };
      if (file instanceof File) {
        onSuccess();
      } else {
        dispatch(deleteAttachment({ id: file?.id, onSuccess }));
      }
    },
    label: "misc.delete",
    icon: "Delete",
    iconClasses: "text-danger-600",
    wrapperClasses: "!bg-danger-50 p-1 rounded-xl",
  };

  const secondaryAction = {
    handler: async (index) => {
      const file = values?.receipts?.[index];
      if (!(file instanceof File)) {
        dispatch(downloadAttachment({ file, id: file?.id }));
      } else downloadFileFromBlob(file?.preview, file?.name);
    },
    label: "misc.download",
    icon: "Download",
    iconClasses: "!text-primary-500", // some issue with SVG, using !
    wrapperClasses: "!bg-primary-50 p-1 rounded-xl",
  };

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(
        fetchShallowProjects({
          project_member: [currentUser?.id],
        })
      );
    }
  }, [currentUser?.id]);

  const isAmountEdited =
    +(selectedReimbursement?.amount?.value ?? 0) !==
    +(selectedReimbursement?.amountToBePaid ?? 0);
  const isShowAmountToBePaid =
    isApproverEdit || (editMode && claimAmountEditEnabled);

  const approverEditUI = (
    <>
      <AmountToBePaid
        amount={editAmount || selectedReimbursement?.amountToBePaid}
        currency={defaultCurrency}
        titleText="reimbursement.approverEdit.amountToBePaid"
        values={values}
        errors={errors}
        handleChange={handleChange}
        setValues={setValues}
        blueBorder={false}
        midUI={
          isApproverEdit ? (
            <>
              <Button
                variant="tertiary"
                id="approver-edit-icon-tooltip"
                label={null}
                preIcon="Edit"
                iconClasses="text-neutral-500"
                classes="mt-6 ml-auto mr-16 w-6 h-6 !bg-neutral-100 hover:!bg-neutral-300 border-none"
                // disabled={isFetchingQuote || isEditableDisabled}
                onClick={() => {
                  saveCompleteForm();
                  searchParam.append(
                    SLIDERS_SEARCH_PARAMS.reimbursements.editClaimAmount,
                    selectedReimbursement?.id
                  );
                  setSearchParam(searchParam);
                }}
              />
              <Tooltip id="approver-edit-icon-tooltip" direction="bottom">
                <Text
                  translationKey="reimbursement.approverEdit.editAmountTitle"
                  classes="text-sm font-medium text-neutral-800"
                />
              </Tooltip>
            </>
          ) : null
        }
        rightOfTooltipUI={
          isAmountEdited ? (
            <Badge
              translationKey="reimbursement.approverEdit.amountEditedChipLabel"
              variant="neutral"
              muted={false}
              classes="py-0.5 px-3 bg-neutral-50"
              textClasses="font-bold text-xs text-neutral-600"
              id="amount-edited-tooltip"
            />
          ) : null
        }
      />
      {isAmountEdited ? (
        <Text
          classes="mt-2 text-neutral-500 text-xs font-medium inline-block"
          translationKey="reimbursement.approverEdit.originalAmountToBePaid"
          translationProps={{
            amountAndCurrency: amountToCurrency(selectedReimbursement?.amount),
          }}
        />
      ) : null}
    </>
  );

  return (
    <div className="flex flex-col gap-8 mt-4 mb-12">
      {reimbursementType === CREATE_REIMBURSEMENT_TYPE.mileage.value ? (
        <div className="flex flex-col gap-10">
          <MileageMap
            values={values}
            handleChange={handleChange}
            errors={errors}
            setValues={setValues}
            disabled={isApproverEdit}
          />
          <DistanceToAmount
            leftValue={{
              translationKey: "distanceUnit",
              translationProps: { kms: commaFormattedNumber(kms) },
              distance: kms,
            }}
            rightValue={{
              translationKey: "reimbursement.createReimbursement.amountValue",
              translationProps: {
                amount: amountToCurrency(distanceToAmountCalc, currency),
              },
              amount: distanceToAmountCalc,
              currency,
            }}
            leftText="reimbursement.createReimbursement.distanceTravelled"
            rightText="reimbursement.createReimbursement.amountHeading"
            description={
              mileageRate
                ? {
                    translationKey:
                      "reimbursement.createReimbursement.mileageDesc",
                    translationProps: {
                      mileageRate,
                      currency,
                    },
                  }
                : null
            }
          />

          {/* Show if approver edit is possible or if editing has been done */}
          {isShowAmountToBePaid ? (
            <div className="my-2">{approverEditUI}</div>
          ) : null}

          <div className="">
            <DateInputTextBox
              name="travelDate"
              value={dateToString(values?.travelDate, { year: "numeric" })}
              error={errors.travelDate}
              dateInputExtraProp={{ maxDate: new Date() }}
              handleChange={handleChange}
              setValues={setValues}
              label="reimbursement.createReimbursement.travelDate"
              disabled={isApproverEdit}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-9">
          <div className="mt-5">
            <VpSelect
              name="merchant"
              label="reimbursement.createReimbursement.merchant"
              labelTranslationProp={
                accountingEnabled
                  ? {
                      accountingSoftware:
                        ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                          accountingSoftware
                        ],
                    }
                  : {}
              }
              placeholder="reimbursement.createReimbursement.merchant"
              options={merchantList}
              isOptionsFetched={isFetchingMerchants}
              isOptionsLoading={isFetchingMerchants}
              optionsDisplayKey="label"
              valueKey="name"
              handleChange={(e) => {
                setIsNewMerchant(false);
                handleChange(e);
              }}
              value={values?.merchant}
              error={errors?.merchant}
              customUIForOptionWhenDropdownOpen={(label, data) => {
                const isNewOption = data?.__isNew__;

                return isNewOption ? (
                  <div className="flex items-center gap-3 my-1 isNew">
                    <Icon className="w-4 h-4" name="Add" />
                    <Text
                      translationKey={`Create '${data?.value}' in Volopay`}
                    />
                  </div>
                ) : (
                  <div className="my-1">
                    <Text translationKey={label} />
                  </div>
                );
              }}
              customUIForOptionWhenDropdownClosed={
                isNewMerchant
                  ? (value, text) => (
                      <div className="flex items-center justify-between">
                        <Text translationKey={text?.label} />
                        <NewVendorChip
                          text={text ?? ""}
                          chipClasses="bg-success-50 text-success-500 border border-success-200 w-fit"
                        />
                      </div>
                    )
                  : null
              }
              menuPosition="absolute"
              insideForm
              creatable
              handleCreate={handleCreateMerchant}
              disabled={isApproverEdit}
            />
            {/* Existing/Creating Alert */}
            {values?.merchant && isNewMerchant ? (
              <div className="mt-2">
                <Alert
                  variant="neutral"
                  title={t(
                    "billPay.vendors.createVendor.creatingMerchantInXYZAlert"
                  )}
                  titleClasses="text-neutral-800 text-base font-semibold"
                />
              </div>
            ) : null}
          </div>
          <div className="flex items-center gap-8">
            <div className="w-1/2 -mt-1">
              <Input
                name="transactionAmount"
                type="number"
                label="reimbursement.createReimbursement.amountHeading"
                value={values?.transactionAmount}
                error={errors.transactionAmount}
                onChange={handleChange}
                classes="pb-3"
                disabled={isApproverEdit}
              />
            </div>
            <div className="w-1/2 ">
              <VpSelect
                name="transactionCurrency"
                label="reimbursement.createReimbursement.currency"
                menuPosition="absolute"
                placeholder="reimbursement.createReimbursement.currency"
                options={currencyOptions}
                optionsDisplayKey="label"
                valueKey="value"
                handleChange={handleChange}
                value={values?.transactionCurrency}
                error={errors.transactionCurrency}
                insideForm
                disabled={isApproverEdit}
              />
            </div>
          </div>

          <div>
            <VpSelect
              name="categoryId"
              label="reimbursement.createReimbursement.category"
              placeholder="reimbursement.createReimbursement.category"
              menuPosition="absolute"
              optionsDisplayKey="name"
              valueKey="id"
              options={categories}
              isOptionsLoading={isFetchingCategories}
              value={values?.categoryId}
              error={errors?.categoryId}
              rightText={
                catetogryLimitExceeded && categoryLimitType === FLEXIBLE ? (
                  <Icon
                    name="FlexibleLimit"
                    className="mr-2 border-2 bg-warning-50 text-warning-500 border-warning-100"
                  />
                ) : null
              }
              handleChange={handleChange}
              helperText={
                isLoading ? (
                  <div className="mt-1">
                    <LoaderSkeleton fullWidth />
                  </div>
                ) : catetogryLimitExceeded ? (
                  helperText()
                ) : null
              }
              helperTextVariant={
                catetogryLimitExceeded && categoryLimitType === FLEXIBLE
                  ? WARNING
                  : DANGER
              }
              insideForm
              disabled={isApproverEdit}
            />
          </div>
          <div className="flex flex-col gap-1 -mt-2 text-neutral-800">
            <DateInputTextBox
              dateInputExtraProp={transactionDateProps}
              name="transactionDate"
              value={dateToString(values?.transactionDate, { year: "numeric" })}
              error={errors.transactionDate}
              handleChange={handleChange}
              label="reimbursement.createReimbursement.transactionDate"
              description="reimbursement.createReimbursement.transactionDateDesc"
              disabled={isApproverEdit}
            />
          </div>
        </div>
      )}
      {isThisReimbursementIsDuplicate?.duplicate ? (
        <Alert
          variant="warning"
          title="This might be a duplicate claim"
          description="The claim details mentioned above may have been used previously, so please ensure that you are not claiming twice"
          primaryAction={{
            label: "Show previous claim",
            callback: () => {
              const data = isThisReimbursementIsDuplicate?.data?.[0];
              if (data?.id) {
                searchParam.append(
                  SLIDERS_SEARCH_PARAMS.reimbursements.approveId,
                  data?.id
                );
                dispatch(setCreateClaimFormDetails(values));
                setSearchParam(searchParam);
              }
            },
          }}
        />
      ) : null}
      {reportEnabled ? null : (
        <div className="mt-6">
          <LinkTo
            values={values}
            errors={errors}
            handleChange={handleChange}
            setValues={setValues}
            metaData={{
              title: "reimbursement.createReimbursement.linkClaimTitle",
              desc:
                values?.linkedTo === VENDOR_LINKED_TO_TYPES.PROJECT
                  ? "reimbursement.createReimbursement.linkClaimProjectDesc"
                  : "reimbursement.createReimbursement.linkClaimDeptDesc",
              departmentName: { departmentName: currentUser?.departmentName },
              departmentHelperText:
                "reimbursement.createReimbursement.departmentOptionHelperText",
              projectHelperText:
                "reimbursement.createReimbursement.projectOptionHelperText",
              disabledTooltipText:
                "myVolopay.reimbursements.reports.create.linkedTo.disabledTooltipText",
            }}
            disabled={isApproverEdit}
          />
        </div>
      )}
      {/* Show if approver edit is possible or if editing has been done */}
      {isShowAmountToBePaid ? (
        <div className="my-2">{approverEditUI}</div>
      ) : null}
      <div className="flex items-center gap-2">
        <Text
          classes="text-xl font-semibold"
          translationKey="reimbursement.approvals.sliderHeadings.receipt"
        />
        {values?.receipts?.length !== 0 ? (
          <span className="inline-block p-1 rounded-full bg-success-600 text-neutral-50">
            <Icon name="Done" />
          </span>
        ) : null}
      </div>

      <div className="w-full -my-4">
        <FileUpload
          acceptText="common.defaultUploadTextWithPdf"
          accept={DEFAULT_ACCEPT_WITH_PDF}
          name="receipts"
          files={values?.receipts}
          handleFileChange={handleChange}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          insideForm
          error={isReceiptError ? "misc.required" : ""}
        />
      </div>
      <div>
        <Text
          classes="text-xl font-semibold mb-2"
          translationKey="reimbursement.approvals.sliderHeadings.memo"
        />
        <Memo
          onKeyUp={(val) => {}}
          name="remarks"
          value={values?.remarks}
          onChange={handleChange}
          insideForm
          error={isMemoError ? "misc.required" : ""}
        />
      </div>
      {accountingEnabled &&
      (nonAccountingCategory?.length || accountingCategory) ? (
        <div className="mt-6">
          <Text
            translationKey="reimbursement.approvals.sliderHeadings.accounting"
            classes="text-xl font-semibold"
          />
          {/* Accounting Details */}
          <div className="flex flex-col gap-6 mt-6">
            {accountingCategory ? (
              <TagInput
                values={values}
                handleChange={handleChange}
                errors={errors}
                dropdownInputKey={accountingCategory?.id}
                textInputKey={accountingCategory?.id}
                vpSelectProps={{ insideForm: true }}
                tag={accountingCategory}
              />
            ) : null}
            {/* non Accounting Details */}
            {nonAccountingCategory?.length > 0
              ? nonAccountingCategory?.map((tag) => (
                  <TagInput
                    key={tag.id}
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    dropdownInputKey={tag?.id}
                    textInputKey={tag?.id}
                    vpSelectProps={{ insideForm: true }}
                    tag={tag}
                  />
                ))
              : null}
          </div>
        </div>
      ) : null}
      {/* Custom tags Details */}
      {customTags?.length > 0 ? (
        <div className="flex flex-col gap-6 mt-4">
          <Text
            translationKey="common.customTags.title"
            classes="text-lg font-semibold"
          />
          <div className="flex flex-col gap-6">
            {customTags?.map((tag) => (
              <TagInput
                key={tag.id}
                values={values}
                handleChange={handleChange}
                errors={errors}
                dropdownInputKey={tag?.id}
                textInputKey={tag?.id}
                vpSelectProps={{ insideForm: true }}
                tag={tag}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

OutOfThePocketForm.propTypes = {
  accountingSoftware: PropTypes.string,
  accountingEnabled: PropTypes.bool,
  customTags: PropTypes.array,
  accountingCategory: PropTypes.object,
  nonAccountingCategory: PropTypes.array,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  setValues: PropTypes.func,
  reimbursementType: PropTypes.string,
  claimCategoryLimitDetails: PropTypes.object,
  isThisReimbursementIsDuplicate: PropTypes.bool,
  isReceiptError: PropTypes.bool,
  isMemoError: PropTypes.bool,
  isApproverEdit: PropTypes.bool,
  saveCompleteForm: PropTypes.func,
  editMode: PropTypes.bool,
};
