import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { to } from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import vToast from "@/utils/vToast";

import { TAG_TYPES } from "@/constants/tags";
import API from "@/api";

import { setIsFormSubmissionProgress } from "./loadersError";

const initialState = {
  tags: [],
  isFetching: false,
  isFetched: false,
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setTags(state, action) {
      state.tags = action.payload;
    },
    setAllTags(state, action) {
      state.allTags = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    updateTag(state, action) {
      const index = state.tags.findIndex((el) => el.id === action.payload.id);
      if (index !== -1) state.tags[index] = action.payload.updatedTag;
    },
    updateTagValueProperty(state, action) {
      const { id, prop, val } = action.payload;
      return {
        ...state,
        tags: state.tags.map((tag) => ({
          ...tag,
          options: tag.options.map((option) =>
            option.id === id ? { ...option, [prop]: val } : option
          ),
        })),
      };
    },
    removeTag(state, action) {
      const index = state.tags.findIndex((el) => el.id === action.payload.id);
      if (index !== -1) state.tags.splice(index, 1);
    },
    addTag(state, action) {
      state.tags.push(action.payload);
    },
    setIsFetched(state, action) {
      state.isFetched = action.payload;
    },
  },
});

// async action creators
export const fetchTags = createAsyncThunk(
  "tags/fetchTags",
  async (params, { dispatch }) => {
    dispatch(setIsFetching(true));
    const [err, response] = await to(API.Tags.all(params));
    if (response?.data) {
      dispatch(setTags(response?.data?.list));
      dispatch(setIsFetched(true));
    }
    dispatch(setIsFetching(false));
  }
);

// async action creators
export const fetchVisibleTags = createAsyncThunk(
  "tags/fetchVisibleTags",
  async (params, { dispatch }) => {
    dispatch(setIsFetching(true));
    const [err, response] = await to(
      API.Tags.all({ ...params, visible: true })
    );
    if (response?.data) {
      dispatch(setTags(response?.data?.list));
    }
    dispatch(setIsFetching(false));
  }
);

export const deleteAccountingField = createAsyncThunk(
  "accounting/deleteAccountingField",
  async (id, { dispatch, rejectWithValue }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(API.Tags.deleteTag(id));

    if (error) {
      dispatch(setIsFormSubmissionProgress(false));
      return rejectWithValue(
        vToast(getErrorToastMessage(error, "errorMessage.errorOccured"))
      );
    }

    if (response?.data)
      vToast(getSuccessToastMessage(response, response?.data));

    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const createAccountingField = createAsyncThunk(
  "accounting/editAccountingField",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(API.Tags.createTag(params));

    if (error) {
      dispatch(setIsFormSubmissionProgress(false));
      return rejectWithValue(
        vToast(getErrorToastMessage(error, "errorMessage.errorOccured"))
      );
    }

    vToast({
      description: "tags.toasters.newFieldCreated",
      descriptionTranslationProp: {
        tagType: TAG_TYPES.CUSTOM,
      },
    });

    dispatch(setIsFormSubmissionProgress(false));
    return response.data;
  }
);

export const editAccountingField = createAsyncThunk(
  "accounting/editAccountingField",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(API.Tags.editTag(params));

    if (error) {
      dispatch(setIsFormSubmissionProgress(false));
      return rejectWithValue(
        vToast(getErrorToastMessage(error, "errorMessage.errorOccured"))
      );
    }

    dispatch(setIsFormSubmissionProgress(false));
    return response.data;
  }
);

export const {
  setTags,
  setAllTags,
  setIsFetching,
  updateTag,
  updateTagValueProperty,
  removeTag,
  addTag,
  setIsFetched,
} = tagsSlice.actions;

export default tagsSlice.reducer;
