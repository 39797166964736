export const FILTER_OPTIONS = {
  CARDS: "cards",
  BILL_PAY: "billPay",
  REIMBURSEMENT: "reimbursement",
  TOTAL: "total",
};

export const FILTER_OPTIONS_LABEL = {
  [FILTER_OPTIONS.CARDS]: "Cards",
  [FILTER_OPTIONS.BILL_PAY]: "Bill pay",
  [FILTER_OPTIONS.REIMBURSEMENT]: "Reimbursements",
  [FILTER_OPTIONS.TOTAL]: "All payments",
};
