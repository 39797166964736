import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import parse from "html-react-parser";
import Checkbox from "@/components/core/Checkbox";

function TncComponent({ tncArray, value, onClickHandler }) {
  const [text, setText] = useState("");
  const getTncObject = (tncObj) => {
    const newTncObj = JSON.parse(JSON.stringify(tncObj));
    const convertedText = tncObj.text.replace(/{link}/g, () => {
      const link = newTncObj.links.splice(0, 1)[0]; // getting first value and manipulating array both at same time
      return `<a className="link link-primary default" target="_blank" href="${link?.href}">${link?.text}</a>`;
    });
    return convertedText;
  };
  useEffect(() => {
    const convertedComponent =
      tncArray?.length > 0 ? (
        tncArray?.length === 1 ? (
          tncArray.map((tncObj) => (
            <p key={tncObj?.id}>{parse(getTncObject(tncObj))}</p>
          ))
        ) : (
          <ol className="ml-3 text-sm list-disc">
            {tncArray.map((tncObj) => (
              <li key={`list-disc-${tncObj?.id}`}>
                {parse(getTncObject(tncObj))}
              </li>
            ))}
          </ol>
        )
      ) : (
        ""
      );

    setText(convertedComponent);
  }, [tncArray]);

  return text ? (
    <Checkbox checked={value} label={text} onClickHandler={onClickHandler} />
  ) : null;
}

export default TncComponent;
TncComponent.propTypes = {
  tncArray: PropTypes.array,
  onClickHandler: PropTypes.func,
  value: PropTypes.bool,
};
