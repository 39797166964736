import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchAllEntities } from "@/store/reducers/app";
import { connectEntity, getConnectedEntities } from "@/store/reducers/user";

import {
  accountsSelector,
  isFetchingAccountsSelector,
} from "@/store/selectors/app";
import { subdomainSelector } from "@/store/selectors/client";
import {
  connectedAccountsSelector,
  isLoadingConnectEntitySelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Loader from "@/components/core/Loader";
import Text from "@/components/core/Text";

import SwitchEntityRadioComponent from "@/components/SwitchEntity/SwitchEntityRadioComponent";

export default function AddAccountForEntitySwitching() {
  const dispatch = useDispatch();
  const ref = useLeftHeaderTitle({ title: "switchEntity.addAccount" });
  const currentUser = useSelector(userSelector);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const entities = useSelector(accountsSelector);
  const connectedEntities = useSelector(connectedAccountsSelector);
  const connectedEntitiesSubdomains = connectedEntities?.map(
    (connectedEntity) => connectedEntity.subdomain
  );
  const currentSubdomain = useSelector(subdomainSelector);
  const isFetching = useSelector(isFetchingAccountsSelector);
  const unConnectedEntities =
    entities &&
    entities.filter(
      (item) =>
        !connectedEntitiesSubdomains.includes(item.subdomain) &&
        currentSubdomain !== item.subdomain
    );

  const isConnectLoading = useSelector(isLoadingConnectEntitySelector);

  useEffect(() => {
    dispatch(fetchAllEntities({ email: currentUser?.email }));
    dispatch(
      getConnectedEntities({
        email: currentUser?.email,
        subdomain: currentSubdomain,
      })
    );
  }, []);

  const onConnectEntity = () => {
    const toSubdomain = selectedEntity.subdomain;
    dispatch(connectEntity({ to_subdomain: toSubdomain }));
  };

  return (
    <div className="slider-content-container">
      <div className="slider-core-container px-9 flex flex-col gap-9">
        <Text
          translationKey="switchEntity.addAccount"
          classes="text-3xl font-bold text-neutral-800"
          refProp={ref}
        />
        <div className="flex flex-col gap-8">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col gap-1">
              <Text
                translationKey="switchEntity.selectEntity"
                classes="text-lg font-bold text-neutral-800"
              />
              <Text
                translationKey="switchEntity.addAccountDescription"
                translationProps={{ email: currentUser?.email }}
                classes="text-base font-medium text-neutral-500"
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {unConnectedEntities?.length
              ? unConnectedEntities?.map((entity, index) => (
                  <SwitchEntityRadioComponent
                    entity={entity}
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                    key={`switch-entity-${index}`}
                  />
                ))
              : null}
            {isFetching ? <Loader /> : null}
          </div>
        </div>
      </div>
      <div className="slider-footer sticky px-6 py-4">
        <div className="flex justify-end items-center gap-6 font-semibold">
          <Button
            label="switchEntity.btnLabels.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold text-neutral-500"
          />
          <Button
            label="switchEntity.btnLabels.continue"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            showLoader={isConnectLoading}
            onClick={onConnectEntity}
            disabled={!selectedEntity && isConnectLoading}
          />
        </div>
      </div>
    </div>
  );
}
