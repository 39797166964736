import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { currentOnboardingStepSelector } from "@/store/selectors/onboarding";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { KYC_STEPS_STATUS, ONBOARDING_STEPS } from "@/constants/onboarding";

function OnboardingStepOverview({ totalSteps = 3, stepsArray, currentStep }) {
  const currentIndex = stepsArray?.findIndex(
    (item) => item?.step === currentStep
  );
  const currentOnboardingStep = useSelector(currentOnboardingStepSelector);

  const showOnboardingForm = !(
    [
      KYC_STEPS_STATUS.IN_PROGRESS,
      KYC_STEPS_STATUS.APPROVED,
      KYC_STEPS_STATUS.REJECTED,
    ].indexOf(currentOnboardingStep) >= 0
  );

  return (
    <div className="flex flex-col pt-4 gap-7">
      <Text
        color="neutral-800"
        classes="text-3xl font-semibold"
        translationKey="onboarding.title"
      />
      <div>
        <Text
          classes="border-l-2 border-neutral-300 text-primary-500 text-xs font-bold pl-5 pb-5"
          translationKey="onboarding.steps"
          translationProps={{ totalSteps }}
        />
        {/* all steps layout */}
        <div>
          {stepsArray?.map((item, index) => (
            <div
              className={`relative pb-10 pl-5 ${
                index < stepsArray.length - 1
                  ? " border-l-2 border-neutral-300"
                  : ""
              }`}
              key={index}
            >
              {currentIndex > index || !showOnboardingForm ? (
                <Icon
                  name="CheckCircle"
                  className="absolute -translate-x-full border-4 w-7 h-7 text-success-600 border-neutral-50 left-3 top-1"
                />
              ) : (
                <div
                  className={`absolute px-2 text-xs text-white left-3 -translate-x-full  border-4 border-neutral-50 top-1 rounded-[50%]   ${
                    currentIndex < index ? "bg-neutral-300" : "bg-primary-500"
                  }`}
                >
                  {index + 1}
                </div>
              )}
              <div className="flex flex-col ">
                <Text
                  color={currentIndex < index ? "neutral-400" : ""}
                  classes="text-lg font-bold"
                  translationKey={item?.label}
                />
                <Text
                  color={currentIndex < index ? "neutral-400" : "neutral-500"}
                  classes="font-medium text-sm"
                  translationKey={item?.helperText}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OnboardingStepOverview;
OnboardingStepOverview.propTypes = {
  totalSteps: PropTypes.number,
  stepsArray: PropTypes.array,
  currentStep: PropTypes.string,
};
