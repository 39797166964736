import { TRANSACTION_STATUS_KEY } from "@/utils/constants/common";

export const TYPES_TIMELINE = {
  CLAIM: "claim",
  PAYROLL: "payroll",
  PURCHASE_BILL: "purchase_bill",
  CARD_REQUEST: "card_request",
  REPORT: "report",
  EXPENSE: "expense",
};
export const TIME_HEADER_BASED_TYPE = {
  [TYPES_TIMELINE.CLAIM]: "timeline.headers.claim",
  [TYPES_TIMELINE.PAYROLL]: "timeline.headers.payroll",
  [TYPES_TIMELINE.PURCHASE_BILL]: "timeline.headers.purchaseBill",
  [TYPES_TIMELINE.CARD_REQUEST]: "timeline.headers.cardRequest",
  [TYPES_TIMELINE.REPORT]: "timeline.headers.report",
  [TYPES_TIMELINE.EXPENSE]: "timeline.headers.expense",
};

export const APPROVAL = "approval";

export const TIMELINE_ITEM_CONFIG = {
  approval: {
    iconName: "Pace",
    classes: {
      iconClasses: "text-neutral-500",
      iconWrapper: "bg-neutral-100",
      textClasses: "text-base font-semibold text-neutral-800",
    },
    subHeading: "VIEW_APPROVER",
    showPending: true,
  },
  approved: {
    iconName: "CircleCheck",
    classes: {
      iconClasses: "text-success-500",
      iconWrapper: "bg-success-100",
      textClasses: "text-base font-semibold text-neutral-800",
    },
    subHeading: "DATE",
  },
  approval_skipped: {
    iconName: "StepOver",
    classes: {
      iconClasses: "text-primary-500",
      iconWrapper: "bg-primary-100",
      textClasses: "text-base font-semibold text-neutral-800",
    },
    subHeading: "DATE",
  },
  rejected: ({ timelineType, status }) => ({
    // TODO, check and remove ternary here
    iconName:
      timelineType === TYPES_TIMELINE.EXPENSE &&
      status?.toLowerCase() === TRANSACTION_STATUS_KEY.not_okay.toLowerCase()
        ? "Flag"
        : "Cancel",
    classes:
      timelineType === TYPES_TIMELINE.EXPENSE &&
      status?.toLowerCase() === TRANSACTION_STATUS_KEY.not_okay.toLowerCase()
        ? {
            iconClasses: "text-danger-400",
            iconWrapper: "bg-danger-50",
            textClasses: "text-base font-semibold text-neutral-800",
          }
        : {
            iconClasses: "text-danger-500",
            iconWrapper: "bg-danger-100",
            textClasses: "text-base font-semibold text-neutral-800",
          },
    subHeading: "DATE",
  }),
  repayment_rejected: ({ timelineType, status }) => ({
    iconName:
      timelineType === TYPES_TIMELINE.EXPENSE &&
      status?.toLowerCase() === TRANSACTION_STATUS_KEY.not_okay.toLowerCase()
        ? "Flag"
        : "Cancel",
    classes:
      timelineType === TYPES_TIMELINE.EXPENSE &&
      status?.toLowerCase() === TRANSACTION_STATUS_KEY.not_okay.toLowerCase()
        ? {
            iconClasses: "text-danger-400",
            iconWrapper: "bg-danger-50",
            textClasses: "text-base font-semibold text-neutral-800",
          }
        : {
            iconClasses: "text-danger-500",
            iconWrapper: "bg-danger-100",
            textClasses: "text-base font-semibold text-neutral-800",
          },
    subHeading: "DATE",
  }),
  failed: {
    iconName: "BillPayCancel",
    classes: {
      iconClasses: "text-danger-500",
      iconWrapper: "bg-danger-100",
      textClasses: "text-base font-semibold text-neutral-800",
    },
    subHeading: "DATE",
  },
  paid: {
    iconName: "Send",
    classes: {
      iconClasses: "text-success-500",
      iconWrapper: "bg-success-100",
      textClasses: "text-base font-semibold text-neutral-800",
    },
    subHeading: "DATE",
  },
  paid_outside_volopay: {
    iconName: "Send",
    classes: {
      iconClasses: "text-success-500",
      iconWrapper: "bg-success-100",
      textClasses: "text-base font-semibold text-neutral-800",
    },
    subHeading: "DATE",
  },
  auto_approved: {
    iconName: "Send",
    classes: {
      iconClasses: "text-success-500",
      iconWrapper: "bg-success-100",
      textClasses: "text-base font-semibold text-neutral-800",
    },
    subHeading: "DATE",
  },
  processing: {
    iconName: "Pace",
    classes: {
      iconClasses: "text-warning-500",
      iconWrapper: "bg-warning-100",
      textClasses: "text-base font-semibold text-neutral-800",
    },
    subHeading: "DATE",
  },
  created: {
    iconName: "Assignment",
    classes: {
      iconClasses: "text-primary-500",
      iconWrapper: "bg-primary-100",
      textClasses: "text-base font-semibold text-neutral-800",
    },
    subHeading: "DATE",
  },
  repayment_requested: {
    iconName: "Replay",
    classes: {
      iconClasses: "text-warning-600",
      iconWrapper: "bg-warning-50",
      textClasses: "text-base font-semibold text-neutral-800",
    },
  },
  repayment_received: {
    iconName: "CallReceivedReverse",
    classes: {
      iconClasses: "text-success-500",
      iconWrapper: "bg-success-50",
      textClasses: "text-base font-semibold text-neutral-800",
    },
  },
  repayment_declined: {
    iconName: "Close",
    classes: {
      iconClasses: "text-danger-500",
      iconWrapper: "bg-danger-50",
      textClasses: "text-base font-semibold text-neutral-800",
    },
  },
  repayment_cancelled: {
    iconName: "Close",
    classes: {
      iconClasses: "text-danger-500",
      iconWrapper: "bg-danger-50",
      textClasses: "text-base font-semibold text-neutral-800",
    },
  },
};
export const ALL_TIMELINE_STATUSES = {
  APPROVAL: "approval",
  APPROVAL_SKIPPED: "approval_skipped",
  REJECTED: "rejected",
  AUTO_APPROVED: "auto_approved",
  APPROVED: "approved",
  PROCESSING: "processing",
  PAID: "paid",
  PAID_OUTSIDE_VOLOPAY: "paid_outside_volopay",
  SETTLED_VIA_CARD: "settled_via_card",
  FAILED: "failed",
  SCHEDULED: "scheduled",
  UNSCHEDULED: "unscheduled",
  AWAITING_FUNDS: "awaiting_funds",
  PENDING_APPROVAL: "pending_approval",
  NOT_APPROVED: "not_approved",
  ARCHIVED: "archived",
  PENDING: "pending",
  OKAY: "okay",
  NOT_OKAY: "not_okay",
  DENIED: "denied",
  INCOMPLETED: "incompleted",
};

export const TIMELINE_STATUS_CONFIG = {
  [ALL_TIMELINE_STATUSES.APPROVAL]: {
    title: "timeline.aboveAccordionTexts.approvalPending",
    titleClasses: "text-base text-neutral-800 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.PENDING_APPROVAL]: {
    title: "timeline.aboveAccordionTexts.approvalPending",
    titleClasses: "text-base text-neutral-800 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.PENDING]: {
    title: ({ timelineType }) =>
      timelineType === TYPES_TIMELINE.EXPENSE
        ? "timeline.aboveAccordionTexts.reviewPending"
        : "timeline.aboveAccordionTexts.approvalPending",
    titleClasses: "text-base text-neutral-800 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.SCHEDULED]: {
    title: "timeline.aboveAccordionTexts.scheduled",
    titleClasses: "text-base text-success-600 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.APPROVED]: {
    title: "timeline.aboveAccordionTexts.approved",
    titleClasses: "text-base text-success-600 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.OKAY]: {
    title: "timeline.aboveAccordionTexts.approved",
    titleClasses: "text-base text-success-600 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.AUTO_APPROVED]: {
    title: "timeline.aboveAccordionTexts.autoApproved",
    titleClasses: "text-base text-success-600 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.UNSCHEDULED]: {
    title: "timeline.aboveAccordionTexts.unscheduled",
    titleClasses: "text-base text-warning-600 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.AWAITING_FUNDS]: {
    title: "timeline.aboveAccordionTexts.awaitingFunds",
    titleClasses: "text-base text-warning-600 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.PAID]: {
    title: "timeline.aboveAccordionTexts.paid",
    titleClasses: "text-base text-success-600 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.PAID_OUTSIDE_VOLOPAY]: {
    title: "timeline.aboveAccordionTexts.paid",
    titleClasses: "text-base text-success-600 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.FAILED]: {
    title: "timeline.aboveAccordionTexts.failed",
    titleClasses: "text-base text-danger-600 font-semibold",
    showAlertText: true,
    alertClasses: "!text-neutral-500",
    alertVariant: "danger",
  },
  [ALL_TIMELINE_STATUSES.REJECTED]: {
    title: "timeline.aboveAccordionTexts.rejected",
    titleClasses: "text-base text-danger-600 font-semibold",
    showAlertText: true,
    alertClasses: "!text-neutral-500",
    alertVariant: "danger",
  },
  [ALL_TIMELINE_STATUSES.DENIED]: {
    title: "timeline.aboveAccordionTexts.rejected",
    titleClasses: "text-base text-danger-600 font-semibold",
    showAlertText: true,
    alertClasses: "!text-neutral-500",
    alertVariant: "danger",
  },
  [ALL_TIMELINE_STATUSES.NOT_OKAY]: {
    title: "timeline.aboveAccordionTexts.flagged",
    titleClasses: "text-base text-danger-600 font-semibold",
    showAlertText: true,
    alertClasses: "!text-neutral-500",
    alertVariant: "danger",
  },
  [ALL_TIMELINE_STATUSES.DENIED]: {
    title: "timeline.aboveAccordionTexts.rejected",
    titleClasses: "text-base text-danger-600 font-semibold",
    showAlertText: true,
    alertClasses: "!text-neutral-500",
    alertVariant: "danger",
  },
  [ALL_TIMELINE_STATUSES.PROCESSING]: {
    title: "timeline.aboveAccordionTexts.processing",
    titleClasses: "text-base text-warning-800 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.INCOMPLETED]: {
    title: "timeline.aboveAccordionTexts.missingDetails",
    titleClasses: "text-base text-danger-600 font-semibold",
  },
  [ALL_TIMELINE_STATUSES.SETTLED_VIA_CARD]: {
    title: "timeline.aboveAccordionTexts.settledViaCard",
    titleClasses: "text-base text-success-600 font-semibold",
  },
};
