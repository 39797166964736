import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { accountDetailSelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import PaymentOptionIcon from "@/components/Company/Overview/AccountOverview/PaymentOptionIcon";
import DosAndDontsList from "@/components/Company/Overview/AccountOverview/Slider/shared/DosAndDontsList";
import {
  PAYMENT_OPTION_TYPE_HELP_TEXT_MAP,
  PAYMENT_OPTION_TYPE_TEXT_MAP,
} from "@/components/Company/Overview/AccountOverview/account-overview-utils";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAYMENT_OPTION_TYPE } from "@/constants/company";

export default function TopSection({ paymentOptionType }) {
  const accountDetails = useSelector(accountDetailSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const currency =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.fundAccount) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.currency);

  const openDrawSlider = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.credit.drawCredit, true);
    setSearchParams(searchParams);
  };

  return (
    <div aria-label="payment-option-slider-first-section">
      <div aria-label="flag-and-texts" className="flex items-center gap-3">
        <PaymentOptionIcon
          currency={currency}
          paymentOptionType={paymentOptionType}
        />
        <span className="text-3xl font-bold text-neutral-800">
          {paymentOptionType === PAYMENT_OPTION_TYPE.LOCAL
            ? `${currency} `
            : null}
          <Text
            translationKey={PAYMENT_OPTION_TYPE_TEXT_MAP[paymentOptionType]}
            translationProps={{ currency }}
          />
        </span>
      </div>
      <div className="mt-1">
        <Text
          classes="font-medium text-neutral-500"
          translationKey={PAYMENT_OPTION_TYPE_HELP_TEXT_MAP[paymentOptionType]}
        />
      </div>
      <div className="flex">
        <a href={accountDetails?.faqLink} target="_blank" rel="noreferrer">
          <Button
            label="company.accountOverview.paymentOptions.buttonTexts.FAQs"
            variant="tertiary"
            classes="w-fit px-11 font-semibold text-neutral-500 mt-6 gap-3"
            preIcon="Quiz"
            iconClasses="w-5 h-5"
            innerClasses="gap-1"
          />
        </a>
        {accountDetails?.ctas?.includes("creditDraw") ? (
          <Button
            label="company.credit.drawCredit"
            variant="primary"
            classes="w-fit px-11 font-semibold mt-6 gap-3 ml-3"
            onClick={() => openDrawSlider()}
          />
        ) : null}
      </div>
      {accountDetails?.guideLines ? (
        <div className="border-[1px] border-primary-300 rounded-xl p-4 bg-primary-50 mt-9">
          <DosAndDontsList dosAndDontsArray={accountDetails?.guideLines} />
        </div>
      ) : null}
    </div>
  );
}

TopSection.propTypes = {
  paymentOptionType: PropTypes.string,
};
