import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchReports,
  setReportInitialState,
  updateSingleReport,
} from "@/store/reducers/reimbursement";

import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  historyReportPaymentStatusFiltersSelector,
  isFetchingReportsSelector,
  reimbursementReportFiltersSelector,
  reportsHasMoreSelector,
  reportsListSelector,
  reportsTotalSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Filters from "@/components/core/Filters";
import Table from "@/components/core/Table";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import Export from "@/components/Exports";
import PaymentsTableLoader from "@/components/Reimbursement/PaymentsTableLoader/index";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import { convertFilters } from "@/utils/filters";
import { handleExport } from "@/utils/exports";
import { SORT_KEYS } from "@/utils/constants/sorting";
import { REIMBURSEMENT_STATUS } from "@/utils/constants/reimbursement";
import { REPORT_CONTEXT, TAB_TYPES } from "@/utils/constants/myVolopay";
import { debounce } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EXPORT_PAGE, EXPORT_PAGE_TYPE } from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import DeleteDraftModal from "../DeleteDraftModal";
import { REIMBURSEMENT_REPORT_TABLE_HEADER } from "./tableConfig";

const status = {
  active: [
    REIMBURSEMENT_STATUS.pending_approval,
    REIMBURSEMENT_STATUS.approved,
    REIMBURSEMENT_STATUS.processing,
  ],
  drafts: REIMBURSEMENT_STATUS.in_draft,
  history: [
    REIMBURSEMENT_STATUS.paid,
    REIMBURSEMENT_STATUS.denied,
    REIMBURSEMENT_STATUS.failed,
    REIMBURSEMENT_STATUS.paid_outside_volopay,
  ],
};

const filterConfig = {
  active: reimbursementReportFiltersSelector,
  drafts: reimbursementReportFiltersSelector,
  history: historyReportPaymentStatusFiltersSelector,
};
function ReimbursementReportList({ tabType }) {
  const dispatch = useDispatch();
  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
    emptyDataTitle,
    emptySubHeadingDataTitle,
  } = REIMBURSEMENT_REPORT_TABLE_HEADER?.[tabType] ?? {};

  const filters = useSelector(filterConfig[tabType]);
  const reports = useSelector(reportsListSelector);
  const isFetching = useSelector(isFetchingReportsSelector);
  const totalRows = useSelector(reportsTotalSelector);
  const hasMore = useSelector(reportsHasMoreSelector);
  const isEmpty = !reports?.length;

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });
  const [showDeleteDraftModal, setShowDeleteDraftModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const currentUser = useSelector(userSelector);
  const [searchtext, setSearchText] = useState("");
  const [isExportReady, setIsExportReady] = useState(false);
  const appliedFilters = useSelector(appliedFilterSelector);

  const [statusConfig, setStatusConfig] = useState(status[tabType]);

  const [selectedReport, setSelectedReport] = useState([]);

  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);
  const [deSelectedIds, setDeSelectedIds] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const updateApi = debounce((text) => {
    setSearchText(text);
  }, 500);

  const filterOptions = {
    status: statusConfig,
    user: currentUser?.id,
  };

  const onReset = () => {
    dispatch(setReportInitialState());
  };

  const loadMore = () => {
    dispatch(
      fetchReports({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        tab: tabType,
        ...filterOptions,
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
        ...convertFilters(appliedFilters),
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...convertFilters(appliedFilters),
      ...sorting,
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  const getEmptyChildrenNode = () => (
    <Button
      label="myVolopay.reimbursements.reports.createReport"
      preIcon="Add"
      variant="primary"
      classes="my-4"
      onClick={() => {
        searchParams.append(SLIDERS_SEARCH_PARAMS.reports.create, true);
        setSearchParams(searchParams);
      }}
    />
  );

  const handleRowClick = (id) => {
    if ([TAB_TYPES.HISTORY, TAB_TYPES.ACTIVE].includes(tabType)) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.reports.reportId, id);
    } else {
      searchParams.append(SLIDERS_SEARCH_PARAMS.reports.reviewId, id);
    }
    setSearchParams(searchParams);
  };

  // multi-select

  const headerSelected = headerSelectAll; // all selected or else unchecked

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedReport(reports.map((report) => report.id));
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedReport([]);
    }
  };

  const rowSelectionHandler = (report) => {
    if (selectedReport?.includes(report?.id)) {
      setSelectedReport((prev) => prev.filter((id) => id !== report?.id));
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, report?.id]);
      }
    } else {
      setSelectedReport((prev) => [...prev, report?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== report?.id));
    }

    dispatch(
      updateSingleReport({
        id: report.id,
        value: {
          ...report,
          selected: !report.selected,
        },
      })
    );
  };

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);
      if (!deSelectedIds.length) {
        setSelectedReport(reports?.map((data) => data.id));
      } else {
        const filteredIds = reports
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedReport(filteredIds);
      }
    }
  }, [bulkSelectAll, reports, deSelectedIds]);

  useEffect(() => {
    // When user selects checkbox from header
    setHeaderSelectAll(
      (selectedReport.length === pageNum * PAGINATION_PER_REQUEST_LIMIT ||
        selectedReport.length === reports?.length) &&
        !isEmpty
    );
  }, [selectedReport, pageNum, reports]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="w-full">
          <Filters filters={filters} />
        </div>
        <div className="flex justify-end">
          <Export
            totalExports={totalRows}
            storeName="reimbursement"
            sectionName="reimbursements"
            selectedFilter={appliedFilters}
            additionalFilters={{
              ...EXPORT_PAGE_TYPE.REPORT,
              status: statusConfig,
              tab: tabType,
            }}
          />
        </div>
      </div>
      <div className="mt-2">
        <Table
          {...{
            ...tableSetting,
            emptyDataTitle,
            emptyDataDescription: emptySubHeadingDataTitle,
            emptyDataChildren: getEmptyChildrenNode(),
          }}
          bulkApproveVisible={selectedReport?.length > 0}
          bulkApproveHeight="100px"
          bulkApproveContent={
            <BulkActionComponent
              selectedRows={
                deSelectedIds.length
                  ? totalRows - deSelectedIds.length
                  : selectedReport?.length
              }
              totalRows={totalRows}
              descriptionText="common.exportDesc"
              bulkApproveSelectAll={
                (bulkSelectAll && !deSelectedIds.length) ||
                selectedReport?.length === totalRows
              }
              showSelectAllButton={headerSelectAll}
              selectAllHandler={(val) => {
                setDeSelectedIds([]);
                setBulkSelectAll(val);
              }}
              handleExport={() => {
                handleExport(
                  false,
                  selectedReport?.length,
                  appliedFilters,
                  dispatch,
                  {
                    ...(bulkSelectAll
                      ? {
                          status: statusConfig,
                          tab: tabType,
                          deselected_ids: deSelectedIds,
                        }
                      : { selected_ids: selectedReport }),
                    export_type: EXPORT_PAGE.REPORT,
                  }
                );
              }}
              showExport
              clearSelection={() => {
                setBulkSelectAll(false);
                setHeaderSelectAll(false);
                setSelectedReport([]);
                setDeSelectedIds([]);
                dispatch(
                  updateSingleReport(
                    reports.map((report) => {
                      return {
                        ...report,
                        selected: !report.selected,
                      };
                    })
                  )
                );
              }}
            />
          }
        >
          <tr className={tableHeadingRowClasses}>
            {tableHeading.map((headVal, index) => (
              <th className={headVal.classes} key={headVal.id}>
                <HeaderCell
                  val={{
                    ...headVal,
                    sorting,
                    checkedValue: headerSelected && !isEmpty,
                    showCheckbox: index === 0 && !isFetching,
                    disabled: isEmpty,
                    handleRowClick,
                    onCheckboxClick: headerSelectionHandler,
                    setSorting,
                  }}
                />
              </th>
            ))}
          </tr>

          {(!isFetching || !isEmpty) &&
            reports?.map((val, index) => (
              <tr
                key={val.id}
                className={tableDataRowClasses}
                ref={(ref) => {
                  if (index === reports.length - 1 && hasMore) {
                    handleRefChange(ref);
                  }
                }}
                onClick={() => {
                  handleRowClick(val?.id);
                }}
              >
                {tableHeading.map(
                  ({ cellComponent: Component, classes, id }) => (
                    <td
                      className={`${classes} } ${
                        selectedReport?.includes(val.id)
                          ? "selected-row-cell"
                          : ""
                      }`}
                      key={id}
                    >
                      <Component
                        val={{
                          ...val,
                          showReportNameCheckbox: true,
                          context: REPORT_CONTEXT,
                          onCheckboxClick: () => rowSelectionHandler(val),
                          handleRowClick,
                          setShowModal: setShowDeleteDraftModal,
                          setDeleteId,
                          checkedValue: selectedReport?.includes(val?.id),
                        }}
                        key={id}
                      />
                    </td>
                  )
                )}
              </tr>
            ))}
          {isFetching && <PaymentsTableLoader />}
        </Table>
      </div>

      {showDeleteDraftModal ? (
        <DeleteDraftModal
          setShowModal={setShowDeleteDraftModal}
          showModal={showDeleteDraftModal}
          deleteId={deleteId}
        />
      ) : null}
    </div>
  );
}

export default ReimbursementReportList;

ReimbursementReportList.propTypes = {
  tabType: PropTypes.string,
};
