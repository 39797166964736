import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchGlobalCountryCurrencies,
  setSelectedGlobalAccountCurrency,
} from "@/store/reducers/account";
import {
  globalCountryCurrenciesSelector,
  isFetchingSelector,
} from "@/store/selectors/account";

import { paymentWalletListSelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import SearchBar from "@/components/Company/Slider/LocationDepartmentDetails/common/SearchBar";
import { debounce } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function GlobalAccountSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currency, setCurrency] = useState(null);
  const [currencies, setCurrencies] = useState([]);

  const isPrimaryAccountSlider = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.primaryAccount
  );

  const paymentAccounts = useSelector(paymentWalletListSelector);

  const primaryAccounts = paymentAccounts?.map((acc) => acc.currency);

  const isFetching = useSelector(isFetchingSelector);
  const globalCountryCurrencies = useSelector(globalCountryCurrenciesSelector);

  const noPrimaryAccountCurrencies = currencies?.filter(
    (a) => !primaryAccounts?.includes(a?.currency)
  );

  const currenciesAllowedForGlobalAcc = currencies?.filter((a) =>
    primaryAccounts?.includes(a?.currency)
  );

  useEffect(() => {
    dispatch(fetchGlobalCountryCurrencies());
  }, []);

  useEffect(() => {
    setCurrencies(
      Object.keys(globalCountryCurrencies).map((cur) => {
        return { currency: cur };
      })
    );
  }, [globalCountryCurrencies]);

  const continueFunction = () => {
    dispatch(setSelectedGlobalAccountCurrency(currency));
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.addAccountLocation, true);
    setSearchParams(searchParams);
  };

  const [search, setSearch] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  return !isFetching ? (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        <div className="flex flex-col">
          <Text
            classes="payment-slider-heading"
            translationKey="company.globalAccounts.addAccountTitle"
          />
          <Text
            classes="text-neutral-500 text-sm"
            translationKey="company.globalAccounts.addAccountDesc"
          />

          <Text
            classes="text-lg font-semibold mt-10"
            translationKey="common.currency"
          />

          <Text
            classes="text-neutral-500 text-sm mb-6"
            translationKey="company.globalAccounts.selectCurrencyDesc"
          />

          <div className="w-full">
            <SearchBar
              classes="mb-6"
              searchQuery={search}
              handleChange={(e) => {
                setSearch(e.target.value);
                const debouncedFunc = debounce(() => {
                  setDebouncedSearchText(e.target.value);
                }, 500);
                debouncedFunc();
              }}
            />
          </div>

          {currenciesAllowedForGlobalAcc &&
            (isPrimaryAccountSlider
              ? noPrimaryAccountCurrencies
              : currenciesAllowedForGlobalAcc
            )
              ?.filter(
                (f) =>
                  !debouncedSearchText ||
                  f?.currency
                    ?.toLowerCase()
                    ?.includes(debouncedSearchText?.toLowerCase())
              )
              ?.map((c) => {
                return (
                  <div
                    className="flex items-center gap-4 mb-6"
                    key={c?.currency}
                  >
                    <Radio
                      value={currency}
                      isChecked={currency === c?.currency}
                      handleChange={() => {
                        setCurrency(c?.currency);
                      }}
                    />
                    <div>
                      <Flag
                        code={c.currency}
                        classes="h-9 w-9 border-1 border-neutral-200"
                        border
                      />
                      <Text translationKey={c?.currency} classes="ml-2" />
                    </div>
                  </div>
                );
              })}
          {isPrimaryAccountSlider ? null : (
            <div className="pt-6 border-t border-neutral-200">
              {noPrimaryAccountCurrencies?.map((f) => {
                return (
                  <div
                    className="flex items-center justify-between mb-6"
                    key={f?.currency}
                  >
                    <div>
                      <Flag
                        code={f?.currency}
                        classes="h-9 w-9 border-1 border-neutral-200 opacity-30 "
                        border
                      />
                      <Text
                        translationKey={f?.currency}
                        classes="ml-2 text-neutral-800"
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <Text
                        translationKey="company.globalAccounts.primaryAccountMissing"
                        classes="text-neutral-500"
                      />
                      <div id="missing-account">
                        <Icon
                          name="Info"
                          className="w-5 h-5 cursor-pointer text-neutral-300"
                        />
                      </div>
                    </div>

                    <Tooltip
                      maxWidth="30rem"
                      id="missing-account"
                      direction="bottom"
                    >
                      <Text
                        translationKey="company.globalAccounts.primaryAccMissingDesc"
                        classes="font-medium text-sm"
                      />
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="sticky p-6 slider-footer ">
        <div className="flex justify-end ">
          <Button
            label="misc.continue"
            disabled={!currency}
            classes="w-18 px-5 py-3 text-base font-semibold"
            id="add-account-button"
            onClick={continueFunction}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="ml-10 gap-19 ">
      <div className="flex items-center gap-2">
        <LoaderSkeleton type="circle" size={[80, 80]} />
        <LoaderSkeleton size={[80, 500]} />
      </div>
      <div className="mt-5">
        <LoaderSkeleton size={[80, 590]} />
      </div>
    </div>
  );
}
