import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchAccountingTransactions,
  fetchAvailableToSync,
  fetchFailedToSync,
  resetAccountingTransactionsList,
} from "@/store/reducers/accounting_transactions";
import { fetchRulesCount } from "@/store/reducers/rules";

import {
  accountingOthersFilterSelector,
  accountingTransactionsHasMoreSelector,
  accountingTransactionsSelector,
  accountingTransactionsTotalSelector,
  availableToSyncSelector,
  failedToSyncSelector,
  fetchingAvailableToSyncSelector,
  fetchingFailedToSyncSelector,
  isFetchingTransactionsSelector,
} from "@/store/selectors/accounting_transactions";
import {
  accountingIntegrationSoftwareSelector,
  isFetchingClientDetailsSelector,
  primaryCardProviderSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { rulesCountSelector } from "@/store/selectors/rules";

import AccountingTransactionSearchFilter from "@/components/Accounting/Transactions/AccountingTransactionSearchFilter";
import { formatDataForTable } from "@/components/Accounting/Transactions/Others/PageHelper/helper";
import TransactionsTable from "@/components/Accounting/Transactions/TransactionsTable";
import FailedSyncCard from "@/components/Accounting/Transactions/common/FailedSyncCard";
import RulesCard from "@/components/Accounting/Transactions/common/RulesCard";
import { convertFilters } from "@/utils/filters";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";

import { CARD_PROVIDER } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_HEADER_IDS,
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
  ACCOUNTING_TRANSACTION_TABS,
  TAB_STATUS_MAP,
} from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { TRANSACTION_ACTIONS } from "@/constants/transactions";

import {
  accountingActionHandler,
  accountingBulkAction,
  formatAccountingStatusFilter,
  getSortingParams,
} from "../../common/util";

export default function OthersPageHelper({ tab }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useState({
    type: null,
    category: SORTING_CATEGORY.AMOUNT,
  });
  // Transactions
  const transactions = useSelector(accountingTransactionsSelector);
  const isFetchingTransactions = useSelector(isFetchingTransactionsSelector);
  const hasMore = useSelector(accountingTransactionsHasMoreSelector);
  const totalTransactions = useSelector(accountingTransactionsTotalSelector);

  // Available to Sync
  const { syncableCount, syncableIds } = useSelector(availableToSyncSelector);
  const fetchingAvailableToSync = useSelector(fetchingAvailableToSyncSelector);

  // Failed to sync
  const failedToSync = useSelector(failedToSyncSelector);
  const fetchingFailedToSync = useSelector(fetchingFailedToSyncSelector);

  // Accounting Software
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = accountingSoftware || false;

  // Filters
  const filters = useSelector(accountingOthersFilterSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const primaryCardProvider = useSelector(primaryCardProviderSelector);
  const slug = primaryCardProvider?.slug;

  const isFetchingClient = useSelector(isFetchingClientDetailsSelector);

  const _filters = useMemo(() => {
    if (slug === CARD_PROVIDER.UOB) {
      const transactionTypeFilter = filters.find(
        (filter) => filter?.props?.filterKey === "transactionType"
      );

      transactionTypeFilter.props.options =
        transactionTypeFilter?.props?.options?.filter(
          (filter) =>
            ![
              "filters.otherType.invoicePayment",
              "filters.otherType.cashback",
              "filters.otherType.travel",
            ].includes(filter?.label)
        );
    }

    return filters;
  }, [filters]);

  const requiredFilters = formatAccountingStatusFilter(
    _filters,
    tab,
    accountingEnabled
  );

  // Rules
  const rulesCount = useSelector(rulesCountSelector);

  // Setting headers
  const headers = [
    {
      id: ACCOUNTING_HEADER_IDS.TRANSACTION_TYPE,
      label: "accounting.transactions.others.listingFields.type",
      classes: "text-left",
      checkbox: true,
      colWidth: 320,
    },
    {
      id: ACCOUNTING_HEADER_IDS.AMOUNT,
      label: "accounting.transactions.cards.listingFields.amount.header",
      classes: "text-left",
      sortable: true,
      colWidth: 200,
    },
    {
      id: ACCOUNTING_HEADER_IDS.ACTION,
      label: "accounting.transactions.cards.listingFields.action.header",
      classes: "text-center flex-1",
      showColumn: tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED,
      colWidth: 200,
    },
    {
      id: ACCOUNTING_HEADER_IDS.OPEN_IN,
      label: "Open in",
      classes: "text-center justify-center",
      showColumn:
        tab === ACCOUNTING_TRANSACTION_STATUS.SYNCED &&
        ![
          ACCOUNTING_SOFTWARES.UNIVERSAL_CSV,
          ACCOUNTING_SOFTWARES.TALLY,
        ].includes(accountingSoftware),
      colWidth: 145,
    },
  ].filter((item) => item?.showColumn ?? true);

  // Selected transactions
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedTransactionObjects, setSelectedTransactionObjects] = useState(
    []
  );
  const [bulkApproveSelectAll, setBulkApproveSelectAll] = useState(false);

  // Infinite Scroll
  const onReset = () => {
    dispatch(resetAccountingTransactionsList());
  };

  const loadMore = (pageNumber = 1, onSuccess = () => {}) => {
    // we have 2 cases, connected to accounting and NOT connected to accounting software
    // Not connected: we need to ensure that we're calling the API on first load
    // Connected: only call the API if tab is passed to prevent duplicate calls when refreshing on pending and synced tabs
    if ((tab || !accountingEnabled) && !isFetchingClient) {
      dispatch(
        fetchAccountingTransactions({
          type: ACCOUNTING_TRANSACTION_PAGES.OTHERS,
          accounting_status: TAB_STATUS_MAP[tab],
          page: pageNum || pageNumber,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          ...getSortingParams(sorting),
          ...convertFilters(appliedFilters),
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (!isFetchingClient) {
      loadMore();
    }
  }, [isFetchingClient]);

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...getSortingParams(sorting),
      ...convertFilters(appliedFilters),
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.25,
  });

  // First load
  useEffect(() => {
    onReset();
    dispatch(
      fetchFailedToSync({ transaction: ACCOUNTING_TRANSACTION_PAGES.OTHER })
    );
    // dispatch(fetchRulesCount());

    return () => {
      dispatch(resetAccountingTransactionsList());
    };
  }, []);

  // Converting transactions to data format
  const formattedTransactions = useMemo(() => {
    return transactions?.map((transaction) => formatDataForTable(transaction));
  }, [transactions]);

  const handleViewOthersTransaction = (id) => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.others.id, id);
    setSearchParams(searchParams);
  };

  // Setting props for table
  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 0,
    numberOfStickyColsRight: !accountingSoftware ? 0 : 1,
    colWidths: headers.map((header) => header.colWidth),
    emptyDataIconSrc: "emptyScreen.svg",
  };

  const handleFailedToSyncSliderOpen = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.failedToSync,
      ACCOUNTING_TRANSACTION_PAGES.OTHERS
    );
    setSearchParams(searchParams);
  };

  const openRules = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.id, "true");
    setSearchParams(searchParams);
  };

  return (
    <>
      {accountingEnabled ? (
        <div className="flex items-center gap-4 mt-6 mb-1">
          {accountingSoftware !== ACCOUNTING_SOFTWARES.UNIVERSAL_CSV ? (
            <FailedSyncCard
              count={failedToSync}
              isFetching={fetchingFailedToSync}
              handleClick={() => handleFailedToSyncSliderOpen()}
            />
          ) : null}
        </div>
      ) : null}

      <div className="mb-2">
        <AccountingTransactionSearchFilter
          filters={requiredFilters}
          showSyncAll={
            accountingEnabled && tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED
          }
          availableToSync={syncableCount}
          fetchingAvailableToSync={fetchingAvailableToSync}
          syncAll={accountingBulkAction}
          syncableIds={syncableIds}
          page={ACCOUNTING_TRANSACTION_PAGES.OTHER}
        />
      </div>

      {formattedTransactions ? (
        <TransactionsTable
          pageNum={pageNum}
          tab={tab}
          page={ACCOUNTING_TRANSACTION_PAGES.OTHERS}
          tableArgs={tableArgs}
          headers={headers}
          originalTransactions={transactions}
          transactions={formattedTransactions}
          totalTransactions={totalTransactions}
          isFetching={isFetchingTransactions}
          hasMore={hasMore}
          isGrouped={false}
          actionDisabled={!accountingSoftware}
          handleRefChange={handleRefChange}
          handleBulkAction={accountingBulkAction}
          loadMore={loadMore}
          selectedTransactions={selectedTransactions}
          selectedTransactionObjects={selectedTransactionObjects}
          onActionClick={accountingActionHandler}
          onViewTransactionClick={handleViewOthersTransaction}
          bulkApproveSelectAll={bulkApproveSelectAll}
          setSelectedTransactions={setSelectedTransactions}
          setSelectedTransactionObjects={setSelectedTransactionObjects}
          setBulkApproveSelectAll={setBulkApproveSelectAll}
          sorting={sorting}
          setSorting={setSorting}
        />
      ) : null}
    </>
  );
}

OthersPageHelper.propTypes = {
  tab: PropTypes.string,
};
