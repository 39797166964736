export const injectObjectPolyfill = () => {
  /**
   * groupby polyfill
   * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/groupBy
   */
  if (!Object.groupBy) {
    Object.defineProperty(Object, "groupBy", {
      value(array, callback) {
        return array.reduce((acc, item) => {
          const key = callback(item);
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});
      },
      configurable: true,
      writable: true,
    });
  }
};
