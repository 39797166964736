import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchReimbursementSetting } from "@/store/reducers/reimbursement";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { REIMBURSEMENT_SETTINGS_TABS } from "@/utils/constants/payments";

import { ROUTES } from "@/constants/routes";

import "../styles.scss";

export default function ReimbursementSettings() {
  const [selectedTab, setSelectedTab] = useState(
    REIMBURSEMENT_SETTINGS_TABS[0].key
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReimbursementSetting());
  }, []);
  return (
    <div className="billpay-settings-page-container">
      <div className="billpay-settings-page-top-section">
        <div aria-label="breadcrumb-and-large-title">
          <Text
            classes="text-neutral-500 text-sm font-semibold block"
            translationKey="reimbursement.settings.reimbursementBreadcrumb"
          />

          <Text
            classes="texxt-neutral-800 text-4xl font-extrabold block"
            translationKey="reimbursement.settings.title"
          />
        </div>
      </div>
      <div className="billpay-settings-page-main-section">
        <Tabs
          items={REIMBURSEMENT_SETTINGS_TABS}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
        />
      </div>
    </div>
  );
}
