import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

function Payroll() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.payroll.salaryPayment.payrollInbox.absolutePath);
  });
  return <div>Payroll</div>;
}

export default Payroll;
