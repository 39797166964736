import PropTypes from "prop-types";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";

export default function NotificationDiscardChangesModal({
  showModal,
  setShowModal,
  handleDiscard,
  handleCancel,
}) {
  const onCloseModal = () => {
    setShowModal(false);
    handleCancel();
  };

  return (
    <Modal
      onClose={onCloseModal}
      open={showModal}
      className="flex items-center justify-center z-[1000]"
    >
      <div className="w-91.5 h-fit flex flex-col bg-white rounded-2xl mx-5 py-3 px-2">
        <div>
          <div className="pt-4 pb-2">
            <Icon name="WarningCircle" className="w-8 h-8 text-danger-500" />
          </div>
          <div className="flex flex-col gap-0.5 w-full">
            <Text
              translationKey="settings.notificationPreferences.tabChangeModalText"
              classes="text-lg font-bold text-neutral-800 py-2"
            />
            <Text
              translationKey="settings.notificationPreferences.tabChangeModalConfirmText"
              classes="text-sm font-semibold text-neutral-400 break-words py-0.5"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 py-6">
            <Button
              label="settings.notificationPreferences.buttonLabel.cancel"
              classes="px-5 py-3 text-base font-semibold text-neutral-500"
              variant="tertiary"
              onClick={onCloseModal}
            />
            <Button
              label="settings.notificationPreferences.buttonLabel.discardChanges"
              classes="px-5 py-3 text-base font-semibold "
              type="danger"
              onClick={handleDiscard}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

NotificationDiscardChangesModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  handleDiscard: PropTypes.func,
  handleCancel: PropTypes.func,
};
