import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import FileUpload from "@/components/core/FileUpload";
import XlsxFilePreviewComponent from "@/components/core/FileUpload/PreviewComponents/XlsxFilePreviewComponent";
import Memo from "@/components/core/Memo";
import Text from "@/components/core/Text";
import { TYPE_REIMBURSEMENT } from "@/utils/constants/reimbursement";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
} from "@/constants/accounting";
import { DEFAULT_ACCEPT_WITH_PDF } from "@/constants/fileViewer";
import { ROUTES } from "@/constants/routes";

import SingleColumnDataRows from "../../common/Slider/SingleColumnDataRows";
import SliderAccountingSection from "../../common/Slider/SliderAccountingSection";
import SliderDataCard from "../../common/Slider/SliderDataCard";
import RequestedDetailsSummary from "./RequestedDetailsSummary";

export default function ReimbursementTransactionOverviewTable({
  reimbursement,
  accountingState,
  setAccountingState,
  files,
  memo,
  setMemo,
  fileUploadHandler,
  primaryAction,
  secondaryAction,
  handleUpdateMemo = () => {},
}) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const category = reimbursement?.category?.label;
  const merchant = reimbursement?.merchant?.name;
  const department = reimbursement?.createdBy?.departmentName;
  const location = reimbursement?.createdBy?.locationName;
  const [accountingTag, setAccountingTag] = useState(
    reimbursement?.accountingTags
  );
  const [customTag, setCustomTag] = useState(reimbursement?.customTags);

  const accountingTagOptions = useMemo(() => accountingTag, []);
  const customTagOptions = useMemo(() => customTag, []);
  const accountingDateOptions = useMemo(
    () => ({
      transactionDate: reimbursement?.transactionDate,
    }),
    []
  );
  const accountingForm = {
    category: {
      value: category,
    },
    merchant: {
      value: merchant,
    },
    accountingTag: {
      value: accountingTag,
      options: accountingTagOptions,
      handleChange: setAccountingTag,
    },
    customTag: {
      value: customTag,
      options: customTagOptions,
      handleChange: setCustomTag,
    },
    department: {
      value: department,
    },
    location: {
      value: location,
    },
    accountingDateOptions,
    accountingState,
    setAccountingState,
  };

  const [invoiceFiles, setInvoiceFiles] = useState(
    reimbursement?.receipts ?? []
  );

  return (
    <div className="flex flex-col gap-8 mt-8 mb-4">
      <div className="flex flex-col gap-3">
        <Text
          translationKey="accounting.transactions.paymentDetails"
          classes="text-lg font-semibold text-neutral-800"
        />
        <div className="grid grid-cols-2 gap-3">
          <SliderDataCard
            nameKey="accounting.transactions.cards.listingFields.owner.header"
            value={reimbursement?.createdBy?.displayName}
            onLinkClick={createNavigationHandler(
              navigate,
              ROUTES.manage.people.absolutePath,
              { personId: reimbursement?.createdBy?.id }
            )}
          />
          <SliderDataCard
            nameKey="accounting.transactions.cards.listingFields.method.header"
            value={TYPE_REIMBURSEMENT[reimbursement?.type]}
            noLink
          />
          <SliderDataCard
            nameKey="cards.listingFields.department.header"
            value={reimbursement?.createdBy?.departmentName}
            onLinkClick={() => {
              searchParams.append(
                SLIDERS_SEARCH_PARAMS.company.department.id,
                reimbursement?.createdBy?.departmentId
              );
              setSearchParams(searchParams);
            }}
          />
        </div>
      </div>

      <div>
        <RequestedDetailsSummary reimbursement={reimbursement} />
      </div>
      <SliderAccountingSection
        showSplit={false}
        inputs={accountingForm}
        transaction={reimbursement}
        page={ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT}
      />

      <div>
        <Text
          classes="text-neutral-800 font-semibold text-lg"
          translationKey="accounting.transactions.cards.slider.transactionDetails"
        />
        <div className="mt-5">
          <SingleColumnDataRows
            dataRows={[
              {
                label:
                  "accounting.transactions.slider.transactionDetailsLabels.transactionId",
                value: reimbursement?.id,
              },
              {
                label:
                  "accounting.transactions.slider.transactionDetailsLabels.category",
                value: reimbursement?.category?.label,
              },
            ]}
          />
        </div>
      </div>
      <div>
        <Text
          classes="text-neutral-800 font-semibold text-lg"
          translationKey="reimbursement.approvals.sliderHeadings.receipt"
        />

        <div className="mt-3">
          <FileUpload
            acceptText="common.defaultUploadTextWithPdf"
            accept={DEFAULT_ACCEPT_WITH_PDF}
            isDisabled={!reimbursement?.editable}
            files={files}
            showNoFileUI={!reimbursement?.editable}
            multiple
            handleFileChange={fileUploadHandler}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
            primaryActionDisabled={!reimbursement?.editable}
            secondaryActionDisabled={!reimbursement?.editable}
            hidePreviewUploadComponent={[
              ACCOUNTING_TRANSACTION_STATUS.VERIFIED,
              ACCOUNTING_TRANSACTION_STATUS.SYNCED,
            ].includes(reimbursement?.accountingStatus)}
          />
        </div>

        {invoiceFiles.length === 0 && (
          <Text
            translationKey="misc.required"
            classes="text-xs font-medium text-danger-600"
          />
        )}
      </div>
      <div>
        <Text
          classes="text-neutral-800 font-semibold text-lg"
          translationKey="reimbursement.approvals.sliderHeadings.memo"
        />
        <Memo
          value={memo}
          onChange={setMemo}
          disabled={!reimbursement?.editable}
          onBlur={(e) => handleUpdateMemo(e.target.value)}
        />
        {!memo && (
          <Text
            translationKey="misc.required"
            classes="text-xs font-medium text-danger-600"
          />
        )}
      </div>
    </div>
  );
}

ReimbursementTransactionOverviewTable.propTypes = {
  reimbursement: PropTypes.object,
  accountingState: PropTypes.object,
  setAccountingState: PropTypes.func,
  files: PropTypes.array,
  memo: PropTypes.string,
  setMemo: PropTypes.func,
  fileUploadHandler: PropTypes.func,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
};

function createNavigationHandler(navigate, destination, queryObject) {
  return () =>
    navigate({
      pathname: destination,
      search: createSearchParams(queryObject).toString(),
    });
}
