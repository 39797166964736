import PropTypes from "prop-types";

import BeneficiaryBankCountry from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/BeneficiaryBankCountry";
import BeneficiaryCountry from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/BeneficiaryCountry";
import BeneficiaryCurrency from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/BeneficiaryCurrency";
import PaymentMethod from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/PaymentMethod";

export default function WallexBankDetails({
  values,
  errors,
  handleChange,
  paymentMethods,
  isFetchingPaymentMethods,
  setValues,
  isEditMode,
  fieldsFilters,
  provider,
  context,
  inPayrollContext,
}) {
  return (
    <div>
      <div className="flex flex-col gap-6 mt-6">
        {/* <PaymentAccount {...{ values, errors, handleChange, setValues, inPayrollContext }} /> */}
        <BeneficiaryCountry
          {...{
            values,
            errors,
            handleChange,
            relevantValues: fieldsFilters.relevantCountries,
            setValues,
            isEditMode,
            context,
          }}
        />
        <BeneficiaryCurrency
          {...{
            values,
            errors,
            handleChange,
            relevantValues: fieldsFilters.relevantCurrencies,
            setValues,
            isEditMode,
            context,
            provider,
          }}
        />
        <BeneficiaryBankCountry
          {...{ values, errors, handleChange, isEditMode, context }}
        />
        <PaymentMethod
          {...{
            values,
            errors,
            handleChange,
            paymentMethods,
            isFetchingPaymentMethods,
            relevantValues: fieldsFilters.relevantPaymentMethods,
            setValues,
            isEditMode,
          }}
        />
      </div>
    </div>
  );
}

WallexBankDetails.propTypes = {
  values: PropTypes.object, // 'values' returned by useForm
  errors: PropTypes.object, // 'errors' returned by useForm
  handleChange: PropTypes.func, // 'handleChange' returned by useForm  isEditMode: PropTypes.bool,
  fieldsFilters: PropTypes.object,
  paymentMethods: PropTypes.array,
  isFetchingPaymentMethods: PropTypes.bool,
  context: PropTypes.string,
  provider: PropTypes.string,
  setValues: PropTypes.func, // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough)
  inPayrollContext: PropTypes.bool,
};
