import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  currentOnboardingSectionSelector,
  selectedSectionSelector,
} from "@/store/selectors/onboarding";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function OnboardingCurrentStepPagesOverview() {
  const [stackOfSteps, setStackOfStep] = useState([]);
  const showStepsSection = stackOfSteps.length > 1;
  const selectedSection = useSelector(selectedSectionSelector);
  const currentOnboardingSection = useSelector(
    currentOnboardingSectionSelector
  );

  const currentIndex = useMemo(() => {
    return stackOfSteps.indexOf(currentOnboardingSection);
  }, [stackOfSteps, currentOnboardingSection]);

  useEffect(() => {
    if (selectedSection) {
      setStackOfStep(selectedSection?.map((item) => item.key));
    }
  }, [selectedSection]);

  return (
    <div>
      {showStepsSection ? (
        <div className="flex items-center mb-8 card-wrapper">
          {selectedSection?.length >= 1
            ? selectedSection?.map((item, index, arr) => {
                const showCheckedCircle = currentIndex > index;

                return (
                  <Fragment key={`onboarding-section-${index}`}>
                    <div className="flex items-center gap-2 min-w-fit">
                      {showCheckedCircle ? (
                        <Icon
                          name="CheckCircle"
                          className="w-5 h-5 text-success-600"
                        />
                      ) : (
                        <div
                          className={`px-2 text-xs text-white border-4 top-1 rounded-full ${
                            currentIndex < index
                              ? "bg-neutral-300"
                              : "bg-primary-500"
                          }`}
                        >
                          {index + 1}
                        </div>
                      )}

                      <Text
                        classes="w-max"
                        color={
                          currentIndex < index ? "neutral-500" : "neutral-800"
                        }
                        translationKey={item.label}
                      />
                    </div>

                    {index <= 1 ? (
                      <div className="w-full mx-3 border-t-2 border-neutral-200" />
                    ) : null}
                  </Fragment>
                );
              })
            : null}
        </div>
      ) : null}
    </div>
  );
}
