import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { newCardRequestDetailsSelector } from "@/store/selectors/cards";

import FormSummaryInfo from "@/components/common/FormSummaryInfo";

import { CARD_FREQUENCY_LABEL, LINKED_TO_TYPE } from "@/constants/Cards";

export default function RejectCardOrderDetailsSummary({ data }) {
  const { t } = useTranslation();
  const cardHolder = data?.cardHolder;
  const cardHolderName = cardHolder?.displayName;

  const { project, linkedTo, frequency, currency, amount: limit } = data;

  const linkedToText = `${
    linkedTo === LINKED_TO_TYPE.DEPARTMENT
      ? t(
          "myVolopay.actionCentre.sliders.rejectCardOrderDetailsSummary.department"
        )
      : t(
          "myVolopay.actionCentre.sliders.rejectCardOrderDetailsSummary.project"
        )
  } ${project?.name}`;

  const config = [
    {
      label:
        "myVolopay.actionCentre.sliders.rejectCardOrderDetailsSummary.cardHolder",
      value: cardHolderName,
      show: !isValueUndefinedOrNull(cardHolderName),
    },
    {
      label:
        "myVolopay.actionCentre.sliders.rejectCardOrderDetailsSummary.amount",
      value: `${limit} ${currency}`,
      show: !isValueUndefinedOrNull(limit),
    },
    {
      label:
        "myVolopay.actionCentre.sliders.rejectCardOrderDetailsSummary.frequency",
      value: CARD_FREQUENCY_LABEL[frequency],
      show: !isValueUndefinedOrNull(frequency),
    },
    {
      label:
        "myVolopay.actionCentre.sliders.rejectCardOrderDetailsSummary.linkedTo",
      value: linkedToText,
      show: !isValueUndefinedOrNull(linkedTo),
    },
  ];

  function isValueUndefinedOrNull(value) {
    return value === undefined || value === null;
  }

  return <FormSummaryInfo formFields={config} />;
}

RejectCardOrderDetailsSummary.propTypes = {
  data: PropTypes.object,
};
