import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  fetchUserNotifications,
  readAllUserNotifications,
  resetNotifications,
  setMessagesTabsCount,
  setNotificationTabsCount,
  setUserNotificationCurrentTab,
} from "@/store/reducers/user";

import {
  isExternalAccountantSelector,
  isFetchingUserNotificationsSelector,
  isLoadingUserNotificationsSelector,
  unreadNotificationTabsCountSelector,
  unreadNotificationTabsCurrentTabSelector,
  userNotificationsHasMoreSelector,
  userNotificationsListSelector,
  userNotificationsUnreadIdsSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import EmptyData from "@/components/core/EmptyData";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Tabs from "@/components/core/Tabs";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT_SHORT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import { USER_INBOX_ITEM_STATUS, USER_INBOX_TABS } from "@/constants/user";

import MessageListingComponent from "../MessageListingComponent";
import UserNotificationsListingComponent from "../UserNotificationsListingComponent";

export default function UserNotificationsAndMessages({ setOnClose }) {
  const notificationsData = useSelector(userNotificationsListSelector);
  const unreadIds = useSelector(userNotificationsUnreadIdsSelector);
  const hasMore = useSelector(userNotificationsHasMoreSelector);
  const isFetching = useSelector(isFetchingUserNotificationsSelector);
  const isLoading = useSelector(isLoadingUserNotificationsSelector);
  const unreadTabsCount = useSelector(unreadNotificationTabsCountSelector);
  const notificationCount = unreadTabsCount?.notifications;
  const messageCount = unreadTabsCount?.messages;
  const [inboxTabs, setInboxTabs] = useState(USER_INBOX_TABS);
  const [shaddedNotificationIds, setShaddedNotificationIds] = useState([]);
  const selectedTab = useSelector(unreadNotificationTabsCurrentTabSelector);
  const pageLimit = PAGINATION_PER_REQUEST_LIMIT_SHORT;

  const handleUnreadCountChange = () => {
    const updatedInboxTab = inboxTabs.map((tab, index) => {
      return {
        ...tab,
        count: index === 0 ? notificationCount : messageCount,
      };
    });

    setInboxTabs(updatedInboxTab);
  };

  const dispatch = useDispatch();

  const handleReadNotifications = () => {
    const newArray = notificationsData?.slice(
      (pageNum - 2) * pageLimit,
      (pageNum - 1) * pageLimit
    );

    const updatedNotificationStatusIds = newArray
      ?.filter(
        (notificationWidgetInfo, index) =>
          notificationWidgetInfo?.status === USER_INBOX_ITEM_STATUS.UNREAD
      )
      ?.map((messageData) => messageData?.id);

    updateTabsCount(updatedNotificationStatusIds);

    setShaddedNotificationIds([...updatedNotificationStatusIds]);

    const payload = {
      notification_ids: updatedNotificationStatusIds,
    };

    dispatch(
      readAllUserNotifications({
        params: payload,
        onSuccess: fetchNotificationRows,
      })
    );
  };

  const navigate = useNavigate();

  const isExternalAccountant = useSelector(isExternalAccountantSelector);

  const hideNotificationPreferencesCtas =
    window.location.pathname === ROUTES.notifications.absolutePath ||
    selectedTab?.key !== 0 ||
    isExternalAccountant;

  const loadMore = () => {
    handleReadNotifications();
  };

  const onReset = () => {
    dispatch(resetNotifications());
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    inSlider: true,
    filterOptions: { key: selectedTab?.key },
  });

  useEffect(() => {
    setOnClose((searchParamsArray) => {
      if (!searchParamsArray.includes(SLIDERS_SEARCH_PARAMS.inbox)) {
        makeReadMessages();
        dispatch(setUserNotificationCurrentTab(inboxTabs?.[0]));
      }
    });
  }, [notificationsData, pageNum]);

  useEffect(() => {
    if (!hasMore) {
      makeReadMessages();
    }
  }, [hasMore]);

  useEffect(() => {
    setShaddedNotificationIds([]);
  }, [selectedTab]);

  useEffect(() => {
    handleUnreadCountChange();
  }, [shaddedNotificationIds, unreadTabsCount]);

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  const handleClearAll = () => {
    const payload = {
      all: true,
      type: "bell",
    };
    dispatch(readAllUserNotifications({ params: payload }));
  };

  const ref = useLeftHeaderTitle(
    {
      title: "user.notificationsAndMessages.inbox",
      icon: "Inbox",
      titleStyle: "text-xl font-bold text-neutral-800",
      iconStyle: "text-primary-500 w-9 h-9 p-2.5 bg-primary-50 rounded-full",
    },
    {},
    true
  );

  function fetchNotificationRows() {
    const params = {
      page: pageNum,
      limit: pageLimit,
      onSuccess: (data) => {
        const unreadNotificationIds = data
          ?.filter((notifictionItem) => notifictionItem?.status === "unread")
          ?.map((item) => item?.id);

        setShaddedNotificationIds(unreadNotificationIds);
      },
    };

    if (selectedTab?.key === 1) {
      params.channel = "message";
    }

    dispatch(fetchUserNotifications(params));
  }

  function makeReadMessages() {
    const currentPageData = notificationsData
      ?.filter(
        (data, index) =>
          index >= (pageNum - 1) * pageLimit &&
          index < pageNum * pageLimit &&
          data?.status === USER_INBOX_ITEM_STATUS.UNREAD
      )
      ?.map((data) => data?.id);

    updateTabsCount(currentPageData);

    if (currentPageData?.length > 0) {
      dispatch(
        readAllUserNotifications({
          params: { notification_ids: currentPageData },
        })
      );
    }
  }

  function updateTabsCount(currentPageData = []) {
    dispatch(
      selectedTab?.key === 0
        ? setNotificationTabsCount(notificationCount - currentPageData.length)
        : setMessagesTabsCount(messageCount - currentPageData.length)
    );
  }

  return (
    <>
      <Tabs
        classes="bg-white px-9"
        items={inboxTabs}
        selectedTab={selectedTab?.key}
        mode
        setCurrentTab={(tab) => dispatch(setUserNotificationCurrentTab(tab))}
        disabled={isFetching || isLoading}
      />
      <div ref={ref} className=" slider-content-core">
        <div className="flex flex-col w-full h-full gap-4">
          {selectedTab?.key === 0 ? (
            <UserNotificationsListingComponent
              notificationsData={notificationsData}
              unreadIds={unreadIds}
              isFetching={isFetching}
              isLoading={isLoading}
              hasMore={hasMore}
              handleRefChange={handleRefChange}
              shaddedNotificationIds={shaddedNotificationIds}
            />
          ) : (
            <MessageListingComponent
              data={notificationsData}
              shaddedNotificationIds={shaddedNotificationIds}
              handleRefChange={handleRefChange}
              hasMore={hasMore}
              setShaddedNotificationIds={setShaddedNotificationIds}
            />
          )}

          {isFetching || isLoading
            ? [...Array(5)].map((_, index) => (
                <div className="p-2" key={`pending-table-loader-item-${index}`}>
                  <LoaderSkeleton fullWidth />
                </div>
              ))
            : null}

          {notificationsData?.length === 0 && !(isFetching || isLoading) ? (
            <EmptyData
              title={
                selectedTab?.key === 0
                  ? "user.notificationsAndMessages.notification.emptyDataText"
                  : "user.notificationsAndMessages.messages.emptyDataText"
              }
            />
          ) : null}
        </div>
      </div>

      <div
        className={`flex px-5 items-center ${
          hideNotificationPreferencesCtas ? "justify-end" : "justify-between"
        } gap-6 py-5 font-semibold slider-footer `}
      >
        {!hideNotificationPreferencesCtas ? (
          <Button
            preIcon="EditNotification"
            label="settings.manageType.notifications"
            variant="tertiary"
            classes="text-neutral-500 px-5 py-4 text-btn-lg"
            onClick={() => {
              navigate(ROUTES.notifications.absolutePath);
            }}
            compact
          />
        ) : null}

        <div>
          <Button
            label="user.notificationsAndMessages.btnLabels.clearAll"
            variant="tertiary"
            classes="w-fit px-5  font-semibold text-neutral-500 px-5 py-4 text-btn-lg"
            disabled={
              !(selectedTab?.key === 0
                ? notificationCount > 0
                : messageCount > 0)
            }
            onClick={() => {
              handleClearAll();
              setShaddedNotificationIds([]);
            }}
            showLoader={isLoading}
            compact
          />
        </div>
      </div>
    </>
  );
}
