import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  fetchPeople,
  fetchPeopleShallow,
  setSelectedDepartment,
  setSelectedProject,
  updateProject,
} from "@/store/reducers/company";

import {
  allPeopleSelector,
  isFetchingPeopleSelector,
} from "@/store/selectors/company";
import {
  arraysHaveSameContentUnordered,
  calculateAddsAndDeletes,
} from "@/utils/common";

import { DEPARTMENT, LOCATION, PROJECT } from "@/constants/company";
import { BE_CTA_RESPONSE } from "@/constants/user";

import PeopleTabDept from "./PeopleTabDept";
import PeopleTabLocation from "./PeopleTabLocation";
import PeopleTabProj from "./PeopleTabProj";

/**
 * People tab of a department (or +3 variations) slider
 *
 * @param {String} sliderData /${id} API data of parent slider
 * @param {String} parentSliderType current slider env (Tab is rendering as Department/Project/BudgetProject)
 */
export default function PeopleTab({ sliderData = null, parentSliderType }) {
  const dispatch = useDispatch();
  const people = useSelector(allPeopleSelector);
  const isFetching = useSelector(isFetchingPeopleSelector);
  const navigate = useNavigate();
  // fetch data, prepare callbacks for dumb components below (children)
  const onSuccess = (response) => {
    if (parentSliderType === DEPARTMENT) {
      dispatch(setSelectedDepartment(response));
    } else {
      dispatch(setSelectedProject(response));
    }
  };

  const showManageMemberCta = sliderData?.ctas?.some(
    (cta) => cta === BE_CTA_RESPONSE.MANAGE_PEOPLE
  );
  const showManageManagerCta = sliderData?.ctas?.some(
    (cta) => cta === BE_CTA_RESPONSE.EDIT_DETAILS
  );

  const handleSave = (value) => {
    let payload = null;
    if (parentSliderType === PROJECT) {
      const newManagerData = value.managers;
      const oldManagerData = sliderData.projectManagers.map((i) => i.userId);

      const newMemberData = value.members;
      const oldMemberData = sliderData.projectMembers.map((i) => i.userId);

      const { added: addedManagers, removed: deletedManagers } =
        calculateAddsAndDeletes(newManagerData, oldManagerData);

      const { added: addedMembers, removed: deletedMembers } =
        calculateAddsAndDeletes(newMemberData, oldMemberData);

      let payloadArr = [];
      payloadArr = [
        ...payloadArr,
        ...addedManagers.map((item) => ({
          user_id: item,
          member_type: "owner",
        })),
      ];
      payloadArr = [
        ...payloadArr,
        ...deletedManagers.map((userId) => {
          const temppeople = sliderData.projectManagers?.find((item) => {
            return item.userId === userId;
          });

          return {
            id: temppeople?.id,
            archived: true,
          };
        }),
      ];
      payloadArr = [
        ...payloadArr,
        ...addedMembers.map((item) => ({
          user_id: item,
          member_type: "member",
        })),
      ];
      payloadArr = [
        ...payloadArr,
        ...deletedMembers.map((userId) => {
          const temppeople = sliderData.projectMembers?.find((item) => {
            return item.userId === userId;
          });
          return {
            id: temppeople?.id,
            archived: true,
          };
        }),
      ];

      payload = {
        project_members_attributes: payloadArr,
        department: parentSliderType === DEPARTMENT,
      };
    } else if (parentSliderType === DEPARTMENT) {
      const newManagerData = value.managers;
      const oldManagerData = sliderData.projectManagers.map((i) => i.userId);

      const { added: addedManagers, removed: deletedManagers } =
        calculateAddsAndDeletes(newManagerData, oldManagerData);

      let payloadArr = [];
      payloadArr = [
        ...payloadArr,
        ...addedManagers.map((item) => ({
          user_id: item,
          member_type: "owner",
        })),
      ];
      payloadArr = [
        ...payloadArr,
        ...deletedManagers.map((userId) => {
          const temppeople = sliderData.projectManagers?.find((item) => {
            return item.userId === userId;
          });

          return {
            id: temppeople?.id,
            archived: true,
          };
        }),
      ];

      payload = {
        project_members_attributes: payloadArr,
        department: parentSliderType === DEPARTMENT,
      };
    }

    dispatch(updateProject({ id: sliderData?.id, payload, onSuccess }));
  };

  useEffect(() => {
    const isLocation = parentSliderType === LOCATION;
    dispatch(
      fetchPeopleShallow({
        shallow: true,
        ...(isLocation ? { location: sliderData?.id } : {}),
      })
    );
  }, []);

  const ProjectMembers = sliderData?.projectMembers?.map((user) => ({
    ...user,
    id: user.userId,
    name: user.userName,
    role: user.userRole,
    colorCode: user?.colorCode,
    avatarUrl: user?.avatarUrl,
  }));
  const ProjectManagers = sliderData?.projectManagers?.map((user) => ({
    ...user,
    id: user.userId,
    name: user.userName,
    role: user.userRole,
    colorCode: user?.colorCode,
    avatarUrl: user?.avatarUrl,
  }));
  let Component = null;
  switch (parentSliderType) {
    case DEPARTMENT:
      Component = (
        <PeopleTabDept
          isFetching={isFetching}
          people={people ?? []}
          departmentManagers={ProjectManagers ?? []}
          departmentMembers={ProjectMembers ?? []}
          handleSave={handleSave}
          parentSliderType={parentSliderType}
          navigate={navigate}
          showManageManagerCta={showManageManagerCta}
          showManageMemberCta={showManageMemberCta}
        />
      );
      break;
    case PROJECT:
      Component = (
        <PeopleTabProj
          isFetching={isFetching}
          people={people}
          projectMembers={ProjectMembers ?? []}
          projectOwners={ProjectManagers ?? []}
          handleSave={handleSave}
          showManageManagerCta={showManageManagerCta}
          showManageMemberCta={showManageMemberCta}
        />
      );
      break;
    case LOCATION:
      Component = (
        <PeopleTabLocation
          isFetching={isFetching}
          people={people}
          navigate={navigate}
          showManageMemberCta={showManageMemberCta}
        />
      );
      break;
    default:
      break;
  }
  return Component;
}

PeopleTab.propTypes = {
  sliderData: PropTypes.object,
  parentSliderType: PropTypes.string, // "department" | "project" | "budget" | "location"
};
