import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import to from "await-to-js";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { updateAccountingData } from "@/store/reducers/accounting_transactions";
import {
  approveRequest,
  downloadAttachment,
  setIndexApiReload,
} from "@/store/reducers/app";
import { addComments, fetchComments } from "@/store/reducers/comments";
import { fetchDepartments, fetchLocations } from "@/store/reducers/company";
import {
  deleteExpenseAttachment,
  dismissSubmissionPolicy,
  fetchAndSelectExpense,
  fetchExpenseSplit,
  fetchSelectedExpenseActivity,
  remindExpense,
  requestReceived,
  setAttachment,
  setSelectedExpense,
  syncedExpense,
  unverifyExpense,
  updateExpense,
  uploadReceipts,
  verifyExpense,
} from "@/store/reducers/expense";
import { fetchTags } from "@/store/reducers/tags";

import { indexApiReloadSelector } from "@/store/selectors/app";
import { categoriesListSelector } from "@/store/selectors/categories";
import {
  commentsHasMoreSelector,
  commentsListSelector,
} from "@/store/selectors/comments";
import {
  expenseSplitSelector,
  isFetchingExpenseActivitySelector,
  isFetchingExpenseSelector,
  selectedExpenseIsApprovedSelector,
  selectedExpenseSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { submissionPolicyObjectSelector } from "@/store/selectors/policy";
import { userSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Tabs from "@/components/core/Tabs";

import ExpenseSliderFooter from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayFooter";
import { ExpenseAndQRPayHeaderJsx } from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayHeader";
import ExpenseHistory from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayHistory";
import ExpenseOverviewTab from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayOverviewTab";
import { COMMENTS_PAGINATION_LIMIT_PER_REQUEST } from "@/utils/constants/payments";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import { NOT_OKAY } from "@/utils/constants/expenses";
import { COMMENT_TYPE, COMMENT_TYPE_PAYLOAD } from "@/utils/constants/comments";
import { createFileFormData, dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_TRANSACTION_STATUS } from "@/constants/accounting";
import { ADMIN_ROLE } from "@/constants/common";
import {
  ADD_FUNDS,
  CARDS_MODULE_EXPENSE_SLIDER,
  DECLINED,
  DECLINE_CODES,
  DECLINE_EXPENSE_ALERT_CTA,
  DECLINE_EXPENSE_ALERT_CTA_TEXT,
  DECLINE_SLIDER_ALERT,
  EXPENSES_PATH_NAMES,
  EXPENSE_CTA_KEYS,
  EXPENSE_SETTLEMENT_STATUS,
  EXPENSE_STATUSES,
  HISTORY,
  INSUFFICIENT_DEPT_LIMIT,
  INSUFFICIENT_DEPT_LIMIT_ADMIN,
  INSUFFICIENT_PROJECT_LIMIT,
  INSUFFICIENT_PROJECT_LIMIT_ADMIN,
  LOW_ACCOUNT_BALANCE,
  OVERVIEW,
  SETTLED,
  SUBMISSION_POLICY_STATUS_KEYS,
  TABS,
  TRANSACTION_TYPE_EXPENSES_OVERVIEW,
  UNSETTLED,
} from "@/constants/expense";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import API from "@/api";

export default function ExpenseSlider({
  isAccountingSlider = false,
  setOnClose,
  setOnBack,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const expense = useSelector(selectedExpenseSelector);

  const categories = useSelector(categoriesListSelector);
  const isFetchingExpense = useSelector(isFetchingExpenseSelector);
  const splitExpense = useSelector(expenseSplitSelector);
  const submissionPolicy =
    useSelector(submissionPolicyObjectSelector) || expense?.submissionPolicy;
  const isFetching = useSelector(isFetchingExpenseActivitySelector);
  const isApproved = useSelector(selectedExpenseIsApprovedSelector);
  const currentUser = useSelector(userSelector);
  const [isApproveProcessing, setIsApproveProcessing] = useState(false);
  const [expenseId, setExpenseId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(TABS?.[0]);
  const [files, setFiles] = useState([]);
  const [memo, setMemo] = useState(expense ? expense.memo : "");
  const isDeclineExpense = expense?.transactionType === DECLINED;
  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const [pageNum, setPageNum] = useState(1);
  const [flaggedCommentObj, setFlaggedCommentObj] = useState({});

  // comments
  const commentsList = useSelector(commentsListSelector);
  const commentHasMore = useSelector(commentsHasMoreSelector);

  const appliedFilters = useSelector(appliedFilterSelector);
  const handleFetchExpenseParam = () => {
    switch (window.location.pathname) {
      case EXPENSES_PATH_NAMES.FLAGGED:
        return { status: [EXPENSE_STATUSES.NOT_OKAY] };
      case EXPENSES_PATH_NAMES.NEEDS_REVIEW:
        return { user_approvals: currentUser?.id };
      case EXPENSES_PATH_NAMES.DECLINED:
        return { transaction_type: DECLINED };
      case EXPENSES_PATH_NAMES.OVERVIEW:
        return {
          settlement_status:
            appliedFilters[AVAILABLE_FILTER_KEYS.settlementStatus]?.value ===
            UNSETTLED
              ? UNSETTLED
              : SETTLED,
          transaction_type: TRANSACTION_TYPE_EXPENSES_OVERVIEW.PURCHASE,
        };
      default:
        return null;
    }
  };
  const refreshExpenseData = (success) => {
    dispatch(setIndexApiReload(!reloadIndexApi));
  };

  const fileUploadHandler = async (latestFileArray) => {
    const filesToUpload = latestFileArray?.filter((item) => {
      return item instanceof File && item.type !== "image/svg+xml";
    });

    if (filesToUpload?.length === 0) return;
    const formPayload = createFileFormData(filesToUpload, "receipts[]");
    dispatch(
      uploadReceipts({
        expenseId,
        payload: formPayload,
        onSuccess: (data) => {
          dispatch(setIndexApiReload(!reloadIndexApi));
          setFiles(data?.receipts);
        },
      })
    );
  };

  const primaryAction = {
    handler: (index) => {
      if (files[index]?.id) {
        dispatch(
          deleteExpenseAttachment({
            id: files[index]?.id,
            onSuccess: () => {
              const remainingFiles = files?.filter(
                (i) => i?.id !== files[index]?.id
              );
              // ADDED becuase delete attachment is common api and it does not return updated data
              // needed to handle in FE
              if (expense?.receiptRequired && !remainingFiles?.length)
                dispatch(
                  setSelectedExpense({
                    ...expense,
                    submissionPolicyStatus:
                      SUBMISSION_POLICY_STATUS_KEYS.NOT_MET,
                    missingFields: {
                      ...expense?.missingFields,
                      receipt_required: true,
                    },
                  })
                );
              dispatch(setAttachment(remainingFiles));
              dispatch(setIndexApiReload(!reloadIndexApi));
            },
          })
        );
      }
    },
    label: "misc.delete",
    icon: "Delete",
    iconClasses: "text-danger-600 bg-danger-50",
  };

  const secondaryAction = {
    handler: (index) => {
      const file = files[index];

      if (file instanceof File) return;
      dispatch(
        downloadAttachment({
          file,
          id: file?.id,
        })
      );
    },
    label: "misc.download",
    icon: "Download",
    iconClasses: "!text-primary-500 !bg-primary-50", // some issue with SVG, using !
  };

  const onSuccess = () => {
    refreshExpenseData();
    dispatch(
      fetchExpenseSplit({
        expenseId,
      })
    );
    dispatch(fetchSelectedExpenseActivity({ expenseId }));
    if (expense?.hasComments && expenseId) {
      dispatch(
        fetchComments({
          type: COMMENT_TYPE_PAYLOAD.EXPENSE,
          id: expenseId,
          page: 1,
          limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
        })
      );
    }
  };

  const handleFooterCtaClick = async (key) => {
    setIsApproveProcessing(true);
    const id = parseInt(
      searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.id) ||
        searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.cards.id),
      10
    );
    let payload = {};
    if (id) {
      switch (key) {
        case EXPENSE_CTA_KEYS.FLAG:
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.expenses.flagTransaction,
            id
          );
          setSearchParams(searchParams);
          break;
        case EXPENSE_CTA_KEYS.REPAYMENT_CANCELLED:
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.expenses.cancelRepayment,
            id
          );
          setSearchParams(searchParams);
          break;
        case EXPENSE_CTA_KEYS.REPAYMENT_DECLINED:
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.expenses.repaymentDeclined,
            id
          );
          setSearchParams(searchParams);
          break;
        case EXPENSE_CTA_KEYS.REQUEST_REPAYMENT:
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.expenses.requestRepayment,
            id
          );
          setSearchParams(searchParams);
          break;
        case EXPENSE_CTA_KEYS.APPROVE:
          dispatch(
            approveRequest({
              payload: { target_id: id, type: "expense" },
              onSuccess: (...args) => {
                onSuccess(...args);
                dispatch(fetchAndSelectExpense({ expenseId }));
              },
            })
          );
          break;
        case EXPENSE_CTA_KEYS.REPAYMENT_RECEIVED:
          dispatch(
            requestReceived({
              id,
              onSuccess: () => {
                dispatch(fetchAndSelectExpense({ expenseId }));
              },
            })
          );
          break;
        case EXPENSE_CTA_KEYS.ACCOUNTING_VERIFY:
          dispatch(
            verifyExpense({
              id: expense?.accountingId,
              onSuccess: () => {
                dispatch(fetchAndSelectExpense({ expenseId }));
              },
            })
          );
          break;
        case EXPENSE_CTA_KEYS.ACCOUNTING_SYNC:
          dispatch(
            syncedExpense({
              id: expense?.accountingId,
              onSuccess: () => {
                dispatch(fetchAndSelectExpense({ expenseId }));
              },
            })
          );
          break;
        case EXPENSE_CTA_KEYS.ACCOUNTING_UNVERIFY:
          dispatch(
            unverifyExpense({
              id: expense?.accountingId,
              onSuccess: () => {
                dispatch(fetchAndSelectExpense({ expenseId }));
              },
            })
          );
          break;
        case EXPENSE_CTA_KEYS.MARK_AS_PAID:
          payload = {
            status: ACCOUNTING_TRANSACTION_STATUS.SYNCED,
          };
          dispatch(
            updateAccountingData({
              accounting_id: expense?.accountingId,
              payload,
            })
          );
          break;
        default:
          break;
      }
    }
    setIsApproveProcessing(false);
  };

  const loadMoreComments = () => {
    setPageNum((prev) => prev + 1);
    dispatch(
      fetchComments({
        type: COMMENT_TYPE_PAYLOAD.EXPENSE,
        id: expenseId,
        page: pageNum + 1,
        limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
      })
    );
  };

  const handleUpdateExpense = (payload) => {
    if (expenseId)
      dispatch(
        updateExpense({
          expenseId,
          payload,
          onSuccess: (success) => {
            refreshExpenseData(success);
            dispatch(fetchSelectedExpenseActivity({ expenseId }));
            if (expense?.hasComments && expenseId) {
              dispatch(
                fetchComments({
                  type: COMMENT_TYPE_PAYLOAD.EXPENSE,
                  id: expenseId,
                  page: 1,
                  limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
                })
              );
            }
          },
        })
      );
  };

  const remindPeople = (key, expense_id) => {
    dispatch(remindExpense({ payload: { [key]: expense_id } }));
  };

  const dismissPolicy = (id) => {
    dispatch(
      dismissSubmissionPolicy({
        expenseId: id,
        onSuccess: () => {
          dispatch(fetchAndSelectExpense({ expenseId }));
        },
      })
    );
  };

  const createNewComment = (formData) => {
    formData.append("owner_type", COMMENT_TYPE_PAYLOAD.EXPENSE);
    formData.append("owner_id", expense?.id);

    dispatch(
      addComments({
        payload: formData,
        onSuccess: () => {
          setPageNum(1);
          dispatch(
            fetchComments({
              type: COMMENT_TYPE_PAYLOAD.EXPENSE,
              id: expenseId,
              page: 1,
              limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
            })
          );
        },
      })
    );
  };

  const getTransalationPropKey = (key) => {
    switch (key) {
      case DECLINE_CODES.FROZEN_CARD:
      case DECLINE_CODES.CARD_FROZEN_BY_EMPLOYEE:
        return {
          date: expense?.freezedAt,
          role: expense?.cardHolder?.roles[0],
          firstName: expense?.cardHolder?.firstName,
        };
      case DECLINE_CODES.BLOCKED_CARD:
      case DECLINE_CODES.AUTO_BLOCKED:
        return {
          date: expense?.blockedAt,
          role: expense?.cardHolder?.roles[0],
          firstName: expense?.cardHolder?.firstName,
        };

      default:
        return {};
    }
  };
  const getCtaLabel = (cta) => {
    switch (cta) {
      case DECLINE_CODES.INSUFFICIENT_DEPT_LIMIT:
        if (expense?.cardHolder?.roles[0].toLowerCase() === ADMIN_ROLE) {
          return DECLINE_EXPENSE_ALERT_CTA_TEXT[INSUFFICIENT_DEPT_LIMIT_ADMIN];
        }
        return DECLINE_EXPENSE_ALERT_CTA[INSUFFICIENT_DEPT_LIMIT];
      case DECLINE_CODES.INSUFFICIENT_PROJECT_LIMIT:
        if (expense?.cardHolder?.roles[0].toLowerCase() === ADMIN_ROLE) {
          return DECLINE_EXPENSE_ALERT_CTA_TEXT[
            INSUFFICIENT_PROJECT_LIMIT_ADMIN
          ];
        }
        return DECLINE_EXPENSE_ALERT_CTA[INSUFFICIENT_PROJECT_LIMIT];

      case DECLINE_CODES.LOW_ACCOUNT_BALANCE:
        if (expense?.cardHolder?.roles[0].toLowerCase() === ADMIN_ROLE) {
          return DECLINE_EXPENSE_ALERT_CTA_TEXT[ADD_FUNDS];
        }
        return DECLINE_EXPENSE_ALERT_CTA_TEXT[LOW_ACCOUNT_BALANCE];
      default:
        return DECLINE_EXPENSE_ALERT_CTA[cta] ?? "";
    }
  };
  const getNavigationforCta = (cta) => {
    switch (cta) {
      case DECLINE_CODES.LOW_CARD_BALANCE:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.editLimit,
          expense?.cardId
        );
        break;
      case DECLINE_CODES.CARD_INACTIVE:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.activation,
          expense?.cardId
        );
        break;
      case DECLINE_CODES.FROZEN_CARD:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.freeze,
          expense?.cardId
        );
        break;
      case DECLINE_CODES.BLOCKED_CARD:
        searchParams.append(SLIDERS_SEARCH_PARAMS.cards.block, expense?.cardId);
        break;
      case DECLINE_CODES.INSUFFICIENT_DEPT_LIMIT:
        if (expense?.cardHolder?.roles[0].toLowerCase() === ADMIN_ROLE) {
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.company.department.id,
            expense?.project?.id
          );
        }
        /* logic for  notify admin */ break;
      case DECLINE_CODES.INSUFFICIENT_PROJECT_LIMIT:
        if (expense?.cardHolder?.roles[0].toLowerCase() === ADMIN_ROLE) {
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.company.project.id,
            expense?.project?.id
          );
        }
        /* logic notify admin */ break;
      case DECLINE_CODES.LOW_ACCOUNT_BALANCE:
        if (expense?.cardHolder?.roles[0]?.toLowerCase() === ADMIN_ROLE) {
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.company.fundAccount,
            expense?.currency
          );
        }
        /* logic notify admin */
        break;
      default:
        break;
    }
    setSearchParams(searchParams);
  };
  /*
  useEffects
  */
  useEffect(() => {
    dispatch(fetchAccountingVendors());
    dispatch(fetchTags({ visible: true }));
    dispatch(
      fetchDepartments({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT })
    );
    dispatch(fetchLocations({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT }));

    // This event is triggered when we click the close icon
    setOnClose((searchParamArray) => {
      if (!searchParamArray?.includes(SLIDERS_SEARCH_PARAMS.expenses.id)) {
        clearSelectedExpense();
      }
    });

    // This event is triggered when we click the left icon of global slider
    setOnBack(() => {
      clearSelectedExpense();
    });
  }, []);

  useEffect(() => {
    setFiles(expense?.receipts ?? []);
    setMemo(expense?.memo);
  }, [expense]);

  useEffect(() => {
    if (!expense) return;

    if (expense?.hasComments && expenseId)
      dispatch(
        fetchComments({
          type: COMMENT_TYPE_PAYLOAD.EXPENSE,
          id: expenseId,
          page: 1,
          limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
        })
      );

    async function fetchFlaggedComment() {
      const response = await to(
        API.Misc.getComments({
          type: COMMENT_TYPE_PAYLOAD.EXPENSE,
          id: expenseId,
          page: 1,
          limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
          comment_type: expense?.actionBy?.userId
            ? COMMENT_TYPE.FLAGGED
            : COMMENT_TYPE.AUTO_FLAGGED,
        })
      );

      setFlaggedCommentObj(response[1]?.data?.list?.[0]);
    }

    if (expenseId) fetchFlaggedComment();
  }, [expense, expenseId, expense?.hasComments]);

  useEffect(() => {
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.id))
      setExpenseId(
        parseInt(searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.id), 10)
      );
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.cards.id))
      setExpenseId(
        parseInt(
          searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.cards.id),
          10
        )
      );
  });

  useEffect(() => {
    if (expenseId && !expense) {
      dispatch(
        fetchAndSelectExpense({
          expenseId,
        })
      );

      dispatch(
        fetchExpenseSplit({
          expenseId,
        })
      );
    }
  }, [expenseId, expense]);

  const allState = useLoadingErrorInjector({
    apiKey: "Expenses:get",
    showLoader: false,
    error: {
      header: "expenses.ExpenseSlider",
      // classes: "flex flex-col items-center justify-center font-bold text-lg",
    },
  });

  function clearSelectedExpense() {
    dispatch(setSelectedExpense(null));
  }

  const scrollToComments = () => {};
  return (
    <>
      <div className="slider-content-core scroll-smooth" ref={allState?.attach}>
        {!isFetchingExpense && expense ? (
          <div className="pb-16 mb-6">
            <div className="flex items-center justify-between">
              <ExpenseAndQRPayHeaderJsx
                isExpenseStatusHidden={isDeclineExpense} // for declined transactionType
                exchangeRate={expense?.exchangeRate}
                transactionAmount={expense?.transactionAmount}
                transactionCurrencyCode={expense?.transactionCurrencyCode}
                settlementStatus={expense?.settlementStatus}
                img={expense?.merchant?.url}
                name={expense?.vendor?.name ?? expense?.merchant?.name}
                amount={expense?.amount}
                currency={expense?.currency}
                transactionStatus={expense?.transactionStatus}
                updatedAt={expense?.expenseDate}
                cashback={expense.cashback}
                cashbackCurrency={expense.currency}
                cashbackPercent={expense.cashback.rate}
                merchantName={expense?.globalMerchant}
                profileWidgetUrl={expense?.merchant?.logoUrl}
                transactionType={expense?.transactionType}
              />
            </div>

            {expense?.accountingStatus ===
              ACCOUNTING_TRANSACTION_STATUS.SYNCED ||
            expense?.accountingStatus ===
              ACCOUNTING_TRANSACTION_STATUS.VERIFIED ? (
              <div className="mt-6">
                <Alert
                  variant="neutral"
                  title="expenses.alertMessages.transactionLogTitle"
                  description={
                    expense?.accountingStatus ===
                    ACCOUNTING_TRANSACTION_STATUS.VERIFIED
                      ? "You will not be able to edit any details for this expense because it has been verified by your accountant"
                      : "expenses.alertMessages.transactionLogDesc"
                  }
                  textClasses="font-semibold"
                  icon="Lock"
                  iconClasses="mt-0.5"
                  wrapperDivClass="justify-start"
                />
              </div>
            ) : null}

            {expense?.parent &&
            expense?.parent?.settlementStatus ===
              EXPENSE_SETTLEMENT_STATUS.SETTLED ? (
              <div className="mt-6">
                <Alert
                  variant="neutral"
                  title="expenses.alertMessages.reversal.title"
                  description="expenses.alertMessages.reversal.description"
                  descriptionTransalationProp={{
                    amount: expense?.parent?.amount,
                    currency: expense?.parent?.currency,
                    date: dateToString(expense?.parent?.expenseDate),
                  }}
                  textClasses="font-semibold"
                  icon="Info"
                  iconClasses="mt-0.5"
                  wrapperDivClass="justify-start"
                  primaryAction={{
                    label:
                      "expenses.alertMessages.settlementReleased.buttonTitle",
                    classes: "card-wrapper px-5 py-1.5 text-primary-500 mb-2",
                    callback: () => {
                      searchParams.set(
                        SLIDERS_SEARCH_PARAMS.expenses.id,
                        expense?.parent?.id
                      );
                      setSearchParams(searchParams);
                    },
                  }}
                  actionSectionClasses="mb-2.5"
                />
              </div>
            ) : null}

            {expense?.parent &&
            expense?.parent?.settlementStatus ===
              EXPENSE_SETTLEMENT_STATUS.RELEASED ? (
              <div className="mt-6">
                <Alert
                  variant="neutral"
                  title="expenses.alertMessages.settlementReleased.title"
                  description="expenses.alertMessages.settlementReleased.description"
                  descriptionTransalationProp={{
                    amount: expense?.parent?.amount,
                    currency: expense?.parent?.currency,
                    date: dateToString(expense?.parent?.expenseDate),
                  }}
                  primaryAction={{
                    label:
                      "expenses.alertMessages.settlementReleased.buttonTitle",
                    classes: "card-wrapper px-5 py-1.5 text-primary-500",
                    callback: () => {
                      searchParams.set(
                        SLIDERS_SEARCH_PARAMS.expenses.id,
                        expense?.parent?.id
                      );
                      setSearchParams(searchParams);
                    },
                  }}
                  textClasses="font-semibold"
                  icon="Info"
                  iconClasses="mt-0.5"
                  wrapperDivClass="justify-start"
                  actionSectionClasses="mb-3"
                />
              </div>
            ) : null}

            {isDeclineExpense && expense?.declineDetails?.code ? (
              <div className="mt-6">
                <Alert
                  wrapperDivClass="justify-start"
                  iconClasses="w-6 h-6 text-neutral-500"
                  variant="warning"
                  title={
                    DECLINE_SLIDER_ALERT[expense?.declineDetails?.code]?.title
                  }
                  description={
                    DECLINE_SLIDER_ALERT[expense?.declineDetails?.code]?.message
                  }
                  descriptionTransalationProp={getTransalationPropKey(
                    expense?.declineDetails?.code
                  )}
                />
              </div>
            ) : null}
            <div className="mt-5">
              <Tabs
                items={TABS}
                selectedTab={selectedTab?.key}
                setCurrentTab={setSelectedTab}
                mode
              />
            </div>
            {selectedTab.name === HISTORY && (
              <div className="mb-5">
                <ExpenseHistory expenseId={expenseId} isFetching={isFetching} />
              </div>
            )}
            {selectedTab.name === OVERVIEW && (
              <ExpenseOverviewTab
                isExpenseStatusHidden={isDeclineExpense} // for declined transactionType
                transaction={expense}
                memo={memo}
                isAccountingSlider={isAccountingSlider}
                handleMemoChange={setMemo}
                updateExpense={handleUpdateExpense}
                categories={categories}
                expenseId={expenseId}
                splitExpenses={splitExpense}
                remindPeople={remindPeople}
                dismissPolicy={dismissPolicy}
                submissionPolicy={submissionPolicy}
                commentsList={commentsList}
                isApproved={isApproved}
                files={files}
                fileUploadHandler={fileUploadHandler}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                createNewComment={createNewComment}
                currentUser={currentUser}
                page={CARDS_MODULE_EXPENSE_SLIDER}
                repaymentRequestStatus={expense?.repaymentRequestStatus}
                showLoadMoreCta={commentHasMore}
                loadMoreComments={loadMoreComments}
                flaggedCommentObj={flaggedCommentObj}
              />
            )}
          </div>
        ) : null}
      </div>
      {!isDeclineExpense && expense?.expenseCta?.length ? (
        <ExpenseSliderFooter
          expenseFooterCtas={expense?.expenseCta}
          // accountingFooterCtas={[
          //   ...expense?.accountingCtas,
          //   ...(expense?.accountingStatus ===
          //   ACCOUNTING_TRANSACTION_STATUS.SYNCED
          //     ? [expense?.accountingStatus]
          //     : []),
          // ]}
          isAccountingSlider={isAccountingSlider}
          onSubmit={handleFooterCtaClick}
          actionByName={expense?.actionBy?.name}
          scrollToComments={
            expense?.transactionStatus === NOT_OKAY
              ? null
              : expense?.hasComments
                ? scrollToComments
                : null
          }
        />
      ) : null}
      {/* in the figma design its not there */}
      {/* {expense?.transactionStatus !== OKAY ? (
        <div className="flex justify-end px-6 py-3 slider-footer">
          <Button
            preIcon="ModeComment"
            variant="tertiary"
            classes="w-fit text-neutral-500 px-5 py-3"
            onClick={scrollToComments}
          />
        </div>
      ) : null} */}
    </>
  );
}

ExpenseSlider.propTypes = {
  isAccountingSlider: PropTypes.bool,
  setOnClose: PropTypes.func,
};
