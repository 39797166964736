import PropTypes from "prop-types";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";

import { FILE_VIEWER_TYPE_ICON } from "@/constants/fileViewer";

/**
 * Renders the header of a modal.
 *
 * @param {Function} handleDownload - The function to handle the download action.
 * @param {Function} handleModalClose - The function to handle the modal close action.
 * @param {boolean} [showDownloadBtn=true] - Flag indicating whether to show the download button.
 * @param {boolean} hideHeader - Flag indicating whether to hide the header.
 * @param {Object} state - The state object.
 * @param {string} state.fileName - The name of the file.
 * @param {string} state.fileType - The type of the file.
 * @returns {ReactElement|null} The rendered header component.
 */

function ModalHeaderRenderer({
  handleDownload,
  handleModalClose,
  showDownloadBtn = true,
  hideHeader,
  state,
}) {
  const { fileName = "", fileType = "" } = state?.currentDocument ?? {};
  if (hideHeader) return null;
  const config = FILE_VIEWER_TYPE_ICON?.[fileType];
  return (
    <div className="cursor-pointer flex items-center gap-4 h-[9%] py-1/2 px-3">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2 " onClick={handleModalClose}>
          <Icon name="ArrowLeft" />
          <div className="flex items-center gap-2">
            <Icon className={config?.classes} name={config?.iconName ?? ""} />
            {fileName}
          </div>
        </div>
        {showDownloadBtn ? (
          <Button
            labelStyleClasses="text-sm"
            classes="w-14.75 h-8"
            onClick={handleDownload}
            preIcon="Download"
            label="Download"
          />
        ) : null}
      </div>
    </div>
  );
}

export default ModalHeaderRenderer;

ModalHeaderRenderer.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  showDownloadBtn: PropTypes.bool,
  hideHeader: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
};
