import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { cardProviderWithInternationalCardOrderEnabledSelector } from "@/store/selectors/client";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import OrderPhysicalCardButton from "@/components/Cards/PhysicalCardPageHelper/OrderPhysicalCardButton";

import { MY_PHYSICAL_CARDS_PAGE_TABS } from "@/constants/myVolopay";

function MyVolopayCardsPhysicalCardsPage() {
  const hasProviderWithInternationalCardOrderEnabled = useSelector(
    cardProviderWithInternationalCardOrderEnabledSelector
  );

  const [selectedTabs, setSelectedTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    hasProviderWithInternationalCardOrderEnabled
      ? MY_PHYSICAL_CARDS_PAGE_TABS.ALL
      : MY_PHYSICAL_CARDS_PAGE_TABS.ACTIVE
  );

  useEffect(() => {
    const requiredTabs = hasProviderWithInternationalCardOrderEnabled
      ? [
          MY_PHYSICAL_CARDS_PAGE_TABS.ALL,
          MY_PHYSICAL_CARDS_PAGE_TABS.YET_TO_ACTIVATE,
          MY_PHYSICAL_CARDS_PAGE_TABS.BLOCKED,
        ]
      : [
          MY_PHYSICAL_CARDS_PAGE_TABS.ACTIVE,
          MY_PHYSICAL_CARDS_PAGE_TABS.BLOCKED,
        ];

    setSelectedTabs(requiredTabs);
  }, [hasProviderWithInternationalCardOrderEnabled]);

  return (
    <div className="px-8">
      <div className="flex items-end justify-between pb-10">
        <div className="flex flex-col">
          <Text
            translationKey="cards.title.plural"
            color="neutral-500"
            classes="text-sm font-semibold"
          />
          <Text
            classes="text-4xl font-extrabold"
            translationKey="cards.pCards.title"
          />
        </div>
        <OrderPhysicalCardButton />
      </div>

      {selectedTabs?.length > 0 ? (
        <Tabs
          items={selectedTabs}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
        />
      ) : null}
    </div>
  );
}

export default MyVolopayCardsPhysicalCardsPage;
