import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  isLoadingSelector,
  userRolesListIsFetchingSelector,
} from "@/store/selectors/user";

import EmptyData from "@/components/core/EmptyData";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Note from "@/components/core/Note";
import Text from "@/components/core/Text";

import SettingFooter from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/Footer";
import PeopleList from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/PeopleList";
import { ROLES_TYPE } from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/SettingsType";
import UserDropdown from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/UserDropdown";
import { arraysHaveSameContentUnordered } from "@/utils/common";

/**
 * EditAddPeopleDumb Component - This component provides a UI for managing and editing a list of people.
 * @param {array} props.allUsers - List of all users
 * @param {array} props.peopleListItems - List of people to be displayed and managed
 * @param {object} props.metaData - Metadata for the UI
 * @param {boolean} props.editable - Indicates if the UI is in editable mode
 * @param {function} props.editButtonHandler - Handler for the "Edit" button
 * @param {function} props.onDiscard - Handler for discarding changes
 * @param {function} props.onSubmit - Handler for submitting changes
 * @param {function} props.setEditable - Function to set the editable state
 * @param {function} props.deletedIds - List of ids that are deleted from the peopleList
 * @param {function} props.setDeletedIds - handler for deleting ids
 * @param {function} props.deletedIds - List of ids that are deleted from the peopleList
 * @param {function} props.setDeletedIds - handler for deleting ids
 * @param {function} props.editablePeopleIds - List of ids that are added from the userDropdown
 * @param {function} props.seteditablePeopleIds - handler for adding ids in the array
 * @param {function} props.selectedPeople - List of ojects that we show in the peopleList while adding
 * @param {function} props.setSelectedPeople - handler for setting peopleList in the selectedPeople array for the first time and while deleting it should update
 * @param {string} props.dropdownLabelFlatView - The label for the dropdown
 * @param {boolean} props.isFetchingExistingList - Indicates if the component is fetching existing user data.
 * @param {string} props.type - Represents the type or category of users that are being managed and edited ex - apClerk, payrollInitiator etc
 */

const EditAddPeopleDumb = ({
  allUsers,
  peopleListItems = [],
  metaData,
  editable,
  editButtonHandler,
  onDiscard,
  onSubmit,
  setEditable,
  deletedIds,
  setDeletedIds,
  editablePeopleIds,
  seteditablePeopleIds,
  selectedPeople = [],
  setSelectedPeople,
  dropdownLabelFastView,
  dropdownLabelFlatView,
  isFetchingExistingList,
  type,
  groupAdminAndManager,
  hideButton,
  showTitleAndDescription = true,
  showCancelButton = false,
  cancelButtonHandler,
}) => {
  const {
    headerTitle,
    headerDescription,
    editTitle,
    editDescription,
    noteConfig,
    listTitle,
    listTitleTranslationProps,
    emptyDataTitle = "misc.noDataAvailable",
    emptyDataDescription = "",
    emptyButtonLabel,
    onClickHandler,
  } = metaData;

  const showNote = noteConfig?.hasNote && editable;
  const isFetchingUserList = useSelector(userRolesListIsFetchingSelector);
  const isLoading = useSelector(isLoadingSelector);

  const [isDisabled, setIsDisabled] = useState(false);

  const keepaAtLeastOneAdmin =
    (type === ROLES_TYPE.PAYROLL_ADMINS ||
      type === ROLES_TYPE.TOGGLE_PAYROLL) &&
    selectedPeople.length === 1;
  /**
   * Handles deletion of a user from the temporary existing list.
   * @param {number} id - The ID of the user to be deleted
   */

  const onDeleteHandler = (id) => {
    if (keepaAtLeastOneAdmin) {
      return;
    }
    setDeletedIds((prev) => [...prev, id]);
    setSelectedPeople((prev) => prev.filter((item) => item.itemId !== id));
  };

  /**
   * Handles the submission of user selection from the dropdown.
   * @param {object} selectedIds - An object containing selected user IDs
   */
  const dropdownSubmitHandler = ({ selectedIds: _selectedIds }) => {
    seteditablePeopleIds(_selectedIds);
  };

  useEffect(() => {
    if (peopleListItems?.length) setSelectedPeople(peopleListItems);
  }, [peopleListItems]);

  useEffect(() => {
    const _isDisabled =
      deletedIds.length !== 0
        ? false
        : arraysHaveSameContentUnordered(
            [
              ...editablePeopleIds,
              ...selectedPeople.map((item) => item?.itemId),
            ],
            peopleListItems.map((item) => item.itemId)
          );

    setIsDisabled(_isDisabled);
  }, [editablePeopleIds, selectedPeople, deletedIds]);

  return (
    <>
      <div
        className={`slider-content-core pb-[150px] text-neutral-800 font-medium ${
          !showTitleAndDescription ? "!px-0" : ""
        }`}
      >
        {/* Slider heading text, description */}
        {showTitleAndDescription ? (
          <div className="flex flex-col w-full">
            <HeaderTitle title={headerTitle} />
            <Text
              translationKey={headerDescription}
              classes="text-sm text-neutral-500 mt-1 font-medium"
            />
          </div>
        ) : null}

        {/* Add clerks section (if editable) */}
        {showNote && (
          <div className="mt-9">
            <Note
              titleText={noteConfig?.text}
              descriptionText={noteConfig?.description ?? ""}
              actionText=""
              backgroundColorClass="bg-warning-50"
              borderColorClass="border border-warning-300 border-solid"
            />
          </div>
        )}
        {/* List of clerks (if not editable) */}
        <div className={`${showTitleAndDescription ? "mt-9" : ""}`}>
          {editable ? (
            <>
              {showTitleAndDescription ? (
                <div>
                  <Text
                    translationKey={editTitle}
                    classes="text-lg font-semibold text-neutral-800"
                  />
                  <br />
                  <Text
                    translationKey={editDescription}
                    classes="text-sm text-neutral-500 mt-1 font-medium"
                  />
                </div>
              ) : null}

              {/* Dropdown */}
              <div className={`${showTitleAndDescription ? "mt-8" : ""}`}>
                <UserDropdown
                  users={allUsers?.map((user) => {
                    return {
                      id: user?.id,
                      name: user?.displayName,
                      role: user?.role,
                      departmentName: user?.departmentName,
                      locationName: user?.locationName,
                      checked: selectedPeople?.some((item) =>
                        item?.isGroup
                          ? item?.itemData?.ids?.includes(user.id)
                          : item?.itemData?.id === user.id
                      ),
                      disabled: selectedPeople?.some((item) =>
                        item?.isGroup
                          ? item?.itemData?.ids?.includes(user.id)
                          : item?.itemData?.id === user.id
                      ),
                    };
                  })}
                  label="Add"
                  selectedIdsArray={editablePeopleIds}
                  handleSubmit={dropdownSubmitHandler}
                  groupAdminAndManager={groupAdminAndManager}
                  dropdownLabelFlatView={dropdownLabelFlatView}
                  dropdownLabelFastView={dropdownLabelFastView}
                  selectedPeople={selectedPeople}
                />
              </div>
              {/* List of clerks */}
              <div className="mt-6">
                {selectedPeople?.length !== 0 ? (
                  <PeopleList
                    list={selectedPeople}
                    titleText={listTitle}
                    titleTextTranslationProps={listTitleTranslationProps}
                    onDeleteHandler={onDeleteHandler}
                    disabledDeleteButton={keepaAtLeastOneAdmin}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <>
              {peopleListItems.length === 0 &&
              emptyDataTitle &&
              !isFetchingUserList ? (
                <div className="absolute w-full h-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 mt-9">
                  <EmptyData
                    buttonLabel={emptyButtonLabel}
                    handleClick={onClickHandler}
                    title={emptyDataTitle}
                    hideButton={hideButton}
                  >
                    <Text
                      classes="text-center w-4/5"
                      translationKey={emptyDataDescription}
                    />
                  </EmptyData>
                </div>
              ) : !isFetchingUserList && peopleListItems.length !== 0 ? (
                <PeopleList
                  list={selectedPeople}
                  titleText={listTitle}
                  titleTextTranslationProps={listTitleTranslationProps}
                  readOnly
                />
              ) : (
                <LoaderSkeleton
                  size="lg"
                  borderRadius="200"
                  fullWidth
                  count={5}
                  classes="py-4 my-2"
                />
              )}
            </>
          )}
        </div>
      </div>
      {type === ROLES_TYPE.TOGGLE_PAYROLL && peopleListItems?.length === 0 ? (
        <SettingFooter
          onDiscard={onDiscard}
          onSubmit={onSubmit}
          submitDisabled={editablePeopleIds.length === 0}
          submitText="billPay.settings.sliders.apClerks.ctas.save"
        />
      ) : (
        <>
          {editable ||
          (peopleListItems.length !== 0 && !isFetchingExistingList) ? (
            <SettingFooter
              onDiscard={
                editable
                  ? onDiscard
                  : showCancelButton
                    ? cancelButtonHandler
                    : null
              }
              onSubmit={
                editable
                  ? onSubmit
                  : () => {
                      editButtonHandler();
                    }
              }
              submitText={
                editable
                  ? "billPay.settings.sliders.apClerks.ctas.save"
                  : "billPay.settings.sliders.apClerks.ctas.edit"
              }
              discardText={
                !editable
                  ? "company.rolesPermissions.buttonLabels.cancel"
                  : "company.rolesPermissions.buttonLabels.discard"
              }
              submitDisabled={editable ? isDisabled : null}
              hideButton={hideButton}
              showLoader={isLoading}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default EditAddPeopleDumb;
EditAddPeopleDumb.propTypes = {
  allUsers: PropTypes.array,
  peopleListItems: PropTypes.array,
  metaData: PropTypes.object,
  editable: PropTypes.bool,
  editButtonHandler: PropTypes.func,
  onDiscard: PropTypes.func,
  onSubmit: PropTypes.func,
  setEditable: PropTypes.func,
  deletedIds: PropTypes.array,
  setDeletedIds: PropTypes.func,
  editablePeopleIds: PropTypes.array,
  seteditablePeopleIds: PropTypes.func,
  selectedPeople: PropTypes.array,
  setSelectedPeople: PropTypes.func,
  dropdownLabelFastView: PropTypes.string,
  dropdownLabelFlatView: PropTypes.string,
  isFetchingExistingList: PropTypes.bool,
  type: PropTypes.string,
  groupAdminAndManager: PropTypes.func,
  hideButton: PropTypes.bool,
  showTitleAndDescription: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  cancelButtonHandler: PropTypes.func,
};

const HeaderTitle = ({ title }) => {
  const ref = useLeftHeaderTitle({ title });
  return (
    <Text
      refProp={ref}
      translationKey={title}
      classes="text-3xl font-bold text-neutral-800"
    />
  );
};
