import PropTypes from "prop-types";
import { useRef } from "react";
import { useSelector } from "react-redux";

import useOnClickOutside from "@/hooks/useClickOutside";

import { isFetchingPurchaseBillQuoteSelector } from "@/store/selectors/purchase-bills";

import Button from "@/components/core/Button";
import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { amountToCurrency } from "@/utils/common";

export default function BalanceCard({
  flagCode,
  iconName,
  iconClasses = "",
  iconBgClasses = "",
  iconComponent = null,
  labelTranslation,
  label,
  labelClasses = "font-normal text-sm text-start",
  amount,
  currency,
  amountFontSizeClass = "text-2xl",
  currencyFontSizeClass = "text-base",
  showCr = false,
  isEditable = false, // show pencil icon
  isEditableDisabled = false, // pencil icon interactive?
  fieldName,
  values,
  errors,
  handleChange,
  setValues,
}) {
  const amountClasses = `font-extrabold text-neutral-800 ${amountFontSizeClass}`;
  const isYSTRInCharge = values?.isYSTRInCharge;
  const editEnabled = values?.isYSTREditEnabled;
  const isFetchingQuote = useSelector(isFetchingPurchaseBillQuoteSelector);
  const inputValue = `${
    isYSTRInCharge ? values?.[fieldName] : (amount?.value ?? amount)
  }`; // if not being used, just show what normal gen quote returned

  const outSideClickRef = useRef();
  const onBlurHandler = () => {
    if (editEnabled && values?.[fieldName] && !errors?.[fieldName]) {
      toggleEditEnabled();
    }
  };
  useOnClickOutside(outSideClickRef, onBlurHandler);

  const toggleEditEnabled = (e) => {
    setValues((prev) => ({
      ...prev,
      isYSTREditEnabled: !prev.isYSTREditEnabled,
    }));
  };

  const currencyUI = (
    <div
      className={`select-none flex items-center gap-2 font-semibold text-neutral-500 ${currencyFontSizeClass}`}
    >
      {currency}

      {isEditable ? (
        <div className="ml-auto">
          {editEnabled ? (
            <Button
              label={null}
              preIcon="Done"
              classes="w-6 h-6"
              disabled={isFetchingQuote || errors?.[fieldName]}
              onClick={toggleEditEnabled}
            />
          ) : (
            <Button
              variant="tertiary"
              id="edit-amount"
              label={null}
              preIcon="Edit"
              iconClasses="text-neutral-500"
              classes="w-6 h-6 !bg-neutral-100 hover:!bg-neutral-300 border-none"
              disabled={isFetchingQuote || isEditableDisabled}
              onClick={toggleEditEnabled}
            />
          )}

          {isEditableDisabled ? (
            <Tooltip id="edit-amount" direction="top" k>
              <Text translationKey="payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentInformation.editButtonHoverState" />
            </Tooltip>
          ) : null}
        </div>
      ) : null}
    </div>
  );

  return (
    <div id="left" className="flex items-center gap-3">
      {flagCode && (
        <Flag code={flagCode} classes="h-10 w-10 border-2 border-neutral-100" />
      )}
      {iconName && (
        <Icon
          id={iconName}
          variant="rounded"
          name={iconName}
          bgClassName={`${iconBgClasses}`}
          className={`${iconClasses} w-5 h-5`}
        />
      )}
      {iconComponent}
      <div id="details">
        <div className={labelClasses}>
          {typeof label === typeof "" ? (
            <Text translationKey={label} translationProps={labelTranslation} />
          ) : (
            label
          )}
        </div>

        {/* Input box or normal text box */}
        {isEditable ? (
          <div className="flex items-center gap-2">
            <span ref={isEditable ? outSideClickRef : null}>
              <Input
                label=""
                name="ystrSendAmount"
                classes={amountClasses}
                style={{
                  // - show small box when nothing is there
                  // - show a little large box when 0 is entered, so the min-zero warning is shown properly
                  // - increase box length when typing
                  width: `calc(64px + ${
                    Math.max(
                      +inputValue === 0 ? 4 : inputValue.length,
                      inputValue.length
                    ) + 1
                  }ch)`,
                }}
                rightOuterClasses="top-1/4"
                rightText={currencyUI}
                onChange={(...args) => {
                  setValues((prev) => ({ ...prev, isYSTRInCharge: true }));
                  return handleChange(...args);
                }}
                disabled={!editEnabled || isFetchingQuote || isEditableDisabled}
                value={inputValue}
                error={errors?.[fieldName]}
                type="number"
              />
            </span>
          </div>
        ) : (
          <div className="flex items-baseline">
            {showCr && amount < 0 ? (
              <span className="mr-2 text-xl font-bold text-warning">Cr</span>
            ) : null}

            {isFetchingQuote ? (
              <LoaderSkeleton classes="my-3" />
            ) : (
              <>
                {" "}
                <span className={amountClasses}>
                  {amountToCurrency(showCr ? Math.abs(amount) : (amount ?? 0))}
                  &nbsp;
                </span>
                {currencyUI}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

BalanceCard.propTypes = {
  flagCode: PropTypes.string,
  iconName: PropTypes.string,
  iconClasses: PropTypes.string,
  iconBgClasses: PropTypes.string,
  label: PropTypes.node,
  labelClasses: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  amountFontSizeClass: PropTypes.string,
  iconComponent: PropTypes.node,
  labelTranslation: PropTypes.object,
  currency: PropTypes.string,
  currencyFontSizeClass: PropTypes.string,
  showCr: PropTypes.bool,
  isEditable: PropTypes.bool,
  isEditableDisabled: PropTypes.bool,
  fieldName: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
};
