import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function ListFilter({
  filterKey,
  allowScroll = true,
  label,
  icon,
  options,
  callback,
  popupTriggerComponent,
  defaultValues,
  showLabel = false,
  show = true,
}) {
  const popupRef = useRef();
  const [selectedOption, setSelectedOption] = useState(null);
  const [focused, setFocused] = useState(false);
  const contentStyle = allowScroll
    ? {
        maxHeight: "60vh",
        overflowY: "auto",
        overflowX: "hidden",
      }
    : {};
  const handleSelection = (option) => {
    callback({
      [filterKey]: option,
    });
    popupRef.current.close();
  };

  const checkSelectedOption = (value) => {
    return selectedOption?.value === value;
  };

  useEffect(() => {
    setSelectedOption(defaultValues[filterKey] || null);
  }, [defaultValues]);

  return show ? (
    <>
      <Popup
        keepTooltipInside="#tooltip-inside"
        ref={popupRef}
        trigger={
          popupTriggerComponent ? (
            popupTriggerComponent()
          ) : (
            <button
              id={`list-filter-${filterKey}`}
              className={`flex items-center gap-2 text-neutral-500 justify-center h-10 p-2 card-wrapper ${
                !showLabel ? "w-10 " : ""
              }  ${focused ? "border-primary-500" : ""}`}
            >
              {icon ? <Icon name={icon} /> : null}
              {showLabel ? (
                <Text className="text-neutral-500" translationKey={label} />
              ) : null}
            </button>
          )
        }
        closeOnDocumentClick
        repositionOnResize={false}
        position="bottom left"
        className="filter-popup"
        contentStyle={contentStyle}
        onOpen={() => setFocused(true)}
        onClose={() => setFocused(false)}
      >
        <div className="flex flex-col w-84">
          <div className="pt-2 pl-3">
            <Text
              translationKey={label}
              classes="text-sm font-medium text-neutral-500"
            />

            {label ? (
              <span className="text-sm font-medium text-neutral-500">:</span>
            ) : null}
          </div>

          {options.map((option) => {
            return (
              <button
                key={`list--without-checkbox-filter-${option.label}`}
                className={` ${
                  checkSelectedOption(option?.value)
                    ? "text-neutral-300 cursor-not-allowed"
                    : ""
                }   w-full ml-0 flex flex-col py-5 text-left pl-3 hover:bg-neutral-50 hover:text-neutral-900 active:bg-red text-neutral-500 pr-10 `}
                onClick={() => {
                  if (!checkSelectedOption(option.value))
                    handleSelection(option);
                }}
              >
                <Text
                  translationKey={option.label}
                  translationProps={option?.labelTranslationProps}
                />

                {option.subLabel ? (
                  <Text
                    classes="text-xs font-semibold text-neutral-500"
                    translationKey={option.subLabel}
                    translationProps={option?.subLabelTranslationProps}
                  />
                ) : null}
              </button>
            );
          })}
        </div>
      </Popup>
      {showLabel ? null : (
        <Tooltip id={`list-filter-${filterKey}`} direction="bottom">
          <Text translationKey={label} />
        </Tooltip>
      )}
    </>
  ) : null;
}

ListFilter.propTypes = {
  filterKey: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  options: PropTypes.array,
  callback: PropTypes.func,
  defaultValues: PropTypes.any,
  popupTriggerComponent: PropTypes.elementType,
  showLabel: PropTypes.bool,
  allowScroll: PropTypes.bool,
};
