// 'react-redux' imports
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  expenseNeedsReviewPageFiltersSelector,
  expensesTotalSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  qrPaymentsNeedsReviewPageFiltersSelector,
  qrPaymentsTotalSelector,
} from "@/store/selectors/qr-payments";

import Text from "@/components/core/Text";

import Export from "@/components/Exports";
import NeedsReviewList from "@/components/common/QrPayAndExpense/common/NeedsReview/NeedsReviewList";
import QrPayAndExpenseFilter from "@/components/common/QrPayAndExpense/common/QRPayAndExpenseFilter";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants/index";
import { convertFilters } from "@/utils/filters";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGE_TYPE_EXPENSE } from "@/constants/expense";
import {
  EXPORT_FILTER_STATUS,
  EXPORT_PAGE_TYPE,
  EXPORT_TAB_TYPE,
} from "@/constants/exports";

export default function NeedsReviewPageHelper({ page, tab }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const expenseSelectors = {
    filters: useSelector(expenseNeedsReviewPageFiltersSelector),
    totalTransactionsSelected: useSelector(expensesTotalSelector),
    storeName: "expenses",
    searchParam: SLIDERS_SEARCH_PARAMS.expenses.id,
    pageSubTitle: "expenses.title",
  };
  const qrPaymentsSelectors = {
    filters: useSelector(qrPaymentsNeedsReviewPageFiltersSelector),
    totalTransactionsSelected: useSelector(qrPaymentsTotalSelector),
    storeName: "qrPayments",
    searchParam: SLIDERS_SEARCH_PARAMS.qrPayments.payments.id,
    pageSubTitle: "QRPay.payments.routeTitle",
  };
  const appliedFilters = useSelector(appliedFilterSelector);

  const currentSelectors =
    page === QRPAY_EXPENSES_CONTEXT.EXPENSES
      ? expenseSelectors
      : qrPaymentsSelectors;
  const handleTransaction = (id) => {
    searchParams.append(currentSelectors?.searchParam, id);
    setSearchParams(searchParams);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="w-full ">
          <QrPayAndExpenseFilter filters={currentSelectors?.filters} />
        </div>
        <div className="flex justify-end ">
          <Export
            totalExports={currentSelectors?.totalTransactionsSelected}
            storeName={currentSelectors?.storeName}
            sectionName={currentSelectors?.storeName}
            additionalFilters={{
              ...convertFilters(appliedFilters),
              ...EXPORT_PAGE_TYPE.EXPENSE,
              ...EXPORT_FILTER_STATUS.EXPENSE.NEEDS_REVIEW,
              tab: EXPORT_TAB_TYPE.EXPENSE.NEEDS_REVIEW,
            }}
          />
        </div>
      </div>
      <NeedsReviewList
        viewTransactionItem={handleTransaction}
        page={page}
        tab={tab}
      />
    </>
  );
}
NeedsReviewPageHelper.propTypes = {
  tab: PropTypes.object,
  page: PropTypes.string,
};
