import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import {
  TRANSACTION_ICONS_STATUSES,
  TRANSACTION_STATUS_KEY,
} from "@/utils/constants/common";

export default function TransactionStatusIcon({ value, index }) {
  const config = TRANSACTION_ICONS_STATUSES[TRANSACTION_STATUS_KEY[value]];
  return (
    <div className="flex-1">
      <Tooltip id={`transaction-status-value-${index}`} direction="top">
        <Text translationKey={config?.label} />
      </Tooltip>
      <div
        className="flex items-center justify-center"
        id={`transaction-status-value-${index}`}
      >
        <Icon name={config?.icon} className={config?.class} />
      </div>
    </div>
  );
}
TransactionStatusIcon.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number,
};
