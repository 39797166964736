import PropTypes from "prop-types";

import { getColorCode } from "@/utils/common";
import { COLOR_CODE_LIST } from "@/utils/constants/colorCode";
import { FLAGS } from "@/assets/flags";

export default function Flag({
  code,
  size = "md",
  classes,
  border = false,
  borderClasses = "border border-neutral-200",
}) {
  if (!code) console.warn("Flag code is empty", { code });
  if (!FLAGS[code]) console.warn("Flag code not found", { code });

  const colorCode = getColorCode(
    Math.floor(Math.random() * (COLOR_CODE_LIST.length - 1))
  );

  return code ? (
    <img
      src={FLAGS[code]}
      alt={code}
      className={`flag flag-${size} ${classes} ${border ? borderClasses : ""}`}
    />
  ) : (
    <div
      className="text-xs avatar avatar-md "
      style={{
        backgroundColor: `rgba(${colorCode},0.3)`,
        color: `rgba(${colorCode},0.9)`,
      }}
    >
      {code}
    </div>
  );
}

Flag.propTypes = {
  code: PropTypes.string.isRequired, // 3 letter currency code
  size: PropTypes.string, // 'sm', 'md', 'lg'
  classes: PropTypes.string,
  border: PropTypes.bool,
  borderClasses: PropTypes.string,
};
