import PropsTypes from "prop-types";
import { useSelector } from "react-redux";

import { appliedFilterSelector } from "@/store/selectors/filters";
import { totalPaymentsSelector } from "@/store/selectors/payments";

import Filters from "@/components/core/Filters";

import Export from "@/components/Exports";
import RequestHistoryButton from "@/components/MyVolopay/ActionCentre/common/RequestHistory/RequestHistoryButton";
import {
  INVOICE_INBOX_LISTING_PARAMS,
  PAYROLL,
  PURCHASE_BILL,
  TAB_TYPES,
} from "@/utils/constants/payments";

import { VENDOR_CLASS } from "@/constants/common";
import { EXPORT_FILTER_STATUS, EXPORT_PAGE } from "@/constants/exports";

export default function BillPayFilters({
  filters,
  page,
  tab,
  payment,
  context,
  requestHistorySliderContext,
  showRequestHistoryButton,
}) {
  const totalPaymentsSelected = useSelector(totalPaymentsSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  return (
    <div className="flex items-center my-2">
      <div className="flex flex-col flex-1 w-11/12">
        {filters ? <Filters filters={filters} /> : null}
      </div>

      <div className="flex justify-end gap-6">
        <Export
          storeName="payments"
          sectionName="payments"
          totalExports={totalPaymentsSelected}
          selectedFilter={appliedFilters}
          additionalFilters={{
            export_type: payment ? EXPORT_PAGE.PAYMENT : page,
            ...([PURCHASE_BILL, PAYROLL, EXPORT_PAGE.PAYROLL].includes(page) &&
            [
              TAB_TYPES.NEEDS_YOUR_APPROVAL,
              TAB_TYPES.PENDING,
              TAB_TYPES.ALL,
              INVOICE_INBOX_LISTING_PARAMS.status,
            ].includes(tab) &&
            !payment
              ? tab === INVOICE_INBOX_LISTING_PARAMS.status
                ? { status: tab }
                : { tab }
              : {
                  status:
                    tab === "all" && payment
                      ? EXPORT_FILTER_STATUS.PAYMENT.ALL.status
                      : tab,
                  tab,
                }),
            ...(page === PAYROLL && payment ? { payroll: true } : {}),
            ...(page === EXPORT_PAGE.VENDOR
              ? {
                  vendor_class:
                    context === PAYROLL
                      ? VENDOR_CLASS.PAYROLL
                      : VENDOR_CLASS.USER_CREATED,
                  not_archived: true,
                }
              : {}),
          }}
        />

        {showRequestHistoryButton ? (
          <RequestHistoryButton context={requestHistorySliderContext} />
        ) : null}
      </div>
    </div>
  );
}

BillPayFilters.propTypes = {
  filters: PropsTypes.arrayOf(Object),
  page: PropsTypes.string,
  tab: PropsTypes.string,
  payment: PropsTypes.bool,
  context: PropsTypes.string,
  requestHistorySliderContext: PropsTypes.string,
  showRequestHistoryButton: PropsTypes.bool,
};
