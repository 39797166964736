import PropTypes from "prop-types";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { closeAppModal, openAppModal } from "@/store/reducers/app";

import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isApproveActionPendingForIdSelector,
  isLoadingPaymentsSelector,
} from "@/store/selectors/payments";
import {
  isActionPendingForPurchaseBillSelector,
  isLoadingPurchaseBillSelector,
} from "@/store/selectors/purchase-bills";
import {
  isCtaActionPendingForIdSelector,
  isLoadingSelector,
} from "@/store/selectors/reimbursement";
import { isAdminSelector, userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import DeleteRecurringPaymentModal from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/DeleteRecurringPaymentModal";
import { convertFilters } from "@/utils/filters";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { CTA_CONFIG_PAYMENTS } from "@/utils/constants/payments-ctas";
import { REPORT_CONTEXT } from "@/utils/constants/myVolopay";

import { REIMBURSEMENT_CONTEXT } from "@/constants/reimbursement";

import ArchivePaymentModal from "../ArchivePaymentModal";

/**
 * @param {Array<String>} ctas
 * @param {Boolean} showPrimaryButton
 */
export default function ActionCell({ val }) {
  const loaderUI = (
    <svg
      className="w-5 h-5 animate-spin "
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const popupRef = useRef();
  const inPayrollContext =
    val?.context === BILL_PAYROLL_CONTEXT.PAYROLL ||
    val?.context === BILL_PAYROLL_CONTEXT.ACTION_CENTER_PAYROLL;

  const currentUser = useSelector(userSelector);
  const appliedFilters = convertFilters(useSelector(appliedFilterSelector));
  const isAdmin = useSelector(isAdminSelector);

  const [refreshing, setRefreshing] = useState(false);
  // provided to CTA handler
  const componentProvidedStuff = {
    dispatch,
    searchParams,
    setSearchParams,
    appliedFilters,
    openModal: (modalId) => {
      dispatch(openAppModal(modalId));
    },
    closeModal: () => {
      dispatch(closeAppModal());
    },
    currentUser,
  };

  const mainData = {
    ...val, // context already inside
    currentUser,
    isAdmin,
    target: "table",
  };

  const ctas = !val.ctas ? val.payrollCta : val.ctas;
  const inReimbursementContext =
    val.context === REIMBURSEMENT_CONTEXT || val.context === REPORT_CONTEXT;

  const isActionApprovePending = useSelector((state) =>
    inReimbursementContext
      ? isCtaActionPendingForIdSelector(state)
      : isApproveActionPendingForIdSelector(state) ||
        isActionPendingForPurchaseBillSelector(state)
  );

  // TODO: showLoader is pending to add
  const isLoading = useSelector((state) =>
    inReimbursementContext
      ? isLoadingSelector(state)
      : isLoadingPaymentsSelector(state) || isLoadingPurchaseBillSelector(state)
  );

  // there are 2 variation variables - [primary button there or not, secondaries there or not]

  // Only the first element of CTA array returned by BE may be a primary button
  const primaryCTAKey = ctas?.at(0);
  const secondaryCTAKeys = ctas; // if the first element is not a primary button, add everything to the 3 dots (kebab) menu
  const [clicked, setClicked] = useState(null);
  const primaryCTAConfig = CTA_CONFIG_PAYMENTS[val.context][primaryCTAKey]
    ?.isPrimary
    ? CTA_CONFIG_PAYMENTS[val.context][primaryCTAKey]
    : null;

  const secondaryCTAConfigs = secondaryCTAKeys
    ?.map((ctaKey) => {
      if (!CTA_CONFIG_PAYMENTS[val.context][ctaKey]?.isPrimary) {
        return CTA_CONFIG_PAYMENTS[val.context][ctaKey];
      }
    })
    .filter((data) => !!data);

  const showTheMoreVerticle = secondaryCTAConfigs?.length > 0;

  const handlePopupToggle = () => {
    popupRef.current.toggle();
  };
  useEffect(() => {
    if (!isLoading && clicked) {
      setClicked(null);
    }
  }, [isLoading]);

  const primaryCTAConfigLabel =
    typeof primaryCTAConfig?.label === typeof ""
      ? primaryCTAConfig?.label
      : primaryCTAConfig?.label?.(inPayrollContext);

  const primaryCTAConfigHoverText =
    typeof primaryCTAConfig?.hoverText === typeof ""
      ? primaryCTAConfig?.hoverText
      : primaryCTAConfig?.hoverText?.(mainData);

  const groupLabelIndex = Array.isArray(secondaryCTAConfigs)
    ? secondaryCTAConfigs.length -
      secondaryCTAConfigs.reduce(
        (accum, item) => accum + (item?.isShowInAdminGroup ? 1 : 0),
        0
      )
    : -1;

  if (ctas?.length === 0 || val?.queued) {
    return <div className="m-auto text-sm text-center w-15">-</div>;
  }

  return (
    <>
      <div
        className="flex items-center justify-evenly"
        id={primaryCTAConfigHoverText ? `action-cell-tooltip-${val.id}` : ""}
      >
        {primaryCTAConfig ? (
          <>
            <Button
              label={primaryCTAConfigLabel}
              preIcon={primaryCTAConfig.icon}
              type={primaryCTAConfig.type}
              classes={` py-1 ${primaryCTAConfig.classes}`}
              size="sm"
              disabled={Boolean(
                isActionApprovePending && isActionApprovePending !== val.id
              )}
              showLoader={isActionApprovePending === val.id}
              compact
              variant="tertiary" // listing primary action don't have background color
              onClick={(event) => {
                event?.stopPropagation();
                primaryCTAConfig.onClick(mainData, componentProvidedStuff);
              }}
            />

            {primaryCTAConfigHoverText ? (
              <Tooltip
                id={`action-cell-tooltip-${val.id}`}
                direction="top-right"
                maxWidth={450}
              >
                <Text
                  translationKey={primaryCTAConfigHoverText}
                  classes="text-neutral-500 text-sm font-medium"
                />
              </Tooltip>
            ) : null}
          </>
        ) : (
          <div className="m-auto text-sm text-center w-15">
            {isActionApprovePending === val.id ? (
              <div className="flex justify-center text-sm w-15">
                {loaderUI}{" "}
              </div>
            ) : val?.bulkProcessing ? (
              <div className="flex justify-center">
                {val?.refreshing ? (
                  <div className="text-primary-500">{loaderUI}</div>
                ) : (
                  <Icon
                    name="Replay"
                    className="cursor-pointer text-neutral-500"
                    handleClick={() => {
                      val?.refreshFunction();
                    }}
                  />
                )}
              </div>
            ) : (
              "-"
            )}
          </div>
        )}
        {showTheMoreVerticle ? (
          <div className="flex flex-col">
            <Popup
              ref={popupRef}
              keepTooltipInside="#root"
              repositionOnResize
              trigger={() => (
                <button
                  type="button"
                  onClick={handlePopupToggle}
                  className="py-2 cursor-pointer"
                >
                  <Icon name="MoreVerticle" />
                </button>
              )}
              closeOnDocumentClick
              position="bottom right"
              className="border-0 filter-popup"
            >
              <div className="flex flex-col min-w-[251px]">
                {secondaryCTAConfigs
                  ?.toSorted(
                    (a, b) =>
                      Number(a?.isShowInAdminGroup || false) -
                      Number(b?.isShowInAdminGroup || false)
                  )
                  ?.map((secondaryCtaConfig, index) => {
                    const label =
                      typeof secondaryCtaConfig?.label === typeof ""
                        ? secondaryCtaConfig?.label
                        : secondaryCtaConfig?.label?.(inPayrollContext);

                    const hoverText =
                      typeof secondaryCtaConfig?.hoverText === typeof ""
                        ? secondaryCtaConfig?.hoverText
                        : secondaryCtaConfig?.hoverText?.(mainData);
                    if (val?.queued) return <div key={label}>-</div>;
                    return (
                      <Fragment key={label}>
                        {isAdmin &&
                        secondaryCtaConfig?.isShowInAdminGroup &&
                        index === groupLabelIndex ? (
                          <Text
                            classes="text-neutral-500 font-semibold text-xs px-3 pt-2 pb-1"
                            translationKey={
                              inPayrollContext
                                ? "reimbursement.ctaGroupLabelPayroll"
                                : "reimbursement.ctaGroupLabelBillpay"
                            }
                          />
                        ) : null}
                        <Button
                          id={`action-cell-menu-item-tooltip-${val.id}-${label}`}
                          key={label}
                          variant="tertiary"
                          classes="border-0 w-full p-4 pl-3 pr-6 font-medium text-neutral-500 hover:text-neutral-900"
                          textAlignment="text-left"
                          label={label}
                          disabled={isLoading}
                          showLoader={isLoading}
                          onClick={(event) => {
                            event?.stopPropagation();
                            popupRef.current.close(); // close the context menu
                            secondaryCtaConfig?.onClick(
                              mainData,
                              componentProvidedStuff
                            );
                          }}
                        />

                        {hoverText ? (
                          <Tooltip
                            id={`action-cell-menu-item-tooltip-${val.id}-${label}`}
                            direction="top-right"
                            maxWidth={450}
                          >
                            <Text
                              translationKey={hoverText}
                              classes="text-neutral-500 text-sm font-medium"
                            />
                          </Tooltip>
                        ) : null}
                      </Fragment>
                    );
                  })}
              </div>
            </Popup>
          </div>
        ) : null}
      </div>

      <DeleteRecurringPaymentModal val={mainData} target="table" />
      <ArchivePaymentModal val={mainData} target="table" />
    </>
  );
}

ActionCell.propTypes = {
  val: PropTypes.object,
};
