import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CheckboxDropdownGroupHeader from "@/components/core/CheckboxDropdown/CheckboxDropdownGroupHeader";
import CheckboxDropdownRow from "@/components/core/CheckboxDropdown/CheckboxDropdownRow";

/**
 * A group - header followed by entries (rows)
 */
export default function CheckboxDropdownGroup({
  groupSelfKey,

  groupLabel = "",
  groupIcon = "",
  entries,
  toggleCheckboxHandler = () => {},
  level = 0,

  backTree,
  currentTree,
  setBackTree = () => {},
  setCurrentTree = () => {},

  selectedStuff,
  getHeaderNodeUI,
  supportExplicitControls = false,
}) {
  const { t } = useTranslation();

  const showLeftArrow = !backTree?.length;
  const onLeftArrowClick = () => {
    setCurrentTree(backTree.pop());
    setBackTree((prev) => (prev.length ? [prev.at(-1)] : []));
  };

  return (
    <div aria-label="checkbox-dropdown-group">
      {/* header with potential back button */}
      {groupLabel || groupIcon ? (
        <CheckboxDropdownGroupHeader
          label={groupLabel}
          icon={groupIcon}
          onBackHandler={onLeftArrowClick}
          showBackArrow={showLeftArrow}
        />
      ) : null}

      {/* entries of the group */}
      <div aria-label="checkbox-group-entries">
        {entries?.map((entry, index) => {
          // my entry
          const showRightArrow = !!entry?.entries?.length;
          const onRightArrowClick = () => {
            setBackTree((prev) => [...prev, currentTree]);
            setCurrentTree([
              {
                ...entry,
                level: level + 1,
                entries: entry?.entries || [],
              },
            ]);
          };

          const isGroupRow = !!entry.groupSelfKey;
          const checked = isGroupRow
            ? selectedStuff.groups.has(entry.groupSelfKey)
            : selectedStuff.ids.has(entry.id);
          const reactListKey = isGroupRow ? entry.groupSelfKey : entry.id;

          const headerConfig = isGroupRow
            ? getHeaderNodeUI?.({
                groupKey: entry.groupSelfKey,
                allIds: selectedStuff?._groupContents?.get(entry.groupSelfKey)
                  ?.allIds,
                selectedIds: selectedStuff?._groupContents?.get(
                  entry.groupSelfKey
                )?.selectedIds,
                isGroupFull:
                  selectedStuff?._groupContents?.get(entry.groupSelfKey)?.allIds
                    .size ===
                  selectedStuff?._groupContents?.get(entry.groupSelfKey)
                    ?.selectedIds?.size,
                t,
              })
            : null;

          return (
            <CheckboxDropdownRow
              key={reactListKey}
              id={entry.id}
              checked={checked || entry?.checked}
              disabled={
                isGroupRow
                  ? headerConfig?.disabled || entry.disabled // for forceful disabling
                  : entry.disabled
              }
              groupKey={entry.groupKey}
              groupSelfKey={entry.groupSelfKey}
              label={isGroupRow ? headerConfig?.label : entry.label}
              labelJsx={isGroupRow ? headerConfig?.labelJsx : entry.labelJsx}
              subLabel={isGroupRow ? headerConfig?.subLabel : entry.subLabel}
              icon={isGroupRow ? headerConfig?.icon : entry.icon}
              subLabelClasses={isGroupRow ? headerConfig?.subLabelClasses : ""}
              showRightArrow={showRightArrow}
              toggleCheckboxHandler={toggleCheckboxHandler}
              onRightArrowClick={onRightArrowClick}
              selectedStuff={selectedStuff}
              supportExplicitControls={supportExplicitControls}
            />
          );
        })}
      </div>
    </div>
  );
}

CheckboxDropdownGroup.propTypes = {
  groupSelfKey: PropTypes.string,

  entries: PropTypes.array,
  groupLabel: PropTypes.string,
  groupIcon: PropTypes.string,
  toggleCheckboxHandler: PropTypes.func,
  level: PropTypes.number,

  backTree: PropTypes.any,
  currentTree: PropTypes.any,
  setBackTree: PropTypes.func,
  setCurrentTree: PropTypes.func,

  selectedStuff: PropTypes.object,
  getHeaderNodeUI: PropTypes.func,
  supportExplicitControls: PropTypes.bool,
};
