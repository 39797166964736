import { bool, func, object, string } from "prop-types";
import { useEffect, useState } from "react";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";

export default function DisableConfirmation({
  header,
  title,
  subtitle,
  desc,
  inputNote,
  labelText,
  showModal,
  showLoader = false,
  setShowModal,
  titleBody,
  onCancelClick = () => {},
  onSubmitClick,
  submitBtnType,
  submitBtnLabel,
}) {
  const [confirmText, setConfirmText] = useState("");
  useEffect(() => {
    setConfirmText("");
  }, [showModal]);
  return (
    <Modal open={showModal} innerContainerClasses="w-126 px-6">
      <div className="py-4 text-lg font-bold">
        <Text translationKey={header} />
      </div>
      <div className="py-2 text-xs font-bold text-danger-500">Important</div>

      {title ? (
        <div className="py-2 font-semibold">
          <Text translationKey={title} />
        </div>
      ) : (
        <div>{titleBody}</div>
      )}
      {subtitle ? (
        <div className="py-2 text-sm font-semibold text-neutral-500">
          <Text translationKey={subtitle} />
        </div>
      ) : null}
      <div className="py-2 text-xs font-semibold text-warning-600 ">
        <Text translationKey={desc} />
      </div>
      <div className="py-4 my-2">
        <Input
          label={`Please type "${labelText}" in uppercase letters`}
          value={confirmText}
          onChange={(e) => {
            setConfirmText(e.target.value);
          }}
        />
        {inputNote ? (
          <div className="text-xs text-neutral-500">
            <Text translationKey={inputNote} />
          </div>
        ) : null}
      </div>
      <div className="flex gap-2 py-4">
        <Button
          variant="tertiary"
          label="Cancel"
          disabled={showLoader}
          onClick={() => {
            setShowModal(false);
            onCancelClick();
          }}
        />
        <Button
          variant="primary"
          disabled={confirmText !== labelText || showLoader}
          type={submitBtnType}
          label={submitBtnLabel}
          onClick={onSubmitClick}
          showLoader={showLoader}
        />
      </div>
    </Modal>
  );
}

DisableConfirmation.propTypes = {
  header: string,
  title: string,
  titleBody: string,
  subtitle: string,
  inputNote: string,
  desc: string,
  labelText: string,
  showLoader: bool,
  showModal: bool,
  setShowModal: func,
  onCancelClick: func,
  onSubmitClick: func,
};
