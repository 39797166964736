import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { setState } from "@/store/reducers/cards";
import { fetchPeople } from "@/store/reducers/company";

import { peopleSelector } from "@/store/selectors/company";

import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import UserDropdown from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/UserDropdown";

function AddDepartmentMainForm({
  value,
  isFetching,
  fetchOptions,
  handleChange,
  people,
  errors,
  isInEditMode,
}) {
  const ref = useLeftHeaderTitle({
    title: isInEditMode
      ? "company.department.editDetails"
      : "company.department.addNewDepartment.title",
  });
  const [state, setstate] = useState([]);
  return (
    <>
      <div ref={ref} className="flex flex-col">
        <Text
          classes="text-neutral-800 font-bold text-3xl"
          translationKey={
            isInEditMode
              ? "company.department.editDetails"
              : "company.department.addNewDepartment.title"
          }
        />
        {!isInEditMode ? (
          <Text
            classes="text-sm text-neutral-500 font-medium"
            translationKey="company.department.addNewDepartment.description"
          />
        ) : null}
      </div>
      <Input
        name="name"
        value={value?.name}
        error={errors?.name}
        onChange={handleChange}
        label="company.department.addNewDepartment.nameOfDepartment"
        placeholder=""
      />
      {!isInEditMode ? (
        <div className="flex flex-col gap-5">
          <Text
            classes="text-lg text-neutral-800 font-semibold"
            translationKey="company.department.addNewDepartment.departmentManager.plural"
          />

          <UserDropdown
            name="departmentManagers"
            users={people?.map((item) => ({
              ...item,
              name: item?.name,
              value: item?.id,
              label: item?.name,
            }))}
            groupAdminAndManager={null}
            dropdownLabelFastView=""
            selectedIdsArray={value?.departmentManagers ?? []}
            handleSubmit={(e) => {
              const _e = structuredClone(e);
              _e.target.value = e.target.value.selectedIds;
              handleChange(_e);
            }}
            isLoading={isFetching}
            insideForm
            label="company.department.addNewDepartment.addManager.plural"
          />
        </div>
      ) : null}
    </>
  );
}

export default AddDepartmentMainForm;
AddDepartmentMainForm.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.string,
  handleChange: PropTypes.func,
  people: PropTypes.array,
  fetchOptions: PropTypes.func,
  isFetching: PropTypes.bool,
  isInEditMode: PropTypes.bool,
};
