import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  resetPassword,
  resetPasswordVerification,
} from "@/store/reducers/user";

import { authDataSelector, isMfaSelector } from "@/store/selectors/user";

import TwoFactorAuth from "@/components/TwoFactorAuth";

import ChangePasswordForm from "./ChangePasswordForm";

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const token = searchParam.get("reset_password_token");
  const authData = JSON.parse(useSelector(authDataSelector));
  const isMfa = useSelector(isMfaSelector);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  useEffect(() => {
    dispatch(
      resetPasswordVerification({
        reset_password_token: token,
        redirect_url: "test",
      })
    );
  }, []);
  const handleSubmit = (obj) => {
    setPassword(obj?.password);
    setConfirmPassword(obj?.password_confirmation);
    const params = {
      password: obj?.password,
      password_confirmation: obj?.password_confirmation,
      reset_password_token: token,
    };
    dispatch(resetPassword({ params, navigate }));
  };
  const handleAuthSubmit = ({ values }) => {
    const params = {
      password,
      password_confirmation: confirmPassword,
      reset_password_token: token,
      otp: values?.otp,
    };
    dispatch(resetPassword({ params, navigate }));
  };
  return isMfa ? (
    <TwoFactorAuth
      handleAuthSubmit={handleAuthSubmit}
      showTrustedCheck={false}
      buttonTitle="login.resetPassword"
    />
  ) : (
    <ChangePasswordForm
      title="Reset password"
      subtitle="Don’t worry, it happens! Please enter the email associated with your account"
      btnLabel="Reset password"
      onSubmit={handleSubmit}
      showGoBack
    />
  );
}
