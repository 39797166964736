import React from "react";
import { useSelector } from "react-redux";

import { blockedPhysicalCardPageFiltersSelector } from "@/store/selectors/cards";

import PhysicalCardsPageHelper from "@/components/Cards/PhysicalCardPageHelper";

import { ROUTES } from "@/constants/routes";

function BlockedPhysicalCards() {
  const filters = useSelector(blockedPhysicalCardPageFiltersSelector);
  return (
    <PhysicalCardsPageHelper
      key={ROUTES.cards.physicalCards.blocked.pathName}
      tab={ROUTES.cards.physicalCards.blocked.pathName}
      absoluteTabPath={ROUTES.cards.physicalCards.blocked.absolutePath}
      filterArray={filters}
    />
  );
}

export default BlockedPhysicalCards;
