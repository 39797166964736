import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { selectedWalletAccountSelector } from "@/store/selectors/company";

import SlideDrawer from "@/components/core/Slider";
import Text from "@/components/core/Text";

import PaymentOptionCard from "@/components/Company/Overview/AccountOverview/PaymentOptionCard";
import CompanyOverviewPageTitle from "@/components/Company/Overview/CompanyOverviewPageTitle";
import WalletAccountWidget from "@/components/Company/Overview/MyAccounts/WalletAccountWidget";
import SpendAnalytics from "@/components/Company/Overview/SpendAnalytics";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAYMENT_OPTION_TYPE } from "@/constants/company";

import "../style.scss";

export default function DashboardAccountOverviewPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedWallet = useSelector(selectedWalletAccountSelector);
  const showAddFunds = true; // TODO: what's the criteria

  const showSlider = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.paymentOptions
  );
  const closeSliderHandler = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.paymentOption);
    setSearchParams(searchParams);
  };

  return (
    <div className="company-overview-page-container">
      <div className="company-overview-main">
        <div className="company-overview-left">
          <CompanyOverviewPageTitle isAccountPage />
          <div className="mt-4">
            <div className="my-4 card-wrapper-lg">
              <WalletAccountWidget account={selectedWallet} onAccountPage />
            </div>
          </div>
          <div className="my-8">
            <SpendAnalytics onAccountPage />
          </div>
        </div>
        <div className="company-overview-right">
          {showAddFunds ? (
            <div className="flex flex-col gap-4 -mb-2">
              <Text
                classes="text-lg text-neutral-500 font-bold -mb-2"
                translationKey="company.accountOverview.paymentOptions.addFunds"
              />
              <PaymentOptionCard
                paymentOptionType={PAYMENT_OPTION_TYPE.ADDFUND}
              />
            </div>
          ) : null}
          <div className="flex flex-col gap-4">
            <Text
              classes="text-lg text-neutral-500 font-bold -mb-2"
              translationKey="company.accountOverview.paymentOptions.receiveFunds"
            />
            {selectedWallet?.paymentOptions.map((option) => (
              <PaymentOptionCard paymentOptionType={option} key={option} />
            ))}
          </div>
        </div>
      </div>
      <SlideDrawer show={showSlider} close={closeSliderHandler} />
    </div>
  );
}
