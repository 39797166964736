import PropTypes from "prop-types";

import Checkbox from "@/components/core/Checkbox";

export default function BillingInvoiceNumberCell({ val }) {
  return (
    <div className="flex items-center gap-3 text-neutral-800">
      <Checkbox
        checked={val?.checkedValue}
        onClickHandler={() => {
          val?.onCheckboxClick(val);
        }}
      />
      <span>{val?.invoiceNumber}</span>
    </div>
  );
}

BillingInvoiceNumberCell.propTypes = {
  val: PropTypes.object,
};
