import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { CATEGORY_ICON } from "@/constants/common";

const CategoriesCell = ({ val }) => {
  return (
    <div className="flex items-center justify-center gap-2 max-w-fit ">
      <div className="avatar-count-parent">
        <Icon name={CATEGORY_ICON?.[val?.name] ?? "CategoriesIcon"} />
      </div>
      <Text
        translationKey={val?.name}
        classes="font-semibold text-neutral-800 text-base"
      />
    </div>
  );
};
CategoriesCell.propTypes = {
  val: PropTypes.object,
};

export default CategoriesCell;
