import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import useOnClickOutside from "@/hooks/useClickOutside";
import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchQuickGuideList } from "@/store/reducers/quickGuide";

import { clientSelector } from "@/store/selectors/client";
import {
  quickGuideListSelector,
  quickGuideSearchOptionsSelector,
} from "@/store/selectors/quickGuide";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import VideoSection from "@/components/core/VideoSection";
import { openChatWindow } from "@/utils/chatbox";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import InappGuideButton from "./InappGuideButton";
import { CARD_PROVIDER_API_TRIGGER_ENDPOINT_KEY } from "./contants";
import "./style.css";
import { getQuickGuidePageInfoByKey } from "./utils";

export default function InAppGuide() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const quickGuide = useSelector(quickGuideListSelector);
  const _quickGuideList = quickGuide?.guides;

  const locationObject = useLocation();

  const pagePathName = locationObject?.pathname;

  // TODO
  /**
   * Below we are using getQuickGuidePageInfoByKey to get us the required object for the page we want, we have to pass the unique key inorder to get the quick guide page info for the required page
   */

  const quickGuidePageInfo = getQuickGuidePageInfoByKey(
    _quickGuideList,
    pagePathName
  );

  const feedback = quickGuidePageInfo?.feedback;
  const supportEmail = quickGuide?.supportEmail;
  const haveSuggestion = quickGuide?.haveSuggestion;

  const videos = quickGuidePageInfo?.videos;

  const statusOverviewSection = quickGuidePageInfo?.statusOverviewSection;

  const helpArticles = quickGuidePageInfo?.helpArticles;

  const otherRelatedTopics = quickGuidePageInfo?.otherRelatedTopics;

  const faq = quickGuidePageInfo?.faq;

  const clientInfo = useSelector(clientSelector);
  const baseCountry = clientInfo?.baseCountry;

  const [openVideoModal, setOpenVideoModal] = useState({
    open: false,
    redirectionLink: null,
    videoUrl: null,
    thumbNailUrl: null,
    playVideo: false,
  });

  const quickGuideOptions = useSelector(quickGuideSearchOptionsSelector);

  const quickGuidePageOptions = quickGuideOptions;

  const cardState = useLoadingErrorInjector({
    showLoader: false,
    error: {
      header: "",
    },
  });

  const [quickGuideSearchOptions, setQuickGuideSearchOptions] = useState({
    show: false,
    q: "",
  });

  const _quickGuideSearchOptions = useMemo(() => {
    return (
      quickGuidePageOptions?.filter((option) =>
        option.label?.includes(quickGuideSearchOptions?.q)
      ) || []
    );
  }, [quickGuideSearchOptions?.q]);

  const ref = useLeftHeaderTitle({ title: "Quick guide" });

  const searchInputRef = useRef();

  useOnClickOutside(searchInputRef, () => {
    setQuickGuideSearchOptions({
      show: false,
      q: "",
    });
  });

  useEffect(() => {
    if (baseCountry && !_quickGuideList) {
      const endpointKey = CARD_PROVIDER_API_TRIGGER_ENDPOINT_KEY?.[baseCountry];
      dispatch(fetchQuickGuideList(endpointKey));
    }
  }, [baseCountry, _quickGuideList]);

  function openVideoPlayModal(videoInfo) {
    setOpenVideoModal({
      ...openVideoPlayModal,
      open: true,
      redirectionLink: videoInfo?.redirectionUrl,
      videoUrl: videoInfo?.videoUrl,
      thumbNailUrl: videoInfo?.thumbnailUrl,
    });
  }

  function handleFeedbackSectionClick() {
    searchParams.append(SLIDERS_SEARCH_PARAMS.quickGuide.feedbackSlider, true);
    setSearchParams(searchParams);
  }

  function handleCloseVideoModal() {
    setOpenVideoModal({
      open: false,
      redirectionLink: null,
      videoUrl: null,
      thumbNailUrl: null,
      playVideo: false,
    });
  }

  function navigateToGivenUrl(url) {
    if (url) {
      window.open(url, "_blank");
    }
  }

  return (
    <div className="flex flex-col gap-6 pt-0 p-9" ref={cardState?.attach}>
      <div className="flex flex-row items-center gap-2 mb-3">
        <div className="bg-primary-50 py-3.5 px-4 rounded-[50%]">
          <Icon name="Bulb" className="text-primary-500" />
        </div>

        <Text
          classes="text-xl font-bold"
          translationKey="onboarding.quickGuide"
          refProp={ref}
        />
      </div>

      <div>
        {/* TODO: will include it in next release */}
        {/* <div
          className={`flex flex-row items-center w-full px-4 py-2 mb-3 border rounded-lg caret-primary search--inactive focus-within:search--active ${
            quickGuideSearchOptions?.show
              ? "border-primary-300"
              : "border-neutral-200"
          }  `}
          ref={searchInputRef}
        >
          <div>
            <Icon name="Search" className="w-4 mr-4 text-neutral" />
          </div>

          <Input
            type="text"
            value={quickGuideSearchOptions?.q}
            placeholder="search.searchAndFilter"
            className="w-full ml-1 text-sm font-medium tracking-wide focus:border-none focus:outline-none"
            outsideDivClasses="w-full"
            onClick={() =>
              setQuickGuideSearchOptions({
                ...quickGuideSearchOptions,
                show: true,
              })
            }
            isFocus={!quickGuidePageOptions?.show}
            onChange={(e) => {
              e?.preventDefault();
              setQuickGuideSearchOptions({
                ...quickGuideSearchOptions,
                q: e?.target?.value,
              });
            }}
          />
        </div> */}

        {quickGuideSearchOptions?.show ? (
          <div>
            {_quickGuideSearchOptions?.length > 0 ? (
              _quickGuideSearchOptions?.map((searchOptions, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-row items-center justify-between w-full my-4"
                  >
                    <Text
                      translationKey={searchOptions?.label}
                      classes="text-sm font-semibold text-neutral-500"
                    />
                    <Icon
                      name="NEArrow"
                      className="cursor-pointer text-primary-500"
                      handleClick={() =>
                        navigateToGivenUrl(searchOptions?.redirectionUrl)
                      }
                    />
                  </div>
                );
              })
            ) : (
              <Text
                translationKey={`No matching for "${quickGuideSearchOptions?.q}"`}
                classes="text-sm font-semibold text-neutral-500"
              />
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-6 mt-6">
            {feedback ? (
              <div
                className="flex flex-row items-center gap-3 p-3 rounded-lg cursor-pointer bg-neutral-50"
                onClick={handleFeedbackSectionClick}
              >
                <Icon name="Heart" />

                <div className="flex flex-row items-center gap-2">
                  <Text
                    translationKey="onboarding.giveFeedback"
                    classes="text-neutral-500"
                  />
                  <Icon name="Right" className="text-neutral-500" />
                </div>
              </div>
            ) : null}

            <VideoSection
              videos={videos}
              videoSectionClasses="p-4"
              showPaginatedArrows
            />

            {statusOverviewSection ? (
              <div className="flex flex-col gap-4 mt-2">
                <div className="flex flex-row gap-3">
                  <Icon
                    name={statusOverviewSection?.titleIcon}
                    className="text-primary-500"
                  />

                  <Text
                    translationKey={`${statusOverviewSection?.statusText} at a glance`}
                    classes="text-base font-semibold text-neutral-800"
                  />
                </div>

                <div className="flex flex-col gap-3">
                  {statusOverviewSection?.statusDescriptionList?.map(
                    (statusOverviewItem, index) => {
                      const chipClasses = statusOverviewItem?.chip?.classes;
                      const width =
                        statusOverviewItem?.chip?.label?.length > 10
                          ? "w-max"
                          : "w-14.7";

                      return (
                        <div
                          key={index}
                          className="flex flex-row gap-4 card-wrapper"
                        >
                          <Chip
                            label={statusOverviewItem?.chip?.label}
                            classes={`${chipClasses} ${width}  h-7 items-center justify-center`}
                          />

                          <Text
                            translationKey={statusOverviewItem?.description}
                            classes="text-xs font-semibold text-neutral-500 w-3/5 grow"
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}

            {helpArticles ? (
              <div>
                <div className="flex flex-row gap-3 mb-3">
                  <Icon
                    name={helpArticles?.titleIcon}
                    className="text-primary-500"
                  />

                  <Text
                    translationKey={helpArticles?.title}
                    classes="text-base font-semibold text-neutral-800"
                  />
                </div>

                <div className="flex flex-col gap-3 card-wrapper">
                  {helpArticles?.redirectionList?.map((articleItem, index) => {
                    return (
                      <div
                        key={index}
                        className="flex flex-row items-center justify-between cursor-pointer"
                        onClick={() =>
                          navigateToGivenUrl(articleItem?.redirectionUrl)
                        }
                      >
                        <Text
                          translationKey={articleItem?.title}
                          classes="text-sm font-semibold text-neutral-500"
                        />

                        <Icon name="RightArrow" className="text-neutral-500" />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {otherRelatedTopics ? (
              <div>
                <div className="flex flex-row gap-3 mb-3">
                  <Icon
                    name={otherRelatedTopics?.titleIcon}
                    className="text-primary-500"
                  />

                  <Text
                    translationKey={otherRelatedTopics?.title}
                    classes="text-base font-semibold text-neutral-800"
                  />
                </div>

                <div className="flex flex-col gap-3 card-wrapper">
                  {otherRelatedTopics?.redirectionList?.map(
                    (articleItem, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-row items-center justify-between cursor-pointer"
                          onClick={() =>
                            navigateToGivenUrl(articleItem?.redirectionUrl)
                          }
                        >
                          <Text
                            translationKey={articleItem?.title}
                            classes="text-sm font-semibold text-neutral-500"
                          />

                          <Icon
                            name="RightArrow"
                            className="text-neutral-500"
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}

            {supportEmail ? (
              <div className="card-wrapper">
                <div className="flex flex-row gap-2 mb-3">
                  <Icon name="SupportAgent" className="text-primary-500" />

                  <Text
                    translationKey="onboarding.touchWithSupport"
                    classes="text-base font-semibold text-neutral-800"
                  />
                </div>

                <Text
                  translationKey="onboarding.supportTeamDesc"
                  classes="text-xs font-medium text-neutral-500"
                />

                <div className="mt-5">
                  <div className="flex flex-row py-2 border-b border-neutral-200">
                    <Text
                      translationKey="security.email"
                      classes="text-xs font-medium text-neutral-500 w-1/5"
                    />

                    <Text
                      translationKey={supportEmail}
                      classes="text-xs font-medium text-neutral-800"
                    />
                  </div>

                  <div className="flex flex-row py-2 ">
                    <Text
                      translationKey="security.timings"
                      classes="text-xs font-medium text-neutral-500 w-1/5"
                    />

                    <Text
                      translationKey="security.nineToSix"
                      classes="text-xs font-medium text-neutral-800"
                    />
                  </div>
                </div>

                <Button
                  label="common.chatWithUs"
                  preIcon="Forum"
                  variant="tertiary"
                  iconClasses="text-neutral-500 font-bold"
                  classes="text-neutral-500 font-bold mt-6"
                  size="sm"
                  onClick={() => openChatWindow()}
                />
              </div>
            ) : null}

            {faq ? (
              <div className=" card-wrapper">
                <div className="flex flex-row items-center justify-between gap-2">
                  <div className="flex flex-row items-center gap-2">
                    <Icon name={faq?.titleIcon} className="text-primary-500" />

                    <Text
                      translationKey={faq?.title}
                      classes="text-base font-semibold text-neutral-800"
                    />
                  </div>

                  <Icon
                    name="NEArrow"
                    className="cursor-pointer text-neutral-500"
                    handleClick={() => navigateToGivenUrl(faq?.redirectionUrl)}
                  />
                </div>

                <Text
                  translationKey={faq?.description}
                  classes="text-xs font-medium text-neutral-500"
                />
              </div>
            ) : null}

            {haveSuggestion ? (
              <div
                className="cursor-pointer card-wrapper"
                onClick={() =>
                  navigateToGivenUrl(haveSuggestion?.redirectionUrl)
                }
              >
                <div className="flex flex-row items-center justify-between gap-2">
                  <div className="flex flex-row items-center gap-2">
                    <Icon
                      name={haveSuggestion?.titleIcon}
                      className="text-primary-500"
                    />

                    <Text
                      translationKey={haveSuggestion?.title}
                      classes="text-base font-semibold text-neutral-800"
                    />
                  </div>

                  <Icon
                    name="NEArrow"
                    className="cursor-pointer text-neutral-500"
                  />
                </div>
                <Text
                  translationKey={haveSuggestion?.description}
                  classes="text-xs font-medium text-neutral-500"
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
