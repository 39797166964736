import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";

function SearchBar({ searchQuery, handleChange, classes }) {
  return (
    <div
      className={` flex items-center w-full h-10 px-4 border rounded-lg caret-primary search--inactive focus-within:search--active ${classes}`}
    >
      <div>
        <Icon name="Search" className="w-5 mr-1 text-neutral" />
      </div>
      <input
        type="text"
        placeholder="Search"
        className="w-full ml-1 text-sm font-medium tracking-wide focus:border-none focus:outline-none"
        defaultValue={searchQuery}
        onChange={handleChange}
      />
    </div>
  );
}

export default SearchBar;
SearchBar.propTypes = {
  searchQuery: PropTypes.string,
  handleChange: PropTypes.func,
};
