import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchAndSelectAccountingTransaction,
  updateTagAndVendor,
} from "@/store/reducers/accounting_transactions";
import {
  createRule,
  fetchRuleTransactionsCount,
  resetRuleStore,
} from "@/store/reducers/rules";
import { fetchTags } from "@/store/reducers/tags";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  isFetchingSelectedAccountingTransactionSelector,
  selectedAccountingTransactionSelector,
} from "@/store/selectors/accounting_transactions";
import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";
import {
  isFetchingRulesTransactionsCountSelector,
  rulesTransactionsCountSelector,
} from "@/store/selectors/rules";
import { allTagsSelector } from "@/store/selectors/tags";

import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Loader from "@/components/core/Loader";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import { useForm } from "@/utils/useForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_HEADER_IDS,
  ACCOUNTING_SOFTWARES_ICONS,
  ACCOUNTING_SYNC_ATTEMPT_ENTITY,
  ACCOUNTING_TRANSACTION_ENTITY_TYPES,
  ACCOUNTING_TRANSACTION_PAGES,
} from "@/constants/accounting";
import {
  ACCOUNTING_FIELD_TYPE,
  ACCOUNTING_OWNER_TYPE,
  RULE_TYPES,
  VP_ITEM_TYPE,
} from "@/constants/rules";

export default function CreateNewRuleSlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const accountingId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.createNewRuleFor
  );
  const allTags = useSelector(allTagsSelector);
  const vendors = useSelector(accountingVendorsSelector);
  const fieldType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.fieldType
  );
  const fieldId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.fieldId
  );

  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const [accountingField, setAccountingField] = useState(null);
  const [shouldRunRuleForAll, setShouldRunRuleForAll] = useState(false);
  const selectedAccountingTransaction = useSelector(
    selectedAccountingTransactionSelector
  );
  const rulesConfig = getDataConfigForRules(
    selectedAccountingTransaction?.accountableType
  );
  const transactionType = getTransactionType();
  const isFetching = useSelector(
    isFetchingSelectedAccountingTransactionSelector
  );

  const rulesTransactionsCount = useSelector(rulesTransactionsCountSelector);
  const isFetchingRulesTransactionsCount = useSelector(
    isFetchingRulesTransactionsCountSelector
  );

  const isFieldTag = fieldType === ACCOUNTING_HEADER_IDS.TAG;
  const accountingFieldOptions = isFieldTag
    ? accountingField?.options
    : vendors;

  const volopayFieldsConfig = [
    {
      type: VP_ITEM_TYPE.MERCHANT,
      typeHeader: "Merchant",
      label: rulesConfig?.merchant?.name,
      noOfPendingTransactions: getTransactionCount(VP_ITEM_TYPE.MERCHANT),
      id: rulesConfig?.merchant?.merchantId,
    },
    {
      type: VP_ITEM_TYPE.CATEGORY,
      typeHeader: "Category",
      label: rulesConfig?.category?.name,
      noOfPendingTransactions: getTransactionCount(VP_ITEM_TYPE.CATEGORY),
      id: rulesConfig?.category?.id,
    },
    {
      type: VP_ITEM_TYPE.DEPARTMENT,
      typeHeader: "Department",
      label: rulesConfig?.department?.name,
      noOfPendingTransactions: getTransactionCount(VP_ITEM_TYPE.DEPARTMENT),
      id: rulesConfig?.department?.id,
    },
    {
      type: VP_ITEM_TYPE.LOCATION,
      typeHeader: "Location",
      label: rulesConfig?.location?.name,
      noOfPendingTransactions: getTransactionCount(VP_ITEM_TYPE.LOCATION),
      id: rulesConfig?.location?.id,
    },
  ];

  const initialValue = {
    volopayField: {
      value: volopayFieldsConfig.find((item) => item.id),
      validate: {
        required: true,
      },
    },
    selectedField: {
      value: "",
      validate: {
        required: true,
      },
    },
  };

  function getDataConfigForRules(accountableType) {
    let config = {
      merchant: {
        name: selectedAccountingTransaction?.merchant?.name,
        id: selectedAccountingTransaction?.merchant?.id,
        merchantId: selectedAccountingTransaction?.merchant?.merchantId,
      },
    };
    switch (accountableType) {
      case ACCOUNTING_SYNC_ATTEMPT_ENTITY.REIMBURSEMENT:
        config = {
          ...config,
          category: {
            id: selectedAccountingTransaction?.category?.id,
            name:
              selectedAccountingTransaction?.category?.name ??
              selectedAccountingTransaction?.category?.label,
          },
          department: {
            id: selectedAccountingTransaction?.createdBy?.departmentId,
            name: selectedAccountingTransaction?.createdBy?.departmentName,
          },
          location: {
            id: selectedAccountingTransaction?.createdBy?.locationId,
            name: selectedAccountingTransaction?.createdBy?.locationName,
          },
        };
        return config;
      case ACCOUNTING_SYNC_ATTEMPT_ENTITY.EXPENSE:
      case ACCOUNTING_SYNC_ATTEMPT_ENTITY.QR_PAY:
        config = {
          ...config,
          category: {
            id: selectedAccountingTransaction?.categoryId,
            name: selectedAccountingTransaction?.categoryName,
          },
          department: {
            id: selectedAccountingTransaction?.project?.id,
            name: selectedAccountingTransaction?.project?.name,
          },
          location: {
            id: selectedAccountingTransaction?.location?.id,
            name: selectedAccountingTransaction?.location?.name,
          },
        };
        return config;
      case ACCOUNTING_SYNC_ATTEMPT_ENTITY.PAYROLL:
        config = {
          merchant: {
            id: selectedAccountingTransaction?.vendor?.id,
            name: selectedAccountingTransaction?.vendor?.name,
          },
          category: {
            id: selectedAccountingTransaction?.categoryId,
            name: selectedAccountingTransaction?.categoryName,
          },
          department: {
            id: selectedAccountingTransaction?.project?.id,
            name: selectedAccountingTransaction?.project?.name,
          },
          location: {
            id: selectedAccountingTransaction?.location?.id,
            name: selectedAccountingTransaction?.location?.name,
          },
        };
        return config;
      default:
        return config;
    }
  }

  function getTransactionType() {
    switch (selectedAccountingTransaction?.accountableType) {
      case ACCOUNTING_SYNC_ATTEMPT_ENTITY.EXPENSE:
        return ACCOUNTING_TRANSACTION_PAGES.EXPENSE;
      case ACCOUNTING_SYNC_ATTEMPT_ENTITY.PAYROLL:
        return ACCOUNTING_TRANSACTION_PAGES.PAYROLL;
      case ACCOUNTING_SYNC_ATTEMPT_ENTITY.REIMBURSEMENT:
        return ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT;
      case ACCOUNTING_SYNC_ATTEMPT_ENTITY.QR_PAY:
        return ACCOUNTING_TRANSACTION_PAGES.QR_PAY_PARAM;
      default:
        break;
    }
  }

  function getTransactionCount(categoryType) {
    return rulesTransactionsCount?.find(
      (transaction) => transaction?.category === categoryType
    )?.count;
  }

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    isFormButtonDisabled,
    _setValues: setValues,
  } = useForm(initialValue, () => {
    handleCreateNewRule();
  });

  useEffect(() => {
    setAccountingField(
      isFieldTag
        ? allTags.find((tag) => tag.id === parseInt(fieldId, 10))
        : vendors
    );
  }, [allTags, vendors]);

  useEffect(() => {
    dispatch(fetchAndSelectAccountingTransaction({ accountingId }));
  }, []);

  useEffect(() => {
    if (!allTags?.length) {
      dispatch(fetchTags({ visible: true }));
    }
  }, [allTags]);

  useEffect(() => {
    if (rulesConfig && selectedAccountingTransaction)
      dispatch(
        fetchRuleTransactionsCount({
          category_details: volopayFieldsConfig?.map((config) => ({
            category: config?.type,
            id: config?.id,
          })),
        })
      );
  }, [selectedAccountingTransaction]);

  const onSuccess = () => {
    let payload = {
      accounting_id: accountingId,
      transaction_type: transactionType,
    };
    if (fieldType === ACCOUNTING_HEADER_IDS.TAG)
      payload = {
        ...payload,
        tag_id: accountingField?.id,
        tag_value_id: values?.selectedField,
      };
    else
      payload = {
        ...payload,
        accounting_payee_id: values?.selectedField,
      };
    dispatch(updateTagAndVendor({ payload }));
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.rules.createNewRuleFor
    );
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.fieldType);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.fieldId);
    setSearchParams(searchParams);
    dispatch(resetRuleStore(null));
  };

  const handleCreateNewRule = () => {
    const payload = {
      rule_type: RULE_TYPES.NORMAL,
      all_applicable: shouldRunRuleForAll,
      transaction_id: selectedAccountingTransaction?.accountableId,
      transaction_type: selectedAccountingTransaction?.accountableType,
      rule_item_groups_attributes: [
        {
          rule_items_attributes: [
            {
              item_ids: [values?.volopayField?.id],
              item_type: values?.volopayField?.type,
            },
          ],
          rule_actions_attributes: [
            {
              owner_id: values?.selectedField,
              owner_type: fieldType.includes(ACCOUNTING_FIELD_TYPE.VENDOR)
                ? ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE
                : ACCOUNTING_OWNER_TYPE.TAG_VALUE,
            },
          ],
        },
      ],
    };
    dispatch(createRule({ payload, onSuccess, onSlider: true }));
  };

  return !isFetching ? (
    <div className="slider-content-container">
      <form
        className="flex flex-col slider-content-core gap-9"
        onSubmit={handleSubmit}
        id="create-new-rule-slider-form"
      >
        <div className="flex flex-col gap-1">
          <Text
            translationKey="accounting.rules.createNewRule.title"
            classes="text-3xl font-bold text-neutral-800"
          />
          <Text
            translationKey="accounting.rules.createNewRule.description"
            classes="text-sm font-medium text-neutral-500"
          />
        </div>

        <div className="flex flex-col gap-6">
          <Text
            translationKey="accounting.rules.createNewRule.ifTransactionHas"
            classes="text-lg font-semibold text-neutral-800"
          />
          <div className="grid grid-cols-2 grid-rows-2 gap-5">
            {volopayFieldsConfig?.map((config, index) => (
              <div
                className="flex justify-between p-4 border rounded-lg border-neutral-200"
                key={`create-new-rule-id-${config?.id}`}
              >
                <div className="flex flex-col">
                  <Text
                    translationKey={config?.typeHeader}
                    classes="text-sm font-semibold text-neutral-500"
                  />
                  <Text
                    translationKey={config?.label}
                    classes="text-base font-semibold text-neutral-800"
                  />
                  {!isFetchingRulesTransactionsCount ? (
                    <Text
                      translationKey={
                        config?.id
                          ? "accounting.rules.createNewRule.pendingToVerifyTransactions"
                          : "accounting.rules.createNewRule.unavailable"
                      }
                      translationProps={{
                        count: config?.noOfPendingTransactions,
                      }}
                      classes="text-xs font-semibold text-neutral-500"
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
                <div className="flex items-center justify-end -mt-6">
                  <Radio
                    name="volopayField"
                    insideForm
                    handleChange={() =>
                      setValues((prev) => ({ ...prev, volopayField: config }))
                    }
                    isChecked={values?.volopayField?.type === config?.type}
                    disabled={!config?.id}
                    error={errors?.volopayField}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-6 mb-11">
          <Text
            translationKey={
              isFieldTag
                ? "accounting.rules.createNewRule.accountingTagTitle"
                : "accounting.rules.createNewRule.vendorTitle"
            }
            translationProps={
              isFieldTag
                ? { accountingTag: accountingField?.name }
                : {
                    accountingVendor:
                      ACCOUNTING_SOFTWARES_ICONS[accountingSoftware],
                  }
            }
            classes="text-lg font-semibold text-neutral-800"
          />
          <VpSelect
            name="selectedField"
            label={
              isFieldTag
                ? accountingField?.name
                : "expenses.slider.accountingMerchant"
            }
            labelTranslationProp={{
              accounting: ACCOUNTING_SOFTWARES_ICONS[accountingSoftware],
            }}
            value={values?.selectedField}
            error={errors?.selectedField}
            labelStyleClasses="!text-neutral-500 font-semibold"
            options={accountingFieldOptions}
            optionsDisplayKey="alias"
            valueKey="id"
            menuPosition="absolute"
            handleChange={handleChange}
            insideForm
          />
          <Checkbox
            checked={shouldRunRuleForAll}
            onClickHandler={(checked) => setShouldRunRuleForAll(checked)}
            label="accounting.rules.createNewRule.runRuleForPendingTransactions"
            labelTranslationProp={{
              count: values?.volopayField?.noOfPendingTransactions,
            }}
            labelClasses="w-full text-sm font-medium text-neutral-500"
            classes="flex items-start"
            disabled={isFormButtonDisabled}
          />
        </div>
      </form>
      <div className="flex justify-end px-6 py-4 slider-footer">
        <Button
          label={
            shouldRunRuleForAll
              ? "accounting.rules.newRule.buttonLabels.createAndRunRule"
              : "accounting.rules.newRule.buttonLabels.createRule"
          }
          classes="w-fit px-5 py-3"
          disabled={isFormButtonDisabled}
          btnType="Submit"
          form="create-new-rule-slider-form"
        />
      </div>
    </div>
  ) : (
    <Loader />
  );
}
