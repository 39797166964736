import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  fetchApprovalRequestHistoryInfo,
  resetApprovalStatusHistoryInfo,
} from "@/store/reducers/actionCentre";

import {
  approvalStatusHistoryDetailsDataSelector,
  hasMoreApprovalStatusHistoryInfoSelector,
  isFetchingApprovalStatusHistoryInfoSelector,
} from "@/store/selectors/actionCentre";
import {
  clientSelector,
  primaryCardProviderSelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";
import { sliderAppliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import Chip from "@/components/core/Chip";
import Filters from "@/components/core/Filters";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import Limit from "@/components/Cards/CardsTable/CardTableComponents/Limit";
import { getCardsModuleRequestFilters } from "@/components/Cards/util";
import AmountCellWithConvervesion from "@/components/Company/common/PendingRequest/Cells/AmountCellWithConvervesion";
import BillPayRequestTypeCell from "@/components/Company/common/PendingRequest/Cells/BillPayRequestTypeCell";
import CardRequestTypeCell from "@/components/Company/common/PendingRequest/Cells/CardRequestTypeCell";
import PayrollRequestTypeCell from "@/components/Company/common/PendingRequest/Cells/PayrollRequestTypeCell";
import ReimbursementRequestTypeCell from "@/components/Company/common/PendingRequest/Cells/ReimbursementRequestTypeCell";
import { convertFilters } from "@/utils/filters";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { GET_KEY_PAGE_TYPE, PAGE_TYPE } from "@/utils/constants/payments";
import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
  FILTER_TRIGGER_CONTEXT,
} from "@/utils/constants/filters";
import { dateToString } from "@/utils/common";

import { CARD_REQUEST_TYPE } from "@/constants/ActionCentre";
import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import {
  BE_FE_REQUEST_TYPE_CARD_REQUEST,
  MODULE_REQUEST_TYPES,
  PENDING_REQUEST_TABS_KEYS,
} from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import RequestHistoryLoader from "./RequestHistoryLoader";

export default function RequestHistory() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const requestHistorySliderContext = searchParams.get(
    SLIDERS_SEARCH_PARAMS.myVolopay.actionCentre.requestHistory
  );
  const approvalHistoryList = useSelector(
    approvalStatusHistoryDetailsDataSelector
  );
  const clientInfo = useSelector(primaryCardProviderSelector);
  const isFetching = useSelector(isFetchingApprovalStatusHistoryInfoSelector);
  const hasMore = useSelector(hasMoreApprovalStatusHistoryInfoSelector);
  const appliedFilter = useSelector(sliderAppliedFilterSelector);
  const loggedInUserInfo = useSelector(userSelector);
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);
  const loggedInUserId = loggedInUserInfo?.id;

  const APPROVAL_STATUES = {
    OKAY: "okay",
    NOT_OKAY: "not_okay",
    PENDING_APPROVAL: "pending_approval",
    APPROVED: "approved",
    DENIED: "denied",
    ARCHIVED: "archived",
    AWAITING_FUNDS: "awaiting_funds",
    SCHEDULED: "scheduled",
    UNSCHEDULED: "unscheduled",
    PROCESSING: "processing",
    FAILED: "failed",
    PAID: "paid",
    PAID_OUTSIDE_VOLOPAY: "paid_outside_volopay",
    AUTO_APPROVED: "auto_approved", // happens for example in reimbursement paid_via_card_settlement flow
    IRRELEVANT: "irrelevant",
  };

  const PILL_STATUS_CONFIG = {
    [APPROVAL_STATUES.OKAY]: {
      label: "myVolopay.actionCentre.sliders.requestHistorySlider.pills.okay",
      pillClasses: "text-success-600 bg-success-50 border border-success-200",
    },
    [APPROVAL_STATUES.NOT_OKAY]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.notOkay",
      pillClasses: "text-danger-600 bg-danger-50 border border-danger-200",
    },
    [APPROVAL_STATUES.SCHEDULED]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.scheduled",
      pillClasses: "text-success-600 bg-success-50 border border-success-200",
    },
    [APPROVAL_STATUES.UNSCHEDULED]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.unscheduled",
      pillClasses: "text-neutral-600 border border-neutral-200 bg-neutral-50",
    },
    [APPROVAL_STATUES.ARCHIVED]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.archieved",
      pillClasses: "text-neutral-600 border border-neutral-200 bg-neutral-50",
    },
    [APPROVAL_STATUES.DENIED]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.rejected",
      pillClasses: "text-danger-600 bg-danger-50 border border-danger-200",
    },
    [APPROVAL_STATUES.FAILED]: {
      label: "myVolopay.actionCentre.sliders.requestHistorySlider.pills.failed",
      pillClasses: "text-danger-600 bg-danger-50 border border-danger-200",
    },
    [APPROVAL_STATUES.PROCESSING]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.processing",
      pillClasses: "text-warning-600 bg-warning-50 border border-warning-200",
    },
    [APPROVAL_STATUES.PAID_OUTSIDE_VOLOPAY]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.outsideVolopay",
      pillClasses: "text-neutral-600 border border-neutral-200 bg-neutral-50",
    },
    [APPROVAL_STATUES.PAID]: {
      label: "myVolopay.actionCentre.sliders.requestHistorySlider.pills.paid",
      pillClasses: "text-success-600 bg-success-50 border border-success-200",
    },
    [APPROVAL_STATUES.AUTO_APPROVED]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.autoApproved",
      pillClasses: "text-success-600 bg-success-50 border border-success-200",
    },
    [APPROVAL_STATUES.APPROVED]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.approved",
      pillClasses: "text-success-600 bg-success-50 border border-success-200",
    },
    [APPROVAL_STATUES.AWAITING_FUNDS]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.insufficientFunds",
      pillClasses: "text-danger-600 bg-danger-50 border border-danger-200",
    },
    [APPROVAL_STATUES.PENDING_APPROVAL]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.pending",
      pillClasses: "text-danger-600 bg-danger-50 border border-danger-200",
    },
    [APPROVAL_STATUES.IRRELEVANT]: {
      label:
        "myVolopay.actionCentre.sliders.requestHistorySlider.pills.irrelevant",
      pillClasses: "text-neutral-600 border border-neutral-200 bg-neutral-50",
    },
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore: fetchHistoryInfoWithModuleContext,
    onReset,
    filterOptions: { ...convertFilters(appliedFilter) },
  });

  function onReset() {
    dispatch(resetApprovalStatusHistoryInfo());
  }

  function onScroll() {
    setPageNum((prev) => prev + 1);
  }

  const headerKeys = useMemo(() => {
    return getRespectiveHeadersByTabKey();
  }, []);

  function fetchHistoryInfoWithModuleContext() {
    const requestTypePageContext = {
      [PENDING_REQUEST_TABS_KEYS.CARDS]: MODULE_REQUEST_TYPES.CARD_REQUEST,
      [PENDING_REQUEST_TABS_KEYS.BILL_PAY]:
        MODULE_REQUEST_TYPES.BILL_PAY_REQUEST,
      [PENDING_REQUEST_TABS_KEYS.PAYROLL]: MODULE_REQUEST_TYPES.PAYROLL_REQUEST,
      [PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT]:
        MODULE_REQUEST_TYPES.REIMBURSEMENT_REQUEST,
    };

    dispatch(
      fetchApprovalRequestHistoryInfo({
        page: pageNum,
        user_id: loggedInUserId,
        request_type: requestTypePageContext?.[requestHistorySliderContext],
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...convertFilters(appliedFilter),
      })
    );
  }

  function getRespectiveHeadersByTabKey() {
    switch (requestHistorySliderContext) {
      case PENDING_REQUEST_TABS_KEYS.CARDS:
        return [
          "company.people.pendingRequestTable.cards.requestType",
          "company.people.pendingRequestTable.status",
          "company.people.pendingRequestTable.cards.amount",
        ];

      case PENDING_REQUEST_TABS_KEYS.BILL_PAY:
        return [
          "company.people.pendingRequestTable.billpay.vendor",
          "company.people.pendingRequestTable.status",
          "company.people.pendingRequestTable.billpay.amount",
        ];

      case PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT:
        if (reportEnabled) {
          return [
            "company.people.pendingRequestTable.report.reportName",
            "company.people.pendingRequestTable.status",
            "company.people.pendingRequestTable.report.amount",
          ];
        }
        return [
          "company.people.pendingRequestTable.reimbursement.merchant",
          "company.people.pendingRequestTable.status",
          "company.people.pendingRequestTable.reimbursement.amount",
        ];

      case PENDING_REQUEST_TABS_KEYS.PAYROLL:
        return [
          "company.people.pendingRequestTable.payroll.user",
          "company.people.pendingRequestTable.status",
          "company.people.pendingRequestTable.payroll.amount",
        ];

      default:
        return [
          "company.people.pendingRequestTable.default.pendingRequest",
          "company.people.pendingRequestTable.status",
          "company.people.pendingRequestTable.default.approvers",
        ];
    }
  }

  function getRowsByTabKey(fieldInfo, index) {
    const chipLabel = PILL_STATUS_CONFIG?.[fieldInfo?.status]?.label;
    const chipClasses = PILL_STATUS_CONFIG?.[fieldInfo?.status]?.pillClasses;

    switch (requestHistorySliderContext) {
      case PENDING_REQUEST_TABS_KEYS.CARDS:
        return (
          <tr
            ref={(ref) => {
              if (index === approvalHistoryList.length - 1 && hasMore) {
                handleRefChange(ref);
              }
            }}
            onClick={() => {
              cardsClickHandler(fieldInfo);
            }}
            className="cursor-pointer"
          >
            <td className="text-left">
              <CardRequestTypeCell
                cardType={fieldInfo?.cardType}
                cardRequestType={fieldInfo?.type}
                name={fieldInfo?.name}
                requestDate={fieldInfo?.requestDate}
                cardUsageType={fieldInfo?.cardUsageType}
                nameTextClasses="text-sm font-semibold text-neutral-800"
              />
            </td>

            <td align="center">
              <Chip label={chipLabel} classes={chipClasses} />
            </td>

            <td className="text-right">
              <Limit
                amount={fieldInfo?.amount?.value}
                currency={fieldInfo?.amount?.currency}
                frequency={fieldInfo?.budgetType}
                showFrequency
                amountClasses="text-sm font-semibold text-neutral-800"
                frequencyClasses="text-xs font-medium text-neutral-500"
              />
            </td>
          </tr>
        );

      case PENDING_REQUEST_TABS_KEYS.BILL_PAY:
        return (
          <tr
            ref={(ref) => {
              if (index === approvalHistoryList.length - 1 && hasMore) {
                handleRefChange(ref);
              }
            }}
            className="cursor-pointer"
            onClick={() =>
              billpayPayrollClickHandler(
                PENDING_REQUEST_TABS_KEYS.BILL_PAY,
                fieldInfo?.id
              )
            }
          >
            <td className="text-left">
              <BillPayRequestTypeCell
                vendor={fieldInfo.vendor}
                invoiceRequestDate={fieldInfo?.invoicetDate}
                invoiceRequestType={fieldInfo?.requestType}
                duplicateBillId={fieldInfo?.duplicateBillId}
                showDescriptiveSection=""
              />
            </td>

            <td align="center">
              <Chip label={chipLabel} classes={chipClasses} />
            </td>

            <td className="text-right">
              <AmountCellWithConvervesion
                amountTo={{
                  amount: fieldInfo?.toAmount?.value,
                  currency: fieldInfo?.toAmount?.currency,
                }}
                amountFrom={{
                  amount: fieldInfo?.fromAmount?.value,
                  currency: fieldInfo?.fromAmount?.currency,
                }}
              />
            </td>
          </tr>
        );

      case PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT:
        return (
          <tr
            ref={(ref) => {
              if (index === approvalHistoryList.length - 1 && hasMore) {
                handleRefChange(ref);
              }
            }}
            className="cursor-pointer"
            onClick={() => {
              reimbursementClickHandler(fieldInfo?.id);
            }}
          >
            <td className="text-left">
              {reportEnabled ? (
                <Text
                  translationKey={fieldInfo?.name}
                  classes="font-semibold text-sm"
                />
              ) : (
                <ReimbursementRequestTypeCell
                  distanceTravelled={fieldInfo?.mileage}
                  merchant={fieldInfo?.merchant}
                  reimbursementRequestDate={dateToString(
                    fieldInfo?.reimbursementRequestDate
                  )}
                  reimbursementRequestType={fieldInfo?.reimbursementRequestType}
                  showDescriptiveSection=""
                />
              )}
            </td>

            <td align="center">
              <Chip label={chipLabel} classes={chipClasses} />
            </td>

            <td className="text-right">
              <AmountCellWithConvervesion
                amountTo={fieldInfo?.amount}
                amountFrom={fieldInfo?.claimAmount}
              />
            </td>
          </tr>
        );

      case PENDING_REQUEST_TABS_KEYS.PAYROLL:
        return (
          <tr
            ref={(ref) => {
              if (index === approvalHistoryList.length - 1 && hasMore) {
                handleRefChange(ref);
              }
            }}
            className="cursor-pointer"
            onClick={() =>
              billpayPayrollClickHandler(
                PENDING_REQUEST_TABS_KEYS.PAYROLL,
                fieldInfo?.id
              )
            }
          >
            <td className="text-left">
              <PayrollRequestTypeCell
                user={fieldInfo?.vendor || fieldInfo?.user}
                payrollRequestDate={fieldInfo?.payrollRequestDate}
                showDescriptiveSection=""
              />
            </td>

            <td align="center">
              <Chip label={chipLabel} classes={chipClasses} />
            </td>

            <td>
              <AmountCellWithConvervesion
                amountTo={fieldInfo?.toAmount}
                amountFrom={fieldInfo?.fromAmount}
              />
            </td>
          </tr>
        );

      default:
        break;
    }
  }

  const cardsClickHandler = (fieldInfo) => {
    const searchParamKey =
      fieldInfo?.type === CARD_REQUEST_TYPE.TOP_UP_REQUEST
        ? SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest
        : SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest?.[
            BE_FE_REQUEST_TYPE_CARD_REQUEST[fieldInfo?.type ?? ""]
          ];

    searchParams.append(searchParamKey, fieldInfo?.id);
    setSearchParams(searchParams);
  };

  const billpayPayrollClickHandler = (key, id, extraParams = {}) => {
    const paramName = [
      BILL_PAYROLL_CONTEXT.BILLPAY,
      MODULE_REQUEST_TYPES.BILL_PAY_REQUEST,
    ]?.includes(key)
      ? SLIDERS_SEARCH_PARAMS.payments.id
      : SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId;

    setSearchParams({
      [paramName]: id,
      [SLIDERS_SEARCH_PARAMS.payments.sliderType]: PAGE_TYPE.approvals,
      [SLIDERS_SEARCH_PARAMS.payments.pageType]: GET_KEY_PAGE_TYPE.pending,
      [SLIDERS_SEARCH_PARAMS.payments.sliderType]: PAGE_TYPE.approvals,
      [SLIDERS_SEARCH_PARAMS.payments.pageType]: GET_KEY_PAGE_TYPE.pending,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]: paramName,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled]: true,
    });
  };

  const reimbursementClickHandler = (id) => {
    searchParams.append(
      reportEnabled
        ? SLIDERS_SEARCH_PARAMS.reports.reportId
        : SLIDERS_SEARCH_PARAMS.reimbursements.approveId,
      id
    );
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.reimbursements.pageType,
      GET_KEY_PAGE_TYPE.pending
    );

    setSearchParams(searchParams);
  };

  const headerRef = useLeftHeaderTitle({
    title: "myVolopay.actionCentre.sliders.requestHistorySlider.title",
  });

  const FILTERS_CONFIG = {
    [PENDING_REQUEST_TABS_KEYS.CARDS]: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.cardModuleRequestType,
      AVAILABLE_FILTER_KEYS.cardModuleCardType,
    ],
    [PENDING_REQUEST_TABS_KEYS.BILL_PAY]: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
    ],
    [PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT]: reportEnabled
      ? [AVAILABLE_FILTER_KEYS.searchAndFilter]
      : [
          AVAILABLE_FILTER_KEYS.searchAndFilter,
          AVAILABLE_FILTER_KEYS.claimType,
        ],
    [PENDING_REQUEST_TABS_KEYS.PAYROLL]: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
    ],
  };

  const filters = getCardsModuleRequestFilters(
    FILTERS_CONFIG?.[requestHistorySliderContext],
    clientInfo
  );

  return (
    <div className="px-10">
      <div className="flex flex-col">
        <div ref={headerRef} className="flex flex-col gap-3">
          <Text
            translationKey="myVolopay.actionCentre.sliders.requestHistorySlider.title"
            classes="text-3xl font-bold"
          />

          <Text
            translationKey="myVolopay.actionCentre.sliders.requestHistorySlider.description"
            classes="text-neutral-500 text-sm"
          />
        </div>
      </div>

      <Filters
        filters={filters}
        context={FILTER_TRIGGER_CONTEXT.SLIDER}
        sliderConfig={{
          sliderId: SLIDERS_SEARCH_PARAMS.myVolopay.actionCentre.requestHistory,
          tabName: requestHistorySliderContext,
        }}
      />

      <Table>
        <tr>
          {headerKeys.map((header, index) => {
            return (
              <th
                key={index}
                className={
                  index === 0
                    ? "text-left"
                    : index === 1
                      ? "text-center"
                      : "text-right"
                }
              >
                <Text
                  translationKey={header}
                  classes="font-semibold text-xs text-neutral-800"
                />
              </th>
            );
          })}
        </tr>

        {approvalHistoryList?.map((rowInfo, index) => {
          return getRowsByTabKey(rowInfo, index);
        })}

        {isFetching ? <RequestHistoryLoader /> : null}
      </Table>
    </div>
  );
}
