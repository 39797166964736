import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import API from "@/api";

const categoriesInitialState = {
  categories: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState: categoriesInitialState,
  reducers: {
    resetCategoriesStore: () => categoriesInitialState,
    setCategories(state, action) {
      state.policies = action.payload;
    },
    setCategoriesList(state, action) {
      state.categories.list = action.payload;
    },
    setIsFetching(state, action) {
      state.categories.isFetching = action.payload;
    },
    addCategories(state, action) {
      state.categories.list = [...state.categories.list, ...action.payload];
    },
    setCategoriespage(state, action) {
      state.categories.page = action.payload;
    },
    setCategoriesTotal(state, action) {
      state.categories.total = action.payload;
    },
    setCategoriesHasMore(state) {
      state.categories.hasMore =
        state.categories.list.length < state.categories.total;
    },
    setCategoriesLimit(state, action) {
      state.categories.limit = action.payload;
    },
  },
});

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (params, { dispatch }) => {
    dispatch(setIsFetching(true));

    const [err, response] = await to(API.Categories.all(params));

    if (response?.data) {
      dispatch(setCategoriesList(response?.data));
    }
    dispatch(setIsFetching(false));
  }
);

export const {
  setCategories,
  setCategoriesList,
  setIsFetching,
  addCategories,
  setCategoriespage,
  setCategoriesTotal,
  setCategoriesHasMore,
  setCategoriesLimit,
  resetCategoriesStore,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
