import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  createExpenseAlert,
  deleteExpenseAlert,
  getAlerts,
  getMerchantShallow,
  updateExpenseAlert,
} from "@/store/reducers/cards";
import { fetchCategories } from "@/store/reducers/categories";
import {
  fetchPeopleShallow,
  fetchShallowDepartmentAndProjects,
  fetchShallowDepartments,
} from "@/store/reducers/company";

import {
  expenseAlertsSettingSelector,
  shallowMerchantListSelector,
} from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";
import { defaultCurrencySelector } from "@/store/selectors/client";
import {
  allDepartmentSelector,
  allPeopleSelector,
} from "@/store/selectors/company";

import RulesAndAlertPreviewSlider from "@/components/Cards/common/RulesAndAlertPreviewSlider";
import RulesAndAlertSettingEditSlider from "@/components/Cards/common/RulesAndAlertSettingEditSlider";
import { convertOptionsToRequiredOptionsStructure } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/utils";

import {
  EXPENSE_ALERT_TYPES,
  RULES_AND_ALERT_TYPE,
  SETTINGS_SEARCH_PARAM_VALUE,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { COMPANY_PEOPLE_TAB_PARAMS } from "@/constants/company";

function ExpenseAlertSettings() {
  const dispatch = useDispatch();

  const [searchParam, setSearchParams] = useSearchParams();
  const [componentKey, setComponentKey] = useState(
    searchParam.get(SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings)
  );
  const currency = useSelector(defaultCurrencySelector);
  const people = useSelector(allPeopleSelector);
  const departmentOptions = useSelector(allDepartmentSelector);
  const merchantOptions = useSelector(shallowMerchantListSelector);
  const categoriesOptions = useSelector(categoriesListSelector);
  const expenseAlerts = useSelector(expenseAlertsSettingSelector);

  const [selectedPeople, setSelectedPeople] = useState([]);

  const handleSubmit = (val) => {
    const payload = {
      amount: val?.amount,
      alert_type: RULES_AND_ALERT_TYPE.ALERT,
      alert_items_attributes: [],
      owner_ids: selectedPeople,
    };

    Object.entries(val).map(([key, value]) => {
      if (Array.isArray(value) && value.length > 0 && key !== "people") {
        payload.alert_items_attributes.push({
          owner_type: key === "department" ? "project" : key,
          owner_ids: value,
        });
      }
    });

    if (val?.id) {
      payload.id = val?.id;
      dispatch(
        updateExpenseAlert({
          payload,
          onSuccess: handleSuccessStateOfUpdatingAlertSettings,
        })
      );
    } else {
      dispatch(
        createExpenseAlert({
          payload,
          onSuccess: handleSuccessStateOfUpdatingAlertSettings,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      fetchPeopleShallow({
        status: COMPANY_PEOPLE_TAB_PARAMS.ACTIVE,
      })
    );
    dispatch(fetchShallowDepartmentAndProjects());
    dispatch(getMerchantShallow());
    fetchAlertsList();
    dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    const allSearchParams = searchParam.getAll(
      SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings
    );
    const key = allSearchParams?.at(-1);
    setComponentKey(key);
    if (key === SETTINGS_SEARCH_PARAM_VALUE.PREVIEW && expenseAlerts.length) {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings]:
          SETTINGS_SEARCH_PARAM_VALUE.PREVIEW,
      });
    }
  }, [searchParam]);

  function handleSuccessStateOfUpdatingAlertSettings() {
    fetchAlertsList();

    searchParam.set(
      SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings,
      SETTINGS_SEARCH_PARAM_VALUE.PREVIEW
    );

    searchParam.delete(SLIDERS_SEARCH_PARAMS.cards.cardsettings.editSettingId);
    setSearchParams(searchParam);
  }

  function fetchAlertsList() {
    dispatch(getAlerts({ alert_type: EXPENSE_ALERT_TYPES.ALERT }));
  }

  const getComponent = () => {
    let component;
    switch (componentKey) {
      case SETTINGS_SEARCH_PARAM_VALUE.EDIT:
        component = (
          <RulesAndAlertSettingEditSlider
            mode={RULES_AND_ALERT_TYPE.ALERT}
            selectedPeople={selectedPeople}
            handleSelectedPeople={setSelectedPeople}
            headingColor="warning-600"
            headingClasses="font-medium"
            title="cardSetting.expenseRules.edittitle"
            description="cardSetting.expenseRules.editdescription"
            heading="cardSetting.expenseRules.editheading"
            peopleOptions={people}
            settingValueArray={expenseAlerts}
            handleSubmit={(val) => handleSubmit(val)}
            departmentOptions={convertOptionsToRequiredOptionsStructure(
              departmentOptions
            )}
            categoryOptions={convertOptionsToRequiredOptionsStructure(
              categoriesOptions
            )}
            merchantOptions={convertOptionsToRequiredOptionsStructure(
              merchantOptions
            )}
          />
        );
        break;

      case SETTINGS_SEARCH_PARAM_VALUE.PREVIEW:
        component = (
          <RulesAndAlertPreviewSlider
            mode={RULES_AND_ALERT_TYPE.ALERT}
            headingColor="warning-600"
            title="cardSetting.expenseRules.edittitle"
            description="cardSetting.expenseRules.editdescription"
            heading="cardSetting.expenseRules.editheading"
            currency={currency}
            amount={expenseAlerts?.map((item) => item?.amount)}
            peopleOptions={people}
            handleDelete={(val) => {
              dispatch(deleteExpenseAlert(val.id));
            }}
            isEmpty={!expenseAlerts.length}
            handleReset={() => {
              searchParam.set(
                SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings,
                SETTINGS_SEARCH_PARAM_VALUE.EDIT
              );
              setSearchParams(searchParam);
            }}
            handleEdit={(val) => {
              searchParam.set(
                SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings,
                SETTINGS_SEARCH_PARAM_VALUE.EDIT
              );
              searchParam.set(
                SLIDERS_SEARCH_PARAMS.cards.cardsettings.editSettingId,
                val?.id
              );
              setSearchParams(searchParam);
            }}
            departmentOptions={convertOptionsToRequiredOptionsStructure(
              departmentOptions
            )}
            categoryOptions={convertOptionsToRequiredOptionsStructure(
              categoriesOptions
            )}
            merchantOptions={convertOptionsToRequiredOptionsStructure(
              merchantOptions
            )}
            selectedPeopleArray={expenseAlerts?.map((item) => item?.ownerIds)}
            selectedValueArray={expenseAlerts?.map((item) => ({
              id: item.id,
              category: item.category,
              department: item.project,
              merchant: item.merchant,
            }))}
            onSubmit={() => {
              searchParam.append(
                SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings,
                SETTINGS_SEARCH_PARAM_VALUE.EDIT
              );
              setSearchParams(searchParam);
            }}
          />
        );
        break;
      default:
        break;
    }
    return component;
  };

  return getComponent();
}

export default ExpenseAlertSettings;
