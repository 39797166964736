import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { arraySum } from "@/utils/common";

import { COMPANY_PENDING_ACCOUNTING_ACTION_ENTRIES } from "@/constants/company";

export default function PendingActionsOverview({ usersPendingActionsCounts }) {
  const totalCount = arraySum(Object.values(usersPendingActionsCounts));

  return (
    <div>
      <div className="flex items-center gap-2">
        <Text
          translationKey="company.overview.pendingAction"
          classes="text-base text-neutral-800 font-semibold"
        />
        <div
          className={`grid place-items-center h-6 min-w-[31px] rounded-lg ${
            totalCount === 0 ? "bg-neutral-100" : "bg-primary-500"
          }`}
        >
          <div
            className={`font-bold text-xs px-2 ${
              totalCount === 0 ? "text-neutral-400" : "text-white"
            }`}
          >
            {totalCount > 999 ? "999+" : totalCount}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 py-6 mt-3">
        {COMPANY_PENDING_ACCOUNTING_ACTION_ENTRIES.map(
          (
            { label, icon, key, iconColor, bgColor, path, relatedToAccounting },
            index
          ) => {
            const count = usersPendingActionsCounts[key];

            return count ? (
              <NavLink
                className="flex items-center gap-2 hover:rounded-lg hover:shadow-sm"
                key={`pending-requests-${key}-${index}`}
                to={count === 0 ? "#" : path}
              >
                <div
                  className={`flex justify-center items-center h-6 w-6 p-1 ${bgColor}`}
                >
                  <Icon
                    name={icon}
                    className={`h-full w-full ${
                      count === 0 ? "text-neutral-300" : iconColor
                    }`}
                  />
                </div>
                <Text
                  classes={`grow text-sm font-semibold ${
                    count === 0 ? "text-neutral-400" : "text-neutral-800"
                  }`}
                  translationKey={label}
                />
                <div
                  className={`grid place-items-center h-6 min-w-[29px] rounded-lg ${
                    count === 0 ? "bg-neutral-50" : "bg-primary-50"
                  }`}
                >
                  <div
                    className={`font-bold text-xs ${
                      count === 0 ? "text-neutral-400" : "text-primary-500"
                    }`}
                  >
                    {count > 99 ? "99+" : count}
                  </div>
                </div>
              </NavLink>
            ) : null;
          }
        )}
      </div>
    </div>
  );
}

PendingActionsOverview.propTypes = {
  usersPendingActionsCounts: PropTypes.object,
};
