import PropTypes from "prop-types";

import Text from "@/components/core/Text";

import BoltIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/BoltIcon";

export default function BillOCRSuccess({ showBolt = true }) {
  return (
    <div className="flex gap-3 items-center bg-neutral-50 border-[1px] p-4 border-neutral-300 rounded-md">
      {showBolt ? <BoltIcon /> : null}
      <Text
        translationKey="billPay.bill.invoiceInbox.createBill.sections.ocrSuccess"
        classes="font-semibold"
      />
    </div>
  );
}
BillOCRSuccess.propTypes = {
  showBolt: PropTypes.any,
};
