import PropTypes from "prop-types";

/**
 * History tab of a department (or +3 variations) slider
 *
 * @param {String} sliderData /${id} API data of parent slider
 * @param {String} parentSliderType current slider env (Tab is rendering as Department/Project/BudgetProject)
 */
export default function HistoryTab({ sliderData = null, parentSliderType }) {
  return <div>HistoryTab</div>;
}

HistoryTab.propTypes = {
  sliderData: PropTypes.object,
  parentSliderType: PropTypes.string, // "department" | "project" | "budget" | "location"
};
