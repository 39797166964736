import PropTypes from "prop-types";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import Button from "@/components/core/Button";
import EmptyData from "@/components/core/EmptyData";
import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { amountToCurrency } from "@/utils/common";

export default function MileageRate({
  onClickHandler,
  mileageRateList,
  onEditHandler,
  countriesList,
  onDeleteHandler,
}) {
  const emptyButtonIcon = "AddMileageRate";
  const emptyButtonLabel =
    "reimbursement.settings.sliders.mileageRate.addMileageRate.title";
  const emptyDataTitle =
    "reimbursement.settings.sliders.mileageRate.addMileageRate.emptyDataTitle";
  const emptyDataDescription =
    "reimbursement.settings.sliders.mileageRate.addMileageRate.emptyDataDescription";

  const isEmpty = mileageRateList.length === 0;
  const showFooter = !isEmpty;
  const ref = useLeftHeaderTitle({
    title: "reimbursement.settings.sliders.mileageRate.title",
  });
  return (
    <>
      <div className="flex flex-col slider-content-core gap-9">
        <div ref={ref} className="flex flex-col">
          <Text
            translationKey="reimbursement.settings.sliders.mileageRate.title"
            classes="text-3xl font-bold text-neutral-800"
          />
          <Text
            translationKey="reimbursement.settings.sliders.mileageRate.description"
            classes="text-sm text-neutral-500 mt-1 font-medium"
          />
        </div>
        <div className="flex flex-col h-full">
          {!isEmpty ? (
            <div className="mb-9">
              {mileageRateList.map((mileageRate) => (
                <div
                  key={mileageRate?.id}
                  className="flex items-center gap-4 mb-4"
                >
                  <div className="flex items-center justify-between w-full p-4 space-x-4 border rounded-md border-neutral-200">
                    <ProfileWidget
                      name={mileageRate.countryName}
                      textClasses="font-semibold"
                      iconComponent={
                        <Flag
                          code={
                            countriesList?.find(
                              (country) =>
                                country.isoCode === mileageRate.countryCode
                            )?.currency || mileageRate.currency
                          }
                        />
                      }
                    >
                      <span className="text-sm font-medium text-neutral-500">
                        <Text
                          translationKey="reimbursement.settings.rows.mileageRate.mileageRatePerMile"
                          translationProps={{
                            rate: amountToCurrency(mileageRate.rate),
                            currency: mileageRate.currency,
                          }}
                        />
                      </span>
                    </ProfileWidget>
                    <Icon
                      name="Edit"
                      handleClick={() => onEditHandler(mileageRate)}
                      className="text-neutral-500 mx-1 my-[3px] hover:cursor-pointer active:text-neutral-800"
                    />
                  </div>
                  <Icon
                    name="Delete"
                    handleClick={() => onDeleteHandler(mileageRate.id)}
                    className="text-neutral-500 mx-1 my-[3px] hover:cursor-pointer active:text-neutral-800  "
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center flex-1 ">
              <EmptyData
                buttonIcon={emptyButtonIcon}
                buttonLabel={emptyButtonLabel}
                handleClick={onClickHandler}
                title={emptyDataTitle}
                buttonProps={{
                  classes: "w-18 h-10",
                  iconClasses: "!w-6 !h-6",
                  innerClasses: "gap-1",
                }}
              >
                <Text
                  classes="text-center w-4/5"
                  translationKey={emptyDataDescription}
                />
              </EmptyData>
            </div>
          )}
        </div>
      </div>

      {showFooter ? (
        <div className="flex items-center justify-end gap-3 p-6 slider-footer">
          <Button
            classes="w-16.2 "
            label="reimbursement.settings.sliders.mileageRate.addMileageRate.title"
            buttonIcon={emptyButtonIcon}
            onClick={onClickHandler}
          />
        </div>
      ) : null}
    </>
  );
}
MileageRate.propTypes = {
  onClickHandler: PropTypes.func,
  mileageRateList: PropTypes.array,
  countriesList: PropTypes.array,
  onEditHandler: PropTypes.array,
  onDeleteHandler: PropTypes.array,
};
