import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchCountries } from "@/store/reducers/app";
import { setOrderPhysicalCardFormData } from "@/store/reducers/cards";
import { fetchPeopleShallow } from "@/store/reducers/company";

// selectors
import { countriesSelector } from "@/store/selectors/app";
import { orderPhysicalCardFormDataSelector } from "@/store/selectors/cards";
import {
  cardProviderWithInternationalCardOrderEnabledSelector,
  primaryCardProviderSelector,
} from "@/store/selectors/client";
import { allPeopleSelector } from "@/store/selectors/company";
import { isAdminSelector, userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Chip from "@/components/core/Chip";
import Input from "@/components/core/Input";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import {
  FIELD_TYPE_MULTISELECT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_TEXT,
} from "@/components/GenericForm/common";
import { useForm } from "@/utils/useForm";

// utils, constant file imports
import {
  APPROVED_KYC_STATUS,
  BULK_ORDER_SECTION,
  BULK_PHYSICAL_CARD_OPTIONS,
  BULK_PHYSICAL_CARD_TYPE,
  CARD_PROVIDER,
  INTERNATIONAL_CARD_ORDER_GUIDELINES,
  ORDER_PHYSICAL_CARDS_TYPE,
  SHOW_USER_SELECTED_SECTION,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_TAB_PARAMS,
  COMPANY_PEOPLE_USER_STATUS,
} from "@/constants/company";
import { COUNTRY_STATES } from "@/constants/onboarding";
import { ROUTES } from "@/constants/routes";

import VerticalStepper from "../CardSlider/Common/VerticalStepper";
import BulkPhysicalCard from "./BulkPhysicalCard";

export default function CreatePhysicalCardSlider({ setOnClose }) {
  const peoples = useSelector(allPeopleSelector);
  const dispatch = useDispatch();
  const countryOptions = useSelector(countriesSelector);
  const orderPhysicalCardFormDetails = useSelector(
    orderPhysicalCardFormDataSelector
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const createPhysicalCardParam = searchParams.get(
    SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard
  );
  const loggedInUser = useSelector(userSelector);
  const isAdmin = useSelector(isAdminSelector);
  const primaryCardProvider = useSelector(primaryCardProviderSelector);
  const cardProviderConfigSlug = primaryCardProvider?.slug;
  const cardProviderWithInternationalCardOrderEnabled = useSelector(
    cardProviderWithInternationalCardOrderEnabledSelector
  );
  const isMyVolopayORGettingStartedPage =
    window.location.pathname === ROUTES.myVolopay.gettingStarted.absolutePath ||
    window.location.pathname?.includes(ROUTES.myVolopay.base.absolutePath);
  const isCardForSelectedUser = searchParams.get(
    SLIDERS_SEARCH_PARAMS.cards.selectedUserCard
  );

  const CREATE_PHYSICAL_CARD_FIELDS = {
    ADDRESS_LINE_ONE: "deliveryAddress_1",
    ADDRESS_LINE_TWO: "deliveryAddress_2",
    CITY: "deliveryCity",
    STATE: "deliveryState",
    COUNTRY: "deliveryCountry",
    POSTAL_CODE: "deliveryZipcode",
    CARDS_FROM: "userIds",
    NO_OF_CARDS: "noOfCards",
    BULK_PHYSICAL_CARD_ORDER_TYPE: "bulkPhysicalCardOrderType",
  };

  const CREATE_PHYSICAL_CARD_FIELDS_INITIAL_VALUES = {
    [CREATE_PHYSICAL_CARD_FIELDS.CARD_HOLDER]: {
      value:
        orderPhysicalCardFormDetails[CREATE_PHYSICAL_CARD_FIELDS.CARD_HOLDER] ||
        undefined,
      validate: {
        required: true,
      },
    },

    [CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_ONE]: {
      value:
        orderPhysicalCardFormDetails[
          CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_ONE
        ] || undefined,
      validate: {
        required: true,
      },
    },

    [CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_TWO]: {
      value:
        orderPhysicalCardFormDetails[
          CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_TWO
        ] || undefined,
      validate: {
        required: false,
      },
    },

    [CREATE_PHYSICAL_CARD_FIELDS.CITY]: {
      value:
        orderPhysicalCardFormDetails[CREATE_PHYSICAL_CARD_FIELDS.CITY] ||
        undefined,
      validate: {
        required: true,
      },
    },

    [CREATE_PHYSICAL_CARD_FIELDS.STATE]: {
      value:
        orderPhysicalCardFormDetails[CREATE_PHYSICAL_CARD_FIELDS.STATE] ||
        undefined,
      validate: {
        required: true,
      },
    },

    [CREATE_PHYSICAL_CARD_FIELDS.COUNTRY]: {
      value:
        orderPhysicalCardFormDetails[CREATE_PHYSICAL_CARD_FIELDS.COUNTRY] ||
        countryOptions?.length === 1
          ? countryOptions?.[0].name
          : undefined,
      validate: {
        required: true,
      },
    },

    [CREATE_PHYSICAL_CARD_FIELDS.POSTAL_CODE]: {
      value:
        orderPhysicalCardFormDetails[CREATE_PHYSICAL_CARD_FIELDS.POSTAL_CODE] ||
        undefined,
      validate: {
        required: true,
      },
    },

    [CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM]: {
      value:
        orderPhysicalCardFormDetails[CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM] ||
        [],
      validate: {
        required: true,
      },
    },

    [CREATE_PHYSICAL_CARD_FIELDS.NO_OF_CARDS]: {
      value:
        orderPhysicalCardFormDetails[CREATE_PHYSICAL_CARD_FIELDS.NO_OF_CARDS] ||
        undefined,
      validate: {
        required: true,
        minNumber: 0,
      },
      errorStatement: {
        required: "This a required field",
        minNumber: "No of Cards should be greater than 0",
      },
    },

    [CREATE_PHYSICAL_CARD_FIELDS.BULK_PHYSICAL_CARD_ORDER_TYPE]: {
      value:
        orderPhysicalCardFormDetails[
          CREATE_PHYSICAL_CARD_FIELDS.BULK_PHYSICAL_CARD_ORDER_TYPE
        ] ||
        createPhysicalCardParam ===
          ORDER_PHYSICAL_CARDS_TYPE.BULK_PHYSICAL_CARD_ORDER.key
          ? BULK_PHYSICAL_CARD_TYPE.PERSONALISED_ORDER
          : undefined,
      validate: {
        required: true,
      },
    },
  };

  const CREATE_PHYSICAL_CARD_FIELDS_SCHEMA = {
    [CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_ONE]: {
      type: "text",
      name: CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_ONE,
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryAddressLine1.title",
      category: "delivery",
    },

    [CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_TWO]: {
      type: "text",
      name: CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_TWO,
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryAddressLine2.title",
      category: "delivery",
    },

    [CREATE_PHYSICAL_CARD_FIELDS.CITY]: {
      type: "text",
      name: CREATE_PHYSICAL_CARD_FIELDS.CITY,
      label: "cards.pCards.sliders.createCardSlider.fields.deliveryCity.title",
      category: "delivery",
    },

    [CREATE_PHYSICAL_CARD_FIELDS.STATE]: {
      type: "text",
      name: CREATE_PHYSICAL_CARD_FIELDS.STATE,
      label: "cards.pCards.sliders.createCardSlider.fields.deliveryState.title",
      category: "delivery",
    },

    [CREATE_PHYSICAL_CARD_FIELDS.COUNTRY]: {
      type: "select",
      name: CREATE_PHYSICAL_CARD_FIELDS.COUNTRY,
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryCountry.title",
      options: countryOptions,
      placeholder:
        "cards.pCards.sliders.createCardSlider.fields.deliveryCountry.title",
      optionsDisplayKey: "name",
      valueKey: "name",
      category: "delivery",
      disabled: countryOptions?.length === 1,
    },

    [CREATE_PHYSICAL_CARD_FIELDS.POSTAL_CODE]: {
      type: "number",
      name: CREATE_PHYSICAL_CARD_FIELDS.POSTAL_CODE,
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryPostalCode.title",
      category: "delivery",
    },

    [CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM]: {
      type: "multiselect",
      label: "cards.pCards.sliders.createCardSlider.fields.userIds.multiple",
      options: [],
      name: CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM,
      placeholder:
        "cards.pCards.sliders.createCardSlider.fields.userIds.multiple",
      optionsDisplayKey: "name",
      disabled:
        createPhysicalCardParam ===
          ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key &&
        isCardForSelectedUser,
    },

    [CREATE_PHYSICAL_CARD_FIELDS.NO_OF_CARDS]: {
      name: CREATE_PHYSICAL_CARD_FIELDS.NO_OF_CARDS,
      label: "cards.pCards.sliders.createCardSlider.fields.noOfCards.title",
      type: "number",
    },

    [CREATE_PHYSICAL_CARD_FIELDS.BULK_PHYSICAL_CARD_ORDER_TYPE]: {
      name: CREATE_PHYSICAL_CARD_FIELDS.BULK_PHYSICAL_CARD_ORDER_TYPE,
      label:
        "cards.pCards.sliders.createCardSlider.fields.bulkPhysicalCardOrderType.title",
      type: "bulkOrderSection",
      radioOptions: Object.values(BULK_PHYSICAL_CARD_OPTIONS),
    },
  };

  const COMMON_SCHEMA = [
    CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[
      CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_ONE
    ],

    CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[
      CREATE_PHYSICAL_CARD_FIELDS.ADDRESS_LINE_TWO
    ],

    CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[CREATE_PHYSICAL_CARD_FIELDS.CITY],

    CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[CREATE_PHYSICAL_CARD_FIELDS.STATE],

    CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[CREATE_PHYSICAL_CARD_FIELDS.COUNTRY],

    CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[CREATE_PHYSICAL_CARD_FIELDS.POSTAL_CODE],
  ];

  const CREATE_PHYSICAL_CARD_FIELDS_CONFIGS = {
    [ORDER_PHYSICAL_CARDS_TYPE.BULK_PHYSICAL_CARD_ORDER.key]: {
      defaultSchema: [
        cardProviderConfigSlug !== CARD_PROVIDER.AIRWALLEX &&
          CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[
            CREATE_PHYSICAL_CARD_FIELDS.BULK_PHYSICAL_CARD_ORDER_TYPE
          ],
      ],

      [BULK_PHYSICAL_CARD_TYPE.PERSONALISED_ORDER]: {
        schema: [
          CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[
            CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM
          ],
          ...COMMON_SCHEMA,
        ],
      },

      [BULK_PHYSICAL_CARD_TYPE.NON_PERSONALISED_ORDER]: {
        schema: [
          CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[
            CREATE_PHYSICAL_CARD_FIELDS.NO_OF_CARDS
          ],
          ...COMMON_SCHEMA,
        ],
      },
    },

    [ORDER_PHYSICAL_CARDS_TYPE.PERSONALISED_PHYSICAL_CARD_ORDER.key]: {
      schema: [
        isAdmin && !isMyVolopayORGettingStartedPage
          ? {
              type: "select",
              label:
                "cards.pCards.sliders.createCardSlider.fields.userIds.individual",
              options: [],
              name: CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM,
              placeholder:
                "cards.pCards.sliders.createCardSlider.fields.userIds.individual",
              optionsDisplayKey: "name",
            }
          : {},
        ...COMMON_SCHEMA,
      ],
    },
    [ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key]: {
      schema: [
        CREATE_PHYSICAL_CARD_FIELDS_SCHEMA[
          CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM
        ],
        {
          type: "showUserSelectedSection",
          name: "usersSelected",
        },
      ],
    },
  };

  const ref = useLeftHeaderTitle({
    title:
      cardProviderConfigSlug === CARD_PROVIDER.AIRWALLEX
        ? "Bulk order physical cards"
        : "cards.pCards.sliders.createCardSlider.title",
  });

  const [formSchema, setFormSchema] = useState([]);

  const [selectedPeople, setSelectedPeople] = useState([]);

  const handleContinueBtn = () => {
    // handling scenario of my volopay physical card page when userIds is going empty we have to pass loggedInUser as userId
    const _values = {
      ...values,
      userIds:
        Array.isArray(values?.userIds) && !values?.userIds?.length
          ? [loggedInUser?.id]
          : values?.userIds,
    };
    dispatch(setOrderPhysicalCardFormData(_values));
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.reviewDetails,
      true
    );
    setSearchParams(searchParams);
  };

  const handleCancel = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard
    );
    setSearchParams(searchParams);
  };

  const handleFormClose = () => {
    if (
      !searchParams.get(
        SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.reviewDetails
      )
    ) {
      resetFormData();
    }
  };

  const resetFormData = () => {
    dispatch(setOrderPhysicalCardFormData({}));
  };

  const {
    handleChange,
    values,
    errors,
    _setValues: setValues,
    handleSubmit,
  } = useForm(
    CREATE_PHYSICAL_CARD_FIELDS_INITIAL_VALUES,
    () => {
      handleContinueBtn();
    },
    {
      isFetchingInitialValue: true,
    }
  );

  useEffect(() => {
    const peopleShallowApiParams = {
      shallow: true,
      status: [COMPANY_PEOPLE_TAB_PARAMS.ACTIVE],
      kyc_status: APPROVED_KYC_STATUS,
      ...getCardProviderDependentParamsForUserShallowApi(),
    };

    setSelectedPeople(
      orderPhysicalCardFormDetails?.[CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM] ||
        []
    );

    dispatch(fetchPeopleShallow(peopleShallowApiParams));
    dispatch(fetchCountries({ provider_specific: true }));
  }, []);

  useEffect(() => {
    const _formSchema =
      createPhysicalCardParam ===
      ORDER_PHYSICAL_CARDS_TYPE.BULK_PHYSICAL_CARD_ORDER.key
        ? CREATE_PHYSICAL_CARD_FIELDS_CONFIGS[createPhysicalCardParam]
            .defaultSchema
        : CREATE_PHYSICAL_CARD_FIELDS_CONFIGS[createPhysicalCardParam].schema;

    setFormSchema(_formSchema);

    return handleFormClose;
  }, [searchParams]);

  useEffect(() => {
    if (isCardForSelectedUser) {
      setValues((prev) => {
        const updatedValue = {
          ...prev,
          [CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM]:
            createPhysicalCardParam ===
            ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key
              ? [Number(isCardForSelectedUser)]
              : Number(isCardForSelectedUser),
        };
        return updatedValue;
      });

      setSelectedPeople([Number(isCardForSelectedUser)]);
    }
  }, [isCardForSelectedUser, peoples]);

  useEffect(() => {
    if (
      values?.[CREATE_PHYSICAL_CARD_FIELDS.COUNTRY] === "Australia" ||
      orderPhysicalCardFormDetails?.[CREATE_PHYSICAL_CARD_FIELDS.COUNTRY] ===
        "Australia"
    ) {
      formSchema?.forEach((schemaFiels, index) => {
        if (schemaFiels?.name === CREATE_PHYSICAL_CARD_FIELDS.STATE) {
          formSchema[index].type = "select";
          formSchema[index].optionsDisplayKey = "name";
          formSchema[index].valueKey = "value";
        }
      });

      setFormSchema(formSchema);
    }
  }, [
    values?.[CREATE_PHYSICAL_CARD_FIELDS.COUNTRY],
    orderPhysicalCardFormDetails?.[CREATE_PHYSICAL_CARD_FIELDS.COUNTRY],
    formSchema,
  ]);

  useEffect(() => {
    if (
      [
        BULK_PHYSICAL_CARD_TYPE.NON_PERSONALISED_ORDER,
        BULK_PHYSICAL_CARD_TYPE.PERSONALISED_ORDER,
      ].includes(values?.bulkPhysicalCardOrderType) &&
      values?.bulkPhysicalCardOrderType
    ) {
      setFormSchema([
        ...CREATE_PHYSICAL_CARD_FIELDS_CONFIGS[createPhysicalCardParam]
          .defaultSchema,
        ...(CREATE_PHYSICAL_CARD_FIELDS_CONFIGS[createPhysicalCardParam][
          values?.bulkPhysicalCardOrderType
        ]?.schema ?? []),
      ]);
    }
  }, [values?.bulkPhysicalCardOrderType]);

  function getOptionsByFieldName(name) {
    switch (name) {
      case CREATE_PHYSICAL_CARD_FIELDS.COUNTRY:
        return {
          selectOptions: countryOptions,
          selectFieldDisabled: countryOptions?.length === 1,
        };

      case CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM:
        return {
          selectOptions: getOptionsForPeopleMultiSelect(),
          selectFieldDisabled: !!isCardForSelectedUser,
        };

      case CREATE_PHYSICAL_CARD_FIELDS.STATE:
        return {
          selectOptions: COUNTRY_STATES?.AUS,
        };

      default:
        break;
    }
    return { selectOptions: [], selectFieldDisabled: false };
  }

  function getOptionsForPeopleMultiSelect() {
    return peoples
      ? peoples?.map((people) => {
          const role = people?.role ?? "";
          const departmentName = people?.departmentName;
          const locationName = people?.locationName;
          const showFrozenUserChip =
            people?.status === COMPANY_PEOPLE_USER_STATUS.FREEZED;

          return {
            ...people,
            label: people?.displayName,
            labelJsx: [
              people ? (
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <div className="text-base font-medium text-neutral-500">
                      {people?.displayName}
                    </div>
                    {showFrozenUserChip ? (
                      <div className="ml-3">
                        <Chip label="Frozen" classes="text-neutral-600" />
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-row items-center">
                    <Text
                      translationKey={role}
                      classes="text-xs font-medium text-neutral-400"
                    />

                    <div className="flex flex-row items-center">
                      {role ? (
                        <span className="ml-1 profile-widget-dot bg-neutral-400" />
                      ) : null}

                      <Text
                        translationKey={departmentName}
                        classes="text-xs font-medium text-neutral-400 ml-2"
                      />
                    </div>

                    <div className="flex flex-row items-center">
                      {role || departmentName ? (
                        <span className="ml-1 profile-widget-dot bg-neutral-400" />
                      ) : null}

                      <Text
                        translationKey={locationName}
                        classes="text-xs font-medium text-neutral-400 ml-2"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                []
              ),
            ],
            disabled: showFrozenUserChip,
          };
        })
      : [];
  }

  function getOptionsForMultiSelectField(fieldName) {
    if (fieldName === CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM) {
      return getOptionsForPeopleMultiSelect();
    }
    return [];
  }

  function getHeaderTextByOrderType() {
    if (values[CREATE_PHYSICAL_CARD_FIELDS.BULK_PHYSICAL_CARD_ORDER_TYPE]) {
      if (cardProviderConfigSlug === CARD_PROVIDER.AIRWALLEX) {
        return (
          <div className="flex flex-col gap-2 px-8 mt-6">
            <Text
              classes="text-3xl not-italic font-bold leading-[56px] tracking-[-0.36px] text-neutral-800"
              translationKey="cards.cardDetails.bulkOrderPhysicalCards.title"
              refProp={ref}
            />
            <Text
              classes="text-sm font-medium text-neutral-500"
              translationKey="cards.cardDetails.bulkOrderPhysicalCards.desc"
            />
          </div>
        );
      }

      return (
        <div className="px-8">
          <div ref={ref}>
            <Text
              translationKey="cards.pCards.sliders.createCardSlider.title"
              classes="text-3xl not-italic font-bold leading-[56px] tracking-[-0.36px] text-neutral-800"
            />
          </div>

          <Text
            translationKey={
              createPhysicalCardParam ===
              ORDER_PHYSICAL_CARDS_TYPE.BULK_PHYSICAL_CARD_ORDER.key
                ? "cards.pCards.sliders.createCardSlider.description"
                : "Place your individual card order"
            }
            classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500 pt-2"
          />
        </div>
      );
    }

    return (
      <div className="px-8">
        <div ref={ref}>
          <Text
            translationKey="cards.pCards.sliders.createCardSlider.title"
            classes="text-3xl not-italic font-bold leading-[56px] tracking-[-0.36px] text-neutral-800"
          />
        </div>

        <Text
          translationKey="cards.cardDetails.bulkOrderPhysicalCards.placeIndividualCardOrder"
          classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500 pt-2"
        />
      </div>
    );
  }

  function getSelectedUsersInfoComponent() {
    const selectedUserInfo = [];

    values?.[CREATE_PHYSICAL_CARD_FIELDS.CARDS_FROM]?.forEach((userId) => {
      const isPresent = peoples?.find(
        (people) => Number(people?.id) === Number(userId)
      );

      if (isPresent) {
        selectedUserInfo?.push(isPresent);
      }
    });

    return selectedUserInfo?.length > 0 ? (
      <div className="flex flex-col gap-4 p-3 rounded bg-neutral-100">
        <div className="flex flex-col">
          <Text
            translationKey="cards.cardSlider.internationalCard.orderPhysicalCard.selectedUserSection.title"
            classes="font-semibold text-sm text-neutral-800"
          />

          <Text
            translationKey="cards.cardSlider.internationalCard.orderPhysicalCard.selectedUserSection.description"
            classes="font-medium text-sm text-neutral-500"
          />
        </div>

        <div className="flex flex-col gap-2">
          {selectedUserInfo?.map((person, index) => {
            return (
              <div
                className="flex flex-row items-center justify-between"
                key={`${person?.id}-${index}`}
              >
                <Text
                  translationKey={person?.displayName}
                  classes="font-medium text-base text-neutral-800"
                />
                <Text
                  translationKey={person?.email}
                  classes="font-medium text-base text-neutral-500"
                />
              </div>
            );
          })}
        </div>
      </div>
    ) : null;
  }

  function getCardProviderDependentParamsForUserShallowApi() {
    const params = {
      without_physical_card: null,
      without_physical_card_request: null,
    };

    if (
      createPhysicalCardParam !==
      ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key
    ) {
      params.without_physical_card = primaryCardProvider?.id;
      params.without_physical_card_request = primaryCardProvider?.id;
    } else {
      params.without_physical_card =
        cardProviderWithInternationalCardOrderEnabled?.id;
      params.without_physical_card_request =
        cardProviderWithInternationalCardOrderEnabled?.id;
    }

    return params;
  }

  const showInstructionsForInternationalCardOrder =
    (cardProviderWithInternationalCardOrderEnabled?.config
      ?.showInstructionsForInternationalCardOrder &&
      [
        ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key,
      ].includes(createPhysicalCardParam)) ??
    false;

  return (
    <>
      <div className="flex flex-col p-0 m-0 slider-content-core ">
        {getHeaderTextByOrderType()}

        <VerticalStepper
          stepperList={INTERNATIONAL_CARD_ORDER_GUIDELINES}
          sectionHeaderTitle="cards.cardSlider.internationalCard.orderPhysicalCard.sectionHeaderTitle"
          sectionFooterText="cards.cardSlider.internationalCard.orderPhysicalCard.sectionFooterText"
          show={showInstructionsForInternationalCardOrder}
          sectionClasses="m-8"
        />

        <form
          onSubmit={handleSubmit}
          id="create_physical_card"
          className="p-8 pt-0"
        >
          {formSchema.map((field, index) => {
            const {
              name,
              label,
              radioOptions,
              type,
              placeholder,
              optionsDisplayKey,
              valueKey,
              category,
              disabled = false,
            } = field;
            const showBillCategoryField =
              category === "billing"
                ? values[CREATE_PHYSICAL_CARD_FIELDS.POST_BOX_ADDRES] === "No"
                : true;

            const { selectFieldDisabled, selectOptions } =
              type === FIELD_TYPE_SELECT
                ? getOptionsByFieldName(name)
                : () => {};

            let FieldComponent = <></>;

            switch (type) {
              case FIELD_TYPE_TEXT:
              case FIELD_TYPE_NUMBER:
                FieldComponent = (
                  <Input
                    name={name}
                    label={label}
                    placeholder={label}
                    value={values[name]}
                    error={errors[name]}
                    onChange={handleChange}
                    type={type}
                  />
                );
                break;

              case BULK_ORDER_SECTION:
                FieldComponent = (
                  <BulkPhysicalCard
                    key={index}
                    radioOptions={radioOptions}
                    name={name}
                    handleChange={handleChange}
                    error={errors[name]}
                    value={values[name]}
                  />
                );
                break;

              case FIELD_TYPE_SELECT:
                FieldComponent = (
                  <VpSelect
                    name={name}
                    label={label}
                    options={selectOptions}
                    placeholder={placeholder}
                    insideForm
                    menuPosition="absolute"
                    handleChange={(e) => {
                      handleChange(e);
                    }}
                    value={values[name]}
                    optionsDisplayKey={optionsDisplayKey}
                    error={errors[name]}
                    valueKey={valueKey}
                    disabled={selectFieldDisabled}
                  />
                );
                break;

              case SHOW_USER_SELECTED_SECTION:
                FieldComponent = getSelectedUsersInfoComponent();
                break;

              case FIELD_TYPE_MULTISELECT:
                FieldComponent = (
                  <CheckboxDropdown
                    error={errors?.[name]}
                    disabled={disabled}
                    mode="grouped"
                    label={label}
                    dropdownLabelFastView=""
                    dropdownLabelFlatView=""
                    selectedIdsArray={[...selectedPeople]}
                    handleSubmit={(e) => {
                      handleChange({
                        target: {
                          name,
                          value: e?.target?.value?.selectedIds,
                          type,
                        },
                      });
                      setSelectedPeople(e?.target?.value?.selectedIds ?? []);
                    }}
                    options={getOptionsForMultiSelectField(name)}
                    name={name}
                    insideForm
                  />
                );
                break;

              case "postBoxAddressRadioOptions":
                FieldComponent = (
                  <div>
                    <div className="mb-3">
                      <Text translationKey={label} />
                    </div>
                    <div>
                      {radioOptions.map((radioOption, id) => {
                        return (
                          <Radio
                            name={name}
                            label={radioOption.label}
                            key={id}
                            handleChange={handleChange}
                            value={radioOption.value}
                            isChecked={values[name] === radioOption.value}
                            error={errors[name]}
                            textClases={
                              id < radioOptions.length - 1 ? "mb-3" : ""
                            }
                            insideForm
                          />
                        );
                      })}
                    </div>
                  </div>
                );
                break;

              default:
                break;
            }
            return (
              showBillCategoryField && (
                <div
                  className={` ${disabled ? "pointer-events-none" : ""} my-9`}
                >
                  {FieldComponent}
                </div>
              )
            );
          })}
        </form>
      </div>

      <div className="px-3 py-5 slider-footer">
        <div className="flex items-center justify-end gap-3">
          <Button
            label="cards.pCards.sliders.createCardSlider.buttons.cancel"
            classes="w-16 text-neutral-500 font-medium"
            variant="tertiary"
            onClick={handleCancel}
          />
          <Button
            label="cards.pCards.sliders.createCardSlider.buttons.continue"
            classes="w-16 text-white font-medium"
            variant="primary"
            btnType="submit"
            form="create_physical_card"
          />
        </div>
      </div>
    </>
  );
}

CreatePhysicalCardSlider.propTypes = {
  setOnClose: PropTypes.func,
};
