import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  fetchFailedToSyncTransactions,
  resetFailedToSyncTransactions,
} from "@/store/reducers/accounting_transactions";

import {
  failedToSyncTransactionsSelector,
  hasMoreFailedToSyncTransactionsSelector,
  isFetchingFailedToSyncTransactionsSelector,
} from "@/store/selectors/accounting_transactions";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { amountToCurrency, groupByDate } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_TRANSACTION_PAGES,
  FAILED_TO_SYNC_ACCOUNTING_TRANSACTION_MAP,
  FAILED_TO_SYNC_TABLE_HEADERS,
} from "@/constants/accounting";
import { OTHER_TYPES_ICON_AND_TITLE_MAP } from "@/constants/other";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import FailedToSyncTransactionLoader from "../Loaders/FailedToSyncTransactionLoader";

export default function FailedToSyncSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const hasMore = useSelector(hasMoreFailedToSyncTransactionsSelector);
  const isFetching = useSelector(isFetchingFailedToSyncTransactionsSelector);
  const failedToSyncTransactions = useSelector(
    failedToSyncTransactionsSelector
  );
  const groupedFailedTransactions = groupByDate(
    failedToSyncTransactions,
    "updatedAt"
  );
  const isEmpty = groupedFailedTransactions.length === 0;
  const titleRef = useLeftHeaderTitle({
    title: "accounting.transactions.failedToSync.title",
  });
  const currentSearchParam = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.failedToSync
  );
  const transaction =
    FAILED_TO_SYNC_ACCOUNTING_TRANSACTION_MAP[currentSearchParam];

  const loadMore = () => {
    dispatch(
      fetchFailedToSyncTransactions({
        transaction,
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
      })
    );
  };

  const onReset = () => {
    dispatch(resetFailedToSyncTransactions());
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    inSlider: true,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  const getRows = (transactions) => {
    return transactions?.map((item, index) => (
      <tr
        ref={(reff) => {
          if ((transactions.length || 0) - 1 === index && hasMore)
            return handleRefChange(reff);
        }}
        key={`failed-to-sync-transaction-slider-item-${index}`}
        onClick={() => handleViewFailedToSyncTransaction(transaction, item?.id)}
        className="cursor-pointer"
      >
        <td className="py-3 vp-core-table-cell">
          <div className="flex items-center">
            <div>
              <ProfileWidget
                avatarSize="sm"
                img={item?.transactionDetails?.cardOwner?.avatarUrl}
              />
            </div>

            <div className="flex flex-col font-semibold text-neutral-800">
              <Text
                translationKey={
                  item?.transactionDetails?.merchant?.name ||
                  item?.transactionDetails?.vendor?.name ||
                  OTHER_TYPES_ICON_AND_TITLE_MAP[
                    item?.transactionDetails?.transactionType
                  ]?.title
                }
                classes="text-sm"
              />
              <Text
                translationKey={amountToCurrency(
                  item?.transactionDetails?.amount,
                  item?.transactionDetails?.currency
                )}
                classes="text-xs text-neutral-500"
              />
            </div>
          </div>
        </td>
        <td className="py-3 text-left vp-core-table-cell ">
          <div className="flex items-center justify-between">
            <Text
              id={`failed-reason-${item?.id}`}
              translationKey={item?.accountingFailReason}
              classes="text-sm w-60  font-semibold text-neutral-800 truncate"
            />
            <Icon name="Error" className="text-danger-400" />
          </div>

          <Tooltip
            id={`failed-reason-${item?.id}`}
            direction="top"
            maxWidth="20rem"
          >
            <Text translationKey={item?.accountingFailReason} />
          </Tooltip>
        </td>
      </tr>
    ));
  };
  const handleViewFailedToSyncTransaction = (transactionType, id) => {
    switch (transactionType) {
      case ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL:
        searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.billPay.id, id);
        setSearchParams(searchParams);
        break;
      case ACCOUNTING_TRANSACTION_PAGES.EXPENSE:
        searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.cards.id, id);
        setSearchParams(searchParams);
        break;
      case ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.accounting.reimbursement.id,
          id
        );
        setSearchParams(searchParams);
        break;
      case ACCOUNTING_TRANSACTION_PAGES.OTHER:
        searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.others.id, id);
        setSearchParams(searchParams);
        break;
      case ACCOUNTING_TRANSACTION_PAGES.QR_PAY:
        searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.qrpay.id, id);
        setSearchParams(searchParams);
        break;
      case ACCOUNTING_TRANSACTION_PAGES.PAYROLL:
        searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.payroll.id, id);
        setSearchParams(searchParams);
        break;
      default:
        break;
    }
  };
  return (
    <div className="slider-content-core">
      <section className="flex flex-col mb-5">
        <Text
          classes="font-bold text-3xl"
          translationKey="accounting.transactions.failedToSync.title"
          refProp={titleRef}
        />
        <Text
          classes="font-medium text-sm text-neutral-500"
          translationKey="accounting.transactions.failedToSync.description"
        />
      </section>
      <Table headerSticky colWidths={[200, 200, 200, 200, 300]}>
        <tr>
          {FAILED_TO_SYNC_TABLE_HEADERS?.map((header, index) => (
            <th
              className={`text-xs font-semibold ${header.class} text-left`}
              key={`failed-to-sync-${index}-header`}
            >
              <Text translationKey={header} />
            </th>
          ))}
        </tr>
        {!isEmpty || !isFetching
          ? Object.entries(groupedFailedTransactions)?.map(
              ([key, value], index) => (
                <>
                  <tr
                    className="vp-core-table-body-row h-8.5"
                    key={`pending-invite-header-${index}`}
                  >
                    <th className="text-xs font-medium text-left vp-core-table-cell text-neutral-500">
                      {key}
                    </th>
                    <td
                      className="vp-core-table-cell"
                      aria-label="render-empty-space"
                    />
                  </tr>
                  {getRows(value)}
                </>
              )
            )
          : null}
        {isFetching ? <FailedToSyncTransactionLoader /> : null}
      </Table>
    </div>
  );
}
