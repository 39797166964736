import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  createExpenseAlert,
  deleteFlagRule,
  getFlagRules,
  getMerchantShallow,
  updateFlagRule,
} from "@/store/reducers/cards";
import { fetchCategories } from "@/store/reducers/categories";
import { fetchShallowDepartmentAndProjects } from "@/store/reducers/company";

import {
  flagRulesSettingSelector,
  shallowMerchantListSelector,
} from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";
import { defaultCurrencySelector } from "@/store/selectors/client";
import { allDepartmentSelector } from "@/store/selectors/company";

import RulesAndAlertPreviewSlider from "@/components/Cards/common/RulesAndAlertPreviewSlider";
import RulesAndAlertSettingEditSlider from "@/components/Cards/common/RulesAndAlertSettingEditSlider";
import { convertOptionsToRequiredOptionsStructure } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/utils";

import {
  EXPENSE_ALERT_TYPES,
  SETTINGS_SEARCH_PARAM_VALUE,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function FlagRulesSettingsSlider() {
  const dispatch = useDispatch();
  const [searchParam, setSearchParams] = useSearchParams();
  const departmentOptions = useSelector(allDepartmentSelector);
  const merchantOptions = useSelector(shallowMerchantListSelector);
  const categoriesOptions = useSelector(categoriesListSelector);
  const flagRules = useSelector(flagRulesSettingSelector);

  const currency = useSelector(defaultCurrencySelector);
  const [componentKey, setComponentKey] = useState(
    searchParam.get(SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings)
  );

  useEffect(() => {
    dispatch(fetchShallowDepartmentAndProjects());
    dispatch(getMerchantShallow());
    dispatch(fetchCategories());
    fetchFlagRules();
  }, []);

  useEffect(() => {
    const allSearchParams = searchParam.getAll(
      SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings
    );
    const key = allSearchParams[allSearchParams.length - 1];
    setComponentKey(key);
    if (key === SETTINGS_SEARCH_PARAM_VALUE.PREVIEW && flagRules.length) {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings]:
          SETTINGS_SEARCH_PARAM_VALUE.PREVIEW,
      });
    }
  }, [searchParam]);
  const handleSubmit = (val) => {
    const payload = {
      amount: val?.amount,
      alert_type: "flag",
      alert_items_attributes: [],
    };

    Object.entries(val).map(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        payload.alert_items_attributes.push({
          owner_type: key === "department" ? "project" : key,
          owner_ids: value,
        });
      }
    });

    if (val?.id) {
      payload.id = val?.id;
      dispatch(
        updateFlagRule({
          payload,
          onSuccess: handleSuccessOfUpadatingFlagRules,
        })
      );
    } else {
      dispatch(
        createExpenseAlert({
          payload,
          onSuccess: handleSuccessOfUpadatingFlagRules,
        })
      );
    }
  };

  function handleSuccessOfUpadatingFlagRules() {
    fetchFlagRules();
    setSearchParams({
      [SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings]:
        SETTINGS_SEARCH_PARAM_VALUE.PREVIEW,
    });
  }

  function fetchFlagRules() {
    dispatch(getFlagRules({ alert_type: EXPENSE_ALERT_TYPES.FLAG }));
  }

  const getComponent = () => {
    let component;
    switch (componentKey) {
      case SETTINGS_SEARCH_PARAM_VALUE.EDIT:
        component = (
          <RulesAndAlertSettingEditSlider
            headingColor="danger-400"
            title="cardSetting.flagRules.editTitle"
            handleSubmit={(val) => handleSubmit(val)}
            description="cardSetting.flagRules.editDescription"
            heading="cardSetting.flagRules.heading"
            settingValueArray={flagRules}
            departmentOptions={convertOptionsToRequiredOptionsStructure(
              departmentOptions
            )}
            categoryOptions={convertOptionsToRequiredOptionsStructure(
              categoriesOptions
            )}
            merchantOptions={convertOptionsToRequiredOptionsStructure(
              merchantOptions
            )}
          />
        );
        break;

      case SETTINGS_SEARCH_PARAM_VALUE.PREVIEW:
        component = (
          <RulesAndAlertPreviewSlider
            headingColor="danger-400"
            title="cardSetting.flagRules.editTitle"
            description="cardSetting.flagRules.editDescription"
            heading="cardSetting.flagRules.heading"
            currency={currency}
            isEmpty={!flagRules.length}
            handleReset={() => {
              searchParam.set(
                SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings,
                SETTINGS_SEARCH_PARAM_VALUE.EDIT
              );

              setSearchParams(searchParam);
            }}
            amount={flagRules?.map((item) => item?.amount)}
            handleDelete={(val) => {
              dispatch(deleteFlagRule(val.id));
            }}
            handleEdit={(val) => {
              searchParam.set(
                SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings,
                SETTINGS_SEARCH_PARAM_VALUE.EDIT
              );
              searchParam.set(
                SLIDERS_SEARCH_PARAMS.cards.cardsettings.editSettingId,
                val?.id
              );
              setSearchParams(searchParam);
            }}
            departmentOptions={convertOptionsToRequiredOptionsStructure(
              departmentOptions
            )}
            categoryOptions={convertOptionsToRequiredOptionsStructure(
              categoriesOptions
            )}
            merchantOptions={convertOptionsToRequiredOptionsStructure(
              merchantOptions
            )}
            selectedValueArray={flagRules?.map((item) => ({
              id: item.id,
              category: item?.category,
              department: item?.project,
              merchant: item?.merchant,
            }))}
            onSubmit={() => {
              searchParam.append(
                SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings,
                SETTINGS_SEARCH_PARAM_VALUE.EDIT
              );

              setSearchParams(searchParam);
            }}
          />
        );
        break;
      default:
        break;
    }
    return component;
  };
  return getComponent();
}

export default FlagRulesSettingsSlider;
