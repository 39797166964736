import PropTypes from "prop-types";

import Text from "@/components/core/Text";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";

export default function PaymentMethodCell({ val }) {
  const text1 =
    val?.transactionStatus === PAYMENT_STATUSES.outsideVolopay
      ? "billPay.bill.payments.paymentFooter.sliderStatusText.outsideVolopay"
      : val?.paymentMethod?.type || "-";
  const text2 =
    val?.transactionStatus === PAYMENT_STATUSES.outsideVolopay
      ? "settings.masterHistory.activityType.other"
      : val?.paymentMethod?.name || val?.paymentMethod?.value;

  return (
    <div>
      <div className="text-sm font-semibold text-neutral-800">
        <Text translationKey={text1} />
      </div>

      {text2 && text1 !== text2 ? (
        <span className="text-xs font-medium text-neutral-500">
          <Text translationKey={text2} />
        </span>
      ) : null}
    </div>
  );
}

PaymentMethodCell.propTypes = {
  val: PropTypes.object,
};
