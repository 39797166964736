import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { decode } from "he";
import FilePreviewComment from "@/components/core/Comments/FilePreviewComment";

import FileViewer from "@/components/core/FileViewer";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { COMMENT_TYPE, COMMENT_TYPE_OBJECT } from "@/utils/constants/comments";
import { capitalizeFirstLetter } from "@/utils/common";

export default function CommentDisplayChildHelper({
  user,
  time,
  data,
  options,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col w-full gap-3 pb-8">
      <div className="flex flex-col justify-start flex-1 pr-2 ">
        <div className="flex items-center justify-between flex-1 pb-2 pr-2">
          {user ? (
            <ProfileWidget
              name={user?.displayName}
              img={user?.avatarUrl}
              avatarColor={user?.colorCode}
              textClasses="font-semibold"
              timeStampAboveName={time}
            />
          ) : (
            <ProfileWidget
              textClasses="text-neutral-500 font-medium text-sm"
              iconComponent={<Icon name="VolopayLogo" className="w-9 h-9" />}
            >
              <Text
                classes=" font-semibold first-letter:uppercase"
                translationKey="common.volopay"
              />
            </ProfileWidget>
          )}
        </div>

        {data
          ?.map((comment, index) => {
            let config = {};
            const repaymentReceivedOrRejected = [
              COMMENT_TYPE.REPAYMENT_RECEIVED,
              COMMENT_TYPE.REPAYMENT_REQUESTED,
              COMMENT_TYPE.APPROVED,
            ].includes(comment?.commentType);

            if (repaymentReceivedOrRejected) {
              config = COMMENT_TYPE_OBJECT[comment?.commentType];

              const currentComment = data.find(
                (item) => item?.comment?.commentType === comment?.commentType
              );

              config.text = currentComment?.comment;
            } else {
              config = COMMENT_TYPE_OBJECT[comment?.commentType];
            }

            return (
              <div
                className="border-l-[1.5px] border-neutral-200 pl-8 ml-5 py-1 flex flex-col gap-2 "
                key={`single-user-comments-${comment.id || index}`}
              >
                {comment?.commentType !== COMMENT_TYPE.USER_CREATED ? (
                  <div className="flex items-center justify-start gap-2 font-medium">
                    <Icon name={config?.icon} className={config?.class} />
                    <Text
                      translationKey={
                        config?.text ||
                        capitalizeFirstLetter(
                          comment?.additionalInfo || comment?.comment
                        )
                      }
                      classes={`${
                        comment?.additionalInfo
                          ? config?.additionalClass
                          : config?.class
                      }`}
                    />
                  </div>
                ) : null}
                {config && !repaymentReceivedOrRejected ? (
                  <div className="mr-auto font-medium text-neutral-500">
                    {decode(comment?.comment)}
                  </div>
                ) : null}
                {comment?.attachments?.map((attachment) => {
                  return (
                    <FilePreviewComment
                      key={`comment-preview-attachment-${comment?.id}-${attachment?.id}`}
                      file={attachment}
                      setShowModal={setShowModal}
                      setSelectedFile={setSelectedFile}
                    />
                  );
                })}
              </div>
            );
          })
          .reverse()}
      </div>

      <FileViewer
        isModalView
        defaultZoom={0.3}
        files={[selectedFile]}
        modalVisible={showModal}
        handleModalBackClick={() => {
          setShowModal(false);
        }}
      />
    </div>
  );
}
CommentDisplayChildHelper.propTypes = {
  user: PropTypes.object,
  time: PropTypes.string,
  data: PropTypes.array,
  options: PropTypes.object,
};
