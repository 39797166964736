import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { pricingPlanUrlSelector } from "@/store/selectors/company";

import Accordion from "@/components/core/Accordion";
import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import { BILLING_DETAILS, INVOICE_KEYS } from "@/utils/constants/company";
import { amountToCurrency } from "@/utils/common";

/**
 * BillingDetailsTable Component displays billing details including plan details and payment details.
 *
 * This component takes planDetails, paymentDetails, and a boolean showPlanDetails to determine
 * whether to display plan details when we open the slider.It also provides an option to open pricing plans in a new window.

 * @param {Object} props.planDetails - Object containing details about the billing plan.
 * @param {Array} props.paymentDetails - Array containing payment details.
 * @param {boolean} props.showPlanDetails - Boolean indicating whether to display plan details.

 */

const BillingDetailsTable = ({
  planDetails,
  paymentDetails,
  showPlanDetails,
}) => {
  const PLAN_TYPE = { card: "Card", bill_pay: "Bill pay" };
  const PLAN_VARIANT = {
    card: "primary",
    bill_pay: "success",
    general: "neutral",
  };

  const pricingUrl = useSelector(pricingPlanUrlSelector);

  const PLAN_DETAILS_DATA = [
    {
      key: INVOICE_KEYS.PLAN_NAME,
      label: "company.billing.planDetailsTable.tableDataLabel.planName",
      value: (
        <div>
          {planDetails?.map((plan, ind) => (
            <div
              key={`${plan?.planName}-${plan?.planType}`}
              className={`flex justify-end text-sm ${ind === 0 ? "" : "mt-1"}`}
            >
              {plan.planName}
              <Badge
                classes="w-14 ml-2"
                variant={PLAN_VARIANT[plan?.planType]}
                translationKey={`company.billing.badge.${plan?.planType}`}
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      key: INVOICE_KEYS.PLAN_FREQ,
      label: "company.billing.planDetailsTable.tableDataLabel.planFrequency",
      value: planDetails?.map((plan) => plan.planFrequency),
    },
    {
      key: INVOICE_KEYS.SAAS_FEE,
      label: "company.billing.planDetailsTable.tableDataLabel.saaSFee",
      value: planDetails?.map((plan) => plan.saasFee),
    },
    {
      key: INVOICE_KEYS.MONTHLY_SPEND_LIMIT,
      label:
        "company.billing.planDetailsTable.tableDataLabel.monthlySpendLimit",
      value: planDetails?.map((plan) => plan?.monthlySpendLimit),
    },
    {
      key: INVOICE_KEYS.BILLING_CYCLE,
      label: "company.billing.planDetailsTable.tableDataLabel.billingCycle",
      value: planDetails?.[0]?.billingCycle,
    },
  ];

  const isExpandable = (key) =>
    [
      INVOICE_KEYS.SAAS_FEE,
      INVOICE_KEYS.MONTHLY_SPEND_LIMIT,
      INVOICE_KEYS.PLAN_FREQ,
    ].includes(key);

  const rightLabel = (data) => {
    if (data.key === INVOICE_KEYS.SAAS_FEE) {
      const total = data?.value.reduce((acc, cur) => {
        return acc + parseFloat(cur.value);
      }, 0);
      return amountToCurrency(total, data?.value?.[0]?.currency);
    }
  };

  const PAYMENT_DETAILS_DATA = paymentDetails;
  const createBillingDetailsData = (plan, payment) => {
    const result = [];
    if (showPlanDetails) result.push(plan);
    if (paymentDetails) result.push(payment);
    return result;
  };
  const PLAN_DETAILS_OBJECT = {
    id: BILLING_DETAILS.PLAN_DETAILS,
    label: "company.billing.planDetailsTable.title",
    value: PLAN_DETAILS_DATA,
  };
  const PAYMENT_DETAILS_OBJECT = {
    id: BILLING_DETAILS.PAYMENT_DETAILS,
    label: "company.billing.paymentDetailsTable.title",
    value: PAYMENT_DETAILS_DATA,
  };
  const dataDetails = createBillingDetailsData(
    PLAN_DETAILS_OBJECT,
    PAYMENT_DETAILS_OBJECT
  );
  const openPricingPlan = () => {
    window.open(pricingUrl, "_blank");
  };

  const showTableValue = (data) => {
    if (typeof data?.value === typeof []) {
      if (INVOICE_KEYS.PLAN_NAME === data?.key) return data?.value;
      if (INVOICE_KEYS.PLAN_FREQ === data?.key)
        return (
          <Text
            classes="text-sm capitalize flex flex-end text-right"
            translationKey={`company.billing.freq.${data?.value}`}
          />
        );
      if (data?.value?.length > 1) return null;
    }
    return (
      <div>
        <Text
          translationKey={
            isExpandable(data.key)
              ? amountToCurrency(
                  data?.value?.[0]?.value,
                  data?.value?.[0]?.currency
                )
              : data?.value
          }
          classes="text-sm capitalize flex flex-end text-right"
        />
      </div>
    );
  };
  return dataDetails?.map((details, index) => {
    return (
      <div
        key={index}
        className={`flex flex-col gap-3  ${
          dataDetails.length > 1 ? "w-1/2" : "w-full"
        }`}
      >
        <div className="flex items-center justify-between w-full h-8">
          <Text
            classes="font-bold text-base text-lg"
            translationProps={details?.id}
            translationKey={details?.label}
          />
          {details?.id === BILLING_DETAILS.PLAN_DETAILS ? (
            <Button
              label="company.billing.planDetailsTable.buttonLabel"
              variant="tertiary w-15 h-9 text-sm"
              onClick={() => openPricingPlan()}
            />
          ) : null}
        </div>

        <div className="gap-4 py-1 border rounded-lg h-fit border-neutral-200">
          {details?.value?.map((data, idx) => {
            return (
              <div
                className={`flex flex-row justify-between p-4 ${
                  idx === (details?.value.length ?? 0) - 1 ? "" : "border-b"
                } border-solid border-b-neutral-200`}
                key={idx}
              >
                {typeof data?.value === typeof [] &&
                isExpandable(data?.key) &&
                data?.value?.length > 1 ? (
                  <Accordion
                    accordionParentClass="w-full"
                    classes="text-neutral-500"
                    items={[
                      {
                        id: 1,
                        label: data?.label,
                        labelsClass: "text-neutral-500 text-sm",
                        rightLabelText: rightLabel(data),
                        rightLabelClasses: "text-neutral-800",
                        renderContent: () => (
                          <div className="w-full p-4 pt-3 mt-4 rounded bg-neutral-50">
                            {data?.value?.map((val, indx) => (
                              <div
                                className="flex items-center justify-between w-full text-sm text-neutral-500"
                                key={`${indx}-${val.value}`}
                              >
                                <Text
                                  translationKey={`company.billing.expand.${data?.key}`}
                                  translationProps={{
                                    type: PLAN_TYPE[
                                      planDetails?.[indx]?.planType
                                    ],
                                  }}
                                />
                                <span>
                                  {INVOICE_KEYS.PLAN_FREQ === data?.key ? (
                                    <Text
                                      translationKey={`company.billing.freq.${val}`}
                                    />
                                  ) : (
                                    amountToCurrency(val.value, val.currency)
                                  )}
                                </span>
                              </div>
                            ))}
                          </div>
                        ),
                      },
                    ]}
                    keepOthersOpen={false}
                  />
                ) : (
                  <div className="flex flex-row justify-between flex-1">
                    <Text
                      translationKey={data?.label}
                      classes="text-sm not-italic font-medium text-neutral-500"
                    />
                    {showTableValue(data)}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  });
};

export default BillingDetailsTable;

BillingDetailsTable.propTypes = {
  planDetails: PropTypes.array,
  paymentDetails: PropTypes.array,
  showPlanDetails: PropTypes.bool,
};
