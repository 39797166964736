import { PAYMENT_OPTION_TYPE } from "@/constants/company";

export const PAYMENT_OPTION_TYPE_BUTTON_LABEL_MAP = {
  [PAYMENT_OPTION_TYPE.LOCAL]:
    "company.accountOverview.paymentOptions.buttonTexts.viewLocalDetails",
  [PAYMENT_OPTION_TYPE.SWIFT]:
    "company.accountOverview.paymentOptions.buttonTexts.viewSWIFTDetails",
  [PAYMENT_OPTION_TYPE.PAYNOW]:
    "company.accountOverview.paymentOptions.buttonTexts.viewTransferDetails",
  [PAYMENT_OPTION_TYPE.ADDFUND]:
    "company.accountOverview.paymentOptions.buttonTexts.addFunds",
};

export const PAYMENT_OPTION_TYPE_TEXT_MAP = {
  [PAYMENT_OPTION_TYPE.LOCAL]: "company.addFund.localAccountDetails",
  [PAYMENT_OPTION_TYPE.SWIFT]: "company.addFund.swiftAccountDetails",
  [PAYMENT_OPTION_TYPE.PAYNOW]: "company.addFund.payQR",
  [PAYMENT_OPTION_TYPE.ADDFUND]: "company.addFund.fund2Account",
  [PAYMENT_OPTION_TYPE.GLOBAL]: "company.addFund.fund2Account",
};

export const PAYMENT_OPTION_TYPE_HELP_TEXT_MAP = {
  [PAYMENT_OPTION_TYPE.LOCAL]: "company.addFund.forLocalTransfers",
  [PAYMENT_OPTION_TYPE.SWIFT]: "company.addFund.forSwiftTransfers",
  [PAYMENT_OPTION_TYPE.PAYNOW]: "company.addFund.payViaQRCode",
  [PAYMENT_OPTION_TYPE.ADDFUND]: "company.addFund.fundDesc",
  [PAYMENT_OPTION_TYPE.GLOBAL]: "company.addFund.fundDesc",
};

export const SHARE_DETAIL_ROW_TEXTS = [
  {
    title: "company.addFund.downloadOfficialLetterTitle",
    description: "company.addFund.downloadOfficialLetterDescription",
    icon: "MoveToInbox",
    iconRight: "Download",
  },
  {
    title: "company.addFund.copyPaymentInstructionsTitle",
    description: "company.addFund.copyPaymentInstructionsDescription",
    icon: "Copy",
    iconRight: "Copy",
  },
];
