import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage } from "@/utils/common";
import vToast from "@/utils/vToast";
import API from "@/api";

const initialState = {
  gettingStartedDetailsList: [],
  isFetching: false,
  bannerDetails: null,
  helpCenterDetailsList: [],
};

const myVolopaySlice = createSlice({
  name: "myVolopay",
  initialState,
  reducers: {
    setGettingStartedDetailsList(state, action) {
      state.gettingStartedDetailsList = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setBannerDetails(state, action) {
      state.bannerDetails = action.payload;
    },
    setHelpCenterList(state, action) {
      state.helpCenterDetailsList = action.payload;
    },
  },
});
export const fetchGettingStarted = createAsyncThunk(
  "myVolopay/fetchGettingStarted",
  async (params, { dispatch }) => {
    dispatch(setIsFetching(true));
    const [error, response] = await to(
      API.User.getGettingStartedDetails(params)
    );
    if (response?.data) {
      dispatch(setGettingStartedDetailsList(response?.data?.gettingStarted));
      dispatch(setHelpCenterList(response?.data?.helpCentre));
      dispatch(setBannerDetails(response?.data?.notificationBanners));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetching(false));
  }
);

export const {
  setGettingStartedDetailsList,
  setIsFetching,
  setHelpCenterList,
  setHelpCenterIsFetching,
  setBannerDetails,
} = myVolopaySlice.actions;
export default myVolopaySlice.reducer;
