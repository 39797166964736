import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import { selectedCardSelector } from "@/store/selectors/cards";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";

import {
  freezeUnfreezeBlockHandler,
  triggerCardsTableComponent,
} from "@/components/Cards/util";
import vToast from "@/utils/vToast";
import { convertFilters } from "@/utils/filters";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function IntentUrlActionConfirmationModal({
  open,
  setOnClose,
  modalConfig,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const selectedCard = useSelector(selectedCardSelector);
  const cardId = selectedCard?.id;
  const appliedFilters = convertFilters(useSelector(appliedFilterSelector));
  const location = useLocation();
  const selectedUser = useSelector(userSelector);
  const selectedUserId = selectedUser?.id;

  function closeModal() {
    setOnClose(false);
  }

  function handleCancelAction() {
    vToast({
      title: modalConfig.cancelToasterText.title,
      titleTranslationProp: modalConfig.cancelToasterText.titleTranslationProps,
      description: modalConfig.cancelToasterText.description,
      descriptionTranslationProp:
        modalConfig?.cancelToasterText?.descriptionTranslationProps,
      variant: "danger",
      outerSectionClasses: "w-123",
    });

    closeModal();
  }

  function handlePrimaryButtonClick() {
    freezeUnfreezeBlockHandler({
      dispatch,
      menuKey: modalConfig?.primaryButtonConfig?.btnKey,
      cardId,
      showToaster: false,
      handleCardStatusChange: () => {
        vToast({
          title: modalConfig.successToasterText.title,
          titleTranslationProp:
            modalConfig.successToasterText.titleTranslationProps,
          description: modalConfig.successToasterText.description,
          descriptionTranslationProp:
            modalConfig.successToasterText.descriptionTranslationProp,
          variant: "success",
        });

        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.cards.internationalCardOperationSlider
        );
        searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.id);
        triggerCardsTableComponent(
          dispatch,
          { ...appliedFilters, user: selectedUserId },
          location
        );
        setSearchParams(searchParams);
        closeModal();
      },
    });
  }

  return (
    <Modal open={open} onClose={closeModal}>
      <div className="p-6 w-120">
        <div className="flex flex-col gap-5 mb-6">
          <Text
            translationKey={modalConfig?.title}
            classes={`text-lg font-bold ${modalConfig?.titleClasses}`}
          />

          <Text
            translationKey={modalConfig?.description}
            classes={`text-sm font-semibold ${modalConfig?.descriptionClasses}`}
          />
        </div>

        <div className="flex flex-row items-center gap-3">
          <Button
            label="Cancel"
            variant="tertiary"
            onClick={handleCancelAction}
          />

          <Button
            label={modalConfig?.primaryButtonConfig?.label}
            classes={modalConfig?.primaryButtonConfig?.classes}
            variant={modalConfig?.primaryButtonConfig?.variant ?? ""}
            onClick={handlePrimaryButtonClick}
          />
        </div>
      </div>
    </Modal>
  );
}
