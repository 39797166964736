import React from "react";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function SecurityPopup({
  onCancel,
  onConfirm,
  heading,
  description,
}) {
  return (
    <div className="flex flex-col gap-3 p-3">
      <Icon name="Info" className="w-6 h-6 text-primary-500" />
      <Text classes="text-lg font-bold" translationKey={heading} />
      <Text
        classes="text-xs font-semibold text-neutral-400"
        translationKey={description}
      />
      <div className="flex items-center gap-2.5">
        <Button
          label="Cancel"
          variant="tertiary"
          classes="w-fit px-6 py-3 "
          onClick={onCancel}
        />
        <Button
          label="Confirm"
          classes="w-fit px-6 py-3 "
          onClick={onConfirm}
        />
      </div>
    </div>
  );
}
