/**
 * Gets common styles for pinned columns based on the column's position.
 *
 * @param {Object} column - The column object from TanStack table.
 * @returns {Object} - The styles to be applied to the column.
 */
function getCommonPinningStyles(column) {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right");

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "4px 0px 8px rgba(0, 0, 0, 0.1)"
      : isFirstRightPinnedColumn
        ? "-4px 0px 8px rgba(0, 0, 0, 0.1)"
        : undefined,
    clipPath: isLastLeftPinnedColumn
      ? "0px -4px 0px 0px"
      : isFirstRightPinnedColumn
        ? "0px -1px 0px -4px"
        : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: 1,
    position: isPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  };
}
/**
 * Compares two objects and returns the keys that are missing or have false values.
 *
 * @param {Object} obj1 - The first object.
 * @param {Object} obj2 - The second object.
 * @returns {string[]} - An array of keys that are missing or have false values.
 */
function getMissingOrFalseKeys(obj1, obj2) {
  return Object.keys(obj1).filter(
    (key) => !Object.hasOwn(obj2, key) || !obj2[key]
  );
}
export { getMissingOrFalseKeys, getCommonPinningStyles };
