import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import usePagination from "@/hooks/usePagination";

import { addComments, fetchComments } from "@/store/reducers/comments";
import {
  addRejectedClaimPayload,
  fetchRejectedReportReimbursements,
  fetchReportReimbursements,
  removeRejectedClaimPayload,
  setReimbursementInitialState,
  setReimbursements,
  setRejectReportReason,
  setRejectedClaimPayload,
  setRejectedClaimsInitialState,
  updateReimbursement,
} from "@/store/reducers/reimbursement";

import {
  commentsHasMoreSelector,
  commentsListSelector,
} from "@/store/selectors/comments";
import {
  isFetchingRejectedClaimsSelector,
  isReimbursementsFetchingSelector,
  reimbursementHasMoreSelector,
  reimbursementTotalSelector,
  reimbursementpageSelector,
  reimbursementsListSelector,
  rejectReportReasonSelector,
  rejectedClaimsHasMoreSelector,
  rejectedClaimsListSelector,
  rejectedClaimsPageSelector,
  rejectedClaimsTotalSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Comments from "@/components/core/Comments";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import Timeline from "@/components/core/Timeline";

import ReimbursementApprovalSliderInfo from "@/components/Reimbursement/ApprovalSlider/ReimbursementSliderInfo";
import TransactionDetails from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/TransactionDetails";
import { TYPES_TIMELINE } from "@/utils/constants/timeline";
import { REPORT_STATUS } from "@/utils/constants/reimbursement";
import {
  COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
  REIMBURSEMENT,
} from "@/utils/constants/payments";
import { COMMENT_TYPE, COMMENT_TYPE_PAYLOAD } from "@/utils/constants/comments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { APPROVE, REJECT } from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT } from "@/constants/pagination";
import { ACTIVE } from "@/constants/reimbursement";

import ReportClaimListComponent from "../ReportClaimListComponent";

function ReportOverviewTab({
  data,
  approveAll,
  rejectAll,
  setRejectAll,
  setApproveAll,
}) {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();

  const reimbursements = useSelector(reimbursementsListSelector);
  const totalReimbursements = useSelector(reimbursementTotalSelector);
  const hasMore = useSelector(reimbursementHasMoreSelector);
  const reimbursementPage = useSelector(reimbursementpageSelector);
  const isFetching = useSelector(isReimbursementsFetchingSelector);

  const rejectedClaims = useSelector(rejectedClaimsListSelector);
  const totalRejectedClaims = useSelector(rejectedClaimsTotalSelector);
  const hasMoreRejectedClaims = useSelector(rejectedClaimsHasMoreSelector);
  const rejectedClaimsPage = useSelector(rejectedClaimsPageSelector);
  const isFetchingRejectingClaims = useSelector(
    isFetchingRejectedClaimsSelector
  );

  const commentsList = useSelector(commentsListSelector);
  const commentHasMore = useSelector(commentsHasMoreSelector);

  const reportRejectionComment = useSelector(rejectReportReasonSelector);

  const currentUser = useSelector(userSelector);

  const [currentClaimTablePage, setCurrentClaimTablePage] = useState(1);
  const [currentRejectedTablePage, setCurrentRejectedTablePage] = useState(1);

  const [commentPage, setCommentPageNum] = useState(1);

  const onResetClaims = () => {
    setCurrentClaimTablePage(1);
    dispatch(setReimbursementInitialState());
  };

  const [tab] = window.location.pathname.split("/").slice(-1);

  const loadMoreClaims = () => {
    if (data && data?.status !== REPORT_STATUS.denied) {
      dispatch(
        fetchReportReimbursements({
          page: pageNum,
          limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
          by_report_id: data?.id,
          status: REPORT_STATUS[data?.status],
        })
      );
    }
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore: loadMoreClaims,
    onReset: onResetClaims,
  });

  const onResetRejectedClaims = () => {
    setCurrentRejectedTablePage(1);
    dispatch(setRejectedClaimsInitialState());
  };

  const loadMoreRejectedClaims = () => {
    if (data && data?.rejectedClaims && reimbursements) {
      dispatch(
        fetchRejectedReportReimbursements({
          page: pageNum2,
          limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
          by_report_id: data?.id,
          status: REPORT_STATUS.denied,
        })
      );
    }
  };

  const [pageNum2, setPageNum2] = usePagination({
    initialPageNum: 1,
    hasMore: hasMoreRejectedClaims,
    loadMore: loadMoreRejectedClaims,
    onReset: onResetRejectedClaims,
  });

  const createNewComment = (formData) => {
    formData.append("owner_type", COMMENT_TYPE_PAYLOAD.REPORT);
    formData.append("owner_id", data?.id);

    dispatch(
      addComments({
        payload: formData,
        onSuccess: () => {
          setCommentPageNum(1);
          dispatch(
            fetchComments({
              type: COMMENT_TYPE_PAYLOAD.REPORT,
              id: data?.id,
              comment_type: COMMENT_TYPE.USER_CREATED,
              page: 1,
              limit: 5,
            })
          );
        },
      })
    );
  };

  const loadMoreComments = () => {
    setCommentPageNum((prev) => prev + 1);
    dispatch(
      fetchComments({
        type: COMMENT_TYPE_PAYLOAD.REPORT,
        id: data?.id,
        page: commentPage + 1,
        limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
        comment_type: COMMENT_TYPE.USER_CREATED,
      })
    );
  };

  const approveAllCta = () => {
    if (reportRejectionComment) {
      dispatch(setRejectReportReason(null));
    }
    setApproveAll(true);
    dispatch(
      setReimbursements(
        reimbursements?.map((singleClaim) => {
          return {
            ...singleClaim,
            status: APPROVE,
          };
        })
      )
    );
    dispatch(setRejectedClaimPayload([]));
  };

  const rejectAllCta = () => {
    setRejectAll(true);

    dispatch(
      setReimbursements(
        reimbursements?.map((singleClaim) => {
          return {
            ...singleClaim,
            status: REJECT,
          };
        })
      )
    );
    dispatch(setRejectedClaimPayload([]));
    searchParam.append(SLIDERS_SEARCH_PARAMS.reports.rejectReport, true);
    setSearchParam(searchParam);
  };

  const approveSingleClaim = (val) => {
    if (reportRejectionComment) {
      dispatch(setRejectReportReason(null));
      const payload = reimbursements
        .filter((r) => r?.status === REJECT && r.id !== val?.id)
        .map((r) => ({ id: r.id }));
      dispatch(setRejectedClaimPayload(payload));
      addRejectedClaimPayload({ id: val?.id });
    }
    dispatch(
      updateReimbursement({
        id: val?.id,
        value: {
          ...val,
          status: APPROVE,
        },
      })
    );

    const approvedReimbursements = reimbursements.filter(
      (reimbursement) => reimbursement?.status === APPROVE
    );

    const totalApprovedreimbursements =
      val?.status === APPROVE
        ? approvedReimbursements.length
        : approvedReimbursements.length + 1;

    setApproveAll(totalApprovedreimbursements >= totalReimbursements);
    dispatch(removeRejectedClaimPayload({ id: val?.id }));
  };

  const rejectSingleClaim = (val) => {
    setRejectAll(false);
    dispatch(
      updateReimbursement({
        id: val?.id,
        value: {
          ...val,
          status: REJECT,
        },
      })
    );
    dispatch(addRejectedClaimPayload({ id: val?.id }));
  };

  useEffect(() => {
    if ((reportRejectionComment || rejectAll) && !isFetching) {
      setRejectAll(true);
      dispatch(
        setReimbursements(
          reimbursements?.map((singleClaim) => ({
            ...singleClaim,
            status: singleClaim?.status || REJECT,
          }))
        )
      );
    }
  }, [reportRejectionComment, isFetching]);

  useEffect(() => {
    if (approveAll && !isFetching) {
      dispatch(
        setReimbursements(
          reimbursements?.map((singleClaim) => ({
            ...singleClaim,
            status: singleClaim?.status || APPROVE,
          }))
        )
      );
    }
  }, [approveAll, isFetching]);

  useEffect(() => {
    if (data)
      dispatch(
        fetchComments({
          type: COMMENT_TYPE_PAYLOAD.REPORT,
          id: data?.id,
          page: 1,
          limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
          comment_type: COMMENT_TYPE.USER_CREATED,
        })
      );
  }, [data]);

  return (
    data && (
      <div className="flex flex-col gap-8 my-8">
        <div>
          {/* Timeline */}
          <Timeline
            status={data?.status}
            currentApprovers={data?.currentApprovers || []}
            rejectedReason={data?.rejectedReason}
            failureReason={data?.failureReasonDescription}
            currentApproverLevel={data?.currentApprovalLevel}
            totalApprovers={data?.approvalLevels}
            type={TYPES_TIMELINE.REPORT}
            id={data?.id}
          />
        </div>
        <div>
          {/* Slider info */}
          <ReimbursementApprovalSliderInfo
            createdBy={data?.createdBy}
            linkedTo={data?.linkedTo}
            totalAmount={data?.totalAmount}
            totalClaims={data?.claims}
          />
        </div>
        {reimbursements?.length ? (
          <div>
            {/* claims table that needs action */}
            <ReportClaimListComponent
              dataVariables={{
                data: reimbursements,
                isFetching,
                hasMore,
                isEmpty: !reimbursements?.length,
                total: totalReimbursements,
                page: reimbursementPage,
                paginationLimit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
              }}
              stepPaginationVariables={{
                showStepPagination: true,
                currentPage: currentClaimTablePage,
                setCurrentPage: setCurrentClaimTablePage,
                setPageNum,
              }}
              ctasActions={{
                showCtas:
                  data?.ctas?.includes("approve") &&
                  data?.ctas?.includes("reject") &&
                  tab !== ACTIVE,
                approveAllCta,
                approveSingleClaim,
                rejectAllCta,
                rejectSingleClaim,
                setApproveAll,
                approveAll,
                rejectAll,
                setRejectAll,
              }}
              onClickOpenSlider={(val) => {
                searchParam.append(
                  SLIDERS_SEARCH_PARAMS.reimbursements.approveId,
                  val?.id
                );
                setSearchParam(searchParam);
              }}
            />
          </div>
        ) : null}

        {data?.rejectedClaims ? (
          <div className="flex flex-col gap-4">
            {/* Rejected claims table */}
            <Text
              translationKey="myVolopay.reimbursements.reports.rejectedClaims"
              classes="font-semibold text-lg"
            />
            <ReportClaimListComponent
              dataVariables={{
                data: rejectedClaims,
                isFetching: isFetchingRejectingClaims,
                hasMore: hasMoreRejectedClaims,
                isEmpty: !rejectedClaims?.length,
                total: totalRejectedClaims,
                page: rejectedClaimsPage,
                paginationLimit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
              }}
              stepPaginationVariables={{
                showStepPagination: true,
                currentPage: currentRejectedTablePage,
                setCurrentPage: setCurrentRejectedTablePage,
                setPageNum: setPageNum2,
              }}
              onClickOpenSlider={(val) => {
                searchParam.append(
                  SLIDERS_SEARCH_PARAMS.reimbursements.approveId,
                  val?.id
                );
                setSearchParam(searchParam);
              }}
            />
          </div>
        ) : null}

        {reportRejectionComment || data?.comment ? (
          <div className="flex flex-col gap-6">
            <Text
              classes="font-semibold text-lg"
              translationKey="reimbursement.rejectPayment.reasonTitle"
            />

            <Input
              label="reimbursement.rejectPayment.commentInputLabel"
              disabled
              value={reportRejectionComment ?? data?.comment}
            />
          </div>
        ) : null}

        {data?.status === REPORT_STATUS.paid ? (
          <div className="my-10">
            <TransactionDetails context={REIMBURSEMENT} payment={data} />
          </div>
        ) : null}
        <div>
          <Text
            translationKey="comments.commentHeading"
            classes="font-semibold text-lg"
          />
          <Comments
            comments={commentsList}
            loadMoreLabel="comments.showOlderComments"
            isVisible
            createNewComment={(val) => {
              createNewComment(val);
            }}
            currentUser={currentUser}
            showLoadMoreCta={commentHasMore}
            loadMoreComments={loadMoreComments}
          />
        </div>
      </div>
    )
  );
}

export default ReportOverviewTab;

ReportOverviewTab.propTypes = {
  data: PropTypes.object,
};
