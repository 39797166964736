import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { login } from "@/store/reducers/user";

import { authDataSelector } from "@/store/selectors/user";

import TwoFactorAuth from "@/components/TwoFactorAuth";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function TwoFactorAuthPage() {
  const authEmail = useSelector(authDataSelector)?.email;
  const authPassword = useSelector(authDataSelector)?.password;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const oneTimeToken = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.oneTimeToken
  );
  const actionType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.actionType
  );
  const fromSubdomain = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.fromSubdomain
  );

  const showTrustedCheck = !oneTimeToken && !actionType && !fromSubdomain;

  const handleSubmit = ({ values, navigate }) => {
    dispatch(
      login({
        email: authEmail,
        password: authPassword,
        otp: values?.otp,
        add_trusted_device: values?.trusted,
        navigate,
        ...(oneTimeToken && { one_time_token: oneTimeToken }),
        ...(actionType && { action_type: actionType }),
        ...(fromSubdomain && { from_subdomain: fromSubdomain }),
      })
    );
  };
  return (
    <TwoFactorAuth
      handleAuthSubmit={handleSubmit}
      showTrustedCheck={showTrustedCheck}
    />
  );
}
