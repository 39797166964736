import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { bulkAction } from "@/store/reducers/accounting_transactions";

import { exportDataSelector } from "@/store/selectors/exports";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import { handleExport } from "@/utils/exports";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  EXPORTS_TOAST_CONFIG,
  EXPORT_STATUS,
  FILE_EXPORT_TYPE,
} from "@/constants/exports";
import { ROUTES } from "@/constants/routes";

function ExportFileTypeSelectionSlider() {
  const [downloadFileType, setDownloadFileType] = useState("csv");
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data = [],
    ucsvPayload,
    ucsvExportTitle,
    ucsvExportDesc,
    cancelHandler,
    csvExportDesc,
    pdfExportDesc,
    hidePdf,
    csvExportTitle,
    pdfExportTitle,
    selectedFilters,
    additionalFilters,
  } = useSelector(exportDataSelector) || {};

  useEffect(() => {
    if (!data) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.export);
      setSearchParams(searchParams);
    }
  }, [data]);

  const onSuccess = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.export);
    setSearchParams(searchParams);
  };

  const exportHandler = () => {
    if (downloadFileType === FILE_EXPORT_TYPE.UCSV) {
      const toastConfig = EXPORTS_TOAST_CONFIG[EXPORT_STATUS.ucsvInitiated];
      toastConfig.description = toastConfig.description.replace(
        "{{downloadsPagePath}}",
        `${ROUTES.exports.base.absolutePath}`
      );
      dispatch(bulkAction({ payload: ucsvPayload, toastConfig }));
    } else {
      handleExport(
        false,
        data.length,
        selectedFilters,
        dispatch,
        {
          ...additionalFilters,
          file_type: downloadFileType,
        },
        onSuccess
      );
    }
  };
  const ref = useLeftHeaderTitle({
    title: "common.exportSliderTitle",
  });

  const exportConfig = [
    {
      key: 1,
      isCheckedCondition: downloadFileType === FILE_EXPORT_TYPE.CSV,
      handleChange: () => {
        setDownloadFileType(FILE_EXPORT_TYPE.CSV);
      },
      desc: csvExportDesc,
      title: csvExportTitle,
    },
    !hidePdf
      ? {
          key: 2,
          isCheckedCondition: downloadFileType === FILE_EXPORT_TYPE.PDF,
          handleChange: () => {
            setDownloadFileType(FILE_EXPORT_TYPE.PDF);
          },
          desc: pdfExportDesc,
          title: pdfExportTitle,
        }
      : null,
    ucsvExportTitle
      ? {
          key: 3,
          isCheckedCondition: downloadFileType === FILE_EXPORT_TYPE.UCSV,
          handleChange: () => {
            setDownloadFileType(FILE_EXPORT_TYPE.UCSV);
          },
          desc: ucsvExportDesc,
          title: ucsvExportTitle,
        }
      : null,
  ];

  return (
    <>
      <div className="slider-content-container">
        <div className="flex flex-col gap-8 slider-content-core px-9 ">
          <div ref={ref} className="flex flex-col">
            <Text
              classes="font-bold text-3xl"
              translationKey="common.exportSliderTitle"
            />
            <Text
              classes="font-medium text-neutral-500 text-sm"
              translationKey="common.exportSliderDesc"
            />
          </div>

          {exportConfig
            ?.filter((singleConfig) => singleConfig)
            ?.map((config) => {
              return (
                <div
                  className="flex gap-2 p-6 border rounded-md border-neutral-300"
                  key={`${config.key}-fileType`}
                >
                  <div className="mt-2">
                    <Radio
                      id={`${config.key}-fileType`}
                      isChecked={config?.isCheckedCondition}
                      name="fileType"
                      handleChange={config?.handleChange}
                    />
                  </div>
                  <div className="flex flex-col font-medium">
                    <Text
                      translationKey={config?.title}
                      classes=" font-semibold"
                    />
                    <Text
                      translationKey={config?.desc}
                      classes="text-sm  text-neutral-500"
                    />
                  </div>
                </div>
              );
            })}

          <div className="flex items-center gap-5 card-wrapper">
            <Icon name="Info" className="text-neutral-500" />
            <Text
              translationKey="common.afterExportDesc"
              classes="text-neutral-800 font-semibold"
            />
          </div>
        </div>
      </div>

      <div className="px-5 py-4 slider-footer">
        <div className="flex justify-end gap-4">
          <Button
            onClick={cancelHandler}
            label="reimbursement.rejectPayment.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold text-neutral-500"
            // disabled={isLoading}
          />

          <Button
            onClick={exportHandler}
            variant="primary"
            label="Export"
            classes=" w-fit px-5 py-3 text-btn-lg font-semibold "
          />
        </div>
      </div>
    </>
  );
}

export default ExportFileTypeSelectionSlider;
