import React from "react";
import { useSelector } from "react-redux";

import { blockedVirtualCardPageFiltersSelector } from "@/store/selectors/cards";
import { userSelector } from "@/store/selectors/user";

import VirtualCardsPageHelper from "@/components/Cards/VirtualCardsPageHelper";

import { ROUTES } from "@/constants/routes";

function MyVolopayVirtualPageBlockedPage() {
  const filters = useSelector(blockedVirtualCardPageFiltersSelector);
  const currentUser = useSelector(userSelector);
  return (
    <VirtualCardsPageHelper
      tab={ROUTES.cards.virtualCards.blocked.pathName}
      filterArray={filters}
      params={{ user: currentUser?.id }}
    />
  );
}

export default MyVolopayVirtualPageBlockedPage;
