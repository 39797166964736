import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  resetLeftSliderHeaderData,
  setIsHeaderShadowVisible,
  setShowLeftSlider,
  setSliderHeaderData,
} from "@/store/reducers/slider";

/**
 * Aka sliding title, slider title, srcoll title
 */
export function useLeftHeaderTitle(
  data = {
    title: null,
    icon: null,
    img: null,
  },
  observerOption = {},
  isStatic = false
) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isStatic) dispatch(setSliderHeaderData(data));
    dispatch(setShowLeftSlider(isStatic));
  }, []);
  const [observer] = useState(
    new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.boundingClientRect.x !== 0) {
          if (target.isIntersecting) {
            if (!isStatic) {
              dispatch(setShowLeftSlider(false));
            }
            dispatch(setIsHeaderShadowVisible(false));
          } else if (target.boundingClientRect.y < 80) {
            if (!isStatic) {
              dispatch(setShowLeftSlider(true));
            }
            dispatch(setIsHeaderShadowVisible(true));
          }
        }
      },
      { threshold: 0.5 }
    )
  );

  const lastElementRef = useRef();

  useEffect(() => {
    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }
    if (!isStatic) dispatch(setShowLeftSlider(false));
    else {
      dispatch(setShowLeftSlider(true));
    }
    dispatch(setSliderHeaderData(data));

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [
    lastElementRef.current,
    data.title,
    data?.titleTranslationProps,
    data.icon,
    data.img,
    isStatic,
  ]);

  return lastElementRef;
}

export default useLeftHeaderTitle;
