import { decryptData } from "./common";

export const decryptUserPermissions = (userData) => {
  const permissions = userData?.permissions;
  if (permissions?.encryptedData && permissions?.iv) {
    return decryptData(permissions, userData);
  }
  return [];
};

/**
 * Decrypts master data that may have been encrypted server side.
 *
 * This function will decrypt any of the following that are encrypted:
 * - availableModules
 * - budgetModules
 * - dashboard
 * - navigations
 *
 * If any of these are not encrypted, they will be returned as is.
 *
 * @param {object} masterData - The master data to decrypt.
 * @param {object} userData - The user data needed to decrypt.
 * @returns {object} The decrypted master data.
 */
export const decryptMasterData = (masterData, userData) => {
  const { availableModules, budgetModules, dashboard, navigations } =
    masterData;

  const result = {};
  if (availableModules?.encryptedData && availableModules?.iv) {
    result.availableModules = decryptData(availableModules, userData);
  }
  if (budgetModules?.encryptedData && budgetModules?.iv) {
    result.budgetModules = decryptData(budgetModules, userData);
  }
  if (dashboard?.encryptedData && dashboard?.iv) {
    result.dashboard = decryptData(dashboard, userData);
  }
  if (navigations?.encryptedData && navigations?.iv) {
    result.navigations = decryptData(navigations, userData);
  }
  return result;
};
