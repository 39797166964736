import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchGlobalAccounts,
  setCurrentGlobalAccount,
} from "@/store/reducers/account";
import { setAccountDetails } from "@/store/reducers/client";

import {
  globalAccountsSelector,
  isFetchingAccountsSelector,
} from "@/store/selectors/account";
import { paymentWalletListSelector } from "@/store/selectors/client";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import EmptyData from "@/components/core/EmptyData";
import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import CompanyOverviewPageTitle from "@/components/Company/Overview/CompanyOverviewPageTitle";
import WalletAccountWidget from "@/components/Company/Overview/MyAccounts/WalletAccountWidget";
import { checkIfUserActionAllowedUsingActionKey } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";
import { ACCOUNT_CTAS, PAYMENT_OPTION_TYPE } from "@/constants/company";
import {
  USER_ALLOWED_CTA_KEYS,
  USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS,
} from "@/constants/user";

export default function DashboardGlobalAccountsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGlobalAccounts());
  }, []);

  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  const showAddGlobalAccountCta = checkIfUserActionAllowedUsingActionKey(
    USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.DASHBOARD_GLOBAL_ACCOUNT,
    userAllowedActions,
    USER_ALLOWED_CTA_KEYS.REQUEST_GLOBAL_ACCOUNT
  );

  const globalAccounts = useSelector(globalAccountsSelector);
  const primaryPaymentAccounts = useSelector(paymentWalletListSelector);
  const isFetchingGlobalAccounts = useSelector(isFetchingAccountsSelector);

  const paymentWallets = useSelector(paymentWalletListSelector);

  const paymentWalletsId = useMemo(() => {
    return paymentWallets?.map((wallet) => wallet?.globalAccountId);
  }, [paymentWallets]);

  const CTAs = {
    [ACCOUNT_CTAS.TRANSACTIONS]: {
      icon: "Expense",
      text: "company.accountOverview.viewTransactions",
      onClick: (acc) => {
        setSearchParams({
          [SLIDERS_SEARCH_PARAMS.company.transactions]: acc?.id,
        });
      },
    },
    [ACCOUNT_CTAS.RECEIVE_FUNDS]: {
      icon: "MoveDown",
      text: "company.addFund.receiveFunds",
      onClick: (acc) => {
        const guidelines = [
          { text: "company.globalAccounts.guideLines.desc1", action: "pace" },
          {
            text: "company.globalAccounts.guideLines.desc2",
            textProp: {
              currency: acc.currency,
              paymentMode: acc.paymentMethods.join(", "),
            },
            action: "flag",
          },
          {
            text: "company.globalAccounts.guideLines.desc3",
            textProp: { currency: acc.currency },
            action: "exchange",
          },
          {
            text: "company.globalAccounts.guideLines.desc4",
            textProp: { currency: acc.currency },
            action: "arrows",
          },
        ];

        dispatch(setCurrentGlobalAccount(acc));
        dispatch(
          setAccountDetails({ bankAccount: acc, guideLines: guidelines })
        );
        setSearchParams({
          [SLIDERS_SEARCH_PARAMS.company.paymentOption]:
            PAYMENT_OPTION_TYPE.GLOBAL,
          [SLIDERS_SEARCH_PARAMS.company.currency]: acc.currency,
          [SLIDERS_SEARCH_PARAMS.company.globalAccountId]: acc.id,
        });
      },
    },
  };

  const accountBoxLines = [
    {
      icon: "AccountBalance",
      label: "company.globalAccounts.accountNumber",
      key: "accountNumber",
    },
    {
      icon: "LocationOn",
      label: "company.globalAccounts.bankLocation",
      key: "countryCode",
    },
    {
      icon: "LocalATM",
      label: "reimbursement.createPayment.paymentMethod",
      key: "paymentMethods",
    },
  ];

  const openGlobalSlider = (isPrimary) => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.company.globalAccounts,
      FORM_TYPE.editCurrency
    );

    if (isPrimary) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.company.primaryAccount, true);
    }

    setSearchParams(searchParams);
  };

  return (
    <div className="company-overview-page-container">
      <div className="company-overview-main">
        <div className="company-overview-left">
          <CompanyOverviewPageTitle isAccountPage />

          {!isFetchingGlobalAccounts ? (
            <>
              <div className="flex items-center justify-between">
                <div className="flex flex-col my-6">
                  <Text
                    translationKey="company.primaryAcc.title"
                    classes="font-bold text-lg"
                  />
                  <Text
                    translationKey="company.primaryAcc.desc"
                    classes="font-medium text-base text-neutral-500"
                  />
                </div>
                <Button
                  label="company.primaryAcc.btnLabel"
                  preIcon="Add"
                  classes="w-fit px-5 py-4"
                  onClick={() => {
                    openGlobalSlider(true);
                  }}
                />
              </div>
              <div className="flex flex-wrap justify-between flex-1 ">
                {primaryPaymentAccounts?.map((acc) => {
                  return (
                    <div className="flex flex-col w-1/2" key={`${acc.id}`}>
                      <div
                        className="flex flex-col m-2 card-wrapper"
                        id="upper"
                      >
                        <WalletAccountWidget account={acc} globalAccounts />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="mt-10 h-5/6">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col mb-6">
                    <Text
                      translationKey="company.secondaryAccounts.title"
                      classes="font-bold text-lg"
                    />
                    <Text
                      translationKey="company.secondaryAccounts.desc"
                      classes="font-medium text-base text-neutral-500"
                    />
                  </div>
                  <Button
                    label="company.secondaryAccounts.globalAccCta"
                    preIcon="Add"
                    onClick={() => {
                      openGlobalSlider(false);
                    }}
                    classes="w-fit px-5 py-4"
                  />
                </div>

                <Alert
                  wrapperDivClass="justify-start "
                  iconClasses="w-6 h-6 text-neutral-500"
                  variant="neutral"
                  title="company.secondaryAccounts.alertTitle"
                  description="company.secondaryAccounts.alertDesc"
                />

                <div className="flex flex-wrap justify-between flex-1 mt-6">
                  {globalAccounts?.length ? (
                    globalAccounts
                      ?.filter((acc) => !paymentWalletsId?.includes(acc?.id))
                      .map((account) => (
                        <div
                          className="flex flex-col w-1/2"
                          key={`${account.id}`}
                        >
                          <div
                            className="flex flex-col px-6 mx-2 my-3 card-wrapper"
                            id="upper"
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex">
                                <Flag
                                  code={account.currency}
                                  classes="h-9 w-9 border-1 border-neutral-200"
                                  border
                                />
                                <Text
                                  classes="font-bold text-xl ml-4"
                                  translationKey="common.currencyAccount"
                                  translationProps={{
                                    currency: account?.currency,
                                  }}
                                />
                              </div>
                              <div id="right" className="flex items-start">
                                {Object.keys(CTAs)?.map((item) => (
                                  <div
                                    className="flex flex-col items-center p-6 hover:cursor-pointer"
                                    onClick={() => CTAs[item]?.onClick(account)}
                                    key={`${CTAs[item]?.icon}-${CTAs[item]?.text}`}
                                  >
                                    <Icon
                                      name={CTAs[item]?.icon}
                                      variant="rounded"
                                      bgClassName={
                                        CTAs[item]?.disabled
                                          ? "text-neutral-300 bg-neutral-50  "
                                          : "text-primary-500 bg-primary-50"
                                      }
                                      className={
                                        CTAs[item]?.disabled
                                          ? "text-netural-400 bg-neutral-50 w-5 h-5"
                                          : "bg-primary-50 text-primary-400 w-5 h-5"
                                      }
                                    />
                                    <Text
                                      translationKey={CTAs[item]?.text}
                                      classes={`text-xs font-semibold mt-2 ${
                                        CTAs[item]?.disabled
                                          ? "text-neutral-300"
                                          : "text-neutral-500"
                                      } text-center`}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div
                              id="lower"
                              className="flex flex-col gap-2 p-4 mt-4 bg-neutral-50"
                            >
                              {accountBoxLines.map(
                                ({
                                  icon,
                                  label,
                                  key,
                                  valueStyleClass = "font-semibold text-sm text-neutral-800",
                                }) => (
                                  <div
                                    className="flex items-center justify-between"
                                    key={label}
                                  >
                                    <div className="flex items-center gap-2">
                                      <Icon
                                        name={icon}
                                        className="w-3 text-neutral-500"
                                      />
                                      <Text
                                        translationKey={label}
                                        classes="text-neutral-500 text-sm font-medium"
                                      />
                                    </div>
                                    <span className={valueStyleClass}>
                                      {key === "paymentMethods"
                                        ? account[key]
                                        : account[key]}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <EmptyData
                      title="company.globalAccounts.emptyTitle"
                      imgSrc="emptyScreen.svg"
                      buttonLabel={
                        showAddGlobalAccountCta ? "Global account" : null
                      }
                      buttonIcon="Plus"
                      handleClick={() => {
                        openGlobalSlider(false);
                      }}
                      buttonProps={{ classes: "w-fit px-5 py-4" }}
                    >
                      <Text
                        translationKey="company.globalAccounts.emptyDesc"
                        classes="text-sm text-neutral-800 font-medium text-center"
                      />
                    </EmptyData>
                  )}
                </div>
              </div>
            </>
          ) : (
            Array(3)
              .fill(null)
              .map((_, idx) => (
                <div
                  className="grid w-full grid-cols-2 gap-3"
                  key={`loader-${idx}`}
                >
                  <LoaderSkeleton fullWidth heightProp={250} classes="mb-4" />
                  <LoaderSkeleton fullWidth heightProp={250} />
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  );
}
