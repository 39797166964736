import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { downloadAttachment } from "@/store/reducers/app";

import Icon from "@/components/core/Icon";
import { getMBs } from "@/utils/common";

function FilePreviewComment({ file, setSelectedFile, setShowModal }) {
  const dispatch = useDispatch();
  return (
    <div
      key={file?.id}
      className="flex items-center justify-between w-full my-2 "
    >
      <div
        className="flex items-center"
        onClick={() => {
          setSelectedFile(file);
          setShowModal(true);
        }}
      >
        {file?.contentType?.includes("image/") &&
        (file?.url || file?.preview) ? (
          <img
            src={file?.url || file?.preview}
            className="w-11 h-11"
            alt={file?.name || file?.fileName}
          />
        ) : (
          <Icon name="Receipt" className="w-8 h-8 mr-1 text-neutral-400" />
        )}
        <div className="flex flex-col mx-2">
          <span className="text-sm">{file?.name || file?.fileName}</span>
          {file?.size ? (
            <div className="flex text-neutral-400">
              <span className="text-xs">
                {Object.values(getMBs(file?.size.split(" ")[0])).join(" ")}
              </span>
            </div>
          ) : null}
        </div>
      </div>

      <div
        className="p-1 bg-primary-50 rounded-xl "
        onClick={() => {
          if (file instanceof File) return;
          dispatch(
            downloadAttachment({
              file,
              id: file?.id,
            })
          );
        }}
      >
        <Icon
          name="Download"
          className="w-8 h-8 p-2 rounded-lg cursor-pointer text-primary-500 "
          bgClassName="bg-neutral-100"
        />
      </div>
    </div>
  );
}

export default FilePreviewComment;

FilePreviewComment.propTypes = {
  file: PropTypes.object,
  setSelectedFile: PropTypes.func,
  setShowModal: PropTypes.func,
};
