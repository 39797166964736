import PropTypes from "prop-types";
import { useState } from "react";

import Tooltip from "@/components/core/Tooltip";
import Icon from "@/components/core//Icon";
import Text from "@/components/core//Text";

export default function ButtonFilter({
  filterKey,
  icon,
  label,
  value,
  callback,
  showLabel = false,
}) {
  const [focused, setFocused] = useState(false);
  const handleClick = () => {
    setFocused((prev) => !prev);
    callback({ [filterKey]: { value, label } });
  };

  return (
    <>
      <button
        id={filterKey}
        className={`flex items-center ${
          !showLabel ? "w-10 h-10" : ""
        } gap-2 py-3 card-wrapper ${focused ? "border-primary-500" : ""}`}
        onClick={() => handleClick()}
      >
        {icon ? (
          <Icon className="w-4 h-4 text-neutral-500" name={icon} />
        ) : null}
        {showLabel ? (
          <Text classes="text-neutral-500" translationKey={label} />
        ) : null}
      </button>
      {showLabel ? null : (
        <Tooltip id={filterKey} direction="bottom">
          <Text translationKey={label} />
        </Tooltip>
      )}
    </>
  );
}

ButtonFilter.propTypes = {
  filterKey: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  callback: PropTypes.func,
  showLabel: PropTypes.bool,
};
