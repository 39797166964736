import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function CardRequestLoader() {
  return Array(PAGINATION_PER_REQUEST_LIMIT)
    .fill(null)
    .map((_, idx) => (
      <tr key={`VendorTableLoader-${idx}`}>
        <td>
          <div className="flex items-center gap-2 px-4">
            <LoaderSkeleton size={[20, 20]} />
            <div className="flex items-center gap-3 px-0">
              <LoaderSkeleton type="circle" />
              <div className="flex flex-col">
                <LoaderSkeleton size={[20, 120]} />
                <LoaderSkeleton size={[20, 120]} />
              </div>
            </div>
          </div>
        </td>
        <td className="text-right">
          <LoaderSkeleton />
        </td>
        <td className="px-4">
          <LoaderSkeleton />
        </td>
        <td>
          <LoaderSkeleton size={[20, 120]} />
        </td>
        <td>
          <div className="flex gap-2">
            <LoaderSkeleton size={[20, 150]} />
            <LoaderSkeleton size={[20, 20]} />
          </div>
        </td>
      </tr>
    ));
}

export default CardRequestLoader;
