import PaymentPageHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentPageHelper";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import { TAB_TYPES } from "@/utils/constants/payments";

export default function PayrollPaymentsPageAll() {
  return (
    <PaymentPageHelper
      page={BILL_PAYROLL_CONTEXT.PAYROLL}
      pageType={TAB_TYPES.ALL}
    />
  );
}
