import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DocViewer from "@cyntler/react-doc-viewer";

import {
  fetchAttachementUrls,
  resetFileViewerStore,
  setDefaultZoom,
  setTotalPageFiles,
} from "@/store/reducers/fileViewer";
import {
  currentPageNumberSelector,
  filesUrlSelector,
  isFetchingFilesUrlSelector,
} from "@/store/selectors/fileViewer";
import { checkIfURLIsValid, downloadFileFromBlob } from "@/utils/common";

import { downloadFile } from "@/utils/actions";

import { FILE_VIEWER_HEADER } from "@/constants/fileViewer";

import FileLoader from "../FileLoader";
import Modal from "../Modal";
import LoadingRenderer from "./DocViewerOverrideComponents/LoadingRenderer";
import ModalHeaderRenderer from "./DocViewerOverrideComponents/ModalHeaderRenderer";
import NoRenderer from "./DocViewerOverrideComponents/NoRenderer";
import OCRHeaderRenderer from "./DocViewerOverrideComponents/OCRHeaderRenderer";
import PdfViewer from "./renderers/PDFRenderer";
import PNGAndJPGRenderer from "./renderers/PNGAndJPGRenderer";

const HEADER_CONFIG = {
  [FILE_VIEWER_HEADER.SECONDARY]: OCRHeaderRenderer,
  [FILE_VIEWER_HEADER.PRIMARY]: ModalHeaderRenderer,
};

/**
 * Renders a file viewer component.
 *
 * -> To get the file viewer header as ocr and out of modal isModalView=false and headerMode=FILE_VIEWER_HEADER.SECONDARY
 * -> To pass hideHeader=true to hide the header
 * -> To pass customHeader to render a custom header
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} showDownloadBtn=true - Whether to show the download button.
 * @param {React.Component} customHeader=null - The custom header component.
 * @param {boolean} hideHeader=false - Whether to hide the header.
 * @param {boolean} isModalView=true - Whether to display the file viewer in modal view.
 * @param {function} handleModalBackClick=()=>{} - The function to handle the modal back click event.
 * @param {String} headerMode=FILE_VIEWER_HEADER.PRIMARY - The mode for the file viewer header.
 * @param {function} handleDelete=(obj)=>{} - The function to handle the delete event.
 * @param {boolean} modalVisible=false - Whether the modal is visible.
 * @param {Array} props.files - The array of files to be displayed.
 * @param {number} defaultZoom=1 - The default zoom level.
 *
 * @return {React.Component} - The file viewer component.
 */
const FileViewer = React.memo(function FileViewer({
  showDownloadBtn = true,
  customHeader = null,
  hideHeader = false,
  isModalView = true,
  handleModalBackClick = () => {},
  headerMode = FILE_VIEWER_HEADER.PRIMARY,
  handleDelete = (obj) => {},
  showDelete = true,
  modalVisible = false,
  files,
  defaultZoom = 1,
  disabled,
  showUpload = false,
  onHandleUpload = () => {},
}) {
  const dispatch = useDispatch();
  const currentPage = useSelector(currentPageNumberSelector);
  const isFetching = useSelector(isFetchingFilesUrlSelector);
  const filesUrl = useSelector(filesUrlSelector);
  const [docs, setDocs] = useState([]);

  const isEmpty =
    !docs?.length ||
    docs.some((item) => !(item.uri || item.url || item.preview));

  const CustomHeader = customHeader;
  const SelectedHeaderComponent = HEADER_CONFIG[headerMode];

  const handleModalClose = () => {
    handleModalBackClick();
  };

  const handleDownload = async () => {
    const {
      uri = "",
      fileName: _fileName = "",
      fileType: _fileType = "",
    } = docs[currentPage];

    const isHttpUrl = uri.startsWith("https://") ?? uri.startsWith("http://");
    const isURLAvailable = await checkIfURLIsValid(uri);

    if (!isURLAvailable) {
      console.warn("FileViewer: Invalid url, aborting download", uri);
      return;
    }

    if (uri && _fileType && _fileName) {
      if (isHttpUrl) downloadFile(uri, _fileName, _fileType);
      else downloadFileFromBlob(uri, _fileName, _fileType);
    }
  };

  useEffect(() => {
    if (files?.length) {
      const createData = files?.map((item) => {
        const filesUrlObj = filesUrl?.find((url) => url?.id === item?.id);
        return filesUrlObj
          ? {
              uri: filesUrlObj?.url,
              fileName: item?.fileName,
              fileType: item?.contentType,
            }
          : {
              // OR is required for features where there's no instant upload
              uri: item?.preview || item?.uri,
              fileName: item?.name || item?.fileName,
              fileType: item?.type || item?.fileType,
            };
      });

      const isValidCreatedData =
        createData?.filter((item) =>
          typeof item === typeof {}
            ? Object.values(item)?.filter((i) => i)?.length !== 0
            : item
        )?.length !== 0;

      if (isValidCreatedData) {
        setDocs(createData);
        dispatch(setTotalPageFiles(files?.length));
        dispatch(setDefaultZoom(defaultZoom));
      }
    }
  }, [JSON.stringify(filesUrl), JSON.stringify(files)]);

  useEffect(() => {
    const filesIds = files?.filter((item) => item?.id)?.map((item) => item.id);
    if (filesIds?.length) dispatch(fetchAttachementUrls({ ids: filesIds }));
  }, [JSON.stringify(files)]);

  useEffect(() => {
    return () => {
      if (!isFetching && !isEmpty) dispatch(resetFileViewerStore());
    };
  }, []);

  // TECH_DEBT translation
  const children = isFetching ? (
    "Fetching...."
  ) : isEmpty ? (
    "Empty state no files"
  ) : (
    <DocViewer
      documents={docs}
      activeDocument={docs[currentPage]}
      pluginRenderers={[PNGAndJPGRenderer, PdfViewer]}
      config={{
        header: {
          disableHeader: hideHeader,
          overrideComponent: (state) =>
            customHeader ? (
              <CustomHeader
                handleDownload={handleDownload}
                handleDelete={handleDelete}
                handleModalClose={handleModalClose}
                showDownloadBtn={showDownloadBtn}
                hideHeader={hideHeader}
                docs={docs}
                disabled={disabled}
                currentPage={currentPage}
                state={state}
                showDelete={showDelete}
                showUpload={showUpload}
                onHandleUpload={onHandleUpload}
              />
            ) : (
              <SelectedHeaderComponent
                handleDownload={handleDownload}
                handleModalClose={handleModalClose}
                showDownloadBtn={showDownloadBtn}
                handleDelete={handleDelete}
                hideHeader={hideHeader}
                docs={docs}
                disabled={disabled}
                currentPage={currentPage}
                state={state}
                showUpload={showUpload}
                onHandleUpload={onHandleUpload}
              />
            ),
        },

        noRenderer: {
          overrideComponent: NoRenderer,
        },

        loadingRenderer: {
          overrideComponent: FileLoader,
        },
      }}
      theme={{ disableThemeScrollbar: false }}
      language="pl"
    />
  );

  return isModalView ? (
    <Modal
      innerContainerClasses="py-0 px-0"
      open={modalVisible}
      onClose={handleModalClose}
    >
      <div
        key={docs[currentPage]?.uri}
        className=" relative w-[80vw] h-[80vh]  bg-neutral-50"
      >
        {isEmpty ? "No files there " : children}
      </div>
    </Modal>
  ) : (
    <div className="relative flex h-full">
      {isEmpty ? "no files there" : children}
    </div>
  );
});

export default FileViewer;
FileViewer.propTypes = {
  showDownloadBtn: PropTypes.bool,
  customHeader: PropTypes.elementType,
  hideHeader: PropTypes.bool,
  isModalView: PropTypes.bool,
  handleModalBackClick: PropTypes.func,
  headerMode: PropTypes.oneOf(Object.values(FILE_VIEWER_HEADER)),
  handleDelete: PropTypes.func,
  showDelete: PropTypes.bool,
  modalVisible: PropTypes.bool,
  files: PropTypes.array,
  defaultZoom: PropTypes.number,
  disabled: PropTypes.bool,
  showUpload: PropTypes.bool,
  onHandleUpload: PropTypes.func,
};
