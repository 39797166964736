import PropTypes from "prop-types";

import { dateToString } from "@/utils/common";

export default function BillingDateCell({ val }) {
  return <span>{dateToString(val?.billingDate)}</span>;
}

BillingDateCell.propTypes = {
  val: PropTypes.object,
};
