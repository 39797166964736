import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { changeUserAccess, resetUserAccess } from "@/store/reducers/user";

import {
  isUserAccessChangeInprogress,
  isUserAccessChangedSelector,
  selectedUserSelector,
} from "@/store/selectors/user";

import Text from "@/components/core/Text";
import { formatAndCleanUpCaptchaText } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { USER_ACCESS_CONTANTS } from "@/constants/user";

import InputCaptchaCheck from "./InputCaptchaCheck";

export default function LockUser() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedUser = useSelector(selectedUserSelector);
  const userId = selectedUser?.id;
  const displayName = selectedUser?.displayName;
  const lockUserLabel = "company.people.lockUserSlider.heading";
  const accessChangedInprogress = useSelector(isUserAccessChangeInprogress);
  const isUserLocked = useSelector(isUserAccessChangedSelector);
  const captchaMatchText = `LOCK ${formatAndCleanUpCaptchaText(
    selectedUser?.displayName
  )}`;

  useEffect(() => {
    if (isUserLocked && !accessChangedInprogress) {
      handleCancel();
    }

    return () => {
      dispatch(resetUserAccess());
    };
  }, [isUserLocked]);

  function handleConfirm() {
    dispatch(
      changeUserAccess({ operation: USER_ACCESS_CONTANTS.LOCKED, id: userId })
    );
  }

  function handleCancel() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.lockUser);
    setSearchParams(searchParams);
  }

  return (
    <>
      <div className="flex flex-col gap-10 p-10">
        <div className="flex flex-col gap-3">
          <Text
            translationKey={lockUserLabel}
            translationProps={{ displayName }}
            classes="text-3xl font-bold"
          />

          <Text
            translationKey="company.people.lockUserSlider.headingDescription"
            classes="text-neutral-500 text-sm"
          />
        </div>
        <div className="flex flex-col gap-3">
          <Text
            classes="text-xl font-semibold"
            translationKey="company.people.lockUserSlider.subjectiveWarningText"
          />

          <div className="mb-4">
            <Text
              translationKey="company.people.lockUserSlider.subjectiveWarningTextDescription"
              classes="text-neutral-500 text-sm"
            />
          </div>
        </div>
      </div>

      <InputCaptchaCheck
        handleConfirmButton={handleConfirm}
        handleCancelButton={handleCancel}
        captchaMatchText={captchaMatchText}
        inProgress={accessChangedInprogress}
        outsideInputDivClasses="m-10 mt-0"
      />
    </>
  );
}
