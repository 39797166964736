export const formatDataForTable = (transaction) => {
  const formatted = {
    accountingId: transaction?.accountingId,
    id: transaction?.id,
    paymentId: transaction?.paymentId,
    accountingPayeeId: transaction?.accountingPayeeId,
    accountingStatus: transaction?.accountingStatus,
    editable: transaction?.editable,
    rule: transaction?.rule,
    vendor: {
      name: transaction?.vendor?.name,
      status: transaction?.accountingStatus,
      avatarUrl: transaction?.vendor?.avatarUrl,
      employeeId: transaction?.vendor?.employeeId,
    },
    amount: {
      amount: transaction?.amount?.value,
      currency: transaction?.amount?.currency,
    },
    receipt: {
      required: transaction?.receiptRequired,
      attached: transaction?.receiptAttached,
    },
    note: {
      required: transaction?.noteRequired,
      value: transaction?.note,
    },
    action: {
      status: transaction?.accountingStatus,
      verifiable: transaction?.verifiable,
      syncable: transaction?.syncable,
      ctas: transaction?.accountingCtas,
    },
    accountingTag: {
      arr: transaction?.accountingTags,
    },
    customTag: {
      arr: transaction?.customTags,
    },
    vendors: {
      id: transaction?.vendor?.id,
      name: transaction?.vendor?.name,
      alias: transaction?.vendor?.alias ?? transaction?.vendor?.name,
    },
    verifiable: transaction?.verifiable,
    syncable: transaction?.syncable,
    status: transaction?.transactionStatus,
    notActionableReason: transaction?.notActionableReason,
    openIn: transaction?.accountingUrl,
    transactionDate: transaction?.transactionDate,
  };

  return formatted;
};
