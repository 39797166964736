import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";

function InappGuideButton({
  handleButtonClick,
  thumbNailImage,
  externalClasses,
}) {
  return (
    <div className={`relative flex flex-row ${externalClasses}`}>
      <img
        src={thumbNailImage}
        alt="Thumbnail"
        className="rounded-lg cursor-pointer"
      />

      <div className="absolute right-4 bottom-4">
        <Button
          preIcon="Play"
          label="Play"
          size="sm"
          variant="tertiary"
          classes="text-base font-semibold text-neutral-500 w-13 h-8.5 rounded-xl border-none"
          iconClasses="w-6 h-6"
          innerClasses="gap-0"
          onClick={handleButtonClick}
        />
      </div>
    </div>
  );
}

InappGuideButton.propTypes = {
  handleButtonClick: PropTypes.func,
  thumbNailImage: PropTypes.string,
  externalClasses: PropTypes.string,
};

export default InappGuideButton;
