export const MULTIPLE_INPUT_VALIDATE_CONFIG = {
  EMPTY: "EMPTY",
  ERROR: "ERROR",
  DUPLICATE: "DUPLICATE",
  NORMAL: "NORMAL",
};

export const MULTIPLE_INPUT_CONFIG = {
  [MULTIPLE_INPUT_VALIDATE_CONFIG.EMPTY]: {
    pillClasses: "bg-danger-50",
    errorMessage: "Please Fill the Input Field",
    errorClasses: "text-xs text-danger-600",
  },
  [MULTIPLE_INPUT_VALIDATE_CONFIG.ERROR]: {
    pillClasses: "bg-danger-50",
    errorMessage: "Invalid email ID",
    errorClasses: "text-xs text-danger-600",
  },
  [MULTIPLE_INPUT_VALIDATE_CONFIG.DUPLICATE]: {
    pillClasses: "bg-warning-100",
    errorMessage: "Email id already exists",
    errorClasses: "text-xs text-warning-600",
  },
  [MULTIPLE_INPUT_VALIDATE_CONFIG.NORMAL]: {
    pillClasses: "bg-primary-50",
    errorMessage: "",
    errorClasses: "text-xs",
  },
};
