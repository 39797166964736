import PropTypes from "prop-types";
import { useId } from "react";

// import "@/assets/styles/theme.scss";

import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

/**
 * Button core component
 * @component
 */
export default function Button({
  id = "",
  label,
  preIcon,
  postIcon,
  type = "default",
  btnType = "button",
  variant = "primary",
  onClick,
  classes = "my-2",
  innerClasses = "",
  iconClasses = "",
  counterClasses = "",
  counter,
  appendedIcon,
  size = "md",
  showLoader,
  showLoaderAtRight = false,
  disabled,
  textAlignment = "center",
  labelStyleClasses = "",
  compact = false, // make button small, don't add extra width or height
  tooltipText = "",
  tooltipTranslationProps,
  tooltipTextProps,
  tooltipProps,
  ...props
}) {
  let _id = useId();
  _id = `button-tooltip-${_id.replaceAll(":", "")}`; // react tooltip trick
  id ||= _id;

  const loaderUI = (
    <svg
      className="w-5 h-5 animate-spin "
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );

  return (
    <>
      <button
        id={id}
        type={btnType}
        onClick={onClick}
        className={`disabled:cursor-not-allowed relative flex btn-${type}-${variant} btn-${size} flex gap-3 justify-${textAlignment} items-center ${classes}  ${
          compact ? "w-fit h-fit px-5" : ""
        }

      `}
        disabled={showLoader || disabled}
        {...props}
      >
        {showLoader && !showLoaderAtRight ? (
          <div className="ml-2">{loaderUI}</div>
        ) : null}
        {/*  */}
        <div
          className={`flex justify-center items-center ${innerClasses} ${
            innerClasses.includes("gap-") ? "" : "gap-2"
          }`}
        >
          {preIcon && !showLoader ? (
            <span className={label && "mr-1"}>
              {preIcon && <Icon name={preIcon} className={iconClasses} />}
            </span>
          ) : null}
          {showLoader ? null : typeof label === typeof "" && !showLoader ? (
            <Text translationKey={label} classes={labelStyleClasses} />
          ) : (
            label
          )}
          {postIcon && !showLoader ? (
            <span className="ml-2">
              {postIcon && <Icon name={postIcon} className={iconClasses} />}
            </span>
          ) : null}

          {counter && (
            <span className="">
              <Chip
                label={counter}
                type={type}
                muted={disabled}
                variant={variant}
                classes={counterClasses}
              />
            </span>
          )}
          {appendedIcon ? (
            <span className="absolute py-1 right-4">
              <Icon name={appendedIcon} className="w-4 h-4" />
            </span>
          ) : null}
        </div>
        {/*  */}
        {showLoader && showLoaderAtRight ? loaderUI : null}
      </button>

      {tooltipText ? (
        <Tooltip direction="top right" id={id} {...tooltipProps}>
          <Text
            translationKey={tooltipText}
            translationProps={tooltipTranslationProps}
            classes="text-sm font-medium text-neutral-800"
            {...tooltipTextProps}
          />
        </Tooltip>
      ) : null}
    </>
  );
}

Button.propTypes = {
  id: PropTypes.string,
  /**
   * Label of button
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Type of button
   * Possible values: 'default', 'success', 'danger'
   */
  type: PropTypes.string,
  /**
   * Variant of button
   * Possible values: 'primary', 'secondary', 'tertiary'
   * Default: 'primary'
   */
  variant: PropTypes.string,
  /**
   * click handler method
   */
  onClick: PropTypes.func,
  /**
   * Name of pre icon for button
   */
  preIcon: PropTypes.string,
  /**
   * Name of post icon for button
   */
  postIcon: PropTypes.string,
  /**
   * Class names that can be given for extra customization
   */
  classes: PropTypes.string,
  /**
   * Class names that can be given customization around label
   */
  innerClasses: PropTypes.string,
  /**
   * Icon's style class prop. Assumption: both icons are not used at the same time
   */
  iconClasses: PropTypes.string,
  /**
   * Counter's style class prop.
   */
  counterClasses: PropTypes.string,
  /**
   * Counter prop to show a count after the button label
   */
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Size of button
   * Possible values: 'sm', 'md', 'lg'
   * Default: 'md'
   */
  size: PropTypes.string,
  /**
   * Icon to be appended at the end
   */
  appendedIcon: PropTypes.string,
  /**
   * Flag to disable the button
   */
  disabled: PropTypes.bool,
  /**
   * Flag to show a loader
   */
  showLoader: PropTypes.bool,
  showLoaderAtRight: PropTypes.bool,
  /**
   * text alignment inside button
   * values start end center default
   */
  textAlignment: PropTypes.string,
  btnType: PropTypes.string,
  labelStyleClasses: PropTypes.string,
  compact: PropTypes.bool,
};
