import PaymentsApprovalsPageHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Approvals/PaymentsApprovalPageHelper";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

export default function PayrollPageApprovalAll() {
  return (
    <PaymentsApprovalsPageHelper
      page={BILL_PAYROLL_CONTEXT.PAYROLL}
      tabType="all"
    />
  );
}
