import PropTypes from "prop-types";
import { useRef } from "react";
import Popup from "reactjs-popup";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import {
  ACCOUNTING_CTAS,
  EXPENSE_FOOTER_CTA,
  EXPENSE_FOOTER_TEXT,
} from "@/constants/expense";
import { ROUTES } from "@/constants/routes";

export default function QRPayAndExpenseSliderFooter({
  isAccountingSlider,
  onAlt,
  isValid,
  onSubmit,
  isProccessing,
  actionByName,
  isExpenseOwner,
  expenseFooterCtas,
  accountingFooterCtas,
  scrollToComments,
}) {
  const popupRef = useRef();

  const expenseOverviewSection =
    window.location.pathname === ROUTES.cards.expenses.overView.absolutePath;

  const footerBtn = isAccountingSlider
    ? accountingFooterCtas?.map((item) => ACCOUNTING_CTAS[item])
    : expenseFooterCtas?.map((item) => EXPENSE_FOOTER_CTA[item]);

  const mainFooterBtnConfig = footerBtn?.filter(
    (cta) =>
      ["primary", "secondary"]?.includes(cta?.configType) &&
      cta?.tableConfigType !== "moreButtons"
  );

  const MoreOptionConfig = footerBtn?.filter(
    (cta) => cta?.tableConfigType === "moreButtons"
  );

  const expenseFooterTexts = expenseFooterCtas
    ?.filter((item) => EXPENSE_FOOTER_TEXT[item])
    ?.map((item) => EXPENSE_FOOTER_TEXT[item]);

  const commentCta = () => {
    return (
      <Button
        preIcon="ModeComment"
        variant="tertiary"
        classes="w-fit text-neutral-500 px-5 py-3"
        onClick={scrollToComments}
      />
    );
  };

  if (expenseOverviewSection) {
    return null;
  }

  return (
    <div className="px-6 py-3 slider-footer">
      <div className="flex items-center justify-between">
        {expenseFooterTexts
          ? expenseFooterTexts?.map((text, index) => (
              <Text
                key={index}
                translationKey={text?.text}
                classes={text?.classes}
                translationProps={{ actionByName }}
              />
            ))
          : null}

        <div className="flex items-center justify-end flex-1 h-full gap-6 bg-white">
          {scrollToComments ? commentCta() : null}
          {MoreOptionConfig?.length ? (
            <Popup
              keepTooltipInside="#tooltip-inside"
              ref={popupRef}
              trigger={
                <button
                  id="more-options"
                  className="flex items-center gap-2 card-wrapper text-neutral-500"
                >
                  <Icon name="MoreVerticle" className="w-4 h-4" />
                </button>
              }
              closeOnDocumentClick
              position="top left"
              className="filter-popup"
            >
              <div className="flex flex-col gap-2 w-61 ">
                {MoreOptionConfig?.map(({ key, label, description }) => (
                  <button
                    onClick={() => onSubmit(key)}
                    className="flex flex-col justify-start p-5 text-left text-neutral-500 hover:text-neutral-800 hover:bg-neutral-50"
                    key={`expense-qrpay-footer-more-options-button-${key}`}
                  >
                    <Text classes="" translationKey={label} />
                    {description ? (
                      <Text
                        classes="text-xs text-neutral-400 rounded-[10px] py-3"
                        translationKey={description}
                      />
                    ) : null}
                  </button>
                ))}
              </div>
            </Popup>
          ) : null}

          {mainFooterBtnConfig
            ?.map((config, index) => (
              <Button
                key={index}
                label={config?.label}
                type={config?.type}
                variant={config?.variant}
                classes={`${
                  config?.class?.includes("w-") ? config?.class : "w-15.5"
                } text-base active:bg-neutral-400 hover:bg-neutral-300`}
                onClick={() => onSubmit(config?.key)}
              />
            ))
            ?.reverse()}
        </div>
      </div>
    </div>
  );
}

QRPayAndExpenseSliderFooter.propTypes = {
  isAccountingSlider: PropTypes.bool,
  onAlt: PropTypes.func,
  onSubmit: PropTypes.func,
  isProccessing: PropTypes.bool,
  isExpenseOwner: PropTypes.bool,
  actionByName: PropTypes.string,
  isValid: PropTypes.bool,
  expenseFooterCtas: PropTypes.array,
  accountingFooterCtas: PropTypes.array,
  scrollToComments: PropTypes.func,
};
