import PropsTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  addGlobalSettingsFields,
  createMerchantRestrictions,
  getMerchantShallow,
  setCardSettingsFormInfo,
  setCardSliderTab,
  setCreateVirtualCardInput,
  setGlobalSettingsMccInfo,
  setIsFethingMerchantRestrictions,
  setMerchantRestrictions,
  updateMerchantRestrictions,
} from "@/store/reducers/cards";
import { fetchCategories } from "@/store/reducers/categories";

import {
  cardSettingsFormInfoSelector,
  createVirtualCardInputSelector,
  settingsSelector,
  shallowMerchantListSelector,
} from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";
import { clientSelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import EntityListWithDelete from "@/components/Cards/common/ShowAndDeleteEntityList";
import {
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_MULTISELECT,
  FIELD_TYPE_SELECT,
} from "@/components/GenericForm/common";
import vToast from "@/utils/vToast";
import { useForm } from "@/utils/useForm";
import {
  camelToSnake,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "@/utils/common";

import {
  CARD_SETTINGS_FORM_FIELD_KEYS,
  CARD_SLIDER_SELECTED_TABS_CARD,
  COMMON_SLIDERS_TRIGGER_KEYS,
  CREATE_VIRTUAL_CARD_FIELD_KEYS,
  MCC_OWNER_TYPE,
  MCC_SELECTED_CONTROLS_TYPES,
  categoryTypeOptions,
  control,
  controlRadioOptions,
  entityListTitleConfig,
  merchantAndCategoryControlsFieldKeys,
  merchantTypeOptions,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import SelectControlSection from "./SelectControlSection";
import { getPayloadForUpdatingMccDetails } from "./util";

/*
  1. Initial Values Format {control: "",selectedControlType: "", [merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS]  }
  2. If there are any intial Values than it will auto prefill.
  3. It also has a dispatch function for saving temperary in the redux or call api whatever we need.
 */

export default function ControlsPage({
  triggerSlider,
  sliderParam,
  isPreviewMode,
  externalSectionClasses,
}) {
  const clientInfo = useSelector(clientSelector);
  const clientId = clientInfo?.id;

  const MCC_CONFIG = {
    [COMMON_SLIDERS_TRIGGER_KEYS.CARD_SLIDER]: {
      key: CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY,
      formResponseSelector: cardSettingsFormInfoSelector,
      dispatchFunc: setCardSettingsFormInfo,
      additionalActionsOnContinue: () => {
        dispatch(setCardSliderTab(CARD_SLIDER_SELECTED_TABS_CARD[1]));
        handleCancel();
      },
    },
    [COMMON_SLIDERS_TRIGGER_KEYS.CREATE_VIRTUAL_CARD]: {
      formResponseSelector: createVirtualCardInputSelector,
      key: CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL,
      dispatchFunc: setCreateVirtualCardInput,
      additionalActionsOnContinue: () => {
        handleCancel();
      },
    },
    [COMMON_SLIDERS_TRIGGER_KEYS.GLOBAL_SETTINGS]: {
      formResponseSelector: settingsSelector,
      key: CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY,
      dispatchFunc: addGlobalSettingsFields,
      additionalActionsOnContinue: () => {
        handleAddiitonalActionsOnContinueForGlobalSettings();
      },
    },
  };

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const categoryList = useSelector(categoriesListSelector);

  const merchantList = useSelector(shallowMerchantListSelector);

  const [controlOptions, setControlOptions] = useState([]);

  const selectedSliderRulesConfig = MCC_CONFIG?.[triggerSlider];

  const mccKey = selectedSliderRulesConfig?.key;

  const formResponseSelector = selectedSliderRulesConfig?.formResponseSelector;

  const dispatchFunc = selectedSliderRulesConfig?.dispatchFunc;

  const additionalActionsOnContinue =
    selectedSliderRulesConfig?.additionalActionsOnContinue;

  const formResponse = useSelector(formResponseSelector);

  const initialMccResponse = getSelectorForIntialMccResponse();

  const updatedCategoryList = useMemo(() => {
    return categoryList?.map((categoryField) => ({
      ...categoryField,
      label: categoryField?.name,
    }));
  }, [categoryList]);

  const updatedMerchantList = useMemo(() => {
    return merchantList?.map((merchantField) => ({
      ...merchantField,
      label: merchantField?.name,
    }));
  }, [merchantList]);

  const ref = useLeftHeaderTitle({
    title: "cardSetting.control.title",
  });

  const initialValues = {
    [merchantAndCategoryControlsFieldKeys.CONTROL]: {
      value: undefined,
      validate: {
        required: true,
      },
    },
    [merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE]: {
      value: undefined,
      validate: {
        required: true,
      },
    },
    [merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS]: {
      value: undefined,
      validate: {
        required: true,
      },
    },
  };

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    _setValues: setValues,
  } = useForm(initialValues, handleFormSubmit);

  const entityListWithDeleteArray = useMemo(() => {
    const list = [];
    const requireControlArray =
      values[merchantAndCategoryControlsFieldKeys.CONTROL] === control.CATEGORY
        ? categoryList
        : merchantList;

    controlOptions.forEach((peopleId) => {
      const filteredOption = requireControlArray?.find(
        (category) => category?.id === peopleId
      );

      if (filteredOption) {
        const filteredElement = {
          id: filteredOption?.id,
          name: filteredOption?.name,
          disabled: isSelectedMccOptionDisabled(filteredOption),
        };

        if (isPreviewMode) {
          filteredElement.nameIconConfig = {
            icon:
              values[
                merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE
              ] === MCC_SELECTED_CONTROLS_TYPES.ALLOW
                ? "CircleCheck"
                : "Cancel",

            iconClasses:
              values[
                merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE
              ] === MCC_SELECTED_CONTROLS_TYPES.ALLOW
                ? "text-success-600"
                : "text-danger-600",
          };

          filteredElement.chipClassess = "bg-white";
        }

        list.push(filteredElement);
      }
    });

    return list;
  }, [controlOptions, merchantList, categoryList]);

  const schema = [
    {
      name: merchantAndCategoryControlsFieldKeys.CONTROL,
      label: "Select control",
      type: "selectControl",
      classes: "mt-5",
      show: true,
      radioOptions: controlRadioOptions,
    },
    {
      name: merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE,
      type: FIELD_TYPE_SELECT,
      options:
        values[merchantAndCategoryControlsFieldKeys.CONTROL] ===
        control.CATEGORY
          ? categoryTypeOptions
          : merchantTypeOptions,
      label: "Select control type",
      show: values[merchantAndCategoryControlsFieldKeys.CONTROL],
      optionsDisplayKey: "name",
      valueKey: "value",
      mainHeading: "Control type",
    },
    {
      name: merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS,
      type: FIELD_TYPE_MULTISELECT,
      wrapperContainerClasses: "bg-neutral-50 mt-4 p-5 rounded",
      triggerComponent: () =>
        getMultiSelectControlTypeOptionsTriggerComponent(),
      show: values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE],
      options: getDisabledStateForCheckboxDropdownOptions(
        values[merchantAndCategoryControlsFieldKeys.CONTROL] ===
          control.CATEGORY
          ? updatedCategoryList
          : updatedMerchantList
      ),
      handleDeleteIconClick,
    },
  ];

  if (sliderParam === SLIDERS_SEARCH_PARAMS.cards.cardsettings.controlsPage) {
    schema.push({
      type: FIELD_TYPE_CHECKBOX,
      label: "cards.vCards.createCardSlider.controls.controlsApplyTypeText",
      name: merchantAndCategoryControlsFieldKeys.APPLY_TYPE,
      classes: "text-base font-medium text-neutral-500",
      show:
        values?.[
          merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
        ]?.length > 0,
    });
  }

  const previewSchema = [
    {
      name: merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE,
      type: FIELD_TYPE_SELECT,
      disabled: true,
      descriptionTextClasses: "text-neutral-400",
      options:
        values[merchantAndCategoryControlsFieldKeys.CONTROL] ===
        control.CATEGORY
          ? categoryTypeOptions
          : merchantTypeOptions,
      label:
        values[merchantAndCategoryControlsFieldKeys.CONTROL] ===
        control.CATEGORY
          ? "cards.vCards.createCardSlider.controls.category.placeholder"
          : "cards.vCards.createCardSlider.controls.merchant.placeholder",
      show: values[merchantAndCategoryControlsFieldKeys.CONTROL],
      optionsDisplayKey: "name",
      valueKey: "value",
      mainHeading:
        values?.[merchantAndCategoryControlsFieldKeys.CONTROL] ===
        control.CATEGORY
          ? "Category Control"
          : "Merchant Control",
      description:
        values?.[merchantAndCategoryControlsFieldKeys.CONTROL] ===
        control.CATEGORY
          ? "cards.vCards.createCardSlider.controls.category.description"
          : "cards.vCards.createCardSlider.controls.merchant.description",
      classes: "pointer-events-none",
    },
    {
      name: merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS,
      type: FIELD_TYPE_MULTISELECT,
      wrapperContainerClasses: "bg-neutral-50 p-5 rounded",
      triggerComponent: () =>
        getMultiSelectControlTypeOptionsTriggerComponent(),
      show: !!(
        values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE] &&
        values?.[
          merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
        ]?.length > 0
      ),
      label: "",
      placeholder: "",
      options:
        values[merchantAndCategoryControlsFieldKeys.CONTROL] ===
        control.CATEGORY
          ? updatedCategoryList
          : updatedMerchantList,
      handleDeleteIconClick,
    },
  ];

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(getMerchantShallow());

    if (!initialMccResponse?.controlType) {
      setValues({
        [merchantAndCategoryControlsFieldKeys.CONTROL]: control.CATEGORY,
      });
    }
  }, []);

  useEffect(() => {
    if (
      initialMccResponse?.[
        merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
      ]
    ) {
      setValues({
        [merchantAndCategoryControlsFieldKeys.CONTROL]:
          initialMccResponse?.controlType,
        [merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS]:
          initialMccResponse?.[
            merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
          ],
        [merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE]:
          initialMccResponse?.selectedControlType,
      });
      setControlOptions(
        initialMccResponse?.[
          merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
        ] || []
      );
    }
  }, [
    initialMccResponse?.[
      merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
    ],
  ]);

  useEffect(() => {
    if (values[merchantAndCategoryControlsFieldKeys.CONTROL]) {
      setValues({
        ...values,
        [merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE]: undefined,
        [merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS]:
          [],
        [merchantAndCategoryControlsFieldKeys.APPLY_TYPE]: null,
      });
      setControlOptions([]);
    }
  }, [
    values[merchantAndCategoryControlsFieldKeys.CONTROL],
    values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE],
  ]);

  useEffect(() => {
    if (values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE]) {
      handleCheckBoxDropDown(
        [],
        merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
      );
    }
  }, [values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE]]);

  function handleDeleteIconClick(id) {
    const filteredIds = controlOptions.filter((peopleId) => peopleId !== id);
    setValues({
      ...values,
      [merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS]:
        filteredIds,
    });
    setControlOptions(filteredIds);
  }

  function getEntityTitleComponent() {
    const entityListTitle =
      entityListTitleConfig[
        values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE]
      ]?.labelObject?.[values[merchantAndCategoryControlsFieldKeys.CONTROL]];

    const entityListTitleClasses =
      entityListTitleConfig[
        values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE]
      ]?.labelObject?.classes;

    const entityListTitlePreIcon =
      entityListTitleConfig[
        values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE]
      ]?.preIconObject?.icon;

    const entityListTitlePreIconClasses =
      entityListTitleConfig[
        values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE]
      ]?.preIconObject?.classes;

    return (
      <div className="flex flex-row items-center gap-2">
        <Icon
          name={entityListTitlePreIcon}
          className={entityListTitlePreIconClasses}
        />

        <Text
          translationKey={entityListTitle}
          classes={entityListTitleClasses}
        />
      </div>
    );
  }

  function handleCheckBoxDropDown(val, name) {
    const formValuesObject = { ...values };

    if (val?.selectedIds) {
      formValuesObject[`${name}`] = val.selectedIds;
    } else if (
      values[merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE] ===
        initialMccResponse?.[
          merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE
        ] &&
      values[merchantAndCategoryControlsFieldKeys.CONTROL] ===
        initialMccResponse?.[merchantAndCategoryControlsFieldKeys.CONTROL]
    ) {
      formValuesObject[`${name}`] =
        initialMccResponse?.[
          merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
        ];
    } else {
      formValuesObject[`${name}`] = [];
    }

    setValues((prev) => ({ ...prev, ...formValuesObject }));
    setControlOptions(
      formValuesObject[
        merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
      ]
    );
  }

  function handleFormSubmit() {
    setFormReponse();
    additionalActionsOnContinue();
  }

  function getSelectorForIntialMccResponse() {
    return formResponse?.[mccKey];
  }

  function handleCancel() {
    searchParams.delete(sliderParam || "");
    setSearchParams(searchParams);
  }

  function setFormReponse() {
    if (dispatchFunc) {
      dispatch(
        dispatchFunc({
          [mccKey]: {
            ...values,
            [merchantAndCategoryControlsFieldKeys.DISABLED_OPTIONS]:
              initialMccResponse?.[
                merchantAndCategoryControlsFieldKeys.DISABLED_OPTIONS
              ],
          },
        })
      );
    }
  }

  function handleAddiitonalActionsOnContinueForGlobalSettings() {
    const merchantId =
      formResponse?.[CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY]
        ?.merchantId;

    const applyType =
      values?.[merchantAndCategoryControlsFieldKeys?.APPLY_TYPE];

    const requiredPayload = getPayloadForUpdatingMccDetails(values);

    const _payload = {
      ...requiredPayload,
      owner_type: MCC_OWNER_TYPE.CLIENT,
      owner_id: clientId,
    };

    if (applyType) {
      _payload[camelToSnake(merchantAndCategoryControlsFieldKeys.APPLY_TYPE)] =
        applyType;
    }

    const formattedPayload = {
      payload: _payload,
      onSuccess: (response) => {
        handleCancel();
        vToast(getSuccessToastMessage(response));
        dispatch(setGlobalSettingsMccInfo({}));
        dispatch(setMerchantRestrictions({}));
        dispatch(setIsFethingMerchantRestrictions(false));
      },
      onFail: (error) => {
        vToast(getErrorToastMessage(error));
      },
    };

    if (merchantId) {
      formattedPayload.id = merchantId;
    }

    dispatch(
      merchantId
        ? updateMerchantRestrictions(formattedPayload)
        : createMerchantRestrictions(formattedPayload)
    );
  }

  function getDisabledStateForCheckboxDropdownOptions(options) {
    return options?.map((option) => ({
      ...option,
      disabled: isSelectedMccOptionDisabled(option),
    }));
  }

  function isSelectedMccOptionDisabled(option) {
    return !!initialMccResponse?.[
      merchantAndCategoryControlsFieldKeys.DISABLED_OPTIONS
    ]?.[option?.id];
  }

  function getMultiSelectControlTypeOptionsTriggerComponent() {
    return !isPreviewMode ? (
      <div className="flex flex-row justify-end">
        <Button
          label={
            values[
              merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
            ]?.length > 0
              ? "cards.vCards.createCardSlider.controls.merchant.triggerButton.addAnother"
              : values[merchantAndCategoryControlsFieldKeys.CONTROL] ===
                  control.CATEGORY
                ? "cards.vCards.createCardSlider.controls.merchant.triggerButton.category"
                : "cards.vCards.createCardSlider.controls.merchant.triggerButton.merchant"
          }
          size="sm"
          preIcon="Add"
        />
      </div>
    ) : null;
  }

  return (
    <>
      <div className={`slider-content-core ${externalSectionClasses}`}>
        <div className="mb-16">
          <div className="flex flex-col gap-3">
            <Text
              refProp={ref}
              translationKey="cardSetting.control.title"
              classes="text-3xl text-neutral-800 font-bold"
            />

            <Text
              translationKey="cards.vCards.createCardSlider.controls.description"
              classes="text-sm text-neutral-500 font-medium"
            />

            <form
              className={`flex flex-col  ${isPreviewMode ? "gap-4" : "gap-8"}`}
              id="merchant_category_controls"
              onSubmit={handleSubmit}
            >
              {(isPreviewMode ? previewSchema : schema).map((field, index) => {
                const {
                  label = "",
                  type = "",
                  name = "",
                  options = [],
                  radioOptions = [],

                  triggerComponent = () => <></>,
                  classes = "",
                  show = false,
                  valueKey = "",
                  optionsDisplayKey = "",
                  mainHeading = "",
                  description = "",
                  descriptionTextClasses = "",
                  disabled = false,
                  handleDeleteIconClick: handleDeleteIconClickFunc,
                } = field;

                let FeildComponent = <></>;

                switch (type) {
                  case FIELD_TYPE_MULTISELECT:
                    FeildComponent = (
                      <CheckboxDropdown
                        label={label}
                        selectedIdsArray={[...controlOptions]}
                        options={options}
                        triggerComponent={triggerComponent}
                        name={name}
                        handleSubmit={(val) =>
                          handleCheckBoxDropDown(val, name)
                        }
                      />
                    );
                    break;

                  case "selectControl":
                    FeildComponent = (
                      <SelectControlSection
                        radioOptions={radioOptions}
                        classes={classes}
                        name={name}
                        handleChange={handleChange}
                        error={errors[name]}
                        value={values[name]}
                      />
                    );
                    break;

                  case FIELD_TYPE_CHECKBOX:
                    FeildComponent = (
                      <Checkbox
                        label={label}
                        labelClasses={classes}
                        checked={values[name] === "all_cards"}
                        onClickHandler={(e) => {
                          handleChange({
                            target: {
                              name,
                              type,
                              value: e ? "all_cards" : null,
                            },
                          });
                        }}
                      />
                    );
                    break;

                  case FIELD_TYPE_SELECT:
                    FeildComponent = (
                      <VpSelect
                        name={name}
                        classes={classes}
                        description={description}
                        descriptionTextClasses={descriptionTextClasses}
                        label={label}
                        placeholder={label}
                        options={options}
                        value={values[name]}
                        error={errors[name]}
                        menuPosition="absolute"
                        optionsDisplayKey={optionsDisplayKey}
                        valueKey={valueKey}
                        handleChange={handleChange}
                        disabled={disabled}
                        insideForm
                        searchable
                      />
                    );
                    break;

                  default:
                    break;
                }

                return show ? (
                  <div
                    key={`${index}-${name}`}
                    className={`flex flex-col gap-3 ${field?.wrapperContainerClasses}`}
                  >
                    {mainHeading ? (
                      <Text
                        translationKey={mainHeading}
                        classes="text-lg font-semibold text-neutral-800 my-3"
                      />
                    ) : null}

                    {name ===
                    merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS ? (
                      <EntityListWithDelete
                        list={entityListWithDeleteArray}
                        title={
                          !isPreviewMode ? getEntityTitleComponent() : null
                        }
                        handleDeleteIconClick={handleDeleteIconClickFunc}
                        hideDeleteIcon={isPreviewMode}
                        classes="w-full"
                      />
                    ) : null}
                    {FeildComponent}
                  </div>
                ) : null;
              })}
            </form>
          </div>
        </div>
      </div>

      <div
        className={`px-3 py-5 slider-footer ${isPreviewMode ? "absolute" : ""}`}
      >
        <div className="flex items-center justify-end gap-3">
          {!isPreviewMode ? (
            <>
              <Button
                label="cards.pCards.sliders.createCardSlider.buttons.cancel"
                classes="w-[160px] text-neutral-500 font-medium"
                variant="tertiary"
                onClick={handleCancel}
              />
              <Button
                label="cards.pCards.sliders.createCardSlider.buttons.continue"
                classes="w-[160px] text-white font-medium"
                variant="primary"
                btnType="submit"
                form="merchant_category_controls"
              />
            </>
          ) : (
            <Button
              label="cards.vCards.createCardSlider.controls.editControl"
              classes="w-16"
              variant="tertiary"
              btnType="submit"
              onClick={() => {
                searchParams.set(sliderParam, true);
                setSearchParams(searchParams);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

ControlsPage.propTypes = {
  triggerSlider: PropsTypes.string,
  sliderParam: PropsTypes.string,
  isPreviewMode: PropsTypes.bool,
  externalSectionClasses: PropsTypes.string,
};
