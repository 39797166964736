import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import { amountToCurrency } from "@/utils/common";

/**
 * @param {Object} amount
 * @param {Object} feeOrExchangeAmount
 *
 * Preview:
 *  _ _ _ _ _ _ _ _ _
 * |      782.75 SGD |
 * |   <-  0.75  SGD |
 * | _ _ _ _ _ _ _ _ |
 *
 *  _ _ _ _ _ _ _ _ _
 * |          22 SGD |
 * |       1100  INR |
 * | _ _ _ _ _ _ _ _ |
 */
export default function AmountWithExchangeAndFee({
  transactionAmount,
  amount,
  feeOrExchangeAmount,
}) {
  const showIcon = amount?.currency === feeOrExchangeAmount?.currency;
  return (
    <div className="flex flex-col items-end">
      {amount?.value ? (
        <span className="text-sm font-semibold cursor-pointer text-neutral-800">
          {amountToCurrency(amount)}
        </span>
      ) : null}
      {feeOrExchangeAmount?.value ? (
        <span className="flex items-center gap-2">
          <Icon name="Cashback" className="w-3 h-3 text-success-500" />
          <span className="text-sm font-medium text-neutral-500">
            {amountToCurrency(feeOrExchangeAmount)}
          </span>
        </span>
      ) : null}
      {transactionAmount?.value ? (
        <span className="text-xs font-medium text-neutral-500">
          {amountToCurrency(transactionAmount)}
        </span>
      ) : null}
    </div>
  );
}
AmountWithExchangeAndFee.propTypes = {
  amount: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
  feeOrExchangeAmount: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
};
