import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAndSelectUser } from "@/store/reducers/user";

import {
  myVolopayExpenseFilters,
  overviewMyVolopayExpenseFilters,
} from "@/store/selectors/expense";
import { userSelector } from "@/store/selectors/user";

import OverviewPageHelper from "@/components/common/QrPayAndExpense/OverviewPageHelper";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";

function MyVolopayExpensesOverviewPage() {
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const myVolopayFilter = useSelector(overviewMyVolopayExpenseFilters);

  useEffect(() => {
    dispatch(fetchAndSelectUser({ userId: currentUser?.id }));
  }, []);

  return (
    <OverviewPageHelper
      myVolopayContext
      page={QRPAY_EXPENSES_CONTEXT.EXPENSES}
      filteringParams={{ user: currentUser?.id }}
      filteringParamsMissingDetails={{ user_ids: [currentUser?.id] }}
      filterSelectorFromParent={myVolopayFilter}
    />
  );
}

export default MyVolopayExpensesOverviewPage;
