// react imports
import PropTypes from "prop-types";
import React from "react";

import Radio from "@/components/core/Radio";
// core components
import Text from "@/components/core/Text";

// styles
import "./style.scss";

export default function RadioWithLabelField({
  name,
  radioLabel,
  radioDescription,
  radioValue,
  handleChange,
  error,
  sectionValue,
}) {
  return (
    <div className="card-wrapper">
      <Radio
        name={name}
        value={radioValue}
        isChecked={radioValue === sectionValue}
        handleChange={handleChange}
        error={error}
        label={
          <div className="flex flex-col ml-4">
            <Text
              translationKey={radioLabel}
              classes="text-sm not-italic font-semibold leading-6 tracking-[0.28px] text-neutral-800"
            />
            <Text
              translationKey={radioDescription}
              classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-600"
            />
          </div>
        }
        insideForm
        textClases="bulk-order-options"
      />
    </div>
  );
}

RadioWithLabelField.propTypes = {
  radioLabel: PropTypes.string,
  radioDescription: PropTypes.string,
  name: PropTypes.string,
  radioValue: PropTypes.string,
  handleChange: PropTypes.func,
  error: PropTypes.string,
  sectionValue: PropTypes.string,
};
