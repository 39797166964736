import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// constant file imports

import { fetchPayrollSetting } from "@/store/reducers/payments";

import { enablePayrollSelector } from "@/store/selectors/payments";
import { userPermissionSelector } from "@/store/selectors/user";

import Tabs from "@/components/core/Tabs";
// core imports
import Text from "@/components/core/Text";

import GeneralSettings from "@/components/common/BillPayAndPayroll/Settings/General";
import TogglePayroll from "@/components/common/BillPayAndPayroll/Settings/General/TogglePayroll";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { PAYROLL_SETTINGS_TABS } from "@/utils/constants/payments";

import { PERMISSIONS } from "@/constants/user";

import "./styles.scss";

export default function PayrollSettings() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(PAYROLL_SETTINGS_TABS[0].key);
  const isPayrollSettingEnabled = useSelector(enablePayrollSelector);

  const userPermission = useSelector(userPermissionSelector);

  const readOnlyView =
    userPermission?.some(
      (data) => data.name === PERMISSIONS.GLOBAL_SETTINGS_MANAGE
    ) &&
    !userPermission?.some(
      (data) => data.name === PERMISSIONS.PAYROLL_ADMINISTRATE
    ) &&
    !userPermission?.some((data) => data.name === PERMISSIONS.PAYROLL_MANAGE);

  useEffect(() => {
    dispatch(fetchPayrollSetting());
  }, [isPayrollSettingEnabled]);
  return (
    <div className="billpay-settings-page-container">
      <div className="billpay-settings-page-top-section">
        <div aria-label="breadcrumb-and-large-title">
          <Text
            classes="text-neutral-500 text-sm font-semibold block"
            translationKey="payroll.payrollSettings.payrollBreadcrumb"
          />

          <Text
            classes="texxt-neutral-800 text-4xl font-extrabold block"
            translationKey="payroll.payrollSettings.title"
          />
        </div>
      </div>
      <div className="billpay-settings-page-main-section">
        {isPayrollSettingEnabled ? (
          !readOnlyView ? (
            <Tabs
              items={PAYROLL_SETTINGS_TABS}
              selectedTab={selectedTab?.key}
              setCurrentTab={(tab) => setSelectedTab(tab)}
            />
          ) : (
            <GeneralSettings context={BILL_PAYROLL_CONTEXT.PAYROLL} />
          )
        ) : (
          <TogglePayroll />
        )}
      </div>
    </div>
  );
}
