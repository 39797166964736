import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function MissingCardLoader() {
  return (
    <div className="flex items-center gap-2">
      <LoaderSkeleton type="circle" />
      <div className="flex flex-col">
        <LoaderSkeleton />
        <LoaderSkeleton />
      </div>
    </div>
  );
}
