import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  analyticsRequestStatement,
  fetchAnalyticsLedgerCurrencies,
} from "@/store/reducers/analytics";

import {
  analyticsEntitesSelector,
  analyticsLedgerCurrencySelector,
  analyticsLedgerDataSelector,
  isFetchingAnalyticsEntitesSelector,
  isLoadingSelector,
} from "@/store/selectors/analytics";
import { accountsSelector } from "@/store/selectors/app";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import DateRangeFilter from "@/components/core/Filters/DateRangeFilter";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import SelectDuration from "@/components/Accounting/Ledger/Sliders/RequestStatementSlider/SelectDuration";
import { EXPORT_MODES } from "@/utils/constants/ledger";
import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
} from "@/utils/constants/filters";
import { capitalizeFirstLetter } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EXPORT_PAGE_TYPE } from "@/constants/exports";

import { ANALYTICS_LEDGER_EXPORT_FORMATS } from "../common/enum";

function AnalyticsRequestStatementSlider() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const entities = useSelector(analyticsEntitesSelector);
  const accounts = useSelector(analyticsLedgerCurrencySelector);
  const ledgerData = useSelector(analyticsLedgerDataSelector);
  const isLoading = useSelector(isLoadingSelector);

  const [duration, setDuration] = useState("");
  const [format, setFormat] = useState("");
  const [account, setAccount] = useState("");
  const [entity, setEntity] = useState("");

  const [entityOptions, setEntityOptions] = useState(entities);

  const onSuccess = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.analytics.analyticsRequestStatement
    );

    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (entities?.length) {
      setEntityOptions(
        entities?.map((data) => ({
          label: data?.subdomain,
          value: data?.subdomain,
        }))
      );
    }
  }, [entities]);

  useEffect(() => {
    if (entity)
      dispatch(
        fetchAnalyticsLedgerCurrencies({
          subdomain: entity,
        })
      );
  }, [entity]);

  const itemRef = useLeftHeaderTitle({
    title: "analytics.export.heading",
  });
  return (
    <>
      <div className="slider-content-container">
        <div className="flex flex-col gap-9 slider-content-core px-9 ">
          <div ref={itemRef} className="flex flex-col">
            <Text
              classes="font-bold text-3xl"
              translationKey="analytics.requestStatement.heading"
            />
            <Text
              classes="font-medium text-neutral-500 text-sm"
              translationKey="analytics.requestStatement.desc"
            />
          </div>

          <div className="flex flex-col w-full gap-9">
            <Text
              classes="font-semibold text-xl"
              translationKey="analytics.requestStatement.entityHeading"
            />
            <div className="flex flex-wrap gap-4">
              {entityOptions?.map((data, id) => (
                <Radio
                  label={capitalizeFirstLetter(data?.label)}
                  labelClasses="font-semibold text-neutral-800"
                  value={data?.value}
                  handleChange={(e) => setEntity(e)}
                  isChecked={entity === data.value}
                  key={id}
                />
              ))}
            </div>
          </div>

          <div className="flex flex-col w-full gap-9">
            <Text
              classes="font-semibold text-xl"
              translationKey="analytics.requestStatement.account"
            />
            <div className="flex flex-wrap gap-4">
              {accounts?.map((data, id) => (
                <Radio
                  label={capitalizeFirstLetter(data?.name)}
                  labelClasses="font-semibold text-neutral-800"
                  value={data}
                  handleChange={() => setAccount(data)}
                  isChecked={account?.name === data?.name}
                  key={id}
                />
              ))}
            </div>
          </div>

          <div className="flex flex-col w-full gap-9">
            <Text
              classes="font-semibold text-xl"
              translationKey="analytics.export.durationHeading"
            />

            <SelectDuration onSelect={(object) => setDuration(object)} />
          </div>

          <div className="flex flex-col w-full gap-9">
            <Text
              classes="font-semibold text-xl"
              translationKey="analytics.export.formatHeading"
            />

            <VpSelect
              label="analytics.export.selectFormat"
              value={format?.value}
              optionsDisplayKey="label"
              valueKey="value"
              handleChange={(e) => {
                setFormat(e);
              }}
              options={ANALYTICS_LEDGER_EXPORT_FORMATS}
            />
          </div>

          <div className="mb-10">
            <Alert
              classes="border-neutral-300"
              wrapperDivClass="items-start border-neutral-200"
              descriptionClasses="font-normal text-neutral-500"
              title="analytics.export.alertHeading"
              description="analytics.export.alertDesc"
            />
          </div>
        </div>
      </div>
      <div className="px-5 py-4 slider-footer">
        <div className="flex justify-end gap-4">
          <Button
            onClick={() => setSearchParams({})}
            label="reimbursement.rejectPayment.cancel"
            variant="tertiary"
            classes="w-fit px-8 py-3 text-btn-lg font-semibold text-neutral-500"
            disabled={isLoading}
          />

          <Button
            onClick={() => {
              dispatch(
                analyticsRequestStatement({
                  onSuccess,
                  payload: {
                    before: duration?.to,
                    after: duration?.from,
                    account_id: account?.accountId,
                    mode: EXPORT_MODES.download,
                    download_type: format?.value,
                    ...EXPORT_PAGE_TYPE.LEDGER,
                    subdomain: entity,
                  },
                })
              );
            }}
            variant="primary"
            label="analytics.export.exportCta"
            classes=" w-fit px-8 py-3 text-btn-lg font-semibold "
            showLoader={isLoading}
            disabled={!account || !entity || !duration || !format}
          />
        </div>
      </div>
    </>
  );
}

export default AnalyticsRequestStatementSlider;
