import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  PAYMENTS_SLICE_ATTRIBUTE_KEY,
  fetchPayments,
  resetPaymentStore,
  setPayment,
  setPayments,
} from "@/store/reducers/payments";

import { appModalSelector } from "@/store/selectors/app";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  countChangesSelector,
  isApproveActionPendingForIdSelector,
  isPaymentsFetchingSelector,
  paymentsAllBillPayPageFilterKeysSelector,
  paymentsAllPayrollPageFilterKeysSelector,
  paymentsFailedPageFilterKeysSelector,
  paymentsHasMoreSelector,
  paymentsListSelector,
  paymentsTotalSelector,
} from "@/store/selectors/payments";
import { isActionPendingForPurchaseBillSelector } from "@/store/selectors/purchase-bills";

import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Table from "@/components/core/Table";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import PaymentsTableLoader from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentsTableLoader";
import { getPaymentsTableDefaultHeader } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/tableConfig";
import BillPayFilters from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/BillPayFilters";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import vToast from "@/utils/vToast";
import { convertFilters } from "@/utils/filters";
import { handleExport } from "@/utils/exports";
import { SORT_KEYS } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  PAYMENTS_TABS,
  PAYMENT_STATUSES,
  PAYMENT_TOASTER_TEXT,
  PAYROLL_PAYMENTS_TABS,
  TAB_TYPES,
} from "@/utils/constants/payments";
import { PAYMENT_STATUS_FILTER_VALUE } from "@/utils/constants/filters";
import { debounce } from "@/utils/common";

import { EXPORT_FILTER_STATUS, EXPORT_PAGE } from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function PaymentList({
  pageType,
  page,
  openSliderHandler,
  enableFilter = true,
  stepPaginationEnable = false,
  itemPagination = PAGINATION_PER_REQUEST_LIMIT,
  defaultParams = {},
  tableSettings = null,
  containerClasses = "",
}) {
  const dispatch = useDispatch();
  const appModal = useSelector(appModalSelector);

  const inPayrollContext = page === BILL_PAYROLL_CONTEXT.PAYROLL;
  const payments = useSelector((state) => paymentsListSelector(state));
  const isFetching = useSelector(isPaymentsFetchingSelector);
  const hasMore = useSelector((state) => paymentsHasMoreSelector(state));
  const totalPayments = useSelector(paymentsTotalSelector);

  const isActionApprovePending = useSelector(
    (state) =>
      isApproveActionPendingForIdSelector(state) ||
      isActionPendingForPurchaseBillSelector(state)
  );

  const countChanges = useSelector((state) => countChangesSelector(state));
  const isEmpty = !payments?.length;
  const [searchText, setSearchText] = useState("");
  const filterConfig = {
    [BILL_PAYROLL_CONTEXT.BILLPAY]: {
      [TAB_TYPES.FAILED]: paymentsFailedPageFilterKeysSelector,
      [TAB_TYPES.ALL]: paymentsAllBillPayPageFilterKeysSelector,
    },
    [BILL_PAYROLL_CONTEXT.PAYROLL]: {
      [TAB_TYPES.FAILED]: paymentsFailedPageFilterKeysSelector,
      [TAB_TYPES.ALL]: paymentsAllPayrollPageFilterKeysSelector,
    },
  };
  const filters = useSelector(filterConfig[page][pageType]);
  const appliedFilters = useSelector(appliedFilterSelector);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const ctaAction = (type) => {
    vToast({
      description: PAYMENT_TOASTER_TEXT[type],
    });
  };
  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  const actionButtonOnClickHandler = (val) => {};

  const searchHandler = debounce((text) => {
    setSearchText(text);
  }, 500);

  const filterOptions = {
    ...(pageType === "all"
      ? {
          status: defaultParams.status
            ? defaultParams.status
            : BILL_PAYROLL_CONTEXT.ALL,
        }
      : { status: [PAYMENT_STATUSES.failed] }),
    ...(searchText ? { q: searchText } : {}),
    ...convertFilters(appliedFilters),
  };
  const onReset = () => {
    dispatch(resetPaymentStore());
  };

  const { pathname: currentPath } = useLocation();

  const currentTab = (
    inPayrollContext ? PAYROLL_PAYMENTS_TABS : PAYMENTS_TABS
  ).find((tab) => tab.path === currentPath);
  const tabListingQueryParams = currentTab?.defaultQueryParams;

  const loadMore = () => {
    if (stepPaginationEnable) {
      dispatch(setPayments([]));
    }
    dispatch(
      fetchPayments({
        context: page,
        value: {
          page: pageNum,
          limit: itemPagination,
          payroll: page === BILL_PAYROLL_CONTEXT.PAYROLL,
          ...defaultParams,
          ...filterOptions,
          ...(filterOptions?.status ===
            PAYMENT_STATUS_FILTER_VALUE.UNSCHEDULED && {
            outside_volopay: false,
          }),
          [SORT_KEYS.COLUMN]: sorting?.category,
          [SORT_KEYS.DIRECTION]: sorting?.type,
        },
        key: PAYMENTS_SLICE_ATTRIBUTE_KEY,
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...filterOptions,
      ...sorting,
    },
  });

  const onScroll = () => {
    if (!stepPaginationEnable) {
      updatePageNum();
    }
  };

  const updatePageNum = () => {
    setPageNum((prev) => prev + 1);
  };

  const headerSelected = headerSelectAll; // all selected or else unchecked

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedPayments(payments.map((payment) => payment.id));
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedPayments([]);
    }
  };

  const rowSelectionHandler = (payment) => {
    if (selectedPayments?.includes(payment?.id)) {
      setSelectedPayments((prev) => prev.filter((id) => id !== payment?.id));
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, payment?.id]);
      }
    } else {
      setSelectedPayments((prev) => [...prev, payment?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== payment?.id));
    }
    dispatch(
      setPayment({
        id: payment.id,
        value: {
          ...payment,
          selected: !payment.selected,
        },
      })
    );
  };

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);
      if (!deSelectedIds.length) {
        setSelectedPayments(payments?.map((data) => data.id));
      } else {
        const filteredIds = payments
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedPayments(filteredIds);
      }
    }
  }, [bulkSelectAll, payments, deSelectedIds]);

  useEffect(() => {
    // When user selects checkbox from header
    setHeaderSelectAll(
      (selectedPayments.length === pageNum * PAGINATION_PER_REQUEST_LIMIT ||
        selectedPayments.length === payments?.length) &&
        !isEmpty
    );
  }, [selectedPayments, pageNum, payments]);

  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.25,
  });

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
    emptyDataTitle,
    emptyDataDescription,
    emptySubHeadingDataTitle,
  } = tableSettings ?? getPaymentsTableDefaultHeader(page, currentTab?.key);

  return (
    <div className={containerClasses}>
      {enableFilter && (
        <BillPayFilters filters={filters} page={page} tab={pageType} payment />
      )}
      <div>
        <Table
          {...{
            ...tableSetting,
            emptyDataTitle,
            emptyDataDescription: emptySubHeadingDataTitle,
          }}
          bulkApproveVisible={selectedPayments?.length > 0}
          bulkApproveHeight="100px"
          bulkApproveContent={
            <BulkActionComponent
              selectedRows={
                deSelectedIds.length
                  ? totalPayments - deSelectedIds.length
                  : selectedPayments?.length
              }
              totalRows={totalPayments}
              descriptionText="common.exportDesc"
              bulkApproveSelectAll={
                (bulkSelectAll && !deSelectedIds.length) ||
                selectedPayments?.length === totalPayments
              }
              showSelectAllButton={headerSelectAll}
              selectAllHandler={(val) => {
                setBulkSelectAll(val);
                setDeSelectedIds([]);
              }}
              handleExport={() => {
                handleExport(
                  false,
                  selectedPayments?.length,
                  appliedFilters,
                  dispatch,
                  {
                    export_type: EXPORT_PAGE.PAYMENT,

                    tab: pageType,
                    ...(page === BILL_PAYROLL_CONTEXT.PAYROLL
                      ? { payroll: true }
                      : {}),

                    ...(bulkSelectAll
                      ? {
                          ...(page === BILL_PAYROLL_CONTEXT.PAYROLL
                            ? { payroll: true }
                            : null),
                          status:
                            pageType === "all"
                              ? EXPORT_FILTER_STATUS.PAYMENT.ALL.status
                              : pageType,
                          deselected_ids: deSelectedIds,
                        }
                      : { selected_ids: selectedPayments }),
                  }
                );
              }}
              showExport
              clearSelection={() => {
                setBulkSelectAll(false);
                setHeaderSelectAll(false);
                setSelectedPayments([]);
                setDeSelectedIds([]);
                dispatch(
                  setPayments(
                    payments?.map((payment) => {
                      return { ...payment, selected: !payment.selected };
                    })
                  )
                );
              }}
            />
          }
        >
          <tr className={tableHeadingRowClasses}>
            {tableHeading?.map((headVal, index) => (
              <th className={headVal.classes} key={headVal.id}>
                <HeaderCell
                  val={{
                    ...headVal,
                    onCheckboxClick: headerSelectionHandler,
                    checkedValue: headerSelected && !isEmpty,
                    showCheckbox:
                      !stepPaginationEnable && index === 0 && !isFetching,
                    disabled: isEmpty,
                    sorting,
                    setSorting,
                  }}
                />
              </th>
            ))}
          </tr>

          {(!isFetching || !isEmpty) &&
            payments?.map((val, index, arr) => {
              const disableCheck = Boolean(
                isActionApprovePending &&
                  [val?.owner?.id, val?.id]?.includes(isActionApprovePending)
              );

              return (
                <tr
                  key={val.id}
                  className={`${tableDataRowClasses}  ${
                    disableCheck
                      ? "bg-neutral-100 text-neutral-500 pointer-events-none "
                      : ""
                  }`}
                  ref={(ref) => {
                    if (index === arr.length - 1 && hasMore) {
                      handleRefChange(ref);
                    }
                  }}
                >
                  {tableHeading?.map(
                    ({ cellComponent: Component, classes, id }) => (
                      <td
                        className={`${classes}  ${
                          disableCheck && "bg-neutral-100 text-neutral-500 "
                        } ${
                          selectedPayments?.includes(val)
                            ? "selected-row-cell"
                            : ""
                        }`}
                        key={id}
                      >
                        <Component
                          val={{
                            ...val,
                            tabType: pageType,
                            paymentsPage: true,
                            context: page,
                            disabled: disableCheck,
                            openSliderHandler,
                            showCheckbox: !stepPaginationEnable,
                            onCheckboxClick: () => rowSelectionHandler(val),
                            checkedValue: selectedPayments?.includes(val?.id),
                          }}
                          key={id}
                        />
                      </td>
                    )
                  )}
                </tr>
              );
            })}
          {isFetching && (
            <PaymentsTableLoader count={itemPagination + 1} hideLastCell />
          )}
        </Table>
        {stepPaginationEnable && payments?.length > 0 && (
          <StepPaginationButtons
            currentPage={pageNum}
            total={totalPayments}
            limit={itemPagination}
            handleLeftButton={() => {}}
            handleRightButton={updatePageNum}
            leftDisabled={pageNum <= 1}
            rightDisabled={totalPayments / pageNum <= itemPagination}
          />
        )}
      </div>
    </div>
  );
}

PaymentList.propTypes = {
  openSliderHandler: PropTypes.func,
  pageType: PropTypes.string,
  page: PropTypes.string,
  enableFilter: PropTypes.bool,
  stepPaginationEnable: PropTypes.bool,
  itemPagination: PropTypes.number,
  defaultParams: PropTypes.object,
  tableSettings: PropTypes.object,
  containerClasses: PropTypes.string,
};
