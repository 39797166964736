import { bool, func, string } from "prop-types";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";

export default function EnableConfirmation({
  title,
  subtitle,
  showModal,
  description,
  setShowModal,
  onSubmitClick,
  onCancelClick,
}) {
  return (
    <Modal open={showModal} innerContainerClasses="w-126 px-6">
      <div className="mt-6 mb-3 w-10 h-10 text-primary-500">
        <Icon name="Info" className="font-bold w-9 h-9" />
      </div>
      <div className="mb-2 font-bold text-lg">
        <Text translationKey={title} />
      </div>
      <div className="py-2 font-semibold ">
        <Text translationKey={subtitle} />
      </div>
      <div className="py-2">
        <div className="text-neutral-400 text-xs">
          <Text translationKey={description} />
        </div>
      </div>
      <div className="py-4 flex gap-2">
        <Button
          variant="tertiary"
          label="Cancel"
          onClick={() => {
            setShowModal(false);
            onCancelClick();
          }}
        />
        <Button
          variant="primary"
          label="common.yesEnable"
          onClick={onSubmitClick}
        />
      </div>
    </Modal>
  );
}

EnableConfirmation.propTypes = {
  title: string,
  subtitle: string,
  description: string,
  showModal: bool,
  setShowModal: func,
  onSubmitClick: func,
  onCancelClick: func,
};
