import PropTypes from "prop-types";
import React from "react";

import EmptyData from "@/components/core/EmptyData";
import Text from "@/components/core/Text";

function CreateNewPage({
  title,
  description,
  emptyDataTitle,
  emptyDataDescription,
  onClickHandler,
  emptyButtonIcon,
  emptyButtonLabel,
  outerSectionClasses,
  innerSectionClasses,
}) {
  return (
    <div className={outerSectionClasses}>
      <div className={`flex flex-col ${innerSectionClasses}`}>
        <Text
          color="neutral-800"
          classes="text-3xl font-bold"
          translationKey={title}
        />
        <Text
          color="neutral-500"
          classes="text-sm font-medium"
          translationKey={description}
        />
      </div>
      <div className="flex items-center justify-center mt-15 ">
        <EmptyData
          buttonIcon={emptyButtonIcon}
          buttonLabel={emptyButtonLabel}
          handleClick={onClickHandler}
          title={emptyDataTitle}
        >
          <Text
            classes="text-center w-4/5"
            translationKey={emptyDataDescription}
          />
        </EmptyData>
      </div>
    </div>
  );
}

CreateNewPage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  emptyDataTitle: PropTypes.string,
  emptyDataDescription: PropTypes.string,
  onClickHandler: PropTypes.func,
  emptyButtonIcon: PropTypes.string,
  emptyButtonLabel: PropTypes.string,
  outerSectionClasses: PropTypes.string,
  innerSectionClasses: PropTypes.string,
};
export default CreateNewPage;
