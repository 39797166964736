import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

export default function Expenses() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.cards.expenses.overView.absolutePath);
  }, []);
  return (
    <div>
      <span className="text-2xl font-semibold">Expenses</span>
    </div>
  );
}
