import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchVendors,
  resetVendorStore,
  setVendor,
  setVendors,
} from "@/store/reducers/vendors";

import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  employeePageFilterKeysSelector,
  hasMoreVendorsSelector,
  isFetchingVendorsSelector,
  vendorPageFilterKeysSelector,
  vendorsListSelector,
  vendorsTotalSelector,
} from "@/store/selectors/vendors";

import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Table from "@/components/core/Table";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import PaymentsTableLoader from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentsTableLoader";
import BillPayFilters from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/BillPayFilters";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import VendorTableLoader from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/VendorTableLoader";
import { convertFilters } from "@/utils/filters";
import { handleExport } from "@/utils/exports";
import { SORT_KEYS } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { debounce } from "@/utils/common";

import { VENDOR_CLASS } from "@/constants/common";
import { EXPORT_PAGE } from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { VENDOR_SCOPES } from "@/constants/vendors";

export default function VendorTable({
  openSliderHandler,
  context,
  headerSettings,
  defaultParams = {},
  enableFilter = true,
  itemPagination = PAGINATION_PER_REQUEST_LIMIT,
  stepPaginationEnable = false,
  containerClasses,
}) {
  const dispatch = useDispatch();

  const filterConfig = {
    [BILL_PAYROLL_CONTEXT.PAYROLL]: employeePageFilterKeysSelector,
    [BILL_PAYROLL_CONTEXT.BILLPAY]: vendorPageFilterKeysSelector,
  };
  const vendors = useSelector(vendorsListSelector);
  const totalVendors = useSelector(vendorsTotalSelector);
  const isFetching = useSelector(isFetchingVendorsSelector);
  const filters = useSelector(filterConfig[context]);
  const appliedFilters = useSelector(appliedFilterSelector);

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });
  const isEmpty = !vendors?.length;

  const [selectedVendors, setSelectedVendors] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  // search
  const [searchText, setSearchText] = useState("");
  const searchHandler = debounce((newSearchText) => {
    setSearchText(newSearchText);
  }, 500);

  // infinite scroll and pagination
  const hasMore = useSelector(hasMoreVendorsSelector);
  const filterOptions = { q: searchText, ...convertFilters(appliedFilters) };

  const onReset = () => {
    dispatch(resetVendorStore());
  };

  const loadMore = () => {
    if (stepPaginationEnable) dispatch(setVendors([]));
    dispatch(
      fetchVendors({
        page: pageNum,
        limit: itemPagination,
        context,
        vendor_class:
          context === BILL_PAYROLL_CONTEXT.PAYROLL
            ? VENDOR_CLASS.PAYROLL
            : VENDOR_CLASS.USER_CREATED,
        [VENDOR_SCOPES.NOT_ARCHIVED]: true,
        ...defaultParams,
        ...convertFilters(appliedFilters),
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...appliedFilters,
      ...sorting,
    },
  });

  const onScroll = () => {
    if (!stepPaginationEnable) {
      updatePageNum();
    }
  };

  const updatePageNumber = (by) => {
    setPageNum((prev) => prev + by);
  };

  const updatePageNum = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);
  const headerSelected = headerSelectAll; // all selected or else unchecked

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedVendors(vendors.map((vendor) => vendor.id));
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedVendors([]);
    }
  };

  const rowSelectionHandler = (vendor) => {
    if (selectedVendors?.includes(vendor?.id)) {
      setSelectedVendors((prev) => prev.filter((id) => id !== vendor?.id));
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, vendor?.id]);
      }
    } else {
      setSelectedVendors((prev) => [...prev, vendor?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== vendor?.id));
    }

    dispatch(
      setVendor({
        id: vendor.id,
        value: {
          ...vendor,
          selected: !vendor.selected,
        },
      })
    );
  };

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);

      if (!deSelectedIds.length) {
        setSelectedVendors(vendors?.map((data) => data.id));
      } else {
        const filteredIds = vendors
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedVendors(filteredIds);
      }
    }
  }, [bulkSelectAll, vendors, deSelectedIds]);

  useEffect(() => {
    // When user selects checkbox from header
    setHeaderSelectAll(
      (selectedVendors.length === pageNum * PAGINATION_PER_REQUEST_LIMIT ||
        selectedVendors.length === vendors?.length) &&
        !isEmpty
    );
  }, [selectedVendors, pageNum, vendors]);

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
  } =
    // context === BILL_PAYROLL_CONTEXT.PAYROLL
    //   ? EMPLOYEE_TABLE_DEFAULT_HEADERS
    //   : VENDOR_TABLE_DEFAULT_HEADERS;
    headerSettings;
  return (
    <>
      {enableFilter ? (
        <div className="w-full text-sm">
          <BillPayFilters
            filters={filters}
            page={EXPORT_PAGE.VENDOR}
            context={context}
          />
        </div>
      ) : null}

      <div className={containerClasses}>
        <Table
          {...tableSetting}
          bulkApproveVisible={selectedVendors?.length > 0}
          bulkApproveHeight="100px"
          bulkApproveContent={
            <BulkActionComponent
              selectedRows={
                deSelectedIds.length
                  ? totalVendors - deSelectedIds.length
                  : selectedVendors?.length
              }
              totalRows={totalVendors}
              selectedRowsText={
                context === BILL_PAYROLL_CONTEXT.PAYROLL
                  ? "common.multipleEmployeeSelected"
                  : "common.multipleVendorSelected"
              }
              selectedSingleRowText={
                context === BILL_PAYROLL_CONTEXT.PAYROLL
                  ? "common.singleEmployeeSelected"
                  : "common.singleVendorSelected"
              }
              descriptionText="common.exportDesc"
              bulkApproveSelectAll={
                (bulkSelectAll && !deSelectedIds.length) ||
                selectedVendors?.length === totalVendors
              }
              showSelectAllButton={headerSelectAll}
              selectAllHandler={(val) => {
                setDeSelectedIds([]);
                setBulkSelectAll(val);
              }}
              handleExport={() => {
                handleExport(
                  false,
                  selectedVendors?.length,
                  appliedFilters,
                  dispatch,
                  {
                    ...(bulkSelectAll
                      ? {
                          vendor_class:
                            context === BILL_PAYROLL_CONTEXT.PAYROLL
                              ? VENDOR_CLASS.PAYROLL
                              : VENDOR_CLASS.USER_CREATED,
                          deselected_ids: deSelectedIds,
                          not_archived: true,
                        }
                      : { selected_ids: selectedVendors }),
                    export_type: EXPORT_PAGE.VENDOR,
                  }
                );
              }}
              showExport
              clearSelection={() => {
                setBulkSelectAll(false);
                setHeaderSelectAll(false);
                setSelectedVendors([]);
                setDeSelectedIds([]);
                dispatch(
                  setVendor(
                    vendors.map((vendor) => {
                      return { ...vendor, selected: !vendor.selected };
                    })
                  )
                );
              }}
            />
          }
        >
          <tr className={tableHeadingRowClasses}>
            {tableHeading.map((headVal, index) => (
              <th className={headVal.classes} key={headVal.id}>
                <HeaderCell
                  val={{
                    ...headVal,
                    onCheckboxClick: headerSelectionHandler,
                    checkedValue: headerSelected && !isEmpty,
                    showCheckbox:
                      !stepPaginationEnable &&
                      index === 0 &&
                      !isFetching &&
                      !isEmpty,
                    sorting,
                    disabled: isEmpty,
                    setSorting,
                  }}
                />
              </th>
            ))}
          </tr>
          {isFetching && <VendorTableLoader count={itemPagination + 1} />}

          {(!isFetching || !isEmpty) &&
            vendors.map((val, index, arr) => (
              <tr
                key={val?.id}
                className={`${tableDataRowClasses}`}
                ref={(ref) => {
                  if (index === arr.length - 1 && hasMore) handleRefChange(ref);
                }}
                onClick={() => openSliderHandler(val?.id)}
              >
                {tableHeading.map(
                  ({ cellComponent: Component, classes, id }) => (
                    <td
                      className={`${classes} ${
                        selectedVendors?.includes(val)
                          ? "selected-row-cell"
                          : ""
                      }`}
                      key={id}
                    >
                      <Component
                        val={{
                          vendor: val,
                          id: val?.id,
                          context,
                          vendorOrEmployeeTable: true,
                          openSliderHandler,
                          showCheckbox: !stepPaginationEnable,
                          onCheckboxClick: () => rowSelectionHandler(val),
                          checkedValue: selectedVendors?.includes(val?.id),
                        }}
                        key={id}
                      />
                    </td>
                  )
                )}
              </tr>
            ))}
        </Table>

        {stepPaginationEnable && vendors?.length > 0 && (
          <StepPaginationButtons
            currentPage={pageNum}
            total={totalVendors}
            limit={itemPagination}
            handleLeftButton={() => updatePageNumber(-1)}
            handleRightButton={() => updatePageNumber(1)}
            leftDisabled={pageNum <= 1}
            rightDisabled={totalVendors / pageNum <= itemPagination}
          />
        )}
      </div>
    </>
  );
}

VendorTable.propTypes = {
  openSliderHandler: PropTypes.func,
  context: PropTypes.string,
  headerSettings: PropTypes.object,
  defaultParams: PropTypes.object,
  enableFilter: PropTypes.bool,
  itemPagination: PropTypes.number,
  stepPaginationEnable: PropTypes.bool,
  containerClasses: PropTypes.string,
};
