import PropTypes from "prop-types";
import { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";

import { getMonth, getYear } from "date-fns";

import { validateAndConvertToISO8601 } from "@/utils/common";

import { DATEINPUT_MODES } from "@/constants/date";

import Icon from "../Icon";
import Text from "../Text";

export default function SelectableDatePicker({
  mode = DATEINPUT_MODES.DATE,
  value,
  onChange,
  inline,
  monthsShown,
  dateFormat = "dd/MM/yyyy",
  content,
  disabled,
  error,
  extraProps = {},
  name = "dateInput",
  hasCustomHeader = true,
}) {
  const [errorString, setErrorString] = useState(error);
  useEffect(() => {
    setErrorString(error);
  }, [error]);
  const [rangeDates, setRangeDates] = useState(
    mode === DATEINPUT_MODES.DATE_RANGE ? [...value] : [null, null]
  ); // extra state needed to facilitate cancellation

  const focusRef = useRef(null);

  const [togglePicker, setTooglePicker] = useState(DATEINPUT_MODES.YEAR);
  const [openPopper, setOpenPopper] = useState(false);

  useEffect(() => {
    if (mode === DATEINPUT_MODES.DATE_RANGE && value?.length)
      setRangeDates(value.map((val) => new Date(val)));
  }, [value]);

  const onDatePickerChange = (dateOrDates) => {
    if (togglePicker === DATEINPUT_MODES.YEAR) {
      setTooglePicker(DATEINPUT_MODES.MONTH);
    } else if (togglePicker === DATEINPUT_MODES.MONTH) {
      setTooglePicker(DATEINPUT_MODES.DATE_RANGE);
      setOpenPopper(true);
    } else {
      setTooglePicker(DATEINPUT_MODES.YEAR);
      setOpenPopper(false);
    }
    if (mode !== DATEINPUT_MODES.DATE_RANGE)
      onChange({ target: { value: dateOrDates, name, type: "vpdate" } });
    else setRangeDates(dateOrDates);
  };

  const getSafeDate = (givenDateOrDates) => {
    const isoDateOrEmptyString = (val) =>
      val ? new Date(validateAndConvertToISO8601(val)) : "";

    if (Array.isArray(givenDateOrDates))
      return givenDateOrDates.map(isoDateOrEmptyString);
    return isoDateOrEmptyString(givenDateOrDates);
  };

  return (
    <div className="relative" id={inline ? "no-border" : ""}>
      <DatePicker
        onBlur={() => {
          setOpenPopper(false);
        }}
        shouldCloseOnSelect={openPopper}
        name={name}
        showMonthYearPicker={togglePicker === DATEINPUT_MODES.MONTH}
        showYearPicker={togglePicker === DATEINPUT_MODES.YEAR}
        onChange={onDatePickerChange}
        selected={getSafeDate(value)}
        monthsShown={monthsShown || 1}
        inline={inline}
        dateFormat={dateFormat}
        showPopperArrow={false}
        disabled={disabled}
        customInput={
          <CustomInputComponent
            value={getSafeDate(value)}
            content={content}
            ref={focusRef}
          />
        }
        allowSameDay
        renderCustomHeader={
          hasCustomHeader
            ? (dateProps) => (
                <CustomHeader
                  {...dateProps}
                  togglePicker={togglePicker}
                  setTooglePicker={setTooglePicker}
                />
              )
            : () => {}
        }
        {...extraProps}
      />
      {errorString && errorString.length !== 0 ? (
        <Text
          classes="absolute  -bottom-5 left-0 text-xs text-danger-600"
          translationKey={error}
        />
      ) : null}
    </div>
  );
}

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  togglePicker,
  setTooglePicker,
  increaseYear,
  decreaseYear,
  increaseMonth,
  decreaseMonth,
}) => {
  const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function changeMonthValue(e) {
    const choosedMonth = new Date(e);
    changeMonth(choosedMonth.getMonth());
  }

  function changeYearValue(e) {
    setTooglePicker(DATEINPUT_MODES.MONTH);
    changeYear(getYear(e));
  }

  return (
    <div className="flex flex-row justify-center m-2">
      {togglePicker === DATEINPUT_MODES.YEAR ? (
        <div className="flex flex-row items-center justify-between w-full">
          <div onClick={decreaseYear} className="cursor-pointer">
            <Icon name="LeftArrow" />
          </div>

          <DatePicker
            showYearPicker
            onChange={changeYearValue}
            showPopperArrow
            customInput={
              <Text
                translationKey="filter.selectYear"
                classes="text-neutral-900 text-base font-bold"
              />
            }
            selected={date}
          />

          <div onClick={increaseYear} className="cursor-pointer">
            <Icon name="RightArrow" />
          </div>
        </div>
      ) : null}

      {togglePicker === DATEINPUT_MODES.MONTH ? (
        <div className="flex flex-row items-center justify-between w-full">
          <div
            onClick={() => {
              changeYear(getYear(date) - 1);
            }}
            className="cursor-pointer"
          >
            <Icon name="LeftArrow" />
          </div>

          <DatePicker
            showMonthYearPicker
            selected={date}
            value={getMonth(date)}
            customInput={
              <Text
                translationKey={getYear(date)}
                classes="text-neutral-900 text-base font-bold"
              />
            }
            onChange={changeMonthValue}
          />

          <div
            onClick={() => {
              changeYear(getYear(date) + 1);
            }}
            className="cursor-pointer"
          >
            <Icon name="RightArrow" />
          </div>
        </div>
      ) : null}

      {togglePicker === DATEINPUT_MODES.DATE_RANGE ? (
        <div className="flex flex-row items-center justify-between w-full">
          <div
            onClick={() => {
              decreaseMonth();
            }}
            className="cursor-pointer"
          >
            <Icon name="LeftArrow" />
          </div>
          <Text
            translationKey={`${getYear(date)} ${monthsArray?.[getMonth(date)]}`}
            classes="text-neutral-900 text-base font-bold"
          />
          <div
            onClick={() => {
              increaseMonth();
            }}
            className="cursor-pointer"
          >
            <Icon name="RightArrow" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

const CustomInputComponent = forwardRef(
  ({ value, onClick = () => {}, content = null }, ref) => (
    <button
      type="button"
      onClick={(e) => {
        onClick(e);

        e.preventDefault();
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      ref={ref}
      className="w-full text-left react-datepicker-vp-custom-content"
    >
      {content || value}
    </button>
  )
);

CustomHeader.propTypes = {
  date: PropTypes.string,
  changeYear: PropTypes.func,
  changeMonth: PropTypes.func,
  togglePicker: PropTypes.string,
  setTooglePicker: PropTypes.func,
  increaseYear: PropTypes.func,
  decreaseYear: PropTypes.func,
};

SelectableDatePicker.propTypes = {
  mode: PropTypes.oneOf(Object.values(DATEINPUT_MODES)),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Date)), // range mode
    PropTypes.instanceOf(Date),
    PropTypes.any, // avoid warning, when no date should be selected by default
  ]),
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  monthsShown: PropTypes.number,
  dateFormat: PropTypes.string,
  content: PropTypes.any,
  extraProps: PropTypes.object,
  name: PropTypes.string,
  error: PropTypes.string,
  customComponentClass: PropTypes.string,
  onCancelDateRange: PropTypes.func,
  hasCustomHeader: PropTypes.bool,
};

CustomInputComponent.propTypes = {
  value: PropTypes.any, // actually same SelectedDateInput.propTypes.values, but the libary type export has some problem
  onClick: PropTypes.func,
  content: PropTypes.any,
};
