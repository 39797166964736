import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  BUDGETS_DEPT_TABS,
  BUDGETS_PROJECT_TABS,
  BUDGETS_TABS,
  BUDGET_SLIDER_SEARCHPARAM_VALUES,
} from "@/constants/company";
import {
  USER_ALLOWED_CTA_KEYS,
  USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS,
} from "@/constants/user";

function BudgetPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  const allNavUrls = useSelector(allNavUrlsSelector);
  const condition = [
    USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_DEPARTMENTS,
    USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_PROJECTS,
  ];

  const budgetPermission = allNavUrls?.filter((data) =>
    condition?.includes(data?.key)
  );

  const [currentUserAllowedCtaKey, setCurrentUserAllowedCtaKey] = useState(
    USER_ALLOWED_CTA_KEYS.DEFINE_NEW_DEPT_BUDGET
  );

  const departmentBudgetPermission = budgetPermission?.some(
    (d) =>
      d.key ===
      USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_DEPARTMENTS
  );

  const projectBudgetPermission = budgetPermission?.some(
    (d) =>
      d.key ===
      USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_PROJECTS
  );

  const showBothTabs =
    allNavUrls?.filter((data) => condition?.includes(data?.key))?.length === 2;

  const [tabs, setTabs] = useState(BUDGETS_TABS);
  const [selectedTab, setSelectedTab] = useState(BUDGETS_TABS[0].key);

  useEffect(() => {
    if (showBothTabs) return;

    if (departmentBudgetPermission) {
      setTabs(BUDGETS_DEPT_TABS);
      return;
    }
    if (projectBudgetPermission) {
      setTabs(BUDGETS_PROJECT_TABS);
      setSelectedTab(BUDGETS_PROJECT_TABS[0].key);
    }
  }, [
    departmentBudgetPermission,
    projectBudgetPermission,
    showBothTabs,
    selectedTab,
  ]);

  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <div className="flex items-center justify-between mb-6">
        <div className="flex flex-col">
          <Text classes="text-neutral-500" translationKey="company.title" />
          <Text
            classes="text-3xl font-bold text-neutral-800"
            translationKey="company.budgets.title"
          />
        </div>
        {checkIfUserActionAllowed(
          allNavUrls,
          userAllowedActions,
          currentUserAllowedCtaKey
        ) ? (
          <Button
            variant="primary"
            label="company.budgets.defineNewBudget"
            classes="w-[212px]"
            onClick={() => {
              searchParams.append(
                SLIDERS_SEARCH_PARAMS.company.budget.create,
                BUDGET_SLIDER_SEARCHPARAM_VALUES.MAIN
              );
              setSearchParams(searchParams);
            }}
          />
        ) : null}
      </div>
      <Tabs
        items={tabs}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => {
          setSelectedTab(tab);
          if (tab.key === 1) {
            setCurrentUserAllowedCtaKey(
              USER_ALLOWED_CTA_KEYS.DEFINE_NEW_DEPT_BUDGET
            );
          } else {
            setCurrentUserAllowedCtaKey(
              USER_ALLOWED_CTA_KEYS.DEFINE_NEW_PROJECT_BUDGET
            );
          }
        }}
      />
    </div>
  );
}

export default BudgetPage;
