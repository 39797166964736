import Tracker from "@openreplay/tracker";
import { subdomain } from "@/utils/common";

const _uid = localStorage.getItem("uid");
function SessionTrackerHandler() {
  if (!(_uid === undefined || _uid === null || _uid === "")) {
    const tracker = new Tracker({
      projectKey: import.meta.env.VITE_TRACKER_PROJECT_API_KEY,
      __DISABLE_SECURE_MODE: import.meta.env.VITE_MODE === "development",
      ingestPoint: "https://openreplay.volopay.co/ingest",
      // network: {
      //   failuresOnly: false,
      //   sessionTokenHeader: "Authorization",
      //   ignoreHeaders: ["Authorization", "Cookie", "access-token"],
      //   capturePayload: true,
      //   sanitizer: (data) => {
      //     const fieldsToMask = [
      //       "mobileNumber",
      //       "dob",
      //       "providerCardId",
      //       "providerId",
      //       "number",
      //       "cvv",
      //       "expiryYear",
      //       "cardExpiryYear",
      //       "cardExpiryMonth",
      //       "expiryMonth",
      //       "result",
      //       "token",
      //       "cardNumber",
      //       "accountNumber",
      //       "accountRoutingType1",
      //       "bankCountryCode",
      //       "bankName",
      //       "swiftCode",
      //       "ifscCode",
      //       "accountRoutingType2",
      //       "bsbCode",
      //       "bankAccountHolderName",
      //       "bankName",
      //       "bankAccount",
      //       "identificationNumber",
      //       "identificationType",
      //       "iban",
      //       "bicSwift",
      //       "cnaps",
      //       "sortCode",
      //       "clabe",
      //     ];

      //     const maskValue = (value) => {
      //       if (typeof value === typeof "") {
      //         return value.replace(/./g, "*");
      //       }
      //       return value;
      //     };

      //     const sanitizeObject = (obj) => {
      //       Object.keys(obj).map((key) => {
      //         if (typeof obj[key] === typeof {} && obj[key] !== null) {
      //           sanitizeObject(obj[key]);
      //         } else if (fieldsToMask.includes(key)) {
      //           obj[key] = maskValue(obj[key]);
      //         }
      //       });
      //     };
      //     const sanitinsingDataInRequestAndResponse = (sanitizedData, type) => {
      //       if (sanitizedData[type]) {
      //         const { body } = sanitizedData?.[type];
      //         if (body && typeof body === typeof "") {
      //           try {
      //             const parsedBody = JSON.parse(body);
      //             sanitizeObject(parsedBody);
      //             sanitizedData[type].body = JSON.stringify(parsedBody);
      //           } catch (e) {
      //             // If parsing fails, proceed without modification
      //           }
      //         }
      //       }
      //     };
      //     const sanitizedData = { ...data };
      //     sanitinsingDataInRequestAndResponse(sanitizedData, "request");
      //     sanitinsingDataInRequestAndResponse(sanitizedData, "response");

      //     return sanitizedData;
      //   },
      //   axiosInstances: [$axios],
      // },
    });
    tracker.start();
    tracker.setUserID(_uid);
    tracker.setMetadata("account", subdomain());
    tracker.setMetadata(
      "country",
      window.localStorage.getItem("api_subdomain")?.split("-")[1] || "sg"
    );
  }

  return null;
}

export default SessionTrackerHandler;
