import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { accountDetailSelector } from "@/store/selectors/client";

import Text from "@/components/core/Text";

import CardDetail from "@/components/Cards/CardSlider/OverviewTab/CardDetail";
import { capitalizeFirstLetter } from "@/utils/common";

import { ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP } from "@/constants/company";

export default function AccountDetailsSection() {
  const accountDetails = useSelector(accountDetailSelector);
  const [bankDetails, setBankDetails] = useState([]);

  useEffect(() => {
    const accountBank = [];

    [
      "institutionName",
      "accountName",
      "accountHolderName",
      "accountNumber",
      "swiftCode",
      "bankName",
      "address",
      "accountRoutingValue",
      "currency",
      "transactionReference",
      "bankStreetAddress",
      "ifscCode",
      "bsbCode",
      "achCode",
      "fedwire",
      "bankCode",
      "bankBranchCode",
    ].forEach((key) => {
      accountBank.push({
        label: key,
        value: accountDetails?.bankAccount[key] ?? null,
      });
    });

    if (accountDetails?.bankAccount?.routingCodes?.length)
      accountDetails?.bankAccount?.routingCodes?.map((code) => {
        return accountBank.push({
          label: code?.type,
          value: code?.value,
        });
      });

    const filteredBankAccountDetails = accountBank?.filter(
      (data) => data?.value && ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP[data?.label]
    );
    setBankDetails(filteredBankAccountDetails);
  }, [accountDetails]);

  return (
    <div className="mt-8">
      <Text
        translationKey="dashboard.myAccounts.accountDetails"
        classes="text-lg font-semibold text-neutral-800"
      />
      <div className="grid grid-cols-2 gap-5 mt-6">
        {bankDetails.map(({ label, value }) => {
          return (
            <div key={label}>
              <CardDetail
                copy
                id={label}
                nameKey={ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP[label]}
                value={value}
                noLink
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
