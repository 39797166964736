import Badge from "@/components/core/Badge";

export default function FlexibleBudgetBadge() {
  return (
    <Badge
      translationKey="company.budgets.flexible"
      classes="bg-neutral-50 border border-neutral-200 px-2 py-[2px] "
      textClasses="text-xs font-bold text-neutral-600"
    />
  );
}
