import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { MERCHANT_CATEGORY_ICONS } from "@/constants/Cards";

function ControlView({ controlArray, controlType, isPreview, deleteControl }) {
  return (
    <div className="w-full px-5 bg-white rounded-xl">
      {controlArray?.map((item) => (
        <div key={item?.label} className="flex items-center justify-between">
          <div className="flex items-center gap-5 py-2">
            <Icon
              className={MERCHANT_CATEGORY_ICONS[controlType]?.color}
              name={MERCHANT_CATEGORY_ICONS[controlType]?.icon}
            />
            <Text
              classes="text-sm font-semibold"
              translationKey={item?.label}
            />
          </div>
          {!isPreview ? (
            <Icon
              name="Delete"
              handleClick={() => {
                deleteControl(item?.id);
              }}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
}
ControlView.propTypes = {
  controlArray: PropTypes.array,
  controlType: PropTypes.string,
  isPreview: PropTypes.bool,
  deleteControl: PropTypes.func,
};
export default ControlView;
