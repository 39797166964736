import { useState } from "react";
import { useSelector } from "react-redux";

import { expenseMultiLayerReviewEnabledSelector } from "@/store/selectors/client";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import { NEEDS_REVIEW_TABS } from "@/constants/expense";

export default function ExpensesNeedsReview() {
  const [selectedTab, setSelectedTab] = useState(NEEDS_REVIEW_TABS[0]?.key);

  const multipleExpenseReviewLayersSupported = useSelector(
    expenseMultiLayerReviewEnabledSelector
  );
  const showNeedsReviewPendingTab = multipleExpenseReviewLayersSupported;
  const tabs = showNeedsReviewPendingTab
    ? NEEDS_REVIEW_TABS
    : NEEDS_REVIEW_TABS.filter((_, idx) => idx !== 1);

  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <div>
        <Text
          translationKey="cards.title.plural"
          classes="text-neutral-500 text-sm font-semibold"
        />
        /
        <Text
          translationKey="expenses.title"
          classes="text-neutral-500 text-sm font-semibold"
        />
        <Text
          translationKey="expenses.needsReview.title"
          classes="text-neutral-800 text-3xl font-bold block"
        />
      </div>
      <div className="mt-6">
        <Tabs
          items={tabs}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
        />
      </div>
    </div>
  );
}
