import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPendingTabCount } from "@/store/reducers/accounting";

import {
  isFetchingPendingCountSelector,
  pendingTabCountSelector,
} from "@/store/selectors/accounting";
import { isFetchingTransactionsSelector } from "@/store/selectors/accounting_transactions";
import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";

import Tabs from "@/components/core/Tabs";

import OthersPageHelper from "@/components/Accounting/Transactions/Others/PageHelper";
import AccountingHeader from "@/components/Accounting/Transactions/common/AccountingHeader";
import { convertFilters } from "@/utils/filters";
import { accountingSelectedTabs } from "@/utils/common";

import {
  ACCOUNTING_SYNC_ATTEMPT_ENTITY,
  ACCOUNTING_TAB_TYPES,
  ACCOUNTING_TRANSACTION_TABS,
} from "@/constants/accounting";
import { PENDING_COUNT_TRANSACTIONS } from "@/constants/company";

export default function ATOthers() {
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = !!accountingSoftware;
  const dispatch = useDispatch();
  const isFetchingPendingCount = useSelector(isFetchingPendingCountSelector);
  const pendingTabCountObj = useSelector(pendingTabCountSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const isFetchingTransactions = useSelector(isFetchingTransactionsSelector);
  const selectedTabs = useMemo(
    () =>
      accountingSelectedTabs(
        accountingSoftware,
        ACCOUNTING_TAB_TYPES.OTHERS,
        isFetchingPendingCount ? 0 : pendingTabCountObj?.pendingCounts
      ),
    [isFetchingPendingCount]
  );
  const [selectedTab, setSelectedTab] = useState();
  useEffect(() => {
    dispatch(
      fetchPendingTabCount({
        transaction: PENDING_COUNT_TRANSACTIONS.OTHERS,
        ...convertFilters(appliedFilters),
      })
    );
  }, []);
  return (
    <div className="flex flex-col ml-10 mr-11 -mb-9">
      <AccountingHeader
        sectionTitleText="accounting.transactions.others.title"
        entity={ACCOUNTING_SYNC_ATTEMPT_ENTITY.OTHER}
      />
      {accountingEnabled ? (
        <Tabs
          items={selectedTabs}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
          conditionalTabChange={isFetchingTransactions}
        />
      ) : (
        <OthersPageHelper tab={selectedTab?.key} />
      )}
    </div>
  );
}
