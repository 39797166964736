import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { updateTagAndVendor } from "@/store/reducers/accounting_transactions";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  accountingIntegrationSoftwareSelector,
  clientSelector,
  currentPaymentProviderSelector,
} from "@/store/selectors/client";

import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import AccountingTransactionLineItemsTable from "@/components/Accounting/Transactions/common/AccountingTransactionLineItemsTable";
import { BILL_PAYROLL_CONTEXT } from "@/components/common/BillPayAndPayroll";
import InvoiceDetails from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/InvoiceDetails";
import PaymentTotalBreakdown from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentTotalBreakdown";
import TransactionDetails from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/TransactionDetails";
import WalletDetails from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/WalletDetails";

import { INTEGRATION_TAGS_TYPE } from "@/utils/constants/integrations";
import { PAYMENT_MODES } from "@/utils/constants/reimbursement";
import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import {
  ACCOUNTING_PAYMENTS_TAG_TYPES,
  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
} from "@/constants/accounting";
import { PAYMENT_PROVIDERS } from "@/constants/provider";

import SliderAccountingSection from "../../common/Slider/SliderAccountingSection";
import { NewVendorChip } from "../../common/util";

export default function BillPayTransactionOverviewTab({
  payment,
  context = BILL_PAYROLL_CONTEXT.BILLPAY,
}) {
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const vendors = useSelector(accountingVendorsSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );

  const transactionLevelTags =
    useSelector(clientSelector)?.transactionLevelTags ?? [];

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const accountingId = parseInt(
    searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.billPay.id),
    10
  );

  const newVendor = {
    id: -1,
    name: payment?.vendor?.name,
    alias: payment?.vendor?.name,
  };

  const updatedVendorOptions = [newVendor, ...vendors];

  const handleUpdateTagAndVendor = ({ params, lineItemId, type = "tags" }) => {
    let payload = {
      accounting_id: accountingId,
      transaction_type: inPayrollContext
        ? ACCOUNTING_TRANSACTION_PAGES.PAYROLL
        : ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL,
    };
    payload =
      type === INTEGRATION_TAGS_TYPE.VENDORS
        ? {
            ...payload,
            accounting_payee_id: params?.id === -1 ? null : params?.id,
          }
        : {
            ...payload,
            tag_id: params?.tagId,
            tag_value_id: params?.id,
            transaction_type: ACCOUNTING_PAYMENTS_TAG_TYPES.LINE_ITEM,
            accountable_type: inPayrollContext
              ? ACCOUNTING_TRANSACTION_PAGES.PAYROLL
              : ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL,
            line_item_id: lineItemId,
          };

    dispatch(updateTagAndVendor({ payload }));
  };

  const editableState = [
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
    ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED,
  ]?.includes(payment?.accountingStatus);

  useEffect(() => {
    if (!payment) return;

    if (!editableState) {
      searchParams.append(
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled,
        false
      );
    } else {
      searchParams.delete(SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled);
    }

    setSearchParams(searchParams);
  }, [payment, editableState]);

  const isPayOutsideVolopay =
    payment?.paymentMode === PAYMENT_MODES.payOutsideVolopay;

  const currentProvider = useSelector(currentPaymentProviderSelector);
  const senderCurrency = payment?.quote?.fromCurrency;
  const beneficiaryCurrency = payment?.quote?.toCurrency;
  const isYSTRPotentiallyApplicable =
    senderCurrency !== beneficiaryCurrency &&
    [PAYMENT_PROVIDERS.WALLEX, PAYMENT_PROVIDERS.AIRWALLEX].includes(
      currentProvider
    );
  const isYSTRApplicable =
    isYSTRPotentiallyApplicable && payment?.lineItems?.length < 2;

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-3">
        <Text
          classes="text-neutral-800 font-semibold text-lg"
          translationKey={
            inPayrollContext
              ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.purpose.title"
              : "company.billing.slider.invoiceDetails.title"
          }
        />
        <div className="flex flex-col gap-4">
          <div className="mt-5">
            <InvoiceDetails payment={payment} context={context} />
          </div>
          {transactionLevelTags.length > 0 ? (
            <div className="mt-5">
              <SliderAccountingSection
                showSplit={false}
                inputs={{}}
                transaction={payment}
                page={
                  inPayrollContext
                    ? ACCOUNTING_TRANSACTION_PAGES.PAYROLL
                    : ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL
                }
              />
            </div>
          ) : null}
          <AccountingTransactionLineItemsTable
            payment={payment}
            editableState={editableState}
            context={context}
          />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        {/* This is UI from below line items table TO Payment details heading */}
        <PaymentTotalBreakdown
          payment={payment}
          context={context}
          isYSTRApplicable={isYSTRPotentiallyApplicable}
        />
        <div className="flex flex-col gap-3 mt-3">
          <Text
            translationKey={
              inPayrollContext
                ? "payroll.employeeDetails.heading"
                : "billPay.bill.payments.vendorDetails.heading"
            }
            classes="text-lg font-semibold text-neutral-800"
          />
          <div className="py-2 my-2">
            <VpSelect
              label="billPay.vendors.createVendor.vendorNameInAccountingSoftware"
              labelTranslationProp={{
                accountingSoftware:
                  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                    accountingIntegrationSoftware
                  ],
              }}
              placeholder="Select"
              options={updatedVendorOptions}
              optionsDisplayKey="alias"
              disabled={!editableState}
              value={
                payment?.accountingPayeeId
                  ? payment?.accountingVendorName
                    ? payment?.accountingPayeeId
                    : null
                  : -1
              }
              valueKey="id"
              labelStyleClasses="text-xs"
              handleChange={(params) => {
                handleUpdateTagAndVendor({
                  params,
                  type: INTEGRATION_TAGS_TYPE.VENDORS,
                });
              }}
              customUIForOptionWhenDropdownClosed={
                !payment?.accountingPayeeId?.id
                  ? (value, text) => (
                      <div className="flex items-center justify-between">
                        <Text translationKey={text?.label} />
                        <NewVendorChip
                          text={text ?? ""}
                          chipClasses="bg-success-50 text-success-500 border border-success-200 w-fit"
                        />
                      </div>
                    )
                  : null
              }
              menuPosition="absolute"
              classes="text-neutral-500"
            />
          </div>
        </div>
        <WalletDetails
          payment={payment}
          isPayOutsideVolopay={isPayOutsideVolopay}
          senderCurrency={senderCurrency}
          context={context}
          hideLinkedTo
        />
        <TransactionDetails payment={payment} context={context} />
      </div>
    </div>
  );
}

BillPayTransactionOverviewTab.propTypes = {
  payment: PropTypes.object,
};
