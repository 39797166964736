import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function PageNotFound() {
  return (
    <div className="flex flex-row items-center justify-center mt-20">
      <Icon name="PageNotFound" className="w-28 h-28" />

      <div className="flex flex-col justify-center ml-14">
        <Text
          classes="text-4xl text-primary-500 font-bold"
          translationKey="404"
        />
        <Text
          classes="text-2xl font-semibold mt-5"
          translationKey="pageNotFound.title"
        />
        <Text translationKey="pageNotFound.desc" />
      </div>
    </div>
  );
}
