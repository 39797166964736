import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { fetchCountries } from "@/store/reducers/app";
// reducers
import {
  fetchOnboardingSectionApi,
  fetchOnboardingStepApi, // getVkycLink,
  sendOtp,
  setAddressDetailsInprogress, // setCurrentOnboardingSection, // setCurrentOnboardingStep,
  setIdentityDetailsInprogress,
  setOnboardingSection,
  setPersonalDetailsInprogress,
  setVkycVerified,
  toggleConfirmReviewModal,
  toggleModal,
  updateVkycVerified,
  verifyOtp,
} from "@/store/reducers/onboarding";
import {
  fetchBannerRetryCtaRedirectionLink,
  setCKycUpdateStatus,
  updateAddressDetails,
  updateIdentityDetails,
  updateUserDetails,
} from "@/store/reducers/user";

import { countriesSelector } from "@/store/selectors/app";
import { primaryCardProviderSelector } from "@/store/selectors/client";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
// selectors
import {
  currentOnboardingSectionSelector,
  currentOnboardingStepSelector,
  isFetchingOnboardingSectionSelector,
  isFetchingOnboardingStepsSelector,
  isFetchingOtpSelector,
  isOtpVerifiedSelector,
  isVkycVerifiedSelector,
  onboardingSectionSelector,
  otpSentCountSelector,
  selectedSectionSelector,
  vKycLinkInprogressSelector,
  vKycLinkSelector, // vkycStateSelector,
} from "@/store/selectors/onboarding";
import {
  bannerRetryCtaRedirectionLinkSelector,
  inProgressAddressDetailsSelector,
  inProgressIdentityDetailsSelector,
  inProgressPersonalDetailsSelector,
  isFetchingUserKycInfoSelector,
  kycUpdateStatusSelector,
  userIdSelector,
  userKycStatusInfoSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import CircleLoader from "@/components/core/CircleLoader";
import EmptyData from "@/components/core/EmptyData";
// core components
import Text from "@/components/core/Text";

import { generatePayloadFromFormValue } from "@/components/GenericForm/common";
import OnboardingModal from "@/components/Onboarding/Modals";
import ConfirmReviewDetailsModal from "@/components/Onboarding/Modals/ConfirmReviewDetailsModal";
import OnboardingCurrentStepPagesOverview from "@/components/Onboarding/OnboardingCurrentStepPagesOverview";
import OnboardingForm from "@/components/Onboarding/OnboardingForm";
import LivquikIdentityDetails from "@/components/Onboarding/OnboardingPageHelper/Livquik/LivquikAddressDetails";
import OnboardingFooter from "@/components/Onboarding/OnboardingPageHelper/OnboardingFooter";
import TncComponent from "@/components/Onboarding/OnboardingPageHelper/TncComponent";
import OnboardingWelcomePage from "@/components/Onboarding/OnboardingWelcomePage";
import PineLabsIdentityDetailsForm from "@/components/Onboarding/PineLabs";
import ReviewDetails from "@/components/Onboarding/ReviewDetails";
import vToast from "@/utils/vToast";
import {
  getDialCodeAndNumberFromPhoneNumber,
  getRedirectionLinkForKycStatusApproved,
} from "@/utils/common";

import { CARD_PROVIDER } from "@/constants/Cards";
// utils, constant file imports
import {
  KYC_STEPS_STATUS,
  MOBILE_VERIFICATION_STEP_PAGES,
  ONBOARDING_SLIDER_PARAMS,
  ONBOARDING_STEPS,
} from "@/constants/onboarding";

function OnboardingPageHelper() {
  const dispatch = useDispatch();
  const allNavUrls = useSelector(allNavUrlsSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [outerFooterDisableState, setOuterFooterDisableState] = useState(false);

  const isFetchingOnboardingSection = useSelector(
    isFetchingOnboardingSectionSelector
  );

  const [stackOfSteps, setStackOfStep] = useState([]);

  const otpSentStatus = useSelector(isFetchingOtpSelector);

  const otpSentCount = useSelector(otpSentCountSelector);

  const selectedSection = useSelector(selectedSectionSelector);

  const onboardingSection = useSelector(onboardingSectionSelector);

  // const allSteps = useSelector(stepsSelector);

  const currentOnboardingStep = useSelector(currentOnboardingStepSelector);

  const currentOnboardingSection = useSelector(
    currentOnboardingSectionSelector
  );

  const showOnboardingForm = !(
    [
      KYC_STEPS_STATUS.IN_PROGRESS,
      KYC_STEPS_STATUS.APPROVED,
      KYC_STEPS_STATUS.REJECTED,
    ].indexOf(currentOnboardingStep) >= 0
  );

  const isNonGenericForm = onboardingSection?.isNonGeneric;

  const onboardingStepParamValue = searchParams.get(ONBOARDING_SLIDER_PARAMS);

  const isOtpVerified = useSelector(isOtpVerifiedSelector);

  const isVkycVerfied = useSelector(isVkycVerifiedSelector);

  const userId = useSelector(userIdSelector);

  const showOnboardingFooter =
    currentOnboardingSection !== ONBOARDING_STEPS.WELCOME;

  const inProgressPersonalDetails = useSelector(
    inProgressPersonalDetailsSelector
  );

  const rejectedRetryLink = useSelector(bannerRetryCtaRedirectionLinkSelector);

  const navigate = useNavigate();

  const isFetchingOnboardingSteps = useSelector(
    isFetchingOnboardingStepsSelector
  );

  const inProgressAddressDetails = useSelector(
    inProgressAddressDetailsSelector
  );

  const inProgressIdentityDetails = useSelector(
    inProgressIdentityDetailsSelector
  );

  const vKycInprogress = useSelector(vKycLinkInprogressSelector);

  const vKycLink = useSelector(vKycLinkSelector);

  const countryOptions = useSelector(countriesSelector);

  const hasTncCheckboxSection = onboardingSection?.[0]?.tnc;

  const cardProviderInfo = useSelector(primaryCardProviderSelector);

  const currentCardProvider = cardProviderInfo?.slug;

  const currentUser = useSelector(userSelector);

  const kycState = currentUser?.kycState;

  const userCkycState = useSelector(userKycStatusInfoSelector)?.kycState;

  const isFetchingUserKycInfo = useSelector(isFetchingUserKycInfoSelector);

  const callOnboardingSectionApi =
    (currentOnboardingSection === ONBOARDING_STEPS.PERSONAL_DETAILS &&
      countryOptions?.length > 0) ||
    ![
      ONBOARDING_STEPS.PERSONAL_DETAILS,
      KYC_STEPS_STATUS.APPROVED,
      KYC_STEPS_STATUS.IN_PROGRESS,
      KYC_STEPS_STATUS.FAILED,
      KYC_STEPS_STATUS.REJECTED,
    ].includes(currentOnboardingSection);

  const kycUpdateStatus = useSelector(kycUpdateStatusSelector);

  const [isTncChecked, setIsTncChecked] = useState(
    hasTncCheckboxSection ? false : true
  );

  const isButtonDisabled = checkButtonDisabled();
  const showLoader =
    inProgressPersonalDetails ||
    inProgressAddressDetails ||
    inProgressIdentityDetails ||
    vKycInprogress ||
    isFetchingOnboardingSection ||
    isFetchingUserKycInfo;

  const currentIndex = useMemo(() => {
    return stackOfSteps.indexOf(currentOnboardingSection);
  }, [stackOfSteps, currentOnboardingSection]);

  const hasSectionsForCurrentStep =
    stackOfSteps.length > 0 &&
    (stackOfSteps.length === 1 ||
      (stackOfSteps.length > 1 &&
        currentOnboardingStep !== stackOfSteps[currentIndex]));

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  useEffect(() => {
    if (selectedSection) {
      setStackOfStep(selectedSection?.map((item) => item.key));
    }
  }, [selectedSection]);

  useEffect(() => {
    if (vKycLink) {
      window.open(vKycLink, "_blank");
    }
  }, [vKycLink]);

  useEffect(() => {
    if (
      [currentOnboardingStep, kycState, userCkycState]?.includes(
        KYC_STEPS_STATUS.APPROVED
      )
    ) {
      getToDashboard();
    } else if (currentOnboardingStep === KYC_STEPS_STATUS.REJECTED) {
      dispatch(fetchBannerRetryCtaRedirectionLink());
    }
  }, [currentOnboardingStep, userCkycState]);

  useEffect(() => {
    if (
      (hasSectionsForCurrentStep && !isFetchingOnboardingSteps) ||
      currentOnboardingSection === ONBOARDING_STEPS.WELCOME
    ) {
      const params = getParamsForFetchingAccountingSection();
      if (callOnboardingSectionApi) {
        dispatch(fetchOnboardingSectionApi(params));
      }
    }
  }, [
    stackOfSteps,
    currentOnboardingSection,
    isFetchingOnboardingSteps,
    countryOptions,
  ]);

  useEffect(() => {
    if (!otpSentStatus && otpSentCount) {
      getOtpField();
    }
  }, [otpSentStatus]);

  useEffect(() => {
    if (
      isOtpVerified ||
      inProgressPersonalDetails === false ||
      inProgressAddressDetails === false ||
      inProgressIdentityDetails === false ||
      isVkycVerfied === false ||
      kycUpdateStatus === false
    ) {
      if (
        onboardingSection?.subPage ===
        MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_EDIT
      ) {
        getOtpField();
      }
      dispatch(setPersonalDetailsInprogress(undefined));
      dispatch(setAddressDetailsInprogress(undefined));
      dispatch(setIdentityDetailsInprogress(undefined));
      dispatch(setVkycVerified(undefined));
      dispatch(setCKycUpdateStatus(undefined));
    }
  }, [
    isOtpVerified,
    inProgressPersonalDetails,
    inProgressAddressDetails,
    inProgressIdentityDetails,
    isVkycVerfied,
  ]);

  function getOtpField() {
    dispatch(
      fetchOnboardingSectionApi({
        step: ONBOARDING_STEPS.MOBILE_VERIFICATION,
        subPage: MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_OTP,
        otpSentCount,
        handleSendOtpButton,
      })
    );
  }

  function getParamsForFetchingAccountingSection() {
    let params = {};

    switch (currentOnboardingSection) {
      case ONBOARDING_STEPS.MOBILE_VERIFICATION:
        params = {
          step: currentOnboardingSection,
          subPage:
            MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_PHONE_NUMBER,
          id: userId,
        };
        break;

      case ONBOARDING_STEPS.VIDEO_KYC_PROCESS:
        params = {
          step: currentOnboardingSection,
          handleActionButtonClick: toggleOnboardingModal,
        };
        break;

      case ONBOARDING_STEPS.PERSONAL_DETAILS:
        params = {
          step: currentOnboardingSection,
          countryOptions,
        };
        break;

      case ONBOARDING_STEPS.ADDRESS_DETAILS:
      case ONBOARDING_STEPS.IDENTITY_DETAILS:
        params = {
          step: currentOnboardingSection,
        };
        break;

      default:
        params = currentOnboardingSection;
        break;
    }
    return params;
  }

  function toggleOnboardingModal() {
    dispatch(toggleModal());
  }

  function moveNextPageOfCurrentStep() {
    dispatch(fetchOnboardingStepApi());
  }

  const onSuccess = () => {
    moveNextPageOfCurrentStep();
  };

  const handleMobileVerificationPagesOnSubmit = (values) => {
    const mobileVerificationSubPage = onboardingSection?.subPage;

    if (
      mobileVerificationSubPage ===
      MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_PHONE_NUMBER
    ) {
      handleSendOtpButton(onboardingSection?.subPage, values);
    } else if (
      mobileVerificationSubPage ===
      MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_OTP
    ) {
      if (!isOtpVerified) {
        const payload = { otp: values?.otp?.value };
        dispatch(verifyOtp({ onSuccess, param: { payload, id: userId } }));
      } else {
        moveNextPageOfCurrentStep();
      }
    }
  };

  function handleSendOtpButton(subPage, fieldValues) {
    if (subPage === MOBILE_VERIFICATION_STEP_PAGES.MOBILE_VERIFICATION_EDIT) {
      const { dialCode, number } = getDialCodeAndNumberFromPhoneNumber(
        fieldValues?.mobile_number.value
      );

      const payload = {
        mobile_number: Number(number),
        mobile_country_code: dialCode,
      };
      dispatch(updateUserDetails({ onSuccess, payload, id: userId }));
    } else {
      dispatch(sendOtp(userId));
    }
  }

  function handlePersonalDetailsFormSubmit(fieldValues) {
    const payload = { ...fieldValues };

    const phoneInfo = getDialCodeAndNumberFromPhoneNumber(
      fieldValues?.mobile_number
    );

    payload.mobile_number = phoneInfo?.number
      ? Number(phoneInfo?.number)
      : undefined;

    payload.mobile_country_code = phoneInfo?.dialCode;
    const formData = new FormData();

    const payloadKeys = Object.keys(payload);
    const payloadValues = Object.values(payload);
    payloadKeys.map((key, index) => {
      if (payloadValues[index]) {
        if (
          (key === "avatar" && payloadValues[index] instanceof File) ||
          key !== "avatar"
        ) {
          formData.append(key, payloadValues[index]);
        }
      }
    });

    dispatch(updateUserDetails({ onSuccess, payload: formData, id: userId }));
  }

  function handleAddressDetailsFormSubmit(fieldValues) {
    dispatch(updateAddressDetails({ onSuccess, ...fieldValues }));
  }

  function handleIdentityDetailsFormSubmit(fieldValues) {
    const fieldsObjectValues = Object.values(fieldValues);
    const fieldsObjectKeys = Object.keys(fieldValues);

    const payload = {
      id_proofs_attributes: [],
    };

    fieldsObjectValues?.forEach((fieldValue, index) => {
      if (fieldValue.id_number) {
        payload.id_proofs_attributes.push({
          ...fieldValue,
          id_type: fieldsObjectKeys[index],
        });
      }
    });

    dispatch(updateIdentityDetails({ onSuccess, payload }));
  }

  function handleSubmit(event, values) {
    dispatch(setOnboardingSection(null));

    const formData = generatePayloadFromFormValue(values);

    if (onboardingStepParamValue === ONBOARDING_STEPS.MOBILE_VERIFICATION) {
      handleMobileVerificationPagesOnSubmit(values);
    } else if (
      onboardingStepParamValue === ONBOARDING_STEPS.VIDEO_KYC_PROCESS
    ) {
      handleVideoKycForLq();
    } else if (onboardingStepParamValue === ONBOARDING_STEPS.PERSONAL_DETAILS) {
      handlePersonalDetailsFormSubmit(formData);
    } else if (onboardingStepParamValue === ONBOARDING_STEPS.ADDRESS_DETAILS) {
      handleAddressDetailsFormSubmit(formData);
    } else if (onboardingStepParamValue === ONBOARDING_STEPS.IDENTITY_DETAILS) {
      handleIdentityDetailsFormSubmit(formData);
    } else {
      moveNextPageOfCurrentStep();
    }
  }

  function handleVideoKycForLq() {
    const areAllStepsStatusFinished = areAllVkycStepsFinished(
      onboardingSection?.sections?.[0]
    );

    if (areAllStepsStatusFinished) {
      dispatch(updateVkycVerified({ onSuccess }));
    } else {
      openVkycCurrentStepsUrl(onboardingSection?.sections?.[0]);
    }
  }

  function checkButtonDisabled() {
    return (
      !isTncChecked ||
      inProgressPersonalDetails ||
      inProgressAddressDetails ||
      inProgressIdentityDetails ||
      vKycInprogress ||
      isFetchingOnboardingSection ||
      outerFooterDisableState
    );
  }

  function handleRetryButtonClick() {
    if (rejectedRetryLink) {
      navigate(rejectedRetryLink);
    }
  }

  function contactSupportClick() {
    vToast({
      title: "onboarding.onboardingSteps.pineLabs.contactSupportToaster.title",
      description:
        "onboarding.onboardingSteps.pineLabs.contactSupportToaster.description",
      variant: "danger",
    });
  }

  function getKycStateComponent() {
    let title = "";
    let descriptionText;
    let titleClasses = "";

    let childComponent = <></>;

    switch (currentOnboardingStep) {
      case KYC_STEPS_STATUS.APPROVED:
        title = "onboarding.onboardingSteps.submit.status.completed.title";
        titleClasses = "text-success-600";
        descriptionText =
          "onboarding.onboardingSteps.submit.status.completed.descriptionText";
        break;

      case KYC_STEPS_STATUS.IN_PROGRESS:
        title = "onboarding.onboardingSteps.submit.status.inProgress.title";
        titleClasses = "text-warning-400";
        descriptionText =
          "onboarding.onboardingSteps.submit.status.inProgress.descriptionText";
        break;

      case KYC_STEPS_STATUS.REJECTED:
        title = "onboarding.onboardingSteps.submit.status.failed.title";
        titleClasses = "text-danger-600";

        childComponent = (
          <div className="flex flex-row justify-center gap-3">
            <Button
              label="onboarding.onboardingSteps.submit.status.failed.buttons.contactSupport.label"
              variant="tertiary"
              classes="text-neutral-500"
              onClick={contactSupportClick}
            />

            <Button
              label="onboarding.onboardingSteps.submit.status.failed.buttons.retry.label"
              onClick={handleRetryButtonClick}
            />
          </div>
        );
        break;

      case KYC_STEPS_STATUS.PENDING:
        break;

      default:
        break;
    }

    return (
      <div className="flex flex-row justify-between h-screen">
        <EmptyData title={title} titleClasses={titleClasses}>
          <div className="w-2/4">
            {descriptionText ? (
              <div className="text-center">
                <Text
                  translationKey={descriptionText}
                  classes="text-sm text-neutral-800 font-medium"
                />
              </div>
            ) : null}

            {childComponent}
          </div>
        </EmptyData>
      </div>
    );
  }

  function getOnboardingFormSubmitButtonLabel() {
    if (!showOnboardingForm) {
      return "onboarding.buttonLabels.redirectDashboard";
    }
    if (onboardingSection?.sections?.[0].buttonLabel) {
      return onboardingSection?.sections?.[0].buttonLabel;
    }
    return "billPay.vendors.createVendor.continue";
  }

  function handleTncClick() {
    setIsTncChecked(!isTncChecked);
  }

  function getToDashboard() {
    const navigatePagePathName =
      getRedirectionLinkForKycStatusApproved(allNavUrls);

    navigate(navigatePagePathName);
  }

  function getRequiredFormToDisplay() {
    let FormComponent = <></>;
    const OnboardingFormComponent = (
      <OnboardingForm
        allSteps={stackOfSteps}
        data={[onboardingSection]}
        setIsFormDisabled={setIsFormDisabled}
        onPageSubmit={(values, values2) => {
          handleSubmit(values, values2);
        }}
        setOuterFooterDisableState={setOuterFooterDisableState}
        handleVideoKycForLq={handleVideoKycForLq}
      />
    );

    if (showLoader || !onboardingSection) {
      return (
        <div
          className={`flex items-center justify-center w-full ${
            stackOfSteps?.length > 1 ? "h-4/5" : "h-full"
          } card-wrapper`}
        >
          <CircleLoader classes="w-10 h-10" />
        </div>
      );
    }

    if (currentOnboardingSection === ONBOARDING_STEPS.REVIEW_DETAILS) {
      return (
        <div className="py-7 slider-content-core card-wrapper">
          <ReviewDetails />
        </div>
      );
    }
    if (currentOnboardingSection === ONBOARDING_STEPS.WELCOME) {
      return <OnboardingWelcomePage />;
    }

    if (isNonGenericForm) {
      if (currentCardProvider?.toLowerCase() === CARD_PROVIDER.PINELABS) {
        FormComponent = <PineLabsIdentityDetailsForm />;
      } else if (
        currentCardProvider?.toLowerCase() === CARD_PROVIDER.LIVQUIK &&
        currentOnboardingSection === ONBOARDING_STEPS.ADDRESS_DETAILS
      ) {
        FormComponent = (
          <LivquikIdentityDetails
            onboardingFormComponent={OnboardingFormComponent}
            disabled={outerFooterDisableState}
          />
        );
      }
    } else {
      FormComponent = OnboardingFormComponent;
    }

    return (
      <div
        className={`p-0 ${
          stackOfSteps?.length > 1 ? "h-4/5" : "h-full"
        } slider-content-core card-wrapper`}
      >
        {FormComponent}
      </div>
    );
  }

  function areAllVkycStepsFinished(sectionResponse) {
    let allStepsDone = true;

    sectionResponse?.fields?.forEach((field) => {
      allStepsDone = allStepsDone && !!field?.finished;
    });

    return allStepsDone;
  }

  function openVkycCurrentStepsUrl(sectionResponse) {
    const selectedStep = sectionResponse?.fields?.find(
      (field) => !field?.finished
    )?.linkUrl;

    if (selectedStep) {
      window.open(selectedStep?.linkUrl?.vciplink, "_blank");
    }
  }

  return (
    <div className="h-full overflow-x-hidden overflow-y-auto no-scrollbar">
      {showOnboardingForm ? (
        <div className="h-full p-0 mx-6 my-10 ">
          <OnboardingCurrentStepPagesOverview />

          {getRequiredFormToDisplay()}
        </div>
      ) : (
        getKycStateComponent()
      )}

      <OnboardingModal
        handleVideoKycForLq={handleVideoKycForLq}
        getToDashboard={getToDashboard}
      />

      <ConfirmReviewDetailsModal />

      {showOnboardingFooter &&
      !isNonGenericForm &&
      currentOnboardingSection !== KYC_STEPS_STATUS.REJECTED &&
      currentOnboardingStep ? (
        <OnboardingFooter
          tncComponent={
            <TncComponent
              tncArray={onboardingSection?.sections?.[0]?.tnc}
              value={isTncChecked}
              onClickHandler={handleTncClick}
            />
          }
          btnLabel={getOnboardingFormSubmitButtonLabel()}
          isFormDisabled={isButtonDisabled || isFetchingOnboardingSection}
          onClick={
            !showOnboardingForm
              ? getToDashboard
              : currentOnboardingSection === ONBOARDING_STEPS.REVIEW_DETAILS
                ? () => {
                    dispatch(toggleConfirmReviewModal());
                  }
                : null
          }
        />
      ) : null}
    </div>
  );
}

export default OnboardingPageHelper;
