import PropTypes from "prop-types";
import React from "react";

import ApprovalsLoaderHelper from "@/components/Reimbursement/ApprovalsTableLoader/LoaderHelper";

export default function ApprovalsTableLoader({ pageType }) {
  return [...Array(10)].map((val, index) => (
    <ApprovalsLoaderHelper
      pageType={pageType}
      key={`approval-loader-${index}`}
    />
  ));
}
ApprovalsTableLoader.propTypes = {
  pageType: PropTypes.string,
};
