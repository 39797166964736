import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import usePagination from "@/hooks/usePagination";

import {
  getSpends,
  getTopSpenders,
  getTransactions,
  resetPaginationTopSpenders,
  resetPaginationTransactions,
  resetSpends,
} from "@/store/reducers/client_analytics";

import { isFetchingSpendChartDataSelector } from "@/store/selectors/analytics";
import { defaultCurrencySelector } from "@/store/selectors/client";
import {
  isFetchingTopSpendersSelector,
  isFetchingTransactionsSelector,
  spendSelector,
  topSpendersListParamsSelector,
  topSpendersListSelector,
  transactionsListParamsSelector,
  transactionsSelector,
} from "@/store/selectors/client_analytics";
import { availableModulesSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import DateRangeFilter from "@/components/core/Filters/DateRangeFilter";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import SpendsTabTable from "@/components/Company/Slider/DateGroupedTable";
import BudgetChart from "@/components/Company/common/BudgetChart";
import SpentWithFrequencyAndDelta from "@/components/Company/common/SpentWithFrequencyAndDelta";
import { DATE_RANGE_FILTER_MODE } from "@/utils/constants/filters";
import {
  amountToCurrency,
  convertDateStringTOYYYYMMDD,
  getDateInPattern,
  getMonthName,
  getRange,
  percentageDiff,
} from "@/utils/common";

import { MODULES } from "@/utils/constants/app";
import { BUDGET_PILL_CONFIG } from "@/constants/common";
import {
  DEPARTMENT,
  DEPARTMENT_SLIDER_SPENDS_TABS,
  LABEL_VS_DATE_RANGE_KEY,
  PROJECT,
  SPEND_ANALYTICS_DATE_RANGE_LABELS,
  SPEND_ANALYTICS_DURATIONS,
  SPEND_FILTER_DURATION_LABELS,
  SPEND_FILTER_DURATION_SPENDS_LABELS,
} from "@/constants/company";
import { DATE_RANGE_KEYS } from "@/constants/date";
import { SHOW_MORE_TABLE_PER_REQUEST_LIMIT } from "@/constants/pagination";

/**
 * Spends tab of a department (or +3 variations) slider
 *
 * Renders donut with spend tables
 * <Title large and bold />
 * <Donut chart with frequencu dropdown />
 * <Analyics table (recent, largest, top spenders) />
 *
 * @param {String} sliderData /${id} API data of parent slider
 * @param {String} parentSliderType current slider env (Tab is rendering as Department/Project/BudgetProject)
 */
export default function SpendsTab({
  showDonut = true,
  showTable = true,
  title = "company.department.departmentDetails.tabs.spends.spendAnalyticsTitle",
  sliderData = null,
  parentSliderType,
}) {
  const sliderId = sliderData?.id;

  const [selectedAnalyticsTab, setSelectedAnalyticsTab] = useState(
    DEPARTMENT_SLIDER_SPENDS_TABS[0]
  );

  const [selectedFrequency, setSelectedFrequency] = useState(
    DATE_RANGE_KEYS.currentMonth
  );
  const isFetchingSpends = useSelector(isFetchingSpendChartDataSelector);

  const transactionList = useSelector(transactionsSelector);
  const topSpendersList = useSelector(topSpendersListSelector);

  const isFetchingTransactions = useSelector(isFetchingTransactionsSelector);
  const isFetchingTopSpenders = useSelector(isFetchingTopSpendersSelector);

  const transactionsListParams = useSelector(transactionsListParamsSelector);
  const topSpendersListParams = useSelector(topSpendersListParamsSelector);

  const transactionState = useLoadingErrorInjector({
    apiKey: "ClientAnalytics:transactions",
    showLoader: true,
    error: {
      header: "",
    },
  });

  const topSpenderState = useLoadingErrorInjector({
    apiKey: "ClientAnalytics:topSpenders",
    showLoader: true,
    error: {
      header: "",
    },
  });

  const list =
    selectedAnalyticsTab.key === 2 ? topSpendersList : transactionList;
  const isFetchingList =
    selectedAnalyticsTab.key === 2
      ? isFetchingTopSpenders
      : isFetchingTransactions;

  const { hasMore } =
    selectedAnalyticsTab.key === 2
      ? topSpendersListParams
      : transactionsListParams;

  const spends = useSelector(spendSelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const availableModule = useSelector(availableModulesSelector);
  const chartData = useMemo(
    () =>
      [
        {
          label: BUDGET_PILL_CONFIG.CARDS.label,
          color: BUDGET_PILL_CONFIG.CARDS.chartHexColor,
          module: MODULES.CARDS,
          value: spends?.cardSpent || 0,
          valueText: amountToCurrency(
            spends?.cardSpent || 0,
            spends?.currency || defaultCurrency
          ),
        },
        {
          label: BUDGET_PILL_CONFIG.BILL_PAY.label,
          color: BUDGET_PILL_CONFIG.BILL_PAY.chartHexColor,
          module: MODULES.BILL_PAY,
          value:
            (spends?.billPaymentSpent ?? 0) +
            (spends.billPaymentPaidOutsideSpent ?? 0),
          valueText: amountToCurrency(
            (spends?.billPaymentSpent ?? 0) +
              (spends?.billPaymentPaidOutsideSpent ?? 0),
            spends?.currency || defaultCurrency
          ),
        },
        {
          label: BUDGET_PILL_CONFIG.REIMBURSEMENT.label,
          color: BUDGET_PILL_CONFIG.REIMBURSEMENT.chartHexColor,
          module: MODULES.REIMBURSEMENTS,
          value:
            (spends?.reimbursementSpent ?? 0) +
            (spends.reimbursementPaidOutsideSpent ?? 0),
          valueText: amountToCurrency(
            (spends?.reimbursementSpent ?? 0) +
              (spends?.reimbursementPaidOutsideSpent ?? 0),
            spends?.currency || defaultCurrency
          ),
        },
      ]?.filter((item) => availableModule?.includes(item?.module)),
    [JSON.stringify(availableModule), JSON.stringify(spends)]
  );

  const fetchedAnalyticsData = {
    delta: percentageDiff(spends.totalSpent || 0, spends.previousSpent || 0),
    balance: { value: spends.totalSpent, currency: spends.currency },
  };

  const dispatch = useDispatch();

  const spendTypeDepProjAdjusted = [DEPARTMENT, PROJECT]?.includes(
    parentSliderType
  )
    ? PROJECT
    : parentSliderType;

  const loadMore = () => {
    if (!sliderData && !showTable) return;

    const params = {
      limit: SHOW_MORE_TABLE_PER_REQUEST_LIMIT,
      page: pageNum,
      spend_type: spendTypeDepProjAdjusted,
      [`${spendTypeDepProjAdjusted}_id`]: sliderId,
      department: parentSliderType === DEPARTMENT,
    };

    if (selectedAnalyticsTab.key === 0) {
      params.sort_by_value = "transaction_date";
    }
    if (selectedAnalyticsTab.key === 1) {
      params.sort_by_value = "transaction_amount";
    }
    if (selectedAnalyticsTab.key === 2) {
      dispatch(getTopSpenders(params));
    } else {
      dispatch(getTransactions(params));
    }
  };
  const handleReset = () => {
    if (selectedAnalyticsTab.key === 2) {
      dispatch(resetPaginationTopSpenders());
    } else {
      dispatch(resetPaginationTransactions());
    }
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset: handleReset,
    filterOptions: {
      tab: JSON.stringify(selectedAnalyticsTab),
      sliderId,
    },
  });

  const showMoreHandler = () => {
    setPageNum((prev) => prev + 1);
  };

  useEffect(() => {
    return () => {
      dispatch(resetSpends());
    };
  }, []);

  // fetch donut data
  useEffect(() => {
    if (!showDonut) return;
    if (!sliderData) return;

    const params = {
      spend_type: spendTypeDepProjAdjusted,
      [`${spendTypeDepProjAdjusted}_id`]: sliderId,
      department: parentSliderType === DEPARTMENT,
    };

    const date = new Date();
    switch (selectedFrequency) {
      case DATE_RANGE_KEYS.currentMonth:
      case DATE_RANGE_KEYS.last3Month:
      case DATE_RANGE_KEYS.last6Month:
      case DATE_RANGE_KEYS.currentYear: {
        const wholeObj = getRange(selectedFrequency, []);
        params.after = convertDateStringTOYYYYMMDD(wholeObj.from);
        params.before = convertDateStringTOYYYYMMDD(wholeObj.to);
        break;
      }
      default:
        params.before = convertDateStringTOYYYYMMDD(selectedFrequency.to);
        params.after = convertDateStringTOYYYYMMDD(selectedFrequency.from);
        break;
    }

    dispatch(getSpends(params));
  }, [sliderId, selectedFrequency, showDonut, !sliderData]);

  return (
    <div className="text-neutral-800">
      {title ? (
        <Text classes="text-lg font-bold" translationKey={title} />
      ) : null}
      {showDonut ? (
        <div className="relative p-6 mt-4 card-wrapper">
          {/* delta card with frequency dropdown (componentize_) */}
          <div className="flex justify-between">
            <SpentWithFrequencyAndDelta
              label={
                typeof selectedFrequency === typeof ""
                  ? SPEND_FILTER_DURATION_SPENDS_LABELS[selectedFrequency]
                  : "company.department.tableHeaders.customSpends"
              }
              // that means it is custom hide in that scenerio
              hideDeltaChip={typeof selectedFrequency !== typeof ""}
              labelTranslationProps={
                typeof selectedFrequency === typeof ""
                  ? {}
                  : {
                      from: getDateInPattern(
                        selectedFrequency?.from,
                        "mmm dd, yyyy",
                        "dd-mm-yyyy"
                      ),
                      to: getDateInPattern(
                        selectedFrequency?.to,
                        "mmm dd, yyyy",
                        "dd-mm-yyyy"
                      ),
                    }
              }
              amount={{
                value: fetchedAnalyticsData.balance?.value,
                currency:
                  fetchedAnalyticsData.balance?.currency || defaultCurrency,
              }}
              delta={fetchedAnalyticsData.delta}
              frequency={selectedFrequency}
              frequencyLabel={SPEND_FILTER_DURATION_LABELS[selectedFrequency]}
            />

            <div className="w-[30%] h-10">
              <DateRangeFilter
                inSlider
                type={DATE_RANGE_FILTER_MODE.dropDown}
                options={Object.keys(SPEND_ANALYTICS_DURATIONS).map(
                  (value) => ({
                    label:
                      SPEND_ANALYTICS_DATE_RANGE_LABELS[
                        SPEND_ANALYTICS_DURATIONS[value]
                      ],
                    value,
                  })
                )}
                dateInputProps={{ disableDateRangeForOneValueSelected: true }}
                callback={(object) => {
                  const spendFrequencyKey =
                    LABEL_VS_DATE_RANGE_KEY[object?.dateRange?.label];

                  if (spendFrequencyKey) {
                    setSelectedFrequency(spendFrequencyKey);
                  } else {
                    setSelectedFrequency(object?.dateRange);
                  }
                }}
                defaultValues={
                  typeof selectedFrequency === typeof ""
                    ? getRange(selectedFrequency, [])
                    : selectedFrequency
                }
                popupTriggerComponent={() => (
                  <button className="flex items-center justify-between flex-1 w-full gap-2 py-3 text-sm font-semibold text-neutral-700 card-wrapper">
                    <Text
                      translationKey={
                        typeof selectedFrequency === typeof ""
                          ? SPEND_ANALYTICS_DATE_RANGE_LABELS[
                              SPEND_ANALYTICS_DURATIONS[selectedFrequency]
                            ]
                          : SPEND_ANALYTICS_DATE_RANGE_LABELS.CUSTOM_RANGE
                      }
                    />
                    <Icon name="ChevronDown" />
                  </button>
                )}
              />
            </div>
          </div>

          {/* Donut with legends */}
          {isFetchingSpends ? (
            <div className="flex flex-row items-center justify-center mt-5 gap-9">
              <LoaderSkeleton type="circle" size={[200, 200]} />
              <div className="flex flex-col gap-5">
                <LoaderSkeleton size={[50, 250]} />
                <LoaderSkeleton size={[50, 250]} />
                <LoaderSkeleton size={[50, 250]} />
                <LoaderSkeleton size={[50, 250]} />
              </div>
            </div>
          ) : (
            <div className="flex justify-end mt-9">
              <BudgetChart
                key={`donut-${chartData?.join("-")}`}
                centerLabelStyles={{ fontSize: "2px", width: "25%" }}
                centerLabel={getMonthName(selectedFrequency)}
                sideLength={300}
                data={chartData}
                sideWidth={680}
                showLegends
              />
            </div>
          )}
        </div>
      ) : null}
      {/* Listing tabs Recent tabs, largest spends, top spenders */}

      {showTable ? (
        <div className="mt-5">
          <Tabs
            items={DEPARTMENT_SLIDER_SPENDS_TABS}
            selectedTab={selectedAnalyticsTab.key}
            setCurrentTab={setSelectedAnalyticsTab}
            mode
          />
          <div
            className="mt-6"
            key={selectedAnalyticsTab.key}
            ref={
              selectedAnalyticsTab.key === 2
                ? topSpenderState?.attach
                : transactionState?.attach
            }
          >
            <SpendsTabTable
              list={list}
              isFetching={isFetchingList}
              loaderFunction={SpendsTabTableLoader}
              isPersonTable={selectedAnalyticsTab.key === 2}
            />
            {hasMore ? (
              <div className="mt-6">
                <Button
                  label="misc.showMore"
                  disabled={!hasMore || isFetchingList}
                  preIcon="Down"
                  onClick={showMoreHandler}
                  classes="px-5 py-3 text-primary-500 font-semibold rounded-lg border-neutral-300"
                  size="0"
                  variant="tertiary"
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

SpendsTab.propTypes = {
  showDonut: PropTypes.bool,
  title: PropTypes.string,
  sliderData: PropTypes.object,
  parentSliderType: PropTypes.string, // "department" | "project" | "budget" | "location"
  showTable: PropTypes.bool,
};

export const SpendsTabTableLoader = () => {
  return Array(5)
    .fill(null)
    .map((_, idx) => (
      <tr key={idx}>
        <td>
          <div className="flex items-center gap-3">
            <LoaderSkeleton type="circle" size={[40, 40]} />
            <div>
              <LoaderSkeleton size={[16, 180]} />
              <LoaderSkeleton size={[16, 280]} />
            </div>
          </div>
        </td>

        <td className="text-right">
          <LoaderSkeleton size={[16, 100]} />
          <LoaderSkeleton size={[16, 100]} />
        </td>
      </tr>
    ));
};
