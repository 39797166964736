import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { defaultZoomSelector } from "@/store/selectors/fileViewer";
import { ocrFileUriSelector } from "@/store/selectors/ocr";

import ZoomAndPageButton from "@/components/core/FileViewer/Buttons/ZoomAndPageButton";

function PNGAndJPGRenderer({
  mainState: { currentDocument, currentFileNo, documents = [] },
}) {
  const containerRef = useRef();
  const [scale, setScale] = useState(0.1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const [rotate, setRotate] = useState(0);
  const isZoomOutDisable = scale <= 0.1;
  const isZoomInDisable = scale >= 5;
  const defaultZoomValue = useSelector(defaultZoomSelector);
  const ocrFileUri = useSelector(ocrFileUriSelector);

  const handleZoomIn = () => {
    setScale((prev) => Number(prev) + 0.1);
  };

  const handleZoomOut = () => {
    setScale((prev) => Number(prev) - 0.1);
  };
  const handleRotate = () => {
    setRotate((prev) => (prev === 360 ? 0 : prev + 90));
  };
  useEffect(() => {
    const image = imageRef.current;
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    const handleMouseDown = (e) => {
      isDragging = true;
      prevPosition = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      const deltaX = e.clientX - prevPosition.x;
      const deltaY = e.clientY - prevPosition.y;
      prevPosition = { x: e.clientX, y: e.clientY };
      setPosition((_position) => ({
        x: _position.x + deltaX,
        y: _position.y + deltaY,
      }));
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    image?.addEventListener("mousedown", handleMouseDown);
    image?.addEventListener("mousemove", handleMouseMove);
    image?.addEventListener("mouseup", handleMouseUp);

    return () => {
      image?.removeEventListener("mousedown", handleMouseDown);
      image?.removeEventListener("mousemove", handleMouseMove);
      image?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [imageRef, scale]);
  // For Wheel Zoom in
  useEffect(() => {
    const handler = (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        setTimeout(() => {
          const _scale = Math.exp(-e.deltaY / 100);

          setScale((prev) => {
            const totalScale = prev * _scale;

            const _isZoomOutDisable = totalScale < 0.1;
            const _isZoomInDisable = totalScale > 5;
            return _isZoomOutDisable || _isZoomInDisable ? prev : totalScale;
          });
        }, 300);
      }
    };
    window.addEventListener("wheel", handler, {
      passive: false, // Add this
    });

    return () => {
      window.removeEventListener("wheel", handler, {
        passive: false, // Add this
      });
    };
  }, []);

  useEffect(() => {
    setScale(defaultZoomValue);
  }, [defaultZoomValue]);
  useEffect(() => {
    const scaleH =
      (containerRef?.current?.clientHeight ?? 0) /
      (imageRef?.current?.clientHeight ?? 1);
    const scaleW =
      (containerRef?.current?.clientWidth ?? 0) /
      (imageRef?.current?.clientWidth ?? 1);

    setScale(Math.min(scaleH, scaleW)?.toFixed(2));
  }, [
    imageRef.current?.clientHeight,
    imageRef.current?.clientWidth,
    containerRef.current?.clientHeight,
    containerRef.current?.clientWidth,
  ]);
  return (
    <div className="flex w-full">
      <div
        ref={containerRef}
        className="flex flex-col items-center justify-center w-full overflow-hidden bg-neutral-100 h-[90%] top-0"
      >
        <div className="flex items-center justify-center w-full h-full">
          <img
            ref={imageRef}
            src={currentDocument?.fileData ?? currentDocument?.uri}
            alt={currentDocument?.fileName}
            className="h-auto cursor-grab active:cursor-grabbing"
            style={{
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px) rotate(${rotate}deg)`,
              backgroundSize: "contain",
            }}
            draggable={false}
          />
        </div>
      </div>
      <ZoomAndPageButton
        numPages={currentFileNo + 1 || 1} // +1 coz currentFileNo uses 0 based indexing
        isZoomInDisable={isZoomInDisable}
        isZoomOutDisable={isZoomOutDisable}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        showPage={documents.length > 1}
        showOCRScannedBadge={currentDocument?.uri === ocrFileUri}
        showRotateButton
        handleRotate={handleRotate}
      />
    </div>
  );
}

export default PNGAndJPGRenderer;

PNGAndJPGRenderer.fileTypes = [
  "png",
  "image/png",
  "jpg",
  "jpeg",
  "image/jpg",
  "image/jpeg",
];
PNGAndJPGRenderer.weight = 2000;
