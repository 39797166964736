import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchCountries } from "@/store/reducers/app";
import {
  deleteMileageRate,
  fetchMileageRateList,
} from "@/store/reducers/reimbursement";

import { countriesSelector } from "@/store/selectors/app";
import {
  isFetchingMileageRateSelector,
  mileageRateSelector,
} from "@/store/selectors/reimbursement";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

import AddMileageRateSlider from "@/components/Reimbursement/Settings/Sliders/MileageRate/AddMileageRateSlider";
import MileageRate from "@/components/Reimbursement/Settings/Sliders/MileageRate/MileageRate";
import { getCorrectedKeysObject } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE } from "@/constants/reimbursement";

export const MileageRateSetting = () => {
  const dispatch = useDispatch();
  const countriesList = useSelector(countriesSelector);
  const mileageRateList = useSelector(mileageRateSelector);
  const isFetching = useSelector(isFetchingMileageRateSelector);
  const [searchParam, setSearchParams] = useSearchParams();
  const [editMileageRate, setEditMileageRate] = useState(null);
  const [componentKey, setComponentKey] = useState(
    searchParam.get(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.general.mileageRate.title
    )
  );

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  useEffect(() => {
    const allSearchParams = searchParam.getAll(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.general.mileageRate.title
    );
    const key = allSearchParams[allSearchParams.length - 1];
    setComponentKey(key);
  }, [searchParam]);
  useEffect(() => {
    dispatch(fetchMileageRateList());
  }, []);
  const searchParamsSetters = (type) => {
    searchParam.delete(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.general.mileageRate.title
    );
    switch (type) {
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.ADD:
        searchParam.append(
          SLIDERS_SEARCH_PARAMS.reimbursements.settings.general.mileageRate
            .title,
          REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.ADD
        );
        setSearchParams(searchParam);
        break;
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.PREVIEW:
        searchParam.append(
          SLIDERS_SEARCH_PARAMS.reimbursements.settings.general.mileageRate
            .title,
          REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.PREVIEW
        );
        setSearchParams(searchParam);
        break;
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.EDIT:
        searchParam.append(
          SLIDERS_SEARCH_PARAMS.reimbursements.settings.general.mileageRate
            .title,
          REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.EDIT
        );
        setSearchParams(searchParam);
        break;
      default:
        break;
    }
  };
  const addMileageRateHandler = () => {
    searchParamsSetters(REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.ADD);
  };
  const handleDiscard = () => {
    searchParamsSetters(REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.PREVIEW);
    setEditMileageRate(null);
  };
  const onEditHandler = (data) => {
    setEditMileageRate(data);
    searchParamsSetters(REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.EDIT);
  };
  const onDeleteHandler = (id) => {
    const filteredMileageRateList = mileageRateList.filter(
      (obj) => obj.id !== id
    );
    const payload = (filteredMileageRateList || []).map(getCorrectedKeysObject);
    const data = { id, data: payload };

    dispatch(deleteMileageRate(data));
  };
  const getComponent = () => {
    let component = null;
    switch (componentKey) {
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.PREVIEW:
        component = (
          <MileageRate
            onClickHandler={addMileageRateHandler}
            mileageRateList={mileageRateList}
            countriesList={countriesList}
            onEditHandler={onEditHandler}
            onDeleteHandler={onDeleteHandler}
          />
        );
        break;
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.ADD:
        component = (
          <AddMileageRateSlider
            title="reimbursement.settings.sliders.mileageRate.addMileageRate.title"
            description="reimbursement.settings.sliders.mileageRate.description"
            showAddNewCountry
            mileageRateList={mileageRateList}
            countriesList={countriesList}
            handleDiscard={handleDiscard}
            editMileageRate={editMileageRate}
            setEditMileageRate={setEditMileageRate}
          />
        );
        break;
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.EDIT:
        component = (
          <AddMileageRateSlider
            title="reimbursement.settings.sliders.mileageRate.editMileageRate.title"
            description="reimbursement.settings.sliders.mileageRate.description"
            showAddNewCountry={false}
            isEditMode
            mileageRateList={mileageRateList}
            countriesList={countriesList}
            handleDiscard={handleDiscard}
            editMileageRate={editMileageRate}
            setEditMileageRate={setEditMileageRate}
          />
        );
        break;
      default:
        break;
    }
    return component;
  };
  if (isFetching)
    return (
      <div className="mx-4">
        <LoaderSkeleton
          size={[50, "full"]}
          fullWidth
          count={5}
          classes="mt-5"
        />
      </div>
    );
  return getComponent();
};
