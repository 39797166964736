import { createSelector } from "@reduxjs/toolkit";

import { FILTER_OPTIONS } from "@/utils/constants/analytics";
import { AVAILABLE_FILTERS } from "@/utils/constants/filters";

import { ROUTES } from "@/constants/routes";
import { TAG_MODULE_TITLES, TAG_TYPES } from "@/constants/tags";

import { cardsEnabledSelector } from "./user";

export const selectAnalyticsStore = (store) => store.analytics;

export const spendChartDataSelector = createSelector(
  selectAnalyticsStore,
  cardsEnabledSelector,
  (analytics, cardsEnabled) => {
    const spendChartData = { ...analytics.spendChartData };
    let { filterOptions } = spendChartData;
    filterOptions = cardsEnabled
      ? filterOptions
      : filterOptions.filter(({ value }) => value !== FILTER_OPTIONS.CARDS);
    return {
      ...spendChartData,
      filterOptions,
    };
  }
);

export const recentSpendsListSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.recentSpends
);
export const largestSpendsListSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.largestSpends
);

export const topSpendersListSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.topSpenders
);

export const monthsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.months
);

export const monthsOptionsAsObjectSelector = createSelector(
  monthsSelector,
  (months) => months.map((month) => ({ label: month, value: month }))
);

export const analyticsExpenseTransactionsFiltersSelector = createSelector(
  selectAnalyticsStore,
  (analytics) =>
    analytics.filterKeys.transactions.expenses.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const analyticsBillPayTransactionsFiltersSelector = createSelector(
  selectAnalyticsStore,
  (analytics) =>
    analytics.filterKeys.transactions.billPay.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const analyticsReimbursementTransactionsFiltersSelector = createSelector(
  selectAnalyticsStore,
  (analytics) =>
    analytics.filterKeys.transactions.reimbursement.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const analyticsOthersTransactionsFiltersSelector = createSelector(
  selectAnalyticsStore,
  (analytics) =>
    analytics.filterKeys.transactions.others.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const analyticsQrPayTransactionsFiltersSelector = createSelector(
  selectAnalyticsStore,
  (analytics) =>
    analytics.filterKeys.transactions.qrPay.map((key) => AVAILABLE_FILTERS[key])
);

export const analyticsPayrollTransactionsFiltersSelector = createSelector(
  selectAnalyticsStore,
  (analytics) =>
    analytics.filterKeys.transactions.payroll.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const analyticsLedgerFiltersSelector = createSelector(
  selectAnalyticsStore,
  (analytics) =>
    analytics.filterKeys.ledger.map((key) => AVAILABLE_FILTERS[key])
);

export const totalSpendSelector = createSelector(
  spendChartDataSelector,
  (spendChartData) => spendChartData?.totalSpends
);

export const spendTypeOptionsSelector = createSelector(
  spendChartDataSelector,
  (spendChartData) => spendChartData.filterOptions
);

export const isFetchingSpendChartDataSelector = createSelector(
  spendChartDataSelector,
  (spendChartData) => spendChartData.isFetching
);

export const companyDashboardSpendChartDataSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.companyDashboardSpendChartData
);

export const isFetchingCompanyDashboardSpendChartData = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.isFetchingCompanyDashboardSpendChartData
);

export const companyAccountBalanceChartDataSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.companyAccountBalanceChartData
);

export const isFetchingCompanyAccountBalanceChartDataSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.isFetchingCompanyAccountBalanceChartData
);

export const companyAccountBalanceAnalyticsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.companyAccountBalanceAnalytics
);

export const isFetchingCompanyAccountBalanceAnalyticsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.isFetchingCompanyAccountBalanceAnalytics
);

export const companyDashboardSpendsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.companyDashboardSpends
);

export const isFetchingCompanyDashboardSpendsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.isFetchingCompanyDashboardSpends
);
export const totalSpendsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.totalSpends
);

export const analyticsExportDataSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.analyticsExportData
);

export const isExportingAnalyticsTransactionSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.isExporting
);

export const analyticsTagsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.analyticsTags
);

export const isFetchingAnalyticsTagsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.isFetchingAnalyticsTags
);

export const analyticsEntitesSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.entities
);

export const isFetchingAnalyticsEntitesSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.isFetchingEntities
);

export const analyticsLedgerDataSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.analyticsLedgerData
);

export const isLoadingSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.isLoading
);

export const analyticsLedgerCurrencySelector = createSelector(
  selectAnalyticsStore,
  (analytics) => {
    const tabs = [];

    if (analytics?.analyticsLedgerCurrency?.length) {
      analytics?.analyticsLedgerCurrency?.forEach((tab, index) => {
        tabs.push({
          name: tab?.currency,
          count: null,
          path: `${
            ROUTES.analytics.ledger.absolutePath
          }/${tab?.currency?.toLowerCase()}`,
          key: index,
          accountId: tab?.id,
        });
      });

      return tabs;
    }
  }
);

export const isFetchingAnalyticsLedgerCurrencySelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.isFetchingAnalyticsLedgerCurrencies
);

export const analyticsCardsAccountingTagsSelector = createSelector(
  analyticsTagsSelector,
  (tags) => tags?.filter((tag) => tag.tagType === TAG_TYPES.ACCOUNTING) ?? null
);

export const analyticsReimbursementAccountingTagsSelector = createSelector(
  analyticsTagsSelector,
  (tags) => tags?.filter((tag) => tag.tagType === TAG_TYPES.ACCOUNTING) ?? null
);

export const analyticsCardsCustomTagsSelector = createSelector(
  analyticsTagsSelector,
  (tags) =>
    tags?.filter(
      (tag) =>
        tag.modules.includes(TAG_MODULE_TITLES.CARD) &&
        tag.tagType === TAG_TYPES.CUSTOM
    ) ?? null
);

export const analyticsReimbursementCustomTagsSelector = createSelector(
  analyticsTagsSelector,
  (tags) =>
    tags?.filter(
      (tag) =>
        tag.modules.includes(TAG_MODULE_TITLES.REIMBURSEMENT) &&
        tag.tagType === TAG_TYPES.CUSTOM
    ) ?? null
);

// transactions selector

export const analyticsTransactionsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.transactions
);
export const analyticsTransactionListSelector = createSelector(
  analyticsTransactionsSelector,
  (transactions) => transactions.list
);

export const analyticsTransactionPageSelector = createSelector(
  analyticsTransactionsSelector,
  (transactions) => transactions.page
);

export const analyticsTransactionTotalSelector = createSelector(
  analyticsTransactionsSelector,
  (transactions) => transactions.total
);

export const analyticsTransactionLimitSelector = createSelector(
  analyticsTransactionsSelector,
  (transactions) => transactions.limit
);

export const analyticsTransactionHasMoreSelector = createSelector(
  analyticsTransactionsSelector,
  (transactions) => transactions.hasMore
);

export const isFetchingAnalyticsTransactionSelector = createSelector(
  analyticsTransactionsSelector,
  (transactions) => transactions.isFetching
);
