import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { fetchClient, updateClientSettings } from "@/store/reducers/client";
import {
  addCompanyTravel,
  addTravelPeople,
  companyTravelLogin,
  fetchPeopleShallow,
  fetchTravelPeople,
} from "@/store/reducers/company";

import {
  clientSelector,
  isCorporateTravelEnabledSelector,
  isFetchingClientDetailsSelector,
} from "@/store/selectors/client";
import {
  isLoginInProgressSelector,
  totalTravelPeopleSelector,
  travelFetchAgainSelector,
  travelOffsetSelector,
} from "@/store/selectors/company";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Carousel from "@/components/core/Carousel";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import VpLink from "@/components/core/vp-link";
import { SETTING_CORPORATE_TRAVEL } from "@/utils/constants/company";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { CAROUSEL_MODE } from "@/constants/common";
import {
  COMPANY_TRAVEL_HELP_URL,
  TRAVEL_CAROUSEL_DATA,
} from "@/constants/company";
import AiroPlane from "@/assets/images/Airoplane.png";

import DeActivateModal from "./DeActivateModal";

export default function CompanyTravel({ isMyVolopayPage }) {
  // TODO: testing with api is done and if any bug will come need to fix it
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const client = useSelector(clientSelector);
  const currentUser = useSelector(userSelector);
  const travelFtechAgain = useSelector(travelFetchAgainSelector);
  const traveloffset = useSelector(travelOffsetSelector);
  const travelPeople = useSelector(totalTravelPeopleSelector);
  const [showModal, setShowModal] = useState(false);
  const [isCheckingUserHasAccess, setIsCheckingUserHasAccess] = useState(true);
  const [isUserHasAccess, setIsUserHasAccess] = useState(false);
  const isCorporateTravelActivated = useSelector(
    isCorporateTravelEnabledSelector
  );

  const isFetching = useSelector(isFetchingClientDetailsSelector);
  const isLoginInProgress = useSelector(isLoginInProgressSelector);

  const checkUuid = () => {
    return (
      travelPeople.length &&
      travelPeople.find((user) => user?.customId === currentUser?.userUuid)
    );
  };
  useEffect(() => {
    const isCompanyCreated = searchParams.get("companyCreated") === "true";
    if (isCompanyCreated && currentUser?.id && client?.id) {
      dispatch(
        updateClientSettings({
          setting: SETTING_CORPORATE_TRAVEL,
          value: !!isCompanyCreated,
        })
      );
      dispatch(
        addTravelPeople({
          client_id: client?.id,
          user_ids: [currentUser?.id],
          onSuccess: () => {
            searchParams.delete("companyCreated");
            setSearchParams(searchParams);
          },
        })
      );
      dispatch(fetchPeopleShallow());
    }
    if (client?.id && !Number.isNaN(traveloffset)) {
      setIsCheckingUserHasAccess(true);
      dispatch(
        fetchTravelPeople({ client_id: client?.id, offset: traveloffset })
      );
    }
  }, [client?.id, currentUser?.id]);

  useEffect(() => {
    if (!checkUuid() && travelFtechAgain && client?.id)
      dispatch(
        fetchTravelPeople({
          client_id: client?.id,
          offset: !Number.isNaN(traveloffset) ? 1 : traveloffset,
        })
      );
  }, [checkUuid(), travelFtechAgain]);

  useEffect(() => {
    travelPeople.forEach((truTripUser, index) => {
      if (truTripUser.customId === currentUser?.uuid) {
        setIsUserHasAccess(true);
      }
      if (index === travelPeople.length - 1) setIsCheckingUserHasAccess(false);
    });
  }, [travelPeople]);

  const carouselRef = useRef(null);
  const textData = TRAVEL_CAROUSEL_DATA;

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.handleRightButton();
    }
  };

  const handlePrevious = () => {
    if (carouselRef.current) {
      carouselRef.current.handleLeftButton();
    }
  };

  const handleActivateCorporateTravel = () => {
    dispatch(
      addCompanyTravel({
        client_id: client?.id,
        onSuccess: (data) => {
          window.open(data?.select_plan_url);
          dispatch(fetchClient());
        },
      })
    );
  };

  const handleDeactivateCorporateTravel = () => {
    const params = { setting: SETTING_CORPORATE_TRAVEL, value: false };

    dispatch(updateClientSettings(params));
  };

  const handleGoToCorporateTravel = () => {
    dispatch(
      companyTravelLogin({
        client_id: client?.id,
        onSuccess: (loginResponse) => {
          window.open(loginResponse?.loginUrl, "_blank");
        },
      })
    );
  };

  const openLearnMorePage = () => {
    window.open(COMPANY_TRAVEL_HELP_URL, "_blank");
  };

  const carouselData = textData.map((val, index) => (
    <div key={`company-travel-carousel-data-${index}`}>
      <div className="flex flex-col gap-8 p-6 border rounded-lg bg-primary-50 border-primary-50">
        <div className="flex items-center justify-between min-w-full gap-8">
          <div className="flex flex-wrap items-center max-w-xl">
            <Text
              translationKey={val.heading}
              classes="text-xl font-bold text-primary-500"
            />
          </div>
          <div className="flex flex-row items-center gap-8">
            <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full">
              <Icon
                name="LeftArrow"
                className="w-5 h-5 cursor-pointer text-neutral-500"
                handleClick={() => handlePrevious()}
              />
            </div>
            <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full">
              <Icon
                name="RightArrow"
                className="w-5 h-5 cursor-pointer text-neutral-500"
                handleClick={() => handleNext()}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5 p-4 bg-white border border-white rounded-lg ">
          <div className="flex flex-col gap-8">
            {val.subData.map((item, idx) => (
              <div
                className="flex items-center gap-4"
                key={`company-tarvel-carousel-subdata-${idx}`}
              >
                <Icon name={item.icon} className="w-5 h-5 text-primary-500" />
                <div className="flex flex-col gap-1">
                  <Text
                    translationKey={item.label}
                    classes="text-base font-semibold text-enutral-800"
                  />
                  <Text
                    translationKey={item.description}
                    classes="text-base font-medium text-neutral-500"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end">
            <VpLink
              text="company.travel.learnMore"
              variant="primary"
              onClickHandler={() => {
                openLearnMorePage();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ));

  const setManagePeopleSlider = () => {
    searchParams.set(SLIDERS_SEARCH_PARAMS.company.travel.managePeople, true);
    setSearchParams(searchParams);
  };
  const popupRef = useRef();
  if (isFetching || isCheckingUserHasAccess)
    return (
      <div className="px-5 py-10">
        <LoaderSkeleton fullWidth size={[80, 80]} count={5} />
      </div>
    );

  return (
    <div className="flex flex-col h-[80vh] pl-11 pr-11.5 ">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <div className="text-sm font-semibold text-neutral-500">
            <Text
              classes="text-neutral-500"
              translationKey={
                isMyVolopayPage ? "myVolopay.title" : "company.title"
              }
            />
            <span>/</span>
            <Text translationKey="company.travel.title" />
          </div>
          <Text
            classes="text-3xl font-bold text-neutral-800"
            translationKey="company.travel.title"
          />
        </div>
        {isCorporateTravelActivated && !isMyVolopayPage ? (
          <div className="flex items-center gap-6">
            <Popup
              ref={popupRef}
              trigger={
                <div>
                  <Button
                    classes="w-fit px-3 font-base text-primary-500 font-semibold"
                    variant="tertiary"
                    preIcon="MoreVerticle"
                    iconClasses="h-6 w-6 text-neutral-500 "
                  />
                </div>
              }
              closeOnDocumentClick
              position="bottom right"
              className="border-0 filter-popup"
            >
              <button
                className="px-3 py-3 text-neutral-500"
                onClick={() => {
                  setShowModal(true);
                  popupRef?.current?.close();
                }}
              >
                <Text translationKey="company.travel.buttons.deactivateCorporateTravel" />
              </button>
            </Popup>
            <Button
              variant="secondary"
              label="company.travel.buttons.managePeople"
              classes="w-fit text-btn-lg font-semibold px-5 py-4"
              onClick={setManagePeopleSlider}
            />
          </div>
        ) : null}
      </div>

      {!isCorporateTravelActivated ? (
        <div className="flex flex-col items-center pb-10 gap-9">
          <Carousel
            isIndicatorsEnabled
            isStepPaginationButtonsDisabled
            isCustomButtonsEnabled
            mode={CAROUSEL_MODE.SINGLE_VIEW}
            refProp={carouselRef}
          >
            {carouselData}
          </Carousel>
          <div>
            <Button
              label="company.travel.buttons.activateCorporateTravel"
              classes="text-btn-lg font-semibold"
              onClick={() => handleActivateCorporateTravel()}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-1/10">
          <div className="flex items-center w-full py-5 bg-primary-50 rounded-xl">
            <div className="flex flex-col w-full gap-5 px-5 py-3">
              <div className="flex flex-col gap-9">
                <Text
                  translationKey={
                    isUserHasAccess
                      ? "company.travel.titleActive"
                      : "company.travel.titleNonAccessUser"
                  }
                  classes="text-3xl font-bold text-primary-500"
                />
                <Text
                  translationKey={
                    isUserHasAccess
                      ? "company.travel.descriptionActive"
                      : "company.travel.descriptionNotAccessUser"
                  }
                  classes="text-lg font-normal text-neutral-500"
                />
              </div>
              {isUserHasAccess ? (
                <Button
                  showLoader={isLoginInProgress}
                  label="company.travel.ctaLable"
                  compact
                  onClick={handleGoToCorporateTravel}
                />
              ) : null}
            </div>
            <div>
              <img src={AiroPlane} alt="Airoplane" />
            </div>
          </div>
        </div>
      )}
      <DeActivateModal
        onCancelClick={() => setShowModal(false)}
        showModal={showModal}
        setShowModal={setShowModal}
        onSubmitClick={handleDeactivateCorporateTravel}
        title="Are you sure you want to deactivate corporate travel?"
        description="Please note that deactivating corporate travel will lead to the disabling of travel access for all users who have been granted corporate travel privileges"
      />
    </div>
  );
}
