import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { getUnreadNotificationCounts, logout } from "@/store/reducers/user";

import { accountFreezeSelector } from "@/store/selectors/client";
import {
  unreadNotificationTabsCountSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import ComingSoonBadge from "@/components/common/ComingSoon";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";

function PageTopSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(userSelector);
  const popupRef = useRef();
  const unreadTabsCount = useSelector(unreadNotificationTabsCountSelector);
  const notificationCount = unreadTabsCount?.notifications;
  const messageCount = unreadTabsCount?.messages;
  const showUnreadMessagesCountIcon = notificationCount + messageCount > 0;
  const acccountFreezed = useSelector(accountFreezeSelector);

  const popUpOptions = [
    {
      label: "My profile",
      action: handleUserProfile,
    },
    {
      label: "Security",
      action: handleSecuritySettings,
    },
    {
      label: "Switch entity",
      action: handleSwitchEntity,
    },
    {
      label: "Log out",
      action: handleLogout,
    },
  ];
  const openPopUp = () => {
    popupRef.current.open();
  };

  const closePopUp = () => {
    popupRef.current.close();
  };

  function handleLogout() {
    dispatch(logout({ navigate }));
  }

  function handleUserProfile() {
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.people.id, user?.id);
    setSearchParams(searchParams);
    closePopUp();
  }

  function handleSecuritySettings() {
    searchParams.append(SLIDERS_SEARCH_PARAMS.security.securitySettings, true);
    setSearchParams(searchParams);
    closePopUp();
  }

  function handleSwitchEntity() {
    searchParams.append(SLIDERS_SEARCH_PARAMS.switchEntity.switch, true);
    setSearchParams(searchParams);
    closePopUp();
  }
  function handleUserNotificationsAndMessages() {
    searchParams.append(SLIDERS_SEARCH_PARAMS.inbox, true);
    setSearchParams(searchParams);
  }

  function handleQuickGuideButtonClick() {
    searchParams.append(SLIDERS_SEARCH_PARAMS.quickGuide.slider, true);
    setSearchParams(searchParams);
  }

  useEffect(() => {
    dispatch(getUnreadNotificationCounts());
  }, []);

  return (
    <div className="h-[60px] shrink-0 pl-11 pr-[60px] py-2 border-b-[1px] border-neutral-300 flex justify-end items-center">
      <div className="flex items-center text-neutral-500">
        <Button
          label="onboarding.quickGuide"
          preIcon="LightBulb"
          size="sm"
          variant="tertiary"
          classes="font-bold"
          onClick={handleQuickGuideButtonClick}
        />
        <div
          className={`relative p-4 ${acccountFreezed ? "opacity-40" : ""}`}
          id="top-section-inbox"
        >
          {showUnreadMessagesCountIcon ? (
            <div className="bg-danger-500 absolute w-2 h-2 right-3 top-2.5 rounded-full" />
          ) : null}

          <Icon
            name="Inbox"
            className={`w-4 h-4 ${
              acccountFreezed ? "cursor-not-allowed" : "cursor-pointer"
            } `}
            handleClick={
              !acccountFreezed ? handleUserNotificationsAndMessages : () => {}
            }
          />
          <Tooltip id="top-section-inbox" direction="bottom">
            <Text translationKey="common.inbox" />
          </Tooltip>
        </div>

        <div
          className={`relative p-4 ${
            acccountFreezed ? "opacity-40 cursor-not-allowed" : "cursor-pointer"
          }`}
          id="top-section-download"
        >
          <Icon
            name="Download"
            className={`w-4 h-4  ${
              acccountFreezed ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            handleClick={
              !acccountFreezed
                ? () => {
                    navigate({
                      pathname: ROUTES.exports.base.absolutePath,
                    });
                  }
                : () => {}
            }
          />
          <Tooltip
            classes="px-2 py-1"
            id="top-section-download"
            direction="bottom"
          >
            <Text translationKey="common.download" />
          </Tooltip>
        </div>

        <div className="pl-4 py-[6px] flex items-center gap-1 text-neutral-500 font-semibold text-sm">
          <div>
            <Popup
              keepTooltipInside="#tooltip-inside"
              ref={popupRef}
              trigger={
                <div
                  onClick={() => {
                    openPopUp();
                  }}
                  className="flex items-center justify-center gap-1 cursor-pointer"
                >
                  <ProfileWidget
                    img={user?.avatarUrl}
                    name={user?.displayName || ""}
                    avatarColor={user?.colorCode}
                    avatarSize="sm"
                    textClasses="text-neutral-500 font-semibold text-sm"
                  />
                  <div className="flex items-center justify-center">
                    <Icon name="Down" className="w-3 h-3 text-neutral-500" />
                  </div>
                </div>
              }
              closeOnDocumentClick
              position="bottom right"
              className="filter-popup"
            >
              <div className="absolute flex flex-col gap-2 bg-white border rounded-lg w-74 top-2 -left-66 border-neutral-200">
                {popUpOptions?.map((item) => (
                  <button
                    disabled={item?.showComingSoon}
                    onClick={() => {
                      item.action();
                    }}
                    className={`flex flex-col justify-start  text-left text-neutral-500 hover:text-neutral-800 hover:bg-neutral-50 outline-0 ${
                      item.showComingSoon ||
                      (acccountFreezed && item?.label !== "Log out")
                        ? "cursor-not-allowed"
                        : ""
                    } ${
                      acccountFreezed && item?.label !== "Log out"
                        ? "opacity-40"
                        : ""
                    }`}
                    key={`${item.key}-${item?.label}-accounting-slider-button-popup-item`}
                  >
                    <div
                      className={`flex items-center justify-between w-full p-5 ${
                        item?.showComingSoon
                          ? "bg-neutral-50 text-neutral-400"
                          : "text-neutral-500"
                      } `}
                    >
                      <Text
                        classes="text-base font-medium"
                        translationKey={item?.label}
                      />
                      {item.showComingSoon ? <ComingSoonBadge /> : null}
                    </div>
                  </button>
                ))}
              </div>
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageTopSection;
