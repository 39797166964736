import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { login, setLoggingUserCred } from "@/store/reducers/user";

import { isLoadingSelector, userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import VpLink from "@/components/core/vp-link";
import { useForm } from "@/utils/useForm";
import { REGEX_VALUE } from "@/utils/constantUseForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";

export default function LoginForm() {
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(isLoadingSelector);
  const [searchParams, _] = useSearchParams();
  const oneTimeToken = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.oneTimeToken
  );
  const actionType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.actionType
  );
  const fromSubdomain = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.fromSubdomain
  );
  const email = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.switching.email
  );
  const isConnectingEntity = !!oneTimeToken && !!actionType && !!fromSubdomain;

  const initialValue = {
    email: {
      value: currentUser?.email ?? email ?? "",
      validate: {
        required: true,
        regex: REGEX_VALUE.email,
      },
    },
    password: {
      value: currentUser?.password || "",
      validate: {
        required: true,
      },
    },
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, (e) => {
      dispatch(setLoggingUserCred({ email: values.email }));

      dispatch(
        login({
          ...values,
          ...(oneTimeToken && { one_time_token: oneTimeToken }),
          ...(actionType && { action_type: actionType }),
          ...(fromSubdomain && { from_subdomain: fromSubdomain }),
          navigate,
        })
      );
    });

  return (
    <form className="flex flex-col pt-4 gap-9" onSubmit={handleSubmit}>
      <Input
        disabled={isConnectingEntity}
        value={currentUser?.email || values?.email}
        placeholder="login.email"
        name="email"
        error={errors?.email}
        wantInitialFocus
        label="login.email"
        onChange={(e) => {
          dispatch(setLoggingUserCred({ email: e.target.value }));
          handleChange(e);
        }}
      />

      <Input
        value={values?.password}
        type="password"
        error={errors?.password}
        placeholder="login.password"
        name="password"
        wantInitialFocus
        allowPasswordEye
        label="login.password"
        onChange={(e) => {
          dispatch(setLoggingUserCred({ password: e.target.value }));
          handleChange(e);
        }}
      />

      <div className="mb-2 ml-auto">
        <VpLink
          text="login.forgotPassword"
          onClickHandler={() => {
            navigate({
              pathname: ROUTES.forgotPassword.base.absolutePath,
              search: searchParams.toString(),
            });
          }}
        />
      </div>
      <Button
        btnType="submit"
        name="formSubmit"
        showLoader={isLoading}
        variant="primary"
        disabled={isFormButtonDisabled}
        classes="w-full"
        label="login.title"
      />
    </form>
  );
}
