import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { updateClientSettings } from "@/store/reducers/client";

import {
  approvalPolicyEnabledSelector,
  customApprovalPolicyEnabledSelector,
  customSubmissionPolicyEnabledSelector,
  paymentsMakerCheckerEnabledSelector,
  payrollMakerCheckerEnabledSelector,
  submissionPolicyEnabledSelector,
} from "@/store/selectors/client";

import SettingsList from "@/components/common/BillPayAndPayroll/Settings/common/SettingsList";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  BILLPAY_SETTING_ENTRIES,
  PAYROLL_SETTING_ENTRIES,
} from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { POLICY_GROUP_MAPPING } from "@/constants/policy";
import { SETTINGS_PARAMS } from "@/constants/settings";

export default function SpendControlsSettings({ context }) {
  const dispatch = useDispatch();

  const submissionPolicyEnabled = useSelector(submissionPolicyEnabledSelector);
  const approvalPolicyEnabled = useSelector(approvalPolicyEnabledSelector);
  const customApprovalPolicyEnabled = useSelector(
    customApprovalPolicyEnabledSelector
  );

  const customSubmissionPolicyEnabled = useSelector(
    customSubmissionPolicyEnabledSelector
  );

  const paymentMakerCheckerEnabled = useSelector(
    paymentsMakerCheckerEnabledSelector
  );

  const payrollMakerCheckerEnabled = useSelector(
    payrollMakerCheckerEnabledSelector
  );

  const getBillpayPayrollSpendControlTabcontent = () => {
    const BILL_PAY_SPEND_CONTROL_CONTENT = [
      {
        id: "approval-policies",
        title: "billPay.settings.rows.approvalPolicies.title",
        description: "billPay.settings.rows.approvalPolicies.description",
        rightChildren: [
          {
            id: BILLPAY_SETTING_ENTRIES.APPROVAL_POLICY,
            title:
              "billPay.settings.rows.approvalPolicies.rightSide.companyApprovalPolicy.title",
            description:
              "billPay.settings.rows.approvalPolicies.rightSide.companyApprovalPolicy.description",

            showSwitch: false,
            isSwitchOn: false,

            sliderType: approvalPolicyEnabled
              ? SLIDERS_SEARCH_PARAMS.settings.policyModule
              : null,
            sliderId: approvalPolicyEnabled
              ? POLICY_GROUP_MAPPING.payments
              : null,
            disableMessage: approvalPolicyEnabled
              ? null
              : "settings.spendControls.approvalPolicy.alertDisabledModuleDesc",
          },
          {
            id: BILLPAY_SETTING_ENTRIES.CUSTOM_APPROVAL_POLICIES,
            title:
              "billPay.settings.rows.approvalPolicies.rightSide.customApprovalPolicies.title",
            description:
              "billPay.settings.rows.approvalPolicies.rightSide.customApprovalPolicies.description",

            showSwitch: false,
            isSwitchOn: false,

            sliderType: customApprovalPolicyEnabled
              ? SLIDERS_SEARCH_PARAMS.settings.customApprovalModule
              : null,
            sliderId: customApprovalPolicyEnabled
              ? POLICY_GROUP_MAPPING.payments
              : null,
            showItem: approvalPolicyEnabled,
            disableMessage: customApprovalPolicyEnabled
              ? null
              : "settings.spendControls.customPolicy.alertDisabledModuleDesc",
          },
          {
            id: "makerChecker",
            title: "settings.spendControls.makerChecker.title",
            description: "settings.spendControls.makerChecker.payment",
            showSwitch: true,
            isSwitchOn: paymentMakerCheckerEnabled,
            showItem: approvalPolicyEnabled,
            handleChange: () =>
              dispatch(
                updateClientSettings({
                  setting: SETTINGS_PARAMS.paymentsMakerCheckerEnabled,
                  value: !paymentMakerCheckerEnabled,
                })
              ),
          },
        ],
      },

      {
        id: "submission-policies",
        title: "billPay.settings.rows.submissionPolicies.title",
        description: "billPay.settings.rows.submissionPolicies.description",
        rightChildren: [
          {
            id: BILLPAY_SETTING_ENTRIES.SUBMISSION_POLICY,
            title:
              "billPay.settings.rows.submissionPolicies.rightSide.companySubmissionPolicy.title",
            description:
              "billPay.settings.rows.submissionPolicies.rightSide.companySubmissionPolicy.description",

            showSwitch: false,
            isSwitchOn: false,
            sliderType: submissionPolicyEnabled
              ? SLIDERS_SEARCH_PARAMS.settings.submissionModule
              : null,
            sliderId: submissionPolicyEnabled
              ? POLICY_GROUP_MAPPING.payments
              : null,
            disableMessage: submissionPolicyEnabled
              ? null
              : "settings.spendControls.submissionPolicy.alertDisabledModuleDesc",
          },
          {
            id: BILLPAY_SETTING_ENTRIES.CUSTOM_SUBMISSION_POLICIES,
            title:
              "billPay.settings.rows.submissionPolicies.rightSide.customSubmissionPolicies.title",
            description:
              "billPay.settings.rows.submissionPolicies.rightSide.customSubmissionPolicies.description",

            showSwitch: false,
            isSwitchOn: false,

            sliderType: customSubmissionPolicyEnabled
              ? SLIDERS_SEARCH_PARAMS.settings.customSubmissionModule
              : null,
            sliderId: customSubmissionPolicyEnabled
              ? POLICY_GROUP_MAPPING.payments
              : null,
            showItem: submissionPolicyEnabled,
            disableMessage: customSubmissionPolicyEnabled
              ? null
              : "settings.spendControls.customSubmissionPolicy.alertDisabledModuleDesc",
          },
        ],
      },
    ];
    const PAYROLL_SPEND_CONTROL_CONTENT = [
      {
        id: "approval-policies",
        title: "settings.approvalPolicies",
        description: "settings.approvalPolicyDescription",
        rightChildren: [
          {
            id: PAYROLL_SETTING_ENTRIES.APPROVAL_POLICY,
            title:
              "payroll.payrollSettings.rows.approvalPolicies.rightSide.payrollApprovalPolicy.title",
            description:
              "payroll.payrollSettings.rows.approvalPolicies.rightSide.payrollApprovalPolicy.description",

            showSwitch: false,
            isSwitchOn: false,

            sliderType: SLIDERS_SEARCH_PARAMS.settings.policyModule,
            sliderId: POLICY_GROUP_MAPPING.payroll,
          },
          {
            id: "makerChecker",
            title: "settings.spendControls.makerChecker.title",
            description: "settings.spendControls.makerChecker.payroll",
            showSwitch: true,
            isSwitchOn: payrollMakerCheckerEnabled,
            showItem: approvalPolicyEnabled,
            handleChange: () =>
              dispatch(
                updateClientSettings({
                  setting: SETTINGS_PARAMS.payrollMakerCheckerEnabled,
                  value: !payrollMakerCheckerEnabled,
                })
              ),
          },
        ],
      },

      {
        id: "submission-policies",
        title: "payroll.payrollSettings.rows.submissionPolicies.title",
        description:
          "payroll.payrollSettings.rows.submissionPolicies.description",
        rightChildren: [
          {
            id: PAYROLL_SETTING_ENTRIES.SUBMISSION_POLICY,
            title:
              "payroll.payrollSettings.rows.submissionPolicies.rightSide.payrollSubmissionPolicy.title",
            description:
              "payroll.payrollSettings.rows.submissionPolicies.rightSide.payrollSubmissionPolicy.description",

            showSwitch: false,
            isSwitchOn: false,

            sliderType: SLIDERS_SEARCH_PARAMS.settings.submissionModule,
            sliderId: POLICY_GROUP_MAPPING.payroll,
          },
        ],
      },
    ];
    return context === BILL_PAYROLL_CONTEXT.BILLPAY
      ? BILL_PAY_SPEND_CONTROL_CONTENT
      : PAYROLL_SPEND_CONTROL_CONTENT;
  };
  return (
    <div>
      <SettingsList tabRows={getBillpayPayrollSpendControlTabcontent()} />
    </div>
  );
}
SpendControlsSettings.propTypes = {
  context: PropTypes.string,
};
