import PropTypes from "prop-types";

import Text from "@/components/core/Text";
import { dateToString, validateAndConvertToISO8601 } from "@/utils/common";
import { BILLING_STATUS } from "@/utils/constants/company";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";

export default function DueDateCell({ val }) {
  const showOverdueText =
    ![
      PAYMENT_STATUSES.paid,
      PAYMENT_STATUSES.outsideVolopay,
      BILLING_STATUS.PAID,
    ].includes(val?.transactionStatus) && // don't show overdue for 'Paid', 'Paid outside voplopay'
    new Date(validateAndConvertToISO8601(val?.paymentDate)).getDate() !==
      new Date(validateAndConvertToISO8601(val?.dueDate)).getDate() &&
    new Date(validateAndConvertToISO8601(val?.dueDate)) < new Date();
  return (
    <div className="flex flex-col items-start " data-done>
      <span>{val.dueDate ? dateToString(val.dueDate) : "-"}</span>

      {showOverdueText ? (
        <Text
          classes="text-danger-500 capitalize text-xs font-normal"
          translationKey="billPay.bill.payments.paymentListTable.overdue"
        />
      ) : null}
    </div>
  );
}

DueDateCell.propTypes = {
  val: PropTypes.object,
};
