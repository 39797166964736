import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  resetLeftSliderHeaderData,
  setIsSliderClosed,
} from "@/store/reducers/slider";

import { urlMapSelector } from "@/store/selectors/loadersError";
import { hasSliderExpandedSelector } from "@/store/selectors/slider";

import SlideDrawer from "@/components/core/Slider";

import {
  FLATTENED_SEARCH_PARAM_OBJECT,
  FLATTENED_SLIDER_LEFT_SIDE_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { DEPENDENT_KEYS } from "@/GlobalSliders/dependentKeys";
import SLIDERS, { ANIMATION_TYPE } from "@/GlobalSliders/Sliders";
import LeftHeaderSlider from "@/GlobalSliders/LeftHeaderSlider";

import { SLIDER_LEFT_SIDE_COMPONENT_MAP } from "./sliderLeftSideComponentMaps";

// only want to render this once
let prevStackLength = 0;
let stackOfComponent = [];
const currentSliderView = [];
function GlobalSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [SliderKey, setSliderKey] = useState();
  const [haveBack, setHaveBack] = useState();
  const [animationType, setAnimationType] = useState(ANIMATION_TYPE.INCREMENT);
  const isExpandedSlider = useSelector(hasSliderExpandedSelector);
  const [rightSideKey, setRightSideKey] = useState(null);
  const [dependentRightSideKey, setDependentRightSideKey] = useState(new Set());
  const apiCallUrlMap = useSelector(urlMapSelector);
  const [isVisibleRightSideComponent, setIsVisibleRightSideComponent] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect
  useEffect(() => {
    dispatch(setIsSliderClosed(!isDrawerOpen));
  }, [isDrawerOpen]); // final slider close action dispatched to store

  useEffect(() => {
    const name = selectCorrectSlider();
    const _rightSideKey = searchParams
      .getAll(SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey)
      .at(-1);
    setRightSideKey(_rightSideKey);
    const _dependentKeyForRightSlider = new Set(
      searchParams.getAll(
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider
      )
    );
    //  cleaning header data whenever slider is changed
    if (name) {
      dispatch(resetLeftSliderHeaderData());
    }
    const keys = [...searchParams.keys()];
    if (keys.includes(SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey)) {
      const rightDependentSlider = searchParams
        .getAll(SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider)
        .at(-1);
      const isItDependentKeyOfCurrent =
        DEPENDENT_KEYS[rightDependentSlider] === name;
      // now checking if right side component is visible

      setIsVisibleRightSideComponent(
        isItDependentKeyOfCurrent || rightDependentSlider === name
      );
    } else {
      setIsVisibleRightSideComponent(false);
    }
    setDependentRightSideKey(_dependentKeyForRightSlider);
    setDrawerOpen(!!name);
    updateAnimationType();
    setHaveBack(stackOfComponent.length > 0);
    childLeftOnCloseHandler([...searchParams.keys()]);
  }, [JSON.stringify([...searchParams.keys()]), rightSideKey]);

  // functions
  const selectCorrectSlider = () => {
    let sliderName;
    let searchParam = "";
    [...searchParams].forEach(([name, value]) => {
      const isNewStack = getIsNewStack(
        stackOfComponent,
        sliderName,
        searchParam
      );
      if (SLIDERS[name]) {
        if (sliderName && isNewStack) {
          stackOfComponent.push({
            currentSliderKey: sliderName,
            searchParam,
          });
        } else if (!isNewStack && stackOfComponent.length > 0) {
          stackOfComponent.pop();
        }
        sliderName = name;
      }
      if (!searchParam) {
        searchParam += `?${name}=${value}`;
      } else {
        searchParam += `&${name}=${value}`;
      }
    });
    setSliderKey(sliderName);
    return sliderName;
  };

  const getIsNewStack = (stack, sliderName, searchParam) => {
    if (sliderName && searchParam)
      return !stack?.some(
        (currentKeyvalue) =>
          currentKeyvalue.currentSliderKey === sliderName &&
          currentKeyvalue.searchParam === searchParam
      );
    return false;
  };

  /**
   * checks which type of animation we have to show and update animation type based on that
   */
  const updateAnimationType = () => {
    // changing animation type
    if (stackOfComponent.length - prevStackLength === -1)
      setAnimationType(ANIMATION_TYPE.DECREMENT);
    if (stackOfComponent.length - prevStackLength === 1)
      setAnimationType(ANIMATION_TYPE.INCREMENT);
    // updating prevStack
    if (prevStackLength !== stackOfComponent.length)
      prevStackLength = stackOfComponent.length;
  };

  /**
   * removes dependent keys
   */
  const removeDependentKeys = () => {
    const params = [...searchParams.keys()];
    params.forEach((val) => {
      // it removes all sliders dependent keys from searchParams
      if (SLIDERS[val]) {
        const dependentKeys = Array.isArray(DEPENDENT_KEYS[val])
          ? DEPENDENT_KEYS[val]
          : [DEPENDENT_KEYS[val]];
        dependentKeys.forEach((dependentKey) => {
          if (dependentKey) {
            searchParams.delete(dependentKey);
          }
        });
      }
    });
  };

  /**
   * removes sliders searchParams of slider and left slider and
   * keep all other search params like of filters and search params
   */
  const removeSearchParamKeys = () => {
    const updatedParams = new URLSearchParams();
    const sliderSearchParamValue = Object.values(FLATTENED_SEARCH_PARAM_OBJECT);
    const rightSideSlideSearchParams = Object.values(
      FLATTENED_SLIDER_LEFT_SIDE_SEARCH_PARAMS
    );
    searchParams.forEach((value, key) => {
      if (
        !sliderSearchParamValue.includes(key) &&
        !rightSideSlideSearchParams.includes(key)
      ) {
        updatedParams.set(key, value);
      }
      setSearchParams(searchParams);
    });

    setSearchParams(updatedParams);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    removeDependentKeys();
    removeSearchParamKeys();
    setSliderKey(null);
    setHaveBack(false);
    stackOfComponent = [];
    setRightSideKey(null);
    setIsVisibleRightSideComponent(false);
    setDependentRightSideKey(new Set());

    const originalSearchParam = new URLSearchParams(window.location.search);
    dispatch(setIsSliderClosed(true));
    childOnCloseHandler([...originalSearchParam.keys()]);
  };

  const [childOnBackHandler, _setChildOnBackHandler] = useState(() => () => {});
  const setChildOnBackHandler = (f) => _setChildOnBackHandler(() => f);
  // make use easier: avoid need to use callback notation of useState setter
  const [childOnCloseHandler, _setCloseOnCloseHandler] = useState(
    () => () => {}
  );
  const setChildOnCloseHandler = (f) => _setCloseOnCloseHandler(() => f);
  const [childLeftOnCloseHandler, _setLeftCloseOnCloseHandler] = useState(
    () => () => {}
  );
  const setLeftChildOnCloseHandler = (f) =>
    _setLeftCloseOnCloseHandler(() => f);
  const onBack = () => {
    const value = stackOfComponent.pop();
    setSearchParams(value?.searchParam ?? {});
    setSliderKey(value?.currentSliderKey ?? null);
    if (haveBack) childOnBackHandler?.();
    // TODO (save navigating to avoid error). It gives error even if content slider has never called setChildOnBackHandler
  };
  // Multiple re-render was happening in component
  const {
    currentQueryParamKey,
    currentQueryParamValue,
    MyComponent,
    staticProps,
    RightSideSlider,
    rightSliderStaticProps,
    currentUrlMap,
  } = useMemo(() => {
    const _currentQueryParamKey = SliderKey;
    const _currentQueryParamValue = searchParams.get(SliderKey);

    const _MyComponent = SLIDERS[SliderKey]?.component;
    const _staticProps = SLIDERS[SliderKey]?.staticProps ?? {};
    const _currentUrlMap = SLIDERS[SliderKey]?.apiKey ?? [];

    // Right Side Value
    // const isRightSliderVisible = dependentRightSideKey?.has(SliderKey);
    const _RightSideSlider = isVisibleRightSideComponent
      ? SLIDER_LEFT_SIDE_COMPONENT_MAP[rightSideKey]?.components
      : null;

    const _rightSliderStaticProps = isVisibleRightSideComponent
      ? SLIDER_LEFT_SIDE_COMPONENT_MAP[rightSideKey]?.staticProps
      : null;

    return {
      currentQueryParamKey: _currentQueryParamKey,
      currentQueryParamValue: _currentQueryParamValue,
      MyComponent: _MyComponent,
      staticProps: _staticProps,
      RightSideSlider: _RightSideSlider,
      rightSliderStaticProps: _rightSliderStaticProps,
      currentUrlMap: _currentUrlMap,
    };
  }, [SliderKey, searchParams, isVisibleRightSideComponent, rightSideKey]);
  // Logic to show loder on right
  useEffect(() => {
    const currentObjectArray = currentUrlMap?.map((i) => apiCallUrlMap?.[i]);
    const currentIsFetching = currentObjectArray
      ?.filter((i) => i)
      ?.some((i) => i?.isFetching);
    if (isLoading !== currentIsFetching) setIsLoading(currentIsFetching);
  }, [JSON.stringify(apiCallUrlMap)]);
  return (
    <SlideDrawer
      show={isDrawerOpen}
      isLoading={isLoading}
      close={closeDrawer}
      haveBack={haveBack}
      leftExtraHeaderContent={<LeftHeaderSlider />}
      onBack={haveBack ? onBack : null}
      stackLength={stackOfComponent?.length}
      animationType={animationType}
      isExpandedSlider={isExpandedSlider}
      showRightSideComponent={isVisibleRightSideComponent}
      rightSideComponent={
        RightSideSlider ? (
          <RightSideSlider
            setOnClose={setLeftChildOnCloseHandler}
            {...rightSliderStaticProps}
          />
        ) : null
      }
    >
      {MyComponent ? (
        <MyComponent
          setOnBack={setChildOnBackHandler}
          closeDrawer={closeDrawer}
          setOnClose={setChildOnCloseHandler}
          searchParamKey={currentQueryParamKey}
          searchParamValue={currentQueryParamValue}
          setIsSliderLoading={setIsLoading}
          {...staticProps}
        />
      ) : null}
    </SlideDrawer>
  );
}

export default GlobalSlider;
