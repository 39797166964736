import to from "await-to-js";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import API from "@/api";

const initialState = {
  timelineData: [],
  timelineType: null,
  timelineResourceId: null,
  timelineStatus: null,
};

const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    setTimelineData(state, action) {
      state.timelineData = action.payload;
    },
    setTimelineType(state, action) {
      state.timelineType = action.payload;
    },
    setTimelineResourceId(state, action) {
      state.timelineResourceId = action.payload;
    },
    setTimelineStatus(state, action) {
      state.timelineStatus = action.payload;
    },
  },
});
// async thunks
export const fetchTimelineData = createAsyncThunk(
  "timeline/fetchTimelineData",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, ...rest } = params;
    const [err, response] = await to(API.Timeline.get(rest));
    if (response?.data) {
      dispatch(setTimelineData(response.data));
      dispatch(setTimelineResourceId(rest?.id));
      onSuccess();
    }
  }
);

export const {
  setTimelineData,
  setTimelineType,
  setTimelineResourceId,
  setTimelineStatus,
} = timelineSlice.actions;

export default timelineSlice.reducer;
