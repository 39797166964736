import PropTypes from "prop-types";

import Owner from "@/components/Accounting/Transactions/common/Tab/Owner";

export default function VendorOwnerCell({ val }) {
  return (
    <Owner
      id={val?.id}
      name={val?.vendor?.owner?.displayName}
      department={val?.vendor?.owner?.departmentName}
      role={val?.vendor?.owner?.roles?.[0]}
      location={val?.vendor?.owner?.locationName}
      showIcon={false}
    />
  );
}

VendorOwnerCell.propTypes = {
  val: PropTypes.shape({
    id: PropTypes.any,

    vendor: PropTypes.object,
    openSliderHandler: PropTypes.func,
    duplicateBillId: PropTypes.any,

    disabled: PropTypes.bool,
    checkedValue: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
  }),
};
