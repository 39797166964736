import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { readAllUserNotifications } from "@/store/reducers/user";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import {
  API_END_PAGE_TYPE,
  REIMBURSEMENT_PAGE_TYPE,
} from "@/utils/constants/reimbursement";
import { PAGE_TYPE } from "@/utils/constants/payments";
import { getDatetimeBasedOnLapsed } from "@/utils/common";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { PAGINATION_PARAM_KEYS } from "@/constants/pagination";
import {
  COMMENT_TYPE_KEY_VALUE_MAPPING,
  MESSAGE_TYPE,
  MESSAGE_TYPE_KEY_VALUE_MAPPING,
} from "@/constants/user";

function MessageListingWidget({
  widgetInfo,
  shaddedNotificationIds,
  setShaddedNotificationIds,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const messageType = widgetInfo?.messageType;
  const ownerName = widgetInfo?.owner?.user?.displayName;
  const ownerAvatarUrl = widgetInfo?.owner?.user?.avatarUrl;
  const ownerAvatarColor = widgetInfo?.owner?.user?.colorCode;
  const createdAt = getDatetimeBasedOnLapsed(widgetInfo?.createdAt);
  const commentType =
    COMMENT_TYPE_KEY_VALUE_MAPPING[widgetInfo?.owner?.commentType];
  const messageTypeText = MESSAGE_TYPE_KEY_VALUE_MAPPING?.[messageType];

  function handleReadStatusOfClickedRow(id) {
    dispatch(
      readAllUserNotifications({
        params: {
          notification_ids: [id],
        },
        onSuccess: () => {
          const updatedIds = shaddedNotificationIds?.filter(
            (shaddedId) => shaddedId !== id
          );

          setShaddedNotificationIds(updatedIds);
        },
      })
    );
  }

  function handleWidgetClick() {
    handleReadStatusOfClickedRow(widgetInfo?.metadata?.ownerId);

    switch (messageType) {
      case MESSAGE_TYPE.VIRTUAL_CARD_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
            .virtualCardRequest,
          widgetInfo?.metadata?.ownerId
        );
        break;

      case MESSAGE_TYPE.TOPUP_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest,
          widgetInfo?.metadata?.ownerId
        );
        break;

      case MESSAGE_TYPE.PHYSICAL_CARD_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
            .physicalCardRequest,
          widgetInfo?.metadata?.ownerId
        );
        break;

      case MESSAGE_TYPE.CLAIM_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.reimbursements.approveId,
          widgetInfo?.metadata?.ownerId
        );
        searchParams.append(PAGINATION_PARAM_KEYS.PAGE, API_END_PAGE_TYPE.all);
        break;

      case MESSAGE_TYPE.PAYROLL_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId,
          widgetInfo?.metadata?.ownerId
        );
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.payments.sliderType,
          REIMBURSEMENT_PAGE_TYPE.approvals
        );
        searchParams.append(PAGINATION_PARAM_KEYS.PAGE, API_END_PAGE_TYPE.all);
        break;

      case MESSAGE_TYPE.BILL_PAYMENT_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.payments.sliderType,
          PAGE_TYPE.approvals
        );
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.payments.pageType,
          API_END_PAGE_TYPE.all
        );
        searchParams.append(
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider,
          SLIDERS_SEARCH_PARAMS.payments.id
        );
        searchParams.append(
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey,
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments
        );
        searchParams.append(
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled,
          true
        );
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.payments.id,
          widgetInfo?.metadata?.ownerId
        );
        break;

      case MESSAGE_TYPE.EXPENSE_FLAGGED:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.expenses.id,
          widgetInfo?.metadata?.ownerId
        );
        break;

      default:
        break;
    }

    if (searchParams?.size > 0) {
      setSearchParams(searchParams);
    }
  }

  return (
    <div
      className={`flex flex-col gap-2 p-6 cursor-pointer border-neutral-200 border-b-2  ${
        shaddedNotificationIds?.includes(widgetInfo?.id) ? "bg-neutral-50" : ""
      }`}
      onClick={handleWidgetClick}
    >
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-col w-full gap-2 0">
          <ProfileWidget
            name={ownerName}
            img={ownerAvatarUrl}
            avatarColor={ownerAvatarColor}
            textClasses="text-neutral-800 font-bold"
          >
            <Text
              translationKey={commentType}
              classes="text-xs font-medium text-neutral-400"
            />
          </ProfileWidget>

          <Text
            translationKey={widgetInfo?.owner?.comment}
            classes="text-sm font-medium text-neutral-500"
          />
        </div>

        <Icon name="RightArrow" className="text-neutral-500" />
      </div>

      <div className="flex flex-row gap-1 items">
        <Text
          translationKey={createdAt}
          classes="text-xs font-medium text-neutral-400"
        />

        <div className="flex items-center">
          <span className="profile-widget-dot" />

          <Text
            translationKey={messageTypeText}
            classes={`text-xs font-medium  ${
              [MESSAGE_TYPE.EXPENSE_FLAGGED].includes(messageType)
                ? "text-danger-500"
                : "text-expense-management-700"
            }`}
          />
        </div>
      </div>
    </div>
  );
}

MessageListingWidget.propTypes = {
  widgetInfo: PropTypes.object,
  shaddedNotificationIds: PropTypes.array,
  setShaddedNotificationIds: PropTypes.func,
};

export default MessageListingWidget;
