import PropTypes from "prop-types";
import { useId } from "react";
import { useTranslation } from "react-i18next";

import Tooltip from "@/components/core/Tooltip";

/**
 * Displays translated text, and takes care of ellipsis (3 dots)
 *
 * @param {String} translationKey   path in translation.json file
 * @param {Object} translationProps object with key-value pairs (both strings). key matches {{template}} used in translation.json
 * @param {String} color            tailwind color class (e.g. text-neutral-500)
 * @param {Object} refProp          used in sliders by slider Title mover hook (useLeftHeaderTitle)
 * @param {Object} id
 * @param {Boolean} showTooltip     if the text exceeds the container width, tooltip is shown
 *  Usage
    ## Using templates in translation strings
    ### - With core/`Text`
    ```jsx
    // JSX
    <Text translation="countNumberOfMembers" translationProps={{count: 23}}/>

    // somewhere in translation.json
    "countNumberOfMembers": "{{count}} members"
    ```

    ### - With useTranslation().`t`
    ```jsx
    // non markup code
    const { t } = useTranslation();
    const memberCountText = t("countNumberOfMembers", { count: 23 });

    // markup
    return <p>{memberCountText}</p>;

    // somewhere in translation.json
    "countNumberOfMembers": "{{count}} members"
    ```

    Result is the same
    > 23 members
 */

export default function Text({
  translationKey = "_TextKey_",
  classes = "",
  color = "",
  refProp = null,
  translationProps = {},
  id = "",
  showTooltip = false,
  toolTipText,
  maxWidthTooltip,
  toolTipDirection = "top",
  noTranslate = false,
  noTooltipTranslate = false,
  tooltipClasses,
  truncate = false,
}) {
  const { t } = useTranslation("translation");
  const finalText = noTranslate
    ? translationKey
    : t(translationKey, translationProps);

  const tooltipId = useId();
  id ||= `${id}-${tooltipId.replaceAll(":", "")}`;

  return (
    <span
      id={`tooltip-${id}`}
      ref={refProp}
      className={`${truncate ? "truncate" : ""} text-${color} ${classes}`}
    >
      {finalText}

      {showTooltip ? (
        <Tooltip
          maxWidth={maxWidthTooltip}
          id={`tooltip-${id}`}
          direction={toolTipDirection}
        >
          <Text
            translationKey={toolTipText || finalText}
            noTranslate={noTooltipTranslate}
            classes={tooltipClasses}
          />
        </Tooltip>
      ) : null}
    </span>
  );
}

Text.propTypes = {
  translationKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  classes: PropTypes.string,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  translationProps: PropTypes.object,
  id: PropTypes.string,
  showTooltip: PropTypes.bool,
  toolTipDirection: PropTypes.string,
  noTranslate: PropTypes.bool,
  toolTipText: PropTypes.string,
  noTooltipTranslate: PropTypes.bool,
  tooltipClasses: PropTypes.string,
  truncate: PropTypes.bool,
};
