import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";

export default function ReceiptCell({ val }) {
  return (
    <div className="flex items-center justify-center">
      {val?.bulkProcessing ? null : val?.hasReceipt ? (
        <Icon name="ReceiptAutoVerified" className=" text-success-500" />
      ) : (
        <Icon name="ReceiptMissing" className=" text-danger-500" />
      )}
    </div>
  );
}

ReceiptCell.propTypes = {
  val: PropTypes.object,
};
