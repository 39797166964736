import PropTypes from "prop-types";

import Text from "@/components/core/Text";

import BalanceCard from "@/components/Company/Overview/MyAccounts/shared/BalanceCard";
import DeltaChip from "@/components/Company/common/DeltaChip";

export default function SpentWithFrequencyAndDelta({
  label = "common.balance",
  labelTranslationProps = {},
  amount,
  delta,
  frequency = "",
  balanceCardProps = {}, // optional
  frequencyLabel,
  hideDeltaChip = false,
}) {
  return (
    <div className="w-fit">
      <BalanceCard
        label={label}
        labelTranslation={labelTranslationProps}
        amount={amount?.value}
        currency={amount?.currency}
        labelClasses="text-xs font-semibold text-neutral-800"
        amountFontSizeClass="text-2xl"
        {...balanceCardProps}
      />

      {/* Chip  Text */}
      <div className="flex items-center gap-1">
        {hideDeltaChip ? null : <DeltaChip delta={delta} />}
        {typeof frequencyLabel === typeof "" ? (
          <Text
            translationKey={frequencyLabel}
            classes="text-xs font-semibold text-neutral-400"
          />
        ) : (
          frequencyLabel
        )}
      </div>
    </div>
  );
}

SpentWithFrequencyAndDelta.propTypes = {
  label: PropTypes.string,
  labelTranslationProps: PropTypes.object,
  amount: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
  }),
  frequency: PropTypes.string, // 'monthly'
  frequencyLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  delta: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      currency: PropTypes.string,
    }),
  ]), // 20.6 % | { value: 200, currency: "SGD"}
  balanceCardProps: PropTypes.object,
  hideDeltaChip: PropTypes.bool,
};
