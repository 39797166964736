import PropTypes from "prop-types";
import React from "react";
import { useSearchParams } from "react-router-dom";

import Button from "@/components/core/Button";

export default function RequestStatementSliderFooter({ confirm, cancel }) {
  return (
    <>
      <Button
        classes="text-neutral-500 font-normal w-16"
        label="cancel"
        onClick={() => cancel()}
        size="md"
        variant="tertiary"
      />
      <Button
        classes="text-neutral-50 font-normal w-16"
        label="Request"
        onClick={() => confirm()}
        size="md"
        variant="primary"
      />
    </>
  );
}
RequestStatementSliderFooter.propTypes = {
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};
