import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";

import { getSpends } from "@/store/reducers/client_analytics";

import { isFetchingSpendChartDataSelector } from "@/store/selectors/analytics";
import { defaultCurrencySelector } from "@/store/selectors/client";
import { spendSelector } from "@/store/selectors/client_analytics";
import { availableModulesSelector, userSelector } from "@/store/selectors/user";

import DateRangeFilter from "@/components/core/Filters/DateRangeFilter";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import BudgetChart from "@/components/Company/common/BudgetChart";
import SpentWithFrequencyAndDelta from "@/components/Company/common/SpentWithFrequencyAndDelta";
import { DATE_RANGE_FILTER_MODE } from "@/utils/constants/filters";
import {
  amountToCurrency,
  endOfMonth,
  getMonthName,
  getRange,
  percentageDiff,
  startOfMonth,
} from "@/utils/common";

import { MODULES } from "@/utils/constants/app";
import { BUDGET_PILL_CONFIG } from "@/constants/common";
import {
  LABEL_VS_DATE_RANGE_KEY,
  SPEND_ANALYTICS_DATE_RANGE_LABELS,
  SPEND_ANALYTICS_DURATIONS,
  SPEND_FILTER_DURATION,
  SPEND_FILTER_DURATION_LABELS,
  SPEND_FILTER_DURATION_SPENDS_LABELS,
} from "@/constants/company";

function SpendAnalyticsSnapshot({
  showDonut = true,
  title = "company.department.departmentDetails.tabs.spends.spendAnalyticsTitle",
  params,
}) {
  const [selectedFrequency, setSelectedFrequency] = useState(
    SPEND_FILTER_DURATION.THIS_MONTH
  );

  const dispatch = useDispatch();

  const spends = useSelector(spendSelector);

  const fetchedAnalyticsData = {
    delta: percentageDiff(spends.totalSpent || 0, spends.previousSpent || 0),
    balance: { value: spends.totalSpent, currency: spends.currency },
  };

  const defaultCurrency = userSelector(defaultCurrencySelector);
  const isFetchingSpends = useSelector(isFetchingSpendChartDataSelector);
  const availableModule = useSelector(availableModulesSelector);
  const chartData = useMemo(
    () =>
      [
        {
          label: BUDGET_PILL_CONFIG.CARDS.label,
          color: BUDGET_PILL_CONFIG.CARDS.chartHexColor,
          module: MODULES.CARDS,
          value: spends?.cardSpent || 0,
          valueText: amountToCurrency(
            spends?.cardSpent || 0,
            spends?.currency || defaultCurrency
          ),
        },
        {
          label: BUDGET_PILL_CONFIG.BILL_PAY.label,
          color: BUDGET_PILL_CONFIG.BILL_PAY.chartHexColor,
          module: MODULES.BILL_PAY,
          value: spends?.billPaymentSpent || 0,
          valueText: amountToCurrency(
            spends?.billPaymentSpent || 0,
            spends?.currency || defaultCurrency
          ),
        },
        {
          label: BUDGET_PILL_CONFIG.REIMBURSEMENT.label,
          color: BUDGET_PILL_CONFIG.REIMBURSEMENT.chartHexColor,
          module: MODULES.REIMBURSEMENTS,
          value: spends?.reimbursementSpent || 0,
          valueText: amountToCurrency(
            spends?.reimbursementSpent || 0,
            spends?.currency || defaultCurrency
          ),
        },
      ]?.filter((item) => availableModule?.includes(item?.module)),
    [JSON.stringify(availableModule)]
  );

  useEffect(() => {
    if (
      Object.entries(params).length > 0 &&
      (params.merchant_id || params.project_id || params.user_id)
    ) {
      dispatch(getSpends({ ...getParamsForSelectedTimeSpan(), ...params }));
    }
  }, [selectedFrequency, JSON.stringify(params)]);

  function getParamsForSelectedTimeSpan() {
    const paramsFrequency = {};
    const date = new Date();

    switch (selectedFrequency) {
      case SPEND_FILTER_DURATION.THIS_MONTH:
        paramsFrequency.after = startOfMonth(date);
        paramsFrequency.before = endOfMonth(date);
        break;
      case SPEND_FILTER_DURATION.LAST_MONTH:
        paramsFrequency.after = startOfMonth(
          new Date(date.getFullYear(), date.getMonth() - 1, 1)
        );
        paramsFrequency.before = endOfMonth(
          new Date(date.getFullYear(), date.getMonth() - 1, 1)
        );
        break;
      case SPEND_FILTER_DURATION.LAST_3_MONTHS:
        paramsFrequency.after = startOfMonth(
          new Date(date.getFullYear(), date.getMonth() - 3, 1)
        );
        paramsFrequency.before = endOfMonth(
          new Date(date.getFullYear(), date.getMonth() - 1, 1)
        );
        break;
      case SPEND_FILTER_DURATION.LAST_6_MONTHS:
        paramsFrequency.after = startOfMonth(
          new Date(date.getFullYear(), date.getMonth() - 6, 1)
        );
        paramsFrequency.before = endOfMonth(date);
        break;
      case SPEND_FILTER_DURATION.THIS_YEAR:
        paramsFrequency.after = startOfMonth(
          new Date(date.getFullYear(), 0, 1)
        );
        paramsFrequency.before = endOfMonth(
          new Date(date.getFullYear() + 1, 0, 0)
        );
        break;

      default:
        break;
    }
    return paramsFrequency;
  }

  const spendState = useLoadingErrorInjector({
    apiKey: "ClientAnalytics:spends",
    showLoader: true,
    error: {
      header: "",
    },
  });

  return (
    <div ref={spendState?.attach}>
      {title ? (
        <Text classes="text-lg font-bold" translationKey={title} />
      ) : null}
      {showDonut ? (
        <div className="p-6 mt-4 card-wrapper">
          <div className="flex justify-between">
            <SpentWithFrequencyAndDelta
              label={SPEND_FILTER_DURATION_SPENDS_LABELS[selectedFrequency]}
              amount={fetchedAnalyticsData.balance}
              delta={fetchedAnalyticsData.delta}
              frequency={selectedFrequency}
              frequencyLabel={SPEND_FILTER_DURATION_LABELS[selectedFrequency]}
            />

            <div className="w-[30%] h-10">
              <DateRangeFilter
                type={DATE_RANGE_FILTER_MODE.dropDown}
                options={Object.keys(SPEND_ANALYTICS_DURATIONS).map(
                  (value) => ({
                    label:
                      SPEND_ANALYTICS_DATE_RANGE_LABELS[
                        SPEND_ANALYTICS_DURATIONS[value]
                      ],
                    value,
                  })
                )}
                callback={(object) => {
                  const spendFrequencyKey =
                    LABEL_VS_DATE_RANGE_KEY[object?.dateRange?.label];

                  if (spendFrequencyKey) {
                    setSelectedFrequency(spendFrequencyKey);
                  } else {
                    setSelectedFrequency(object?.dateRange);
                  }
                }}
                defaultValues={
                  typeof selectedFrequency === typeof ""
                    ? getRange(selectedFrequency, [])
                    : selectedFrequency
                }
                dateInputProps={{ disableDateRangeForOneValueSelected: true }}
                popupTriggerComponent={() => (
                  <button className="flex items-center justify-between flex-1 w-full gap-2 py-3 card-wrapper">
                    <Text
                      translationKey={
                        typeof selectedFrequency === typeof ""
                          ? SPEND_ANALYTICS_DATE_RANGE_LABELS[
                              SPEND_ANALYTICS_DURATIONS[selectedFrequency]
                            ]
                          : SPEND_ANALYTICS_DATE_RANGE_LABELS.CUSTOM_RANGE
                      }
                    />
                    <Icon name="ChevronDown" />
                  </button>
                )}
              />
            </div>
          </div>

          {isFetchingSpends ? (
            <div className="flex flex-row gap-3 mt-5">
              <LoaderSkeleton type="circle" size={[300, 300]} />
              <div className="flex flex-col gap-5">
                <LoaderSkeleton size={[50, 200]} />
                <LoaderSkeleton size={[50, 200]} />
                <LoaderSkeleton size={[50, 200]} />
                <LoaderSkeleton size={[50, 200]} />
              </div>
            </div>
          ) : (
            <div className="flex justify-end mt-9">
              <BudgetChart
                key={`donut-${chartData?.join("-")}`}
                centerLabelStyles={{ fontSize: "2px", width: "25%" }}
                centerLabel={getMonthName(selectedFrequency)}
                sideLength={300}
                sideWidth={700}
                showLegends
                data={chartData?.length > 0 ? chartData : []}
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}
SpendAnalyticsSnapshot.propTypes = {
  showDonut: PropTypes.bool,
  title: PropTypes.string,
  params: PropTypes.object,
};

export default SpendAnalyticsSnapshot;
