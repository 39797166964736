import { createSelector } from "@reduxjs/toolkit";

export const accountStore = (store) => store.account;
export const clientStore = (store) => store.client;

export const globalCountryCurrenciesSelector = createSelector(
  accountStore,
  (account) => account.globalCountryCurrencies
);

export const isFetchingSelector = createSelector(
  accountStore,
  (account) => account.isFetching
);

export const isRequestingCollectionSelector = createSelector(
  accountStore,
  (account) => account.isRequestingCollection
);

export const isFetchingTransactionsSelector = createSelector(
  accountStore,
  (account) => account.isFetchingTransactions
);

export const isFetchingAccountsSelector = createSelector(
  accountStore,
  (account) => account.isFetchingAccounts
);

export const globalAccountsSelector = createSelector(
  accountStore,
  (account) => account.globalAccounts
);

export const currentGlobalAccountSelector = createSelector(
  accountStore,
  (account) => account.currentGlobalAccount
);

export const globalAccountTransactionsSelector = createSelector(
  accountStore,
  (account) => account.globalAccountTransactions
);

export const selectedGlobalAccountCurrencySelector = createSelector(
  accountStore,
  (account) => account.selectedGlobalAccountCurrency
);

export const selectedGlobalAccountLocationSelector = createSelector(
  accountStore,
  (account) => account.selectedGlobalAccountLocation
);
