import { useState } from "react";
import { useSelector } from "react-redux";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { isLoadingPeopleInvitedSelector } from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import { PENDING_INVITES_TABS } from "@/constants/company";

import PendingTable from "./PendingInviteSliderTable";

export default function PendingInvite() {
  const [selectedTab, setSelectedTab] = useState(PENDING_INVITES_TABS[0]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [resendHandlerFlag, setResendHandlerFlag] = useState(0);
  const isLoading = useSelector(isLoadingPeopleInvitedSelector);

  const ref = useLeftHeaderTitle({
    title: "company.people.pendingInvites.title",
  });

  return (
    <div className="slider-content-container">
      <div className="flex flex-col slider-content-core px-9 gap-9">
        <div className="flex flex-col">
          <Text
            translationKey="company.people.pendingInvites.title"
            classes="text-3xl font-bold text-neutral-800"
            refProp={ref}
          />
          <Text
            translationKey="company.people.pendingInvites.description"
            classes="text-sm text-neutral-500 font-medium"
          />
        </div>

        <div className="flex flex-col gap-4">
          <Tabs
            items={PENDING_INVITES_TABS}
            selectedTab={selectedTab?.key}
            setCurrentTab={(tab) => setSelectedTab(tab)}
            mode
            classes="text-base font-semibold"
          />

          <PendingTable
            tab={selectedTab}
            resendHandlerFlag={resendHandlerFlag}
            setSelectedCount={setSelectedCount}
          />
        </div>
      </div>

      <div className="p-6 slider-footer">
        <div className="flex items-center justify-end gap-3 ">
          <Button
            label="company.people.pendingTable.resendInvites"
            classes="w-auto text-btn-lg py-5 px-4 font-semibold "
            iconClasses="gap-2.5"
            counter={selectedCount || null}
            counterClasses="px-2 py-0.5 rounded-lg"
            onClick={() => {
              setResendHandlerFlag((prev) => prev + 1);
            }}
            showLoader={isLoading}
            disabled={selectedCount <= 0}
          />
        </div>
      </div>
    </div>
  );
}
