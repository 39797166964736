import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { getMBs } from "@/utils/common";

export default function XlsxFilePreviewComponent({
  file,
  iconName = "CSV",
  iconClasses = "",
  errorMessageText,
  primaryAction = {},
}) {
  const {
    handler = () => {},
    icon: primaryActionIcon = "Delete",
    className: primaryActionIconClasses = "text-danger-600",
    bgClassName:
      primaryActionBackgroundClasses = "bg-danger-50 p-1 bg-danger-50 rounded-xl",
  } = primaryAction;

  const showPrimaryActionIcon = handler !== null;

  return (
    <div className="flex flex-row items-center gap-4 py-7">
      <div className="flex items-center justify-center rounded-lg w-13 h-13 bg-neutral-50">
        <Icon name={iconName} className={iconClasses} />
      </div>

      <div className="flex flex-col grow">
        <Text
          translationKey={file?.name ?? file?.fileName}
          classes="text-base font-semibold text-neutral-800"
        />

        {errorMessageText ? (
          <Text
            translationKey={errorMessageText}
            classes="text-sm font-medium text-danger-600"
          />
        ) : (
          <div className="flex flex-row gap-3">
            <Text
              translationKey={
                typeof file?.size === typeof ""
                  ? file?.size
                  : Object.values(getMBs(file?.size)).join(" ")
              }
              classes="text-neutral-500 font-semibold text-sm"
            />

            <div className="flex flex-row items-center ">
              <span className="profile-widget-dot" />

              <Text
                classes="text-neutral-500 font-semibold text-sm"
                translationKey="common.uploaded"
              />
            </div>
          </div>
        )}
      </div>

      {showPrimaryActionIcon ? (
        <div className="p-1 bg-danger-50 rounded-xl">
          <Icon
            name={primaryActionIcon}
            className={`w-8 h-8 p-2 rounded-lg cursor-pointer text-danger-600 ${
              primaryActionIconClasses || "text-neutral-700"
            }`}
            bgClassName={primaryActionBackgroundClasses || "bg-neutral-100"}
            handleClick={handler}
          />
        </div>
      ) : null}
    </div>
  );
}

XlsxFilePreviewComponent.propTypes = {
  iconName: PropTypes.string,
  iconClasses: PropTypes.string,
  errorMessageText: PropTypes.string,
  file: PropTypes.object,
  primaryAction: PropTypes.func,
};
