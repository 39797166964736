import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchDepartments, fetchLocations } from "@/store/reducers/company";
import {
  fetchGettingStarted,
  setBannerDetails,
} from "@/store/reducers/myVolopay";
import {
  fetchBannerRetryCtaRedirectionLink,
  setCurrentUser,
  updateUserDetails,
} from "@/store/reducers/user";

import { departmentsSelector } from "@/store/selectors/company";
import {
  bannerDetailsSelector,
  gettingStartedListSelector,
  helpCenterListSelector,
  isFetchingMyVolopaySelector,
} from "@/store/selectors/myVolopay";
import {
  bannerRetryCtaRedirectionLinkSelector,
  userSelector,
} from "@/store/selectors/user";

import Banner from "@/components/core/Banner";
import Text from "@/components/core/Text";

import FirstUserOnboardingModal from "@/components/MyVolopay/FirstUserOnboardingModal";
import GettingStartedList from "@/components/MyVolopay/GettingStartedContent";
import HelpCenter from "@/components/MyVolopay/HelpCenter";
import { openChatWindow } from "@/utils/chatbox";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { MOBILE_APP_URL } from "@/constants/company";
import {
  FE_BE_KEY_GETTING_STARTED_MAPPING,
  GETTING_STARTED_ICON_NAME,
  GETTING_STARTED_NAVIGATE_MAPPING,
  GETTING_STARTED_STATUS,
  GETTING_STARTED_TOOLTIP_LABEL,
  HELP_CENTER_ICON_NAME,
} from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import PlayStore from "@/assets/images/PlayStore.png";
import PhoneImage from "@/assets/images/IPhone13ProShellScreen.png";
import AppStore from "@/assets/images/AppStore.png";

import GettingStartedLoader from "./GettingStartedLoader";

const HELP_CENTER_FOOTER_DETAILS = [
  {
    title: "myVolopay.gettingStarted.helpCenter.needAnyHelpTitle",
    description: "myVolopay.gettingStarted.helpCenter.needAnyHelpDesc",
    icon: "NeedAnyHelp",
    url: "https://help.volopay.co/en/",
  },
  {
    title: "myVolopay.gettingStarted.helpCenter.wantToLearnMoreTitle",
    description: "myVolopay.gettingStarted.helpCenter.wantToLearnMoreDesc",
    icon: "LearnMore",
    url: "https://help.volopay.co/en/",
  },
];

function MyVolopayGettingStartedPage() {
  const dispatch = useDispatch();
  const gettingStartedDetails = useSelector(gettingStartedListSelector);
  const helpCenterDetails = useSelector(helpCenterListSelector);
  const bannerDetails = useSelector(bannerDetailsSelector);
  const currentUser = useSelector(userSelector);
  const isFetching = useSelector(isFetchingMyVolopaySelector);
  const departments = useSelector(departmentsSelector);
  const [showmodal, setShowModal] = useState(false);
  const bannerRetryLink = useSelector(bannerRetryCtaRedirectionLinkSelector);

  // checking if the departments length is equal to 1, we will not show this modal if the department length is greater than 1, once they save and continue the second condition will get false
  // adding id 1 to make sure it wont show for dept/proj managers

  useEffect(() => {
    if (departments) {
      setShowModal(
        departments?.length === 1 &&
          departments[0]?.id === 1 &&
          departments[0]?.createdAt === departments[0]?.updatedAt
      );
    }
  }, [departments]);

  const [showBanner, setShowBanner] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const getColorType = (status) => {
    switch (status) {
      case GETTING_STARTED_STATUS.completed:
        return {
          iconBgColor: "bg-primary-500",
          iconColor: "text-neutral-500",
          titleColor: "text-neutral-800",
          descriptionColor: "text-neutral-500",
        };
      case GETTING_STARTED_STATUS.pending:
        return {
          iconBgColor: "bg-neutral-100",
          iconColor: "text-neutral-500",
          titleColor: "text-neutral-800",
          descriptionColor: "text-neutral-500",
        };
      case GETTING_STARTED_STATUS.disabled:
        return {
          iconBgColor: "bg-neutral-100",
          iconColor: "text-neutral-400",
          titleColor: "text-neutral-400",
          descriptionColor: "text-neutral-400",
        };
      default:
        break;
    }
  };

  const handleRetryClick = () => {
    if (bannerRetryLink) {
      window.open(bannerRetryLink, "_blank");
    }
  };

  const handleSkip = (task) => {
    const updatedSkippedTasks =
      currentUser?.skippedGettingStartedTasks?.length > 0
        ? currentUser?.skippedGettingStartedTasks.includes(task)
          ? currentUser?.skippedGettingStartedTasks
          : [...(currentUser?.skippedGettingStartedTasks ?? []), task]
        : [task];

    dispatch(
      updateUserDetails({
        id: currentUser?.id,
        payload: {
          skipped_getting_started_tasks: updatedSkippedTasks,
        },
        onSuccess: (userData) => {
          dispatch(setCurrentUser(userData));
          dispatch(fetchGettingStarted({ id: currentUser?.id }));
        },
        skipToast: true,
      })
    );
  };
  const contactSupportClick = () => {
    openChatWindow();
  };
  const handleViewPermission = (id) => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.company.rolesPermissions.role,
      id
    );
    setSearchParams(searchParams);
  };

  const handleCloseBanner = (index) => {
    setShowBanner((prev) => ({ ...prev, [index]: false }));
  };

  function handleCompleteNowButtonClick() {
    let completeKeyLink = "";

    bannerDetails?.forEach((bannerSection) => {
      const hasCompleteNowButtonKey = bannerSection?.ctas?.find(
        (ctaKey) => ctaKey === BANNER_CTAS.COMPLETE_NOW
      );

      if (hasCompleteNowButtonKey) {
        completeKeyLink = bannerSection?.link;
      }
    });

    if (completeKeyLink) {
      window.open(completeKeyLink, "_blank");
    }
  }

  // Helper function to open URL in a new tab
  const openUrl = (url) => {
    window.open(url, "_blank");
  };

  // "Contact support": contactSupportClick,
  // Retry: handleRetryClick,
  // "View Permission": handleViewPermission,
  // CTAS will come from backend
  const BANNER_CTAS = {
    VIEW_PERMISSIION: "View permissions",
    CONTACT_SUPPORT: "Contact support",
    RETRY: "Retry",
    COMPLETE_NOW: "Complete now",
  };

  const CTAS_CONFIG = {
    [BANNER_CTAS.VIEW_PERMISSIION]: {
      label: "View permissions",
      classes: "text-success-500",
      outerClasses: "bg-white",
      order: 1,
      handleClick: handleViewPermission,
    },
    [BANNER_CTAS.CONTACT_SUPPORT]: {
      label: "Contact support",
      handleClick: contactSupportClick,
      outerClasses: "bg-danger-400",
      classes: "text-white",
      order: 2,
    },
    [BANNER_CTAS.RETRY]: {
      label: "Retry",
      classes: "text-danger-600",
      outerClasses: "bg-white",
      handleClick: handleRetryClick,
      order: 1,
    },
    [BANNER_CTAS.COMPLETE_NOW]: {
      label: "Complete Now",
      classes: "text-warning-600",
      outerClasses: "bg-warning-100",
      order: 2,
      handleClick: handleCompleteNowButtonClick,
    },
  };

  const handleNavigation = (key) => {
    const baseOriginUrl = window.location.origin;
    let targetUrl = "";
    switch (key) {
      case FE_BE_KEY_GETTING_STARTED_MAPPING.invite_user:
      case FE_BE_KEY_GETTING_STARTED_MAPPING.claim_first_reimbursement:
      case FE_BE_KEY_GETTING_STARTED_MAPPING.create_first_wallet:
      case FE_BE_KEY_GETTING_STARTED_MAPPING.order_physical_card:
      case FE_BE_KEY_GETTING_STARTED_MAPPING.create_virtual_card:
      case FE_BE_KEY_GETTING_STARTED_MAPPING.activate_physical_card:
      case FE_BE_KEY_GETTING_STARTED_MAPPING.explore_departments:
      case FE_BE_KEY_GETTING_STARTED_MAPPING.explore_locations:
      case FE_BE_KEY_GETTING_STARTED_MAPPING.manage_accounting_integrations:
      case FE_BE_KEY_GETTING_STARTED_MAPPING.customise_your_notifications:
        targetUrl = GETTING_STARTED_NAVIGATE_MAPPING[key]?.page;
        // eslint-disable-next-line no-case-declarations
        const newUrl = new URL(`${baseOriginUrl}${targetUrl}`);

        if (GETTING_STARTED_NAVIGATE_MAPPING[key]?.searchParam) {
          newUrl.searchParams.append(
            GETTING_STARTED_NAVIGATE_MAPPING[key]?.searchParam,
            GETTING_STARTED_NAVIGATE_MAPPING[key]?.searchValue
          );
          window.location.href = newUrl.toString();
          return;
        }

        window.location.href = targetUrl;
        break;

      default:
        break;
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    dispatch(fetchGettingStarted({ id: currentUser?.id }));
    dispatch(
      fetchDepartments({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT })
    );
    dispatch(
      fetchLocations({
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
      })
    );
    return () => {
      dispatch(setBannerDetails([]));
    };
  }, []);

  useEffect(() => {
    const obj = {};
    if (bannerDetails && typeof bannerDetails === typeof []) {
      bannerDetails?.forEach((item, index) => {
        obj[index] = true;
      });
    }
    if (Object.keys(obj ?? {})?.length) setShowBanner(obj);

    let hasRetryButtonKey = false;

    bannerDetails?.forEach((bannerSection) => {
      hasRetryButtonKey ||= bannerSection?.ctas?.find(
        (ctaKey) => ctaKey === BANNER_CTAS.RETRY
      );
    });

    if (hasRetryButtonKey) {
      dispatch(fetchBannerRetryCtaRedirectionLink());
    }
  }, [bannerDetails]);

  return (
    <div
      className={`before:contents-[''] relative pb-10  before:absolute  before:left-0 before:bg-neutral-100  before:w-full before:-z-10 ${
        Object.values(showBanner).every((item) => !item) ||
        !bannerDetails?.length
          ? !gettingStartedDetails?.length && !isFetching
            ? "before:-top-1/10 before:h-19.9"
            : "before:-top-1/10 before:h-[35%] "
          : !gettingStartedDetails?.length && !isFetching
            ? "before:h-18"
            : "before:h-1/3"
      } `}
    >
      {bannerDetails?.length ? (
        <div className="flex flex-col gap-3">
          {bannerDetails?.map((bannerDetail, index) =>
            showBanner?.[index] ? (
              <Banner
                key={index}
                id={bannerDetail?.id}
                title={bannerDetail?.title}
                description={bannerDetail?.description}
                closable
                ctas={bannerDetail?.ctas?.map((cta) => CTAS_CONFIG[cta])}
                type={bannerDetail?.status}
                handleCloseBanner={() => handleCloseBanner(index)}
              />
            ) : null
          )}
        </div>
      ) : null}
      <div className="flex flex-col gap-10 px-11">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col items-center justify-center">
            <Text
              translationKey="myVolopay.gettingStarted.title"
              classes="text-primary-500 font-semibold text-2xl"
            />
            <Text
              translationKey="myVolopay.gettingStarted.description"
              classes="text-neutral-500 font-medium text-base"
            />
          </div>

          {gettingStartedDetails?.length > 0 ? (
            <div className="bg-white border-b rounded-lg border-neutral-200">
              {gettingStartedDetails?.map((item, index) =>
                !isFetching ? (
                  <div
                    key={`getting-started-list-key-${item.key}`}
                    className="flex items-center py-6 pl-6 border border-b-0 border-neutral-200 pr-9 h-14"
                  >
                    <GettingStartedList
                      tooltipComponent={
                        GETTING_STARTED_STATUS.disabled === item.status
                          ? GETTING_STARTED_TOOLTIP_LABEL[
                              FE_BE_KEY_GETTING_STARTED_MAPPING[item?.key]
                            ]
                          : null
                      }
                      title={item?.label}
                      description={item?.description}
                      colorType={getColorType(item.status)}
                      icon={
                        GETTING_STARTED_ICON_NAME[
                          FE_BE_KEY_GETTING_STARTED_MAPPING[item?.key]
                        ]
                      }
                      iconClasses="text-neutral-500"
                      id={FE_BE_KEY_GETTING_STARTED_MAPPING[item?.key]}
                      handleChange={
                        GETTING_STARTED_STATUS.disabled === item.status
                          ? () => {}
                          : handleNavigation
                      }
                      navigate={
                        GETTING_STARTED_STATUS.disabled === item.status
                          ? () => {}
                          : item?.navigation
                      } // TODO: Check this navigation link
                      isCompleted={
                        item?.status === GETTING_STARTED_STATUS.completed
                      }
                      handleSkip={() => handleSkip(item?.key)}
                    />
                  </div>
                ) : null
              )}
            </div>
          ) : null}
          {isFetching ? <GettingStartedLoader /> : null}
        </div>

        <div className="mt-5">
          <div className="flex flex-col ">
            <div className="flex flex-col gap-5">
              {helpCenterDetails?.length ? (
                <Text
                  translationKey="myVolopay.gettingStarted.helpCenter.title"
                  classes="font-bold text-2xl text-neutral-800"
                />
              ) : null}
              <div className="grid grid-cols-4 gap-6 ">
                {helpCenterDetails?.map((item, index) => (
                  <div
                    className="p-4 border rounded-lg cursor-pointer border-neutral-200"
                    key={item.key}
                    onClick={() => openUrl(item?.url)}
                  >
                    <HelpCenter
                      icon={HELP_CENTER_ICON_NAME[item.key].icon}
                      title={item?.label}
                      iconColor={HELP_CENTER_ICON_NAME[item.key].iconColor}
                      bgColor={HELP_CENTER_ICON_NAME[item.key].bgColor}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6 py-10">
              {HELP_CENTER_FOOTER_DETAILS.map((el, index) => (
                <div
                  key={index}
                  className="px-4 py-6 border rounded-lg cursor-pointer border-b-1 border-neutral-200"
                  onClick={() => {
                    if (el.icon === "NeedAnyHelp") {
                      openChatWindow();
                    } else {
                      openUrl(el?.url);
                    }
                  }}
                >
                  <GettingStartedList
                    title={el?.title}
                    description={el?.description}
                    titleClasses=" text-xl font-semibold"
                    descriptionClasses=" text-neutral-500 font-medium"
                    icon={el?.icon}
                    iconClasses=" w-15 h-15"
                    showDefaultIcon
                  />
                </div>
              ))}
            </div>
            <div className="relative w-full overflow-hidden rounded-lg h-89 bg-footer-50 px-14.75 pt-8 flex justify-end ">
              <img src={PhoneImage} alt="Getting started" />
              <div className="absolute flex flex-col justify-center w-1/2 -translate-x-1/2 -translate-y-1/2 top-1/3 left-[40%]">
                <Text
                  classes="font-bold text-3xl text-footer-850 font-sora"
                  translationKey="myVolopay.gettingStarted.footer.title"
                />
                <Text
                  classes="text-success-900 font-bold text-base text-footer-850"
                  translationKey="myVolopay.gettingStarted.footer.description"
                />
              </div>
              {/* Placeholder are mapped on exact location of button in the image on click of that it open the link */}
              {/* APP store placeholder */}
              <div className="absolute flex items-center gap-5 w-1/2 -translate-x-1/2 -translate-y-1/2 top-2/3 left-[40%]">
                <div
                  className="cursor-pointer"
                  onClick={() => openUrl(MOBILE_APP_URL.android)}
                >
                  <img src={PlayStore} alt="Play store" />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => openUrl(MOBILE_APP_URL.ios)}
                >
                  <img src={AppStore} alt="App store" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showmodal ? (
        <FirstUserOnboardingModal
          showModal={showmodal}
          setShowModal={setShowModal}
        />
      ) : null}
    </div>
  );
}
export default MyVolopayGettingStartedPage;
