import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";

import BillPay from "@/pages/bill-pay";
import ExchangeRate from "@/pages/bill-pay/exchange-rate";
import BillPayRequests from "@/pages/bill-pay/requests";
import Vendor from "@/pages/bill-pay/vendor";
import { ROUTES } from "@/constants/routes";
import BillPaymentsBillRoutes from "@/routes/bill-pay/bill";
import BillPaymentsSettingsRoutes from "@/routes/bill-pay/settings";

export default function BillPayRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<BillPay />} />
      </Route>

      <Route
        path={`${ROUTES.billpay.bill.pathName}/*`}
        element={<BillPaymentsBillRoutes />}
      />

      <Route path={ROUTES.billpay.vendors.pathName} element={<Vendor />} />

      <Route
        path={ROUTES.billpay.exchangeRate.pathName}
        element={<ExchangeRate />}
      />

      <Route
        path={ROUTES.billpay.requests.pathName}
        element={<BillPayRequests />}
      />

      <Route
        path={`${ROUTES.billpay.settings.pathName}/*`}
        element={<BillPaymentsSettingsRoutes />}
      />
    </Routes>
  );
}
