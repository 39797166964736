import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

/**
 * This component includes options for exporting selected rows and clearing the current selection in the billing invoice table.

 * @param {number} props.allSelectedRow - The number of selected rows.
 * @param {Function} props.handleExport - A function to handle the export action.
 * @param {Function} props.clearSelection - A function to clear the current selection.
 * @param {boolean} props.bulkApproveSelectAll - A boolean indicating whether all rows are selected.

 */
export default function BillingBulkAction({
  allSelectedRowLength,
  handleExport,
  clearSelection,
  bulkApproveSelectAll,
}) {
  return (
    <div className="flex items-center justify-between w-full px-6 bg-white">
      <div className="flex flex-col">
        <Text
          color="neutral-800"
          classes="font-semibold"
          translationKey="accounting.transactions.bulkExport"
          translationProps={{
            selectedRows: bulkApproveSelectAll ? "All" : allSelectedRowLength,
          }}
        />
        <Text translationKey="company.billing.invoicesTableHeaders.exportWrapperDesc" />
      </div>
      <div className="flex items-center gap-7">
        <Button
          type="neutral"
          classes="w-[106px]"
          label="company.billing.invoicesTableHeaders.exportLabel"
          preIcon="OpenInNew"
          onClick={() => handleExport()}
        />
        <div onClick={clearSelection} className="cursor-pointer">
          <Icon name="Close" className="text-neutral-400" />
        </div>
      </div>
    </div>
  );
}
BillingBulkAction.propTypes = {
  allSelectedRowLength: PropTypes.number,
  handleExport: PropTypes.func,
  clearSelection: PropTypes.func,
  bulkApproveSelectAll: PropTypes.bool,
};
