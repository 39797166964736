import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getRequestApprovalInfo } from "@/store/reducers/cards";

import {
  cardRequestDetailsIsFetchingSelector,
  newCardRequestDetailsSelector,
} from "@/store/selectors/cards";

import Alert from "@/components/core/Alert";
import FileUpload from "@/components/core/FileUpload";
import Text from "@/components/core/Text";
import Timeline from "@/components/core/Timeline";

import CardsModuleRequestSection from "@/components/MyVolopay/ActionCentre/common/CardsModuleRequestSection";
import { TYPES_TIMELINE } from "@/utils/constants/timeline";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import CardRules from "./CardRules";
import MerchantAndCategoryControls from "./MerchantAndCategoryControls";
import RequestDetailsForm from "./RequestDetailsForm";
import VirtualCardRequestOverviewCards from "./VirtualCardRequestOverviewCards";

export default function OverviewTab() {
  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);

  const isFetching = useSelector(cardRequestDetailsIsFetchingSelector);
  const isError = Object.keys(cardRequestDetails)?.length === 0;

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
        .virtualCardRequest
    );
    if (id) dispatch(getRequestApprovalInfo({ id }));
  }, [JSON.stringify(searchParams)]);

  const hasInSufficientFunds = cardRequestDetails?.insufficientFunds;

  return (
    <>
      {!isError && !isFetching ? (
        <>
          <div className="mt-5">
            {hasInSufficientFunds ? (
              <Alert
                description="myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.insufficientFunds"
                classes="bg-warning-50 border-warning-300 mb-5"
                descriptionClasses="text-neutral-800"
              />
            ) : null}

            <Timeline
              id={cardRequestDetails?.id}
              status={cardRequestDetails?.currentApprovalLevelStatus}
              currentApprovers={
                cardRequestDetails?.currentApprovers
                  ? cardRequestDetails?.currentApprovers
                  : []
              }
              rejectedReason={cardRequestDetails?.rejectedReason}
              currentApproverLevel={cardRequestDetails?.currentApprovalLevel}
              totalApprovers={cardRequestDetails?.approvalLevels}
              type={TYPES_TIMELINE.CARD_REQUEST}
            />
          </div>
          <div className="mt-5">
            <VirtualCardRequestOverviewCards
              requestOwner={cardRequestDetails?.requestOwner}
              cardHolder={cardRequestDetails?.cardHolder}
            />
          </div>
          <div className="mt-12">
            <RequestDetailsForm
              cardName={cardRequestDetails?.cardName}
              purposeCard={cardRequestDetails?.reason}
              limit={cardRequestDetails?.amount}
              currency={cardRequestDetails?.currency}
              frequency={cardRequestDetails?.frequency}
              linkedTo={cardRequestDetails?.linkedTo}
              blockingDate={cardRequestDetails?.autoBlockDate}
              transactionAmountLimit={
                cardRequestDetails?.transactionAmountLimit
              }
            />
          </div>

          <div className="flex flex-col gap-6">
            <MerchantAndCategoryControls />
            <CardRules />
          </div>

          {cardRequestDetails?.receipts ? (
            <div>
              <Text
                classes="text-lg font-semibold"
                translationKey="accounting.transactions.cards.slider.receipt"
              />

              <FileUpload
                isDisabled
                files={cardRequestDetails?.receipts}
                hidePreviewUploadComponent
                primaryActionDisabled
                secondaryActionDisabled
                accept={{
                  "text/csv": [".pdf"],
                  image: [".png", ".gif", ".jpg", ".jpeg"],
                }}
                insideForm
              />
            </div>
          ) : null}

          <CardsModuleRequestSection />

          {/* <div className="flex flex-col gap-3 mt-4">
        <Text
          translationKey="myVolopay.actionCentre.sliders.requestSliders.orderCard.physical.overviewTab.requestTimeline"
          classes="text-lg font-semibold "
        />

        <Timeline
          cardRequestDetails={activityDetails}
          config={REQUEST_TIMELINE_ITEM_TYPE_BASED_UI_CONFIG}
          showApproversOnStatus={APPROVAL_PENDING_STATUS}
        />
      </div> */}
        </>
      ) : null}
      {isFetching ? "Loading..." : null}
    </>
  );
}
