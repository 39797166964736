import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function CreateSliderProjectDepartmentLoader() {
  return (
    <div className="flex flex-col gap-5">
      {[...Array(10)].map((item, index) => (
        <div
          key={`loader-${index}`}
          className="flex justify-between card-wrapper"
        >
          <div className="flex flex-col gap-1">
            <LoaderSkeleton size={[10, 200]} />
            <LoaderSkeleton size={[10, 100]} />
          </div>
        </div>
      ))}
    </div>
  );
}
