import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchSpendCreditOverview } from "@/store/reducers/expense";

import {
  creditEnabledSelector,
  defaultCurrencySelector,
  isCreditWorthySelector,
  subdomainSelector,
} from "@/store/selectors/client";
import {
  expenseOverviewDetailsSelector,
  expenseOverviewPageFiltersSelector,
  expensesTotalSelector,
  isFetchingStatisticsSelector,
  missingDetailsCountSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isQrPaymentsOverviewDetailsSelector,
  qrPaymentsMissingDetailsSelector,
  qrPaymentsOverviewDetailsSelector,
  qrPaymentsOverviewPageFiltersSelector,
  qrPaymentsTotalSelector,
} from "@/store/selectors/qr-payments";

import Alert from "@/components/core/Alert";
import CopytoClipboard from "@/components/core/CopyToClipboard";
import Text from "@/components/core/Text";

import Export from "@/components/Exports";
import MissingItemsCard from "@/components/common/QrPayAndExpense/common/Overview/MissingItemsCard";
import OverviewDetails from "@/components/common/QrPayAndExpense/common/Overview/OverviewDetails";
import OverviewTable from "@/components/common/QrPayAndExpense/common/Overview/OverviewTable";
import SpendCreditOverview from "@/components/common/QrPayAndExpense/common/Overview/SpendCreditOverview";
import QrPayAndExpenseFilter from "@/components/common/QrPayAndExpense/common/QRPayAndExpenseFilter";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants/index";
import { convertFilters } from "@/utils/filters";
import { amountToCurrency } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  EXPORT_FILTER_STATUS,
  EXPORT_PAGE_TYPE,
  EXPORT_TAB_TYPE,
} from "@/constants/exports";

export default function OverviewPageHelper({
  myVolopayContext = false,
  page,
  filteringParams = {},
  filteringParamsMissingDetails = () => {},
  filterSelectorFromParent = null,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;
  const { t } = useTranslation();

  const expenseSelectors = {
    overviewDetails: useSelector(expenseOverviewDetailsSelector),
    missingData: useSelector(missingDetailsCountSelector),
    filters: useSelector(expenseOverviewPageFiltersSelector),
    totalSelected: useSelector(expensesTotalSelector),
    isOverviewDetails: useSelector(isFetchingStatisticsSelector),
    storeName: "expenses",
    pageSubTitle: "expenses.title",
    searchParam_missingDetails: SLIDERS_SEARCH_PARAMS.expenses.missingDetails,
    searchParam_indexSlider: SLIDERS_SEARCH_PARAMS.expenses.id,
    searchParam_pastPayemnts: SLIDERS_SEARCH_PARAMS.expenses.pastPayments,
  };
  const paymentsSelectors = {
    overviewDetails: useSelector(qrPaymentsOverviewDetailsSelector),
    isOverviewDetails: useSelector(isQrPaymentsOverviewDetailsSelector),
    missingData: useSelector(qrPaymentsMissingDetailsSelector)?.incomplete,
    filters: useSelector(qrPaymentsOverviewPageFiltersSelector),
    totalSelected: useSelector(qrPaymentsTotalSelector),
    storeName: "qrPayments",
    pageSubTitle: "QRPay.payments.routeTitle",
    searchParam_missingDetails: "",
    searchParam_indexSlider: SLIDERS_SEARCH_PARAMS.qrPayments.payments.id,
    searchParam_pastPayemnts: "",
  };
  const currentSelectors = isExpensesPage
    ? expenseSelectors
    : paymentsSelectors;

  // common selectors for expenses and payments
  const isCreditEnabled = useSelector(creditEnabledSelector);
  const isCreditWorthy = useSelector(isCreditWorthySelector);
  const currency = useSelector(defaultCurrencySelector);
  const subdomain = useSelector(subdomainSelector);

  const selectedFilters = useSelector(appliedFilterSelector);

  const action = () => {
    // logic for wanting a higher credit line
  };

  const knowMoreText = {
    label: "expenses.knowMore",
    callback: action,
  };

  useEffect(() => {
    if (isCreditWorthy && isCreditEnabled && isExpensesPage)
      dispatch(fetchSpendCreditOverview());
  }, [isCreditWorthy, isCreditEnabled]);

  return (
    <div className="pl-11 pr-11.5">
      <div className="mb-4">
        <Text
          translationKey="cards.title.plural"
          classes="text-neutral-500 text-sm font-semibold"
        />
        &nbsp;/&nbsp;
        <Text
          translationKey={currentSelectors?.pageSubTitle}
          classes="text-neutral-500 text-sm font-semibold"
        />
        <Text
          color="neutral-800"
          classes="text-4xl font-bold block"
          translationKey="expenses.overview.title"
        />
      </div>

      <div className="flex flex-col gap-5">
        {!isCreditEnabled && isCreditWorthy ? (
          <Alert
            title="expenses.alertMessages.getCreditLine"
            variant="primary"
            icon="Volopay3D"
            sideAction={knowMoreText}
            iconClasses="w-10 h-10"
            titleTransalationProp={{
              amount: amountToCurrency(250000),
              currency,
            }}
          />
        ) : null}
        {isCreditWorthy && isCreditEnabled ? (
          <>
            <SpendCreditOverview
              viewPastRepayments={() =>
                setSearchParams({
                  [currentSelectors?.searchParam_pastPayemnts]: true,
                })
              }
            />
            <Alert
              title="expenses.higherCreditQuestions"
              variant="primary"
              icon="Volopay3D"
              sideAction={knowMoreText}
              iconClasses="w-10 h-10"
            />
          </>
        ) : null}
      </div>
      <div className="flex items-center justify-between">
        <div className="w-11/12">
          <QrPayAndExpenseFilter
            filters={
              filterSelectorFromParent
                ? filterSelectorFromParent
                : currentSelectors.filters
            }
          />
        </div>
        <div className="flex justify-end ">
          <Export
            totalExports={currentSelectors.totalSelected}
            storeName={currentSelectors.storeName}
            sectionName={currentSelectors.storeName}
            additionalFilters={{
              ...convertFilters(selectedFilters),
              ...EXPORT_PAGE_TYPE.EXPENSE,
              ...EXPORT_FILTER_STATUS.EXPENSE.OVERVIEW,
              tab: EXPORT_TAB_TYPE.EXPENSE.OVERVIEW,
              ...filteringParams,
            }}
          />
        </div>
      </div>

      {myVolopayContext ? (
        <div className="flex items-center mb-4 text-sm font-medium text-center text-neutral-500">
          <div className="flex items-center gap-1">
            <Text classes="" translationKey="expenses.overview.dropStatement" />

            <div className="flex items-center justify-center gap-1">
              <Text
                classes="text-neutral-800 font-semibold"
                translationKey="expenses.overview.emailToText"
                translationProps={{ subdomain }}
              />
              <CopytoClipboard
                text={t("expenses.overview.emailToText", {
                  subdomain,
                })}
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className="flex gap-5 mt-1">
        <MissingItemsCard
          isFetching={currentSelectors.isOverviewDetails}
          data={{ count: currentSelectors?.missingData }}
          viewMissingItems={() =>
            setSearchParams({
              [currentSelectors?.searchParam_missingDetails]: true,
            })
          }
          showSection={!myVolopayContext}
        />

        <OverviewDetails
          isFetching={currentSelectors.isOverviewDetails}
          data={currentSelectors.overviewDetails}
          currency={currency}
          page={page}
        />
      </div>
      <div className="mt-10">
        <OverviewTable
          viewTransactionItem={(id) => {
            if (
              !searchParams.has(currentSelectors?.searchParam_indexSlider, id)
            ) {
              searchParams.append(
                currentSelectors?.searchParam_indexSlider,
                id
              );
              setSearchParams(searchParams);
            }
          }}
          page={page}
          filteringParams={filteringParams}
          myVolopayContext={myVolopayContext}
        />
      </div>
    </div>
  );
}
OverviewPageHelper.propTypes = {
  page: PropTypes.string,
  myVolopayContext: PropTypes.string,
  filteringParamsMissingDetails: PropTypes.object,
  filterSelectorFromParent: PropTypes.array,
};
