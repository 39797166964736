import PropsTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import usePagination from "@/hooks/usePagination";

import {
  INVOICE_TABLE_SLICE_ATTRIBUTE_KEY,
  fetchPurchaseBills,
  setPurchaseBillsIntialState,
} from "@/store/reducers/purchase-bills";

import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Table from "@/components/core/Table";

import PaymentsTableLoader from "@/components/Reimbursement/PaymentsTableLoader";
import AmountCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/AmountCell";
import DueDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/DueDateCell";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import InvoiceCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceCell";
import InvoiceNumberCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceNumberCell";
import MonthAndYearCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/MonthAndYearCell";
import PaymentDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentDateCell";
import PaymentStatusCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentStatusCell";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { openRelevantPaymentSlider } from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  INVOICE_SLIDER_TABLE_LIMIT,
  QUERY_PARAMS,
} from "@/constants/pagination";
import { VENDOR_SLIDER_INVOICES_TABS } from "@/constants/vendors";

/**
 * @param {{ key: Number, name: String, count: null }} tab for core/Tabs
 */

// table cell configs
const VENDOR_TABLE_DEFAULT_HEADERS = {
  tableSetting: {
    colWidths: [200, 125, 125, 100, 200],
  },
  tableHeading: [
    {
      id: "previousInvoice",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.previousInvoiceHeading",
      classes: "text-left",
      cellComponent: InvoiceNumberCell,
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT, // TODO 'invoice_number'
    },
    {
      id: "dueDate",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.dueDateHeading",
      classes: "text-left",
      cellComponent: DueDateCell,
    },
    {
      id: "amount",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.amountHeading",
      classes: "text-right justify-end",
      cellComponent: AmountCell,
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },
    {
      id: "invoice",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.invoiceHeading",
      classes: "justify-center",
      cellComponent: InvoiceCell,
    },
    {
      id: "status",
      title:
        "billPay.bill.payments.vendorDetails.accordian.vendorTable.statusHeading",
      classes: "justify-center",
      cellComponent: PaymentStatusCell,
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
  tableDataRowClasses:
    "text-sm font-semibold text-center border-y-2 border-neutral-100",
};

const EMPLOYEE_TABLE_DEFAULT_HEADERS = {
  tableSetting: {
    colWidths: [200, 120, 100, 100, 200],
  },
  tableHeading: [
    {
      id: "paymentNo",
      title: "payroll.employeeDetails.accordian.EmployeeTable.paymentNoHeading",
      classes: "text-left",
      cellComponent: InvoiceNumberCell,
    },
    {
      id: "paymentDate",
      title:
        "payroll.employeeDetails.accordian.EmployeeTable.paymentDateHeading",
      classes: "text-left",
      cellComponent: PaymentDateCell,
    },
    {
      id: "amount",
      title: "payroll.employeeDetails.accordian.EmployeeTable.amountHeading",
      classes: "text-left",
      cellComponent: AmountCell,
    },
    {
      id: "document",
      title: "payroll.employeeDetails.accordian.EmployeeTable.documentHeading",
      classes: "justify-center",
      cellComponent: InvoiceCell,
    },
    {
      id: "status",
      title: "payroll.employeeDetails.accordian.EmployeeTable.statusHeading",
      classes: "justify-center",
      cellComponent: PaymentStatusCell,
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
  tableDataRowClasses:
    " text-sm font-semibold text-center border-y-2 border-neutral-100",
};

const EMPLOYEE_TABLE_SLIDER_DEFAULT_HEADERS = {
  tableSetting: {
    colWidths: [200, 120, 151, 200],
  },
  tableHeading: [
    {
      id: "paymentMonthHeading",
      title:
        "payroll.salaryPayment.payments.employeeDetails.accordian.employeeTable.paymentMonthAndYearHeading",
      classes: "text-left",
      cellComponent: MonthAndYearCell,
    },
    {
      id: "paymentDateHeading",
      title:
        "payroll.salaryPayment.payments.employeeDetails.accordian.employeeTable.paymentDateHeading",
      classes: "text-left",
      cellComponent: PaymentDateCell,
    },
    {
      id: "amountHeading",
      title:
        "payroll.salaryPayment.payments.employeeDetails.accordian.employeeTable.amountHeading",
      classes: "text-right justify-end",
      cellComponent: AmountCell,
    },
    {
      id: "statusHeading",
      title:
        "payroll.salaryPayment.payments.employeeDetails.accordian.employeeTable.statusHeading",
      classes: "justify-center",
      cellComponent: PaymentStatusCell,
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
  tableDataRowClasses:
    " text-sm font-semibold text-center border-y-2 border-neutral-100",
};

export default function InvoiceTable({
  purchaseBillId,
  vendorId,
  tab,
  context,
  sliderTable,
}) {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

  // explicit pagination
  const [currentPage, setCurrentPage] = useState(1); // visible in UI
  const [sorting, setSorting] = useState({
    type: null,
    category: SORTING_CATEGORY.AMOUNT,
  });

  // data fetching
  let filterOptions = {};
  if (tab?.key === VENDOR_SLIDER_INVOICES_TABS[1].key) {
    // largest invoices
    filterOptions = {
      [QUERY_PARAMS.SORT_COLUMNS.KEY_NAME]:
        QUERY_PARAMS.SORT_COLUMNS.VALUES.AMOUNT,
      [QUERY_PARAMS.SORT_DIRECTION.KEY_NAME]:
        QUERY_PARAMS.SORT_DIRECTION.VALUES.DESC,
    };
  } else {
    filterOptions = {
      [QUERY_PARAMS.SORT_COLUMNS.KEY_NAME]:
        QUERY_PARAMS.SORT_COLUMNS.VALUES.CREATED_AT,
      [QUERY_PARAMS.SORT_DIRECTION.KEY_NAME]:
        QUERY_PARAMS.SORT_DIRECTION.VALUES.DESC,
    };
  }

  const listObj = useSelector(
    (store) => store.purchaseBills[INVOICE_TABLE_SLICE_ATTRIBUTE_KEY]
  );
  const initialPaginationParams = {
    list: [],
    page: 1,
    limit: INVOICE_SLIDER_TABLE_LIMIT,
    total: 0,
    isFetching: false,
    hasMore: true,
  };

  const { list, page, limit, total, isFetching, hasMore } =
    listObj || initialPaginationParams;

  const onReset = () => {
    dispatch(
      setPurchaseBillsIntialState({
        key: INVOICE_TABLE_SLICE_ATTRIBUTE_KEY,
        value: initialPaginationParams,
      })
    );

    setCurrentPage(1);
  };

  const loadMore = () => {
    dispatch(
      fetchPurchaseBills({
        key: INVOICE_TABLE_SLICE_ATTRIBUTE_KEY,
        value: {
          page: pageNum,
          limit: INVOICE_SLIDER_TABLE_LIMIT,
          ...(purchaseBillId ? { except_ids: [purchaseBillId] } : {}),
          ...filterOptions,
        },
        context,
        listingParams: {
          vendor_id: vendorId,
          reviewed: true,
        },
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  // cleanup arbitrary key
  useEffect(() => {
    return () => {
      dispatch(
        setPurchaseBillsIntialState({
          key: INVOICE_TABLE_SLICE_ATTRIBUTE_KEY,
          value: initialPaginationParams,
        })
      );
    };
  }, []);

  const isEmpty = !list.length;
  // Note (to avoid confusion)
  // 1. currentPage - for UI navigation, not related to pagination. Basically - where are you, in the UI
  // 2. pageNum - used for triggering fetch call. Simply - what page is being downloaded, or was downloaded last
  // 3. page - downloaded pages, a store state. Simply - how many pages have been downloaded

  // pagination actions
  const prevDisabled = currentPage === 1;
  const nextDisabled = !hasMore && currentPage === page;

  const prevPageHandler = () => setCurrentPage((p) => (p > 1 ? p - 1 : p));

  const nextPageHandler = () => {
    if (currentPage === page) {
      // on the last loaded page
      if (hasMore) {
        setCurrentPage((p) => p + 1); // show loader page
        setPageNum((p) => p + 1); // trigger pagination to fetch more
      }
    } else if (currentPage < page) {
      setCurrentPage((p) => p + 1); // move navigation forward, no fetch
    }
    // currentPage > page case is absent, since button is disabled
  };

  const rows = list.slice(
    INVOICE_SLIDER_TABLE_LIMIT * (currentPage - 1),
    INVOICE_SLIDER_TABLE_LIMIT * currentPage
  );

  // TECH_DEBT invoice table handles only payroll context, remove these conditions
  // or refactor EmployeeDetailsTable into this component
  const config = sliderTable
    ? context === BILL_PAYROLL_CONTEXT.PAYROLL
      ? EMPLOYEE_TABLE_SLIDER_DEFAULT_HEADERS
      : VENDOR_TABLE_DEFAULT_HEADERS
    : context === BILL_PAYROLL_CONTEXT.PAYROLL
      ? EMPLOYEE_TABLE_DEFAULT_HEADERS
      : VENDOR_TABLE_DEFAULT_HEADERS;
  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
  } = config;

  return (
    <div className="mt-6">
      <Table {...tableSetting}>
        <tr className={tableHeadingRowClasses}>
          {tableHeading.map((headVal, index) => (
            <th
              className={`${headVal.classes} text-xs font-semibold`}
              key={headVal.id}
            >
              <HeaderCell
                val={{
                  ...headVal,
                  showCheckbox: false,
                  sorting,
                  setSorting,
                  haveSort: false,
                  // both tabs tables are already sorted, and we dont support multiple sorts
                }}
              />
            </th>
          ))}
        </tr>

        {(!isFetching || !isEmpty) &&
          rows.map((val, index, arr) => (
            <tr
              key={val.id}
              className={`${tableDataRowClasses} ${
                val.selected ? "selected-row-cell" : ""
              }`}
              onClick={() => {
                openRelevantPaymentSlider(val, inPayrollContext, {
                  searchParams,
                  setSearchParams,
                });
              }}
            >
              {tableHeading.map(({ cellComponent: Component, classes, id }) => (
                <td className={classes} key={id}>
                  <Component
                    val={{ ...val, context }}
                    key={id}
                    showCheckbox={false}
                  />
                </td>
              ))}
            </tr>
          ))}
        {isFetching ? (
          <PaymentsTableLoader count={INVOICE_SLIDER_TABLE_LIMIT} />
        ) : null}
      </Table>
      {isEmpty ? null : (
        <StepPaginationButtons
          currentPage={currentPage}
          total={total}
          limit={limit}
          handleLeftButton={prevPageHandler}
          handleRightButton={nextPageHandler}
          leftDisabled={prevDisabled}
          rightDisabled={nextDisabled}
        />
      )}
    </div>
  );
}

InvoiceTable.propTypes = {
  purchaseBillId: PropsTypes.number,
  tab: PropsTypes.object,
  context: PropsTypes.string,
  sliderTable: PropsTypes.bool,
  vendorId: PropsTypes.number,
};
