import PropTypes from "prop-types";

import CircleProgress from "@/components/core/CircleProgress";
import { amountToCurrency } from "@/utils/common";

import { CURRENCY } from "@/constants/currency";

export default function Spent({ spent, total, currency }) {
  if (total === 0) total = 100; // divison by zero
  const circlePercentage = Math.round((100 * spent) / total);
  return (
    <div className="flex flex-col text-sm font-semibold">
      {amountToCurrency(spent, currency)}
      <CircleProgress
        value={circlePercentage}
        variant="sm"
        className="gap-1"
        textClasses="text-xs text-neutral-500 font-medium p-0"
        iconClasses="w-3 h-3"
        blankCircleClasses="stroke-[6px]"
        progressCircleClasses={`stroke-[6px] ${
          circlePercentage === 100 ? "fill-danger-400" : ""
        }`}
        viewBox="2 -4 28 40"
      />
    </div>
  );
}

Spent.propTypes = {
  spent: PropTypes.number,
  total: PropTypes.number,
  currency: PropTypes.oneOf(Object.keys(CURRENCY)),
};
