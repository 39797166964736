import { createSelector } from "@reduxjs/toolkit";

export const ocrResultsStore = (store) => store.ocr;

export const isOcrResultsFound = createSelector(
  ocrResultsStore,
  (ocr) => ocr.results.length !== 0
);
export const isFetchingOCRResultsSelector = createSelector(
  ocrResultsStore,
  (ocr) => ocr.isFetchingOCRResults
);
export const filesSelector = createSelector(
  ocrResultsStore,
  (ocr) => ocr.files
);
export const isOCRReadOnlySelector = createSelector(
  ocrResultsStore,
  (ocr) => ocr.isReadOnly
);
export const ocrErrorSelector = createSelector(
  ocrResultsStore,
  (ocr) => ocr.error
);
export const ocrFileUriSelector = createSelector(
  ocrResultsStore,
  (ocr) => ocr.ocrFileUri
);
export const ocrResults = createSelector(ocrResultsStore, (ocr) => ocr.results);
