import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchCompanyAccountBalanceAnalytics,
  fetchCompanyAccountBalanceChart,
  fetchCompanyDashboardSpendChart,
  fetchCompanyDashboardSpends,
} from "@/store/reducers/analytics";
import { setCompanyFilters } from "@/store/reducers/company";

import {
  accountFreezeSelector,
  isDepartmentClientBudgetTypeSelector,
} from "@/store/selectors/client";
import { companyFiltersSelectors } from "@/store/selectors/company";

import ActionCard from "@/components/Company/Overview/SpendAnalytics/ActionCard";
import ActionCardForAccount from "@/components/Company/Overview/SpendAnalytics/ActionCardForAccount";
import SpendFilters from "@/components/Company/Overview/SpendAnalytics/Filters";
import SpendChart from "@/components/Company/Overview/SpendAnalytics/SpendChart";
import { getRange } from "@/utils/common";

import {
  SPEND_FILTER_DURATION,
  SPEND_FREQUENCY,
  SPEND_TYPES,
} from "@/constants/company";

import SpendListing from "./SpendListing";

function fromAndToDates(duration, from = null, to = null) {
  const today = new Date();

  switch (duration) {
    case SPEND_FILTER_DURATION.THIS_MONTH: {
      const firstDayOfThisMonth = new Date(today);
      firstDayOfThisMonth.setDate(1); // first day

      return { from: firstDayOfThisMonth, to: today };
    }
    case SPEND_FILTER_DURATION.LAST_MONTH: {
      const firstDayOfPreviousMonth = new Date(today);
      firstDayOfPreviousMonth.setMonth(today.getMonth() - 1);
      firstDayOfPreviousMonth.setDate(1); // first day

      const lastDayOfPreviousMonth = new Date(today);
      lastDayOfPreviousMonth.setDate(0); // last day of previous month

      return { from: firstDayOfPreviousMonth, to: lastDayOfPreviousMonth };
    }
    case SPEND_FILTER_DURATION.LAST_6_MONTHS: {
      const dateSixMonthsAgo = new Date(today);
      dateSixMonthsAgo.setMonth(today.getMonth() - 6); // 6 months ago

      return { from: dateSixMonthsAgo, to: today };
    }
    case SPEND_FILTER_DURATION.THIS_YEAR: {
      const firstJanuaryThisYear = new Date(today);
      firstJanuaryThisYear.setMonth(0); // January
      firstJanuaryThisYear.setDate(1); // first day

      return { from: firstJanuaryThisYear, to: today };
    }
    case SPEND_FILTER_DURATION.CUSTOM_RANGE: {
      return { from, to };
    }
    case SPEND_FILTER_DURATION.ALL_TIME: {
      return { from: null, to: today }; // from: null
    }

    default:
      return { from, to: today };
  }
}

// Graph (x, y, curves)
export default function SpendAnalytics({ onAccountPage = false }) {
  const dispatch = useDispatch();
  const accountFreezed = useSelector(accountFreezeSelector);
  const isDepartmentClient = useSelector(isDepartmentClientBudgetTypeSelector);
  const filters = useSelector(companyFiltersSelectors);
  useEffect(() => {
    dispatch(
      setCompanyFilters({
        spendType: onAccountPage ? SPEND_TYPES.BILL_PAY : SPEND_TYPES.TOTAL,
        ...(onAccountPage
          ? getRange(SPEND_FILTER_DURATION.LAST_6_MONTHS, [], 0, "yyyy-mm-dd")
          : getRange(SPEND_FILTER_DURATION.THIS_MONTH, [], 0, "yyyy-mm-dd")),
        frequency: onAccountPage
          ? SPEND_FREQUENCY.MONTHLY
          : SPEND_FREQUENCY.WEEKLY,
      })
    );
  }, []);
  useEffect(() => {
    const check =
      filters?.spendType &&
      filters?.frequency &&
      filters.to?.length &&
      filters?.from?.length;
    if (filters?.spendType && filters?.frequency && filters.to && filters?.from)
      if (onAccountPage) {
        const params = check
          ? {
              ...fromAndToDates(filters.duration),
              frequency: filters.frequency,
            }
          : {};
        if (Object.keys(params).length) {
          delete params.spendType;
          dispatch(fetchCompanyAccountBalanceChart(params));
          dispatch(fetchCompanyAccountBalanceAnalytics(params));
        }
      } else {
        const params = check
          ? {
              after: filters?.from,
              before: filters.to,
              spendType: filters.spendType,
              frequency: filters.frequency,
            }
          : {};
        if (Object.keys(params).length) {
          delete params.spendType;
          dispatch(fetchCompanyDashboardSpendChart(params));
          dispatch(fetchCompanyDashboardSpends(params));
        }
      }
  }, [filters]);

  return (
    <div className={accountFreezed ? "cursor-not-allowed" : ""}>
      <SpendFilters
        onAccountPage={onAccountPage}
        disableFilters={accountFreezed}
      />
      <div className="mt-8">
        {onAccountPage ? (
          <ActionCardForAccount
            spendType={filters.spendType}
            duration={filters.duration}
          />
        ) : (
          <ActionCard
            spendType={filters.spendType}
            duration={filters.duration}
          />
        )}
      </div>
      <div className="mt-8">
        <SpendChart
          // TODO: refactor these props into Redux, this is too much drilling
          spendType={filters.spendType}
          duration={filters.duration}
          frequency={filters.frequency}
          onAccountPage={onAccountPage}
        />
      </div>
      <div className="mt-8">
        <SpendListing
          isDepartmentClient={isDepartmentClient}
          filters={filters}
        />
      </div>
    </div>
  );
}

SpendAnalytics.propTypes = { onAccountPage: PropTypes.bool };
