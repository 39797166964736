import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { archiveClaimPolicy, archivePolicy } from "@/store/reducers/policy";
import { saveClaimPolicyData } from "@/store/reducers/reimbursement";

import { currentPolicySelector } from "@/store/selectors/policy";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import DisableConfirmation from "@/components/Settings/Modals/DisableConfirmation";
import AppliedCountCards from "@/components/Settings/Sliders/common/AppliedCountCards";
import NonEditableSettingSliderFooter from "@/components/Settings/Sliders/common/NonEditableSettingSliderFooter";
import ClaimPolicyHeader from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ClaimPolicyHeader";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  POLICY_CTA,
  POLICY_GROUP_MAPPING,
  POLICY_LAYER,
} from "@/constants/policy";
import { REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE } from "@/constants/reimbursement";
import { ROUTES } from "@/constants/routes";

/**
 * This component provides a view of claim policy categories for a company, including applied count cards,
 * an alert section, and a table displaying the current claim policy data. Users can add or edit claim policy categories.

 * @param {string} searchParams - The search parameters for company claim policies.
 * @param {function} setSearchParams - A function to set the search parameters.
 * @param {function} addCategoriesHandler - A function to handle the addition of claim policy categories.
 * @param {Array} dropdownCategoryName - The currently selected claim policy categories.
 * @param {function} getReimbursementSettingsClaimPolicy - A function to retrieve claim policy settings.
 * @param {Object} claimPolicyData - Data representing the existing claim policy settings.
 * @param {function} onEditPolicyHandler - A function to handle the editing of claim policy settings.
 */

const CompanyClaimPolicyCategories = ({
  searchParams,
  setSearchParams,
  addCategoriesHandler,
  dropdownCategoryName,
  getReimbursementSettingsClaimPolicy,
  claimPolicyData,
  onEditPolicyHandler,
  onCustomisePolicyHandler,
  entity,
}) => {
  const {
    tableHeadingRowClasses,
    tableHeading,
    emptyDataTitle,
    emptyDataDescription,
  } = getReimbursementSettingsClaimPolicy(
    REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.PREVIEW
  );
  const getEmptyChildrenNode = () => (
    <div className="flex flex-col items-center justify-center w-full gap-6 px-4 py-8">
      <Button
        classes="w-fit px-5 py-3"
        label="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.emptyStateButtonTitle"
        onClick={addCategoriesHandler}
      />
    </div>
  );

  const [resetPolicy, setResetPolicy] = useState(false);

  const dispatch = useDispatch();

  const curtPolicy = useSelector(currentPolicySelector);
  const [showDisableModal, setShowDisableModal] = useState(false);

  const departmentCount = claimPolicyData?.linkedDepartments?.total;

  const projectOrDepartmentId =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.department.id) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.project.id);

  const addDeptOrProject =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.project.add) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.department.add);

  const [hide, setHide] = useState(false);
  const projectCount = claimPolicyData?.linkedProjects?.total;
  const handleCancel = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.claimPolicy
    );
    setSearchParams(searchParams);
  };

  const customClaimPolicy = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.customClaimModule
  );

  const closeSlider = (id) => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.claimPolicy
    );
    setSearchParams(searchParams);
  };

  const submitPolicy = async () => {
    setShowDisableModal(false);

    const params = {
      type: POLICY_LAYER.company,
    };
    if (resetPolicy || (projectOrDepartmentId && curtPolicy?.default)) {
      params.projectId = projectOrDepartmentId;
      params.policyGroupType = POLICY_GROUP_MAPPING.reimbursements;
      params.id = claimPolicyData?.id;
    }

    if (resetPolicy)
      await dispatch(archiveClaimPolicy({ ...params, closeSlider }));
    else await dispatch(saveClaimPolicyData({ ...params, closeSlider }));
  };

  return claimPolicyData ? (
    <div className="">
      <div className="pb-16 ">
        {entity ||
        projectOrDepartmentId ||
        addDeptOrProject ||
        customClaimPolicy ? null : (
          <div className="flex items-center w-full gap-5 mt-9">
            <AppliedCountCards
              departmentCount={departmentCount}
              projectCount={projectCount}
            />
          </div>
        )}
        {claimPolicyData?.claimPolicyCategoryLimits.length === 0 && !hide ? (
          <div className="w-full h-auto my-6">
            <Alert
              variant="neutral"
              icon="Error"
              title="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.alertTitle"
              titleClasses="font-semibold"
              description="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.alertDescription"
              close={() => setHide(true)}
              wrapperDivClass="p-2 gap-6"
              iconClasses="m-0.5"
            />
          </div>
        ) : null}
        <div className="flex flex-col mt-6 gap-0.5">
          <Text
            classes="text-lg font-medium"
            translationKey="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.volopayLimitsTitle"
          />
          <Text
            classes="text-sm text-neutral-500"
            translationKey="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.volopayLimitsDescription"
          />
        </div>

        {!claimPolicyData?.flexible &&
        claimPolicyData?.claimPolicyCategoryLimits.length !== 0 ? (
          <div className="w-full h-auto mt-5 mb-10">
            <Alert
              variant="neutral"
              icon="Error"
              title="Non-flexible limit is applied only on selected categories"
              titleClasses="font-semibold text-neutral-800 mb-1"
              description="Categories present here have a non flexible limit set to them, while users have the flexibility to submit claims for any amount in other categories"
              wrapperDivClass="p-2 gap-6"
              iconClasses="m-0.5"
            />
          </div>
        ) : null}
        <div className="flex flex-col p-0.5 mt-6 border rounded-lg border-neutral-300">
          <Table
            {...{
              emptyDataTitle,
              emptyDataDescription,
            }}
            colWidths={[200, 200]}
          >
            <tr className={`${tableHeadingRowClasses} !border-collapse`}>
              {tableHeading.map((headVal, index) => (
                <th className={headVal.classes} key={headVal.id}>
                  <ClaimPolicyHeader
                    val={{
                      ...headVal,
                    }}
                  />
                </th>
              ))}
            </tr>
            {claimPolicyData
              ? dropdownCategoryName.map((category, index) => (
                  <tr key={index}>
                    {tableHeading.map(
                      ({ cellComponent: Component, classes, id }) => (
                        <td className={`${classes} !border-none `} key={id}>
                          <Component
                            val={category}
                            claimPolicyData={claimPolicyData}
                          />
                        </td>
                      )
                    )}
                  </tr>
                ))
              : null}
          </Table>
        </div>
      </div>
      {claimPolicyData?.ctas && !addDeptOrProject ? (
        <div className="absolute bottom-0 gap-5 px-3 slider-footer">
          <div
            className={`flex items-center ${
              claimPolicyData?.ctas?.[0] === POLICY_CTA.EDIT_GLOBAL_POLICY
                ? ""
                : "justify-end"
            } gap-4 p-4`}
          >
            {claimPolicyData?.ctas?.[0] === POLICY_CTA.EDIT_GLOBAL_POLICY ? (
              <NonEditableSettingSliderFooter
                linkText="settings.editableOnlyInReimbursementSetting.linkText"
                linkRedirect={`${ROUTES.reimbursement.settings.spendControl.absolutePath}?${SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.claimPolicy}=preview`}
              />
            ) : projectOrDepartmentId &&
              claimPolicyData?.ctas?.[0] === POLICY_CTA.EDIT_POLICY ? (
              <Button
                label="settings.spendControls.revertToCompanyPolicy"
                classes="w-20 text-sm"
                variant="tertiary"
                onClick={() => {
                  setResetPolicy(true);
                  setShowDisableModal(true);
                }}
              />
            ) : null}
            {claimPolicyData?.ctas?.[0] !== POLICY_CTA.EDIT_GLOBAL_POLICY ? (
              <Button
                label={`settings.spendControls.${claimPolicyData?.ctas?.[0]}`}
                classes="w-16 text-sm"
                variant="primary"
                onClick={() =>
                  claimPolicyData?.ctas?.[0] === POLICY_CTA.EDIT_POLICY
                    ? onEditPolicyHandler()
                    : onCustomisePolicyHandler()
                }
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {/* TODO: translation */}
      <DisableConfirmation
        header="settings.sliders.claimPolicies.companyClaimPolicy.confirmModal.title"
        titleBody={
          <ul className="px-8 py-4 font-semibold list-disc">
            <li>
              <Text translationKey="settings.sliders.claimPolicies.companyClaimPolicy.confirmModal.desc1" />
            </li>
            <li>
              <Text translationKey="settings.sliders.claimPolicies.companyClaimPolicy.confirmModal.desc2" />
            </li>
          </ul>
        }
        desc="settings.sliders.claimPolicies.companyClaimPolicy.confirmModal.desc"
        showModal={showDisableModal}
        setShowModal={setShowDisableModal}
        onSubmitClick={() => {
          submitPolicy();
        }}
        inputNote="settings.sliders.claimPolicies.companyClaimPolicy.confirmModal.inputNote"
        labelText="settings.sliders.claimPolicies.companyClaimPolicy.confirmModal.labelText"
        submitBtnType="default"
        submitBtnLabel="misc.submit"
      />
    </div>
  ) : (
    <div className="mt-6">
      <LoaderSkeleton count={5} classes="py-4 mx-9 my-4" fullWidth />
    </div>
  );
};
CompanyClaimPolicyCategories.propTypes = {
  searchParams: PropTypes.string,
  setSearchParams: PropTypes.func,
  addCategoriesHandler: PropTypes.func,
  dropdownCategoryName: PropTypes.array,
  getReimbursementSettingsClaimPolicy: PropTypes.func,
  claimPolicyData: PropTypes.object,
  onEditPolicyHandler: PropTypes.func,
  onCustomisePolicyHandler: PropTypes.func,
  entity: PropTypes.string,
};
export default CompanyClaimPolicyCategories;
