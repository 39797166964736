import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";

import { ROLE_TITLES_LABELS } from "@/constants/user";

/**
 * A list that shows sections and individual items
 *
 * The only user action here is 'Deletion' (ON by default)
 *
 * This can be configured (read propTypes before)
 *    1. If deletion is not allowed for an item. Pass deletable as false (default true)
 *    2. If deletion happens for an indiviual item, you will recieve the `id` (of data) in the callback.
 *    3. If deletion happens for a group item,      you will receive an array of `ids` (of data) in the callback
 *  Note: the itemIds received in props are not returned in the callback, only data ids (inside of itemData ) are.
 *
 * @param {String} titleText
 * @param {Array<Object>} list
 * @param {Function} onDeleteHandler
 * @param {Boolean}  readOnly
 */
export default function PeopleList({
  titleText = "billPay.settings.sliders.paymentInitiators.addPaymentInitiators.listOfPaymentInitiators",
  list,
  onDeleteHandler = (idOrIds) => {},
  readOnly = false, // no deletions (hides the icon)
  classes = "bg-neutral-50 p-4 rounded-lg",
  disabledDeleteButton = false,
  titleTextTranslationProps = {},
  tooltipText = "payroll.payrollSettings.payrollSliders.enablePayroll.atleastOnePayrollWarning",
}) {
  const { t } = useTranslation();
  return (
    <div className={`flex flex-col gap-4 ${classes}`}>
      {titleText ? (
        <div className="text-sm font-semibold text-neutral-500">
          <Text
            translationKey={titleText}
            translationProps={titleTextTranslationProps}
          />
          :
        </div>
      ) : null}
      {/*  */}

      {list.map((listItem, index) => {
        const {
          itemId,
          isGroup = false,
          deletable = true,
          itemData,
          otherComponents = [],
        } = listItem;
        // 'Admins' or 'Rajith Shaji'
        const mainText = isGroup ? itemData?.mainText : itemData?.displayName;

        // '12 people' vs 'AP clerk • P&T • India'
        const subText = isGroup
          ? itemData?.subText
          : [
              t(ROLE_TITLES_LABELS[itemData?.role ?? itemData?.roles?.[0]]),
              itemData?.departmentName,
              itemData?.locationName,
            ]
              .filter((textItem) => textItem?.length > 0)
              .join(" • ");

        return (
          <div
            className="flex items-center justify-between"
            key={itemId ?? index}
          >
            <ProfileWidget
              truncateBasedOnSize={false}
              avatarColor={itemData?.colorCode}
              name={mainText}
              img={itemData?.avatarUrl}
              textClasses="font-semibold"
              iconComponent={
                isGroup && !!itemData?.icon ? (
                  <span className="grid p-2 rounded-full place-items-center w-9 h-9 bg-primary-50">
                    <Icon name={itemData?.icon} className="text-primary-500" />
                  </span>
                ) : null
              }
            >
              <span className="text-sm font-medium cursor-pointer text-neutral-500">
                {subText}
              </span>
            </ProfileWidget>

            {!readOnly && deletable ? (
              <div>
                {disabledDeleteButton ? (
                  <TextWithTooltipIcon
                    icon="Delete"
                    tooltipText={tooltipText}
                    direction="top"
                    tooltipClasses="text-neutral-500"
                    iconClasses="text-neutral-300 mx-1 cursor-pointer "
                  />
                ) : (
                  <Icon
                    name="Delete"
                    handleClick={() =>
                      onDeleteHandler(isGroup ? itemData?.ids : itemData?.id)
                    }
                    className="text-neutral-500 mx-1 my-[3px] hover:cursor-pointer active:text-neutral-800  "
                  />
                )}
                {otherComponents?.length
                  ? otherComponents?.map((item) => item)
                  : null}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

PeopleList.propTypes = {
  titleText: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.any,
      deletable: PropTypes.bool,
      isGroup: PropTypes.bool,

      itemData: PropTypes.oneOfType([
        PropTypes.shape({
          // isGroup false, i.e. individual row
          id: PropTypes.any,
          displayName: PropTypes.string,
          avatarUrl: PropTypes.string,
          departmentName: PropTypes.string,
          locationName: PropTypes.string,
          colorCode: PropTypes.string,
          role: PropTypes.string,
          roles: PropTypes.arrayOf(PropTypes.string),
        }),
        PropTypes.shape({
          // isGroup true
          ids: PropTypes.arrayOf(PropTypes.any),
          id: PropTypes.any, // not used usually, left if this will ever be used for items other than 'people'
          mainText: PropTypes.string,
          subText: PropTypes.string,
          icon: PropTypes.string,
        }),
      ]),
      mainText: PropTypes.string,
      subText: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  onDeleteHandler: PropTypes.func,
  readOnly: PropTypes.bool,
  classes: PropTypes.string,
  disabledDeleteButton: PropTypes.bool,
  titleTextTranslationProps: PropTypes.object,
  tooltipText: PropTypes.string,
};
