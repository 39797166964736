import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

function InvoiceLoader() {
  return [...Array(10)].map((val, i) => (
    <tr className="" key={i}>
      <td className="flex items-center gap-3 ml-4">
        <LoaderSkeleton size={[20, 20]} />
        <LoaderSkeleton type="circle" />
        <LoaderSkeleton size={[20, 200]} />
      </td>
      <td>
        <LoaderSkeleton size="sm" />
      </td>
      <td>
        <LoaderSkeleton size="sm" />
      </td>
      <td>
        <LoaderSkeleton size="sm" />
      </td>
      <td>
        <LoaderSkeleton size="sm" />
      </td>
      <td>
        <LoaderSkeleton size="sm" />
      </td>
      <td>
        <LoaderSkeleton size="sm" />
      </td>
      <td>
        <LoaderSkeleton size="sm" />
      </td>
    </tr>
  ));
}

export default InvoiceLoader;

// 200, 200, 200, 200, 120, 120, 300, 145
