import PropTypes from "prop-types";

export default function PopupContainer({ children, classes }) {
  return (
    <div
      className={`border border-neutral-200 shadow-lg rounded-lg px-5 py-4 bg-white ${classes}`}
    >
      {children}
    </div>
  );
}

PopupContainer.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
