import { createSelector } from "@reduxjs/toolkit";

import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
} from "@/utils/constants/filters";

import { OKAY } from "@/constants/expense";

import { isDepartmentClientBudgetTypeSelector } from "./client";
import {
  selectedUserAssignedCopilotsSelector,
  userDepartmentIdSelector,
  userSelector,
} from "./user";

export const selectExpensesStore = (store) => store.expenses;

export const expensesSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.expenses
);

export const expensesSliderSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.sliderExpenseList
);

export const expensesListSelector = createSelector(
  expensesSelector,
  (expenses) => expenses?.list
);

export const expensesSliderListSelector = createSelector(
  expensesSliderSelector,
  (expensesSlider) => expensesSlider?.list
);

export const expensesSliderListIsFetchingSelector = createSelector(
  expensesSliderSelector,
  (expensesSlider) => expensesSlider?.isFetching
);

export const expensesSliderCurrentPageSelector = createSelector(
  expensesSliderSelector,
  (expensesSlider) => expensesSlider?.page
);

export const expensesSliderSelectedTabSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.selectedExpenseTab
);

export const expenseListOnSliderViewStateInfoSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.expenseListOnSliderViewState
);

export const expensesSliderOnResetBooleanSelector = createSelector(
  expenseListOnSliderViewStateInfoSelector,
  (expenseListOnSliderViewState) =>
    expenseListOnSliderViewState?.expenseListSliderPaginationResetBool
);

export const expensesSliderSelectedExpenseIdSelector = createSelector(
  expenseListOnSliderViewStateInfoSelector,
  (expenseListOnSliderViewState) =>
    expenseListOnSliderViewState?.selectedExpenseItemId
);

export const expensesTotalSelector = createSelector(
  expensesSelector,
  (expenses) => expenses?.total
);
export const isExpensesFetchingSelector = createSelector(
  expensesSelector,
  (expenses) => expenses?.isFetching
);

export const expensesHasMoreSelector = createSelector(
  expensesSelector,
  (expenses) => expenses?.hasMore
);

export const expensesSliderHasMoreSelector = createSelector(
  expensesSliderSelector,
  (expensesSlider) => expensesSlider?.hasMore
);

export const isFetchingExpenseSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.isFetchingExpense
);

export const spendOverviewDataSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.spendOverviewData
);
export const isSpendOverviewFetchingSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.isSpendOverviewFetching
);
export const creditOverviewDataSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.creditOverviewData
);
export const isCreditOverviewFetchingSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.isCreditOverviewFetching
);
export const missingDetailsSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.missingDetails.data
);
export const missingDetailsUserIdsSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.missingDetails?.userIds
);
export const isFetchingMissingDetailsSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.isFetchingMissingDetails
);

export const isFetchingExpenseActivitySelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.isFetchingExpenseActivity
);

export const selectedExpenseActivitySelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.selectedExpenseActivity
);

export const selectedExpenseSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.selectedExpense
);

export const repaymentsSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.repayments
);

export const isFetchingRepaymentsSelector = createSelector(
  repaymentsSelector,
  (repayment) => repayment.isFetching
);
export const repaymentListSelector = createSelector(
  repaymentsSelector,
  (repayment) => repayment.list
);
export const repaymentHasMoreSelector = createSelector(
  repaymentsSelector,
  (repayment) => repayment.hasMore
);
export const creditDetailsSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.creditDetails
);

export const isFetchingCreditDetailsSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.isFetchingCreditDetails
);

export const sliderpageSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.sliderpage
);
export const sliderPageTypeSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.sliderPageType
);
export const splitExpenseSliderSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.splitExpenseSliderId
);

export const expenseOverviewDetailsSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.expenseOverviewDetails
);
export const isExpenseOverviewDetailsSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.isExpenseOverviewDetails
);
export const filtersKeySelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.filtersKey
);

export const unsettledListSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.unsettledExpenses.list
);
export const isFetchingUnsettledSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.unsettledExpenses.isFetching
);
export const expenseSplitSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.splitExpense
);
export const isFetchingSplitExpenseSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.isFetchingSplitExpense
);
export const isBulkApproveInProgressSelector = createSelector(
  selectExpensesStore,
  (expense) => expense.isBulkApprovalInProgress
);
export const isFlagExpenseInProcessSelector = createSelector(
  selectExpensesStore,
  (expense) => expense.isFlagExpenseInProcess
);
export const isSplitExpenseSuccessSelector = createSelector(
  selectExpensesStore,
  (expense) => expense.isSplitExpenseSuccess
);
export const selectedExpenseIsApprovedSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses?.selectedExpense?.transactionStatus === OKAY
);
export const ExpenseFilterSelector = createSelector(
  selectExpensesStore,
  (expense) => expense.filters
);
export const merchantDetailSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.globalMerchantDetails
);
export const missingDetailsCountSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.missingDetailsCount
);
export const expenseOverviewFiltersKeysFilterByClientTypeDepartmentSelector =
  createSelector(
    selectExpensesStore,
    isDepartmentClientBudgetTypeSelector,
    (expense, isDepartmentClient) =>
      expense.filtersKey.overview.filter((key) =>
        key === AVAILABLE_FILTER_KEYS.department ? isDepartmentClient : true
      )
  );

export const expenseOverviewPageFiltersSelector = createSelector(
  expenseOverviewFiltersKeysFilterByClientTypeDepartmentSelector,
  userDepartmentIdSelector,
  (filters, departmentId) =>
    filters?.map((key) =>
      key === AVAILABLE_FILTER_KEYS.department
        ? {
            ...AVAILABLE_FILTERS[key],
            props: { ...AVAILABLE_FILTERS[key].props, value: departmentId },
          }
        : AVAILABLE_FILTERS[key]
    )
);
export const expensesNeedsReviewFiltersKeysFilterByClientTypeDepartmentSelector =
  createSelector(
    selectExpensesStore,
    isDepartmentClientBudgetTypeSelector,
    (expense, isDepartmentClient) =>
      expense.filtersKey.needsReview.filter((key) =>
        key === AVAILABLE_FILTER_KEYS.department ? isDepartmentClient : true
      )
  );

export const expenseNeedsReviewPageFiltersSelector = createSelector(
  expensesNeedsReviewFiltersKeysFilterByClientTypeDepartmentSelector,
  userDepartmentIdSelector,
  (filters, departmentId) =>
    filters.map((key) =>
      key === AVAILABLE_FILTER_KEYS.department
        ? {
            ...AVAILABLE_FILTERS[key],
            props: { ...AVAILABLE_FILTERS[key].props, value: departmentId },
          }
        : AVAILABLE_FILTERS[key]
    )
);

export const expenseFlaggedPageFiltersSelector = createSelector(
  selectExpensesStore,
  (expenses) => expenses.filtersKey.flagged.map((key) => AVAILABLE_FILTERS[key])
);

export const expenseDeclinesPageFiltersSelector = createSelector(
  selectExpensesStore,
  (expense) => expense.filtersKey.declines.map((val) => AVAILABLE_FILTERS[val])
);

export const isFetchingStatisticsSelector = createSelector(
  selectExpensesStore,
  (expense) => expense?.isFetchingStatistics
);
export const isDeclineDetailsFetchingSelectors = createSelector(
  selectExpensesStore,
  (expense) => expense.isDeclineDetailsFetching
);
export const declineDetailsSelector = createSelector(
  selectExpensesStore,
  (expense) => expense?.declineDetails
);
export const isValidAccountingSliderSelector = createSelector(
  selectExpensesStore,
  (expense) => expense?.isValidAccountingSlider
);

export const isLinkedToChangedExpenseInfoSelector = createSelector(
  selectExpensesStore,
  (cards) => cards.changedLinkedToStatus
);

export const isLinkedToChangedExpenseSelector = createSelector(
  isLinkedToChangedExpenseInfoSelector,
  (isLinkedToChangedInfo) => isLinkedToChangedInfo?.isChanged
);

export const isLinkedToChangedExpenseInpgressSelector = createSelector(
  isLinkedToChangedExpenseInfoSelector,
  (isLinkedToChangedInfo) => isLinkedToChangedInfo?.inProgress
);

export const myVolopayExpenseFilters = createSelector(
  selectExpensesStore,
  (expense) => expense.myVolopayFiltersKey
);
//  created a common function to use in myVolopayExpenseFilters
function getCopilotWidthOtherFilters(filters, currentUser, copilots) {
  return filters
    ?.filter((item) =>
      item === AVAILABLE_FILTER_KEYS.copilots ? copilots?.length : true
    )
    ?.map((key) => {
      if (key === AVAILABLE_FILTER_KEYS.copilots) {
        const copilotsObj = AVAILABLE_FILTERS[key];
        copilotsObj.props.options = [
          {
            label: "myVolopay.gettingStarted.myExpense",
            value: currentUser?.id,
          },
          ...(copilots?.map((copilot) => ({
            label: "myVolopay.gettingStarted.userExpense",
            labelTranslationProps: { user: copilot.fullName },
            value: copilot.id,
          })) ?? []),
        ];
        return copilotsObj;
      }
      return AVAILABLE_FILTERS[key];
    });
}

export const overviewMyVolopayExpenseFilters = createSelector(
  myVolopayExpenseFilters,
  selectedUserAssignedCopilotsSelector,
  userSelector,
  (filters, copilots, currentUser) =>
    getCopilotWidthOtherFilters(filters?.overview, currentUser, copilots)
);

export const flaggedMyVolopayExpenseFilters = createSelector(
  myVolopayExpenseFilters,
  selectedUserAssignedCopilotsSelector,
  userSelector,
  (filters, copilots, currentUser) =>
    getCopilotWidthOtherFilters(filters?.flagged, currentUser, copilots)
);

export const declinesVolopayExpenseFilters = createSelector(
  myVolopayExpenseFilters,
  selectedUserAssignedCopilotsSelector,
  userSelector,
  (filters, copilots, currentUser) =>
    getCopilotWidthOtherFilters(filters?.declines, currentUser, copilots)
);
export const isUndoSplitInProgressSelector = createSelector(
  selectExpensesStore,
  (expense) => expense.isUndoSplitInProgress
);

export const missingDetailsInfoSelector = createSelector(
  selectExpensesStore,
  (expense) => expense?.missingDetails
);

export const expandStatusIndexSelector = createSelector(
  missingDetailsInfoSelector,
  (missingDetails) => missingDetails?.expandStatusIndex
);

export const missingDetailsUserRemindStatusSelector = createSelector(
  missingDetailsInfoSelector,
  (missingDetails) => missingDetails?.userRemindStatus
);

export const selectMissingDetailsPeopleListSelector = createSelector(
  selectExpensesStore,
  (expense) => expense?.missingDetailsPeople
);

export const missingDetailsListSelector = createSelector(
  selectMissingDetailsPeopleListSelector,
  (missingDetailsListInfo) => missingDetailsListInfo?.list
);

export const hasMoreMissingDetailsUsersSelector = createSelector(
  selectMissingDetailsPeopleListSelector,
  (missingDetailsListInfo) => missingDetailsListInfo?.hasMore
);

export const isFetchingMissingDetailsUsersSelector = createSelector(
  selectMissingDetailsPeopleListSelector,
  (missingDetailsListInfo) => missingDetailsListInfo?.isFetching
);

export const dataActionInProgressIdSelector = createSelector(
  selectExpensesStore,
  (expense) => expense.dataActionInProgressId
);
