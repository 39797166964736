import PropTypes from "prop-types";

import BeneficiaryBankCountry from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/BeneficiaryBankCountry";
import BeneficiaryCountry from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/BeneficiaryCountry";
import BeneficiaryCurrency from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider/common/BeneficiaryCurrency";

export default function XenditBankDetails({
  values,
  errors,
  handleChange,
  fieldsFilters,
  setValues,
  isEditMode,
  disabled,
  context,
  provider,
  inPayrollContext,
}) {
  return (
    <div>
      <div className="flex flex-col gap-6 mt-6">
        <BeneficiaryCountry
          {...{
            values,
            errors,
            handleChange,
            isEditMode,
            setValues,
            disabled,
            relevantValues: fieldsFilters?.relevantCountries,
            context,
          }}
        />
        <BeneficiaryBankCountry
          {...{
            values,
            errors,
            handleChange,
            setValues,
            disabled,
            isEditMode,
            context,
          }}
        />
        <BeneficiaryCurrency
          {...{
            values,
            errors,
            handleChange,
            setValues,
            isEditMode,
            disabled,
            relevantValues: fieldsFilters?.relevantCurrencies,
            context,
            provider,
          }}
        />
      </div>
    </div>
  );
}

XenditBankDetails.propTypes = {
  values: PropTypes.object, // 'values' returned by useForm
  errors: PropTypes.object, // 'errors' returned by useForm
  handleChange: PropTypes.func, // 'handleChange' returned by useForm
  setValues: PropTypes.func, // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough) // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough)
  isEditMode: PropTypes.bool,
  fieldsFilters: PropTypes.object,
  disabled: PropTypes.bool,
  context: PropTypes.string,
  provider: PropTypes.string,
  inPayrollContext: PropTypes.bool,
};
