import PropTypes from "prop-types";
import { useState } from "react";
import { usePopper } from "react-popper";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Popup from "@/components/core/Popup";
import Text from "@/components/core/Text";
import vToast from "@/utils/vToast";

/* Copy to clipboard */

/**
 * Copies the provided text to the clipboard.
 *
 * @param {string} text - The text to be copied to the clipboard.
 * @param {boolean} showToaster - show successful toast?
 *
 * @example copyToClipboard("This text will be copied to the clipboard.");
 */
export const copyToClipboard = (text, showToaster = true) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    if (successful)
      if (showToaster)
        vToast({ title: "misc.copied", description: "misc.copiedToClipboard" });
  } catch (err) {
    vToast({
      title: "misc.someErrorOccurred",
      description: "misc.pleaseTryAgain",
      constant: "danger",
    });
  }

  // Removing textarea element
  document.body.removeChild(textArea);
};

// component
export default function CopytoClipboard({
  text,
  children,
  timeoutms = 1000,
  iconClasses = "text-primary-500",
  disabled = false,
  classes,
}) {
  // for popper
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {});

  const [showPopup, setShowPopup] = useState(false);

  const copyToClipboardHandler = () => {
    copyToClipboard(text, false);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 1000);
  };

  return (
    <span
      onClick={copyToClipboardHandler}
      className={`relative hover:cursor-pointer ${classes}`}
    >
      {children ? (
        children
      ) : (
        <Button
          classes="w-fit h-fit border-none my-1"
          variant="tertiary"
          preIcon="Copy"
          iconClasses={iconClasses}
          disabled={disabled}
        />
      )}

      {showPopup ? (
        <Popup
          keepTooltipInside="#tooltip-inside"
          noPortaling
          containerClasses="bottom-full right-0 mb-1 absolute"
          attr={{ ...attributes.popper }}
          popupRef={setPopperElement}
        >
          <div className="flex items-center gap-1 py-1 -m-3">
            <Icon
              name="Done"
              className="rounded-full text-white w-4 h-4 bg-success-500 p-[1px]"
            />
            <Text
              translationKey="misc.copied"
              classes="text-neutral-800 text-sm font-medium"
            />
          </div>
        </Popup>
      ) : null}
    </span>
  );
}

CopytoClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  timeoutms: PropTypes.number,
  iconClasses: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
};
