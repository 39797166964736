import Text from "@/components/core/Text";

import RadioWithLabelField from "@/components/Cards/Sliders/OrderPhysicalCardSlider/RadioWithLabelField";

export default function SelectControlSection({
  radioOptions,
  classes,
  name,
  handleChange,
  error,
  value,
}) {
  return (
    <div className={classes}>
      <Text
        translationKey="cards.cardSlider.selectControl"
        classes="text-xl not-italic font-bold leading-9"
      />
      <div className="flex flex-row gap-4 mt-4">
        {radioOptions?.map((radioOption, index) => (
          <RadioWithLabelField
            radioLabel={radioOption.heading}
            radioDescription={radioOption.description}
            radioValue={radioOption.value}
            name={name}
            handleChange={handleChange}
            error={error}
            sectionValue={value}
            key={`${index}.${radioOption?.value}`}
          />
        ))}
      </div>
    </div>
  );
}
