import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { moveToNextPage, moveToPrevPage } from "@/store/reducers/fileViewer";

import {
  currentPageNumberSelector,
  totalPageFilesSelector,
} from "@/store/selectors/fileViewer";

import Badge from "@/components/core/Badge";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

/**
 * Renders the ZoomAndPageButton component.
 *
 * @param {number} numPages - The number of pages.
 * @param {boolean} isZoomInDisable - Indicates if the zoom-in button is disabled.
 * @param {boolean} isZoomOutDisable - Indicates if the zoom-out button is disabled.
 * @param {function} handleZoomIn - The function to handle the zoom-in button click.
 * @param {function} handleZoomOut - The function to handle the zoom-out button click.
 * @param {boolean}  showPage=true - Indicates if the page number should be shown.
 */
function ZoomAndPageButton({
  numPages,
  isZoomInDisable,
  isZoomOutDisable,
  handleZoomIn,
  handleZoomOut,
  showPage = true,
  showOCRScannedBadge = false,
  showRotateButton = false,
  handleRotate = () => {},
}) {
  const dispatch = useDispatch();
  const currentPage = useSelector(currentPageNumberSelector);
  const totalPages = useSelector(totalPageFilesSelector);
  const isNextDisable = currentPage === totalPages - 1;
  const isPrevDisable = currentPage === 0;

  return (
    <div className="absolute justify-center w-full z-50 flex bottom-[1.5%]">
      <div className="flex rounded-lg bg-neutral-800">
        {/* Current page number */}
        {showPage ? (
          <Text
            classes="flex items-center justify-center px-3 text-white border-r-2 border-neutral-400"
            translationKey="misc.pageX"
            translationProps={{ pageNum: numPages }}
          />
        ) : null}

        {/* Zoom controls */}
        <button
          className="w-[44px] h-[44px] flex items-center justify-center"
          onClick={isZoomOutDisable ? () => {} : handleZoomOut}
        >
          <Icon
            className={isZoomOutDisable ? "text-neutral-400" : "text-white"}
            name="Minus"
          />
        </button>
        {/*  */}
        <div className="w-[44px] h-[44px] flex items-center justify-center">
          <Icon className="text-white" name="Zoom" />
        </div>

        <button
          className="w-[44px] h-[44px] flex items-center justify-center"
          onClick={isZoomInDisable ? () => {} : handleZoomIn}
        >
          <Icon
            className={isZoomInDisable ? "text-neutral-400" : "text-white"}
            name="Add"
          />
        </button>
        {/* rotate button */}
        {showRotateButton ? (
          <div className="border-l-2 border-neutral-400">
            <button
              className="w-[44px] h-[44px] border-l-2 border-r-2 flex items-center justify-center"
              onClick={handleRotate}
            >
              <Icon className="text-white" name="Rotate" />
            </button>
          </div>
        ) : null}
        {/* Page nav arrows */}
        {totalPages > 1 ? (
          <div className="flex items-center gap-2 px-3 border-l-2 border-neutral-400">
            <button
              onClick={() => {
                if (!isPrevDisable) dispatch(moveToPrevPage());
              }}
            >
              <Icon
                className={`w-5 h-5 ${
                  isPrevDisable ? "text-neutral-400" : "text-white"
                }`}
                name="IosArrowBack"
              />
            </button>
            <button
              onClick={() => {
                if (!isNextDisable) dispatch(moveToNextPage());
              }}
            >
              <Icon
                className={`w-5 h-5 ${
                  isNextDisable ? "text-neutral-400" : "text-white"
                }`}
                name="IosArrowForward"
              />
            </button>
          </div>
        ) : null}
      </div>

      {/* Scanned file (indicates OCR file) */}
      {showOCRScannedBadge ? (
        <Badge
          classes="absolute right-6 top-1/4 p-3 gap-2"
          preIcon="Bolt"
          iconClasses="text-warning-400"
          translationKey="misc.scannedFile"
          variant="neutral"
          textClasses="text-nenurtal-700 text-xs tracking-medium font-bold leading-[18px]"
        />
      ) : null}
    </div>
  );
}
export default ZoomAndPageButton;

ZoomAndPageButton.propTypes = {
  numPages: PropTypes.number,
  isZoomInDisable: PropTypes.bool,
  isZoomOutDisable: PropTypes.bool,
  handleZoomIn: PropTypes.func,
  handleZoomOut: PropTypes.func,
  showPage: PropTypes.bool,
  showOCRScannedBadge: PropTypes.bool,
  showRotateButton: PropTypes.bool,
  handleRotate: PropTypes.func,
};
