import { createSelector } from "@reduxjs/toolkit";

import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
} from "@/utils/constants/filters";

import { OKAY } from "@/constants/expense";

import { isDepartmentClientBudgetTypeSelector } from "./client";
import { userDepartmentIdSelector } from "./user";

export const selectQrPaymentStore = (store) => store.qrPayments;

// payments in qr-payments
export const qrPaymentsOverviewDetailsSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments?.qrPaymentsOverviewDetails
);

export const isQrPaymentsOverviewDetailsSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments?.isQrPaymentsOverviewDetails
);

export const qrPaymentsMissingDetailsSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments?.missingDetails
);
export const isFetchingMissingDetailsSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.isFetchingMissingDetails
);
export const qrPaymentsOverviewFiltersKeysFilterByClientTypeDepartmentSelector =
  createSelector(
    selectQrPaymentStore,
    isDepartmentClientBudgetTypeSelector,
    (qrPayments, isDepartmentClient) =>
      qrPayments.filtersKey.payments.overview.filter((key) =>
        key === AVAILABLE_FILTER_KEYS.department ? isDepartmentClient : true
      )
  );
export const qrPaymentsOverviewPageFiltersSelector = createSelector(
  qrPaymentsOverviewFiltersKeysFilterByClientTypeDepartmentSelector,
  userDepartmentIdSelector,
  (filters, departmentId) =>
    filters?.map((key) =>
      key === AVAILABLE_FILTER_KEYS.department
        ? {
            ...AVAILABLE_FILTERS[key],
            props: { ...AVAILABLE_FILTERS[key].props, value: departmentId },
          }
        : AVAILABLE_FILTERS[key]
    )
);
export const paymentsSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments?.qrPayments
);
export const isFetchingQrPayments = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.isFetchingQrPayments
);
export const qrPaymentsBulkApproveSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.isBulkApprovalInProgress
);
export const selectedQrpaymentSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.selectedQrpayment
);
export const qrPaymentSplitSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.splitQrPayment
);
export const isFetchingQrPaymentActivitySelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.isFetchingQrPaymentActivity
);
export const iisFetchingSplitQrPaymentSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.isFetchingSplitQrPayment
);
export const isFlagQrPaymentInProcessSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.isFlagQrPaymentInProcess
);
export const qrPaymentsNeedsReviewFiltersKeysFilterByClientTypeDepartmentSelector =
  createSelector(
    selectQrPaymentStore,
    isDepartmentClientBudgetTypeSelector,
    (qrPayments, isDepartmentClient) =>
      qrPayments.filtersKey.payments.needsReview.filter((key) =>
        key === AVAILABLE_FILTER_KEYS.department ? isDepartmentClient : true
      )
  );

export const qrPaymentsNeedsReviewPageFiltersSelector = createSelector(
  qrPaymentsNeedsReviewFiltersKeysFilterByClientTypeDepartmentSelector,
  userDepartmentIdSelector,
  (filters, departmentId) =>
    filters.map((key) =>
      key === AVAILABLE_FILTER_KEYS.department
        ? {
            ...AVAILABLE_FILTERS[key],
            props: { ...AVAILABLE_FILTERS[key].props, value: departmentId },
          }
        : AVAILABLE_FILTERS[key]
    )
);

export const qrPaymentsFlaggedPageFiltersSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) =>
    qrPayments.filtersKey.payments.flagged.map((key) => AVAILABLE_FILTERS[key])
);
export const qrPaymentsFailuresPageFiltersSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) =>
    qrPayments.filtersKey.payments.failures.map((key) => AVAILABLE_FILTERS[key])
);
export const isFailureDetailsFetchingSelectors = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.isFailureDetailsFetching
);
export const failureDetailsSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments?.failureDetails
);
export const selectedQrPaymentActivitySelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments.selectedQrPaymentActivity
);
export const selectedQrPaymentIsApprovedSelector = createSelector(
  selectedQrpaymentSelector,
  (qrPayments) => qrPayments?.transactionStatus === OKAY
);
export const isSplitQrPaymentSuccessSelector = createSelector(
  selectQrPaymentStore,
  (qrPayments) => qrPayments?.isSplitQrPaymentSuccess
);
export const qrPaymentsListSelector = createSelector(
  paymentsSelector,
  (qrPayments) => qrPayments?.list
);

export const qrPaymentsHasMoreSelector = createSelector(
  paymentsSelector,
  (qrPayemnts) => qrPayemnts.hasMore
);
export const qrPaymentsTotalSelector = createSelector(
  paymentsSelector,
  (qrPayemnts) => qrPayemnts?.total
);

// user wallets
export const filtersKeySelector = createSelector(
  selectQrPaymentStore,
  (qrPayemnts) => qrPayemnts.filtersKey
);
export const UserWalletFilterKeySelector = createSelector(
  filtersKeySelector,
  (filterkeys) => filterkeys.userWallets
);
export const allUserWalletsPageFiltersSelector = createSelector(
  UserWalletFilterKeySelector,
  (keys) => keys.all?.map((key) => AVAILABLE_FILTERS[key])
);
export const yetToActivateUserWalletsPageFiltersSelector = createSelector(
  UserWalletFilterKeySelector,
  (keys) => keys.yetToActivate?.map((key) => AVAILABLE_FILTERS[key])
);
export const userWalletSelector = createSelector(
  selectQrPaymentStore,
  (qrPayemnts) => qrPayemnts.userWallet
);
export const selectedUserWalletSelector = createSelector(
  selectQrPaymentStore,
  (qrPayemnts) => qrPayemnts.selectedUserWallet
);
export const selectedUserWalletCtaBtnsSelector = createSelector(
  selectedUserWalletSelector,
  (qrPayemnts) => qrPayemnts?.allowedCtas
);
export const UserWalletListSelector = createSelector(
  userWalletSelector,
  (userWallet) => userWallet.list
);
export const isFetchingUserWalletListSelector = createSelector(
  userWalletSelector,
  (userWallet) => userWallet.isFetching
);

export const hasMoreUserWalletSelector = createSelector(
  userWalletSelector,
  (userWallet) => userWallet.hasMore
);

export const totalUserWalletSelector = createSelector(
  userWalletSelector,
  (userWallet) => userWallet.total
);

export const pageUserWalletSelector = createSelector(
  userWalletSelector,
  (userWallet) => userWallet.page
);
