import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchLoggedInDevices,
  fetchTrustedDevices,
  removeSession,
  removeTrustedDevice,
  revokeAllLoggedInDevices,
  revokeAllTrustedDevices,
} from "@/store/reducers/user";

import {
  loggedInDevicesSelector,
  mfaMethodSelector,
  trustedDevicesSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import PopupContainer from "@/components/core/Popup/PopupContainer";
import Text from "@/components/core/Text";
import { dateToTimeStampString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { SECURITY_SETTINGS_VERIFICATION_MODES } from "@/constants/user";

import DeviceListing from "./DeviceListing";
import SecurityPopup from "./SecurityPopups";
import TwoStepVerification from "./TwoStepVerification";

function SecuritySettings() {
  const ref = useLeftHeaderTitle({
    title: "security.heading",
  });
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPopup, setShowPopup] = useState(true);
  const [popupContent, setPopupContent] = useState(null);
  const trustedDevicesConfig = useSelector(trustedDevicesSelector);
  const mfaMethod = useSelector(mfaMethodSelector);
  const currentUser = useSelector(userSelector);
  const loggedInDevices = useSelector(loggedInDevicesSelector);
  const loggedInDevicesConfig = loggedInDevices?.map((item) => ({
    id: item?.clientId,
    userAgent: item?.name,
    lastActivityAt: item?.lastSignInAt,
  }));

  const verificationConfig = [
    {
      id: 1,
      icon: "Mobile",
      key: SECURITY_SETTINGS_VERIFICATION_MODES.MOBILE,
      verificationMode: "security.mobileVerificationMode",
      isDefault: mfaMethod === SECURITY_SETTINGS_VERIFICATION_MODES.MOBILE,
      description: "security.mobileVerificationModeDesc",
    },
    {
      id: 2,
      key: SECURITY_SETTINGS_VERIFICATION_MODES.EMAIL,
      icon: "Email",
      verificationMode: "security.email",
      isDefault: mfaMethod === SECURITY_SETTINGS_VERIFICATION_MODES.EMAIL,
      description: "security.emailVerificationModeDesc",
    },
    {
      id: 3,
      key: SECURITY_SETTINGS_VERIFICATION_MODES.GAUTH,
      icon: "Authenticator",
      verificationMode: "security.googleAuthenticator",
      isDefault: mfaMethod === SECURITY_SETTINGS_VERIFICATION_MODES.GAUTH,
      description: "security.googleAuthenticatorDesc",
    },
  ];

  const handleResestPassword = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.security.resetPassword, true);
    setSearchParams(searchParams);
  };

  const handleShowPopup = (action) => {
    setShowPopup(true);
    switch (action) {
      case "logoutAll":
        setPopupContent(
          <PopupContainer>
            <SecurityPopup
              heading="security.logoutFromAllDevices"
              description="You will be logged out of all the devices"
              onCancel={() => setShowPopup(false)}
              onConfirm={() => {
                dispatch(
                  revokeAllLoggedInDevices({
                    onSuccess: () => {
                      setShowPopup(false);
                    },
                  })
                );
              }}
            />
          </PopupContainer>
        );
        break;
      case "revokeAll":
        setPopupContent(
          <PopupContainer>
            <SecurityPopup
              heading="security.revokeAccessAll"
              description="security.revokeAccessAllDesc"
              onCancel={() => setShowPopup(false)}
              onConfirm={() => {
                // TODO: call revoke all from all api here
                dispatch(
                  revokeAllTrustedDevices({
                    onSuccess: () => {
                      setShowPopup(false);
                    },
                  })
                );
              }}
            />
          </PopupContainer>
        );
        break;

      default:
        return null;
    }
  };

  const handleTwoStepVerification = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.security.twoStepVerification,
      true
    );
    setSearchParams(searchParams);
  };

  const handleRemoveTrustedDevice = async (key, id) => {
    dispatch(removeTrustedDevice({ id }));
  };

  const handleLogoutFromSession = async (key, id) => {
    dispatch(removeSession({ client_id: id }));
  };

  useEffect(() => {
    dispatch(fetchTrustedDevices());
    dispatch(fetchLoggedInDevices());
  }, []);
  return (
    <div className="pb-6 slider-content-core">
      <div className="absolute z-10 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
        {showPopup && popupContent}
      </div>
      <div>
        <h1>
          <Text
            ref={ref}
            translationKey="security.heading"
            classes="text-3xl text-neutral-800 font-bold"
          />
        </h1>

        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1 pt-10">
              <Text
                classes="text-lg font-semibold"
                translationKey="security.password"
              />
              <Text
                classes="text-sm text-neutral-500"
                translationKey="security.passwordDescription"
              />
            </div>
            <div className="flex items-center justify-between p-4 rounded-lg bg-neutral-50">
              <Text
                translationKey="security.lastModified"
                classes="text-neutral-500 text-sm"
                translationProps={{
                  date: dateToTimeStampString(currentUser?.passwordChangedAt),
                }}
              />
              <Button
                label="security.resetPassword"
                variant="tertiary"
                size="sm"
                labelStyleClasses="font-semibold"
                onClick={handleResestPassword} // reset password call
              />
            </div>

            {trustedDevicesConfig && trustedDevicesConfig?.length ? (
              <div className="flex flex-col gap-6">
                <Text
                  classes="text-lg font-semibold"
                  translationKey="security.devicesYouTrust"
                />
                <DeviceListing
                  deviceConfig={trustedDevicesConfig}
                  handleRemoveDevice={(key, id) =>
                    handleRemoveTrustedDevice(key, id)
                  }
                />
                <Button
                  label="security.revokeAccess"
                  variant="tertiary"
                  classes="w-fit px-5 text-neutral-500"
                  size="sm"
                  labelStyleClasses="font-semibold"
                  onClick={() => handleShowPopup("revokeAll")}
                />
              </div>
            ) : null}
            {loggedInDevicesConfig && loggedInDevicesConfig?.length ? (
              <div className="flex flex-col gap-6">
                <Text
                  classes="text-lg font-semibold"
                  translationKey="security.devicesLoggedIn"
                />
                <DeviceListing
                  deviceConfig={loggedInDevicesConfig}
                  handleRemoveDevice={(key, id) =>
                    handleLogoutFromSession(key, id)
                  }
                />
                <Button
                  label="security.logoutFromAllDevices"
                  variant="tertiary"
                  classes="w-fit px-5 text-neutral-500"
                  size="sm"
                  labelStyleClasses="font-semibold"
                  onClick={() => handleShowPopup("logoutAll")}
                />
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex justify-between">
              <Text
                classes="text-lg font-semibold"
                translationKey="security.twoStepVerification"
              />
              <Button
                label="security.edit"
                variant="tertiary"
                preIcon="Edit"
                classes="w-fit px-5"
                iconClasses="text-primary-500 !h-3 !w-3"
                size="sm"
                labelStyleClasses="font-semibold"
                onClick={handleTwoStepVerification} // edit slider
              />
            </div>
            <TwoStepVerification verificationConfig={verificationConfig} />
          </div>

          {trustedDevicesConfig && trustedDevicesConfig?.length ? (
            <div className="flex flex-col gap-6">
              <Text
                classes="text-lg font-semibold"
                translationKey="security.devicesYouTrust"
              />
              <DeviceListing
                deviceConfig={trustedDevicesConfig}
                handleRemoveDevice={(key, id) =>
                  handleRemoveTrustedDevice(key, id)
                }
              />
              <Button
                label="security.revokeAccess"
                variant="tertiary"
                classes="w-fit px-5 text-neutral-500"
                size="sm"
                labelStyleClasses="font-semibold"
                onClick={() => handleShowPopup("revokeAll")}
              />
            </div>
          ) : null}
          {loggedInDevicesConfig && loggedInDevicesConfig?.length ? (
            <div className="flex flex-col gap-6">
              <Text
                classes="text-lg font-semibold"
                translationKey="Devices Logged in"
              />
              <DeviceListing
                deviceConfig={loggedInDevicesConfig}
                handleRemoveDevice={(key, id) =>
                  handleLogoutFromSession(key, id)
                }
              />
              <Button
                label="security.logoutFromAllDevices"
                variant="tertiary"
                classes="w-fit px-5 text-neutral-500"
                size="sm"
                labelStyleClasses="font-semibold"
                onClick={() => handleShowPopup("logoutAll")}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SecuritySettings;
