import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { amountToCurrency } from "@/utils/common";

import { CARD_FREQUENCY_CORE, CARD_FREQUENCY_LABEL } from "@/constants/Cards";
import { CURRENCY } from "@/constants/currency";

export default function Limit({
  amount = 12345,
  isEditable,
  currency = "SGD",
  frequency = CARD_FREQUENCY_CORE.RECURRING,
  onIconClick,
  showFrequency = true,
  amountClasses = "text-sm font-semibold text-neutral-800",
  frequencyClasses,
}) {
  return (
    <ProfileWidget
      className="flex-row-reverse justify-between text-sm font-semibold"
      iconComponent={
        isEditable ? (
          <div
            className="flex items-center justify-center -mr-1 rounded w-7 h-7 text-neutral-300 hover:text-neutral-500 hover:bg-neutral-200"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div id="limit">
              <Icon name="Edit" handleClick={onIconClick} />
            </div>
            <Tooltip id="limit" direction="bottom">
              <Text translationKey="cards.listingFields.limit.tooltip" />
            </Tooltip>
          </div>
        ) : null
      }
      name={amountToCurrency(amount, currency)}
      textClasses={amountClasses}
    >
      {showFrequency && frequency ? (
        <Text
          translationKey={CARD_FREQUENCY_LABEL[frequency]}
          classes={`text-xs font-medium text-neutral-500 ${frequencyClasses}`}
        />
      ) : null}
    </ProfileWidget>
  );
}

Limit.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.oneOf(Object.keys(CURRENCY)),
  frequency: PropTypes.oneOf(Object.values(CARD_FREQUENCY_CORE)),
  onIconClick: PropTypes.func,
  isEditable: PropTypes.bool,
  showFrequency: PropTypes.bool,
  amountClasses: PropTypes.string,
  frequencyClasses: PropTypes.string,
};
