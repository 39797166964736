import Text from "@/components/core/Text";

import AnalyticsOthersHelper from "@/components/Analytics/Transactions/Others";

import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";

export default function AnalyticsOthers() {
  return (
    <div>
      <div className="flex flex-col mx-12 mb-3">
        <Text
          classes="text-neutral-500 text-sm font-semibold"
          translationKey="analytics.header"
        />
        <Text
          classes="text-neutral-800 text-4xl font-extrabold"
          translationKey="analytics.transactions.others.title"
        />
      </div>
      <AnalyticsOthersHelper type={ACCOUNTING_TRANSACTION_PAGES.OTHERS} />
    </div>
  );
}
