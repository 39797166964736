import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";

import { dashboardAnalyticsTabsFilterSelector } from "@/store/selectors/user";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import SpendTable from "@/components/Company/Overview/SpendAnalytics/SpendListing/SpendTable";

import {
  SPEND_CATEGORY_REQUEST_VALUES,
  SPEND_LISTING_ENTITY_TYPES,
  SPEND_TYPES,
} from "@/constants/company";

const TABS = [
  {
    name: "company.overview.spendListing.tabs.cardMerchants",
    count: null,
    key: 1,
    entityType: SPEND_LISTING_ENTITY_TYPES.CARD_MERCHANT,
    isNotVisibleFor: [SPEND_TYPES.BILL_PAY, SPEND_TYPES.REIMBURSEMENT],
    entityDisplayName:
      "company.overview.spendListing.columns.cardMerchantSingular",
  },
  {
    name: "company.overview.spendListing.columns.vendor",
    count: null,
    key: 2,
    isNotVisibleFor: [SPEND_TYPES.CARDS, SPEND_TYPES.REIMBURSEMENT],
    entityType: SPEND_LISTING_ENTITY_TYPES.VENDOR,
    entityDisplayName: "company.overview.spendListing.columns.vendor",
  },
  {
    name: "category.pluralTitleCase",
    count: null,
    key: 3,
    entityType: SPEND_LISTING_ENTITY_TYPES.CATEGORY,
    entityDisplayName: "company.overview.spendListing.columns.category",
  },
  {
    name: "company.department.title",
    count: null,
    key: 4,
    entityType: SPEND_LISTING_ENTITY_TYPES.DEPARTMENT,
    entityDisplayName: "company.department.titleSingular",
  },
  {
    name: "company.project.title",
    count: null,
    key: 5,
    entityType: SPEND_LISTING_ENTITY_TYPES.PROJECT,
    entityDisplayName: "company.project.titleSingular",
  },
  {
    name: "company.location.title",
    count: null,
    key: 6,
    entityType: SPEND_LISTING_ENTITY_TYPES.LOCATION,
    entityDisplayName: "company.location.titleSingular",
  },
  {
    name: "company.people.title",
    count: null,
    key: 7,
    entityType: SPEND_LISTING_ENTITY_TYPES.PEOPLE,
    entityDisplayName: "company.overview.spendListing.columns.person",
  },
];

export default function SpendListing({ filters, isDepartmentClient }) {
  const tabsFromMasterData = useSelector(dashboardAnalyticsTabsFilterSelector);
  const tabsFromMasterSet = new Set(tabsFromMasterData || []);
  const filteredSelectedTabs = tabsFromMasterData
    ? TABS.filter((tab) =>
        tabsFromMasterSet.has(SPEND_CATEGORY_REQUEST_VALUES[tab.entityType])
      )
    : TABS;
  const [selectedTab, setSelectedTab] = useState(filteredSelectedTabs[0]);

  return (
    <div>
      <Text
        classes="text-lg text-neutral-500 font-bold"
        translationKey="expenses.overview.spendTable"
      />
      <Tabs
        items={filteredSelectedTabs}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => setSelectedTab(tab)}
        mode
        classes="font-semibold"
        itemClasses="py-3 -mr-1 mt-6"
      />

      <div className="mt-8">
        <SpendTable tab={selectedTab} filters={filters} />
      </div>
    </div>
  );
}

SpendListing.propTypes = {
  filters: PropTypes.object,
  isDepartmentClient: PropTypes.bool,
};
