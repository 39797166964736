import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAppliedFilters } from "@/store/reducers/filters";
import { appliedFilterSelector } from "@/store/selectors/filters";
import DateRangeFilter from "@/components/core/Filters/DateRangeFilter";
import ExportButton from "@/components/Exports/ExportButton";
import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
} from "@/utils/constants/filters";
import { handleExport } from "@/utils/exports";

export default function Export({
  onExport,
  totalExports = 1,
  storeName = "",
  sectionName = "",
  additionalFilters,
  handleExportFunc = null,
}) {
  const dispatch = useDispatch();
  const reduxSlice = useSelector((state) => state[storeName][sectionName]);
  const [showDateRange, setShowDateRange] = useState(false);
  const appliedFilters = useSelector(appliedFilterSelector);

  const handleCallback = async (filter) => {
    dispatch(setAppliedFilters(filter));
    handleExport(
      setShowDateRange,
      reduxSlice,
      appliedFilters,
      dispatch,
      additionalFilters
    );
  };

  if (showDateRange) {
    return (
      <DateRangeFilter
        popupTriggerComponent={() => (
          <div>
            <ExportButton />
          </div>
        )}
        options={
          AVAILABLE_FILTERS[AVAILABLE_FILTER_KEYS.datePeriods].props.options
        }
        callback={(filter) => handleCallback(filter)}
      />
    );
  }
  return (
    <ExportButton
      onClick={() => {
        if (handleExportFunc) {
          handleExportFunc();
        } else {
          handleExport(
            setShowDateRange,
            totalExports,
            appliedFilters,
            dispatch,
            additionalFilters
          );
        }
      }}
    />
  );
}

Export.propTypes = {
  onExport: PropTypes.func,
  totalExports: PropTypes.number,
  sectionName: PropTypes.string,
  storeName: PropTypes.string,
  additionalFilters: PropTypes.object,
  handleExportFunc: PropTypes.func,
};
