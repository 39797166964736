export default function contactDropdownOption(displayValue, option) {
  return (
    <span className="font-medium">
      {[option?.name, option?.email, option?.phone]
        .filter((_) => _)
        .map((item, idx) => (
          <span
            key={`${displayValue}-${idx}`}
            className={idx === 0 ? "text-neutral-800" : "text-neutral-500"}
          >
            {idx === 0 ? "" : " • "}
            {item}
          </span>
        ))}
    </span>
  );
}
