import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  walletListSelector,
  showGlobalAccountSelector,
} from "@/store/selectors/client";
import { bannerDetailsSelector } from "@/store/selectors/company";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import CashbackAccountWidget from "@/components/Company/Overview/MyAccounts/CashbackWidget";
import CreditAccountWidget from "@/components/Company/Overview/MyAccounts/CreditAccountWidget";
import PayrollAccountWidget from "@/components/Company/Overview/MyAccounts/PayrollAccountWidget";
import WalletAccountWidget from "@/components/Company/Overview/MyAccounts/WalletAccountWidget";
import { BUTTON_KEYS } from "@/utils/constants/payments";
import {
  ACCOUNT_TYPE,
  PAYMENT_ACCOUNT_TYPE_ALIASES,
} from "@/utils/constants/client";
import { checkIfUserActionAllowed } from "@/utils/common";

import { ROUTES } from "@/constants/routes";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

import InternationalAccountWidget from "./InternationalAccountWidget";
import AyoConnectAccountWidget from "./shared/AyoConnectAccountWidget";

export default function CompanyOverviewMyAccounts({
  isFetching,
  walletAccounts = [],
  isCreditEnabled = false,
  companyName,
  defaultCurrency,
}) {
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const myAccountEntries = [...walletAccounts];
  const showGlobalAccount = useSelector(showGlobalAccountSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);
  const allNavUrls = useSelector(allNavUrlsSelector);
  const walletLists = useSelector(walletListSelector);

  const showGlobalAccountsLink = checkIfUserActionAllowed(
    allNavUrls,
    userAllowedActions,
    USER_ALLOWED_CTA_KEYS.GLOBAL_ACCOUNTS
  );

  const bannerDetails = useSelector(bannerDetailsSelector);

  function getEntrySpecficButtonProps(walletInfo, idx) {
    const accountType = walletInfo?.accountType;
    const aliasName = walletInfo?.aliasName;

    const config = {
      innerClasses: "gap-0",
      iconClasses: `{ mr-1.5 ${
        idx === selectedIndex ? "text-white" : "text-primary-500"
      } }`,
    };

    switch (accountType) {
      case ACCOUNT_TYPE.CASHBACK:
        config.label = "dashboard.cashback.title";
        config.preIcon = "Cashback";
        break;

      case ACCOUNT_TYPE.PAYROLL:
        config.label = "payroll.title";
        config.preIcon = "Payments";
        break;

      case ACCOUNT_TYPE.CREDIT_ACCOUNT:
        config.label = "dashboard.myAccounts.walletAccountTypes.credit";
        config.preIcon = "CreditCard";
        break;

      case ACCOUNT_TYPE.INTERNATIONAL_CARDS:
        config.label =
          "dashboard.myAccounts.walletAccountTypes.internationalCards";
        break;

      case ACCOUNT_TYPE.CARDS:
        config.label = "dashboard.myAccounts.walletAccountTypes.cards";
        break;

      case ACCOUNT_TYPE.PAYMENTS:
        if (aliasName === PAYMENT_ACCOUNT_TYPE_ALIASES.PAYMENTS) {
          config.label = "dashboard.myAccounts.walletAccountTypes.payments";
        } else {
          config.label = walletAccounts[idx]?.currency;
        }
        break;

      default:
        break;
    }

    return config;
  }

  function _getWidget(idx) {
    const walletInfo = walletLists?.[idx];
    const accountType = walletInfo?.accountType;
    const aliasName = walletInfo?.aliasName;

    switch (accountType) {
      case ACCOUNT_TYPE.CASHBACK:
        return (
          <CashbackAccountWidget
            currency={defaultCurrency}
            cashbacks={walletInfo}
          />
        );

      case ACCOUNT_TYPE.PAYROLL:
        return (
          <PayrollAccountWidget
            companyName={companyName}
            payroll={walletInfo}
            creditDetails={walletInfo}
          />
        );

      case ACCOUNT_TYPE.CREDIT_ACCOUNT:
        return (
          <CreditAccountWidget
            companyName={companyName}
            creditDetails={walletInfo}
          />
        );

      case ACCOUNT_TYPE.INTERNATIONAL_CARDS:
        return (
          <InternationalAccountWidget
            accountType={accountType}
            widgetInfo={walletInfo}
          />
        );

      case ACCOUNT_TYPE.CARDS:
        return (
          <AyoConnectAccountWidget
            accountType={accountType}
            widgetInfo={walletInfo}
          />
        );

      case ACCOUNT_TYPE.PAYMENTS:
        if (aliasName === PAYMENT_ACCOUNT_TYPE_ALIASES.PAYMENTS) {
          return (
            <AyoConnectAccountWidget
              accountType={accountType}
              widgetInfo={walletInfo}
            />
          );
        }

        return (
          <WalletAccountWidget
            isCreditEnabled={isCreditEnabled}
            account={myAccountEntries[idx]}
          />
        );

      default:
        break;
    }
  }

  return (
    <div>
      <Text
        translationKey="dashboard.myAccounts.title"
        classes="text-lg text-neutral-500 font-bold mb-6 block"
      />
      {isFetching || !walletLists?.length ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-4 card-wrapper">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row flex-wrap flex-1 gap-4">
              {walletLists.map((_, idx) =>
                _?.status !== "disabled" ? (
                  <Button
                    key={`my-account-link-${idx}`}
                    variant={idx === selectedIndex ? "primary" : ""}
                    classes={`font-semibold py-2 px-6 rounded-md mr-2 ${
                      idx === selectedIndex
                        ? ""
                        : "text-neutral-500 bg-neutral-100"
                    }`}
                    size="0"
                    onClick={() => setSelectedIndex(idx)}
                    {...getEntrySpecficButtonProps(_, idx)}
                  />
                ) : null
              )}
            </div>
            {showGlobalAccount && showGlobalAccountsLink ? (
              <Link to={ROUTES.dashboard.globalAccounts.absolutePath}>
                <div className="flex flex-row items-center">
                  <Text
                    translationKey="company.globalAccounts.viewAllAccount"
                    classes="simple-link-styling text-primary"
                  />
                  <Icon name="EArrow" className="w-4 h-4 ml-2 text-primary" />
                </div>
              </Link>
            ) : null}
          </div>
          <div className="mt-4 text-right">{_getWidget(selectedIndex)}</div>
          {bannerDetails ? (
            <Alert
              wrapperDivClass="justify-start"
              iconClasses="w-6 h-6 text-neutral-500"
              variant="danger"
              title={bannerDetails?.title}
              icon=""
              description={bannerDetails?.description}
              primaryAction={{
                label: `billPay.bill.ctas.${bannerDetails?.ctas?.[0]}` ?? "",
                callback: () =>
                  bannerDetails?.ctas?.[0] === BUTTON_KEYS.payNow
                    ? navigate(ROUTES.manage.billing.absolutePath)
                    : null,
              }}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

function Loader() {
  return (
    <div className="p-6 rounded-lg bg-neutral-50">
      <div aria-label="row-1">
        <LoaderSkeleton isDarker size={[40, 80]} classes="mr-4" />
        <LoaderSkeleton isDarker size={[40, 160]} />
      </div>
      {/*  */}
      <div
        aria-label="row-2"
        className="flex items-center justify-between mt-8"
      >
        <div
          className="flex items-center gap-3"
          aria-label="circle+two+lines on right"
        >
          <LoaderSkeleton isDarker size={[60, 60]} type="circle" />
          <div>
            <LoaderSkeleton isDarker size={[12, 60]} />
            <LoaderSkeleton isDarker size={[24, 140]} classes="mb-3 block" />
          </div>
        </div>
        <div className="flex" aria-label="3 copied items">
          {Array(3)
            .fill(null)
            .map((_, idx) => (
              <div
                className="flex flex-col items-center px-4 pb-4"
                key={idx}
                aria-label="circle + two lines below"
              >
                <LoaderSkeleton isDarker size={[48, 48]} type="circle" />
                <LoaderSkeleton isDarker size={[12, 70]} classes="mt-4" />
                <LoaderSkeleton isDarker size={[12, 70]} classes="mt-0" />
              </div>
            ))}
        </div>
      </div>
      {/*  */}
      <div aria-label="row-3" className="p-4 mt-4 rounded-lg bg-neutral-100">
        <div className="flex items-center">
          <LoaderSkeleton isDarker size={[12, 12]} type="circle" />
          <LoaderSkeleton isDarker size={[12, 120]} classes="ml-2" />
          <LoaderSkeleton isDarker size={[12, 370]} classes="ml-6" />
        </div>
        <div className="flex items-center">
          <LoaderSkeleton isDarker size={[12, 12]} type="circle" />
          <LoaderSkeleton isDarker size={[12, 70]} classes="ml-2" />
          <LoaderSkeleton isDarker size={[12, 370]} classes="ml-11" />
        </div>
      </div>
    </div>
  );
}
CompanyOverviewMyAccounts.propTypes = {
  isFetching: PropTypes.bool,
  walletAccounts: PropTypes.array,
  creditDetails: PropTypes.object,
  isCreditEnabled: PropTypes.bool,
  companyName: PropTypes.string,
  defaultCurrency: PropTypes.string,
  payroll: PropTypes.object,
};
