import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { dateToTimeStampString } from "@/utils/common";

import { ACTIVE } from "@/constants/reimbursement";

function DeviceListing({ deviceConfig, handleRemoveDevice }) {
  return (
    <div>
      <div className="p-4 border rounded-lg border-neutral-200 ">
        <div>
          {Array.isArray(deviceConfig)
            ? deviceConfig?.map((item, index) => (
                <div
                  className={`flex items-center justify-between gap-4 ${
                    index !== deviceConfig.length - 1 ? "mb-6" : ""
                  }`}
                  key={`${item.id}-${index}`}
                >
                  <div>
                    <div className="flex items-center gap-1">
                      <Text
                        translationKey={item?.userAgent?.split(" ")[0]}
                        classes="text-base font-medium"
                      />
                      {/* NOT supported BY BE */}
                      {/* {item?.status === ACTIVE ? (
                        <div className="flex items-center justify-center gap-1">
                          <div className="profile-widget-dot" />
                          <Text
                            translationKey="This device"
                            classes="text-sm text-neutral-500"
                          />
                        </div>
                      ) : null} */}
                    </div>
                    <Text
                      translationKey="security.lastActivityOn"
                      translationProps={{
                        timestamp: dateToTimeStampString(item?.lastActivityAt),
                      }}
                      classes="text-sm text-neutral-500"
                    />
                  </div>
                  <div className="flex items-center justify-center gap-4">
                    {item?.platform ? (
                      <Text
                        translationKey={item?.platform}
                        classes="text-sm text-neutral-500"
                      />
                    ) : null}
                    <Icon
                      name="Delete"
                      className="w-5 h-5 cursor-pointer text-neutral-400"
                      handleClick={(key, id) =>
                        handleRemoveDevice(item?.key, item?.id)
                      }
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default DeviceListing;
DeviceListing.propTypes = {
  deviceConfig: PropTypes.array,
  handleRemoveDevice: PropTypes.func,
};
