import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  bottomLinksSelector,
  navLinksSelector,
} from "@/store/selectors/navigations";
import { userSelector } from "@/store/selectors/user";

import Icon from "@/components/core/Icon";

import InactivityConfirmation from "@/components/Settings/Modals/InactivityConfirmation";
import GlobalSlider from "@/GlobalSliders";

import BottomNav from "./BottomNav";
import NavChildLinks from "./NavChildLinks";
import NavIcon from "./NavIcon";

export default function NavBar() {
  const navBarRef = useRef(null);
  const navigationRef = useRef(null);
  const navLinks = useSelector(navLinksSelector);
  const bottomLinks = useSelector(bottomLinksSelector);
  const [activeNavItemIndex, changeActiveNavItemIndex] = useState(-1);
  const user = useSelector(userSelector);

  // Keeping below methods and commented code for a record of close on hover feature
  // Might need these later
  // handling horizontal leave from navBar
  function handleHorizontalMovement(e, ref, handleLeftNavigation = true) {
    if (ref) {
      const rectClient = ref?.current?.getBoundingClientRect();
      const x = rectClient?.x || 0;
      const width = rectClient?.width || 0;
      const clientx = e?.clientX || 0;
      if ((clientx >= width + x || clientx <= x) && handleLeftNavigation) {
        changeActiveNavItemIndex(-1);
      }
    }
  }

  // handling vertical Leave
  function handleVerticalMovement(e, ref) {
    if (ref) {
      const rectClient = ref?.current?.getBoundingClientRect();
      const elementy = rectClient?.y || 0;
      const elementheight = rectClient?.height || 0;
      const eventy = e?.clientY || 0;
      // For checking if mouse is leaving from top
      if (eventy < elementy) changeActiveNavItemIndex(-1);
      // For checking if mouse is leaving fromn bottom
      if (elementheight + elementy <= eventy) changeActiveNavItemIndex(-1);
    }
  }

  useEffect(() => {
    const documentClickListener = (event) => {
      if (
        navigationRef?.current &&
        !navigationRef.current.contains(event?.target)
      ) {
        changeActiveNavItemIndex(-1);
      }
    };
    document.addEventListener("click", documentClickListener);
    return () => {
      document.removeEventListener("click", documentClickListener);
    };
  }, []);

  return (
    <>
      {/* this opening all slider on on all page using search Param */}
      <GlobalSlider />
      <InactivityConfirmation
        title="login.inactivityTitle"
        subtext="login.inactivitySubText"
      />
      <div
        ref={navigationRef}
        className="flex flex-col justify-between items-center text-center w-11 border-r-[1px] border-neutral-300 fixed h-full z-[800]"
        // onMouseLeave={(e) => {
        //   handleHorizontalMovement(e, navigationRef);
        // }}
      >
        <div className="flex flex-col items-center justify-between w-full mt-6 text-center">
          <Icon name="VolopayLogo" className="w-8 h-8 mb-4 text-neutral-700" />
          <div
            className="w-full"
            ref={navBarRef}
            // onMouseLeave={(e) => handleVerticalMovement(e, navBarRef)}
          >
            {navLinks?.map((linkObject, index) => (
              <div key={`nav-item-${index}`}>
                <NavIcon
                  disabled={linkObject?.disabled}
                  data={linkObject}
                  index={index}
                  iconStr={linkObject.icon}
                  path={linkObject.path}
                  borderBottom={linkObject.borderBottom}
                  activeNavItemIndex={activeNavItemIndex}
                  hasChildren={!!linkObject.childLinks?.length}
                  changeActiveNavItemIndex={() => {
                    if (linkObject.key === "dashboard") {
                      changeActiveNavItemIndex(-1);
                      return;
                    }
                    if (activeNavItemIndex !== index) {
                      changeActiveNavItemIndex(index);
                    } else {
                      changeActiveNavItemIndex(-1);
                    }
                  }}
                />

                <NavChildLinks
                  links={navLinks}
                  activeNavItemIndex={activeNavItemIndex}
                  handleHorizontalMovement={(
                    e,
                    childRef,
                    handleLeftNavigation
                  ) => {
                    // handleHorizontalMovement(e, childRef, handleLeftNavigation);
                  }}
                  onNavItemClick={() => changeActiveNavItemIndex(-1)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="mb-4 cursor-pointer">
          <BottomNav
            activeNavItemIndex={activeNavItemIndex}
            links={bottomLinks}
          />
        </div>
      </div>
    </>
  );
}
