import PropTypes, { object, string } from "prop-types";
import { useEffect, useRef, useState } from "react";

import { MULTIPLE_INPUT_VALIDATE_CONFIG } from "@/utils/constants/multipleInput";

import Pill from "../CheckboxDropdown/Pill";
import Input from "../Input";
import Text from "../Text";

// TODO: TECH DEBT will have to convert it to two binding component
/**
 * Is for Multiple Inputs with pills,validations and formatting on blur
 * @param {String} uniqueId pass unique id if want to fix validation error when using updateValue boolean
 * @param {Boolean} toggle it to update value for Now later will create this two wy binding component
 * @param {string} label - (Not Required) give string  example "Email"
 * @param {function} handleChange -  (Not Required) handleChange function is a call back function that is triggered on handlerKeys defined will return the list of inputs that are present in the component
 * @param {array<object>} values - (Not Required) default values that can be passed given as [{label:"",key:""}] where key is the state of the pill eg, ERROR,NORMAL,DUPLICATE You need to define key in the config first
 * @param {array<string>} handlerKeys - (Required) List of handler keys that are the triggering keys of keyboard through which new pills are added given as ["Enter",","]
 * @param {function} validate -  (Not Required) validator function which takes values and text as a input are return ENUMS like "ERROR","DUPLICATE","NORMAL" based on validations
 * @param {function} handleError -  (Not Required) - ErrorHandler callback function that is triggered by handlerKeys and will return boolean value whether error is there or not
 * @param {function} formatNamesOnBlur - (Not Required) - formatNamesOnBlur is formatting function which takes an array of strings and return formatted string as an output
 * @param {array<object>} MultipleInputConfig -
 * @param {Boolean} updateValue - you can toggle this value whenever you want to change the value
 * */
export default function MultipleInput({
  uniqueId,
  values: defaultValues = [],
  validate = () => {},
  label = "",
  handleChange = () => {},
  handleError = () => {},
  formatNamesOnBlur = () => {},
  handlerKeys,
  MultipleInputConfig,
  emptyErrorMessage,
  updateValue,
  desc,
  descClasses,
}) {
  const [pills, setPills] = useState(defaultValues);

  const [curr, setCurr] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState("");
  const [errorClasses, setErrorClasses] = useState("");
  const [isDisplayText, setIsDisplayText] = useState(false);
  const isInputFocus = useRef(false);
  const ref = useRef(null);
  const handleKeyDown = (e) => {
    if (handlerKeys.includes(e.key)) {
      if (pills.length === 0 && curr?.trim() === "") {
        setError(emptyErrorMessage);
        setErrorClasses("text-xs text-danger-600");
        return;
      }
      if (pills.length > 0 && curr?.trim() === "") {
        setIsDisplayText(true);
        ref.current.blur();
        return;
      }
      const key = validate(
        curr,
        pills.map((it) => it.label)
      );
      const currKey = MultipleInputConfig[key];
      if (currKey) {
        if (currKey?.errorMessage) {
          setError(currKey.errorMessage);
          handleError(true);
          setIsDisabled(true);
          setPills((prev) => [
            ...prev,
            {
              label: curr,
              key,
            },
          ]);
        } else {
          handleError(false);

          setError("");
          setPills((prev) => [
            ...prev,
            {
              label: curr,
              key,
            },
          ]);
          if (e?.blurOut) {
            setIsDisplayText(true);
            isInputFocus.current = false;
          }
        }
      }
      setErrorClasses(currKey.errorClasses);
      setCurr("");
    } else if (e?.key === "Backspace" && !curr) {
      setPills(pills.filter((_element, idx, arr) => idx !== arr.length - 1));
    }
  };
  const handleDeleteHandler = (index) => {
    const isFillsWithErrorRemaining = pills.filter(
      (pill, idx) =>
        idx !== index &&
        [
          MULTIPLE_INPUT_VALIDATE_CONFIG.ERROR,
          MULTIPLE_INPUT_VALIDATE_CONFIG.DUPLICATE,
          MULTIPLE_INPUT_VALIDATE_CONFIG.EMPTY,
        ]?.includes(pill.key)
    );
    if (!isFillsWithErrorRemaining?.length) {
      setIsDisabled(false);
      setError("");
      handleError(false);
    } else {
      const lastPill =
        isFillsWithErrorRemaining[isFillsWithErrorRemaining.length - 1];
      const key = lastPill?.key;
      const configObject = MultipleInputConfig[key];

      setError(configObject?.errorMessage);
    }
    setPills((prev) => prev.filter((_element, idx) => idx !== index));
  };

  const handlePaste = (e) => {
    const _string = e.clipboardData.getData("Text");
    const stringOfEmails = _string.split(",").map((i) => i?.trim());
    const errors = [];
    stringOfEmails?.forEach((email) => {
      const key = validate(
        email,
        pills.map((it) => it?.label)
      );
      const currKey = MultipleInputConfig[key];
      errors.push(currKey.errorMessage);

      handleError(!!currKey.errorMessage);
      setPills((prev) => [
        ...prev,
        {
          label: email,
          key,
        },
      ]);
    });
    setError(errors[errors.length - 1]);
  };

  const handleUpdate = (value) => {
    const stringOfEmails = value.map((i) => i?.trim());
    const errors = [];
    const _pills = [];
    const emails = [];
    stringOfEmails?.forEach((email) => {
      const key = validate(email, emails, uniqueId);
      const currKey = MultipleInputConfig[key];

      errors.push(currKey.errorMessage);
      _pills?.push({
        label: email,
        key,
      });
      emails?.push(email);
    });
    setPills(_pills);
    const hasSomeError = errors?.some((err) => err !== "");

    if (hasSomeError) {
      const findLastError = errors.reverse().find((i) => i !== "");
      setError(findLastError);
      handleError(true);
      setIsDisabled(true);
    } else {
      handleError(false);

      setError("");
    }
  };

  useEffect(() => {
    handleChange(
      pills
        ?.filter((it) => it.key === MULTIPLE_INPUT_VALIDATE_CONFIG.NORMAL)
        ?.map((it) => it.label)
    );
  }, [pills]);

  // Update pills when values prop changes, but avoid infinite loop
  useEffect(() => {
    // Check if defaultValues and pills are different
    handleUpdate(defaultValues);
  }, [updateValue]);

  return (
    <div>
      <Input
        isFocus
        type="text"
        placeholder=""
        label={label}
        labelStyleClasses="text-sm"
        classes="text-sm hidden"
        value={curr}
      />
      <div
        className={`flex border-b-[1px] items-center  ${
          isDisplayText ? "border-neutral-500" : "border-primary-500"
        } `}
      >
        <div className="flex flex-wrap items-center gap-1 px-0 pt-1 pb-2 mt-1 grow">
          {isDisplayText ? (
            <div
              className="text-base text-neutral-800"
              onClick={() => {
                ref.current.focus();
                setIsDisplayText(false);
              }}
            >
              {formatNamesOnBlur(pills.map((it) => it.label))}
            </div>
          ) : (
            pills?.map((item, index) => (
              <div className="pill" key={item?.label}>
                <Pill
                  pillClasses={MultipleInputConfig[item?.key].pillClasses}
                  label={item?.label}
                  onDeleteHandler={() => {
                    isInputFocus.current = false;
                    handleDeleteHandler(index);
                  }}
                />
              </div>
            ))
          )}

          <input
            id="searchInputPill"
            className="search-input-pill"
            value={curr}
            onChange={(e) => {
              let cleanedValue = e.target.value;

              handlerKeys?.forEach((key) => {
                if (key?.length === 1)
                  cleanedValue = cleanedValue.replace(key, "");
              });

              setCurr(cleanedValue);
            }}
            ref={ref}
            onFocus={() => {
              setIsDisplayText(false);
              isInputFocus.current = true;
            }}
            onBlur={(e) => {
              if (isInputFocus.current)
                handleKeyDown({ key: handlerKeys[0], blurOut: true });
            }}
            onPaste={(e) => {
              e.preventDefault();
              handlePaste(e);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
            disabled={isDisabled}
          />
        </div>
      </div>
      {desc ? (
        <div>
          <Text translationKey={desc} classes={descClasses} />
        </div>
      ) : null}

      <Text translationKey={error} classes={errorClasses} />
    </div>
  );
}
MultipleInput.propTypes = {
  values: PropTypes.arrayOf(Object),
  validate: PropTypes.func,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  handleError: PropTypes.func,
  formatNamesOnBlur: PropTypes.func,
  handlerKeys: PropTypes.arrayOf(string),
  MultipleInputConfig: PropTypes.arrayOf(object),
  emptyErrorMessage: PropTypes.string,
  updateValue: PropTypes.bool,
  uniqueId: PropTypes.number,
  descClasses: PropTypes.string,
  desc: PropTypes.string,
};
