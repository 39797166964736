import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  setCurrentOnboardingSection,
  setCurrentOnboardingStep,
  setOnboardingSection,
} from "@/store/reducers/onboarding";

import { countriesSelector } from "@/store/selectors/app";
import { onboardingSectionSelector } from "@/store/selectors/onboarding";

import Button from "@/components/core/Button";
import DateInput from "@/components/core/DateInput";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import PhoneNumberInput from "@/components/core/PhoneInput";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import VpAddress from "@/components/core/VpAddress";
import VpSelect from "@/components/core/VpSelect";

import {
  FIELD_KEY_KEY,
  FIELD_PATH_KEY,
  FIELD_TYPE_ADDRESS,
  FIELD_TYPE_DATE,
  FIELD_TYPE_EMAIL,
  FIELD_TYPE_MOBILE_NUMBER,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_RADIO,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_TEXT,
  INLINE_GROUP_TYPE_NAME,
  getCompleteFormKey,
} from "@/components/GenericForm/common";
import CommonHeaderComponent from "@/components/Onboarding/OnboardingForm/CommonHeaderComponent";
import { areSameAddress } from "@/components/Onboarding/util";
import { dateToString } from "@/utils/common";

import { DATEINPUT_MODES } from "@/constants/date";
import {
  ONBOARDING_SLIDER_PARAMS,
  ONBOARDING_STEPS,
} from "@/constants/onboarding";

function ReviewDetails() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const onboardingFormResponse = useSelector(onboardingSectionSelector);
  const onboardingSection = onboardingFormResponse?.sections[0];

  const stepSections = onboardingSection?.steps;

  const countryOptions = useSelector(countriesSelector);

  const countryOptionsModified = useMemo(() => {
    return countryOptions?.map((c) => {
      return { key: c?.name, value: c?.name };
    });
  }, [countryOptions]);

  const formKey = onboardingSection?.key || onboardingSection?.step;
  const { fields, ...commonHeaderProps } = onboardingSection;

  useEffect(() => {
    if (formKey) {
      searchParams.set(ONBOARDING_SLIDER_PARAMS, formKey);
      setSearchParams(searchParams);
    }
  }, [formKey]);

  const addressSection = stepSections?.find(
    (section) => section?.key === ONBOARDING_STEPS.ADDRESS_DETAILS
  );

  const isSameAddress = areSameAddress(addressSection?.fields);

  const editClickHandler = (fieldkey) => {
    dispatch(setCurrentOnboardingSection(fieldkey));
    dispatch(setCurrentOnboardingStep(fieldkey));
    dispatch(setOnboardingSection(null));

    searchParams.delete(ONBOARDING_SLIDER_PARAMS);

    searchParams.set(ONBOARDING_SLIDER_PARAMS, fieldkey);
    setSearchParams(searchParams);
  };

  const getFieldByType = (field, grouped) => {
    const fieldType = field?.type;
    const completeKey = getCompleteFormKey(
      field[FIELD_KEY_KEY],
      field[FIELD_PATH_KEY]
    );

    const addressValues = Object.fromEntries(
      Object.entries(field?.values ?? {}).map(([key, value]) => [
        `${completeKey}.${key}`,
        value,
      ])
    );

    let FieldComponent = <></>;

    switch (fieldType) {
      case FIELD_TYPE_NUMBER:
      case FIELD_TYPE_EMAIL:
      case FIELD_TYPE_TEXT:
        FieldComponent = (
          <Input value={field?.value} label={field?.label} disabled />
        );
        break;

      case FIELD_TYPE_SELECT:
        FieldComponent = (
          <VpSelect
            label={field?.label}
            placeholder={field?.label}
            options={field?.options ?? countryOptionsModified}
            value={field?.value}
            menuPosition="absolute"
            valueKey="key"
            optionsDisplayKey="value"
            classes="mt-2"
            disabled
          />
        );

        break;

      case FIELD_TYPE_MOBILE_NUMBER:
        FieldComponent = <PhoneNumberInput disabled value={field?.value} />;
        break;

      case FIELD_TYPE_DATE:
        FieldComponent = (
          <DateInput
            disabled
            value={dateToString(field?.value, { year: "numeric" })}
            content={
              <Input
                label={field?.label}
                value={dateToString(field?.value, { year: "numeric" })}
                rightText={<Icon name="Calendar" />}
                autoComplete="off"
                titleNoTranslate
                disabled
              />
            }
            mode={DATEINPUT_MODES.DATE}
          />
        );
        break;

      case FIELD_TYPE_RADIO:
        FieldComponent = (
          <div className="flex flex-col gap-3">
            <Text
              translationKey={field?.label}
              classes="text-xs text-neutral-500"
            />
            <div className="flex gap-4">
              {field?.options?.map((item) => (
                <Radio
                  key={item?.key}
                  label={item?.value}
                  value={item?.key}
                  isChecked={field?.value === item?.key}
                  disabled
                />
              ))}
            </div>
          </div>
        );
        break;

      case FIELD_TYPE_ADDRESS:
        FieldComponent = (
          <VpAddress
            disabled
            enabledSameAsCheckbox={field?.enabledSameAsCheckbox}
            label={field?.label}
            name={completeKey}
            labelCheckBox={field?.checkboxText}
            values={{
              ...addressValues,
              ...(field?.key === "billing_address_attributes" && isSameAddress
                ? { [`${completeKey}.isSameChecked`]: true }
                : {}),
            }}
            keyForSameAsCheckbox={completeKey?.replace(
              field?.key,
              field?.keyForSameAsCheckbox
            )}
          />
        );
        break;

      default:
        break;
    }

    return (
      <div className={`mb-6 ${grouped ? "w-full mt-2" : ""}`}>
        {FieldComponent}
      </div>
    );
  };

  return (
    <div>
      <CommonHeaderComponent headerProps={{ ...commonHeaderProps }} />

      {stepSections?.map((field) => {
        return (
          <>
            <div className="flex justify-between">
              <Text
                classes="font-bold text-lg text-neutral-800"
                translationKey={field?.label}
              />

              <Button
                label="common.edit"
                variant="tertiary"
                classes="w-fit px-5 text-xs py-2 h-fit"
                preIcon="Edit"
                iconClasses="w-4 h-4"
                onClick={() => {
                  editClickHandler(field?.key);
                }}
              />
            </div>

            <div className="my-4">
              {field?.fields?.map((singleField) => {
                return singleField?.type !== INLINE_GROUP_TYPE_NAME ? (
                  getFieldByType(singleField)
                ) : (
                  <div className="flex flex-row items-center justify-between gap-8">
                    {singleField?.fields?.map((groupField) => {
                      return getFieldByType(groupField, true);
                    })}
                  </div>
                );
              })}
            </div>
          </>
        );
      })}
    </div>
  );
}

export default ReviewDetails;
