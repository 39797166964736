import PropTypes from "prop-types";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

export default function NotificationsSavePopUp({
  changesCount,
  handleCancel,
  handleSave,
  isLoading,
}) {
  return (
    <div className="p-6 flex itmes-center gap-9 sticky left-1/2 -translate-x-1/2 bottom-6 max-w-prose border border-neutral-200 rounded-lg bg-white shadow vp-tooltip slider-footer">
      <div className="flex items-center w-1/2">
        <Text
          translationKey="settings.notificationPreferences.changesMadeModalText"
          translationProps={{ count: changesCount }}
          classes="text-base text-neutral-800 font-semibold"
        />
      </div>
      <div className="flex items-center gap-6 w-1/2">
        <Button
          label="settings.notificationPreferences.buttonLabel.cancel"
          variant="tertiary"
          classes="px-5 py-3 text-base font-semibold text-neutral-500 w-fit"
          onClick={handleCancel}
        />
        <Button
          label="settings.notificationPreferences.buttonLabel.saveChanges"
          type="success"
          classes="px-5 py-3 text-base font-semibold text-white w-fit"
          onClick={handleSave}
          showLoader={isLoading}
        />
      </div>
    </div>
  );
}

NotificationsSavePopUp.propTypes = {
  changesCount: PropTypes.number,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
};
