import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import BudgetChartLegends from "@/components/Company/common/BudgetChart/BudgetChartLegends";
import { generatePieChartProps } from "@/components/Company/common/BudgetChart/generatePieChartProps";
import { amountToCurrency } from "@/utils/common";

import { BUDGET_PILL_CONFIG } from "@/constants/common";

import "./index.scss";

/**
 *  Renders a box with donut and optional legends on the right
 *
 *  Order of donut items
 *   In list - top to down
 *   In donut - starts from top, clockwise.
 *
 * @param {Array<Object>} data // percentages are auto-calculate based on sum of array
 * @param {Object} sideLength   size of the donut
 * @param {String} centerLabel  renders center (inside) donut chart
 * @param {Object} centerLabelStyles
 * @param {Boolean} showLegends show bullet points
 */

export default function BudgetChart(props) {
  const {
    data = [],
    containerClass = "",
    showLegends = true,
    sideLength = 200,
    centerLabel = "my center label",
    centerLabelStyles = {},
  } = props;
  const { t } = useTranslation();

  const chartRef = useRef();

  useEffect(() => {
    const docObj = document.querySelector(
      ".apexcharts-datalabels-group > .apexcharts-datalabel-value"
    );
    if (docObj) {
      docObj.style.fontSize = "1em";
      docObj.style.fontFamily = "'Manrope', sans-serif";
    }
  }, [JSON.stringify(props?.centerLabel)]);
  const values = data.map(({ value }) => value);
  const colors = data.map(({ color }) => color);
  const labels = data.map(({ label }) => t(label));
  const valueTexts = data.map(({ valueText }) => valueText);

  const chartProps = generatePieChartProps({
    ...props,
    values,
    colors,
    labels,
    valueText: valueTexts,
  });
  const keys = values.join("-");
  return (
    <div
      className={`w-fit budget-pie-chart ${
        showLegends ? "" : "aspect-square"
      } ${containerClass}`}
    >
      <div className="w-fit">
        <Chart key={`${keys}`} ref={chartRef} {...chartProps} />
      </div>
      {/*  JSX is evaluated but not rendered directly */}
      {showLegends ? (
        <BudgetChartLegends chartRef={chartRef} data={data} />
      ) : null}
    </div>
  );
}

BudgetChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Cards
      color: PropTypes.string, // '#FF0000'
      value: PropTypes.number, // e.g. 2000, for calculating sum
      valueText: PropTypes.string, // "2000 SGD", for rendering text. amount same as above.
    })
  ),
  showLegends: PropTypes.bool,
  sideLength: PropTypes.number,
  centerLabel: PropTypes.string,
  centerLabelStyles: PropTypes.object,
  containerClass: PropTypes.string,
  sideWidth: PropTypes.number,
};

export const BudgetChartExample = (props = {}) => (
  <BudgetChart
    centerLabel="August"
    sideLength={170}
    showLegends={false}
    data={[
      {
        label: "iCard",
        color: BUDGET_PILL_CONFIG.CARDS.chartHexColor,
        value: 100,
        valueText: amountToCurrency(100, "SGD"),
      },
      {
        label: "iPayment",
        color: BUDGET_PILL_CONFIG.BILL_PAY.chartHexColor,
        value: 200,
        valueText: amountToCurrency(200, "SGD"),
      },
      {
        label: "iReimbursements",
        color: BUDGET_PILL_CONFIG.REIMBURSEMENT.chartHexColor,
        value: 100,
        valueText: amountToCurrency(100, "SGD"),
      },
    ]}
    {...props}
  />
);
