import React from "react";
import { useSelector } from "react-redux";

import { yetToActivatePhysicalCardPageFiltersSelector } from "@/store/selectors/cards";
import { userSelector } from "@/store/selectors/user";

import PhysicalCardsPageHelper from "@/components/Cards/PhysicalCardPageHelper";

import { ROUTES } from "@/constants/routes";

function MyVolopayCardsPhysicalCardsYetToActivate() {
  const currentUser = useSelector(userSelector);
  const filters = useSelector(yetToActivatePhysicalCardPageFiltersSelector);

  return (
    <PhysicalCardsPageHelper
      key={ROUTES.myVolopay.cards.physicalCards.yetToActivate.pathName}
      tab={ROUTES.myVolopay.cards.physicalCards.yetToActivate.pathName}
      absoluteTabPath={
        ROUTES.myVolopay.cards.physicalCards.yetToActivate.absolutePath
      }
      filterArray={filters}
      params={{ user: currentUser?.id }}
    />
  );
}

export default MyVolopayCardsPhysicalCardsYetToActivate;
