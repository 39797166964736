import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

/**
 * AddSection component displays a section with a title, description, and a button.
 *
 * @component
 * @example
 * const title = "Section Title";
 * const description = "This is a description.";
 * const btnText = "Click Me";
 * const btnPreIcon = "IconName";
 * const onClick = () => console.log('Button clicked');
 * return (
 *   <AddSection
 *     title={title}
 *     description={description}
 *     btnText={btnText}
 *     btnPreIcon={btnPreIcon}
 *     onClick={onClick}
 *   />
 * );
 *
 * @param {Object} props - The component props
 * @param {string} props.title - The title of the section
 * @param {string} props.description - The description of the section
 * @param {string} props.btnText - The text displayed on the button
 * @param {string} props.btnPreIcon - The pre-icon displayed on the button
 * @param {function} props.onClick - The function to call when the button is clicked
 * @returns {JSX.Element} The rendered AddSection component
 */
function AddSection({
  title,
  description,
  btnText = "company.people.peopleMyProfileSlider.edit",
  btnPreIcon = "Edit",
  onClick = () => {},
}) {
  return (
    <div className="flex flex-col gap-4 p-3 mb-2 bg-neutral-50 rounded-2 ">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex gap-3">
            <Text
              translationKey={title}
              classes="text-base font-semibold text-neutral-800"
            />
          </div>

          <Text
            translationKey={description}
            classes="text-xs font-semibold text-neutral-500"
          />
        </div>

        <Button
          label={btnText}
          size="sm"
          classes="w-fit py-1.5 px-5"
          preIcon={btnPreIcon}
          iconClasses="p-0.75 -mr-2"
          variant="tertiary"
          innerClasses="gap-1 font-bold text-xs"
          onClick={onClick}
        />
      </div>
    </div>
  );
}

AddSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnText: PropTypes.string,
  btnPreIcon: PropTypes.string,
  onClick: PropTypes.func,
};

export default AddSection;
