import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

/**
 * List of `dot key: value` items in a line
 *
 * - dot is optional (item[i].noDot)
 * - value is optional (item[i].textValue is falsy)
 * - item gap can be changed (classes)
 *
 * @param {Array<Object>} items
 * @param {String} classes
 */
export default function DotList({ items = [], classes = "flex gap-3" }) {
  return (
    <div className={`flex gap-3 ${classes}`}>
      {items.map((val, index) => (
        <div
          key={`spend-credit-label-progress-bar-${index}`}
          className="flex items-center gap-2"
        >
          {val.noDot ? null : (
            <div
              className={` w-2 h-2  rounded-full  ${
                val.dotColorClasses || "bg-neutral-200"
              }`}
            />
          )}
          <div className="flex items-center">
            {/* `label` */}
            <span
              className={
                val.textLabelClasses || "text-xs font-semibold text-neutral-500"
              }
            >
              <Text translationKey={val.textLabel} />
            </span>

            {/* `: value` , supports optional rendering */}
            {val.textValue ? (
              <>
                <span
                  className={
                    val.textLabelClasses ||
                    "text-xs font-semibold text-neutral-500"
                  }
                >
                  :&nbsp;
                </span>
                {/* value */}
                <span
                  className={
                    val.textValueClasses ||
                    "text-xs font-semibold text-neutral-800"
                  }
                >
                  <Text translationKey={val.textValue} />
                </span>
              </>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}

DotList.propTypes = {
  classes: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      noDot: PropTypes.bool,
      dotColorClasses: PropTypes.string,
      // example: "text-corporate-cards-500", "text-neutral-600"

      textLabel: PropTypes.string.isRequired,
      textLabelClasses: PropTypes.string,
      // example: "text-corporate-cards-500", "text-neutral-600"

      textValue: PropTypes.string,
      textValueClasses: PropTypes.string,
      // example: "text-corporate-cards-500", "text-neutral-600"
    })
  ),
};
