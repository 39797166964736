import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FixedSizeList as List } from "react-window";

import useOnClickOutside from "@/hooks/useClickOutside";

import {
  setIsFetchingFilters,
  setSearchInputText,
  setSliderSearchInputText,
} from "@/store/reducers/filters";

import {
  searchAndFilterCommonStructureSelector,
  searchAndFilterInputTextSelector,
  searchAndFilterOptionsCurrentLevelSelector,
  searchAndFilterOptionsListSelector,
  sliderSearchAndFilterCommonStructureSelector,
  sliderSearchAndFilterInputTextSelector,
  sliderSearchAndFilterOptionsCurrentLevelSelector,
  sliderSearchAndFilterOptionsListSelector,
} from "@/store/selectors/filters";

import Icon from "@/components/core/Icon";
import Popup from "@/components/core/Popup";
import {
  getModuleValueBasedOnPagePathName,
  isCurrentOptionLevelMultiselect,
} from "@/utils/constants/searchAndFilter";
import { FILTER_TRIGGER_CONTEXT } from "@/utils/constants/filters";

import Checkbox from "../Checkbox";
import LoaderSkeleton from "../LoaderSkeleton";
import Text from "../Text";

export default function SearchFilterInputOptionsComponent({
  triggerComponent,
  handleOptionClick,
  handleClosePopup,
  currentNavigationIds = [],
  handleFocusClick,
  context,
  classes,
}) {
  const COMPONENT_CONTEXT_CONFIG = {
    [FILTER_TRIGGER_CONTEXT.PAGE]: {
      selectors: {
        currentLevel: searchAndFilterOptionsCurrentLevelSelector,
        options: searchAndFilterOptionsListSelector,
        searchInputText: searchAndFilterInputTextSelector,
        searchAndFilterCommonStoreStructure:
          searchAndFilterCommonStructureSelector,
      },
      reducers: {
        setSearchInputText,
      },
    },
    [FILTER_TRIGGER_CONTEXT.SLIDER]: {
      selectors: {
        currentLevel: sliderSearchAndFilterOptionsCurrentLevelSelector,
        options: sliderSearchAndFilterOptionsListSelector,
        searchInputText: sliderSearchAndFilterInputTextSelector,
        searchAndFilterCommonStoreStructure:
          sliderSearchAndFilterCommonStructureSelector,
      },
      reducers: {
        setSearchInputText: setSliderSearchInputText,
      },
    },
  };

  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const currentLevel = useSelector(
    COMPONENT_CONTEXT_CONFIG[context]?.selectors?.currentLevel
  );
  const dispatchSetSearchInputText =
    COMPONENT_CONTEXT_CONFIG?.[context]?.reducers.setSearchInputText;

  const options =
    useSelector(COMPONENT_CONTEXT_CONFIG[context]?.selectors?.options) ?? [];

  const searchInputText = useSelector(
    COMPONENT_CONTEXT_CONFIG[context]?.selectors?.searchInputText
  );

  const searchAndFilterCommonStoreStructure = useSelector(
    COMPONENT_CONTEXT_CONFIG[context]?.selectors
      ?.searchAndFilterCommonStoreStructure
  );

  const searchAndFilterCommonStructureValues = Object.values(
    searchAndFilterCommonStoreStructure
  );

  const trackIndexes = searchAndFilterCommonStructureValues?.map(
    (filter) => filter?.levelPosition
  );

  const locationObject = useLocation();

  const pagePathName = locationObject?.pathname;

  const { onlyTextSearch } = getModuleValueBasedOnPagePathName(pagePathName);

  const ref = useRef();
  const optionRef = useRef(null);

  useOnClickOutside(ref, (e) => {
    if (
      !referenceElement.contains(e.target) &&
      !popperElement?.contains(e.target)
    ) {
      if (currentLevel > 1) {
        dispatch(setSearchInputText(""));
      }
      closePopup();
    }
  });

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start", // placement of usePopper
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          mainAxis: false, // true by default
        },
      },
    ],
  });

  const [fetchingOptions, setIsFetchingOptions] = useState(true);

  const areOptionsMultiSelect = isCurrentOptionLevelMultiselect(
    currentLevel,
    currentNavigationIds
  );

  const [categoryIds, setCategoryIds] = useState({});

  useEffect(() => {
    if (options) {
      setIsFetchingOptions(false);
      dispatch(setIsFetchingFilters(false));
    }
  }, [options]);

  useEffect(() => {
    dispatch(dispatchSetSearchInputText(""));
  }, []);

  function handleLevelOptionClick(
    optionSelected,
    hasNextLevelOptions,
    optionIndex
  ) {
    if (!areOptionsMultiSelect || hasNextLevelOptions) {
      setIsFetchingOptions(true);
      dispatch(setIsFetchingFilters(true));
      handleOptionClick({
        values: optionSelected,
        optionIndex,
      });

      if (!hasNextLevelOptions) {
        closePopup();
      }
    }
  }

  function handleApplyButton() {
    handleOptionClick({
      values: getFormattedResponseForMultiSelectResponse(categoryIds),
    });
    closePopup();
  }

  function closePopup() {
    setCategoryIds({});
    setShowMenu(false);
    handleClosePopup();
  }

  function getFormattedResponseForMultiSelectResponse(response) {
    const multiselectResponseValues = Object.values(response);
    const _requiredResponse = {};

    multiselectResponseValues?.forEach((multiselectField) => {
      if (
        multiselectField?.label ||
        multiselectField?.name ||
        multiselectField?.title
      ) {
        const key =
          typeof multiselectField?.label !== typeof (<></>)
            ? (multiselectField?.label ??
              multiselectField?.title ??
              multiselectField?.name)
            : multiselectField?.title;
        _requiredResponse[multiselectField?.id] = key;
      }
    });

    return _requiredResponse;
  }

  function trackShadedOptions(optionInfo, optionIndex) {
    const hasNextLevelOptions = optionInfo?.url || optionInfo?.options;

    const parentLevelInfo =
      currentNavigationIds?.[currentNavigationIds.length - 1];

    if (hasNextLevelOptions) {
      if (currentNavigationIds?.length <= 0) {
        return (
          trackIndexes?.filter((option) => option?.includes(`L.${optionIndex}`))
            ?.length > 0
        );
      }
      return (
        trackIndexes?.filter((option) =>
          option?.includes(`${parentLevelInfo?.levelPosition}.${optionIndex}`)
        )?.length > 0
      );
    }

    if (searchAndFilterCommonStoreStructure?.[parentLevelInfo?.label]) {
      const optionValues =
        searchAndFilterCommonStoreStructure?.[parentLevelInfo?.label]?.values;

      const preSelectedIds =
        typeof optionValues === typeof {}
          ? Object.keys(optionValues)
          : optionValues;

      if (typeof preSelectedIds === typeof []) {
        const isOptionSelected = preSelectedIds?.some((id) =>
          Number.isInteger(optionInfo?.id)
            ? Number(id) === Number(optionInfo?.id)
            : id === optionInfo?.id
        );

        if (isOptionSelected) {
          categoryIds[optionInfo?.id] = optionInfo;
          return true;
        }

        return false;
      }

      return (
        preSelectedIds === optionInfo?.id ||
        preSelectedIds === optionInfo?.value
      );
    }
  }

  const _trackShadedOptions = useCallback(
    (option, index) => {
      return trackShadedOptions(option, index);
    },
    [options]
  );

  const handleToggle = (e) => {
    if (!onlyTextSearch) {
      if (e.currentTarget === referenceElement && !showMenu) {
        handleFocusClick();
        setShowMenu((prev) => !prev);
      }
    }
  };

  function addCategories(option) {
    if (categoryIds?.[option?.id]) {
      delete categoryIds[option.id];
    } else {
      categoryIds[option.id] = option;
    }

    setCategoryIds({ ...categoryIds });
  }

  const OptionRenderer = ({ index, style }) => {
    const option = filteredOptions[index];
    const showIcon = option?.url || option?.options;
    const showOption = option?.show ?? true;

    const groupTitle = option?.groupTitle;

    const isGreyShadedOption = _trackShadedOptions(option, index);

    const _isGreyShadedOption = areOptionsMultiSelect
      ? categoryIds?.[option?.id]
      : isGreyShadedOption;

    const fieldName =
      typeof option?.label !== typeof (<></>)
        ? option?.title || option?.label || option?.name
        : "";

    return showOption ? (
      <div
        ref={optionRef}
        key={option?.id}
        className="flex flex-col"
        style={style}
      >
        {groupTitle ? (
          <Text
            translationKey={groupTitle}
            classes="text-xs font-semibold text-neutral-500 my-6 mx-3"
          />
        ) : null}

        {option.nonClickable ? (
          <Text
            translationKey={option.label}
            classes="text-xs font-semibold text-neutral-500 my-6 mx-3"
          />
        ) : (
          <div
            className={`flex items-center justify-between  py-5 w-full  cursor-pointer hover:bg-neutral-50 px-3 ${
              _isGreyShadedOption ? "bg-neutral-100" : ""
            }`}
            onClick={() => {
              if (!option.nonClickable) {
                if (areOptionsMultiSelect) {
                  addCategories(option);
                } else {
                  handleLevelOptionClick(option, showIcon, option.index);
                }
              }
            }}
          >
            <div className="flex flex-row items-center gap-8">
              {areOptionsMultiSelect ? (
                <Checkbox
                  name={fieldName}
                  checked={categoryIds?.[option?.id]}
                  onClickHandler={() => addCategories(option)}
                />
              ) : null}

              {typeof option?.label === typeof (<></>) ? (
                option?.label
              ) : typeof option?.title === typeof "" ||
                typeof option?.label === typeof "" ||
                typeof option?.name === typeof "" ? (
                <Text
                  classes="text-base not-italic font-medium leading-6 text-neutral-500"
                  translationKey={
                    option?.title || option?.label || option?.name
                  }
                />
              ) : null}
            </div>

            {showIcon ? <Icon name="Right" /> : null}
          </div>
        )}
      </div>
    ) : null;
  };

  const filteredOptions = options.filter(
    ({ show }) => show === undefined || show === true
  );
  const optionHeight = optionRef?.current?.offsetHeight || 50;
  const optionsListHeight = () => {
    return filteredOptions.length * optionHeight > 400
      ? 400
      : filteredOptions.length * optionHeight;
  };

  return (
    <div ref={ref}>
      <button type="button" ref={setReferenceElement} onClick={handleToggle}>
        {triggerComponent({
          searchInputText,
          showBorderFocusedColor: showMenu,
          clearCategoryIds: () => {
            setCategoryIds({});
          },
          togglePopup: setShowMenu,
        })}
      </button>

      {showMenu ? (
        <Popup
          keepTooltipInside="#tooltip-inside"
          styles={styles.popper}
          attr={{
            ...attributes.popper,
          }}
          popupRef={setPopperElement}
          containerClasses="!px-0 m-0 mt-4 !pt-0 !pb-0"
        >
          {filteredOptions?.length ? (
            <div className="flex flex-col p-0">
              <div
                className={`flex flex-col gap-2  py-3 p-0  text-neutral-500 ${
                  !classes?.includes("w-") ? "w-115" : ""
                }`}
              >
                <Text translationKey="search.filterBy" classes="px-3" />

                {!fetchingOptions ? (
                  <div className="flex flex-col w-full">
                    <List
                      itemData={filteredOptions}
                      itemCount={filteredOptions?.length}
                      width="100%"
                      height={optionsListHeight()}
                      itemSize={optionHeight}
                    >
                      {OptionRenderer}
                    </List>
                  </div>
                ) : (
                  <div className="mx-5">
                    <LoaderSkeleton count={5} fullWidth heightProp={30} />
                  </div>
                )}
              </div>

              {areOptionsMultiSelect ? (
                <div
                  className="px-6 py-3 border-t border-neutral-200"
                  onClick={handleApplyButton}
                >
                  <Text
                    translationKey="Apply"
                    classes="text-base font-medium text-primary-500 cursor-pointer"
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </Popup>
      ) : null}
    </div>
  );
}

SearchFilterInputOptionsComponent.propTypes = {
  triggerComponent: PropTypes.func,
  handleOptionClick: PropTypes.func,
  handleClosePopup: PropTypes.func,
  currentNavigationIds: PropTypes.array,
  handleFocusClick: PropTypes.func,
  context: PropTypes.string,
  classes: PropTypes.string,
};
