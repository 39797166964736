import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchAccountingTransactions,
  fetchAvailableToSync,
  fetchFailedToSync,
  resetAccountingTransactionsList,
} from "@/store/reducers/accounting_transactions";
import { fetchRulesCount } from "@/store/reducers/rules";

import {
  accountingPaymentsFilterSelector,
  accountingTransactionsHasMoreSelector,
  accountingTransactionsSelector,
  accountingTransactionsTotalSelector,
  availableToSyncSelector,
  failedToSyncSelector,
  fetchingAvailableToSyncSelector,
  fetchingFailedToSyncSelector,
  isFetchingTransactionsSelector,
} from "@/store/selectors/accounting_transactions";
import {
  accountingContinuousBillSyncSelector,
  accountingIntegrationSoftwareSelector,
  isFetchingClientDetailsSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { rulesCountSelector } from "@/store/selectors/rules";

import AccountingTransactionSearchFilter from "@/components/Accounting/Transactions/AccountingTransactionSearchFilter";
import { formatDataForTable } from "@/components/Accounting/Transactions/BillPay/PageHelper/helper";
import TransactionsTable from "@/components/Accounting/Transactions/TransactionsTable";
import FailedSyncCard from "@/components/Accounting/Transactions/common/FailedSyncCard";
import RulesCard from "@/components/Accounting/Transactions/common/RulesCard";
import {
  accountingActionHandler,
  accountingBulkAction,
  formatAccountingStatusFilter,
  getSortingParams,
  stickyRightColumn,
} from "@/components/Accounting/Transactions/common/util";
import { convertFilters } from "@/utils/filters";
import { SORTING_CATEGORY, SORTING_TYPE } from "@/utils/constants/sorting";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import {
  ACCOUNTING_HEADER_IDS,
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
  TAB_STATUS_MAP,
} from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function BillPayPageHelper({ tab }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useState({
    type: null,
    category: SORTING_CATEGORY.AMOUNT,
  });
  // Transactions
  const transactions = useSelector(accountingTransactionsSelector);
  const isFetchingTransactions = useSelector(isFetchingTransactionsSelector);
  const hasMore = useSelector(accountingTransactionsHasMoreSelector);
  const totalTransactions = useSelector(accountingTransactionsTotalSelector);

  // Available to Sync
  const { syncableCount, syncableIds } = useSelector(availableToSyncSelector);
  const fetchingAvailableToSync = useSelector(fetchingAvailableToSyncSelector);

  // Failed to sync
  const failedToSync = useSelector(failedToSyncSelector);
  const fetchingFailedToSync = useSelector(fetchingFailedToSyncSelector);

  // Accounting Software
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = accountingSoftware || false;

  // Filters
  const filters = useSelector(accountingPaymentsFilterSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const requiredFilters = formatAccountingStatusFilter(
    filters,
    tab,
    accountingEnabled
  );

  const accountingContinuousBillSync = useSelector(
    accountingContinuousBillSyncSelector
  );

  const isFetchingClient = useSelector(isFetchingClientDetailsSelector);

  // Rules
  const rulesCount = useSelector(rulesCountSelector);
  // Setting headers
  const headers = [
    {
      id: ACCOUNTING_HEADER_IDS.VENDOR,
      label: "accounting.transactions.billPay.listingFields.vendor",
      classes: "text-left",
      checkbox: true,
      colWidth: 320,
    },
    {
      id: ACCOUNTING_HEADER_IDS.VENDOR_OWNER,
      label: "accounting.transactions.billPay.listingFields.vendorOwner",
      classes: "text-left",
      colWidth: 300,
    },
    {
      id: ACCOUNTING_HEADER_IDS.AMOUNT,
      label: "accounting.transactions.billPay.listingFields.amount",
      classes: "text-right justify-end",
      sortable: true,
      colWidth: 200,
    },
    {
      id: ACCOUNTING_HEADER_IDS.INVOICE,
      label: "accounting.transactions.billPay.listingFields.invoice",
      classes: "text-center flex-1",
      colWidth: 120,
    },
    {
      id: ACCOUNTING_HEADER_IDS.LINKED_TO,
      label: "Linked to",
      classes: "text-right",
      colWidth: 200,
      showColumn: true,
    },
    {
      id: ACCOUNTING_HEADER_IDS.ACTION,
      label: "accounting.transactions.billPay.listingFields.action",
      classes: "text-center justify-center flex-1 items-center",
      showColumn: tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED,
      colWidth: 145,
    },
    {
      id: ACCOUNTING_HEADER_IDS.OPEN_IN,
      label: "Open in",
      classes: "text-center justify-center",
      showColumn:
        tab === ACCOUNTING_TRANSACTION_STATUS.SYNCED &&
        ![
          ACCOUNTING_SOFTWARES.UNIVERSAL_CSV,
          ACCOUNTING_SOFTWARES.TALLY,
        ].includes(accountingSoftware),
      colWidth: 145,
    },
  ].filter((item) => item?.showColumn ?? true);

  // Selected transactions
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedTransactionObjects, setSelectedTransactionObjects] = useState(
    []
  );
  const [bulkApproveSelectAll, setBulkApproveSelectAll] = useState(false);

  // Selection actions
  const handleRowSelection = (transaction) => {
    if (selectedTransactions?.includes(transaction.accountingId)) {
      setSelectedTransactions((prev) =>
        prev.filter((transactionId) => {
          return transactionId !== transaction.accountingId;
        })
      );
      setSelectedTransactionObjects((prev) =>
        prev.filter((prevTransaction) => {
          return prevTransaction.accountingId !== transaction.accountingId;
        })
      );
    } else {
      setSelectedTransactions((prev) => [...prev, transaction.accountingId]);
      setSelectedTransactionObjects((prev) => [...prev, transaction]);
    }
  };

  const handleAllTransactions = () => {
    setSelectedTransactions(
      transactions.map((transaction) => transaction.accountingId)
    );
    setSelectedTransactionObjects(transactions);
  };

  const clearSelectedTransactions = () => {
    setSelectedTransactions([]);
    setSelectedTransactionObjects([]);
  };

  // Infinite Scroll
  const onReset = () => {
    dispatch(resetAccountingTransactionsList());
  };

  const loadMore = (pageNumber = 1, onSuccess = () => {}) => {
    // we have 2 cases, connected to accounting and NOT connected to accounting software
    // Not connected: we need to ensure that we're calling the API on first load
    // Connected: only call the API if tab is passed to prevent duplicate calls when refreshing on pending and synced tabs
    if ((tab || !accountingEnabled) && !isFetchingClient) {
      dispatch(
        fetchAccountingTransactions({
          type: ACCOUNTING_TRANSACTION_PAGES.BILL_PAY,
          accounting_status: TAB_STATUS_MAP[tab],
          page: pageNum || pageNumber,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          ...getSortingParams(sorting),
          ...convertFilters(appliedFilters),
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (!isFetchingClient) {
      loadMore();
    }
  }, [isFetchingClient]);

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...getSortingParams(sorting),
      ...convertFilters(appliedFilters),
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.25,
  });

  // First load
  useEffect(() => {
    onReset();
    dispatch(
      fetchFailedToSync({
        transaction: ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL,
      })
    );
    dispatch(fetchRulesCount());
  }, [tab]);

  // Converting transactions to data format
  const formattedTransactions = useMemo(() => {
    return transactions?.map((transaction) => formatDataForTable(transaction));
  }, [transactions]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(resetAccountingTransactionsList());
    };
  }, [tab]);

  const handleViewBillPayTransaction = (id) => {
    let changed = false;
    if (!searchParams.has(SLIDERS_SEARCH_PARAMS.accounting.billPay.id, id)) {
      changed = true;
      searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.billPay.id, id);
    }
    if (
      !searchParams.has(
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider,
        SLIDERS_SEARCH_PARAMS.accounting.billPay.id
      )
    ) {
      changed = true;
      searchParams.append(
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider,
        SLIDERS_SEARCH_PARAMS.accounting.billPay.id
      );
    }
    if (
      !searchParams.has(
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey,
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments
      )
    ) {
      changed = true;
      searchParams.append(
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey,
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments
      );
    }
    if (changed) setSearchParams(searchParams);
  };

  const openRules = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.id, "true");
    setSearchParams(searchParams);
  };

  // Setting props for table
  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 0,
    numberOfStickyColsRight: stickyRightColumn(accountingSoftware, tab) ? 1 : 0,
    colWidths: headers.map((header) => header.colWidth),
    rightColWidths: !accountingSoftware ? [] : [145],
    emptyDataIconSrc: "emptyScreen.svg",
  };

  const handleFailedToSyncSliderOpen = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.failedToSync,
      ACCOUNTING_TRANSACTION_PAGES.BILL_PAY
    );
    setSearchParams(searchParams);
  };

  return (
    <>
      {accountingEnabled ? (
        <div className="flex items-center gap-4 mt-6 mb-1">
          {accountingSoftware !== ACCOUNTING_SOFTWARES.UNIVERSAL_CSV ? (
            <FailedSyncCard
              count={failedToSync}
              isFetching={fetchingFailedToSync}
              handleClick={() => handleFailedToSyncSliderOpen()}
            />
          ) : null}
          <RulesCard rulesCount={rulesCount} viewRules={openRules} />
        </div>
      ) : null}
      <div className="mb-2">
        <AccountingTransactionSearchFilter
          filters={requiredFilters}
          showSyncAll={accountingEnabled && !accountingContinuousBillSync}
          availableToSync={syncableCount}
          fetchingAvailableToSync={fetchingAvailableToSync}
          syncAll={accountingBulkAction}
          syncableIds={syncableIds}
          page={ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL}
        />
      </div>
      {formattedTransactions && (
        <TransactionsTable
          pageNum={pageNum}
          tab={tab}
          page={ACCOUNTING_TRANSACTION_PAGES.BILL_PAY}
          tableArgs={tableArgs}
          headers={headers}
          originalTransactions={transactions}
          transactions={formattedTransactions}
          totalTransactions={totalTransactions}
          isFetching={isFetchingTransactions}
          hasMore={hasMore}
          dateToBeGrouped="invoiceDate"
          actionDisabled={!accountingSoftware}
          handleRefChange={handleRefChange}
          handleAllRows={handleAllTransactions}
          handleRowSelection={handleRowSelection}
          handleBulkAction={accountingBulkAction}
          loadMore={loadMore}
          selectedTransactions={selectedTransactions}
          selectedTransactionObjects={selectedTransactionObjects}
          clearSelectedTransactions={clearSelectedTransactions}
          onActionClick={accountingActionHandler}
          onViewTransactionClick={handleViewBillPayTransaction}
          setSelectedTransactions={setSelectedTransactions}
          setSelectedTransactionObjects={setSelectedTransactionObjects}
          bulkApproveSelectAll={bulkApproveSelectAll}
          setBulkApproveSelectAll={setBulkApproveSelectAll}
          sorting={sorting}
          setSorting={setSorting}
        />
      )}
    </>
  );
}

BillPayPageHelper.propTypes = {
  tab: PropTypes.string,
};
