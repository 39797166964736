import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAndSelectUser } from "@/store/reducers/user";

import { overviewMyVolopayExpenseFilters } from "@/store/selectors/expense";
import { userSelector } from "@/store/selectors/user";

import FlaggedPageHelper from "@/components/common/QrPayAndExpense/FlaggedPageHelper";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";

function MyVolopayExpensesFlaggedPage() {
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const myVolopayFilter = useSelector(overviewMyVolopayExpenseFilters);

  useEffect(() => {
    dispatch(fetchAndSelectUser({ userId: currentUser?.id }));
  }, []);
  return (
    <FlaggedPageHelper
      page={QRPAY_EXPENSES_CONTEXT.EXPENSES}
      filteringParams={{ user: currentUser?.id }}
      filterSelectorFromParent={myVolopayFilter}
    />
  );
}

export default MyVolopayExpensesFlaggedPage;
