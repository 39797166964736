import React from "react";

import ReimbursementReportHelper from "@/components/ReimbursementReport/ReimbursementReportHelper";
import { TAB_TYPES } from "@/utils/constants/myVolopay";

function MyVolopayReimbursementReportsActivePage() {
  return (
    <div className="mt-2">
      <ReimbursementReportHelper tabType={TAB_TYPES.ACTIVE} />
    </div>
  );
}

export default MyVolopayReimbursementReportsActivePage;
