import PropsTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchUserReimbursements,
  fetchUserReport,
  setUserReimbursementInitialState,
  setUserReportInitialState,
} from "@/store/reducers/reimbursement";

import {
  isFetchingClientDetailsSelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isFetchinguserReportSelector,
  isUserReimbursementsFetchingSelector,
  paidFiltersSelector,
  paymentsFiltersSelector,
  userReimbursementHasMoreSelector,
  userReimbursementListSelector,
  userReimbursementTotalSelector,
  userReportHasMoreSelector,
  userReportListSelector,
  userReportTotalSelector,
} from "@/store/selectors/reimbursement";

import Filters from "@/components/core/Filters";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Table from "@/components/core/Table";

import Export from "@/components/Exports";
import {
  REIMBURSEMENT_PAYMENT_PAID_TABLE_HEADER,
  REPORT_PAYMENT_PAID_TABLE_HEADER,
} from "@/components/Reimbursement/tableConfig";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import { convertFilters } from "@/utils/filters";
import { SORTING_TYPE, SORT_KEYS } from "@/utils/constants/sorting";
import { REIMBURSEMENT_PAGE_TYPE } from "@/utils/constants/reimbursement";
import { debounce } from "@/utils/common";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export const CONFIG = {
  reimbursement: {
    listSelector: userReimbursementListSelector,
    isFetchingSelector: isUserReimbursementsFetchingSelector,
    totalSelector: userReimbursementTotalSelector,
    hasMoreSelector: userReimbursementHasMoreSelector,
  },

  report: {
    listSelector: userReportListSelector,
    isFetchingSelector: isFetchinguserReportSelector,
    totalSelector: userReportTotalSelector,
    hasMoreSelector: userReportHasMoreSelector,
  },
};

export default function ReimbursementPaymentsList({ type, onClickHandler }) {
  const dispatch = useDispatch();
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);
  const isFetchingReportEnabledData = useSelector(
    isFetchingClientDetailsSelector
  );

  const [config, setConfig] = useState(CONFIG.reimbursement);

  const { listSelector, isFetchingSelector, totalSelector, hasMoreSelector } =
    config;

  const list = useSelector(listSelector);
  const isFetching = useSelector(isFetchingSelector);
  const total = useSelector(totalSelector);
  const hasMore = useSelector(hasMoreSelector);
  const isEmpty = !list.length;

  const paidFilters = useSelector(paidFiltersSelector);
  const paymentsFilters = useSelector(paymentsFiltersSelector);
  const filters =
    type === REIMBURSEMENT_PAGE_TYPE.paid ? paidFilters : paymentsFilters;
  const appliedFilters = useSelector(appliedFilterSelector);

  const [searchtext, setSearchText] = useState("");
  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  const updateApi = debounce((text) => {
    setSearchText(text);
  }, 1000);
  const filterOptions = {
    type: REIMBURSEMENT_PAGE_TYPE[type],
    q: searchtext,
  };
  const onReset = () => {
    if (reportEnabled) {
      dispatch(setUserReportInitialState());
    } else {
      dispatch(setUserReimbursementInitialState());
    }
  };
  const loadMore = () => {
    const payload = {
      page: pageNum,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      ...filterOptions,
      ...convertFilters(appliedFilters),
      [SORT_KEYS.COLUMN]: sorting?.category,
      [SORT_KEYS.DIRECTION]: sorting?.type,
    };

    if (!isFetchingReportEnabledData) {
      if (reportEnabled) {
        dispatch(fetchUserReport(payload));
      } else {
        dispatch(fetchUserReimbursements(payload));
      }
    }
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...convertFilters(appliedFilters),
      ...sorting,
      reportEnabled,
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);
  const handleSorting = (category) => {
    setSorting((prev) => {
      return {
        category,
        type:
          prev.type === SORTING_TYPE.INC ? SORTING_TYPE.DEC : SORTING_TYPE.INC,
      };
    });
  };

  useEffect(() => {
    if (reportEnabled) {
      setConfig(CONFIG.report);
    }
  }, [reportEnabled]);

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
    emptyDataTitle,
    emptySubHeadingDataTitle,
  } = reportEnabled
    ? REPORT_PAYMENT_PAID_TABLE_HEADER[type]
    : REIMBURSEMENT_PAYMENT_PAID_TABLE_HEADER[type];

  return (
    (!isFetchingReportEnabledData || !list.length) && (
      <>
        <div className="flex items-center justify-between mt-6">
          <div className="w-full">
            <Filters filters={filters} />
          </div>
          <div className="flex justify-end ">
            <Export
              totalExports={total}
              storeName="reimbursement"
              sectionName="reimbursements"
              selectedFilter={appliedFilters}
              additionalFilters={{
                export_type: "ReimbursementPayment",
                type: REIMBURSEMENT_PAGE_TYPE[type],
              }}
            />
          </div>
        </div>
        <Table
          {...{
            ...tableSetting,
            emptyDataTitle,
            emptyDataDescription: emptySubHeadingDataTitle,
          }}
        >
          <tr className={tableHeadingRowClasses}>
            {tableHeading.map((headVal, index) => (
              <th className={headVal.classes} key={headVal.id}>
                <HeaderCell
                  val={{
                    ...headVal,
                    disabled: isEmpty,
                    showCheckbox: false,
                    sorting,
                    setSorting,
                  }}
                />
              </th>
            ))}
          </tr>

          {(!isFetching || !isEmpty) &&
            list?.map((val, index, arr) => (
              <tr
                key={val.id}
                className={`${tableDataRowClasses} ${
                  val.selected ? "selected-row-cell" : ""
                } `}
                ref={(ref) => {
                  if (index === arr.length - 1 && hasMore) {
                    handleRefChange(ref);
                  }
                }}
              >
                {tableHeading.map(
                  ({ cellComponent: Component, classes, id }) => (
                    <td className={classes} key={id}>
                      <Component
                        val={{
                          ...val,
                          onClickHandler,
                          showCheckbox: false,
                          type,
                        }}
                        key={id}
                      />
                    </td>
                  )
                )}
              </tr>
            ))}
          {isFetching &&
            [...Array(10)].map((val, i) => (
              <tr className="text-center" key={i}>
                <td className="flex items-center gap-3 ml-4">
                  <LoaderSkeleton type="circle" />
                  <LoaderSkeleton size={[20, 180]} />
                </td>
                <td className="text-right">
                  <LoaderSkeleton size="lg" />
                </td>
                <td className="text-center">
                  <LoaderSkeleton size="lg" />
                </td>
                <td className="text-left">
                  <LoaderSkeleton />
                </td>
                <td className="text-left">
                  <LoaderSkeleton />
                </td>
              </tr>
            ))}
        </Table>
      </>
    )
  );
}

ReimbursementPaymentsList.propTypes = {
  onClickHandler: PropsTypes.func,
  type: PropsTypes.string,
};
