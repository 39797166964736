import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { rejectCardRequest } from "@/store/reducers/actionCentre";
import { setIndexApiReload } from "@/store/reducers/app";
import { getRequestApprovalInfo } from "@/store/reducers/cards";

import { actionInProgressIdSelector } from "@/store/selectors/actionCentre";
import { indexApiReloadSelector } from "@/store/selectors/app";
import {
  cardRequestDetailsIsFetchingSelector,
  newCardRequestDetailsSelector,
} from "@/store/selectors/cards";
import { userSelector } from "@/store/selectors/user";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import Timeline from "@/components/core/Timeline";
import { useForm } from "@/utils/useForm";
import { TYPES_TIMELINE } from "@/utils/constants/timeline";
import { amountToCurrency } from "@/utils/common";

import {
  ACTION_CENTRE_APPROVAL_TYPE,
  BE_FE_KEY_ORDER_TYPE_MAPPING,
  CARD_REQUEST_TYPE,
} from "@/constants/ActionCentre";
import { CARD_MODULE_REQUEST_PAGE_TABS, CARD_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { BE_FE_REQUEST_TYPE_CARD_REQUEST } from "@/constants/company";
import { APPROVAL_REQUEST_TYPE } from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";

import CardLimitReview from "../CardLimitRequestSlider/OverviewTab/CardLimitReview";
import OrderPhysicalCardOverviewCards from "./PhysicalCardRequestSlider/OverviewTab/OrderPhysicalCardOverviewCards";
import ReviewCardOrderRequestDetails from "./PhysicalCardRequestSlider/OverviewTab/ReviewCardOrderRequestDetails";
import RejectCardOrderDetailsSummary from "./VirtualCardRequestSlider/OverviewTab/RejectCardOrderDetailsSummary";

function RejectCardRequest() {
  const [searchParams, setSearchParams] = useSearchParams();

  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);

  const isFetching = useSelector(cardRequestDetailsIsFetchingSelector);

  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const isEmpty = Object.keys(cardRequestDetails ?? {}).length === 0;

  const currentUser = useSelector(userSelector);

  const dispatch = useDispatch();

  const actionInProgress = useSelector(actionInProgressIdSelector);

  const page =
    window.location.pathname ===
    ROUTES.myVolopay.actionCentre.cards.absolutePath
      ? "actionCentre"
      : "cards";

  const isCardModulePage = page === "cards";

  const initialValues = {
    rejectComment: {
      value: "",
      validate: {
        required: true,
      },
    },
  };

  const formSchema = [
    {
      name: "rejectComment",
      label:
        "myVolopay.actionCentre.sliders.rejectOrderSlider.fields.comment.label",
      placeholder:
        "myVolopay.actionCentre.sliders.rejectOrderSlider.fields.comment.label",
      type: "text",
    },
  ];

  const onSucess = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest
    );
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest?.[
        BE_FE_REQUEST_TYPE_CARD_REQUEST[cardRequestDetails?.requestType]
      ]
    );
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest
    );

    dispatch(setIndexApiReload(!reloadIndexApi));

    setSearchParams(searchParams);
  };
  const handleReject = (value) => {
    dispatch(
      rejectCardRequest({
        target_id: cardRequestDetails?.id,
        type: ACTION_CENTRE_APPROVAL_TYPE.card,
        ...(value?.rejectComment?.length
          ? { comment: value?.rejectComment }
          : {}),
        onSucess,
      })
    );
  };

  const { handleChange, values, errors, handleSubmit, isFormButtonDisabled } =
    useForm(initialValues, (e, value) => {
      handleReject(value);
    });

  function handleCancel() {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest
    );
    setSearchParams(searchParams);
  }
  const { isRequestTypeTopUp, sliderTitle } = useMemo(() => {
    const _isRequestTypeTopUp =
      CARD_REQUEST_TYPE.TOP_UP_REQUEST === cardRequestDetails?.requestType;
    const title = _isRequestTypeTopUp
      ? "myVolopay.actionCentre.sliders.rejectOrderSlider.labelTopUp"
      : "myVolopay.actionCentre.sliders.rejectOrderSlider.label";
    return { isRequestTypeTopUp: _isRequestTypeTopUp, sliderTitle: title };
  }, [cardRequestDetails?.requestType]);
  const ref = useLeftHeaderTitle({
    title: sliderTitle,
  });

  const BULK_ORDER_PHYSICAL_KEYS = Object.entries(BE_FE_KEY_ORDER_TYPE_MAPPING)
    .filter(([key, value]) =>
      [
        BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderNonPersonalized,
        BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderPersonalized,
      ]?.includes(value)
    )
    ?.map(([key, value]) => key);

  useEffect(() => {
    const id = searchParams.get(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest
    );

    if (id) dispatch(getRequestApprovalInfo({ id }));
  }, [searchParams]);

  return (
    <>
      {!isFetching && !isEmpty ? (
        <>
          <div className="flex flex-col slider-content-core gap-7">
            <div ref={ref} className="flex flex-col gap-3">
              <Text
                translationKey={sliderTitle}
                classes="text-4xl font-bold non-italic"
              />

              <Text
                translationKey="myVolopay.actionCentre.sliders.rejectOrderSlider.description"
                classes="text-sm text-neutral-500 font-medium"
              />
              <div className="mt-5">
                <Timeline
                  status={cardRequestDetails?.currentApprovalLevelStatus}
                  currentApprovers={
                    cardRequestDetails?.currentApprovers
                      ? cardRequestDetails?.currentApprovers
                      : []
                  }
                  rejectedReason={cardRequestDetails?.rejectedReason}
                  currentApproverLevel={
                    cardRequestDetails?.currentApprovalLevel
                  }
                  totalApprovers={cardRequestDetails?.approvalLevels}
                  type={TYPES_TIMELINE.CARD_REQUEST}
                  id={cardRequestDetails?.id}
                />
              </div>
            </div>

            <OrderPhysicalCardOverviewCards
              requestOwner={cardRequestDetails?.requestOwner}
              cardHolder={
                BULK_ORDER_PHYSICAL_KEYS?.includes(
                  cardRequestDetails?.orderType
                )
                  ? null
                  : cardRequestDetails?.cardHolder
              }
              orderType={cardRequestDetails?.orderType}
            />
            {cardRequestDetails?.requestType ===
              CARD_REQUEST_TYPE.PHYSICAL_CARD_ORDER &&
            cardRequestDetails?.requestType !==
              CARD_REQUEST_TYPE.TOP_UP_REQUEST ? (
              <ReviewCardOrderRequestDetails
                bulkOrderKeysArray={BULK_ORDER_PHYSICAL_KEYS}
                data={cardRequestDetails}
              />
            ) : (
              <RejectCardOrderDetailsSummary data={cardRequestDetails} />
            )}

            {cardRequestDetails?.requestType ===
            CARD_REQUEST_TYPE.TOP_UP_REQUEST ? (
              <CardLimitReview data={cardRequestDetails} />
            ) : null}
            <Text
              classes="text-lg font-semibold"
              translationKey="myVolopay.actionCentre.sliders.rejectOrderSlider.fields.comment.mainHeading"
            />

            <form
              className="mb-9"
              id="approve-limit-request"
              onSubmit={handleSubmit}
            >
              {formSchema.map((field, index) => {
                const { label, name, type, placeholder } = field;

                let FieldComponent = <></>;

                switch (type) {
                  case "text":
                    FieldComponent = (
                      <Input
                        label={label}
                        name={name}
                        value={values[name]}
                        type={type}
                        placeholder={placeholder}
                        onChange={handleChange}
                        error={errors[name]}
                      />
                    );
                    break;
                  default:
                    break;
                }

                return <div key={name + index}>{FieldComponent}</div>;
              })}
            </form>
          </div>

          <div className="flex flex-row justify-end gap-6 px-3 py-5 slider-footer">
            <Button
              classes="w-16 text-neutral-500"
              label="myVolopay.actionCentre.sliders.rejectOrderSlider.buttons.cancel"
              onClick={handleCancel}
              disabled={actionInProgress}
              variant="tertiary"
              type="default"
            />

            <Button
              classes="w-15.5 h-10"
              type="danger"
              disabled={isFormButtonDisabled}
              showLoader={actionInProgress}
              btnType="submit"
              label="myVolopay.actionCentre.sliders.rejectOrderSlider.buttons.reject"
              form="approve-limit-request"
            />
          </div>
        </>
      ) : null}
      {isFetching ? "Loading..." : null}
      {!isFetching && isEmpty ? "Error" : null}
    </>
  );
}

export default RejectCardRequest;
