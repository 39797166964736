import qs from "qs";

import {
  getCategoriesForSearchFilters,
  getCategoryOptions,
} from "@/store/reducers/filters";
import Icon from "@/components/core/Icon";
import Chip from "@/components/core/Chip";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_USER_STATUS,
  PENDING_REQUEST_TABS_KEYS,
} from "@/constants/company";
import { ROUTES } from "@/constants/routes";
import { TAGS_TYPE_CONFIG } from "@/constants/tags";

export const SEARCH_AND_FILTER_PARAM = {
  category: "search_cat",
  valueName: "value",
};

export const BACKSPACE_KEY = "Backspace";
export const ENTER_KEY = "Enter";
export const SEARCH_PARAM_KEY = "searchParam";
export const SEARCH_PAYLOAD_PARAM_KEY = "q";

export const KEY_TO_CHECK = {
  title: "title",
};

export const SEARCH_AND_FILTER_KEYS = {
  EXPENSE_TYPE: "expense_type",
  USER: "user",
  CARD: "card",
  MERCHANT: "merchant",
  CATEGORY: "category",
  PROJECT: "project",
  LOCATION: "location",
  TAGS: "tag_values",
  EMAIL: "email",
  ROLE: "role",
  VENDOR: "vendor",
};

export const LEVEL_OPTIONS_TYPE = {
  DEFAULT: "default",
  DYNAMIC: "dynamic",
};

export const SEARCH_FILTER_OPTIONS_UI_TYPE = {
  DYNAMIC: "dynamic",
  DEFAULT: "default",
};

export const SEARCH_FILTER_OPTIONS_SELECT_TYPE = {
  SINGLE_SELECT: "singleSelect",
  MUTLI_SELECT: "multiSelect",
};

export const CATEGORY_KEYS_LABEL_MAPPING = {
  DEPARTMENT: "Department",
  PROJECT: "Project",
  LOCATION: "Location",
};

export const PARAMS_CREATION_TYPE = {
  DEFAULT: "default", // the key which we are getting will be allocated with the value
  DYNAMIC: "dynamic", // If we want to create our own way of params by taking the data
};

/*
1. selectType - Whether they are single select or multiselect
2. processOptions - Do we need to group the options which we have gotten
3. uiType - Whether the options are plain text or dynamic UI.

 */

/*
    getDynamicOptionUi -> Is a function used for generating options dynamically.
    1. If you want to have a dynamic UI not simple text then we use this function.
    2. When we want to group the options which we have gotten from api into different categories.

    uiType -- can be dynamic or default with normal text, for dynamic we pass getDynamicOptionUI
    selectType --- can be multiselct or single select
 */

/*
  This is a category config, it actually has props and actions for a cateogory and its next levels
 */

export const CATEGORIES_LEVELS_CONFIG = {
  [SEARCH_AND_FILTER_KEYS.EXPENSE_TYPE]: [
    null,
    {
      processOptions: false,
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DEFAULT,
    },
  ],
  [SEARCH_AND_FILTER_KEYS.USER]: [
    null,
    {
      processOptions: false,
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DYNAMIC,
      getDynamicOptionUi: (optionProps) => {
        const {
          label = null,
          title = "",
          id = null,
          index,
        } = getOptionsForPeopleMultiSelect(optionProps);

        return { label, title, id, index };
      },
    },
  ],
  [SEARCH_AND_FILTER_KEYS.CARD]: [
    null,
    {
      processOptions: false,
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DYNAMIC,
      getDynamicOptionUi: (cardProps) => {
        const { label = null, title = "", id } = getCardsOptionUi(cardProps);
        return { label, title, id };
      },
    },
  ],
  [SEARCH_AND_FILTER_KEYS.MERCHANT]: [
    null,
    {
      processOptions: false,
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DEFAULT,
    },
  ],
  [SEARCH_AND_FILTER_KEYS.CATEGORY]: [
    null,
    {
      processOptions: false,
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DEFAULT,
    },
  ],
  [SEARCH_AND_FILTER_KEYS.PROJECT]: [
    null,
    {
      processOptions: false,
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DEFAULT,
    },
  ],
  [SEARCH_AND_FILTER_KEYS.LOCATION]: [
    null,
    {
      processOptions: false,
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DEFAULT,
    },
  ],
  [SEARCH_AND_FILTER_KEYS.TAGS]: [
    null,
    {
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DEFAULT,
      processOptions: true,
      getProcessedOptions: (options) => getTagsGroupedOptions(options),
      generateParams: (optionProps) =>
        getParamNameForAccountingTags(optionProps?.values),
    },
  ],
  [SEARCH_AND_FILTER_KEYS.EMAIL]: [
    null,
    {
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DYNAMIC,
      getDynamicOptionUi: (emailProps) => {
        return getEmailOptionsUi(emailProps);
      },
    },
  ],
  [SEARCH_AND_FILTER_KEYS.ROLE]: [
    null,
    {
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DYNAMIC,
      getDynamicOptionUi: (roleProps) => {
        return getRolesOptionsUi(roleProps);
      },
    },
  ],
  [SEARCH_AND_FILTER_KEYS.VENDOR]: [
    null,
    {
      uiType: SEARCH_FILTER_OPTIONS_UI_TYPE.DYNAMIC,
      getDynamicOptionUi: (vendorProps, parentNavInfo, t) => {
        return getVendorOptionsUi(vendorProps, parentNavInfo, t);
      },
    },
  ],
};

/*
  The following is a page level function for search and filter.
  Its major task is to give the module value and onlyTextSearch value
  onlyTextSearch means, the page won't have the functionality to toggle with options they will only be able to search.
  returns {module: "",onlyTextSearch: "" }
 */

export function getModuleValueForSliderBySliderId(
  sliderConfig,
  defaultPageSearchAndFilterConfig
) {
  const { sliderId = null, tabName = null } = sliderConfig;

  switch (sliderId) {
    case SLIDERS_SEARCH_PARAMS.cards.actionCentre.myPendingRequest:
    case SLIDERS_SEARCH_PARAMS.company.people.pendingRequest:
    case SLIDERS_SEARCH_PARAMS.myVolopay.actionCentre.requestHistory:
      if (tabName === PENDING_REQUEST_TABS_KEYS.CARDS) {
        defaultPageSearchAndFilterConfig.moduleValue = "action_center/cards";
      } else if (tabName === PENDING_REQUEST_TABS_KEYS.BILL_PAY) {
        defaultPageSearchAndFilterConfig.moduleValue = "action_center/bill_pay";
      } else if (tabName === PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT) {
        defaultPageSearchAndFilterConfig.moduleValue =
          "action_center/reimbursement";
      } else if (tabName === PENDING_REQUEST_TABS_KEYS.PAYROLL) {
        defaultPageSearchAndFilterConfig.moduleValue = "action_center/payroll";
      }
      break;

    case SLIDERS_SEARCH_PARAMS.company.rolesPermissions.role:
      defaultPageSearchAndFilterConfig.moduleValue = "roles_and_permissions";
      defaultPageSearchAndFilterConfig.onlyTextSearch = true;
      break;
    case SLIDERS_SEARCH_PARAMS.company.people.pendingInvite:
      defaultPageSearchAndFilterConfig.moduleValue = "people_invite";
      defaultPageSearchAndFilterConfig.onlyTextSearch = true;
      break;
    default:
      break;
  }
}

export function getModuleValueBasedOnPagePathName(pagePathName, sliderConfig) {
  const defaultPageSearchAndFilterConfig = {
    moduleValue: "",
    onlyTextSearch: false,
  };

  if (sliderConfig) {
    getModuleValueForSliderBySliderId(
      sliderConfig,
      defaultPageSearchAndFilterConfig
    );

    return defaultPageSearchAndFilterConfig;
  }

  switch (pagePathName) {
    case `${ROUTES.cards.expenses.overView.absolutePath}`:
    case `${ROUTES.cards.expenses.needsReview.needsYourReview.absolutePath}`:
    case `${ROUTES.cards.expenses.needsReview.all.absolutePath}`:
    case `${ROUTES.cards.expenses.flagged.absolutePath}`:
    case `${ROUTES.cards.expenses.declines.absolutePath}`:
    case `${ROUTES.accounting.transactions.cards.all.absolutePath}`:
    case `${ROUTES.accounting.transactions.cards.pending.absolutePath}`:
    case `${ROUTES.accounting.transactions.cards.synced.absolutePath}`:
    case `${ROUTES.accounting.transactions.cards.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "expense";
      if (
        [
          ROUTES.cards.expenses.declines.absolutePath,
          ROUTES.accounting.transactions.cards.absolutePath,
          ROUTES.accounting.transactions.cards.all.absolutePath,
          ROUTES.accounting.transactions.cards.pending.absolutePath,
          ROUTES.accounting.transactions.cards.synced.absolutePath,
        ].includes(pagePathName)
      ) {
        defaultPageSearchAndFilterConfig.status = "declined";
      }
      break;

    case `${ROUTES.cards.physicalCards.all.absolutePath}`:
    case `${ROUTES.cards.physicalCards.blocked.absolutePath}`:
    case `${ROUTES.cards.physicalCards.yetToActivate.absolutePath}`:
      if (pagePathName === ROUTES.cards.physicalCards.all.absolutePath) {
        defaultPageSearchAndFilterConfig.status = "active";
      } else if (
        pagePathName === ROUTES.cards.physicalCards.yetToActivate.absolutePath
      ) {
        defaultPageSearchAndFilterConfig.status = "inactive";
      }
      defaultPageSearchAndFilterConfig.moduleValue = "physical_card";
      break;

    case `${ROUTES.cards.virtualCards.all.absolutePath}`:
    case `${ROUTES.cards.virtualCards.blocked.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "virtual_card";
      defaultPageSearchAndFilterConfig.status = "active";
      break;

    case `${ROUTES.cards.requests.needsYourAction.absolutePath}`:
    case `${ROUTES.cards.requests.all.absolutePath}`:
    case `${ROUTES.cards.requests.pending.absolutePath}`:
      if (pagePathName === ROUTES.cards.requests.pending.absolutePath) {
        defaultPageSearchAndFilterConfig.status = "pending";
      } else if (pagePathName === ROUTES.cards.requests.all.absolutePath) {
        defaultPageSearchAndFilterConfig.status = "all";
      }
      defaultPageSearchAndFilterConfig.moduleValue = "card_request";
      break;

    case `${ROUTES.billpay.bill.invoiceInbox.absolutePath}`:
    case `${ROUTES.billpay.bill.approvals.needsApproval.absolutePath}`:
    case `${ROUTES.billpay.bill.approvals.pending.absolutePath}`:
    case `${ROUTES.billpay.bill.approvals.all.absolutePath}`:
    case `${ROUTES.billpay.bill.payments.all.absolutePath}`:
    case `${ROUTES.billpay.bill.payments.failed.absolutePath}`:
    case `${ROUTES.billpay.bill.paid.absolutePath}`:
    case `${ROUTES.accounting.transactions.billpay.all.absolutePath}`:
    case `${ROUTES.accounting.transactions.billpay.pending.absolutePath}`:
    case `${ROUTES.accounting.transactions.billpay.synced.absolutePath}`:
    case `${ROUTES.accounting.transactions.billpay.absolutePath}`:
      if (pagePathName === ROUTES.billpay.bill.invoiceInbox.absolutePath) {
        defaultPageSearchAndFilterConfig.status = "invoice";
      } else if (
        [
          ROUTES.billpay.bill.payments.failed.absolutePath,
          ROUTES.billpay.bill.payments.all.absolutePath,
        ].includes(pagePathName)
      ) {
        defaultPageSearchAndFilterConfig.status = "failed";
      } else if (
        [
          ROUTES.billpay.bill.approvals.needsApproval.absolutePath,
          ROUTES.billpay.bill.approvals.pending.absolutePath,
          ROUTES.billpay.bill.approvals.all.absolutePath,
        ].includes(pagePathName)
      ) {
        defaultPageSearchAndFilterConfig.status = "approvals";
      }

      defaultPageSearchAndFilterConfig.moduleValue = "bill_pay";
      break;

    case `${ROUTES.billpay.vendors.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "vendors";
      break;

    case `${ROUTES.payroll.salaryPayment.payrollInbox.absolutePath}`:
    case `${ROUTES.payroll.salaryPayment.approvals.needsApproval.absolutePath}`:
    case `${ROUTES.payroll.salaryPayment.approvals.pending.absolutePath}`:
    case `${ROUTES.payroll.salaryPayment.approvals.all.absolutePath}`:
    case `${ROUTES.payroll.salaryPayment.payments.all.absolutePath}`:
    case `${ROUTES.payroll.salaryPayment.payments.failed.absolutePath}`:
    case `${ROUTES.payroll.salaryPayment.paid.absolutePath}`:
    case `${ROUTES.payroll.payrollEmployees.absolutePath}`:
    case `${ROUTES.accounting.transactions.payroll.all.absolutePath}`:
    case `${ROUTES.accounting.transactions.payroll.pending.absolutePath}`:
    case `${ROUTES.accounting.transactions.payroll.synced.absolutePath}`:
    case `${ROUTES.accounting.transactions.payroll.absolutePath}`:
      if (
        pagePathName === ROUTES.payroll.salaryPayment.payrollInbox.absolutePath
      ) {
        defaultPageSearchAndFilterConfig.status = "inbox";
      } else if (
        pagePathName === ROUTES.payroll.payrollEmployees.absolutePath
      ) {
        defaultPageSearchAndFilterConfig.status = "employee";
      } else if (
        [
          ROUTES.payroll.salaryPayment.payments.failed.absolutePath,
          ROUTES.payroll.salaryPayment.payments.all.absolutePath,
        ].includes(pagePathName)
      ) {
        defaultPageSearchAndFilterConfig.status = "payments";
      }
      defaultPageSearchAndFilterConfig.moduleValue = "payroll";
      break;

    case `${ROUTES.reimbursement.approvals.needYourAttention.absolutePath}`:
    case `${ROUTES.reimbursement.approvals.pending.absolutePath}`:
    case `${ROUTES.reimbursement.approvals.all.absolutePath}`:
    case `${ROUTES.reimbursement.paid.absolutePath}`:
    case `${ROUTES.reimbursement.payments.absolutePath}`:
    case `${ROUTES.accounting.transactions.reimbursement.all.absolutePath}`:
    case `${ROUTES.accounting.transactions.reimbursement.pending.absolutePath}`:
    case `${ROUTES.accounting.transactions.reimbursement.synced.absolutePath}`:
    case `${ROUTES.accounting.transactions.reimbursement.absolutePath}`:
      if (pagePathName.includes(ROUTES.reimbursement.approvals.absolutePath)) {
        defaultPageSearchAndFilterConfig.status = "approval";
      } else if (
        [
          ROUTES.reimbursement.paid.absolutePath,
          ROUTES.reimbursement.payments.absolutePath,
          ROUTES.accounting.transactions.reimbursement.all.absolutePath,
          ROUTES.accounting.transactions.reimbursement.pending.absolutePath,
          ROUTES.accounting.transactions.reimbursement.synced.absolutePath,
          ROUTES.accounting.transactions.reimbursement.absolutePath,
        ].includes(pagePathName)
      ) {
        defaultPageSearchAndFilterConfig.status = "payment";
      }

      defaultPageSearchAndFilterConfig.moduleValue = "reimbursement";
      break;

    case `${ROUTES.manage.people.users.all.absolutePath}`:
    case `${ROUTES.manage.people.users.blocked.absolutePath}`:
      if (pagePathName === ROUTES.manage.people.users.all.absolutePath) {
        defaultPageSearchAndFilterConfig.status = "active";
      }
      defaultPageSearchAndFilterConfig.moduleValue = "users";
      break;

    case `${ROUTES.manage.company.departments.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "department";
      break;

    case `${ROUTES.manage.company.locations.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "location";
      defaultPageSearchAndFilterConfig.onlyTextSearch = true;
      break;

    case `${ROUTES.manage.company.projects.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "project";
      break;

    case `${ROUTES.manage.company.budgets["departments-budgets"].absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "department_budget";
      break;

    case `${ROUTES.manage.company.budgets["project-budgets"].absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "project_budget";
      break;

    case `${ROUTES.manage.billing.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "billing";
      break;

    case `${ROUTES.myVolopay.actionCentre.cards.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "action_center/cards";
      break;
    case `${ROUTES.myVolopay.actionCentre.billPay.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "action_center/bill_pay";
      break;

    case `${ROUTES.myVolopay.actionCentre.reimbursement.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue =
        "action_center/reimbursement";
      break;

    case `${ROUTES.myVolopay.actionCentre.payroll.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "action_center/payroll";
      break;

    case `${ROUTES.myVolopay.reimbursement.claims.drafts.absolutePath}`:
    case `${ROUTES.myVolopay.reimbursement.claims.active.absolutePath}`:
    case `${ROUTES.myVolopay.reimbursement.claims.history.absolutePath}`:
    case `${ROUTES.myVolopay.reimbursement.reports.drafts.absolutePath}`:
    case `${ROUTES.myVolopay.reimbursement.reports.active.absolutePath}`:
    case `${ROUTES.myVolopay.reimbursement.reports.history.absolutePath}`:
    case `${ROUTES.myVolopay.reimbursement.paymentHistory.absolutePath}`:
      if (
        pagePathName ===
          ROUTES.myVolopay.reimbursement.claims.history.absolutePath ||
        pagePathName ===
          ROUTES.myVolopay.reimbursement.reports.history.absolutePath
      ) {
        defaultPageSearchAndFilterConfig.status = "history";
      } else if (
        pagePathName ===
        ROUTES.myVolopay.reimbursement.paymentHistory.absolutePath
      ) {
        defaultPageSearchAndFilterConfig.status = "payment_history";
      }

      defaultPageSearchAndFilterConfig.moduleValue =
        "action_center/reimbursement_page";
      break;

    case `${ROUTES.myVolopay.expenses.overView.absolutePath}`:
    case `${ROUTES.myVolopay.expenses.flagged.absolutePath}`:
    case `${ROUTES.myVolopay.expenses.declines.absolutePath}`:
      if (pagePathName === ROUTES.myVolopay.expenses.declines.absolutePath) {
        defaultPageSearchAndFilterConfig.status = "declined";
      }
      defaultPageSearchAndFilterConfig.moduleValue = "action_center/expenses";
      break;

    case `${ROUTES.exports.base.absolutePath}`:
      defaultPageSearchAndFilterConfig.moduleValue = "exports";
      break;

    default:
      defaultPageSearchAndFilterConfig.onlyTextSearch = true;
      break;
  }

  return defaultPageSearchAndFilterConfig;
}

export function skipSearchAndFilterInputsFromAppliedFilters(
  appliedFilters,
  searchAndFilterObject
) {
  const _appliedFilters = JSON.parse(JSON.stringify(appliedFilters));
  const searchAndFilterParamsValueObject = Object.values(searchAndFilterObject);

  searchAndFilterParamsValueObject?.forEach((searchParamObject) => {
    const targetParam = searchParamObject?.param;
    if (_appliedFilters[targetParam]) {
      delete _appliedFilters[targetParam];
    }
  });

  return _appliedFilters;
}

export function generateTagsForSearchAndFilterComponent(
  searchAndFilterObject,
  clearCategory
) {
  const searchAndFilterObjectKeys = Object.keys(searchAndFilterObject);
  const searchAndFilterObjectValues = Object.values(searchAndFilterObject);

  function getTagValueComponent(tagValue, toolTipUsers, categoryKey) {
    return (
      <div className="px-2 py-1 rounded bg-neutral-200">
        <Text
          translationKey={tagValue}
          id={`search-and-filter-tooltip-${categoryKey}`}
          classes={toolTipUsers ? "cursor-pointer" : ""}
        />

        {toolTipUsers ? (
          <Tooltip
            id={`search-and-filter-tooltip-${categoryKey}`}
            direction="bottom"
          >
            {toolTipUsers?.map((user, index) => (
              <div key={index}>{user}</div>
            ))}
          </Tooltip>
        ) : null}
      </div>
    );
  }

  function getTagValuesComponentForMultiselect(values, categoryKey) {
    const titleKeys = Object.values(values);
    const titleKeysLength = titleKeys?.length;
    const showLimitCrossedText = titleKeysLength > 2;
    const toolTipUsers = titleKeys?.slice(2);

    return (
      <div className="flex flex-row gap-3">
        {titleKeys?.map((title, index) =>
          index < 2 ? getTagValueComponent(title) : null
        )}

        {showLimitCrossedText
          ? getTagValueComponent(
              `+ ${titleKeysLength - 2}`,
              toolTipUsers,
              categoryKey
            )
          : null}
      </div>
    );
  }

  return (
    <>
      {searchAndFilterObjectKeys?.map((title, index) => {
        const values = searchAndFilterObjectValues[index]?.values;
        const showPill = JSON.parse(
          searchAndFilterObjectValues[index]?.showPill ?? true
        );
        const isSingleSelect = typeof values !== typeof {};

        // const _value = isSingleSelect ? values ? ""

        return showPill ? (
          <div
            key={index}
            className="flex flex-row items-center gap-3 p-2 rounded bg-neutral-50"
          >
            <Text
              translationKey={`${title} : `}
              classes="text-sm font-medium text-neutral-500"
            />

            {isSingleSelect
              ? getTagValueComponent(
                  searchAndFilterObjectValues[index]?.filterLabel
                )
              : getTagValuesComponentForMultiselect(values, index)}

            <Icon
              name="Close"
              className="w-3 h-3 mt-0.5 cursor-pointer"
              handleClick={() => clearCategory(title)}
            />
          </div>
        ) : null;
      })}
    </>
  );
}

export function getCommonStructureForApiToParams(
  searchAndFilterCommonStoreStructure
) {
  // This function will convert the common structure format to api trigger format

  const _commonStructureValues = Object.values(
    searchAndFilterCommonStoreStructure
  );

  const paramsStructure = {};

  _commonStructureValues?.forEach((searchAndFilterUnformattedParams) => {
    const { param = "", values = "" } = searchAndFilterUnformattedParams;
    const paramObject = paramsStructure?.[param]?.value;
    const isValueOfTypeObject = typeof values === typeof {};

    const _values = isValueOfTypeObject ? Object.keys(values) : values;

    if (paramObject) {
      if (typeof paramObject === typeof []) {
        if (isValueOfTypeObject) {
          paramsStructure[param].value =
            paramsStructure?.[param]?.value?.concat(_values);
        } else {
          paramsStructure[param].value =
            paramsStructure?.[param]?.value?.push(_values);
        }
      } else {
        const tempObject = [...paramObject];
        if (isValueOfTypeObject) {
          tempObject?.concat(_values);
        } else {
          tempObject?.push(_values);
        }
        paramsStructure[param].value = tempObject;
      }
    } else {
      paramsStructure[param] = { value: _values };
    }
  });

  return paramsStructure;
}

// Dynamic Options Section --> These utility functions are used for getting the options UI for a level if it is dynamic.

export function getOptionsForPeopleMultiSelect(people) {
  const role = people?.role ?? "";
  const departmentName = people?.departmentName;
  const locationName = people?.locationName;
  const displayName = people?.displayName;
  const showFrozenUserChip =
    people?.status === COMPANY_PEOPLE_USER_STATUS.FREEZED;

  return {
    label: (
      <div className="flex flex-col" key={`${role}-${departmentName}`}>
        <div className="flex flex-row">
          <div className="text-base font-medium text-neutral-500">
            {displayName}
          </div>
          {showFrozenUserChip ? (
            <div className="ml-3">
              <Chip label="Frozen" classes="text-neutral-600" />
            </div>
          ) : null}
        </div>

        <div className="flex flex-row items-center">
          <Text
            translationKey={role}
            classes="text-xs font-medium text-neutral-400"
          />

          <div className="flex flex-row items-center">
            {role ? (
              <span className="ml-1 profile-widget-dot bg-neutral-400" />
            ) : null}

            <Text
              translationKey={departmentName}
              classes="text-xs font-medium text-neutral-400 ml-2"
            />
          </div>

          <div className="flex flex-row items-center">
            {role || departmentName ? (
              <span className="ml-1 profile-widget-dot bg-neutral-400" />
            ) : null}

            <Text
              translationKey={locationName}
              classes="text-xs font-medium text-neutral-400 ml-2"
            />
          </div>
        </div>
      </div>
    ),
    title: displayName,
    id: people?.id,
  };
}

export function getCardsOptionUi(card) {
  const cardNumber = card?.cardNumber;
  const cardName = card?.name;

  return {
    label: (
      <div className="flex flex-row items-center gap-2">
        <Text
          classes="text-base not-italic font-medium leading-6 text-neutral-500"
          translationKey={cardName}
        />

        {cardNumber && cardNumber !== "" ? (
          <div className="flex flex-row items-center gap-2">
            <span className="profile-widget-dot text-neutral-500" />
            <Text translationKey={cardNumber} />
          </div>
        ) : null}
      </div>
    ),
    title: cardName,
    id: card?.id,
  };
}

export function getEmailOptionsUi(emailProps) {
  return {
    label: emailProps?.email,
    id: emailProps?.email,
    value: emailProps?.email,
  };
}

export function getRolesOptionsUi(roleProps) {
  return {
    label: roleProps?.name,
    id: roleProps?.id,
    value: roleProps?.id,
  };
}

export function getVendorOptionsUi(vendorProps, parentNavInfo, t) {
  if (
    parentNavInfo?.label ===
    t("payroll.payrollEmployees.tableHeaders.employeeId")
  ) {
    return {
      label: vendorProps?.employeeId,
      id: vendorProps?.id,
      value: vendorProps?.id,
    };
  }
  return {
    label: vendorProps?.name,
    id: vendorProps?.id,
    value: vendorProps?.id,
  };
}

export function getTagsGroupedOptions(options) {
  let trackLastAccountingEntriesCount = 0;

  options.sort((option) => {
    if (option?.groupType === TAGS_TYPE_CONFIG.ACCOUNTING.key) {
      return -1;
    }
    return 1;
  });

  options?.forEach((option) => {
    if (option.groupType === TAGS_TYPE_CONFIG.ACCOUNTING.key) {
      trackLastAccountingEntriesCount += 1;
    }
  });

  if (trackLastAccountingEntriesCount > 0) {
    options.unshift({
      label: TAGS_TYPE_CONFIG.ACCOUNTING.groupTitle,
      nonClickable: true,
    });
    // options[0].groupTitle = TAGS_TYPE_CONFIG.ACCOUNTING.groupTitle;
  }
  if (
    options[trackLastAccountingEntriesCount] &&
    options[trackLastAccountingEntriesCount].groupType ===
      TAGS_TYPE_CONFIG.CUSTOM.key
  ) {
    options.splice(trackLastAccountingEntriesCount, 0, {
      label: TAGS_TYPE_CONFIG.CUSTOM.groupTitle,
      nonClickable: true,
    });
    // options[trackLastAccountingEntriesCount].groupTitle =
    //   TAGS_TYPE_CONFIG.CUSTOM.groupTitle;
  }

  return options;
}

export function getParamNameForAccountingTags(optionProps) {
  if (
    optionProps?.tagType === TAGS_TYPE_CONFIG.ACCOUNTING.key ||
    optionProps?.tagType === TAGS_TYPE_CONFIG.CUSTOM.key
  ) {
    return "tag_values";
  }
  return "accounting_payee";
}

export function isCurrentOptionLevelMultiselect(
  currentLevel,
  currentNavigationIds
) {
  return currentLevel === currentNavigationIds?.[0]?.totalLevels
    ? currentNavigationIds?.[0]?.isLastLevelMultiSelect
    : false;
}

export const TRIGGER_KEY_AND_CATEGORY_KEY_MAPPING = {
  trigger_department: "project",
  trigger_project: "project",
};

export const TRIGGER_PRE_TEXT = "trigger_";

export function generateRedirectionUrlForFilters(props) {
  generateSearchAndFilterCommonStructure(props);
}

export function generateSearchAndFilterCommonStructure(props) {
  const { parentRoute, dispatch } = props;

  fetchSearchAndFilterCategoriesOptions(
    parentRoute,
    (options) => {
      getNextLevelSelectedOptionsForCategoryKey({
        categoryOptions: options,
        ...props,
      });
    },
    dispatch
  );
}

function fetchSearchAndFilterCategoriesOptions(
  targettedParentRoute,
  onSuccess,
  dispatch
) {
  const { moduleValue, status } =
    getModuleValueBasedOnPagePathName(targettedParentRoute);

  const params = { module: moduleValue };
  if (status) {
    params.status = status;
  }

  dispatch(getCategoriesForSearchFilters({ params, onSuccess }));
}

function getNextLevelSelectedOptionsForCategoryKey(props) {
  const { categoryOptions = [], dispatch, categoryValues, categoryKey } = props;

  let curentTrackIndex = null;

  categoryOptions?.forEach((categoryOption, index) => {
    if (
      [
        categoryOption?.label,
        categoryOption?.title,
        categoryOption?.name,
      ].includes(categoryKey)
    ) {
      curentTrackIndex = index;

      const hasOptions =
        categoryOption?.options && categoryOption?.options?.length > 0;

      if (hasOptions) {
        dispatch(
          navigateWithAppliedSearchAndFilters({
            nextLevelOptions: categoryOption?.options,
            selectedValuesIds: categoryValues,
            categoryOption,
            levelPosition: index,
            ...props,
          })
        );
      } else {
        dispatch(
          getCategoryOptions({
            params: categoryOption?.params,
            url: categoryOption?.url,
            onSuccess: (nextLevelOptions) => {
              navigateWithAppliedSearchAndFilters({
                nextLevelOptions,
                selectedValuesIds: categoryValues,
                categoryOption,
                levelPosition: index,
                ...props,
              });
            },
          })
        );
      }
    }
  });
}

function navigateWithAppliedSearchAndFilters({
  nextLevelOptions,
  selectedValuesIds,
  categoryOption,
  levelPosition,
  parentRoute,
  navigate,
  additionalSearchParams,
  isCurrentAndTargetedRouteSame,
}) {
  const config = {};

  const _selectedValueIds =
    typeof selectedValuesIds === typeof {}
      ? Object.keys(selectedValuesIds)
      : [selectedValuesIds];

  _selectedValueIds?.map((id) => {
    const searchedIdInfo = nextLevelOptions?.find(
      (levelOption) => Number(levelOption?.id) === Number(id)
    );

    if (Object.values(searchedIdInfo)?.length > 0) {
      config[categoryOption?.label] = {
        levelPosition: `L.${levelPosition}`,
        param: categoryOption?.key,
        values: {
          [id]:
            searchedIdInfo?.title ||
            searchedIdInfo?.label ||
            searchedIdInfo?.name,
        },
      };
    } else {
      return "";
    }
  });

  if (Object.values(config)?.length > 0) {
    const queryParams = qs.stringify(
      {
        page: { ...config },
      },
      { allowDots: true }
    );

    navigate({
      pathname: parentRoute,
      search: additionalSearchParams
        ? `searchAndFilter=${btoa(queryParams)}&&${additionalSearchParams}`
        : `searchAndFilter=${btoa(queryParams)}`,
    });

    if (isCurrentAndTargetedRouteSame) {
      window.location.reload();
    }
  }
}
