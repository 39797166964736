import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCardRulesByRuleId } from "@/store/reducers/rules";

import { newCardRequestDetailsSelector } from "@/store/selectors/cards";
import {
  isFetchingRulesSelector,
  rulesListSelector,
} from "@/store/selectors/rules";
import {
  accountingTagsSelector,
  cardsCustomTagsSelector,
} from "@/store/selectors/tags";

import {
  formatLinkedRulesApiResponse,
  getCardRulesForReview,
} from "@/components/Cards/Sliders/CardMerchantCategoryControls/util";
import EntityListView, {
  renderingListType,
} from "@/components/common/EntityListView";
import EntityListViewLoader from "@/components/common/EntityListView/EntityListViewLoader";

export default function CardRules() {
  const dispatch = useDispatch();
  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);
  const ruleId = cardRequestDetails?.ruleId;
  const accountingTags = useSelector(accountingTagsSelector);
  const customTags = useSelector(cardsCustomTagsSelector);
  const currentLinkedCardRules = useSelector(rulesListSelector);
  const isFetchingCardRules = useSelector(isFetchingRulesSelector);

  const cardRulesPreviewList = useMemo(() => {
    const allTags = [...accountingTags, ...customTags];
    const formattedResponse = formatLinkedRulesApiResponse(
      currentLinkedCardRules
    );

    const _formattedResponse = getCardRulesForReview(
      formattedResponse,
      allTags
    );

    return _formattedResponse;
  }, [currentLinkedCardRules]);

  useEffect(() => {
    fetchCardRules();
  }, []);

  function fetchCardRules() {
    if (ruleId && typeof ruleId !== typeof "") {
      dispatch(getCardRulesByRuleId(ruleId));
    }
  }

  return !isFetchingCardRules ? (
    cardRulesPreviewList?.length > 0 ? (
      <EntityListView
        list={cardRulesPreviewList}
        count={cardRulesPreviewList?.length}
        type={renderingListType.PLAIN}
        showCount
        mainHeading="cards.cardDetails.editCardDetails.entityListView.cardRules.mainHeading"
        headingDescription="cards.cardDetails.editCardDetails.entityListView.cardRules.headingDescription"
      />
    ) : null
  ) : (
    <EntityListViewLoader />
  );
}
