import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

function SettingTabSkelaton() {
  return (
    <div className="flex flex-col gap-4">
      {[...new Array(5)]?.map((row, index) => (
        <div
          className="flex flex-col items-center justify-between gap-4 border-0 card-wrapper bg-neutral-50"
          key={index}
        >
          <LoaderSkeleton size={[15, 578]} />
          <div className="flex flex-row items-center justify-between w-full">
            <LoaderSkeleton size={[10, 300]} />
            <LoaderSkeleton size={[15, 100]} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default SettingTabSkelaton;
