import { useState } from "react";
import { useSelector } from "react-redux";

import { isFetchingTransactionsSelector } from "@/store/selectors/accounting_transactions";
import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";

import Tabs from "@/components/core/Tabs";

import QRPayPageHelper from "@/components/Accounting/Transactions/QRPay/PageHelper";
import AccountingHeader from "@/components/Accounting/Transactions/common/AccountingHeader";
import { accountingSelectedTabs } from "@/utils/common";

import {
  ACCOUNTING_SYNC_ATTEMPT_ENTITY,
  ACCOUNTING_TAB_TYPES,
  ACCOUNTING_TRANSACTION_TABS,
} from "@/constants/accounting";
import { ROUTES } from "@/constants/routes";

export default function ATQRPay() {
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const isFetchingTransactions = useSelector(isFetchingTransactionsSelector);
  const selectedTabs = accountingSelectedTabs(
    accountingSoftware,
    ACCOUNTING_TAB_TYPES.QR_PAY
  );
  const [selectedTab, setSelectedTab] = useState();

  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <AccountingHeader
        sectionTitleText="QR Pay"
        entity={ACCOUNTING_SYNC_ATTEMPT_ENTITY.QR_PAY}
      />
      {accountingSoftware ? (
        <Tabs
          items={selectedTabs}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
          conditionalTabChange={isFetchingTransactions}
        />
      ) : (
        <QRPayPageHelper tab={selectedTab?.key} />
      )}
    </div>
  );
}
