import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { claimApprovalAllTabEnabledSelector } from "@/store/selectors/client";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import { ROUTES } from "@/constants/routes";

import "../styles.scss";

export const REIMBURSEMENT_TABS = [
  {
    name: "Needs your attention",
    count: null,
    path: ROUTES.reimbursement.approvals.needYourAttention.absolutePath,
    key: 1,
  },
  {
    name: "Pending",
    count: null,
    path: ROUTES.reimbursement.approvals.pending.absolutePath,
    key: 2,
  },
  {
    name: "All",
    count: null,
    path: ROUTES.reimbursement.approvals.all.absolutePath,
    key: 3,
  },
];
// TECH DEBT
export default function Approvals() {
  const claimApprovalAllTabEnabled = useSelector(
    claimApprovalAllTabEnabledSelector
  );
  // Requirement is to remove `All` tab when `claimApprovalAllTabEnabled` is false
  const availableTabs = REIMBURSEMENT_TABS.filter(
    ({ key }) => claimApprovalAllTabEnabled || key !== 3
  );

  const [selectedTab, setSelectedTab] = useState(availableTabs[0]);

  return (
    <div className="pl-11 pr-11.5 flex flex-col">
      <div className="flex flex-col">
        <Text
          classes="text-neutral-500 text-sm"
          translationKey="reimbursement.title"
        />
        <Text
          classes="text-neutral-800 text-4xl font-extrabold"
          translationKey="reimbursement.approvals.title"
        />
      </div>
      <div className="mt-6">
        <Tabs
          items={availableTabs}
          selectedTab={selectedTab.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
        />
      </div>
    </div>
  );
}
