import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { defaultCurrencySelector } from "@/store/selectors/client";

import Text from "@/components/core/Text";

import FlexibleBudgetBadge from "@/components/Company/common/FlexibleBudgetBadge";
import BudgetPill from "@/components/common/BudgetPill";
import DeltaPercentagePill from "@/components/common/DeltaPercentagePill";
import { amountToCurrency, percentageDiff } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  BE_FE_FREQUENCY_MAP,
  BUDGET_FREQUENCY_LABELS,
  BUDGET_FREQUENCY_VS_LABELS,
  BUDGET_FREQUENCY_VS_TITLE,
} from "@/constants/company";

function BudgetsListingRow({
  data,
  isLastRow,
  isFetching,
  hasMore,
  handleRefChange,
  type,
}) {
  const currency = useSelector(defaultCurrencySelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const clickHandler = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.budget.id, data.id);
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.budget.type, type);
    setSearchParams(searchParams);
  };
  const frequencyKey = data?.frequency?.toUpperCase();
  return (
    <div
      ref={(ref) => {
        if (isLastRow && hasMore && !isFetching) handleRefChange(ref);
      }}
      className="flex items-center justify-between px-8 py-4 card-wrapper"
      onClick={clickHandler}
    >
      <div className="w-1/2">
        <Text
          classes="text-xl font-bold w-11/12 truncate "
          translationKey={data?.projectName}
        />

        <div className="flex">
          <div className="mr-2 text-sm font-medium text-neutral-500">
            <Text translationKey="filters.budgetedFor.title" />
            <span>:</span>
          </div>
          <BudgetPill keyForModules="modules" id={data?.id} data={[data]} />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between w-1/4">
        <div>
          <Text
            translationKey="company.budgets.budgetLimit"
            classes="text-sm font-medium"
          />
          <div className="flex items-center gap-2 text-xl font-bold">
            <div className="flex items-baseline gap-2">
              <span>{amountToCurrency(data?.limit)}</span>
              <span className="text-sm text-neutral-500">{data?.currency}</span>
            </div>
            {data?.flexible ? <FlexibleBudgetBadge /> : null}
          </div>
          <Text
            classes="text-xs font-semibold text-neutral-500"
            translationKey={
              BUDGET_FREQUENCY_LABELS[BE_FE_FREQUENCY_MAP[data?.frequency]]
            }
          />
        </div>
      </div>
      <div className="flex flex-col items-end w-2/12">
        <Text
          classes="text-neutral-800 text-sm font-medium"
          translationKey={BUDGET_FREQUENCY_VS_TITLE[frequencyKey]}
        />
        <div>
          <span className="text-xl font-bold text-neutral-800">
            {amountToCurrency(data?.currentSpends ?? 0)}
          </span>
          <span className="font-bold text-neutral-500">{currency}</span>
        </div>
        <div className="flex gap-2">
          <DeltaPercentagePill
            percentage={`${
              percentageDiff(
                data?.currentSpends ?? 0,
                data?.previousSpends ?? 0
              ) ?? 0
            }`}
          />
          <Text
            classes="text-xs font-bold text-neutral-400"
            translationKey={BUDGET_FREQUENCY_VS_LABELS[frequencyKey]}
          />
        </div>
      </div>
    </div>
  );
}

export default BudgetsListingRow;
BudgetsListingRow.propTypes = {
  data: PropTypes.array,
  handleRefChange: PropTypes.func,
  hasMore: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLastRow: PropTypes.bool,
  type: PropTypes.string,
};
