import Text from "@/components/core/Text";

import PaymentPageHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentPageHelper";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

export default function PaidPayments() {
  const page = BILL_PAYROLL_CONTEXT.BILLPAY;
  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <div className="breadcrumb-text">
        <Text translationKey="billPay.title" />
        &nbsp;/&nbsp;
        <Text translationKey="billPay.bill.title" />
      </div>
      <Text
        classes="listing-page-title"
        translationKey="billPay.bill.paid.title"
      />
      <PaymentPageHelper page={page} pageType={BILL_PAYROLL_CONTEXT.PAID} />
    </div>
  );
}
