import PropTypes from "prop-types";

import { amountToCurrency } from "@/utils/common";

export default function BillingAmountCell({ val }) {
  return (
    <div className="flex items-center justify-end gap-2">
      <span>{amountToCurrency(val?.totalAmount, val?.currency)}</span>
    </div>
  );
}

BillingAmountCell.propTypes = {
  val: PropTypes.object,
};
