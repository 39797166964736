import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";

/**
 * Document cell for Vendors, Employees
 */
export default function ContractCell({ val }) {
  return (
    <div className="flex items-center justify-center">
      <Icon
        name={val?.vendor?.hasContract ? "Article" : "AddNotes"}
        className={
          val?.vendor?.hasContract ? "text-success-600" : "text-neutral-500"
        }
      />
    </div>
  );
}

ContractCell.propTypes = {
  val: PropTypes.shape({
    id: PropTypes.any,

    vendor: PropTypes.object,
    openSliderHandler: PropTypes.func,
    duplicateBillId: PropTypes.any,

    disabled: PropTypes.bool,
    checkedValue: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
  }),
};
