import PropTypes from "prop-types";
import { useMemo } from "react";

import { useLeftHeaderTitle } from "@/hooks/useLeftHeaderTitle";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { amountToCurrency, dateToString } from "@/utils/common";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

/**
 *
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=4663-222259&mode=dev}
 * payroll {@link https://www.figma.com/file/nYb1B3o06dNJPuFhW7fLWP/v2-Payroll?type=design&node-id=4825-264986&mode=design&t=zvZOgDFzWghB6ZDW-4}
 */
export default function PaymentSliderHead({
  text,
  paymentAmount,
  paymentDate,
  paymentOnApprove,
  context,
}) {
  const ref = useLeftHeaderTitle(
    {
      title: (
        <div className="flex items-center gap-3">
          <Icon
            name={
              context === BILL_PAYROLL_CONTEXT.BILLPAY ? "BillPay" : "Payments"
            }
            variant="rounded"
            className="w-4 h-4 cursor-pointer text-success-600"
            bgClassName="bg-success-100 w-9 h-9"
          />

          <Text classes="text-xl font-bold" translationKey={text} showTooltip />
        </div>
      ),
    },
    {},
    true
  );

  const paymentDateAlternateConfig = useMemo(() => {
    if (paymentOnApprove) {
      return {
        label:
          "reimbursement.settings.sliders.automatedPayments.addPaymentDate.payOnceApproved",
      };
    }
    if (!paymentDate) {
      return {
        label: "billPay.bill.payments.paymentStatus.unscheduledTitle",
        icon: "Error",
        iconClasses: "w-4 h-4 text-danger-600",
      };
    }
  }, [paymentOnApprove]);

  return (
    <div ref={ref}>
      <Text
        classes="text-3xl font-bold text-neutral-800"
        translationKey={amountToCurrency(paymentAmount)}
      />
      <div className="flex items-center">
        <Text
          translationKey="reimbursement.payments.payOutsideVolopay.paymentDateLabel"
          classes="text-sm font-normal text-neutral-500"
        />
        <span className="mr-2 text-sm font-normal text-neutral-500">:</span>
        <Text
          classes="text-sm font-bold text-neutral-500 mr-1"
          translationKey={
            paymentDateAlternateConfig
              ? paymentDateAlternateConfig?.label
              : dateToString(paymentDate)
          }
        />
        {paymentDateAlternateConfig?.icon ? (
          <Icon
            name={paymentDateAlternateConfig.icon}
            className={`inline-block ${paymentDateAlternateConfig.iconClasses}`}
          />
        ) : null}
      </div>
    </div>
  );
}

PaymentSliderHead.propTypes = {
  text: PropTypes.string,
  paymentAmount: PropTypes.object,
  paymentDate: PropTypes.string,
  paymentOnApprove: PropTypes.bool,
  context: PropTypes.string,
};
