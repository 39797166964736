import PropTypes from "prop-types";
import React from "react";

import MessageListingWidget from "./MessageListingWidget";

export default function MessageListingComponent({
  data = [],
  shaddedNotificationIds,
  handleRefChange,
  hasMore,
  setShaddedNotificationIds,
}) {
  return (
    <div className="mt-4">
      {data?.map((widgetItemInfo, index) => {
        return (
          <div
            key={`${widgetItemInfo?.id}-${index}`}
            ref={(ref) => {
              if (data.length - 1 === index && hasMore) {
                handleRefChange(ref);
              }
            }}
          >
            <MessageListingWidget
              widgetInfo={widgetItemInfo}
              shaddedNotificationIds={shaddedNotificationIds}
              setShaddedNotificationIds={setShaddedNotificationIds}
            />
          </div>
        );
      })}
    </div>
  );
}

MessageListingComponent.propTypes = {
  data: PropTypes.array,
  shaddedNotificationIds: PropTypes.array,
  handleRefChange: PropTypes.func,
  hasMore: PropTypes.bool,
};
