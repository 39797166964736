import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import Input from "@/components/core/Input";
import { useForm } from "@/utils/useForm";

import FooterSection from "./FooterSection";

function InputCaptchaCheck({
  handleConfirmButton,
  handleCancelButton,
  outsideInputDivClasses,
  captchaMatchText,
  inProgress,
  classes = "",
  portalSelector = "", // css selector
  disabled = false,
}) {
  const [isConfirmButtonDisabled, setIsConfimButtonDisabled] = useState(false);

  const initialValue = {
    captchaText: {
      value: "",
      validate: {
        required: true,
      },
    },
  };

  const { handleChange, values, errors, handleSubmit } = useForm(
    initialValue,
    () => {
      handleConfirmButtonClick();
    }
  );

  useEffect(() => {
    setIsConfimButtonDisabled(values?.captchaText !== captchaMatchText);
  }, [values]);

  function handleConfirmButtonClick() {
    if (captchaMatchText === values?.captchaText) {
      handleConfirmButton();
    } else {
      setIsConfimButtonDisabled(true);
    }
  }

  const footerUI = (
    <FooterSection
      isConfirmButtonDisabled={isConfirmButtonDisabled || inProgress}
      inProgress={inProgress}
      handleCancel={handleCancelButton}
    />
  );

  const portalDestination = portalSelector
    ? document.querySelector(portalSelector)
    : null;

  return (
    <div className={`flex flex-col justify-between h-full ${classes}`}>
      <form id="captcha_verification" onSubmit={handleSubmit}>
        <Input
          label={`Please type "${captchaMatchText}" in uppercase letters`}
          placeholder={`Please type ${captchaMatchText} in uppercase letters`}
          outsideDivClasses={outsideInputDivClasses}
          name="captchaText"
          value={values?.captchaText}
          error={
            !captchaMatchText.startsWith(values?.captchaText) &&
            captchaMatchText !== values?.captchaText
              ? "misc.invalidFormat"
              : errors?.captchaText
          }
          onChange={handleChange}
          labelExtraClasses="text-neutral-500 font-semibold text-sm"
          disabled={disabled}
        />
      </form>

      {/* TODO: footer and slider are standardized in v2 to avoid scroll issues */}
      {/* this should be refactored out of this component */}
      {/* For now, portal can fix this */}
      {portalSelector && portalDestination
        ? createPortal(footerUI, portalDestination)
        : footerUI}
    </div>
  );
}

InputCaptchaCheck.propTypes = {
  handleConfirmButton: PropTypes.func,
  handleCancelButton: PropTypes.func,
  outsideInputDivClasses: PropTypes.string,
  captchaMatchText: PropTypes.string,
  inProgress: PropTypes.bool,
  classes: PropTypes.string,
  portalSelector: PropTypes.string,
  disabled: PropTypes.bool,
};
export default InputCaptchaCheck;
