import PropTypes from "prop-types";
import React from "react";

import Checkbox from "@/components/core/Checkbox";
import ProfileWidget from "@/components/core/ProfileWidget";

function ProfileWidgetWithCheckbox({
  onRowClickHandler,
  onCheckboxClickHandler,
  checkBoxValue,
  ProfileWidgetName,
  ProfileWidgetImg,
  disabled,
  children,
}) {
  return (
    <div
      className="flex items-center flex-1 min-w-0 gap-2 font-medium text-left text-neutral-800"
      onClick={onRowClickHandler}
    >
      <Checkbox
        onClickHandler={onCheckboxClickHandler}
        checked={checkBoxValue}
        disabled={disabled}
      />
      <ProfileWidget
        textClasses="font-semibold"
        name={ProfileWidgetName}
        img={ProfileWidgetImg}
        tooltipClasses="w-17"
      >
        {children}
      </ProfileWidget>
    </div>
  );
}

export default ProfileWidgetWithCheckbox;

ProfileWidgetWithCheckbox.propTypes = {
  onRowClickHandler: PropTypes.func,
  onCheckboxClickHandler: PropTypes.func,
  checkBoxValue: PropTypes.bool,
  ProfileWidgetName: PropTypes.string,
  ProfileWidgetImg: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};
