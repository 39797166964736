import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  bulkOperationReimbursement,
  bulkOperationReport,
  updateReimbursement,
} from "@/store/reducers/reimbursement";

import {
  isFetchingClientDetailsSelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  approvalsAllFiltersSelector,
  approvalsFiltersSelector,
  isCtaActionPendingForIdSelector,
  reportApprovalsAllFiltersSelector,
  reportApprovalsFiltersSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Filters from "@/components/core/Filters";
import Table from "@/components/core/Table";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import Export from "@/components/Exports";
import RequestHistoryButton from "@/components/MyVolopay/ActionCentre/common/RequestHistory/RequestHistoryButton";
import ApprovalsTableLoader from "@/components/Reimbursement/ApprovalsTableLoader";
import {
  REIMBURSEMENT_APPROVAL_TABLE_HEADER,
  REPORT_APPROVAL_TABLE_HEADER,
} from "@/components/Reimbursement/tableConfig";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import { convertFilters } from "@/utils/filters";
import { handleExport } from "@/utils/exports";
import { SORT_KEYS } from "@/utils/constants/sorting";
import {
  PAGE_TYPE,
  REIMBURSEMENT_PAGE_LABEL_TYPE_MAPPING,
  REPORT_PAGE_LABEL_TYPE_MAPPING,
  TAB,
} from "@/utils/constants/reimbursement";
import { REPORT_CONTEXT } from "@/utils/constants/myVolopay";

import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";
import { REIMBURSEMENT_ACTION_CENTER_PAGE_CONFIG } from "@/constants/actionCentreReimbursement";
import { PENDING_REQUEST_TABS_KEYS } from "@/constants/company";
import { EXPORT_PAGE_TYPE } from "@/constants/exports";
import { APPROVAL_REQUEST_TYPE, APPROVE } from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { REIMBURSEMENT_CONTEXT } from "@/constants/reimbursement";
import { TRANSACTION_ACTIONS } from "@/constants/transactions";

const filterConfig = {
  report: {
    "reimbursement.tabTitles.all": reportApprovalsAllFiltersSelector,
    "reimbursement.tabTitles.needsYourAction": reportApprovalsFiltersSelector,
    "reimbursement.tabTitles.pending": reportApprovalsFiltersSelector,
    "myVolopay.actionCentre.reportReimbursementKey":
      reportApprovalsFiltersSelector,
  },
  reimbursement: {
    "reimbursement.tabTitles.all": approvalsAllFiltersSelector,
    "reimbursement.tabTitles.needsYourAction": approvalsFiltersSelector,
    "reimbursement.tabTitles.pending": approvalsFiltersSelector,
    "myVolopay.actionCentre.reimbursementKey": approvalsFiltersSelector,
  },
  "reimbursement-actionCentre": {
    "myVolopay.actionCentre.reimbursementKey": approvalsFiltersSelector,
  },
  "report-actionCentre": {
    "myVolopay.actionCentre.reimbursementReportKey":
      reportApprovalsFiltersSelector,
  },
};

export default function ReimbursementApprovalList({
  pageType,
  onClickHandler,
}) {
  const [reloadApi, setReloadApi] = useState(false);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);
  const isFetchingReportEnabledData = useSelector(
    isFetchingClientDetailsSelector
  );

  const [configKey, setConfigKey] = useState(
    REIMBURSEMENT_PAGE_LABEL_TYPE_MAPPING[pageType]
  );
  const [config, setConfig] = useState(
    REIMBURSEMENT_ACTION_CENTER_PAGE_CONFIG[configKey]
  );
  const reportEnabledAndNotActionCenter =
    reportEnabled &&
    (configKey !== ACTION_CENTRE_PAGE_CONTEXT.REPORT ||
      configKey !== ACTION_CENTRE_PAGE_CONTEXT.REIMBURSEMT);

  const {
    list,
    isFetching: isFetchingSelector,
    total,
    hasMore: hasMoreSelector,
    resetMethod,
    loadMoreMethod,
  } = config;

  const reimbursements = useSelector((state) => list(state));

  const currentUser = useSelector(userSelector);
  const isFetching = useSelector(isFetchingSelector);
  const totalReimbursementsSelected = useSelector(total);
  const filters = useSelector(filterConfig[configKey][pageType]);
  const hasMore = useSelector(hasMoreSelector);
  const appliedFilters = useSelector(appliedFilterSelector);

  const isEmpty = !reimbursements?.length;
  const allQueued = !reimbursements?.filter((reim) => !reim?.queued)?.length;
  const queuedPaymentLength = reimbursements?.filter(
    (reim) => reim?.queued
  )?.length;

  const isActionApprovePending = useSelector(isCtaActionPendingForIdSelector);

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });
  const showRequestHistoryButton =
    REIMBURSEMENT_PAGE_LABEL_TYPE_MAPPING?.[pageType] ===
      ACTION_CENTRE_PAGE_CONTEXT.REIMBURSEMT ||
    REIMBURSEMENT_PAGE_LABEL_TYPE_MAPPING[pageType] ===
      ACTION_CENTRE_PAGE_CONTEXT.REPORT;

  const filterOptions = {
    tab: TAB[PAGE_TYPE[pageType]],
  };

  const onReset = () => {
    const { func, args } = resetMethod();
    dispatch(func(args));
  };

  const loadMore = () => {
    const { func, args } = loadMoreMethod({
      request_type: APPROVAL_REQUEST_TYPE.REIMBURSEMENT,
      page: pageNum,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      ...filterOptions,
      ...convertFilters(appliedFilters),
      [SORT_KEYS.COLUMN]: sorting?.category,
      [SORT_KEYS.DIRECTION]: sorting?.type,
    });
    dispatch(func(args));
  };

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...appliedFilters,
      pageType,
      config,
      configKey,
      ...sorting,
      reloadApi,
    },
  });

  // Selected transactions
  const [selectedReimbursements, setSelectedReimbursements] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);

  const headerSelected = headerSelectAll; // all selected or else unchecked

  const handleRowSelection = (reimbursement) => {
    if (selectedReimbursements?.includes(reimbursement?.id)) {
      setSelectedReimbursements((prev) =>
        prev.filter((id) => id !== reimbursement?.id)
      );
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, reimbursement?.id]);
      }
    } else {
      setSelectedReimbursements((prev) => [...prev, reimbursement?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== reimbursement?.id));
    }
    dispatch(
      updateReimbursement({
        id: reimbursement.id,
        value: {
          ...reimbursement,
          selected: !reimbursement.selected,
        },
      })
    );
  };

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedReimbursements(
          reimbursements
            ?.filter((reimbursement) => !reimbursement?.queued)
            .map((reimbursement) => reimbursement.id)
        );
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedReimbursements([]);
    }
  };

  const showApproveCta = reimbursements
    ?.filter((item) => {
      return selectedReimbursements?.find((data) => data === item.id);
    })
    .map((data) => data?.ctas?.some((val) => val === APPROVE))
    ?.every((value) => value);

  const handleBulkApprove = (action) => {
    if (TRANSACTION_ACTIONS.APPROVE) {
      const onSuccess = () => {
        setHeaderSelectAll(false);
        setSelectedReimbursements([]);
        if (pageNum !== 1) setPageNum(1);
        else setReloadApi((prev) => !prev);
      };

      const payload = {
        onSuccess,
        param: {
          operation: action,
          ...(bulkSelectAll
            ? {
                all: true,
                ...(deSelectedIds.length
                  ? { except_ids: deSelectedIds }
                  : null),
              }
            : {
                ids: selectedReimbursements,
              }),

          ...convertFilters(appliedFilters),
        },
      };

      if (reportEnabled) {
        dispatch(bulkOperationReport(payload));
      } else {
        dispatch(bulkOperationReimbursement(payload));
      }
    }
  };

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
    emptyDataTitle,
    emptySubHeadingDataTitle,
  } = reportEnabledAndNotActionCenter
    ? REPORT_APPROVAL_TABLE_HEADER
    : REIMBURSEMENT_APPROVAL_TABLE_HEADER;

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);
      if (!deSelectedIds.length) {
        setSelectedReimbursements(reimbursements?.map((data) => data.id));
      } else {
        const filteredIds = reimbursements
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedReimbursements(filteredIds);
      }
    }
  }, [bulkSelectAll, reimbursements, deSelectedIds]);

  useEffect(() => {
    // When user selects checkbox from header
    setHeaderSelectAll(
      (selectedReimbursements.length ===
        pageNum * PAGINATION_PER_REQUEST_LIMIT ||
        selectedReimbursements.length ===
          reimbursements.length - queuedPaymentLength) &&
        !isEmpty
    );
  }, [selectedReimbursements, pageNum, reimbursements]);

  useEffect(() => {
    if (reportEnabledAndNotActionCenter) {
      setConfigKey(REPORT_PAGE_LABEL_TYPE_MAPPING[pageType]);

      setConfig(
        REIMBURSEMENT_ACTION_CENTER_PAGE_CONFIG[
          REPORT_PAGE_LABEL_TYPE_MAPPING[pageType]
        ]
      );
    }
  }, [reportEnabled, pageType]);

  return (
    !isFetchingReportEnabledData && (
      <>
        <div className="flex items-center justify-between">
          <div className="w-full">
            <Filters filters={filters} />
          </div>

          <div className="flex items-center justify-end gap-6">
            <Export
              totalExports={totalReimbursementsSelected}
              storeName="reimbursement"
              sectionName="reimbursements"
              selectedFilter={appliedFilters}
              additionalFilters={{
                ...(reportEnabled
                  ? EXPORT_PAGE_TYPE.REPORT
                  : EXPORT_PAGE_TYPE.REIMBURSEMENT),
                tab: TAB[PAGE_TYPE[pageType]],
              }}
            />

            {showRequestHistoryButton ? (
              <RequestHistoryButton
                context={PENDING_REQUEST_TABS_KEYS.REIMBURSEMENT}
              />
            ) : null}
          </div>
        </div>

        <Table
          {...{
            ...tableSetting,
            emptyDataTitle,
            emptyDataDescription: emptySubHeadingDataTitle,
          }}
          bulkApproveVisible={selectedReimbursements?.length > 0}
          bulkApproveHeight="100px"
          bulkApproveContent={
            <BulkActionComponent
              selectedRows={
                bulkSelectAll
                  ? deSelectedIds.length
                    ? totalReimbursementsSelected - deSelectedIds.length
                    : totalReimbursementsSelected
                  : selectedReimbursements?.length
              }
              totalRows={totalReimbursementsSelected}
              handleBulkAction={handleBulkApprove}
              descriptionText={
                showApproveCta ? "common.approvalDesc" : "common.exportDesc"
              }
              bulkApproveSelectAll={
                (bulkSelectAll && !deSelectedIds.length) ||
                selectedReimbursements?.length === totalReimbursementsSelected
              }
              showSelectAllButton={headerSelectAll}
              selectAllHandler={(val) => {
                setDeSelectedIds([]);
                setBulkSelectAll(val);
              }}
              handleExport={() => {
                handleExport(
                  false,
                  selectedReimbursements?.length,
                  appliedFilters,
                  dispatch,
                  {
                    ...(bulkSelectAll
                      ? {
                          ...(reportEnabled
                            ? EXPORT_PAGE_TYPE.REPORT
                            : EXPORT_PAGE_TYPE.REIMBURSEMENT),
                          deselected_ids: deSelectedIds,
                        }
                      : {
                          selected_ids: selectedReimbursements,
                        }),
                    tab: TAB[PAGE_TYPE[pageType]],
                    ...(reportEnabled
                      ? EXPORT_PAGE_TYPE.REPORT
                      : EXPORT_PAGE_TYPE.REIMBURSEMENT),
                  }
                );
              }}
              showExport={
                ![
                  ACTION_CENTRE_PAGE_CONTEXT.REIMBURSEMT,
                  ACTION_CENTRE_PAGE_CONTEXT.REPORT,
                ]?.includes(configKey)
              }
              showApprove={showApproveCta}
              clearSelection={() => {
                setBulkSelectAll(false);
                setHeaderSelectAll(false);
                setSelectedReimbursements([]);
                setDeSelectedIds([]);
                dispatch(
                  updateReimbursement(
                    reimbursements.map((payment) => {
                      return { ...payment, selected: !payment.selected };
                    })
                  )
                );
              }}
            />
          }
        >
          <tr className={tableHeadingRowClasses}>
            {tableHeading.map((headVal, index) => (
              <th className={headVal.classes} key={headVal.id}>
                <HeaderCell
                  val={{
                    ...headVal,
                    checkedValue: headerSelected && !isEmpty,
                    onCheckboxClick: headerSelectionHandler,
                    showCheckbox: index === 0 && !isFetching,
                    disabled: isEmpty || allQueued,
                    sorting,
                    setSorting,
                  }}
                />
              </th>
            ))}
          </tr>
          {!isFetching || !isEmpty
            ? reimbursements.map((val, index, arr) => {
                const disableCheck =
                  Boolean(
                    isActionApprovePending && isActionApprovePending === val.id
                  ) || val?.queued;
                return (
                  <tr
                    key={val.id}
                    className={`${tableDataRowClasses} ${
                      disableCheck
                        ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                        : ""
                    }`}
                    onClick={() => onClickHandler(val?.id)}
                    ref={(ref) => {
                      if (index === arr.length - 1 && hasMore) {
                        handleRefChange(ref);
                      }
                    }}
                  >
                    {tableHeading.map(
                      ({ cellComponent: Component, classes, id }) => (
                        <td
                          className={` ${
                            selectedReimbursements?.includes(val.id)
                              ? "selected-row-cell"
                              : ""
                          } ${
                            disableCheck
                              ? "bg-neutral-100 text-neutral-500"
                              : ""
                          } ${classes} }`}
                          key={id}
                        >
                          <Component
                            val={{
                              ...val,
                              onClickHandler: !disableCheck
                                ? onClickHandler
                                : null,
                              showCheckbox: true,
                              disabled: disableCheck,
                              context: reportEnabled
                                ? REPORT_CONTEXT
                                : REIMBURSEMENT_CONTEXT,
                              onCheckboxClick: () => handleRowSelection(val),
                              checkedValue:
                                headerSelectAll || bulkSelectAll
                                  ? showApproveCta
                                    ? selectedReimbursements?.includes(val?.id)
                                    : true
                                  : selectedReimbursements?.includes(val?.id),
                            }}
                            key={id}
                          />
                        </td>
                      )
                    )}
                  </tr>
                );
              })
            : null}
          {isFetching && <ApprovalsTableLoader />}
        </Table>
      </>
    )
  );
}
ReimbursementApprovalList.propTypes = {
  pageType: PropTypes.string,
  onClickHandler: PropTypes.func,
};
