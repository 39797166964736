import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchUserWallets,
  resetUserWalletListAndPagination,
} from "@/store/reducers/qr-payments";

import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  UserWalletListSelector,
  hasMoreUserWalletSelector,
  isFetchingUserWalletListSelector,
  totalUserWalletSelector,
} from "@/store/selectors/qr-payments";

import Filters from "@/components/core/Filters";

import CardsTable from "@/components/Cards/CardsTable";
import Export from "@/components/Exports";
import { convertFilters } from "@/utils/filters";
import { WALLET_FREQUENCY } from "@/utils/constants/filters";
import { dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { CARD_FREQUENCY, CARD_FREQUENCY_BE_MAPPING } from "@/constants/card";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  LINKED_TO_TYPE,
  USER_WALLET_STATUS,
  USER_WALLET_TABLE_HEADERS,
  USER_WALLET_TABLE_HEADER_KEYS,
  USER_WALLET_TAB_STATUS_MAP,
  WALLET_FREQUENCY_CORE,
} from "@/constants/qrPayments";
import {
  USER_WALLETS_ALL_PATH_NAME,
  USER_WALLETS_YET_TO_ACTIVATE_PATH_NAME,
} from "@/constants/routes";

export default function UserWalletsPageHelper({
  tab = USER_WALLETS_ALL_PATH_NAME,
  filterArray = [],
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userWalletList = useSelector(UserWalletListSelector);
  const isFetching = useSelector(isFetchingUserWalletListSelector);
  const totalUserWalletSelected = useSelector(totalUserWalletSelector);
  const hasMore = useSelector(hasMoreUserWalletSelector);
  const appliedFilter = useSelector(appliedFilterSelector);
  const filterOptions = {
    ...USER_WALLET_TAB_STATUS_MAP[tab],
    ...convertFilters(appliedFilter),
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const onReset = () => {
    dispatch(resetUserWalletListAndPagination());
  };
  const loadMore = () => {
    dispatch(
      fetchUserWallets({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...filterOptions,
      })
    );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  const handleRowClick = (id) => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.qrPayments.userWallet.id, id);
    setSearchParams(searchParams);
  };

  const formatDataForTable = (wallet) =>
    wallet
      ? {
          id: wallet.id,
          [USER_WALLET_TABLE_HEADER_KEYS.WALLET_HOLDER]: {
            index: wallet.id,
            displayName: wallet.walletHolder.displayName,
            role: wallet.walletHolder.roles,
            avatarUrl: wallet.walletHolder.avatarUrl,
            department: wallet.walletHolder.departmentName,
            location: wallet.walletHolder.locationName,
            walletHolder: true,
            isTemporaryLimit: wallet.isTemporaryLimit,
            topUpRequests: wallet.topUpRequests,
            topUpRequestTooltip: {
              amount: wallet.topUpRequests.amount,
              currency: wallet.topUpRequests.currency,
              frequency: WALLET_FREQUENCY[wallet.topUpRequests.frequency],
            },
            temporaryLimitTooltip: {
              amount: wallet?.limit,
              date: wallet?.activationDate,
              currency: wallet?.currency,
              frequency:
                CARD_FREQUENCY[CARD_FREQUENCY_BE_MAPPING[wallet?.frequency]],
            },
          },
          [USER_WALLET_TABLE_HEADER_KEYS.LIMIT]: {
            amount: wallet.limit,
            currency: wallet.currency,
            frequency: wallet.frequency,
            isEditable: !USER_WALLET_STATUS.INACTIVE.includes(wallet.status),
          },
          [USER_WALLET_TABLE_HEADER_KEYS.SPENT]: {
            spent:
              wallet.frequency === WALLET_FREQUENCY_CORE.RECURRING
                ? wallet.monthlySpent
                : wallet.totalSpent,
            total: wallet.limit,
            currency: wallet.currency,
          },
          [USER_WALLET_TABLE_HEADER_KEYS.AVAILABLE]: {
            availableBalance: wallet.availableBalance,
            currency: wallet.currency,
          },
          [USER_WALLET_TABLE_HEADER_KEYS.LINKED_TO]: {
            linkedToType:
              wallet.linkedTo === LINKED_TO_TYPE.PROJECT
                ? LINKED_TO_TYPE.PROJECT
                : LINKED_TO_TYPE.DEPARTMENT,
            linkedToName: wallet?.project?.name,
          },
          [USER_WALLET_TABLE_HEADER_KEYS.ACTIVATION_DATE]: {
            translationKey: dateToString(wallet.activationDate),
          },
        }
      : {};
  const dataArray = useMemo(() => {
    return userWalletList.map((wallet) => formatDataForTable(wallet));
  }, [userWalletList]);
  return (
    <>
      <div className="flex items-start ">
        <div className="flex flex-col flex-1 w-11/12">
          <Filters filters={filterArray} />
        </div>
        <div className="flex justify-end mt-4">
          <Export
            onExport={() => {}}
            totalExports={totalUserWalletSelected}
            storeName="qrPay"
            sectionName="qrPay"
          />
        </div>
      </div>
      <div className="mt-5">
        <CardsTable
          handleRowClick={handleRowClick}
          handleRefChange={handleRefChange}
          hasMore={hasMore}
          isFetchingData={isFetching}
          data={dataArray}
          headers={USER_WALLET_TABLE_HEADERS.USER_WALLET}
          emptyDataTitle="QRPay.userWallets.emptyDataTitle"
          emptyDataDescription="QRPay.userWallets.emptyDataDescription"
          classes="text-neutral-800"
        />
      </div>
    </>
  );
}

UserWalletsPageHelper.propTypes = {
  tab: PropTypes.oneOf([
    USER_WALLETS_ALL_PATH_NAME,
    USER_WALLETS_YET_TO_ACTIVATE_PATH_NAME,
  ]),
  filterArray: PropTypes.array,
};
