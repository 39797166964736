import PropTypes from "prop-types";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import Badge from "@/components/core/Badge";
import Text from "@/components/core/Text";
import { amountToCurrency } from "@/utils/common";
import { PAYMENT_LIST_BADGE_TYPES } from "@/utils/constants/payments";

export default function PaymentSummaryShort({ payment }) {
  const { color, text } =
    PAYMENT_LIST_BADGE_TYPES[payment.accountingStatus] ?? {};

  const ref = useLeftHeaderTitle({
    title: payment?.vendor?.name,
    img: payment?.vendor?.avatarUrl,
  });
  return (
    <div className="flex items-center gap-2" ref={ref}>
      <Text
        translationKey={payment?.vendor?.name}
        classes="text-3xl text-neutral-800 font-bold"
      />
    </div>
  );
}

PaymentSummaryShort.propTypes = {
  payment: PropTypes.object,
};
