import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCountries } from "@/store/reducers/app";

import { countriesSelector } from "@/store/selectors/app";

export default function useGetCountry(countryCode) {
  // for country name
  const [country, setCountry] = useState("");
  const dispatch = useDispatch();
  const countries = useSelector(countriesSelector);
  useEffect(() => {
    if (!countries.length) dispatch(fetchCountries());
    setCountry(countries?.find((item) => item.isoCode === countryCode)?.name); // filter here
  }, [countryCode, countries?.length]);

  return country;
}
