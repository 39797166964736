import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import { isBoolean } from "@/utils/common";

// Logic for this component will be handled later
export default function InvoiceCell({ val }) {
  // 3 values => true | false | null (unfilled)
  return (
    <div className="flex items-center justify-center">
      {isBoolean(val?.isUploadedViaMail) || val?.invoiceUrl?.length ? (
        <Icon
          name={val?.isUploadedViaMail ? "InvoiceFromMail" : "InvoiceUploaded"}
          className="text-success-600"
        />
      ) : (
        "-"
      )}
    </div>
  );
}

InvoiceCell.propTypes = {
  val: PropTypes.object,
};
