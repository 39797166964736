import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchPeople } from "@/store/reducers/company";
import {
  changeUserAccess,
  fetchAndSelectUser,
  resetUserAccess,
} from "@/store/reducers/user";

import {
  availableModulesSelector,
  isFetchingSelectedUserSelector,
  isUserAccessChangeInprogress,
  isUserAccessChangedSelector,
  selectedUserSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import { joinArrayOfSentences } from "@/utils/common";
import { MODULES } from "@/utils/constants/app";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_USER_STATUS,
  INVITATION_STATUS,
} from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  USER_ACCESS_CONTANTS,
  USER_ACCESS_SETTINGS_KEYS,
} from "@/constants/user";

export default function UserDetailsSettings() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const availableModules = useSelector(availableModulesSelector);
  const selectedUser = useSelector(selectedUserSelector);
  const inProgress = useSelector(isUserAccessChangeInprogress);
  const { t } = useTranslation();
  const isFetchingSelectedUserInfo = useSelector(
    isFetchingSelectedUserSelector
  );
  const isUserAccessChanged = useSelector(isUserAccessChangedSelector);
  const userId = selectedUser?.id;

  const userAccessSettingskeysStatus = useMemo(() => {
    const status = {};

    Object.values(USER_ACCESS_SETTINGS_KEYS).forEach((key) => {
      const isPresent = selectedUser?.ctas?.find(
        (responseCta) => responseCta === key && responseCta !== "lockUser"
      );
      status[key] = isPresent;
    });

    return status;
  }, [selectedUser]);

  useEffect(() => {
    if (isUserAccessChanged && !inProgress) {
      resetUserAccessFields();
      dispatch(fetchAndSelectUser({ userId }));
    }

    return () => {
      resetUserAccessFields();
    };
  }, [inProgress, isUserAccessChanged]);

  function getRequiredComponentByAccessKey(accessKey) {
    const componentProps = {};
    componentProps.show = userAccessSettingskeysStatus[accessKey];
    componentProps.disabled = inProgress;
    const arrayOfSentences = [];
    const toFreeze =
      accessKey === USER_ACCESS_SETTINGS_KEYS[USER_ACCESS_CONTANTS.FREEZED];
    if (availableModules?.includes(MODULES.CARDS))
      arrayOfSentences.push(t("company.people.settings.cardsSentence"));
    if (availableModules?.includes(MODULES.BILL_PAY))
      arrayOfSentences.push(t("company.people.settings.paymentsSentence"));
    if (availableModules?.includes(MODULES.REIMBURSEMENTS))
      arrayOfSentences.push(t("company.people.settings.reimbursmentSentence"));
    const sentence = joinArrayOfSentences(arrayOfSentences, t);
    switch (accessKey) {
      case USER_ACCESS_SETTINGS_KEYS[USER_ACCESS_CONTANTS.BLOCKED]:
        componentProps.title = "company.people.settings.blockTitle";
        componentProps.desc = "company.people.settings.blockDesc";
        componentProps.btnVariant = "secondary";
        componentProps.btnType = "danger";
        componentProps.handleBtnClick = () => {
          handleButtonClick(SLIDERS_SEARCH_PARAMS.company.people.blockUser);
        };

        componentProps.btnLabel = "company.people.settings.blockBtn";
        componentProps.btnTextColor = "text-danger-500";
        break;

      case USER_ACCESS_SETTINGS_KEYS[USER_ACCESS_CONTANTS.UNFREEZED]:
      case USER_ACCESS_SETTINGS_KEYS[USER_ACCESS_CONTANTS.FREEZED]:
        componentProps.title = `company.people.settings.${
          toFreeze ? "freezeTitle" : "unFreezeTitle"
        }`;
        componentProps.desc = `company.people.settings.${
          toFreeze ? "freezeDesc" : "unFreezeDesc"
        }`;

        componentProps.descProps = { text: sentence };
        componentProps.btnVariant = "tertiary";
        componentProps.btnType = "default";
        componentProps.handleBtnClick = () =>
          handleFreezeUnfreeBtnClick(accessKey);
        componentProps.btnLabel = `company.people.settings.${
          toFreeze ? "freezeBtn" : "unFreezeBtn"
        }`;
        componentProps.btnTextColor = "text-neutral-500";
        break;

      default:
        break;
    }
    return componentProps;
  }

  function handleLockUnlockBtnClick(accessKey) {
    if (accessKey === USER_ACCESS_SETTINGS_KEYS[USER_ACCESS_CONTANTS.LOCKED]) {
      handleButtonClick(SLIDERS_SEARCH_PARAMS.company.people.lockUser);
    } else {
      changeUserAccessFunc(USER_ACCESS_CONTANTS.UNLOCKED);
    }
  }
  const status = [
    COMPANY_PEOPLE_USER_STATUS.ACTIVE,
    COMPANY_PEOPLE_USER_STATUS.PENDING,
    COMPANY_PEOPLE_USER_STATUS.SUBMITTED,
    COMPANY_PEOPLE_USER_STATUS.REJECTED,
    COMPANY_PEOPLE_USER_STATUS.FREEZED,
  ];
  const onSuccess = () => {
    dispatch(
      fetchPeople({
        status,
        invitation_status: [INVITATION_STATUS.ACCEPTED],
        order_by_name: true,
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
      })
    );
  };

  function handleFreezeUnfreeBtnClick(accessKey) {
    if (accessKey === USER_ACCESS_SETTINGS_KEYS[USER_ACCESS_CONTANTS.FREEZED]) {
      handleButtonClick(SLIDERS_SEARCH_PARAMS.company.people.freezeUser);
    } else {
      changeUserAccessFunc(USER_ACCESS_CONTANTS.UNFREEZED);
    }
  }

  function handleButtonClick(searchParamKey) {
    searchParams.append(searchParamKey, true);
    setSearchParams(searchParams);
  }

  function changeUserAccessFunc(userAccessOperation) {
    dispatch(
      changeUserAccess({
        operation: userAccessOperation,
        id: userId,
        onSuccess,
      })
    );
  }

  function resetUserAccessFields() {
    dispatch(resetUserAccess());
  }

  return (
    <div className="flex flex-col justify-between py-9 h-4/5 ">
      {Object?.keys(userAccessSettingskeysStatus)?.map((ctaKey) => {
        const componentProps = getRequiredComponentByAccessKey(ctaKey);
        const {
          title,
          desc,
          btnLabel,
          btnType,
          btnTextColor,
          handleBtnClick,
          btnVariant,
          show,
          disabled,
          descProps = null,
        } = componentProps || {};

        if (inProgress && isFetchingSelectedUserInfo) {
          return (
            <UserDetailsSettingsSkelaton
              key={`user-details-settings-${ctaKey}`}
            />
          );
        }

        return show ? (
          <div
            className={`flex flex-col gap-6 ${
              ctaKey === USER_ACCESS_SETTINGS_KEYS[USER_ACCESS_CONTANTS.BLOCKED]
                ? "pb-4"
                : ""
            }`}
            key={`user-details-settings-${ctaKey}`}
          >
            {ctaKey ===
            USER_ACCESS_SETTINGS_KEYS[USER_ACCESS_CONTANTS.BLOCKED] ? (
              <hr className="text-neutral-200" />
            ) : null}
            <div className="flex flex-col gap-1">
              <Text translationKey={title} classes="text-base font-semibold" />
              <Text
                translationProps={descProps}
                translationKey={desc}
                classes="text-sm font-medium text-neutral-500"
              />
            </div>

            <div>
              <Button
                classes={`p-4 w-fit ${btnTextColor}`}
                variant={btnVariant}
                label={btnLabel}
                type={btnType}
                onClick={handleBtnClick}
                disabled={disabled}
                showLoader={disabled}
              />
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
}

const UserDetailsSettingsSkelaton = () => {
  return (
    <div className="flex flex-col gap-3">
      <LoaderSkeleton size={[20, 400]} />
      <LoaderSkeleton size={[50, 600]} />
      <LoaderSkeleton size={[40, 100]} />
    </div>
  );
};
