import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { reimbursementReportsEnabledSelector } from "@/store/selectors/client";

import ReimbursementApprovalList from "@/components/Reimbursement/ApprovalList";
import { PAGE_TYPE } from "@/utils/constants/reimbursement";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function ApprovalsPageHelper({ pageType }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  const onClickHandler = (approvalId) => {
    if (reportEnabled) {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.reports.reportId]: approvalId,
        [SLIDERS_SEARCH_PARAMS.reimbursements.pageType]: PAGE_TYPE[pageType],
      });
    } else {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.reimbursements.approveId]: approvalId,
        [SLIDERS_SEARCH_PARAMS.reimbursements.pageType]: PAGE_TYPE[pageType],
      });
    }
  };

  return (
    <ReimbursementApprovalList
      pageType={pageType}
      onClickHandler={(approvalId) => onClickHandler(approvalId)}
    />
  );
}

ApprovalsPageHelper.propTypes = {
  pageType: PropTypes.string,
};
