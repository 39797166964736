import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function EmptyRow({
  toolTipPosition,
  toolTipText,
  index,
  showToolTip = false,
  classes = "",
}) {
  return (
    <>
      <div id={`empty-row-${index}`}>
        <Text translationKey="-" noTranslate />
      </div>
      {showToolTip ? (
        <Tooltip direction={toolTipPosition} id={`empty-row-${index}`}>
          <div className="flex flex-col text-sm">
            <Text classes="text-neutral-500" translationKey={toolTipText} />
          </div>
        </Tooltip>
      ) : null}
    </>
  );
}

EmptyRow.propTypes = {
  toolTipPosition: PropTypes.string,
  toolTipText: PropTypes.string,
  index: PropTypes.number,
  showToolTip: PropTypes.bool,
  classes: PropTypes.string,
};
