import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import VendorSelectedPreview from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/VendorInfo/VendorSelectedPreview";
import { BILLPAY_BILL_CTA_KEYS } from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

/**
 *
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=3974-221730&mode=dev}
 * payroll {@link https://www.figma.com/file/nYb1B3o06dNJPuFhW7fLWP/v2-Payroll?type=design&node-id=4825-270436&mode=design&t=zvZOgDFzWghB6ZDW-4}
 */
export default function VendorDetails({
  paymentId,
  vendor,
  senderCurrency,
  context,
  inPayrollContext,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const showPencilCta = vendor?.ctas?.some(
    (data) => data === BILLPAY_BILL_CTA_KEYS.edit
  );

  return (
    <VendorSelectedPreview
      senderCurrency={senderCurrency}
      paymentId={paymentId}
      vendor={vendor}
      showEditButton={showPencilCta}
      editVendorHandler={() => {
        // actually just redirect to vendor, no edit
        searchParams.append(
          inPayrollContext
            ? SLIDERS_SEARCH_PARAMS.employees.id
            : SLIDERS_SEARCH_PARAMS.vendors.id,
          vendor?.id
        );
        setSearchParams(searchParams);
      }}
      inPayrollContext={inPayrollContext}
      context={context}
      contactsReadOnly={false}
    />
  );
}

VendorDetails.propTypes = {
  paymentId: PropTypes.number,
  vendor: PropTypes.object,
  senderCurrency: PropTypes.string,
  inPayrollContext: PropTypes.bool,
  context: PropTypes.string,
};
