import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

// TODO: add count===0 complete 'green' icon, texts to 'important section'
// see src/components/common/BillPayAndPayroll/VendorOrEmployee/DeleteEmployee/index.js for props
function ImportantToKnowSection({
  config = [],
  mainLabelConfig,
  allSectionContainerClasses = "",
  classes = "",
  isFetchingTotalCount, // at heading level
  totalCount,
  totalCountClasses = "",
  showSectionExpandableStatusText,
  expandableIconClasses,
  headerSectionClasses,
  innerContentWrapperClasses,
}) {
  const [expandableArrowPosition, setExpandableArrowPosition] = useState({}); // {key=index, value=true/false}

  const showImportantSection = config.length > 0;

  function handleExpandArrowClick(
    index,
    isSectionExpanded,
    triggerFuncOnExpand,
    handleActionOnClose
  ) {
    if (triggerFuncOnExpand && !isSectionExpanded) {
      triggerFuncOnExpand();
    }
    if (handleActionOnClose && isSectionExpanded) {
      handleActionOnClose();
    }
    setExpandableArrowPosition({
      ...expandableArrowPosition,
      [index]: !isSectionExpanded,
    });
  }

  useEffect(() => {
    config.forEach((item) => {
      item?.runWhenStillCollapsed?.(); // to get count before user expands the accordion
      // Example: this runs a redux thunk (with dynamic redux key), and innerContent has
      // a selector for count (total value gotten from first page call) of that key
      // Real example: DeleteEmployee component uses this
    });

    return () => {
      config.forEach((item) => {
        item?.cleanup?.();
        // needed since show/hide runs usePagination's onReset (due to mount/unmount) making content invisible
        // having this cleanup and making onReset empty function helps
        // make sure to run onReset code in cleanup and also as part of runWhenStillCollapsed
      });
    };
  }, []);

  return (
    <div className={`flex flex-col gap-2 ${classes}`}>
      <div className="flex items-center gap-2">
        {mainLabelConfig?.icon ? (
          <Icon
            name={mainLabelConfig?.icon}
            className={mainLabelConfig?.iconClasses}
            {...mainLabelConfig?.iconProps}
          />
        ) : null}

        {mainLabelConfig?.label ? (
          <Text
            translationKey={mainLabelConfig?.label}
            classes={mainLabelConfig?.classes}
          />
        ) : null}
        {isFetchingTotalCount ? (
          // spin loader
          <svg
            className="w-5 h-5 mr-3 animate-spin "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : totalCount ? (
          <span
            className={`text-xs ${totalCountClasses} px-2 py-0.5 rounded w-fit`}
          >
            <Text translationKey={totalCount} />
          </span>
        ) : null}
      </div>
      {showImportantSection ? (
        <div
          className={`flex flex-col gap-4 card-wrapper ${allSectionContainerClasses}`}
        >
          {config.map((section, index) => {
            const sectionLabel = section?.labelConfig?.label;
            const labelClasses = section?.labelConfig?.classes;
            const labelTranslationProps =
              section?.labelConfig?.translationProps;
            const showSectionLabel =
              sectionLabel !== undefined || sectionLabel !== null;
            const showIcon = section?.verticalExpandable;
            const iconName =
              showIcon && expandableArrowPosition[index]
                ? "UpArrow"
                : "ExpandMore";
            const iconClasses = `transition-all ease-in cursor-pointer duration-600 stroke-neutral-500 ${
              showIcon && expandableArrowPosition[index]
                ? "w-4 h-4 mr-1"
                : "w-6 h-6"
            }`;
            const isSectionExpanded = iconName === "UpArrow";
            const InnerContent = section?.innerContent;
            const showExpandedInnerContent = InnerContent && isSectionExpanded;
            const count = section?.count;
            const isFetching = section?.isFetching;
            const triggerFuncOnExpand = section?.triggerFuncOnExpand;
            const handleActionOnClose = section?.handleActionOnClose;

            return (
              <div
                className={`${
                  index < config.length - 1 &&
                  "pb-2 border-b border-solid border-b-neutral-200 cursor-pointer"
                } ${section?.sectionClasses}`}
                key={`${section?.key}-${index}`}
              >
                <div
                  className={`flex flex-row items-center justify-between ${headerSectionClasses}`}
                >
                  <div>
                    <div
                      aria-label="label-with-count"
                      className="flex items-center gap-2"
                    >
                      {showSectionLabel ? (
                        typeof sectionLabel === typeof "" ? (
                          <span>
                            <Text
                              translationKey={sectionLabel}
                              classes={labelClasses}
                              translationProps={labelTranslationProps}
                            />
                          </span>
                        ) : (
                          sectionLabel
                        )
                      ) : null}

                      {isFetching ? (
                        // spin loader
                        <svg
                          className="w-5 h-5 mr-3 animate-spin "
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      ) : count ? (
                        <span className="text-xs text-white bg-neutral-500 px-2 py-0.5 rounded w-fit">
                          <Text translationKey={count} />
                        </span>
                      ) : null}
                    </div>

                    {section?.sectionDescription ? (
                      <Text
                        translationKey={section?.sectionDescription}
                        classes={`inline-block ${
                          section?.descriptionClasses ||
                          "text-neutral-500 text-sm font-medium"
                        }`}
                        translationProps={section?.descriptionTranslationProps}
                      />
                    ) : null}
                  </div>
                  {/* toggle icon */}
                  {showIcon ? (
                    <div
                      onClick={() =>
                        handleExpandArrowClick(
                          index,
                          isSectionExpanded,
                          triggerFuncOnExpand,
                          handleActionOnClose
                        )
                      }
                      className="flex flex-row items-center gap-2 cursor-pointer"
                    >
                      {showSectionExpandableStatusText ? (
                        <Text
                          translationKey={isSectionExpanded ? "Hide" : "Show"}
                          classes="text-xs font-medium text-netral-800 "
                        />
                      ) : null}

                      <Icon
                        name={iconName}
                        className={`text-neutral-600 ${iconClasses} ${expandableIconClasses} ${
                          !expandableIconClasses?.includes("w-") && "w-4"
                        } ${!expandableIconClasses?.includes("h-") && "h-4"}`}
                      />
                    </div>
                  ) : null}
                </div>

                {showExpandedInnerContent ? (
                  <div
                    className={` ${innerContentWrapperClasses} ${
                      !innerContentWrapperClasses?.includes("mt-") && "mt-4"
                    }`}
                  >
                    {InnerContent}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

ImportantToKnowSection.propTypes = {
  config: PropTypes.arrayOf(PropTypes.object),
  mainLabelConfig: PropTypes.object,
  allSectionContainerClasses: PropTypes.string,
  classes: PropTypes.string,
  isFetchingTotalCount: PropTypes.bool,
  totalCount: PropTypes.number,
  totalCountClasses: PropTypes.string,
  showSectionExpandableStatusText: PropTypes.bool,
  expandableIconClasses: PropTypes.string,
  headerSectionClasses: PropTypes.string,
  innerContentWrapperClasses: PropTypes.string,
};

export default ImportantToKnowSection;
