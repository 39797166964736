import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

// import "@/assets/styles/theme.scss";

import { getColorCode, getMemoizedColor, getRGBAColor } from "@/utils/common";

// Memoized function to get color based on name

function Avatar({
  name = "",
  imgSrc = "",
  color = "",
  size = "md",
  imgClasses = "",
  opacityChangeRatio = 0,
}) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const avatarColor = useMemo(() => {
    return color || getMemoizedColor(name);
  }, [color, name]);

  useEffect(() => {
    setIsError(!imgSrc);
    setIsLoading(!!imgSrc);
  }, [imgSrc]);

  function convertNameToInitials() {
    let fullName = (typeof name === typeof "" ? name : "").trim().split(" ");

    fullName = fullName.filter(
      // eslint-disable-next-line quotes
      (word) => !word.includes('"') && !word.includes("(")
    );

    return fullName.length > 1
      ? fullName[0].charAt(0) + fullName[fullName.length - 1].charAt(0)
      : fullName[0]?.charAt(0) || "v";
  }

  function getInitials() {
    return convertNameToInitials().toUpperCase();
  }

  const avatarStyle =
    isError || isLoading
      ? {
          backgroundColor: getRGBAColor(
            avatarColor,
            opacityChangeRatio ? 0.1 / opacityChangeRatio : 0.1
          ),
          color: getRGBAColor(
            avatarColor,
            opacityChangeRatio ? 0.9 / opacityChangeRatio : 0.9
          ),
          borderColor: getRGBAColor(
            avatarColor,
            opacityChangeRatio ? 0.9 / opacityChangeRatio : 0.9
          ),
        }
      : {};

  return (
    <div
      className={`avatar avatar-${size} ${getColorCode(avatarColor, 0.9)} ${
        !isError && !isLoading ? "img" : ""
      }`}
      style={avatarStyle}
    >
      {!isError && !isLoading ? (
        <img
          src={imgSrc}
          alt={name}
          className={imgClasses}
          onLoad={() => setIsLoading(false)}
          onError={(e) => {
            e.stopPropagation();
            setIsError(true);
            setIsLoading(false);
          }}
        />
      ) : (
        <span>{getInitials()}</span>
      )}
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
  imgSrc: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  imgClasses: PropTypes.string,
  opacityChangeRatio: PropTypes.number,
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.name === nextProps.name &&
    prevProps.imgSrc === nextProps.imgSrc &&
    prevProps.color === nextProps.color &&
    prevProps.size === nextProps.size &&
    prevProps.imgClasses === nextProps.imgClasses &&
    prevProps.opacityChangeRatio === nextProps.opacityChangeRatio
  );
}

export default React.memo(Avatar, areEqual);
