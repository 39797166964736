import PropTypes from "prop-types";
import { useId } from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function TextWithTooltipIcon({
  text = "",
  classes = "",
  tooltipText = "",
  icon = "Info",
  iconClasses = "text-neutral-500 p-[1.5px] h-5 w-5 hover:cursor-pointer",
  direction = "bottom",
  textClasses = "text-base text-neutral-800 font-semibold",
  tooltipClasses = "text-sm font-medium text-neutral-800",
  maxWidth,
  customStyle,
  tooltipTextProps,
  descriptionTooltipChildren,

  descriptionTooltipText,
  descriptionTooltipClasses,
  descriptionTooltipProps,
}) {
  const id = useId();
  const _id = `tooltips-${id.replaceAll(":", "")}`;
  return (
    <div className={`flex items-center gap-1 shadow-neutral-200 ${classes}`}>
      <Text translationKey={text} classes={textClasses} />
      {tooltipText ? (
        <span id={_id}>
          <Icon name={icon} className={iconClasses} />
          <Tooltip
            id={_id}
            direction={direction}
            maxWidth={maxWidth}
            customStyle={customStyle}
          >
            <div className="flex flex-col">
              <Text
                translationKey={tooltipText}
                classes={tooltipClasses}
                translationProps={tooltipTextProps}
              />
              {descriptionTooltipChildren ? descriptionTooltipChildren : null}
              {descriptionTooltipText ? (
                <Text
                  translationKey={descriptionTooltipText}
                  classes={descriptionTooltipClasses}
                  translationProps={descriptionTooltipProps}
                />
              ) : null}
            </div>
          </Tooltip>
        </span>
      ) : null}
    </div>
  );
}

TextWithTooltipIcon.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.string,
  tooltipText: PropTypes.string,
  icon: PropTypes.string,
  iconClasses: PropTypes.string,
  direction: PropTypes.string,
  textClasses: PropTypes.string,
  tooltipClasses: PropTypes.string,
  maxWidth: PropTypes.string,
  customStyle: PropTypes.object,
  tooltipTextProps: PropTypes.object,
  descriptionTooltipText: PropTypes.string,
  descriptionTooltipChildren: PropTypes.node,
  descriptionTooltipClasses: PropTypes.string,
  descriptionTooltipProps: PropTypes.object,
};
