import PropTypes from "prop-types";

import { dateToString } from "@/utils/common";

export default function MonthAndYearCell({ val }) {
  return (
    <span>
      {val?.payrollMonth
        ? dateToString(val?.payrollMonth, { day: undefined })
        : "-"}
    </span>
  );
}
MonthAndYearCell.propTypes = {
  val: PropTypes.object,
};
