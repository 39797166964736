import PropTypes from "prop-types";

import Badge from "@/components/core/Badge";
import Text from "@/components/core/Text";

export default function AccountingIntegrationsHeader({ sectionTitleText }) {
  return (
    <div className="flex w-full">
      <div className="text-sm font-semibold text-neutral-500">
        <Text translationKey="accounting.title" />
        &nbsp;/&nbsp;
        <Text translationKey="accounting.integrations.title" />
        <p className="flex items-center gap-3 text-4xl font-extrabold text-neutral-800">
          <Text translationKey={sectionTitleText} />
          <Badge
            translationKey="misc.connected"
            variant="success"
            classes="px-2"
          />
        </p>
      </div>
    </div>
  );
}

AccountingIntegrationsHeader.propTypes = {
  sectionTitleText: PropTypes.string,
};
