import { amountToCurrency } from "@/utils/common";

/**
 * Generate props ApexCharts Donut (used for Budget slider)
 *
 * @param {Array<Numbers>} values
 * @param {Boolean} showLegends
 *
 * @param {Array<String>} colors # hex values
 * @param {String} centerLabel
 * @param {Object} centerLabelStyles
 *
 */
export const generatePieChartProps = (originalProps) => {
  const {
    values = [25, 25, 25, 25],
    colors = ["#FF0000"],
    labels,
    centerLabel = "",
    centerLabelStyles = null,
    sideLength = "100%",
    sideWidth,
    showLegends = true,
    valueText,
  } = originalProps;

  const isNullOrZeroarray = values.map((i) => !!i);
  const isDiabledState = !isNullOrZeroarray.includes(true);

  return {
    // series: [44, 55, 41, 17, 15], // array of numbers, dist w.r.t total
    height: sideLength,
    width: sideWidth ? sideWidth : showLegends ? sideLength * 5 : sideLength, // FIXME (width with legends)
    type: "donut",

    redrawOnParentResize: true,
    series: isDiabledState
      ? values.map((i, idx) => (idx === 0 ? 1 : 0))
      : values,
    options: {
      chart: { type: "donut" },
      dataLabels: {
        enabled: false,
      }, // no labels on parts
      colors,
      fill: colors && !isDiabledState ? { colors } : { colors: ["#E5E6EA"] },
      stroke: { width: 0 }, // no gaps
      plotOptions: {
        chart: { type: "donut", width: "20px" },
        pie: {
          customScale: 0.7,
          offsetX: -20,
          donut: {
            size: "80%", // thickness
            labels: {
              // https://apexcharts.com/docs/options/plotoptions/pie/#labels

              // config to show center label
              // ApexChart API is not stable
              // just change the total.formatter
              show: true, // have to show frequency in the middle
              showAlways: true,
              name: { show: false },
              value: {
                show: true,
                // needed for label to be vertically centered
                // offsetY: 6,
              },

              total: {
                show: true,
                showAlways: true,
                ...centerLabelStyles,
                formatter(W) {
                  return centerLabel;
                },
              },
            },
          },

          // expandOnClick: false,
        },
      },
      // 17 and 25.5

      tooltip: {
        ...(isDiabledState ? { enabled: false } : {}),
        fillSeriesColor: true,
        y: {
          formatter(val) {
            if (isDiabledState) return "";
            return amountToCurrency(val);
          },
          title: {
            formatter(seriesName) {
              const index = seriesName.split("-")[1];
              const idx = parseInt(index, 10) - 1;
              if (isDiabledState) return "";
              return labels[idx];
            },
          },
        },
      },
      legend: {
        show: showLegends,

        markers: {
          fillColors: colors,
        },

        // custom legends doesn't support mouse events,
      },
    },
    ...originalProps,
  };
};
