import PropTypes from "prop-types";
import React from "react";

import Alert from "@/components/core/Alert";

import { EXPENSE_STATUSES, FLAGGED_EXPENSE_ALERT } from "@/constants/expense";

export default function FlagAlert({
  currentUserId,
  flaggedById,
  flaggedByName,
  repaymentStatus,
  reason,
}) {
  let config;
  if (!flaggedById) {
    config = FLAGGED_EXPENSE_ALERT.AUTO_FLAGGED;
  } else if (currentUserId === flaggedById) {
    config = FLAGGED_EXPENSE_ALERT.FLAGGED_BY_YOU;
  } else if (
    [EXPENSE_STATUSES.REQUESTED, EXPENSE_STATUSES.RECEIVED].includes(
      repaymentStatus
    )
  ) {
    config = FLAGGED_EXPENSE_ALERT.FLAGGED_BY_OTHERS_REPAYMENT;
  } else {
    config = FLAGGED_EXPENSE_ALERT.FLAGGED_BY_OTHERS;
  }

  if (reason) {
    config.description2 = reason;
  }

  return (
    <div className="my-4">
      <Alert
        wrapperDivClass={currentUserId !== flaggedById ? "items-start" : null}
        textClasses="text-neutral-800 font-semibold"
        descriptionClasses="font-medium text-neutral-600"
        variant="danger"
        icon="Flag"
        title={config?.label}
        description={config?.description}
        titleTransalationProp={{ flaggedBy: flaggedByName }}
        iconClasses={`text-danger-500${
          currentUserId !== flaggedById ? " mt-2" : ""
        }`}
        description2="comments.reason"
        description2TranslationProp={{ reason: config.description2 }}
      />
    </div>
  );
}
FlagAlert.propTypes = {
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flaggedById: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flaggedByName: PropTypes.string,
  repaymentStatus: PropTypes.string,
  reason: PropTypes.string,
};
