import PropTypes from "prop-types";
import React, { useEffect } from "react";

/**
 * @param {String} leftText
 * @param {String} rightText
 * @param {String} leftValue
 * @param {String} rightValue
 * @param {String} desc
 */
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";

function DistanceToAmount({
  leftText,
  rightText,
  leftValue,
  rightValue,
  description,
}) {
  return (
    <div>
      {/* Distance travelled in miles   --------   Amount calculated as per the mileage rate of that country */}
      <div className="flex items-center justify-between p-4 border rounded-md border-neutral-200 bg-neutral-50">
        <div className="w-1/3">
          <TextWithTooltipIcon text={leftText} />
          <div>
            <span>
              <Text
                translationKey={leftValue?.distance}
                classes="text-2xl font-bold"
              />
              &nbsp;
              <Text
                translationKey={leftValue?.translationKey}
                classes="text-xl font-semibold text-neutral-500"
              />
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-1/3 ">
          <div className="h-6 w-0.5 bg-neutral-300 decoration-dashed"> </div>
          <Icon name="SyncAlt" className="text-primary-600" />
          <div className="h-6 w-0.5 bg-neutral-300 decoration-dotted"> </div>
        </div>
        <div className="flex flex-col items-end w-1/3 ">
          {" "}
          <TextWithTooltipIcon text={rightText} />
          <div>
            <span className="text-2xl font-bold ">
              <Text
                translationKey={rightValue?.amount}
                classes="text-2xl font-bold"
              />
              &nbsp;
              <Text
                translationKey={rightValue?.currency}
                classes="text-xl font-semibold text-neutral-500"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="px-4 mt-2">
        {description ? (
          <Text {...description} classes="text-neutral-500 text-sm" />
        ) : null}
      </div>
    </div>
  );
}

export default DistanceToAmount;

DistanceToAmount.propTypes = {
  leftText: PropTypes.string,
  leftValue: PropTypes.object,
  rightText: PropTypes.string,
  rightValue: PropTypes.object,
  description: PropTypes.object,
};
