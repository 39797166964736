import { VALUE_VENDOR_ID, VENDOR_ID } from "@/constants/tags";

import { DATA_TO_CONVERT_TYPE } from "./constants/expenses";

export const convertDataToPayloadStructure = (dataToConvert, type) => {
  switch (type) {
    case DATA_TO_CONVERT_TYPE.SPLIT: {
      const data = dataToConvert.map((itemdata) => {
        const tempData = {
          amount: itemdata.amount,
          accounting_payee_id: itemdata?.accountingPayeeId,
          line_item_tag_values_attributes: itemdata.lineItemTagValues
            .filter((_) => !!_.valueId)
            .map((lineItemValue) => {
              return {
                tag_id: lineItemValue.tagId,
                tag_value_id: lineItemValue.valueId,
              };
            }),
        };

        if (
          itemdata?.id &&
          typeof itemdata?.id === typeof "" &&
          itemdata?.id?.includes("split")
        ) {
          return tempData;
        }

        return {
          ...tempData,
          id: itemdata?.id,
        };
      });
      return { line_items: data };
    }
    default:
      break;
  }
  const data = dataToConvert.map((itemdata) => {
    const tempData = {
      amount: itemdata.amount,
      accounting_payee_id: itemdata?.accountingPayeeId,
      line_item_tag_values_attributes: itemdata.lineItemTagValues
        .filter((_) => !!_.valueId)
        .map((lineItemValue) => {
          return { tag_value_id: lineItemValue.valueId };
        }),
    };

    if (
      itemdata?.id &&
      typeof itemdata?.id === typeof "" &&
      itemdata?.id?.includes("split")
    ) {
      return tempData;
    }

    return {
      ...tempData,
      id: itemdata?.id,
    };
  });
  return { line_items: data };
};

export const getSplitExpensePayload = (splitExpense) => {
  return {
    line_items: splitExpense?.map((splitExpenseItem) => {
      const vendorAccountingPayeeId = splitExpenseItem.tags?.find(
        (tag) => tag?.id === VENDOR_ID
      );
      return {
        amount: splitExpenseItem.amount,
        ...(splitExpenseItem.beId ? { id: splitExpenseItem.beId } : {}),
        ...(vendorAccountingPayeeId?.valueToBE
          ? {
              accounting_payee_id:
                vendorAccountingPayeeId?.valueToBE === VALUE_VENDOR_ID
                  ? null
                  : vendorAccountingPayeeId?.valueToBE,
            }
          : {}),
        line_item_tag_values_attributes: splitExpenseItem.tags
          ?.filter((obj) => {
            if (obj?.id !== VENDOR_ID) {
              if (obj?.isText) return obj.textValueToBe;
              return obj.valueToBE;
            }

            return false;
          })
          ?.map((tag) => ({
            ...(tag.beId ? { id: tag.beId } : {}),
            tag_id: tag.id,
            ...(tag?.isText
              ? { custom_text_value: tag.textValueToBe }
              : { tag_value_id: tag.valueToBE }),
          })),
      };
    }),
  };
};
