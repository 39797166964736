import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAccountWallets } from "@/store/reducers/client";
import { setSelectedPaymentAccount } from "@/store/reducers/payments";

import {
  isFetchingAccountWalletSelector,
  isFetchingPayrollWalletSelector,
  payrollWalletAsArraySelector,
  walletListSelector,
} from "@/store/selectors/client";
import { onboardingMailDetailsSelector } from "@/store/selectors/vendors";

import Flag from "@/components/core/Flag";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import { MAIL_CONTEXT } from "@/utils/constants/common";

import { VENDOR_CREATE_REQUIREMENTS_PARAMS } from "@/constants/vendors";

export default function PaymentAccount({
  name = VENDOR_CREATE_REQUIREMENTS_PARAMS.SENDER_CURRENCY,
  values,
  errors,
  handleChange,
  disableForInrAndIdr,
  setValues,
  isEditMode,
  inPayrollContext,
  disabled,
  context,
}) {
  const dispatch = useDispatch();

  const paymentAccounts = useSelector(
    inPayrollContext ? payrollWalletAsArraySelector : walletListSelector
  );
  const isFetchingPaymentAccounts = useSelector(
    inPayrollContext
      ? isFetchingPayrollWalletSelector
      : isFetchingAccountWalletSelector
  );
  const onboardingMailDetailsAccounts =
    useSelector(onboardingMailDetailsSelector)?.accounts ?? [];

  useEffect(() => {
    if (context === MAIL_CONTEXT || !context) return;
    dispatch(fetchAccountWallets());
  }, [context]);

  useEffect(() => {
    dispatch(setSelectedPaymentAccount(values[name]));
  }, [values[name]]);

  return (
    <VpSelect
      name={name}
      value={
        onboardingMailDetailsAccounts?.[0]?.currency ??
        values[name] ??
        paymentAccounts?.[0]?.currency
      }
      error={errors[name]}
      handleChange={handleChange}
      insideForm
      menuPosition="absolute"
      options={
        context !== MAIL_CONTEXT
          ? paymentAccounts
          : onboardingMailDetailsAccounts
      }
      optionsDisplayKey="currency"
      valueKey="currency"
      isOptionsLoading={isFetchingPaymentAccounts}
      label="billPay.vendors.createVendor.paymentAccount"
      labelStyleClasses="text-neutral-600 text-xs font-semibold"
      placeholder="billPay.vendors.createVendor.selectPaymentAccount"
      customUIForOptionWhenDropdownClosed={customEntryWhenDropdownIsOpen}
      customUIForOptionWhenDropdownOpen={customEntryWhenDropdownIsOpen}
      clearable
      autoSelectIfSingleOption={disableForInrAndIdr}
      disabled={isEditMode || disableForInrAndIdr}
    />
  );
}

function customEntryWhenDropdownIsOpen(displayValue, option) {
  const { currency } = option;
  return (
    <div className="flex items-center gap-1">
      <Flag code={currency} size="sm" />
      <Text
        classes="text-base text-neutral-800 font-medium"
        translationKey="billPay.vendors.createVendor.CURaccount"
        translationProps={{ currency }}
      />
    </div>
  );
}

PaymentAccount.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  isEditMode: PropTypes.bool,
  disableForInrAndIdr: PropTypes.bool,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  context: PropTypes.string,
  setValues: PropTypes.func, // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough)
  inPayrollContext: PropTypes.bool,
};
