import React from "react";

import PaymentsApprovalsPageHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Approvals/PaymentsApprovalPageHelper";

import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";

export default function ActionCentrePayrollTab() {
  return (
    <PaymentsApprovalsPageHelper
      isActionCentrePage
      page={ACTION_CENTRE_PAGE_CONTEXT.PAYROLL}
      tabType="needs-your-approval"
    />
  );
}
