import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  expenseFlaggedPageFiltersSelector,
  expensesTotalSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  qrPaymentsFlaggedPageFiltersSelector,
  qrPaymentsTotalSelector,
} from "@/store/selectors/qr-payments";
import { userSelector } from "@/store/selectors/user";

import Text from "@/components/core/Text";

import Export from "@/components/Exports";
import FlaggedList from "@/components/common/QrPayAndExpense/common/FlaggedList";
import QrPayAndExpenseFilter from "@/components/common/QrPayAndExpense/common/QRPayAndExpenseFilter";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants/index";
import { convertFilters } from "@/utils/filters";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  EXPORT_FILTER_STATUS,
  EXPORT_PAGE_TYPE,
  EXPORT_TAB_TYPE,
} from "@/constants/exports";
import { FLAGGED_PATH_NAME } from "@/constants/routes";

export default function FlaggedPageHelper({
  page,
  filteringParams = {},
  filterSelectorFromParent,
}) {
  const expenseSelectors = {
    filters: useSelector(expenseFlaggedPageFiltersSelector),
    totalTransactionsSelected: useSelector(expensesTotalSelector),
    storeName: "expenses",
    searchParam: SLIDERS_SEARCH_PARAMS.expenses.id,
    pageSubTitle: "expenses.title",
  };
  const qrPaymentsSelectors = {
    filters: useSelector(qrPaymentsFlaggedPageFiltersSelector),
    totalTransactionsSelected: useSelector(qrPaymentsTotalSelector),
    storeName: "qrPayments",
    searchParam: SLIDERS_SEARCH_PARAMS.qrPayments.payments.id,
    pageSubTitle: "QRPay.payments.routeTitle",
  };
  const currentSelectors =
    page === QRPAY_EXPENSES_CONTEXT.EXPENSES
      ? expenseSelectors
      : qrPaymentsSelectors;
  const appliedFilters = useSelector(appliedFilterSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleTransaction = (id) => {
    searchParams.set(currentSelectors?.searchParam, id);
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <div>
        <Text
          translationKey="cards.title.plural"
          classes="text-neutral-500 text-sm font-semibold"
        />
        /
        <Text
          translationKey={currentSelectors?.pageSubTitle}
          classes="text-neutral-500 text-sm font-semibold"
        />
        <Text
          translationKey="expenses.flagged.title"
          classes="text-neutral-800 text-3xl font-bold block"
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="w-11/12 ">
          <QrPayAndExpenseFilter
            filters={
              filterSelectorFromParent
                ? filterSelectorFromParent
                : currentSelectors?.filters
            }
          />
        </div>
        <div className="flex justify-end ">
          <Export
            totalExports={currentSelectors?.totalTransactionsSelected}
            storeName={currentSelectors?.storeName}
            sectionName={currentSelectors?.storeName}
            additionalFilters={{
              ...convertFilters(appliedFilters), // this should be passed in selectedFilters below sectionName instead of additionalFilters
              ...EXPORT_FILTER_STATUS.EXPENSE.FLAGGED,
              ...EXPORT_PAGE_TYPE.EXPENSE,
              tab: EXPORT_TAB_TYPE.EXPENSE.FLAGGED,
              ...filteringParams,
            }}
          />
        </div>
      </div>
      <FlaggedList
        viewExpenseHandler={handleTransaction}
        page={page}
        filteringParams={filteringParams}
      />
    </div>
  );
}
FlaggedPageHelper.propTypes = {
  page: PropTypes.string,
  filteringParams: PropTypes.object,
  filterSelectorFromParent: PropTypes.array,
};
