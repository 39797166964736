import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchAndSelectReimbursement,
  resetEditAmountState,
  setEditAmountState,
} from "@/store/reducers/reimbursement";

import {
  claimAmountDependentValidationEnabledSelector,
  defaultCurrencySelector,
} from "@/store/selectors/client";
import {
  editAmountReasonSelector,
  editAmountSelector,
  selectedReimbursementSelector,
} from "@/store/selectors/reimbursement";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import ReimbursementApprovalSliderInfo from "@/components/Reimbursement/ApprovalSlider/ReimbursementSliderInfo";
import { useForm } from "@/utils/useForm";
import { REIMBURSEMENT_TYPE_LABEL } from "@/utils/constants/reimbursement";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function EditClaimAmountSlider({ searchParamKey, setOnClose }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const title = "reimbursement.approverEdit.editAmountTitle";
  const reimId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.reimbursements.editClaim
  );
  const selectedReimbursement = useSelector(selectedReimbursementSelector);
  const ref = useLeftHeaderTitle({ title });

  const defaultCurrency = useSelector(defaultCurrencySelector);
  const amountIncreaseValidationFlag = useSelector(
    claimAmountDependentValidationEnabledSelector
  );
  const editAmount = useSelector(editAmountSelector);
  const editAmountReason = useSelector(editAmountReasonSelector);
  const currentAmountValue =
    editAmount || selectedReimbursement?.amountToBePaid;

  const initialFormValue = {
    editAmount: {
      value: currentAmountValue,
      validate: {
        required: true,
        ...(amountIncreaseValidationFlag
          ? { maxNumber: selectedReimbursement?.amountToBePaid } // can only decrease from last edited amount
          : { maxNumber: selectedReimbursement?.amount?.value }), // can increase upto original amount
      },
    },
    editAmountReason: {
      value: editAmountReason || "",
      validate: {
        required: true,
      },
    },
  };

  useEffect(() => {
    setOnClose((searchParamArray) => {
      const isClosed =
        !searchParamArray?.includes(
          SLIDERS_SEARCH_PARAMS.reimbursements.create
        ) &&
        !searchParamArray?.includes(
          SLIDERS_SEARCH_PARAMS.reimbursements.editClaim
        );

      if (isClosed) {
        dispatch(resetEditAmountState());
      }
    });

    if (selectedReimbursement) return;

    dispatch(
      fetchAndSelectReimbursement({
        reimId,
        onSuccess: () => {
          dispatch(resetEditAmountState(null));
        },
      })
    );
  }, []);

  const onCancel = () => {
    searchParams.delete(searchParamKey);
    setSearchParams(searchParams);
  };
  const onSubmit = (formEvent, latestValues) => {
    dispatch(setEditAmountState(latestValues));
    onCancel();
  };

  const {
    handleChange,
    values,
    _setValues: setValues,
    errors,
    handleSubmit,
    isFormButtonDisabled,
  } = useForm(initialFormValue, onSubmit, {
    formId: "edit-claim-amount-form",
  });

  return (
    <>
      <div className="flex flex-col slider-content-core px-9 ">
        <div className="flex flex-col">
          <Text
            refProp={ref}
            classes="font-bold text-3xl"
            translationKey={title}
          />
          <Text
            classes="font-medium text-neutral-500 text-sm"
            translationKey="reimbursement.approverEdit.editAmountDesc"
          />

          <div className="mt-8">
            <ReimbursementApprovalSliderInfo
              createdBy={selectedReimbursement?.createdBy}
              type={REIMBURSEMENT_TYPE_LABEL[selectedReimbursement?.type]}
              linkedTo={selectedReimbursement?.linkedTo}
              totalAmount={selectedReimbursement?.claimAmount}
              totalAmountText="reimbursement.approverEdit.claimAmount"
              linkedToClasses="py-6"
              totalAmountClasses="py-6"
            />
          </div>

          <form
            onSubmit={handleSubmit}
            id="edit-claim-amount-form"
            className="mt-9"
          >
            <Input
              label="reimbursement.approverEdit.editAmountToBePaidLabel"
              labelExtraClasses="text-neutral-800 text-xs font-semibold"
              rightText={defaultCurrency}
              onChange={handleChange}
              name="editAmount"
              value={values?.editAmount}
              error={errors?.editAmount}
              type="number"
            />

            <div className="mt-9">
              <Text
                translationKey="reimbursement.approverEdit.reasonForAmountChangeTitle"
                classes="text-neutral-800 font-semibold text-lg block mb-6"
              />
              <Input
                label="reimbursement.approverEdit.commentLabel"
                labelExtraClasses="text-neutral-800 text-xs font-semibold"
                onChange={handleChange}
                name="editAmountReason"
                value={values?.editAmountReason}
                error={errors?.editAmountReason}
              />
            </div>
          </form>
        </div>
      </div>

      <div className="p-6 slider-footer flex justify-end gap-4">
        <Button
          label="misc.cancel"
          type="neutral"
          classes="w-fit px-8 py-3 font-medium"
          onClick={onCancel}
        />

        <Button
          label="misc.saveChanges"
          classes="w-16 px-5 py-3 text-white font-medium"
          variant="primary"
          disabled={isFormButtonDisabled}
          btnType="submit"
          form="edit-claim-amount-form"
        />
      </div>
    </>
  );
}

EditClaimAmountSlider.propTypes = {
  searchParamKey: PropTypes.string,
  setOnClose: PropTypes.func,
};

function createNavigationHandler(navigate, destination, queryObject) {
  return () =>
    navigate({
      pathname: destination,
      search: createSearchParams(queryObject).toString(),
    });
}
