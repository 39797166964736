import AmountsCell from "@/components/Reimbursement/cells/AmountCell";
import ApprovedAmount from "@/components/Reimbursement/cells/ApprovedAmountCell";
import LinkedToCell from "@/components/Reimbursement/cells/LinkedToCell";
import MemoCell from "@/components/Reimbursement/cells/MemoCell";
import ReportNameCell from "@/components/Reimbursement/cells/ReportNameCell";
import TotalClaimCell from "@/components/Reimbursement/cells/TotalClaimCell";
import ActionCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ActionCell";
import ApprovalProgressCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ApprovalProgressCell";
import PaymentStatusCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentStatusCell";
import SubmissionDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/SubmissionDateCell";
import {
  SORTING_CATEGORY,
  SORTING_CATEGORY_REPORT,
} from "@/utils/constants/sorting";

export const REIMBURSEMENT_REPORT_TABLE_HEADER = {
  drafts: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      numberOfStickyColsRight: 1,
      colWidths: [300, 200, 120, 200, 200, 300],
      rightColWidths: [200],
    },
    emptyDataTitle:
      "myVolopay.reimbursements.reports.drafts.tableHeaders.emptyDataTitle",
    emptySubHeadingDataTitle:
      "myVolopay.reimbursements.reports.drafts.tableHeaders.emptyDataDesc",
    tableHeading: [
      {
        id: "reportName",
        title:
          "myVolopay.reimbursements.reports.drafts.tableHeaders.reportName",
        classes: "text-left",
        cellComponent: ReportNameCell,
        showCheckbox: true,
      },
      {
        id: "amount",
        title: "myVolopay.reimbursements.reports.drafts.tableHeaders.amount",
        classes: "justify-end",
        cellComponent: AmountsCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "totalClaims",
        title:
          "myVolopay.reimbursements.reports.drafts.tableHeaders.totalClaims",
        classes: "text-left",
        cellComponent: TotalClaimCell,
      },
      {
        id: "linkedTo",
        title: "myVolopay.reimbursements.reports.drafts.tableHeaders.linkedTo",
        classes: "text-left",
        cellComponent: LinkedToCell,
      },
      {
        id: "submittedDate",
        title:
          "myVolopay.reimbursements.reports.drafts.tableHeaders.submittedDate",
        classes: "text-left",
        cellComponent: SubmissionDateCell,
      },
      {
        id: "description",
        title:
          "myVolopay.reimbursements.reports.drafts.tableHeaders.description",
        classes: "text-left ",
        cellComponent: MemoCell,
      },
      {
        id: "action",
        title:
          "myVolopay.reimbursements.reports.drafts.tableHeaders.actionText",
        classes: "text-center",
        cellComponent: ActionCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      " h-12  text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
  active: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 120, 200, 200, 200, 300],
    },
    emptyDataTitle:
      "myVolopay.reimbursements.reports.active.tableHeaders.emptyDataTitle",
    emptySubHeadingDataTitle:
      "myVolopay.reimbursements.reports.drafts.tableHeaders.emptyDataDesc",
    tableHeading: [
      {
        id: "reportName",
        title:
          "myVolopay.reimbursements.reports.active.tableHeaders.reportName",
        classes: "text-left",
        cellComponent: ReportNameCell,
        showCheckbox: true,
      },
      {
        id: "amount",
        title: "myVolopay.reimbursements.reports.active.tableHeaders.amount",
        classes: "justify-end",
        cellComponent: AmountsCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "totalClaims",
        title:
          "myVolopay.reimbursements.reports.active.tableHeaders.totalClaims",
        classes: "text-left",
        cellComponent: TotalClaimCell,
      },
      {
        id: "linkedTo",
        title: "myVolopay.reimbursements.reports.active.tableHeaders.linkedTo",
        classes: "text-left",
        cellComponent: LinkedToCell,
      },
      {
        id: "approval",
        title:
          "myVolopay.reimbursements.reports.active.tableHeaders.approvalStatus",
        classes: "text-right",
        cellComponent: ApprovalProgressCell,
      },
      {
        id: "submittedDate",
        title:
          "myVolopay.reimbursements.reports.active.tableHeaders.submittedDate",
        classes: "text-left",
        cellComponent: SubmissionDateCell,
      },
      {
        id: "description",
        title:
          "myVolopay.reimbursements.reports.active.tableHeaders.description",
        classes: "text-left ",
        cellComponent: MemoCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      " h-12  text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
  history: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 120, 200, 200, 200, 200, 300],
    },
    emptyDataTitle:
      "myVolopay.reimbursements.reports.history.tableHeaders.emptyDataTitle",
    emptySubHeadingDataTitle:
      "myVolopay.reimbursements.reports.history.tableHeaders.emptyDataDesc",
    tableHeading: [
      {
        id: "reportName",
        title:
          "myVolopay.reimbursements.reports.history.tableHeaders.reportName",
        classes: "text-left",
        cellComponent: ReportNameCell,
        showCheckbox: true,
      },
      {
        id: "amount",
        title: "myVolopay.reimbursements.reports.history.tableHeaders.amount",
        classes: "justify-end",
        cellComponent: AmountsCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REPORT.TOTAL_AMOUNT,
      },
      {
        id: "totalClaims",
        title:
          "myVolopay.reimbursements.reports.history.tableHeaders.totalClaims",
        classes: "text-left",
        cellComponent: TotalClaimCell,
      },
      {
        id: "approvedAmount",
        title:
          "myVolopay.reimbursements.reports.history.tableHeaders.approvedAmount",
        classes: "justify-end",
        cellComponent: ApprovedAmount,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REPORT.APPROVED_AMOUNT,
      },
      {
        id: "paymentStatus",
        title: "myVolopay.reimbursements.reports.history.tableHeaders.status",
        classes: "justify-center",
        cellComponent: PaymentStatusCell,
      },
      {
        id: "linkedTo",
        title: "myVolopay.reimbursements.reports.history.tableHeaders.linkedTo",
        classes: "text-left",
        cellComponent: LinkedToCell,
      },
      {
        id: "submittedDate",
        title:
          "myVolopay.reimbursements.reports.history.tableHeaders.submittedDate",
        classes: "text-left",
        cellComponent: SubmissionDateCell,
      },
      {
        id: "description",
        title:
          "myVolopay.reimbursements.reports.history.tableHeaders.description",
        classes: "text-left",
        cellComponent: MemoCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      " h-12  text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
};
