import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchBillingDetails,
  fetchTableData,
  fetchUpcomingInvoiceDetails,
  resetBillingDataState,
} from "@/store/reducers/company";

import {
  bannerDetailsSelector,
  billingFiltersSelector,
  billingPaymentDetailsSelector,
  billingPlanDetailsSelector,
  billingTableDataSelector,
  billingTableHasMoreSelector,
  upcomingInvoiceDetailsSelector,
} from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";

import Text from "@/components/core/Text";

import DownloadPopupButton from "@/components/common/DownloadPopupButton";

import BillingBannerDetails from "@/pages/billing/BillingBannerDetails";
import BillingDetailsTable from "@/pages/billing/BillingDetailsTable";
import BillingFilter from "@/pages/billing/BillingFilter";
import BillingInvoiceTable from "@/pages/billing/BillingInvoiceTable";
import InvoiceBox from "@/pages/billing/invoiceBox";
import { convertFilters } from "@/utils/filters";
import { SORT_KEYS } from "@/utils/constants/sorting";
import { BILLING_STATUS } from "@/utils/constants/company";
import { validateAndConvertToISO8601 } from "@/utils/common";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

const BillingPage = () => {
  const dispatch = useDispatch();
  const billingTableData = useSelector(billingTableDataSelector);
  const planDetails = useSelector(billingPlanDetailsSelector);
  const paymentDetails = useSelector(billingPaymentDetailsSelector);
  const upcomingDetails = useSelector(upcomingInvoiceDetailsSelector);
  const bannerDetails = useSelector(bannerDetailsSelector);
  const billingFilter = useSelector(billingFiltersSelector);
  const hasMore = useSelector(billingTableHasMoreSelector);

  const appliedFilters = useSelector(appliedFilterSelector);

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  const onReset = () => {
    dispatch(resetBillingDataState());
  };

  const loadMore = () => {
    dispatch(
      fetchTableData({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...convertFilters(appliedFilters),
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...appliedFilters,
      ...sorting,
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);
  const isEmpty = billingTableData?.length === 0;

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);

  const handleRowSelection = (invoice) => {
    if (selectedInvoices?.includes(invoice?.id)) {
      setSelectedInvoices((prev) => prev.filter((id) => id !== invoice?.id));
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, invoice?.id]);
      }
    } else {
      setSelectedInvoices((prev) => [...prev, invoice?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== invoice?.id));
    }
  };

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedInvoices(billingTableData.map((invoice) => invoice.id));
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedInvoices([]);
    }
  };

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);
      if (!deSelectedIds.length) {
        setSelectedInvoices(billingTableData?.map((data) => data.id));
      } else {
        const filteredIds = billingTableData
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedInvoices(filteredIds);
      }
    }
  }, [bulkSelectAll, billingTableData, deSelectedIds]);

  useEffect(() => {
    // When user selects checkbox from header

    setHeaderSelectAll(
      selectedInvoices?.length &&
        selectedInvoices.length === billingTableData?.length
    );
  }, [selectedInvoices, billingTableData, isEmpty]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchBillingDetails());
    dispatch(fetchUpcomingInvoiceDetails());
  }, []);

  const getPendingInvoiceDetails = (invoices) => {
    const generatedInvoices = invoices.filter(
      (invoice) => invoice.status === BILLING_STATUS.GENERATED
    );
    generatedInvoices.sort(
      (a, b) =>
        new Date(validateAndConvertToISO8601(a.dueDate)) -
        new Date(validateAndConvertToISO8601(b.dueDate))
    );
    const nearestDueDateInvoice = generatedInvoices[0];
    return nearestDueDateInvoice;
  };
  const openSliderHandler = (id) => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.billing.invoiceId, id);
    setSearchParams(searchParams);
  };

  return (
    <>
      {bannerDetails ? (
        <BillingBannerDetails bannerDetails={bannerDetails} />
      ) : null}

      <div className="flex flex-col px-8">
        <div className="flex flex-col mb-6 ">
          <Text classes="text-neutral-500" translationKey="company.title" />
          <Text
            classes="text-3xl font-bold text-neutral-800"
            translationKey="company.billing.title"
          />
        </div>
        <div className="flex items-center justify-between w-full gap-4">
          <InvoiceBox
            upcomingDetails={upcomingDetails}
            pendingDetails={getPendingInvoiceDetails(billingTableData)}
            openSliderHandler={openSliderHandler}
          />
        </div>
        <div className="flex items-start gap-4 mt-10 mb-3">
          <BillingDetailsTable planDetails={planDetails} showPlanDetails />
        </div>
        <div className="flex items-center gap-1 mb-6 text-base">
          <Text
            classes="font-medium text-sm text-neutral-800"
            translationKey="For queries related to plan change or your billing cycle please contact"
          />
          <Text
            classes="text-primary-500 cursor-pointer underline"
            translationKey="support@volopay.co"
          />
        </div>
        <BillingDetailsTable paymentDetails={paymentDetails} />
        <div className="flex flex-col mt-4">
          <Text
            classes="text-lg font-bold mt-6"
            translationKey="company.billing.invoicesTableHeaders.invoiceTitle"
          />

          <div className="flex justify-between">
            <BillingFilter filters={billingFilter} />
            {/* <DownloadPopupButton /> */}
          </div>

          <BillingInvoiceTable
            billingTableData={billingTableData}
            handleRowSelection={handleRowSelection}
            selectedInvoices={selectedInvoices}
            setBulkSelectAll={setBulkSelectAll}
            deSelectedIds={deSelectedIds}
            setDeSelectedIds={setDeSelectedIds}
            setHeaderSelectAll={setHeaderSelectAll}
            setSelectedInvoices={setSelectedInvoices}
            headerSelectAll={headerSelectAll}
            totalInvoicesSelected={billingTableData?.length}
            headerSelectionHandler={headerSelectionHandler}
            sorting={sorting}
            setSorting={setSorting}
            handleRefChange={handleRefChange}
            hasMore={hasMore}
          />
        </div>
      </div>
    </>
  );
};

export default BillingPage;
