import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import "@/store/reducers/accounting";

import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";
import Text from "@/components/core/Text";

import { useForm } from "@/utils/useForm";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import CSVFileUploadPreview from "./CSVFileUploadPreview";

export default function CustomFieldBulkUpload() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasFieldName, setHasFieldName] = useState(false);

  const initialValue = {
    file: {
      value: null,
      validate: {
        required: true,
      },
    },
  };

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    _setValues: setValues,
  } = useForm(initialValue, () => {
    handleContinue();
  });

  useEffect(() => {
    if (values?.fieldName?.trim() !== "") setHasFieldName(true);
    else setHasFieldName(false);
  }, [values]);

  const handleCancel = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.settings.bulkUpload);
    setSearchParams(searchParams);
  };

  const handleDownloadTemplate = () => {
    // window.open(INTEGRATION_UCSV_CHART_OF_ACCOUNGTS_TEMPLATE_URL, "_blank");
  };

  const handleContinue = () => {
    console.log("saved");
  };
  console.log("files", values);
  return (
    <div className="slider-content-container flex flex-col gap-11.5">
      <div className="flex flex-col gap-8 px-10 slider-core-container ">
        <div className="flex flex-col">
          <Text
            translationKey="accounting.settings.accountingRule.customRuleSlider.title"
            classes="text-neutral-800 text-3xl font-bold"
          />
          <Text
            classes="text-neutral-500 font-medium text-sm "
            translationKey="accounting.settings.accountingRule.customRuleSlider.desc"
          />
        </div>
        <div className="flex flex-col">
          <Text
            translationKey="accounting.settings.accountingRule.customRuleSlider.downloadTemplate"
            classes="text-lg font-semibold text-neutral-800"
          />
          <Text
            classes="text-neutral-500 font-medium text-sm "
            translationKey="accounting.settings.accountingRule.customRuleSlider.desc"
          />
          <form
            onSubmit={handleSubmit}
            id="ucsv-create-field-form"
            className="flex flex-col gap-9 "
          >
            <Button
              variant="tertiary"
              label="misc.download"
              preIcon="Download"
              classes="w-1/3 px-5 py-1 text-xs font-bold mt-4"
              disabled={false}
              onClick={() => {
                handleDownloadTemplate();
              }}
            />

            <div className="flex flex-col justify-center">
              <Text
                translationKey="accounting.settings.accountingRule.customRuleSlider.upload"
                classes="text-lg font-semibold text-neutral-800"
              />

              <Text
                classes="text-neutral-500 font-medium text-sm "
                translationKey="accounting.settings.accountingRule.customRuleSlider.uploadDesc"
              />
              <div className="mt-4">
                <FileUpload
                  name="file"
                  files={values.file || []}
                  accept={{ csv: [".csv"] }}
                  handleFileChange={handleChange}
                  acceptText="common.csvFormatFileUploadAccept"
                  primaryAction={{
                    handler: () => {
                      setValues((prev) => ({ ...prev, file: [] }));
                    },
                    label: "misc.delete",
                    icon: "Delete",
                    iconClasses: "text-danger-600 bg-danger-50",
                  }}
                  previewCustomComponent={(props) => (
                    <CSVFileUploadPreview
                      file={props.file}
                      primaryAction={props.primaryAction}
                    />
                  )}
                  maxFiles={1}
                  hideCustomButtonAfterUpload
                  insideForm
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="sticky px-6 py-4 slider-footer">
        <div className="flex items-center justify-end gap-6 text-base font-semibold">
          <Button
            label="misc.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            onClick={() => handleCancel()}
          />
          <Button
            label="misc.save"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            btnType="submit"
            disabled={values?.file?.length === 0 || values?.file === null}
            form="ucsv-create-field-form"
          />
        </div>
      </div>
    </div>
  );
}
