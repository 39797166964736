import { useSelector } from "react-redux";

import { analyticsTagsSelector } from "@/store/selectors/analytics";

import Text from "@/components/core/Text";

import AnalyticsCardsHelper from "@/components/Analytics/Transactions/Cards";

import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";

export default function AnalyticsCards() {
  const analyticsTags = useSelector(analyticsTagsSelector);

  return (
    <div>
      <div className="flex flex-col mx-12 mb-3">
        <Text
          classes="text-neutral-500 text-sm font-semibold"
          translationKey="analytics.header"
        />
        <Text
          classes="text-neutral-800 text-4xl font-extrabold"
          translationKey="analytics.transactions.cards.title"
        />
      </div>

      {analyticsTags ? (
        <AnalyticsCardsHelper type={ACCOUNTING_TRANSACTION_PAGES.EXPENSES} />
      ) : null}
    </div>
  );
}
