import PropTypes, { bool } from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchSelectedExpenseActivity } from "@/store/reducers/expense";

import { selectedExpenseActivitySelector } from "@/store/selectors/expense";

import Avatar from "@/components/core/Avatar";
import Icon from "@/components/core/Icon";

import {
  EXPENSE_ACTIVITY_CLASS,
  EXPENSE_ACTIVITY_TYPES_TAGS,
} from "@/constants/expense";

export default function ExpenseAndQRPayHistory({ expenseId, isFetching }) {
  const dispatch = useDispatch();
  const expenseActivity = useSelector(selectedExpenseActivitySelector);
  useEffect(() => {
    dispatch(fetchSelectedExpenseActivity({ expenseId }));
  }, [expenseId]);

  return (
    <div className="mb-14">
      {!isFetching && expenseActivity
        ? expenseActivity.map((val, key) => (
            <div key={key}>
              <div className="border-b-[1px] py-3 px-4 border-neutral-200 font-semibold text-neutral-800">
                {val.createdAt}
              </div>
              <div className="z-0 py-4 relative after:absolute after:top-0 after:left-[23px] after:bottom-0 after:border-l-[1.5px] after:border-neutral-200">
                {val.items.map((item, itemKey) => (
                  <div
                    className="flex w-full mb-5"
                    key={`activity-item-${itemKey}`}
                  >
                    <div className="z-10 pr-4 opacity-100">
                      <div className="bg-white">
                        <Avatar name={item.user.userName.fullName} size="lg" />
                      </div>
                    </div>
                    <div className="flex-col w-full">
                      <div className="text-sm font-semibold text-neutral-500">
                        {item.user.time}
                      </div>
                      <div className="mb-3 font-semibold text-neutral-800">
                        {item.user.userName.fullName}
                      </div>
                      {item.activities.map((actions, actionKey) => (
                        <div
                          className={`mb-3 font-medium text-neutral-500  ${
                            EXPENSE_ACTIVITY_CLASS[actions.type]
                          }`}
                          key={`activity-action-${actionKey}`}
                        >
                          <div className="flex justify-between">
                            <div className="flex">
                              {actions.icon && (
                                <Icon
                                  name={actions.icon}
                                  className={`h-7 w-7 pb-1 pr-3 ${
                                    EXPENSE_ACTIVITY_TYPES_TAGS[actions.type]
                                  }`}
                                />
                              )}
                              <div>
                                {EXPENSE_ACTIVITY_TYPES_TAGS[actions.type]}
                                {actions.description && (
                                  <div className="mt-1 text-neutral-500">
                                    {actions.description}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="text-neutral-500 min-w-[64px]">
                              1:20 PM
                            </div>
                            {
                              // actions.time
                            }
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        : "loading...."}
    </div>
  );
}
ExpenseAndQRPayHistory.propTypes = {
  isFetching: PropTypes.bool,

  expenseId: PropTypes.number,
};
