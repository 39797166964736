import { ROUTES } from "@/constants/routes";

/**
 * Retrieves redirection url using search param `redirect`
 * @returns {String}
 */
export const getRedirectUrl = () => {
  const params = new URL(window.location).searchParams;
  const redirectParamValue = params.get("redirect");

  const url = decodeURIComponent(redirectParamValue ? redirectParamValue : "");

  if (url) {
    return url;
  }

  return "/";
};

/**
 * Append redirect url in given `uri`
 * @param {*} uri
 * @returns {String}
 */
export const getUrlWithRedirectUri = (uri) => {
  const redirectUrl = getRedirectUrl();

  const isOauthPageAsRedirect = redirectUrl?.startsWith(
    ROUTES.oauthLogin.base.absolutePath
  );
  if (redirectUrl !== "/" && !isOauthPageAsRedirect) {
    uri += `?redirect=${encodeURIComponent(redirectUrl)}`;
  }
  return uri;
};
