import PropsTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import usePagination from "@/hooks/usePagination";

import {
  fetchPurchaseBills,
  INVOICE_TABLE_SLICE_ATTRIBUTE_KEY,
  setPurchaseBillsIntialState,
} from "@/store/reducers/purchase-bills";
import { appliedFilterSelector } from "@/store/selectors/filters";

import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Table from "@/components/core/Table";

import PaymentsTableLoader from "@/components/Reimbursement/PaymentsTableLoader";
import AmountCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/AmountCell";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import MonthAndYearCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/MonthAndYearCell";
import PaymentDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentDateCell";
import PaymentStatusCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentStatusCell";
import { convertFilters } from "@/utils/filters";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { openRelevantPaymentSlider } from "@/utils/constants/payments";

import {
  INVOICE_SLIDER_TABLE_LIMIT,
  QUERY_PARAMS,
} from "@/constants/pagination";

/**
 * @param {{ key: Number, name: String, count: null }} tab for core/Tabs
 */

const EMPLOYEE_DETAILS_TABLE_DEFAULT_HEADERS = {
  tableSetting: {
    colWidths: [200, 120, 151, 200],
  },
  tableHeading: [
    {
      id: "paymentMonthHeading",
      title:
        "payroll.salaryPayment.payments.employeeDetails.accordian.employeeTable.paymentMonthAndYearHeading",
      classes: "text-left",
      cellComponent: MonthAndYearCell,
    },
    {
      id: "paymentDateHeading",
      title:
        "payroll.salaryPayment.payments.employeeDetails.accordian.employeeTable.paymentDateHeading",
      classes: "text-left",
      cellComponent: PaymentDateCell,
    },
    {
      id: "amountHeading",
      title:
        "payroll.salaryPayment.payments.employeeDetails.accordian.employeeTable.amountHeading",
      classes: "text-right justify-end",
      cellComponent: AmountCell,
    },
    {
      id: "statusHeading",
      title:
        "payroll.salaryPayment.payments.employeeDetails.accordian.employeeTable.statusHeading",
      classes: "justify-center",
      cellComponent: PaymentStatusCell,
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
  tableDataRowClasses:
    "text-sm font-semibold text-center border-y-2 border-neutral-100",
};

/**
 * Used inside Employee slider. Is NOT a listing page.
 */
export default function EmployeeDetailsTable({ paymentId, vendorId, context }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // explicit pagination
  const [currentPage, setCurrentPage] = useState(1); // visible in UI
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

  // const isFetching = useSelector(employeeSliderIsPaymentsFetchingSelector);
  // const hasMore = useSelector(employeeSliderPaymentsHasMoreSelector);
  const appliedFilters = useSelector(appliedFilterSelector);

  const [sorting, setSorting] = useState({
    type: null,
    category: SORTING_CATEGORY.AMOUNT,
  });

  const listObj = useSelector(
    (store) => store.purchaseBills[INVOICE_TABLE_SLICE_ATTRIBUTE_KEY]
  );
  const initialPaginationParams = {
    list: [],
    page: 1,
    limit: INVOICE_SLIDER_TABLE_LIMIT,
    total: 0,
    isFetching: false,
    hasMore: true,
  };

  const { list, page, limit, total, isFetching, hasMore } =
    listObj || initialPaginationParams;

  const isEmpty = !list.length;

  // data fetching
  const filterOptions = {
    [QUERY_PARAMS.SORT_COLUMNS.KEY_NAME]:
      QUERY_PARAMS.SORT_COLUMNS.VALUES.CREATED_AT,
    [QUERY_PARAMS.SORT_DIRECTION.KEY_NAME]:
      QUERY_PARAMS.SORT_DIRECTION.VALUES.DESC,
  };

  const rows =
    list?.slice(
      INVOICE_SLIDER_TABLE_LIMIT * (currentPage - 1),
      INVOICE_SLIDER_TABLE_LIMIT * currentPage
    ) ?? [];

  const onReset = () => {
    dispatch(
      setPurchaseBillsIntialState({
        key: INVOICE_TABLE_SLICE_ATTRIBUTE_KEY,
        value: initialPaginationParams,
      })
    );

    setCurrentPage(1);
  };

  const loadMore = () => {
    dispatch(
      fetchPurchaseBills({
        key: INVOICE_TABLE_SLICE_ATTRIBUTE_KEY,
        value: {
          page: pageNum,
          limit: INVOICE_SLIDER_TABLE_LIMIT,
          ...(paymentId ? { except_ids: [paymentId] } : {}),
          ...filterOptions,
        },
        context,
        listingParams: {
          vendor_id: vendorId,
          reviewed: true,
        },
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...convertFilters(appliedFilters),
      ...sorting,
    },
  });

  // cleanup arbitrary key
  useEffect(() => {
    return () => {
      dispatch(
        setPurchaseBillsIntialState({
          key: INVOICE_TABLE_SLICE_ATTRIBUTE_KEY,
          value: null,
        })
      );
    };
  }, []);

  // Note (to avoid confusion)
  // 1. currentPage - for UI navigation, not related to pagination. Basically - where are you, in the UI
  // 2. pageNum - used for triggering fetch call. Simply - what page is being downloaded, or was downloaded last
  // 3. page - downloaded pages, a store state. Simply - how many pages have been downloaded

  // pagination actions
  const prevDisabled = currentPage === 1;
  const nextDisabled = !hasMore && currentPage === page;

  const prevPageHandler = () => {
    setCurrentPage((p) => (p > 1 ? p - 1 : p));
  };

  const nextPageHandler = () => {
    if (currentPage === page) {
      // on the last loaded page
      if (hasMore) {
        setCurrentPage((p) => p + 1); // show loader page
        setPageNum((p) => p + 1); // trigger pagination to fetch more
      }
    } else if (currentPage < page) {
      setCurrentPage((p) => p + 1); // move navigation forward, no fetch
    }
    // currentPage > page case is absent, since button is disabled
  };

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
  } = EMPLOYEE_DETAILS_TABLE_DEFAULT_HEADERS;

  return (
    <div className="mt-6">
      <Table {...tableSetting}>
        <tr className={tableHeadingRowClasses}>
          {tableHeading.map((headVal, index) => (
            <th className={headVal.classes} key={headVal.id}>
              <HeaderCell
                val={{
                  ...headVal,
                  showCheckbox: false,
                  sorting,
                  setSorting,
                }}
              />
            </th>
          ))}
        </tr>
        {(!isFetching || !isEmpty) &&
          rows.map((val, index, arr) => (
            <tr
              key={val.id}
              className={`${tableDataRowClasses} ${
                val.selected ? "selected-row-cell" : ""
              }`}
              onClick={() => {
                openRelevantPaymentSlider(val, inPayrollContext, {
                  searchParams,
                  setSearchParams,
                });
              }}
            >
              {tableHeading.map(({ cellComponent: Component, classes, id }) => (
                <td className={classes} key={id}>
                  <Component
                    val={{
                      ...val,
                      context: BILL_PAYROLL_CONTEXT.PAYROLL,
                    }}
                    key={id}
                  />
                </td>
              ))}
            </tr>
          ))}
        {isFetching ? (
          <PaymentsTableLoader count={INVOICE_SLIDER_TABLE_LIMIT} />
        ) : null}
      </Table>

      {isEmpty ? null : (
        <StepPaginationButtons
          currentPage={currentPage}
          limit={INVOICE_SLIDER_TABLE_LIMIT}
          total={total}
          handleLeftButton={prevPageHandler}
          handleRightButton={nextPageHandler}
          leftDisabled={prevDisabled}
          rightDisabled={nextDisabled}
        />
      )}
    </div>
  );
}

EmployeeDetailsTable.propTypes = {
  paymentId: PropsTypes.number,
  context: PropsTypes.string,
  vendorId: PropsTypes.number,
};
