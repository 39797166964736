import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import NoRule from "@/components/Accounting/Rules/Slider/NoRule";
import { MAPPING_TYPE } from "@/utils/constants/rules";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { RULE_TYPES } from "@/constants/rules";

import RuleCard from "../RuleCard";
import RulesListLoader from "../RulesDetails/RulesListLoader";

export default function AdvancedRulesDetails({ rules, isFetching }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const openNewAdvancedRule = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.rules.newEditAdvancedRule,
      MAPPING_TYPE.NEW_RULE
    );
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col mb-6 ">
      {!rules?.length && !isFetching ? (
        <NoRule
          type={RULE_TYPES.ADVANCED}
          createNewRule={openNewAdvancedRule}
        />
      ) : (
        <div className="flex flex-col gap-6 mt-8 rules-cards">
          {rules.map((rule) => {
            return (
              <RuleCard key={rule.id} rule={rule} type={RULE_TYPES.ADVANCED} />
            );
          })}
          {/* todo: use different loader for advanced rules */}
          {isFetching && !rules?.length ? <RulesListLoader /> : null}
        </div>
      )}
    </div>
  );
}

AdvancedRulesDetails.propTypes = {
  rules: PropTypes.array,
  isFetching: PropTypes.bool,
};
