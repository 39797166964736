import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { currentPaymentProviderSelector } from "@/store/selectors/client";
import { userDepartmentIdSelector } from "@/store/selectors/user";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import { paymentAccountDropdownOptionSelected } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/paymentAccountDropdownOption";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { PAY_OUTSIDE_VOLOPAY_CURRENCY } from "@/utils/constants/common";

import { PAYMENT_PROVIDERS } from "@/constants/provider";
import { VENDOR_LINKED_TO_TYPES } from "@/constants/vendors";

/**
 *
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=3974-221816&mode=dev}
 * outside volopay {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=4009-227804&mode=dev}
 * payroll (see below this) {@link https://www.figma.com/file/nYb1B3o06dNJPuFhW7fLWP/v2-Payroll?type=design&node-id=3490-256954&mode=design&t=zvZOgDFzWghB6ZDW-4}
 */
export default function WalletDetails({
  payment,
  context,
  senderCurrency,
  isPayOutsideVolopay,
  hideLinkedTo = false,
}) {
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const payOutsideVolopaySelectOption = {
    currency: PAY_OUTSIDE_VOLOPAY_CURRENCY,
    // For now (dropdown select), need valid value here to avoid Flag breakage in other sections on dropdown
    specialLabel:
      "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.payoutSideVolopay.title",
    specialDescription:
      "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.payoutSideVolopay.description",
    specialIcon: "PayOutsideVolopay",
  };

  const currentProvider = useSelector(currentPaymentProviderSelector);
  const isHidePaymentMethodDropdown =
    currentProvider === PAYMENT_PROVIDERS.XENDIT; // not relevant for Xendit (ID)

  const isLinkedToDepartment =
    payment?.linkedTo?.type === VENDOR_LINKED_TO_TYPES.DEPARTMENT;

  const name = payment?.linkedTo?.name;

  return (
    <div>
      <Text
        classes="payment-heading"
        translationKey="billPay.bill.payments.paymentDetails.heading"
      />
      <div className="mt-4 card-wrapper">
        {isPayOutsideVolopay
          ? paymentAccountDropdownOptionSelected(
              PAY_OUTSIDE_VOLOPAY_CURRENCY,
              payOutsideVolopaySelectOption
            )
          : paymentAccountDropdownOptionSelected(senderCurrency, {
              balance: payment?.account?.balance,
              ...(inPayrollContext
                ? {
                    specialLabel:
                      "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentDetails.payrollAccount",
                  }
                : {}),
            })}
      </div>

      {isPayOutsideVolopay || isHidePaymentMethodDropdown ? null : (
        <div className="mt-9">
          <Input
            value={
              payment?.paymentMethod?.name || payment?.quote?.paymentMethod
            } // OR here covers both pre-approved (purchase bill, payroll) and payment APIs
            label="billPay.vendors.createVendor.paymentMethod"
            placeholder="billPay.vendors.createVendor.selectPaymentMethod"
            labelExtraClasses="font-semibold text-xs"
            disabled
          />
        </div>
      )}
    </div>
  );
}

WalletDetails.propTypes = {
  senderCurrency: PropTypes.string,
  payment: PropTypes.object,
  context: PropTypes.string,
  isPayOutsideVolopay: PropTypes.bool,
  hideLinkedTo: PropTypes.bool,
};
