import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getMerchantRestrictions,
  getMerchantShallow,
} from "@/store/reducers/cards";
import { fetchCategories } from "@/store/reducers/categories";

import {
  isFetchingMerchantRestrictionsSelector,
  merchantRestrictionsInfoSelector,
  newCardRequestDetailsSelector,
  shallowMerchantListSelector,
} from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import {
  formatLinkedMccRestrictionsApiResponse,
  getMccListForPreview,
} from "@/components/Cards/Sliders/CardMerchantCategoryControls/util";
import CategoryPointersSection from "@/components/Cards/common/CategoryPointersSection";
import EntityListViewLoader from "@/components/common/EntityListView/EntityListViewLoader";

import { CARD_REQUEST_TYPE } from "@/constants/ActionCentre";

function MerchantAndCategoryControls() {
  const dispatch = useDispatch();
  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);
  const categoryList = useSelector(categoriesListSelector);
  const merchantList = useSelector(shallowMerchantListSelector);
  const cardRequestId = cardRequestDetails?.id;
  const isFetchingLinkedMerchantRestrictionsInfo = useSelector(
    isFetchingMerchantRestrictionsSelector
  );

  const currentLinkedMccRestrictions = useSelector(
    merchantRestrictionsInfoSelector
  );

  const { categoryConfig = null, list = null } = useMemo(() => {
    const formatedResponse = formatLinkedMccRestrictionsApiResponse(
      currentLinkedMccRestrictions
    );

    return getMccListForPreview(
      formatedResponse,
      categoryList,
      merchantList
    )?.[0];
  }, [currentLinkedMccRestrictions]);

  const { iconConfig = null, textConfig = null } = categoryConfig;

  useEffect(() => {
    fetchMerchantRestrictions();
    dispatch(fetchCategories());
    dispatch(getMerchantShallow());
  }, []);

  function fetchMerchantRestrictions() {
    if (cardRequestId) {
      dispatch(
        getMerchantRestrictions({
          params: {
            owner_type: CARD_REQUEST_TYPE.CARD_REQUEST,
            owner_id: cardRequestId,
          },
        })
      );
    }
  }

  return !isFetchingLinkedMerchantRestrictionsInfo ? (
    <CategoryPointersSection
      categoryMainHeading="myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.merchantAndCategoryControls.sectionHeading"
      categoryDescription="myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.merchantAndCategoryControls.sectionDescription"
      count={list?.length}
      showPointersSection={list?.length > 0}
    >
      {textConfig?.text ? (
        <div className="flex flex-row gap-2">
          <Icon name={iconConfig?.icon} className={iconConfig?.classes} />
          <Text
            translationKey={textConfig?.text}
            classes={textConfig?.classes}
          />
        </div>
      ) : null}

      <div className="flex flex-col justify-center gap-4 p-3 rounded-lg bg-neutral-50">
        {list?.map((categoryControl, index) => {
          return (
            <div key={index} className="flex flex-row items-center gap-4">
              <Icon
                name={categoryControl?.preIconConfig?.icon}
                className={categoryControl?.preIconConfig?.iconClasses}
              />
              <Text translationKey={categoryControl?.leftText} />
            </div>
          );
        })}
      </div>
    </CategoryPointersSection>
  ) : (
    <EntityListViewLoader />
  );
}

export default MerchantAndCategoryControls;
