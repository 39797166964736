import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import API from "@/api";

const initialState = {
  currentPageNumber: 0,
  totalPageFiles: 1,
  filesUrl: null,
  isFetchingFilesUrl: false,
  defaultZoom: 1,
};
const fileViewer = createSlice({
  name: "fileViewer",
  initialState,
  reducers: {
    setCurrentPageNumber(state, action) {
      state.currentPageNumber = action.payload;
    },
    setTotalPageFiles(state, action) {
      state.totalPageFiles = action.payload;
    },
    moveToNextPage(state) {
      state.currentPageNumber =
        state.currentPageNumber >= state.totalPageFiles
          ? initialState.currentPageNumber
          : state.currentPageNumber + 1;
    },
    moveToPrevPage(state) {
      state.currentPageNumber =
        state.currentPageNumber <= initialState.currentPageNumber
          ? state.totalPageFiles
          : state.currentPageNumber - 1;
    },
    setFilesUrl(state, action) {
      state.filesUrl = action.payload;
    },
    setIsFetchingFilesUrl(state, action) {
      state.isFetchingFilesUrl = action.payload;
    },
    setDefaultZoom(state, action) {
      state.defaultZoom = action.payload;
    },
    resetFileViewerStore(state) {
      return initialState;
    },
  },
});
export const fetchAttachementUrls = createAsyncThunk(
  "fileViewer/fetchAttachementUrls",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingFilesUrl(true));
    const [error, response] = await to(API.Misc.getAttachementsUrls(params));
    if (response?.data) {
      dispatch(setFilesUrl(response?.data));
      dispatch(setTotalPageFiles(response?.data?.length));
      dispatch(setCurrentPageNumber(0));
    } else if (error) {
      dispatch(setFilesUrl([]));
      dispatch(setTotalPageFiles(0));
      dispatch(setCurrentPageNumber(0));
    }
    dispatch(setIsFetchingFilesUrl(false));
  }
);

export const {
  setCurrentPageNumber,
  setFilesUrl,
  setTotalPageFiles,
  moveToPrevPage,
  moveToNextPage,
  setIsFetchingFilesUrl,
  setDefaultZoom,
  resetFileViewerStore,
} = fileViewer.actions;

export default fileViewer.reducer;
