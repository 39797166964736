import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setIndexApiReload } from "@/store/reducers/app";
import {
  fetchAndSelectExpense,
  requestRepayment,
} from "@/store/reducers/expense";

import { indexApiReloadSelector } from "@/store/selectors/app";
import { categoriesListSelector } from "@/store/selectors/categories";
import { defaultCurrencySelector } from "@/store/selectors/client";
import {
  isFlagExpenseInProcessSelector,
  selectedExpenseSelector,
} from "@/store/selectors/expense";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import ExpenseSliderOverviewCards from "@/components/Expenses/ExpenseSliders/ExpenseSlider/ExpenseSliderOverviewCards";
import { ExpenseAndQRPayHeaderJsx } from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayHeader";
import FlagExpenseFooter from "@/components/common/QrPayAndExpense/common/Sliders/FlagExpenseAndQRPay/FlagExpenseAndQRPayFooter";
import FlagExpenseHeader from "@/components/common/QrPayAndExpense/common/Sliders/FlagExpenseAndQRPay/FlagExpenseAndQRPayHeader";
import { useForm } from "@/utils/useForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  EXPENSE_FLAG_REPAYMENT_SLIDER,
  FLAG_EXPENSE_CTA_KEYS,
} from "@/constants/expense";

export default function RequestRepayment({ isAccountingSlider, sliderType }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expenseId, setExpenseId] = useState(null);
  const dispatch = useDispatch();
  const expense = useSelector(selectedExpenseSelector);
  const categories = useSelector(categoriesListSelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const isFlagInProcess = useSelector(isFlagExpenseInProcessSelector);
  const reloadIndexApi = useSelector(indexApiReloadSelector);
  const [submitKey, setSubmitKey] = useState("");
  const initialValue = {
    repaymentAmount: {
      value: "",
      validate: {
        required: true,
        number: true,
      },
    },
  };

  const removeSearchParams = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.expenses.requestRepayment);
    setSearchParams(searchParams);
  };
  const onSubmitForm = (value, reset) => {
    const params = {
      expenseId,
      payload: {
        amount: value?.repaymentAmount,
      },
    };
    dispatch(requestRepayment({ params, reset }));
  };
  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, (e, value, reset) =>
      onSubmitForm(value, () => {
        dispatch(setIndexApiReload(!reloadIndexApi));
        removeSearchParams();
      })
    );

  const handleCtaClick = (ctaKey) => {
    if (ctaKey === FLAG_EXPENSE_CTA_KEYS.CANCEL_FLAG) {
      removeSearchParams();
    }
  };

  // use Effect
  useEffect(() => {
    const id = parseInt(
      searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.requestRepayment),
      10
    );
    if (id) setExpenseId(id);
  });

  useEffect(() => {
    if (expenseId && !expense) dispatch(fetchAndSelectExpense({ expenseId }));
  }, [expenseId, expense]);

  return expense ? (
    <div className="slider-content-container">
      <div className="px-9 pb-15 slider-content-core ">
        <FlagExpenseHeader
          title={EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.title}
          description={EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.description}
        />
        <ExpenseAndQRPayHeaderJsx
          transactionAmount={expense?.transactionAmount}
          transactionCurrencyCode={expense?.transactionCurrencyCode}
          settlementStatus={expense?.settlementStatus}
          amount={expense?.amount}
          currency={expense?.currency}
          transactionStatus={expense?.transactionStatus}
          updatedAt={expense?.expenseDate}
          merchantName={expense?.globalMerchant}
        />
        <ExpenseSliderOverviewCards />
        <form id="flag-expense-form" className="mt-13" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-8 pt-5">
            <Text
              classes="text-xl font-semibold text-neutral-800 "
              translationKey={
                EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.secondaryHeading
              }
            />
          </div>
          <div className="flex flex-col gap-3 pt-5">
            <Input
              name="repaymentAmount"
              value={values?.repaymentAmount}
              error={errors?.repaymentAmount}
              type="number"
              rightText={defaultCurrency}
              onChange={handleChange}
              label="expenses.flagTransaction.repaymentAmount"
              placeholder="expenses.flagTransaction.repaymentAmount"
            />
          </div>
        </form>
      </div>
      <div className="slider-footer">
        <FlagExpenseFooter
          onClick={handleCtaClick}
          type={sliderType}
          form="flag-expense-form"
          toggleSwitchTrue={
            EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.isSwitchThere
          }
          isSubmitButtonDisabled={isFormButtonDisabled}
          requestRepaymentTrue={values?.isRepaymentRequested}
        />
      </div>
    </div>
  ) : (
    "loading"
  );
}
RequestRepayment.propTypes = {
  isAccountingSlider: PropTypes.bool,
  sliderType: PropTypes.string,
};
