import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import { setPeople } from "@/store/reducers/company";
import {
  dismissSubmissionPolicy,
  fetchUsersWithMissingDetails,
  remindExpense,
  resetMissingDetailsListStoreState,
  resetSliderExpensesListAndPagination,
  retrieveExpenseSliderTransactionList,
  setExpandStatusIndexIndex,
  setUserRemindStatus,
} from "@/store/reducers/expense";
import {
  setAppliedFilters,
  setSearchAndFilterCommonStoreStructure,
} from "@/store/reducers/filters";

import {
  expandStatusIndexSelector,
  expensesSliderListIsFetchingSelector,
  expensesSliderSelector,
  hasMoreMissingDetailsUsersSelector,
  isFetchingMissingDetailsUsersSelector,
  missingDetailsListSelector,
  missingDetailsUserIdsSelector,
} from "@/store/selectors/expense";
import { selectedUserSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { generateRedirectionUrlForFilters } from "@/utils/constants/searchAndFilter";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import { dateToString } from "@/utils/common";

import { SUBMISSION_POLICY_STATUSES } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  INITIAL_PAGE_NUM,
  PAGINATION_PER_REQUEST_LIMIT,
  PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
} from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import { USER_STATUS } from "@/constants/user";
import { API_KEYS_MAPPING } from "@/api/apiKeys";

import MissingDetailLoader from "./Loader";
import MissingDetailsItemRow from "./MissingDetailsItemRow";

function MissingDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationObject = useLocation();
  const pagePathName = locationObject?.pathname;
  const selectedUser = useSelector(selectedUserSelector);
  const missingDetailsUserIds = useSelector(missingDetailsUserIdsSelector);
  const peopleList = useSelector(missingDetailsListSelector);
  const titleRef = useLeftHeaderTitle({
    title: "expenses.overview.missingDetails",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const isUserMissingDetails = Object.keys(selectedUser)?.length > 0;
  const [selectedMissingDetails, setSelectedMissingDetails] = useState([]);
  const hasMore = useSelector(hasMoreMissingDetailsUsersSelector);
  const isFetching = useSelector(isFetchingMissingDetailsUsersSelector);
  const expandStatusIndex = useSelector(expandStatusIndexSelector);
  const sliderExpenseListInfo = useSelector(expensesSliderSelector);
  const sliderExpenseList = sliderExpenseListInfo?.list ?? [];
  const sliderExpensesTotalCount = sliderExpenseListInfo?.total;
  const isFetchingExpensesForSelectedUser = useSelector(
    expensesSliderListIsFetchingSelector
  );

  const cardState = useLoadingErrorInjector({
    apiKey: API_KEYS_MAPPING.USER_ALL,
    showLoader: false,
    error: {
      header: "",
    },
  });

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
  });

  function loadMore() {
    dispatch(
      fetchUsersWithMissingDetails({
        order_by_name: true,
        status: [USER_STATUS.ACTIVE],
        missing_detail_users: true,
        ...(isUserMissingDetails ? { user: [selectedUser?.id] } : {}),
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
      })
    );
  }

  function handleLoadMoreButton() {
    setPageNum(pageNum + 1);
  }

  function onReset() {
    dispatch(resetMissingDetailsListStoreState());
  }

  const handleRemindUser = (userIds) => {
    dispatch(
      remindExpense({
        payload: { user_ids: [userIds] },
        onSuccess: toggleOffUserRemindHandlerField,
      })
    );
  };

  const handleBulkRemindUsers = async () => {
    let action = {};

    if (peopleList?.length > 0) {
      action = await dispatch(
        remindExpense({
          payload: {
            user_ids: peopleList?.map((peopleInfo) => peopleInfo?.id),
          },
          onSuccess: toggleOffUserRemindHandlerField,
        })
      );
    }

    if (remindExpense.fulfilled.match(action)) {
      setSelectedMissingDetails([]);
    }
  };

  const handleRemindExpense = (expenseId) => {
    dispatch(
      remindExpense({
        payload: { expense_ids: [expenseId] },
        onSuccess: toggleOffUserRemindHandlerField,
      })
    );
  };

  const handleDismissSubmissionPolicy = (expenseId, index) => {
    dispatch(
      dismissSubmissionPolicy({
        expenseId,
        onSuccess: () => onSuccess(index),
      })
    );
  };

  function onSuccess(index) {
    if (sliderExpenseList?.length > 1) {
      fetchExpensesForSelectedUser();
    } else {
      const updatedPeopleList = [...peopleList];
      updatedPeopleList?.splice(index, 1);
      dispatch(setPeople(updatedPeopleList));
    }
  }

  const handleRowSelection = (missingDetail) => {
    if (selectedMissingDetails?.includes(missingDetail?.id)) {
      setSelectedMissingDetails((prev) =>
        prev.filter((missingDetailId) => {
          return missingDetailId !== missingDetail?.id;
        })
      );
    } else {
      setSelectedMissingDetails((prev) => [...prev, missingDetail?.id]);
    }
  };

  useEffect(() => {
    if (expandStatusIndex) {
      fetchExpensesForSelectedUser();
    }
  }, [expandStatusIndex]);

  function toggleOffUserRemindHandlerField() {
    dispatch(setUserRemindStatus(false));
  }

  function openExpenseSlider(expenseId) {
    searchParams.append(SLIDERS_SEARCH_PARAMS.expenses.id, expenseId);
    setSearchParams(searchParams);
  }

  function handleHearderSectionClick(userDetail) {
    dispatch(
      setExpandStatusIndexIndex(
        expandStatusIndex
          ? userDetail?.id !== expandStatusIndex
            ? userDetail?.id
            : null
          : userDetail?.id
      )
    );

    if (userDetail?.id !== expandStatusIndex) {
      dispatch(resetSliderExpensesListAndPagination());
    }
  }

  function fetchExpensesForSelectedUser() {
    dispatch(
      retrieveExpenseSliderTransactionList({
        page: INITIAL_PAGE_NUM,
        limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
        user: expandStatusIndex,
        submission_policy_status: SUBMISSION_POLICY_STATUSES.NOT_MET,
      })
    );
  }

  function viewAllExpenses(userDetail) {
    generateRedirectionUrlForFilters({
      parentRoute: ROUTES.cards.expenses.overView.absolutePath,
      dispatch,
      categoryKey: "Cardholder",
      categoryValues: { [userDetail?.id]: userDetail?.displayName },
      navigate,
      setSearchAndFilterCommonStoreStructure,
      setAppliedFilters,
      additionalSearchParams: `${AVAILABLE_FILTER_KEYS.submissionPolicyStatus}=${SUBMISSION_POLICY_STATUSES.NOT_MET}`,
      isCurrentAndTargetedRouteSame:
        pagePathName === ROUTES.cards.expenses.overView.absolutePath,
    });
  }

  return (
    <>
      <div
        className="flex flex-col gap-6 pt-0 p-9 slider-content-core"
        ref={cardState?.attach}
      >
        <div className="flex flex-col">
          <div className="flex flex-col gap-3 mb-10">
            <Text
              translationKey="expenses.overview.missingDetails"
              classes="text-3xl font-bold"
              refProp={titleRef}
            />
            <Text
              translationKey="cards.cardDetails.overview.policyDetails.expenses.missingDetailsDesc"
              classes="text-neutral-500 text-sm"
            />
          </div>

          <div className="flex flex-col gap-4 rounded-lg">
            {peopleList?.map((userDetail, index) => {
              return (
                <div
                  className="flex flex-col border rounded-lg cursor-pointer border-neutral-200"
                  key={userDetail?.id}
                >
                  <div
                    className={`flex items-center justify-between px-4 py-3 rounded-lg bg-neutral-100 ${
                      expandStatusIndex === userDetail?.id
                        ? "rounded-b-none"
                        : " "
                    }`}
                    key={index}
                    onClick={() => {
                      handleHearderSectionClick(userDetail);
                    }}
                  >
                    <ProfileWidget
                      name={userDetail?.displayName}
                      textClasses="text-neutral-800 text-sm"
                      className="grow"
                    >
                      <div className="flex gap-0.5 items-center text-xs font-medium text-neutral-500">
                        {userDetail?.role ? (
                          <Text
                            translationKey={userDetail?.role}
                            showTooltip={userDetail?.role?.length > 12}
                            tooltipClasses="w-12"
                            id={index}
                          />
                        ) : null}

                        {userDetail?.role &&
                        (userDetail?.departmentName ||
                          userDetail?.locationName) ? (
                          <span className="profile-widget-dot text-neutral-500" />
                        ) : null}

                        {userDetail?.departmentName ? (
                          <Text
                            translationKey={userDetail?.departmentName}
                            showTooltip={
                              userDetail?.departmentName?.length > 12
                            }
                            tooltipClasses="w-12"
                            id={index}
                          />
                        ) : null}

                        {userDetail?.departmentName &&
                        userDetail?.locationName ? (
                          <span className="profile-widget-dot text-neutral-500" />
                        ) : null}
                        {userDetail?.locationName ? (
                          <Text
                            translationKey={userDetail?.locationName}
                            showTooltip={userDetail?.locationName?.length > 12}
                            tooltipClasses="w-12"
                          />
                        ) : null}
                      </div>
                    </ProfileWidget>

                    <div className="flex flex-row items-center gap-2">
                      <Button
                        preIcon="NotificationActive"
                        iconClasses="h-4 w-4 text-primary-500"
                        label="cards.cardDetails.overview.policyDetails.expenses.remind"
                        classes="px-5 rounded-lg"
                        labelStyleClasses=" text-xs font-semibold"
                        variant="tertiary"
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemindUser(userDetail?.id);
                        }}
                      />

                      <Icon
                        name={
                          expandStatusIndex &&
                          expandStatusIndex === userDetail?.id
                            ? "UpArrow"
                            : "DownArrow"
                        }
                        className="text-neutral-500"
                      />
                    </div>
                  </div>

                  {expandStatusIndex === userDetail?.id ? (
                    <div className="flex flex-col gap-4 ">
                      {isFetchingExpensesForSelectedUser ? (
                        <MissingDetailLoader />
                      ) : null}
                      {sliderExpenseList?.map((details, ind) => {
                        const missingDetailLabel = `${
                          details?.missingDetailsCount
                        } missing detail${
                          details?.missingDetailsCount > 1 ? "s" : ""
                        }`;

                        const expenseDate = dateToString(details?.expenseDate);
                        const categoryName = details?.project?.name;
                        const expenseAmount = details?.amount;
                        const expenseAmountCurrency = details?.currency;
                        const expenseAmountLabel = `${expenseAmount} ${expenseAmountCurrency}`;

                        return (
                          <div
                            className={`px-4 py-3  ${
                              ind < sliderExpenseList.length - 1
                                ? "border border-t-0 border-l-0 border-r-0 border-neutral-200"
                                : ""
                            }`}
                            key={details?.id}
                          >
                            <MissingDetailsItemRow
                              handleRemindExpenses={() =>
                                handleRemindExpense(details?.id)
                              }
                              handleDismissSubmissionPolicy={() =>
                                handleDismissSubmissionPolicy(
                                  details?.id,
                                  index
                                )
                              }
                              name={details?.merchant?.name}
                              missingDetailLabel={missingDetailLabel}
                              expenseDate={expenseDate}
                              categoryName={categoryName}
                              expenseAmountLabel={expenseAmountLabel}
                              handleBulkSelect={handleRowSelection}
                              missingDetail={details}
                              selectedMissingDetails={selectedMissingDetails}
                              onClick={() => {
                                openExpenseSlider(details?.id);
                              }}
                            />
                          </div>
                        );
                      })}

                      {sliderExpensesTotalCount >
                      PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT ? (
                        <div className="px-3 pb-4 ">
                          <Button
                            label="cards.cardDetails.overview.policyDetails.expenses.viewAllLabel"
                            classes="w-fit px-5 rounded-lg"
                            labelStyleClasses=" text-xs font-semibold"
                            variant="tertiary"
                            size="xs"
                            onClick={() => viewAllExpenses(userDetail)}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              );
            })}

            {isFetching ? (
              <div
                className={
                  pageNum <= 1
                    ? "border rounded-lg cursor-pointer border-neutral-200"
                    : ""
                }
              >
                <MissingDetailLoader loopCount={10} />
              </div>
            ) : null}
          </div>
        </div>

        {hasMore && peopleList?.length > 0 ? (
          <Button
            classes="w-18"
            disabled={isFetching}
            type="default"
            variant="tertiary"
            label="myVolopay.reimbursements.reports.missingDetails.loadMore"
            onClick={handleLoadMoreButton}
          />
        ) : null}
      </div>

      <div className="flex items-center justify-end gap-4 px-3 pt-4 pb-2 slider-footer ">
        <Button
          label={
            isUserMissingDetails
              ? `Remind ${selectedUser?.firstName}`
              : `Remind ${peopleList?.length}`
          }
          preIcon="NotificationActive"
          classes="w-fit px-5"
          disabled={peopleList?.length === 0 || isFetching}
          onClick={handleBulkRemindUsers}
        />
      </div>
    </>
  );
}

export default MissingDetails;
