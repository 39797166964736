import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { defaultCurrencySelector } from "@/store/selectors/client";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { capitalizeFirstLetter } from "@/utils/common";

export default function SubmissionRequirementItem({ data, type }) {
  const createText = () => {
    const res = (
      <Text
        translationKey={`${data.name || capitalizeFirstLetter(type)}
    ${
      !data.required
        ? "not required for any transaction"
        : data.amount
          ? `required for all transactions above ${data.amount}`
          : "required for all transactions"
    }`}
      />
    );

    return res;
  };

  return (
    <div className="flex items-center gap-3">
      <div>
        {data.required ? (
          <Icon name="CircleCheck" className="text-success-600" />
        ) : (
          <Icon name="Cancel" className="text-danger-500" />
        )}
      </div>
      <span className="font-medium my-1">{createText()}</span>
    </div>
  );
}

SubmissionRequirementItem.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
};
