import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import UserDropdown from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/UserDropdown";
import DateInputTextBox from "@/components/common/DateInputTextBox";
import {
  dateDiff,
  dateToString,
  validateAndConvertToISO8601,
} from "@/utils/common";

function AddProjectMainForm({
  value,
  handleChange,
  errors,
  projectOwner,
  fetchProjectOwnerOptions,
  isFetchingProjectOwnerOptions,
  fetchProjectMemberOptions,
  isFetchingProjectMember,
  projectMember,
  isInEditMode,
  dateError,
  setDateError,
}) {
  const dateDiffrence = dateDiff(value.endDate, value?.startDate) < 0;
  const projectAlreadyStarted = dateDiff(value?.startDate, new Date()) < 0;

  useEffect(() => {
    if (dateDiffrence) {
      setDateError({
        endDate: "company.department.editProject.cannotSelectDateLess",
      });
    } else {
      setDateError("");
    }
    if (isInEditMode)
      setDateError({
        startDate: "company.department.editProject.cannotEditSelectDate",
      });
  }, [value]);
  const ref = useLeftHeaderTitle({
    title: isInEditMode
      ? "misc.editDetails"
      : "company.department.addNewProject.title",
  });

  const getMinDate = () => {
    if (dateDiff(value.endDate, value?.startDate) < 0) {
      return new Date(validateAndConvertToISO8601(value?.startDate));
    }
    return new Date(validateAndConvertToISO8601(value?.startDate));
  };

  return (
    <>
      <div ref={ref} className="flex flex-col">
        <Text
          classes="text-neutral-800 font-bold text-3xl"
          translationKey={
            isInEditMode
              ? "misc.editDetails"
              : "company.department.addNewProject.title"
          }
        />
        {!isInEditMode ? (
          <Text
            classes="text-sm text-neutral-500 font-medium"
            translationKey="company.department.addNewProject.description"
          />
        ) : null}
      </div>
      <Input
        name="name"
        value={value?.name}
        error={errors?.name}
        onChange={handleChange}
        label="company.department.addNewProject.nameOfProject"
        placeholder="company.department.addNewProject.nameOfProject"
      />
      <div className="flex w-full gap-6">
        <DateInputTextBox
          classes="w-full"
          name="startDate"
          value={dateToString(value?.startDate)}
          error={dateError?.startDate ?? errors?.startDate}
          handleChange={handleChange}
          label="company.department.addNewProject.date.startDate"
          disabled={isInEditMode}
          dateInputExtraProp={{ minDate: new Date() }}
        />

        {/* <DateInputTextBox
          classes="w-full"
          name="endDate"
          description="company.department.addNewProject.date.description"
          value={dateToString(value?.endDate)}
          dateInputExtraProp={{
            minDate: getMinDate(),
          }}
          error={dateError?.endDate ?? errors?.endDate}
          handleChange={handleChange}
          label="company.department.addNewProject.date.endDate"
        /> */}
      </div>

      {!isInEditMode ? (
        <>
          <div
            className={`flex flex-col ${
              value?.projectOwners?.length ? "gap-5" : "gap-3"
            }`}
          >
            <div className="flex flex-col font-semibold">
              <Text
                classes="text-lg  text-neutral-800"
                translationKey="company.department.addNewProject.projectOwner.plural"
              />
              <Text
                classes="text-sm text-neutral-500"
                translationKey="company.department.addNewProject.projectOwner.description"
              />
            </div>

            <UserDropdown
              name="projectOwners"
              users={projectOwner?.map((item) => ({
                ...item,
                name: item?.name,
                value: item?.id,
                label: item?.name,
              }))}
              groupAdminAndManager={null}
              dropdownLabelFastView=""
              selectedIdsArray={value?.projectOwners ?? []}
              handleSubmit={(e) => {
                const _e = structuredClone(e);
                _e.target.value = e.target.value.selectedIds;
                handleChange(_e);
              }}
              isLoading={isFetchingProjectOwnerOptions}
              insideForm
              label="company.department.addNewProject.projectOwner.owners"
            />
          </div>
          <div
            className={`flex flex-col ${
              value?.projectOwners?.length ? "gap-5" : "gap-3"
            }`}
          >
            <div className="flex flex-col font-semibold">
              <Text
                classes="text-lg  text-neutral-800"
                translationKey="company.department.addNewProject.projectMember.plural"
              />
              <Text
                classes="text-sm text-neutral-500"
                translationKey="company.department.addNewProject.projectMember.description"
              />
            </div>
            <UserDropdown
              name="projectMembers"
              users={projectMember?.map((item) => ({
                ...item,
                name: item?.name,
                value: item?.id,
                label: item?.name,
              }))}
              selectedIdsArray={value?.projectMembers ?? []}
              handleSubmit={(e) => {
                const _e = structuredClone(e);
                _e.target.value = e.target.value.selectedIds;
                handleChange(_e);
              }}
              isLoading={isFetchingProjectMember}
              insideForm
              label="company.department.addNewProject.projectMember.selectMembers"
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddProjectMainForm;
AddProjectMainForm.propTypes = {
  value: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  projectOwner: PropTypes.array,
  projectMember: PropTypes.array,
  fetchProjectOwnerOptions: PropTypes.func,
  isFetchingProjectOwnerOptions: PropTypes.bool,
  fetchProjectMemberOptions: PropTypes.func,
  isFetchingProjectMember: PropTypes.bool,
  isInEditMode: PropTypes.bool,
  dateError: PropTypes.string,
  setDateError: PropTypes.func,
};
