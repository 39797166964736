import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { redeemCashback } from "@/store/reducers/company";
import { drawCredit } from "@/store/reducers/credit";

import {
  creditAccountSelector,
  paymentWalletListSelector,
} from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";
import { amountToCurrency } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function DrawCredit() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const curCurrency = searchParams.get(SLIDERS_SEARCH_PARAMS.company.currency);

  const creditAccount = useSelector(creditAccountSelector);
  const paymentAccounts = useSelector(paymentWalletListSelector);

  const curAccount = paymentAccounts?.find(
    (acc) => acc.currency === curCurrency
  );

  const initialFormValue = {
    amount: {
      value: "",
      validate: {
        required: true,
        maxNumber: parseFloat(creditAccount?.creditAvailableBalance?.value),
      },
      errorStatement: {
        required: "cards.cardDetails.editLimitDetails.errorStatement",
        maxNumber: "company.credit.amountError",
      },
    },
  };
  const onSave = () => {
    const params = {
      amount: values.amount,
      currency: curCurrency,
    };
    dispatch(drawCredit({ payload: params, onSuccess }));
  };

  const onSuccess = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.credit.drawCredit);
    setSearchParams(searchParams);
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialFormValue, onSave);

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        <div>
          <Text
            classes="font-bold text-3xl text-neutral-800"
            translationKey="company.credit.drawCredit"
          />
        </div>
        {/* <Text
          classes="text-sm text-neutral-500 mt-1"
          translationKey="company.credit.drawCredit"
        /> */}

        <form onSubmit={handleSubmit} id="edit-cashback-slider-form">
          <div className="mt-6 flex flex-col">
            <Text
              classes="font-semibold text-lg text-neutral-800 mb-5"
              translationKey="company.credit.draw.drawingFrom"
            />
            <div className="card-wrapper flex items-center justify-between p-2">
              <div className="ml-3">
                <Text
                  classes="text-xs text-neutral-500 font-semibold"
                  translationKey="company.credit.draw.drawingFrom"
                />
                <Text
                  classes="text-lg font-bold block"
                  translationKey="company.credit.title"
                />
              </div>
              <div className="ml-3 text-right">
                <Text
                  classes="text-xs text-neutral-500 font-semibold"
                  translationKey="common.balance"
                />
                <div className="flex items-baseline">
                  <span className="text-lg font-bold text-neutral-800">
                    {amountToCurrency(
                      parseFloat(creditAccount?.creditAvailableBalance?.value)
                    )}
                  </span>
                  <span className="font-semibold text-neutral-500 ml-1 text-xs">
                    {creditAccount?.currency}
                  </span>
                </div>
              </div>
            </div>
            <Text
              classes="font-medium text-lg text-neutral-800 my-7"
              translationKey="company.credit.draw.howMuchDraw"
            />
            <div className="mt-4">
              <Input
                classes="text-xl font-semibold"
                name="amount"
                type="number"
                value={values?.amount}
                rightText={creditAccount?.currency}
                onChange={handleChange}
                label="forms.enterAmount"
                placeholder={0}
                error={errors.amount}
              />
            </div>

            {values?.amount ? (
              <div className="mt-10">
                <Text
                  classes="font-semibold text-lg text-neutral-800 mb-5"
                  translationKey="company.credit.draw.drawingDetails"
                />
                <div className="card-wrapper bg-neutral-50 border-neutral-50 mt-4 font-semibold text-sm">
                  <div className="flex justify-between">
                    <Text
                      translationKey="company.credit.draw.accountBalance"
                      translationProps={{ currency: curCurrency }}
                    />
                    <span>
                      {amountToCurrency(
                        parseFloat(curAccount.availableBalance),
                        curCurrency
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between mt-4">
                    <Text translationKey="company.credit.draw.requestedAmount" />
                    <span>
                      {amountToCurrency(
                        parseFloat(values?.amount),
                        curCurrency
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between mt-4">
                    <Text
                      translationKey="company.credit.draw.accountBalanceAfterDraw"
                      translationProps={{ currency: curCurrency }}
                    />
                    <span>
                      {amountToCurrency(
                        parseFloat(curAccount.availableBalance) +
                          parseFloat(values?.amount),
                        curCurrency
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between mt-4">
                    <Text translationKey="company.credit.draw.creditBalanceAfterDraw" />
                    <span>
                      {amountToCurrency(
                        parseFloat(
                          creditAccount?.creditAvailableBalance?.value
                        ) - parseFloat(values?.amount),
                        curCurrency
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </form>
      </div>
      <div className="slider-footer sticky p-6 ">
        <div className="flex justify-end ">
          <Button
            label="company.credit.confirmDraw"
            classes="w-18 px-5 py-3 text-base font-semibold"
            disabled={isFormButtonDisabled}
            id="draw-credit-button"
            btnType="submit"
            onClick={() => {
              onSave();
            }}
            form="edit-cashback-slider-form"
          />
        </div>
      </div>
    </div>
  );
}
