// React imports
import { PropTypes } from "prop-types";
import React from "react";
// Redux imports
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { selectedCardSelector } from "@/store/selectors/cards";

// Core component imports
import Icon from "@/components/core/Icon";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { amountToCurrency } from "@/utils/common";

import { LINKED_TO_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { INFINITY_BUDGET_AVAILABLE_TEXT } from "@/constants/expense";

function LinkedToWidget({
  linkedToName,
  currency,
  limit,
  cardAvailableBalance,
  projectId,
  values,
  onClick,
  budgetAvailableBalance,
  disableWidget,
  project,
  cardBudgetId,
  widgetType,
  showBudgetAvailableText = true,
}) {
  const DETAIL_CARD_OVERVIEW = {
    limit: {
      id: 1,
      label: "filters.cardLimit",
      currency,
      amount: limit,
    },
    available: {
      id: 2,
      label: "cards.spendOverview.available",
      currency,
      amount: cardAvailableBalance,
    },
  };
  const selectedCard = useSelector(selectedCardSelector);
  const configCard = [
    DETAIL_CARD_OVERVIEW.limit,
    DETAIL_CARD_OVERVIEW.available,
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const isTriggerCardSlider = searchParams.get(SLIDERS_SEARCH_PARAMS.cards.id);

  const isChecked = cardBudgetId === values?.id;
  const showWarnigMessageForSettingLimit =
    limit === cardAvailableBalance && limit === 0 && isTriggerCardSlider;

  return (
    <div className="p-0 card-wrapper">
      <div className="flex justify-between p-4">
        <div className="flex gap-2" id={`project-${cardBudgetId}`}>
          <Radio
            isChecked={isChecked}
            handleChange={() => {
              onClick(project);
            }}
            value={projectId}
            disabled={disableWidget}
          />

          {disableWidget ? (
            <Tooltip id={`project-${cardBudgetId}`} direction="top">
              <Text
                translationKey="cards.listingFields.linkedTo.warningTooltipMessages"
                translationProps={{ widgetType: widgetType?.toLowerCase() }}
              />
            </Tooltip>
          ) : null}

          <div className="flex flex-col">
            <Text translationKey={linkedToName} />

            {showBudgetAvailableText ? (
              <Text
                translationKey={
                  budgetAvailableBalance === INFINITY_BUDGET_AVAILABLE_TEXT
                    ? "cards.listingFields.linkedTo.infinityBudgetSubText"
                    : "cards.listingFields.linkedTo.budgetSubText"
                }
                translationProps={
                  budgetAvailableBalance === INFINITY_BUDGET_AVAILABLE_TEXT
                    ? {}
                    : { budgetAvailableBalance, currency }
                }
                classes="text-sm text-neutral-500 font-semibold"
              />
            ) : null}
          </div>
        </div>
        {selectedCard?.project?.id === projectId && <Icon name="Link" />}
      </div>
      <div className="flex justify-between p-4 bg-neutral-50">
        {configCard.map((card) => (
          <div className="flex flex-col gap-1" key={card?.id}>
            <Text
              translationKey={card.label}
              classes="text-sm text-neutral-500 font-semibold"
            />
            {amountToCurrency(card.amount, card?.currency)}
          </div>
        ))}
      </div>
      {showWarnigMessageForSettingLimit ? (
        <div className="flex items-center gap-2 p-4">
          <Icon name="WarningTriangle" className="text-warning-500" />
          <Text
            translationKey="cards.listingFields.linkedTo.warningMessaageForSettingLimit"
            classes="text-warning-500"
            translationProps={{
              linkedToType:
                widgetType === LINKED_TO_TYPE.DEPARTMENT
                  ? LINKED_TO_TYPE.DEPARTMENT?.toLowerCase()
                  : LINKED_TO_TYPE.PROJECT?.toLowerCase(),
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export default LinkedToWidget;
LinkedToWidget.propTypes = {
  disableWidget: PropTypes.bool,
  linkedToName: PropTypes.string,
  cardAvailableBalance: PropTypes.number,
  budgetAvailableBalance: PropTypes.number,
  currency: PropTypes.string,
  limit: PropTypes.number,
  projectId: PropTypes.number,
  values: PropTypes.object,
  onClick: PropTypes.func,
  project: PropTypes.object,
  cardBudgetId: PropTypes.number,
  showBudgetAvailableText: PropTypes.bool,
};
