import PropTypes from "prop-types";

import Badge from "@/components/core/Badge";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { getLocalTime } from "@/utils/common";

import { MASTER_HISTORY_ACTIVITY_TYPES_TITLES_MAP } from "@/constants/settings";

export default function MasterHistoryDumbComponent({
  logsData,
  isGrouped,
  handleRefChange,
  handleActivityTypeSliderOpen,
  handleChildLogsSliderOpen,
  hasMore,
}) {
  const dataLength = isGrouped ? Object.keys(logsData).length : logsData.length;

  const RenderLogItem = ({
    logItem,
    paginationCheck,
    logDataLength,
    index,
  }) => {
    return (
      <div
        key={`master-history-list-${logItem?.id}`}
        className={`px-4 py-3 flex items-center justify-between ${
          isGrouped ? "border-t-2" : "border-b-2"
        } ${index === logDataLength - 1 ? "border-b-2" : ""} ${
          !logItem?.navigable ? "bg-neutral-50" : ""
        } border-neutral-200`}
        ref={(ref) => {
          if (paginationCheck) {
            handleRefChange(ref);
          }
        }}
      >
        <div className="flex flex-col">
          <div className="max-w-prose">
            <Text translationKey={logItem?.message} />
          </div>

          <div className="flex items-center gap-1 text-sm font-medium text-neutral-500">
            <Text
              translationKey={
                MASTER_HISTORY_ACTIVITY_TYPES_TITLES_MAP[logItem?.activityType]
              }
            />
            <Text translationKey="•" noTranslate />
            <Text translationKey={getLocalTime(logItem?.createdAt)} />
          </div>
        </div>

        {logItem?.navigable ? (
          <div className="flex items-center gap-3 cursor-pointer">
            {logItem?.childLogs && isGrouped ? (
              <Badge
                translationKey={`${logItem?.childLogs} logs`}
                classes="px-2 py-1 rounded-lg"
                textClasses="text-xs font-semibold"
              />
            ) : null}

            <Icon
              name="ArrowForward"
              className="text-neutral-500"
              handleClick={() => {
                if (logItem?.navigable && logItem?.childLogs === 0)
                  handleActivityTypeSliderOpen(logItem);
                else if (isGrouped) handleChildLogsSliderOpen(logItem?.id);
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  return isGrouped
    ? Object.entries(logsData)?.map(([key, values = []], index) => (
        <div key={`${key}-master-history`}>
          <div className="px-4 py-2">
            <Text
              translationKey={key}
              classes="text-sm font-medium text-neutral-500"
            />
          </div>

          {values?.map((value, indx) => (
            <RenderLogItem
              key={value.id}
              logItem={value}
              paginationCheck={
                dataLength - 1 === index &&
                values.length - 1 === indx &&
                hasMore
              }
              logDataLength={values.length}
            />
          ))}
        </div>
      ))
    : logsData?.map((log, index) => (
        <RenderLogItem
          key={log.id}
          logItem={log}
          paginationCheck={dataLength - 1 === index && hasMore}
          logDataLength={dataLength}
        />
      ));
}

MasterHistoryDumbComponent.propTypes = {
  isGrouped: PropTypes.bool,
  logsData: PropTypes.object,
  hasMore: PropTypes.bool,
  handleRefChange: PropTypes.func,
  handleActivityTypeSliderOpen: PropTypes.func,
  handleChildLogsSliderOpen: PropTypes.func,
};
