// Keep only functions here

import to from "await-to-js";
import vToast from "@/utils/vToast";
import { getErrorToastMessage } from "@/utils/common";
import $axios from "@/api/axios";

/**
 * Cause a download from remote URL
 * @param {String} url
 * @param {String} label file will be downloaded as
 * @param {String} contentType
 */
export const downloadFile = async (
  url,
  label = "download",
  contentType = ""
) => {
  const [err, response] = await to($axios.get(url, { responseType: "blob" }));
  if (response) {
    const blob = new Blob([response.data.data ?? response.data], {
      type: contentType,
    });
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(blob);
    link.download = label;
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(link.href);
    link.remove();
  } else {
    vToast(getErrorToastMessage(err, "misc.fileDownloadFailedMessage"));
  }
};
