// react imports
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// reducers
import {
  setCurrentOnboardingSection,
  setCurrentOnboardingStep,
  setOnboardingSection,
} from "@/store/reducers/onboarding";
import { fetchAndSelectUser } from "@/store/reducers/user";

import { countriesSelector } from "@/store/selectors/app";
// selectors
import {
  currentOnboardingSectionSelector,
  currentOnboardingStepSelector,
  selectedSectionSelector,
  stepsSelector,
} from "@/store/selectors/onboarding";
import { selectedUserSelector, userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";
import Icon from "@/components/core/Icon";
import Note from "@/components/core/Note";
// core components
import Text from "@/components/core/Text";

// utils, constant file imports
import {
  ONBOARDING_SLIDER_PARAMS,
  ONBOARDING_STEPS,
  PERSONAL_DETAILS,
} from "@/constants/onboarding";

function CommonHeaderComponent({ headerProps }) {
  const {
    step,
    label,
    showSectionInstructions,
    skippable,
    helperText,
    note,
    handleActionButtonClick,
    setFormValues,
  } = headerProps;

  const [searchPararms, setSearchParams] = useSearchParams();

  const currentUser = useSelector(userSelector);

  const userId = currentUser?.id;

  const selectedUser = useSelector(selectedUserSelector);

  const stepSearchParam = searchPararms.get(ONBOARDING_SLIDER_PARAMS);

  const dispatch = useDispatch();

  const selectedSection = useSelector(selectedSectionSelector);

  const [file, setFile] = useState([]);

  const [stackOfSteps, setStackOfStep] = useState([]);

  const currentOnboardingSection = useSelector(
    currentOnboardingSectionSelector
  );
  const currentOnboardingStep = useSelector(currentOnboardingStepSelector);

  const currentIndex = useMemo(() => {
    return stackOfSteps.indexOf(currentOnboardingSection);
  }, [stackOfSteps, currentOnboardingSection]);

  const countryOptions = useSelector(countriesSelector);

  const showNote = !!note;

  const { showButton, buttonText, handleClick } = getActionButtonConfig();

  const {
    titleText = "",
    descriptionText = "",
    actionText = "",
    borderColorClass = "",
    backgroundColorClass = "",
  } = showNote ? (Array.isArray(note) ? (note?.[0] ?? {}) : note) : {};

  const allSteps = useSelector(stepsSelector);

  const currentStepIndex = useMemo(() => {
    const currentIndexInfo = allSteps.findIndex(
      (indexStep) => indexStep.step === currentOnboardingStep
    );
    return currentIndexInfo;
  }, [allSteps, currentOnboardingStep]);

  // currentIndex - It is the index of the page in a step
  // currentStepIndx - It is the index of the step which we are in currently

  const showLeftArrowIcon = currentIndex > 0 || currentStepIndex > 0;

  useEffect(() => {
    if (stepSearchParam === ONBOARDING_STEPS.PERSONAL_DETAILS) {
      dispatch(fetchAndSelectUser({ userId }));
    }
  }, [stepSearchParam]);

  useEffect(() => {
    if (selectedSection) {
      setStackOfStep(selectedSection?.map((item) => item.key));
    }
  }, [selectedSection]);

  useEffect(() => {
    const avatarUrl = selectedUser?.avatarUrl;

    if (avatarUrl) {
      setFile([{ url: avatarUrl }]);
    }
  }, [selectedUser]);

  function handleBack() {
    dispatch(setOnboardingSection(null));
    dispatch(setCurrentOnboardingSection(null));

    if (stackOfSteps.length > 1) {
      if (currentIndex === 0) {
        checkForPreviousStep();
      } else if (currentIndex > 0) {
        dispatch(setCurrentOnboardingSection(stackOfSteps[currentIndex - 1]));
      }
    } else if (stackOfSteps.length === 1) {
      checkForPreviousStep();
    }
  }

  function checkForPreviousStep() {
    const previousStepIndex = currentStepIndex - 1;
    if (previousStepIndex >= 0) {
      const previousStackOfSteps = allSteps[previousStepIndex].sections;
      const previousStepName = allSteps[previousStepIndex].step;
      const onboradingSectionKey =
        previousStackOfSteps[previousStackOfSteps.length - 1].key;
      dispatch(setCurrentOnboardingSection(onboradingSectionKey));
      dispatch(setCurrentOnboardingStep(previousStepName));
    }
  }

  function getActionButtonConfig() {
    const config = {
      handleClick: handleActionButtonClick,
      show: false,
      buttonText: "",
    };

    switch (currentOnboardingStep) {
      case ONBOARDING_STEPS.VIDEO_KYC_PROCESS:
        config.buttonText = "I’ll do it later";
        config.handleClick = handleActionButtonClick;
        config.showButton = skippable;
        break;

      default:
        break;
    }
    return config;
  }

  return (
    <div
      className={`flex items-center justify-between border-b-2 pb-9 mb-9 border-neutral-100 ${
        showNote ? "flex-col gap-8" : ""
      }`}
    >
      <div className="flex items-center justify-between w-full">
        <div
          className={`flex gap-2  ${
            showButton ? "justify-between w-full" : ""
          }`}
        >
          <div className="flex flex-row items-start gap-6">
            {showLeftArrowIcon ? (
              <Icon
                handleClick={() => {
                  handleBack();
                }}
                name="LeftArrow"
                className="mt-2 cursor-pointer w-7 h-7"
              />
            ) : null}

            <div className="flex flex-col w-10/12 gap-3">
              <Text classes="text-xl font-bold " translationKey={label} />
              <Text
                color="neutral-500"
                classes="text-sm font-normal"
                translationKey={helperText}
              />
            </div>
          </div>

          {showButton ? (
            <Button
              label={buttonText}
              classes="border-neutral-300 border-2 w-fit p-4 text-neutral-500"
              variant=""
              onClick={handleClick}
            />
          ) : null}
        </div>

        {stepSearchParam === PERSONAL_DETAILS ? (
          <div className="w-12 h-12 ">
            <FileUpload
              showPreview
              variant="custom"
              profileUpload
              customComponent={
                <div className="relative flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-neutral-200">
                  <Icon name="Person" className="w-5 h-5" />
                  <div className="absolute bottom-0 right-0 p-1 bg-white rounded-full border-[1px] border-neutral-100">
                    <Icon name="Edit" className="w-3 h-3" />
                  </div>
                </div>
              }
              hideCustomButtonAfterUpload
              replaceAfterUpload
              files={file}
              previewChildClasses="w-12 h-12 rounded-full border-[1px] border-neutral-100 overflow-hidden bg-contain"
              handleFileChange={(e) => {
                if (setFormValues) {
                  setFormValues((prev) => ({ ...prev, avatar: e?.[0] }));
                }

                setFile(e);
              }}
            />
          </div>
        ) : null}
      </div>

      {showNote ? (
        <Note
          titleText={titleText}
          descriptionText={descriptionText}
          actionText={actionText}
          borderColorClass={borderColorClass}
          backgroundColorClass={backgroundColorClass}
        />
      ) : null}
    </div>
  );
}
CommonHeaderComponent.propTypes = {
  headerProps: PropTypes.shape({
    step: PropTypes.string,
    label: PropTypes.string,
    showSectionInstructions: PropTypes.bool,
    helperText: PropTypes.string,
    note: PropTypes.object,
    handleActionButtonClick: PropTypes.func,
    skippable: PropTypes.bool,
    setFormValues: PropTypes.func,
  }),
};
export default CommonHeaderComponent;
