import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { downloadAttachment } from "@/store/reducers/app";
import { createCreditLimit } from "@/store/reducers/credit";

import { creditDetailsSelector } from "@/store/selectors/credit";

import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { createFileFormData, jsToFormData } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";
import { DEFAULT_ACCEPT_WITH_PDF } from "@/constants/fileViewer";

import ReviewBudget from "../common/ReviewBudget";

export default function CreditLimitSlider() {
  const dispatch = useDispatch();
  const creditDetail = useSelector(creditDetailsSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  const [amount, setAmount] = useState(null);
  const [note, setNote] = useState(null);
  const [files, setFiles] = useState([]);

  const creditType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.credit.creditLimit
  );

  const isRequest = creditType === FORM_TYPE.request;

  const onSave = () => {
    const params = {
      amount,
      note,
      bank_statements: files,
    };
    const formData = jsToFormData(params);

    dispatch(createCreditLimit({ payload: formData, onSuccess }));
  };

  const onSuccess = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.credit.creditLimit);
    setSearchParams(searchParams);
  };

  const primaryAction = {
    handler: (index) => {
      setFiles([]);
    },
    label: "misc.delete",
    icon: "Delete",
    iconClasses: "text-danger-600 bg-danger-50",
  };

  const secondaryAction = {
    handler: (index) => {
      const file = files[index];
      if (file instanceof File) return;
      dispatch(
        downloadAttachment({
          file,
          id: file?.id,
        })
      );
    },
    label: "misc.download",
    icon: "Download",
    iconClasses: "text-primary-500 bg-primary-50", // some issue with SVG, using !
  };

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        <div>
          <Text
            classes="font-bold text-3xl text-neutral-800"
            translationKey={`company.credit.${
              isRequest ? "requestCredit" : "increaseCreditLine"
            }`}
          />
        </div>
        {/* <Text
          classes="text-sm text-neutral-500 mt-1"
          translationKey="company.cashback.description"
        /> */}
        <div className="mt-6 flex flex-col">
          <Text
            classes="font-semibold text-xl text-neutral-800 my-7"
            translationKey={`company.credit.${
              isRequest ? "howMuchCredit" : "howMuchCreditInc"
            }`}
          />
          <div className="mt-4">
            <Input
              classes="text-xl font-semibold"
              name="amount"
              type="number"
              value={amount}
              rightText={creditDetail?.currency}
              onChange={(e) => setAmount(e.target.value)}
              label="forms.enterAmount"
              s
            />
          </div>
        </div>
        <div className="mt-6 flex flex-col">
          <Text
            classes="font-semibold text-xl text-neutral-800 my-7"
            translationKey={`company.credit.${
              isRequest ? "reasonRequest" : "reasonIncr"
            }`}
          />

          <div className="mt-4">
            <Input
              name="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              label="forms.enterComment"
            />
          </div>
        </div>
        <div className="mt-6 flex flex-col">
          <Text
            classes="font-semibold text-xl text-neutral-800 mt-7"
            translationKey="company.credit.bankStatement"
          />
          <Text
            classes="text-sm text-neutral-500 mb-4"
            translationKey="company.credit.bankStatementDesc"
          />
          <FileUpload
            acceptText="common.defaultUploadTextWithPdf"
            accept={DEFAULT_ACCEPT_WITH_PDF}
            files={files}
            handleFileChange={(e) => {
              setFiles(e);
            }}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
          />
        </div>

        {amount && !isRequest ? (
          <div className="my-7">
            <Text
              classes="font-semibold text-xl text-neutral-800"
              translationKey="company.credit.availCreditLimit"
            />
            <ReviewBudget
              oldValue={{
                amount: {
                  value: creditDetail?.creditLimit,
                  currency: creditDetail?.currency,
                  oldValueTitle: "cards.editLimit.reviewBudget.limit.old",
                },
              }}
              newValue={{
                amount: {
                  value:
                    parseFloat(creditDetail?.creditLimit) + parseFloat(amount),
                  currency: creditDetail?.currency,
                  newValueTitle: "cards.editLimit.reviewBudget.limit.new",
                },
              }}
              budgetKeys={["amount"]}
            />
          </div>
        ) : null}
      </div>
      <div className="slider-footer sticky p-6 ">
        <div className="flex justify-end ">
          <Button
            label="forms.submit"
            classes="w-18 px-5 py-3 text-base font-semibold"
            disabled={!amount}
            id="credit-limit-button"
            onClick={() => {
              onSave();
            }}
            form="edit-cashback-slider-form"
          />
        </div>
      </div>
    </div>
  );
}
