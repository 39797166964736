import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import usePagination from "@/hooks/usePagination";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";

import SpendsTabTable from "@/components/Company/Slider/DateGroupedTable";
import { SpendsTabTableLoader } from "@/components/Company/Slider/LocationDepartmentDetails/SpendsTab";

export default function SpendAnalyticsTable({
  tabs = [],
  outerContainerClasses,
}) {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const tableInfoSelector = selectedTab?.listSelector;

  const isExpenseTable = selectedTab?.isExpenseTable;

  const list = useSelector(tableInfoSelector);

  const inProgressSelector = selectedTab?.inProgress;

  const inProgressStatusValueFromStore = useSelector(inProgressSelector);
  const inProgressStatusValue = inProgressSelector
    ? inProgressStatusValueFromStore
    : false;

  const hasMore = useSelector(selectedTab?.hasMoreSelector);

  const resetFunc = selectedTab?.resetTableData;

  const CustomTableComponent = selectedTab?.customTableComponent;

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset: handleReset,
    filterOptions: {
      tab: JSON.stringify(selectedTab),
    },
  });

  function handleTabClick(tab) {
    setSelectedTab(tab);
  }

  function showMoreHandler() {
    setPageNum((prev) => prev + 1);
  }

  function handleReset() {
    if (resetFunc) {
      resetFunc();
    }
  }

  function loadMore() {
    selectedTab?.handleClick(pageNum);
  }

  const spendState = useLoadingErrorInjector({
    apiKey: "ClientAnalytics:transactions",
    showLoader: true,
    error: {
      header: "Spends",
    },
  });

  if (tabs.length <= 0) return null;

  return (
    <div className={outerContainerClasses} ref={spendState?.attach}>
      <Tabs
        items={tabs}
        selectedTab={selectedTab.key}
        setCurrentTab={handleTabClick}
        classes="mb-7"
        mode
      />

      {CustomTableComponent ? (
        <CustomTableComponent data={list} />
      ) : (
        <SpendsTabTable
          list={list}
          isFetching={inProgressStatusValue}
          loaderFunction={SpendsTabTableLoader}
          isExpenseTable={isExpenseTable}
        />
      )}

      {hasMore ? (
        <div className="mt-5">
          <Button
            label="misc.showMore"
            disabled={!hasMore || inProgressStatusValue}
            preIcon="Down"
            onClick={showMoreHandler}
            classes="px-5 py-3 text-primary-500 font-semibold rounded-lg border-neutral-300"
            size="0"
            variant="tertiary"
          />
        </div>
      ) : null}
    </div>
  );
}

SpendAnalyticsTable.propTypes = {
  tabs: PropTypes.array,
  outerContainerClasses: PropTypes.string,
};
