import PropTypes from "prop-types";
import React from "react";

import CommentDisplayChildHelper from "@/components/core/Comments/CommentDisplayChildHelper";

export default function CommentDisplayChild({
  sanitizeHtmlOptions,
  data: dataForParticularUser,
}) {
  return (
    <div>
      {dataForParticularUser
        ?.map(({ user, time, data }, index) => (
          <CommentDisplayChildHelper
            key={data.id || index}
            options={sanitizeHtmlOptions}
            user={user}
            time={time}
            data={data}
          />
        ))
        .reverse()}
    </div>
  );
}
CommentDisplayChild.propTypes = {
  sanitizeHtmlOptions: PropTypes.object,
  data: PropTypes.array,
};
