import PropTypes from "prop-types";
import React from "react";

export default function DotIterator({ iterateCount }) {
  return Array(iterateCount)
    .fill(0)
    .map((index) => (
      <span className="profile-widget-dot text-neutral-500" key={index} />
    ));
}

DotIterator.propTypes = {
  iterateCount: PropTypes.number,
};
