import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { currentPolicySelector } from "@/store/selectors/policy";

import Text from "@/components/core/Text";

import AppliedCountCards from "@/components/Settings/Sliders/common/AppliedCountCards";
import { MODULES } from "@/utils/constants/app";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { POLICY_LAYER } from "@/constants/policy";
import { TAGS_MAP, TAGS_TYPES_ARRAY } from "@/constants/settings";

import EditForm from "./EditForm";
import SubmissionRequirementItem from "./SubmissionRequirementItem";

export default function SubmissionRequirements() {
  const curtPolicy = useSelector(currentPolicySelector);

  const [displayData, setDisplayData] = useState(curtPolicy);
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultRequirement = ["receipt", "memo", "review", "subCategory"];

  const editState = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.editRequirement
  );

  const currentModule = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.submissionModule
  );

  const submissionPolicyType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.submissionPolicy
  );

  const isCompany = submissionPolicyType === POLICY_LAYER.company;

  useEffect(() => {
    setDisplayData(curtPolicy || {});
  }, [curtPolicy]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-0.5">
        {!editState &&
        isCompany &&
        ![MODULES.PAYROLL].includes(currentModule) ? (
          <div className="mt-2">
            <AppliedCountCards
              departmentCount={curtPolicy?.linkedDepartments?.total || 0}
              projectCount={curtPolicy?.linkedProjects?.total || 0}
            />
          </div>
        ) : null}

        {/* View state */}
        {!editState && (
          <div className="mt-4">
            <div className="flex flex-col gap-2">
              {Object.keys(displayData).map((key, index) => {
                let component = null;

                if (
                  typeof displayData[key] === "object" &&
                  defaultRequirement.includes(key)
                ) {
                  component = (
                    <SubmissionRequirementItem
                      data={displayData[key]}
                      type={key}
                      key={index}
                    />
                  );
                } else if (
                  TAGS_TYPES_ARRAY.includes(key) &&
                  displayData[key].length
                )
                  component = (
                    <div key={index} className="flex flex-col gap-2 mt-2">
                      <Text
                        translationKey={TAGS_MAP[key]}
                        classes="text-sm text-neutral-400 font-medium"
                      />
                      {displayData[key].map((item, idx) => (
                        <SubmissionRequirementItem
                          data={item}
                          type={key}
                          key={idx}
                        />
                      ))}
                    </div>
                  );

                return component;
              })}
            </div>
          </div>
        )}

        {/* Edit state */}
        {editState && (
          <div className="mt-4">
            <div className="flex flex-col gap-2">
              {Object.keys(displayData).map((key, index) => {
                let component = null;
                if (key !== "id" || key !== "type") {
                  if (
                    typeof displayData[key] === "object" &&
                    defaultRequirement.includes(key)
                  ) {
                    component = (
                      <EditForm
                        form={displayData[key]}
                        name={key}
                        key={index}
                      />
                    );
                  }
                  if (TAGS_TYPES_ARRAY.includes(key) && displayData[key].length)
                    component = (
                      <div key={index}>
                        <Text
                          translationKey={TAGS_MAP[key]}
                          classes="text-lg font-medium mt-4 my-2"
                        />
                        {displayData[key].map((tag, tagIndex) => (
                          <EditForm
                            tagType={key}
                            form={tag}
                            name={tag.name}
                            key={tagIndex}
                            index={tagIndex}
                          />
                        ))}
                      </div>
                    );
                }

                return component;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
