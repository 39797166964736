import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";

import QRPaySettings from "@/pages/QrPay/settings";
import QRPaySettingsGeneralPage from "@/pages/QrPay/settings/general";
import QRPaySettingsSpendControlPage from "@/pages/QrPay/settings/spend-control";
import UserWallets from "@/pages/QrPay/user-wallets";
import AllUserWallets from "@/pages/QrPay/user-wallets/all";
import YetToActivateUserWallets from "@/pages/QrPay/user-wallets/yet-to-activate";
import { ROUTES } from "@/constants/routes";
import { ADMIN_ROLE } from "@/constants/common";
import PaymentRoutes from "@/routes/QRPay";

function QrPayPayments() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<PaymentRoutes />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={`${ROUTES.QRPay.qrPayments.pathName}/*`}
          element={<PaymentRoutes />}
        />
        <Route
          path={ROUTES.QRPay.userWallets.pathName}
          element={<UserWallets />}
        >
          <Route
            path={ROUTES.QRPay.userWallets.all.pathName}
            element={<AllUserWallets />}
          />
          <Route
            path={ROUTES.QRPay.userWallets.yetToActivate.pathName}
            element={<YetToActivateUserWallets />}
          />
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.cards.settings.pathName}
          element={<QRPaySettings />}
        >
          <Route
            path={ROUTES.cards.settings.general.pathName}
            element={<QRPaySettingsGeneralPage />}
          />
          <Route
            path={ROUTES.cards.settings.spendControl.pathName}
            element={<QRPaySettingsSpendControlPage />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default QrPayPayments;
