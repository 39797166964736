import PropTypes from "prop-types";
import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function ApprovalsLoaderHelper({ pageType }) {
  return (
    <tr className="text-center">
      <td className="flex gap-3 items-center ml-4">
        <LoaderSkeleton type="circle" />
        <LoaderSkeleton size={[20, 180]} />
      </td>
      <td className="text-right">
        <LoaderSkeleton size="lg" />
      </td>
      <td className="text-left">
        <LoaderSkeleton size="lg" />
      </td>
      <td className="text-left">
        <LoaderSkeleton />
      </td>
      <td className="text-center">
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td className="text-left">
        <LoaderSkeleton />
      </td>
      {pageType === "reimbursement.tabTitles.needsYourAction" ? (
        <td className="text-left">
          <LoaderSkeleton size={[30, 100]} />
        </td>
      ) : null}
    </tr>
  );
}
ApprovalsLoaderHelper.propTypes = {
  pageType: PropTypes.string,
};
