import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  dismissSubmissionPolicy,
  fetchMissingDetails,
  fetchStatistics,
  remindExpense,
} from "@/store/reducers/expense";

import { allPeopleSelector } from "@/store/selectors/company";
import {
  isFetchingMissingDetailsSelector,
  missingDetailsSelector,
  missingDetailsUserIdsSelector,
} from "@/store/selectors/expense";

import Button from "@/components/core/Button";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import MissingDetailsItemRow from "./MissingDetailsItemRow";

function UserMissingDetails() {
  const missingDetailsResponse = useSelector(missingDetailsSelector);
  const missingDetailsUserIds = useSelector(missingDetailsUserIdsSelector);

  const peopleList = useSelector(allPeopleSelector);
  const inProgress = useSelector(isFetchingMissingDetailsSelector);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.expenses.userMissingDetails
  );
  const missingDetailsOfUser = missingDetailsResponse[userId];
  const userDetail = peopleList.find((people) => people.id === Number(userId));
  const fetchingMissingDetails = () => {
    dispatch(
      fetchMissingDetails({
        user_ids: missingDetailsUserIds,
      })
    );
  };
  const handleRemindExpense = (expenseId) => {
    dispatch(remindExpense({ expense_ids: [expenseId] }));
  };

  const handleRemindUser = (userIds) => {
    dispatch(remindExpense({ user_ids: [userIds] }));
  };
  const onSuccess = () => {
    fetchingMissingDetails();
    dispatch(fetchStatistics());
  };
  const handleDismissSubmissionPolicy = (id) => {
    dispatch(dismissSubmissionPolicy({ expenseId: id, onSuccess }));
  };

  return (
    <div className="flex flex-col gap-10 pt-0 p-9 slider-content-core">
      <div className="flex flex-col gap-3">
        <Text
          translationKey="cards.cardDetails.overview.policyDetails.expenses.missingDetails"
          classes="text-3xl font-bold"
        />
        <Text
          translationKey="cards.cardDetails.overview.policyDetails.expenses.missingDetailsDesc"
          classes="text-neutral-500 text-sm"
        />
      </div>
      {inProgress ? (
        <Text translationKey="cards.editLimit.loadingText" />
      ) : null}

      <div className="flex flex-col gap-8">
        <div>
          <div className="flex justify-between px-4 py-3 border border-b-0 rounded-lg border-neutral-200 bg-neutral-100">
            <ProfileWidget
              name={userDetail?.displayName}
              textClasses="text-neutral-800 text-sm"
            >
              <div className="flex gap-0.5 items-center text-xs font-medium text-neutral-500">
                {userDetail?.role ? (
                  <Text translationKey={userDetail?.role} />
                ) : null}
                {userDetail?.role &&
                (userDetail?.departmentName || userDetail?.locationName) ? (
                  <span className="profile-widget-dot text-neutral-500" />
                ) : null}
                {userDetail?.departmentName ? (
                  <Text translationKey={userDetail?.departmentName} />
                ) : null}
                {userDetail?.departmentName && userDetail?.locationName ? (
                  <span className="profile-widget-dot text-neutral-500" />
                ) : null}
                {userDetail?.locationName ? (
                  <Text translationKey={userDetail?.locationName} />
                ) : null}
              </div>
            </ProfileWidget>
            <Button
              preIcon="NotificationActive"
              iconClasses="h-4 w-4 text-primary-500"
              label={`Remind ${userDetail?.displayName.split(" ")[0]}`}
              classes="w-fit  px-5 rounded-lg"
              labelStyleClasses=" text-xs font-semibold"
              variant="tertiary"
              size="xs"
              onClick={() => handleRemindUser(userDetail?.id)}
            />
          </div>
          <div className="flex flex-col gap-4 card-wrapper">
            {missingDetailsOfUser?.map((details, index) => {
              const missingDetailLabel = `${
                details?.missingDetailsCount
              } missing detail${details?.missingDetailsCount > 1 ? "s" : ""}`;

              const expenseDate = dateToString(details?.expenseDate);
              const categoryName = details?.categoryName;
              const expenseAmount = details?.amount;
              const expenseAmountCurrency = details?.currency;
              const expenseAmountLabel = `${expenseAmount} ${expenseAmountCurrency}`;

              return (
                <>
                  <MissingDetailsItemRow
                    handleRemindExpenses={() =>
                      handleRemindExpense(details?.id)
                    }
                    handleDismissSubmissionPolicy={() =>
                      handleDismissSubmissionPolicy(details?.id)
                    }
                    key={details?.id}
                    name={details?.merchant}
                    missingDetailLabel={missingDetailLabel}
                    expenseDate={expenseDate}
                    categoryName={categoryName}
                    expenseAmountLabel={expenseAmountLabel}
                  />
                  {index < missingDetailsOfUser.length - 1 && (
                    <div className="border border-b-1 border-neutral-100" />
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserMissingDetails;
