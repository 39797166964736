import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { BANNER_COLORS } from "@/constants/common";

/**

 * This component renders a banner with a title, description, optional close button, CTAs (Call to Actions),
 * and a background color based on the specified type.
 *
 * @param {string} props.title - title text to be displayed in the banner.
 * @param {string} props.description -  description text to be displayed in the banner.
 * @param {bool} props.closable - A boolean indicating whether the banner can be closed.
 * @param {Object} props.ctas - An array of objects representing ctas in the banner.
 * @param {string} props.type - type of the banner, which affects its background color (e.g.,cc "warning", "success", "danger").
 * @param {function} props.handleCloseBanner -  function to be called when the banner is closed.
 */

const Banner = ({
  id,
  title,
  description,
  closable,
  ctas,
  type,
  handleCloseBanner,
  classes = "",
}) => {
  return (
    <div
      className={`px-8 py-6 flex items-center mb-3 rounded-b-3xl -mt-9 relative justify-between ${BANNER_COLORS[type]} ${classes} `}
    >
      {closable ? (
        <Icon
          name="Close"
          className="absolute text-white cursor-pointer right-6 top-6"
          handleClick={handleCloseBanner}
        />
      ) : null}
      <div className="flex flex-col gap-1 px-5">
        <Text
          classes="text-white text-lg font-semibold"
          translationKey={title}
        />
        <Text
          classes="text-white text-base font-medium"
          translationKey={description}
        />
      </div>
      {ctas?.length ? (
        <div className="flex gap-2 mr-9 ">
          {ctas
            ?.sort((a, b) => b.order - a.order)
            ?.map((cta, index) => {
              return (
                <Button
                  key={`banners-cta-${index}`}
                  label={cta.label}
                  onClick={() => cta.handleClick(id)}
                  variant={type ?? "danger"}
                  type={cta?.isPrimary ? "default" : "secondary"}
                  classes={` w-[134px] h-8  ${cta?.outerClasses} `}
                  labelStyleClasses={`text-xs font-bold ${cta?.classes}`}
                />
              );
            })}
        </div>
      ) : null}
    </div>
  );
};
Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  closable: PropTypes.bool,
  ctas: PropTypes.array,
  type: PropTypes.string,
  handleCloseBanner: PropTypes.func,
  id: PropTypes.string,
  classes: PropTypes.string,
};

export default Banner;
