import { PropTypes } from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";

import { clientSelector } from "@/store/selectors/client";

import Text from "@/components/core/Text";

import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import { amountToCurrency, numberFormatter } from "@/utils/common";

import { VP_ERROR_COMPONENT_TYPE } from "@/constants/common";

export default function OverviewDetails({ data, currency, page }) {
  const clientInfo = useSelector(clientSelector);
  const isCashbackEnabled = clientInfo?.cashbackEnabled;

  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;
  // Expenses:fetchExpenseDetails
  const obj = {
    apiKey: "Expenses:fetchExpenseDetails",
    error: {
      type: VP_ERROR_COMPONENT_TYPE.COMPACT,
    },
  };
  const allState1 = useLoadingErrorInjector(obj);
  const allState2 = useLoadingErrorInjector(obj);
  const allState3 = useLoadingErrorInjector(obj);
  const allState = useMemo(() => [allState1, allState2, allState3], []);

  const config = [
    {
      title: "expenses.overview.expenseCardDetails.totalAmount",
      isAmount: true,
      key: "totalAmount",
    },
    {
      title: "expenses.overview.expenseCardDetails.totalCashback",
      isAmount: true,
      key: "cashbacks",
    },
    {
      title: isExpensesPage
        ? "expenses.overview.expenseCardDetails.totalExpenses"
        : "QRPay.payments.overview.totalSpends",
      isAmount: false,
      key: isExpensesPage ? "totalExpense" : "totalSpends",
    },
  ];

  const updatedConfig = useMemo(() => {
    return isCashbackEnabled
      ? config
      : config?.filter((item) => item?.key !== "cashbacks");
  }, [isCashbackEnabled]);

  return (
    <>
      {/* {isFetching && <OverviewDetailsLoader />} */}
      {/* {!isFetching && data ? ( */}
      <div className="flex h-full gap-5">
        {/* Fix Me:- Change amount currency and value to actual api response */}

        {updatedConfig?.map(
          (val, index) => (
            // data[val.key] ? (
            <div
              ref={allState[index].attach}
              key={`expense-overview-details-${index}`}
              className="flex flex-col items-start px-3 py-4 border cursor-default min-w-17 border-neutral-200 rounded-xl"
            >
              <div>
                <Text
                  color="neutral-500"
                  classes="text-sm font-semibold"
                  translationKey={val.title}
                />
                <div>
                  <span className="text-xl font-semibold ">
                    {val.isAmount
                      ? amountToCurrency(data?.[val.key])
                      : numberFormatter(data?.[val.key])}
                  </span>
                  {val.isAmount ? (
                    <span className="ml-1 text-base font-semibold text-neutral-500">
                      {currency}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          )
          // ) : null
        )}
      </div>
      {/* ) : null} */}
    </>
  );
}
OverviewDetails.propTypes = {
  data: PropTypes.object,
  currency: PropTypes.string,
  page: PropTypes.string,
};
