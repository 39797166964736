import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// 'utils' import

// 'store' imports
import {
  blockCard,
  fetchAndSelectCard,
  freezeCard,
  setIsCardOperationProcessing,
  setSelectedCard,
  unfreezeCard,
} from "@/store/reducers/cards";
import { fetchUserPhysicalCard } from "@/store/reducers/user";

import {
  isCardOperationProcessingSelector,
  selectedCardSelector,
} from "@/store/selectors/cards";
import {
  currentUserPhysicalCardSelector,
  userSelector,
} from "@/store/selectors/user";

// 'components/core' imports
import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";
import { camelToSnake } from "@/utils/common";

// 'constants' imports
import {
  CARDS_ALL_SLIDER_MENU_KEYS,
  CARD_STATUS_OPERATIONS_MAPPING,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function CardOperationReasonSlider({ operation }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const cardId = searchParams.get(SLIDERS_SEARCH_PARAMS.cards[operation]);
  const myCardInfo = useSelector(currentUserPhysicalCardSelector);
  const myCardBudgetId = myCardInfo?.cardBudgetId;
  const isCardOperationProcessing = useSelector(
    isCardOperationProcessingSelector
  );
  const card = useSelector(selectedCardSelector);
  const cardBudgetId = card?.cardBudgetId;
  const currentUser = useSelector(userSelector);

  const CARD_OPERATION_TEXTS = {
    freeze: {
      heading: "cards.freezeCards.heading",
      subText: "cards.freezeCards.headingSubText",
      reason: "cards.freezeCards.secondaryHeading",
      reasonSubText: "cards.freezeCards.subheading",
      placeholder: "cards.freezeCards.placeholder",
      inputLabel: "cards.freezeCards.inputLabel",
      footerText: "cards.freezeCards.footerText",
    },
    unfreeze: {
      heading: "cards.unfreezeCards.heading",
      subText: "cards.unfreezeCards.headingSubText",
      reason: "cards.unfreezeCards.secondaryHeading",
      reasonSubText: "cards.unfreezeCards.subheading",
      placeholder: "cards.unfreezeCards.placeholder",
      inputLabel: "cards.unfreezeCards.inputLabel",
    },
    block: {
      heading: "cards.blockCard.heading",
      subText: "cards.blockCard.headingSubText",
      reason: "cards.blockCard.secondaryHeading",
      reasonSubText: "cards.blockCard.subheading",
      placeholder: "cards.blockCard.placeholder",
      inputLabel: "cards.blockCard.inputLabel",
      footerText: "cards.blockCard.footerText",
      footerTextClasses: "text-danger-400",
    },
  };
  const currentOperation = CARD_OPERATION_TEXTS[operation];
  const [checked, setChecked] = useState(!currentOperation?.footerText);
  const initialFormValue = {
    reason: {
      value: "",
      validate: {
        required: true,
      },
      errorStatement: {
        required: "cards.cardDetails.editLimitDetails.errorStatement",
      },
    },
  };
  const onCancel = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards[operation]);
    setSearchParams(searchParams);
  };

  const onSuccess = () => {
    dispatch(setSelectedCard(null));
    dispatch(fetchAndSelectCard({ cardId }));
    dispatch(fetchUserPhysicalCard({ id: currentUser?.id }));
    dispatch(setIsCardOperationProcessing(false));
    onCancel();
  };

  const onSave = () => {
    dispatch(setIsCardOperationProcessing(true));

    const payload = {
      ...values,
      card_budget_id: cardBudgetId ?? myCardBudgetId,
      request_type: CARD_STATUS_OPERATIONS_MAPPING[operation],
    };

    switch (operation) {
      case CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD:
        dispatch(freezeCard({ cardId, payload, onSuccess }));
        break;
      case CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE:
        dispatch(unfreezeCard({ cardId, payload, onSuccess }));
        break;
      case CARDS_ALL_SLIDER_MENU_KEYS.BLOCK:
        dispatch(blockCard({ cardId, payload, onSuccess }));
        break;
      default:
        break;
    }
  };
  const {
    handleChange,
    values,
    _setValues: setValues,
    errors,
    handleSubmit,
  } = useForm(initialFormValue, onSave);

  // card_budget_id, reason, request_type

  return (
    <div>
      <div className="slider-content-core">
        <form
          onSubmit={handleSubmit}
          id="card-reason-slider"
          className="flex flex-col gap-7 "
        >
          <section className="flex flex-col gap-1">
            <Text
              classes="font-bold text-3xl"
              translationKey={currentOperation.heading}
            />
            <Text
              classes="font-medium text-sm text-neutral-500"
              translationKey={currentOperation.subText}
            />
          </section>
          <section className="flex flex-col gap-1">
            <Text
              classes="font-medium text-xl"
              translationKey={currentOperation.reason}
            />
            <Text
              classes="font-medium text-sm text-neutral-500"
              translationKey={currentOperation.reasonSubText}
            />
          </section>
          <section className="flex flex-col gap-1 my-5 ">
            <Input
              placeholder={currentOperation.placeholder}
              onChange={handleChange}
              value={values?.reason}
              isFocus
              classes="flex items-center justify-between cursor-pointer text-base font-medium"
              name="reason"
              label={currentOperation.inputLabel}
              labelExtraClasses="text-xs font-semibold"
              error={errors?.reason}
            />
          </section>
        </form>
      </div>
      <div className="fixed px-3 py-5 slider-footer">
        <div
          className={`flex items-center ${
            currentOperation?.footerText ? "justify-between" : "justify-end"
          } gap-3`}
        >
          {currentOperation?.footerText ? (
            <Checkbox
              label={currentOperation?.footerText}
              classes={`text-sm px-2 ${currentOperation?.footerTextClasses}`}
              onClickHandler={() => setChecked(!checked)}
            />
          ) : (
            ""
          )}
          <div className="flex gap-3">
            <Button
              label="cards.vCards.createCardSlider.cancel"
              classes="w-16 text-neutral-500 font-medium"
              variant="tertiary"
              onClick={onCancel}
              disabled={isCardOperationProcessing}
            />
            <Button
              label="cards.cardDetails.editCardDetails.CTALabels.submit"
              classes="w-16 text-white font-medium"
              variant="primary"
              btnType="submit"
              form="card-reason-slider"
              disabled={isCardOperationProcessing || !checked}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardOperationReasonSlider;
CardOperationReasonSlider.propTypes = {
  operation: PropTypes.string, // freeze , unfreeze , block
};
