import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { SUBMISSION_POLICY_STATUSES } from "@/utils/constants/common";

function SubmissionPolicyIcon({ value, index }) {
  const config = SUBMISSION_POLICY_STATUSES[value.toUpperCase()];

  return (
    <div>
      <div id={`submission-policy-icon-${index}`}>
        <Icon name={config?.icon} className={config?.class} />
      </div>
      <Tooltip id={`submission-policy-icon-${index}`} direction="bottom">
        <Text translationKey={config?.label} />
      </Tooltip>
    </div>
  );
}

export default SubmissionPolicyIcon;
SubmissionPolicyIcon.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number,
};
