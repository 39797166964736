import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function SliderDataCard({
  nameKey,
  value,
  onLinkClick = () => {},
  noLink = false,
  nameTooltipText = "",
  valueTooltipText = "",
  extraValue,
}) {
  return (
    <div className="p-4 flex flex-col border border-neutral-200 rounded-[theme(spacing.2)]">
      <div className="flex items-center gap-1">
        <Text
          translationKey={nameKey}
          classes="text-sm text-neutral-500 font-semibold h-6 flex items-center"
        />
        {nameTooltipText && (
          <span id={nameKey}>
            <Icon
              name="Info"
              className="text-neutral-500 p-[1.5px] h-5 w-5 hover:cursor-pointer"
            />
            <Tooltip id={nameKey} direction="bottom">
              <Text
                translationKey={nameTooltipText}
                classes="text-sm font-medium text-neutral-800"
              />
            </Tooltip>
          </span>
        )}
      </div>
      <div className="flex items-center gap-1">
        <span className="text-base font-semibold text-neutral-800">
          {value}
        </span>
        {valueTooltipText && (
          <span id={valueTooltipText}>
            <Icon
              name="Info"
              className="text-neutral-500 p-[1.5px] h-5 w-5 hover:cursor-pointer"
            />
            <Tooltip id={valueTooltipText} direction="bottom">
              <Text
                translationKey={valueTooltipText}
                classes="text-sm font-medium text-neutral-800"
              />
            </Tooltip>
          </span>
        )}
        {!noLink && (
          <Icon
            handleClick={onLinkClick}
            name="ToLink"
            className="ml-2 text-primary-500 hover:cursor-pointer"
          />
        )}
      </div>
      {extraValue}
    </div>
  );
}

SliderDataCard.propTypes = {
  nameKey: PropTypes.string,
  value: PropTypes.string,
  onLinkClick: PropTypes.func,
  noLink: PropTypes.bool,
  nameTooltipText: PropTypes.string,
  valueTooltipText: PropTypes.string,
  extraValue: PropTypes.elementType,
};
