import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { CARD_TYPE, CARD_USAGE_TYPES } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

/**
 * Renders a card usage type component, displaying the card's name, type, usage, and last four digits.
 * It also includes an icon that allows navigation when clicked.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.cardName - The name of the card.
 * @param {string} props.cardType - The type of the card, either virtual or physical.
 * @param {string} props.cardUsageType - The usage type of the card, either international or domestic.
 * @param {string} [props.cardNumber] - The card number, used to display the last four digits.
 * @param {number} props.cardId - The unique identifier of the card.
 *
 * @returns {JSX.Element} The rendered CardUsageType component.
 */
export default function CardUsageType({
  cardName,
  cardType,
  cardUsageType,
  cardNumber,
  cardId,
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const titleTranslationKey =
    cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
      ? "expenses.tableCells.cardUsageType.internationalCardTitle"
      : "expenses.tableCells.cardUsageType.nonInternationalCardTitle";

  const titleTranslationProp = {
    cardName:
      cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL
        ? cardName?.toLowerCase()
        : cardName,
  };

  const formattedCardNumber = cardNumber?.slice(-4);

  function handleCardIconClick() {
    searchParams.append(SLIDERS_SEARCH_PARAMS.cards.id, cardId);
    setSearchParams(searchParams);
  }

  const cardTypeText =
    cardType === CARD_TYPE.VIRTUAL
      ? "expenses.tableCells.cardUsageType.virtualCardTypeText"
      : "expenses.tableCells.cardUsageType.physicalCardTypeText";

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-col justify-start">
        <Text
          translationKey={titleTranslationKey}
          translationProps={titleTranslationProp}
          classes="text-sm font-semibold text-neutral-800 text-left"
        />

        <div className="flex flex-row gap-4">
          <Text
            translationKey={cardTypeText}
            classes="text-xs font-medium text-neutral-500"
          />

          {formattedCardNumber ? (
            <div className="flex flex-row items-center">
              <span className="text-xs font-medium profile-widget-dot text-neutral-500" />

              <Text
                translationKey={formattedCardNumber}
                classes="text-xs font-medium text-neutral-500"
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex items-center justify-center rounded cursor-pointer w-7 h-7 text-neutral-300 hover:text-neutral-500 hover:bg-neutral-200">
        <Icon name="Person" handleClick={handleCardIconClick} />
      </div>
    </div>
  );
}

CardUsageType.propTypes = {
  cardName: PropTypes.string,
  cardType: PropTypes.string,
  cardUsageType: PropTypes.string,
  cardNumber: PropTypes.string,
  cardId: PropTypes.number,
};
