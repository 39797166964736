import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { deleteCustomRole, fetchRoles } from "@/store/reducers/company";

import { isLoadingPermissionsSelector } from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function DeleteRoleModal({ showModal, setShowModal }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const onCloseModal = () => {
    setShowModal(false);
  };
  const isLoading = useSelector(isLoadingPermissionsSelector);
  const roleId = parseInt(
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.rolesPermissions.role),
    10
  );

  const initialValue = {
    deleteText: {
      value: "",
      validate: {
        required: true,
        regex: /\bDELETE\b/,
      },
      errorStatement: {
        regex: "company.rolesPermissions.deleteRole.invalidInput",
      },
    },
  };

  const handleDelete = () => {
    const onSuccess = () => {
      dispatch(fetchRoles());
      onCloseModal();
      searchParams.delete(SLIDERS_SEARCH_PARAMS.company.rolesPermissions.role);
      setSearchParams(searchParams);
    };
    dispatch(
      deleteCustomRole({
        id: roleId,
        onSuccess,
      })
    );
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, () => {
      handleDelete();
    });

  return (
    <Modal
      onClose={onCloseModal}
      open={showModal}
      className="flex items-center justify-center z-[1000]"
    >
      <div className="flex flex-col gap-2 px-6 py-2 bg-white w-115 h-fit rounded-2xl">
        <div className="flex flex-col">
          <Text
            translationKey="company.rolesPermissions.deleteRole.areYouSureToDelete.title"
            classes="text-lg font-bold text-neutral-800 py-2"
          />
        </div>
        <div className="flex flex-col">
          <div className="py-2">
            <Text
              translationKey="company.rolesPermissions.deleteRole.important"
              classes="text-xs font-bold text-danger-500"
            />
          </div>
          <div className="flex justify-start w-11/12 py-2">
            <Text
              translationKey="company.rolesPermissions.deleteRole.areYouSureToDelete.description"
              classes="text-base font-semibold text-neutral-800"
            />
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          id={`delete-custom-role-${roleId}`}
          className="py-4"
        >
          <Input
            name="deleteText"
            label="company.rolesPermissions.deleteRole.deleteHelpText"
            value={values?.deleteText}
            error={errors?.deleteText}
            onChange={handleChange}
            classes="text-sm text-neutral-800"
            labelExtraClasses=" font-semibold text-neutral-500"
          />
        </form>
        <div className="flex items-center gap-6 py-8">
          <Button
            label="accounting.integrations.buttonLabel.cancel"
            classes="px-5 py-3 text-base font-semibold text-neutral-500"
            variant="tertiary"
            onClick={onCloseModal}
          />
          <Button
            label="company.rolesPermissions.buttonLabels.yesDelete"
            type="danger"
            classes="px-5 py-3 text-base font-semibold "
            btnType="submit"
            showLoader={isLoading}
            form={`delete-custom-role-${roleId}`}
            disabled={isFormButtonDisabled}
          />
        </div>
      </div>
    </Modal>
  );
}

DeleteRoleModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};
