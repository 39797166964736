import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getActiveSessions,
  logout,
  removeSession,
} from "@/store/reducers/user";

import { activeSessionsSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import { dateToTimeStampString } from "@/utils/common";

import MainPageHeader from "./MainPageHeader";

export default function LoginExceeded() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeSessions = useSelector(activeSessionsSelector);
  const [onMount, setOnMount] = useState(false);

  useEffect(() => {
    dispatch(getActiveSessions({}));
    setOnMount(true);
  }, []);

  useEffect(() => {
    let interval = null;
    if (onMount)
      interval = setInterval(() => {
        dispatch(getActiveSessions({}));
      }, 30000);
    return () => {
      if (onMount) clearInterval(interval);
    };
  }, [onMount]);

  const handleLogout = (id) => {
    dispatch(removeSession({ client_id: id }));
  };
  return (
    <div>
      <MainPageHeader
        title="login.loginLimit"
        subtitle="login.loginLimitDesc"
        handleBack={() => {
          dispatch(logout({ navigate }));
        }}
        showBack
      />
      <Alert
        wrapperDivClass="justify-start"
        classes="mt-10"
        variant="warning"
        title="login.loginLimitAlert"
        description="login.loginLimitAlertDesc"
      />
      {activeSessions?.map((session, id) => (
        <div
          className="flex items-center justify-between gap-6 p-4 mt-10 bg-neutral-50 rounded-xl "
          key={`current-session-${id}`}
        >
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 m-1">
              <Icon name="Pace" className="w-6 h-6 text-neutral-500" />
            </div>
            <div className="flex flex-col gap-1">
              <div className="font-semibold">{session?.name}</div>
              <div className="text-sm font-medium text-neutral-600">
                Last login: {dateToTimeStampString(session?.lastSignInAt)}
              </div>
            </div>
          </div>
          <Button
            label="login.logout"
            classes="w-auto h-auto px-5 py-1 text-xs "
            type="danger"
            onClick={() => handleLogout(session?.clientId)}
          />
        </div>
      ))}
    </div>
  );
}
