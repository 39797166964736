import PropTypes from "prop-types";
import React from "react";
import { useSearchParams } from "react-router-dom";

import Button from "@/components/core/Button";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function RequestHistoryButton({ context }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Button
      classes="text-neutral-500 font-semibold w-17 px-0 "
      preIcon="ListAlt"
      label="myVolopay.actionCentre.buttons.requestHistory"
      type="default"
      variant="tertiary"
      onClick={() => {
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.myVolopay.actionCentre.requestHistory,
          context
        );
        setSearchParams(searchParams);
      }}
    />
  );
}

RequestHistoryButton.propTypes = {
  context: PropTypes.string,
};

export default RequestHistoryButton;
