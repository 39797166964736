import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { analyticsBulkExport } from "@/store/reducers/analytics";

import {
  analyticsEntitesSelector,
  analyticsExportDataSelector,
  isExportingAnalyticsTransactionSelector,
} from "@/store/selectors/analytics";
import { accountsSelector } from "@/store/selectors/app";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import DateRangeFilter from "@/components/core/Filters/DateRangeFilter";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import SelectDuration from "@/components/Accounting/Ledger/Sliders/RequestStatementSlider/SelectDuration";
import {
  ANALYTICS_EXPORT_FORMATS,
  EXPORT_TRANSACTION_TYPE,
} from "@/components/Analytics/common/enum";
import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
} from "@/utils/constants/filters";
import { capitalizeFirstLetter, subdomain } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function ExportSlider() {
  const entities = useSelector(analyticsEntitesSelector);
  const exportDetails = useSelector(analyticsExportDataSelector);
  const isLoading = useSelector(isExportingAnalyticsTransactionSelector);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const itemRef = useLeftHeaderTitle({
    title: "analytics.export.heading",
  });

  const [entityOptions, setEntityOptions] = useState(entities);
  const [subdomainPayload, setSubdomainPayload] = useState([]);
  const [allEntitesSelected, setAllEntitiesSelected] = useState(false);
  const [duration, setDuration] = useState("");
  const [format, setFormat] = useState("");

  const multiSelectedMode = exportDetails?.selectedTransactions?.length;
  const currentSubdomain = subdomain();

  const exportHandler = () => {
    const onSuccess = () => {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.analytics.export);
      setSearchParams(searchParams);
    };
    const payload = {
      after: duration?.from,
      before: duration?.to,
      template: format?.value,
      transaction_type: EXPORT_TRANSACTION_TYPE[exportDetails?.transactionType],
      ...(exportDetails?.deSelectedIds.length
        ? { except_ids: exportDetails?.deSelectedIds }
        : {}),
      export_type:
        subdomainPayload?.length === 1 || multiSelectedMode
          ? "single_client"
          : "multi_client",
      ...(subdomainPayload?.length === 1 && !allEntitesSelected
        ? { subdomain: subdomainPayload?.map((data) => data?.value) }
        : { subdomains: subdomainPayload?.map((data) => data?.value) }),

      accounting_ids: exportDetails?.bulkSelectAll
        ? []
        : exportDetails?.selectedTransactions,
    };

    dispatch(analyticsBulkExport({ payload, onSuccess }));
  };

  useEffect(() => {
    if (multiSelectedMode) {
      setSubdomainPayload([
        {
          label: currentSubdomain,
          value: currentSubdomain,
        },
      ]);
      setAllEntitiesSelected(false);
    }
  }, [multiSelectedMode]);

  useEffect(() => {
    if (entities?.length) {
      setEntityOptions(
        entities?.map((data) => {
          return {
            label: data?.subdomain,
            value: data?.subdomain,
          };
        })
      );
    }
  }, [entities]);

  useEffect(() => {
    if (!multiSelectedMode)
      setAllEntitiesSelected(
        subdomainPayload?.length === entityOptions?.length
      );
  }, [subdomainPayload, multiSelectedMode]);

  return (
    <>
      <div className="slider-content-container">
        <div className="flex flex-col gap-9 slider-content-core px-9 ">
          <div ref={itemRef} className="flex flex-col">
            <Text
              classes="font-bold text-3xl"
              translationKey="analytics.export.heading"
            />
            <Text
              classes="font-medium text-neutral-500 text-sm"
              translationKey="analytics.export.desc"
            />
          </div>
          {multiSelectedMode ? null : (
            <div className="flex flex-col gap-6">
              <Text
                classes="font-semibold text-xl"
                translationKey="analytics.export.entitiesHeading"
              />

              <div className="flex flex-wrap gap-6">
                <Checkbox
                  label="analytics.export.allEntities"
                  checked={allEntitesSelected}
                  onClickHandler={(val) => {
                    if (val) {
                      setSubdomainPayload(entityOptions);
                    } else {
                      setSubdomainPayload([]);
                    }

                    setAllEntitiesSelected(val);
                  }}
                />
                {entityOptions?.map((data, id) => (
                  <Checkbox
                    key={id}
                    label={capitalizeFirstLetter(data?.label)}
                    checked={
                      allEntitesSelected ||
                      subdomainPayload?.find(
                        (singleSubdomain) =>
                          singleSubdomain.label === data.label
                      )
                    }
                    onClickHandler={(val) => {
                      if (val) {
                        setSubdomainPayload((prev) => [...prev, data]);
                      } else {
                        setSubdomainPayload((prev) =>
                          prev?.filter(
                            (singleSubdomain) =>
                              singleSubdomain?.label !== data?.label
                          )
                        );
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {multiSelectedMode ? null : (
            <div className="flex flex-col w-full gap-9">
              <Text
                classes="font-semibold text-xl"
                translationKey="analytics.export.durationHeading"
              />

              <SelectDuration onSelect={(object) => setDuration(object)} />
            </div>
          )}

          <div className="flex flex-col w-full gap-9">
            <Text
              classes="font-semibold text-xl"
              translationKey="analytics.export.formatHeading"
            />

            <VpSelect
              label="analytics.export.selectFormat"
              value={format?.value}
              optionsDisplayKey="label"
              valueKey="value"
              handleChange={(e) => {
                setFormat(e);
              }}
              options={ANALYTICS_EXPORT_FORMATS}
            />
          </div>

          <div className="mb-10">
            <Alert
              classes="border-neutral-300"
              wrapperDivClass="items-start border-neutral-200"
              descriptionClasses="font-normal text-neutral-500"
              title="analytics.export.alertHeading"
              description="analytics.export.alertDesc"
            />
          </div>
        </div>
      </div>
      <div className="px-5 py-4 slider-footer">
        <div className="flex justify-end gap-4">
          <Button
            onClick={() => {
              setSearchParams({});
            }}
            label="reimbursement.rejectPayment.cancel"
            variant="tertiary"
            classes="w-fit px-8 py-3 text-btn-lg font-semibold text-neutral-500"
            disabled={isLoading}
          />

          <Button
            onClick={exportHandler}
            variant="primary"
            label="analytics.export.exportCta"
            classes=" w-fit px-8 py-3 text-btn-lg font-semibold "
            showLoader={isLoading}
            disabled={
              multiSelectedMode
                ? !format
                : !subdomainPayload?.length || !duration || !format
            }
          />
        </div>
      </div>
    </>
  );
}

export default ExportSlider;
