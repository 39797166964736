import { SORTING_CATEGORY } from "./sorting";

export const NOT_OKAY = "not_okay";
export const AUTO_APPROVED = "auto_approved";

export const TRANSACTION_STATUS_BADGE = {
  APPROVED: {
    color: "success",
    text: "expenses.needsReview.badgeStatus.approved",
  },
  NEEDS_REVIEW: {
    color: "warning",
    text: "expenses.needsReview.badgeStatus.needsReview",
  },
  FLAGGED: {
    color: "danger",
    text: "expenses.needsReview.badgeStatus.flagged",
  },
};

export const AMOUNT_SLIDER_COLOR = {
  unsettled: "text-neutral-500",
  settled: "text-neutral-800",
};

export const DATA_TO_CONVERT_TYPE = {
  SPLIT: "split",
};
export const FLAGGED_EXPENSE_HEADER = [
  {
    label: "expenses.flagged.tableHeaders.date",
    haveArrow: true,
    class: "flex items-center gap-2 cursor-pointer justify-start",
    iconClass: "w-3 h-3",
    sortingCategory: SORTING_CATEGORY.DATE,
  },
  {
    label: "expenses.flagged.tableHeaders.amount",
    haveArrow: true,
    class:
      "flex items-center gap-2 flex-row-reverse  cursor-pointer justify-start",
    iconClass: "w-3 h-3",
    sortingCategory: SORTING_CATEGORY.AMOUNT,
  },
  { label: "expenses.overview.overviewTable.headers.card" },
  {
    label: "expenses.flagged.tableHeaders.cardHolder",
  },
  {
    label: "expenses.flagged.tableHeaders.flaggedBy",
  },
  {
    label: "QRPay.submissionPolicyStatus",
  },
  {
    label: "expenses.flagged.tableHeaders.receipt",
    class: "text-center",
  },
  {
    label: "expenses.flagged.tableHeaders.memo",
    class: "text-left",
  },
  {
    label: "expenses.flagged.tableHeaders.accountingCategory",
    isAccountingTag: true,
  },
  {
    label: "expenses.flagged.tableHeaders.accountingVendor",
    isAccountingTag: true,
  },
  {
    label: "expenses.flagged.tableHeaders.action",
    class: "text-center",
  },
];

export const DECLINES_TABLE_HEADERS = [
  {
    label: "expenses.declines.headers.date",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.DATE,
    className: "flex-row-reverse justify-end gap-2",
  },
  {
    label: "expenses.declines.headers.amount",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.AMOUNT,
    className: "justify-end gap-2",
  },
  { label: "expenses.declines.headers.card" },
  { label: "expenses.declines.headers.cardHolder" },
  { label: "expenses.declines.headers.declineReason" },
];

export const GST_RADIO_BUTTONS = [
  {
    label: "expenses.slider.gst.radioButtonLabels.yes",
    textClasses: "text-base font-semibold text-neutral-500",
    isChecked: (isGstApplied) => isGstApplied,
    handleChange: (handleGstApplied) => handleGstApplied(true),
  },
  {
    label: "expenses.slider.gst.radioButtonLabels.no",
    textClasses: "text-base font-semibold text-neutral-500",
    isChecked: (isGstApplied) => !isGstApplied,
    handleChange: (handleGstApplied) => handleGstApplied(false),
  },
];
