import PropTypes from "prop-types";

import Carousel from "@/components/core/Carousel";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";

import { ACCOUNTING_SOFTWARE_CONFIG } from "@/constants/accounting";

export default function OtherIntegrationsCarousel({ excludeSoftware }) {
  const caraouselData = ACCOUNTING_SOFTWARE_CONFIG.map((item, index) =>
    !item.label.includes(excludeSoftware) ? (
      <div
        className="flex items-center gap-4 p-6 border rounded-lg w-75 h-14 border-neutral-200"
        key={`accounting-integration-carousel-item-${index}`}
      >
        <Icon
          name={item.icon}
          className="w-10 h-10 rounded-full overflow-hidden !bg-white text-white bg-no-repeat bg-center bg-contain relative border border-neutral-200 p-0.5"
        />
        <Text translationKey={item.label} classes="text-sm font-semibold" />
      </div>
    ) : null
  );
  return (
    <div className="flex flex-col justify-center gap-6">
      <SliderHeader
        heading="accounting.integrations.labels.otherIntegrations"
        headingClasses="text-xl font-semibold text-neutral-800"
      />
      <Carousel>{caraouselData?.filter((data) => data)}</Carousel>
    </div>
  );
}

OtherIntegrationsCarousel.propTypes = {
  excludeSoftware: PropTypes.string,
};
