import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchPeopleShallow,
  fetchShallowDepartments,
  fetchShallowLocations,
} from "@/store/reducers/company";
import { invite } from "@/store/reducers/user";

import {
  allDepartmentSelector,
  allLocationSelector,
  allPeopleSelector,
} from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import MultipleInput from "@/components/core/MultipleInput";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import { MULTIPLE_INPUT_CONFIG } from "@/utils/constants/multipleInput";
import { formatNames } from "@/utils/common";
import { REGEX_VALUE } from "@/utils/constantUseForm";
import {
  INVITE_PEOPLE_FIELD_TYPES,
  INVITE_PEOPLE_TYPES,
  INVITE_PEOPLE_TYPES_SLIDER_CONFIG,
  INVITE_TYPE_MAP,
} from "@/constants/company";
import { ROLE_TITLES } from "@/constants/user";

import { SLIDERS_SEARCH_PARAMS } from "../../../constants/SearchParams";

export default function InviteUser() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const allUser = useSelector(allPeopleSelector);
  const allUserEmail = new Set(
    allUser?.filter((i) => i?.email)?.map((item) => item.email?.toLowerCase())
  );
  const [updateValue, setUpdateValue] = useState(false);
  const defaultValue = INVITE_PEOPLE_TYPES_SLIDER_CONFIG[
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.inviteUser)
  ]?.fields?.reduce((accumulator, value) => {
    if (value === "email") return { ...accumulator, [value]: [] };
    return { ...accumulator, [value]: undefined };
  }, {});

  const [inviteUserType, setInviteUserType] = useState(
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.inviteUser)
  );
  const showSkipKycCheckbox = [
    INVITE_PEOPLE_TYPES.MEMBER,
    INVITE_PEOPLE_TYPES.ADMIN,
  ].includes(inviteUserType);
  const [inviteBox, setInviteBox] = useState([defaultValue]);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [isInviteDisabled, setIsInviteDisabled] = useState(true);
  const [errorArray, setErrorArray] = useState([false]);
  const [isEmptyArray, setIsEmptyArray] = useState([true]);

  const selectLocation = useSelector(allLocationSelector);
  const selectDepartment = useSelector(allDepartmentSelector);

  const validate = (text, arr, index = null) => {
    let key = "NORMAL";
    if (!REGEX_VALUE.email.test(text)) {
      key = "ERROR";
    }
    if (arr.includes(text) || allUserEmail.has(text?.toLowerCase()))
      key = "DUPLICATE";
    inviteBox.forEach((item, idx) => {
      item.email.forEach((eml) => {
        if (index !== idx) if (eml === text) key = "DUPLICATE";
      });
    });
    return key;
  };

  const getLocationId = (name) => {
    return selectLocation?.list?.find((item) => item.name === name)?.id;
  };

  const getProjectId = (name) => {
    return selectDepartment?.list?.find((item) => item.name === name)?.id;
  };

  const handleClick = () => {
    setInviteBox([...inviteBox, defaultValue]);
    setErrorArray([...errorArray, false]);
    setIsEmptyArray([...isEmptyArray, true]);
  };

  const handleChange = (values, index) => {
    setInviteBox((prev) => {
      return prev.map((it, idx) =>
        idx === index ? { ...it, email: values } : it
      );
    });
    if (!values?.length) {
      setErrorArray((prev) =>
        prev.map((item, id) => {
          return id === index;
        })
      );
    }
  };

  const handleSkipKycField = (index) => {
    inviteBox[index][INVITE_PEOPLE_FIELD_TYPES.SKIP_KYC] =
      !inviteBox[index]?.[INVITE_PEOPLE_FIELD_TYPES.SKIP_KYC];
    setInviteBox([...inviteBox]);
  };

  const handleClose = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.inviteUser);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.mainInvite);
    setSearchParams(searchParams);
  };

  const handleSubmit = () => {
    const result = [];
    inviteBox.forEach((item) => {
      item.email.forEach((email) => {
        const inviteObj = {
          email,
          role: INVITE_TYPE_MAP[inviteUserType],
          department_id: item.department,
          location_id: item.location,
          skip_kyc: item?.[INVITE_PEOPLE_FIELD_TYPES.SKIP_KYC] ?? false,
        };
        result.push(inviteObj);
      });
    });

    dispatch(invite({ people: result, onSuccess: handleClose }));
  };

  const handleDisable = () => {
    if (errorArray.length === 0) {
      setIsAddDisabled(false);
      setIsInviteDisabled(true);
      return;
    }
    let flag = false;
    errorArray.forEach((it) => {
      if (it === true) {
        flag = true;
      }
    });
    setIsInviteDisabled(flag);

    isEmptyArray.forEach((it) => {
      if (it === true) flag = true;
    });

    setIsAddDisabled(flag);
  };

  const handleErrors = (err, index) => {
    setErrorArray((prev) =>
      prev.map((item, id) => {
        return id === index ? err : item;
      })
    );
  };

  const handleEmptyErrors = () => {
    inviteBox.forEach((lst, index) => {
      if (inviteUserType === ROLE_TITLES.ACCOUNTANT && lst?.email?.length) {
        setIsEmptyArray((prev) =>
          prev.map((it, idx) => (idx === index ? false : it))
        );
      } else if (!lst.department || !lst.location) {
        setIsEmptyArray((prev) =>
          prev.map((it, idx) => (idx === index ? true : it))
        );
      } else {
        setIsEmptyArray((prev) =>
          prev.map((it, idx) => (idx === index ? false : it))
        );
      }
    });
  };

  useEffect(() => {
    handleEmptyErrors();
  }, [inviteBox]);

  useEffect(() => {
    handleDisable();
  }, [errorArray, isEmptyArray]);
  useEffect(() => {
    setInviteUserType(
      searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.inviteUser)
    );
    dispatch(fetchShallowLocations());
    dispatch(fetchShallowDepartments());
    dispatch(fetchPeopleShallow({ invited_check: true }));
  }, []);

  return (
    <>
      <div className="px-6 slider-content-core">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col mb-6">
            <span className="text-3xl font-bold">
              <Text translationKey="company.people.invite.title" />{" "}
              <Text
                translationKey={
                  INVITE_PEOPLE_TYPES_SLIDER_CONFIG[inviteUserType]?.title
                }
              />
            </span>
            <span className="text-sm font-medium text-neutral-500">
              <Text
                translationKey={
                  INVITE_PEOPLE_TYPES_SLIDER_CONFIG[inviteUserType]?.description
                }
              />
            </span>
          </div>
          {inviteBox?.map((lst, index) => (
            <div
              key={`invite-member-${index}`}
              className="flex flex-col gap-5 px-4 py-6 rounded-lg bg-neutral-50 border-neutral-200"
            >
              <div>
                <div className="flex items-center">
                  <div className="w-full mr-4">
                    {Object.keys(lst)?.map((field) => {
                      switch (field) {
                        case "email":
                          return (
                            <MultipleInput
                              uniqueId={index}
                              emptyErrorMessage="form.errorStatements.emptyEmails"
                              label="user.bankDetails.email"
                              handlerKeys={[" ", "Enter", ","]}
                              values={inviteBox[index]?.email}
                              updateValue={updateValue}
                              handleChange={(values) => {
                                handleChange(values, index);
                              }}
                              MultipleInputConfig={MULTIPLE_INPUT_CONFIG}
                              formatNamesOnBlur={formatNames}
                              validate={validate}
                              handleError={(err) => {
                                handleErrors(err, index);
                              }}
                              desc="company.people.invite.email.description"
                              descClasses="mr-4 text-xs font-medium text-neutral-500"
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                  </div>
                  <Icon
                    name="Delete"
                    className={`cursor-pointer ${
                      inviteBox[index]?.email?.length
                        ? "text-neutral-400"
                        : "text-neutral-300"
                    } `}
                    handleClick={
                      inviteBox?.length > 1
                        ? () => {
                            setInviteBox((prev) =>
                              prev.filter((invite_, idx) => index !== idx)
                            );
                            setErrorArray((prev) =>
                              prev.filter((err, idx) => index !== idx)
                            );
                            setIsEmptyArray((prev) =>
                              prev.filter((err, idx) => index !== idx)
                            );
                            setIsAddDisabled(false);
                            setUpdateValue((prev) => !prev);
                          }
                        : () => {}
                    }
                  />
                </div>
              </div>
              <div className="flex justify-between gap-4 mt-4">
                {Object.keys(lst)?.map((field) => {
                  switch (field) {
                    case "email":
                    case "id":
                    case INVITE_PEOPLE_FIELD_TYPES.SKIP_KYC:
                      return;
                    default:
                      return (
                        <VpSelect
                          classes="w-1/2"
                          label={field.charAt(0).toUpperCase() + field.slice(1)}
                          value={
                            field === "location"
                              ? inviteBox[index].location
                              : inviteBox[index].department
                          }
                          handleChange={(option) => {
                            setInviteBox(
                              inviteBox.map((ele, idx) =>
                                index === idx
                                  ? { ...ele, [field]: option.value }
                                  : ele
                              )
                            );
                          }}
                          valueKey="id"
                          optionsDisplayKey="label"
                          menuPosition="absolute"
                          placeholder=""
                          options={(field === "location"
                            ? selectLocation
                            : selectDepartment
                          )?.map((location) => {
                            return {
                              label: location?.name,
                              value: location?.id,
                              id: location?.id,
                            };
                          })}
                        />
                      );
                  }
                })}
              </div>

              {showSkipKycCheckbox ? (
                <div
                  className="flex flex-row items-center gap-4 cursor-pointer"
                  onClick={() => handleSkipKycField(index)}
                >
                  <Checkbox
                    checked={
                      inviteBox[index]?.[INVITE_PEOPLE_FIELD_TYPES.SKIP_KYC]
                    }
                  />

                  <div className="flex flex-col">
                    <Text
                      translationKey="company.people.invite.skipKyc.title"
                      classes="text-sm font-semibold"
                    />
                    <Text
                      translationKey="company.people.invite.skipKyc.description"
                      classes="text-sm font-medium text-neutral-500"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ))}

          <div className="flex justify-end mb-2">
            <Button
              disabled={isAddDisabled}
              preIcon="Add"
              classes="w-11 h-11 p-4 py-4"
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
      <div className="p-6 slider-footer ">
        <div className="flex flex-row-reverse gap-6 ">
          <Button
            disabled={isInviteDisabled || isAddDisabled}
            variant="primary"
            classes="text-btn-lg font-semibold py-4 w-[150px]  h-auto px-5"
            label="company.people.invite.title"
            onClick={handleSubmit}
          />
          <Button
            classes="text-btn-lg font-semibold py-4 w-[150px]  h-auto px-5"
            variant="secondary"
            label="misc.cancel"
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}
