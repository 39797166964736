import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import Text from "@/components/core/Text";

import UserDropdown from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/UserDropdown";
import {
  CATEGORY_KEYS_LABEL_MAPPING,
  generateRedirectionUrlForFilters,
} from "@/utils/constants/searchAndFilter";
import { arraysHaveSameContentUnordered } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";

import PaginatedPeopleTable from "../common/PaginatedPeopleTable";

export default function PeopleTabDept({
  departmentManagers,
  departmentMembers,
  people,
  handleSave,
  isFetching,
  navigate,
  showManageManagerCta,
  showManageMemberCta,
}) {
  const dispatch = useDispatch();
  const [isEditingDeptManager, setIsEditingDeptManager] = useState(false);
  const [editableManagers, setIsEditableManagers] =
    useState(departmentManagers);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setIsEditableManagers(departmentManagers);
  }, [departmentManagers.length]);

  const deptManagerSectionButtonHandler = () => {
    setIsEditingDeptManager(true);
  };
  const deptMemberSectionButtonHandler = () => {
    generateRedirectionUrlForFilters({
      parentRoute: ROUTES.manage.people.users.all.absolutePath,
      dispatch,
      categoryKey: CATEGORY_KEYS_LABEL_MAPPING.DEPARTMENT,
      categoryValues: searchParams.get(
        SLIDERS_SEARCH_PARAMS.company.department.id
      ),
      navigate,
    });
  };

  // edit state
  const [selectedManagers, setSelectedManagers] = useState([]);

  // derived state
  const isManagersEmpty = departmentManagers?.length === 0;
  const isMemberEmpty = departmentMembers?.length === 0;

  // form ctas
  const onDiscardHandler = (e) => {
    setIsEditingDeptManager(false);
    setIsEditableManagers(departmentManagers);
    setSelectedManagers([]);
  };
  const onSubmitHandler = (e) => {
    handleSave({
      managers: [
        ...(editableManagers?.map((item) => item.id) ?? []),
        ...selectedManagers,
      ],
    });
    onDiscardHandler(e);
  };

  // checkbox dropdown button handlers
  const onDeleteHandlerManager = (id) => {
    if (editableManagers?.length !== 1)
      setIsEditableManagers((prev) => prev.filter((item) => item.id !== id));
  };

  const dropdownSubmitHandlerManagers = ({
    selectedIds,
    selectedGroups,
    selectedIndividualIds,
    groupContents,
  }) => {
    setSelectedManagers(selectedIds);
  };
  const isSaveChangesDisabled = arraysHaveSameContentUnordered(
    [...(editableManagers?.map((item) => item.id) ?? []), ...selectedManagers],
    departmentManagers?.map((i) => i.id)
  );

  return (
    <div className="text-neutral-800 min-h-[570px]">
      {/* Top - title and button the right (below) */}
      {/* <div className="flex items-center justify-between">
        <Text
          translationKey="company.department.departmentDetails.tabs.people.title"
          classes="text-lg font-bold"
        />
      </div> */}

      {/* Department managers */}
      <div className="mt-6">
        {/* manager Section head */}
        <div
          aria-label="dept-managers-section-head"
          className="flex items-center justify-between gap-4"
        >
          <div aria-label="dept-managers-title-description-count">
            <div className="flex items-center gap-2">
              <Text
                translationKey="company.department.departmentDetails.tabs.people.departmentManagersTitle"
                classes="text-lg font-bold"
              />
              <Chip
                label={
                  (isEditingDeptManager ? editableManagers : departmentManagers)
                    ?.length ?? 0
                }
                classes="rounded-lg text-xs font-bold"
              />
            </div>
            <Text
              aria-label="managers-description-text"
              translationKey="company.department.departmentDetails.tabs.people.departmentManagersDescription"
              classes="block text-neutral-500 text-sm font-medium"
            />
          </div>
          {!isManagersEmpty && !isEditingDeptManager && showManageManagerCta ? (
            <Button
              label="company.department.departmentDetails.tabs.people.editDepartmentManagerLabel"
              variant="tertiary"
              iconClasses="text-xs"
              labelStyleClasses="text-xs font-bold"
              onClick={deptManagerSectionButtonHandler}
              compact
              classes="shrink-0 py-1.5"
              id="manage-people-tooltip"
            />
          ) : null}
        </div>

        {/* manager section content */}
        {/* manager dropdown */}
        {isEditingDeptManager ? (
          <div className="mt-6">
            <UserDropdown
              users={people
                ?.filter(
                  (item) => !editableManagers?.some(({ id }) => id === item.id)
                )
                ?.map((item) => ({
                  ...item,
                  name: item?.name,
                  value: item?.id,
                  label: item?.name,
                }))}
              dropdownLabelFastView=""
              selectedIdsArray={selectedManagers}
              handleSubmit={dropdownSubmitHandlerManagers}
              isLoading={isFetching}
              label="company.department.departmentDetails.tabs.people.addDepartmentManagers"
            />
          </div>
        ) : null}

        {/* manager preview (empty + otherwise state handled) */}
        <div className="mt-4">
          <PaginatedPeopleTable
            handleDelete={onDeleteHandlerManager}
            disabledDeleteButton={editableManagers?.length === 1}
            emptyLabel="company.department.departmentDetails.tabs.people.emptyState.dept.managers.title"
            emptyDescription="company.department.departmentDetails.tabs.people.emptyState.dept.managers.description"
            emptyBtnLabel={
              showManageManagerCta
                ? "company.department.departmentDetails.tabs.people.emptyState.dept.managers.buttonLabel"
                : null
            }
            people={
              isEditingDeptManager ? editableManagers : departmentManagers
            }
            handleEmptyBtnClick={deptManagerSectionButtonHandler}
            inEditMode={isEditingDeptManager}
            limit={5}
          />
        </div>

        {/* member preview */}

        {/* manager edit ctas */}
        {isEditingDeptManager ? (
          <div className="flex items-center gap-3 mt-4">
            <Button
              label="misc.cancel"
              variant="tertiary"
              classes="px-5 py-2 text-neutral-500 text-xs font-bold"
              onClick={onDiscardHandler}
              compact
            />
            <Button
              label="misc.saveChanges"
              btnType="submit"
              disabled={isSaveChangesDisabled}
              variant="primary"
              classes="px-5 py-2 text-white text-xs font-bold"
              compact
              onClick={onSubmitHandler}
            />
          </div>
        ) : null}
      </div>

      {/* Department members */}
      <div className="mt-12">
        {/* member section head */}
        <div
          aria-label="dept-members-section-head"
          className="flex items-center justify-between gap-4"
        >
          <div aria-label="dept-members-title-description-count">
            <div className="flex items-center gap-2">
              <Text
                translationKey="company.department.departmentDetails.tabs.people.departmentMembersTitle"
                classes="text-lg font-bold"
              />
              <Chip
                label={departmentMembers.length}
                classes="rounded-lg text-xs font-bold"
              />
            </div>
            <Text
              aria-label="members-description-text"
              translationKey="company.department.departmentDetails.tabs.people.departmentMembersDescription"
              classes="block text-neutral-500 text-sm font-medium"
            />
          </div>
          {!isMemberEmpty && showManageMemberCta ? (
            <Button
              label="company.department.departmentDetails.tabs.people.managePeopleLabel"
              variant="tertiary"
              iconClasses="text-xs"
              labelStyleClasses="text-xs font-bold"
              onClick={deptMemberSectionButtonHandler}
              compact
              classes="shrink-0 py-1.5"
              id="manage-people-tooltip"
            />
          ) : null}
        </div>

        {/* member preview */}
        <div className="mt-8">
          <PaginatedPeopleTable
            emptyLabel="company.department.departmentDetails.tabs.people.emptyState.dept.members.title"
            emptyDescription="company.department.departmentDetails.tabs.people.emptyState.dept.members.description"
            emptyBtnLabel={
              showManageMemberCta
                ? "company.department.departmentDetails.tabs.people.emptyState.dept.members.buttonLabel"
                : null
            }
            people={departmentMembers}
            inEditMode={false}
            handleEmptyBtnClick={deptMemberSectionButtonHandler}
            limit={5}
          />
        </div>
      </div>
    </div>
  );
}

PeopleTabDept.propTypes = {
  departmentManagers: PropTypes.array,
  departmentMembers: PropTypes.array,
  handleSave: PropTypes.func,
  people: PropTypes.array,
  isFetching: PropTypes.bool,
  navigate: PropTypes.any,
  showManageManagerCta: PropTypes.bool,
  showManageMemberCta: PropTypes.bool,
};
