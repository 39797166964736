import PropTypes from "prop-types";
import { useRef, useState } from "react";

import { encode } from "he";
import useOnClickOutside from "@/hooks/useClickOutside";

import Avatar from "@/components/core/Avatar";
import Button from "@/components/core/Button";
import EmojiPicker from "@/components/core/EmojiPicker";
import FileUpload from "@/components/core/FileUpload";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";

import { FILE_UPLOAD_VARAINTS } from "@/constants/common";
import { DEFAULT_ACCEPT_WITH_PDF } from "@/constants/fileViewer";

export default function CommentsInput({
  isVisible = true,
  currentUser,
  isDisabled,
  onSubmit,
}) {
  const [files, setFiles] = useState([]);

  const initialValue = {
    comment: {
      value: "",
      validate: {
        required: files?.length === 0,
      },
    },
  };
  const {
    handleChange,
    values,
    _setValues: setValues,
    errors,
    isFormButtonDisabled,
    handleSubmit,
  } = useForm(
    initialValue,
    (e) => {
      const formData = new FormData();
      const _values = {
        ...values,
        comment: encode(values.comment),
      };
      Object.entries(_values)?.forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (files?.length) {
        files?.forEach((file) => {
          formData.append("attachments[]", file);
        });
      }
      onSubmit(formData);
      setFiles([]);
      setValues(() => ({
        comment: "",
      }));
    },
    { needBtnDisable: true }
  );

  const primaryAction = {
    handler: () => {
      setFiles([]);
    },
    icon: "Delete",
  };

  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const disabled = files?.length > 0 || isDisabled;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setShowEmojiPicker(false));

  return isVisible ? (
    <form className="bg-white card-wrapper" onSubmit={handleSubmit}>
      {files.length > 0 && (
        <div className="flex items-center gap-2 p-2 rounded-lg mb-9 bg-neutral-50">
          {files[0].preview || files[0].url ? (
            <img
              src={files[0].url ?? files[0].preview}
              className="w-11 h-11"
              alt={files[0].name ?? files[0].fileName}
            />
          ) : (
            <Icon name="Receipt" className="w-8 h-8 mr-1 text-neutral-400" />
          )}

          <div className="flex flex-col justify-center w-full gap-1">
            <Text
              translationKey={`Reciept.${files[0].type?.split("/")[1]}`}
              classes="text-sm font-medium text-neutral-800"
            />
          </div>

          <div onClick={primaryAction?.handler}>
            <Icon
              name={primaryAction?.icon}
              className="p-2 bg-white rounded-lg cursor-pointer w-7 h-7 text-danger-600"
            />
          </div>
        </div>
      )}
      <div className="flex items-center w-full gap-4 mt-6 border-neutral-200">
        <Avatar
          name={currentUser?.displayName}
          imgSrc={currentUser?.avatarUrl}
          color={currentUser?.colorCode}
          size="xs"
        />

        <Input
          name="comment"
          outsideDivClasses="flex-1"
          placeholder="comments.inputText"
          label="comments.inputText"
          classes="text-sm"
          labelExtraClasses="text-neutral-500 text-sm"
          onChange={handleChange}
          value={values?.comment}
        />
      </div>
      <div className="flex items-center justify-end gap-3 mt-5 ">
        <div className="relative" ref={ref}>
          <div
            className={`${
              showEmojiPicker ? "rounded-lg bg-neutral-200" : ""
            } p-2`}
          >
            <Icon
              name="Emoji"
              className={`w-5 h-5 cursor-pointer ${
                isDisabled ? "text-neutral-300" : "text-neutral-500"
              } `}
              handleClick={() => setShowEmojiPicker((prev) => !prev)}
            />
          </div>
          {showEmojiPicker ? (
            <div className="absolute right-0 top-10">
              <EmojiPicker
                size="1em"
                navPosition="none"
                theme="light"
                previewPosition="none"
                emojiSize={20}
                emojiButtonSize={32}
                skinTonePosition="none"
                onEmojiSelect={({ native }) =>
                  setValues((prev) => ({
                    ...prev,
                    comment: `${prev.comment} ${native}`,
                  }))
                }
              />
            </div>
          ) : null}
        </div>

        <FileUpload
          accept={DEFAULT_ACCEPT_WITH_PDF}
          variant={FILE_UPLOAD_VARAINTS.CUSTOM}
          customComponent={
            <div
              className={`p-2 ${
                isFileDialogOpen ? "rounded-lg bg-neutral-200" : ""
              }`}
            >
              <Icon
                name="Attach"
                className={`w-5 h-5 cursor-pointer  ${
                  disabled ? "text-neutral-300" : "text-neutral-500"
                } `}
              />
            </div>
          }
          name="file"
          files={files}
          handleFileChange={setFiles}
          acceptText="common.defaultUploadTextWithPdf"
          primaryAction={{
            handler: () => setFiles([]),
            label: "Delete",
            icon: "Delete",
            iconClasses: "text-danger-600 bg-danger-50",
          }}
          maxFiles={1}
          showPreview={false}
          isDisabled={files.length > 0}
          setIsFileDialogOpen={setIsFileDialogOpen}
        />

        <Button
          disabled={!values?.comment}
          btnType="submit"
          label="comments.btnLabel"
          classes="w-14 h-8"
        />
      </div>
    </form>
  ) : null;
}
CommentsInput.propTypes = {
  isVisible: PropTypes.bool,
  currentUser: PropTypes.object,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};
