import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { editCustomRole, fetchRoleDetails } from "@/store/reducers/company";

import {
  isLoadingPermissionsSelector,
  permissionsSelector,
  roleDetailsSelector,
} from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Loader from "@/components/core/Loader";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import SetPermissions from "@/components/Company/Slider/AddCustomRole/SetPermissions";
import {
  convertPermissions,
  permissionsSetup,
  sortPermissions,
} from "@/components/Company/common/RolesPermissionsCommon";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  PERMISSIONS_ACCESSIBILITY_TYPE,
  PERMISSIONS_TITLE_MAP,
  PERMISSIONS_TYPES,
  SETTING_PERMISSIONS_ACTION_TYPE,
} from "@/constants/company";

export default function EditCustomRole() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [permissionList, setPermissionList] = useState({});
  const [showSelectors, setShowSelectors] = useState({});
  const roleDetails = useSelector(roleDetailsSelector);
  const originalPermissions = useSelector(permissionsSelector) || [];
  const isLoading = useSelector(isLoadingPermissionsSelector);
  const [selectedPermissionList, setSelectedPermissionList] = useState([]);
  const roleId = parseInt(
    searchParams.get(
      SLIDERS_SEARCH_PARAMS.company.rolesPermissions.editCustomRole
    ),
    10
  );

  const allTypesofPermission = PERMISSIONS_TYPES;
  const [hasRunEffect, setHasRunEffect] = useState(false);
  const ref = useLeftHeaderTitle({
    title: `Edit ${roleDetails?.name}`,
  });
  useEffect(() => {
    if (originalPermissions && !hasRunEffect && selectedPermissionList) {
      const permissions = permissionsSetup(originalPermissions);
      setPermissionList((prev) => {
        const updatedPermissions = convertPermissions(
          permissions,
          SETTING_PERMISSIONS_ACTION_TYPE.EDIT,
          selectedPermissionList
        );
        Object.entries(updatedPermissions)?.forEach(([key, value]) =>
          sortPermissions(value.permissions, key)
        );
        return updatedPermissions;
      });
    }
  }, [
    JSON.stringify(originalPermissions),
    selectedPermissionList,
    hasRunEffect,
  ]);

  useEffect(() => {
    if (roleDetails && roleDetails.rolePermissions) {
      setSelectedPermissionList(
        roleDetails?.rolePermissions?.map((permission) => ({
          ...permission,
          parent: permission?.name?.split(":")[0].split("_")[0],
        }))
      );
    }
  }, [roleDetails]);

  useEffect(() => {
    if (permissionList) {
      setShowSelectors((prevShowSelectors) => {
        if (Object.keys(prevShowSelectors)?.length === 0) {
          const initialSelectors = Object.keys(permissionList)?.reduce(
            (acc, key) => {
              acc[key] = true;
              return acc;
            },
            {}
          );
          return initialSelectors;
        }
        return prevShowSelectors;
      });
    }
  }, [permissionList]);

  useEffect(() => {
    if (
      !hasRunEffect &&
      selectedPermissionList &&
      selectedPermissionList.length > 0
    ) {
      setPermissionList((prev) => {
        const updatedPermissions = { ...prev };

        Object.values(updatedPermissions)?.forEach((item) => {
          const permissionOptions = item?.permissions || [];
          if (
            permissionOptions?.some((permission) =>
              selectedPermissionList?.some(
                (p) => p?.permissionId === permission?.id
              )
            )
          ) {
            item.default = true;
          }
        });

        return updatedPermissions;
      });

      setHasRunEffect(true);
    }
  }, [selectedPermissionList, hasRunEffect]);

  const toggleEnableAll = (key, val) => {
    const allPermissionsAreEdit = permissionList[key]?.permissions?.every(
      (permission) =>
        permission.accessibility === PERMISSIONS_ACCESSIBILITY_TYPE.VIEW
    );

    if (val) {
      setSelectedPermissionList((prev) => [
        ...prev,
        ...(permissionList[key]?.permissions
          .filter(
            (permission) =>
              permission.accessibility === PERMISSIONS_ACCESSIBILITY_TYPE.EDIT
          )
          .map((permission) => ({
            id: permission?.id,
            permissionId: permission?.id,
            name: permission?.name,
            parent: key,
            newAddition: true,
          })) ?? []),
      ]);
    } else {
      setSelectedPermissionList((prev) =>
        prev?.filter((permission) => permission?.parent !== key)
      );
    }
  };

  // combinePermissionByName will return an array of array which is combined by the permission name
  function combinePermissionsByName(permissions) {
    const combinedPermissions = {};

    permissions.forEach((permission) => {
      if (!combinedPermissions[permission.name]) {
        combinedPermissions[permission.name] = [permission];
      } else {
        combinedPermissions[permission.name].push(permission);
      }
    });
    return Object.values(combinedPermissions);
  }

  const combinedResult = combinePermissionsByName(selectedPermissionList);

  // array of 2 length means the user has selected a permission having accessibility of edit and view for this we should send the edit accessibility data with _destroy in payload
  const filteredArrays = combinedResult.filter((arr) => arr.length === 2);

  const reducedArray = filteredArrays.reduce((acc, curr) => {
    const editPermissions = curr.filter(
      (permission) =>
        permission.accessibility === "edit" && !permission?.newAddition
    );
    return acc.concat(editPermissions);
  }, []);

  const handleCancel = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.company.rolesPermissions.editCustomRole
    );
    setSearchParams(searchParams);
  };

  const handleSave = () => {
    const deletePermissionList = roleDetails?.rolePermissions?.filter(
      (rolePermission) =>
        !selectedPermissionList?.some(
          (selectedPermission) =>
            selectedPermission.permissionId === rolePermission?.permissionId
        )
    );

    setSelectedPermissionList((prev) =>
      prev?.filter((item) =>
        deletePermissionList?.map(
          (permission) => permission?.permissionId === item?.permissionId
        )
      )
    );

    setPermissionList((prev) =>
      Object.values(prev)?.map((permission) => {
        const foundItem = deletePermissionList?.find(
          (item) => item?.id === permission?.id
        );
        if (foundItem) {
          permission.default = false;
        }
        return permission;
      })
    );
    const filteredSelectedPermissionsList = Object.values(
      selectedPermissionList.reduce((acc, obj) => {
        const key = obj.name;
        if (
          !acc[key] ||
          obj.accessibility === PERMISSIONS_ACCESSIBILITY_TYPE.VIEW
        ) {
          acc[key] = obj;
        }
        return acc;
      }, {})
    ).filter((obj) => Object.keys(obj).length > 0);

    const editPermissionList = [
      ...(filteredSelectedPermissionsList?.map((permission) => {
        return permission?.newAddition
          ? {
              permission_id: permission?.permissionId,
            }
          : {
              id: permission?.id,
              permission_id: permission?.permissionId,
            };
      }) ?? []),
      ...(deletePermissionList?.map((deletePermission) => {
        return {
          id: deletePermission?.id,
          role_id: roleId,
          permission_id: deletePermission?.permissionId,
          _destroy: true,
        };
      }) ?? []),

      ...(reducedArray?.map((deletePermission) => {
        return {
          id: deletePermission?.id,
          _destroy: true,
        };
      }) ?? []),
    ];
    const payload = {
      name: roleDetails?.name,
      role_permissions_attributes: editPermissionList,
    };
    const onSuccess = () => {
      dispatch(fetchRoleDetails({ roleId: parseInt(roleId, 10) }));
      handleCancel();
    };

    dispatch(editCustomRole({ id: roleId, payload, onSuccess }));
  };

  return (
    <div className="slider-content-container">
      {!isLoading ? (
        <div className="flex flex-col slider-content-core gap-9">
          <div className="flex flex-col justify-center">
            <Text
              translationKey="company.rolesPermissions.editRole.title"
              translationProps={{ role: roleDetails?.name }}
              classes="text-3xl font-bold text-neutral-800"
              refProp={ref}
            />
            <Text
              translationKey="company.rolesPermissions.editRole.description"
              classes="text-sm font-medium text-neutral-500"
            />
          </div>
          <div className="flex flex-col gap-4">
            <Text
              translationKey="company.rolesPermissions.tabs.permissions.title"
              classes="text-lg font-semibold text-neutral-800"
            />
            <div className="flex flex-col gap-6 pb-10">
              {Object.entries(permissionList)?.map(([key, value]) => (
                <div
                  className="flex flex-col justify-center gap-5 p-4 border rounded-lg cursor-pointer border-neutral-200"
                  key={`${key}-permissions`}
                >
                  <div
                    className="flex items-center justify-between w-full"
                    onClick={() => {
                      setShowSelectors((prev) => ({
                        ...prev,
                        [key]: !prev[key],
                      }));
                    }}
                  >
                    <div className="flex items-center w-full gap-3">
                      <Switch
                        value={permissionList[key]?.default}
                        handleChange={(val, event) => {
                          event.stopPropagation();
                          setPermissionList((prev) => ({
                            ...prev,
                            [key]: {
                              default: val,
                              permissions: prev[key]?.permissions?.map(
                                (item) =>
                                  item.accessibility ===
                                  PERMISSIONS_ACCESSIBILITY_TYPE.EDIT
                                    ? {
                                        ...item,
                                        disabled: !val,
                                        isEditOnly: !val,
                                      }
                                    : item
                              ),
                            },
                          }));
                          toggleEnableAll(key, val);

                          setShowSelectors((prev) => ({
                            ...prev,
                            [key]: val,
                          }));
                        }}
                      />
                      <Text
                        translationKey={PERMISSIONS_TITLE_MAP[key]}
                        classes="text-lg font-semibold text-neutral-800 first-letter:capitalize"
                      />
                    </div>
                    <Icon
                      name={`${
                        showSelectors[key] ? "ChevronUp" : "ChevronDown"
                      }`}
                      className="w-4 h-4 text-neutral-400"
                    />
                  </div>
                  {showSelectors[key] ? (
                    <SetPermissions
                      permissionKey={key}
                      permissions={permissionList}
                      setPermissions={setPermissionList}
                      selectedPermissionList={selectedPermissionList}
                      setSelectedPermissionList={setSelectedPermissionList}
                      type={SETTING_PERMISSIONS_ACTION_TYPE.EDIT}
                      fromAssistantRole={roleDetails?.assistantRole}
                    />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <div className="px-6 py-4 slider-footer">
        <div className="flex items-center justify-end w-full gap-6">
          <Button
            label="company.rolesPermissions.buttonLabels.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-base text-neutral-500 font-semibold"
            onClick={() => handleCancel()}
            disabled={isLoading}
          />
          <Button
            label="company.rolesPermissions.buttonLabels.saveChanges"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            onClick={() => handleSave()}
            showLoader={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
