import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  exchangeFunds,
  fetchAccountWallets,
  generateFundQuote,
  setGeneratedFundQuote,
} from "@/store/reducers/client";
import { allocateToPayroll } from "@/store/reducers/company";

import {
  defaultPaymentAccountSelector,
  primaryCardProviderSelector,
  walletListSelector,
} from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Note from "@/components/core/Note";
import Text from "@/components/core/Text";

import {
  GENERATE_QUOTE_API_BODY_KEYS_REQUEST,
  PAYMENT_TYPE,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import { useForm } from "@/utils/useForm";
import { PAYMENT_CHANNELS } from "@/utils/constants/payments";
import { ACCOUNT_TYPE } from "@/utils/constants/client";
import { amountToCurrency, debounce } from "@/utils/common";

import { CARD_PROVIDER } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";
import { ALLOCATE_FUNDS_ACCOUNT_TYPE_KEY } from "@/constants/company";

export default function AllocateFundSlider() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useLeftHeaderTitle({
    title: "company.allocateFunds.title",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFundQuoteGenerating, setIsFundQuoteGenerating] = useState(false);
  const defaultAccount = useSelector(defaultPaymentAccountSelector);
  const sendingToAccountKey = searchParams.get(ALLOCATE_FUNDS_ACCOUNT_TYPE_KEY);
  const defaultCurrency = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.currency
  );
  const walletsList = useSelector(walletListSelector);
  const [quoteId, setQuoteId] = useState("");
  const sliderPreviewType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.allocate
  );
  const client = useSelector(primaryCardProviderSelector);
  const slug = client?.slug;
  const triggerQuoteGenerationApi = [
    CARD_PROVIDER.AYOCONNECT,
    CARD_PROVIDER.PINELABS,
    CARD_PROVIDER.LIVQUIK,
  ].includes(slug);
  const showPrimaryButtonLoader =
    sliderPreviewType === FORM_TYPE.edit &&
    triggerQuoteGenerationApi &&
    isFundQuoteGenerating;

  const ACCOUNT_KEYS_LABEL_MAPPING = {
    cards: {
      label: t("company.allocateFunds.accountTypeKeysLabel.cards"),
      icon: "CreditCard",
    },
    payments: {
      label: getWidgetTitleForAccountTypePayments,
      icon: "BillPay",
    },
    payroll: {
      label: t("company.allocateFunds.accountTypeKeysLabel.payroll"),
      icon: "Payments",
    },
    global_cards: {
      label: t(
        "company.allocateFunds.accountTypeKeysLabel.internationalCardAccount"
      ),
      icon: "InternationalCards",
    },
  };

  function getWidgetTitleForAccountTypePayments() {
    if (!defaultAccount?.aliasName) {
      return {
        label: t("company.allocateFunds.defaultAccountLabel"),
        labelProps: { currency: defaultAccount?.currency },
      };
    }

    return {
      label: t("company.allocateFunds.accountTypeKeysLabel.payments"),
      labelProps: {},
    };
  }

  const [fromAccountOption, sendingToAccountOption] = useMemo(() => {
    const toAccountObject = walletsList?.find(
      (wallet) => wallet?.accountType === sendingToAccountKey
    );

    const fromAccount = getAccountConfig(defaultAccount);
    const toAccount = getAccountConfig(toAccountObject);

    return [fromAccount, toAccount];
  }, [walletsList, defaultAccount]);

  const fromAccountOptionLabel = t(
    fromAccountOption?.label,
    fromAccountOption?.labelProps
  );

  const initialFormValue = {
    amount: {
      value: "",
      validate: {
        required: true,
        maxNumber: parseFloat(defaultAccount?.availableBalance),
      },
      errorStatement: {
        required: "cards.cardDetails.editLimitDetails.errorStatement",
        maxNumber: "company.allocateFunds.amountError",
      },
    },
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialFormValue, handleAllocateFunds);

  const onSuccess = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.allocate);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.currency);
    searchParams.delete(ALLOCATE_FUNDS_ACCOUNT_TYPE_KEY);
    setSearchParams(searchParams);
    dispatch(fetchAccountWallets()); // updating the current wallet accounts
  };

  const handleBack = () => {
    searchParams.set(SLIDERS_SEARCH_PARAMS.company.allocate, FORM_TYPE.edit);
    setSearchParams(searchParams);
  };

  const generateQuote = () => {
    const params = {
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.AMOUNT]: values?.amount,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.BUY_CURRENCY]: defaultCurrency,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.PAYMENT_CHANNEL]:
        PAYMENT_CHANNELS.LOCAL,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.SELL_CURRENCY]: defaultCurrency,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.FIXED_SIDE]: "sell",
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.TYPE]: "card_allocation",
    };
    dispatch(setGeneratedFundQuote(null));

    dispatch(
      generateFundQuote({
        params,
        onSuccess: (data) => {
          setIsFundQuoteGenerating(false);
          setQuoteId(data?.id);
        },
      })
    );
  };

  const debounceGenerateQuote = debounce(generateQuote, 1000);

  const customAccountWidgetChip = (option) => {
    if (option) {
      return (
        <div className="flex flex-col gap-1">
          <Text
            translationKey="accounting.integrations.labels.selectAccount"
            classes="text-xs font-semibold text-neutral-500"
          />

          <div className="flex items-center w-full gap-1 py-1 pb-3 border-b border-solid border-neutral-300">
            <div className="flex justify-between w-full align-center">
              <div className="flex flex-row items-center gap-2">
                {option?.icon ? (
                  <div className="p-3 border-none avatar avatar-md text-primary-500 bg-primary-50">
                    <Icon name={option?.icon} className="w-5 h-5" />
                  </div>
                ) : null}

                <Text
                  classes="text-sm text-neutral-800 font-medium ml-2"
                  translationKey={option?.label}
                  translationProps={option?.labelProps}
                />
              </div>
              <Text
                classes="text-sm text-neutral-500 font-medium ml-2 flex items-center"
                translationKey={amountToCurrency(
                  option.amount,
                  option.currency
                )}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const reviewWidgetsConfig = [
    {
      mainHeading: "company.exchangeFund.sendingFrom",
      icon: fromAccountOption?.icon,
      title: "company.allocateFunds.reviewWalletWidgetsTitles",
      titleTranslationProps: {
        label: fromAccountOptionLabel,
      },
      newBalance: `${amountToCurrency(
        (fromAccountOption?.amount ?? 0) - (values?.amount ?? 0)
      )}`,
      currency: defaultCurrency,
      deductedAmount: amountToCurrency(values?.amount),
    },
    {
      mainHeading: "company.exchangeFund.sendingTo",
      icon: sendingToAccountOption?.icon,
      title: "company.allocateFunds.reviewWalletWidgetsTitles",
      titleTranslationProps: { label: sendingToAccountOption?.label },
      newBalance: `${amountToCurrency(
        Number(sendingToAccountOption?.amount) + Number(values?.amount)
      )}`,
      addedAmount: amountToCurrency(values?.amount),
      currency: defaultCurrency,
    },
  ];

  useEffect(() => {
    if (values?.amount && triggerQuoteGenerationApi) {
      debounceGenerateQuote();
      setIsFundQuoteGenerating(true);
    }
  }, [values?.amount]);

  function handleAllocateFunds() {
    if (sliderPreviewType === FORM_TYPE.review) {
      if (
        [ACCOUNT_TYPE.CARDS, ACCOUNT_TYPE.INTERNATIONAL_CARDS].includes(
          sendingToAccountKey
        )
      ) {
        dispatch(
          exchangeFunds({
            value: {
              amount: values?.amount,
              currency: defaultCurrency,
              from_account_id: fromAccountOption?.id,
              to_account_id: sendingToAccountOption?.id,
              payment_type: PAYMENT_TYPE.CARD,
              quote_id: quoteId,
            },
            onSuccess,
          })
        );
      } else if (sendingToAccountKey === ACCOUNT_TYPE.PAYROLL) {
        const params = {
          amount: values.amount,
          currency: defaultAccount?.currency,
        };
        dispatch(allocateToPayroll({ payload: params, onSuccess }));
      }
    } else {
      searchParams.set(
        SLIDERS_SEARCH_PARAMS.company.allocate,
        FORM_TYPE.review
      );
      setSearchParams(searchParams);
    }
  }

  function getAccountConfig(acc) {
    const _label = ACCOUNT_KEYS_LABEL_MAPPING?.[acc?.accountType]?.label;

    const { label = "", labelProps = "" } =
      typeof _label === typeof (() => {})
        ? _label()
        : { label: _label, labelProps: {} };

    return {
      value: acc?.currency,
      currency: acc?.currency,
      amount: acc?.availableBalance,
      id: acc?.id,
      label,
      labelProps,
      icon: ACCOUNT_KEYS_LABEL_MAPPING?.[acc?.accountType]?.icon,
    };
  }

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        <div className="flex flex-col gap-2">
          <Text
            classes="font-bold text-3xl text-neutral-800"
            translationKey="company.allocateFunds.title"
          />

          <Text
            refProp={ref}
            classes="text-sm text-neutral-500 mt-1 font-medium"
            translationKey={
              sendingToAccountKey === ACCOUNT_TYPE.INTERNATIONAL_CARDS
                ? "company.allocateFunds.internationalAccountTransferFundsDescription"
                : sendingToAccountKey === ACCOUNT_TYPE.PAYROLL
                  ? "company.allocateFunds.description"
                  : "company.allocateFunds.transferFundsDescription"
            }
          />
        </div>

        {sliderPreviewType === FORM_TYPE.edit ? (
          <div>
            {sendingToAccountKey === ACCOUNT_TYPE.INTERNATIONAL_CARDS ? (
              <Note
                descriptionText="company.allocateFunds.notes.internationalTransferInitialMessage"
                descriptionTextTranslationProps={{
                  fromAccount: fromAccountOptionLabel,
                }}
                externalDivClasses="mt-8 border-warning-300 bg-warning-50"
                descriptionTextClasses="font-semibold text-neutral-800"
              />
            ) : (
              <Note
                showIcon
                descriptionText={
                  sendingToAccountKey === ACCOUNT_TYPE.PAYROLL
                    ? "company.allocateFunds.note"
                    : "company.allocateFunds.notes.transferInitiationMessage"
                }
                descriptionTextTranslationProps={{
                  fromAccount: t(
                    fromAccountOption?.label,
                    fromAccountOption?.labelProps
                  ),
                  toAccount: sendingToAccountOption?.label,
                }}
                actionText=""
                externalDivClasses="mt-8 border-neutral-300 bg-neutral-50"
                descriptionTextClasses="font-semibold text-neutral-800"
              />
            )}

            <div className="flex flex-col mt-6">
              <Text
                classes="font-semibold text-lg text-neutral-800 mb-7"
                translationKey="company.exchangeFund.sendingFrom"
              />

              {customAccountWidgetChip(fromAccountOption)}

              <Text
                classes="font-semibold text-lg text-neutral-800 my-7"
                translationKey="company.exchangeFund.sendingTo"
              />

              {customAccountWidgetChip(sendingToAccountOption)}

              <form
                id="edit-allocate-slider-form"
                className="mt-14"
                onSubmit={handleSubmit}
              >
                <Text
                  classes="font-semibold text-lg text-neutral-800 "
                  translationKey="company.exchangeFund.transferAmountOf"
                />

                <div className="mt-4">
                  <Input
                    classes="text-xl font-semibold text-neutral-500"
                    showNumberInvalidError
                    name="amount"
                    type="number"
                    value={values?.amount}
                    error={errors?.amount}
                    minNumber={1}
                    insideForm
                    onChange={handleChange}
                    rightText={defaultCurrency}
                    label="forms.enterAmount"
                  />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>
            <Note
              showIcon
              descriptionText="company.allocateFunds.notes.allocateFundsMessage"
              descriptionTextTranslationProps={{
                fromAccountLabel: fromAccountOptionLabel,
                toAccountLabel: t(sendingToAccountOption?.label?.toLowerCase()),
              }}
              actionText=""
              externalDivClasses="mt-8 border-warning-300 bg-warning-50"
              descriptionTextClasses="font-semibold text-neutral-800"
            />

            <div className="flex flex-col gap-8 mt-10">
              {reviewWidgetsConfig?.map((widget, index) => {
                const {
                  icon = null,
                  title = null,
                  titleTranslationProps = null,
                  newBalance = null,
                  deductedAmount = null,
                  addedAmount = null,
                  currency = null,
                  mainHeading = null,
                } = widget;

                return (
                  <div key={index} className="flex flex-col gap-4">
                    <Text
                      translationKey={mainHeading}
                      classes="text-lg font-semibold"
                    />
                    <div className="flex flex-row items-center gap-4 card-wrapper">
                      <div className="p-3 border-none avatar avatar-md text-primary-500 bg-primary-50 w-12 h-11.5 flex items-center justify-center">
                        <Icon name={icon} className="w-6 h-6" />
                      </div>
                      <div className="flex flex-col w-full">
                        <Text
                          translationKey={title}
                          translationProps={titleTranslationProps}
                          classes="text-xs font-semibold text-neutral-800"
                        />

                        <div className="flex flex-row items-center w-full gap-3">
                          <Text
                            translationKey={newBalance}
                            classes="text-neutral-800 text-2xl font-bold"
                          />

                          <Text
                            translationKey={widget?.currency}
                            classes="text-base font-semibold text-neutral-500"
                          />

                          <Chip
                            label={`${deductedAmount ? "-" : "+"} ${
                              deductedAmount || addedAmount
                            } ${currency}`}
                            classes={`${
                              deductedAmount
                                ? "text-danger-400  bg-danger-50"
                                : "text-success-400 bg-success-50"
                            } text-xs`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className="sticky p-6 slider-footer ">
        <div className="flex justify-end ">
          {sliderPreviewType === FORM_TYPE.review ? (
            <Button
              classes="w-16 text-neutral-500 mr-3"
              label="cards.pCards.sliders.createCardSlider.buttons.cancel"
              onClick={() => handleBack()}
              variant="tertiary"
              type="default"
            />
          ) : null}
          <Button
            label="Continue"
            classes="w-18 px-5 py-3 text-base font-semibold"
            btnType="submit"
            onClick={
              sliderPreviewType === FORM_TYPE.review
                ? handleAllocateFunds
                : () => {}
            }
            form="edit-allocate-slider-form"
            showLoader={showPrimaryButtonLoader}
            disabled={
              sliderPreviewType === FORM_TYPE.edit
                ? isFormButtonDisabled || isFundQuoteGenerating
                : false
            }
          />
        </div>
      </div>
    </div>
  );
}
