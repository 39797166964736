import PropTypes from "prop-types";

import { getTaxValues } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/utils";
import { amountToCurrency } from "@/utils/common";

export default function LineItemTaxCell({ tax, lineItemAmount: amount }) {
  const amountEnteredManually = !tax?.taxRate; // amount was entered manually, or selected from dropdown (rate)

  const { value, currency, description } = getTaxValues(tax, amount);

  return (
    <td key={tax.id}>
      <div>
        <div>
          {/* main text */}
          {amountToCurrency(value, currency)}
        </div>
        {amountEnteredManually ? null : (
          <div className="text-xs font-medium text-neutral-500">
            {/* sub text */}
            {description}
          </div>
        )}
      </div>
    </td>
  );
}

LineItemTaxCell.propTypes = {
  tax: PropTypes.object,
  lineItemAmount: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
  }),
};
