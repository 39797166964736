// Note: this is the last place you want to create a new variable. Prefer `src/utils/constants/payments.js`

import {
  fetchRequestTableInfo,
  resetPaginationActionCentre,
} from "@/store/reducers/actionCentre";
import {
  PAYMENTS_SLICE_ATTRIBUTE_KEY,
  fetchPaymentApprovals,
  fetchPayrollApprovals,
  resetPaymentStore,
} from "@/store/reducers/payments";

import {
  actionCentreTableHasMoreSelector,
  actionCentreTableIsFetchingSelector,
  actionCentreTableListSelector,
  actionCentreTableTotalSelector,
} from "@/store/selectors/actionCentre";
import {
  isPaymentsFetchingSelector,
  paymentsHasMoreSelector,
  paymentsListSelector,
  paymentsTotalSelector,
} from "@/store/selectors/payments";
import {
  BILLPAY_ACTION_PARAMS,
  BILL_PAYROLL_ACTION_CENTER_APPROVE_TYPES,
  BILL_PAYROLL_CONTEXT,
  PAYROLL_ACTION_PARAMS,
} from "@/utils/constants/paymentsStore";

import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { APPROVAL_REQUEST_TYPE } from "@/constants/myVolopay";

export const BILL_PAY_APPROVAL_LIST_CONFIG = {
  listSelector: paymentsListSelector,
  isFetchingSelector: isPaymentsFetchingSelector,
  totalSelector: paymentsTotalSelector,
  hasMoreSelector: paymentsHasMoreSelector,
  type: BILLPAY_ACTION_PARAMS.TYPE_PURCHASE_BILL,
  seacrhParams: {
    reject: SLIDERS_SEARCH_PARAMS.payments.reject,
    pageType: SLIDERS_SEARCH_PARAMS.payments.pageType,
  },
  resetMethod: () => ({
    func: resetPaymentStore,
    args: { key: PAYMENTS_SLICE_ATTRIBUTE_KEY },
  }),
  loadMoreMethod: ({ context, key, value }) => ({
    func: fetchPaymentApprovals,
    args: {
      context,
      key,
      value,
    },
  }),
};
export const BILL_PAYROLL_APPROVAL_LIST_CONFIG = {
  listSelector: paymentsListSelector,
  isFetchingSelector: isPaymentsFetchingSelector,
  totalSelector: paymentsTotalSelector,
  hasMoreSelector: paymentsHasMoreSelector,
  type: PAYROLL_ACTION_PARAMS.TYPE_PAYROLL,
  seacrhParams: {
    reject: SLIDERS_SEARCH_PARAMS.payrollPayments.reject,
  },
  resetMethod: () => ({
    func: resetPaymentStore,
    args: { key: PAYMENTS_SLICE_ATTRIBUTE_KEY },
  }),
  loadMoreMethod: ({ context, key, value }) => ({
    func: fetchPayrollApprovals,
    args: {
      context,
      key,
      value,
    },
  }),
};

export const ACTION_CENTRE_CONFIG = {
  BILL_PAY: {
    listSelector: actionCentreTableListSelector,
    isFetchingSelector: actionCentreTableIsFetchingSelector,
    totalSelector: actionCentreTableTotalSelector,
    hasMoreSelector: actionCentreTableHasMoreSelector,
    type: BILLPAY_ACTION_PARAMS.TYPE_PURCHASE_BILL,
    resetMethod: () => ({
      func: resetPaginationActionCentre,
      args: {},
    }),
    seacrhParams: {
      reject: SLIDERS_SEARCH_PARAMS.payments.reject,
    },
    loadMoreMethod: ({
      user,
      page,
      limit,
      context,
      key,
      value,
      ...restFilteringParams
    }) => ({
      func: fetchRequestTableInfo,
      args: {
        user,
        request_type: APPROVAL_REQUEST_TYPE.BILL_PAY,
        page,
        limit,
        ...restFilteringParams,
      },
    }),
  },
  PAYROLL: {
    listSelector: actionCentreTableListSelector,
    isFetchingSelector: actionCentreTableIsFetchingSelector,
    totalSelector: actionCentreTableTotalSelector,
    hasMoreSelector: actionCentreTableHasMoreSelector,
    type: PAYROLL_ACTION_PARAMS.TYPE_PAYROLL,
    seacrhParams: {
      reject: SLIDERS_SEARCH_PARAMS.payrollPayments.reject,
    },
    resetMethod: () => ({
      func: resetPaginationActionCentre,
      args: {},
    }),
    loadMoreMethod: ({
      user,
      page,
      limit,
      context,
      key,
      value,
      ...restFilteringParams
    }) => ({
      func: fetchRequestTableInfo,
      args: {
        user,
        request_type: APPROVAL_REQUEST_TYPE.PAYROLL,
        page,
        limit,
        ...restFilteringParams,
      },
    }),
  },
};

export const BILL_PAYROLL_ACTION_CENTER_PAGE_CONFIG = {
  [BILL_PAYROLL_CONTEXT.BILLPAY]: {
    ...BILL_PAY_APPROVAL_LIST_CONFIG,
    type: BILL_PAYROLL_ACTION_CENTER_APPROVE_TYPES[
      BILL_PAYROLL_CONTEXT.BILLPAY
    ],
  },
  [BILL_PAYROLL_CONTEXT.PAYROLL]: {
    ...BILL_PAYROLL_APPROVAL_LIST_CONFIG,
    type: BILL_PAYROLL_ACTION_CENTER_APPROVE_TYPES[
      BILL_PAYROLL_CONTEXT.PAYROLL
    ],
  },
  [ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY]: {
    ...ACTION_CENTRE_CONFIG.BILL_PAY,
    type: BILL_PAYROLL_ACTION_CENTER_APPROVE_TYPES[
      ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY
    ],
  },
  [ACTION_CENTRE_PAGE_CONTEXT.PAYROLL]: {
    ...ACTION_CENTRE_CONFIG.PAYROLL,
    type: BILL_PAYROLL_ACTION_CENTER_APPROVE_TYPES[
      ACTION_CENTRE_PAGE_CONTEXT.PAYROLL
    ],
  },
};
