export const formatDataForTable = (transaction) => {
  const formatted = {
    accountingId: transaction?.accountingId,
    id: transaction?.id,
    accountingPayeeId: transaction?.accountingPayeeId,
    accountingVendorName: transaction?.accountingVendorName,
    accountingStatus: transaction?.accountingStatus,
    editable: transaction?.editable,
    rule: transaction?.rule,
    merchant: {
      name: transaction?.merchant?.name,
      category: transaction?.categoryName,
      status: transaction?.accountingStatus,
      avatarUrl: transaction?.merchant?.logoUrl,
    },
    amount: {
      amount: transaction?.amount,
      currency: transaction?.currency,
    },
    cardOwner: transaction?.cardHolder,
    receipt: {
      receiptStatus: transaction?.receiptStatus,
    },
    note: {
      required: transaction?.noteRequired,
      value: transaction?.note,
    },
    transactionStatus: transaction?.transactionStatus,
    action: {
      status: transaction?.accountingStatus,
      verifiable: transaction?.verifiable,
      syncable: transaction?.syncable,
      ctas: transaction?.accountingCtas,
    },
    accountingTag: {
      arr: transaction?.accountingTags,
    },
    customTag: {
      arr: transaction?.customTags,
    },
    vendors: {
      id: transaction?.merchant?.id,
      name: transaction?.merchant?.name,
      alias: transaction?.merchant?.alias || transaction?.merchant?.name,
    },
    transactionDate: transaction?.transactionDate,
    verifiable: transaction?.verifiable,
    syncable: transaction?.syncable,
    notActionableReason: transaction?.notActionableReason,
    openIn: transaction?.accountingUrl,
    lineItems: transaction?.lineItems,
    transactionLinkedTo: {
      linkedTo: transaction?.linkedTo,
      project: transaction?.project?.name,
    },
    ledgerDate: transaction?.settlementDate,
  };

  return formatted;
};
