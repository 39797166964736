import PropTypes from "prop-types";

import { amountToCurrency } from "@/utils/common";

export default function SettledAmountCell({ val }) {
  return (
    <div>
      <p>{amountToCurrency(val?.settledAmount)}</p>
    </div>
  );
}

SettledAmountCell.propTypes = {
  val: PropTypes.object,
};
