export const ONBOARDING_SLIDER_PARAMS = "step";
export const PERSONAL_DETAILS = "personal_details";
export const VP_ADDRESS_CONFIG = {
  ADDRESS_LINE_1: "address_1",
  ADDRESS_LINE_2: "address_2",
  ZIPCODE: "zipcode",
  CITY: "city",
  STATE_OR_PROVINCE: "state",
  COUNTRY: "country",
};
export const COUNTRY_CODE_ENUM = {
  AUS: "AUS",
  USA: "USA",
  IND: "IND",
  IDN: "IDN",
  SG: "SGP",
};
export const COUNTRY_STATES = {
  AUS: [
    { value: "ACT", name: "Australian Capital Territory" },
    { value: "NSW", name: "New South Wales" },
    { value: "NT", name: "Northern Territory" },
    { value: "QLD", name: "Queensland" },
    { value: "SA", name: "Southtralia" },
    { value: "TAS", name: "Tasmania" },
    { value: "VIC", name: "Victoria" },
    { value: "WA", name: "Western Australia" },
  ],
  USA: [
    { value: "AK", name: "Alaska" },
    { value: "AL", name: "Alabama" },
    { value: "AR", name: "Arkansas" },
    { value: "AZ", name: "Arizona" },
    { value: "CA", name: "California" },
    { value: "CO", name: "Colorado" },
    { value: "CT", name: "Connecticut" },
    { value: "DC", name: "District of Columbia" },
    { value: "DE", name: "Delaware" },
    { value: "FL", name: "Florida" },
    { value: "GA", name: "Georgia" },
    { value: "HI", name: "Hawaii" },
    { value: "IA", name: "Iowa" },
    { value: "ID", name: "Idaho" },
    { value: "IL", name: "Illinois" },
    { value: "IN", name: "Indiana" },
    { value: "KS", name: "Kansas" },
    { value: "KY", name: "Kentucky" },
    { value: "LA", name: "Louisiana" },
    { value: "MA", name: "Massachusetts" },
    { value: "MD", name: "Maryland" },
    { value: "ME", name: "Maine" },
    { value: "MI", name: "Michigan" },
    { value: "MN", name: "Minnesota" },
    { value: "MO", name: "Missouri" },
    { value: "MS", name: "Mississippi" },
    { value: "MT", name: "Montana" },
    { value: "NC", name: "North Carolina" },
    { value: "ND", name: "North Dakota" },
    { value: "NE", name: "Nebraska" },
    { value: "NH", name: "New Hampshire" },
    { value: "NJ", name: "New Jersey" },
    { value: "NM", name: "New Mexico" },
    { value: "NV", name: "Nevada" },
    { value: "NY", name: "New York" },
    { value: "OH", name: "Ohio" },
    { value: "OK", name: "Oklahoma" },
    { value: "OR", name: "Oregon" },
    { value: "PA", name: "Pennsylvania" },
    { value: "RI", name: "Rhode Island" },
    { value: "SC", name: "South Carolina" },
    { value: "SD", name: "South Dakota" },
    { value: "TN", name: "Tennessee" },
    { value: "TX", name: "Texas" },
    { value: "UT", name: "Utah" },
    { value: "VA", name: "Virginia" },
    { value: "VT", name: "Vermont" },
    { value: "WAS", name: "Washington" },
    { value: "WI", name: "Wisconsin" },
    { value: "WV", name: "West Virginia" },
    { value: "WY", name: "Wyoming" },
  ],
};

export const MOBILE_VERIFICATION_STEP_PAGES = {
  MOBILE_VERIFICATION_PHONE_NUMBER: "mobile_verification_phone_number",
  MOBILE_VERIFICATION_OTP: "mobile_verification_otp",
  MOBILE_VERIFICATION_EDIT: "mobile_verification_edit",
};

export const ONBOARDING_STEPS = {
  WELCOME: "welcome",
  REVIEW_DETAILS: "review_details",
  YOUR_DETAILS: "your_details",
  MOBILE_VERIFICATION: "mobile_verification",
  VIDEO_KYC_PROCESS: "v_kyc",
  PERSONAL_DETAILS: "personal_details",
  ADDRESS_DETAILS: "address_details",
  IDENTITY_DETAILS: "identity_details",
  PINE_LABS_V_KYC: "vkyc",
  PINE_LABS_C_KYC: "kyc",
};

export const KYC_STEPS_STATUS = {
  PENDING: "pending",
  COMPLETED: "completed",
  FAILED: "failed",
  IN_PROGRESS: "in_progress",
  APPROVED: "approved",
  REJECTED: "rejected",
  SUBMITTED: "submitted",
};

export const VKYC_STEPS_KEYS = {
  AADHAR: "verify_aadhar",
  UPLOAD: "upload",
  SPEAK_TO_AGENT: "speak_to_agent",
};

export const VKYC_STEPS_ICONS = {
  [VKYC_STEPS_KEYS.AADHAR]: "AccountBox",
  [VKYC_STEPS_KEYS.UPLOAD]: "Backup",
  [VKYC_STEPS_KEYS.SPEAK_TO_AGENT]: "Backup",
};

export const PINELABS_CKYC_STEPS_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  IN_PROGRESS: "in_progress",
  FAILED: "failed",
};

export const PINELABS_CKYC_CONFIG = {
  [PINELABS_CKYC_STEPS_STATUS.PENDING]: {
    nameConfig: {
      text: "Pending",
      classes: "text-warning-600",
    },
    iconConfig: {
      name: "FilledSchedule",
      classes: "text-warning-400",
    },
  },
  [PINELABS_CKYC_STEPS_STATUS.SUCCESS]: {
    nameConfig: {
      text: "Done",
      classes: "text-success-600",
    },
    iconConfig: {
      name: "CheckCircle",
      classes: "text-success-600",
    },
  },
  [PINELABS_CKYC_STEPS_STATUS.FAILED]: {
    nameConfig: {
      text: "Failed",
      classes: "text-danger-500",
    },
    iconConfig: {
      name: "Cancel",
      classes: "text-danger-500",
    },
  },
  [PINELABS_CKYC_STEPS_STATUS.IN_PROGRESS]: {
    nameConfig: {
      text: "In Progress",
      classes: "text-success-600",
    },
    iconConfig: {
      name: "CheckCircle",
      classes: "text-success-600",
    },
  },
};
