import { createSelector } from "@reduxjs/toolkit";

const timelineStore = (store) => store.timeline;

export const timelineDataSelector = createSelector(
  timelineStore,
  (state) => state.timelineData
);

export const timelineTypeSelector = createSelector(
  timelineStore,
  (state) => state.timelineType
);

export const timelineResourceIdSelector = createSelector(
  timelineStore,
  (state) => state.timelineResourceId
);
export const timelineStatusSelector = createSelector(
  timelineStore,
  (state) => state.timelineStatus
);
