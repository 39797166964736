import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchCountries } from "@/store/reducers/app";
import {
  fetchCountriesForMail,
  fetchVendorCreateRequirements,
  fetchVendorPaymentMethods,
  mergeIntoVendorCreateForm,
  setVendorCreateForm,
} from "@/store/reducers/vendors";

import { currentPaymentProviderSelector } from "@/store/selectors/client";
import {
  bankDetailsAlreadyAddedViaMailSelector,
  bankDetailsFormSelector,
  countriesForMailSelector,
  isFetchingVendorPaymentMethodsSelector,
  onboardingMailDetailsSelector,
  vendorCreateFormSelector,
  vendorPaymentMethodsSelector,
} from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import {
  formValueFromExactValue,
  generatePayloadFromFormValue,
} from "@/components/GenericForm/common";
import { BANK_DETAILS_VIA_MAIL_SEARCH_PARAM } from "@/components/common/BillPayAndPayroll/AddBankDetailsComponent/enum";
import "@/components/common/BillPayAndPayroll/AddBankDetailsComponent/syles.scss";
import AddBankDetails from "@/components/common/BillPayAndPayroll/VendorOrEmployee/CreateVendor/AddBankDetails";
import BankDetailsBasedOnProvider from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider";
import { useForm } from "@/utils/useForm";
import { MAIL_CONTEXT } from "@/utils/constants/common";
import { USE_FORM_TYPE_OF_VALIDATION } from "@/utils/constantUseForm";
import { getToken, subdomain } from "@/utils/common";

import { PAYMENT_PROVIDERS } from "@/constants/provider";
import {
  VENDOR_CREATE_FINAL_PAYLOAD_PARAMS,
  VENDOR_CREATE_REQUIREMENTS_PARAMS,
  VENDOR_TYPES,
  VENDOR_UPDATE_REQUEST_PARAMS,
} from "@/constants/vendors";

function BankDetailMethodComponent({ context, classes }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const onboardingMailDetails = useSelector(onboardingMailDetailsSelector);
  const entityName = onboardingMailDetails?.clientName ?? subdomain();
  const bankDetailsAlreadyAdded = useSelector(
    bankDetailsAlreadyAddedViaMailSelector
  );
  const countriesForMail = useSelector(countriesForMailSelector);
  const currentPaymentProvider =
    useSelector(currentPaymentProviderSelector) ||
    onboardingMailDetails?.provider;

  const bankDetailsForm = useSelector(bankDetailsFormSelector);
  const storedInitialFormValue = useSelector(vendorCreateFormSelector) ?? {};
  const paymentMethods = useSelector(vendorPaymentMethodsSelector);

  const isFetchingPaymentMethods = useSelector(
    isFetchingVendorPaymentMethodsSelector
  );
  const currentStep = searchParams.get(BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.KEY);

  const inSingaporeWithWallex =
    currentPaymentProvider === PAYMENT_PROVIDERS.WALLEX;

  const initialValue = {
    [VENDOR_UPDATE_REQUEST_PARAMS.VENDOR_TYPE]: {
      value: onboardingMailDetails?.vendorType,
      validate: {
        required: true,
        [USE_FORM_TYPE_OF_VALIDATION.oneOf]: [
          VENDOR_TYPES.CORPORATE,
          VENDOR_TYPES.INDIVIDUAL,
        ],
      },
      [USE_FORM_TYPE_OF_VALIDATION.oneOf]: "errorMessage.notASupportedValue",
    },
    ...(inSingaporeWithWallex && bankDetailsForm
      ? {}
      : {
          [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]: {
            value:
              storedInitialFormValue?.[
                VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY
              ]?.value ?? "",
            validate: { required: true },
          },

          [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]: {
            value:
              storedInitialFormValue?.[
                VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY
              ]?.value ?? "",
            validate: { required: true },
          },
          [VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_METHOD]: {
            value:
              storedInitialFormValue?.[
                VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_METHOD
              ]?.value ?? "",
            validate: {
              required: true,
            },
          },
          [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]: {
            value:
              storedInitialFormValue?.[
                VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY
              ]?.value ?? "",
            validate: { required: true },
          },
        }),
  };

  const {
    handleChange,
    values,
    errors,
    _setValues: setValues,
    isFormButtonDisabled,
  } = useForm(initialValue, () => {}, {
    isFetchingInitialValue: false,
    formId: "vendor-add-bank-details-form",
  });

  useEffect(() => {
    if (countriesForMail || !onboardingMailDetails || !context) return;

    if (context === MAIL_CONTEXT) {
      dispatch(
        fetchCountriesForMail({
          token: getToken(),
          account: subdomain(),
        })
      );

      dispatch(
        fetchVendorPaymentMethods({
          context,
          payload: {
            token: getToken(),
            account: subdomain(),
          },
        })
      );
    } else {
      dispatch(fetchCountries());
    }
  }, []);

  const addBankDetailsDisabled =
    !values[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_METHOD];

  const onFailRequirementsApi = () => {
    searchParams.set(
      BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.KEY,
      BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.BENEFICARY_DETAILS
    );
    setSearchParams(searchParams);
  };

  const openAddBankDetailsPage = () => {
    // save main form
    dispatch(
      mergeIntoVendorCreateForm(
        formValueFromExactValue(values, storedInitialFormValue)
      )
    );

    const requirementsPayload = generatePayloadFromFormValue({
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.PAYOUT_METHOD_TYPE]:
        values?.[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_METHOD],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.SENDER_CURRENCY]:
        values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.SENDER_CURRENCY],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]:
        values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]:
        values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]:
        values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE]:
        values?.[VENDOR_UPDATE_REQUEST_PARAMS.VENDOR_TYPE],
    });

    dispatch(
      fetchVendorCreateRequirements({
        context,
        payload: {
          ...requirementsPayload,
          token: getToken(),
          account: subdomain(),
        },
        onFail: onFailRequirementsApi,
      })
    );
    searchParams.set(
      BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.KEY,
      BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.BASIC
    );
    setSearchParams(searchParams);
  };

  const cancelHandler = () => {
    dispatch(setVendorCreateForm(null));
    searchParams.set(
      BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.KEY,
      BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.BENEFICARY_DETAILS
    );
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!bankDetailsAlreadyAdded) {
      searchParams.set(
        BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.KEY,
        BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.BENEFICARY_DETAILS
      );
      setSearchParams(searchParams);
    }
  }, [bankDetailsAlreadyAdded]);

  return (
    <div className={`${classes} h-full w-full flex flex-col`}>
      {/* Content */}
      <div className="overflow-auto grow px-11">
        {currentStep ===
        BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.BENEFICARY_DETAILS ? (
          <div>
            <Text
              translationKey={
                currentPaymentProvider === PAYMENT_PROVIDERS.DECENTRO
                  ? "bankDetailsViaMail.rightColumn.paymentModeHeading"
                  : "bankDetailsViaMail.rightColumn.beneficiaryDetailsHeading"
              }
              classes="font-semibold text-lg block"
            />

            <form id="vendor-add-bank-details-form">
              <BankDetailsBasedOnProvider
                {...{
                  values,
                  errors,
                  handleChange,
                  paymentMethods,
                  isFetchingPaymentMethods,
                  setValues,
                  context,
                }}
                provider={currentPaymentProvider}
              />
            </form>
          </div>
        ) : null}

        {currentStep === BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.BASIC ? (
          <>
            <Text
              translationKey="bankDetailsViaMail.rightColumn.addBankDetailsHeading"
              classes="font-semibold text-lg"
            />
            <AddBankDetails fromPage />
          </>
        ) : null}

        {currentStep === BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.SUCCESS ? (
          <div className="flex flex-col mt-10">
            <Text
              translationKey="bankDetailsViaMail.rightColumn.finalPageDesc"
              classes="font-bold"
            />
            <Text
              translationKey="bankDetailsViaMail.rightColumn.finalPageHelperText"
              translationProps={{ entityName }}
              classes="text-neutral-500 text-sm"
            />
          </div>
        ) : null}
      </div>

      {/* Footer */}
      <div className="mt-auto">
        {/* fixed bottom-0 right-0 w-1/2 */}
        {currentStep === BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.BASIC ? (
          <div className="flex justify-end gap-2 p-4 slider-footer">
            <Button
              label="misc.cancel"
              onClick={cancelHandler}
              variant="tertiary"
              classes="px-5 py-3 font-medium"
              compact
            />
            <Button
              label="misc.submit"
              btnType="submit"
              compact
              variant="primary"
              classes="px-5 py-3 text-white font-medium"
              form="vendor-add-bank-details-form"
            />
          </div>
        ) : null}

        {currentStep ===
        BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.BENEFICARY_DETAILS ? (
          <div className="flex justify-end gap-2 p-4 slider-footer">
            <Button
              onClick={openAddBankDetailsPage}
              disabled={isFormButtonDisabled}
              label="billPay.vendors.createVendor.continue"
              variant="primary"
              classes="px-5 py-3"
              compact
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

BankDetailMethodComponent.propTypes = {
  classes: PropTypes.string,
  context: PropTypes.string,
};

export default BankDetailMethodComponent;
