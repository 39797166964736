import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useEffectSlider from "@/hooks/useEffectSlider";

import {
  fetchPaymentApprovers,
  setPaymentApprovers,
} from "@/store/reducers/payments";

import {
  approvalPolicyEnabledSelector,
  paymentsAutoApproveEnabledSelector,
  paymentsMakerCheckerEnabledSelector,
  payrollMakerCheckerEnabledSelector,
} from "@/store/selectors/client";
import {
  isFetchingPaymentApproversSelector,
  paymentApproversSelector,
} from "@/store/selectors/payments";
import {
  isFetchingBillPayrollCreationApprovalPolicySelector,
  purchaseBillQuoteSelector,
} from "@/store/selectors/purchase-bills";
import { selectedVendorSelector } from "@/store/selectors/vendors";

import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import ApproversAtLevel from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/ApproversAtLevel";
import LevelPill from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/LevelPill";
import { GENERATE_QUOTE_API_BODY_KEYS_RESPONSE } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  PAYMENTS,
  PAYMENT_APPROVERS_API_QPARAMS,
  PAYROLL,
} from "@/utils/constants/payments";
import { debounce } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

// TECH_DEBT: ApproversSection and ApproversSectionComponent both exist in the codebase, just keep one
// difference currently is that this file has pre and auto-approved logic added
export default function ApproversSection({
  searchParamKey,
  setOnClose,
  //
  values,
  context,
  beneficiaryCurrency,
  disabled,
  createBillFlowContext,
  isPayOutsideVolopay,
  saveCompleteForm,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const isMakerCheckerEnabled = useSelector(
    inPayrollContext
      ? payrollMakerCheckerEnabledSelector
      : paymentsMakerCheckerEnabledSelector
  );
  const vendor = useSelector(selectedVendorSelector);

  // fetch approvers
  const isFetching = useSelector(isFetchingPaymentApproversSelector);
  const approversResponse = useSelector(paymentApproversSelector);
  const isFetchingApprovalPolicy = useSelector(
    isFetchingBillPayrollCreationApprovalPolicySelector
  );

  const approvers = approversResponse?.approvers ?? [];
  const isPreApproved = useSelector(paymentsAutoApproveEnabledSelector);
  const approverPolicyEnabled = useSelector(approvalPolicyEnabledSelector);

  const numberOfLevels = approvers?.length;
  const isAutoApprovedFinal = !approverPolicyEnabled || approvers?.length === 0;

  const preApproved = !isMakerCheckerEnabled && isPreApproved;

  // collapse UI
  const [approversOpen, setApproverOpen] = useState(true);

  // for markup

  const ownerDepartmentId = vendor?.owner?.departmentId;
  const isLinkedToDepartment =
    Number(values?.projectId) === Number(ownerDepartmentId); // prefer linked to slider latest values
  const projectName = values?.projectName || vendor?.projectName; // prefer linked to slider latest values

  const quoteFromAPI = useSelector(purchaseBillQuoteSelector);
  const amount =
    quoteFromAPI?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.TOTAL_AMOUNT]?.value ??
    0;

  const currency =
    quoteFromAPI?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.TOTAL_AMOUNT]
      ?.currency;
  const projectId = values?.projectId || vendor?.projectId;

  const approversUseEffectCall = () => {
    const payload = {
      [PAYMENT_APPROVERS_API_QPARAMS.AMOUNT]: amount,
      [PAYMENT_APPROVERS_API_QPARAMS.CURRENCY]: currency,
      [PAYMENT_APPROVERS_API_QPARAMS.PROJECT_ID]: projectId,
    };

    if (inPayrollContext)
      payload[PAYMENT_APPROVERS_API_QPARAMS.POLICY_GROUP_TYPE] = PAYROLL;
    else {
      payload[PAYMENT_APPROVERS_API_QPARAMS.POLICY_GROUP_TYPE] = PAYMENTS;
    }

    if (amount !== "0.00" && currency && amount) {
      dispatch(fetchPaymentApprovers(payload));
    }
  };

  useEffect(debounce(approversUseEffectCall, 1000), [
    amount,
    currency,
    projectId,
  ]);

  useEffectSlider(
    () => () => {
      dispatch(setPaymentApprovers(null));
    },
    [],
    { searchParamKey, setOnClose }
  );

  const onLinkClick = () => {
    saveCompleteForm();
    searchParams.set(SLIDERS_SEARCH_PARAMS.payments.billLinkedTo, true);
    setSearchParams(searchParams);
  };

  if (isFetching || isFetchingApprovalPolicy)
    return (
      <div className="mt-10">
        <LoaderSkeleton
          size={["lg", "xl"]}
          borderRadius="200"
          fullWidth
          classes="py-4 my-2"
        />
        <LoaderSkeleton
          size="lg"
          borderRadius="200"
          fullWidth
          count={3}
          classes="py-4 my-2"
        />
      </div>
    );

  return (
    approversResponse && (
      <div
        className={`flex flex-col gap-6 mt-10 ${
          disabled ? "opacity-50 pointer-events-none" : ""
        }`}
      >
        <Text
          translationKey="billPay.bill.invoiceInbox.createBill.sections.approvers.title"
          classes="text-xl font-bold"
        />
        {/* {isAutoApprovedFinal ? (
          <div className="card-wrapper">
            <div className="flex items-center gap-2">
              <Icon name="CheckCircle" className="text-success-600" />
              <Text
                translationKey="common.autoApproved"
                classes="text-lg font-semibold"
              />
            </div>
            <Text
              translationKey="billPay.bill.invoiceInbox.createBill.sections.approvers.paymentWillBeAutoApproved"
              classes="text-sm font-medium text-neutral-500"
            />
          </div>
        ) : null} */}

        <div className="p-4 border rounded-lg border-neutral-200">
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setApproverOpen((prev) => !prev)}
            aria-label="fixed-part-approvers"
          >
            <div aria-label="left-side">
              <div className="flex items-end gap-2">
                <Text
                  translationKey={
                    inPayrollContext
                      ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.approvers.policyTitle"
                      : "billPay.bill.invoiceInbox.createBill.sections.approvers.policyTitle"
                  }
                  classes="text-lg font-semibold"
                />
                <LevelPill
                  containerClass="bg-neutral-50"
                  innerClasses="text-xs font-bold text-neutral-600"
                >
                  {numberOfLevels}{" "}
                  <Text
                    translationKey={
                      numberOfLevels === 1
                        ? "billPay.bill.invoiceInbox.createBill.sections.approvers.levelSingular"
                        : "billPay.bill.invoiceInbox.createBill.sections.approvers.levels"
                    }
                  />
                </LevelPill>
              </div>
              {inPayrollContext ? null : (
                <span
                  onClick={onLinkClick}
                  className="flex items-center gap-1 text-sm"
                >
                  <span className="font-medium underline decoration-neutral-500">
                    <Text
                      translationKey="billPay.bill.invoiceInbox.createBill.sections.approvers.paymentLinkedTo"
                      classes="text-neutral-500"
                    />
                    &nbsp;
                    <Text
                      translationKey={
                        isLinkedToDepartment
                          ? "billPay.bill.invoiceInbox.createBill.sections.approvers.linkedToDepartment"
                          : "billPay.bill.invoiceInbox.createBill.sections.approvers.linkedToProject"
                      }
                      classes="text-primary-500"
                      translationProps={{ name: projectName }}
                    />
                  </span>

                  <Icon name="ToLink" className="text-primary-500" />
                </span>
              )}
            </div>
            <div
              aria-label="right-side"
              className="flex items-center justify-end gap-4 text-neutral-500"
            >
              <Icon
                name={`${approversOpen ? "UpArrow" : "DownArrow"}`}
                className="transition-all ease-in cursor-pointer duration-600 stroke-neutral-500"
              />
            </div>
          </div>
          {/* Approvers list (expanded part) */}
          {/* TODO: prevent CLS (compression scroll UX annoyance) */}
          {approversOpen ? (
            <div className="mt-4">
              {isAutoApprovedFinal ? (
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <Icon name="CheckCircle" className="text-success-600" />
                    <Text
                      translationKey="common.autoApproved"
                      classes="text-lg font-semibold"
                    />
                  </div>
                  <Text
                    translationKey="billPay.bill.invoiceInbox.createBill.sections.approvers.paymentWillBeAutoApproved"
                    classes="text-sm font-medium text-neutral-500"
                  />
                </div>
              ) : null}
              {isAutoApprovedFinal
                ? null
                : Object.entries(approvers)?.map(([level, approver]) => {
                    return approver?.users?.length ? (
                      <ApproversAtLevel
                        key={level}
                        level={approver?.level}
                        levelNumber={1 + parseInt(level, 10)} // 1, 2, 3
                        approversList={approver?.users ?? []}
                        isMakerCheckerEnabled={isMakerCheckerEnabled}
                      />
                    ) : null;
                  })}
            </div>
          ) : null}
        </div>
      </div>
    )
  );
}

ApproversSection.propTypes = {
  searchParamKey: PropTypes.string,
  setOnClose: PropTypes.func,
  //
  values: PropTypes.object,
  context: PropTypes.string,
  beneficiaryCurrency: PropTypes.string,
  disabled: PropTypes.bool,
  createBillFlowContext: PropTypes.any,
  isPayOutsideVolopay: PropTypes.any,
  saveCompleteForm: PropTypes.func,
};
