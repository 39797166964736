import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useSearchParams } from "react-router-dom";

import {
  fetchOnboardingSectionApi,
  fetchOnboardingStepApi,
} from "@/store/reducers/onboarding";

import {
  currentOnboardingStepSelector,
  onboardingSectionSelector,
  stepsSelector,
} from "@/store/selectors/onboarding";

import Icon from "@/components/core/Icon";

import OnboardingStepOverview from "@/components/Onboarding/OnboardingStepOverview";

import { ONBOARDING_STEPS } from "@/constants/onboarding";
import DotShadow from "@/assets/images/DotShadow.png";
import DotShadow2 from "@/assets/images/DotShadow2.png";

function OnboardingLayout() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // selectors
  const currentOnboardingStep = useSelector(currentOnboardingStepSelector);
  const showOnboardingSteps =
    currentOnboardingStep !== ONBOARDING_STEPS.WELCOME;
  const allSteps = useSelector(stepsSelector);

  // useEffects

  useEffect(() => {
    // list of all the steps present your details, mobile verfication
    dispatch(fetchOnboardingStepApi());
  }, []);

  return (
    <div className="flex w-full h-screen">
      <div
        className={`${
          showOnboardingSteps ? "p-10" : ""
        } relative w-1/4 bg-neutral-50 overflow-y-hidden`}
      >
        <div className={showOnboardingSteps ? " " : "m-10"}>
          <Icon
            className="w-8.5 h-8.5 text-3xl text-neutral-700"
            name="VolopayLogo"
          />
        </div>

        {showOnboardingSteps ? (
          <OnboardingStepOverview
            totalSteps={allSteps?.length}
            stepsArray={allSteps}
            currentStep={currentOnboardingStep}
          />
        ) : (
          <div className="absolute top-0 flex flex-col justify-between w-full h-full gap-6">
            <img src={DotShadow} alt="" width="75%" height="50%" />

            <img src={DotShadow2} alt="" width="90%" height="40%" />
          </div>
        )}
      </div>
      <div className="relative w-9/12 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
}

export default OnboardingLayout;
