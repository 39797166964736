import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

// Pill used in top part of checkbox dropdown
export default function Pill({
  label,
  disabled,
  deletable = true,
  onDeleteHandler = () => {},
  pillClasses = "",
}) {
  return (
    <div
      className={`px-2 py-1 flex items-center gap-1 rounded-[4px]
      } ${pillClasses} ${
        !pillClasses?.includes("bg-")
          ? disabled
            ? "bg-neutral-500"
            : "bg-primary-50"
          : ""
      }
      }`}
      id="checkbox-dropdown-pill"
    >
      <Text
        translationKey={label}
        classes={`text-xs font-medium select-none ${
          disabled ? "text-neutral-500" : "text-neutral-800"
        }`}
      />
      {deletable && !disabled ? (
        <Icon
          name="Close"
          className="w-4 h-4 p-1 text-neutral-400 hover:cursor-pointer"
          handleClick={(e) => onDeleteHandler(false, e)}
          // `false` here, since it's like a checkbox being unchecked, but through a close button
        />
      ) : null}
    </div>
  );
}

Pill.propTypes = {
  label: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  deletable: PropTypes.bool,
  onDeleteHandler: PropTypes.func,
  pillClasses: PropTypes.string,
};
