import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { isFetchingAccountWalletSelector } from "@/store/selectors/client";
import { ledgerTabsSelector } from "@/store/selectors/ledgers";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import LedgerPageHelper from "@/components/Accounting/Ledger/LedgerPageHelper";
import { checkIfUserActionAllowed } from "@/utils/common";

import { ACCOUNTING_LEDGER_CTA_KEYS } from "@/constants/accounting";

export default function Ledger() {
  const ledgerTabs = useSelector(ledgerTabsSelector);

  const [selectedTab, setSelectedTab] = useState(ledgerTabs[0]);
  const [searchParams, setSearchParams] = useSearchParams();

  const isFetchingWallets = useSelector(isFetchingAccountWalletSelector);

  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  const queryParam = useParams();

  return (
    <div className="px-11">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <Text
            classes="text-sm font-semibold text-neutral-500"
            translationKey="accounting.title"
          />
          <Text
            classes="text-4xl font-bold text-neutral-800"
            translationKey="accounting.ledger.title"
          />
        </div>
        {checkIfUserActionAllowed(
          allNavUrls,
          userAllowedActions,
          ACCOUNTING_LEDGER_CTA_KEYS.REQUEST_STATEMENT
        ) ? (
          <Button
            label="accounting.ledger.ctaButtonLabel"
            onClick={() => {
              setSearchParams({
                accountId: selectedTab.accountId,
                requestStatement: true,
              });
            }}
            classes="w-[204px] h-11"
            type="default"
            variant="primary"
          />
        ) : null}
      </div>

      {!isFetchingWallets ? (
        <>
          <div className="mt-8">
            <Tabs
              items={ledgerTabs}
              selectedTab={selectedTab?.key}
              setCurrentTab={(tab) => setSelectedTab(tab)}
            />
          </div>

          <LedgerPageHelper
            type={queryParam.type}
            key={queryParam.type}
            tab={selectedTab}
          />
        </>
      ) : null}
    </div>
  );
}
