import PropTypes from "prop-types";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function GenericFormLoader({ fromPage }) {
  return (
    <div>
      {Array(3)
        .fill(null)
        .map((item, idx) => (
          <div className={`${fromPage ? "" : "mx-9"} mt-8 `} key={idx}>
            <LoaderSkeleton fullWidth size={[40]} />
            <div className="mt-6">
              <LoaderSkeleton fullWidth size={[24]} />
              <LoaderSkeleton fullWidth size={[24]} />
              <LoaderSkeleton fullWidth size={[24]} />
            </div>
          </div>
        ))}
    </div>
  );
}

GenericFormLoader.propTypes = {
  fromPage: PropTypes.bool,
};
