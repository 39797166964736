import React from "react";

import Text from "@/components/core/Text";

function PremiumContactSupport() {
  return (
    <Text
      classes="text-xs bg-primary-50 border rounded-full text-primary-400 px-2 font-semibold border-primary-200"
      translationKey="common.premiumContactSupport"
    />
  );
}

export default PremiumContactSupport;
