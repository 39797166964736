import PropTypes from "prop-types";
import React from "react";

import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { dateToString } from "@/utils/common";

function PayrollRequestTypeCell({
  user,
  showDescriptiveSection,
  payrollRequestDate,
}) {
  return (
    <div className="flex items-center gap-3 text-neutral-800">
      <ProfileWidget
        name={user?.name}
        img={user?.avatarUrl}
        className="text-sm font-semibold"
      >
        {showDescriptiveSection ? (
          <div className="flex flex-row items-center gap-3">
            {payrollRequestDate ? (
              <Text
                translationKey={dateToString(payrollRequestDate)}
                classes="text-neutral-500 font-semibold"
              />
            ) : null}

            <div className="flex flex-row items-center gap-2">
              {payrollRequestDate ? (
                <span className="text-xs font-semibold profile-widget-dot text-neutral-500" />
              ) : null}

              <Text
                translationKey="payroll.title"
                classes="text-warning-500 font-semibold text-sm"
              />
            </div>
          </div>
        ) : null}
      </ProfileWidget>
    </div>
  );
}

PayrollRequestTypeCell.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
  showDescriptiveSection: PropTypes.bool,
  payrollRequestDate: PropTypes.string,
};

export default PayrollRequestTypeCell;
