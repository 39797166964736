import React from "react";
import { useSearchParams } from "react-router-dom";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import GoogleLoginButton from "./GoogleLoginButton";
import LoginForm from "./LoginForm";
import OrDivider from "./OrDivider";
import SSOLogInButton from "./SSOLoginButton";

function LoginComponents() {
  const [searchParams, setSearchParams] = useSearchParams();

  const oneTimeToken = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.oneTimeToken
  );
  const actionType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.actionType
  );
  const fromSubdomain = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.fromSubdomain
  );
  const isConnectingEntity = !!oneTimeToken && !!actionType && !!fromSubdomain;

  return (
    <div>
      <div className="my-8 flex gap-4">
        <GoogleLoginButton />
        {!isConnectingEntity ? <SSOLogInButton /> : null}
      </div>
      <OrDivider />
      <div className="mt-8">
        <LoginForm />
      </div>
    </div>
  );
}

export default LoginComponents;
