import PropTypes from "prop-types";

import Button from "@/components/core/Button";

export default function SettingFooter({
  onDiscard = () => {},
  onSubmit = () => {},
  formId = "", // optional, meant to catch a detached <form />'s submit event

  discardText = "billPay.settings.sliders.automatedPayments.ctas.discard",
  submitText = "billPay.settings.sliders.automatedPayments.ctas.save",
  discardDisabled = false,
  submitDisabled = false,
  hideButton = false,
  showLoader = false,
}) {
  return hideButton ? null : (
    <div className="fixed p-6 slider-footer">
      <div className="flex items-center justify-end gap-3">
        {onDiscard ? (
          <Button
            label={discardText}
            classes="w-[150px] h-11 text-btn-lg text-neutral-500 font-semibold "
            variant="tertiary"
            onClick={onDiscard}
            disabled={discardDisabled || showLoader}
          />
        ) : null}
        {onSubmit ? (
          <Button
            label={submitText}
            classes="w-[150px] h-11 text-btn-lg text-white font-semibold"
            variant="primary"
            onClick={onSubmit}
            disabled={submitDisabled}
            btnType={formId ? "submit" : "button"}
            form={formId}
            showLoader={showLoader}
          />
        ) : null}
      </div>
    </div>
  );
}

SettingFooter.propTypes = {
  onDiscard: PropTypes.func,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,

  discardText: PropTypes.string,
  submitText: PropTypes.string,

  discardDisabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  showLoader: PropTypes.bool,
};
