import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { downloadBill, fetchCreditBills } from "@/store/reducers/credit";

import {
  creditBillsSelector,
  isFetchingCreditBillsSelector,
} from "@/store/selectors/credit";

import Accordion from "@/components/core/Accordion";
import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import EmptyData from "@/components/core/EmptyData";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import {
  CREDIT_PAYMENT_HISTORY_BADGE,
  CREDIT_PAYMENT_STATUS,
} from "@/utils/constants/credit";
import { amountToCurrency, dateToString } from "@/utils/common";

const RepaymentHistorySlider = () => {
  const dispatch = useDispatch();

  const cdBills = useSelector(creditBillsSelector);
  const isFetching = useSelector(isFetchingCreditBillsSelector);
  const [accordionData, setAccordionData] = useState([]);

  useEffect(() => {
    const accData = cdBills.list.map((data, index) => ({
      id: index,
      renderTitle: () => (
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-center">
            <Icon
              name={
                [
                  CREDIT_PAYMENT_STATUS.PAID,
                  CREDIT_PAYMENT_STATUS.MANUAL_PAID,
                ].includes(data.status)
                  ? "Refresh"
                  : "RefreshTime"
              }
              variant="rounded"
              bgClassName={
                [
                  CREDIT_PAYMENT_STATUS.PAID,
                  CREDIT_PAYMENT_STATUS.MANUAL_PAID,
                ].includes(data.status)
                  ? "bg-success-50"
                  : "bg-warning-50"
              }
              className={
                [
                  CREDIT_PAYMENT_STATUS.PAID,
                  CREDIT_PAYMENT_STATUS.MANUAL_PAID,
                ].includes(data.status)
                  ? "text-success-400"
                  : "text-warning-400"
              }
            />
            <div className="flex flex-col ml-2">
              <span className="sfont-semibold">
                {dateToString(data.startDate)} - {dateToString(data.endDate)}
              </span>
              {data.paymentDate ||
              data.status === CREDIT_PAYMENT_STATUS.MANUAL_PAID ? (
                <div>
                  <Text
                    classes="text-neutral-500 text-sm"
                    translationKey="company.credit.paidOn"
                    translationProps={{ date: dateToString(data.paymentDate) }}
                  />
                  {data.status === CREDIT_PAYMENT_STATUS.MANUAL_PAID ? (
                    <Text
                      classes="text-warning-500 text-sm ml-2"
                      translationKey="company.credit.paidManually"
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="font-semibold mr-2">
            {amountToCurrency(
              data.totalAmount.amount,
              data.totalAmount.currency
            )}
          </div>
        </div>
      ),
      renderContent: () => (
        <div>
          {data.paymentHistories.length > 0 && (
            <div className="bg-neutral-50 p-4 mt-4 rounded-lg">
              {data.paymentHistories.map((history, idx) => (
                <div
                  className={`flex justify-between ${idx === 0 ? "" : "mt-3"}`}
                  key={`${history.paymentDate}-${history?.id}`}
                >
                  <div className="text-neutral-500">
                    <Text translationKey="company.credit.repaymentOn" />
                    <span className="ml-2">
                      {history.paymentDate && dateToString(history.paymentDate)}
                    </span>
                  </div>
                  <div className="flex">
                    <Badge
                      variant={
                        CREDIT_PAYMENT_HISTORY_BADGE[history.paymentType]?.color
                      }
                      translationKey={
                        CREDIT_PAYMENT_HISTORY_BADGE[history.paymentType]?.text
                      }
                      classes="px-2"
                    />
                    <span className="font-semibold ml-2">
                      {amountToCurrency(
                        history.amount,
                        data.totalAmount.currency
                      )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="bg-neutral-50 p-4 mt-4 rounded-lg">
            <div className="flex justify-between">
              <Text
                translationKey="company.credit.billId"
                classes="text-neutral-500"
              />
              <div className="font-semibold">
                <span>{data.billNumber}</span>
              </div>
            </div>
            {data.dueDate ? (
              <div className="flex justify-between mt-2">
                <Text
                  translationKey="company.credit.dueDate"
                  classes="text-neutral-500"
                />
                <span className="font-semibold">
                  {data.dueDate ? dateToString(data.dueDate) : null}
                </span>
              </div>
            ) : null}
            {data.lateFee.amount > 0 || parseFloat(data.saasFee) > 0 ? (
              <div className="flex justify-between mt-2">
                <div className="flex items-center">
                  <Text
                    translationKey="company.credit.fee"
                    classes="text-neutral-500 mr-1"
                  />
                  <span id={`create-bill-amount-to-pay-tooltip-id-${data.id}`}>
                    <Icon
                      name="Info"
                      className="text-neutral-500 p-[1.5px] h-5 w-5 hover:cursor-pointer"
                    />
                    <Tooltip
                      id={`create-bill-amount-to-pay-tooltip-id-${data.id}`}
                      direction="top"
                    >
                      {parseFloat(data.saasFee) > 0 ? (
                        <div>
                          <Text translationKey="company.credit.interestFee" />
                          <span>
                            {`: ${amountToCurrency(
                              parseFloat(data.saasFee),
                              data.lateFee?.currency
                            )}`}
                          </span>
                        </div>
                      ) : null}
                      {parseFloat(data.lateFee?.amount) > 0 ? (
                        <div>
                          <Text
                            translationKey="company.credit.lateFee"
                            classes="ml-2"
                          />
                          <span>
                            {`: ${amountToCurrency(
                              data.lateFee?.amount,
                              data.lateFee?.currency
                            )}`}
                          </span>
                        </div>
                      ) : null}
                    </Tooltip>
                  </span>
                </div>
                <div className="font-semibold">
                  {amountToCurrency(
                    parseFloat(data?.totalFeeOutstandingAmount),
                    data.lateFee?.currency
                  )}
                </div>
              </div>
            ) : null}
            <div className="flex justify-between mt-2">
              <Text
                translationKey="company.credit.utilisedAmount"
                classes="text-neutral-500"
              />
              <div className="font-semibold">
                <span>
                  {amountToCurrency(
                    data.utilization,
                    data.totalAmount.currency
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              label="company.credit.buttonLabels.downloadStatement"
              preIcon="Download"
              variant="tertiary"
              classes="w-19 h-min text-xs font-bold text-primary-500 px-5 py-1.5 mt-4"
              onClick={() => dispatch(downloadBill(data.id))}
              disabled={
                ![
                  CREDIT_PAYMENT_STATUS.PAID,
                  CREDIT_PAYMENT_STATUS.GENERATED,
                  CREDIT_PAYMENT_STATUS.PARTIALLY_PAID,
                  CREDIT_PAYMENT_STATUS.MANUAL_PARTIALLY_PAID,
                  CREDIT_PAYMENT_STATUS.MANUAL_PAID,
                ].includes(data.status)
              }
            />
          </div>
        </div>
      ),
    }));
    setAccordionData(accData);
  }, [cdBills]);

  useEffect(() => {
    dispatch(fetchCreditBills());
  }, []);

  return (
    <div className="slider-content-container">
      <div className="pb-8 slider-content-core">
        <Text
          classes="payment-slider-heading"
          translationKey="company.credit.repaymentHistory"
        />
        {isFetching ? (
          <LoaderSkeleton fullWidth classes="py-8 mt-5" count="5" />
        ) : cdBills?.list?.length > 0 ? (
          <Accordion
            items={accordionData}
            keepOthersOpen={false}
            classes="mt-4"
            type="card"
          />
        ) : (
          <EmptyData
            title="company.credit.repayment.emptyTitle"
            imgSrc="emptyScreen.svg"
          >
            <Text
              translationKey="company.credit.repayment.emptyDesc"
              classes="text-sm text-neutral-800 font-medium text-center"
            />
          </EmptyData>
        )}
      </div>
    </div>
  );
};
export default RepaymentHistorySlider;
