import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function CheckboxDropdownGroupHeader({
  label,
  icon = "", // label icon, not back icon

  showBackArrow, // left arrow
  onBackHandler = () => {},
}) {
  return (
    <div
      aria-label="checkbox-dropdown-group-header"
      className="px-3 pt-2 pb-1 flex items-center gap-2.5"
    >
      {showBackArrow ? null : (
        <div onClick={onBackHandler}>
          <Icon
            name="BackArrow"
            className="w-5 h-5 rounded-sm cursor-pointer text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900"
          />
        </div>
      )}
      <div className="flex items-center gap-1">
        {/* label cold be string or JSX, use typeof and render conditionally */}
        {label ? (
          typeof label === typeof "" ? (
            <Text
              translationKey={label}
              classes="text-neutral-500 font-semibold text-xs"
            />
          ) : (
            label
          )
        ) : null}
        {icon ? (
          typeof icon === typeof "" ? (
            <Icon name={icon} className="w-4 h-4" />
          ) : (
            icon
          )
        ) : null}
      </div>
    </div>
  );
}

CheckboxDropdownGroupHeader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  showBackArrow: PropTypes.bool,
  onBackHandler: PropTypes.func,
};
