import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import "./styles.scss";

/**
 * A slider bar component for selecting a range of values.
 *
 * @param {Number} min - The minimum value of the slider.
 * @param {Number} max - The maximum value of the slider.
 * @param {Function} onChange - A callback function to handle value changes.
 * @param {Number} step - The step value for the slider.
 * @param {Number} minUtilization - The minimum utilization value.
 * @param {Number} maxUtilization - The maximum utilization value.
 * @param {String} thumbClasses - Additional CSS classes for the slider thumbs.
 * @param {Boolean} disabled - Indicates whether the slider is disabled.
 * @param {String} classes - Additional CSS classes for the slider container.
 * @param {String} size - The size of the slider.
 * @param {String} variant - The variant of the slider.
 * @param {Number} percentToSetLeftPositionFromLeft - The percentage to set the left position from the left side.
 * @param {Number} percentToSetLeftPositionFromRight - The percentage to set the left position from the right side.
 * @returns {JSX.Element} - The rendered SliderBar component.
 */

function SliderBar({
  min = 0,
  max = 100,
  onChange,
  step = 1,
  minUtilization,
  maxUtilization,
  thumbClasses,
  percentToSetLeftPositionFromLeft = 1.17,
  percentToSetLeftPositionFromRight = 1.17,
  classes,
  size = "w-[400px]",
  variant,
  disabled,
}) {
  const [minVal, setMinVal] = useState(parseInt(minUtilization, 10) || 0);
  const [maxVal, setMaxVal] = useState(parseInt(maxUtilization, 10) || 100);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);
  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }

    onChange(minVal, maxVal);
  }, [minVal, maxVal]);

  return (
    <div className={`mt-5 ${classes}`}>
      <input
        type="range"
        step={step}
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className={`thumb z-[3] bg-neutral-100 ${size}`}
        disabled={disabled}
      />
      {minVal === 0 ? null : (
        <div
          ref={minValRef}
          className={`absolute p-1 m-2 text-center ${thumbClasses}`}
          style={{
            left: `${getPercent(minVal / percentToSetLeftPositionFromLeft)}%`,
          }}
        >
          {`${minVal} %`}
          <div className="box top-[-15%] left-[40%]" />
        </div>
      )}

      <input
        type="range"
        step={step}
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className={`thumb z-[3] ${size}`}
        disabled={disabled}
      />
      {maxVal === 100 ? null : (
        <div
          ref={maxValRef}
          className={`absolute p-1 m-2 text-center ${thumbClasses}`}
          style={{
            left: `${getPercent(maxVal / percentToSetLeftPositionFromRight)}%`,
          }}
        >
          {`${maxVal} %`}
          <div className="box top-[-15%] left-[40%]" />
        </div>
      )}

      <div className={`relative ${size}`}>
        <div className="absolute w-full h-2 rounded-lg bg-neutral-200 z-1" />
        <div
          ref={range}
          className={`absolute rounded-lg h-[9px] w-full z-2 ${variant} `}
        />
      </div>
      <div className="flex justify-between pt-3 text-xs text-neutral-500">
        <div>{`${min} %`}</div>
        <div>{`${max} %`}</div>
      </div>
    </div>
  );
}

export default SliderBar;
SliderBar.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  minUtilization: PropTypes.number,
  maxUtilization: PropTypes.number,
  thumbClasses: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  percentToSetLeftPositionFromLeft: PropTypes.number,
  percentToSetLeftPositionFromRight: PropTypes.number,
};
