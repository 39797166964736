// utils, constant file imports
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// reducers
import { setSettings } from "@/store/reducers/accounting";

// selectors
import {
  accountingIntegrationDefaultValuesSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";
import {
  accountingCategoryTagsSelector,
  accountingNonCategoryTags,
  accountingTaxCodeTagsSelector,
} from "@/store/selectors/tags";

// core components
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import { camelToSnake } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function ManageAccountTagsSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryOptions = useSelector(accountingCategoryTagsSelector)?.options;
  const nonCategoryTags = useSelector(accountingNonCategoryTags);
  const taxCodeOptions = useSelector(accountingTaxCodeTagsSelector)?.options;
  const isLoading = useSelector(isLoadingAccountingIntegrationsSelector);

  const [areFieldDefined, setAreFieldDefined] = useState(false);

  const currentSearchParam = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.integrations.manageAccountingTags
  );

  const [accountingTagsSelected, setAccountingTagsSelected] = useState(
    useSelector(accountingIntegrationDefaultValuesSelector)
  );

  const [showSelectors, setShowSelectors] = useState(
    accountingTagsSelected
      ? Object.fromEntries(
          Object.entries(accountingTagsSelected)?.map(([key, value]) => [
            key,
            false,
          ])
        )
      : []
  );

  const convertKeysToSnakeCase = (obj) => {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(convertKeysToSnakeCase);
    }

    return Object.keys(obj).reduce((acc, key) => {
      const snakeCaseKey = camelToSnake(key);
      acc[snakeCaseKey] = convertKeysToSnakeCase(obj[key]);
      return acc;
    }, {});
  };

  useEffect(() => {
    if (accountingTagsSelected)
      setAreFieldDefined(
        Object.values(accountingTagsSelected).some(
          (value) => value?.category?.tagId
        )
      );
  }, [accountingTagsSelected]);

  const handleSaveChange = async () => {
    const action = await dispatch(
      setSettings({
        default_accounting_values: convertKeysToSnakeCase(
          accountingTagsSelected
        ),
      })
    );

    if (setSettings.fulfilled.match(action)) {
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.accounting.integrations.manageAccountingTags
      );
      setSearchParams(searchParams);
    }
  };

  const handleValueChange = ({ option, key, tagToUpdate }) => {
    const updatedItem = {
      tagId: option.tagId,
      tagValueId: option.tagValueId,
      value: option.name,
    };

    setAccountingTagsSelected((prev) => ({
      ...prev,
      [key]: { ...prev[key], [tagToUpdate]: updatedItem },
    }));
  };

  const handleCancel = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.manageAccountingTags
    );
    setSearchParams(searchParams);
  };

  const formatString = (str) => {
    if (!str) return "";
    return str
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  return (
    <div className="slider-content-container flex flex-col ">
      <div className="slider-content-core pb-9">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-1">
            <SliderHeader
              heading="accounting.integrations.integrationSettings.manageAccountingTags.title"
              headingClasses="text-3xl font-bold text-neutral-800"
              refFlag
            />
            <SliderHeader
              subText="accounting.integrations.integrationSettings.manageAccountingTags.description"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
          </div>

          <div className="text-base flex flex-col gap-6">
            <SliderHeader
              heading="accounting.integrations.integrationSettings.manageAccountingTags.defaultAccountingTags.title"
              subText="accounting.integrations.integrationSettings.manageAccountingTags.defaultAccountingTags.description"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />

            {Object.entries(accountingTagsSelected || {})?.map(
              ([key, item]) => (
                <div
                  className="flex flex-col gap-4 p-4 border border-neutral-200 rounded-lg"
                  key={`manage-tags-${key}`}
                >
                  <div className="flex">
                    <Text
                      translationKey={formatString(key)}
                      classes="w-full text-base font-semibold"
                    />
                    <div className="flex items-center gap-3">
                      {item?.category?.tagId ? (
                        <Text
                          translationKey="accounting.integrations.labels.defined"
                          classes="!w-auto h-auto py-0.5 px-2 text-xs text-success-600 font-bold bg-success-50 border border-success-200 rounded-full"
                        />
                      ) : (
                        <Text
                          translationKey="accounting.integrations.labels.notDefined"
                          classes="!w-13.5 h-auto py-0.5 px-2 text-xs text-neutral-600 font-bold bg-neutral-50 border border-neutral-200 rounded-full"
                        />
                      )}
                      <Icon
                        name={`${
                          showSelectors[key] ? "ChevronUp" : "ChevronDown"
                        }`}
                        className="w-6 h-6 p-1 text-neutral-500 cursor-pointer"
                        handleClick={() => {
                          if (key) {
                            setShowSelectors((prev) => ({
                              ...prev,
                              [key]: !prev[key],
                            }));
                          }
                        }}
                      />
                    </div>
                  </div>
                  {showSelectors[key] ? (
                    <div className="w-full flex flex-col gap-6">
                      <div>
                        <VpSelect
                          label="accounting.integrations.integrationSettings.manageAccountingTags.category.title"
                          labelTranslationProp={{
                            name: formatString(
                              currentSearchParam?.replace("_", " ")
                            ),
                          }}
                          placeholder="accounting.integrations.integrationSettings.manageAccountingTags.category.placeholder"
                          labelStyleClasses="w-full font-medium"
                          menuPosition="absolute"
                          options={categoryOptions || []}
                          optionsDisplayKey="alias"
                          value={item?.category?.tagValueId || ""}
                          valueKey="tagValueId"
                          handleChange={(option) =>
                            handleValueChange({
                              option,
                              key,
                              tagToUpdate: "category",
                            })
                          }
                        />
                      </div>
                      <div>
                        <VpSelect
                          label="accounting.integrations.integrationSettings.manageAccountingTags.taxCodes.title"
                          labelTranslationProp={{
                            name: formatString(
                              currentSearchParam?.replace("_", " ")
                            ),
                          }}
                          placeholder="accounting.integrations.integrationSettings.manageAccountingTags.taxCodes.placeholder"
                          labelStyleClasses="py-4.5 font-medium"
                          menuPosition="absolute"
                          options={taxCodeOptions || []}
                          optionsDisplayKey="alias"
                          value={item?.taxCode?.tagValueId || ""}
                          valueKey="tagValueId"
                          handleChange={(option) =>
                            handleValueChange({
                              option,
                              key,
                              tagToUpdate: "taxCode",
                            })
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className="slider-footer px-6 py-4">
        <div className="flex justify-end items-center gap-6 text-base font-semibold">
          <Button
            label="accounting.integrations.buttonLabel.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            onClick={() => {
              handleCancel();
            }}
          />
          <Button
            label="accounting.integrations.buttonLabel.saveChanges"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            disabled={!areFieldDefined}
            onClick={handleSaveChange}
            showLoader={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
