import XOF from "./XOF.svg";
import TMT from "./TMT.svg";
import BIF from "./BIF.svg";
import BRL from "./BRL.svg";
import YER from "./YER.svg";
import GNF from "./GNF.svg";
import USD from "./USD.svg";
import RUB from "./RUB.svg";
import KMF from "./KMF.svg";
import COP from "./COP.svg";
import HKD from "./HKD.svg";
import CVE from "./CVE.svg";
import MYR from "./MYR.svg";
import XCD from "./XCD.svg";
import SRD from "./SRD.svg";
import JPY from "./JPY.svg";
import DKK from "./DKK.svg";
import RON from "./RON.svg";
import BGN from "./BGN.svg";
import GBP from "./GBP.svg";
import BDT from "./BDT.svg";
import SGD from "./SGD.svg";
import MUR from "./MUR.svg";
import MZN from "./MZN.svg";
import OMR from "./OMR.svg";
import VUV from "./VUV.svg";
import MAD from "./MAD.svg";
import BHD from "./BHD.svg";
import KYD from "./KYD.svg";
import PAB from "./PAB.svg";
import LRD from "./LRD.svg";
import ZMK from "./ZMK.svg";
import ANG from "./ANG.svg";
import TTD from "./TTD.svg";
import AOA from "./AOA.svg";
import AWG from "./AWG.svg";
import PYG from "./PYG.svg";
import ILS from "./ILS.svg";
import NIO from "./NIO.svg";
import LKR from "./LKR.svg";
import WST from "./WST.svg";
import MWK from "./MWK.svg";
import SSP from "./SSP.svg";
import ALL from "./ALL.svg";
import STD from "./STD.svg";
import AED from "./AED.svg";
import MGA from "./MGA.svg";
import TJS from "./TJS.svg";
import JMD from "./JMD.svg";
import SAR from "./SAR.svg";
import BZD from "./BZD.svg";
import PEN from "./PEN.svg";
import BTN from "./BTN.svg";
import PKR from "./PKR.svg";
import GHS from "./GHS.svg";
import IRR from "./IRR.svg";
import XPF from "./XPF.svg";
import MDL from "./MDL.svg";
import SZL from "./SZL.svg";
import HTG from "./HTG.svg";
import KGS from "./KGS.svg";
import BAM from "./BAM.svg";
import LYD from "./LYD.svg";
import NOK from "./NOK.svg";
import QAR from "./QAR.svg";
import DZD from "./DZD.svg";
import HNL from "./HNL.svg";
import AFN from "./AFN.svg";
import MKD from "./MKD.svg";
import HUF from "./HUF.svg";
import BBD from "./BBD.svg";
import GIP from "./GIP.svg";
import KRW from "./KRW.svg";
import UYU from "./UYU.svg";
import NAD from "./NAD.svg";
import ERN from "./ERN.svg";
import EST from "./EST.svg";
import BOB from "./BOB.svg";
import CHF from "./CHF.svg";
import BND from "./BND.svg";
import BWP from "./BWP.svg";
import RSD from "./RSD.svg";
import CDF from "./CDF.svg";
import DOP from "./DOP.svg";
import SYP from "./SYP.svg";
import ARS from "./ARS.svg";
import BMD from "./BMD.svg";
import SCR from "./SCR.svg";
import JOD from "./JOD.svg";
import SOS from "./SOS.svg";
import LBP from "./LBP.svg";
import LAK from "./LAK.svg";
import ISK from "./ISK.svg";
import UAH from "./UAH.svg";
import KES from "./KES.svg";
import VEF from "./VEF.svg";
import MRO from "./MRO.svg";
import EGP from "./EGP.svg";
import PHP from "./PHP.svg";
import GEL from "./GEL.svg";
import KPW from "./KPW.svg";
import SLL from "./SLL.svg";
import THB from "./THB.svg";
import CRC from "./CRC.svg";
import TRY from "./TRY.svg";
import IQD from "./IQD.svg";
import IDR from "./IDR.svg";
import LTL from "./LTL.svg";
import PGK from "./PGK.svg";
import UZS from "./UZS.svg";
import BYR from "./BYR.svg";
import KHR from "./KHR.svg";
import NZD from "./NZD.svg";
import SBD from "./SBD.svg";
import VND from "./VND.svg";
import TWD from "./TWD.svg";
import CAD from "./CAD.svg";
import ETB from "./ETB.svg";
import AMD from "./AMD.svg";
import FJD from "./FJD.svg";
import DJF from "./DJF.svg";
import ZAR from "./ZAR.svg";
import MMK from "./MMK.svg";
import LSL from "./LSL.svg";
import EUR from "./EUR.svg";
import GYD from "./GYD.svg";
import AZN from "./AZN.svg";
import TND from "./TND.svg";
import XAF from "./XAF.svg";
import CUP from "./CUP.svg";
import UGX from "./UGX.svg";
import NGN from "./NGN.svg";
import GMD from "./GMD.svg";
import GTQ from "./GTQ.svg";
import MOP from "./MOP.svg";
import MNT from "./MNT.svg";
import SHP from "./SHP.svg";
import AUD from "./AUD.svg";
import TZS from "./TZS.svg";
import ZWL from "./ZWL.svg";
import MVR from "./MVR.svg";
import MXN from "./MXN.svg";
import CZK from "./CZK.svg";
import DEU from "./DEU.svg";
import CNY from "./CNY.svg";
import TOP from "./TOP.svg";
import HRK from "./HRK.svg";
import NPR from "./NPR.svg";
import BSD from "./BSD.svg";
import INR from "./INR.svg";
import KWD from "./KWD.svg";
import PLN from "./PLN.svg";
import KZT from "./KZT.svg";
import RWF from "./RWF.svg";
import SDG from "./SDG.svg";
import CLP from "./CLP.svg";
import FKP from "./FKP.svg";

export const FLAGS = {
  XOF,
  TMT,
  BIF,
  BRL,
  YER,
  GNF,
  USD,
  RUB,
  KMF,
  COP,
  HKD,
  CVE,
  MYR,
  XCD,
  SRD,
  JPY,
  DKK,
  RON,
  BGN,
  GBP,
  BDT,
  SGD,
  MUR,
  MZN,
  OMR,
  VUV,
  MAD,
  BHD,
  KYD,
  PAB,
  LRD,
  ZMK,
  ANG,
  TTD,
  AOA,
  AWG,
  PYG,
  ILS,
  NIO,
  LKR,
  WST,
  MWK,
  SSP,
  ALL,
  STD,
  AED,
  MGA,
  TJS,
  JMD,
  SAR,
  BZD,
  PEN,
  BTN,
  PKR,
  GHS,
  IRR,
  XPF,
  MDL,
  SZL,
  HTG,
  KGS,
  BAM,
  LYD,
  NOK,
  QAR,
  DZD,
  HNL,
  AFN,
  MKD,
  HUF,
  BBD,
  GIP,
  KRW,
  UYU,
  NAD,
  ERN,
  EST,
  BOB,
  CHF,
  BND,
  BWP,
  RSD,
  CDF,
  DOP,
  SYP,
  ARS,
  BMD,
  SCR,
  JOD,
  SOS,
  LBP,
  LAK,
  ISK,
  UAH,
  KES,
  VEF,
  MRO,
  EGP,
  PHP,
  GEL,
  KPW,
  SLL,
  THB,
  CRC,
  TRY,
  IQD,
  IDR,
  LTL,
  PGK,
  UZS,
  BYR,
  KHR,
  NZD,
  SBD,
  VND,
  TWD,
  CAD,
  ETB,
  AMD,
  FJD,
  DJF,
  ZAR,
  MMK,
  LSL,
  EUR,
  GYD,
  AZN,
  TND,
  XAF,
  CUP,
  UGX,
  NGN,
  GMD,
  GTQ,
  MOP,
  MNT,
  SHP,
  AUD,
  TZS,
  ZWL,
  MVR,
  MXN,
  CZK,
  DEU,
  CNY,
  TOP,
  HRK,
  NPR,
  BSD,
  INR,
  KWD,
  PLN,
  KZT,
  RWF,
  SDG,
  CLP,
  FKP,
};
