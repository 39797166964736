import { createSelector } from "@reduxjs/toolkit";

export const selectAnalyticsStore = (store) => store.clientAnalytics;

export const transactionsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.transactions.list
);

export const isFetchingTransactionsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.transactions.isFetching
);

export const transactionsListParamsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.transactions
);

export const transactionsListHasMoreSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics?.transactions?.hasMore
);

export const topSpendersListSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.topSpenders.list
);

export const isFetchingTopSpendersSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.topSpenders.isFetching
);

export const topSpendersListParamsSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.topSpenders
);

export const spendSelector = createSelector(
  selectAnalyticsStore,
  (analytics) => analytics.spends
);
