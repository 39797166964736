import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchCardActionGuidelinesVideos } from "@/store/reducers/cards";

import {
  cardActionGuidelineVideosSelector,
  selectedCardSelector,
} from "@/store/selectors/cards";
import { userPermissionSelector, userSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import VideoSection from "@/components/core/VideoSection";
import { camelToKebab } from "@/utils/common";

import {
  CARDS_ALL_SLIDER_MENU_KEYS,
  CARD_OPERATION_TYPES,
  INTERNATIONAL_CARD_OPERATION_SLIDER_CONFIG,
  VIDEO_PAGE_CONTEXT,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";
import { API_KEYS_MAPPING } from "@/api/apiKeys";

import VerticalStepper from "../CardSlider/Common/VerticalStepper";
import IntentUrlActionConfirmationModal from "./IntentUrlActionConfirmationModal";

export default function InternationalCardOperationSlider() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const operationType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.cards.internationalCardOperationSlider
  );
  const cardInfo = useSelector(selectedCardSelector);
  const selectedUser = useSelector(userSelector);
  const selectedUseriD = selectedUser?.id;
  const isMyCard = cardInfo?.cardHolder?.id === selectedUseriD;
  const mainTitle =
    INTERNATIONAL_CARD_OPERATION_SLIDER_CONFIG?.[operationType]?.mainTitle;
  const sectionHeaderTitle =
    INTERNATIONAL_CARD_OPERATION_SLIDER_CONFIG?.[operationType]
      ?.sectionHeaderTitle;

  const ref = useLeftHeaderTitle({
    title: mainTitle,
  });

  const cardActionLink = cardInfo?.cardActionLink;
  const [open, setOpen] = useState(false);
  const permissionsList = useSelector(userPermissionSelector);
  const cardActionGuidelineVideos = useSelector(
    cardActionGuidelineVideosSelector
  );
  const locationObject = useLocation();
  const requiredPageContextForGuidelineVideos =
    locationObject.pathname.includes(
      ROUTES.myVolopay.cards.physicalCards.absolutePath
    )
      ? VIDEO_PAGE_CONTEXT.MY_VOLOPAY
      : VIDEO_PAGE_CONTEXT.CARDS;
  const videos =
    cardActionGuidelineVideos[requiredPageContextForGuidelineVideos]?.[
      operationType
    ];

  const cardState = useLoadingErrorInjector({
    apiKey: API_KEYS_MAPPING.CARD_ACTIONS_GUIDELINE_VIDEOS,
    showLoader: false,
    error: {
      header: "",
      classes: "h-full flex items-center justify-center",
    },
  });

  useEffect(() => {
    if (!Object.keys(cardActionGuidelineVideos)?.length) {
      dispatch(fetchCardActionGuidelinesVideos());
    }
  }, []);

  function getStepperListBasedOnActionType() {
    let _stepperList = [
      {
        title:
          "cards.cardSlider.internationalCard.commonStepperGuideLines.redirectToAdminPortal.title",
        titleTranslationProps: {
          roleSpecificText: isMyCard
            ? t("cards.cardSlider.internationalCard.cardHolderPortal")
            : t("cards.cardSlider.internationalCard.adminPortal"),
        },
        description:
          "cards.cardSlider.internationalCard.commonStepperGuideLines.redirectToAdminPortal.description",
        descriptionTranslationProps: {
          roleSpecificText: isMyCard
            ? t("cards.cardSlider.internationalCard.cardHolderPortal")
            : t("cards.cardSlider.internationalCard.adminPortal"),
        },
      },
      {
        title:
          "cards.cardSlider.internationalCard.commonStepperGuideLines.loginToAdminPortal.title",
        titleTranslationProps: {
          roleSpecificText: isMyCard
            ? t("cards.cardSlider.internationalCard.cardHolderPortal")
            : t("cards.cardSlider.internationalCard.adminPortal"),
        },
        description:
          "cards.cardSlider.internationalCard.commonStepperGuideLines.loginToAdminPortal.description",
        descriptionTranslationProps: {
          roleSpecificText: isMyCard
            ? t("cards.cardSlider.internationalCard.cardHolderPortal")
            : t("cards.cardSlider.internationalCard.adminPortal"),
        },
      },
    ];

    switch (operationType) {
      case CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD:
        _stepperList = _stepperList.concat([
          {
            title:
              "cards.cardSlider.internationalCard.freezeCard.stepper.freezeCard.title",
            descriptionList: [
              "cards.cardSlider.internationalCard.freezeCard.stepper.freezeCard.descriptionList.first",
              "cards.cardSlider.internationalCard.freezeCard.stepper.freezeCard.descriptionList.second",
            ],
          },
          {
            title:
              "cards.cardSlider.internationalCard.freezeCard.stepper.confirmFreezingCard.title",
            description:
              "cards.cardSlider.internationalCard.freezeCard.stepper.confirmFreezingCard.description",
          },
        ]);
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.BLOCK:
        _stepperList = _stepperList.concat([
          {
            title:
              "cards.cardSlider.internationalCard.blockCard.stepper.blockCard.title",
            descriptionList: [
              "cards.cardSlider.internationalCard.blockCard.stepper.blockCard.descriptionList.first",
              "cards.cardSlider.internationalCard.blockCard.stepper.blockCard.descriptionList.second",
            ],
          },
          {
            title:
              "cards.cardSlider.internationalCard.blockCard.stepper.confirmBlockingCard.title",
            description:
              "cards.cardSlider.internationalCard.blockCard.stepper.confirmBlockingCard.description",
          },
        ]);
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD_CONTROLS:
        _stepperList.push({
          title:
            "cards.cardSlider.internationalCard.editCardControls.stepper.manageCardSettings.title",
          description:
            "cards.cardSlider.internationalCard.editCardControls.stepper.manageCardSettings.description",
        });
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.EDIT_TRANSACTION_LIMIT:
        _stepperList.push({
          title:
            "cards.cardSlider.internationalCard.editTransactionLimit.stepper.changeTransactionLimit.title",
          description:
            "cards.cardSlider.internationalCard.editTransactionLimit.stepper.changeTransactionLimit.description",
        });
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE:
        _stepperList = _stepperList.concat([
          {
            title:
              "cards.cardSlider.internationalCard.unfreezeCard.stepper.unfreezeCard.title",
            descriptionList: [
              "cards.cardSlider.internationalCard.unfreezeCard.stepper.unfreezeCard.descriptionList.first",
              "cards.cardSlider.internationalCard.unfreezeCard.stepper.unfreezeCard.descriptionList.second",
            ],
          },
          {
            title:
              "cards.cardSlider.internationalCard.unfreezeCard.stepper.confirmUnFreezingCard.title",
            description:
              "cards.cardSlider.internationalCard.unfreezeCard.stepper.confirmUnFreezingCard.description",
          },
        ]);
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN:
        _stepperList.unshift({
          title:
            "cards.cardSlider.internationalCard.changePin.stepper.initiateChangePin.title",
          description:
            "cards.cardSlider.internationalCard.changePin.stepper.initiateChangePin.description",
        });

        _stepperList.push({
          title:
            "cards.cardSlider.internationalCard.changePin.stepper.changePin.title",
          description:
            "cards.cardSlider.internationalCard.changePin.stepper.changePin.description",
        });
        break;

      default:
        break;
    }
    return _stepperList;
  }

  function handleContinueBtnClick() {
    if (cardActionLink) {
      window.open(cardActionLink);
    }
  }

  function getActionConfirmationButton() {
    const buttonConfig = {
      label: "",
      classes: "",
      variant: "",
      onClick: () => {},
    };

    const modalConfig = {
      title: "",
      titleClasses: "",
      description: "",
      descriptionClasses: "text-neutral-500",
      modalClasses: "",
      primaryButtonConfig: {
        label: "",
        classes: "",
        variant: "",
        btnKey: "",
      },
      cancelToasterText: {
        title: "",
        titleTranslationProps: "",
        description: "",
        descriptionTranslationProps: "",
      },
      successToasterText: {
        title: "",
        titleTranslationProps: "",
        description: "",
        descriptionTranslationProps: "",
      },
    };

    switch (operationType) {
      case CARDS_ALL_SLIDER_MENU_KEYS.BLOCK:
        buttonConfig.label =
          "cards.cardSlider.internationalCard.blockCard.buttons.secondary";
        buttonConfig.classes =
          "border border-danger-300 bg-danger-50 text-danger-500 w-fit px-4";
        buttonConfig.onClick = () => setOpen(true);

        modalConfig.title =
          "cards.cardSlider.internationalCard.blockCard.modalConfig.title";
        modalConfig.titleClasses = "text-danger-500";
        modalConfig.description =
          "cards.cardSlider.internationalCard.blockCard.modalConfig.description";
        modalConfig.primaryButtonConfig.label =
          "cards.cardSlider.internationalCard.blockCard.modalConfig.buttons.primary";
        modalConfig.primaryButtonConfig.classes = "bg-danger-500 text-white";
        modalConfig.primaryButtonConfig.btnKey =
          CARDS_ALL_SLIDER_MENU_KEYS.BLOCK;
        modalConfig.cancelToasterText.title =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.failure.title";
        modalConfig.cancelToasterText.titleTranslationProps = {
          cardAction: CARDS_ALL_SLIDER_MENU_KEYS.BLOCK,
        };
        modalConfig.cancelToasterText.description =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.failure.description";
        modalConfig.cancelToasterText.descriptionTranslationProps = {
          cardAction: `${CARDS_ALL_SLIDER_MENU_KEYS.BLOCK}ed`,
        };
        modalConfig.successToasterText.title =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.success.title";
        modalConfig.successToasterText.titleTranslationProps = {
          cardAction: CARDS_ALL_SLIDER_MENU_KEYS.BLOCK,
        };
        modalConfig.successToasterText.description =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.success.description";

        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD:
        buttonConfig.label =
          "cards.cardSlider.internationalCard.freezeCard.buttons.secondary";
        buttonConfig.variant = "secondary";
        buttonConfig.classes = "w-fit px-4";
        buttonConfig.onClick = () => setOpen(true);

        modalConfig.title =
          "cards.cardSlider.internationalCard.freezeCard.modalConfig.title";
        modalConfig.description =
          "cards.cardSlider.internationalCard.freezeCard.modalConfig.description";
        modalConfig.primaryButtonConfig.label =
          "cards.cardSlider.internationalCard.freezeCard.modalConfig.buttons.primary";
        modalConfig.primaryButtonConfig.variant = "primary";
        modalConfig.primaryButtonConfig.btnKey =
          CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD;
        modalConfig.cancelToasterText.title =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.failure.title";
        modalConfig.cancelToasterText.titleTranslationProps = {
          cardAction: CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD,
        };
        modalConfig.cancelToasterText.description =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.failure.description";
        modalConfig.cancelToasterText.descriptionTranslationProps = {
          cardAction: "frozen",
        };
        modalConfig.successToasterText.title =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.success.title";
        modalConfig.successToasterText.titleTranslationProps = {
          cardAction: CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD,
        };
        modalConfig.successToasterText.description =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.success.description";
        break;

      case CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE:
        buttonConfig.label =
          "cards.cardSlider.internationalCard.unfreezeCard.buttons.secondary";
        buttonConfig.variant = "secondary";
        buttonConfig.classes = "w-fit px-4";
        buttonConfig.onClick = () => setOpen(true);

        modalConfig.title =
          "cards.cardSlider.internationalCard.unfreezeCard.modalConfig.title";
        modalConfig.description =
          "cards.cardSlider.internationalCard.unfreezeCard.modalConfig.description";
        modalConfig.primaryButtonConfig.label =
          "cards.cardSlider.internationalCard.unfreezeCard.modalConfig.buttons.primary";
        modalConfig.primaryButtonConfig.variant = "primary";
        modalConfig.primaryButtonConfig.btnKey =
          CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE;
        modalConfig.cancelToasterText.title =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.failure.title";
        modalConfig.cancelToasterText.titleTranslationProps = {
          cardAction: CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE,
        };
        modalConfig.cancelToasterText.description =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.failure.description";
        modalConfig.cancelToasterText.descriptionTranslationProps = {
          cardAction: "unfrozen",
        };
        modalConfig.successToasterText.title =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.success.title";
        modalConfig.successToasterText.titleTranslationProps = {
          cardAction: CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE,
        };
        modalConfig.successToasterText.description =
          "cards.cardSlider.internationalCard.cardActionsModalConfig.toasterMessages.success.description";
        break;

      default:
        buttonConfig.label = "Cancel";
        buttonConfig.classes = "w-15.5 text-neutral-500";
        buttonConfig.variant = "tertiary";
        buttonConfig.onClick = () => {
          searchParams.delete(
            SLIDERS_SEARCH_PARAMS.cards.internationalCardOperationSlider
          );
          setSearchParams(searchParams);
        };
        break;
    }

    return { buttonConfig, modalConfig };
  }

  const {
    buttonConfig: {
      label: secondaryButtonLabel,
      variant: secondaryButtonVariant,
      classes: secondaryButtonClasses,
      onClick: handleSecondaryButtonClick,
    },
    modalConfig,
  } = getActionConfirmationButton();

  const isLoggedInUserCardManager = useMemo(() => {
    if (permissionsList?.length > 0) {
      return permissionsList?.find(
        (permission) => permission?.name === "card:manage"
      );
    }

    return false;
  }, [permissionsList]);

  if (
    operationType === CARD_OPERATION_TYPES.EDIT_TRANSACTION_LIMIT &&
    !isLoggedInUserCardManager
  ) {
    return (
      <div className="flex flex-col gap-8 slider-content-core">
        <Text
          translationKey={mainTitle}
          classes="text-3xl font-bold"
          refProp={ref}
        />

        <Alert
          title="cards.cardSlider.internationalCard.editTransactionLimit.alert.title"
          description="cards.cardSlider.internationalCard.editTransactionLimit.alert.description"
          variant="warning"
        />
      </div>
    );
  }

  return (
    <>
      <div className="mb-8 px-9" ref={cardState?.attach}>
        <Text
          translationKey={mainTitle}
          classes="text-3xl font-bold"
          refProp={ref}
        />
      </div>

      <div className="flex flex-col gap-8 mb-8 slider-content-core">
        <VerticalStepper
          stepperList={getStepperListBasedOnActionType()}
          sectionHeaderTitle={sectionHeaderTitle}
          sectionFooterText="cards.cardSlider.internationalCard.commonFooterNote"
        />

        <VideoSection videos={[videos]} videoSectionClasses="p-3 pt-0" />
      </div>

      <div className="flex items-center justify-end gap-4 px-6 py-4 slider-footer">
        <Button
          label={secondaryButtonLabel}
          variant={secondaryButtonVariant}
          classes={secondaryButtonClasses}
          onClick={handleSecondaryButtonClick}
        />

        <Button
          label="cards.cardSlider.internationalCard.changePin.buttons.primary"
          classes="w-19.9"
          onClick={handleContinueBtnClick}
        />
      </div>

      <IntentUrlActionConfirmationModal
        open={open}
        setOnClose={setOpen}
        modalConfig={modalConfig}
      />
    </>
  );
}
