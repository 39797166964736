import PropTypes from "prop-types";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { closeAppModal, openAppModal } from "@/store/reducers/app";

import {
  billAutoPayEnabledSelector,
  payrollAutoPayEnabledSelector,
} from "@/store/selectors/client";
import { isApproveActionPendingForIdSelector } from "@/store/selectors/payments";
import { isActionPendingForPurchaseBillSelector } from "@/store/selectors/purchase-bills";
import { isAdminSelector, userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import MoreOptions from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentSliderFooter/MoreOptions";
import Status from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentSliderFooter/Status";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { CTA_CONFIG_PAYMENTS } from "@/utils/constants/payments-ctas";
import { PAGE_TYPE, PAYMENT_STATUSES } from "@/utils/constants/payments";

import ArchivePaymentModal from "../../../common/ArchivePaymentModal";
import DeleteRecurringPaymentModal from "../../../common/DeleteRecurringPaymentModal";

/**
 * Footer component that renders two ctas and a kebab menu on the left.
 *
 * Usage: Used in payment slider to handle cta keys sent by the backend.
 */
export default function PaymentSliderFooter({
  context,
  payment,
  scrollToComments,
  tabType,
  closeDrawer,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const isAdmin = useSelector(isAdminSelector);

  // const inReimbursementContext = context === REIMBURSEMENT_CONTEXT;
  const paymentCompleted = [
    PAYMENT_STATUSES.paid,
    PAYMENT_STATUSES.outsideVolopay,
  ]?.includes(payment?.transactionStatus);

  const billAutoPayEnabled = useSelector(billAutoPayEnabledSelector);
  const payrollAutoPayEnabled = useSelector(payrollAutoPayEnabledSelector);

  const currentUser = useSelector(userSelector);
  const displayLeftSide = paymentCompleted && tabType === PAGE_TYPE.paid;
  const displayRightSide = true; // for recreate payment
  const isLoadingId1 = useSelector(isApproveActionPendingForIdSelector);
  const isLoadingId2 = useSelector(isActionPendingForPurchaseBillSelector);
  const isLoadingId = isLoadingId1 || isLoadingId2;

  const mainData = {
    ...payment,
    billAutoPayEnabled,
    payrollAutoPayEnabled,
    context,
    currentUser,
    tabType,
    isAdmin,
    target: "slider",
  };
  const componentProvidedStuff = {
    // provided to CTA handler
    dispatch,
    searchParams,
    setSearchParams,
    context,
    closeDrawer,
    openModal: (modalId) => {
      dispatch(openAppModal(modalId));
    },
    closeModal: () => {
      dispatch(closeAppModal());
    },
  };

  const mainCTAConfigs = payment?.ctas?.length
    ? payment?.ctas
        ?.map((ctaKey) => CTA_CONFIG_PAYMENTS?.[context]?.[ctaKey])
        ?.filter((ctaConfig) => ctaConfig?.isSliderPrimary)
        ?.sort((a, b) => {
          // sort the primary buttons
          // left to right: negatives, neutrals, and then positives
          const buttonMoodMap = {
            danger: -1,
            default: 0,
            success: 1,
          };

          return buttonMoodMap[a.type] - buttonMoodMap[b.type];
        })
    : [];

  const nonMainCTAConfigs = payment?.ctas?.length
    ? payment?.ctas
        ?.map((ctaKey) => CTA_CONFIG_PAYMENTS?.[context]?.[ctaKey])
        ?.filter((ctaConfig) => !ctaConfig?.isSliderPrimary)
    : [];

  const displayMoreOptionsButton = nonMainCTAConfigs?.length > 0;
  const displayMoreOptionsIconOnly = mainCTAConfigs?.length > 0; // ':' vs ': More options'

  return (
    <div className="flex items-center justify-between">
      {/* left side of the footer */}
      {displayLeftSide ? <Status /> : <div />}

      {/* right side of the footer */}
      {displayRightSide ? (
        <div className="relative flex items-center gap-6" id="slider-footer">
          {/* secondary button */}

          {scrollToComments ? (
            <Button
              preIcon="ModeComment"
              variant="tertiary"
              classes="w-fit text-neutral-500 px-5 py-3"
              onClick={scrollToComments}
            />
          ) : null}
          {displayMoreOptionsButton ? (
            <MoreOptions
              ctaConfigs={nonMainCTAConfigs}
              iconOnly={displayMoreOptionsIconOnly}
              data={mainData}
              disabled={isLoadingId === payment.id}
              componentProvidedStuff={componentProvidedStuff}
              context={context}
            />
          ) : null}

          {/* main buttons */}

          {mainCTAConfigs?.map((ctaConfig) => {
            const label =
              typeof ctaConfig?.label === typeof ""
                ? ctaConfig?.label
                : ctaConfig?.label?.(inPayrollContext);

            const hoverText =
              typeof ctaConfig?.hoverText === typeof ""
                ? ctaConfig?.hoverText
                : ctaConfig?.hoverText?.(mainData);

            return (
              <Fragment key={label}>
                <Button
                  id={`payment-footer-main-button-tooltip-${payment.id}-${label}`}
                  key={label}
                  onClick={() => {
                    ctaConfig.onClick(mainData, componentProvidedStuff);
                  }}
                  showLoader={
                    isLoadingId === payment.id && ctaConfig?.type === "success"
                  }
                  disabled={isLoadingId && isLoadingId === payment.id}
                  label={label}
                  preIcon=""
                  type={ctaConfig?.type}
                  classes={`${ctaConfig?.classes} px-5 py-3 font-base font-semibold`}
                  btnType="submit"
                  variant={ctaConfig?.variant ?? "primary"} // the grey buttons don't have a bg-color
                  compact
                />
                {hoverText ? (
                  <Tooltip
                    id={`payment-footer-main-button-tooltip-${payment.id}-${label}`}
                    direction="top-right"
                    maxWidth={450}
                  >
                    <Text
                      translationKey={hoverText}
                      classes="text-neutral-500 text-sm font-medium"
                    />
                  </Tooltip>
                ) : null}
              </Fragment>
            );
          })}
        </div>
      ) : (
        <div />
      )}

      <DeleteRecurringPaymentModal val={mainData} target="slider" />
      <ArchivePaymentModal val={mainData} target="slider" />
    </div>
  );
}

PaymentSliderFooter.propTypes = {
  payment: PropTypes.object,
  context: PropTypes.string,
  scrollToComments: PropTypes.func,
  tabType: PropTypes.string,
  closeDrawer: PropTypes.func,
};
