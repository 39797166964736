import { bool, func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login } from "@/store/reducers/user";

import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import VpLink from "@/components/core/vp-link";

export default function ResendButton({
  label = "",
  handleClick,
  isButton = true,
}) {
  const [seconds, setSeconds] = useState(30);
  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
        setIsDisabled(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return isButton ? (
    <Button
      variant={isDisabled ? "tertiary" : "primary"}
      label={` ${label} ${seconds === 0 ? "" : `${seconds}s`}`}
      classes="px-5 py-4 w-auto h-auto"
      disabled={isDisabled}
      preIcon="Pace"
      onClick={() => {
        setSeconds(30);
        setIsDisabled(true);
        handleClick();
      }}
    />
  ) : (
    <VpLink
      // variant={isDisabled ? "tertiary" : "primary"}
      text={` ${label} ${seconds === 0 ? "" : `${seconds}s`}`}
      classes="px-5 py-4 w-auto h-auto"
      disabled={isDisabled}
      preIcon="Pace"
      onClickHandler={() => {
        setSeconds(30);
        setIsDisabled(true);
        handleClick();
      }}
    />
  );
}

ResendButton.propTypes = {
  label: string,
  handleClick: func,
  isButton: bool,
};
