import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { selectedCardSelector } from "@/store/selectors/cards";

import Text from "@/components/core/Text";

import OverviewCardsSection from "@/components/common/OverviewCardsSection";
import { dateToString } from "@/utils/common";

import { CARD_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { POLICY_GROUP_MAPPING } from "@/constants/policy";

export default function CardSliderOverviewCards() {
  const cardDetails = useSelector(selectedCardSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const userName = cardDetails?.cardHolder?.displayName;
  const userId = cardDetails?.cardHolder?.id;
  const role = cardDetails?.cardHolder?.roles[0];
  const department = cardDetails?.cardHolder?.departmentName;
  const location = cardDetails?.cardHolder?.locationName;
  const approvalPolicyName = cardDetails?.approvalPolicy?.name;
  const submissionPolicyName = cardDetails?.submissionPolicy?.name;
  const submissionPolicyId = cardDetails?.submissionPolicy?.id;
  const approvalPolicyId = cardDetails?.approvalPolicy?.id;
  const cardType = cardDetails?.type;
  const cardName = cardDetails?.name;
  const autoBlockDate = cardDetails?.autoBlockDate;
  const linkedToType = cardDetails?.linkedTo?.type;
  const linkedToName = cardDetails?.linkedTo?.name;
  const availableBalance = cardDetails?.availableBalance;
  const currency = cardDetails?.currency;

  const COMMON_OVERVIEW_CARDS = {
    USER: {
      rows: [
        {
          text: {
            translationKey: "cards.cardDetails.overview.policyDetails.user",
            translationProps: "",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6",
          },
        },
        {
          text: {
            translationKey: userName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
          icon: {
            name: "NEArrow",
            type: "icon",
          },
        },
        {
          renderArray: [
            { label: role },
            {
              label: department,
              showTooltip: department?.length > 18,
              tooltipClasses: "w-14.75",
            },
            {
              label: location,
              showTooltip: location?.length > 18,
              tooltipClasses: "w-14.75",
            },
          ],
        },
      ],
      cardProps: {
        handleCardClick: () => {
          searchParams.append(SLIDERS_SEARCH_PARAMS.company.people.id, userId);
          setSearchParams(searchParams);
        },
      },
    },
    APPROVAL_POLICY: {
      rows: [
        {
          text: {
            translationKey:
              "cards.cardDetails.overview.policyDetails.policies.approval.label",
            translationProps: "",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6",
          },
        },
        {
          text: {
            translationKey: approvalPolicyName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
          icon: {
            name: "NEArrow",
            type: "icon",
          },
        },
      ],
      cardProps: {
        handleCardClick: () => {
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.settings.policyModule,
            POLICY_GROUP_MAPPING.cards
          );
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.settings.policyId,
            approvalPolicyId
          );
          setSearchParams(searchParams);
        },
      },
    },
    SUBMISSION_POLICY: {
      rows: [
        {
          text: {
            translationKey:
              "cards.cardDetails.overview.policyDetails.policies.submission.label",
            translationProps: "",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6",
          },
        },
        {
          text: {
            translationKey: submissionPolicyName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
          icon: {
            name: "NEArrow",
            type: "icon",
          },
        },
      ],

      cardProps: {
        handleCardClick: () => {
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.settings.submissionModule,
            POLICY_GROUP_MAPPING.cards
          );
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.settings.policyId,
            submissionPolicyId
          );
          setSearchParams(searchParams);
        },
      },
    },
  };

  const OVERVIEW_CARDS_CONFIG_DETAILS_SECTION = {
    physical: [
      COMMON_OVERVIEW_CARDS.USER,
      COMMON_OVERVIEW_CARDS.APPROVAL_POLICY,
      COMMON_OVERVIEW_CARDS.SUBMISSION_POLICY,
    ],
    virtual: [
      COMMON_OVERVIEW_CARDS.USER,
      {
        rows: [
          {
            text: {
              translationKey: "Card name",
              color: "neutral-500",
              classes:
                "text-sm not-italic font-semibold leading-6 lowercase first-letter:capitalize",
            },
          },
          {
            text: {
              translationKey: cardName,
              color: "neutral-800",
              classes: "text-base not-italic font-semibold leading-6",
            },
          },
        ],
      },
      {
        rows: [
          {
            text: {
              translationKey:
                "cards.cardDetails.editCardDetails.basicDetails.autoBlockDate.fieldName",
              color: "neutral-500",
              classes:
                "text-sm not-italic font-semibold leading-6 lowercase first-letter:capitalize",
            },
            icon: {
              name: "Info",
              type: "icon",
              className: "text-neutral-500",
            },
          },
          {
            text: {
              translationKey: autoBlockDate ? dateToString(autoBlockDate) : "-",
              color: "neutral-800",
              classes: "text-base not-italic font-semibold leading-6",
            },
          },
        ],
      },
      {
        rows: [
          {
            text: {
              translationKey:
                "cards.cardDetails.editCardDetails.basicDetails.linkedTo",
              color: "neutral-500",
              classes:
                "text-sm not-italic font-semibold leading-6 lowercase first-letter:capitalize",
            },
          },
          {
            text: {
              translationKey:
                linkedToType || linkedToName
                  ? `${linkedToType} : ${linkedToName}`
                  : "-",
              color: "neutral-800",
              classes: "text-base not-italic font-semibold leading-6 truncate",
              showTooltip: true,
              tooltipClasses: "w-20 ",
            },
          },

          {
            text: {
              translationKey: availableBalance
                ? "cards.cardDetails.editCardDetails.basicDetails.budgetAvailable"
                : "-",
              color: "neutral-800",
              classes: "text-xs text-neutral-500 not-italic leading-6",
              translationProps: { availableBalance, currency },
            },
          },
        ],
      },
    ],
  };

  const POLICY_DETAILS_SECTION = [
    COMMON_OVERVIEW_CARDS.APPROVAL_POLICY,
    COMMON_OVERVIEW_CARDS.SUBMISSION_POLICY,
  ];

  return (
    <div className="flex flex-col gap-10 mb-10">
      <div className="flex flex-col gap-4">
        <Text
          translationKey="cards.cardDetails.overview.policyDetails.details"
          classes="text-lg font-semibold"
        />

        <OverviewCardsSection
          config={OVERVIEW_CARDS_CONFIG_DETAILS_SECTION?.[cardType]}
        />
      </div>

      {cardType === CARD_TYPE.VIRTUAL ? (
        <div className="flex flex-col gap-4">
          <Text
            translationKey="expenses.slider.policyDetails"
            classes="text-lg font-semibold"
          />

          <OverviewCardsSection config={POLICY_DETAILS_SECTION} />
        </div>
      ) : null}
    </div>
  );
}
