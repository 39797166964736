/* eslint-disable import/extensions */
// ^ for react-toastify/dist/ReactToastify.css (CSS file not a marked export in that package, but we need it)
import { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { clearResidualCookies } from "@/store/reducers/user";
import PageLoader from "@/components/core/PageLoader";
import FormLoaderProvider from "@/components/FormLoaderProvider";
import SessionTrackerHandler from "@/components/common/SessionTrackerHandler";
import { bootChatBox } from "@/utils/chatbox";
import { injectStoreForCommon } from "@/utils/common";
import { injectArrayPolyfill } from "@/utils/polyfills/array";
import injectDatePolyfills from "@/utils/polyfills/date";
import { injectObjectPolyfill } from "@/utils/polyfills/object";

import { injectStore } from "@/api/axios";

import "@/i18n";
import RoutesContainer from "@/routes";
import store from "@/store";

export default function App() {
  useEffect(() => {
    injectDatePolyfills();
    injectArrayPolyfill();
    injectObjectPolyfill();
    injectStore(store);
    injectStoreForCommon(store);
    bootChatBox();
    clearResidualCookies();
  }, []);

  return (
    <Suspense fallback={<PageLoader />}>
      <Provider store={store}>
        <BrowserRouter>
          <FormLoaderProvider>
            <SessionTrackerHandler />
            <ToastContainer />
            <RoutesContainer />
          </FormLoaderProvider>
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
}
