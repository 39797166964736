import React from "react";

import BudgetsPageHelper from "@/components/Company/Budgets";

import { PROJECT } from "@/constants/company";

function ProjectBudget() {
  return <BudgetsPageHelper type={PROJECT} />;
}

export default ProjectBudget;
