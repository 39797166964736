import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { QRPAY_FORM_VIEWS } from "@/constants/qrPayments";
import API from "@/api";

const qrPaymentsInitialState = {
  qrPaymentsOverviewDetails: null,
  isQrPaymentsOverviewDetails: false,
  missingDetails: null,
  isFetchingMissingDetails: false,
  isFetchingStatistics: false,
  isFetchingQrPayments: false,
  isBulkApprovalInProgress: false,
  isFailureDetailsFetching: false,
  failureDetails: null,
  selectedQrpayment: null,
  isFetchingSelectedQrPayment: false,
  isFetchingSplitQrPayment: false,
  isFetchingQrPaymentActivity: false,
  splitQrPayment: [],
  selectedQrPaymentActivity: null,
  isDeclineDetailsFetching: false,
  isFetchingReceipts: false,
  isSplitQrPaymentSuccess: false,
  isFlagQrPaymentInProcess: false,
  qrPayments: {
    list: [],
    total: null,
    page: 1,
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
  },
  userWallet: {
    list: [],
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    isFetching: false,
    page: 0,
    total: 0,
  },
  unsettledQrPayments: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  selectedUserWallet: null,
  filtersKey: {
    userWallets: {
      all: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.walletLimit,
        AVAILABLE_FILTER_KEYS.walletFrequency,
        AVAILABLE_FILTER_KEYS.walletState,
        AVAILABLE_FILTER_KEYS.utilization,
        AVAILABLE_FILTER_KEYS.department,
      ],
      yetToActivate: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.walletLimit,
        AVAILABLE_FILTER_KEYS.walletFrequency,
        AVAILABLE_FILTER_KEYS.walletState,
        AVAILABLE_FILTER_KEYS.utilization,
        AVAILABLE_FILTER_KEYS.department,
      ],
    },
    payments: {
      overview: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
        AVAILABLE_FILTER_KEYS.transactionStatus,
        AVAILABLE_FILTER_KEYS.receiptStatus,
        AVAILABLE_FILTER_KEYS.accountingStatus,
        AVAILABLE_FILTER_KEYS.department,
      ],
      needsReview: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
        AVAILABLE_FILTER_KEYS.transactionStatus,
        AVAILABLE_FILTER_KEYS.receiptStatus,
        AVAILABLE_FILTER_KEYS.accountingStatus,
        AVAILABLE_FILTER_KEYS.department,
      ],
      flagged: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
        AVAILABLE_FILTER_KEYS.receiptStatus,
        AVAILABLE_FILTER_KEYS.accountingStatus,
        AVAILABLE_FILTER_KEYS.flaggedBy,
      ],
      failures: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.amount,
        AVAILABLE_FILTER_KEYS.declineReason,
      ],
    },
  },
};

// QR Payments Reducers

const qrPaymentsSlice = createSlice({
  name: "qrPayments",
  initialState: qrPaymentsInitialState,
  reducers: {
    // payments in qr-payments
    setQrPaymentsOverviewDetails(state, action) {
      state.qrPaymentsOverviewDetails = action.payload;
    },
    setIsQrPaymentsOverviewDetails(state, action) {
      state.isQrPaymentsOverviewDetails = action.payload;
    },
    setMissingDetails(state, action) {
      state.missingDetails = action.payload;
    },
    setIsFetchingMissingDetails(state, action) {
      state.isFetchingMissingDetails = action.payload;
    },
    setIsFetchingStatistics(state, action) {
      state.isFetchingStatistics = action.payload;
    },
    setQrPaymentsList(state, action) {
      state.qrPayments.list = action.payload;
    },
    setQrPayment(state, action) {
      const paymentIndex = state.qrPayments.list.findIndex(
        ({ id }) => id === action.payload.id
      ); // linear search for now

      if (paymentIndex !== -1) {
        state.qrPayments.list[paymentIndex] = action.payload.payload;
      }
    },
    addQrPaymentsList(state, action) {
      state.qrPayments.list = [...state.qrPayments.list, ...action.payload];
    },
    deleteItemQrPaymentsList(state, action) {
      state.qrPayments.list = state.qrPayments.list.filter(
        (val) => val?.id !== action.payload
      );
    },
    setQrPaymentsTotal(state, action) {
      state.qrPayments.total = action.payload;
    },
    setQrPaymentsHasMore(state) {
      state.qrPayments.hasMore =
        state.qrPayments.list.length < state.qrPayments.total;
    },
    setIsFetchingQrPayments(state, action) {
      state.isFetchingQrPayments = action.payload;
    },
    setQrPaymentsLimit(state, action) {
      state.qrPayments.limit = action.payload;
    },
    setQrPaymentsPage(state, action) {
      state.qrPayments.page = action.payload;
    },
    resetQrPaymentsListAndPagination(state) {
      state.qrPayments.list = [];
      state.qrPayments.page = 1;
      state.qrPayments.hasMore = true;
      state.qrPayments.total = 0;
    },
    setIsBulkApprovalInProgress(state, action) {
      state.isBulkApprovalInProgress = action.payload;
    },
    setIsFailureDetailsFetching(state, action) {
      state.isFailureDetailsFetching = action.payload;
    },
    setFailureDetails(state, action) {
      state.failureDetails = action.payload;
    },
    // user wallet
    setUserWallet(state, action) {
      state.userWallet = action.payload;
    },
    setIsFetchingUserWallet(state, action) {
      state.userWallet.isFetching = action.payload;
    },
    setUserWalletList(state, action) {
      state.userWallet.list = action.payload;
    },
    resetUserWalletListAndPagination(state) {
      state.userWallet.list = [];
      state.userWallet.page = 1;
      state.userWallet.hasMore = true;
      state.userWallet.total = 0;
      state.userWallet.isFetching = false;
    },
    setUserWalletPageConfig(state, action) {
      const { page, total, limit } = action.payload;

      if (page) {
        state.userWallet.page = page;
      }

      if (limit) {
        state.userWallet.limit = limit;
      }

      if (total) {
        state.userWallet.hasMore = state.userWallet.list.length < total;

        state.userWallet.total = total;
      }
    },

    appendToUserWalletList(state, action) {
      state.userWallet.list = [...state.userWallet.list, ...action.payload];
    },
    setSelectedQrPayment(state, action) {
      state.selectedQrpayment = action.payload;
    },
    setIsFetchingSelectedQrPayment(state, action) {
      state.isFetchingSelectedQrPayment = action.payload;
    },
    deleteAttachmentFromSelectedQrpayment(state, action) {
      const { receipts } = state.selectedQrpayment;
      const updatedReceipts = receipts.filter(
        (val) => val.id !== action.payload
      );
      state.selectedQrpayment = {
        ...state.selectedQrpayment,
        receipts: updatedReceipts,
      };
    },
    setAttachment(state, action) {
      const updatedReceipts = action.payload;

      state.selectedQrpayment = {
        ...state.selectedQrpayment,
        receipts: updatedReceipts,
      };
    },
    selectQrPayment(state, action) {
      state.selectedQrpayment = action.payload;
    },
    setUnsettledQrPayment(state, action) {
      state.unsettledQrPayments.list = action.payload;
    },
    addUnsettledQrPayment(state, action) {
      state.unsettledQrPayments.list = [
        ...state.unsettledQrPayments.list,
        ...action.payload,
      ];
    },
    setIsFetchingUnSettledQrPayment(state, action) {
      state.unsettledQrPayments.isFetching = action.payload;
    },
    setUnSettledQrPaymentspage(state, action) {
      state.unsettledQrPayments.page = action.payload;
    },
    setUnSettledQrPaymentTotal(state, action) {
      state.unsettledQrPayments.total = action.payload;
    },
    setUnSettledQrPaymentHasMore(state) {
      state.unsettledQrPayments.hasMore =
        state.expenses.list.length < state.expenses.total;
    },
    setUnSettledQrPaymentLimit(state, action) {
      state.unsettledQrPayments.limit = action.payload;
    },
    setIsFetchingSplitQrPayment(state, action) {
      state.isFetchingSplitQrPayment = action.payload;
    },
    setSplitQrPayment(state, action) {
      state.splitQrPayment = action.payload;
    },
    setQrPaymentActivityFetchState(state, action) {
      state.isFetchingQrPaymentActivity = action.payload;
    },
    selectQrPaymentActivity(state, action) {
      state.selectedQrPaymentActivity = action.payload;
    },
    setIsDeclineDetailsFetching(state, action) {
      state.isDeclineDetailsFetching = action.payload;
    },
    setIsFetchingReceipts(state, action) {
      state.isFetchingReceipts = action.payload;
    },
    setIsSplitQrPaymentSuccess(state, action) {
      state.isSplitQrPaymentSuccess = action.payload;
    },
    setIsFlagQrPaymentInProcess(state, action) {
      state.isFlagQrPaymentInProcess = action.payload;
    },
    setSelectedUserWallet(state, action) {
      state.selectedUserWallet = action.payload;
    },
    updateCurrentWallet(state, action) {
      state.selectedUserWallet = action.payload;
    },
    updateWallet(state, action) {
      const index = state.userWallet.list.findIndex(
        (_wallet) => _wallet.id === action.payload.id
      );
      state.userWallet.list[index] = action.payload.wallet;
      state.userWallet.list = [...state.userWallet.list];
    },
    setIsWalletFreezePending(state, action) {
      state.isCardFreezePending = action.payload;
    },
    setIsWalletUnFreezePending(state, action) {
      state.isCardFreezePending = action.payload;
    },
  },
});

// QR Payments actions

export const fetchQrPaymentsOverviewDetails = createAsyncThunk(
  "qr-payments/fetchQrPaymentsOverviewDetails",
  async (params, { dispatch }) => {
    dispatch(setIsQrPaymentsOverviewDetails(true));
    const [error, response] = await to(
      API.QRPayments.fetchQrPaymentDetails(params)
    );
    if (response) {
      dispatch(setQrPaymentsOverviewDetails(response));
    }
    dispatch(setIsQrPaymentsOverviewDetails(false));
  }
);

export const fetchMissingDetails = createAsyncThunk(
  "qr-payments/fetchMissingDetails",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingMissingDetails(true));
    const [error, response] = await to(API.QRPayments.fetchStatistics(params));
    if (response) {
      dispatch(setMissingDetails(response));
    }
    dispatch(setIsFetchingMissingDetails(false));
  }
);

export const fetchQrPaymentsStatistics = createAsyncThunk(
  "qr-payments/fetchStatistics",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingStatistics(true));

    await Promise.all([
      dispatch(fetchQrPaymentsOverviewDetails(params)),
      dispatch(fetchMissingDetails(params)),
    ]);

    dispatch(setIsFetchingStatistics(false));
  }
);
export const fetchQrPayments = createAsyncThunk(
  "qr-payments/fetchQrPayments",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingQrPayments(true));

    const [error, response] = await to(API.QRPayments.all(params));
    if (response) {
      if (params.page === 1) {
        dispatch(setQrPaymentsList(response.list));
      } else {
        dispatch(addQrPaymentsList(response.list));
      }
      dispatch(setQrPaymentsLimit(response.limit));
      dispatch(setQrPaymentsTotal(response.total));
      dispatch(setQrPaymentsPage(response.page));
      dispatch(setQrPaymentsHasMore());
    }
    dispatch(setIsFetchingQrPayments(false));
  }
);
export const qrPaymentsBulkApprove = createAsyncThunk(
  "qr-payments/bulkApprove",
  async (params, { dispatch }) => {
    dispatch(setIsBulkApprovalInProgress(true));

    const [error, response] = await to(
      API.QRPayments.qrPaymentsBulkApprove(params.payload)
    );

    if (!error && response) {
      vToast(getSuccessToastMessage(response, response));
      dispatch(setIsBulkApprovalInProgress(false));
      if (params?.onSuccess) params?.onSuccess();
    } else vToast(getErrorToastMessage(error));
  }
);
export const updateQrPayments = createAsyncThunk(
  "qr-payments/updateQrPayments",
  async (params, { dispatch }) => {
    const [error, response] = await to(API.QRPayments.update(params));

    if (!error && response?.data) {
      vToast(getSuccessToastMessage(response, response?.data));
      // params.onSuccess();
    } else vToast(getErrorToastMessage(error));
  }
);
export const failureDetails = createAsyncThunk(
  "qr-payments/failureDetails",
  async (params, { dispatch }) => {
    dispatch(setIsFailureDetailsFetching(true));
    const [error, response] = await to(API.QRPayments.failureDetails(params));

    if (!error && response) {
      dispatch(setFailureDetails(response));
    }
    dispatch(setIsFailureDetailsFetching(false));
  }
);
export const fetchAndSelectQrPayment = createAsyncThunk(
  "qr-payments/fetchAndSelectQrPayment",
  async (qrPaymentId, { dispatch }) => {
    dispatch(setIsFetchingSelectedQrPayment(true));
    const [error, response] = await to(API.QRPayments.get(qrPaymentId));
    if (!error && response) {
      dispatch(setSelectedQrPayment(response));
    }
    dispatch(setIsFetchingSelectedQrPayment(false));
  }
);
export const updateSelectedQrPayment = createAsyncThunk(
  "qr-payment/updateSelectedQrPayment",
  async (params, { dispatch }) => {
    const response = await API.Expenses.get(params.expenseId);
    if (response) {
      dispatch(selectQrPayment({ ...response, memo: params.payload }));
    }
  }
);
export const fetchUnsettledQrPayments = createAsyncThunk(
  "qr-payments/fetchUnsettledQrPayments",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUnSettledQrPayment(true));

    const [err, response] = await to(API.QRPayments.all(params));
    if (response) {
      if (params.page === 1) {
        dispatch(setUnsettledQrPayment(response.list));
      } else {
        dispatch(addUnsettledQrPayment(response.list));
      }
      dispatch(setUnSettledQrPaymentLimit(response.limit));
      dispatch(setUnSettledQrPaymentTotal(response.total));
      dispatch(setUnSettledQrPaymentspage(response.page));
      dispatch(setUnSettledQrPaymentHasMore());
    }
    dispatch(setIsFetchingUnSettledQrPayment(false));
  }
);

export const fetchQrPaymentSplit = createAsyncThunk(
  "qr-payments/fetchQrPaymentSplit",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSplitQrPayment(true));
    const [err, response] = await to(API.QRPayments.getSplitQrPayment(params));

    if (response?.data) dispatch(setSplitQrPayment(response?.data));
    dispatch(setIsFetchingSplitQrPayment(false));
  }
);

export const fetchSelectedQrPaymentActivity = createAsyncThunk(
  "qr-payments/fetchSelectedQrPaymentActivity",
  async ({ expenseId, view = QRPAY_FORM_VIEWS.ACCOUNTING }, { dispatch }) => {
    dispatch(setQrPaymentActivityFetchState(true));

    const response = await API.Expenses.getActivities(expenseId);
    dispatch(setQrPaymentActivityFetchState(false));
    dispatch(selectQrPaymentActivity(response));
  }
);
export const markAsSyncedQrPayment = createAsyncThunk(
  "qr-payments/markAsSyncedQrPayment",
  async (params, { dispatch }) => {
    dispatch(setIsDeclineDetailsFetching(true));
    const [error, response] = await to(
      API.AccountingTransactions.markAsSyncedTransaction(params)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      setSelectedQrPayment(response?.data);
      vToast(getErrorToastMessage(response));
    }
    dispatch(setIsDeclineDetailsFetching(false));
  }
);
export const syncedQrPayment = createAsyncThunk(
  "qr-payments/syncedQrPayment",
  async (params, { dispatch }) => {
    dispatch(setIsDeclineDetailsFetching(true));
    const [error, response] = await to(
      API.AccountingTransactions.syncTransaction(params)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      setSelectedQrPayment(response?.data);
      vToast(getErrorToastMessage(response));
    }
    dispatch(setIsDeclineDetailsFetching(false));
  }
);
export const remindQrPayment = createAsyncThunk(
  "qr-payments/remindQrPayment",
  async (params, { dispatch }) => {
    const [error, response] = await to(API.QRPayments.remind(params));

    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
    }
  }
);
export const requestReceived = createAsyncThunk(
  "qr-payments/requestReceived",
  async (params, { dispatch }) => {
    const [error, response] = await to(
      API.QRPayments.repaymentReceived(params.id)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
      if (params?.onSuccess) params.onSuccess();
    }
  }
);
export const verifyQrPayment = createAsyncThunk(
  "qr-payments/verifyQrPayment",
  async (params, { dispatch }) => {
    dispatch(setIsDeclineDetailsFetching(true));
    const [error, response] = await to(
      API.AccountingTransactions.verifyTransaction(params)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      setSelectedQrPayment(response?.data);
      vToast(getErrorToastMessage(response));
    }
    dispatch(setIsDeclineDetailsFetching(false));
  }
);
export const unverifyQrPayment = createAsyncThunk(
  "qr-payments/unverifyQrPayment",
  async (params, { dispatch }) => {
    dispatch(setIsDeclineDetailsFetching(true));
    const [error, response] = await to(
      API.AccountingTransactions.unverifyTransaction(params)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      setSelectedQrPayment(response?.data);
      vToast(getErrorToastMessage(response));
    }
    dispatch(setIsDeclineDetailsFetching(false));
  }
);
export const updateQrPayment = createAsyncThunk(
  "qr-payments/updateQrPayment",
  async (params, { dispatch }) => {
    const [error, response] = await to(API.QRPayments.update(params));

    if (!error && response?.data) {
      vToast(getSuccessToastMessage(response, response?.data));
      // params.onSuccess();
    } else vToast(getErrorToastMessage(error));
  }
);
export const uploadQrPaymentReceipts = createAsyncThunk(
  "qr-payment/uploadQrPaymentReceipts",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingReceipts(true));
    const { onSuccess = () => {}, ...rest } = params;
    const [error, response] = await to(API.QRPayments.uploadRecipts(rest));
    if (response) {
      dispatch(setAttachment(response.data));

      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess(response?.data);
    } else if (error) {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingReceipts(false));
  }
);
export const updateQrPaymentSplit = createAsyncThunk(
  "qr-payments/updateQrPaymentSplit",
  async (params, { dispatch }) => {
    dispatch(setIsSplitQrPaymentSuccess(false));
    dispatch(setIsFetchingSplitQrPayment(true));
    const [err, response] = await to(API.QRPayments.addSplitQrPayment(params));
    if (!err && response.data) {
      dispatch(setIsSplitQrPaymentSuccess(true));
      vToast(getSuccessToastMessage());
    } else {
      vToast(getErrorToastMessage(err.message));
    }
    dispatch(setIsFetchingSplitQrPayment(false));
  }
);
// user wallet

export const fetchUserWallets = createAsyncThunk(
  "qr-payments/fetchUserWallets",
  async (params, { dispatch, getState }) => {
    dispatch(setIsFetchingUserWallet(true));
    const [err, response] = await to(API.QRPayments.UserWallets.all(params));

    if (response) {
      if (response.page > 1) {
        dispatch(appendToUserWalletList(response?.list));
      } else {
        dispatch(setUserWalletList(response?.list));
      }

      dispatch(
        setUserWalletPageConfig({
          page: response?.page,
          limit: response?.limit,
          total: response?.total,
        })
      );

      dispatch(setIsFetchingUserWallet(false));
    }
  }
);
export const fetchAndSelectUserWallet = createAsyncThunk(
  "qr-payments/fetchAndSelectUserWallet",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUserWallet(true));

    const { walletId } = params;
    const [err, response] = await to(API.QRPayments.UserWallets.get(walletId));
    if (response) {
      dispatch(setSelectedUserWallet(response));
    }

    dispatch(setIsFetchingUserWallet(false));
  }
);
export const freezeWallet = createAsyncThunk(
  "qr-payments/freezeWallet",
  async (walletId, { dispatch }) => {
    setIsWalletFreezePending(true);
    const [err, response] = await to(
      API.QRPayments.UserWallets.freeze(walletId)
    );

    if (response) {
      dispatch(updateWallet({ walletId, payload: response }));
    }
    setIsWalletFreezePending(false);
  }
);

export const unfreezeWallet = createAsyncThunk(
  "qr-payments/unfreezeWallet",
  async (walletId, { dispatch }) => {
    setIsWalletUnFreezePending(true);
    const [err, response] = await to(
      API.QRPayments.UserWallets.unfreeze(walletId)
    );

    if (response) {
      dispatch(updateWallet({ walletId, payload: response }));
    }
    setIsWalletUnFreezePending(false);
  }
);
export const flagQrPayment = createAsyncThunk(
  "qr-payments/flagQrPayment",
  async (params, { dispatch }) => {
    dispatch(setIsFlagQrPaymentInProcess(true));

    const [error, response] = await to(
      API.QRPayments.flagQrPayment(params.params)
    );

    if (!error && response?.data) {
      vToast(getSuccessToastMessage(response, response?.data));
      params.reset();
    } else vToast(getErrorToastMessage(error));
    dispatch(setIsFlagQrPaymentInProcess(false));
  }
);
// exporting modules

export const {
  setQrPaymentsOverviewDetails,
  setIsQrPaymentsOverviewDetails,
  setIsFetchingMissingDetails,
  setMissingDetails,
  setIsFetchingStatistics,
  setQrPaymentsList,
  setQrPayment,
  setQrPaymentsTotal,
  setQrPaymentsHasMore,
  setIsFetchingQrPayments,
  addQrPaymentsList,
  setQrPaymentsLimit,
  setQrPaymentsPage,
  resetQrPaymentsListAndPagination,
  setIsBulkApprovalInProgress,
  deleteItemQrPaymentsList,
  setIsFailureDetailsFetching,
  setFailureDetails,
  setIsFetchingUserWallet,
  appendToUserWalletList,
  setUserWalletList,
  setUserWalletPageConfig,
  resetUserWalletListAndPagination,
  setSelectedQrPayment,
  setAttachment,
  setUserWallet,
  setIsFetchingSelectedQrPayment,
  deleteAttachmentFromSelectedQrpayment,
  selectQrPayment,
  setIsFetchingUnSettledQrPayment,
  setUnSettledQrPaymentHasMore,
  setUnSettledQrPaymentLimit,
  setUnSettledQrPaymentTotal,
  setUnSettledQrPaymentspage,
  setUnsettledQrPayment,
  addUnsettledQrPayment,
  setIsFetchingSplitQrPayment,
  setSplitQrPayment,
  setQrPaymentActivityFetchState,
  selectQrPaymentActivity,
  setIsDeclineDetailsFetching,
  setIsFetchingReceipts,
  setIsSplitQrPaymentSuccess,
  setIsFlagQrPaymentInProcess,
  setSelectedUserWallet,
  updateCurrentWallet,
  updateWallet,
  setIsWalletFreezePending,
  setIsWalletUnFreezePending,
} = qrPaymentsSlice.actions;

export default qrPaymentsSlice.reducer;
