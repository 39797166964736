import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import useOnClickOutside from "@/hooks/useClickOutside";

import ChatWithButton from "@/components/common/ChatWithButton";

import { Portal } from "../Portal";
import "./styles.scss";

export default function Modal({
  children,
  open = true,
  className = "flex justify-center items-center",
  onClose = () => {},
  innerContainerClasses = "",
}) {
  const appShell = document.getElementById("root");

  const [isModalOpen, setIsModalOpen] = useState(open);

  const innerContainerRef = useRef();
  const chatWithUsRef = useRef();

  useOnClickOutside([innerContainerRef, chatWithUsRef], () => {
    onClose();
  });

  if (isModalOpen) {
    appShell.classList.add("root-modal-open");
  } else {
    appShell.classList.remove("root-modal-open");
  }

  useEffect(() => {
    setIsModalOpen(open);
  }, [open]);

  return (
    <Portal>
      {isModalOpen ? (
        <div
          id="modal"
          className={`${isModalOpen ? "modal-open" : ""} ${className}`}
        >
          <div
            className={`top-1/2 left-1/2  rounded-2xl overflow-hidden ${
              innerContainerClasses.indexOf("bg") < 0 && "bg-white"
            } ${innerContainerClasses}`}
            ref={innerContainerRef}
          >
            {children}
          </div>
          {isModalOpen ? (
            <div ref={chatWithUsRef}>
              <ChatWithButton />
            </div>
          ) : null}
        </div>
      ) : null}
    </Portal>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  innerContainerClasses: PropTypes.string,
};
