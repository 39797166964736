import React from "react";

import Loader from "@/components/core/Loader";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";

function LocationProjectAndDepartmentLoader() {
  return [...Array(10)].map((idx, index) => (
    <div
      key={`location-project-and-department-loader-${index}`}
      className="flex items-center justify-between py-4 card-wrapper px-9 "
    >
      <div className="flex flex-col">
        <LoaderSkeleton
          size={["xs", "sm", "md", "lg"][Math.floor(Math.random() * 4) + 1]}
        />
        <LoaderSkeleton size={[10, 40]} />
      </div>
      <div className="flex flex-col gap-2">
        <LoaderSkeleton size={[10, 80]} />
        <LoaderSkeleton borderRadius="80px" size={[20, 80]} />
      </div>
      <div className="flex flex-col gap-2">
        <LoaderSkeleton size={[10, 80]} />
        <LoaderSkeleton type="circle" />
      </div>
      <div className="flex flex-col items-end">
        <LoaderSkeleton size={[10, 100]} />
        <LoaderSkeleton size={[10, 40]} />
        <div className="flex gap-2">
          <LoaderSkeleton size={[10, 40]} />
          <LoaderSkeleton size={[10, 40]} />
        </div>
      </div>
    </div>
  ));
}

export default LocationProjectAndDepartmentLoader;
