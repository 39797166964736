import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { validateUserSwitch } from "@/store/reducers/user";

import Loader from "@/components/core/Loader";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function SwitchEntity() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const oneTimeToken = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.switching.oneTimeToken
  );
  const email = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.switching.email
  );
  const fromSubdomain = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.switching.fromSubdomain
  );

  useEffect(() => {
    const params = {
      one_time_token: oneTimeToken,
      from_subdomain: fromSubdomain,
      email,
      navigate,
    };
    dispatch(validateUserSwitch(params));
  }, []);

  return <Loader />;
}
