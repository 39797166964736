import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

import CardCellLoader from "@/components/Cards/CardsTable/CardTableLoader/CardCellLoader";

export default function RequestHistoryLoader() {
  return [...Array(3)].map((value, index) => {
    return (
      <tr key={index}>
        <td>
          <CardCellLoader />
        </td>
        <td align="center">
          <LoaderSkeleton size={[10, 50]} />
        </td>
        <td>
          <div className="flex flex-col items-end">
            <LoaderSkeleton size={[10, 100]} />
            <LoaderSkeleton size={[10, 50]} />
          </div>
        </td>
      </tr>
    );
  });
}
