import AmountsCell from "@/components/Reimbursement/cells/AmountCell";
import LinkedToCell from "@/components/Reimbursement/cells/LinkedToCell";
import MemoCell from "@/components/Reimbursement/cells/MemoCell";
import MerchantTypeCell from "@/components/Reimbursement/cells/MerchantTypeCell";
import ReceiptCell from "@/components/Reimbursement/cells/ReceiptCell";
import ActionCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ActionCell";
import ApprovalProgressCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ApprovalProgressCell";
import PaymentStatusCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentStatusCell";
import SubmissionDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/SubmissionDateCell";
import TransactionDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/TransactionDateCell";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";

export const REIMBURSEMENT_CLAIM_TABLE_HEADER = {
  drafts: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      numberOfStickyColsRight: 1,
      colWidths: [300, 200, 200, 200, 200, 120, 300],
      rightColWidths: [200],
    },
    emptyDataTitle:
      "myVolopay.reimbursements.drafts.tableHeaders.emptyDataTitle",
    emptySubHeadingDataTitle:
      "myVolopay.reimbursements.drafts.tableHeaders.emptyDataDesc",
    emptyDataTitleHavingAccount: "Get started by adding your bank details",
    emptySubHeadingDataTitleHavingAccount:
      "Your company needs to know your bank account details to reimburse you of business expenses. Add your personal bank account below",
    tableHeading: [
      {
        id: "merchantMileage",
        title: "myVolopay.reimbursements.active.tableHeaders.merchantMileage",
        classes: "text-left",
        cellComponent: MerchantTypeCell,
        showCheckbox: true,
      },
      {
        id: "amount",
        title: "myVolopay.reimbursements.active.tableHeaders.amount",
        classes: "justify-end",
        cellComponent: AmountsCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "createdAt",
        title: "myVolopay.reimbursements.reports.drafts.tableHeaders.createdAt",
        classes: "text-left",
        cellComponent: SubmissionDateCell,
      },
      {
        id: "transactionDate",
        title: "myVolopay.reimbursements.active.tableHeaders.transactionDate",
        classes: "text-left",
        cellComponent: TransactionDateCell,
      },
      {
        id: "linkedTo",
        title: "myVolopay.reimbursements.active.tableHeaders.linkedTo",
        classes: "text-left",
        cellComponent: LinkedToCell,
      },
      {
        id: "receipt",
        title: "myVolopay.reimbursements.active.tableHeaders.receipt",
        classes: "text-center justify-center",
        cellComponent: ReceiptCell,
      },
      {
        id: "memo",
        title: "myVolopay.reimbursements.active.tableHeaders.Memo",
        classes: "text-left ",
        cellComponent: MemoCell,
      },
      {
        id: "action",
        title: "myVolopay.reimbursements.drafts.tableHeaders.actionText",
        classes: "text-center",
        cellComponent: ActionCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      " h-12 text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
  active: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 200, 200, 200, 120, 300],
    },
    emptyDataTitle:
      "myVolopay.reimbursements.active.tableHeaders.emptyDataTitle",
    emptySubHeadingDataTitle:
      "myVolopay.reimbursements.active.tableHeaders.emptyDataDesc",
    emptyDataTitleHavingAccount: "Get started by adding your bank details",
    emptySubHeadingDataTitleHavingAccount:
      "Your company needs to know your bank account details to reimburse you of business expenses. Add your personal bank account below",
    tableHeading: [
      {
        id: "merchantMileage",
        title: "myVolopay.reimbursements.active.tableHeaders.merchantMileage",
        classes: "text-left cursor-pointer",
        cellComponent: MerchantTypeCell,
        showCheckbox: true,
      },
      {
        id: "amount",
        title: "myVolopay.reimbursements.active.tableHeaders.amount",
        classes: "justify-end",
        cellComponent: AmountsCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "approval",
        title:
          "myVolopay.reimbursements.reports.active.tableHeaders.approvalStatus",
        classes: "text-right",
        cellComponent: ApprovalProgressCell,
      },
      {
        id: "submittedDate",
        title: "myVolopay.reimbursements.active.tableHeaders.submittedDate",
        classes: "text-left",
        cellComponent: SubmissionDateCell,
      },
      {
        id: "transactionDate",
        title: "myVolopay.reimbursements.active.tableHeaders.transactionDate",
        classes: "text-left",
        cellComponent: TransactionDateCell,
      },
      {
        id: "linkedTo",
        title: "myVolopay.reimbursements.active.tableHeaders.linkedTo",
        classes: "text-left",
        cellComponent: LinkedToCell,
      },
      {
        id: "receipt",
        title: "myVolopay.reimbursements.active.tableHeaders.receipt",
        classes: "text-center justify-center",
        cellComponent: ReceiptCell,
      },
      {
        id: "memo",
        title: "myVolopay.reimbursements.active.tableHeaders.Memo",
        classes: "text-left",
        cellComponent: MemoCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      " h-12  text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
  history: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 200, 200, 200, 200, 120, 300],
    },
    emptyDataTitle:
      "myVolopay.reimbursements.history.tableHeaders.emptyDataTitle",
    emptySubHeadingDataTitle:
      "myVolopay.reimbursements.history.tableHeaders.emptyDataDesc",
    emptyDataTitleHavingAccount: "Get started by adding your bank details",
    emptySubHeadingDataTitleHavingAccount:
      "Your company needs to know your bank account details to reimburse you of business expenses. Add your personal bank account below",
    tableHeading: [
      {
        id: "merchantMileage",
        title: "myVolopay.reimbursements.active.tableHeaders.merchantMileage",
        classes: "text-left",
        cellComponent: MerchantTypeCell,
        showCheckbox: true,
      },
      {
        id: "amount",
        title: "myVolopay.reimbursements.active.tableHeaders.amount",
        classes: "justify-end",
        cellComponent: AmountsCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "submittedDate",
        title: "myVolopay.reimbursements.active.tableHeaders.submittedDate",
        classes: "text-left",
        cellComponent: SubmissionDateCell,
      },
      {
        id: "transactionDate",
        title: "myVolopay.reimbursements.active.tableHeaders.transactionDate",
        classes: "text-left",
        cellComponent: TransactionDateCell,
      },
      {
        id: "paymentstatus",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.paymentStatus",
        classes: "text-center justify-center",
        cellComponent: PaymentStatusCell,
      },
      {
        id: "linkedTo",
        title: "myVolopay.reimbursements.active.tableHeaders.linkedTo",
        classes: "text-left",
        cellComponent: LinkedToCell,
      },
      {
        id: "receipt",
        title: "myVolopay.reimbursements.active.tableHeaders.receipt",
        classes: "text-center justify-center",
        cellComponent: ReceiptCell,
      },
      {
        id: "memo",
        title: "myVolopay.reimbursements.active.tableHeaders.Memo",
        classes: "text-left",
        cellComponent: MemoCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      " h-12  text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
};

export const REPORT_ENABLED_CLAIM_TABLE_HEADER = {
  drafts: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      numberOfStickyColsRight: 1,
      colWidths: [300, 200, 200, 200, 120, 300],
      rightColWidths: [200],
    },
    emptyDataTitle:
      "myVolopay.reimbursements.drafts.tableHeaders.emptyDataTitle",
    emptySubHeadingDataTitle:
      "myVolopay.reimbursements.drafts.tableHeaders.emptyDataDesc",
    emptyDataTitleHavingAccount: "Get started by adding your bank details",
    emptySubHeadingDataTitleHavingAccount:
      "Your company needs to know your bank account details to reimburse you of business expenses. Add your personal bank account below",
    tableHeading: [
      {
        id: "merchantMileage",
        title: "myVolopay.reimbursements.active.tableHeaders.merchantMileage",
        classes: "text-left",
        cellComponent: MerchantTypeCell,
        showCheckbox: true,
      },
      {
        id: "amount",
        title: "myVolopay.reimbursements.active.tableHeaders.amount",
        classes: "justify-end",
        cellComponent: AmountsCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "createdAt",
        title: "myVolopay.reimbursements.reports.drafts.tableHeaders.createdAt",
        classes: "text-left",
        cellComponent: SubmissionDateCell,
      },
      {
        id: "transactionDate",
        title: "myVolopay.reimbursements.active.tableHeaders.transactionDate",
        classes: "text-left",
        cellComponent: TransactionDateCell,
      },

      {
        id: "receipt",
        title: "myVolopay.reimbursements.active.tableHeaders.receipt",
        classes: "text-center justify-center",
        cellComponent: ReceiptCell,
      },
      {
        id: "memo",
        title: "myVolopay.reimbursements.active.tableHeaders.Memo",
        classes: "text-left ",
        cellComponent: MemoCell,
      },
      {
        id: "action",
        title: "myVolopay.reimbursements.drafts.tableHeaders.actionText",
        classes: "text-center",
        cellComponent: ActionCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      " h-12  text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
  active: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 200, 200, 200, 200, 120, 300],
    },
    emptyDataTitle:
      "myVolopay.reimbursements.active.tableHeaders.emptyDataTitle",
    emptySubHeadingDataTitle:
      "myVolopay.reimbursements.active.tableHeaders.emptyDataDesc",
    emptyDataTitleHavingAccount: "Get started by adding your bank details",
    emptySubHeadingDataTitleHavingAccount:
      "Your company needs to know your bank account details to reimburse you of business expenses. Add your personal bank account below",
    tableHeading: [
      {
        id: "merchantMileage",
        title: "myVolopay.reimbursements.active.tableHeaders.merchantMileage",
        classes: "text-left cursor-pointer",
        cellComponent: MerchantTypeCell,
        showCheckbox: true,
      },
      {
        id: "amount",
        title: "myVolopay.reimbursements.active.tableHeaders.amount",
        classes: "justify-end",
        cellComponent: AmountsCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "linkedTo",
        title: "myVolopay.reimbursements.active.tableHeaders.linkedTo",
        classes: "text-left",
        cellComponent: LinkedToCell,
      },
      {
        id: "approval",
        title:
          "myVolopay.reimbursements.reports.active.tableHeaders.approvalStatus",
        classes: "text-right",
        cellComponent: ApprovalProgressCell,
      },
      {
        id: "submittedDate",
        title: "myVolopay.reimbursements.active.tableHeaders.submittedDate",
        classes: "text-left",
        cellComponent: SubmissionDateCell,
      },
      {
        id: "transactionDate",
        title: "myVolopay.reimbursements.active.tableHeaders.transactionDate",
        classes: "text-left",
        cellComponent: TransactionDateCell,
      },

      {
        id: "receipt",
        title: "myVolopay.reimbursements.active.tableHeaders.receipt",
        classes: "text-center justify-center",
        cellComponent: ReceiptCell,
      },
      {
        id: "memo",
        title: "myVolopay.reimbursements.active.tableHeaders.Memo",
        classes: "text-left",
        cellComponent: MemoCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      " h-12  text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
  history: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 200, 200, 200, 200, 120, 300],
    },
    emptyDataTitle:
      "myVolopay.reimbursements.history.tableHeaders.emptyDataTitle",
    emptySubHeadingDataTitle:
      "myVolopay.reimbursements.history.tableHeaders.emptyDataDesc",
    emptyDataTitleHavingAccount: "Get started by adding your bank details",
    emptySubHeadingDataTitleHavingAccount:
      "Your company needs to know your bank account details to reimburse you of business expenses. Add your personal bank account below",
    tableHeading: [
      {
        id: "merchantMileage",
        title: "myVolopay.reimbursements.active.tableHeaders.merchantMileage",
        classes: "text-left",
        cellComponent: MerchantTypeCell,
        showCheckbox: true,
      },
      {
        id: "amount",
        title: "myVolopay.reimbursements.active.tableHeaders.amount",
        classes: "justify-end",
        cellComponent: AmountsCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "linkedTo",
        title: "myVolopay.reimbursements.active.tableHeaders.linkedTo",
        classes: "text-left",
        cellComponent: LinkedToCell,
      },
      {
        id: "paymentstatus",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.paymentStatus",
        classes: "text-center justify-center",
        cellComponent: PaymentStatusCell,
      },
      {
        id: "submittedDate",
        title: "myVolopay.reimbursements.active.tableHeaders.submittedDate",
        classes: "text-left",
        cellComponent: SubmissionDateCell,
      },
      {
        id: "transactionDate",
        title: "myVolopay.reimbursements.active.tableHeaders.transactionDate",
        classes: "text-left",
        cellComponent: TransactionDateCell,
      },

      {
        id: "receipt",
        title: "myVolopay.reimbursements.active.tableHeaders.receipt",
        classes: "text-center justify-center",
        cellComponent: ReceiptCell,
      },
      {
        id: "memo",
        title: "myVolopay.reimbursements.active.tableHeaders.Memo",
        classes: "text-left",
        cellComponent: MemoCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      " h-12  text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
};
