import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  defaultCurrencySelector,
  entityNameSelector,
} from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Progress from "@/components/core/Progress";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import DotList from "@/components/Company/common/DotList";
import { CREDIT_MODULE, CREDIT_PAYMENT_STATUS } from "@/utils/constants/credit";
import { amountToCurrency, dateToString, getNextDate } from "@/utils/common";

import { ACCOUNTING_TRANSACTION_STATUS } from "@/constants/accounting";
import {
  CREDIT_KEY_MAPPING,
  CREDIT_PILL_CONFIG,
  DATE_DIFFRENCE_DURATIONS,
} from "@/constants/common";

const CreditBox = ({
  openSliderHandler,
  creditDetails,
  creditBills,
  showPayNowCta,
}) => {
  // Currency
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const companyName = useSelector(entityNameSelector);

  const [processingBills, setProcessingBill] = useState(null);

  useEffect(() => {
    setProcessingBill(
      creditBills?.list?.find(
        (bill) => bill.status === ACCOUNTING_TRANSACTION_STATUS.PROCESSING
      )
    );
  }, [creditBills]);

  const generatedBill = creditBills?.list?.filter((bill) =>
    [
      CREDIT_PAYMENT_STATUS.GENERATED,
      CREDIT_PAYMENT_STATUS.PARTIALLY_PAID,
      CREDIT_PAYMENT_STATUS.MANUAL_PARTIALLY_PAID,
    ].includes(bill.status)
  )?.[0];

  const totalOutstanding =
    parseFloat(creditDetails?.creditLimit) -
      parseFloat(creditDetails?.creditAvailableBalance?.value) || 0;

  const moduleCredit = [
    {
      module: CREDIT_MODULE.outstanding,
      value: totalOutstanding,
      currency:
        creditDetails?.creditAvailableBalance?.currency || defaultCurrency,
    },
    {
      module: CREDIT_MODULE.availableLimit,
      value:
        creditDetails?.creditAvailableBalance?.value > 0
          ? creditDetails?.creditAvailableBalance?.value
          : 0 || 0,
      currency:
        creditDetails?.creditAvailableBalance?.currency || defaultCurrency,
    },
  ];

  return (
    <div className="flex flex-col flex-auto gap-4">
      <div className="flex flex-col justify-between gap-4 p-0 p-5 h-29 card-wrapper">
        <div className="flex items-center justify-between">
          <div>
            <div>
              <div className="flex items-center gap-2">
                <Text translationKey="company.credit.totalOutstanding" />
                <div id="credit-total-spent" className="-ml-1 cursor-pointer">
                  <Icon
                    name="Info"
                    className="w-4 h-4 text-neutral-300 hover:cursor-pointer"
                  />
                  <Tooltip id="credit-total-spent" direction="right">
                    <Text
                      translationKey="company.credit.toolTip.totalOutstanding"
                      classes="text-sm font-medium text-neutral-500"
                    />
                  </Tooltip>
                </div>
              </div>
              <div>
                {totalOutstanding < 0 ? (
                  <span className="text-xl font-bold text-warning">Cr</span>
                ) : null}
                <span className="ml-2 text-xl font-bold text-neutral-800">
                  {amountToCurrency(Math.abs(totalOutstanding))}
                </span>
                <span className="font-semibold text-neutral-500">
                  {creditDetails?.totalSpent?.currency || defaultCurrency}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="flex items-center justify-end gap-2">
                <Text translationKey="company.credit.creditLimit" />
                <div id="credit-total-limit" className="-ml-1 cursor-pointer">
                  <Icon
                    name="Info"
                    className="w-4 h-4 text-neutral-300 hover:cursor-pointer"
                  />
                  <Tooltip id="credit-total-limit" direction="right">
                    <Text
                      translationProps={{ companyName }}
                      translationKey="company.credit.toolTip.totalLimit"
                      classes="text-sm font-medium text-neutral-500"
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="text-right">
                <span className="text-xl font-bold text-neutral-800">
                  {amountToCurrency(creditDetails?.creditLimit || 0)}
                </span>
                <span className="font-semibold text-neutral-500">
                  {creditDetails?.totalSpent?.currency || defaultCurrency}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Spend distribution among modules (Progress) */}
        <Progress
          type="ordered"
          data={{
            total: creditDetails && creditDetails?.creditLimit,
            items: moduleCredit.map((spendItem) => ({
              variant:
                CREDIT_PILL_CONFIG[CREDIT_KEY_MAPPING[spendItem.module]]
                  ?.variant,
              value: spendItem.value,
            })),
          }}
          neutralTrack
        />

        <div className="flex justify-between">
          <DotList
            items={moduleCredit.map((spendItem) => ({
              dotColorClasses: `progress-${
                CREDIT_PILL_CONFIG[CREDIT_KEY_MAPPING[spendItem.module]]
                  ?.variant
              }`,
              textLabel:
                CREDIT_PILL_CONFIG[CREDIT_KEY_MAPPING[spendItem.module]]?.label,
              textValue: amountToCurrency(
                spendItem.value,
                spendItem.currency,
                {},
                spendItem.module === CREDIT_MODULE.outstanding
              ),
            }))}
          />
          <div className="flex">
            <Text
              classes="text-base font-normal font-semibold text-xs text-neutral-500"
              translationKey="expenses.overview.progressBar.label.unsettled"
            />
            <span className="text-xs font-bold text-neutral-800">
              :{" "}
              {amountToCurrency(
                parseFloat(creditDetails?.unsettledAmount) || 0,
                defaultCurrency
              )}
            </span>
            <div id="credit-utilisation" className="ml-2 cursor-pointer">
              <Icon
                name="Info"
                className="w-4 h-4 text-neutral-300 hover:cursor-pointer"
              />
              <Tooltip id="credit-utilisation" direction="top">
                <div>
                  <div>
                    <Text
                      translationKey="company.credit.totalUnsettled"
                      classes="text-sm font-medium text-neutral-500"
                    />
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="flex mt-3">
          <div className="flex items-center justify-between w-full p-4 mr-2 rounded-md bg-neutral-50">
            <div className="flex items-center">
              <Icon
                name="CalendarClock"
                className="w-5 h-5 text-warning"
                variant="rounded"
                bgClassName="bg-white"
              />
              <div className="flex flex-col ml-2">
                <div className="flex items-center align-center">
                  <Text
                    classes="text-base font-normal font-semibold"
                    translationKey="company.credit.dueAsOnToday"
                  />
                  {generatedBill ? (
                    <div id="credit-due-today" className="ml-2 cursor-pointer">
                      <Icon
                        name="WarningHex"
                        className="w-4 h-4 text-danger-400 hover:cursor-pointer"
                      />
                      <Tooltip id="credit-due-today" direction="right">
                        <Text
                          translationKey="company.credit.toolTip.generatedStatement"
                          translationProps={{
                            amount: amountToCurrency(
                              generatedBill?.totalAmount
                            ),
                          }}
                          classes="text-sm font-medium text-neutral-500"
                        />
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
                <div className="flex items-baseline">
                  <span className="text-xl font-bold text-neutral-800">
                    {amountToCurrency(
                      totalOutstanding > 0 ? totalOutstanding : 0
                    )}
                  </span>
                  <span className="ml-1 font-semibold text-neutral-500">
                    {creditDetails?.totalSpent?.currency || defaultCurrency}
                  </span>
                </div>
                <div className="flex mt-3">
                  <div>
                    <Text
                      classes="text-base font-normal font-semibold text-sm text-neutral-500"
                      translationKey={`company.credit.${
                        generatedBill
                          ? "statementGeneratedOn"
                          : "nextStatementDate"
                      }`}
                    />
                    <Text
                      classes="text-base font-normal font-semibold text-sm ml-2"
                      translationKey={`${dateToString(
                        generatedBill
                          ? generatedBill?.billDate
                          : getNextDate(
                              creditDetails?.currentCycle?.[1],
                              DATE_DIFFRENCE_DURATIONS.DAILY,
                              1
                            )
                      )}`}
                    />
                  </div>
                  <div className="ml-5">
                    <Text
                      classes="text-base font-normal font-semibold text-sm text-neutral-500"
                      translationKey="company.credit.creditCycle"
                    />
                    <Text
                      classes="text-base font-normal font-semibold text-sm ml-2"
                      translationKey={`${dateToString(
                        creditDetails?.currentCycle?.[0]
                      )} - ${dateToString(creditDetails?.currentCycle?.[1])}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            {showPayNowCta ? (
              <>
                <Button
                  classes="font-normal w-15.5"
                  label="company.credit.payNow"
                  size="md"
                  variant="primary"
                  disabled={processingBills}
                  onClick={() => openSliderHandler()}
                  id="credit-pay-now"
                />
                {processingBills ? (
                  <Tooltip direction="top" id="credit-pay-now">
                    <Text translationKey="company.credit.paymentProcessing" />
                  </Tooltip>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditBox;
CreditBox.propTypes = {
  openSliderHandler: PropTypes.func,
  creditDetails: PropTypes.object,
  creditBills: PropTypes.array,
  showPayNowCta: PropTypes.bool,
};
