import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  addCustomRole,
  fetchPermissions,
  fetchRoles,
} from "@/store/reducers/company";

import {
  isLoadingPermissionsSelector,
  permissionsSelector,
} from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import {
  convertPermissions,
  permissionsSetup,
  sortPermissions,
} from "@/components/Company/common/RolesPermissionsCommon";
import { useForm } from "@/utils/useForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  PERMISSIONS_ACCESSIBILITY_TYPE,
  PERMISSIONS_TITLE_MAP,
  SETTING_PERMISSIONS_ACTION_TYPE,
} from "@/constants/company";

import SetPermissions from "./SetPermissions";

export default function AddCustomRole() {
  const ref = useLeftHeaderTitle({
    title: "company.rolesPermissions.addCustomRole.title",
  });
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isLoading = useSelector(isLoadingPermissionsSelector);
  const [permissionList, setPermissionList] = useState([]);
  const [selectedPermissionList, setSelectedPermissionList] = useState([]);
  const originalPermissions = useSelector(permissionsSelector);

  const initialValue = {
    roleName: {
      value: "",
      validate: {
        required: true,
      },
    },
    roleDescription: {
      value: "",
      validate: {
        required: true,
      },
    },
  };

  useEffect(() => {
    dispatch(fetchPermissions());
  }, []);

  useEffect(() => {
    if (originalPermissions) {
      const permissions = permissionsSetup(originalPermissions);
      setPermissionList((prev) => {
        const updatedPermissions = convertPermissions(permissions);
        Object.entries(updatedPermissions)?.forEach(([key, value]) =>
          sortPermissions(value.permissions, key)
        );
        return updatedPermissions;
      });
    }
  }, [originalPermissions]);

  const [showSelectors, setShowSelectors] = useState(
    Object.fromEntries(
      Object.entries(permissionList)?.map(([key, value]) => [key, false])
    )
  );

  const toggleEnableAll = (key, val) => {
    const allPermissionsAreEdit = selectedPermissionList[
      key
    ]?.permissions?.every(
      (permission) =>
        permission.accessibility === PERMISSIONS_ACCESSIBILITY_TYPE.EDIT
    );

    if (val) {
      setSelectedPermissionList((prev) => [
        ...prev,
        ...(permissionList[key]?.permissions
          ?.map((item) => ({
            id: item?.id,
            name: item?.name,
            parent: key,
            accessibility: item?.accessibility,
          }))
          .filter((data) => data.accessibility === "edit") ?? []),
      ]);
    } else
      setSelectedPermissionList((prev) =>
        prev.filter((permission) => permission?.parent !== key)
      );
  };

  const handleCancel = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.company.rolesPermissions.addCustomRole
    );
    setSearchParams(searchParams);
  };

  const handleAddRole = () => {
    const filteredSelectedPermissionsList = Object.values(
      selectedPermissionList.reduce((acc, obj) => {
        const key = obj.name;
        if (
          !acc[key] ||
          obj.accessibility === PERMISSIONS_ACCESSIBILITY_TYPE.VIEW
        ) {
          acc[key] = obj;
        }
        return acc;
      }, {})
    ).filter((obj) => Object.keys(obj).length > 0);
    const selectedPermissionsId = filteredSelectedPermissionsList?.map(
      (permission) => permission?.id
    );

    const onSuccess = () => {
      dispatch(fetchRoles());
      handleCancel();
    };
    dispatch(
      addCustomRole({
        name: values?.roleName,
        description: values?.roleDescription,
        role_permissions_attributes: selectedPermissionsId
          ?.filter((id) => id)
          ?.map((id) => ({
            permission_id: id,
          })),
        onSuccess,
      })
    );
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, handleAddRole);

  return (
    <div className="slider-content-container">
      <div className="flex flex-col slider-content-core gap-9">
        <div className="flex flex-col justify-center">
          <Text
            translationKey="company.rolesPermissions.addCustomRole.title"
            classes="text-3xl font-bold text-neutral-800"
            refProp={ref}
          />
          <Text
            translationKey="company.rolesPermissions.addCustomRole.description"
            classes="text-sm font-medium text-neutral-500"
          />
        </div>

        <form
          className="flex flex-col justify-center gap-8"
          onSubmit={handleSubmit}
          id="add-custom-role-permissions"
        >
          <Input
            label="company.rolesPermissions.addCustomRole.roleName.label"
            name="roleName"
            value={values?.roleName}
            onChange={handleChange}
          />
          <Input
            label="company.rolesPermissions.addCustomRole.roleDescription.label"
            name="roleDescription"
            value={values?.roleDescription}
            onChange={handleChange}
          />
        </form>

        <div className="flex flex-col gap-4">
          <Text
            translationKey="company.rolesPermissions.tabs.permissions.title"
            classes="text-lg font-semibold text-neutral-800"
          />
          <div className="flex flex-col gap-6 pb-10">
            {Object.entries(permissionList)?.map(([key, value]) => (
              <div
                className="flex flex-col justify-center gap-5 p-4 border rounded-lg border-neutral-200"
                key={`${key}-permissions`}
              >
                <div
                  className="flex items-center justify-between w-full cursor-pointer"
                  onClick={() => {
                    setShowSelectors((prev) => ({
                      ...prev,
                      [key]: !prev[key],
                    }));
                  }}
                >
                  <div className="flex items-center w-full gap-3">
                    <Switch
                      value={permissionList[key]?.default}
                      handleChange={(val, event) => {
                        event.stopPropagation();
                        setPermissionList((prev) => ({
                          ...prev,
                          [key]: {
                            default: val,
                            permissions: prev[key]?.permissions?.map((item) =>
                              item.accessibility ===
                              PERMISSIONS_ACCESSIBILITY_TYPE.VIEW
                                ? { ...item, disabled: !val }
                                : item
                            ),
                          },
                        }));
                        toggleEnableAll(key, val);
                        setShowSelectors((prev) => ({
                          ...prev,
                          [key]: val,
                        }));
                      }}
                    />

                    <Text
                      translationKey={PERMISSIONS_TITLE_MAP[key]}
                      classes="text-lg font-semibold text-neutral-800 first-letter:capitalize"
                    />
                  </div>
                  <Icon
                    name={`${showSelectors[key] ? "ChevronUp" : "ChevronDown"}`}
                    className="w-4 h-4 text-neutral-400"
                  />
                </div>
                {showSelectors[key] ? (
                  <SetPermissions
                    permissionKey={key}
                    permissions={permissionList}
                    setPermissions={setPermissionList}
                    selectedPermissionList={selectedPermissionList}
                    setSelectedPermissionList={setSelectedPermissionList}
                    type={SETTING_PERMISSIONS_ACTION_TYPE.ADD}
                  />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="px-6 py-4 slider-footer">
        <div className="flex items-center justify-end w-full gap-6">
          <Button
            label="company.rolesPermissions.buttonLabels.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-base text-neutral-500 font-semibold"
            onClick={() => handleCancel()}
          />
          <Button
            label="company.rolesPermissions.buttonLabels.addRole"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            disabled={
              isFormButtonDisabled ||
              !selectedPermissionList?.filter((a) => a?.id).length
            }
            showLoader={isLoading}
            btnType="submit"
            form="add-custom-role-permissions"
          />
        </div>
      </div>
    </div>
  );
}
