import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchActionCentreTabs,
  setActionCentreSelectedTab,
} from "@/store/reducers/actionCentre";

import {
  actionCentreSelectedTabSelector,
  actionCentreTabsSelector,
} from "@/store/selectors/actionCentre";
import { reimbursementReportsEnabledSelector } from "@/store/selectors/client";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import {
  availableModulesSelector,
  userAllowedActionsConfigSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { MODULE_REQUEST_TYPES } from "@/constants/company";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

export default function ActionCentre() {
  const selectedTab = useSelector(actionCentreSelectedTabSelector);
  const actionCentreTabs = useSelector(actionCentreTabsSelector);
  const selectedTabKey = selectedTab?.key;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const allNavUrls = useSelector(allNavUrlsSelector);
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);
  const availableModules = useSelector(availableModulesSelector);
  const finalTabs = useMemo(() => {
    return availableModules?.length
      ? actionCentreTabs
          ?.map((item) =>
            item.currKey === MODULE_REQUEST_TYPES.REIMBURSEMENT_REQUEST
              ? {
                  ...item,
                  name: reportEnabled
                    ? "dashboard.quickAccess.headings.report"
                    : "dashboard.quickAccess.headings.reimbursements",
                }
              : item
          )
          ?.filter((item) => availableModules.includes(item?.module))
      : actionCentreTabs;
  }, [reportEnabled, actionCentreTabs, JSON.stringify(availableModules)]);

  useEffect(() => {
    dispatch(fetchActionCentreTabs());
    handleSelectedTab(actionCentreTabs[0]);
  }, []);

  function handleSelectedTab(tab) {
    dispatch(setActionCentreSelectedTab(tab));
  }
  const openMyPendingRequest = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.myPendingRequest,
      true
    );
    setSearchParams(searchParams);
  };
  return (
    <div className="mx-10">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <Text
            translationKey="common.modules.myVolopay"
            classes="text-neutral-500 font-semibold"
          />

          <Text
            translationKey="common.modules.actionCentre"
            classes="font-extrabold text-neutral-800 text-4xl"
          />
        </div>
        {checkIfUserActionAllowed(
          allNavUrls,
          userAllowedActions,
          USER_ALLOWED_CTA_KEYS.MY_PENDING_REQUEST
        ) ? (
          <Button
            label="myVolopay.actionCentre.pendingRequest"
            preIcon="Person"
            variant="tertiary"
            classes="w-60 h-11"
            onClick={openMyPendingRequest}
          />
        ) : null}
      </div>
      <div className="mt-9">
        <Tabs
          items={finalTabs}
          selectedTab={selectedTabKey}
          setCurrentTab={handleSelectedTab}
        />
      </div>
    </div>
  );
}
