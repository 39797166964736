import { PAYMENT_CHANNELS_TYPES } from "@/utils/constants/payments";

/**
 * Given pm and pmc (payment method category), check if they match
 *
 * @param {String} pm  payment method
 * @param {String} pmc payment method category
 *
 * @returns {Boolean} true if they match
 *
 * Examples (match - yes or no):
 *  'sg_citibankna_bank' and 'local' -> yes
 *  'sg_deutsche_bank'   and 'swift' -> no
 *  'xx_intl_swift_bank' and 'local' -> match
 *  'xx_swift_bank'      and 'swift' -> yes
 */
export const paymentMethodAndPaymentMethodCategoryMatches = (pm, pmc) => {
  if (!pm || !pmc) return true;

  const isPMSwift = pm?.toLowerCase()?.includes(PAYMENT_CHANNELS_TYPES.SWIFT);
  const isPMCSwift = pmc?.toLowerCase()?.includes(PAYMENT_CHANNELS_TYPES.SWIFT);

  return isPMSwift === isPMCSwift;
};

/**
 * Given pm (payment method), get category (local or swift)
 *
 * @param {String} pm  payment method
 * @returns {String} payment method category
 *
 * Examples:
 *  'sg_citibankna_bank'     --> local
 *  'sg_deutsche_bank'       --> local
 *  'xx_deutsche_swift_bank' --> swift
 */
export const getPaymentMethodCategory = (pm) => {
  return (
    pm?.toLowerCase()?.includes(PAYMENT_CHANNELS_TYPES.SWIFT)
      ? PAYMENT_CHANNELS_TYPES.SWIFT
      : PAYMENT_CHANNELS_TYPES.LOCAL
  ).toLowerCase();
};
