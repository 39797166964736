import { bool, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  resetPassword,
  resetPasswordVerification,
} from "@/store/reducers/user";

import { authDataSelector, isLoadingSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";
import { REST_PASSWORD_INPUT_NAME } from "@/utils/constantUseForm";

import { ROUTES } from "@/constants/routes";

export default function ChangePasswordForm({
  title = "",
  subtitle = "",
  btnLabel = "",
  onSubmit,
  showGoBack = false,
  showCurrentPassword = false,
}) {
  const [searchParam, setSearchParam] = useSearchParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const navigate = useNavigate();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    isFormButtonDisabled,
    passwordValidate,
  } = useForm(
    {
      currentPassword: {
        value: "",
        validate: { required: showCurrentPassword },
      },

      newPassword: {
        value: "",
        validate: { required: true },
      },
      confirmPassword: {
        value: "",
        validate: {
          required: true,
          compare: {
            keys: ["newPassword", "confirmPassword"],
            comparatorFunc: (a, b) => a === b,
          },
        },
        errorStatement: {
          compare: "confirmPasswordError",
        },
      },
    },
    async (e) => {
      const payload = {
        password: values?.newPassword,
        password_confirmation: values?.confirmPassword,
      };

      if (showCurrentPassword) {
        payload.current_password = values?.currentPassword;
      }
      onSubmit(payload);
    },
    { isForResetPassword: true, initialCasesOfPasswordValidation: {} }
  );

  return (
    <div>
      {showGoBack ? (
        <button
          onClick={() => {
            navigate(ROUTES.login.base.absolutePath);
          }}
        >
          <Icon className="h-10 cursor-pointer" name="ChevronLeft" />
        </button>
      ) : null}

      <div className="flex flex-col">
        <Text classes="text-3xl font-bold" translationKey={title} />
        <Text classes="text-sm text-neutral-500" translationKey={subtitle} />
      </div>
      <div className="mt-10">
        <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
          {showCurrentPassword ? (
            <Input
              name={REST_PASSWORD_INPUT_NAME.currentPassword}
              type="password"
              allowPasswordEye
              value={values.currentPassword || ""}
              placeholder="login.currentPassword"
              error={errors.currentPassword}
              onChange={(e) => handleChange(e, true)}
              label="login.currentPassword"
            />
          ) : null}
          <Input
            name={REST_PASSWORD_INPUT_NAME.newPassword}
            type="password"
            allowPasswordEye
            value={values.newPassword || ""}
            placeholder="login.newPassword"
            error={errors.newPassword}
            onChange={(e) => handleChange(e, true)}
            label="login.newPassword"
          />
          <Input
            type="password"
            allowPasswordEye
            value={values.confirmPassword || ""}
            name={REST_PASSWORD_INPUT_NAME.confirmPassword}
            error={errors.confirmPassword}
            placeholder="login.confirmPassword"
            onChange={(e) => handleChange(e, true)}
            label="login.confirmPassword"
          />
          <Button
            btnType="submit"
            showLoader={isLoading}
            disabled={isFormButtonDisabled}
            classes="w-full"
            label={btnLabel}
          />
        </form>
        <div className="flex flex-col gap-2 p-4 px-2 mt-10 rounded-md bg-neutral-100">
          {[
            "resetPassword.case1",
            "resetPassword.case2",
            "resetPassword.case3",
            "resetPassword.case4",
            "resetPassword.case5",
          ].map((val, index) => (
            <div
              key={`reset-password-case${index}`}
              className="flex items-center gap-2"
            >
              <Icon
                className={`w-5 rounded-full h-5 p-0.5 ${
                  passwordValidate[`case${index + 1}`]
                    ? "text-white bg-success-600"
                    : "bg-neutral-200 text-neutral-50"
                }`}
                name="Done"
              />
              <Text
                classes={`${
                  passwordValidate[`case${index + 1}`]
                    ? "text-neutral-800"
                    : "text-neutral-400"
                }`}
                translationKey={val}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ChangePasswordForm.propTypes = {
  title: string,
  subtitle: string,
  btnLabel: string,
  onSubmit: Function,
  showGoBack: bool,
};
