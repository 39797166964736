import PropTypes from "prop-types";

import Text from "@/components/core/Text";

const SingleColumnDataRowsDefaultProps = {
  dataRows: [
    { label: "You send", value: "1278 SGD" },
    { label: "Total fees (Included)", value: "3.44 SGD" },
    {
      label: "accounting.transactions.billPay.billHistory.amountWeConvert",
      value: "1278 SGD",
    },
    {
      label: "Cashback",
      value: (
        <Text
          translationKey="+2 SGD"
          classes="text-success-600 text-sm font-semibold"
        />
      ),
    },
    {
      label: (
        <span className="text-sm font-semibold text-danger-500">Expiry</span>
      ),
      value: "3567 INR",
    },
  ],
};

export default function SingleColumnDataRows({
  dataRows = SingleColumnDataRowsDefaultProps.dataRows,
  hideBlanks = true,
}) {
  const readyDataRows = hideBlanks
    ? dataRows.filter(Boolean).filter(({ value }) => value)
    : dataRows
        .filter(Boolean)
        .map((row) => ({ ...row, value: row.value || "-" }));

  return (
    <div className="p-0 card-wrapper">
      {readyDataRows.map(({ label, value }, idx, arr) => (
        <div
          className={`flex justify-between p-4 ${
            arr.length - 1 !== idx ? "border-b-[1px]" : ""
          } border-neutral-200`}
          key={label}
        >
          {typeof label === typeof "" ? (
            <Text
              translationKey={label}
              classes="text-neutral-500 text-sm font-medium"
            />
          ) : (
            label
          )}

          {typeof value === typeof "" ? (
            <span className="max-w-[70%] text-sm font-semibold text-right break-words text-neutral-800">
              <Text translationKey={value} />
            </span>
          ) : (
            value
          )}
        </div>
      ))}
    </div>
  );
}

SingleColumnDataRows.propTypes = {
  dataRows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.node,
    })
  ),
  hideBlanks: PropTypes.bool,
};

// variations shown here instead of in Storybook.js
