import { createSelector } from "@reduxjs/toolkit";

import { AVAILABLE_FILTERS } from "@/utils/constants/filters";

const PAYMENTS_SLICE_ATTRIBUTE_KEY = "payments";
const EMPLOYEE_SLIDER_PAYMENTS_SLICE_ATTRIBUTE_KEY = "employeeSliderPayments";

export const selectPaymentsStore = (store) => store.payments;

// payments
export const paymentsSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments[PAYMENTS_SLICE_ATTRIBUTE_KEY]
);
export const isApproveActionPendingForIdSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.isApproveActionPendingForId
);

// employee slider payments
export const employeeSliderPaymentsSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments[EMPLOYEE_SLIDER_PAYMENTS_SLICE_ATTRIBUTE_KEY]
);

export const isLoadingPaymentsSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.isLoading
);

export const paymentModeListSelector = createSelector(
  selectPaymentsStore,
  (payment) => payment.paymentModeList
);

export const isFetchingPaymentModeSelector = createSelector(
  selectPaymentsStore,
  (payment) => payment.isFetchingPaymentModeList
);

export const countChangesSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.countChanges
);
export const pageSelector = createSelector(
  paymentsSelector,
  (payments) => payments.page
);
export const paymentsListSelector = createSelector(
  paymentsSelector,
  (payments) => payments.list
);
export const totalPaymentsSelector = createSelector(
  paymentsSelector,
  (payments) => payments.total
);

export const employeeSliderPaymentsPageSelector = createSelector(
  employeeSliderPaymentsSelector,
  (payments) => payments.page
);

export const employeeSliderPaymentsListSelector = createSelector(
  employeeSliderPaymentsSelector,
  (payments) => payments.list
);
export const employeeSliderTotalPaymentsSelector = createSelector(
  employeeSliderPaymentsSelector,
  (payments) => payments.total
);

export const employeeSliderIsPaymentsFetchingSelector = createSelector(
  employeeSliderPaymentsSelector,
  (payments) => payments.isFetching
);

export const employeeSliderPaymentsHasMoreSelector = createSelector(
  employeeSliderPaymentsSelector,
  (payments) => payments.hasMore
);

export const selectedPaymentSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.selectedPayment
);

export const isPaymentsFetchingSelector = createSelector(
  paymentsSelector,
  (payments) => payments.isFetching
);

export const isPaymentFetchingSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.isPaymentFetching
);

export const paymentsHasMoreSelector = createSelector(
  paymentsSelector,
  (payments) => payments.hasMore
);
export const paymentsTotalSelector = createSelector(
  paymentsSelector,
  (payments) => payments.total
);

export const paymentAccountsSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.paymentAccounts
);

export const selectedPaymentAccountsSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.selectedPaymentAccount
);

export const isFetchingPaymentAccountsSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.isFetchingPaymentAccounts
);

export const createBillFormSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.createBillForm
);

export const createBillFormLineItemsSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.createBillFormLineItems
);

export const paymentApproversSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.approvers
);

export const isFetchingPaymentApproversSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.isFetchingApprovers
);

export const lineItemsSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.lineItems
);

export const isFetchingLineItemsSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.isFetchingLineItems
);

// filter selectors
export const filterKeysSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.filterKeys
);

export const invoiceInboxPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) => filterKeys.invoiceInbox.map((key) => AVAILABLE_FILTERS[key])
);

export const approvalsNeedsApprovalPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) =>
    filterKeys.approvals.needsApproval.map((key) => AVAILABLE_FILTERS[key])
);

export const approvalsPendingPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) =>
    filterKeys.approvals.pending.map((key) => AVAILABLE_FILTERS[key])
);

export const approvalsAllBillPayPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) =>
    filterKeys.approvals.allBillPay.map((key) => AVAILABLE_FILTERS[key])
);
export const approvalsAllPayrollPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) =>
    filterKeys.approvals.allPayroll.map((key) => AVAILABLE_FILTERS[key])
);

export const paymentsAllBillPayPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) =>
    filterKeys.payments.allBillPay.map((key) => AVAILABLE_FILTERS[key])
);

export const paymentsAllPayrollPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) =>
    filterKeys.payments.allPayroll.map((key) => AVAILABLE_FILTERS[key])
);

export const paymentsFailedPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) =>
    filterKeys.payments.failed.map((key) => AVAILABLE_FILTERS[key])
);

export const paidPageFilterKeysSelector = createSelector(
  filterKeysSelector,
  (filterKeys) => filterKeys.paid.map((key) => AVAILABLE_FILTERS[key])
);

export const settingsUsersListSelector = createSelector(
  selectPaymentsStore,
  (payments) => {
    return payments.settingUsersList;
  }
);

export const payrollSettingSelector = createSelector(
  selectPaymentsStore,
  (payment) => payment.payrollSetting
);

export const isFetchingPayrollSettingSelector = createSelector(
  selectPaymentsStore,
  (payment) => payment.isFetchingPayrollSetting
);

// We don't have payroll reducer as such. so added payroll related selector here
export const enablePayrollSelector = createSelector(
  payrollSettingSelector,
  (payment) => payment.payrollEnabled
);

export const autoPaymentEnabledSelector = createSelector(
  payrollSettingSelector,
  (payment) => payment.autoPaymentEnabled
);

export const autoPaymentDaySelector = createSelector(
  payrollSettingSelector,
  (payment) => payment.payrollAutoPayDay
);

export const automatedPaymentToggleSwitchSelector = createSelector(
  selectPaymentsStore,
  (payment) => payment.automatedPaymentToggleSwitch
);

export const payrollToggleSwitchSelector = createSelector(
  selectPaymentsStore,
  (payment) => payment.payrollToggleSwitch
);

export const payrollJobStatusForBulkUploadSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.bulkUploadPayrollJobStatusInfo
);

export const bulkUploadTableInfoSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments?.bulkUploadTableInfo
);

export const bulkUploadTableListSelector = createSelector(
  bulkUploadTableInfoSelector,
  (bulkUploadTableInfo) => bulkUploadTableInfo?.list
);

export const bulkUploadTableHasMoreSelector = createSelector(
  bulkUploadTableInfoSelector,
  (bulkUploadTableInfo) => bulkUploadTableInfo?.hasMore
);

export const bulkUploadTableisFetchingSelector = createSelector(
  bulkUploadTableInfoSelector,
  (bulkUploadTableInfo) => bulkUploadTableInfo?.isFetching
);

export const bulkUploadTableTotalCountSelector = createSelector(
  bulkUploadTableInfoSelector,
  (bulkUploadTableInfo) => bulkUploadTableInfo?.total
);

export const bulkUplodFileRecordIdSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments?.bulkFileRecordId
);

export const isSubmittingBulkUploadSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.isSubmittingBulkUpload
);

export const isDownloadingPaymentReceiptSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments?.isDownloadingReceipt
);

export const createBillRefSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.createBillRef
);

export const createBillTotalContextSelector = createSelector(
  selectPaymentsStore,
  (payments) => payments.createBillTotalContext
);
