import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import to from "await-to-js";

import { activateMFA } from "@/store/reducers/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import vToast from "@/utils/vToast";
import { getErrorToastMessage } from "@/utils/common";
import { useForm } from "@/utils/useForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { SECURITY_SETTINGS_VERIFICATION_MODES } from "@/constants/user";
import API from "@/api";

function GoogleAuthenticator() {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState({
    scanQr: true,
    verification: false,
  });
  const scanQrContainerRef = useRef(null);
  const [encodedQR, setEncodedQR] = useState(null);
  const [verticalLineHieght, setVerticalLineHieght] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const fetchQR = async () => {
      const [err, response] = await to(API.User.generateMfa());
      if (response?.data) {
        setEncodedQR(response?.data?.qrcode);
      } else if (err) {
        vToast(getErrorToastMessage(err));
      }
    };

    fetchQR();
  }, []);

  const initialValue = {
    verificationCode: {
      value: "",
      validate: {
        required: true,
      },
    },
  };

  const onSuccess = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.security.setUpGoogleAuth);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.security.twoStepVerification);
    setSearchParams(searchParams);
  };

  const onVerify = () => {
    // api call with values
    const payload = {
      mfa_mode: SECURITY_SETTINGS_VERIFICATION_MODES.GAUTH,
      otp: values?.verificationCode,
    };

    dispatch(
      activateMFA({
        payload,
        onSuccess,
      })
    );
  };
  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, () => {
      onVerify();
    });
  useEffect(() => {
    const scanQrContainer = scanQrContainerRef.current;
    const scanQrConatinerHeight = scanQrContainer.clientHeight - 20;
    setVerticalLineHieght(scanQrConatinerHeight.toString());
  }, [expand]);

  return (
    <div
      className="flex flex-col gap-10 pb-6 slider-content-core"
      id="container"
    >
      <h1>
        <Text
          translationKey="security.setUpGoogleAuth"
          classes="text-3xl text-neutral-800 font-bold"
        />
      </h1>
      <div className="flex items-start gap-4">
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="p-3 rounded-full bg-primary-50">
            <Icon name="QrPay" className="w-5 h-5 text-primary-500" />
          </div>
          <div
            className="w-0.5 bg-neutral-100"
            style={{ height: `${verticalLineHieght}px` }}
          />
          <div className="p-3 rounded-full bg-primary-50">
            <Icon name="Password" className="w-5 h-5 text-primary-500" />
          </div>
        </div>
        <div className="flex flex-col gap-10">
          <div
            className="border rounded-lg border-neutral-200"
            ref={scanQrContainerRef}
          >
            <div className="flex flex-col gap-1 p-4">
              <Text
                translationKey="security.scanQr"
                classes={`${
                  expand.scanQr ? "" : "text-neutral-400"
                } font-semibold`}
              />
              <Text
                translationKey="security.scanQrDesc"
                classes={`${
                  expand.scanQr ? "text-neutral-500" : "text-neutral-400"
                } `}
              />

              {/** inserting qrcode coming from BE */}
              {expand.scanQr && encodedQR ? (
                <div className="flex justify-center pt-4 pb-2">
                  <img
                    src={`data:image/svg+xml;base64,${encodedQR}`}
                    width="200px"
                    height="200px"
                    alt=""
                  />
                </div>
              ) : null}
            </div>
            {expand.scanQr ? (
              <div className="flex justify-end px-6 py-4 bg-neutral-100">
                <Button
                  size="sm"
                  label="Next"
                  classes="w-fit px-5"
                  onClick={() =>
                    setExpand({ scanQr: false, verification: true })
                  }
                  disabled={!encodedQR}
                />
              </div>
            ) : null}
          </div>
          <div className="border rounded-lg border-neutral-200">
            <div className="flex flex-col gap-1 p-4">
              <Text
                translationKey="security.verification"
                classes={`${
                  expand.verification ? "" : "text-neutral-400"
                } font-semibold`}
              />
              <Text
                translationKey="security.enterCode"
                classes={`${
                  expand.verification ? "text-neutral-500" : "text-neutral-400"
                } `}
              />
              {expand.verification ? (
                <div className="py-4">
                  <Input
                    name="verificationCode"
                    value={values?.verificationCode}
                    onChange={handleChange}
                    error={errors?.verificationCode}
                    type="number"
                    insideForm
                    label="Enter code"
                  />
                </div>
              ) : null}
            </div>
            {expand.verification ? (
              <div className="flex justify-end gap-6 px-6 py-4 bg-neutral-100">
                <Button
                  size="sm"
                  label="Go back"
                  variant="tertiary"
                  classes="w-fit px-5 text-neutral-500"
                  onClick={() =>
                    setExpand({ scanQr: true, verification: false })
                  }
                />
                <Button
                  size="sm"
                  label="security.verifyAndSetDefault"
                  classes="w-fit px-5"
                  btnType="submit"
                  onClick={onVerify}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleAuthenticator;
