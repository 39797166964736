export const API_KEYS_MAPPING = {
  GLOBAL_COUNTRY_CURRENCIES: "Account:globalCountryCurrencies",
  FETCH_GLOBAL_ACCOUNT_TRANSACTIONS: "Account:fetchGlobalAccountTransactions",
  EXPENSE_GET_MISSING_DETAILS: "Expenses:getMissingDetils",
  FETCH_EXPENSE_DETAILS: "Expenses:fetchExpenseDetails",
  EXPENSE_DETAIL: "Expenses:get",
  GET_SPLIT_EXPENSE: "Expenses:getSplitExpense",
  PAYROLLS_GET: "Payrolls:get",
  PAYMENTS_GET: "Payments:get",
  REIMBURSEMENT_MILEAGE_RATE_ALL: "ReimbursementMileageRate:all",
  REIMBURSEMENT_GET: "Reimbursements:get",
  REPORTS_GET: "Reports:get",
  USER_REIMBURSEMENT_GET: "UserReimbursement:get",
  REIMBURSEMENT_SETTLEMENT_GET: "ReimbursementSettlement:get",
  CLAIM_POLICIES_GET: "ClaimPolicies:get",
  CARDS_GET: "Cards:get",
  GET_CREATE_VIRTUAL_CARD_STEPS: "Cards:getCreateVirtualCardSteps",
  GET_PROJECT_LIST: "Cards:getProjectList",
  PHSYICAL_CARDS_ACTIVATION_FORM_FIELDS:
    "Cards:physicalCardsActivationFormFields",
  GET_MERCHANT_LIST: "Cards:getMerchantList",
  CARDS_GET_MERCHANT_DETAILS: "Cards:getMerchantDetails",
  GET_CARD_REQUEST: "Cards:getCardRequest",
  GET_MERCHANT_RESTRICTION_DETAILS: "Cards:getMerchantRestrictionDetails",
  FETCH_CREDIT_BILLS: "Company:Credit:fetchCreditBills",
  GET_PROJECT_DETAILS: "Company:Project:get",
  COMPANY_POLICY_GROUPS: "Company:Project:policyGroups",
  COMPANY_LOCATION_GET: "Company:Location:get",
  COMPANY_BUDGETS_GET: "Company:Budgets:get",
  COMPANY_ROLES_GET: "Company:Roles:get",
  COMPANY_PERMISSSIONS_ALL: "Company:Permissions:all",
  COMPANY_BILLING_GET_INVOICE_DETAILS: "Company:Billing:getInvoiceDetails",
  APPROVAL_GET_POLICY: "ApprovalPolicy:getPolicy",
  USER_GET: "User:get",
  USER_ALL: "User:all",
  USER_ALL_SHALLOW: "User:all:shallow",
  USER_ACTIVE_SESSIONS: "User:activeSessions",
  BLOCK_USER_REQUIREMENTS_COUNT: "User:blockUserRequirementsCount",
  BLOCK_USER_POLICY_REQUIREMENTS: "User:blockUserPolicyRequirements",
  GET_CONNECT_ENTITIES: "User:getConnectedEntities",
  ACCOUNTING_GET_UCSV_SUPPORTED_FORMAT_OPTIONS:
    "Accounting:getUCSVSupportedFormatOptions",
  ACCOUNTING_GET_COMPANY_FILES: "Accounting:getCompanyFiles",
  CATEGORIES_ALL: "Categories:all",
  ACCOUNTING_TRANSACTIONS_GET: "AccountingTransactions:get",
  RULES_ALL: "Rules:all",
  VENDORS_GET: "Vendors:get",
  VENDORS_GET_COUNTRIES_FOR_MAIL: "Vendors:getCountriesForMail",
  VENDORS_GET_PAYMENT_MODE: "Vendors:getPaymentMode",
  MISC_GET_COMMENTS: "Misc:getComments",
  SECURITY_GET_DEVICES_YOU_TRUST: "Security:getDevicesYouTrust",
  PAYMENTS_UPDATE: "Payments:update",
  PROJECT_ALL: "Project:all",
  REPORTS_GET_USER_REPORT_REIMBURSEMENT_DETAILS:
    "Reports:getUserReportReimbursementDetails",
  FETCH_QUICK_GUIDE_LIST: "QuickGuide:fetchQuickGuideList",
  FETCH_PAYMENT_INFO: "Cards:paymentInfo",
  UPLOAD_RECEIPT_EXPENSE: "Expense:UploadReceipt",
  ACCOUNT_CLIENT: "Account:Client",
  CARD_ACTIONS_GUIDELINE_VIDEOS: "Cards:getCardActionsGuidelineVideos",
};
