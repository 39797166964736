import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// utils, constant file imports

// reducers
import { getTallyAccessToken } from "@/store/reducers/accounting";

// selectors
import { tallyAccessTokenPayloadSelector } from "@/store/selectors/accounting";
import { clientSelector } from "@/store/selectors/client";
import { getAccountingIntegrationsToastMessage } from "@/components/Accounting/Integrations/common/utils";

// core components
import Button from "@/components/core/Button";
import CopytoClipboard from "@/components/core/CopyToClipboard";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import {
  DOWNLOAD_MODES,
  DOWNLOAD_PLUGIN_TOAST,
  INTEGRATION_TALLY_PLUGIN_URL,
} from "@/utils/constants/integrations";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import DisconnectAccountingSoftwareModal from "../../common/DisconnectAccountingSoftwareModal";

export default function TallySettingsSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  // const [tallyKey, setTallyKey] = useState(null);
  const client = useSelector(clientSelector);
  const tallyPayload = useSelector(tallyAccessTokenPayloadSelector);

  useEffect(() => {
    dispatch(getTallyAccessToken());
  }, []);

  const handleDefaultAcccountsSlider = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.defaultAccounts,
      true
    );
    setSearchParams(searchParams);
  };

  const handleAccountTagsSlider = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.manageAccountingTags,
      true
    );
    setSearchParams(searchParams);
  };

  const handlePluginDownload = async () => {
    window.open(INTEGRATION_TALLY_PLUGIN_URL, "_blank");
    getAccountingIntegrationsToastMessage(
      DOWNLOAD_PLUGIN_TOAST[DOWNLOAD_MODES.download]
    );
  };

  const handleDisconnect = () => {
    setShowDisconnectModal(true);
  };

  return (
    <div className="flex flex-col gap-15.5 pb-15">
      <div className="flex flex-col gap-8 px-10 slider-content-container">
        <div className="flex flex-col gap-9">
          <SliderHeader
            heading="Tally settings"
            headingClasses="text-4xl font-bold text-neutral-800"
            refFlag
          />

          <div className="flex flex-col gap-9 ">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <Text
                  translationKey="accounting.integrations.tally.setup.tallyKey"
                  classes="text-neutral-800 text-lg font-semibold"
                />
                <Text
                  translationKey="accounting.integrations.tally.setup.description"
                  classes="text-neutral-500 text-sm font-medium"
                />
              </div>

              <div className="flex items-center justify-between gap-4 p-4 border rounded-lg border-neutral-200">
                <Text
                  translationKey={tallyPayload?.secret}
                  classes="w-auto text-neutral-500 text-sm"
                />

                <CopytoClipboard
                  text={tallyPayload?.secret}
                  iconClasses="w-6 h-6 text-neutral-500"
                />
              </div>
            </div>

            <div className="flex items-center gap-4 p-4 border rounded-lg border-neutral-200">
              <div className="flex flex-col w-full ">
                <Text
                  translationKey="accounting.integrations.tally.defaultAccount.title"
                  classes="text-base font-semibold text-neutral-800"
                />
                <Text
                  translationKey="accounting.integrations.tally.defaultAccount.desc"
                  classes="text-sm font-medium text-neutral-500"
                />
              </div>
              <Icon
                name="ArrowForward"
                className=" text-neutral-500 hover:cursor-pointer"
                handleClick={handleDefaultAcccountsSlider}
              />
            </div>

            <div className="flex gap-8 p-4 border rounded-lg border-neutral-200 ">
              <div className="flex flex-col">
                <Text
                  translationKey="accounting.integrations.tally.downloadPlugin.title"
                  classes="text-base font-semibold text-neutral-800"
                />
                <Text
                  translationKey="accounting.integrations.tally.downloadPlugin.desc"
                  classes="text-sm font-medium text-neutral-500"
                />
              </div>
              <div className="flex flex-col justify-center ">
                <Button
                  variant="tertiary"
                  label="accounting.integrations.buttonLabel.downloadPlugin"
                  preIcon="Download"
                  size="sm"
                  classes="w-max px-5 !py-1.5 text-xs gap-1 font-bold"
                  innerClasses="gap-1"
                  onClick={() => handlePluginDownload()}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <hr className="text-text-light" />
            <div className="flex flex-col gap-1">
              <Text
                translationKey="accounting.integrations.integrationSettings.disconnect.title"
                translationProps={{ accountingSoftware: "Tally" }}
                classes="text-base font-semibold text-neutral-800"
              />
              <Text
                translationKey="accounting.integrations.integrationSettings.disconnect.description"
                translationProps={{ accountingSoftware: "Tally" }}
                classes="text-sm font-medium text-neutral-500"
              />
            </div>
            <Button
              label="accounting.integrations.buttonLabel.disconnect"
              variant="tertiary"
              classes="w-fit px-5 py-3 text-btn-lg font-semibold text-danger-300 !bg-danger-50 border border-danger-300 rounded-lg "
              onClick={handleDisconnect}
            />
          </div>
        </div>
      </div>

      {showDisconnectModal ? (
        <DisconnectAccountingSoftwareModal
          showModal={showDisconnectModal}
          setShowModal={setShowDisconnectModal}
        />
      ) : null}
    </div>
  );
}
