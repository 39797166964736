import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
// custom hooks
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

// reducers
import {
  getMerchantlist,
  resetMerchantListInfo,
  updateMerchant,
} from "@/store/reducers/cards";

import {
  merchantListHasMoreSelector,
  merchantListSelector,
  merchantListisFetchingSelector,
} from "@/store/selectors/cards";

// selectors
import Icon from "@/components/core/Icon";
// core components
import ProfileWidget from "@/components/core/ProfileWidget";
import Switch from "@/components/core/Switch";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import SliderExpansionHandler from "@/GlobalSliders/SliderExpansionHandler";

import MerchantListingLoader from "./MerchantListingLoader";

function MerchantListing() {
  const dispatch = useDispatch();
  const ref = useLeftHeaderTitle({
    title: "settings.generalSettings.merchantListTitle",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const globalMerchantListItems = useSelector(merchantListSelector);
  const hasMore = useSelector(merchantListHasMoreSelector);
  const isFetching = useSelector(merchantListisFetchingSelector);

  const tableHeaders = {
    MERCHANT: {
      title: "settings.generalSettings.merchant",
    },
    SUBMISSION_POLICY: {
      title: "settings.generalSettings.submissionPolicy",
      tooltipText: "settings.generalSettings.expandText",
      icon: "Info",
      classes: "justify-end",
    },
  };

  const [merchantsSwitchResponse, setMerchantSwitchResponse] = useState({});

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore: getMerchantList,
    onReset: resetMerchantTableInfo,
    inSlider: true,
  });

  const handleRefChange = useInfiniteScroll(onScroll);

  useEffect(() => {
    if (globalMerchantListItems) {
      globalMerchantListItems?.map((item) => {
        merchantsSwitchResponse[item?.id] = item?.submissionPolicyRequired;
      });

      setMerchantSwitchResponse({ ...merchantsSwitchResponse });
    }
  }, [globalMerchantListItems]);

  function handleSwitchClick(merchantInfo, value) {
    dispatch(
      updateMerchant({
        payload: {
          id: merchantInfo?.id,
          submission_policy_required: value,
        },
        onSuccess: () => handleOnToggleSwitch(merchantInfo, value),
      })
    );
  }

  function onScroll() {
    setPageNum((prevPageNumber) => prevPageNumber + 1);
  }

  function handleOnToggleSwitch(merchantInfo, value) {
    merchantsSwitchResponse[merchantInfo?.id] = value;

    setMerchantSwitchResponse({ ...merchantsSwitchResponse });
  }

  function getMerchantList() {
    dispatch(getMerchantlist({ limit: 20, page: pageNum }));
  }

  function resetMerchantTableInfo() {
    dispatch(resetMerchantListInfo());
  }

  return (
    <div className="pb-6 slider-content-core">
      <div className="flex items-center justify-between">
        <h1>
          <Text
            refProp={ref}
            translationKey="settings.generalSettings.merchantListTitle"
            classes="text-3xl text-neutral-800 font-bold py-8"
          />
        </h1>
        <SliderExpansionHandler iconClasses="text-neutral-500 w-8 h-8" />
      </div>
      <div className="my-5">
        <Table headerSticky colWidths={[200, 200, 200]}>
          <tr className="text-xs font-semibold text-left text-neutral-800">
            {Object.values(tableHeaders).map((header, index) => (
              <th key={index}>
                <div
                  className={`flex flex-row items-center gap-3 ${header?.classes}`}
                >
                  <Text translationKey={header?.title} key={index} />
                  <div id="expandToolTip" className="cursor-pointer">
                    {header?.icon ? (
                      <Icon
                        name={header?.icon}
                        className={`${header?.classes} text-neutral-400`}
                      />
                    ) : null}
                  </div>

                  <Tooltip id="expandToolTip" direction="right">
                    <Text
                      translationKey="settings.generalSettings.expandText"
                      classes="text-sm font-medium text-neutral-800"
                    />
                  </Tooltip>
                </div>
              </th>
            ))}
          </tr>

          {globalMerchantListItems.map((item, index) => (
            <tr
              key={index}
              className="text-sm font-semibold text-center cursor-pointer text-neutral-800"
              ref={(reff) => {
                if (globalMerchantListItems.length - 1 === index && hasMore)
                  return handleRefChange(reff);
              }}
            >
              <td className="text-start">
                <div
                  className="flex items-center gap-2 text-neutral-800"
                  onClick={() => {
                    searchParams.set(
                      SLIDERS_SEARCH_PARAMS.expenses.merchantId,
                      item?.id
                    );
                    setSearchParams(searchParams);
                  }}
                >
                  <ProfileWidget
                    textClasses="font-semibold text-left"
                    name={item?.name}
                    img={item?.logoUrl}
                  />
                </div>
              </td>

              <td className="text-right">
                <Switch
                  value={!merchantsSwitchResponse?.[item?.id]}
                  handleChange={(value) => {
                    handleSwitchClick(item, !value);
                  }}
                />
              </td>
            </tr>
          ))}

          {isFetching ? <MerchantListingLoader /> : null}
        </Table>
      </div>
    </div>
  );
}

export default MerchantListing;
