import PropsTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";

import { isFetchingClientDetailsSelector } from "@/store/selectors/client";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";
import {
  PAYMENT_CARD_TYPE,
  REIMBURSEMENT_CARD,
  REPORT_CARD,
} from "@/utils/constants/reimbursement";
import { amountToCurrency } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function ReimbursementPaymentsSliderInfo({
  type,
  department,
  location,
  amount,
  noOfClaims,
  noOfReports,
  reportEnabled,
}) {
  const [searchParams, setSearchParam] = useSearchParams();
  const isFetchingReportEnabledData = useSelector(
    isFetchingClientDetailsSelector
  );

  return REIMBURSEMENT_CARD[type] && !isFetchingReportEnabledData ? (
    <div className="grid grid-cols-2 gap-5">
      {(reportEnabled ? REPORT_CARD[type] : REIMBURSEMENT_CARD[type]).map(
        (val, index) => (
          <div className="flex flex-col card-wrapper" key={index}>
            <div className="flex gap-2 text-neutral-500">
              <TextWithTooltipIcon
                text={val.heading}
                textClasses="text-sm text-neutral-500 font-semibold"
                tooltipText={val?.toolTipText}
                tooltipClasses="text-neutral-800 "
                maxWidth="25rem"
                direction="top-left"
              />
            </div>
            {val.hasLink ? (
              <button
                className="flex items-center gap-2"
                onClick={() => {
                  if (val.type === REIMBURSEMENT_CARD[type][0].type) {
                    searchParams.set(
                      SLIDERS_SEARCH_PARAMS.company.department.id,
                      department.id
                    );
                  } else {
                    searchParams.set(
                      SLIDERS_SEARCH_PARAMS.company.location.id,
                      location.id
                    );
                  }
                  setSearchParam(searchParams);
                }}
              >
                <Text
                  classes="text-neutral-800 font-semibold"
                  translationKey={
                    val.type === REIMBURSEMENT_CARD[type][0].type
                      ? department.name || department
                      : location.name || location
                  }
                />

                <Icon className="text-primary-500" name="ToLink" />
              </button>
            ) : (
              <div>
                {val?.type === PAYMENT_CARD_TYPE.amount ? (
                  <Text
                    classes="text-neutral-800 font-semibold"
                    translationKey={amountToCurrency(
                      amount?.value,
                      amount?.currency
                    )}
                  />
                ) : null}

                {val?.type === PAYMENT_CARD_TYPE.noOfReports ? (
                  <Text
                    classes="text-neutral-800 font-semibold"
                    translationKey={noOfReports}
                  />
                ) : null}
                {val?.type === PAYMENT_CARD_TYPE.noOfClaims ? (
                  <Text
                    classes="text-neutral-800 font-semibold"
                    translationKey={noOfClaims}
                  />
                ) : null}
              </div>
            )}
          </div>
        )
      )}
    </div>
  ) : null;
}
ReimbursementPaymentsSliderInfo.propTypes = {
  department: PropsTypes.string,
  location: PropsTypes.string,
  amount: PropsTypes.object,
  noOfClaims: PropsTypes.number,
  type: PropsTypes.string,
  noOfReports: PropsTypes.number,
  reportEnabled: PropsTypes.bool,
};
