import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { newCardRequestDetailsSelector } from "@/store/selectors/cards";

import { checkCardOwnerAndRequestOwnerSame } from "@/components/MyVolopay/ActionCentre/common/utils";
import OverviewCardsSection from "@/components/common/OverviewCardsSection";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function VirtualCardRequestOverviewCards({
  requestOwner,
  cardHolder,
}) {
  const requestOwnerName = requestOwner?.displayName;
  const requestOwnerRole = requestOwner?.roles[0] || "";
  const requestOwnerDepartment = requestOwner?.departmentName;
  const requestOwnerLocationName = requestOwner?.locationName;
  let _cardHolder = null;
  if (cardHolder?.length === 1)
    _cardHolder = cardHolder?.length === 1 ? cardHolder?.[0] : null;

  const cardHolderName = _cardHolder?.displayName;
  const cardHolderRole = _cardHolder?.roles[0] || "";
  const cardHolderDepartment = _cardHolder?.departmentName;
  const cardHolderLocationName = _cardHolder?.locationName;
  const [searchParams, setSearchParams] = useSearchParams();
  const isCardOwnerAndRequestOwnerSame = checkCardOwnerAndRequestOwnerSame(
    cardHolder,
    requestOwner
  );
  const handleClick = ({ searchParam, searchParamValue }) => {
    if (searchParam && searchParamValue) {
      searchParams.append(searchParam, searchParamValue);
      setSearchParams(searchParams);
    }
  };
  const CONFIG = [
    {
      rows: [
        {
          text: {
            translationKey: isCardOwnerAndRequestOwnerSame
              ? "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.overviewCards.requestCardOwnerAndCardHolder"
              : "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.overviewCards.requestCardOwner",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6 ",
          },
        },
        {
          text: {
            translationKey: requestOwnerName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
          icon: {
            searchParam: SLIDERS_SEARCH_PARAMS.company.people.id,
            searchParamValue: requestOwner?.id,
            handleClick,
            name: "NEArrow",
            type: "icon",
          },
        },
        {
          renderArray: [
            { label: requestOwnerRole },
            {
              label: requestOwnerDepartment,
              showTooltip: requestOwnerDepartment?.length > 18,
              tooltipClasses: "w-14.75",
            },
            {
              label: requestOwnerLocationName,
              showTooltip: requestOwnerLocationName?.length > 18,
              tooltipClasses: "w-14.75",
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          text: {
            translationKey:
              "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.overviewCards.cardHolder",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6 ",
          },
        },
        {
          text: {
            translationKey: cardHolderName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
          icon: {
            searchParam: SLIDERS_SEARCH_PARAMS.company.people.id,
            searchParamValue: cardHolder?.[0]?.id,
            handleClick,
            name: "NEArrow",
            type: "icon",
          },
        },
        {
          renderArray: [
            { label: cardHolderRole },
            {
              label: cardHolderDepartment,
              showTooltip: requestOwnerDepartment?.length > 18,
              tooltipClasses: "w-14.75",
            },
            {
              label: cardHolderLocationName,
              showTooltip: cardHolderLocationName?.length > 18,
              tooltipClasses: "w-14.75",
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          text: {
            translationKey:
              "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.overviewCards.cardType",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6",
          },
        },
        {
          text: {
            translationKey:
              "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.overviewCards.virtualCard",
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
        },
      ],
    },
  ];

  if (isCardOwnerAndRequestOwnerSame) {
    CONFIG.splice(1, 1);
  }

  return <OverviewCardsSection config={CONFIG} />;
}
VirtualCardRequestOverviewCards.propTypes = {
  requestOwner: PropTypes.string,
  cardHolder: PropTypes.array,
};
