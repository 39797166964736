import PropTypes from "prop-types";

import Text from "@/components/core/Text";

export default function InvoiceNumberCell({ val }) {
  const text = val.invoiceNumber || "-";

  return (
    <span
      id={`invoice-number-cell-${val?.id}`}
      className="inline-block w-full truncate"
    >
      {text ? <Text translationKey={text} showTooltip noTranslate /> : "-"}
    </span>
  );
}

InvoiceNumberCell.propTypes = {
  val: PropTypes.object,
};
