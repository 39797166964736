import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { googleLogin } from "@/store/reducers/user";

import { isGoogleLoadingSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function SSOLogInButton() {
  const dispatch = useDispatch();
  const [onHover, setOnHover] = useState(false);

  return (
    <Button
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      iconClasses={onHover ? "w-5 mb-1 h-5" : ""}
      id="SSO-Tooltip"
      preIcon={onHover ? "Celebration" : "ShieldCross"}
      variant="tertiary"
      innerClasses="gap-1"
      classes="text-neutral-500 font-semibold w-full cursor-not-allowed"
      label={onHover ? "Coming soon" : "Login with SSO"}
      onClick={() => {
        // dispatch(googleLogin());
      }}
    />
  );
}
