import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// reducers
import {
  addAccountingIntegrationImportedFiles,
  importUCSVFiles,
  setAccountingIntegrationImportedFiles,
} from "@/store/reducers/accounting";

// selectors
import {
  accountingIntegrationImportFileFieldsSelector,
  accountingIntegrationImportedFilesSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";

// core components
import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";

import CSVFileUploadPreview from "@/components/Accounting/Integrations/common/CSVFileUploadPreview";
// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import {
  FILE_IMPORT_VARIANTS,
  INTEGRATION_UCSV_CHART_OF_ACCOUNGTS_TEMPLATE_URL,
  INTEGRATION_UCSV_TAX_CODE_TEMPLATE_URL,
  INTEGRATION_UCSV_VENDORS_TEMPLATE_URL,
} from "@/utils/constants/integrations";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function ImportFilesSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentDocument = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.integrations.document
  );
  const importedFiles = useSelector(accountingIntegrationImportedFilesSelector);
  const [importFile, setImportFile] = useState(
    importedFiles ? importedFiles[currentDocument] : []
  );
  const isLoading = useSelector(isLoadingAccountingIntegrationsSelector);

  const getSubText = () => {
    switch (currentDocument) {
      case FILE_IMPORT_VARIANTS.CHART_OF_ACCOUNTS:
        return "code and name, along with their type";
      case FILE_IMPORT_VARIANTS.TAXCODES:
        return "tax rate  and  name of the tax code";
      case FILE_IMPORT_VARIANTS.VENDORS:
        return "vendor name";
      default:
        return "tag name and ID";
    }
  };

  const subText = getSubText();

  const handleDownloadTemplate = () => {
    switch (currentDocument) {
      case FILE_IMPORT_VARIANTS.CHART_OF_ACCOUNTS:
        window.open(INTEGRATION_UCSV_CHART_OF_ACCOUNGTS_TEMPLATE_URL, "_blank");
        break;
      case FILE_IMPORT_VARIANTS.TAXCODES:
        window.open(INTEGRATION_UCSV_TAX_CODE_TEMPLATE_URL, "_blank");
        break;
      case FILE_IMPORT_VARIANTS.VENDORS:
        window.open(INTEGRATION_UCSV_VENDORS_TEMPLATE_URL, "_blank");
        break;
      default:
        window.open(INTEGRATION_UCSV_CHART_OF_ACCOUNGTS_TEMPLATE_URL, "_blank");
    }
  };

  const handleCancel = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.integrations.document);
    setSearchParams(searchParams);
  };

  const ucsvFields = useSelector(accountingIntegrationImportFileFieldsSelector);

  const currentImportField = ucsvFields.find(
    (item) => item.value === currentDocument
  );

  const handleSave = async () => {
    const action = await dispatch(
      importUCSVFiles({
        csv_file: importFile,
        template: currentDocument,
        override_values: false,
      })
    );

    if (importUCSVFiles.fulfilled.match(action)) {
      if (!importedFiles)
        dispatch(
          setAccountingIntegrationImportedFiles({
            [currentDocument]: importFile,
          })
        );
      else
        dispatch(
          addAccountingIntegrationImportedFiles({
            [currentDocument]: importFile,
          })
        );
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.accounting.integrations.document
      );
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="slider-content-container flex flex-col gap-11.5">
      <div className="slider-core-container px-10 flex flex-col gap-8 ">
        <SliderHeader
          heading="accounting.integrations.imports.title"
          headingTranslationProps={{ file: currentImportField?.name }}
          headingClasses="text-4xl font-bold text-neutral-800"
          refFlag
        />

        <div className="flex flex-col gap-9">
          <div className="flex flex-col justify-center gap-6">
            <SliderHeader
              heading="Download and fill template"
              subText="Download our CSV templates and fill in the code and name along with their respective types. These names will be listed as accounting tags in Volopay"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <Button
              variant="tertiary"
              label="accounting.integrations.buttonLabel.downloadTemplate"
              preIcon="Download"
              classes="text-xs w-fit px-5 py-1 font-bold"
              onClick={() => handleDownloadTemplate()}
            />
          </div>

          <div className="flex flex-col justify-center gap-4 ">
            <SliderHeader
              heading="accounting.integrations.universalCsv.uploadTemplate.title"
              subText="accounting.integrations.universalCsv.uploadTemplate.description"
              subTextTranslationProps={{ text: subText }}
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />

            <FileUpload
              accept={{ "text/csv": [".csv"] }}
              files={importFile}
              handleFileChange={setImportFile}
              acceptText="common.csvFormatFileUploadAccept"
              primaryAction={{
                handler: () => setImportFile([]),
                label: "Delete",
                icon: "Delete",
                iconClasses: "text-danger-600 bg-danger-50",
              }}
              previewCustomComponent={(props) => (
                <CSVFileUploadPreview
                  file={props.file}
                  primaryAction={props.primaryAction}
                />
              )}
              maxFiles={1}
              hideCustomButtonAfterUpload
            />
          </div>
        </div>
      </div>
      <div className="slider-footer sticky px-6 py-4">
        <div className="flex justify-end items-center gap-6 text-base font-semibold">
          <Button
            label="accounting.integrations.buttonLabel.cancel"
            variant="tertiary"
            classes="w-fit  px-5 py-3"
            onClick={() => handleCancel()}
          />
          <Button
            label="accounting.integrations.buttonLabel.saveContinue"
            classes="w-fit px-5 py-4 "
            disabled={!importFile?.length}
            showLoader={isLoading}
            onClick={() => {
              handleSave();
            }}
          />
        </div>
      </div>
    </div>
  );
}
