import { Outlet } from "react-router-dom";

import NavBar from "@/components/NavBar";
import PageTopSection from "@/components/PageTopSection";

export default function HomeLayout() {
  return (
    <div className="flex h-full min-h-screen w-100">
      {/* left side */}
      <NavBar />

      {/* right side */}
      <div
        className="flex flex-col w-full h-screen pl-11 absolute"
        id="tooltip-inside"
      >
        <PageTopSection />
        <div className="w-full pt-9">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
