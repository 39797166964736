import {
  CARDS_ALL_SLIDER_MENU_CONFIG,
  ORDER_PHYSICAL_CARDS_TYPE,
} from "./Cards";
import { SLIDERS_SEARCH_PARAMS } from "./SearchParams";
import { ROUTES } from "./routes";

export const FE_BE_KEY_GETTING_STARTED_MAPPING = {
  invite_user: "INVITE_MEMBER",
  order_physical_card: "ORDER_PHYSICAL_CARD",
  activate_physical_card: "ACTIVATE_PHYSICAL_CARD",
  create_virtual_card: "CREATE_VIRTUAL_CARD",
  make_first_expense: "MAKE_YOUR_EXPENSE",
  claim_first_reimbursement: "CLAIM_REIMBURSEMENT",
  create_first_wallet: "CREATE_WALLET",
  make_qr_payment: "MAKE_QR_PAYMENT",
  explore_departments: "EXPLORE_DEPARTMENT",
  customise_your_notifications: "CUSTOMISE_NOTIFICATION",
  explore_locations: "EXPLORE_LOCATION",
  manage_accounting_integrations: "MANAGE_ACCOUNTING_INTEGRATIONS",
};

export const GETTING_STARTED_ICON_NAME = {
  [FE_BE_KEY_GETTING_STARTED_MAPPING.explore_locations]: "Location",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.manage_accounting_integrations]: "Power",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.invite_user]: "Groups",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.order_physical_card]: "ShoppingBag",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.activate_physical_card]: "Card",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.create_virtual_card]: "Cloud",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.make_first_expense]: "Payments",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.claim_first_reimbursement]:
    "Reimbursement",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.create_first_wallet]:
    "AccountBalanceWallet",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.make_qr_payment]: "QrPay",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.explore_departments]: "Department",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.customise_your_notifications]:
    "Notifications",
};

export const GETTING_STARTED_TOOLTIP_LABEL = {
  [FE_BE_KEY_GETTING_STARTED_MAPPING.invite_user]: null,
  [FE_BE_KEY_GETTING_STARTED_MAPPING.order_physical_card]:
    "dashboard.tooltipGettingStarted.orderPhysicalCard",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.activate_physical_card]:
    "dashboard.tooltipGettingStarted.activatePhysicalCard",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.create_virtual_card]:
    "dashboard.tooltipGettingStarted.createVirtualCard",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.make_first_expense]:
    "dashboard.tooltipGettingStarted.makeFirstExpense",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.claim_first_reimbursement]: null,
  [FE_BE_KEY_GETTING_STARTED_MAPPING.create_first_wallet]: null,
  [FE_BE_KEY_GETTING_STARTED_MAPPING.make_qr_payment]:
    "dashboard.tooltipGettingStarted.makeFirstQRPayment",
  [FE_BE_KEY_GETTING_STARTED_MAPPING.explore_departments]: null,
  [FE_BE_KEY_GETTING_STARTED_MAPPING.customise_your_notifications]: null,
};

export const GETTING_STARTED_NAVIGATE_MAPPING = {
  [FE_BE_KEY_GETTING_STARTED_MAPPING.invite_user]: {
    page: ROUTES.manage.people.users.all.absolutePath,
    searchParam: SLIDERS_SEARCH_PARAMS.company.people.mainInvite,
    searchValue: true,
  },
  [FE_BE_KEY_GETTING_STARTED_MAPPING.order_physical_card]: {
    page: ROUTES.myVolopay.cards.physicalCards.absolutePath,
    searchParam: SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
    searchValue: ORDER_PHYSICAL_CARDS_TYPE.PERSONALISED_PHYSICAL_CARD_ORDER.key,
  },
  [FE_BE_KEY_GETTING_STARTED_MAPPING.activate_physical_card]: {
    page: ROUTES.myVolopay.cards.physicalCards.active.absolutePath,
    searchParam: SLIDERS_SEARCH_PARAMS.cards.activation,
    searchValue: true,
  },
  [FE_BE_KEY_GETTING_STARTED_MAPPING.create_virtual_card]: {
    page: ROUTES.myVolopay.cards.virtualCards.all.absolutePath,
    searchParam: SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage,
    searchValue: true,
  },
  [FE_BE_KEY_GETTING_STARTED_MAPPING.make_first_expense]: null, // can't navigate
  [FE_BE_KEY_GETTING_STARTED_MAPPING.claim_first_reimbursement]: {
    page: ROUTES.myVolopay.reimbursement.claims.drafts.absolutePath,
    searchParam: SLIDERS_SEARCH_PARAMS.reimbursements.create,
    searchValue: true,
  },
  [FE_BE_KEY_GETTING_STARTED_MAPPING.create_first_wallet]: "", // TODO: add request wallets page is not there
  [FE_BE_KEY_GETTING_STARTED_MAPPING.make_qr_payment]: null, // not navigatable
  [FE_BE_KEY_GETTING_STARTED_MAPPING.explore_locations]: {
    page: ROUTES.manage.company.locations.absolutePath,
    searchParam: null,
    searchValue: true,
  },
  [FE_BE_KEY_GETTING_STARTED_MAPPING.manage_accounting_integrations]: {
    page: ROUTES.accounting.integrations.absolutePath,
    searchParam: null,
    searchValue: true,
  },

  [FE_BE_KEY_GETTING_STARTED_MAPPING.explore_departments]: {
    page: ROUTES.manage.company.departments.absolutePath,
    searchParam: null,
    searchValue: true,
  },
  [FE_BE_KEY_GETTING_STARTED_MAPPING.customise_your_notifications]: {
    page: ROUTES.notifications.absolutePath,
    searchParam: null,
    searchValue: true,
  }, // TODO: add page url of cutamise notification
};
export const GETTING_STARTED_STATUS = {
  completed: "completed",
  pending: "pending",
  disabled: "disabled",
};

export const HELP_CENTER_ICON_NAME = {
  account_setup: {
    icon: "AccountSetUp",
    iconColor: "text-destructive-500",
    bgColor: "bg-accounting-50",
  },
  department: {
    icon: "Company",
    iconColor: "text-business-accounts-700",
    bgColor: "bg-business-accounts-50",
  },
  cards: {
    icon: "Card",
    iconColor: "text-corporate-cards-700",
    bgColor: "bg-corporate-cards-50",
  },
  bill_payment: {
    icon: "BillPay",
    iconColor: "text-accounts-payable-700",
    bgColor: "bg-accounts-payable-50",
  },
  reimbursement: {
    icon: "Reimbursement",
    iconColor: "text-expense-management-700",
    bgColor: "bg-expense-management-50",
  },
  qr_pay: {
    icon: "QrPay",
    iconColor: "text-warning-500",
    bgColor: "bg-expense-management-50",
  },
  payroll: {
    icon: "Payments",
    iconColor: "text-accounts-payable-700",
    bgColor: "bg-accounts-payable-50",
  },
  accounting: {
    icon: "Accounting",
    iconColor: "text-accounting-700",
    bgColor: "bg-accounting-50",
  },
};

export const APPROVAL_PENDING_STATUS = "pending";

export const APPROVAL_REQUEST_TYPE = {
  CARD: "CardRequest",
  BILL_PAY: "PurchaseBill",
  REIMBURSEMENT: "Reimbursement",
  PAYROLL: "Payroll",
};

export const APPROVE = "approve";
export const REJECT = "reject";

export const MY_PHYSICAL_CARDS_PAGE_TABS = {
  ACTIVE: {
    name: "cards.cardStatuses.active",
    count: null,
    path: ROUTES.myVolopay.cards.physicalCards.active.absolutePath,
    key: ROUTES.myVolopay.cards.physicalCards.active.absolutePath,
  },
  YET_TO_ACTIVATE: {
    name: "cards.cardStatuses.yetToActivate",
    count: null,
    path: ROUTES.myVolopay.cards.physicalCards.yetToActivate.absolutePath,
    key: ROUTES.myVolopay.cards.physicalCards.yetToActivate.absolutePath,
  },
  ALL: {
    name: "cards.requestPage.tabs.all",
    count: null,
    path: ROUTES.myVolopay.cards.physicalCards.all.absolutePath,
    key: ROUTES.myVolopay.cards.physicalCards.all.absolutePath,
  },
  BLOCKED: {
    name: "cards.cardStatuses.blocked",
    count: null,
    path: ROUTES.myVolopay.cards.physicalCards.blocked.absolutePath,
    key: ROUTES.myVolopay.cards.physicalCards.blocked.absolutePath,
  },
};
