import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  fetchAndSelectUserReimbursement,
  fetchAndSelectUserReport,
  setSelectedUserReimbursementInitialState,
  setSelectedUserReportListInitialState,
} from "@/store/reducers/reimbursement";
import {
  changeUserAccess,
  fetchUserBlockDepartments,
  fetchUserBlockPolicyRequirements,
  fetchUserBlockProjects,
  fetchUserBlockRequirementsCount,
  resetUserAccess,
} from "@/store/reducers/user";

import {
  isFetchingClientDetailsSelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isuserReimbursementFetchingSelector,
  selectedUserReimbursementHasMoreSelector,
  selectedUserReimbursementListSelector,
  selectedUserReimbursementPageSelector,
  selectedUserReimbursementSelector,
  selectedUserReimbursementTotalSelector,
  selectedUserReportHasMoreSelector,
  selectedUserReportListSelector,
  selectedUserReportPageSelector,
  selectedUserReportTotalReportsSelector,
} from "@/store/selectors/reimbursement";
import {
  availableModulesSelector,
  isUserAccessChangeInprogress,
  isUserAccessChangedSelector,
  selectedUserSelector,
  userBlockDepartmentsSelector,
  userBlockProjectsSelector,
  userBlockRequirementsCountSelector,
  userBlockRequirementsSelector,
} from "@/store/selectors/user";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import InputCaptchaCheck from "@/components/Company/Slider/People/InputCaptchaCheck";
import ImportantToKnowSection from "@/components/Company/common/ImportantToKnowSection";
import PendingRequest from "@/components/Company/common/PendingRequest";
import PeopleCardsTable from "@/components/Company/common/PeopleCardsTable";
import ReimbursementPaymentsSliderTable from "@/components/Reimbursement/ReimbursementPaymentsSlider/ReimbursementPaymentSliderTable";
import { BILL_PAYROLL_CONTEXT } from "@/components/common/BillPayAndPayroll";
import PaymentList from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentList";
import AmountCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/AmountCell";
import PaymentStatusCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentStatusCell";
import VendorCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/VendorCell";
import VendorTable from "@/components/common/BillPayAndPayroll/VendorOrEmployee/VendorTable";
import BeneficiaryCurrCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/BeneficiaryCurrCell";
import TotalSpendCell from "@/components/common/BillPayAndPayroll/VendorOrEmployee/cells/TotalSpendCell";
import { SORTING_CATEGORY, SORT_KEYS } from "@/utils/constants/sorting";
import { REIMBURSEMENT_PAGE_TYPE } from "@/utils/constants/reimbursement";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";
import { TRANSACTION_STATUS_FILTER_VALUE } from "@/utils/constants/filters";
import { formatAndCleanUpCaptchaText } from "@/utils/common";

import { MODULES } from "@/utils/constants/app";
import { CARD_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { INVOICE_SLIDER_TABLE_LIMIT } from "@/constants/pagination";
import { USER_ACCESS_CONTANTS } from "@/constants/user";

import UserProjectRequirements from "./UserProjectRequirements";
import UserRequirements from "./UserRequirements";

const CONFIG = {
  reimbursement: {
    listSelector: selectedUserReimbursementListSelector,
    totalClaimSelector: selectedUserReimbursementTotalSelector,
    hasMoreSelector: selectedUserReimbursementHasMoreSelector,
    pageSelector: selectedUserReimbursementPageSelector,
  },
  report: {
    listSelector: selectedUserReportListSelector,
    totalClaimSelector: selectedUserReportTotalReportsSelector,
    hasMoreSelector: selectedUserReportHasMoreSelector,
    pageSelector: selectedUserReportPageSelector,
  },
};

function BlockUser() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedUser = useSelector(selectedUserSelector);
  const [count, updateCount] = useState(1);
  const userId = searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id);
  const displayName = selectedUser?.displayName;
  const blockUserLabel = "company.people.blockUserSlider.heading";
  const [reimbursementTabOpen, setReimbursementTabIsOpen] = useState(false);
  const selectedUserReimbursement = useSelector(
    selectedUserReimbursementSelector
  );

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  const isFetchingReportEnabledData = useSelector(
    isFetchingClientDetailsSelector
  );
  const [config, setConfig] = useState(CONFIG.reimbursement);
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  const { listSelector, totalClaimSelector, hasMoreSelector, pageSelector } =
    config ?? {};

  useEffect(() => {
    if (reportEnabled) {
      setConfig(CONFIG.report);
    }
  }, [reportEnabled]);

  const selectedUserReimbursementList = useSelector(listSelector);
  const selectedUserReimbursementTotal = useSelector(totalClaimSelector);
  const selectedUserReimbursementPage = useSelector(pageSelector);

  const ref = useLeftHeaderTitle({
    title: blockUserLabel,
    titleTranslationProps: { displayName },
  });
  const accessChangedInprogress = useSelector(isUserAccessChangeInprogress);
  const availableModule = useSelector(availableModulesSelector);
  const isUserBlocked = useSelector(isUserAccessChangedSelector);
  const isReimbursementFetching = useSelector(
    isuserReimbursementFetchingSelector
  );
  const appliedFilter = useSelector(appliedFilterSelector);
  const hasMoreSelectedUserReimbursement = useSelector(hasMoreSelector);

  const blockRequirements = useSelector(userBlockRequirementsCountSelector);

  const mandatoryPolicyRequirementsCount =
    blockRequirements?.mandatory?.approvalPolicy;

  const mandatoryProjectRequirementsCount =
    (blockRequirements?.mandatory?.departmentManager ?? 0) +
    (blockRequirements?.mandatory?.projectManager ?? 0);

  const mandatoryRequirementsCount =
    mandatoryPolicyRequirementsCount + mandatoryProjectRequirementsCount;

  const blockPolicyRequirements = useSelector(userBlockRequirementsSelector);
  const blockUserDepartments = useSelector(userBlockDepartmentsSelector);
  const blockUserProjects = useSelector(userBlockProjectsSelector);

  useEffect(() => {
    dispatch(fetchUserBlockRequirementsCount({ id: userId }));
    dispatch(fetchUserBlockPolicyRequirements({ id: userId }));
    dispatch(
      fetchUserBlockDepartments({
        only_department_manager: userId,
        department: true,
        shallow: true,
      })
    );
    dispatch(
      fetchUserBlockProjects({ only_project_manager: userId, shallow: true })
    );
  }, []);

  const userReimbursementFilterOptions = {
    user: userId,
    status: [PAYMENT_STATUSES.approved],
  };

  function loadMoreSelectedUserReimbursement() {
    const payload = {
      page: pageNum,
      limit: INVOICE_SLIDER_TABLE_LIMIT,
      ...userReimbursementFilterOptions,
    };

    const sortingPayload = {
      [SORT_KEYS.COLUMN]: sorting?.category,
      [SORT_KEYS.DIRECTION]: sorting?.type,
    };
    if (reimbursementTabOpen) {
      if (!isFetchingReportEnabledData) {
        if (reportEnabled) {
          dispatch(fetchAndSelectUserReport({ payload, sortingPayload }));
        } else {
          dispatch(
            fetchAndSelectUserReimbursement({
              payload,
              sortingPayload,
            })
          );
        }
      }
    }
  }

  function onResetSelectedUserReimbursement() {
    if (reportEnabled) {
      dispatch(setSelectedUserReportListInitialState());
    } else {
      dispatch(setSelectedUserReimbursementInitialState());
    }
  }

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore: hasMoreSelectedUserReimbursement,
    loadMore: loadMoreSelectedUserReimbursement,
    onReset: onResetSelectedUserReimbursement,
    inSlider: true,
    filterOptions: {
      ...sorting,
      reimbursementTabOpen,
    },
  });

  const updatePageNumber = (by) => {
    setPageNum((prev) => prev + by);
  };

  const captchaMatchText = `BLOCK ${
    formatAndCleanUpCaptchaText(selectedUser?.displayName) ||
    selectedUser?.email
  }`;

  const VENDOR_TABLE_DEFAULT_HEADERS = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 0,
      colWidths: [200, 165, 170],
      emptyDataTitle: "billPay.vendors.emptyTableHeading",
      emptyDataDescription: "billPay.vendors.emptyTableDescription",
    },
    tableHeading: [
      {
        id: "vendor",
        title: "billPay.vendors.tableHeaders.vendor",
        classes: "text-left",
        showCheckbox: false,
        cellComponent: VendorCell,
      },
      {
        id: "totalSpend",
        title: "billPay.vendors.tableHeaders.totalSpend",
        classes: "text-right justify-end",
        haveSort: true,
        cellComponent: TotalSpendCell,
      },
      {
        id: "beneficiaryCurrency",
        title: "billPay.vendors.tableHeaders.beneficiaryCurrency",
        classes: "text-left",
        cellComponent: BeneficiaryCurrCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses: "text-sm font-semibold text-center  text-neutral-800",
  };

  const BILLPAY_TABLE_DEFAULT_HEADERS = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 0,
      colWidths: [200, 165, 170],
    },
    tableHeading: [
      {
        id: "vendor",
        title: "billPay.bill.invoiceInbox.tableHeaders.vendorHeading",
        classes: "text-left",
        cellComponent: VendorCell,
      },
      {
        id: "amount",
        title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },

      {
        id: "paymentstatus",
        title: "billPay.bill.invoiceInbox.tableHeaders.paymentStatus",
        classes: "text-center",
        cellComponent: PaymentStatusCell,
      },
    ],
    emptyDataTitle: "misc.noDataAvailable",
    emptySubHeadingDataTitle: "misc.noDataAvailable",
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses: "text-sm font-semibold text-center  text-neutral-800",
  };

  const REIMBURSEMENT_TABLE_DEFAULT_HEADERS = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 0,
      colWidths: [200, 165, 170],
    },
    tableHeading: [
      {
        id: "claimType",
        title: "reimbursement.payments.claimTable.type",
        classes: "text-left",
        showCheckbox: false,
      },
      {
        id: "amount",
        title: "reimbursement.payments.claimTable.amount",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
        showCheckbox: false,
      },

      {
        id: "submissionDate",
        title: "reimbursement.payments.claimTable.submittedDate",
        classes: "text-center",
        showCheckbox: false,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  const REPORT_TABLE_DEFAULT_HEADERS = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 0,
      colWidths: [200, 165, 170],
    },
    tableHeading: [
      {
        id: "reportName",
        title: "myVolopay.reimbursements.drafts.tableHeaders.reportName",
        classes: "text-left",
        showCheckbox: false,
      },
      {
        id: "amount",
        title: "reimbursement.payments.claimTable.amount",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
        showCheckbox: false,
      },

      {
        id: "submissionDate",
        title: "reimbursement.payments.claimTable.submittedDate",
        classes: "text-center",
        showCheckbox: false,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  const importantToKnowConfig = useMemo(
    () =>
      [
        {
          verticalExpandable: true,
          labelConfig: {
            label: "company.people.blockUserSlider.captchaConfig.cardsLabel",
            classes: "text-sm font-semibold text-neutral-800",
          },
          module: MODULES.CARDS,
          count: blockRequirements?.info?.cards,
          innerContent: (
            <PeopleCardsTable
              showCardsOfType={[CARD_TYPE.PHYSICAL, CARD_TYPE.VIRTUAL]}
            />
          ),
          key: "expandable-1",
        },
        {
          verticalExpandable: true,
          labelConfig: {
            label:
              "company.people.blockUserSlider.captchaConfig.pendingRequest",
            classes: "text-sm font-semibold text-neutral-800",
          },
          count: blockRequirements?.info?.pendingRequests,
          innerContent: (
            <PendingRequest
              tableExternalClasses="!p-4"
              showTabs={false}
              blockFlow
              stepPaginationEnable
              stepPaginationLimit={3}
            />
          ),
          key: "expandable-2",
        },
        {
          verticalExpandable: true,
          labelConfig: {
            label: "company.people.blockUserSlider.captchaConfig.vendorsLabel",
            classes: "text-sm font-semibold text-neutral-800",
          },
          count: blockRequirements?.info?.vendors,
          module: MODULES.BILL_PAY,
          innerContent: (
            <VendorTable
              context={BILL_PAYROLL_CONTEXT.BILLPAY}
              headerSettings={VENDOR_TABLE_DEFAULT_HEADERS}
              enableFilter={false}
              defaultParams={{
                vendor_owner: userId,
              }}
              itemPagination={3}
              stepPaginationEnable
              openSliderHandler={handleVendorSliderOpen}
              containerClasses="pt-4"
            />
          ),
          key: "expandable-3",
        },
        {
          verticalExpandable: true,
          labelConfig: {
            label: "company.people.blockUserSlider.captchaConfig.paymentsLabel",
            classes: "text-sm font-semibold text-neutral-800",
          },
          module: MODULES.BILL_PAY,
          count: blockRequirements?.info?.billPayments,
          innerContent: (
            <PaymentList
              pageType="all"
              page={BILL_PAYROLL_CONTEXT.BILLPAY}
              enableFilter={false}
              stepPaginationEnable
              itemPagination={3}
              defaultParams={{
                vendor_owner: userId,
                status: TRANSACTION_STATUS_FILTER_VALUE.PENDING_TO_BE_TRIGGERED,
              }}
              tableSettings={BILLPAY_TABLE_DEFAULT_HEADERS}
              containerClasses="pt-4"
            />
          ),
          key: "expandable-4",
        },
        {
          verticalExpandable: true,
          labelConfig: {
            label: reportEnabled
              ? "company.people.blockUserSlider.captchaConfig.reportLabel"
              : "company.people.blockUserSlider.captchaConfig.reimbursementsLabel",
            classes: "text-sm font-semibold text-neutral-800",
          },
          module: MODULES.REIMBURSEMENTS,
          count: blockRequirements?.info?.reimbursements,
          innerContent: (
            <ReimbursementPaymentsSliderTable
              stepPaginationEnable
              type={REIMBURSEMENT_PAGE_TYPE.paid}
              reportEnabled={reportEnabled}
              data={selectedUserReimbursementList}
              updateCount={updateCount}
              paymentHistory={false}
              paginationData={{
                pageNum,
                total: selectedUserReimbursementTotal,
                page: selectedUserReimbursementPage,
                limit: 3,
                updatePageNum: updatePageNumber,
                hasMore: hasMoreSelectedUserReimbursement,
                isFetching: isReimbursementFetching,
              }}
              headerSettings={
                reportEnabled
                  ? REPORT_TABLE_DEFAULT_HEADERS
                  : REIMBURSEMENT_TABLE_DEFAULT_HEADERS
              }
              containerClasses="pt-4"
              sorting={sorting}
              setSorting={setSorting}
            />
          ),
          key: "expandable-5",
          triggerFuncOnExpand: () => {
            setReimbursementTabIsOpen(true);
          },
          handleActionOnClose: () => {
            onResetSelectedUserReimbursement();
            setPageNum(1);
            setReimbursementTabIsOpen(false);
          },
        },
        {
          verticalExpandable: false,
          labelConfig: {
            label:
              "company.people.blockUserSlider.captchaConfig.transactionHistory",
            classes: "text-sm font-semibold text-neutral-800",
          },
          key: "expandable-3",
        },
      ]?.filter(
        (item) => !item.module || availableModule?.includes(item?.module)
      ),
    [JSON.stringify(availableModule)]
  );

  const mandatoryRequirements = [];
  if (mandatoryPolicyRequirementsCount > 0) {
    mandatoryRequirements.push({
      verticalExpandable: true,
      labelConfig: {
        label: "This user cannot be part of any approval and review policy",
        subLabel:
          "In order to block a user, you must first ensure that they are not part of any approval policy.",
        classes: "text-sm font-semibold text-neutral-800",
        subLabelClasses: "text-sm font-medium text-neutral-500",
      },
      count: mandatoryPolicyRequirementsCount,
      innerContent: <UserRequirements data={blockPolicyRequirements} />,
      key: "expandable-1",
    });
  }

  if (mandatoryProjectRequirementsCount > 0) {
    mandatoryRequirements.push({
      verticalExpandable: true,
      labelConfig: {
        label: "This user cannot be only manager of any department or project",
        subLabel: "",
        classes: "text-sm font-semibold text-neutral-800",
        subLabelClasses: "text-sm font-medium text-neutral-500",
      },
      count: mandatoryProjectRequirementsCount,
      innerContent: (
        <UserProjectRequirements
          data={[...blockUserDepartments, ...blockUserProjects]}
        />
      ),
      key: "expandable-1",
    });
  }

  useEffect(() => {
    if (isUserBlocked && !accessChangedInprogress) {
      handleCancel();
    }

    return () => {
      dispatch(resetUserAccess());
    };
  }, [isUserBlocked]);

  function handleConfirm() {
    dispatch(
      changeUserAccess({ operation: USER_ACCESS_CONTANTS.BLOCKED, id: userId })
    );
  }

  function handleCancel() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.blockUser);
    setSearchParams(searchParams);
  }

  function handleVendorSliderOpen(id) {
    searchParams.append(SLIDERS_SEARCH_PARAMS.vendors.id, id);
    setSearchParams(searchParams);
  }

  return (
    <div className="slider-content-container">
      <div className="flex flex-col gap-10 p-10 pt-0 slider-core-container">
        <div className="flex flex-col gap-3">
          <Text
            translationKey={blockUserLabel}
            translationProps={{ displayName }}
            classes="text-3xl font-bold"
            refProp={ref}
          />

          <Text
            translationKey="company.people.blockUserSlider.headingDescription"
            classes="text-neutral-500 text-sm font-medium"
          />
        </div>
        <div className="mt-8">
          <Text
            classes="text-xl font-semibold"
            translationKey="company.people.blockUserSlider.subjectiveWarningText"
          />

          <div className="mb-5">
            <Text
              translationKey="company.people.blockUserSlider.subjectiveWarningTextDescription"
              classes="text-neutral-500 text-sm font-medium"
            />
          </div>

          <div className="flex flex-col gap-8">
            <ImportantToKnowSection
              mainLabelConfig={{
                label: "company.people.blockUserSlider.importantToKnowLabel",
                classes: "text-warning-600 text-sm font-semibold",
              }}
              config={importantToKnowConfig}
            />
            {mandatoryRequirementsCount ? (
              <ImportantToKnowSection
                mainLabelConfig={{
                  label:
                    "company.people.blockUserSlider.mandatoryRequirementsLabel",
                  classes: "text-danger-500 text-sm font-semibold",
                }}
                config={mandatoryRequirements}
                allSectionContainerClasses="bg-danger-50"
                expandableIconClasses="w-3 h-3 text-neutral-800"
              />
            ) : (
              <div className="flex items-center gap-2">
                <Icon name="CheckCircle" className="text-success-600" />
                <Text
                  translationKey="company.people.blockUserSlider.mandatoryRequirementsCompletedLabel"
                  classes="text-sm font-semibold text-success-600"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <InputCaptchaCheck
        handleConfirmButton={handleConfirm}
        handleCancelButton={handleCancel}
        captchaMatchText={captchaMatchText}
        outsideInputDivClasses="m-10 mt-0"
        inProgress={accessChangedInprogress}
        disabled={mandatoryPolicyRequirementsCount > 0}
      />
    </div>
  );
}

export default BlockUser;
