import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import Badge from "@/components/core/Badge";
import Icon from "@/components/core/Icon";
import Progress from "@/components/core/Progress";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import DotList from "@/components/Company/common/DotList";
import FlexibleBudgetBadge from "@/components/Company/common/FlexibleBudgetBadge";
import BudgetPill from "@/components/common/BudgetPill";
import { amountToCurrency, getDateInPattern, getRange } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  BE_FE_BUDGET_KEY_MAPPING,
  BUDGET_PILL_CONFIG,
} from "@/constants/common";
import {
  BE_FE_FREQUENCY_MAP,
  BUDGET_FREQUENCY,
  BUDGET_FREQUENCY_LABELS,
} from "@/constants/company";
import { DATE_RANGE_KEYS } from "@/constants/date";

/**
 * Budget row - list item used in Create budget slider (not the listing page)
 */
export default function BudgetRow({
  data,
  available = {},
  currency = "",
  overSpend = {},
  classes = "",
  isFlexible = false,
  type,
  isClickable = true,
  noBadge = false,
}) {
  const { t } = useTranslation();
  const {
    spends: moduleSpends = [],
    limit: budgetLimit,
    frequency,
  } = data ?? { spends: [], limit: "", frequency: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const totalSpend = {
    value: moduleSpends.reduce(
      (total, moduleSpendItem) => total + (moduleSpendItem.value ?? 0),
      0
    ),
    currency,
  };

  const totalSpendForProgressBar =
    (totalSpend?.value ?? 0) + (available?.value ?? 0);
  const selectFrequencyForDate = (key) => {
    switch (key) {
      case BUDGET_FREQUENCY.MONTHLY:
        return getRange(DATE_RANGE_KEYS.currentMonth, [], 1);
      case BUDGET_FREQUENCY.YEARLY:
        return getRange(DATE_RANGE_KEYS.currentYear, [], 1);
      case BUDGET_FREQUENCY.QUARTERLY:
        return getRange(DATE_RANGE_KEYS.next3Month, [], 1);
      case BUDGET_FREQUENCY.HALF_YEARLY:
        return getRange(DATE_RANGE_KEYS.next6Month, [], 1);
      case BUDGET_FREQUENCY.DAILY:
        return getRange(DATE_RANGE_KEYS.tomorrow, [], 1);
      case BUDGET_FREQUENCY.WEEKLY:
        return getRange(DATE_RANGE_KEYS.currentWeek, [], 1);
      default:
        return null;
    }
  };
  const limitEndDate = selectFrequencyForDate(
    BE_FE_FREQUENCY_MAP[frequency]
  )?.to;

  return (
    <div
      className={`${classes} min-w-[382px] card-wrapper p-6 rounded-lg ${
        isClickable ? "cursor-pointer" : ""
      }`}
      onClick={
        isClickable
          ? () => {
              searchParams.append(
                SLIDERS_SEARCH_PARAMS.company.budget.id,
                data.id
              );
              searchParams.append(
                SLIDERS_SEARCH_PARAMS.company.budget.type,
                type
              );
              setSearchParams(searchParams);
            }
          : () => {}
      }
    >
      {/* Budget pills */}
      {noBadge ? null : ( // removed due to design
        <div>
          <BudgetPill hideInfo data={[data]} />
        </div>
      )}
      {/* Spent   --------   Available */}
      <div
        className={`flex items-center justify-between ${noBadge ? "" : "mt-6"}`}
      >
        {[
          {
            id: "spent",
            text: "company.budgets.spent",
            tooltipText: "company.budgets.budgetRow.spentTooltip",
            showOverSpent: !!overSpend?.value,
            showDot: data?.modules?.length === 1,
            dotClasses:
              data?.modules?.length === 1
                ? `text-${
                    BUDGET_PILL_CONFIG[
                      BE_FE_BUDGET_KEY_MAPPING[moduleSpends?.[0]?.module]
                    ]?.variant
                  }-500`
                : null,
            amount: totalSpend,
          },
          {
            id: "available",
            text: "company.budgets.available",
            tooltipText: "company.budgets.budgetRow.availableTooltip",
            showDot: true,
            rightSide: true,
            amount: available ?? 0,
          },
        ].map((item) => {
          return (
            <div key={item.id}>
              {/* Top - Spent info dot */}
              <div
                className={`flex items-center gap-2 ${
                  item.rightSide ? "justify-end" : "justify-start"
                }`}
              >
                {/* Text */}
                <Text
                  translationKey={item.text}
                  classes="text-sm font-medium text-neutral-800"
                />

                <span id={item.id}>
                  {/* Info with tooltip */}
                  <Icon
                    name="Info"
                    id={item.id}
                    className="text-neutral-300 p-[1.5px] h-5 w-5 hover:cursor-pointer"
                  />
                  <Tooltip id={item.id} direction="bottom">
                    <Text translationKey={item.tooltipText} />
                  </Tooltip>
                </span>

                {item.showDot ? (
                  <span
                    className={` order-first budget-pill--${
                      BUDGET_PILL_CONFIG[
                        BE_FE_BUDGET_KEY_MAPPING[moduleSpends?.[0]?.module]
                      ]?.variant
                    }`}
                  >
                    <Icon
                      name="Dot"
                      className={`${
                        item.dotClasses ? item.dotClasses : "text-neutral-200"
                      }`}
                    />
                  </span>
                ) : null}
              </div>

              {/* bottom - 1000 SGD */}
              <div
                className={`flex items-baseline gap-1  ${
                  item.rightSide ? "justify-end" : "justify-start"
                }`}
              >
                <span className="mr-1 text-xl font-bold text-neutral-800">
                  {amountToCurrency(item.amount.value, "")}
                </span>
                <span className="text-base font-bold text-neutral-500">
                  {item.amount.currency ?? currency}
                </span>
                {/* Overspent red badge with popup */}
                {item.showOverSpent ? (
                  <span id={`overspent-${item.id}`}>
                    <Badge
                      preIcon="AirlineStops"
                      translationKey="company.budgets.budgetRow.xAmountOverspent"
                      translationProp={{
                        amountWithCurrency: amountToCurrency(overSpend),
                      }}
                      iconClasses="w-3.5 h-3.5 mr-1"
                      classes="text-danger-400 bg-danger-50 border-none px-2 py-0.5"
                      textClasses="text-xs font-semibold"
                    />
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      {/* Spend distribution among modules (Progress) */}
      <Progress
        classes="mt-3"
        data={{
          total: overSpend?.value ? 100 : totalSpendForProgressBar, // force 100% if overSpend
          items: moduleSpends.map((spendItem) => ({
            variant: overSpend?.value
              ? "danger"
              : BUDGET_PILL_CONFIG[BE_FE_BUDGET_KEY_MAPPING[spendItem.module]]
                  ?.variant,
            value: spendItem.value,
          })),
        }}
        neutralTrack
      />
      {/* Module wise spends */}
      {data?.modules?.length > 1 ? (
        <DotList
          classes="mt-3"
          items={moduleSpends.map((spendItem) => ({
            dotColorClasses: `bg-${
              BUDGET_PILL_CONFIG[BE_FE_BUDGET_KEY_MAPPING[spendItem.module]]
                ?.variant
            }-500`,
            textLabel:
              BUDGET_PILL_CONFIG[BE_FE_BUDGET_KEY_MAPPING[spendItem.module]]
                ?.label,
            textValue: amountToCurrency(spendItem.value, spendItem.currency),
          }))}
        />
      ) : null}

      {/* Budget limit: 200 SGD. flexible? */}
      <div className="flex items-center justify-between mt-3">
        <div className="flex items-center gap-2">
          <p className="text-sm font-medium text-neutral-500">
            <Text translationKey="company.budgets.budgetLimit" />
            <span>
              :&nbsp;
              {amountToCurrency(budgetLimit, currency)}
              &nbsp;
            </span>
            <Text
              classes="lowercase"
              translationKey={
                BUDGET_FREQUENCY_LABELS[BE_FE_FREQUENCY_MAP[frequency]]
              }
            />
          </p>
          {isFlexible ? <FlexibleBudgetBadge /> : null}
        </div>

        <div>
          <Text
            classes="text-sm font-medium text-neutral-400"
            translationKey={
              BE_FE_FREQUENCY_MAP[frequency] === BE_FE_FREQUENCY_MAP.one_time
                ? "cardSetting.BudgetLimitdoesntReset"
                : "cardSetting.budgetLimitReset"
            }
            translationProps={{
              date:
                BE_FE_FREQUENCY_MAP[frequency] === BE_FE_FREQUENCY_MAP.one_time
                  ? null
                  : getDateInPattern(
                      limitEndDate,
                      "mmm dd, yyyy",
                      "dd-mm-yyyy"
                    ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

BudgetRow.propTypes = {
  data: PropTypes.object,
  available: PropTypes.any,
  currency: PropTypes.string,
  overSpend: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }),
  classes: PropTypes.string,
  type: PropTypes.string,
  isFlexible: PropTypes.bool,
  isClickable: PropTypes.bool,
  noBadge: PropTypes.bool,
};

export const BudgetRowExample = (props) => (
  <BudgetRow
    available={{ value: 4000, currency: "SGD" }}
    currency="USD"
    overSpend={{ value: 600, currency: "SGD" }}
    data={
      {
        id: 1,
        modules: ["expense", "payment"],
        limit: 10000,
        frequency: "monthly",
        flexible: false,
        currency: "SGD",
        spend: [
          { module: "expense", value: 300, currency: "SGD" },
          { module: "payment", value: 400, currency: "SGD" },
          { module: "reimbursement", value: 200, currency: "SGD" },
        ],
      }
      // alternate example
      // {
      //   id: 1,
      //   modules: ["reimbursement"],
      //   limit: 20000,
      //   frequency: "recurring",
      //   flexible: true,
      //   spend: [{ module: "reimbursement", value: 200, currency: "SGD" }],
      // }
    }
    {...props}
  />
);
