import { useState } from "react";

import { copyToClipboard } from "@/components/core/CopyToClipboard";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function Developers() {
  const [showKey, setShowKey] = useState(false);

  const subdomain = "Volopay1";
  const secretKey =
    "djsnhisfvheiuvfhwiurhvdc sjdb vieru87436r2938102uevdksjdbndvb";

  const hiddenKey = "*".repeat(secretKey.length);

  const copy = (text) => {
    copyToClipboard(text);
  };

  return (
    <div className="flex items-start gap-14 my-9 justify-between">
      <div className="flex flex-col gap-2 w-2/5">
        <Text
          translationKey="settings.developersAPI.apiKeys.title"
          classes="text-lg font-medium"
        />
        <Text
          translationKey="settings.developersAPI.apiKeys.description"
          classes="text-sm text-neutral-500"
        />
      </div>
      <div className="flex flex-col gap-8 w-3/5">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <Text
              translationKey="settings.developersAPI.subdomain"
              classes="text-lg font-medium"
            />
            <span className="text-sm text-neutral-500">{subdomain}</span>
          </div>
          <div
            className="text-neutral-500 cursor-pointer"
            onClick={() => copy(subdomain)}
          >
            <Icon name="Copy" />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-4">
              <Text
                translationKey="settings.developersAPI.secretKey"
                classes="text-lg font-medium"
              />
              <div
                className="text-neutral-400 flex items-center justify-center"
                onClick={() => setShowKey((prev) => !prev)}
              >
                <Icon name={showKey ? "SlashEye" : "Eye"} className="w-4 h-4" />
              </div>
            </div>
            <span className="text-sm text-neutral-500">
              {showKey ? secretKey : hiddenKey}
            </span>
          </div>
          <div
            className="text-neutral-500 cursor-pointer"
            onClick={() => copy(secretKey)}
          >
            <Icon name="Copy" />
          </div>
        </div>
      </div>
    </div>
  );
}
