import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { clientSelector } from "@/store/selectors/client";

import FileUpload from "@/components/core/FileUpload";
import XlsxFilePreviewComponent from "@/components/core/FileUpload/PreviewComponents/XlsxFilePreviewComponent";
import Icon from "@/components/core/Icon";
import Memo from "@/components/core/Memo";
import Text from "@/components/core/Text";

import SliderAccountingSection from "@/components/Accounting/Transactions/common/Slider/SliderAccountingSection";
import ExpenseSliderOverviewCards from "@/components/Expenses/ExpenseSliders/ExpenseSlider/ExpenseSliderOverviewCards";
import SubmissionPolicyCard from "@/components/common/QrPayAndExpense/common/Sliders/SubmissionPolicy";
import { fieldIsRequired } from "@/utils/common";

import {
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
} from "@/constants/accounting";
import { DEFAULT_ACCEPT_WITH_PDF } from "@/constants/fileViewer";

export default function AccountingTransactionCardsOverviewTab({
  expense,
  isAccountingSlider,
  memo,
  handleMemoChange,
  splitExpenses,
  updateExpense,
  remindPeople,
  dismissPolicy,
  submissionPolicy,
  files,
  fileUploadHandler,
  primaryAction,
  secondaryAction,
  currentUser,
  isExpenseStatusHidden,
}) {
  const category = expense?.categoryName;
  const merchant = expense?.merchant?.name;
  const department = expense?.cardHolder?.departmentName;
  const location = expense?.cardHolder?.locationName;
  const clientDetails = useSelector(clientSelector);
  const accountingDateOptions = useMemo(
    () => ({
      transactionDate: expense?.expenseDate,
      ledgerDate: expense?.settlementDate,
    }),
    [expense]
  );
  const accountingForm = {
    category: {
      value: category,
    },
    merchant: {
      value: merchant,
    },
    department: {
      value: department,
    },
    location: {
      value: location,
    },
    accountingDateOptions,
  };

  return (
    <>
      <div className="mt-8">
        <ExpenseSliderOverviewCards isAccountingSlider />
      </div>

      {!isExpenseStatusHidden ? (
        <div className="mt-8">
          {isAccountingSlider ? (
            <SliderAccountingSection
              inputs={accountingForm}
              showSplit={
                clientDetails?.splitExpensesEnabled && expense?.editable
              }
              isAccounting={isAccountingSlider}
              transaction={expense}
              showAccountingTitle
              page={ACCOUNTING_TRANSACTION_PAGES.EXPENSE}
              splitExpenses={splitExpenses}
            />
          ) : null}
          <div className="mt-8">
            <Text
              translationKey="expenses.slider.policyDetails"
              classes="text-lg text-neutral-800 font-semibold mt-5"
            />
            <SubmissionPolicyCard
              handleRemindClick={remindPeople}
              handleDismissPolicy={dismissPolicy}
              expenseId={expense?.id}
              missingFields={expense?.missingFields ?? {}}
              status={expense?.submissionPolicyStatus}
              data={submissionPolicy}
            />
          </div>
          <div className="mt-8">
            <div className="flex items-center gap-2">
              <Text
                classes="text-lg font-semibold"
                translationKey="accounting.transactions.cards.slider.receipt"
              />
              {expense?.receipts?.length !== 0 ? (
                <span className="p-1 rounded-full bg-success-600 text-neutral-50">
                  <Icon name="Done" />
                </span>
              ) : null}
            </div>

            <div className="w-full mt-3">
              <FileUpload
                acceptText="common.defaultUploadTextWithPdf"
                accept={DEFAULT_ACCEPT_WITH_PDF}
                isDisabled={!expense?.editable}
                files={files?.length ? files : []}
                multiple
                showNoFileUI={!expense?.editable}
                handleFileChange={fileUploadHandler}
                primaryAction={primaryAction}
                hidePreviewUploadComponent={[
                  ACCOUNTING_TRANSACTION_STATUS.VERIFIED,
                  ACCOUNTING_TRANSACTION_STATUS.SYNCED,
                ].includes(expense?.accountingStatus)}
                primaryActionDisabled={!expense?.editable}
                secondaryActionDisabled={!expense?.editable}
                secondaryAction={secondaryAction}
                error={fieldIsRequired(
                  parseFloat(submissionPolicy?.receipt?.amount ?? 0, 10),
                  submissionPolicy?.receipt?.required,
                  expense?.amount
                )}
              />
            </div>
          </div>
          {/* MEMO */}
          <div className="mt-8">
            <Text
              translationKey="accounting.transactions.cards.listingFields.memo.header"
              classes="font-semibold text-neutral-800 text-lg"
            />
            <Memo
              disabled={!expense?.editable}
              value={memo}
              name="expenseMemo"
              onBlur={() => updateExpense({ remarks: memo })}
              onChange={handleMemoChange}
              error={fieldIsRequired(
                parseFloat(submissionPolicy?.memo?.amount ?? 0, 10),
                submissionPolicy?.memo?.required,
                expense?.amount
              )}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
AccountingTransactionCardsOverviewTab.propTypes = {
  expense: PropTypes.object,
  handleMemoChange: PropTypes.func,
  isAccountingSlider: PropTypes.bool,
  memo: PropTypes.string,
  splitExpenses: PropTypes.array,
  updateExpense: PropTypes.func,
  remindPeople: PropTypes.func,
  submissionPolicy: PropTypes.object,
  files: PropTypes.array,
  fileUploadHandler: PropTypes.func,
  primaryAction: PropTypes.object,
  secondaryAction: PropTypes.object,
  currentUser: PropTypes.object,
  isExpenseStatusHidden: PropTypes.bool,
};
