import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  setCurrentOnboardingSection,
  setCurrentOnboardingStep,
  setOnboardingSection,
} from "@/store/reducers/onboarding";

import { primaryCardProviderSelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Note from "@/components/core/Note";
import Text from "@/components/core/Text";

import { CARD_PROVIDER } from "@/constants/Cards";
import {
  ONBOARDING_SLIDER_PARAMS,
  ONBOARDING_STEPS,
} from "@/constants/onboarding";

function OnboardingWelcomePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const currentCardProviderInfo = useSelector(primaryCardProviderSelector);
  const currentCardProvider = currentCardProviderInfo?.slug;

  const { welcomeDescription, prerequisites, pointersArray, notes } =
    getOnboardingWelcomePageConfig();

  useEffect(() => {
    searchParams.set(ONBOARDING_SLIDER_PARAMS, ONBOARDING_STEPS.WELCOME);
    setSearchParams(searchParams);
  }, []);

  function getOnboardingWelcomePageConfig() {
    if (
      currentCardProvider?.toLowerCase() ===
      CARD_PROVIDER.PINELABS?.toLowerCase()
    ) {
      return {
        welcomeDescription:
          "Welcome to the world of effortless expense management. Begin your journey with your Volopay account in just a few minutes. Please take a moment to review the following prerequisites",
        prerequisites:
          "To ensure a smooth and hassle-free KYC process please have the prerequisites readily available",
        pointersArray: [
          {
            icon: "CheckCircle",
            text: "Any government issued ID such as PAN card or Driving license",
          },
        ],
        notes: {
          titleText:
            "Please ensure that you have all the necessary requirements ready before starting the KYC process. This will help us in verifying your identity and completing the process quickly",
        },
      };
    }

    return {
      welcomeDescription:
        "As per KYC requirements you are required to provide details according to your government issued ID",
      prerequisites:
        "For a smooth and hassle-free KYC process, it is essential to have the prerequisite details handy.",
      pointersArray: [
        {
          icon: "CheckCircle",
          text: "PAN card",
        },
        {
          icon: "CheckCircle",
          text: "Aadhaar details",
        },
        {
          icon: "CheckCircle",
          text: "Working camera on your laptop",
        },
      ],
      notes: {
        titleText:
          "Please ensure that you have all the necessary requirements ready before starting the KYC process. This will help us in verifying your identity and completing the process quickly",
      },
    };
  }

  function fetchPersonalDetailsSection() {
    let nextStep = null;
    const nextPage = ONBOARDING_STEPS.PERSONAL_DETAILS;

    if (currentCardProvider?.toLowerCase() === CARD_PROVIDER.LIVQUIK) {
      nextStep = ONBOARDING_STEPS.YOUR_DETAILS;
    } else {
      nextStep = ONBOARDING_STEPS.PERSONAL_DETAILS;
    }

    dispatch(setOnboardingSection(null));
    dispatch(setCurrentOnboardingStep(nextStep));
    dispatch(setCurrentOnboardingSection(nextPage));
  }

  return (
    <>
      <div className="relative flex flex-col items-start justify-center w-full h-full gap-10 p-9">
        <div className="flex flex-col h-full gap-10 pb-0 slider-content-core">
          <Text
            translationKey="myVolopay.welcomeToVolopay"
            classes="text-5xl text-neutral-800 font-semibold"
          />
          <Text
            translationKey={welcomeDescription}
            classes="text-lg font-bold text-neutral-500"
          />

          {pointersArray?.length > 0 ? (
            <div className="flex flex-col gap-4">
              <Text
                translationKey={prerequisites}
                classes="text-lg font-medium text-neutral-800"
              />
              {pointersArray?.map((note, index) => (
                <div key={index} className="flex flex-row items-center gap-3">
                  <Icon name="CheckCircle" className="text-primary-600" />

                  <Text
                    translationKey={note?.text}
                    classes="text-base
                  font-semibold
                  text-neutral-800"
                  />
                </div>
              ))}
            </div>
          ) : null}

          {notes ? (
            <Note
              titleText={notes?.titleText || ""}
              actionText={notes?.actionText || ""}
              descriptionText={notes?.descriptionText || ""}
            />
          ) : null}
        </div>
      </div>

      <div className="absolute bottom-0 flex items-center justify-end gap-6 p-4 slider-footer h-14">
        <Button label="misc.continue" onClick={fetchPersonalDetailsSection} />
      </div>
    </>
  );
}

export default OnboardingWelcomePage;
