import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";

export const BILLPAY_ACTION_PARAMS = {
  TYPE_PURCHASE_BILL: "purchase_bill",
  DO: {
    ADD_PAYMENT_DATE: "add_payment_date",
    VERIFIED: "verified",
    SKIP_APPROVALS: "skip_approvals",
    UNSCHEDULE_PAYMENT: "unschedule_payment",
  },
};

export const PAYROLL_ACTION_PARAMS = {
  TYPE_PAYROLL: "payroll",
  DO: {
    ADD_PAYMENT_DATE: "add_payment_date",
    VERIFIED: "verified",
    SKIP_APPROVALS: "skip_approvals",
    UNSCHEDULE_PAYMENT: "unschedule_payment",
  },
};

export const BILL_PAYROLL_CONTEXT = {
  BILLPAY: "billpay",
  PAYROLL: "payroll",
  PAID: "paid",
  ALL: "all",
  FAILED: "failed",
  BLOCK_USER: "blockUser",
  ACTION_CENTER_BILLPAY: "billPay-actionCentre",
  ACTION_CENTER_PAYROLL: "payroll-actionCentre",
};

export const BILL_PAYROLL_ACTION_CENTER_APPROVE_TYPES = {
  [BILL_PAYROLL_CONTEXT.BILLPAY]: BILLPAY_ACTION_PARAMS.TYPE_PURCHASE_BILL,
  [BILL_PAYROLL_CONTEXT.PAYROLL]: PAYROLL_ACTION_PARAMS.TYPE_PAYROLL,
  [ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY]:
    BILLPAY_ACTION_PARAMS.TYPE_PURCHASE_BILL,
  [ACTION_CENTRE_PAGE_CONTEXT.PAYROLL]: PAYROLL_ACTION_PARAMS.TYPE_PAYROLL,
};
