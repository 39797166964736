import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  getNetsuiteConfigurations,
  setNetsuiteConfig,
  syncChartOfAccounts,
  syncTaxCodes,
  syncTrackingCategories,
  syncVendors,
} from "@/store/reducers/accounting";
import { fetchClient } from "@/store/reducers/client";
// reducers
import { fetchTags } from "@/store/reducers/tags";

import {
  accountingIntegrationContinuousBillSyncSelector,
  accountingIntegrationJournalAutoApprovedSelector,
  accountingIntegrationSubsidiaryOptionsSelector,
  accountingIntegrationSyncModeSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";
import { getAccountingIntegrationsToastMessage } from "@/components/Accounting/Integrations/common/utils";
// selectors

// core components
import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import {
  EXECUTION_STATUS,
  INTEGRATION_SYNC_OPTIONS,
  INTEGRATION_SYNC_TOAST,
} from "@/utils/constants/integrations";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARES } from "@/constants/accounting";

import NetsuiteSyncOptionComponent from "./NetsuiteSyncOptionComponent";

export default function NetsuiteSyncSlider() {
  const dispatch = useDispatch();
  const [subsidiarySelected, setSubsidiarySelected] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [syncOptions, setSyncOptions] = useState(INTEGRATION_SYNC_OPTIONS);
  const subsidiaryOptions = useSelector(
    accountingIntegrationSubsidiaryOptionsSelector
  );
  const [syncMode, setSyncMode] = useState(
    useSelector(accountingIntegrationSyncModeSelector)
  );
  const [journalAsApproved, setJournalAsApproved] = useState(
    useSelector(accountingIntegrationJournalAutoApprovedSelector)
  );
  const isLoading = useSelector(isLoadingAccountingIntegrationsSelector);
  const [continuousBillSync, setContinuousBillSync] = useState(
    useSelector(accountingIntegrationContinuousBillSyncSelector)
  );

  useEffect(() => {
    dispatch(getNetsuiteConfigurations());
  }, []);

  const syncActions = {
    category: syncChartOfAccounts,
    vendors: syncVendors,
    trackingCategories: syncTrackingCategories,
    taxCodes: syncTaxCodes,
  };

  const handleContinue = async () => {
    const action = await dispatch(
      setNetsuiteConfig({
        journal_auto_approved: journalAsApproved,
        tenant_id: subsidiarySelected.id,
        sync_mode: syncMode,
        continuous_bill_sync: continuousBillSync,
      })
    );

    if (setNetsuiteConfig.fulfilled.match(action)) onSuccessfulSetConfig();
  };

  const onSuccessfulSetConfig = async () => {
    dispatch(fetchClient());

    const promises = Object.entries(syncOptions)
      .filter(([item, value]) => value.sync && syncActions[item])
      .map(([item]) => dispatch(syncActions[item]()));

    await Promise.all(promises);
    dispatch(fetchTags({ visible: true }));

    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.integrations.sync);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.integrations.software);
    setSearchParams(searchParams);

    getAccountingIntegrationsToastMessage(
      INTEGRATION_SYNC_TOAST[ACCOUNTING_SOFTWARES.NETSUITE][
        EXECUTION_STATUS.SUCCESS
      ]
    );
  };

  return (
    <div className="flex flex-col slider-content-container ">
      <div className="pb-10 slider-content-core">
        <div className="flex flex-col gap-8">
          <SliderHeader
            heading="accounting.integrations.netsuite.title"
            headingClasses="text-3xl font-semibold text-neutral-800"
            refFlag
          />

          <div className="flex flex-col gap-9 ">
            <div className="flex flex-col gap-3">
              <SliderHeader
                heading="accounting.integrations.netsuite.input.subsidiary.title"
                subText="accounting.integrations.netsuite.input.subsidiary.description"
                headingClasses="text-lg font-semibold text-neutral-800"
                subTextClasses="text-sm font-medium text-neutral-500"
              />

              <VpSelect
                label="accounting.integrations.netsuite.input.subsidiary.label"
                options={subsidiaryOptions}
                optionsDisplayKey="name"
                value={subsidiarySelected?.name}
                valueKey="name"
                handleChange={(option) => setSubsidiarySelected(option)}
                menuPosition="absolute"
              />
            </div>

            <div className="flex flex-col gap-4 p-4 border rounded-lg bg-neutral-50 border-neutral-300">
              <SliderHeader
                heading="accounting.integrations.labels.sync"
                subText="Fetch the accounting fields from Netsuite to Volopay"
                headingClasses="text-lg font-semibold text-neutral-800"
                subTextClasses="text-sm font-medium text-neutral-500"
              />
              <div className="flex flex-col justify-center gap-4">
                {Object.entries(syncOptions)?.map(([key, value]) => (
                  <Checkbox
                    key={key}
                    label={value.label}
                    labelClasses="text-base font-medium text-neutral-800"
                    checked={value.sync}
                    onClickHandler={(val) =>
                      setSyncOptions((prev) => ({
                        ...prev,
                        [key]: { ...value, sync: val },
                      }))
                    }
                  />
                ))}
              </div>
            </div>

            <NetsuiteSyncOptionComponent
              syncMode={syncMode}
              setSyncMode={setSyncMode}
              journalAsApproved={journalAsApproved}
              setJournalAsApproved={setJournalAsApproved}
              continuousBillSync={continuousBillSync}
              setContinuousBillSync={setContinuousBillSync}
            />
          </div>
        </div>
      </div>
      <div className="p-6 slider-footer ">
        <div className="flex justify-end ">
          <Button
            label="accounting.integrations.buttonLabel.syncContinue"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            disabled={!subsidiarySelected}
            showLoader={isLoading}
            onClick={() => {
              handleContinue();
            }}
            id={`netsuite-sync-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
          />
          {!subsidiarySelected ? (
            <Tooltip
              direction="top"
              id={`netsuite-sync-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
            >
              <Text translationKey="accounting.integrations.toolTipText.subsidiaryMandatory" />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}
