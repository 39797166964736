import PropTypes from "prop-types";

import Text from "@/components/core/Text";

import Tooltip from "../Tooltip";

export default function Radio({
  variant = "default",
  name,
  label,
  labelClasses,
  value,
  isChecked,
  disabled,
  handleChange,
  textClases,
  tooltipText, // TECH_DEBT does not work, width too small due to 'absolute usage''. Workaround: use dedicated <Tooltip /> outside this
  error,
  id,
  description = "",
  insideForm = false,
  translateLabel = true,
  radioButtonClasses = "", // for adding few styles in radio
}) {
  const handleRadioChange = (e) => {
    const { value: elementValue } = e.currentTarget;

    if (insideForm) handleChange(e);
    else handleChange(elementValue);
  };

  const cursorClass = () =>
    disabled ? "cursor-not-allowed" : "cursor-pointer";

  return variant === "box" ? (
    <div className="relative w-1/2 card-wrapper">
      <input
        type="radio"
        name={name}
        value={value}
        checked={isChecked}
        onChange={handleRadioChange}
        className={`absolute opacity-0 ${cursorClass()}  w-full h-full top-0 left-0 ${radioButtonClasses}`}
        disabled={disabled}
      />
      <div className="flex items-center justify-content">
        <span
          className={`h-5 w-5 min-w-[20px] mr-2 relative rounded-[10px] border-neutral-300 ${radioButtonClasses} ${
            isChecked
              ? `border-2 ${
                  disabled
                    ? "border-neutral-300 after:bg-neutral-300"
                    : "border-primary-500 after:bg-primary-500"
                } after:absolute after:h-3 after:w-3 after:rounded-[6px]  after:top-0.5 after:left-0.5`
              : `${disabled ? "bg-neutral-200" : "border"}`
          }`}
        />

        <div className="flex flex-col text-sm">
          <Text
            translationKey={label}
            classes={`font-semibold ${labelClasses}`}
          />
          <Text
            classes="font-normal text-neutral-500"
            translationKey={description}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-1">
      <label
        className={`text-base ${cursorClass()} flex items-center gap-2 relative select-none ${
          textClases ? textClases : ""
        }`}
      >
        <div className="relative inline-block w-5 h-5">
          <input
            type="radio"
            name={name}
            value={value}
            checked={isChecked}
            onChange={handleRadioChange}
            className={`absolute hover:accent-primary-500 disabled:accent-neutral-500 accent-primary-500 border-primary-500 h-5 w-5 ${cursorClass()} ${radioButtonClasses}`}
            disabled={disabled}
          />
        </div>

        {typeof label === typeof "" && translateLabel ? (
          <Text translationKey={label} classes={labelClasses} />
        ) : (
          label
        )}

        {tooltipText ? (
          <div>
            <Tooltip id={id} direction="top" maxWidth>
              <Text
                translationKey={tooltipText}
                classes="text-neutral-500 text-sm font-normal"
              />
            </Tooltip>
          </div>
        ) : null}
      </label>
      {error ? (
        <Text
          classes="pl-1 text-xs text-danger-600 block"
          translationKey={error}
        />
      ) : null}
    </div>
  );
}

Radio.propTypes = {
  variant: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelClasses: PropTypes.string,
  value: PropTypes.string,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  textClases: PropTypes.string,
  error: PropTypes.string,
  insideForm: PropTypes.bool,
  translateLabel: PropTypes.bool,
  radioButtonClasses: PropTypes.string,
  tooltipText: PropTypes.string,
  id: PropTypes.string,
};
