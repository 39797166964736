import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { QUICK_ACCESS_LINKS__ALL_ENTRIES } from "@/utils/constants/dashboard";

import "./styles.scss";

export default function QuickAccessAll({ onClose, actions }) {
  const allQuickAccess = QUICK_ACCESS_LINKS__ALL_ENTRIES;
  return (
    <div className="w-[642px] px-2 py-6 flex flex-col bg-white h-full ">
      <div className="flex items-center justify-between mb-8">
        <div>
          <Text
            translationKey="dashboard.quickAccess.title"
            classes="text-lg text-neutral-800 font-bold mb-3"
          />
        </div>
        <span
          onClick={onClose}
          className="cursor-pointer p-[5px] rounded-lg hover:bg-neutral-200"
        >
          <Icon name="Close" />
        </span>
      </div>
      <div className="flex flex-col overflow-auto gap-9">
        {Object.entries(actions).map(([actionKey, v]) => {
          const showSection = Object.values(v).reduce((acc, currentValue) => {
            // If any value is true, return true
            if (currentValue) {
              return true;
            }
            return acc;
          }, false);

          return (
            showSection && (
              <div key={actionKey}>
                <div className="mb-4">
                  <Text
                    translationKey={`dashboard.quickAccess.headings.${actionKey}`}
                    classes="text-base font-bold text-neutral-500 py-[1px]"
                  />
                </div>
                <div className="grid grid-cols-5 px-1 py-4 mr-5 card-wrapper-lg grow">
                  {Object.entries(actions[actionKey])
                    .filter(([_, value]) => value)
                    ?.map(([key, _]) => {
                      return (
                        <NavLink
                          className={`flex flex-col justify-start items-center text-center ${
                            actionKey[key] ? "hover:cursor-not-allowed" : ""
                          }`}
                          key={`wallet-cta-${allQuickAccess[actionKey]?.ctas?.[key]?.index}`}
                          to={
                            actionKey[key]
                              ? "#"
                              : allQuickAccess[actionKey]?.ctas?.[key]?.path
                          }
                        >
                          <div
                            className={`flex justify-center items-center h-11 w-11 p-5 rounded-full ${
                              actionKey[key]
                                ? "bg-neutral-100"
                                : allQuickAccess[actionKey]?.ctas?.[key]
                                    ?.bgColor
                            } mb-1`}
                          >
                            <Icon
                              name={
                                allQuickAccess[actionKey]?.ctas?.[key]?.icon
                              }
                              className={`h-[22.4px] w-[22.4px] ${
                                actionKey[key]
                                  ? "text-neutral-400"
                                  : allQuickAccess[actionKey]?.ctas?.[key]
                                      ?.iconColor
                              }`}
                            />
                          </div>
                          <div className="text-xs font-semibold text-neutral-800 text-center py-1 w-12.5">
                            <Text
                              translationKey={
                                allQuickAccess[actionKey]?.ctas?.[key]?.label
                              }
                            />
                          </div>
                        </NavLink>
                      );
                    })}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

QuickAccessAll.propTypes = {
  onClose: PropTypes.func,
  actions: PropTypes.object,
};
