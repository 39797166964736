import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import Button from "@/components/core/Button";

import { TRANSACTION_ACTIONS } from "@/constants/transactions";

export default function SyncAll({
  count,
  isFetching,
  handleSyncAll,
  syncableIds,
  page,
}) {
  const dispatch = useDispatch();
  return (
    <Button
      label="accounting.transactions.cards.sync"
      counter={count}
      type="success"
      classes="border border-neutral-200 w-[165px] h-10 active:bg-neutral-400 hover:bg-neutral-300"
      showLoader={isFetching}
      onClick={() => {
        const payload = {
          operation: TRANSACTION_ACTIONS.SYNC,
          accounting_ids: syncableIds,
          transaction_type: page,
        };
        handleSyncAll(dispatch, payload);
      }}
      disabled={count === 0}
    />
  );
}

SyncAll.propTypes = {
  count: PropTypes.number,
  isFetching: PropTypes.bool,
  handleSyncAll: PropTypes.func,
  syncableIds: PropTypes.array,
  page: PropTypes?.string,
};
