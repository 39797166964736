import { SORTING_CATEGORY } from "./sorting";

export const FAILURES_TABLE_HEADERS = [
  {
    label: "QRPay.failures.headers.date",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.DATE,
    className: "flex-row-reverse justify-end gap-5",
  },
  {
    label: "QRPay.failures.headers.amount",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.AMOUNT,
    className: "justify-end gap-4",
  },
  { label: "QRPay.failures.headers.walletHolder" },
  { label: "QRPay.failures.headers.declineReason" },
];

export const QRPAY_NEEDS_REVIEW_TABLE_HEADERS = [
  {
    label: "QRPay.failures.headers.date",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.DATE,
    class: "flex flex-1 min-w-0 gap-2 cursor-pointer",
  },
  {
    label: "QRPay.failures.headers.amount",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.AMOUNT,
    class: "flex justify-end gap-4 cursor-pointer",
  },
  { label: "QRPay.failures.headers.walletHolder" },
  { label: "QRPay.submissionPolicyStatus" },
  {
    label: "expenses.flagged.tableHeaders.receipt",
    class: "text-center",
  },
  {
    label: "expenses.flagged.tableHeaders.memo",
  },
  {
    label: "expenses.flagged.tableHeaders.accountingCategory",
    isAccountingTag: true,
  },
  {
    label: "expenses.flagged.tableHeaders.accountingVendor",
    isAccountingTag: true,
  },
  {
    label: "expenses.flagged.tableHeaders.action",
  },
];

export const EXPENSES_NEEDS_REVIEW_TABLE_HEADERS = [
  {
    label: "QRPay.failures.headers.date",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.DATE,
    class: "flex flex-1 min-w-0 gap-2  cursor-pointer",
    extraClasses: "justify-start",
  },

  {
    label: "QRPay.failures.headers.amount",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.AMOUNT,
    class: "flex  gap-2 flex-row-reverse  cursor-pointer",
    extraClasses: "justify-end",
  },
  {
    label: "expenses.overview.overviewTable.headers.card",
  },
  {
    label: "expenses.overview.overviewTable.headers.reviewStatus",
    classes: "text-left justify-center",
    id: "reviewStatusMultipleReviewLayers",
  },
  { label: "expenses.needsReview.tableHeaders.cardHolder" },
  { label: "QRPay.submissionPolicyStatus" },
  {
    label: "expenses.flagged.tableHeaders.receipt",
    class: "text-center",
  },
  {
    label: "expenses.flagged.tableHeaders.memo",
    class: "text-center",
  },

  {
    label: "expenses.flagged.tableHeaders.action",
    class: "text-center",
  },
];
export const FLAGGED_QRPAY_HEADER = [
  {
    label: "expenses.flagged.tableHeaders.date",
    haveArrow: true,
    class: "flex items-center gap-2 cursor-pointer justify-start",
    iconClass: "w-3 h-3",
    sortingCategory: SORTING_CATEGORY.DATE,
  },
  {
    label: "expenses.flagged.tableHeaders.amount",
    haveArrow: true,
    class:
      "flex items-center gap-2 flex-row-reverse  cursor-pointer justify-start",
    iconClass: "w-3 h-3",
    sortingCategory: SORTING_CATEGORY.AMOUNT,
  },
  { label: "QRPay.failures.headers.walletHolder" },

  {
    label: "expenses.flagged.tableHeaders.flaggedBy",
  },
  { label: "QRPay.submissionPolicyStatus" },

  {
    label: "expenses.flagged.tableHeaders.receipt",
    class: "text-center",
  },
  {
    label: "expenses.flagged.tableHeaders.memo",
    class: "text-left",
  },
  {
    label: "expenses.flagged.tableHeaders.accountingCategory",
    isAccountingTag: true,
  },
  {
    label: "expenses.flagged.tableHeaders.accountingVendor",
    isAccountingTag: true,
  },
  {
    label: "expenses.flagged.tableHeaders.action",
    class: "text-center",
  },
];
