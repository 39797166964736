import PropsTypes from "prop-types";

import Text from "@/components/core/Text";

export default function UserDetailCard({
  title,
  subtitle,
  children,
  classes,
  handleClick,
}) {
  return (
    <div
      className={`flex flex-col  border rounded-lg border-neutral-200 p-4 ${classes}`}
      onClick={handleClick}
    >
      <Text
        translationKey={subtitle}
        classes="text-sm text-neutral-500 font-semibold"
      />
      <div className="flex flex-row items-center gap-2 text-base font-semibold text-neutral-800 ">
        <Text translationKey={title} classes="truncate" />
        {children}
      </div>
    </div>
  );
}
UserDetailCard.propTypes = {
  title: PropsTypes.string,
  subtitle: PropsTypes.string,
  children: PropsTypes.any,
  classes: PropsTypes.string,
  handleClick: PropsTypes.func,
};
