import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import { INVOICE_STATUSES, RECEIPT_STATUSES } from "@/constants/common";

export default function ReceiptIcon({
  type = "receipt",
  index,
  receiptStatus,
}) {
  const TYPE_CONFIG = type === "receipt" ? RECEIPT_STATUSES : INVOICE_STATUSES;

  // const config =  required
  //   ? attached
  //     ? TYPE_CONFIG.SUBMITTED
  //     : TYPE_CONFIG.REQUIRED_NOT_SUBMITTED
  //   : TYPE_CONFIG.NOT_SUBMITTED;

  const config = RECEIPT_STATUSES?.[receiptStatus];

  return config ? (
    <>
      <Tooltip id={`receipt-${index}`} direction="top">
        {config.label}
      </Tooltip>
      <div className="flex justify-center" id={`receipt-${index}`}>
        {config?.icon === "Minus" ? (
          <Text translationKey="-" noTranslate />
        ) : (
          <Icon name={config.icon} className={config.class} />
        )}
      </div>
    </>
  ) : null;
}

ReceiptIcon.propTypes = {
  type: PropTypes.string,
  index: PropTypes.number,
  receiptStatus: PropTypes.string,
};
