import PropTypes from "prop-types";
import React from "react";

import Input from "@/components/core/Input";
import Note from "@/components/core/Note";
import Text from "@/components/core/Text";

export default function CardControlsInputField({
  title,
  description,
  handleChange,
  name,
  value,
  error,
}) {
  const isDescriptionOfTypeArray = typeof description === typeof [];
  const descriptionTextComponent = isDescriptionOfTypeArray
    ? generateDescriptionOfTypeArray()
    : () => {};

  function generateDescriptionOfTypeArray() {
    return (
      <div className="flex flex-col">
        {description?.map((text, index) => {
          return (
            <div className="flex flex-row items-center gap-3" key={index}>
              <span className="profile-widget-dot" />
              <Text translationKey={text} classes="text-xs font-medium" />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <Input
        name={name}
        value={value}
        label={title}
        placeholder={title}
        onChange={handleChange}
        description={!isDescriptionOfTypeArray ? description : ""}
        labelExtraClasses="text-xs font-semibold text-neutral-500"
      />

      {error && (
        <Text
          classes="font-medium text-xs text-danger-600 my-3"
          translationKey={error}
        />
      )}

      {isDescriptionOfTypeArray ? (
        <div className="mt-5">
          <Note
            descriptionText={descriptionTextComponent}
            titleText=""
            actionText=""
            backgroundColorClass="bg-warning-50"
            borderColorClass="border-warning-300"
          />
        </div>
      ) : null}
    </div>
  );
}

CardControlsInputField.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.number,
  error: PropTypes.string,
};
