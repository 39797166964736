import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function Note({
  titleText = "",
  descriptionText = "billPay.bill.invoiceInbox.createBill.sections.vendorNotPresent.text",
  actionText = "",

  onActionClickHandler = () => {},

  iconName = "Info",
  iconColorClass = "text-neutral-500",

  actionTextColorClass = "text-primary-500",
  backgroundColorClass = "bg-neutral-50",
  borderColorClass = "border-neutral-300",
  descriptionTextTranslationProps,
  externalDivClasses,
  showIcon = true,
  descriptionTextClasses = "",
}) {
  return (
    <div
      className={`p-4 flex gap-6 border-[1px] rounded-xl ${borderColorClass} ${backgroundColorClass} ${externalDivClasses}`}
    >
      {showIcon && iconName ? (
        <div>
          <Icon className={`${iconColorClass} w-5 h-5 m-0.5`} name={iconName} />
        </div>
      ) : null}
      <div>
        {titleText ? (
          <Text
            translationKey={titleText}
            classes="block font-semibold text-base text-neutral-800"
          />
        ) : null}

        {typeof descriptionText === typeof "" ? (
          descriptionText !== "" && descriptionText ? (
            <Text
              translationKey={descriptionText}
              translationProps={descriptionTextTranslationProps}
              classes={`block ${titleText ? "mt-1" : ""} font-medium text-sm  ${
                !descriptionTextClasses?.includes("text-")
                  ? "text-neutral-500"
                  : ""
              } ${descriptionTextClasses}`}
            />
          ) : null
        ) : (
          descriptionText
        )}

        {actionText !== "" && actionText ? (
          <div
            onClick={onActionClickHandler}
            className={`mt-3 text-sm font-semibold underline cursor-pointer ${actionTextColorClass}`}
          >
            <Text translationKey={actionText} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

Note.propTypes = {
  titleText: PropTypes.string,
  descriptionText: PropTypes.string,
  actionText: PropTypes.string,
  descriptionTextClasses: PropTypes.string,
  onActionClickHandler: PropTypes.func,
  iconName: PropTypes.string,
  iconColorClass: PropTypes.string,
  actionTextColorClass: PropTypes.string,
  backgroundColorClass: PropTypes.string,
  borderColorClass: PropTypes.string,
  descriptionTextTranslationProps: PropTypes.object,
  externalDivClasses: PropTypes.string,
  showIcon: PropTypes.bool,
};
