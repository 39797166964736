import PropTypes from "prop-types";

import CardDetail from "@/components/Cards/CardSlider/OverviewTab/CardDetail";

import { ROUTES } from "@/constants/routes";

// Policy applied to count cards
export default function AppliedCountCards({ departmentCount, projectCount }) {
  return (
    <div className="flex items-center w-full gap-5">
      {/* Count Cards of department and project (where the policies applies) */}
      <div className="w-1/2">
        <CardDetail
          nameKey="settings.appliedToCountCards.departments.label"
          value={departmentCount}
          linkTo={ROUTES.manage.company.departments.absolutePath}
        />
      </div>
      <div className="w-1/2">
        <CardDetail
          nameKey="settings.appliedToCountCards.projects.label"
          value={projectCount}
          linkTo={ROUTES.manage.company.projects.absolutePath}
        />
      </div>
    </div>
  );
}

AppliedCountCards.propTypes = {
  departmentCount: PropTypes.number,
  projectCount: PropTypes.number,
};
