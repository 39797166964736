import { MODULES } from "@/utils/constants/app";
import { DATE_RANGE_KEYS } from "@/constants/date";
import { ROUTES } from "@/constants/routes";

import { DATE_DIFFRENCE_DURATIONS, FORM_TYPE } from "@/constants/common";
import { CARD_PROVIDER } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export const DURATION = "duration";
export const SPEND_TYPE = "spendType";
export const TOTAL_SPEND = "totalspend";
export const CARDS_SPEND = "cards";
export const BILL_PAY_SPEND = "billpay";
export const REIMBURSEMENT_SPEND = "reimbursement";
export const CASHBACKS_SPEND = "cashbacks";
export const DECLINE_SPEND = "decline";
export const PAYMENTS_ACCOUNT_TYPE = "payments";
export const PAYROLL_ACCOUNT_TYPE = "payroll";
export const REVIEW_STATUS = "reviewStatus";
export const ACCOUNTING_STATUS = "accountingStatus";
export const BILL_STATUS = "billStatus";
export const CLAIM_STATUS = "claimStatus";
export const TRUTRIP_SUPER_ADMIN = "super-admin";

export const STATUS = {
  PENDING: "pending",
  INCOMPLETED: "incompleted",
  VERIFIED: "verified",
  SYNC_IN_PROGRESS: "sync_in_progress",
  PENDING_APPROVAL: "pending_approval",
};

export const USER_ROLE = {
  EMPLOYEE: "employee",
  ADMIN: "admin",
  Admin: "Admin",
  DEPARTMENT_MANAGER: "department_manager",
};

export const PROJECT_TYPE_TITLE = {
  DEPARTMENT: "Department",
  PROJECT: "Project",
};

export const PROJECT_MEMBER_TYPE = {
  OWNER: "owner",
  MEMBER: "member",
};

export const DEPARTMENT_SLIDER_TAB_KEYS = {
  SPENDS: "spends",
  BUDGETS: "budgets",
  PEOPLE: "people",
  HISTORY: "history",
};

export const DEPARTMENT = "department";
export const LOCATION = "location";
export const PROJECT = "project";
export const BUDGET = "budget";
export const NEW_SLIDER = "newSlider";
export const EDIT_SLIDER = "editSlider";

export const EXISTING_DEPARTMENT = "EXISTING_DEPARTMENT";
export const EXISTING_PROJECT = "EXISTING_PROJECT";
export const NEW_PROJECT = "NEW_PROJECT";

export const DEFINE_NEW_BUDGET_BE_FLAG = "defineNewBudget";

export const COMPANY_BUDGET_MODULES = {
  EXPENSE: "expense",
  PAYMENT: "payment",
  REIMBURSEMENT: "reimbursement",
};
export const AVAILABLE_BUDGETS_MODULES = {
  [COMPANY_BUDGET_MODULES.EXPENSE]: MODULES.CARDS,
  [COMPANY_BUDGET_MODULES.PAYMENT]: MODULES.BILL_PAY,
  [COMPANY_BUDGET_MODULES.REIMBURSEMENT]: MODULES.REIMBURSEMENTS,
};

export const ACCOUNT_CTAS = {
  TRANSACTIONS: "transactions",
  EXCHANGE: "exchange",
  STATEMENT: "statement",
  RECEIVE_FUNDS: "receiveFunds",
  ADD_FUNDS_WALLEX: "addFundsWallex",
  ADD_FUNDS_DECE: "addFundsDecentro",
  ADD_FUNDS_AIRWALLEX: "addFundsAirwallex",
  ADD_FUNDS_XENDIT: "addFundsXendit",
  TRANSFER_FUNDS_TO_CARD: "transferFundsToCard",
  ALLOCATE_FUNDS: "allocateFund",
};

export const ACCOUNT_CTAS_CONFIG = {
  [ACCOUNT_CTAS.ALLOCATE_FUNDS]: {
    icon: "Plus",
    text: "company.allocateFunds.title",
    onClick: ({ setSearchParams, account }) => {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.company.currency]: account?.currency,
        sendingToAccountKey: account?.accountType,
        [SLIDERS_SEARCH_PARAMS.company.allocate]: FORM_TYPE.edit,
      });
    },
  },
  [ACCOUNT_CTAS.TRANSACTIONS]: {
    icon: "Expense",
    text: "company.accountOverview.viewTransactions",
    onClick: ({ navigate, account }) => {
      navigate(
        ROUTES.accounting.ledger[account?.currency.toLowerCase()].absolutePath
      );
    },
  },
  [ACCOUNT_CTAS.ADD_FUNDS_WALLEX]: {
    icon: "MoveDown",
    text: "company.accountOverview.addOrReceiveFund",
    onClick: ({ setSearchParams, account }) => {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.company.fundAccount]: account?.currency,
      });
    },
  },
  [ACCOUNT_CTAS.ADD_FUNDS_DECE]: {
    icon: "MoveDown",
    text: "company.accountOverview.addFunds",
    onClick: ({ setSearchParams, account }) => {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.company.currency]: account?.currency,
        [SLIDERS_SEARCH_PARAMS.company.paymentOption]:
          PAYMENT_OPTION_TYPE.ADDFUND,
      });
    },
  },
  [ACCOUNT_CTAS.ADD_FUNDS_AIRWALLEX]: {
    icon: "MoveDown",
    text: "company.accountOverview.addOrReceiveFund",
    onClick: ({ setSearchParams, account }) => {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.company.currency]: account?.currency,
        [SLIDERS_SEARCH_PARAMS.company.paymentOption]:
          PAYMENT_OPTION_TYPE.ADDFUND,
      });
    },
  },
  [ACCOUNT_CTAS.ADD_FUNDS_XENDIT]: {
    icon: "MoveDown",
    text: "company.accountOverview.addOrReceiveFund",
    onClick: ({ setSearchParams, account }) => {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.company.currency]: account?.currency,
        [SLIDERS_SEARCH_PARAMS.company.paymentOption]:
          PAYMENT_OPTION_TYPE.ADDFUND,
      });
    },
  },
  [ACCOUNT_CTAS.EXCHANGE]: {
    icon: "Exchange",
    text: "company.accountOverview.exchangeFunds",
    onClick: ({ setSearchParams, account }) => {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.company.currency]: account?.currency,
        [SLIDERS_SEARCH_PARAMS.company.exchangeFund]: FORM_TYPE.edit,
      });
    },
  },
  [ACCOUNT_CTAS.STATEMENT]: {
    icon: "Download",
    text: "company.accountOverview.requestStatement",
    onClick: ({ setSearchParams, account }) => {
      setSearchParams({
        accountId: account.id,
        requestStatement: true,
      });
    },
  },
  [ACCOUNT_CTAS.TRANSFER_FUNDS_TO_CARD]: {
    icon: "Exchange",
    text: "Tcompany.accountOverview.transferFunds",
    onClick: ({ setSearchParams, account }) => {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.company.currency]: account?.currency,
        [SLIDERS_SEARCH_PARAMS.company.exchangeFund]: FORM_TYPE.edit,
      });
    },
  },
};

export const BUDGETS_TABS = [
  {
    name: "company.budgets.departmentBudgets.title",
    count: null,
    path: ROUTES.manage.company.budgets["departments-budgets"].absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "departments-budgets",
    },
  },
  {
    name: "company.budgets.projectBudgets.title",
    count: null,
    path: ROUTES.manage.company.budgets["project-budgets"].absolutePath,
    key: 2,
    defaultQueryParams: {
      tab: "project-budgets",
    },
  },
];

export const BUDGETS_DEPT_TABS = [
  {
    name: "company.budgets.departmentBudgets.title",
    count: null,
    path: ROUTES.manage.company.budgets["departments-budgets"].absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "departments-budgets",
    },
  },
];

export const BUDGETS_PROJECT_TABS = [
  {
    name: "company.budgets.projectBudgets.title",
    count: null,
    path: ROUTES.manage.company.budgets["project-budgets"].absolutePath,
    key: 1,
    defaultQueryParams: {
      tab: "project-budgets",
    },
  },
];

export const DEPARTMENT_SLIDER_TABS = [
  {
    name: "company.department.departmentDetails.tabs.spends.title",
    count: null,
    key: DEPARTMENT_SLIDER_TAB_KEYS.SPENDS,
  },
  {
    name: "company.department.departmentDetails.tabs.budgets.title",
    count: null,
    key: DEPARTMENT_SLIDER_TAB_KEYS.BUDGETS,
  },
  {
    name: "company.department.departmentDetails.tabs.people.title",
    count: null,
    key: DEPARTMENT_SLIDER_TAB_KEYS.PEOPLE,
  },
  {
    name: "company.department.departmentDetails.tabs.history.title",
    count: null,
    key: DEPARTMENT_SLIDER_TAB_KEYS.HISTORY,
    showComingSoon: true,
  },
];

// Department slider has 4 tabs (one of them is Spends)
// this Spends tab has 3 tabs of its own - recent, largest, top spenders
export const DEPARTMENT_SLIDER_SPENDS_TABS = [
  {
    name: "company.department.tabTitles.recentExpenses",
    count: null,
    key: 0,
  },
  {
    name: "company.department.tabTitles.largestExpense",
    count: null,
    key: 1,
  },
  {
    name: "company.department.tabTitles.topSpenders",
    count: null,
    key: 2,
  },
];

export const COMPANY_PEOPLE_TAB_PARAMS = {
  ALL: "all",
  BLOCKED: "blocked",
  PENDING: "pending",
  ACTIVE: "active",
};
export const COMPANY_USER_DETAILS_TAB_PARAMS = {
  HISTORY: "history",
  OVERVIEW: "overview",
  SETTINGS: "settings",
};

export const COMPANY_PEOPLE_USER_STATUS = {
  ACTIVE: "active",
  BLOCKED: "blocked",
  PENDING: "pending",
  SUBMITTED: "submitted",
  REJECTED: "rejected",
  ARCHIVED: "archived",
  LOCKED: "locked",
  FREEZED: "freezed",
};

export const COMPANY_PEOPLE_TABS = [
  {
    name: "company.people.tabTitles.all",
    path: COMPANY_PEOPLE_TAB_PARAMS.ALL,
    count: null,
    key: 0,
  },
  {
    name: "company.people.tabTitles.blocked",
    path: COMPANY_PEOPLE_TAB_PARAMS.BLOCKED,
    count: null,
    key: 1,
  },
];

export const COMPANY_USER_DETAILS_TAB = [
  {
    name: "company.people.peopleDetail.tabTitles.overview",
    path: COMPANY_USER_DETAILS_TAB_PARAMS.OVERVIEW,
    count: null,
    key: 0,
  },
  {
    name: "company.people.peopleDetail.tabTitles.settings",
    path: COMPANY_USER_DETAILS_TAB_PARAMS.SETTINGS,
    count: null,
    key: 1,
  },
  {
    name: "company.people.peopleDetail.tabTitles.history",
    path: COMPANY_USER_DETAILS_TAB_PARAMS.HISTORY,
    count: null,
    key: 2,
    showComingSoon: true,
  },
];

export const INVITATION_STATUS = {
  ACCEPTED: "accepted",
  NOT_EXPIRED: "not_expired",
  EXPIRED: "expired",
};

export const PENDING_INVITES_TAB_PARAMS = {
  PENDING: "pending",
  EXPIRED: "expired",
};

export const PENDING_INVITES_TABS = [
  {
    name: "company.people.pendingInvitesTabTitles.pending",
    path: PENDING_INVITES_TAB_PARAMS.PENDING,
    count: null,
    key: 0,
  },
  {
    name: "company.people.pendingInvitesTabTitles.expired",
    path: PENDING_INVITES_TAB_PARAMS.EXPIRED,
    count: null,
    key: 1,
  },
];

export const ROLE_PERMISSIONS_PEOPLE_TAB_PARAMS = {
  PERMISSIONS: "permissions",
  PEOPLE: "people",
};

export const ROLE_TYPE_TITLE = {
  PRIMARY: "primary",
  CUSTOM: "custom",
  DEFAULT: "default",
  PAYROLL: "payroll",
};

export const ROLE_PERMISSIONS_PEOPLE_TABS = [
  {
    name: "Permissions",
    path: ROLE_PERMISSIONS_PEOPLE_TAB_PARAMS.PERMISSIONS,
    count: null,
    key: 0,
  },
  {
    name: "People",
    path: ROLE_PERMISSIONS_PEOPLE_TAB_PARAMS.PEOPLE,
    count: null,
    key: 1,
  },
];

export const ROLE_PERMISSIONS_TABS = [
  {
    name: "Permissions",
    path: ROLE_PERMISSIONS_PEOPLE_TAB_PARAMS.PERMISSIONS,
    count: null,
    key: 0,
  },
];

export const PAYMENT_INITIATOR_ROLES = {
  PAYMENT_INITIATOR: "Payment initiator",
  SETTLEMENT_INITIATOR: "Settlement initiator",
};

export const PAYMENT_INITIATOR_MODULE = {
  [PAYMENT_INITIATOR_ROLES.PAYMENT_INITIATOR]: "Billpay",
  [PAYMENT_INITIATOR_ROLES.SETTLEMENT_INITIATOR]: "Reimbursement",
};

export const ROLE_PERMISSIONS_ICON_TAG = {
  COMPANY: "company",
  CARD: "card",
  SETTING: "setting",
  ACCOUNTING: "accounting",
  PAYMENT: "payment",
  CLAIM: "claim",
};

export const COMPANY_PENDING_ACCOUNTING_ACTION_ENTRIES = [
  {
    label: "dashboard.pendingAccountingAction.reviewExpenses",
    icon: "Checklist",
    key: "reviewExpense",
    iconColor: "text-primary-500",
    bgColor: "bg-white",
    path: `${ROUTES.cards.expenses.needsReview.absolutePath}`,
  },
  {
    label: "dashboard.needsYourAction.pendingTransactionReconciliation",
    icon: "BookOpen",
    key: "pendingTransactionReconciliation",
    iconColor: "text-primary-500",
    bgColor: "bg-white",
    path: `${ROUTES.accounting.transactions.cards.pending.absolutePath}`,
  },
  {
    label: "dashboard.needsYourAction.companyMissingDetails",
    icon: "ShieldCross",
    key: "companyMissingDetail",
    iconColor: "text-primary-500",
    bgColor: "bg-white",
    path: `${ROUTES.cards.expenses.overView.absolutePath}?${SLIDERS_SEARCH_PARAMS.expenses.missingDetails}=true`,
  },
  {
    label: "dashboard.needsYourAction.companysFailedPayments",
    icon: "BillPayCancel",
    key: "companyFailedPayments",
    iconColor: "text-primary-500",
    bgColor: "bg-white",
    path: ROUTES.billpay.bill.payments.failed.absolutePath,
  },
  {
    label: "dashboard.needsYourAction.pendingBillPayApprovals",
    icon: "BillPayCancel",
    key: "pendingBillPayApprovals",
    iconColor: "text-primary-500",
    bgColor: "bg-white",
    path: `${ROUTES.billpay.bill.approvals.all.absolutePath}?${BILL_STATUS}=${STATUS.PENDING_APPROVAL}`,
  },
  {
    label: "dashboard.needsYourAction.pendingCardRequests",
    icon: "CreditCard",
    key: "pendingCardRequests",
    iconColor: "text-primary-500",
    bgColor: "bg-white",
    path: ROUTES.cards.requests.pending.absolutePath,
  },
  {
    label: "dashboard.needsYourAction.pendingClaimApprovals",
    icon: "AccountBalanceWallet",
    key: "pendingClaimApprovals",
    iconColor: "text-primary-500",
    bgColor: "bg-white",
    path: `${ROUTES.reimbursement.approvals.all.absolutePath}?${CLAIM_STATUS}=${STATUS.PENDING_APPROVAL}`,
  },
  {
    label: "dashboard.needsYourAction.pendingWalletRequets",
    icon: "Wallet",
    key: "pendingWalletRequets",
    iconColor: "text-primary-500",
    bgColor: "bg-white",
    path: ROUTES.QRPay.qrPayments.overview.absolutePath,
  },
];

export const SPEND_LISTING_ENTITY_TYPES = {
  VENDOR: "VENDOR",
  CARD_MERCHANT: "CARD_MERCHANT",
  CARD_HOLDER: "CARD_HOLDER",
  CATEGORY: "CATEGORY",
  PEOPLE: "PEOPLE",
  DEPARTMENT: "DEPARTMENT",
  PROJECT: "PROJECT",
  LOCATION: "LOCATION",
};
export const SPEND_CATEGORY_REQUEST_KEY = "spend_category";
export const SPEND_TYPE_REQUEST_KEY = "spend_type";
export const FROM_DATE_REQUEST_KEY = "from_date";
export const TO_DATE_REQUEST_KEY = "to_date";
// department, vendor, location, user, category, card_merchant
export const SPEND_CATEGORY_REQUEST_VALUES = {
  VENDOR: "vendor",
  CARD_MERCHANT: "merchant",
  CATEGORY: "category",
  PEOPLE: "user",
  PROJECT: "project",
  DEPARTMENT: "department",
  LOCATION: "location",
};

// same as analytics/constant:FILTER_OPTIONS
export const SPEND_TYPES = {
  TOTAL: "total",
  CARDS: "cards",
  BILL_PAY: "billPay",
  REIMBURSEMENT: "reimbursement",
};

export const SPEND_TYPE_LABELS = {
  [SPEND_TYPES.TOTAL]: "company.overview.spendAnalytics.filters.allSpends",
  [SPEND_TYPES.CARDS]: "company.overview.spendAnalytics.filters.cardSpends",
  [SPEND_TYPES.BILL_PAY]:
    "company.overview.spendAnalytics.filters.billPayments",
  [SPEND_TYPES.REIMBURSEMENT]:
    "company.overview.spendAnalytics.filters.reimbursements",
};

export const SPEND_FILTER_DURATION = {
  THIS_MONTH: DATE_RANGE_KEYS.currentMonth,
  LAST_MONTH: DATE_RANGE_KEYS.lastMonth,
  QUARTER: DATE_RANGE_KEYS.quarter,
  LAST_6_MONTHS: DATE_RANGE_KEYS.last6Month,
  THIS_YEAR: DATE_RANGE_KEYS.currentYear,
  ALL_TIME: DATE_RANGE_KEYS.allTime,
  CURRENT_STATEMENT: DATE_RANGE_KEYS.currentStatement,
  PREVIOUS_STATEMENT: DATE_RANGE_KEYS.prevStatement,
};
export const SPEND_ANALYTICS_DURATIONS = {
  [DATE_RANGE_KEYS.currentMonth]: "THIS_MONTH",
  [DATE_RANGE_KEYS.last3Month]: "LAST_3_MONTHS",
  [DATE_RANGE_KEYS.last6Month]: "LAST_6_MONTHS",
  [DATE_RANGE_KEYS.currentYear]: "THIS_YEAR",
};
// monthly quarterly yearly customDateRange
export const SPEND_ANALYTICS_DATE_RANGE_LABELS = {
  THIS_MONTH: "dashboard.spendAnalytics.monthly",
  LAST_6_MONTHS: "dashboard.spendAnalytics.halfYearly",
  LAST_3_MONTHS: "filters.frequency.quarterly",
  THIS_YEAR: "dashboard.spendAnalytics.yearly",
  CUSTOM_RANGE: "dashboard.spendAnalytics.customRange",
};
export const LABEL_VS_DATE_RANGE_KEY = {
  "dashboard.spendAnalytics.monthly": DATE_RANGE_KEYS.currentMonth,
  "dashboard.spendAnalytics.halfYearly": DATE_RANGE_KEYS.last6Month,
  "filters.frequency.quarterly": DATE_RANGE_KEYS.last3Month,
  "dashboard.spendAnalytics.yearly": DATE_RANGE_KEYS.currentYear,
  "dashboard.spendAnalytics.customRange": null,
};
export const SPEND_FILTER_DURATION_KEYS = {
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  LAST_3_MONTHS: "LAST_3_MONTHS",
  LAST_6_MONTHS: "LAST_6_MONTHS",
  THIS_YEAR: "THIS_YEAR",
  ALL_TIME: "ALL_TIME",
  CURRENT_STATEMENT: "CURRENT_STATEMENT",
  PREVIOUS_STATEMENT: "PREVIOUS_STATEMENT",
  QUARTER: "QUARTER",
};
export const SPEND_FILTER_DURATION_LABELS = {
  [SPEND_FILTER_DURATION.THIS_MONTH]: "filter.dateRange.thisMonth",
  [SPEND_FILTER_DURATION.LAST_MONTH]: "filter.dateRange.lastMonth",
  [SPEND_FILTER_DURATION.QUARTER]: "filters.frequency.quarterly",
  [SPEND_FILTER_DURATION.LAST_6_MONTHS]: "filter.dateRange.last6Month",
  [SPEND_FILTER_DURATION.THIS_YEAR]: "filter.dateRange.thisYear",
  [SPEND_FILTER_DURATION.ALL_TIME]:
    "company.overview.spendAnalytics.filters.allTime",
  [SPEND_FILTER_DURATION.CURRENT_STATEMENT]:
    "company.overview.spendAnalytics.filters.currentStatement",
  [SPEND_FILTER_DURATION.PREVIOUS_STATEMENT]:
    "company.overview.spendAnalytics.filters.lastStatement",
  [SPEND_FILTER_DURATION.CUSTOM_RANGE]: "filter.dateRange.customRange",
};
export const SPEND_FREQUENCY = {
  MONTHLY: "monthly",
  WEEKLY: "weekly",
  DAILY: "daily",
  YEARLY: "yearly",
  QUARTERLY: "quarterly",
  SEMIANNUALLY: "semiannually",
};

export const SPEND_FREQUENCY_MODULE_WISE = {
  ANALYTICS: {
    [SPEND_FREQUENCY.MONTHLY]: SPEND_FREQUENCY.MONTHLY,
    [SPEND_FREQUENCY.QUARTERLY]: SPEND_FREQUENCY.QUARTERLY,
    [SPEND_FREQUENCY.WEEKLY]: SPEND_FREQUENCY.WEEKLY,
    [SPEND_FREQUENCY.DAILY]: SPEND_FREQUENCY.DAILY,
    [SPEND_FREQUENCY.YEARLY]: SPEND_FREQUENCY.YEARLY,
  },
  BILLPAY_AND_PAYROLL: {
    [SPEND_FREQUENCY.MONTHLY]: SPEND_FREQUENCY.MONTHLY,
    [SPEND_FREQUENCY.WEEKLY]: SPEND_FREQUENCY.WEEKLY,
    [SPEND_FREQUENCY.DAILY]: SPEND_FREQUENCY.DAILY,
    [SPEND_FREQUENCY.YEARLY]: SPEND_FREQUENCY.YEARLY,
    [SPEND_FREQUENCY.QUARTERLY]: SPEND_FREQUENCY.QUARTERLY,
    [SPEND_FREQUENCY.SEMIANNUALLY]: SPEND_FREQUENCY.SEMIANNUALLY,
  },
};

export const SPEND_FILTER_DURATION_SPENDS_LABELS = {
  [SPEND_FILTER_DURATION.THIS_MONTH]:
    "dashboard.spendAnalytics.thisMonthSpends",
  [SPEND_FILTER_DURATION.LAST_MONTH]:
    "dashboard.spendAnalytics.lastMonthSpends",
  [SPEND_FILTER_DURATION.LAST_6_MONTHS]:
    "dashboard.spendAnalytics.last6MonthSpends",
  [SPEND_FILTER_DURATION.THIS_YEAR]: "dashboard.spendAnalytics.thisYearSpends",
  [SPEND_FILTER_DURATION.ALL_TIME]: "dashboard.spendAnalytics.allTimeSpends",
  [SPEND_FILTER_DURATION.CURRENT_STATEMENT]:
    "dashboard.spendAnalytics.currentStatementSpends",
  [SPEND_FILTER_DURATION.PREVIOUS_STATEMENT]:
    "dashboard.spendAnalytics.previousStatementSpends",
  [SPEND_FILTER_DURATION.CUSTOM_RANGE]:
    "dashboard.spendAnalytics.customRangeSpends",
};

export const SPEND_FREQUENCY_LABELS = {
  [SPEND_FREQUENCY.MONTHLY]: "company.overview.spendAnalytics.filters.monthly",
  [SPEND_FREQUENCY.WEEKLY]: "company.overview.spendAnalytics.filters.weekly",
  [SPEND_FREQUENCY.DAILY]: "company.overview.spendAnalytics.filters.daily",
  [SPEND_FREQUENCY.YEARLY]: "company.overview.spendAnalytics.filters.yearly",
  [SPEND_FREQUENCY.QUARTERLY]:
    "company.overview.spendAnalytics.filters.quarterly",
  [SPEND_FREQUENCY.SEMIANNUALLY]:
    "company.overview.spendAnalytics.filters.semiAnnually",
};

export const SPEND_FILTER_DURATION_VS_LABELS = {
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.THIS_MONTH]]:
    "dashboard.spendAnalytics.vsLastMonth",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.LAST_MONTH]]:
    "dashboard.spendAnalytics.vsPreviousMonth",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.LAST_6_MONTHS]]:
    "dashboard.spendAnalytics.vsPrevious6Month",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.QUARTER]]:
    "dashboard.spendAnalytics.vsLastQuarter",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.THIS_YEAR]]:
    "dashboard.spendAnalytics.vsLastYear",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.ALL_TIME]]:
    "dashboard.spendAnalytics.vsAllTime",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.CURRENT_STATEMENT]]:
    "dashboard.spendAnalytics.vsLastStatement",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.PREVIOUS_STATEMENT]]:
    "dashboard.spendAnalytics.vsPrevStatement",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.CUSTOM_RANGE]]:
    "dashboard.spendAnalytics.vsCustomRange",
};

export const SPEND_FILTER_DURATION_THIS_LABELS = {
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.THIS_MONTH]]:
    "dashboard.spendAnalytics.inThisMonth",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.LAST_MONTH]]:
    "dashboard.spendAnalytics.inLastMonth",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.LAST_6_MONTHS]]:
    "dashboard.spendAnalytics.inLast6Month",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.QUARTER]]:
    "dashboard.spendAnalytics.inThisQuater",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.THIS_YEAR]]:
    "dashboard.spendAnalytics.inThisYear",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.ALL_TIME]]:
    "dashboard.spendAnalytics.allTime",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.CURRENT_STATEMENT]]:
    "dashboard.spendAnalytics.inCurrentStatement",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.PREVIOUS_STATEMENT]]:
    "dashboard.spendAnalytics.inLastStatement",
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.CUSTOM_RANGE]]:
    "dashboard.spendAnalytics.inCustomRange",
};

export const SPEND_FREQUENCY_FILTER_OBJECT = {
  [SPEND_FREQUENCY.MONTHLY]: {
    label: SPEND_FREQUENCY_LABELS[SPEND_FREQUENCY.MONTHLY],
    frequencyKey: SPEND_FREQUENCY.MONTHLY,
    value: SPEND_FREQUENCY.MONTHLY,
  },
  [SPEND_FREQUENCY.WEEKLY]: {
    label: SPEND_FREQUENCY_LABELS[SPEND_FREQUENCY.WEEKLY],
    frequencyKey: SPEND_FREQUENCY.WEEKLY,
    value: SPEND_FREQUENCY.WEEKLY,
  },
  [SPEND_FREQUENCY.DAILY]: {
    label: SPEND_FREQUENCY_LABELS[SPEND_FREQUENCY.DAILY],
    frequencyKey: SPEND_FREQUENCY.DAILY,
    value: SPEND_FREQUENCY.DAILY,
  },
  [SPEND_FREQUENCY.YEARLY]: {
    label: SPEND_FREQUENCY_LABELS[SPEND_FREQUENCY.YEARLY],
    frequencyKey: SPEND_FREQUENCY.YEARLY,
    value: SPEND_FREQUENCY.YEARLY,
  },
};

export const PAYMENT_OPTION_TYPE = {
  LOCAL: "local",
  SWIFT: "swift",
  PAYNOW: "payNow",
  ADDFUND: "addFund",
  GLOBAL: "global",
};

export const PAYMENT_PROVIDER_TYPE = {
  COLLECTION_VA: "COLLECTION_VA",
  COLLECTION_VA_REQUESTED: "COLLECTION_VA_REQUESTED",
};

export const ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP = {
  firstName: "user.bankDetails.firstName",
  lastName: "user.bankDetails.lastName",
  dateOfBirth: "user.bankDetails.dateOfBirth",
  companyName: "user.bankDetails.companyName",
  nickname: "user.bankDetails.nickname",
  personalEmail: "user.bankDetails.personalEmail",
  businessRegistrationNumber: "user.bankDetails.businessRegistrationNumber",
  legalRepFirstNameInChinese: "user.bankDetails.legalRepFirstNameInChinese",
  legalRepLastNameInChinese: "user.bankDetails.legalRepLastNameInChinese",
  legalRepIdNumber: "user.bankDetails.legalRepIdNumber",
  streetAddress: "user.bankDetails.streetAddress",
  city: "user.bankDetails.city",
  countryCode: "user.bankDetails.countryCode",
  postcode: "user.bankDetails.postcode",
  state: "user.bankDetails.state",
  accountCurrency: "user.bankDetails.accountCurrency",
  accountName: "user.bankDetails.accountName",
  institutionName: "user.bankDetails.bankName",
  bankCountryCode: "user.bankDetails.bankCountryCode",
  bankName: "user.bankDetails.bankName",
  bankStreetAddress: "user.bankDetails.bankStreetAddress",
  accountNumber: "user.bankDetails.accountNumber",
  swiftCode: "user.bankDetails.swiftCode",
  iban: "user.bankDetails.iban",
  accountRoutingType1: "user.bankDetails.accountRoutingType",
  accountRoutingValue1: "user.bankDetails.accountRoutingValue",
  transactionReference: "user.bankDetails.transactionReference",
  accountRoutingType2: "user.bankDetails.accountRoutingType",
  accountRoutingValue2: "user.bankDetails.accountRoutingValue",
  localClearingSystem: "user.bankDetails.localClearingSystem",
  purposeOfTransfer: "user.bankDetails.purposeOfTransfer",
  relationship: "user.bankDetails.relationship",
  mobile: "user.bankDetails.mobile",
  address: "user.bankDetails.address",
  emailAddress: "user.bankDetails.emailAddress",
  gstin: "user.bankDetails.gstin",
  pan: "user.bankDetails.pan",
  accountHolderName: "user.bankDetails.accountHolderName",
  ifsc: "user.bankDetails.ifsc",
  upiHandle: "user.bankDetails.upiHandle",
  purposeCode: "user.bankDetails.purposeCode",
  middleName: "user.bankDetails.middleName",
  email: "user.bankDetails.email",
  mobileNumber: "user.bankDetails.mobileNumber",
  nationality: "user.bankDetails.nationality",
  phoneNumber: "user.bankDetails.phoneNumber",
  country: "user.bankDetails.country",
  dateOfIncorporation: "user.bankDetails.dateOfIncorporation",
  paymentType: "user.bankDetails.paymentType",
  identificationType: "user.bankDetails.identificationType",
  identificationValue: "user.bankDetails.identificationValue",
  aba: "user.bankDetails.aba",
  bankBranchCode: "user.bankDetails.bankBranchCode",
  bankCode: "user.bankDetails.bankCode",
  bank_code: "user.bankDetails.bankCode",
  branch_code: "user.bankDetails.branchCode",
  bicSwift: "user.bankDetails.bicSwift",
  beneficiaryBicLocal: "user.bankDetails.beneficiaryBicLocal",
  sortCode: "user.bankDetails.sortCode",
  bankAccountType: "user.bankDetails.bankAccountType",
  IFSC: "user.bankDetails.IFSC",
  statementNarrative: "user.bankDetails.statementNarrative",
  branchCode: "user.bankDetails.branchCode",
  sourceOfIncome: "user.bankDetails.sourceOfIncome",
  beneficiaryRelationship: "user.bankDetails.beneficiaryRelationship",
  region: "user.bankDetails.region",
  stateOrProvince: "user.bankDetails.stateOrProvince",
  identificationNumber: "user.bankDetails.identificationNumber",
  bankAccountHolderName: "user.bankDetails.bankAccountHolderName",
  cnaps: "user.bankDetails.cnaps",
  clabe: "user.bankDetails.clabe",
  ifscCode: "user.bankDetails.ifscCode",
  bsbCode: "user.bankDetails.bsbCode",
  bsb: "user.bankDetails.bsbCode",
  ach: "user.bankDetails.achCode",
  fedwire: "user.bankDetails.fedwireCode",
  institutionNo: "user.bankDetails.institutionNo",
  currency: "user.bankDetails.currency",
  fundingSource: "user.bankDetails.fundingSource",
  ppnid: "user.bankDetails.ppnid",
  bankNameText: "user.bankDetails.bankNameText",
  bicSwiftIban: "user.bankDetails.bicSwiftIban",
  recipientName: "user.bankDetails.recipientName",
  businessNature: "user.bankDetails.businessNature",
};

export const ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM = {
  ACCOUNT_ROUTE_TYPE_1: "Account routing type1",
  ACCOUNT_ROUTE_TYPE_2: "Account routing type2",
  ACCOUNT_ROUTE_VALUE_1: "Account routing value1",
  ACCOUNT_ROUTE_VALUE_2: "Account routing value2",
};

export const SPEND_DURATION_VALID_FREQUENCY = {
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.THIS_MONTH]]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.LAST_MONTH]]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.LAST_6_MONTHS]]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.MONTHLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.THIS_YEAR]]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.MONTHLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.ALL_TIME]]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.YEARLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.MONTHLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [DATE_DIFFRENCE_DURATIONS.YEARLY]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.MONTHLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [DATE_DIFFRENCE_DURATIONS.MONTHLY]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.MONTHLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [DATE_DIFFRENCE_DURATIONS.WEEKLY]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [DATE_DIFFRENCE_DURATIONS.DAILY]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.PREVIOUS_STATEMENT]]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
  [SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.CUSTOM_RANGE]]: [
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.MONTHLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.WEEKLY],
    SPEND_FREQUENCY_FILTER_OBJECT[SPEND_FREQUENCY.DAILY],
  ],
};

// badge Spend comparition card

export const BADGE_SPEND_COMPARISION_CARD_KEYS = {
  POSITIVE: "POSITIVE",
  NEGATIVE: "NEGATIVE",
  NO_CHANGE: "NO_CHANGE",
};

export const BADGE_SPEND_COMPARISION_CARD = {
  [BADGE_SPEND_COMPARISION_CARD_KEYS.POSITIVE]: {
    variant: "success",
    preIcon: "ArrowUpward",
  },
  [BADGE_SPEND_COMPARISION_CARD_KEYS.NEGATIVE]: {
    variant: "danger",
    preIcon: "ArrowDownward",
  },
  [BADGE_SPEND_COMPARISION_CARD_KEYS.NO_CHANGE]: {
    variant: "neutral",
    preIcon: "NoChange",
  },
};
export const SPEND_COMPARISION_KEYS = {
  REIMBURSEMENT: "REIMBURSEMENT",
  BILL_PAYMENTS: "BILL_PAYMENTS",
  CARD_SPENDS: "CARD_SPENDS",
  CASHBACK: "CASHBACK",
  DECLINE: "DECLINE",
  BILL_PAY_PAID_VIA_VOLOPAY: "BILL_PAY_PAID_VIA_VOLOPAY",
  BILL_PAY_PAID_OUTSIDE_VOLOPAY: "BILL_PAY_PAID_OUTSIDE_VOLOPAY",
  REIM_PAID_VIA_VOLOPAY: "REIM_PAID_VIA_VOLOPAY",
  REIM_PAID_OUTSIDE_VOLOPAY: "REIM_PAID_OUTSIDE_VOLOPAY",
};
export const SPEND_TYPE_MAP_TO_SHOW_MORE_TABS = {
  [SPEND_TYPES.TOTAL]: [
    { key: SPEND_COMPARISION_KEYS.CARD_SPENDS, module: MODULES.CARDS },
    { key: SPEND_COMPARISION_KEYS.BILL_PAYMENTS, module: MODULES.BILL_PAY },
    {
      key: SPEND_COMPARISION_KEYS.REIMBURSEMENT,
      module: MODULES.REIMBURSEMENTS,
    },
  ],
  // [SPEND_TYPES.CARDS]: [
  //   SPEND_COMPARISION_KEYS.CASHBACK,
  //   SPEND_COMPARISION_KEYS.DECLINE,
  // ],
  [SPEND_TYPES.BILL_PAY]: [
    {
      key: SPEND_COMPARISION_KEYS.BILL_PAY_PAID_VIA_VOLOPAY,
      module: MODULES.BILL_PAY,
    },
    {
      key: SPEND_COMPARISION_KEYS.BILL_PAY_PAID_OUTSIDE_VOLOPAY,
      module: MODULES.BILL_PAY,
    },
  ],
  [SPEND_TYPES.REIMBURSEMENT]: [
    {
      key: SPEND_COMPARISION_KEYS.REIM_PAID_VIA_VOLOPAY,
      module: MODULES.REIMBURSEMENTS,
    },
    {
      key: SPEND_COMPARISION_KEYS.REIM_PAID_OUTSIDE_VOLOPAY,
      module: MODULES.REIMBURSEMENTS,
    },
  ],
};

export const SPEND_CHART_VISIBLE = {
  [SPEND_TYPES.TOTAL]: {
    [SPEND_TYPES.TOTAL]: true,
    [SPEND_TYPES.BILL_PAY]: MODULES.BILL_PAY,
    [SPEND_TYPES.CARDS]: MODULES.CARDS,
    [SPEND_TYPES.REIMBURSEMENT]: MODULES.REIMBURSEMENTS,
  },
  [SPEND_TYPES.CARDS]: {
    [SPEND_TYPES.CARDS]: MODULES.CARDS,
  },
  [SPEND_TYPES.BILL_PAY]: {
    [SPEND_TYPES.BILL_PAY]: MODULES.BILL_PAY,
  },
  [SPEND_TYPES.REIMBURSEMENT]: {
    [SPEND_TYPES.REIMBURSEMENT]: MODULES.REIMBURSEMENTS,
  },
};

export const INVITE_OPTIONS = [
  {
    title: "common.inviteViaMail",
    isUpcomingFeature: false,
  },
  { title: "common.inviteViaCSV", isUpcomingFeature: true },
  { title: "common.hrSoftware", isUpcomingFeature: true },
];
export const INVITE_PEOPLE_TYPES = {
  MEMBER: "member",
  ADMIN: "admin",
  EXTERNAL_ACCOUNTANT: "accountant",
};
export const INVITE_PEOPLE_FIELD_TYPES = {
  EMAIL: "email",
  DEPARTMENT: "department",
  LOCATION: "location",
  SKIP_KYC: "skip_kyc",
};
export const INVITE_PEOPLE_TYPES_SLIDER_CONFIG = {
  [INVITE_PEOPLE_TYPES.MEMBER]: {
    title: "company.people.member",
    type: INVITE_PEOPLE_TYPES.MEMBER,
    description: "company.people.memberDesc",
    fields: [
      INVITE_PEOPLE_FIELD_TYPES.EMAIL,
      INVITE_PEOPLE_FIELD_TYPES.DEPARTMENT,
      INVITE_PEOPLE_FIELD_TYPES.LOCATION,
      INVITE_PEOPLE_FIELD_TYPES.SKIP_KYC,
    ],
  },
  [INVITE_PEOPLE_TYPES.ADMIN]: {
    title: "company.people.admin",
    type: INVITE_PEOPLE_TYPES.ADMIN,
    fields: [
      INVITE_PEOPLE_FIELD_TYPES.EMAIL,
      INVITE_PEOPLE_FIELD_TYPES.DEPARTMENT,
      INVITE_PEOPLE_FIELD_TYPES.LOCATION,
      INVITE_PEOPLE_FIELD_TYPES.SKIP_KYC,
    ],
    description: "company.people.adminDesc",
  },
  [INVITE_PEOPLE_TYPES.EXTERNAL_ACCOUNTANT]: {
    title: "company.people.externalAcc",
    type: INVITE_PEOPLE_TYPES.EXTERNAL_ACCOUNTANT,
    fields: [INVITE_PEOPLE_FIELD_TYPES.EMAIL],
    description: "company.people.externalAccDesc",
  },
};
export const POLICY_FILTER_VALUES = {
  DEFAULT: "default_policy",
  CUSTOM: "custom_policy",
};

export const BUDGET_TYPE_FILTER_VALUES = {
  DEFAULT: "default_budget",
  CUSTOM: "custom_budget",
};

export const REVIEW_EDIT_BUDGET_KEYS = {
  AMOUNT: "amount",
  FREQUENCY: "frequency",
  BUDGET_TYPE: "budgetType",
  BUDGET_FOR: "budgetFor",
};

export const BUDGET_FREQUENCY = {
  ONE_TIME: "ONE_TIME",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  HALF_YEARLY: "HALF_YEARLY",
  YEARLY: "YEARLY",
  WEEKLY: "WEEKLY",
  DAILY: "DAILY",
};

export const BE_FE_FREQUENCY_MAP = {
  one_time: "ONE_TIME",
  monthly: "MONTHLY",
  quarterly: "QUARTERLY",
  half_yearly: "HALF_YEARLY",
  yearly: "YEARLY",
  weekly: "WEEKLY",
  daily: "DAILY",
};

export const BUDGET_FREQUENCY_LABELS = {
  [BUDGET_FREQUENCY.ONE_TIME]: "filters.frequency.oneTime",
  [BUDGET_FREQUENCY.MONTHLY]: "filters.frequency.monthly",
  [BUDGET_FREQUENCY.QUARTERLY]: "filters.frequency.quarterly",
  [BUDGET_FREQUENCY.HALF_YEARLY]: "filters.frequency.halfYearly",
  [BUDGET_FREQUENCY.YEARLY]: "filters.frequency.yearly",
  [BUDGET_FREQUENCY.WEEKLY]: "Weekly",
  [BUDGET_FREQUENCY.DAILY]: "Daily",
};
export const BUDGET_FREQUENCY_VS_LABELS = {
  [BUDGET_FREQUENCY.ONE_TIME]: "cards.listingFields.budget.vsLabels.oneTime",
  [BUDGET_FREQUENCY.MONTHLY]: "cards.listingFields.budget.vsLabels.monthly",
  [BUDGET_FREQUENCY.QUARTERLY]: "cards.listingFields.budget.vsLabels.quarterly",
  [BUDGET_FREQUENCY.HALF_YEARLY]:
    "cards.listingFields.budget.vsLabels.halfYearly",
  [BUDGET_FREQUENCY.YEARLY]: "cards.listingFields.budget.vsLabels.yearly",
};
export const BUDGET_FREQUENCY_VS_TITLE = {
  [BUDGET_FREQUENCY.ONE_TIME]: "cards.listingFields.budget.titleLabels.oneTime",
  [BUDGET_FREQUENCY.MONTHLY]: "cards.listingFields.budget.titleLabels.monthly",
  [BUDGET_FREQUENCY.QUARTERLY]:
    "cards.listingFields.budget.titleLabels.quarterly",
  [BUDGET_FREQUENCY.HALF_YEARLY]:
    "cards.listingFields.budget.titleLabels.halfYearly",
  [BUDGET_FREQUENCY.YEARLY]: "cards.listingFields.budget.titleLabels.yearly",
};

export const BUDGET_SLIDER_SEARCHPARAM_VALUES = {
  MAIN: "main",
};
export const PROJECT_DEPARTMENT_CREATE_LISTING = {
  [EXISTING_DEPARTMENT]: DEPARTMENT,
  [EXISTING_PROJECT]: PROJECT,
};
export const ALL_TABS_IN_BUDGET_MAIN_PAGE = {
  [EXISTING_DEPARTMENT]: {
    title:
      "company.department.addNewDepartment.budgets.existingDepartment.title",
    description:
      "company.department.addNewDepartment.budgets.existingDepartment.description",
    submitKey: "EXISTING_DEPARTMENT",
  },
  [EXISTING_PROJECT]: {
    title: "company.department.addNewDepartment.budgets.existingProject.title",
    description:
      "company.department.addNewDepartment.budgets.existingProject.description",
    submitKey: "EXISTING_PROJECT",
  },
  [NEW_PROJECT]: {
    title: "company.department.addNewDepartment.budgets.newProject.title",
    description:
      "company.department.addNewDepartment.budgets.newProject.description",
    submitKey: "NEW_PROJECT",
  },
};

// TODO: will add in ALL_TABS_IN_BUDGET_MAIN_PAGE constant

export const ONLY_DEPARTMENT_TABS_IN_BUDGET_MAIN_PAGE = {
  [EXISTING_DEPARTMENT]: {
    title:
      "company.department.addNewDepartment.budgets.existingDepartment.title",
    description:
      "company.department.addNewDepartment.budgets.existingDepartment.description",
    submitKey: "EXISTING_DEPARTMENT",
  },
};

export const ONLY_PROJECT_TABS_IN_BUDGET_MAIN_PAGE = {
  [EXISTING_PROJECT]: {
    title: "company.department.addNewDepartment.budgets.existingProject.title",
    description:
      "company.department.addNewDepartment.budgets.existingProject.description",
    submitKey: "EXISTING_PROJECT",
  },
  [NEW_PROJECT]: {
    title: "company.department.addNewDepartment.budgets.newProject.title",
    description:
      "company.department.addNewDepartment.budgets.newProject.description",
    submitKey: "NEW_PROJECT",
  },
};
// TODO: FIX_TRANSLATION

export const BUDGETED_FOR_EXPENSE_KEYS = {
  EXPENSE: "expense",
  PAYMENT: "payment",
  REIMBURSEMENT: "reimbursement",
  QR: "qr",
  DOMESTIC_EXPENSE: "domestic_expense",
};

export const BUDGET_FOR_CONFIG = {
  [BUDGETED_FOR_EXPENSE_KEYS.EXPENSE]: {
    id: 1,
    name: "company.budgets.allowedBudgetsForModulesConfig.expense",
    label: "company.budgets.allowedBudgetsForModulesConfig.expense",
    value: BUDGETED_FOR_EXPENSE_KEYS.EXPENSE,
  },
  [BUDGETED_FOR_EXPENSE_KEYS.DOMESTIC_EXPENSE]: {
    id: 2,
    name: "company.budgets.allowedBudgetsForModulesConfig.domesticExpense",
    label: "company.budgets.allowedBudgetsForModulesConfig.domesticExpense",
    value: BUDGETED_FOR_EXPENSE_KEYS.EXPENSE,
  },
  [BUDGETED_FOR_EXPENSE_KEYS.PAYMENT]: {
    id: 3,
    name: "company.budgets.allowedBudgetsForModulesConfig.payment",
    label: "company.budgets.allowedBudgetsForModulesConfig.payment",
    value: BUDGETED_FOR_EXPENSE_KEYS.PAYMENT,
  },
  [BUDGETED_FOR_EXPENSE_KEYS.REIMBURSEMENT]: {
    id: 4,
    name: "company.budgets.allowedBudgetsForModulesConfig.reimbursement",
    label: "company.budgets.allowedBudgetsForModulesConfig.reimbursement",
    value: BUDGETED_FOR_EXPENSE_KEYS.REIMBURSEMENT,
  },
};

export const TRAVEL_CAROUSEL_DATA = [
  {
    heading: "company.travel.carouselTexts.text1.heading",
    subData: [
      {
        label: "company.travel.carouselTexts.text1.subText1.heading",
        description: "company.travel.carouselTexts.text1.subText1.description",
        icon: "AirplaneTicket",
      },
      {
        label: "company.travel.carouselTexts.text1.subText2.heading",
        description: "company.travel.carouselTexts.text1.subText2.description",
        icon: "AlarmOn",
      },
      {
        label: "company.travel.carouselTexts.text1.subText3.heading",
        description: "company.travel.carouselTexts.text1.subText3.description",
        icon: "VerifiedUser",
      },
    ],
  },
  {
    heading: "company.travel.carouselTexts.text2.heading",
    subData: [
      {
        label: "company.travel.carouselTexts.text2.subText1.heading",
        description: "company.travel.carouselTexts.text2.subText1.description",
        icon: "AlarmOn",
      },
      {
        label: "company.travel.carouselTexts.text2.subText2.heading",
        description: "company.travel.carouselTexts.text2.subText2.description",
        icon: "DashboardOther",
      },
      {
        label: "company.travel.carouselTexts.text2.subText3.heading",
        description: "company.travel.carouselTexts.text2.subText3.description",
        icon: "Forum",
      },
    ],
  },
  {
    heading: "company.travel.carouselTexts.text3.heading",
    subData: [
      {
        label: "company.travel.carouselTexts.text3.subText1.heading",
        description: "company.travel.carouselTexts.text3.subText1.description",
        icon: "SelectGesture",
      },
      {
        label: "company.travel.carouselTexts.text3.subText2.heading",
        description: "company.travel.carouselTexts.text3.subText2.description",
        icon: "Dollar",
      },
      {
        label: "company.travel.carouselTexts.text3.subText3.heading",
        description: "company.travel.carouselTexts.text3.subText3.description",
        icon: "CheckedHeart",
      },
    ],
  },
];

export const COMPANY_TRAVEL_HELP_URL =
  "https://help.volopay.co/en/articles/6714606-connect-to-trutrip";

export const SYNC_FAILED_TRANSATIONS_SLIDER_TABS_PARAMS = {
  OVERVIEW: "Overview",
  HISTORY: "History",
};

export const SYNC_FAILED_TRANSATIONS_SLIDER_TABS = [
  {
    name: SYNC_FAILED_TRANSATIONS_SLIDER_TABS_PARAMS.OVERVIEW,
    path: SYNC_FAILED_TRANSATIONS_SLIDER_TABS_PARAMS.OVERVIEW,
    count: null,
    key: 0,
  },
  {
    name: SYNC_FAILED_TRANSATIONS_SLIDER_TABS_PARAMS.HISTORY,
    path: SYNC_FAILED_TRANSATIONS_SLIDER_TABS_PARAMS.HISTORY,
    count: null,
    key: 1,
  },
];

export const MISSING_DETAILS_TABS_TITLES = {
  EXPENSES: "Expenses",
  QR_PAYMENTS: "QR payments",
};

export const MISSING_DETAILS_SLIDER_TABS = [
  {
    name: MISSING_DETAILS_TABS_TITLES.EXPENSES,
    path: MISSING_DETAILS_TABS_TITLES.EXPENSES,
    key: 0,
  },
];

export const INVITE_TYPE_MAP = {
  admin: "Admin",
  accountant: "External accountant",
  member: "Member",
};

export const ROLE_TYPE = {
  CUSTOM: "custom",
  DEFAULT: "default",
  PRIMARY: "primary",
  INTERNAL: "internal",
};

export const ROLE_TITLE = {
  ADMIN: "Admin",
  MEMBER: "Member",
  EXTERNAL_ACCOUNTANT: "External accountant",
  CARD_MANAGER: "Card manager",
  AP_CLERK: "AP clerk",
  PAYMENT_INITIATOR: "Payment initiator",
  DEPARTMENT_MANAGER: "Department manager",
  PROJECT_MANAGER: "Project manager",
  CLAIM_MANAGER: "Claim manager",
  SETTLEMENT_INITIATOR: "Settlement initiator",
  EXPENSE_MANAGER: "Expense manager",
  QR_PAYMENT_MANAGER: "QRPayment manager",
  QR_WALLET_MANAGER: "QRWallet manager",
};

export const PERMISSIONS_TYPES = [
  "company",
  "card",
  "qrpay",
  "qrwallet",
  "billpay",
  "reimbursement",
  "accounting",
  "expense",
  "global",
];

export const PERMISSIONS_TYPES_CONFIG = {
  COMPANY: "company",
  CARD: "card",
  SETTING: "setting",
  GLOBAL: "global",
  ACCOUNTING: "accounting",
  PAYMENT: "payment",
  CLAIM: "claim",
  EXPENSE: "expense",
  QRPAY: "qrpay",
  QR_WALLET: "qrwallet",
  BILLPAY: "billpay",
  REIMBURSEMENT: "reimbursement",
};

export const PERMISSIONS_ACCESSIBILITY_TYPE = {
  EDIT: "edit",
  VIEW: "view",
};

export const SETTING_PERMISSIONS_ACTION_TYPE = {
  EDIT: "edit",
  ADD: "add",
};

export const PERMISSIONS_CTA_CONFIG = {
  EDIT_PERMISSIONS: "editPermissions",
};

export const PERMISSIONS_TITLE_MAP = {
  [PERMISSIONS_TYPES_CONFIG.GLOBAL]: "Settings",
  [PERMISSIONS_TYPES_CONFIG.ACCOUNTING]: "Accounting",
  [PERMISSIONS_TYPES_CONFIG.BILLPAY]: "Billpay",
  [PERMISSIONS_TYPES_CONFIG.CARD]: "Cards",
  [PERMISSIONS_TYPES_CONFIG.COMPANY]: "Company",
  [PERMISSIONS_TYPES_CONFIG.QRPAY]: "QR pay",
  [PERMISSIONS_TYPES_CONFIG.REIMBURSEMENT]: "Reimbursements",
};

export const PERMISSIONS_TYPE_MAP = {
  CARD_MANAGE: "card:manage",
  EXPENSE_MANAGE: "expense:manage",
  CARD_SETTINGS_MANAGE: "card_settings:manage",
  COMPANY_MANAGE_ACCOUNTS: "company:manage_accounts",
  COMPANY_MANAGE_PEOPLE_LOCATIONS: "company:manage_people_and_locations",
  COMPANY_MANAGE_PROJECT: "company:manage_project",
  COMPANY_MANAGE_DEPARTMENT: "company:manage_department",
  COMPANY_MANAGE_TRAVEL: "company:manage_travel",
  QRPAY_MANAGE: "qrpay:manage",
  QRPAY_SETTINGS_MANAGE: "qrpay_settings:manage",
  QR_WALLET_MANAGE: "qrwallet:manage",
  BILLPAY_MANAGE: "billpay:manage",
  BILLPAY_AUTHORIZE_PAYMENT: "billpay:authorize_payment",
  BILLPAY_SETTINGS_MANAGE: "billpay_settings:manage",
  BILLPAY_MANAGE_SELF_CREATION: "billpay:manage_self_creation",
  REIMBURSEMENT_MANAGE: "reimbursement:manage",
  REIMBURSEMENT_SETTLE: "reimbursement:settle",
  REIMBURSEMENT_SETTINGS_MANAGE: "reimbursement_settings:manage",
  ACCOUNTING_MANAGE: "accounting:manage",
  SETTINGS_MANAGE: "global_settings:manage",
};

export const PERMISSIONS_SORTING_CONFIG = {
  [PERMISSIONS_TYPES_CONFIG.CARD]: [
    { priority: 1, name: PERMISSIONS_TYPE_MAP.CARD_MANAGE },
    { priority: 2, name: PERMISSIONS_TYPE_MAP.EXPENSE_MANAGE },
    { priority: 3, name: PERMISSIONS_TYPE_MAP.CARD_SETTINGS_MANAGE },
  ],
  [PERMISSIONS_TYPES_CONFIG.COMPANY]: [
    { priority: 1, name: PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_ACCOUNTS },
    { priority: 2, name: PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_PEOPLE_LOCATIONS },
    { priority: 3, name: PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_DEPARTMENT },
    { priority: 4, name: PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_PROJECT },
    { priority: 5, name: PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_TRAVEL },
  ],
  [PERMISSIONS_TYPES_CONFIG.QRPAY]: [
    { priority: 1, name: PERMISSIONS_TYPE_MAP.QRPAY_MANAGE },
    { priority: 2, name: PERMISSIONS_TYPE_MAP.QR_WALLET_MANAGE },
    { priority: 3, name: PERMISSIONS_TYPE_MAP.QRPAY_SETTINGS_MANAGE },
  ],
  [PERMISSIONS_TYPES_CONFIG.BILLPAY]: [
    { priority: 1, name: PERMISSIONS_TYPE_MAP.BILLPAY_MANAGE },
    { priority: 2, name: PERMISSIONS_TYPE_MAP.BILLPAY_AUTHORIZE_PAYMENT },
    { priority: 3, name: PERMISSIONS_TYPE_MAP.BILLPAY_SETTINGS_MANAGE },
  ],
  [PERMISSIONS_TYPES_CONFIG.REIMBURSEMENT]: [
    { priority: 1, name: PERMISSIONS_TYPE_MAP.REIMBURSEMENT_MANAGE },
    { priority: 2, name: PERMISSIONS_TYPE_MAP.REIMBURSEMENT_SETTLE },
    { priority: 3, name: PERMISSIONS_TYPE_MAP.REIMBURSEMENT_SETTINGS_MANAGE },
  ],

  [PERMISSIONS_TYPES_CONFIG.ACCOUNTING]: [
    { priority: 1, name: PERMISSIONS_TYPE_MAP.ACCOUNTING_MANAGE },
  ],

  [PERMISSIONS_TYPES_CONFIG.SETTING]: [
    { priority: 1, name: PERMISSIONS_TYPE_MAP.SETTINGS_MANAGE },
  ],
};

export const ROLES_MODULE_CONFIG = {
  [ROLE_TITLE.DEPARTMENT_MANAGER]: "policy.company",
  [ROLE_TITLE.PROJECT_MANAGER]: "policy.company",
  [ROLE_TITLE.QR_PAYMENT_MANAGER]: "policy.qrpays",
  [ROLE_TITLE.QR_WALLET_MANAGER]: "policy.qrpays",
  [ROLE_TITLE.AP_CLERK]: "policy.payments",
  [ROLE_TITLE.EXPENSE_MANAGER]: "policy.cards",
  [ROLE_TITLE.CARD_MANAGER]: "policy.cards",
  [ROLE_TITLE.PAYMENT_INITIATOR]: "policy.payments",
  [ROLE_TITLE.SETTLEMENT_INITIATOR]: "policy.reimbursements",
};

export const PERMISSIONS_HEADERS_DESCRIPTION_CONFIG = {
  [ROLE_TITLE.ADMIN]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription1.description",
        icon: "SettingsSync",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription2.description",
        icon: "ManageAccount",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription3.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription3.description",
        modules: [MODULES.CARDS],
        icon: "Settings",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription4.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription4.description",
        icon: "Accounting",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription5.heading",
        modules: [MODULES.CARDS],
        description:
          "company.rolesPermissions.rolePermissionsDataText.admin.headingDescription5.description",
        icon: "Savings",
      },
    ],
  },
  [ROLE_TITLE.MEMBER]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.member.headingDescription1.heading",
        modules: [MODULES.CARDS],
        description:
          "company.rolesPermissions.rolePermissionsDataText.member.headingDescription1.description",
        icon: "PersonCircled",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.member.headingDescription2.heading",
        headerConfig: [
          {
            text: "company.rolesPermissions.rolePermissionsDataText.member.headingDescription2.headerConfig.card",
            modules: [MODULES.CARDS],
          },
          {
            text: "company.rolesPermissions.rolePermissionsDataText.member.headingDescription2.headerConfig.wallet",
            modules: [MODULES.CARDS],
          },
          {
            text: "company.rolesPermissions.rolePermissionsDataText.member.headingDescription2.headerConfig.claimRequest",
            modules: [MODULES.REIMBURSEMENTS],
          },
        ],
        description:
          "company.rolesPermissions.rolePermissionsDataText.member.headingDescription2.description",
        descriptionConfig: [
          {
            text: "company.rolesPermissions.rolePermissionsDataText.member.headingDescription2.descriptionConfig.card",
            modules: [MODULES.CARDS],
          },
          {
            text: "company.rolesPermissions.rolePermissionsDataText.member.headingDescription2.descriptionConfig.wallet",
            modules: [MODULES.CARDS],
          },
          {
            text: "company.rolesPermissions.rolePermissionsDataText.member.headingDescription2.descriptionConfig.claimRequest",
            modules: [MODULES.REIMBURSEMENTS],
          },
        ],
        modules: [MODULES.CARDS, MODULES.REIMBURSEMENTS],
        icon: "CircleCheck",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.member.headingDescription3.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.member.headingDescription3.description",
        icon: "DashboardOther",
        descriptionConfig: [
          {
            text: "company.rolesPermissions.rolePermissionsDataText.member.headingDescription3.descriptionConfig.text",
            modules: [MODULES.CARDS],
          },
        ],
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.member.headingDescription4.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.member.headingDescription4.description",
        icon: "Receipt",
        modules: [MODULES.CARDS],
      },
    ],
  },
  [ROLE_TITLE.CARD_MANAGER]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.cardManager.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.cardManager.headingDescription1.description",
        icon: "Card",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.cardManager.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.cardManager.headingDescription2.description",
        icon: "CardChecked",
      },
    ],
  },
  [ROLE_TITLE.EXTERNAL_ACCOUNTANT]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.externalAccountant.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.externalAccountant.headingDescription1.description",
        icon: "Accounting",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.externalAccountant.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.externalAccountant.headingDescription2.description",
        icon: "AccountingTree",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.externalAccountant.headingDescription3.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.externalAccountant.headingDescription3.description",
        icon: "Settings",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.externalAccountant.headingDescription4.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.externalAccountant.headingDescription4.description",
        icon: "LightningBolt",
      },
    ],
  },
  [ROLE_TITLE.AP_CLERK]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.apClerk.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.apClerk.headingDescription1.description",
        icon: "InvoicePayment",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.apClerk.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.apClerk.headingDescription2.description",
        icon: "Cancel",
      },
    ],
  },
  [ROLE_TITLE.PAYMENT_INITIATOR]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.paymentInitiator.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.paymentInitiator.headingDescription1.description",
        icon: "InvoicePayment",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.paymentInitiator.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.paymentInitiator.headingDescription2.description",
        icon: "Send",
      },
    ],
  },
  [ROLE_TITLE.SETTLEMENT_INITIATOR]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.settlementInitiator.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.settlementInitiator.headingDescription1.description",
        icon: "InvoicePayment",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.settlementInitiator.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.settlementInitiator.headingDescription2.description",
        icon: "Send",
      },
    ],
  },
  [ROLE_TITLE.DEPARTMENT_MANAGER]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription1.description",
        icon: "SettingsAccount",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription2.description",
        icon: "Groups",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription3.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription3.description",
        modules: [MODULES.CARDS],
        icon: "Card",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription4.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription4.description",
        headerConfig: [
          {
            text: "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription4.headingConfig.expenses",
            modules: [MODULES.CARDS],
          },
          {
            text: "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription4.headingConfig.claimReimbursement",
            modules: [MODULES.REIMBURSEMENTS],
          },
        ],
        descriptionConfig: [
          {
            text: "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription4.descriptionConfig.expenses",
            modules: [MODULES.CARDS],
          },
          {
            text: "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription4.descriptionConfig.reimbursement",
            modules: [MODULES.REIMBURSEMENTS],
          },
        ],
        modules: [MODULES.REIMBURSEMENTS, MODULES.CARDS],
        icon: "CircleCheck",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription5.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.departmentManager.headingDescription5.description",
        modules: [MODULES.REIMBURSEMENTS, MODULES.BILL_PAY],
        icon: "Payments",
      },
    ],
  },
  [ROLE_TITLE.PROJECT_MANAGER]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription1.description",
        icon: "SettingsAccount",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription2.description",
        icon: "Groups",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription3.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription3.description",
        modules: [MODULES.CARDS],
        icon: "Card",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription4.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription4.description",
        icon: "CircleCheck",
        headerConfig: [
          {
            text: "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription4.headingConfig.expense",
            modules: [MODULES.CARDS],
          },
          {
            text: "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription4.headingConfig.reimbursement",
            modules: [MODULES.REIMBURSEMENTS],
          },
        ],
        descriptionConfig: [
          {
            text: "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription4.descriptionConfig.expense",
            modules: [MODULES.CARDS],
          },
          {
            text: "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription4.descriptionConfig.reimbursement",
            modules: [MODULES.REIMBURSEMENTS],
          },
        ],
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription5.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.projectManager.headingDescription5.description",
        icon: "Payments",
      },
    ],
  },
  [ROLE_TITLE.CLAIM_MANAGER]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.claimManager.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.claimManager.headingDescription1.description",
        icon: "InvoicePayment",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.claimManager.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.claimManager.headingDescription2.description",
        icon: "Payments",
      },
    ],
  },
  [ROLE_TITLE.QR_PAYMENT_MANAGER]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.paymentManager.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.paymentManager.headingDescription1.description",
        icon: "DashboardCustomise",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.paymentManager.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.paymentManager.headingDescription2.description",
        icon: "Edit",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.paymentManager.headingDescription3.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.paymentManager.headingDescription3.description",
        icon: "Chat",
      },
    ],
  },
  [ROLE_TITLE.QR_WALLET_MANAGER]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.walletManager.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.walletManager.headingDescription1.description",
        icon: "Wallet",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.walletManager.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.walletManager.headingDescription2.description",
        icon: "SettingsAccount",
      },
    ],
  },
  [ROLE_TITLE.EXPENSE_MANAGER]: {
    permissions: [
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.expenseManager.headingDescription1.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.expenseManager.headingDescription1.description",
        icon: "Wallet",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.expenseManager.headingDescription2.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.expenseManager.headingDescription2.description",
        icon: "Edit",
      },
      {
        heading:
          "company.rolesPermissions.rolePermissionsDataText.expenseManager.headingDescription3.heading",
        description:
          "company.rolesPermissions.rolePermissionsDataText.expenseManager.headingDescription3.description",
        icon: "Chat",
      },
    ],
  },
};

export const PERMISSIONS_LABEL_DESCRIPTION_CONFIG = {
  [PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_ACCOUNTS]: {
    name: "Manage accounts",
    description:
      "Allow user to access the dashboard, accounts, and also exchange funds.",
    toolTipText: "Restrict the user to transfer funds.",
  },
  [PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_PEOPLE_LOCATIONS]: {
    name: "Manage people and location",
    description:
      "Allow the user to invite people, edit user details and locations",
    toolTipText:
      "Restrict the user to invite users and edit the user details/locations.",
  },
  [PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_DEPARTMENT]: {
    name: "Manage departments",
    description:
      "Allow user the access to create and edit department details, add managers as well as define budgets and customise policies if custom policy is enabled.",
    toolTipText:
      "Restrict the user to edit department details as well as define budgets and customize policies.",
  },
  [PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_PROJECT]: {
    name: "Manage projects",
    description:
      "Allow user the access to create and edit project details, add managers as well as define budgets and customise policies if custom policy is enabled.",
    toolTipText:
      "Restrict the user to edit project details as well as define budgets and customize policies.",
  },
  [PERMISSIONS_TYPE_MAP.COMPANY_MANAGE_TRAVEL]: {
    name: "Manage travel",
    description: "Allow the user to access travel and manage users",
  },

  [PERMISSIONS_TYPE_MAP.CARD_MANAGE]: {
    name: "Manage cards",
    description: (clientInfo) => {
      const slug = clientInfo?.slug;
      if (slug === CARD_PROVIDER.UOB) {
        return "Allow user to access all cards present in the company.";
      }
      return "Allow user to access and create cards for users in the company.";
    },
    toolTipText: "Restrict the user to create cards.",
  },
  [PERMISSIONS_TYPE_MAP.EXPENSE_MANAGE]: {
    name: "Manage expenses",
    description:
      "Allow the user to access all company expenses, including expenses that are flagged, declined or needs review",
    toolTipText:
      "Allowing edit access permits the user to initiate payments for the approved bills",
  },
  [PERMISSIONS_TYPE_MAP.CARD_SETTINGS_MANAGE]: {
    name: "Manage card settings",
    description:
      "Allow the user to access card settings, permitting them to make modifications to the overall card configuration as well as manage spend controls for cards and expenses",
  },
  [PERMISSIONS_TYPE_MAP.BILLPAY_MANAGE]: {
    name: "Manage bills",
    description:
      "Allow user to create bills, create vendors and skip approvals for the bills created",
    toolTipText: "Restrict the user to create bills and skip approvals.",
  },
  [PERMISSIONS_TYPE_MAP.BILLPAY_AUTHORIZE_PAYMENT]: {
    name: "Manage payments",
    description:
      "Allows the user to initiate payments for bills, access all bills, view vendors, and keep track of the payment statuses for all bills",
    toolTipText:
      "Allowing edit access permits the user to authorize payments for the approved bills",
  },
  [PERMISSIONS_TYPE_MAP.BILLPAY_SETTINGS_MANAGE]: {
    name: "Manage bill pay settings",
    description:
      "Allow the user to access bill pay settings, permitting them to make modifications to the overall general configurations as well as manage spend controls for approval and submission policy",
  },
  [PERMISSIONS_TYPE_MAP.BILLPAY_MANAGE_SELF_CREATION]: {
    name: "Manage self created bills and payments",
    description:
      "Restrict the user to view, edit and pay only self created bills",
  },
  [PERMISSIONS_TYPE_MAP.QRPAY_MANAGE]: {
    name: "Manage expenses",
    description:
      "Allow the user to access all company QR payments, including payments that are flagged, fails or needs review",
  },
  [PERMISSIONS_TYPE_MAP.QR_WALLET_MANAGE]: {
    name: "Manage QR wallet",
    description:
      "Allow the user to access user wallets, allowing them to view wallets associated with the users in the company",
  },
  [PERMISSIONS_TYPE_MAP.QRPAY_SETTINGS_MANAGE]: {
    name: "Manage QR pay settings",
    description:
      "Allow the user to access user wallet settings, permitting them to make modifications to the overall wallet configuration as well as manage spend controls for wallets and QR payments",
  },
  [PERMISSIONS_TYPE_MAP.REIMBURSEMENT_MANAGE]: {
    name: "Manage claims",
    description:
      "Allow the user to access and review all claims submitted by other users, including the ability to check the status of these claims. However the user does not have the authority to initiate any payments for these claims",
    toolTipText: "",
  },
  [PERMISSIONS_TYPE_MAP.REIMBURSEMENT_SETTLE]: {
    name: "Manage claim payments",
    description:
      "Allow the user access to initiate payments for the approved claims from the payments tab.",
  },
  [PERMISSIONS_TYPE_MAP.REIMBURSEMENT_SETTINGS_MANAGE]: {
    name: "Manage reimbursement settings",
    description:
      "Allow the user to access reimbursement settings, permitting them to make modifications to the general configuration as well as manage spend controls for approval, submission and claim policy",
  },

  [PERMISSIONS_TYPE_MAP.ACCOUNTING_MANAGE]: {
    name: "Manage accounting",
    description:
      "Allow the user to access accounting transactions, integrations, ledgers, and accounting settings, enabling them to establish rules and custom fields as well",
  },

  [PERMISSIONS_TYPE_MAP.SETTINGS_MANAGE]: {
    name: "Manage company settings",
    description:
      "Allow the user to access the company settings, allowing them to make modifications to company’s spend controls and manage alerts.",
  },
};

export const LOCATION_CONFIG = {
  edit: {
    label: "Edit Details",
    btnLabel: "Save Changes",
  },
  create: {
    label: "Add new location(s)",
    description: "Add a new location to this department.",
    btnLabel: "Add location(s)",
  },
};

export const CARD_REQUEST_TYPES = {
  PHYSICAL_CARD_REQUEST: "PhysicalCardRequest",
  VIRTUAL_CARD_REQUEST: "VirtualCardRequest",
  TOP_UP_REQUEST: "TopUpRequest",
};

export const MODULE_REQUEST_TYPES = {
  CARD_REQUEST: "CardRequest",
  BILL_PAY_REQUEST: "PurchaseBill",
  REIMBURSEMENT_REQUEST: "Reimbursement",
  PAYROLL_REQUEST: "Payroll",
  ALL: "All",
};

export const BE_FE_TRANSACTION_TYPE_KEY = {
  QRPay: "QR_PAY",
  BillPayment: "BILL_PAY",
  Reimbursement: "REIMBURSEMENT",
  Expense: "CARD",
};

export const TRANSACTION_TYPE_LABELS = {
  [BE_FE_TRANSACTION_TYPE_KEY.QRPay]: "qrPay.title",
  [BE_FE_TRANSACTION_TYPE_KEY.BillPayment]: "breadcrumbs.billPay",
  [BE_FE_TRANSACTION_TYPE_KEY.Reimbursement]:
    "accounting.transactions.reimbursement.title",
  [BE_FE_TRANSACTION_TYPE_KEY.Expense]: "cards.title.singular",
};

export const BE_FE_REQUEST_TYPE_CARD_REQUEST = {
  PhysicalCardRequest: "physicalCardRequest",
  VirtualCardRequest: "virtualCardRequest",
  TopUpRequest: "topUpRequest",
};

export const MOBILE_APP_URL = {
  android:
    "https://play.google.com/store/apps/details?id=com.volopay.com&hl=en_AU&pli=1",
  ios: "https://apps.apple.com/us/app/volopay/id1638686451",
};

export const USER_BLOCK_FREEZE_POLICY_TYPES = {
  PHYSICAL_CARD: "physicalCardOrder",
  EXPENSE_REVIEW: "expenseReview",
  PAYROLL: "payroll",
  COMPANY: "company",
  CUSTOM: "custom",
};

export const USER_BLOCK_FREEZE_POLICY_KEYS = {
  GENERIC: "generic",
  COMPANY: "company",
  CUSTOM: "custom",
};

export const USER_BLOCK_FREEZE_APPROVAL_POLICIES_REQUIREMENTS_CONFIG = {
  [USER_BLOCK_FREEZE_POLICY_KEYS.GENERIC]: {
    [USER_BLOCK_FREEZE_POLICY_TYPES.PHYSICAL_CARD]: {
      searchParam: SLIDERS_SEARCH_PARAMS.settings.policyModule,
      searchValue: "physicalCard",
    },
    [USER_BLOCK_FREEZE_POLICY_TYPES.EXPENSE_REVIEW]: {
      searchParam: SLIDERS_SEARCH_PARAMS.settings.policyModule,
      searchValue: "expenseReview",
    },
    [USER_BLOCK_FREEZE_POLICY_TYPES.PAYROLL]: {
      searchParam: SLIDERS_SEARCH_PARAMS.settings.policyModule,
      searchValue: "payroll",
    },
  },
  [USER_BLOCK_FREEZE_POLICY_TYPES.COMPANY]: {
    searchParam: SLIDERS_SEARCH_PARAMS.settings.approvalPolicy,
    searchValue: "company",
  },
  [USER_BLOCK_FREEZE_POLICY_TYPES.CUSTOM]: {
    searchParam: SLIDERS_SEARCH_PARAMS.settings.customPolicy,
    searchValue: "approval",
  },
};
export const PENDING_COUNT_TRANSACTIONS = {
  CARDS: "expense",
  PURCHASE_BILL: "purchase_bill",
  REIMBURSEMENT: "claim",
  OTHERS: "accounting_transaction",
  PAYROLL: "payroll",
};
export const TRANSACTION_TYPE = {
  EXPENSE: "Expense",
  CARDS: "Card",
  PURCHASE_BILL: "PurchaseBill",
  PAYROLL: "Payroll",
  REIMBURSEMENT: "Reimbursement",
  PAYMENT: "Payment",
  LEDGER: "Ledger",
  ACCOUNTING_TRANSACTION: "AccountingTransaction",
  VENDOR: "Vendor",
};

export const PROJECT_API_FILTERS = {
  PROJECT_MANAGER: "project_manager",
  PROJECT_MEMBER: "project_member",
};

export const PENDING_REQUEST_TABS_KEYS = {
  CARDS: "CardRequest",
  BILL_PAY: "PurchaseBill",
  REIMBURSEMENT: "Reimbursement",
  REPORT: "Report",
  PAYROLL: "Payroll",
  ALL: "all",
};

export const ALLOCATE_FUNDS_ACCOUNT_TYPE_KEY = "sendingToAccountKey";

export const VOLOPAY_ASSISTANT_TOGGLE = {
  title: "company.rolesPermissions.assistant.accountSupportSwitch.title",
  description:
    "company.rolesPermissions.assistant.accountSupportSwitch.description",
  setting: "volopay_assistant_enabled",
};
