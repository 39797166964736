import PropTypes from "prop-types";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import DisableConfirmation from "@/components/Settings/Modals/DisableConfirmation";
import EnableConfirmation from "@/components/Settings/Modals/EnableConfirmation";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function PolicyItem({
  id,
  type,
  title,
  description,
  showSwitch,
  switchKey,
  switchValue,
  showDisableModal,
  showEnableModal,
  setSwitchValue,
  disableModalDesc,
  disableModalSubtitle,
  disableModalTitle,
  disableModalHeader,
  disableModalInputLabelText,
  disableModalInputNote,
  disableModalSubmitBtnType,
  disableModalSubmitBtnLabel,
  enableModalTitle,
  enableModalSubtitle,
  enableModalDescription,
  onSubmit,
  showPolicyItem = true,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [switchVal, setSwitchVal] = useState(switchValue);
  const [showDisableModalOn, setShowDisableModalOn] = useState(false);
  const [showEnableModalOn, setShowEnableModalOn] = useState(false);
  const handleClick = () => {
    if (switchVal || !showSwitch) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.settings[id], type);
      setSearchParams(searchParams);
    }
  };
  return showPolicyItem ? (
    <>
      <div
        className={`flex items-center justify-between ${
          switchVal || !showSwitch ? "cursor-pointer" : null
        } `}
        onClick={handleClick}
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            {showSwitch ? (
              <Switch
                value={switchVal}
                handleChange={(val, event) => {
                  event?.stopPropagation();
                  if (showDisableModal || showEnableModal) {
                    if (val) {
                      setShowEnableModalOn(true);
                    } else setShowDisableModalOn(true);
                  } else {
                    setSwitchVal((prev) => !prev);
                  }
                }}
              />
            ) : null}
            <Text classes="font-medium" translationKey={title} />
          </div>
          <Text
            classes="text-sm text-neutral-500 w-[90%]"
            translationKey={description}
          />
        </div>
        <div>
          <Icon name="RightArrow" />
        </div>
      </div>
      {showDisableModal ? (
        <DisableConfirmation
          header={disableModalHeader}
          title={disableModalTitle}
          subtitle={disableModalSubtitle}
          desc={disableModalDesc}
          labelText={disableModalInputLabelText}
          inputNote={disableModalInputNote}
          submitBtnLabel={disableModalSubmitBtnLabel}
          submitBtnType={disableModalSubmitBtnType}
          showModal={showDisableModalOn}
          setShowModal={setShowDisableModalOn}
          onCancelClick={() => {
            setSwitchVal(true);
          }}
          onSubmitClick={() => {
            setShowDisableModalOn(false);
            setSwitchVal(false);
            onSubmit(switchKey, false);
          }}
        />
      ) : null}
      {showEnableModal ? (
        <EnableConfirmation
          title={enableModalTitle}
          subtitle={enableModalSubtitle}
          showModal={showEnableModalOn}
          description={enableModalDescription}
          setShowModal={setShowEnableModalOn}
          onCancelClick={() => {
            setSwitchVal(false);
          }}
          onSubmitClick={() => {
            setShowEnableModalOn(false);
            setSwitchVal(true);
            onSubmit(switchKey, true);
          }}
        />
      ) : null}
    </>
  ) : null;
}

PolicyItem.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  enableModalDescription: PropTypes.string,
  disableModalHeader: PropTypes.string,
  disableModalInputLabelText: PropTypes.string,
  disableModalInputNote: PropTypes.string,
  disableModalSubmitBtnType: PropTypes.string,
  disableModalSubmitBtnLabel: PropTypes.string,
  description: PropTypes.string,
  showSwitch: PropTypes.bool,
  switchValue: PropTypes.bool,
  setSwitchValue: PropTypes.func,
  onSubmit: PropTypes.func,
  switchKey: PropTypes.string,
  showPolicyItem: PropTypes.bool,
};
