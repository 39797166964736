import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

import { CARD_FREQUENCY, CARD_FREQUENCY_BE_MAPPING } from "@/constants/card";
import { BUDGET_PILL_CONFIG, REVIEW_LABELS } from "@/constants/common";
import {
  BE_FE_FREQUENCY_MAP,
  BUDGET_FREQUENCY_LABELS,
} from "@/constants/company";

function ReviewFrequency({
  oldValue,
  newValue,
  newContainerClasses,
  oldContainerClasses,
}) {
  return (
    <div className="flex items-center gap-2">
      <div
        className={`text-xs font-semibold text-neutral-400 ${oldContainerClasses}`}
      >
        <div>
          <Text translationKey={REVIEW_LABELS.FREQUENCY.OLD} />
        </div>
        <div className="text-lg capitalize text-neutral-500">
          <Text
            translationKey={
              BUDGET_FREQUENCY_LABELS[BE_FE_FREQUENCY_MAP[oldValue]]
            }
          />
        </div>
      </div>
      <div
        className={`flex flex-col items-end text-xs font-semibold text-neutral-400 ${newContainerClasses}`}
      >
        <div>
          <Text translationKey={REVIEW_LABELS.FREQUENCY.NEW} />
        </div>
        <div className="text-lg capitalize text-neutral-800">
          <Text
            translationKey={
              BUDGET_FREQUENCY_LABELS[BE_FE_FREQUENCY_MAP[newValue]]
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ReviewFrequency;

ReviewFrequency.propTypes = {
  oldValue: PropTypes.string,
  newValue: PropTypes.string,
  newContainerClasses: PropTypes.string,
  oldContainerClasses: PropTypes.string,
};
