import PropTypes from "prop-types";
import { Slide, toast } from "react-toastify";

import Toast from "@/components/core/Toast/Toast";

const vToast = ({
  title,
  titleTranslationProp,
  description,
  descriptionTranslationProp,
  variant, // "success" | "danger" | "warning"
  timeout = 3000,
  link,
  outerSectionClasses,
  noToast = false,
}) => {
  const config = {
    transition: Slide,
    hideProgressBar: true,
    icon: false,
    closeButton: false,
    autoClose: timeout,
    closeOnClick: true,
  };

  if (noToast) {
    console.warn("VP: toast not shown"); // silent behavior in UI, like job flush API on bulk payroll cancel
    return;
  }

  toast(
    <Toast
      title={title}
      description={description}
      titleTranslationProp={titleTranslationProp}
      descriptionTranslationProp={descriptionTranslationProp}
      variant={variant}
      link={link}
      outerSectionClasses={outerSectionClasses}
    />,
    config
  );
};

vToast.propTypes = {
  title: PropTypes.string,
  titleTranslationProp: PropTypes.object,
  description: PropTypes.string,
  descriptionTranslationProp: PropTypes.object,
  variant: PropTypes.string,
  timeout: PropTypes.number,
  link: PropTypes.string,
  outerSectionClasses: PropTypes.string,
  noToast: PropTypes.bool,
};

export default vToast;
