import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import BudgetsTabEmptyState from "@/components/Company/Slider/LocationDepartmentDetails/BudgetsTab/BudgetsTabEmptyState";
import BudgetRow, {
  BudgetRowExample,
} from "@/components/Company/common/BudgetRow";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_BUDGET_MODULES,
  DEFINE_NEW_BUDGET_BE_FLAG,
  DEPARTMENT,
  PROJECT,
  PROJECT_TYPE_TITLE,
} from "@/constants/company";
import { BE_CTA_RESPONSE } from "@/constants/user";

/**
 * Budgets tab of a department (or +3 variations) slider
 *
 * @param {String} sliderData /${id} API data of parent slider
 * @param {String} parentSliderType current slider env (Tab is rendering as Department/Project/BudgetProject)
 */
export default function BudgetsTab({ sliderData = null, parentSliderType }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { budgets = [] } = sliderData ?? []; // rows of this tab

  // all modules were used? Example: [['cards', 'reimbursement'], ['billpay']]
  const isAllModulesUsed =
    budgets?.reduce(
      (accum, budgetRowData) => [...accum, ...budgetRowData.modules],
      []
    ).length === Object.entries(COMPANY_BUDGET_MODULES).length;

  const defineNewBudgetBEFlagPresent = sliderData?.ctas?.includes(
    DEFINE_NEW_BUDGET_BE_FLAG
  );
  const defineNewBudgetDisabled =
    isAllModulesUsed || !defineNewBudgetBEFlagPresent;

  const showDefineNewBudgetCta = sliderData?.ctas?.some(
    (cta) => cta === BE_CTA_RESPONSE.DEFINE_NEW_BUDGET
  );

  const openNewBudgetHandler = () => {
    switch (parentSliderType) {
      case DEPARTMENT:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.company.budget.createForm.newDeptBudget,
          sliderData?.id
        );
        break;
      case PROJECT:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.company.budget.createForm.newProjBudget,
          sliderData?.id
        );
        break;
      default:
        break;
    }
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.budget.storeMode, false);
    setSearchParams(searchParams);
  };

  // empty state
  if (budgets.length === 0) {
    return (
      <BudgetsTabEmptyState
        title="company.department.departmentDetails.tabs.budgets.budgetEmptyTitle"
        desc={
          parentSliderType === DEPARTMENT
            ? "company.department.departmentDetails.tabs.budgets.budgetEmptyDescription"
            : "company.department.departmentDetails.tabs.budgets.budgetProjectEmptyDescription"
        }
        handleClick={openNewBudgetHandler}
        hideButton={!showDefineNewBudgetCta}
      />
    );
  }

  // non-empty state
  return (
    <div className="text-neutral-800 min-h-[570px]">
      <div className="flex items-center justify-between">
        <Text
          translationKey="company.department.departmentDetails.tabs.budgets.title"
          classes="text-lg font-bold"
        />
        {showDefineNewBudgetCta ? (
          <Button
            preIcon="Plus"
            label="company.department.departmentDetails.tabs.budgets.defineNewBudgetLabel"
            variant="tertiary"
            iconClasses="text-xs p-0.5"
            labelStyleClasses="text-xs font-semibold"
            classes="w-[166px] h-[32px]"
            disabled={defineNewBudgetDisabled}
            onClick={openNewBudgetHandler}
            id="define-new-budget-tooltip"
          />
        ) : null}

        {isAllModulesUsed ? (
          <Tooltip id="define-new-budget-tooltip" direction="top">
            <div className="max-w-[330px]">
              <Text
                translationKey="company.department.departmentDetails.tabs.budgets.isAllModulesUsed"
                classes="font-medium text-sm text-neutral-800"
              />
            </div>
          </Tooltip>
        ) : null}
      </div>
      <div className="flex flex-col gap-6 mt-8">
        {budgets.map((budgetRowData, index) => (
          <BudgetRow
            key={budgetRowData?.id}
            data={budgetRowData}
            available={budgetRowData?.available}
            currency={budgetRowData?.currency}
            overSpend={budgetRowData?.overSpend}
            isFlexible={budgetRowData?.flexible}
            type={parentSliderType}
          />
          // <BudgetRowExample key={index} />
        ))}
      </div>
    </div>
  );
}

BudgetsTab.propTypes = {
  sliderData: PropTypes.object,
  parentSliderType: PropTypes.string, // "department" | "project" | "budget" | "location"
};
