import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { deleteAttachment, downloadAttachment } from "@/store/reducers/app";
import { addComments, fetchComments } from "@/store/reducers/comments";
import {
  fetchAndSelectReimbursement,
  setCreatingduplicateReimbursement,
  setSelectedReimbursement,
  updateClaim,
  updateSelectedReimbursement,
} from "@/store/reducers/reimbursement";

import {
  claimAmountEditEnabledSelector,
  defaultCurrencySelector,
} from "@/store/selectors/client";
import {
  commentsHasMoreSelector,
  commentsListSelector,
} from "@/store/selectors/comments";
import {
  creatingduplicateReimbursementSelector,
  mileageRateListSelector,
  setClaimCategoryLimitDetailSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Badge from "@/components/core/Badge";
import Comments from "@/components/core/Comments";
import FileUpload from "@/components/core/FileUpload";
import Icon from "@/components/core/Icon";
import Memo from "@/components/core/Memo";
import Text from "@/components/core/Text";
import Timeline from "@/components/core/Timeline";

import SliderAccountingSection from "@/components/Accounting/Transactions/common/Slider/SliderAccountingSection";
import AmountToBePaid from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/AmountToBePaid";
import TransactionDetails from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/TransactionDetails";
import { TYPES_TIMELINE } from "@/utils/constants/timeline";
import {
  API_END_PAGE_TYPE,
  REIMBURSEMENT_STATUS,
  REIMBURSEMENT_TYPE_KEY,
  TYPE_REIMBURSEMENT,
} from "@/utils/constants/reimbursement";
import { COMMENTS_PAGINATION_LIMIT_PER_REQUEST } from "@/utils/constants/payments";
import { COMMENT_TYPE, COMMENT_TYPE_PAYLOAD } from "@/utils/constants/comments";
import {
  amountToCurrency,
  createFileFormData,
  dateToString,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";
import { FLEXIBLE } from "@/constants/reimbursement";

import ApprovalMerchantRequestDetails from "./ApprovalMerchantRequestDetails";
import ApprovalMileageRequestDetails from "./ApprovalMileageRequestDetails";
import ReimbursementApprovalSliderInfo from "./ReimbursementSliderInfo";

function ApprovalOverviewTab({ data }) {
  const dispatch = useDispatch();

  const isThisReimbursementIsDuplicate = useSelector(
    creatingduplicateReimbursementSelector
  );

  const claimCategoryLimitDetails = useSelector(
    setClaimCategoryLimitDetailSelector
  );

  const defaultCurrency = useSelector(defaultCurrencySelector);

  const mileageRateList = useSelector(mileageRateListSelector);

  const commentsList = useSelector(commentsListSelector);

  const commentHasMore = useSelector(commentsHasMoreSelector);

  const currentUser = useSelector(userSelector);

  const [commentPage, setCommentPageNum] = useState(1);

  const fetchCommentsFunc = (page) => {
    dispatch(
      fetchComments({
        id: data?.id,
        type: COMMENT_TYPE_PAYLOAD.REIMBURSEMENT,
        comment_type: [
          COMMENT_TYPE.USER_CREATED,
          COMMENT_TYPE.CLAIM_AMOUNT_CHANGED,
        ],
        page,
        limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
      })
    );
  };

  const createNewComment = (formData) => {
    formData.append("owner_type", COMMENT_TYPE_PAYLOAD.REIMBURSEMENT);
    formData.append("owner_id", data?.id);

    dispatch(
      addComments({
        payload: formData,
        onSuccess: () => {
          setCommentPageNum(1);
          fetchCommentsFunc(1);
        },
      })
    );
  };

  const loadMoreComments = () => {
    setCommentPageNum((prev) => prev + 1);
    fetchCommentsFunc(commentPage + 1);
  };

  useEffect(() => {
    if (data?.id) fetchCommentsFunc(1);
  }, [data]);

  const mileage = mileageRateList?.find(
    (singleMileage) => singleMileage.countryName === data?.travelCountry
  );
  const currency = mileage?.currency;

  const mileageRate = mileage?.rate;

  const [sliderId, setSliderId] = useState(null);

  const [files, setFiles] = useState([]);

  const [pageType, setPageType] = useState();

  const [accountingTag, setAccountingTag] = useState(
    data?.accountingTags ?? []
  );

  const [customTag, setCustomTag] = useState(data?.customTags ?? []);

  const accountingTagOptions = accountingTag;
  const customTagOptions = customTag;

  const [searchParams, setSearchParams] = useSearchParams();
  const [accountingState, setAccountingState] = useState();

  useEffect(() => {
    setFiles(data?.receipts ?? []);
  }, [data]);

  const accountingForm = {
    accountingTag: {
      value: accountingTag,
      options: accountingTagOptions,
      handleChange: setAccountingTag,
    },
    customTag: {
      value: customTag,
      options: customTagOptions,
      handleChange: setCustomTag,
    },
    accountingState,
    setAccountingState,
  };

  const fileUploadHandler = (latestFileArray) => {
    const filesToUpload = latestFileArray?.filter((item) => {
      return item instanceof File && item.type !== "image/svg+xml";
    });

    if (filesToUpload?.length === 0) return;

    const formPayload = createFileFormData(filesToUpload, "receipts[]");

    dispatch(updateClaim({ id: sliderId, payload: formPayload }));
  };

  const getCommentsComponent = () => {
    return (
      <div className="mt-9">
        <Text
          translationKey="comments.commentHeading"
          classes="font-semibold text-lg"
        />
        <Comments
          comments={commentsList}
          loadMoreLabel="comments.showOlderComments"
          createNewComment={(val) => {
            createNewComment(val);
          }}
          currentUser={currentUser}
          showLoadMoreCta={commentHasMore}
          loadMoreComments={loadMoreComments}
        />
      </div>
    );
  };

  const hideCategoryAlert = [
    REIMBURSEMENT_STATUS.approved,
    REIMBURSEMENT_STATUS.failed,
    REIMBURSEMENT_STATUS.processing,
    REIMBURSEMENT_STATUS.paid_outside_volopay,
    REIMBURSEMENT_STATUS.paid,
    REIMBURSEMENT_STATUS.settled_via_card,
  ]?.includes(data?.status);

  const primaryAction = {
    handler: (index) => {
      if (files[index]?.id) {
        dispatch(deleteAttachment({ id: files[index]?.id, onSuccess }));
      }
    },
    label: "misc.delete",
    icon: "Delete",
    iconClasses: "text-danger-600 bg-danger-50",
  };
  const secondaryAction = {
    handler: (index) => {
      const file = files[index];
      if (file instanceof File) return;
      dispatch(
        downloadAttachment({
          file,
          id: file?.id,
        })
      );
    },

    label: "misc.download",
    icon: "Download",
    iconClasses: "!text-primary-500 !bg-primary-50", // some issue with SVG, using !
  };

  const onSuccess = () => {
    if (sliderId || pageType) {
      dispatch(
        fetchAndSelectReimbursement({
          reimId: parseInt(sliderId, 10),
          type: API_END_PAGE_TYPE[pageType],
        })
      );
    }
  };

  useEffect(() => {
    setSliderId(
      searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.approveId) ||
        searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.activeClaimId) ||
        searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.historyClaimId) ||
        searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.claimId)
    );
    setPageType(
      searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.pageType)
    );
  }, []);

  const claimAmountEditEnabled = useSelector(claimAmountEditEnabledSelector); // flag
  const isAmountEdited =
    +(data?.amount?.value ?? 0) !== +(data?.amountToBePaid ?? 0);
  const isShowAmountToBePaid = claimAmountEditEnabled;
  const approverEditUI = (
    <>
      <AmountToBePaid
        amount={data?.amountToBePaid}
        currency={defaultCurrency}
        titleText="reimbursement.approverEdit.amountToBePaid"
        blueBorder={false}
        rightOfTooltipUI={
          isAmountEdited ? (
            <Badge
              translationKey="reimbursement.approverEdit.amountEditedChipLabel"
              variant="neutral"
              muted={false}
              classes="py-0.5 px-3 bg-neutral-50"
              textClasses="font-bold text-xs text-neutral-600"
              id="amount-edited-tooltip"
            />
          ) : null
        }
      />
      {/* exchange rate guideline text */}
      {isAmountEdited ? (
        <Text
          translationKey="reimbursement.approverEdit.originalAmountToBePaid"
          translationProps={{
            amountAndCurrency: amountToCurrency(data?.amount),
          }}
          classes="text-sm font-medium text-neutral-800"
        />
      ) : null}
    </>
  );

  return (
    <div>
      {isThisReimbursementIsDuplicate?.duplicate ? (
        <section
          className={isThisReimbursementIsDuplicate?.duplicate ? "pt-9" : ""}
        >
          <Alert
            variant="warning"
            title="reimbursement.createReimbursement.duplicateClaimTitle"
            description="reimbursement.createReimbursement.duplicateClaimDesc"
            primaryAction={{
              label: "reimbursement.createReimbursement.prevClaimLabel",
              callback: () => {
                dispatch(setCreatingduplicateReimbursement(null));
                dispatch(setSelectedReimbursement(null));
                const duplicateData = isThisReimbursementIsDuplicate?.data?.[0];
                if (duplicateData?.id) {
                  searchParams.append(
                    SLIDERS_SEARCH_PARAMS.reimbursements.approveId,
                    duplicateData?.id
                  );
                  setSearchParams(searchParams);
                }
              },
            }}
          />
        </section>
      ) : null}
      {claimCategoryLimitDetails?.categoryLimitExceeded &&
      !hideCategoryAlert ? (
        <section
          className={
            isThisReimbursementIsDuplicate?.duplicate ||
            claimCategoryLimitDetails?.categoryLimitExceeded
              ? "pt-9"
              : ""
          }
        >
          <Alert
            variant="warning"
            title={
              claimCategoryLimitDetails?.type === FLEXIBLE
                ? "reimbursement.createReimbursement.flexibleClaim"
                : "reimbursement.createReimbursement.nonFlexibleClaim"
            }
            description={
              claimCategoryLimitDetails?.type === FLEXIBLE
                ? "reimbursement.createReimbursement.flexibleLimitExceeded"
                : "reimbursement.createReimbursement.nonFlexibleLimitExceeded"
            }
            descriptionTransalationProp={{
              amount: amountToCurrency({
                value: claimCategoryLimitDetails?.categoryLimit,
                currency: defaultCurrency,
              }),
            }}
          />
        </section>
      ) : null}
      <section className="py-6">
        <Timeline
          status={
            data?.transactionStatus ? data?.transactionStatus : data?.status
          }
          currentApprovers={
            data?.currentApprovers ? data?.currentApprovers : []
          }
          failureReason={data?.failureReasonDescription}
          rejectedReason={data?.rejectedReason}
          currentApproverLevel={data?.currentApprovalLevel}
          totalApprovers={data?.approvalLevels}
          type={TYPES_TIMELINE.CLAIM}
          id={data?.id}
        />
      </section>
      <ReimbursementApprovalSliderInfo
        createdBy={data?.createdBy}
        linkedTo={data?.linkedTo}
        type={TYPE_REIMBURSEMENT[data?.type]}
        linkedToClasses="py-6"
      />
      <section className="py-9">
        <Text
          classes="text-lg font-semibold"
          translationKey="reimbursement.approvals.sliderHeadings.requestDetails"
        />

        {REIMBURSEMENT_TYPE_KEY[data?.type] ===
        REIMBURSEMENT_TYPE_KEY.out_of_pocket ? (
          <ApprovalMerchantRequestDetails
            merchant={data?.merchant}
            amount={data?.claimAmount}
            category={data?.category}
            transactionDate={data?.transactionDate}
          />
        ) : (
          <ApprovalMileageRequestDetails
            startLocation={data?.startLocation}
            endLocation={data?.endLocation}
            kms={data?.distanceTravelled}
            mileageRate={mileageRate}
            totalAmount={data?.claimAmount}
            currency={currency}
            travelCountry={data?.travelCountry}
            travelDate={dateToString(data?.travelDate)}
          />
        )}
      </section>
      {isShowAmountToBePaid ? (
        <div className="my-6 mt-4">
          {approverEditUI} {/* exchange rate guideline text */}
        </div>
      ) : null}
      <section className="my-6">
        <div className="flex items-center gap-2">
          <Text
            classes="text-lg font-semibold"
            translationKey="reimbursement.approvals.sliderHeadings.receipt"
          />

          {data?.receipts?.length ? (
            <span className="inline-block p-1 rounded-full bg-success-600 text-neutral-50">
              <Icon name="Done" />
            </span>
          ) : null}
        </div>
        <div className="w-full py-6">
          <FileUpload
            files={files}
            isDisabled
            acceptText={
              files.length
                ? undefined
                : "reimbursement.approvals.sliderHeadings.noReceiptText"
            }
            handleFileChange={fileUploadHandler}
            primaryAction={primaryAction}
            hideCustomButtonAfterUpload
            primaryActionDisabled
            secondaryAction={secondaryAction}
          />
        </div>
        <div className="mt-6">
          <Text
            classes="text-lg font-semibold"
            translationKey="reimbursement.approvals.sliderHeadings.memo"
          />
          <div className="mt-4">
            <Memo disabled value={data?.memo} />
          </div>
        </div>
        <SliderAccountingSection
          showSplit={false}
          inputs={accountingForm}
          hideAccoutingIfNoAccountingTag
          transaction={data}
          page={ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT}
          onSuccess={(response) => {
            dispatch(
              updateSelectedReimbursement({
                accountingTags: response.accountingTags,
                customTags: response.customTags,
              })
            );
          }}
          accountingHeading="Misc.accountingDetails"
        />
        {data?.status === REIMBURSEMENT_STATUS.paid ? (
          <div className="my-10">
            <TransactionDetails payment={data} isReimbursement />
          </div>
        ) : null}

        {getCommentsComponent()}
      </section>
    </div>
  );
}

export default ApprovalOverviewTab;

ApprovalOverviewTab.propTypes = {
  data: PropTypes.object,
};
