import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Text from "@/components/core/Text";

import ReimbursementPaymentsList from "@/components/Reimbursement/ReimbursementPaymentsList";
import ReimbursementPaymentsSlider from "@/components/Reimbursement/ReimbursementPaymentsSlider";
import { REIMBURSEMENT_PAGE_TYPE } from "@/utils/constants/reimbursement";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function Paid() {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="px-10">
      <div className="flex flex-col">
        <Text
          classes="text-neutral-500 text-sm"
          translationKey="reimbursement.title"
        />
        <Text
          classes="text-neutral-800 text-4xl font-extrabold"
          translationKey="reimbursement.paid.title"
        />
      </div>
      <ReimbursementPaymentsList
        type={REIMBURSEMENT_PAGE_TYPE.paid}
        onClickHandler={(id) => {
          setSearchParams({
            [SLIDERS_SEARCH_PARAMS.reimbursements.id]: id,
            [SLIDERS_SEARCH_PARAMS.reimbursements.pageType]: "paid",
          });
        }}
      />
    </div>
  );
}
