import { createSelector } from "@reduxjs/toolkit";

import { AVAILABLE_FILTERS } from "@/utils/constants/filters";

export const selectAccountingTransactionsStore = (store) =>
  store.accountingTransactions;

export const accountingTransactionsDataSelector = createSelector(
  selectAccountingTransactionsStore,
  (store) => store.accountingTransactions
);
export const accountingTransactionsSelector = createSelector(
  accountingTransactionsDataSelector,
  (accountingTransactions) => accountingTransactions.list
);

export const accountingTransactionsLimitSelector = createSelector(
  accountingTransactionsDataSelector,
  (accountingTransactions) => accountingTransactions.limit
);
export const accountingTransactionsPageSelector = createSelector(
  accountingTransactionsDataSelector,
  (accountingTransactions) => accountingTransactions.page
);
export const accountingTransactionsTotalSelector = createSelector(
  accountingTransactionsDataSelector,
  (accountingTransactions) => accountingTransactions.total
);
export const accountingTransactionsHasMoreSelector = createSelector(
  accountingTransactionsDataSelector,
  (accountingTransactions) => accountingTransactions.hasMore
);
export const isFetchingTransactionsSelector = createSelector(
  accountingTransactionsDataSelector,
  (accountingTransactions) => accountingTransactions.isFetching
);

export const fetchingAvailableToSyncSelector = createSelector(
  selectAccountingTransactionsStore,
  (data) => data.fetchingAvailableToSync
);
export const availableToSyncSelector = createSelector(
  selectAccountingTransactionsStore,
  (data) => {
    return {
      syncableIds: data.availableToSyncIds,
      syncableCount: data.availableToSync,
    };
  }
);

export const failedToSyncSelector = createSelector(
  selectAccountingTransactionsStore,
  (data) => data.failedToSync
);

export const failedToSyncTransactionsSelector = createSelector(
  selectAccountingTransactionsStore,
  (data) => data.failedToSyncTransactions.list
);

export const isFetchingFailedToSyncTransactionsSelector = createSelector(
  selectAccountingTransactionsStore,
  (data) => data.failedToSyncTransactions.isFetching
);

export const pageFailedToSyncTransactionsSelector = createSelector(
  selectAccountingTransactionsStore,
  (data) => data.failedToSyncTransactions.page
);

export const totalFailedToSyncTransactionsSelector = createSelector(
  selectAccountingTransactionsStore,
  (data) => data.failedToSyncTransactions.total
);

export const hasMoreFailedToSyncTransactionsSelector = createSelector(
  selectAccountingTransactionsStore,
  (data) => data.failedToSyncTransactions.hasMore
);

export const fetchingFailedToSyncSelector = createSelector(
  selectAccountingTransactionsStore,
  (data) => data.fetchingFailedToSync
);

export const accountingCardsFilterSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) =>
    accountingTransactions.filterKeys.cards.map((key) => AVAILABLE_FILTERS[key])
);
export const accountingPaymentsFilterSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) =>
    accountingTransactions.filterKeys.payments.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);
export const accountingReimbursementsFilterSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) =>
    accountingTransactions.filterKeys.reimbursements.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);
export const accountingPayrollFilterSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) =>
    accountingTransactions.filterKeys.payroll.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const accountingQrpayFilterSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) =>
    accountingTransactions.filterKeys.qrpay.map((key) => AVAILABLE_FILTERS[key])
);

export const accountingOthersFilterSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) =>
    accountingTransactions.filterKeys.others.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const selectedAccountingTransactionSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) =>
    accountingTransactions?.selectedAccountingTransaction
);

export const isFetchingSelectedAccountingTransactionSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) =>
    accountingTransactions?.isFetchingSelectedAccountingTransaction
);

export const isLoadingBulkActionSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) => accountingTransactions?.isLoadingBulkAction
);

export const isTransactionStatusLoadingSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) => accountingTransactions?.isTransactionStatusLoading
);
export const reloadSelectedTransactionSelector = createSelector(
  selectAccountingTransactionsStore,
  (accountingTransactions) => accountingTransactions?.reloadSelectedTransaction
);
