import { Route, Routes } from "react-router-dom";

import Payroll from "@/pages/payroll";
import PayrollSalaryPayment from "@/pages/payroll/salary-payment";
import PayrollApprovals from "@/pages/payroll/salary-payment/approvals";
import PayrollPageApprovalAll from "@/pages/payroll/salary-payment/approvals/all";
import PayrollApprovalNeedsYourApproval from "@/pages/payroll/salary-payment/approvals/needs-your-approval";
import PayrollApprovalPending from "@/pages/payroll/salary-payment/approvals/pending";
import PayrollPaid from "@/pages/payroll/salary-payment/paid";
import PayrollPayments from "@/pages/payroll/salary-payment/payments";
import PayrollPaymentsPageAll from "@/pages/payroll/salary-payment/payments/all";
import PayrollPaymentsPageFailed from "@/pages/payroll/salary-payment/payments/failed";
import PayrollInbox from "@/pages/payroll/salary-payment/payroll-inbox";
import { ROUTES } from "@/constants/routes";

export default function PayrollSalaryPayments() {
  return (
    <Routes>
      <Route path="/" element={<Payroll />} />
      <Route
        path={`${ROUTES.payroll.salaryPayment.payrollInbox.pathName}`}
        element={<PayrollInbox />}
      />
      <Route
        path={`${ROUTES.payroll.salaryPayment.approvals.pathName}`}
        element={<PayrollApprovals />}
      >
        <Route
          path={`${ROUTES.payroll.salaryPayment.approvals.all.pathName}`}
          element={<PayrollPageApprovalAll />}
        />
        <Route
          path={`${ROUTES.payroll.salaryPayment.approvals.needsApproval.pathName}`}
          element={<PayrollApprovalNeedsYourApproval />}
        />
        <Route
          path={`${ROUTES.payroll.salaryPayment.approvals.pending.pathName}`}
          element={<PayrollApprovalPending />}
        />
      </Route>
      <Route
        path={`${ROUTES.payroll.salaryPayment.payments.pathName}`}
        element={<PayrollPayments />}
      >
        <Route
          path={`${ROUTES.payroll.salaryPayment.payments.all.pathName}`}
          element={<PayrollPaymentsPageAll />}
        />
        <Route
          path={`${ROUTES.payroll.salaryPayment.payments.failed.pathName}`}
          element={<PayrollPaymentsPageFailed />}
        />
      </Route>

      <Route
        path={`${ROUTES.payroll.salaryPayment.paid.pathName}`}
        element={<PayrollPaid />}
      />
    </Routes>
  );
}
