import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { mergeIntoCreateBillForm } from "@/store/reducers/payments";
import { setPurchaseBillQuote } from "@/store/reducers/purchase-bills";
import {
  fetchAndSelectVendor,
  fetchVendors,
  prependToVendorsList,
  resetVendorsList,
  setSelectedVendor,
} from "@/store/reducers/vendors";

import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";
import {
  hasMoreVendorsSelector,
  isFetchingVendorsSelector,
  vendorsListSelector,
  vendorsPageSelector,
} from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import BoltOnTheLeft from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/BoltOnTheLeft";
import { CREATE_BILL_FLOW_CONTEXT_KEYS } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import { vendorDropdownOption } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/vendorDropdownOption";
import VendorSelectedPreview from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/VendorInfo/VendorSelectedPreview";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { debounce } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import { VENDOR_CLASS } from "@/constants/common";

/**
 *
 * Vendor selection box, shows simple dropdown when nothing selected.
 * If selected, shows recent invoices of vendor, vendor owner contact etc
 *
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=4188-191567&mode=dev}
 */
const VendorInfo = (
  {
    values,
    errors,
    handleChange,
    setValues,
    senderCurrency,
    context,
    vendorDetails,
    disabled,
    classes,
    createBillFlowContext,
    ocrMode,
    billOCRSuccess,
    parentSearchParamKey,
    vendorNameCreateOCR,
    showVendorNameCreateOCR,
    setShowVendorNameCreateOCR,
    saveCompleteForm,
  },
  ref
) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const isFetchingVendors = useSelector(isFetchingVendorsSelector);
  const vendorOptions = useSelector(vendorsListSelector);
  // using both (form and store) since the dropdown replaced (unmounted) by custom UI on selection
  const vendorSelected = values?.vendor;
  const hasMore = useSelector(hasMoreVendorsSelector);
  const vendorPageNumber = useSelector(vendorsPageSelector); // pagination page, not UI page
  const vendorsSet = useRef(new Set());

  // vendor reset (fixed double search entries bug)
  useEffect(() => {
    dispatch(resetVendorsList());
    return () => {
      dispatch(resetVendorsList());
    };
  }, []);

  // Fetch vendors in chunks automatically, until all are fetched
  useEffect(() => {
    if (!hasMore) return;

    dispatch(
      fetchVendors({
        shallow_paginated: true,
        not_archived: true,
        page: vendorPageNumber + 1,
        limit: 500,
        vendor_class: inPayrollContext
          ? VENDOR_CLASS.PAYROLL
          : VENDOR_CLASS.USER_CREATED,
        onSuccess: (data) => {
          // mark fetched items in vendor set
          data.list.forEach((item) => vendorsSet.current.add(item.id));
        },
      })
    );
  }, [hasMore, vendorPageNumber]);

  // search event handler
  // add results to vendor list if not already fetched (i.e. not in set)
  const onInputChange = debounce((text) => {
    if (!text) return;

    dispatch(
      fetchVendors({
        q: text,
        shallow_paginated: true,
        not_archived: true,
        page: 1,
        limit: 500,
        vendor_class: inPayrollContext
          ? VENDOR_CLASS.PAYROLL
          : VENDOR_CLASS.USER_CREATED,
        isSave: false,
        onSuccess: (data) => {
          const dataToAdd = data.list.filter((item) => {
            if (vendorsSet.current.has(item.id)) return false;
            vendorsSet.current.add(item.id);
            return true;
          });

          dispatch(prependToVendorsList(dataToAdd));
        },
      })
    );
  }, 500);

  // select first contact as the default, when vendor has been fetched
  useEffect(() => {
    if (vendorDetails?.contactDetails?.length !== 0) {
      dispatch(
        mergeIntoCreateBillForm({
          vendorContact: vendorDetails?.contactDetails?.[0]?.id,
          vendor: vendorDetails?.id,
        })
      );
    }
  }, [vendorDetails?.contactDetails]);

  const focusRef = useRef(false);

  // button actions
  const clearVendorHandler = () => {
    setValues((prev) => ({
      ...prev,
      vendor: "",
      projectId: "",
      projectName: "",
    })); // why: dropdown is absent, but form object still has it
    dispatch(setSelectedVendor(null));
    dispatch(mergeIntoCreateBillForm({ projectId: "", projectName: "" }));

    focusRef.current = true;
  };
  // logic for open dropdown clear is pressed and nothing is selected
  if (vendorSelected) {
    focusRef.current = false;
  }

  const navigateToVendorHandler = () => {
    saveCompleteForm();
    // actually just redirect to vendor, no edit
    searchParams.append(
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.employees.id
        : SLIDERS_SEARCH_PARAMS.vendors.id,
      vendorSelected
    );
    setSearchParams(searchParams);
  };

  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );

  // feat: vendor createable dropdown
  const handleCreate = (newName, isSetParam = true) => {
    if (!newName.trim()) return;

    saveCompleteForm();
    if (isSetParam) searchParams.set(parentSearchParamKey, newName); // so can select create vendor when we come back
    searchParams.append(
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.employees.create
        : SLIDERS_SEARCH_PARAMS.vendors.create,
      newName
    ); // signal for create vendor.
    // EXTRA_FEAT: since create is now a string value param, one can create "create vendor" links
    // IN fact: since both createbill and create vendor query params exist side by side, one can create
    // EXTRA_FEAT: "payment for new vendor with vendor name specified in link"
    setSearchParams(searchParams);
  };

  // feat: vendor OCR identified but not presented in volopay
  const ocrCreateNewVendorYesHandler = () => {
    // reuse, as if dropdown creatable was used and enter was pressed
    handleCreate(vendorNameCreateOCR, !!ocrMode); // don't set param to true in email draft mode
  };
  const ocrCreateNewVendorNoHandler = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.payments.showCreateVendor);
    searchParams.delete(
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payrollPayments.createEmployeePrompt
        : SLIDERS_SEARCH_PARAMS.payments.createVendorPrompt
    );
    setShowVendorNameCreateOCR(false);
    if (ocrMode) {
      searchParams.set(parentSearchParamKey, true);
    }
    setSearchParams(searchParams); // remove name from query param. Btw, we still have the vendor name in state, ref if user could still use detected name to create vendor
    // on close, uploaded files remains in place (on the left),
    // and can be uploaded like normal attachment, still in OCR mode. Ok.
  };

  const handleVendorChange = (e) => {
    const selectedVendorId = e?.id;
    if (selectedVendorId)
      dispatch(
        fetchAndSelectVendor({
          id: selectedVendorId,
          onSuccess: () => {
            // precaution - empty existing quotes to avoid stale exchange rate text
            dispatch(setPurchaseBillQuote(null));

            setValues((prev) => ({
              ...prev,
              vendor: selectedVendorId,
            }));
          },
        })
      );
    else {
      // precaution - empty existing quotes to avoid stale exchange rate text
      dispatch(setPurchaseBillQuote(null));

      dispatch(setSelectedVendor(null));
    }
    return handleChange(e);
  };

  return (
    <div className={`mt-8 ${classes}`} ref={ref}>
      <Text
        translationKey="billPay.bill.invoiceInbox.createBill.sections.receiver.title"
        classes="text-xl font-bold"
      />

      <div className={`${disabled ? "opacity-50" : ""} mt-6`}>
        {/* OCR create box true case */}
        {showVendorNameCreateOCR ? (
          <div>
            <BoltOnTheLeft showBolt>
              <div className="border-[1px] p-4 border-neutral-300 rounded-md">
                <ProfileWidget
                  name={vendorNameCreateOCR}
                  textClasses="font-semibold text-lg text-neutral-800"
                >
                  <Text
                    translationKey="billPay.bill.invoiceInbox.createBill.ocrNewVendor"
                    classes="text-sm font-medium text-neutral-500"
                  />
                </ProfileWidget>

                <div className="flex items-center gap-4 mt-6">
                  <Button
                    label="billPay.bill.invoiceInbox.createBill.ocrNewVendorYesLabel"
                    classes="text-white font-semibold font-base py-3 w-full"
                    compact
                    variant="primary"
                    onClick={ocrCreateNewVendorYesHandler}
                  />
                  <Button
                    label="billPay.bill.invoiceInbox.createBill.ocrNewVendorNoLabel"
                    classes="text-primary-500 font-semibold py-3 w-full"
                    compact
                    variant="tertiary"
                    onClick={ocrCreateNewVendorNoHandler}
                  />
                </div>
              </div>
            </BoltOnTheLeft>
            {accountingEnabled ? (
              <div className="flex items-center gap-0.5">
                <Icon name="LightBulb" className="text-warning-500" />
                <div>
                  <Text
                    translationKey="billPay.bill.invoiceInbox.createBill.tipColon"
                    classes="text-xs font-medium text-neutral-800"
                  />
                  &nbsp;
                  <Text
                    translationKey="billPay.bill.invoiceInbox.createBill.ocrNewVendorSoftwareON"
                    classes="text-xs font-medium text-neutral-500"
                    translationProps={{
                      vendorName: vendorNameCreateOCR,
                      accountingSoftware,
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        {/* OCR create box false case (normal flow) */}
        {showVendorNameCreateOCR ? null : (
          <div>
            {vendorDetails ? (
              <BoltOnTheLeft
                showBolt={
                  ocrMode &&
                  billOCRSuccess &&
                  createBillFlowContext.current[
                    CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
                  ]?.vendor === values.vendor
                }
              >
                <VendorSelectedPreview
                  readOnly={false}
                  contactsReadOnly={false}
                  vendor={vendorDetails}
                  clearVendorHandler={clearVendorHandler}
                  editVendorHandler={navigateToVendorHandler}
                  senderCurrency={senderCurrency}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  context={context}
                  inPayrollContext={inPayrollContext}
                />
              </BoltOnTheLeft>
            ) : (
              <VpSelect
                name="vendor"
                value={values?.vendor}
                onInputChange={onInputChange}
                label={
                  inPayrollContext
                    ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.receiver.selectEmployee"
                    : "billPay.bill.invoiceInbox.createBill.sections.receiver.selectVendor"
                }
                placeholder={
                  inPayrollContext
                    ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.receiver.selectEmployee"
                    : "billPay.bill.invoiceInbox.createBill.sections.receiver.selectVendor"
                }
                labelStyleClasses="font-semibold text-xs text-neutral-500"
                error={errors?.vendor}
                handleChange={handleVendorChange}
                insideForm
                menuPosition="absolute"
                options={vendorOptions.map((item) => ({
                  ...item,
                  label: item?.name,
                  value: item?.id,
                }))}
                customUIForOptionWhenDropdownOpen={(...args) => {
                  if (args?.[1]?.__isNew__) return args?.[0]; // don't apply custom UI, render the default (`formatCreateLabel` in this case)

                  return vendorDropdownOption(...args);
                }}
                valueKey="value"
                optionsDisplayKey="label"
                isOptionsLoading={isFetchingVendors}
                isFocused={focusRef.current && !disabled && !vendorSelected}
                openMenuOnFocus={
                  focusRef.current && !disabled && !vendorSelected
                }
                creatable
                handleCreate={handleCreate}
                formatCreateLabel={(newEnteredName) => (
                  <div className="flex items-center gap-3">
                    <Icon className="w-3 h-3 text-neutral-800" name="Add" />
                    <Text
                      translationKey={
                        accountingEnabled
                          ? inPayrollContext
                            ? "billPay.vendors.createVendor.createEmployeeXAndMapWithYSoftwareON"
                            : "billPay.vendors.createVendor.createVendorXAndMapWithYSoftwareON"
                          : inPayrollContext
                            ? "billPay.vendors.createVendor.createEmployeeSoftwareOFF"
                            : "billPay.vendors.createVendor.createVendorSoftwareOFF"
                      }
                      translationProps={{
                        newName: newEnteredName,
                        accountingSoftwareName:
                          ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                            accountingIntegrationSoftware
                          ],
                      }}
                      classes="text-base text-neutral-500 font-medium"
                    />
                    <Icon
                      className="w-3 h-3 ml-auto text-neutral-800"
                      name="RightArrow"
                      // the icon is consmetic, the whole row is clickable, so no need handleClick
                    />
                  </div>
                )}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

VendorInfo.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  senderCurrency: PropTypes.string,
  context: PropTypes.string,
  vendorDetails: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  createBillFlowContext: PropTypes.any,
  ocrMode: PropTypes.string,
  billOCRSuccess: PropTypes.bool,
  parentSearchParamKey: PropTypes.string,
  vendorNameCreateOCR: PropTypes.string,
  showVendorNameCreateOCR: PropTypes.bool,
  setShowVendorNameCreateOCR: PropTypes.func,
  saveCompleteForm: PropTypes.func,
};

export default React.forwardRef(VendorInfo);
