import PropTypes from "prop-types";

import SingleColumnDataRows from "@/components/Accounting/Transactions/common/Slider/SingleColumnDataRows";
import { dateToString } from "@/utils/common";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

/**
 *
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=3994-218756&mode=dev}
 *
 * Works for both billpay and  payroll
 */
export default function InvoiceDetails({ payment, context }) {
  return (
    <SingleColumnDataRows
      dataRows={
        context === BILL_PAYROLL_CONTEXT.BILLPAY
          ? [
              {
                label:
                  "billPay.bill.payments.invoiceDetails.invoiceDescription.invoiceNumberHeading",
                value: payment?.invoiceNumber?.toString(),
              },
              {
                label:
                  "billPay.bill.payments.invoiceDetails.invoiceDescription.invoiceDateHeading",
                value: dateToString(payment?.invoiceDate),
              },
              {
                label:
                  "billPay.bill.payments.invoiceDetails.invoiceDescription.dueDateHeading",
                value: dateToString(payment?.dueDate),
              },
              {
                label:
                  "billPay.bill.payments.invoiceDetails.invoiceDescription.billDescriptionHeading",
                value: payment?.memo,
              },
            ]
          : [
              {
                label:
                  "payroll.salaryPayment.payments.salaryDetails.salaryDescription.paymentMonthAndYear",
                value: dateToString(payment?.payrollMonth, { day: undefined }),
              },
              {
                label:
                  "payroll.salaryPayment.payments.salaryDetails.salaryDescription.paymentCreationeDateHeading",
                value: dateToString(payment?.createdAt),
              },
              {
                label:
                  "payroll.salaryPayment.payments.salaryDetails.salaryDescription.memoHeading",
                value: payment?.memo,
              },
            ]
      }
    />
  );
}

InvoiceDetails.propTypes = {
  payment: PropTypes.object,
  context: PropTypes.string,
};
