import PropTypes from "prop-types";
import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function MissingDetailLoader({ loopCount = 5 }) {
  return (
    <div className="flex flex-col gap-3">
      {[...Array(loopCount)].map((idx, index) => (
        <div
          className={`flex flex-row justify-between px-4 pt-4 ${
            index < loopCount - 1 ? "" : "mb-4"
          }`}
          key={index}
        >
          <div>
            <div className="flex">
              <LoaderSkeleton type="circle" />

              <div className="flex flex-col">
                <LoaderSkeleton size={[10, 130]} />
                <LoaderSkeleton size={[10, 240]} />
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-2">
            <div className="text-right">
              <LoaderSkeleton size={[20, 100]} />
            </div>

            <div>
              <div className="flex items-center justify-around gap-3">
                <LoaderSkeleton size={[15, 15]} />
                <LoaderSkeleton size={[15, 15]} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

MissingDetailLoader.propTypes = {
  loopCount: PropTypes.number,
};
