import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  editBudget,
  fetchBudgets,
  resetBudgetFromData,
  setDepartmentOrProjectFormData,
  setReviewBudgetNewData,
  setReviewBudgetOldData,
  setSelectedBudget,
  updateBudgetFormData,
} from "@/store/reducers/company";
import {
  fetchAndSelectPayment,
  fetchAndSelectPaymentApproval,
  fetchAndSelectPayrollApproval,
  setPayment,
} from "@/store/reducers/payments";

import { defaultCurrencySelector } from "@/store/selectors/client";
import {
  reviewNewBudgetSelector,
  reviewOldBudgetSelector,
} from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import ReviewBudget from "@/components/Company/common/ReviewBudget";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

const CONFIG_ALL_REVIEW_KEYS = {
  amount: "limit",
  frequency: "frequency",
  budgetType: "flexible",
  budgetFor: "modules",
};
export function SubmitReviewBudget({ setOnBack, setOnClose }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const oldReviewData = useSelector(reviewOldBudgetSelector);
  const newReviewData = useSelector(reviewNewBudgetSelector);
  const dispatch = useDispatch();
  const [oldData, setOldData] = useState({});
  const [newData, setNewData] = useState({});
  const storeModeSavedData =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.budget.storeMode) === "true";
  const budgetType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.budget.type
  );
  const id = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.budget.reviewBudget
  );

  const defaultCurrency = useSelector(defaultCurrencySelector);
  const createReviewDataObject = (data) => {
    const newObject = {};
    const arrayOf = Object.keys(CONFIG_ALL_REVIEW_KEYS);

    arrayOf.forEach((item) => {
      newObject[item] =
        item === "amount"
          ? {
              value: data?.[item] ?? data?.[CONFIG_ALL_REVIEW_KEYS?.[item]],
              currency: defaultCurrency,
            }
          : {
              value:
                item === "budgetType" && data?.isFlexble
                  ? (data?.isFlexible ?? data?.[CONFIG_ALL_REVIEW_KEYS?.[item]])
                  : (data?.[item] ?? data?.[CONFIG_ALL_REVIEW_KEYS?.[item]]),
            };
    });
    return newObject;
  };
  useEffect(() => {
    setOldData(createReviewDataObject(oldReviewData));
    setNewData(createReviewDataObject(newReviewData));
  }, [newReviewData, oldReviewData]);

  const handleSubmit = () => {
    if (!storeModeSavedData) {
      const onSuccess = (response) => {
        const handleSearchParams = () => {
          searchParams.delete(
            SLIDERS_SEARCH_PARAMS.company.budget.reviewBudget
          );
          searchParams.delete(
            SLIDERS_SEARCH_PARAMS.company.budget.editBudget.oldDeptBudget
          );
          searchParams.delete(
            SLIDERS_SEARCH_PARAMS.company.budget.editBudget.oldProjBudget
          );
          searchParams.delete(SLIDERS_SEARCH_PARAMS.payments.relatedPayId);
          searchParams.delete(SLIDERS_SEARCH_PARAMS.payments.relatedPayContext);
          searchParams.delete(
            SLIDERS_SEARCH_PARAMS.payments.relatedPayIsPayment
          );
          setSearchParams(searchParams);
        };
        // handle billpay/payroll code (for update ctas essentially)
        const handleBillpayPayroll = () => {
          const payId = searchParams.get(
            SLIDERS_SEARCH_PARAMS.payments.relatedPayId
          );
          const payContext = searchParams.get(
            SLIDERS_SEARCH_PARAMS.payments.relatedPayContext
          );
          const isPayAPayment =
            searchParams.get(
              SLIDERS_SEARCH_PARAMS.payments.relatedPayIsPayment
            ) === "true";
          const isPaySliderOpenInBackground =
            searchParams.get(SLIDERS_SEARCH_PARAMS.payments.id) ||
            searchParams.get(SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId);
          //
          const onPayFetchSuccess = (fetchedPayData) => {
            dispatch(
              setPayment({
                id: fetchedPayData.id,
                value: fetchedPayData,
              })
            );
            handleSearchParams();
          };
          if (payId) {
            if (isPayAPayment) {
              dispatch(
                fetchAndSelectPayment({
                  id: payId,
                  payroll: payContext === BILL_PAYROLL_CONTEXT.PAYROLL,
                  isSetSelected: isPaySliderOpenInBackground,
                  onSuccess: onPayFetchSuccess,
                  onError: handleSearchParams,
                })
              );
            } else if (payContext === BILL_PAYROLL_CONTEXT.PAYROLL) {
              dispatch(
                fetchAndSelectPayrollApproval({
                  id: payId,
                  isSetSelected: isPaySliderOpenInBackground,
                  onSuccess: onPayFetchSuccess,
                  onError: handleSearchParams,
                })
              );
            } else {
              dispatch(
                fetchAndSelectPaymentApproval({
                  id: payId,
                  isSetSelected: isPaySliderOpenInBackground,
                  onSuccess: onPayFetchSuccess,
                  onError: handleSearchParams,
                })
              );
            }
          } else {
            handleSearchParams();
          }
        };

        dispatch(setSelectedBudget(response));
        dispatch(
          fetchBudgets({
            page: 1,
            limit: PAGINATION_PER_REQUEST_LIMIT,
            budget_type: budgetType,
          })
        );
        handleBillpayPayroll();
      };

      const params = {
        id,
        limit: parseFloat(newReviewData?.amount || 0.0),
        frequency: newReviewData?.frequency,
        flexible: newReviewData?.flexible,
        modules: newReviewData?.budgetFor?.map((budget) => budget),
      };
      dispatch(editBudget({ ...params, onSuccess }));
    } else {
      dispatch(setReviewBudgetOldData({}));
      dispatch(setReviewBudgetNewData({}));
      if (storeModeSavedData) {
        searchParams.delete(SLIDERS_SEARCH_PARAMS.company.budget.reviewBudget);
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.company.budget.editBudget.newDeptBudget
        );
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.company.budget.editBudget.newProjBudget
        );
      }
    }
    setSearchParams(searchParams);
  };
  useEffect(() => {
    setOnBack(() => {
      if (storeModeSavedData && Object.keys(oldReviewData)?.length) {
        dispatch(
          updateBudgetFormData({
            indexMode: true,
            index: parseInt(id, 10),
            data: oldReviewData,
          })
        );
      }
    });
    setOnClose((searchParam) => {
      const isLastSlider = searchParam.length === 0;
      if (isLastSlider && storeModeSavedData) {
        dispatch(resetBudgetFromData());
        dispatch(setReviewBudgetOldData({}));
        dispatch(setReviewBudgetNewData({}));
        dispatch(setDepartmentOrProjectFormData(null));
      }
    });
  }, []);
  const ref = useLeftHeaderTitle({ title: "Review edit budget" });
  return (
    <>
      {Object.keys(oldData)?.length && Object.keys(newData)?.length ? (
        <div className="flex flex-col slider-content-core gap-9">
          <div
            ref={ref}
            className="flex flex-col text-3xl font-bold text-neutral-800"
          >
            <Text translationKey="company.people.reviewEditBudget" />
            <Text
              classes="text-sm text-neutral-500 font-normal"
              translationKey="cards.cardDetails.editCardDetails.reviewSection.description"
            />
          </div>
          {newData?.projectType && newData?.projectName ? (
            <div className="flex justify-between text-xs font-semibold capitalize card-wrapper ">
              <Text
                classes="text-neutral-500"
                translationKey={newData?.projectType}
              />
              <Text
                classes="text-neutral-800"
                translationKey={newData?.projectName}
              />
            </div>
          ) : null}
          <div className="flex flex-col gap-6">
            <Text
              classes="text-lg font-semibold"
              translationKey="company.budgets.budgetLimit"
            />
            <ReviewBudget
              oldValue={oldData}
              newValue={newData}
              budgetKeys={["amount", "frequency"]}
            />
          </div>
          <div className="flex flex-col gap-6">
            <Text
              classes="text-lg font-semibold"
              translationKey="filters.budgetType.title"
            />
            <ReviewBudget
              oldValue={oldData}
              newValue={newData}
              budgetKeys={["budgetType"]}
            />
          </div>
          <div className="flex flex-col gap-6 pb-7">
            <Text
              classes="text-lg font-semibold"
              translationKey="company.budgets.budgetedFor"
            />
            <ReviewBudget
              oldValue={oldData}
              newValue={newData}
              budgetKeys={["budgetFor"]}
            />
          </div>
        </div>
      ) : null}

      <div className="flex justify-end gap-4 px-3 py-4 slider-footer">
        <Button
          label="misc.cancel"
          variant="tertiary"
          classes="w-15.5 text-neutral-500"
          onClick={() => {
            searchParams.delete(
              SLIDERS_SEARCH_PARAMS.company.budget.reviewBudget
            );
            setSearchParams(searchParams);
          }}
        />

        <Button label="misc.submit" classes="w-15.5" onClick={handleSubmit} />
      </div>
    </>
  );
}

export default SubmitReviewBudget;
