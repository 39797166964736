import { useState } from "react";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { PAYROLL_APPROVALS_TABS } from "@/utils/constants/payments";

export default function PayrollApprovals() {
  const [selectedTab, setSelectedTab] = useState(PAYROLL_APPROVALS_TABS[0].key);
  return (
    <>
      {/* page  */}
      <div className="flex flex-col pl-11 pr-11.5">
        <span className="breadcrumb-text">
          <Text translationKey="payroll.title" />
          &nbsp;/&nbsp;
          <Text translationKey="payroll.salaryPayment.title" />
        </span>
        <div className="flex justify-between">
          <Text
            translationKey="billPay.bill.approvals.title"
            classes="listing-page-title"
          />
        </div>
        <div className="mt-6">
          <Tabs
            items={PAYROLL_APPROVALS_TABS}
            selectedTab={selectedTab?.key}
            setCurrentTab={(tab) => setSelectedTab(tab)}
          />
        </div>
      </div>
    </>
  );
}
