import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";

import { clientSelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { useForm } from "@/utils/useForm";

export default function AmountFilter({
  filterKey,
  label,
  icon,
  isLabelVisible = true,
  currency,
  callback,
  defaultValues,
}) {
  const popupRef = useRef();
  const clientInfo = useSelector(clientSelector);
  const defaultCurrency = clientInfo?.defaultCurrency;
  const [focused, setFocused] = useState(false);

  const initialValue = {
    minAmount: {
      value: defaultValues?.[`${filterKey}`]?.minAmount?.value || "",
      validate: {
        required: true,
      },
      errorStatement: {
        compare: "minium amount should not be less than maxAmount",
      },
    },
    maxAmount: {
      value: defaultValues?.[`${filterKey}`]?.maxAmount?.value || "",
      validate: {
        required: true,
      },
      errorStatement: {
        compare: "maximum amount should not be less than minAmount",
      },
    },
  };
  const isValidNumber = (number) => !Number.isNaN(parseFloat(number));

  const {
    handleChange,
    values,
    errors,
    _setValues: handleReset,
    isFormButtonDisabled,
    handleSubmit,
  } = useForm(initialValue, (e) => {
    callback({
      [filterKey]: {
        minAmount: {
          value: values.minAmount,
        },
        maxAmount: {
          value: values.maxAmount,
        },
      },
    });
    popupRef.current.close();
    setFocused(false);
  });
  const minimumAmountErrorMessage =
    isValidNumber(values?.minAmount) &&
    isValidNumber(values?.maxAmount) &&
    parseFloat(values?.minAmount) > parseFloat(values?.maxAmount)
      ? "common.minimumAmountErrorMessage"
      : "";
  const maximumAmountErrorMessage =
    isValidNumber(values?.minAmount) &&
    isValidNumber(values?.maxAmount) &&
    parseFloat(values?.minAmount) > parseFloat(values?.maxAmount)
      ? "common.maximumAmountErrorMessage"
      : "";

  return (
    <>
      <Popup
        ref={popupRef}
        trigger={
          <button
            id={`amount-filter-${filterKey}`}
            className={`card-wrapper py-3 px-3 text-neutral-500 gap-2  ${
              !isLabelVisible ? "justify-center w-fit" : "w-[112px]"
            } ${focused ? "border-primary-500" : ""}`}
          >
            <div className="flex items-center justify-center ">
              <Icon name={icon} />
              {isLabelVisible ? <Text translationKey={label} /> : null}
            </div>
          </button>
        }
        closeOnDocumentClick
        position="bottom left"
        className="filter-popup"
        keepTooltipInside="#tooltip-inside"
        onOpen={() => setFocused(true)}
        onClose={() => setFocused(false)}
      >
        <form
          onSubmit={handleSubmit}
          onReset={(e) => {
            handleReset({
              minAmount: defaultValues?.amount?.minAmount?.value || "",
              maxAmount: defaultValues?.amount?.maxAmount?.value || "",
            });
            popupRef.current.close();
          }}
          className="flex flex-col gap-5 py-3 w-123"
        >
          <div className="flex items-center justify-between px-5 my-2">
            <Input
              minNumber={Number.MIN_SAFE_INTEGER}
              name="minAmount"
              placeholder="Min"
              label="Min"
              type="number"
              rightText={currency ?? defaultCurrency}
              value={values?.minAmount}
              error={errors.minAmount || minimumAmountErrorMessage}
              onChange={handleChange}
            />

            <Icon name="EArrow" className="text-neutral-500" />

            <Input
              minNumber={Number.MIN_SAFE_INTEGER}
              name="maxAmount"
              placeholder="Max"
              label="Max"
              type="number"
              rightText={currency ?? defaultCurrency}
              value={values?.maxAmount}
              error={errors.maxAmount || maximumAmountErrorMessage}
              onChange={handleChange}
            />
          </div>

          <div className="flex justify-end gap-3 px-5 py-4 mt-4 border-t border-neutral-200">
            <Button
              btnType="reset"
              label="filter.amountButton.cancel"
              type="default"
              variant="tertiary"
              classes=" w-13.5 text-neutral-500"
            />
            <Button
              btnType="submit"
              name="formSubmit"
              disabled={
                maximumAmountErrorMessage ||
                minimumAmountErrorMessage ||
                !isValidNumber(values?.minAmount) ||
                !isValidNumber(values?.maxAmount)
              }
              label="filter.amountButton.apply"
              classes="w-13.5"
            />
          </div>
        </form>
      </Popup>

      {isLabelVisible ? null : (
        <Tooltip id={`amount-filter-${filterKey}`} direction="bottom">
          <Text translationKey={label} />
        </Tooltip>
      )}
    </>
  );
}

AmountFilter.propTypes = {
  filterKey: PropTypes.string,
  currency: PropTypes.string,
  callback: PropTypes.func,
  defaultValues: PropTypes.any,
  label: PropTypes.string,
  icon: PropTypes.string,
  isLabelVisible: PropTypes.bool,
};
