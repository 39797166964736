import PropTypes from "prop-types";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import Alert from "@/components/core/Alert";
import ProfileWidget from "@/components/core/ProfileWidget";

import ExpenseStatusBadge from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayStatusBadge";
import TimeStampAndCashback from "@/components/common/QrPayAndExpense/common/Sliders/TimeStampAndCashback";
import { AMOUNT_SLIDER_COLOR } from "@/utils/constants/expenses";
import { amountToCurrency } from "@/utils/common";

import {
  EXPENSE_SETTLEMENT_STATUS,
  TRANSACTION_TYPE_EXPENSES_OVERVIEW,
} from "@/constants/expense";

export const ExpenseAndQRPayHeaderJsx = ({
  name,
  amount,
  currency,
  transactionStatus,
  updatedAt,
  cashback,
  cashbackCurrency,
  settlementStatus,
  transactionAmount,
  transactionCurrencyCode,
  exchangeRate,
  isExpenseStatusHidden,
  cashbackPercent,
  profileWidgetUrl,
  transactionType,
}) => {
  const isReversalExpense =
    transactionType === TRANSACTION_TYPE_EXPENSES_OVERVIEW.PURCHASE_REVERSAL ||
    amount < 0;

  const isReversedExepense =
    settlementStatus === EXPENSE_SETTLEMENT_STATUS.RELEASED;

  const ref = useLeftHeaderTitle(
    {
      title: name,
      img: profileWidgetUrl,
    },
    { root: null },
    true
  );

  return (
    <div ref={ref}>
      <div className="mt-5">
        <div className="flex items-center gap-2">
          <p
            className={`text-3xl font-bold  ${
              isReversalExpense
                ? "text-success-600"
                : AMOUNT_SLIDER_COLOR[settlementStatus]
            }`}
          >
            {isReversalExpense ? "+" : ""}
            {amountToCurrency(Math.abs(amount), currency)}
          </p>

          {!isExpenseStatusHidden && transactionStatus ? (
            <ExpenseStatusBadge transactionStatus={transactionStatus} />
          ) : null}
        </div>
        <TimeStampAndCashback
          updatedAt={updatedAt}
          cashback={cashback}
          cashbackCurrency={cashbackCurrency}
          cashbackPercent={cashbackPercent}
          isReversalExpense={isReversalExpense}
          isReversedExepense={isReversedExepense}
        />
      </div>

      {transactionCurrencyCode !== currency && exchangeRate > 0 ? (
        <div className="mt-3">
          <Alert
            variant="neutral"
            icon="swapHorizontal"
            title="expenses.slider.exchangeAlertSliderAmount"
            titleTransalationProp={{
              amount: amountToCurrency(
                transactionAmount,
                transactionCurrencyCode
              ),
            }}
            description="expenses.slider.exchangeAlertSliderAmountComparision"
            descriptionTransalationProp={{
              toAmount: amountToCurrency(1, transactionCurrencyCode),
              fromAmount: amountToCurrency(Math.abs(exchangeRate), currency, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
              }),
            }}
            iconClasses="mt-1"
            titleClasses="mt-1"
            textClasses="flex !flex-row gap-1"
          />
        </div>
      ) : null}
    </div>
  );
};
ExpenseAndQRPayHeaderJsx.propTypes = {
  name: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  transactionStatus: PropTypes.string,
  updatedAt: PropTypes.string,
  cashback: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cashbackCurrency: PropTypes.string,
  settlementStatus: PropTypes.string,
  transactionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transactionCurrencyCode: PropTypes.string,
  isExpenseStatusHidden: PropTypes.bool,
  cashbackPercent: PropTypes.number,
  profileWidgetUrl: PropTypes.string,
  transactionType: PropTypes.string,
};

ExpenseAndQRPayHeaderJsx.defualtProps = {
  isHeader: false,
};
