import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { isHeaderShadowVisibleSlector } from "@/store/selectors/slider";

import ChatWithButton from "@/components/common/ChatWithButton";
import { ANIMATION_TYPE } from "@/GlobalSliders/Sliders";

import Icon from "../Icon";
import SliderLoader from "../SliderLoader";

export default function SlideDrawer({
  show,
  children,
  haveBack,
  close,
  onBack,
  leftExtraHeaderContent,
  rightExtraHeaderContent,
  stackLength = 0,
  animationType = "dec",
  isExpandedSlider,
  showRightSideComponent,
  rightSideComponent,
  isLoading,
}) {
  const isShadowVisible = useSelector(isHeaderShadowVisibleSlector);
  const [sliderClass, setSliderClass] = useState("slider");
  const [rightClass, setRightSiderClass] = useState("");
  // Add scroll logic with slider store

  useEffect(() => {
    if (show) {
      setSliderClass("slider open");
      document.body.classList.add("overflow-hidden");
    } else {
      setSliderClass("slider");
      document.body.classList.remove("overflow-hidden");
    }
  }, [show]);

  return (
    <div id="slider-component">
      <div
        className={isExpandedSlider ? `${sliderClass} w-10/12` : sliderClass}
      >
        <div
          aria-labelledby="slider-header"
          id="slider-header"
          className={`z-10 flex items-center shrink-0 p-6 pb-3 justify-between ${
            isShadowVisible ? "shadow-md" : ""
          }`}
        >
          <div
            aria-labelledby="slider-header-left-side"
            className="flex items-center gap-2"
          >
            {onBack && haveBack ? (
              <span
                onClick={onBack}
                className="cursor-pointer p-4.5 rounded-lg hover:bg-neutral-200 self-start"
              >
                <Icon name="ArrowLeft" />
              </span>
            ) : null}
            <div className={isExpandedSlider ? "w-full" : "max-w-[600px]"}>
              {leftExtraHeaderContent}
            </div>
          </div>
          <div
            aria-labelledby="slider-header-right-side"
            className="flex items-center gap-2"
          >
            {rightExtraHeaderContent}
            <span
              id="hide-close"
              onClick={close}
              className="cursor-pointer p-4.5 rounded-lg hover:bg-neutral-200 self-start"
            >
              <Icon name="Close" className="text-neutral-800" />
            </span>
          </div>
        </div>

        {show && (
          <div
            id="slider-core-container-id"
            key={`key-and-value-${stackLength}`}
            className={`slider-content-container relative ${
              animationType === ANIMATION_TYPE.INCREMENT
                ? "slide-in"
                : "slide-out"
            }`}
          >
            {isLoading ? (
              <div className="absolute z-30 w-full h-full bg-white cursor-progress">
                <SliderLoader />
              </div>
            ) : null}
            {children}
          </div>
        )}
      </div>
      {show || showRightSideComponent ? <ChatWithButton /> : null}
      {show && !showRightSideComponent && <Backdrop close={close} />}
      {showRightSideComponent ? (
        <div
          className={`fixed w-[calc(100%-688px)]  ${
            showRightSideComponent && show ? "slide-out" : "slide-in"
          }  h-full bg-white z-[802] top-0 left-0`}
        >
          {rightSideComponent}
        </div>
      ) : null}
    </div>
  );
}

function Backdrop({ close }) {
  return (
    <div
      className="fixed w-full  h-full bg-black z-[801] top-0 right-0 opacity-50"
      onClick={(e) => {
        e.stopPropagation();
        close(e);
      }}
    />
  );
}

SlideDrawer.propTypes = {
  show: PropTypes.any,
  children: PropTypes.node,
  close: PropTypes.func,
  onBack: PropTypes.func,
  leftExtraHeaderContent: PropTypes.node,
  rightExtraHeaderContent: PropTypes.node,
  haveBack: PropTypes.bool,
  stackLength: PropTypes.number,
  animationType: PropTypes.string,
  isExpandedSlider: PropTypes.bool,
};

Backdrop.propTypes = {
  close: PropTypes.func,
};
