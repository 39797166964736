import PropTypes from "prop-types";

import Checkbox from "@/components/core/Checkbox";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";

export default function UserCell({ val }) {
  return (
    <div
      className="flex items-center gap-3 text-neutral-800"
      onClick={() => val?.onClickHandler(val?.id) ?? (() => {})}
    >
      {val?.showCheckbox ? (
        <Checkbox
          disabled={val?.disabled ?? val?.queued ?? false}
          checked={val?.checkedValue ?? false}
          onClickHandler={val?.onCheckboxClick ?? (() => {})}
        />
      ) : null}

      <ProfileWidget
        avatarColor={val?.createdBy?.colorCode}
        img={val?.createdBy?.avatarUrl}
        textClasses="font-semibold "
        name={val?.createdBy?.displayName}
        id={val?.id}
      >
        <div className="flex items-center gap-1 pr-3 text-xs font-medium text-left truncate text-neutral-500">
          {val?.createdBy?.roles?.length > 0 && (
            <Text
              toolTipDirection="left"
              showTooltip={val?.createdBy?.roles?.[0]?.length > 9}
              tooltipClasses={val?.showCheckbox ? "w-10" : "w-13"}
              translationKey={val?.createdBy?.roles?.[0]}
            />
          )}
          {val?.createdBy?.departmentName ? (
            <div className="flex items-center gap-1">
              <span className="inline-block w-1 h-1 rounded-full bg-neutral-500" />

              <Text
                toolTipDirection="left"
                showTooltip={val?.createdBy?.departmentName?.length > 8}
                tooltipClasses={val?.showCheckbox ? "w-10" : "w-13"}
                translationKey={val?.createdBy?.departmentName}
              />
            </div>
          ) : null}
          {val?.createdBy?.locationName ? (
            <div className="flex items-center gap-1">
              <span className="inline-block w-1 h-1 rounded-full bg-neutral-500" />
              <Text
                toolTipDirection="left"
                showTooltip={
                  val?.duplicateReimbursementId
                    ? val?.createdBy?.locationName?.length > 8
                    : val?.createdBy?.locationName?.length > 9
                }
                tooltipClasses={val?.showCheckbox ? "w-10" : "w-13"}
                translationKey={val?.createdBy?.locationName}
              />
            </div>
          ) : null}
        </div>
      </ProfileWidget>
      {val?.duplicateReimbursementId ? (
        <div className="absolute right-0 mr-2">
          <TextWithTooltipIcon
            icon="Copy"
            iconClasses="w-6 h-6 p-1  border text-warning-500 rounded-md bg-warning-50 border-warning-100 "
            text=""
            tooltipText="reimbursement.createReimbursement.duplicateClaimTitle"
          />
        </div>
      ) : null}
    </div>
  );
}

UserCell.propTypes = {
  val: PropTypes.object,
};
