import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getAccounts } from "@/store/reducers/app";
import { subdomain } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import AppLoginForm from "./AppLoginForm";
import LoginComponents from "./LoginComponents";
import MainPageHeader from "./MainPageHeader";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showEntities, setShowEntities] = useState(false);
  const currentSubdomain = subdomain();
  const isAppSubdomain = currentSubdomain === "app";
  const email = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.switching.email
  );
  useEffect(() => {
    const apiSubdomain = window.localStorage.getItem("api_subdomain");
    const clientUrl = window.localStorage.getItem("client_url");
    //  here it should be || condition if any one of the thing is not present we should call api
    if (!isAppSubdomain && (!apiSubdomain || !clientUrl))
      dispatch(getAccounts({ navigate }));
    else {
      const { hostname } = window.location;
      //  checking if client url and hostname is same or not
      //  includes will check if the hostname is in the client url so we are sure
      // the we will redirect
      if (clientUrl && hostname !== clientUrl && clientUrl.includes(hostname)) {
        const { search } = window.location;
        window.location.href = `http://${clientUrl}/login${search}`;
      }
    }
  }, []);

  return (
    <>
      <MainPageHeader
        title={
          !showEntities
            ? email
              ? "continueLogin"
              : "login.title"
            : "chooseEntity.title"
        }
        subtitle={
          !showEntities
            ? email
              ? "logginInto"
              : "greeting"
            : "chooseEntity.description"
        }
        subtitleTranslationProps={{ entityName: currentSubdomain }}
        showBack={false}
        showGreetIcon={!showEntities}
      />
      {!isAppSubdomain ? (
        <LoginComponents />
      ) : (
        <div className="mt-8">
          <AppLoginForm
            showEntities={showEntities}
            setShowEntities={setShowEntities}
          />
        </div>
      )}
    </>
  );
}
