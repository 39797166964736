import {
  bulkAction,
  fetchAvailableToSync,
  fetchFailedToSync,
  setIsLoadingBulkAction,
  syncTransaction,
  updateAccountingData,
  verifyTransaction,
} from "@/store/reducers/accounting_transactions";

import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { SORTING_CATEGORY, SORTING_TYPE } from "@/utils/constants/sorting";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";

import {
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_STATUS_FILTER_CONFIG,
  ACCOUNTING_TRANSACTION_CTA_KEYS,
  ACCOUNTING_TRANSACTION_STATUS,
} from "@/constants/accounting";
import { ALL_ALLOWED_CTAS } from "@/constants/expense";
import { ACCOUNTING_OWNER_TYPE, RULE_TYPES } from "@/constants/rules";
import { TRANSACTION_ACTIONS } from "@/constants/transactions";

export const accountingActionHandler = async (
  dispatch,
  action,
  payload,
  onSuccess
) => {
  const {
    id,
    accounting_id,
    status,
    transactionType,
    exportable = false,
  } = payload;
  switch (action) {
    case ACCOUNTING_TRANSACTION_CTA_KEYS.VERIFY:
      dispatch(verifyTransaction({ id, onSuccess }));
      break;

    case ACCOUNTING_TRANSACTION_CTA_KEYS.SYNC:
    case ACCOUNTING_TRANSACTION_CTA_KEYS.EXPORT:
      await dispatch(syncTransaction({ id, exportable, onSuccess }));
      dispatch(
        fetchFailedToSync({
          transaction: transactionType,
          onSuccess,
        })
      );
      break;

    case ACCOUNTING_TRANSACTION_CTA_KEYS.UNVERIFY:
    case ACCOUNTING_TRANSACTION_CTA_KEYS.MARK_AS_SYNCED:
      dispatch(
        updateAccountingData({ accounting_id, payload: { status }, onSuccess })
      );
      break;

    default:
  }
};

export const accountingBulkAction = async (
  dispatch,
  payload,
  fetchTransactions,
  pageNumbers
) => {
  const action = await dispatch(bulkAction({ payload }));
  if (bulkAction.fulfilled.match(action)) {
    if (fetchTransactions)
      fetchTransactions({
        pageNumbers,
        onSuccess: () => {
          dispatch(setIsLoadingBulkAction(false));
        },
      });
  }
};

export const formatAccountingStatusFilter = (
  filters,
  tab,
  accountingEnabled = false
) => {
  const filteredFilters = filters.filter((filter) => {
    if (filter.props.filterKey === AVAILABLE_FILTER_KEYS.accountingStatus)
      return accountingEnabled;
    return true;
  });
  return filteredFilters.map((filter) => {
    if (filter.props.filterKey === AVAILABLE_FILTER_KEYS.accountingStatus) {
      return {
        ...filter,
        props: {
          ...filter?.props,
          options: filter?.props?.options.filter(
            (option) =>
              !option.value.some((value) =>
                ACCOUNTING_STATUS_FILTER_CONFIG[tab]?.includes(value)
              )
          ),
        },
      };
    }
    return filter;
  });
};

export const NewVendorChip = ({ label = "", text = "", chipClasses = "" }) => {
  return (
    <div className="flex items-center justify-between rounded-full">
      <Text
        translationKey={label ?? text}
        classes="truncate text-sm font-semibold"
      />
      <Chip
        label="New"
        classes={`bg-success-50 text-success-500 border border-success-200 ${chipClasses}`}
      />
    </div>
  );
};

export const RuleAppliedIcon = ({
  label = "",
  text = "",
  iconClasses = "",
  type = RULE_TYPES.NORMAL,
}) => {
  return (
    <div className="flex items-center justify-between rounded-full">
      <Text translationKey={label ?? text} />
      <div className="flex items-center justify-center p-1 rounded-full bg-primary-200">
        <Icon
          name={type === RULE_TYPES.NORMAL ? "Bolt" : "LightningBoltCustom"}
          label="New"
          className={`w-4 h-4 text-white border border-primary-200 ${iconClasses}`}
        />
      </div>
    </div>
  );
};

export const renderCustomUIForOptionWhenDropdownClosed = (label) => {
  return (
    <NewVendorChip
      label={label}
      chipClasses="bg-primary-50 text-primary-500 border border-primary-200 w-fit"
    />
  );
};

export const renderRuleAppliedIconForOptionWhenDropdownClosed = (
  label,
  type
) => {
  return <RuleAppliedIcon label={label} type={type} />;
};

export const isRuleApplied = (type, id, rule) => {
  let isFound = false;
  if (type === ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE) {
    isFound = rule?.accountingPayeeIds?.includes(id);
  } else {
    isFound = rule?.ruleItemGroups?.find((ruleItem) =>
      ruleItem?.ruleActions?.some((ruleAction) => ruleAction?.ownerId === id)
    );
  }
  return isFound;
};

export const truncateString = (str, length) => {
  if (str?.length <= length) {
    return str;
  }

  return `${str?.slice(0, length - 3)}...`;
};

const iconNames = {
  SYNC: "Sync",
  VERIFY: "Review",
};

export const getActionAndIcon = (
  exportable,
  ctas,
  billPayWithContinousSync,
  status
) => {
  if (status === TRANSACTION_ACTIONS.SYNCED) {
    return exportable
      ? [TRANSACTION_ACTIONS.EXPORTED, iconNames.SYNC]
      : [TRANSACTION_ACTIONS.SYNCED, iconNames.SYNC];
  }
  if (billPayWithContinousSync || ctas?.length === 0)
    return exportable
      ? [TRANSACTION_ACTIONS.EXPORTED, iconNames.SYNC]
      : [TRANSACTION_ACTIONS.SYNC, iconNames.SYNC];
  if (ctas?.includes(ALL_ALLOWED_CTAS.SYNC)) {
    return exportable
      ? [TRANSACTION_ACTIONS.EXPORT, iconNames.SYNC]
      : [TRANSACTION_ACTIONS.SYNC, iconNames.SYNC];
  }
  return [TRANSACTION_ACTIONS.VERIFY, iconNames.VERIFY];
};

export const stickyRightColumn = (accountingSoftware, tab) => {
  if (
    accountingSoftware === ACCOUNTING_SOFTWARES.UNIVERSAL_CSV ||
    accountingSoftware === ACCOUNTING_SOFTWARES.TALLY
  ) {
    return tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED;
  }

  return !!accountingSoftware;
};

export const getSortingParams = (sorting) => {
  const params = {
    sort_column: SORTING_CATEGORY.DATE,
    sort_direction: SORTING_TYPE.DEC,
  };
  if (sorting.type) {
    params.sort_column = SORTING_CATEGORY.AMOUNT;
    params.sort_direction = sorting?.type;
  }
  return params;
};

export const sortAndUnique = (array) => {
  return [...new Set(array.filter(Boolean))].sort((a, b) => a - b);
};
