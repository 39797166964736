import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// reducers
import {
  fetchAccountingIntegrationDetails,
  setSettings,
} from "@/store/reducers/accounting";

// selectors
import {
  accountingIntegrationBankAccountIdSelector,
  accountingIntegrationBankAccountsSelector,
  accountingIntegrationReimbursementBankAccountIdSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";

// core components
import Button from "@/components/core/Button";
import VpSelect from "@/components/core/VpSelect";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import vToast from "@/utils/vToast";
import { useForm } from "@/utils/useForm";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function DefaultAccountsSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [bankAccountId, setBankAccountId] = useState(
    useSelector(accountingIntegrationBankAccountIdSelector)
  );
  const [reimbursementBankAccountId, setReimbursementBankAccountId] = useState(
    useSelector(accountingIntegrationReimbursementBankAccountIdSelector)
  );

  const accountData = useSelector(accountingIntegrationBankAccountsSelector);
  const isLoading = useSelector(isLoadingAccountingIntegrationsSelector);

  const handleCancel = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.defaultAccounts
    );
    setSearchParams(searchParams);
  };

  const handleSave = async () => {
    const action = await dispatch(
      setSettings({
        bank_account_id: values?.creditAccount,
        reimbursement_bank_account_id: values?.reimbursementAccount,
      })
    );

    if (setSettings.fulfilled.match(action)) onSuccessfulSave();
  };

  const onSuccessfulSave = () => {
    setBankAccountId(values?.creditAccount);
    setReimbursementBankAccountId(values?.reimbursementAccount);

    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.defaultAccounts
    );
    handleCancel();
  };

  const initialValue = {
    creditAccount: {
      value: bankAccountId || "",
      validate: {
        required: true,
      },
    },
    reimbursementAccount: {
      value: reimbursementBankAccountId || "",
      validate: {
        required: true,
      },
    },
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, () => {
      handleSave();
    });

  return (
    <>
      <div className="flex flex-col gap-8 px-11">
        <SliderHeader
          heading="accounting.integrations.integrationSettings.defaultAccounts.title"
          subText="accounting.integrations.integrationSettings.defaultAccounts.description"
          headingClasses="text-3xl font-bold text-neutral-800"
          subTextClasses="text-sm font-medium text-neutral-500"
        />
        <form onSubmit={handleSubmit} className="flex flex-col gap-9">
          <div className="flex flex-col gap-3">
            <SliderHeader
              heading="accounting.integrations.integrationSettings.defaultAccounts.defaultCreditAccount.title"
              subText="accounting.integrations.integrationSettings.defaultAccounts.defaultCreditAccount.description"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <VpSelect
              name="creditAccount"
              label="accounting.integrations.integrationSettings.defaultAccounts.selectAccount.title"
              placeholder="accounting.integrations.integrationSettings.defaultAccounts.selectAccount.placeholder"
              optionsDisplayKey="name"
              options={accountData}
              value={values?.creditAccount}
              error={errors?.creditAccount}
              handleChange={handleChange}
              insideForm
              valueKey="id"
              menuPosition="absolute"
            />
          </div>
          <div className="flex flex-col gap-3">
            <SliderHeader
              heading="accounting.integrations.integrationSettings.defaultAccounts.reimbursementAccount.title"
              subText="accounting.integrations.integrationSettings.defaultAccounts.reimbursementAccount.description"
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <VpSelect
              name="reimbursementAccount"
              label="accounting.integrations.integrationSettings.defaultAccounts.selectAccount.title"
              placeholder="accounting.integrations.integrationSettings.defaultAccounts.selectAccount.placeholder"
              optionsDisplayKey="name"
              options={accountData}
              valueKey="id"
              value={values?.reimbursementAccount}
              error={errors?.reimbursementAccount}
              handleChange={handleChange}
              insideForm
              menuPosition="absolute"
            />
          </div>
        </form>
      </div>

      <div className="slider-footer px-6 py-4">
        <div className="flex justify-end items-center gap-6">
          <Button
            label="accounting.integrations.buttonLabel.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            onClick={() => handleCancel()}
          />
          <Button
            label="accounting.integrations.buttonLabel.saveChanges"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            showLoader={isLoading}
            onClick={() => {
              handleSave();
            }}
            btnType="submit"
            disabled={isFormButtonDisabled}
          />
        </div>
      </div>
    </>
  );
}
