import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function DisabledPayrollState() {
  const DISABLED_PAYROLL_CONTENT = [
    {
      id: "1",
      title: "payroll.payrollSettings.rows.bankDetails.title",
      description: "payroll.payrollSettings.rows.bankDetails.description",
      icon: "Encrypted",
    },
    {
      id: "2",
      title: "payroll.payrollSettings.rows.automatedTransfers.title",
      description:
        "payroll.payrollSettings.rows.automatedTransfers.description",
      icon: "Cached",
    },
    {
      id: "3",
      title: "payroll.payrollSettings.rows.effortlessPayments.title",
      description:
        "payroll.payrollSettings.rows.effortlessPayments.description",
      icon: "PaymentColor",
    },
  ];
  return (
    <div className="flex flex-col w-full gap-8">
      {DISABLED_PAYROLL_CONTENT.map((content) => {
        return (
          <div key={content?.id} className="flex items-center gap-4">
            <Icon name={content?.icon} className="text-primary-500" />
            <div className="flex flex-col">
              <Text
                classes="text-base font-semibold"
                translationKey={content?.title}
              />
              <Text
                classes="text-base text-neutral-500"
                translationKey={content?.description}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
