import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";

import { AVAILABLE_FILTER_KEYS } from "./filters";
import { REIMBURSEMENT } from "./payments";
import { REIMBURSEMENT_STATUS } from "./reimbursement";

export const WALLET_ACCOUNT_TYPES = {
  WALLET: "wallet",
  CREDIT: "credit",
};

export const WALLET_CTAS = [
  {
    label: "dashboard.myAccounts.addOrReceive",
    icon: "AddReceiveAmount",
  },
  {
    label: "dashboard.myAccounts.accountDetails",
    icon: "AccountDetails",
  },
  {
    label: "dashboard.myAccounts.exchange",
    icon: "Exchange",
  },
  {
    label: "dashboard.myAccounts.more",
    icon: "MeatballsMenu",
  },
];

export const QUICK_ACCESS_LINKS_ENTRIES = {
  physicalCards: {
    label: "cards.pCards.title",
    icon: "CreditCard",
    iconColor: "text-corporate-cards-700",
    bgColor: "bg-corporate-cards-50",
    path: ROUTES.cards.physicalCards.all.absolutePath,
    disabled: false,
  },
  requestCard: {
    label: "dashboard.quickAccess.cards.requestVirtualCard",
    icon: "AddCard",
    iconColor: "text-corporate-cards-700",
    bgColor: "bg-corporate-cards-50",
    path: `${ROUTES.cards.virtualCards.all.absolutePath}?${SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage}=true`,
  },
  card: {
    label: "dashboard.quickAccess.cards.myCards",
    icon: "Card",
    iconColor: "text-corporate-cards-700",
    bgColor: "bg-corporate-cards-50",
    path: ROUTES.cards.physicalCards.all.absolutePath,
  },
  createPayment: {
    label: "dashboard.quickAccess.billPay.createPayment",
    icon: "Send",
    iconColor: "text-accounts-payable-700",
    bgColor: "bg-success-50",
    path: `${ROUTES.billpay.bill.invoiceInbox.absolutePath}?${SLIDERS_SEARCH_PARAMS.payments.createBillDetails}=true&${SLIDERS_SEARCH_PARAMS.payments.showOCRModal}=true&${SLIDERS_SEARCH_PARAMS.payments.ocrFetched}=false&${SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey}=${SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments}&${SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider}=${SLIDERS_SEARCH_PARAMS.payments.createBillDetails}`,
  },
  addPeople: {
    label: "dashboard.quickAccess.company.addPeople",
    icon: "PersonAdd",
    iconColor: "text-business-accounts-700",
    bgColor: "bg-business-accounts-50",
    path: `${ROUTES.manage.people.users.all.absolutePath}?${SLIDERS_SEARCH_PARAMS.company.people.add}=true`,
  },
  addDepartment: {
    label: "dashboard.quickAccess.company.addDepartments",
    icon: "GroupsAdd",
    iconColor: "text-business-accounts-700",
    bgColor: "bg-business-accounts-50",
    path: `${ROUTES.manage.company.departments.absolutePath}?${SLIDERS_SEARCH_PARAMS.company.department.add}=true`,
  },
  createClaim: {
    label: "dashboard.quickAccess.reimbursement.requestClaim",
    icon: "RequestClaim",
    iconColor: "text-expense-management-700",
    bgColor: "bg-expense-management-50",
    path: `${ROUTES.myVolopay.reimbursement.claims.absolutePath}?${SLIDERS_SEARCH_PARAMS.reimbursements.create}=true`,
  },
  unpaidClaim: {
    label: "dashboard.quickAccess.reimbursement.unPaidClaims",
    icon: "OutstandingClaims",
    iconColor: "text-expense-management-700",
    bgColor: "bg-expense-management-50",
    path: ROUTES.reimbursement.payments.absolutePath,
  },
  rules: {
    label: "dashboard.quickAccess.accounting.rules",
    icon: "Triggers",
    iconColor: "text-accounting-700",
    bgColor: "bg-accounting-50",
    path: `${ROUTES.accounting.settings.absolutePath}?${SLIDERS_SEARCH_PARAMS.accounting.rules.id}=true`,
  },
  integrations: {
    label: "dashboard.quickAccess.accounting.integrations",
    icon: "Integrations",
    iconColor: "text-accounting-700",
    bgColor: "bg-accounting-50",
    path: ROUTES.accounting.integrations.absolutePath,
  },
};

export const QUICK_ACCESS_LINKS__ALL_ENTRIES = {
  cards: {
    sectionLabel: "cards.title.plural",
    ctas: {
      requestCard: {
        label: "dashboard.quickAccess.cards.requestVirtualCard",
        icon: "AddCard",
        iconColor: "text-corporate-cards-700",
        bgColor: "bg-corporate-cards-50",
        path: `${ROUTES.cards.virtualCards.all.absolutePath}?${SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage}=true`,
        disabled: false,
      },
      expenses: {
        label: "expenses.title",
        icon: "LocalATM",
        iconColor: "text-corporate-cards-700",
        bgColor: "bg-corporate-cards-50",
        path: ROUTES.cards.expenses.overView.absolutePath,
        disabled: false,
      },
      physicalCards: {
        label: "cards.pCards.title",
        icon: "CreditCard",
        iconColor: "text-corporate-cards-700",
        bgColor: "bg-corporate-cards-50",
        path: ROUTES.cards.physicalCards.all.absolutePath,
        disabled: false,
      },
      virtualCards: {
        label: "cards.vCards.title",
        icon: "Cloud",
        iconColor: "text-corporate-cards-700",
        bgColor: "bg-corporate-cards-50",
        path: ROUTES.cards.virtualCards.all.absolutePath,
        disabled: false,
      },
      declineExpenses: {
        label: "expenses.declined",
        icon: "AddCard",
        iconColor: "text-corporate-cards-700",
        bgColor: "bg-corporate-cards-50",
        path: ROUTES.cards.expenses.declines.absolutePath,
        disabled: false,
      },
    },
  },
  payments: {
    sectionLabel: "billPay.title",
    ctas: {
      createBill: {
        label: "dashboard.quickAccess.billPay.createBill",
        icon: "Send",
        iconColor: "text-accounts-payable-700",
        bgColor: "bg-accounts-payable-50",
        path: `${ROUTES.billpay.bill.payments.all.absolutePath}?${SLIDERS_SEARCH_PARAMS.payments.createBillDetails}=true&${SLIDERS_SEARCH_PARAMS.payments.ocrFetched}=false&${SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey}=${SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments}&${SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider}=${SLIDERS_SEARCH_PARAMS.payments.createBillDetails}`,
        disabled: false,
      },
      createVendor: {
        label: "dashboard.quickAccess.billPay.createVendor",
        icon: "AddBusiness",
        iconColor: "text-accounts-payable-700",
        bgColor: "bg-accounts-payable-50",
        path: `${ROUTES.billpay.vendors.absolutePath}?${SLIDERS_SEARCH_PARAMS.vendors.create}=true`,
        disabled: false,
      },
      paidPayment: {
        label: "dashboard.quickAccess.billPay.paidPayment",
        icon: "Payments",
        iconColor: "text-accounts-payable-700",
        bgColor: "bg-accounts-payable-50",
        path: ROUTES.billpay.bill.paid.absolutePath,
        disabled: false,
      },
      allVendors: {
        label: "dashboard.quickAccess.billPay.allVendors",
        icon: "Store",
        iconColor: "text-accounts-payable-700",
        bgColor: "bg-accounts-payable-50",
        path: ROUTES.billpay.vendors.absolutePath,
        disabled: false,
      },
      exchange: {
        label: "dashboard.quickAccess.billPay.exchangeRates",
        icon: "Exchange",
        iconColor: "text-accounts-payable-700",
        bgColor: "bg-accounts-payable-50",
        path: ROUTES.billpay.exchangeRate.absolutePath,
        disabled: false,
      },
    },
  },
  reimbursements: {
    sectionLabel: "reimbursement.title",
    ctas: {
      requestClaim: {
        label: "dashboard.quickAccess.reimbursement.requestClaim",
        icon: "ContentPaste",
        iconColor: "text-expense-management-700",
        bgColor: "bg-expense-management-50",
        path: `${ROUTES.myVolopay.reimbursement.claims.drafts.absolutePath}?${SLIDERS_SEARCH_PARAMS.reimbursements.create}=true`,
        disabled: false,
      },
      unApprovedClaim: {
        label: "dashboard.quickAccess.reimbursement.unApprovedClaims",
        icon: "ContentPasteSearch",
        iconColor: "text-expense-management-700",
        bgColor: "bg-expense-management-50",
        path: `${ROUTES.reimbursement.approvals.all.absolutePath}?${AVAILABLE_FILTER_KEYS.claimStatus}=${REIMBURSEMENT_STATUS.pending_approval}`,
        disabled: false,
      },
      unpaidClaim: {
        label: "dashboard.quickAccess.reimbursement.unPaidClaims",
        icon: "PendingActions",
        iconColor: "text-expense-management-700",
        bgColor: "bg-expense-management-50",
        path: ROUTES.reimbursement.payments.absolutePath,
        disabled: false,
      },
      paidClaim: {
        label: "dashboard.quickAccess.reimbursement.paidClaims",
        icon: "Verified",
        iconColor: "text-expense-management-700",
        bgColor: "bg-expense-management-50",
        path: ROUTES.reimbursement.paid.absolutePath,
        disabled: false,
      },
    },
  },
  company: {
    sectionLabel: "company.title",
    ctas: {
      addPeople: {
        label: "dashboard.quickAccess.company.addPeople",
        icon: "PersonAdd",
        iconColor: "text-business-accounts-700",
        bgColor: "bg-business-accounts-50",
        path: `${ROUTES.manage.people.users.absolutePath}?${SLIDERS_SEARCH_PARAMS.company.people.add}=true`,
        disabled: false,
      },
      addDepartment: {
        label: "dashboard.quickAccess.company.addDepartments",
        icon: "GroupsAdd",
        iconColor: "text-business-accounts-700",
        bgColor: "bg-business-accounts-50",
        path: `${ROUTES.manage.company.departments.absolutePath}?${SLIDERS_SEARCH_PARAMS.company.department.add}=true`,
        disabled: false,
      },
      addLocation: {
        label: "dashboard.quickAccess.company.addLocations",
        icon: "AddLocationAlt",
        iconColor: "text-business-accounts-700",
        bgColor: "bg-business-accounts-50",
        path: `${ROUTES.manage.company.locations.absolutePath}?${SLIDERS_SEARCH_PARAMS.company.location.add}=true`,
        disabled: false,
      },
      people: {
        label: "dashboard.quickAccess.company.people",
        icon: "Group",
        iconColor: "text-business-accounts-700",
        bgColor: "bg-business-accounts-50",
        path: ROUTES.manage.people.users.absolutePath,
        disabled: false,
      },
      department: {
        label: "dashboard.quickAccess.company.departments",
        icon: "Groups",
        iconColor: "text-business-accounts-700",
        bgColor: "bg-business-accounts-50",
        path: ROUTES.manage.company.departments.absolutePath,
        disabled: false,
      },
      location: {
        label: "dashboard.quickAccess.company.locations",
        icon: "LocationOn",
        iconColor: "text-business-accounts-700",
        bgColor: "bg-business-accounts-50",
        path: ROUTES.manage.company.locations.absolutePath,
        disabled: false,
      },
    },
  },
  accounting: {
    sectionLabel: "accounting.title",
    ctas: {
      card: {
        label: "dashboard.quickAccess.accounting.cardTransactions",
        icon: "CreditCard",
        iconColor: "text-accounting-700",
        bgColor: "bg-accounting-50",
        path: ROUTES.accounting.transactions.cards.absolutePath,
        disabled: false,
      },
      payment: {
        label: "dashboard.quickAccess.accounting.billPayTransactions",
        icon: "Send",
        iconColor: "text-accounting-700",
        bgColor: "bg-accounting-50",
        path: ROUTES.accounting.transactions.billpay.absolutePath,
        disabled: false,
        // billpay landing page
      },
      reimbursement: {
        label: "dashboard.quickAccess.accounting.reimbursementTransactions",
        icon: "ContentPaste",
        iconColor: "text-accounting-700",
        bgColor: "bg-accounting-50",
        path: ROUTES.accounting.transactions.reimbursement.absolutePath,
        disabled: false,
        // module landing page
      },
      other: {
        label: "dashboard.quickAccess.accounting.otherTransactions",
        icon: "Calculate",
        iconColor: "text-accounting-700",
        bgColor: "bg-accounting-50",
        path: ROUTES.accounting.transactions.others.absolutePath,
        disabled: false,
      },
      ledger: {
        label: "dashboard.quickAccess.accounting.ledgers",
        icon: "AddNotes",
        iconColor: "text-accounting-700",
        bgColor: "bg-accounting-50",
        path: ROUTES.accounting.ledger.absolutePath,
        disabled: false,
      },
      integrations: {
        label: "dashboard.quickAccess.accounting.integrations",
        icon: "Integrations",
        iconColor: "text-accounting-700",
        bgColor: "bg-accounting-50",
        path: ROUTES.accounting.integrations.absolutePath,
        disabled: false,
      },
      rules: {
        label: "dashboard.quickAccess.accounting.rules",
        icon: "Triggers",
        iconColor: "text-accounting-700",
        bgColor: "bg-accounting-50",
        path: `${ROUTES.accounting.settings.absolutePath}?${SLIDERS_SEARCH_PARAMS.accounting.rules.id}=true`,
        disabled: false,
      },
    },
  },
};

export const PENDING_REQUEST_COUNTS_ENTRIES = [
  {
    label: "dashboard.pendingRequestCount.newCardRequests",
    icon: "AddCard",
    key: "newCardRequests",
    iconColor: "text-corporate-cards-700",
    bgColor: "bg-corporate-cards-50",
    path: ROUTES.cards.virtualCards.all.absolutePath,
  },
  {
    label: "dashboard.pendingRequestCount.editCardRequest",
    icon: "EditCard",
    key: "editCardRequests",
    iconColor: "text-corporate-cards-700",
    bgColor: "bg-corporate-cards-50",
    path: ROUTES.cards.virtualCards.all.absolutePath,
  },
  {
    label: "dashboard.pendingRequestCount.billPayRequests",
    icon: "BillPayRequests",
    key: "billPaymentsRequests",
    iconColor: "text-accounts-payable-700",
    bgColor: "bg-accounts-payable-50",
    path: ROUTES.billpay.bill.payments.all.absolutePath,
  },
  {
    label: "dashboard.pendingRequestCount.reimbursementRequests",
    icon: "ReimbursementRequests",
    key: "reimbursementRequests",
    iconColor: "text-expense-management-700",
    bgColor: "bg-expense-management-50",
    path: ROUTES.reimbursement.approvals.absolutePath,
  },
];

export const PENDING_ACCOUNTING_ACTION_COUNT_ENTRIES = [
  {
    label: "dashboard.pendingAccountingAction.reviewExpenses",
    icon: "Checklist",
    key: "reviewExpense",
    iconColor: "text-accounting-700",
    bgColor: "bg-accounting-50",
    path: ROUTES.accounting.transactions.cards.all.absolutePath,
  },
  {
    label: "dashboard.pendingAccountingAction.pendingToVerify",
    icon: "PendingToVerify",
    key: "pendingToVerify",
    iconColor: "text-accounting-700",
    bgColor: "bg-accounting-50",
    path: ROUTES.accounting.transactions.cards.pending.absolutePath,
  },
  {
    label: "dashboard.pendingAccountingAction.pendingToSync",
    icon: "Sync",
    key: "pendingToSync",
    iconColor: "text-accounting-700",
    bgColor: "bg-accounting-50",
    path: ROUTES.accounting.transactions.cards.pending.absolutePath,
  },
];

export const NEEDS_ATTENTION_COUNT_ENTRIES = [
  {
    label: "dashboard.needsYourAction.yourMissingDetails",
    icon: "ShieldCross",
    key: "userMissingDetails",
    iconColor: "text-danger-400",
    bgColor: "bg-danger-50",
    path: ROUTES.cards.expenses.overView.absolutePath,
  },
  {
    label: "dashboard.needsYourAction.companyMissingDetails",
    icon: "ShieldCross",
    key: "companyMissingDetails",
    iconColor: "text-danger-400",
    bgColor: "bg-danger-50",
    path: ROUTES.cards.expenses.overView.absolutePath,
  },
  {
    label: "dashboard.needsYourAction.yourDeclinedExpenses",
    icon: "Cancel",
    key: "userDeclinedExpenses",
    iconColor: "text-danger-400",
    bgColor: "bg-danger-50",
    path: ROUTES.cards.expenses.overView.absolutePath,
  },
  {
    label: "dashboard.needsYourAction.companysDeclinedExpenses",
    icon: "Cancel",
    key: "companyDeclinedExpenses",
    iconColor: "text-danger-400",
    bgColor: "bg-danger-50",
    path: ROUTES.cards.expenses.overView.absolutePath,
  },
  {
    label: "dashboard.needsYourAction.yourFailedPayments",
    icon: "BillPayCancel",
    key: "userFailedBillPayments",
    iconColor: "text-danger-400",
    bgColor: "bg-danger-50",
    path: ROUTES.billpay.bill.payments.failed.absolutePath,
  },
  {
    label: "dashboard.needsYourAction.companysFailedPayments",
    icon: "BillPayCancel",
    key: "companyFailedBillPayments",
    iconColor: "text-danger-400",
    bgColor: "bg-danger-50",
    path: ROUTES.billpay.bill.payments.failed.absolutePath,
  },
];
