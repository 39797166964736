import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { checkCardOwnerAndRequestOwnerSame } from "@/components/MyVolopay/ActionCentre/common/utils";
import OverviewCardsSection from "@/components/common/OverviewCardsSection";

import {
  BE_FE_KEY_ORDER_TYPE_MAPPING,
  ORDER_TYPE_CONFIG,
} from "@/constants/ActionCentre";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function OrderPhysicalCardOverviewCards({
  requestOwner,
  cardHolder,
  orderType,
}) {
  const requestOwnerName = requestOwner?.displayName;
  const requestOwnerRole = requestOwner?.roles[0] || "";
  const requestOwnerDepartment = requestOwner?.departmentName;
  const requestOwnerLocationName = requestOwner?.locationName;

  let _cardHolder = null;
  if (cardHolder?.length === 1) {
    _cardHolder = cardHolder?.length === 1 ? cardHolder?.[0] : null;
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const cardHolderName = _cardHolder?.displayName;
  const cardHolderRole = _cardHolder?.roles?.[0] || "";
  const cardHolderDepartment = _cardHolder?.departmentName;
  const cardHolderLocationName = _cardHolder?.locationName;

  const overviewCardsConfig = useMemo(() => {
    return getOverviewCardsConfig();
  }, [cardHolder, requestOwner]);

  function getOverviewCardsConfig() {
    const isCardOwnerAndRequestOwnerSame = checkCardOwnerAndRequestOwnerSame(
      cardHolder,
      requestOwner
    );
    const cardHolderCardProps = {
      rows: [
        {
          text: {
            translationKey:
              "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.overviewCards.cardHolder",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6 ",
          },
        },
        {
          text: {
            translationKey: cardHolderName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
          icon: {
            searchParam: SLIDERS_SEARCH_PARAMS.company.people.id,
            searchParamValue: [
              BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderNonPersonalized,
              BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderPersonalized,
            ]?.includes(BE_FE_KEY_ORDER_TYPE_MAPPING?.[orderType])
              ? null
              : cardHolder?.[0]?.id,
            name: "NEArrow",
            type: "icon",
            handleClick,
          },
        },
        {
          renderArray: [
            { label: cardHolderRole },
            {
              label: cardHolderDepartment,
              showTooltip: cardHolderDepartment?.length > 18,
              tooltipClasses: "w-14.75",
            },
            {
              label: cardHolderLocationName,
              showTooltip: cardHolderLocationName?.length > 18,
              tooltipClasses: "w-14.75",
            },
          ],
        },
      ],
    };

    const config = [
      {
        rows: [
          {
            text: {
              translationKey: isCardOwnerAndRequestOwnerSame
                ? "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.overviewCards.requestCardOwnerAndCardHolder"
                : "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.overviewCards.requestCardOwner",
              color: "neutral-500",
              classes: "text-sm not-italic font-semibold leading-6 ",
            },
          },
          {
            text: {
              translationKey: requestOwnerName,
              color: "neutral-800",
              classes: "text-base not-italic font-semibold leading-6 ",
            },
            icon: {
              name: "NEArrow",
              type: "icon",
              searchParam: SLIDERS_SEARCH_PARAMS.company.people.id,
              searchParamValue: requestOwner?.id,
              handleClick,
            },
          },
          {
            renderArray: [
              {
                label: requestOwnerRole,
              },
              {
                label: requestOwnerDepartment,
                showTooltip: cardHolderDepartment?.length > 18,
                tooltipClasses: "w-14.75",
              },
              {
                label: requestOwnerLocationName,
                showTooltip: cardHolderLocationName?.length > 18,
                tooltipClasses: "w-14.75",
              },
            ],
          },
        ],
      },
      {
        rows: [
          {
            text: {
              translationKey:
                "myVolopay.actionCentre.sliders.requestSliders.orderCard.physical.overviewTab.overviewCards.orderType",
              color: "neutral-500",
              classes: "text-sm not-italic font-semibold leading-6",
            },
          },
          {
            text: {
              translationKey:
                ORDER_TYPE_CONFIG[BE_FE_KEY_ORDER_TYPE_MAPPING[orderType]],
              color: "neutral-800",
              classes: "text-base not-italic font-semibold leading-6 ",
            },
          },
        ],
      },
    ];
    if (cardHolder && !isCardOwnerAndRequestOwnerSame) {
      config.splice(1, 0, cardHolderCardProps);
    }

    return config;
  }
  function handleClick({ searchParam, searchParamValue }) {
    if (searchParam && searchParamValue) {
      searchParams.append(searchParam, searchParamValue);
      setSearchParams(searchParams);
    }
  }

  return <OverviewCardsSection config={overviewCardsConfig} />;
}

export default OrderPhysicalCardOverviewCards;

OrderPhysicalCardOverviewCards.propTypes = {
  requestOwner: PropTypes.object,
  cardHolder: PropTypes.array,
  orderType: PropTypes.string,
};
