import { createExport } from "@/store/reducers/exports";
import { convertFilters } from "@/utils/filters";

import vToast from "@/utils/vToast";
import { EXPORTS_TOAST_CONFIG, TOTAL_EXPORT_LIMIT } from "@/constants/exports";

export const handleExport = (
  setShowDateRange,
  totalExports,
  selectedFilters,
  dispatch,
  additionalFilters,
  onSuccess
) => {
  if (totalExports === 0) {
    vToast(EXPORTS_TOAST_CONFIG.DATA_EMPTY);
    // show toast nothing to export
  } else if (totalExports <= TOTAL_EXPORT_LIMIT) {
    dispatch(
      createExport({
        ...convertFilters(selectedFilters),
        ...additionalFilters,
        onSuccess,
      })
    );
  } else {
    vToast(EXPORTS_TOAST_CONFIG.DATA_EXCEEDED);
    // vToast({ title: "Out if range", description: "", variant: "danger" });
    // show toast out of range
  }
};
