import PropTypes from "prop-types";
import React, { useCallback } from "react";

import Text from "@/components/core/Text";

export default function SearchAndFilterUpperPillSection({
  currentNavigationIds,
}) {
  const generateUpperNavPills = useCallback(() => {
    return currentNavigationIds?.map((lavelObject, index) => {
      return (
        <Text
          key={index}
          translationKey={`${lavelObject?.label} > `}
          classes="text-sm font-medium text-neutral-800 w-max"
        />
      );
    });
  }, [currentNavigationIds]);

  return (
    <div
      className={`flex text-left fle-row ${
        currentNavigationIds?.length <= 2 ? "flex-nowrap" : "flex-wrap"
      }`}
    >
      {generateUpperNavPills()}
    </div>
  );
}

SearchAndFilterUpperPillSection.propTypes = {
  currentNavigationIds: PropTypes.array,
};
