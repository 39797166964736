import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAnalyticsTransaction } from "@/store/reducers/analytics";
import { fetchAllEntities } from "@/store/reducers/app";

import { analyticsTransactionListSelector } from "@/store/selectors/analytics";
import { userSelector } from "@/store/selectors/user";

import AnalyticsTableHelper from "@/components/Analytics/common/AnalyticsTableHelper";
import HeaderAndFilterHelper from "@/components/Analytics/common/HeaderAndFilterHelper";

function AnalyticsTransactionHelper({ headerTitle, type }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelector);

  const list = useSelector(analyticsTransactionListSelector);

  useEffect(() => {
    dispatch(fetchAllEntities({ email: currentUser?.email }));
    dispatch(fetchAnalyticsTransaction({ type, page: 1 }));
  }, []);

  return (
    <div className="mx-11">
      <HeaderAndFilterHelper headerTitle={headerTitle} type={type} />
      <AnalyticsTableHelper />
    </div>
  );
}

export default AnalyticsTransactionHelper;

AnalyticsTransactionHelper.propTypes = {
  headerTitle: PropTypes.string,
  type: PropTypes.string,
};
