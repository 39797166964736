export const OCR_UPLOAD_REQUEST_PARAMS = {
  ATTACHMENTS: "file",
  INVOICE_PREDECTION_TYPE: "invoice",
};

export const OCR_EXTRA_HEADERS = {
  VOLO_BE_URL: "volo-be-url",
  PROVIDER: "provider",
  REGION: "region",
};
