import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import Badge from "@/components/core/Badge";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { amountToCurrency } from "@/utils/common";

import {
  CARD_STATUS,
  CARD_STATUS_BADGE_MAP,
  CARD_TYPE_LABEL,
} from "@/constants/Cards";
import { AWAITING_FUNDS, PENDING_APPROVAL } from "@/constants/card";
import { WALLET_TYPE_LABEL } from "@/constants/qrPayments";

function CardSummaryShort({
  heading,
  status = CARD_STATUS.BLOCKED,
  cardType,
  topUpRequestTooltip,
  temporaryLimitTooltip,
  isTemporaryLimit,
  topUpRequests,
  cardId,
  insufficientFunds,
}) {
  const ref = useLeftHeaderTitle({ title: heading });
  const { t } = useTranslation();
  const tempRequestsText = "cards.listingFields.card.tooltip.tempLimit";

  return (
    <div>
      <div className="flex items-center gap-4">
        <span ref={ref} className="text-3xl font-bold text-neutral-800">
          {heading}
        </span>
        <Badge
          translationKey={`cards.cardStatuses.${status}`}
          variant={CARD_STATUS_BADGE_MAP[status]}
          classes="w-11"
        />

        <div className="flex gap-2 cursor-pointer">
          {isTemporaryLimit ? (
            <>
              <div id={`eventRepeat-${cardId}-${temporaryLimitTooltip?.limit}`}>
                <Icon
                  name="EventRepeat"
                  className="w-6 h-6 p-1 border rounded-lg text-neutral-400 bg-neutral-50 border-neutral-100"
                />
              </div>

              <Tooltip
                id={`eventRepeat-${cardId}-${temporaryLimitTooltip?.limit}`}
                direction="top-right"
                maxWidth="400px"
              >
                <Text translationKey="cards.listingFields.card.tooltip.label" />
                <Text
                  translationKey="cards.listingFields.card.tooltip.description"
                  translationProps={{
                    amountCurrency: amountToCurrency(
                      temporaryLimitTooltip?.limit,
                      temporaryLimitTooltip?.currency
                    ),
                    frequency: t(temporaryLimitTooltip?.frequency),
                  }}
                  classes="block text-xs text-neutral-400"
                />
              </Tooltip>
            </>
          ) : null}

          {topUpRequests ? (
            <div id={`top-up-tooltip-${cardId}-${topUpRequestTooltip?.amount}`}>
              <Icon
                name="Schedule"
                className="w-6 h-6 p-1 border rounded-lg text-warning-300 bg-warning-50 border-warning-100"
              />
              <Tooltip
                id={`top-up-tooltip-${cardId}-${topUpRequestTooltip?.amount}`}
                direction="bottom"
              >
                <div>
                  <Text
                    translationKey={tempRequestsText}
                    translationProps={{
                      approvalStatus: insufficientFunds
                        ? t(AWAITING_FUNDS)
                        : t(PENDING_APPROVAL),
                      amountCurrency: amountToCurrency(
                        topUpRequestTooltip?.amount,
                        topUpRequestTooltip?.currency
                      ),
                      frequency: t(topUpRequestTooltip?.frequency),
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
      <span className="text-sm font-medium text-neutral-500">
        <Text
          translationKey={
            CARD_TYPE_LABEL[cardType] ?? WALLET_TYPE_LABEL[cardType]
          }
        />
      </span>
    </div>
  );
}
export default CardSummaryShort;
CardSummaryShort.propTypes = {
  heading: PropTypes.node,
  status: PropTypes.oneOf(Object.values(CARD_STATUS)),
  cardType: PropTypes.string,
  topUpRequestTooltip: PropTypes.object,
  temporaryLimitTooltip: PropTypes.object,
  isTemporaryLimit: PropTypes.string,
  topUpRequests: PropTypes.bool,
  cardId: PropTypes.string,
  insufficientFunds: PropTypes.string,
};
