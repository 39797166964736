import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  setSelectedExpenseId,
  setSliderOnResetBoolean,
} from "@/store/reducers/expense";

import { expensesSliderSelectedExpenseIdSelector } from "@/store/selectors/expense";

import Button from "@/components/core/Button";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import GroupedTableRow from "@/components/common/QrPayAndExpense/common/Overview/OverviewTable/GroupedTableRow";
import OverviewTableLoader from "@/components/common/QrPayAndExpense/common/Overview/OverviewTable/Loader";
import { dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function ExpenseTableSlider({
  isFetching,
  show,
  groupedExpenses,
  categories,
  handleShowMore,
  hasMore,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const groupItemRefs = useRef({});

  const selectedExpenseId = useSelector(
    expensesSliderSelectedExpenseIdSelector
  );

  const setGroupItemRef = useCallback((node, id) => {
    if (node) {
      groupItemRefs.current[id] = node;
    }
  }, []);

  useEffect(() => {
    if (groupItemRefs?.current && groupItemRefs?.current?.[selectedExpenseId]) {
      groupItemRefs.current[selectedExpenseId].scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  return groupedExpenses ? (
    <div>
      <Table tableWrapperClasses="no-scrollbar">
        <tr className="w-full text-xs font-semibold text-left text-neutral-500">
          <th>
            <Text translationKey="cards.cardDetails.overview.policyDetails.expenses.headerLabels.date" />
          </th>
          <th className="text-center">
            <Text translationKey="cards.cardDetails.overview.policyDetails.expenses.headerLabels.policyStatus" />
          </th>
          <th className="text-right">
            <Text translationKey="cards.cardDetails.overview.policyDetails.expenses.headerLabels.amount" />
          </th>
        </tr>
        {Object.entries(groupedExpenses)?.map(([key, value]) => [
          <tr key={`table-inside-slider-${key}`} className="h-9">
            <td className="text-xs font-medium">{dateToString(key)}</td>
            <td aria-label="render-empty-space" />
            <td aria-label="render-empty-space" />
          </tr>,
          (!isFetching || value) && show
            ? value.map((val, index) => (
                <GroupedTableRow
                  setGroupItemRef={(node) => setGroupItemRef(node, val.id)}
                  showTransactionCell={false}
                  key={`overview-row-expense-table-${index}`}
                  tableHeaderKey={key}
                  UnsettledApplied={false}
                  value={val}
                  show={show}
                  category={
                    categories
                      ? categories?.find((_) => _.id === val?.categoryId)
                      : null
                  }
                  viewTransactionItem={(id) => {
                    dispatch(setSliderOnResetBoolean(false));
                    dispatch(setSelectedExpenseId(val?.id));
                    searchParams.append(SLIDERS_SEARCH_PARAMS.expenses.id, id);
                    setSearchParams(searchParams);
                  }}
                />
              ))
            : null,
        ])}
        {isFetching ? <OverviewTableLoader /> : null}
      </Table>

      {hasMore ? (
        <div className="mt-3 mb-9">
          <Button
            classes="w-18"
            disabled={!hasMore}
            type="default"
            variant="tertiary"
            label="misc.viewAllExpense"
            onClick={handleShowMore}
          />
        </div>
      ) : null}
    </div>
  ) : null;
}

export default ExpenseTableSlider;
ExpenseTableSlider.propTypes = {
  isFetching: PropTypes.bool,
  hasMore: PropTypes.bool,
  show: PropTypes.bool,
  categories: PropTypes.array,
  handleShowMore: PropTypes.func,
  groupedExpenses: PropTypes.object,
};
