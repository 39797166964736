import PropTypes from "prop-types";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedExpense } from "@/store/reducers/expense";

import {
  clientSelector,
  expenseMultiLayerReviewEnabledSelector,
} from "@/store/selectors/client";

import Comments from "@/components/core/Comments";
import FileUpload from "@/components/core/FileUpload";
import Icon from "@/components/core/Icon";
import Memo from "@/components/core/Memo";
import Text from "@/components/core/Text";
import Timeline from "@/components/core/Timeline";

import SliderAccountingSection from "@/components/Accounting/Transactions/common/Slider/SliderAccountingSection";
import ExpenseSliderOverviewCards from "@/components/Expenses/ExpenseSliders/ExpenseSlider/ExpenseSliderOverviewCards";
import FlagAlert from "@/components/common/QrPayAndExpense/common/Sliders/FlagAlert";
import SubmissionPolicyCard from "@/components/common/QrPayAndExpense/common/Sliders/SubmissionPolicy";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import { TYPES_TIMELINE } from "@/utils/constants/timeline";
import { fieldIsRequired } from "@/utils/common";
import { NOT_OKAY } from "@/utils/constants/expenses";

import {
  ACCOUNTING_EXPENSE_SLIDER_PAGE,
  EXPENSE_ACCOUNTING_STATUS_TYPES,
  EXPENSE_STATUSES,
} from "@/constants/expense";
import { DEFAULT_ACCEPT_WITH_PDF } from "@/constants/fileViewer";
import { API_KEYS_MAPPING } from "@/api/apiKeys";

export default function ExpenseAndQrPayOverviewTab({
  transaction, // aka expense
  isAccountingSlider,
  memo,
  handleMemoChange,
  expenseId,
  splitExpenses,
  updateExpense,
  remindPeople,
  dismissPolicy,
  submissionPolicy,
  isApproved,
  commentsList,
  files,
  fileUploadHandler,
  primaryAction,
  secondaryAction,
  createNewComment,
  currentUser,
  isExpenseStatusHidden,
  page,
  repaymentRequestStatus,
  showLoadMoreCta,
  loadMoreComments,
  flaggedCommentObj,
}) {
  const dispatch = useDispatch();
  const memoTouched = useRef(null);
  const multipleExpenseReviewLayersSupported = useSelector(
    expenseMultiLayerReviewEnabledSelector
  );
  const showTimeline = multipleExpenseReviewLayersSupported;

  const expenseValues = {
    userName: transaction?.cardHolder?.displayName,
    department: transaction?.cardHolder?.departmentName,
    location: transaction?.cardHolder?.locationName,
    category: transaction?.categoryName,
    merchant: transaction?.merchant?.name,
  };
  const qrPayValues = {
    userName: transaction?.walletHolder?.displayName,
    department: transaction?.walletHolder?.departmentName,
    location: transaction?.walletHolder?.locationName,
    role: transaction?.walletHolder?.roles[0].name,
    upiId: transaction?.merchant?.upiId,
    category: transaction?.categoryName,
    merchant: transaction?.merchant?.name,
  };
  const clientDetails = useSelector(clientSelector);

  const disableFileUpload = [
    EXPENSE_ACCOUNTING_STATUS_TYPES.VERIFIED,
    EXPENSE_ACCOUNTING_STATUS_TYPES.SYNCED,
  ].includes(transaction?.accountingStatus);

  const hideFileUpload = disableFileUpload && files?.length > 0;

  const disableInputsBasedOnAccountingStatus = [
    EXPENSE_STATUSES.VERIFIED,
    EXPENSE_STATUSES.SYNC_FAILED,
    EXPENSE_STATUSES.SYNC_IN_PROGRESS,
    EXPENSE_STATUSES.SYNCED,
  ].includes(transaction?.accountingStatus);
  const currentPageValues =
    page === QRPAY_EXPENSES_CONTEXT.QRPAY ? qrPayValues : expenseValues;

  const accountingDateOptions = useMemo(
    () => ({
      transactionDate: transaction?.expenseDate,
      ledgerDate: transaction?.ledgerDate,
    }),
    []
  );
  const accountingForm = {
    category: {
      value: currentPageValues?.category,
    },
    merchant: {
      value: currentPageValues?.merchant,
    },
    department: {
      value: currentPageValues?.department,
    },
    location: {
      value: currentPageValues?.location,
    },
    accountingDateOptions,
  };
  useEffect(() => {
    if (memoTouched.current && memo?.length === 0)
      updateExpense({ remarks: memo });
  }, [memoTouched.current, memo]);

  return (
    <>
      {transaction?.transactionStatus === NOT_OKAY ? (
        <FlagAlert
          currentUserId={currentUser?.id}
          flaggedById={transaction?.actionBy?.userId}
          flaggedByName={transaction?.actionBy?.name}
          repaymentStatus={transaction?.repaymentRequestStatus}
          reason={flaggedCommentObj?.comment}
        />
      ) : null}
      {transaction?.hasComments &&
      transaction?.transactionStatus === NOT_OKAY ? (
        <Comments
          loadMoreLabel="comments.showOlderActivity"
          comments={commentsList}
          isVisible={!isApproved}
          createNewComment={(val) => {
            createNewComment(val);
          }}
          currentUser={currentUser}
          showLoadMoreCta={showLoadMoreCta}
          loadMoreComments={loadMoreComments}
        />
      ) : null}

      {showTimeline ? (
        <div className="mt-8">
          <Timeline
            status={transaction?.transactionStatus}
            currentApprovers={
              transaction?.currentApprovers ? transaction?.currentApprovers : []
            }
            rejectedReason={transaction?.rejectedReason}
            currentApproverLevel={transaction?.currentApprovalLevel}
            totalApprovers={transaction?.approvalLevels}
            type={TYPES_TIMELINE.EXPENSE}
            id={transaction?.id}
          />
        </div>
      ) : null}

      <ExpenseSliderOverviewCards isAccountingSlider={isAccountingSlider} />
      {/* for declined transactionType */}
      {!isExpenseStatusHidden ? (
        <div className="mt-8">
          {isAccountingSlider && (
            <SliderAccountingSection
              showSplit={
                clientDetails?.splitExpensesEnabled &&
                !disableInputsBasedOnAccountingStatus
              }
              inputs={accountingForm}
              transaction={transaction}
              splitExpenses={splitExpenses}
              page={page}
            />
          )}
          <div className="mt-8">
            <Text
              translationKey="expenses.slider.policyDetails"
              classes="text-lg text-neutral-800 font-semibold mt-5"
            />
            <SubmissionPolicyCard
              handleRemindClick={remindPeople}
              handleDismissPolicy={dismissPolicy}
              expenseId={transaction?.id}
              missingFields={transaction?.missingFields ?? {}}
              status={transaction?.submissionPolicyStatus}
              data={transaction?.submissionPolicy}
            />
          </div>
          {/* RECEIPTS */}

          <div className="mt-8">
            <div className="flex items-center gap-2">
              <Text
                classes="text-lg font-semibold"
                translationKey="accounting.transactions.cards.slider.receipt"
              />
              {transaction?.receipts?.length !== 0 ? (
                <span className="px-1 py-1.25 rounded-full bg-success-600 text-neutral-50">
                  <Icon name="Done" />
                </span>
              ) : null}
            </div>

            <div className="w-full mt-3">
              <FileUpload
                accept={DEFAULT_ACCEPT_WITH_PDF}
                isDisabled={disableFileUpload}
                disabledLabel={
                  files?.length <= 0 ? (
                    <div className="flex flex-col items-center text-neutral-500">
                      <Icon name="accounting.transactions.cards.slider.receipt" />
                      <Text
                        translationKey="accounting.transactions.cards.slider.noReceiptText"
                        classes="font-normal"
                      />
                    </div>
                  ) : null
                }
                acceptText={
                  disableFileUpload ? "" : "common.defaultUploadTextWithPdf"
                }
                files={files}
                handleFileChange={fileUploadHandler}
                primaryAction={primaryAction}
                primaryActionDisabled={disableFileUpload}
                secondaryAction={secondaryAction}
                error={fieldIsRequired(
                  parseFloat(submissionPolicy?.receipt?.amount ?? 0, 10),
                  submissionPolicy?.receipt?.required,
                  transaction?.amount
                )}
                hideCustomButtonAfterUpload={hideFileUpload}
                apiKey={API_KEYS_MAPPING.UPLOAD_RECEIPT_EXPENSE}
                showLoader
              />
            </div>
          </div>
          {/* MEMO */}
          <div className="mt-8">
            <Text
              translationKey="Memo"
              classes="font-semibold text-neutral-800 text-lg"
            />

            <Memo
              disabled={disableFileUpload}
              value={memo}
              name="expenseMemo"
              onBlur={() =>
                memo?.length > 0 ? updateExpense({ remarks: memo }) : () => {}
              }
              onChange={(value) => {
                handleMemoChange(value);
              }}
              onFocus={() => {
                memoTouched.current = true;
              }}
              error={fieldIsRequired(
                parseFloat(submissionPolicy?.memo?.amount ?? 0, 10),
                submissionPolicy?.memo?.required,
                transaction?.amount
              )}
            />
          </div>
          <div className="my-9">
            {!isAccountingSlider && (
              <SliderAccountingSection
                nonAccountingHeading="common.volopayDetails"
                inputs={accountingForm}
                showSplit={
                  clientDetails?.splitExpensesEnabled &&
                  !disableInputsBasedOnAccountingStatus
                }
                onSuccess={(value) => {
                  dispatch(setSelectedExpense(value));
                }}
                transaction={transaction}
                splitExpenses={splitExpenses}
                isAccounting={isAccountingSlider}
                page={ACCOUNTING_EXPENSE_SLIDER_PAGE[page] || page}
              />
            )}
          </div>

          {transaction?.hasComments &&
          transaction?.transactionStatus !== NOT_OKAY ? (
            <Comments
              loadMoreLabel="comments.showOlderActivity"
              comments={commentsList}
              isVisible={!isApproved}
              createNewComment={(val) => {
                createNewComment(val);
              }}
              currentUser={currentUser}
              showLoadMoreCta={showLoadMoreCta}
              loadMoreComments={loadMoreComments}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
}
ExpenseAndQrPayOverviewTab.propTypes = {
  transaction: PropTypes.object,
  handleMemoChange: PropTypes.func,
  isAccountingSlider: PropTypes.bool,
  memo: PropTypes.string,
  expenseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  splitExpenses: PropTypes.array,
  updateExpense: PropTypes.func,
  remindPeople: PropTypes.func,
  dismissPolicy: PropTypes.func,
  submissionPolicy: PropTypes.object,
  isApproved: PropTypes.bool,
  commentsList: PropTypes.arrayOf(PropTypes.object),
  files: PropTypes.array,
  fileUploadHandler: PropTypes.func,
  primaryAction: PropTypes.object,
  secondaryAction: PropTypes.object,
  createNewComment: PropTypes.func,
  currentUser: PropTypes.object,
  isExpenseStatusHidden: PropTypes.bool,
  page: PropTypes.string,
  repaymentRequestStatus: PropTypes.string,
  showLoadMoreCta: PropTypes.bool,
  loadMoreComments: PropTypes.func,
  flaggedCommentObj: PropTypes.object,
};
