import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function EntityListViewLoader() {
  return (
    <div className="flex flex-row items-center justify-between gap-3 border-0 card-wrapper bg-neutral-50">
      <div className="flex flex-col gap-3">
        <LoaderSkeleton size={[15, 150]} />
        <LoaderSkeleton size={[15, 300]} />
      </div>
      <LoaderSkeleton size={[25, 100]} />
    </div>
  );
}
