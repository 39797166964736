import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchAssociatedDataOfSelectedPaymentHistoryData,
  fetchSelectedPaymentHistoryData,
  setSelectedPaymentHistoryListingDataInitialState,
} from "@/store/reducers/reimbursement";

import {
  isFetchingClientDetailsSelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";
import {
  isFetchingSelectedPaymentHistoryListingDataSelector,
  selectedPaymentHistoryDataSelector,
  selectedPaymentHistoryListingDataHasMoreSelector,
  selectedPaymentHistoryListingDataListSelector,
  selectedPaymentHistoryListingDataTotalSelector,
} from "@/store/selectors/reimbursement";

import ReimbursementPaymentDumbComponent from "@/components/Reimbursement/ReimbursementPaymentsSlider/ReimbursementPaymentDumbComponent";
import { SORT_KEYS } from "@/utils/constants/sorting";
import {
  PAID_SELECTED_TABS,
  REIMBURSEMENT_PAGE_TYPE,
} from "@/utils/constants/reimbursement";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  REIMBURSEMENT_DEFAULT_HEADERS,
  REPORT_DEFAULT_HEADERS,
} from "@/constants/reimbursement";

// history
export default function ReimbursementPaymentHistorySlider() {
  const [searchParams, setSearchParam] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(PAID_SELECTED_TABS[0]);
  const [sliderId, setSliderId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [count, updateCount] = useState(0);
  const type = REIMBURSEMENT_PAGE_TYPE.paid;
  const userReimbursement = useSelector(selectedPaymentHistoryDataSelector);

  const list = useSelector(selectedPaymentHistoryListingDataListSelector);
  const hasMore = useSelector(selectedPaymentHistoryListingDataHasMoreSelector);
  const isFetching = useSelector(
    isFetchingSelectedPaymentHistoryListingDataSelector
  );

  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);
  const isFetchingReportEnabledData = useSelector(
    isFetchingClientDetailsSelector
  );

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  const dispatch = useDispatch();
  const paymentHistory = true;

  useEffect(() => {
    setSliderId(
      searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.paymentHistoryId) ||
        null
    );
  });

  const loadMore = () => {
    if (sliderId && !isFetchingReportEnabledData) {
      dispatch(
        fetchAssociatedDataOfSelectedPaymentHistoryData({
          id: parseInt(sliderId, 10),
          page: pageNum,
          [SORT_KEYS.COLUMN]: sorting?.category,
          [SORT_KEYS.DIRECTION]: sorting?.type,
          limit: PAGINATION_PER_REQUEST_LIMIT,
        })
      );
    }
  };

  const onReset = () => {
    dispatch(setSelectedPaymentHistoryListingDataInitialState());
  };

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    inSlider: true,
    onReset,
    filterOptions: {
      ...sorting,
      sliderId,
      isFetchingReportEnabledData,
    },
  });

  const userReimbursementFilterOptions = {
    user: parseInt(sliderId, 10),
    type: REIMBURSEMENT_PAGE_TYPE[type],
    status:
      type === REIMBURSEMENT_PAGE_TYPE.payments
        ? [PAYMENT_STATUSES.approved, PAYMENT_STATUSES.processing]
        : [PAYMENT_STATUSES.paid, PAYMENT_STATUSES.settledViaCard],
  };

  const getReimbursementPaymentsSliderTableProps = () => {
    return {
      headerSettings: reportEnabled
        ? REPORT_DEFAULT_HEADERS
        : REIMBURSEMENT_DEFAULT_HEADERS,
      type,
      data: list,
      isFetchingData: isFetching,
      paymentHistory,
      userReimbursementFilterOptions,
      userId: sliderId,
      reportEnabled,
      hasMore,
      onScroll,
    };
  };

  useEffect(() => {
    if (sliderId && !isFetchingReportEnabledData) {
      dispatch(
        fetchSelectedPaymentHistoryData({
          id: parseInt(sliderId, 10),
          type: REIMBURSEMENT_PAGE_TYPE[type],
        })
      );
    }
  }, [sliderId, isFetchingReportEnabledData]);

  return (
    !isFetchingReportEnabledData &&
    !isFetching && (
      <ReimbursementPaymentDumbComponent
        userReimbursement={userReimbursement}
        type="payments"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        count={count}
        totalAmount={totalAmount}
        setTotalAmount={setTotalAmount}
        updateCount={updateCount}
        onScroll={onScroll}
        paymentHistory
        getReimbursementPaymentsSliderTableProps={
          getReimbursementPaymentsSliderTableProps
        }
        reportEnabled={reportEnabled}
        sorting={sorting}
        setSorting={setSorting}
      />
    )
  );
}
