import { createSelector } from "@reduxjs/toolkit";

export const selectLedgersStore = (store) => store.loadersError;

export const urlMapSelector = createSelector(
  selectLedgersStore,
  (client) => client.urlMap
);
export const isFormSubmissionProgressSelector = createSelector(
  selectLedgersStore,
  (client) => client.isFormSubmissionProgress
);
