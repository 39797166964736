import { useEffect } from "react";

export default function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Check if ref is defined and if it's an array, check if every item is defined
      if (
        !ref ||
        (Array.isArray(ref) &&
          ref.every(
            (item) =>
              item && item.current && !item.current.contains(event.target)
          )) ||
        (!Array.isArray(ref) &&
          ref.current &&
          !ref.current.contains(event.target))
      ) {
        handler(event);
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
