import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setTotalSpends } from "@/store/reducers/analytics";

import { companyDashboardSpendsSelector } from "@/store/selectors/analytics";
import { durationLabelSelectors } from "@/store/selectors/company";
import {
  budgetedPaidOutsideEnabledSelector,
  defaultCurrencySelector,
} from "@/store/selectors/client";
import { availableModulesSelector } from "@/store/selectors/user";

import SpendComparisonCard from "@/components/Company/Overview/MyAccounts/shared/SpendComparisonCard";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { dateToString, percentageDiff } from "@/utils/common";

import {
  SPEND_TYPES,
  SPEND_TYPE_MAP_TO_SHOW_MORE_TABS,
} from "@/constants/company";

export default function ActionCard({ spendType }) {
  const [showingMore, setShowingMore] = useState(false);
  const dispatch = useDispatch();
  const duration = useSelector(durationLabelSelectors);
  const budgetedPaidOutsideEnabled = useSelector(
    budgetedPaidOutsideEnabledSelector
  );

  const showMoreHandler = () => setShowingMore((_) => !_);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const spendValues = useSelector(companyDashboardSpendsSelector);
  const availableModule = useSelector(availableModulesSelector);
  const cardsSpend = spendValues?.cardSpent ?? 0;

  // Paid via volopay
  const billPayPaidViaVolopaySpend = spendValues?.billPaymentSpent ?? 0;
  const reimbursementPaidViaVolopaySpend = spendValues?.reimbursementSpent ?? 0;

  // Paid outside volopay
  const billPayPaidOutsideSpend = spendValues?.billPaymentPaidOutsideSpent ?? 0;
  const reimPaidOutsideVolopaySpend =
    spendValues?.reimbursementPaidOutsideSpent ?? 0;

  // Total spend billpay and reimbursement
  const billpayTotalSpend =
    billPayPaidViaVolopaySpend + billPayPaidOutsideSpend;
  const reimTotalSpend =
    reimbursementPaidViaVolopaySpend + reimPaidOutsideVolopaySpend;

  // Spend percentage
  const totalSpendPercentage = percentageDiff(
    spendValues?.totalSpent,
    spendValues?.previousSpent
  );

  const cardsSpendPercentage = percentageDiff(
    spendValues?.cardSpent,
    spendValues?.previousCardSpent
  );

  const billPayPaidViaVolopaySpendPercentage = percentageDiff(
    spendValues?.billPaymentSpent,
    spendValues?.previousBillPaymentSpent
  );

  const billPayPaidOutsideSpendPercentage = percentageDiff(
    spendValues?.billPaymentPaidOutsideSpent,
    spendValues?.previousBillPaymentPaidOutsideSpent
  );

  const reimbursementPaidViaVolopaySpendPercentage = percentageDiff(
    spendValues?.reimbursementSpent,
    spendValues?.previousReimbursementSpent
  );
  const reimbursementPaidOutsideSpendPercentage = percentageDiff(
    spendValues?.reimbursementPaidOutsideSpent,
    spendValues?.previousReimbursementPaidOutsideSpent
  );

  // Total billpay and reimbursement spend percentage

  const billpayTotalSpendPercentage =
    billPayPaidViaVolopaySpendPercentage + billPayPaidOutsideSpendPercentage;

  const reimTotalSpendPercentage =
    reimbursementPaidViaVolopaySpendPercentage +
    reimbursementPaidOutsideSpendPercentage;

  const SPEND_COMPARISION_CARD = {
    REIMBURSEMENT: {
      bgColorClass: "bg-neutral-50",
      headText: "company.overview.spendAnalytics.filters.reimbursements",
      amount: reimTotalSpend,
      currency: defaultCurrency,
      percentage: reimTotalSpendPercentage,
      percentageText: duration,
    },
    BILL_PAYMENTS: {
      bgColorClass: "bg-neutral-50",
      headText: "company.overview.spendAnalytics.filters.billPayments",
      amount: billpayTotalSpend,
      currency: defaultCurrency,
      percentage: billpayTotalSpendPercentage,
      percentageText: duration,
    },
    CARD_SPENDS: {
      bgColorClass: "bg-neutral-50",
      headText: "company.overview.spendAnalytics.filters.cardSpends",
      amount: cardsSpend,
      currency: defaultCurrency,
      percentage: cardsSpendPercentage,
      percentageText: duration,
    },
    BILL_PAY_PAID_VIA_VOLOPAY: {
      bgColorClass: "bg-neutral-50",
      headText: "dashboard.spendAnalytics.paidViaVolopay",
      amount: billPayPaidViaVolopaySpend,
      currency: defaultCurrency,
      percentage: billPayPaidViaVolopaySpendPercentage,
      percentageText: duration,
    },
    REIM_PAID_VIA_VOLOPAY: {
      bgColorClass: "bg-neutral-50",
      headText: "dashboard.spendAnalytics.paidViaVolopay",
      amount: reimbursementPaidViaVolopaySpend,
      currency: defaultCurrency,
      percentage: reimbursementPaidViaVolopaySpendPercentage,
      percentageText: duration,
    },
    BILL_PAY_PAID_OUTSIDE_VOLOPAY: {
      bgColorClass: "bg-neutral-50",
      headText: "dashboard.spendAnalytics.paidOutsideVolopay",
      amount: billPayPaidOutsideSpend,
      currency: defaultCurrency,
      percentage: billPayPaidOutsideSpendPercentage,
      percentageText: duration,
    },

    REIM_PAID_OUTSIDE_VOLOPAY: {
      bgColorClass: "bg-neutral-50",
      headText: "dashboard.spendAnalytics.paidOutsideVolopay",
      amount: reimPaidOutsideVolopaySpend,
      currency: defaultCurrency,
      percentage: reimbursementPaidOutsideSpendPercentage,
      percentageText: duration,
    },
  };

  const currentSpend = {
    [SPEND_TYPES.TOTAL]: {
      amount: spendValues?.totalSpent,
      percentage: totalSpendPercentage,
    },
    [SPEND_TYPES.CARDS]: {
      amount: cardsSpend,
      percentage: cardsSpendPercentage,
    },
    [SPEND_TYPES.BILL_PAY]: {
      amount: billpayTotalSpend,
      percentage: billpayTotalSpendPercentage,
    },
    [SPEND_TYPES.REIMBURSEMENT]: {
      amount: reimTotalSpend,
      percentage: reimTotalSpendPercentage,
    },
  };
  const spendTypeMappedToShowMore = useMemo(
    () =>
      SPEND_TYPE_MAP_TO_SHOW_MORE_TABS[spendType]
        ?.filter(
          (item) =>
            availableModule?.length && availableModule.includes(item.module)
        )
        ?.map(({ key }) => key),
    [spendType, JSON.stringify(availableModule)]
  );

  const hideDeltaChip =
    typeof duration !== typeof "" ||
    duration === "company.overview.spendAnalytics.filters.allTime";

  useEffect(() => {
    dispatch(setTotalSpends(spendValues?.totalSpent ?? 0));
  }, [spendValues?.totalSpent]);

  useEffect(() => {
    setShowingMore(false);
  }, [spendType]);

  return (
    <div className="p-4 card-wrapper-lg">
      <div className="flex items-center justify-between" id="upper">
        <SpendComparisonCard
          headText={
            typeof duration === typeof ""
              ? `${duration}Spends`
              : "company.department.tableHeaders.customSpends"
          }
          amount={currentSpend[spendType]?.amount}
          currency={defaultCurrency}
          percentage={currentSpend[spendType]?.percentage}
          percentageText={duration}
          paddingClass=""
          amountFontSizeClass="text-2xl"
          hideDeltaChip={hideDeltaChip}
          headTranslation={
            typeof duration === typeof ""
              ? {}
              : {
                  from: dateToString(duration?.from),
                  to: dateToString(duration?.to),
                }
          }
        />
        {spendType === SPEND_TYPES.TOTAL ||
        ([SPEND_TYPES.BILL_PAY, SPEND_TYPES.REIMBURSEMENT].includes(
          spendType
        ) &&
          budgetedPaidOutsideEnabled) ? (
          <div id="right">
            <div className="min-w-[100px] flex flex-col gap-2 items-center hover:cursor-pointer select-none">
              <div
                className="flex items-center justify-center w-10 h-10 rounded-full bg-primary-50"
                onClick={showMoreHandler}
              >
                <Icon
                  name={showingMore ? "ChevronUp" : "ChevronDown"}
                  className="w-[10px] h-[6px] text-primary-500"
                />
              </div>
              <Text
                translationKey={showingMore ? "misc.showLess" : "misc.showMore"}
                classes="text-xs font-semibold text-neutral-500"
              />
            </div>
          </div>
        ) : null}
      </div>
      {showingMore ? (
        <div
          id="spend-card-types-container"
          className="flex items-center gap-4 mt-6"
        >
          {spendTypeMappedToShowMore?.map((key) => (
            <SpendComparisonCard
              key={key}
              {...SPEND_COMPARISION_CARD[key]}
              hideDeltaChip={hideDeltaChip}
              showDuration={[
                SPEND_TYPES.BILL_PAY,
                SPEND_TYPES.REIMBURSEMENT,
              ]?.includes(spendType)}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

ActionCard.propTypes = {
  spendType: PropTypes.string,
};
