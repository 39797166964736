import PropTypes from "prop-types";
import React from "react";
import { useSearchParams } from "react-router-dom";

import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import AvatarWithCount from "@/components/common/AvatarWithCount";
import BudgetPill from "@/components/common/BudgetPill";
import DeltaPercentagePill from "@/components/common/DeltaPercentagePill";
import { amountToCurrency, dateToString, percentageDiff } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { LOCATION } from "@/constants/company";

function LocationProjectAndDepartmentRow({
  data,
  handleRefChange,
  hasMore,
  isFetching,
  isLastRow,
  currency = "SGD",
  type,
  id,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const clickHandler = () => {
    searchParams.set(SLIDERS_SEARCH_PARAMS.company[type].id, data.id);
    setSearchParams(searchParams);
  };

  return (
    <div
      ref={(ref) => {
        if (isLastRow && hasMore && !isFetching) handleRefChange(ref);
      }}
      className="flex items-center justify-between w-full px-8 py-4 cursor-pointer hover:shadow-lg card-wrapper"
      onClick={clickHandler}
    >
      {type === LOCATION ? (
        <div className="flex items-center  w-[35%]">
          <div className="w-11/12 pr-4 mr-4 truncate">
            <Text
              classes="text-xl font-semibold "
              translationKey={data?.name}
              id={`location-title-tooltip-for-${id}`}
            />

            <Tooltip direction="top" id={`location-title-tooltip-for-${id}`}>
              <Text
                translationKey={data?.name}
                classes="text-sm font-medium text-neutral-800"
              />
            </Tooltip>
          </div>

          <div className="flex flex-col w-2/12 gap-1">
            <Text
              classes="text-neutral-500 text-sm font-medium"
              translationKey="company.people.title"
            />
            {data?.people?.displayName && data?.people?.count ? (
              <AvatarWithCount
                firstUserName={data?.people?.displayName}
                firstUserImageUrl={data?.people?.avatar}
                firstUserColorCode={data?.people?.colorCode}
                totalUsers={data?.people?.count ?? 0}
              />
            ) : (
              <Text
                classes="text-base text-neutral-800 font-bold"
                translationKey="-"
                translationProps={{ count: data?.people?.count ?? 0 }}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col w-[25%]">
            <div className="w-11/12 truncate">
              <Text
                classes="text-xl font-semibold"
                translationKey={data?.name}
                id={`department-or-project-title-tooltip-for-${id}`}
              />
              <Tooltip
                direction="top"
                id={`department-or-project-title-tooltip-for-${id}`}
              >
                <Text
                  translationKey={data?.name}
                  classes="text-sm font-medium text-neutral-800"
                />
              </Tooltip>
            </div>

            <Text
              classes="text-xs text-neutral-500 font-medium "
              translationKey={`company.department.managersCount.${
                data?.managerCount <= 1 ? "singular" : "plural"
              }`}
              translationProps={{ count: data?.managerCount }}
            />
            {data?.startDate || data?.endDate ? (
              <div className="flex items-center gap-3">
                <div className="flex gap-1 font-semibold">
                  <Text
                    classes="text-xs text-neutral-500"
                    translationKey="company.project.startDate"
                  />
                  <Text
                    classes="text-xs text-neutral-800"
                    translationKey={dateToString(data?.startDate) ?? "-"}
                  />
                </div>
                {data?.endDate ? (
                  <div className="flex gap-1 font-semibold">
                    <Text
                      classes="text-xs text-neutral-500"
                      translationKey="company.project.endDate"
                    />
                    <Text
                      classes="text-xs text-neutral-800"
                      translationKey={dateToString(data?.endDate) ?? "-"}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          {data?.budgets ? (
            <div className="flex flex-col items-start w-5/12 gap-1">
              <Text
                classes="text-neutral-500 font-medium"
                translationKey="company.budgets.budgetFor"
              />
              <BudgetPill id={data?.id} data={data?.budgets} />
            </div>
          ) : null}
          <div className="flex flex-col w-2/12 gap-1">
            <Text
              classes="text-neutral-500 text-sm font-medium"
              translationKey="company.people.title"
            />
            {data?.people?.displayName && data?.people?.count ? (
              <AvatarWithCount
                firstUserName={data?.people?.displayName}
                firstUserImageUrl={data?.people?.avatar}
                firstUserColorCode={data?.people?.colorCode}
                totalUsers={data?.people?.count ?? 0}
              />
            ) : (
              <Text
                classes="text-base text-neutral-800 font-bold"
                translationKey="-"
                translationProps={{ count: data?.people?.count ?? 0 }}
              />
            )}
          </div>
        </>
      )}

      <div className="flex flex-col items-end w-2/12">
        <Text
          classes="text-neutral-800 text-sm font-medium"
          translationKey="company.budgets.spendThisMonth.title"
        />
        <div>
          <span className="text-xl font-bold text-neutral-800">
            {amountToCurrency(data?.currentSpends ?? 0)}
          </span>
          <span className="font-bold text-neutral-500">{currency}</span>
        </div>
        {percentageDiff(data?.currentSpends ?? 0, data?.previousSpends ?? 0) ? (
          <div className="flex gap-1">
            <DeltaPercentagePill
              percentage={percentageDiff(
                data?.currentSpends ?? 0,
                data?.previousSpends ?? 0
              )}
            />
            <Text
              classes="text-xs font-semibold text-neutral-400"
              translationKey="company.budgets.spendThisMonth.vsLastmonth"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default LocationProjectAndDepartmentRow;

LocationProjectAndDepartmentRow.propTypes = {
  data: PropTypes.object,
  currency: PropTypes.string,
  handleRefChange: PropTypes.func,
  hasMore: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLastRow: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.number,
};
