import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { allNavUrlsSelector } from "@/store/selectors/navigations";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ALL_TABS_IN_BUDGET_MAIN_PAGE,
  EXISTING_DEPARTMENT,
  EXISTING_PROJECT,
  NEW_PROJECT,
  ONLY_DEPARTMENT_TABS_IN_BUDGET_MAIN_PAGE,
  ONLY_PROJECT_TABS_IN_BUDGET_MAIN_PAGE,
} from "@/constants/company";
import { USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS } from "@/constants/user";

function NewBudgetMainPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const allNavUrls = useSelector(allNavUrlsSelector);

  const condition = [
    USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_DEPARTMENTS,
    USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_PROJECTS,
  ];

  const budgetPermission = allNavUrls?.filter((data) =>
    condition?.includes(data?.key)
  );

  const departmentBudgetPermission = budgetPermission?.some(
    (d) =>
      d.key ===
      USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_DEPARTMENTS
  );

  const projectBudgetPermission = budgetPermission?.some(
    (d) =>
      d.key ===
      USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.MANAGE_COMPANY_PROJECTS
  );

  const showAllRows =
    allNavUrls?.filter((data) => condition?.includes(data?.key))?.length === 2;

  const [rows, setRows] = useState(ALL_TABS_IN_BUDGET_MAIN_PAGE);

  useEffect(() => {
    if (showAllRows) return;

    if (departmentBudgetPermission) {
      setRows(ONLY_DEPARTMENT_TABS_IN_BUDGET_MAIN_PAGE);
      return;
    }
    if (projectBudgetPermission) {
      setRows(ONLY_PROJECT_TABS_IN_BUDGET_MAIN_PAGE);
    }
  }, [departmentBudgetPermission, projectBudgetPermission, showAllRows]);

  const handleOptionClick = (submitKey) => {
    switch (submitKey) {
      case EXISTING_PROJECT:
      case EXISTING_DEPARTMENT:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.company.budget.createExisting,
          submitKey
        );
        break;
      case NEW_PROJECT:
        searchParams.append(SLIDERS_SEARCH_PARAMS.company.project.add, true);
        break;
      default:
        break;
    }
    setSearchParams(searchParams);
  };
  const ref = useLeftHeaderTitle({
    title: "company.department.addNewDepartment.budgets.defineNewBudgetBtn",
  });
  return (
    <div className="slider-content-core ">
      <div
        ref={ref}
        className="flex flex-col text-3xl font-bold text-neutral-800"
      >
        <Text translationKey="company.department.addNewDepartment.budgets.defineNewBudgetBtn" />
        <Text
          color="neutral-500"
          classes="text-sm font-semibold"
          translationKey="company.department.addNewDepartment.budgets.defineBudgetDescription"
        />
      </div>
      <div className="flex flex-col gap-4 mt-9">
        {Object.values(rows)?.map((item) => (
          <div
            key={item.title}
            className="flex items-center justify-between cursor-pointer card-wrapper"
            onClick={() => handleOptionClick(item.submitKey)}
          >
            <div className="flex flex-col w-10/12 text-lg font-semibold text-neutral-800">
              <Text translationKey={item.title} />
              <Text
                classes="text-neutral-500 font-medium text-xs"
                translationKey={item.description}
              />
            </div>
            <Icon className="cursor-pointer text-neutral-500" name="Right" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewBudgetMainPage;
