import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  getMerchantRestrictions,
  getMerchantShallow,
  setGlobalSettingsMccInfo,
  setMerchantRestrictions,
} from "@/store/reducers/cards";
import { fetchCategories } from "@/store/reducers/categories";

import {
  globalSettingsMccInfoSelector,
  isFetchingMerchantRestrictionsSelector,
  merchantRestrictionsInfoSelector,
} from "@/store/selectors/cards";
import { clientSelector } from "@/store/selectors/client";

import Text from "@/components/core/Text";

import ControlsPage from "@/components/Cards/Sliders/CardMerchantCategoryControls";
import { formatLinkedMccRestrictionsApiResponse } from "@/components/Cards/Sliders/CardMerchantCategoryControls/util";

import {
  COMMON_SLIDERS_TRIGGER_KEYS,
  MCC_OWNER_TYPE,
  merchantAndCategoryControlsFieldKeys,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import CreateNewPage from "../../common/CreateNewPage";

function ControlSettingSlider({ setOnClose }) {
  const dispatch = useDispatch();
  const [searchParam, setSearchParams] = useSearchParams();

  const clientInfo = useSelector(clientSelector);
  const clientId = clientInfo?.id;
  const currentLinkedMccRestrictions = useSelector(
    merchantRestrictionsInfoSelector
  );
  const isFetchingCurrentLinkedMccRestrictions = useSelector(
    isFetchingMerchantRestrictionsSelector
  );
  const globalSettingsMccInfo = useSelector(globalSettingsMccInfoSelector);
  const globalSettingsSelectedControlOptions =
    globalSettingsMccInfo?.[
      merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
    ];
  const showCreateMccScreen =
    globalSettingsSelectedControlOptions &&
    globalSettingsSelectedControlOptions?.length === 0;

  useEffect(() => {
    if (Object.entries(globalSettingsMccInfo || {}).length === 0) {
      const apiFormattedResponse = formatLinkedMccRestrictionsApiResponse(
        currentLinkedMccRestrictions
      );

      dispatch(setGlobalSettingsMccInfo(apiFormattedResponse));
    }

    setOnClose((searchParamArray) => {
      if (searchParamArray?.length === 0) {
        dispatch(setMerchantRestrictions({}));
      }
    });
  }, [currentLinkedMccRestrictions]);

  useEffect(() => {
    if (clientId) {
      dispatch(
        getMerchantRestrictions({
          params: { owner_type: MCC_OWNER_TYPE.CLIENT, owner_id: clientId },
        })
      );
    }

    dispatch(fetchCategories());
    dispatch(getMerchantShallow());
  }, [clientInfo]);

  return (
    <div className="slider-content-core">
      {!isFetchingCurrentLinkedMccRestrictions ? (
        <div className="flex flex-col">
          {showCreateMccScreen ? (
            <CreateNewPage
              title="cardSetting.control.title"
              description="cardSetting.control.description"
              emptyDataTitle="cardSetting.control.emptyDataTitle"
              emptyDataDescription="cardSetting.control.emptyDataDescription"
              emptyButtonIcon="Add"
              emptyButtonLabel="cardSetting.control.emptyButtonLabel"
              onClickHandler={() => {
                searchParam.append(
                  SLIDERS_SEARCH_PARAMS.cards.cardsettings.controlsPage,
                  true
                );
                setSearchParams(searchParam);
              }}
            />
          ) : (
            <ControlsPage
              triggerSlider={COMMON_SLIDERS_TRIGGER_KEYS.GLOBAL_SETTINGS}
              sliderParam={
                SLIDERS_SEARCH_PARAMS.cards.cardsettings.controlsPage
              }
              isPreviewMode
              externalSectionClasses="px-0"
            />
          )}
        </div>
      ) : (
        <Text translationKey="cards.editLimit.loadingText" />
      )}
    </div>
  );
}

ControlSettingSlider.propTypes = {
  setOnClose: PropTypes.func,
};

export default ControlSettingSlider;
