import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";

import ReviewAmount from "./ReviewAmount";
import ReviewBudgetFor from "./ReviewBudgetFor";
import ReviewBudgetType from "./ReviewBudgetType";
import ReviewFrequency from "./ReviewFrequency";

/**
 *
 * @param {Object} oldValue
 * @param {Object} newValue
 * @param {Array}  budgetKeys - array of ['amount','frequency','budgetType','budgetFor]
 * @returns
 */
function ReviewBudget({ oldValue, newValue, budgetKeys, showPercentagePill }) {
  const renderSingleCompoent = (key, classes) => {
    switch (key) {
      case "amount":
        return (
          <ReviewAmount
            oldValue={oldValue?.amount?.value}
            newValue={newValue?.amount?.value}
            oldValueTitle={oldValue?.amount?.oldValueTitle}
            newValueTitle={newValue?.amount?.newValueTitle}
            oldCurrency={oldValue?.amount?.currency}
            newCurrency={newValue?.amount?.currency}
            showPercentagePill={showPercentagePill}
            newContainerClasses={classes}
            oldContainerClasses={classes}
          />
        );
      case "frequency":
        return (
          <ReviewFrequency
            oldValue={oldValue.frequency?.value}
            newValue={newValue.frequency?.value}
            newContainerClasses={classes}
            oldContainerClasses={classes}
          />
        );
      case "budgetType":
        return (
          <ReviewBudgetType
            oldValue={oldValue.budgetType?.value}
            newValue={newValue?.budgetType?.value}
            newContainerClasses={classes}
            oldContainerClasses={classes}
          />
        );
      case "budgetFor":
        return (
          <ReviewBudgetFor
            id={key}
            oldValue={oldValue?.budgetFor?.value ?? []}
            newValue={newValue?.budgetFor?.value ?? []}
            newContainerClasses={classes}
            oldContainerClasses={classes}
          />
        );
      default:
        break;
    }
  };
  const getFullComponent = () => {
    if (budgetKeys?.length === 1) {
      const currentKey = budgetKeys[0];
      return (
        <div className="relative">
          {renderSingleCompoent(currentKey, "card-wrapper w-1/2")}
          <div className="absolute flex items-center justify-center w-8 h-8 -translate-x-1/2 -translate-y-1/2 rounded-md left-1/2 top-1/2 bg-primary-400 ">
            <Icon name="EArrow" className="w-3.5 h-3.5 text-white " />
          </div>
        </div>
      );
    }
    if (budgetKeys.length > 1) {
      return budgetKeys?.length ? (
        <div className="relative card-wrapper">
          <div className="flex flex-col divide-y-4 divide-neutral-100 divide-dashed">
            {budgetKeys?.map((key, index, arr) => (
              <div key={key} className="py-5">
                {renderSingleCompoent(key, "w-1/2")}
              </div>
            ))}
          </div>
          <div className="absolute flex items-center justify-center w-8 h-8 -translate-x-1/2 -translate-y-1/2 rounded-md left-1/2 top-1/2 bg-primary-400">
            <Icon name="EArrow" className="w-3.5 h-3.5 text-white " />
          </div>
        </div>
      ) : null;
    }
  };
  return <div>{budgetKeys?.length ? getFullComponent() : null}</div>;
}

export default ReviewBudget;

ReviewBudget.propTypes = {
  oldValue: PropTypes.object,
  newValue: PropTypes.object,
  budgetKeys: PropTypes.array,
  showPercentagePill: PropTypes.bool,
};
