import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

function AmountCellWithConvervesion({ amountTo, amountFrom }) {
  const config = [
    {
      amount: amountTo?.amount || amountTo?.value,
      currency: amountTo?.currency,
      textClasses: "text-sm font-semibold text-neutral-800",
      show: amountTo?.amount || amountTo?.value,
    },
    {
      amount: amountFrom?.amount || amountFrom?.value,
      currency: amountFrom?.currency,
      textClasses: "text-xs font-medium text-neutral-500",
      show:
        amountFrom?.amount || amountFrom?.value
          ? amountFrom?.currency !== amountTo?.currency
          : false,
      frontIcon: "World",
    },
  ];

  return (
    <div className="flex flex-col items-end">
      {config.map((amountRow, index) => {
        const {
          amount = null,
          currency = null,
          show,
          frontIcon = null,
          textClasses = "",
        } = amountRow;
        const amountLabel = `${amount} ${currency}`;

        return show ? (
          <div className="flex flex-row items-center gap-2" key={index}>
            {frontIcon ? <Icon name={frontIcon} /> : null}
            <Text translationKey={amountLabel} classes={textClasses} />
          </div>
        ) : null;
      })}
    </div>
  );
}

AmountCellWithConvervesion.propTypes = {
  amountTo: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number,
  }),
  amountFrom: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number,
  }),
};
export default AmountCellWithConvervesion;
