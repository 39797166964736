import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { defaultCurrencySelector } from "@/store/selectors/client";

// component import
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";

const LimitCell = ({
  handleDeleteCategories,
  val,
  limitInput,
  setLimitInput,
  getLimitData,
}) => {
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const handleInputChange = (value) => {
    const tempLimit = {
      ...limitInput,
      [val?.id]: value,
    };
    setLimitInput(tempLimit);
  };
  useEffect(() => {
    getLimitData(val.id);
  }, []);
  return (
    <div className="flex items-center justify-between gap-2 ">
      <Input
        type="number"
        label="Limit"
        classes="!w-18.5"
        rightText={defaultCurrency}
        value={limitInput ? limitInput[val?.id] : ""}
        onChange={(e) => handleInputChange(e.target.value)}
      />
      <Icon
        name="Delete"
        handleClick={() => handleDeleteCategories(val.id)}
        className="text-neutral-400 mr-4 my-0.75 hover:cursor-pointer active:text-neutral-800  "
      />
    </div>
  );
};
LimitCell.propTypes = {
  handleDeleteCategories: PropTypes.func,
  val: PropTypes.object,
  limitInput: PropTypes.object,
  setLimitInput: PropTypes.func,
  getLimitData: PropTypes.func,
};

export default LimitCell;
