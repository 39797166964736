import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { reportsListSelector } from "@/store/selectors/reimbursement";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { REIMBURSEMENT_REPORTS_TABS } from "@/utils/constants/myVolopay";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function MyVolopayReimbursementReportPage() {
  const reports = useSelector(reportsListSelector);
  const [searchParam, setSearchParam] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(
    REIMBURSEMENT_REPORTS_TABS[0].key
  );

  const createReportCtaHandler = () => {
    searchParam.append(SLIDERS_SEARCH_PARAMS.reports.create, true);
    setSearchParam(searchParam);
  };
  return (
    <div className="px-10">
      <div className="flex items-center justify-between pb-6">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <Text
              classes="text-neutral-500 text-sm"
              translationKey="myVolopay.title"
            />
            <div>/</div>
            <Text
              classes="text-neutral-500 text-sm"
              translationKey="reimbursement.title"
            />
          </div>
          <Text
            classes="text-neutral-800 text-4xl font-extrabold"
            translationKey="myVolopay.reimbursements.reports.title"
          />
        </div>
        {reports?.length ? (
          <Button
            classes="h-11 font-medium w-16"
            preIcon="Add"
            onClick={createReportCtaHandler}
            label="myVolopay.reimbursements.reports.createReport"
          />
        ) : null}
      </div>

      <div className="mt-6">
        <Tabs
          items={REIMBURSEMENT_REPORTS_TABS}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
        />
      </div>
    </div>
  );
}

export default MyVolopayReimbursementReportPage;
