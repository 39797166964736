import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchEntityOptions,
  setAccountingIntegrationEntity,
} from "@/store/reducers/accounting";
// reducers
import { fetchClient, setClient } from "@/store/reducers/client";

// selectors
import {
  accountingEntityOptionsSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";
// selectors
import { clientSelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
// core components
import VpSelect from "@/components/core/VpSelect";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";

export default function EntitySelectionSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const clientData = useSelector(clientSelector);
  const currentSoftware = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.integrations.software
  );
  const [entitySelected, setEntitySelected] = useState(null);
  const entityOptions = useSelector(accountingEntityOptionsSelector);
  const isLoadingConnect = useSelector(isLoadingAccountingIntegrationsSelector);

  useEffect(() => {
    dispatch(fetchEntityOptions({ slug: currentSoftware }));
  }, []);

  const handleContinue = async () => {
    const action = await dispatch(
      setAccountingIntegrationEntity({
        company_file_id: entitySelected.value,
        slug: currentSoftware,
      })
    );

    if (setAccountingIntegrationEntity.fulfilled.match(action))
      onAccountingIntegrationCompleted();
  };

  const onAccountingIntegrationCompleted = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.setEntity
    );
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.integrations.software);
    setSearchParams(searchParams);

    dispatch(fetchClient());
  };

  return (
    <div className="slider-content-container">
      <div className="slider-content-core px-10 flex flex-col gap-8">
        <SliderHeader
          heading="accounting.integrations.labels.softwareSetup"
          headingTranslationProps={{
            accountingSoftware:
              ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[currentSoftware],
          }}
          headingClasses="text-3xl font-bold text-neutral-700"
          refFlag
        />
        <SliderHeader
          heading="accounting.integrations.labels.entity"
          subText="accounting.integrations.selectEntity.description"
          subTextTranslationProps={{
            accountingSoftware:
              ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[currentSoftware],
          }}
          headingClasses="text-lg font-semibold text-neutral-800"
          subTextClasses="text-sm font-medium text-neutral-500"
        />
        <VpSelect
          label="accounting.integrations.selectEntity.title"
          optionsDisplayKey="label"
          valueKey="label"
          value={entitySelected?.label}
          handleChange={(option) => setEntitySelected(option)}
          options={entityOptions}
          menuPosition="absolute"
          classes="pb-auto"
        />
      </div>
      <div className="slider-footer sticky p-6 ">
        <div className="flex justify-end">
          <Button
            label="accounting.integrations.buttonLabel.continue"
            classes="w-14.75 px-5 py-3 text-btn-lg font-semibold"
            disabled={!entitySelected}
            showLoader={isLoadingConnect}
            onClick={() => {
              handleContinue();
            }}
            id={`connect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
          />
          {!entitySelected ? (
            <Tooltip
              direction="top"
              id={`connect-${SLIDERS_SEARCH_PARAMS.accounting.integrations.software}`}
            >
              <Text translationKey="accounting.integrations.labels.allFieldsMandatory" />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}
