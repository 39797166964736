import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  fetchLastSyncAttempt,
  fetchSyncAttempts,
} from "@/store/reducers/sync-attempts";

import {
  accountingIntegrationSoftwareSelector,
  clientSelector,
} from "@/store/selectors/client";
import {
  isFetchingSyncAttemptsSelector,
  lastSyncAttemptSelector,
} from "@/store/selectors/sync-attempts";

import Button from "@/components/core/Button";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import LastSyncedOn, {
  LastSyncedOnLoader,
} from "@/components/Accounting/Transactions/common/LastSyncedOn";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_SOFTWARES_ICONS,
  ACCOUNTING_TRANSACTION_ENTITY_TYPES,
} from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";

const ROUTE_TYPE_PAIRS = [
  {
    route: ROUTES.accounting.transactions.cards.absolutePath,
    type: ACCOUNTING_TRANSACTION_ENTITY_TYPES.CARD,
  },
  {
    route: ROUTES.accounting.transactions.billpay.absolutePath,
    type: ACCOUNTING_TRANSACTION_ENTITY_TYPES.PAYMENT,
  },
  {
    route: ROUTES.accounting.transactions.reimbursement.absolutePath,
    type: ACCOUNTING_TRANSACTION_ENTITY_TYPES.REIMBURSEMENT,
  },
  {
    route: ROUTES.accounting.transactions.others.absolutePath,
    type: ACCOUNTING_TRANSACTION_ENTITY_TYPES.OTHER,
  },
  {
    route: ROUTES.accounting.transactions.qrpay.absolutePath,
    type: ACCOUNTING_TRANSACTION_ENTITY_TYPES.QR_PAY,
  },
  {
    route: ROUTES.accounting.transactions.payroll.absolutePath,
    type: ACCOUNTING_TRANSACTION_ENTITY_TYPES.PAYROLL,
  },
];

export default function AccountingHeader({ sectionTitleText, entity = "" }) {
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const isUcsv = accountingSoftware === ACCOUNTING_SOFTWARES.UNIVERSAL_CSV;

  const [searchParams, setSearchParams] = useSearchParams();

  const syncAttemptSliderOpen =
    searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.billPay.sync.id) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.billPay.sync.showAll);

  const showBackButton = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.billPay.sync.id
  );

  const openSlider = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.billPay.sync.showAll,
      true
    );
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.billPay.sync.entityType,
      entity
    );
    setSearchParams(searchParams);
  };
  const closeSlider = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.billPay.sync.id);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.billPay.sync.showAll);
    setSearchParams(searchParams);
  };

  const location = useLocation();
  const type =
    ROUTE_TYPE_PAIRS.find(({ route }) => location.pathname.startsWith(route))
      ?.type ?? "";

  const [showButton, setShowButton] = useState(false);

  const dispatch = useDispatch();
  const lastSyncAttempt = useSelector(lastSyncAttemptSelector);
  const fetchingLastSyncAttempt = useSelector(isFetchingSyncAttemptsSelector);
  const integratedAccountingSoftware =
    useSelector(clientSelector)?.accountingIntegrationSoftware;

  useEffect(() => {
    dispatch(
      fetchSyncAttempts({
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        transaction_type: entity,
      })
    );
  }, []);

  useEffect(() => {
    if (lastSyncAttempt && integratedAccountingSoftware) {
      setShowButton(true);
    }
  }, [lastSyncAttempt]);

  return (
    <div className="flex items-end justify-between pb-8">
      <div className="text-sm font-semibold grow text-neutral-500">
        <Text translationKey="accounting.title" />
        &nbsp;/&nbsp;
        <Text translationKey="accounting.transactions.title" />
        <p className="text-4xl font-extrabold text-neutral-800">
          <Text translationKey={sectionTitleText} />
        </p>
      </div>
      {fetchingLastSyncAttempt ? (
        <div className="flex gap-6 items-center w-[514px] h-[56px]">
          <LastSyncedOnLoader />
          <LoaderSkeleton size={[56, 200]} />
        </div>
      ) : (
        showButton &&
        lastSyncAttempt &&
        !isUcsv && (
          <div className="flex items-center gap-6">
            <LastSyncedOn
              date={lastSyncAttempt?.createdAtDate}
              time={lastSyncAttempt?.createdAtTime}
              software={
                ACCOUNTING_SOFTWARES_ICONS[lastSyncAttempt?.accountingSlug]
              }
            />
            <Button
              label="accounting.transactions.syncHistory"
              classes="w-[136px] h-11 text-btn-lg font-medium"
              onClick={openSlider}
              type="default"
              variant="tertiary"
              // TODO: spinner and disabled state remaining
            />
          </div>
        )
      )}
    </div>
  );
}

AccountingHeader.propTypes = {
  sectionTitleText: PropTypes.string,
  entity: PropTypes.string,
};
