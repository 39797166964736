import PropTypes from "prop-types";

import Button from "@/components/core/Button";

export default function EmptyCustomFields({ createField }) {
  return (
    <div className="flex flex-col gap-4 items-center justify-center h-full">
      <span className="text-lg font-semibold">No custom fields yet! </span>
      <span className="text-sm text-neutral-500 text-center">
        You have not created any custom fields yet. Capture business-specific
        information through the use of custom field.
      </span>
      {/* <Button
        preIcon="Memo"
        label="Create custom field"
        onClick={createField}
      /> */}
    </div>
  );
}

EmptyCustomFields.propTypes = {
  createField: PropTypes.func,
};
