import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchCategories } from "@/store/reducers/categories";
import { fetchClaimPolicy } from "@/store/reducers/reimbursement";

import { categoriesListSelector } from "@/store/selectors/categories";
import { claimPolicyEnabledSelector } from "@/store/selectors/client";
import { claimPolicySelector } from "@/store/selectors/reimbursement";

import Alert from "@/components/core/Alert";
import Badge from "@/components/core/Badge";
import Text from "@/components/core/Text";

import AddCompanyClaimPolicyCategories from "@/components/Reimbursement/Settings/Sliders/ClaimPolicy/AddCompanyClaimPolicyCategories";
import CompanyClaimPolicyCategories from "@/components/Reimbursement/Settings/Sliders/ClaimPolicy/CompanyClaimPolicyCategories";
import CategoriesCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/CategoriesCell";
import ClaimPolicyLimitDummy from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ClaimPolicyLimitDummy";
import LimitCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/LimitCell";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE } from "@/constants/reimbursement";

/**
 * This function returns an object containing the table heading, row and cell classes, and information
 * for rendering an empty data state in the claim policy table.

 * @param {string} params - The parameters to determine the configuration variations. Possible values are "add", "preview", or "edit".
 */

export const getReimbursementSettingsClaimPolicy = (params) => {
  return {
    tableHeading: [
      {
        id: "1",
        title: "Category",

        classes: "text-left font-normal p-2 text-sm",
        cellComponent: CategoriesCell,
      },
      {
        id: "2",
        title: "Limit",
        classes: "text-right font-normal p-2 text-sm",
        cellComponent: params === "add" ? LimitCell : ClaimPolicyLimitDummy,
      },
    ].filter((item) => item),
    tableHeadingRowClasses: "  text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses: " text-center cursor-pointer text-neutral-800",
    emptyDataTitle:
      "reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.emptyDataTitle",
    emptyDataDescription:
      "reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.emptyDataDescription",
  };
};
export const ClaimPolicy = ({ custom }) => {
  const dispatch = useDispatch();
  const claimPolicyData = useSelector(claimPolicySelector);
  const [searchParam, setSearchParams] = useSearchParams();
  const [dropdownCategoryName, setDropdownCategoryName] = useState([]);
  const categoriesList = useSelector(categoriesListSelector);
  const claimPolicyEnabled = useSelector(claimPolicyEnabledSelector);

  const isDepartment =
    searchParam.get(SLIDERS_SEARCH_PARAMS.company.department.id) ||
    searchParam.get(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.departmentID
    ) ||
    searchParam.get(SLIDERS_SEARCH_PARAMS.company.department.add);

  const projectOrDepartmentId =
    searchParam.get(SLIDERS_SEARCH_PARAMS.company.department.id) ||
    searchParam.get(SLIDERS_SEARCH_PARAMS.company.project.id) ||
    searchParam.get(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.projectID
    ) ||
    searchParam.get(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.departmentID
    );

  const newDepartmentOrProject = searchParam.get(
    SLIDERS_SEARCH_PARAMS.company.department.add
  );

  useEffect(() => {
    if (!claimPolicyData) return;

    const existingIds = claimPolicyData?.claimPolicyCategoryLimits?.map(
      (item) => item.categoryId
    );
    setDropdownCategoryName(
      categoriesList.filter((item) => existingIds.includes(item.id))
    );
  }, [categoriesList, claimPolicyData]);

  const [componentKey, setComponentKey] = useState(
    searchParam.get(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.claimPolicy
    )
  );

  useEffect(() => {
    const params = { project_id: projectOrDepartmentId };
    if (policyEnabled) {
      dispatch(fetchClaimPolicy(params));
      dispatch(fetchCategories());
    }
  }, []);
  useEffect(() => {
    const allSearchParams = searchParam.getAll(
      custom
        ? SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls
            .claimPolicy
        : SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls
            .claimPolicy
    );

    const key = allSearchParams[allSearchParams.length - 1];
    setComponentKey(key);
  }, [searchParam]);

  const searchParamsSetters = (type) => {
    searchParam.delete(
      SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.claimPolicy
    );
    let paramValue = null;
    switch (type) {
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.ADD:
        paramValue = REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.ADD;
        break;
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.PREVIEW:
        paramValue = REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.PREVIEW;
        break;
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.EDIT:
        paramValue = REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.EDIT;
        break;
      case REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.CUSTOM:
        paramValue = REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.CUSTOM;
        break;
      default:
        break;
    }
    if (paramValue !== null) {
      searchParam.append(
        SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.claimPolicy,
        paramValue
      );
      setSearchParams(searchParam);
    }
  };
  const addCategoriesHandler = () => {
    searchParamsSetters(REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.ADD);
  };
  const handleDiscard = () => {
    searchParamsSetters(REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.PREVIEW);
  };
  const onEditPolicyHandler = () => {
    searchParamsSetters(REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.EDIT);
  };

  const onCustomisePolicyHandler = () => {
    searchParamsSetters(REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.CUSTOM);
  };

  const getAddOrPreviewClaimPolicyCategoryComponent = () => {
    if (
      componentKey === REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.ADD ||
      componentKey === REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.EDIT ||
      componentKey === REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.CUSTOM
    ) {
      return (
        <AddCompanyClaimPolicyCategories
          categoriesList={categoriesList}
          dropdownCategoryName={dropdownCategoryName}
          getReimbursementSettingsClaimPolicy={
            getReimbursementSettingsClaimPolicy
          }
          handleDiscard={handleDiscard}
          claimPolicyData={claimPolicyData}
          setDropdownCategoryName={setDropdownCategoryName}
          projectDepartmentId={projectOrDepartmentId}
          customiseClaim={
            componentKey === REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.CUSTOM
          }
        />
      );
    }
    if (componentKey === REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.PREVIEW) {
      return (
        <CompanyClaimPolicyCategories
          searchParams={searchParam}
          setSearchParams={setSearchParams}
          addCategoriesHandler={addCategoriesHandler}
          dropdownCategoryName={dropdownCategoryName}
          getReimbursementSettingsClaimPolicy={
            getReimbursementSettingsClaimPolicy
          }
          claimPolicyData={claimPolicyData}
          onEditPolicyHandler={onEditPolicyHandler}
          onCustomisePolicyHandler={onCustomisePolicyHandler}
        />
      );
    }

    return null;
  };
  const policyEnabled = claimPolicyEnabled;

  const ref = useLeftHeaderTitle({
    title: `settings.sliders.claimPolicies.${
      claimPolicyData?.default ? "companyClaimPolicy.title" : "title"
    }`,
  });

  return (
    <div className="slider-content-container">
      <div className="slider-content-core">
        <div className="flex flex-col gap-1 ">
          <div className="flex items-center" ref={ref}>
            <Text
              refProp={ref}
              classes="text-2xl font-semibold mr-3"
              translationKey={`settings.sliders.claimPolicies.${
                claimPolicyData?.default ? "companyClaimPolicy.title" : "title"
              }`}
            />
            {projectOrDepartmentId && claimPolicyEnabled ? (
              <Badge
                variant={claimPolicyData?.default ? "neutral" : "primary"}
                translationKey={
                  claimPolicyData?.default ? "common.company" : "common.custom"
                }
                classes="w-13"
              />
            ) : null}
          </div>
          <Text
            classes="text-sm text-neutral-500"
            translationKey="settings.sliders.claimPolicies.companyClaimPolicy.description"
          />
        </div>
        {/* Title is common in both AddCompanyClaimPolicyCategories and CompanyClaimPolicyCategories. so removed from the child component  */}
        {policyEnabled ? (
          getAddOrPreviewClaimPolicyCategoryComponent()
        ) : (
          <Alert
            wrapperDivClass="justify-start"
            iconClasses="w-6 h-6 text-neutral-500"
            variant="warning"
            title="settings.spendControls.claimPolicy.alertDisableTitle"
            description={`settings.spendControls.claimPolicy.${
              isDepartment
                ? "alertDisableDescriptionDepartment"
                : "alertDisableDescriptionProject"
            }`}
            classes="my-9"
          />
        )}
      </div>
    </div>
  );
};

ClaimPolicy.propTypes = {
  custom: PropTypes.string,
};
