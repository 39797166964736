import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";

import Payroll from "@/pages/payroll";
import ExchangeRate from "@/pages/payroll/exchange-rate";
import PayrollEmployees from "@/pages/payroll/payroll-employees";
import { ROUTES } from "@/constants/routes";
import PayrollSalaryPayments from "@/routes/payroll/salary-payments";
import PayrollSettingsRoutes from "@/routes/payroll/settings";

export default function PayrollRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Payroll />} />
      </Route>
      <Route
        path={`${ROUTES.payroll.salaryPayment.pathName}/*`}
        element={<PayrollSalaryPayments />}
      />

      <Route
        path={ROUTES.payroll.payrollEmployees.pathName}
        element={<PayrollEmployees />}
      />
      <Route
        path={`${ROUTES.payroll.settings.pathName}/*`}
        element={<PayrollSettingsRoutes />}
      />
      <Route
        path={ROUTES.payroll.exchangeRate.pathName}
        element={<ExchangeRate />}
      />
    </Routes>
  );
}
