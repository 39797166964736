import PropTypes from "prop-types";
import React from "react";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import Text from "@/components/core/Text";

export default function FlagExpenseAndQRPayHeader({
  title,
  description,
  descriptionTranslationProps,
}) {
  const ref = useLeftHeaderTitle({ title });
  return (
    <div className="flex flex-col gap-1">
      <Text
        refProp={ref}
        classes="text-3xl font-bold text-neutral-800"
        translationKey={title}
      />
      <Text
        classes="text-sm font-medium text-neutral-500"
        translationKey={description}
        translationProps={descriptionTranslationProps}
      />
    </div>
  );
}
FlagExpenseAndQRPayHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionTranslationProps: PropTypes.object,
};
