import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchAndSelectAccountingTransaction,
  setUploadedReceipts,
  updateAccountingData,
} from "@/store/reducers/accounting_transactions";
import { deleteAttachment, downloadAttachment } from "@/store/reducers/app";

import { selectedAccountingTransactionSelector } from "@/store/selectors/accounting_transactions";
import {
  accountingEnabledSelector,
  claimAmountEditEnabledSelector,
} from "@/store/selectors/client";

import Alert from "@/components/core/Alert";
import Tabs from "@/components/core/Tabs";

import AccountingSliderSubmitButton from "@/components/Accounting/Transactions/common/Slider/AccountingSliderSubmitButton";
import ApprovalSliderHeader from "@/components/Reimbursement/ApprovalSlider/ApprovalSliderHeader";
import { CONST_TYPE_REIMBURSEMENT } from "@/utils/constants/reimbursement";
import {
  APPROVAL_OR_PAYMENT_STATUS_COLOR,
  APPROVAL_OR_PAYMENT_STATUS_TEXT,
} from "@/utils/constants/payments";
import { amountToCurrency, createFileFormData } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
} from "@/constants/accounting";
import { TABS } from "@/constants/common";

import ActivityTab from "../../BillPay/Slider/ActivityTab";
import { accountingActionHandler } from "../../common/util";
import ReimbursementTransactionOverviewTable from "./ReimbursementTransactionOverviewTab";

export default function AccountingTransactionReimbursementSlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const accountingId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.reimbursement.id
  );

  const accountingEnabled = useSelector(accountingEnabledSelector);
  const claimAmountEditEnabled = useSelector(claimAmountEditEnabledSelector);
  const dispatch = useDispatch();
  const reimbursement = useSelector(selectedAccountingTransactionSelector);
  const [files, setFiles] = useState([]);
  const [memo, setMemo] = useState("");
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const { t } = useTranslation();

  const getLeftHeader = () => {
    switch (reimbursement?.type) {
      case CONST_TYPE_REIMBURSEMENT.mileage:
        return {
          title: t("reimbursement.approvals.sliderHeadings.distanceTravelled", {
            distanceTravelled: reimbursement?.distanceTravelled,
          }),
          icon: "Location",
          iconStyle:
            "bg-warning-50 text-success-500 p-2 border-[1px] border-success-100 w-10 h-10 flex justify-center text-sm  items-center rounded-full",
        };
      case CONST_TYPE_REIMBURSEMENT.outOfPocket:
        return {
          title: reimbursement?.merchant?.name,
          img: reimbursement?.createdBy?.colorCode,
        };
      default:
        break;
    }
  };

  const ref = useLeftHeaderTitle(getLeftHeader(), { root: null }, true);

  useEffect(() => {
    dispatch(
      fetchAndSelectAccountingTransaction({
        accountingId,
      })
    );
  }, []);

  useEffect(() => {
    setFiles(reimbursement?.receipts ?? []);
    setMemo(reimbursement?.memo);
  }, [reimbursement]);

  const fileUploadHandler = (latestFileArray) => {
    const filesToUpload = latestFileArray?.filter((item) => {
      return item instanceof File && item.type !== "image/svg+xml";
    });

    if (filesToUpload?.length === 0) return;

    const formPayload = createFileFormData(filesToUpload, "receipts[]");

    dispatch(
      updateAccountingData({
        accounting_id: reimbursement?.accountingId,
        payload: formPayload,
        onSuccess: (data) => {
          dispatch(setUploadedReceipts({ receipts: data?.receipts }));
        },
      })
    );
  };

  const handleUpdateMemo = (note) => {
    dispatch(
      updateAccountingData({
        accounting_id: reimbursement?.accountingId,
        payload: { note },
      })
    );
  };

  const primaryAction = {
    handler: (index) => {
      if (files[index]?.id) {
        dispatch(
          deleteAttachment({
            id: files[index]?.id,
            onSuccess: () =>
              dispatch(
                setUploadedReceipts({
                  receipts: files.filter((_, i) => i !== index),
                })
              ),
          })
        );
      }
    },
    label: "misc.delete",
    icon: "Delete",
    iconClasses: "text-danger-600 bg-danger-50",
  };

  const secondaryAction = {
    handler: (index) => {
      const file = files[index];
      if (file instanceof File) return;
      dispatch(
        downloadAttachment({
          file,
          id: file?.id,
        })
      );
    },
    label: "misc.download",
    icon: "Download",
    iconClasses: "!text-primary-500 !bg-primary-50",
  };

  return (
    reimbursement && (
      <>
        <div ref={ref} className="slider-content-core scroll-smooth">
          <div className="mt-5 mb-6">
            <ApprovalSliderHeader
              header={amountToCurrency(
                claimAmountEditEnabled
                  ? reimbursement?.amountToBePaid
                  : reimbursement?.amount?.value,
                reimbursement?.amount?.currency
              )}
              badgeText={
                APPROVAL_OR_PAYMENT_STATUS_TEXT[
                  reimbursement?.transactionStatus ?? reimbursement?.status
                ]
              }
              badgeColor={
                APPROVAL_OR_PAYMENT_STATUS_COLOR[
                  reimbursement?.transactionStatus ?? reimbursement?.status
                ]
              }
              submitedDate={reimbursement?.submissionDate}
            />
          </div>
          {reimbursement?.accountingStatus ===
          ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED ? (
            <div className="mt-6">
              <Alert
                wrapperDivClass="justify-start"
                iconClasses="w-6 h-6 text-neutral-500"
                variant="warning"
                title={reimbursement?.accountingFailReason}
              />
            </div>
          ) : null}
          <div className="mt-5">
            <Tabs
              items={TABS}
              selectedTab={selectedTab?.key}
              setCurrentTab={setSelectedTab}
              mode
            />
          </div>
          <div className="mt-8" id="tabContent">
            {selectedTab.key === 1 ? (
              <ReimbursementTransactionOverviewTable
                reimbursement={reimbursement}
                files={files}
                fileUploadHandler={fileUploadHandler}
                memo={memo}
                setMemo={setMemo}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                handleUpdateMemo={handleUpdateMemo}
              />
            ) : (
              <ActivityTab reimbursement={reimbursement} />
            )}
          </div>
        </div>

        {accountingEnabled ? (
          <div className="slider-footer">
            <div className="flex items-center justify-end h-full px-6 py-4 bg-white">
              <AccountingSliderSubmitButton
                accountingStatus={reimbursement?.accountingStatus}
                accountingId={reimbursement?.accountingId}
                syncable={reimbursement?.syncable}
                verifiable={reimbursement?.verifiable}
                onClick={accountingActionHandler}
                page={ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT}
                accountingCtas={reimbursement?.accountingCtas}
                notActionableReason={reimbursement?.notActionableReason}
              />
            </div>
          </div>
        ) : null}
      </>
    )
  );
}
