import LoaderSkeleton from ".";

export default {
  title: "Components/Core/LoaderSkeleton",
  component: LoaderSkeleton,
};

const Template = (args) => (
  <div>
    <LoaderSkeleton {...args} />
  </div>
);

export const val = Template.bind({});
val.args = {
  type: "rectangle",
  inline: true,
  size: "md",
  count: 1,
  borderRadius: "5px",
};
