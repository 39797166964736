import React from "react";
import { useSearchParams } from "react-router-dom";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { SECURITY_SETTINGS_VERIFICATION_MODES } from "@/constants/user";

function TwoStepVerification({
  verificationConfig,
  showRadioButton = false,
  selectedRadioValueId,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const openGoogleAuthSetupSlider = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.security.setUpGoogleAuth, true);
    setSearchParams(searchParams);
  };
  return (
    <div className="p-4 border rounded-lg border-neutral-200 ">
      <div>
        {verificationConfig.map((item, index) => (
          <div
            className={`flex gap-4 ${
              index !== verificationConfig.length - 1 ? "mb-6" : ""
            }`}
            key={`${item.verificationMode}-${index}`}
          >
            {showRadioButton ? (
              <Radio
                handleChange={() => item?.handleChange(item?.key)}
                name={item.key}
                value={item?.key}
                isChecked={selectedRadioValueId === item?.key}
              />
            ) : (
              <Icon name={item.icon} className="w-9 h-9 shrink-0" />
            )}
            <div>
              <div className="flex items-center gap-4">
                <Text
                  translationKey={item.verificationMode}
                  classes="text-base font-medium"
                />
                {item?.isDefault ? (
                  <Badge
                    translationKey={item.isDefault ? "Default" : ""}
                    classes="border-neutral-200 px-2 text-neutral-600 bg-neutral-50"
                  />
                ) : null}
              </div>
              <Text
                translationKey={item.description}
                classes="text-sm text-neutral-500"
              />
              {selectedRadioValueId ===
                SECURITY_SETTINGS_VERIFICATION_MODES.GAUTH &&
              item?.key === SECURITY_SETTINGS_VERIFICATION_MODES.GAUTH ? (
                <div className="pt-4">
                  <Button
                    label="security.setUpAuthenticator"
                    variant="tertiary"
                    size="sm"
                    classes="w-fit px-5 "
                    labelStyleClasses="font-semibold"
                    onClick={openGoogleAuthSetupSlider}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TwoStepVerification;
