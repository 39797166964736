import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { fetchAndSelectAccountingTransaction } from "@/store/reducers/accounting_transactions";
import { addComments, fetchComments } from "@/store/reducers/comments";
import {
  fetchShallowDepartments,
  fetchShallowLocations,
} from "@/store/reducers/company";
import {
  fetchQrPaymentSplit,
  fetchSelectedQrPaymentActivity,
  remindQrPayment,
  updateQrPayment,
  uploadQrPaymentReceipts,
} from "@/store/reducers/qr-payments";
import { fetchTags } from "@/store/reducers/tags";

import { selectedAccountingTransactionSelector } from "@/store/selectors/accounting_transactions";
import { categoriesListSelector } from "@/store/selectors/categories";
import { accountingEnabledSelector } from "@/store/selectors/client";
import {
  commentsHasMoreSelector,
  commentsListSelector,
} from "@/store/selectors/comments";
import { submissionPolicyObjectSelector } from "@/store/selectors/policy";
import {
  isFetchingQrPaymentActivitySelector,
  isFetchingQrPayments,
  qrPaymentSplitSelector,
  selectedQrPaymentIsApprovedSelector,
} from "@/store/selectors/qr-payments";
import { userSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Tabs from "@/components/core/Tabs";

import { ExpenseAndQRPayHeaderJsx } from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayHeader";
import ExpenseAndQRPayHistory from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayHistory";
import ExpenseAndQRPayOverviewTab from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayOverviewTab";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import { COMMENTS_PAGINATION_LIMIT_PER_REQUEST } from "@/utils/constants/payments";
import { createFileFormData } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_TYPES,
} from "@/constants/accounting";
import {
  DECLINED,
  DECLINE_CODES,
  DECLINE_EXPENSE_ALERT_CTA,
  DECLINE_SLIDER_ALERT,
  HISTORY,
  OVERVIEW,
  TABS,
} from "@/constants/expense";

import AccountingSliderSubmitButton from "../../common/Slider/AccountingSliderSubmitButton";
import { accountingActionHandler } from "../../common/util";

export default function AccountingTransactionQrPaymentSlider({
  isAccountingSlider = true,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const qrPayment = useSelector(selectedAccountingTransactionSelector);
  const categories = useSelector(categoriesListSelector);
  const isFetchingQrPayment = useSelector(isFetchingQrPayments);
  const splitQrPayment = useSelector(qrPaymentSplitSelector);
  const submissionPolicySelector = useSelector(submissionPolicyObjectSelector);
  const submissionPolicy = Array.isArray(submissionPolicySelector)
    ? submissionPolicySelector?.find(
        (_) => _?.id === qrPayment?.submissionPolicyId
      )
    : [];

  const isFetching = useSelector(isFetchingQrPaymentActivitySelector);
  const isApproved = useSelector(selectedQrPaymentIsApprovedSelector);
  const commentsList = useSelector(commentsListSelector);
  const commentHasMore = useSelector(commentsHasMoreSelector);
  const currentUser = useSelector(userSelector);
  const accountingEnabled = useSelector(accountingEnabledSelector);

  const qrPaymentAccountingId = parseInt(
    searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.qrpay.id),
    10
  );
  const qrPaymentId = qrPayment?.id;

  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [files, setFiles] = useState([]);
  const [memo, setMemo] = useState("");
  const isDeclineQrPayment = qrPayment?.transactionType === DECLINED;

  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    dispatch(fetchShallowDepartments({ shallow: true }));
    dispatch(fetchShallowLocations());
    dispatch(
      fetchAndSelectAccountingTransaction({
        accountingId: qrPaymentAccountingId,
      })
    );
  }, []);

  useEffect(() => {
    setFiles(qrPayment?.receipts ?? []);
    setMemo(qrPayment?.memo);
  }, [qrPayment]);

  useEffect(() => {
    if (qrPayment?.hasComments)
      dispatch(
        fetchComments({
          type: ACCOUNTING_TRANSACTION_PAGES.QR_PAYMENTS,
          id: qrPaymentId,
          page: 1,
          limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
        })
      );
  }, [qrPaymentId, qrPayment?.hasComments]);

  const loadMoreComments = () => {
    setPageNum((prev) => prev + 1);
    dispatch(
      fetchComments({
        type: ACCOUNTING_TRANSACTION_PAGES.QR_PAYMENTS,
        id: qrPaymentId,
        page: pageNum + 1,
        limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
      })
    );
  };

  useEffect(() => {
    if (qrPaymentId && !Number.isNaN(qrPaymentId)) {
      dispatch(
        fetchQrPaymentSplit({
          qrPaymentId,
        })
      );
    }
  }, [qrPaymentId]);

  const fileUploadHandler = (latestFileArray) => {
    const filesToUpload = latestFileArray?.filter((item) => {
      return item instanceof File && item.type !== "image/svg+xml";
    });
    setFiles(latestFileArray);
    if (filesToUpload?.length === 0) return;

    const formPayload = createFileFormData(filesToUpload, "receipts[]");

    dispatch(uploadQrPaymentReceipts({ qrPaymentId, payload: formPayload }));
  };

  const primaryAction = {
    handler: (index) => {
      if (files[index]?.id) {
        // dispatch(deleteQrPaymentAttachment({ id: files[index]?.id }));
      }
    },
    label: "misc.delete",
    icon: "Delete",
    iconClasses: "text-danger-600 bg-danger-50",
  };

  const secondaryAction = {
    handler: (index) => {
      const file = files[index];
      // if (file instanceof File) return;
      // dispatch(
      //   downloadQrPaymentAttachment({
      //     file,
      //     receiptId: file?.id,
      //   })
      // );
    },
    label: "misc.download",
    icon: "Download",
    iconClasses: "!text-primary-500 !bg-primary-50", // some issue with SVG, using !
  };

  const initData = () => {
    dispatch(
      fetchAndSelectAccountingTransaction({
        qrPaymentAccountingId,
      })
    );
    dispatch(
      fetchQrPaymentSplit({
        qrPaymentId,
      })
    );
    dispatch(fetchSelectedQrPaymentActivity({ qrPaymentId }));
  };

  const handleUpdateQrPayment = (payload) => {
    if (qrPaymentId)
      dispatch(
        updateQrPayment({
          qrPaymentId,
          payload,
          onSuccess: () => {
            initData();
            if (qrPayment?.hasComments)
              dispatch(
                fetchComments({
                  type: QRPAY_EXPENSES_CONTEXT.QRPAY,
                  id: qrPaymentId,
                  page: 1,
                  limit: 5,
                })
              );
          },
        })
      );
  };

  const remindPeople = (key, qrPayment_id) => {
    dispatch(remindQrPayment({ [key]: qrPayment_id }));
  };

  const createNewComment = (val) => {
    dispatch(
      addComments({
        payload: {
          owner_type: QRPAY_EXPENSES_CONTEXT.QRPAY,
          owner_id: qrPayment?.id,
          comment: val?.comment,
        },
      })
    );
  };

  const getTransalationPropKey = (key) => {
    switch (key) {
      case DECLINE_CODES.FROZEN_CARD:
      case DECLINE_CODES.CARD_FROZEN_BY_EMPLOYEE:
        return {
          date: qrPayment?.freezedAt,
          role: qrPayment?.walletHolder?.roles[0],
          firstName: qrPayment?.walletHolder?.firstName,
        };
      case DECLINE_CODES.AUTO_BLOCKED:
      case DECLINE_CODES.BLOCKED_CARD:
        return {
          date: qrPayment?.blockedAt,
          role: qrPayment?.walletHolder?.roles[0],
          firstName: qrPayment?.walletHolder?.firstName,
        };

      default:
        return {};
    }
  };
  return (
    <>
      <div className="slider-content-core">
        {isFetchingQrPayment && <div>Loading...</div>}
        {!isFetchingQrPayment && qrPayment ? (
          <div className="pb-6 mb-6">
            <div className="flex items-center justify-between">
              <ExpenseAndQRPayHeaderJsx
                isExpenseStatusHidden={isDeclineQrPayment} // for declined transactionType
                exchangeRate={qrPayment?.exchangeRate}
                transactionAmount={qrPayment?.transactionAmount}
                transactionCurrencyCode={qrPayment?.transactionCurrencyCode}
                settlementStatus={qrPayment?.settlementStatus}
                img={qrPayment?.vendor?.avatarUrl}
                colorCode={qrPayment?.vendor?.colorCode}
                name={qrPayment?.walletHolder?.displayName}
                amount={qrPayment?.amount}
                currency={qrPayment?.currency}
                transactionStatus={qrPayment?.transactionStatus}
                updatedAt={qrPayment.updatedAt}
                merchantName={qrPayment.merchant.name}
              />
            </div>
            {isDeclineQrPayment ? (
              <div className="mt-6">
                <Alert
                  wrapperDivClass="justify-start"
                  iconClasses="w-6 h-6 text-neutral-500"
                  variant="warning"
                  title={DECLINE_SLIDER_ALERT[qrPayment?.declineCode]?.title}
                  description={
                    DECLINE_SLIDER_ALERT[qrPayment?.declineCode]?.message
                  }
                  descriptionTransalationProp={getTransalationPropKey(
                    qrPayment?.declineCode
                  )}
                  primaryAction={{
                    label:
                      DECLINE_EXPENSE_ALERT_CTA[qrPayment?.declineCode] ?? "",
                    callback: () => {},
                  }}
                />
              </div>
            ) : null}
            <div className="mt-5">
              <Tabs
                items={TABS}
                selectedTab={selectedTab?.key}
                setCurrentTab={setSelectedTab}
                mode
              />
            </div>
            {selectedTab.name === HISTORY && (
              <div className="mb-5">
                <ExpenseAndQRPayHistory
                  expenseId={qrPaymentId}
                  isFetching={isFetching}
                />
              </div>
            )}
            {selectedTab.name === OVERVIEW && (
              <ExpenseAndQRPayOverviewTab
                isExpenseStatusHidden={isDeclineQrPayment} // for declined transactionType
                transaction={qrPayment}
                page={QRPAY_EXPENSES_CONTEXT.QRPAY}
                memo={memo}
                isAccountingSlider={isAccountingSlider}
                handleMemoChange={setMemo}
                updateExpense={handleUpdateQrPayment}
                categories={categories}
                expenseId={qrPaymentId}
                splitExpenses={splitQrPayment}
                remindPeople={remindPeople}
                submissionPolicy={submissionPolicy}
                commentsList={commentsList}
                isApproved={isApproved}
                files={files?.length ? files : []}
                fileUploadHandler={fileUploadHandler}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                createNewComment={createNewComment}
                currentUser={currentUser}
                showLoadMoreCta={commentHasMore}
                loadMoreComments={loadMoreComments}
              />
            )}
          </div>
        ) : null}
      </div>

      {accountingEnabled ? (
        <div className="slider-footer">
          <div className="flex items-center h-full px-6 py-4 bg-white">
            <AccountingSliderSubmitButton
              verifiable={qrPayment?.verifiable}
              syncable={qrPayment?.syncable}
              accountingStatus={qrPayment?.accountingStatus}
              onClick={accountingActionHandler}
              accountingId={qrPayment?.accountingId}
              page={ACCOUNTING_TRANSACTION_TYPES.CREDIT_TYPE}
              accountingCtas={qrPayment?.accountingCtas}
              notActionableReason={qrPayment?.notActionableReason}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

AccountingTransactionQrPaymentSlider.propTypes = {
  isAccountingSlider: PropTypes.bool,
};
