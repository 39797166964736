import PropTypes from "prop-types";

import Checkbox from "@/components/core/Checkbox";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function ReportNameCell({ val }) {
  const id = `report-name-cell-${val?.id}`;
  return (
    <div
      id={`report-name-cell-${val?.id}`}
      className="flex items-center w-full gap-3 truncate "
    >
      {val?.showReportNameCheckbox ? (
        <Checkbox
          disabled={val?.disabled ?? false}
          checked={val?.checkedValue ?? false}
          onClickHandler={val?.onCheckboxClick ?? (() => {})}
        />
      ) : null}

      <Text
        translationKey={val?.name}
        id={`report-name-cell-${val?.id}`}
        showTooltip
        toolTipDirection="right"
      />
    </div>
  );
}

ReportNameCell.propTypes = {
  val: PropTypes.object,
};
