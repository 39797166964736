import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

function LinkedToComponent({
  linkedToName,
  linkedToType,
  linkedToNameClasses,
  linkedToTypeClasses,
}) {
  return linkedToName || linkedToType ? (
    <div className="flex flex-col">
      {linkedToName ? (
        <Text
          translationKey={linkedToName}
          showTooltip={linkedToName?.length > 10}
          tooltipClasses="w-16"
          classes="text-sm text-neutral-800 font-semibold capitalize"
        />
      ) : (
        "-"
      )}

      <span className="text-xs font-medium capitalize text-neutral-300">
        {linkedToType ? (
          <Text
            translationKey={linkedToType}
            showTooltip={linkedToType?.length > 10}
            tooltipClasses="w-10"
            classes="text-xs font-medium capitalize text-neutral-500"
          />
        ) : (
          "-"
        )}
      </span>
    </div>
  ) : (
    <div className="capitalize">-</div>
  );
}

export default LinkedToComponent;
LinkedToComponent.propTypes = {
  linkedToName: PropTypes.string,
  linkedToNameClasses: PropTypes.string,
  linkedToType: PropTypes.string,
  linkedToTypeClasses: PropTypes.string,
};
