import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

import DeltaPercentagePill from "@/components/common/DeltaPercentagePill";
import { amountToCurrency, removeFloatingZero } from "@/utils/common";

import { DELTA_PERCENTAGE_MODE, REVIEW_LABELS } from "@/constants/common";

function ReviewAmount({
  oldValue,
  oldCurrency,
  newCurrency,
  newValue,
  newContainerClasses,
  oldContainerClasses,
  showPercentagePill = true,
  oldValueTitle,
  newValueTitle,
}) {
  return (
    <div className="flex items-center gap-2">
      <div
        className={`text-xs font-semibold text-neutral-400 ${oldContainerClasses}`}
      >
        <div>
          <Text translationKey={oldValueTitle ?? REVIEW_LABELS.AMOUNT.OLD} />
        </div>
        <div className="text-lg text-neutral-500">
          {amountToCurrency(oldValue, oldCurrency)}
        </div>
      </div>
      <div
        className={`flex flex-col items-end text-xs font-semibold text-neutral-400 ${newContainerClasses}`}
      >
        <div>
          <Text translationKey={newValueTitle ?? REVIEW_LABELS.AMOUNT.NEW} />
        </div>

        <div className="flex items-center gap-2 text-lg text-neutral-800">
          {showPercentagePill ? (
            <DeltaPercentagePill
              value={removeFloatingZero(
                parseFloat(newValue - oldValue).toFixed(2)
              )}
              currency={newCurrency}
              mode={DELTA_PERCENTAGE_MODE.AMOUNT}
            />
          ) : null}

          {amountToCurrency(newValue, newCurrency)}
        </div>
      </div>
    </div>
  );
}

export default ReviewAmount;

ReviewAmount.propTypes = {
  oldValue: PropTypes.string,
  newValue: PropTypes.string,
  newContainerClasses: PropTypes.string,
  oldContainerClasses: PropTypes.string,
  oldCurrency: PropTypes.string,
  newCurrency: PropTypes.string,
  showPercentagePill: PropTypes.bool,
  oldValueTitle: PropTypes.string,
  newValueTitle: PropTypes.string,
};
