import PropTypes from "prop-types";
import React, { useRef } from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

/**
 * Functional component for rendering Getting Started content.

 * @param {string} props.title - The title for the Getting Started content.
 * @param {string} props.description - The description for the Getting Started content.
 * @param {string} props.icon - The name of the icon to be displayed.
 * @param {boolean} props.navigate - Indicates whether the Getting Started page can navigate to another page.
 * @param {boolean} props.colorType - Getting Started content color

 */
const GettingStartedList = ({
  title,
  description,
  titleClasses = "",
  descriptionClasses = "",
  icon,
  iconClasses = "",
  navigate,
  colorType,
  showDefaultIcon = false,
  handleChange = () => {},
  tooltipComponent,
  id,
  isCompleted,
  handleSkip,
}) => {
  const ref = useRef();
  return (
    <div
      onClick={(e) => {
        if (ref.current?.contains(e?.target)) handleSkip();
        else handleChange(id);
      }}
      className={`flex items-center justify-between w-full ${
        navigate ? "cursor-pointer" : ""
      }`}
    >
      <div
        id={`getting-started-list-key-${id}`}
        className="flex items-center gap-4"
      >
        {showDefaultIcon ? (
          <Icon name={icon} className={`w-10 h-10 p-6 ${iconClasses} `} />
        ) : (
          <div className="flex items-center justify-center w-10 h-10 rounded-full">
            <Icon
              name={icon}
              className={` w-9 h-9 p-3 ${colorType?.iconColor} ${iconClasses}`}
            />
          </div>
        )}

        <div className="flex flex-col">
          <div className="flex items-center">
            <Text
              translationKey={title}
              classes={`text-base font-semibold ${colorType?.titleColor} ${titleClasses}`}
            />
            {isCompleted ? (
              <Icon name="CheckCircle" className="ml-2 text-success-600" />
            ) : null}
          </div>
          <Text
            translationKey={description}
            classes={`text-sm font-medium ${colorType?.descriptionColor} ${descriptionClasses}`}
          />
        </div>
      </div>
      {tooltipComponent ? (
        <Tooltip direction="top" id={`getting-started-list-key-${id}`}>
          {typeof tooltipComponent === typeof "" ? (
            <Text translationKey={tooltipComponent} />
          ) : (
            tooltipComponent
          )}
        </Tooltip>
      ) : null}
      {navigate ? (
        <div>
          <div ref={ref} id={`skip-icon-${id}`}>
            <Icon name="Close" className="w-2.5 h-2.5 text-neutral-400" />
          </div>
          <Tooltip id={`skip-icon-${id}`} direction="bottom">
            <Text translationKey="myVolopay.skip" />
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};
GettingStartedList.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  titleClasses: PropTypes.string,
  descriptionClasses: PropTypes.string,
  icon: PropTypes.string,
  iconClasses: PropTypes.string,
  navigate: PropTypes.bool,
  colorType: PropTypes.object,
  showDefaultIcon: PropTypes.bool,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  tooltipComponent: PropTypes.any,
  isCompleted: PropTypes.bool,
  handleSkip: PropTypes.func,
};
export default GettingStartedList;
