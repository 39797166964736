import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";

import useStateResettable from "@/hooks/useStateResettable";

import CheckboxDropdownFooter from "@/components/core/CheckboxDropdown/CheckboxDropdownFooter";
import CheckboxDropdownGroup from "@/components/core/CheckboxDropdown/CheckboxDropdownGroup";
import PillsUpperSection from "@/components/core/CheckboxDropdown/PillsUpperSection";
import {
  __TOGGLE_MODE_GROUP_ENTRY,
  __TOGGLE_MODE_SINGLE_ENTRY,
} from "@/components/core/CheckboxDropdown/utils";
import { formatNames } from "@/utils/common";

import LoaderSkeleton from "../LoaderSkeleton";
import Text from "../Text";

/**
 * UI Assumptions - labels, sublabels, icons
 *
 * 1. Label for top level views (fast view, and flat view) - `dropdownLabelFastView`, `dropdownLabelFlatView`. Can be strings or JSX. Set to null to hide.
 * 2. Entry row label (in flat view), sublabel, icon is derived from `options` prop array
 * 3. Entry row label (in fast view groups), sublabel, icon is derived from `groupingFunction` prop
 * 4. Group row label, sublabel, icon is derived from `getHeaderNodeUI` prop.
 *      Why? Because, (on some pages/features) we need to show the number of selected entries in the group (which is dynamic)
 * 5. Group header UI (shown when group is in expanded state) is derived from `headerLabelsMap` prop
 * 6. Pill labels
 *   a. Individual pills - derived from `options` prop array
 *   b. Group pills - derived from `headerLabelsMap` prop
 * 7. Except `headerLabelsMap`, all other label and subLabels can be a string or JSX
 * 8. To set a whole entry as custom UI, provide JSX for `label` and set subLabel, icon to null. Only label JSX will be rendered
 * 9. For disabled checkbox - pass `disable:true` in the option that is disabled. A section with all disabled entries will become disabled automatically.
 *    - to force disabling a group (i.e. not all entries are disable in it, but you wish to disallow 'Select' all by disabling the checkbox)
 *       use the `getHeaderNodeUI` function and include `disable:true` for the relevant section
 * 10. If we want to hide the upper pills, which shows the selected options we use showPills as false
 *
 *
 * So, total UI determining props - `options`, `dropdownLabelFastView`, `dropdownLabelFlatView`, `groupingFunction`, `getHeaderNodeUI`, `headerLabelsMap`
 */

/**
 * @param {Array} options              flat array of objects
 * @param {Function} groupingFunction  takes an entry and returns an object { groupKey: String, label: String, subLabel: String, icon: String }
 * @param {String} label
 * @param {String} selectedIdsArray    initially selected ids
 * @param {String} handleSubmit        runs when 'done' is pressed
 * @param {String} triggerComponent    optional component, if you want a custom UI (instead of plain <Input />)
 * @param {String} tooltipParentClass  you have to give some class to parent and pass it to this prop in order to make it reposition itself whenever it goes outside of parent
 * @param {String} mode                "grouped"
 * @param {String} headerLabelsMap     header label when a section is expanded
 * @param {String} tooltipParentId    tooltipId is string which you can pass when not passing tooltipClasses
 * @param {String} getHeaderNodeUI     header entry
 * @param {String} dropdownLabelFastView  label for the fast view
 * @param {String} dropdownLabelFlatView  label for the flat view
 * @param {String} isLoading              are the `options` loading?
 * @param {String} loader                 UI to show if loading is true
 * @param {String} error                  to pass error to this compoent
 * @param {String} disabled               disable state for the whole component
 * @param {Boolean} supportExplicitControls   To handle state from outside. Used for adding explicit options in approval workflow
 * @param {String} noDataAvailableText   noData available text you can enter
 * @param {Boolean} saveOnExternalEvent if there is a delete function which needs saving of the selectedStuff
 */

export default function CheckboxDropdown({
  options,
  groupingFunction = () => [],
  label = "Select or search",
  selectedIdsArray,
  tooltipParentId,
  selectedGroupsArray = [],
  handleSubmit,
  triggerComponent = PillsUpperSection,
  tooltipParentClass = "slider-content-container",
  mode = null,
  headerLabelsMap,
  getHeaderNodeUI,
  dropdownLabelFastView = "Fast view",
  dropdownLabelFlatView = "Flat view",
  isLoading = false,
  loader = (
    <LoaderSkeleton size="lg" count={5} classes="px-5 gap-5" fullWidth />
  ),
  placeholder,
  insideForm = false,
  name,
  error = "",
  disabled = false,
  toggleCheckboxHandler,
  supportExplicitControls = false,
  labelExtraClasses,
  forciblyUpdateGroupedOptionsUI,
  hideLabelAfterSelect = false,
  showPills = true,
  noDataAvailableText = "misc.noDataAvailable",
  saveOnExternalEvent = false,
  hideDisplayLabel,
}) {
  const { t } = useTranslation();
  const [displayLabel, setDisplayLabel] = useState("");
  const [shouldDisplayLabel, toggleLabelDisplay] = useState(true);

  const [closedFromPill, setClosedFromPill] = useState(false);

  const groupsWithEntriesForUI = useMemo(() => {
    const groups = {};

    options?.forEach((option) => {
      const { id } = option; // example: userId, precautionary measure
      const intermediateRepresentation = groupingFunction(option, t);

      intermediateRepresentation?.forEach((optionInContextOfGroup) => {
        const { groupKey } = optionInContextOfGroup;

        const valueToAdd = {
          ...optionInContextOfGroup,
          id,
          checked: !!option.checked,
          disabled: !!option.disabled,
        };

        if (groups[groupKey]) groups[groupKey].push(valueToAdd);
        else groups[groupKey] = [valueToAdd];
      });
    }); // { groupKey1: [{label, subLabel, icon, id}, {label, subLabel, icon, id}], groupKey2: [{label, subLabel, icon, id}] }

    const fastView = {
      viewKey: "fast-view",
      isFlatView: false,
      level: 0,
      label: t(dropdownLabelFastView), // actually the group label
      entries: Object.entries(groups).map(([groupKey, entries]) => ({
        // this is a row in the fast view - 'Admins', 'Department Managers'
        level: 1,
        groupSelfKey: groupKey,
        disabled: entries.every((option) => option.disabled),
        checked: entries.every((option) => option.checked),
        // label, subLabel, icon, subLabelClasses provided by `headerLabelsMap` prop
        label: groupKey,
        entries,
      })),
    };

    const flatView = {
      // these are simple rows. Example: 'All users'
      viewKey: "flat-view",
      isFlatView: true,
      level: 0,
      label: t(dropdownLabelFlatView), // actually the group label
      entries: options?.map((option) => ({
        ...option,
        // checked: selected.includes(option.id), // omit, since it's already available in the state
        level: 1,
        label: option.label,
        entries: [],
      })),
    };

    return [fastView, flatView];
  }, [options, forciblyUpdateGroupedOptionsUI, selectedIdsArray]);

  const popupRef = useRef();

  const formatData = () => {
    return options?.map((option) => ({
      ...option,
      checked: selectedIdsArray?.includes(option.id),
    }));
  };

  const [list, setList] = useState(formatData());
  useEffect(() => {
    setList(formatData());
  }, [options, selectedIdsArray]);

  const createSelectStuffState = (_selectedIdsArray) => {
    const ids = new Set(_selectedIdsArray); // selected ids

    // groups
    const fastViewGroups = groupsWithEntriesForUI[0].entries; // Example: 'Admins', 'Department Managers'
    const _groupContents = new Map(
      fastViewGroups.map((fastViewGroup) => [
        fastViewGroup.groupSelfKey,
        {
          allIds: new Set(fastViewGroup.entries.map((entry) => entry.id)),
          selectedIds: new Set(
            fastViewGroup.entries
              ?.filter((entry) => ids.has(entry.id))
              ?.map((entry) => entry.id)
          ),
        },
      ])
    );

    const idsParticipatingInGroups = new Set();
    _groupContents.forEach(({ allIds }) => {
      allIds.forEach((idOfGroup) => idsParticipatingInGroups.add(idOfGroup));
    });

    // selected ids that don't belong to any view group
    const individualIds = new Set(
      _selectedIdsArray?.filter((id) => !idsParticipatingInGroups.has(id))
    );

    const groups = new Set(selectedGroupsArray); // selected group keys

    // commmenting it out since if only one user is available and if that user is selected it pre selects admin/dept manager which is not required
    // _groupContents.forEach(({ allIds, selectedIds }, groupSelfKey) => {
    //   if (allIds.size === selectedIds.size) groups.add(groupSelfKey);
    // });

    return { ids, groups, _groupContents, individualIds };
  };

  const [
    selectedStuff,
    setSelectedStuff,
    resetSelectedStuff,
    saveSelectedStuff,
  ] = useStateResettable(() => createSelectStuffState(selectedIdsArray));

  useEffect(() => {
    setSelectedStuff(createSelectStuffState(selectedIdsArray));
  }, [groupsWithEntriesForUI]);

  useEffect(() => {
    if (selectedStuff?.ids?.size && hideLabelAfterSelect)
      toggleLabelDisplay(false);
    else if (!selectedStuff?.ids?.size) toggleLabelDisplay(true);
  }, [selectedStuff]);

  const [enteredText, setEnteredText] = useState("");

  // Creating display label with selected checkboxes
  const createDisplayLabel = () => {
    let names = list
      ?.filter((option) => selectedStuff.ids.has(option.id))
      ?.map((selected) => selected.label);

    if (supportExplicitControls && selectedStuff.groups) {
      const groupKeys = Array.from(selectedStuff.groups);
      names = [...names, ...groupKeys.map((key) => headerLabelsMap[key].label)];
    }

    const res = formatNames(names);

    setDisplayLabel(res);
  };

  const _toggleCheckboxHandler = ({
    id = null,
    value,
    groupKey = null,
    toggleMode = "",
    fromPill = false,
    ...rest
  }) => {
    if (toggleCheckboxHandler) {
      toggleCheckboxHandler({
        id,
        value,
        groupKey,
        toggleMode,
        setSelectedStuff,
        fromPill,
      });
      return;
    }
    // works fine - START
    if (toggleMode === __TOGGLE_MODE_SINGLE_ENTRY) {
      setSelectedStuff((prev) => {
        // handle individual ids
        if (value) prev.ids.add(id);
        else prev.ids.delete(id);

        if (value) prev.individualIds.add(id);
        else prev.individualIds.delete(id);

        // handle groups contents
        prev._groupContents.forEach(
          ({ allIds = [], selectedIds }, groupKey_) => {
            const entryBelongsToGroup = allIds.has(id);
            if (!entryBelongsToGroup) return;

            if (value) selectedIds.add(id);
            else selectedIds.delete(id);

            // handle group sets
            if (selectedIds.size === allIds.size) prev.groups.add(groupKey_);
            else prev.groups.delete(groupKey_);
          }
        );

        return { ...prev };
      });
    } else if (toggleMode === __TOGGLE_MODE_GROUP_ENTRY) {
      // a group was toggled
      setSelectedStuff((prev) => {
        const relevantGroup = prev._groupContents.get(groupKey);

        // handle individual ids
        if (value) {
          relevantGroup.allIds.forEach((_id) => prev.ids.add(_id)); // add all ids to the outer ids set (used in flat view)
          relevantGroup.selectedIds = new Set([...relevantGroup.allIds]); // all applicable are selected now
        } else {
          relevantGroup.allIds.forEach((_id) => prev.ids.delete(_id)); // remove all ids from the outer ids Set (used in flat view)
          relevantGroup.selectedIds.clear(); // all applicable are deselected now
        }

        // handle groups contents
        prev._groupContents.forEach(({ allIds, selectedIds }, groupKey_) => {
          relevantGroup.allIds.forEach((_id) => {
            const entryBelongsToGroup = allIds.has(_id);
            if (!entryBelongsToGroup) return;

            if (value) selectedIds.add(_id);
            else selectedIds.delete(_id);
          });

          // handle group sets
          if (selectedIds.size === allIds.size) prev.groups.add(groupKey_);
          else prev.groups.delete(groupKey_);
        });

        return { ...prev };
      });
    }
  };

  const handleInput = (val) => {
    setEnteredText(val.split(",").pop().trim());

    setDisplayLabel(val);
  };

  // Done
  const confirm = () => {
    // Confirmation CTA
    const { ids, groups, _groupContents, individualIds } = selectedStuff;
    const finalValue = {
      // make everything a plain array or object
      selectedIds: [...ids],
      selectedGroups: [...groups],
      groupContents: [..._groupContents.entries()].reduce(
        (accum, [key, value]) => {
          accum[key] = value;
          return accum;
        },
        {}
      ),
      selectedIndividualIds: [...individualIds],
    };
    handleSubmit(
      insideForm
        ? {
            target: {
              name,
              type: "checkbox-dropdown",
              value: finalValue,
            },
          }
        : finalValue
    );

    setEnteredText("");
    saveSelectedStuff();
    // Set selected
    if (hideLabelAfterSelect) toggleLabelDisplay(false);
    popupRef.current.close(); // close the dropdown (Popup)
  };

  useEffect(() => {
    createDisplayLabel();
  }, [selectedIdsArray, selectedStuff]);

  const [backTree, setBackTree] = useState([]); // a stack (type is Array), initially empty (since back button is not applicable)
  const [currentTree, setCurrentTree] = useState(groupsWithEntriesForUI); // an array
  const resetTree = () => {
    setBackTree([]);
    setCurrentTree(groupsWithEntriesForUI);
  };

  const reset = () => {
    setEnteredText("");
    resetTree();
    resetSelectedStuff();
    setClosedFromPill(false);
  };
  useEffect(() => {
    setCurrentTree(groupsWithEntriesForUI);
  }, [groupsWithEntriesForUI]);

  useEffect(() => {
    if (!saveOnExternalEvent) return;

    saveSelectedStuff(createSelectStuffState(selectedIdsArray));
  }, [selectedIdsArray]);

  const handleScroll = (e) => {
    popupRef?.current?.close();
  };
  //  TO close popup js when scrolled
  useEffect(() => {
    const sliderComponents = document.querySelectorAll(".slider-content-core");
    if (sliderComponents?.length) {
      sliderComponents?.forEach((sliderComponent) => {
        sliderComponent.addEventListener("scroll", handleScroll);
        return () => {
          sliderComponent.removeEventListener("scroll", handleScroll);
        };
      });
    }
  }, []);

  const TriggerComponent = triggerComponent;
  const triggerRef = useRef(null);
  const clientRect = triggerRef.current?.getBoundingClientRect();
  const allRemainingFilter = !isLoading
    ? options?.filter((option) =>
        option?.label
          ?.toLowerCase()
          ?.includes(enteredText?.trim()?.toLowerCase())
      )
    : [];
  const isEmpty = !allRemainingFilter?.length;
  useEffect(() => {
    // DONOT REMOVE : this we are doing because popup is not reposition when content is changed
    window.dispatchEvent(new Event("resize"));
  }, [enteredText]);
  return (
    <>
      <Popup
        ref={popupRef}
        onClose={reset}
        keepTooltipInside={
          tooltipParentId ||
          `${
            tooltipParentClass.startsWith(".") ? "" : "."
          }${tooltipParentClass}`
        }
        repositionOnResize
        trigger={(isPopupOpen) => (
          <div className="w-full my-3">
            {/** DONOT REMOVE: this decide width of dropdown */}
            <div ref={triggerRef} className="w-full">
              {TriggerComponent ? (
                <TriggerComponent
                  name={name}
                  showLabel={shouldDisplayLabel}
                  selectedStuff={selectedStuff}
                  setSelectedStuff={setSelectedStuff}
                  toggleCheckboxHandler={_toggleCheckboxHandler}
                  options={options}
                  headerLabelsMap={headerLabelsMap}
                  onChange={(e) => handleInput(e.target.value)}
                  isOpen={isPopupOpen}
                  openPopup={() => popupRef?.current?.open()}
                  closePopup={() => popupRef?.current?.close()}
                  togglePopup={() => popupRef?.current?.toggle()}
                  enteredText={enteredText}
                  label={label}
                  displayLabel={displayLabel}
                  hideDisplayLabel={hideDisplayLabel}
                  handleInput={handleInput}
                  disabled={disabled}
                  supportExplicitControls={supportExplicitControls}
                  labelExtraClasses={labelExtraClasses}
                  setClosedFromPill={setClosedFromPill}
                  showPills={showPills}
                />
              ) : null}
            </div>
          </div>
        )}
        closeOnDocumentClick
        position="bottom left"
        className="filter-popup"
      >
        {/* {!allRemainingFilter?.length ? "No Options Like this exist" : null} */}
        {!disabled ? (
          <div style={{ width: clientRect?.width }} className="flex flex-col">
            {!isEmpty && !isLoading ? (
              <div className="content max-h-[224px] overflow-y-auto">
                {mode === "grouped" ? (
                  currentTree?.map((item) => {
                    const isAView = !!item.viewKey;
                    const { label: groupHeaderLabel, icon: groupHeaderIcon } =
                      isAView ? item : headerLabelsMap[item.groupSelfKey];

                    const reactKey = isAView
                      ? `vp-checkbox-dropdown-view-key-${item.viewKey}`
                      : item.groupSelfKey;

                    // search logic
                    /*
                What to include in search (user types something)

                if both fast and flat views are visible
                  - show filtered entries from flat
                  - don't render fast view
                if group has been selected
                  - show filtered entries from that group only
              */
                    const isInSearchMode = !!enteredText;
                    const filteredEntries = isInSearchMode
                      ? item?.entries?.filter((option) =>
                          option?.label
                            ?.toLowerCase()
                            ?.includes(enteredText?.toLowerCase())
                        )
                      : item?.entries;

                    if (isInSearchMode && isAView && !item.isFlatView)
                      return null;

                    return (
                      <CheckboxDropdownGroup
                        key={reactKey}
                        groupSelfKey={item.groupSelfKey}
                        groupLabel={groupHeaderLabel}
                        groupIcon={groupHeaderIcon}
                        entries={filteredEntries}
                        toggleCheckboxHandler={_toggleCheckboxHandler}
                        level={item.level}
                        backTree={backTree}
                        currentTree={currentTree}
                        setBackTree={setBackTree}
                        setCurrentTree={setCurrentTree}
                        selectedStuff={selectedStuff}
                        getHeaderNodeUI={getHeaderNodeUI}
                        supportExplicitControls={supportExplicitControls}
                        hideLabelAfterSelect={hideLabelAfterSelect}
                        toggleLabelDisplay={toggleLabelDisplay}
                      />
                    );
                  })
                ) : (
                  <CheckboxDropdownGroup
                    groupKey={null}
                    groupLabel=""
                    entries={list
                      ?.filter((option) =>
                        option.label
                          ?.toLowerCase()
                          ?.includes(enteredText?.toLowerCase())
                      )
                      ?.map((option) => ({
                        ...option,
                        checked: false,
                        label: option.label,
                      }))}
                    level={0}
                    toggleCheckboxHandler={_toggleCheckboxHandler}
                    selectedStuff={selectedStuff}
                    supportExplicitControls={supportExplicitControls}
                  />
                )}
              </div>
            ) : null}
            {isLoading ? <div className="px-5">{loader}</div> : null}
            {isEmpty && !isLoading ? (
              <Text
                classes="px-5 py-3 text-neutral-500 font-normal"
                translationKey={noDataAvailableText}
              />
            ) : null}

            <CheckboxDropdownFooter
              disabledSubmit={isEmpty || isLoading}
              onClear={null}
              onConfirm={confirm}
            />
          </div>
        ) : null}
      </Popup>
      {error ? (
        <Text
          classes="inline-block text-xs text-danger-600"
          translationKey={error}
        />
      ) : null}
    </>
  );
}

CheckboxDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  mode: PropTypes.string,
  options: PropTypes.array.isRequired,
  tooltipParentClass: PropTypes.string,
  handleSubmit: PropTypes.func,
  triggerComponent: PropTypes.elementType,
  groupingFunction: PropTypes.func,
  headerLabelsMap: PropTypes.object,
  selectedIdsArray: PropTypes.array.isRequired,
  getHeaderNodeUI: PropTypes.func,
  dropdownLabelFastView: PropTypes.string,
  dropdownLabelFlatView: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  isLoading: PropTypes.bool,
  loader: PropTypes.any,
  insideForm: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  supportExplicitControls: PropTypes.bool,
  labelExtraClasses: PropTypes.string,
  hideLabelAfterSelect: PropTypes.bool,
  showPills: PropTypes.bool,
  noDataAvailableText: PropTypes.string,
  saveOnExternalEvent: PropTypes.bool,
  hideDisplayLabel: PropTypes.bool,
};
