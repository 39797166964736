import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPendingTabCount } from "@/store/reducers/accounting";

import {
  isFetchingPendingCountSelector,
  pendingTabCountSelector,
} from "@/store/selectors/accounting";
import { isFetchingTransactionsSelector } from "@/store/selectors/accounting_transactions";
import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";

import Tabs from "@/components/core/Tabs";

import CardsPageHelper from "@/components/Accounting/Transactions/Cards/PageHelper";
import AccountingHeader from "@/components/Accounting/Transactions/common/AccountingHeader";
import { convertFilters } from "@/utils/filters";
import { accountingSelectedTabs } from "@/utils/common";

import {
  ACCOUNTING_SYNC_ATTEMPT_ENTITY,
  ACCOUNTING_TAB_TYPES,
  ACCOUNTING_TRANSACTION_TABS,
} from "@/constants/accounting";
import {
  PENDING_COUNT_TRANSACTIONS,
  TRANSACTION_TYPE,
} from "@/constants/company";

export default function AccountingCards() {
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = !!accountingSoftware;
  const dispatch = useDispatch();
  const isFetchingPendingCount = useSelector(isFetchingPendingCountSelector);
  const pendingTabCountObj = useSelector(pendingTabCountSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const isFetchingTransactions = useSelector(isFetchingTransactionsSelector);
  const selectedTabs = useMemo(
    () =>
      accountingSelectedTabs(
        accountingSoftware,
        ACCOUNTING_TAB_TYPES.CARDS,
        isFetchingPendingCount ? 0 : pendingTabCountObj?.pendingCounts
      ),
    [isFetchingPendingCount]
  );
  const [selectedTab, setSelectedTab] = useState();
  useEffect(() => {
    dispatch(
      fetchPendingTabCount({
        transaction: PENDING_COUNT_TRANSACTIONS.CARDS,
        ...convertFilters(appliedFilters),
      })
    );
  }, []);
  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <AccountingHeader
        sectionTitleText="accounting.transactions.cards.title"
        entity={ACCOUNTING_SYNC_ATTEMPT_ENTITY.EXPENSE}
      />
      {accountingEnabled ? (
        <Tabs
          items={selectedTabs}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
          conditionalTabChange={isFetchingTransactions}
        />
      ) : (
        <CardsPageHelper tab={selectedTab?.key} />
      )}
    </div>
  );
}
