import PropTypes from "prop-types";
import React, { useRef } from "react";
import Popup from "reactjs-popup";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

export default function OptionsSectionPopup({
  options,
  showOptions,
  handleOptionChange,
}) {
  const popupRef = useRef();
  const innerHandleOption = (index) => {
    handleOptionChange(options?.[index]?.key);
    popupRef.current.close();
  };

  return (
    <Popup
      ref={popupRef}
      trigger={
        <div>
          <Button
            label={`cards.pCards.orderButtonLabels.${
              showOptions ? "plural" : "singular"
            }`}
            preIcon="ShoppingBag"
            classes="h-11 w-[calc(theme(spacing.16)+theme(spacing.5))] text-btn-lg font-semibold"
          />
        </div>
      }
      closeOnDocumentClick
      className="filter-popup"
      position="bottom right"
      keepTooltipInside="#tooltip-inside"
    >
      {showOptions && (
        <div className="min-h-fit w-fit">
          {options.map((option, index) => {
            return (
              <div
                className="flex flex-col items-start self-stretch h-12 p-3 cursor-pointer hover:bg-neutral-50"
                onClick={() => innerHandleOption(index)}
                key={index}
              >
                <Text
                  translationKey={option.label}
                  classes="text-base not-italic font-medium leading-6 text-neutral-900"
                />
                <Text
                  translationKey={option.description}
                  classes="text-xs not-italic font-medium leading-5 text-neutral-400"
                />
              </div>
            );
          })}
        </div>
      )}
    </Popup>
  );
}

OptionsSectionPopup.propTypes = {
  options: PropTypes.array,
  showOptions: PropTypes.bool,
  handleOptionChange: PropTypes.func,
};
