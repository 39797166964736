import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchRequestTableInfo } from "@/store/reducers/actionCentre";
import {
  PAYMENTS_SLICE_ATTRIBUTE_KEY,
  fetchAndSelectPaymentApproval,
  fetchAndSelectPayrollApproval,
  fetchPaymentApprovals,
  fetchPayrollApprovals,
  rejectPayments,
  removePayment,
} from "@/store/reducers/payments";

import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isLoadingPaymentsSelector,
  isPaymentFetchingSelector,
  selectedPaymentSelector,
} from "@/store/selectors/payments";
import { userSelector } from "@/store/selectors/user";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import SingleColumnDataRows from "@/components/Accounting/Transactions/common/Slider/SingleColumnDataRows";
import { useForm } from "@/utils/useForm";
import { convertFilters } from "@/utils/filters";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  APPROVAL_OR_PAYMENT_STATUS_COLOR,
  APPROVAL_OR_PAYMENT_STATUS_TEXT,
  PAYROLL,
  TAB_TYPES,
} from "@/utils/constants/payments";
import { amountToCurrency, dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { APPROVAL_REQUEST_TYPE } from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";

const REJECT_BILL_PAY_PAYROLL_CONFIG = {
  [BILL_PAYROLL_CONTEXT.PAYROLL]: {
    title:
      "billPay.bill.payments.payrollPaymentRejectSlider.payrollRejectTitle",
    description:
      "billPay.bill.payments.payrollPaymentRejectSlider.payrollRejectDescription",
    searchParam: SLIDERS_SEARCH_PARAMS.payrollPayments.reject,
    totalValue: (payment) => payment?.quote?.totalAmount,
    review: [
      {
        label:
          "billPay.bill.payments.payrollPaymentRejectSlider.submissionDate",
        key: "createdAt",
        isDate: true,
      },
      {
        label: "billPay.bill.payments.payrollPaymentRejectSlider.paymentDate",
        key: "paymentDate",
        isDate: true,
      },

      {
        label: "billPay.bill.payments.payrollPaymentRejectSlider.memo",
        key: "memo",
      },
    ],
    reasonTitle:
      "billPay.bill.payments.payrollPaymentRejectSlider.reasonForRejection",
    comment: "billPay.bill.payments.payrollPaymentRejectSlider.enterComment",
  },
  [BILL_PAYROLL_CONTEXT.BILLPAY]: {
    title:
      "billPay.bill.payments.payrollPaymentRejectSlider.paymentRejectTitle",
    description:
      "billPay.bill.payments.payrollPaymentRejectSlider.paymentRejectDescription",
    searchParam: SLIDERS_SEARCH_PARAMS.payments.reject,
    totalValue: (payment) => payment?.quote?.totalAmount,
    review: [
      {
        label: "billPay.bill.payments.payrollPaymentRejectSlider.invoiceNumber",
        key: "invoiceNumber",
      },
      {
        label: "billPay.bill.payments.payrollPaymentRejectSlider.invoiceDate",
        key: "invoiceDate",
        isDate: true,
      },
      {
        label: "billPay.bill.payments.payrollPaymentRejectSlider.dueDate",
        key: "dueDate",
        isDate: true,
      },
      {
        label: "billPay.bill.payments.payrollPaymentRejectSlider.memo",
        key: "memo",
      },
    ],
    reasonTitle:
      "billPay.bill.payments.payrollPaymentRejectSlider.reasonForRejection",
    comment: "billPay.bill.payments.payrollPaymentRejectSlider.enterComment",
  },
};
export default function RejectApprovalSLider({
  setOnClose,
  context,
  closeDrawer,
}) {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const config = REJECT_BILL_PAY_PAYROLL_CONFIG[context];
  let payment = useSelector(selectedPaymentSelector);
  const appliedFilters = convertFilters(useSelector(appliedFilterSelector));

  payment = useMemo(() => {
    // needed for [payment, paid] sliders, .purchaseBill contains invoice details
    if (payment?.purchaseBill) return { ...payment?.purchaseBill, ...payment };
    return payment;
  }, [payment]);

  const isLoading = useSelector(isLoadingPaymentsSelector);
  const isFetching = useSelector(isPaymentFetchingSelector);
  const currentUser = useSelector(userSelector);
  const id = searchParam.get(config.searchParam);

  const badgeColor = APPROVAL_OR_PAYMENT_STATUS_COLOR[payment?.status];
  const badgeText = APPROVAL_OR_PAYMENT_STATUS_TEXT[payment?.status];
  const tabType = searchParam.get(SLIDERS_SEARCH_PARAMS.payments.pageType);

  const cancelHandler = () => {
    searchParam.delete(config.searchParam);
    setSearchParam(searchParam);
    closeDrawer();
  };

  const onSuccess = (responseData) => {
    cancelHandler();
    searchParam.delete(SLIDERS_SEARCH_PARAMS.payrollPayments.id);
    searchParam.delete(SLIDERS_SEARCH_PARAMS.payments.sliderType);
    searchParam.delete(SLIDERS_SEARCH_PARAMS.payments.pageType);
    searchParam.delete(SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId);
    searchParam.delete(SLIDERS_SEARCH_PARAMS.payments.reject);
    searchParam.delete(SLIDERS_SEARCH_PARAMS.payrollPayments.reject);
    setSearchParam(searchParam);
    closeDrawer();

    // to verify if this is action page or not
    const isOnActionCentrePagePayroll =
      window.location.pathname ===
      ROUTES.myVolopay.actionCentre.payroll.absolutePath;
    const isOnActionCentrePageBillPay =
      window.location.pathname ===
      ROUTES.myVolopay.actionCentre.billPay.absolutePath;

    if (tabType === TAB_TYPES.ALL) {
      const params = {
        key: PAYMENTS_SLICE_ATTRIBUTE_KEY,
        value: {
          page: 1,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          tab: tabType,
          sort_column: null,
          sort_direction: null,
        },
        ...(appliedFilters ? appliedFilters : {}),
      };
      if (context === PAYROLL) {
        dispatch(fetchPayrollApprovals(params));
      } else {
        dispatch(fetchPaymentApprovals(params));
      }
    } else {
      dispatch(removePayment(payment?.id));
    }

    if (isOnActionCentrePageBillPay || isOnActionCentrePagePayroll) {
      dispatch(
        fetchRequestTableInfo({
          limit: PAGINATION_PER_REQUEST_LIMIT,
          page: 1,
          request_type: isOnActionCentrePageBillPay
            ? APPROVAL_REQUEST_TYPE.BILL_PAY
            : APPROVAL_REQUEST_TYPE.PAYROLL,
          user: currentUser?.id,
          ...(appliedFilters ? appliedFilters : {}),
        })
      );
    }
  };

  const rejectHandler = () => {
    dispatch(
      rejectPayments({
        id: parseInt(id, 10),
        context,
        comment: values?.comment,
        onSuccess,
      })
    );
  };

  useEffect(() => {
    if (id) {
      if (context === BILL_PAYROLL_CONTEXT.PAYROLL)
        dispatch(
          fetchAndSelectPayrollApproval({ id: parseInt(id, 10), context })
        );
      if (context === BILL_PAYROLL_CONTEXT.BILLPAY)
        dispatch(
          fetchAndSelectPaymentApproval({ id: parseInt(id, 10), context })
        );
    }
  }, [id]);

  const itemRef = useLeftHeaderTitle({
    title: config?.title,
  });
  const totalAmount = config.totalValue(payment);
  const initialValue = {
    comment: {
      value: "",
      validate: { required: true },
    },
  };

  const { isFormButtonDisabled, errors, handleChange, handleSubmit, values } =
    useForm(initialValue, rejectHandler);

  return (
    <div className="slider-content-container">
      <div className="flex flex-col gap-8 slider-content-core px-9 ">
        <div ref={itemRef} className="flex flex-col">
          <Text classes="font-bold text-3xl" translationKey={config?.title} />
          <Text
            classes="font-medium text-neutral-500 text-sm"
            translationKey={config?.description}
          />
        </div>

        {isFetching ? (
          <LoaderSkeleton fullWidth count={5} classes="py-4" />
        ) : (
          <>
            <div>
              <div className="flex items-center gap-2">
                <Text
                  classes="text-3xl font-bold"
                  translationKey={amountToCurrency(totalAmount)}
                />
                <div>
                  <Badge
                    classes={`p-2 bg-${badgeColor}-50 border-${badgeColor}-200 text-${badgeColor}-600`}
                    variant={badgeColor}
                    translationKey={badgeText}
                  />
                </div>
              </div>
              <Text
                classes="font-medium text-neutral-500 text-sm"
                translationKey="billPay.bill.payments.payrollPaymentRejectSlider.approverDone"
                translationProps={{
                  currentLevel: payment?.currentApprovalLevel,
                  totalLevel: payment?.approvalLevels,
                }}
              />
            </div>

            <div>
              <SingleColumnDataRows
                dataRows={config.review?.map((item) => ({
                  label: item.label,
                  value: item.isDate
                    ? dateToString(payment?.[item.key])
                    : payment?.[item.key],
                }))}
              />
            </div>

            <form
              onSubmit={handleSubmit}
              id="reject-form-payroll-billpay"
              className="flex flex-col gap-6"
            >
              <Text
                classes="font-semibold text-lg"
                translationKey={config.reasonTitle}
              />

              <Input
                name="comment"
                label={config.comment}
                onChange={(e) => handleChange(e)}
                value={values?.comment}
                error={errors?.comment}
              />
            </form>
          </>
        )}
      </div>
      <div className="px-5 py-4 slider-footer">
        <div className="flex justify-end gap-4">
          <Button
            onClick={cancelHandler}
            label="billPay.bill.invoiceInbox.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold text-neutral-500"
            disabled={isLoading}
          />

          <Button
            form="reject-form-payroll-billpay"
            type="danger"
            btnType="submit"
            label="billPay.bill.invoiceInbox.rejectBtnLabel"
            classes=" w-16 px-5 py-3 text-btn-lg font-semibold "
            disabled={isFormButtonDisabled || isLoading}
            showLoader={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

RejectApprovalSLider.propTypes = {
  context: PropTypes.string,
  setOnClose: PropTypes.func,
  closeDrawer: PropTypes.func,
};
