import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchAndSelectReport,
  fetchReports,
  setRejectReportReason,
  setSelectedReport,
  submitReport,
} from "@/store/reducers/reimbursement";

import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isFetchingReportsSelector,
  selectedReportSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import SingleColumnDataRows from "@/components/Accounting/Transactions/common/Slider/SingleColumnDataRows";
import { convertFilters } from "@/utils/filters";
import {
  APPROVAL_OR_PAYMENT_STATUS_COLOR,
  APPROVAL_OR_PAYMENT_STATUS_TEXT,
} from "@/utils/constants/payments";
import { amountToCurrency } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { REJECT } from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function RejectReport({ setOnClose }) {
  const [comment, setComment] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  const dispatch = useDispatch();

  const selectedReportData = useSelector(selectedReportSelector);

  const isFetching = useSelector(isFetchingReportsSelector);

  const reportId =
    searchParam.get(SLIDERS_SEARCH_PARAMS.reports.reportId) ||
    searchParam.get(SLIDERS_SEARCH_PARAMS.reports.rejectReport);

  const badgeColor =
    APPROVAL_OR_PAYMENT_STATUS_COLOR[selectedReportData?.status];
  const badgeText = APPROVAL_OR_PAYMENT_STATUS_TEXT[selectedReportData?.status];
  const appliedFilters = convertFilters(useSelector(appliedFilterSelector));

  const onSuccess = () => {
    const filterParams = {
      page: 1,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      tab,
      ...(appliedFilters ? appliedFilters : {}),
    };

    dispatch(fetchReports(filterParams));
    cancelHandler();
  };

  const saveHandler = () => {
    if (
      searchParam.get(SLIDERS_SEARCH_PARAMS.reports.rejectReport) === reportId
    ) {
      const payload = {
        operation: REJECT,
        comment,
      };

      dispatch(submitReport({ id: reportId, payload, onSuccess }));
    } else {
      dispatch(setRejectReportReason(comment));
      cancelHandler();
    }
  };

  const [tab] = window.location.pathname.split("/").slice(-1);

  const cancelHandler = () => {
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reports.rejectReport);
    setSearchParam(searchParam);
  };

  useEffect(() => {
    if (reportId && !selectedReportData) {
      dispatch(fetchAndSelectReport({ reportId }));
    }
  }, [reportId, selectedReportData]);

  useEffect(() => {
    setOnClose((searchParamArray) => {
      if (
        !searchParamArray?.includes(
          SLIDERS_SEARCH_PARAMS.reports.rejectReport
        ) &&
        !searchParamArray?.includes(SLIDERS_SEARCH_PARAMS.reports.reportId)
      ) {
        dispatch(setSelectedReport(null));
      }
    });
  }, []);

  const ref = useLeftHeaderTitle({ title: "" });

  return (
    <div className="slider-content-container">
      <div className="flex flex-col gap-8 slider-content-core px-9">
        <div ref={ref} className="flex flex-col">
          <Text
            classes="font-bold text-3xl"
            translationKey="myVolopay.reimbursements.reports.history.slider.rejectReport.title"
          />
          <Text
            classes="font-medium text-neutral-500 text-sm"
            translationKey="myVolopay.reimbursements.reports.history.slider.rejectReport.desc"
          />
        </div>
        {isFetching ? (
          <LoaderSkeleton fullWidth count={5} classes="py-4" />
        ) : (
          <>
            <div>
              <div className="flex items-center gap-2">
                <Text
                  classes="text-3xl font-bold"
                  translationKey={amountToCurrency(
                    selectedReportData?.totalAmount
                  )}
                />
                <div>
                  <Badge
                    classes={`p-2 bg-${badgeColor}-50 border-${badgeColor}-200 text-${badgeColor}-600`}
                    variant={badgeColor}
                    translationKey={badgeText}
                  />
                </div>
              </div>
              <Text
                classes="font-medium text-neutral-500 text-sm"
                translationKey="reimbursement.rejectPayment.approversDone"
                translationProps={{
                  currentLevel: selectedReportData?.currentApprovalLevel,
                  totalLevel: selectedReportData?.approvalLevels,
                }}
              />
            </div>

            <div>
              <SingleColumnDataRows
                dataRows={[
                  {
                    label:
                      "myVolopay.reimbursements.reports.history.slider.rejectReport.rows.name",
                    value: selectedReportData?.name,
                  },
                  {
                    label:
                      "myVolopay.reimbursements.reports.history.slider.rejectReport.rows.user",
                    value: selectedReportData?.createdBy?.displayName,
                  },
                  {
                    label:
                      "myVolopay.reimbursements.reports.history.slider.rejectReport.rows.linkedTo",
                    value: `${selectedReportData?.linkedTo?.title} : ${selectedReportData?.linkedTo?.name}`,
                  },
                  {
                    label:
                      "myVolopay.reimbursements.reports.history.slider.rejectReport.rows.totalAmount",
                    value: amountToCurrency(selectedReportData?.totalAmount),
                  },
                  {
                    label:
                      "myVolopay.reimbursements.reports.history.slider.rejectReport.rows.noOfClaims",
                    value: selectedReportData?.claims,
                  },
                ]}
              />
            </div>

            <div className="flex flex-col gap-6 mb-14 ">
              <Text
                classes="font-semibold text-lg"
                translationKey="reimbursement.rejectPayment.reasonTitle"
              />

              <Input
                label="reimbursement.rejectPayment.commentInputLabel"
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                value={comment}
              />
            </div>
          </>
        )}
      </div>
      <div className="px-5 py-4 slider-footer">
        <div className="flex justify-end gap-4">
          <Button
            onClick={cancelHandler}
            label="reimbursement.rejectPayment.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold text-neutral-500"
          />

          <Button
            label="Save"
            classes=" w-fit px-5 py-3 text-btn-lg font-semibold "
            disabled={!comment}
            onClick={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default RejectReport;
