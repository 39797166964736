import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Popup from "reactjs-popup";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  deleteBudget,
  fetchAndSelectBudget,
  fetchAndSelectDepartment,
  fetchAndSelectLocation,
  fetchAndSelectProject,
  fetchBudgets,
  fetchDepartments,
  fetchLocations,
  fetchProjects,
  setSelectedBudget,
  setSelectedDepartment,
  setSelectedLocation,
  setSelectedProject,
} from "@/store/reducers/company";

import {
  isFetchingDepartmentSelector,
  isFetchingLocationsSelector,
  isFetchingSelectedBudgetSelector,
  isFetchingSelectedProjectSelector,
  selectedBudgetSelector,
  selectedDepartmentSelector,
  selectedLocationSelector,
  selectedProjectSelector,
} from "@/store/selectors/company";

import Button from "@/components/core/Button";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import TabHelper from "@/components/Company/Slider/LocationDepartmentDetails/TabHelper";
import BudgetRow from "@/components/Company/common/BudgetRow";
import GridOfButtons from "@/components/Company/common/GridOfButtons";
import StartEndDate from "@/components/common/StartEndDate";
import {
  CATEGORY_KEYS_LABEL_MAPPING,
  generateRedirectionUrlForFilters,
} from "@/utils/constants/searchAndFilter";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import { DOT_SYMBOL } from "@/utils/constants/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  BUDGET,
  DEPARTMENT,
  DEPARTMENT_SLIDER_TABS,
  DEPARTMENT_SLIDER_TAB_KEYS,
  LOCATION,
  PROJECT,
} from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import { BE_CTA_RESPONSE } from "@/constants/user";

import DeleteBudgetModal from "./DeleteBudgetModal";

export default function LocationDepartmentDetails({ sliderType = DEPARTMENT }) {
  const NAVIGATION_TRIGGER_KEYS = {
    PHYSICAL_CARDS: "physicalCards",
    EXPENSES: "expenses",
    MISSING_ITEMS: "missingItems",
    BILL_PAYMENT: "billPayment",
    REIMBURSEMENT: "reimbursements",
    VIRTUAL_CARDS: "virtualCards",
  };

  const gridConfig = {
    [NAVIGATION_TRIGGER_KEYS.PHYSICAL_CARDS]: {
      label: "cards.navigationLabels.pCard",
      icon: "CreditCard",
      onClick: () => handleClickOnGridItems("cards.navigationLabels.pCard"),
    },
    [NAVIGATION_TRIGGER_KEYS.VIRTUAL_CARDS]: {
      label: "cards.navigationLabels.vCard",
      icon: "Cloud",
      onClick: () => handleClickOnGridItems("cards.navigationLabels.vCard"),
    },
    [NAVIGATION_TRIGGER_KEYS.EXPENSES]: {
      label: "expenses.title",
      icon: "Expense",
      onClick: () => handleClickOnGridItems("expenses.title"),
    },
    [NAVIGATION_TRIGGER_KEYS.MISSING_ITEMS]: {
      label: "misc.missingItems",
      icon: "MissingBadge",
      onClick: () => {
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.expenses.missingDetails,
          true
        );
        setSearchParams(searchParams);
      },
    },
    [NAVIGATION_TRIGGER_KEYS.BILL_PAYMENT]: {
      label: "billPay.bill.payments.billPaymentsTitle",
      icon: "Send",
      onClick: () =>
        handleClickOnGridItems("billPay.bill.payments.billPaymentsTitle"),
    },
    [NAVIGATION_TRIGGER_KEYS.REIMBURSEMENT]: {
      label: "reimbursement.titlePlural",
      icon: "Cloud",
      onClick: () => handleClickOnGridItems("reimbursement.titlePlural"),
    },
  };

  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  // below: already set when component loads
  const sliderIdKey = SLIDERS_SEARCH_PARAMS.company[sliderType].id;
  const id = searchParams.get(sliderIdKey); // id of department or project
  const isDepartmentBudget =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.budget.type) === DEPARTMENT;

  // For location slider, budget tab is not shown.
  // For budget slider, no tab UI is shown, and a custom UI is shown (budget row + spends tabbed tables)
  const tabs =
    sliderType === LOCATION
      ? DEPARTMENT_SLIDER_TABS.filter(
          ({ key }) => key !== DEPARTMENT_SLIDER_TAB_KEYS.BUDGETS
        )
      : sliderType === BUDGET
        ? [
            DEPARTMENT_SLIDER_TABS.find(
              (item) => item.key === DEPARTMENT_SLIDER_TAB_KEYS.SPENDS
            ),
          ]
        : DEPARTMENT_SLIDER_TABS;

  const [selectedSliderTab, setSelectedSliderTab] = useState(tabs[0]);
  useEffect(() => {
    const tabType = searchParams.get(
      SLIDERS_SEARCH_PARAMS.company.openTabInBudgetLocationAndDepartment
    );
    if (tabType === BUDGET) {
      const selectedData = tabs.find(
        (item) => item.key === DEPARTMENT_SLIDER_TAB_KEYS.BUDGETS
      );
      setSelectedSliderTab(selectedData);
    }
  }, [searchParams]);

  const sliderData = useSelector((state) => {
    switch (sliderType) {
      case DEPARTMENT:
        return selectedDepartmentSelector(state);
      case LOCATION:
        return selectedLocationSelector(state);
      case PROJECT:
        return selectedProjectSelector(state);
      case BUDGET:
        return selectedBudgetSelector(state);
      default:
        return [];
    }
  });

  const gridItemsByKeys = getRequiredGridItemsByKeys() || [];

  const isFetching = useSelector((state) => {
    switch (sliderType) {
      case DEPARTMENT:
        return isFetchingDepartmentSelector(state);
      case LOCATION:
        return isFetchingLocationsSelector(state);
      case PROJECT:
        return isFetchingSelectedProjectSelector(state);
      case BUDGET:
        return isFetchingSelectedBudgetSelector(state);
      default:
        return [];
    }
  });

  // fetch detail
  useEffect(() => {
    if (id) {
      if (sliderType === DEPARTMENT) {
        dispatch(fetchAndSelectDepartment({ id }));
      } else if (sliderType === LOCATION) {
        dispatch(fetchAndSelectLocation({ id }));
      } else if (sliderType === PROJECT) {
        dispatch(fetchAndSelectProject({ id }));
      } else if (sliderType === BUDGET) {
        dispatch(fetchAndSelectBudget({ id, department: isDepartmentBudget }));
      }
    }
  }, [id]);

  const editDetailsHandler = () => {
    const projectType = searchParams.get(
      SLIDERS_SEARCH_PARAMS.company.budget.type
    );
    const budgetId = searchParams.get(SLIDERS_SEARCH_PARAMS.company.budget.id);
    if (projectType || budgetId) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.budget.editBudget?.[
          projectType === DEPARTMENT ? "oldDeptBudget" : "oldProjBudget"
        ],
        budgetId
      );
    } else
      searchParams.append(SLIDERS_SEARCH_PARAMS.company[sliderType].edit, id);
    setSearchParams(searchParams);
  };

  const deleteBudgetHandler = () => {
    const handleCloseSlider = () => {
      if (sliderType === DEPARTMENT || sliderType === PROJECT) {
        dispatch(
          fetchProjects({
            page: 1,
            limit: PAGINATION_PER_REQUEST_LIMIT,
            department: sliderType === PROJECT,
          })
        );
      } else if (sliderType === LOCATION) {
        dispatch(
          fetchLocations({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT })
        );
      } else if (sliderType === BUDGET) {
        dispatch(
          fetchBudgets({
            page: 1,
            limit: PAGINATION_PER_REQUEST_LIMIT,
            budget_type: isDepartmentBudget ? DEPARTMENT : PROJECT,
          })
        );
        const projectId = searchParams.get(
          SLIDERS_SEARCH_PARAMS.company.project.id
        );
        const deptId = searchParams.get(
          SLIDERS_SEARCH_PARAMS.company.department.id
        );
        if (isDepartmentBudget && deptId) {
          dispatch(fetchAndSelectDepartment({ id: deptId }));
        }
        if (!isDepartmentBudget && projectId) {
          dispatch(fetchAndSelectProject({ id: projectId, department: false }));
        }
        if (isDepartmentBudget)
          dispatch(
            fetchDepartments({
              page: 1,
              limit: PAGINATION_PER_REQUEST_LIMIT,
              department: isDepartmentBudget,
            })
          );
        else
          dispatch(
            fetchProjects({
              page: 1,
              limit: PAGINATION_PER_REQUEST_LIMIT,
              department: isDepartmentBudget,
            })
          );
      }
      searchParams.delete(SLIDERS_SEARCH_PARAMS.company.budget.type);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.company.budget.id);
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.company.openTabInBudgetLocationAndDepartment
      );
      searchParams.delete(sliderIdKey);
      setSearchParams(searchParams);
    };

    dispatch(deleteBudget({ id, onSuccess: handleCloseSlider }));
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  // Replace with reducer

  // slider header stuff
  const sliderTitle = sliderData?.name;
  const projectName = sliderData?.projectName;
  const memberCount = sliderData?.members;
  const popupRef = useRef();

  const titleRef = useLeftHeaderTitle({ title: sliderData?.name });
  let sliderSearchParam = null;

  switch (sliderType) {
    case DEPARTMENT:
      sliderSearchParam = {
        [AVAILABLE_FILTER_KEYS.department]: id,
      };
      break;
    case LOCATION:
      sliderSearchParam = { [AVAILABLE_FILTER_KEYS.location]: id };
      break;
    case PROJECT:
      sliderSearchParam = { [AVAILABLE_FILTER_KEYS.project]: id };
      break;
    default:
      break;
  }
  const searchParam = sliderSearchParam
    ? createSearchParams(sliderSearchParam).toString()
    : null;

  useEffect(() => {
    return () => {
      //  clean
      if (sliderType === DEPARTMENT) {
        dispatch(setSelectedDepartment(null));
      } else if (sliderType === LOCATION) {
        dispatch(setSelectedLocation(null));
      } else if (sliderType === PROJECT) {
        dispatch(setSelectedProject(null));
      } else if (sliderType === BUDGET) {
        dispatch(setSelectedBudget(null));
      }
    };
  }, []);

  function handleClickOnGridItems(gridItemLabel) {
    let parentRoute = "";
    let categoryKey = "";
    let additionalSearchParams = "";

    switch (gridItemLabel) {
      case "cards.navigationLabels.vCard":
        parentRoute = ROUTES.cards.virtualCards.all.absolutePath;
        break;

      case "cards.navigationLabels.pCard":
        parentRoute = ROUTES.cards.physicalCards.all.absolutePath;
        break;

      case "expenses.title":
        parentRoute = ROUTES.cards.expenses.overView.absolutePath;
        break;

      case "billPay.bill.payments.billPaymentsTitle":
        parentRoute = ROUTES.billpay.bill.paid.absolutePath;
        break;

      case "reimbursement.titlePlural":
        parentRoute = ROUTES.reimbursement.approvals.all.absolutePath;
        additionalSearchParams = `${AVAILABLE_FILTER_KEYS.claimStatus}=Paid`;
        break;

      default:
        break;
    }

    if (sliderType === DEPARTMENT) {
      categoryKey = CATEGORY_KEYS_LABEL_MAPPING.DEPARTMENT;
    } else if (sliderType === PROJECT) {
      categoryKey = CATEGORY_KEYS_LABEL_MAPPING.PROJECT;
    } else if (sliderType === LOCATION) {
      categoryKey = CATEGORY_KEYS_LABEL_MAPPING.LOCATION;
    }

    generateRedirectionUrlForFilters({
      parentRoute,
      dispatch,
      categoryKey,
      categoryValues: id,
      navigate,
      additionalSearchParams,
    });
  }

  const allCtas = [
    BE_CTA_RESPONSE.EDIT_DETAILS,
    BE_CTA_RESPONSE.EDIT_BUDGET,
    BE_CTA_RESPONSE.DELETE_BUDGET,
  ];
  const permissionCheckResults = allCtas?.reduce((result, cta) => {
    const found = sliderData?.ctas?.some((data) => data === cta);
    result[cta] = found;
    return result;
  }, {});

  const {
    [BE_CTA_RESPONSE.EDIT_DETAILS]: showEditDetailsCta,
    [BE_CTA_RESPONSE.EDIT_BUDGET]: showEditBudgetCta,
    [BE_CTA_RESPONSE.DELETE_BUDGET]: showDeleteBudget,
  } = permissionCheckResults;
  const handleScroll = (e) => {
    popupRef?.current?.close();
  };
  //  TO close popup js when scrolled
  useEffect(() => {
    const sliderComponents = document.querySelectorAll(".slider-content-core");
    if (sliderComponents?.length) {
      sliderComponents?.forEach((sliderComponent) => {
        sliderComponent.addEventListener("scroll", handleScroll);
        return () => {
          sliderComponent.removeEventListener("scroll", handleScroll);
        };
      });
    }
  }, []);
  const projectState = useLoadingErrorInjector({
    apiKey: "Company:Project:get",
    showLoader: false,
    error: {
      header: "",
    },
  });

  const budgetState = useLoadingErrorInjector({
    apiKey: "Company:Budgets:get",
    showLoader: false,
    error: {
      header: "",
    },
  });

  const locationState = useLoadingErrorInjector({
    apiKey: "Company:Location:get",
    showLoader: false,
    error: {
      header: "",
    },
  });

  let curState = {};
  if (sliderType === PROJECT || sliderType === DEPARTMENT) {
    curState = projectState;
  } else if (sliderType === BUDGET) {
    curState = budgetState;
  } else if (sliderType === LOCATION) {
    curState = locationState;
  }

  function getRequiredGridItemsByKeys() {
    const ctaKeys = sliderData?.moreCtas;
    return ctaKeys?.map((ctaKey) => gridConfig?.[ctaKey]);
  }

  return (
    <>
      <div
        className="pb-6 slider-content-core px-9 for-popupjs-more-button-bound"
        id={sliderType}
        ref={curState?.attach}
      >
        {/* Slider header (dept or project name), countNumber members */}
        <div ref={titleRef} className="flex flex-col">
          <span className="w-11/12 text-3xl font-semibold truncate text-neutral-800">
            {isFetching ? (
              <LoaderSkeleton size={[36]} classes="w-4/5" />
            ) : (
              sliderTitle
            )}
          </span>
          {isFetching ? (
            <LoaderSkeleton size={[16]} classes="w-2/5" />
          ) : (
            <div className="flex items-center ">
              {sliderType === BUDGET ? (
                <Text
                  classes="text-neutral-500 font-medium text-sm"
                  translationKey={
                    isDepartmentBudget
                      ? "misc.nameDepartment"
                      : "misc.nameProject"
                  }
                  translationProps={{ name: projectName }}
                />
              ) : (
                <Text
                  classes="text-neutral-500 font-medium text-sm"
                  translationKey={
                    memberCount > 1
                      ? "misc.xMemberPlural"
                      : "misc.oneMemberSingular"
                  }
                  translationProps={{ count: memberCount }}
                />
              )}
              {sliderType === PROJECT ? (
                <>
                  <span className="text-neutral-500">{` ${DOT_SYMBOL} `}</span>
                  <StartEndDate
                    startDate={sliderData?.startDate}
                    endDate={sliderData?.endDate}
                  />
                </>
              ) : null}
            </div>
          )}
        </div>

        {/* Two buttons - edit-details, more/delete */}
        <div className="flex items-center gap-5 mt-4">
          {showEditDetailsCta || showEditBudgetCta ? (
            <Button
              variant="tertiary"
              label={
                sliderType === BUDGET
                  ? "misc.editBudget"
                  : sliderType === LOCATION
                    ? "misc.editDetails"
                    : "company.department.editDetails"
              }
              preIcon="Edit"
              classes={`${
                [PROJECT, DEPARTMENT].includes(sliderType)
                  ? "w-[220px]"
                  : "w-[189px]"
              } text-neutral-500 text-base font-semibold`}
              onClick={editDetailsHandler}
            />
          ) : null}

          {/* 'More' button (grid), or Delete Budget button */}
          {sliderType === BUDGET && !gridItemsByKeys?.length ? (
            showDeleteBudget ? (
              <Button
                variant="tertiary"
                type="danger"
                label="misc.deleteBudget"
                classes="w-[189px] text-base font-semibold !border-danger-500"
                onClick={handleDelete}
              />
            ) : null
          ) : (
            <Popup
              ref={popupRef}
              keepTooltipInside=".for-popupjs-more-button-bound"
              repositionOnResize
              className="filter-popup"
              trigger={
                <div>
                  <Button
                    variant="tertiary"
                    label="misc.more"
                    preIcon="Menu"
                    classes="w-[189px] text-neutral-500 text-base font-semibold"
                  />
                </div>
              }
              closeOnDocumentClick
              position="right top"
            >
              <GridOfButtons columns={2} buttonConfigs={gridItemsByKeys} />
            </Popup>
          )}
        </div>

        {/* Budget row is shown for Budget slider */}
        {/* This is outside of any tabs */}
        {sliderType === BUDGET ? (
          <div className="mt-8">
            <BudgetRow
              key={sliderData?.id}
              data={sliderData}
              available={sliderData?.available}
              currency={sliderData?.currency}
              overSpend={sliderData?.overSpend}
              isFlexible={sliderData?.flexible}
              isClickable={false}
              noBadge
            />
          </div>
        ) : null}

        {/* 4 Tabs - spends, budgets etc */}
        {/* In budget slider, no tabs are shown (SpendTab's content, i.e. tabbed table is shown) */}
        <div className="mt-9">
          {sliderType === BUDGET ? null : (
            <Tabs
              items={tabs}
              selectedTab={selectedSliderTab.key}
              setCurrentTab={setSelectedSliderTab}
              mode
            />
          )}
          <div className="h-full mt-9 mb-11">
            <TabHelper
              tab={
                sliderType === BUDGET
                  ? DEPARTMENT_SLIDER_TAB_KEYS.SPENDS
                  : selectedSliderTab.key
              }
              sliderData={sliderData}
              parentSliderType={sliderType}
            />
          </div>
        </div>
      </div>
      {/* some tabs have a footer, some don't */}
      {/* this is the portal destination (the tab portals the footer node into this) */}
      <div className="slider-footer" id="department-detail-slider" />
      <DeleteBudgetModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        handleDelete={deleteBudgetHandler}
      />
    </>
  );
}

LocationDepartmentDetails.propTypes = {
  sliderType: PropTypes.string, // department | location | project | budget
};
