import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage } from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import {
  DELETE_FAILED,
  DELETE_SUCCESS,
  EXPORTS_EMAIL_STATUS_CONFIG,
  EXPORTS_TOAST_CONFIG,
  EXPORT_EMAIL_STATUS,
  EXPORT_STATUS,
} from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import API from "@/api";

const initialState = {
  exports: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetchingExports: false,
    hasMore: true,
  },
  exportData: null,
  filters: [AVAILABLE_FILTER_KEYS.searchAndFilter],
};
const exportsSlice = createSlice({
  name: "exports",
  initialState,
  reducers: {
    setExports(state, action) {
      state.exports.list = action.payload;
    },
    setIsFetchingExports(state, action) {
      state.exports.isFetchingExports = action.payload;
    },
    setExportTotal(state, action) {
      state.exports.total = action.payload;
    },
    resetExportsStoreState(state) {
      state.exports = initialState.exports;
    },
    addExports(state, action) {
      state.exports.list = [...state.exports.list, ...action.payload];
    },
    setExportspage(state, action) {
      state.exports.page = action.payload;
    },
    setExportsHasMore(state) {
      state.exports.hasMore = state.exports.list.length < state.exports.total;
    },
    setExportsLimit(state, action) {
      state.exports.limit = action.payload;
    },
    setExportData(state, action) {
      state.exportData = action.payload;
    },
  },
});

export const createExport = createAsyncThunk(
  "exports/createExport",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingExports(true));
    const { onSuccess = () => {}, ...rest } = params;
    const [err, response] = await to(API.Exports.create(rest));

    if (response && !err) {
      let config = EXPORTS_TOAST_CONFIG[EXPORT_STATUS.initiated];
      if (params.send_to_mail) {
        config = EXPORTS_EMAIL_STATUS_CONFIG[EXPORT_EMAIL_STATUS.EMAIL_SENT];
      } else {
        config.description = config.description.replace(
          "{{downloadsPagePath}}",
          `${ROUTES.exports.base.absolutePath}`
        );
      }
      vToast(config);
      onSuccess();
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchExports = createAsyncThunk(
  "exports/fetchExports",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingExports(true));
    const [err, response] = await to(API.Exports.all(params));
    if (response?.data) {
      if (response.data.page === 1) dispatch(setExports(response?.data?.list));
      else dispatch(addExports(response?.data?.list));
      dispatch(setExportTotal(response?.data?.total));
      dispatch(setExportsLimit(response?.data?.limit));
      dispatch(setExportsHasMore());
    } else console.error(err);
    dispatch(setIsFetchingExports(false));
  }
);
export const deleteExports = createAsyncThunk(
  "exports/deleteExports",
  async (params, { dispatch }) => {
    const { onSuccess, ...rest } = params;
    const [err, response] = await to(API.Exports.delete(rest));
    if (response?.data) {
      if (onSuccess) onSuccess();
      vToast(EXPORTS_TOAST_CONFIG[DELETE_SUCCESS]);
    } else {
      vToast(EXPORTS_TOAST_CONFIG[DELETE_FAILED]);
    }
  }
);
export const retryExports = createAsyncThunk(
  "exports/retryExports",
  async (params, { dispatch }) => {
    const { onSuccess, ...rest } = params;
    const [err, response] = await to(API.Exports.retry(rest));
    if (response?.data && !err) {
      if (onSuccess) onSuccess();
      const config = EXPORTS_TOAST_CONFIG[EXPORT_STATUS.initiated];
      config.description = config.description.replace(
        "{{downloadsPagePath}}",
        `${ROUTES.exports.base.absolutePath}`
      );
      vToast(config);
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);
export const {
  setExports,
  setIsFetchingExports,
  setExportTotal,
  resetExportsStoreState,
  addExports,
  setExportsLimit,
  setExportsHasMore,
  setExportspage,
  setExportData,
} = exportsSlice.actions;

export default exportsSlice.reducer;
