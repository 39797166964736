import PropTypes from "prop-types";
import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { toggleClientSetting } from "@/store/reducers/client";
import { fetchRoles } from "@/store/reducers/company";

import { splitExpenseEnabledSelector } from "@/store/selectors/client";

import Icon from "@/components/core/Icon";
// core components
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";

// constants
import { GENERAL_SETTINGS_CONFIG } from "@/constants/Cards";
import { QRPAY_GENERAL_SETTINGS_CONFIG } from "@/constants/qrPayments";

function GeneralSettingsPageHelper({ page }) {
  const [searchParam, setSearchParams] = useSearchParams();
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;
  const splitExpenseEnabled = useSelector(splitExpenseEnabledSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);

  const GENERAL_PAGE_SETTINGS_CONFIG = isExpensesPage
    ? GENERAL_SETTINGS_CONFIG
    : QRPAY_GENERAL_SETTINGS_CONFIG;
  return (
    <div className="mt-5">
      {[
        GENERAL_PAGE_SETTINGS_CONFIG?.map(
          (generalSetting, index, { length }) => (
            <>
              <div className="flex w-full my-8 gap-17" key={index}>
                <div className="flex flex-col w-2/5 gap-2 py-5">
                  <Text
                    color="neutral-800"
                    classes="text-md font-semibold"
                    translationKey={generalSetting.title}
                  />
                  <Text
                    color="neutral-500"
                    classes="text-sm font-medium"
                    translationKey={generalSetting.description}
                  />
                </div>
                <div className="w-3/5 p-5 cursor-pointer hover:bg-neutral-50">
                  <div
                    className="flex justify-between w-full"
                    onClick={
                      generalSetting.child?.searchParam
                        ? () => {
                            searchParam.append(
                              generalSetting.child?.searchParam,
                              generalSetting.child?.searchValue
                            );
                            setSearchParams(searchParam);
                          }
                        : () => {}
                    }
                  >
                    <div className="flex flex-col w-4/5 gap-2">
                      <div className="flex items-center gap-3">
                        {generalSetting.child?.type === "switch" && (
                          <Switch
                            name="switchSplitExpense"
                            value={splitExpenseEnabled}
                            handleChange={(e) => {
                              dispatch(
                                toggleClientSetting({
                                  setting: generalSetting.child?.setting,
                                  value: e,
                                })
                              );
                            }}
                          />
                        )}

                        <Text
                          color="neutral-800"
                          classes="text-md font-semibold"
                          translationKey={generalSetting.child.title}
                        />
                      </div>
                      <Text
                        color="neutral-500"
                        classes="text-sm font-medium"
                        translationKey={generalSetting.child.description}
                      />
                    </div>
                    {generalSetting.child?.searchParam ? (
                      <Icon name="ArrowForward" />
                    ) : null}
                  </div>
                </div>
              </div>
              {index === length - 1 ? null : (
                <div className="my-3 border-b-[1px] border-neutral-300" />
              )}
            </>
          )
        ),
      ]}
    </div>
  );
}

export default GeneralSettingsPageHelper;
GeneralSettingsPageHelper.propTypes = {
  page: PropTypes.string,
};
