import { ReactComponent as CompanyNotFound } from "../images/CompanyNotFound.svg";
import { ReactComponent as Error404 } from "./404Error.svg";
import { ReactComponent as AcUnit } from "./AcUnit.svg";
import { ReactComponent as AcUnits } from "./AcUnits.svg";
import { ReactComponent as AccessDeniedError } from "./AccessDeniedError.svg";
import { ReactComponent as AccountBalance } from "./AccountBalance.svg";
import { ReactComponent as AccountBalanceWallet } from "./AccountBalanceWallet.svg";
import { ReactComponent as AccountBox } from "./AccountBox.svg";
import { ReactComponent as AccountDetails } from "./AccountDetails.svg";
import { ReactComponent as AccountSetUp } from "./AccountSetUp.svg";
import { ReactComponent as Accounting } from "./Accounting.svg";
import { ReactComponent as AccountingTree } from "./AccountingTree.svg";
import { ReactComponent as Add } from "./Add.svg";
import { ReactComponent as AddBusiness } from "./AddBusiness.svg";
// Dashboard Quick access icons
import { ReactComponent as AddCard } from "./AddCard.svg";
import { ReactComponent as AddLocationAlt } from "./AddLocationAlt.svg";
import { ReactComponent as AddMileageRate } from "./AddMileageRate.svg";
import { ReactComponent as AddNotes } from "./AddNotes.svg";
import { ReactComponent as AddOnField } from "./AddOnField.svg";
// Dashboard My accounts section icons
import { ReactComponent as AddReceiveAmount } from "./AddReceiveAmount.svg";
import { ReactComponent as Adjustment } from "./Adjustment.svg";
import { ReactComponent as Admin } from "./Admin.svg";
import { ReactComponent as AdsClick } from "./AdsClick.svg";
import { ReactComponent as AirlineStops } from "./AirlineStops.svg";
import { ReactComponent as AirplaneTicket } from "./AirplaneTicket.svg";
import { ReactComponent as AlarmOn } from "./AlarmOn.svg";
import { ReactComponent as Alert } from "./Alert.svg";
import { ReactComponent as Analytics } from "./Analytics.svg";
import { ReactComponent as ArmingCountdown } from "./ArmingCountdown.svg";
import { ReactComponent as ArrowDownward } from "./ArrowDownward.svg";
import { ReactComponent as ArrowDropDown } from "./ArrowDropDown.svg";
import { ReactComponent as ArrowDropUp } from "./ArrowDropUp.svg";
import { ReactComponent as ArrowForward } from "./ArrowForward.svg";
import { ReactComponent as ArrowLeft } from "./ArrowLeft.svg";
import { ReactComponent as ArrowUpward } from "./ArrowUpward.svg";
import { ReactComponent as Article2 } from "./Article2.svg";
import { ReactComponent as Article } from "./Article.svg";
import { ReactComponent as Assignment } from "./Assignment.svg";
import { ReactComponent as AssignmentAddOn } from "./AssignmentAddOn.svg";
import { ReactComponent as Attach } from "./Attach.svg";
import { ReactComponent as Authenticator } from "./Authenticator.svg";
import { ReactComponent as AverageTimer } from "./AverageTimer.svg";
import { ReactComponent as BackArrow } from "./BackArrow.svg";
import { ReactComponent as Backup } from "./Backup.svg";
import { ReactComponent as Badge } from "./Badge.svg";
// Alert Icons
import { ReactComponent as BellPlus } from "./BellPlus.svg";
import { ReactComponent as BellWarning } from "./BellWarning.svg";
import { ReactComponent as BillPay } from "./BillPay.svg";
import { ReactComponent as BillPayCancel } from "./BillPayCancel.svg";
import { ReactComponent as BillPayRequests } from "./BillPayRequests.svg";
import { ReactComponent as BinClock } from "./BinClock.svg";
import { ReactComponent as Block } from "./Block.svg";
import { ReactComponent as Bolt } from "./Bolt.svg";
import { ReactComponent as Book } from "./Book.svg";
import { ReactComponent as BookOpen } from "./BookOpen.svg";
import { ReactComponent as Bulb } from "./Bulb.svg";
import { ReactComponent as CSV } from "./CSV.svg";
import { ReactComponent as CSVFile } from "./CSVFile.svg";
import { ReactComponent as Cached } from "./Cached.svg";
import { ReactComponent as Calculate } from "./Calculate.svg";
import { ReactComponent as Calendar } from "./Calendar.svg";
import { ReactComponent as CalendarAddOn } from "./CalendarAddOn.svg";
import { ReactComponent as CalendarClock } from "./CalendarClock.svg";
import { ReactComponent as Call } from "./Call.svg";
import { ReactComponent as CallReceived } from "./CallReceived.svg";
import { ReactComponent as CallReceivedReverse } from "./CallReceivedReverse.svg";
import { ReactComponent as CallSplit } from "./CallSplit.svg";
import { ReactComponent as Cancel } from "./Cancel.svg";
import { ReactComponent as CaptivePortal } from "./CaptivePortal.svg";
import { ReactComponent as Card } from "./Card.svg";
import { ReactComponent as CardChecked } from "./CardChecked.svg";
import { ReactComponent as CardState } from "./CardState.svg";
import { ReactComponent as CardStraps } from "./CardStraps.svg";
import { ReactComponent as Cashback } from "./Cashback.svg";
import { ReactComponent as CategoriesIcon } from "./CategoriesIcon.svg";
import { ReactComponent as Celebration } from "./Celebration.svg";
import { ReactComponent as ChangePin } from "./ChangePin.svg";
import { ReactComponent as Chat } from "./Chat.svg";
import { ReactComponent as CheckCircle } from "./CheckCircle.svg";
import { ReactComponent as CheckedHeart } from "./CheckedHeart.svg";
import { ReactComponent as Checklist } from "./Checklist.svg";
import { ReactComponent as ChevronLeft } from "./ChevronLeft.svg";
import { ReactComponent as Right } from "./ChevronRight.svg";
import { ReactComponent as ChevronUp } from "./ChevronUp.svg";
import { ReactComponent as ChevronDown } from "./Chevron_Down.svg";
import { ReactComponent as CircleCheck } from "./CircleCheck.svg";
import { ReactComponent as ClearInput } from "./ClearInput.svg";
import { ReactComponent as Close } from "./Close.svg";
import { ReactComponent as Cloud } from "./Cloud.svg";
import { ReactComponent as CloudSync } from "./CloudSync.svg";
import { ReactComponent as Collapse } from "./Collapse.svg";
import { ReactComponent as Columns } from "./Columns.svg";
import { ReactComponent as Company } from "./Company.svg";
import { ReactComponent as Contacts } from "./Contacts.svg";
import { ReactComponent as ContentPaste } from "./ContentPaste.svg";
import { ReactComponent as ContentPasteSearch } from "./ContentPasteSearch.svg";
import { ReactComponent as Copy } from "./Copy.svg";
import { ReactComponent as CreditCard } from "./CreditCard.svg";
import { ReactComponent as CreditCardGear } from "./CreditCardGear.svg";
import { ReactComponent as CreditPaid } from "./CreditPaid.svg";
import { ReactComponent as CreditPending } from "./CreditPending.svg";
import { ReactComponent as Dashboard2 } from "./Dashboard2.svg";
import { ReactComponent as Dashboard } from "./Dashboard.svg";
import { ReactComponent as DashboardCustomise } from "./DashboardCustomise.svg";
import { ReactComponent as DashboardOther } from "./DashboardOther.svg";
import { ReactComponent as DateRangeArrow } from "./DateRangeArrow.svg";
import { ReactComponent as Delete } from "./Delete.svg";
import { ReactComponent as Department } from "./Department.svg";
import { ReactComponent as Discussion } from "./Discussion.svg";
import { ReactComponent as DoNotDisturb } from "./DoNotDisturb.svg";
import { ReactComponent as Dollar } from "./Dollar.svg";
import { ReactComponent as Done } from "./Done.svg";
import { ReactComponent as DonutLarge } from "./DonutLarge.svg";
import { ReactComponent as Dot } from "./Dot.svg";
import { ReactComponent as DottedDesign } from "./DottedDesign.svg";
import { ReactComponent as Down } from "./Down.svg";
import { ReactComponent as DownArrow } from "./DownArrow.svg";
import { ReactComponent as DownRight } from "./DownRight.svg";
import { ReactComponent as Download } from "./Download.svg";
import { ReactComponent as Downtime } from "./Downtime.svg";
import { ReactComponent as EArrow } from "./EArrow.svg";
import { ReactComponent as Edit } from "./Edit.svg";
import { ReactComponent as EditCalendar } from "./EditCalendar.svg";
import { ReactComponent as EditCard } from "./EditCard.svg";
import { ReactComponent as EditInternationalCard } from "./EditInternationalCard.svg";
import { ReactComponent as EditNotification } from "./EditNotification.svg";
import { ReactComponent as EditUnderline } from "./EditUnderline.svg";
import { ReactComponent as Ellipse } from "./Ellipse.svg";
import { ReactComponent as Email } from "./Email.svg";
import { ReactComponent as Emoji } from "./Emoji.svg";
import { ReactComponent as EmojiFlagSolid } from "./EmojiFlagSolid.svg";
import { ReactComponent as Encrypted } from "./Encrypted.svg";
import { ReactComponent as Entity } from "./Entity.svg";
import { ReactComponent as Error } from "./Error.svg";
import { ReactComponent as EventRepeat } from "./EventRepeat.svg";
// Dashboard My accounts section icons
import { ReactComponent as Exchange } from "./Exchange.svg";
import { ReactComponent as Expand } from "./Expand.svg";
import { ReactComponent as ExpandMore } from "./ExpandMore.svg";
import { ReactComponent as Expense } from "./Expense.svg";
import { ReactComponent as ExpenseChart } from "./ExpenseChart.svg";
import { ReactComponent as Eye } from "./Eye.svg";
import { ReactComponent as EyeTracking } from "./EyeTracking.svg";
import { ReactComponent as FactCheck } from "./FactCheck.svg";
import { ReactComponent as FeatureSearch } from "./FeatureSearch.svg";
import { ReactComponent as FileCopy } from "./FileCopy.svg";
import { ReactComponent as FilledSchedule } from "./FilledSchedule.svg";
import { ReactComponent as FilterList } from "./FilterList.svg";
import { ReactComponent as Flag } from "./Flag.svg";
import { ReactComponent as FlexibleLimit } from "./FlexibleLimit.svg";
import { ReactComponent as FormatLetterSpacing } from "./FormatLetterSpacing.svg";
import { ReactComponent as Forum } from "./Forum.svg";
import { ReactComponent as Frequency } from "./Frequency.svg";
import { ReactComponent as Google } from "./Google.svg";
import { ReactComponent as Group } from "./Group.svg";
import { ReactComponent as Groups } from "./Groups.svg";
import { ReactComponent as GroupsAdd } from "./GroupsAdd.svg";
import { ReactComponent as Headset } from "./Headset.svg";
import { ReactComponent as Heart } from "./Heart.svg";
import { ReactComponent as Help } from "./Help.svg";
import { ReactComponent as Hourglass } from "./Hourglass.svg";
import { ReactComponent as HowToReg } from "./HowToReg.svg";
import { ReactComponent as InCompleteCircle } from "./InCompleteCircle.svg";
import { ReactComponent as Inbox } from "./Inbox.svg";
import { ReactComponent as Info } from "./Info.svg";
// Dashboard Quick access icons
import { ReactComponent as Integrations } from "./Integrations.svg";
import { ReactComponent as InternationalCardTopUpRequest } from "./InternationalCardTopUpRequest.svg";
import { ReactComponent as InternationalCards } from "./InternationalCards.svg";
import { ReactComponent as InvoiceFromMail } from "./InvoiceFromMail.svg";
import { ReactComponent as InvoicePayment } from "./InvoicePayment.svg";
import { ReactComponent as InvoiceUploaded } from "./InvoiceUploaded.svg";
import { ReactComponent as LearnMore } from "./LearnMore.svg";
import { ReactComponent as LeftArrow } from "./LeftArrow.svg";
import { ReactComponent as LightBulb } from "./LightBulb.svg";
import { ReactComponent as LightiningBolt } from "./LightiningBolt.svg";
import { ReactComponent as LightningBolt } from "./LightningBolt.svg";
import { ReactComponent as LightningBoltCustom } from "./LightningBoltCustom.svg";
import { ReactComponent as Link } from "./Link.svg";
import { ReactComponent as ListAlt } from "./ListAlt.svg";
import { ReactComponent as LoadingCircle } from "./LoadingCircle.svg";
import { ReactComponent as LocalATM } from "./LocalATM.svg";
import { ReactComponent as Location } from "./Location.svg";
import { ReactComponent as LocationOn } from "./LocationOn.svg";
import { ReactComponent as Lock } from "./Lock.svg";
import { ReactComponent as LockClock } from "./LockClock.svg";
import { ReactComponent as Mail } from "./Mail.svg";
import { ReactComponent as ManageAccount } from "./ManageAccount.svg";
import { ReactComponent as MapArrow } from "./MapArrow.svg";
import { ReactComponent as MarkEmail } from "./MarkEmail.svg";
import { ReactComponent as MasterCard } from "./MasterCard.svg";
import { ReactComponent as MeatballsMenu } from "./MeatballsMenu.svg";
import { ReactComponent as Memo } from "./Memo.svg";
import { ReactComponent as Memo_NotPresent } from "./Memo_NotPresent.svg";
import { ReactComponent as Memo_Present } from "./Memo_Present.svg";
import { ReactComponent as Menu } from "./Menu.svg";
import { ReactComponent as MintMark } from "./MintMark.svg";
import { ReactComponent as Minus } from "./Minus.svg";
import { ReactComponent as MissingBadge } from "./MissingBadge.svg";
import { ReactComponent as Mobile } from "./Mobile.svg";
import { ReactComponent as ModeComment } from "./ModeComment.svg";
import { ReactComponent as MoneyIn } from "./MoneyIn.svg";
import { ReactComponent as MoneyOut } from "./MoneyOut.svg";
import { ReactComponent as MoreVerticle } from "./MoreVerticle.svg";
import { ReactComponent as MoveDown } from "./MoveDown.svg";
import { ReactComponent as MoveToInbox } from "./MoveToInbox.svg";
import { ReactComponent as NeedAnyHelp } from "./NeedAnyHelp.svg";
import { ReactComponent as NewReleases } from "./NewReleases.svg";
import { ReactComponent as NoChange } from "./Nochange.svg";
import { ReactComponent as NonFlexibleLimit } from "./NonFlexibleLimit.svg";
import { ReactComponent as NEArrow } from "./NorthEastArrow.svg";
import { ReactComponent as NotificationActive } from "./NotificaitonActive.svg";
// navigation
import { ReactComponent as Notification } from "./Notification.svg";
import { ReactComponent as Notifications } from "./Notifications.svg";
import { ReactComponent as OCRHigh } from "./OCRHigh.svg";
import { ReactComponent as OCRLow } from "./OCRLow.svg";
import { ReactComponent as OCRMedium } from "./OCRMedium.svg";
import { ReactComponent as OCRPending } from "./OCRPending.svg";
import { ReactComponent as OfflineBolt } from "./OfflineBolt.svg";
import { ReactComponent as OpenInNew } from "./OpenInNew.svg";
import { ReactComponent as OutgoingMail } from "./OutgoingMail.svg";
import { ReactComponent as OutstandingClaims } from "./OutstandingClaims.svg";
import { ReactComponent as Pace } from "./Pace.svg";
import { ReactComponent as PageInfo } from "./PageInfo.svg";
import { ReactComponent as PageNotFound } from "./PageNotFound.svg";
import { ReactComponent as Paid } from "./Paid.svg";
import { ReactComponent as Password } from "./Password.svg";
import { ReactComponent as PayOutsideVolopay } from "./PayOutsideVolopay.svg";
import { ReactComponent as Payments } from "./Payments.svg";
import { ReactComponent as PaymentColor } from "./PaymentsColor.svg";
import { ReactComponent as PendingActions } from "./PendingActions.svg";
import { ReactComponent as PendingToVerify } from "./PendingToVerify.svg";
import { ReactComponent as Percentage } from "./Percentage.svg";
import { ReactComponent as Person } from "./Person.svg";
import { ReactComponent as PersonAdd } from "./PersonAdd.svg";
import { ReactComponent as PersonCircled } from "./PersonCircled.svg";
import { ReactComponent as PersonSearch } from "./PersonSearch.svg";
import { ReactComponent as PersonWithCircle } from "./PersonWithCircle.svg";
import { ReactComponent as PhotoCamera } from "./PhotoCamera.svg";
import { ReactComponent as PhysicalCardEditLimit } from "./PhysicalCardEditLimit.svg";
import { ReactComponent as PhysicalCardRequest } from "./PhysicalCardRequest.svg";
import { ReactComponent as PieChart } from "./PieChart.svg";
import { ReactComponent as PineLabs } from "./Pinelabs.svg";
import { ReactComponent as Play } from "./Play.svg";
import { ReactComponent as PlayCircle } from "./PlayCircle.svg";
import {
  ReactComponent as PlaylistAddCheck,
  ReactComponent as Review,
} from "./PlaylistAddCheck.svg";
import { ReactComponent as PlaylistRemove } from "./PlaylistRemove.svg";
import { ReactComponent as Plus } from "./Plus.svg";
import { ReactComponent as PriceChange } from "./PriceChange.svg";
import { ReactComponent as PriceCheck } from "./PriceCheck.svg";
import { ReactComponent as Priority } from "./Priority.svg";
import { ReactComponent as Processing } from "./Processing.svg";
import { ReactComponent as QrCode } from "./QrCode.svg";
import { ReactComponent as QrPay } from "./QrPay.svg";
import { ReactComponent as Quiz } from "./Quiz.svg";
import { ReactComponent as ReadInbox } from "./ReadInbox.svg";
import { ReactComponent as Recurring } from "./Reccuring.svg";
import { ReactComponent as Receipt } from "./Receipt.svg";
import { ReactComponent as ReceiptChecked } from "./ReceiptChecked.svg";
import { ReactComponent as ReceiptStatus } from "./ReceiptStatus.svg";
import { ReactComponent as ReceiptAutoCaptured } from "./Receipt_AutoCaptured.svg";
import { ReactComponent as ReceiptAutoVerified } from "./Receipt_AutoVerified.svg";
import { ReactComponent as ReceiptMissing } from "./Receipt_Missing.svg";
import { ReactComponent as ReceiptNotRequested } from "./Receipt_NotRequested.svg";
import { ReactComponent as ReceiptSubmitted } from "./Receipt_Submitted.svg";
import { ReactComponent as Received } from "./Received.svg";
import { ReactComponent as Recreated } from "./Recreated.svg";
import { ReactComponent as Redeem } from "./Redeem.svg";
import { ReactComponent as Refresh } from "./Refresh.svg";
import { ReactComponent as RefreshTime } from "./RefreshTime.svg";
import { ReactComponent as Reimbursement } from "./Reimbursement.svg";
import { ReactComponent as ReimbursementRequests } from "./ReimbursementRequests.svg";
import { ReactComponent as Remind } from "./Remind.svg";
import { ReactComponent as Repeat } from "./Repeat.svg";
import { ReactComponent as Replay } from "./Replay.svg";
import { ReactComponent as Report } from "./Report.svg";
import { ReactComponent as ReportFailed } from "./ReportFailed.svg";
import { ReactComponent as RequestClaim } from "./RequestClaim.svg";
import { ReactComponent as RightArrow } from "./RightArrow.svg";
import { ReactComponent as Rotate } from "./Rotate.svg";
import { ReactComponent as RoundedCloud } from "./RoundedCloud.svg";
import { ReactComponent as RoundedCreditCard } from "./RoundedCreditCard.svg";
import { ReactComponent as RoundedEditCloud } from "./RoundedEditCloud.svg";
import { ReactComponent as RoundedEditCreditCard } from "./RoundedEditCreditCard.svg";
import { ReactComponent as Rupay } from "./Rupay.svg";
import { ReactComponent as Savings } from "./Savings.svg";
import { ReactComponent as SavingsFill } from "./SavingsFill.svg";
import { ReactComponent as Schedule } from "./Schedule.svg";
import { ReactComponent as Search } from "./Search.svg";
import { ReactComponent as SelectGesture } from "./SelectGesture.svg";
import { ReactComponent as Send } from "./Send.svg";
import { ReactComponent as Settings } from "./Settings.svg";
import { ReactComponent as SettingsAccount } from "./SettingsAccount.svg";
import { ReactComponent as SettingsSync } from "./SettingsSync.svg";
import { ReactComponent as Share } from "./Share.svg";
import { ReactComponent as Sheild } from "./Sheild.svg";
import { ReactComponent as ShieldCross } from "./ShieldCross.svg";
import { ReactComponent as ShoppingBag } from "./ShoppingBag.svg";
import { ReactComponent as Slack } from "./Slack.svg";
import { ReactComponent as SlashEye } from "./SlashEye.svg";
import { ReactComponent as Snowflake } from "./Snowflake.svg";
import { ReactComponent as SolidCancel } from "./SolidCancel.svg";
import { ReactComponent as SolidCheckCircle } from "./SolidCheckCircle.svg";
import { ReactComponent as SpinLoader } from "./SpinLoader.svg";
import { ReactComponent as Store } from "./Store.svg";
import { ReactComponent as SubPolicy_Met } from "./SubPolicy_Met.svg";
import { ReactComponent as SubPolicy_NotMet } from "./SubPolicy_NotMet.svg";
import { ReactComponent as SubdirectoryArrowRight } from "./SubdirectoryArrowRight.svg";
import { ReactComponent as SubmissionPolicy } from "./SubmissionPolicy.svg";
import { ReactComponent as SupervisorAccount } from "./SupervisorAccount.svg";
import { ReactComponent as SupportAgent } from "./SupportAgent.svg";
import { ReactComponent as SwapHorizontal } from "./SwapHorizontal.svg";
import { ReactComponent as SwapHorzontal } from "./SwapHorzontal.svg";
import { ReactComponent as SwiftLogo } from "./SwiftLogo.svg";
import { ReactComponent as Sync } from "./Sync.svg";
import { ReactComponent as SyncAlt } from "./SyncAlt.svg";
import { ReactComponent as SyncDisabled } from "./SyncDisabled.svg";
import { ReactComponent as SyncFailed } from "./SyncFailed.svg";
import { ReactComponent as TArrow } from "./TArrow.svg";
import { ReactComponent as Task } from "./Task.svg";
import { ReactComponent as TaskAlt } from "./TaskAlt.svg";
import { ReactComponent as TaskCheck } from "./TaskCheck.svg";
import { ReactComponent as TeamDashboard } from "./TeamDashboard.svg";
import { ReactComponent as ThumbsUp } from "./ThumbsUp.svg";
import { ReactComponent as Timer } from "./Timer.svg";
import { ReactComponent as ToLink } from "./ToLink.svg";
import { ReactComponent as Toll } from "./Toll.svg";
import { ReactComponent as TourFlag } from "./TourFlag.svg";
import { ReactComponent as TrendingDown } from "./TrendingDown.svg";
import { ReactComponent as TrendingUp } from "./TrendingUp.svg";
import { ReactComponent as Triggers } from "./Triggers.svg";
import { ReactComponent as Type } from "./Type.svg";
import { ReactComponent as Unsynced } from "./Unsynced.svg";
import { ReactComponent as UpArrow } from "./UpArrow.svg";
import { ReactComponent as Upload } from "./Upload.svg";
import { ReactComponent as Vector } from "./Vector.svg";
import { ReactComponent as Verified } from "./Verified.svg";
import { ReactComponent as VerifiedUser } from "./VerifiedUser.svg";
import { ReactComponent as Verify } from "./Verify.svg";
import { ReactComponent as VideoCam } from "./VideoCam.svg";
import { ReactComponent as VirtualCardEditRequest } from "./VirtualEditLimitRequest.svg";
import { ReactComponent as VisaCorporateSvg } from "./VisaCorporateSvg.svg";
import { ReactComponent as Volopay3D } from "./Volopay3D.svg";
import { ReactComponent as VolopayLogo } from "./VolopayLogo.svg";
import { ReactComponent as VolopayLogoOnCard } from "./VolopayLogoOnCard.svg";
import { ReactComponent as Wallet } from "./Wallet.svg";
import { ReactComponent as WalletLimit } from "./WalletLimit.svg";
import { ReactComponent as WalletState } from "./WalletState.svg";
import { ReactComponent as WarningCircle } from "./WarningCircle.svg";
import { ReactComponent as WarningCircleFill } from "./WarningCircleFill.svg";
import { ReactComponent as WarningHex } from "./WarningHex.svg";
import { ReactComponent as WarningTriangle } from "./WarningTriangle.svg";
import { ReactComponent as WhatsApp } from "./WhatsApp.svg";
import { ReactComponent as World } from "./World.svg";
import { ReactComponent as Deskera } from "./accounting/Deskera.svg";
import { ReactComponent as MYOB } from "./accounting/MYOB.svg";
import { ReactComponent as Netsuite } from "./accounting/Netsuite.svg";
import { ReactComponent as Power } from "./accounting/Power.svg";
import { ReactComponent as QuickBooks } from "./accounting/Quickbooks.svg";
import { ReactComponent as Tally } from "./accounting/Tally.svg";
import { ReactComponent as UniversalCSV } from "./accounting/UniversalCSV.svg";
import { ReactComponent as Xero } from "./accounting/Xero.svg";
import { ReactComponent as ZohoBooks } from "./accounting/Zohobooks.svg";
import { ReactComponent as Comments } from "./comments.svg";
import { ReactComponent as Communication } from "./communication.svg";
import { ReactComponent as FreezeCardIcon } from "./freezeCard.svg";
import { ReactComponent as FreezeCloud } from "./freezeCloud.svg";
import { ReactComponent as GppBad } from "./gppBad.svg";
import { ReactComponent as IosArrowBack } from "./iosArrowBack.svg";
import { ReactComponent as IosArrowForward } from "./iosArrowForward.svg";
import { ReactComponent as Pdf } from "./pdf.svg";
import { ReactComponent as pdfDocument } from "./pdfDocument.svg";
import { ReactComponent as Png } from "./png.svg";
import { ReactComponent as Reject } from "./reject.svg";
import { ReactComponent as StepOver } from "./stepOver.svg";
import { ReactComponent as Zoom } from "./zoom.svg";

export default {
  ReportFailed,
  MintMark,
  InternationalCardTopUpRequest,
  VisaCorporateSvg,
  EditInternationalCard,
  CreditCardGear,
  InternationalCards,
  Timer,
  MasterCard,
  InCompleteCircle,
  Downtime,
  ReceiptStatus,
  ReadInbox,
  Play,
  Bulb,
  MarkEmail,
  SupportAgent,
  FeatureSearch,
  Book,
  EyeTracking,
  Heart,
  ListAlt,
  AcUnit,
  PineLabs,
  Rupay,
  NewReleases,
  VideoCam,
  FilledSchedule,
  World,
  PhysicalCardEditLimit,
  PhysicalCardRequest,
  VirtualCardEditRequest,
  CardStraps,
  NotificationActive,
  Priority,
  Celebration,
  FilterList,
  CallReceived,
  CallReceivedReverse,
  RoundedCloud,
  RoundedEditCloud,
  RoundedCreditCard,
  RoundedEditCreditCard,
  Recurring,
  TourFlag,
  VolopayLogo,
  VolopayLogoOnCard,
  Dashboard,
  DashboardOther,
  Expense,
  Notification,
  Card,
  Right,
  Info,
  Close,
  Upload,
  Contacts,
  Delete,
  Department,
  Download,
  BillPay,
  Reimbursement,
  Accounting,
  Company,
  Settings,
  ShoppingBag,
  AccountBalanceWallet,
  Received,
  Discussion,
  Verify,
  Flag,
  Google,
  SyncDisabled,
  // sync icons
  Copy,
  AccountSetUp,
  Comments,
  Location,
  Sync,
  DateRangeArrow,
  Calendar,
  Eye,
  SlashEye,
  CallSplit,
  NEArrow,
  BackArrow,
  ArrowForward,
  Plus,
  Cloud,
  CreditCard,
  CSVFile,
  Person,
  Edit,
  Password,
  Snowflake,
  ChevronDown,
  SubPolicy_Met,
  SubPolicy_NotMet,
  ReceiptSubmitted,
  ReceiptAutoCaptured,
  ReceiptAutoVerified,
  ReceiptMissing,
  ReceiptNotRequested,
  Memo_Present,
  Memo_NotPresent,
  Dollar,
  ThumbsUp,
  ArrowLeft,
  MissingBadge,
  PageNotFound,
  Down,
  VerifiedUser,
  ReceiptChecked,
  SubmissionPolicy,
  Receipt,
  CreditPending,
  CreditPaid,
  Remind,
  ChevronLeft,
  CSV,
  Mail,
  ManageAccount,
  SettingsSync,
  SettingsAccount,
  Savings,
  AccountingTree,

  // Alert Icons
  BellPlus,
  BellWarning,
  BinClock,
  WarningHex,
  WarningCircle,
  WarningTriangle,
  Search,
  ToLink,
  Memo,

  Hourglass,

  SyncAlt,
  DownArrow,
  TArrow,
  Assignment,
  AssignmentAddOn,
  AddOnField,
  Done,
  Emoji,
  Attach,
  MoreVerticle,
  Add,
  AddMileageRate,
  Review,
  UpArrow,
  Task,
  TaskCheck,
  Schedule,
  LeftArrow,
  RightArrow,
  Processing,

  // Dashboard My accounts section icons
  AddReceiveAmount,
  AccountDetails,
  Exchange,
  MeatballsMenu,
  MoveDown,
  Payments,
  SavingsFill,
  Toll,
  CaptivePortal,

  // Dashboard Quick access icons
  AddCard,
  PersonAdd,
  Integrations,
  OutstandingClaims,
  RequestClaim,
  Triggers,

  // Dashboard Quick access - all icons
  AddBusiness,
  AddLocationAlt,
  Calculate,
  ContentPaste,
  ContentPasteSearch,
  Group,
  Groups,
  GroupsAdd,
  LocalATM,
  LocationOn,
  OpenInNew,
  PendingActions,
  Send,
  Store,
  Vector,
  Verified,

  // Dashboard Pending requests icons
  BillPayRequests,
  EditCard,
  ReimbursementRequests,
  Checklist,
  PendingToVerify,
  Cancel,
  BillPayCancel,
  ShieldCross,
  EArrow,
  TrendingUp,
  TrendingDown,
  SupervisorAccount,

  Adjustment,
  InvoicePayment,
  Minus,
  PlayCircle,
  Replay,
  Repeat,
  Help,
  Chat,
  Redeem,
  Cashback,
  Dot,
  ArrowUpward,
  ArrowDownward,
  Notifications,
  ChevronUp,
  SwapHorzontal,
  HowToReg,
  CircleCheck,
  PersonSearch,
  PersonWithCircle,
  PersonCircled,
  GppBad,
  PlaylistRemove,
  PlaylistAddCheck,
  MoneyIn,
  MoneyOut,
  SwiftLogo,
  WarningCircleFill,
  Quiz,
  MoveToInbox,
  Xero,
  Tally,
  MYOB,
  QuickBooks,
  Netsuite,
  Deskera,
  UniversalCSV,
  ZohoBooks,
  Volopay3D,
  OfflineBolt,
  Error,
  Power,
  Sheild,
  DashboardCustomise,
  EmojiFlagSolid,
  SolidCheckCircle,
  PriceCheck,
  ModeComment,
  Alert,
  Bolt,
  CheckCircle,
  SubdirectoryArrowRight,
  SwapHorizontal,
  BookOpen,
  LightningBolt,
  SyncFailed,
  Percentage,
  Type,
  FactCheck,
  Unsynced,
  Columns,
  PriceChange,
  DoNotDisturb,
  ArrowDropUp,
  ArrowDropDown,
  FreezeCardIcon,
  FreezeCloud,
  EventRepeat,
  AcUnits,
  ChangePin,
  Block,
  Pace,
  CalendarAddOn,
  CalendarClock,
  EditCalendar,
  Recreated,
  CloudSync,
  ArmingCountdown,
  OutgoingMail,
  Slack,
  Refresh,
  RefreshTime,
  WhatsApp,
  NoChange,
  LightiningBolt,
  LightningBoltCustom,
  DownRight,
  MapArrow,
  AirlineStops,
  PieChart,
  FormatLetterSpacing,
  Article,
  Admin,
  CardState,
  CardChecked,
  Frequency,
  Menu,
  Collapse,
  Expand,
  Link,
  DonutLarge,
  QrCode,
  Wallet,
  WalletLimit,
  WalletState,
  AirplaneTicket,
  AlarmOn,
  Forum,
  SelectGesture,
  CheckedHeart,
  AccountBox,
  Headset,
  Backup,
  Ellipse,
  NeedAnyHelp,
  LearnMore,
  QrPay,
  Paid,
  Encrypted,
  Cached,
  PaymentColor,
  AdsClick,
  Reject,
  CompanyNotFound,
  DottedDesign,
  Share,
  Badge,
  Call,
  PhotoCamera,
  Lock,
  AverageTimer,
  AddNotes,
  FileCopy,
  PayOutsideVolopay,
  AccountBalance,
  LoadingCircle,
  ClearInput,
  CategoriesIcon,
  FlexibleLimit,
  ExpenseChart,
  TaskAlt,
  Zoom,
  Png,
  Pdf,
  IosArrowBack,
  IosArrowForward,
  pdfDocument,
  LockClock,
  Email,
  Mobile,
  Authenticator,
  StepOver,
  SpinLoader,
  TeamDashboard,
  InvoiceFromMail,
  InvoiceUploaded,
  ExpandMore,
  NonFlexibleLimit,
  Report,
  SolidCancel,
  Inbox,
  OCRHigh,
  OCRLow,
  OCRMedium,
  OCRPending,
  Communication,
  LightBulb,
  Entity,
  Article2,
  EditNotification,
  Analytics,
  PageInfo,
  Error404,
  AccessDeniedError,
  EditUnderline,
  Rotate,
};
