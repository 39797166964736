import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { INVITATION_MODE_STATUSES_CONFIG } from "@/utils/constants/common";

export default function InvitationModeIcon({ mode, index }) {
  const config = INVITATION_MODE_STATUSES_CONFIG[mode];

  return config ? (
    <>
      <div className="flex items-center" id={`invitaion-mode-tooltip-${index}`}>
        <Icon name={config.icon} className={`${config.class} p-0 w-5 h-5`} />
      </div>
      <Tooltip id={`invitaion-mode-tooltip-${index}`} direction="top">
        <Text translationKey={config.label} />
      </Tooltip>
    </>
  ) : null;
}

InvitationModeIcon.propTypes = {
  mode: PropTypes.string,
  index: PropTypes.number,
};
