import React from "react";

import ReimbursementClaimHelper from "@/components/Reimbursement/ReimbursementClaimHelper";
import { TAB_TYPES } from "@/utils/constants/myVolopay";

function MyVolopayReimbursementDraftsPage() {
  return (
    <div>
      <ReimbursementClaimHelper tabType={TAB_TYPES.DRAFTS} />
    </div>
  );
}

export default MyVolopayReimbursementDraftsPage;
