import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { ALLOCATE_FUNDS_ACCOUNT_TYPE_KEY } from "@/constants/company";

// left side: host slider
// right side one: query key which will be deleted on close of host slider
export const DEPENDENT_KEYS = {
  // [SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.controlsPage]:
  //   SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage,
  // [SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.rulesPage]:
  //   SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage,
  [SLIDERS_SEARCH_PARAMS.company.allocate]: [ALLOCATE_FUNDS_ACCOUNT_TYPE_KEY],
  [SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard]: [
    SLIDERS_SEARCH_PARAMS.cards.selectedUserCard,
  ],
  [SLIDERS_SEARCH_PARAMS.company.budget.id]: [
    SLIDERS_SEARCH_PARAMS.company.budget.type,
    SLIDERS_SEARCH_PARAMS.payments.relatedPayId,
    SLIDERS_SEARCH_PARAMS.payments.relatedPayContext,
    SLIDERS_SEARCH_PARAMS.payments.relatedPayIsPayment,
  ],
  [SLIDERS_SEARCH_PARAMS.company.budget.createForm.newDeptBudget]:
    SLIDERS_SEARCH_PARAMS.company.budget.storeMode,
  [SLIDERS_SEARCH_PARAMS.company.budget.createForm.newProjBudget]:
    SLIDERS_SEARCH_PARAMS.company.budget.storeMode,
  [SLIDERS_SEARCH_PARAMS.company.budget.editBudget.newDeptBudget]:
    SLIDERS_SEARCH_PARAMS.company.budget.storeMode,
  [SLIDERS_SEARCH_PARAMS.company.budget.createForm.newProjBudget]:
    SLIDERS_SEARCH_PARAMS.company.budget.storeMode,
  [SLIDERS_SEARCH_PARAMS.company.budget.createForm.newDeptBudget]:
    SLIDERS_SEARCH_PARAMS.company.budget.storeMode,
  [SLIDERS_SEARCH_PARAMS.cards.settings]: SLIDERS_SEARCH_PARAMS.cards.id,
  [SLIDERS_SEARCH_PARAMS.cards.editCard]: SLIDERS_SEARCH_PARAMS.cards.id,
  [SLIDERS_SEARCH_PARAMS.cards.freezeCard]: SLIDERS_SEARCH_PARAMS.cards.id,
  [SLIDERS_SEARCH_PARAMS.cards.block]: SLIDERS_SEARCH_PARAMS.cards.id,
  // [SLIDERS_SEARCH_PARAMS.cards.changePin]: SLIDERS_SEARCH_PARAMS.cards.id,
  [SLIDERS_SEARCH_PARAMS.company.department.edit]:
    SLIDERS_SEARCH_PARAMS.company.department.id,
  // ocr stuff
  [SLIDERS_SEARCH_PARAMS.payments.createBillDetails]: [
    SLIDERS_SEARCH_PARAMS.payments.ocrFetched,
    SLIDERS_SEARCH_PARAMS.payments.showCreateVendor,
    SLIDERS_SEARCH_PARAMS.payments.showOCRModal,
  ],
  [SLIDERS_SEARCH_PARAMS.payments.id]: [
    SLIDERS_SEARCH_PARAMS.payments.ocrFetched,
    SLIDERS_SEARCH_PARAMS.payments.showCreateVendor,
    SLIDERS_SEARCH_PARAMS.payments.showOCRModal,
    SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider,
    SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey,
    SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled,
  ],

  [SLIDERS_SEARCH_PARAMS.accounting.billPay.id]: [
    SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider,
    SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey,
    SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled,
  ],
  [SLIDERS_SEARCH_PARAMS.vendors.id]: SLIDERS_SEARCH_PARAMS.focus,
  [SLIDERS_SEARCH_PARAMS.employees.id]: SLIDERS_SEARCH_PARAMS.focus,
  [SLIDERS_SEARCH_PARAMS.company.people.editBankDetails]:
    SLIDERS_SEARCH_PARAMS.company.people.preAddBankDetails,
  [SLIDERS_SEARCH_PARAMS.vendors.id]: SLIDERS_SEARCH_PARAMS.scrollTo,

  // onBack scrollTo was getting removed, https://discord.com/channels/740574938263584789/1234810921671463003/1234863250102030488
  // [SLIDERS_SEARCH_PARAMS.payments.editBill]: SLIDERS_SEARCH_PARAMS.scrollTo,
  // [SLIDERS_SEARCH_PARAMS.payrollPayments.editBill]:
  //   SLIDERS_SEARCH_PARAMS.scrollTo,
};
