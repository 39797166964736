import { configureStore } from "@reduxjs/toolkit";

import accountReducer from "@/store/reducers/account";
import accountingReducer from "@/store/reducers/accounting";
import accountingTransactionsReducer from "@/store/reducers/accounting_transactions";
import actionCentreSlice from "@/store/reducers/actionCentre";
import analyticsReducer from "@/store/reducers/analytics";
import appReducer from "@/store/reducers/app";
import cardsReducer from "@/store/reducers/cards";
import categoriesReducer from "@/store/reducers/categories";
import clientReducer from "@/store/reducers/client";
import clientAnalyticsReducer from "@/store/reducers/client_analytics";
import commentsReducer from "@/store/reducers/comments";
import companyReducer from "@/store/reducers/company";
import creditReducer from "@/store/reducers/credit";
import expenseReducer from "@/store/reducers/expense";
import exportsSlice from "@/store/reducers/exports";
import fileViewerReducer from "@/store/reducers/fileViewer";
import filterReducer from "@/store/reducers/filters";
import ledgersReducer from "@/store/reducers/ledgers";
import loadersErrorReducer from "@/store/reducers/loadersError";
import masterHistoryReducer from "@/store/reducers/master-history";
import mileageRateReducer from "@/store/reducers/mileageRate";
import myVolopayReducer from "@/store/reducers/myVolopay";
import navigationReducer from "@/store/reducers/navigation";
import notificationPreferencesReducer from "@/store/reducers/notification_preferences";
import ocrReducer from "@/store/reducers/ocr-results";
import onboardingReducer from "@/store/reducers/onboarding";
import paymentsReducer from "@/store/reducers/payments";
import policyReducer from "@/store/reducers/policy";
import purchaseBillsReducer from "@/store/reducers/purchase-bills";
import qrPaymentsReducer from "@/store/reducers/qr-payments";
import quickGuideReducer from "@/store/reducers/quickGuide";
import reimbursementReducer from "@/store/reducers/reimbursement";
import rulesReducer from "@/store/reducers/rules";
import sliderReducer from "@/store/reducers/slider";
import syncAttemptsReducer from "@/store/reducers/sync-attempts";
import tagsReducer from "@/store/reducers/tags";
import timelineReducer from "@/store/reducers/timeline";
import userReducer from "@/store/reducers/user";
import vendorReducer from "@/store/reducers/vendors";

const store = configureStore({
  reducer: {
    account: accountReducer,
    accounting: accountingReducer,
    accountingTransactions: accountingTransactionsReducer,
    actionCentre: actionCentreSlice,
    analytics: analyticsReducer,
    app: appReducer,
    cards: cardsReducer,
    categories: categoriesReducer,
    client: clientReducer,
    clientAnalytics: clientAnalyticsReducer,
    comments: commentsReducer,
    company: companyReducer,
    credit: creditReducer,
    expenses: expenseReducer,
    exports: exportsSlice,
    fileViewer: fileViewerReducer,
    filters: filterReducer,
    ledgers: ledgersReducer,
    masterHistory: masterHistoryReducer,
    mileageRate: mileageRateReducer,
    myVolopay: myVolopayReducer,
    navigation: navigationReducer,
    notificationPreferences: notificationPreferencesReducer,
    ocr: ocrReducer,
    onboarding: onboardingReducer,
    payments: paymentsReducer,
    policy: policyReducer,
    purchaseBills: purchaseBillsReducer,
    qrPayments: qrPaymentsReducer,
    reimbursement: reimbursementReducer,
    rules: rulesReducer,
    slider: sliderReducer,
    syncAttempts: syncAttemptsReducer,
    tags: tagsReducer,
    timeline: timelineReducer,
    user: userReducer,
    vendors: vendorReducer,
    loadersError: loadersErrorReducer,
    quickGuide: quickGuideReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
