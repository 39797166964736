import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import API from "@/api";

const DEFAULT_FILTERS_PAGE = {
  appliedFilters: {},
  isFetchingFilters: false,
  searchAndFilter: {
    commonStructure: {},
    optionsByLevel: {
      currentOptionsList: [],
      currentLevel: null,
    },
    searchInputText: "",
    paramKey: "",
    currentNavigationIds: [],
  },
};

const filterInitialState = {
  ...DEFAULT_FILTERS_PAGE,
  slider: {
    ...DEFAULT_FILTERS_PAGE,
  },
};

const filterSlice = createSlice({
  name: "filters",
  initialState: filterInitialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsFetchingFilters(state, action) {
      state.isFetchingFilters = action.payload;
    },
    setAppliedFilters(state, action) {
      state.appliedFilters = { ...state.appliedFilters, ...action.payload };
    },
    setAppliedFiltersObject(state, action) {
      state.appliedFilters = action.payload;
    },
    deleteAppliedFilter(state, action) {
      delete state.appliedFilters[action.payload];
    },
    resetAppliedFilters(state) {
      state.appliedFilters = {};
    },
    setSearchAndFilterCommonStoreStructure(state, action) {
      state.searchAndFilter.commonStructure = {
        ...state.searchAndFilter.commonStructure,
        ...action.payload,
      };
    },
    resetSearchAndFilterCommonStoreStructure(state) {
      state.searchAndFilter.commonStructure = {};
    },
    setSearchAndFilterOptions(state, action) {
      state.searchAndFilter.optionsByLevel.currentOptionsList = action.payload;
    },
    setSearchAndFilterOptionsLevelCount(state, action) {
      state.searchAndFilter.optionsByLevel.currentLevel = action.payload;
    },
    deleteSearchAndFilterAppliedFilter(state, action) {
      delete state.searchAndFilter.commonStructure[action.payload];
    },
    setSearchInputText(state, action) {
      state.searchAndFilter.searchInputText = action.payload;
    },
    setSearchAndFilterParamKey(state, action) {
      state.searchAndFilter.paramKey = action.payload;
    },
    setCurrentNavigationIds(state, action) {
      state.searchAndFilter.currentNavigationIds = [
        ...state.searchAndFilter.currentNavigationIds,
        action.payload,
      ];
    },
    resetCurrentNavigationIds(state) {
      state.searchAndFilter.currentNavigationIds = [];
    },
    popCurrentNavigationIds(state) {
      state.searchAndFilter.currentNavigationIds?.pop();
    },
    setSliderAppliedFilters(state, action) {
      state.slider.appliedFilters = {
        ...state.slider.appliedFilters,
        ...action.payload,
      };
    },
    setSliderAppliedFiltersObject(state, action) {
      state.slider.appliedFilters = action.payload;
    },
    deleteSliderAppliedFilters(state, action) {
      delete state.slider.appliedFilters[action.payload];
    },
    resetSliderAppliedFilters(state) {
      state.slider.appliedFilters = {};
    },
    setSliderSearchAndFilterCommonStoreStructure(state, action) {
      state.slider.searchAndFilter.commonStructure = {
        ...state.slider.searchAndFilter.commonStructure,
        ...action.payload,
      };
    },
    resetSliderSearchAndFilterCommonStoreStructure(state) {
      state.slider.searchAndFilter.commonStructure = {};
    },
    setSliderSearchAndFilterOptions(state, action) {
      state.slider.searchAndFilter.optionsByLevel.currentOptionsList =
        action.payload;
    },
    setSliderSearchAndFilterOptionsLevelCount(state, action) {
      state.slider.searchAndFilter.optionsByLevel.currentLevel = action.payload;
    },
    deleteSliderSearchAndFilterAppliedFilter(state, action) {
      delete state.slider.searchAndFilter.commonStructure[action.payload];
    },
    setSliderSearchInputText(state, action) {
      state.slider.searchAndFilter.searchInputText = action.payload;
    },
    setSliderSearchAndFilterParamKey(state, action) {
      state.slider.searchAndFilter.paramKey = action.payload;
    },
    setSliderCurrentNavigationIds(state, action) {
      state.slider.searchAndFilter.currentNavigationIds = [
        ...state.slider.searchAndFilter.currentNavigationIds,
        action.payload,
      ];
    },
    resetSliderCurrentNavagationIds(state, action) {
      state.slider.searchAndFilter.currentNavigationIds = [];
    },
    popSliderCurrentNavigationIds(state) {
      state.slider.searchAndFilter.currentNavigationIds?.pop();
    },
  },
});

export const fetchFiltersValues = createAsyncThunk(
  "filters/fetchFiltersValues",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingFilters(true));
    const [err, response] = await to(API.Filters.all(params));
    if (response) {
      dispatch(setFilters(response));
    }
    dispatch(setIsFetchingFilters(false));
  }
);

export const getCategoriesForSearchFilters = createAsyncThunk(
  "filters/searchFilterCategories",
  async ({ params, onSuccess }, { dispatch }) => {
    const [err, response] = await to(
      API.Filters.fetchSearchFiltersCategories(params)
    );

    if (response && onSuccess) {
      onSuccess(
        response?.data?.filters?.map((option, index) => ({ ...option, index }))
      );
    }
  }
);

export const getCategoryOptions = createAsyncThunk(
  "filters/searchFilterCategories",
  async ({ params, onSuccess, url }, { dispatch }) => {
    const [err, response] = await to(
      API.Filters.fetchFilterOptions({ params, url })
    );

    if (response && onSuccess) {
      onSuccess(response?.data);
    }
  }
);

export const {
  setFilters,
  setIsFetchingFilters,
  setAppliedFilters,
  setAppliedFiltersObject,
  setAppliedFilterTagsObject,
  setFilterTag,
  deleteAppliedFilter,
  resetAppliedFilters,

  // search and filter dispatchers
  setSearchAndFilterCommonStoreStructure,
  resetSearchAndFilterCommonStoreStructure,
  setSearchAndFilterOptions,
  setSearchAndFilterOptionsLevelCount,
  deleteSearchAndFilterAppliedFilter,
  setSearchInputText,
  setSearchAndFilterParamKey,
  setCurrentNavigationIds,
  resetCurrentNavigationIds,
  popCurrentNavigationIds,

  // selector for slider
  setSliderAppliedFilters,
  setSliderAppliedFiltersObject,
  deleteSliderAppliedFilters,
  resetSliderAppliedFilters,
  setSliderSearchAndFilterCommonStoreStructure,
  resetSliderSearchAndFilterCommonStoreStructure,
  setSliderSearchAndFilterOptions,
  setSliderSearchAndFilterOptionsLevelCount,
  deleteSliderSearchAndFilterAppliedFilter,
  setSliderSearchInputText,
  setSliderSearchAndFilterParamKey,
  setSliderCurrentNavigationIds,
  resetSliderCurrentNavagationIds,
  popSliderCurrentNavigationIds,
} = filterSlice.actions;

export default filterSlice.reducer;
