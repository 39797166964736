import PropsTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import PaidList from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Paid/PaidList";
import PaymentList from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentList";
import { PAGE_TYPE } from "@/utils/constants/payments";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";

export default function PaymentPageHelper({ page, pageType }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const openSliderHandler = (id, extraParams = {}) => {
    const inPayrollContext = page === BILL_PAYROLL_CONTEXT.PAYROLL;
    const paramName =
      page === BILL_PAYROLL_CONTEXT.BILLPAY
        ? SLIDERS_SEARCH_PARAMS.payments.id
        : SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId;

    setSearchParams({
      [paramName]: id,
      [SLIDERS_SEARCH_PARAMS.payments.sliderType]: "payments",
      [SLIDERS_SEARCH_PARAMS.payments.pageType]: pageType,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]: paramName,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
      [SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled]: true,
      ...extraParams,
    });
  };
  return (
    <>
      {pageType === PAGE_TYPE.paid ? (
        <PaidList
          page={page}
          pageType={pageType}
          openSliderHandler={openSliderHandler}
        />
      ) : (
        <PaymentList
          pageType={pageType}
          page={page}
          openSliderHandler={openSliderHandler}
        />
      )}
    </>
  );
}
PaymentPageHelper.propTypes = {
  pageType: PropsTypes.string,
  page: PropsTypes.string,
};
