import PropTypes from "prop-types";

import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

// Note: This may be used elsewhere too
export default function ApplyAtLineItemLevel({
  keyName,
  values,
  errors,
  handleChange,
  text,
  textProps,
  classes = "flex gap-3 items-center",
  textClasses = "text-base text-neutral-800 font-semibold",
}) {
  return (
    <div className={classes}>
      <Switch
        name={keyName}
        value={values[keyName]}
        handleChange={handleChange}
        isError={errors[keyName]}
        insideForm
      />
      <Text
        translationKey={text}
        translationProps={textProps}
        classes={textClasses}
      />
    </div>
  );
}

ApplyAtLineItemLevel.propTypes = {
  keyName: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  text: PropTypes.string,
  textProps: PropTypes.object,
  classes: PropTypes.string,
  textClasses: PropTypes.string,
};
