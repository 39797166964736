import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchPermissions } from "@/store/reducers/company";

import {
  adminRoleIdSelector,
  isFetchingRoleDetailsSelector,
  permissionsSelector,
  roleDetailsSelector,
} from "@/store/selectors/company";
import { isAdminSelector } from "@/store/selectors/user";

import Icon from "@/components/core/Icon";
import Loader from "@/components/core/Loader";
import Switch from "@/components/core/Switch";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import {
  convertPermissions,
  permissionsSetup,
  sortPermissions,
} from "@/components/Company/common/RolesPermissionsCommon";
import { camelToSentence, capitalizeFirstLetter } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  PERMISSIONS_TITLE_MAP,
  SETTING_PERMISSIONS_ACTION_TYPE,
} from "@/constants/company";

import SetPermissions from "../AddCustomRole/SetPermissions";

export default function PermissionsSlider({ custom }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const isAdmin = useSelector(isAdminSelector);
  const [permissionList, setPermissionList] = useState({});
  const [showSelectors, setShowSelectors] = useState({});
  const roleDetails = useSelector(roleDetailsSelector);
  const [selectedPermissionList, setSelectedPermissionList] = useState([]);
  const isFetching = useSelector(isFetchingRoleDetailsSelector);
  const originalPermissions = useSelector(permissionsSelector);
  const selectedPermissionIds = selectedPermissionList.map(
    (permission) => permission?.permissionId
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const ref = useLeftHeaderTitle({
    title: capitalizeFirstLetter(roleDetails?.name),
  });

  const roleId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.rolesPermissions.role
  );

  const adminRoleId = useSelector(adminRoleIdSelector);

  const isAdminSlider = adminRoleId === Number(roleId);

  useEffect(() => {
    dispatch(fetchPermissions());
  }, []);

  useEffect(() => {
    if (originalPermissions) {
      const permissions = permissionsSetup(originalPermissions);
      setPermissionList((prev) => {
        const updatedPermissions = convertPermissions(permissions);
        Object.entries(updatedPermissions)?.forEach(([key, value]) =>
          sortPermissions(value?.permissions, key)
        );
        return updatedPermissions;
      });
    }
  }, [originalPermissions]);

  useEffect(() => {
    if (roleDetails && roleDetails.rolePermissions) {
      setSelectedPermissionList(roleDetails?.rolePermissions);
    }
    if (permissionList)
      setShowSelectors(
        Object.keys(permissionList)?.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
  }, [roleDetails, permissionList]);

  useEffect(() => {
    if (selectedPermissionList)
      setPermissionList((prev) => {
        Object.values(prev)?.forEach((item) => {
          const permissionOptions = item?.permissions;
          if (
            permissionOptions?.some((permission) =>
              selectedPermissionList.some(
                (p) => p?.permissionId === permission?.id
              )
            )
          ) {
            item.default = true;
          }
        });
        return { ...prev };
      });
  }, [selectedPermissionList, originalPermissions]);

  const handleEditPermissions = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.company.rolesPermissions.editCustomRole,
      roleDetails?.roleId
    );
    setSearchParams(searchParams);
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  return !isFetching ? (
    <div className="slider-content-container !px-0">
      <div
        className={`flex flex-col slider-content-core gap-9 ${
          custom ? "!px-0" : ""
        }`}
      >
        {isAdmin ? (
          <div className="flex flex-col gap-9">
            <div className="flex flex-col gap-4">
              <Text
                translationKey="company.rolesPermissions.tabs.permissions.title"
                classes="text-lg font-semibold text-neutral-800"
              />
              <div className="flex flex-col gap-6 pb-10">
                {Object.entries(permissionList)?.map(([key, value]) => (
                  <div
                    className="flex flex-col justify-center gap-5 p-4 border rounded-lg border-neutral-200"
                    key={`${key}-permissions`}
                  >
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center w-full gap-3">
                        <Switch
                          disabled
                          value={isAdminSlider || permissionList[key]?.default}
                          disabledStyles={
                            isAdminSlider || permissionList[key]?.default
                              ? "bg-primary-200"
                              : ""
                          }
                        />
                        <Text
                          translationKey={PERMISSIONS_TITLE_MAP[key]}
                          classes="text-lg font-semibold text-neutral-800"
                        />
                      </div>
                      <Icon
                        name={`${
                          showSelectors[key] ? "ChevronUp" : "ChevronDown"
                        }`}
                        className="w-6 h-6 cursor-pointer text-neutral-400"
                        handleClick={() =>
                          setShowSelectors((prev) => ({
                            ...prev,
                            [key]: !prev[key],
                          }))
                        }
                      />
                    </div>
                    {showSelectors[key] ? (
                      <SetPermissions
                        permissionKey={key}
                        permissions={permissionList}
                        setPermissions={setPermissionList}
                        selectedPermissionList={selectedPermissionList}
                        setSelectedPermissionList={setSelectedPermissionList}
                        disabled
                        type={SETTING_PERMISSIONS_ACTION_TYPE.EDIT}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-9">
            <Text
              translationKey={camelToSentence(roleDetails?.name)}
              classes="text-3xl font-bold text-neutral-800 lowercase first-letter:capitalize"
              refProp={ref}
            />
            <div className="flex flex-col gap-9">
              <Text
                translationKey="company.rolesPermissions.tabs.permissions.title"
                classes="text-lg font-semibold text-neutral-800"
              />
              <div className="flex flex-col gap-6 pb-10">
                {Object.entries(permissionList)?.map(([key, value]) => (
                  <div
                    className="flex flex-col justify-center gap-5 p-4 border rounded-lg border-neutral-200"
                    key={`${key}-permissions`}
                  >
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center w-full gap-3">
                        <Switch
                          disabled
                          value={permissionList[key]}
                          disabledStyles={
                            permissionList[key]?.default ? "bg-primary-200" : ""
                          }
                        />
                        <Text
                          translationKey={PERMISSIONS_TITLE_MAP[key]}
                          classes="text-lg font-semibold text-neutral-800"
                        />
                      </div>
                      <Icon
                        name={`${
                          showSelectors[key] ? "ChevronUp" : "ChevronDown"
                        }`}
                        className="w-6 h-6 cursor-pointer text-neutral-400"
                        handleClick={() =>
                          setShowSelectors((prev) => ({
                            ...prev,
                            [key]: !prev[key],
                          }))
                        }
                      />
                    </div>
                    {showSelectors[key] ? (
                      <SetPermissions
                        permissionKey={key}
                        permissions={permissionList}
                        setPermissions={setPermissionList}
                        selectedPermissionList={selectedPermissionList}
                        setSelectedPermissionList={setSelectedPermissionList}
                        disabled
                        type={SETTING_PERMISSIONS_ACTION_TYPE.EDIT}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Loader />
  );
}

PermissionsSlider.propTypes = {
  custom: PropTypes.bool,
};
