import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { exportLedger } from "@/store/reducers/ledgers";

import {
  ledgerTabsSelector,
  totalLedgersSelector,
} from "@/store/selectors/ledgers";

import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import vToast from "@/utils/vToast";
import { handleExport } from "@/utils/exports";
import {
  DEFAULT_LEDGER_PAGE_TYPE,
  EXPORT_FORMATS,
  EXPORT_MODES,
  EXPORT_MODES_TOAST,
  LEDGER_PAGE_SLIDER_TYPE,
  LEDGER_PAGE_TYPE,
  LEDGER_PAGE_TYPE_CONFIG,
  REQUEST_STATEMENT_PARAM,
} from "@/utils/constants/ledger";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EXPORT_PAGE_TYPE } from "@/constants/exports";

import RequestStatementSliderFooter from "./RequestStatementSliderFooter";
import RequestStatementSliderHeader from "./RequestStatementSliderHeader";
import SelectDuration from "./SelectDuration";

export default function RequestStatementSlider() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const totalLedgersSelected = useSelector(totalLedgersSelector);
  const ledgerTabs = useSelector(ledgerTabsSelector);
  const ledgerTabMappings = ledgerTabs?.map((tab) => ({
    name: tab?.name,
    id: tab?.accountId,
  }));
  const [selectedFormat, setSelectedFormat] = useState(EXPORT_FORMATS[0]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [exportMode, setExportMode] = useState(EXPORT_MODES.download);
  const [duration, setDuration] = useState(null);
  const ledgerTabList = ledgerTabMappings;
  const [ledgerPageType, setLedgerPageType] = useState("");
  const [isEmailToSent, setIsEmailToSent] = useState(false);

  const id = searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.accountId);
  const accountId = id ? Number(id) : null;

  const selectedLedgerTab = ledgerTabs.find(
    (ledgerTab) => ledgerTab.name === ledgerPageType?.name
  );
  const accountIds = selectedLedgerTab?.key ? [selectedLedgerTab.key] : null;

  const handleDurationSelect = (date) => {
    setDuration({ before: date.to, after: date.from });
  };

  const handleCancel = () => {
    setSearchParams({});
  };

  const onSuccess = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.requestStatement);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.accountId);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const selectedLedger = ledgerTabList?.find((tab) => tab.id === accountId);
    if (selectedLedger && !ledgerPageType) setLedgerPageType(selectedLedger);
  }, [ledgerTabList]);
  return (
    <>
      <div className="slider-content-core">
        <RequestStatementSliderHeader />
        <div className="flex flex-col gap-8 mt-10">
          <div className="flex flex-col gap-3 ">
            <Text
              classes="text-lg font-medium"
              translationKey="accounting.ledger.tableHeader.account"
            />
            <div className="flex gap-4 text-sm font-semibold">
              {ledgerTabList.map((ledgerAccount, index) => (
                <Radio
                  key={index}
                  label={t(ledgerAccount?.name)}
                  isChecked={ledgerPageType?.name === ledgerAccount?.name}
                  handleChange={() =>
                    setLedgerPageType({
                      name: t(ledgerAccount?.name),
                      id: ledgerAccount?.id,
                    })
                  }
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <Text
              classes="text-lg font-medium "
              translationKey="accounting.ledger.requestStatementSlider.duration"
            />

            <SelectDuration onSelect={handleDurationSelect} />
          </div>
          <div className="flex flex-col gap-3">
            <Text
              classes="text-lg font-medium "
              translationKey="accounting.ledger.requestStatementSlider.formatOfExport"
            />
            <VpSelect
              classes="mt-4 text-xs font-medium"
              label="accounting.ledger.requestStatementSlider.selectFormat"
              placeholder="accounting.ledger.requestStatementSlider.selectFormat"
              value={selectedFormat.id}
              options={EXPORT_FORMATS}
              handleChange={(option) => setSelectedFormat(option)}
              menuPosition="absolute"
            />
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-3 ">
              <Checkbox
                checked={isEmailToSent}
                onClickHandler={() => {
                  setIsEmailToSent((prev) => !prev);
                }}
              />
              <div className="flex flex-col ">
                <Text
                  classes="text-neutral-800 font-semibold text-base"
                  translationKey="accounting.ledger.requestStatementSlider.emailStatementTitle"
                />
                <Text
                  classes=" text-neutral-500 font-medium text-sm"
                  translationKey="accounting.ledger.requestStatementSlider.emailStatementDescription"
                />
              </div>
            </div>
            <div className="flex gap-6 p-4 mb-10 border bg-neutral-50 rounded-xl border-neutral-200">
              <div className="w-6 h-6">
                <Icon name="Error" className=" text-neutral-500" />
              </div>

              <div className="flex flex-col ">
                <Text
                  translationKey="accounting.ledger.requestStatementSlider.warningTitle"
                  classes="text-neutral-800 font-semibold text-base"
                />
                <Text
                  classes="text-neutral-500 font-medium text-sm"
                  translationKey="accounting.ledger.requestStatementSlider.warningDescription"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-5 px-3 py-5 slider-footer">
        <RequestStatementSliderFooter
          confirm={() => {
            handleExport(
              false,
              null, // totalLedgersSelected doesn't represent the count of ledgers exported by request statement slider hence should not be passed.
              null,
              dispatch,
              {
                ...duration,
                account_id: accountIds,
                mode: exportMode,
                send_to_mail: isEmailToSent,
                download_type: selectedFormat.value,
                ...EXPORT_PAGE_TYPE.LEDGER,
              },
              onSuccess
            );
          }}
          cancel={handleCancel}
        />
      </div>
    </>
  );
}
