import PropTypes from "prop-types";

import Button from "@/components/core/Button";

export default function Footer({
  formId,
  isEditMode,
  onDiscard = () => {},
  inPayrollContext,
  leftDisabled = false,
  rightDisabled = false,
  isLoading = false,
}) {
  const positiveLabel = isEditMode
    ? "misc.saveChanges"
    : inPayrollContext // create mode (below)
      ? "payroll.payrollEmployees.addEmployee.title"
      : "billPay.vendors.createVendor.title";

  return (
    <div className="px-3 py-5 slider-footer">
      <div className="flex items-center justify-end gap-6">
        <Button
          label="billPay.vendors.createVendor.cancel"
          classes="text-neutral-500 font-medium py-3"
          variant="tertiary"
          disabled={leftDisabled || isLoading}
          onClick={onDiscard}
          compact
        />
        <Button
          label={positiveLabel}
          classes="text-white font-medium py-3 w-16"
          variant="primary"
          btnType="submit"
          disabled={rightDisabled}
          showLoader={isLoading}
          form={formId}
          // compact
        />
      </div>
    </div>
  );
}

Footer.propTypes = {
  formId: PropTypes.string,
  onDiscard: PropTypes.func,
  inPayrollContext: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isLoading: PropTypes.bool,
};
