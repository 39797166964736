export const CREDIT_PAYMENT_STATUS = {
  PAID: "paid",
  PENDING: "pending",
  GENERATED: "generated",
  PARTIALLY_PAID: "partially_paid",
  MANUAL_PARTIALLY_PAID: "manual_partially_paid",
  MANUAL_PAID: "manual_paid",
};

export const CREDIT_MODULE = {
  outstanding: "outstanding",
  availableLimit: "availableLimit",
};

export const CREDIT_PAYMENT_HISTORY_BADGE = {
  full_payment: {
    color: "success",
    text: "common.badgeStatus.fullAmount",
  },
  full_payment_manual: {
    color: "success",
    text: "common.badgeStatus.fullAmountManual",
  },
  partial_payment_manual: {
    color: "warning",
    text: "common.badgeStatus.partialAmountManual",
  },
  partial_payment: {
    color: "warning",
    text: "common.badgeStatus.partialAmount",
  },
  advance_payment: {
    color: "neutral",
    text: "common.badgeStatus.advanceAmount",
  },
};

export const REPAYMENT_TYPE = {
  TOTAL: "total",
  OTHER: "other",
};
