import PropsTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
// custom hooks
import usePagination from "@/hooks/usePagination";

// utils, constant file imports

// reducers
import {
  expireUserInvite,
  fetchPeopleInvited,
  resendUserInvite,
  resetPeopleInvitedStoreState,
  setPeopleInvited,
} from "@/store/reducers/company";

// selectors
import {
  isFetchingPeopleInvitedSelector,
  peopleInviteSliderFiltersSelector,
  peopleInvitedHasMoreSelector,
  peopleInvitedSelector,
} from "@/store/selectors/company";
import { sliderAppliedFilterSelector } from "@/store/selectors/filters";

import Checkbox from "@/components/core/Checkbox";
import Filters from "@/components/core/Filters";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Table from "@/components/core/Table";
// core components
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

// page relevant components
import InvitationModeIcon from "@/components/common/InvitedModeIcon";
import { convertFilters } from "@/utils/filters";
import { FILTER_TRIGGER_CONTEXT } from "@/utils/constants/filters";
import { dateToString, groupByDate } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_TAB_PARAMS,
  INVITATION_STATUS,
} from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function PendingInviteSliderTable({
  tab,
  resendHandlerFlag,
  setSelectedCount,
}) {
  const dispatch = useDispatch();
  const [headerSelected, setHeaderSelected] = useState(false);
  const filters = useSelector(peopleInviteSliderFiltersSelector);
  const peopleInvited = useSelector(peopleInvitedSelector);
  const peopleInvitedTotal = peopleInvited?.length;
  const appliedFilters = useSelector(sliderAppliedFilterSelector);

  const hasMore = useSelector(peopleInvitedHasMoreSelector);
  const isFetching = useSelector(isFetchingPeopleInvitedSelector);
  const groupedInvitedPeopleData = groupByDate(
    peopleInvited,
    "invitationSentAt"
  );
  const groupedInvitedPeopleDataLength = Object.keys(
    groupedInvitedPeopleData
  ).length;
  const isEmpty = peopleInvitedTotal === 0;
  const invitedPeopleData = Object.values(groupedInvitedPeopleData).flat();

  const status = [COMPANY_PEOPLE_TAB_PARAMS.PENDING];
  const invitation_status = [
    tab.path === COMPANY_PEOPLE_TAB_PARAMS.PENDING
      ? INVITATION_STATUS.NOT_EXPIRED
      : INVITATION_STATUS.EXPIRED,
  ];

  useEffect(() => {
    if (peopleInvited) {
      const count = peopleInvited?.filter((item) => item.selected)?.length;
      setSelectedCount(count);
      if (count > 0) setHeaderSelected((prev) => count === peopleInvitedTotal);
    }
  }, [peopleInvited]);

  useEffect(() => {
    if (resendHandlerFlag && resendHandlerFlag > 0) handleResendinvites();
  }, [resendHandlerFlag]);

  const allRowSelectionHandler = (selectionState) => {
    setHeaderSelected((prev) => !prev);
    dispatch(
      setPeopleInvited(
        invitedPeopleData?.map((item) => ({
          ...item,
          selected: selectionState,
        }))
      )
    );
  };

  const loadMore = () => {
    dispatch(
      fetchPeopleInvited({
        status,
        invitation_status,
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...convertFilters(appliedFilters),
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    inSlider: true,
    filterOptions: {
      tabKey: tab?.key,
      status,
      invitation_status,
      ...convertFilters(appliedFilters),
    },
  });

  function onReset() {
    dispatch(resetPeopleInvitedStoreState());
  }

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  const resetRowHandler = () => {
    setHeaderSelected(false);
  };

  const rowSelectionHandler = (id, selectionState) => {
    const selectedPeopleList = invitedPeopleData?.map((item) =>
      item.id === id ? { ...item, selected: selectionState } : item
    );
    dispatch(setPeopleInvited(selectedPeopleList));
  };

  const handleResendinvites = () => {
    const selectedResendInvitee = peopleInvited
      ?.filter((item) => item.selected)
      ?.map((item) => item.id);
    dispatch(
      resendUserInvite({
        payload: {
          user_ids: selectedResendInvitee,
        },
        onSuccess: loadMore,
      })
    );
    resetRowHandler();
  };

  const handleIndividualResendInvite = (id) => {
    dispatch(
      resendUserInvite({
        payload: {
          user_ids: [id],
        },
        onSuccess: loadMore,
      })
    );
    resetRowHandler();
  };

  const handleExpiryInvite = (id) => {
    dispatch(
      expireUserInvite({
        payload: {
          user_ids: [id],
        },
        onSuccess: loadMore,
      })
    );
    resetRowHandler();
  };

  const getRows = (userList, index) => {
    return userList?.map((item, indx) => (
      <tr
        key={`pending-invite-${indx}`}
        ref={(ref) => {
          if (
            groupedInvitedPeopleDataLength - 1 === index &&
            (userList.length ?? 0) - 1 === indx &&
            hasMore
          ) {
            handleRefChange(ref);
          }
        }}
      >
        <td className="text-left vp-core-table-cell ">
          <div className="flex items-center gap-3">
            <Checkbox
              onClickHandler={(checked) =>
                rowSelectionHandler(item?.id, checked)
              }
              checked={item?.selected}
            />

            <div className="flex flex-col justify-center py-3 font-xs">
              <Text
                translationKey={item?.email}
                classes="text-sm text-neutral-800 font-semibold"
              />
              <div className="flex items-center gap-1 text-xs font-medium text-neutral-500">
                <Text
                  translationKey={item?.role[0]}
                  classes="lowercase first-letter:capitalize"
                />
                <Icon name="Dot" className="w-1 h-1" />

                <div className="overflow-hidden w-fit whitespace-nowrap">
                  <Text
                    translationKey={item?.departmentName}
                    classes="w-full"
                  />
                </div>

                <Icon name="Dot" className="w-1 h-1" />
                <div className="overflow-hidden w-fit whitespace-nowrap">
                  <Text translationKey={item?.locationName} />
                </div>
              </div>
            </div>
          </div>
        </td>
        <td className="vp-core-table-cell vp-core-table-body-cell">
          <div className="flex flex-col">
            <Text
              translationKey={item?.invitedBy?.displayName}
              classes="text-sm text-neutral-800 font-semibold"
            />
          </div>
        </td>
        <td className="vp-core-table-cell">
          <div className="flex items-center justify-center">
            <InvitationModeIcon mode={item?.invitationMode} index={item?.id} />
          </div>
        </td>
        <td className="text-center vp-core-table-cell">
          <div className="flex items-center justify-center gap-4">
            <div id={`resend-invite-${index}`} className="cursor-pointer">
              <Icon
                name="Sync"
                className="w-5 h-5 text-neutral-500"
                handleClick={() => handleIndividualResendInvite(item?.id)}
              />
              <Tooltip id={`resend-invite-${index}`} direction="bottom">
                <Text translationKey="company.people.resentInvite" />
              </Tooltip>
            </div>
            {tab.path === COMPANY_PEOPLE_TAB_PARAMS.PENDING ? (
              <div id={`expiry-invite-${index}`} className="cursor-pointer">
                <Icon
                  name="AverageTimer"
                  className="w-5 h-5 text-neutral-500"
                  handleClick={() => handleExpiryInvite(item?.id)}
                />
                <Tooltip id={`expiry-invite-${index}`} direction="bottom">
                  <Text translationKey="company.people.expirInvite" />
                </Tooltip>
              </div>
            ) : null}
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Filters
        filters={filters}
        context={FILTER_TRIGGER_CONTEXT.SLIDER}
        sliderConfig={{
          tabKey: tab?.key,
          sliderId: SLIDERS_SEARCH_PARAMS.company.people.pendingInvite,
        }}
      />
      <Table headerSticky>
        <tr className="text-xs font-semibold vp-core-table-header -z-10">
          <th className="font-semibold text-left">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={headerSelected}
                onClickHandler={(checked) => {
                  allRowSelectionHandler(checked);
                }}
                disabled={isEmpty}
              />
              <Text
                translationKey="company.people.pendingTable.date"
                classes="pt-0.5"
              />
            </div>
          </th>
          <th className="font-semibold text-left">
            <Text translationKey="company.people.pendingTable.invitedBy" />
          </th>
          <th className="font-semibold text-center">
            <Text translationKey="company.people.pendingTable.invitedVia" />
          </th>
          <th className="font-semibold text-center">
            <Text translationKey="company.people.pendingTable.action" />
          </th>
        </tr>
        {peopleInvited?.length
          ? Object.entries(groupedInvitedPeopleData)?.map(
              ([key, values], index) => (
                <>
                  <tr
                    className="vp-core-table-body-row h-9"
                    key={`pending-invite-header-${index}`}
                  >
                    <td className="text-xs font-medium vp-core-table-cell text-neutral-500">
                      {dateToString(key)}
                    </td>
                    <td
                      className="vp-core-table-cell "
                      aria-label="render-empty-space"
                    />
                    <td
                      className="vp-core-table-cell "
                      aria-label="render-empty-space"
                    />
                    <td
                      className="vp-core-table-cell "
                      aria-label="render-empty-space"
                    />
                  </tr>
                  {getRows(values, index)}
                </>
              )
            )
          : null}
        {isFetching
          ? [...Array(5)].map((_, index) => (
              <tr
                className="text-center"
                key={`pending-table-loader-item-${index}`}
              >
                <td className="text-left">
                  <LoaderSkeleton />
                </td>
                <td className="text-left">
                  <LoaderSkeleton />
                </td>
                <td className="text-center">
                  <LoaderSkeleton />
                </td>
                <td className="text-center">
                  <LoaderSkeleton />
                </td>
              </tr>
            ))
          : null}
      </Table>
    </>
  );
}

PendingInviteSliderTable.propTypes = {
  tab: PropsTypes.object,
  resendHandlerFlag: PropsTypes.number,
  setSelectedCount: PropsTypes.func,
};
