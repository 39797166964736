import PropsTypes from "prop-types";

import Text from "@/components/core/Text";

export default function ActivityCardDetail({
  children,
  subtitle,
  title,
  outerSectionStyles,
  handleClick,
  handleRef,
}) {
  return (
    <div
      ref={handleRef?.attach}
      className={`flex items-center w-1/2 gap-5 p-2 border rounded-lg border-neutral-200 ${outerSectionStyles}`}
      onClick={handleClick}
    >
      <div>{children}</div>
      <div className="flex flex-col ">
        <Text
          classes="text-sm font-semibold text-neutral-500"
          translationKey={subtitle}
        />
        <div className="text-xl font-semibold text-neutral-800">{title}</div>
      </div>
    </div>
  );
}
ActivityCardDetail.propTypes = {
  title: PropsTypes.string,
  subtitle: PropsTypes.string,
  children: PropsTypes.any,
  outerSectionStyles: PropsTypes.string,
  handleClick: PropsTypes.func,
  handleRef: PropsTypes.shape({ attach: PropsTypes.func }),
};
