import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { googleCodeVerification } from "@/store/reducers/user";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function OauthLogin() {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();

  const code = searchParams.get("code");
  const oneTimeToken = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.oneTimeToken
  );
  const actionType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.actionType
  );
  const fromSubdomain = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.fromSubdomain
  );

  useEffect(() => {
    if (code)
      dispatch(
        googleCodeVerification({
          navigate,
          ...{ code },
          ...(oneTimeToken && { one_time_token: oneTimeToken }),
          ...(actionType && { action_type: actionType }),
          ...(fromSubdomain && { from_subdomain: fromSubdomain }),
        })
      );
  }, []);
  return "";
}
