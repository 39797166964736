import { createSelector } from "@reduxjs/toolkit";

export const sliderStoreSelector = (store) => store.slider;

export const isSliderClosedSelector = createSelector(
  sliderStoreSelector,
  (sliderStore) => sliderStore.isSliderClosed
);
export const sliderHeaderDataSelector = createSelector(
  sliderStoreSelector,
  (sliderStore) => sliderStore.sliderHeaderData
);
export const showLeftSliderHeaderSelector = createSelector(
  sliderStoreSelector,
  (sliderStore) => sliderStore.showLeftSliderHeader
);
export const isHeaderShadowVisibleSlector = createSelector(
  sliderStoreSelector,
  (sliderStore) => sliderStore.isHeaderShadowVisible
);
export const hasSliderExpandedSelector = createSelector(
  sliderStoreSelector,
  (sliderStore) => sliderStore.hasSliderExpanded
);
