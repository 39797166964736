import { getDateInPattern } from "@/utils/common";
import {
  AVAILABLE_FILTER_KEYS,
  BUDGET_TYPE_FILTER_VALUES,
  UTILIZATION_SEARCH_PARAMS,
} from "@/utils/constants/filters";

/**
 * converts camelcase to snake case
 * @param {String} str
 * @returns
 */
export function camelToSnake(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

/**
 * convert filter value to backend accepted value
 * @param {object} input
 * @returns
 */
export function convertFilters(input) {
  const output = {};
  const filters = input;
  if (filters)
    Object.keys(filters).forEach((filterKey) => {
      const filter = filters[filterKey];
      switch (filterKey) {
        case AVAILABLE_FILTER_KEYS.dateRange:
          output.before = getDateInPattern(
            filter.to,
            "yyyy-mm-dd",
            "dd-mm-yyyy"
          );
          output.after = getDateInPattern(
            filter.from,
            "yyyy-mm-dd",
            "dd-mm-yyyy"
          );
          break;

        case AVAILABLE_FILTER_KEYS.amount:
        case AVAILABLE_FILTER_KEYS.spends:
        case AVAILABLE_FILTER_KEYS.analyticsLimit:
        case AVAILABLE_FILTER_KEYS.cardLimit:
          output.greater_than = filter.minAmount.value;
          output.less_than = filter.maxAmount.value;
          break;

        case AVAILABLE_FILTER_KEYS.unsettledAmount:
          output.unsettled_amount_greater_than = filter.minAmount.value;
          output.unsettled_amount_less_than = filter.maxAmount.value;
          break;

        case AVAILABLE_FILTER_KEYS.settledAmount:
          output.settled_amount_greater_than = filter.minAmount.value;
          output.settled_amount_less_than = filter.maxAmount.value;
          break;

        case AVAILABLE_FILTER_KEYS.creditLedgerType:
        case AVAILABLE_FILTER_KEYS.payrollLedgerType:
        case AVAILABLE_FILTER_KEYS.ledgerType:
          output.type = filter.value;
          break;
        case AVAILABLE_FILTER_KEYS.transactionStatus:
          output.status = filter.value;
          break;
        case AVAILABLE_FILTER_KEYS.recurringStatus:
        case AVAILABLE_FILTER_KEYS.employeeBankDetailsStatus:
        case AVAILABLE_FILTER_KEYS.vendorBankDetailsStatus:
          output[filter.value] = true;
          break;

        case AVAILABLE_FILTER_KEYS.billStatus:
        case AVAILABLE_FILTER_KEYS.payrollStatus:
        case AVAILABLE_FILTER_KEYS.billPaymentStatus:
        case AVAILABLE_FILTER_KEYS.payrollPaymentStatus:
        case AVAILABLE_FILTER_KEYS.paymentHistoryStatus:
        case AVAILABLE_FILTER_KEYS.reportStatus:
        case AVAILABLE_FILTER_KEYS.claimStatus:
          output.status = filter.value;
          break;

        case AVAILABLE_FILTER_KEYS.flaggedBy:
          output[camelToSnake(filterKey)] = filter.value === "auto_flagged";
          break;

        case AVAILABLE_FILTER_KEYS.utilization:
          output[UTILIZATION_SEARCH_PARAMS.minUtilization] =
            filter[UTILIZATION_SEARCH_PARAMS.minUtilization].value;
          output[UTILIZATION_SEARCH_PARAMS.maxUtilization] =
            filter[UTILIZATION_SEARCH_PARAMS.maxUtilization].value;
          break;
        case AVAILABLE_FILTER_KEYS.budgetType: {
          output.flexible = filter.value === BUDGET_TYPE_FILTER_VALUES.FLEXIBLE;
          break;
        }
        case AVAILABLE_FILTER_KEYS.cardRequestType: {
          output.card_request_type = filter.value;
          break;
        }
        case AVAILABLE_FILTER_KEYS.receiptStatusFilter:
        case AVAILABLE_FILTER_KEYS.cardType: {
          output[filter.value] = true;
          break;
        }
        case AVAILABLE_FILTER_KEYS.person: {
          output.project_member = filter.value;
          break;
        }
        case AVAILABLE_FILTER_KEYS.copilots: {
          output.user = filter.value;
          break;
        }
        case AVAILABLE_FILTER_KEYS.entity: {
          output.subdomain = filter.value;
          break;
        }

        case AVAILABLE_FILTER_KEYS.budgetTypeDateRange: {
          output.frequency = filter.value;
          break;
        }
        case AVAILABLE_FILTER_KEYS.reimbursementPaymentDate: {
          const [to, from] = filter.value;
          output.payment_date_to = to;
          output.payment_date_from = from;
          break;
        }
        case AVAILABLE_FILTER_KEYS.reimbursementSubmissionDate: {
          const [to, from] = filter.value;
          output.submission_date_to = to;
          output.submission_date_from = from;
          break;
        }

        default:
          // Ignore unknown filters
          output[camelToSnake(filterKey)] = filter.value;
          break;
      }
    });

  return output;
}
