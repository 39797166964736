import PropTypes from "prop-types";
import React, { useMemo, useRef } from "react";

import EmptyData from "@/components/core/EmptyData";
import Text from "@/components/core/Text";

import "./styles.scss";

export default function Table({
  children,
  headerSticky,
  colWidths = [],
  rightColWidths = [],
  numberOfStickyColsLeft = 0,
  numberOfStickyColsRight = 0,
  styleId = "",
  emptyDataIconSrc = "",
  emptyDataTitle = "No data available",
  emptyDataDescription = "",
  emptyDataDescriptionProps = {},
  emptyDataChildren = null,
  isDataLoading = false,
  bulkApproveVisible = false,
  bulkApproveContent,
  maxHeight = "max-h-screen",
  bulkApproveBottom = "15%",
  bulkApproveWidth = "60%",
  bulkApproveHeight = "200px",
  bulkApproveTransform = "translateX(-45%)",
  boxShadowNone = false,
  tableWrapperClasses,
}) {
  const childrenArray = React.Children.toArray(children);
  const Tableref = useRef();

  // inject widths and sticky classes
  const injectedHeaderRow = useMemo(() => {
    const tableHeaderRow = childrenArray[0];

    if (!tableHeaderRow) return [];

    const tableHeaderCells = React.Children.toArray(
      tableHeaderRow.props.children
    );

    // no header row
    if (
      tableHeaderCells.some((potentialThCell) => potentialThCell.type !== "th")
    )
      return null;

    let leftStickyOffset = 0;
    let rightStickyOffset = rightColWidths
      .slice(-numberOfStickyColsRight)
      .reduce((accum, current) => accum + current, 0);

    return React.cloneElement(
      tableHeaderRow,
      {
        className: `vp-core-table-row vp-core-table-header-row ${
          tableHeaderRow.props.className || ""
        }`,
      },
      ...tableHeaderCells.map((thCell, i) => {
        // Need to inject:
        // 1. width(optional)
        // 2. Sticky class with offsets(optional)
        // 3. Corresponding class and styles

        let mutatedWidth;
        let mutatedClassName = `vp-core-table-cell vp-core-table-header-cell ${
          thCell.props.className ?? ""
        }`.trim();
        let mutatedStyles = {};

        const rowSize = tableHeaderCells.length;
        const rightColWidthsLength = rightColWidths.length;

        if (i < rowSize - rightColWidthsLength) {
          // not affected by the right widths
          mutatedWidth = colWidths?.[i] || "auto"; // stickiness doesn't matter
          if (i < numberOfStickyColsLeft) {
            // left sticky
            mutatedClassName =
              `vp-core-table-sticky-item vp-core-table-sticky-header-cell-left ${
                i === numberOfStickyColsLeft - 1
                  ? "vp-core-table-sticky-left-inner-cell vp-core-table-sticky-header-left-inner-cell"
                  : ""
              } ${mutatedClassName}`.trim();
            mutatedStyles.left = `${leftStickyOffset}px`;
            leftStickyOffset += mutatedWidth;
          }
        } else {
          mutatedWidth = rightColWidths[i - rowSize + rightColWidthsLength]; // stickiness doesn't matter

          if (i < numberOfStickyColsLeft) {
            // left sticky
            mutatedClassName =
              `vp-core-table-sticky-item vp-core-table-sticky-header-cell-left ${
                i === numberOfStickyColsLeft - 1
                  ? "vp-core-table-sticky-left-inner-cell vp-core-table-sticky-header-left-inner-cell"
                  : ""
              } ${mutatedClassName}`.trim();
            mutatedStyles.left = `${leftStickyOffset}px`;
            leftStickyOffset += mutatedWidth;
          } else if (i >= rowSize - numberOfStickyColsRight) {
            // right sticky
            mutatedClassName =
              `vp-core-table-sticky-item vp-core-table-sticky-header-cell-right ${
                i === rowSize - numberOfStickyColsRight
                  ? "vp-core-table-sticky-right-inner-cell vp-core-table-sticky-header-right-inner-cell"
                  : ""
              } ${mutatedClassName}`.trim();
            rightStickyOffset -= mutatedWidth;
            mutatedStyles.right = `${rightStickyOffset}px`;
          }
        }

        mutatedClassName = mutatedClassName.trim();

        mutatedStyles.minWidth = mutatedWidth;
        mutatedStyles.maxWidth = mutatedWidth;
        mutatedStyles = {
          ...thCell.props.style, // retain other style
          ...mutatedStyles,
        };

        return React.cloneElement(thCell, {
          ...thCell.props, // retain props
          style: mutatedStyles,
          className: mutatedClassName,
        });
      })
    );
  }, [
    children,
    numberOfStickyColsLeft,
    numberOfStickyColsRight,
    colWidths,
    rightColWidths,
  ]);

  // inject sticky classes
  const injectedBodyRows = useMemo(() => {
    const tableBodyRows = childrenArray.slice(injectedHeaderRow ? 1 : 0);

    return tableBodyRows.map((row, idx) => {
      const rowCells = React.Children.toArray(row.props.children);

      let leftStickyOffset = 0;
      let rightStickyOffset = rightColWidths
        .slice(-numberOfStickyColsRight)
        .reduce((accum, current) => accum + current, 0);

      return React.cloneElement(
        row,
        {
          className: `vp-core-table-row vp-core-table-body-row ${
            row.props.className || ""
          }`.trim(),
        },
        ...rowCells.map((trCell, i) => {
          // Need to inject:
          // 1. Width(optional)
          // 2. Sticky class with offsets(optional)
          // 3. Corresponding class and styles

          let mutatedWidth;
          let mutatedClassName = `vp-core-table-cell vp-core-table-body-cell ${
            trCell.props.className ?? ""
          }`.trim();
          let mutatedStyles = {};

          const rowSize = rowCells.length;
          const rightColWidthsLength = rightColWidths.length;

          if (i < rowSize - rightColWidthsLength) {
            // not affected by the right widths
            mutatedWidth = colWidths?.[i] || "auto"; // stickiness doesn't matter
            if (i < numberOfStickyColsLeft) {
              // left sticky
              mutatedClassName =
                `vp-core-table-sticky-item vp-core-table-sticky-body-cell-left ${
                  i === numberOfStickyColsLeft - 1
                    ? "vp-core-table-sticky-left-inner-cell vp-core-table-sticky-body-left-inner-cell"
                    : ""
                } ${mutatedClassName}`.trim();
              mutatedStyles.left = `${leftStickyOffset}px`;
              leftStickyOffset += mutatedWidth;
            }
          } else {
            mutatedWidth = rightColWidths[i - rowSize + rightColWidthsLength]; // stickiness doesn't matter

            if (i < numberOfStickyColsLeft) {
              // left sticky
              mutatedClassName =
                `vp-core-table-sticky-item vp-core-table-sticky-body-cell-left ${
                  i === numberOfStickyColsLeft - 1
                    ? "vp-core-table-sticky-left-inner-cell vp-core-table-sticky-body-left-inner-cell"
                    : ""
                } ${mutatedClassName}`.trim();
              mutatedStyles.left = `${leftStickyOffset}px`;
              leftStickyOffset += mutatedWidth;
            } else if (i >= rowSize - numberOfStickyColsRight) {
              // right sticky
              mutatedClassName =
                `vp-core-table-sticky-item vp-core-table-sticky-body-cell-right ${
                  i === rowSize - numberOfStickyColsRight
                    ? "vp-core-table-sticky-right-inner-cell vp-core-table-sticky-body-right-inner-cell"
                    : ""
                } ${mutatedClassName}`.trim();
              rightStickyOffset -= mutatedWidth;
              mutatedStyles.right = `${rightStickyOffset}px`;
            }
          }

          mutatedClassName = mutatedClassName.trim();

          mutatedStyles.minWidth = mutatedWidth;
          mutatedStyles.maxWidth = mutatedWidth;
          mutatedStyles = {
            ...trCell.props.style, // retain existing styles
            ...mutatedStyles,
          };

          return React.cloneElement(trCell, {
            ...trCell.props, // retain props
            style: mutatedStyles,
            className: mutatedClassName,
          });
        })
      );
    });
  }, [
    children,
    numberOfStickyColsLeft,
    numberOfStickyColsRight,
    colWidths,
    rightColWidths,
  ]);

  let readyTable;
  let emptyTable;

  const showEmpty = !injectedBodyRows.length && !isDataLoading;

  if (showEmpty) {
    emptyTable = (
      <>
        <table className="vp-core-table-html-table">
          {injectedHeaderRow && (
            <thead
              className={`vp-core-table-header ${
                headerSticky ? "vp-core-table-sticky-header" : ""
              }`}
            >
              {injectedHeaderRow}
            </thead>
          )}
        </table>
        <div className="w-full h-full py-[calc(10%)]">
          <EmptyData
            title={emptyDataTitle}
            imgSrc={emptyDataIconSrc || undefined}
          >
            <Text
              translationKey={emptyDataDescription}
              translationProps={emptyDataDescriptionProps}
              classes="text-sm text-neutral-800 font-medium text-center"
            />
            {emptyDataChildren}
          </EmptyData>
        </div>
      </>
    );
  } else {
    readyTable = (
      <table className="vp-core-table-html-table">
        {injectedHeaderRow && (
          <thead
            className={`vp-core-table-header ${
              headerSticky ? "vp-core-table-sticky-header" : ""
            }`}
          >
            {injectedHeaderRow}
          </thead>
        )}
        <tbody className="vp-core-table-body">{injectedBodyRows}</tbody>
      </table>
    );
  }

  return (
    <div className="relative">
      {bulkApproveVisible && bulkApproveContent ? (
        <div
          className="fixed left-[50%] z-[99] rounded-xl bg-white border-neutral-200 border-[1px] shadow-2xl flex overflow-hidden"
          style={{
            width: bulkApproveWidth,
            height: bulkApproveHeight,
            transform: bulkApproveTransform,
            top: window.innerHeight * 0.85,
          }}
        >
          {bulkApproveContent}
        </div>
      ) : null}
      <div
        ref={Tableref}
        id={styleId}
        className={`vp-core-table-container  ${
          showEmpty ? "vp-core-table-container-empty" : ""
        } ${maxHeight ?? ""} ${tableWrapperClasses}`}
        style={showEmpty || boxShadowNone ? { boxShadow: "none" } : {}}
      >
        {showEmpty ? emptyTable : readyTable}
      </div>
    </div>
  );
}

// width and sticky API
// 1. colWidth vs rightColWidth. On collision - rightColWidth has more priority
// 2. If a side is sticky, providing (colWidth, numberOfStickyColsLeft) and/or (rightColWidth, numberOfStickyColsRight) is required

Table.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired, // rows (including header)
  headerSticky: PropTypes.bool,
  numberOfStickyColsLeft: PropTypes.number,
  numberOfStickyColsRight: PropTypes.number,
  colWidths: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ), // unit: pixel, or 'auto'
  rightColWidths: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ), // direction: left to right. unit: pixel, or 'auto'
  styleId: PropTypes.string,
  emptyDataIconSrc: PropTypes.string,
  emptyDataTitle: PropTypes.string,
  emptyDataDescription: PropTypes.string,
  emptyDataDescriptionProps: PropTypes.object,
  emptyDataChildren: PropTypes.node,
  isDataLoading: PropTypes.bool,
  bulkApproveVisible: PropTypes.bool,
  bulkApproveContent: PropTypes.node,
  bulkApproveBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bulkApproveWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bulkApproveHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bulkApproveTransform: PropTypes.string,
  maxHeight: PropTypes.string,
  boxShadowNone: PropTypes.bool,
  tableWrapperClasses: PropTypes.bool,
};
