import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

export default function QrPay() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.QRPay.qrPayments.overview.absolutePath);
  }, []);
  return (
    <div>
      <span className="text-2xl font-semibold">QR Payments</span>
    </div>
  );
}
