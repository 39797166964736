import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  fetchAnalyticsLedgerCurrencies,
  fetchAnalyticsTags,
} from "@/store/reducers/analytics";

import {
  analyticsLedgerCurrencySelector,
  analyticsTagsSelector,
} from "@/store/selectors/analytics";

import ProtectedRoute from "@/components/ProtectedRoute";

import Analytics from "@/pages/analytics";
import AnalyticsLedger from "@/pages/analytics/ledger";
import AnalyticsTransaction from "@/pages/analytics/transactions";
import AnalyticsBillPay from "@/pages/analytics/transactions/bill-pay";
import AnalyticsCards from "@/pages/analytics/transactions/cards";
import AnalyticsOthers from "@/pages/analytics/transactions/others";
import AnalyticsQrPay from "@/pages/analytics/transactions/qr-pay";
import AnalyticsReimbursement from "@/pages/analytics/transactions/reimbursement";
import { subdomain } from "@/utils/common";
import { ROUTES } from "@/constants/routes";

export default function AnalyticsRoutes() {
  const dispatch = useDispatch();
  const analyticsLedgerCurrencies = useSelector(
    analyticsLedgerCurrencySelector
  );

  const tags = useSelector(analyticsTagsSelector);

  useEffect(() => {
    dispatch(fetchAnalyticsLedgerCurrencies({ subdomain: subdomain() }));
    dispatch(
      fetchAnalyticsTags({
        subdomain: subdomain(),
      })
    );
  }, []);

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Analytics />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.analytics.transactions.pathName}
          // element={<Transactions />}
        >
          <Route index element={<AnalyticsTransaction />} />
          <Route
            path={ROUTES.analytics.transactions.cards.pathName}
            element={<AnalyticsCards />}
          />
          <Route
            path={ROUTES.analytics.transactions.billpay.pathName}
            element={<AnalyticsBillPay />}
          />
          <Route
            path={ROUTES.analytics.transactions.reimbursement.pathName}
            element={<AnalyticsReimbursement />}
          />
          <Route
            path={ROUTES.analytics.transactions.others.pathName}
            element={<AnalyticsOthers />}
          />
          <Route
            path={ROUTES.analytics.transactions.qrPay.pathName}
            element={<AnalyticsQrPay />}
          />
        </Route>
      </Route>

      {analyticsLedgerCurrencies ? (
        <Route element={<ProtectedRoute />}>
          <Route
            path={ROUTES.analytics.ledger.pathName}
            element={
              <Navigate
                to={
                  analyticsLedgerCurrencies?.length &&
                  analyticsLedgerCurrencies?.[0]?.path
                }
              />
            }
          />
          <Route
            path={`${ROUTES.analytics.ledger.pathName}/:type`}
            element={<AnalyticsLedger />}
          />
        </Route>
      ) : null}
    </Routes>
  );
}
