import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

export default function BillPay() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.billpay.bill.absolutePath);
  });
  return <div>Bill Pay</div>;
}
