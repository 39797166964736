import PropsTypes from "prop-types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Filters from "@/components/core/Filters";
import Text from "@/components/core/Text";

import DepartmentsTable from "@/components/Company/DepartmentsTable";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

export default function DepartmentPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <HeaderText
        setSliderHandler={() => {
          searchParams.set(SLIDERS_SEARCH_PARAMS.company.department.add, true);
          setSearchParams(searchParams);
        }}
      />

      <div className="mt-3">
        <DepartmentsTable
          onClickHandler={(id) => {
            searchParams.set(SLIDERS_SEARCH_PARAMS.company.department.id, id);
            setSearchParams(searchParams);
          }}
        />
      </div>
    </div>
  );
}

function HeaderText({ setSliderHandler }) {
  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col">
        <Text classes="text-neutral-500" translationKey="company.title" />
        <Text
          classes="text-3xl font-bold text-neutral-800"
          translationKey="company.department.title"
        />
      </div>
      {checkIfUserActionAllowed(
        allNavUrls,
        userAllowedActions,
        USER_ALLOWED_CTA_KEYS.NEW_DEPARTMENT
      ) ? (
        <Button
          variant="primary"
          label="company.department.addDepartment"
          preIcon="Add"
          classes="w-[212px]"
          onClick={setSliderHandler}
        />
      ) : null}
    </div>
  );
}

HeaderText.propTypes = {
  setSliderHandler: PropsTypes.func,
};
