import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchPayrollSetting } from "@/store/reducers/payments";

import { currentPaymentProviderSelector } from "@/store/selectors/client";
import {
  autoPaymentDaySelector,
  autoPaymentEnabledSelector,
  isFetchingPayrollSettingSelector,
} from "@/store/selectors/payments";
import { selectedVendorSelector } from "@/store/selectors/vendors";

import Checkbox from "@/components/core/Checkbox";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import VendorNotSelected from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/VendorNotSelected";
import { CREATE_BILL_FLOW_CONTEXT_KEYS } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import DateInputTextBox from "@/components/common/DateInputTextBox";
import {
  BILL_PAYMENT_FREQUENCY,
  PAYMENT_SCROLL_TO_KEYS,
  PAYROLL_PAYMENT_FREQUENCY,
} from "@/utils/constants/payments";
import {
  dateToString,
  getNextDate,
  validateAndConvertToISO8601,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { SPEND_FREQUENCY, SPEND_FREQUENCY_LABELS } from "@/constants/company";
import { DATEINPUT_MODES } from "@/constants/date";
import { PAYMENT_PROVIDERS } from "@/constants/provider";

/**
 * Handles 'pay once approved' and 'recurring' payments features, or a combination of both
 *
 */
export default function AutoRecurringSection({
  values,
  errors,
  handleChange,
  setValues,
  vendorInfoRef,
  senderCurrency,
  beneficiaryCurrency,
  inPayrollContext,
  isPayOutsideVolopay,
  disabled,
  isAllPrefillDone,
  createBillFlowContext,
  isEditBill,
  prefillSource,
  paymentModeOptions,
  amountToBeReceivedByVendor,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const scrollToRef = useRef(); // flow triggered from edit recurring alert?
  const scrollToValue = searchParams.get(SLIDERS_SEARCH_PARAMS.scrollTo);

  const vendor = useSelector(selectedVendorSelector);
  const vendorSelected = !!vendor;
  const currentProvider = useSelector(currentPaymentProviderSelector);

  const isFetchingAutoPaymentSettings = useSelector(
    isFetchingPayrollSettingSelector
  );

  const billLinkedToMode = searchParams.get(
    SLIDERS_SEARCH_PARAMS.payments.billLinkedTo
  );

  const isAutoPaymentEnabled = useSelector(autoPaymentEnabledSelector);
  const autoPaymentDay = useSelector(autoPaymentDaySelector);

  const isHidePaymentMethodDropdown =
    currentProvider === PAYMENT_PROVIDERS.XENDIT; // vendor payment_method also returns nothing, so hide it.

  const frequencyOptions = (
    inPayrollContext ? PAYROLL_PAYMENT_FREQUENCY : BILL_PAYMENT_FREQUENCY
  ).map((v) => ({
    value: v,
    name: t(SPEND_FREQUENCY_LABELS[v]),
  }));

  const isPayOnceApproved = values?.paymentOnApprove;
  const isRecurringPayment = values?.recurringPayment;

  // payment dates auto-filling
  useEffect(() => {
    if (isPayOnceApproved || isPayOutsideVolopay || !values.paymentFrequency)
      return;

    setValues((prev) => {
      const nextPaymentDateCalculated = getNextDate(
        prev.paymentDate,
        prev.paymentFrequency
      );

      return { ...prev, nextPaymentDate: nextPaymentDateCalculated };
    });
  }, [
    isPayOnceApproved,
    isPayOutsideVolopay,
    JSON.stringify([values?.paymentDate, values.paymentFrequency]),
  ]);

  // payroll payment date auto-filling from settings
  useEffect(() => {
    if (
      inPayrollContext &&
      !isFetchingAutoPaymentSettings &&
      !isPayOnceApproved &&
      isAutoPaymentEnabled &&
      autoPaymentDay
    ) {
      setValues((prev) => {
        const paymentDateCalc = new Date();
        if (paymentDateCalc.getDate() < autoPaymentDay) {
          paymentDateCalc.setDate(autoPaymentDay);
        } else {
          paymentDateCalc.setMonth(paymentDateCalc.getMonth() + 1);
          paymentDateCalc.setDate(autoPaymentDay);
        }

        return {
          ...prev,
          paymentDate: paymentDateCalc,
        };
      });
    }
  }, [
    inPayrollContext,
    isFetchingAutoPaymentSettings,
    isPayOnceApproved,
    isAutoPaymentEnabled,
    autoPaymentDay,
  ]);

  // auto-filling of recurring fields when check box is enabled and frequency is selected
  useEffect(() => {
    if (isRecurringPayment && !values?.paymentFrequency) {
      setValues((prev) => {
        prev.paymentFrequency = SPEND_FREQUENCY.MONTHLY;

        return { ...prev };
      });
    }

    if (isRecurringPayment && values?.paymentFrequency) {
      setValues((prev) => {
        prev.nextInvoiceCreationDate = getNextDate(
          inPayrollContext ? new Date() : prev.invoiceDate,
          prev.paymentFrequency
        );

        return { ...prev };
      });
    }
  }, [
    isRecurringPayment,
    JSON.stringify([
      values?.invoiceDate,
      values?.payrollMonth,
      values.paymentFrequency,
    ]),
  ]);
  // next due date
  useEffect(() => {
    if (isRecurringPayment && values?.paymentFrequency && !inPayrollContext) {
      setValues((prev) => ({
        ...prev,
        nextDueDate: getNextDate(prev.dueDate, prev.paymentFrequency),
      }));
    }
  }, [
    isRecurringPayment,
    JSON.stringify([values?.dueDate, values.paymentFrequency]),
  ]);

  // clear values when checkboxes are turned off
  useEffect(() => {
    if (!isRecurringPayment) return;
    if (isRecurringPayment || billLinkedToMode) return;

    return () => {
      setValues((prev) => ({
        ...prev,
        paymentFrequency: "",
        nextInvoiceCreationDate: "",
        nextPaymentDate: "",
        nextDueDate: "",
        endOfRecurringPaymentCycle: "",
      }));
    };
  }, [isRecurringPayment, billLinkedToMode]);
  //
  useEffect(() => {
    if (isPayOnceApproved) {
      setValues((prev) => ({
        ...prev,
        paymentDate: "",
      }));
    }
  }, [isPayOnceApproved]);
  //
  useEffect(() => {
    // auto select first payment method type
    if (values?.payoutMethodType) return;

    setValues((prev) => ({
      ...prev,
      payoutMethodType: paymentModeOptions?.filter(
        (option) => !option?.disabled
      )?.[0]?.value,
    }));
  }, [
    values?.payoutMethodType,
    JSON.stringify(paymentModeOptions),
    !!amountToBeReceivedByVendor,
  ]);

  // scroll to section
  useEffect(() => {
    if (isAllPrefillDone && scrollToValue) {
      scrollToRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [
    isAllPrefillDone,
    scrollToValue,
    !!scrollToRef?.current?.scrollIntoView, // without this, React settles but the ref doesn't, problem.
  ]);

  useEffect(() => {
    if (inPayrollContext && vendor) dispatch(fetchPayrollSetting()); // add billpay context settings during integration
  }, [vendor, inPayrollContext]);

  const isPaymentAddDateMode =
    scrollToValue === PAYMENT_SCROLL_TO_KEYS.PAYMENT_DATE;

  useEffect(() => {
    createBillFlowContext.current[
      CREATE_BILL_FLOW_CONTEXT_KEYS.ADD_PAYMENT_DATE
    ] = (isPaymentAddDateMode && values?.paymentDate) || null;
  }, [isPaymentAddDateMode, values?.paymentDate]);

  const originalDate = new Date(
    validateAndConvertToISO8601(values?.nextInvoiceCreationDate)
  );

  const originalDay = originalDate.getDate();
  const originalMonth = originalDate.getMonth();
  const currentYear = new Date().getFullYear();

  const modifiedDate = values?.nextInvoiceCreationDate
    ? new Date(currentYear, originalMonth, originalDay)
    : new Date();

  return (
    // TODO: fix opacity issue here whent isPaymentEditMode is true, disabled is false
    <div
      className={`mt-12 ${isPaymentAddDateMode ? "pointer-events-none" : ""}`}
    >
      <Text
        translationKey="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.title"
        classes="text-xl font-bold"
      />
      <div
        className={`${disabled ? "opacity-50" : ""} flex flex-col gap-6 mt-6`}
      >
        {/* TODO: not exactly as per design, minor changes needed */}
        {!vendorSelected ? (
          <VendorNotSelected
            scrollToRef={vendorInfoRef}
            inPayrollContext={inPayrollContext}
          />
        ) : null}

        {/* Pay via volopay (happy for volopay pay) */}
        {vendorSelected && !isPayOutsideVolopay ? (
          <>
            {isHidePaymentMethodDropdown ? null : (
              <VpSelect
                name="payoutMethodType"
                value={values.payoutMethodType}
                error={errors.payoutMethodType}
                handleChange={handleChange}
                insideForm
                menuPosition="absolute"
                options={paymentModeOptions}
                classes="mt-6"
                optionsDisplayKey="label"
                valueKey="value"
                isOptionsLoading={false}
                label="billPay.vendors.createVendor.paymentMethod"
                placeholder="billPay.vendors.createVendor.selectPaymentMethod"
                labelStyleClasses="font-medium text-base text-neutral-800"
                disabled={isPaymentAddDateMode}
                customUIForOptionWhenDropdownOpen={(displayValue, option) => (
                  <div className={option?.disabled ? "cursor-not-allowed" : ""}>
                    <Text
                      translationKey={option.label}
                      noTranslate
                      classes="text-neutral-500 text-base font-medium block hover:text-neutral-900"
                    />
                    <Text
                      translationKey={option.desc}
                      noTranslate
                      classes="mt-1 text-neutral-400 text-xs font-medium block"
                    />
                  </div>
                )}
              />
            )}

            <Input
              name="paymentReference"
              label="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.paymentReferenceLabel"
              showOptional
              value={values.paymentReference}
              error={errors.paymentReference}
              onChange={handleChange}
              description="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.paymentReferenceDescription"
              disabled={isPaymentAddDateMode}
            />
            {isPayOnceApproved ? null : (
              <div
                ref={isPaymentAddDateMode ? scrollToRef : null}
                className={
                  isPaymentAddDateMode && !disabled ? "pointer-events-auto" : ""
                }
              >
                <DateInputTextBox
                  name="paymentDate"
                  value={values.paymentDate}
                  handleChange={handleChange}
                  label="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.paymentDateLabel"
                  valueToShow={dateToString(values.paymentDate, {
                    year: "numeric",
                  })}
                  error={errors.paymentDate}
                  clearable
                  autoComplete="off"
                  mode={DATEINPUT_MODES.DATE}
                />
              </div>
            )}
            <div
              className={
                isPaymentAddDateMode && !disabled ? "pointer-events-auto" : ""
              }
            >
              <Checkbox
                name="paymentOnApprove"
                label="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.payOnceApprovedCheckboxLabel"
                checked={!!values.paymentOnApprove}
                errorMsg={errors.paymentOnApprove}
                onClickHandler={handleChange}
                labelClasses="text-base text-neutral-800 font-semibold"
                description={
                  inPayrollContext
                    ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentDetails.payOnceApprovedCheckboxLabelHelperText"
                    : "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.payOnceApprovedCheckboxLabelHelperText"
                }
                descriptionClasses="text-sm text-neutral-800 font-medium"
                insideForm
                disabled={
                  isPaymentAddDateMode && !prefillSource?.paymentOnApprove
                } // isEditBill to avoid payment date present bug
              />
            </div>
          </>
        ) : null}

        {/* Recurring payment (and related inputs) */}
        {vendorSelected ? (
          <div>
            <Checkbox
              name="recurringPayment"
              label="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.recurringPaymentCheckboxLabel"
              labelClasses="text-base text-neutral-800 font-semibold"
              checked={!!values.recurringPayment}
              errorMsg={errors.recurringPayment}
              onClickHandler={handleChange}
              insideForm
              disabled={isEditBill ? isRecurringPayment : isPaymentAddDateMode}
            />
            {isRecurringPayment ? (
              <div
                id="if-recurring-true"
                className="flex flex-col justify-between gap-6 p-4 mt-4 card-wrapper"
                ref={
                  scrollToValue === PAYMENT_SCROLL_TO_KEYS.EDIT_RECURRING
                    ? scrollToRef
                    : null
                }
              >
                <Text
                  translationKey="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.recurringPaymentTitle"
                  classes="font-bold text-base"
                />
                <VpSelect
                  name="paymentFrequency"
                  value={values.paymentFrequency}
                  error={errors.paymentFrequency}
                  handleChange={handleChange}
                  insideForm
                  menuPosition="absolute"
                  options={frequencyOptions}
                  optionsDisplayKey="name"
                  disabled={isPaymentAddDateMode}
                  valueKey="value"
                  isOptionsLoading={false}
                  label="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.paymentFrequency"
                  translate
                  helperText={
                    inPayrollContext
                      ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentDetails.paymentFrequencyHelperText"
                      : "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.paymentFrequencyHelperText"
                  }
                  helperTextTransalationProp={{
                    frequencyText: t(
                      SPEND_FREQUENCY_LABELS[values?.paymentFrequency]
                    )?.toLowerCase(),
                  }}
                />

                <DateInputTextBox
                  name="nextInvoiceCreationDate"
                  value={values?.nextInvoiceCreationDate}
                  handleChange={handleChange}
                  label={
                    inPayrollContext
                      ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentDetails.nextDraftCreationDate"
                      : "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.nextInvoiceCreationDate"
                  }
                  valueToShow={dateToString(modifiedDate, {
                    year: "numeric",
                  })}
                  error={errors.nextInvoiceCreationDate}
                  description={
                    inPayrollContext
                      ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentDetails.nextDraftCreationDateHelperText"
                      : "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.nextInvoiceCreationDateHelperText"
                  }
                  clearable
                  autoComplete="off"
                  mode={DATEINPUT_MODES.DATE}
                  disabled={isPaymentAddDateMode}
                />

                {isPayOnceApproved || isPayOutsideVolopay ? null : (
                  <DateInputTextBox
                    name="nextPaymentDate"
                    value={values.nextPaymentDate}
                    handleChange={handleChange}
                    label="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.nextPaymentDate"
                    valueToShow={dateToString(values.nextPaymentDate, {
                      year: "numeric",
                    })}
                    error={errors.nextPaymentDate}
                    description="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.nextPaymentDateHelperText"
                    clearable
                    autoComplete="off"
                    mode={DATEINPUT_MODES.DATE}
                    disabled={isPaymentAddDateMode}
                  />
                )}

                {inPayrollContext ? null : (
                  <DateInputTextBox
                    name="nextDueDate"
                    value={values.nextDueDate}
                    handleChange={handleChange}
                    label="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.nextDueDate"
                    valueToShow={dateToString(values.nextDueDate, {
                      year: "numeric",
                    })}
                    error={errors.nextDueDate}
                    description="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.nextDueDateHelperText"
                    clearable
                    autoComplete="off"
                    mode={DATEINPUT_MODES.DATE}
                    disabled={isPaymentAddDateMode}
                  />
                )}

                <DateInputTextBox
                  name="endOfRecurringPaymentCycle"
                  value={values.endOfRecurringPaymentCycle}
                  handleChange={handleChange}
                  label="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.endOfRecurringPaymentCycle"
                  valueToShow={dateToString(values.endOfRecurringPaymentCycle, {
                    year: "numeric",
                  })}
                  error={errors.endOfRecurringPaymentCycle}
                  description="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.endOfRecurringPaymentCycleHelperText"
                  clearable
                  autoComplete="off"
                  onChange={handleChange}
                  showOptional
                  mode={DATEINPUT_MODES.DATE}
                  disabled={isPaymentAddDateMode}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

AutoRecurringSection.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  vendorInfoRef: PropTypes.any,
  senderCurrency: PropTypes.string,
  beneficiaryCurrency: PropTypes.string,
  inPayrollContext: PropTypes.bool,
  isPayOutsideVolopay: PropTypes.bool,
  disabled: PropTypes.bool,
  isAllPrefillDone: PropTypes.bool,
  createBillFlowContext: PropTypes.any,
  isEditBill: PropTypes.bool,
  prefillSource: PropTypes.object,
  paymentModeOptions: PropTypes.array,
  amountToBeReceivedByVendor: PropTypes.number,
};
