import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { fetchExports } from "@/store/reducers/exports";

import Text from "@/components/core/Text";

import ExportTable from "../../components/Exports/ExportTable";

export default function Exports() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchExports());
  }, []);
  return (
    <div className="px-8 flex flex-col">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <Text
            classes="text-neutral-800 text-3xl font-bold"
            translationKey="exports.title"
          />
        </div>
      </div>
      <div className="mt-8">
        <ExportTable />
      </div>
    </div>
  );
}
