import PropTypes from "prop-types";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function TableLoader({ columns }) {
  return (
    <tbody>
      {[...Array(10)].map((item, index) => (
        <tr className="text-center" key={`ledger-loader-${index}`}>
          {columns.map((column, i) => {
            return (
              <td
                className={`${column.classes} px-2 py-4`}
                key={`${column.id}-${i}`}
              >
                <LoaderSkeleton size={["", column.colWidth - 40]} />
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
}

TableLoader.propTypes = {
  columns: PropTypes.array,
};
