import PropTypes from "prop-types";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  countriesSelector,
  isFetchingCountriesSelector,
} from "@/store/selectors/app";
import {
  countriesForMailSelector,
  isFetchingCountriesForMailSelector,
} from "@/store/selectors/vendors";

import VpSelect from "@/components/core/VpSelect";
import { MAIL_CONTEXT } from "@/utils/constants/common";

import { VENDOR_CREATE_REQUIREMENTS_PARAMS } from "@/constants/vendors";

export default function BeneficiaryBankCountry({
  name = VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY,
  values,
  errors,
  handleChange,
  isEditMode,
  setValues,
  disabled,
  context,
}) {
  const dispatch = useDispatch();

  const appCountries = useSelector((state) =>
    context === MAIL_CONTEXT
      ? countriesForMailSelector(state)
      : countriesSelector(state)
  );

  const isFetchingCountries = useSelector(isFetchingCountriesSelector);
  const isFetchingCountriesForMail = useSelector(
    isFetchingCountriesForMailSelector
  );
  const isFetching = isFetchingCountries || isFetchingCountriesForMail;

  const countryOptions = useMemo(
    () =>
      appCountries?.map((country) => ({
        name: country.name,
        value: country.iso2Code,
      })),
    [appCountries]
  ); // common of (all, available vendor countries)

  return (
    <VpSelect
      name={name}
      value={values[name]}
      error={errors[name]}
      handleChange={handleChange}
      insideForm
      menuPosition="absolute"
      options={countryOptions}
      optionsDisplayKey="name"
      valueKey="value"
      label="billPay.vendors.createVendor.beneficiaryBankCountryLabel"
      labelStyleClasses="text-neutral-600 text-xs font-semibold"
      placeholder="billPay.vendors.createVendor.beneficiaryBankCountryPlaceholder"
      helperText="billPay.vendors.createVendor.beneficiaryBankCountryHelperText"
      clearable
      isOptionsLoading={isFetching}
      disabled={!!isEditMode || disabled}
    />
  );
}

BeneficiaryBankCountry.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  isEditMode: PropTypes.bool,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  context: PropTypes.string,
  setValues: PropTypes.func, // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough)
};
