import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  isFetchingPurchaseBillTaxDetailsSelector,
  purchaseBillTaxDetailsSelector,
} from "@/store/selectors/purchase-bills";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";
import {
  CREATE_BILL_TAX_RESPONSE,
  TAX_KEYS,
  TAX_NAMES,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import { getTaxAmount } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/utils";
import { amountToCurrency } from "@/utils/common";

export default function TaxField({
  keyName,
  values,
  errors,
  handleChange,
  setValues = () => {},

  amount,
  currency,
  taxName,
  taxAtLineItemLevel = false,

  text,
  tooltipText,

  placeholder,
  labelStyleClasses,
  helperText,

  isSubtractive = false,
}) {
  const { t } = useTranslation();

  const categoryDropdownName = `${keyName}-category-dropdown`; // super category label
  const dropdownName = `${keyName}-dropdown`; // rate
  const valueName = `${keyName}-value`;
  const tagValueName = `${keyName}-tag-value-id`; // tag value id
  const dropdownLabelName = `${keyName}-dropdown-label`; // dropdown entry

  let amountAsNumber = parseFloat(amount);
  if (Number.isNaN(amountAsNumber)) amountAsNumber = 0;

  const taxDetails = useSelector(purchaseBillTaxDetailsSelector);
  const isFetchingTaxDetails = useSelector(
    isFetchingPurchaseBillTaxDetailsSelector
  );

  // prepare dropdown options
  // add '#' notation, a custom notation to distinguish between rate and amount, since both are numbers

  const relevantTax = taxDetails.find(
    (item) => item[CREATE_BILL_TAX_RESPONSE.KEY_NAME] === taxName
  );

  // assumption: a given tax has all categories simple, or all nested. But not both at the same time
  // this simplies FE a lot
  const firstTaxOption =
    relevantTax?.[CREATE_BILL_TAX_RESPONSE.CATEGORIES]?.[0];
  const isNestedTax =
    firstTaxOption?.[CREATE_BILL_TAX_RESPONSE.NESTED] ||
    firstTaxOption?.[CREATE_BILL_TAX_RESPONSE.NESTED_CATEGORIES]?.length;

  const categoryOptions = (
    isNestedTax
      ? (relevantTax?.[CREATE_BILL_TAX_RESPONSE.CATEGORIES] ?? [])
      : []
  ).map((item) => ({
    label: item.label,
    value: item.value,
  }));

  const options =
    (isNestedTax
      ? relevantTax[CREATE_BILL_TAX_RESPONSE.CATEGORIES]?.find(
          (taxHigherOption) =>
            taxHigherOption.value === values[categoryDropdownName]
        )?.[CREATE_BILL_TAX_RESPONSE.NESTED_CATEGORIES]
      : relevantTax?.[CREATE_BILL_TAX_RESPONSE.CATEGORIES]) ?? [];

  // TODO: fused UI - second argument may be string (dropdown) or number (direct input)
  const taxAmount = getTaxAmount(amountAsNumber, values[valueName]);

  const isDropdownInCharge = useRef(false);
  // updating amount prop, updates input box if empty as per dropdown value
  useEffect(() => {
    if (isDropdownInCharge.current) {
      setValues((prev) => ({
        ...prev,
        [valueName]: getTaxAmount(amountAsNumber, values[dropdownName], true),
      }));
    }
  }, [amountAsNumber]);

  // PPnBM show/hide when corresponding PPN category is 11%
  const rate = values[dropdownName];
  const isNextTimeEnablePPnBM = useRef(true); // variable to store value of switch when it is hidden and will reappear the next time
  // `true` initially, i.e.when visible the first time, PPnBM is ON
  useEffect(() => {
    const isPPNTax =
      relevantTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME] === TAX_NAMES.PPN;

    if (isPPNTax) {
      const keyNameOfPPnBM = keyName.replace(TAX_NAMES.PPN, TAX_NAMES.PPNBM);
      const childVisibleKeyOfPPnBM = `${keyNameOfPPnBM}-visible`;
      const optionalSwitchKeyOfPPnBM = `${keyNameOfPPnBM}-${TAX_KEYS.OPTIONAL_ENABLED}`;
      const childDropdownLabelOfPPnBM = `${keyNameOfPPnBM}-dropdown-label`; // dropdown entry

      //
      if (
        Number(rate) === 11 ||
        values[dropdownName]?.toString().replaceAll(" ", "").includes("11%")
        // note: assumes labels always contain percentage values. Note for future: if percentages are removed, we can still use tax dropdown entries to search for value.
      ) {
        const hiddenSwitchValue = isNextTimeEnablePPnBM.current; // save in variable since ref update is faster than state update, can lead to bug
        setValues((prev) => ({
          ...prev,
          ...(hiddenSwitchValue
            ? {
                [optionalSwitchKeyOfPPnBM]: !!values[childDropdownLabelOfPPnBM],
              }
            : {}),
          [childVisibleKeyOfPPnBM]: true,
        }));
        isNextTimeEnablePPnBM.current = false; // from now on, user interaction will control switch, so remember that
      } else {
        isNextTimeEnablePPnBM.current ||= values[optionalSwitchKeyOfPPnBM]; // save current value of switch for re-appear use
        setValues((prev) => ({
          ...prev,
          [optionalSwitchKeyOfPPnBM]: false, // switch off before hiding so its ignored in payload generation
          [childVisibleKeyOfPPnBM]: false,
        }));
      }
    }
  }, [rate]);

  return (
    <div
      className={`flex items-center bg-neutral-50 ${
        taxAtLineItemLevel ? "gap-6" : "px-4 py-2 gap-12"
      } `}
    >
      <div className="w-full">
        {/* GST heading */}
        {taxAtLineItemLevel ? null : (
          <TextWithTooltipIcon text={text} tooltipText={tooltipText} />
        )}
        {/* inputs, need a little more margin than needed because vpSelect and Input have problematic spacing */}
        {/* this matches design */}
        {/* Nested category dropdown (used infrequently) */}
        <div className="mt-7 flex flex-col gap-9">
          {isNestedTax ? (
            <VpSelect
              name={categoryDropdownName}
              value={values[categoryDropdownName]}
              error={errors[categoryDropdownName]}
              handleChange={(...args) => {
                // when category option changes, reset sub category selection. If text was dependent, reset that too

                handleChange(...args); // updates form state
                setValues((prev) => ({
                  ...prev,
                  [dropdownName]: "",
                  [valueName]: "",
                }));
                isDropdownInCharge.current = true;
              }}
              insideForm
              menuPosition="absolute"
              options={categoryOptions}
              optionsDisplayKey="label"
              valueKey="value"
              isOptionsLoading={isFetchingTaxDetails}
              label={t(
                "billPay.bill.invoiceInbox.createBill.sections.lineItems.taxCategoryLabel",
                { taxName: text }
              )}
              labelStyleClasses={labelStyleClasses}
            />
          ) : null}

          {/* Category dropdown (always used) */}
          <VpSelect
            name={dropdownLabelName}
            value={values[dropdownLabelName]}
            error={errors[dropdownLabelName]}
            handleChange={(...args) => {
              // updating dropdown, updates text box value, but not vice versa
              // not doing in useEffect, since it hinders prefilling

              const {
                value = 0,
                [CREATE_BILL_TAX_RESPONSE.TAG_VALUE_ID]: tagValueId,
              } = args?.[0] || {};
              handleChange(...args); // updates form state
              setValues((prev) => ({
                ...prev,
                [valueName]: getTaxAmount(amountAsNumber, value, true), // we then update the input box value
                // [dropdownLabelName]: label,
                [dropdownName]: value,
                [tagValueName]: tagValueId,
              }));
              isDropdownInCharge.current = true;
            }}
            insideForm
            menuPosition="absolute"
            options={options}
            optionsDisplayKey="label"
            valueKey="label" // to avoid same value issue, labels are guaranteed to be unique
            isOptionsLoading={isFetchingTaxDetails}
            label={t(
              isNestedTax
                ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.taxSubCategoryLabel"
                : "billPay.bill.invoiceInbox.createBill.sections.lineItems.taxCategoryLabel",
              { taxName: text }
            )}
            placeholder={placeholder}
            labelStyleClasses={labelStyleClasses}
            helperText={helperText}
            rightText={currency}
            customUIForOptionWhenDropdownOpen={(displayValue, option) => (
              <div>
                <Text
                  translationKey={option.label}
                  noTranslate
                  classes="text-neutral-500 text-base font-medium block hover:text-neutral-900"
                />
                <Text
                  translationKey="billPay.bill.invoiceInbox.createBill.sections.lineItems.XPercentTax"
                  translationProps={{ taxRate: option.value }}
                  classes="mt-1 text-neutral-400 text-xs font-medium block"
                />
              </div>
            )}
          />

          <div className="flex items-center justify-between gap-12">
            <Input
              name={valueName}
              value={values[valueName]}
              error={errors[valueName]}
              onChange={(...args) => {
                handleChange(...args);
                isDropdownInCharge.current = false;
              }}
              label={t(
                "billPay.bill.invoiceInbox.createBill.sections.lineItems.taxValueLabel",
                { taxName: text }
              )}
              placeholder={placeholder}
              labelExtraClasses={labelStyleClasses}
              rightText={currency}
              description="billPay.bill.invoiceInbox.createBill.sections.lineItems.manualTaxHelperText"
              type="number"
              outsideDivClasses="w-full"
            />

            {values[valueName] ? (
              <span
                className={`${
                  taxAtLineItemLevel ? "text-base" : "text-base"
                } font-bold shrink-0 ${
                  taxAmount >= 0 && !isSubtractive
                    ? "text-success-600"
                    : "text-danger-500"
                }`}
              >
                {taxAmount > 0 && !isSubtractive
                  ? "+"
                  : taxAmount < 0 || isSubtractive
                    ? "-"
                    : null}{" "}
                {amountToCurrency(Math.abs(taxAmount))} {currency}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

TaxField.propTypes = {
  keyName: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,

  amount: PropTypes.number,
  currency: PropTypes.string,
  taxName: PropTypes.string,
  taxAtLineItemLevel: PropTypes.bool,

  text: PropTypes.string,
  tooltipText: PropTypes.string,

  placeholder: PropTypes.string,
  labelStyleClasses: PropTypes.string,
  helperText: PropTypes.string,
  isSubtractive: PropTypes.bool,
};
