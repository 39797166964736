export const CURRENCY = {
  AED: { value: "AED", name: "United Arab Emirates", locale: "ar-AE" },
  AUD: { value: "AUD", name: "Australian Dollar", locale: "en-AU" },
  BDT: { value: "BDT", name: "Bangladeshi Taka", locale: "bn-BD" },
  BGN: { value: "BGN", name: "Bulgarian New Lev", locale: "bg-BG" },
  BHD: { value: "BHD", name: "Bahraini Dinar", locale: "ar-BH" },
  BND: { value: "BND", name: "Brunei Dollar", locale: "ms-BN" },
  CAD: { value: "CAD", name: "Canadian Dollar", locale: "en-CA" },
  CHF: { value: "CHF", name: "Swiss Franc", locale: "fr-CH" },
  CNY: { value: "CNY", name: "Chinese Yuan", locale: "zh-CN" },
  // CNH: { value: 'CNH', name: 'Chinese Yuan (offshore)', locale: 'zh-CN' },
  CZK: { value: "CZK", name: "Czech Koruna", locale: "cs-CZ" },
  DKK: { value: "DKK", name: "Danish Krone", locale: "da-DK" },
  EGP: { value: "EGP", name: "Egyptian Pound", locale: "ar-EG" },
  EUR: { value: "EUR", name: "Euro", locale: "en-EU" },
  GBP: { value: "GBP", name: "Pound", locale: "en-GB" },
  HKD: { value: "HKD", name: "Hong Kong Dollar", locale: "zh-HK" },
  HRK: { value: "HRK", name: "Croatian Kuna", locale: "hr-HR" },
  HUF: { value: "HUF", name: "Hungarian Forint", locale: "hu-HU" },
  IDR: { value: "IDR", name: "Indonesian Rupiah", locale: "id-ID" },
  ILS: { value: "ILS", name: "Israeli Shekel", locale: "he-IL" },
  INR: { value: "INR", name: "Indian Rupee", locale: "hi-IN" },
  JPY: { value: "JPY", name: "Japan Yen", locale: "ja-JP" },
  KES: { value: "KES", name: "Kenyan Shilling", locale: "sw-KE" },
  KHR: { value: "KHR", name: "Cambodian Riel", locale: "km-KH" },
  KPW: { value: "KPW", name: "North Korean Won", locale: "ko-KP" },
  KRW: { value: "KRW", name: "South Korean Won", locale: "ko-KR" },
  KWD: { value: "KWD", name: "Kuwaiti Dinar", locale: "ar-KW" },
  LKR: { value: "LKR", name: "Sri Lankan Rupee", locale: "si-LK" },
  MAD: { value: "MAD", name: "Moroccan Dirham", locale: "ar-MA" },
  MUR: { value: "MUR", name: "Mauritius Rupee", locale: "en-MU" },
  MXN: { value: "MXN", name: "Mexican Peso", locale: "es-MX" },
  MYR: { value: "MYR", name: "Malaysian Ringgit", locale: "ms-MY" },
  NOK: { value: "NOK", name: "Norwegian Krone", locale: "no-NO" },
  NPR: { value: "NPR", name: "Nepalese Rupee", locale: "ne-NP" },
  NZD: { value: "NZD", name: "New Zealand Dollar", locale: "en-NZ" },
  OMR: { value: "OMR", name: "Oman Rial", locale: "ar-OM" },
  PHP: { value: "PHP", name: "Philippine Peso", locale: "fil-PH" },
  PLN: { value: "PLN", name: "Polish New Zloty", locale: "pl-PL" },
  PKR: { value: "PKR", name: "Pakistan Rupee", locale: "ur-PK" },
  QAR: { value: "QAR", name: "Qatari Riyal", locale: "ar-QA" },
  RON: { value: "RON", name: "Romanian Leu", locale: "ro-RO" },
  SAR: { value: "SAR", name: "Saudi Riyal", locale: "ar-SA" },
  // SEK: { value: 'SEK', name: 'Swedish Krona', locale: 'sv-SE' },
  SGD: { value: "SGD", name: "Singapore Dollar", locale: "en-SG" },
  THB: { value: "THB", name: "Thai Baht", locale: "th-TH" },
  TWD: { value: "TWD", name: "Taiwan Dollar", locale: "zh-TW" },
  TRY: { value: "TRY", name: "Turkish Lira", locale: "tr-TR" },
  UGX: { value: "UGX", name: "Ugandan Shilling", locale: "sw-UG" },
  USD: { value: "USD", name: "United States Dollar", locale: "en-US" },
  UYU: { value: "UYU", name: "Uruguayan Peso", locale: "es-UY" },
  VND: { value: "VND", name: "Vietnamese Dong", locale: "vi-VN" },
  ZAR: { value: "ZAR", name: "South African Rand", locale: "en-ZA" },
};

export const COUNTRY_CURRENCY = {
  AU: { currency: "AUD", country: "Australia" },
  DE: { currency: "DEU", country: "Germany" },
  EE: { currency: "EST", country: "Estonia" },
  GB: { currency: "GBP", country: "United Kingdom" },
  HK: { currency: "HKD", country: "Hong Kong" },
  JP: { currency: "JPY", country: "Japan" },
  US: { currency: "USD", country: "United States" },
};

export const CURRENCY_CURRENCYNAME_ARRAY = [
  {
    value: "AUD",
    label: "AUD",
    name: "Australian Dollar",
  },
  {
    value: "CAD",
    label: "CAD",
    name: "Canadian Dollar",
  },
  {
    value: "CHF",
    label: "CHF",
    name: "Swiss Franc",
  },
  {
    value: "CNY",
    label: "CNY",
    name: "Chinese Yuan",
  },
  {
    value: "EUR",
    label: "EUR",
    name: "Euro",
  },
  {
    value: "GBP",
    label: "GBP",
    name: "Pound",
  },
  {
    value: "HKD",
    label: "HKD",
    name: "Hong Kong Dollar",
  },
  {
    value: "JPY",
    label: "JPY",
    name: "Japan Yen",
  },
  {
    value: "NZD",
    label: "NZD",
    name: "New Zealand Dollar",
  },
  {
    value: "USD",
    label: "USD",
    name: "United State Dollar",
  },
  {
    value: "SGD",
    label: "SGD",
    name: "Singapore Dollar",
  },
  {
    value: "MYR",
    label: "MYR",
    name: "Malaysian Ringgit ",
  },
  {
    value: "INR",
    label: "INR",
    name: "Indian Rupee",
  },
  {
    value: "IDR",
    label: "IDR",
    name: "Indonesian Rupiah",
  },
  {
    value: "VND",
    label: "VND",
    name: "Vietnamese Dong",
  },
  {
    value: "THB",
    label: "THB",
    name: "Thai Baht",
  },
  {
    value: "PHP",
    label: "PHP",
    name: "Philippine Peso",
  },
  {
    value: "AED",
    label: "AED",
    name: "United Arab Emirates",
  },
  {
    value: "KRW",
    label: "KRW",
    name: "South Korean Won",
  },
  {
    value: "LKR",
    label: "LKR",
    name: "Sri Lankan Rupee",
  },
  {
    value: "MXN",
    label: "MXN",
    name: "Mexican Peso",
  },
  {
    value: "SAR",
    label: "SAR",
    name: "Saudi Riyal",
  },
  {
    value: "DKK",
    label: "DKK",
    name: "Danish Krone",
  },
  {
    value: "ILS",
    label: "ILS",
    name: "Israeli Shekel",
  },
  {
    value: "BDT",
    label: "BDT",
    name: "Bangladeshi Taka",
  },
  {
    value: "ZAR",
    label: "ZAR",
    name: "South African Rand",
  },
  {
    value: "TWD",
    label: "TWD",
    name: "Taiwan dollar",
  },
  {
    value: "PLN",
    label: "PLN",
    name: "Polish New Zloty",
  },
  {
    value: "TRY",
    label: "TRY",
    name: "Turkish Lira ",
  },
  {
    value: "UAH",
    label: "UAH",
    name: "Ukrainian hryvnia",
  },
  {
    value: "HUF",
    label: "HUF",
    name: "Hungarian Forint",
  },
  {
    value: "ARS",
    label: "ARS",
    name: "Argentine Peso",
  },
  {
    value: "NOK",
    label: "NOK",
    name: "Norwegian Krone",
  },
  {
    value: "MAD",
    label: "MAD",
    name: "Moroccan Dirham",
  },
  {
    value: "PGK",
    label: "PGK",
    name: "Papua New Guinean Kina",
  },
  {
    value: "FJD",
    label: "FJD",
    name: "Fijian Dollar",
  },
  {
    value: "BRL",
    label: "BRL",
    name: "Brazilian Real",
  },
  {
    value: "PKR",
    label: "PKR",
    name: "Pakistan Rupee",
  },
  {
    value: "RUB",
    label: "RUB",
    name: "Russian Ruble",
  },
  {
    value: "AOA",
    label: "AOA",
    name: "Angolan Kwanza",
  },
  {
    value: "BND",
    label: "BND",
    name: "Brunei Dollar",
  },
  {
    value: "AZN",
    label: "AZN",
    name: "Azerbaijani Manat",
  },
  {
    value: "QAR",
    label: "QAR",
    name: "Qatari Riyal",
  },
  {
    value: "LAK",
    label: "LAK",
    name: "Laotian Kip",
  },
  {
    value: "RON",
    label: "RON",
    name: "Romanian Leu",
  },
  {
    value: "KHR",
    label: "KHR",
    name: "Cambodian Riel",
  },
  {
    value: "COP",
    label: "COP",
    name: "Colombian Peso",
  },
  {
    value: "MOP",
    label: "MOP",
    name: "Macanese Pataca",
  },
  {
    value: "XOF",
    label: "XOF",
    name: "West African CFA franc",
  },
  {
    value: "EGP",
    label: "EGP",
    name: "Egyptian Pound",
  },
  {
    value: "GEL",
    label: "GEL",
    name: "Georgian Lari",
  },

  {
    value: "NGN",
    label: "NGN",
    name: "Nigerian Naira",
  },
  {
    value: "MMK",
    label: "MMK",
    name: "Myanmar Kyat",
  },
  {
    value: "RSD",
    label: "RSD",
    name: "Serbian Dinar",
  },
  {
    value: "MWK",
    label: "MWK",
    name: "Malawian Kwacha",
  },
  {
    value: "CZK",
    label: "CZK",
    name: "Czech Koruna",
  },
  {
    value: "NPR",
    label: "NPR",
    name: "Nepalese Rupee",
  },
  {
    value: "BGN",
    label: "BGN",
    name: "Bulgarina New Lev",
  },
  {
    value: "AMD",
    label: "AMD",
    name: "Armenian Dram",
  },
  {
    value: "BWP",
    label: "BWP",
    name: "Botswanan Pula",
  },
  {
    value: "KZT",
    label: "KZT",
    name: "Kazakhstani Tenge",
  },
];

export const POPULAR_CURRENCIES = [
  {
    value: "EUR",
    currency: "EUR",
    fullName: "Euro",
  },
  {
    value: "GBP",
    currency: "GBP",
    fullName: "Pound",
  },
  {
    value: "INR",
    currency: "INR",
    fullName: "Indian Rupee",
  },
  {
    value: "USD",
    currency: "USD",
    fullName: "United State Dollar",
  },
  {
    value: "SGD",
    currency: "SGD",
    fullName: "Singapore Dollar",
  },
  {
    value: "IDR",
    currency: "IDR",
    fullName: "Indonesian Rupiah",
  },
  {
    value: "AUD",
    currency: "AUD",
    fullName: "Australian Dollar",
  },
];

export const FIXED_SIDE = {
  SELL: "sell",
  BUY: "buy",
};
