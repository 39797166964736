import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { isFetchingOnboardingMailDetailsSelector } from "@/store/selectors/vendors";

import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

/**
 * @param {String} context
 * @description
 *    depending on the context, whether it is bank details for adding employee or bank details for creating vendor
 *    the text in the UI changes
 */
function LeftColumn({ context }) {
  const isFetchingOnboardingMailDetails = useSelector(
    isFetchingOnboardingMailDetailsSelector
  );
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const headingAndDescriptionPayroll = [
    {
      title: "Payroll.securebankDetails",
      desc: "Payroll.securebankDetailsDesc",
      icon: "Encrypted",
    },
    {
      title: "Payroll.autoTransfer",
      desc: "Payroll.autoTransferDesc",
      icon: "Cached",
    },
    {
      title: "Payroll.effortlessPay",
      desc: "Payroll.effortlessPayDesc",
      icon: "PaymentColor",
    },
  ];
  const headingAndDescriptionBillpay = [
    {
      title: "AccPayablesTitle",
      desc: "AccPayablesDesc",
      icon: "Person",
    },
    {
      title: "AccAutomationTitle",
      desc: "AccAutomationDesc",
      icon: "Cached",
    },
    {
      title: "ExpManageTitle",
      desc: "ExpManageDesc",
      icon: "ExpenseChart",
    },
  ];
  return (
    <div className="w-full flex flex-col h-full bg-neutral-50 justify-between bg-[url('@/assets/images/Card_image.png')] bg-no-repeat bg-contain">
      <div className="flex flex-col flex-wrap ">
        <Icon
          className="w-min h-9 px-10 mt-8 text-primary-500"
          name="VolopayLogoOnCard"
        />

        <div className="px-10 mt-20 ">
          {!isFetchingOnboardingMailDetails ? (
            <Text
              translationKey={
                inPayrollContext
                  ? "bankDetailsViaMail.leftColumn.Payroll.MainDesc"
                  : "bankDetailsViaMail.leftColumn.MainDesc"
              }
              classes="text-3xl font-semibold"
            />
          ) : (
            <LoaderSkeleton fullWidth classes="py-5" />
          )}

          {inPayrollContext
            ? headingAndDescriptionPayroll.map((list) => {
                return (
                  <div className="mt-8" key={list.title}>
                    <div className="flex items-center gap-8 ">
                      <Icon name={list.icon} className=" text-primary-500" />
                      <div className="flex flex-col">
                        <Text
                          translationKey={`bankDetailsViaMail.leftColumn.${list.title}`}
                          classes="font-medium"
                        />
                        <Text
                          translationKey={`bankDetailsViaMail.leftColumn.${list.desc}`}
                          classes="text-sm text-neutral-500"
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : headingAndDescriptionBillpay.map((list) => {
                return (
                  <div className="mt-8" key={list.title}>
                    <div className="flex items-center gap-4">
                      {!isFetchingOnboardingMailDetails ? (
                        <Icon
                          name={list.icon}
                          className="text-primary-500 w-5 h-5"
                        />
                      ) : (
                        <LoaderSkeleton type="circle" />
                      )}

                      {!isFetchingOnboardingMailDetails ? (
                        <div className="flex flex-col gap-1">
                          <Text
                            translationKey={`bankDetailsViaMail.leftColumn.${list.title}`}
                            classes="text-neutral-800 text-base font-semibold"
                          />
                          <Text
                            translationKey={`bankDetailsViaMail.leftColumn.${list.desc}`}
                            classes="text-neutral-500 text-base font-medium"
                          />
                        </div>
                      ) : (
                        <div className="flex flex-col w-full">
                          {" "}
                          <LoaderSkeleton fullWidth />
                          <LoaderSkeleton fullWidth />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}

LeftColumn.propTypes = {
  context: PropTypes.string,
};

export default LeftColumn;
