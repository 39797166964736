import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

function MyVolopayExpensesPage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.myVolopay.expenses.overView.pathName);
  }, []);
  return <div />;
}

export default MyVolopayExpensesPage;
