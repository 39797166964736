import PaymentPageHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentPageHelper";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

export default function PaymentsPageAll() {
  return (
    <PaymentPageHelper
      page={BILL_PAYROLL_CONTEXT.BILLPAY}
      pageType={BILL_PAYROLL_CONTEXT.ALL}
    />
  );
}
