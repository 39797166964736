import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchAndSelectWalletAccountDetails,
  redeemCashback,
} from "@/store/reducers/company";

import {
  cashbackSelector,
  generatedFundQuoteSelector,
  isFetchingClientSelector,
  paymentWalletListSelector,
} from "@/store/selectors/client";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";
import { amountToCurrency } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";

import BalanceCard from "../../MyAccounts/shared/BalanceCard";

export default function CashbackSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const cashback = useSelector(cashbackSelector);

  const paymentAccounts = useSelector(paymentWalletListSelector);

  const [review, setReivew] = useState(false);

  const curAccount = paymentAccounts?.find(
    (acc) => acc.currency === cashback?.values?.total?.currency
  );

  const initialFormValue = {
    amount: {
      value: "",
      validate: {
        required: true,
        maxNumber: parseFloat(cashback?.values?.available?.value),
      },
      errorStatement: {
        required: "cards.cardDetails.editLimitDetails.errorStatement",
        maxNumber: "company.cashback.amountError",
      },
    },
  };
  const onSave = () => {
    if (review) {
      const params = {
        amount: values.amount,
        currency: cashback.values?.total?.currency,
      };
      dispatch(redeemCashback(params));
    } else {
      setReivew(true);
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.company.cashback]: FORM_TYPE.review,
      });
    }
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialFormValue, onSave);

  const handleBack = () => {
    setReivew(false);
    setSearchParams({
      [SLIDERS_SEARCH_PARAMS.company.cashback]: FORM_TYPE.edit,
    });
  };

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        <div>
          <Text
            classes="font-bold text-3xl text-neutral-800"
            translationKey={`company.cashback.${
              review ? "reviewTitle" : "title"
            }`}
          />
        </div>
        <Text
          classes="text-sm text-neutral-500 mt-1"
          translationKey={`company.cashback.${
            review ? "reviewDesc" : "description"
          }`}
        />
        {review ? (
          <div>
            <div className="mt-7">
              <Text
                classes="font-semibold text-lg text-neutral-800"
                translationKey="company.cashback.redeemAmount"
              />
              <div className="flex items-baseline">
                <span className="text-2xl font-bold text-neutral-800">
                  {amountToCurrency(values?.amount || 0)}
                </span>
                <span className="font-semibold text-neutral-500 ml-1">
                  {cashback?.values?.total?.currency}
                </span>
              </div>
              <div className="card-wrapper flex items-center mt-4">
                <Flag
                  code={curAccount?.currency}
                  size="lg"
                  classes="border border-neutral-200"
                />
                <div className="ml-3">
                  <Text
                    classes="text-xs text-neutral-800 font-semibold"
                    translationKey="company.exchangeFund.afterTransfer"
                    translationProps={{ currency: curAccount?.currency }}
                  />
                  <div className="flex items-baseline">
                    <span className="text-2xl font-bold text-neutral-800">
                      {amountToCurrency(
                        parseFloat(curAccount?.availableBalance) +
                          parseFloat(values?.amount) || 0
                      )}
                    </span>
                    <span className="font-semibold text-neutral-500 ml-1">
                      {curAccount?.currency}
                    </span>
                    <Badge
                      translationKey={`+ ${amountToCurrency(
                        values?.amount,
                        curAccount?.currency
                      )}`}
                      variant="success"
                      classes="rounded-[100px] px-2 py-[2px] ml-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-7 mb-10">
              <div className="card-wrapper flex items-center mt-4">
                <Icon
                  name="Exchange"
                  variant="rounded"
                  bgClassName="bg-success-50"
                  className="text-success-400 bg-success-50"
                />
                <div className="ml-3">
                  <Text
                    classes="text-xs text-neutral-800 font-semibold"
                    translationKey="company.cashback.cashbackAfterTransfer"
                  />
                  <div className="flex items-baseline">
                    <span className="text-2xl font-bold text-neutral-800">
                      {amountToCurrency(
                        parseFloat(cashback?.values?.available?.value) -
                          parseFloat(values?.amount) || 0
                      )}
                    </span>
                    <span className="font-semibold text-neutral-500 ml-1">
                      {curAccount?.currency}
                    </span>
                    <Badge
                      translationKey={`- ${amountToCurrency(
                        values?.amount,
                        curAccount.currency
                      )}`}
                      variant="danger"
                      classes="rounded-[100px] px-2 py-[2px] ml-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} id="edit-cashback-slider-form">
            <div className="mt-6 flex flex-col">
              <Text
                classes="font-semibold text-lg text-neutral-800 mb-7"
                translationKey="company.cashback.availableForRedeem"
              />
              <BalanceCard
                iconName="Cashback"
                iconClasses="text-success-500 bg-success-50"
                iconBgClasses="text-success-500 bg-success-50"
                label="dashboard.cashback.availableRedeem"
                amount={cashback?.values?.available?.value}
                currency={cashback?.values?.available?.currency}
              />
              <Text
                classes="font-medium text-lg text-neutral-800 my-7"
                translationKey="company.cashback.howMuchRedeem"
              />
              <div className="mt-4">
                <Input
                  classes="text-xl font-semibold"
                  name="amount"
                  type="number"
                  value={values?.amount}
                  rightText={cashback?.values?.available?.currency}
                  onChange={handleChange}
                  label="forms.enterAmount"
                  placeholder={0}
                  error={errors.amount}
                />
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="slider-footer sticky p-6 ">
        <div className="flex justify-end ">
          {review ? (
            <Button
              classes="w-16 text-neutral-500 mr-3"
              label="cancel"
              onClick={() => handleBack()}
              variant="tertiary"
              type="default"
            />
          ) : null}
          <Button
            label={review ? "company.cashback.title" : "misc.continue"}
            classes="w-18 px-5 py-3 text-base font-semibold"
            disabled={isFormButtonDisabled}
            id="exchange-button"
            btnType="submit"
            onClick={() => {
              if (review) onSave();
            }}
            form="edit-cashback-slider-form"
          />
        </div>
      </div>
    </div>
  );
}
