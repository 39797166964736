import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { saveClaimPolicyData } from "@/store/reducers/reimbursement";

import Button from "@/components/core/Button";
// component imports
import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Radio from "@/components/core/Radio";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import ClaimPolicyHeader from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ClaimPolicyHeader";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  FLEXIBLE,
  NON_FLEXIBLE,
  REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE,
} from "@/constants/reimbursement";

/**
 * This component allows users to add and edit claim policy categories for a company.
 * It includes a dropdown for selecting categories, options for defining policy limits,
 * and a table to display and edit the selected categories and their corresponding limits.
 * Users can choose between flexible and non-flexible limits for each category.

 * @param {Array} categoriesList - The list of available claim policy categories.
 * @param {Array} dropdownCategoryName - The currently selected claim policy categories.
 * @param {function} setDropdownCategoryName - function to set the selected claim policy categories.
 * @param {function} getReimbursementSettingsClaimPolicy - function to retrieve claim policy settings.
 * @param {function} handleDiscard - function to handle the discard action.
 * @param {Object} claimPolicyData - Data representing the existing claim policy settings.
 */

const AddCompanyClaimPolicyCategories = ({
  categoriesList,
  dropdownCategoryName,
  setDropdownCategoryName,
  getReimbursementSettingsClaimPolicy,
  handleDiscard,
  claimPolicyData,
  projectDepartmentId,
  customiseClaim,
}) => {
  const { tableHeadingRowClasses, tableHeading } =
    getReimbursementSettingsClaimPolicy(
      REIMBURSEMENT_SETTINGS_SEARCH_PARAM_VALUE.ADD
    );

  const dispatch = useDispatch();
  const [limitType, setLimitType] = useState(
    claimPolicyData?.flexible ? FLEXIBLE : NON_FLEXIBLE
  );

  const [limitInput, setLimitInput] = useState(null);

  const [listingData, setListingData] = useState([]);

  useEffect(() => {
    if (dropdownCategoryName?.length) {
      setListingData(dropdownCategoryName);
    }
  }, [dropdownCategoryName]);

  const isDropdownEmpty = dropdownCategoryName?.length === 0;
  const isButtonDisabled =
    limitInput && Object.keys(limitInput ?? {})?.length !== listingData?.length;

  const addCategoriesdropdownHandler = (selectedCategory) => {
    const selectedArrayCategory = categoriesList.filter((obj) =>
      selectedCategory.selectedIds.includes(obj.id)
    );

    setDropdownCategoryName(selectedArrayCategory);

    setListingData(selectedArrayCategory);
  };

  const handleDeleteCategories = (id) => {
    const tempCategoryLimit = listingData.filter((obj) => obj.id !== id);
    const tempLimitInput = { ...limitInput };
    delete tempLimitInput[id];
    setLimitInput(tempLimitInput);
    setDropdownCategoryName(tempCategoryLimit);

    setListingData(tempCategoryLimit);
  };

  const handleSaveData = () => {
    setDropdownCategoryName(listingData);
    const categoryData = listingData.map((category, index) => {
      const apicategoryObject =
        claimPolicyData?.claimPolicyCategoryLimits?.find(
          (item) => item?.categoryId === category?.id
        );

      return {
        ...(apicategoryObject && !customiseClaim
          ? { id: apicategoryObject?.id }
          : {}),
        category_id: category?.id,
        limit: limitInput?.[category?.id],
      };
    });

    const deletedData = claimPolicyData?.claimPolicyCategoryLimits
      ?.map((item, index) => {
        const searchItem = !listingData.find(
          (obj) => obj.id === item.categoryId
        );
        if (searchItem) {
          return {
            ...item,
            _destroy: true,
          };
        }
      })
      .filter(Boolean);

    const data = {
      flexible: limitType === FLEXIBLE,
      claim_policy_category_limits_attributes: [
        ...categoryData,
        ...deletedData,
      ],
    };

    if (customiseClaim) {
      data.project_id = parseInt(projectDepartmentId, 10);
    } else {
      if (projectDepartmentId) {
        data.project_id = parseInt(projectDepartmentId, 10);
      }
      data.id = claimPolicyData?.id;
    }

    dispatch(saveClaimPolicyData(data));
    handleDiscard();
  };

  const getLimitData = (id) => {
    const requiredObject = claimPolicyData?.claimPolicyCategoryLimits?.find(
      (obj) => obj.categoryId === id
    );
    if (requiredObject) {
      setLimitInput((prev) => ({
        ...prev,
        [requiredObject.categoryId]: requiredObject.limit,
      }));
    }
  };
  const discardHandler = () => {
    const existingIds = claimPolicyData?.claimPolicyCategoryLimits?.map(
      (item) => item.categoryId
    );
    setDropdownCategoryName(
      categoriesList.filter((item) => existingIds.includes(item.id))
    );
    handleDiscard();
  };

  useEffect(() => {
    const _limitType = claimPolicyData?.flexible ? FLEXIBLE : NON_FLEXIBLE;
    setLimitType(_limitType);
  }, [claimPolicyData?.flexible]);

  return (
    <>
      <div className="pb-16 ">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col mt-6 gap-0.5">
            <Text
              classes="text-lg font-medium"
              translationKey="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.subTitle"
            />
            <Text
              classes="text-sm text-neutral-500"
              translationKey="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.subDescription"
            />
          </div>
          <div className="my-6">
            <CheckboxDropdown
              label="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.dropDownLabel"
              selectedIdsArray={listingData.map((obj) => obj.id)}
              handleSubmit={addCategoriesdropdownHandler}
              saveOnExternalEvent
              options={categoriesList.map((category) => ({
                ...category,
                label: category.name,
                disabled: dropdownCategoryName
                  ?.filter((data) =>
                    claimPolicyData?.claimPolicyCategoryLimits?.some(
                      (itme) => itme.categoryId === data.id
                    )
                  )
                  .some((data) => data.id === category.id),
              }))}
            />
          </div>
        </div>
        {listingData?.length ? (
          <div className="w-full ">
            <div className="flex flex-col gap-3 p-4 rounded-md bg-neutral-50">
              <Text
                classes="text-lg font-medium"
                translationKey="myVolopay.reimbursements.drafts.tableHeaders.limitType"
              />
              <Radio
                name="type"
                label="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.limitTypeNonFlexibleTitle"
                isChecked={limitType === NON_FLEXIBLE}
                handleChange={() => setLimitType(NON_FLEXIBLE)}
              />
              <Text
                translationKey="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.nonFlexibleDesc"
                classes="pl-8 -mt-4 text-sm text-neutral-500"
              />
              <Radio
                name="type"
                label="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.limitTypeFlexibleTitle"
                isChecked={limitType === FLEXIBLE}
                handleChange={() => setLimitType(FLEXIBLE)}
              />
              <Text
                translationKey="reimbursement.settings.sliders.claimPolicies.companyClaimPolicy.flexibleDesc"
                classes="pl-8 -mt-4 text-sm text-neutral-500"
              />
            </div>
            <div className="flex flex-col p-0.5 mt-6 border rounded-lg border-neutral-300 ">
              <Table colWidths={[200, 200]}>
                <tr className={tableHeadingRowClasses}>
                  {tableHeading.map((headVal, index) => (
                    <th className={headVal.classes} key={headVal.id}>
                      <ClaimPolicyHeader
                        val={{
                          ...headVal,
                        }}
                      />
                    </th>
                  ))}
                </tr>
                {listingData.map((category, index, arr) => {
                  return (
                    <tr key={category?.id}>
                      {tableHeading.map(
                        ({ cellComponent: Component, classes, id }) => (
                          <td
                            key={id}
                            className={`${classes}  py-8  ${
                              arr?.length === index + 1 ? "!border-none" : ""
                            } `}
                          >
                            <Component
                              val={category}
                              getLimitData={getLimitData}
                              key={id}
                              handleDeleteCategories={handleDeleteCategories}
                              limitInput={limitInput}
                              setLimitInput={setLimitInput}
                              claimPolicyData={claimPolicyData}
                            />
                          </td>
                        )
                      )}
                    </tr>
                  );
                })}
              </Table>
            </div>
          </div>
        ) : null}
      </div>

      <div className="absolute bottom-0 flex justify-end gap-5 px-3 py-5 mt-4 slider-footer">
        <Button
          variant="tertiary"
          classes="text-neutral-500 w-16.2 font-semibold"
          label="reimbursement.settings.sliders.claimPolicies.ctas.discard"
          onClick={discardHandler}
        />
        <Button
          disabled={isButtonDisabled}
          classes="w-16.2  font-semibold"
          label="reimbursement.settings.sliders.claimPolicies.ctas.save"
          onClick={handleSaveData}
        />
      </div>
    </>
  );
};
AddCompanyClaimPolicyCategories.propTypes = {
  categoriesList: PropTypes.array,
  dropdownCategoryName: PropTypes.array,
  setDropdownCategoryName: PropTypes.func,
  getReimbursementSettingsClaimPolicy: PropTypes.func,
  handleDiscard: PropTypes.func,
  claimPolicyData: PropTypes.object,
  projectDepartmentId: PropTypes.string,
  customiseClaim: PropTypes.string,
};
export default AddCompanyClaimPolicyCategories;
