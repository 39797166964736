import PropTypes from "prop-types";

// import "@/assets/styles/theme.scss";

import { loadLanguages } from "i18next";

export default function Progress({
  id,
  value = null,
  variant = "",
  classes = "",
  data = null,
  viewBox = "2 -2 28 36",
  neutralTrack = false,
  type = "default",
}) {
  const orderedItems = () => {
    return data.items.sort((valA, valB) => (valA.value < valB.value ? 1 : -1));
  };

  return (
    <>
      {value || value === 0 ? (
        <progress
          id={id}
          max="100"
          value={value}
          className={`${variant} ${classes} ${
            neutralTrack ? "neutral-track" : ""
          }`}
        />
      ) : null}
      {data ? (
        <div
          className={`min-h-3 progress-bar flex rounded-lg overflow-hidden ${variant} ${
            neutralTrack ? "neutral-track" : ""
          } ${classes}`}
        >
          {data && data.items && type === "default"
            ? data.items
                .sort((valA, valB) => (valA.value < valB.value ? 1 : -1))
                .map((val, index) => (
                  <div
                    key={`progress-${id}-index-${index}`}
                    className={`h-3 progress-${val.variant}  rounded-none`}
                    style={{ width: `${(val.value * 100) / data.total}%` }}
                  />
                ))
            : data.items.map((val, index) => (
                <div
                  key={`progress-${id}-index-${index}`}
                  className={`h-3 progress-${val.variant}  rounded-none`}
                  style={{ width: `${(val.value * 100) / data.total}%` }}
                />
              ))}
        </div>
      ) : null}
    </>
  );
}

Progress.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  variant: PropTypes.string,
  classes: PropTypes.string,
  data: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.number]),
        variant: PropTypes.string, // corporate-cards | expense-management | accounts-payable
      })
    ),
  }),
  neutralTrack: PropTypes.bool,
  viewBox: PropTypes.string,
  type: PropTypes.string,
};
