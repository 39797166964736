import PropTypes from "prop-types";

import { dateToString } from "@/utils/common";

export default function InvoiceDateCell({ val }) {
  return <span>{val.invoiceDate ? dateToString(val.invoiceDate) : "-"}</span>;
}

InvoiceDateCell.propTypes = {
  val: PropTypes.object,
};
