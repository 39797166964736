import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import DateInput from "@/components/core/DateInput";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import { dateStringToDate, getRange } from "@/utils/common";

import { DATEINPUT_MODES, DATE_RANGE_KEYS } from "@/constants/date";

export default function DateRangeFilter({
  filterKey,
  popupTriggerComponent,
  type,
  options,
  callback,
  defaultValues,
  showCustomDate = true,
  inSlider = false,
  btnClasses = "",
  dateInputProps = {},
  ...rest
}) {
  const popupRef = useRef();

  const [defaults, setDefaults] = useState([new Date(), new Date()]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleSelection = (option) => {
    let res;
    if (showDatePicker) res = getRange(null, option.target.value);
    else {
      res = getRange(option.value, []);
    }

    callback({
      [AVAILABLE_FILTER_KEYS.dateRange]: {
        ...res,
        ...option,
      },
    });

    popupRef.current.close();
  };

  const formatDefaultValues = () => {
    if (defaultValues?.dateRange) {
      setDefaults([
        dateStringToDate(defaultValues.dateRange.from),
        dateStringToDate(defaultValues.dateRange.to),
      ]);
    }
  };

  useEffect(() => {
    formatDefaultValues();
  }, [defaultValues]);

  useEffect(() => {
    if (type === AVAILABLE_FILTER_KEYS.dateRange) setShowDatePicker(true);
  }, [type]);

  useEffect(() => {
    if (inSlider && showDatePicker) {
      popupRef.current.open();
    }
  }, [showDatePicker, inSlider]);

  // reposition datePicker
  useEffect(() => {
    if (showDatePicker) {
      window.dispatchEvent(new Event("resize"));
    }
  }, [showDatePicker]);

  //  TO close popup js when scrolled
  useEffect(() => {
    const handleScroll = (e) => {
      popupRef?.current?.close();
    };
    const sliderComponents = document.querySelectorAll(".slider-content-core");
    if (sliderComponents?.length) {
      sliderComponents?.forEach((sliderComponent) => {
        sliderComponent.addEventListener("scroll", handleScroll);
        return () => {
          sliderComponent.removeEventListener("scroll", handleScroll);
        };
      });
    }
  }, []);

  return (
    <Popup
      key={inSlider ? `${showDatePicker}-popup` : "react-date-popup"}
      keepTooltipInside="#tooltip-inside"
      ref={popupRef}
      trigger={
        popupTriggerComponent ? (
          popupTriggerComponent()
        ) : (
          <button
            className={`p-3 card-wrapper w-[135px] text-neutral-500  ${
              focused ? "border-primary-400" : ""
            }`}
          >
            <div className="flex items-center justify-center gap-1 ">
              <Icon name={rest?.icon || "Calendar"} className="p-0.5" />
              <Text translationKey={rest?.label || "dateRange"} />
            </div>
          </button>
        )
      }
      closeOnDocumentClick
      repositionOnResize
      onOpen={() => setFocused(true)}
      position="bottom left"
      className="filter-popup"
      onClose={() => {
        setFocused(false);
        setShowDatePicker(false);
      }}
    >
      {!showDatePicker ? (
        <div key="date-picker-list" className="flex flex-col ">
          {options.map((option) => {
            return (
              <button
                key={`list--without-checkbox-filter-${option.label}`}
                className={`w-full py-4 pl-3 pr-10 ml-0 text-left hover:bg-neutral-50 hover:text-neutral-900 active:bg-red text-neutral-500 ${btnClasses}`}
                onClick={() => handleSelection(option)}
              >
                <div className="flex flex-col">
                  <Text translationKey={option.label} />
                  {option?.subLabel ? (
                    <Text
                      translationProps={option.subLabelTranslationProps}
                      classes="text-xs font-medium text-neutral-400"
                      translationKey={option.subLabel}
                    />
                  ) : null}
                </div>
              </button>
            );
          })}
          {showCustomDate ? (
            <button
              className={`w-full py-5 pl-3 pr-10 ml-0 text-left hover:bg-neutral-50 hover:text-neutral-900 active:bg-red text-neutral-500 ${btnClasses}`}
              onClick={() => setShowDatePicker(true)}
            >
              <Text translationKey="filters.customRange" />
            </button>
          ) : null}
        </div>
      ) : (
        <div key="date-picker-dateInput">
          <DateInput
            onCancelDateRange={() => {
              popupRef.current.close();
            }}
            mode={DATEINPUT_MODES.DATE_RANGE}
            value={defaults}
            onChange={handleSelection}
            inline
            content={
              <button className="flex items-center gap-2 py-3 card-wrapper w-fit text-neutral-500">
                <Icon name="Calendar" />
                <Text translationKey="dateRange" />
              </button>
            }
            {...dateInputProps}
          />
        </div>
      )}
    </Popup>
  );
}

DateRangeFilter.propTypes = {
  filterKey: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  callback: PropTypes.func,
  defaultValues: PropTypes.any,
  popupTriggerComponent: PropTypes.elementType,
  showCustomDate: PropTypes.bool,
  inSlider: PropTypes.bool,
  btnClasses: PropTypes.string,
  dateInputProps: PropTypes.object,
};
