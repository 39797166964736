import { getDateInPattern, getRange } from "@/utils/common";

import { DATE_RANGE_KEYS } from "./date";

export const FREQUENCY_OPTIONS = [
  {
    label: "filters.frequency.oneTime",
    value: "one_time",
    subLabel: "cardSetting.doesntReset",
    subLabelProp: true,
  },
  {
    label: "filters.frequency.monthly",
    value: "monthly",
    subLabel: "cardSetting.resetNext",
    subLabelProp: {
      date: getDateInPattern(
        getRange(DATE_RANGE_KEYS.currentMonth, [], 1)?.to,
        "mmm dd, yyyy",
        "dd-mm-yyyy"
      ),
    },
  },
  {
    label: "filters.frequency.quarterly",
    value: "quarterly",
    subLabel: "cardSetting.resetNext",
    subLabelProp: {
      date: getDateInPattern(
        getRange(DATE_RANGE_KEYS.quarter, [], 1)?.to,
        "mmm dd, yyyy",
        "dd-mm-yyyy"
      ),
    },
  },
  {
    label: "filters.frequency.halfYearly",
    value: "half_yearly",
    subLabel: "cardSetting.resetNext",
    subLabelProp: {
      date: getDateInPattern(
        getRange(DATE_RANGE_KEYS.halfYearly, [], 1)?.to,
        "mmm dd, yyyy",
        "dd-mm-yyyy"
      ),
    },
  },
  {
    label: "filters.frequency.yearly",
    value: "yearly",
    subLabel: "cardSetting.resetNext",
    subLabelProp: {
      date: getDateInPattern(
        getRange(DATE_RANGE_KEYS.currentYear, [], 1)?.to,
        "mmm dd, yyyy",
        "dd-mm-yyyy"
      ),
    },
  },
];
