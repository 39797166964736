import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { amountToCurrency } from "@/utils/common";

/**
 * `special` means other than dropdown label, value
 * `currencyDesc` will come as string in reimbursement payment slider when claim balance is greater than account balance
 */
export function paymentAccountDropdownOptionSelected(
  displayValue,
  option,
  currencyDesc,
  currencyDescClasses
) {
  const currency = displayValue;

  const {
    specialLabel = "",
    specialDescription = "",
    specialIcon = "",
    showBalance = false,
    balance = 0,
  } = option || {};

  return (
    <div className="">
      <div className="flex items-center gap-3">
        {specialIcon ? (
          <Icon name={specialIcon} classes="h-10 w-10" />
        ) : (
          <Flag code={currency} classes="h-10 w-10" border />
        )}

        <div
          aria-label="left-side-payment-account-info"
          className="flex flex-col justify-between gap-[2px] grow-1 "
        >
          <Text
            translationKey="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.payingFrom"
            classes="text-neutral-500 font-base font-medium"
          />
          <span className="text-lg font-semibold">
            {specialLabel ? (
              <Text translationKey={specialLabel} />
            ) : (
              <>
                {currency}{" "}
                <Text translationKey="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.accountLowerCase" />
                {typeof currencyDesc === typeof "" ? (
                  <div>
                    <Text
                      classes={`text-xs font-semibold ${currencyDescClasses} `}
                      translationKey={currencyDesc}
                    />
                  </div>
                ) : null}
              </>
            )}
          </span>
        </div>
        {showBalance ? (
          <div
            aria-label="right-side-payment-account-info"
            className="flex flex-col items-end gap-[2px] grow-1 mt-4"
          >
            <Text
              translationKey="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.balance"
              classes="text-neutral-500 font-base font-medium"
            />
            <span>
              <span className="text-lg font-bold">
                {amountToCurrency(balance)}
              </span>
              <span className="text-base font-semibold text-neutral-500">
                {currency}
              </span>
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

/**
 * `special` means other than dropdown label, value
 * `currencyDesc` will come as string in reimbursement payment slider when claim balance is greater than account balance
 */
export function paymentAccountDropdownOption(
  displayValue,
  option,
  displayValueDesc,
  currencyDescClasses
) {
  const currency = displayValue;
  const amount = option.balance;
  const currencyDesc = displayValueDesc;

  const {
    specialLabel = "",
    specialDescription = "",
    specialIcon = "",
    showBalance = false,
  } = option || {};

  if (specialLabel && specialDescription) {
    return (
      <div className="my-2">
        <Text
          translationKey={specialLabel}
          classes="text-neutral-800 text-base font-medium inline-block"
        />
        <Text
          translationKey={specialDescription}
          classes="text-neutral-400 text-xs font-medium inline-block"
        />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between my-2 text-neutral-500">
      <div>
        {specialIcon ? (
          <Icon name={specialIcon} classes="h-10 w-10" />
        ) : currency ? (
          <Flag code={currency} classes="h-6 w-6 border-1 border-neutral-200" />
        ) : null}
        <span className="ml-3">
          {specialLabel || specialDescription ? (
            <>
              {specialLabel ? (
                <Text
                  translationKey={specialLabel}
                  classes="text-neutral-800 text-base font-medium inline-block"
                />
              ) : null}
              {specialDescription ? (
                <Text
                  translationKey={specialDescription}
                  classes="text-neutral-400 text-xs font-medium inline-block"
                />
              ) : null}
            </>
          ) : (
            <>
              {currency}{" "}
              <Text translationKey="billPay.bill.invoiceInbox.createBill.sections.paymentDetails.accountTitleCase" />
              {typeof currencyDesc === typeof "" ? (
                <div>
                  <Text
                    classes={`text-xs font-semibold ${currencyDescClasses} `}
                    translationKey={currencyDesc}
                  />
                </div>
              ) : null}
            </>
          )}
        </span>
      </div>
      {showBalance ? (
        <span>
          {amountToCurrency(amount)} {currency}
        </span>
      ) : null}
    </div>
  );
}
