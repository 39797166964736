import PropTypes from "prop-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { lineItemsSelector } from "@/store/selectors/payments";

import TextWithTooltipAndAmount from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipAndAmount";
import { BILL_RESPONSE_KEYS } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import { getTaxValues } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/utils";
import { PAYMENT_MODES } from "@/utils/constants/reimbursement";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import "@/components/common/BillPayAndPayroll/style.scss";

import AmountToBePaid from "../../Inbox/Create/common/AmountToBePaid";
import InvoiceTotalPaid from "./InvoiceTotalPaid";

/* Breakdown (taxes, totals) */
/* If taxes are at line items, then taxes here are the sums. The table will have line item level taxes. */

export default function PaymentTotalBreakdown({
  payment,
  context,
  isYSTRApplicable,
}) {
  const lineItems = useSelector(lineItemsSelector);
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

  const isPayOutsideVolopay =
    payment?.paymentMode === PAYMENT_MODES.payOutsideVolopay;

  const beneficiaryCurrency = payment?.quote?.toCurrency;
  const senderCurrency = payment?.quote?.fromCurrency;

  const subtotal = payment?.invoiceSubtotal?.value || 0;
  const [additiveTaxes, subtractiveTaxes] = useMemo(() => {
    const adds = [];
    const subs = [];

    // line item level or otherwise (btw, a tax can be at bill or line item level, but not both)
    [
      ...(payment?.taxes || []),
      ...Object.values(
        lineItems.reduce((accum, item) => {
          // gather similar taxes from all line items
          // if optional ones are all OFF, they'll be ignored
          const taxesOfLineItem = item.taxes;
          taxesOfLineItem?.forEach((taxItem) => {
            // add to existing item. BE ensured name is unique.
            // All properties are the same for taxes of the same name.
            // rate is meaningless in sum
            const taxName = taxItem[BILL_RESPONSE_KEYS.TAX_ITEM.NAME];
            if (accum[taxName]) {
              accum[taxName][BILL_RESPONSE_KEYS.TAX_ITEM.VALUE] =
                +accum[taxName][BILL_RESPONSE_KEYS.TAX_ITEM.VALUE] +
                +taxItem[BILL_RESPONSE_KEYS.TAX_ITEM.VALUE];
            } else {
              accum[taxName] = structuredClone(taxItem); // avoid redux write issue
              accum[taxName][BILL_RESPONSE_KEYS.TAX_ITEM.RATE] = null; // rate is meaningless in sum
            }
          });

          return accum;
        }, {})
      ),
    ]
      .map((taxItem) => {
        const { value, currency, description } = getTaxValues(
          taxItem,
          payment?.invoiceSubtotal // only for currency
        );
        const taxName = taxItem[BILL_RESPONSE_KEYS.TAX_ITEM.NAME];
        return {
          id: `taxItem-${taxName}`,
          [BILL_RESPONSE_KEYS.TAX_ITEM.DEDUCTIVE_TAX]:
            taxItem?.[BILL_RESPONSE_KEYS.TAX_ITEM.DEDUCTIVE_TAX],
          amount: {
            value,
            currency,
          },
          name: taxItem?.name ?? description,
          description: taxItem?.description,
        };
      })
      .forEach((taxItem) =>
        taxItem?.[BILL_RESPONSE_KEYS.TAX_ITEM.DEDUCTIVE_TAX]
          ? subs.push(taxItem)
          : adds.push(taxItem)
      );

    return [adds, subs];
  });

  const sumOfAdditiveTaxes = additiveTaxes.reduce(
    (accum, item) => +accum + +item.amount.value,
    0
  );

  const total = (payment?.invoiceSubtotal?.value || 0) + +sumOfAdditiveTaxes; // "total"
  const isShowTaxBreakdown = additiveTaxes.length + subtractiveTaxes.length > 0;

  return (
    <>
      {isShowTaxBreakdown ? (
        <div className="flex flex-col gap-4 p-6 mt-1 rounded-lg bg-neutral-50">
          {additiveTaxes.length && !inPayrollContext ? (
            <>
              {/* Invoice subtotal */}
              <TextWithTooltipAndAmount
                text="billPay.bill.invoiceInbox.createBill.sections.lineItems.invoiceSubtotal"
                tooltipText={
                  additiveTaxes?.[0]?.name
                    ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.exclusiveOfXYZTax"
                    : ""
                }
                tooltipTextProps={{
                  taxName: additiveTaxes?.[0]?.name,
                }}
                currency={beneficiaryCurrency}
                amount={subtotal}
                classes=""
              />

              {/* Additive taxes (sums or at line item level off) */}
              {additiveTaxes.map((taxItem, index) => {
                const additiveBillLevelTaxes = payment?.taxes?.filter(
                  (tax) => !tax?.[BILL_RESPONSE_KEYS.TAX_ITEM.DEDUCTIVE_TAX]
                );
                return (
                  <TextWithTooltipAndAmount
                    key={taxItem.id}
                    text={taxItem.name}
                    currency={taxItem.amount.currency}
                    amount={taxItem.amount.value}
                    isTax
                    classes=""
                  >
                    {additiveBillLevelTaxes?.[index] ? (
                      <div className="text-sm font-semibold -pt-1 text-neutral-500">
                        {additiveBillLevelTaxes?.[index]?.category?.includes?.(
                          "%"
                        ) ||
                        !additiveBillLevelTaxes?.[index]?.taxRate ? null : (
                          <p>
                            {`${
                              additiveBillLevelTaxes?.[index]?.taxRate || 0
                            }%`}
                          </p>
                        )}
                        <p>
                          {/* TECH_DEBT: currently FE saved (and thus BE returns the string 'undefined'), BE cleanup to be done soon, remove this then */}
                          {((t) => (t && t !== "undefined" ? t : null))(
                            additiveBillLevelTaxes?.[index]?.category
                          )}
                        </p>
                      </div>
                    ) : null}
                  </TextWithTooltipAndAmount>
                );
              })}
            </>
          ) : null}

          {/* Total */}
          <TextWithTooltipAndAmount
            text={
              inPayrollContext
                ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.paymentTotal"
                : "billPay.bill.invoiceInbox.createBill.sections.lineItems.invoiceTotal"
            }
            tooltipText={
              additiveTaxes?.[0]?.name
                ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.inclusiveOfXYZTax"
                : ""
            }
            tooltipTextProps={{
              taxName: additiveTaxes?.[0]?.name,
            }}
            currency={beneficiaryCurrency}
            amount={total}
            classes=""
          />

          {/* Subtractive taxes (sums or at line item level off) */}
          {subtractiveTaxes.map((taxItem, index) => {
            const subtractiveBillLevelTaxes = payment?.taxes?.filter(
              (tax) => tax?.[BILL_RESPONSE_KEYS.TAX_ITEM.DEDUCTIVE_TAX]
            );
            return (
              <TextWithTooltipAndAmount
                key={taxItem.id}
                text={taxItem.name}
                currency={taxItem.amount.currency}
                amount={taxItem.amount.value}
                isTax
                isSubtractive
                classes=""
              >
                {subtractiveBillLevelTaxes?.[index] ? (
                  <div className="text-sm font-semibold -pt-1 text-neutral-500">
                    {subtractiveBillLevelTaxes?.[index]?.category?.includes?.(
                      "%"
                    ) || !subtractiveBillLevelTaxes?.[index]?.taxRate ? null : (
                      <p>
                        {`-${
                          subtractiveBillLevelTaxes?.[index]?.taxRate || 0
                        }%`}
                      </p>
                    )}
                    <p>
                      {/* TECH_DEBT: currently FE saved (and thus BE returns the string 'undefined'), BE cleanup to be done soon, remove this then */}
                      {((t) => (t && t !== "undefined" ? t : null))(
                        subtractiveBillLevelTaxes?.[index]?.category
                      )}
                    </p>
                  </div>
                ) : null}
              </TextWithTooltipAndAmount>
            );
          })}
        </div>
      ) : (
        <AmountToBePaid
          amount={total}
          currency={beneficiaryCurrency}
          titleText="billPay.bill.invoiceInbox.createBill.sections.lineItems.invoiceTotal"
          blueBorder={false}
          classes="mt-2"
          inReqSlider
          descriptionText={
            isYSTRApplicable
              ? context === BILL_PAYROLL_CONTEXT.BILLPAY
                ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.vendorReceivesThisAmount"
                : "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentInformation.employeeReceivesThisAmount"
              : ""
          }
        />
      )}

      <InvoiceTotalPaid
        classes="mt-3"
        exchangeRate={payment?.quote?.rate}
        transferFees={payment?.quote?.transferFees}
        quoteAmount={payment?.quote?.totalAmount}
        senderCurrency={senderCurrency}
        beneficiaryCurrency={beneficiaryCurrency}
        context={context}
        isPayOutsideVolopay={isPayOutsideVolopay}
        tooltipText={
          subtractiveTaxes?.[0]?.name
            ? inPayrollContext
              ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.taxXSubtractedFromPaymentTotal"
              : "billPay.bill.invoiceInbox.createBill.sections.lineItems.taxXSubtractedFromInvoiceTotal"
            : ""
        }
        tooltipTextProps={{
          taxName: subtractiveTaxes?.[0]?.name,
        }}
        isYSTRApplicable={isYSTRApplicable}
      />
    </>
  );
}

PaymentTotalBreakdown.propTypes = {
  payment: PropTypes.object,
  context: PropTypes.string,
  isYSTRApplicable: PropTypes.bool,
};
