import PropTypes from "prop-types";
import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function PaymentsLoaderHelper({ hideLastCell = false }) {
  return (
    <tr className="text-center">
      <td className="flex items-center gap-3 ml-4">
        <LoaderSkeleton size={[20, 20]} />
        <LoaderSkeleton type="circle" />
        <LoaderSkeleton size={[20, 180]} />
      </td>

      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton size={[20, 30]} />
      </td>
      <td>
        <LoaderSkeleton size={[20, 30]} />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      {hideLastCell ? null : (
        <td className="flex justify-center gap-6">
          <LoaderSkeleton size={[30, 100]} />
          <LoaderSkeleton size={[30, 30]} />
        </td>
      )}
    </tr>
  );
}

PaymentsLoaderHelper.propTypes = {
  hideLastCell: PropTypes.bool, // true for invoice-inbox page
};
