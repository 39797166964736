import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function MainPageHeader({
  title,
  subtitle = "",
  subtitleTranslationProps = {},
  showBack,
  showGreetIcon,
  handleBack = () => {},
}) {
  return (
    <>
      {showBack ? (
        <div onClick={handleBack}>
          <Icon name="ChevronLeft" className="mb-4 cursor-pointer" />
        </div>
      ) : null}
      <Text classes="text-3xl font-bold" translationKey={title} />
      <div className="flex items-center gap-1">
        <Text
          classes="text-sm font-semibold text-neutral-500"
          translationKey={subtitle}
          translationProps={subtitleTranslationProps}
        />
        {showGreetIcon ? <span>👋</span> : null}
      </div>
    </>
  );
}

MainPageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleTranslationProps: PropTypes.object,
  showBack: PropTypes.bool,
  showGreetIcon: PropTypes.bool,
  handleBack: PropTypes.func,
};
