import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Icon from "../Icon";
import Input from "../Input";
import Text from "../Text";

function OtpInput({
  label,
  value,
  error,
  type,
  description,
  // outsideDivClasses,
  onChange,
  name,
  labelExtraClasses,
  otpLimit,
  otpSentCount,
  handleResendOtpButton,
  allowPasswordEye = true,
  otpNumberCount = 6,
}) {
  const [timeLimit, setTimeLimit] = useState(30);
  const [textType, setTextType] = useState("password");

  const timeLimitText = `${
    timeLimit === 0 ? "Resend OTP" : `Resend OTP in ${timeLimit}s`
  }`;

  useEffect(() => {
    handleLimitText();
  }, [timeLimit]);

  function resendOtp() {
    setTimeLimit(30);
    handleLimitText();
    handleResendOtpButton();
  }

  function handleLimitText() {
    const limitInterval = setTimeout(() => {
      const currentTimeLimit = timeLimit - 1;
      setTimeLimit(currentTimeLimit);
    }, [1000]);

    if (timeLimit === 0) {
      clearTimeout(limitInterval);
    }
  }

  function toggleShowPassword(event) {
    event.stopPropagation();
    if (textType === "password") {
      setTextType("text");
    } else if (textType === "text") {
      setTextType("password");
    }
  }

  const getDescription = () => {
    const limitText =
      otpSentCount === otpLimit
        ? " Max resend otp attempts exceeded"
        : timeLimitText;

    const timeLimitClasses =
      otpSentCount === otpLimit
        ? "text-xs text-danger-600"
        : `text-xs text-neutral-500 pl-0 ${
            timeLimit === 0 && "cursor-pointer text-primary-500"
          }`;

    return (
      <div className="flex flex-row justify-between w-full mt-4">
        <Text
          translationKey={description}
          classes="text-xs text-neutral-500 pl-0"
        />

        <div onClick={timeLimit === 0 && resendOtp}>
          <Text translationKey={limitText} classes={timeLimitClasses} />
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <Input
        value={value}
        error={error}
        name={name}
        placeholder="• • • - • • •"
        maskField
        label={label}
        description={getDescription()}
        outsideDivClasses="w-[610px]"
        onChange={(e) => {
          if (e?.target?.value?.length <= otpNumberCount) {
            onChange(e);
          }
        }}
        labelExtraClasses={labelExtraClasses}
        fallBackPasswordType={type}
        allowPasswordEye
      />
    </div>
  );
}

OtpInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  error: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  // outsideDivClasses: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  labelExtraClasses: PropTypes.string,
  otpLimit: PropTypes.number,
  otpSentCount: PropTypes.number,
  handleResendOtpButton: PropTypes.func,
  allowPasswordEye: PropTypes.bool,
  otpNumberCount: PropTypes.number,
};

export default OtpInput;
