import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { updateTagAndVendor } from "@/store/reducers/accounting_transactions";
import { setSelectedExpense } from "@/store/reducers/expense";
import { fetchTags } from "@/store/reducers/tags";

import { selectedAccountingTransactionSelector } from "@/store/selectors/accounting_transactions";
import {
  accountingIntegrationSoftwareSelector,
  clientSelector,
  defaultCurrencySelector,
} from "@/store/selectors/client";
import { selectedExpenseSelector } from "@/store/selectors/expense";
import { selectedQrpaymentSelector } from "@/store/selectors/qr-payments";
import {
  accountingCategoryTagsSelector,
  accountingNonCategoryTags,
  cardsCustomTagsSelector,
  customTagsSelector,
  isFetchingTagsSelector,
  isTagsFetchedSelector,
  qrpayCustomTagsSelector,
} from "@/store/selectors/tags";

import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import TagInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/TagInput";
import { amountToCurrency, debounce } from "@/utils/common";

import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import { EXPENSE_TYPE, TRANSACTION_TYPE_LINE_ITEM } from "@/constants/expense";
import { TAG_FIELD_TYPES } from "@/constants/tags";

export default function ExpenseAndQRPaySplitDisplay({
  disabled = true,
  expenseType = EXPENSE_TYPE.EXPENSE,
  isAccounting,
}) {
  const dispatch = useDispatch();
  const isFetchingAccountingTags = useSelector(isFetchingTagsSelector);
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = !!accountingSoftware;
  const selectedExpense = useSelector(
    isAccounting
      ? selectedAccountingTransactionSelector
      : expenseType === EXPENSE_TYPE.EXPENSE
        ? selectedExpenseSelector
        : selectedQrpaymentSelector
  );

  const splitExpenses = selectedExpense?.lineItems || [];

  const defaultCurrency = useSelector(defaultCurrencySelector);

  const isFetchedAccountingTags = useSelector(isTagsFetchedSelector);
  const categoryAccountingTags = useSelector(accountingCategoryTagsSelector);
  const nonCategoryAccountingTags = useSelector(accountingNonCategoryTags);

  const transactionLevelTags =
    useSelector(clientSelector)?.transactionLevelTags;

  const netsuiteLineItemLevelTags = nonCategoryAccountingTags?.filter(
    (nonCategoryAccountingTag) => {
      const netsuiteTransactionLevelTagIds = transactionLevelTags?.map(
        (transactionLevelTag) => transactionLevelTag?.id
      );
      return !netsuiteTransactionLevelTagIds?.includes(
        nonCategoryAccountingTag?.id
      );
    }
  );

  const accountingLineItemTags =
    transactionLevelTags?.length > 0
      ? netsuiteLineItemLevelTags
      : nonCategoryAccountingTags;

  function getCustomTagSelector() {
    switch (expenseType) {
      case EXPENSE_TYPE.EXPENSE:
        return cardsCustomTagsSelector;
      case EXPENSE_TYPE.QR_PAY:
        return qrpayCustomTagsSelector;
      default:
        return customTagsSelector;
    }
  }
  const customTags = useSelector(getCustomTagSelector());
  const debouncedUpdate = debounce((params) => {
    dispatch(updateTagAndVendor(params));
  }, 500);
  const handleUpdateTagsandVendors = async ({
    expense,
    option,
    isTextField,
    isDateField,
  }) => {
    let payload = {
      transaction_type: TRANSACTION_TYPE_LINE_ITEM,
      accountable_type: expenseType,
      line_item_id: expense?.id,
    };
    payload = {
      ...payload,
      tag_id: option?.tagId,
      ...(isTextField || isDateField
        ? { custom_text_value: option?.target?.value }
        : { tag_value_id: option?.id }),
    };
    const onSuccess = (transaction) => {
      dispatch(setSelectedExpense(transaction));
    };
    if (isTextField) {
      debouncedUpdate({ payload, onSuccess, splitItemTable: true });
    } else
      dispatch(
        updateTagAndVendor({ payload, onSuccess, splitItemTable: true })
      );
  };

  return (
    <div>
      {splitExpenses?.length
        ? splitExpenses?.map((expense, index) => {
            const accountingCategoryLineItem = expense?.lineItemTagValues?.find(
              (_) => _?.tagValue?.tagId === categoryAccountingTags?.id
            );

            return (
              <div
                key={`split-display-key-${index}`}
                className="flex flex-col border-[1px] my-6 p-4 rounded-lg border-neutral-200 gap-6"
              >
                <div className="flex justify-between">
                  <div className="font-semibold text-neutral-500">
                    <Text
                      translationKey="expenses.splitExpense.splitNoTitle"
                      translationProps={{ splitNo: index + 1 }}
                    />
                  </div>
                  <div className="font-bold text-neutral-800">
                    <Text
                      translationKey={amountToCurrency(
                        expense.amount,
                        defaultCurrency
                      )}
                    />
                  </div>
                </div>

                {accountingEnabled ? (
                  <div
                    className={`flex flex-col gap-6 ${
                      disabled ? "text-neutral-400 opacity-50" : ""
                    }`}
                  >
                    {categoryAccountingTags ? (
                      <VpSelect
                        placeholder="expenses.slider.accountingCategory"
                        label="expenses.slider.accountingCategory"
                        value={accountingCategoryLineItem?.tagValue?.tagValueId}
                        options={categoryAccountingTags?.options}
                        labelTranslationProp={{
                          accounting:
                            ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                              accountingSoftware
                            ],
                        }}
                        placeholderTranslationProp={{
                          accounting:
                            ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                              accountingSoftware
                            ],
                        }}
                        disabled={disabled}
                        menuPosition="absolute"
                        isOptionsFetched={isFetchedAccountingTags}
                        isOptionsLoading={isFetchingAccountingTags}
                        fetchOptions={() => dispatch(fetchTags())}
                        handleChange={(option) => {
                          handleUpdateTagsandVendors({ expense, option });
                        }}
                        optionsDisplayKey="alias"
                        valueKey="tagValueId"
                      />
                    ) : null}
                    {accountingLineItemTags.length > 0 ? (
                      <div className="flex flex-col gap-6">
                        {accountingLineItemTags?.map((tag, idx) => {
                          const selectedNonCategoryTag =
                            expense?.lineItemTagValues?.find(
                              (item) => item?.tagValue?.tagId === tag?.id
                            );

                          const selectedNonCategoryTagValue =
                            selectedNonCategoryTag?.tagValue?.tagValueId;

                          return (
                            <VpSelect
                              key={`vp-select-split-custom-tags-section-${idx}`}
                              label={tag?.name}
                              placeholder={tag?.name}
                              value={selectedNonCategoryTagValue}
                              menuPosition="absolute"
                              options={tag?.options}
                              handleChange={(option) => {
                                handleUpdateTagsandVendors({ expense, option });
                              }}
                              valueKey="id"
                              optionsDisplayKey="alias"
                              disabled={disabled}
                              classes={`${
                                disabled
                                  ? "text-neutral-400"
                                  : "text-neutral-800"
                              }`}
                            />
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {customTags?.length
                  ? customTags?.map((tag) => {
                      const selectedCustomTag =
                        expense?.lineItemTagValues?.find(
                          (item) => item?.tagValue?.tagId === tag?.id
                        );
                      const isText = tag?.fieldType === TAG_FIELD_TYPES.TEXT;
                      const isDate = tag?.fieldType === TAG_FIELD_TYPES.DATE;
                      const selectedCustomTagValue =
                        isText || isDate
                          ? selectedCustomTag?.customTextValue
                          : selectedCustomTag?.tagValue?.tagValueId;

                      return (
                        <TagInput
                          key={tag?.id}
                          tag={tag}
                          values={selectedCustomTagValue}
                          isDirectValue
                          wantValueOnBlur
                          handleChange={(e) => {
                            handleUpdateTagsandVendors({
                              expense,
                              option:
                                isText || isDate
                                  ? {
                                      ...e,
                                      ...(isText
                                        ? {
                                            target: {
                                              value: e?.textValue,
                                            },
                                          }
                                        : {}),
                                      tagId: tag?.id,
                                    }
                                  : e,
                              isTextField:
                                tag?.fieldType === TAG_FIELD_TYPES.TEXT,
                              isDateField:
                                tag?.fieldType === TAG_FIELD_TYPES.DATE,
                            });
                          }}
                        />
                      );
                    })
                  : null}
              </div>
            );
          })
        : null}
    </div>
  );
}

ExpenseAndQRPaySplitDisplay.propTypes = {
  disabled: PropTypes.bool,
  expenseType: PropTypes.string,
  isAccounting: PropTypes.bool,
};
