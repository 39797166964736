import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

import AddSection from "../../Common/AddSection";

function EditCardContols({ onClick }) {
  return (
    <div className="flex flex-col gap-5">
      <Text
        translationKey="cards.cardDetails.editCardDetails.settings.cardControls.label"
        classes="text-lg font-semibold text-neutral-800"
      />

      <AddSection
        title="cards.cardDetails.editCardDetails.cardControls.addSection.title"
        description="cards.cardDetails.editCardDetails.cardControls.addSection.description"
        onClick={onClick}
      />
    </div>
  );
}

EditCardContols.propTypes = {
  onClick: PropTypes.func,
};

export default EditCardContols;
