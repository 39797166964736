import { createSlice } from "@reduxjs/toolkit";

const SliderInitialState = {
  sliderHeaderData: {
    title: null,
    titleTranslationProps: null,
    icon: null,
    img: null,
    iconStyle: null,
  },
  showLeftSliderHeader: false,
  isHeaderShadowVisible: false,
  isSliderClosed: true,
  hasSliderExpanded: false,
};

const sliderSlice = createSlice({
  name: "slider",
  initialState: SliderInitialState,
  reducers: {
    setSliderExpand(state, action) {
      state.hasSliderExpanded = action.payload;
    },
    setIsSliderClosed(state, action) {
      state.isSliderClosed = action.payload;
    },
    setSliderHeaderData(state, action) {
      state.sliderHeaderData = { ...state.sliderHeaderData, ...action.payload };
    },
    setShowLeftSlider(state, action) {
      state.showLeftSliderHeader = action.payload;
    },
    setIsHeaderShadowVisible(state, action) {
      state.isHeaderShadowVisible = action.payload;
    },
    resetLeftSliderHeaderData(state) {
      state.sliderHeaderData = {
        title: null,
        titleTranslationProps: null,
        icon: null,
        img: null,
        iconStyle: null,
      };
    },
    resetSliderStore: () => SliderInitialState,
  },
});
export default sliderSlice.reducer;
export const {
  setSliderExpand,
  setShowLeftSlider,
  setSliderHeaderData,
  setIsSliderClosed,
  resetSliderStore,
  resetLeftSliderHeaderData,
  setIsHeaderShadowVisible,
} = sliderSlice.actions;
