import PropTypes from "prop-types";
import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

function CardCellLoader({ outerSectionClasses }) {
  return (
    <div className={`${outerSectionClasses} flex gap-2`}>
      <LoaderSkeleton type="circle" size={[40, 40]} />
      <div className="flex flex-col">
        <LoaderSkeleton size={[20, 100]} />
        <LoaderSkeleton size={[10, 80]} />
      </div>
    </div>
  );
}

CardCellLoader.propTypes = {
  outerSectionClasses: PropTypes.string,
};

export default CardCellLoader;
