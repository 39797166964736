import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { exchangeFunds, generateFundQuote } from "@/store/reducers/client";
import { fetchAndSelectWalletAccountDetails } from "@/store/reducers/company";

import {
  generatedFundQuoteSelector,
  isFetchingClientSelector,
  paymentWalletListSelector,
} from "@/store/selectors/client";
import { selectedWalletAccountSelector } from "@/store/selectors/company";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Flag from "@/components/core/Flag";
import Input from "@/components/core/Input";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import QuoteExchangeRate from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/QuoteExchangeRate";
import {
  GENERATE_QUOTE_API_BODY_KEYS_REQUEST,
  PAYMENT_TYPE,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import { useForm } from "@/utils/useForm";
import { PAYMENT_CHANNELS } from "@/utils/constants/payments";
import { amountToCurrency, debounce } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";

export default function LocalAndSwiftSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentAccounts = useSelector(paymentWalletListSelector);
  const generatedFundQuote = useSelector(generatedFundQuoteSelector);
  const isFetching = useSelector(isFetchingClientSelector);
  const curCurrency = searchParams.get(SLIDERS_SEARCH_PARAMS.company.currency);

  const [debouncedAmount, setDebouncedAmount] = useState(null);
  const [fromAccount, setFromAccount] = useState(null);
  const [toAccount, setToAccount] = useState(null);
  const [review, setReivew] = useState(false);

  const ref = useLeftHeaderTitle({
    title: `company.exchangeFund.${review ? "reviewTitle" : "title"}`,
  });

  const initialFormValue = {
    amount: {
      value: "",
      validate: {
        required: true,
        maxNumber: parseFloat(fromAccount?.amount),
      },
      errorStatement: {
        required: "cards.cardDetails.editLimitDetails.errorStatement",
        maxNumber: "form.errorStatements.exchangeLimit",
      },
    },
  };

  const { handleChange, values, errors, isFormButtonDisabled } = useForm(
    initialFormValue,
    () => {
      handleSubmit();
    }
  );

  const dropDownOptions = paymentAccounts?.map((acc) => ({
    value: acc.currency,
    currency: acc.currency,
    amount: acc.availableBalance,
    id: acc.id,
  }));

  useEffect(() => {
    if (!paymentAccounts) return;

    const from = dropDownOptions?.find((acc) => acc.currency === curCurrency);
    const to = dropDownOptions?.filter(
      (acc) => acc.currency !== curCurrency
    )[0];

    setFromAccount(from);
    setToAccount(to);
    dispatch(
      fetchAndSelectWalletAccountDetails({
        curCurrency,
        accountType: "payments",
      })
    );
  }, [JSON.stringify(dropDownOptions)]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const isValidNumber = !Number.isNaN(parseFloat(values?.amount));
      if (isValidNumber) generateQuote();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [values?.amount, toAccount, fromAccount]);

  const generateQuote = () => {
    const params = {
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.AMOUNT]: values?.amount,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.BUY_CURRENCY]: toAccount?.currency,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.PAYMENT_CHANNEL]:
        PAYMENT_CHANNELS.LOCAL,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.SELL_CURRENCY]:
        fromAccount?.currency,
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.FIXED_SIDE]: "sell",
      [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.TYPE]: "exchange",
    };

    if (values?.amount > 0) dispatch(generateFundQuote({ params }));
  };

  const handleBack = () => {
    setReivew(false);
    setSearchParams({
      [SLIDERS_SEARCH_PARAMS.company.exchangeFund]: FORM_TYPE.edit,
    });
  };

  const handleSubmit = () => {
    const onSuccess = () => {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.company.exchangeFund);
      setSearchParams(searchParams);
    };

    if (review) {
      const params = {
        [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.AMOUNT]: values?.amount,
        [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.CURRENCY]: fromAccount?.currency,
        [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.QUOTE_ID]: generatedFundQuote?.id,
        [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.PAYMENT_TYPE]:
          PAYMENT_TYPE.CONVERSION,
        [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.FROM_ACCOUNT_ID]: fromAccount.id,
        [GENERATE_QUOTE_API_BODY_KEYS_REQUEST.TO_ACCOUNT_ID]: toAccount.id,
      };
      dispatch(exchangeFunds({ value: params, onSuccess }));
    } else {
      setReivew(true);
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.company.exchangeFund]: FORM_TYPE.review,
      });
    }
  };

  const customAccountChip = (option) => {
    return (
      <div className="flex items-center w-full gap-1 py-1">
        <div className="flex justify-between w-full align-center">
          <div>
            <Flag
              code={option?.currency}
              size="md"
              classes="border border-neutral-200"
            />
            <Text
              classes="text-sm text-neutral-800 font-medium ml-2"
              translationKey={`${option?.currency} account`}
            />
          </div>
          <Text
            classes="text-sm text-neutral-500 font-medium ml-2 flex items-center"
            translationKey={amountToCurrency(option.amount, option.currency)}
          />
        </div>
      </div>
    );
  };

  const customEntryWhenDropdownIsOpen = (displayValue, option) => {
    return customAccountChip(option);
  };

  const customFromAccountValueChip = () => {
    return customAccountChip(fromAccount);
  };

  const customToAccountValueChip = () => {
    return customAccountChip(toAccount);
  };

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        <div>
          <Text
            ref={ref}
            classes="font-bold text-3xl text-neutral-800"
            translationKey={`company.exchangeFund.${
              review ? "reviewTitle" : "title"
            }`}
          />
        </div>
        <Text
          classes="text-sm text-neutral-500 mt-1"
          translationKey={`company.exchangeFund.${
            review ? "reviewDesc" : "description"
          }`}
        />
        {review ? (
          <div>
            <div className="mt-7">
              <Text
                classes="font-semibold text-lg text-neutral-800"
                translationKey="company.exchangeFund.sendingFrom"
              />
              <div className="flex items-center mt-4 card-wrapper">
                <Flag
                  code={fromAccount?.currency}
                  size="lg"
                  classes="border border-neutral-200"
                />
                <div className="ml-3">
                  <Text
                    classes="text-xs text-neutral-800 font-semibold"
                    translationKey="company.exchangeFund.afterTransfer"
                    translationProps={{ currency: fromAccount?.currency }}
                  />
                  <div className="flex items-baseline">
                    <span className="text-2xl font-bold text-neutral-800">
                      {amountToCurrency(
                        parseFloat(fromAccount?.amount) -
                          parseFloat(values?.amount) || 0
                      )}
                    </span>
                    <span className="ml-1 font-semibold text-neutral-500">
                      {fromAccount?.currency}
                    </span>
                    <Badge
                      translationKey={`- ${amountToCurrency(
                        values?.amount,
                        fromAccount?.currency
                      )}`}
                      variant="danger"
                      classes="rounded-[100px] px-2 py-[2px] ml-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-10 mt-7">
              <Text
                classes="font-semibold text-lg text-neutral-800"
                translationKey="company.exchangeFund.sendingTo"
              />
              <div className="flex items-center mt-4 card-wrapper">
                <Flag
                  code={toAccount?.currency}
                  size="lg"
                  classes="border border-neutral-200"
                />
                <div className="ml-3">
                  <Text
                    classes="text-xs text-neutral-800 font-semibold"
                    translationKey="company.exchangeFund.afterTransfer"
                    translationProps={{ currency: toAccount?.currency }}
                  />
                  <div className="flex items-baseline">
                    <span className="text-2xl font-bold text-neutral-800">
                      {amountToCurrency(
                        parseFloat(toAccount?.amount) +
                          parseFloat(generatedFundQuote?.toAmount) || 0
                      )}
                    </span>
                    <span className="ml-1 font-semibold text-neutral-500">
                      {toAccount?.currency}
                    </span>
                    <Badge
                      translationKey={`+ ${amountToCurrency(
                        generatedFundQuote?.toAmount,
                        toAccount?.currency
                      )}`}
                      variant="success"
                      classes="rounded-[100px] px-2 py-[2px] ml-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between mt-8 mb-4 card-wrapper">
              <div>
                <Text
                  classes="text-sm text-neutral-800 mt-8"
                  translationKey="company.exchangeFund.sending"
                />
                <div className="flex items-baseline">
                  <span className="text-2xl font-bold text-neutral-800">
                    {amountToCurrency(values?.amount || 0)}
                  </span>
                  <span className="ml-1 font-semibold text-neutral-500">
                    {fromAccount?.currency}
                  </span>
                </div>
              </div>
              <Flag
                code={fromAccount?.currency}
                size="md"
                classes="border border-neutral-200"
              />
            </div>
            <QuoteExchangeRate
              senderCurrency={toAccount?.currency}
              beneficiaryCurrency={fromAccount?.currency}
              exchangeRate={generatedFundQuote?.rate}
              isFetching={false}
            />
            <div className="flex items-center justify-between mt-6 card-wrapper">
              <div>
                <Text
                  classes="text-sm text-neutral-800 mt-8"
                  translationKey="company.exchangeFund.receiving"
                />
                <div className="flex items-baseline">
                  <span className="text-2xl font-bold text-neutral-800">
                    {amountToCurrency(generatedFundQuote?.toAmount || 0)}
                  </span>
                  <span className="ml-1 font-semibold text-neutral-500">
                    {toAccount?.currency}
                  </span>
                </div>
              </div>
              <Flag
                code={toAccount?.currency}
                size="md"
                classes="border border-neutral-200"
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col mt-6">
            <Text
              classes="font-semibold text-lg text-neutral-800 mb-7"
              translationKey="company.exchangeFund.sendingFrom"
            />
            <VpSelect
              searchable={false}
              label="company.exchangeFund.selectAccount"
              labelStyleClasses="text-xs pt-2 my-6 font-semibold text-neutral-500"
              value={fromAccount}
              optionsDisplayKey="currency"
              valueKey="currency"
              menuPosition="absolute"
              options={dropDownOptions}
              labelTranslate={`${fromAccount ? "-translate-y-7" : ""}`}
              handleChange={(e) => {
                setFromAccount(e);
                if (e.currency === toAccount.currency)
                  setToAccount(
                    dropDownOptions.find((acc) => acc.currency !== e.currency)
                  );
              }}
              customUIForOptionWhenDropdownOpen={customEntryWhenDropdownIsOpen}
              customUIForOptionWhenDropdownClosed={customFromAccountValueChip}
            />
            <Text
              classes="font-semibold text-lg text-neutral-800 my-7"
              translationKey="company.exchangeFund.sendingTo"
            />
            <VpSelect
              searchable={false}
              label="company.exchangeFund.selectAccount"
              labelStyleClasses="text-xs pt-2 my-4 font-semibold text-neutral-500"
              value={toAccount}
              optionsDisplayKey="currency"
              valueKey="currency"
              menuPosition="absolute"
              options={dropDownOptions}
              labelTranslate={`${toAccount ? "-translate-y-7" : ""}`}
              handleChange={(e) => {
                setToAccount(e);
                if (e.currency === fromAccount.currency)
                  setFromAccount(
                    dropDownOptions.find((acc) => acc.currency !== e.currency)
                  );
              }}
              customUIForOptionWhenDropdownOpen={customEntryWhenDropdownIsOpen}
              customUIForOptionWhenDropdownClosed={customToAccountValueChip}
            />
            <Text
              classes="font-semibold text-lg text-neutral-800 mt-14"
              translationKey="company.exchangeFund.transferAmountOf"
            />
            <div className="mt-4">
              <Input
                classes="text-xl font-semibold"
                showNumberInvalidError
                name="amount"
                type="number"
                value={values?.amount}
                error={errors?.amount}
                minNumber={1}
                rightText={fromAccount?.currency}
                insideForm
                onChange={(e) => {
                  handleChange(e);
                }}
                label="forms.enterAmount"
                placeholder={`0 ${fromAccount?.currency}`}
              />
            </div>
            {toAccount && values?.amount ? (
              <div className="flex flex-col mt-6 ">
                {isFetching ? (
                  <LoaderSkeleton size={[50, 590]} />
                ) : (
                  <div className="flex justify-between card-wrapper">
                    <Text
                      classes="text-sm text-neutral-500"
                      translationKey="misc.exchangeRate"
                    />
                    <span>{generatedFundQuote?.rate}</span>
                  </div>
                )}
                {generatedFundQuote?.toAmount && !isFetching ? (
                  <div className="flex flex-col">
                    <Text
                      classes="text-sm text-neutral-500 mt-8"
                      translationKey="company.exchangeFund.receivedAmount"
                      translationProps={{ currency: toAccount?.currency }}
                    />
                    <Text
                      classes="text-xl font-semibold text-success-600 mt-1"
                      translationKey={amountToCurrency(
                        generatedFundQuote?.toAmount,
                        toAccount.currency
                      )}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </div>
      <div className="sticky p-6 slider-footer ">
        <div className="flex justify-end ">
          {review ? (
            <Button
              classes="w-16 text-neutral-500 mr-3"
              label="cancel"
              onClick={() => handleBack()}
              variant="tertiary"
              type="default"
            />
          ) : null}
          <Button
            label={
              review ? "company.exchangeFund.allocateFund" : "misc.continue"
            }
            classes="w-18 px-5 py-3 text-base font-semibold"
            disabled={
              isFormButtonDisabled ||
              !generatedFundQuote ||
              isFetching ||
              fromAccount?.value === toAccount?.value
            }
            id="exhange-button"
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </div>
    </div>
  );
}
