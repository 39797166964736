import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { downloadAccountDetail } from "@/store/reducers/client";

import { accountDetailSelector } from "@/store/selectors/client";
import { selectedWalletAccountSelector } from "@/store/selectors/company";
import { copyToClipboard } from "@/components/core/CopyToClipboard";

import Text from "@/components/core/Text";

import { SHARE_DETAIL_ROW_TEXTS } from "@/components/Company/Overview/AccountOverview/account-overview-utils";
import SliderRow from "@/components/Company/common/SliderRow";
import { camelToSnake } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP,
  PAYMENT_OPTION_TYPE,
} from "@/constants/company";

export default function ShareDetailsSection() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const datums = useSelector(accountDetailSelector) ?? [];
  const paymentOptionType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.paymentOption
  );

  const selectedWallet = useSelector(selectedWalletAccountSelector);

  const arrayToObject = (arr) => {
    return arr.reduce((acc, item) => {
      acc[camelToSnake(item.key)] = item.value;
      return acc;
    }, {});
  };

  const accountDetailsData = useMemo(() => {
    const _routingData =
      datums?.bankAccount?.routingCodes
        ?.filter(
          (routingData) =>
            ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP[routingData.type]
        )
        ?.map((acc) => {
          return {
            key: acc.type,
            value: acc.value,
          };
        }) ?? [];

    const bankAccountData = Object.keys(datums?.bankAccount ?? [])
      .filter(
        (key) =>
          datums?.bankAccount[key] && ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP[key]
      )
      .map((key) => {
        return {
          key,
          value: datums?.bankAccount[key],
        };
      });

    return [...bankAccountData, ..._routingData];
  }, [datums?.bankAccount]);

  const copyToClipboardHandler = () => {
    /* Copy example
      Account holder name: Rapyd Holdings Pte Ltd
      Account number: 01734766477783
      SWIFT code: WLFG02568XX
      Bank name: Wells Fargo
      Branch code: Long Beach
      Currency: USD
    */

    const textToCopy = accountDetailsData
      ?.map(
        (d) => `${t(ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP[d?.key])}: ${d?.value}`
      )
      .join("\n");

    copyToClipboard(textToCopy);
  };

  const downloadDetails = () => {
    let params = {};
    if (selectedWallet?.id && paymentOptionType !== PAYMENT_OPTION_TYPE.GLOBAL)
      params = {
        account_id: selectedWallet.id,
        type: paymentOptionType,
      };
    else {
      params = {
        bank_account: arrayToObject(accountDetailsData),
      };
    }

    dispatch(downloadAccountDetail(params));
  };

  return (
    <div className="mt-8">
      <Text
        translationKey="company.addFund.shareDetails"
        classes="text-lg font-semibold text-neutral-800"
      />
      <Text
        translationKey="company.addFund.shareDetailsDescription"
        classes="text-sm text-neutral-500 font-semibold mt-1 block"
      />
      <div className="flex flex-col gap-4 mt-6">
        {SHARE_DETAIL_ROW_TEXTS.map((item) => (
          <SliderRow
            key={item.title}
            title={item.title}
            description={item.description}
            icon={item.icon}
            iconRight={item.iconRight}
            rightIconClickHandler={
              item.icon === "Copy" ? copyToClipboardHandler : downloadDetails
            }
            iconClickHandler={null}
          />
        ))}
      </div>
    </div>
  );
}
