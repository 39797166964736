import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchCreditBills, fetchCreditDetails } from "@/store/reducers/credit";

import {
  creditFreezeDateSelector,
  creditFreezeSelector,
} from "@/store/selectors/client";
import {
  creditBillsSelector,
  creditDetailsSelector,
} from "@/store/selectors/credit";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import CreditBox from "@/components/Company/Credit/CreditBox";
import {
  checkIfUserActionAllowedUsingActionKey,
  dateToString,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";
import {
  USER_ALLOWED_CTA_KEYS,
  USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS,
} from "@/constants/user";
import VolopayCard from "@/assets/images/Volopay_Card.png";

function CreditPageHelper() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [onHover, setOnHover] = useState(false);

  const creditDetails = useSelector(creditDetailsSelector);
  const creditBills = useSelector(creditBillsSelector);
  const creditFreezed = useSelector(creditFreezeSelector);
  const creditFreezedDate = useSelector(creditFreezeDateSelector);

  const handleRepaymentSlider = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.credit.repayment, "main");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    dispatch(fetchCreditDetails());
    dispatch(fetchCreditBills());
  }, []);

  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);
  const ctas = [
    USER_ALLOWED_CTA_KEYS.MANAGE_AUTO_REPAYMENT,
    USER_ALLOWED_CTA_KEYS.INCREASE_CREDIT_LINE,
    USER_ALLOWED_CTA_KEYS.PAY_NOW,
  ];

  const permissionCheckResults = ctas.reduce((result, cta) => {
    const foundPermission = checkIfUserActionAllowedUsingActionKey(
      USER_ALLOWED_VALIDATE_RESPONSE_ACTION_KEYS.DASHBOARD_CREDIT,
      userAllowedActions,
      cta
    );

    result[cta] = foundPermission;
    return result;
  }, {});

  const {
    [USER_ALLOWED_CTA_KEYS.MANAGE_AUTO_REPAYMENT]: showManageCta,
    [USER_ALLOWED_CTA_KEYS.INCREASE_CREDIT_LINE]: showIncreaseCreditCta,
    [USER_ALLOWED_CTA_KEYS.PAY_NOW]: showPayNowCta,
  } = permissionCheckResults;

  return (
    <div className="flex flex-col px-8">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <div className="flex">
            <Text classes="text-neutral-500" translationKey="company.title" />
            <span className="mx-2 text-neutral-500"> / </span>
            <Text
              classes="text-neutral-500"
              translationKey="company.credit.title"
            />
          </div>
          <Text
            classes="text-3xl font-bold text-neutral-800"
            translationKey="company.credit.title"
          />
        </div>
        <div className="flex gap-6">
          {showManageCta ? (
            <Button
              onMouseEnter={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
              iconClasses={onHover ? "w-5 mb-1 h-5" : ""}
              id="autoRepayment"
              preIcon={onHover ? "Celebration" : ""}
              variant="tertiary"
              innerClasses="gap-1"
              classes={`w-[242px] text-btn-lg font-semibold cursor-not-allowed ${
                onHover ? "text-neutral-500" : ""
              }`}
              label={
                onHover
                  ? "common.comingSoon"
                  : "company.credit.buttonLabels.manageAutoRepayment"
              }
            />
          ) : null}
          <Button
            variant="tertiary"
            label="company.credit.buttonLabels.viewRepaymentHistory"
            classes="w-[242px] text-btn-lg font-semibold"
            onClick={() => {
              searchParams.append(
                SLIDERS_SEARCH_PARAMS.company.credit.repaymentHistory,
                "main"
              );
              setSearchParams(searchParams);
            }}
          />
        </div>
      </div>
      {creditFreezed ? (
        <Alert
          title="company.credit.creditFreezed"
          titleTransalationProp={{ date: dateToString(creditFreezedDate) }}
          variant="danger"
          icon="Lock"
          classes="mt-8"
          iconClasses="text-neutral-800"
        />
      ) : null}
      <div className="flex w-full gap-4 mt-4">
        <CreditBox
          creditDetails={creditDetails}
          openSliderHandler={handleRepaymentSlider}
          creditBills={creditBills}
          showPayNowCta={showPayNowCta}
        />
      </div>
      <div className="flex items-center justify-between w-full gap-4 mt-4 font-semibold card-wrapper border-primary-50 bg-primary-50">
        <div className="flex flex-col">
          <Text
            translationKey="company.credit.higherCreditQuestions"
            classes="ml-3 text-xl"
          />
          <Text
            translationKey="company.credit.higherCreditDesc"
            classes="ml-3 text-sm text-neutral-500"
          />
          <div className="flex items-center mt-8">
            {showIncreaseCreditCta ? (
              <Button
                variant="primary"
                label="company.credit.increaseCreditLine"
                onClick={() => {
                  setSearchParams({
                    [SLIDERS_SEARCH_PARAMS.company.credit.creditLimit]:
                      FORM_TYPE.increase,
                  });
                }}
                classes="w-20"
              />
            ) : null}

            <Button
              variant="tertiary"
              label="common.learnMore"
              classes="ml-4 w-15"
            />
          </div>
        </div>
        <img src={VolopayCard} alt="" className="w-20" />
      </div>
    </div>
  );
}

export default CreditPageHelper;
