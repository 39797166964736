import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  downloadSyncHistory,
  fetchSyncAttempts,
} from "@/store/reducers/sync-attempts";

import {
  isFetchingSyncAttemptsSelector,
  syncAttemptsHasMoreSelector,
  syncAttemptsListSelector,
} from "@/store/selectors/sync-attempts";

import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import LastSyncedOn, {
  LastSyncedOnLoader,
} from "@/components/Accounting/Transactions/common/LastSyncedOn";
import { dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARES_ICONS } from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function AllAttempts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const entityType = searchParams.get("entity");

  const dispatch = useDispatch();

  const syncAttempts = useSelector(syncAttemptsListSelector);
  const isFetching = useSelector(isFetchingSyncAttemptsSelector);

  const rowClickHandler = (id) => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.billPay.sync.id, id);
    setSearchParams(searchParams);
  };

  const lastSyncAttempt = syncAttempts[0];

  const ref = useLeftHeaderTitle({
    title: "accounting.transactions.syncHistory",
  });
  // infinite scroll logic
  const hasMore = useSelector(syncAttemptsHasMoreSelector);
  const filterOptions = {
    type: entityType,
  };

  const loadMore = () => {
    dispatch(
      fetchSyncAttempts({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        transaction_type: entityType,
        ...filterOptions,
      })
    );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    inSlide: true,
    filterOptions,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  const handleAction = (id) => {
    dispatch(downloadSyncHistory({ attempt_id: id }));
  };

  return (
    <div className="slider-content-core">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <Text
            classes="text-3xl text-neutral-800 font-bold inline-block"
            translationKey="accounting.transactions.syncHistory"
            refProp={ref}
          />
          <Text
            translationKey="accounting.transactions.syncAttemptsHelpText"
            classes="text-sm font-medium text-neutral-500 inline-block"
          />
        </div>

        {isFetching ? (
          <LastSyncedOnLoader />
        ) : (
          <LastSyncedOn
            date={lastSyncAttempt?.createdAtDate}
            time={lastSyncAttempt?.createdAtTime}
            software={
              ACCOUNTING_SOFTWARES_ICONS[lastSyncAttempt?.accountingSlug]
            }
          />
        )}
      </div>

      <div className="mt-8">
        <Table headerSticky colWidths={[244, 220, 120]}>
          <tr className="text-left text-xs font-semibold text-neutral-800">
            <th>
              <Text translationKey="accounting.transactions.slider.date&Time" />
            </th>
            <th className="text-center">
              <Text translationKey="accounting.transactions.slider.totalNoOfTransactions" />
            </th>
            <th className="text-center">
              <Text translationKey="accounting.transactions.slider.action" />
            </th>
          </tr>
          {syncAttempts.length || !isFetching
            ? syncAttempts?.map((attempt, index, arr) => (
                <tr
                  key={attempt.id}
                  ref={(reff) => {
                    if (index === arr.length - 1) handleRefChange(reff);
                  }}
                >
                  <td>
                    <div
                      // onClick={() => rowClickHandler(attempt?.id)}
                      className="flex flex-row items-center justify-between"
                    >
                      <div>
                        <div className="flex items-center gap-1">
                          <Text
                            translationKey={dateToString(
                              attempt?.createdAtDate
                            )}
                            classes="text-sm font-semibold text-neutral-800"
                          />
                          &#44;
                          <Text
                            translationKey={`${attempt?.createdAtTime} `}
                            classes="text-sm font-semibold text-neutral-800"
                          />
                        </div>
                      </div>
                      {attempt?.failedCount > 0 ? (
                        <Icon
                          name="SyncFailed"
                          className="w-7 h-7 p-1 border rounded-lg text-danger-500 bg-danger-50 border-danger-100"
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-center">
                      <Text
                        translationKey={attempt?.transactionCount}
                        classes="text-sm font-semibold text-neutral-800"
                      />
                    </div>
                  </td>
                  <td className="text-sm font-semibold text-neutral-800">
                    <div className="flex justify-center">
                      <Icon
                        name="Download"
                        className="w-5 h-5 text-neutral-500 cursor-pointer"
                        handleClick={() => {
                          handleAction(attempt?.id);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))
            : null}
          {isFetching ? <TableLoader /> : null}
        </Table>
      </div>
    </div>
  );
}

function TableLoader() {
  return Array(5)
    .fill(null)
    .map((_, i) => (
      <tr
        key={`${i}_AccountingTransactionsSyncAttemptsAll`}
        className="text-left flex items-center gap-6"
      >
        <td>
          <LoaderSkeleton size="xxl" />
        </td>
        <td>
          <LoaderSkeleton size="xxl" />
        </td>
        <td>
          <LoaderSkeleton size="xxl" borderRadius="200" />
        </td>
      </tr>
    ));
}
