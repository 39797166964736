import AmountCell from "@/components/Reimbursement/cells/AmountCell";
import DepartmentCell from "@/components/Reimbursement/cells/DepartmentCell";
import LinkedToCell from "@/components/Reimbursement/cells/LinkedToCell";
import LocationCell from "@/components/Reimbursement/cells/LocationCell";
import MemoCell from "@/components/Reimbursement/cells/MemoCell";
import ReceiptCell from "@/components/Reimbursement/cells/ReceiptCell";
import ReportNameCell from "@/components/Reimbursement/cells/ReportNameCell";
import SettledAmountCell from "@/components/Reimbursement/cells/SettledAmountCell";
import SettledClaimCell from "@/components/Reimbursement/cells/SettledClaimCell";
import TotalClaimCell from "@/components/Reimbursement/cells/TotalClaimCell";
import TotalReportCell from "@/components/Reimbursement/cells/TotalReportCell";
import TypeCell from "@/components/Reimbursement/cells/TypeCell";
import UnSettledAmountCell from "@/components/Reimbursement/cells/UnSettledAmountCell";
import UnSettledClaimCell from "@/components/Reimbursement/cells/UnSettledClaimCell";
import UserCell from "@/components/Reimbursement/cells/UserCell";
import ActionCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ActionCell";
import ApprovalProgressCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ApprovalProgressCell";
import SubmissionDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/SubmissionDateCell";
import TransactionDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/TransactionDateCell";
import {
  SORTING_CATEGORY,
  SORTING_CATEGORY_REIMBURSEMENT,
  SORTING_CATEGORY_REPORT,
} from "@/utils/constants/sorting";

export const REIMBURSEMENT_APPROVAL_TABLE_HEADER = {
  tableSetting: {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    numberOfStickyColsRight: 1,
    colWidths: [300, 200, 200, 200, 200, 200, 200, 120, 300],
    rightColWidths: [200],
  },
  emptyDataTitle: "reimbursement.emptyScreen.approval.title",
  emptySubHeadingDataTitle: "reimbursement.emptyScreen.approval.desc",
  tableHeading: [
    {
      id: "user",
      title: "reimbursement.approvals.listHeading.user",
      classes: "text-left",
      cellComponent: UserCell,
      showCheckbox: true,
    },
    {
      id: "amount",
      title: "reimbursement.approvals.listHeading.amount",
      classes: "text-right justify-end",
      cellComponent: AmountCell,
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },
    {
      id: "type",
      title: "reimbursement.approvals.listHeading.type",
      classes: "text-left",
      cellComponent: TypeCell,
    },
    {
      id: "approval",
      title: "reimbursement.approvals.listHeading.approval",
      classes: "text-left",
      cellComponent: ApprovalProgressCell,
    },
    {
      id: "submissionDate",
      title: "reimbursement.approvals.listHeading.submissionDate",
      classes: "text-left",
      cellComponent: SubmissionDateCell,
    },
    {
      id: "transactionDate",
      title: "reimbursement.approvals.listHeading.transactionDate",
      classes: "text-left",
      cellComponent: TransactionDateCell,
    },
    {
      id: "linkedTo",
      title: "reimbursement.approvals.listHeading.linkedTo",
      classes: "text-left",
      cellComponent: LinkedToCell,
    },
    {
      id: "receipt",
      title: "reimbursement.approvals.listHeading.receipt",
      classes: "text-center justify-center",
      cellComponent: ReceiptCell,
    },
    {
      id: "memo",
      title: "reimbursement.approvals.listHeading.memo",
      classes: "text-left",
      cellComponent: MemoCell,
    },
    {
      id: "action",
      title: "reimbursement.approvals.listHeading.actionText",
      classes: "justify-center",
      cellComponent: ActionCell,
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
  tableDataRowClasses:
    " text-sm font-semibold text-center border-y-2 border-neutral-100",
};

export const REIMBURSEMENT_PAYMENT_PAID_TABLE_HEADER = {
  paid: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 200, 300, 260],
    },
    emptyDataTitle: "reimbursement.emptyScreen.paid.title",
    emptySubHeadingDataTitle: "reimbursement.emptyScreen.paid.desc",

    tableHeading: [
      {
        id: "user",
        cellComponent: UserCell,
        title: "reimbursement.paid.listHeading.user",
        classes: "text-left",
        haveSort: false,
      },
      {
        id: "amount",
        cellComponent: SettledAmountCell,
        title: "reimbursement.paid.listHeading.amount",
        classes: "text-right justify-end",
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REIMBURSEMENT.SETTLED_AMOUNT,
      },
      {
        id: "claim",
        cellComponent: SettledClaimCell,
        title: "reimbursement.paid.listHeading.claims",
        classes: "justify-center",
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REIMBURSEMENT.SETTLED_CLAIM,
      },
      {
        id: "department",
        cellComponent: DepartmentCell,
        title: "reimbursement.paid.listHeading.department",
        classes: "text-left",
        haveSort: false,
      },
      {
        id: "location",
        cellComponent: LocationCell,
        title: "reimbursement.paid.listHeading.location",
        classes: "text-left",
        haveSort: false,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      "text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
  payments: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 200, 300, 260],
    },
    emptyDataTitle: "reimbursement.emptyScreen.payments.title",
    emptySubHeadingDataTitle: "reimbursement.emptyScreen.payments.desc",

    tableHeading: [
      {
        id: "user",
        cellComponent: UserCell,
        title: "reimbursement.payments.listHeading.user",
        classes: "text-left",
        haveSort: false,
      },
      {
        id: "amount",
        cellComponent: UnSettledAmountCell,
        title: "reimbursement.payments.listHeading.amount",
        classes: "text-right justify-end",
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REIMBURSEMENT.UNSETTLED_AMOUNT,
      },
      {
        id: "claim",
        cellComponent: UnSettledClaimCell,
        title: "reimbursement.payments.listHeading.claims",
        classes: "justify-center",
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REIMBURSEMENT.UNSETTLED_CLAIM,
      },
      {
        id: "department",
        cellComponent: DepartmentCell,
        title: "reimbursement.payments.listHeading.department",
        classes: "text-left",
        haveSort: false,
      },
      {
        id: "location",
        cellComponent: LocationCell,
        title: "reimbursement.payments.listHeading.location",
        classes: "text-left",
        haveSort: false,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      "text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
};

export const REPORT_APPROVAL_TABLE_HEADER = {
  tableSetting: {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    numberOfStickyColsRight: 1,
    colWidths: [300, 200, 200, 200, 200, 200, 200, 120, 300],
    rightColWidths: [200],
  },
  emptyDataTitle: "reimbursement.emptyScreen.approval.title",
  emptySubHeadingDataTitle: "reimbursement.emptyScreen.approval.desc",
  tableHeading: [
    {
      id: "user",
      title: "reimbursement.approvals.listHeading.user",
      classes: "text-left",
      cellComponent: UserCell,
      showCheckbox: true,
    },
    {
      id: "reportName",
      title: "Report name",
      classes: "text-left",
      cellComponent: ReportNameCell,
    },
    {
      id: "amount",
      title: "Total amount",
      classes: "text-right justify-end",
      cellComponent: AmountCell,
      haveSort: true,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },

    {
      id: "approval",
      title: "reimbursement.approvals.listHeading.approval",
      classes: "text-left",
      cellComponent: ApprovalProgressCell,
    },

    {
      id: "totalClaims",
      title: "No of claims",
      classes: "text-left",
      cellComponent: TotalClaimCell,
    },
    {
      id: "submissionDate",
      title: "reimbursement.approvals.listHeading.submissionDate",
      classes: "text-left",
      cellComponent: SubmissionDateCell,
    },

    {
      id: "linkedTo",
      title: "reimbursement.approvals.listHeading.linkedTo",
      classes: "text-left",
      cellComponent: LinkedToCell,
    },
    {
      id: "memo",
      title: "Description",
      classes: "text-left",
      cellComponent: MemoCell,
    },
    {
      id: "action",
      title: "reimbursement.approvals.listHeading.actionText",
      classes: "justify-center",
      cellComponent: ActionCell,
    },
  ],
  tableHeadingRowClasses:
    "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
  tableDataRowClasses:
    " text-sm font-semibold text-center border-y-2 border-neutral-100",
};

export const REPORT_PAYMENT_PAID_TABLE_HEADER = {
  paid: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 200, 300, 260],
    },
    emptyDataTitle: "reimbursement.emptyScreen.paid.title",
    emptySubHeadingDataTitle: "reimbursement.emptyScreen.paid.desc",

    tableHeading: [
      {
        id: "user",
        cellComponent: UserCell,
        title: "reimbursement.paid.listHeading.user",
        classes: "text-left",
        haveSort: false,
      },
      {
        id: "amount",
        cellComponent: SettledAmountCell,
        title: "reimbursement.paid.listHeading.amount",
        classes: "text-right justify-end",
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REIMBURSEMENT.SETTLED_AMOUNT,
      },
      {
        id: "totalReport",
        cellComponent: TotalReportCell,
        title: "No of reports",
        classes: "justify-center",
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REPORT.SETTLED_REPORT,
      },
      {
        id: "department",
        cellComponent: DepartmentCell,
        title: "reimbursement.paid.listHeading.department",
        classes: "text-left",
        haveSort: false,
      },
      {
        id: "location",
        cellComponent: LocationCell,
        title: "reimbursement.paid.listHeading.location",
        classes: "text-left",
        haveSort: false,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      "text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
  payments: {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      colWidths: [300, 200, 200, 300, 260],
    },
    emptyDataTitle: "reimbursement.emptyScreen.payments.title",
    emptySubHeadingDataTitle: "reimbursement.emptyScreen.payments.desc",

    tableHeading: [
      {
        id: "user",
        cellComponent: UserCell,
        title: "reimbursement.payments.listHeading.user",
        classes: "text-left",
        haveSort: false,
      },
      {
        id: "amount",
        cellComponent: UnSettledAmountCell,
        title: "reimbursement.payments.listHeading.amount",
        classes: "text-right justify-end",
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REIMBURSEMENT.UNSETTLED_AMOUNT,
      },
      {
        id: "totalReport",
        cellComponent: TotalReportCell,
        title: "No of reports",
        classes: "justify-center",
        haveSort: true,
        sortingCategory: SORTING_CATEGORY_REPORT.UNSETTLED_REPORT,
      },
      {
        id: "department",
        cellComponent: DepartmentCell,
        title: "reimbursement.payments.listHeading.department",
        classes: "text-left",
        haveSort: false,
      },
      {
        id: "location",
        cellComponent: LocationCell,
        title: "reimbursement.payments.listHeading.location",
        classes: "text-left",
        haveSort: false,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-50 border-neutal-200",
    tableDataRowClasses:
      "text-sm font-semibold text-center border-y-2 border-neutral-100",
  },
};
