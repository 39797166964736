import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { payrollJobStatusForBulkUploadSelector } from "@/store/selectors/payments";
import { hasSliderExpandedSelector } from "@/store/selectors/slider";

import Text from "@/components/core/Text";

import ImportantToKnowSection from "@/components/Company/common/ImportantToKnowSection";
import JobStatusBanner from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/BulkUploadPayroll/JobStatusBanner";

export default function FailedRowsTable({ classes }) {
  const bulkUploadJobStatusInfo = useSelector(
    payrollJobStatusForBulkUploadSelector
  );

  const failedRows =
    typeof bulkUploadJobStatusInfo?.failedRows === typeof ""
      ? []
      : bulkUploadJobStatusInfo?.failedRows;

  const showFailedSection = failedRows?.length > 0;

  const hasSliderExpanded = useSelector(hasSliderExpandedSelector);

  if (!showFailedSection) return null;

  const config = [
    {
      verticalExpandable: true,
      labelConfig: {
        label: (
          <JobStatusBanner
            listCount={failedRows?.length}
            mainTitle="payroll.bulkUpload.tables.missingData.mainTitle"
            tableDataInfoNote="payroll.bulkUpload.tables.missingData.tableDataInfoNote"
            tableDateInfoClasses={
              !hasSliderExpanded ? "w-84 grow truncate" : "mr-3"
            }
            mainTitleClasses="w-13"
          />
        ),
      },
      innerContent: (
        <div className="px-3 py-4 bg-white">
          <div className="flex flex-row justify-start px-3 py-3 border-b border-solid border-neutral-200">
            <Text
              translationKey="payroll.bulkUpload.tables.missingData.rowNo"
              classes="text-sm font-semibold text-neutral-800"
            />
          </div>

          {failedRows?.map((failedRow, index) => {
            return (
              <div
                className="flex flex-row items-start px-3 py-3 border-b border-solid border-neutral-200"
                key={`${failedRow}-${index}`}
              >
                <Text
                  translationKey={failedRow}
                  classes="text-sm font-semibold text-neutral-800"
                />
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <ImportantToKnowSection
      classes={classes}
      config={config}
      allSectionContainerClasses="bg-warning-50 border-none p-0"
      showSectionExpandableStatusText
      expandableIconClasses="text-neutral-800"
      headerSectionClasses="card-wrapper border-none p-3 "
      headerLeftSectionClasses={
        hasSliderExpanded ? "w-full text-justify flex flex-row" : "w-full mr-6"
      }
      innerContentWrapperClasses="mt-0"
    />
  );
}

FailedRowsTable.propTypes = {
  classes: PropTypes.string,
};
