import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { isFetchingRulesCountSelector } from "@/store/selectors/rules";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import MissingCardLoader from "@/components/common/QrPayAndExpense/common/ExpenseLoaders/Loaders/MissingCard";

export default function RulesCard({ rulesCount, viewRules }) {
  const isFetchingRulesCount = useSelector(isFetchingRulesCountSelector);

  return (
    <div
      className={`flex items-center p-4 rounded-xl hover:shadow-md w-[190px] h-[76px] cursor-pointer border border-neutral-200 ${
        !rulesCount ? "hover:cursor-not-allowed" : ""
      }`}
      onClick={viewRules}
    >
      {!isFetchingRulesCount ? (
        <>
          <div
            className={`${
              rulesCount > 0 ? "bg-primary-50" : ""
            }  rounded-full p-2 `}
          >
            <Icon
              className="border rounded-full bg-primary-500 border-primary-500 text-primary-50"
              name="LightiningBolt"
            />
          </div>
          <div className="flex flex-col pl-3">
            <Text
              translationKey="accounting.rules.title"
              classes="text-neutral-500 text-sm font-medium"
            />
            <span className="text-xl font-semibold text-neutral-800">
              {rulesCount}
            </span>
          </div>
        </>
      ) : (
        <MissingCardLoader />
      )}
    </div>
  );
}

RulesCard.propTypes = {
  rulesCount: PropTypes.number,
  viewRules: PropTypes.func,
};
