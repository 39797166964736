import PropTypes from "prop-types";
import { useMemo } from "react";

import CommentDisplay from "@/components/core/Comments/CommentDisplay";
import CommentsInput from "@/components/core/Comments/CommentsInput";
import { groupByDateTimeUserAndCommentType } from "@/utils/common";

import Button from "../Button";

export default function Comments({
  comments,
  createNewComment,
  currentUser,
  isDisabled,
  isVisible,
  sanitizeHtmlOptions,
  loadMoreLabel,
  showLoadMoreCta,
  loadMoreComments,
}) {
  // grouping comments
  const groupedComments = useMemo(
    () =>
      groupByDateTimeUserAndCommentType(
        comments,
        "createdAtDate",
        "createdAtTime",
        "user"
      ),
    [comments]
  );

  return (
    <div className="flex flex-col gap-5 p-5 my-5 rounded-lg bg-neutral-50 ">
      {/* show older comments cta which will handle pagination */}
      {showLoadMoreCta ? (
        <Button
          label={loadMoreLabel}
          variant="priamry"
          onClick={loadMoreComments}
          classes="text-primary-500 underline w-fit px-2 self-center"
        />
      ) : null}

      <div className="overflow-x-hidden overflow-y-auto max-h-90">
        <CommentDisplay comments={groupedComments} />
      </div>

      <CommentsInput
        sanitizeHtmlOptions={sanitizeHtmlOptions}
        currentUser={currentUser}
        isDisabled={isDisabled}
        isVisible={isVisible}
        onSubmit={createNewComment}
      />
    </div>
  );
}
Comments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  createNewComment: PropTypes.func,
  currentUser: PropTypes.object,
  isDisabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  sanitizeHtmlOptions: PropTypes.object,
  loadMoreLabel: PropTypes.string,
  showLoadMoreCta: PropTypes.bool,
  loadMoreComments: PropTypes.func,
};
