export const COMMENT_TYPE = {
  FLAGGED: "flagged",
  COMMENTED: "commented",
  USER_CREATED: "user_created",
  REPAYMENT_REQUESTED: "repayment_requested",
  REPAYMENT_RECEIVED: "repayment_received",
  REPAYMENT_CANCELLED: "repayment_cancelled",
  APPROVED: "approved",
  AUTO_FLAGGED: "auto_flagged",
  CLAIM_AMOUNT_CHANGED: "claim_amount_changed",
};

export const COMMENT_TYPE_OBJECT = {
  [COMMENT_TYPE.FLAGGED]: {
    icon: "Flag",
    class: "text-danger-600",
    text: "comments.type.flag",
  },
  [COMMENT_TYPE.COMMENTED]: {
    icon: "ModeComment",
    class: "text-neutral-500 font-semibold",
    text: null,
  },
  [COMMENT_TYPE.USER_CREATED]: {
    icon: "ModeComment",
    class: "text-neutral-500 font-semibold",
    text: null,
  },
  [COMMENT_TYPE.REPAYMENT_REQUESTED]: {
    icon: "Replay",
    class: "text-neutral-500",
    text: null,
  },
  [COMMENT_TYPE.REPAYMENT_RECEIVED]: {
    icon: "MoneyIn",
    class: "text-neutral-600",
    text: null,
  },
  [COMMENT_TYPE.REPAYMENT_CANCELLED]: {
    icon: "Cancel",
    class: "text-neutral-500",
    text: "comments.type.declined",
  },
  [COMMENT_TYPE.APPROVED]: {
    icon: "CircleCheck",
    class: "text-success-600",
    text: "comments.type.approved",
  },
  [COMMENT_TYPE.AUTO_FLAGGED]: {
    icon: "Flag",
    class: "text-danger-600",
    text: "comments.type.autoFlag",
  },
  [COMMENT_TYPE.CLAIM_AMOUNT_CHANGED]: {
    icon: "EditUnderline",
    class: "text-neutral-500 font-medium text-base",
    additionalClass: "text-neutral-500 font-bold text-base",
    text: "",
  },
};

export const COMMENT_TYPE_PAYLOAD = {
  EXPENSE: "Expense",
  QRPAY: "QrPayment",
  CARD_REQUEST: "CardRequest",
  PURCHASEBILL: "PurchaseBill",
  REIMBURSEMENT: "Reimbursement",
  PAYROLL: "Payroll",
  TOP_UP_REQUEST: "TopUpRequest",
  REPORT: "Report",
};
