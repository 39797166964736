import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { isFetchingSelectedBudgetSelector } from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";

export default function DeleteBudgetModal({
  showModal,
  setShowModal,
  handleDelete,
}) {
  const onCloseModal = () => {
    setShowModal(false);
  };

  const isLoading = useSelector(isFetchingSelectedBudgetSelector);

  const initialValue = {
    deleteText: {
      value: "",
      validate: {
        required: true,
        regex: /\bCONFIRM\b/,
      },
      errorStatement: {
        regex: "company.rolesPermissions.deleteRole.invalidInput",
      },
    },
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, () => {
      handleDelete();
    });

  return (
    <Modal
      onClose={onCloseModal}
      open={showModal}
      className="flex items-center justify-center"
    >
      <div className="px-6 pt-2 pb-6 w-115 h-fit flex flex-col gap-2 bg-white rounded-2xl">
        <div className="flex flex-col">
          <Text
            translationKey="company.budgets.deleteBudget.areYouSureToDelete.title"
            classes="text-lg font-bold text-neutral-800 py-2"
          />
        </div>
        <div className="flex flex-col">
          <div className="py-2">
            <Text
              translationKey="company.budgets.deleteBudget.important"
              classes="text-xs font-bold text-danger-500"
            />
          </div>
          <div className="py-2 flex justify-start w-11/12">
            <Text
              translationKey="company.budgets.deleteBudget.areYouSureToDelete.description"
              classes="text-base font-semibold text-neutral-800"
            />
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          id="delete-department-budget"
          className="py-2"
        >
          <Input
            name="deleteText"
            label="company.budgets.deleteBudget.deleteHelpText"
            value={values?.deleteText}
            error={errors?.deleteText}
            onChange={handleChange}
            labelExtraClasses="text-xs font-semibold text-neutral-500"
          />
        </form>
        <div className="flex items-center gap-6 py-1">
          <Button
            label="company.budgets.deleteBudget.cancel"
            classes="px-5 py-3 text-base font-semibold text-neutral-500"
            variant="tertiary"
            onClick={onCloseModal}
          />
          <Button
            label="company.budgets.deleteBudget.delete"
            type="danger"
            classes="px-5 py-3 text-base font-semibold "
            btnType="submit"
            showLoader={isLoading}
            form="delete-department-budget"
            disabled={isFormButtonDisabled}
          />
        </div>
      </div>
    </Modal>
  );
}

DeleteBudgetModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  handleDelete: PropTypes.func,
};
