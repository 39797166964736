import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setIndexApiReload } from "@/store/reducers/app";
import {
  fetchAndSelectExpense,
  fetchExpenses,
  flagExpense,
} from "@/store/reducers/expense";
import {
  fetchAndSelectQrPayment,
  flagQrPayment,
} from "@/store/reducers/qr-payments";

import { indexApiReloadSelector } from "@/store/selectors/app";
import { categoriesListSelector } from "@/store/selectors/categories";
import { defaultCurrencySelector } from "@/store/selectors/client";
import {
  isFlagExpenseInProcessSelector,
  selectedExpenseSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isFlagQrPaymentInProcessSelector,
  selectedQrpaymentSelector,
} from "@/store/selectors/qr-payments";
import { userSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Input from "@/components/core/Input";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import ExpenseAndQRPaySliderOverviewCards from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPaySliderOverviewCards";
import ExpenseAndQRPayStatusBadge from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayStatusBadge";
import FlagExpenseAndQRPayFooter from "@/components/common/QrPayAndExpense/common/Sliders/FlagExpenseAndQRPay/FlagExpenseAndQRPayFooter";
import FlagExpenseAndQRPayHeader from "@/components/common/QrPayAndExpense/common/Sliders/FlagExpenseAndQRPay/FlagExpenseAndQRPayHeader";
import TimeStampAndCashback from "@/components/common/QrPayAndExpense/common/Sliders/TimeStampAndCashback";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import { useForm } from "@/utils/useForm";
import {
  AVAILABLE_FILTER_KEYS,
  TRANSACTION_TYPE_FILTER_VALUE,
} from "@/utils/constants/filters";
import { AMOUNT_SLIDER_COLOR } from "@/utils/constants/expenses";
import { amountToCurrency } from "@/utils/common";

import { LINKED_TO_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  DECLINED,
  EXPENSES_PATH_NAMES,
  EXPENSE_FLAG_REPAYMENT_SLIDER,
  EXPENSE_STATUSES,
  FLAG_EXPENSE_CTA_KEYS,
  SETTLED,
  TRANSACTION_TYPE_EXPENSES_OVERVIEW,
  UNSETTLED,
} from "@/constants/expense";

export default function FlagExpenseAndQRPay({
  isAccountingSlider,
  sliderType,
  page,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [transactionId, setTransactionId] = useState(null);
  const dispatch = useDispatch();
  const categories = useSelector(categoriesListSelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const [submitKey, setSubmitKey] = useState("");
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;
  const appliedFilters = useSelector(appliedFilterSelector);
  const reloadIndexApi = useSelector(indexApiReloadSelector);
  const currentUser = useSelector(userSelector);

  const expenseSelectors = {
    transaction: useSelector(selectedExpenseSelector),
    isFlagInProcess: useSelector(isFlagExpenseInProcessSelector),
  };
  const qrPaymentSelectors = {
    transaction: useSelector(selectedQrpaymentSelector),
    isFlagInProcess: useSelector(isFlagQrPaymentInProcessSelector),
  };
  const currentSelectors = isExpensesPage
    ? expenseSelectors
    : qrPaymentSelectors;
  const expenseValues = {
    id: currentSelectors?.transaction?.id,
    userName: currentSelectors?.transaction?.cardHolder?.displayName,
    role: currentSelectors?.transaction?.cardHolder?.roles[0],
    department: currentSelectors?.transaction?.cardHolder?.departmentName,
    location: currentSelectors?.transaction?.cardHolder?.locationName,
    searchParams: SLIDERS_SEARCH_PARAMS.expenses.flagTransaction,
    transactionStatus: currentSelectors?.transaction?.transactionStatus,
    transactionKey: "expenseId",
  };
  const qrPayValues = {
    id: currentSelectors?.transaction?.id,
    userName: currentSelectors?.transaction?.walletHolder?.displayName,
    department: currentSelectors?.transaction?.walletHolder?.departmentName,
    location: currentSelectors?.transaction?.walletHolder?.locationName,
    role: currentSelectors?.walletHolder?.roles[0].name,
    upiId: currentSelectors?.merchant?.upiId,
    searchParams: SLIDERS_SEARCH_PARAMS.qrPayments.payments.flagTransaction,
    transactionStatus: currentSelectors?.transaction?.transactionStatus,
    transactionKey: "qrPaymentId",
  };
  const currentValues = isExpensesPage ? expenseValues : qrPayValues;
  const initialValue = {
    comment: {
      value: "",
      validate: {
        required: true,
      },
    },

    isRepaymentRequested: {
      value: false,
    },
    repaymentAmount: {
      value: "",
    },
  };
  useEffect(() => {
    if (currentSelectors?.isFlagInProcess) {
      searchParams.delete(currentValues?.searchParams);
      setSearchParams(searchParams);
      removeSearchParams(currentValues?.searchParams);
    }
  }, [currentSelectors?.isFlagInProcess]);
  // repayment_requested comment amount
  const converStateToPayload = (values) => {
    let payloadObject = {};
    if (
      values.comment.length !== 0 &&
      values.repaymentAmount.length !== 0 &&
      values.isRepaymentRequested
    )
      payloadObject = {
        comment: values.comment,
        repayment_requested: values.isRepaymentRequested,
        amount: values.repaymentAmount,
      };
    else if (values.repaymentAmount) {
      payloadObject = { amount: values.repaymentAmount };
    } else payloadObject = { comment: values.comment };
    return payloadObject;
  };
  const onSubmitForm = (value, reset) => {
    const fetchAction = isExpensesPage ? flagExpense : flagQrPayment;
    dispatch(
      fetchAction({
        id: transactionId,
        payload: converStateToPayload(value),
        onSuccess: () => {
          refreshExpenseData();
        },
        reset,
      })
    );
    removeSearchParams(currentValues?.searchParams);
  };
  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, (e, value, reset) =>
      onSubmitForm(value, () => {
        reset();
        removeSearchParams(currentValues?.searchParams);
      })
    );
  const removeSearchParams = (sliderName) => {
    searchParams.delete(sliderName);
    setSearchParams(searchParams);
  };
  const handleCtaClick = (ctaKey) => {
    if (ctaKey === FLAG_EXPENSE_CTA_KEYS.CANCEL_FLAG) {
      removeSearchParams(currentValues?.searchParams);
    } else {
      setSubmitKey(ctaKey);
    }
  };
  const handleFetchExpenseParam = () => {
    switch (window.location.pathname) {
      case EXPENSES_PATH_NAMES.FLAGGED:
        return { status: [EXPENSE_STATUSES.NOT_OKAY] };
      case EXPENSES_PATH_NAMES.NEEDS_REVIEW:
        return { user_approvals: currentUser?.id };
      case EXPENSES_PATH_NAMES.DECLINED:
        return { transaction_type: DECLINED };
      case EXPENSES_PATH_NAMES.OVERVIEW:
        return {
          settlement_status:
            appliedFilters[AVAILABLE_FILTER_KEYS.settlementStatus]?.value ===
            UNSETTLED
              ? UNSETTLED
              : SETTLED,
          transaction_type: [
            TRANSACTION_TYPE_FILTER_VALUE.PURCHASE,
            TRANSACTION_TYPE_FILTER_VALUE.PURCHASE_REVERSAL,
          ],
        };
      default:
        return null;
    }
  };
  const refreshExpenseData = () => {
    dispatch(setIndexApiReload(!reloadIndexApi));
  };

  // use Effect
  useEffect(() => {
    const id = parseInt(searchParams.get(currentValues?.searchParams), 10);

    if (id) setTransactionId(id);
  }, [searchParams]);
  useEffect(() => {
    const fetchAction = isExpensesPage
      ? fetchAndSelectExpense
      : fetchAndSelectQrPayment;
    if (transactionId && !currentSelectors?.transaction)
      dispatch(
        fetchAction({
          [currentValues?.transactionKey]: transactionId,
        })
      );
  }, [transactionId, currentSelectors?.transaction]);

  return currentSelectors?.transaction ? (
    <div className="slider-content-container">
      <div className="px-9 pb-15 slider-content-core ">
        <FlagExpenseAndQRPayHeader
          title={EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.title}
          description={EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.description}
          descriptionTranslationProps={EXPENSE_FLAG_REPAYMENT_SLIDER[
            sliderType
          ]?.descriptionTranslationProps(currentSelectors?.transaction)}
        />
        <div className="flex items-center gap-2 mt-8">
          <p
            className={`text-3xl font-bold ${
              AMOUNT_SLIDER_COLOR[
                currentSelectors?.transaction?.settlementStatus
              ]
            }`}
          >
            {amountToCurrency(
              currentSelectors?.transaction?.amount,
              currentSelectors?.transaction?.currency
            )}
          </p>
          <ExpenseAndQRPayStatusBadge
            transactionStatus={currentValues?.transactionStatus}
          />
        </div>
        <TimeStampAndCashback
          updatedAt={currentSelectors?.transaction?.expenseDate}
          cashback={currentSelectors?.transaction?.cashback}
          cashbackCurrency={currentSelectors?.transaction?.cashbackCurrency}
        />
        {currentSelectors?.transaction?.transactionCurrencyCode !==
        currentSelectors?.transaction?.currency ? (
          <div className="mt-5 mb-9">
            <Alert
              variant="neutral"
              icon="swapHorizontal"
              title="expenses.slider.exchangeAlertSlider"
              titleTransalationProp={{
                amount: amountToCurrency(
                  currentSelectors?.transaction?.transactionAmount,
                  currentSelectors?.transaction?.transactionCurrencyCode
                ),

                toAmount: amountToCurrency(
                  1,
                  currentSelectors?.transaction?.currency
                ),
                fromAmount: amountToCurrency(
                  currentSelectors?.transaction?.exchangeRate,
                  currentSelectors?.transaction?.transactionCurrencyCode,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                  }
                ),
              }}
            />
          </div>
        ) : null}

        <ExpenseAndQRPaySliderOverviewCards
          isAccountingSlider={isAccountingSlider}
          userName={currentValues?.userName}
          department={currentValues?.department}
          location={currentValues?.location}
          role={currentValues?.role}
          upiId={currentValues?.upiId}
          cardName={currentSelectors?.transaction?.cardName}
          cardNumber={currentSelectors?.transaction?.cardNumber?.slice(-4)}
          cardType={currentSelectors?.transaction?.cardType}
          merchantName={currentSelectors?.transaction?.merchant?.name}
          merchantFullName={currentSelectors?.transaction?.merchant?.name}
          category={currentSelectors?.transaction?.categoryName}
          page={page}
          LinkedToName={currentSelectors?.transaction?.linkedTo}
          LinkedToValue={
            currentSelectors?.transaction?.linkedTo === LINKED_TO_TYPE.PROJECT
              ? currentSelectors?.transaction?.project?.name
              : currentValues?.department
          }
        />
        <form id="flag-expense-form" className="mt-8" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-6 pt-5">
            <Text
              classes="text-xl font-semibold text-neutral-800 "
              translationKey={
                EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.secondaryHeading
              }
            />
            <Input
              name="comment"
              value={values?.comment}
              onChange={handleChange}
              error={errors?.comment}
              label="expenses.flagTransaction.enterComment"
              placeholder="expenses.flagTransaction.enterComment"
            />
          </div>
          <div className="flex flex-col gap-6 mt-8">
            <Text
              classes="text-xl font-semibold text-neutral-800 "
              translationKey="expenses.flagTransaction.requestRepayment"
            />

            <div className="flex justify-between">
              <Text
                classes="text-xs text-neutral-500 font-medium"
                translationKey="expenses.flagTransaction.repaymentDescription"
              />
              <Switch
                name="isRepaymentRequested"
                value={values?.isRepaymentRequested}
                handleChange={handleChange}
                insideForm
              />
            </div>
            {values?.isRepaymentRequested && (
              <Input
                name="repaymentAmount"
                value={values?.repaymentAmount}
                error={errors?.repaymentAmount}
                type="number"
                rightText={defaultCurrency}
                onChange={handleChange}
                label="expenses.flagTransaction.repaymentAmount"
                placeholder="expenses.flagTransaction.repaymentAmount"
              />
            )}
          </div>
        </form>
      </div>
      <div className="slider-footer">
        <FlagExpenseAndQRPayFooter
          onClick={handleCtaClick}
          type={sliderType}
          form="flag-expense-form"
          toggleSwitchTrue={
            EXPENSE_FLAG_REPAYMENT_SLIDER[sliderType]?.isSwitchThere
          }
          isSubmitButtonDisabled={isFormButtonDisabled}
          requestRepaymentTrue={values?.isRepaymentRequested}
        />
      </div>
    </div>
  ) : (
    "loading"
  );
}
FlagExpenseAndQRPay.propTypes = {
  isAccountingSlider: PropTypes.bool,
  sliderType: PropTypes.string,
  page: PropTypes.string,
};
