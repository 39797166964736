import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import {
  CLIENT_ANALYTICS_PAGINATION_PER_REQUEST_LIMIT,
  PAGINATION_PER_REQUEST_LIMIT,
} from "@/constants/pagination";
import API from "@/api";

import { setIsFetchingSpendChartData } from "./analytics";

const transactionsInitialState = {
  transactions: {
    list: [],
    page: 1,
    limit: 5,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  topCounts: {
    list: [],
    page: 1,
    limit: 5,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  topSpenders: {
    list: [],
    page: 1,
    limit: 5,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  spends: {},
};

const transactionsSlice = createSlice({
  name: "clientAnalytics",
  initialState: transactionsInitialState,
  reducers: {
    setTransactions(state, action) {
      state.transactions.list = action.payload;
    },
    addTransactions(state, action) {
      state.transactions.list = [...state.transactions.list, ...action.payload];
    },
    setTransactionsLimit(state, action) {
      state.transactions.limit = action.payload;
    },
    setTransactionsTotal(state, action) {
      state.transactions.total = action.payload;
    },
    setTransactionsPage(state, action) {
      state.transactions.page = action.payload;
    },
    setTransactionHasMore(state) {
      state.transactions.hasMore =
        state.transactions.list.length < state.transactions.total;
    },
    //
    setTopCounts(state, action) {
      state.topCounts.list = action.payload;
    },
    addTopCounts(state, action) {
      state.topCounts.list = [...state.topCounts.list, ...action.payload];
    },
    setTopCountsLimit(state, action) {
      state.topCounts.limit = action.payload;
    },
    setTopCountsTotal(state, action) {
      state.topCounts.total = action.payload;
    },
    setTopCountsPage(state, action) {
      state.topCounts.page = action.payload;
    },
    setTopCountsHasMore(state) {
      state.topCounts.hasMore =
        state.topCounts.list.length < state.topCounts.total;
    },
    setIsFetchingTopCounts(state, action) {
      state.topCounts.isFetching = action.payload;
    },
    setTopSpenders(state, action) {
      state.topSpenders.list = action.payload;
    },
    addTopSpenders(state, action) {
      state.topSpenders.list = [...state.topSpenders.list, ...action.payload];
    },
    setTopSpendersLimit(state, action) {
      state.topSpenders.limit = action.payload;
    },
    setTopSpendersTotal(state, action) {
      state.topSpenders.total = action.payload;
    },
    setTopSpendersPage(state, action) {
      state.topSpenders.page = action.payload;
    },
    setIsFetchingTopSpenders(state, action) {
      state.topSpenders.page = action.payload;
    },
    setTopSpendersHasMore(state) {
      state.topSpenders.hasMore =
        state.topSpenders.list.length < state.topSpenders.total;
    },
    resetPaginationTopSpenders(state) {
      state.topSpenders.list = [];
      state.topSpenders.page = 1;
      state.topSpenders.limit = PAGINATION_PER_REQUEST_LIMIT;
      state.topSpenders.total = null;
      state.topSpenders.isFetching = false;
      state.topSpenders.hasMore = true;
    },
    resetPaginationTransactions(state) {
      state.transactions.list = [];
      state.transactions.page = 1;
      state.transactions.limit = PAGINATION_PER_REQUEST_LIMIT;
      state.transactions.total = null;
      state.transactions.isFetching = false;
      state.transactions.hasMore = true;
    },
    setSpends(state, action) {
      state.spends = { ...state.spends, ...action.payload };
    },
    resetSpends(state) {
      state.spends = {};
    },
    resetTransactions(state) {
      state.transactions = {
        list: [],
        page: 1,
        limit: CLIENT_ANALYTICS_PAGINATION_PER_REQUEST_LIMIT,
        total: null,
        isFetching: false,
        hasMore: true,
      };
    },
    setTransitionsInProgress(state, action) {
      state.transactions.isFetching = action.payload;
    },
  },
});

export const getTransactions = createAsyncThunk(
  "client_analytics/transactions",
  async (params, { dispatch }) => {
    dispatch(setTransitionsInProgress(true));
    const [err, response] = await to(API.ClientAnalytics.transactions(params));
    if (response.data) {
      if (params.page === 1) {
        dispatch(setTransactions(response.data.list));
      } else {
        dispatch(addTransactions(response.data.list));
      }
      dispatch(setTransactionsLimit(response.data.limit));
      dispatch(setTransactionsTotal(response.data.total));
      dispatch(setTransactionsPage(response.data.page));
      dispatch(setTransactionHasMore());
    }
    if (err) {
      dispatch(resetTransactions());
    }
    dispatch(setTransitionsInProgress(false));
  }
);

export const getTopSpenders = createAsyncThunk(
  "client_analytics/top_spenders",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingTopSpenders(true));
    const [err, response] = await to(API.ClientAnalytics.topSpenders(params));
    if (response.data) {
      if (params.page === 1) {
        dispatch(setTopSpenders(response.data.list));
      } else {
        dispatch(addTopSpenders(response.data.list));
      }
      dispatch(setTopSpendersLimit(response.data.limit));
      dispatch(setTopSpendersTotal(response.data.total));
      dispatch(setTopSpendersPage(response.data.page));
      dispatch(setTopSpendersHasMore());
      dispatch(setIsFetchingTopSpenders(false));
    }
  }
);

export const getTopCounts = createAsyncThunk(
  "client_analytics/top_counts",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.ClientAnalytics.topCount(params));
    if (response.data) {
      if (params.page === 1) {
        dispatch(setTopCounts(response.data.list));
      } else {
        dispatch(addTopCounts(response.data.list));
      }
      dispatch(setTopCountsLimit(response.data.limit));
      dispatch(setTopCountsTotal(response.data.total));
      dispatch(setTopCountsPage(response.data.page));
      dispatch(setTopCountsHasMore());
    }
  }
);

export const getSpends = createAsyncThunk(
  "client_analytics/spends",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSpendChartData(true));
    dispatch(setSpends(transactionsInitialState.spends));
    const [err, response] = await to(API.ClientAnalytics.spends(params));
    if (response?.data) {
      dispatch(setSpends(response?.data));
    }
    dispatch(setIsFetchingSpendChartData(false));
  }
);

export const {
  setTransactions,
  addTransactions,
  setTransactionsLimit,
  setTransactionsTotal,
  setTransactionsPage,
  setTransactionHasMore,
  //
  setTopCounts,
  addTopCounts,
  setTopCountsLimit,
  setTopCountsTotal,
  setTopCountsPage,
  setTopCountsHasMore,
  //
  setTopSpenders,
  addTopSpenders,
  setTopSpendersLimit,
  setTopSpendersTotal,
  setTopSpendersPage,
  setTopSpendersHasMore,
  setIsFetchingTopSpenders,
  resetPaginationTopSpenders,
  resetPaginationTransactions,
  setSpends,
  resetSpends,
  resetTransactions,
  setTransitionsInProgress,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
