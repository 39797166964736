import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchPeopleShallow } from "@/store/reducers/company";
import {
  deleteAppliedFilter,
  deleteSearchAndFilterAppliedFilter,
  deleteSliderAppliedFilters,
  deleteSliderSearchAndFilterAppliedFilter,
  resetAppliedFilters,
  resetSearchAndFilterCommonStoreStructure,
  resetSliderAppliedFilters,
  resetSliderSearchAndFilterCommonStoreStructure,
} from "@/store/reducers/filters";

import { defaultCurrencySelector } from "@/store/selectors/client";
import { allPeopleSelector } from "@/store/selectors/company";
import {
  appliedFilterSelector,
  searchAndFilterCommonStructureSelector,
  sliderAppliedFilterSelector,
  sliderSearchAndFilterCommonStructureSelector,
} from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import {
  generateTagsForSearchAndFilterComponent,
  skipSearchAndFilterInputsFromAppliedFilters,
} from "@/utils/constants/searchAndFilter";
import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
  FILTER_TRIGGER_CONTEXT,
  UTILIZATION_SEARCH_PARAMS,
} from "@/utils/constants/filters";
import { amountToCurrency } from "@/utils/common";

export default function FilterTag({ context }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tags, setTags] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const FILTER_CONFIG = {
    [FILTER_TRIGGER_CONTEXT.PAGE]: {
      appliedFilters: appliedFilterSelector,
      searchAndFilterCommonStoreStructure:
        searchAndFilterCommonStructureSelector,
      dispatchResetCommonStructure: resetSearchAndFilterCommonStoreStructure,
      dispatchResetFilters: resetAppliedFilters,
      dispatchDeleteAppliedFilter: deleteAppliedFilter,
      dispatchDeleteFilterFromCommonStructure:
        deleteSearchAndFilterAppliedFilter,
    },
    [FILTER_TRIGGER_CONTEXT.SLIDER]: {
      appliedFilters: sliderAppliedFilterSelector,
      searchAndFilterCommonStoreStructure:
        sliderSearchAndFilterCommonStructureSelector,
      dispatchResetCommonStructure:
        resetSliderSearchAndFilterCommonStoreStructure,
      dispatchResetFilters: resetSliderAppliedFilters,
      dispatchDeleteAppliedFilter: deleteSliderAppliedFilters,
      dispatchDeleteFilterFromCommonStructure:
        deleteSliderSearchAndFilterAppliedFilter,
    },
  };

  const currentContextFilters = useSelector(
    FILTER_CONFIG[context]?.appliedFilters
  );

  const showFilterTagSection = Object.values(currentContextFilters)?.length > 0;

  const defaultCurrency = useSelector(defaultCurrencySelector);

  const searchAndFilterCommonStoreStructure =
    useSelector(
      FILTER_CONFIG?.[context]?.searchAndFilterCommonStoreStructure
    ) ?? {};

  const showClearAllIcon =
    Object.keys(tags).length +
    Object.keys(searchAndFilterCommonStoreStructure).length;

  const usersList = useSelector(allPeopleSelector);
  const loggedInUser = useSelector(userSelector);

  useEffect(() => {
    dispatch(fetchPeopleShallow());
  }, []);

  const generateTags = () => {
    const nonSearchAndFilterFilters =
      skipSearchAndFilterInputsFromAppliedFilters(
        currentContextFilters,
        searchAndFilterCommonStoreStructure
      );

    const _tags = {};
    if (nonSearchAndFilterFilters) {
      Object.keys(nonSearchAndFilterFilters).forEach((filter) => {
        if (
          filter === AVAILABLE_FILTER_KEYS.amount ||
          filter === AVAILABLE_FILTER_KEYS.analyticsLimit ||
          filter === AVAILABLE_FILTER_KEYS.cardLimit ||
          filter === AVAILABLE_FILTER_KEYS.spends ||
          filter === AVAILABLE_FILTER_KEYS.unsettledAmount ||
          filter === AVAILABLE_FILTER_KEYS.settledAmount
        ) {
          _tags[filter] = {
            label: `${t(AVAILABLE_FILTERS[`${filter}`].props.label)} : ${
              nonSearchAndFilterFilters?.[`${filter}`]?.minAmount?.value
            } - ${amountToCurrency(
              nonSearchAndFilterFilters?.[`${filter}`]?.maxAmount?.value,
              defaultCurrency
            )}`,
          };
        } else if (filter === "datePeriods" || filter === "dateRange") {
          _tags[filter] = {
            label: ` Date Range : ${nonSearchAndFilterFilters[filter].from} to ${nonSearchAndFilterFilters[filter].to} `,
          };
        } else if (filter === "utilization") {
          _tags.utilization = {
            label: `Utilization : ${
              nonSearchAndFilterFilters?.utilization?.[
                UTILIZATION_SEARCH_PARAMS.minUtilization
              ]?.value
            }% - ${
              nonSearchAndFilterFilters?.utilization?.[
                UTILIZATION_SEARCH_PARAMS.maxUtilization
              ]?.value
            }%`,
          };
        } else if (filter === AVAILABLE_FILTER_KEYS.copilots) {
          const userInfo = usersList?.find(
            (userDetails) =>
              Number(userDetails?.id) ===
              Number(nonSearchAndFilterFilters?.[filter]?.value)
          );

          if (userInfo && usersList?.length > 0) {
            _tags[filter] = {
              label:
                userInfo?.id === loggedInUser?.id
                  ? nonSearchAndFilterFilters?.[filter]?.label
                  : "filters.copilots",
              labelTranslationProps: {
                user: userInfo?.id !== loggedInUser?.id ? userInfo?.name : "",
              },
            };
          }
        } else {
          const options = AVAILABLE_FILTERS?.[filter]?.props?.options;
          const findSelectedOptions = options?.find((i) =>
            Array.isArray(i?.value)
              ? i?.value?.includes(nonSearchAndFilterFilters[filter]?.value)
              : i.value === nonSearchAndFilterFilters[filter]?.value
          );

          _tags[filter] =
            findSelectedOptions || nonSearchAndFilterFilters[filter];
        }
      });
    }

    return _tags;
  };

  // Clear single filter
  const removeSearchParam = (filterKey) => {
    const dispatchDeleteFunc =
      context === FILTER_TRIGGER_CONTEXT.PAGE
        ? deleteAppliedFilter
        : deleteSliderAppliedFilters;

    dispatch(dispatchDeleteFunc(filterKey));
  };

  // Clear all
  const clearFilters = () => {
    const dispatchResetCommonStructure =
      FILTER_CONFIG?.[context]?.dispatchResetCommonStructure;
    const dispatchResetFilters = FILTER_CONFIG?.[context]?.dispatchResetFilters;

    dispatch(dispatchResetCommonStructure());
    dispatch(dispatchResetFilters());
  };

  function clearSelectedSearchAndFilterInputCategory(titleKey) {
    const deleteAppliedFilterForParam =
      searchAndFilterCommonStoreStructure?.[titleKey]?.param;

    const isTriggeredFilter =
      searchAndFilterCommonStoreStructure?.[titleKey]?.triggered;

    const triggeredFilterKey =
      searchAndFilterCommonStoreStructure?.[titleKey]?.triggeredKey;

    if (isTriggeredFilter) {
      searchParams.delete(triggeredFilterKey);
      setSearchParams(searchParams);
    }

    const dispatchDeleteAppliedFilter =
      FILTER_CONFIG?.[context]?.dispatchDeleteAppliedFilter;
    const dispatchDeleteFilterFromCommonStructure =
      FILTER_CONFIG?.[context]?.dispatchDeleteFilterFromCommonStructure;

    dispatch(dispatchDeleteAppliedFilter(deleteAppliedFilterForParam));
    dispatch(dispatchDeleteFilterFromCommonStructure(titleKey));
  }

  useEffect(() => {
    setTags(generateTags());
  }, [currentContextFilters, defaultCurrency, usersList]);

  return showFilterTagSection ? (
    <div className="flex flex-row flex-wrap items-center gap-6 mb-4">
      {generateTagsForSearchAndFilterComponent(
        searchAndFilterCommonStoreStructure,
        clearSelectedSearchAndFilterInputCategory
      )}

      {Object.keys(tags).length
        ? Object.keys(tags).map((filterKey) => {
            return (
              <div
                className="flex items-center gap-2 p-2 rounded bg-neutral-50 h-9 text-neutral-500"
                key={filterKey}
              >
                {AVAILABLE_FILTERS[filterKey]?.props?.filterTagStartWith ? (
                  <>
                    <Text
                      translationKey={
                        AVAILABLE_FILTERS[filterKey]?.props?.filterTagStartWith
                      }
                    />
                    <span>:</span>
                  </>
                ) : null}
                <Text
                  translationKey={tags[filterKey]?.label}
                  translationProps={tags?.[filterKey]?.labelTranslationProps}
                  classes="text-sm"
                />

                <button onClick={() => removeSearchParam(filterKey)}>
                  <Icon name="Close" className="w-3 h-3 mt-0.5" />
                </button>
              </div>
            );
          })
        : null}

      {showClearAllIcon ? (
        <div
          className="flex items-center gap-2 rounded p-2 h-[39px] text-neutral-500 border border-neutral-200 bg-white cursor-pointer"
          onClick={() => clearFilters()}
        >
          <Text classes="text-sm" translationKey="filter.clearAll" />
          <button>
            <Icon name="Close" className="w-3 h-3 mt-0.5" />
          </button>
        </div>
      ) : null}
    </div>
  ) : null;
}

FilterTag.propTypes = {
  context: PropTypes.string,
};
