import { useState } from "react";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { PAYROLL_PAYMENTS_TABS } from "@/utils/constants/payments";

import "./styles.scss";

export default function PayrollPayments() {
  const [selectedTab, setSelectedTab] = useState(PAYROLL_PAYMENTS_TABS[0]);
  return (
    <>
      {/* page  */}
      <div className="flex flex-col pl-11 pr-11.5">
        <span className="breadcrumb-text">
          <Text translationKey="payroll.title" />
          &nbsp;/&nbsp;
          <Text translationKey="payroll.salaryPayment.title" />
        </span>
        <div className="flex justify-between">
          <Text
            classes="listing-page-title"
            translationKey="payroll.salaryPayment.payments.title"
          />
        </div>
        <div className="mt-6">
          <Tabs
            items={PAYROLL_PAYMENTS_TABS}
            selectedTab={selectedTab?.key}
            setCurrentTab={(tab) => setSelectedTab(tab)}
          />
        </div>
      </div>
    </>
  );
}
