import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setCurrentPageNumber } from "@/store/reducers/fileViewer";
import {
  bulkUploadReimbursement,
  setBulkUploadCount,
  setBulkUploadRecordIds,
} from "@/store/reducers/reimbursement";

import {
  bulkUploadCountSelector,
  bulkUploadRecordIdsSelector,
  isFetchingBulkUploadSelector,
} from "@/store/selectors/reimbursement";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import FileUpload from "@/components/core/FileUpload";
import FileViewer from "@/components/core/FileViewer";
import Icon from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";

import { DEFAULT_ACCEPT_WITH_PDF } from "@/constants/fileViewer";

import BulkUploadCustomFilePreviewer from "./BulkUploadCustomFilePreviewer";

/**
 * BulkUploadButton component for handling bulk file uploads.
 *this component will handle everything related viewing and uploading of files
 *
 *
 * @component
 * @example
 * // Usage example in a parent component
 * import BulkUploadButton from './BulkUploadButton';
 *
 * const MyParentComponent = () => {
 *   const handleReceiptUpload = () => {
 *     // Handle receipt upload logic
 *   };
 *
 *   const handleModalClose = () => {
 *     // Handle modal close logic
 *   };
 *
 *   return (
 *     <BulkUploadButton
 *       showModal={handleReceiptUpload}
 *       isModalVisible={boolean indicating modal visibility }
 *       handleModalClose={handleModalClose}
 *     />
 *   );
 * };
 *
 * @param {Object} props - The properties of the component.
 * @param {function} props.showModal - Function to handle receipt upload.
 * @param {boolean} [props.isModalVisible] - Boolean indicating modal visibility.
 * @param {function} [props.handleModalClose] - Function to handle modal close.
 * @returns {React.Component} Returns the BulkUploadButton component.
 */
function BulkUploadButton({ showModal, isModalVisible, handleModalClose }) {
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingBulkUploadSelector);
  const [file, setFile] = useState([]);
  const [isFilePreviewerVisible, setIsFilePreviewerVisible] = useState(false);
  const bulkUploadRecordIdsData = useSelector(bulkUploadRecordIdsSelector);
  const bulkUploadCountData = useSelector(bulkUploadCountSelector);

  const handleReset = () => {
    setFile([]);
    handleModalClose();
    setIsFilePreviewerVisible(false);
  };

  const handleUpload = () => {
    dispatch(
      bulkUploadReimbursement({
        "receipts[]": file,
        onSuccess: (resp) => {
          dispatch(
            setBulkUploadRecordIds([
              ...bulkUploadRecordIdsData,
              resp?.bulkRecordId,
            ])
          );

          dispatch(
            setBulkUploadCount(bulkUploadCountData + (resp?.count ?? 0))
          );

          handleReset();
        },
      })
    );
  };

  const BULK_UPLOAD_KEYS = {
    EMPTY: "empty",
    HAVE_VALUE: "haveValue",
  };

  const BULK_UPLOAD_BUTTON_CONFIG = {
    [BULK_UPLOAD_KEYS.EMPTY]: {
      middleClasses: "w-full h-full px-3",
      outerClasses: "w-full h-full flex-1 mb-13 pb-9",
      innerClasses: "border-dashed !w-full !h-full",
    },
    [BULK_UPLOAD_KEYS.HAVE_VALUE]: {
      outerClasses: "overflow-auto",
      innerClasses: "border-dashed",
      previewClasses:
        "flex flex-row gap-8 pb-13  h-[190px] pb-12 pt-5 overview-auto w-full flex-wrap  flex-wrap",
      previewHeader: true,
    },
  };

  const Config =
    file?.length > 0
      ? BULK_UPLOAD_BUTTON_CONFIG[BULK_UPLOAD_KEYS.HAVE_VALUE]
      : BULK_UPLOAD_BUTTON_CONFIG[BULK_UPLOAD_KEYS.EMPTY];

  return (
    <>
      <Button
        classes="h-11 font-medium w-16"
        variant="secondary"
        onClick={showModal}
        label="myVolopay.reimbursements.drafts.ctas.uploadReceipts"
      />
      {/* MODAL FOR UPLOADING */}
      <Modal
        innerContainerClasses="!p-0"
        onClose={() => {
          handleReset();
        }}
        open={isModalVisible}
      >
        {isFilePreviewerVisible ? (
          <div className="w-[900px]  h-[572px] flex flex-col relative">
            <FileViewer
              files={file}
              isModalView={false}
              handleModalBackClick={() => setIsFilePreviewerVisible(false)}
            />
          </div>
        ) : (
          <div className="w-[900px]  h-[572px] flex flex-col relative ">
            {/* Header */}
            <div className="relative flex flex-row w-full p-8">
              <Text
                classes="text-xl font-bold"
                translationKey="myVolopay.reimbursements.drafts.ctas.bulkUpload"
              />
              <div
                onClick={handleModalClose}
                className="absolute -translate-y-1/2 rounded-lg cursor-pointer right-10 hover:bg-neutral-50 top-1/2"
              >
                <Icon className="w-3.5   h-3.5 text-neutral-500" name="Close" />
              </div>
            </div>
            {/* Body */}
            <div className="w-full h-full px-5 pb-12 mb-7">
              <FileUpload
                accept={DEFAULT_ACCEPT_WITH_PDF}
                files={file}
                handleFileChange={setFile}
                acceptText="PDF, PNG, JPG, JPEG upto 10MB"
                middleClasses={Config?.middleClasses}
                outerClasses={Config?.outerClasses}
                primaryAction={{
                  handler: (index) => {
                    setFile((prev) => prev.filter((_, idx) => idx !== index));
                  },
                  label: "Delete",
                  icon: "Delete",
                  className: "text-danger-600",
                  bgClassName: "bg-danger-50",
                }}
                previewClasses={Config?.previewClasses}
                innerClasses={Config?.innerClasses}
                maxFiles={10}
                previewHeader={
                  Config?.previewHeader ? (
                    <div className="flex gap-5 mt-6 ">
                      <Text
                        translationKey="myVolopay.reimbursements.drafts.ctas.uploadedReceipts"
                        classes="text-neutral-800 font-bold mb-3"
                      />
                      <Chip
                        classes="text-white h-fit rounded-lg bg-primary-500"
                        label={file.length}
                      />
                    </div>
                  ) : null
                }
                hidePreviewUploadIcon
                previewCustomComponent={(props) => (
                  <BulkUploadCustomFilePreviewer
                    {...props}
                    onFilePreviewer={() => {
                      setIsFilePreviewerVisible(true);
                      dispatch(setCurrentPageNumber(props.index));
                    }}
                    isFetchingIndex={isFetching ? props?.index : null}
                  />
                )}
              />
            </div>

            {/* footer */}
            <div className="absolute bottom-0 flex items-center justify-end gap-3 px-3 py-6 overflow-hidden slider-footer">
              <Button
                classes="w-15.5 h-11.5 text-neutral-500"
                variant="tertiary"
                label="Cancel"
                onClick={handleReset}
              />
              <Button
                classes="w-15.5 h-11.5"
                label="Proceed"
                disabled={!file.length}
                onClick={handleUpload}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default BulkUploadButton;

/**
 * PropTypes for BulkUploadButton component.
 *
 * @type {Object}
 * @property {function} showModal - Function to handle receipt upload.
 * @property {boolean} [isModalVisible] - Boolean indicating modal visibility.
 * @property {function} [handleModalClose] - Function to handle modal close.
 */
BulkUploadButton.propTypes = {
  showModal: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool,
  handleModalClose: PropTypes.func,
};
