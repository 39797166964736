import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function SliderRow({
  title,
  description,
  icon,
  iconRight,
  iconClickHandler = null,
  rightIconClickHandler = () => {},
  showLeftBorder = false,
}) {
  return (
    <div
      className={`card-wrapper-lg p-4 flex items-center gap-6 ${
        showLeftBorder ? "border-l-8 border-l-primary-500" : ""
      }`}
    >
      <span
        aria-label="icon"
        className={`h-10 w-10 bg-primary-50 grid place-items-center rounded-full ${
          iconClickHandler ? "hover: cursor-pointer" : ""
        }`}
        onClick={iconClickHandler ? iconClickHandler : () => {}}
      >
        <Icon name={icon} className="text-primary-500 w-4.5 h-4.5" />
      </span>
      <div className="grow" aria-label="texts">
        <Text
          translationKey={title}
          classes="text-neutral-800 font-bold block"
        />
        <Text
          translationKey={description}
          classes="text-sm text-neutral-500 font-semibold max-w-[280px] block"
        />
      </div>
      {rightIconClickHandler ? (
        <Icon
          name={iconRight}
          className={`text-neutral-500 w-6 h-6 p-1 ${
            rightIconClickHandler ? "hover: cursor-pointer" : ""
          }`}
          handleClick={rightIconClickHandler}
        />
      ) : null}
    </div>
  );
}

SliderRow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  iconRight: PropTypes.string,
  iconClickHandler: PropTypes.func,
  rightIconClickHandler: PropTypes.func,
  showLeftBorder: PropTypes.bool,
};
