import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";
import { REIMBURSEMENT_PAGE_TYPE } from "@/utils/constants/reimbursement";

function TotalReportCell({ val }) {
  const data =
    val?.type === REIMBURSEMENT_PAGE_TYPE.payments
      ? val.unsettledReports
      : val?.settledReports;
  return (
    <div id={`total-claim-cell-${val?.id}`} className="inline-block w-full">
      <Text translationKey={data} />
    </div>
  );
}

export default TotalReportCell;

TotalReportCell.propTypes = {
  val: PropTypes.object,
};
