import PropTypes from "prop-types";
import { useRef } from "react";
import Popup from "reactjs-popup";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";

import { ALL_ALLOWED_CTAS, EXPENSE_FOOTER_CTA } from "@/constants/expense";

export default function ActionCell({
  onSubmit,
  val,
  expenseFooterCtas,
  disabled,
  showLoader,
}) {
  const popupRef = useRef();

  const handlePopupToggle = () => {
    popupRef.current.toggle();
  };

  const footerBtn = expenseFooterCtas
    ?.filter((btn) => btn !== ALL_ALLOWED_CTAS.BULK_APPROVE)
    ?.map((item) => EXPENSE_FOOTER_CTA[item]);

  const [mainFooterBtnConfig] =
    footerBtn
      ?.map((cta) => {
        if (cta?.tableConfigType === "primary") {
          return cta;
        }
      })
      ?.filter((data) => !!data) || [];

  const MoreOptionConfig = footerBtn
    ?.map((cta) => {
      if (cta?.tableConfigType === "moreButtons") {
        return cta;
      }
    })
    ?.filter((data) => !!data);

  return (
    <div className="flex items-center justify-evenly">
      {mainFooterBtnConfig ? (
        <Button
          label={mainFooterBtnConfig?.label}
          type={mainFooterBtnConfig?.type}
          size="sm"
          variant={mainFooterBtnConfig?.tableVariant}
          preIcon={mainFooterBtnConfig?.preIcon}
          onClick={() => onSubmit(mainFooterBtnConfig?.key, val)}
          disabled={disabled}
          showLoader={showLoader}
        />
      ) : (
        <div className="m-auto text-sm text-center w-15">-</div>
      )}

      {MoreOptionConfig?.length ? (
        <div className="flex flex-col">
          <Popup
            keepTooltipInside="#root"
            ref={popupRef}
            repositionOnResize
            trigger={(isPopupOpen) => (
              <button
                type="button"
                disabled={disabled}
                onClick={handlePopupToggle}
                className="py-2 cursor-pointer"
              >
                <Icon name="MoreVerticle" />
              </button>
            )}
            closeOnDocumentClick
            position="bottom right"
            className="border-0 filter-popup"
          >
            <div className="flex flex-col min-w-[251px]">
              {MoreOptionConfig?.map(({ key, label, description }) => (
                <Button
                  key={key}
                  id={`expense-qrpay-footer-more-options-button-${key}`}
                  variant="tertiary"
                  classes="border-0 w-full p-4 pl-3 pr-6 font-medium text-neutral-500 hover:text-neutral-900"
                  textAlignment="text-left"
                  label={label}
                  disabled={disabled}
                  onClick={() => {
                    popupRef?.current?.close();
                    onSubmit(key, val);
                  }}
                />
              ))}
            </div>
          </Popup>
        </div>
      ) : null}
    </div>
  );
}

ActionCell.propTypes = {
  onSubmit: PropTypes.func,
  val: PropTypes.object,
  expenseFooterCtas: PropTypes.array,
  disabled: PropTypes.bool,
  showLoader: PropTypes.bool,
};
