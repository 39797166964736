import PropTypes from "prop-types";
import React from "react";

import PaymentsLoaderHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentsTableLoader/LoaderHelper";

export default function PaymentsTableLoader({
  hideLastCell = false,
  count = 10,
}) {
  return [...Array(count)].map((val, i) => (
    <PaymentsLoaderHelper
      key={`payments-table-loader-${i}`}
      hideLastCell={hideLastCell}
    />
  ));
}

PaymentsTableLoader.propTypes = {
  hideLastCell: PropTypes.bool,
  count: PropTypes.number,
};
