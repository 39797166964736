import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setPayrollToggleSwitchState } from "@/store/reducers/payments";

import {
  enablePayrollSelector,
  payrollToggleSwitchSelector,
} from "@/store/selectors/payments";
import { userPermissionSelector } from "@/store/selectors/user";

import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import DisabledPayrollState from "@/components/common/BillPayAndPayroll/Settings/General/DisabledPayrollState";
import SettingText from "@/components/common/BillPayAndPayroll/Settings/common/SettingsList/SettingRow/SettingText";
import { PAYROLL_SETTING_ENTRIES } from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EDIT, PERMISSIONS, READ_ONLY } from "@/constants/user";

/**
 * Functional component representing the Toggle Payroll settings.
 * It allows users to enable or disable payroll settings.
 * @param {function} props.setEnablePayrollSwitch - Function to set the state of the payroll switch.
 * @param {boolean} props.enablePayrollSwitch - Current state of the payroll switch.
 */

const TogglePayroll = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isPayrollEnabled = useSelector(enablePayrollSelector);

  const payrollToggleSwitch = useSelector(payrollToggleSwitchSelector);

  const userPermission = useSelector(userPermissionSelector);

  const permissionListToCheck = [
    PERMISSIONS.GLOBAL_SETTINGS_MANAGE,
    PERMISSIONS.PAYROLL_ADMINISTRATE,
    PERMISSIONS.PAYROLL_MANAGE,
  ];

  const permissionCheckResults = permissionListToCheck?.reduce(
    (result, permission) => {
      const foundPermission = userPermission?.some(
        (userPerm) =>
          userPerm?.name?.toLowerCase() === permission?.toLowerCase()
      );
      result[permission] = foundPermission;

      return result;
    },
    {}
  );

  const {
    [PERMISSIONS.GLOBAL_SETTINGS_MANAGE]: readOnlyView,
    [PERMISSIONS.PAYROLL_ADMINISTRATE]: payrollAdministrativeView,
    [PERMISSIONS.PAYROLL_MANAGE]: payrollManageView,
  } = permissionCheckResults;

  const isViewChange =
    payrollAdministrativeView || payrollManageView || readOnlyView;

  const adminAndReadOrManagerView =
    payrollAdministrativeView && (readOnlyView || payrollManageView);

  const adminOrManageAndReadView =
    (payrollAdministrativeView || payrollManageView) && readOnlyView;

  // Content for when payroll settings are enabled
  const ENABLED_PAYROLL_CONTENT = [
    {
      id: PAYROLL_SETTING_ENTRIES.PAYROLL_USERS,
      title: adminAndReadOrManagerView
        ? "payroll.payrollSettings.rows.payrollUsers.rightSide.editPayrollAdmins.title"
        : "payroll.payrollSettings.rows.payrollUsers.rightSide.editPayrollAdmins.readOnlyTitle",
      description:
        "payroll.payrollSettings.rows.payrollUsers.rightSide.editPayrollAdmins.description",

      showSwitch: false,
      isSwitchOn: false,

      permissionType: adminAndReadOrManagerView ? EDIT : READ_ONLY,
      sliderType:
        SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general.payrollAdmins,
      sliderId: null,
    },
    {
      id: PAYROLL_SETTING_ENTRIES.ADD_PAYROLL_MANAGERS,
      title: adminOrManageAndReadView
        ? "payroll.payrollSettings.rows.payrollUsers.rightSide.addPayrollManagers.title"
        : "payroll.payrollSettings.rows.payrollUsers.rightSide.addPayrollManagers.readOnlyTitle",
      description:
        "payroll.payrollSettings.rows.payrollUsers.rightSide.addPayrollManagers.description",

      showSwitch: false,
      isSwitchOn: false,

      permissionType: adminOrManageAndReadView ? EDIT : READ_ONLY,
      sliderType:
        SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general
          .addPayrollManagers,
      sliderId: null,
    },
    {
      id: PAYROLL_SETTING_ENTRIES.ADD_PAYROLL_MEMBERS,
      title: adminOrManageAndReadView
        ? "payroll.payrollSettings.rows.payrollUsers.rightSide.addPayrollMembers.title"
        : "payroll.payrollSettings.rows.payrollUsers.rightSide.addPayrollMembers.readOnlyTitle",
      description:
        "payroll.payrollSettings.rows.payrollUsers.rightSide.addPayrollMembers.description",

      showSwitch: false,
      isSwitchOn: false,

      permissionType: adminOrManageAndReadView ? EDIT : READ_ONLY,
      sliderType:
        SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general
          .addPayrollMembers,
      sliderId: null,
    },
  ];

  const handleSwitch = (val) => {
    if (!isPayrollEnabled) {
      dispatch(setPayrollToggleSwitchState(val));

      searchParams.append(
        SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general.togglePayroll,
        true
      );

      setSearchParams(searchParams);
    }
  };

  return (
    <div
      className={`flex flex-col pt-9 pb-10 ${
        isPayrollEnabled ? "border-b-[1px] border-neutral-300 " : ""
      }`}
    >
      <div className="flex justify-between gap-[131px] ">
        <div aria-label="row-left-side-portion" className="w-[404px] shrink-0 ">
          <div className="flex items-center gap-2">
            {isPayrollEnabled ? null : (
              <Switch
                value={payrollToggleSwitch}
                handleChange={(val) => handleSwitch(val)}
              />
            )}

            <Text
              translationKey={
                isPayrollEnabled
                  ? "payroll.payrollSettings.rows.enablePayroll.manangeTitle"
                  : "payroll.payrollSettings.rows.enablePayroll.title"
              }
              classes="text-neutral-800 font-semibold text-lg"
            />
          </div>
          <Text
            translationKey="payroll.payrollSettings.rows.enablePayroll.description"
            classes="text-neutral-500 font-medium text-sm"
          />
        </div>
        {isPayrollEnabled ? (
          <div
            aria-label="row-right-side-portion"
            className="flex flex-col gap-10 grow"
          >
            {ENABLED_PAYROLL_CONTENT?.map((childRow) => (
              <SettingText
                key={`${childRow?.id}-${isViewChange}`}
                title={childRow?.title}
                description={childRow?.description}
                showSwitch={childRow?.showSwitch}
                readOnly={childRow?.readOnly}
                isSwitchOn={childRow?.isSwitchOn}
                isSwitchLoading={childRow?.isSwitchLoading}
                permissionType={childRow?.permissionType}
                sliderId={childRow?.sliderId}
                sliderType={childRow?.sliderType}
              />
            ))}
          </div>
        ) : (
          <DisabledPayrollState />
        )}
      </div>
    </div>
  );
};

export default TogglePayroll;
