import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import { ACTION_CENTRE_TABS } from "@/constants/ActionCentre";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import API from "@/api";

import { setIsFormSubmissionProgress } from "./loadersError";

const actionCentreState = {
  selectedTab: null, // This is the selectedTab of the main action centre page.
  actionCentreTabs: ACTION_CENTRE_TABS,
  actionCentre: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  inProgress: false,
  actionInProgressId: null,
  actionInProgress: {
    approve: false,
    reject: false,
  },
  filterKey: {
    cards: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.cardModuleRequestType,
      AVAILABLE_FILTER_KEYS.cardModuleCardType,
    ],
  },
  approvalStatusHistoryInfo: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: true,
    hasMore: true,
  },
};

const actionCentreSlice = createSlice({
  name: "actionCentre",
  initialState: actionCentreState,
  reducers: {
    setActionCentreSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    setActionCentreTabsCount(state, action) {
      const availableModues = action?.payload.map(
        (request) => request?.requestType
      );
      const tabs = state?.actionCentreTabs?.map((item) => {
        const selectedTab = action?.payload?.find(
          (payload) => payload?.requestType === item.currKey
        );

        if (availableModues.includes(item.currKey)) {
          return { ...item, count: selectedTab?.count ?? 0 };
        }
      });

      state.actionCentreTabs = tabs.filter((val) => val !== undefined);
    },
    setActionCentre(state, action) {
      state.actionCentre.list = action.payload;
    },
    addActionCentre(state, action) {
      state.actionCentre.list = [...state.actionCentre.list, ...action.payload];
    },
    setIsFetchingActionCentre(state, action) {
      state.actionCentre.isFetching = action.payload;
    },
    setActionCentreHasMore(state) {
      state.actionCentre.hasMore =
        state.actionCentre.list.length < state.actionCentre.total;
    },
    setActionCentreLimit(state, action) {
      state.actionCentre.limit = action.payload;
    },
    setActionCentreTotal(state, action) {
      state.actionCentre.total = action.payload;
    },
    setActionCentrePage(state, action) {
      state.actionCentre.page = action.payload;
    },
    resetPaginationActionCentre(state) {
      state.actionCentre = {
        list: [],
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        total: null,
        isFetching: false,
        hasMore: true,
      };
    },

    setTableInfoInprogress(state, action) {
      state.inProgress = action.payload;
    },
    setActionInProgressId(state, action) {
      state.actionInProgressId = action.payload;
    },

    setApproveActionInProgress(state, action) {
      state.actionInProgress.approve = action.payload;
    },
    setRejectActionInProgress(state, action) {
      state.actionInProgress.reject = action.payload;
    },
    setApprovalHistoryList(state, action) {
      state.approvalStatusHistoryInfo.list = action.payload;
    },
    appendApprovalHistoryList(state, action) {
      state.approvalStatusHistoryInfo.list = [
        ...state.approvalStatusHistoryInfo.list,
        ...action.payload,
      ];
    },
    setApprovalHistoryTotal(state, action) {
      state.approvalStatusHistoryInfo.total = action.payload;
    },
    setApprovalHistoryHasMore(state) {
      state.approvalStatusHistoryInfo.hasMore =
        state.approvalStatusHistoryInfo.list?.length <
        state.approvalStatusHistoryInfo.total;
    },
    setIsFethchingApprovalStatusHistoryInfo(state, action) {
      state.approvalStatusHistoryInfo.isFetching = action.payload;
    },
    resetApprovalStatusHistoryInfo(state) {
      state.approvalStatusHistoryInfo = {
        list: [],
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        total: null,
        isFetching: true,
        hasMore: true,
      };
    },
  },
});

export const fetchActionCentreTabs = createAsyncThunk(
  "actionCentre/fetchActionCentreTabs",
  async (params, { dispatch, getState }) => {
    const [error, response] = await to(API.ApprovalRequests.getCount());

    if (response?.data) {
      dispatch(setActionCentreTabsCount(response?.data));
    }
  }
);

export const fetchRequestTableInfo = createAsyncThunk(
  "actionCentre/fetchRequestTableInfo",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingActionCentre(true));

    const [error, response] = await to(API.ApprovalRequests.all(params));

    if (response?.data) {
      if (response?.data?.page === 1)
        dispatch(setActionCentre(response?.data?.list));
      else dispatch(addActionCentre(response?.data?.list));
      dispatch(setActionCentreTotal(response?.data?.total));
      dispatch(setActionCentrePage(response?.data?.page));
      dispatch(setActionCentreLimit(response?.data?.limit));
      dispatch(setActionCentreHasMore(response?.data?.hasMore));
    }
    dispatch(setIsFetchingActionCentre(false));
  }
);

export const approveCardRequest = createAsyncThunk(
  "actionCentre/approveCardRequest",
  async (params, { dispatch }) => {
    const { onSucess, inSlider = false, ...rest } = params;
    dispatch(setActionInProgressId(rest?.target_id));

    if (inSlider) {
      dispatch(setIsFormSubmissionProgress(true));
    }
    const [err, response] = await to(API.ApprovalRequests.approve(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSucess) onSucess();
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setActionInProgressId(null));

    if (inSlider) {
      dispatch(setIsFormSubmissionProgress(false));
    }
  }
);

export const rejectCardRequest = createAsyncThunk(
  "actionCentre/rejectCardRequest",
  async (params, { dispatch }) => {
    const { onSucess, inSlider = false, ...rest } = params;

    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setActionInProgressId(rest?.target_id));
    const [err, response] = await to(API.ApprovalRequests.reject(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSucess) onSucess();
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setActionInProgressId(null));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const skipApprovalCardRequest = createAsyncThunk(
  "actionCentre/skipApprovalCardRequest",
  async (params, { dispatch }) => {
    const { onSucess, inSlider = false, ...rest } = params;

    if (inSlider) {
      dispatch(setIsFormSubmissionProgress(true));
    }
    dispatch(setActionInProgressId(rest?.id));

    const [err, response] = await to(API.Cards.skipApprovalCardRequest(rest));

    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSucess) onSucess();
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setActionInProgressId(null));
    if (inSlider) {
      dispatch(setIsFormSubmissionProgress(false));
    }
  }
);

export const fetchApprovalRequestHistoryInfo = createAsyncThunk(
  "actionCentre/fetchApprovalRequestHistoryInfo",
  async (params, { dispatch }) => {
    dispatch(setIsFethchingApprovalStatusHistoryInfo(true));

    const [err, response] = await to(
      API.ApprovalRequests.getApprovalRequestHistoryInfo(params)
    );
    if (response?.data) {
      // vToast(getSuccessToastMessage(response));
      if (response.data?.page === 1) {
        dispatch(setApprovalHistoryList(response?.data?.list));
      } else {
        dispatch(appendApprovalHistoryList(response?.data?.list));
      }
      dispatch(setApprovalHistoryTotal(response?.data?.total));
      dispatch(setApprovalHistoryHasMore());
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFethchingApprovalStatusHistoryInfo(false));
  }
);

export const {
  setActionCentreSelectedTab,
  setActionCentreTabsCount,
  setActionCentreCardsRequestTableInfo,
  setTableInfoInprogress,
  setActionCentre,
  addActionCentre,
  setIsFetchingActionCentre,
  setActionCentreHasMore,
  setActionCentreLimit,
  setActionCentreTotal,
  setActionCentrePage,
  resetPaginationActionCentre,
  setApproveActionInProgress,
  setActionInProgressId,
  setRejectActionInProgress,
  // setApprovalStatusHistoryInfo,
  setApprovalHistoryList,
  appendApprovalHistoryList,
  setApprovalHistoryTotal,
  setIsFethchingApprovalStatusHistoryInfo,
  setApprovalHistoryHasMore,
  resetApprovalStatusHistoryInfo,
} = actionCentreSlice.actions;

export default actionCentreSlice.reducer;
