import PropTypes from "prop-types";

import Text from "@/components/core/Text";
import { amountToCurrency } from "@/utils/common";

export default function UnSettledAmountCell({ val }) {
  return (
    <div>
      <Text translationKey={amountToCurrency(val?.unsettledAmount)} />
    </div>
  );
}

UnSettledAmountCell.propTypes = {
  val: PropTypes.object,
};
