// react imports
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

// custom hooks
import useOnClickOutside from "@/hooks/useClickOutside";

import DateInput from "@/components/core/DateInput";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
// core components
import Text from "@/components/core/Text";

// utils, constants file imports
import { DATEINPUT_MODES } from "@/constants/date";

export default function DateSelect({
  handleOptionChange,
  options,
  label,
  placeholder,
  value,
  description,
  labelExtraClasses,
  isDisabled,
}) {
  const [showDate, setShowDate] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const resetStates = () => {
    setShowDate(false);
    setShowOptions(false);
  };

  const handleInputFieldClick = () => {
    if (!isDisabled) {
      setShowOptions(true);
    }
  };

  const showDatePicker = () => {
    setShowDate(true);
  };

  const handleDatePickerOnChange = (index, datePickerValue) => {
    handleOptionChange(index, datePickerValue);
  };

  const ref = useRef();

  // Whenever options or date picker are visible and we click outside of the main section then it will toggle off the sections visible.
  useOnClickOutside(ref, resetStates);

  const renderOption = (option, index) => {
    return (
      !showDate && (
        <div
          className="flex flex-row items-center justify-between h-12 p-3 cursor-pointer hover:bg-neutral-50"
          onClick={() => {
            if (index < options.length - 1) {
              handleOptionChange(index);
              resetStates();
            }
          }}
        >
          <div className="flex flex-col ">
            <Text
              translationKey={option.label}
              classes="text-base not-italic font-medium leading-6 text-neutral-900"
            />
            <Text
              translationKey={option.description}
              classes="text-xs not-italic font-medium leading-5 text-neutral-400"
            />
          </div>
          {option.icon && <Icon name={option.icon} />}
        </div>
      )
    );
  };

  return (
    <div>
      <div onClick={!isDisabled ? handleInputFieldClick : null}>
        <Input
          label={label}
          placeholder={placeholder}
          value={value}
          description={description}
          labelExtraClasses={labelExtraClasses}
          type="text"
          disabled={isDisabled}
        />
      </div>

      {showOptions ? (
        <div
          className={`p-0 shadow-md card-wrapper mt-4 ${
            showDate ? "border-none shadow-none hover:bg-none" : ""
          }`}
          ref={ref}
        >
          {options.map((option, index) => {
            const isCustomDateOption = index === options.length - 1;

            return !isCustomDateOption ? (
              renderOption(option, index)
            ) : (
              <div
                id="duration"
                className={`w-full ${
                  showDate ? "hover-bg-none" : "hover:bg-neutral-50"
                }`}
                onClick={showDatePicker}
              >
                <DateInput
                  name="accountingDate"
                  onChange={(e) => {
                    handleDatePickerOnChange(index, e.target.value);
                  }}
                  content={<div>{renderOption(option, index)}</div>}
                  mode={DATEINPUT_MODES.DATE}
                />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

DateSelect.propTypes = {
  handleOptionChange: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  labelExtraClasses: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
};
