import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  fetchReportReimbursements,
  setCreateReportFormData,
  setDeletedClaimIdsForReportInititalState,
  setReimbursementInitialState,
  setSelectedAllClaimsForReport,
  setSelectedClaimForReport,
  updateReportClaim,
} from "@/store/reducers/reimbursement";

import { subdomainSelector } from "@/store/selectors/client";
import {
  createReportFormDataSelector,
  deletedClaimsIdForReportSelector,
  isReimbursementsFetchingSelector,
  reimbursementHasMoreSelector,
  reimbursementTotalSelector,
  reimbursementpageSelector,
  reimbursementsListSelector,
  selectedAllClaimsForReportSelector,
  selectedClaimForReportSelector,
  showEmptyListSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import EmptyData from "@/components/core/EmptyData";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import ReportClaimListComponent from "@/components/ReimbursementReport/ReportClaimListComponent";
import { SORTING_TYPE, SORT_KEYS } from "@/utils/constants/sorting";
import { REIMBURSEMENT_STATUS } from "@/utils/constants/reimbursement";
import { SORT_COLUMN_BY_MISSING_DETAILS } from "@/utils/constants/myVolopay";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function AddExistingClaimSlider({ setOnBack, setOnClose }) {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const itemRef = useLeftHeaderTitle({
    title: "myVolopay.reimbursements.reports.create.title",
  });

  // selector
  const reimbursements = useSelector(reimbursementsListSelector);
  const currentUser = useSelector(userSelector);
  const isFetching = useSelector(isReimbursementsFetchingSelector);
  const hasMore = useSelector(reimbursementHasMoreSelector);
  const totalReimbursements = useSelector(reimbursementTotalSelector);
  const reimbursementPage = useSelector(reimbursementpageSelector);
  const isEmpty = !reimbursements?.length;
  const selectedClaimIds = useSelector(selectedClaimForReportSelector);
  const showEmptyList = useSelector(showEmptyListSelector);
  const selectedAllClaimsForReport = useSelector(
    selectedAllClaimsForReportSelector
  );

  const reportData = useSelector(createReportFormDataSelector);
  const deletedIds = useSelector(deletedClaimsIdForReportSelector);

  const subdomain = useSelector(subdomainSelector);
  // states
  const [selectedData, setSelectedData] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);

  const reportId = searchParam.get(SLIDERS_SEARCH_PARAMS.reports.reviewId);

  const filterOptions = {
    status: REIMBURSEMENT_STATUS.in_draft,
    user: currentUser?.id,
  };

  const onReset = () => {
    setPageNum(1);
    dispatch(setReimbursementInitialState());
  };

  const loadMore = () => {
    if (!showEmptyList)
      dispatch(
        fetchReportReimbursements({
          page: pageNum,
          unlinked_claims: true,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          ...(reportId ? {} : { report_project_id: reportData?.projectId }),
          ...(deletedIds && selectedAllClaimsForReport && !reportId
            ? { by_ids: deletedIds }
            : { except_ids: selectedClaimIds }),

          ...filterOptions,
          [SORT_KEYS.COLUMN]: SORT_COLUMN_BY_MISSING_DETAILS,
          [SORT_KEYS.DIRECTION]: SORTING_TYPE.INC,
        })
      );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    inSlider: true,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const onSuccess = () => {
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reports.addClaims);
    setSearchParam(searchParam);
    dispatch(setReimbursementInitialState());
  };
  const addClaimCtaHandler = () => {
    if (reportId) {
      dispatch(
        updateReportClaim({
          id: reportId,
          payload: {
            ...(selectedAllClaimsForReport
              ? { add_all: true }
              : { add_ids: selectedData }),
          },
          onSuccess,
        })
      );
    } else {
      dispatch(
        setSelectedClaimForReport([...selectedClaimIds, ...selectedData])
      );
      onSuccess();
    }
  };

  const clearSelection = () => {
    setSelectedData([]);
    setHeaderSelectAll(false);
    dispatch(setSelectedAllClaimsForReport(false));
    setBulkSelectAll(false);
  };

  useEffect(() => {
    // When user selects - 'Select all claims'
    if (bulkSelectAll) {
      setSelectedData(reimbursements?.map((data) => data.id));
      setHeaderSelectAll(true);
    }
  }, [bulkSelectAll, reimbursements]);

  useEffect(() => {
    const headerCheckBoxCondition =
      (selectedData.length === pageNum * PAGINATION_PER_REQUEST_LIMIT ||
        selectedData.length === reimbursements?.length) &&
      !isEmpty;
    // When user selects checkbox from header
    setHeaderSelectAll(headerCheckBoxCondition);

    if (!headerCheckBoxCondition) {
      dispatch(setSelectedAllClaimsForReport(false));
    }
  }, [selectedData, pageNum, reimbursements]);

  useEffect(() => {
    if (
      selectedData?.length === totalReimbursements &&
      reimbursements?.length &&
      !isEmpty
    ) {
      dispatch(setSelectedAllClaimsForReport(true));
      dispatch(setDeletedClaimIdsForReportInititalState());
    }
  }, [selectedData, totalReimbursements, reimbursements, isEmpty]);
  useEffect(() => {
    setOnBack(() => {
      clearSelection();
    });
  }, []);

  useEffect(() => {
    setOnClose((searchParamArray) => {
      if (
        !searchParamArray?.includes(SLIDERS_SEARCH_PARAMS.reports.addClaims)
      ) {
        dispatch(setReimbursementInitialState());
        dispatch(setCreateReportFormData(null));
      }
    });
  }, []);

  return (
    <div className="relative slider-content-container">
      <div className="pb-8 slider-content-core">
        <div className="flex flex-col gap-6 ">
          <div ref={itemRef} className="flex flex-col">
            <Text
              classes="font-bold text-3xl"
              translationKey="myVolopay.reimbursements.reports.addExistingClaimSlider.title"
            />
            <Text
              classes="font-medium text-neutral-500 text-sm"
              translationKey="myVolopay.reimbursements.reports.addExistingClaimSlider.desc"
            />
          </div>

          {!isFetching ? (
            showEmptyList || !reimbursements?.length ? (
              <div>
                <EmptyData
                  title="myVolopay.reimbursements.reports.addExistingClaimSlider.emptyTableTitle"
                  imgSrc="emptyScreen.svg"
                  classes="mt-14"
                >
                  <Text
                    translationKey="myVolopay.reimbursements.reports.addExistingClaimSlider.emptyTableDesc"
                    translationProps={{ subdomain }}
                    classes="text-sm text-neutral-800 font-medium text-center"
                  />
                </EmptyData>
              </div>
            ) : (
              <ReportClaimListComponent
                dataVariables={{
                  data: reimbursements,
                  isFetching,
                  hasMore,
                  isEmpty,
                  total: totalReimbursements,
                  page: reimbursementPage,
                }}
                infiniteScrollVariables={{
                  onScroll,
                  setPageNum,
                  headerSelectAll,
                  setHeaderSelectAll,
                }}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                showCheckBox
                isLoadingCount={PAGINATION_PER_REQUEST_LIMIT}
                onClickOpenSlider={(val) => {
                  searchParam.append(
                    SLIDERS_SEARCH_PARAMS.reimbursements.review,
                    val?.id
                  );
                  setSearchParam(searchParam);
                }}
              />
            )
          ) : (
            <div className="flex flex-col gap-2">
              <LoaderSkeleton fullWidth classes="p-3" />
              <LoaderSkeleton fullWidth classes="p-4" />
              <LoaderSkeleton fullWidth classes="p-4" />
              <LoaderSkeleton fullWidth classes="p-4" />
            </div>
          )}

          {headerSelectAll ? (
            <div className="absolute left-0 w-full z-9 border-y bottom-13 border-y-neutral-200 ">
              <BulkActionComponent
                selectedRows={selectedData?.length}
                totalRows={totalReimbursements}
                bulkApproveSelectAll={
                  bulkSelectAll || selectedData?.length === totalReimbursements
                }
                showSelectAllButton={headerSelectAll}
                showExport={false}
                selectedRowsText={
                  selectedData?.length === 1
                    ? "claim on this page is selected"
                    : "claims on this page are selected"
                }
                clearSelection={clearSelection}
                selectAllHandler={(val) => {
                  setBulkSelectAll(val);
                  dispatch(setSelectedAllClaimsForReport(val));
                  dispatch(setDeletedClaimIdsForReportInititalState());
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex justify-end px-5 py-4 slider-footer">
        <Button
          label="myVolopay.reimbursements.reports.addExistingClaimSlider.cta.addClaims"
          variant="primary"
          classes="w-fit px-5 py-3"
          onClick={addClaimCtaHandler}
          disabled={!selectedData?.length}
          counter={
            selectedAllClaimsForReport
              ? totalReimbursements
              : selectedData?.length
          }
        />
      </div>
    </div>
  );
}

export default AddExistingClaimSlider;
