import React from "react";
import { Navigate } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function DowntimeRoute() {
  const showWithTimeDescription =
    import.meta.env.VITE_START_DOWN_TIME && import.meta.env.VITE_END_DOWN_TIME;

  const redirectionToBaseUrl = !(
    import.meta.env.VITE_SHOW_DOWN_TIME === "true" ||
    (import.meta.env.VITE_START_DOWN_TIME && import.meta.env.VITE_END_DOWN_TIME)
  );

  if (redirectionToBaseUrl) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex flex-row items-center justify-center w-full h-screen gap-2">
      <Icon name="Downtime" />

      <div className="flex flex-col gap-2 w-130">
        <Text
          translationKey="downtime.title"
          classes="text-xl font-bold text-neutral-800"
        />

        <Text
          translationKey={
            showWithTimeDescription
              ? "downtime.withTimeDescription"
              : "downtime.withoutTimeDescription"
          }
          translationProps={
            showWithTimeDescription
              ? {
                  downtimeStartTime: import.meta.env.VITE_START_DOWN_TIME,
                  downtimeEndTime: import.meta.env.VITE_END_DOWN_TIME,
                }
              : null
          }
          classes="text-sm font-medium text-neutral-800"
        />
      </div>
    </div>
  );
}
