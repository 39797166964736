import PropTypes from "prop-types";

import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function Badge({
  translationKey = "_BadgeTKey_",
  variant = "primary",
  preIcon = null,
  postIcon = null,
  preFlag = null,
  postFlag = null,
  disabled = false,
  classes = "",
  translationProp = {},
  iconClasses = "w-3 h-3 mr-1",
  textClasses = "text-xs tracking-medium font-bold leading-[18px]",
  id,
}) {
  return (
    <div
      id={id}
      className={`flex items-center justify-center h-[23px] border rounded-full badge--${
        disabled ? "disabled" : variant
      } ${classes}`}
    >
      {preIcon ? <Icon name={preIcon} className={iconClasses} /> : null}
      {preFlag ? (
        <Flag
          code={preFlag}
          size="sm"
          classes="border-[1px] border-neutral-100 mr-1"
        />
      ) : null}
      <Text
        translationKey={translationKey}
        translationProps={translationProp}
        classes={textClasses}
      />
      {postIcon ? <Icon name={postIcon} className="w-3 h-3 ml-1" /> : null}
      {postFlag ? (
        <Flag
          code={postFlag}
          size="sm"
          classes="border-[1px] border-neutral-100 ml-1"
        />
      ) : null}
    </div>
  );
}

Badge.propTypes = {
  translationKey: PropTypes.string,
  variant: PropTypes.oneOf([
    "success",
    "primary",
    "danger",
    "warning",
    "neutral",
    "disabled",
    "corporate-cards",
    "settled",
    "accounts-payable",
    "expense-management",
  ]),
  preIcon: PropTypes.string,
  postIcon: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  preFlag: PropTypes.string,
  postFlag: PropTypes.string,
  translationProp: PropTypes.object,
  iconClasses: PropTypes.string,
  textClasses: PropTypes.string,
  id: PropTypes.any,
};
