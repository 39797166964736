import PropTypes from "prop-types";
import { Fragment } from "react";
import { useSelector } from "react-redux";

import {
  additivePurchaseTaxChildrenSelector,
  additivePurchaseTaxParentSelector,
  subtractivePurchaseTaxChildrenSelector,
  subtractivePurchaseTaxParentSelector,
} from "@/store/selectors/purchase-bills";

import Icon from "@/components/core/Icon";

import ApplyAtLineItemLevel from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/ApplyAtLineItemLevel";
import BoltOnTheLeft from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/BoltOnTheLeft";
import TaxField from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TaxField";
import {
  CREATE_BILL_FLOW_CONTEXT_KEYS,
  CREATE_BILL_TAX_RESPONSE,
  LINE_ITEM_KEY,
  LINE_ITEM_KEYS,
  TAX_AT_LINE_ITEM_LEVEL_ADDITIVE,
  TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE,
  TAX_KEYS,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";

export default function TaxesForLineItemListEntry({
  values,
  errors,
  handleChange,
  setValues,
  beneficiaryCurrency,
  id = 0,
  inPayrollContext,
  createBillFlowContext,
  ocrMode,
  billOCRSuccess,
}) {
  const additiveParentTax = useSelector(additivePurchaseTaxParentSelector);
  const additiveChildrenTax = useSelector(additivePurchaseTaxChildrenSelector);
  const subtractiveParentTax = useSelector(
    subtractivePurchaseTaxParentSelector
  );
  const subtractiveChildrenTax = useSelector(
    subtractivePurchaseTaxChildrenSelector
  );

  const applicableTaxes = (
    inPayrollContext
      ? [
          {
            parentTax: subtractiveParentTax,
            childrenTax: subtractiveChildrenTax,
            show: values[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE],
            isSubtractive: true,
          },
        ]
      : [
          {
            parentTax: additiveParentTax,
            childrenTax: additiveChildrenTax,
            show: values[TAX_AT_LINE_ITEM_LEVEL_ADDITIVE], // is the switch ON?
            isSubtractive: false,
          },
          {
            parentTax: subtractiveParentTax,
            childrenTax: subtractiveChildrenTax,
            show: values[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE],
            isSubtractive: true,
          },
        ]
  ).filter(
    (item) =>
      item.parentTax &&
      item.parentTax[CREATE_BILL_TAX_RESPONSE.LINE_ITEM_LEVEL_APPLICABLE] &&
      item.show
  );

  return applicableTaxes.map(({ parentTax, childrenTax, isSubtractive }) => (
    <Fragment key={parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}>
      <BoltOnTheLeft
        verticalCenter
        classes="flex items-center col-span-2 gap-2"
        showBolt={
          ocrMode &&
          billOCRSuccess &&
          (() => {
            const keyName = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
            const categoryDropdownName = `${LINE_ITEM_KEYS.TAX}.${keyName}-category-dropdown`;
            const dropdownName = `${LINE_ITEM_KEYS.TAX}.${keyName}-dropdown`;
            const valueName = `${LINE_ITEM_KEYS.TAX}.${keyName}-value`;
            // const dropdownLabelName = `${LINE_ITEM_KEYS.TAX}.${keyName}-dropdown-label`; not needed
            const boltPrefix = `${LINE_ITEM_KEY}.${id}`;

            return (
              [categoryDropdownName, dropdownName, valueName]
                .filter(
                  (key) => values[key] || !Number.isNaN(parseFloat(values[key]))
                )
                .filter(
                  (key) =>
                    createBillFlowContext.current[
                      CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_LINE_ITEMS
                    ]?.[id]?.[key]?.toString() ===
                    values[`${boltPrefix}.${key}`]?.toString()
                ).length >= 2
            );
          })()
        }
      >
        <span className="grid w-6 h-6 place-items-center text-neutral-300">
          <Icon name="SubdirectoryArrowRight" />
        </span>
        <div className="w-full">
          <TaxField
            keyName={`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.TAX}.${
              parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]
            }`}
            values={values}
            isSubtractive={isSubtractive}
            errors={errors}
            handleChange={handleChange}
            setValues={setValues}
            amount={values[`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.AMOUNT}`]}
            currency={beneficiaryCurrency}
            taxName={parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}
            taxAtLineItemLevel
            text={parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}
            tooltipText="billPay.bill.invoiceInbox.createBill.sections.lineItems.createBillWalletTooltip"
            placeholder="billPay.bill.invoiceInbox.createBill.sections.lineItems.taxAmountOrCategory"
            labelStyleClasses="font-semibold text-xs text-neutral-500"
            helperText={parentTax?.[CREATE_BILL_TAX_RESPONSE.DESCRIPTION_TEXT]}
          />
          {/* option switches */}
          {childrenTax.map((childTax, index) => {
            // build the form switch Input's key
            const prefix = `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.TAX}`;
            const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];

            const childVisibleKey = `${prefix}.${taxKeyName}-visible`;
            const switchKey = `${prefix}.${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;

            if (!values[childVisibleKey]) return null;

            return (
              <div className="mt-3" key={index}>
                {/* This is actually an optional switch, not lineItemLevel switch. */}
                <ApplyAtLineItemLevel
                  keyName={switchKey}
                  values={values}
                  errors={errors}
                  text="billPay.bill.invoiceInbox.createBill.sections.lineItems.applyXYZTaxOptional"
                  textProps={{ taxName: taxKeyName }}
                  classes="flex gap-2 items-center"
                  textClasses="font-semibold text-xs text-neutral-500"
                  handleChange={handleChange}
                />
              </div>
            );
          })}
        </div>
      </BoltOnTheLeft>
      {/* option enabled taxes */}
      {childrenTax.map((childTax) => {
        // build the form value input's key
        const prefix = `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.TAX}`;
        const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
        const inputKey = `${prefix}.${taxKeyName}`;

        // determine if the optional switch is ON
        const childVisibleKey = `${prefix}.${taxKeyName}-visible`;
        const switchKey = `${prefix}.${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;
        const optionalEnabled = values[switchKey];

        return (
          <Fragment key={childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}>
            {optionalEnabled && values[childVisibleKey] ? (
              <BoltOnTheLeft
                verticalCenter
                classes="flex items-center col-span-2 gap-2"
                showBolt={
                  ocrMode &&
                  billOCRSuccess &&
                  (() => {
                    const keyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
                    const boltPrefix = `${LINE_ITEM_KEY}.${id}`;
                    const categoryDropdownName = `${LINE_ITEM_KEYS.TAX}.${keyName}-category-dropdown`;
                    const dropdownName = `${LINE_ITEM_KEYS.TAX}.${keyName}-dropdown`;
                    const valueName = `${LINE_ITEM_KEYS.TAX}.${keyName}-value`;
                    // const dropdownLabelName = `${LINE_ITEM_KEYS.TAX}.${keyName}-dropdown-label`; // not needed

                    return (
                      [categoryDropdownName, dropdownName, valueName]
                        .filter(
                          (key) =>
                            values[key] ||
                            !Number.isNaN(parseFloat(values[key]))
                        )
                        .filter(
                          (key) =>
                            createBillFlowContext.current[
                              CREATE_BILL_FLOW_CONTEXT_KEYS
                                .OCR_DETECTED_LINE_ITEMS
                            ]?.[id]?.[key]?.toString() ===
                            values[`${boltPrefix}.${key}`]?.toString()
                        ).length >= 2
                    );
                  })()
                }
              >
                <span className="grid w-6 h-6 place-items-center text-neutral-300">
                  <Icon name="SubdirectoryArrowRight" />
                </span>
                <div className="w-full">
                  <TaxField
                    keyName={inputKey}
                    values={values}
                    isSubtractive={isSubtractive}
                    errors={errors}
                    handleChange={handleChange}
                    setValues={setValues}
                    amount={
                      values[`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.AMOUNT}`]
                    }
                    currency={beneficiaryCurrency}
                    taxName={taxKeyName}
                    taxAtLineItemLevel
                    text={childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}
                    tooltipText="billPay.bill.invoiceInbox.createBill.sections.lineItems.createBillWalletTooltip"
                    placeholder="billPay.bill.invoiceInbox.createBill.sections.lineItems.taxAmountOrCategory"
                    labelStyleClasses="font-semibold text-xs text-neutral-500"
                    helperText={
                      childTax?.[CREATE_BILL_TAX_RESPONSE.DESCRIPTION_TEXT]
                    }
                  />
                </div>
              </BoltOnTheLeft>
            ) : null}
          </Fragment>
        );
      })}
    </Fragment>
  ));
}

TaxesForLineItemListEntry.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  beneficiaryCurrency: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inPayrollContext: PropTypes.bool,
  createBillFlowContext: PropTypes.any,
  ocrMode: PropTypes.string,
  billOCRSuccess: PropTypes.bool,
};
