import PropTypes from "prop-types";

import Text from "@/components/core/Text";

export default function TotalClaimCell({ val }) {
  return (
    <div id={`total-claim-cell-${val?.id}`} className="inline-block w-full">
      <Text translationKey={val?.claims ?? val?.totalClaims} />
    </div>
  );
}

TotalClaimCell.propTypes = {
  val: PropTypes.object,
};
