import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchApprovalPolicies,
  fetchSubmissionPolicies,
  setCurrentClaimSubmissionType,
  setCurrentPolicy,
} from "@/store/reducers/policy";

import {
  approvalPolicyEnabledSelector,
  submissionPolicyEnabledSelector,
} from "@/store/selectors/client";
import { currentPoliciesSelector } from "@/store/selectors/policy";

import Alert from "@/components/core/Alert";
import Badge from "@/components/core/Badge";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { DEPARTMENT } from "@/constants/company";
import {
  DEFAULT_APPROVAL_POLICY_CONFIG,
  DEFAULT_SUBMISSION_POLICY_CONFIG,
  POLICY_LAYER,
} from "@/constants/policy";

export default function PolicySlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const policyObject = useSelector(currentPoliciesSelector);
  const submissionPolicyEnabled = useSelector(submissionPolicyEnabledSelector);
  const approvalPolicyEnabled = useSelector(approvalPolicyEnabledSelector);

  const approvalPolicyType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.approvalPolicy
  );
  const submissionPolicy = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.submissionPolicy
  );

  const isCompany = [approvalPolicyType, submissionPolicy].includes(
    POLICY_LAYER.company
  );

  const [sliderHeading, setSliderHeading] = useState({
    title: isCompany
      ? "settings.spendControls.approvalPolicy.title"
      : "settings.spendControls.approvalPolicy.title2",
    description: "settings.spendControls.approvalPolicy.description",
  });

  const projectOrDepartment = [
    POLICY_LAYER.project,
    POLICY_LAYER.department,
  ].includes(approvalPolicyType || submissionPolicy);

  const projectOrDepartmentId =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.department.id) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.project.id);

  const policyDetailConfig = submissionPolicy
    ? DEFAULT_SUBMISSION_POLICY_CONFIG
    : DEFAULT_APPROVAL_POLICY_CONFIG;

  useEffect(() => {
    const params = {};
    if (projectOrDepartment) params.project_id = projectOrDepartmentId;

    if (approvalPolicyType) dispatch(fetchApprovalPolicies(params));
    else if (submissionPolicy) dispatch(fetchSubmissionPolicies(params));

    if (submissionPolicy)
      setSliderHeading({
        title: isCompany
          ? "settings.spendControls.submissionPolicy.title"
          : "settings.spendControls.submissionPolicy.title2",
        description: "settings.spendControls.submissionPolicy.description",
      });
  }, []);

  const handleSliderClick = (type, id) => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.settings[
        submissionPolicy ? "submissionModule" : "policyModule"
      ],
      type
    );
    if (id) searchParams.append(SLIDERS_SEARCH_PARAMS.settings.policyId, id);

    setSearchParams(searchParams);

    if (submissionPolicy) {
      dispatch(setCurrentClaimSubmissionType(null));
      dispatch(setCurrentPolicy(null));
    }
  };

  const checkDisabled = !(
    (approvalPolicyType && approvalPolicyEnabled) ||
    (submissionPolicy && submissionPolicyEnabled)
  );

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-7">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1 mb-8">
            <Text
              classes="text-2xl font-semibold"
              translationKey={sliderHeading.title}
            />

            <Text
              classes="text-sm text-neutral-500"
              translationKey={sliderHeading.description}
            />
          </div>
          {!isCompany && checkDisabled ? (
            <Alert
              wrapperDivClass="justify-start"
              iconClasses="w-6 h-6 text-neutral-500"
              variant="warning"
              title={`settings.spendControls.${
                approvalPolicyType ? "approvalPolicy" : "submissionPolicy"
              }.alertDisableTitle`}
              description={`settings.spendControls.${
                approvalPolicyType ? "approvalPolicy" : "submissionPolicy"
              }.${
                submissionPolicy === DEPARTMENT ||
                approvalPolicyType === DEPARTMENT
                  ? "alertDisableDescriptionDepartment"
                  : "alertDisableDescriptionProject"
              }`}
            />
          ) : (
            policyObject &&
            Object.keys(policyObject).map((item) =>
              policyDetailConfig[item] ? (
                <div
                  key={item}
                  className="relative flex items-center justify-between p-4 overflow-hidden card-wrapper hover"
                  onClick={() =>
                    handleSliderClick(
                      policyDetailConfig[item]?.sliderId,
                      policyObject[item]?.id
                    )
                  }
                >
                  <div className="flex items-center w-11/12 mr-3">
                    <div
                      className={
                        policyObject[item]?.default || isCompany
                          ? ""
                          : "w-2 h-full bg-primary-500 absolute left-0"
                      }
                    />
                    <div className="flex items-center justify-center p-3 mr-3 rounded-full bg-primary-50">
                      <Icon
                        name={policyDetailConfig[item]?.icon}
                        className="w-5 h-5 text-primary-500"
                      />
                    </div>
                    <div>
                      <div className="flex">
                        <Text
                          classes="text-neutral-800 font-semibold mr-4"
                          translationKey={policyDetailConfig[item]?.title}
                        />
                        {projectOrDepartment ? (
                          <Badge
                            variant={
                              policyObject[item]?.default
                                ? "neutral"
                                : "primary"
                            }
                            translationKey={
                              policyObject[item]?.default
                                ? "common.company"
                                : "common.custom"
                            }
                            classes="w-13"
                          />
                        ) : null}
                      </div>
                      <Text
                        classes="text-neutral-500 text-sm font-semibold"
                        translationKey={policyDetailConfig[item]?.description}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end w-1/12">
                    <Icon name="ArrowForward" />
                  </div>
                </div>
              ) : null
            )
          )}
        </div>
      </div>
    </div>
  );
}
