import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchAnalyticsEntitites,
  fetchAnalyticsTransaction,
  resetAnalyticsTransactionsData,
} from "@/store/reducers/analytics";

import {
  analyticsEntitesSelector,
  analyticsReimbursementAccountingTagsSelector,
  analyticsReimbursementCustomTagsSelector,
  analyticsTransactionsSelector,
  isFetchingAnalyticsEntitesSelector,
} from "@/store/selectors/analytics";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import { getSortingParams } from "@/components/Accounting/Transactions/common/util";
import AnalyticsTableHelper from "@/components/Analytics/common/AnalyticsTableHelper";
import HeaderAndFilterHelper from "@/components/Analytics/common/HeaderAndFilterHelper";
import { convertFilters } from "@/utils/filters";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";

import { ACCOUNTING_HEADER_IDS } from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { REIMBURSEMENTS_TYPES } from "@/constants/reimbursement";

function AnalyticsReimbursementHelper({ type }) {
  const dispatch = useDispatch();
  const transactions = useSelector(analyticsTransactionsSelector);

  const entities = useSelector(analyticsEntitesSelector);
  const isFetchingEntities = useSelector(isFetchingAnalyticsEntitesSelector);
  const currentUser = useSelector(userSelector);
  const { list, hasMore, page, isFetching, total } = transactions ?? {};
  const appliedFilters = useSelector(appliedFilterSelector);
  const reimsAccountingTags = useSelector(
    analyticsReimbursementAccountingTagsSelector
  );
  const reimsCustomTags = useSelector(analyticsReimbursementCustomTagsSelector);

  const headers = [
    {
      id: ACCOUNTING_HEADER_IDS.MERCHANT_OR_MILEAGE,
      label: "reimbursement.approvals.listHeading.type",
      classes: "text-left",
      checkbox: true,
      colWidth: 320,
    },
    {
      id: ACCOUNTING_HEADER_IDS.CREATED_BY,
      label: "accounting.transactions.cards.listingFields.owner.header",
      classes: "text-left",
      colWidth: 300,
    },
    {
      id: ACCOUNTING_HEADER_IDS.AMOUNT,
      label: "accounting.transactions.cards.listingFields.amount.header",
      classes: "text-right justify-end",
      sortable: true,
      colWidth: 200,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },
    {
      id: ACCOUNTING_HEADER_IDS.TRANSACTION_STATUS,
      label: "accounting.transactions.cards.listingFields.status.header",
      classes: "text-left justify-center",
      colWidth: 200,
    },
    ...(reimsAccountingTags?.map((tag) => ({
      ...tag,
      actualId: tag?.id,
      id: ACCOUNTING_HEADER_IDS.ACCOUNTING_TAGS,
      label: tag.name,
      tagId: tag.id,
      classes: "text-left",
      colWidth: 300,
    })) ?? []),
    {
      id: ACCOUNTING_HEADER_IDS.ENTITY,
      label: "analytics.entityTableHeader",
      classes: "text-left ",
      colWidth: 200,
    },
    ...(reimsCustomTags?.map((tag) => ({
      ...tag,
      actualId: tag?.id,
      id: ACCOUNTING_HEADER_IDS.CUSTOM_TAGS,
      label: tag.name,
      tagId: tag.id,
      classes: "text-left",
      colWidth: 300,
    })) ?? []),
  ];

  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: headers
      ?.filter((data) => data)
      ?.map((header) => header?.colWidth),
    emptyDataIconSrc: "emptyScreen.svg",
  };

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [sorting, setSorting] = useState({
    category: null,
    type: null,
  });

  const onReset = () => {
    dispatch(resetAnalyticsTransactionsData());
  };

  const loadMore = () => {
    dispatch(
      fetchAnalyticsTransaction({
        limit: PAGINATION_PER_REQUEST_LIMIT,
        type,
        ...getSortingParams(sorting),
        page: pageNum,
        ...convertFilters(appliedFilters),
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...appliedFilters,
      ...getSortingParams(sorting),
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.15,
  });

  useEffect(() => {
    if (!entities?.length && !isFetchingEntities)
      dispatch(fetchAnalyticsEntitites());
  }, [entities, isFetchingEntities]);

  return (
    <div className="mx-11">
      <HeaderAndFilterHelper type={type} />
      <AnalyticsTableHelper
        handleRefChange={handleRefChange}
        headers={headers?.filter((data) => data)}
        selectedTransactions={selectedTransactions}
        setSelectedTransactions={setSelectedTransactions}
        transactions={list}
        tableArgs={tableArgs}
        totalTransactions={total}
        isFetching={isFetching}
        sorting={sorting}
        setSorting={setSorting}
        isGrouped
        page={page}
        hasMore={hasMore}
        type={type}
        dateToBeGrouped={(selectItem) => {
          return selectItem?.type === REIMBURSEMENTS_TYPES.MILEAGE
            ? "travelDate"
            : "transactionDate";
        }}
      />
    </div>
  );
}

export default AnalyticsReimbursementHelper;

AnalyticsReimbursementHelper.propTypes = {
  type: PropTypes.string,
};
