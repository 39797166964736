import PropTypes from "prop-types";
import { createPortal } from "react-dom";

export const Portal = ({
  children,
  destinationContainer = document.body,
  toAppShell = false,
}) => {
  return createPortal(
    children,
    toAppShell ? document.getElementById("root") : destinationContainer
  );
};

Portal.propTypes = {
  children: PropTypes.node,
  destinationContainer: PropTypes.node,
  toAppShell: PropTypes.bool,
};
