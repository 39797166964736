import {
  fetchRequestTableInfo,
  skipApprovalCardRequest,
} from "@/store/reducers/actionCentre";

import { SLIDERS_SEARCH_PARAMS } from "./SearchParams";
import { BE_FE_REQUEST_TYPE_CARD_REQUEST } from "./company";
import { APPROVAL_REQUEST_TYPE } from "./myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "./pagination";

export const CARD_REQUEST_CTA_KEYS = {
  approve: "approve",
  reject: "reject",
  skipApprovals: "skipApprovals",
};

export const CARD_REQUEST_CTA_CONFIGS = {
  [CARD_REQUEST_CTA_KEYS.skipApprovals]: {
    label: "Skip approvals",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        currentUser = null,
        appliedFilters,
      } = componentProvidedStuff;

      const onSucess = () => {
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest
        );
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest
        );
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest?.[
            BE_FE_REQUEST_TYPE_CARD_REQUEST[val?.requestType]
          ]
        );
        dispatch(
          fetchRequestTableInfo({
            user: currentUser?.id,
            request_type: APPROVAL_REQUEST_TYPE.CARD,
            page: 1,
            limit: PAGINATION_PER_REQUEST_LIMIT,
            ...(appliedFilters ? appliedFilters : {}),
          })
        );
        setSearchParams(searchParams);
      };

      dispatch(
        skipApprovalCardRequest({ id: val.id, do: "skip_approvals", onSucess })
      );
    },
  },
};

export const CTA_CONFIG_CARD_REQUEST = {
  [CARD_REQUEST_CTA_KEYS.skipApprovals]: CARD_REQUEST_CTA_CONFIGS,
};
