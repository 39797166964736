import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  additivePurchaseTaxParentSelector,
  subtractivePurchaseTaxParentSelector,
} from "@/store/selectors/purchase-bills";

import ApplyAtLineItemLevel from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/ApplyAtLineItemLevel";
import BoltOnTheLeft from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/BoltOnTheLeft";
import {
  CREATE_BILL_FLOW_CONTEXT_KEYS,
  CREATE_BILL_TAX_RESPONSE,
  TAX_AT_LINE_ITEM_LEVEL_ADDITIVE,
  TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";

/**
 * In the UI, this is below invoice total, and appears when atleast one tax has been applied at line item level
 */
export default function EnabledAtLineItemLevelSwitches({
  values,
  errors,
  handleChange,
  createBillFlowContext,
  ocrMode,
  billOCRSuccess,
}) {
  const additiveParentTax = useSelector(additivePurchaseTaxParentSelector);
  const subtractiveParentTax = useSelector(
    subtractivePurchaseTaxParentSelector
  );

  const showAdditiveSwitch =
    !!additiveParentTax && values[TAX_AT_LINE_ITEM_LEVEL_ADDITIVE];
  const showSubtractiveSwitch =
    !!subtractiveParentTax && values[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE];

  if (!showAdditiveSwitch && !showSubtractiveSwitch) return null;

  return (
    <div className="mt-8">
      {showAdditiveSwitch ? (
        <BoltOnTheLeft
          showBolt={
            ocrMode &&
            billOCRSuccess &&
            createBillFlowContext.current[
              CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
            ]?.[TAX_AT_LINE_ITEM_LEVEL_ADDITIVE] ===
              values[TAX_AT_LINE_ITEM_LEVEL_ADDITIVE]
          }
        >
          <ApplyAtLineItemLevel
            keyName={TAX_AT_LINE_ITEM_LEVEL_ADDITIVE}
            values={values}
            errors={errors}
            text="billPay.bill.invoiceInbox.createBill.sections.lineItems.applyXYZAtLineItemLevel"
            textProps={{
              taxName: additiveParentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME],
            }}
            handleChange={handleChange}
          />
        </BoltOnTheLeft>
      ) : null}

      {showSubtractiveSwitch ? (
        <BoltOnTheLeft
          showBolt={
            ocrMode &&
            billOCRSuccess &&
            createBillFlowContext.current[
              CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
            ]?.[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE] ===
              values[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE]
          }
        >
          <ApplyAtLineItemLevel
            keyName={TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE}
            values={values}
            errors={errors}
            text="billPay.bill.invoiceInbox.createBill.sections.lineItems.applyXYZAtLineItemLevel"
            textProps={{
              taxName: subtractiveParentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME],
            }}
            handleChange={handleChange}
          />
        </BoltOnTheLeft>
      ) : null}
    </div>
  );
}

EnabledAtLineItemLevelSwitches.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  createBillFlowContext: PropTypes.any,
  ocrMode: PropTypes.string,
  billOCRSuccess: PropTypes.bool,
};
