export default function AuthLayoutSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4446 73L32.4446 -3.54548e-07L40.5557 0L40.5557 73L32.4446 73Z"
        fill="#4B4EFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73 40.5556L0 40.5556L0 32.4445L73 32.4445V40.5556Z"
        fill="#4B4EFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.4417 65.1771L7.82293 13.5583L13.5583 7.82288L65.1771 59.4417L59.4417 65.1771Z"
        fill="#4B4EFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.177 13.5583L13.5582 65.1771L7.82275 59.4417L59.4415 7.82292L65.177 13.5583Z"
        fill="#4B4EFC"
      />
    </svg>
  );
}
