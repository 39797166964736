import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { newCardRequestDetailsSelector } from "@/store/selectors/cards";

import FormSummaryInfo from "@/components/common/FormSummaryInfo";

import {
  CARD_FREQUENCY_LABEL,
  CARD_TYPE,
  LINKED_TO_TYPE,
} from "@/constants/Cards";

export default function LimitSummaryInfo({ outerSectionClasses }) {
  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);

  const cardType = cardRequestDetails?.type;

  const currentFrequency = cardRequestDetails?.frequency;

  const newFrequency = cardRequestDetails?.newFrequency;

  const newLimit = cardRequestDetails?.newLimit;

  const linkedTo = cardRequestDetails?.linkedTo;
  const project = cardRequestDetails?.project;
  const linkedToText = `${
    linkedTo === LINKED_TO_TYPE.DEPARTMENT ? "Department" : "Project"
  } ${project?.name}`;

  const currency = cardRequestDetails?.currency;

  const showSection = !currentFrequency && cardType === CARD_TYPE.PHYSICAL;

  const limitDetailsConfig = [
    {
      label: "Amount",
      value: `${newLimit} ${currency}`,
      show: true,
    },
    {
      label: "Frequency",
      value: CARD_FREQUENCY_LABEL[newFrequency],
      show: true,
    },
    {
      label: "Linked To",
      value: linkedToText,
      show: true,
    },
  ];

  return (
    <FormSummaryInfo
      formFields={limitDetailsConfig}
      outerSectionClasses={outerSectionClasses}
      showSection={showSection}
    />
  );
}

LimitSummaryInfo.propTypes = {
  outerSectionClasses: PropTypes.string,
};
