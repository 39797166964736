import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * DateChangeInput is a controlled input component for entering a date in "DD/MM/YYYY" format.
 * It handles adding the "/" separators as the user types, and checks the entered date against
 * the provided minimum and maximum dates. If the entered date is invalid or outside the range,
 * it will display an error message.
 *
 * @param {{ inputDate: string, onDateChange: function, checkMinDate: boolean, minDate: Date, maxDate: Date, checkMaxDate: boolean, autoFocus: boolean }} props
 * @returns {JSX.Element} The rendered DateChangeInput component.
 */
function DateChangeInput({
  inputDate,
  onDateChange,
  checkMinDate,
  minDate,
  maxDate,
  checkMaxDate,
  autoFocus,
}) {
  const [date, setDate] = useState("");
  const { t } = useTranslation();
  const [prevValue, setPrevValue] = useState(""); // To track previous value
  const [error, setError] = useState("");
  const [isTouched, setIsTouched] = useState(true);
  const handleInputChange = (e) => {
    let { value } = e.target;

    // Handle backspace for removing entire day, month, or year
    if (prevValue.length > value.length) {
      // Detect backspace
      if (prevValue[prevValue.length - 1] === "/") {
        // If last char was '/'
        value = prevValue.slice(0, -1); // Remove the '/' first
      }
    } else {
      // Otherwise, apply normal formatting
      value = value.replace(/\D/g, ""); // Remove non-digits
      if (value.length > 2) value = `${value.slice(0, 2)}/${value.slice(2)}`;
      if (value.length > 5) value = `${value.slice(0, 5)}/${value.slice(5)}`;
      if (value.length > 10) value = value.slice(0, 10); // Restrict to 10 chars
    }

    setPrevValue(value);
    setDate(value);
  };
  useEffect(() => {
    if ((!autoFocus || !isTouched) && inputDate !== "" && inputDate !== date)
      setDate(inputDate);
  }, [inputDate, isTouched]);

  useEffect(() => {
    const creatingDate = new Date();
    const dateArray = date?.split("/");

    if (dateArray?.length === 3 && date?.length === 10) {
      creatingDate.setFullYear(dateArray[2]);
      creatingDate.setMonth(dateArray[1] - 1);
      creatingDate.setDate(dateArray[0]);
      creatingDate.setHours(0, 0, 0, 0);
      if (minDate?.isValid()) minDate.setHours(0, 0, 0, 0);
      if (maxDate?.isValid()) maxDate.setHours(0, 0, 0, 0);

      if (
        creatingDate.isValid() &&
        ((checkMinDate &&
          minDate?.isValid() &&
          creatingDate.isAfter(minDate)) ||
          (checkMaxDate &&
            maxDate?.isValid() &&
            creatingDate.isBefore(maxDate)))
      ) {
        onDateChange(creatingDate);
        setError("");
      } else if (checkMinDate && minDate instanceof Date)
        setError("common.minDateErrorMessage");
      else if (checkMaxDate && maxDate instanceof Date)
        setError("common.maxDateErrorMessage");
    }
  }, [date]);
  const errorMessage = useMemo(() => t(error), [error]);
  return (
    <div>
      <input
        type="text"
        value={date}
        onBlur={() => setIsTouched(false)}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        onChange={handleInputChange}
        maxLength="10"
        className={`w-[125px] h-9 rounded-lg border bg-neutral-100 p-3 ${
          error?.length ? "border-danger-600" : "border-neutral-100"
        }`}
        placeholder="DD/MM/YYYY"
      />
      {error?.length !== 0 ? (
        <p className="absolute text-xs text-danger-600">{errorMessage}</p>
      ) : null}
    </div>
  );
}

export default DateChangeInput;
