import PropTypes from "prop-types";
import React from "react";

import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

export default function CardControlSwitchField({
  title,
  description,
  isEditable,
  handleChange,
  value,
  name,
  titleClasses,
}) {
  return (
    <div className="flex flex-row items-center justify-between gap-6">
      <div className="flex flex-col ">
        <Text
          translationKey={title}
          classes={`text-base font-semibold text-neutral-800 ${titleClasses}`}
        />
        <Text
          translationKey={description}
          classes="text-sm font-medium text-neutral-500"
        />
      </div>

      <Switch
        disabled={!isEditable}
        handleChange={handleChange}
        name={name}
        value={value}
        insideForm
      />
    </div>
  );
}

CardControlSwitchField.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isEditable: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.bool,
  name: PropTypes.string,
  titleClasses: PropTypes.string,
};
