import React from "react";
import { useSelector } from "react-redux";

import { yetToActivateUserWalletsPageFiltersSelector } from "@/store/selectors/qr-payments";

import UserWalletsPageHelper from "@/components/QRPayments/UserWalletsPageHelper";

import { ROUTES } from "@/constants/routes";

function YetToActivateUserWallets() {
  const filters = useSelector(yetToActivateUserWalletsPageFiltersSelector);
  return (
    <UserWalletsPageHelper
      key={ROUTES.QRPay.userWallets.yetToActivate.pathName}
      tab={ROUTES.QRPay.userWallets.yetToActivate.pathName}
      filterArray={filters}
    />
  );
}

export default YetToActivateUserWallets;
