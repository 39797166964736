import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";

import { MAX_SIZE_OF_FILE_ALLOWED_MB } from "@/constants/fileViewer";

/**
 * Renders the header for an OCR document.
 *
 * @param {Function} handleDelete - The function to handle delete action.
 * @param {Function} handleDownload - The function to handle download action.
 * @param {boolean} hideHeader - Flag indicating whether to hide the header.
 * @param {Array} docs - The array of documents.
 * @param {number} currentPage - The current page number.
 * @param {Object} state - The state object.
 * @return {JSX.Element|null} The JSX element representing the header or null if hideHeader is true.
 */
function OCRHeaderRenderer({
  handleDelete,
  handleDownload,
  hideHeader,
  docs,
  disabled,
  currentPage,
  state,
  showDelete = true,
  error,
  maxSize = MAX_SIZE_OF_FILE_ALLOWED_MB * 1024 * 1024,
  maxFiles = 10,
  multiple = false,
  showUpload,
  onHandleUpload,
}) {
  // Using library hook directly, instead of core/FileUpload to avoid cyclic dependency (FileUploaded uses FileViewer that uses OCRHeaderRenderer)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
    },
    maxSize,
    multiple,
    maxFiles,
    disabled,
    onDrop: (acceptedFiles) => {
      if (!disabled) {
        const newFiles = [
          ...docs,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ];
        onHandleUpload(newFiles);
        return newFiles;
      }
      return docs;
    },
  });

  if (hideHeader) return null;

  return (
    <div className="flex items-center justify-end gap-4 px-4 py-3 shadow-md cursor-pointer">
      {showUpload ? (
        <div className="p-2 bg-white rounded-full" {...getRootProps()}>
          <input {...getInputProps()} disabled={disabled} />
          <Button
            className={`text-primary-600 border border-neutral-300 py-1.5 px-5
                      text-sm font-medium rounded-lg flex items-center gap-2 cursor-pointer ${
                        disabled
                          ? "disabled:opacity-50 disabled:cursor-not-allowed"
                          : "bg-white"
                      }`}
            onClick={() => {}} // hook will take care of event
            disabled={disabled}
            variant="tertiary"
            label="billPay.bill.invoiceInbox.createBill.uploadMore"
            preIcon="Add"
            iconClasses="-mr-1"
            innerClasses="gap-1 font-bold text-xs"
          />
        </div>
      ) : null}

      <div className="p-2 bg-white rounded-full">
        <Icon
          className="w-5 h-5"
          name="Download"
          handleClick={handleDownload}
        />
      </div>
      {showDelete ? (
        <div className="p-2 bg-white rounded-full">
          <Icon
            className="w-5 h-5"
            name="Delete"
            handleClick={() => {
              handleDelete({ document: docs[currentPage], state });
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export default OCRHeaderRenderer;

OCRHeaderRenderer.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  hideHeader: PropTypes.bool.isRequired,
  docs: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
  error: PropTypes.string,
  showUpload: PropTypes.bool,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  onHandleUpload: PropTypes.func,
};
