import PropTypes from "prop-types";

import Text from "@/components/core/Text";

export default function MemoCell({ val, memoEditable }) {
  return (
    <div
      id={`description-cell-${val?.id}`}
      className="inline-block w-full truncate"
    >
      {val?.bulkProcessing ? null : (
        <Text
          translationKey={val?.description || val?.memo || "-"}
          showTooltip
          noTranslate
        />
      )}
    </div>
  );
}
MemoCell.propTypes = {
  val: PropTypes.object,
  memoEditable: PropTypes.bool,
};
