import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { googleLogin } from "@/store/reducers/user";

import { isGoogleLoadingSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function GoogleLoginButton() {
  const dispatch = useDispatch();
  const isLoading = useSelector(isGoogleLoadingSelector);
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();
  const oneTimeToken = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.oneTimeToken
  );
  const actionType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.actionType
  );
  const fromSubdomain = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.fromSubdomain
  );

  return (
    <Button
      preIcon="Google"
      variant="tertiary"
      classes="text-neutral-500 font-semibold w-full"
      label="login.loginWithGoogle"
      showLoader={isLoading}
      onClick={() => {
        dispatch(
          googleLogin({
            navigate,
            ...(oneTimeToken && { one_time_token: oneTimeToken }),
            ...(actionType && { action_type: actionType }),
            ...(fromSubdomain && { from_subdomain: fromSubdomain }),
          })
        );
      }}
    />
  );
}
