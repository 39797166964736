import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchAnalyticsEntitites,
  fetchAnalyticsLedgerCurrencies,
  fetchAnalyticsTransaction,
  resetAnalyticsTransactionsData,
} from "@/store/reducers/analytics";

import {
  analyticsEntitesSelector,
  analyticsLedgerCurrencySelector,
  analyticsTransactionsSelector,
  isFetchingAnalyticsEntitesSelector,
} from "@/store/selectors/analytics";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import AnalyticsTableHelper from "@/components/Analytics/common/AnalyticsTableHelper";
import HeaderAndFilterHelper from "@/components/Analytics/common/HeaderAndFilterHelper";
import { convertFilters } from "@/utils/filters";
import { SORTING_CATEGORY, SORT_KEYS } from "@/utils/constants/sorting";
import {
  LEDGER_PAGE_TYPE,
  LEDGER_TABLE_HEADERS,
} from "@/utils/constants/ledger";
import { subdomain } from "@/utils/common";

import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function AnalyticsLedgerHelper({ type, tab }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const transactions = useSelector(analyticsTransactionsSelector);

  const entities = useSelector(analyticsEntitesSelector);

  const analyticsLedgerCurrencies = useSelector(
    analyticsLedgerCurrencySelector
  );

  const isFetchingEntities = useSelector(isFetchingAnalyticsEntitesSelector);
  const { list, hasMore, page, isFetching, total } = transactions ?? {};
  const appliedFilters = useSelector(appliedFilterSelector);

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  const currentPageCredit =
    tab?.name?.toLowerCase() === LEDGER_PAGE_TYPE.credit;

  const onReset = () => {
    dispatch(resetAnalyticsTransactionsData());
  };

  const loadMore = () => {
    dispatch(
      fetchAnalyticsTransaction({
        type,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
        page: pageNum,

        ...(type === ACCOUNTING_TRANSACTION_PAGES.LEDGER
          ? {
              account_id: tab?.accountId,
            }
          : {}),
        ...(!appliedFilters?.entity
          ? {
              subdomain: subdomain(),
            }
          : {}),
        ...convertFilters(appliedFilters),
      })
    );
  };

  const headers = [
    {
      id: LEDGER_TABLE_HEADERS.createdAt,
      label: "accounting.ledger.tableHeader.date",
      classes: "text-left",
      colWidth: 240,
      disableCustomise: true,
    },
    {
      id: LEDGER_TABLE_HEADERS.ledgerTypeCamelCase,
      label: "accounting.ledger.tableHeader.ledgerType",
      classes: "text-left capitalize",
      colWidth: 200,
    },
    {
      id: LEDGER_TABLE_HEADERS.ledgerAmount,
      label: "accounting.ledger.tableHeader.amount",
      classes: "justify-end text-right",
      colWidth: 200,
    },
    {
      id: LEDGER_TABLE_HEADERS.type,
      label: "accounting.ledger.tableHeader.type",
      classes: "text-left",
      colWidth: 120,
    },
    {
      id: LEDGER_TABLE_HEADERS.currentOutstanding,
      label: `accounting.ledger.tableHeader.${
        currentPageCredit
          ? LEDGER_TABLE_HEADERS.outstanding
          : LEDGER_TABLE_HEADERS.balance
      }`,
      classes: "justify-end text-right",
      colWidth: 200,
    },
    {
      id: LEDGER_TABLE_HEADERS.description,
      label: "accounting.ledger.tableHeader.description",
      classes: "text-left truncate",
      colWidth: 300,
    },
  ];

  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: headers?.filter((data) => data).map((header) => header.colWidth),
    emptyDataIconSrc: "emptyScreen.svg",
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...appliedFilters,
      ...sorting?.type,
      tab,
      analyticsLedgerCurrencies,
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  useEffect(() => {
    if (!entities?.length && !isFetchingEntities)
      dispatch(fetchAnalyticsEntitites());
  }, [entities, isFetchingEntities]);

  useEffect(() => {
    if (appliedFilters?.entity) {
      dispatch(
        fetchAnalyticsLedgerCurrencies({
          subdomain: appliedFilters?.entity?.value,
        })
      );
    } else {
      dispatch(
        fetchAnalyticsLedgerCurrencies({
          subdomain: subdomain(),
        })
      );
    }

    navigate({ pathname: analyticsLedgerCurrencies?.[0]?.path });
  }, [JSON.stringify(appliedFilters?.entity)]);

  return (
    <div className="mx-11">
      <HeaderAndFilterHelper type={type} />
      <AnalyticsTableHelper
        handleRefChange={handleRefChange}
        headers={headers?.filter((data) => data)}
        selectedTransactions={selectedTransactions}
        setSelectedTransactions={setSelectedTransactions}
        transactions={list}
        tableArgs={tableArgs}
        totalTransactions={total}
        isFetching={isFetching}
        sorting={sorting}
        isGrouped={false}
        setSorting={setSorting}
        page={page}
        hasMore={hasMore}
        type={type}
        tab={tab}
      />
    </div>
  );
}

export default AnalyticsLedgerHelper;

AnalyticsLedgerHelper.propTypes = {
  type: PropTypes.string,
  tab: PropTypes.string,
};
