import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  resetEditedLimitData,
  setInProgressLimitChange,
  setIsLimitChanged,
  updateCardLimit,
} from "@/store/reducers/cards";
import { setIndexApiReload } from "@/store/reducers/app";
import { indexApiReloadSelector } from "@/store/selectors/app";

import {
  editedLimitDataSelector,
  isFetchingSelectedCardSelector,
  isLimitChangeInProgressSelector,
  isLimtChangedSelector,
  selectedCardProviderDataSelector,
  selectedCardSelector,
} from "@/store/selectors/cards";
import { clientSelector } from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import FrequencyOrderNote from "@/components/Cards/Sliders/CardSlider/Common/EditLimitReviewSlider/FrequencyOrderNote";
import ApproversSectionComponent from "@/components/Cards/Sliders/OrderPhysicalCardSlider/ApproversSection";
import ReviewBudget from "@/components/Company/common/ReviewBudget";
import FormSummaryInfo from "@/components/common/FormSummaryInfo";
import { convertFilters } from "@/utils/filters";
import { amountToCurrency, camelToSnake, dateToString } from "@/utils/common";

import { frequencyOrder } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { CARD_REQUEST_TYPES } from "@/constants/company";

function EditLimitReview({ setOnClose }) {
  const dispatch = useDispatch();
  const reloadIndexApi = useSelector(indexApiReloadSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const card = useSelector(selectedCardSelector);
  const cardHolderId = card?.cardHolder?.id;
  const isFetchingSelectedCardDetails = useSelector(
    isFetchingSelectedCardSelector
  );
  const editedData = useSelector(editedLimitDataSelector);

  const isLimitChanged = useSelector(isLimtChangedSelector);
  const isLimtChangedInProgress = useSelector(isLimitChangeInProgressSelector);

  const ref = useLeftHeaderTitle({
    title: card?.name,
  });
  const clientInfo = useSelector(clientSelector);
  const cardsMakerCheckerEnabled = clientInfo?.cardsMakerCheckerEnabled;
  const totalSpent = card?.totalSpent;
  const cardLimit = card?.limit ?? 0;
  const { oldBalance, newBalance } = getBalancesValues();
  const selectedCardProviderInfo = useSelector(
    selectedCardProviderDataSelector
  );
  const supportsFundAllocation =
    selectedCardProviderInfo?.config?.supportsFundAllocation;

  const pageAppliedFilters = convertFilters(useSelector(appliedFilterSelector));
  const selectedUser = useSelector(userSelector);
  const selectedUserId = selectedUser?.id;

  const ORDER_PHYSICAL_CARD_SUMMARY_CONFIG = [
    {
      name: "cardName",
      label: "cards.cardDetails.editCardDetails.basicDetails.cardName",
      value: card?.name,
      show: true,
    },
    {
      name: "cardHolder",
      label: "cards.cardDetails.editCardDetails.basicDetails.label",
      value: card?.cardHolder?.displayName,
      show: true,
    },
    {
      name: "department",
      label: "cards.cardTable.department",
      value: card?.cardHolder?.departmentName,
      show: true,
    },
    {
      name: "location",
      label: "cards.cardTable.location",
      value: card?.cardHolder?.locationName,
      show: true,
    },
    {
      name: "linkedTo",
      label: "cards.cardTable.linkedTo",
      value: `${card?.linkedTo?.type} : ${card?.linkedTo?.name}`,
      show: true,
    },
    {
      name: "autoBlockDate",
      label: "cards.cardDetails.editCardDetails.basicDetails.autoBlockDate",
      value: card?.blockedAt,
      show: true,
    },
  ];

  useEffect(() => {
    setOnClose((searchParamsArray) => {
      if (searchParamsArray.length === 0) {
        dispatch(resetEditedLimitData());
      }
    });
  }, []);

  useEffect(() => {
    if (isLimitChanged) {
      closeEditLimitReviewSlider();
      closeEditLimitSlider();
      if (searchParams.get(SLIDERS_SEARCH_PARAMS.cards.id)) {
        closeCardSlider();
      }
      dispatch(setIsLimitChanged(false));
      dispatch(setInProgressLimitChange(false));
      dispatch(resetEditedLimitData());
    }
  }, [isLimitChanged]);

  const closeEditLimitReviewSlider = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.editLimitReview);
    setSearchParams(searchParams);
  };

  function closeEditLimitSlider() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.editLimit);
    setSearchParams(searchParams);
  }

  function closeCardSlider() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.id);
    setSearchParams(searchParams);
  }

  function onRequest(e) {
    e.preventDefault();
    const editLimitPreview = {};
    const editLimitDetailsKeys = Object.keys(editedData) || [];
    const editLimitDetailsValues = Object.values(editedData) || [];
    editLimitDetailsKeys?.forEach((fieldKey, index) => {
      editLimitPreview[camelToSnake(fieldKey)] =
        editLimitDetailsValues?.[index];
    });

    dispatch(
      updateCardLimit({
        payload: {
          card_name: card?.name,
          type: CARD_REQUEST_TYPES.TOP_UP_REQUEST,
          project_id: card?.linkedTo?.id,
          card_budget_id: card?.cardBudgetId,
          currency: card?.currency,
          user: [cardHolderId],
          ...editLimitPreview,
        },
        onSuccess: () => {
          dispatch(setIndexApiReload(!reloadIndexApi));
        },
      })
    );
  }

  function getBalancesValues() {
    if (!card || !editedData) {
      // empty check
      return {
        newBalance: 0,
        oldBalance: 0,
      };
    }

    if (
      editedData?.budgetType !== card?.frequency &&
      frequencyOrder[card?.frequency] < frequencyOrder[editedData?.budgetType]
    ) {
      return {
        newBalance: editedData?.amount,
        oldBalance: cardLimit - totalSpent,
      };
    }
    return {
      newBalance: (editedData?.amount ?? 0) - totalSpent,
      oldBalance: cardLimit - totalSpent,
    };
  }

  if (isFetchingSelectedCardDetails) {
    return <Text translationKey="cards.editLimit.loadingText" classes="p-9" />;
  }

  return (
    <div>
      <div className="pb-16 slider-content-core">
        <form
          onSubmit={onRequest}
          id="edit-limit-review-slider"
          className="flex flex-col gap-8"
        >
          <div className="flex flex-col">
            <h1>
              <Text
                refProp={ref}
                translationKey={card?.name}
                classes="text-3xl text-neutral-800 font-bold"
              />
            </h1>

            <Text
              translationKey="cards.cardDetails.editLimitDetails.reviewTitleDesc"
              classes="text-sm text-neutral-500 font-medium"
            />
          </div>

          <FormSummaryInfo formFields={ORDER_PHYSICAL_CARD_SUMMARY_CONFIG} />

          <div className="flex flex-col gap-3">
            <Text
              translationKey="cards.cardDetails.editLimitDetails.limit"
              classes="text-lg "
            />

            <ReviewBudget
              oldValue={{
                amount: {
                  value: cardLimit,
                  currency: card?.currency,
                  oldValueTitle: "cards.editLimit.reviewBudget.limit.old",
                },
                frequency: { value: card?.frequency },
              }}
              newValue={{
                amount: {
                  value: editedData?.amount,
                  currency: card?.currency,
                  newValueTitle: "cards.editLimit.reviewBudget.limit.new",
                },
                frequency: { value: editedData?.budgetType },
              }}
              budgetKeys={["amount", "frequency"]}
            />

            <FrequencyOrderNote
              fromFrequency={card?.frequency}
              toFrequency={editedData?.budgetType}
            />
          </div>

          {editedData?.tempLimit ? (
            <Alert
              description="cards.editLimit.recurMessage"
              descriptionTransalationProp={{
                amountCurrency: amountToCurrency(
                  card?.refreshLimit !== 0 ? card?.refreshLimit : cardLimit,
                  card?.currency
                ),
                frequency: card?.frequency,
                nextRecurAt: dateToString(card?.nextRecurAt),
              }}
              icon="EventRepeat"
              iconClasses="text-neutral-500"
              descriptionClasses="text-neutral-800"
            />
          ) : null}

          {supportsFundAllocation ? (
            <Alert
              description={
                cardLimit > editedData?.amount
                  ? "cards.editLimit.ayoConnectChangeLimitText.decreasedLimit"
                  : "cards.editLimit.ayoConnectChangeLimitText.increasedLimit"
              }
              descriptionTransalationProp={{
                amount: Math.abs(cardLimit - (editedData?.amount ?? 0)),
                currency: card?.currency,
              }}
              classes="border-warning-300 bg-warning-50"
              descriptionClasses="text-neutral-800"
            />
          ) : null}

          <div className="flex flex-col gap-3">
            <Text
              translationKey="cards.cardDetails.editLimitDetails.availableBalance"
              classes="text-lg "
            />

            <ReviewBudget
              oldValue={{
                amount: {
                  value: oldBalance || 0,
                  currency: card?.currency,
                  oldValueTitle: "cards.editLimit.reviewBudget.balance.old",
                },
              }}
              newValue={{
                amount: {
                  value: newBalance || 0,
                  currency: card?.currency,
                  newValueTitle: "cards.editLimit.reviewBudget.balance.new",
                },
              }}
              budgetKeys={["amount"]}
            />
          </div>

          {editedData?.reason ? (
            <div className="flex flex-col gap-3 mt-3">
              <Input
                name="reason"
                label="Reason"
                value={editedData?.reason}
                classes="text-neutral-600 "
                disabled
              />
            </div>
          ) : null}

          {cardLimit < editedData?.amount ||
          (cardLimit > editedData?.amount &&
            card?.refreshLimit > 0 &&
            !editedData?.tempLimit) ? (
            <ApproversSectionComponent
              params={{
                amount: editedData?.amount,
                currency: card?.currency,
                project_id: card?.linkedTo?.id,
                policy_group_type: "cards",
              }}
              approvalDescription="cards.pCards.sliders.createPhysicalCardsDetailsSummary.approvers.approvalDescription"
              preApprovalDescription="cards.pCards.sliders.createPhysicalCardsDetailsSummary.approvers.preApprovalDescription"
              descriptionTextClasses="text-neutral-500"
              makerCheckerStatus={cardsMakerCheckerEnabled}
            />
          ) : (
            <div className="card-wrapper">
              <div className="flex items-center gap-2">
                <Icon name="CheckCircle" className="text-success-600" />
                <Text
                  translationKey="timeline.aboveAccordionTexts.autoApproved"
                  classes="text-lg font-semibold"
                />
              </div>
              <Text
                translationKey="billPay.bill.invoiceInbox.createBill.sections.approvers.paymentWillBeAutoApproved"
                classes="text-sm font-medium text-neutral-500"
              />
            </div>
          )}
        </form>
      </div>
      <div className="fixed px-3 py-5 slider-footer">
        <div className="flex items-center justify-end gap-3">
          <Button
            label="cards.vCards.createCardSlider.cancel"
            classes="w-[160px] text-neutral-500 font-medium"
            variant="tertiary"
            onClick={closeEditLimitReviewSlider}
          />

          <Button
            label="cards.cardDetails.editLimitDetails.request"
            classes="w-[160px] text-white font-medium"
            variant="primary"
            btnType="submit"
            form="edit-limit-review-slider"
            disabled={isLimtChangedInProgress}
          />
        </div>
      </div>
    </div>
  );
}

EditLimitReview.propTypes = {
  setOnClose: PropTypes.func,
};

export default EditLimitReview;
