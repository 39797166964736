import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { usePopper } from "react-popper";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  isFetchingQuickActionsSelector,
  masterDataQuickActionsSelector,
  quickActionsSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Modal from "@/components/core/Modal";
import Popup from "@/components/core/Popup";
import Text from "@/components/core/Text";

import QuickAccessAll from "@/components/Company/Overview/QuickAccessLinks/QuickAccessAll";
import { QUICK_ACCESS_LINKS_ENTRIES } from "@/utils/constants/dashboard";

export default function DashboardQuickAccessLinks({ companyPage = false }) {
  const [showAll, setShowAll] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {});
  const actions = useSelector(quickActionsSelector);
  const isFetching = useSelector(isFetchingQuickActionsSelector);
  const quickActionsMasterData = useSelector(masterDataQuickActionsSelector);
  const quickActions = actions?.mini;

  const quickActionsKeysMasterData = useMemo(
    () =>
      quickActions
        ? Object.keys(quickActions || {})?.filter(
            (key) =>
              quickActionsMasterData?.length &&
              quickActionsMasterData?.includes(key)
          )
        : [],
    [JSON.stringify(quickActionsMasterData), JSON.stringify(quickActions)]
  );
  const ctas = QUICK_ACCESS_LINKS_ENTRIES;

  return (
    <div className="col-span-2">
      <div className="flex flex-col h-full">
        <Text
          translationKey="dashboard.quickAccess.title"
          classes={
            companyPage
              ? "text-base text-neutral-800 font-semibold mb-3"
              : "text-lg text-neutral-500 font-bold mb-3"
          }
        />
        {!isFetching && quickActions ? (
          <div
            className={`card-wrapper-lg grow ${
              companyPage ? "border-none" : ""
            }`}
          >
            <div
              className={`grid grid-cols-4 gap-4 ${
                companyPage ? "pb-4" : "p-4"
              } outlinex`}
            >
              {quickActionsKeysMasterData.map((key, index) => (
                <NavLink
                  className={`flex flex-col justify-start items-center text-center ${
                    !quickActions[key] ? "hover:cursor-not-allowed" : ""
                  }`}
                  key={`wallet-cta-${index}`}
                  to={!quickActions[key] ? "#" : ctas[key]?.path}
                >
                  <div
                    className={`flex justify-center items-center h-12 w-12 p-5 rounded-full ${
                      !quickActions[key] ? "bg-neutral-100" : ctas[key]?.bgColor
                    } ${companyPage ? "mb-2" : "mb-3"}`}
                  >
                    <Icon
                      name={ctas[key]?.icon}
                      className={`h-full w-full ${
                        !quickActions[key]
                          ? "text-neutral-400"
                          : ctas[key]?.iconColor
                      }`}
                    />
                  </div>
                  <Text
                    classes={`${
                      companyPage ? "text-xs" : "text-base"
                    } font-semibold text-neutral-800 text-center`}
                    translationKey={ctas[key]?.label}
                  />
                </NavLink>
              ))}
            </div>
            <Button
              label="dashboard.viewAll"
              variant="tertiary"
              classes="w-full text-btn-lg font-semibold"
              onClick={() => setShowAll((_) => !_)}
            />
          </div>
        ) : (
          <div className="flex flex-col">
            <LoaderSkeleton classes="mt-3" size={[70, 400]} />
            <LoaderSkeleton classes="mt-3" size={[70, 400]} />
            <LoaderSkeleton classes="mt-3" size={[70, 400]} />
          </div>
        )}
        {showAll && (
          <Modal
            onClose={() => setShowAll(false)}
            innerContainerClasses="h-[700px]"
          >
            <div className="h-full ml-4 mr-1">
              <QuickAccessAll
                onClose={() => setShowAll(false)}
                actions={actions.detail}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

DashboardQuickAccessLinks.propTypes = {
  companyPage: PropTypes.bool,
};
