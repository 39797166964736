import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { defaultCurrencySelector } from "@/store/selectors/client";

import Text from "@/components/core/Text";
import { amountToCurrency } from "@/utils/common";

const ClaimPolicyLimitDummy = ({ val, claimPolicyData }) => {
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const getLimitData = () => {
    const foundObject = claimPolicyData.claimPolicyCategoryLimits.find(
      (obj) => obj.categoryId === val.id
    );

    return foundObject ? foundObject.limit : null;
  };

  const limit = getLimitData() ?? 0;
  const text = amountToCurrency(limit, defaultCurrency);

  return (
    <span className="font-semibold text-neutral-800 text-base">
      {text || "-"}
    </span>
  );
};
ClaimPolicyLimitDummy.propTypes = {
  val: PropTypes.object,
  claimPolicyData: PropTypes.object,
};

export default ClaimPolicyLimitDummy;
