import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function NavIcon({
  index,
  iconStr,
  path,
  activeNavItemIndex,
  changeActiveNavItemIndex,
  hasChildren,
  borderBottom = false,
  disabled,
  data,
}) {
  const location = useLocation();
  const [navHovered, setNavHovered] = useState(false);

  const isActive = () => location.pathname.startsWith(path);

  const navElement = () => (
    <div
      className={`p-4 rounded ${!disabled ? "hover:bg-primary-50" : ""} ${
        isActive() ? "bg-primary-50" : ""
      }`}
      id={`nav-bar-${index}`}
    >
      <Icon
        name={iconStr}
        className={`h-4 w-4 ${
          !disabled
            ? activeNavItemIndex === index || isActive() || navHovered
              ? "text-primary-500"
              : "text-neutral-500"
            : "opacity-40 cursor-not-allowed"
        }`}
      />
      {activeNavItemIndex !== index ? (
        <Tooltip id={`nav-bar-${index}`}>
          <Text translationKey={data?.title} />
        </Tooltip>
      ) : null}
    </div>
  );

  return hasChildren ? (
    <div className="flex flex-col items-center gap-2">
      <div
        className="flex flex-col items-center justify-between w-full my-1 text-center"
        onClick={(e) => (!disabled ? changeActiveNavItemIndex(e) : () => {})}
        onMouseEnter={() => setNavHovered(true)}
        onMouseLeave={() => setNavHovered(false)}
      >
        {navElement()}
      </div>
      {borderBottom ? (
        <hr className="w-10 border-t border-neutral-300" />
      ) : null}
    </div>
  ) : (
    <NavLink
      className="flex flex-col items-center justify-between w-full my-1 text-center"
      to={path}
      onClick={(e) => changeActiveNavItemIndex(e)}
    >
      {navElement()}
    </NavLink>
  );
}

NavIcon.propTypes = {
  index: PropTypes.number,
  iconStr: PropTypes.string,
  path: PropTypes.string,
  activeNavItemIndex: PropTypes.number,
  changeActiveNavItemIndex: PropTypes.func,
  hasChildren: PropTypes.bool,
  borderBottom: PropTypes.bool,
};
