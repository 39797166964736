import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { defaultCurrencySelector } from "@/store/selectors/client";

import Flag from "@/components/core/Flag";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import BalanceCard from "@/components/Company/Overview/MyAccounts/shared/BalanceCard";

/**
 * Box with balance on the left and flag on the right
 */
export default function AmountToBePaid({
  amount,
  currency,
  titleText = "billPay.bill.invoiceInbox.createBill.sections.lineItems.amountToBePaid",
  descriptionText,
  tooltipText,
  tooltipTextProps,
  blueBorder = true,
  darkGrayBorder = false,
  inPayrollContext,
  inReqSlider = false,
  classes = "",
  isEditable,
  isEditableDisabled,
  fieldName,
  values,
  errors,
  handleChange,
  setValues,
  midUI,
  rightOfTooltipUI,
}) {
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const flagCode = currency || defaultCurrency;
  return (
    <div
      className={`px-6 py-4 flex items-center justify-between rounded-md ${classes} ${
        blueBorder
          ? "border-primary-400 border "
          : darkGrayBorder
            ? "border-neutral-300 border"
            : "border-neutral-200 border"
      }`}
    >
      <div id="left">
        <BalanceCard
          isEditable={isEditable}
          isEditableDisabled={isEditableDisabled}
          fieldName={fieldName}
          values={values}
          errors={errors}
          handleChange={handleChange}
          setValues={setValues}
          //
          label={
            <div className="flex items-center gap-2">
              <Text
                translationKey={titleText}
                classes="text-base text-neutral-800 font-semibold"
              />

              {descriptionText ? (
                <Text
                  translationKey={descriptionText}
                  classes="text-base text-neutral-600 font-semibold"
                />
              ) : null}

              {tooltipText ? (
                <span id="create-bill-amount-to-pay-tooltip-id">
                  <Icon
                    name="Info"
                    className="text-neutral-300 p-[1.5px] h-5 w-5 hover:cursor-pointer"
                  />
                  <Tooltip
                    id="create-bill-amount-to-pay-tooltip-id"
                    direction="bottom"
                  >
                    <Text
                      translationKey={tooltipText}
                      classes="text-sm font-medium text-neutral-800"
                      translationProps={tooltipTextProps}
                    />
                  </Tooltip>
                </span>
              ) : null}

              {rightOfTooltipUI}
            </div>
          }
          amountFontSizeClass={
            inPayrollContext || inReqSlider ? "text-lg font-bold" : "text-3xl"
          }
          currencyFontSizeClass={inReqSlider ? "" : "text-lg"}
          amount={amount?.value || amount}
          currency={currency || amount?.currency}
        />
      </div>
      {midUI}
      {flagCode ? (
        <div id="right">
          <Flag
            code={flagCode}
            classes="h-8 w-8 border-1 border-neutral-200"
            border
          />
        </div>
      ) : null}
    </div>
  );
}

// "taxXYZSubtractedFromInvoiceTotal"

AmountToBePaid.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.number,
      currency: PropTypes.string,
    }),
  ]),
  currency: PropTypes.string,
  titleText: PropTypes.string,
  descriptionText: PropTypes.string,
  blueBorder: PropTypes.bool,
  darkGrayBorder: PropTypes.bool,
  inPayrollContext: PropTypes.bool,
  classes: PropTypes.string,
  inReqSlider: PropTypes.bool,

  tooltipText: PropTypes.string,
  tooltipTextProps: PropTypes.object,
  isEditable: PropTypes.bool,
  isEditableDisabled: PropTypes.bool,
  fieldName: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  midUI: PropTypes.elementType,
  rightOfTooltipUI: PropTypes.elementType,
};
