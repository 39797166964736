import { createSelector } from "@reduxjs/toolkit";

import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
} from "@/utils/constants/filters";

import { REIMBURSEMENT_CTA_KEYS } from "@/utils/constants/payments";
import { reimbursementSubmissionDateFilterEnabledSelector } from "./client";

export const selectReimbursementStore = (store) => store.reimbursement;

export const reimbursementSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.reimbursements
);

export const isCreateLoadingSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.isCreateLoading
);

export const filtersKeysSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.filtersKeys
);
export const reimbursementPaymentHistorySelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.reimbursementPaymentHistory
);

export const selectedPaymentHistoryDataSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.selectedPaymentHistoryData
);
export const createClaimFormDetailsSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.createClaimForm
);

export const directionResponseDataSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.directionResponse
);

export const reimbursementAutoPaymentSwitchSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.reimbursementAutoPaymentSwitch
);

export const selectedPaymentsSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.selectedPaymentsData
);

export const setClaimCategoryLimitDetailSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.claimCategoryLimitDetails
);

export const mileageRateListSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.mileageRateList.list
);

export const approvalsFiltersSelector = createSelector(
  selectReimbursementStore,
  reimbursementSubmissionDateFilterEnabledSelector,
  (reimbursement, reimbursementSubmissionDateFilterEnabled) =>
    reimbursement.filterKeys?.approvals
      ?.filter(
        (item) =>
          item !== AVAILABLE_FILTER_KEYS.reimbursementSubmissionDate ||
          reimbursementSubmissionDateFilterEnabled
      )
      ?.map((key) => AVAILABLE_FILTERS[key])
);
export const approvalsAllFiltersSelector = createSelector(
  selectReimbursementStore,
  reimbursementSubmissionDateFilterEnabledSelector,
  (reimbursement, reimbursementSubmissionDateFilterEnabled) =>
    reimbursement.filterKeys?.approvalsAll
      ?.filter(
        (item) =>
          item !== AVAILABLE_FILTER_KEYS.reimbursementSubmissionDate ||
          reimbursementSubmissionDateFilterEnabled
      )
      ?.map((key) => AVAILABLE_FILTERS[key])
);

export const reportApprovalsFiltersSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) =>
    reimbursement.filterKeys?.reportApprovals.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const reportApprovalsAllFiltersSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) =>
    reimbursement.filterKeys?.reportApprovalsAll.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const paymentsFiltersSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) =>
    reimbursement.filterKeys.payments.map((key) => AVAILABLE_FILTERS[key])
);

export const paidFiltersSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) =>
    reimbursement.filterKeys.paid.map((key) => AVAILABLE_FILTERS[key])
);

export const paymentHistoryFiltersSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) =>
    reimbursement.filterKeys.paymentHistory.map((key) => AVAILABLE_FILTERS[key])
);

export const historyPaymentStatusFiltersSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) =>
    reimbursement.filterKeys.history.map((key) => AVAILABLE_FILTERS[key])
);

export const historyReportPaymentStatusFiltersSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) =>
    reimbursement.filterKeys.historyReport.map((key) => AVAILABLE_FILTERS[key])
);

export const reimbursementHasMoreSelector = createSelector(
  reimbursementSelector,
  (reimbursement) => reimbursement.hasMore
);

export const reimbursementPaymentHistoryHasMoreSelector = createSelector(
  reimbursementPaymentHistorySelector,
  (reimbursement) => reimbursement.hasMore
);

export const reimbursementPaymentHistoryTotalPaidSelector = createSelector(
  reimbursementPaymentHistorySelector,
  (reimbursement) => reimbursement.totalPaid
);

export const reimbursementPaymentHistoryTotalListSelector = createSelector(
  reimbursementPaymentHistorySelector,
  (reimbursement) => reimbursement.total
);

export const reimbursementPaymentHistoryAmountSelector = createSelector(
  reimbursementPaymentHistorySelector,
  (reimbursement) => reimbursement.totalAmount
);

export const isFetchingReimbursementPaymentHistorySelector = createSelector(
  reimbursementPaymentHistorySelector,
  (reimbursement) => reimbursement.isFetching
);
export const reimbursementTotalSelector = createSelector(
  reimbursementSelector,
  (reimbursement) => reimbursement.total
);

export const reimbursementTotalAmountSelector = createSelector(
  reimbursementSelector,
  (reimbursement) => reimbursement.totalAmount
);

export const reimbursementpageSelector = createSelector(
  reimbursementSelector,
  (reimbursement) => reimbursement.page
);
export const reimbursementsListSelector = createSelector(
  reimbursementSelector,
  (reimbursement) => reimbursement.list
);
export const selectedReimbursementSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.selectedReimbursement
);
export const userReimbursementSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.userReimbursement
);
export const userReimbursementHasMoreSelector = createSelector(
  userReimbursementSelector,
  (reimbursement) => reimbursement.hasMore
);
export const userReimbursementListSelector = createSelector(
  userReimbursementSelector,
  (reimbursement) => reimbursement.list
);
export const userReimbursementTotalSelector = createSelector(
  userReimbursementSelector,
  (reimbursement) => reimbursement.total
);
export const selectedUserReimbursementSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.selectedUserReimbursement
);
export const selectedReimbursementUserCanSettleViaCardSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) =>
    reimbursement?.selectedUserReimbursement?.ctas?.includes?.(
      REIMBURSEMENT_CTA_KEYS.settleViaCard
    )
);

export const selectedUserReimbursementListSelector = createSelector(
  selectedUserReimbursementSelector,
  (reimbursement) => reimbursement.list
);

export const selectedUserReimbursementPageSelector = createSelector(
  selectedUserReimbursementSelector,
  (reimbursement) => reimbursement.page
);

export const selectedUserReimbursementTotalSelector = createSelector(
  selectedUserReimbursementSelector,
  (reimbursement) => reimbursement.totalClaims
);

export const selectedUserReimbursementCreatedBySelector = createSelector(
  selectedUserReimbursementSelector,
  (reimbursement) => reimbursement.createdBy
);
export const selectedUserReimbursementAmountSelector = createSelector(
  selectedUserReimbursementSelector,
  (reimbursement) => reimbursement.amount
);
export const selectedUserReimbursementCtaSelector = createSelector(
  selectedUserReimbursementSelector,
  (reimbursement) => reimbursement.ctas
);

export const selectedUserReimbursementHasMoreSelector = createSelector(
  selectedUserReimbursementSelector,
  (reimbursement) => reimbursement.hasMore
);

export const selectedUserReportSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.selectedUserReport
);

export const selectedUserReportListSelector = createSelector(
  selectedUserReportSelector,
  (selectedUserReport) => selectedUserReport.list
);

export const selectedUserReportTotalSelector = createSelector(
  selectedUserReportSelector,
  (selectedUserReport) => selectedUserReport.totalClaims
);

export const selectedUserReportTotalReportsSelector = createSelector(
  selectedUserReportSelector,
  (selectedUserReport) => selectedUserReport.totalReports
);

export const selectedUserReportCreatedBySelector = createSelector(
  selectedUserReportSelector,
  (selectedUserReport) => selectedUserReport.createdBy
);
export const selectedUserReportAmountSelector = createSelector(
  selectedUserReportSelector,
  (selectedUserReport) => selectedUserReport.amount
);

export const selectedUserReportCtasSelector = createSelector(
  selectedUserReportSelector,
  (selectedUserReport) => selectedUserReport.ctas
);

export const selectedUserReportHasMoreSelector = createSelector(
  selectedUserReportSelector,
  (selectedUserReport) => selectedUserReport.hasMore
);

export const selectedUserReportPageSelector = createSelector(
  selectedUserReportSelector,
  (selectedUserReport) => selectedUserReport.page
);

export const isReimbursementFetchingSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.isReimbursementFetching
);
export const isUserReimbursementsFetchingSelector = createSelector(
  userReimbursementSelector,
  (reimbursement) => reimbursement.isFetching
);
export const isuserReimbursementsTotalSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.total
);
export const isuserReimbursementFetchingSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.isUserReimbursementFetching
);
export const isReimbursementsFetchingSelector = createSelector(
  reimbursementSelector,
  (reimbursement) => reimbursement.isFetching
);
export const reimbursementFiltersSelector = createSelector(
  filtersKeysSelector,
  (filterKeys) =>
    filterKeys?.reimbursementDraft?.map((key) => AVAILABLE_FILTERS[key])
);

export const reimbursementReportFiltersSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) =>
    reimbursement.filterKeys?.reimbursementReport.map(
      (key) => AVAILABLE_FILTERS[key]
    )
);

export const mileageRateSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.mileageRateList.list
);
export const isFetchingMileageRateSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.mileageRateList.isFetching
);
export const companyClaimPolicySelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.companyClaimPolicyData
);
// settings

export const reimbursementSettingSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.reimbursementSetting
);

export const reimbursementAutopayEnabledSelector = createSelector(
  reimbursementSettingSelector,
  (reimbursement) => reimbursement.reimbursementAutopayEnabled
);

export const reimbursementAutopayDaySelector = createSelector(
  reimbursementSettingSelector,
  (reimbursement) => reimbursement.reimbursementAutopayDay
);

export const reimbursementAutoClaimSettlementToCardEnabledSelector =
  createSelector(
    reimbursementSettingSelector,
    (reimbursement) => reimbursement.autoClaimSettlementToCardEnabled
  );

export const claimPolicySelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.claimPolicyData
);

export const isLoadingSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.isLoading
);

export const isCtaActionPendingForIdSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.isCtaActionPendingForId
);

export const reimbursementAutomatedPaymentToggleSwitchSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.automatedPaymentToggleSwitch
);
export const isFetchingBulkUploadSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.isFetchingBulkUpload
);

export const creatingduplicateReimbursementSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.creatingduplicateReimbursement
);

// reports selector

export const reportSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.reports
);
export const reportsListSelector = createSelector(
  reportSelector,
  (report) => report.list
);

export const reportsPageSelector = createSelector(
  reportSelector,
  (report) => report.page
);

export const reportsTotalSelector = createSelector(
  reportSelector,
  (report) => report.total
);

export const reportsLimitSelector = createSelector(
  reportSelector,
  (report) => report.limit
);

export const reportsHasMoreSelector = createSelector(
  reportSelector,
  (report) => report.hasMore
);

export const isFetchingReportsSelector = createSelector(
  reportSelector,
  (report) => report.isFetching
);

export const selectedClaimForReportSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.selectedClaimForReport
);

export const selectedAllClaimsForReportSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.selectedAllClaimsForReport
);

export const createReportFormDataSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.createReportFormData
);

export const showEmptyListSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.showEmptyList
);

export const selectedReportSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.selectedReport
);

export const isFetchingSelectedReportsSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.isFetchingReport
);

export const isCreatingReportSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.isCreatingReport
);

export const rejectReportReasonSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.rejectReportReason
);

// rejected report selector
export const rejectedClaimsSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.rejectedReimbursements
);
export const rejectedClaimsListSelector = createSelector(
  rejectedClaimsSelector,
  (rejectedClaim) => rejectedClaim.list
);

export const rejectedClaimsPageSelector = createSelector(
  rejectedClaimsSelector,
  (rejectedClaim) => rejectedClaim.page
);

export const rejectedClaimsTotalSelector = createSelector(
  rejectedClaimsSelector,
  (rejectedClaim) => rejectedClaim.total
);

export const rejectedClaimsLimitSelector = createSelector(
  rejectedClaimsSelector,
  (rejectedClaim) => rejectedClaim.limit
);

export const rejectedClaimsHasMoreSelector = createSelector(
  rejectedClaimsSelector,
  (rejectedClaim) => rejectedClaim.hasMore
);

export const isFetchingRejectedClaimsSelector = createSelector(
  rejectedClaimsSelector,
  (rejectedClaim) => rejectedClaim.isFetching
);

export const rejectedClaimPayloadSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.rejectedClaimPayload
);

// user reports selector

export const userReportSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.userReport
);

export const userReportListSelector = createSelector(
  userReportSelector,
  (userReport) => userReport.list
);

export const userReportPageSelector = createSelector(
  userReportSelector,
  (userReport) => userReport.page
);

export const userReportTotalSelector = createSelector(
  userReportSelector,
  (userReport) => userReport.total
);

export const userReportLimitSelector = createSelector(
  userReportSelector,
  (userReport) => userReport.limit
);

export const userReportHasMoreSelector = createSelector(
  userReportSelector,
  (userReport) => userReport.hasMore
);

export const isFetchinguserReportSelector = createSelector(
  userReportSelector,
  (userReport) => userReport.isFetching
);

export const deletedClaimsIdForReportSelector = createSelector(
  selectReimbursementStore,
  (reim) => reim.deletedClaimsIdForReport
);

export const selectedPaymentHistoryListingDataSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.selectedPaymentHistoryListingData
);

export const selectedPaymentHistoryListingDataListSelector = createSelector(
  selectedPaymentHistoryListingDataSelector,
  (selectedPaymentHistoryListingData) => selectedPaymentHistoryListingData.list
);

export const selectedPaymentHistoryListingDataTotalSelector = createSelector(
  selectedPaymentHistoryListingDataSelector,
  (selectedPaymentHistoryListingData) => selectedPaymentHistoryListingData.total
);

export const selectedPaymentHistoryListingDataLimitSelector = createSelector(
  selectedPaymentHistoryListingDataSelector,
  (selectedPaymentHistoryListingData) => selectedPaymentHistoryListingData.limit
);

export const selectedPaymentHistoryListingDataPageSelector = createSelector(
  selectedPaymentHistoryListingDataSelector,
  (selectedPaymentHistoryListingData) => selectedPaymentHistoryListingData.page
);

export const selectedPaymentHistoryListingDataHasMoreSelector = createSelector(
  selectedPaymentHistoryListingDataSelector,
  (selectedPaymentHistoryListingData) =>
    selectedPaymentHistoryListingData.hasMore
);

export const isFetchingSelectedPaymentHistoryListingDataSelector =
  createSelector(
    selectedPaymentHistoryListingDataSelector,
    (selectedPaymentHistoryListingData) =>
      selectedPaymentHistoryListingData.isFetching
  );

export const reimbursementClaimSubmissionPolicySelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.reimbursementClaimSubmissionPolicy
);

export const isFetchingReimbursementClaimSubmissionPolicySelector =
  createSelector(
    selectReimbursementStore,
    (reimbursement) =>
      reimbursement.isFetchingReimbursementClaimSubmissionPolicy
  );

export const editAmountSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.editAmount
);

export const editAmountReasonSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.editAmountReason
);

export const reimbursementBulkUploadResponseSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.bulkUploadResponse
);

export const bulkUploadRefreshDataSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.bulkUploadRefreshData
);

export const bulkUploadRecordIdsSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.bulkUploadRecordIds
);

export const bulkUploadCountSelector = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.bulkUploadCount
);

export const reimbusementSettingExtraKeys = createSelector(
  selectReimbursementStore,
  (reimbursement) => reimbursement.settingsExtraKeys
);
