import PropTypes from "prop-types";
import { useMemo } from "react";

import Pill from "@/components/core/CheckboxDropdown/Pill";
import {
  __TOGGLE_MODE_GROUP_ENTRY,
  __TOGGLE_MODE_SINGLE_ENTRY,
} from "@/components/core/CheckboxDropdown/utils";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";

export default function PillsUpperSection({
  selectedStuff, // selected ids, groups
  setSelectedStuff,
  toggleCheckboxHandler, // used when pill delete icon is clicked

  // for pill labels
  // note: these are props that are originally received by CheckboxDropdown, passed here as is
  options = [], // individual id labels
  headerLabelsMap, // group labels
  name,
  // reactjs-popup related
  isOpen,
  openPopup,
  // closePopup,
  // togglePopup,

  onChange, // text handler, not used currently
  enteredText,

  // needed for showing label of non-editable state
  label = "",
  showLabel = true,
  displayLabel = "",
  handleInput = () => {},
  supportExplicitControls = false,
  labelExtraClasses,
  setClosedFromPill,
  showPills = true,
  hideDisplayLabel,
}) {
  const {
    ids: selectedIds,
    groups: selectedGroups,
    _groupContents: groupContents,
  } = selectedStuff;

  const pillSpaceClickHandler = (e) => {
    // when space between pills is clicked, the popup should not open. But it should close if it is open.
    if (isOpen) e.stopPropagation();
  };

  const pillsIdLabelMap = useMemo(
    () => new Map(options.map((option) => [option.id, option.label])),
    [options]
  );

  // pills to be rendered
  const pills = useMemo(() => {
    const groupPills = [...selectedGroups].map(
      (_groupSelfKey) => {
        const showDeleteHandler =
          headerLabelsMap[_groupSelfKey]?.showDeleteHandler ?? true;
        const pillClasses = headerLabelsMap[_groupSelfKey]?.pillClasses ?? "";

        return {
          id: _groupSelfKey,
          label: headerLabelsMap[_groupSelfKey].label,
          onDeleteHandler: (value, fromPill) => {
            if (fromPill) {
              setClosedFromPill(true);
            }
            toggleCheckboxHandler({
              groupKey: _groupSelfKey,
              value,
              toggleMode: __TOGGLE_MODE_GROUP_ENTRY,
              fromPill,
              setSelectedStuff,
            });
          },
          pillClasses,
          deletable: showDeleteHandler,
        };
      },
      [JSON.stringify(selectedGroups)]
    );

    // remove individual ids if they are in any 'full' group
    // individual pills include options who are part of a non-full group, as well as options who don't belong to a group
    const individualPillsIds = [...selectedIds].filter((selectedId) => {
      if (supportExplicitControls) {
        return true;
      }
      let individualIdPresentInSomeFullGroup = false; // assume individual id is not in any group (i.e. it should be rendered)

      groupContents.forEach(
        ({ selectedIds: selectedIds_, allIds: allIds_ }) => {
          if (
            selectedIds_.has(selectedId) &&
            selectedIds_.size === allIds_.size
          )
            individualIdPresentInSomeFullGroup = true;
        }
      );
      return !individualIdPresentInSomeFullGroup; // render only if not present in any full group
    });
    const individualPills = individualPillsIds.map((_id) => ({
      id: _id,
      label: pillsIdLabelMap.get(_id) ?? _id,
      onDeleteHandler: (value, fromPill) => {
        if (fromPill) {
          setClosedFromPill(true);
        }
        toggleCheckboxHandler({
          id: _id,
          value,
          toggleMode: __TOGGLE_MODE_SINGLE_ENTRY,
          fromPill,
          setSelectedStuff,
        });
      },
    }));

    return [...groupPills, ...individualPills];
  }, [selectedStuff]); // useMemo overhead is negligible here, since the dependency is just a reference check - O(1)

  const pillDeletionUsingCursorHandler = (event, pillOnDeletionHandler) => {
    const isAnyDeletionKeyPressed = event.keyCode === 8 || event.keyCode === 46; // Backspace or Delete
    if (isAnyDeletionKeyPressed) {
      pillOnDeletionHandler?.(); // could be undefined in case of no pills (the text input is the only thing present)
    }
  };

  const pillPressEnterHandler = (event, pillOnDeletionHandler) => {
    const isEnterKeyPressed = event.keyCode === 13 || event.key === "Enter"; // Backspace or Delete
    // if (isEnterKeyPressed) {
    //   pillOnDeletionHandler?.(); // could be undefined in case of no pills (the text input is the only thing present)
    // }
  };

  return (
    <>
      <Input
        name={name}
        // isFocus
        type="text"
        placeholder={label}
        label={showLabel ? label : null}
        labelStyleClasses="text-sm"
        classes="text-sm hidden"
        value={showPills ? displayLabel : ""}
        onChange={(e) => handleInput(e.target.value)}
        labelExtraClasses={labelExtraClasses}
      />
      <div
        className={`flex items-center border-b ${
          isOpen ? "border-primary-500" : "border-neutral-300 "
        } `}
      >
        <div
          className="flex flex-wrap items-center gap-1 px-0 pt-2 pb-3 mt-1 grow"
          onClick={pillSpaceClickHandler}
        >
          {showPills && isOpen
            ? pills.map((pillItem) => (
                <Pill
                  key={pillItem.id}
                  label={pillItem.label}
                  onDeleteHandler={(value, e) => {
                    pillItem.onDeleteHandler(value, true);
                    e.stopPropagation();
                  }}
                  deletable={pillItem?.deletable}
                  pillClasses={pillItem?.pillClasses}
                />
              ))
            : null}
          <input
            name={name}
            onFocus={openPopup}
            onBlur={(e) => {
              // DONOT REMOVE: this make blur trigger which will close popup
              e.stopPropagation();
              e.preventDefault();
            }}
            className="search-input-pill"
            value={
              showPills && isOpen
                ? enteredText
                : hideDisplayLabel
                  ? ""
                  : displayLabel
            }
            onChange={onChange}
            onSubmit={() => {
              // console.log("Pressed enter");
            }}
            onKeyDown={
              enteredText
                ? () => {}
                : (event) => {
                    pillPressEnterHandler(event);
                    pillDeletionUsingCursorHandler(
                      event,
                      pills.at(-1)?.onDeleteHandler
                    );
                  }
            }
          />
        </div>
        <div className="shrink-0">
          <Icon
            name={isOpen ? "UpArrow" : "DownArrow"}
            // no need of onClick handler here. It is handled by the parent component.
          />
        </div>
      </div>
    </>
  );
}

PillsUpperSection.propTypes = {
  selectedStuff: PropTypes.shape({
    ids: PropTypes.instanceOf(Set).isRequired,
    groups: PropTypes.instanceOf(Set).isRequired,
    _groupContents: PropTypes.instanceOf(Map).isRequired,
  }).isRequired,
  setSelectedStuff: PropTypes.func,
  toggleCheckboxHandler: PropTypes.func.isRequired,

  options: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.any, label: PropTypes.string })
  ),
  headerLabelsMap: PropTypes.objectOf(
    PropTypes.shape({ label: PropTypes.string })
  ),

  isOpen: PropTypes.bool.isRequired,
  openPopup: PropTypes.func.isRequired,
  // closePopup: PropTypes.func.isRequired,
  // togglePopup: PropTypes.func.isRequired,

  onChange: PropTypes.func.isRequired,
  enteredText: PropTypes.string.isRequired,

  name: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  displayLabel: PropTypes.string,
  handleInput: PropTypes.func,
  labelExtraClasses: PropTypes.string,
  setClosedFromPill: PropTypes.func,
  showPills: PropTypes.bool,
  hideDisplayLabel: PropTypes.bool,
};
