import NeedsReviewPageHelper from "@/components/common/QrPayAndExpense/NeedsReviewPageHelper";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";

import { NEEDS_REVIEW_TABS } from "@/constants/expense";

export default function ExpensesNRNRTab() {
  return (
    <NeedsReviewPageHelper
      tab={NEEDS_REVIEW_TABS[0]}
      page={QRPAY_EXPENSES_CONTEXT.EXPENSES}
    />
  );
}
