import PropTypes from "prop-types";
import React from "react";

function LivquikIframeComponent({ iFrameUrl }) {
  return (
    <iframe
      src={iFrameUrl}
      title="liviquick card frame"
      className="w-full h-full"
    />
  );
}

export default LivquikIframeComponent;

LivquikIframeComponent.propTypes = {
  iFrameUrl: PropTypes.string,
};
