import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { login, resendOtp } from "@/store/reducers/user";

import {
  authDataSelector,
  isLoadingSelector,
  mfaDataSelector,
  mfaMethodSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import PhoneNumberInput from "@/components/core/PhoneInput";
import Text from "@/components/core/Text";

import ResendButton from "@/components/TwoFactorAuth/ResendButton";
import { useForm } from "@/utils/useForm";
import { REGEX_VALUE } from "@/utils/constantUseForm";

import { ROUTES } from "@/constants/routes";

export default function TwoFactorAuth({
  handleAuthSubmit,
  showTrustedCheck = true,
  buttonTitle = "login.title",
}) {
  const currentUser = useSelector(userSelector);
  const isLoading = useSelector(isLoadingSelector);
  const authEmail = useSelector(authDataSelector)?.email;
  const authPassword = useSelector(authDataSelector)?.password;
  const mfaData = useSelector(mfaDataSelector);
  const mfaMethodSelected = useSelector(mfaMethodSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const [via, setVia] = useState(searchParam.get("via") || mfaData?.via);
  const VIA_CONFIG = {
    email: "email",
    gauth: "authenticator app",
    mobile: "mobile",
  };
  const MASK_COMPONENT = {
    [VIA_CONFIG.mobile]: (
      <Input label="common.otpSentTo" value={mfaData?.mfa_resource} disabled />
    ),
    [VIA_CONFIG.email]: (
      <Input label="common.otpSentTo" value={mfaData?.mfa_resource} disabled />
    ),
  };
  const MaskComponent = MASK_COMPONENT[via];
  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(
      {
        email: {
          value: currentUser?.email || "",
          validate: {
            required: true,
            regex: REGEX_VALUE.email,
          },
        },
        phone: {
          value: currentUser?.phone || "",
          validate: {
            required: true,
          },
        },
        trusted: {
          value: false,
        },
        otp: {
          value: "",
          validate: {
            required: true,
            number: true,
            max: 6,
          },
        },
      },
      (e) => {
        handleAuthSubmit({ values, navigate });
      }
    );

  useEffect(() => {
    if (!via) {
      setVia(mfaMethodSelected);
    }
  }, []);

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          navigate(ROUTES.login.base.absolutePath);
        }}
      >
        <Icon className="h-10 cursor-pointer" name="ChevronLeft" />
      </button>
      <div className="flex flex-col mb-10">
        <Text
          classes="text-3xl font-bold"
          translationKey="Two-step verification"
        />
        <Text
          classes="text-neutral-500 text-sm font-semibold"
          translationKey="login.twoFactorDescription"
          translationProps={{ via: VIA_CONFIG[via] ?? via }}
        />
      </div>
      {MaskComponent ? <div className="my-3">{MaskComponent}</div> : null}
      <form
        className="flex flex-col gap-5 pt-4"
        id="2fa-submit-form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Input
          value={values?.otp}
          error={errors?.otp}
          placeholder="• • • - • • •"
          name="otp"
          maskField
          maxLength="6"
          allowPasswordEye
          label="login.otp"
          onChange={(e) => handleChange(e)}
          fallBackPasswordType="number"
        />

        {via === "gauth" ? (
          <div className="mb-5 "> </div>
        ) : (
          <div className="mb-5 ">
            <ResendButton
              isButton={false}
              label="Resend OTP "
              handleClick={() => {
                const { otp, ...rest } = values;
                handleAuthSubmit({ values: rest, navigate });
              }}
            />
          </div>
        )}
        {showTrustedCheck ? (
          <Checkbox
            name="trusted"
            checked={values?.trusted}
            insideForm
            onClickHandler={(e) => {
              handleChange(e);
            }}
            label="Trust this device for the next 14 days"
          />
        ) : null}

        <Button
          form="2fa-submit-form"
          btnType="submit"
          showLoader={isLoading}
          classes="w-full"
          label={buttonTitle}
        />
      </form>
    </div>
  );
}

TwoFactorAuth.propTypes = {
  handleAuthSubmit: PropTypes.func,
  showTrustedCheck: PropTypes.bool,
  buttonTitle: PropTypes.string,
};
