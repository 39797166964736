import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { updateVendor } from "@/store/reducers/vendors";

import Button from "@/components/core/Button";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";

function EditVendorConfirmationModal({
  showModal,
  setShowModal,
  payload,
  isLoading,
  extraOnCloseFunctionCall = () => {},
}) {
  const dispatch = useDispatch();
  return (
    <div>
      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
        className="flex items-center justify-center "
      >
        <div className="flex flex-col px-6 w-120">
          <div className="py-4 text-lg font-bold">
            <Text translationKey="billPay.vendors.createVendor.editVendorModalTitle" />
          </div>

          <div className="py-2 text-sm">
            <Text translationKey="billPay.vendors.createVendor.editVendorModalDesc" />
          </div>

          <div className="flex items-center gap-2 pt-4 pb-6">
            <Button
              label="billPay.vendors.createVendor.cancel"
              variant="tertiary"
              disabled={isLoading}
              onClick={() => {
                setShowModal(false);
                extraOnCloseFunctionCall();
              }}
              classes="text-neutral-500"
            />
            <Button
              label="billPay.vendors.createVendor.confirmModalCta"
              variant="primary"
              disabled={isLoading}
              showLoader={isLoading}
              onClick={() => dispatch(updateVendor(payload))}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditVendorConfirmationModal;

EditVendorConfirmationModal.propTypes = {
  showModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  setShowModal: PropTypes.func,
  payload: PropTypes.object,
  extraOnCloseFunctionCall: PropTypes.func,
};
