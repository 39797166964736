import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";

import Company from "@/pages/company";
import BudgetPage from "@/pages/company/budget";
import DepartmentBudget from "@/pages/company/budget/department-budget";
import ProjectBudget from "@/pages/company/budget/project-budget";
import DepartmentPage from "@/pages/company/department";
import LocationPage from "@/pages/company/location";
import ProjectPage from "@/pages/company/project";
import { ROUTES } from "@/constants/routes";

export default function CompanyRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/*" element={<Company />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.manage.company.departments.pathName}
          element={<DepartmentPage />}
        />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.manage.company.projects.pathName}
          element={<ProjectPage />}
        />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.manage.company.budgets.pathName}
          element={<BudgetPage />}
        >
          <Route
            path={ROUTES.manage.company.budgets["departments-budgets"].pathName}
            element={<DepartmentBudget />}
          />
          <Route
            path={ROUTES.manage.company.budgets["project-budgets"].pathName}
            element={<ProjectBudget />}
          />
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.manage.company.locations.pathName}
          element={<LocationPage />}
        />
      </Route>
    </Routes>
  );
}
