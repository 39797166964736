import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  showLeftSliderHeaderSelector,
  sliderHeaderDataSelector,
} from "@/store/selectors/slider";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";

export default function LeftHeaderSlider() {
  const isVisible = useSelector(showLeftSliderHeaderSelector);
  const sliderdata = useSelector(sliderHeaderDataSelector);
  const [leftSlider, setLeftSlider] = useState();
  const searchParam = useSearchParams();

  useEffect(() => {
    const value = [...searchParam.keys()];
    if (value.length !== 0 && isVisible) {
      setLeftSlider("visible");
    } else {
      setLeftSlider("");
    }
  }, [isVisible]);
  return (
    <div className={`left-header ${leftSlider}`}>
      <LeftHeader {...sliderdata} />
    </div>
  );
}

export const LeftHeader = ({
  img,
  icon,
  title,
  titleTranslationProps,
  iconStyle,
  titleStyle,
}) => {
  if (title && !img && !icon)
    return typeof title === typeof "" ? (
      <Text
        classes={`text-xl font-bold ${titleStyle}`}
        translationKey={title}
        translationProps={titleTranslationProps}
      />
    ) : (
      title
    );
  if (title && img && !icon)
    return (
      <ProfileWidget
        showTooltip
        truncateBasedOnSize={false}
        id="left-header-tooltip"
        className="text-xl font-bold "
        textClasses={titleStyle}
        img={img}
        name={title}
      />
    );
  if (title && icon && !img)
    return (
      <ProfileWidget
        name={title}
        showTooltip
        truncateBasedOnSize={false}
        id="left-header-tooltip"
        className="text-xl font-bold "
        textClasses={titleStyle}
        iconComponent={<Icon className={iconStyle} name={icon} />}
      />
    );
};
LeftHeader.propTypes = {
  img: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titleTranslationProps: PropTypes.object,
  iconStyle: PropTypes.string,
};
