import PropTypes from "prop-types";
import React from "react";

import LoaderHelper from "@/components/Reimbursement/PaymentsTableLoader/LoaderHelper";

export default function PaymentsTableLoader({ count = 10, type }) {
  return [...Array(count)].map((val, i) => (
    <LoaderHelper type={type} key={`PaymentsTableLoader-${i}`} />
  ));
}

PaymentsTableLoader.propTypes = {
  type: PropTypes.string,
};
