import ExchangeRate from "@/components/common/BillPayAndPayroll/ExchangeRate";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

export default function ExchangeRatePage() {
  return (
    <div>
      <ExchangeRate context={BILL_PAYROLL_CONTEXT.PAYROLL} />
    </div>
  );
}
