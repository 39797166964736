import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchRules, setRulesSliderTab } from "@/store/reducers/rules";

import {
  advancedRulesSelector,
  isFetchingRulesSelector,
  normalRulesSelector,
  rulesSliderSelectedTabSelector,
  rulesSliderTabListSelector,
} from "@/store/selectors/rules";

import Button from "@/components/core/Button";
import Loader from "@/components/core/Loader";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { MAPPING_TYPE } from "@/utils/constants/rules";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { RULE_TYPES } from "@/constants/rules";

import AdvancedRulesDetails from "./AdvancedRulesDetails";
import RulesDetails from "./RulesDetails";

export default function RulesSlider({ setOnClose }) {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const tabList = useSelector(rulesSliderTabListSelector);
  const selectedTab = useSelector(rulesSliderSelectedTabSelector);

  const normalRules = useSelector(normalRulesSelector);

  const advancedRules = useSelector(advancedRulesSelector);

  const isFetchingRules = useSelector(isFetchingRulesSelector);

  const [showNewRule, setShowNewRule] = useState(false);
  const ref = useRef(null);
  const sliderHeaderRef = useLeftHeaderTitle({
    title: "accounting.rules.rulesSlider.title",
  });
  const handleShowNewRule = (val) => {
    if (selectedTab === tabList[1]) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.accounting.rules.newEditAdvancedRule,
        MAPPING_TYPE.NEW_RULE
      );
      setSearchParams(searchParams);
    }
    if (selectedTab === tabList[0]) {
      ref.current.scrollTo({ top: 0, behavior: "smooth" });
    }
    setShowNewRule(val);
  };

  const showFooter = (tab) => {
    return (
      (tab.id === RULE_TYPES.NORMAL
        ? normalRules?.length
        : advancedRules?.length) && !showNewRule
    );
  };

  useEffect(() => {
    dispatch(fetchRules());

    setOnClose((searchParamsArray) => {
      if (
        !searchParamsArray.includes(SLIDERS_SEARCH_PARAMS.accounting.rules.id)
      ) {
        dispatch(setRulesSliderTab(tabList[0]));
      }
    });
  }, []);

  return (
    <div className="slider-content-container">
      <div ref={ref} className="slider-content-core">
        <div className="flex flex-col">
          <span className="text-3xl font-bold text-neutral-800">
            <Text
              translationKey="accounting.rules.rulesSlider.title"
              refProp={sliderHeaderRef}
            />
          </span>
          <span className="mt-1 text-sm text-neutral-500">
            <Text translationKey="accounting.rules.rulesSlider.desc" />
          </span>
        </div>
        <div className="mt-6">
          <Tabs
            items={tabList}
            selectedTab={selectedTab?.key}
            setCurrentTab={(tab) => {
              dispatch(setRulesSliderTab(tab));
            }}
            mode
          />

          {selectedTab.key === 0 ? (
            <RulesDetails
              rules={normalRules}
              showNewRule={showNewRule}
              handleShowNewRule={handleShowNewRule}
              isFetching={isFetchingRules}
            />
          ) : (
            <AdvancedRulesDetails
              rules={advancedRules}
              isFetching={isFetchingRules}
            />
          )}
        </div>
      </div>
      {showFooter(selectedTab) ? (
        <div className="flex items-center justify-end p-4 slider-footer">
          <Button
            variant="primary"
            label={
              (normalRules?.length && selectedTab.key === 0) ||
              (advancedRules?.length && selectedTab.key === 1)
                ? "accounting.rules.rulesSlider.createNewRule"
                : "accounting.rules.rulesSlider.createRule"
            }
            preIcon="LightiningBolt"
            classes="w-fit px-4"
            iconClasses="mr-0"
            onClick={() => handleShowNewRule(true)}
          />
        </div>
      ) : null}
    </div>
  );
}

RulesSlider.propTypes = {
  setOnClose: PropTypes.func,
};
