import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function JobStatusBanner({
  listCount,
  mainTitle,
  tableDataInfoNote,
  bannerBgClasses,
  extraClasses,
  tableDateInfoClasses,
  mainTitleClasses,
}) {
  return (
    <div
      className={`flex flex-row items-center gap-2 ${bannerBgClasses} ${extraClasses}`}
    >
      <div>
        <Icon name="Hourglass" className="w-4 h-4 text-neutral-500" />
      </div>

      <Text
        translationKey={mainTitle}
        classes={`text-xs font-medium text-neutral-800 ${mainTitleClasses}`}
      />

      <div className="flex items-center justify-center px-2 py-0.5 rounded-lg bg-white">
        <Text
          translationKey={listCount || 0}
          classes="text-primary-500 text-xs font-bold"
        />
      </div>

      <Text
        translationKey={tableDataInfoNote}
        classes={`text-xs font-medium text-neutral-800  ${tableDateInfoClasses}`}
      />
    </div>
  );
}

JobStatusBanner.propTypes = {
  listCount: PropTypes.number,
  mainTitle: PropTypes.string,
  tableDataInfoNote: PropTypes.string,
  bannerBgClasses: PropTypes.string,
  extraClasses: PropTypes.string,
  tableDateInfoClasses: PropTypes.string,
  mainTitleClasses: PropTypes.string,
};
