import { createSelector } from "@reduxjs/toolkit";

export const commentStore = (store) => store.comments;

export const commentsSelector = createSelector(
  commentStore,
  (comments) => comments.comments
);

export const commentsListSelector = createSelector(
  commentsSelector,
  (comments) => comments.list
);

export const commentsTotalSelector = createSelector(
  commentsSelector,
  (comments) => comments.total
);
export const commentsPageSelector = createSelector(
  commentsSelector,
  (comments) => comments.page
);
export const commentsHasMoreSelector = createSelector(
  commentsSelector,
  (comments) => comments.hasMore
);
export const commentsLimitSelector = createSelector(
  commentsSelector,
  (comments) => comments.limit
);
