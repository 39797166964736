import PropsTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useMemo } from "react";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import { fetchPeople, resetPeopleStoreState } from "@/store/reducers/company";

import { primaryCardProviderSelector } from "@/store/selectors/client";
import {
  isFetchingPeopleSelector,
  peopleFiltersSelector,
  peopleHasMoreSelector,
  peopleSelector,
  peopleTotalSelector,
} from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";

import { availableModulesSelector } from "@/store/selectors/user";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import PeopleTableLoader from "@/components/Company/Loaders/PeopleTableLoader";
import Export from "@/components/Exports";
import EmptyRow from "@/components/common/EmptyRow";
import PhysicalCardIcon from "@/components/common/PhysicalCardIcon";
import { convertFilters } from "@/utils/filters";
import { FILTER_TRIGGER_CONTEXT } from "@/utils/constants/filters";

import { MODULES } from "@/utils/constants/app";
import { CARD_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_TAB_PARAMS,
  COMPANY_PEOPLE_USER_STATUS,
  INVITATION_STATUS,
} from "@/constants/company";
import { EXPORT_PAGE_TYPE } from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import PeopleTableSearchAndFilter from "./PeopleTableSearchAndFilters";

export default function PeopleTable({ tab }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const availableModules = useSelector(availableModulesSelector);
  const isCardsEnabled = useMemo(
    () => availableModules?.includes(MODULES.CARDS),
    [JSON.stringify(availableModules)]
  );
  const hasMore = useSelector(peopleHasMoreSelector);
  const isFetching = useSelector(isFetchingPeopleSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const filters = useSelector(peopleFiltersSelector);
  const primaryCardProvider = useSelector(primaryCardProviderSelector);
  const supportedCardTypes = primaryCardProvider?.config?.supportedCardTypes;
  const total = useSelector(peopleTotalSelector);
  const isVirtualKeyPresent = supportedCardTypes?.find(
    (cardType) => cardType === CARD_TYPE.VIRTUAL
  );

  const status =
    tab === COMPANY_PEOPLE_TAB_PARAMS.ALL
      ? [
          COMPANY_PEOPLE_USER_STATUS.ACTIVE,
          COMPANY_PEOPLE_USER_STATUS.PENDING,
          COMPANY_PEOPLE_USER_STATUS.SUBMITTED,
          COMPANY_PEOPLE_USER_STATUS.REJECTED,
          COMPANY_PEOPLE_USER_STATUS.FREEZED,
        ]
      : [COMPANY_PEOPLE_USER_STATUS.BLOCKED];

  const invitation_status =
    tab === COMPANY_PEOPLE_TAB_PARAMS.ALL ? [INVITATION_STATUS.ACCEPTED] : null;

  const data = useSelector(peopleSelector);

  const isEmpty = data.length === 0;

  const filterOptions = {
    tab,
    ...convertFilters(appliedFilters),
  };

  const onReset = () => {
    dispatch(resetPeopleStoreState());
  };

  const loadMore = () => {
    dispatch(
      fetchPeople({
        status,
        invitation_status,
        order_by_name: true,
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...filterOptions,
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <PeopleTableSearchAndFilter
          filters={filters}
          context={FILTER_TRIGGER_CONTEXT.PAGE}
        />

        <Export
          totalExports={total}
          storeName="user"
          sectionName="user"
          selectedFilter={appliedFilters}
          additionalFilters={{
            status,
            invitation_status,
            tab,
            ...convertFilters(appliedFilters),
            ...EXPORT_PAGE_TYPE.USER,
          }}
        />
      </div>

      <Table
        headerSticky
        numberOfStickyColsLeft={tab === COMPANY_PEOPLE_TAB_PARAMS.ALL ? 1 : 0}
        colWidths={
          tab === COMPANY_PEOPLE_TAB_PARAMS.ALL
            ? [300, 200, 200, 200, 200, 200, 200]
            : [300, 200, 200, 200, 200]
        }
      >
        <tr className="text-xs text-center text-neutral-800 bg-neutral-50">
          <th className="font-semibold text-left">
            <Text translationKey="company.people.peopleTable.name" />
          </th>

          <th className="font-semibold text-left">
            <Text translationKey="company.people.peopleTable.role" />
          </th>
          {tab === COMPANY_PEOPLE_TAB_PARAMS.ALL ? (
            <th className="font-semibold text-left">
              <Text translationKey="company.people.peopleTable.department" />
            </th>
          ) : null}
          {tab === COMPANY_PEOPLE_TAB_PARAMS.ALL ? (
            <th className="font-semibold text-left">
              <Text translationKey="company.people.peopleTable.location" />
            </th>
          ) : null}
          {isCardsEnabled ? (
            <th className="font-semibold text-center vp-core-table-cell vp-core-table-header-cell">
              <Text translationKey="company.people.peopleTable.physicalCard" />
            </th>
          ) : null}

          {isVirtualKeyPresent && isCardsEnabled ? (
            <th className="font-semibold text-center vp-core-table-cell vp-core-table-header-cell">
              <Text translationKey="company.people.peopleTable.virtualCard" />
            </th>
          ) : null}

          <th className="font-semibold text-center">
            <Text translationKey="company.people.peopleTable.spends" />
          </th>
        </tr>

        {!isEmpty || !isFetching
          ? data?.map((val, index, arr) => (
              <tr
                className="text-sm font-semibold text-center cursor-pointer text-neutral-800"
                key={`people-${index}`}
                ref={(ref) => {
                  if (index === arr.length - 1 && hasMore) {
                    handleRefChange(ref);
                  }
                }}
                onClick={() => {
                  searchParams.set(
                    SLIDERS_SEARCH_PARAMS.company.people.id,
                    val?.id
                  );
                  setSearchParams(searchParams);
                }}
              >
                <td className="text-left">
                  <div>
                    {![
                      COMPANY_PEOPLE_USER_STATUS.FREEZED,
                      COMPANY_PEOPLE_USER_STATUS.LOCKED,
                    ].includes(val?.status) ? (
                      <ProfileWidget
                        name={val.displayName || "-"}
                        avatarSize="sm"
                        img={val.avatarUrl}
                        avatarColor={val.colorCode}
                        textClasses="font-semibold"
                      >
                        <Text
                          translationKey={val?.email}
                          showTooltip
                          classes="text-neutral-500 text-xs font-medium truncate"
                        />
                      </ProfileWidget>
                    ) : (
                      <div className="flex items-center gap-3">
                        <div className="bg-neutral-100 rounded-full p-2.5 w-fit">
                          <Icon
                            name={
                              COMPANY_PEOPLE_USER_STATUS.FREEZED === val?.status
                                ? "Snowflake"
                                : "Lock"
                            }
                            className="w-4 h-4 text-neutral-500"
                          />
                        </div>
                        <div className="flex flex-col">
                          <Text
                            translationKey={val?.displayName}
                            classes="text-neutral-800 text-sm font-semibold "
                          />
                          <Text
                            translationKey={val?.email}
                            classes="text-neutral-500 text-xs font-medium  "
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </td>
                <td className="text-left lowercase first-letter:capitalize">
                  <Text
                    translationKey={val?.role[0]}
                    classes="text-sm font-semibold text-neutral-800"
                  />
                </td>
                {tab === COMPANY_PEOPLE_TAB_PARAMS.ALL ? (
                  <td className="text-left first-letter:capitalize">
                    <div className="flex flex-row items-center text-sm font-semibold text-neutral-800">
                      <Text
                        translationKey={val?.departmentName || "-"}
                        classes="truncate"
                      />
                    </div>
                  </td>
                ) : null}
                {tab === COMPANY_PEOPLE_TAB_PARAMS.ALL ? (
                  <td className="text-left first-letter:capitalize">
                    <div className="flex flex-row items-center text-sm font-semibold text-neutral-800 ">
                      <Text
                        translationKey={val?.locationName || "-"}
                        classes="truncate"
                      />
                    </div>
                  </td>
                ) : null}

                {isCardsEnabled ? (
                  <td className="text-center">
                    <PhysicalCardIcon
                      status={val?.physicalCardStatus}
                      index={index}
                    />
                  </td>
                ) : null}

                {isVirtualKeyPresent && isCardsEnabled ? (
                  <td className="text-center">
                    {val?.virtualCards ?? (
                      <EmptyRow
                        toolTipPosition="top"
                        toolTipText="company.people.peopleTable.noVirtualCard"
                        index={index}
                      />
                    )}
                  </td>
                ) : null}

                <td className="text-center">
                  <Text translationKey={val?.totalSpend} />
                </td>
              </tr>
            ))
          : null}

        {isFetching ? (
          <PeopleTableLoader isCardsEnabled={isCardsEnabled} type={tab} />
        ) : null}
      </Table>
    </div>
  );
}

PeopleTable.propTypes = {
  tab: PropsTypes.string,
};
