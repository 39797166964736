import PropTypes from "prop-types";
import { useState } from "react";

// utils, constant file imports

import { val } from "@/components/core/LoaderSkeleton/stories";
// core components
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import {
  INTEGRATION_CURRENCY_OPTIONS,
  INTEGRATION_CURRENCY_TYPE,
} from "@/utils/constants/integrations";

export default function CurrencyComponent({ name, handleChange, value }) {
  const currencyOptions = INTEGRATION_CURRENCY_OPTIONS;

  return (
    <div className="flex flex-col gap-4">
      <SliderHeader
        heading="accounting.integrations.currencyOptions.title"
        subText="accounting.integrations.currencyOptions.description"
        headingClasses="text-lg font-semibold text-neutral-800"
        subTextClasses="text-sm font-medium text-neutral-500"
      />

      {currencyOptions.map((item, index) => (
        <div
          className="flex h-full  gap-4 p-6 rounded-lg border border-neutral-200"
          key={`currency-option-${index}`}
        >
          <div className="flex h-3/4 items-center">
            <Radio
              name={name}
              value={item.value}
              handleChange={handleChange}
              isChecked={value === item.value}
              insideForm
            />
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 items-center">
              <Text
                translationKey={item.title}
                classes="text-lg flex font-semibold text-neutral-800"
              />
              {item.value === INTEGRATION_CURRENCY_TYPE.MULTI ? (
                <Text
                  translationKey="accounting.integrations.labels.recommended"
                  classes="text-xs font-bold border border-primary-200 rounded-full px-2 py-0 text-primary-400 bg-primary-50"
                />
              ) : null}
            </div>
            <Text
              translationKey={item.description}
              classes="text-sm font-medium text-neutral-500 "
            />
          </div>
        </div>
      ))}
    </div>
  );
}

CurrencyComponent.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
};
