import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import CopytoClipboard from "@/components/core/CopyToClipboard";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function CardDetail({
  nameKey, // label text
  value, // value text

  noLink = false, // show/hide link (arrow icon)
  linkTo = "",
  onLinkClick = () => {},

  nameTooltipText = "",
  valueTooltipText = "",
  copy = false,
  id = "",
}) {
  return (
    <div className="p-4 flex flex-col border border-neutral-200 rounded-[theme(spacing.2)]">
      <div className="flex items-center gap-1">
        <Text
          translationKey={nameKey}
          classes="text-sm text-neutral-500 font-semibold h-6 flex items-center"
        />
        {nameTooltipText && (
          <span id={nameKey}>
            <Icon
              name="Info"
              className="text-neutral-500 p-[1.5px] h-5 w-5 hover:cursor-pointer"
            />
            <Tooltip id={nameKey} direction="bottom">
              <Text
                translationKey={nameTooltipText}
                classes="text-sm font-medium text-neutral-800"
              />
            </Tooltip>
          </span>
        )}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span
            className="overflow-hidden text-base font-semibold line-clamp-1 text-neutral-800"
            id={`tooltip-${id}`}
          >
            {value}
          </span>

          <Tooltip id={`tooltip-${id}`} direction="bottom">
            <Text
              translationKey={value}
              classes="text-sm font-medium text-neutral-800"
            />
          </Tooltip>

          {noLink ? null : (
            <Link to={linkTo} onClick={onLinkClick}>
              <Icon
                name="ToLink"
                className="text-primary-500 hover:cursor-pointer"
              />
            </Link>
          )}
        </div>
        {copy ? <CopytoClipboard text={value} /> : null}
      </div>
    </div>
  );
}

CardDetail.propTypes = {
  nameKey: PropTypes.string,
  value: PropTypes.string,

  noLink: PropTypes.bool,
  linkTo: PropTypes.string,
  onLinkClick: PropTypes.func,

  nameTooltipText: PropTypes.string,
  valueTooltipText: PropTypes.string,
  copy: PropTypes.bool,
  id: PropTypes.string,
};
