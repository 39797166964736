import { createSelector } from "@reduxjs/toolkit";

export const myVolopayStore = (store) => store.myVolopay;

export const gettingStartedListSelector = createSelector(
  myVolopayStore,
  (myVolopay) => myVolopay.gettingStartedDetailsList
);
export const isFetchingMyVolopaySelector = createSelector(
  myVolopayStore,
  (myVolopay) => myVolopay.isFetching
);
export const helpCenterListSelector = createSelector(
  myVolopayStore,
  (myVolopay) => myVolopay.helpCenterDetailsList
);
export const bannerDetailsSelector = createSelector(
  myVolopayStore,
  (myVolopay) => myVolopay.bannerDetails
);
