import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

function CategoryPointersSection({
  categoryMainHeading,
  categoryDescription,
  childrenClasses,
  mainSectionClasses,
  children,
  count,
  showPointersSection = true,
}) {
  return showPointersSection ? (
    <div className={`card-wrapper ${mainSectionClasses}`}>
      <div className="flex flex-row gap-4">
        <Text
          translationKey={categoryMainHeading}
          classes="text-base font-semibold"
        />

        {count ? (
          <div className="flex flex-row items-center justify-center w-6 h-6 rounded-lg bg-neutral-500">
            <Text translationKey={count} classes="text-white" />
          </div>
        ) : null}
      </div>

      <Text
        translationKey={categoryDescription}
        classes="font-semibold text-xs text-neutral-500"
      />

      {children ? (
        <div
          className={`flex flex-col justify-center gap-4 p-3 mt-6 rounded-lg ${
            childrenClasses?.search("bg") ? "" : "bg-neutral-50"
          } ${childrenClasses}`}
        >
          {children}
        </div>
      ) : null}
    </div>
  ) : null;
}

CategoryPointersSection.propTypes = {
  categoryMainHeading: PropTypes.string,
  categoryDescription: PropTypes.string,
  childrenClasses: PropTypes.string,
  mainSectionClasses: PropTypes.string,
  children: PropTypes.node,
  count: PropTypes.number,
  showPointersSection: PropTypes.bool,
};

export default CategoryPointersSection;
