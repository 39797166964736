import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";

import { defaultCurrencySelector } from "@/store/selectors/client";

import EmptyData from "@/components/core/EmptyData";
import Filters from "@/components/core/Filters";
import Text from "@/components/core/Text";

import { DEPARTMENT, LOCATION, PROJECT } from "@/constants/company";

import LocationProjectAndDepartmentLoader from "./LocationProjectAndDepartmentLoader";
import LocationProjectAndDepartmentRow from "./LocationProjectAndDepartmentRow";

function LocationProjectAndDepartmentList({
  isEmpty,
  hasMore,
  isFetching,
  handleRefChange,
  data = [],
  type,
  filters,
}) {
  const emptyDataConfig = () => {
    switch (type) {
      case DEPARTMENT:
        return {
          title:
            "company.department.departmentDetails.tabs.budgets.departmentEmptyTitle",
          desc: "company.department.departmentDetails.tabs.budgets.departmentEmptyDescription",
        };
      case PROJECT:
        return {
          title:
            "company.department.departmentDetails.tabs.budgets.projectEmptyTitle",
          desc: "company.department.departmentDetails.tabs.budgets.projectEmptyDescription",
        };
      case LOCATION:
        return {
          title:
            "company.department.departmentDetails.tabs.budgets.locationEmptyTitle",
          desc: "company.department.departmentDetails.tabs.budgets.locationEmptyDescription",
        };

      default:
        return null;
    }
  };

  const defaultCurrency = useSelector(defaultCurrencySelector);

  const departmentOrProjectState = useLoadingErrorInjector({
    apiKey: "Company:Project:all",
    showLoader: true,
    error: {
      header: "",
    },
  });
  const locationState = useLoadingErrorInjector({
    apiKey: "Company:Location:all",
    showLoader: true,
    error: {
      header: "",
    },
  });

  const state =
    type === DEPARTMENT || type === PROJECT
      ? departmentOrProjectState
      : locationState;

  return (
    <>
      <div className="mb-3">
        <Filters filters={filters} />
      </div>
      <div className="flex flex-col gap-4 pb-9" ref={state?.attach}>
        {!isEmpty || !isFetching
          ? data?.map((department, index, arr) => (
              <LocationProjectAndDepartmentRow
                key={department?.id}
                isLastRow={index === arr.length - 1}
                hasMore={hasMore}
                handleRefChange={handleRefChange}
                data={department}
                currency={defaultCurrency}
                type={type}
                id={department?.id}
              />
            ))
          : null}
        {isFetching ? <LocationProjectAndDepartmentLoader /> : null}
        {isEmpty && !isFetching ? (
          <div className="pt-12 text-neutral-800">
            <EmptyData title={emptyDataConfig()?.title}>
              <Text
                classes="font-medium text-neutral-500 text-sm"
                translationKey={emptyDataConfig()?.desc}
              />
            </EmptyData>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default LocationProjectAndDepartmentList;

LocationProjectAndDepartmentList.propTypes = {
  isEmpty: PropTypes.bool,
  hasMore: PropTypes.bool,
  isFetching: PropTypes.bool,
  handleRefChange: PropTypes.func,
  data: PropTypes.array,
  type: PropTypes.string,
  filters: PropTypes.array,
};
