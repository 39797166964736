import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
  transactionLevelTagsSetSelector,
} from "@/store/selectors/client";
import { billPayrollCreationSubmissionPolicySelector } from "@/store/selectors/purchase-bills";
import {
  accountingCategoryTagSelector,
  accountingTagsSelector,
  billPayCustomTagsSelector,
  payrollCustomTagsSelector,
} from "@/store/selectors/tags";

import Icon from "@/components/core/Icon";

import {
  LINE_ITEM_KEY,
  LINE_ITEM_KEYS,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import AccountingCategoryInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/AccountingCategoryInput";
import TagInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/TagInput";

import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";

export default function TagsForLineItemListEntry({
  values,
  errors,
  handleChange,
  setValues,
  id = 0,
  inPayrollContext,
}) {
  const { t } = useTranslation();
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );
  const submissionPolicy = useSelector(
    billPayrollCreationSubmissionPolicySelector
  );
  const submissionPolicyTags = useMemo(
    () => [
      ...(accountingEnabled ? (submissionPolicy?.accountingTags ?? []) : []),
      // ignore accountingTags is software is not integrated.
      ...(submissionPolicy?.customTags ?? []),
    ],
    [submissionPolicy, accountingEnabled]
  );
  const submissionPolicyMap = useMemo(
    () =>
      submissionPolicyTags?.length
        ? submissionPolicyTags?.reduce(
            (acc, curr) => ({ ...acc, [curr.tagId]: curr }),
            {}
          )
        : {},
    [JSON.stringify(submissionPolicyTags)]
  );
  const _accountingCategoryTag = useSelector(accountingCategoryTagSelector);
  const _accountingTags = useSelector(accountingTagsSelector);
  const __customTags = useSelector((state) =>
    inPayrollContext
      ? payrollCustomTagsSelector(state)
      : billPayCustomTagsSelector(state)
  );
  const _customTags = useMemo(
    () =>
      __customTags?.map((tag) => {
        const submissionPolicyOfTag = submissionPolicyMap?.[tag?.id];
        if (!submissionPolicyOfTag?.required)
          return { ...tag, required: false };
        if (submissionPolicyOfTag?.required && !submissionPolicyOfTag?.amount)
          return { ...tag, required: true };

        return {
          ...tag,
          required:
            parseFloat(submissionPolicyOfTag?.amount || 0) <
            parseFloat(
              values?.[`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.AMOUNT}`] || 0
            ),
        };
      }),
    [
      JSON.stringify(submissionPolicyMap),
      JSON.stringify(__customTags),
      values?.[`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.AMOUNT}`],
    ]
  );

  const netSuiteTagsSet = useSelector(transactionLevelTagsSetSelector);
  //
  const accountingCategoryTag = useMemo(
    () =>
      netSuiteTagsSet.has(_accountingCategoryTag?.id)
        ? null
        : _accountingCategoryTag,
    [_accountingCategoryTag, netSuiteTagsSet]
  );
  const accountingTags = useMemo(
    () => _accountingTags.filter((tag) => !netSuiteTagsSet.has(tag.id)),
    [
      _accountingTags,
      netSuiteTagsSet,
      values?.[`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.AMOUNT}`],
    ]
  );
  const customTags = useMemo(
    () => _customTags.filter((tag) => !netSuiteTagsSet.has(tag.id)),
    [_customTags, netSuiteTagsSet]
  );
  // for making sure accounting category tag comes first
  const tagRows = useMemo(() => {
    const tagRowsToBeReturned = [];

    const totalTags = accountingEnabled
      ? [
          accountingCategoryTag,
          ...accountingTags.filter(
            (accTag) => accTag.id !== accountingCategoryTag?.id
          ),
          ...customTags,
        ]
      : [...customTags];

    totalTags
      ?.filter((item) => !!item)
      ?.forEach((tag, idx) => {
        if (!tag) return; // coz it was already included in the first row

        const startNewRow = idx % 2 === 0;

        if (startNewRow) {
          tagRowsToBeReturned.push([tag]); // new row
        } else {
          tagRowsToBeReturned.at(-1).push(tag); // insert in last half filled row
        }
      });

    return tagRowsToBeReturned;
  }, [
    accountingCategoryTag,
    accountingTags,
    accountingEnabled,
    accountingCategoryTag,
  ]);

  return (
    <>
      {tagRows.map((row, rowIndex) =>
        row.map((tag, colIndex) => (
          <div
            key={tag.id}
            className={`${
              rowIndex === 0 ? "" : "mt-3"
            } flex items-center gap-2 ${row.length === 1 ? "col-span-2" : ""}`}
          >
            {colIndex === 0 ? (
              <span className="grid w-6 h-6 place-items-center text-neutral-300">
                <Icon name="SubdirectoryArrowRight" />
              </span>
            ) : null}
            {tag.id === accountingCategoryTag?.id ? (
              <AccountingCategoryInput
                dropdownInputKey={`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.ACCCOUNTING_TAGS}.tag-id-${tag.id}`}
                textInputKey={`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.ACCCOUNTING_TAGS}.tag-id-${tag.id}`}
                values={values}
                errors={errors}
                handleChange={handleChange}
                setValues={setValues}
                vpSelectProps={{
                  label: `${
                    ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                      accountingIntegrationSoftware
                    ]
                  } ${t(
                    "billPay.bill.invoiceInbox.createBill.sections.lineItems.category"
                  )}`,
                  labelStyleClasses: "font-semibold text-xs text-neutral-500",
                  placeholder: `${t(
                    "billPay.bill.invoiceInbox.createBill.sections.lineItems.select"
                  )} ${
                    ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                      accountingIntegrationSoftware
                    ]
                  } ${t(
                    "billPay.bill.invoiceInbox.createBill.sections.lineItems.category"
                  )}`,
                  helperText: "",
                  classes: "w-full",
                  clearable: true,
                }}
              />
            ) : (
              <TagInput
                key={tag?.id}
                dropdownInputKey={`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.ACCCOUNTING_TAGS}.tag-id-${tag.id}`}
                textInputKey={`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.ACCCOUNTING_TAGS}.tag-id-${tag.id}`}
                values={values}
                errors={errors}
                handleChange={handleChange}
                callTags={false}
                setValues={setValues}
                tag={tag}
                vpSelectProps={{
                  labelStyleClasses: "font-semibold text-xs text-neutral-500",
                  classes: "w-full",
                  clearable: true,
                }}
              />
            )}
          </div>
        ))
      )}
    </>
  );
}

TagsForLineItemListEntry.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
