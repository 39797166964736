import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { requestCollection } from "@/store/reducers/account";
import { fetchClient } from "@/store/reducers/client";

import { isRequestingCollectionSelector } from "@/store/selectors/account";
import { paymentProviderTypeSelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import PaymentOptionIcon from "@/components/Company/Overview/AccountOverview/PaymentOptionIcon";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  PAYMENT_OPTION_TYPE,
  PAYMENT_PROVIDER_TYPE,
} from "@/constants/company";

export default function FundAccountSlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currency = searchParams.get(SLIDERS_SEARCH_PARAMS.company.fundAccount);
  const paymentType = useSelector(paymentProviderTypeSelector);
  const isRequesting = useSelector(isRequestingCollectionSelector);
  const dispatch = useDispatch();

  const triggerSlider = (type) => {
    searchParams.append([SLIDERS_SEARCH_PARAMS.company.paymentOption], type);
    setSearchParams(searchParams);
  };

  const onSuccess = () => {
    dispatch(fetchClient());
  };
  const requestVACollection = () => {
    dispatch(requestCollection({ onSuccess }));
  };
  const transfer = [
    {
      icon: "AddBusiness",
      title: "company.addFund.transferFunds",
      description: "company.addFund.direct2Account",
      buttonLabel: "company.addFund.addFunds",
      bgClassName: "text-integrations-500 bg-integrations-50",
      className: "text-integrations-500 bg-integrations-50 w-5 h-5",
      paymentOption: PAYMENT_OPTION_TYPE.ADDFUND,
      buttonClick: () => triggerSlider(PAYMENT_OPTION_TYPE.ADDFUND),
    },
  ];

  const [transferBoxes, setTransferBoxes] = useState(transfer);

  const collectionAcc = [
    {
      flag: true,
      title: "company.addFund.localAccountDetails",
      description: "company.addFund.localAccountDesc",
      buttonLabel: "company.addFund.viewLocalDetails",
      paymentOption: PAYMENT_OPTION_TYPE.LOCAL,
      buttonClick: () => triggerSlider(PAYMENT_OPTION_TYPE.LOCAL),
    },
    {
      icon: "World",
      title: "company.addFund.swiftAccountDetails",
      description: "company.addFund.swiftAccountDesc",
      buttonLabel: "company.addFund.viewSWIFTDetails",
      bgClassName: "text-primary-500 bg-primary-100",
      className: "text-primary-500 bg-primary-100 w-6 h-6",
      paymentOption: PAYMENT_OPTION_TYPE.SWIFT,
      buttonClick: () => triggerSlider(PAYMENT_OPTION_TYPE.SWIFT),
    },
  ];

  const cardBuilder = (acc) => (
    <div
      className="flex items-center justify-between mb-4 card-wrapper bg-neutral-50 border-neutral-50"
      key={`${currency}-${acc.paymentOption}`}
    >
      <div className="flex">
        <PaymentOptionIcon
          currency={currency}
          paymentOptionType={acc.paymentOption}
        />
        <div className="flex flex-col ml-3">
          <Text classes="text-sm font-semibold" translationKey={acc.title} />
          <Text
            classes="font-semibold text-neutral-500"
            translationKey={acc.description}
            translationProps={{ currency }}
          />
        </div>
      </div>
      <Button
        label={acc.buttonLabel}
        variant="tertiary"
        classes="w-fit h-8 px-5 py-1 text-xs font-bold"
        onClick={() => acc.buttonClick()}
      />
    </div>
  );
  useEffect(() => {
    if (currency === "SGD") {
      const paynow = {
        icon: "PayNow",
        title: "common.payNow",
        description: "company.addFund.payCode",
        buttonLabel: "company.addFund.viewQrCode",
        paymentOption: PAYMENT_OPTION_TYPE.PAYNOW,
        buttonClick: () => triggerSlider(PAYMENT_OPTION_TYPE.PAYNOW),
      };

      setTransferBoxes([...transferBoxes, paynow]);
    }
  }, []);

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        <div aria-label="add-funds-remaining-slider-section">
          <div className="flex flex-col gap-1 mb-8">
            <div className="flex items-center gap-2">
              <Text
                classes="font-bold text-3xl text-neutral-800"
                translationKey="company.addFund.title"
              />
              <Chip
                classes="bg-success-50 text-success-600 border border-success-200"
                label="company.accountOverview.account"
                labelTranslationProps={{ currency }}
              />
            </div>
            <Text
              classes="text-sm text-neutral-500"
              translationKey="company.addFund.description"
            />
          </div>
          <Text
            translationKey="company.addFund.addFunds"
            classes="text-lg text-neutral-500 font-bold block mt-8 mb-6"
          />
          {transferBoxes.map((acc) => cardBuilder(acc))}
          <Text
            translationKey="company.addFund.receiveFunds"
            classes="text-lg text-neutral-500 font-bold block mt-8 mb-6"
          />
          {paymentType === PAYMENT_PROVIDER_TYPE.COLLECTION_VA ? (
            collectionAcc.map((acc) => cardBuilder(acc))
          ) : (
            <div className="flex flex-col card-wrapper bg-primary-50 border-primary-50">
              <div className="flex justify-between mb-4">
                <Icon
                  id="business"
                  variant="rounded"
                  name="AddBusiness"
                  bgClassName="text-white bg-primary-400"
                  className="w-5 h-5 text-white bg-primary-400"
                />
                <Text
                  classes="font-semibold ml-3 w-11/12"
                  translationKey="company.addFund.getAccountDetail"
                  translationProps={{ currency }}
                />
              </div>
              <div>
                <div className="flex items-center my-4">
                  <Icon
                    name="CaptivePortal"
                    className="w-5 h-5 text-primary-400"
                  />
                  <Text
                    classes="text-xs ml-3"
                    translationKey="company.addFund.getAccountDesc1"
                  />
                </div>
                <div className="flex items-center my-4">
                  <Icon name="Toll" className="text-primary-400 w-7 h-7" />
                  <Text
                    classes="text-xs ml-3"
                    translationKey="company.addFund.getAccountDesc2"
                  />
                </div>
                <div className="flex items-center my-4">
                  <Icon
                    name="SavingsFill"
                    className="text-primary-400 w-7 h-7"
                    id="saving"
                  />
                  <Text
                    classes="text-xs ml-3"
                    translationKey="company.addFund.getAccountDesc3"
                  />
                </div>
              </div>
              <div className="flex items-center mt-6">
                <Button
                  label="company.addFund.requestCollection"
                  variant="primary"
                  classes="w-fit h-8 px-5 py-1 text-xs"
                  disabled={
                    paymentType ===
                    PAYMENT_PROVIDER_TYPE.COLLECTION_VA_REQUESTED
                  }
                  showLoader={isRequesting}
                  onClick={() => requestVACollection()}
                />
                {paymentType ===
                PAYMENT_PROVIDER_TYPE.COLLECTION_VA_REQUESTED ? (
                  <>
                    <Icon
                      name="Info"
                      className="w-5 h-5 ml-2 text-neutral-500"
                    />
                    <Text
                      classes="text-xs ml-1 text-neutral-400"
                      translationKey="company.addFund.requestCollectionPending"
                    />
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
