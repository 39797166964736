import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import EmptyData from "@/components/core/EmptyData";
import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Text from "@/components/core/Text";

import PeopleList from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/PeopleList";

import SearchBar from "./SearchBar";

function PaginatedPeopleTable({
  people,
  inEditMode,
  limit,
  disabledDeleteButton = false,
  disableTooltip,
  handleEmptyBtnClick,
  emptyBtnLabel,
  emptyDescription,
  emptyLabel,
  handleDelete = () => {},
  otherComponents = [],
  hideEmptyBtn,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const prevPageHandler = () => {
    setCurrentPage((prev) => prev - 1);
  };
  const nextPageHandler = () => {
    setCurrentPage((prev) => prev + 1);
  };
  const _people =
    people?.length >= 0
      ? people?.filter((item) =>
          item?.name
            ? item?.name?.toLowerCase()?.includes(search?.toLowerCase())
            : item
        )
      : [];
  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const currentPageDisabled = limit * currentPage > _people.length - 1;
  const prevPageDisabled = currentPage === 1;
  const startPage = (currentPage - 1) * limit;
  const endPage = currentPage * limit;
  const filteredPeople = _people?.slice(startPage ?? 0, endPage ?? limit);
  const isEmpty = _people?.length === 0;

  const searchedArrayEmpty =
    filteredPeople?.filter((item) =>
      item.name?.toLowerCase()?.includes(search?.toLowerCase())
    )?.length === 0;
  return (
    <>
      {inEditMode ? null : (
        <SearchBar
          classes="mt-5"
          searchQuery={search}
          handleChange={(e) => setSearch(e.target.value)}
        />
      )}
      {isEmpty || searchedArrayEmpty ? (
        <div className="mt-5">
          <EmptyData
            classes="pt-9"
            buttonLabel={
              hideEmptyBtn ||
              (!isEmpty && searchedArrayEmpty) ||
              inEditMode ||
              search?.length
                ? null
                : emptyBtnLabel
            }
            title={emptyLabel}
            buttonProps={{
              preIcon: "Plus",
              compact: true,
              classes: "px-5 py-3",
            }}
            handleClick={handleEmptyBtnClick}
          >
            <Text
              classes="font-medium text-neutral-500 text-sm"
              translationKey={emptyDescription}
            />
          </EmptyData>
        </div>
      ) : (
        <>
          <div className="mt-5 limit card-wrapper">
            <PeopleList
              list={filteredPeople?.map((user) => ({
                itemId: user.id,
                deletable: true,
                otherComponents: otherComponents?.map((Item) => {
                  const Component = Item.component;
                  return (
                    <Component
                      handleClick={Item?.handleClick}
                      disabled={Item?.checkDisable(user.id)}
                      id={user.id}
                      key={`people-${user.id}`}
                    />
                  );
                }),
                itemData: {
                  id: user.id,
                  role: user.role,
                  displayName: user.name,
                  avatarUrl: user.avatarUrl,
                  location: user.locationName,
                  departmentName: user.departmentName,
                  colorCode: user?.colorCode,
                },
              }))}
              onDeleteHandler={handleDelete}
              disabledDeleteButton={disabledDeleteButton}
              tooltipText={
                disableTooltip ||
                "company.department.addNewDepartment.atleastOneDepartmentManagerTooltip"
              }
              classes=""
              titleText=""
              readOnly={!inEditMode}
            />
          </div>
          <StepPaginationButtons
            currentPage={currentPage}
            total={_people?.length}
            limit={limit}
            handleLeftButton={prevPageHandler}
            handleRightButton={nextPageHandler}
            leftDisabled={prevPageDisabled}
            rightDisabled={currentPageDisabled}
          />
        </>
      )}
    </>
  );
}

export default PaginatedPeopleTable;
PaginatedPeopleTable.propTypes = {
  people: PropTypes.array,
  inEditMode: PropTypes.bool,
  limit: PropTypes.number,
  handleEmptyBtnClick: PropTypes.func,
  emptyBtnLabel: PropTypes.string,
  emptyDescription: PropTypes.string,
  emptyLabel: PropTypes.string,
  handleDelete: PropTypes.func,
  otherComponents: PropTypes.array,
  disabledDeleteButton: PropTypes.bool,
  disableTooltip: PropTypes.string,
  hideEmptyBtn: PropTypes.bool,
};
