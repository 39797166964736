import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchCountries } from "@/store/reducers/app";
import {
  fetchAndSelectVendor,
  fetchVendorCreateRequirements,
  fetchVendorPaymentMethods,
  mergeIntoVendorCreateForm,
  setSelectedVendor,
  setVendorCreateForm,
  setVendorCreateRequirements,
} from "@/store/reducers/vendors";

import { countriesSelector } from "@/store/selectors/app";
import { currentPaymentProviderSelector } from "@/store/selectors/client";
import { selectedUserSelector } from "@/store/selectors/user";
import {
  bankDetailsFormSelector,
  isFetchingVendorPaymentMethodsSelector,
  selectedVendorSelector,
  vendorCreateFormSelector,
  vendorPaymentMethodsSelector,
} from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import {
  formValueFromExactValue,
  generatePayloadFromFormValue,
} from "@/components/GenericForm/common";
import BankDetailsBasedOnProvider from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/BankDetailsBasedOnProvider";
import vToast from "@/utils/vToast";
import { useForm } from "@/utils/useForm";
import { MAIL_CONTEXT } from "@/utils/constants/common";
import { capitalizeFirstLetter, getErrorToastMessage } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { VENDOR_CLASS } from "@/constants/common";
import { CURRENCY } from "@/constants/currency";
import { PAYMENT_PROVIDERS } from "@/constants/provider";
import { OWNER_TYPE_USER } from "@/constants/user";
import {
  VENDOR_CREATE_FINAL_PAYLOAD_PARAMS,
  VENDOR_CREATE_PARAMS,
  VENDOR_CREATE_REQUIREMENTS_PARAMS,
  VENDOR_TYPES,
} from "@/constants/vendors";

/**
 * aka 'Pre add bank detail slider'
 * This page is a simple form, that takes fields that are needed by requirements API
 * The next page (on submit) navigates to the generic form (see AddBankDetails)
 *
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=10164-238322&mode=dev}
 */
export default function AddBankDetailSlider({
  vendorClass,
  nextSlider,
  context,
  setOnBack,
  setOnClose,
  searchParamKey,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const vendorDetailsFromForm = useSelector(vendorCreateFormSelector);
  const currentPaymentProvider = useSelector(currentPaymentProviderSelector);
  const bankDetailsForm = useSelector(bankDetailsFormSelector);
  const paymentMethods = useSelector(vendorPaymentMethodsSelector);
  const selectedUser = useSelector(selectedUserSelector);
  const isFetchingPaymentMethods = useSelector(
    isFetchingVendorPaymentMethodsSelector
  );

  const countries = useSelector(countriesSelector);

  const selectedVendor = useSelector(selectedVendorSelector);

  const inSingaporeWithWallex =
    currentPaymentProvider === PAYMENT_PROVIDERS.WALLEX;
  const isOverrideModeId =
    searchParams.get(SLIDERS_SEARCH_PARAMS.vendors.overrideEdit) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.employees.overrideEdit);
  const ref = useLeftHeaderTitle({
    title: "billPay.vendors.createVendor.addBankDetailsPage.title",
  });

  const initialFormValue = {
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]: {
      value: selectedVendor?.beneficiaryCountry ?? "",
      validate: { required: true },
    },

    [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]: {
      value: selectedVendor?.beneficiaryCurrency ?? "",
      validate: { required: true },
    },
    [VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_METHOD]: {
      value: selectedVendor?.paymentMethodType ?? "",
      validate: { required: true },
    },
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]: {
      value: selectedVendor?.beneficiaryBankCountry ?? "",
      validate: { required: true },
    },
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE]: {
      value: selectedVendor?.type,
      validate: { required: !!isOverrideModeId },
    },
  };

  const onSubmit = () => {
    // save main form
    // also important for auto-fill in generic based on previous FE forms

    const addToVendorForm = { ...values };

    if (vendorClass === VENDOR_CLASS.EMPLOYEE) {
      addToVendorForm[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.VENDOR_NAME] =
        selectedUser?.displayName;
      addToVendorForm[VENDOR_CREATE_PARAMS.OWNER_ID] = selectedUser.id;
      addToVendorForm[VENDOR_CREATE_PARAMS.OWNER_TYPE] =
        capitalizeFirstLetter(OWNER_TYPE_USER);
    }
    dispatch(
      mergeIntoVendorCreateForm(formValueFromExactValue(addToVendorForm, {}))
    );

    const requirementsPayload = generatePayloadFromFormValue({
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.PAYOUT_METHOD_TYPE]:
        values?.[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_METHOD],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.SENDER_CURRENCY]:
        values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.SENDER_CURRENCY],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]:
        values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]:
        values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]:
        values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY],
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE]:
        vendorClass === VENDOR_CLASS.USER_CREATED
          ? (values?.vendor_type ??
            vendorDetailsFromForm?.[
              VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE
            ]?.value)
          : VENDOR_TYPES.INDIVIDUAL,
      // to avoid any filtering of requirements (add below)
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_DETAIL_CONTROL_FIELD]: true,
      [VENDOR_CREATE_REQUIREMENTS_PARAMS.OTHER_DETAILS_CONTROL_FIELD]: true,
    });

    dispatch(
      fetchVendorCreateRequirements({
        payload: requirementsPayload,
        onFail: (err) => {
          dispatch(setVendorCreateRequirements(null));
          searchParams.delete(SLIDERS_SEARCH_PARAMS.vendors.addBankDetails);
          setSearchParams(searchParams);
          vToast(getErrorToastMessage(err));
        },
      })
    );

    searchParams.append(nextSlider, true);
    setSearchParams(searchParams);
  };

  const cancel = () => {
    searchParams.delete([
      SLIDERS_SEARCH_PARAMS.company.people.preAddBankDetails,
    ]);
    searchParams.delete([SLIDERS_SEARCH_PARAMS.company.people.edit]);
    searchParams.delete([SLIDERS_SEARCH_PARAMS.company.people.id]);
    setSearchParams(searchParams);
  };

  const {
    handleChange,
    values,
    errors,
    _setValues: setValues,
    isFormButtonDisabled,
    handleSubmit,
  } = useForm(initialFormValue, onSubmit, {
    isFetchingInitialValue: false,
    formId: "add-user-bank-details",
  });

  useEffect(() => {
    if (currentPaymentProvider === PAYMENT_PROVIDERS.DECENTRO) {
      setValues((prev) => ({
        ...prev,
        [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]:
          CURRENCY.INR.value,
        [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]:
          CURRENCY.INR.countryCode,
      }));
    }
  }, [currentPaymentProvider]);

  // fetch payment methods
  // declaring outside effect, to observe if all dependencies are present
  const paymentMethodsPayload = generatePayloadFromFormValue({
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]:
      values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY],
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]:
      values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY],
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE]: VENDOR_TYPES.INDIVIDUAL,
  });
  useEffect(() => {
    if (!context) return;

    if (context !== MAIL_CONTEXT && !paymentMethods?.length) {
      dispatch(
        fetchVendorPaymentMethods({
          payload: paymentMethodsPayload,
        })
      );

      if (!countries?.length) dispatch(fetchCountries());
    }
  }, [context, paymentMethods]);

  const { title, description } = (() => {
    switch (vendorClass) {
      case VENDOR_CLASS.USER_CREATED:
        return {
          title: "company.people.peopleMyProfileSlider.addBankDetailsTitle",
          description:
            "billPay.vendors.createVendor.addBankDetailsPage.description",
        };
      case VENDOR_CLASS.PAYROLL:
        return {
          title: "company.people.peopleMyProfileSlider.addBankDetailsTitle",
          description:
            "billPay.vendors.createVendor.addBankDetailsPage.descriptionEmployee",
        };
      case VENDOR_CLASS.EMPLOYEE:
        return {
          title: "company.people.peopleMyProfileSlider.addBankDetailsTitle",
          description:
            "billPay.vendors.createVendor.addBankDetailsPage.descriptionEmployee",
        };

      default: {
        return {
          title: "company.people.peopleMyProfileSlider.addBankDetailsTitle",
          description:
            "billPay.vendors.createVendor.addBankDetailsPage.description",
        };
      }
    }
  })();

  // in override flow, on close move directly back to vendor/employee slider
  useEffect(() => {
    setOnBack(() => {
      searchParams.delete(searchParamKey); // usual

      if (vendorClass === VENDOR_CLASS.EMPLOYEE) {
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.company.people.editBankDetails
        );
      } else if (isOverrideModeId) {
        searchParams.delete(SLIDERS_SEARCH_PARAMS.vendors.overrideEdit);
        searchParams.delete(SLIDERS_SEARCH_PARAMS.employees.overrideEdit);
      }

      setSearchParams(searchParams);
    });
  }, []);

  // closing should close until id, or including create
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (
      searchParams.has(SLIDERS_SEARCH_PARAMS.vendors.create) ||
      searchParams.has(SLIDERS_SEARCH_PARAMS.employees.create) ||
      !urlSearchParams.has(searchParamKey)
    )
      return;

    setOnClose(() => {
      dispatch(setSelectedVendor(null));
      searchParams.delete(searchParamKey);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.vendors.edit);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.employees.edit);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.vendors.overrideEdit);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.employees.overrideEdit);
      setSearchParams(searchParams);
    });
  }, []);

  useEffect(() => {
    if (!selectedVendor && isOverrideModeId)
      dispatch(
        fetchAndSelectVendor({ id: isOverrideModeId, not_humanized: true })
        // not_humanized added for safety of data
      );
    else {
      setValues((prev) => ({
        ...prev,
        [VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE]:
          vendorDetailsFromForm?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE]
            ?.value ?? selectedVendor?.type,
      }));
    }
  }, [selectedVendor]);

  return (
    <>
      <div className="slider-content-core">
        <div className="flex flex-col mb-8">
          <Text
            refProp={ref}
            translationKey={title}
            classes="text-2xl font-bold text-neutral-800"
          />
          <Text
            translationKey={description}
            classes="mt-1 text-sm text-neutral-500 font-medium"
          />
        </div>
        <form onSubmit={handleSubmit} id="add-user-bank-details">
          <BankDetailsBasedOnProvider
            {...{
              values,
              errors,
              handleChange,
              paymentMethods,
              isFetchingPaymentMethods,
              setValues,
              context,
              inPayrollContext:
                vendorClass === VENDOR_CLASS.PAYROLL ||
                vendorClass === VENDOR_CLASS.EMPLOYEE,
            }}
            provider={currentPaymentProvider}
          />
        </form>
      </div>
      <div className="slider-footer-right-buttons slider-footer">
        <Button
          variant="tertiary"
          classes="px-5 py-3"
          label="company.people.peopleMyProfileSlider.cancel"
          labelStyleClasses="text-neutral-500 font-semibold"
          onClick={cancel}
          compact
        />
        <Button
          variant="primary"
          classes="px-5 py-3"
          label="misc.continue"
          labelStyleClasses="font-semibold"
          form="add-user-bank-details"
          btnType="submit"
          compact
          disabled={isFormButtonDisabled}
        />
      </div>
    </>
  );
}

AddBankDetailSlider.propTypes = {
  vendorClass: PropTypes.string,
  nextSlider: PropTypes.string,
  context: PropTypes.string,
  setOnBack: PropTypes.func,
  setOnClose: PropTypes.func,
  searchParamKey: PropTypes.string,
};
