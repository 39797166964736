import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { dateToString } from "@/utils/common";

/**
 * Display start and end date with icons
 *
 * {@link https://www.figma.com/file/8dGDeaCLPkt8bmg5aT9OvD/v2-Company?type=design&node-id=2067-69866&mode=dev}
 */
export default function StartEndDate({ startDate, endDate }) {
  return (
    <span className="flex items-center text-sm font-medium  text-neutral-500">
      {startDate ? (
        <>
          <Icon
            name="Calendar"
            className="inline-block w-4 h-4 text-neutral-400"
          />
          <Text translationKey="company.project.startDate" classes="ml-1" />
          {": "}
          <span className="text-neutral-800">{dateToString(startDate)}</span>
        </>
      ) : null}

      {endDate ? (
        <>
          <Icon
            name="AdsClick"
            className={`${
              startDate ? "ml-2" : ""
            } w-4 h-4 text-neutral-400 inline-block`}
          />
          <Text translationKey="company.project.endDate" classes="ml-1" />
          {": "}
          <span className="text-neutral-800">{dateToString(endDate)}</span>
        </>
      ) : null}
    </span>
  );
}

StartEndDate.propTypes = {
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};
