import AnalyticsTransactionHelper from "@/components/Analytics/Transactions";

import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";

export default function AnalyticsQrPay() {
  return (
    <div>
      <AnalyticsTransactionHelper
        headerTitle="analytics.transactions.qrPay.title"
        type={ACCOUNTING_TRANSACTION_PAGES.QR_PAY}
      />
    </div>
  );
}
