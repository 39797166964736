import PropTypes from "prop-types";

import Button from "@/components/core/Button";
import EmptyData from "@/components/core/EmptyData";

import { RULE_TYPES } from "@/constants/rules";

export default function NoRule({ createNewRule, type }) {
  const getRuleDescription = () => {
    return type === RULE_TYPES.NORMAL
      ? "accounting.rules.createAdvancedRules.normalRules"
      : "accounting.rules.createAdvancedRules.advanceRule";
  };
  return (
    <EmptyData
      classes="mt-12"
      title="accounting.rules.createAdvancedRules.noRules"
      description={getRuleDescription()}
      buttonLabel="accounting.rules.newRule.buttonLabels.createRule"
      buttonProps={{ preIcon: "LightiningBolt" }}
      handleClick={createNewRule}
    />
  );
}

NoRule.propTypes = {
  type: PropTypes.string,
  createNewRule: PropTypes.func,
};
