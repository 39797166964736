import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { baseCountrySelector } from "@/store/selectors/client";
import { selectedWalletAccountSelector } from "@/store/selectors/company";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import PaymentOptionIcon from "@/components/Company/Overview/AccountOverview/PaymentOptionIcon";
import DosAndDontsList from "@/components/Company/Overview/AccountOverview/Slider/shared/DosAndDontsList";
import useGetCountry from "@/components/Company/Overview/AccountOverview/Slider/shared/useGetCountry";
import {
  PAYMENT_OPTION_TYPE_BUTTON_LABEL_MAP,
  PAYMENT_OPTION_TYPE_HELP_TEXT_MAP,
  PAYMENT_OPTION_TYPE_TEXT_MAP,
} from "@/components/Company/Overview/AccountOverview/account-overview-utils";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAYMENT_OPTION_TYPE } from "@/constants/company";

export default function PaymentOptionCard({ paymentOptionType }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const paymentOptionText = PAYMENT_OPTION_TYPE_TEXT_MAP[paymentOptionType];
  const paymentOptionHelpText =
    PAYMENT_OPTION_TYPE_HELP_TEXT_MAP[paymentOptionType];

  const currency = useSelector(selectedWalletAccountSelector)?.currency;
  const countryCode = useSelector(baseCountrySelector);
  const country = useGetCountry(countryCode);

  const buttonText = PAYMENT_OPTION_TYPE_BUTTON_LABEL_MAP[paymentOptionType];
  const buttonClickHandler = () => {
    searchParams.set(
      SLIDERS_SEARCH_PARAMS.company.paymentOption,
      paymentOptionType
    );
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col gap-6 rounded-lg px-4 py-5 bg-neutral-50 border-none">
      <div aria-label="flag-and-texts" className="flex gap-3 items-center">
        <PaymentOptionIcon
          currency={currency}
          paymentOptionType={paymentOptionType}
        />
        <div className="flex flex-col">
          <Text
            classes="font-semibold text-xs text-neutral-800"
            translationKey={paymentOptionText}
          />
          <Text
            classes="font-medium text-base text-neutral-500"
            translationKey={paymentOptionHelpText}
          />
        </div>
      </div>
      <DosAndDontsList {...{ paymentOptionType, currency, country }} />
      <Button
        label={buttonText}
        onClick={buttonClickHandler}
        variant="tertiary"
        classes="w-full text-base font-semibold"
      />
    </div>
  );
}

PaymentOptionCard.propTypes = {
  paymentOptionType: PropTypes.oneOf(Object.values(PAYMENT_OPTION_TYPE)),
};
