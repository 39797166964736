export const RECEIPT_STATUSES = {
  SUBMITTED: {
    icon: "ReceiptSubmitted",
    class: "text-success-600",
    label: "receipt.status.submitted",
  },
  REQUIRED_NOT_SUBMITTED: {
    icon: "ReceiptMissing",
    class: "text-danger-400",
    label: "receipt.status.missing",
  },
  NOT_SUBMITTED: {
    icon: "Minus",
    class: "text-NEUTRAL-600",
    label: "receipt.status.notRequired",
  },
};
export const PHYSICAL_CARD_STATUSES_BE = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  freezed: "FROZEN",
  notIssued: "NOT_ISSUED",
  blocked: "BLOCKED",
};
export const PHYSICAL_CARD_STATUSES_CONFIG = {
  ACTIVE: {
    status: "active",
    icon: "CreditCard",
    class: "text-success-600",
    label: "cards.cardStatuses.active",
  },
  INACTIVE: {
    status: "inactive",
    icon: "CreditCard",
    class: "text-warning-500",
    label: "cards.cardStatuses.inactive",
  },
  FROZEN: {
    status: "frozen",
    icon: "CreditCard",
    class: "text-neutral-400",
    label: "cards.cardStatuses.frozen",
  },
  NOT_ISSUED: {
    status: "",
    icon: "Minus",
    class: "text-neutral-500",
    label: "cards.cardStatuses.notIssued",
  },
  BLOCKED: {
    status: "blocked",
    icon: "Minus",
    class: "text-neutral-500",
    label: "cards.cardStatuses.blocked",
  },
};

export const INVITATION_MODE_STATUSES = {
  EMAIL: "email",
  CSV: "csv",
};

export const INVITATION_MODE_STATUSES_BE = {
  email: "EMAIL",
  csv: "CSV",
};

export const INVITATION_MODE_STATUSES_CONFIG = {
  [INVITATION_MODE_STATUSES.EMAIL]: {
    icon: "Mail",
    class: "text-neutral-500",
    label: "Invited via email",
  },
  [INVITATION_MODE_STATUSES.CSV]: {
    icon: "CSV",
    class: "text-neutral-500",
    label: "Invited via csv",
  },
};

export const MEMO_NOTE_STATUSES = {
  PRESENT: {
    icon: "Memo_Present",
    class: "text-success-600",
    label: "memo.status.present",
  },
  REQUIRED_NOT_PRESENT: {
    icon: "Memo_NotPresent",
    class: "text-danger-400",
    label: "memo.status.missing",
  },
  NOT_REQUIRED: {
    icon: "Minus",
    class: "text-neutral-600",
    label: "memo.status.notRequired",
  },
  NOT_REQUIRED_MISSING: {
    icon: "Minus",
    class: "text-neutral-600",
    label: "memo.status.notRequiredMissing",
  },
};

export const SUBMISSION_POLICY_STATUSES = {
  MET: {
    icon: "VerifiedUser",
    class: "text-success-600",
    label: "submissionPolicy.status.met",
  },
  NOT_MET: {
    icon: "GppBad",
    class: "text-danger-400",
    label: "submissionPolicy.status.notMet",
  },
  FULFILLED: {
    icon: "VerifiedUser",
    class: "text-success-600",
    label: "submissionPolicy.status.met",
  },
  UNFULFILLED: {
    icon: "GppBad",
    class: "text-danger-400",
    label: "submissionPolicy.status.notMet",
  },
  OVERRIDDEN: {
    icon: "VerifiedUser",
    class: "text-success-600",
    label: "Submission policy excluded for merchant",
  },
};

export const TRANSACTION_STATUS_KEY = {
  incompleted: "NEEDS_REVIEW",
  pending: "NEEDS_REVIEW",
  okay: "APPROVED",
  not_okay: "FLAGGED",
  auto_approved: "AUTO_APPROVED",
};

export const TRANSACTION_ICONS_STATUSES = {
  APPROVED: {
    label: "filters.transactionStatus.approved",
    icon: "HowToReg",
    class: "text-success-600",
  },
  AUTO_APPROVED: {
    label: "filters.transactionStatus.autoApproved",
    icon: "CircleCheck",
    color: "text-success-600",
  },
  FLAGGED: {
    label: "filters.transactionStatus.flagged",
    icon: "Flag",
    class: "text-danger-500",
  },
  NEEDS_REVIEW: {
    label: "filters.transactionStatus.needsReview",
    icon: "PersonSearch",
    class: "text-warning-500",
  },
};
export const MEMO_NOTE_VARAINT = {
  ICON: "icon",
  TEXT: "text",
};
export const MEMO_NOTE_NOT_REQUIRE_TEXT = "―";
export const MEMO_NOTE_TOOLTIP_POSITION = {
  [MEMO_NOTE_VARAINT.ICON]: "top",
  [MEMO_NOTE_VARAINT.TEXT]: "left",
};

export const DOT_SYMBOL = "•"; // example: `Murari Kumar • India • Card`

export const WHITELIST_URL = ["add-bank-account"];
export const MAIL_CONTEXT = "mail";
export const PAY_OUTSIDE_VOLOPAY_CURRENCY = "VPL";
