import React from "react";

import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function RulesListLoader() {
  return (
    <div className="flex flex-col gap-5">
      {[...Array(10)].map((item, index) => (
        <div key={index} className="flex justify-between card-wrapper">
          <div className="flex items-center gap-4">
            <div>
              <LoaderSkeleton type="circle" />
            </div>
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <LoaderSkeleton size={[20, 200]} />
                <Icon name="MapArrow" className="text-neutral-500" />

                <LoaderSkeleton size={[20, 200]} />
              </div>
              <span>
                <LoaderSkeleton size={[15, 200]} />
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
