import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import {
  MEMO_NOTE_NOT_REQUIRE_TEXT,
  MEMO_NOTE_STATUSES,
  MEMO_NOTE_VARAINT,
} from "@/utils/constants/common";
import { getTextWidth } from "@/utils/common";

export default function MemoNoteIcon({
  required,
  value,
  index,
  variant = MEMO_NOTE_VARAINT.ICON,
  maxWidthTooltip,
}) {
  const containerRef = useRef();
  const containerWidth = containerRef.current?.getBoundingClientRect()?.width;
  const textWidth = getTextWidth(value, "");
  const [
    isTextSizeMoreThanContainerWidth,
    setIsTextSizeMoreThanContainerWidth,
  ] = useState();

  useEffect(() => {
    setIsTextSizeMoreThanContainerWidth(containerWidth <= textWidth);
  }, [containerWidth, textWidth]);

  const getMemoNoteStatus = () => {
    if (required) {
      if (!value) {
        return MEMO_NOTE_STATUSES.REQUIRED_NOT_PRESENT;
      }
      if (value?.length > 0) {
        return MEMO_NOTE_STATUSES.PRESENT;
      }
      return MEMO_NOTE_STATUSES.REQUIRED_NOT_PRESENT;
    }

    if (value?.length > 0) {
      return MEMO_NOTE_STATUSES.PRESENT;
    }
    return MEMO_NOTE_STATUSES.NOT_REQUIRED_MISSING;
  };

  let config = {};
  if (variant === MEMO_NOTE_VARAINT.ICON) config = getMemoNoteStatus();

  if (variant === MEMO_NOTE_VARAINT.TEXT)
    config = required && value?.length ? value : MEMO_NOTE_NOT_REQUIRE_TEXT;

  return (
    <>
      {textWidth === 0 || isTextSizeMoreThanContainerWidth ? (
        <Tooltip
          maxWidth={maxWidthTooltip}
          id={`memo-note-value-${index}`}
          direction="top"
        >
          <Text
            translationKey={
              value?.length
                ? value
                : !required && !value?.length
                  ? MEMO_NOTE_STATUSES.NOT_REQUIRED_MISSING.label
                  : required
                    ? MEMO_NOTE_STATUSES.REQUIRED_NOT_PRESENT.label
                    : MEMO_NOTE_STATUSES.NOT_REQUIRED.label
            }
          />
        </Tooltip>
      ) : null}
      <div
        className={
          variant === MEMO_NOTE_VARAINT.ICON
            ? "flex items-center justify-center"
            : "truncate"
        }
        ref={containerRef}
        id={
          variant === MEMO_NOTE_VARAINT.ICON ? `memo-note-value-${index}` : ""
        }
      >
        {variant === MEMO_NOTE_VARAINT.ICON && config.icon !== "Minus" ? (
          <Icon name={config.icon} className={config.class} />
        ) : required && value?.length ? (
          <Text
            id={
              variant === MEMO_NOTE_VARAINT.TEXT
                ? `memo-note-value-${index}`
                : ""
            }
            translationKey={config}
          />
        ) : (
          <Text translationKey="-" noTranslate />
        )}
      </div>
    </>
  );
}
MemoNoteIcon.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  index: PropTypes.number,
  maxWidthTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
