import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import vToast from "@/utils/vToast";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import API from "@/api";

const initialState = {
  syncAttempts: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  selectedSyncAttempt: null,
  isFetchingSyncAttempt: false,
  lastSyncAttempt: null,
  isFetchingLastSyncAttempt: false,
};

const syncAttempts = createSlice({
  name: "syncAttempts",
  initialState,
  reducers: {
    setSyncAttempts(state, action) {
      state.syncAttempts = action.payload;
    },
    setSyncAttemptsList(state, action) {
      state.syncAttempts.list = action.payload;
    },
    setSelectedSyncAttempt(state, action) {
      state.selectedSyncAttempt = action.payload;
    },
    setSelectedSyncAttemptDetailItem(state, action) {
      const attemptDetailItemId = action.payload.id;

      const attemptDetailItemIndex =
        state.selectedSyncAttempt.unsuccessfulTransactions.findIndex(
          ({ id }) => id === attemptDetailItemId
        );

      if (attemptDetailItemIndex === -1) return;

      if (action.payload.mode === "merge") {
        state.selectedSyncAttempt.unsuccessfulTransactions[
          attemptDetailItemIndex
        ] = {
          ...state.selectedSyncAttempt.unsuccessfulTransactions[
            attemptDetailItemIndex
          ],
          ...action.payload.value,
        };
      } else
        state.selectedSyncAttempt.unsuccessfulTransactions[
          attemptDetailItemIndex
        ] = action.payload.value;
    },
    setSyncAttempt(state, action) {
      const listIndex = state.syncAttempts.list.findIndex(
        (syncAttempt) => syncAttempt.id === action.payload.id
      );
      state.syncAttempts.list[listIndex] = action.payload.value;
    },
    setIsFetching(state, action) {
      state.syncAttempts.isFetching = action.payload;
    },
    setIsFetchingSinglular(state, action) {
      state.isFetchingSyncAttempt = action.payload;
    },
    addSyncAttempts(state, action) {
      state.syncAttempts.list = [...state.syncAttempts.list, ...action.payload];
    },
    setSyncAttemptspage(state, action) {
      state.syncAttempts.page = action.payload;
    },
    setSyncAttemptsTotal(state, action) {
      state.syncAttempts.total = action.payload;
    },
    setSyncAttemptsHasMore(state) {
      state.syncAttempts.hasMore =
        state.syncAttempts.list.length < state.syncAttempts.total;
    },
    setSyncAttemptsLimit(state, action) {
      state.syncAttempts.limit = action.payload;
    },
    setLastSyncAttempt(state, action) {
      state.lastSyncAttempt = action.payload;
    },
    setIsFetchingLastSyncAttempt(state, action) {
      state.isFetchingLastSyncAttempt = action.payload;
    },
  },
});

export const fetchSyncAttempts = createAsyncThunk(
  "syncAttempts/fetchSyncAttempts",
  async (params, { dispatch }) => {
    dispatch(setIsFetching(true));

    const [err, response] = await to(API.Accounting.getSyncAttempts(params));

    if (response.data) {
      if (params.page === 1) {
        dispatch(setSyncAttemptsList(response.data.list));
      } else {
        dispatch(addSyncAttempts(response.data.list));
      }
      dispatch(setSyncAttemptsLimit(response.data.limit));
      dispatch(setSyncAttemptsTotal(response.data.total));
      dispatch(setSyncAttemptspage(response.data.page));
      dispatch(setSyncAttemptsHasMore());
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetching(false));
  }
);

export const downloadSyncHistory = createAsyncThunk(
  "syncAttempts/downloadSyncHistory",
  async (params, { dispatch }) => {
    const [error, response] = await to(
      API.Accounting.downloadSyncHistory(params)
    );
    if (response.data) {
      window.open(response?.data?.url);
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const fetchAndSelectSyncAttemptWithDetail = createAsyncThunk(
  "syncAttempts/fetchSyncAttemptDetail",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSinglular(true));
    const { id } = params;

    const [err, response] = await to(API.SyncAttempts.get(id));

    if (response) {
      dispatch(setSelectedSyncAttempt(response));
    }
    dispatch(setIsFetchingSinglular(false));
  }
);

export const fetchLastSyncAttempt = createAsyncThunk(
  "syncAttempts/fetchSyncAttemptInfo",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingLastSyncAttempt(true));

    const [err, resp] = await to(
      API.SyncAttempts.getLastSyncAttempt({ type: params.type })
    );

    if (resp) {
      dispatch(setLastSyncAttempt(resp));
    }

    dispatch(setIsFetchingLastSyncAttempt(false));
  }
);

export const {
  setSyncAttempts,
  setSyncAttemptsList,
  setIsFetching,
  setIsFetchingSinglular,
  addSyncAttempts,
  setSelectedSyncAttempt,
  setSelectedSyncAttemptDetailItem,
  setSyncAttempt,
  setSyncAttemptspage,
  setSyncAttemptsTotal,
  setSyncAttemptsHasMore,
  setSyncAttemptsLimit,
  setLastSyncAttempt,
  setIsFetchingLastSyncAttempt,
  setSyncHistoryDownloadUrl,
} = syncAttempts.actions;

export default syncAttempts.reducer;
