export const RULE_TYPES = {
  NORMAL: "normal",
  ADVANCED: "advanced",
};

export const VP_FIELD_MAPPING = {
  card: "Volopay card",
  merchant: "Volopay merchant",
  vendor: "Volopay vendor",
  category: "Volopay category",
  project: "Volopay project",
  department: "Volopay department",
  location: "Volopay location",
  budget: "Volopay budget",
};

export const ACCOUNTING_OWNER_TYPE = {
  TAG_VALUE: "TagValue",
  ACCOUNTING_PAYEE: "AccountingPayee",
};

export const ACCOUNTING_FIELD_TYPE = {
  VENDOR: "vendor",
  TAX_CODES: "tax codes",
  CATEGORY: "category",
};

export const VP_ITEM_TYPE = {
  MERCHANT: "merchant",
  VENDOR: "vendor",
  CATEGORY: "category",
  PROJECT: "project",
  DEPARTMENT: "department",
  CARD: "card",
  LOCATION: "location",
};

export const DELETE_RULE_TYPES = {
  rule: "rule",
  rule_item_group: "rule_item_group",
};

export const RULES_TOAST_CONFIG = {
  CREATE_SUCCESS: {
    title: "Success",
    description: "New rule is created.",
    variant: "success",
  },
  CREATE_ON_SLIDER_SUCCESS: {
    title: "Success",
    description:
      "The new rule has been created and applied to the transaction.",
    variant: "success",
  },
  CREATE_ALL_APPLICABLE_SUCCESS: {
    title: "Success",
    description:
      "The new rule has been created. Updating the transactions may take a while.",
    variant: "success",
  },
  NOT_SET: {
    title: "Rule not set",
    description: "There was an error in setting the rule, please try again",
    variant: "danger",
  },
  EDIT_SUCCESS: {
    title: "Success",
    description: "Changes have been saved",
    variant: "success",
  },
  DELETE_RULE_SUCCESS: {
    title: "Success",
    description: "The rule has been removed",
    variant: "success",
  },
  DELETE_MAPPING_SUCCESS: {
    title: "Success",
    description: "Mapping successfully deleted",
    variant: "success",
  },
  DELETE_RULE_FAIL: {
    title: "Failed",
    description: "Failed to delete rule",
    variant: "danger",
  },
  DELETE_MAPPING_FAIL: {
    title: "Failed",
    description: "Failed to delete mapping",
    variant: "danger",
  },
};
