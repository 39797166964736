import PropsTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchDepartments,
  resetDepartmentsStoreState,
} from "@/store/reducers/company";

import {
  departmentsFiltersSelector,
  departmentsHasMoreSelector,
  departmentsSelector,
  isFetchingDepartmentsSelector,
} from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";

import Icon from "@/components/core/Icon";
import SearchInput from "@/components/core/SearchInput";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import { convertFilters } from "@/utils/filters";
import { debounce } from "@/utils/common";

import { DEPARTMENT } from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import LocationProjectAndDepartmentList from "./common/LocationProjectAndDepartmentList";
import "./styles.scss";

export default function DepartmentsTable({ onClickHandler }) {
  const dispatch = useDispatch();
  const departments = useSelector((state) => departmentsSelector(state));
  const isFetching = useSelector((state) =>
    isFetchingDepartmentsSelector(state)
  );
  const hasMore = useSelector(departmentsHasMoreSelector);
  const isEmpty = !departments?.length;
  const [searchText, setSearchText] = useState("");
  const filters = useSelector(departmentsFiltersSelector);
  const searchHandler = debounce((text) => {
    setSearchText(text);
  }, 500);
  const appliedFilters = useSelector(appliedFilterSelector);
  const filterOptions = {
    q: searchText,
    department: true,
    ...convertFilters(appliedFilters),
  };
  const onReset = () => {
    dispatch(resetDepartmentsStoreState());
  };
  const loadMore = () => {
    dispatch(
      fetchDepartments({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,

        ...filterOptions,
      })
    );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  return (
    <div className="flex flex-col">
      <LocationProjectAndDepartmentList
        isEmpty={isEmpty}
        hasMore={hasMore}
        isFetching={isFetching}
        handleRefChange={handleRefChange}
        data={departments}
        type={DEPARTMENT}
        filters={filters}
      />
    </div>
  );
}

DepartmentsTable.propTypes = {
  onClickHandler: PropsTypes.func,
};
