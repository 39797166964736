import PropsTypes from "prop-types";
import React, { useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { amountToCurrency } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function ReimbursementApprovalSliderInfo({
  createdBy,
  linkedTo,
  type,
  totalAmount,
  totalClaims,
  totalAmountText,
  linkedToClasses,
  totalAmountClasses,
}) {
  const [searchParams, setSearchParam] = useSearchParams();
  return (
    <div className="grid grid-cols-2 gap-5 auto-rows-max">
      <div className="flex flex-col card-wrapper">
        <Text
          classes="text-sm text-neutral-500 font-semibold"
          translationKey="reimbursement.approvals.sliderHeadings.info.user"
        />
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => {
            searchParams.set(
              SLIDERS_SEARCH_PARAMS.company.people.id,
              createdBy?.id
            );
            setSearchParam(searchParams);
          }}
        >
          <Text
            classes="text-neutral-800 font-semibold"
            translationKey={createdBy?.displayName}
          />

          <Icon className="text-primary-500" name="ToLink" />
        </div>

        <div className="flex flex-wrap items-center gap-1 text-xs font-semibold text-left">
          <Text
            classes="text-neutral-500 capitalize"
            translationKey={createdBy?.roles?.[0]}
          />
          {createdBy?.departmentName ? (
            <span className="flex items-center justify-center w-0.5 h-0.5 rounded-full bg-neutral-500">
              {"  "}
            </span>
          ) : null}
          <Text
            classes="text-neutral-500 capitalize"
            translationKey={createdBy?.departmentName}
          />

          {createdBy?.locationName ? (
            <span className="flex items-center justify-center w-0.5 h-0.5 rounded-full bg-neutral-500">
              {"  "}
            </span>
          ) : null}
          <Text
            classes="text-neutral-500 capitalize"
            translationKey={createdBy?.locationName}
          />
        </div>
      </div>
      {type ? (
        <div className="flex flex-col justify-center card-wrapper">
          <Text
            classes="text-sm text-neutral-500 font-semibold"
            translationKey="reimbursement.approvals.sliderHeadings.info.type"
          />
          <Text
            classes="text-neutral-800 font-semibold"
            translationKey={type}
          />
        </div>
      ) : null}

      <div
        className={`flex flex-col justify-center card-wrapper ${linkedToClasses}`}
      >
        <Text
          classes="text-sm text-neutral-500 font-semibold"
          translationKey="reimbursement.approvals.sliderHeadings.info.linkedTo"
        />
        <div
          className="flex flex-wrap w-full cursor-pointer"
          onClick={() => {
            if (linkedTo?.name) {
              searchParams.set(
                SLIDERS_SEARCH_PARAMS.company[linkedTo?.title?.toLowerCase()]
                  ?.id,
                linkedTo?.id
              );
              setSearchParam(searchParams);
            }
          }}
        >
          <Text
            classes="text-neutral-800 font-semibold"
            translationKey={linkedTo?.title}
          />
          <span>:</span>
          <Text
            classes="text-neutral-800 font-semibold truncate"
            translationKey={linkedTo?.name}
            showTooltip
          />

          <Icon
            className="inline-block ml-2 cursor-pointer text-primary-500"
            name="ToLink"
          />
        </div>
      </div>

      {totalAmount ? (
        <div className={`flex flex-col card-wrapper ${totalAmountClasses}`}>
          <Text
            classes="text-sm text-neutral-500 font-semibold"
            translationKey={totalAmountText || "Total amount"}
          />
          <Text
            classes="text-neutral-800 font-semibold"
            translationKey={amountToCurrency(totalAmount)}
          />
        </div>
      ) : null}

      {totalClaims ? (
        <div className="flex flex-col card-wrapper">
          <Text
            classes="text-sm text-neutral-500 font-semibold"
            translationKey="myVolopay.reimbursements.drafts.tableHeaders.totalClaims"
          />
          <Text
            classes="text-neutral-800 font-semibold"
            translationKey={totalClaims}
          />
        </div>
      ) : null}
    </div>
  );
}
ReimbursementApprovalSliderInfo.propTypes = {
  createdBy: PropsTypes.object,
  linkedTo: PropsTypes.object,
  type: PropsTypes.string,
  totalAmount: PropsTypes.object,
  totalClaims: PropsTypes.number,
  totalAmountText: PropsTypes.string,
  linkedToClasses: PropsTypes.string,
  totalAmountClasses: PropsTypes.string,
};
