import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  addMileageRate,
  addMileageRateList,
  updateMileageRate,
  updateMileageRateList,
} from "@/store/reducers/reimbursement";

import { defaultCurrencySelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import { camelToSnake } from "@/utils/common";

/**
 * This component is designed as a slider for users to add or edit mileage rates for various countries.
 * It consists of input fields for selecting a country and entering a corresponding mileage rate. Users can dynamically
 * add new countries and the component validates to prevent duplicate entries. The component also provides options
 * to save changes or discard them.

 * @param {string} title -       title of the slider.
 * @param {string} description - description of the slider.
 * @param {boolean} showAddNewCountry - Flag to show or hide the option to add another country.
 * @param {function} handleDiscard - discard action.
 * @param {Array} mileageRateList - List of existing mileage rates.
 * @param {Array} countriesList - List of available countries .
 * @param {object} editMileageRate - The mileage rate being edited (if in edit mode).
 * @param {function} setEditMileageRate - Function to set the mileage rate being edited.
 */

export default function AddMileageRateSlider({
  title,
  description,
  showAddNewCountry,
  mileageRateList,
  countriesList,
  handleDiscard,
  editMileageRate,
  setEditMileageRate,
  isEditMode = false,
}) {
  const baseCurrency = useSelector(defaultCurrencySelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const [addNewCountryBoxCount, setNewCountryBoxCount] = useState(1);
  const [disabled, setdisabled] = useState(true);
  const dispatch = useDispatch();
  const [handleSaveInProgress, setHandleSaveInProgress] = useState(false);
  const [amount, setAmount] = useState(
    editMileageRate ? { 0: editMileageRate.rate } : {}
  );
  const [countries, setCountries] = useState(
    editMileageRate
      ? [
          {
            name: editMileageRate.countryName,
            isoCode: editMileageRate.countryCode,
          },
        ]
      : []
  );
  useEffect(() => {
    // Disable the button if editing and values match, or if no amount or country is selected.
    const val = editMileageRate
      ? amount[0] === editMileageRate.amount &&
        countries[0]?.name === editMileageRate.countryName
      : Object.keys(amount)?.length !== addNewCountryBoxCount ||
        countries?.length !== addNewCountryBoxCount;

    setdisabled(val);
  }, [amount, countries]);

  const handleSelectChange = (option, index) => {
    setCountries((prev) => {
      const updatedCountries = [...prev];

      updatedCountries[index] = option;

      return updatedCountries;
    });
  };

  const handleInputChange = (val, index) => {
    setAmount((prev) => {
      const updatedAmount = { ...prev };

      updatedAmount[index] = val;

      return updatedAmount;
    });
  };
  const handleAddNewCountry = () => {
    if (
      amount[addNewCountryBoxCount - 1] &&
      countries[addNewCountryBoxCount - 1]
    )
      setNewCountryBoxCount((prev) => prev + 1);
  };

  const handleSaveData = () => {
    setHandleSaveInProgress(true);
    if (disabled) return;

    let latestId = mileageRateList?.[0]?.id ?? 0;

    const result = Object.keys(countries)
      ?.map((key) => {
        if (amount[key] !== undefined) {
          const { name, isoCode } = countries[key];
          const tempAmount = amount[key];

          let newId;
          if (editMileageRate) {
            newId = editMileageRate.id;
          } else if (
            !editMileageRate &&
            (mileageRateList.length > 0 || countries?.length > 0)
          ) {
            newId = latestId + 1;
            latestId += 1;
          }
          return {
            id: newId,
            countryName: name,
            countryCode: isoCode,
            rate: tempAmount,
            currency: baseCurrency,
          };
        }

        return null;
      })
      .filter(Boolean) // Filter out null values
      ?.reverse();

    if (editMileageRate) {
      const firstResult = result?.[0];
      const data = mileageRateList?.map((mileageItem) => {
        const selectedData =
          mileageItem?.id === firstResult?.id ? firstResult : mileageItem;

        const selectedDataToObject = Object.fromEntries(
          Object.entries(selectedData)?.map(([key, value]) => [
            camelToSnake(key),
            value,
          ])
        );
        return selectedDataToObject;
      });

      dispatch(updateMileageRate({ data }));
    } else {
      const data = [...result, ...mileageRateList].map((mileageItem) => {
        return Object.entries(mileageItem).reduce((accum, [key, value]) => {
          accum[camelToSnake(key)] = value;
          return accum;
        }, {});
      });
      dispatch(addMileageRate({ data }));
    }
    handleDiscard();
    setHandleSaveInProgress(false);
    setEditMileageRate(null);
  };
  const isCountriesAvailable = (displayValue) => {
    return mileageRateList.some((list) => list.countryName === displayValue);
  };
  function MileageRateDropdownOption(displayValue, option) {
    const { name } = option;
    const isMileageRateAvailable = isCountriesAvailable(displayValue);
    return (
      <div
        className={`flex flex-col ${isMileageRateAvailable ? "disabled" : ""}`}
      >
        <span className="font-normal text-s">{name}</span>
        {isMileageRateAvailable ? (
          <span className="text-xs">
            <Text translationKey="reimbursement.settings.sliders.mileageRate.alreadySlectedItem" />
          </span>
        ) : null}
      </div>
    );
  }

  const getFilteredCountryList = (index) => {
    const filteredSelectedCountry = countries.filter((item, i) => index !== i);
    return countriesList.filter(
      (data) => !filteredSelectedCountry.some((item) => item.id === data.id)
    );
  };
  const ref = useLeftHeaderTitle({ title });
  let isDisabledSelectedReimbursement = false;
  if (isEditMode) {
    const firstReimbursment = mileageRateList?.[0];
    const currency = countries[0]?.countryCode ?? countries[0]?.isoCode;
    isDisabledSelectedReimbursement =
      amount[0] === firstReimbursment?.rate &&
      currency === firstReimbursment?.countryCode;
  }

  return (
    <div className="slider-content-container tooltipCheckboxDropdown">
      <div className="slider-content-core px-9">
        <div className="flex flex-col pb-8" ref={ref}>
          <Text
            color="neutral-800"
            classes="text-3xl font-bold"
            translationKey={title}
          />
          <Text
            color="neutral-500"
            classes="text-sm font-medium"
            translationKey={description}
          />
        </div>
        {[...Array(addNewCountryBoxCount)].map((_, index) => {
          return (
            <div
              key={index}
              className="flex flex-col gap-8 mb-5 card-wrapper pt-9"
            >
              <VpSelect
                label="reimbursement.settings.sliders.mileageRate.countryLabel"
                options={getFilteredCountryList(index)}
                checkIsOptionDisabled={(option) =>
                  isCountriesAvailable(option.name)
                }
                menuPosition="absolute"
                handleChange={(option) => handleSelectChange(option, index)}
                value={countries[index]?.name}
                optionsDisplayKey="name"
                customUIForOptionWhenDropdownOpen={MileageRateDropdownOption}
                valueKey="name"
              />
              <Input
                type="number"
                label="reimbursement.settings.rows.mileageRate.title"
                rightText={defaultCurrency}
                value={amount[index]}
                description="reimbursement.settings.sliders.mileageRate.mileageRateInputDesc"
                onChange={(e) => handleInputChange(e.target.value, index)}
              />
              <div className="flex items-center justify-center" />
            </div>
          );
        })}

        {showAddNewCountry ? (
          <div className="flex gap-4 pt-4 mb-9 ">
            <Button
              variant="tertiary"
              label="reimbursement.settings.sliders.mileageRate.addAnotherCountry"
              preIcon="Add"
              classes="w-16.2 h-8"
              labelStyleClasses="text-xs"
              onClick={handleAddNewCountry}
              innerClasses="!gap-1"
              disabled={disabled}
            />
          </div>
        ) : null}
      </div>

      <div className="flex justify-end gap-5 px-3 py-5 slider-footer">
        <Button
          variant="tertiary"
          classes="text-neutral-500 w-16.2 h-10"
          label="reimbursement.settings.sliders.mileageRate.ctas.discard"
          onClick={handleDiscard}
        />
        <Button
          disabled={
            disabled ||
            isDisabledSelectedReimbursement ||
            Object.values(amount)
              .map((val) => Number(val))
              ?.includes(0)
          }
          classes="w-16.2 h-10"
          label="reimbursement.settings.sliders.mileageRate.ctas.save"
          onClick={handleSaveData}
        />
      </div>
    </div>
  );
}
AddMileageRateSlider.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showAddNewCountry: PropTypes.bool,
  handleDiscard: PropTypes.func,
  mileageRateList: PropTypes.array,
  countriesList: PropTypes.array,
  editMileageRate: PropTypes.object,
  setEditMileageRate: PropTypes.func,
  isEditMode: PropTypes.bool,
};
