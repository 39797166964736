import { createSelector } from "@reduxjs/toolkit";

export const selectFiltersStore = (store) => store.filters;

export const filtersSelector = createSelector(
  selectFiltersStore,
  (filters) => filters.filters
);
export const isFetchingFiltersSelector = createSelector(
  selectFiltersStore,
  (filters) => filters.isFetchingFilters
);
export const appliedFilterSelector = createSelector(
  selectFiltersStore,
  (filters) => filters.appliedFilters
);
export const filterTagSelector = createSelector(
  selectFiltersStore,
  (filters) => filters.filterTags
);

export const searchAndFilterStoreInfoSelector = createSelector(
  selectFiltersStore,
  (filters) => filters?.searchAndFilter
);

export const searchAndFilterCommonStructureSelector = createSelector(
  searchAndFilterStoreInfoSelector,
  (searchAndFilterInfo) => searchAndFilterInfo?.commonStructure
);

export const searchAndFilterOptionsInfoSelector = createSelector(
  searchAndFilterStoreInfoSelector,
  (searchAndFilterInfo) => searchAndFilterInfo?.optionsByLevel
);

export const searchAndFilterOptionsListSelector = createSelector(
  searchAndFilterOptionsInfoSelector,
  (searchAndFilterOptionsInfo) => searchAndFilterOptionsInfo?.currentOptionsList
);

export const searchAndFilterOptionsCurrentLevelSelector = createSelector(
  searchAndFilterOptionsInfoSelector,
  (searchAndFilterOptionsInfo) => searchAndFilterOptionsInfo?.currentLevel
);

export const searchAndFilterInputTextSelector = createSelector(
  searchAndFilterStoreInfoSelector,
  (searchAndFilterInfo) => searchAndFilterInfo?.searchInputText
);

export const searchAndFilterParamKeySelector = createSelector(
  searchAndFilterStoreInfoSelector,
  (searchAndFilterInfo) => searchAndFilterInfo?.paramKey
);

export const searchAndFilterCurrentNavigationIdsSelector = createSelector(
  searchAndFilterStoreInfoSelector,
  (searchAndFilterInfo) => searchAndFilterInfo?.currentNavigationIds
);

// selector for slider filters

export const sliderFiltersInfoSelector = createSelector(
  selectFiltersStore,
  (filters) => filters?.slider
);

export const sliderAppliedFilterSelector = createSelector(
  sliderFiltersInfoSelector,
  (sliderFiltersInfo) => sliderFiltersInfo?.appliedFilters
);

export const sliderSearchAndFilterStoreInfoSelector = createSelector(
  sliderFiltersInfoSelector,
  (sliderFiltersInfo) => sliderFiltersInfo?.searchAndFilter
);

export const sliderSearchAndFilterCommonStructureSelector = createSelector(
  sliderSearchAndFilterStoreInfoSelector,
  (sliderSearchAndFilterInfo) => sliderSearchAndFilterInfo?.commonStructure
);

export const sliderSearchAndFilterOptionsInfoSelector = createSelector(
  sliderSearchAndFilterStoreInfoSelector,
  (sliderSearchAndFilterInfo) => sliderSearchAndFilterInfo?.optionsByLevel
);

export const sliderSearchAndFilterOptionsListSelector = createSelector(
  sliderSearchAndFilterOptionsInfoSelector,
  (searchAndFilterOptionsInfo) => searchAndFilterOptionsInfo?.currentOptionsList
);

export const sliderSearchAndFilterOptionsCurrentLevelSelector = createSelector(
  sliderSearchAndFilterOptionsInfoSelector,
  (searchAndFilterOptionsInfo) => searchAndFilterOptionsInfo?.currentLevel
);

export const sliderSearchAndFilterInputTextSelector = createSelector(
  sliderSearchAndFilterStoreInfoSelector,
  (sliderSearchAndFilterInfo) => sliderSearchAndFilterInfo?.searchInputText
);

export const sliderSearchAndFilterParamKeySelector = createSelector(
  sliderSearchAndFilterStoreInfoSelector,
  (sliderSearchAndFilterInfo) => sliderSearchAndFilterInfo?.paramKey
);

export const sliderSearchAndFilterCurrentNavigationIdsSelector = createSelector(
  sliderSearchAndFilterStoreInfoSelector,
  (sliderSearchAndFilterInfo) => sliderSearchAndFilterInfo?.currentNavigationIds
);
