import React from "react";
import { useSelector } from "react-redux";

import { allUserWalletsPageFiltersSelector } from "@/store/selectors/qr-payments";

import UserWalletsPageHelper from "@/components/QRPayments/UserWalletsPageHelper";

import { ROUTES } from "@/constants/routes";

function AllUserWallets() {
  const filters = useSelector(allUserWalletsPageFiltersSelector);
  return (
    <UserWalletsPageHelper
      key={ROUTES.QRPay.userWallets.all.pathName}
      tab={ROUTES.QRPay.userWallets.all.pathName}
      filterArray={filters}
    />
  );
}

export default AllUserWallets;
