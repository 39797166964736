import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import {
  fetchAndSelectQrPayment,
  fetchQrPaymentSplit,
  updateQrPaymentSplit,
} from "@/store/reducers/qr-payments";
import { fetchTags } from "@/store/reducers/tags";

import {
  accountingVendorsSelector,
  isFetchedAccountingVendorsSelector,
  isFetchingAccountingVendorsSelector,
} from "@/store/selectors/accounting";
import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";
import {
  iisFetchingSplitQrPaymentSelector,
  isFetchingQrPayments,
  isSplitQrPaymentSuccessSelector,
  qrPaymentSplitSelector,
  selectedQrpaymentSelector,
} from "@/store/selectors/qr-payments";
import {
  accountingCategoryTagSelector,
  accountingCategoryTagsSelector,
  accountingTagsSelector,
  isFetchingTagsSelector,
  isTagsFetchedSelector,
} from "@/store/selectors/tags";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import ExpenseSplitFooter from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPaySplit/ExpenseAndQRPaySplitFooter";
import ExpenseSplitHeader from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPaySplit/ExpenseAndQRPaySplitHeader";
import { convertDataToPayloadStructure } from "@/utils/expenses";
import { DATA_TO_CONVERT_TYPE } from "@/utils/constants/expenses";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_CATEGORY,
  ACCOUNTING_VENDOR,
  AMOUNT,
} from "@/constants/expense";

export default function QrPaymentSplit() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingCategory = useSelector(accountingCategoryTagSelector);
  const splitQrPayment = useSelector(qrPaymentSplitSelector);
  const accountingTags = useSelector(accountingTagsSelector);
  const isFetchedAccountingTags = useSelector(isTagsFetchedSelector);
  const isFetchingAccountingTags = useSelector(isFetchingTagsSelector);
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const qrPayments = useSelector(selectedQrpaymentSelector);
  const isFetching = useSelector(iisFetchingSplitQrPaymentSelector);
  const isQrPaymentFetching = useSelector(isFetchingQrPayments);
  const isFetchingAccountingVendors = useSelector(
    isFetchingAccountingVendorsSelector
  );
  const categoryAccountingTags = useSelector(accountingCategoryTagsSelector);
  const isFetchedAccountingVendors = useSelector(
    isFetchedAccountingVendorsSelector
  );
  const accountingVendorOptions = useSelector(accountingVendorsSelector);
  const isSplitSuccess = useSelector(isSplitQrPaymentSuccessSelector);
  const isSplitQrPaymentProgress = useSelector(
    iisFetchingSplitQrPaymentSelector
  );

  const [totalSplitAmount, setTotalSplitAmount] = useState(0);
  const [splitData, setSplitData] = useState([]);

  const ref = useLeftHeaderTitle({ title: "QRPay.splitQrPaymentTitle" });

  const update = (index, keyToUpdate, e) => {
    setSplitData((prev) =>
      prev.map((currPrev, idx) => {
        switch (keyToUpdate) {
          case AMOUNT:
            if (idx === index) {
              return { ...currPrev, amount: e?.target?.value };
            }
            break;

          case ACCOUNTING_CATEGORY:
            if (idx === index) {
              return {
                ...currPrev,
                lineItemTagValues: currPrev?.lineItemTagValues?.map(
                  (lineItem, lineItemIndex) => {
                    if (lineItemIndex === 0) {
                      return { ...lineItem, valueId: e?.id };
                    }
                    return lineItem;
                  }
                ),
              };
            }
            break;

          case ACCOUNTING_VENDOR:
            if (idx === index) {
              return {
                ...currPrev,
                accountingPayeeId: e?.id,
              };
            }
            break;

          default:
            break;
        }
        return currPrev;
      })
    );
  };
  const addRow = () => {
    const tempData = {
      id: `split${splitData.length + 1}`,
      amount: 0,
      accountingPayeeId: null,
      lineItemTagValues: [{ valueId: null }, { valueId: null }],
      deleteBtn: true,
    };
    setSplitData((prev) =>
      Array.isArray(prev) ? [...prev, tempData] : tempData
    );
  };

  const deleteSplit = (id) => {
    setSplitData((prev) =>
      prev.filter((_) => {
        return _?.id !== id;
      })
    );
  };
  const createInitialNewValue = () => {
    const tempData = [];
    if (splitQrPayment) {
      let _splitQrPayment = splitQrPayment;
      if (_splitQrPayment?.length === 0)
        _splitQrPayment = new Array(2).fill({});
      _splitQrPayment.forEach((currSplit, index) => {
        const lineItem1 = currSplit?.lineItemTagValues?.find(
          (_) => _?.tagValue?.tagId === accountingCategory?.id
        );

        const lineItem = [{ valueId: lineItem1?.tagValue?.tagValueId }];
        const singleSplit = {
          id: currSplit?.id ? currSplit?.id : `split${index + 1}`,
          amount: currSplit?.amount
            ? currSplit?.amount
            : (qrPayments?.amount ?? 0) / 2,
          deleteBtn: index > 1,
          accountingPayeeId: currSplit?.accountingPayeeId,
          lineItemTagValues: lineItem || [{ valueId: null }],
        };
        tempData.push(singleSplit);
      });
      setSplitData(tempData);
    }
  };

  const dispatchAndCloseSlider = () => {
    const payload = convertDataToPayloadStructure(
      splitData,
      DATA_TO_CONVERT_TYPE.SPLIT
    );

    const qrPaymentId = parseInt(
      searchParams.get(SLIDERS_SEARCH_PARAMS.qrPayments.payments.splitExpense),
      10
    );

    dispatch(updateQrPaymentSplit({ qrPaymentId, payload }));
  };

  const checkForStatus = () => {
    const isValid = splitData
      ? Object.keys(splitData)
          ?.map((key) => parseFloat(splitData[key]?.amount) === 0)
          .includes(true)
      : true;

    return isValid || totalSplitAmount - (qrPayments?.amount ?? 0) !== 0;
  };

  // on Mount
  useEffect(() => {
    dispatch(fetchTags());
    dispatch(fetchAccountingVendors());
  }, []);

  useEffect(() => {
    const qrPaymentId = parseInt(
      searchParams.get(SLIDERS_SEARCH_PARAMS.qrPayments.payments.splitExpense),
      10
    );
    if (qrPaymentId) {
      dispatch(
        fetchQrPaymentSplit({
          qrPaymentId,
        })
      );
      dispatch(
        fetchAndSelectQrPayment({
          qrPaymentId,
        })
      );
    }
  }, [searchParams]);

  useEffect(() => {
    createInitialNewValue();
  }, [qrPayments, splitQrPayment]);

  useEffect(() => {
    if (splitData.length !== 0) {
      const total = splitData
        ?.map((split) => split?.amount)
        ?.reduce((a, b) => parseFloat(a) + parseFloat(b ? b : 0));
      setTotalSplitAmount(total);
    }
  }, [splitData]);

  useEffect(() => {
    if (isSplitSuccess) {
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.qrPayments.payments.splitQrPayment
      );
      setSearchParams(searchParams);
    }
  }, [isSplitSuccess]);

  return !isFetching && !isQrPaymentFetching ? (
    <>
      <div className="flex flex-col slider-content-core">
        <Text
          refProp={ref}
          classes="text-3xl font-semibold mt-7"
          translationKey="QRPay.splitQrPaymentTitle"
        />
        <Text
          classes="text-sm text-neutral-500"
          translationKey="expenses.splitExpense.description"
        />
        <ExpenseSplitHeader
          amount={qrPayments?.amount?.toFixed(2)}
          currency={qrPayments?.currency}
          splitAmount={totalSplitAmount}
        />
        <div className="flex justify-between mb-5">
          <Text
            color="neutral-800"
            classes="text-xl font-bold"
            translationKey="expenses.splitExpense.lineItems"
          />
          <Button
            preIcon="Add"
            label="expenses.splitExpense.addSplit"
            className="flex items-center gap-2 text-primary-500"
            onClick={addRow}
          />
        </div>
        <div className="relative">
          {/* Table */}
          <Table
            styleId="splitExpenseTable"
            headerSticky
            colWidths={[120, 150, 300, 300, 300]}
          >
            <tr className="text-sm text-left text-semibold">
              <th>
                <Text translationKey="expenses.splitExpense.splitNo" />
              </th>
              <th>
                <Text translationKey="expenses.splitExpense.amount" />
              </th>
              {accountingEnabled ? (
                <th>
                  <Text
                    translationKey="expenses.slider.accountingCategory"
                    translationProps={{ accounting: accountingSoftware }}
                  />
                </th>
              ) : null}
              {accountingEnabled ? (
                <th>
                  <Text
                    translationKey="expenses.slider.accountingMerchant"
                    translationProps={{ accounting: accountingSoftware }}
                  />
                </th>
              ) : null}
            </tr>
            {splitData.map((currSplit, index) => {
              return (
                <tr key={`split-data-currentsplit-${index + 1}`}>
                  <td>
                    {currSplit?.deleteBtn ? (
                      <span
                        className="absolute cursor-pointer -left-0 z-9"
                        onClick={() => deleteSplit(currSplit?.id)}
                      >
                        <Icon className="text-neutral-400" name="Delete" />
                      </span>
                    ) : null}

                    <Text
                      translationKey="expenses.splitExpense.splitTitle"
                      translationProps={{ splitNo: index + 1 }}
                    />
                  </td>
                  <td>
                    <Input
                      rightText={qrPayments?.currency}
                      type="number"
                      label="expenses.splitExpense.enterAmountPlaceholder"
                      value={currSplit?.amount}
                      hideLabelAfterValueAdded
                      onChange={(e) => {
                        update(index, "amount", e);
                      }}
                    />
                  </td>
                  {accountingEnabled ? (
                    <td>
                      <VpSelect
                        clearable
                        placeholder="expenses.slider.accountingCategory"
                        label="expenses.slider.accountingCategory"
                        value={currSplit?.lineItemTagValues[0]?.valueId}
                        options={categoryAccountingTags?.options}
                        labelTranslationProp={{
                          accounting: accountingSoftware,
                        }}
                        placeholderTranslationProp={{
                          accounting: accountingSoftware,
                        }}
                        hideLabelAfterSelect
                        menuPosition="absolute"
                        isOptionsFetched={isFetchedAccountingTags}
                        isOptionsLoading={isFetchingAccountingTags}
                        fetchOptions={() => dispatch(fetchTags())}
                        handleChange={(e) => {
                          update(index, ACCOUNTING_CATEGORY, e);
                        }}
                        optionsDisplayKey="alias"
                      />
                    </td>
                  ) : null}
                  {accountingEnabled ? (
                    <td>
                      <VpSelect
                        clearable
                        placeholder="expenses.slider.accountingMerchant"
                        label="expenses.slider.accountingMerchant"
                        value={currSplit?.accountingPayeeId}
                        labelTranslationProp={{
                          accounting: accountingSoftware,
                        }}
                        placeholderTranslationProp={{
                          accounting: accountingSoftware,
                        }}
                        hideLabelAfterSelect
                        options={accountingVendorOptions}
                        handleChange={(e) => {
                          update(index, ACCOUNTING_VENDOR, e);
                        }}
                        isOptionsFetched={isFetchedAccountingVendors}
                        isOptionsLoading={isFetchingAccountingVendors}
                        fetchOptions={() => dispatch(fetchAccountingVendors())}
                        menuPosition="absolute"
                        optionsDisplayKey="alias"
                      />
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </Table>
        </div>
      </div>
      <div className="px-3 py-5 slider-footer">
        <ExpenseSplitFooter
          inProgress={isSplitQrPaymentProgress}
          ctaStatus={checkForStatus()}
          submitSplit={dispatchAndCloseSlider}
        />
      </div>
    </>
  ) : null;
}
