import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPoolCardDetails } from "@/store/reducers/cards";

import { poolCardDetailsSelector } from "@/store/selectors/cards";

import Icon from "@/components/core/Icon";
import Progress from "@/components/core/Progress";
import Text from "@/components/core/Text";

import { CARD_PROVIDER } from "@/constants/Cards";

function VirtualCardPoolWidget() {
  const poolCardDetails = useSelector(poolCardDetailsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPoolCardDetails({ slug: CARD_PROVIDER.UOB }));
  }, []);

  const DETAILS_POOL_ACCOUNT = {
    spent: {
      color: "bg-corporate-cards-500",
      label: "cards.cardTable.spent",
      amount: poolCardDetails?.spent,
      currency: poolCardDetails?.currency,
    },
    available: {
      color: "bg-neutral-200",
      label: "cards.cardTable.available",
      amount: poolCardDetails?.available,
      currency: poolCardDetails?.currency,
    },
  };

  const POOL_LIMIT_DETAILS = {
    poolLimit: {
      label: "cards.cardDetails.cardPoolLimit.poolLimit",
      translationProps: poolCardDetails?.poolLimit,
    },
    cardsCount: {
      label: "cards.cardDetails.cardPoolLimit.noOfCards",
      translationProps: poolCardDetails?.cardsCount,
    },
  };

  const configHeader = [
    DETAILS_POOL_ACCOUNT.spent,
    DETAILS_POOL_ACCOUNT.available,
  ];

  const configDetails = [
    POOL_LIMIT_DETAILS.poolLimit,
    POOL_LIMIT_DETAILS.cardsCount,
  ];

  const totalAmount = poolCardDetails
    ? poolCardDetails.spent + poolCardDetails.available
    : 0;

  return (
    <div className="p-0 card-wrapper">
      <div className="flex justify-between px-5 pt-5">
        {configHeader?.map((card) => (
          <div key={card.label}>
            <div className="flex items-center gap-2">
              <div
                className={`inline-block w-2 h-2 rounded-full ${card.color}`}
              />
              <Text translationKey={card.label} />
              <Icon name="Info" className="w-5 h-5 text-neutral-300" />
            </div>
            <div>
              <span className="text-xl font-bold text-neutral-800">
                {card.amount}
              </span>
              <span className="pl-1 text-base font-bold text-neutral-500">
                {card.currency}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="px-5 pb-5">
        <Progress
          classes="mt-3 mb-3"
          data={{
            total: totalAmount,
            items: [
              {
                variant: "corporate-cards",
                value: poolCardDetails?.spent,
              },
            ],
          }}
          neutralTrack
        />

        <div className="flex items-center gap-5 mt-3 text-neutral-500">
          {configDetails?.map((card) => (
            <div
              key={card.label}
              className="flex items-center gap-2 text-neutral-500"
            >
              <Text
                translationKey={card.label}
                translationProps={{ count: card.translationProps }}
              />

              <Icon name="Info" className="w-5 h-5 text-neutral-300" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VirtualCardPoolWidget;
