import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPaymentApprovers } from "@/store/reducers/payments";

import {
  isFetchingPaymentApproversSelector,
  paymentApproversSelector,
} from "@/store/selectors/payments";

import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import ApproversAtLevel from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/ApproversAtLevel";

export default function ApproversSectionComponent({
  approvalDescription,
  descriptionTextClasses,
  params,
  makerCheckerStatus = true,
  preApprovalDescription = "",
}) {
  const [approversOpen, setApproverOpen] = useState(false);
  const approversObject = useSelector(paymentApproversSelector);
  const approversList = approversObject?.approvers || [];
  const isFetching = useSelector(isFetchingPaymentApproversSelector);

  const dispatch = useDispatch();

  const nonApprovers = approversList?.reduce(
    (nonApproversCount, approversAtEachLevel) => {
      if (approversAtEachLevel.users.length === 0) {
        nonApproversCount += 1;
        return nonApproversCount;
      }
    },
    0
  );

  const showAutoApproveSection = nonApprovers === approversList.length;

  const approversCountText = `${approversList.length} Level${
    approversList.length > 1 ? "s" : ""
  }`;

  useEffect(() => {
    dispatch(fetchPaymentApprovers({ ...params }));
  }, []);

  if (isFetching) {
    return <ApproversSectionSkelaton />;
  }

  if (!approversObject) {
    return null;
  }

  if (showAutoApproveSection) {
    return (
      <div className="card-wrapper">
        <div className="flex items-center gap-2">
          <Icon name="CheckCircle" className="text-success-600" />
          <Text
            translationKey="timeline.aboveAccordionTexts.autoApproved"
            classes="text-lg font-semibold"
          />
        </div>
        <Text
          translationKey="billPay.bill.invoiceInbox.createBill.sections.approvers.paymentWillBeAutoApproved"
          classes="text-sm font-medium text-neutral-500"
        />
      </div>
    );
  }

  return (
    <div className="card-wrapper">
      <div
        className="flex flex-row justify-between"
        onClick={() => setApproverOpen((prev) => !prev)}
      >
        <div className="flex flex-col">
          <div className="flex flex-row gap-5">
            <Text
              translationKey="timeline.aboveAccordionTexts.approvers"
              classes="text-xl not-italic font-semibold leading-8 tracking-[-0.2px] text-neutral-800"
            />

            <Chip
              label={approversCountText}
              classes="items-center text-neutral-600 w-28"
            />
          </div>

          {typeof approvalDescription === "string" ? (
            <div className="mt-3">
              <Text
                translationKey={approvalDescription}
                classes={`text-sm not-italic font-medium text-neutral-500 ${descriptionTextClasses}`}
              />
            </div>
          ) : (
            approvalDescription
          )}
        </div>

        <div
          aria-label="right-side"
          className="flex items-center justify-end gap-4 text-neutral-500"
        >
          <Icon
            name={`${approversOpen ? "UpArrow" : "DownArrow"}`}
            className="transition-all ease-in cursor-pointer duration-600 stroke-neutral-500"
          />
        </div>
      </div>

      {approversOpen && (
        <div className="mt-5">
          {Object.entries(approversList)?.map(([level, approver]) => {
            return approver?.users?.length ? (
              <ApproversAtLevel
                key={level}
                level={approver?.level}
                levelNumber={1 + parseInt(level, 10)} // 1, 2, 3
                approversList={approver?.users ?? []}
                isAutoApproved={showAutoApproveSection}
                isMakerCheckerEnabled={makerCheckerStatus}
                preApprovalDescription={preApprovalDescription}
              />
            ) : null;
          })}
        </div>
      )}
    </div>
  );
}

ApproversSectionComponent.propTypes = {
  approvalDescription: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.string,
  ]),
  descriptionTextClasses: PropTypes.string,
  makerCheckerStatus: PropTypes.bool,
  preApprovalDescription: PropTypes.string,
};

const ApproversSectionSkelaton = () => {
  return (
    <div className="flex flex-col gap-2 card-wrapper">
      <div className="flex flex-row items-center gap-4">
        <LoaderSkeleton size={[32, 96]} />
        <LoaderSkeleton size={[24, 110]} />
      </div>
      <LoaderSkeleton size={[24, 400]} />
    </div>
  );
};
