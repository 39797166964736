import PropTypes from "prop-types";
import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import { REIMBURSEMENT_PAGE_TYPE } from "@/utils/constants/reimbursement";

export default function LoaderHelper({ type }) {
  return (
    <tr className="text-center">
      <td className="flex items-center gap-3 ml-4">
        <LoaderSkeleton type="circle" />
        <LoaderSkeleton size={[20, 180]} />
      </td>
      <td className="text-right">
        <LoaderSkeleton size="lg" />
      </td>
      <td className="text-center">
        <LoaderSkeleton size="lg" />
      </td>
      <td className="text-left">
        <LoaderSkeleton />
      </td>
      <td className="text-left">
        <LoaderSkeleton />
      </td>
      <td className="text-center">
        <LoaderSkeleton />
      </td>
      <td className="text-left">
        <LoaderSkeleton />
      </td>
    </tr>
  );
}

LoaderHelper.propTypes = {
  type: PropTypes.string,
};
