export const BILLING_STATUS = {
  GENERATED: "Generated",
  PAID: "Paid",
  FAILED: "Failed",
  PROCESSING: "Processing",
  VOIDED: "Voided",
};
const BILLING_COLORS = {
  success: "success",
  primary: "primary",
  danger: "danger",
  warning: "warning",
};
export const SETTING_CORPORATE_TRAVEL = "corporate_travel_enabled";
export const BILLING_BADGE_TYPES = {
  [BILLING_STATUS.GENERATED]: {
    text: "common.statuses.generated",
    icon: null,
    color: BILLING_COLORS.primary,
  },
  [BILLING_STATUS.PAID]: {
    text: "common.statuses.paid",
    icon: null,
    color: BILLING_COLORS.success,
  },
  [BILLING_STATUS.FAILED]: {
    text: "common.statuses.failed",
    icon: null,
    color: BILLING_COLORS.danger,
  },
  [BILLING_STATUS.PROCESSING]: {
    text: "common.statuses.processing",
    icon: null,
    color: BILLING_COLORS.warning,
  },
  [BILLING_STATUS.VOIDED]: {
    text: "common.statuses.voided",
    icon: null,
    color: BILLING_COLORS.danger,
  },
};
export const BILLING_DETAILS = {
  PLAN_DETAILS: "planDetails",
  PAYMENT_DETAILS: "paymentDetails",
};

export const INVOICE_KEYS = {
  PLAN_NAME: "plan-name",
  PLAN_FREQ: "plan-frequency",
  SAAS_FEE: "saas-fee",
  MONTHLY_SPEND_LIMIT: "monthly-spend-limit",
  BILLING_CYCLE: "billing-cycle",
};
export const BILLING_TABLE_HEADING = {
  INVOICE_NUMBER: "invoiceNumber",
  AMOUNT: "amount",
  BILLING_DATE: "billingDate",
  PAYMENT_STATUS: "paymentStatus",
  DUE_DATE: "dueDate",
  PAYMENT_METHOD: "paymentMethod",
  ACTION: "action",
};
export const SLIDER_INVOICE_DETAILS_TABLE = {
  INVOICE_NUMBER: "invoice-number",
  INVOICE_GENERATION_DATE: "Invoice-generation-date",
  INVOICE_DUE_DATE: "Invoice-due-date",
  INVOICE_DESCRIPTION: "Invoice-description",
};
