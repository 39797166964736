import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { accountFreezeSelector } from "@/store/selectors/client";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import {
  isLoggedInSelector,
  isLoggedIntoMultipleDevicesSelector,
  selectRoles,
  userSelector,
} from "@/store/selectors/user";

import { KYC_STEPS_STATUS } from "@/constants/onboarding";
import { ROUTES } from "@/constants/routes";

export default function ProtectedRoute({ allowChild = true }) {
  const location = useLocation();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const isLoggedIntoMultipleDevices = useSelector(
    isLoggedIntoMultipleDevicesSelector
  );
  const accountFreezed = useSelector(accountFreezeSelector);

  const navigate = useNavigate();
  const roles = useSelector(selectRoles);

  const allRouteUrls = useSelector(allNavUrlsSelector);

  const currentUser = useSelector(userSelector);
  const currentUserKycStatus = currentUser?.kycState;

  const resolvedRoutes = () => {
    if (isLoggedIntoMultipleDevices) {
      navigate(ROUTES.loginExceeded.base.absolutePath);
    } else if (
      accountFreezed &&
      !location.pathname?.includes(ROUTES.manage.billing.absolutePath) &&
      !location.pathname?.includes(ROUTES.dashboard.base.absolutePath)
    ) {
      navigate(ROUTES.dashboard.base.absolutePath);
    } else if (
      ![KYC_STEPS_STATUS.APPROVED, KYC_STEPS_STATUS.SUBMITTED].includes(
        currentUserKycStatus
      )
    ) {
      navigate(ROUTES.onboarding.base.absolutePath);
    }
  };

  const isAccessAuthorized = () => {
    if (!isLoggedIn) {
      return false;
    }

    let authorised = false;

    if (allowChild) {
      authorised = allRouteUrls.some((route) =>
        location?.pathname.includes(route.path)
      );
    } else
      authorised = allRouteUrls
        .map((route) => route.path)
        .includes(location.pathname);

    if (authorised) {
      resolvedRoutes();
    }

    return authorised;

    // const routeRoles = ["default"];
    // let authorised = false;
    // if (
    //   routeRoles.length === 1 &&
    //   routeRoles[0] === "default" &&
    //   disallowedRoles.length === 0
    // ) {
    //   authorised = true;
    // }

    // if (roles.find((role) => allowedRoles.includes(role))) {
    //   authorised = true;
    // }

    // if (roles.find((role) => disallowedRoles.includes(role))) {
    //   authorised = false;
    // }
    // if (authorised) {
    //   resolvedRoutes();
    // }
    // return authorised;
  };

  const isAuthorized = isAccessAuthorized();
  const redirectUri = encodeURIComponent(
    `${window.location.pathname}${window.location.search || ""}`
  );

  return isAuthorized ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/?redirect=${redirectUri}`}
      state={{ from: location }}
      replace
    />
  );
}

ProtectedRoute.propTypes = {
  allowChild: PropTypes.bool,
};
