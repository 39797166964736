import { useEffect } from "react";

/**
 * Run code when current slider is closed. i.e. we've unmounted and popped off GlobalSlider
 *
 * useEffect with cleanup to be used in sliders. This does not run on forward navigation, since that'll be a bug.
 * @param {Function}  callbackFn
 * @param {Array}     dependencyArray
 * @param {Object}    searchParamKey from global slider
 * @param {Function}  setOnClose     from global slider
 */
// TECH_DEBT - Global sliders should to give latest direction of navigation
//
// Won't run case of forward nav (where unmount happens as well)
// Example: nav to pre add bank details page will be `false`
// Example: back nav to listing page will be `true`
export default function useEffectSlider(
  callbackFn = () => {},
  dependencyArray = [],
  { searchParamKey = null, setOnClose = () => {} } = {}
) {
  useEffect(() => {
    const cleanupFn = callbackFn();

    const cleanupFn_ = () => {
      const isSliderClosed = !window.location.search.includes(searchParamKey);
      if (isSliderClosed) {
        cleanupFn();
      }
    };

    setOnClose(cleanupFn_);
    return cleanupFn_;
    // normal cleanup will be
    // - incorrect, since we don't want to clean up on every unmount (like forward nav to pre-add bank details)
    // - insufficient, we want to clean up even if user closes the form at pre-add bank details page
    // for the same reasons, clean on first mount is also be wrong
  }, dependencyArray);
}
