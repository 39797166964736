/**
 * TODO: the below keys are just for showing how we are going to use it, these are to be replaced with keys which are expected to come
 */
import { CARD_PROVIDER } from "@/constants/Cards";
import { COUNTRY_CODE_ENUM } from "@/constants/onboarding";

export const quickGuideSearchOptions = [
  {
    label: "inappGuide.top-upYourVolopayAccount",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223175-top-up-your-volopay-account",
  },
  {
    label: "inappGuide.howToApprove/rejectARequest?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223183-how-to-approve-reject-a-request",
  },
  {
    label: "inappGuide.howToAddTravelMembers?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223188-how-to-add-travel-members",
  },
  {
    label: "inappGuide.howToOrderAPhysicalCardForYourself?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223194-order-a-physical-card-for-yourself",
  },
  {
    label: "inappGuide.activateYourPhysicalCard",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223196-activate-your-physical-card",
  },
  {
    label: "inappGuide.howToCreateAVirtualCard?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223207-how-to-create-a-virtual-card",
  },
  {
    label: "inappGuide.howToEditLimitOfVirtualCards?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223210-how-to-edit-limit-of-virtual-cards",
  },
  {
    label: "inappGuide.blockingOrFreezingAVirtualCard",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223213-blocking-or-freezing-a-virtual-card",
  },
  {
    label: "inappGuide.exploreYourExpenses",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223217-explore-your-expenses",
  },
  {
    label: "inappGuide.decliningRepaymentForYourFlaggedExpense",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223229-declining-repayment-for-a-flagged-expense",
  },
  {
    label: "inappGuide.howToCreateANewDepartment?",
    redirectionUrl:
      "fttps://help.volopay.co/en/articles/9223780-how-to-create-a-new-department",
  },
  {
    label: "inappGuide.managePeopleInDepartment",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223783-manage-people-in-department",
  },
  {
    label: "inappGuide.howToCreateANewProject?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223786-how-to-create-a-new-project",
  },
  {
    label: "inappGuide.managePeopleInAProject",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223788-manage-people-in-a-project",
  },
  {
    label: "inappGuide.flexible&Non-flexibleBudgets",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223791-flexible-non-flexible-budgets",
  },
  {
    label: "inappGuide.creatingANewBudget",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223794-overview-of-budgets",
  },
  {
    label: "inappGuide.createANewLocation",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223796-create-a-new-location",
  },
  {
    label: "inappGuide.invitingAUsersToVolopay",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223798-inviting-a-users-to-volopay",
  },
  {
    label: "inappGuide.howToFreeze/blockAUser?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223799-how-to-freeze-block-a-user",
  },
  {
    label: "inappGuide.creatingANewCustomRole",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223807-creating-a-new-custom-role",
  },
  {
    label: "inappGuide.modifyingApprovalPolicy",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223187-setting-up-approval-policy",
  },
  {
    label: "inappGuide.modifyingSubmissionPolicy",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223765-setting-up-submission-policy",
  },
  {
    label: "inappGuide.analysingYourCompanySpends",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223176-analysing-your-company-spends",
  },
  {
    label: "inappGuide.understandingUnsettledExpenses",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223180-understanding-unsettled-expenses",
  },
  {
    label: "inappGuide.knowMoreAboutYourCreditLine",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223182-know-more-about-your-credit-line",
  },
  {
    label: "inappGuide.howCanIUseMyCashbackRewards?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223179-how-to-use-cashback-rewards",
  },
  {
    label: "inappGuide.howToSkipApprovalsAsAnAdmin",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223185-how-to-skip-approvals-as-an-admin",
  },
  {
    label: "inappGuide.settingUpApprovalPolicy",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223187-setting-up-approval-policy",
  },
  {
    label: "inappGuide.activatingVolopayTravel",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223191-activating-volopay-travel",
  },
  {
    label: "inappGuide.howToSyncATransaction?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223155-how-to-sync-a-transaction",
  },
  {
    label: "inappGuide.howToAdd/removeATravelMembers?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223188-how-to-add-travel-members",
  },
  {
    label: "inappGuide.updatePhysicalCardLimitOrFrequency",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223198-update-physical-card-limit",
  },
  {
    label: "inappGuide.blockingOrFreezingAPhysicalCard",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223200-blocking-or-freezing-a-physical-card",
  },
  {
    label: "inappGuide.addYourPhysicalCardOnGoogle/applePay?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223205-add-your-physical-card-on-google-apple-pay",
  },
  {
    label: "inappGuide.howToSwitchPhysicalCardLinkedTo",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223203-how-to-switch-physical-card-linked-to",
  },
  {
    label: "inappGuide.howToSetAutoBlockDateOnYourVirtualCard",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223215-how-to-set-auto-block-date-on-your-virtual-card",
  },
  {
    label: "inappGuide.attachReceiptsViaOCR",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223226-attach-receipts-via-ocr",
  },
  {
    label: "inappGuide.howToSplitAnExpense?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223218-how-to-split-an-expense",
  },
  {
    label: "inappGuide.whatIsAFlaggedExpense?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223228-what-is-a-flagged-expense",
  },
  {
    label: "inappGuide.howDoesAnExpenseGetAutomaticallyFlagged",
    redirectionUrl: "https://help.volopay.co/en/articles/9223231-auto-flag",
  },
  {
    label: "inappGuide.whyWasMyCardDeclined?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223232-why-was-my-card-declined",
  },
  {
    label: "inappGuide.howToViewDeclinedTransactions?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223234-view-declined-transactions",
  },
  {
    label: "inappGuide.changingAUser'sDepartment",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223783-manage-people-in-department",
  },
  {
    label: "inappGuide.understandingBudgetForDepartments",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223784-understanding-budget-for-departments",
  },
  {
    label: "inappGuide.understandingApprovalPolicy",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223187-setting-up-approval-policy",
  },
  {
    label: "inappGuide.removingUser'sFromAProject",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223788-manage-people-in-a-project",
  },
  {
    label: "inappGuide.understandingBudgetForProjects",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223790-understanding-budget-for-projects",
  },
  {
    label: "inappGuide.editingABudget",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223794-overview-of-budgets",
  },
  {
    label: "inappGuide.budgetedFor:IncorporatingTypesOfSpendIntoABudget",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223795-budgeted-for-incorporating-types-of-spend-into-a-budget",
  },
  {
    label: "inappGuide.managePeopleInALocation",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223797-manage-people-in-a-location",
  },
  {
    label: "inappGuide.howToRe-inviteAUserToVolopay",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223798-inviting-a-users-to-volopay",
  },
  {
    label: "inappGuide.howToChangeAUser'sDepartment",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223783-manage-people-in-department",
  },
  {
    label: "inappGuide.howToChangeAUser'sRole",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223803-how-to-change-a-user-s-role",
  },
  {
    label: "inappGuide.howToChangeAUser'sLocation",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223797-manage-people-in-a-location",
  },
  {
    label: "inappGuide.assingingACo-pilotOfAUser",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223804-assinging-a-co-pilot",
  },
  {
    label: "inappGuide.assigningUser'sACustomRole",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223803-how-to-change-a-user-s-role",
  },
  {
    label: "inappGuide.modifyingCustomRolePermissions",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223811-modifying-custom-role-permissions",
  },
  {
    label: "inappGuide.understandingVolopayRoles",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223814-understanding-volopay-roles",
  },
  {
    label: "inappGuide.settingUpAdminAlerts",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223766-setting-up-admin-alerts",
  },
  {
    label: "inappGuide.understandingApprovalPolicy",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223187-setting-up-approval-policy",
  },
  {
    label: "inappGuide.understsandingSubmissionPolicy",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223765-setting-up-submission-policy",
  },
  {
    label: "inappGuide.understandingVolopayLedger",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223162-understanding-volopay-ledger",
  },
  {
    label: "inappGuide.howToCreateAVirtualCardAsAnAdmin?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223774-how-to-create-a-virtual-card-as-an-admin",
  },
  {
    label: "inappGuide.orderAPersonalisedPhysicalCard",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223235-order-a-personalised-physical-card",
  },
  {
    label: "inappGuide.orderPhysicalCardsInBulk",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223236-order-physical-cards-in-bulk",
  },
  {
    label: "inappGuide.howToSwitchPhysicalCardLinkedTo",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223203-how-to-switch-physical-card-linked-to",
  },
  {
    label: "inappGuide.updatePhysicalCardLimitOrFrequency",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223198-update-physical-card-limit",
  },
  {
    label: "inappGuide.exploreYourCompany’sExpense",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223262-explore-your-company-s-expense",
  },
  {
    label: "inappGuide.remindingUsersToProvideMissingExpenseDetails",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223263-reminder-s-for-missing-details",
  },
  {
    label: "inappGuide.trackYouCompany’sExpense",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223262-explore-your-company-s-expense",
  },
  {
    label: "inappGuide.dismissingSubmissionPolicyForAnExpense",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223266-dismissing-submission-policy-for-an-expense",
  },
  {
    label: "inappGuide.howToApproveAnExpense",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223270-how-to-approve-an-expense",
  },
  {
    label: "inappGuide.howToFlagAnExpense",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223228-what-is-a-flagged-expense",
  },
  {
    label: "inappGuide.howToSet-upExpenseReviewPolicy?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223271-setting-up-expense-review-policy",
  },
  {
    label: "inappGuide.howToAddAnExpenseReviewer",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223271-setting-up-expense-review-policy",
  },
  {
    label: "inappGuide.howToAuto-flagAnExpense",
    redirectionUrl: "https://help.volopay.co/en/articles/9223231-auto-flag",
  },
  {
    label: "inappGuide.whatIsAFlaggedExpense?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223228-what-is-a-flagged-expense",
  },
  {
    label: "inappGuide.howToAddAnExpenseReviewer",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223271-setting-up-expense-review-policy",
  },
  {
    label: "inappGuide.howToApproveAFlaggedExpense",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223228-what-is-a-flagged-expense",
  },
  {
    label: "inappGuide.requestingRepaymentOfAFlaggedExpense",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223324-requesting-repayment-against-a-flagged-expense",
  },
  {
    label: "inappGuide.whatIsAFlaggedExpense?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223228-what-is-a-flagged-expense",
  },
  {
    label: "inappGuide.requesting/CancellingRepaymentOfAFlaggedExpense",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223324-requesting-repayment-against-a-flagged-expense",
  },
  {
    label: "inappGuide.howToAuto-flagAnExpense",
    redirectionUrl: "https://help.volopay.co/en/articles/9223231-auto-flag",
  },
  {
    label: "inappGuide.howToCreateAnExpenseFlagRule?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223515-how-to-create-an-expense-flag-rule",
  },
  {
    label: "inappGuide.whatIsExpenseAlerts?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223525-what-is-expense-alerts",
  },
  {
    label: "inappGuide.howToSet-upExpenseReviewPolicy?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223271-setting-up-expense-review-policy",
  },
  {
    label: "inappGuide.howToApprove/rejectARequest?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223183-how-to-approve-reject-a-request",
  },
  {
    label: "inappGuide.addingAUserAsAnCardManager",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223526-card-expense-manager-permission",
  },
  {
    label: "inappGuide.addingAUserAsAnExpenseManager",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223526-card-expense-manager-permission",
  },
  {
    label: "inappGuide.modifyingCardsApprovalPolicy",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223187-setting-up-approval-policy",
  },
  {
    label: "inappGuide.modifyingExpenseSubmissionPolicy",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223765-setting-up-submission-policy",
  },
  {
    label: "inappGuide.howToSplitAnExpense?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223218-how-to-split-an-expense",
  },
  {
    label: "inappGuide.howToEditLimitOfVirtualCards?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223210-how-to-edit-limit-of-virtual-cards",
  },
  {
    label: "inappGuide.settingUpApprovalPolicy",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223187-setting-up-approval-policy",
  },
  {
    label: "inappGuide.howToSyncATransaction?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223155-how-to-sync-a-transaction",
  },
  {
    label: "inappGuide.howToExportLedgerData?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223168-how-to-export-ledger-data",
  },
  {
    label: "inappGuide.understandingVolopayLedger",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223162-understanding-volopay-ledger",
  },
  {
    label: "inappGuide.howToCustomiseLedgerColumns?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223170-how-to-customise-ledger-columns",
  },
  {
    label: "inappGuide.overviewOfAccountingTransactions",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223138-accounting-transactions",
  },
  {
    label: "inappGuide.syncingTransactionsToYouAccountingSoftware",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223155-how-to-sync-a-transaction",
  },
  {
    label: "inappGuide.whatIsSyncHistory?",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223160-what-is-sync-history",
  },
  {
    label: "inappGuide.typesOfOtherAccountingTransactions",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223172-types-of-other-accounting-transactions",
  },
  {
    label: "inappGuide.addPersonalBankAccountDetails",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9215656-guide-to-creating-reimbursement-claims-for-employees#h_529dc2d9c1",
  },
  {
    label: "inappGuide.guideForCreatingAReimbursementClaimForEmployees",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9215656-guide-to-creating-reimbursement-claims-for-employees",
  },
  {
    label: "inappGuide.howToCreateAReimbursementClaimViaMail",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9215656-guide-to-creating-reimbursement-claims-for-employees#h_bc567b2c77",
  },
  {
    label: "inappGuide.howToCreateAMileageClaim",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9215656-guide-to-creating-reimbursement-claims-for-employees#h_4a4ebd74bd",
  },
  {
    label: "inappGuide.editAReimbursementClaim",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9215263-editing-your-reimbursement-claim",
  },
  {
    label: "inappGuide.whatIsPaidOutsideVolopay",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9221475-reimbursement-payments#h_2492f5d92d",
  },
  {
    label: "inappGuide.howToSkipApprovalsAsAnAdmin",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223773-approve-or-reject-claims#h_7e881e98e2",
  },
  {
    label: "inappGuide.howToIdentifyDuplicateReimbursements",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223779-how-to-identify-duplicate-reimbursements",
  },
  {
    label: "inappGuide.understandClaimPolicyAndCategoryLimits",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_a2b1c65be3",
  },
  {
    label: "inappGuide.howToAddPaymentInitiators",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_450f2c678a",
  },
  {
    label: "inappGuide.enableAuto-paymentsForReimbursementClaims",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_16b9fa9f74",
  },
  {
    label: "inappGuide.howToPayAReimbursementClaim",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9221475-reimbursement-payments",
  },
  {
    label: "inappGuide.guideToSetUpReimbursementsForAdmins",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins",
  },
  {
    label: "inappGuide.howToSyncReimbursementClaimsToYourAccountingSoftware",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223155-how-to-sync-a-transaction",
  },
  {
    label: "inappGuide.methodsOfReimbursementClaimCreation",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9215656-guide-to-creating-reimbursement-claims-for-employees#h_23c2f26489",
  },
  {
    label: "inappGuide.setupApprovalPolicies",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_4afbd1a0f7",
  },
  {
    label: "inappGuide.setupSubmissionPolicies",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_bb523563b1",
  },
  {
    label: "inappGuide.creatingCustomFieldsForReimbursements",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223782-overview-of-accounting-settings#h_6daf0ef079",
  },
  {
    label: "inappGuide.howToAddBudgetsForReimbursements",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223794-overview-of-budgets",
  },
  {
    label:
      "inappGuide.howToSyncPaidReimbursementClaimsToYourAccountingSoftware",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223155-how-to-sync-a-transaction",
  },
  {
    label: "inappGuide.guideToReimbursementForAdmins",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins",
  },
  {
    label: "inappGuide.howToPayAnApprovedClaim",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9221475-reimbursement-payments",
  },
  {
    label: "inappGuide.assignCustomRolesToUsersForReimbursementManagement",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223807-creating-a-new-custom-role",
  },
  {
    label: "inappGuide.howToSetBudgetsForReimbursements",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223794-overview-of-budgets",
  },
  {
    label: "inappGuide.createCustomFieldsForReimbursements",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223782-overview-of-accounting-settings#h_6daf0ef079",
  },
  {
    label: "inappGuide.guideOnCreatingBillPayments",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223801-guide-on-creating-bill-payments",
  },
  {
    label: "inappGuide.howToCreateABillPaymentViaEmail",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223801-guide-on-creating-bill-payments",
  },
  {
    label: "inappGuide.editABillPayment",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223805-edit-a-bill-payment",
  },
  {
    label: "inappGuide.howToCreateAScheduledOrUnscheduledPayment",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223801-guide-on-creating-bill-payments#h_638f8c3684",
  },
  {
    label: "inappGuide.howPaymentLinkedToWorks",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223810-bill-payment-linkage",
  },
  {
    label: "inappGuide.howRecurringPaymentWorks",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223812-recurring-bill-payments",
  },
  {
    label: "inappGuide.checkPaymentHistoryForVendor",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223823-overview-of-vendors-in-bill-pay#h_fadd9083cb",
  },
  {
    label: "inappGuide.identifyDuplicateBills",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223826-identify-duplicate-bill-payments",
  },
  {
    label: "inappGuide.archiveABillPayment",
    redirectionUrl: "http://help.volopay.co/en/articles/9223827#h_4f84eeb6b1",
  },
  {
    label: "inappGuide.howToSkipApprovalsAsAnAdmin",
    redirectionUrl: "http://help.volopay.co/en/articles/9223830#h_52ea72d9eb",
  },
  {
    label: "inappGuide.guideToPayVendors",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223832-guide-to-pay-vendors",
  },
  {
    label: "inappGuide.whoCanInitiateBillPayments",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223832-guide-to-pay-vendors#h_551550d1eb",
  },
  {
    label: "inappGuide.retryFailedPayments",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223847-retry-failed-bill-payments",
  },
  {
    label: "inappGuide.howToDownloadProofOfPayment",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223848-how-to-download-proof-of-payment",
  },
  {
    label: "inappGuide.recreateABillPayment",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223819-recreate-a-bill-payment",
  },
  {
    label: "inappGuide.exportingAllPaidPaymentsAsCSVUsingFilters",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223851-export-all-paid-payments",
  },
  {
    label: "inappGuide.understandVendorCreationInVolopay",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223823-overview-of-vendors-in-bill-pay#h_9bcdfae105",
  },
  {
    label: "inappGuide.manageVendorsInVolopay",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223823-overview-of-vendors-in-bill-pay",
  },
  {
    label: "inappGuide.editVendorDetails",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223823-overview-of-vendors-in-bill-pay#h_83de210c26",
  },
  {
    label: "inappGuide.whoIsAVendorOwner?",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223823-overview-of-vendors-in-bill-pay#h_865f18777f",
  },
  {
    label: "inappGuide.howToDeleteAVendor",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223823-overview-of-vendors-in-bill-pay#h_2394fd14bf",
  },
  {
    label: "inappGuide.howVolopayCalculatesExchangeRates",
    redirectionUrl: "http://help.volopay.co/en/articles/9223854-exchange-rates",
  },
  {
    label: "inappGuide.whatCurrenciesDoesVolopaySupport?",
    redirectionUrl: "http://help.volopay.co/en/articles/9223854-exchange-rates",
  },
  {
    label: "inappGuide.guideToSetUpBillPayForAdmins",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223816-guide-to-setup-bill-pay",
  },
  {
    label: "inappGuide.syncBillPaymentsToYourAccountingSoftware",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223155-how-to-sync-a-transaction",
  },
  {
    label: "inappGuide.setupApprovalPolicy",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223816-guide-to-setup-bill-pay#h_9ab474421e",
  },
  {
    label: "inappGuide.setupSubmissionPolicy",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223816-guide-to-setup-bill-pay#h_f7e990ac70",
  },
  {
    label: "inappGuide.createCustomFieldsForBillPay",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223782-overview-of-accounting-settings#h_6daf0ef079",
  },
  {
    label: "inappGuide.whatIsContinuousSyncForBillPay",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223821-guide-to-integrate-with-an-accounting-software#h_a3e9bd9ba2",
  },
  {
    label: "inappGuide.howToAddPaymentInitiators",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223816-guide-to-setup-bill-pay#h_93b02d21b8",
  },
  {
    label: "inappGuide.howToSetUpAutoPaymentsForBillPayments",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223816-guide-to-setup-bill-pay#h_d043b0b94b",
  },
  {
    label: "inappGuide.addBudgetsForBillPayments",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223794-overview-of-budgets",
  },
  {
    label: "inappGuide.howToApproveOrRejectABillPayment",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223830-approve-or-reject-a-bill-payment",
  },
  {
    label: "inappGuide.assignCustomRolesToUsersForBillPaymentManagement",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223807-creating-a-new-custom-role",
  },
  {
    label: "inappGuide.howToSetBudgetsForBillPay",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223794-overview-of-budgets",
  },
  {
    label: "inappGuide.howToConnectVolopayWithAnAccountingSoftware",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223821-guide-to-integrate-with-an-accounting-software",
  },
  {
    label: "inappGuide.howUniversalCSVWorks",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223904-integrate-with-universal-csv",
  },
  {
    label: "inappGuide.howToAutomateYourAccountingProcessUsingAccountingRules",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223782-overview-of-accounting-settings#h_cc7f1de6e1",
  },
  {
    label: "inappGuide.howToUseCustomFieldsInSubmissionPolicy",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223782-overview-of-accounting-settings#h_6daf0ef079",
  },
  {
    label: "inappGuide.syncingTransactionsToYourAccountingSoftware",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223155-how-to-sync-a-transaction",
  },
  {
    label: "inappGuide.overviewOfVolopayLedger",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223162-understanding-volopay-ledger",
  },
  {
    label: "inappGuide.guideOnSettingUpAccountingForAdmins",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223906-guide-to-setup-accounting",
  },
  {
    label: "inappGuide.assignCustomRolesToUsersForAccountingManagement",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223807-creating-a-new-custom-role",
  },
  {
    label: "inappGuide.inviteAnExternalAccountant",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223798-inviting-a-users-to-volopay",
  },
  {
    label: "inappGuide.saveBillsAsDraft",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223801-guide-on-creating-bill-payments",
  },
  {
    label: "inappGuide.magicScanForInvoicePayments",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223801-guide-on-creating-bill-payments",
  },
  {
    label: "inappGuide.manualBillPaymentCreation",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223801-guide-on-creating-bill-payments",
  },
  {
    label: "inappGuide.scheduleApprovedPayments",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223801-guide-on-creating-bill-payments#h_638f8c3684",
  },
  {
    label: "inappGuide.aPClerks&PaymentInitiators",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223816-guide-to-setup-bill-pay#h_93b02d21b8",
  },
  {
    label: "inappGuide.automaticPayments",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9223816-guide-to-setup-bill-pay#h_d043b0b94b",
  },
  {
    label: "inappGuide.manualReimbursementClaims",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9215656-guide-to-creating-reimbursement-claims-for-employees#h_30b8a43db2",
  },
  {
    label: "inappGuide.useMagicScanForClaims",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9215656-guide-to-creating-reimbursement-claims-for-employees#h_09e38fa89c",
  },
  {
    label: "inappGuide.approveOrRejectClaims",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9223773-approve-or-reject-claims",
  },
  {
    label: "inappGuide.payReimbursementClaims",
    redirectionUrl:
      "https://help.volopay.co/en/articles/9221475-reimbursement-payments",
  },
  {
    label: "inappGuide.exportPaidClaims",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216540-export-paid-claims",
  },
  {
    label: "inappGuide.automaticPayments",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_16b9fa9f74",
  },
  {
    label: "inappGuide.addPaymentInitiators",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_450f2c678a",
  },
  {
    label: "inappGuide.setMileageRatesForDifferentCountries",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_eb35c0f191",
  },
  {
    label: "inappGuide.setupApprovalPolicy",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_4afbd1a0f7",
  },
  {
    label: "inappGuide.setupSubmissionPolicy",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_bb523563b1",
  },
  {
    label: "inappGuide.setupClaimPolicy",
    redirectionUrl:
      "http://help.volopay.co/en/articles/9216287-guide-to-setup-reimbursements-for-admins#h_a2b1c65be3",
  },
];

export const CARD_PROVIDER_API_TRIGGER_ENDPOINT_KEY = {
  [COUNTRY_CODE_ENUM.AUS]: "au",
  [COUNTRY_CODE_ENUM.IDN]: "id",
  [COUNTRY_CODE_ENUM.IND]: "in",
  [COUNTRY_CODE_ENUM.SG]: "sg",
};
