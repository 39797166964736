import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage } from "@/utils/common";
import { COMMENTS_PAGINATION_LIMIT_PER_REQUEST } from "@/utils/constants/payments";
import vToast from "@/utils/vToast";
import API from "@/api";

const initialState = {
  comments: {
    list: [],
    page: 0,
    limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
    total: 0,
    hasMore: false,
  },
  isFetching: false,
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    setComments(state, action) {
      state.comments.list = action.payload;
    },
    setCommentsInitialState(state) {
      state.comments.list = [];
      state.comments.page = 0;
      state.comments.limit = COMMENTS_PAGINATION_LIMIT_PER_REQUEST;
      state.comments.total = 0;
      state.comments.hasMore = false;
    },
    setIsFetchingComments(state, action) {
      state.isFetching = action.payload;
    },
    addComment(state, action) {
      state.comments.list = [...state.comments.list, action.payload];
    },
    appendToCommentsList(state, action) {
      const listToAppend = action.payload;
      state.comments.list = state.comments.list.concat(listToAppend);
    },
    setCommentsLimit(state, action) {
      state.comments.limit = action.payload;
    },
    setCommentsPage(state, action) {
      state.comments.page = action.payload;
    },
    setCommentsTotal(state, action) {
      state.comments.total = action.payload;
    },
    setCommentsHasMore(state) {
      state.comments.hasMore =
        state.comments.list.length < state.comments.total;
    },
  },
});

export const fetchComments = createAsyncThunk(
  "comments/fetchComments",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingComments(true));

    const [error, response] = await to(API.Misc.getComments(params));

    if (response?.data) {
      // Do something

      if (params.page === 1) {
        dispatch(setComments(response?.data?.list));
      } else {
        dispatch(appendToCommentsList(response?.data.list));
      }

      dispatch(setCommentsLimit(response?.data.limit));
      dispatch(setCommentsTotal(response?.data.total));
      dispatch(setCommentsPage(response?.data.page));
      dispatch(setCommentsHasMore());
    } else if (error) {
      // error do something
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingComments(false));
  }
);
export const addComments = createAsyncThunk(
  "comments/addComments",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingComments(true));

    const { payload, onSuccess = () => {} } = params;

    const [error, response] = await to(API.Misc.createComments(payload));

    if (response) {
      dispatch(addComment(response.data));
      onSuccess();
    } else {
      // error do something
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingComments(false));
  }
);
export const {
  setComments,
  addComment,
  setIsFetchingComments,
  setCommentsHasMore,
  setCommentsLimit,
  setCommentsPage,
  setCommentsTotal,
  appendToCommentsList,
  setCommentsInitialState,
} = commentsSlice.actions;
export default commentsSlice.reducer;
