import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { orderCard, setOrderCardStatus } from "@/store/reducers/cards";

import {
  createVirtualCardInputSelector,
  merchantListSelector,
  orderCardStatusSelector,
  orderVirtualCardStepsSelector,
} from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";
import { clientSelector } from "@/store/selectors/client";
import { projectsSelector } from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  accountingTagsSelector,
  customTagsSelector,
} from "@/store/selectors/tags";
import { selectedUserSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import ApproversSectionComponent from "@/components/Cards/Sliders/OrderPhysicalCardSlider/ApproversSection";
import { triggerCardsTableComponent } from "@/components/Cards/util";
import EntityListView, {
  renderingListType,
} from "@/components/common/EntityListView";
import FormSummaryInfo from "@/components/common/FormSummaryInfo";
import { convertFilters } from "@/utils/filters";
import {
  capitalizeFirstLetter,
  createFileFormData,
  dateToString,
  objectToFormData,
} from "@/utils/common";

import { CARD_APPROVAL_REQUEST_TYPE } from "@/constants/ActionCentre";
import {
  CARD_FREQUENCY_LABEL,
  CREATE_VIRTUAL_CARD_FIELD_KEYS,
  LINKED_TO_TYPE,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import {
  getCardRulesForReview,
  getMccListForPreview,
  getPayloadForUpdatingMccDetails,
  getPayloadForUploadingCardRules,
} from "../CardMerchantCategoryControls/util";

export default function ReviewCreateVirtualCardDetails({
  outerSectionClasses,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const virtualCardFormFieldsInfo = useSelector(createVirtualCardInputSelector);
  const orderCardStatus = useSelector(orderCardStatusSelector);
  const projectId = virtualCardFormFieldsInfo?.project_id;
  const amount = virtualCardFormFieldsInfo?.amount;
  const currency = virtualCardFormFieldsInfo?.currency;
  const virtualCardsSteps = useSelector(orderVirtualCardStepsSelector);
  const generalSection = virtualCardsSteps?.sections
    ? virtualCardsSteps?.sections[0]?.fields
    : [];

  const ref = useLeftHeaderTitle({
    title: "cards.vCards.createCardSlider.title",
  });

  const categoryList = useSelector(categoriesListSelector);
  const merchantList = useSelector(merchantListSelector);
  const accountingTags = useSelector(accountingTagsSelector);
  const customTags = useSelector(customTagsSelector);

  const projectsList = useSelector(projectsSelector);
  const clientInfo = useSelector(clientSelector);
  const cardsMakerCheckerEnabled = clientInfo?.cardsMakerCheckerEnabled;
  const cardHoldersOptions = generalSection?.[0]?.options;
  const pageAppliedFilters = convertFilters(useSelector(appliedFilterSelector));
  const locationObject = useLocation();

  const cardHolderName = useMemo(() => {
    if (generalSection) {
      const userIds =
        generalSection?.find(
          (fields) => fields.key === CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER
        )?.options || [];

      return (
        userIds?.find(
          (user) =>
            user?.key ===
            virtualCardFormFieldsInfo?.[
              CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER
            ]
        )?.value || ""
      );
    }
    return "";
  }, [generalSection]);

  const ORDER_VIRTUAL_CARD_REQUEST_SUMMARY_CONFIG = [
    {
      name: CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_NAME,
      label: "Card name",
      show: true,
      value:
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_NAME],
    },
    {
      name: CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER,
      label: "Cardholder",
      show: true,
      value: cardHolderName,
    },
    {
      name: CREATE_VIRTUAL_CARD_FIELD_KEYS.AMOUNT,
      label: "Amount",
      value: currency
        ? `${
            virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.AMOUNT]
          } ${currency}`
        : "",
      show: true,
    },
    {
      name: CREATE_VIRTUAL_CARD_FIELD_KEYS.FREQUENCY,
      label: "Frequency",
      value:
        CARD_FREQUENCY_LABEL?.[
          virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.FREQUENCY]
        ],
      show: true,
    },
    {
      name: CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO,
      label: "Linked to",
      value: `${capitalizeFirstLetter(
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO]
      )} : ${getLinkedToValue()}`,
      show: true,
    },
    {
      name: CREATE_VIRTUAL_CARD_FIELD_KEYS.AUTO_BLOCK_DATE,
      label: "Auto-block date",
      value: dateToString(
        virtualCardFormFieldsInfo?.[
          CREATE_VIRTUAL_CARD_FIELD_KEYS.AUTO_BLOCK_DATE
        ]
      ),
      show: true,
    },
    {
      name: CREATE_VIRTUAL_CARD_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT,
      label: "Transaction Amount Limit",
      value: `${
        virtualCardFormFieldsInfo?.[
          CREATE_VIRTUAL_CARD_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT
        ]
      } ${currency}`,
      show: virtualCardFormFieldsInfo?.[
        CREATE_VIRTUAL_CARD_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT
      ],
      labelContainerClass: "w-full",
    },
  ];

  const merchantAndCategoryControlsList = useMemo(() => {
    return getMccListForPreview(
      virtualCardFormFieldsInfo?.[
        CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL
      ],
      categoryList,
      merchantList
    );
  }, [virtualCardFormFieldsInfo]);

  const cardRulesList = useMemo(() => {
    return getCardRulesForReview(
      virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES],
      [...accountingTags, ...customTags]
    );
  }, [virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES]]);

  useEffect(() => {
    if (orderCardStatus) {
      dispatch(setOrderCardStatus(false));
      closeCreateVirtualCardSlider();
    }
  }, [orderCardStatus]);

  function closeSlider() {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.reviewVCardDetails
    );
    setSearchParams(searchParams);
  }

  function closeCreateVirtualCardSlider() {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.reviewVCardDetails
    );

    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage
    );

    setSearchParams(searchParams);
  }

  function handleSubmit() {
    const transactionAmountLimitValue =
      virtualCardFormFieldsInfo?.[
        CREATE_VIRTUAL_CARD_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT
      ];

    const payload = {
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.AMOUNT]:
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.AMOUNT],
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.FREQUENCY]:
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.FREQUENCY],
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_NAME]:
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_NAME],
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.CURRENCY]:
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.CURRENCY],
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.PROJECT_ID]:
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.PROJECT_ID],
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.MEMO]:
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.MEMO],
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER]: [
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER],
      ],
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.AUTO_BLOCK_DATE]:
        virtualCardFormFieldsInfo?.[
          CREATE_VIRTUAL_CARD_FIELD_KEYS.AUTO_BLOCK_DATE
        ],
      ...(transactionAmountLimitValue
        ? {
            [CREATE_VIRTUAL_CARD_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT]:
              transactionAmountLimitValue,
          }
        : {}),
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT]: null,
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES]: {
        tags: getPayloadForUploadingCardRules(
          virtualCardFormFieldsInfo?.[
            CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES
          ] || {}
        ),
      },
      type: CARD_APPROVAL_REQUEST_TYPE.VIRTUAL,
    };

    const merchantDetailsResponse = getPayloadForUpdatingMccDetails(
      virtualCardFormFieldsInfo?.[
        CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL
      ] || {}
    );

    if (merchantDetailsResponse) {
      payload[CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL] =
        getPayloadForUpdatingMccDetails(
          virtualCardFormFieldsInfo?.[
            CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL
          ] || {}
        );
    }

    if (virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT]) {
      const documentsPayload = createFileFormData(
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT]
      );

      payload[CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT] = [
        ...(documentsPayload?.values() ?? []),
      ];
    }

    Object.entries(
      virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES] ||
        {}
    )?.forEach((ruleArray) => {
      payload.rule_attributes.tags.push({
        tag_id: Number(ruleArray?.[0]),
        tag_value_id: ruleArray?.[1],
      });
    });

    if (payload?.rule_attributes?.tags?.length <= 0) {
      delete payload?.rule_attributes;
    }

    const formDataPayload = objectToFormData({
      ...payload,
    });

    dispatch(
      orderCard({
        payload: formDataPayload,
        onSuccess: () =>
          triggerCardsTableComponent(
            dispatch,
            pageAppliedFilters,
            locationObject
          ),
      })
    );
  }

  function openControlsHandler() {
    searchParams.set(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.controlsPage,
      true
    );
    setSearchParams(searchParams);
  }

  function openRulesHandler() {
    searchParams.set(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.rulesPage,
      true
    );
    setSearchParams(searchParams);
  }

  function getLinkedToValue() {
    if (
      virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO] ===
      LINKED_TO_TYPE.DEPARTMENT.toLowerCase()
    ) {
      return cardHoldersOptions?.find((userInfo) => {
        return (
          userInfo?.key ===
          virtualCardFormFieldsInfo?.[
            CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER
          ]
        );
      })?.departmentName;
    }

    return projectsList?.find(
      (project) =>
        project?.id ===
        virtualCardFormFieldsInfo?.[CREATE_VIRTUAL_CARD_FIELD_KEYS?.PROJECT_ID]
    )?.name;
  }

  return (
    <>
      <div
        className={`slider-content-core flex flex-col gap-7  mb-10 ${outerSectionClasses}`}
      >
        <div className="flex flex-col gap-3 mb-8">
          <Text
            refProp={ref}
            translationKey="cards.vCards.createCardSlider.title"
            classes="text-3xl text-neutral-800 font-bold"
          />

          <Text
            translationKey="cards.cardDetails.editCardDetails.reviewSection.description"
            classes="text-sm text-neutral-500 font-medium"
          />
        </div>

        <FormSummaryInfo
          formFields={ORDER_VIRTUAL_CARD_REQUEST_SUMMARY_CONFIG}
        />

        {merchantAndCategoryControlsList[0]?.list?.length ? (
          <EntityListView
            list={merchantAndCategoryControlsList}
            type={renderingListType.CATEGORIZED}
            count={merchantAndCategoryControlsList[0]?.list?.length || 0}
            showCount
            showEditIcon
            handleEditClick={openControlsHandler}
            mainHeading="cards.cardDetails.editCardDetails.entityListView.merchantAndCategoryControls.mainHeading"
            headingDescription="cards.cardDetails.editCardDetails.entityListView.merchantAndCategoryControls.headingDescription"
          />
        ) : null}

        {cardRulesList?.length > 0 ? (
          <EntityListView
            list={cardRulesList}
            mainHeading="cards.cardDetails.editCardDetails.entityListView.cardRules.mainHeading"
            headingDescription="cards.cardDetails.editCardDetails.entityListView.cardRules.headingDescription"
            type={renderingListType.PLAIN}
            count={cardRulesList?.length || 0}
            showCount
            showEditIcon
            handleEditClick={openRulesHandler}
          />
        ) : null}

        <ApproversSectionComponent
          params={{
            amount,
            currency,
            project_id: projectId,
            policy_group_type: "cards",
          }}
          approvalDescription="cards.pCards.sliders.createPhysicalCardsDetailsSummary.approvers.approvalDescription"
          preApprovalDescription="cards.pCards.sliders.createPhysicalCardsDetailsSummary.approvers.preApprovalDescription"
          makerCheckerStatus={cardsMakerCheckerEnabled}
        />
      </div>

      <div className="slider-footer">
        <div className="flex flex-row items-center justify-end gap-6 p-4">
          <Button
            label="cards.pCards.sliders.createCardSlider.buttons.cancel"
            variant="tertiary"
            classes="w-[150px] text-neutral-500 text-btn-lg bg-white hover:bg-neutral-300 border border-neutral-300"
            onClick={closeSlider}
          />

          <Button
            label="cards.cardDetails.editCardDetails.requestCardButton"
            classes="w-[150px] text-white text-btn-lg"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}

ReviewCreateVirtualCardDetails.propTypes = {
  outerSectionClasses: PropTypes.string,
};
