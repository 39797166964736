import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchRuleItemTypes, rulePresent } from "@/store/reducers/rules";

import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";
import {
  ruleItemTypesSelector,
  rulePresentSelector,
} from "@/store/selectors/rules";
import { listTypeTagsSelector } from "@/store/selectors/tags";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import { MAPPING_TYPE } from "@/utils/constants/rules";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARES_TAG_TITLES } from "@/constants/accounting";
import { VP_FIELD_MAPPING } from "@/constants/rules";

export default function NewRuleInit({ cancelCreate }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);

  const listTypeTags = useSelector(listTypeTagsSelector);

  const formattedListTypeTags = listTypeTags?.map((tag) => {
    return { id: tag.id, name: tag.name };
  });

  const volopayFields = useSelector(ruleItemTypesSelector);

  const mappedVolopayFields = volopayFields?.map((value, index) => {
    return {
      id: index,
      name: VP_FIELD_MAPPING[value],
      type: value,
    };
  });

  const [accountingFields, setAccountingFields] = useState([]);

  useEffect(() => {
    setAccountingFields(formattedListTypeTags);
  }, []);

  useEffect(() => {
    const accountingVendor = {
      name: `${ACCOUNTING_SOFTWARES_TAG_TITLES[accountingSoftware]} vendor`,
    };
    setAccountingFields((prev) => [...prev, accountingVendor]);
  }, [accountingSoftware]);

  const isRulePresent = useSelector(rulePresentSelector);

  const confirmCreate = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.rules.newEditRule,
      MAPPING_TYPE.NEW_RULE
    );
    searchParams.append("volopayField", volopayField);
    searchParams.append("accountingField", accountingField);
    setSearchParams(searchParams);
  };

  const [volopayField, setVolopayField] = useState({});
  const [accountingField, setAccountingField] = useState({});
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);

  const handleVpFieldChange = (data) => {
    setVolopayField(data?.type);
  };

  const handleAccountingChange = (data) => {
    setAccountingField(data?.name);
  };

  useEffect(() => {
    if (
      Object.keys(volopayField).length !== 0 &&
      Object.keys(accountingField).length !== 0
    ) {
      dispatch(
        rulePresent({
          payload: {
            rule_item_types: [volopayField],
            rule_action_owner_types: [accountingField],
          },
        })
      );
      setIsCreateDisabled(isRulePresent);
    }
  }, [volopayField, accountingField, isRulePresent]);

  useEffect(() => {
    dispatch(fetchRuleItemTypes());
  }, []);

  return (
    <div className="flex flex-col mt-9">
      <div className="flex items-center justify-between">
        <span className="text-lg">
          <Text translationKey="accounting.rules.newRule.title" />
        </span>
        <div onClick={cancelCreate} className="cursor-pointer">
          <Icon name="Close" className="text-neutral-300" />
        </div>
      </div>
      <div className="flex flex-col gap-4 px-6 py-4 mt-3 border rounded-lg border-neutral-200">
        <VpSelect
          label="accounting.rules.newRule.volopayField"
          placeholder="accounting.rules.newRule.volopayField"
          menuPosition="absolute"
          options={mappedVolopayFields}
          optionsDisplayKey="name"
          handleChange={(data) => handleVpFieldChange(data)}
          value={volopayField}
          valueKey="type"
          classes="mt-4"
        />
        <div className="flex items-center gap-3 mt-3">
          <div className="flex items-center justify-start gap-2">
            <Icon
              name="LightiningBolt"
              className="rounded-full text-primary-300"
            />
            <Icon name="DownRight" className="text-neutral-500" />
          </div>
          <div className="w-full">
            <VpSelect
              label="accounting.integrations.labels.accountingField"
              labelTranslationProp={{
                accountingSoftware:
                  ACCOUNTING_SOFTWARES_TAG_TITLES[accountingSoftware],
              }}
              placeholder="accounting.integrations.labels.accountingField"
              placeholderTranslationProp={{
                accountingSoftware:
                  ACCOUNTING_SOFTWARES_TAG_TITLES[accountingSoftware],
              }}
              menuPosition="absolute"
              options={accountingFields}
              optionsDisplayKey="name"
              handleChange={(data) => handleAccountingChange(data)}
              value={accountingField}
              valueKey="name"
            />
          </div>
        </div>
        <div className="flex items-center justify-end gap-3 mt-3 ">
          {isRulePresent ? (
            <Text translationKey="accounting.rules.newRule.ruleAlreadyPresent" />
          ) : null}
          <Button
            label="accounting.rules.newRule.buttonLabels.createRule"
            classes="w-fit px-4 h-8 text-xs font-semibold"
            variant="secondary"
            disabled={isCreateDisabled}
            onClick={confirmCreate}
          />
        </div>
      </div>
    </div>
  );
}

NewRuleInit.propTypes = {
  cancelCreate: PropTypes.func,
};
