import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { t } from "i18next";

import { getErrorToastMessage } from "@/utils/common";
import vToast from "@/utils/vToast";
import API from "@/api";

export const OCR_RESULTS_SLICE_ATTRIBUTE_KEY = "results";

/**
 * To be used inside thunk or slice's reducer
 *
 * @param {{Object=}} actionPayload  thunk's first param or `action.payload` (`action` is the second param of the slice reducer)
 *
 * @returns {{key: OCR_RESULTS_SLICE_ATTRIBUTE_KEY , value: Object=}}
 */

const getListKeyAndPayload = (actionPayload) => {
  actionPayload ??= {};

  // Note: if 'key' is absent, 'value' is the same as payload
  // and it is assumed the operation is meant for purchaseBill, not recurring payments
  // Why do this: keeps existing code simple, i.e. `dispatch(someReducer(valueToSet))` works,
  // as opposed to forcing `dispatch(someReducer({value: valueToSet, key: ...}))` for purchaseBill ops

  return {
    key: actionPayload?.key || OCR_RESULTS_SLICE_ATTRIBUTE_KEY,
    value: actionPayload?.key ? actionPayload?.value : actionPayload,
  };
};

const ocrResultsInitialState = {
  isFetchingOCRResults: false,
  results: [],
  files: [],
  ocrFileUri: "",
  error: null, // for displaying in page, not toaster
};

const ocrResultsSlice = createSlice({
  name: "ocrResults",
  initialState: ocrResultsInitialState,
  reducers: {
    setIntialState: () => ocrResultsInitialState,
    setOcrResults(state, action) {
      const { key, value } = getListKeyAndPayload(action.payload);
      state[key] = value;
    },
    setIsFetchingOCRResults(state, action) {
      state.isFetchingOCRResults = action.payload;
    },
    setUploadedFiles(state, action) {
      state.files = action.payload;
    },
    resetOcrResult(state) {
      return ocrResultsInitialState;
    },
    setOCRError(state, action) {
      state.error = action.payload;
    },
    setOCRFileUri(state, action) {
      state.ocrFileUri = action.payload;
    },
  },
});

export const fetchOCRResults = createAsyncThunk(
  "ocr/results",
  async (params, { dispatch }) => {
    const {
      context = "",
      onSuccess = () => {},
      onError = () => {},
      extraHeaders,
    } = params;
    const { key, value } = getListKeyAndPayload(params);

    dispatch(setIsFetchingOCRResults(true));
    const [err, response] = await to(API.OCR.resultsSync(value, extraHeaders));

    if (!err && response) {
      dispatch(setOcrResults({ value: response.data, key }));
      onSuccess(response.data);
    } else {
      vToast(
        getErrorToastMessage(
          {},
          t("billPay.bill.invoiceInbox.createBill.ocrFailToastTitle"),
          t("billPay.bill.invoiceInbox.createBill.ocrFailToastDescription")
        )
      );
      onError();
    }
    dispatch(setIsFetchingOCRResults(false));
  }
);

export const {
  setIntialState,
  setIsFetchingOCRResults,
  setOcrResults,
  setUploadedFiles,
  resetOcrResult,
  setOCRError,
  setOCRFileUri,
} = ocrResultsSlice.actions;

export default ocrResultsSlice.reducer;
