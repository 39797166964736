import PropTypes from "prop-types";
import React from "react";

function LoadingRenderer({ document, fileName }) {
  const fileText = fileName || document?.fileType || "";

  return <div>loading.... ({fileText ?? ""})</div>;
}

export default LoadingRenderer;
LoadingRenderer.propTypes = {
  document: PropTypes.any,
  fileName: PropTypes.string,
};
