import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import FormSummaryInfo from "@/components/common/FormSummaryInfo";

import {
  BE_FE_KEY_ORDER_TYPE_MAPPING,
  ORDER_TYPE_CONFIG,
} from "@/constants/ActionCentre";
import { BULK_PHYSICAL_CARD_TYPE } from "@/constants/Cards";

export default function ReviewCardOrderRequestDetails({
  data,
  bulkOrderKeysArray,
}) {
  const ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS = {
    NO_OF_CARDS: "noOfCards",
    CARDS_FOR: "users",
    DELIVERY_ADDRESS_LINE_1: "address1",
    DELIVERY_ADDRESS_LINE_2: "address2",
    DELIVERY_CITY: "city",
    DELIVERY_STATE: "state",
    DELIVERY_COUNTRY: "country",
    DELIVERY_POSTAL_CODE: "zipcode",
  };

  const ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_CONFIG = [
    {
      name: ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.NO_OF_CARDS,
      label: "cards.pCards.sliders.createCardSlider.fields.noOfCards.title",
      value: undefined,
      show: true,
    },
    {
      name: ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.CARDS_FOR,
      label: `cards.pCards.sliders.createCardSlider.fields.userIds.${
        data?.cardHolder?.length > 1 ? "multiple" : "individual"
      }`,
      value: undefined,
      valueType: "array",
      show: true,
    },
    {
      name: ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_ADDRESS_LINE_1,
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryAddressLine1.title",
      value: undefined,
      show: true,
    },
    {
      name: ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_ADDRESS_LINE_2,
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryAddressLine2.title",
      value: undefined,
      show: true,
    },
    {
      name: ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_CITY,
      label: "cards.pCards.sliders.createCardSlider.fields.deliveryCity.title",
      value: undefined,
      show: true,
    },
    {
      name: ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_STATE,
      label: "cards.pCards.sliders.createCardSlider.fields.deliveryState.title",
      value: undefined,
      show: true,
    },
    {
      name: ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_COUNTRY,
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryCountry.title",
      value: undefined,
      show: true,
    },
    {
      name: ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_POSTAL_CODE,
      label:
        "cards.pCards.sliders.createCardSlider.fields.deliveryPostalCode.title",
      value: undefined,
      show: true,
    },
  ];

  // const data = useSelector(newdataSelector);

  const [cardOrderRequestSummaryConfig, setCardOrderRequestSummaryConfig] =
    useState(ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_CONFIG);

  if (!data) {
    return null;
  }

  const cardRequestDetailsFormFields = getFormSummaryInfo();

  function getFormSummaryInfo() {
    if (!data) {
      return;
    }

    ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_CONFIG.forEach((field) => {
      const fieldName = field.name;

      switch (fieldName) {
        case ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.CARDS_FOR:
          field.value =
            BE_FE_KEY_ORDER_TYPE_MAPPING[data?.orderType] ===
            BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderPersonalized
              ? data?.cardHolder?.map((user) => user.displayName)
              : null;
          break;

        case ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_ADDRESS_LINE_1:
        case ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_ADDRESS_LINE_2:
        case ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_CITY:
        case ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_COUNTRY:
        case ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_POSTAL_CODE:
        case ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.DELIVERY_STATE:
          field.value = data?.deliveryAddress?.[fieldName];
          break;
        case ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_FIELDS.NO_OF_CARDS:
          field.value = [
            BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderNonPersonalized,
            BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderPersonalized,
          ]?.includes(BE_FE_KEY_ORDER_TYPE_MAPPING[data?.orderType])
            ? data?.[fieldName]
            : null;
          break;
        default:
          field.value = data?.[fieldName];
          break;
      }
      if (field.value === undefined) {
        field.show = false;
      }
    });

    return ORDER_PHYSICAL_CARD_REQUEST_SUMMARY_CONFIG;
  }

  return <FormSummaryInfo formFields={cardRequestDetailsFormFields} />;
}
ReviewCardOrderRequestDetails.propTypes = {
  data: PropTypes.object,
  bulkOrderKeysArray: PropTypes.array,
};
