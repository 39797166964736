import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { ledgerTabsSelector } from "@/store/selectors/ledgers";

import AccountingSettings from "@/components/Accounting/Settings";
import ProtectedRoute from "@/components/ProtectedRoute";

// level 0
import Accounting from "@/pages/accounting";
import Billing from "@/pages/accounting/billing";
import Integrations from "@/pages/accounting/integrations";
import Ledger from "@/pages/accounting/ledger";
// level 1
import Transactions from "@/pages/accounting/transactions";
import ATBillPay from "@/pages/accounting/transactions/bill-pay";
import AccountingPaymentsAll from "@/pages/accounting/transactions/bill-pay/all";
import AccountingPaymentsPending from "@/pages/accounting/transactions/bill-pay/pending";
import AccountingPaymentsSynced from "@/pages/accounting/transactions/bill-pay/synced";
// level 2
import AccountingCards from "@/pages/accounting/transactions/cards";
// components
import AccountingCardsAll from "@/pages/accounting/transactions/cards/all";
import AccountingCardsPending from "@/pages/accounting/transactions/cards/pending";
import AccountingCardsSynced from "@/pages/accounting/transactions/cards/synced";
import ATOthers from "@/pages/accounting/transactions/others";
import AccountingOthersAll from "@/pages/accounting/transactions/others/all";
import AccountingOthersPending from "@/pages/accounting/transactions/others/pending";
import AccountingOthersSynced from "@/pages/accounting/transactions/others/synced";
import ATPayroll from "@/pages/accounting/transactions/payroll";
import AccountingPayrollAll from "@/pages/accounting/transactions/payroll/all";
import AccountingPayrollPending from "@/pages/accounting/transactions/payroll/pending";
import AccountingPayrollSynced from "@/pages/accounting/transactions/payroll/synced";
import ATQRPay from "@/pages/accounting/transactions/qr-pay";
import AccountingQRPayAll from "@/pages/accounting/transactions/qr-pay/all";
import AccountingQRPayPending from "@/pages/accounting/transactions/qr-pay/pending";
import AccountingQRPaySynced from "@/pages/accounting/transactions/qr-pay/synced";
import ATReimbursement from "@/pages/accounting/transactions/reimbursement";
import AccountingReimbursementAll from "@/pages/accounting/transactions/reimbursement/all";
import AccountingReimbursementPending from "@/pages/accounting/transactions/reimbursement/pending";
import AccountingReimbursementSynced from "@/pages/accounting/transactions/reimbursement/synced";
import { ROUTES } from "@/constants/routes";

export default function AccountingRoutes() {
  const ledgerTabs = useSelector(ledgerTabsSelector);

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Accounting />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.accounting.transactions.pathName}
          // element={<Transactions />}
        >
          <Route index element={<Transactions />} />
          <Route
            path={ROUTES.accounting.transactions.cards.pathName}
            element={<AccountingCards />}
          >
            <Route
              path={ROUTES.accounting.transactions.cards.all.pathName}
              element={<AccountingCardsAll />}
            />

            <Route
              path={ROUTES.accounting.transactions.cards.pending.pathName}
              element={<AccountingCardsPending />}
            />

            <Route
              path={ROUTES.accounting.transactions.cards.synced.pathName}
              element={<AccountingCardsSynced />}
            />
          </Route>

          <Route
            path={ROUTES.accounting.transactions.billpay.pathName}
            element={<ATBillPay />}
          >
            <Route
              path={ROUTES.accounting.transactions.billpay.all.pathName}
              element={<AccountingPaymentsAll />}
            />

            <Route
              path={ROUTES.accounting.transactions.billpay.pending.pathName}
              element={<AccountingPaymentsPending />}
            />

            <Route
              path={ROUTES.accounting.transactions.billpay.synced.pathName}
              element={<AccountingPaymentsSynced />}
            />
          </Route>

          <Route
            path={ROUTES.accounting.transactions.reimbursement.pathName}
            element={<ATReimbursement />}
          >
            <Route
              path={ROUTES.accounting.transactions.reimbursement.all.pathName}
              element={<AccountingReimbursementAll />}
            />

            <Route
              path={
                ROUTES.accounting.transactions.reimbursement.pending.pathName
              }
              element={<AccountingReimbursementPending />}
            />

            <Route
              path={
                ROUTES.accounting.transactions.reimbursement.synced.pathName
              }
              element={<AccountingReimbursementSynced />}
            />
          </Route>

          <Route
            path={ROUTES.accounting.transactions.others.pathName}
            element={<ATOthers />}
          >
            <Route
              path={ROUTES.accounting.transactions.others.all.pathName}
              element={<AccountingOthersAll />}
            />

            <Route
              path={ROUTES.accounting.transactions.others.pending.pathName}
              element={<AccountingOthersPending />}
            />

            <Route
              path={ROUTES.accounting.transactions.others.synced.pathName}
              element={<AccountingOthersSynced />}
            />
          </Route>

          <Route
            path={ROUTES.accounting.transactions.qrpay.pathName}
            element={<ATQRPay />}
          >
            <Route
              path={ROUTES.accounting.transactions.qrpay.all.pathName}
              element={<AccountingQRPayAll />}
            />

            <Route
              path={ROUTES.accounting.transactions.qrpay.pending.pathName}
              element={<AccountingQRPayPending />}
            />

            <Route
              path={ROUTES.accounting.transactions.qrpay.synced.pathName}
              element={<AccountingQRPaySynced />}
            />
          </Route>

          <Route
            path={ROUTES.accounting.transactions.payroll.pathName}
            element={<ATPayroll />}
          >
            <Route
              path={ROUTES.accounting.transactions.payroll.all.pathName}
              element={<AccountingPayrollAll />}
            />

            <Route
              path={ROUTES.accounting.transactions.payroll.pending.pathName}
              element={<AccountingPayrollPending />}
            />

            <Route
              path={ROUTES.accounting.transactions.payroll.synced.pathName}
              element={<AccountingPayrollSynced />}
            />
          </Route>
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        {/* Navigating to first tab by default */}
        <Route
          path={ROUTES.accounting.ledger.pathName}
          element={<Navigate to={ledgerTabs.length && ledgerTabs[0].path} />}
        />
        <Route
          path={`${ROUTES.accounting.ledger.pathName}/:type`}
          element={<Ledger />}
        />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.accounting.integrations.pathName}
          element={<Integrations />}
        />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.accounting.billing.pathName}
          element={<Billing />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.accounting.settings.pathName}
          element={<AccountingSettings />}
        />
      </Route>
    </Routes>
  );
}
