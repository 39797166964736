import { createSelector } from "@reduxjs/toolkit";

import { getCardsModuleRequestFilters } from "@/components/Cards/util";
import { ROLES_TYPE } from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/SettingsType";
import { AVAILABLE_FILTERS } from "@/utils/constants/filters";

import { getAvailableOptionBasedOnModule } from "@/utils/common";
import {
  MODULE_REQUEST_TYPES,
  PAYMENTS_ACCOUNT_TYPE,
  PAYROLL_ACCOUNT_TYPE,
} from "@/constants/company";

import { defaultCurrencySelector, primaryCardProviderSelector } from "./client";
import { availableModulesSelector } from "./user";

export const selectCompanyStore = (store) => store.company;

export const selectPeopleStore = createSelector(
  selectCompanyStore,
  (company) => company.people
);

export const companyBillingDetailsStore = createSelector(
  selectCompanyStore,
  (company) => company.companyBillingDetails
);
export const selectPeopleInvitedStore = createSelector(
  selectCompanyStore,
  (company) => company.peopleInvited
);

export const selectDepartmentsStore = createSelector(
  selectCompanyStore,
  (company) => company.departments
);
export const departmentOrProjectFormDataSelector = createSelector(
  selectCompanyStore,
  (company) => company.departmentOrProjectFormData
);
export const selectLocationStore = createSelector(
  selectCompanyStore,
  (company) => company.locations
);
export const selectProjectStore = createSelector(
  selectCompanyStore,
  (company) => company.projects
);
export const selectBudgetStore = createSelector(
  selectCompanyStore,
  (company) => company.budgets
);
export const travelSelector = createSelector(
  selectCompanyStore,
  (company) => company.travel
);

export const selectRolesStore = createSelector(
  selectCompanyStore,
  (company) => company.roles
);

export const isFetchingRolesSelector = createSelector(
  selectCompanyStore,
  (company) => company.isFetchingRoles
);

export const isFetchingRoleDetailsSelector = createSelector(
  selectRolesStore,
  (roles) => roles.isFetchingRoleDetails
);

export const selectPermissionsStore = createSelector(
  selectCompanyStore,
  (company) => company.permissions
);

export const permissionsSelectorList = createSelector(
  selectPermissionsStore,
  (permissions) => permissions.list
);

export const roleDetailsSelector = createSelector(
  selectRolesStore,
  (roles) => roles.roleDetails
);

export const permissionsSelector = createSelector(
  selectPermissionsStore,
  (permissions) => permissions?.permissions
);

export const isLoadingPermissionsSelector = createSelector(
  selectPermissionsStore,
  (permissions) => permissions.isLoadingPermissions
);

export const projectsSelector = createSelector(
  selectProjectStore,
  (projects) => projects.list
);
export const isFetchingProjectsSelector = createSelector(
  selectProjectStore,
  (projects) => projects.isFetchingProjects
);
export const projectsHasMoreSelector = createSelector(
  selectProjectStore,
  (projects) => projects?.hasMoreProjects
);
export const isLoadingProjectsSelector = createSelector(
  selectProjectStore,
  (projects) => projects.isLoading
);
export const budgetSelector = createSelector(
  selectBudgetStore,
  (budgets) => budgets.list
);
export const isFetchingbudgetSelector = createSelector(
  selectBudgetStore,
  (budgets) => budgets.isFetchingBudgets
);
export const budgetsHasMoreSelector = createSelector(
  selectBudgetStore,
  (budgets) => budgets.hasMoreBudgets
);
export const departmentsSelector = createSelector(
  selectDepartmentsStore,
  (departments) => departments.list
);
export const isFetchingDepartmentsSelector = createSelector(
  selectDepartmentsStore,
  (departments) => departments.isFetchingDepartments
);
export const departmentsHasMoreSelector = createSelector(
  selectDepartmentsStore,
  (departments) => departments.hasMoreDepartments
);

export const locationsSelector = createSelector(
  selectLocationStore,
  (locations) => locations?.list
);

export const isFetchingLocationsSelector = createSelector(
  selectLocationStore,
  (locations) => locations?.isFetchingLocations
);

export const isLoadingLocationsSelector = createSelector(
  selectLocationStore,
  (locations) => locations?.isLoading
);

export const locationsHasMoreSelector = createSelector(
  selectLocationStore,
  (locations) => locations?.hasMoreLocations
);

export const selectedLocationSelector = createSelector(
  selectCompanyStore,
  (store) => store.selectedLocation
);

export const peopleSelector = createSelector(
  selectPeopleStore,
  (people) => people?.list
);

export const isFetchingPeopleSelector = createSelector(
  selectPeopleStore,
  (people) => people?.isFetchingPeople
);

export const peopleHasMoreSelector = createSelector(
  selectPeopleStore,
  (people) => people?.hasMorePeople
);

export const peopleTotalSelector = createSelector(
  selectPeopleStore,
  (people) => people?.total
);

export const allPeopleSelector = createSelector(
  selectPeopleStore,
  (people) => people?.shallowList
);

export const allDepartmentSelector = createSelector(
  selectDepartmentsStore,
  (department) => department?.shallowList
);

export const allLocationSelector = createSelector(
  selectLocationStore,
  (location) => location?.shallowList
);

export const peopleInvitedSelector = createSelector(
  selectPeopleInvitedStore,
  (peopleInvited) => peopleInvited?.list
);

export const isFetchingPeopleInvitedSelector = createSelector(
  selectPeopleInvitedStore,
  (peopleInvited) => peopleInvited?.isFetchingPeople
);

export const peopleInvitedPageSelector = createSelector(
  selectPeopleInvitedStore,
  (peopleInvited) => peopleInvited?.page
);

export const peopleInvitedLimitSelector = createSelector(
  selectPeopleInvitedStore,
  (peopleInvited) => peopleInvited?.limit
);
export const isLoadingPeopleInvitedSelector = createSelector(
  selectPeopleInvitedStore,
  (peopleInvited) => peopleInvited?.isLoading
);

export const peopleInvitedHasMoreSelector = createSelector(
  selectPeopleInvitedStore,
  (peopleInvited) => peopleInvited?.hasMorePeople
);

export const peopleInvitedTotalSelector = createSelector(
  selectPeopleInvitedStore,
  (peopleInvited) => peopleInvited?.total
);

export const peopleInvitedCountSelector = createSelector(
  selectCompanyStore,
  (company) => company.peopleInvitedCount
);

export const walletAccountsCompanyStoreSelector = createSelector(
  selectCompanyStore,
  (company) => company.walletAccounts
);
export const walletPaymentAccountsSelector = createSelector(
  walletAccountsCompanyStoreSelector,
  (wallets) =>
    wallets.filter((wallet) => wallet.accountType === PAYMENTS_ACCOUNT_TYPE)
);
export const walletPayrollAccountsCompanyStoreSelector = createSelector(
  walletAccountsCompanyStoreSelector,
  (wallets) =>
    wallets.filter((wallet) => wallet.accountType === PAYROLL_ACCOUNT_TYPE)
);
export const companyFiltersSelectors = createSelector(
  selectCompanyStore,
  (company) => company?.companyFilters
);
export const durationLabelSelectors = createSelector(
  selectCompanyStore,
  (company) => company?.durationLabel
);
export const sortedWalletPaymentAccountsCompanyStoreSelector = createSelector(
  [walletPaymentAccountsSelector, defaultCurrencySelector],
  (wallets, currency) =>
    wallets.sort((a, b) => {
      if (a.currency === currency && b.currency !== currency) {
        return -1; // a comes before b
      }
      if (a.currency !== currency && b.currency === currency) {
        return 1; // b comes before a
      }
      return 0; // no change in order
    })
);
export const isFetchingWalletAccountsCompanyStoreSelector = createSelector(
  selectCompanyStore,
  (company) => company.isFetchingWalletAccounts
);

export const companyPendingActionsSelector = createSelector(
  selectCompanyStore,
  (company) => company.pendingActions
);

export const companyIsFetchingPendingActionsSelector = createSelector(
  selectCompanyStore,
  (company) => company.isFetchingPendingActions
);

// export const cashbacksSelector = createSelector(
//   selectCompanyStore,
//   (company) => company.cashbacks
// );

export const topCountItemsSelector = createSelector(
  selectCompanyStore,
  (company) => company.topCountItems
);

export const topCountItemsListSelector = createSelector(
  topCountItemsSelector,
  (topCountItems) => topCountItems.list
);

export const topCountItemsIsFetchingSelector = createSelector(
  selectCompanyStore,
  (company) => company.isFetchingTopCountItems
);

export const topCountItemsHasMoreSelector = createSelector(
  topCountItemsSelector,
  (topCountItems) => topCountItems.hasMore
);

export const topCountItemsPaginationDataSelector = createSelector(
  topCountItemsSelector,
  (topCountItems) => ({ ...topCountItems, list: [] })
);

export const selectedWalletAccountSelector = createSelector(
  selectCompanyStore,
  (company) => company.selectedWalletAccount
);
export const isFetchingSelectedWalletAccountSelector = createSelector(
  selectCompanyStore,
  (company) => company.isFetchingSelectedWalletAccount
);

export const selectedPaymentOptionDetailsSelector = createSelector(
  selectCompanyStore,
  (company) => company.selectedPaymentOptionDetails
);
export const isFetchingSelectedPaymentOptionDetailsSelector = createSelector(
  selectCompanyStore,
  (company) => company.isFetchingSelectedPaymentOptionDetails
);

export const rolesSelector = createSelector(
  selectRolesStore,
  (roles) => roles.list
);

export const adminRoleIdSelector = createSelector(
  rolesSelector,
  (roles) =>
    roles?.find((currentRole) => currentRole?.name === ROLES_TYPE.ADMIN)?.id
);

export const primaryRolesSelector = createSelector(rolesSelector, (rolesList) =>
  rolesList?.filter((role) => role?.roleType === "primary")
);

export const customRolesSelector = createSelector(rolesSelector, (rolesList) =>
  rolesList?.filter((role) => role?.roleType === "custom")
);

export const filtersKeysSelector = createSelector(
  selectCompanyStore,
  (company) => company.filtersKeys
);

export const departmentsFiltersSelector = createSelector(
  filtersKeysSelector,
  availableModulesSelector,
  (filtersKeys, availableModules) =>
    filtersKeys?.departments?.map((key) =>
      getAvailableOptionBasedOnModule(AVAILABLE_FILTERS[key], availableModules)
    )
);
export const peopleInviteSliderFiltersSelector = createSelector(
  filtersKeysSelector,
  (filtersKeys) =>
    filtersKeys?.peopleInviteSlider?.map((key) => AVAILABLE_FILTERS[key])
);

export const budgetFiltersSelector = createSelector(
  filtersKeysSelector,
  availableModulesSelector,
  (filtersKeys, availableModules) =>
    filtersKeys?.budgets?.map((key) =>
      getAvailableOptionBasedOnModule(AVAILABLE_FILTERS[key], availableModules)
    )
);

export const locationsFiltersSelector = createSelector(
  filtersKeysSelector,
  (filtersKeys) => filtersKeys?.locations?.map((key) => AVAILABLE_FILTERS[key])
);

export const projectsFiltersSelector = createSelector(
  filtersKeysSelector,
  availableModulesSelector,
  (filtersKeys, availableModule) =>
    filtersKeys?.projects?.map((key) =>
      getAvailableOptionBasedOnModule(AVAILABLE_FILTERS[key], availableModule)
    )
);
export const billingFiltersSelector = createSelector(
  filtersKeysSelector,
  (filtersKeys) => filtersKeys?.billing?.map((key) => AVAILABLE_FILTERS[key])
);

export const peopleFiltersSelector = createSelector(
  filtersKeysSelector,
  primaryCardProviderSelector,
  availableModulesSelector,
  (filtersKeys, clientInfo, availableModule) =>
    getCardsModuleRequestFilters(
      filtersKeys?.people,
      clientInfo,
      availableModule
    )
);

export const selectedProjectSelector = createSelector(
  selectCompanyStore,
  (store) => store.selectedProject
);
export const selectedDepartmentSelector = createSelector(
  selectCompanyStore,
  (store) => store.selectedDepartment
);
export const isFetchingSelectedProjectSelector = createSelector(
  selectCompanyStore,
  (store) => store.isFetchingProject
);
export const isFetchingDepartmentSelector = createSelector(
  selectCompanyStore,
  (store) => store.isFetchingDepartment
);

export const selectedBudgetSelector = createSelector(
  selectCompanyStore,
  (store) => store.selectedBudget
);
export const isFetchingSelectedBudgetSelector = createSelector(
  selectCompanyStore,
  (store) => store.isFetchingSelectedBudget
);

export const budgetFormDataSelector = createSelector(
  selectCompanyStore,
  (store) => store.budgetFormData
);
export const reviewBudgetSelector = createSelector(
  selectCompanyStore,
  (store) => store.reviewBudget
);
export const reviewOldBudgetSelector = createSelector(
  selectCompanyStore,
  (store) => store.reviewBudget.oldBudget
);
export const reviewNewBudgetSelector = createSelector(
  selectCompanyStore,
  (store) => store.reviewBudget.newBudget
);
export const projectApproversListSelector = createSelector(
  selectCompanyStore,
  (cards) => cards.projectApprovers
);

export const travelPeopleListSelector = createSelector(
  travelSelector,
  (travel) => travel?.truTripUsers
);
export const isLoginInProgressSelector = createSelector(
  travelSelector,
  (travel) => travel?.isLoginInProgress
);
export const isLoadingTravelSelector = createSelector(
  travelSelector,
  (travel) => travel?.isLoading
);

export const billingTableDataSelector = createSelector(
  selectCompanyStore,
  (company) => company.billing.list
);
export const isFetchingBillingTableDataSelector = createSelector(
  selectCompanyStore,
  (company) => company.billing.isFetchingTableData
);
export const billingTableTotalSelector = createSelector(
  selectCompanyStore,
  (company) => company.billing.total
);
export const billingTablePageSelector = createSelector(
  selectCompanyStore,
  (company) => company.billing.page
);
export const billingTableHasMoreSelector = createSelector(
  selectCompanyStore,
  (company) => company.billing.hasMore
);

export const pricingPlanUrlSelector = createSelector(
  companyBillingDetailsStore,
  (company) => company.pricingUrl
);

export const billingPlanDetailsSelector = createSelector(
  companyBillingDetailsStore,
  (company) => company.planDetails
);
export const billingPaymentDetailsSelector = createSelector(
  companyBillingDetailsStore,
  (company) => company.paymentDetails
);
export const bannerDetailsSelector = createSelector(
  companyBillingDetailsStore,
  (company) => company.bannerDetails
);
export const upcomingInvoiceDetailsSelector = createSelector(
  selectCompanyStore,
  (company) => company.upcominInvoiceDetails
);
export const invoiceSliderDetailsSelector = createSelector(
  selectCompanyStore,
  (company) => company.invoiceSliderDetails
);

export const pendingRequestTableInfoSelector = createSelector(
  selectCompanyStore,
  (company) => company?.pendingRequestTable
);

export const pendingRequestListSelector = createSelector(
  pendingRequestTableInfoSelector,
  (pendingRequestTableInfo) => pendingRequestTableInfo?.list
);

export const isFetchingPeopleListSelector = createSelector(
  pendingRequestTableInfoSelector,
  (pendingRequestTableInfo) => pendingRequestTableInfo?.isFetching
);

export const hasMorePendingRequestSelector = createSelector(
  pendingRequestTableInfoSelector,
  (pendingRequestTableInfo) => pendingRequestTableInfo?.hasMore
);

export const totalPendingCardsRequestSelector = createSelector(
  pendingRequestTableInfoSelector,
  (pendingRequestTableInfo) => pendingRequestTableInfo?.total
);

export const pendingRequestPageSelector = createSelector(
  pendingRequestTableInfoSelector,
  (pendingRequestTableInfo) => pendingRequestTableInfo?.page
);

export const pendingFilterKeysSelector = createSelector(
  selectCompanyStore,
  (company) => company?.pendingFilterKeys
);

export const pendingCardRequestFilterKeys = createSelector(
  pendingFilterKeysSelector,
  primaryCardProviderSelector,
  (pendingFilterModules, clientInfo) =>
    getCardsModuleRequestFilters(
      pendingFilterModules[MODULE_REQUEST_TYPES.CARD_REQUEST],
      clientInfo
    )
);

export const pendingReimbursementFilterKeys = createSelector(
  pendingFilterKeysSelector,
  (pendingFilterModules) =>
    pendingFilterModules[MODULE_REQUEST_TYPES.REIMBURSEMENT_REQUEST]?.map(
      (reimbursementFilterKey) => AVAILABLE_FILTERS[reimbursementFilterKey]
    )
);

export const alertsSelector = createSelector(
  selectCompanyStore,
  (company) => company.alerts
);

export const isFetchingAlertsSelector = createSelector(
  selectCompanyStore,
  (company) => company.isFetchingAlerts
);

export const isLoadingAlertsSelector = createSelector(
  selectCompanyStore,
  (company) => company.isLoadingAlerts
);

export const isFormSubmittingSelector = createSelector(
  selectCompanyStore,
  (company) => company.isFormSubmitting
);

export const travelOffsetSelector = createSelector(
  travelSelector,
  (travel) => travel?.offset
);
export const totalTravelPeopleSelector = createSelector(
  travelSelector,
  (travel) => travel?.totalUser
);
export const travelFetchAgainSelector = createSelector(
  travelSelector,
  (travel) => travel?.fetchAgain
);

export const budgetDataSelector = createSelector(
  selectCompanyStore,
  (company) => company?.budgetData
);

export const isFetchingBudgetDataSelector = createSelector(
  selectCompanyStore,
  (company) => company?.isFetchingBudgetData
);

export const allowedBudgetsForModulesSelector = createSelector(
  selectCompanyStore,
  (company) => company?.allowedBudgetsForModules
);
