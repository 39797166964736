import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";

import {
  FLAG_EXPENSE_FOOTER,
  FLAG_EXPENSE_FORM_BUTTON_TYPE,
} from "@/constants/expense";

export default function FlagExpenseAndQRPayFooter({
  requestRepaymentTrue,
  form,
  toggleSwitchTrue,
  isSubmitButtonDisabled,
  type,
  onClick,
}) {
  const primaryConfig =
    toggleSwitchTrue && requestRepaymentTrue
      ? FLAG_EXPENSE_FOOTER[type].primaryBtnVaraint
      : FLAG_EXPENSE_FOOTER[type].primaryBtn;
  const secondaryConfig = FLAG_EXPENSE_FOOTER[type].secondaryBtn;
  return (
    <div className="flex justify-end gap-2 px-3 py-4">
      {secondaryConfig ? (
        <Button
          form={form}
          btnType={secondaryConfig?.btnType}
          onClick={() => onClick(secondaryConfig?.key)}
          disabled={
            secondaryConfig.btnType === FLAG_EXPENSE_FORM_BUTTON_TYPE.SUBMIT &&
            isSubmitButtonDisabled
          }
          label={secondaryConfig?.label}
          type={secondaryConfig?.type}
          variant={secondaryConfig?.variant}
          classes={`w-15.5 h-10 ${secondaryConfig?.class}`}
        />
      ) : null}
      <Button
        form={form}
        label={primaryConfig?.label}
        type={primaryConfig?.type}
        disabled={isSubmitButtonDisabled}
        btnType={primaryConfig?.btnType}
        onClick={() => onClick(primaryConfig?.key)}
        classes={`w-15.5 h-10 ${primaryConfig.class}`}
      />
    </div>
  );
}

FlagExpenseAndQRPayFooter.propTypes = {
  requestRepaymentTrue: PropTypes.bool,
  isSubmitButtonDisabled: PropTypes.bool,
  toggleSwitchTrue: PropTypes.bool,
  form: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};
