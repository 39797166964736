import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";

import QrPay from "@/pages/QrPay";
import QrPayFailures from "@/pages/QrPay/payments/failures";
import QrPayFlagged from "@/pages/QrPay/payments/flagged";
import QrPayNeedsReview from "@/pages/QrPay/payments/needs-review";
import QrPayOverview from "@/pages/QrPay/payments/overview";
import { ROUTES } from "@/constants/routes";
import { ADMIN_ROLE } from "@/constants/common";

function QrPayRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/*" element={<QrPay />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.QRPay.qrPayments.overview.pathName}
          element={<QrPayOverview />}
        />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.QRPay.qrPayments.needsReview.pathName}
          element={<QrPayNeedsReview />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.QRPay.qrPayments.flagged.pathName}
          element={<QrPayFlagged />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES.QRPay.qrPayments.failures.pathName}
          element={<QrPayFailures />}
        />
      </Route>
    </Routes>
  );
}

export default QrPayRoutes;
