export const CLIENT_BUDGET_TYPE = {
  DEPARTMENT: "department",
};

export const ACCOUNT_TYPE = {
  PAYMENTS: "payments",
  CREDIT_ACCOUNT: "credit_account",
  CASHBACK: "cashback",
  PAYROLL: "payroll",
  CARDS: "cards",
  INTERNATIONAL_CARDS: "global_cards",
};

export const PAYMENT_ACCOUNT_TYPE_ALIASES = {
  CARDS: "Cards",
  PAYMENTS: "Payments",
};

export const ALERTS_TYPES = {
  LOW_ACCOUNT: "lowAccount",
  LOW_CREDIT: "lowCredit",
  LOW_PAYROLL: "lowPayroll",
  OVERSPENT: "overspent",
};

export const ALERTS_TYPES_TEXT_CONFIG = {
  [ALERTS_TYPES.LOW_ACCOUNT]: {
    switch: {
      title:
        "settings.alerts.alertsTextAndDescription.lowAccount.enableAlert.title",
      description:
        "settings.alerts.alertsTextAndDescription.lowAccount.enableAlert.description",
    },
    amountInput: {
      title:
        "settings.alerts.alertsTextAndDescription.lowAccount.amountInput.title",
      description:
        "settings.alerts.alertsTextAndDescription.lowAccount.amountInput.description",
    },
  },
  [ALERTS_TYPES.LOW_CREDIT]: {
    switch: {
      title:
        "settings.alerts.alertsTextAndDescription.lowCredit.enableAlert.title",
      description:
        "settings.alerts.alertsTextAndDescription.lowCredit.enableAlert.description",
    },
    amountInput: {
      title:
        "settings.alerts.alertsTextAndDescription.lowCredit.amountInput.title",
      description:
        "settings.alerts.alertsTextAndDescription.lowCredit.amountInput.description",
    },
  },
  [ALERTS_TYPES.LOW_PAYROLL]: {
    switch: {
      title:
        "settings.alerts.alertsTextAndDescription.lowPayroll.enableAlert.title",
      description:
        "settings.alerts.alertsTextAndDescription.lowPayroll.enableAlert.description",
    },
    amountInput: {
      title:
        "settings.alerts.alertsTextAndDescription.lowPayroll.amountInput.title",
      description:
        "settings.alerts.alertsTextAndDescription.lowPayroll.amountInput.description",
    },
  },
  [ALERTS_TYPES.OVERSPENT]: {
    switch: {
      title:
        "settings.alerts.alertsTextAndDescription.overspent.enableAlert.title",
      description:
        "settings.alerts.alertsTextAndDescription.overspent.enableAlert.description",
    },
    amountInput: {
      title:
        "settings.alerts.alertsTextAndDescription.overspent.amountInput.title",
      description:
        "settings.alerts.alertsTextAndDescription.overspent.amountInput.description",
    },
  },
};
