import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getCardsListForSelectedUser } from "@/store/reducers/user";

import {
  isFetchingSelectedUserCardList,
  selectedUserPhysicalCardsListSelector,
  selectedUserVirtualCardsListSelector,
  totalCountSelectedUserCardSelector,
} from "@/store/selectors/user";

import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import Card from "@/components/Cards/CardsTable/CardTableComponents/Card";
import Limit from "@/components/Cards/CardsTable/CardTableComponents/Limit";
import LinkedToComponent from "@/components/Cards/CardsTable/CardTableComponents/LinkedToComponent";
import CardTableLoader from "@/components/Cards/CardsTable/CardTableLoader";

import { CARD_STATUS, CARD_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function PeopleCardsTable({
  showCardsOfType = [],
  filterByDepartment = false,
  departmentIds = [],
}) {
  const pageLimit = 3;

  const tableHeaderKeys = {
    CARD: "cards",
    LIMIT: "limit",
    LINKED_TO: "linkedTo",
  };

  const tableConfig = {
    [tableHeaderKeys.CARD]: {
      tableHeadingName: "Card",
      TableDataComponent: Card,
      id: tableHeaderKeys.CARD,
    },
    [tableHeaderKeys.LIMIT]: {
      tableHeadingName: "Limit",
      TableDataComponent: Limit,
      id: tableHeaderKeys.LIMIT,
      staticProps: {
        isEditable: false,
      },
    },
    [tableHeaderKeys.LINKED_TO]: {
      tableHeadingName: "Linked to",
      TableDataComponent: LinkedToComponent,
      id: tableHeaderKeys.LINKED_TO,
    },
  };

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const userId = searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id);

  const isFetchingCards = useSelector(isFetchingSelectedUserCardList);

  const physicalCards = useSelector(selectedUserPhysicalCardsListSelector);

  const physicalCardsLength = physicalCards?.length;

  const virtualCards = useSelector(selectedUserVirtualCardsListSelector);

  const virtualCardsLength = virtualCards?.length;

  const totalCount = useSelector(totalCountSelectedUserCardSelector);

  const showTableLoader = isFetchingCards;

  const [currentPage, setCurrentPage] = useState(1);

  const cardsList = useMemo(() => {
    return getCardsListByCardsType()?.filter(
      (card, index) =>
        index >= (currentPage - 1) * 3 &&
        index < (currentPage - 1) * pageLimit + pageLimit
    );
  }, [currentPage, virtualCards, physicalCards]);

  useEffect(() => {
    if (physicalCardsLength <= 0 && virtualCardsLength <= 0) {
      getCardsList();
    }
  }, []);

  function getCardsList() {
    let payload = {
      page: 1,
      card_type: [CARD_TYPE.PHYSICAL, CARD_TYPE.VIRTUAL],
      status: [CARD_STATUS.ACTIVE, CARD_STATUS.FROZEN],
      user: userId,
    };
    if (filterByDepartment) payload = { ...payload, project: departmentIds };
    dispatch(
      getCardsListForSelectedUser({
        ...payload,
      })
    );
  }

  function getCardsListByCardsType() {
    if (showCardsOfType.length === 1) {
      return showCardsOfType[0] === CARD_TYPE.PHYSICAL
        ? physicalCards
        : virtualCards;
    }

    return [...physicalCards, ...virtualCards];
  }

  function getTotalCount() {
    if (showCardsOfType.length === 1) {
      return showCardsOfType[0] === CARD_TYPE.PHYSICAL
        ? physicalCardsLength
        : virtualCardsLength;
    }

    return totalCount;
  }

  function getRightDisabled() {
    if (showCardsOfType.length === 1) {
      return showCardsOfType[0] === CARD_TYPE.PHYSICAL
        ? currentPage * pageLimit > physicalCardsLength
        : currentPage * pageLimit > virtualCardsLength;
    }
    return currentPage * pageLimit > totalCount;
  }

  const handleOpenCardSlider = (cardId) => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.cards.id, cardId);
    setSearchParams(searchParams);
  };

  if (showCardsOfType.length === 0) return null;

  return (
    <div>
      <div className="mt-3">
        <Table headerSticky numberOfStickyColsLeft={2}>
          <tr>
            {Object.values(tableConfig).map((tableColumn, index) => {
              return (
                <th key={index} className="text-xs font-semibold text-left">
                  <Text translationKey={tableColumn.tableHeadingName} />
                </th>
              );
            })}
          </tr>

          {!showTableLoader ? (
            cardsList.map((cardInfo, ind) => {
              return (
                <tr
                  onClick={() => handleOpenCardSlider(cardInfo?.id)}
                  key={`card-slider-item-${cardInfo?.key}`}
                >
                  {Object.values(tableConfig).map((details, index) => {
                    const { TableDataComponent } = details;
                    let staticProps = details?.staticProps || {};
                    if (details && cardInfo)
                      switch (details.id) {
                        case tableHeaderKeys.LIMIT:
                          staticProps = {
                            ...staticProps,
                            amount: cardInfo?.limit,
                            currency: cardInfo?.currency,
                          };
                          break;
                        case tableHeaderKeys.LINKED_TO:
                          staticProps = {
                            ...staticProps,
                            linkedToName: cardInfo?.linkedTo?.name,
                            linkedToType: cardInfo?.linkedTo?.type,
                          };
                          break;
                        default:
                          break;
                      }

                    return (
                      <td key={ind + index}>
                        <TableDataComponent {...cardInfo} {...staticProps} />
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <CardTableLoader
              header={Object.values(tableConfig)}
              loaderCount={pageLimit}
            />
          )}
        </Table>

        <div className="flex flex-row justify-end w-full">
          <StepPaginationButtons
            currentPage={currentPage}
            handleLeftButton={() => setCurrentPage(currentPage - 1)}
            handleRightButton={() => setCurrentPage(currentPage + 1)}
            total={getTotalCount()}
            leftDisabled={currentPage <= 1 || showTableLoader}
            rightDisabled={
              getRightDisabled() || getTotalCount() / currentPage <= 3
            }
            showStepPaginationButtons={cardsList.length > 0}
          />
        </div>
      </div>
    </div>
  );
}

PeopleCardsTable.propTypes = {
  showCardsOfType: PropTypes.array,
  filterByDepartment: PropTypes.bool,
  departmentIds: PropTypes.array,
};

export default PeopleCardsTable;
