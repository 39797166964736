import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

function CollapsibleSection({
  headerComponent,
  title,
  description,
  children,
  value,
  handleChange,
}) {
  return (
    <div>
      <div className="flex items-center justify-between">
        {!headerComponent ? (
          <div className="flex flex-col text-lg font-semibold text-neutral-800">
            <Text translationKey={title} />
            <Text
              classes="text-sm text-neutral-500 font-semibold"
              translationKey={description}
            />
          </div>
        ) : null}
        <div
          onClick={handleChange}
          className="p-3 rounded-full cursor-pointer bg-neutral-100"
        >
          <Icon
            className="w-3 h-3 transition-all duration-500 ease-in-out text-neutral-500"
            name={!value ? "ChevronDown" : "ChevronUp"}
          />
        </div>
      </div>
      {value ? <div>{children}</div> : null}
    </div>
  );
}

export default CollapsibleSection;

CollapsibleSection.propTypes = {
  headerComponent: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.bool,
  handleChange: PropTypes.func,
};
