/* eslint-disable import/extensions */
// ^ for react-loading-skeleton/dist/skeleton.css (CSS file not a marked export in that package, but we need it)
import PropTypes from "prop-types";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  LOADER_CIRCLE_SIZES,
  LOADER_RECTANGLE_SIZES,
} from "@/utils/constants/loaderSizes";

/**
 *
 * @param {String} type - Default Value: `rectangle` VALUES:- `circle` and `rectangle`
 * @param {String OR Array<number>} size - Default value: "md" String values:- `xs` `sm` `md` `lg` `xl` `xxl` To give custom height use `Array` `[height,width]` in Number
 * @param {number}  count - Default Value: `1`  use in scenerio where needed same component multiple time
 * @param {boolean} inline - Default Value: `true` - by default it is inline false will make them block
 * @param {String}  classes     - custom css class
 * @param {String}  borderRadius - Default radius "5px" you can add custom percentage or px radius
 */

export default function LoaderSkeleton({
  type = "rectangle",
  size = "md",
  count = 1,
  inline = true,
  classes,
  borderRadius = "5px",
  fullWidth,
  heightProp,
  isDarker,
}) {
  let height = 10;
  let width = 50;
  if (type === "circle" && typeof size === "string") {
    [height, width] = LOADER_CIRCLE_SIZES[size];
  } else if (type !== "circle" && typeof size === "string") {
    [height, width] = LOADER_RECTANGLE_SIZES[size];
  } else {
    [height, width] = size;
  }

  const getWidth = () => (fullWidth ? "100%" : width);

  if (type !== "circle") {
    return (
      <Skeleton
        inline={inline}
        height={heightProp ?? height}
        baseColor={
          isDarker ? "rgba(230, 230, 230, 1)" : "rgba(243, 244, 246, 1)"
        }
        highlightColor={
          isDarker ? "rgba(230, 230, 230, 0.5)" : "rgba(229, 230, 234, 0.5)"
        }
        width={getWidth()}
        count={count}
        className={classes}
        style={{ borderRadius: `${borderRadius}` }}
      />
    );
  }
  return (
    <Skeleton
      inline={inline}
      circle
      height={height}
      width={width}
      count={count}
      baseColor={isDarker ? "rgba(230, 230, 230, 1)" : "rgba(243, 244, 246, 1)"}
      highlightColor={
        isDarker ? "rgba(230, 230, 230, 0.5)" : "rgba(229, 230, 234, 0.5)"
      }
      className={classes}
      style={{ borderRadius: `${borderRadius}` }}
    />
  );
}
LoaderSkeleton.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  count: PropTypes.number,
  inline: PropTypes.bool,
  classes: PropTypes.string,
  borderRadius: PropTypes.string,
  fullWidth: PropTypes.bool,
  heightProp: PropTypes.number,
  isDarker: PropTypes.bool,
};
