import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getAccounts } from "@/store/reducers/app";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { subdomain } from "@/utils/common";

function CompanyNotFound() {
  // getting hostName from window to show as description
  const { hostname } = window.location;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAccounts({ navigate }));
  }, []);

  return (
    <div>
      <Icon name="CompanyNotFound" />
      <div className="flex flex-col">
        <Text classes="text-2xl font-bold" translationKey="Company not found" />
        <Text
          classes="text-sm text-neutral-500"
          translationKey={`Volopay cannot recognise the subdomain (${hostname}) you have entered. Please try again with the correct subdomain`}
        />
      </div>
    </div>
  );
}

export default CompanyNotFound;
