import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { forgotPassword } from "@/store/reducers/user";

import { userSelector } from "@/store/selectors/user";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import ResendButton from "@/components/TwoFactorAuth/ResendButton";

import { ROUTES } from "@/constants/routes";

export default function VerifyInbox() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelector);
  useEffect(() => {
    if (!currentUser?.email) {
      navigate(ROUTES.login.base.absolutePath);
    }
  }, []);
  return (
    <>
      <button
        onClick={() => {
          navigate(ROUTES.forgotPassword.base.absolutePath);
        }}
        className="mb-4"
      >
        <Icon className="h-10 cursor-pointer " name="ChevronLeft" />
      </button>
      <div>
        <Text
          classes="text-3xl font-bold"
          translationKey="user.notificationsAndMessages.checkInbox"
        />
        <br />
        <Text
          classes="text-sm font-semibold text-neutral-500 "
          translationKey={`We’ve sent you a magic link to ${currentUser?.email}`}
        />
        <div className="mt-10 ">
          <ResendButton
            label="Send Again"
            handleClick={() => {
              dispatch(
                forgotPassword({
                  data: {
                    email: currentUser?.email,
                    redirect_url: "test",
                  },
                  navigate,
                })
              );
            }}
          />
        </div>
      </div>
    </>
  );
}
