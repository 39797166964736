import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { paymentsAutoApproveEnabledSelector } from "@/store/selectors/client";
import { userSelector } from "@/store/selectors/user";

import Avatar from "@/components/core/Avatar";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { CREATE_BILL_APPROVERS_RESPONSE } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import { positionifyNumber } from "@/utils/common";

import { APPROVER_LEVELS_TO_NUMBER_MAP } from "@/constants/approvers";
import { ROLE_TITLES_LABELS } from "@/constants/user";

export default function ApproversAtLevel({
  level,
  levelNumber,
  approversList = [],
  isMakerCheckerEnabled,
  preApprovalDescription = "billPay.bill.common.preApprovedAtXLevel",
}) {
  const [showAll, setShowAll] = useState(false);
  const showAtleast = 3;
  const currentUser = useSelector(userSelector);
  const hiddenInitallyCount = approversList.length - showAtleast;
  const isPreApproved = useMemo(
    () =>
      levelNumber === 1 &&
      !!approversList?.find((user) => user.id === currentUser.id),
    [approversList]
  );

  const isAutoApproved = useSelector(paymentsAutoApproveEnabledSelector);

  const isPreApprovedFinal =
    isAutoApproved && isPreApproved && !isMakerCheckerEnabled;

  return (
    <div>
      <span className="text-sm text-neutral-500">
        {positionifyNumber(APPROVER_LEVELS_TO_NUMBER_MAP[level])}{" "}
        <Text translationKey="billPay.bill.invoiceInbox.createBill.sections.approvers.levelApprovers" />
      </span>
      <div className={`p-3 mt-2 ${isPreApprovedFinal ? "pl-0" : ""}`}>
        {!isPreApprovedFinal ? (
          <div className="flex flex-col gap-3">
            {(showAll
              ? approversList
              : approversList.slice(0, showAtleast)
            ).map((approver) => (
              <div
                className="flex items-center gap-2 text-sm"
                key={`approver-${approver?.id}`}
              >
                <Avatar
                  avatarSize="sm"
                  color={approver?.colorCode}
                  imgSrc={approver?.[CREATE_BILL_APPROVERS_RESPONSE.AVATAR_URL]}
                  name={approver?.[CREATE_BILL_APPROVERS_RESPONSE.NAME]}
                />
                <span>
                  <span>{approver?.[CREATE_BILL_APPROVERS_RESPONSE.NAME]}</span>
                  {approver?.[CREATE_BILL_APPROVERS_RESPONSE.ROLES]?.length ? (
                    <span className="text-neutral-500">
                      {approver?.[CREATE_BILL_APPROVERS_RESPONSE.NAME]
                        ? " • "
                        : ""}
                      <Text
                        translationKey={
                          ROLE_TITLES_LABELS[
                            approver?.[
                              CREATE_BILL_APPROVERS_RESPONSE.ROLES
                            ]?.[0]
                          ]
                        }
                      />
                    </span>
                  ) : null}
                </span>
              </div>
            ))}
            {!showAll && hiddenInitallyCount > 0 ? (
              <span
                className="text-sm font-semibold cursor-pointer text-primary-500"
                onClick={() => setShowAll(true)}
              >
                +{hiddenInitallyCount}&nbsp;
                <Text translationKey="billPay.bill.invoiceInbox.createBill.sections.approvers.more" />
              </span>
            ) : null}
          </div>
        ) : (
          <div>
            <div className="flex items-center gap-2">
              <Icon name="CheckCircle" className="text-success-600" />
              <Text
                translationKey="common.preApproved"
                classes="text-neutral-800 text-lg font-semibold"
              />
            </div>
            <Text
              translationKey={preApprovalDescription}
              translationProps={{
                level: positionifyNumber(APPROVER_LEVELS_TO_NUMBER_MAP[level]),
              }}
              classes="text-sm font-medium text-neutral-500 block mt-1"
            />
          </div>
        )}
      </div>
    </div>
  );
}

ApproversAtLevel.propTypes = {
  level: PropTypes.string,
  levelNumber: PropTypes.number,
  approversList: PropTypes.array,
  isMakerCheckerEnabled: PropTypes.bool,
  preApprovalDescription: PropTypes.string,
};
