import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  creditOverviewDataSelector,
  isSpendOverviewFetchingSelector,
  spendOverviewDataSelector,
} from "@/store/selectors/expense";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Progress from "@/components/core/Progress";
import Text from "@/components/core/Text";

import HeaderSpendCreditOverview from "@/components/common/QrPayAndExpense/common/Overview/SpendCreditOverview/HeaderSpendCreditOverview";
import SpendCreditLoader from "@/components/common/QrPayAndExpense/common/Overview/SpendCreditOverview/SpendCreditLoader";
import { amountToCurrency, dateToString } from "@/utils/common";

import { EXPENSE_TYPE_OF_UI_DATA } from "@/constants/expense";

export default function SpendCreditOverview({ viewPastRepayments }) {
  const isFetching = useSelector(isSpendOverviewFetchingSelector);
  const spendData = useSelector(spendOverviewDataSelector);

  const getData = (type) => {
    if (type === EXPENSE_TYPE_OF_UI_DATA.PROGRESS)
      return {
        total: spendData.available,
        items: [
          {
            value: spendData.settledAmount,
            variant: "cards-500",
          },
          { value: spendData.unsettledAmount, variant: "cards-100" },
        ],
      };
    if (type === EXPENSE_TYPE_OF_UI_DATA.LABEL)
      return [
        {
          title: "expenses.overview.progressBar.label.settled",
          color: "bg-corporate-cards-500",
          value: spendData.settledAmount,
        },
        {
          title: "expenses.overview.progressBar.label.unsettled",
          color: "bg-corporate-cards-100",
          value: spendData.unsettledAmount,
        },
        {
          title: "expenses.overview.progressBar.label.available",
          color: "bg-neutral-200",
          value: spendData.available,
        },
      ];
  };
  return !isFetching && spendData ? (
    <div className="flex flex-col gap-5 card-wrapper">
      <HeaderSpendCreditOverview
        spentAmount={spendData.totalSpent}
        spentCurrency={spendData?.currency}
        creditAmount={spendData.creditLimit}
        creditCurrency={spendData?.currency}
      />
      <Progress data={getData(EXPENSE_TYPE_OF_UI_DATA.PROGRESS)} neutralTrack />
      {/*  label of progress */}
      <div className="flex gap-5">
        {getData(EXPENSE_TYPE_OF_UI_DATA.LABEL).map((val, index) => (
          <div
            key={`spend-credit-label-progress-bar-${index}`}
            className="flex items-center gap-1"
          >
            <div className={`w-2 h-2  rounded  ${val.color}`} />
            <div className="flex">
              <Text
                color="neutral-500"
                classes="text-sm"
                translationKey={val.title}
              />
              <span className="text-sm text-neutral-500">:</span>
            </div>
            <Text
              classes="text-sm"
              translationKey={amountToCurrency(val.value, spendData?.currency)}
            />
            <Icon name="Info" className="w-4 h-4 ml-2 text-neutral-300" />
          </div>
        ))}
      </div>
      {/* overview */}
      <div className="flex justify-between bg-neutral-50 py-3 px-2 rounded-[10px]">
        <div className="flex items-center gap-1">
          <Text
            classes="text-neutral-500"
            translationKey="expenses.overview.credit.upcomingDue"
          />
          <span>{dateToString(spendData?.creditBill?.dueDate)}</span>
          <span className="profile-widget-dot" />
          <Text
            classes="text-warning-500 capitalize"
            translationKey={spendData?.creditBill?.status ?? "Outstanding"}
          />
        </div>
        <Text
          translationKey={amountToCurrency(
            spendData?.creditBill?.totalAmount ?? 0,
            spendData?.currency
          )}
        />
      </div>
      <div className="flex justify-between">
        <div className="flex gap-5">
          <Button
            classes="w-14.6 h-10"
            label="expenses.overview.credit.payNow"
            type="default"
            variant="primary"
            onClick={() => {
              console.log("PAY NOW");
            }}
          />
          <Button
            classes="w-18.5 h-10"
            label="expenses.overview.credit.viewPastRepayment"
            type="default"
            variant="secondary"
            onClick={viewPastRepayments}
          />
        </div>
        <Button
          classes="w-16.2 h-10"
          label="expenses.overview.credit.viewDetails"
          postIcon="ArrowForward"
          type="default"
          variant="tertiary"
          onClick={() => {
            console.log("View Details");
          }}
        />
      </div>
    </div>
  ) : (
    <SpendCreditLoader />
  );
}
SpendCreditOverview.propTypes = {
  viewPastRepayments: PropTypes.func,
};
