import PropTypes from "prop-types";

import Text from "@/components/core/Text";

export default function DescriptionCell({ val }) {
  const text = val?.vendor?.description || val?.description || val?.memo;

  return (
    <span
      id={`description-cell-${val?.id}`}
      className="inline-block w-full truncate"
    >
      {text ? (
        <Text
          translationKey={text}
          id={`description-cell-${val?.id}`}
          showTooltip
          noTranslate
        />
      ) : (
        "-"
      )}
    </span>
  );
}

DescriptionCell.propTypes = {
  val: PropTypes.object,
};
