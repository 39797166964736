import { createSelector } from "@reduxjs/toolkit";

import { RULE_TYPES } from "@/constants/rules";

export const selectRulesStore = (store) => store.rules;

export const selectMerchantsStore = (store) => store.merchants;

export const rulesSelector = createSelector(
  selectRulesStore,
  (rules) => rules.rules
);

export const rulesListSelector = createSelector(
  rulesSelector,
  (rules) => rules.list
);

export const selectedRuleSelector = createSelector(
  selectRulesStore,
  (rules) => rules.selectedRule
);

export const selectedMappingsSelector = createSelector(
  selectRulesStore,
  (rules) => rules.selectedMappings
);

export const ruleItemGroupsSelector = createSelector(
  selectedMappingsSelector,
  (selectedMappings) => selectedMappings?.ruleItemGroups
);

export const normalRulesSelector = createSelector(rulesSelector, (rules) =>
  rules.list?.data.filter((rule) => rule?.ruleType === RULE_TYPES.NORMAL)
);

export const advancedRulesSelector = createSelector(rulesSelector, (rules) =>
  rules.list?.data.filter((rule) => rule?.ruleType === RULE_TYPES.ADVANCED)
);

export const isFetchingRulesSelector = createSelector(
  rulesSelector,
  (rules) => rules.isFetching
);

export const isFetchingRuleSelector = createSelector(
  selectRulesStore,
  (rules) => rules.isFetchingRule
);

export const isFetchingMappingsSelector = createSelector(
  selectRulesStore,
  (rules) => rules.isFetchingMappings
);

export const ruleItemTypesSelector = createSelector(
  selectRulesStore,
  (rules) => rules?.ruleItemTypes || []
);

export const isFetchingRuleItemTypesSelector = createSelector(
  selectRulesStore,
  (rules) => rules?.isFetchingRuleItemTypes
);

export const formattedRuleItemTypesSelector = createSelector(
  ruleItemTypesSelector,
  (ruleItemTypes) =>
    ruleItemTypes.map((value, index) => ({
      tagId: index,
      itemType: value,
    }))
);

export const ruleItemsSelector = createSelector(
  rulesSelector,
  (rules) => rules?.ruleItems
);

export const mappedToSelector = createSelector(
  selectRulesStore,
  (rules) => rules?.mappedTo
);

export const rulePresentSelector = createSelector(
  selectRulesStore,
  (rules) => rules?.rulePresent
);

export const rulesCountSelector = createSelector(
  selectRulesStore,
  (rules) => rules?.rulesCount
);

export const isLoadingRulesSelector = createSelector(
  selectRulesStore,
  (rules) => rules?.isLoading
);

export const rulesSliderTabsInfoSelector = createSelector(
  selectRulesStore,
  (cards) => cards?.rulesSliderTabsInfo
);

export const rulesSliderTabListSelector = createSelector(
  rulesSliderTabsInfoSelector,
  (tabsInfo) => tabsInfo?.tabList
);

export const rulesSliderSelectedTabSelector = createSelector(
  rulesSliderTabsInfoSelector,
  (tabsInfo) => tabsInfo?.selectedTab
);

export const rulesTransactionsCountSelector = createSelector(
  selectRulesStore,
  (rules) => rules?.transactionsCount
);

export const isFetchingRulesTransactionsCountSelector = createSelector(
  selectRulesStore,
  (rules) => rules?.isFetchingTransactionsCount
);

export const isFetchingRulesCountSelector = createSelector(
  selectRulesStore,
  (rules) => rules?.isFetchingRulesCount
);
