import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { closeAppModal } from "@/store/reducers/app";
import {
  fetchAndSelectPayment,
  removePayment,
} from "@/store/reducers/payments";
import { archive } from "@/store/reducers/purchase-bills";
import { fetchAndSelectVendor } from "@/store/reducers/vendors";

import { appModalDataSelector, appModalSelector } from "@/store/selectors/app";
import { isActionPendingForPurchaseBillSelector } from "@/store/selectors/purchase-bills";

import RadioModal from "@/components/common/RadioModal";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  DELETE_PAY_MODAL_OPTION_VALUES,
  PAYMENT_STATUSES,
} from "@/utils/constants/payments";
import { VP_MODALS } from "@/utils/constants/app";

export default function DeleteRecurringPaymentModal({ val, target }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const appModal = useSelector(appModalSelector);
  const appModalData = useSelector(appModalDataSelector);
  const isActionPending = useSelector(isActionPendingForPurchaseBillSelector);

  const isPaidOrProcessing = [
    PAYMENT_STATUSES.paid,
    PAYMENT_STATUSES.processing,
  ]?.includes(val?.transactionStatus);

  const options = [
    isPaidOrProcessing
      ? null
      : {
          id: DELETE_PAY_MODAL_OPTION_VALUES.ARCHIVE_ALL,
          label:
            "billPay.bill.payments.paymentFooter.deleteRecurringPayment.all",
        },
    isPaidOrProcessing
      ? null
      : {
          id: DELETE_PAY_MODAL_OPTION_VALUES.CURRENT,
          label:
            "billPay.bill.payments.paymentFooter.deleteRecurringPayment.current",
        },
    {
      id: DELETE_PAY_MODAL_OPTION_VALUES.UPCOMING,
      label:
        "billPay.bill.payments.paymentFooter.deleteRecurringPayment.upcoming",
    },
  ].filter(Boolean);

  return (
    <RadioModal
      title={
        val?.context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? "billPay.bill.payments.paymentFooter.deleteRecurringPayment.payrollTitle"
          : "billPay.bill.payments.paymentFooter.deleteRecurringPayment.title"
      }
      description="billPay.bill.payments.paymentFooter.deleteRecurringPayment.description"
      visible={
        [
          VP_MODALS.DELETE_RECURRING_PAYMENT,
          VP_MODALS.DELETE_RECURRING_PAYROLL,
        ].includes(appModal) &&
        appModalData?.id === val?.id &&
        target === appModalData?.target
      }
      onSubmit={(selectedOptionId) => {
        const onSuccess = () => {
          dispatch(closeAppModal());
          if (selectedOptionId === DELETE_PAY_MODAL_OPTION_VALUES.UPCOMING) {
            dispatch(
              fetchAndSelectPayment({ id: val?.id, isSetListItem: true })
            );
          } else {
            dispatch(removePayment(val?.id));
          }
          dispatch(
            fetchAndSelectVendor({
              id: val?.vendor?.id,
              isSetListItem: true,
            })
          );
          setSearchParams({});
        };

        dispatch(
          archive({
            context: val?.context,
            id: val?.owner?.id ?? val?.id,
            onSuccess,
            [selectedOptionId]: true,
          })
        );
      }}
      submitBtnProps={{
        disabled: isActionPending,
        type:
          val?.context === BILL_PAYROLL_CONTEXT.PAYROLL ? "success" : undefined,
      }}
      configs={options}
      onClose={() => dispatch(closeAppModal())}
      submitLabel="misc.saveChanges"
    />
  );
}
