import { useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { setReloadSelectedTransaction } from "@/store/reducers/accounting_transactions";
import {
  fetchAndSelectExpense,
  fetchExpenseSplit,
  undoSplitExpense,
  updateExpenseSplit,
} from "@/store/reducers/expense";
import { fetchTags } from "@/store/reducers/tags";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import { selectedAccountingTransactionSelector } from "@/store/selectors/accounting_transactions";
import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";
import {
  expenseSplitSelector,
  isFetchingExpenseSelector,
  isFetchingSplitExpenseSelector,
  isUndoSplitInProgressSelector,
  selectedExpenseSelector,
} from "@/store/selectors/expense";
import {
  accountingCategoryTagsSelector,
  cardsCustomTagsSelector,
  netsuiteExpenseCategoryTagsSelector,
  nonAccountingCategoryTagSelector,
} from "@/store/selectors/tags";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Modal from "@/components/core/Modal";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import TagInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/TagInput";
import ExpenseSplitFooter from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPaySplit/ExpenseAndQRPaySplitFooter";
import ExpenseSplitHeader from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPaySplit/ExpenseAndQRPaySplitHeader";
import {
  checkSplitAmountIsZeroOrNot,
  gracefullySubtractFloatNumber,
  gracefullySumFloatNumber,
  splitExpenseAmount,
  testRegex,
} from "@/utils/common";
import { getSplitExpensePayload } from "@/utils/expenses";
import { REGEX_VALUE } from "@/utils/constantUseForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { TAG_FIELD_TYPES, VALUE_VENDOR_ID, VENDOR_ID } from "@/constants/tags";

export default function ExpenseSplit() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const splitExpense = useSelector(expenseSplitSelector);
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const nonAccountingCategory = useSelector(nonAccountingCategoryTagSelector);
  const customTags = useSelector(cardsCustomTagsSelector);
  const isDeleSplitInProgress = useSelector(isUndoSplitInProgressSelector);
  const isValidNumberCustomFunction = (value) => {
    return testRegex(REGEX_VALUE.number.all, value);
  };
  const isExpenseFetching = useSelector(isFetchingExpenseSelector);

  const { t } = useTranslation();
  const [openModal, toggleOpenModal] = useState(false);
  const categoryAccountingTags = useSelector(accountingCategoryTagsSelector);
  const expenseCategoryTags = useSelector(netsuiteExpenseCategoryTagsSelector);
  const [amountEditing, setAmountEditing] = useState();
  const accountingVendorOptions = useSelector(accountingVendorsSelector);
  const isSplitExpenseProgress = useSelector(isFetchingSplitExpenseSelector);
  const isAccounting = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.cards.id
  );

  const ref = useLeftHeaderTitle({ title: "expenses.splitExpense.title" });

  const selectedExpense = useSelector(
    isAccounting
      ? selectedAccountingTransactionSelector
      : selectedExpenseSelector
  );

  const accountingVendor = {
    id: VENDOR_ID,
    isText: false,
    name: t("expenses.needsReview.tableHeaders.accountingMerchant", {
      accounting: accountingSoftware,
    }),
    options: [
      {
        id: VALUE_VENDOR_ID,
        name: selectedExpense?.merchant?.name,
        alias: selectedExpense?.merchant?.name,
      },
      ...accountingVendorOptions,
    ],
  };
  const allTags = [
    ...(accountingEnabled && categoryAccountingTags
      ? [categoryAccountingTags]
      : []),
    ...(accountingEnabled && expenseCategoryTags ? [expenseCategoryTags] : []),
    ...(accountingEnabled ? nonAccountingCategory : []),
    ...customTags,
  ];

  const getTagsState = (_allTags, _splitExpense = {}) => {
    if (_splitExpense) {
      return _allTags?.map((item) => {
        const selectedSplitExpenseTag = _splitExpense?.lineItemTagValues?.find(
          (lineItem) => item?.id === lineItem?.tagValue?.tagId
        );
        const isAccountingVendorTag = accountingVendor?.id === item?.id;

        return {
          isText: item?.fieldType === TAG_FIELD_TYPES.TEXT,
          customText: "",
          ...(item?.fieldType === TAG_FIELD_TYPES.TEXT && !isAccountingVendorTag
            ? {
                textValueToBe: selectedSplitExpenseTag?.customTextValue,
              }
            : {
                valueToBE: isAccountingVendorTag
                  ? _splitExpense?.accountingPayeeId || VALUE_VENDOR_ID
                  : selectedSplitExpenseTag?.tagValue?.tagValueId,
              }),
          beId: selectedSplitExpenseTag?.id,
          ...item,
        };
      });
    }
    return _allTags?.map((item) => ({
      isText: item?.fieldType === TAG_FIELD_TYPES.TEXT,
      customText: "",
      ...item,
    }));
  };

  const initialState = useMemo(() => {
    let initialSplit = [];

    if (!splitExpense?.length) {
      const [amount1, amount2] = splitExpenseAmount(
        parseFloat(selectedExpense?.amount ?? 0)
      );
      initialSplit = [
        { id: 1, amount: amount1, tags: getTagsState(allTags) },
        { id: 2, amount: amount2, tags: getTagsState(allTags) },
      ];
    } else {
      initialSplit = splitExpense.map((item, index) => ({
        id: index + 1,
        beId: item?.id,
        amount: item?.amount,
        tags: getTagsState(allTags, splitExpense[index]),
      }));
    }

    return initialSplit;
  }, [
    selectedExpense,
    JSON.stringify(allTags),
    JSON.stringify(customTags),
    splitExpense,
    accountingEnabled,
  ]);

  const ACTIONS = {
    UPDATE_AMOUNT: "UPDATE_AMOUNT",
    UPDATE_TAG: "UPDATE_TAG",
    ADD_ITEM: "ADD_ITEM",
    REMOVE_ITEM: "REMOVE_ITEM",
    ADD_TAG: "ADD_TAG",
    RESET_STATE: "RESET_STATE",
  };

  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.UPDATE_AMOUNT:
        return state.map((item) =>
          item.id === action.payload.id
            ? { ...item, amount: action.payload.amount }
            : item
        );
      case ACTIONS.UPDATE_TAG:
        return state.map((item) =>
          +item.id === +action.payload.itemId
            ? {
                ...item,
                tags: item.tags.map((tag) => {
                  return `${tag.id}` === `${action.payload.tagId}`
                    ? {
                        ...tag,
                        ...(action.payload.isTextField
                          ? { textValueToBe: action.payload.value }
                          : { valueToBE: action.payload.value }),
                      }
                    : tag;
                }),
              }
            : item
        );

      case ACTIONS.ADD_ITEM:
        return [...state, action.payload];
      case ACTIONS.REMOVE_ITEM:
        return state.filter((item, index) =>
          action.payload.indexMode
            ? index !== action.payload.index
            : item.id !== action.payload.id
        );
      case ACTIONS.ADD_TAG:
        return state.map((item) =>
          item.id === action.payload.itemId
            ? { ...item, tags: [...item.tags, action.payload.tag] }
            : item
        );
      case ACTIONS.RESET_STATE:
        return action.payload;
      default:
        return state;
    }
  }

  const [state, dispatchReducer] = useReducer(reducer, initialState);

  const totalAmount = useMemo(
    () =>
      state.reduce(
        (total, item) =>
          gracefullySumFloatNumber(total, Number(item.amount) || 0),
        0
      ),
    [state]
  );

  // Helper functions
  const isValueNotChange = useMemo(
    () => JSON.stringify(state) === JSON.stringify(splitExpense),
    [state, splitExpense]
  );

  const totalAmountError = useMemo(
    () =>
      state.some(
        (item) =>
          !isValidNumberCustomFunction(item.amount) ||
          parseFloat(item.amount) === 0
      ),
    [state]
  );

  const handleAmountChange = (id, amount) =>
    dispatchReducer({ type: ACTIONS.UPDATE_AMOUNT, payload: { id, amount } });

  const handleTagChange = (itemId, tagId, _value, isTextField, tag) => {
    dispatchReducer({
      type: ACTIONS.UPDATE_TAG,
      payload: { itemId, tagId, value: _value, isTextField },
    });
  };

  const addItem = () => {
    const newItem = {
      id: state.length + 1,
      amount: 0,
      tags: getTagsState(allTags),
    };
    dispatchReducer({ type: ACTIONS.ADD_ITEM, payload: newItem });
  };

  const handleDeleteSplit = (id, index, indexMode) =>
    dispatchReducer({
      type: ACTIONS.REMOVE_ITEM,
      payload: { id, index, indexMode },
    });

  const handleSaveApiCall = () => {
    dispatch(
      updateExpenseSplit({
        payload: getSplitExpensePayload(state),
        expenseId: selectedExpense?.id,
        onSuccess: handleSuccess,
      })
    );
  };

  // Compute difference
  const difference = gracefullySubtractFloatNumber(
    totalAmount,
    Number(selectedExpense?.amount ?? 0)
  );

  const disableContinueButton =
    checkSplitAmountIsZeroOrNot(difference) ||
    totalAmountError ||
    isValueNotChange;
  function handleSuccess() {
    dispatch(fetchExpenseSplit({ expenseId: selectedExpense?.id }));
    if (isAccounting) {
      // calling api is not updating api not same correct timne due to which during transition error are coming with api call
      // that why used this way
      searchParams.delete(SLIDERS_SEARCH_PARAMS.expenses.splitExpense);
      setSearchParams(searchParams);
      dispatch(setReloadSelectedTransaction(true));
    } else
      dispatch(
        fetchAndSelectExpense({
          expenseId: selectedExpense?.id,
          onSuccess: () => {
            searchParams.delete(SLIDERS_SEARCH_PARAMS.expenses.splitExpense);
            setSearchParams(searchParams);
          },
        })
      );
  }
  const handleDeleteExpenseApiCall = () => {
    dispatch(
      undoSplitExpense({
        id: selectedExpense?.id,
        payload: {},
        onSuccess: handleSuccess,
      })
    );
  };

  // on Mount
  useEffect(() => {
    dispatch(fetchTags());
    if (accountingEnabled) dispatch(fetchAccountingVendors());
  }, [accountingEnabled]);

  useEffect(() => {
    const expenseId = parseInt(
      searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.splitExpense),
      10
    );
    if (expenseId) {
      dispatch(
        fetchExpenseSplit({
          expenseId,
        })
      );
      if (searchParams.get(SLIDERS_SEARCH_PARAMS.expenses.id))
        dispatch(
          fetchAndSelectExpense({
            expenseId,
          })
        );
    }
  }, [JSON.stringify(searchParams)]);

  const handleDeleteExpense = () => {
    toggleOpenModal(true);
  };
  useEffect(() => {
    dispatchReducer({ type: ACTIONS.RESET_STATE, payload: initialState });
  }, [JSON.stringify(initialState)]);

  if (isExpenseFetching) return <div>Loading....</div>;
  return !isExpenseFetching ? (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col slider-content-core">
          <Text
            refProp={ref}
            classes="text-3xl font-semibold"
            translationKey="expenses.splitExpense.title"
          />
          <Text
            classes="text-sm text-neutral-500"
            translationKey="expenses.splitExpense.description"
          />
          <ExpenseSplitHeader
            amount={selectedExpense?.amount}
            currency={selectedExpense?.currency}
            splitAmount={totalAmount}
            difference={difference}
            showSuccessfullSplitIcon={!disableContinueButton}
          />
          <div className="flex justify-between mb-5">
            <Text
              color="neutral-800"
              classes="text-xl font-bold"
              translationKey="expenses.splitExpense.lineItems"
            />
            <Button
              preIcon="Add"
              label="expenses.splitExpense.addSplit"
              className="flex items-center gap-2 text-base text-primary-500"
              iconClasses="w-3 h-3"
              onClick={() => {
                addItem();
              }}
            />
          </div>
        </div>

        <div className="mx-9">
          {/* Table */}

          <Table
            styleId="splitExpenseTable"
            headerSticky
            colWidths={[5, 120, 200]?.concat(allTags?.map((_) => 300))}
            boxShadowNone
          >
            <tr className="text-sm text-left text-semibold">
              <th className="!shadow-none !border-none text-center">
                <div className="w-full" aria-label="render-empty-space" />
              </th>

              <th>
                <Text
                  translationKey="expenses.splitExpense.splitNo"
                  classes="text-right"
                />
              </th>
              <th>
                <Text translationKey="expenses.splitExpense.amount" />
              </th>
              {allTags?.length
                ? allTags?.map((tag) => (
                    <th key={tag?.id} className="text-left">
                      <Text translationKey={tag?.name} classes="text-right" />
                    </th>
                  ))
                : null}
            </tr>
            {state?.map((currentSplit, index) => (
              <tr key={`split-data-currentsplit-${index + 1}`}>
                <td className="!shadow-none !border-none !text-right ">
                  {index > 1 ? (
                    <span
                      className="relative text-center cursor-pointer "
                      onClick={() =>
                        handleDeleteSplit(
                          currentSplit?.id,
                          index,
                          !!currentSplit?.id
                        )
                      }
                    >
                      <Icon className="text-neutral-400" name="Delete" />
                    </span>
                  ) : null}
                </td>

                <td>
                  <Text
                    translationKey="expenses.splitExpense.splitTitle"
                    translationProps={{ splitNo: index + 1 }}
                    classes="lowercase first-letter:capitalize"
                  />
                </td>
                <td>
                  <div
                    onClick={() => {
                      setAmountEditing(currentSplit?.id);
                    }}
                    className="flex items-center w-full"
                  >
                    <div className="flex flex-row items-center justify-between w-full">
                      {amountEditing === currentSplit?.id ? (
                        <div className="w-12">
                          <Input
                            type="number"
                            isFocus={amountEditing === currentSplit?.id}
                            value={currentSplit?.amount}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              handleAmountChange(currentSplit.id, inputValue); // Update state with the input value
                            }}
                            onBlur={() => {
                              setAmountEditing(null);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="w-10/12">{currentSplit?.amount}</div>
                      )}

                      {amountEditing === currentSplit?.id ? (
                        <Text translationKey={selectedExpense?.currency} />
                      ) : (
                        <Icon
                          name="Edit"
                          className="w-4 h-4 ml-2 text-neutral-500"
                        />
                      )}
                    </div>
                  </div>
                </td>

                {currentSplit?.tags?.length
                  ? currentSplit?.tags?.map((tag) => {
                      return (
                        <td key={tag?.id}>
                          <TagInput
                            dropdownInputKey={tag?.id}
                            textInputKey={tag?.id}
                            isTextTag={tag?.isText}
                            key={tag?.id}
                            tag={tag}
                            values={
                              tag?.isText ? tag?.textValueToBe : tag?.valueToBE
                            }
                            vpSelectProps={{ hideLabelAfterSelect: true }}
                            inputProps={{
                              hideLabelAfterValueAdded: true,
                              hideLabelAfterFocussed: true,
                            }}
                            options={tag?.options}
                            vpSelectClasses="text-xs"
                            labelStyleClasses="text-xs"
                            isDirectValue
                            handleChange={(e) => {
                              handleTagChange(
                                currentSplit?.id,
                                e?.target?.name,
                                e?.target?.value,
                                tag?.isText,
                                tag
                              );
                            }}
                          />
                        </td>
                      );
                    })
                  : null}
              </tr>
            ))}
          </Table>
        </div>
      </div>
      <div className="px-3 py-5 slider-footer">
        <ExpenseSplitFooter
          isInEditMode={!!splitExpense?.length}
          inProgress={isSplitExpenseProgress}
          disableContinueButton={disableContinueButton}
          submitSplit={handleSaveApiCall}
          handleDeleteExpense={handleDeleteExpense}
        />
      </div>
      <Modal
        open={openModal}
        onClose={() => {
          toggleOpenModal((prev) => !prev);
        }}
      >
        <div className="flex w-[440px] flex-col p-5 gap-9">
          <div className="flex flex-col gap-3">
            <Text
              classes="text-lg font-bold"
              translationKey="expenses.splitExpense.splitExpenseHeader"
            />
            <Text translationKey="expenses.splitExpense.splitExpenseModalBody" />
          </div>
          <div className="flex flex-row">
            <Button
              label="common.cancel"
              variant="tertiary"
              classes="mx-auto"
              labelStyleClasses="text-neutral-500"
              onClick={() => {
                toggleOpenModal((prev) => !prev);
              }}
              compact
            />
            <Button
              showLoader={isDeleSplitInProgress}
              label="expenses.splitExpense.confirmModalPositiveBtn"
              type="danger"
              classes="mx-auto"
              onClick={() => {
                handleDeleteExpenseApiCall();
              }}
              compact
            />
          </div>
        </div>
      </Modal>
    </>
  ) : null;
}
