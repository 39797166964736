import { createSelector } from "@reduxjs/toolkit";

import { AVAILABLE_FILTERS } from "@/utils/constants/filters";

export const selectExportsStore = (store) => store.exports;
export const exportPaginationSelector = createSelector(
  selectExportsStore,
  (store) => store.exports
);
export const exportsSelector = createSelector(
  exportPaginationSelector,
  (exports) => exports?.list
);

export const exportTotalSelector = createSelector(
  exportPaginationSelector,
  (exports) => {
    return exports?.total;
  }
);

export const exportIsFetchingSelector = createSelector(
  exportPaginationSelector,
  (exports) => exports.isFetchingExports
);

export const exportsHasMoreSelector = createSelector(
  exportPaginationSelector,
  (exports) => exports.hasMore
);

export const exportDataSelector = createSelector(
  selectExportsStore,
  (exports) => exports?.exportData
);

export const exportFiltersSelector = createSelector(
  selectExportsStore,
  (exports) => exports?.filters?.map((item) => AVAILABLE_FILTERS[item])
);
