import to from "await-to-js";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorToastMessage } from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import API from "@/api";

const initialState = {
  masterHistoryLogs: {
    list: [],
    total: null,
    page: 1,
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    isFetching: false,
  },
  childLogs: {
    list: [],
    total: null,
    page: 1,
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    isFetching: false,
  },

  filtersKeys: {
    masterHistoryLogs: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.activityType,
    ],
  },
};

const masterHistorySlice = createSlice({
  name: "masterHistory",
  initialState,
  reducers: {
    setMasterHistoryLogs(state, action) {
      state.masterHistoryLogs.list = action.payload;
    },
    resetMasterHistoryLogs(state) {
      state.masterHistoryLogs = initialState.masterHistoryLogs;
    },
    addMasterHistoryLogs(state, action) {
      state.masterHistoryLogs.list = [
        ...state.masterHistoryLogs.list,
        ...action.payload,
      ];
    },
    setIsFetchingMasterHistoryLogs(state, action) {
      state.masterHistoryLogs.isFetching = action.payload;
    },
    setMasteHistoryLogsPage(state, action) {
      state.masterHistoryLogs.page = action.payload;
    },
    setMasteHistoryLogsLimit(state, action) {
      state.masterHistoryLogs.limit = action.payload;
    },
    setMasteHistoryLogsHasMore(state) {
      state.masterHistoryLogs.hasMore =
        state.masterHistoryLogs.list.length < state.masterHistoryLogs.total;
    },
    setMasteHistoryLogsTotal(state, action) {
      state.masterHistoryLogs.total = action.payload;
    },
    setChildLogs(state, action) {
      state.childLogs.list = action.payload;
    },
    addChildLogs(state, action) {
      state.childLogs.list = [...state.childLogs.list, ...action.payload];
    },
    setChildLogsPage(state, action) {
      state.childLogs.page = action.payload;
    },
    setChildLogsLimit(state, action) {
      state.childLogs.limit = action.payload;
    },
    setChildLogsHasMore(state) {
      state.childLogs.hasMore =
        state.childLogs.list.length < state.childLogs.total;
    },
    setChildLogsTotal(state, action) {
      state.childLogs.total = action.payload;
    },
    setIsFetchingChildLogs(state, action) {
      state.childLogs.isFetching = action.payload;
    },
    resetChildLogs(state) {
      state.childLogs = initialState.childLogs;
    },
  },
});

export const fetchMasterHistoryLogs = createAsyncThunk(
  "masterHistory/fetchMasterHistoryLogs",
  async (params, { dispatch }) => {
    const { page } = params;
    dispatch(setIsFetchingMasterHistoryLogs(true));
    const [err, response] = await to(API.MasterHistory.all(params));

    if (response.data) {
      if (page === 1) {
        dispatch(setMasterHistoryLogs(response.data.list));
      } else {
        dispatch(addMasterHistoryLogs(response.data.list));
      }

      dispatch(setMasteHistoryLogsTotal(response.data.total));
      dispatch(setMasteHistoryLogsLimit(response.data.limit));
      dispatch(setMasteHistoryLogsPage(response.data.page));
      dispatch(setMasteHistoryLogsHasMore());
    }
    dispatch(setIsFetchingMasterHistoryLogs(false));
  }
);

export const fetchChildLogs = createAsyncThunk(
  "masterHistory/fetchChildLogs",
  async (params, { dispatch }) => {
    const { page } = params;
    dispatch(setIsFetchingChildLogs(true));
    const [err, response] = await to(API.MasterHistory.all(params));
    if (response.data) {
      if (page === 1) {
        dispatch(setChildLogs(response.data.list));
      } else {
        dispatch(addChildLogs(response.data.list));
      }
      dispatch(setChildLogsTotal(response.data.total));
      dispatch(setChildLogsLimit(response.data.limit));
      dispatch(setChildLogsPage(response.data.page));
      dispatch(setChildLogsHasMore());
    } else vToast(getErrorToastMessage(err));

    dispatch(setIsFetchingChildLogs(false));
  }
);

export const {
  // master histoy
  setMasterHistoryLogs,
  addMasterHistoryLogs,
  setIsFetchingMasterHistoryLogs,
  setMasteHistoryLogsPage,
  setMasteHistoryLogsLimit,
  setMasteHistoryLogsHasMore,
  setMasteHistoryLogsTotal,
  resetMasterHistoryLogs,

  // child logs
  setChildLogs,
  addChildLogs,
  setChildLogsPage,
  setChildLogsLimit,
  setChildLogsHasMore,
  setChildLogsTotal,
  setIsFetchingChildLogs,
  resetChildLogs,
} = masterHistorySlice.actions;

export default masterHistorySlice.reducer;
