import PropsTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { setAppliedFilters } from "@/store/reducers/filters";

import Filters from "@/components/core/Filters";
import DateRangeFilter from "@/components/core/Filters/DateRangeFilter";
import Icon from "@/components/core/Icon";
import {
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
} from "@/utils/constants/filters";

export default function QrPayAndExpenseFilter({ filters }) {
  const dispatch = useDispatch();
  return filters ? <Filters filters={filters} /> : null;
}

QrPayAndExpenseFilter.propTypes = {
  filters: PropsTypes.arrayOf(Object),
};
