import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import {
  FILTER_OPTIONS,
  FILTER_OPTIONS_LABEL,
} from "@/utils/constants/analytics";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import { EXPORTS_TOAST_CONFIG, EXPORT_STATUS } from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import API from "@/api";

const initialState = {
  spendChartData: {
    currentMonth: {
      xaxis: [],
      series: [],
    },
    previousMonth: {
      xaxis: [],
      series: [],
    },
    budgetId: null,
    isFetching: true,
    cashback: 0,
    savings: 0,
    currency: "SGD",
    totalSpends: null,

    filterOptions: [
      {
        label: FILTER_OPTIONS_LABEL[FILTER_OPTIONS.CARDS],
        value: FILTER_OPTIONS.CARDS,
      },
      {
        label: FILTER_OPTIONS_LABEL[FILTER_OPTIONS.BILL_PAY],
        value: FILTER_OPTIONS.BILL_PAY,
      },
      {
        label: FILTER_OPTIONS_LABEL[FILTER_OPTIONS.REIMBURSEMENT],
        value: FILTER_OPTIONS.REIMBURSEMENT,
      },
      {
        label: FILTER_OPTIONS_LABEL[FILTER_OPTIONS.TOTAL],
        value: FILTER_OPTIONS.TOTAL,
      },
    ],
  },
  companyDashboardSpends: null,
  isFetchingCompanyDashboardSpends: false,
  companyDashboardSpendChartData: null,
  isFetchingCompanyDashboardSpendChartData: false,
  companyAccountBalanceChartData: null,
  isFetchingCompanyAccountBalanceChartData: false,
  companyAccountBalanceAnalytics: null,
  isFetchingCompanyAccountBalanceAnalytics: null,
  months: [],

  recentSpends: [],
  largestSpends: [],
  topSpenders: [],

  analyticsExportData: null,
  isExporting: false,
  analyticsTags: null,
  isFetchingAnalyticsTags: false,
  analyticsLedgerCurrency: [],
  isFetchingAnalyticsLedgerCurrencies: false,

  transactions: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: 0,
    isFetching: false,
    hasMore: true,
  },

  entities: [],
  isFetchingEntities: false,

  analyticsLedgerData: null,
  isLoading: false,

  filterKeys: {
    transactions: {
      expenses: [
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.entity,
        AVAILABLE_FILTER_KEYS.analyticsLimit,
        AVAILABLE_FILTER_KEYS.transactionStatus,
      ],
      billPay: [
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.entity,
        AVAILABLE_FILTER_KEYS.analyticsLimit,
      ],
      reimbursement: [
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.entity,
        AVAILABLE_FILTER_KEYS.analyticsLimit,
        AVAILABLE_FILTER_KEYS.claimStatus,
      ],
      others: [
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.transactionType,
        AVAILABLE_FILTER_KEYS.entity,
        AVAILABLE_FILTER_KEYS.analyticsLimit,
      ],
      qrPay: [
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.entity,
        AVAILABLE_FILTER_KEYS.analyticsLimit,
        AVAILABLE_FILTER_KEYS.transactionStatus,
      ],
      payroll: [
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.entity,
        AVAILABLE_FILTER_KEYS.analyticsLimit,
      ],
    },
    ledger: [AVAILABLE_FILTER_KEYS.datePeriods, AVAILABLE_FILTER_KEYS.entity],
  },
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setIsFetchingSpendChartData(state, action) {
      state.spendChartData.isFetching = action.payload;
    },
    setSpendChartMonthData(state, action) {
      const { currentMonth, previousMonth } = action.payload;
      state.spendChartData.currentMonth = currentMonth;
      state.spendChartData.previousMonth = previousMonth;
    },
    setMonths(state, action) {
      state.months = action.payload;
    },
    setCompanyDashboardSpendChartData(state, action) {
      state.companyDashboardSpendChartData = action.payload;
    },
    setIsFetchingCompanyDashboardSpendChartData(state, action) {
      state.isFetchingCompanyDashboardSpendChartData = action.payload;
    },
    setCompanyAccountBalanceChartData(state, action) {
      state.companyAccountBalanceChartData = action.payload;
    },
    setIsFetchingCompanyAccountBalanceChartData(state, action) {
      state.isFetchingCompanyAccountBalanceChartData = action.payload;
    },
    setCompanyAccountBalanceAnalytics(state, action) {
      state.companyAccountBalanceAnalytics = action.payload;
    },
    setIsFetchingCompanyAccountBalanceAnalytics(state, action) {
      state.isFetchingCompanyAccountBalanceAnalytics = action.payload;
    },
    setCompanyDashboardSpends(state, action) {
      state.companyDashboardSpends = action.payload;
    },
    setIsFetchingCompanyDashboardSpends(state, action) {
      state.isFetchingCompanyDashboardSpends = action.payload;
    },
    setTotalSpends(state, action) {
      state.totalSpends = action.payload;
    },
    setAnalyticsTransaction(state, action) {
      state.transactions.list = action.payload;
    },
    appendAnalyticsTransaction(state, action) {
      state.transactions.list = [...state.transactions.list, ...action.payload];
    },
    setIsFetchingAnalyticsTransaction(state, action) {
      state.transactions.isFetching = action.payload;
    },
    setAnalyticsTransactionTotal(state, action) {
      state.transactions.total = action.payload;
    },
    setAnalyticsTransactionPage(state, action) {
      state.transactions.page = action.payload;
    },
    setAnalyticsTransactionLimit(state, action) {
      state.transactions.limit = action.payload;
    },
    setAnalyticsTransactionHasMore(state) {
      state.transactions.hasMore =
        state.transactions.list.length < state.transactions.total;
    },

    resetAnalyticsTransactionsData(state) {
      state.transactions = initialState.transactions;
    },

    setAnalyticsExportData(state, action) {
      state.analyticsExportData = action.payload;
    },
    setAnalyticsIsExporting(state, action) {
      state.isExporting = action.payload;
    },
    setAnalyticsTags(state, action) {
      state.analyticsTags = action.payload;
    },

    setAnalyticsLedgerCurrency(state, action) {
      state.analyticsLedgerCurrency = action.payload;
    },
    setIsFetchingAnalyticsTags(state, action) {
      state.isFetchingAnalyticsTags = action.payload;
    },
    setIsFetchingAnalyticsLedgerCurrencies(state, action) {
      state.isFetchingAnalyticsLedgerCurrencies = action.payload;
    },
    setEntites(state, action) {
      state.entities = action.payload;
    },
    setIsFetchingEntites(state, action) {
      state.isFetchingEntities = action.payload;
    },
    setAnalyticsLedgerData(state, action) {
      state.analyticsLedgerData = action.payload;
    },
    setIsLoadingRequestStatementApi(state, action) {
      state.isLoading = action.payload;
    },
  },
});

// export const fetchSpendChart = createAsyncThunk(
//   "analytics/fetchSpendChart",
//   async (payload, { dispatch }) => {
//     dispatch(setIsFetchingSpendChartData(true));
//     const [err, response] = await to(API.Dashboard.spendMetrics());

//     if (response) {
//       const { currentMonth, previousMonth, months } = response;
//       dispatch(setSpendChartMonthData({ currentMonth, previousMonth }));
//       dispatch(setMonths(months));
//     }
//     dispatch(setIsFetchingSpendChartData(false));
//   }
// );

export const fetchCompanyDashboardSpendChart = createAsyncThunk(
  "analytics/fetchCompanyDashboardSpendChart",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCompanyDashboardSpendChartData(true));
    const [err, response] = await to(API.ClientAnalytics.spendMetrics(params));

    if (response?.data) {
      const data = {};
      Object.entries(response?.data)?.forEach(([key, value]) => {
        data[key] = JSON.parse(value);
      });
      dispatch(setCompanyDashboardSpendChartData(data));
    }
    dispatch(setIsFetchingCompanyDashboardSpendChartData(false));
  }
);

export const fetchCompanyAccountBalanceChart = createAsyncThunk(
  "analytics/fetchCompanyAccountBalanceChart",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCompanyAccountBalanceChartData(true));
    const [err, response] = await to(
      API.SpendMetrics.getBalanceMetrics(params)
    );

    if (response) {
      dispatch(setCompanyAccountBalanceChartData(response));
    }
    dispatch(setIsFetchingCompanyAccountBalanceChartData(false));
  }
);

export const fetchCompanyAccountBalanceAnalytics = createAsyncThunk(
  "analytics/fetchCompanyAccountBalanceAnalytics",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCompanyAccountBalanceAnalytics(true));
    const [err, response] = await to(
      API.SpendMetrics.getBalanceMetrics(params)
    );

    if (response) {
      dispatch(setCompanyAccountBalanceAnalytics(response));
    }
    dispatch(setIsFetchingCompanyAccountBalanceAnalytics(false));
  }
);

export const fetchCompanyDashboardSpends = createAsyncThunk(
  "analytics/fetchCompanyDashboardSpends",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCompanyDashboardSpends(true));
    const [err, response] = await to(
      API.ClientAnalytics.spends({ ...params, spend_type: "client" })
    );

    if (response?.data) {
      dispatch(setCompanyDashboardSpends(response?.data));
    }
    dispatch(setIsFetchingCompanyDashboardSpends(false));
  }
);

export const fetchAnalyticsEntitites = createAsyncThunk(
  "app/fetchAnalyticsEntitites",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingEntites(true));

    const [err, response] = await to(API.PartnerAnalyticsAccounting.entities());
    if (response?.data) {
      dispatch(setEntites(response.data));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingEntites(false));
  }
);

export const analyticsRequestStatement = createAsyncThunk(
  "analytics/analyticsRequestStatement",
  async (params, { dispatch }) => {
    dispatch(setIsLoadingRequestStatementApi(true));

    const { onSuccess, payload } = params;
    const [err, response] = await to(
      API.PartnerAnalyticsAccounting.requestStatement(payload)
    );
    if (response?.data) {
      if (onSuccess) {
        onSuccess();
      }
      vToast(getSuccessToastMessage(response));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsLoadingRequestStatementApi(false));
  }
);

export const analyticsBulkExport = createAsyncThunk(
  "analytics/analyticsBulkExport",
  async (params, { dispatch }) => {
    dispatch(setAnalyticsIsExporting(true));

    const { payload, onSuccess = () => {} } = params;
    const [err, response] = await to(
      API.PartnerAnalyticsAccounting.bulkExport(payload)
    );

    if (response && !err) {
      if (onSuccess) onSuccess();
      const config = EXPORTS_TOAST_CONFIG[EXPORT_STATUS.initiated];

      config.description = config.description.replace(
        "{{downloadsPagePath}}",
        `${ROUTES.exports.base.absolutePath}`
      );

      vToast(config);
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setAnalyticsIsExporting(false));
  }
);

export const fetchAnalyticsTags = createAsyncThunk(
  "analytics/fetchAnalyticsTags",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingAnalyticsTags(true));

    const [err, response] = await to(
      API.PartnerAnalyticsAccounting.analyticsTags(params)
    );

    if (response && !err) {
      dispatch(setAnalyticsTags(response?.data?.list));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingAnalyticsTags(false));
  }
);

export const fetchAnalyticsLedgerCurrencies = createAsyncThunk(
  "analytics/fetchAnalyticsLedgerCurrencies",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingAnalyticsLedgerCurrencies(true));

    const [err, response] = await to(
      API.PartnerAnalyticsAccounting.analyticsLedgerCurrencies(params)
    );

    if (response?.data && !err) {
      dispatch(setAnalyticsLedgerCurrency(response?.data?.currencies));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingAnalyticsLedgerCurrencies(false));
  }
);

export const fetchAnalyticsTransaction = createAsyncThunk(
  "analytics/fetchAnalyticsTransaction",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingAnalyticsTransaction(true));

    const { subdomain, ...rest } = params;

    const [err, response] = await to(
      API.PartnerAnalyticsAccounting.all(params)
    );

    if (response && !err) {
      const { data } = response;

      if (params.page === 1) {
        dispatch(setAnalyticsTransaction(data?.list));
      } else {
        dispatch(appendAnalyticsTransaction(data?.list));
      }

      dispatch(setAnalyticsTransactionTotal(data.total));
      dispatch(setAnalyticsTransactionPage(data.page));
      dispatch(setAnalyticsTransactionLimit(data.limit));
      dispatch(setAnalyticsTransactionHasMore());
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingAnalyticsTransaction(false));
  }
);

export const {
  setIsFetchingSpendChartData,
  setSpendChartMonthData,
  setMonths,
  setCompanyDashboardSpendChartData,
  setIsFetchingCompanyDashboardSpendChartData,
  setCompanyDashboardSpends,
  setIsFetchingCompanyDashboardSpends,
  setCompanyAccountBalanceChartData,
  setIsFetchingCompanyAccountBalanceChartData,
  setCompanyAccountBalanceAnalytics,
  setIsFetchingCompanyAccountBalanceAnalytics,
  setTotalSpends,
  // Analytics transactions
  setAnalyticsTransaction,
  appendAnalyticsTransaction,
  setAnalyticsTransactionTotal,
  setAnalyticsTransactionPage,
  setAnalyticsTransactionLimit,
  setAnalyticsTransactionHasMore,
  setIsFetchingAnalyticsTransaction,
  resetAnalyticsTransactionsData,
  setAnalyticsExportData,
  setAnalyticsIsExporting,
  setAnalyticsTags,
  setAnalyticsLedgerCurrency,
  setIsFetchingAnalyticsTags,
  setIsFetchingAnalyticsLedgerCurrencies,
  setEntites,
  setIsFetchingEntites,
  setAnalyticsLedgerData,
  setIsLoadingRequestStatementApi,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
