import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchAnalyticsEntitites,
  fetchAnalyticsTransaction,
  resetAnalyticsTransactionsData,
} from "@/store/reducers/analytics";

import {
  analyticsEntitesSelector,
  analyticsTransactionsSelector,
  isFetchingAnalyticsEntitesSelector,
} from "@/store/selectors/analytics";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import { getSortingParams } from "@/components/Accounting/Transactions/common/util";
import AnalyticsTableHelper from "@/components/Analytics/common/AnalyticsTableHelper";
import HeaderAndFilterHelper from "@/components/Analytics/common/HeaderAndFilterHelper";
import { convertFilters } from "@/utils/filters";
import { SORTING_CATEGORY, SORT_KEYS } from "@/utils/constants/sorting";

import { ACCOUNTING_HEADER_IDS } from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function AnalyticsBillPayHelper({ headerTitle, type }) {
  const dispatch = useDispatch();

  const transactions = useSelector(analyticsTransactionsSelector);

  const entities = useSelector(analyticsEntitesSelector);
  const isFetchingEntities = useSelector(isFetchingAnalyticsEntitesSelector);
  const { list, hasMore, page, isFetching, total } = transactions ?? {};
  const currentUser = useSelector(userSelector);
  const appliedFilters = useSelector(appliedFilterSelector);

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  const headers = [
    {
      id: ACCOUNTING_HEADER_IDS.VENDOR,
      label: "accounting.transactions.billPay.listingFields.vendor",
      classes: "text-left",
      checkbox: true,
      colWidth: 320,
    },
    {
      id: ACCOUNTING_HEADER_IDS.VENDOR_OWNER,
      label: "accounting.transactions.billPay.listingFields.vendorOwner",
      classes: "text-left",
      colWidth: 300,
    },
    {
      id: ACCOUNTING_HEADER_IDS.AMOUNT,
      label: "accounting.transactions.billPay.listingFields.amount",
      classes: "text-right justify-end",
      sortable: true,
      colWidth: 300,
      sortingCategory: SORTING_CATEGORY.AMOUNT,
    },
    list?.[0]?.accountingTags?.length
      ? {
          id: ACCOUNTING_HEADER_IDS.ACCOUNTING_TAG,
          label: list?.[0]?.accountingTags?.[0]?.tag,
          classes: "text-left",
          colWidth: 300,
        }
      : null,
    {
      id: ACCOUNTING_HEADER_IDS.ENTITY,
      label: "analytics.entityTableHeader",
      classes: "text-left ",
      colWidth: 300,
    },
    list?.[0]?.customTags?.length
      ? {
          id: ACCOUNTING_HEADER_IDS.CUSTOM_TAG,
          label: list?.[0]?.customTags?.[0]?.tag,
          classes: "text-left",
          colWidth: 300,
        }
      : null,
  ];
  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: headers
      ?.filter((data) => data)
      ?.map((header) => header?.colWidth),
    emptyDataIconSrc: "emptyScreen.svg",
  };

  const onReset = () => {
    dispatch(resetAnalyticsTransactionsData());
  };

  const loadMore = () => {
    dispatch(
      fetchAnalyticsTransaction({
        type,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...getSortingParams(sorting),
        page: pageNum,
        ...convertFilters(appliedFilters),
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...appliedFilters,
      ...getSortingParams(sorting),
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.15,
  });

  useEffect(() => {
    if (!entities?.length && !isFetchingEntities)
      dispatch(fetchAnalyticsEntitites());
  }, [entities, isFetchingEntities]);

  return (
    <div className="mx-11">
      <HeaderAndFilterHelper headerTitle={headerTitle} type={type} />
      <AnalyticsTableHelper
        handleRefChange={handleRefChange}
        headers={headers?.filter((data) => data)}
        selectedTransactions={selectedTransactions}
        setSelectedTransactions={setSelectedTransactions}
        transactions={list}
        tableArgs={tableArgs}
        totalTransactions={total}
        isFetching={isFetching}
        sorting={sorting}
        setSorting={setSorting}
        isGrouped
        page={page}
        hasMore={hasMore}
        type={type}
      />
    </div>
  );
}

export default AnalyticsBillPayHelper;

AnalyticsBillPayHelper.propTypes = {
  headerTitle: PropTypes.string,
  type: PropTypes.string,
};
