import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSliderExpand } from "@/store/reducers/slider";

import { hasSliderExpandedSelector } from "@/store/selectors/slider";

import Icon from "@/components/core/Icon";

/**
 * This component represents an expandable slider with an icon that toggles its expanded state.
 */
const SliderExpansionHandler = ({ iconClasses }) => {
  const dispatch = useDispatch();

  /**
   * Redux Selector: Get the current expanded state of the slider.
   *
   * @type {boolean}
   */
  const hasSliderExpanded = useSelector(hasSliderExpandedSelector);
  const handleExpandIconCLick = () => {
    dispatch(setSliderExpand(!hasSliderExpanded));
  };

  useEffect(() => {
    return () => {
      dispatch(setSliderExpand(false));
    };
  }, []);

  return (
    <Icon
      className={`cursor-pointer ${iconClasses}`}
      name={`${!hasSliderExpanded ? "Expand" : "Collapse"}`}
      handleClick={handleExpandIconCLick}
    />
  );
};

SliderExpansionHandler.propTypes = {
  iconClasses: PropTypes.string,
};
export default SliderExpansionHandler;
