import PropTypes from "prop-types";

import Filters from "@/components/core/Filters";

export default function PeopleTableSearchAndFilter({
  filters,
  context,
  sliderConfig,
}) {
  return (
    <div className="flex items-baseline justify-between w-full">
      <div className="flex flex-col items-start w-full gap-4 text-center">
        <Filters
          filters={filters}
          classes="w-full"
          context={context}
          sliderConfig={sliderConfig}
        />
      </div>
    </div>
  );
}

PeopleTableSearchAndFilter.propTypes = {
  filters: PropTypes.array,
  sliderConfig: PropTypes.string,
};
