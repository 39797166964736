import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";

import { defaultZoomSelector } from "@/store/selectors/fileViewer";
import { ocrFileUriSelector } from "@/store/selectors/ocr";

import ZoomAndPageButton from "@/components/core/FileViewer/Buttons/ZoomAndPageButton";
import Text from "@/components/core/Text";
import { checkIfURLIsValid } from "@/utils/common";
import "./style.scss";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

/**
 * Renders a PDF viewer component.
 *
 * @param {Object} mainState - The main state object.
 * @return {JSX.Element} The PDF viewer component.
 */
const PdfViewer = ({ mainState }) => {
  const {
    currentFileNo,
    currentDocument = null,
    documents = [],
  } = mainState || {};
  const defaultZoomValue = useSelector(defaultZoomSelector);
  const containerRef = useRef(null);
  const ocrFileUri = useSelector(ocrFileUriSelector);
  const [error, setError] = useState(null);
  const [isChecking, setIsChecking] = useState(false); // checking if URL is available

  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);

  const onDocumentLoadSuccess = ({ numPages: _numPages }) => {
    setNumPages(_numPages);
  };

  const handleZoomIn = () => {
    setScale(scale + 0.2);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.2);
  };

  const isZoomInDisable = scale >= 5;
  const isZoomOutDisable = scale <= 0.5;

  // Avoid 404 failure
  useEffect(() => {
    if (!currentDocument?.uri) return;

    (async () => {
      setIsChecking(true);
      const isURLAvailable = await checkIfURLIsValid(currentDocument.uri);

      if (!isURLAvailable) {
        console.warn(
          "PDFRenderer Invalid url, aborting download",
          currentDocument.uri
        );
        setError(true);
      }

      setIsChecking(false);
    })();
  }, []);
  //
  useEffect(() => {
    const handler = (e) => {
      // If multi touch is there then it will true that For us means it is zooming
      if (e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        setTimeout(() => {
          const _scale = Math.exp(-e.deltaY / 100);

          setScale((prev) => {
            const totalScale = Number.parseFloat(prev * _scale).toFixed(2);

            const _isZoomOutDisable = totalScale < 0.5;
            const _isZoomInDisable = totalScale > 5;
            return _isZoomOutDisable || _isZoomInDisable ? prev : totalScale;
          });
        }, 400);
      }
    };
    window.addEventListener("wheel", handler, {
      passive: false,
    });

    return () => {
      window.removeEventListener("wheel", handler, {
        passive: false,
      });
    };
  }, []);

  useEffect(() => {
    setScale(defaultZoomValue);
  }, [defaultZoomValue]);

  if (!currentDocument) return null;

  return (
    <div
      ref={containerRef}
      className="flex flex-col items-center flex-1 w-full pt-4 bg-neutral-50"
    >
      {isChecking ? (
        "Fetching file..." // TODO: add loader
      ) : (
        <div className="pdf-viewer-container">
          {error ? (
            <Text translationKey="misc.fileLoadError" />
          ) : (
            <Document
              file={currentDocument?.fileData ?? currentDocument?.uri}
              onLoadSuccess={onDocumentLoadSuccess}
              className="hide-verticle-scrollbar bg-neutral-50"
              onError={() => console.log("PDFRenderer error react-pdf")}
            >
              {numPages
                ? Array(numPages)
                    .fill(1)
                    .map((_, i) => (
                      <Page
                        height={containerRef.current?.clientHeight}
                        className="react-pdf-pages-page bg-neutral-50"
                        key={i + 1}
                        pageNumber={i + 1}
                        scale={scale}
                      />
                    ))
                : null}
            </Document>
          )}
        </div>
      )}
      {currentFileNo + 1 ? (
        <ZoomAndPageButton
          numPages={currentFileNo + 1 || 1} // +1 coz currentFileNo uses 0 based indexing
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          isZoomInDisable={isZoomInDisable}
          isZoomOutDisable={isZoomOutDisable}
          showPage={documents.length > 1}
          showOCRScannedBadge={currentDocument?.uri === ocrFileUri}
        />
      ) : null}
    </div>
  );
};

export default PdfViewer;

PdfViewer.fileTypes = [".pdf", "application/pdf"];
PdfViewer.weight = 2000;

PdfViewer.propTypes = {
  mainState: PropTypes.object.isRequired,
};
