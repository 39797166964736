import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchAndSelectSyncAttemptWithDetail,
  setSelectedSyncAttemptDetailItem,
} from "@/store/reducers/sync-attempts";

import {
  isFetchingSyncAttemptSingularSelector,
  selectedSyncAttemptSelector,
} from "@/store/selectors/sync-attempts";

import Badge from "@/components/core/Badge";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import Action from "@/components/Accounting/Transactions/common/Action";
import {
  dateToString,
  dateToTimeStampString,
  validateAndConvertToISO8601,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EXPENSE_STATUSES } from "@/constants/expense";

export default function SpecificAttemptSlider() {
  const [searchParams, setSearchParams] = useSearchParams();

  const attemptId = parseInt(
    searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.billPay.sync.id),
    10
  );
  const attempt = useSelector(selectedSyncAttemptSelector);
  const fetching = useSelector(isFetchingSyncAttemptSingularSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAndSelectSyncAttemptWithDetail({ id: attemptId }));
  }, []);

  const actionHandler = (id) => {
    dispatch(
      setSelectedSyncAttemptDetailItem({
        id,
        value: { syncing: true },
        mode: "merge",
      })
    );
  };

  const [referenceElement, setReferenceElement] = useState(null); // tooltip

  return (
    <div className="slider-content-core">
      <div>
        <Text
          classes="text-3xl text-neutral-800 font-bold inline-block"
          translationKey="accounting.transactions.cards.syncAttemptsSingular"
        />
        <p className="mb-6 text-sm font-medium text-neutral-500">
          {dateToTimeStampString(attempt?.lastSyncedDate, false)}
        </p>
        <h2 className="flex items-center gap-1 text-lg font-semibold text-neutral-800">
          <Text translationKey="accounting.transactions.slider.unsuccessfulSync" />
          <div className="text-danger-400 font-bold text-xs rounded-lg bg-danger-50 grid place-items-center min-w-6 min-h-6 px-2 py-[2px]">
            {attempt?.unsuccessfulTransactions.length}
          </div>
        </h2>
      </div>

      <div className="mt-3">
        <Table colWidths={[288 - 2, 200, 120]}>
          {(attempt?.unsuccessfulTransactions ?? [])
            .map(({ id, lastAttemptDate, syncing }) => (
              <tr key={id}>
                <td>
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-neutral-800">
                      {id}
                    </span>
                    <span className="text-xs font-medium text-neutral-500">
                      {dateToString(lastAttemptDate, { year: "numeric" })}
                      {", "}
                      {new Date(
                        validateAndConvertToISO8601(lastAttemptDate)
                      ).toLocaleTimeString()}
                    </span>
                  </div>
                </td>
                <td className="text-sm font-semibold text-center text-neutral-800">
                  <div className="flex items-center justify-center">
                    <div className="relative flex items-center justify-center">
                      <Badge
                        translationKey={
                          syncing
                            ? "accounting.transactions.slider.retryingSync"
                            : "expenseStatuses.sync_failed"
                        }
                        variant={syncing ? "warning" : "danger"}
                        classes="w-[110px]"
                      />

                      {!syncing && (
                        <span
                          data-tip
                          data-for={id.toString()}
                          ref={setReferenceElement}
                          className="absolute -right-6"
                        >
                          <Icon
                            name="Info"
                            className="text-neutral-500 p-[1.5px] h-5 w-5 hover:cursor-pointer"
                          />
                          <Tooltip id={id.toString()} direction="bottom">
                            <Text
                              translationKey="accounting.transactions.slider.syncFailed"
                              classes="text-sm font-medium text-neutral-800"
                            />
                          </Tooltip>
                        </span>
                      )}
                    </div>
                  </div>
                </td>
                <td className="text-sm font-semibold text-center text-neutral-800">
                  <div className="-mx-4">
                    <Action
                      status={EXPENSE_STATUSES.VERIFIED}
                      onClick={() => actionHandler(id)}
                      disabled={syncing}
                    />
                  </div>
                </td>
              </tr>
            ))
            .concat(fetching ? tableLoader() : [])}
        </Table>
      </div>
    </div>
  );
}

function tableLoader() {
  return Array(5)
    .fill(null)
    .map((_, i) => (
      <tr
        key={`${i}_AccountingTransactionsSyncAttemptsSpecific`}
        className="text-left"
      >
        <td>
          <LoaderSkeleton size="lg" />
        </td>
        <td>
          <LoaderSkeleton size="md" />
        </td>
        <td>
          <LoaderSkeleton size="sm" borderRadius="200" />
        </td>
      </tr>
    ));
}
