export const LOADER_CIRCLE_SIZES = {
  xs: [20, 20],
  sm: [32, 32],
  md: [40, 40],
  lg: [48, 48],
  xl: [56, 56],
  xxl: [64, 64],
};
export const LOADER_RECTANGLE_SIZES = {
  xs: [10, 50],
  sm: [20, 60],
  md: [20, 80],
  lg: [25, 100],
  xl: [30, 150],
  xxl: [35, 180],
  xl4: [56, 180],
};
