import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  approveCardRequest,
  fetchActionCentreTabs,
  fetchRequestTableInfo,
  resetPaginationActionCentre,
  skipApprovalCardRequest,
} from "@/store/reducers/actionCentre";
import { setIndexApiReload } from "@/store/reducers/app";
import {
  bulkOperationCardRequest,
  fetchCardRequestTableInfo,
  resetCardRequestTableInfo,
} from "@/store/reducers/cards";

import {
  actionCentreTableHasMoreSelector,
  actionCentreTableIsFetchingSelector,
  actionCentreTableLimitSelector,
  actionCentreTableListSelector,
  actionCentreTablePageSelector,
  actionCentreTableTotalSelector,
  actionInProgressIdSelector,
  cardsFiltersSelector,
} from "@/store/selectors/actionCentre";
import {
  dataActionLoadingIdSelector,
  indexApiReloadSelector,
} from "@/store/selectors/app";
import {
  bulkOperationInProgressSelector,
  cardModuleRequestPageFilterKeysSelector,
  cardRequestTableListSelector,
  hasMoreCardRequestTableSelector,
  isFetchingCardRequestListSelector,
  totalCountCardRequestTableSelector,
} from "@/store/selectors/cards";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import ApprovalProgress from "@/components/core/ApprovalProgress";
import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Filters from "@/components/core/Filters";
import Icon from "@/components/core/Icon";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import Limit from "@/components/Cards/CardsTable/CardTableComponents/Limit";
import LinkedToComponent from "@/components/Cards/CardsTable/CardTableComponents/LinkedToComponent";
import Export from "@/components/Exports";
import ActionCell from "@/components/common/QrPayAndExpense/common/Cells/ActionCell";
import { convertFilters } from "@/utils/filters";
import {
  SORTING_CATEGORY,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";

import {
  ACTION_CENTRE_APPROVAL_TYPE,
  CARDS_REQUEST_TABLE_HEADER_CONFIG,
  CARDS_REQUEST_TABLE_KEYS,
  CARD_REQUEST_TYPE,
} from "@/constants/ActionCentre";
import { CARD_MODULE_REQUEST_PAGE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  BE_FE_REQUEST_TYPE_CARD_REQUEST,
  PENDING_REQUEST_TABS_KEYS,
} from "@/constants/company";
import { EXPENSE_CTA_KEYS } from "@/constants/expense";
import { EXPORT_PAGE_TYPE } from "@/constants/exports";
import { APPROVAL_REQUEST_TYPE, APPROVE, REJECT } from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";

import RequestHistoryButton from "../common/RequestHistory/RequestHistoryButton";
import CardRequestLoader from "./CardRequestLoader";
import CardRequestTypeCell from "./TableComponents/CardRequestType";

export default function CardRequestTable({ tabKey, pageContext }) {
  const headerKeysValues = Object.values(CARDS_REQUEST_TABLE_KEYS);

  const HEADER_KEYS = {
    NON_PAGE_SPECIFIC_CONTEXT_HEADER_KEYS: headerKeysValues,
    [ROUTES.cards.requests.needsYourAction.pathName]: headerKeysValues,
    [ROUTES.cards.requests.pending.pathName]: headerKeysValues,
    [ROUTES.cards.requests.all.pathName]: headerKeysValues,
  };

  const dispatch = useDispatch();

  const {
    isFetchingSelector,
    listSelector,
    hasMoreSelector,
    totalSelector,
    filtersSelector,
  } = getSelectorsByPageContext();

  const actionCentreTableList = useSelector(listSelector);

  const bulkOperationInProgress = useSelector(bulkOperationInProgressSelector);
  const hasMore = useSelector(hasMoreSelector);
  const total = useSelector(totalSelector);

  const isCardModulePage = pageContext === CARD_MODULE_REQUEST_PAGE;

  const appliedFilters = useSelector(appliedFilterSelector);

  const currentUser = useSelector(userSelector);

  const [allSelected, setAllSelcted] = useState(false);

  const isFetching = useSelector(isFetchingSelector);
  const dataActionInProgressId = useSelector(actionInProgressIdSelector);
  const approveActionInProgressId = useSelector(dataActionLoadingIdSelector);
  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const isEmpty = actionCentreTableList?.length === 0;

  const tableListLength = actionCentreTableList?.length;

  const cardsFilterKeys = useSelector(filtersSelector);

  const headerKeys = tabKey
    ? HEADER_KEYS?.[tabKey]
    : HEADER_KEYS.NON_PAGE_SPECIFIC_CONTEXT_HEADER_KEYS;

  const colWidths = Object.values(CARDS_REQUEST_TABLE_HEADER_CONFIG).map(
    (eachColumn) => eachColumn.colWidth
  );

  const page = useSelector(actionCentreTablePageSelector);

  const limit = useSelector(actionCentreTableLimitSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedRowsInfo, setSelectedRowsInfo] = useState({
    selectedIds: {}, // {id:true}
    selectedIdsInfo: {}, // {id: rowInfo}
  });

  const selectedRowsLength = Object.keys(selectedRowsInfo.selectedIds).length;

  function onSucessBulkAction() {
    onSucess();
    setAllSelcted(false);
    setSelectedRowsInfo({
      selectedIds: {},
      selectedIdsInfo: {},
    });
  }

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });
  const SORT_DIRECTION_CYCLE = [SORTING_TYPE.DEC, SORTING_TYPE.INC, null];
  const handleSorting = (category) => {
    const newIndex =
      (SORT_DIRECTION_CYCLE.indexOf(sorting.type) + 1) %
      SORT_DIRECTION_CYCLE.length;

    setSorting((prev) => {
      return {
        category,
        type: SORT_DIRECTION_CYCLE.at(newIndex),
      };
    });
  };

  const tableArgs = {
    colWidths,
    rightColWidths: [200],
    numberOfStickyColsRight: 1,
    numberOfStickyColsLeft: 1,
    headerSticky: true,
    bulkApproveVisible: selectedRowsLength > 0,
    bulkApproveHeight: "100px",
    emptyDataTitle: "myVolopay.actionCentre.emptyScreen.emptyDataTitle",
    emptyDataDescription:
      "myVolopay.actionCentre.emptyScreen.emptyDataDescription",
    bulkApproveContent: (
      <BulkActionComponent
        selectedRows={
          allSelected
            ? selectedRowsLength < actionCentreTableList?.length
              ? total - checkDeselectedIds().length
              : total
            : selectedRowsLength
        }
        totalRows={total}
        bulkApproveSelectAll={allSelected || selectedRowsLength === total}
        showSelectAllButton
        approveInProgress={bulkOperationInProgress}
        selectAllHandler={() => handleBulkOperation()}
        clearSelection={() => handleClearSelection()}
        showApprove
        showExport={false}
        handleBulkAction={handleBulkAction}
        selectedRowsText={`request${
          selectedRowsLength > 1 ? "s" : ""
        } selected`}
        descriptionText="myVolopay.actionCentre.table.descriptionText"
      />
    ),
  };

  const onReset = () => {
    const resetDispatcherFunc =
      pageContext === CARD_MODULE_REQUEST_PAGE
        ? resetCardRequestTableInfo
        : resetPaginationActionCentre;

    dispatch(resetDispatcherFunc());
  };

  const loadMore = () => {
    const loadMoreDispatcherFunc = isCardModulePage
      ? fetchCardRequestTableInfo
      : fetchRequestTableInfo;

    const dispatcherProps = {
      request_type: APPROVAL_REQUEST_TYPE.CARD,
      page: pageNum,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      ...convertFilters(appliedFilters),
      ...(sorting?.category && sorting?.type
        ? {
            [SORT_KEYS.COLUMN]: sorting?.category,
            [SORT_KEYS.DIRECTION]: sorting?.type,
          }
        : {}),
    };

    if (isCardModulePage) {
      dispatcherProps.tab = tabKey;
      dispatcherProps.user = currentUser?.id;
      delete dispatcherProps.user;
      dispatcherProps[SORT_KEYS.COLUMN] = "created_at";
    }

    dispatch(loadMoreDispatcherFunc(dispatcherProps));
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      category: sorting?.category,
      appliedFilters,
      type: sorting?.type,
      tabKey,
      reloadIndexApi,
    },
  });

  useEffect(() => {
    if (allSelected) {
      handleSelectAllCheckBox(true);
    }
  }, [actionCentreTableList]);

  const handleRefChange = useInfiniteScroll(() => {
    setPageNum((prev) => prev + 1);
  });

  function handleBulkOperation() {
    setAllSelcted(true);
    handleSelectAllCheckBox(true);
  }

  function handleClearSelection() {
    setAllSelcted(false);
    handleSelectAllCheckBox(false);
  }

  function handleSelectAllCheckBox(checked) {
    const selectedIdsInfo = {};
    const selectedIds = {};

    if (checked) {
      actionCentreTableList.forEach((tableRow) => {
        selectedIdsInfo[tableRow.id] = tableRow;
        selectedIds[tableRow.id] = tableRow?.id;
      });
    }
    setSelectedRowsInfo({
      selectedIdsInfo,
      selectedIds,
    });
  }

  function handleIndividualRowCheckBox(rowInfo) {
    if (selectedRowsInfo.selectedIds[rowInfo.id]) {
      delete selectedRowsInfo.selectedIds[rowInfo.id];
      delete selectedRowsInfo.selectedIdsInfo[rowInfo.id];
      setSelectedRowsInfo({ ...selectedRowsInfo });
    } else {
      setSelectedRowsInfo({
        selectedIdsInfo: {
          ...selectedRowsInfo.selectedIdsInfo,
          [rowInfo?.id]: rowInfo,
        },
        selectedIds: {
          ...selectedRowsInfo.selectedIds,
          [rowInfo?.id]: true,
        },
      });
    }
  }

  function getRequiredHeadersById(headerKey, index) {
    let HeaderComponent = <></>;
    let extraClasses = "";
    let showHeaderComponent = true;

    switch (headerKey) {
      case CARDS_REQUEST_TABLE_KEYS.REQUEST_TYPE: {
        showHeaderComponent = !(
          pageContext === CARD_MODULE_REQUEST_PAGE &&
          [
            ROUTES.cards.requests.pending.pathName,
            ROUTES.cards.requests.all.pathName,
          ].includes(tabKey)
        );

        HeaderComponent = (
          <Checkbox
            onClickHandler={(checked) => handleSelectAllCheckBox(checked)}
            disabled={isFetching}
            checked={!isEmpty && selectedRowsLength === tableListLength}
          />
        );
        break;
      }

      case CARDS_REQUEST_TABLE_KEYS.AMOUNT: {
        HeaderComponent = (
          <div className="flex flex-col justify-center">
            <Icon
              className={
                sorting?.type === SORTING_TYPE.INC &&
                sorting?.category === SORTING_CATEGORY.AMOUNT
                  ? "text-neutral-500"
                  : "text-neutral-300"
              }
              name="ArrowDropUp"
            />
            <Icon
              className={
                sorting?.type === SORTING_TYPE.DEC &&
                sorting?.category === SORTING_CATEGORY.AMOUNT
                  ? "text-neutral-500"
                  : "text-neutral-300"
              }
              name="ArrowDropDown"
            />
          </div>
        );
        extraClasses = "justify-end";
        break;
      }
      default:
        break;
    }

    return (
      <th className="text-xs font-semibold text-left" key={index}>
        <div
          onClick={
            headerKey === CARDS_REQUEST_TABLE_KEYS.AMOUNT
              ? () => handleSorting(SORTING_CATEGORY.AMOUNT)
              : () => {}
          }
          className={`flex flex-row items-center gap-2 cursor-pointer ${extraClasses} w-full`}
        >
          {showHeaderComponent ? HeaderComponent : null}
          <Text
            translationKey={CARDS_REQUEST_TABLE_HEADER_CONFIG[headerKey].label}
          />
        </div>
      </th>
    );
  }

  function getRequiredColumnCellById(key, rowInfo) {
    let TableCell = "-";

    // CARDS_REQUEST_TABLE_KEYS.REQUEST_TYPE
    const cardType = rowInfo?.request.cardType;
    const requestType = rowInfo?.request.type;
    const requestName = rowInfo?.request?.name;
    const requestCta = rowInfo?.request?.ctas;

    const requestDate = rowInfo?.request?.requestDate;
    const isRequestTypeCheckboxChecked =
      selectedRowsInfo.selectedIds[rowInfo.id];
    const showRequestTypeCheckbox = !(
      pageContext === CARD_MODULE_REQUEST_PAGE &&
      [
        ROUTES.cards.requests.pending.pathName,
        ROUTES.cards.requests.all.pathName,
      ].includes(tabKey)
    );

    // CARDS_REQUEST_TABLE_KEYS.AMOUNT
    const requestAmount = rowInfo?.request?.amount;
    const currency = rowInfo?.request?.currency;
    const frequency = rowInfo?.budgetType;

    // CARDS_REQUEST_TABLE_KEYS.REQUEST_REASON
    const requestReason = rowInfo?.request.reason;
    const requestId = rowInfo?.id;

    // CARDS_REQUEST_TABLE_KEYS.LINKED_TO
    const linkedToName = rowInfo?.linkedTo?.name;
    const linkedToType = rowInfo?.linkedTo?.type;

    // CARDS_REQUEST_TABLE_KEYS.APPROVAL_STATUS
    const totalNumberOfApprovals = rowInfo?.request?.totalNumberOfApprovals;
    const currentStep =
      !rowInfo?.request?.currentApprovalLevel ||
      typeof rowInfo?.request?.currentApprovalLevel === typeof ""
        ? 0
        : rowInfo?.request?.currentApprovalLevel;
    const requestStatus = rowInfo?.approvalStatus;
    const previousApprovers = rowInfo?.request?.previousApprovers;
    const hasInSufficientFunds = rowInfo?.request?.insufficientFunds;
    const cardRequestId = rowInfo?.request?.id;
    const cardUsageType = rowInfo?.request?.cardUsageType;
    const status = rowInfo?.request?.status;

    const disableCheck = Boolean(
      (approveActionInProgressId &&
        approveActionInProgressId === rowInfo?.request?.id) ||
        (dataActionInProgressId &&
          dataActionInProgressId === rowInfo?.request?.id)
    );

    switch (key) {
      case CARDS_REQUEST_TABLE_KEYS.REQUEST_TYPE:
        TableCell = (
          <div onClick={() => handleRowClick(rowInfo)}>
            <CardRequestTypeCell
              cardType={cardType}
              requestType={requestType}
              name={requestName}
              requestDate={requestDate}
              handleCheckboxClick={() => handleIndividualRowCheckBox(rowInfo)}
              isChecked={isRequestTypeCheckboxChecked}
              showCheckbox={showRequestTypeCheckbox}
              hasInSufficientFunds={hasInSufficientFunds}
              cardRequestId={cardRequestId}
              disabled={disableCheck}
              status={status}
              cardUsageType={cardUsageType}
            />
          </div>
        );
        break;

      case CARDS_REQUEST_TABLE_KEYS.AMOUNT:
        TableCell = (
          <div className="flex items-center justify-end text-right">
            <Limit
              amount={requestAmount}
              currency={currency}
              frequency={frequency}
              showFrequency
            />
          </div>
        );
        break;

      case CARDS_REQUEST_TABLE_KEYS.REQUEST_REASON:
        TableCell = (
          <>
            <div className="truncate">
              <Text
                id={`request-reason${requestId}`}
                translationKey={requestReason || "-"}
                classes="text-neutral-800 text-sm font-semibold"
              />
            </div>

            {requestReason ? (
              <Tooltip direction="top" id={`request-reason${requestId}`}>
                {requestReason}
              </Tooltip>
            ) : null}
          </>
        );
        break;

      case CARDS_REQUEST_TABLE_KEYS.ACTIONS:
        TableCell = (
          <ActionCell
            expenseFooterCtas={requestCta}
            onSubmit={getActionButtons}
            val={rowInfo}
            disabled={approveActionInProgressId || dataActionInProgressId}
            showLoader={disableCheck}
          />
        );
        break;

      case CARDS_REQUEST_TABLE_KEYS.LINKED_TO:
        TableCell = (
          <LinkedToComponent
            linkedToName={linkedToName}
            linkedToNameClasses="text-sm"
            linkedToType={linkedToType}
            linkedToTypeClasses="text-sm"
          />
        );
        break;

      case CARDS_REQUEST_TABLE_KEYS.APPROVAL_STATUS:
        TableCell = (
          <ApprovalProgress
            id={rowInfo?.id}
            totalApprovalSteps={totalNumberOfApprovals}
            currentStep={currentStep}
            currentStatus={requestStatus}
            previousApprovers={previousApprovers}
          />
        );
        break;

      default:
        break;
    }
    return TableCell;
  }

  function getSelectorsByPageContext() {
    if (pageContext === CARD_MODULE_REQUEST_PAGE) {
      return {
        isFetchingSelector: isFetchingCardRequestListSelector,
        listSelector: cardRequestTableListSelector,
        hasMoreSelector: hasMoreCardRequestTableSelector,
        totalSelector: totalCountCardRequestTableSelector,
        filtersSelector: cardModuleRequestPageFilterKeysSelector,
      };
    }

    return {
      isFetchingSelector: actionCentreTableIsFetchingSelector,
      listSelector: actionCentreTableListSelector,
      hasMoreSelector: actionCentreTableHasMoreSelector,
      totalSelector: actionCentreTableTotalSelector,
      filtersSelector: cardsFiltersSelector,
    };
  }

  function getActionButtons(key, val) {
    switch (key) {
      case APPROVE:
        handleBtnClick(val, APPROVE);
        break;

      case REJECT:
        handleBtnClick(val, REJECT);
        break;

      case EXPENSE_CTA_KEYS.SKIP_APPROVALS:
        handleBtnClick(val, EXPENSE_CTA_KEYS.SKIP_APPROVALS);
        break;
      default:
        break;
    }
  }

  function handleRowClick(tableRowInfo) {
    const id = tableRowInfo?.request?.id;
    const type = tableRowInfo?.request.type;

    const searchParamKey =
      type === CARD_REQUEST_TYPE.TOP_UP_REQUEST
        ? SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest
        : SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest?.[
            BE_FE_REQUEST_TYPE_CARD_REQUEST[type]
          ];

    searchParams.append(searchParamKey, id);
    setSearchParams(searchParams);
  }

  const onSucess = () => {
    dispatch(setIndexApiReload(!reloadIndexApi));
    if (!isCardModulePage) {
      dispatch(fetchActionCentreTabs());
    }
  };

  function handleApprove(data) {
    dispatch(
      approveCardRequest({
        target_id: data?.request?.id,
        type: ACTION_CENTRE_APPROVAL_TYPE.card,
        onSucess,
      })
    );
  }

  function handleBulkAction(action) {
    const deleselectedRowsInfo = checkDeselectedIds();
    const delSelectedRequestIds = deleselectedRowsInfo?.map(
      (rowInfo) => rowInfo?.id
    );

    const payload = {
      operation: action,
      ...(allSelected
        ? delSelectedRequestIds?.length > 0
          ? { all: true, except_ids: delSelectedRequestIds }
          : { all: true }
        : {
            request_ids: Object.keys(selectedRowsInfo.selectedIds),
          }),
      ...convertFilters(appliedFilters),
    };

    dispatch(
      bulkOperationCardRequest({ ...payload, onSuccess: onSucessBulkAction })
    );
  }

  function checkDeselectedIds() {
    const currentSelectedIdsLength = Object.keys(
      selectedRowsInfo?.selectedIds
    )?.length;

    if (currentSelectedIdsLength < total) {
      return (
        actionCentreTableList?.filter(
          (tableRowInfo) => !selectedRowsInfo?.selectedIds?.[tableRowInfo?.id]
        ) ?? []
      );
    }
    return [];
  }

  const handleReject = (data) => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest,
      data?.request?.id
    );
    setSearchParams(searchParams);
  };

  const handleSkipApprovals = (data) => {
    dispatch(
      skipApprovalCardRequest({
        id: data?.request?.id,
        do: "skip_approvals",
        onSucess,
      })
    );
  };

  const handleBtnClick = (data, status) => {
    // setInProgress(data?.id);
    if (status === APPROVE) {
      handleApprove(data);
    } else if (status === REJECT) {
      handleReject(data);
    } else if (status === EXPENSE_CTA_KEYS.SKIP_APPROVALS) {
      handleSkipApprovals(data);
    }
  };

  function filterHeaderByKey(headerKey) {
    return headerKeysValues?.filter((key) => key !== headerKey);
  }

  return (
    <div>
      <div className="flex flex-wrap items-center justify-between mb-6">
        <Filters filters={cardsFilterKeys} />

        <div className="flex items-center gap-6">
          <Export
            totalExports={total}
            storeName="expenses"
            sectionName="expenses"
            selectedFilter={appliedFilters}
            additionalFilters={{
              category: sorting?.category,
              appliedFilters,
              type: sorting?.type,
              tabKey,
              ...EXPORT_PAGE_TYPE.CARD_REQUEST,
            }}
          />

          {pageContext !== CARD_MODULE_REQUEST_PAGE ? (
            <RequestHistoryButton context={PENDING_REQUEST_TABS_KEYS.CARDS} />
          ) : null}
        </div>
      </div>

      <div className="max-h-screen">
        <Table {...tableArgs}>
          <tr>
            {headerKeys.map((headerKey, index) =>
              getRequiredHeadersById(headerKey, index)
            )}
          </tr>

          {!isEmpty || isFetching
            ? actionCentreTableList.map((tableRow, index) => {
                const disableCheck = Boolean(
                  (approveActionInProgressId &&
                    approveActionInProgressId === tableRow?.request?.id) ||
                    (dataActionInProgressId &&
                      dataActionInProgressId === tableRow?.request?.id)
                );

                return (
                  <tr
                    ref={(ref) => {
                      if (
                        hasMore &&
                        index === actionCentreTableList.length - 1
                      ) {
                        handleRefChange(ref);
                      }
                    }}
                    key={`list-map-${index}-${tableRow?.id}`}
                    className="cursor-pointer"
                  >
                    {headerKeys.map((headerKey, id) => {
                      return (
                        <td
                          key={id}
                          className={`${
                            selectedRowsInfo.selectedIds[tableRow?.id] ||
                            allSelected
                              ? "selected-row-cell"
                              : ""
                          }  ${
                            disableCheck
                              ? "bg-neutral-100 text-neutral-500 pointer-events-none"
                              : ""
                          }`}
                        >
                          {getRequiredColumnCellById(headerKey, tableRow)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : null}
          {isFetching ? <CardRequestLoader /> : null}
        </Table>
      </div>
    </div>
  );
}

CardRequestTable.propTypes = {
  pageContext: PropTypes.string,
  tabKey: PropTypes.string,
};
