import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { setCurrentPageNumber } from "@/store/reducers/fileViewer";

import { urlMapSelector } from "@/store/selectors/loadersError";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import vToast from "@/utils/vToast";
import { getId, getMBs } from "@/utils/common";

import { FILE_UPLOAD_ERROR, FILE_UPLOAD_VARAINTS } from "@/constants/common";

import {
  MAX_NUMBER_OF_FILES_ALLOWED,
  MAX_SIZE_OF_FILE_ALLOWED_MB,
} from "@/constants/fileViewer";
import FileViewer from "../FileViewer";
import Progress from "../Progress";

/**
 * Renders a file upload component with various configuration options.
 *
 * @param {Object} props - The properties for the FileUpload component.
 * @param {string} name - The name of the file upload component.
 * @param {Object} accept - The accepted file types for upload, defaulting to image types.
 * @param {Array} files - The array of files to be uploaded.
 * @param {string} error - The error message, if any.
 * @param {boolean} showErrorAtCenter - show error at the center instead of at bottom boundary (if true, boundary error text will not be shown)
 * @param {function} handleFileChange - The callback function for file change event.
 * @param {number} maxSize - The maximum size of the file to be uploaded, in bytes.
 * @param {boolean} multiple - Flag indicating whether multiple files can be uploaded.
 * @param {string} acceptText - The text indicating accepted file types and size limit.
 * @param {Object} primaryAction - The primary action configuration.
 * @param {function} primaryAction.handler - The handler function for primary action.
 * @param {string} primaryAction.label - The label for primary action.
 * @param {string} primaryAction.icon - The icon for primary action.
 * @param {string} primaryAction.iconClasses - The classes for primary action icon.
 * @param {Object} secondaryAction - The secondary action configuration.
 * @param {function} secondaryAction.handler - The handler function for secondary action.
 * @param {string} secondaryAction.label - The label for secondary action.
 * @param {string} secondaryAction.icon - The icon for secondary action.
 * @param {string} secondaryAction.iconClasses - The classes for secondary action icon.
 * @param {string} variant - The variant of the file upload component. box button custom ` FILE_UPLOAD_VARAINTS`
 * @param {number} maxFiles - The maximum number of files that can be uploaded.
 * @param {string} buttonText - The text for the file upload button.
 * @param {boolean} insideForm - Flag indicating whether the component is placed inside a form.
 * @param {boolean} replaceAfterUpload - Flag indicating whether files should be replaced after upload.
 * @param {React.Component} customComponent - The custom component to be rendered.
 * @param {boolean} hideCustomButtonAfterUpload - Flag indicating whether the custom button should be hidden after upload.
 * @param {React.Component} previewCustomComponent - The custom component to be rendered for file preview.
 * @param {string} previewClasses - The classes for the file preview container.
 * @param {string} previewChildClasses - The classes for the file preview child elements.
 * @param {boolean} showPreview - this is to stop preview of files.
 * @param {boolean} hidePreviewUploadComponent - this hide upload Component
 * @param {boolean} showNoFileUI - Show No FIle UI
 * @return {React.Component} The rendered FileUpload component.
 */
export default function FileUpload({
  profileUpload,
  name,
  accept = {
    "image/*": [".png", ".gif", ".jpeg", ".jpg"],
  },
  files = [],
  error = null,
  showErrorAtCenter = false,
  handleFileChange = () => {},
  maxSize = MAX_SIZE_OF_FILE_ALLOWED_MB * 1024 * 1024,
  maxFiles = MAX_NUMBER_OF_FILES_ALLOWED,
  multiple = true,
  acceptText = "PNG, JPG, JPEG, GIF upto 10MB",
  primaryAction = {
    handler: () => {},
    label: "Delete",
    icon: "Delete",
    className: "text-danger-600",
    bgClassName: "bg-danger-50",
  },
  secondaryAction = {
    handler: () => {},
    label: "Download",
    icon: "Download",
    className: "text-primary-500", // some issue with SVG, using !
    bgClassName: "bg-primary-50",
  },
  variant = FILE_UPLOAD_VARAINTS.BOX, // or "button",
  buttonText = "fileUpload.uploadFiles",
  insideForm = false,
  replaceAfterUpload = false,
  customComponent,
  hideCustomButtonAfterUpload = false,
  previewCustomComponent,
  previewClasses = "",
  previewChildClasses = "w-11 h-11",
  showPreview = true,
  isDisabled: _isDisabled = false,
  primaryActionDisabled = false,
  secondaryActionDisabled = false,
  setIsFileDialogOpen = () => {},
  outerClasses,
  innerClasses,
  middleClasses,
  previewHeader,
  hidePreviewUploadIcon,
  disabledLabel = "",
  hidePreviewUploadComponent,
  showNoFileUI = false,
  apiKey,
  showLoader,
  formError,
  isShowErrorToast,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loaderMapper = useSelector(urlMapSelector);
  const currentFileUploadErrorLoaderState = loaderMapper?.[apiKey];
  const showLoadingUI =
    showLoader && currentFileUploadErrorLoaderState
      ? currentFileUploadErrorLoaderState?.isFetching
      : false;

  const showErrorUI = currentFileUploadErrorLoaderState
    ? currentFileUploadErrorLoaderState?.isError
    : false;

  const isDisabled =
    showLoader && !_isDisabled
      ? currentFileUploadErrorLoaderState?.isFetching
      : _isDisabled;
  const [number, setNumber] = useState(0);
  const newUploadedFiles = useRef(new Set());

  useEffect(() => {
    let interval = null;
    if (showLoader && showLoadingUI) {
      interval = setInterval(() => {
        setNumber((prev) => {
          if (prev <= 80) return prev + 2;
          clearInterval(interval);
          return prev;
        });
      }, 1000);
    } else clearInterval(interval);

    return () => {
      if (showLoader && showLoadingUI) clearInterval(interval);
    };
  }, [showLoader, showLoadingUI]);

  const [fileUploaded, setFileUploaded] = useState(files || []);
  const [rejectedFiles, setRejectedFile] = useState([]);

  const [showModal, setShowModal] = useState(false);
  // to setup pre added value at first
  useEffect(() => {
    setFileUploaded(files);
  }, [JSON.stringify(files)]);

  // dispatching file upload handleChange
  useEffect(() => {
    if (insideForm)
      handleFileChange({ target: { value: fileUploaded, type: "file", name } });
    else handleFileChange(fileUploaded);
  }, [fileUploaded]);

  const getErrorText = (errorMessage, file) => {
    switch (errorMessage) {
      case FILE_UPLOAD_ERROR.FILE_INVALID_TYPE:
        return { error: "fileUpload.errors.invalidFileType" };
      case FILE_UPLOAD_ERROR.FILE_TOO_LARGE:
        return {
          error: "fileUpload.errors.fileTooLarge",
          errorProps: { size: Object.values(getMBs(maxSize))?.join(" ") },
        };

      case FILE_UPLOAD_ERROR.TOO_MANY_FILES:
        return {
          error: "fileUpload.errors.tooManyFiles",
          errorProps: {
            noOfFiles: maxFiles,
          },
        };
      default:
        break;
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxSize,
    multiple,
    maxFiles,
    disabled: isDisabled,
    onFileDialogCancel: () => {
      setIsFileDialogOpen(false);
    },
    onFileDialogOpen: () => {
      if (!isDisabled) setIsFileDialogOpen(true);
    },
    onDrop: (_acceptedFiles, fileRejections) => {
      const acceptedFiles = _acceptedFiles.map((file) =>
        Object.assign(file, {
          uuid: getId(),
          preview: URL.createObjectURL(file),
        })
      );
      if (!isDisabled) {
        let newFiles = replaceAfterUpload ? [] : [...files];
        if (showLoader)
          newUploadedFiles.current = new Set(
            acceptedFiles?.map((item) => item?.uuid)
          );
        // Returns file object
        newFiles = [...newFiles, ...acceptedFiles];

        setRejectedFile(
          fileRejections?.map((item) =>
            Object.assign(item, {
              preview: URL.createObjectURL(item?.file),
            })
          )
        );
        setFileUploaded(newFiles);
        return newFiles;
      }
      return files;
    },
    validator: (item) => {
      const { name: fileName, type } = item;
      if (fileName) {
        const ext = fileName.split(".").at(-1)?.toLowerCase();

        if (
          accept &&
          accept?.[type] &&
          accept?.[type]?.length &&
          !accept?.[type]?.includes(`.${ext}`)
        ) {
          return {
            code: FILE_UPLOAD_ERROR.FILE_INVALID_TYPE,
            message: "file not supported", // we not showing this text we have custom error text in getErrorText so need translation
          };
        }
      }

      return null;
    },
  });
  const PreviewCustomComponent = previewCustomComponent;

  const PreviewComponentWithProp = PreviewCustomComponent
    ? ({ file, index }) => (
        <PreviewCustomComponent
          index={index}
          file={file}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          handleFileOpen={() => setShowModal(true)}
          fileSize={
            typeof file?.size === typeof ""
              ? file?.size
              : Object.values(getMBs(file.size)).join(" ")
          }
        />
      )
    : null;
  const fileToastShown = useRef({}); // since toast is render code, avoid showing if shown once
  const imageComponent = (file, index, errorsObject = [], preview = "") => {
    let errorMessage = {};
    if (errorsObject?.length)
      errorMessage = getErrorText(errorsObject[0]?.code, file);

    if (errorsObject?.length && isShowErrorToast && !fileToastShown[preview]) {
      const message = t("fileUpload.errors.uploadFailedTitle");
      const desc = t("fileUpload.errors.fileTooLargeDesc", {
        size: Object.values(getMBs(maxSize))?.join(" "),
      });
      vToast({
        title: message,
        description: desc,
        variant: "warning",
      });
      fileToastShown[preview] = true;
    }
    return (
      <div key={file.id}>
        <div className="flex items-center justify-between px-4 my-4">
          <div
            className="flex items-center w-10/12 cursor-pointer"
            onClick={() => {
              if (!errorsObject?.length) {
                dispatch(setCurrentPageNumber(index));
                setShowModal(true);
              }
            }}
          >
            {["image/jpeg", "image/png", "image/jpg", "image/gif"]?.includes(
              file?.type
            ) &&
            (file?.url || file?.preview) ? (
              <img
                src={file?.url || file?.preview}
                className="w-11 h-11"
                alt={file?.name || file?.fileName}
              />
            ) : (
              <Icon name="Receipt" className="w-8 h-8 mr-1 text-neutral-400" />
            )}
            <div className="flex flex-col w-10/12 mx-2">
              <span className="text-sm">{file?.name || file?.fileName}</span>
              {!showLoadingUI &&
              !showErrorUI &&
              (!newUploadedFiles?.current?.has(file?.uuid) || file?.id) &&
              !errorsObject?.length &&
              file?.size ? (
                <div className="flex text-neutral-400">
                  <span className="text-xs ">
                    {typeof file?.size === typeof ""
                      ? file?.size
                      : Object.values(getMBs(file.size)).join(" ")}
                  </span>
                </div>
              ) : null}
              {showLoadingUI &&
              !showErrorUI &&
              newUploadedFiles?.current?.has(file?.uuid) &&
              !errorsObject?.length ? (
                <Progress value={number} classes="w-full mt-2" />
              ) : null}
              {errorsObject?.length || showErrorUI ? (
                <Text
                  translationKey={
                    showErrorUI
                      ? "fileUpload.errors.errorInApi"
                      : errorMessage?.error
                  }
                  classes="text-danger-500 text-xs"
                  translationProps={
                    errorMessage?.errorProps ? errorMessage?.errorProps : {}
                  }
                />
              ) : null}
            </div>
          </div>
          <div className="flex gap-3">
            {!primaryActionDisabled && !errorsObject?.length ? (
              <div
                className={`bg-danger-50 p-1 rounded-xl ${primaryAction.wrapperClasses}`}
                onClick={() => primaryAction.handler(index)}
              >
                <Icon
                  name={primaryAction.icon}
                  className={`w-8 h-8 p-2 rounded-lg cursor-pointer text-danger-600 ${
                    primaryAction.iconClasses || "text-neutral-700"
                  }`}
                  bgClassName={primaryAction.bgClassName || "bg-neutral-100"}
                />
              </div>
            ) : null}
            {!secondaryActionDisabled && !errorsObject?.length ? (
              <div
                className={`bg-primary-50 p-1 rounded-xl ${secondaryAction.wrapperClasses}`}
                onClick={() => secondaryAction.handler(index)}
              >
                <Icon
                  name={secondaryAction.icon}
                  className={`w-8 h-8 p-2 rounded-lg cursor-pointer text-primary-500 ${
                    secondaryAction.iconClasses || "text-neutral-700"
                  }`}
                  bgClassName={secondaryAction.bgClassName || "bg-neutral-100"}
                />
              </div>
            ) : null}
            {errorsObject?.length ? (
              <div
                className="p-1 bg-danger-50 rounded-xl "
                onClick={() => {
                  setRejectedFile((rejectFile) =>
                    rejectFile?.filter(
                      (_file) => _file?.file?.name !== file?.name
                    )
                  );
                }}
              >
                <Icon
                  name="Close"
                  className="p-2 rounded-lg cursor-pointer w-7 h-7 text-danger-500 "
                  bgClassName="bg-danger-100"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
  const images = fileUploaded?.length
    ? fileUploaded?.map((file, index) =>
        !PreviewCustomComponent ? (
          imageComponent(file, index)
        ) : (
          <PreviewComponentWithProp index={index} key={file.id} file={file} />
        )
      )
    : null;
  const rejectedImages = rejectedFiles?.length
    ? rejectedFiles?.map((file, index) =>
        !PreviewCustomComponent ? (
          imageComponent(file?.file, index, file?.errors, file.preview)
        ) : (
          <PreviewComponentWithProp index={index} key={file.id} file={file} />
        )
      )
    : null;

  const isBoxVisible = !hideCustomButtonAfterUpload || !fileUploaded?.length;
  const PreviewHeader = previewHeader;

  return (
    <div className={`flex  flex-col ${outerClasses} `}>
      {hidePreviewUploadComponent ? null : (
        <div className={middleClasses} {...getRootProps()}>
          <input {...getInputProps()} disabled={isDisabled} name={name} />
          {variant === FILE_UPLOAD_VARAINTS.BOX && isBoxVisible && (
            <div
              className={`flex flex-col items-center justify-center py-6 border-[1.5px] border-neutral-200 font-semibold rounded-xl hover:shadow-md hover:cursor-pointer hover:bg-neutral-50 ${
                isDisabled ? "bg-neutral-100" : ""
              } ${innerClasses}`}
            >
              {hidePreviewUploadIcon ||
              (isDisabled && !showLoadingUI) ? null : (
                <Icon
                  name="Upload"
                  className={`mb-4 ${
                    isDisabled ? "text-neutral-400" : "text-primary-500"
                  }`}
                />
              )}

              {!isDragActive ? (
                isDisabled ? (
                  typeof disabledLabel === typeof (<></>) ? (
                    disabledLabel
                  ) : (
                    <Text
                      translationKey={disabledLabel}
                      classes="text-neutral-400"
                    />
                  )
                ) : (
                  <div className="cursor-pointer">
                    <Text
                      translationKey="fileUpload.browseFiles"
                      classes="text-primary-500 "
                    />
                    <Text translationKey="fileUpload.dragAndDrop" />
                  </div>
                )
              ) : (
                <Text
                  classes="text-neutral-500"
                  translationKey="fileUpload.dropHere"
                />
              )}
              <Text
                translationKey={acceptText}
                classes="mt-1 text-sm font-semibold tracking-wide text-neutral-500"
              />

              {showErrorAtCenter && error && !fileUploaded?.length ? (
                <Text
                  classes="-bottom-5 left-0 text-xs text-danger-600"
                  translationKey={error}
                />
              ) : null}
            </div>
          )}
          {variant === FILE_UPLOAD_VARAINTS.BUTTON &&
            (!isDragActive ? (
              <Button
                variant="secondary"
                label={buttonText}
                classes="text-sm"
                disabled={isDisabled}
              />
            ) : (
              <Button variant="secondary" label="fileUpload.dropHere" />
            ))}
          {variant === FILE_UPLOAD_VARAINTS.CUSTOM ? (
            <div
              className={
                hideCustomButtonAfterUpload && fileUploaded?.length
                  ? "hidden"
                  : ""
              }
            >
              {customComponent}
            </div>
          ) : (
            ""
          )}

          {variant === FILE_UPLOAD_VARAINTS.CUSTOM &&
          hideCustomButtonAfterUpload &&
          fileUploaded?.length ? (
            showPreview ? (
              <div className={`${previewClasses} cursor-pointer`}>
                {PreviewHeader ? PreviewHeader : null}
                {fileUploaded?.map((file, index) =>
                  !PreviewComponentWithProp ? (
                    <div
                      key={file?.id}
                      className={
                        profileUpload
                          ? "relative flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-neutral-200"
                          : ""
                      }
                    >
                      <img
                        src={file?.url || file?.preview}
                        className={previewChildClasses}
                        alt={file?.name || file?.fileName}
                      />

                      {profileUpload ? (
                        <div className="absolute bottom-0 right-0 p-1 bg-white border rounded-full border-neutral-100">
                          <Icon name="Edit" className="w-3 h-3" />
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <PreviewComponentWithProp
                      key={file.id}
                      index={index}
                      file={file}
                    />
                  )
                )}
              </div>
            ) : null
          ) : null}
          {!showErrorAtCenter && error && !fileUploaded?.length ? (
            <Text
              classes="bottom-[-20px] left-[0px] text-xs text-danger-600"
              translationKey={error}
            />
          ) : null}
        </div>
      )}

      {variant === FILE_UPLOAD_VARAINTS.CUSTOM &&
      !(hideCustomButtonAfterUpload && fileUploaded?.length) &&
      showPreview ? (
        <div className={previewClasses}>
          {PreviewHeader ? PreviewHeader : null}
          {fileUploaded?.map((file, index) =>
            !PreviewComponentWithProp ? (
              <div key={file.id}>
                <img
                  src={file?.url || file?.preview}
                  className={previewChildClasses}
                  alt={file?.name || file?.fileName}
                />
              </div>
            ) : (
              <PreviewComponentWithProp
                index={index}
                key={file.id}
                file={file}
              />
            )
          )}
        </div>
      ) : null}

      {showPreview && variant === FILE_UPLOAD_VARAINTS.BOX ? (
        <div className="mb-6">
          {PreviewHeader}
          <div className={previewClasses}>{images}</div>
          <div className={previewClasses}>{rejectedImages}</div>
        </div>
      ) : null}
      <FileViewer
        isModalView
        files={fileUploaded}
        modalVisible={showModal}
        handleModalBackClick={() => setShowModal(false)}
      />
      {!files?.length && showNoFileUI && !showLoadingUI ? (
        <div className="flex flex-col items-center justify-center gap-2 py-8 rounded-lg bg-neutral-100 text-neutral-500">
          <Icon name="Receipt" />
          <Text translationKey="accounting.transactions.cards.slider.noReceiptText" />
        </div>
      ) : null}

      {formError ? (
        <Text
          classes="font-medium text-xs text-danger-600"
          translationKey={formError}
        />
      ) : null}
    </div>
  );
}

FileUpload.propTypes = {
  files: PropTypes.array,
  handleFileChange: PropTypes.func,
  accept: PropTypes.object,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  acceptText: PropTypes.string,
  primaryAction: PropTypes.object,
  secondaryAction: PropTypes.object,
  variant: PropTypes.string,
  buttonText: PropTypes.string,
  error: PropTypes.string,
  showErrorAtCenter: PropTypes.bool,
  name: PropTypes.string,
  insideForm: PropTypes.bool,
  replaceAfterUpload: PropTypes.bool,
  customComponent: PropTypes.element,
  hideCustomButtonAfterUpload: PropTypes.bool,
  previewCustomComponent: PropTypes.func,
  previewClasses: PropTypes.string,
  previewChildClasses: PropTypes.string,
  outerClasses: PropTypes.string,
  innerClasses: PropTypes.string,
  middleClasses: PropTypes.string,
  showPreview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  primaryActionDisabled: PropTypes.bool,
  secondaryActionDisabled: PropTypes.bool,
  previewHeader: PropTypes.element,
  disabledLabel: PropTypes.string,
  hidePreviewUploadComponent: PropTypes.bool,
  apiKey: PropTypes.string,
  showLoader: PropTypes.bool,
  formError: PropTypes.string,
  isShowErrorToast: PropTypes.bool,
};
