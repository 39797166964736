import { createSelector } from "@reduxjs/toolkit";

import { getCardsModuleRequestFilters } from "@/components/Cards/util";
import { AVAILABLE_FILTERS } from "@/utils/constants/filters";

import { clientSelector, primaryCardProviderSelector } from "./client";

export const actionCentreStore = (state) => state.actionCentre;

export const actionCentreSelectedTabSelector = createSelector(
  actionCentreStore,
  (actionCentre) => actionCentre.selectedTab
);

export const actionCentreTabsSelector = createSelector(
  actionCentreStore,
  (actionCentre) => actionCentre.actionCentreTabs
);

export const actionCentreSelector = createSelector(
  actionCentreStore,
  (actionCentre) => actionCentre.actionCentre
);

export const actionCentreTableListSelector = createSelector(
  actionCentreSelector,
  (actionCentre) =>
    actionCentre.list?.map((item) => ({ ...item, ...item.request }))
);

export const actionCentreTableLimitSelector = createSelector(
  actionCentreSelector,
  (actionCentre) => actionCentre.limit
);

export const actionCentreTablePageSelector = createSelector(
  actionCentreSelector,
  (actionCentre) => actionCentre.page
);

export const actionCentreTableTotalSelector = createSelector(
  actionCentreSelector,
  (actionCentre) => actionCentre.total
);
export const actionCentreTableIsFetchingSelector = createSelector(
  actionCentreSelector,
  (actionCentre) => actionCentre.isFetching
);
export const actionCentreTableHasMoreSelector = createSelector(
  actionCentreSelector,
  (actionCentre) => actionCentre.hasMore
);

export const actionCentreCardsRequestTableSelector = createSelector(
  actionCentreStore,
  (actionCentre) => actionCentre.inProgress
);

export const cardsFiltersSelector = createSelector(
  actionCentreStore,
  primaryCardProviderSelector,
  (actionCentre, clientInfo) =>
    getCardsModuleRequestFilters(actionCentre.filterKey.cards, clientInfo)
);

export const actionIsInProgressSelector = createSelector(
  actionCentreStore,
  (actionCentre) => actionCentre.actionInProgress
);

export const actionInProgressIdSelector = createSelector(
  actionCentreStore,
  (actionCentre) => actionCentre.actionInProgressId
);

export const approveActionIsInProgressSelector = createSelector(
  actionIsInProgressSelector,
  (action) => action.approve
);

export const rejectActionIsInProgressSelector = createSelector(
  actionIsInProgressSelector,
  (action) => action.reject
);

export const approvalStatusHistoryDetailsSelector = createSelector(
  actionCentreStore,
  (actionCentre) => actionCentre?.approvalStatusHistoryInfo
);

export const approvalStatusHistoryDetailsDataSelector = createSelector(
  approvalStatusHistoryDetailsSelector,
  (approvalHistoryInfo) => approvalHistoryInfo?.list
);

export const isFetchingApprovalStatusHistoryInfoSelector = createSelector(
  approvalStatusHistoryDetailsSelector,
  (approvalHistoryInfo) => approvalHistoryInfo?.isFetching
);

export const hasMoreApprovalStatusHistoryInfoSelector = createSelector(
  approvalStatusHistoryDetailsSelector,
  (approvalHistoryInfo) => approvalHistoryInfo?.hasMore
);
