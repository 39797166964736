import ActionCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/ActionCell";
import AmountCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/AmountCell";
import DescriptionCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/DescriptionCell";
import DueDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/DueDateCell";
import InvoiceCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceCell";
import InvoiceDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceDateCell";
import InvoiceNumberCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/InvoiceNumberCell";
import MonthAndYearCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/MonthAndYearCell";
import OcrAccuracyCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/OCRAccuracyCell";
import PaymentDateCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/PaymentDateCell";
import VendorCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/VendorCell";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { PAYROLL_BULK_UPLOAD_SLIDER } from "@/utils/constants/payments";

export const getInvoiceBoxTableDefaultHeader = (page, sliderArgs) => {
  const {
    inPageSlider = null,
    fileRecordIdValue = null,
    bulkUploadJobStatusInfo = null,
  } = sliderArgs;
  const successRows = bulkUploadJobStatusInfo?.successRows;
  const totalRows = bulkUploadJobStatusInfo?.totalRows;

  const isPayrollContextSlider =
    page === BILL_PAYROLL_CONTEXT.PAYROLL &&
    inPageSlider === PAYROLL_BULK_UPLOAD_SLIDER;

  const BILLPAY_INBOX_TABLE_HEADERS = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      numberOfStickyColsRight: 1,
      colWidths: [300, 200, 200, 200, 200, 120, 120, 300, 200],
      rightColWidths: [200],
      styleId: "payments-table",
    },
    emptyDataTitle: "billPay.bill.invoiceInbox.emptyTexts.title",
    // empty description is custom UI here
    tableHeading: [
      {
        id: "vendor",
        title: "billPay.bill.invoiceInbox.tableHeaders.vendorHeading",
        classes: "text-left",
        cellComponent: VendorCell,
      },
      {
        id: "Amount",
        title: "billPay.bill.invoiceInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "dueDate",
        title: "billPay.bill.invoiceInbox.tableHeaders.dueDate",
        classes: "text-left",
        cellComponent: DueDateCell,
      },
      {
        id: "invoiceDate",
        title: "billPay.bill.invoiceInbox.tableHeaders.invoiceDate",
        classes: "text-left",
        cellComponent: InvoiceDateCell,
      },
      {
        id: "invoiceNumber",
        title: "billPay.bill.invoiceInbox.tableHeaders.invoiceNumber",
        classes: "text-left",
        cellComponent: InvoiceNumberCell,
      },
      {
        id: "invoice",
        title: "billPay.bill.invoiceInbox.tableHeaders.invoice",
        classes: "justify-center",
        cellComponent: InvoiceCell,
      },
      {
        id: "ocrAccuracy",
        title: "billPay.bill.invoiceInbox.tableHeaders.ocrAccuracy",
        classes: "justify-center",
        cellComponent: OcrAccuracyCell,
      },
      {
        id: "description",
        title: "billPay.bill.invoiceInbox.tableHeaders.description",
        classes: "text-left",
        cellComponent: DescriptionCell,
      },
      {
        id: "action",
        title: "billPay.bill.invoiceInbox.tableHeaders.actionHeading",
        classes: "justify-center",
        cellComponent: ActionCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  const PAYROLL_INBOX_TABLE_HEADERS = {
    tableSetting: {
      headerSticky: true,
      numberOfStickyColsLeft: 1,
      numberOfStickyColsRight: !isPayrollContextSlider ? 1 : 0,
      colWidths: [!isPayrollContextSlider ? 300 : 200, 200, 200, 200, 215, 151],
      rightColWidths: [151],
      styleId: "payments-table",
    },
    emptyDataTitle: "payroll.salaryPayment.payrollInbox.emptyTexts.title",
    emptyDataDescription:
      "payroll.salaryPayment.payrollInbox.emptyTexts.description",
    tableHeading: [
      {
        id: "employee",
        title:
          "payroll.salaryPayment.payrollInbox.tableHeaders.employeeHeading",
        classes: "text-left",
        cellComponent: VendorCell,
        showCheckbox: !isPayrollContextSlider,
      },
      {
        id: "Amount",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.amountHeading",
        classes: "text-right justify-end",
        cellComponent: AmountCell,
        haveSort: true,
        sortingCategory: SORTING_CATEGORY.AMOUNT,
      },
      {
        id: "forMonth",
        title:
          "payroll.salaryPayment.payrollInbox.tableHeaders.paymentMonthAndYear",
        classes: "justify-center",
        cellComponent: MonthAndYearCell,
      },
      {
        id: "invoiceDate",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.paymentDate",
        classes: "text-left",
        cellComponent: PaymentDateCell,
      },
      {
        id: "description",
        title: "payroll.salaryPayment.payrollInbox.tableHeaders.description",
        classes: "text-left",
        cellComponent: DescriptionCell,
      },
    ],
    tableHeadingRowClasses:
      "text-xs font-semibold text-center text-neutral-800 bg-neutral-100",
    tableDataRowClasses:
      "text-sm font-semibold text-center cursor-pointer text-neutral-800",
  };

  switch (page) {
    case BILL_PAYROLL_CONTEXT.BILLPAY:
      return BILLPAY_INBOX_TABLE_HEADERS;

    case BILL_PAYROLL_CONTEXT.PAYROLL:
      if (inPageSlider) {
        if (fileRecordIdValue) {
          if (totalRows === 0 || totalRows === "") {
            PAYROLL_INBOX_TABLE_HEADERS.emptyDataTitle =
              "payroll.salaryPayment.payrollInbox.tableHeaders.zeroRowsHeading";
          } else if (successRows === 0 || successRows === "") {
            PAYROLL_INBOX_TABLE_HEADERS.emptyDataTitle =
              "payroll.salaryPayment.payrollInbox.tableHeaders.zeroTotalRowsHeading";
          }
        } else {
          PAYROLL_INBOX_TABLE_HEADERS.emptyDataTitle =
            "payroll.salaryPayment.payrollInbox.tableHeaders.noFileUploadedHeading";
        }
      } else {
        PAYROLL_INBOX_TABLE_HEADERS.tableHeading.push({
          id: "action",
          title:
            "payroll.salaryPayment.payrollInbox.tableHeaders.actionHeading",
          classes: "text-left",
          cellComponent: ActionCell,
        });
      }
      return PAYROLL_INBOX_TABLE_HEADERS;
    default:
      break;
  }
};
