import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

import useOnClickOutside from "@/hooks/useClickOutside";

import Icon from "@/components/core/Icon";
import Popup from "@/components/core/Popup";

import ChildrenDownloadOptionPopup from "./ChildrenDownloadOptionPopup";

export default function DownloadPopupButton({ label }) {
  const [showMenu, setShowMenu] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const ref = useRef();
  useOnClickOutside(ref, (e) => {
    if (!referenceElement.contains(e.target)) setShowMenu(false);
  });

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end", // placement of usePopper
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          mainAxis: false, // true by default
        },
      },
    ],
  });
  const [data, setData] = useState({
    filters: "withFilters",
    downloadFormat: "pdf",
  });
  const handleToggle = (e) => {
    if (e.currentTarget === referenceElement) setShowMenu((prev) => !prev);
  };

  return (
    <>
      <button type="button" ref={setReferenceElement} onClick={handleToggle}>
        <div className="cursor-pointer card-wrapper">
          <Icon name="Download" />
        </div>
      </button>
      {showMenu ? (
        <Popup
          keepTooltipInside="#tooltip-inside"
          styles={styles.popper}
          attr={{
            ...attributes.popper,
          }}
          popupRef={setPopperElement}
          containerClasses="w-[20rem] px-0 py-0"
        >
          <ChildrenDownloadOptionPopup
            label={label}
            data={data}
            handleData={setData}
            refProp={ref}
            onCancel={() => setShowMenu(false)}
            onSubmit={() => {
              setShowMenu(false);
            }}
          />
        </Popup>
      ) : null}
    </>
  );
}

DownloadPopupButton.propTypes = {
  label: PropTypes.string,
};
