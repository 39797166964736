import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  bulkUploadPayrollSheet,
  getBulkPayrollStatus,
  getBulkUploadSampleSheetForPayroll,
  modifyBulkPayrollAction,
  resetBulkUploadTableInfo,
  setBulkUploadFileRecordId,
  setIsFetchingBulkUploadTableList,
  setJobStatusForPayrollBulkUpload,
} from "@/store/reducers/payments";

import {
  bulkUplodFileRecordIdSelector,
  isSubmittingBulkUploadSelector,
  payrollJobStatusForBulkUploadSelector,
} from "@/store/selectors/payments";

import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";
import XlsxFilePreviewComponent from "@/components/core/FileUpload/PreviewComponents/XlsxFilePreviewComponent";
import Note from "@/components/core/Note";
import Text from "@/components/core/Text";

import FailedRowsTable from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/BulkUploadPayroll/FailedRowsTable";
import SuccessRowsTable from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/BulkUploadPayroll/SuccessRowsTable";
import { MODIFY_BULK_PAYROLL_ACTION_TYPES } from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";
import SliderExpansionHandler from "@/GlobalSliders/SliderExpansionHandler";

export default function BulkUploadPayroll({ setOnClose }) {
  const JOB_STATUS_POLLING_LIMIT = 10;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sliderHeadingRef = useLeftHeaderTitle({
    title: "payroll.bulkUpload.sliderTitle",
  });
  const [file, setFile] = useState([]);
  const isSubmitted = useRef(false);
  const hasFile = useRef(false);
  const isSubmittingBulkUpload = useSelector(isSubmittingBulkUploadSelector);
  const fileRecordIdValue = useSelector(bulkUplodFileRecordIdSelector);
  hasFile.current = fileRecordIdValue;
  const bulkUploadJobStatusInfo = useSelector(
    payrollJobStatusForBulkUploadSelector
  );
  const bulkUploadJobStatusSuccessRows = bulkUploadJobStatusInfo?.successRows;

  const successCount = bulkUploadJobStatusInfo?.successRows || 0;
  const failedCount = bulkUploadJobStatusInfo?.failedRows?.length || 0;
  const totalCount = bulkUploadJobStatusInfo?.totalRows || 0;
  const disabledSubmitButton =
    isSubmittingBulkUpload ||
    !successCount ||
    successCount + failedCount !== totalCount;

  const disableCancelButton = !fileRecordIdValue;
  const jobStatusIntervalId = useRef(null);
  const [showFileUploadErrorMessage, setShowFileUploadErrorMessage] =
    useState(false);

  function handleDownloadButtonClick() {
    dispatch(getBulkUploadSampleSheetForPayroll());
  }

  function handleBulkUpload(e) {
    const formData = new FormData();
    formData.append("file", e?.target?.value?.[0]);

    if (e?.target?.value?.length > 0) {
      dispatch(
        bulkUploadPayrollSheet({
          payload: formData,
          onSuccess: (fileRecordId) => {
            dispatch(setBulkUploadFileRecordId(fileRecordId));
            onBulkUploadSuccess(e?.target?.value, fileRecordId);
          },
        })
      );
    }
  }

  function onBulkUploadSuccess(fileImage, fileRecordId) {
    setFile(fileImage);
    dispatch(setIsFetchingBulkUploadTableList(true));
    checkJobStatus(fileRecordId);
  }

  function checkJobStatus(fileRecordId) {
    let pollingCount = 0;
    jobStatusIntervalId.current = setInterval(() => {
      pollingCount += 1;
      if (pollingCount < JOB_STATUS_POLLING_LIMIT) {
        dispatch(
          getBulkPayrollStatus({
            payload: { file_record_id: fileRecordId },
            onSuccess: (jobStatusInfo) =>
              onCheckJobStatusSuccess(
                jobStatusIntervalId?.current,
                jobStatusInfo
              ),
          })
        );
      } else {
        setShowFileUploadErrorMessage(true);
        dispatch(resetBulkUploadTableInfo());
        clearInterval(jobStatusIntervalId.current);
      }
    }, 5000);
  }

  function onCheckJobStatusSuccess(timeIntervalRef, jobStatusInfo) {
    const failedRows = jobStatusInfo?.failedRows ?? [];
    const successRows = jobStatusInfo?.successRows;
    const totalRows = jobStatusInfo?.totalRows;

    if (totalRows === failedRows.length + successRows) {
      clearInterval(timeIntervalRef);
      dispatch(setIsFetchingBulkUploadTableList(false));
    }
  }

  function handleFooterBtnsClick(action, noClose = false) {
    dispatch(
      modifyBulkPayrollAction({
        payload: {
          do: action,
          file_record_id:
            action === MODIFY_BULK_PAYROLL_ACTION_TYPES.CANCEL
              ? hasFile.current // latest value
              : fileRecordIdValue,
        },
        // close on both success and error
        onSuccess: () => {
          if (!noClose) closePayrollBulkUploadSlider();
          isSubmitted.current =
            action !== MODIFY_BULK_PAYROLL_ACTION_TYPES.CANCEL;
        },
        onError: () => {
          if (!noClose) closePayrollBulkUploadSlider();
        },
      })
    );
  }

  function cancelHandler(event, noClose = false) {
    if (hasFile.current)
      return handleFooterBtnsClick(
        MODIFY_BULK_PAYROLL_ACTION_TYPES.CANCEL,
        noClose
      );
  }

  function navigateToExportsPage() {
    navigate(ROUTES.exports.base.absolutePath);
  }

  function closePayrollBulkUploadSlider() {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.payrollPayments.bulkUploadPayroll
    );
    setSearchParams(searchParams);
    resetSliderInfo();
  }

  function handleDeleteUploadedFile() {
    if (hasFile.current)
      handleFooterBtnsClick(MODIFY_BULK_PAYROLL_ACTION_TYPES.CANCEL, true); // don't close

    resetSliderInfo();
  }

  function resetSliderInfo() {
    dispatch(resetBulkUploadTableInfo());
    dispatch(setBulkUploadFileRecordId(null));
    dispatch(setJobStatusForPayrollBulkUpload(null));
    clearInterval(jobStatusIntervalId?.current);
    setShowFileUploadErrorMessage(false);
    setFile([]);
  }

  useEffect(() => {
    setOnClose(() => {
      if (!isSubmitted.current) cancelHandler({}, false);
      resetSliderInfo();
    });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-0 mb-6 slider-content-core">
        <div className="flex flex-row justify-between">
          <Text
            classes="text-3xl font-bold text-neutral-800 mb-8"
            translationKey="payroll.bulkUpload.sliderTitle"
            refProp={sliderHeadingRef}
          />

          <SliderExpansionHandler iconClasses="text-neutral-500 w-8 h-8" />
        </div>

        <div className="mb-8">
          <Note
            borderColorClass="border-warning-300"
            backgroundColorClass="bg-warning-50"
            actionText=""
            descriptionText=""
            titleText="payroll.bulkUpload.note"
          />
        </div>
        <div className="flex flex-col gap-1 mb-8">
          <Text
            translationKey="payroll.bulkUpload.downloadSection.mainTitle"
            classes="text-lg font-semibold text-neutral-800"
          />

          <Text
            translationKey="payroll.bulkUpload.downloadSection.mainTitleDescription"
            classes="text-sm font-medium text-neutral-500 mb-2"
          />

          <div className="flex flex-col gap-3 card-wrapper">
            <Text
              translationKey="payroll.bulkUpload.downloadSection.downloadNote.mainTitle"
              classes="text-base font-semibold text-neutral-800"
            />

            <Text
              translationKey="payroll.bulkUpload.downloadSection.downloadNote.descriptionTitle"
              classes="text-sm font-medium text-neutral-500"
            />

            <div className="flex flex-row gap-7">
              <div onClick={handleDownloadButtonClick}>
                <Text
                  translationKey="payroll.bulkUpload.downloadSection.downloadNote.redirectionBtns.exportTemplate"
                  classes="text-sm font-semibold text-primary-500 underline cursor-pointer"
                />
              </div>

              <div onClick={navigateToExportsPage}>
                <Text
                  translationKey="payroll.bulkUpload.downloadSection.downloadNote.redirectionBtns.goToDownloads"
                  classes="text-sm font-semibold text-neutral-500 decoration-text-neutral-500 underline cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <Text
            translationKey="payroll.bulkUpload.uploadSection.mainTitle"
            classes="text-lg font-semibold text-neutral-800"
          />

          <Text
            translationKey="payroll.bulkUpload.uploadSection.mainTitleDescription"
            classes="text-sm font-medium text-neutral-500 mb-2"
          />

          <FileUpload
            accept={{
              "text/csv": [".xlsx"],
            }}
            maxFiles={1}
            hideCustomButtonAfterUpload
            primaryAction={{
              handler: handleDeleteUploadedFile,
              label: null,
              icon: "Delete",
              className: "text-danger-600",
              bgClassName: "bg-danger-50",
            }}
            secondaryAction={{}}
            handleFileChange={handleBulkUpload}
            files={file}
            acceptText="payroll.bulkUpload.uploadSection.uploadActionSectionDescription"
            previewCustomComponent={({ file: fileInfo, primaryAction }) => {
              return (
                <XlsxFilePreviewComponent
                  file={fileInfo}
                  errorMessageText={
                    showFileUploadErrorMessage
                      ? "payroll.bulkUpload.uploadSection.errorMessage"
                      : null
                  }
                  primaryAction={primaryAction}
                />
              );
            }}
            insideForm
          />
        </div>

        <div className="flex flex-col mt-8">
          <Text
            translationKey="payroll.bulkUpload.preview.sectionTitle"
            classes="text-lg font-semibold text-neutral-800 mb-4"
          />

          <FailedRowsTable />

          <SuccessRowsTable />
        </div>
      </div>

      <div className="flex flex-row justify-end gap-6 p-4 slider-footer">
        <Button
          label="payroll.bulkUpload.buttons.cancel"
          size="sm"
          variant="tertiary"
          classes="py-5 text-base"
          disabled={disableCancelButton}
          onClick={cancelHandler}
        />

        <Button
          label="payroll.bulkUpload.buttons.submit"
          size="sm"
          variant="primary"
          classes="py-5 text-base"
          onClick={() =>
            handleFooterBtnsClick(MODIFY_BULK_PAYROLL_ACTION_TYPES.APPROVE)
          }
          disabled={disabledSubmitButton}
          counter={bulkUploadJobStatusSuccessRows}
        />
      </div>
    </>
  );
}

BulkUploadPayroll.propTypes = {
  setOnClose: PropTypes.func,
};
