import PropTypes from "prop-types";

import SettingRow from "@/components/common/BillPayAndPayroll/Settings/common/SettingsList/SettingRow";

/**
 * This is just maps and renders a list.
 * It's main job is to manage spacing and borders between rows.
 *
 * @param {Object} tabRows array of objects
 */
export default function SettingsList({ tabRows }) {
  return (
    <div aria-label="setting-row">
      {tabRows?.map((tabRow, index, { length }) => {
        // top spacing for first row is 40px instead of 48px
        const spacingClasses = index === 0 ? "pt-9 pb-10" : "py-10";

        // the separator line is not shown below the last rows
        const borderClasses =
          index === length - 1 ? "" : "border-b-[1px] border-neutral-300";

        return (
          <div
            key={tabRow?.id}
            // TODO: Need to add this in tailwind config
            className={`flex gap-15  ${borderClasses} ${spacingClasses}`}
          >
            <SettingRow tabRow={tabRow} />
          </div>
        );
      })}
    </div>
  );
}

SettingsList.propTypes = {
  tabRows: PropTypes.arrayOf(PropTypes.object),
};
