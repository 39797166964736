import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { getConnectedEntities, switchEntity } from "@/store/reducers/user";

import { subdomainSelector } from "@/store/selectors/client";
import {
  connectedAccountsSelector,
  isFetchingConnectedAccountsSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Loader from "@/components/core/Loader";
import Text from "@/components/core/Text";

import SwitchEntityRadioComponent from "@/components/SwitchEntity/SwitchEntityRadioComponent";
import vToast from "@/utils/vToast";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function SwitchEntitySlider() {
  const dispatch = useDispatch();
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(userSelector);
  const navigate = useNavigate();

  const currentSubdomain = useSelector(subdomainSelector);
  const connectedEntities = useSelector(connectedAccountsSelector)?.filter(
    (entity) => currentSubdomain !== entity.subdomain
  );
  const isFetching = useSelector(isFetchingConnectedAccountsSelector);
  const ref = useLeftHeaderTitle({ title: "switchEntity.title" });
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenAddAccountSlider = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.switchEntity.addAccount, true);
    setSearchParams(searchParams);
  };

  const handleCloseSlider = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.switchEntity.switch);
    setSearchParams(searchParams);
  };

  const { t } = useTranslation();

  const successfullyConnected = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.successfullyConnected
  );
  const entityConnectionMessage = searchParams.get(
    SLIDERS_SEARCH_PARAMS.switchEntity.connecting.entityConnectionMessage
  );

  useEffect(() => {
    if (successfullyConnected && entityConnectionMessage) {
      const [toastTitle, toastVariant] =
        parseInt(successfullyConnected, 10) === 1
          ? ["switchEntity.toastTitleSuccess", "success"]
          : ["switchEntity.toastTitleFailed", "danger"];

      vToast({
        title: t(toastTitle),
        description: entityConnectionMessage,
        variant: toastVariant,
      });

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(
        SLIDERS_SEARCH_PARAMS.switchEntity.connecting.successfullyConnected
      );
      newSearchParams.delete(
        SLIDERS_SEARCH_PARAMS.switchEntity.connecting.entityConnectionMessage
      );

      setSearchParams(newSearchParams);
    }
  }, [successfullyConnected, entityConnectionMessage]);

  useEffect(() => {
    dispatch(
      getConnectedEntities({
        email: currentUser?.email,
        subdomain: currentSubdomain,
      })
    );
  }, []);

  const onSwitchEntity = () => {
    setIsLoading(true);
    const toSubdomain = selectedEntity.subdomain;
    dispatch(switchEntity({ to_subdomain: toSubdomain, navigate }));
  };

  return (
    <div className="slider-content-container">
      <div className="slider-core-container px-9 flex flex-col gap-9">
        <Text
          translationKey="switchEntity.title"
          classes="text-3xl font-bold text-neutral-800"
          refProp={ref}
        />
        <div className="flex flex-col gap-8">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col gap-1">
              <Text
                translationKey="switchEntity.selectEntity"
                classes="text-lg font-bold text-neutral-800"
              />
              <Text
                translationKey="switchEntity.switchEntityDescription"
                classes="text-base font-medium text-neutral-500"
              />
            </div>
            <div>
              <Button
                label="switchEntity.addAccount"
                labelStyleClasses="text-xs font-bold"
                preIcon="Plus"
                classes="px-5 py-4 w-fit"
                variant="tertiary"
                onClick={handleOpenAddAccountSlider}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {connectedEntities?.length
              ? connectedEntities?.map((entity, index) => (
                  <SwitchEntityRadioComponent
                    entity={entity}
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                    key={`switch-entity-${index}`}
                  />
                ))
              : null}
            {isFetching ? <Loader /> : null}
          </div>
        </div>
      </div>
      <div className="slider-footer sticky px-6 py-4">
        <div className="flex justify-end items-center gap-6 font-semibold">
          <Button
            label="switchEntity.btnLabels.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold text-neutral-500"
            onClick={handleCloseSlider}
          />
          <Button
            label="switchEntity.btnLabels.switch"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            onClick={onSwitchEntity}
            showLoader={isLoading}
            disabled={!selectedEntity}
          />
        </div>
      </div>
    </div>
  );
}
