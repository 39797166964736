import PropTypes from "prop-types";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import qs from "qs";

import {
  resetSearchAndFilterCommonStoreStructure,
  resetSliderAppliedFilters,
  resetSliderSearchAndFilterCommonStoreStructure,
  setAppliedFilters,
  setAppliedFiltersObject,
  setSearchAndFilterCommonStoreStructure,
  setSliderAppliedFilters,
  setSliderAppliedFiltersObject,
  setSliderSearchAndFilterCommonStoreStructure,
} from "@/store/reducers/filters";

import {
  appliedFilterSelector,
  searchAndFilterCommonStructureSelector,
  sliderAppliedFilterSelector,
  sliderSearchAndFilterCommonStructureSelector,
} from "@/store/selectors/filters";

import FilterTag from "@/components/core/FilterTag";
import AmountFilter from "@/components/core/Filters/AmountFilter";
import ButtonFilter from "@/components/core/Filters/ButtonFilter";
import DateRangeFilter from "@/components/core/Filters/DateRangeFilter";
import ListFilter from "@/components/core/Filters/ListFilter";
import UtilizationFilter from "@/components/core/Filters/UtilizationFilter";
import SearchInput from "@/components/core/SearchInput";
import { skipSearchAndFilterInputsFromAppliedFilters } from "@/utils/constants/searchAndFilter";
import {
  AMOUNT_SEARCH_PARAMS,
  AVAILABLE_FILTERS,
  AVAILABLE_FILTER_KEYS,
  DATE_RANGE_PARAMS,
  FILTER_TRIGGER_CONTEXT,
  FILTER_TYPES,
  MAX_UTILIZATION,
  MIN_UTILIZATION,
  SEARCH_PARAMS_AVAILABLE_FILTER_KEYS,
  SLIDER_PARAMS_PREFIX,
  UTILIZATION_SEARCH_PARAMS,
} from "@/utils/constants/filters";
import { arraysHaveSameValues } from "@/utils/common";

export default function Filters({
  filters = [],
  classes,
  context = FILTER_TRIGGER_CONTEXT.PAGE,
  sliderConfig,
}) {
  const dispatch = useDispatch();

  const FILTER_CONFIG = {
    [FILTER_TRIGGER_CONTEXT.PAGE]: {
      dispatchSetAppliedFilters: setAppliedFilters,
      dispatchResetSearchAndFilterFunc:
        resetSearchAndFilterCommonStoreStructure,
    },
    [FILTER_TRIGGER_CONTEXT.SLIDER]: {
      dispatchSetAppliedFilters: setSliderAppliedFilters,
      dispatchResetSearchAndFilterFunc:
        resetSliderSearchAndFilterCommonStoreStructure,
    },
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const availableFilters = AVAILABLE_FILTERS;

  const appliedFilters = useSelector(appliedFilterSelector);

  const sliderAppliedFilter = useSelector(sliderAppliedFilterSelector);

  const searchAndFilterCommonStoreStructure = useSelector(
    searchAndFilterCommonStructureSelector
  );

  const sliderSearchAndFilterCommonStoreStruture = useSelector(
    sliderSearchAndFilterCommonStructureSelector
  );

  const dispatchResetSearchAndFilterFunc =
    FILTER_CONFIG?.[context]?.dispatchResetSearchAndFilterFunc;

  useEffect(() => {
    setDefaultParams();
  }, []);

  useEffect(() => {
    return () => {
      if (context === FILTER_TRIGGER_CONTEXT.SLIDER) {
        dispatch(resetSliderAppliedFilters());
        dispatch(resetSliderSearchAndFilterCommonStoreStructure());
      }
    };
  }, [sliderConfig?.tabName]);

  useEffect(() => {
    setFiltersAsParams();
  }, [appliedFilters, sliderAppliedFilter]);

  function handleFilter(filter) {
    const dispatchFunc = FILTER_CONFIG?.[context]?.dispatchSetAppliedFilters;

    dispatch(dispatchFunc(filter));
  }

  function setDefaultParams() {
    const searchAsObject = qs.parse(window.location.search?.split("?")?.[1]);

    const searchAndFilterSearchParamStructure = searchAsObject?.[
      AVAILABLE_FILTER_KEYS.searchAndFilter
    ]
      ? qs.parse(
          atob(searchAsObject?.[AVAILABLE_FILTER_KEYS.searchAndFilter]),
          {
            interpretNumericEntities: true,
            arrayLimit: -1,
            depth: 10,
            allowDots: true,
          }
        )
      : null;

    delete searchAsObject[AVAILABLE_FILTER_KEYS.searchAndFilter];

    setDefaultForSearchAndFilterFilters(searchAsObject);

    dispatch(resetSliderSearchAndFilterCommonStoreStructure());

    dispatch(dispatchResetSearchAndFilterFunc());

    setDefaultParamsForNoramlFilters(searchAndFilterSearchParamStructure);
  }

  function setDefaultParamsForNoramlFilters(
    searchAndFilterSearchParamStructure
  ) {
    if (searchAndFilterSearchParamStructure?.page) {
      dispatch(
        setSearchAndFilterCommonStoreStructure(
          searchAndFilterSearchParamStructure?.page
        )
      );
      return;
    }

    dispatch(
      setSliderSearchAndFilterCommonStoreStructure(
        searchAndFilterSearchParamStructure?.slider
      )
    );
  }

  function setDefaultForSearchAndFilterFilters(searchAsObject) {
    const searchAsObjectKeys = Object.keys(searchAsObject);
    const pageNormalAppliedFiltersParams = {};
    const sliderNormalAppliedFiltersParams = {};

    searchAsObjectKeys?.forEach((key) => {
      if (key?.includes(SLIDER_PARAMS_PREFIX)) {
        const _key = key?.split("__")?.[1];
        sliderNormalAppliedFiltersParams[_key] = searchAsObject[key];
      } else {
        pageNormalAppliedFiltersParams[key] = searchAsObject[key];
      }
    });

    if (context === FILTER_TRIGGER_CONTEXT.PAGE) {
      dispatch(
        setAppliedFiltersObject(
          formatParamsToAppliedFilters(pageNormalAppliedFiltersParams, {})
        )
      );
    } else {
      dispatch(
        setSliderAppliedFiltersObject(
          formatParamsToAppliedFilters(
            sliderNormalAppliedFiltersParams,
            {},
            SLIDER_PARAMS_PREFIX
          )
        )
      );
    }
  }

  function formatParamsToAppliedFilters(
    searchAsObject,
    defaultFilters,
    prefixText
  ) {
    Object.keys(searchAsObject).forEach((key) => {
      switch (key) {
        case AMOUNT_SEARCH_PARAMS.minAmount:
          defaultFilters.amount = {
            ...defaultFilters.amount,
            minAmount: {
              value: searchAsObject[key],
            },
          };
          break;
        case AMOUNT_SEARCH_PARAMS.maxAmount:
          defaultFilters.amount = {
            ...defaultFilters.amount,
            maxAmount: {
              value: searchAsObject[key],
            },
          };
          break;
        case DATE_RANGE_PARAMS.toDate:
          defaultFilters.dateRange = {
            ...defaultFilters.dateRange,
            to: searchAsObject[key],
          };
          break;
        case DATE_RANGE_PARAMS.fromDate:
          defaultFilters.dateRange = {
            ...defaultFilters.dateRange,
            from: searchAsObject[key],
          };
          break;
        case UTILIZATION_SEARCH_PARAMS.minUtilization:
          defaultFilters.utilization = {
            ...defaultFilters.utilization,
            [MIN_UTILIZATION]: {
              value: searchAsObject[key],
            },
          };
          break;
        case UTILIZATION_SEARCH_PARAMS.maxUtilization:
          defaultFilters.utilization = {
            ...defaultFilters.utilization,
            [MAX_UTILIZATION]: {
              value: searchAsObject[key],
            },
          };
          break;
        default: {
          if (Object.keys(AVAILABLE_FILTER_KEYS).includes(key)) {
            const searchParamKeyAllValue = searchParams.getAll(
              prefixText ? `${prefixText}${key}` : key
            );
            const searchParamValue =
              Array.isArray(searchParamKeyAllValue) &&
              searchParamKeyAllValue?.length > 1
                ? searchParamKeyAllValue
                : searchParamKeyAllValue[0];

            const optionLabel = availableFilters[key]?.props?.options?.find(
              (obj) =>
                typeof obj?.value === typeof ""
                  ? obj?.value === searchParamValue
                  : arraysHaveSameValues(obj?.value, searchParamValue)
            )?.label;
            const optionLabelTranslation = availableFilters[
              key
            ]?.props?.options?.find((obj) =>
              typeof obj?.value === typeof ""
                ? obj?.value === searchParamValue
                : arraysHaveSameValues(obj?.value, searchParamValue)
            )?.labelTranslationProps;
            defaultFilters[key] = {
              label: optionLabel,
              value: searchParamValue,
              ...(optionLabelTranslation
                ? { labelTranslation: optionLabelTranslation }
                : {}),
            };
          }
        }
      }
    });

    return defaultFilters;
  }

  // Setting filter params
  function setFiltersAsParams() {
    const searchParamsObject = {};
    const availableFilterKeyList = SEARCH_PARAMS_AVAILABLE_FILTER_KEYS;
    const existingSearchParamsAsObject = Object.fromEntries(
      new URLSearchParams(searchParams)
    );

    // Removing filter keys from search params, it should be coming from available filters
    Object.keys(existingSearchParamsAsObject).forEach((key) => {
      const _key = key?.includes(SLIDER_PARAMS_PREFIX)
        ? key?.split("__")?.[1]
        : key;

      if (availableFilterKeyList.includes(_key)) {
        delete existingSearchParamsAsObject[key];
      }
    });

    generateSearchParamsForNormalFilters(
      existingSearchParamsAsObject,
      searchParamsObject
    );

    generateSearchParamsForSearchAndFilter(searchParamsObject);

    setSearchParams(searchParamsObject);
  }

  function generateSearchParamsForNormalFilters(
    existingSearchParamsAsObject,
    searchParamsObject
  ) {
    // param = "" & slider__param
    const skippedSearchAndFilterFiltersFromAppliedFiltersOfPage =
      skipSearchAndFilterInputsFromAppliedFilters(
        appliedFilters,
        searchAndFilterCommonStoreStructure
      );

    const skippedSearchAndFilterFiltersFromAppliedFiltersOfSlider =
      skipSearchAndFilterInputsFromAppliedFilters(
        sliderAppliedFilter,
        sliderSearchAndFilterCommonStoreStruture
      );

    // we have manually put slider__on each key of the filterd filters which we got after skipping search and filter filters from slider applied filters
    const __skippedSearchAndFilterFiltersFromAppliedFiltersOfSlider = {};

    Object.entries(
      skippedSearchAndFilterFiltersFromAppliedFiltersOfSlider
    )?.forEach(([key, value]) => {
      __skippedSearchAndFilterFiltersFromAppliedFiltersOfSlider[
        `${SLIDER_PARAMS_PREFIX}${key}`
      ] = value;
    });

    const normalParams = {
      ...existingSearchParamsAsObject,
      ...skippedSearchAndFilterFiltersFromAppliedFiltersOfPage,
      ...__skippedSearchAndFilterFiltersFromAppliedFiltersOfSlider,
    };

    formatNormalFiltersToParamsForm(normalParams, searchParamsObject);
  }

  function generateSearchParamsForSearchAndFilter(searchParamsObject) {
    const addSearchAndFilterParams =
      Object.values(searchAndFilterCommonStoreStructure).length ||
      Object.values(sliderSearchAndFilterCommonStoreStruture).length;

    if (addSearchAndFilterParams) {
      searchParamsObject[AVAILABLE_FILTER_KEYS.searchAndFilter] = btoa(
        qs.stringify(
          {
            page: searchAndFilterCommonStoreStructure,
            slider: sliderSearchAndFilterCommonStoreStruture,
          },
          { allowDots: true }
        )
      );
    }
  }

  function formatNormalFiltersToParamsForm(
    existingUrlParamsWithAppliedFilters,
    searchParamsObject
  ) {
    Object.keys(existingUrlParamsWithAppliedFilters).forEach((filterKey) => {
      switch (filterKey) {
        case AVAILABLE_FILTER_KEYS.amount:
        case AVAILABLE_FILTER_KEYS.analyticsLimit:
        case AVAILABLE_FILTER_KEYS.cardLimit:
        case AVAILABLE_FILTER_KEYS.unsettledAmount:
        case AVAILABLE_FILTER_KEYS.settledAmount:
        case AVAILABLE_FILTER_KEYS.spends: {
          searchParamsObject[AMOUNT_SEARCH_PARAMS.minAmount] =
            existingUrlParamsWithAppliedFilters?.[filterKey].minAmount?.value;
          searchParamsObject[AMOUNT_SEARCH_PARAMS.maxAmount] =
            existingUrlParamsWithAppliedFilters?.[filterKey].maxAmount?.value;
          break;
        }
        case AVAILABLE_FILTER_KEYS.dateRange:
        case AVAILABLE_FILTER_KEYS.datePeriods: {
          searchParamsObject[DATE_RANGE_PARAMS.toDate] =
            existingUrlParamsWithAppliedFilters[filterKey].to;
          searchParamsObject[DATE_RANGE_PARAMS.fromDate] =
            existingUrlParamsWithAppliedFilters[filterKey].from;
          break;
        }
        case AVAILABLE_FILTER_KEYS.utilization: {
          searchParamsObject[UTILIZATION_SEARCH_PARAMS.minUtilization] =
            existingUrlParamsWithAppliedFilters.utilization?.[
              [UTILIZATION_SEARCH_PARAMS.minUtilization]
            ]?.value;
          searchParamsObject[UTILIZATION_SEARCH_PARAMS.maxUtilization] =
            existingUrlParamsWithAppliedFilters.utilization?.[
              UTILIZATION_SEARCH_PARAMS.maxUtilization
            ]?.value;
          break;
        }

        default: {
          searchParamsObject[filterKey] =
            existingUrlParamsWithAppliedFilters[filterKey]?.value ||
            existingUrlParamsWithAppliedFilters[filterKey];
        }
      }
    });
  }

  const getFilterComponent = (data, index) => {
    let component;
    switch (data.type) {
      case FILTER_TYPES.amount:
        component = (
          <AmountFilter
            filterKey={data.filterKey}
            key={`filter-value-${data.type}-${index}`}
            {...data.props}
            callback={handleFilter}
            defaultValues={appliedFilters}
            // Add default currency as fallback
          />
        );
        break;
      case FILTER_TYPES.list:
        component = (
          <ListFilter
            key={`filter-value-${data.type}-${index}`}
            {...data.props}
            callback={handleFilter}
            defaultValues={appliedFilters}
          />
        );
        break;
      case FILTER_TYPES.dateRange:
        component = (
          <DateRangeFilter
            filterKey={data.filterKey}
            key={`filter-value-${data.type}-${index}`}
            type={data.type}
            {...data.props}
            callback={(e) => {
              handleFilter(e);
            }}
            defaultValues={appliedFilters}
          />
        );
        break;
      case FILTER_TYPES.datePeriods:
        component = (
          <DateRangeFilter
            filterKey={data.filterKey}
            key={`filter-value-${data.type}-${index}`}
            type={data.type}
            {...data.props}
            callback={(e) => {
              handleFilter(e);
            }}
            dateInputProps={{ disableDateRangeForOneValueSelected: true }}
            defaultValues={appliedFilters}
          />
        );
        break;
      case FILTER_TYPES.searchAndFilter:
        component = (
          <SearchInput
            {...data.props}
            handleFilter={handleFilter}
            context={context}
            sliderConfig={sliderConfig}
          />
        );
        break;
      case FILTER_TYPES.utilization:
        component = (
          <UtilizationFilter
            filterKey={data.filterKey}
            key={`filter-value-${data.type}-${index}`}
            {...data.props}
            callback={handleFilter}
            defaultValues={appliedFilters}
            disabled={false}
          />
        );
        break;
      default:
        component = (
          <ButtonFilter
            key={`filter-value-${data.type}-${index}`}
            {...data.props}
            callback={handleFilter}
          />
        );
    }

    return component;
  };

  return (
    <div>
      <div className={`flex gap-4 items-center ${classes} my-5 flex-wrap`}>
        {filters?.map((filter, index) => (
          <Fragment key={index}>{getFilterComponent(filter, index)}</Fragment>
        ))}
      </div>

      {Object.keys(appliedFilters)?.length ||
      Object.keys(sliderAppliedFilter)?.length ? (
        <FilterTag context={context} />
      ) : null}
    </div>
  );
}

Filters.propTypes = {
  classes: PropTypes.string,
  filters: PropTypes.array,
  context: PropTypes.string,
};
