import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  createAccountingField,
  deleteAccountingField,
  editAccountingField,
  fetchTags,
} from "@/store/reducers/tags";

import { enablePayrollSelector } from "@/store/selectors/payments";
import {
  customTagsSelector,
  isTagsFetchedSelector,
} from "@/store/selectors/tags";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import vToast from "@/utils/vToast";
import { getSuccessToastMessage } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { TAG_MODULE_TYPES } from "@/constants/tags";

import CreateEditFieldForm from "./CreateEditFieldForm";
import CustomFieldCard from "./CustomFieldCard";
import EmptyCustomFields from "./EmptyCustomFields";

export default function CustomFields() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const customFields = useSelector(customTagsSelector);
  const payrollEnabled = useSelector(enablePayrollSelector);

  const [showCreateField, setShowCreateField] = useState(false);
  const initialFormValue = {
    value: "",
    field_type: "list",
    modules: Object.values(TAG_MODULE_TYPES).filter(
      (module) => !(payrollEnabled && module === TAG_MODULE_TYPES.PAYROLL)
    ),
    values_attributes: [],
  };
  const [formData, setFormData] = useState(initialFormValue);
  const [isLoading, setIsLoading] = useState(false);

  const saveCustomField = () => {
    setShowCreateField(true);
  };

  const openEditCustomField = (customFieldId) => {
    setShowCreateField(true);
    const selectedCustomField = customFields.find(
      (field) => field.id === customFieldId
    );

    const { id, name, tagType, fieldType, options, modules } =
      selectedCustomField;

    setFormData({
      id,
      value: name,
      tag_type: tagType,
      field_type: fieldType,
      modules,
      values_attributes: options?.map((option) => ({
        id: option?.id,
        tempId: option?.id,
        value: option?.name,
      })),
    });
  };

  const handleSubmit = async () => {
    let apiCall = null;
    setIsLoading(true);
    const payloadFormData = {
      ...formData,
      values_attributes: formData.values_attributes.map((option) => ({
        ...(option?.id ? { id: option?.id } : {}),
        ...(option?._destroy ? { _destroy: true } : {}),
        value: option.value,
      })),
    };
    if (payloadFormData?.id) apiCall = editAccountingField;
    else apiCall = createAccountingField;
    const action = await dispatch(apiCall(payloadFormData));
    setIsLoading(false);

    if (apiCall.fulfilled.match(action)) {
      dispatch(fetchTags());
      setShowCreateField(false);
      setFormData(initialFormValue);
    }
  };

  const handleDelete = async (id) => {
    const action = await dispatch(deleteAccountingField(id));
    if (deleteAccountingField.fulfilled.match(action)) dispatch(fetchTags());
  };

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-7">
        <div className="flex flex-col gap-1 mb-4">
          <Text
            classes="text-2xl font-semibold"
            translationKey="accounting.settings.accountingRule.customRuleSlider.title"
          />
          <Text
            classes="text-sm text-neutral-500"
            translationKey="accounting.settings.accountingRule.customRuleSlider.desc"
          />
        </div>

        {showCreateField ? (
          <CreateEditFieldForm
            form={formData}
            setFormData={setFormData}
            payrollEnabled={payrollEnabled}
          />
        ) : customFields.length ? (
          customFields.map((customField) => {
            return (
              <div
                className="flex items-center w-full gap-4"
                key={customField.id}
              >
                <CustomFieldCard
                  id={customField.id}
                  name={customField.name}
                  fieldType={customField.fieldType}
                  options={customField.options}
                  modules={customField.modules}
                  editCustomField={openEditCustomField}
                />
                <Icon
                  name="Delete"
                  className="text-neutral-500"
                  handleClick={() => handleDelete(customField.id)}
                />
              </div>
            );
          })
        ) : (
          <EmptyCustomFields createField={saveCustomField} />
        )}
      </div>

      <div className="flex items-center justify-end gap-4 px-3 pt-4 pb-2 slider-footer">
        {!showCreateField ? (
          <Button
            label="accounting.settings.accountingRule.createCustomRule"
            variant="primary w-[230px] h-10"
            classes="text-base"
            preIcon="Memo"
            onClick={saveCustomField}
          />
        ) : (
          <>
            {/* <Button
              label="Bulk upload fields"
              variant="tertiary w-[150px] h-10"
              classes="text-base"
              onClick={() => {
                searchParams.append(
                  SLIDERS_SEARCH_PARAMS.accounting.settings.bulkUpload,
                  true
                );
                setSearchParams(searchParams);
              }}
              disabled={formData?.field_type === "text"}
            /> */}
            <Button
              label="common.save"
              variant="primary w-[150px] h-10"
              classes="text-base"
              showLoader={isLoading}
              onClick={handleSubmit}
            />
          </>
        )}
      </div>
    </div>
  );
}
