import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

function MyVolopayPage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.myVolopay.base.absolutePath);
  }, []);
  return <div>index</div>;
}

export default MyVolopayPage;
