import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

// Create root level element for react-tooltips
const domNode = document.createElement("div");
domNode.ariaLabel = "tooltip-and-popup-container";
document.body.appendChild(domNode);

// Wrapper component to portal react-tooltips
export function BodyPortal({ children }) {
  return ReactDOM.createPortal(children, domNode);
}
/**
 * Make sure `id` does not have any `.`, `#` in it
 */
export default function Tooltip({
  id,
  backgroundColor = null,
  borderColor = null,
  direction = "right",
  children,
  maxWidth = "100%",
  customStyle = {},
  isOpen,
  offset,
  ...rest
}) {
  return (
    <BodyPortal>
      <ReactTooltip
        anchorSelect={`#${id}`}
        place={direction}
        arrowColor="transparent"
        border={`1px solid ${borderColor || "#E5E6EA"}`}
        opacity={1}
        style={{
          maxWidth,
          backgroundColor: backgroundColor || "#FAFAFA",
          color: "#1F2937",
          zIndex: 800,
          fontSize: "14px",
          fontWeight: "500",
          boxShadow: "box-shadow: 0px 4px 6px 2px rgba(16, 24, 40, 0.08)",
          borderRadius: "4px",
          ...customStyle,
        }}
        noArrow
        isOpen={isOpen}
        effect="solid"
        className="shadow-sm vp-tooltip no-arrow"
        offset={offset}
        {...rest}
      >
        {children}
      </ReactTooltip>
    </BodyPortal>
  );
}

Tooltip.propTypes = {
  id: PropTypes.any,
  direction: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  customStyle: PropTypes.object,
  offset: PropTypes.number,
};
