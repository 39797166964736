import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import { amountToCurrency } from "@/utils/common";

export default function DeltaChip({ delta = 0 }) {
  const isPercentage = typeof delta !== typeof {};
  const deltaNumber = isPercentage ? delta : delta?.value;
  const isNegative = deltaNumber < 0;

  return (
    <div
      className={`flex gap-1 items-center rounded-[theme(spacing.10)] px-2 py-[2px] text-xs font-semibold ${
        isNegative
          ? "text-danger-500 bg-danger-50"
          : "text-success-500 bg-success-50"
      }`}
    >
      <Icon
        // className="w-3 h-3"
        name={isNegative ? "ArrowDownward" : "ArrowUpward"}
      />
      <span>
        {isPercentage ? delta : amountToCurrency(delta)}
        {isPercentage ? "%" : ""}
      </span>
    </div>
  );
}

DeltaChip.propTypes = {
  delta: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      currency: PropTypes.string,
    }),
  ]), // 20.6 % | { value: 200, currency: "SGD"}
};
