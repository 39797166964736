export const MONTH_NAME = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MONTH_FULL_NAME = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTH_DATA = [
  {
    full: "monthNames.fullForm.january",
    short: "monthNames.shortForm.january",
    value: 1,
  },
  {
    full: "monthNames.fullForm.february",
    short: "monthNames.shortForm.february",
    value: 2,
  },
  {
    full: "monthNames.fullForm.march",
    short: "monthNames.shortForm.march",
    value: 3,
  },
  {
    full: "monthNames.fullForm.april",
    short: "monthNames.shortForm.april",
    value: 4,
  },
  {
    full: "monthNames.fullForm.may",
    short: "monthNames.shortForm.may",
    value: 5,
  },
  {
    full: "monthNames.fullForm.june",
    short: "monthNames.shortForm.june",
    value: 6,
  },
  {
    full: "monthNames.fullForm.july",
    short: "monthNames.shortForm.july",
    value: 7,
  },
  {
    full: "monthNames.fullForm.august",
    short: "monthNames.shortForm.august",
    value: 8,
  },
  {
    full: "monthNames.fullForm.september",
    short: "monthNames.shortForm.september",
    value: 9,
  },
  {
    full: "monthNames.fullForm.october",
    short: "monthNames.shortForm.october",
    value: 10,
  },
  {
    full: "monthNames.fullForm.november",
    short: "monthNames.shortForm.november",
    value: 11,
  },
  {
    full: "monthNames.fullForm.december",
    short: "monthNames.shortForm.december",
    value: 12,
  },
];
