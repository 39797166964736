import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";

import { addRejectedClaimPayload } from "@/store/reducers/reimbursement";

import {
  rejectReportReasonSelector,
  rejectedClaimPayloadSelector,
} from "@/store/selectors/reimbursement";

import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import ProfileWidget from "@/components/core/ProfileWidget";
import StepPaginationButtons from "@/components/core/StepPaginationButtons";
import Text from "@/components/core/Text";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";
import { CREATE_REIMBURSEMENT_TYPE } from "@/utils/constants/reimbursement";
import { amountToCurrency } from "@/utils/common";

import { APPROVE, REJECT } from "@/constants/myVolopay";

function ReportClaimListComponent({
  dataVariables,
  stepPaginationVariables,
  infiniteScrollVariables,
  ctasActions,
  deleteActions,
  selectedData,
  setSelectedData,
  showCheckBox = false,
  isLoadingCount = 10,
  onClickOpenSlider = () => {},
}) {
  const missingDetailsTooltip = {
    icon: "ShieldCross",
    tooltipText: "Missing details",
    iconColor: "danger",
  };

  const {
    data = [],
    isFetching,
    hasMore,
    isEmpty,
    total,
    page,
    paginationLimit,
  } = dataVariables ?? {};

  const {
    currentPage,
    setCurrentPage,
    showStepPagination = false,
    setPageNum,
  } = stepPaginationVariables ?? {};

  const { onScroll, headerSelectAll, setHeaderSelectAll } =
    infiniteScrollVariables ?? {};
  const dispatch = useDispatch();
  const {
    showCtas = false,
    approveAllCta,
    approveSingleClaim,
    rejectAllCta,
    rejectSingleClaim,
    rejectAll,
    approveAll,
  } = ctasActions ?? {};

  const { showDelete = false, deleteHandler } = deleteActions ?? {};

  const rows =
    data?.slice(
      paginationLimit * (currentPage - 1),
      paginationLimit * currentPage
    ) ?? [];

  const rejectReportReason = useSelector(rejectReportReasonSelector);
  const rejectedClaimPayload = useSelector(rejectedClaimPayloadSelector);
  const [searchParam, setSearchParam] = useSearchParams();

  const prevDisabled = currentPage === 1;
  const nextDisabled = !hasMore && currentPage === page;

  const headerSelected = headerSelectAll;
  const headerSelectionHandler = (checked) => {
    if (checked) {
      setHeaderSelectAll(true);
      setSelectedData(data?.map((dataItem) => dataItem.id));
    } else {
      setHeaderSelectAll(false);
      setSelectedData([]);
    }
  };

  const rowSelectionHandler = (selectedRow) => {
    if (selectedData?.includes(selectedRow?.id))
      setSelectedData((prev) => prev.filter((id) => id !== selectedRow?.id));
    else setSelectedData((prev) => [...prev, selectedRow?.id]);
  };

  // If the table in slider is scrollable, then we need to pass the threshold as 0.25
  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.25,
  });

  const prevPageHandler = () => {
    setCurrentPage((p) => (p > 1 ? p - 1 : p));
  };

  const nextPageHandler = () => {
    if (currentPage === page) {
      if (hasMore) {
        setCurrentPage((p) => p + 1);
        setPageNum((p) => p + 1);
      }
    } else if (currentPage < page) {
      setCurrentPage((p) => p + 1);
    }
  };

  const rejectionCommentHandler = (e, val) => {
    dispatch(
      addRejectedClaimPayload({
        id: val?.id,
        comment: e.target.value,
      })
    );
  };

  return (
    <>
      <div className="sticky w-full mb-4 border rounded-lg border-neutral-200">
        <div className="top-0 z-10 flex items-center gap-3 px-4 py-2 rounded-lg bg-neutral-50">
          {showCheckBox ? (
            <div onClick={(event) => event.stopPropagation()} className="-mb-2">
              <Checkbox
                disabled={isEmpty}
                checked={(headerSelected && !isEmpty) ?? false}
                onClickHandler={headerSelectionHandler ?? (() => {})}
              />
            </div>
          ) : null}
          <Text
            translationKey="accounting.transactions.reimbursement.claimDetails"
            classes="text-sm text-neutral-800 font-semibold"
          />
        </div>

        {(!isFetching || !isEmpty) &&
          [...(showStepPagination ? rows : data)]?.map((val, index, arr) => {
            const isSame =
              val?.unsettledAmount?.currency === val?.amount?.currency ||
              val?.claimAmount?.currency === val?.amount?.currency ||
              val?.fromAmount?.currency === val?.amount?.currency;

            const missingDetails =
              val?.missingDetails?.missingCategoryId ||
              val?.missingDetails?.missingReimbursementTags ||
              val?.missingDetails?.missingReceipt ||
              val?.missingDetails?.missingMemo ||
              val?.missingDetails?.missingTransactionDate ||
              val?.missingDetails?.missingTravelDate ||
              val?.missingDetails?.missingBasicDetails;

            const categoryLimitExceeded =
              val?.missingDetails?.categoryLimitDetails?.exceeded;

            const merchantNameNotFound = !val?.merchant?.name;
            const emptyAmount = !val?.amount?.value;

            return (
              <div
                key={val?.id}
                ref={(ref) => {
                  if (index === arr.length - 1 && hasMore) {
                    handleRefChange(ref);
                  }
                }}
                className="flex flex-col gap-2 border-b border-b-neutral-200"
              >
                <div className="flex items-center ">
                  <div className="flex items-center w-full gap-3 px-4 py-4">
                    {showCheckBox ? (
                      <Checkbox
                        checked={selectedData?.includes(val?.id) ?? false}
                        onClickHandler={() => {
                          rowSelectionHandler(val);
                        }}
                      />
                    ) : null}
                    <div
                      className="flex items-center justify-start w-full gap-3 "
                      onClick={() => {
                        onClickOpenSlider(val);
                      }}
                    >
                      <ProfileWidget
                        iconComponent={
                          val?.type ===
                          CREATE_REIMBURSEMENT_TYPE?.mileage?.value ? (
                            <Icon
                              name="Location"
                              className="flex items-center justify-center p-2 text-sm border-2 rounded-full w-9 h-9 bg-success-50 text-success-500 border-success-100"
                            />
                          ) : merchantNameNotFound ? (
                            <div className="rounded-full w-9 h-9 bg-danger-200" />
                          ) : null
                        }
                        img={
                          val?.type !==
                          CREATE_REIMBURSEMENT_TYPE?.mileage?.value
                            ? val?.merchant?.avatarUrl
                            : null
                        }
                        textClasses={
                          merchantNameNotFound &&
                          val?.type ===
                            CREATE_REIMBURSEMENT_TYPE?.outOfPocket?.value
                            ? "text-neutral-400"
                            : "font-semibold"
                        }
                        name={
                          val?.type ===
                          CREATE_REIMBURSEMENT_TYPE?.mileage?.value
                            ? "reimbursement.createReimbursement.kms"
                            : merchantNameNotFound
                              ? "reimbursement.createReimbursement.merchantNotFound"
                              : val?.merchant?.name
                        }
                        nameTranslationProps={{
                          kms: val?.distanceTravelled || 0,
                        }}
                        showTooltip
                        id={val.id}
                      >
                        <div className="flex items-center gap-1">
                          {val?.amount?.currency ? (
                            <Text
                              translationKey={amountToCurrency(val?.amount)}
                              classes="text-xs text-neutral-500 "
                            />
                          ) : (
                            "-"
                          )}

                          {!isSame ? (
                            <>
                              <Text translationKey=" • " noTranslate />
                              <div className="flex items-center justify-end gap-1 text-xs text-neutral-500">
                                <Icon name="World" />
                                <p className="font-normal ">
                                  {amountToCurrency(val?.claimAmount)}
                                </p>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </ProfileWidget>
                      {missingDetails ? (
                        <TextWithTooltipIcon
                          icon={missingDetailsTooltip?.icon}
                          tooltipText={missingDetailsTooltip?.tooltipText}
                          text=""
                          direction="left"
                          descriptionTooltipChildren={
                            <div className="flex flex-col text-danger-600">
                              {val?.missingDetails?.missingBasicDetails ? (
                                <Text translationKey="myVolopay.reimbursements.reports.missingDetails.missingBasicDetails" />
                              ) : null}

                              {val?.missingDetails?.missingCategoryId ? (
                                <Text translationKey="myVolopay.reimbursements.reports.missingDetails.missingCategory" />
                              ) : null}

                              {val?.missingDetails?.missingTransactionDate ? (
                                <Text translationKey="myVolopay.reimbursements.reports.missingDetails.missingTransactionDate" />
                              ) : null}

                              {val?.missingDetails?.missingTravelDate ? (
                                <Text translationKey="myVolopay.reimbursements.reports.missingDetails.missingTransactionDate" />
                              ) : null}

                              {val?.missingDetails?.missingReceipt ? (
                                <Text translationKey="myVolopay.reimbursements.reports.missingDetails.missingReceipt" />
                              ) : null}

                              {val?.missingDetails?.missingMemo ? (
                                <Text translationKey="myVolopay.reimbursements.reports.missingDetails.missingMemo" />
                              ) : null}

                              {val?.missingDetails?.missingReimbursementTags ? (
                                <Text translationKey="myVolopay.reimbursements.reports.missingDetails.missingAccounting" />
                              ) : null}
                            </div>
                          }
                          tooltipClasses="text-neutral-500"
                          iconClasses={`w-8 h-8 p-2 text-${missingDetailsTooltip?.iconColor}-400 bg-${missingDetailsTooltip?.iconColor}-100 rounded-md cursor-pointer`}
                        />
                      ) : null}

                      {categoryLimitExceeded ? (
                        <TextWithTooltipIcon
                          icon={
                            val?.missingDetails?.categoryLimitDetails?.flexible
                              ? "FlexibleLimit"
                              : "NonFlexibleLimit"
                          }
                          tooltipTextProps={{
                            amount:
                              val?.missingDetails?.categoryLimitDetails?.limit,
                          }}
                          tooltipText={
                            val?.missingDetails?.categoryLimitDetails?.flexible
                              ? "reimbursement.createReimbursement.reportFlexibleLimitExceeded"
                              : "reimbursement.createReimbursement.reportNonFlexibleLimitExceeded"
                          }
                          text=""
                          direction="left"
                          tooltipClasses="text-neutral-500 !w-66 "
                          iconClasses={` w-8 h-8 ${
                            val?.missingDetails?.categoryLimitDetails?.flexible
                              ? "bg-warning-50 text-warning-500 border-2 border-warning-100"
                              : "bg-danger-50 text-danger-500 border-2 border-danger-100"
                          }  rounded-md cursor-pointer`}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="flex justify-center w-16 p-5">
                    {val?.hasReceipt ? (
                      <Icon
                        name="ReceiptAutoVerified"
                        className="text-success-500 "
                      />
                    ) : (
                      <Icon
                        name="ReceiptMissing"
                        className=" text-danger-500"
                      />
                    )}
                  </div>

                  {showCtas ? (
                    <div className="flex items-center gap-4 px-4">
                      <Button
                        onClick={() => approveSingleClaim(val)}
                        preIcon={
                          val?.status === APPROVE
                            ? "SolidCheckCircle"
                            : "CircleCheck"
                        }
                        variant="tertiary"
                        classes={`w-fit h-fit px-2 border text-neutral-500 ${
                          val?.status === APPROVE
                            ? "border-success-500 bg-success-200"
                            : ""
                        }`}
                        iconClasses={`w-6 h-6 p-0.5 ${
                          val?.status === APPROVE ? "text-success-500 " : ""
                        }`}
                      />
                      <Button
                        onClick={() => rejectSingleClaim(val)}
                        preIcon={
                          val?.status === REJECT ? "SolidCancel" : "Cancel"
                        }
                        // preIcon="Cancel"
                        variant="tertiary"
                        classes={`w-fit h-fit border text-neutral-500 ${
                          val?.status === REJECT
                            ? "border-danger-500 bg-danger-200"
                            : ""
                        }`}
                        iconClasses={` mx-2 w-6 h-6 ${
                          val?.status === REJECT
                            ? "text-danger-500 "
                            : "p-[2px]"
                        }`}
                      />
                    </div>
                  ) : null}
                  {showDelete ? (
                    <Button
                      label=""
                      variant="tertiary"
                      preIcon="Delete"
                      onClick={() => deleteHandler(val)}
                      iconClasses="text-neutral-400"
                      classes="w-fit px-4 mr-1 h-fit border-none"
                    />
                  ) : null}
                </div>

                {(!rejectReportReason && val?.status === REJECT) ||
                val?.comment ? (
                  <div className="px-4 mb-4 ">
                    <Input
                      label="myVolopay.reimbursements.reports.history.slider.reasonForRejecting"
                      labelExtraClasses="text-neutral-500 text-xs"
                      disabled={val?.comment}
                      value={
                        val?.comment ??
                        rejectedClaimPayload?.find(
                          (item) => item?.id === val?.id
                        )?.comment
                      }
                      onChange={(e) => {
                        rejectionCommentHandler(e, val);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        {showCtas && data.length > 1 ? (
          <div className="flex justify-end gap-3 p-4 rounded-lg bg-neutral-50">
            <Button
              label="myVolopay.reimbursements.reports.history.slider.rejectAllCta"
              type="danger"
              variant="tertiary"
              classes="w-fit h-fit px-5 text-sm"
              onClick={rejectAllCta}
              disabled={rejectReportReason}
            />
            <Button
              label="myVolopay.reimbursements.reports.history.slider.approveAllCta"
              type="success"
              classes="w-fit h-fit px-5 text-sm"
              onClick={approveAllCta}
              disabled={
                approveAll &&
                data?.filter((item) => item.status === REJECT)?.length === 0
              }
            />
          </div>
        ) : null}

        {isFetching && (
          <div className="flex flex-col gap-3 m-2">
            <LoaderSkeleton
              count={isLoadingCount}
              classes="py-4 my-3"
              fullWidth
            />
          </div>
        )}
      </div>

      {showStepPagination ? (
        <StepPaginationButtons
          currentPage={currentPage}
          limit={paginationLimit}
          total={total}
          handleLeftButton={prevPageHandler}
          handleRightButton={nextPageHandler}
          leftDisabled={prevDisabled}
          rightDisabled={nextDisabled}
        />
      ) : null}
    </>
  );
}

export default ReportClaimListComponent;
ReportClaimListComponent.propTypes = {
  dataVariables: PropTypes.object,
  stepPaginationVariables: PropTypes.object,
  infiniteScrollVariables: PropTypes.object,
  ctasActions: PropTypes.object,
  deleteActions: PropTypes.object,
  selectedData: PropTypes.array,
  setSelectedData: PropTypes.func,
  showCheckBox: PropTypes.bool,
  isLoadingCount: PropTypes.number,
  onClickOpenSlider: PropTypes.func,
};
