import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Alert from "@/components/core/Alert";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { VENDOR_STATUS } from "@/constants/vendors";

export default function VendorAlerts({
  vendor,
  inPayrollContext,
  classes,
  saveCompleteForm,
  ...rest
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  function getAlertConfig() {
    const VENDOR_ALERT_KEY = {
      MISSING: "MISSING",
      UNVERIFIED: "UNVERIFIED",
      FIRST_TIME: "FIRST_TIME",
    };
    const VENDOR_ALERT_CONFIGS = {
      [VENDOR_ALERT_KEY.MISSING]: {
        title: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.missing.title"
          : "billPay.bill.invoiceInbox.createBill.alert.missing.title",
        description: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.missing.description"
          : "billPay.bill.invoiceInbox.createBill.alert.missing.description",

        callback: () => {
          searchParams.append(
            inPayrollContext
              ? SLIDERS_SEARCH_PARAMS.employees.id
              : SLIDERS_SEARCH_PARAMS.vendors.id,
            vendor?.id
          );
          setSearchParams(searchParams);
        },
        label: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.missing.label"
          : "billPay.bill.invoiceInbox.createBill.alert.missing.label",
      },
      [VENDOR_ALERT_KEY.UNVERIFIED]: {
        title: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.unverified.title"
          : "billPay.bill.invoiceInbox.createBill.alert.unverified.title",
        description: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.unverified.description"
          : "billPay.bill.invoiceInbox.createBill.alert.unverified.description",

        callback: () => {
          searchParams.delete("focus");
          searchParams.append(
            inPayrollContext
              ? SLIDERS_SEARCH_PARAMS.employees.id
              : SLIDERS_SEARCH_PARAMS.vendors.id,
            vendor?.id
          );
          searchParams.append("focus", "bankDetailsSection");
          setSearchParams(searchParams);
        },
        label: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.unverified.label"
          : "billPay.bill.invoiceInbox.createBill.alert.unverified.label",
      },
      [VENDOR_ALERT_KEY.FIRST_TIME]: {
        title: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.firstTime.title"
          : "billPay.bill.invoiceInbox.createBill.alert.firstTime.title",
        description: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.firstTime.description"
          : "billPay.bill.invoiceInbox.createBill.alert.firstTime.description",

        callback: () => {
          searchParams.delete("focus");

          if (saveCompleteForm) saveCompleteForm();
          searchParams.append(
            inPayrollContext
              ? SLIDERS_SEARCH_PARAMS.employees.id
              : SLIDERS_SEARCH_PARAMS.vendors.id,
            vendor?.id
          );
          searchParams.append("focus", "bankDetailsSection");
          setSearchParams(searchParams);
        },
        label: inPayrollContext
          ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.firstTime.label"
          : "billPay.bill.invoiceInbox.createBill.alert.firstTime.label",
      },
    };

    let alertKey = "";
    if (vendor) {
      if (vendor?.status === VENDOR_STATUS.MISSING_VENDOR_DETAILS) {
        alertKey = VENDOR_ALERT_KEY.MISSING;
      } else if (vendor?.status === VENDOR_STATUS.UNVERIFIED) {
        alertKey = VENDOR_ALERT_KEY.UNVERIFIED;
      } else if (vendor?.totalPaymentsCount === 0) {
        alertKey = VENDOR_ALERT_KEY.FIRST_TIME;
      }
      // else (all good)
    }

    return VENDOR_ALERT_CONFIGS[alertKey] ?? null;
  }
  const vendorBankConfig = getAlertConfig(vendor);

  if (!vendorBankConfig) return null;

  return (
    <Alert
      classes={classes}
      title={vendorBankConfig.title}
      description={vendorBankConfig.description}
      variant="warning"
      icon="Info"
      wrapperDivClass="items-start"
      primaryAction={{
        callback: vendorBankConfig.callback,
        label: vendorBankConfig.label,
      }}
      iconClasses="mt-1"
      {...rest}
    />
  );
}

VendorAlerts.propTypes = {
  vendor: PropTypes.object,
  inPayrollContext: PropTypes.bool,
  classes: PropTypes.string,
  saveCompleteForm: PropTypes.func,
};
