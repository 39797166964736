import { useState } from "react";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import { ROUTES } from "@/constants/routes";

const SELECTED_TABS = [
  {
    name: "cards.settings.general.title",
    count: null,
    path: ROUTES.cards.settings.general.absolutePath,
    key: 1,
  },
  {
    name: "cards.settings.spendControl.title",
    count: null,
    path: ROUTES.cards.settings.spendControl.absolutePath,
    key: 2,
  },
];

export default function CardSettings() {
  const [selectedTab, setSelectedTab] = useState(SELECTED_TABS[1]);

  return (
    <div className="px-11">
      <Text translationKey="cards.cards" />
      <div className="flex justify-between">
        <Text
          classes="payment-page-heading font-extrabold"
          translationKey="cards.settings.title"
        />
      </div>
      <Tabs
        items={SELECTED_TABS}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => setSelectedTab(tab)}
      />
    </div>
  );
}
