import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// selectors
import { clientSelector } from "@/store/selectors/client";

// core components
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP,
} from "@/constants/accounting";

import DisconnectAccountingSoftwareModal from "../../common/DisconnectAccountingSoftwareModal";

export default function IntegrationsSettingsSliderComponent({
  accountingSoftware,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const isUCSV = accountingSoftware === ACCOUNTING_SOFTWARES.UNIVERSAL_CSV;

  const handleDefaultAcccountsSlider = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.defaultAccounts,
      true
    );
    setSearchParams(searchParams);
  };

  const handleAccountTagsSlider = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.manageAccountingTags,
      accountingSoftware
    );
    setSearchParams(searchParams);
  };

  const handleDisconnect = () => {
    setShowDisconnectModal(true);
  };

  const handleAccountAutomationSlider = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.accountAutomation,
      true
    );
    setSearchParams(searchParams);
  };

  return (
    <div className="relative flex flex-col flex-1">
      <div className="flex flex-col flex-1 gap-8 px-9">
        <SliderHeader
          heading="accounting.integrations.integrationSettings.title"
          headingTranslationProps={{
            accountingSoftware:
              ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingSoftware],
          }}
          headingClasses="text-3xl font-bold text-neutral-800"
          refFlag
        />
        <div className="flex flex-col justify-between flex-1 pb-4 py-auto">
          <div className="flex flex-col gap-6 ">
            {!isUCSV ? (
              <div className="flex items-center justify-between gap-4 p-4 border rounded-lg border-neutral-200">
                <SliderHeader
                  heading="accounting.integrations.integrationSettings.defaultAccounts.title"
                  subText="accounting.integrations.integrationSettings.defaultAccounts.description"
                  headingClasses="text-lg font-semibold text-neutral-800"
                  subTextClasses="text-sm font-medium text-neutral-500"
                />

                <Icon
                  name="ArrowForward"
                  className=" text-neutral-500 hover:cursor-pointer"
                  handleClick={handleDefaultAcccountsSlider}
                />
              </div>
            ) : null}

            <div className="flex items-center justify-between gap-4 p-4 border rounded-lg border-neutral-200">
              <SliderHeader
                heading="accounting.integrations.integrationSettings.manageAccountingTags.title"
                subText="accounting.integrations.integrationSettings.manageAccountingTags.description"
                headingClasses="text-lg font-semibold text-neutral-800"
                subTextClasses="text-sm font-medium text-neutral-500"
              />
              <Icon
                name="ArrowForward"
                className=" text-neutral-500 hover:cursor-pointer"
                handleClick={handleAccountTagsSlider}
              />
            </div>

            {!isUCSV ? (
              <div className="flex items-center justify-between gap-4 p-4 border rounded-lg border-neutral-200">
                <SliderHeader
                  heading="accounting.integrations.integrationSettings.accountingAutomation.title"
                  subText="accounting.integrations.integrationSettings.accountingAutomation.description"
                  headingClasses="text-lg font-semibold text-neutral-800"
                  subTextClasses="text-sm font-medium text-neutral-500"
                />
                <Icon
                  name="ArrowForward"
                  className="text-neutral-500 hover:cursor-pointer"
                  handleClick={handleAccountAutomationSlider}
                />
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex items-center">
              <hr className="w-full text-text-light" />
            </div>

            <SliderHeader
              heading="accounting.integrations.integrationSettings.disconnect.title"
              headingTranslationProps={{
                accountingSoftware:
                  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingSoftware],
              }}
              subText="accounting.integrations.integrationSettings.disconnect.description"
              subTextTranslationProps={{
                accountingSoftware:
                  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingSoftware],
              }}
              headingClasses="text-lg font-semibold text-neutral-800"
              subTextClasses="text-sm font-medium text-neutral-500"
            />
            <Button
              label="accounting.integrations.buttonLabel.disconnect"
              variant="tertiary"
              classes="w-fit text-btn-lg px-5 py-3 font-semibold text-danger-500 !bg-danger-50 border border-danger-300 rounded-lg"
              onClick={handleDisconnect}
            />
          </div>
        </div>
      </div>

      {showDisconnectModal ? (
        <DisconnectAccountingSoftwareModal
          showModal={showDisconnectModal}
          setShowModal={setShowDisconnectModal}
        />
      ) : null}
    </div>
  );
}

IntegrationsSettingsSliderComponent.propTypes = {
  accountingSoftware: PropTypes.string,
};
