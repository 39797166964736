import PropTypes, { oneOfType } from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { dateToTimeStampString } from "@/utils/common";

export default function TimeStampAndCashback({
  updatedAt,
  cashback,
  cashbackCurrency,
  cashbackPercent,
  isReversalExpense,
  isReversedExepense,
}) {
  const amountText = amountToCurrency();
  const showCashback = !isReversedExepense && cashback && amountText;

  function amountToCurrency(options = {}) {
    const amount = cashback?.amount;
    const computeAmountWithCurreny = amount && amount > 0;
    if (!computeAmountWithCurreny) {
      return null;
    }
    const currencyString = new Intl.NumberFormat("en-US", options).format(
      amount
    );

    return `${currencyString === "-0" ? 0 : currencyString} ${
      cashbackCurrency ? cashbackCurrency : ""
    }`;
  }

  return (
    <div className="flex items-center gap-1 text-sm font-medium text-neutral-500">
      <div>{dateToTimeStampString(updatedAt, false)}</div>

      {showCashback ? (
        <>
          <span className="profile-widget-dot" />
          <Text
            translationKey={
              cashbackPercent
                ? "expenses.cashbackSliderText"
                : "expenses.cashbackSliderTextWithNoPercent"
            }
            translationProps={{
              amount: amountText,
              cashbackPercent,
            }}
          />
          <Icon name="Cashback" className="w-3 h-3 text-success-500" />
        </>
      ) : null}

      {isReversalExpense || isReversedExepense ? (
        <div className="flex flex-row items-center text-warning-600">
          <div className="profile-widget-dot bg-neutral-500" />
          <div className="text-sm not-italic font-medium leading-6">
            {isReversalExpense ? (
              <Text translationKey="expenses.reversal" />
            ) : (
              ""
            )}

            {isReversedExepense ? (
              <Text translationKey="expenses.reversed" />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
TimeStampAndCashback.propTypes = {
  updatedAt: PropTypes.string,
  cashback: oneOfType([PropTypes.string, PropTypes.number]),
  cashbackCurrency: PropTypes.string,
  cashbackPercent: PropTypes.number,
  isReversalExpense: PropTypes.bool,
};
