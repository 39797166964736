import React from "react";
import { useSelector } from "react-redux";

import { allVirtualCardPageFiltersSelector } from "@/store/selectors/cards";

import VirtualCardsPageHelper from "@/components/Cards/VirtualCardsPageHelper";

import { ROUTES } from "@/constants/routes";

function VirtualCardsAll() {
  const filters = useSelector(allVirtualCardPageFiltersSelector);
  return (
    <VirtualCardsPageHelper
      tab={ROUTES.cards.virtualCards.all.pathName}
      filterArray={filters}
    />
  );
}

export default VirtualCardsAll;
