import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { fetchAndSelectAccountingTransaction } from "@/store/reducers/accounting_transactions";

import {
  isFetchingSelectedAccountingTransactionSelector,
  selectedAccountingTransactionSelector,
} from "@/store/selectors/accounting_transactions";
import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";

import Alert from "@/components/core/Alert";
import Icon from "@/components/core/Icon";
import Loader from "@/components/core/Loader";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import VpLink from "@/components/core/vp-link";

import ActivityTab from "@/components/Accounting/Transactions/BillPay/Slider/ActivityTab";
import BillPayTransactionOverviewTab from "@/components/Accounting/Transactions/BillPay/Slider/BillPayTransactionOverviewTab";
import PaymentSummaryShort from "@/components/Accounting/Transactions/BillPay/Slider/PaymentSummaryShort";
import AccountingSliderSubmitButton from "@/components/Accounting/Transactions/common/Slider/AccountingSliderSubmitButton";
import { accountingActionHandler } from "@/components/Accounting/Transactions/common/util";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
} from "@/constants/accounting";
import { TABS } from "@/constants/common";

const selectedTabs = TABS;

export default function AccountingTransactionBillpaySlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const sliderId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.billPay.id
  );
  const dispatch = useDispatch();
  const payment = useSelector(selectedAccountingTransactionSelector);
  const isFetching = useSelector(
    isFetchingSelectedAccountingTransactionSelector
  );
  const [selectedTab, setSelectedTab] = useState(selectedTabs[0]);
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = !!accountingSoftware;

  useEffect(() => {
    dispatch(
      fetchAndSelectAccountingTransaction({
        context: ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL,
        accountingId: sliderId,
      })
    );
  }, []);

  return !isFetching && payment ? (
    <>
      <div className="slider-content-core">
        <div className="mt-5 mb-6">
          <PaymentSummaryShort payment={payment} />
        </div>
        {payment?.accountingStatus ===
        ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED ? (
          <div className="mt-6">
            <Alert
              wrapperDivClass="justify-start"
              iconClasses="w-6 h-6 text-neutral-500"
              variant="warning"
              title={payment?.accountingFailReason}
            />
          </div>
        ) : null}
        <div className="my-4">
          <Tabs
            items={selectedTabs}
            selectedTab={selectedTab?.key}
            setCurrentTab={(tab) => setSelectedTab(tab)}
            mode
          />
          <div className="mt-8" id="tabContent">
            {selectedTab.key === 1 ? (
              <BillPayTransactionOverviewTab payment={payment} />
            ) : (
              <ActivityTab payment={payment} />
            )}
          </div>
        </div>
      </div>

      {accountingSoftware ? (
        <div className="slider-footer">
          <div className="flex items-center h-full px-6 py-4 bg-white">
            <AccountingSliderSubmitButton
              verifiable={payment?.verifiable}
              syncable={payment?.syncable}
              accountingStatus={payment?.accountingStatus}
              accountingId={payment?.accountingId}
              onClick={accountingActionHandler}
              page={ACCOUNTING_TRANSACTION_PAGES.BILL_PAY}
              notActionableReason={payment?.notActionableReason}
              accountingCtas={payment?.accountingCtas}
            />
          </div>
        </div>
      ) : null}
    </>
  ) : (
    <Loader />
  );
}
