import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

export default function DocumentInfo({
  vendor,
  isFetching,
  onClick = () => {}, // on arrow click
}) {
  return (
    <div className="flex flex-col justify-center w-full p-4 font-medium border rounded-lg border-neutral-300">
      {isFetching ? (
        <div className="flex flex-col">
          <LoaderSkeleton size={[24, 90]} />
          <LoaderSkeleton size={[24, 24]} />
        </div>
      ) : (
        <>
          <div className="flex items-center gap-1">
            <Text
              translationKey="billPay.vendors.slider.documents"
              classes="text-base text-neutral-500 font-semibold"
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-base font-semibold text-neutral-800">
              {vendor.attachments?.length || 0}
            </span>
            <div onClick={onClick} className="cursor-pointer">
              <Icon name="ToLink" className="text-primary-500" />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

DocumentInfo.propTypes = {
  vendor: PropTypes.object,
  isFetching: PropTypes.bool,
  onClick: PropTypes.func,
};
