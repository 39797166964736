import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchAndSelectLedger,
  setUnsyncedSlider,
} from "@/store/reducers/ledgers";

import {
  totalUnsyncedLedgerIsFetchingSelector,
  totalUnsyncedLedgerListSelector,
} from "@/store/selectors/ledgers";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import {
  LEDGER_ACCOUNTING_SLIDER_STATUS,
  LEDGER_ACCOUNTING_STATUS,
  SYNC_STATUS_LEDGER,
  TABLE_HEADER,
} from "@/utils/constants/ledger";
import {
  amountToCurrency,
  dateToTimeStampString,
  getLegerSyncStatus,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function UnsyncSlider() {
  const unsyncedList = useSelector(totalUnsyncedLedgerListSelector);
  const isFetching = useSelector(totalUnsyncedLedgerIsFetchingSelector);
  const [isEmpty, setIsEmpty] = useState(true);
  const [searchParam, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const openNextSlider = (unsyncLedger) => {
    if (unsyncLedger && unsyncLedger.transactionType) {
      if (unsyncLedger.transactionType === "expense") {
        searchParam.set(SLIDERS_SEARCH_PARAMS.expenses.id, unsyncLedger.id);
      }
      if (["credit", "debit"].includes(unsyncLedger.transactionType)) {
        searchParam.set(
          SLIDERS_SEARCH_PARAMS.accounting.ledger.others,
          unsyncLedger.id
        );
        searchParam.set(
          SLIDERS_SEARCH_PARAMS.accounting.others.type,
          unsyncLedger.transactionType
        );
        dispatch(fetchAndSelectLedger({ id: parseInt(unsyncLedger.id, 10) }));
      }

      setSearchParams(searchParam);

      dispatch(
        setUnsyncedSlider({
          page: 1,
          type: unsyncLedger.transactionType,
          id: unsyncLedger.id,
        })
      );
    }
  };
  useEffect(() => {
    setIsEmpty(unsyncedList.length === 0);
  });
  return (
    <>
      <div className="slider-content-core">
        <section className="flex flex-col mb-5">
          <Text
            classes="font-bold text-3xl"
            translationKey="accounting.ledger.unsyncTransaction.title"
          />
          <Text
            classes="font-medium text-sm text-neutral-500"
            translationKey="accounting.ledger.unsyncTransaction.description"
          />
        </section>
        <Table headerSticky colWidths={[200, 200, 200, 200, 300]}>
          <tr>
            {TABLE_HEADER.map((header, index) => (
              <th
                className={`text-xs font-semibold ${header.class}`}
                key={index}
              >
                {header.haveSort ? (
                  <div
                    className={`flex items-center gap-2 ${
                      header.class ? header.class : ""
                    }`}
                  >
                    <Icon name="ChevronDown" />
                    <Text translationKey={header.name} />
                  </div>
                ) : (
                  <Text translationKey={header.name} />
                )}
              </th>
            ))}
          </tr>
          {!isFetching || !isEmpty
            ? unsyncedList.map((unsync, index) => (
                <tr
                  className="text-sm font-medium text-left cursor-pointer"
                  onClick={() => openNextSlider(unsync)}
                  key={index}
                >
                  <td>{dateToTimeStampString(unsync.createdAt)}</td>
                  <td>
                    <div className="flex justify-center">
                      <Badge
                        classes="w-14 "
                        variant={
                          unsync.accountingStatus !==
                          LEDGER_ACCOUNTING_STATUS.pending
                            ? "success"
                            : "danger"
                        }
                        preIcon={
                          unsync.accountingStatus ===
                          LEDGER_ACCOUNTING_STATUS.pending
                            ? null
                            : "Done"
                        }
                        translationKey={
                          unsync.accountingStatus ===
                          LEDGER_ACCOUNTING_STATUS.pending
                            ? LEDGER_ACCOUNTING_SLIDER_STATUS.notVerified
                            : LEDGER_ACCOUNTING_SLIDER_STATUS.verified
                        }
                      />
                    </div>
                  </td>
                  <td className="text-right">
                    <Text
                      translationKey={amountToCurrency(
                        unsync.amount.amount,
                        unsync.amount.currency
                      )}
                    />
                  </td>
                  <td>
                    <ProfileWidget
                      className="flex flex-row-reverse justify-between"
                      iconComponent={
                        <Icon className="text-neutral-500" name="Person" />
                      }
                      name={
                        unsync.user.name.fullName
                          ? unsync.user.name.fullName
                          : unsync.user.name
                      }
                    >
                      <Text
                        classes="text-xs text-neutral-500 font-medium"
                        translationKey={unsync.user.department.name}
                      />
                    </ProfileWidget>
                  </td>
                  <td>{unsync.description}</td>
                </tr>
              ))
            : null}
        </Table>
      </div>
      <div className="px-3 py-8 slider-footer">
        <div className="flex justify-end">
          <Button
            counter={
              unsyncedList.filter(
                (val) =>
                  val.accountingStatus !== LEDGER_ACCOUNTING_STATUS.pending
              ).length
            }
            type="success"
            label="Sync"
          />
        </div>
      </div>
    </>
  );
}
