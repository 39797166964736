import { useEffect } from "react";

import Lottie from "lottie-web";
import API from "@/api";

import sliderLoaderJson from "@/assets/lottie/form-loader.json";

export default function FormLoader() {
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#form-loader"),
      animationData: sliderLoaderJson,
    });
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-full ">
      <div id="form-loader" className="w-[300px] h-[300px]" />
    </div>
  );
}
