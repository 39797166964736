import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { setCreateClaimFormDetails } from "@/store/reducers/reimbursement";

import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { CREATE_REIMBURSEMENT_TYPE_ARRAY } from "@/utils/constants/reimbursement";

/**
 * @param {Function} handleChange
 * @param {Object} values
 * @param {Object} errors
 * @param {Funtion} setReimbursementType
 *
 * @description
 *    on selecting a value among out of pocket and mileage rate, setReimbursementType will set the value in initialFormValue as selected
 *    value and according to that the OutOfPocketForm changes
 *
 */
export default function ReimbursementType({
  handleChange,
  values,
  errors,
  setReimbursementType,
  disabled,
}) {
  const dispatch = useDispatch();

  return (
    <div className="flex gap-4 mt-4">
      {CREATE_REIMBURSEMENT_TYPE_ARRAY.map((val, index) => (
        <div
          key={`create-reimbursement-type-selector-radio-button-${index}`}
          className="flex items-center w-full gap-4 card-wrapper"
        >
          <Radio
            name="reimbursementType"
            isChecked={values.reimbursementType === val.value}
            value={val.value}
            error={errors.reimbursementType}
            handleChange={(e) => {
              setReimbursementType(e.target.value);
              handleChange(e);
              dispatch(setCreateClaimFormDetails(null));
            }}
            insideForm
            disabled={disabled}
          />
          <div className="flex flex-col text-sm ">
            <Text classes="font-semibold" translationKey={val.name} />
            <Text
              classes="font-normal  text-neutral-500"
              translationKey={val.description}
            />
          </div>
        </div>
      ))}
      <Tooltip direction="top" id="mileage-tooltip">
        <div className="flex flex-col text-sm">
          <Text
            classes="text-neutral-800 font-semibold"
            translationKey="featureNotAvailable"
          />
        </div>
      </Tooltip>
    </div>
  );
}
ReimbursementType.propTypes = {
  handleChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  setReimbursementType: PropTypes.func,
  disabled: PropTypes.bool,
};
