import PropTypes from "prop-types";

export default function LinkedToCell({ val }) {
  return val?.bulkProcessing ? null : val?.linkedTo?.id ? (
    <>
      {" "}
      <div className="truncate">{val?.linkedTo?.name}</div>
      <div className="text-xs font-normal text-neutral-500">
        {val?.linkedTo?.title}
      </div>
    </>
  ) : (
    "-"
  );
}

LinkedToCell.propTypes = {
  val: PropTypes.object,
};
