import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { updateClientSettings } from "@/store/reducers/client";
import { fetchRoles } from "@/store/reducers/company";
import {
  setAutomatedPaymentToggleSwitch,
  updatePayrollSetting,
} from "@/store/reducers/payments";

import {
  billAutoPayEnabledSelector,
  isUpdatingClientSettingsSelector,
} from "@/store/selectors/client";
import {
  autoPaymentEnabledSelector,
  automatedPaymentToggleSwitchSelector,
  enablePayrollSelector,
} from "@/store/selectors/payments";
import { userPermissionSelector } from "@/store/selectors/user";

import TogglePayroll from "@/components/common/BillPayAndPayroll/Settings/General/TogglePayroll";
import SettingsList from "@/components/common/BillPayAndPayroll/Settings/common/SettingsList";
import vToast from "@/utils/vToast";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  BILLPAY_SETTING_ENTRIES,
  PAYROLL_SETTING_ENTRIES,
} from "@/utils/constants/payments";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { SETTINGS_PARAMS } from "@/constants/settings";
import { EDIT, PERMISSIONS, READ_ONLY } from "@/constants/user";

const getBillpayPayrollGeneralTabContent = ({
  context,
  automatedPaymentToggleSwitch,
  openAutomatedPaymentSlider,
  isLoadingAutomatedPaymentToggleSwitch,
  adminOrManageAndReadView,
  isViewChange,
  adminOrManange,
}) => {
  const billpayConfig = () => {
    return [
      {
        id: "ap-clerks-section",
        title: "billPay.settings.rows.apClerks.title",
        description: "billPay.settings.rows.apClerks.description",
        rightChildren: [
          {
            id: BILLPAY_SETTING_ENTRIES.AP_CLERKS,
            title: "billPay.settings.rows.apClerks.rightSide.addAPClerks.title",
            description:
              "billPay.settings.rows.apClerks.rightSide.addAPClerks.description",

            showSwitch: false,

            sliderType:
              SLIDERS_SEARCH_PARAMS.payments.settings.general.apClerks,
            sliderId: null,
          },
        ],
      },

      {
        id: "payment-initiators-section",
        title: "billPay.settings.rows.paymentInitiators.title",
        description: "billPay.settings.rows.paymentInitiators.description",
        rightChildren: [
          {
            id: BILLPAY_SETTING_ENTRIES.PAYMENT_INITIATION,
            title:
              "billPay.settings.rows.paymentInitiators.rightSide.addPaymentInitiators.title",
            description:
              "billPay.settings.rows.paymentInitiators.rightSide.addPaymentInitiators.description",

            showSwitch: false,

            sliderType:
              SLIDERS_SEARCH_PARAMS.payments.settings.general.paymentInitiators,
            sliderId: null,
          },
        ],
      },

      {
        id: "automated-payments",
        title: "billPay.settings.rows.automatedPayments.title",
        description: "billPay.settings.rows.automatedPayments.description",
        rightChildren: [
          {
            id: BILLPAY_SETTING_ENTRIES.AUTOMATED_PAYMENTS,
            title:
              "billPay.settings.rows.automatedPayments.rightSide.automatedPaymentsSwitch.title",
            description:
              "billPay.settings.rows.automatedPayments.rightSide.automatedPaymentsSwitch.description",

            showSwitch: true,
            isSwitchOn: automatedPaymentToggleSwitch,
            isSwitchLoading: isLoadingAutomatedPaymentToggleSwitch, // loading/updating

            sliderType: null,
            sliderId: null,
            handleChange: openAutomatedPaymentSlider,
          },
        ],
      },
    ];
  };

  const payrollConfigData = () => {
    return [
      {
        id: "payroll-clerks-section",
        title: "payroll.payrollSettings.rows.payrollClerks.title",
        description: "payroll.payrollSettings.rows.payrollClerks.description",
        rightChildren: [
          {
            id: PAYROLL_SETTING_ENTRIES.PAYROLL_CLERKS,
            title: adminOrManageAndReadView
              ? "payroll.payrollSettings.rows.payrollClerks.rightSide.addPayrollCherks.title"
              : "payroll.payrollSettings.rows.payrollClerks.rightSide.addPayrollCherks.readOnlyTitle",
            description:
              "payroll.payrollSettings.rows.payrollClerks.rightSide.addPayrollCherks.description",

            showSwitch: false,
            permissionType: adminOrManageAndReadView ? EDIT : READ_ONLY,

            sliderType:
              SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general
                .payrollClerks,
            sliderId: null,
            isViewChange,
          },
        ],
      },
      {
        id: "payment-initiators-section",
        title: "payroll.payrollSettings.rows.paymentInitiators.title",
        description:
          "payroll.payrollSettings.rows.paymentInitiators.description",
        rightChildren: [
          {
            id: PAYROLL_SETTING_ENTRIES.PAYMENT_INITIATION,
            title: adminOrManageAndReadView
              ? "payroll.payrollSettings.rows.paymentInitiators.rightSide.addPaymentInitiators.title"
              : "payroll.payrollSettings.rows.paymentInitiators.rightSide.addPaymentInitiators.readOnlyTitle",
            description:
              "payroll.payrollSettings.rows.paymentInitiators.rightSide.addPaymentInitiators.description",

            showSwitch: false,
            permissionType: adminOrManageAndReadView ? EDIT : READ_ONLY,

            sliderType:
              SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general
                .paymentInitiators,
            sliderId: null,
            isViewChange,
          },
        ],
      },

      adminOrManange
        ? {
            id: "automated-payments",
            title: "payroll.payrollSettings.rows.automatedPayments.title",
            description:
              "payroll.payrollSettings.rows.automatedPayments.description",
            rightChildren: [
              {
                id: PAYROLL_SETTING_ENTRIES.AUTOMATED_PAYMENTS,
                title:
                  "payroll.payrollSettings.rows.automatedPayments.rightSide.automatedPaymentsSwitch.title",
                description:
                  "payroll.payrollSettings.rows.automatedPayments.rightSide.automatedPaymentsSwitch.description",

                showSwitch: true,
                isSwitchOn: automatedPaymentToggleSwitch,

                sliderType:
                  SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general
                    .automatedPayments,
                sliderId: null,

                handleChange: openAutomatedPaymentSlider,
                isViewChange,
              },
            ],
          }
        : {},
    ];
  };

  if (context === BILL_PAYROLL_CONTEXT.PAYROLL) {
    return payrollConfigData();
  }
  return billpayConfig();
};

export default function GeneralSettings({ context }) {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();

  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const autoPaymentEnabled = useSelector(
    inPayrollContext ? autoPaymentEnabledSelector : billAutoPayEnabledSelector
  );
  const isFetchingClientSettings = useSelector(
    isUpdatingClientSettingsSelector
  );
  const isLoadingAutomatedPaymentToggleSwitch = isFetchingClientSettings;
  const payrollEnabled = useSelector(enablePayrollSelector);
  const automatedPaymentToggleSwitch = useSelector(
    automatedPaymentToggleSwitchSelector
  );
  const userPermission = useSelector(userPermissionSelector);

  const permissionListToCheck = [
    PERMISSIONS.GLOBAL_SETTINGS_MANAGE,
    PERMISSIONS.PAYROLL_ADMINISTRATE,
    PERMISSIONS.PAYROLL_MANAGE,
  ];

  const permissionCheckResults = permissionListToCheck.reduce(
    (result, permission) => {
      const foundPermission = userPermission?.some(
        (userPerm) =>
          userPerm?.name?.toLowerCase() === permission?.toLowerCase()
      );
      result[permission] = foundPermission;

      return result;
    },
    {}
  );

  const {
    [PERMISSIONS.GLOBAL_SETTINGS_MANAGE]: readOnlyView,
    [PERMISSIONS.PAYROLL_ADMINISTRATE]: payrollAdministrativeView,
    [PERMISSIONS.PAYROLL_MANAGE]: payrollManageView,
  } = permissionCheckResults;

  const isViewChange =
    payrollAdministrativeView || payrollManageView || readOnlyView;

  const adminOrManageAndReadView =
    (payrollAdministrativeView || payrollManageView) && readOnlyView;

  const adminOrManange = payrollAdministrativeView || payrollManageView;

  // TECH_DEBT remove this useEffect, after API call, the onSuccess, the store will update anyway
  useEffect(() => {
    dispatch(setAutomatedPaymentToggleSwitch(autoPaymentEnabled));
  }, [autoPaymentEnabled, automatedPaymentToggleSwitch]);

  const openAutomatedPaymentSlider = (e) => {
    if (!inPayrollContext) {
      dispatch(
        updateClientSettings({
          setting: SETTINGS_PARAMS.billPaymentAutopayEnabled,
          value: e,
          onSuccess: () => {
            vToast({
              description: e
                ? "payroll.payrollSettings.payrollSliders.automatedPayments.successEnableToast"
                : "payroll.payrollSettings.payrollSliders.automatedPayments.successDisableToast",
              variant: "success",
            });
          },
        })
      );
      return;
    }

    if (!e) {
      dispatch(
        updatePayrollSetting({
          payload: { auto_payment_enabled: false },
          onSuccess: () => {
            vToast({
              description:
                "payroll.payrollSettings.payrollSliders.automatedPayments.successDisableToast",
              variant: "success",
            });
          },
        })
      );
      dispatch(setAutomatedPaymentToggleSwitch(e));
    } else {
      searchParam.append(
        SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general
          .automatedPayments,
        true
      );
      setSearchParam(searchParam);
      dispatch(setAutomatedPaymentToggleSwitch(e));
    }
  };

  useEffect(() => {
    dispatch(
      fetchRoles({
        shallow: true,
      })
    );
  }, []);

  // TECH_DEBT: no need of state, effect here. Do direct or do useMemo
  const paramsForConfig = {
    context,
    automatedPaymentToggleSwitch,
    openAutomatedPaymentSlider,
    isLoadingAutomatedPaymentToggleSwitch,
    isViewChange,
    readOnlyView,
    adminOrManageAndReadView,
    adminOrManange,
  };
  const [configState, setConFigState] = useState(
    getBillpayPayrollGeneralTabContent(paramsForConfig)
  );
  useEffect(() => {
    setConFigState(getBillpayPayrollGeneralTabContent(paramsForConfig));
  }, [
    isViewChange,
    readOnlyView,
    adminOrManageAndReadView,
    adminOrManange,
    automatedPaymentToggleSwitch,
    isLoadingAutomatedPaymentToggleSwitch,
  ]);

  return (
    <div>
      {inPayrollContext ? (
        <>
          <TogglePayroll />
          {payrollEnabled ? <SettingsList tabRows={configState} /> : null}
        </>
      ) : (
        <SettingsList tabRows={configState} />
      )}
    </div>
  );
}

GeneralSettings.propTypes = {
  context: PropTypes.string,
};
