import React from "react";

import SpendControlsPageHelper from "@/components/Cards/Settings/SpendControls";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";

function SpendControlPage() {
  return <SpendControlsPageHelper page={QRPAY_EXPENSES_CONTEXT.EXPENSES} />;
}

export default SpendControlPage;
