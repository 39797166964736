import PropTypes from "prop-types";
import React from "react";

import CommentDisplayChild from "@/components/core/Comments/CommentDisplayChild";
import Text from "@/components/core/Text";
import { dateToString } from "@/utils/common";

export default function CommentDisplay({ comments, sanitizeHtmlOptions }) {
  return (
    <div>
      {comments
        ?.map(({ date, data: dataForParticularDate }, index) => {
          return (
            <div key={`comments-display${index}`} className="w-full">
              <Text translationKey={dateToString(date)} />
              <div className="border-t-[1.5px] mt-5 border-neutral-200">
                <div className="h-6 mx-5 mb-2 border-l-[1.5px] border-neutral-200" />
                <CommentDisplayChild
                  sanitizeHtmlOptions={sanitizeHtmlOptions}
                  data={dataForParticularDate}
                />
              </div>
            </div>
          );
        })
        .reverse()}
    </div>
  );
}
CommentDisplay.propTypes = {
  comments: PropTypes.array,
  sanitizeHtmlOptions: PropTypes.object,
};
