import PropTypes from "prop-types";
import { useRef } from "react";
import Popup from "reactjs-popup";

import Button from "@/components/core/Button";

export default function PayOutsideMoreOption({ config, disabled }) {
  const popupRef = useRef();

  const handlePopupToggle = () => {
    popupRef.current.toggle();
  };

  return (
    <div>
      <Popup
        ref={popupRef}
        repositionOnResize
        trigger={(isPopupOpen) => (
          <div>
            <Button
              classes="w-fit px-3 font-base text-primary-500 font-semibold"
              variant="tertiary"
              onClick={handlePopupToggle}
              preIcon="MoreVerticle"
              disabled={disabled}
              iconClasses="h-6 w-6 text-neutral-500"
              innerClasses="gap-[0px]"
            />
          </div>
        )}
        closeOnDocumentClick
        position="top right"
        className="border-0 filter-popup"
        nested
      >
        {config.map((configItem) =>
          configItem.hide ? null : (
            <Button
              key={configItem.label}
              variant="tertiary"
              classes="border-0 w-full p-4 pl-3 pr-6 font-medium text-neutral-500 hover:text-neutral-900"
              textAlignment="text-left"
              label={configItem.label}
              tooltipProps={{
                direction: "left center",
                maxWidth: "400px",
                keepToolTipInside: ".slider-content-core",
                nested: true,
              }}
              onClick={() => {
                popupRef.current.close(); // close the context menu
                configItem.onClick();
              }}
              {...configItem.buttonProps}
            />
          )
        )}
      </Popup>
    </div>
  );
}

PayOutsideMoreOption.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
