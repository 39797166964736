import PropTypes from "prop-types";
import { useId } from "react";

import { convertTo12HourFormat } from "@/utils/common";

import Avatar from "../Avatar";
import Text from "../Text";

export default function ProfileWidget({
  img,
  name,
  timeStampAboveName,
  truncateBasedOnSize = true,
  nameTranslationProps = {},
  children,
  avatarColor,
  imageClasses,
  avatarSize,
  className = "gap-3",
  iconComponent = null,
  opacityChangeRatio,
  textClasses = "text-neutral-800",
  showTooltip = false,
  tooltipClasses,
  id,
  profileContentSectionClassNames,
}) {
  const tooltipId = useId();
  id ||= `${id}-${tooltipId.replaceAll(":", "")}`;

  return (
    <div
      className={`flex items-center overflow-hidden ${className} ${
        className?.includes("gap") ? "" : "gap-3"
      } `}
    >
      <div>
        {iconComponent ? (
          iconComponent
        ) : (
          <Avatar
            opacityChangeRatio={opacityChangeRatio}
            imgSrc={img}
            name={name}
            color={avatarColor}
            size={avatarSize}
            imgClasses={imageClasses}
          />
        )}
      </div>
      <div
        className={`flex overflow-hidden ${profileContentSectionClassNames} ${
          profileContentSectionClassNames?.includes("flex-row")
            ? "flex-row"
            : "flex-col"
        }`}
      >
        {name ? (
          <>
            {timeStampAboveName ? (
              <Text
                classes="font-semibold text-neutral-500 text-sm"
                translationKey={convertTo12HourFormat(timeStampAboveName)}
              />
            ) : null}
            <Text
              classes={`${
                !textClasses?.includes("font") ? "font-normal" : ""
              } ${textClasses}`}
              id={`profile-widget-name-${id}`}
              translationKey={name}
              tooltipClasses={tooltipClasses}
              showTooltip={name?.length > 12}
              translationProps={nameTranslationProps}
              truncate
            />
          </>
        ) : null}

        {children}
      </div>
    </div>
  );
}

ProfileWidget.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
  nameTranslationProps: PropTypes.object,
  avatarColor: PropTypes.string,
  children: PropTypes.node,
  avatarSize: PropTypes.string,
  className: PropTypes.string,
  iconComponent: PropTypes.element,
  textClasses: PropTypes.string,
  opacityChangeRatio: PropTypes.number,
  profileContentSectionClassNames: PropTypes.string,
  timeStampAboveName: PropTypes.string,
  showTooltip: PropTypes.bool,
  tooltipClasses: PropTypes.string,
  id: PropTypes.number,
  imageClasses: PropTypes.string,
};
