import PropTypes from "prop-types";
import { useState } from "react";

import Text from "@/components/core/Text";

import GenericFormField from "@/components/GenericForm/GenericFormField";
import {
  FIELDS_KEY_PLURAL,
  FIELD_HIDDEN_KEY,
  FIELD_KEY_KEY,
  FIELD_TYPE_KEY,
  INLINE_FIELDS_KEY,
  INLINE_GROUP_IDENTIFIER_KEY,
  INLINE_GROUP_IDENTIFIER_KEY_OLD_SNAKE_CASE,
  INLINE_GROUP_TYPE_NAME,
  sectionPropType,
} from "@/components/GenericForm/common";

import "./style.scss";

/**
 * A section in a form page, i.e. a heading and with associated form fields
 *
 * @param {Object}    section  section object in the form blueprint.  requirementsObject > [Page] > [Section]
 * @param {Function}  onChange 'errors' object returned by useForm
 * @param {Object}    values 'values' object returned by useForm
 * @param {Object}    errors 'errors' object returned by useForm
 */
export default function GenericFormSection({
  section,
  onChange,
  values,
  errors,
  sectionHeaderComponent = null,
  setInitialFormValue = () => {},
  setValues,
  cleanUp,
  hideFields,
  hideEmptySections,
  autofillValueFromRegexIfLiteral,
  autofillDropdownIfSingleOption,
  autoFillSource,
  headingTextBase,
}) {
  const sectionLabel = section?.label;
  const sectionDescription = section?.description;
  const { fields, ...sectionHeaderProps } = section;
  const [isChecked, setIschecked] = useState({});
  const HeaderComponent = sectionHeaderComponent;

  if (
    hideFields &&
    hideEmptySections &&
    fields.every((item) => item[FIELD_HIDDEN_KEY])
  ) {
    return null; // hide section if all fields 'hidden'
  }

  return (
    <div>
      {/* Section title, description */}
      {!sectionHeaderComponent ? (
        <div className="flex flex-col">
          <Text
            translationKey={sectionLabel}
            classes={`${
              headingTextBase ? "text-base" : "text-xl"
            } font-semibold text-neutral-800 block mb-6`}
          />
          {sectionDescription ? (
            <Text
              translationKey={sectionDescription}
              classes="text-base text-neutral-500 font-medium"
            />
          ) : null}
        </div>
      ) : (
        <HeaderComponent
          headerProps={{ ...sectionHeaderProps, setFormValues: setValues }}
        />
      )}

      {/* Fields of the section */}
      <div className="flex flex-col gap-9">
        {section[FIELDS_KEY_PLURAL]?.map((myfield) => {
          if (hideFields && myfield[FIELD_HIDDEN_KEY]) return null; // hide 'hidden' fields (simple field)

          const isInlineGroup =
            myfield[FIELD_TYPE_KEY] === INLINE_GROUP_TYPE_NAME;

          if (!isInlineGroup)
            return (
              <GenericFormField
                onChange={onChange}
                key={myfield[FIELD_KEY_KEY]}
                field={myfield}
                values={values}
                errors={errors}
                setInitialFormValue={setInitialFormValue}
                setValues={setValues}
                isChecked={isChecked}
                setIschecked={setIschecked}
                cleanup={cleanUp}
                autofillValueFromRegexIfLiteral={
                  autofillValueFromRegexIfLiteral
                }
                autofillDropdownIfSingleOption={autofillDropdownIfSingleOption}
                autoFillSource={autoFillSource}
              />
            ); // simple field

          // case where field is an inline-group, i.e. array of simple fields
          const inlineGroupFields = myfield[INLINE_FIELDS_KEY].filter(
            (item) => !(hideFields && item[FIELD_HIDDEN_KEY])
          ); // hide 'hidden' (parts of inline group)
          if (hideFields && inlineGroupFields.length === 0) return null; // hide 'hidden' fields (whole inline group)

          return (
            <div
              className="grid gap-10"
              style={{
                gridTemplateColumns: `repeat(${inlineGroupFields.length}, 1fr)`,
              }}
              key={
                myfield[INLINE_GROUP_IDENTIFIER_KEY] ||
                myfield[INLINE_GROUP_IDENTIFIER_KEY_OLD_SNAKE_CASE]
              }
            >
              {inlineGroupFields.map((inlineField) => (
                <GenericFormField
                  onChange={onChange}
                  key={inlineField[FIELD_KEY_KEY]}
                  field={inlineField}
                  values={values}
                  errors={errors}
                  setInitialFormValue={setInitialFormValue}
                  setValues={setValues}
                  isChecked={isChecked}
                  setIschecked={setIschecked}
                  cleanup={cleanUp}
                  autofillValueFromRegexIfLiteral={
                    autofillValueFromRegexIfLiteral
                  }
                  autofillDropdownIfSingleOption={
                    autofillDropdownIfSingleOption
                  }
                  autoFillSource={autoFillSource}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}

GenericFormSection.propTypes = {
  section: sectionPropType,
  onChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  sectionHeaderComponent: PropTypes.any,

  setInitialFormValue: PropTypes.func,
  setValues: PropTypes.func,
  cleanUp: PropTypes.func,
  hideFields: PropTypes.bool,
  hideEmptySections: PropTypes.bool,
  autofillValueFromRegexIfLiteral: PropTypes.bool,
  autofillDropdownIfSingleOption: PropTypes.bool,
  autoFillSource: PropTypes.object,
  headingTextBase: PropTypes.bool,
};
