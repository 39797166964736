import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchDepartmentRequirementsCount,
  setSelectedUserDepartmentChangeConfirmation,
} from "@/store/reducers/user";

import {
  allDepartmentSelector,
  totalPendingCardsRequestSelector,
} from "@/store/selectors/company";
import {
  selectedUserPhysicalCardsTotalCountSelector,
  selectedUserSelector,
  selectedUserVirtualCardsTotalCountSelector,
  userDepartmentRequirementsCountSelector,
} from "@/store/selectors/user";

import Text from "@/components/core/Text";

import ImportantToKnowSection from "@/components/Company/common/ImportantToKnowSection";
import PendingRequest from "@/components/Company/common/PendingRequest";
import PeopleCardsTable from "@/components/Company/common/PeopleCardsTable";
import { formatAndCleanUpCaptchaText } from "@/utils/common";

import { CARD_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import InputCaptchaCheck from "./InputCaptchaCheck";

function SwitchDepartement() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedUser = useSelector(selectedUserSelector);
  const displayName = selectedUser?.displayName;
  const switchDepartmentToId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.people.switchDepartmentTo
  );
  const currentDepartment = selectedUser?.departmentId;
  const switchDepartmentDescription =
    "company.people.switchDepartmentSlider.switchDepartmentDescription";
  const captchaMatchText = `SWITCH ${
    formatAndCleanUpCaptchaText(selectedUser?.displayName) ||
    selectedUser?.email
  }`;

  const departments = useSelector(allDepartmentSelector);

  const departmentRequirementsCount = useSelector(
    userDepartmentRequirementsCountSelector
  );

  const departmentName = departments.find((a) => {
    return a.id === parseInt(switchDepartmentToId, 10);
  })?.name;
  const ref = useLeftHeaderTitle({
    title: "company.people.switchDepartmentSlider.heading",
  });

  const totalPhysicalCardListCount =
    useSelector(selectedUserPhysicalCardsTotalCountSelector) || 0;

  const totalVirtualCardListCount =
    useSelector(selectedUserVirtualCardsTotalCountSelector) || 0;

  const totalPendingRequestCount = useSelector(
    totalPendingCardsRequestSelector
  );

  const userId = searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id);

  useEffect(() => {
    dispatch(fetchDepartmentRequirementsCount({ id: userId }));
  }, []);

  const config = [
    {
      verticalExpandable: true,
      labelConfig: {
        label: "company.people.switchDepartmentSlider.captchaConfig.pCard",
        classes: "text-sm font-semibold text-neutral-800",
        translationProps: { departmentName },
      },
      innerContent: (
        <PeopleCardsTable
          showCardsOfType={[CARD_TYPE.PHYSICAL]}
          filterByDepartment
          departmentIds={[currentDepartment]}
        />
      ),
      handleActionOnClose: () => {},
      count: departmentRequirementsCount?.info?.physicalCards,
      key: "expandable-1",
    },
    {
      verticalExpandable: true,
      labelConfig: {
        label: "company.people.switchDepartmentSlider.captchaConfig.vCard",
        classes: "text-sm font-semibold text-neutral-800",
      },
      innerContent: (
        <PeopleCardsTable
          showCardsOfType={[CARD_TYPE.VIRTUAL]}
          filterByDepartment
          departmentIds={[currentDepartment]}
        />
      ),
      handleActionOnClose: () => {},
      key: "expandable-2",
      count: departmentRequirementsCount?.info?.virtualCards,
    },
    {
      verticalExpandable: true,
      labelConfig: {
        label:
          "company.people.switchDepartmentSlider.captchaConfig.pendingRequest",
        classes: "text-sm font-semibold text-neutral-800",
      },
      count: departmentRequirementsCount?.info?.pendingRequests,
      innerContent: (
        <PendingRequest
          stepPaginationEnable
          departmentChangeFlow
          stepPaginationLimit={3}
          showTabs={false}
          tableExternalClasses="p-0 pt-4"
        />
      ),
      key: "expandable-3",
    },
    {
      verticalExpandable: false,
      labelConfig: {
        label:
          "company.people.switchDepartmentSlider.captchaConfig.transaction",
        classes: "text-sm font-semibold text-neutral-800",
      },
      key: "expandable-4",
    },
  ];

  function closeSwitchDepartment() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.switchDepartment);
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.company.people.switchDepartmentTo
    );
    setSearchParams(searchParams);
  }

  function handleAction(value) {
    dispatch(setSelectedUserDepartmentChangeConfirmation(value));
    closeSwitchDepartment();
  }

  function getAction(cta) {
    if (selectedUser.departmentId === parseInt(switchDepartmentToId, 10)) {
      return false;
    }
    if (cta === "cancel") {
      return false;
    }
    return true;
  }

  return (
    <>
      <div className="flex flex-col gap-10 p-10 pt-0">
        <div className="flex flex-col gap-3">
          <Text
            translationKey="company.people.switchDepartmentSlider.heading"
            classes="text-3xl font-bold"
            refProp={ref}
          />

          <Text
            translationKey="company.people.switchDepartmentSlider.headingDescription"
            classes="text-neutral-500 text-sm"
          />
        </div>
        <div className="flex flex-col gap-3">
          <Text
            classes="text-xl font-semibold"
            translationKey={switchDepartmentDescription}
            translationProps={{ displayName, departmentName }}
          />

          <div className="mb-4">
            <Text
              translationKey="company.people.switchDepartmentSlider.subjectiveWarningText"
              classes="text-neutral-500 text-sm"
            />
          </div>

          <ImportantToKnowSection
            mainLabelConfig={{
              label: "company.people.blockUserSlider.importantToKnowLabel",
              classes: "text-warning-500 text-sm",
            }}
            config={config}
          />
        </div>
      </div>
      <InputCaptchaCheck
        handleConfirmButton={() => handleAction(getAction("confirm"))}
        handleCancelButton={() => handleAction(getAction("cancel"))}
        outsideInputDivClasses="m-10 mt-0"
        captchaMatchText={captchaMatchText}
      />
    </>
  );
}

export default SwitchDepartement;
