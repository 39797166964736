import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setAnalyticsLedgerData } from "@/store/reducers/analytics";

import { analyticsLedgerCurrencySelector } from "@/store/selectors/analytics";
import { appliedFilterSelector } from "@/store/selectors/filters";

import Button from "@/components/core/Button";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import AnalyticsLedgerHelper from "@/components/Analytics/Ledger";
import { subdomain } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";

export default function AnalyticsLedger() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const analyticsLedgerCurrencies = useSelector(
    analyticsLedgerCurrencySelector
  );

  const appliedFilters = useSelector(appliedFilterSelector);

  const [selectedTab, setSelectedTab] = useState(
    analyticsLedgerCurrencies?.[0]
  );

  useEffect(() => {
    if (
      !appliedFilters?.entity ||
      appliedFilters?.entity?.value === subdomain()
    )
      setSelectedTab(analyticsLedgerCurrencies?.[0]);
  }, [analyticsLedgerCurrencies, appliedFilters?.entity]);

  return (
    <div>
      <div className="flex items-center justify-between mx-12 mb-3">
        <div className="flex flex-col ">
          <Text
            classes="text-neutral-500 text-sm font-semibold"
            translationKey="analytics.header"
          />
          <Text
            classes="text-neutral-800 text-4xl font-extrabold"
            translationKey="analytics.ledger.title"
          />
        </div>
        <Button
          label="accounting.ledger.requestStatementSlider.header"
          classes="w-fit px-5"
          onClick={() => {
            searchParams.append(
              SLIDERS_SEARCH_PARAMS.analytics.analyticsRequestStatement,
              true
            );
            setSearchParams(searchParams);
            dispatch(
              setAnalyticsLedgerData({
                selectedTab,
              })
            );
          }}
        />
      </div>
      {analyticsLedgerCurrencies ? (
        <>
          <div className="mx-12 mt-4">
            <Tabs
              items={analyticsLedgerCurrencies}
              selectedTab={selectedTab?.key}
              setCurrentTab={(tab) => setSelectedTab(tab)}
            />
          </div>

          <AnalyticsLedgerHelper
            type={ACCOUNTING_TRANSACTION_PAGES.LEDGER}
            tab={selectedTab}
          />
        </>
      ) : (
        <div className="flex gap-3 mx-12 my-4">
          <LoaderSkeleton classes="p-4" />
          <LoaderSkeleton classes="p-4" />
        </div>
      )}
    </div>
  );
}
