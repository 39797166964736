import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchCountries } from "@/store/reducers/app";
import {
  fetchPeople,
  fetchPeopleShallow,
  fetchRoles,
  fetchShallowDepartments,
  fetchShallowLocations,
} from "@/store/reducers/company";
import { setIsFormSubmissionProgress } from "@/store/reducers/loadersError";
import {
  fetchAndSelectUser,
  setSelectedEditFormDetails,
  setSelectedUserDepartmentChangeConfirmation,
  setSelectedUserLocationChangeConfirmation,
  setSelectedUserRoleChangeConfirmation,
  updateAddressDetails,
  updateUserAvatar,
  updateUserDetails,
} from "@/store/reducers/user";
import {
  clearBankDetailsForm,
  fetchAndSelectVendor,
  setSelectedVendor,
} from "@/store/reducers/vendors";

import { countriesSelector } from "@/store/selectors/app";
import {
  allDepartmentSelector,
  allLocationSelector,
  allPeopleSelector,
  customRolesSelector,
  isFetchingLocationsSelector,
  isFetchingProjectsSelector,
  isFetchingRolesSelector,
  primaryRolesSelector,
  rolesSelector,
} from "@/store/selectors/company";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userTagsSelector } from "@/store/selectors/tags";
import {
  availableModulesSelector,
  isDepartmentChangeConfirmedSelector,
  isExternalAccountantSelector,
  isLocationChangeConfirmedSelector,
  isRoleChangeConfirmedSelector,
  selectedUserSelector,
  selectedUserUpdatedDetailsSelector,
  userSelector,
} from "@/store/selectors/user";
import { selectedVendorSelector } from "@/store/selectors/vendors";

import Avatar from "@/components/core/Avatar";
import Button from "@/components/core/Button";
import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import FileUpload from "@/components/core/FileUpload";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import PhoneNumberInput from "@/components/core/PhoneInput";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import { ROLES_TYPE } from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/SettingsType";
import DateInputTextBox from "@/components/common/DateInputTextBox";
import { useForm } from "@/utils/useForm";
import { convertFilters } from "@/utils/filters";
import { REGEX_VALUE } from "@/utils/constantUseForm";
import {
  dateToString,
  flattenObject,
  getDateInPattern,
  getDialCodeAndNumberFromPhoneNumber,
  toCamelCase,
} from "@/utils/common";

import { MODULES } from "@/utils/constants/app";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP,
  COMPANY_PEOPLE_USER_STATUS,
  INVITATION_STATUS,
} from "@/constants/company";
import { DATEINPUT_MODES } from "@/constants/date";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  EDIT_USER_DEPARTMENT_LOCATION_TITLES,
  GENDER,
  KYC_STATE,
  TITLE,
  USER_STATUS,
  VP_ADDRESS_DETAILS_CONFIG_MAP,
} from "@/constants/user";

import EntityListWithDelete from "./EntityListWithDelete";

export default function EditUser({
  setOnClose = () => {},
  setOnBack = () => {},
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const ref = useLeftHeaderTitle({
    title: "company.people.peopleMyProfileSlider.myProfileHeader",
  });

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  const userIdParam = searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id);

  const isFetchingRoles = useSelector(isFetchingRolesSelector);
  const isFetchingDepartments = useSelector(isFetchingProjectsSelector);
  const isFetchingLocations = useSelector(isFetchingLocationsSelector);
  const selectedUser = useSelector(selectedUserSelector);
  const isExternalAccountant = useSelector(isExternalAccountantSelector);
  const availableModules = useSelector(availableModulesSelector);
  const primaryRolesOptions = useSelector(primaryRolesSelector);
  const customRoles = useSelector(customRolesSelector);
  const selectedDepartments = useSelector(allDepartmentSelector);
  const selectedLocations = useSelector(allLocationSelector);
  const selectedRoles = useSelector(rolesSelector);
  const isLocationChanged = useSelector(isLocationChangeConfirmedSelector);
  const isDepartmentChanged = useSelector(isDepartmentChangeConfirmedSelector);
  const isRoleChanged = useSelector(isRoleChangeConfirmedSelector);
  const peopleOptions = useSelector(allPeopleSelector);
  const selectedVendor = useSelector(selectedVendorSelector);
  const currentUser = useSelector(userSelector);
  const userTags = useSelector(userTagsSelector);
  const appliedFilter = useSelector(appliedFilterSelector);
  const memberRoleId = useSelector(primaryRolesSelector).find(
    (role) => role?.name === ROLES_TYPE.MEMBER
  )?.id;
  const updatedEditUserFieldsValues = useSelector(
    selectedUserUpdatedDetailsSelector
  );

  const initialCopilots = selectedUser?.copilots;

  const initialCoPilotsIds = initialCopilots?.map(
    (copilot) => copilot.assignedUserId
  );

  const selectedUserIsExternalAccountant =
    selectedUser?.role?.name === ROLES_TYPE.EXTERNAL_ACCOUNTANT;

  const isBlockedOrFreezed =
    [USER_STATUS.FREEZED, USER_STATUS.BLOCKED].includes(selectedUser?.status) ||
    isExternalAccountant ||
    selectedUserIsExternalAccountant;

  const reff = useLeftHeaderTitle({
    title: isBlockedOrFreezed
      ? "company.people.peopleMyProfileSlider.viewDetails"
      : "company.people.peopleMyProfileSlider.editProfile",
  });

  const initialNormalRole = selectedUser?.role?.id;

  const initialCustomRoles = selectedUser?.customRoles;

  const initialCustomRolesIds = initialCustomRoles?.map(
    (customRole) => customRole.roleId
  );

  const [selectedPeopleOptions, setSelectedPeopleOptions] = useState([]);
  const [selectedCustomRolesOptions, setSelectedCustomRolesOptions] = useState(
    []
  );

  const bankDetailsForm = selectedVendor?.bankDetailsPresent;
  const myProfile = currentUser?.id === selectedUser?.id;

  const myProfileNotAdmin =
    myProfile && selectedUser?.role?.name !== ROLES_TYPE.ADMIN;

  const isDisabled =
    selectedUser?.kycState === KYC_STATE.APPROVED && selectedUser?.vkycStatus
      ? selectedUser?.vkycStatus === KYC_STATE.APPROVED
      : true;

  const userStatus = [
    COMPANY_PEOPLE_USER_STATUS.ACTIVE,
    COMPANY_PEOPLE_USER_STATUS.PENDING,
    COMPANY_PEOPLE_USER_STATUS.SUBMITTED,
    COMPANY_PEOPLE_USER_STATUS.REJECTED,
    COMPANY_PEOPLE_USER_STATUS.FREEZED,
  ];

  const [file, setFile] = useState([]);
  const addressDetails =
    updatedEditUserFieldsValues?.permanentAddressAttributes ??
    selectedUser?.permanentAddress;

  const countries = useSelector(countriesSelector)?.map(
    (country) => country?.iso2Code
  );
  const initialFormValue = {
    title: {
      value: selectedUser?.name?.title ?? "",
      validate: { required: true },
    },
    firstName: {
      value: selectedUser?.name?.first ?? "",
      validate: { required: true, regex: REGEX_VALUE.alphaSpaceHyphen },
    },
    lastName: {
      value: selectedUser?.name?.last,
      validate: { required: true, regex: REGEX_VALUE.alphaNumericSpaceHyphen },
    },
    middleName: {
      value: selectedUser?.name?.middle,
      validate: { required: false, regex: REGEX_VALUE.alphaSpaceHyphen },
    },
    gender: {
      value: selectedUser?.gender,
      validate: {
        required: false,
      },
    },

    dob: {
      value: selectedUser?.dob,
      validate: { required: true },
    },

    phoneNumber: {
      value: `${selectedUser?.mobileNumber}`,
      validate: { required: true },
    },
    email: {
      value: selectedUser?.email,
      validate: { required: true },
    },
    role: {
      value: updatedEditUserFieldsValues?.role ?? initialNormalRole,
      validate: { required: true },
    },
    department: {
      value: isDepartmentChanged
        ? updatedEditUserFieldsValues?.department
        : selectedUser?.departmentId,
      validate: { required: true },
    },
    location: {
      value: isLocationChanged
        ? updatedEditUserFieldsValues?.location
        : selectedUser?.locationId,
      validate: { required: true },
    },
    customRoles: {
      value: "",
      validate: { required: false },
    },

    receiptForwarding: {
      value: selectedUser?.secondaryEmail,
      validate: { required: false, regex: REGEX_VALUE.email },
    },
    copilot: {
      value: "",
      validate: { required: false },
    },
    ...(userTags?.length
      ? userTags
          ?.map((tag) => {
            return {
              [`userTag${toCamelCase(tag?.name)}`]: {
                value:
                  selectedUser?.userTags?.find((tg) => tag.id === tg.tagId)
                    ?.customTextValue ?? "",
              },
            };
          })
          ?.reduce((acc, curr) => ({ ...acc, ...curr }), {})
      : {}),
  };

  const openAddAddressDetailsPage = () => {
    searchParams.append(
      [SLIDERS_SEARCH_PARAMS.company.people.addAddressDetails],
      true
    );

    setSearchParams(searchParams);
  };

  const editAddressDetailsPage = () => {
    searchParams.append(
      [SLIDERS_SEARCH_PARAMS.company.people.editAddressDetails],
      true
    );

    setSearchParams(searchParams);
  };
  const editBankDetailsCtaHandler = () => {
    searchParams.append(
      [SLIDERS_SEARCH_PARAMS.company.people.editBankDetails],
      true
    );
    searchParams.append(
      [SLIDERS_SEARCH_PARAMS.company.people.preAddBankDetails],
      true
    );

    // get selected vendor not human values (for prefill)
    dispatch(
      fetchAndSelectVendor({
        not_humanized: true,
        id: selectedUser?.vendorId,
      })
    );

    setSearchParams(searchParams);
  };

  const openAddBankDetailsPage = () => {
    searchParams.append(
      [SLIDERS_SEARCH_PARAMS.company.people.preAddBankDetails],
      true
    );

    setSearchParams(searchParams);
  };
  const handleClose = async () => {
    const action = await dispatch(
      fetchPeople({
        order_by_name: true,
        page: 1,
        status: userStatus,
        invitation_status: [INVITATION_STATUS.ACCEPTED],
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...convertFilters(appliedFilter),
      })
    );
    if (fetchPeople.fulfilled.match(action))
      searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.edit);
    setSearchParams(searchParams);
    resetUserFromData();
  };

  const resetUserFromData = () => {
    dispatch(setSelectedEditFormDetails({}));
    dispatch(setSelectedUserLocationChangeConfirmation(false));
    dispatch(setSelectedUserDepartmentChangeConfirmation(false));
    dispatch(setSelectedUserRoleChangeConfirmation(false));
    dispatch(setSelectedVendor(null));
    dispatch(clearBankDetailsForm());
  };

  const onSubmit = () => {
    const phoneInfo = getDialCodeAndNumberFromPhoneNumber(
      `${selectedUser?.mobileCountryCode}${values?.phoneNumber}`
    );
    const payload = {
      roles: [],
      copilots_attributes: [],
      department_id: "",
      location_id: "",
      user_roles_attributes: [],
      secondary_email: "",
      title: values?.title,
      first_name: values?.firstName,
      middle_name: values?.middleName,
      last_name: values?.lastName,
      gender: values?.gender,
      mobile_number: Number(phoneInfo?.number),
      mobile_country_code: phoneInfo?.dialCode,
      email: values?.email,
      dob: getDateInPattern(values?.dob, "dd-mm-yyyy"),
    };

    if (userTags?.length) {
      payload.user_tag_values_attributes = userTags?.map((tag) => {
        const tempTag = selectedUser?.userTags?.find(
          (ut) => ut.tagId === tag.id
        );
        return {
          id: tempTag?.id,
          tag_id: tag?.id,
          custom_text_value: values?.[`userTag${toCamelCase(tag.name)}`],
        };
      });
    }
    const matchedRoles = primaryRolesOptions.find(
      (normalRole) => normalRole.id === values?.role
    );

    payload.user_roles_attributes.push({
      id: null,
      role_id: matchedRoles?.id,
    });

    payload.department_id = values?.department;

    payload.location_id = values?.location;
    payload.secondary_email = values?.receiptForwarding;

    selectedCustomRolesOptions?.forEach((customOptionsId) => {
      customRoles?.forEach((customRole) => {
        if (customRole?.id === customOptionsId) {
          payload.user_roles_attributes.push({
            id: null,
            role_id: customRole?.id,
          });
        }
      });
    });

    initialCustomRolesIds.forEach((initialCustomRoleId, index) => {
      let isPresent = false;
      selectedCustomRolesOptions?.forEach((selectedCustomId) => {
        if (initialCustomRoleId === selectedCustomId) {
          isPresent = true;
        }
      });

      const initialCustomId = initialCustomRoles?.find(
        (data) => data?.roleId === initialCustomRoleId
      )?.id;

      if (!isPresent) {
        payload.user_roles_attributes.push({
          id: initialCustomId,
          role_id: initialCustomRoleId,
          _destroy: true,
        });
      }
    });

    selectedPeopleOptions.forEach((peopleOptionId) => {
      peopleOptions.forEach((people) => {
        if (people.id === peopleOptionId) {
          payload.copilots_attributes.push({
            assigned_user_id: people.id,
          });
        }
      });
    });

    initialCoPilotsIds.forEach((initialCopilotId, index) => {
      let isPresent = false;
      selectedPeopleOptions.forEach((selectedPeopleId) => {
        if (initialCopilotId === selectedPeopleId) {
          isPresent = true;
        }
      });

      if (!isPresent) {
        payload.copilots_attributes.push({
          assigned_user_id: initialCopilotId,
          id: initialCopilots[index]?.id,
          _destroy: true,
        });
      }
    });

    const updateAddressOnSuccess = () => {
      if (updatedEditUserFieldsValues?.permanentAddressAttributes)
        dispatch(
          updateAddressDetails({
            permanent_address_attributes:
              updatedEditUserFieldsValues?.permanentAddressAttributes,
            onSuccess: handleClose,
          })
        );
    };
    const onFinal = () => {
      dispatch(setIsFormSubmissionProgress(false));
    };
    dispatch(setIsFormSubmissionProgress(true));
    resetUserFromData();
    dispatch(
      updateUserDetails({
        payload,
        id: userIdParam,
        onFinal,
        onSuccess: updatedEditUserFieldsValues?.permanentAddressAttributes
          ? updateAddressOnSuccess
          : handleClose,
      })
    );
  };

  useEffect(() => {
    if (file[0]) {
      const form = new FormData();
      form.append("avatar", file[0]);
      dispatch(updateUserAvatar({ payload: form, id: userIdParam }));
    }
  }, [file]);

  const {
    handleChange,
    values,
    errors,
    _setValues: setValues,
    handleSubmit,
  } = useForm(initialFormValue, onSubmit);

  useEffect(() => {
    dispatch(fetchRoles());

    dispatch(fetchShallowDepartments());

    dispatch(fetchShallowLocations());

    dispatch(
      fetchAndSelectUser({
        userId: searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id),
      })
    );
    dispatch(fetchCountries());

    setOnClose((searchParam) => {
      if (!searchParam.includes(SLIDERS_SEARCH_PARAMS.company.people.edit)) {
        resetUserFromData();
      }
    });

    setOnBack(() => {
      dispatch(setSelectedVendor(null));
    });
  }, []);

  useEffect(() => {
    if (memberRoleId)
      dispatch(
        fetchPeopleShallow({
          status: USER_STATUS.ACTIVE,
          role: [memberRoleId],
        })
      );
  }, [memberRoleId]);

  useEffect(() => {
    const initialCustomRolesValues = updatedEditUserFieldsValues?.customRoles
      ? updatedEditUserFieldsValues?.customRoles
      : initialCustomRolesIds;

    const initialCopilotsValues = updatedEditUserFieldsValues?.copilot
      ? updatedEditUserFieldsValues?.copilot
      : initialCoPilotsIds;

    setSelectedCustomRolesOptions(initialCustomRolesValues || []);

    setSelectedPeopleOptions(initialCopilotsValues || []);
  }, [updatedEditUserFieldsValues]);

  function handleChangeDepartment(e) {
    if (values?.department !== e?.id) {
      dispatch(setSelectedEditFormDetails({ ...values, department: e?.id }));
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.people.switchDepartment,
        true
      );
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.people.switchDepartmentTo,
        e?.id
      );
      setSearchParams(searchParams);
      handleChange(e);
    }
  }

  function handleChangeLocation(e) {
    if (values?.location !== e?.id) {
      dispatch(setSelectedEditFormDetails({ ...values, location: e?.id }));
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.people.switchLocation,
        true
      );
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.people.switchLocationTo,
        e?.id
      );
      setSearchParams(searchParams);
      handleChange(e);
    }
  }

  function handleChangeRole(e) {
    if (values?.role !== e?.id) {
      dispatch(setSelectedEditFormDetails({ ...values, role: e?.id }));
      dispatch(setSelectedUserRoleChangeConfirmation(true));
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.people.switchRole,
        true
      );
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.people.switchRoleTo,
        e?.id
      );
      setSearchParams(searchParams);
    }

    handleChange(e);
  }

  function getEntityListArrayForCopilots() {
    const entityList = [];
    selectedPeopleOptions.forEach((peopleId) => {
      peopleOptions.forEach((people) => {
        if (people.id === peopleId) {
          entityList.push({ id: people.id, name: people.displayName });
        }
      });
    });
    return entityList;
  }

  function getEntityListArrayForCustomRoles() {
    const entityList = [];
    selectedCustomRolesOptions.forEach((customRoleId) => {
      customRoles.forEach((role) => {
        if (customRoleId === role.id) {
          entityList.push({ id: role.id, name: role.name });
        }
      });
    });
    return entityList;
  }

  function handleDeleteIconClickForCopilotsEntityList(id) {
    const selectedPeoplesIds = selectedPeopleOptions.filter(
      (peopleOption) => peopleOption !== id
    );
    setSelectedPeopleOptions(selectedPeoplesIds);
  }

  function handleDeleteIconClickForCustomRolesEntityList(id) {
    const selectedCustomRolesIds = selectedCustomRolesOptions.filter(
      (customRoleOption) => customRoleOption !== id
    );
    setSelectedCustomRolesOptions(selectedCustomRolesIds);
  }

  const getDepartmentorLocationorRoleNameFromList = (type, id) => {
    return type === EDIT_USER_DEPARTMENT_LOCATION_TITLES.DEPARTMENT
      ? selectedDepartments?.find((item) => item?.id === id)?.name
      : type === EDIT_USER_DEPARTMENT_LOCATION_TITLES.LOCATION
        ? selectedLocations?.find((item) => item?.id === id)?.name
        : selectedRoles?.find((item) => item?.id === id)?.name;
  };

  useEffect(() => {
    if (selectedUser?.vendorId && myProfile && !selectedVendor) {
      dispatch(
        fetchAndSelectVendor({
          not_humanized: true,
          id: selectedUser?.vendorId,
        })
      );
    }
  }, [selectedUser?.vendorId, myProfile, selectedVendor]);

  const bankDetailsArray = Object.entries(
    flattenObject({
      ...selectedVendor?.bankDetails,
      ...selectedVendor?.bankDetails?.bankAccount,
    })
  )
    ?.map(([k, v]) => ({
      key: k,
      value: v,
      label: ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP[k],
    }))
    ?.filter(({ label }) => !!label)
    ?.reverse(); // id is removed

  const addressDetailsArray =
    addressDetails &&
    Object?.entries(addressDetails ?? {})
      ?.map(([k, v]) => ({
        key: k,
        value: v,
        label: VP_ADDRESS_DETAILS_CONFIG_MAP[k],
      }))
      ?.filter(({ label }) => !!label);

  const updatedIcon = (text) => {
    return (
      <div className="flex items-center justify-between rounded-full">
        <Text translationKey={text} />
        <div className="bg-warning-100 py-1.5 px-2 rounded-md my-2">
          <Icon name="Repeat" className="text-warning-500" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="slider-content-core">
        <form onSubmit={handleSubmit} id="edit-user-form">
          <div className="flex flex-col mb-5 gap-9">
            {myProfile ? (
              <div className="flex items-center justify-between gap-8">
                <Text
                  translationKey="company.people.peopleMyProfileSlider.myProfileHeader"
                  classes="text-3xl font-bold"
                  refProp={ref}
                />
                <div>
                  <FileUpload
                    showPreview
                    accept={{
                      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
                    }}
                    variant="custom"
                    customComponent={
                      <div className="relative flex flex-col items-center justify-center gap-1 rounded-full cursor-pointer">
                        <Avatar
                          size="xl"
                          imgSrc={selectedUser?.avatarUrl}
                          name={selectedUser?.displayName}
                        />
                        <Text
                          translationKey="misc.edit"
                          classes="underline text-sm font-semibold text-primary-500"
                        />
                      </div>
                    }
                    previewCustomComponent={(props) => {
                      return (
                        <div className="flex flex-col items-center gap-1">
                          <Avatar
                            size="xl"
                            imgSrc={props?.file?.preview || props?.file?.url}
                            avatarColor={selectedUser?.colorCode}
                            name={selectedUser?.displayName}
                          />
                          <Text
                            translationKey="misc.edit"
                            classes="underline text-sm font-semibold text-primary-500"
                          />
                        </div>
                      );
                    }}
                    hideCustomButtonAfterUpload
                    replaceAfterUpload
                    files={file}
                    maxFiles={1}
                    previewChildClasses="w-12 h-12 rounded-full border-[1px] border-neutral-100 overflow-hidden bg-contain"
                    handleFileChange={(e) => {
                      setFile(e);
                    }}
                  />
                </div>
              </div>
            ) : (
              <Text
                translationKey={
                  isBlockedOrFreezed
                    ? "company.people.peopleMyProfileSlider.viewDetails"
                    : "company.people.peopleMyProfileSlider.editProfile"
                }
                classes="text-3xl font-bold"
                refProp={reff}
              />
            )}
            <div>
              <Text
                translationKey="company.people.peopleMyProfileSlider.personalDetails"
                classes="text-lg font-semibold"
              />

              <div className="flex items-center my-6 gap-7">
                <VpSelect
                  label="company.people.peopleMyProfileSlider.title"
                  name="title"
                  value={values?.title}
                  options={TITLE}
                  optionsDisplayKey="value"
                  error={errors?.title}
                  insideForm
                  valueKey="value"
                  handleChange={handleChange}
                  classes="w-1/4 mt-1.25 "
                  menuPosition="absolute"
                  disabled={isDisabled}
                />
                <div className="w-full mt-0.5">
                  <Input
                    label="company.people.peopleMyProfileSlider.firstName"
                    name="firstName"
                    value={values?.firstName}
                    error={errors?.firstName}
                    onChange={handleChange}
                    disabled={isDisabled}
                  />
                </div>
              </div>

              <div className="flex items-center my-6 gap-7">
                <div className="w-1/2">
                  <Input
                    label="company.people.peopleMyProfileSlider.middleName"
                    name="middleName"
                    value={values?.middleName}
                    error={errors?.middleName}
                    disabled={isDisabled}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-1/2">
                  <Input
                    label="company.people.peopleMyProfileSlider.lastName"
                    name="lastName"
                    value={values?.lastName}
                    error={errors?.lastName}
                    classes="text-base font-medium"
                    disabled={isDisabled}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex items-center my-8 gap-7 ">
                <VpSelect
                  label="company.people.peopleMyProfileSlider.gender"
                  name="gender"
                  insideForm
                  classes="w-1/2 mt-1.25"
                  menuPosition="absolute"
                  value={values?.gender}
                  options={GENDER}
                  optionsDisplayKey="label"
                  error={errors?.gender}
                  valueKey="value"
                  handleChange={handleChange}
                  disabled={isDisabled}
                />
                <div className="flex items-center w-1/2 text-sm">
                  <PhoneNumberInput
                    showEditIcon={myProfile}
                    value={`${selectedUser?.mobileCountryCode}${values?.phoneNumber}`}
                    error={errors?.phoneNumber}
                    handleChange={handleChange}
                    disabled={isDisabled}
                    disableDropdown={isDisabled}
                    disableInputField={isDisabled}
                    inputProps={{ name: "phoneNumber" }}
                    countriesList={countries}
                    insideForm
                    // labelClass="text-xs !text-neutral-400"
                    containerClasses="!-mt-2"
                  />
                </div>
              </div>

              <DateInputTextBox
                name="dob"
                label="DOB"
                disabled={isDisabled}
                isSelectable
                value={dateToString(values?.dob)}
                error={errors?.dob}
                handleChange={handleChange}
                // labelExtraClasses="text-xs"
                mode={DATEINPUT_MODES.DATE}
                dateInputExtraProp={{
                  maxDate,
                }}
                valueToShow={dateToString(values.dob, {
                  year: "numeric",
                })}
              />

              <div className="flex items-center my-6 gap-7 ">
                <div className="w-full">
                  <Input
                    name="email"
                    value={values?.email}
                    label="company.people.peopleMyProfileSlider.email"
                    error={errors?.email}
                    disabled
                  />
                </div>
              </div>

              <div className="flex items-center my-6 gap-7">
                {selectedUser?.role?.name === ROLES_TYPE.EXTERNAL_ACCOUNTANT ? (
                  <div className="w-full">
                    <Input
                      label="company.people.peopleMyProfileSlider.role"
                      name="role"
                      value={selectedUser?.role?.name}
                      error={errors?.role}
                      classes="text-base font-medium mt-1.25  "
                      disabled
                    />
                  </div>
                ) : (
                  <VpSelect
                    label="company.people.peopleMyProfileSlider.role"
                    name="role"
                    value={values?.role}
                    options={primaryRolesOptions.filter(
                      (role) => role.name !== ROLES_TYPE.EXTERNAL_ACCOUNTANT
                    )}
                    error={errors?.role}
                    optionsDisplayKey="name"
                    handleChange={(e) => {
                      handleChangeRole(e);
                    }}
                    valueKey="id"
                    classes="mt-1.25 w-full text-xs"
                    insideForm
                    menuPosition="absolute"
                    disabled={isBlockedOrFreezed || myProfileNotAdmin}
                    isOptionsLoading={isFetchingRoles}
                    helperText={
                      isRoleChanged ? (
                        <Text
                          translationKey={`This user's role will be switched from ${getDepartmentorLocationorRoleNameFromList(
                            "role",
                            selectedUser?.role?.id
                          )} to ${getDepartmentorLocationorRoleNameFromList(
                            "role",
                            values?.role
                          )}`}
                          classes="text-xs font-medium text-warning-500"
                        />
                      ) : null
                    }
                    customUIForOptionWhenDropdownClosed={
                      isRoleChanged ? updatedIcon : null
                    }
                  />
                )}
              </div>

              <div className="flex flex-col gap-6">
                <VpSelect
                  label="company.people.peopleMyProfileSlider.department"
                  name="department"
                  value={values?.department}
                  error={errors?.department}
                  options={selectedDepartments}
                  insideForm
                  optionsDisplayKey="name"
                  handleChange={(e) => {
                    handleChangeDepartment(e);
                  }}
                  isOptionsLoading={isFetchingDepartments}
                  valueKey="id"
                  classes="mt-1.25"
                  menuPosition="absolute"
                  helperText={
                    isDepartmentChanged ? (
                      <Text
                        translationKey={`This user's department will be switched from ${getDepartmentorLocationorRoleNameFromList(
                          "department",
                          selectedUser?.departmentId
                        )} to ${getDepartmentorLocationorRoleNameFromList(
                          "department",
                          values?.department
                        )}`}
                        classes="text-xs font-medium text-warning-500"
                      />
                    ) : null
                  }
                  disabled={isBlockedOrFreezed || myProfileNotAdmin}
                  customUIForOptionWhenDropdownClosed={
                    isDepartmentChanged ? updatedIcon : null
                  }
                />

                <VpSelect
                  label="company.people.peopleMyProfileSlider.location"
                  name="location"
                  value={values?.location}
                  error={errors?.location}
                  options={selectedLocations}
                  optionsDisplayKey="name"
                  handleChange={(e) => {
                    handleChangeLocation(e);
                  }}
                  isOptionsLoading={isFetchingLocations}
                  insideForm
                  valueKey="id"
                  classes="mt-1.25"
                  menuPosition="absolute"
                  helperText={
                    isLocationChanged ? (
                      <Text
                        translationKey={`This user's loaction will be switched from ${getDepartmentorLocationorRoleNameFromList(
                          "location",
                          selectedUser?.locationId
                        )} to ${getDepartmentorLocationorRoleNameFromList(
                          "location",
                          values?.location
                        )}`}
                        classes="text-xs font-medium text-warning-500"
                      />
                    ) : null
                  }
                  disabled={isBlockedOrFreezed || myProfileNotAdmin}
                  customUIForOptionWhenDropdownClosed={
                    isLocationChanged ? updatedIcon : null
                  }
                />
              </div>
            </div>

            <div className="flex gap-7">
              {userTags.map((tag) => (
                <div className="w-1/2" key={tag.id}>
                  <Input
                    name={`userTag${toCamelCase(tag.name)}`}
                    value={values?.[`userTag${toCamelCase(tag.name)}`]}
                    onChange={handleChange}
                    label={tag.name}
                    classes="text-base font-medium"
                  />
                </div>
              ))}
            </div>

            <div className="flex flex-col font-semibold">
              <Text
                translationKey="company.people.peopleMyProfileSlider.addressDetails"
                classes="text-lg"
              />
              <Text
                translationKey="company.people.peopleMyProfileSlider.addressDetailsDesc"
                classes="text-xs text-neutral-500"
              />
              {addressDetails ? (
                <>
                  <div className="flex flex-col p-4 mt-5 border rounded-lg border-neutral-200">
                    {Object.entries(addressDetailsArray).map(
                      ([k, { key = "", label = "", value = "" }]) => {
                        return (
                          <div
                            className="flex items-center justify-between py-4 accordion-row"
                            key={`address-detail-key-${key}`}
                          >
                            <Text
                              translationKey={label}
                              classes="text-sm text-neutral-500"
                            />
                            <Text
                              translationKey={value}
                              classes="text-sm font-medium tracking-wider text-neutral-800 text-right"
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                  {myProfile && !isExternalAccountant ? (
                    <div className="flex justify-end">
                      <Button
                        className="text-primary-600 mt-4 border border-neutral-300 w-fit py-1.5 px-5 text-sm font-medium rounded-lg flex items-center gap-2 cursor-pointer"
                        onClick={editAddressDetailsPage}
                        label="company.people.peopleMyProfileSlider.editAddressDetails"
                        preIcon="Edit"
                        iconClasses="p-1 -mr-2"
                        innerClasses="gap-1 font-bold text-xs"
                      />
                    </div>
                  ) : null}
                </>
              ) : myProfile ? (
                <Button
                  className="text-primary-600 mt-4 border border-neutral-300 w-fit py-1.5 px-5 text-sm font-medium rounded-lg flex items-center gap-2 cursor-pointer"
                  onClick={openAddAddressDetailsPage}
                  label="company.people.peopleMyProfileSlider.addAddressDetails"
                  preIcon="Add"
                  iconClasses=" -mr-2"
                  innerClasses="gap-1 font-bold text-xs"
                />
              ) : null}
            </div>
            {myProfile &&
            !isExternalAccountant &&
            availableModules?.includes(MODULES.REIMBURSEMENTS) ? (
              <div className="flex flex-col font-semibold">
                <Text
                  translationKey="company.people.peopleMyProfileSlider.personalBankDetails"
                  classes="text-lg"
                />
                <Text
                  translationKey="company.people.peopleMyProfileSlider.personalBankDetailsDesc"
                  classes="text-xs text-neutral-500"
                />
                {bankDetailsForm ? (
                  <>
                    <div className="flex flex-col p-4 mt-5 border rounded-lg border-neutral-200">
                      {Object.entries(bankDetailsArray).map(
                        ([key, { label = "", value = "" }]) => {
                          return (
                            <div
                              className="flex items-center justify-between py-4 accordion-row"
                              key={`bank-detail-key-${key}`}
                            >
                              <span className="text-sm text-neutral-500">
                                <Text translationKey={label} />
                              </span>
                              <span className="w-[60%] max-w-md break-words whitespace-normal text-sm font-medium tracking-wider text-right text-neutral-800">
                                {value}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="flex justify-end">
                      <Button
                        className="text-primary-600 mt-4 border border-neutral-300 w-fit py-1.5 px-5 text-sm font-medium rounded-lg flex items-center gap-2 cursor-pointer"
                        onClick={editBankDetailsCtaHandler}
                        label="company.people.peopleMyProfileSlider.edit"
                        preIcon="Edit"
                        iconClasses=" p-1 -mr-2"
                        innerClasses="gap-1 font-bold text-xs"
                      />
                    </div>
                  </>
                ) : (
                  <Button
                    className="text-primary-600 mt-4 border border-neutral-300 w-fit py-1.5 px-5 text-sm font-medium rounded-lg flex items-center gap-2 cursor-pointer"
                    onClick={openAddBankDetailsPage}
                    //   disabled={addBankDetailsDisabled}
                    label="billPay.vendors.createVendor.addBankDetails"
                    preIcon="Add"
                    iconClasses="-mr-2"
                    innerClasses="gap-1 font-bold text-xs"
                  />
                )}
              </div>
            ) : selectedUser?.role?.name === ROLES_TYPE.MEMBER ? (
              <div
                className={`flex flex-col gap-5 font-medium text-neutral-800 ${
                  isBlockedOrFreezed ? "!opacity-50 !pointer-events-none" : ""
                }`}
              >
                <div className="flex flex-col font-semibold">
                  <Text
                    translationKey="company.people.peopleMyProfileSlider.customRoles"
                    classes="text-lg"
                  />
                  <Text
                    translationKey="company.people.peopleMyProfileSlider.customRolesDesc"
                    classes="text-xs text-neutral-500"
                  />
                </div>

                <CheckboxDropdown
                  label="company.people.peopleMyProfileSlider.customRolesLabel"
                  selectedIdsArray={[...selectedCustomRolesOptions]}
                  handleSubmit={(val) => {
                    setValues({
                      ...values,
                      customRoles: val.selectedIds ?? [],
                    });
                    setSelectedCustomRolesOptions(val.selectedIds ?? []);
                  }}
                  options={customRoles?.map((role) => ({
                    id: role.id,
                    label: role.name,
                  }))}
                  disabled={isBlockedOrFreezed}
                />
                <EntityListWithDelete
                  title="company.people.peopleMyProfileSlider.listOfCustomRoles"
                  isCustomRole
                  list={getEntityListArrayForCustomRoles()}
                  handleDeleteIconClick={
                    handleDeleteIconClickForCustomRolesEntityList
                  }
                />
              </div>
            ) : null}
            <div
              className={`flex flex-col gap-6 ${
                isBlockedOrFreezed ? "!opacity-50 !pointer-events-none" : ""
              }`}
            >
              <div className="flex flex-col font-semibold">
                <Text
                  translationKey="company.people.peopleMyProfileSlider.copilot"
                  classes="text-lg"
                />
                <Text
                  translationKey="company.people.peopleMyProfileSlider.copilotDesc"
                  classes="text-xs text-neutral-500"
                />
              </div>
              <div className="font-medium text-neutral-800">
                <CheckboxDropdown
                  label="company.people.peopleMyProfileSlider.copilot"
                  selectedIdsArray={[...selectedPeopleOptions]}
                  handleSubmit={(val) => {
                    setValues({
                      ...values,
                      copilot: val.selectedIds ?? [],
                    });
                    setSelectedPeopleOptions(val.selectedIds ?? []);
                  }}
                  options={peopleOptions?.map((people) => ({
                    ...people,
                    label: people.displayName,
                  }))}
                  disabled={isBlockedOrFreezed}
                />
                <EntityListWithDelete
                  title="company.people.peopleMyProfileSlider.listOfCopilots"
                  list={getEntityListArrayForCopilots()}
                  handleDeleteIconClick={
                    handleDeleteIconClickForCopilotsEntityList
                  }
                />
              </div>
            </div>
            {/* {myProfile ? (
              <div className="flex flex-col gap-6">
                <div className="flex flex-col font-semibold">
                  <Text
                    translationKey="company.people.peopleMyProfileSlider.receiptForwarding"
                    classes="text-lg"
                  />
                  <Text
                    translationKey="company.people.peopleMyProfileSlider.receiptForwardingDesc"
                    classes="text-xs text-neutral-500"
                  />
                </div>
                <div className="font-medium text-neutral-800">
                  <Input
                    name="receiptForwarding"
                    onChange={handleChange}
                    value={values?.receiptForwarding}
                    label="company.people.peopleMyProfileSlider.email"
                    error={errors?.receiptForwarding}
                    labelExtraClasses="text-xs "
                    disabled={isBlockedOrFreezed}
                  />
                </div>
              </div>
            ) : null} */}
          </div>
        </form>
      </div>
      {!isBlockedOrFreezed ? (
        <div className="flex justify-end gap-5 px-3 py-5 text-xl slider-footer">
          <Button
            variant="tertiary"
            classes="text-neutral-500 px-5 py-4  w-15.5"
            label="company.people.peopleMyProfileSlider.cancel"
            onClick={handleClose}
          />
          <Button
            variant="primary"
            classes="px-5 py-4 w-15.5"
            label="company.people.peopleMyProfileSlider.save"
            btnType="submit"
            form="edit-user-form"
          />
        </div>
      ) : null}
    </>
  );
}

EditUser.propTypes = {
  setOnClose: PropTypes.func,
  setOnBack: PropTypes.func,
};
