import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchBulkUploadForPayrollTableList,
  resetBulkUploadTableInfo,
} from "@/store/reducers/payments";
import {
  PURCHASE_BILLS_SLICE_ATTRIBUTE_KEY,
  fetchPurchaseBills,
  setPurchaseBill,
  setPurchaseBillsIntialState,
} from "@/store/reducers/purchase-bills";

import { subdomainSelector } from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import {
  bulkUploadTableHasMoreSelector,
  bulkUploadTableListSelector,
  bulkUploadTableTotalCountSelector,
  bulkUploadTableisFetchingSelector,
  bulkUplodFileRecordIdSelector,
  invoiceInboxPageFilterKeysSelector,
  payrollJobStatusForBulkUploadSelector,
} from "@/store/selectors/payments";
import {
  isProcessingCountSelector,
  isPurchaseBillsFetchingSelector,
  purchaseBillsHasMoreSelector,
  purchaseBillsListSelector,
  purchaseBillsTotalSelector,
} from "@/store/selectors/purchase-bills";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import CopytoClipboard from "@/components/core/CopyToClipboard";
import Icon from "@/components/core/Icon";
import Popup from "@/components/core/Popup";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import VpLink from "@/components/core/vp-link";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import InvoiceLoader from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/InvoiceLoader";
import { getInvoiceBoxTableDefaultHeader } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/tableConfig";
import BillPayFilters from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/BillPayFilters";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import { convertFilters } from "@/utils/filters";
import { handleExport } from "@/utils/exports";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  BILLPAY_BILL_CTA_KEYS,
  INVOICE_INBOX_LISTING_PARAMS,
  PAYROLL_BULK_UPLOAD_SLIDER,
  PAYROLL_INBOX_CTA_CONFIG,
  PAYROLL_INBOX_CTA_KEYS,
} from "@/utils/constants/payments";
import {
  allowedActionsForCurrentPage,
  checkIfUserActionAllowed,
} from "@/utils/common";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { EXPORT_PAGE } from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

export default function BillpayAndPayrollInvoiceInbox({
  page = BILL_PAYROLL_CONTEXT.BILLPAY,
  openSliderHandler,
  inPageSlider,
  externalClasses,
  tableSectionClasses,
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {});
  const [showMenu, setShowMenu] = useState(false);
  const handleToggle = (e) => {
    setShowMenu((prev) => !prev);
  };
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isBillpayContext = page === BILL_PAYROLL_CONTEXT.BILLPAY;
  const isPayrollContextSlider =
    page === BILL_PAYROLL_CONTEXT.PAYROLL &&
    inPageSlider === PAYROLL_BULK_UPLOAD_SLIDER;

  // BE accepts only one sort key at a time
  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  const addVendorOrEmployeeButtonHandler = () => {
    setShowMenu(false);
    if (isBillpayContext) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.vendors.create, true);
    } else {
      searchParams.append(SLIDERS_SEARCH_PARAMS.employees.create, true);
    }
    setSearchParams(searchParams);
  };
  const billpayUploadQueryParams = {
    [isBillpayContext
      ? SLIDERS_SEARCH_PARAMS.payments.createBillDetails
      : SLIDERS_SEARCH_PARAMS.payrollPayments.createSalary]: true,
    //
    ...(isBillpayContext
      ? {
          [SLIDERS_SEARCH_PARAMS.payments.showOCRModal]: true,
          [SLIDERS_SEARCH_PARAMS.payments.ocrFetched]: false, // status of OCR, has run or not
        }
      : {}),
    //
    [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
      SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
    [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]:
      isBillpayContext
        ? SLIDERS_SEARCH_PARAMS.payments.createBillDetails
        : SLIDERS_SEARCH_PARAMS.payrollPayments.createSalary,
  };

  const createBilOrPaymentButtonHandler = () => {
    setShowMenu(false);
    Object.entries(billpayUploadQueryParams).forEach(([k, v]) => {
      searchParams.append(k, v);
    });
    setSearchParams(searchParams);
  };

  const dispatch = useDispatch();
  const currentSubdomain = useSelector(subdomainSelector);
  const invoiceHelpEmail = `bill+${currentSubdomain}@volopay.co`;

  const [filesUploaded, setFilesUploade] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const {
    hasMoreSelector,
    isFetchingSelector,
    totalCountsSelector,
    listSelector,
  } = getPaginationSelectors();

  const purchaseBills = useSelector(listSelector);

  const fileRecordIdValue = useSelector(bulkUplodFileRecordIdSelector);

  const isProcessingCount = useSelector(isProcessingCountSelector);

  const isFetching = useSelector(isFetchingSelector);

  const isEmpty = purchaseBills.length === 0;

  const hasMore = useSelector(hasMoreSelector);

  const totalPurchaseBills = useSelector(totalCountsSelector);

  const filters = useSelector(invoiceInboxPageFilterKeysSelector);

  const appliedFilters = useSelector(appliedFilterSelector);

  let ctas = allowedActionsForCurrentPage(allNavUrls, userAllowedActions);

  if (ctas) {
    ctas = ctas.filter(
      (key) =>
        ![
          PAYROLL_INBOX_CTA_KEYS.createSalaryPayment,
          BILLPAY_BILL_CTA_KEYS.createBill,
          USER_ALLOWED_CTA_KEYS.UPLOAD_INVOICE,
        ].includes(key)
    );
  }

  const bulkUploadJobStatusInfo = useSelector(
    payrollJobStatusForBulkUploadSelector
  );

  const failedRows =
    typeof bulkUploadJobStatusInfo?.failedRows === typeof ""
      ? []
      : bulkUploadJobStatusInfo?.failedRows;
  const successRows = bulkUploadJobStatusInfo?.successRows;
  const totalRows = bulkUploadJobStatusInfo?.totalRows;
  const triggerGetBulkUploadTableInfoFunc =
    (failedRows?.length ?? 0) + successRows === totalRows;

  const [selectedPurchaseBills, setSelectedPurchaseBills] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);

  const uploadBillPayInvoiceEnabled = checkIfUserActionAllowed(
    allNavUrls,
    userAllowedActions,
    USER_ALLOWED_CTA_KEYS.UPLOAD_INVOICE
  );

  const payrollBulkUploadEnabled = checkIfUserActionAllowed(
    allNavUrls,
    userAllowedActions,
    PAYROLL_INBOX_CTA_KEYS.bulkUpload
  );

  const getNewInvoiceUploadText = (haveLink = false) => {
    return (
      <div
        className={`text-neutral-500 flex items-center font-medium text-sm ${
          haveLink ? " text-left mt-5" : "text-center"
        } `}
      >
        <div>
          <Text
            classes="text-neutral-800 font-semibold "
            translationKey="billPay.bill.invoiceInbox.newInvoice"
          />{" "}
          {uploadBillPayInvoiceEnabled ? (
            <>
              {haveLink ? (
                <VpLink
                  text="upload"
                  onClickHandler={(val) => onUploadHandler(val, true)}
                />
              ) : (
                <Text translationKey="upload" />
              )}{" "}
              <Text
                classes="inline-block"
                translationKey="billPay.bill.invoiceInbox.dropStatement1"
              />{" "}
            </>
          ) : null}
          <Text
            classes="inline-block"
            translationKey="billPay.bill.invoiceInbox.dropStatement2"
          />{" "}
          {!haveLink ? (
            <div className="flex items-center justify-center">
              <Text
                classes="text-neutral-800 font-semibold"
                translationKey={invoiceHelpEmail}
              />
              <CopytoClipboard text={invoiceHelpEmail} classes="ml-1" />
            </div>
          ) : (
            <Text
              classes="text-neutral-800 font-semibold"
              translationKey={invoiceHelpEmail}
            />
          )}
        </div>
        {haveLink ? (
          <CopytoClipboard text={invoiceHelpEmail} classes="ml-1" />
        ) : null}
      </div>
    );
  };
  const getNewPayrollInvoiceUploadText = (haveLink = false) =>
    inPageSlider !== PAYROLL_BULK_UPLOAD_SLIDER ? (
      <div
        className={`text-neutral-500 flex items-center font-medium text-sm ${
          payrollBulkUploadEnabled ? "w-11/12" : ""
        } ${haveLink ? " text-left mt-5" : "text-center"} `}
      >
        <div>
          <Text translationKey="payroll.salaryPayment.payrollInbox.noSalaryPayments" />{" "}
          {payrollBulkUploadEnabled ? (
            <Text translationKey="payroll.salaryPayment.payrollInbox.uploadDraftsPrompt" />
          ) : null}
        </div>
        {haveLink ? (
          <CopytoClipboard text={invoiceHelpEmail} classes="ml-1" />
        ) : null}
      </div>
    ) : null;
  function openBulkUploadSlider() {
    setShowMenu(false);
    searchParams.set(
      SLIDERS_SEARCH_PARAMS.payrollPayments.bulkUploadPayroll,
      true
    );
    setSearchParams(searchParams);
  }
  const getEmptyChildrenNode = () => (
    <div className="flex flex-col items-center justify-center w-full gap-6">
      {isBillpayContext
        ? getNewInvoiceUploadText()
        : getNewPayrollInvoiceUploadText()}
      {isBillpayContext && uploadBillPayInvoiceEnabled ? (
        <Button
          label="fileUpload.uploadFiles"
          variant="secondary"
          onClick={(val) => onUploadHandler(val, true)}
        />
      ) : null}

      {!isBillpayContext &&
      inPageSlider !== PAYROLL_BULK_UPLOAD_SLIDER &&
      payrollBulkUploadEnabled ? (
        <Button
          label="Bulk Upload"
          variant="secondary"
          onClick={openBulkUploadSlider}
        />
      ) : null}
    </div>
  );

  const onUploadHandler = (latestFileArray, _isBillpayContext) => {
    if (_isBillpayContext) {
      Object.entries(billpayUploadQueryParams).forEach(([k, v]) => {
        searchParams.append(k, v);
      });
      setSearchParams(searchParams);
    }
  };

  const filterOptions = convertFilters(appliedFilters);

  const onReset = () => {
    const resetDispatcherFunc = isPayrollContextSlider
      ? resetBulkUploadTableInfo
      : setPurchaseBillsIntialState;

    dispatch(resetDispatcherFunc());
  };

  const loadMore = () => {
    const triggerApi = isPayrollContextSlider ? !!fileRecordIdValue : true;

    const value = {
      page: pageNum,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      ...filterOptions,
      // TODO: BE sort is down jan 18, uncomment this later
      // [SORT_KEYS.COLUMN]: sorting?.category,
      // [SORT_KEYS.DIRECTION]: sorting?.type,
    };

    if (isPayrollContextSlider) {
      value.file_record_id = fileRecordIdValue;
    }

    const dispatcherFunction = !isPayrollContextSlider
      ? fetchPurchaseBills
      : fetchBulkUploadForPayrollTableList;

    if (triggerApi) {
      dispatch(
        dispatcherFunction({
          key: PURCHASE_BILLS_SLICE_ATTRIBUTE_KEY,
          value,
          context: page,
        })
      );
    }
  };

  const requiredPaginationProps = getUsePaginationProps();

  const [pageNum, setPageNum] = usePagination(requiredPaginationProps);

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  //
  function getProcessingRow() {
    return (
      <tr id="dataInvoiceInbox" className="bg-neutral-200">
        {[...new Array(6)].map((val, index) =>
          index === 0 ? (
            <td
              key={`processing-row-${index}`}
              className="text-sm font-medium text-warning-500"
            >
              <div className="flex items-center gap-2">
                <Icon name="Schedule" />
                {`${t(
                  "billPay.bill.invoiceInbox.processing"
                )} ${isProcessingCount} ${t(
                  "billPay.bill.invoiceInbox.invoices"
                )}`}
              </div>
            </td>
          ) : (
            <td
              key={`processing-row-${index}`}
              aria-label="render-empty-space"
            />
          )
        )}
      </tr>
    );
  }

  // multi-select

  const headerSelected = headerSelectAll; // all selected or else unchecked

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedPurchaseBills(
          purchaseBills.map((purchaseBill) => purchaseBill?.id)
        );
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedPurchaseBills([]);
    }
  };

  const rowSelectionHandler = (purchaseBill) => {
    if (selectedPurchaseBills?.includes(purchaseBill?.id)) {
      setSelectedPurchaseBills((prev) =>
        prev.filter((id) => id !== purchaseBill?.id)
      );
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, purchaseBill?.id]);
      }
    } else {
      setSelectedPurchaseBills((prev) => [...prev, purchaseBill?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== purchaseBill?.id));
    }

    dispatch(
      setPurchaseBill({
        id: purchaseBill?.id,
        value: {
          ...purchaseBill,
          selected: !purchaseBill?.selected,
        },
      })
    );
  };

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);
      if (!deSelectedIds.length) {
        setSelectedPurchaseBills(purchaseBills?.map((data) => data.id));
      } else {
        const filteredIds = purchaseBills
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedPurchaseBills(filteredIds);
      }
    }
  }, [bulkSelectAll, purchaseBills, deSelectedIds]);

  useEffect(() => {
    // When user selects checkbox from header
    setHeaderSelectAll(
      (selectedPurchaseBills.length ===
        pageNum * PAGINATION_PER_REQUEST_LIMIT ||
        selectedPurchaseBills.length === purchaseBills?.length) &&
        !isEmpty
    );
  }, [selectedPurchaseBills, pageNum, purchaseBills]);

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
    emptyDataTitle,
  } = getInvoiceBoxTableDefaultHeader(
    page,
    isPayrollContextSlider
      ? { inPageSlider, fileRecordIdValue, bulkUploadJobStatusInfo }
      : {}
  );

  function getUsePaginationProps() {
    const commonProps = {
      initialPageNum: 1,
      hasMore,
      onReset,
      loadMore,
    };

    if (isPayrollContextSlider) {
      return {
        ...commonProps,
        filterOptions: { triggerGetBulkUploadTableInfoFunc },
      };
    }

    return {
      ...commonProps,
      filterOptions: {
        ...filterOptions,
        ...sorting,
      },
    };
  }

  function getPaginationSelectors() {
    if (isPayrollContextSlider) {
      return {
        hasMoreSelector: bulkUploadTableHasMoreSelector,
        isFetchingSelector: bulkUploadTableisFetchingSelector,
        totalCountsSelector: bulkUploadTableTotalCountSelector,
        listSelector: bulkUploadTableListSelector,
      };
    }

    return {
      hasMoreSelector: purchaseBillsHasMoreSelector,
      isFetchingSelector: isPurchaseBillsFetchingSelector,
      totalCountsSelector: purchaseBillsTotalSelector,
      listSelector: purchaseBillsListSelector,
    };
  }

  return (
    <div
      className={` ${externalClasses} ${
        !externalClasses?.includes("mx-") && "ml-11 mr-11.5"
      }`}
    >
      {!(
        page === BILL_PAYROLL_CONTEXT.PAYROLL &&
        inPageSlider === PAYROLL_BULK_UPLOAD_SLIDER
      ) ? (
        <div className="flex items-center justify-between ">
          <div>
            <div className="breadcrumb-text">
              <Text
                translationKey={
                  isBillpayContext ? "billPay.title" : "payroll.title"
                }
              />
              &nbsp;/&nbsp;
              <Text
                translationKey={
                  isBillpayContext
                    ? "billPay.bill.title"
                    : "payroll.salaryPayment.title"
                }
              />
            </div>
            <Text
              classes="text-4xl font-extrabold"
              translationKey={
                isBillpayContext
                  ? "billPay.bill.invoiceInbox.title"
                  : "payroll.salaryPayment.payrollInbox.title"
              }
            />
          </div>
          <div className="flex items-center gap-5">
            {/* Kebab menu, and its options */}
            {ctas?.length > 0 ? (
              <button
                type="button"
                onBlur={() => {
                  setShowMenu(false);
                }}
                ref={setReferenceElement}
                onClick={(e) => handleToggle(e)}
                className="relative border cursor-pointer border-neutral-300 p-4.5 rounded-md"
              >
                <span>
                  <Icon name="MoreVerticle" className="text-neutral-500" />
                </span>
              </button>
            ) : null}

            {checkIfUserActionAllowed(
              allNavUrls,
              userAllowedActions,
              isBillpayContext
                ? BILLPAY_BILL_CTA_KEYS.createBill
                : PAYROLL_INBOX_CTA_KEYS.createSalaryPayment
            ) ? (
              <Button
                preIcon="Add"
                onClick={createBilOrPaymentButtonHandler}
                label={
                  isBillpayContext
                    ? "billPay.bill.invoiceInbox.createBill.buttonText"
                    : "payroll.salaryPayment.payrollInbox.createSalaryPayment.buttonText"
                }
                labelStyleClasses="font-semibold text-btn-lg"
                classes="px-5 py-4"
                iconClasses="w-6 h-6"
                compact
              />
            ) : null}
          </div>
        </div>
      ) : null}

      <div className={`${tableSectionClasses ? tableDataRowClasses : "pt-8"} `}>
        {!(
          page === BILL_PAYROLL_CONTEXT.PAYROLL &&
          inPageSlider === PAYROLL_BULK_UPLOAD_SLIDER
        ) ? (
          <BillPayFilters
            filters={filters}
            page={
              page === BILL_PAYROLL_CONTEXT.BILLPAY ? "PurchaseBill" : "Payroll"
            }
            tab={INVOICE_INBOX_LISTING_PARAMS.status}
          />
        ) : null}

        <div>
          <div className="mb-6 -mt-4">
            {!isFetching && purchaseBills?.length !== 0 && isBillpayContext
              ? getNewInvoiceUploadText(true)
              : null}
          </div>
          <Table
            {...{
              ...tableSetting,
              emptyDataTitle,
              emptyDataChildren: getEmptyChildrenNode(),
            }}
            bulkApproveVisible={selectedPurchaseBills?.length > 0}
            bulkApproveHeight="100px"
            bulkApproveContent={
              <BulkActionComponent
                selectedRows={
                  deSelectedIds.length
                    ? totalPurchaseBills - deSelectedIds.length
                    : selectedPurchaseBills?.length
                }
                totalRows={totalPurchaseBills}
                descriptionText="common.exportDesc"
                bulkApproveSelectAll={
                  (bulkSelectAll && !deSelectedIds.length) ||
                  selectedPurchaseBills?.length === totalPurchaseBills
                }
                showSelectAllButton={headerSelectAll}
                selectAllHandler={(val) => {
                  setDeSelectedIds([]);
                  setBulkSelectAll(val);
                }}
                handleExport={() => {
                  handleExport(
                    false,
                    selectedPurchaseBills?.length,
                    appliedFilters,
                    dispatch,
                    {
                      export_type:
                        page === BILL_PAYROLL_CONTEXT.PAYROLL
                          ? EXPORT_PAGE.PAYROLL
                          : EXPORT_PAGE.PURCHASE_BILL,

                      ...(bulkSelectAll
                        ? page === BILL_PAYROLL_CONTEXT.PAYROLL
                          ? {
                              payroll: true,
                              status: INVOICE_INBOX_LISTING_PARAMS.status,
                              deselected_ids: deSelectedIds,
                            }
                          : {
                              status: INVOICE_INBOX_LISTING_PARAMS.status,
                              deselected_ids: deSelectedIds,
                            }
                        : { selected_ids: selectedPurchaseBills }),
                    }
                  );
                }}
                showExport
                clearSelection={() => {
                  setBulkSelectAll(false);
                  setHeaderSelectAll(false);
                  setSelectedPurchaseBills([]);
                  setDeSelectedIds([]);
                  dispatch(
                    setPurchaseBill(
                      purchaseBills?.map((purchaseBill) => {
                        return {
                          ...purchaseBill,
                          selected: !purchaseBill?.selected,
                        };
                      })
                    )
                  );
                }}
              />
            }
          >
            <tr className={tableHeadingRowClasses}>
              {tableHeading.map((headVal, index) => (
                <th className={headVal.classes} key={headVal?.id}>
                  <HeaderCell
                    val={{
                      ...headVal,
                      onCheckboxClick: headerSelectionHandler,
                      checkedValue: headerSelected && !isEmpty,
                      showCheckbox:
                        index === 0 &&
                        !isFetching &&
                        !(
                          page === BILL_PAYROLL_CONTEXT.PAYROLL &&
                          inPageSlider === PAYROLL_BULK_UPLOAD_SLIDER
                        ),
                      sorting,
                      setSorting,
                      disabled: isEmpty,
                    }}
                  />
                </th>
              ))}
            </tr>
            {/* processing row */}

            {filesUploaded.length !== 0 && isProcessing
              ? getProcessingRow()
              : null}

            {(!isFetching || !isEmpty) &&
              purchaseBills?.map((val, index, arr) => (
                <tr
                  key={val?.id}
                  className={`${tableDataRowClasses}`}
                  ref={(ref) => {
                    if (index === arr.length - 1) handleRefChange(ref);
                  }}
                  onClick={() => openSliderHandler(val?.id)}
                >
                  {tableHeading.map(
                    ({ cellComponent: Component, classes, id, ...rest }) => (
                      <td
                        className={`${classes} ${
                          selectedPurchaseBills?.includes(val)
                            ? "selected-row-cell"
                            : ""
                        }`}
                        key={id}
                      >
                        <Component
                          val={{
                            ...val,
                            tabType: "",
                            showCheckbox: !inPageSlider,
                            onCheckboxClick: () => rowSelectionHandler(val),
                            checkedValue: selectedPurchaseBills?.includes(
                              val?.id
                            ),
                            context: page,
                          }}
                          key={id}
                          {...rest}
                        />
                      </td>
                    )
                  )}
                </tr>
              ))}
            {isFetching ? <InvoiceLoader /> : null}
          </Table>
        </div>
      </div>
      {showMenu ? (
        <Popup
          keepTooltipInside="#tooltip-inside"
          containerClasses=" pl-0 pr-0 mr-20 mt-2"
          styles={styles.popper}
          attr={{ ...attributes.popper }}
          popupRef={setPopperElement}
        >
          <div className="flex flex-col ">
            {ctas.map((ctaKey) => {
              const config = PAYROLL_INBOX_CTA_CONFIG[ctaKey];
              // provided to CTA handler
              const componentProvidedStuff = {
                dispatch,
                searchParams,
                setSearchParams,
              };
              if (!config.customComponent) {
                return (
                  <Button
                    key={ctaKey}
                    variant="tertiary"
                    classes="border-0 w-18 text-neutral-500  flex justify-start text-left pl-3 rounded-none "
                    label={config.label}
                    onClick={config.onClick(null, componentProvidedStuff)}
                    textAlignment="start"
                  />
                );
              }

              if (ctaKey === PAYROLL_INBOX_CTA_KEYS.bulkUpload) {
                return (
                  <Button
                    key={ctaKey}
                    label={config.label}
                    onClick={openBulkUploadSlider}
                    variant="tertiary"
                    classes="border-0  text-neutral-500  flex justify-start text-left pl-3 rounded-none "
                    textAlignment="start"
                  />
                );
              }
              if (ctaKey === PAYROLL_INBOX_CTA_KEYS.create) {
                return (
                  <Button
                    key={ctaKey}
                    variant="tertiary"
                    classes="border-0  text-neutral-500  flex justify-start text-left pl-3 rounded-none "
                    label={config.label}
                    onClick={addVendorOrEmployeeButtonHandler}
                    textAlignment="start"
                  />
                );
              }
            })}
          </div>
        </Popup>
      ) : null}
    </div>
  );
}

BillpayAndPayrollInvoiceInbox.propTypes = {
  page: PropTypes.string,
  openSliderHandler: PropTypes.func,
  inPageSlider: PropTypes.string,
  externalClasses: PropTypes.string,
  tableSectionClasses: PropTypes.string,
};
