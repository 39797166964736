import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { resetPaginationActionCentre } from "@/store/reducers/actionCentre";

import {
  isFetchingClientDetailsSelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";

import ReimbursementApprovalList from "@/components/Reimbursement/ApprovalList";
import { PAGE_TYPE } from "@/utils/constants/reimbursement";

import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function ActionCentreReimburshmentTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPaginationActionCentre());
  }, []);

  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);
  const isFetchingReportEnabledData = useSelector(
    isFetchingClientDetailsSelector
  );

  const pageType = reportEnabled
    ? "myVolopay.actionCentre.reimbursementReportKey"
    : "myVolopay.actionCentre.reimbursementKey";

  const onClickHandler = (approvalId) => {
    if (reportEnabled) {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.reports.reportId]: approvalId,
        [SLIDERS_SEARCH_PARAMS.reimbursements.pageType]: PAGE_TYPE[pageType],
      });
    } else {
      setSearchParams({
        [SLIDERS_SEARCH_PARAMS.reimbursements.approveId]: approvalId,
        [SLIDERS_SEARCH_PARAMS.reimbursements.pageType]: PAGE_TYPE[pageType],
      });
    }
  };

  return (
    !isFetchingReportEnabledData && (
      <ReimbursementApprovalList
        isActionCentrePage
        pageType={pageType}
        onClickHandler={(approvalId) => {
          onClickHandler(approvalId);
        }}
      />
    )
  );
}
