import { createSelector } from "@reduxjs/toolkit";

export const fileViewersStore = (store) => store.fileViewer;

export const currentPageNumberSelector = createSelector(
  fileViewersStore,
  (fileViewer) => fileViewer.currentPageNumber
);
export const filesUrlSelector = createSelector(
  fileViewersStore,
  (fileViewer) => fileViewer.filesUrl
);
export const totalPageFilesSelector = createSelector(
  fileViewersStore,
  (fileViewer) => fileViewer.totalPageFiles
);

export const isFetchingFilesUrlSelector = createSelector(
  fileViewersStore,
  (fileViewer) => fileViewer.isFetchingFilesUrl
);
export const defaultZoomSelector = createSelector(
  fileViewersStore,
  (fileViewer) => fileViewer.defaultZoom
);
