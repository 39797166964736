import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { reimbursementReportsEnabledSelector } from "@/store/selectors/client";

import Text from "@/components/core/Text";

import ReimbursementPaymentsList from "@/components/Reimbursement/ReimbursementPaymentsList";
import { REIMBURSEMENT_PAGE_TYPE } from "@/utils/constants/reimbursement";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function Payments() {
  const [searchParams, setSearchParams] = useSearchParams();
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  return (
    <div className="flex flex-col pl-11 pr-11.5 ">
      <div className="flex flex-col">
        <Text
          classes="text-neutral-500 text-sm"
          translationKey="reimbursement.title"
        />
        <Text
          classes="text-neutral-800 text-4xl font-extrabold"
          translationKey="reimbursement.payments.title"
        />
      </div>
      <ReimbursementPaymentsList
        type={REIMBURSEMENT_PAGE_TYPE.payments}
        onClickHandler={(id) => {
          setSearchParams({
            ...(reportEnabled
              ? { [SLIDERS_SEARCH_PARAMS.reports.userReportId]: id }
              : { [SLIDERS_SEARCH_PARAMS.reimbursements.id]: id }),
            [SLIDERS_SEARCH_PARAMS.reimbursements.pageType]: "payments",
          });
        }}
      />
    </div>
  );
}
