import PropTypes from "prop-types";
import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

const HelpCenter = ({ icon, title, iconColor, bgColor }) => {
  return (
    <div className="flex flex-col gap-5">
      <div
        className={`flex justify-center items-center h-11 w-11 p-5 rounded-full ${bgColor}`}
      >
        <Icon name={icon} className={`w-6 h-[18px] ${iconColor}`} />
      </div>
      <div className="flex items-center gap-2">
        <Text translationKey={title} classes="font-semibold text-base" />
        <Icon name="ArrowForward" className=" text-neutral-500" />
      </div>
    </div>
  );
};
HelpCenter.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  bgColor: PropTypes.string,
};
export default HelpCenter;
