import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchPeople } from "@/store/reducers/company";
import {
  changeUserAccess,
  fetchUserBlockPolicyRequirements,
  fetchUserBlockRequirementsCount,
  resetUserAccess,
} from "@/store/reducers/user";

import {
  availableModulesSelector,
  isUserAccessChangeInprogress,
  isUserAccessChangedSelector,
  selectedUserSelector,
  userBlockRequirementsCountSelector,
  userBlockRequirementsSelector,
} from "@/store/selectors/user";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import ImportantToKnowSection from "@/components/Company/common/ImportantToKnowSection";
import PeopleCardsTable from "@/components/Company/common/PeopleCardsTable";
import { MODULES } from "@/utils/constants/app";
import { formatAndCleanUpCaptchaText } from "@/utils/common";

import { CARD_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_USER_STATUS,
  INVITATION_STATUS,
} from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { USER_ACCESS_CONTANTS } from "@/constants/user";

import InputCaptchaCheck from "./InputCaptchaCheck";
import UserRequirements from "./UserRequirements";

function FreezeUser() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const availableModule = useSelector(availableModulesSelector);
  const selectedUser = useSelector(selectedUserSelector);
  const displayName = selectedUser?.displayName;

  const freezeUserLabel = "company.people.freezeUserSlider.heading";

  const userId = searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id);
  const ref = useLeftHeaderTitle({
    title: freezeUserLabel,
    titleTranslationProps: { displayName },
  });

  const userStatus = [
    COMPANY_PEOPLE_USER_STATUS.ACTIVE,
    COMPANY_PEOPLE_USER_STATUS.PENDING,
    COMPANY_PEOPLE_USER_STATUS.SUBMITTED,
    COMPANY_PEOPLE_USER_STATUS.REJECTED,
    COMPANY_PEOPLE_USER_STATUS.FREEZED,
  ];
  const accessChangedInprogress = useSelector(isUserAccessChangeInprogress);

  const isUserFreezed = useSelector(isUserAccessChangedSelector);

  const captchaMatchText = `FREEZE ${
    formatAndCleanUpCaptchaText(selectedUser?.displayName) ||
    selectedUser?.email
  }`;

  const blockRequirements = useSelector(userBlockRequirementsCountSelector);
  const mandatoryRequirementsCount =
    blockRequirements?.mandatory?.approvalPolicy;

  const blockPolicyRequirements = useSelector(userBlockRequirementsSelector);
  const mandatoryRequirements = [
    {
      verticalExpandable: true,
      labelConfig: {
        label: "This user cannot be part of any approval policy",
        subLabel:
          "In order to block a user, you must first ensure that they are not part of any approval policy.",
        classes: "text-sm font-semibold text-neutral-800",
        subLabelClasses: "text-sm font-medium text-neutral-500",
      },
      count: mandatoryRequirementsCount,
      innerContent: <UserRequirements data={blockPolicyRequirements} />,
      key: "expandable-1",
    },
  ];

  const config = useMemo(() => {
    const CONFIG = [
      {
        label: t("company.people.freezeUserSlider.expenses"),
        module: MODULES.CARDS,
      },
      {
        label: t("company.people.freezeUserSlider.requestClaim"),
        module: MODULES.REIMBURSEMENTS,
      },
      {
        label: t("company.people.freezeUserSlider.createPayment"),
        module: MODULES.BILL_PAY,
      },
    ]?.filter((item) => availableModule?.includes(item?.module));
    return [
      {
        verticalExpandable: true,
        labelConfig: {
          label: "company.people.freezeUserSlider.captchaConfig.cardsLabel",
          classes: "text-sm font-semibold text-neutral-800",
        },
        module: MODULES.CARDS,
        innerContent: (
          <PeopleCardsTable
            showCardsOfType={[CARD_TYPE.PHYSICAL, CARD_TYPE.VIRTUAL]}
          />
        ),
        count: blockRequirements?.info?.cards,
        key: "expandable-1",
      },
      {
        verticalExpandable: false,
        labelConfig: {
          label: "company.people.freezeUserSlider.captchaConfig.modifications",
          translationProps: {
            value: CONFIG?.map(({ label }) => label).join(", "),
          },
          classes: "text-sm font-semibold text-neutral-800",
        },
        key: "expandable-1",
      },
      {
        verticalExpandable: false,
        labelConfig: {
          label: "company.people.freezeUserSlider.captchaConfig.freezingUser",
          classes: "text-sm font-semibold text-neutral-800",
        },
        key: "expandable-3",
      },
    ]?.filter(
      (item) => !item.module || availableModule?.includes(item?.module)
    );
  }, [JSON.stringify(availableModule)]);

  useEffect(() => {
    dispatch(fetchUserBlockRequirementsCount({ id: userId }));
    dispatch(fetchUserBlockPolicyRequirements({ id: userId }));
  }, []);

  useEffect(() => {
    if (isUserFreezed && !accessChangedInprogress) {
      handleCancel();
    }

    return () => {
      dispatch(resetUserAccess());
    };
  }, [isUserFreezed]);

  const onSuccess = () => {
    dispatch(
      fetchPeople({
        order_by_name: true,
        page: 1,
        status: userStatus,
        invitation_status: [INVITATION_STATUS.ACCEPTED],
        limit: PAGINATION_PER_REQUEST_LIMIT,
      })
    );
  };

  function handleConfirm() {
    dispatch(
      changeUserAccess({
        operation: USER_ACCESS_CONTANTS.FREEZED,
        id: userId,
        onSuccess,
      })
    );
  }

  function handleCancel() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.freezeUser);
    setSearchParams(searchParams);
  }

  return (
    <>
      <div className="flex flex-col gap-10 p-10 pt-0">
        <div className="flex flex-col gap-3">
          <Text
            translationKey={freezeUserLabel}
            translationProps={{ displayName }}
            classes="text-3xl font-bold"
            refProp={ref}
          />

          <Text
            translationKey="company.people.freezeUserSlider.headingDescription"
            classes="text-neutral-500 text-sm"
          />
        </div>
        <div className="flex flex-col gap-3">
          <Text
            classes="text-xl font-semibold"
            translationKey="company.people.freezeUserSlider.subjectiveWarningText"
          />

          <div className="mb-4">
            <Text
              translationKey="company.people.freezeUserSlider.subjectiveWarningTextDescription"
              classes="text-neutral-500 text-sm"
            />
          </div>

          <div className="flex flex-col gap-8">
            <ImportantToKnowSection
              mainLabelConfig={{
                label: "company.people.blockUserSlider.importantToKnowLabel",
                classes: "text-warning-500 text-sm font-semibold",
              }}
              config={config}
            />
            {mandatoryRequirementsCount ? (
              <ImportantToKnowSection
                mainLabelConfig={{
                  label:
                    "company.people.blockUserSlider.mandatoryRequirementsLabel",
                  classes: "text-danger-500 text-sm font-semibold",
                }}
                config={mandatoryRequirements}
                allSectionContainerClasses="bg-danger-50"
              />
            ) : (
              <div className="flex items-center gap-2">
                <Icon name="CheckCircle" className="text-success-600" />
                <Text
                  translationKey="company.people.blockUserSlider.mandatoryRequirementsCompletedLabel"
                  classes="text-sm font-semibold text-success-600"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <InputCaptchaCheck
        handleConfirmButton={handleConfirm}
        handleCancelButton={handleCancel}
        captchaMatchText={captchaMatchText}
        inProgress={accessChangedInprogress}
        outsideInputDivClasses="m-10 mt-0"
        disabled={mandatoryRequirementsCount > 0}
      />
    </>
  );
}

export default FreezeUser;
