import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateClientSettings } from "@/store/reducers/client";

import {
  approvalPolicyEnabledSelector,
  customApprovalPolicyEnabledSelector,
  customSubmissionPolicyEnabledSelector,
  physicalCardPolicyEnabledSelector,
  primaryCardProviderSelector,
  submissionPolicyEnabledSelector,
} from "@/store/selectors/client";

import { availableModulesSelector } from "@/store/selectors/user";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import DisableConfirmation from "@/components/Settings/Modals/DisableConfirmation";
import EnableConfirmation from "@/components/Settings/Modals/EnableConfirmation";
import PolicyItem from "@/components/Settings/SpendControls/PolicyItem";

import { MODULES } from "@/utils/constants/app";
import { SETTINGS_KEY, SETTINGS_PARAMS } from "@/constants/settings";

export default function SpendControls() {
  const approvalPolicySwitch = useSelector(approvalPolicyEnabledSelector);
  const submissionPolicySwitch = useSelector(submissionPolicyEnabledSelector);
  const customApprovalPolicySwitch = useSelector(
    customApprovalPolicyEnabledSelector
  );
  const customSubmissionPolicySwitch = useSelector(
    customSubmissionPolicyEnabledSelector
  );
  const physicalCardApprovalSwitch = useSelector(
    physicalCardPolicyEnabledSelector
  );
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showEnableModal, setShowEnableModal] = useState(false);
  const currentCardProviderInfo = useSelector(primaryCardProviderSelector);
  const physicalCardPolicyEnabled =
    currentCardProviderInfo?.config.physicalCardPolicyEnabled;

  const [modalType, setModalType] = useState("");
  const dispatch = useDispatch();
  const availableModule = useSelector(availableModulesSelector);
  const submitSettings = (key, val) => {
    dispatch(
      updateClientSettings({
        setting: SETTINGS_PARAMS[key],
        value: val,
      })
    );
  };
  const approvalPolicyOptions = [
    {
      id: 0,
      showSwitch: false,
      title: "settings.spendControls.approvalPolicy.title",
      description: "settings.spendControls.approvalPolicy.description",
      sliderId: "approvalPolicy",
      sliderType: "company",
    },
    {
      id: 1,
      showSwitch: true,
      showEnableModal: true,
      showDisableModal: true,
      switchKey: SETTINGS_KEY.customApprovalPolicy,
      switchValue: customApprovalPolicySwitch,
      onSubmit: submitSettings,
      sliderId: "customPolicy",
      sliderType: "approval",
      enableModalTitle: "settings.spendControls.customPolicy.enableModalTitle",
      enableModalSubtitle:
        "settings.spendControls.customPolicy.enableModalSubtitle",
      enableModalDescription:
        "settings.spendControls.customPolicy.enableModalDescription",
      title: "settings.spendControls.customPolicy.title",
      description: "settings.spendControls.customPolicy.description",
      disableModalHeader:
        "settings.spendControls.customPolicy.disableModalHeader",
      disableModalTitle:
        "settings.spendControls.customPolicy.disableModalTitle",
      disableModalSubtitle:
        "settings.spendControls.customPolicy.disableModalSubtitle",
      disableModalDesc: "settings.spendControls.customPolicy.disableModalDesc",
      disableModalBtnLabel: "common.btnLabel.yesDisable",
      disableModalBtnType: "danger",
      disableModalInputLabelText: "DISABLE",
    },
    {
      id: 2,
      showSwitch: true,
      showPolicyItem: physicalCardPolicyEnabled,
      showEnableModal: true,
      showDisableModal: true,
      sliderId: "policyModule",
      sliderType: "physicalCard",
      title: "settings.spendControls.physicalCardPolicy.title",
      description: "settings.spendControls.physicalCardPolicy.description",
      enableModalTitle:
        "settings.spendControls.physicalCardPolicy.enableModalTitle",
      enableModalSubtitle:
        "settings.spendControls.physicalCardPolicy.enableModalSubtitle",
      enableModalDescription:
        "settings.spendControls.physicalCardPolicy.enableModalDescription",
      disableModalHeader:
        "settings.spendControls.physicalCardPolicy.disableModalHeader",
      disableModalTitle:
        "settings.spendControls.physicalCardPolicy.disableModalTitle",
      disableModalSubtitle:
        "settings.spendControls.physicalCardPolicy.disableModalSubtitle",
      disableModalDesc:
        "settings.spendControls.physicalCardPolicy.disableModalDesc",
      disableModalBtnLabel: "common.btnLabel.yesDisable",
      disableModalBtnType: "danger",
      disableModalInputLabelText: "DISABLE",
      switchKey: SETTINGS_KEY.physicalCardPolicy,
      module: MODULES.CARDS,
      switchValue: physicalCardApprovalSwitch,
      onSubmit: submitSettings,
    },
  ]?.filter((item) => !item?.module || availableModule?.includes(item?.module));

  const submissionPolicyOptions = [
    {
      id: 0,
      showSwitch: false,
      title: "settings.spendControls.submissionPolicy.title",
      description: "settings.spendControls.submissionPolicy.description",
      sliderId: "submissionPolicy",
      sliderType: "company",
    },
    {
      id: 1,
      showSwitch: true,
      showEnableModal: true,
      showDisableModal: true,
      switchKey: SETTINGS_KEY.customSubmissionPolicy,
      switchValue: customSubmissionPolicySwitch,
      onSubmit: submitSettings,
      sliderId: "customPolicy",
      sliderType: "submission",
      title: "settings.spendControls.customSubmissionPolicy.title",
      description: "settings.spendControls.customSubmissionPolicy.description",
      enableModalTitle:
        "settings.spendControls.customSubmissionPolicy.enableModalTitle",
      enableModalSubtitle:
        "settings.spendControls.customSubmissionPolicy.enableModalSubtitle",
      enableModalDescription:
        "settings.spendControls.customSubmissionPolicy.enableModalDescription",
      disableModalHeader:
        "settings.spendControls.customSubmissionPolicy.disableModalHeader",
      disableModalTitle:
        "settings.spendControls.customSubmissionPolicy.disableModalTitle",
      disableModalSubtitle:
        "settings.spendControls.customSubmissionPolicy.disableModalSubtitle",
      disableModalDesc:
        "settings.spendControls.customSubmissionPolicy.disableModalDesc",
      disableModalBtnLabel: "common.btnLabel.yesDisable",
      disableModalBtnType: "danger",
      disableModalInputLabelText: "DISABLE",
    },
  ];

  const SPEND_CONTROL_OPTIONS = {
    APPROVAL_POLICIES: "APPROVAL_POLICIES",
    SUBMISSION_POLICIES: "SUBMISSION_POLICIES",
  };

  const MODAL_DISABLE_CONFIG = {
    [SPEND_CONTROL_OPTIONS.APPROVAL_POLICIES]: {
      header: "settings.spendControls.approvalPolicy.disableModalHeader",
      title: "settings.spendControls.approvalPolicy.disableModalTitle",
      subtitle: "settings.spendControls.approvalPolicy.disableModalSubtitle",
      desc: "settings.spendControls.approvalPolicy.disableModalDesc",
      inputLabelText: "DISABLE",
      inputNote: "",
      submitBtnType: "danger",
      submitBtnLabel: "common.btnLabel.yesDisable",
    },
    [SPEND_CONTROL_OPTIONS.SUBMISSION_POLICIES]: {
      header: "settings.spendControls.submissionPolicy.disableModalHeader",
      title: "settings.spendControls.submissionPolicy.disableModalTitle",
      subtitle: "settings.spendControls.submissionPolicy.disableModalSubtitle",
      desc: "settings.spendControls.submissionPolicy.disableModalDesc",
      inputLabelText: "DISABLE",
      inputNote: "",
      submitBtnType: "danger",
      submitBtnLabel: "common.btnLabel.yesDisable",
    },
  };

  const MODAL_ENABLE_CONFIG = {
    [SPEND_CONTROL_OPTIONS.APPROVAL_POLICIES]: {
      title: "settings.spendControls.approvalPolicy.enableModalTitle",
      subtitle: "settings.spendControls.approvalPolicy.enableModalSubtitle",
      description:
        "settings.spendControls.approvalPolicy.enableModalDescription",
      key: SETTINGS_KEY.approvalPolicy,
    },
    [SPEND_CONTROL_OPTIONS.SUBMISSION_POLICIES]: {
      title: "settings.spendControls.submissionPolicy.enableModalTitle",
      subtitle: "settings.spendControls.submissionPolicy.enableModalSubtitle",
      description:
        "settings.spendControls.submissionPolicy.enableModalDescription",
      key: SETTINGS_KEY.submissionPolicy,
    },
  };

  return (
    <>
      <div className="flex flex-col my-9">
        {/* Approval Policy */}
        <div className="flex justify-between gap-14">
          <div className="flex flex-col w-2/5 gap-2">
            <div className="flex items-center gap-2">
              <Switch
                value={approvalPolicySwitch}
                handleChange={(val) => {
                  setModalType(SPEND_CONTROL_OPTIONS.APPROVAL_POLICIES);
                  if (!val) {
                    setShowDisableModal(true);
                  } else {
                    setShowEnableModal(true);
                  }
                }}
              />
              <Text
                translationKey="settings.approvalPolicies"
                classes="text-lg font-medium"
              />
            </div>
            <Text
              translationKey="settings.approvalPolicyDescription"
              classes="text-sm text-neutral-500"
            />
          </div>
          {approvalPolicySwitch ? (
            <div className="flex flex-col w-3/5 gap-10">
              {approvalPolicyOptions.map((option) => (
                <PolicyItem
                  showPolicyItem={option?.showPolicyItem}
                  key={option.id}
                  id={option.sliderId}
                  type={option.sliderType}
                  showSwitch={option.showSwitch}
                  title={option.title}
                  description={option.description}
                  switchKey={option.switchKey}
                  switchValue={option.switchValue}
                  showDisableModal={option?.showDisableModal}
                  showEnableModal={option?.showDisableModal}
                  disableModalDesc={option?.disableModalDesc}
                  disableModalSubtitle={option?.disableModalSubtitle}
                  disableModalTitle={option?.disableModalTitle}
                  disableModalHeader={option?.disableModalHeader}
                  disableModalInputLabelText={
                    option?.disableModalInputLabelText
                  }
                  disableModalInputNote={option?.disableModalInputNote}
                  disableModalSubmitBtnLabel={option?.disableModalBtnLabel}
                  disableModalSubmitBtnType={option?.disableModalBtnType}
                  enableModalTitle={option?.enableModalTitle}
                  enableModalSubtitle={option?.enableModalSubtitle}
                  enableModalDescription={option?.enableModalDescription}
                  onSubmit={option.onSubmit}
                />
              ))}
            </div>
          ) : null}
        </div>

        <hr className=" border-t-0.5 border-neutral-200 my-10" />

        {/* Submission Policy */}
        <div className="flex justify-between gap-14 pb-14">
          <div className="flex flex-col w-2/5 gap-2">
            <div className="flex items-center gap-2">
              <Switch
                value={submissionPolicySwitch}
                handleChange={(val) => {
                  setModalType(SPEND_CONTROL_OPTIONS.SUBMISSION_POLICIES);
                  if (!val) {
                    setShowDisableModal(true);
                  } else {
                    setShowEnableModal(true);
                  }
                }}
              />
              <Text
                translationKey="settings.submissionPolicies"
                classes="text-lg font-medium"
              />
            </div>
            <Text
              translationKey="settings.submissionPolicyDescription"
              classes="text-sm text-neutral-500"
            />
          </div>
          {submissionPolicySwitch ? (
            <div className="flex flex-col w-3/5 gap-10">
              {submissionPolicyOptions.map((option) => (
                <PolicyItem
                  showPolicyItem={option?.showPolicyItem}
                  key={option.id}
                  id={option.sliderId}
                  type={option.sliderType}
                  showSwitch={option.showSwitch}
                  title={option.title}
                  description={option.description}
                  switchValue={option.switchValue}
                  switchKey={option.switchKey}
                  showDisableModal={option?.showDisableModal}
                  showEnableModal={option?.showDisableModal}
                  disableModalDesc={option?.disableModalDesc}
                  disableModalSubtitle={option?.disableModalSubtitle}
                  disableModalTitle={option?.disableModalTitle}
                  disableModalHeader={option?.disableModalHeader}
                  disableModalInputLabelText={
                    option?.disableModalInputLabelText
                  }
                  disableModalInputNote={option?.disableModalInputNote}
                  disableModalSubmitBtnLabel={option?.disableModalBtnLabel}
                  disableModalSubmitBtnType={option?.disableModalBtnType}
                  enableModalTitle={option?.enableModalTitle}
                  enableModalSubtitle={option?.enableModalSubtitle}
                  enableModalDescription={option?.enableModalDescription}
                  onSubmit={option.onSubmit}
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <DisableConfirmation
        header={MODAL_DISABLE_CONFIG[modalType]?.header}
        title={MODAL_DISABLE_CONFIG[modalType]?.title}
        subtitle={MODAL_DISABLE_CONFIG[modalType]?.subtitle}
        desc={MODAL_DISABLE_CONFIG[modalType]?.desc}
        inputNote={MODAL_DISABLE_CONFIG[modalType]?.inputNote}
        showModal={showDisableModal}
        setShowModal={setShowDisableModal}
        // onCancelClick={() => {}}
        labelText={MODAL_DISABLE_CONFIG[modalType]?.inputLabelText}
        submitBtnLabel={MODAL_DISABLE_CONFIG[modalType]?.submitBtnLabel}
        submitBtnType={MODAL_DISABLE_CONFIG[modalType]?.submitBtnType}
        onSubmitClick={() => {
          submitSettings(MODAL_ENABLE_CONFIG[modalType]?.key, false);
          setShowDisableModal(false);
        }}
      />
      <EnableConfirmation
        title={MODAL_ENABLE_CONFIG[modalType]?.title}
        subtitle={MODAL_ENABLE_CONFIG[modalType]?.subtitle}
        showModal={showEnableModal}
        description={MODAL_ENABLE_CONFIG[modalType]?.description}
        setShowModal={setShowEnableModal}
        onSubmitClick={() => {
          submitSettings(MODAL_ENABLE_CONFIG[modalType]?.key, true);
          setShowEnableModal(false);
        }}
        onCancelClick={() => {
          setShowEnableModal(false);
        }}
      />
    </>
  );
}
