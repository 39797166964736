import PropTypes from "prop-types";

import Checkbox from "@/components/core/Checkbox";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";
import RecurringPayIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/RecurringIcon";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";

export default function VendorCell({ val }) {
  let cellBottomUI = null;

  if (
    val?.context === BILL_PAYROLL_CONTEXT.BILLPAY ||
    val?.context === ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY
  ) {
    if (val?.vendorOrEmployeeTable) {
      cellBottomUI = (
        <Text
          classes="text-xs text-neutral-500 capitalize"
          translationKey={val?.vendor?.type}
        />
      );
    } else {
      cellBottomUI =
        val?.duplicateBillId && val?.tabType !== BILL_PAYROLL_CONTEXT.PAID ? (
          <div className="flex items-center gap-1 font-normal -ml-1.5">
            <TextWithTooltipIcon
              tooltipClasses="text-neutral-800 font-sm"
              iconClasses="text-warning-600"
              tooltipText="payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.duplicateSalary.title"
            />
            <Text
              translationKey="billPay.bill.common.duplicateBillText"
              classes="text-xs text-warning-600"
            />
          </div>
        ) : null;
    }
  } else if (val?.vendorOrEmployeeTable) {
    cellBottomUI = null;
  } else {
    cellBottomUI =
      val?.duplicatePayrollId && val?.tabType !== BILL_PAYROLL_CONTEXT.PAID ? (
        <div className="flex items-center gap-1 font-normal -ml-1.5">
          <TextWithTooltipIcon
            tooltipClasses="text-neutral-800 font-sm"
            iconClasses="text-warning-600"
            tooltipText="payroll.salaryPayment.payrollInbox.createSalaryPayment.alert.duplicateSalary.title"
          />
          <Text
            translationKey="billPay.bill.common.duplicateBillText"
            classes="text-xs text-warning-600"
          />
        </div>
      ) : (
        <Text
          classes="text-xs font-medium text-neutral-500 capitalize"
          translationKey="payroll.salaryPayment.payrollInbox.employeeId"
          translationProps={{ employeeId: val?.vendor?.employeeId }}
        />
      );
  }

  const vendorNameNotFound = !val?.vendor?.name;
  return (
    <div
      className="flex items-center gap-3 text-neutral-800"
      onClick={() => val?.openSliderHandler?.(val.id)}
    >
      {val?.showCheckbox ? (
        <Checkbox
          disabled={val.disabled ?? val?.queued ?? false}
          checked={val.checkedValue ?? false}
          onClickHandler={val.onCheckboxClick ?? (() => {})}
          classes="shrink-0"
        />
      ) : null}

      <ProfileWidget
        avatarColor={val?.vendor?.colorCode}
        iconComponent={
          vendorNameNotFound ? (
            <div className="rounded-full w-9 h-9 bg-danger-200" />
          ) : null
        }
        name={
          vendorNameNotFound
            ? "billPay.vendors.vendorNameNotFound"
            : val?.vendor?.name
        }
        id={val?.vendor?.id}
        img={val?.vendor?.avatarUrl}
        tooltipClasses="w-18"
        textClasses={`${
          vendorNameNotFound
            ? "text-neutral-400"
            : val?.disabled
              ? "text-neutral-500 "
              : ""
        } font-semibold`}
      >
        {cellBottomUI}
      </ProfileWidget>

      {/* Keep it this way (??), this is used by both Payments and Vendor tables */}
      {(val?.recurringPayment ?? val?.vendor?.hasRecurringPayments) ? (
        <RecurringPayIcon />
      ) : null}
    </div>
  );
}

VendorCell.propTypes = {
  val: PropTypes.shape({
    id: PropTypes.any,

    vendor: PropTypes.object,
    openSliderHandler: PropTypes.func,
    duplicateBillId: PropTypes.any,
    duplicatePayrollId: PropTypes.any,

    disabled: PropTypes.bool,
    checkedValue: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
    showCheckbox: PropTypes.bool,
  }),
};
