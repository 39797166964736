import PropsTypes from "prop-types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import LocationsTable from "@/components/Company/LocationsTable";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

export default function LocationPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <HeaderText
        setSliderHandler={() => {
          searchParams.set(SLIDERS_SEARCH_PARAMS.company.location.add, true);
          setSearchParams(searchParams);
        }}
      />
      {checkIfUserActionAllowed(
        allNavUrls,
        userAllowedActions,
        USER_ALLOWED_CTA_KEYS.NEW_LOCATIONS
      ) ? (
        <div className="mt-4">
          <LocationsTable
            onClickHandler={(id) => {
              searchParams.set(SLIDERS_SEARCH_PARAMS.company.location.id, id);
              setSearchParams(searchParams);
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

function HeaderText({ setSliderHandler }) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col">
        <Text classes="text-neutral-500" translationKey="company.title" />
        <Text
          classes="text-3xl font-bold text-neutral-800"
          translationKey="company.location.title"
        />
      </div>
      <Button
        variant="primary"
        label="dashboard.quickAccess.company.newLocations"
        preIcon="Add"
        classes="w-[212px]"
        onClick={setSliderHandler}
      />
    </div>
  );
}

HeaderText.propTypes = {
  setSliderHandler: PropsTypes.func,
};
