import PropTypes from "prop-types";

import Text from "@/components/core/Text";
import PaymentPageHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentPageHelper";
import { PAGE_TYPE } from "@/utils/constants/payments";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

export default function PayrollPaid({ page = BILL_PAYROLL_CONTEXT.PAYROLL }) {
  return (
    <div className="flex flex-col pl-11 pr-11.5">
      <div className="breadcrumb-text">
        <Text translationKey="payroll.title" />
        &nbsp;/&nbsp;
        <Text translationKey="payroll.salaryPayment.title" />
      </div>
      <Text
        translationKey="payroll.salaryPayment.paid.title"
        classes="listing-page-title"
      />
      <PaymentPageHelper page={page} pageType={PAGE_TYPE.paid} />
    </div>
  );
}
PayrollPaid.propTypes = {
  page: PropTypes.string,
};
