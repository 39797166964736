import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  createReport,
  fetchAndSelectReport,
  fetchReportReimbursements,
  setCreateClaimFormDetails,
  setCreateReportFormData,
  setDeletedClaimIdsForReport,
  setDeletedClaimIdsForReportInititalState,
  setReimbursementInitialState,
  setSelectedAllClaimsForReport,
  setSelectedClaimForReport,
  setSelectedReport,
  setShowEmptyList,
  updateReport,
  updateReportClaim,
} from "@/store/reducers/reimbursement";

import { projectsSelector } from "@/store/selectors/company";
import {
  createReportFormDataSelector,
  deletedClaimsIdForReportSelector,
  isCreatingReportSelector,
  isReimbursementsFetchingSelector,
  reimbursementHasMoreSelector,
  reimbursementTotalSelector,
  reimbursementpageSelector,
  reimbursementsListSelector,
  selectedAllClaimsForReportSelector,
  selectedClaimForReportSelector,
  selectedReportSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import ReportClaimListComponent from "@/components/ReimbursementReport/ReportClaimListComponent";
import LinkTo from "@/components/common/BillPayAndPayroll/VendorOrEmployee/CreateVendor/CreateVendorInit/LinkVendorTo";
import { useForm } from "@/utils/useForm";
import { SORTING_TYPE, SORT_KEYS } from "@/utils/constants/sorting";
import { REIMBURSEMENT_STATUS } from "@/utils/constants/reimbursement";
import { SORT_COLUMN_BY_MISSING_DETAILS } from "@/utils/constants/myVolopay";
import { camelToSnake } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  PAGINATION_PER_REQUEST_LIMIT,
  PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
} from "@/constants/pagination";
import { VENDOR_LINKED_TO_TYPES } from "@/constants/vendors";

function CreateReport({ setOnClose }) {
  const itemRef = useLeftHeaderTitle({
    title: "myVolopay.reimbursements.reports.create.title",
  });

  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();

  // selectors

  const reimbursements = useSelector(reimbursementsListSelector);
  const totalReimbursements = useSelector(reimbursementTotalSelector);
  const { id, departmentName, departmentId } = useSelector(userSelector);
  const isFetching = useSelector(isReimbursementsFetchingSelector);
  const hasMore = useSelector(reimbursementHasMoreSelector);
  const reimbursementPage = useSelector(reimbursementpageSelector);

  const [tab] = window.location.pathname.split("/").slice(-1);

  const deletedIds = useSelector(deletedClaimsIdForReportSelector);

  const selectedClaimIdForReport = useSelector(selectedClaimForReportSelector);
  const selectedAllClaimsForReport = useSelector(
    selectedAllClaimsForReportSelector
  );
  const projects = useSelector(projectsSelector);
  const createReportFormData = useSelector(createReportFormDataSelector);
  const isLoading = useSelector(isCreatingReportSelector);
  const selectedReportData = useSelector(selectedReportSelector);

  // states

  // searchparam ids
  const reportId = searchParam.get(SLIDERS_SEARCH_PARAMS.reports.reviewId);

  const isEmpty = !reimbursements?.length;

  const filterOptions = {
    status: REIMBURSEMENT_STATUS.in_draft,
  };

  const reportLinkToMetaData = {
    title: "myVolopay.reimbursements.reports.create.linkedTo.title",
    desc: "myVolopay.reimbursements.reports.create.linkedTo.desc",
    departmentName: { departmentName },
    departmentHelperText:
      "myVolopay.reimbursements.reports.create.linkedTo.departmentHelperText",
    projectHelperText:
      "myVolopay.reimbursements.reports.create.linkedTo.projectHelperText",
    disabledTooltipText:
      "myVolopay.reimbursements.reports.create.linkedTo.disabledTooltipText",
  };

  const initialValues = {
    name: {
      value: selectedReportData?.name ?? createReportFormData?.name ?? "",
      validate: {
        required: true,
      },
    },

    description: {
      value:
        selectedReportData?.description ??
        createReportFormData?.description ??
        "",
      validate: {
        required: true,
      },
    },

    linkedTo: {
      value:
        selectedReportData?.linkedTo?.title ??
        createReportFormData?.linkedTo ??
        VENDOR_LINKED_TO_TYPES.DEPARTMENT,
      validate: {
        required: true,
      },
    },
    projectId: {
      value:
        selectedReportData?.linkedTo?.id ??
        createReportFormData?.projectId ??
        departmentId,
      validate: {
        required: false,
      },
    },
  };

  const onSuccess = () => {
    setSearchParam({});
  };

  const onReset = () => {
    setPageNum(1);
    dispatch(setReimbursementInitialState());
  };

  const loadMore = () => {
    if ((selectedClaimIdForReport?.length || reportId) && values?.projectId) {
      dispatch(
        fetchReportReimbursements({
          page: pageNum,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          ...(reportId ? {} : { report_project_id: values?.projectId }),
          ...(reportId
            ? { by_report_id: reportId }
            : selectedAllClaimsForReport
              ? { user: id, except_ids: deletedIds }
              : { by_ids: selectedClaimIdForReport }),
          ...filterOptions,
          [SORT_KEYS.COLUMN]: SORT_COLUMN_BY_MISSING_DETAILS,
          [SORT_KEYS.DIRECTION]: SORTING_TYPE.INC,
        })
      );
    }
  };

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const createReportApiCall = (payload) => {
    const correctedKeysObject = Object.entries(payload).reduce(
      (accum, [key, value]) => {
        accum[camelToSnake(key)] = value;
        return accum;
      },
      {}
    );

    dispatch(
      createReport({
        payload: correctedKeysObject,
        onSuccess,
        tab,
      })
    );
  };

  const submitHandler = () => {
    const payload = { value: values, ...values };

    const totalAmount = reimbursements?.reduce(
      (previousValue, currentValue) => {
        return {
          amount:
            Number(previousValue?.amount || 0) +
            Number(currentValue?.amount?.value || 0),
          currency: currentValue?.amount?.currency,
        };
      },
      {
        amount: 0,
      }
    );

    if (values?.projectId === departmentId) {
      payload.deptOrProjectName = departmentName;
    } else {
      const { name: projectName } =
        projects.find((project) => project.id === values?.projectId) ?? {};
      // TODO: handle project search failed (above)
      payload.deptOrProjectName = projectName;
    }

    const noOfClaims = totalReimbursements;

    payload.totalAmount = totalAmount;
    payload.noOfClaims = noOfClaims;
    dispatch(setCreateReportFormData(payload));

    searchParam.append(SLIDERS_SEARCH_PARAMS.reports.reviewReport, true);
    setSearchParam(searchParam);
  };

  const {
    handleChange,
    values,
    _setValues: setValues,
    errors,
    isFormButtonDisabled,
  } = useForm(initialValues, submitHandler, {
    formId: "create-report-details-form",
  });

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    inSlider: true,
    filterOptions: {
      reportId,
      ...(values?.projectId ? { projectId: values?.projectId } : {}),
    },
  });

  const saveAsDraftCtaHandler = () => {
    const payload = {
      ...values,
    };

    if (selectedAllClaimsForReport) {
      payload.all = true;
    } else {
      payload.claimIds = selectedClaimIdForReport;
    }

    if (reportId) {
      const correctedKeysObject = Object.entries(payload).reduce(
        (accum, [key, value]) => {
          accum[camelToSnake(key)] = value;
          return accum;
        },
        {}
      );
      dispatch(
        updateReport({
          payload: correctedKeysObject,
          id: reportId,
          onSuccess,
        })
      );
    } else {
      createReportApiCall(payload);
    }
  };

  const createClaimCtaHandler = () => {
    dispatch(setCreateReportFormData(values));
    searchParam.append(SLIDERS_SEARCH_PARAMS.reimbursements.create, true);
    setSearchParam(searchParam);
  };

  const addClaimsCtaHandler = () => {
    dispatch(setCreateReportFormData(values));
    searchParam.append(SLIDERS_SEARCH_PARAMS.reports.addClaims, true);
    setSearchParam(searchParam);
  };
  const popupRef = useRef();
  const handlePopupToggle = () => {
    popupRef.current.toggle();
  };

  useEffect(() => {
    if (deletedIds?.length) {
      onReset();
      dispatch(
        fetchReportReimbursements({
          page: 1,
          limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
          report_project_id: values?.projectId,
          ...(selectedAllClaimsForReport
            ? { user: id, except_ids: deletedIds }
            : { by_ids: selectedClaimIdForReport }),
          ...filterOptions,
          unlinked_claims: true,
          [SORT_KEYS.COLUMN]: SORT_COLUMN_BY_MISSING_DETAILS,
          [SORT_KEYS.DIRECTION]: SORTING_TYPE.INC,
        })
      );
    }
  }, [deletedIds]);

  const deleteHandler = (val) => {
    const filteredIds = selectedClaimIdForReport?.filter(
      (deletedId) => deletedId !== val?.id
    );
    dispatch(setSelectedClaimForReport(filteredIds));

    dispatch(setShowEmptyList(false));
    if (reportId) {
      dispatch(
        updateReportClaim({
          id: reportId,
          payload: {
            remove_ids: [val?.id],
          },
          onSuccess: () => {
            onReset();
            dispatch(
              fetchReportReimbursements({
                page: 1,

                limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
                by_report_id: reportId,
                ...filterOptions,
                [SORT_KEYS.COLUMN]: SORT_COLUMN_BY_MISSING_DETAILS,
                [SORT_KEYS.DIRECTION]: SORTING_TYPE.INC,
              })
            );
          },
        })
      );
    } else if (filteredIds?.length) {
      dispatch(setDeletedClaimIdsForReport(val?.id));
    } else {
      dispatch(setReimbursementInitialState());
    }
  };

  const dataHaveMissingDetails = reimbursements
    ?.map((item) => {
      return Object.values(item?.missingDetails ?? {})?.some(
        (value) => value === true
      );
    })
    ?.filter((val) => val);

  const categoryLimitExceeded = reimbursements
    ?.map((item) => {
      return (
        item?.missingDetails?.categoryLimitDetails?.exceeded &&
        !item?.missingDetails?.categoryLimitDetails?.flexible
      );
    })
    .filter((val) => val);

  useEffect(() => {
    if (reimbursements?.length && reportId) {
      const ids = reimbursements?.map((item) => item.id);

      dispatch(setSelectedClaimForReport(ids));
    }
  }, [reimbursements, reportId]);

  useEffect(() => {
    if (reportId) {
      dispatch(fetchAndSelectReport({ reportId }));
    }
  }, [reportId]);

  useEffect(() => {
    if (selectedAllClaimsForReport) dispatch(setShowEmptyList(true));
  }, [selectedAllClaimsForReport]);

  useEffect(() => {
    if (searchParam.size === 1) {
      //  we need to clear this state when report is enabled. when the searchparam has one key here it will always be createReport or reviewReport
      dispatch(setCreateClaimFormDetails(null));
    }
  }, [searchParam?.size]);

  useEffect(() => {
    setOnClose((searchParamsArray) => {
      if (
        !searchParamsArray?.includes(SLIDERS_SEARCH_PARAMS.reports.create) &&
        !searchParamsArray?.includes(SLIDERS_SEARCH_PARAMS.reports.reviewId) &&
        !searchParamsArray?.includes(
          SLIDERS_SEARCH_PARAMS.reimbursements.review
        ) &&
        !searchParamsArray?.includes(
          SLIDERS_SEARCH_PARAMS.reimbursements.create
        )
      ) {
        dispatch(setSelectedClaimForReport([]));
        dispatch(setSelectedAllClaimsForReport(false));
        dispatch(setCreateReportFormData(null));
        dispatch(setSelectedReport(null));
        dispatch(setShowEmptyList(false));
        dispatch(setCreateClaimFormDetails(null));
        dispatch(setReimbursementInitialState());
        dispatch(setDeletedClaimIdsForReportInititalState());
      }
    });
  }, []);

  return (
    <>
      <div className="slider-content-container ">
        <div className="flex flex-col gap-6 px-9 slider-content-core">
          <div ref={itemRef} className="flex flex-col">
            <Text
              classes="font-bold text-3xl"
              translationKey="myVolopay.reimbursements.reports.create.title"
            />
            <Text
              classes="font-medium text-neutral-500 text-sm"
              translationKey="myVolopay.reimbursements.reports.create.desc"
            />
          </div>
          <form
            id="create-report-details-form"
            className="flex flex-col gap-6 mb-6"
          >
            <Input
              value={values?.name}
              name="name"
              label="myVolopay.reimbursements.reports.create.reportName"
              type="text"
              onChange={handleChange}
            />
            <Input
              value={values?.description}
              name="description"
              label="myVolopay.reimbursements.reports.create.reportDesc"
              type="text"
              onChange={handleChange}
            />

            <LinkTo
              values={values}
              errors={errors}
              handleChange={handleChange}
              setValues={setValues}
              metaData={reportLinkToMetaData}
              personId={id}
              isVendorOwnerSelected
            />

            <div className="flex items-center justify-between gap-8 my-3">
              <div className="flex flex-col">
                <Text
                  classes="font-bold text-xl"
                  translationKey="reimbursement.claims"
                />
                <Text
                  classes="font-normal text-neutral-500 text-base"
                  translationKey="myVolopay.reimbursements.reports.create.addClaimsText"
                />
              </div>

              <Popup
                ref={popupRef}
                repositionOnResize
                trigger={() => (
                  <div>
                    <Button
                      label="myVolopay.reimbursements.reports.create.addClaimsCta"
                      preIcon="Add"
                      variant="secondary"
                      onClick={handlePopupToggle}
                      classes="px-5 py-1.5 text-xs font-semibold w-16"
                    />
                  </div>
                )}
                closeOnDocumentClick
                keepTooltipInside="#tooltip-inside"
                position="bottom right"
                className="filter-popup"
              >
                <div className="flex flex-col min-w-[251px]">
                  <Button
                    label="myVolopay.reimbursements.reports.create.addFromExisting"
                    postIcon="ArrowForward"
                    type="neutral"
                    classes="border-none px-2"
                    innerClasses="w-full flex justify-between"
                    onClick={() => {
                      addClaimsCtaHandler();
                      popupRef.current.close();
                    }}
                  />
                  <Button
                    label="myVolopay.reimbursements.reports.create.createNew"
                    postIcon="ArrowForward"
                    type="neutral"
                    classes="border-none px-2"
                    innerClasses="w-full flex justify-between"
                    onClick={() => {
                      createClaimCtaHandler();
                      popupRef.current.close();
                    }}
                  />
                </div>
              </Popup>
            </div>

            {!isFetching ? (
              reimbursements?.length ? (
                <div className="max-h-[45vh] overflow-auto hide-scroll-bar">
                  <ReportClaimListComponent
                    dataVariables={{
                      data: reimbursements,
                      isFetching,
                      hasMore,
                      isEmpty,
                      total: totalReimbursements,
                      page: reimbursementPage,
                      paginationLimit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
                    }}
                    infiniteScrollVariables={{
                      onScroll,
                    }}
                    deleteActions={{
                      showDelete: true,
                      deleteHandler,
                    }}
                    onClickOpenSlider={(val) => {
                      searchParam.append(
                        SLIDERS_SEARCH_PARAMS.reimbursements.review,
                        val?.id
                      );
                      setSearchParam(searchParam);
                    }}
                  />
                </div>
              ) : null
            ) : (
              <div className="flex flex-col gap-2">
                <LoaderSkeleton fullWidth classes="p-3" />
                <LoaderSkeleton fullWidth classes="p-4" />
                <LoaderSkeleton fullWidth classes="p-4" />
                <LoaderSkeleton fullWidth classes="p-4" />
              </div>
            )}
          </form>
        </div>
      </div>
      <div className="flex justify-end gap-4 px-5 py-4 slider-footer">
        <Button
          label="myVolopay.reimbursements.reports.create.createFooterCtas.saveAsDraft"
          variant="tertiary"
          classes="w-16 px-5 py-3"
          onClick={saveAsDraftCtaHandler}
          disabled={isFormButtonDisabled}
          showLoader={isLoading}
        />
        <Button
          id="saveAndContinueBtn"
          label="myVolopay.reimbursements.reports.create.createFooterCtas.saveAndContinue"
          variant="primary"
          classes="w-fit px-5 py-3"
          btnType="button"
          onClick={submitHandler}
          form="create-report-details-form"
          disabled={
            isFormButtonDisabled ||
            !selectedClaimIdForReport?.length ||
            dataHaveMissingDetails?.length ||
            isLoading ||
            categoryLimitExceeded?.length
          }
        />
        {dataHaveMissingDetails?.length ? (
          <div>
            <Tooltip id="saveAndContinueBtn" direction="top">
              <Text
                translationKey="myVolopay.reimbursements.reports.create.createReportWarning"
                classes="text-neutral-500 text-sm font-normal"
              />
            </Tooltip>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default CreateReport;
