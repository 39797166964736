import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import {
  capitalizeFirstLetter,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import {
  ACCOUNTING_TRANSACTION_STATUS,
  SYNC_TOAST_CONFIG,
} from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { TRANSACTION_ACTIONS } from "@/constants/transactions";
import API from "@/api";

const initialState = {
  accountingTransactions: {
    list: [],
    hasMore: true,
    perPage: 10,
    page: 0,
    total: 0,
    isFetching: false,
  },
  selectedAccountingTransaction: null,
  selectedAccountingVendor: null,
  isFetchingSelectedAccountingVendor: false,
  isFetchingSelectedAccountingTransaction: false,
  isLoadingBulkAction: false,
  availableToSync: null,
  availableToSyncIds: null,
  fetchingAvailableToSync: false,
  failedToSync: null,
  failedToSyncTransactions: {
    list: [],
    page: 0,
    total: 0,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    hasMore: true,
    isFetching: false,
  },
  fetchingFailedToSync: false,
  reloadSelectedTransaction: false,
  filterKeys: {
    cards: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.analyticsLimit,
      AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
      AVAILABLE_FILTER_KEYS.transactionStatus,
      AVAILABLE_FILTER_KEYS.receiptStatus,
      AVAILABLE_FILTER_KEYS.accountingStatus,
    ],
    payments: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      // AVAILABLE_FILTER_KEYS.vendorOwner,// remove vendor owner filter
      AVAILABLE_FILTER_KEYS.invoiceStatus,
      // AVAILABLE_FILTER_KEYS.taxation, // remove taxation for now
      AVAILABLE_FILTER_KEYS.accountingStatus,
    ],
    reimbursements: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.reimbursementPaymentDate,
      AVAILABLE_FILTER_KEYS.analyticsLimit,
      AVAILABLE_FILTER_KEYS.receiptStatusFilter,
      AVAILABLE_FILTER_KEYS.accountingClaimStatus,
      AVAILABLE_FILTER_KEYS.accountingStatus,
    ],
    others: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.transactionType,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.accountingStatus,
    ],
    qrpay: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.transactionType,
      AVAILABLE_FILTER_KEYS.receiptStatusFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.analyticsLimit,
      AVAILABLE_FILTER_KEYS.accountingStatus,
    ],
    payroll: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      // AVAILABLE_FILTER_KEYS.transactionType, // remove type
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.analyticsLimit,
      AVAILABLE_FILTER_KEYS.accountingStatus,
    ],
  },
  isTransactionStatusLoading: false,
};

const accountingTransactionsSlice = createSlice({
  name: "accountingTransactions",
  initialState,
  reducers: {
    setTransactions(state, action) {
      state.accountingTransactions.list = action.payload;
    },
    setTransaction(state, action) {
      const { id, value } = action.payload;
      const transactionIndex = state.accountingTransactions.list.findIndex(
        (transaction) => Number(transaction.id) === Number(id)
      );

      if (transactionIndex !== -1) {
        state.accountingTransactions.list[transactionIndex] = value;
      }
    },
    addTransactions(state, action) {
      const newTransactions = action.payload;
      const existingTransactions = state.accountingTransactions.list;
      const transactionMap = new Map(
        existingTransactions.map((tx) => [tx.id, tx])
      );

      // Iterate over new transactions to perform upsert
      newTransactions.forEach((newTx) => {
        transactionMap.set(newTx.id, {
          ...transactionMap.get(newTx.id),
          ...newTx,
        });
      });

      state.accountingTransactions.list = Array.from(transactionMap.values());
    },
    setTransactionsLimit(state, action) {
      state.accountingTransactions.limit = action.payload;
    },
    setTransactionsPage(state, action) {
      state.accountingTransactions.page = action.payload;
    },
    setTransactionsTotal(state, action) {
      state.accountingTransactions.total = action.payload;
    },
    updateTransation(state, action) {
      state.accountingTransactions.list = state.accountingTransactions.list.map(
        (transaction) =>
          transaction.id === action.payload.id
            ? { ...transaction, ...action.payload.data }
            : transaction
      );
    },
    setTransactionsHasMore(state) {
      state.accountingTransactions.hasMore =
        state.accountingTransactions.list.length <
        state.accountingTransactions.total;
    },
    setIsFetchingTransactions(state, action) {
      state.accountingTransactions.isFetching = action.payload;
    },
    setSelectedAccountingTransaction(state, action) {
      state.selectedAccountingTransaction = action.payload;
    },
    appendSelectedAccountingTransaction(state, action) {
      state.selectedAccountingTransaction = {
        ...state.selectedAccountingTransaction,
        ...action.payload,
      };
    },
    setIsFetchingSelectedAccountingTransaction(state, action) {
      state.isFetchingSelectedAccountingTransaction = action.payload;
    },
    setIsLoadingBulkAction(state, action) {
      state.isLoadingBulkAction = action.payload;
    },
    setAvailableToSync(state, action) {
      state.availableToSync = action.payload.count;
      state.availableToSyncIds = action.payload.ids;
    },
    setFetchingAvailableToSync(state, action) {
      state.fetchingAvailableToSync = action.payload;
    },
    setFailedToSync(state, action) {
      state.failedToSync = action.payload;
    },
    setFailedToSyncTransactions(state, action) {
      state.failedToSyncTransactions.list = action.payload;
    },
    addFailedToSyncTransactions(state, action) {
      state.failedToSyncTransactions.list = [
        ...state.failedToSyncTransactions.list,
        ...action.payload,
      ];
    },
    resetFailedToSyncTransactions(state) {
      state.failedToSyncTransactions.list = [];
      state.failedToSyncTransactions.hasMore = true;
      state.failedToSyncTransactions.page = 0;
      state.failedToSyncTransactions.total = 0;
      state.failedToSyncTransactions.isFetching = false;
      state.failedToSyncTransactions.limit = PAGINATION_PER_REQUEST_LIMIT;
    },
    setFailedToSyncTransactionsPage(state, action) {
      state.failedToSyncTransactions.page = action.payload;
    },
    setFailedToSyncTransactionsTotal(state, action) {
      state.failedToSyncTransactions.total = action.payload;
    },
    setFailedToSyncTransactionsLimit(state, action) {
      state.failedToSyncTransactions.limit = action.payload;
    },
    setFetchingFailedToSync(state, action) {
      state.fetchingFailedToSync = action.payload;
    },
    setIsFetchingFailedToSyncTransactions(state, action) {
      state.failedToSyncTransactions.isFetching = action.payload;
    },
    setHasMoreFailedToSyncTransactions(state) {
      state.failedToSyncTransactions.hasMore =
        state.failedToSyncTransactions.list.length <
        state.failedToSyncTransactions.total;
    },
    resetAccountingTransactionsList(state, action) {
      state.accountingTransactions.list = [];
      state.accountingTransactions.page = 1;
      state.accountingTransactions.hasMore = true;
      state.accountingTransactions.total = 0;
    },
    setIsTransactionStatusLoading(state, action) {
      state.isTransactionStatusLoading = action.payload;
    },
    setUploadedReceipts(state, action) {
      state.selectedAccountingTransaction = {
        ...state.selectedAccountingTransaction,
        ...action.payload,
      };
    },
    setReloadSelectedTransaction(state, action) {
      state.reloadSelectedTransaction = action.payload;
    },
  },
});

export const fetchAccountingTransactions = createAsyncThunk(
  "accountingTransactions/fetchAccountingTransactions",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingTransactions(true));

    const { onSuccess, ...rest } = params;
    const [err, response] = await to(API.AccountingTransactions.all(rest));

    if (response.data) {
      if (response.data.page === 1) {
        dispatch(setTransactions(response.data.list));
      } else {
        dispatch(addTransactions(response.data.list));
      }
      dispatch(setTransactionsLimit(response.data.limit));
      dispatch(setTransactionsTotal(response.data.total));
      dispatch(setTransactionsPage(response.data.page));
      dispatch(setTransactionsHasMore());
    }

    if (onSuccess) onSuccess();
    dispatch(setIsFetchingTransactions(false));
  }
);

export const fetchAvailableToSync = createAsyncThunk(
  "accountingTransactions/fetchAvailableToSync",
  async (params, { dispatch }) => {
    dispatch(setFetchingAvailableToSync(true));

    const [err, response] = await to(
      API.AccountingTransactions.availableToSync(params)
    );
    if (response?.data) {
      dispatch(
        setAvailableToSync({
          count: response?.data.totalSyncable,
          ids: response?.data.syncableIds,
        })
      );
    }
    dispatch(setFetchingAvailableToSync(false));
  }
);

export const syncTransaction = createAsyncThunk(
  "accountingTransactions/syncTransaction",
  async (params, { dispatch }) => {
    dispatch(setIsTransactionStatusLoading(true));

    const { id, exportable, onSuccess = () => {} } = params;
    const [error, response] = await to(
      API.AccountingTransactions.syncTransaction({
        id,
      })
    );

    if (error) {
      vToast(getErrorToastMessage(error, "accounting.toaster.syncErrorTitle"));
      dispatch(
        fetchAndSelectAccountingTransaction({
          accountingId: id,
          updateInTable: true,
        })
      ); // fetch to update the error message
    } else {
      const transaction = response?.data;
      dispatch(updateTransation({ id: transaction?.id, data: transaction }));
      dispatch(
        setSelectedAccountingTransaction({ ...response.data, ...transaction })
      );
      vToast(SYNC_TOAST_CONFIG[exportable ? "export" : "sync"]);
      onSuccess();
    }
    dispatch(setIsTransactionStatusLoading(false));
  }
);
export const verifyTransaction = createAsyncThunk(
  "accountingTransactions/verifyTransaction",
  async ({ id, onSuccess = () => {} }, { dispatch }) => {
    dispatch(setIsTransactionStatusLoading(true));
    const [error, response] = await to(
      API.AccountingTransactions.verifyTransaction({
        id,
      })
    );
    if (error) vToast(getErrorToastMessage(error));
    if (!error && response) {
      const transaction = response?.data;
      dispatch(updateTransation({ id: transaction?.id, data: transaction }));
      dispatch(
        setSelectedAccountingTransaction({ ...response.data, ...transaction })
      );
      vToast({
        description: "accounting.toaster.verifySuccess",
        variant: "success",
      });
      onSuccess();
    }
    dispatch(setIsTransactionStatusLoading(false));
  }
);

export const unverifyTransaction = createAsyncThunk(
  "accountingTransactions/unverifyTransaction",
  async (id, { dispatch }) => {
    dispatch(setIsTransactionStatusLoading(true));
    const [error, response] = await to(
      API.AccountingTransactions.unverifyTransaction({
        id,
      })
    );
    if (error) vToast(getErrorToastMessage(error));
    else vToast(getSuccessToastMessage(response));
    dispatch(setIsTransactionStatusLoading(false));
  }
);

export const markAsSyncedTransaction = createAsyncThunk(
  "accountingTransactions/markAsSyncedTransaction",
  async (id, { dispatch }) => {
    const [error, response] = await to(
      API.AccountingTransactions.markAsSyncedTransaction({
        id,
      })
    );
    if (error) vToast(getErrorToastMessage(error));
    else {
      const transaction = response?.data;
      dispatch(updateTransation({ id: transaction?.id, data: transaction }));
      vToast({
        description: "accounting.toaster.syncSuccess",
        variant: "success",
      });
    }
  }
);

export const bulkAction = createAsyncThunk(
  "accountingTransactions/bulkAction",
  async (params, { dispatch }) => {
    const { payload, toastConfig } = params;

    dispatch(setIsLoadingBulkAction(true));
    const [error, response] = await to(
      API.AccountingTransactions.bulkOperation(payload)
    );

    if (response.data) {
      const operationTranslationMappings = {
        sync: "accounting.toaster.bulkOperationSync",
        verify: "accounting.toaster.bulkOperationVerified",
      };

      const config = toastConfig || {
        description: operationTranslationMappings[params?.payload?.operation],
        variant: "success",
      };

      vToast(config);
    }

    if (error) {
      vToast(getErrorToastMessage(error));
      dispatch(setIsLoadingBulkAction(false));
    }
  }
);

export const updateTagAndVendor = createAsyncThunk(
  "accountingTransactions/updateTag",
  async (params, { dispatch }) => {
    const {
      payload,
      onSuccess = () => {},
      onError = () => {},
      splitItemTable = false,
    } = params;
    const [error, response] = await to(
      API.AccountingTransactions.tagUpdate(payload)
    );
    if (response) {
      const transaction = response?.data?.transaction;

      // Prevent updating the table for line item updates
      if (payload.transaction_type !== "line_item" && !splitItemTable) {
        dispatch(updateTransation({ id: transaction?.id, data: transaction }));
      }
      dispatch(setSelectedAccountingTransaction({ ...transaction }));
      // vToast(getSuccessToastMessage(response));
      onSuccess(transaction);
    } else {
      vToast(getErrorToastMessage(error));
      onError(error);
    }
  }
);

export const updateAccountingTransactionVendor = createAsyncThunk(
  "accountingTransactions/updateVendor",
  async (params, { dispatch }) => {
    const { payload } = params;
    const [error, response] = await to(
      API.AccountingTransactions.updateVendor(payload)
    );
    if (response) {
      const transaction = response?.data?.transaction;
      dispatch(updateTransation({ id: transaction?.id, data: transaction }));
      vToast(getSuccessToastMessage(response));
    } else vToast(getErrorToastMessage(error));
  }
);

export const updateAccountingData = createAsyncThunk(
  "accountingTransactions/updateAccountingData",
  async (params, { dispatch }) => {
    const { accounting_id, payload = {}, onSuccess = () => {} } = params;
    dispatch(setIsTransactionStatusLoading(true));

    const [error, response] = await to(
      API.AccountingTransactions.updateAccounting(accounting_id, payload)
    );
    if (response?.data) {
      const transaction = response?.data;
      dispatch(
        setSelectedAccountingTransaction({ ...response?.data, ...transaction })
      );
      onSuccess(response?.data);
      dispatch(updateTransation({ id: transaction?.id, data: transaction }));

      dispatch(setIsTransactionStatusLoading(false));
      vToast(getSuccessToastMessage(response));
    }
    if (error) vToast(getErrorToastMessage(error));
    dispatch(setIsTransactionStatusLoading(false));
  }
);

export const fetchFailedToSync = createAsyncThunk(
  "accountingTransactions/fetchFailedToSync",
  async ({ onSuccess = () => {}, ...params }, { dispatch }) => {
    dispatch(setFetchingFailedToSync(true));

    const [err, response] = await to(
      API.AccountingTransactions.failedToSync(params)
    );
    if (response) {
      dispatch(setFailedToSync(response.data.total));
      onSuccess();
    }

    dispatch(setFetchingFailedToSync(false));
  }
);

export const fetchSelectedOtherTransaction = createAsyncThunk(
  "accountingTransactions/fetchSelectedOtherTransaction",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSelectedAccountingTransaction(true));

    const [err, response] = await to(
      API.AccountingTransactions.getAccountingTransaction(params)
    );
    if (response?.data) {
      dispatch(setSelectedAccountingTransaction(response?.data));
    }

    dispatch(setIsFetchingSelectedAccountingTransaction(false));
  }
);

export const fetchAndSelectAccountingTransaction = createAsyncThunk(
  "accountingTransactions/fetchAndSelectAccountingTransaction",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSelectedAccountingTransaction(true));

    const { accountingId, updateInTable = false } = params;

    const [err, response] = await to(
      API.AccountingTransactions.get(accountingId)
    );
    if (response?.data) {
      dispatch(setSelectedAccountingTransaction(response?.data));
      dispatch(setReloadSelectedTransaction(false));
      if (updateInTable) {
        dispatch(
          updateTransation({ id: response?.data?.id, data: response?.data })
        );
      }
    }

    dispatch(setIsFetchingSelectedAccountingTransaction(false));
  }
);

export const fetchFailedToSyncTransactions = createAsyncThunk(
  "accountingTransactions/fetchFailedToSyncTransactions",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingFailedToSyncTransactions(true));

    const [err, response] = await to(
      API.AccountingTransactions.failedToSyncTransactions(params)
    );
    if (response?.data) {
      if (response.data.page === 1) {
        dispatch(setFailedToSyncTransactions(response.data.list));
      } else {
        dispatch(addFailedToSyncTransactions(response.data.list));
      }
      dispatch(setFailedToSyncTransactionsLimit(response.data.limit));
      dispatch(setFailedToSyncTransactionsTotal(response.data.total));
      dispatch(setFailedToSyncTransactionsPage(response.data.page));
    }
    dispatch(setIsFetchingFailedToSyncTransactions(false));
  }
);

export const setBankAccountForAccounting = createAsyncThunk(
  "accountingTransactions/setBankAccountForAccounting",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSelectedAccountingTransaction(true));
    const [error, response] = await to(
      API.AccountingTransactions.updateAccountingTransaction(params)
    );
    if (response?.data) {
      dispatch(appendSelectedAccountingTransaction(response?.data));
      vToast(getSuccessToastMessage(response));
    } else vToast(getErrorToastMessage(error));
    dispatch(setIsFetchingSelectedAccountingTransaction(false));
  }
);

export const {
  setTransactions,
  setTransaction,
  addTransactions,
  setTransactionsLimit,
  setTransactionsPage,
  setTransactionsTotal,
  setTransactionsHasMore,
  setIsFetchingTransactions,
  setAvailableToSync,
  setSelectedAccountingTransaction,
  appendSelectedAccountingTransaction,
  setIsFetchingSelectedAccountingTransaction,
  setIsLoadingBulkAction,
  setFetchingAvailableToSync,
  setFailedToSync,
  setFailedToSyncTransactions,
  addFailedToSyncTransactions,
  resetFailedToSyncTransactions,
  setFailedToSyncTransactionsPage,
  setFailedToSyncTransactionsTotal,
  setFailedToSyncTransactionsLimit,
  setIsFetchingFailedToSyncTransactions,
  setHasMoreFailedToSyncTransactions,
  setFetchingFailedToSync,
  resetAccountingTransactionsList,
  updateTransation,
  setIsTransactionStatusLoading,
  setUploadedReceipts,
  setReloadSelectedTransaction,
} = accountingTransactionsSlice.actions;

export default accountingTransactionsSlice.reducer;
