import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";

// reducers
import {
  fetchAccountingVendors,
  syncChartOfAccounts,
  syncTaxCodes,
  syncTrackingCategories,
  syncVendors,
} from "@/store/reducers/accounting";
import { setIsFormSubmissionProgress } from "@/store/reducers/loadersError";
import { deleteAccountingField, fetchTags } from "@/store/reducers/tags";

// selectors
import {
  accountingIntegrationSoftwareSelector,
  clientSelector,
} from "@/store/selectors/client";
import { allTagsSelector } from "@/store/selectors/tags";

import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
// core components
import Icon from "@/components/core/Icon";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import vToast from "@/utils/vToast";
import { INTEGRATION_SYNC_OPTIONS } from "@/utils/constants/integrations";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARES } from "@/constants/accounting";

export default function AccountingFieldsTable({
  accountingFieldsData,
  tableHeaders,
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const isEmpty = dataList.length === 0;
  const [syncOptions, setSyncOptions] = useState(INTEGRATION_SYNC_OPTIONS);
  const integratedAccountingSoftware =
    useSelector(clientSelector)?.accountingIntegrationSoftware;

  const isUCSV =
    integratedAccountingSoftware === ACCOUNTING_SOFTWARES.UNIVERSAL_CSV;

  const handleEdit = (item) => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.editAccountingFields,
      item.id || item.name.replace(" ", "_")
    );
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setDataList(accountingFieldsData);
  }, [accountingFieldsData]);

  const handleDelete = async ({ index, id }) => {
    const action = await dispatch(deleteAccountingField(id));

    if (deleteAccountingField.fulfilled.match(action)) {
      setDataList((prev) => {
        const newData = [...prev];
        newData.splice(index, 1);
        return newData;
      });
    }
  };

  const popupRef = useRef();
  const syncActions = {
    categories: syncChartOfAccounts,
    vendors: syncVendors,
    "tracking categories": syncTrackingCategories,
    "tax codes": syncTaxCodes,
  };

  // Function to format the list of items with commas and "and"
  const formatItemList = (items) => {
    if (items.length > 1) {
      const lastItem = items.pop();
      return `${items.join(", ")} and ${lastItem}`;
    }
    return items[0];
  };

  const handleSync = async () => {
    dispatch(setIsFormSubmissionProgress(true));
    const promises = Object.entries(syncOptions)
      .filter(([item, value]) => value.sync && syncActions[item])
      .map(async ([item]) => {
        // Note the async keyword here
        try {
          const result = await dispatch(syncActions[item]());
          if (result.error) {
            return { status: "rejected", item, error: result.error };
          }
          return { status: "fulfilled", item, result };
        } catch (error) {
          return { status: "rejected", item, error };
        }
      });

    const results = await Promise.all(promises);

    const fulfilledPromises = results.filter(
      (result) => result.status === "fulfilled"
    );
    const rejectedPromises = results.filter(
      (result) => result.status === "rejected"
    );

    if (fulfilledPromises.length > 0) onSuccessfulSync({ fulfilledPromises });
    if (rejectedPromises.length > 0) onSyncFailure({ rejectedPromises });
    dispatch(setIsFormSubmissionProgress(false));
  };

  const onSuccessfulSync = ({ fulfilledPromises }) => {
    const syncedItemsList = formatItemList(
      fulfilledPromises.map((result) => result.item)
    );

    dispatch(fetchTags({ visible: true }));
    dispatch(fetchAccountingVendors());

    // Display success message for the synced items
    vToast({
      title: t("accounting.integrations.importingCategories.success.title"),
      description: t(
        "accounting.integrations.importingCategories.success.description",
        {
          syncedItemsList,
        }
      ),
    });
  };

  const onSyncFailure = ({ rejectedPromises }) => {
    const failedItemList = formatItemList(
      rejectedPromises.map((result) => result.item)
    );
    vToast({
      title: t("accounting.integrations.importingCategories.failed.title"),
      description: t(
        "accounting.integrations.importingCategories.failed.description",
        {
          failedItemList,
        }
      ),
      variant: "danger",
    });
  };

  const setUpdateSlider = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.updateField,
      true
    );
    setSearchParams(searchParams);
  };
  const handlePopupClose = () => {
    popupRef.current.close();
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="w-full flex flex-1 items-center justify-between">
        <SliderHeader
          heading="accounting.integrations.accountingFields.title"
          headingClasses="text-xl font-semibold text-neutral-800"
          refFlag
        />
        {isUCSV ? (
          <Button
            label="accounting.integrations.buttonLabel.update"
            preIcon="Sync"
            variant="tertiary"
            classes="w-min h-min text-xs font-bold text-primary-500 px-5 py-1.5"
            onClick={() => setUpdateSlider()}
          />
        ) : (
          <Popup
            ref={popupRef}
            trigger={() => (
              <div>
                <Button
                  label="accounting.integrations.buttonLabel.sync"
                  preIcon="Sync"
                  variant="tertiary"
                  classes="w-min h-min text-xs font-bold text-success-500 px-5 py-1.5"
                  onClick={() => setSyncOptions(INTEGRATION_SYNC_OPTIONS)}
                />
              </div>
            )}
            closeOnDocumentClick
            position="bottom right"
            className="filter-popup"
          >
            <div className="flex flex-col py-2 w-84">
              <div className="ml-4">
                <Text
                  translationKey="accounting.integrations.accountingFields.syncField"
                  classes="text-xs font-semibold text-neutral-500"
                />
              </div>
              <div className="flex flex-col justify-center  px-4 py-3 cursor-pointer text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900">
                {Object.entries(syncOptions)?.map(([key, value]) => (
                  <div
                    className="flex items-center gap-3 p-4"
                    key={`sync-${key}`}
                  >
                    <Checkbox
                      checked={value.sync}
                      onClickHandler={(val) =>
                        setSyncOptions((prev) => ({
                          ...prev,
                          [key]: { ...value, sync: val },
                        }))
                      }
                    />
                    <Text
                      translationKey={value.label}
                      classes="text-base font-medium text-neutral-800"
                    />
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-end gap-4 px-3 pt-4 pb-2 slider-footer ">
                <Button
                  label="filter.listButton.cancel"
                  type="neutral"
                  classes="w-fit h-8 px-5 py-1 text-xs font-bold"
                  onClick={() => {
                    setSyncOptions({});
                    handlePopupClose();
                  }}
                />
                <Button
                  label="accounting.integrations.buttonLabel.syncNow"
                  variant="secondary"
                  classes="w-fit h-8 px-5 py-1 text-xs font-bold"
                  onClick={() => {
                    handleSync();
                    handlePopupClose();
                  }}
                />
              </div>
            </div>
          </Popup>
        )}
      </div>

      <Table
        colWidths={[300, 200, 644, 116]}
        rightColWidths={[116]}
        numberOfStickyColsRight={1}
      >
        <tr>
          {tableHeaders.map((item, index) => (
            <th
              className={`${
                index === tableHeaders.length - 1 ? "text-center" : "text-left"
              } px-4 py-2`}
              key={`table-header-${index}`}
            >
              <Text
                translationKey={item}
                classes="text-neutral-800 text-xs font-semibold"
              />
            </th>
          ))}
        </tr>
        {!isEmpty
          ? dataList?.map((item, index) => (
              <tr
                className="text-sm font-semibold text-neutral-800 py-3"
                key={`table-row-${index}`}
              >
                <td>
                  <Text translationKey={item?.name} />
                </td>
                <td className="lowercase first-letter:capitalize">
                  <Text translationKey={item?.fieldType} />
                </td>
                <td>
                  {item?.options?.length >= 1
                    ? `${item?.options?.[0]?.alias} + ${
                        (item?.options?.length ?? 0) - 1
                      } more`
                    : null}
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    <div className="flex gap-6">
                      <Icon
                        name="Edit"
                        className="cursor-pointer text-neutral-500"
                        handleClick={() => handleEdit(item)}
                      />
                      <Icon
                        name="Delete"
                        className={`cursor-pointer ${
                          !(
                            item?.name?.toLowerCase().includes("categories") ||
                            item?.name?.toLowerCase().includes("vendor")
                          )
                            ? "text-neutral-400"
                            : "text-neutral-300"
                        }`}
                        handleClick={
                          !(
                            item?.name?.toLowerCase().includes("categories") ||
                            item?.name?.toLowerCase().includes("vendor")
                          )
                            ? () => handleDelete({ index, id: item.id })
                            : null
                        }
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))
          : null}
      </Table>
    </div>
  );
}

AccountingFieldsTable.propTypes = {
  accountingFieldsData: PropTypes.array,
  tableHeaders: PropTypes.array,
};
