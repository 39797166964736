import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function OwnerInfo({ vendor, isFetching }) {
  // TODO: the slider does not exist as of now. Correct the query param when it does

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex flex-col w-full p-4 font-medium border rounded-lg border-neutral-300">
      {isFetching ? (
        <div>
          <LoaderSkeleton fullWidth />
          <LoaderSkeleton fullWidth />
          <span className="flex justify-between">
            <LoaderSkeleton size={[20, 120]} />
            <LoaderSkeleton size={[20, 120]} />
          </span>
        </div>
      ) : (
        <>
          <div className="flex items-center gap-1">
            <Text
              translationKey="billPay.vendors.slider.vendorOwner"
              classes="text-sm text-neutral-500 font-semibold"
            />
          </div>
          <div
            onClick={() => {
              searchParams.append(
                SLIDERS_SEARCH_PARAMS.company.people.id,
                vendor?.owner?.id
              );

              setSearchParams(searchParams);
            }}
            className="flex items-center gap-2 cursor-pointer"
          >
            <span className="text-base font-semibold text-neutral-800">
              {vendor?.owner?.displayName ? (
                <div className="flex items-center gap-2">
                  <Text translationKey={vendor?.owner?.displayName} />
                  <Icon name="ToLink" className="text-primary-500" />
                </div>
              ) : (
                "-"
              )}
            </span>
          </div>
          <span className="text-xs font-semibold text-neutral-500">
            {[vendor?.owner?.departmentName, vendor?.owner?.locationName]
              .filter(Boolean)
              .join(" • ")}
          </span>
        </>
      )}
    </div>
  );
}

OwnerInfo.propTypes = { vendor: PropTypes.object, isFetching: PropTypes.bool };
