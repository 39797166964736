import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import {
  cardRequestDetailsIsFetchingSelector,
  newCardRequestDetailsSelector,
} from "@/store/selectors/cards";

import Timeline from "@/components/core/Timeline";

import CardsModuleRequestSection from "@/components/MyVolopay/ActionCentre/common/CardsModuleRequestSection";
import { TYPES_TIMELINE } from "@/utils/constants/timeline";

import { BE_FE_KEY_ORDER_TYPE_MAPPING } from "@/constants/ActionCentre";

import OrderPhysicalCardOverviewCards from "./OrderPhysicalCardOverviewCards";
import ReviewCardOrderRequestDetails from "./ReviewCardOrderRequestDetails";

export default function OverviewTab({ type }) {
  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);
  const isFetching = useSelector(cardRequestDetailsIsFetchingSelector);

  const BULK_ORDER_PHYSICAL_KEYS = Object.entries(BE_FE_KEY_ORDER_TYPE_MAPPING)
    .filter(([key, value]) =>
      [
        BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderNonPersonalized,
        BE_FE_KEY_ORDER_TYPE_MAPPING.bulkOrderPersonalized,
      ]?.includes(value)
    )
    ?.map(([key, value]) => key);

  return (
    <>
      {!isFetching ? (
        <div className="flex flex-col gap-8">
          <div className="mt-5">
            <Timeline
              id={cardRequestDetails?.id}
              status={cardRequestDetails?.currentApprovalLevelStatus}
              currentApprovers={
                cardRequestDetails?.currentApprovers
                  ? cardRequestDetails?.currentApprovers
                  : []
              }
              rejectedReason={cardRequestDetails?.rejectedReason}
              currentApproverLevel={cardRequestDetails?.currentApprovalLevel}
              totalApprovers={cardRequestDetails?.approvalLevels}
              type={TYPES_TIMELINE.CARD_REQUEST}
            />
          </div>
          <OrderPhysicalCardOverviewCards
            requestOwner={cardRequestDetails?.requestOwner}
            cardHolder={
              BULK_ORDER_PHYSICAL_KEYS?.includes(cardRequestDetails?.orderType)
                ? null
                : cardRequestDetails?.cardHolder
            }
            orderType={cardRequestDetails?.orderType}
          />
          <ReviewCardOrderRequestDetails
            bulkOrderKeysArray={BULK_ORDER_PHYSICAL_KEYS}
            data={cardRequestDetails}
          />

          <CardsModuleRequestSection />
        </div>
      ) : null}

      {/* Will remove it when proper loaders are available */}

      {isFetching ? "Loading..." : null}
    </>
  );
}
OverviewTab.propTypes = {
  type: PropTypes.string,
};
