import PropTypes from "prop-types";

import Badge from "@/components/core/Badge";
import Text from "@/components/core/Text";

import BalanceCard from "@/components/Company/Overview/MyAccounts/shared/BalanceCard";

import { dateToString } from "@/utils/common";
import {
  BADGE_SPEND_COMPARISION_CARD,
  BADGE_SPEND_COMPARISION_CARD_KEYS,
  SPEND_FILTER_DURATION_THIS_LABELS,
  SPEND_FILTER_DURATION_VS_LABELS,
} from "@/constants/company";

export default function SpendComparisonCard({
  amount,
  currency,
  bgColorClass = "",
  paddingClass = "pl-4 pt-4 pr-12 pb-6",
  headText,
  percentage,
  headTranslation,
  percentageText,
  amountFontSizeClass = "text-xl",
  hideDeltaChip = false,
  showDuration = false,
}) {
  const keys = !percentage
    ? BADGE_SPEND_COMPARISION_CARD_KEYS.NO_CHANGE
    : percentage < 0
      ? BADGE_SPEND_COMPARISION_CARD_KEYS.NEGATIVE
      : BADGE_SPEND_COMPARISION_CARD_KEYS.POSITIVE;
  const config = BADGE_SPEND_COMPARISION_CARD[keys];

  return (
    <div className={`${paddingClass} ${bgColorClass} w-full rounded-lg`}>
      <BalanceCard
        label={headText}
        labelTranslation={headTranslation}
        amount={amount}
        currency={currency}
        amountFontSizeClass={amountFontSizeClass}
      />
      {!hideDeltaChip && !showDuration && (
        <div className="flex items-center gap-1">
          <div className="flex gap-1 " />
          <Badge
            textClasses="text-xs font-semibold"
            classes="px-2 border-0.25"
            variant={config?.variant}
            preIcon={config?.preIcon}
            translationKey={`${
              Number.isNaN(Number.parseInt(percentage, 10))
                ? 0
                : Math.abs(percentage)
            }${percentage ? "%" : ""}`}
          />
          <Text
            translationKey={SPEND_FILTER_DURATION_VS_LABELS[percentageText]}
            classes="text-xs font-semibold text-neutral-400"
          />
        </div>
      )}

      {showDuration ? (
        <Text
          translationKey={
            typeof percentageText === typeof {}
              ? "dashboard.spendAnalytics.inCustomRange"
              : SPEND_FILTER_DURATION_THIS_LABELS[percentageText]
          }
          translationProps={
            typeof percentageText === typeof {}
              ? {
                  to: dateToString(percentageText?.to),
                  from: dateToString(percentageText?.from),
                }
              : {}
          }
          classes="text-xs font-semibold text-neutral-400"
        />
      ) : null}
    </div>
  );
}

SpendComparisonCard.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  bgColorClass: PropTypes.string,
  paddingClass: PropTypes.string,
  headText: PropTypes.string,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percentageText: PropTypes.string,
  amountFontSizeClass: PropTypes.string,
  headTranslation: PropTypes.object,
  hideDeltaChip: PropTypes.bool,
  showDuration: PropTypes.bool,
};
