import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  addBudgetFormData,
  fetchAndSelectBudget,
  fetchAndSelectDepartment,
  fetchAndSelectProject,
  fetchBudgets,
  getBudgetsAvailableForModules,
  resetBudgetFromData,
  setReviewBudgetNewData,
  setReviewBudgetOldData,
  setSelectedDepartment,
  setSelectedProject,
  updateBudgetFormData,
  updateProject,
} from "@/store/reducers/company";
import { setIsFormSubmissionProgress } from "@/store/reducers/loadersError";

import { defaultCurrencySelector } from "@/store/selectors/client";
import {
  allowedBudgetsForModulesSelector,
  budgetFormDataSelector,
  reviewOldBudgetSelector,
  selectedBudgetSelector,
  selectedDepartmentSelector,
  selectedProjectSelector,
} from "@/store/selectors/company";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import CheckboxDropdown from "@/components/core/CheckboxDropdown";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import { useForm } from "@/utils/useForm";
import { arraysHaveSameValues, removeFloatingZero } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  BUDGETED_FOR_EXPENSE_KEYS,
  DEPARTMENT,
  EDIT_SLIDER,
  PROJECT,
} from "@/constants/company";
import { FREQUENCY_OPTIONS } from "@/constants/frequencyOptions";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";

function BudgetForm({
  setOnClose,
  configKey,
  isInEditMode,
  sliderType,
  isNewSlider,
  searchParamKey,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currency = useSelector(defaultCurrencySelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [storeModeSavedData, setStoreModeSavedData] = useState({});
  const budgetFormDataArray = useSelector(budgetFormDataSelector);
  const selectedBudget = useSelector(selectedBudgetSelector);
  const selectedShowApiOfProjectAndBudget = useSelector((state) =>
    sliderType === DEPARTMENT
      ? selectedDepartmentSelector(state)
      : selectedProjectSelector(state)
  );

  const BUDGET_FOR_CONFIG = {
    [BUDGETED_FOR_EXPENSE_KEYS.EXPENSE]: {
      id: 1,
      name: t("company.budgets.allowedBudgetsForModulesConfig.expense"),
      label: t("company.budgets.allowedBudgetsForModulesConfig.expense"),
      value: BUDGETED_FOR_EXPENSE_KEYS.EXPENSE,
    },
    [BUDGETED_FOR_EXPENSE_KEYS.DOMESTIC_EXPENSE]: {
      id: 2,
      name: t("company.budgets.allowedBudgetsForModulesConfig.domesticExpense"),
      label: t(
        "company.budgets.allowedBudgetsForModulesConfig.domesticExpense"
      ),
      value: BUDGETED_FOR_EXPENSE_KEYS.EXPENSE,
    },
    [BUDGETED_FOR_EXPENSE_KEYS.PAYMENT]: {
      id: 3,
      name: t("company.budgets.allowedBudgetsForModulesConfig.payment"),
      label: t("company.budgets.allowedBudgetsForModulesConfig.payment"),
      value: BUDGETED_FOR_EXPENSE_KEYS.PAYMENT,
    },
    [BUDGETED_FOR_EXPENSE_KEYS.REIMBURSEMENT]: {
      id: 4,
      name: t("company.budgets.allowedBudgetsForModulesConfig.reimbursement"),
      label: t("company.budgets.allowedBudgetsForModulesConfig.reimbursement"),
      value: BUDGETED_FOR_EXPENSE_KEYS.REIMBURSEMENT,
    },
  };

  const CONFIG = {
    project: {
      translationKey:
        "company.department.addNewDepartment.budgets.budgetForDepartmentOrProject.title",
      translationProps: {
        name:
          isInEditMode && !isNewSlider
            ? selectedBudget?.projectName
            : selectedShowApiOfProjectAndBudget?.name,
        type: sliderType,
      },
    },
    department: {
      translationKey:
        "company.department.addNewDepartment.budgets.budgetForDepartmentOrProject.title",
      translationProps: {
        name:
          isInEditMode && !isNewSlider
            ? selectedBudget?.projectName
            : selectedShowApiOfProjectAndBudget?.name,
        type: sliderType,
      },
    },
    newSlider: {
      translationKey:
        "company.department.addNewDepartment.budgets.defineNewBudgetBtn",
    },
    editSlider: {
      translationKey: "Edit Budget",
    },
  };

  const saveDataInstore =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.budget.storeMode) === "true";
  const CONFIG_KEY = isInEditMode
    ? EDIT_SLIDER
    : !saveDataInstore
      ? sliderType
      : configKey;
  const configObject = CONFIG?.[CONFIG_KEY];
  const id = parseInt(searchParams.get(searchParamKey), 10);
  const oldData = useSelector(reviewOldBudgetSelector);
  const allowedBudgetsForModulesInfo = useSelector(
    allowedBudgetsForModulesSelector
  );
  const showInternationalCardExpensesBannerForBudgets =
    allowedBudgetsForModulesInfo?.showBanner;
  const availableModulesForBudget =
    allowedBudgetsForModulesInfo?.availableModules;
  const _BUDGET_FOR_CONFIG = availableModulesForBudget?.map(
    (budgetKey) => BUDGET_FOR_CONFIG?.[budgetKey]
  );

  const initialValue = {
    amount: {
      value: saveDataInstore
        ? storeModeSavedData?.amount
        : !isNewSlider && isInEditMode
          ? removeFloatingZero(selectedBudget?.limit) // selected budget api return limit
          : "",
      validate: {
        required: true,
        minNumber: Number.MIN_VALUE,
      },
    },
    frequency: {
      value: saveDataInstore
        ? storeModeSavedData?.frequency
        : !isNewSlider && isInEditMode
          ? selectedBudget?.frequency
          : "",
      validate: { required: true },
    },
    flexible: {
      value: saveDataInstore
        ? storeModeSavedData?.flexible
        : !isNewSlider && isInEditMode
          ? selectedBudget?.flexible
          : "",
      validate: { required: false },
    },
    modules: {
      value: saveDataInstore
        ? storeModeSavedData?.budgetFor?.map(
            (item) =>
              _BUDGET_FOR_CONFIG?.find((i) => i.value === item)?.id || item
          )
        : !isNewSlider && isInEditMode
          ? selectedBudget?.modules?.map(
              (item) =>
                _BUDGET_FOR_CONFIG?.find((i) => i.value === item)?.id || item
            )
          : [],
      validate: { required: true },
    },
  };

  const createReviewData = (object) => {
    const modulesArray = object?.budgetFor ?? object?.modules;
    const modules = modulesArray?.map(
      (item) => _BUDGET_FOR_CONFIG?.find((i) => i.id === item)?.value || item
    );

    return {
      amount: object?.amount ?? object?.limit,
      frequency: object?.frequency,
      flexible: object?.flexible,
      budgetFor: modules,
    };
  };
  const handleFormSubmit = (valueObj) => {
    // scenerio for saved data in store
    if (saveDataInstore && !isInEditMode) {
      dispatch(addBudgetFormData(createReviewData(valueObj)));
      searchParams.delete(searchParamKey);
    } else if (isInEditMode) {
      if (isInEditMode) {
        dispatch(setReviewBudgetNewData(createReviewData(valueObj)));

        dispatch(
          updateBudgetFormData({
            indexMode: true,
            index: parseInt(id, 10),
            data: createReviewData(valueObj),
          })
        );
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.company.budget.reviewBudget,
          id
        );
      }
    } else if (!saveDataInstore && !isInEditMode) {
      const _data = createReviewData(values);
      const apipayload = {
        id,
        payload: {
          department: sliderType === DEPARTMENT,
          budgets_attributes: [
            {
              limit: _data?.amount,
              frequency: _data?.frequency,
              flexible: _data?.flexible,
              modules: _data?.budgetFor,
            },
          ],
        },
      };
      const onSuccess = (response) => {
        // if on project page then only fetch api
        const isOnProjectPage = window.location.pathname.includes(
          ROUTES.manage.company.budgets["project-budgets"].absolutePath
        );
        if (sliderType === DEPARTMENT) {
          dispatch(setSelectedDepartment(response));
          if (!isOnProjectPage)
            dispatch(
              fetchBudgets({
                page: 1,
                limit: PAGINATION_PER_REQUEST_LIMIT,
                budget_type: sliderType,
              })
            );
        } else if (sliderType === PROJECT) {
          dispatch(setSelectedProject(response));
          if (isOnProjectPage)
            dispatch(
              fetchBudgets({
                page: 1,
                limit: PAGINATION_PER_REQUEST_LIMIT,
                budget_type: sliderType,
              })
            );
        }
        searchParams.delete(searchParamKey);
        searchParams.delete(SLIDERS_SEARCH_PARAMS.company.budget.create);
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.company.budget.createExisting
        );
        searchParams.delete(SLIDERS_SEARCH_PARAMS.company.budget.storeMode);
        setSearchParams(searchParams);
      };
      const onFinal = () => {
        dispatch(setIsFormSubmissionProgress(false));
      };
      dispatch(setIsFormSubmissionProgress(true));
      dispatch(updateProject({ ...apipayload, onSuccess, onFinal }));
    }
    // last delete key
    setSearchParams(searchParams);
  };
  const { isFormButtonDisabled, values, errors, handleChange, handleSubmit } =
    useForm(initialValue, (e, value) => handleFormSubmit(value));

  const ref = useLeftHeaderTitle({
    title: (
      <Text
        classes="left-header-text"
        translationKey={configObject.translationKey}
        translationProps={configObject.translationProps}
      />
    ),
  });

  useEffect(() => {
    // scenerio where data is saved on store and edit is done
    dispatch(getBudgetsAvailableForModules());
    if (saveDataInstore && isInEditMode) {
      const _selectedData = budgetFormDataArray.find(
        (item, index) => index === id
      );
      setStoreModeSavedData(_selectedData);
      if (!oldData)
        dispatch(setReviewBudgetOldData(createReviewData(_selectedData)));
    } else if (!saveDataInstore && !isInEditMode) {
      // scenerio where data is not saved on store and create is done
      if (id)
        if (sliderType === PROJECT)
          dispatch(
            fetchAndSelectProject({ id, department: sliderType === DEPARTMENT })
          );
        else
          dispatch(
            fetchAndSelectDepartment({
              id,
              department: sliderType === DEPARTMENT,
            })
          );
    } else if (!isNewSlider && isInEditMode) {
      dispatch(fetchAndSelectBudget({ id }));
      const _selectedData = createReviewData(selectedBudget);

      dispatch(setReviewBudgetOldData(_selectedData));
    }
  }, [searchParams]);

  useEffect(() => {
    setOnClose((searchParam) => {
      const isSliderClosed = !searchParam.includes(searchParamKey);
      // Cleaning data when editing is not completed but slider closed
      if (isInEditMode && isSliderClosed && !saveDataInstore) {
        dispatch(
          updateBudgetFormData({
            index: id,
            indexMode: true,
            data: createReviewData(oldData),
          })
        );
      }
      const isLastSlider = searchParam.length === 0;
      if (isLastSlider && saveDataInstore && isInEditMode) {
        dispatch(resetBudgetFromData());
        dispatch(setReviewBudgetOldData({}));
        dispatch(setReviewBudgetNewData({}));
      }
    });
  }, []);

  const getDisableOptions = (item) => {
    if (saveDataInstore) {
      const inStoreModeAllowedDisabled = budgetFormDataArray
        ?.filter((i, index) => index !== parseInt(id, 10))
        .map((it) => it?.budgetFor)
        ?.flat(2);

      return inStoreModeAllowedDisabled?.includes(item.value);
    }
    if (!saveDataInstore && isNewSlider) {
      const notInStoreModeNewSlider = selectedShowApiOfProjectAndBudget?.budgets
        ?.map((itn) => itn?.modules)
        .flat(2);
      return notInStoreModeNewSlider?.includes(item.value);
    }
    if (!saveDataInstore && !isNewSlider && isInEditMode) {
      const notInStoreModeEditSlider = _BUDGET_FOR_CONFIG
        .filter((itm) => !selectedBudget?.availableModules?.includes(itm.value))
        ?.map((i) => i.value);

      return notInStoreModeEditSlider?.includes(item.value);
    }

    return null;
  };

  const handleCancelClick = () => {
    searchParams.delete(searchParamKey);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.budget.create);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.budget.createExisting);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.budget.storeMode);
    setSearchParams(searchParams);
  };
  const isChangeInOldAndNewData =
    removeFloatingZero(oldData?.amount) ===
      removeFloatingZero(values?.amount) &&
    oldData?.frequency === values?.frequency &&
    oldData?.flexible === values?.flexible &&
    arraysHaveSameValues(
      oldData?.budgetFor,
      values?.modules?.map(
        (idm) =>
          _BUDGET_FOR_CONFIG.find((i) => i.id === parseInt(idm, 10))?.value
      )
    );

  return (
    <>
      <div className="slider-content-core">
        <div
          ref={ref}
          className="flex flex-col text-3xl font-bold truncate text-neutral-800"
        >
          <Text classes="truncate" {...configObject} />
          <Text
            classes="text-sm text-neutral-500 font-semibold"
            translationKey="company.department.addNewDepartment.budgets.defineBudgetDescription"
          />
        </div>

        {showInternationalCardExpensesBannerForBudgets ? (
          <Alert
            title="company.budgets.internationalCardExpensesNote.title"
            description="company.budgets.internationalCardExpensesNote.description"
            variant="warning"
            classes="mt-10"
          />
        ) : null}

        <form
          id="budget-create-form"
          onSubmit={handleSubmit}
          className="flex flex-col gap-8"
        >
          <div className="flex items-center w-full gap-9 mt-9">
            <div className="w-full">
              <Input
                name="amount"
                value={values?.amount}
                error={errors?.amount}
                type="number"
                label="filters.amount"
                placeholder="filters.amount"
                onChange={handleChange}
                rightText={currency}
                showNumberInvalidError
              />
            </div>
            <div className="w-full mt-1">
              <VpSelect
                name="frequency"
                value={values?.frequency}
                handleChange={handleChange}
                translate
                label="cards.cardDetails.overview.history.requestDataLabels.frequency"
                placeholder="cards.cardDetails.overview.history.requestDataLabels.frequency"
                options={FREQUENCY_OPTIONS}
                customUIForOptionWhenDropdownOpen={(
                  label,
                  data,
                  finalValue
                ) => (
                  <div className="flex flex-col">
                    <Text translationKey={label} />
                    {data?.subLabelProp ? (
                      <Text
                        classes={`text-xs text-neutral-500 ${
                          finalValue?.value === data.value ? "text-white" : ""
                        }`}
                        translationKey={data?.subLabel}
                        translationProps={data?.subLabelProp}
                      />
                    ) : null}
                  </div>
                )}
                optionsDisplayKey="label"
                menuPosition="absolute"
                valueKey="value"
                error={errors?.frequency}
                insideForm
              />
            </div>
          </div>

          <Checkbox
            checked={values?.flexible}
            onClickHandler={handleChange}
            insideForm
            name="flexible"
            errorMsg={errors?.flexible}
            classes="flex item-start justify-start "
            label={
              <div className="flex flex-col font-semibold text-neutral-800">
                <Text translationKey="company.department.addNewProject.budgets.flexibleBudget" />
                <Text
                  classes="text-neutral-500 font-medium text-sm"
                  translationKey="company.department.addNewProject.budgets.flexibleBudgetDesc"
                />
              </div>
            }
          />
          <div>
            <CheckboxDropdown
              name="modules"
              options={_BUDGET_FOR_CONFIG?.map((item) => ({
                ...item,
                disabled: getDisableOptions(item),
              }))}
              dropdownLabelFlatView="filters.budgetedFor.spendType"
              label="filters.budgetedFor.title"
              selectedIdsArray={values?.modules ?? []}
              handleSubmit={(e) => {
                const _e = {
                  target: { ...e.target, value: e.target.value.selectedIds },
                };
                handleChange(_e);
              }}
              insideForm
            />

            <Text
              classes="text-xs text-neutral-500 font-medium"
              translationKey="filters.budgetedFor.desc"
            />
          </div>
        </form>
      </div>
      <div className="flex justify-end gap-4 px-3 py-4 slider-footer">
        <Button
          label="misc.cancel"
          variant="tertiary"
          onClick={handleCancelClick}
          classes="w-15.5 text-neutral-500"
        />

        <Button
          disabled={
            isFormButtonDisabled ||
            isChangeInOldAndNewData ||
            !values?.modules?.length
          }
          form="budget-create-form"
          btnType="submit"
          label={
            isInEditMode
              ? "company.department.addNewProject.budgets.next"
              : "company.department.addNewProject.budgets.defineBudget"
          }
          classes="w-15.5"
        />
      </div>
    </>
  );
}

export default BudgetForm;

BudgetForm.propTypes = {
  setOnBack: PropTypes.func,
  isInEditMode: PropTypes.string,
};
