import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchAccountingTransactions,
  fetchFailedToSync,
  resetAccountingTransactionsList,
  updateTagAndVendor,
} from "@/store/reducers/accounting_transactions";
import { fetchRulesCount } from "@/store/reducers/rules";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  accountingReimbursementsFilterSelector,
  accountingTransactionsHasMoreSelector,
  accountingTransactionsSelector,
  accountingTransactionsTotalSelector,
  availableToSyncSelector,
  failedToSyncSelector,
  fetchingAvailableToSyncSelector,
  fetchingFailedToSyncSelector,
  isFetchingTransactionsSelector,
} from "@/store/selectors/accounting_transactions";
import {
  accountingIntegrationSoftwareSelector,
  claimAmountEditEnabledSelector,
  isFetchingClientDetailsSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { rulesCountSelector } from "@/store/selectors/rules";
import {
  accountingCategoryTagSelector,
  nonAccountingCategoryTagSelector,
  reimbursementCustomTagsSelector,
} from "@/store/selectors/tags";

import { formatDataForTable } from "@/components/Accounting/Transactions/Reimbursement/PageHelper/helper";
import TransactionsTable from "@/components/Accounting/Transactions/TransactionsTable";
import FailedSyncCard from "@/components/Accounting/Transactions/common/FailedSyncCard";
import RulesCard from "@/components/Accounting/Transactions/common/RulesCard";
import { convertFilters } from "@/utils/filters";
import { SORTING_CATEGORY } from "@/utils/constants/sorting";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_HEADER_IDS,
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
  TAB_STATUS_MAP,
} from "@/constants/accounting";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { REIMBURSEMENTS_TYPES } from "@/constants/reimbursement";

import AccountingTransactionSearchFilter from "../../AccountingTransactionSearchFilter";
import {
  accountingActionHandler,
  accountingBulkAction,
  formatAccountingStatusFilter,
  getSortingParams,
  stickyRightColumn,
} from "../../common/util";

export default function ReimbursementsPageHelper({ tab }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useState({
    type: null,
    category: SORTING_CATEGORY.AMOUNT,
  });
  // Transactions
  const transactions = useSelector(accountingTransactionsSelector);
  const claimAmountEditEnabled = useSelector(claimAmountEditEnabledSelector);
  const isFetchingTransactions = useSelector(isFetchingTransactionsSelector);
  const hasMore = useSelector(accountingTransactionsHasMoreSelector);
  const totalTransactions = useSelector(accountingTransactionsTotalSelector);

  // Available to Sync
  const { syncableCount, syncableIds } = useSelector(availableToSyncSelector);
  const fetchingAvailableToSync = useSelector(fetchingAvailableToSyncSelector);

  // Failed to sync
  const failedToSync = useSelector(failedToSyncSelector);
  const fetchingFailedToSync = useSelector(fetchingFailedToSyncSelector);

  // Accounting Software
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = accountingSoftware || false;

  // Filters
  const filters = useSelector(accountingReimbursementsFilterSelector);
  const appliedFilters = useSelector(appliedFilterSelector);
  const requiredFilters = formatAccountingStatusFilter(
    filters,
    tab,
    accountingEnabled
  );

  const isFetchingClient = useSelector(isFetchingClientDetailsSelector);

  // Tags
  const accountingCategoryTags = useSelector(accountingCategoryTagSelector);
  const nonAccountingTags = useSelector(nonAccountingCategoryTagSelector);
  const customTags = useSelector(reimbursementCustomTagsSelector);

  const vendors = useSelector(accountingVendorsSelector);

  // Rules
  const rulesCount = useSelector(rulesCountSelector);
  // Setting headers
  const headers = [
    {
      id: ACCOUNTING_HEADER_IDS.MERCHANT_OR_MILEAGE,
      label: "reimbursement.approvals.listHeading.type",
      classes: "text-left",
      checkbox: true,
      colWidth: 320,
    },
    {
      id: ACCOUNTING_HEADER_IDS.OWNER,
      label: "accounting.transactions.cards.listingFields.owner.header",
      classes: "text-left",
      colWidth: 300,
    },
    {
      id: ACCOUNTING_HEADER_IDS.PAYMENT_DATE,
      label: "accounting.transactions.cards.listingFields.paymentDate.header",
      classes: "text-left",
      colWidth: 300,
    },
    {
      id: ACCOUNTING_HEADER_IDS.AMOUNT,
      label: "accounting.transactions.cards.listingFields.amount.header",
      classes: "text-right justify-end",
      sortable: true,
      colWidth: 200,
    },
    {
      id: ACCOUNTING_HEADER_IDS.ACCOUNTING_TAG,
      label: accountingCategoryTags?.name,
      tagId: accountingCategoryTags?.id,
      classes: "text-left",
      options: accountingCategoryTags?.options,
      colWidth: 300,
      showColumn: accountingEnabled && (accountingCategoryTags ?? false),
    },
    {
      id: ACCOUNTING_HEADER_IDS.RECEIPT,
      label: "accounting.transactions.cards.listingFields.receipt.header",
      classes: "text-center flex-1",
      colWidth: 120,
    },
    {
      id: ACCOUNTING_HEADER_IDS.MEMO,
      label: "accounting.transactions.cards.listingFields.memo.header",
      classes: "text-center flex-1",
      colWidth: 120,
    },
    {
      id: ACCOUNTING_HEADER_IDS.STATUS,
      label: "accounting.transactions.cards.listingFields.status.header",
      classes: "text-center flex-1",
      colWidth: 200,
    },
    ...(nonAccountingTags?.map((tag) => ({
      ...tag,
      actualId: tag?.id,
      id: ACCOUNTING_HEADER_IDS.NON_ACCOUNTING_TAG,
      label: tag.name,
      tagId: tag.id,
      classes: "text-left",
      options: tag.options,
      colWidth: 300,
      showColumn: accountingEnabled,
    })) ?? []),

    ...(customTags?.map((tag) => ({
      ...tag,
      actualId: tag?.id,
      id: ACCOUNTING_HEADER_IDS.CUSTOM_TAG,
      label: tag.name,
      tagId: tag.id,
      classes: "text-left",
      options: tag.options,
      colWidth: 300,
      showColumn: customTags?.length,
    })) ?? []),
    {
      id: ACCOUNTING_HEADER_IDS.LINKED_TO,
      label: "Linked to",
      classes: "text-left",
      showColumn: true,
      colWidth: 200,
    },
    {
      id: ACCOUNTING_HEADER_IDS.ACTION,
      label: "accounting.transactions.cards.listingFields.action.header",
      classes: "text-center justify-center flex-1 items-center",
      showColumn: tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED,
      colWidth: 145,
    },
    {
      id: ACCOUNTING_HEADER_IDS.OPEN_IN,
      label: "Open in",
      classes: "text-center justify-center",
      showColumn:
        tab === ACCOUNTING_TRANSACTION_STATUS.SYNCED &&
        ![
          ACCOUNTING_SOFTWARES.UNIVERSAL_CSV,
          ACCOUNTING_SOFTWARES.TALLY,
        ].includes(accountingSoftware),
      colWidth: 145,
    },
  ].filter((item) => item?.showColumn ?? true);

  // Selected transactions
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedTransactionObjects, setSelectedTransactionObjects] = useState(
    []
  );
  const [bulkApproveSelectAll, setBulkApproveSelectAll] = useState(false);

  // Infinite Scroll
  const onReset = () => {
    dispatch(resetAccountingTransactionsList());
  };

  const loadMore = (pageNumber = 1, onSuccess = () => {}) => {
    // we have 2 cases, connected to accounting and NOT connected to accounting software
    // Not connected: we need to ensure that we're calling the API on first load
    // Connected: only call the API if tab is passed to prevent duplicate calls when refreshing on pending and synced tabs
    if ((tab || !accountingEnabled) && !isFetchingClient) {
      dispatch(
        fetchAccountingTransactions({
          type: ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENTS,
          accounting_status: TAB_STATUS_MAP[tab],
          page: pageNum || pageNumber,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          ...getSortingParams(sorting),
          ...convertFilters(appliedFilters),
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (!isFetchingClient) {
      loadMore();
    }
  }, [isFetchingClient]);

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...getSortingParams(sorting),
      ...convertFilters(appliedFilters),
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll, {
    root: null,
    threshold: 0.25,
  });

  // First load
  useEffect(() => {
    dispatch(
      fetchFailedToSync({
        transaction: ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT,
      })
    );
    dispatch(fetchRulesCount());
  }, []);

  // Converting transactions to data format
  // replaced transactions with newTransactions(with mock memo data)
  const formattedTransactions = useMemo(() => {
    return transactions?.map((transaction) =>
      formatDataForTable(transaction, {
        claimAmountEditEnabled,
      })
    );
  }, [transactions]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(resetAccountingTransactionsList());
    };
  }, [tab]);

  const handleViewReimbursementTransaction = (id) => {
    if (
      !searchParams.has(SLIDERS_SEARCH_PARAMS.accounting.reimbursement.id, id)
    ) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.accounting.reimbursement.id,
        id
      );
      setSearchParams(searchParams);
    }
  };

  const handleUpdateTagAndVendor = (payload) => {
    payload.transaction_type = ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT;
    dispatch(updateTagAndVendor({ payload }));
  };

  const handleFailedToSyncSliderOpen = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.failedToSync,
      ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT
    );
    setSearchParams(searchParams);
  };

  const openRules = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.id, "true");
    setSearchParams(searchParams);
  };

  // Setting props for table
  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    numberOfStickyColsRight: stickyRightColumn(accountingSoftware, tab) ? 1 : 0,
    colWidths: headers.map((header) => header.colWidth),
    rightColWidths: stickyRightColumn(accountingSoftware, tab) ? [145] : [],
    emptyDataIconSrc: "emptyScreen.svg",
  };

  return (
    <>
      {accountingEnabled ? (
        <div className="flex items-center gap-4 mt-6 mb-1">
          {accountingSoftware !== ACCOUNTING_SOFTWARES.UNIVERSAL_CSV ? (
            <FailedSyncCard
              count={failedToSync}
              isFetching={fetchingFailedToSync}
              handleClick={() => handleFailedToSyncSliderOpen()}
            />
          ) : null}
          <RulesCard rulesCount={rulesCount} viewRules={openRules} />
        </div>
      ) : null}

      <div className="mb-2">
        <AccountingTransactionSearchFilter
          filters={requiredFilters}
          showSyncAll={
            accountingEnabled && tab !== ACCOUNTING_TRANSACTION_STATUS.SYNCED
          }
          availableToSync={syncableCount}
          fetchingAvailableToSync={fetchingAvailableToSync}
          syncAll={accountingBulkAction}
          syncableIds={syncableIds}
          page={ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT}
        />
      </div>
      {formattedTransactions ? (
        <TransactionsTable
          pageNum={pageNum}
          tab={tab}
          page={ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT}
          tableArgs={tableArgs}
          headers={headers}
          originalTransactions={transactions}
          transactions={formattedTransactions}
          totalTransactions={totalTransactions}
          isFetching={isFetchingTransactions}
          hasMore={hasMore}
          dateToBeGrouped={(selectItem) => {
            return selectItem?.type === REIMBURSEMENTS_TYPES.MILEAGE
              ? "travelDate"
              : "transactionDate";
          }}
          actionDisabled={!accountingSoftware}
          handleRefChange={handleRefChange}
          handleBulkAction={accountingBulkAction}
          loadMore={loadMore}
          selectedTransactions={selectedTransactions}
          selectedTransactionObjects={selectedTransactionObjects}
          onActionClick={accountingActionHandler}
          onViewTransactionClick={handleViewReimbursementTransaction}
          handleUpdateTagAndVendor={handleUpdateTagAndVendor}
          bulkApproveSelectAll={bulkApproveSelectAll}
          setSelectedTransactions={setSelectedTransactions}
          setSelectedTransactionObjects={setSelectedTransactionObjects}
          setBulkApproveSelectAll={setBulkApproveSelectAll}
          sorting={sorting}
          setSorting={setSorting}
        />
      ) : null}
    </>
  );
}

ReimbursementsPageHelper.propTypes = {
  tab: PropTypes.string,
};
