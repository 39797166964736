import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { VP_ERROR_COMPONENT_TYPE } from "@/constants/common";

export default function MissingItemsCard({
  data,
  isFetching,
  viewMissingItems,
  showSection = true,
}) {
  const { t } = useTranslation("translation");
  const allState = useLoadingErrorInjector({
    apiKey: "Expenses:getMissingDetailsCount",
    showLoader: true,
    error: {
      header: t(
        "cards.cardDetails.overview.policyDetails.expenses.missingDetails"
      ),
      type: VP_ERROR_COMPONENT_TYPE.COMPACT,
    },
  });

  return showSection ? (
    data?.count || allState?.error ? (
      <div
        ref={allState?.attach}
        className={`flex items-center py-4 px-3 rounded-xl hover:shadow-md w-17 h-full cursor-pointer border border-neutral-200 ${
          isFetching ? "hover:cursor-not-allowed" : ""
        }`}
        onClick={isFetching ? () => {} : viewMissingItems}
      >
        {/* {!isFetching && data && ( */}
        <div className="flex items-center justify-center rounded-full bg-danger-100 w-9 h-9">
          <Icon
            name="MissingBadge"
            className="w-5 h-5 rounded-full text-danger-400"
          />
        </div>
        <div className="flex flex-col ml-4">
          <Text
            translationKey="expenses.overview.missingDetails"
            classes="text-neutral-500 text-sm font-semibold"
          />
          <span className="flex items-center gap-2 text-xl font-semibold text-neutral-800">
            {data?.count}
            <Icon name="NEArrow" />
          </span>
        </div>
        {/* )} */}
        {/* {isFetching && <MissingCardLoader />} */}
      </div>
    ) : null
  ) : null;
}

MissingItemsCard.propTypes = {
  data: PropTypes.object,
  isFetching: PropTypes.bool,
  viewMissingItems: PropTypes.func,
  showSection: PropTypes.bool,
};
