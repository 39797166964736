import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { isValidPhoneNumber } from "libphonenumber-js";
import {
  dateToString,
  getConstantTimeDate,
  testRegex,
  toTitleCase,
} from "@/utils/common";
import {
  REGEX_VALUE,
  REST_PASSWORD_INPUT_NAME,
  USE_FORM_TYPE_OF_VALIDATION,
} from "@/utils/constantUseForm";

// this i'm using for free known error also use can pass there own error
// TODO add translation
export const ERROR_VALUE = {
  password: { regex: "Incorrect password" },
  email: { regex: "Please enter a valid email" },
  otp: { max: "Not a valid otp", number: "otp can only be of number type" },
};
/**
 * @callback SubmitCallback
 * @param {Object} values to set values
 */
/**
 * @param {Object} initialValue
 * @param {SubmitCallback} callback
 * @param {Object} options
 * @returns
 */

// TODO: move to different file, when all useForm changes have been merged file
function getErrorStatementByValidationType(
  initialValidateEntry,
  currentErrorStatement,
  t
) {
  const retVal = currentErrorStatement ?? {};

  Object.keys(initialValidateEntry ?? {}).forEach((k) => {
    if (k === "required" || retVal[k]) return;

    switch (k) {
      case USE_FORM_TYPE_OF_VALIDATION.dateBetween:
        {
          let [startDate, endDate] = initialValidateEntry[k];
          startDate = dateToString(startDate);
          endDate = dateToString(endDate);

          retVal[k] = t("form.errorStatements.dateBetween", {
            startDate,
            endDate,
          });
        }
        break;
      case USE_FORM_TYPE_OF_VALIDATION.onOrAfterDate:
        {
          const afterDate = dateToString(initialValidateEntry[k]);
          retVal[k] = t("form.errorStatements.onOrAfterDate", { afterDate });
        }
        break;
      case USE_FORM_TYPE_OF_VALIDATION.onOrBeforeDate:
        {
          const beforeDate = dateToString(initialValidateEntry[k]);
          retVal[k] = t("form.errorStatements.onOrBeforeDate", { beforeDate });
        }
        break;
      case USE_FORM_TYPE_OF_VALIDATION.afterDate:
        {
          const afterDate = dateToString(initialValidateEntry[k]);
          retVal[k] = t("form.errorStatements.onOrAfterDate", { afterDate });
        }
        break;
      case USE_FORM_TYPE_OF_VALIDATION.beforeDate:
        {
          const beforeDate = dateToString(initialValidateEntry[k]);
          retVal[k] = t("form.errorStatements.onOrBeforeDate", { beforeDate });
        }
        break;
      case USE_FORM_TYPE_OF_VALIDATION.max:
        retVal[k] = t("form.errorStatements.max", {
          length: initialValidateEntry[k],
        });
        break;
      case USE_FORM_TYPE_OF_VALIDATION.email:
        retVal[k] = t("form.errorStatements.email");
        break;
      case USE_FORM_TYPE_OF_VALIDATION.maxNumber:
        retVal[k] = t("form.errorStatements.maxNumber", {
          number: initialValidateEntry[k],
        });
        break;
      case USE_FORM_TYPE_OF_VALIDATION.minNumber:
        retVal[k] = t("form.errorStatements.minNumber", {
          number: initialValidateEntry[k],
        });
        break;
      default:
        break;
    }
  });

  return retVal;
}

export const useForm = (
  initialValue,
  callback,
  options = {
    extraErrorConditions: false,
    isForResetPassword: false,
    needBtnDisable: true,
    checkMinMax: false,
    formId: "",
    retainState: false, // retain field value (from hook) even when input to useForm changes, if initialValue value is empty. Default: false (OFF)
  }
) => {
  const { t } = useTranslation();

  let Error = {};
  const newOptions = {
    isForResetPassword: false,
    needBtnDisable: true,
    ...options,
  };
  // Form values
  const [values, setValues] = useState({});

  const resetValue = () => setValues({});
  // Errors
  const [errors, setErrors] = useState({});
  // validate object
  const [validator, setValidator] = useState({});
  //   validate Password
  const [passwordValidate, setPasswordValidate] = useState({});
  // isDisabled
  const [isFormButtonDisabled, setIsFormButtonDisabled] = useState(true);
  const [fieldsVisibility, setFieldsVisibility] = useState({});
  // useForm gives all values it received as input argument, even if those fields have no UI node
  // this is helpful, like for keeping derived state. The following state exposes whether
  // a field is visible at the moment or not, and works also after onSubmit
  // Use this state to figure out `_destroy` as needed in Rails to signal deletion of array element

  // seperating initial value into to parts
  useEffect(() => {
    const keys = Object.keys(initialValue);
    const retrievedValue = {};
    const Validators = {};
    keys.forEach((val) => {
      retrievedValue[val] = options?.retainState
        ? initialValue[val].value || values[val]
        : initialValue[val].value;
      Validators[val] = initialValue[val].validate;
      ERROR_VALUE[val] = getErrorStatementByValidationType(
        initialValue[val].validate,
        initialValue[val].errorStatement,
        t
      );
    });
    setValues(retrievedValue);
    setValidator(Validators);
    // making reset password state as false
    setPasswordValidate({
      case1: false,
      case2: false,
      case3: false,
      case4: false,
      case5: false,
    });
  }, [JSON.stringify(initialValue)]);

  // update error State
  const updateSateInError = (stateName, error = null, errorOptions = {}) => {
    if (!Error[stateName]) {
      let newError = "";
      if (!errorOptions?.isMultipleValidationError)
        newError =
          error && ERROR_VALUE[stateName] && ERROR_VALUE[stateName][error]
            ? ERROR_VALUE[stateName][error]
            : `${toTitleCase(stateName)} is not in correct format`;
      // handling showing error in case of multiple validation error
      if (errorOptions?.isMultipleValidationError) {
        const feildsString = errorOptions?.dependentsKeys?.reduce(
          (accum, curr, index, arr) =>
            `${accum} ${
              accum.length !== 0
                ? index === arr.length - 1
                  ? " and "
                  : " , "
                : ""
            } ${curr}`,
          ""
        );

        newError =
          error &&
          ERROR_VALUE?.multiple &&
          ERROR_VALUE?.multiple[stateName] &&
          ERROR_VALUE?.multiple[stateName][error]
            ? ERROR_VALUE?.multiple[stateName][error]
            : `${feildsString} not in valid format`;
      }
      Error = {
        ...Error,
        [stateName]: newError,
      };
    }
  };

  // updateing valid state
  const updateStatesInValid = (stateName) => {
    const _err = Error;
    if (_err) delete _err[stateName];
    Error = {
      ..._err,
    };
  };

  const isValid = (value) => {
    const doesNotExist = [null, undefined].includes(value);
    const emptyString = typeof value === typeof "" ? value.length === 0 : false;
    const emptyArray = Array.isArray(value) ? value.length === 0 : false;

    return doesNotExist || emptyString || emptyArray;
  };

  const checkOtherValidation = (value, name) => {
    if (!validator[name].required) {
      if (Array.isArray(value)) {
        if (value.length) {
          return !value.some(isValid);
        }
      } else {
        const tValue = typeof value === typeof "" ? value.trim() : value; // string validation ignores spaces at the ends

        return !isValid(tValue);
      }
    } else {
      return true;
    }
  };
  const validate = (type, name, value, extraOptions = {}) => {
    const innerFunctionValidation = (
      validateObject,
      GOT_ERROR,
      innerFuntionOptions = { isMultipleValidationError: null }
    ) => {
      Object.keys(validateObject).forEach((key) => {
        if (!GOT_ERROR)
          switch (key) {
            case USE_FORM_TYPE_OF_VALIDATION.regex:
              if (!testRegex(validateObject[key], value)) {
                GOT_ERROR = true;
                updateSateInError(name, key, innerFuntionOptions);
              } else updateStatesInValid(name);
              break;

            case USE_FORM_TYPE_OF_VALIDATION.number:
              {
                const regexObject = REGEX_VALUE.number;
                if (
                  validateObject[key] === true &&
                  !testRegex(regexObject?.all, value)
                ) {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                } else if (
                  validateObject[key] ===
                    USE_FORM_TYPE_OF_VALIDATION.positive &&
                  !testRegex(regexObject.positive, value)
                ) {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                } else if (
                  validateObject[key] ===
                    USE_FORM_TYPE_OF_VALIDATION.negative &&
                  !testRegex(regexObject.negative, value)
                ) {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                } else updateStatesInValid(name);
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.string:
              if (
                type === USE_FORM_TYPE_OF_VALIDATION.text &&
                typeof value === typeof "" &&
                testRegex(REGEX_VALUE.number.all, value)
              ) {
                GOT_ERROR = true;
                updateSateInError(name, key, innerFuntionOptions);
              } else updateStatesInValid(name);
              break;
            case USE_FORM_TYPE_OF_VALIDATION.max:
              if (value?.length > validateObject[key]) {
                GOT_ERROR = true;
                updateSateInError(name, key, innerFuntionOptions);
              } else updateStatesInValid(name);
              break;
            case USE_FORM_TYPE_OF_VALIDATION.min:
              if (value?.length < validateObject[key]) {
                GOT_ERROR = true;
                updateSateInError(name, key, innerFuntionOptions);
              } else updateStatesInValid(name);
              break;
            case USE_FORM_TYPE_OF_VALIDATION.minDecimal:
              {
                const regexObject = REGEX_VALUE.number;
                if (!testRegex(regexObject.all, value)) {
                  updateSateInError(
                    name,
                    USE_FORM_TYPE_OF_VALIDATION.number,
                    innerFuntionOptions
                  );
                } else if (
                  !value.includes(".") ||
                  (value.includes(".") &&
                    value.split(".")[1]?.length < validateObject[key])
                ) {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                } else updateStatesInValid(name);
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.maxDecimal:
              {
                const regexObject = REGEX_VALUE.number;
                if (!testRegex(regexObject.all, value)) {
                  updateSateInError(
                    name,
                    USE_FORM_TYPE_OF_VALIDATION.number,
                    innerFuntionOptions
                  );
                } else if (
                  value.includes(".") &&
                  value.split(".")[1]?.length > validateObject[key]
                ) {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                } else updateStatesInValid(name);
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.minNumber:
              if (!testRegex(REGEX_VALUE.number.all, value)) {
                GOT_ERROR = true;
                updateSateInError(name, key, innerFuntionOptions);
              } else if (parseFloat(value) <= validateObject[key]) {
                GOT_ERROR = true;
                updateSateInError(name, key, innerFuntionOptions);
              } else updateStatesInValid(name);
              break;
            case USE_FORM_TYPE_OF_VALIDATION.maxNumber:
              if (!testRegex(REGEX_VALUE.number.all, value)) {
                GOT_ERROR = true;
                updateSateInError(name, key, innerFuntionOptions);
              } else if (parseFloat(value, 10) > validateObject[key]) {
                GOT_ERROR = true;
                updateSateInError(name, key, innerFuntionOptions);
              } else updateStatesInValid(name);
              break;
            case USE_FORM_TYPE_OF_VALIDATION.oneOf:
              {
                const valid =
                  validator[name][USE_FORM_TYPE_OF_VALIDATION.oneOf].includes(
                    value
                  );
                if (valid) {
                  updateStatesInValid(name);
                } else {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                }
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.compare:
              {
                const namesKey =
                  validator[name][USE_FORM_TYPE_OF_VALIDATION.compare]?.keys;

                const comparatorFunc =
                  validator[name][USE_FORM_TYPE_OF_VALIDATION.compare]
                    ?.comparatorFunc;

                const valuesArray = namesKey.map((val) =>
                  name === val ? value : values[val]
                );

                let valid = false;
                if (valuesArray.length >= 2) {
                  const array = [];
                  for (let i = 0; i < valuesArray.length - 1; i += 1) {
                    array.push(
                      comparatorFunc(valuesArray[i], valuesArray[i + 1])
                    );
                  }

                  valid = array.every((val) => val);
                }
                if (valid) {
                  updateStatesInValid(name);
                } else {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                }
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.onOrAfterDate:
              {
                const validateAgainstDate =
                  validator[name][USE_FORM_TYPE_OF_VALIDATION.onOrAfterDate];

                const valid =
                  getConstantTimeDate(value) >=
                  getConstantTimeDate(validateAgainstDate);

                if (valid) {
                  updateStatesInValid(name);
                } else {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                }
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.onOrBeforeDate:
              {
                const validateAgainstDate =
                  validator[name][USE_FORM_TYPE_OF_VALIDATION.onOrBeforeDate];

                const valid =
                  getConstantTimeDate(value) <=
                  getConstantTimeDate(validateAgainstDate);

                if (valid) {
                  updateStatesInValid(name);
                } else {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                }
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.dateBetween:
              {
                let [startDate, endDate] =
                  validator[name][USE_FORM_TYPE_OF_VALIDATION.dateBetween];

                startDate = getConstantTimeDate(startDate);
                endDate = getConstantTimeDate(endDate);

                const valueAsDate = getConstantTimeDate(value);

                const valid =
                  startDate <= valueAsDate && valueAsDate <= endDate;

                if (valid) {
                  updateStatesInValid(name);
                } else {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                }
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.afterDate:
              {
                const validateAgainstDate =
                  validator[name][USE_FORM_TYPE_OF_VALIDATION.afterDate];

                const valid =
                  getConstantTimeDate(value) >
                  getConstantTimeDate(validateAgainstDate);

                if (valid) {
                  updateStatesInValid(name);
                } else {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                }
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.beforeDate:
              {
                const validateAgainstDate =
                  validator[name][USE_FORM_TYPE_OF_VALIDATION.beforeDate];

                const valid =
                  getConstantTimeDate(value) <
                  getConstantTimeDate(validateAgainstDate);

                if (valid) {
                  updateStatesInValid(name);
                } else {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                }
              }
              break;
            case USE_FORM_TYPE_OF_VALIDATION.phoneNumber:
              {
                const _value = value ?? "";
                const isPhoneNumberValid = isValidPhoneNumber(_value);

                if (!isPhoneNumberValid) {
                  GOT_ERROR = true;
                  updateSateInError(name, key, innerFuntionOptions);
                } else {
                  updateStatesInValid(name);
                }
              }
              break;
            default:
              break;
          }
      });
      return GOT_ERROR;
    };

    // A function to validate each input values
    if (
      !extraOptions?.isMultiple &&
      validator[name] &&
      checkOtherValidation(value, name)
    ) {
      const validateObject = validator[name];
      const GOT_ERROR = false;

      return innerFunctionValidation(validateObject, GOT_ERROR);
    }
    if (extraOptions?.isMultiple) {
      const GOT_ERROR = false;
      const validateObject = extraOptions?.validateObject;
      return innerFunctionValidation(validateObject, GOT_ERROR, {
        isMultipleValidationError: extraOptions?.isMultiple,
        dependentsKeys: extraOptions?.dependentsKeys,
      });
    }
  };

  // match all cases
  const matchAll = (str, regEx, count) => {
    const value = [...(str?.matchAll(new RegExp(regEx, "g")) ?? [])].map(
      (val) => val[0]
    );
    return value?.length >= count;
  };

  // reset password validation
  const resetPasswordValidation = (type, name, value) => {
    if (name !== REST_PASSWORD_INPUT_NAME.confirmPassword) {
      const uniqueValue = [...new Set(value)].join("");
      // atleast 10 character
      if (value.trim()?.length >= 10) {
        setPasswordValidate((prev) => {
          return { ...prev, case1: true };
        });
      } else
        setPasswordValidate((prev) => {
          return { ...prev, case1: false };
        });
      //   atleast 1 or more numerics character
      if (matchAll(value, /\d/, 1)) {
        setPasswordValidate((prev) => {
          return { ...prev, case2: true };
        });
      } else
        setPasswordValidate((prev) => {
          return { ...prev, case2: false };
        });
      // atleast 1 or more upper case character
      if (matchAll(value, /[A-Z]/, 1)) {
        setPasswordValidate((prev) => {
          return { ...prev, case3: true };
        });
      } else
        setPasswordValidate((prev) => {
          return { ...prev, case3: false };
        });
      //   atleast 1 or more lower case character
      if (matchAll(value, /[a-z]/, 1)) {
        setPasswordValidate((prev) => {
          return { ...prev, case4: true };
        });
      } else
        setPasswordValidate((prev) => {
          return { ...prev, case4: false };
        });
      // 1 or more special character
      if (matchAll(value, /[@#$%^&+*!=]/, 1)) {
        setPasswordValidate((prev) => {
          return { ...prev, case5: true };
        });
      } else
        setPasswordValidate((prev) => {
          return { ...prev, case5: false };
        });
    }
  };

  // for disabling button
  // all required feilds are filled or not
  useEffect(() => {
    // ui element visible
    const formNode = options?.formId
      ? document.getElementById(options?.formId)
      : null;

    if (Object.keys(validator).length) {
      if (newOptions.needBtnDisable) {
        const formDisabledOrNot = Object.keys(validator)
          .filter(
            (key) =>
              validator[key] &&
              (!validator[key]?.required || !validator[key]?.requiredIf)
          )
          .map((key) => {
            const fieldNode = formNode?.elements?.[key];
            const fieldInvisible = options?.formId && !fieldNode; // to prevent auto 'Success' if options.formId is not passed
            setFieldsVisibility((prev) => {
              return { ...prev, [key]: !!fieldNode };
            });
            if (fieldInvisible) return true;
            // bypassing validate for non visible in UI values

            const validateObject = validator[key];

            if (
              validateObject?.maxNumber &&
              validateObject?.maxNumber < parseFloat(values[key])
            )
              return false;

            if (
              validateObject?.minNumber &&
              validateObject?.minNumber > parseFloat(values[key])
            )
              return false;

            // validate if required is there
            if (validateObject?.required) return !!values[key];
            // if reuired is not present check for requiredIf flag and validate
            if (validateObject?.requiredIf) {
              const requiredIfName = validateObject?.requiredIf?.name;
              let requiredIfValue = validateObject?.requiredIf?.value;
              requiredIfValue =
                typeof requiredIfValue === typeof (() => {})
                  ? requiredIfValue()
                  : requiredIfValue;
              const currentValueRequiredIf = values[requiredIfName];

              if (requiredIfValue === currentValueRequiredIf) {
                return !!values[key];
              }
            }

            return true;
          });

        setIsFormButtonDisabled(
          !formDisabledOrNot.every((val) => val) ||
            !!Object.keys(errors).length ||
            (newOptions?.isForResetPassword &&
              Object.values(passwordValidate).some((a) => !a))
        );
      }
    }
  }, [values]);
  //  onChange Error
  const handleOnChangeError = (event) => {
    const { name, value, type } = event.target;

    const formNode = options?.formId
      ? document.querySelector(`#${options?.formId}`)
      : null;

    const mockedFormEvent = { target: { elements: formNode?.elements } };

    // ordering important (required, then other validations)
    const isRequired = validateRequire(event, true);
    const gotError = validate(type, name, value);

    const multipleValidateArray = newOptions?.multiValidate;
    multipleValidateArray?.forEach((obj) => {
      multipleFeildValidator(mockedFormEvent, obj);
    });
    const isMinMaxValid = options.checkMinMax ? validateMinMax() : true;

    if (isRequired && !gotError && isMinMaxValid)
      setErrors((prev) => {
        const _prev = prev;
        delete _prev[name];
        return _prev;
      });
    else if (!isRequired) {
      setErrors((prev) => {
        const _prev = prev;

        return {
          ..._prev,
          [name]:
            ERROR_VALUE[name] && ERROR_VALUE[name].required
              ? ERROR_VALUE[name].required
              : t("misc.required"),
        };
      });
    } else if (gotError || isMinMaxValid) {
      setErrors((prev) => ({
        ...prev,
        ...Error,
      }));
    }
  };

  //  A method to handle form inputs
  const handleChange = (event, object = null) => {
    const { name, value, type } = event.target;

    handleOnChangeError(event);

    // Let's set these values in state
    setValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (newOptions.isForResetPassword) {
      resetPasswordValidation(type, name, value);
    }
  };

  // onSubmit checking required
  const validateRequire = (e, singleValidate = false) => {
    if (singleValidate && validator?.[e?.target?.name]?.required) {
      const { value } = e.target;
      const tValue = typeof value === typeof "" ? value.trim() : value;
      return !isValid(tValue);
    }
    if (!singleValidate)
      Object.keys(validator).forEach((key) => {
        if (!e.target.elements[key]) return false; // bypassing validate for non visible in UI values

        const tValue =
          typeof values[key] === typeof "" ? values[key].trim() : values[key];

        if (validator[key] && validator[key].required) {
          const check = isValid(tValue);

          if (check)
            Error = {
              ...Error,
              [key]:
                ERROR_VALUE[key] && ERROR_VALUE[key].required
                  ? ERROR_VALUE[key].required
                  : t("misc.required"),
            };
        }
      });

    return Object.keys(Error).length === 0;
  };

  const multipleFeildValidator = (event, obj) => {
    const fields = obj?.fields;
    const validateObject = obj?.validate;
    const errorStatement = obj?.errorStatement;

    // concatted value
    const concatValue = fields
      ?.map((key) => values[key])
      ?.reduce((accum, currVal) => accum + currVal, "");

    fields?.map((fieldString) => {
      if (event.target.elements[fieldString]) {
        const { name, type } = event.target.elements[fieldString];
        ERROR_VALUE.multiple = {
          ...ERROR_VALUE.multiple,
          [fieldString]: errorStatement,
        };
        validate(type, name, concatValue, {
          dependentsKeys: fields,
          validateObject,
          isMultiple: true,
        });
      }
    });
  };

  const validateMinMax = () => {
    // Use `minAmount` and `maxAmount` for all use cases
    const min = parseFloat(values.minAmount);
    const max = parseFloat(values.maxAmount);

    if (min > max) {
      Error = {
        ...Error,
        maxAmount: "filters.amountFormError",
      };
      return false;
    }
    return true;
  };
  // A method to submit form input
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
      const keys = Object.keys(values);
      const multipleValidateArray = newOptions?.multiValidate;

      keys.forEach((key) => {
        if (event.target.elements[key]) {
          const { name, value, type } = event.target.elements[key];
          validate(type, name, value);
        }

        setFieldsVisibility((prev) => {
          return { ...prev, [key]: !!event.target.elements[key] };
        });
      });

      const isRequireValid = validateRequire(event);

      const isMinMaxValid = options.checkMinMax ? validateMinMax() : true;

      if (Error && Object.keys(Error).length === 0) {
        // after single validation validating multi validation if any
        multipleValidateArray?.forEach((obj) => {
          multipleFeildValidator(event, obj);
        });
        // checking for multiple validation
        if (Error && Object.keys(Error).length === 0) {
          if (
            isRequireValid &&
            isMinMaxValid &&
            !options.extraErrorConditions
          ) {
            // using state setter to get latest state, but not updating state.
            setFieldsVisibility((latestFieldsVisibility) => {
              callback(event, values, resetValue, {
                fieldsVisibility,
                setErrors,
                setValues,
              });
              return latestFieldsVisibility;
            });
          }
        }
      }

      setErrors(Error);
    }
  };

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    isFormButtonDisabled,
    passwordValidate,
    fieldsVisibility, // Simple object with field key names and true (if they are visible), else false.

    _setValues: setValues, // used for very rare cases. prefer using 'handleChange' with input components (Input, VpSelect etc). This is used for clearing values.
    _setErrors: setErrors, // TECH_DEBT, used to fix bug, i.e. clearing errors (when form is discarded form)
  };
};
