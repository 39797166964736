import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { cardsListSelector } from "@/store/selectors/cards";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import {
  userAllowedActionsConfigSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { checkIfUserActionAllowed } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

const selectedTabs = [
  {
    name: "cards.cardStatuses.all",
    count: null,
    path: ROUTES.myVolopay.cards.virtualCards.all.absolutePath,
    key: 1,
  },
  {
    name: "cards.cardStatuses.blocked",
    count: null,
    path: ROUTES.myVolopay.cards.virtualCards.blocked.absolutePath,
    key: 2,
  },
];
function MyVolopayVirtualCardsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(selectedTabs[0]);
  const currentUser = useSelector(userSelector);

  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  function handleVirtualCardButtonClick() {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage,
      true
    );
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.selectedUserCard,
      currentUser?.id
    );
    setSearchParams(searchParams);
  }

  return (
    <div className="px-8">
      <div className="flex items-end justify-between pb-10">
        <div className="flex flex-col">
          <Text
            translationKey="common.modules.breadcrumbs"
            color="neutral-500"
            classes="text-sm font-semibold"
          />

          <Text
            classes="text-4xl font-extrabold"
            color="neutral-800"
            translationKey="cards.vCards.title"
          />
        </div>

        {checkIfUserActionAllowed(
          allNavUrls,
          userAllowedActions,
          USER_ALLOWED_CTA_KEYS.VIRTUAL_CARD
        ) ? (
          <Button
            label="cards.vCards.orderButtonLabel"
            preIcon="Plus"
            classes="h-11 w-[calc(theme(spacing.16)+theme(spacing.5))] text-btn-lg font-semibold"
            onClick={handleVirtualCardButtonClick}
          />
        ) : null}
      </div>
      <Tabs
        items={selectedTabs}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => setSelectedTab(tab)}
      />
    </div>
  );
}

export default MyVolopayVirtualCardsPage;
