import React from "react";
import { useSelector } from "react-redux";

import { selectedUserSelector } from "@/store/selectors/user";

import ForgotPassword from "@/components/ForgotPassword";

export default function ForgotPasswordPage() {
  const mfaSelected = useSelector(selectedUserSelector);
  return <ForgotPassword />;
}
