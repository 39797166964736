// react imports
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { deleteRule } from "@/store/reducers/rules";

import { isLoadingRulesSelector } from "@/store/selectors/rules";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { DELETE_RULE_TYPES } from "@/constants/rules";

export default function DeleteRuleModal({ showModal, setShowModal }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const onCloseModal = () => {
    setShowModal(false);
  };

  const isLoading = useSelector(isLoadingRulesSelector);

  const ruleId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.rules.ruleId
  );

  const handleDelete = async () => {
    const payload = { id: ruleId, type: DELETE_RULE_TYPES.rule };
    const action = await dispatch(deleteRule({ payload, onSuccess: null }));

    if (deleteRule.fulfilled.match(action)) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.newEditRule);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.ruleId);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.newEditRule);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.accounting.rules.volopayField);
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.accounting.rules.accountingField
      );
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.accounting.rules.newEditAdvancedRule
      );

      setSearchParams(searchParams);
      onCloseModal();
    }
  };

  return (
    <Modal
      onClose={onCloseModal}
      open={showModal}
      className="flex items-center justify-center z-[1000]"
    >
      <div className="pt-2 w-89 h-fit flex flex-col gap-5 bg-white border border-neutral-200 rounded-2xl">
        <div className="px-6 flex flex-col gap-4">
          <div className="pt-4 pb-2">
            <Icon name="WarningCircle" className="w-9 h-9 text-danger-500" />
          </div>
          <div className="flex flex-col gap-4">
            <Text
              translationKey="accounting.rules.deleteRule.deleteConfirmation.title"
              classes="text-lg font-bold text-neutral-800 py-2"
            />
            <Text
              translationKey="accounting.rules.deleteRule.deleteConfirmation.description"
              classes="text-sm font-semibold text-neutral-500 break-words py-0.5"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 px-6">
          <div className="flex items-center gap-6 py-6">
            <Button
              label="accounting.rules.deleteRule.btnLabels.cancel"
              classes="px-5 py-3 text-base font-semibold text-neutral-500"
              variant="tertiary"
              onClick={onCloseModal}
            />
            <Button
              label="accounting.rules.deleteRule.title"
              type="danger"
              classes="px-5 py-3 text-base font-semibold"
              onClick={() => handleDelete()}
              showLoader={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

DeleteRuleModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};
