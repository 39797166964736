import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import CircleProgress from "@/components/core/CircleProgress";
import Text from "@/components/core/Text";
import { limitDecimalsWithRounding } from "@/utils/common";

import "./index.scss";

export default function BudgetChartLegends({ chartRef, data }) {
  const customLegendsContainerRef = useRef(); // JSX is evaluate but invisible

  const values = data.map(({ value }) => value);
  const sumOfValues = values.reduce((a, b) => a + b, 0);

  // Move rendered HTML to legends inside apexchart
  // i.e. explicitly replace text node HTML of legend since hover doesn't work with custom labels
  useEffect(() => {
    const chartOutermostNode = chartRef?.current?.chartRef?.current;
    const legendItems = [
      ...document.querySelectorAll(".apexcharts-legend-text"),
    ].filter((legendItem) => chartOutermostNode?.contains(legendItem));

    // get HTML nodes from rendered JSX
    const customLegends = [...customLegendsContainerRef.current.children];

    // there are 3 parts to a legend
    // 1. Container (.apexcharts-legend-series) - handled using CSS
    // 2. Marker (.apexcharts-legend-marker) - handled using CSS
    // 3. Label (.apexcharts-legend-text) - HTML replaced

    legendItems?.forEach((legendItem, index) => {
      legendItem.innerHTML = customLegends[index]?.innerHTML;
    });

    // clean up set HTML
    return () => {
      legendItems.forEach((legendItem, index) => {
        legendItem.innerHTML = "";
      });
    };
  });

  return (
    <div ref={customLegendsContainerRef} className="hidden">
      {data &&
        data.map(({ label, value, valueText }) => {
          const percentage =
            sumOfValues > 0
              ? limitDecimalsWithRounding((100 * value) / sumOfValues)
              : 0.0;

          return (
            <div
              key={label}
              aria-label="buffer-div-gets-ignored-during-HTML-transfer"
            >
              <div
                className="flex justify-between w-full items-center  min-w-[230px]"
                aria-label="legend-text-replacement-vp-to-apexchart"
              >
                {/* left part */}
                <div aria-label="legend-left-part">
                  <Text
                    translationKey={label}
                    classes="text-sm text-neutral-800  !min-w-[120px] inline-block  font-medium"
                  />
                  <div className="flex items-center gap-1">
                    <div>
                      <CircleProgress
                        value={percentage}
                        variant="sm"
                        className="gap-[5.28px]"
                        textClasses="text-xs text-neutral-500 font-medium p-0"
                        iconClasses="w-[11.43px] h-[11.43px]"
                        blankCircleClasses="stroke-[6px]"
                        progressCircleClasses="stroke-[6px]"
                        viewBox="2 -4 28 40"
                        noText
                      />
                    </div>
                    <div className="text-xs font-medium text-neutral-500">
                      {percentage}%
                    </div>
                  </div>
                </div>
                {/* right part */}
                <div
                  aria-label="legend-right-part"
                  className="text-base font-bold text-neutral-800"
                >
                  {valueText}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

BudgetChartLegends.propTypes = {
  chartRef: PropTypes.any, // ref to the chart
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Cards
      color: PropTypes.string, // '#FF0000'
      value: PropTypes.number, // e.g. 2000, for calculating sum
      valueText: PropTypes.string, // "2000 SGD", for rendering text. amount same as above.
    })
  ),
};
