import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function TableLoaderHelper() {
  return (
    <tr className="text-center">
      <td className="flex items-center gap-3 ml-4">
        <LoaderSkeleton size={[20, 20]} />
        <LoaderSkeleton type="circle" />
        <LoaderSkeleton size={[20, 180]} />
      </td>
      <td className="text-right">
        <LoaderSkeleton size="lg" />
      </td>
      <td className="text-left pl-4">
        <LoaderSkeleton size="lg" />
      </td>
      <td className="ml-4">
        <LoaderSkeleton size="lg" />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>

      <td className="flex justify-center gap-6">
        <LoaderSkeleton size={[30, 100]} />
      </td>
    </tr>
  );
}
