import PropTypes from "prop-types";
import React from "react";

import Alert from "@/components/core/Alert";
import Text from "@/components/core/Text";

import ReviewBudget from "@/components/Company/common/ReviewBudget";
import { amountToCurrency, dateToString } from "@/utils/common";

import { CARD_FREQUENCY_CORE, CARD_TYPE } from "@/constants/Cards";
import { REVIEW_TYPES } from "@/constants/common";

function CardLimitReview({ data }) {
  const {
    newLimit,
    newBalance,
    oldLimit,
    oldBalance,
    oldFrequency,
    frequency,
    currency,
    cardType,
    limit = "",
    refreshLimit = "",
    nextRecurAt = "",
    isTempLimit = false,
  } = data;

  const showReviewSections =
    cardType === CARD_TYPE.VIRTUAL ||
    (cardType === CARD_TYPE.PHYSICAL && frequency);

  return showReviewSections ? (
    <div>
      <div className="mt-6">
        <div className="mb-5">
          <Text
            translationKey="cards.cardDetails.editLimitDetails.limit"
            classes="text-lg font-semibold "
          />
        </div>

        <ReviewBudget
          oldValue={{
            amount: {
              value: oldLimit,
              currency,
            },
            frequency: {
              value: oldFrequency,
            },
          }}
          newValue={{
            amount: {
              value: newLimit,
              currency,
            },
            frequency: {
              value: frequency,
            },
          }}
          budgetKeys={[REVIEW_TYPES.AMOUNT, REVIEW_TYPES.FREQUENCY]}
        />
      </div>

      {isTempLimit ? (
        <Alert
          description="cards.editLimit.recurMessage"
          descriptionTransalationProp={{
            amountCurrency: amountToCurrency(
              refreshLimit !== 0 ? refreshLimit : limit,
              currency
            ),
            frequency,
            nextRecurAt: dateToString(nextRecurAt),
          }}
          classes="bg-warning-50 border-warning-300 mt-8"
          icon="EventRepeat"
          iconClasses="text-neutral-500"
          descriptionClasses="text-neutral-800"
        />
      ) : null}

      <div className="mt-6">
        <div className="mb-5">
          <Text
            translationKey="cards.cardDetails.editLimitDetails.availableBalance"
            classes="text-lg font-semibold "
          />
        </div>

        <ReviewBudget
          oldValue={{
            amount: {
              value: oldBalance,
              currency,
            },
          }}
          newValue={{
            amount: {
              value: newBalance,
              currency,
            },
          }}
          budgetKeys={[REVIEW_TYPES.AMOUNT]}
          showPercentagePill={false}
        />
      </div>
    </div>
  ) : null;
}

export default CardLimitReview;
CardLimitReview.propTypes = {
  data: PropTypes.object,
};
