import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  links: {},
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {},
});

export default navigationSlice.reducer;
