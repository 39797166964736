import PropTypes from "prop-types";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import {
  MISSING_DETAILS_ALL_STYLES,
  SUBMISSION_POLICY_BADGE_STYLE,
  SUBMISSION_POLICY_STYLE,
} from "@/constants/expense";

export default function SubmissionPolicyCard({
  data,
  status,
  missingFields,
  handleRemindClick,
  handleDismissPolicy,
  expenseId,
}) {
  const missingDetailLength = Object.keys(missingFields)?.length;
  const config = SUBMISSION_POLICY_STYLE[status];
  const badgeConfig = SUBMISSION_POLICY_BADGE_STYLE[status];
  const missingFieldsConfig = Object.keys(missingFields)?.map(
    (_) => MISSING_DETAILS_ALL_STYLES[_?.toUpperCase()]
  );

  return (
    <div className="mt-3 card-wrapper">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Icon
            name={config?.icon}
            className={`${config?.color} ${config?.background} w-10 h-10 p-3 rounded-full`}
          />

          <div>
            <Text
              color="neutral-500"
              classes="text-sm font-semibold"
              translationKey="expenses.slider.submissionPolicy.name"
            />
            <div>
              <Text
                classes="text-base font-semibold text-neutral-800"
                translationKey={data?.name}
              />
            </div>
          </div>
        </div>

        <Badge
          classes="px-3 h-full"
          translationKey={badgeConfig?.label}
          variant={badgeConfig?.variant}
          preIcon={badgeConfig?.preIcon}
          translationProp={{ missingLength: missingDetailLength }}
        />
      </div>
      {missingFieldsConfig?.length ? (
        <>
          <div className="p-4 m-4 rounded-lg bg-neutral-50">
            {missingFieldsConfig?.map((missingField, index) => (
              <div
                key={`missing-feilds-item-${index}}`}
                className="flex items-center mb-3"
              >
                <Icon
                  name={missingField?.icon}
                  className={missingField?.color}
                />
                <Text
                  classes="ml-4 text-neutral-500 text-sm font-semibold"
                  translationKey={missingField?.label}
                />
              </div>
            ))}
          </div>
          <div className="flex items-center justify-end gap-4 p-4 pt-0 pb-0">
            <Button
              label="expenses.slider.remind"
              type="default"
              variant="tertiary"
              size="sm"
              classes="text-xs w-fit px-5 py-1.5 font-bold text-primary-500"
              onClick={() => handleRemindClick("expense_ids", [expenseId])}
            />
            <div className="">
              <Button
                label="expenses.slider.dismissReplacement"
                type="default"
                variant="tertiary"
                size="sm"
                classes="text-xs w-fit px-5 py-1.5  font-bold text-primary-500"
                onClick={() => handleDismissPolicy(expenseId)}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

SubmissionPolicyCard.propTypes = {
  data: PropTypes.object,
  status: PropTypes.string,
  missingFields: PropTypes.object,
  handleRemindClick: PropTypes.func,
  handleDismissPolicy: PropTypes.func,
  expenseId: PropTypes.number,
};
