import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function ExportButton({ onClick = () => {} }) {
  return (
    <button
      onClick={onClick}
      id="export-ledger"
      className="flex items-center justify-center w-10 h-10 gap-4 p-3 text-primary-5000 card-wrapper"
    >
      <Icon className="text-neutral-500" name="OpenInNew" />
      <Tooltip id="export-ledger" direction="top">
        <Text translationKey="accounting.transactions.cards.listingFields.action.actions.export" />
      </Tooltip>
    </button>
  );
}
ExportButton.propTypes = {
  onClick: PropTypes.func,
};
