import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { currentPaymentProviderSelector } from "@/store/selectors/client";
import { onboardingMailDetailsSelector } from "@/store/selectors/vendors";

import VpSelect from "@/components/core/VpSelect";

import { PAYMENT_PROVIDERS } from "@/constants/provider";
import {
  VENDOR_CREATE_FINAL_PAYLOAD_PARAMS,
  VENDOR_CREATE_REQUIREMENTS_PARAMS,
} from "@/constants/vendors";

export default function PaymentMethod({
  name = VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_METHOD,
  values,
  errors,
  handleChange,
  paymentMethods,
  isFetchingPaymentMethods,
  relevantValues = [],
  disabled,
  setValues,
  isEditMode,
}) {
  const currentPaymentProvider = useSelector(currentPaymentProviderSelector);
  const onboardingMailDetailsProvider = useSelector(
    onboardingMailDetailsSelector
  )?.provider;

  const options = useMemo(() => {
    if (
      currentPaymentProvider === PAYMENT_PROVIDERS.DECENTRO ||
      onboardingMailDetailsProvider === PAYMENT_PROVIDERS.DECENTRO
    ) {
      return paymentMethods;
    }
    return paymentMethods.filter(({ payout_method_type: pm }) => {
      return relevantValues.includes(pm);
    });
  }, [paymentMethods, relevantValues]);

  // auto select if only one thing is relevant
  // delete if there's no relevant value
  useEffect(() => {
    if (relevantValues.length <= 1) {
      setValues?.((prev) => ({ ...prev, [name]: values[name] }));
    }
  }, [values[name]]);

  return (
    <VpSelect
      name={name}
      value={values[name]}
      error={errors[name]}
      handleChange={handleChange}
      insideForm
      isOptionsLoading={isFetchingPaymentMethods}
      options={options}
      optionsDisplayKey="name"
      optionsDisplaySubtextKey="description"
      valueKey={VENDOR_CREATE_REQUIREMENTS_PARAMS.PAYOUT_METHOD_TYPE}
      label="billPay.vendors.createVendor.paymentMethod"
      labelStyleClasses="text-neutral-600 text-xs font-semibold"
      placeholder="billPay.vendors.createVendor.selectPaymentMethod"
      menuPosition="absolute"
      clearable
      disabled={!!isEditMode}
    />
  );
}

PaymentMethod.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  isEditMode: PropTypes.bool,
  paymentMethods: PropTypes.array,
  isFetchingPaymentMethods: PropTypes.bool,
  disabled: PropTypes.bool,
  relevantValues: PropTypes.array,
  setValues: PropTypes.func, // 'setValues' returned by useForm (use is not preferred, handleChange is usually enough)
};
