import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchAccountWallets } from "@/store/reducers/client";
import { fetchBudgetData } from "@/store/reducers/company";
import { fetchPaymentModes } from "@/store/reducers/payments";
import { setPurchaseBillQuote } from "@/store/reducers/purchase-bills";
import {
  fetchUserReimbursements,
  fetchUserReport,
  generateBeneficiaryAmount,
  setSelectedPaymentsInitialData,
  settleClaimReimbursement,
} from "@/store/reducers/reimbursement";

import {
  currentPaymentProviderSelector,
  defaultCurrencySelector,
  paymentWalletListSelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";
import { budgetDataSelector } from "@/store/selectors/company";
import { paymentModeListSelector } from "@/store/selectors/payments";
import {
  isFetchingPurchaseBillQuoteSelector,
  purchaseBillQuoteSelector,
} from "@/store/selectors/purchase-bills";
import {
  isLoadingSelector,
  selectedPaymentsSelector,
  selectedUserReimbursementTotalSelector,
  selectedUserReportTotalReportsSelector,
} from "@/store/selectors/reimbursement";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import "@/components/Reimbursement/style.scss";
import AmountToBePaid from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/AmountToBePaid";
import {
  CREATE_BILL_FLOW_CONTEXT_KEYS,
  GENERATE_QUOTE_API_BODY_KEYS_RESPONSE,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import {
  paymentAccountDropdownOption,
  paymentAccountDropdownOptionSelected,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/paymentAccountDropdownOption";
import QuoteSection from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/LineItemsSection/QuoteSection";
import { useForm } from "@/utils/useForm";
import {
  CURRENCYOBJ,
  REIMBURSEMENT_KEY,
  REIMBURSEMENT_PAGE_TYPE,
} from "@/utils/constants/reimbursement";
import { USE_FORM_TYPE_OF_VALIDATION } from "@/utils/constantUseForm";
import {
  amountToCurrency,
  camelToSnake,
  limitDecimalsWithRounding,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { CURRENCY } from "@/constants/currency";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { PAYMENT_PROVIDERS } from "@/constants/provider";

export const ReimbursementPayNowSlider = ({ setOnBack, setOnClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedPaymentData = useSelector(selectedPaymentsSelector);
  const paymentModesList = useSelector(paymentModeListSelector);
  const quoteFromAPI = useSelector(purchaseBillQuoteSelector);
  const isFetchingQuote = useSelector(isFetchingPurchaseBillQuoteSelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentAccounts = useSelector(paymentWalletListSelector);
  const isLoading = useSelector(isLoadingSelector);
  const budgetData = useSelector(budgetDataSelector);

  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  const total = useSelector((state) =>
    reportEnabled
      ? selectedUserReportTotalReportsSelector(state)
      : selectedUserReimbursementTotalSelector(state)
  );

  const currentPaymentProvider = useSelector(currentPaymentProviderSelector);

  const senderAmountWithTransferFees =
    quoteFromAPI?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.TOTAL_AMOUNT] ?? 0;

  const senderAmountWithoutTransferFees =
    quoteFromAPI?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.FROM_AMOUNT] ?? 0;

  const senderCurrencyWithoutTransferFees =
    quoteFromAPI?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.FROM_CURRENCY] ?? 0;

  // TODO : from BE we will get a config which will have hide or show keys
  const paymentProviderIsInId =
    currentPaymentProvider === PAYMENT_PROVIDERS.DECENTRO ||
    currentPaymentProvider === PAYMENT_PROVIDERS.XENDIT;

  const hidePaymentMethodForID =
    currentPaymentProvider === PAYMENT_PROVIDERS.XENDIT;

  const senderCurrencyForInrAndIdr = () => {
    if (currentPaymentProvider === PAYMENT_PROVIDERS.DECENTRO) {
      return CURRENCYOBJ.INR.value;
    }
    if (currentPaymentProvider === PAYMENT_PROVIDERS.XENDIT) {
      return CURRENCYOBJ.IDR.value;
    }
  };

  const initialFormValue = {
    paymentAccount: {
      value: paymentAccounts?.[0]?.currency ?? "",
      validate: { required: true },
    },
    payoutMethodType: {
      value: paymentModesList?.options?.[0]?.value ?? "",
      validate: { required: true },
    },
    paymentReference: {
      value: "",
      validate: { required: false, [USE_FORM_TYPE_OF_VALIDATION.max]: 35 },
    },
  };

  const cancelHandler = () => {
    dispatch(setSelectedPaymentsInitialData());
    searchParams.delete(SLIDERS_SEARCH_PARAMS.reimbursements.paymentPage);
    setSearchParams(searchParams);
  };

  const onSuccess = () => {
    dispatch(setSelectedPaymentsInitialData());

    const payload = {
      page: 1,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      type: REIMBURSEMENT_PAGE_TYPE.payments,
    };

    if (reportEnabled) {
      dispatch(fetchUserReport(payload));
    } else {
      dispatch(fetchUserReimbursements(payload));
    }

    searchParams.delete(SLIDERS_SEARCH_PARAMS.reimbursements.paymentPage);

    if (total === selectedPaymentData?.reimbursementIds?.length) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.reimbursements.pageType);

      if (reportEnabled) {
        searchParams.delete(SLIDERS_SEARCH_PARAMS.reports.userReportId);
      } else {
        searchParams.delete(SLIDERS_SEARCH_PARAMS.reimbursements.id);
      }
    }

    setSearchParams(searchParams);
  };

  const onSubmit = () => {
    let payload = {
      ...selectedPaymentData,
      amount: quoteFromAPI?.totalAmount?.value,
      ...values,
      quoteId: quoteFromAPI.id,
    };
    payload.paymentDate = new Date();

    const correctedKeysObject = Object.entries(payload).reduce(
      (accum, [key, value]) => {
        if (key === CREATE_BILL_FLOW_CONTEXT_KEYS.FINAL_AMOUNT) {
          const finalAmountLowerCase = key.toLocaleLowerCase();
          accum[camelToSnake(finalAmountLowerCase)] = value;
        } else {
          accum[camelToSnake(key)] = value;
        }
        return accum;
      },
      {}
    );
    payload = correctedKeysObject;

    dispatch(settleClaimReimbursement({ payload, onSuccess }));
  };

  const {
    handleChange,
    values,
    errors,
    _setValues: setValues,
    isFormButtonDisabled,
    handleSubmit,
  } = useForm(initialFormValue, onSubmit, {
    formId: "claim_payment",
    isFetchingInitialValue: false,
  });

  useEffect(() => {
    dispatch(
      fetchBudgetData({
        module: REIMBURSEMENT_KEY,
        project_ids: [],
      })
    );
  }, [values?.paymentAccount]);

  const helperText = () => {
    const selectedOption = paymentModesList?.options?.find(
      (data) => data.value === values?.payoutMethodType
    );
    return selectedOption?.desc;
  };

  useEffect(() => {
    if (selectedPaymentData?.vendorId) {
      dispatch(
        fetchPaymentModes({
          vendor_id: selectedPaymentData.vendorId,
        })
      );
      dispatch(
        generateBeneficiaryAmount({
          [reportEnabled ? "report_ids" : "reimbursement_ids"]:
            selectedPaymentData.reimbursementIds,
        })
      );
    }
  }, [selectedPaymentData?.vendorId, selectedPaymentData?.reimbursementIds]);

  useEffect(() => {
    if (!selectedPaymentData) cancelHandler();

    dispatch(fetchAccountWallets({ shallow: true }));

    setOnClose((searchParamArray) => {
      const searchParam = SLIDERS_SEARCH_PARAMS.reimbursements.paymentPage;
      const isClosed = !searchParamArray?.includes(searchParam);

      if (isClosed) {
        dispatch(setSelectedPaymentsInitialData());
        dispatch(setPurchaseBillQuote(null));
      }
    });

    setOnBack(() => {
      dispatch(setSelectedPaymentsInitialData());
      dispatch(setPurchaseBillQuote(null));
    });
  }, []);

  const paymentAccountsOptions = paymentAccounts.map((item, index) => {
    return {
      ...item,
      disabled: Boolean(
        budgetData?.account?.find(
          (itemm) =>
            Number(itemm?.availableBalance) <
              senderAmountWithTransferFees?.value &&
            itemm?.currency === item.currency &&
            itemm?.currency === values?.paymentAccount
        )
      ),
    };
  });

  return (
    <>
      <div className="slider-content-core">
        <div className="flex flex-col mb-8">
          <Text
            translationKey="reimbursement.createPayment.title"
            classes="text-neutral-800 text-3xl font-semibold"
          />
          <Text
            translationKey="reimbursement.createPayment.description"
            classes="text-neutral-500 text-sm "
          />
        </div>

        <form onSubmit={handleSubmit} id="claim_payment">
          {paymentProviderIsInId ? (
            <div className="card-wrapper reim-payment-account">
              {paymentAccountDropdownOptionSelected(
                paymentAccountsOptions?.[0]?.currency,
                paymentAccountsOptions?.[0],
                paymentAccountsOptions?.[0]?.disabled &&
                  quoteFromAPI &&
                  !isFetchingQuote
                  ? "reimbursement.approvals.insufficientAccountBalanceDesc"
                  : "",
                "text-warning-600 pb-2"
              )}
            </div>
          ) : quoteFromAPI && !isFetchingQuote ? (
            <div className="card-wrapper reim-payment-account">
              <VpSelect
                name="paymentAccount"
                value={values.paymentAccount}
                error={errors.paymentAccount}
                handleChange={handleChange}
                isOptionsFetched={!isFetchingQuote}
                isOptionsLoading={isFetchingQuote}
                insideForm
                menuPosition="absolute"
                options={paymentAccountsOptions}
                optionsDisplayKey="currency"
                valueKey="currency"
                label={null}
                // searchable={false}
                customUIForOptionWhenDropdownClosed={(label, option) => {
                  let displayValueDesc = "";
                  if (
                    paymentAccountsOptions?.find((a) => a.currency === label)
                      ?.disabled &&
                    quoteFromAPI &&
                    !isFetchingQuote
                  ) {
                    displayValueDesc =
                      "reimbursement.approvals.insufficientAccountBalanceDesc";
                  } else {
                    displayValueDesc = "";
                  }
                  return paymentAccountDropdownOptionSelected(
                    label,
                    option,
                    displayValueDesc,
                    "text-warning-600 pb-2"
                  );
                }}
                customUIForOptionWhenDropdownOpen={(label, option) => {
                  let displayValueDesc = "";
                  let disable = false;

                  const accountBudgetCheck = budgetData?.account?.find(
                    (i) =>
                      Number(i?.availableBalance) <
                        senderAmountWithTransferFees?.value &&
                      i?.currency === label &&
                      i?.currency === values.paymentAccount
                  );

                  if (accountBudgetCheck && quoteFromAPI && !isFetchingQuote) {
                    displayValueDesc =
                      "reimbursement.approvals.insufficientAccountBalanceDesc";
                    disable = true;
                  } else {
                    displayValueDesc = null;
                    disable = false;
                  }

                  return paymentAccountDropdownOption(
                    label,
                    option,
                    displayValueDesc,
                    "text-warning-600"
                  );
                }}
              />
            </div>
          ) : (
            <LoaderSkeleton fullWidth classes="py-10" />
          )}

          <div className="my-8">
            <Input
              value={selectedPaymentData?.totalBeneficiaryAmount || 0}
              type="number"
              label="reimbursement.createPayment.reimburseAmountLabel"
              rightText={selectedPaymentData?.beneficiaryCurrency}
              isFocus
              labelExtraClasses="text-xs"
              disabled
            />
          </div>

          <div>
            {paymentProviderIsInId ? null : (
              <div className="bg-white border rounded-lg border-neutral-300">
                <AmountToBePaid
                  currency={
                    senderAmountWithTransferFees?.currency || defaultCurrency
                  }
                  amount={
                    selectedPaymentData
                      ? senderAmountWithTransferFees?.value
                      : 0
                  }
                  blueBorder={false}
                />
              </div>
            )}

            {selectedPaymentData?.beneficiaryCurrency !==
              senderAmountWithTransferFees?.currency ||
            selectedPaymentData?.beneficiaryCurrency !== defaultCurrency ? (
              <div className="flex flex-col mt-2">
                <Text
                  translationKey="reimbursement.createPayment.differentCurrencyDesc"
                  classes="text-xs text-neutral-500 font-medium"
                />
                <Text
                  translationKey="reimbursement.createPayment.differentCurrencyAmountDesc"
                  translationProps={{
                    amount: amountToCurrency({
                      value: senderAmountWithoutTransferFees,
                      currency: senderCurrencyWithoutTransferFees,
                    }),
                  }}
                  classes="text-xs text-neutral-500 font-medium"
                />
              </div>
            ) : null}

            <QuoteSection
              values={values}
              vendor={{ id: selectedPaymentData?.vendorId }}
              setValues={setValues}
              senderCurrency={
                senderCurrencyForInrAndIdr() ??
                values?.paymentAccount ??
                defaultCurrency
              }
              beneficiaryCurrency={selectedPaymentData?.beneficiaryCurrency}
              quoteAmount={limitDecimalsWithRounding(
                selectedPaymentData?.totalBeneficiaryAmount,
                2
              )}
              fromReimbursement
              hideAsPerProvider={paymentProviderIsInId}
            />
          </div>

          <div
            className={`flex flex-col gap-8 ${
              hidePaymentMethodForID ? "" : "my-10"
            }`}
          >
            {hidePaymentMethodForID ? null : (
              <div>
                <VpSelect
                  name="payoutMethodType"
                  options={paymentModesList?.options}
                  value={values?.payoutMethodType}
                  menuPosition="absolute"
                  optionsDisplayKey="label"
                  valueKey="value"
                  insideForm
                  handleChange={handleChange}
                  label="reimbursement.createPayment.paymentMethod"
                  placeholder="reimbursement.createPayment.selectPaymentMethod"
                  customUIForOptionWhenDropdownOpen={(label, option) =>
                    paymentMethodDropdownOption(label, option)
                  }
                />
              </div>
            )}
            <div className="mb-10">
              <Input
                name="paymentReference"
                label="reimbursement.createPayment.paymentReferenceLabel"
                showOptional
                value={values.paymentReference}
                error={errors.paymentReference}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="flex justify-end gap-5 px-3 py-5 text-xl slider-footer">
        <Button
          variant="tertiary"
          classes="text-neutral-500 px-5 py-4 w-fit"
          label="reimbursement.createPayment.cancel"
          onClick={cancelHandler}
          disabled={isLoading}
        />
        <Button
          form="claim_payment"
          variant="primary"
          classes="px-5 py-4 w-18"
          label="reimbursement.createPayment.createPayment"
          btnType="submit"
          showLoader={isLoading}
          disabled={
            !senderAmountWithTransferFees?.value ||
            isLoading ||
            isFetchingQuote ||
            paymentAccountsOptions?.find(
              (a) => a.currency === values?.paymentAccount
            )?.disabled
          }
        />
      </div>
    </>
  );
};

export function paymentMethodDropdownOption(displayValue, option) {
  return (
    <div className="my-2">
      <div className="flex flex-col">
        <Text classes=" text-neutral-500" translationKey={option?.label} />
        <Text
          classes="text-sm  text-neutral-400 "
          translationKey={option?.desc}
        />
      </div>
    </div>
  );
}
