import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import get from "get-value";
import set from "set-value";

import { validateToken } from "@/store/reducers/user";
import {
  clearBankDetailsForm,
  createVendor,
  createVendorViaMail,
  fetchBankAndSwiftCodes,
  fetchCityAndRegionMapping,
  mergeIntoBankDetailsForm,
  mergeIntoBankDetailsFormSetter,
  setBankDetailsForm,
  setBankDetailsFormReviewRows,
  setSelectedVendor,
  updateSelectedVendor,
  updateVendor,
} from "@/store/reducers/vendors";

import { currentPaymentProviderSelector } from "@/store/selectors/client";
import { userSelector } from "@/store/selectors/user";
import {
  bankAndSwitfCodesSelector,
  bankDetailsFormSelector,
  cityAndRegionMappingSelector,
  isFetchingBankAndSwitfCodesSelector,
  isFetchingCityAndRegionMappingSelector,
  isFetchingVendorCreateRequirementsSelector,
  onboardingMailDetailsSelector,
  selectedVendorSelector,
  vendorCreateFormSelector,
  vendorCreateRequirementsSelector,
} from "@/store/selectors/vendors";

import Icon from "@/components/core/Icon";

import GenericForm from "@/components/GenericForm";
import {
  FIELD_AUTOFILL_MAPPING_OBJECT,
  FIELD_DROPDOWN_OPTIONS_ARRAY_KEY,
  FIELD_DROPDOWN_OPTION_LABEL_KEY,
  FIELD_DROPDOWN_OPTION_VALUE_KEY,
  FIELD_KEY_KEY,
  FIELD_KEY_VALUE,
  FIELD_REGEX_KEY,
  FIELD_TOOLTIP_KEY,
  GENERIC_FORM_KEY_SEPARATOR,
  changeFieldInRequirements,
  generatePayloadFromFormValue,
} from "@/components/GenericForm/common";
import GenericFormLoader from "@/components/GenericForm/common/GenericFormLoader";
import { BANK_DETAILS_VIA_MAIL_SEARCH_PARAM } from "@/components/common/BillPayAndPayroll/AddBankDetailsComponent/enum";
import { PAYMENT_CHANNELS_TYPES } from "@/utils/constants/payments";
import {
  deepCamelToSnake,
  flattenObject,
  getCorrectedKeysObject,
  getToken,
  removedTerminalsOfRegex,
  subdomain,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { VENDOR_CLASS } from "@/constants/common";
import { PAYMENT_PROVIDERS } from "@/constants/provider";
import { ROUTES } from "@/constants/routes";
import {
  BANK_TOOLTIP_FIELD_KEYS,
  CREATE_VENDOR_BANK_CITY_REGION_KEYS,
  CREATE_VENDOR_BANK_SWIFT_CODE_KEYS,
  JP_TOOLTIP_ENUMS,
  VENDOR_CREATE_FINAL_PAYLOAD_PARAMS,
  VENDOR_CREATE_REQUIREMENTS_PARAMS,
  VENDOR_TYPES,
} from "@/constants/vendors";

export default function AddBankDetails({
  setOnBack = () => {},
  setOnClose = () => {},
  fromPage = false,
  searchParamKey,
  vendorClass,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nonGenericForm = useSelector(vendorCreateFormSelector); // form values from first page of create vendor

  const [searchParams, setSearchParams] = useSearchParams();
  const [flattenObjPayload, setFlattenObjPayload] = useState();

  const editMode = searchParams.has(
    SLIDERS_SEARCH_PARAMS.company.people.editBankDetails
  );
  const selectedUser = useSelector(userSelector);
  const isFetchingRequirements = useSelector(
    isFetchingVendorCreateRequirementsSelector
  );
  const isFetchingBankAndSwitfCodes = useSelector(
    isFetchingBankAndSwitfCodesSelector
  );
  const isFetchingCityAndRegionMapping = useSelector(
    isFetchingCityAndRegionMappingSelector
  );
  const requirements = useSelector(vendorCreateRequirementsSelector);
  const storedInitialFormValue = useSelector(vendorCreateFormSelector) ?? {};

  const selectedVendor = useSelector(selectedVendorSelector);

  const mailDetails = useSelector(onboardingMailDetailsSelector);

  const currentPaymentProvider = useSelector(currentPaymentProviderSelector);
  const selectedCountry =
    nonGenericForm?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]
      ?.value; // was selected on previous (FE only) page

  const selectedBeneficiaryBankCountry =
    nonGenericForm?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]
      ?.value;

  // in mail flow logic
  const { token } = useParams();
  const { pathname } = useLocation();
  const isMailFlow =
    pathname === `/${ROUTES.billpay.addBankDetails.absolutePath}/${token}`;

  const isUseBankAndSwiftCodeAPI =
    ([PAYMENT_PROVIDERS.WALLEX, PAYMENT_PROVIDERS.AIRWALLEX].includes(
      currentPaymentProvider
    ) ||
      isMailFlow) &&
    selectedCountry === "SG" &&
    selectedBeneficiaryBankCountry === "SG";

  const isUseCityAndRegionAPI =
    [currentPaymentProvider, mailDetails?.provider].includes(
      PAYMENT_PROVIDERS.WALLEX
    ) && selectedCountry === "ID";

  const isDoAirwallexStateToolTipPrefill = [
    currentPaymentProvider,
    mailDetails?.provider,
  ].includes(PAYMENT_PROVIDERS.AIRWALLEX);

  const isDoWallexTypeToolTipPrefill =
    [currentPaymentProvider, mailDetails?.provider].includes(
      PAYMENT_PROVIDERS.WALLEX
    ) &&
    // all fields in pre add bank details page is JP types, and swift
    nonGenericForm?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]
      ?.value === JP_TOOLTIP_ENUMS.COUNTRY &&
    nonGenericForm?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]
      ?.value === JP_TOOLTIP_ENUMS.CURRENCY &&
    nonGenericForm?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]
      ?.value === JP_TOOLTIP_ENUMS.COUNTRY &&
    nonGenericForm?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.PAYOUT_METHOD_TYPE]
      ?.value === PAYMENT_CHANNELS_TYPES.SWIFT;

  const isDoPrefillTooltip =
    isDoAirwallexStateToolTipPrefill || isDoWallexTypeToolTipPrefill;

  const onBackFromFirstPage = () => {
    if (!fromPage) dispatch(clearBankDetailsForm());
  };

  // Need to prefill swift code, since it's a UX goal
  // Technically, country, bank, and swift code are three variables
  // and BE cannot handle n^3 variations
  // Anyway, country is known at this point
  // And BE can send the bank + swiftCode (1-1 mapping) via API
  // so we'll create the prefill mapping here
  // And inject it in the auto fill source
  const bankAndSwiftCodes = useSelector(bankAndSwitfCodesSelector);
  const cityAndRegionMapping = useSelector(cityAndRegionMappingSelector);
  const selectedCountriesBankNameAndSwiftCodes =
    bankAndSwiftCodes?.[selectedCountry] ?? [];
  const bankAndSwiftCodeMappingObject = useMemo(() => {
    return selectedCountriesBankNameAndSwiftCodes.reduce((accum, item) => {
      const bankKey = CREATE_VENDOR_BANK_SWIFT_CODE_KEYS.LABEL;
      const swiftCodeKey = CREATE_VENDOR_BANK_SWIFT_CODE_KEYS.SWIFT_CODE;
      accum[item[bankKey]] = item[swiftCodeKey];
      return accum;
    }, {});
  }, [selectedCountriesBankNameAndSwiftCodes]);

  const cityAndRegionMappingObject = useMemo(() => {
    return cityAndRegionMapping.reduce((accum, item) => {
      const cityKey = CREATE_VENDOR_BANK_CITY_REGION_KEYS.LABEL;
      const regionKey = CREATE_VENDOR_BANK_CITY_REGION_KEYS.REGION;
      accum[item[cityKey]] = item[regionKey];
      return accum;
    }, {});
  }, [cityAndRegionMapping]);

  const autoFillSource = useMemo(() => {
    // used for autofill country, and bicSwift auto fill based on selected bank
    let autoFillSource_ = nonGenericForm;
    autoFillSource_ = {
      ...autoFillSource_,
      ...(isDoAirwallexStateToolTipPrefill
        ? {
            [CREATE_VENDOR_BANK_SWIFT_CODE_KEYS.REQUIREMENTS_BANK_NAME_FIELD_KEY]:
              {
                [FIELD_AUTOFILL_MAPPING_OBJECT]: bankAndSwiftCodeMappingObject,
              },
          }
        : {
            [CREATE_VENDOR_BANK_SWIFT_CODE_KEYS.REQUIREMENTS_BANK_FIELD_KEY]: {
              [FIELD_AUTOFILL_MAPPING_OBJECT]: bankAndSwiftCodeMappingObject,
            },
          }),
    };
    autoFillSource_ = {
      ...autoFillSource_,
      ...(isUseCityAndRegionAPI
        ? {
            [CREATE_VENDOR_BANK_CITY_REGION_KEYS.REQUIREMENTS_CITY_FIELD_KEY]: {
              [FIELD_AUTOFILL_MAPPING_OBJECT]: cityAndRegionMappingObject,
            },
          }
        : {}),
    };
    return autoFillSource_;
  }, [
    nonGenericForm,
    isDoAirwallexStateToolTipPrefill,
    bankAndSwiftCodeMappingObject,
    isUseCityAndRegionAPI,
    cityAndRegionMappingObject,
  ]);

  // prefill logic, condition and value to set if true
  const prefillTooltp = (fieldObj) => {
    const _isDoPrefillTooltip =
      isDoPrefillTooltip &&
      [
        BANK_TOOLTIP_FIELD_KEYS.STATE_OR_PROVINCE,
        BANK_TOOLTIP_FIELD_KEYS.STATE,
        BANK_TOOLTIP_FIELD_KEYS.TYPE,
      ].includes(fieldObj[FIELD_KEY_KEY]) &&
      !!fieldObj[FIELD_REGEX_KEY];

    if (_isDoPrefillTooltip) {
      return {
        [FIELD_TOOLTIP_KEY]: t(
          "billPay.vendors.createVendor.addBankDetailsPage.availableValuesTooltip",
          {
            tooltipValues: removedTerminalsOfRegex(
              fieldObj[FIELD_REGEX_KEY].toString()
                .replaceAll("(", "")
                .replaceAll(")", "")
                .replaceAll("|", ", ")
                .replaceAll("/", "")
            ),
          }
        ),
      };
    }

    return null;
  };

  const prefillBankAndSwiftCodes = (field) => {
    const BANK_SWIFT_CODES_LABEL_KEY = "label";

    if (
      isUseBankAndSwiftCodeAPI &&
      (field[FIELD_KEY_KEY] === "bankName" ||
        field[FIELD_KEY_KEY] === "bank_name")
    ) {
      return {
        [FIELD_DROPDOWN_OPTIONS_ARRAY_KEY]:
          selectedCountriesBankNameAndSwiftCodes.map((bankItem) => ({
            [FIELD_DROPDOWN_OPTION_LABEL_KEY]:
              bankItem?.[BANK_SWIFT_CODES_LABEL_KEY],
            [FIELD_DROPDOWN_OPTION_VALUE_KEY]:
              bankItem?.[BANK_SWIFT_CODES_LABEL_KEY], // reuse label as value since unique
          })),
      };
    }

    return null;
  };

  const addCityOptions = (field) => {
    const CITY_LABEL_KEY = "label";

    if (
      isUseCityAndRegionAPI &&
      field[FIELD_KEY_KEY] ===
        CREATE_VENDOR_BANK_CITY_REGION_KEYS.REQUIREMENTS_CITY_FIELD_INDIVIDUAL_KEY
    ) {
      return {
        [FIELD_DROPDOWN_OPTIONS_ARRAY_KEY]: cityAndRegionMapping.map(
          (cityRegionItem) => ({
            [FIELD_DROPDOWN_OPTION_LABEL_KEY]: cityRegionItem?.[CITY_LABEL_KEY],
            [FIELD_DROPDOWN_OPTION_VALUE_KEY]: cityRegionItem?.[CITY_LABEL_KEY], // reuse label as value since unique
          })
        ),
      };
    }

    return null;
  };

  const isLoading =
    isFetchingRequirements ||
    isFetchingBankAndSwitfCodes ||
    isFetchingCityAndRegionMapping;
  const isChangeRequirements =
    requirements?.length &&
    [
      isUseBankAndSwiftCodeAPI,
      isUseCityAndRegionAPI,
      flattenObjPayload && editMode,
      isDoPrefillTooltip,
    ].some(Boolean);

  const isDependenciesFetched =
    requirements?.length &&
    [
      isUseBankAndSwiftCodeAPI
        ? bankAndSwiftCodes && selectedCountriesBankNameAndSwiftCodes?.length
        : true,
      isUseCityAndRegionAPI ? !!cityAndRegionMapping?.length : true,
    ].every(Boolean);

  // Mutates the requirements
  // 1. Bank swift codes (AU and SG when selected country is SG)
  // 2. Prefill values (needed for profile edit)
  // 3. Prefill tooltip for Airwallex for state field
  // 3. Prefill region when city is selected (for ID case)
  const prefilledRequirements = useMemo(() => {
    if (!isChangeRequirements || !isDependenciesFetched) return requirements;

    let entriesToPrefill = {};

    if (isUseBankAndSwiftCodeAPI) {
      entriesToPrefill = {
        ...entriesToPrefill,
        checkBankName: (field) =>
          isUseBankAndSwiftCodeAPI &&
          (field[FIELD_KEY_KEY] === "bankName" ||
            field[FIELD_KEY_KEY] === "bank_name"),
      };
    }

    if (isDoPrefillTooltip)
      entriesToPrefill = { ...entriesToPrefill, prefillTooltp };
    if (flattenObjPayload)
      entriesToPrefill = { ...entriesToPrefill, ...flattenObjPayload };
    if (isUseCityAndRegionAPI)
      entriesToPrefill = {
        ...entriesToPrefill,
        addCityOptions,
      };

    return Object.entries(entriesToPrefill).reduce((acc, [k, v]) => {
      return changeFieldInRequirements(
        acc,
        typeof v === typeof (() => {}) ? v : k, // simple key, or criteria
        (fieldObj) => ({
          ...fieldObj,
          ...(editMode ? { [FIELD_KEY_VALUE]: v } : {}),
          ...prefillBankAndSwiftCodes(fieldObj),
          ...prefillTooltp(fieldObj),
          ...addCityOptions(fieldObj),
        }),
        true
      );
    }, structuredClone(requirements));
  }, [
    isChangeRequirements,
    isDependenciesFetched,
    JSON.stringify(flattenObjPayload),
    selectedVendor,
    requirements,
    JSON.stringify(selectedCountriesBankNameAndSwiftCodes), // TECH DEBT: check redux value reference instead of stringify
    cityAndRegionMapping,
  ]);

  useEffect(() => {
    let payload;
    let final;

    switch (currentPaymentProvider) {
      case PAYMENT_PROVIDERS.DECENTRO:
        final = flattenObject({
          ...selectedVendor?.bankDetailsNotHumanized,
        });
        payload = getCorrectedKeysObject({
          ...final,
          ...selectedVendor?.otherDetailsNotHumanized,
        });

        break;
      case PAYMENT_PROVIDERS.WALLEX:
        final = getCorrectedKeysObject({
          ...selectedVendor?.otherDetailsNotHumanized,
        });
        payload = flattenObject({
          final,
          ...selectedVendor?.bankDetailsNotHumanized,
        });

        break;
      case PAYMENT_PROVIDERS.AIRWALLEX:
      case PAYMENT_PROVIDERS.XENDIT:
        final = flattenObject({
          ...selectedVendor?.otherDetailsNotHumanized,
          ...selectedVendor?.bankDetailsNotHumanized,
        });
        payload = getCorrectedKeysObject(final);

        break;
      default:
        payload = {};
        break;
    }
    setFlattenObjPayload(payload);
  }, [currentPaymentProvider, selectedVendor, requirements]);

  useEffect(() => {
    if (isUseBankAndSwiftCodeAPI)
      dispatch(
        fetchBankAndSwiftCodes(
          isMailFlow
            ? { token: getToken(), account: subdomain(), isMailFlow }
            : {}
        )
      );
  }, [isUseBankAndSwiftCodeAPI, isMailFlow]);

  useEffect(() => {
    if (isUseCityAndRegionAPI)
      dispatch(fetchCityAndRegionMapping({ country: selectedCountry }));
  }, [isUseCityAndRegionAPI]);

  const onSuccess = (response, shoudldUpdateVendor = false) => {
    if (fromPage) {
      searchParams.set(
        BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.KEY,
        BANK_DETAILS_VIA_MAIL_SEARCH_PARAM.VALUES.SUCCESS
      );
      setSearchParams(searchParams);
    } else {
      searchParams.delete(globalSliderQueryParam);
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.company.people.preAddBankDetails
      );
      if (editMode) {
        searchParams.delete(
          SLIDERS_SEARCH_PARAMS.company.people.editBankDetails
        );
      }
      if (shoudldUpdateVendor) {
        dispatch(updateSelectedVendor(response));
      }

      if (vendorClass === VENDOR_CLASS.EMPLOYEE) {
        dispatch(validateToken({}));
      }

      setSearchParams(searchParams);
    }
  };

  let globalSliderQueryParam = "";
  if (fromPage) {
    globalSliderQueryParam = "step";
  } else if (vendorClass === VENDOR_CLASS.EMPLOYEE) {
    globalSliderQueryParam =
      SLIDERS_SEARCH_PARAMS.company.people.addBankDetails;
  } else if (vendorClass === VENDOR_CLASS.USER_CREATED) {
    globalSliderQueryParam = SLIDERS_SEARCH_PARAMS.vendors.addBankDetails;
  } else if (vendorClass === VENDOR_CLASS.PAYROLL) {
    globalSliderQueryParam = SLIDERS_SEARCH_PARAMS.employees.addBankDetails;
  }

  const onSubmit = (...argArray) => {
    const bankDetails = argArray[0]; // bank detail ,payload object
    let reviewRows = argArray?.[2]?.reviewRows ?? [];

    if (isUseBankAndSwiftCodeAPI) {
      // A, change the part of affected payload
      // two possibilities, try both

      let bankPath = "beneficiary.bankAccount.bank_name";
      let bankUniqueName = get(bankDetails, bankPath);
      if (!bankUniqueName) {
        bankPath = "beneficiary.bankAccount.bankName";
        bankUniqueName = get(bankDetails, bankPath);
      }

      if (bankUniqueName) {
        const bankShorterName = selectedCountriesBankNameAndSwiftCodes.find(
          (item) => item?.label?.trim() === bankUniqueName?.trim() // names may have extra spaces at ends
        )?.[CREATE_VENDOR_BANK_SWIFT_CODE_KEYS.BANK];

        // bank details mutated
        set(bankDetails, bankPath, bankShorterName, {
          separator: GENERIC_FORM_KEY_SEPARATOR,
        });
        // B, change the review rows

        reviewRows = reviewRows.map((item) => {
          if (item.id === bankPath) {
            return {
              ...item,
              value: bankShorterName,
              displayValue: bankShorterName,
            };
          }

          return item;
        });

        // C update redux
        dispatch(
          mergeIntoBankDetailsFormSetter((bankDetailsInStore) => {
            const bankNamePart = bankDetailsInStore[bankPath];

            return {
              [bankPath]: { ...bankNamePart, value: bankShorterName },
            };
          })
        );
      }
      // else give up (retain filled value, and rows)
    }
    dispatch(setBankDetailsFormReviewRows(reviewRows)); // save to store, since surrounding pages may show it

    const finalFormValue = { ...storedInitialFormValue };

    finalFormValue[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_PROVIDER_DATA] =
      JSON.stringify(
        generatePayloadFromFormValue(deepCamelToSnake(bankDetails))
      );

    finalFormValue[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.VENDOR_CLASS] =
      vendorClass;

    finalFormValue[VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE] =
      VENDOR_TYPES.INDIVIDUAL;

    const payload = generatePayloadFromFormValue(finalFormValue);
    if (fromPage) {
      dispatch(
        createVendorViaMail({
          payload,
          params: { token: getToken(), account: subdomain() },
          onSuccess,
        })
      );
    } else if (vendorClass === VENDOR_CLASS.EMPLOYEE) {
      if (selectedUser?.vendorId)
        dispatch(
          updateVendor({
            payload: { ...payload, owner_id: selectedUser?.id },
            id: selectedUser?.vendorId,
            param: { not_humanized: true },
            onSuccess: (response) => {
              onSuccess(response, true);
            },
          })
        );
      else {
        dispatch(
          createVendor({
            payload: {
              ...payload,
              owner_id: selectedUser?.id,
            },
            updateSelectAlso: true,
            onSuccess: (response) => {
              onSuccess(response, true);
            },
          })
        );
      }
    } else {
      searchParams.delete(searchParamKey);
      searchParams.delete(globalSliderQueryParam);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.vendors.preAddBankDetails);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.employees.preAddBankDetails);
      setSearchParams(searchParams);
    }
  };

  const isOverrideMode = !!(
    searchParams.get(SLIDERS_SEARCH_PARAMS.vendors.overrideEdit) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.employees.overrideEdit)
  );

  // closing should close until id, or including create
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (
      searchParams.has(SLIDERS_SEARCH_PARAMS.vendors.create) ||
      searchParams.has(SLIDERS_SEARCH_PARAMS.employees.create) ||
      !urlSearchParams.has(searchParamKey)
    )
      return;

    setOnClose(() => {
      setSelectedVendor(null);
      if (vendorClass === VENDOR_CLASS.EMPLOYEE) {
        setSearchParams({});
        dispatch(clearBankDetailsForm());
      } else {
        searchParams.delete(searchParamKey);
        searchParams.delete(globalSliderQueryParam);
        searchParams.delete(SLIDERS_SEARCH_PARAMS.vendors.preAddBankDetails);
        searchParams.delete(SLIDERS_SEARCH_PARAMS.employees.preAddBankDetails);
        searchParams.delete(SLIDERS_SEARCH_PARAMS.vendors.edit);
        searchParams.delete(SLIDERS_SEARCH_PARAMS.employees.edit);
        searchParams.delete(SLIDERS_SEARCH_PARAMS.vendors.overrideEdit);
        searchParams.delete(SLIDERS_SEARCH_PARAMS.employees.overrideEdit);
        setSearchParams(searchParams);
      }
    });
  }, []);

  if (isLoading || !requirements || !isDependenciesFetched)
    return <GenericFormLoader fromPage={fromPage} />;

  return prefilledRequirements?.length ? (
    <GenericForm
      setOnBack={setOnBack}
      backComponent={fromPage ? <Icon name="BackArrow" /> : null}
      inSlider={!fromPage}
      onBackFromFirstPage={onBackFromFirstPage}
      pages={prefilledRequirements}
      globalSliderQueryParam={globalSliderQueryParam}
      onSubmit={onSubmit}
      isFetching={isLoading}
      storeSetterReducer={setBankDetailsForm}
      storeClearReducer={clearBankDetailsForm}
      showFooter={!fromPage}
      storeMergeReducer={mergeIntoBankDetailsForm}
      formId="vendor-add-bank-details-form"
      storeSelector={bankDetailsFormSelector}
      wantCleanUp
      autoFillSource={autoFillSource}
      genericFormClasses={fromPage ? "!px-0 mb-15 " : null}
      hideFields
      hideEmptySections
      autofillValueFromRegexIfLiteral
      editMode={editMode}
      autofillDropdownIfSingleOption
      generateReviewRows
      restrictClosingSliderAction={
        vendorClass === VENDOR_CLASS?.EMPLOYEE ||
        vendorClass === VENDOR_CLASS.USER_CREATED ||
        vendorClass === VENDOR_CLASS.PAYROLL
      }
      positiveLabelFinal={
        fromPage
          ? "company.people.peopleMyProfileSlider.saveBankDetails"
          : vendorClass === VENDOR_CLASS?.EMPLOYEE
            ? "misc.submit"
            : "misc.continue"
      }
    />
  ) : null;
}

AddBankDetails.propTypes = {
  setOnBack: PropTypes.func,
  setOnClose: PropTypes.func,
  fromPage: PropTypes.bool,
  vendorClass: PropTypes.string,
  searchParamKey: PropTypes.string,
};
