import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import { ACCOUNTING_TRANSACTION_STATUS } from "@/constants/accounting";
import { TRANSACTION_ACTIONS } from "@/constants/transactions";

import { getActionAndIcon } from "./util";

export default function Action({
  status,
  id,
  disabled,
  disabledToolTipText,
  onClick = () => {},
  exportable = false,
  isLoading = false,
  billPayWithContinousSync = false,
  ctas,
  transactionType,
  classes,
}) {
  const dispatch = useDispatch();
  const resyncButton =
    billPayWithContinousSync &&
    [
      ACCOUNTING_TRANSACTION_STATUS.VERIFIED,
      ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED,
    ].includes(status);

  const [action, icon] = getActionAndIcon(
    exportable,
    ctas,
    resyncButton,
    status
  );

  const tooltipId = `transaction-no-verify-reason-tooltip-text-${id}-${status}`;

  return (
    <>
      <div className="flex items-center justify-center flex-1" id={tooltipId}>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onClick(dispatch, action, { id, transactionType, exportable });
          }}
          preIcon={icon}
          label={
            resyncButton
              ? "accounting.transactions.cards.listingFields.action.actions.resync"
              : `accounting.transactions.cards.listingFields.action.actions.${action}`
          }
          classes={`w-14.75 h-8 text-xs ${
            !disabled ? "text-success-600" : "text-neutral-400"
          } ${classes}`}
          iconClasses="-mr-1"
          type="neutral"
          disabled={disabled}
          showLoader={isLoading}
        />
      </div>
      {disabled &&
      disabledToolTipText &&
      status !== ACCOUNTING_TRANSACTION_STATUS.SYNCED ? (
        <Tooltip id={tooltipId} direction="bottom">
          <Text translationKey={disabledToolTipText} />
        </Tooltip>
      ) : null}
    </>
  );
}

Action.propTypes = {
  status: PropTypes.oneOf(Object.values(ACCOUNTING_TRANSACTION_STATUS)),
  disabled: PropTypes.bool,
  id: PropTypes.number,
  onClick: PropTypes.func,
  exportable: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabledToolTipText: PropTypes.string,
  billPayWithContinousSync: PropTypes.bool,
  ctas: PropTypes.array,
  classes: PropTypes.string,
};
