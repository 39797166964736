export const formatDataForTable = (
  transaction,
  options = { claimAmountEditEnabled: false }
) => {
  const formatted = {
    accountingId: transaction?.accountingId,
    id: transaction?.id,
    paymentId: transaction?.paymentId,
    accountingPayeeId: transaction?.accountingPayeeId,
    accountingStatus: transaction?.accountingStatus,
    editable: transaction?.editable,
    rule: transaction?.rule,
    merchantOrMileage: {
      name: transaction?.merchant?.name,
      category: transaction?.merchant?.category,
      status: transaction?.accountingStatus,
      avatarUrl: transaction?.globalMerchant?.logoUrl,
    },
    amount: {
      amount: options?.claimAmountEditEnabled
        ? transaction?.amountToBePaid
        : transaction?.amount?.value,
      currency: transaction?.amount?.currency,
    },
    owner: transaction?.createdBy,
    receipt: {
      hasReceipt: transaction?.receiptAttached,
      required: true,
      attached: transaction?.receiptAttached,
    },
    memo: {
      required: transaction?.memoRequired,
      value: transaction?.memo,
    },
    transactionLinkedTo: {
      linkedTo: transaction?.linkedTo?.title,
      project: transaction?.linkedTo?.name,
    },
    action: {
      status: transaction?.accountingStatus,
      verifiable: transaction?.verifiable,
      syncable: transaction?.syncable,
      ctas: transaction?.accountingCtas,
    },
    accountingTag: {
      arr: transaction?.accountingTags,
    },
    customTag: {
      arr: transaction?.customTags,
    },
    verifiable: transaction?.verifiable,
    syncable: transaction?.syncable,
    notActionableReason: transaction?.notActionableReason,
    status: transaction?.status,
    openIn: transaction?.accountingUrl,
    transactionDate: transaction?.transactionDate,
    type: transaction?.type,
    distanceTravelled: transaction?.distanceTravelled,
    travelDate: transaction?.travelDate,
    paymentDate: transaction?.paymentDate,
  };

  return formatted;
};
