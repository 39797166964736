import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Button from "@/components/core/Button";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function ExpenseAndQRPaySplitFooter({
  inProgress,
  disableContinueButton,
  submitSplit,
  isInEditMode,
  handleDeleteExpense,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const removeSearchParam = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.expenses.splitExpense);
    setSearchParams(searchParams);
  };
  return (
    <div className="flex items-center justify-end h-full">
      {isInEditMode ? (
        <Button
          label="cards.cardDetails.overview.policyDetails.expenses.ctas.editSplit"
          variant="tertiary"
          type="danger"
          classes="w-14"
          onClick={handleDeleteExpense}
        />
      ) : (
        <Button
          label="cards.cardDetails.editCardDetails.CTALabels.cancel"
          type="danger"
          variant="tertiary"
          onClick={removeSearchParam}
          classes="w-14"
        />
      )}
      <div className="ml-6">
        <Button
          label="myVolopay.gettingStarted.firstUserOnboardingModal.saveAndContinue"
          classes="w-16.2"
          type="default"
          variant="primary"
          showLoader={inProgress}
          disabled={disableContinueButton}
          onClick={() => {
            submitSplit();
          }}
        />
      </div>
    </div>
  );
}
ExpenseAndQRPaySplitFooter.propTypes = {
  disableContinueButton: PropTypes.bool,
  inProgress: PropTypes.bool,
  submitSplit: PropTypes.func,
  handleDeleteExpense: PropTypes.func,
  isInEditMode: PropTypes.bool,
};
