import PropTypes from "prop-types";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  updateAccountingData,
  updateTagAndVendor,
} from "@/store/reducers/accounting_transactions";
import { updateExpense } from "@/store/reducers/expense";
import { setSelectedPayment } from "@/store/reducers/payments";

import {
  accountingVendorsSelector,
  isFetchingAccountingVendorsSelector,
} from "@/store/selectors/accounting";
import {
  accountingIntegrationSoftwareSelector,
  isDepartmentClientBudgetTypeSelector,
  transactionLevelTagsSetSelector,
} from "@/store/selectors/client";
import {
  accountingCategoryTagsSelector,
  accountingNonCategoryTags,
  billPayCustomTransactionLevelTagsSelector,
  cardsCustomTagsSelector,
  isTagsFetchedSelector,
  payrollCustomTagsSelector,
  qrpayCustomTagsSelector,
  reimbursementCustomTagsSelector,
} from "@/store/selectors/tags";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VpSelect from "@/components/core/VpSelect";
import DateSelect from "@/components/Expenses/ExpenseSliders/ExpenseSlider/DateSelect";
import TagInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/TagInput";
import ExpenseAndQRPaySplitDisplay from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPaySplitDisplay";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import { dateToString, debounce } from "@/utils/common";
import { GST_RADIO_BUTTONS } from "@/utils/constants/expenses";
import { INTEGRATION_TAGS_TYPE } from "@/utils/constants/integrations";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_HEADER_IDS,
  ACCOUNTING_SOFTWARES_TAG_TITLES,
  ACCOUNTING_TRANSACTION_PAGES,
} from "@/constants/accounting";
import { ACCOUNTING_OWNER_TYPE } from "@/constants/rules";
import { TAG_FIELD_TYPES } from "@/constants/tags";

import {
  isRuleApplied,
  renderCustomUIForOptionWhenDropdownClosed,
  renderRuleAppliedIconForOptionWhenDropdownClosed,
} from "../util";

export default function SliderAccountingSection({
  inputs,
  showSplit = false,
  splitExpenses = [],
  page = "",
  transaction,
  onSuccess = () => {},
  accountingHeading,
  nonAccountingHeading,
  customHeading,
  isAccounting,
  showCustomFields = true,
  hideAccoutingIfNoAccountingTag = false,
  isHideCreateNewRule = false,
  showAccountingTitle: _showAccountingTitle = false,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category, merchant, department, location, accountingDateOptions } =
    inputs;

  const dispatch = useDispatch();

  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );
  const categoryAccountingTags = useSelector(accountingCategoryTagsSelector);
  const nonCategoryAccountingTags = useSelector(accountingNonCategoryTags);
  const accountingVendors = useSelector(accountingVendorsSelector);
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const isFetchedAccountingTags = useSelector(isTagsFetchedSelector);
  const isFetchingAccountingVendors = useSelector(
    isFetchingAccountingVendorsSelector
  );
  const isDepartmentClientBudget = useSelector(
    isDepartmentClientBudgetTypeSelector
  );

  const customTagsSelector = getCustomTagSelector();

  const customTags = useSelector(customTagsSelector) ?? [];

  const [accountingPayeeId, setAccountingPayeeId] = useState(
    transaction?.accountingPayeeId
  );
  const [isGstApplied, setIsGstApplied] = useState(transaction?.gstApplied);

  const [isUpdatingAccountingCategory, setIsUpdatingAccountingCategory] =
    useState(false);
  const [isUpdatingAccountingVendor, setIsUpdatingAccountingVendor] =
    useState(false);

  const [selectedAccountingCategoryTag, setSelectedAccountingCategoryTag] =
    useState([]);

  const accountingEnabled = accountingIntegrationSoftware || false;
  const showAccountingTitle =
    (isAccounting || hideAccoutingIfNoAccountingTag) && !_showAccountingTitle
      ? accountingEnabled
      : true;

  const noShowVendor =
    page === ACCOUNTING_TRANSACTION_PAGES.OTHER ||
    page === ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT ||
    page === ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL;

  const pageIsNotOthers = page !== ACCOUNTING_TRANSACTION_PAGES.OTHER;

  const defaultVendorOption = isHideCreateNewRule
    ? null
    : {
        id: -1,
        name: transaction?.vendor?.name || transaction?.merchant?.name,
        alias: transaction?.vendor?.name || transaction?.merchant?.name,
      };

  const selectedVendor = accountingPayeeId
    ? accountingVendors.find(
        (vendorItem) => vendorItem?.id === accountingPayeeId
      )
    : defaultVendorOption;

  const createNewRuleOption = {
    id: -2,
    name: "accounting.rules.createNewRule.title",
    alias: "accounting.rules.createNewRule.title",
  };
  const updatedVendorOptions = [
    ...(isHideCreateNewRule ? [] : [defaultVendorOption]),
    ...accountingVendors,
    ...(pageIsNotOthers && !isHideCreateNewRule ? [createNewRuleOption] : []),
  ];

  const displayAllNonCategoryTags =
    !splitExpenses?.length &&
    page !== ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL &&
    accountingEnabled &&
    nonCategoryAccountingTags.length > 0;

  const transactionLevelTagsSet = useSelector(transactionLevelTagsSetSelector);
  const transactionLevelTags = useMemo(
    () =>
      nonCategoryAccountingTags.filter((tag) =>
        transactionLevelTagsSet.has(tag.id)
      ),
    [JSON.stringify(nonCategoryAccountingTags)]
  );
  const displaytransactionLevelTags =
    (splitExpenses?.length ||
      page === ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL) &&
    accountingEnabled &&
    transactionLevelTags.length > 0;

  const showGstRadioButtons = page === ACCOUNTING_TRANSACTION_PAGES.EXPENSE;

  // const clearTagOption = {
  //   id: null,
  //   tagId: selectedAccountingCategoryTag?.tagId,
  //   name: "accounting.transactions.clearTagOption",
  //   alias: "accounting.transactions.clearTagOption",
  // };
  const categoryAccountingTagOptions = [
    ...(categoryAccountingTags?.options ?? []),
    ...(pageIsNotOthers && !isHideCreateNewRule ? [createNewRuleOption] : []),
  ];

  const transactionCustomTags = transaction?.customTags;
  const transactionNonCategoryAccountingTags =
    transaction?.accountingTags?.filter(
      (tag) => !tag?.name?.includes(INTEGRATION_TAGS_TYPE.CATEGORY)
    );
  const rule = transaction?.rule;
  const editable = transaction?.editable;

  const getAccountingDateOptionsConfig = () => {
    const cardTransactionDateConfig = {
      label: `Transaction date - ${dateToString(
        accountingDateOptions?.transactionDate
      )}`,
      description: "accounting.slider.cardsTransactionDate",
      value: accountingDateOptions?.transactionDate,
    };
    const cardTransactionSettledDateConfig = {
      label: `Ledger date - ${dateToString(transaction?.settlementDate)}`,
      description: "accounting.slider.cardsSettledDate",
      value: transaction?.settlementDate,
    };
    const customDateConfig = {
      label: "Custom date",
      description: "accounting.slider.customDate",
      icon: "Right",
    };

    const reimbursementTransactionDateConfig = {
      label: `Transaction date - ${dateToString(
        accountingDateOptions?.transactionDate || transaction?.travelDate
      )}`,
      description: "accounting.slider.reimbursementTransactionDate",
      value: accountingDateOptions?.transactionDate || transaction?.travelDate,
    };

    switch (page) {
      case ACCOUNTING_TRANSACTION_PAGES.EXPENSE:
        return [
          ...[
            cardTransactionDateConfig,
            cardTransactionSettledDateConfig,
            customDateConfig,
          ],
        ];
      case ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT:
        return [...[reimbursementTransactionDateConfig, customDateConfig]];
      default:
        return [...[customDateConfig]];
    }
  };

  const getDefaultAccountingDate = () => {
    switch (page) {
      case ACCOUNTING_TRANSACTION_PAGES.EXPENSE:
        return transaction?.accountingDate || transaction?.expenseDate;
      case ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT:
        return (
          transaction?.accountingDate ||
          accountingDateOptions?.transactionDate ||
          transaction?.travelDate
        );
      default:
        return (
          transaction?.accountingDate || accountingDateOptions?.transactionDate
        );
    }
  };

  const accountingDateOptionsConfig = getAccountingDateOptionsConfig();

  function getCustomTagSelector() {
    switch (page) {
      case ACCOUNTING_TRANSACTION_PAGES.EXPENSE:
        return cardsCustomTagsSelector;
      case ACCOUNTING_TRANSACTION_PAGES.REIMBURSEMENT:
        return reimbursementCustomTagsSelector;
      case ACCOUNTING_TRANSACTION_PAGES.PAYROLL:
        return payrollCustomTagsSelector;
      case ACCOUNTING_TRANSACTION_PAGES.QR_PAY_PARAM:
        return qrpayCustomTagsSelector;
      case ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL:
        return billPayCustomTransactionLevelTagsSelector;
      default:
        return customTagsSelector;
    }
  }

  const openSplit = () => {
    if (page === QRPAY_EXPENSES_CONTEXT.QRPAY) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.qrPayments.payments.splitQrPayment,
        transaction?.id
      );
    } else {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.expenses.splitExpense,
        transaction?.id
      );
    }
    setSearchParams(searchParams);
  };

  const getleftProp = (idname, tooltipText = "Rules text") => {
    return (
      <div id={`accounting-transaction-${idname}`}>
        <Icon name="OfflineBolt" className="cursor-pointer text-primary-300" />
        <Tooltip id={`accounting-transaction-${idname}`} direction="top">
          {tooltipText}
        </Tooltip>
      </div>
    );
  };

  const debouncedUpdate = debounce(
    (params) => dispatch(updateTagAndVendor(params)),
    500
  );

  const handleUpdateTagsAndVendor = ({
    type = "tags",
    params,
    isText,
    isDate,
  }) => {
    let payload = {
      accounting_id: transaction?.accountingId,
      transaction_type: page,
    };
    payload =
      type === INTEGRATION_TAGS_TYPE.VENDORS
        ? {
            ...payload,
            accounting_payee_id:
              params?.id === defaultVendorOption?.id ? null : params?.id,
          }
        : {
            ...payload,
            tag_id: params?.tagId || params?.id,
            ...(isText || isDate
              ? {
                  custom_text_value: isText
                    ? params?.currentTextValue
                    : params?.currentDateValue,
                }
              : { tag_value_id: params?.id }),
          };

    if (isText) {
      debouncedUpdate({ payload, onSuccess });
    } else {
      const onSuccessUpdateVendor = (newData) => {
        setAccountingPayeeId(newData?.accountingPayeeId || params?.id); // TODO: remove params.id when BE response is fixed
        onSuccess(newData);
        dispatch(setSelectedPayment(newData));

        // set loading false
        if (type === INTEGRATION_TAGS_TYPE.VENDORS)
          setIsUpdatingAccountingVendor(false);
        else setIsUpdatingAccountingCategory(false);
      };

      const onError = () => {
        // set loading false
        if (type === INTEGRATION_TAGS_TYPE.VENDORS)
          setIsUpdatingAccountingVendor(false);
        else setIsUpdatingAccountingCategory(false);
      };

      // set loading true
      if (type === INTEGRATION_TAGS_TYPE.VENDORS)
        setIsUpdatingAccountingVendor(true);
      else setIsUpdatingAccountingCategory(true);

      dispatch(
        updateTagAndVendor({
          payload,
          onSuccess: onSuccessUpdateVendor,
          onError,
        })
      );
    }
  };

  const handleTransactionDateUpdate = (customDate) => {
    const payload = {
      custom_spend_date: customDate,
    };
    dispatch(
      updateAccountingData({
        accounting_id: transaction?.accountingId,
        payload,
        onSuccess,
      })
    );
  };

  function handleOpenCreateNewRule(accountingId, type, id) {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.rules.createNewRuleFor,
      accountingId
    );
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.fieldType, type);
    if (type === ACCOUNTING_HEADER_IDS.TAG) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.fieldId, id);
    }
    setSearchParams(searchParams);
  }

  function handleGstApplied(gstAppliedBool) {
    setIsGstApplied(gstAppliedBool);
    dispatch(
      updateExpense({
        payload: { gst_applied: gstAppliedBool },
        expenseId: transaction?.id,
      })
    );
  }

  function customUIForLastOption(displayValue, option) {
    return (
      <div className="flex items-center gap-3 bg-primary-50 p-4 text-primary-500 !w-full ">
        <Icon name="Plus" className="w-5 h-5 mr-2" />
        <Text
          translationKey={displayValue}
          classes="text-btn-lg font-semibold"
        />
      </div>
    );
  }

  useEffect(() => {
    setAccountingPayeeId(transaction?.accountingPayeeId);
  }, [transaction?.accountingPayeeId]);

  useEffect(() => {
    if (transaction?.accountingTags?.length) {
      const foundTag = categoryAccountingTags?.options?.find((tag) =>
        transaction?.accountingTags?.some(
          (tagItem) => tagItem?.tagValueId === tag?.id
        )
      );

      setSelectedAccountingCategoryTag(foundTag);
    }
  }, [transaction]);

  return (
    <div className="flex flex-col gap-1 mt-8">
      <div className="flex items-center justify-between ">
        {showAccountingTitle ? (
          <Text
            translationKey={accountingHeading || "accounting.title"}
            classes="text-lg font-semibold text-neutral-800 my-4"
          />
        ) : null}

        {showSplit ? (
          <Button
            label={
              splitExpenses?.length
                ? "expenses.splitExpense.editBtn"
                : "expenses.splitExpense.splitBtn"
            }
            type="default"
            variant="secondary"
            size="sm"
            preIcon="CallSplit"
            classes="font-bold text-xs"
            onClick={openSplit}
          />
        ) : null}
      </div>
      <div className="flex flex-col gap-9">
        <div
          className={`grid ${
            !splitExpenses?.length &&
            accountingEnabled &&
            categoryAccountingTags?.options
              ? category
                ? "grid-cols-2"
                : "grid-cols-1"
              : "grid-cols-1"
          } gap-y-8 gap-x-6`}
        >
          {category ? (
            <Input
              label="accounting.transactions.cards.slider.volopayCategory"
              value={category?.value}
              disabled
            />
          ) : null}

          {!splitExpenses?.length &&
          page !== ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL &&
          accountingEnabled &&
          categoryAccountingTags?.options ? (
            <div className="flex items-center gap-6 w-full mt-0.5">
              <div className="w-full h-full">
                <VpSelect
                  disabled={!editable || isUpdatingAccountingCategory}
                  placeholder="expenses.slider.accountingCategory"
                  label="expenses.slider.accountingCategory"
                  value={selectedAccountingCategoryTag?.tagValueId}
                  leftProp={getleftProp("category")}
                  options={categoryAccountingTagOptions}
                  labelTranslationProp={{
                    accounting:
                      ACCOUNTING_SOFTWARES_TAG_TITLES[accountingSoftware],
                  }}
                  placeholderTranslationProp={{
                    accounting:
                      ACCOUNTING_SOFTWARES_TAG_TITLES[accountingSoftware],
                  }}
                  menuPosition="absolute"
                  isOptionsFetched={isFetchedAccountingTags}
                  isOptionsLoading={
                    !isFetchedAccountingTags || isUpdatingAccountingCategory
                  }
                  handleChange={(params) => {
                    handleUpdateTagsAndVendor({
                      params,
                    });
                  }}
                  translate
                  valueKey="id"
                  optionsDisplayKey="alias"
                  classes={editable ? "text-neutral-800" : "!text-neutral-400"}
                  placeholderStyleClasses={`text-xs font-semibold ${
                    editable ? "text-neutral-800" : "text-neutral-400"
                  }`}
                  lastOptionSticky={pageIsNotOthers && !isHideCreateNewRule}
                  onLastOptionSelected={() => {
                    if (pageIsNotOthers) {
                      handleOpenCreateNewRule(
                        transaction?.accountingId,
                        ACCOUNTING_HEADER_IDS.TAG,
                        categoryAccountingTags.id
                      );
                    }
                  }}
                  customUIForLastOption={
                    pageIsNotOthers ? customUIForLastOption : null
                  }
                  customUIForOptionWhenDropdownClosed={
                    isRuleApplied(
                      ACCOUNTING_OWNER_TYPE.TAG_VALUE,
                      selectedAccountingCategoryTag?.tagValueId,
                      rule
                    )
                      ? (label) =>
                          renderRuleAppliedIconForOptionWhenDropdownClosed(
                            label
                          )
                      : null
                  }
                />
              </div>
            </div>
          ) : null}
        </div>

        {!noShowVendor ? (
          <div
            className={`grid ${
              accountingEnabled
                ? merchant
                  ? "grid-cols-2"
                  : "grid-cols-1"
                : "grid-cols-1"
            } gap-y-8 gap-x-6`}
          >
            {merchant ? (
              <Input
                label="accounting.transactions.cards.slider.volopayMerchant"
                value={merchant?.value}
                disabled
              />
            ) : null}

            {accountingEnabled ? (
              <div className="flex items-center gap-6">
                <div className="w-full h-full">
                  <VpSelect
                    placeholder="expenses.slider.accountingMerchant"
                    label="expenses.slider.accountingMerchant"
                    value={
                      transaction?.accountingPayeeId
                        ? transaction?.accountingPayeeId
                        : -1
                    }
                    leftProp={getleftProp("vendor")}
                    options={updatedVendorOptions}
                    labelTranslationProp={{
                      accounting:
                        ACCOUNTING_SOFTWARES_TAG_TITLES[accountingSoftware],
                    }}
                    placeholderTranslationProp={{
                      accounting:
                        ACCOUNTING_SOFTWARES_TAG_TITLES[accountingSoftware],
                    }}
                    menuPosition="absolute"
                    isOptionsFetched={isFetchingAccountingVendors}
                    isOptionsLoading={isUpdatingAccountingVendor}
                    handleChange={(params) => {
                      handleUpdateTagsAndVendor({ type: "vendors", params });
                    }}
                    valueKey="id"
                    optionsDisplayKey="alias"
                    placeholderStyleClasses={`text-xs font-semibold ${
                      editable ? "text-neutral-800" : "!text-neutral-400"
                    }`}
                    disabled={
                      !editable ||
                      isUpdatingAccountingVendor ||
                      isFetchingAccountingVendors
                    }
                    customUIForOptionWhenDropdownClosed={
                      isRuleApplied(
                        ACCOUNTING_OWNER_TYPE.ACCOUNTING_PAYEE,
                        selectedVendor?.id,
                        rule
                      )
                        ? (label) =>
                            renderRuleAppliedIconForOptionWhenDropdownClosed(
                              label,
                              rule?.ruleType
                            )
                        : selectedVendor?.id === -1
                          ? (label) =>
                              renderCustomUIForOptionWhenDropdownClosed(label)
                          : null
                    }
                    lastOptionSticky={pageIsNotOthers && !isHideCreateNewRule}
                    onLastOptionSelected={() => {
                      if (pageIsNotOthers) {
                        handleOpenCreateNewRule(
                          transaction?.accountingId,
                          ACCOUNTING_HEADER_IDS.VENDOR
                        );
                      }
                    }}
                    customUIForLastOption={
                      pageIsNotOthers ? customUIForLastOption : null
                    }
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        {displayAllNonCategoryTags ? (
          <AccountingTags
            tags={nonCategoryAccountingTags}
            transactionNonCategoryAccountingTags={
              transactionNonCategoryAccountingTags
            }
            pageIsNotOthers={pageIsNotOthers}
            isHideCreateNewRule={isHideCreateNewRule}
            createNewRuleOption={createNewRuleOption}
            handleUpdateTagsAndVendor={handleUpdateTagsAndVendor}
            editable={editable}
            rule={rule}
            handleOpenCreateNewRule={handleOpenCreateNewRule}
            transaction={transaction}
            customUIForLastOption={customUIForLastOption}
          />
        ) : null}

        {displaytransactionLevelTags ? (
          <AccountingTags
            tags={transactionLevelTags}
            transactionNonCategoryAccountingTags={
              transactionNonCategoryAccountingTags
            }
            pageIsNotOthers={pageIsNotOthers}
            isHideCreateNewRule={isHideCreateNewRule}
            createNewRuleOption={createNewRuleOption}
            handleUpdateTagsAndVendor={handleUpdateTagsAndVendor}
            editable={editable}
            rule={rule}
            handleOpenCreateNewRule={handleOpenCreateNewRule}
            transaction={transaction}
            customUIForLastOption={customUIForLastOption}
          />
        ) : null}

        {isDepartmentClientBudget ? (
          <div className="flex items-center w-full gap-6 ">
            {department ? (
              <div className="w-full">
                <Input
                  label="accounting.transactions.cards.slider.volopayDepartment"
                  value={department?.value}
                  classes="text-base font-medium text-neutral-400"
                  disabled
                />
              </div>
            ) : null}
            {location ? (
              <div className="w-full">
                <Input
                  label="accounting.transactions.cards.slider.volopayLocation"
                  value={location?.value}
                  disabled
                />
              </div>
            ) : null}
          </div>
        ) : null}

        {splitExpenses?.length ? (
          <ExpenseAndQRPaySplitDisplay
            disabled={!editable}
            isAccounting={isAccounting}
          />
        ) : null}

        {accountingDateOptions ? (
          <DateSelect
            options={accountingDateOptionsConfig}
            label="expenses.slider.accountingDate"
            placeholder="expenses.slider.accountingDate"
            value={dateToString(getDefaultAccountingDate())}
            description={
              accountingEnabled ? "expenses.slider.editDateHelperText" : null
            }
            isDisabled={!editable}
            handleOptionChange={
              !editable
                ? () => {}
                : (index, customDateInput) => {
                    let formattedDate = null;
                    if (customDateInput) {
                      formattedDate = `${customDateInput?.getFullYear()}-${`0${
                        (customDateInput?.getMonth() ?? 0) + 1
                      }`.slice(-2)}-${`0${customDateInput?.getDate()}`.slice(
                        -2
                      )}`;
                    }

                    handleTransactionDateUpdate(
                      formattedDate || accountingDateOptionsConfig[index]?.value
                    );
                  }
            }
          />
        ) : null}
      </div>

      {showGstRadioButtons ? (
        <div className="flex flex-col gap-3 my-4">
          <Text
            translationKey="expenses.slider.gst.label"
            classes="text-sm font-semibold text-neutral-800"
          />

          <div className="flex flex-row justify-start gap-5">
            {GST_RADIO_BUTTONS.map((gstRadioButtonsInfo, index) => {
              const isChecked = gstRadioButtonsInfo?.isChecked(isGstApplied);
              const handleRadioButtonChange = gstRadioButtonsInfo?.handleChange;

              return (
                <Radio
                  disabled={!editable}
                  label={gstRadioButtonsInfo?.label}
                  textClases={gstRadioButtonsInfo?.textClasses}
                  handleChange={() => handleRadioButtonChange(handleGstApplied)}
                  isChecked={isChecked}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      ) : null}

      {showCustomFields && !splitExpenses?.length && customTags?.length ? (
        <div className="flex flex-col gap-6 ">
          <Text
            translationKey={
              customHeading ??
              "accounting.transactions.cards.slider.customFieldsHeading"
            }
            classes={
              !accountingEnabled
                ? "text-lg font-semibold text-neutral-800"
                : "text-base font-semibold text-neutral-800"
            }
          />
          <div
            className={`flex flex-col gap-9 ${
              editable ? "text-neutral-800" : "text-neutral-400 opacity-70"
            }`}
          >
            {customTags?.map((tag, index) => {
              const selectedCustomTag = transactionCustomTags?.find(
                (item) => item?.tagId === tag?.id
              );

              const selectedCustomTagOptions = [
                ...(tag?.options ?? []),
                ...(pageIsNotOthers && !isHideCreateNewRule
                  ? [createNewRuleOption]
                  : []),
              ];
              const isText = tag?.fieldType === TAG_FIELD_TYPES.TEXT;
              const isDate = tag?.fieldType === TAG_FIELD_TYPES.DATE;
              const selectedCustomTagValue =
                isText || isDate
                  ? selectedCustomTag?.customTextValue
                  : selectedCustomTag?.tagValueId;

              return (
                <div key={`vp-select-custom-tags-accounting-section-${index}`}>
                  <TagInput
                    tag={tag}
                    values={selectedCustomTagValue}
                    isDirectValue
                    wantValueOnBlur
                    disabled={!editable}
                    callTags={false}
                    vpSelectProps={{
                      customUIForOptionWhenDropdownClosed: isRuleApplied(
                        ACCOUNTING_OWNER_TYPE.TAG_VALUE,
                        selectedCustomTagValue,
                        rule
                      )
                        ? (label) =>
                            renderRuleAppliedIconForOptionWhenDropdownClosed(
                              label,
                              rule?.ruleType
                            )
                        : null,

                      lastOptionSticky: pageIsNotOthers,
                      onLastOptionSelected: () => {
                        if (pageIsNotOthers) {
                          handleOpenCreateNewRule(
                            transaction?.accountingId,
                            ACCOUNTING_HEADER_IDS.TAG,
                            tag?.id
                          );
                        }
                      },
                      options: selectedCustomTagOptions,
                      customUIForLastOption: pageIsNotOthers
                        ? customUIForLastOption
                        : null,
                    }}
                    dropdownInputKey="tagValueId"
                    handleChange={(params) => {
                      handleUpdateTagsAndVendor({
                        ...(isDate || isText
                          ? {
                              params: {
                                ...(isText
                                  ? { currentTextValue: params?.textValue }
                                  : {}),
                                ...(isDate
                                  ? { currentDateValue: params?.target?.value }
                                  : {}),
                                ...tag,
                              },
                            }
                          : { params }),
                        isText,
                        isDate,
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}

const AccountingTags = ({
  tags,
  transactionNonCategoryAccountingTags,
  pageIsNotOthers,
  isHideCreateNewRule,
  createNewRuleOption,
  handleUpdateTagsAndVendor,
  editable,
  rule,
  handleOpenCreateNewRule,
  transaction,
  customUIForLastOption,
}) => {
  return (
    <div className="flex flex-col gap-4">
      {tags?.map((tagsValue, index) => {
        const selectedNonCategoryAccountingTag =
          transactionNonCategoryAccountingTags?.find(
            (item) => item?.tagId === tagsValue?.id
          );
        const selectedNonCategoryAccountingOption = tagsValue?.options?.find(
          (item) => item?.id === selectedNonCategoryAccountingTag?.tagValueId
        );

        const nonCategoryAccountingTagsOptions = [
          ...(tagsValue?.options ?? []),
          ...(pageIsNotOthers && !isHideCreateNewRule
            ? [createNewRuleOption]
            : []),
        ];
        return (
          <div key={`accounting-section-${index}`}>
            <VpSelect
              key={`vp-select-accounting-section-${index}`}
              label={tagsValue?.name}
              placeholder={tagsValue?.name}
              value={selectedNonCategoryAccountingOption?.id}
              menuPosition="absolute"
              options={nonCategoryAccountingTagsOptions}
              handleChange={(params) => {
                handleUpdateTagsAndVendor({
                  params,
                });
              }}
              translate
              valueKey="id"
              optionsDisplayKey="alias"
              classes={`${!editable ? "opacity-80" : ""}`}
              placeholderStyleClasses={`text-xs font-semibold  ${
                editable ? "text-neutral-800" : "!text-neutral-400"
              }`}
              disabled={!editable}
              customUIForOptionWhenDropdownClosed={
                isRuleApplied(
                  ACCOUNTING_OWNER_TYPE.TAG_VALUE,
                  selectedNonCategoryAccountingOption?.id,
                  rule
                )
                  ? (label) =>
                      renderRuleAppliedIconForOptionWhenDropdownClosed(
                        label,
                        rule?.ruleType
                      )
                  : null
              }
              lastOptionSticky={pageIsNotOthers && !isHideCreateNewRule}
              onLastOptionSelected={() => {
                if (pageIsNotOthers) {
                  handleOpenCreateNewRule(
                    transaction?.accountingId,
                    ACCOUNTING_HEADER_IDS.TAG,
                    selectedNonCategoryAccountingOption.tagId
                  );
                }
              }}
              customUIForLastOption={
                pageIsNotOthers ? customUIForLastOption : null
              }
            />
          </div>
        );
      })}
    </div>
  );
};

SliderAccountingSection.propTypes = {
  inputs: PropTypes.object,
  showSplit: PropTypes.bool,
  page: PropTypes.string,
  transaction: PropTypes.object,
  splitExpenses: PropTypes.array,
};
