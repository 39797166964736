import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// core components
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import VpLink from "@/components/core/vp-link";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function AccountingSoftwareCard({
  label,
  icon,
  description,
  linkTo,
  searchParam,
}) {
  const [showConnect, setShowConnect] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleMouseEnter = () => {
    setShowConnect(true);
  };

  const setSliderHandler = () => {
    searchParams.set(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.software,
      searchParam
    );
    setSearchParams(searchParams);
  };

  const handleMouseLeave = () => {
    setShowConnect(false);
  };

  return (
    <div
      className="p-6 w-120 h-60 rounded-lg shadow-md flex flex-col gap-1 border border-neutral-200"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex justify-between items-center gap-1">
        <Icon
          name={icon}
          className="w-11 h-11 rounded-full overflow-hidden bg-lightgray text-white bg-no-repeat bg-center bg-contain relative border border-neutral-200"
        />
        {showConnect ? (
          <Button
            label="accounting.integrations.buttonLabel.connect"
            classes="py-1 px-5 w-fit text-xs font-bold flex"
            size="sm"
            preIcon="Power"
            innerClasses="gap-0 py-1.5"
            onClick={() => setSliderHandler()}
          />
        ) : null}
      </div>

      <div className="flex flex-col gap-2 h-full">
        <div>
          <Text
            translationKey={label}
            classes="text-lg font-semibold text-neutral-800"
          />
        </div>
        <div className="flex flex-col justify-between h-13 items-start">
          <Text
            translationKey={description}
            classes="text-sm font-medium text-neutral-800"
          />
          <VpLink
            onClickHandler={() => {
              window.open(linkTo, "_blank");
            }}
            text="accounting.integrations.linkLabel.readMore"
            textClasses="text-sm underline font-semibold"
          />
        </div>
      </div>
    </div>
  );
}

AccountingSoftwareCard.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  linkTo: PropTypes.string,
  searchParam: PropTypes.string,
};
