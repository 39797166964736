import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import BoltOnTheLeft from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/BoltOnTheLeft";
import { CREATE_BILL_FLOW_CONTEXT_KEYS } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import DateInputTextBox from "@/components/common/DateInputTextBox";
import {
  compareDates,
  dateToString,
  validateAndConvertToISO8601,
} from "@/utils/common";

import { DATEINPUT_MODES } from "@/constants/date";

export default function PurposeSection({
  values,
  errors,
  handleChange,
  showBolt,
  disabled,
  createBillFlowContext,
  ocrMode,
  billOCRSuccess,
  classes = "",
  isMemoError,
  isOverDue,
}) {
  const { t } = useTranslation();
  return (
    <div className={`relative flex flex-col gap-9 mt-12 ${classes}`}>
      <Text
        translationKey="billPay.bill.invoiceInbox.createBill.sections.purpose.title"
        classes="text-xl font-bold"
      />
      <BoltOnTheLeft
        showBolt={
          ocrMode &&
          billOCRSuccess &&
          createBillFlowContext.current[
            CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
          ]?.invoiceNumber?.trim?.() &&
          createBillFlowContext.current[
            CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
          ]?.invoiceNumber === values.invoiceNumber
        }
        classes={disabled ? "opacity-50" : ""}
      >
        <Input
          name="invoiceNumber"
          label="billPay.bill.invoiceInbox.createBill.sections.purpose.invoiceNumber"
          value={values.invoiceNumber}
          error={errors.invoiceNumber}
          onChange={handleChange}
        />
      </BoltOnTheLeft>
      <div
        className={`${disabled ? "opacity-50" : ""} grid grid-cols-2 gap-x-6`}
      >
        <BoltOnTheLeft
          showBolt={
            ocrMode &&
            billOCRSuccess &&
            createBillFlowContext.current[
              CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
            ]?.invoiceDate &&
            compareDates(
              createBillFlowContext.current[
                CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
              ]?.invoiceDate,
              values.invoiceDate
            )
          }
        >
          <DateInputTextBox
            name="invoiceDate"
            value={values.invoiceDate}
            handleChange={handleChange}
            label="billPay.bill.invoiceInbox.createBill.sections.purpose.invoiceDate"
            valueToShow={dateToString(values.invoiceDate, { year: "numeric" })}
            error={errors.invoiceDate}
            clearable
            autoComplete="off"
            mode={DATEINPUT_MODES.DATE}
          />
        </BoltOnTheLeft>

        <BoltOnTheLeft
          showBolt={
            ocrMode &&
            billOCRSuccess &&
            createBillFlowContext.current[
              CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
            ]?.dueDate &&
            compareDates(
              createBillFlowContext.current[
                CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
              ]?.dueDate,
              values.dueDate
            )
          }
        >
          <DateInputTextBox
            name="dueDate"
            value={values.dueDate}
            handleChange={handleChange}
            label="billPay.bill.invoiceInbox.createBill.sections.purpose.dueDate"
            valueToShow={dateToString(values.dueDate, { year: "numeric" })}
            error={errors.dueDate}
            clearable
            autoComplete="off"
            mode={DATEINPUT_MODES.DATE}
          />

          {isOverDue ? (
            <Text
              translationKey="billPay.bill.invoiceInbox.createBill.overdueWarningText"
              classes="text-xs text-danger-500 font-semibold"
            />
          ) : null}
        </BoltOnTheLeft>
      </div>

      <Input
        name="memo"
        label="billPay.bill.invoiceInbox.createBill.sections.purpose.memo"
        value={values.memo}
        error={errors.memo || (isMemoError ? t("misc.required") : "")}
        onChange={handleChange}
        outsideDivClasses={disabled ? "opacity-50" : ""}
      />
    </div>
  );
}

PurposeSection.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  showBolt: PropTypes.any,
  disabled: PropTypes.bool,
  createBillFlowContext: PropTypes.any,
  ocrMode: PropTypes.string,
  billOCRSuccess: PropTypes.bool,
  classes: PropTypes.string,
  isMemoError: PropTypes.bool,
  isOverDue: PropTypes.bool,
};
