import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";

import {
  getTransactions,
  resetTransactions,
} from "@/store/reducers/client_analytics";
import {
  fetchExpenses,
  resetExpensesListAndPagination,
} from "@/store/reducers/expense";

import {
  isFetchingTransactionsSelector,
  transactionsListHasMoreSelector,
  transactionsSelector,
} from "@/store/selectors/client_analytics";
import {
  expensesHasMoreSelector,
  expensesListSelector,
  isExpensesFetchingSelector,
} from "@/store/selectors/expense";
import {
  availableModulesSelector,
  isExternalAccountantSelector,
  selectedUserSelector,
  userSelector,
} from "@/store/selectors/user";

import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import SpendAnalyticsSnapshot from "@/components/ClientAnalytics/SpendAnalyticsSnapshot";
import SpendAnalyticsTable from "@/components/ClientAnalytics/SpendAnalyticsTable";
import { ROLES_TYPE } from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/SettingsType";
import { generateRedirectionUrlForFilters } from "@/utils/constants/searchAndFilter";
import { capitalizeFirstLetter } from "@/utils/common";

import { MODULES } from "@/utils/constants/app";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { VP_ERROR_COMPONENT_TYPE } from "@/constants/common";
import { SPEND_CATEGORY_REQUEST_VALUES } from "@/constants/company";
import { ROUTES } from "@/constants/routes";
import { ROLE_TITLE, ROLE_TITLES, USER_STATUS } from "@/constants/user";

import ShowCardsSection from "./Slider/People/UserDetail/ShowCardsSection";
import ActivityCardDetail from "./UserDetail/ActivityCardDetail";
import UserDetailCard from "./UserDetail/UserDetailCard";

export default function UserDetailsOverview() {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");

  const selectedUser = useSelector(selectedUserSelector) || {};
  const availableModules = useSelector(availableModulesSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const customRoles = selectedUser?.customRoles;

  const showCustomRolesSection = customRoles?.length > 0;
  const isAccountant =
    selectedUser?.role?.[0] === ROLE_TITLE[ROLE_TITLES.INTERNAL_ACCOUNTANT];
  const isExternalAccountant = useSelector(isExternalAccountantSelector);

  const selectedUserIsExternalAccountant =
    selectedUser?.role?.name === ROLES_TYPE.EXTERNAL_ACCOUNTANT;

  const isUserBlocked =
    selectedUser?.status === USER_STATUS.BLOCKED ||
    isExternalAccountant ||
    selectedUserIsExternalAccountant;
  const userDetailCards = !isUserBlocked
    ? [
        {
          subtitle: "Role",
          title: selectedUser?.role?.name,
          action: () => {},
          showIcon: false,
          showCard: true,
        },
        {
          subtitle: "Department",
          title: selectedUser?.departmentName,
          action: () => {
            searchParams.append(
              SLIDERS_SEARCH_PARAMS.company.department.id,
              selectedUser?.departmentId
            );
            setSearchParams(searchParams);
          },
          showIcon: true,
          showCard: !isAccountant,
        },
        {
          subtitle: "Location",
          title: selectedUser?.locationName,
          action: () => {
            searchParams.append(
              SLIDERS_SEARCH_PARAMS.company.location.id,
              selectedUser?.locationId
            );
            setSearchParams(searchParams);
          },
          showIcon: true,
          showCard: !isAccountant,
        },
        {
          subtitle: "Projects",
          title: selectedUser?.projectsCount,
          action: () => {
            generateRedirectionUrlForFilters({
              parentRoute: ROUTES.manage.company.projects.absolutePath,
              dispatch,
              categoryKey: "Project member",
              categoryValues: searchParams.get(
                SLIDERS_SEARCH_PARAMS.company.people.id
              ),
              navigate,
            });
          },
          showIcon: selectedUser?.projectsCount > 0 ? true : false,
          showCard: !isAccountant,
        },
      ]
    : [
        {
          subtitle: "Role",
          title: selectedUser?.role?.name,
          action: () => {},
          showIcon: false,
          showCard: true,
        },
      ];

  selectedUser?.userTags?.forEach((tag) => {
    userDetailCards.push({
      subtitle: tag?.tagValue,
      title: tag?.customTextValue,
      action: () => {},
      showIcon: false,
      showCard: true,
    });
  });

  const spendAnalyticsTableTabs = [
    {
      name: "company.department.tabTitles.recentExpenses",
      key: 1,
      handleClick: (pageNum) => {
        dispatch(
          getTransactions({
            user_id: userId,
            sort_by_value: "transaction_date",
            limit: 5,
            page: pageNum,
          })
        );
      },
      listSelector: transactionsSelector,
      resetTableData: () => {
        dispatch(resetTransactions());
      },
      hasMoreSelector: transactionsListHasMoreSelector,
      inProgress: isFetchingTransactionsSelector,
      isExpenseTable: false,
    },
    {
      name: "company.department.tabTitles.largestExpense",
      key: 2,
      handleClick: (pageNum) => {
        dispatch(
          getTransactions({
            user_id: userId,
            sort_by_value: "transaction_amount",
            limit: 5,
            page: pageNum,
          })
        );
      },
      listSelector: transactionsSelector,
      inProgress: isFetchingTransactionsSelector,
      hasMoreSelector: transactionsListHasMoreSelector,
      isExpenseTable: false,
      resetTableData: () => {
        dispatch(resetTransactions());
      },
    },
    {
      name: "company.department.tabTitles.declinedExpense",
      key: 3,
      handleClick: (pageNum) => {
        dispatch(
          fetchExpenses({
            user: userId,
            expense_type: "decline",
            limit: 5,
            page: pageNum,
          })
        );
      },
      listSelector: expensesListSelector,
      inProgress: isExpensesFetchingSelector,
      hasMoreSelector: expensesHasMoreSelector,
      isExpenseTable: true,
      resetTableData: () => {
        dispatch(resetExpensesListAndPagination());
      },
    },
  ];

  const isAdmin =
    selectedUser?.roles?.findIndex((role) => role === ROLES_TYPE.ADMIN) >= 0;

  const userId = searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id);

  function handleCardActivities(serachParamKey) {
    searchParams.append(serachParamKey, true);
    setSearchParams(searchParams);
  }

  function handlePendingRequestType() {
    handleCardActivities(SLIDERS_SEARCH_PARAMS.company.people.pendingRequest);
  }

  const spendState = useLoadingErrorInjector({
    apiKey: "ClientAnalytics:spends",
    showLoader: true,
    error: {
      header: "Spend Analytics",
      headerClasses: "text-neutral-800 text-center text-lg font-bold mb-8",
    },
  });

  const missingDetailState = useLoadingErrorInjector({
    apiKey: "",
    showLoader: true,
    showErrorOnSuccess: selectedUser?.missingDetailsCount === "access denied",
    error: {
      header: t(
        "cards.cardDetails.overview.policyDetails.expenses.missingDetails"
      ),
      type: VP_ERROR_COMPONENT_TYPE.COMPACT,
    },
  });

  const currentUser = useSelector(userSelector);

  const cardState = useLoadingErrorInjector({
    apiKey: "",
    showLoader: true,
    showErrorOnSuccess: !(
      selectedUser?.id === currentUser?.id ||
      currentUser?.permissions?.some((el) => el.name === "card:manage")
    ),
    error: {
      header: t(`${selectedUser?.displayName}'s cards`),
    },
  });

  return (
    <>
      <div className="grid grid-cols-2 gap-5 mt-9 h-fit">
        {userDetailCards?.map((card, index) => (
          <div key={`user-detail-cards-${index}`}>
            <UserDetailCard
              title={card.title}
              subtitle={card.subtitle}
              classes="h-13"
            >
              {card?.showIcon ? (
                <Icon
                  name="NEArrow"
                  className="ml-1 cursor-pointer text-primary-500"
                  handleClick={card?.action}
                />
              ) : null}
            </UserDetailCard>
          </div>
        ))}
      </div>

      {showCustomRolesSection ? (
        <div className="flex flex-col gap-4 p-3 rounded-lg bg-neutral-50 mt-9">
          <div className="flex flex-col gap-1 ">
            <div className="flex items-center gap-1 font-semibold ">
              <Text translationKey="company.people.peopleMyProfileSlider.customRoles" />

              <Chip
                type="default"
                label={customRoles?.length}
                classes="bg-neutral-500 text-white rounded-lg ml-1"
                variant="primary"
              />
            </div>

            <div className="text-xs text-neutral-500">
              <Text translationKey="company.people.peopleMyProfileSlider.AddingCustomRoleDesc" />
            </div>
          </div>

          <div className="flex gap-1 p-3 bg-white rounded-lg">
            {customRoles?.map((role, index) => (
              <Chip
                key={`custom-roles-${index}`}
                classes="bg-neutral-50 rounded-full text-xs !font-bold text-neutral-600 border border-neutral-200"
                label={capitalizeFirstLetter(role?.roleName)}
              />
            ))}
          </div>
        </div>
      ) : null}
      {!isUserBlocked ? (
        <>
          <div className="flex flex-col w-full gap-3 mt-10">
            <Text
              translationKey={`${selectedUser?.name?.first}'s activity`}
              classes="text-lg font-semibold text-neutral-800"
            />
            <div className="flex flex-row justify-between gap-5">
              {availableModules?.length &&
              availableModules?.includes(MODULES.CARDS) ? (
                <ActivityCardDetail
                  handleRef={missingDetailState}
                  title={selectedUser?.missingDetailsCount}
                  subtitle="common.requestMissingDetails"
                  outerSectionStyles={
                    selectedUser?.missingDetailsCount > 0
                      ? "cursor-pointer"
                      : ""
                  }
                  handleClick={() =>
                    handleCardActivities(
                      SLIDERS_SEARCH_PARAMS.expenses.missingDetails
                    )
                  }
                >
                  <div className="flex items-center justify-center rounded-full bg-danger-50 text-danger-400 h-9 w-9">
                    <Icon className="w-6 h-6" name="ShieldCross" />
                  </div>
                </ActivityCardDetail>
              ) : null}

              <ActivityCardDetail
                title={selectedUser?.pendingRequestsCount || 0}
                subtitle="cards.requests.title"
                outerSectionStyles={
                  selectedUser?.requestsCount > 0 ? "cursor-pointer" : ""
                }
                handleClick={handlePendingRequestType}
              >
                <div className="flex items-center justify-center rounded-full bg-warning-50 text-warning-400 h-9 w-9">
                  <Icon className="w-6 h-6" name="ShieldCross" />
                </div>
              </ActivityCardDetail>
            </div>
          </div>

          {selectedUser?.cardsEnabled &&
          availableModules?.length &&
          availableModules?.includes(MODULES.CARDS) ? (
            <div className="flex flex-col gap-3 mt-9 " ref={cardState?.attach}>
              <ShowCardsSection />
            </div>
          ) : null}
        </>
      ) : null}
      <div className="mt-10" ref={spendState?.attach}>
        <div>
          <SpendAnalyticsSnapshot
            params={{
              spend_type: SPEND_CATEGORY_REQUEST_VALUES.PEOPLE,
              user_id: userId,
            }}
          />
        </div>

        <SpendAnalyticsTable
          tabs={spendAnalyticsTableTabs}
          outerContainerClasses="mt-5"
        />
      </div>
    </>
  );
}
