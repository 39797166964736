import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import {
  downloadFileFromResponse,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "@/utils/common";
import vToast from "@/utils/vToast";
import API from "@/api";

import { setIsFormSubmissionProgress } from "./loadersError";

const creditInitialState = {
  creditDetails: null,
  isFetchingCreditDetails: false,
  isFetchingCreditBills: false,
  creditBills: { list: [] },
};

// Credit Reducers
const creditSlice = createSlice({
  name: "credit",
  initialState: creditInitialState,
  reducers: {
    setCreditDetails(state, action) {
      state.creditDetails = action.payload;
    },
    setIsFetchingCreditDetails(state, action) {
      state.isFetchingCreditDetails = action.payload;
    },
    setCreditBills(state, action) {
      state.creditBills = action.payload;
    },
    setIsFetchingCreditBills(state, action) {
      state.isFetchingCreditBills = action.payload;
    },
  },
});

// Credit Actions
export const fetchCreditDetails = createAsyncThunk(
  "credit/fetchCreditDetails",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCreditDetails(true));
    const [err, response] = await to(API.Company.Credit.fetchCreditDetails());
    if (response) {
      dispatch(setCreditDetails(response.data));
    }
    dispatch(setIsFetchingCreditDetails(false));
  }
);

export const createCreditLimit = createAsyncThunk(
  "credit/createCreditLimit",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Company.Credit.createRequest(params.payload)
    );
    if (response) {
      params.onSuccess();
      vToast(getSuccessToastMessage(response));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const drawCredit = createAsyncThunk(
  "credit/drawCredit",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Company.Credit.drawCredit(params.payload)
    );
    if (response) {
      params.onSuccess();
      vToast(getSuccessToastMessage(response));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchCreditBills = createAsyncThunk(
  "credit/fetchCreditBills",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCreditBills(true));
    const [err, response] = await to(API.Company.Credit.fetchCreditBills());
    if (response) {
      dispatch(setCreditBills(response.data));
    }
    dispatch(setIsFetchingCreditBills(false));
  }
);

export const triggerCreditPayment = createAsyncThunk(
  "credit/triggerCreditPayment",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [err, response] = await to(
      API.Company.Credit.repayment(params.payload)
    );
    if (response) {
      params.onSuccess();
      vToast(getSuccessToastMessage(response));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const downloadBill = createAsyncThunk(
  "credit/downloadBill",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Company.Credit.downloadBill(params));
    if (response) {
      downloadFileFromResponse(
        response.data,
        "credit_bill.pdf",
        "application/pdf"
      );
    }
  }
);

// Exporting Modules

export const {
  setCreditDetails,
  setCreditBills,
  setIsFetchingCreditDetails,
  setIsFetchingCreditBills,
} = creditSlice.actions;

export default creditSlice.reducer;
