import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import { MAIL_CONTEXT } from "@/utils/constants/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import vToast from "@/utils/vToast";

import { VENDOR_CLASS } from "@/constants/common";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import API from "@/api";

import { setIsFormSubmissionProgress } from "./loadersError";

const vendorsInitialState = {
  vendors: {
    list: [],
    page: 0,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: 0,
    isFetching: false,
    hasMore: true,
    totalPages: 0,
  },
  selectedVendor: null,
  isFetchingSelectedVendor: false,
  filterKeys: {
    vendor: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.vendorBankDetailsStatus,
      AVAILABLE_FILTER_KEYS.recurringStatus,
    ],
    employee: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.employeeBankDetailsStatus,
      AVAILABLE_FILTER_KEYS.recurringStatus,
    ],
  },

  // GenericForm entities - generalizable to any entity
  vendorCreateRequirements: null, // dynamic form's skeleton. Actually used only for bank details part of vendor create
  isFetchingVendorCreateRequirements: false,
  bankDetailsForm: null, // type: 'initialFormValue' accepted by useForm
  bankDetailsFormReviewRows: [],

  vendorCreateForm: null, // type: 'initialFormValue' accepted by useForm

  countryCurrencyMappings: null,
  isFetchingCountryCurrencyMappings: false,

  paymentMethods: [],
  isFetchingPaymentMethods: false,

  isUpdatedBankDetails: false,
  apiCallFailed: false,
  onboardingMailDetails: null,
  isFetchingOnboardingMailDetails: false,

  bankAndSwiftCodes: null, // object of arrays
  isFetchingBankAndSwiftCodes: false,
  isResendVendorMailInProgress: false,
  isLoading: false,
  countriesForMail: null,
  isFetchingCountriesForMail: false,
  bankDetailsAlreadyAddedViaMail: false,
  isVerifyingVendor: false, // vendor/employee

  cityAndRegionMapping: [],
  isFetchingCityAndRegionMapping: false,
};

const vendorsSlice = createSlice({
  name: "vendors",
  initialState: vendorsInitialState,
  reducers: {
    resetVendorStore: () => vendorsInitialState,
    setVendors(state, action) {
      state.vendors.list = action.payload;
    },
    resetVendorsList(state, action) {
      state.vendors = vendorsInitialState.vendors;
    },
    appendToVendorsList(state, action) {
      const listToAppend = action.payload;
      state.vendors.list = state.vendors.list.concat(
        Array.isArray(listToAppend) ? listToAppend : [listToAppend]
      );
    },
    prependToVendorsList(state, action) {
      const listOrObj = action.payload;
      state.vendors.list = [
        ...(Array.isArray(listOrObj) ? listOrObj : [listOrObj]),
        ...state.vendors.list,
      ];
    },
    setVendorsTotal(state, action) {
      state.vendors.total = action.payload;
    },
    setVendorsTotalPages(state, action) {
      state.vendors.totalPages = action.payload;
    },
    removeVendor(state, action) {
      state.vendors.list = state.vendors.list.filter(
        (val) => Number(val.id) !== Number(action.payload)
      );
    },
    setIsUpdatedBankDetails(state, action) {
      state.isUpdatedBankDetails = action.payload;
    },
    setVendorsLimit(state, action) {
      state.vendors.limit = action.payload;
    },
    setOnboardingMailDetails(state, action) {
      state.onboardingMailDetails = action.payload;
    },
    setIsFetchingOnboardingMailDetails(state, action) {
      state.isFetchingOnboardingMailDetails = action.payload;
    },

    setVendorsPage(state, action) {
      state.vendors.page = action.payload;
    },
    setVendorsHasMore(state) {
      state.vendors.hasMore = state.vendors.list.length < state.vendors.total;
    },
    setVendorsIsFetching(state, action) {
      state.vendors.isFetching = action.payload;
    },
    setVendor(state, action) {
      const { id, value } = action.payload;
      const vendorIndex = state.vendors.list.findIndex(
        (vendor) => Number(vendor.id) === Number(id)
      ); // linear search for now

      if (vendorIndex !== -1) {
        state.vendors.list[vendorIndex] = value;
      }
    },

    setSelectedVendor(state, action) {
      state.selectedVendor = action.payload;
    },
    updateSelectedVendor(state, action) {
      state.selectedVendor = state.selectedVendor
        ? {
            ...state.selectedVendor,
            ...action.payload,
          }
        : action.payload;
    },
    setSelectedVendorIsFetching(state, action) {
      state.isFetchingSelectedVendor = action.payload;
    },
    setVendorCreateRequirements(state, action) {
      state.vendorCreateRequirements = action.payload;
    },
    setIsFetchingVendorCreateRequirements(state, action) {
      state.isFetchingVendorCreateRequirements = action.payload;
    },
    setBankDetailsForm(state, action) {
      state.bankDetailsForm = action.payload;
    },
    mergeIntoBankDetailsForm(state, action) {
      state.bankDetailsForm = { ...state.bankDetailsForm, ...action.payload };
    },
    mergeIntoBankDetailsFormSetter(state, action) {
      state.bankDetailsForm = {
        ...state.bankDetailsForm,
        ...action.payload(state.bankDetailsForm),
      };
    },
    clearBankDetailsForm(state) {
      state.bankDetailsForm = null;
    },
    setVendorCreateForm(state, action) {
      state.vendorCreateForm = action.payload;
    },
    mergeIntoVendorCreateForm(state, action) {
      state.vendorCreateForm = { ...state.vendorCreateForm, ...action.payload };
    },
    clearVendorCreateForm(state) {
      state.vendorCreateForm = null;
    },
    setBankDetailsFormReviewRows(state, action) {
      state.bankDetailsFormReviewRows = action.payload;
    },

    setCountryCurrencyMappings(state, action) {
      state.countryCurrencyMappings = action.payload;
    },
    setIsFetchingCountryCurrencyMappings(state, action) {
      state.isFetchingCountryCurrencyMappings = action.payload;
    },

    setPaymentMethods(state, action) {
      state.paymentMethods = action.payload;
    },
    setIsFetchingPaymentMethods(state, action) {
      state.isFetchingPaymentMethods = action.payload;
    },

    setBankAndSwiftCodes(state, action) {
      state.bankAndSwiftCodes = action.payload;
    },
    resetBankAndSwiftCodes(state) {
      state.bankAndSwiftCodes = vendorsInitialState.bankAndSwiftCodes;
      state.isFetchingBankAndSwiftCodes =
        vendorsInitialState.isFetchingBankAndSwiftCodes;
    },
    setIsFetchingBankAndSwiftCodes(state, action) {
      state.isFetchingBankAndSwiftCodes = action.payload;
    },
    setCityAndRegionMapping(state, action) {
      state.cityAndRegionMapping = action.payload;
    },
    resetCityAndRegionMapping(state) {
      state.cityAndRegionMapping = vendorsInitialState.cityAndRegionMapping;
      state.isFetchingCityAndRegionMapping =
        vendorsInitialState.isFetchingCityAndRegionMapping;
    },
    setIsFetchingCityAndRegionMapping(state, action) {
      state.isFetchingCityAndRegionMapping = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCountriesForMail(state, action) {
      state.countriesForMail = action.payload;
    },
    setIsFetchingCountriesForMail(state, action) {
      state.isFetchingCountriesForMail = action.payload;
    },
    setBankDetailsAlreadyAddedViaMail(state, action) {
      state.bankDetailsAlreadyAddedViaMail = action.payload;
    },
    setSelectedVendorAttachment(state, action) {
      state.selectedVendor = { ...state.selectedVendor, ...action.payload };
    },
    setIsResendVendorMailInProgress(state, action) {
      state.isResendVendorMailInProgress = action.payload;
    },
    mergeIntoVendorListItem(state, action) {
      const { id, value } = action.payload;
      state.vendors.list = state.vendors.list.map((item) =>
        Number(item.id) === Number(id) ? { ...item, ...value } : item
      ); // linear search for now
    },
    setIsVerifyingVendor(state, action) {
      state.isVerifyingVendor = action.payload;
    },
  },
});

export const fetchVendors = createAsyncThunk(
  "vendors/fetchVendors",
  async (params, { dispatch }) => {
    dispatch(setVendorsIsFetching(true));

    const {
      context,
      isSave = true,
      onSuccess = () => {},
      ...rest
    } = params ?? {};
    const [err, response] = await to(
      API.Vendors.all(
        params.context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? { ...rest, vendor_class: VENDOR_CLASS.PAYROLL }
          : rest
      )
    );

    if (!err && response) {
      const { data } = response;

      if (isSave) {
        if (params.pageNumber === 1) {
          dispatch(setVendors(data.list));
        } else {
          dispatch(appendToVendorsList(data.list));
        }

        dispatch(setVendorsLimit(data.limit));
        dispatch(setVendorsTotal(data.total));
        dispatch(setVendorsTotalPages(data.totalPages));
        dispatch(setVendorsPage(data.page));
        dispatch(setVendorsHasMore());
      }
      onSuccess(data);
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setVendorsIsFetching(false));
  }
);

export const fetchVendorsShallow = createAsyncThunk(
  "vendors/fetchVendorsShallow",
  async (params, { dispatch }) => {
    dispatch(setVendorsIsFetching(true));

    const { context, ...rest } = params ?? {};
    const [err, response] = await to(
      API.Vendors.all({
        shallow: true,
        not_archived: true,
        vendor_class:
          params.context === BILL_PAYROLL_CONTEXT.PAYROLL
            ? VENDOR_CLASS.PAYROLL
            : VENDOR_CLASS.USER_CREATED,
        ...rest,
      })
    );

    if (!err && response) {
      const { data = [] } = response;

      dispatch(setVendors(data));
      dispatch(setVendorsLimit(data.length));
      dispatch(setVendorsTotal(data.length));
      dispatch(setVendorsTotalPages(1));
      dispatch(setVendorsPage(1));
      dispatch(setVendorsHasMore());
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setVendorsIsFetching(false));
  }
);

export const fetchAndSelectVendor = createAsyncThunk(
  "vendors/fetchAndSelectVendor",
  async (params, { dispatch }) => {
    dispatch(setSelectedVendorIsFetching(true));

    const {
      id,
      onSuccess = () => {},
      isSetSelected = true,
      isSetListItem = false,
      ...rest
    } = params;
    const [err, response] = await to(API.Vendors.get(id, rest));

    if (!err && response) {
      const vendor = response.data?.data; // last 'data' not related to await-to-js, BE response body is of shape { message: '', data: {} }
      if (isSetSelected) dispatch(setSelectedVendor(vendor));
      if (isSetListItem) dispatch(setVendor({ id: vendor?.id, value: vendor }));
      onSuccess(vendor);
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setSelectedVendorIsFetching(false));
  }
);

export const searchAndSelectVendor = createAsyncThunk(
  "vendors/searchAndSelectVendor",
  async (params, { dispatch }) => {
    const {
      context,
      onVendorNotFound = () => {},
      onVendorFound = (vendor) => {},
      ...rest
    } = params;
    dispatch(setSelectedVendorIsFetching(true));
    const [err, response] = await to(
      API.Vendors.all({
        not_archived: true,
        ...(params.context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? { vendor_class: VENDOR_CLASS.PAYROLL }
          : {}),
        ...rest,
      })
    );

    if (!err && response) {
      const responseData = response?.data?.data || response?.data; // TODO response-nesting

      if (params.page === 1) {
        // dispatch(setVendors(data.list));
        const vendor = responseData.list[0]; // last 'data' not related to await-to-js, BE response body is of shape { message: '', data: {} }
        if (vendor) {
          onVendorFound(vendor);
        } else {
          onVendorNotFound();
        }
      } else {
        dispatch(appendToVendorsList(responseData.list));
      }
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setSelectedVendorIsFetching(false));
  }
);

export const updateVendor = createAsyncThunk(
  "vendors/updateVendor",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLoading(true));

    const {
      id,
      payload,
      context,
      param,
      onError = () => {},
      onSuccess = () => {},
      showSuccessToast = true,
      showErrorToast = true,
    } = params;

    const [error, response] = await to(
      API.Vendors.update(
        params.context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? {
              id,
              payload,
              params: { vendor_class: VENDOR_CLASS.PAYROLL, ...param },
            }
          : { id, payload, params: param }
      )
    );

    if (!error && response) {
      const vendor = response?.data?.data || response?.data; // TODO response-nesting
      dispatch(setSelectedVendor(vendor));
      dispatch(setVendor({ id, value: vendor }));
      onSuccess(vendor);
      if (showSuccessToast) vToast(getSuccessToastMessage(response));
    } else {
      onError();
      if (showErrorToast) vToast(getErrorToastMessage(error));
    }

    dispatch(setIsLoading(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const verifyVendor = createAsyncThunk(
  "vendors/verifyVendor",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsVerifyingVendor(true));

    const {
      id,
      payload,
      context,
      onError = () => {},
      onSuccess = () => {},
      showSuccessToast = true,
      showErrorToast = true,
    } = params;

    const [error, response] = await to(
      API.Vendors.verify(
        params.context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? { id, payload, params: { vendor_class: VENDOR_CLASS.PAYROLL } }
          : { id, payload }
      )
    );

    if (!error && response) {
      const vendor = response?.data?.data || response?.data; // TODO response-nesting
      dispatch(setSelectedVendor(vendor));
      dispatch(setVendor({ id, value: vendor }));
      onSuccess(vendor);
      if (showSuccessToast) vToast(getSuccessToastMessage(response));
    } else {
      onError();
      if (showErrorToast) vToast(getErrorToastMessage(error));
    }

    dispatch(setIsVerifyingVendor(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const fetchDetailsForBank = createAsyncThunk(
  "vendors/fetchDetailsForBank",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingOnboardingMailDetails(true));
    const { payload, onFail = () => {}, onSuccess = () => {} } = params;
    const [err, response] = await to(API.Vendors.getOnBoardingDetails(payload));

    if (response) {
      if (onSuccess) onSuccess();
      dispatch(setOnboardingMailDetails(response.data));
    }

    if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingOnboardingMailDetails(false));
  }
);

export const fetchCountriesForMail = createAsyncThunk(
  "vendors/fetchCountriesForMail",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCountriesForMail(true));

    const [err, response] = await to(API.Vendors.getCountriesForMail(params));
    if (response) {
      dispatch(setCountriesForMail(response?.data));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingCountriesForMail(false));
  }
);

export const fetchCountryCurrenciesForMail = createAsyncThunk(
  "vendors/fetchCountryCurrenciesForMail",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCountryCurrencyMappings(true));

    const [err, response] = await to(
      API.Vendors.getCountryCurrenciesForMail(params)
    );

    if (response) {
      dispatch(setCountryCurrencyMappings(response?.data));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingCountryCurrencyMappings(false));
  }
);

export const fetchVendorCreateRequirements = createAsyncThunk(
  "vendors/fetchVendorCreateRequirements",
  async (param, { dispatch }) => {
    dispatch(setIsFetchingVendorCreateRequirements(true));

    const { context, payload, onFail = () => {}, params } = param;
    const [err, response] = await to(
      context === MAIL_CONTEXT
        ? API.Vendors.getRequirementsForMailFlow(payload)
        : API.Vendors.getRequirements(payload)
    );

    if (response) {
      dispatch(setVendorCreateRequirements(response.data?.data));
    } else if (err) {
      if (onFail) onFail(err);
    }

    dispatch(setIsFetchingVendorCreateRequirements(false));
  }
);

export const createVendor = createAsyncThunk(
  "vendors/createVendor",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLoading(true));

    const {
      payload,
      updateSelectAlso,
      onSuccess = () => {},
      onError = () => {},
    } = params;
    const [error, response] = await to(
      API.Vendors.create(
        params.context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? { payload, params: { vendor_class: VENDOR_CLASS.PAYROLL } }
          : { payload }
      )
    );
    if (!error && response) {
      const vendor = response?.data?.data?.vendor || response?.data?.vendor; // TODO response-nesting
      vToast(getSuccessToastMessage(response));
      dispatch(prependToVendorsList(vendor));
      if (updateSelectAlso) dispatch(setSelectedVendor(vendor));
      onSuccess(vendor);
    } else if (error) {
      vToast(getErrorToastMessage(error));
      onError();
    }

    dispatch(setIsLoading(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const createVendorViaMail = createAsyncThunk(
  "vendors/createVendorViaMail",
  async (param, { dispatch }) => {
    const { payload, onSuccess = () => {}, params } = param;
    const [error, response] = await to(
      API.Vendors.createViaMail({ payload, params })
    );

    const createdVendor = response?.data?.data || response?.data; // TODO response-nesting
    if (!error && response) {
      if (onSuccess) {
        onSuccess(createdVendor);
      }
      // no success toast (page shows status)
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const deleteVendor = createAsyncThunk(
  "purchaseBills/deleteVendor",
  async (payload, { dispatch }) => {
    const { id, onSuccess = () => {}, onError = () => {}, ...rest } = payload;

    const [error, response] = await to(
      API.Vendors.archive({ vendor_ids: [id], ...rest })
    );

    if (!error && response) {
      const deletedVendor = response?.data?.data || response?.data; // TODO response-nesting
      vToast(getSuccessToastMessage(response));
      dispatch(removeVendor(id));
      onSuccess(deletedVendor);
    } else {
      onError();
      vToast(getErrorToastMessage(error));
    }
  }
);

export const fetchVendorCountryCurrencyMappings = createAsyncThunk(
  "vendors/fetchVendorCountryCurrencyMappings",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCountryCurrencyMappings(true));

    const [err, response] = await to(
      API.Vendors.getCountryCurrencyMappings(
        params.context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? { vendor_class: VENDOR_CLASS.PAYROLL }
          : {}
      )
    );

    if (!err && response) dispatch(setCountryCurrencyMappings(response.data));
    else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingCountryCurrencyMappings(false));
  }
);

export const fetchVendorPaymentMethods = createAsyncThunk(
  "vendors/fetchVendorPaymentMethods",
  async (params, { dispatch }) => {
    const { context, payload } = params;
    dispatch(setIsFetchingPaymentMethods(true));
    const [err, response] = await to(
      context === MAIL_CONTEXT
        ? API.Vendors.getPaymentMethodsViaMail(payload)
        : API.Vendors.getPaymentMethods(payload)
    );

    if (response && !err) {
      dispatch(setPaymentMethods(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingPaymentMethods(false));
  }
);

export const fetchBankAndSwiftCodes = createAsyncThunk(
  "vendors/fetchBankAndSwiftCodes",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingBankAndSwiftCodes(true));

    const [error, response] = await to(
      params?.isMailFlow
        ? API.Vendors.getBankAndSwiftCodesForMail(params)
        : API.Vendors.getBankAndSwiftCodes()
    );

    if (!error && response) {
      dispatch(setBankAndSwiftCodes(response.data));
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsFetchingBankAndSwiftCodes(false));
  }
);

export const fetchCityAndRegionMapping = createAsyncThunk(
  "vendors/fetchCityAndRegionMapping",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCityAndRegionMapping(true));

    const [error, response] = await to(
      API.Vendors.getCityAndRegionMapping(params)
    );

    if (!error && response) {
      dispatch(setCityAndRegionMapping(response.data));
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsFetchingCityAndRegionMapping(false));
  }
);

export const resendVendorMail = createAsyncThunk(
  "vendors/resendVendorMail",
  async (params, { dispatch }) => {
    dispatch(setIsResendVendorMailInProgress(true));

    const {
      id,
      context = BILL_PAYROLL_CONTEXT.BILLPAY,
      onSuccess = () => {},
      ...rest
    } = params;

    const [error, response] = await to(
      API.Vendors.triggerOnBoardingMail(id, rest)
    );

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
      onSuccess();
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsResendVendorMailInProgress(false));
  }
);

export const {
  resetVendorStore,
  setVendors,
  resetVendorsList,
  appendToVendorsList,
  prependToVendorsList,
  setVendorsTotal,
  setVendorsLimit,
  setVendorsPage,
  setVendorsTotalPages,
  setVendorsHasMore,
  setVendorsIsFetching,
  setVendor,
  setSelectedVendor,
  setSelectedVendorIsFetching,
  setVendorCreateRequirements,
  setIsFetchingVendorCreateRequirements,
  setBankDetailsForm,
  removeVendor,
  mergeIntoBankDetailsForm,
  mergeIntoBankDetailsFormSetter,
  clearBankDetailsForm,
  setVendorCreateForm,
  mergeIntoVendorCreateForm,
  clearVendorCreateForm,
  setBankDetailsFormReviewRows,
  setCountryCurrencyMappings,
  setIsFetchingCountryCurrencyMappings,
  setPaymentMethods,
  setIsFetchingPaymentMethods,
  setIsUpdatedBankDetails,
  setOnboardingMailDetails,
  setIsFetchingOnboardingMailDetails,
  setBankAndSwiftCodes,
  resetBankAndSwiftCodes,
  setIsFetchingBankAndSwiftCodes,
  setIsLoading,
  setCountriesForMail,
  setIsFetchingCountriesForMail,
  setBankDetailsAlreadyAddedViaMail,
  setSelectedVendorAttachment,
  updateSelectedVendor,
  setIsResendVendorMailInProgress,
  mergeIntoVendorListItem,
  setIsVerifyingVendor,
  setCityAndRegionMapping,
  resetCityAndRegionMapping,
  setIsFetchingCityAndRegionMapping,
} = vendorsSlice.actions;

export default vendorsSlice.reducer;
