import PropTypes from "prop-types";
import React from "react";

// TODO: change this with accurate design when available
function NoRenderer({ document, fileName }) {
  const fileText = fileName || document?.fileType || "";

  return <div>no renderer available {fileText ?? ""}</div>;
}

export default NoRenderer;

NoRenderer.propTypes = {
  document: PropTypes.any,
  fileName: PropTypes.string,
};
