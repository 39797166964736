import { createSelector } from "@reduxjs/toolkit";

export const selectNotificationPreferencesStore = (store) =>
  store.notificationPreferences;

export const notificationPreferenceListSelector = createSelector(
  selectNotificationPreferencesStore,
  (notificationPreferences) => notificationPreferences.preferences
);

export const isFetchingNotificationPreferencesSelector = createSelector(
  selectNotificationPreferencesStore,
  (notificationPreferences) => notificationPreferences.isFetchingPreferences
);

export const personalNotificationPreferencesSelector = createSelector(
  notificationPreferenceListSelector,
  (preferences) => preferences?.personalPreferences
);

export const companyNotificationPreferencesSelector = createSelector(
  notificationPreferenceListSelector,
  (preferences) => preferences?.companyPreferences
);

export const isLoadingSelector = createSelector(
  selectNotificationPreferencesStore,
  (notificationPreferences) => notificationPreferences.isLoading
);
