import React from "react";
import ReactDOM from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";

import App from "@/App";

import "@/assets/styles/index.scss";

import { ToastProvider } from "./components/core/Toast";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <IntercomProvider appId={import.meta.env.VITE_INTERCOM_API}>
    <ToastProvider>
      <App />
    </ToastProvider>
  </IntercomProvider>
);
