import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import Text from "@/components/core/Text";

import UserDropdown from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/UserDropdown";
import { arraysHaveSameContentUnordered } from "@/utils/common";

import AddPeopleIcon from "../common/AddPeopleIcon";
import PaginatedPeopleTable from "../common/PaginatedPeopleTable";

function PeopleTabProj({
  projectOwners,
  people,
  projectMembers,
  handleSave,
  isFetching,
  showManageMemberCta,
}) {
  const [alert, setAlert] = useState(true);

  const [inEditMode, setInEditMode] = useState(false);

  const [selectedIdsManagers, setSelectedIdsManagers] = useState([]);

  const [selectedIdsMembers, setSelectedIdsMembers] = useState([]);

  const [editableManagers, setEditableManagers] = useState(projectOwners);

  const [editableMembers, setEditableMembers] = useState(projectMembers);

  const handleClose = () => {
    setEditableManagers(projectOwners);
    setEditableMembers(projectMembers);
    setSelectedIdsManagers([]);
    setSelectedIdsMembers([]);
    setInEditMode(false);
  };

  const handleInnerSave = () => {
    const editableManagersIds = editableManagers?.map((item) => item.id);
    const editableMembersIds = editableMembers?.map((item) => item.id);
    handleSave({
      managers: [...selectedIdsManagers, ...editableManagersIds],
      members: [...selectedIdsMembers, ...editableMembersIds],
    });
    handleClose();
  };

  const COMPONENTS_CONFIG = [
    {
      component: AddPeopleIcon,
      handleClick: (id) => {
        const clickManagersObject = editableManagers?.find(
          (item) => item.id === id
        );
        if (inEditMode) {
          setEditableMembers((prev) => [...prev, clickManagersObject]);
        }
      },
      checkDisable: (id) => {
        // if memeber exist then disable
        const disableMember = editableMembers?.some((item) => item.id === id);

        return inEditMode ? disableMember : false;
      },
    },
  ];

  const handleManagePeopleBtnClick = () => {
    setInEditMode(true);
  };

  const isMemberSame = arraysHaveSameContentUnordered(
    projectMembers?.map((i) => i.id),
    [...selectedIdsMembers, ...(editableMembers?.map((item) => item.id) ?? [])]
  );

  const isOwnersSame = arraysHaveSameContentUnordered(
    projectOwners?.map((i) => i.id),
    [
      ...selectedIdsManagers,
      ...(editableManagers?.map((item) => item.id) ?? []),
    ]
  );

  useEffect(() => {
    setEditableManagers(projectOwners);
  }, [projectOwners?.length]);

  useEffect(() => {
    setEditableMembers(projectMembers);
  }, [projectMembers?.length]);

  return (
    <>
      <div className="text-neutral-800 pb-9">
        {/* Top - title and button the right (below) */}
        <div className="flex items-center justify-between">
          <Text
            translationKey="company.department.departmentDetails.tabs.people.title"
            classes="text-xl font-bold"
          />
          {inEditMode ? null : showManageMemberCta ? (
            <Button
              variant="tertiary"
              iconClasses="text-xs"
              labelStyleClasses="text-xs font-bold"
              label="company.department.departmentDetails.tabs.people.managePeopleLabel"
              onClick={handleManagePeopleBtnClick}
              compact
              classes="shrink-0"
            />
          ) : null}
        </div>
        <div className="mt-8">
          <div className="flex items-center gap-2">
            <Text
              translationKey="company.department.addNewProject.projectOwner.pluralSecondary"
              classes="text-lg font-bold"
            />
            <Chip
              label={
                (inEditMode ? editableManagers : projectOwners)?.length ?? 0
              }
            />
          </div>
          <Text
            translationKey="company.department.departmentDetails.tabs.people.projectOwnerDescription"
            classes="block text-neutral-500 text-sm font-medium"
          />
          {alert && inEditMode ? (
            <div className="mt-5">
              <Alert
                variant="warning"
                description="company.department.departmentDetails.tabs.people.projectAlertDescription"
                title="company.department.departmentDetails.tabs.people.projectAlertTitle"
                close={() => {
                  setAlert((prev) => !prev);
                }}
              />
            </div>
          ) : null}
          {inEditMode ? (
            <div className={!selectedIdsManagers?.length ? "mt-5" : "mt-10"}>
              <UserDropdown
                dropdownLabelFastView=""
                users={people
                  ?.filter(
                    (item) =>
                      !editableManagers?.some(({ id }) => id === item.id)
                  )
                  ?.map((item) => ({
                    ...item,
                    name: item?.name,
                    value: item?.id,
                    label: item?.name,
                  }))}
                selectedIdsArray={selectedIdsManagers ?? []}
                handleSubmit={(value) => {
                  setSelectedIdsManagers(value?.selectedIds);
                }}
                isLoading={isFetching}
                label="company.department.departmentDetails.tabs.people.addDepartmentManagers"
              />
            </div>
          ) : null}

          <PaginatedPeopleTable
            handleDelete={(id) => {
              setEditableManagers((prev) =>
                prev?.filter((item) => item.id !== id)
              );
            }}
            // COMMENTED: component because causing confusion right now
            // otherComponents={COMPONENTS_CONFIG}
            emptyLabel="company.department.departmentDetails.tabs.people.projectOwneremptyLabel"
            emptyDescription="company.department.departmentDetails.tabs.people.ProjectOwneremptyDescription"
            emptyBtnLabel={
              showManageMemberCta
                ? "company.department.departmentDetails.tabs.people.emptyBtnDescription"
                : null
            }
            disableTooltip="company.department.addNewDepartment.atleastOneProjecttManagerTooltip"
            disabledDeleteButton={editableManagers?.length === 1}
            people={inEditMode ? editableManagers : projectOwners}
            inEditMode={inEditMode}
            handleEmptyBtnClick={handleManagePeopleBtnClick}
            limit={5}
          />
        </div>
        <div className="mt-8">
          <div className="flex items-center gap-2">
            <Text
              translationKey="company.department.departmentDetails.tabs.people.projectMemberTitle"
              classes="text-lg font-bold"
            />
            <Chip
              label={
                (inEditMode ? editableMembers : projectMembers)?.length ?? 0
              }
            />
          </div>
          <Text
            translationKey="company.department.departmentDetails.tabs.people.projectMemberDescription"
            classes="block text-neutral-500 text-sm font-medium"
          />
          {inEditMode ? (
            <div className="mt-5">
              <UserDropdown
                dropdownLabelFastView=""
                users={people
                  ?.filter(
                    (item) => !editableMembers?.some(({ id }) => id === item.id)
                  )
                  ?.map((item) => ({
                    ...item,
                    name: item?.name,
                    value: item?.id,
                    label: item?.name,
                  }))}
                selectedIdsArray={selectedIdsMembers ?? []}
                handleSubmit={(value) => {
                  setSelectedIdsMembers(value?.selectedIds);
                }}
                isLoading={isFetching}
                label="company.department.departmentDetails.tabs.people.addDepartmentMembers"
              />
            </div>
          ) : null}

          <PaginatedPeopleTable
            handleDelete={(id) => {
              setEditableMembers((prev) =>
                prev?.filter((item) => item.id !== id)
              );
            }}
            emptyLabel="company.department.departmentDetails.tabs.people.projectMemberemptyLabel"
            emptyDescription="company.department.departmentDetails.tabs.people.ProjectMemberemptyDescription"
            emptyBtnLabel="company.department.departmentDetails.tabs.people.emptyBtnDescription"
            people={inEditMode ? editableMembers : projectMembers}
            inEditMode={inEditMode}
            hideEmptyBtn={!projectMembers?.length === 0}
            handleEmptyBtnClick={handleManagePeopleBtnClick}
            limit={5}
          />
        </div>
      </div>
      {inEditMode ? (
        <div className="absolute bottom-0 flex flex-row justify-end gap-3 px-5 py-3 slider-footer mt-9">
          <Button
            label="Cancel"
            classes="w-[150px] text-neutral-500 text-sm"
            variant="tertiary"
            onClick={handleClose}
          />
          <Button
            label="Save changes"
            disabled={isMemberSame && isOwnersSame}
            classes="w-[150px] text-sm"
            onClick={handleInnerSave}
          />
        </div>
      ) : null}
    </>
  );
}

export default PeopleTabProj;

PeopleTabProj.propTypes = {
  projectOwners: PropTypes.array,
  projectMembers: PropTypes.array,
  handleSave: PropTypes.func,
  people: PropTypes.array,
  isFetching: PropTypes.bool,
  showManageMemberCta: PropTypes.bool,
};
