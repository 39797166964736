import PropTypes from "prop-types";

import Text from "@/components/core/Text";

export default function VendorInfoCell({ label, value }) {
  return (
    <div className="flex flex-col">
      <Text
        translationKey={label}
        classes="font-bold text-xs text-neutral-500"
      />
      {typeof value === typeof "" ? (
        <Text
          translationKey={value}
          classes="text-basee text-neutral-800 font-medium"
        />
      ) : (
        value
      )}
    </div>
  );
}

VendorInfoCell.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
};
