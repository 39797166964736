export const SORTING_TYPE = {
  // aka 'direction'
  INC: "asc",
  DEC: "desc",
};

export const SORTING_CATEGORY = {
  // aka 'column'
  AMOUNT: "amount",
  DATE: "date",
};

export const SORTING_CATEGORY_BILLPAY = {
  // aka 'column'
  CURRENT_AMOUNT: "current_spent",
  PREVIOUS_AMOUNT: "previous_spent",
  PAYMENT_DATE: "payment_date",
};

export const SORTING_CATEGORY_REPORT = {
  APPROVED_AMOUNT: "approved_amount",
  TOTAL_AMOUNT: "total_amount",
  SETTLED_REPORT: "settled_reports",
  UNSETTLED_REPORT: "unsettled_reports",
};

export const SORT_KEYS = {
  COLUMN: "sort_column",
  DIRECTION: "sort_direction",
};

export const SORTING_CATEGORY_REIMBURSEMENT = {
  // aka 'column'
  UNSETTLED_AMOUNT: "unsettled_amount",
  SETTLED_AMOUNT: "settled_amount",
  DATE: "date",
  UNSETTLED_CLAIM: "unsettled_claims",
  SETTLED_CLAIM: "settled_claims",
  UPDATED_AT: "updated_at",
  APPROVED_AT: "approved_at",
  SETTLED_AT: "settled_at",
};
