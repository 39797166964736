import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

// The secondary button (the button on the left, in the right portion of the footer)
export default function Status({
  iconName = "CheckCircle",
  iconClasses = "text-success-600",
  text = "Payment completed",
  textClasses = "font-base text-neutral-800 text-sm font-semibold",
}) {
  return (
    <div className="h-[48px] flex gap-2 items-center">
      <Icon name={iconName} className={iconClasses} />
      <Text translationKey={text} classes={textClasses} />
    </div>
  );
}

Status.propTypes = {
  iconName: PropTypes.string,
  iconClasses: PropTypes.string,
  text: PropTypes.string,
  textClasses: PropTypes.string,
};
