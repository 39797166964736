import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import { ROLE_TITLE } from "@/constants/user";

export default function CardHolder({
  id,
  displayName = "",
  index,
  role = "",
  avatarUrl,
  onIconClick,
  department,
  location,
  walletHolder,
  isTemporaryLimit,
  topUpRequests,
  temporaryLimitTooltip,
  topUpRequestTooltip,
  classes,
  showIconComponent = true,
}) {
  return (
    <div className="flex items-center">
      <ProfileWidget
        imgsrc={avatarUrl}
        className={`${
          !walletHolder ? "flex-row-reverse" : ""
        } justify-between ${classes}`}
        textClasses="text-sm font-semibold text-neutral-800"
        iconComponent={
          showIconComponent ? (
            !walletHolder ? (
              <div className="text-neutral-300">
                {/* <div
                  id={`cardHolder-${index}`}
                  className="flex items-center justify-center rounded w-7 h-7 text-neutral-300 hover:text-neutral-500 hover:bg-neutral-200"
                >
                  <Icon name="Person" handleClick={onIconClick} />
                </div> */}
                <Tooltip id={`cardHolder-${index}`} direction="bottom">
                  <Text translationKey="cards.listingFields.cardHolder.tooltip" />
                </Tooltip>
              </div>
            ) : (
              ""
            )
          ) : null
        }
        name={displayName}
      >
        <div className="flex gap-0.5 items-center text-xs font-medium text-neutral-500">
          {role[0] ? (
            <Text
              translationKey={role[0]?.name || role[0]}
              classes="whitespace-nowrap"
            />
          ) : null}

          <div className="flex items-center gap-0.5">
            {role[0] && (department || location) ? (
              <span className="left-0 profile-widget-dot text-neutral-500" />
            ) : null}

            {department ? (
              <Text
                translationKey={department}
                classes="whitespace-nowrap"
                showTooltip={department?.length > 12}
                tooltipClasses="w-12"
                id={`department-${index}`}
              />
            ) : null}
          </div>

          <div className="flex items-center gap-0.5">
            {department && location ? (
              <span className="profile-widget-dot text-neutral-500" />
            ) : null}
            {location ? (
              <Text
                translationKey={location}
                classes="whitespace-nowrap"
                showTooltip={location?.length > 12}
                tooltipClasses="w-12"
                id={`location-${index}`}
              />
            ) : null}
          </div>
        </div>
      </ProfileWidget>

      <div className="flex gap-2">
        {isTemporaryLimit ? (
          <>
            <div id="eventRepeat">
              <Icon
                name="EventRepeat"
                className="w-6 h-6 p-1 border rounded-lg text-neutral-400 bg-neutral-50 border-neutral-100"
              />
            </div>

            <Tooltip id="eventRepeat" direction="bottom">
              <Text translationKey="cards.eventRepeatTooltip" />
              <Text
                translationKey="cards.eventRepeatTooltipHelper"
                translationProps={temporaryLimitTooltip}
                classes="block text-xs text-neutral-400"
              />
            </Tooltip>
          </>
        ) : null}

        <Tooltip id={`card-schedule-tooltip-${id}`} direction="left">
          <Text
            translationKey="cards.scheduleTooltip"
            translationProps={topUpRequestTooltip}
          />
        </Tooltip>
      </div>
    </div>
  );
}

CardHolder.propTypes = {
  displayName: PropTypes.string,
  role: PropTypes.oneOf(Object.keys(ROLE_TITLE)),
  avatarUrl: PropTypes.string,
  onIconClick: PropTypes.func,
  index: PropTypes.string,
  department: PropTypes.string,
  location: PropTypes.string,
  walletHolder: PropTypes.bool,
  isTemporaryLimit: PropTypes.bool,
  topUpRequests: PropTypes.object,
  id: PropTypes.number,
  topUpRequestTooltip: PropTypes.object,
  temporaryLimitTooltip: PropTypes.object,
  classes: PropTypes.string,
  showIconComponent: PropTypes.bool,
};
