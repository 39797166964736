// utils, constant file imports
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";

// core components
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_SOFTWARES } from "@/constants/accounting";

export default function IntegrationChecklist({
  authenticated,
  creditAccountSelected,
  reimbursementAccountSelected,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const hideChecklist = [
    ACCOUNTING_SOFTWARES.NETSUITE,
    ACCOUNTING_SOFTWARES.UNIVERSAL_CSV,
    ACCOUNTING_SOFTWARES.TALLY,
  ].includes(accountingSoftware);
  const satisifiedChecklist =
    authenticated && creditAccountSelected && reimbursementAccountSelected;

  const handleDefaultAccount = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.defaultAccounts,
      true
    );
    setSearchParams(searchParams);
  };
  if (hideChecklist || satisifiedChecklist) return null;

  return (
    <div className="flex flex-col py-5  border border-neutral-200 rounded-lg">
      <div className="flex flex-col ">
        <div className="px-6 py-5">
          <Text
            translationKey="accounting.integrations.integrationChecklist.title"
            classes="text-xl text-neutral-800 font-semibold"
          />
        </div>
        <div className="w-full flex justify-between gap-6 border-b-2 pl-6 pr-9 border-neutral-200">
          <div className="h-full flex items-center pb-btn-lg gap-4 ">
            <Icon
              name="CheckCircle"
              className={`${
                authenticated ? "text-success-600" : "text-neutral-400"
              }`}
            />
            <Text
              translationKey="accounting.integrations.labels.authenticated"
              classes="text-sm font-semibold text-neutral-800"
            />
          </div>
          <div className="pb-4">
            <Icon name="Right" className="text-neutral-400" />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between pl-6 pr-9 border-b-2 border-neutral-200">
        <div className="flex items-center py-4 gap-4">
          <Icon
            name="CheckCircle"
            className={`${
              creditAccountSelected ? "text-success-600" : "text-neutral-400"
            }`}
          />
          <Text
            translationKey="accounting.integrations.labels.creditAccountSelected"
            classes="text-sm font-semibold text-neutral-800"
          />
        </div>
        <div className="py-4">
          <Icon
            name="Right"
            className="text-neutral-400 cursor-pointer"
            handleClick={() => handleDefaultAccount()}
          />
        </div>
      </div>

      <div className="flex items-center justify-between pl-6 pr-9">
        <div className="flex items-center  pt-4  gap-4 ">
          <Icon
            name="CheckCircle"
            className={`${
              reimbursementAccountSelected
                ? "text-success-600"
                : "text-neutral-400"
            }`}
          />
          <Text
            translationKey="accounting.integrations.labels.reimbursementAccountSelected"
            classes="text-sm font-semibold text-neutral-800"
          />
        </div>
        <div className="pt-4">
          <Icon
            name="Right"
            className="text-neutral-400 cursor-pointer"
            handleClick={() => handleDefaultAccount()}
          />
        </div>
      </div>
    </div>
  );
}

IntegrationChecklist.propTypes = {
  authenticated: PropTypes.bool,
  creditAccountSelected: PropTypes.bool,
  reimbursementAccountSelected: PropTypes.bool,
};
