import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

import Icon from "@/components/core/Icon";

PublicLayout.propTypes = {
  leftColumn: PropTypes.element,
};

export default function PublicLayout({ leftColumn }) {
  return (
    <div className="flex min-w-full min-h-screen">
      <div className="relative w-3/5">{leftColumn}</div>

      <div className="grow flex flex-col justify-center">
        <Outlet />
      </div>
    </div>
  );
}
