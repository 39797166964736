import { useState } from "react";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import Alerts from "@/components/Settings/Alerts";
import Developers from "@/components/Settings/Developers";
import SpendControls from "@/components/Settings/SpendControls";

import { GLOBAL_SETTINGS_TABS, TABS_IDS } from "@/constants/settings";

export default function GlobalSettings() {
  const tabs = GLOBAL_SETTINGS_TABS;
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <div className="flex flex-col px-11">
      <div className="flex flex-col">
        <Text translationKey="settings.manage" classes="text-neutral-500" />
        <Text
          translationKey="settings.companySettings"
          classes="text-neutral-800 text-3xl font-bold"
        />
        <div className="mt-6">
          <Tabs
            items={tabs}
            selectedTab={selectedTab.key}
            setCurrentTab={(tab) => setSelectedTab(tab)}
            // mode
          />
        </div>
      </div>
    </div>
  );
}
