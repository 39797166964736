import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import MissingCardLoader from "@/components/common/QrPayAndExpense/common/ExpenseLoaders/Loaders/MissingCard";

export default function FailedSyncCard({ count, isFetching, handleClick }) {
  return (
    <div
      className="flex items-center p-3 rounded-xl hover:shadow-md w-[190px] h-[76px] cursor-pointer border border-neutral-200"
      onClick={handleClick}
    >
      {!isFetching ? (
        <>
          <div className="flex items-center justify-center rounded-full bg-danger-50 w-9 h-9">
            <Icon name="SyncFailed" className="rounded-full text-danger-400" />
          </div>
          <div className="flex flex-col ml-4">
            <Text
              translationKey="accounting.transactions.failedToSync.title"
              classes="text-neutral-500 text-sm font-medium"
            />
            <span className="text-xl font-semibold text-neutral-800">
              {count}
            </span>
          </div>
        </>
      ) : (
        <MissingCardLoader />
      )}
    </div>
  );
}

FailedSyncCard.propTypes = {
  count: PropTypes.number,
  isFetching: PropTypes.bool,
  handleClick: PropTypes.func,
};
