import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import { sendFeedback } from "@/store/reducers/company";

import { quickGuideListSelector } from "@/store/selectors/quickGuide";

import Button from "@/components/core/Button";
import Memo from "@/components/core/Memo";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import { getQuickGuidePageInfoByKey } from "./utils";

export default function FeedbackSlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const quickGuide = useSelector(quickGuideListSelector);
  const quickGuideList = quickGuide?.guides;
  const locationObject = useLocation();
  const pagePathName = locationObject?.pathname;
  const quickGuidePageInfo = getQuickGuidePageInfoByKey(
    quickGuideList,
    pagePathName
  );
  const feedback = quickGuidePageInfo?.feedback;
  const radioOptions = feedback?.radioOptions;
  const FORM_KEYS = {
    SUB_TEXT: "sub_text",
    TEXT: "text",
  };

  const [isSubmitBtnDisabled, setDisableStatusForSubmitButton] = useState(true);

  const initialValues = {
    [FORM_KEYS.SUB_TEXT]: {
      value: "",
    },
    [FORM_KEYS.TEXT]: {
      value: "",
    },
  };

  const {
    handleChange: handleFormValuesChange,
    values,
    handleSubmit,
  } = useForm(initialValues, handleFormSubmit);

  function handleFormSubmit() {
    setDisableStatusForSubmitButton(true);
    dispatch(sendFeedback({ payload: values, onSuccess: handleCancel }));
  }

  function handleCancel() {
    setDisableStatusForSubmitButton(false);
    searchParams.delete(SLIDERS_SEARCH_PARAMS.quickGuide.feedbackSlider);
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (values?.[FORM_KEYS.TEXT] || values?.[FORM_KEYS.SUB_TEXT]) {
      setDisableStatusForSubmitButton(false);
    }
  }, [values]);

  return (
    <>
      <form id="feedback_slider" onSubmit={handleSubmit}>
        <div className="slider-content-core">
          <Text
            translationKey="myVolopay.gettingStarted.feedBackSlider.title"
            classes="text-2xl font-bold text-neutral-800"
          />

          <div className="flex flex-row items-center">
            <span className="text-sm font-medium text-neutral-500">
              <Text translationKey="myVolopay.gettingStarted.feedBackSlider.desc" />
              <span className="text-sm font-medium text-primary-500">
                <Text translationKey="myVolopay.gettingStarted.feedBackSlider.contactUs" />
              </span>
            </span>
          </div>

          <div>
            <div className="mt-10">
              <Text
                translationKey="myVolopay.gettingStarted.feedBackSlider.letUsKnow"
                classes="text-sm font-semibold text-neutral-800"
              />
            </div>

            {radioOptions?.map((radioOption, index) => {
              return (
                <Radio
                  name={FORM_KEYS.SUB_TEXT}
                  key={index}
                  value={radioOption?.value}
                  label={radioOption?.key}
                  isChecked={
                    values?.[FORM_KEYS.SUB_TEXT] === radioOption?.value
                  }
                  textClases="text-base font-semibold text-neutral-800 my-3"
                  handleChange={handleFormValuesChange}
                  insideForm
                />
              );
            })}

            <div className="mt-10">
              <Text
                translationKey="myVolopay.gettingStarted.feedBackSlider.additionalComments"
                classes="text-sm font-semibold text-neutral-800"
              />

              <Memo
                name={FORM_KEYS.TEXT}
                placeholderLabel="myVolopay.gettingStarted.feedBackSlider.addSuggestion"
                placeholderMemoDescription="What would you like us to know?"
                onChange={handleFormValuesChange}
                insideForm
              />
            </div>
          </div>
        </div>
      </form>

      <div className="px-3 py-5 slider-footer">
        <div className="flex items-center justify-end gap-3">
          <Button
            label="cards.pCards.sliders.createCardSlider.buttons.cancel"
            classes="w-16 text-neutral-500 font-medium"
            variant="tertiary"
            onClick={handleCancel}
          />

          <Button
            label="myVolopay.gettingStarted.feedBackSlider.submitFeedback"
            classes="w-16 text-white font-medium"
            variant="primary"
            btnType="submit"
            form="feedback_slider"
            disabled={isSubmitBtnDisabled}
          />
        </div>
      </div>
    </>
  );
}
