import React from "react";

import PaymentsApprovalsPageHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Approvals/PaymentsApprovalPageHelper";

import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";

export default function ActionCentreBillPayTab() {
  return (
    <PaymentsApprovalsPageHelper
      tabType="needs-your-approval"
      page={ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY}
    />
  );
}
