import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { forgotPassword } from "@/store/reducers/user";

import {
  accountLockedTypeSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import { ROUTES } from "@/constants/routes";

import MainPageHeader from "./MainPageHeader";

const ACCOUNT_LOCKED_TYPE = {
  ACCOUNT_LOCKED: "ACCOUNT_LOCKED",
  ACCOUNT_BLOCKED: "ACCOUNT_BLOCKED",
  INACTIVITY_LOCKED: "INACTIVITY_LOCKED",
  ADMIN_FREEZED: "ADMIN_FREEZED",
  ADMIN_UNFREEZED: "ADMIN_UNFREEZED",
  OTP_RETRY_COUNT_EXHAUSTED: "OTP_RETRY_COUNT_EXHAUSTED",
  COMPANY_ACCOUNT_LOCKED: "COMPANY_ACCOUNT_LOCKED",
};
const ACCOUNT_LOCKED_CONFIG = {
  [ACCOUNT_LOCKED_TYPE.ACCOUNT_LOCKED]: {
    desc: "accountLockDesc.lock",
  },
  [ACCOUNT_LOCKED_TYPE.ACCOUNT_BLOCKED]: {
    desc: "accountLockDesc.block",
  },
  [ACCOUNT_LOCKED_TYPE.ADMIN_FREEZED]: {
    desc: "accountLockDesc.adminFreezed",
  },
  [ACCOUNT_LOCKED_TYPE.ADMIN_UNFREEZED]: {
    desc: "accountLockDesc.adminUnfreezed",
  },
  [ACCOUNT_LOCKED_TYPE.INACTIVITY_LOCKED]: {
    desc: "accountLockDesc.inactivityLocked",
  },
  [ACCOUNT_LOCKED_TYPE.OTP_RETRY_COUNT_EXHAUSTED]: {
    desc: "accountLockDesc.otpRetryCountExhausted",
  },
  [ACCOUNT_LOCKED_TYPE.COMPANY_ACCOUNT_LOCKED]: {
    desc: "accountLockDesc.companyLocked",
  },
};

export default function AccountLocked() {
  const accountLockedSelected = useSelector(accountLockedTypeSelector);
  const navigate = useNavigate();
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();

  const isFreezedOrBlocked = [
    ACCOUNT_LOCKED_TYPE.ADMIN_FREEZED,
    ACCOUNT_LOCKED_TYPE.ACCOUNT_BLOCKED,
    ACCOUNT_LOCKED_TYPE.COMPANY_ACCOUNT_LOCKED,
  ].includes(accountLockedSelected);

  const handleResetClick = () => {
    dispatch(
      forgotPassword({
        data: {
          email: currentUser?.email,
          redirect_url: "test",
        },
        navigate,
      })
    );
  };
  const ACCOUNT_LOCKED_LABELS = {
    [ACCOUNT_LOCKED_TYPE.ADMIN_FREEZED]: {
      label: "login.accountFrozen",
    },
    [ACCOUNT_LOCKED_TYPE.ACCOUNT_BLOCKED]: {
      label: "login.accountBlockedByAdmin",
    },
    [ACCOUNT_LOCKED_TYPE.INACTIVITY_LOCKED]: {
      label: "login.inactivityHeading",
    },
    [ACCOUNT_LOCKED_TYPE.ACCOUNT_LOCKED]: {
      label: "login.unsuccessfulLogin",
    },
    [ACCOUNT_LOCKED_TYPE.OTP_RETRY_COUNT_EXHAUSTED]: {
      label: "login.otpExhausted",
    },
    [ACCOUNT_LOCKED_TYPE.ADMIN_UNFREEZED]: {
      label: "login.passwordExpired",
    },
  };

  const accountFreezed =
    ACCOUNT_LOCKED_TYPE.ADMIN_FREEZED === accountLockedSelected;

  useEffect(() => {
    // If email not there then redirect to login page
    if (!currentUser?.email) navigate(ROUTES.login.base.absolutePath);
  }, []);
  return (
    <div>
      <MainPageHeader
        title={
          ACCOUNT_LOCKED_LABELS[accountLockedSelected]?.label ??
          "accountLockDesc.accountLocked"
        }
        showBack
        handleBack={() => {
          navigate("/");
        }}
      />

      <div>
        <div className="mb-10 text-sm font-medium text-neutral-500">
          {[
            ACCOUNT_LOCKED_TYPE.INACTIVITY_LOCKED,
            ACCOUNT_LOCKED_TYPE.ADMIN_FREEZED,
            ACCOUNT_LOCKED_TYPE.ACCOUNT_LOCKED,
            ACCOUNT_LOCKED_TYPE.OTP_RETRY_COUNT_EXHAUSTED,
            ACCOUNT_LOCKED_TYPE.ADMIN_UNFREEZED,
          ]?.includes(accountLockedSelected) ? (
            <>
              <Text translationKey="accountLockDesc.yourAccountHasBeen" />
              <Text
                translationKey={
                  accountFreezed
                    ? "accountLockDesc.temporarilyFrozen"
                    : "accountLockDesc.temporarilyLocked"
                }
                classes={accountFreezed ? "" : "text-neutral-800"}
              />
            </>
          ) : null}
          <Text
            classes="text-neutral-500"
            translationKey={ACCOUNT_LOCKED_CONFIG[accountLockedSelected]?.desc}
          />

          {[
            ACCOUNT_LOCKED_TYPE.ACCOUNT_BLOCKED,
            ACCOUNT_LOCKED_TYPE.ADMIN_FREEZED,
            ACCOUNT_LOCKED_TYPE.COMPANY_ACCOUNT_LOCKED,
          ]?.includes(accountLockedSelected) ? (
            <Text
              translationKey="accountLockDesc.contactYourAdmin"
              classes="text-neutral-800"
            />
          ) : null}
        </div>

        {!isFreezedOrBlocked ? (
          <Button
            label="login.resetPasswordToUnlockAccount"
            onClick={handleResetClick}
            classes="w-full"
          />
        ) : null}
      </div>
    </div>
  );
}
