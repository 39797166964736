import PropTypes from "prop-types";

import Radio from "@/components/core/Radio";

export default function FieldTypeCard({
  name = "Option list",
  desc = "Use this field to select an option from available options",
  selected,
  handleValueChange,
}) {
  return (
    <div className="flex items-center border border-neutral-300 px-4 py-2 w-[296px] h-[106px] rounded-lg">
      <div className="mr-2">
        <Radio isChecked={selected} handleChange={handleValueChange} />
      </div>
      <div className="flex flex-col">
        <span className="font-medium">{name}</span>
        <span className="text-sm text-neutral-400">{desc}</span>
      </div>
    </div>
  );
}

FieldTypeCard.propTypes = {
  name: PropTypes.string,
  desc: PropTypes.string,
  selected: PropTypes.bool,
  handleValueChange: PropTypes.func,
};
