import { ROUTES } from "@/constants/routes";

const CURRENCY_TABLE_HEADER = [
  { name: "accounting.ledger.tableHeader.date", haveSort: false, class: null },
  {
    name: "accounting.ledger.tableHeader.ledgerType",
    haveSort: false,
    class: null,
  },
  {
    name: "accounting.ledger.tableHeader.amount",
    haveSort: true,
    class: "justify-end",
  },
  { name: "accounting.ledger.tableHeader.type", haveSort: false, class: null },
  {
    name: "accounting.ledger.tableHeader.closingBalance",
    haveSort: false,
    class: "text-right",
  },
  {
    name: "accounting.ledger.tableHeader.quickbooksSyncStatus",
    haveSort: false,
    class: null,
  },
  { name: "accounting.ledger.tableHeader.user", haveSort: false, class: null },
  {
    name: "accounting.ledger.tableHeader.description",
    haveSort: false,
    class: null,
  },
  {
    name: "accounting.ledger.tableHeader.account",
    haveSort: false,
    class: null,
  },
];
export const TABLE_HEADER_LEDGER = {
  credit: [
    {
      name: "accounting.ledger.tableHeader.date",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.ledgerType",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.amount",
      haveSort: true,
      class: "justify-end",
    },
    {
      name: "accounting.ledger.tableHeader.type",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.currentOutStanding",
      haveSort: false,
      class: "text-right",
    },
    {
      name: "accounting.ledger.tableHeader.quickbooksSyncStatus",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.user",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.description",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.account",
      haveSort: false,
      class: null,
    },
  ],
  sgd: CURRENCY_TABLE_HEADER,
  usd: CURRENCY_TABLE_HEADER,
  all: [
    {
      name: "accounting.ledger.tableHeader.date",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.ledgerType",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.amount",
      haveSort: true,
      class: "justify-end",
    },
    {
      name: "accounting.ledger.tableHeader.type",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.outstandingAndBalance",
      haveSort: false,
      class: "text-right",
    },
    {
      name: "accounting.ledger.tableHeader.quickbooksSyncStatus",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.user",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.description",
      haveSort: false,
      class: null,
    },
    {
      name: "accounting.ledger.tableHeader.account",
      haveSort: false,
      class: null,
    },
  ],
};

export const SYNC_STATUS_LEDGER = {
  synced: {
    text: "Synced",
    color: "success",
    icon: "Sync",
  },
  notSynced: {
    text: "Not Synced",
    color: "neutral",
    icon: null,
  },
  syncFailed: {
    text: "Sync failed",
    color: "danger",
    icon: null,
  },
  syncProgress: {
    text: "Retrying sync",
    color: "warning",
    icon: null,
  },
};
export const LEDGER_PAGE_TAB = [
  {
    name: "accounting.ledger.childPage.credit",
    count: null,
    path: ROUTES.accounting.ledger.credit.absolutePath,
    key: 1,
  },
  {
    name: "accounting.ledger.childPage.sgd",
    count: null,
    path: ROUTES.accounting.ledger.sgd.absolutePath,
    key: 2,
  },
  {
    name: "accounting.ledger.childPage.usd",
    count: null,
    path: ROUTES.accounting.ledger.usd.absolutePath,
    key: 3,
  },
  {
    name: "accounting.ledger.childPage.all",
    count: null,
    path: ROUTES.accounting.ledger.all.absolutePath,
    key: 4,
  },
];

export const LEDGER_PAGE_TYPE = {
  all: "all",
  usd: "usd",
  sgd: "sgd",
  credit: "credit",
  wallet: "wallet",
  payroll: "payroll",
};
export const LEDGER_PAGE_SLIDER_TYPE = {
  all: "all",
  usd: "usd",
  sgd: "sgd",
  credit: "credit",
};
export const DEFAULT_LEDGER_PAGE_TYPE = "All accounts";

export const LEDGER_PAGE_TYPE_CONFIG = {
  [LEDGER_PAGE_SLIDER_TYPE.all]: {
    label: "All account",
  },
  [LEDGER_PAGE_SLIDER_TYPE.credit]: {
    label: "SGD",
  },
  [LEDGER_PAGE_SLIDER_TYPE.usd]: {
    label: "USD",
  },
  [LEDGER_PAGE_SLIDER_TYPE.sgd]: {
    label: "Credit",
  },
};

export const LEDGER_CREDIT_VALUE = {
  credit: "credit_transfer",
};

export const LEDGER_CURRENCY_TYPE = {
  SGD: "SGD",
  USD: "USD",
};

export const LEDGER_TRANSACTION_TYPE = {
  credit: "credit",
  debit: "debit",
  expense: "expense",
};

export const TABLE_HEADER = [
  { name: "accounting.ledger.tableHeader.date", haveSort: false, class: null },
  {
    name: "accounting.ledger.tableHeader.accountingStatus",
    haveSort: false,
    class: "text-center",
  },
  {
    name: "accounting.ledger.tableHeader.amount",
    haveSort: true,
    class: "justify-end",
  },
  { name: "accounting.ledger.tableHeader.user", haveSort: false, class: null },
  {
    name: "accounting.ledger.tableHeader.description",
    haveSort: false,
    class: null,
  },
];

export const LEDGER_ACCOUNTING_STATUS = {
  pending: "pending",
  verified: "verified",
};

export const LEDGER_ACCOUNTING_SLIDER_STATUS = {
  verified: "accounting.ledger.unsyncTransaction.verified",
  notVerified: "accounting.ledger.unsyncTransaction.notVerified",
};

export const EXPORT_FORMATS = [
  {
    id: 1,
    label: "CSV",
    value: "csv",
  },
  {
    id: 2,
    label: "XLSX",
    value: "xlsx",
  },
  {
    id: 3,
    label: "PDF",
    value: "pdf",
  },
];

export const EXPORT_MODES = {
  download: "download",
  email: "email",
};

export const UNSYNCED = "unsynced";

export const LEDGER_STATUSES = {
  notSynced: "notSynced",
  syncFailed: "syncFailed",
  synced: "synced",
  syncProgress: "syncProgress",
};

export const LEDGER_TABLE_HEADERS = {
  ledgerDate: "ledger_date",
  ledgerType: "ledger_type",
  ledgerTypeCamelCase: "ledgerType",
  amount: "amount",
  ledgerAmount: "ledgerAmount",
  currentOutstanding: "currentOutstanding",
  type: "type",
  balance: "balance",
  outstanding: "outstanding",
  description: "description",
  createdAt: "createdAt",
};

export const CONSTANT_LEDGER_HEADER_IDS = [
  LEDGER_TABLE_HEADERS.ledgerDate,
  LEDGER_TABLE_HEADERS.ledgerType,
  LEDGER_TABLE_HEADERS.amount,
];

export const EXPORT_MODES_TOAST = {
  [EXPORT_MODES.download]: {
    title: "toast.information",
    variant: "warning",
    message: "accounting.ledger.toasterDescription.download",
  },
  [EXPORT_MODES.email]: {
    title: "toast.success",
    variant: "success",
    message: "accounting.ledger.toasterDescription.email",
  },
};

export const ACCOUNT_WALLET_TYPES = {
  creditAccount: "credit_account",
  paymentAccount: "payments",
  payrollAccount: "payroll",
  cardsAccount: "cards",
  internationalAccount: "global_cards",
};

export const ALL_ACCOUNTS_TYPES_LIST = [
  ACCOUNT_WALLET_TYPES.paymentAccount,
  ACCOUNT_WALLET_TYPES.payrollAccount,
];

export const REQUEST_STATEMENT_PARAM = {
  accountId: "accountId",
};

export const LEDGER_TYPE_FILTERS = {
  expense: "expense",
  payment: "payment",
  creditPrefunding: "credit_prefunding",
  invoicePayment: "invoice_payment",
  creditBillPayment: "credit_bill_payment",
  adjustment: "adjustment",
  cashback: "cashback",
  payrollFundAllocation: "payroll_fund_allocation",
};
