import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import VpLink from "@/components/core/vp-link";

export default function BulkApproveComponent({
  selectedRows,
  handleApprove,
  isApprovalInProgress,
  selectTotalExpense,
  totalApprove,
  handleTotalExpenseSelection,
  selectAllVisible = true,
}) {
  return (
    <div className="flex items-center justify-between w-full px-6 bg-white">
      <div className="flex flex-col">
        <Text
          color="neutral-800"
          classes="font-semibold"
          translationKey="expenses.needsReview.bulkApprove"
          translationProps={{ selectedRows }}
        />
        <Text
          color="neutral-500"
          classes="font-normal text-sm"
          translationKey="expenses.needsReview.bulkApproveDescription"
        />
        {selectAllVisible ? (
          !selectTotalExpense ? (
            <VpLink
              textClasses="text-sm  first-letter:uppercase"
              text="expenses.needsReview.selectAll"
              textTranslationProp={{ total: totalApprove }}
              onClickHandler={() => handleTotalExpenseSelection(true)}
            />
          ) : (
            <VpLink
              textClasses="text-sm first-letter:uppercase"
              text="expenses.needsReview.clearAll"
              onClickHandler={() => handleTotalExpenseSelection(false)}
            />
          )
        ) : null}
      </div>

      <Button
        classes="w-15.5 h-10"
        type="success"
        preIcon="Verify"
        showLoader={isApprovalInProgress}
        onClick={handleApprove}
        label="misc.approve"
      />
    </div>
  );
}
BulkApproveComponent.propTypes = {
  selectedRows: PropTypes.number,
  totalApprove: PropTypes.number,
  handleApprove: PropTypes.func,
  handleTotalExpenseSelection: PropTypes.func,
  isApprovalInProgress: PropTypes.bool,
  selectTotalExpense: PropTypes.bool,
  selectAllVisible: PropTypes.bool,
};
