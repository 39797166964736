import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchRoles } from "@/store/reducers/company";

import {
  isFetchingRolesSelector,
  rolesSelector,
} from "@/store/selectors/company";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Loader from "@/components/core/Loader";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import { checkIfUserActionAllowed, groupByKey } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  PAYMENT_INITIATOR_ROLES,
  ROLES_MODULE_CONFIG,
  ROLE_TITLE,
  ROLE_TYPE,
  ROLE_TYPE_TITLE,
} from "@/constants/company";
import { USER_ALLOWED_CTA_KEYS } from "@/constants/user";

export default function RolesPermissionsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const roles = useSelector(rolesSelector);

  const modifiedRoles = roles.map((role) => {
    if (role.roleType === ROLE_TYPE.INTERNAL) {
      return { ...role, roleType: ROLE_TYPE.DEFAULT };
    }
    return role;
  });

  const groupedRoles = groupByKey(
    modifiedRoles.filter(
      (role) =>
        role?.roleType !== ROLE_TYPE_TITLE.PAYROLL &&
        role?.name !== ROLE_TITLE.CLAIM_MANAGER
    ),
    "roleType"
  );

  const isFetching = useSelector(isFetchingRolesSelector);

  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  const getRandomColorForCustomRole = () => {
    const colors = [
      {
        bgColor: "bg-expense-management-50",
        textColor: "text-expense-management-700",
      },
      {
        bgColor: "bg-accounts-payable-50",
        textColor: "text-accounts-payable-700",
      },
      {
        bgColor: "bg-business-accounts-50",
        textColor: "text-business-accounts-700",
      },
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  useEffect(() => {
    dispatch(fetchRoles({ with_internal: true }));
  }, []);

  const handleViewRole = (roleID, type, isAssistantRole) => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.company.rolesPermissions.role,
      roleID
    );
    if (isAssistantRole) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.rolesPermissions.assistant,
        true
      );
    }
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col justify-center pb-10 pl-11 pr-11.5 gap-9">
      <div className="flex items-center justify-between ">
        <div className="flex flex-col">
          <Text
            translationKey="company.title"
            classes="text-neutral-500 text-sm font-semibold"
          />
          <Text
            translationKey="company.rolesPermissions.title"
            classes="text-3xl font-bold text-neutral-800"
          />
        </div>
        {checkIfUserActionAllowed(
          allNavUrls,
          userAllowedActions,
          USER_ALLOWED_CTA_KEYS.ADD_CUSTOM_ROLE
        ) ? (
          <div className="flex items-end">
            <Button
              variant="primary"
              label="company.rolesPermissions.buttonLabels.addCustomRole"
              preIcon="Plus"
              classes="w-fit px-5 py-4 text-btn-lg font-semibold"
              onClick={() => {
                searchParams.append(
                  SLIDERS_SEARCH_PARAMS.company.rolesPermissions.addCustomRole,
                  true
                );
                setSearchParams(searchParams);
              }}
            />
          </div>
        ) : null}
      </div>

      <div className="flex flex-col gap-12">
        {!isFetching ? (
          Object.entries(groupedRoles)?.map(([key, value]) => (
            <div
              className="flex flex-col justify-center gap-4"
              key={`${key}-roles-and-permissions`}
            >
              <Text
                translationKey="company.rolesPermissions.roles.title"
                translationProps={{ name: key === "default" ? "volopay" : key }}
                classes="text-xl font-semibold text-neutral-800 lowercase first-letter:uppercase"
              />
              <div className="flex flex-wrap items-center gap-6">
                {value.map((item, index) => {
                  const customRoleTheme =
                    key === ROLE_TYPE_TITLE.CUSTOM
                      ? getRandomColorForCustomRole()
                      : null;
                  return (
                    <div
                      className="flex flex-col justify-center gap-2 p-6 border rounded-lg cursor-pointer w-120 max-h-min border-neutral-200"
                      key={`${key}-role-${index}`}
                      onClick={() => {
                        handleViewRole(item.id, key, item?.assistantRole);
                      }}
                    >
                      <div
                        className={`w-fit rounded-full ${
                          key === "custom"
                            ? customRoleTheme.bgColor
                            : "bg-primary-50"
                        } ${
                          item?.assistantRole ? "" : "p-3"
                        } border border-neutral-100`}
                      >
                        <Icon
                          name={item?.assistantRole ? "VolopayLogo" : "Admin"}
                          className={` ${
                            key === "custom"
                              ? customRoleTheme.textColor
                              : item?.assistantRole
                                ? "bg-primary-500 text-white rounded-full w-9 h-9 p-1.25"
                                : "text-primary-500"
                          }`}
                        />
                      </div>
                      <div className="flex flex-col justify-center">
                        <div className="flex items-center gap-2">
                          <Text
                            translationKey={
                              item.name ===
                              PAYMENT_INITIATOR_ROLES.SETTLEMENT_INITIATOR
                                ? PAYMENT_INITIATOR_ROLES.PAYMENT_INITIATOR
                                : item.name
                            }
                            classes="text-lg font-semibold text-neutral-800 first-letter:capitalize"
                          />
                          <div className="flex items-center">
                            <Icon
                              name="ToLink"
                              className="w-4 h-4 cursor-pointer text-primary-500 text-bold"
                              handleClick={() => handleViewRole(item.id, key)}
                            />
                          </div>
                        </div>
                        {!item?.assistantRole ? (
                          <div className="flex flex-row items-center gap-1">
                            {key === ROLE_TYPE_TITLE.DEFAULT ? (
                              <div className="flex flex-row items-center gap-2">
                                <Text
                                  translationKey={
                                    ROLES_MODULE_CONFIG[item.name]
                                  }
                                  classes="text-sm font-medium text-neutral-500 lowercase first-letter:capitalize"
                                />
                                <div className="flex flex-row items-end">
                                  <Icon
                                    name="Dot"
                                    className="w-1 h-1 text-neutral-500"
                                  />
                                </div>
                              </div>
                            ) : null}

                            <Text
                              translationKey="company.rolesPermissions.roles.people"
                              translationProps={{ count: item.totalPeople }}
                              classes="text-sm font-medium text-neutral-500"
                            />
                          </div>
                        ) : (
                          <Text
                            translationKey="company.rolesPermissions.assistant.description"
                            classes="text-sm font-medium text-neutral-500"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        ) : (
          <div className="px-5">
            <LoaderSkeleton size={[100, 50]} count={10} fullWidth />
          </div>
        )}
      </div>
    </div>
  );
}
