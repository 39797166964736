import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { useSelector } from "react-redux";

import { urlMapSelector } from "@/store/selectors/loadersError";

import VPErrors from "@/components/core/VPError";
import VPLoaders from "@/components/core/VPLoader";

const hideChildren = (element) => {
  const { childNodes } = element;
  const arrayChildNode = Array.from(childNodes).filter(
    (child) => !child.classList.value.includes("error")
  );
  arrayChildNode.forEach((childNode) => {
    childNode.style.visibility = "hidden";
  });
};
const unhideChildren = (element) => {
  const { childNodes } = element;
  const arrayChildNode = Array.from(childNodes).filter(
    (child) => !child.classList.value.includes("error")
  );
  arrayChildNode.forEach((childNode) => {
    childNode.style.visibility = "";
  });
};
const removeUIOfLoaderError = (
  ele,
  oldPosition,
  oldEle,
  message,
  messageRoot
) => {
  ele.style.position = oldPosition;
  unhideChildren(oldEle);
  messageRoot.unmount();
  ele.removeChild(message);
};
const useLoaderError = ({
  error: initialError,
  loader: initialLoader,
  errorObject: inititalErrorObject,
  showLoader,
  showErrorOnSuccess,
}) => {
  const [error, setError] = useState(initialError);
  const [errorObject, setErrorObject] = useState(inititalErrorObject);
  const [loader, setLoader] = useState(initialLoader);
  const _ref = useRef(null);

  useEffect(() => {
    const ele = _ref.current;
    if (ele) {
      const oldEle = _ref.current;
      const oldPosition = ele.style.position;

      const message = document.createElement("div"); // Create a container element
      const messageRoot = ReactDOM.createRoot(message);

      if (error || loader || showErrorOnSuccess) {
        if (ele) {
          message.classList.add("error");
          message.style.position = "absolute";
          message.style.top = "0";
          message.style.left = "0";
          message.style.width = "100%";
          message.style.height = "100%";
          if (loader)
            // Render the Loaders component into the container element
            messageRoot.render(<VPLoaders element={oldEle} />);
          else if (error || showErrorOnSuccess)
            messageRoot.render(
              <VPErrors
                showErrorOnSuccess={showErrorOnSuccess}
                errorObject={errorObject}
                element={oldEle}
              />
            );
          hideChildren(oldEle);
          ele.style.position = "relative";
          if (loader && showLoader) ele.appendChild(message);
          else if (error || showErrorOnSuccess) ele.appendChild(message);
          // Cleanup function
          return () => {
            removeUIOfLoaderError(
              ele,
              oldPosition,
              oldEle,
              message,
              messageRoot
            );
          };
        }
      } else if (ele.contains(message)) {
        removeUIOfLoaderError(ele, oldPosition, oldEle, message, messageRoot);
      }
    }
  }, [error, loader]);

  useEffect(() => {
    setError(initialError);
    setLoader(initialLoader);
    setErrorObject(inititalErrorObject);
  }, [
    JSON.stringify(initialError),
    JSON.stringify(initialLoader),
    JSON.stringify(inititalErrorObject),
  ]);
  const attach = (ref) => {
    if (ref) {
      _ref.current = ref;
    }
  };

  return { attach, loader, error };
};

/**
 * @description This hook is used to inject error and loader states into the component tree.
 * @param {Object} apiMapper - An object or array of objects representing API mappers.
 * @returns {Object} - An array of objects containing error, loader, and errorObject states.
 * @link https://github.com/Volopay/vp-fly/wiki/How-to-use-useLoaderErrorInjector
 * @example  const allState = useLoadingErrorInjector({
    apiKey: "Expenses:getMissingDetailsCount",
    showLoader: true,
    error: {
      header: "",
      //.... other params want to send to Error component
    },
  });
 */
const useLoadingErrorInjector = (apiMapper) => {
  let stateArray = apiMapper;
  const apiUrlErrorLoaderMap = useSelector(urlMapSelector);
  const newConfigObject = apiUrlErrorLoaderMap[apiMapper?.apiKey];

  const apiMapperObject = apiMapper || {};
  const showLoader = apiMapperObject?.showLoader
    ? apiMapperObject?.showLoader
    : true;

  const showErrorOnSuccess = apiMapperObject?.showErrorOnSuccess
    ? apiMapperObject?.showErrorOnSuccess
    : false;

  stateArray = useLoaderError({
    error: newConfigObject?.isError,
    loader: showLoader ? newConfigObject?.isFetching : false,
    showLoader,
    showErrorOnSuccess,
    errorObject: {
      ...(newConfigObject?.error || {}),
      ...(apiMapperObject?.error || {}),
    },
  });

  return stateArray;
};

export default useLoadingErrorInjector;
