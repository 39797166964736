import PropTypes from "prop-types";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";
import { amountToCurrency } from "@/utils/common";

export default function TextWithTooltipAndAmount({
  text,
  tooltipText,
  tooltipTextProps,
  currency = "",
  amount,

  amountStyleClasses = "text-neutral-800 text-base font-bold",
  currencyStyleClasses = "text-neutral-500 text-base font-bold",

  children,

  isTax = false,
  isSubtractive = false,
  classes = "px-4",
}) {
  return (
    <div className={`flex items-center justify-between ${classes}`}>
      {/* left side */}
      <div>
        <TextWithTooltipIcon
          text={text}
          tooltipText={tooltipText}
          tooltipTextProps={tooltipTextProps}
        />
        <div>{children}</div>
      </div>
      {/* right side */}
      <div>
        {isTax ? (
          <span
            className={`text-base font-bold shrink-0 ${
              amount >= 0 && !isSubtractive
                ? "text-success-600"
                : "text-danger-500"
            }`}
          >
            {amount > 0 && !isSubtractive
              ? "+"
              : amount < 0 || isSubtractive
                ? "-"
                : null}{" "}
            {amountToCurrency(Math.abs(+amount), currency)}
          </span>
        ) : (
          <>
            <span className={amountStyleClasses}>{amount}</span>{" "}
            <span className={currencyStyleClasses}>
              {currency || amount.currency}
            </span>
          </>
        )}
      </div>
    </div>
  );
}

TextWithTooltipAndAmount.propTypes = {
  text: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTextProps: PropTypes.object,
  currency: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  amountStyleClasses: PropTypes.string,
  currencyStyleClasses: PropTypes.string,

  children: PropTypes.any,
  classes: PropTypes.string,
};
