import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import Note from "@/components/core/Note";
import { dateToString, positionifyNumber } from "@/utils/common";
import { PAYMENT_SCROLL_TO_KEYS } from "@/utils/constants/payments";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { SPEND_FREQUENCY_LABELS } from "@/constants/company";

/**
 * Edit recurring payment
 *
 * Information (purple) box with a clickable (Opens modal on click)
 *
 * {@link https://www.figma.com/file/nYb1B3o06dNJPuFhW7fLWP/v2-Payroll?type=design&node-id=5553-267052&mode=design&t=hKuie5YBqxOOIRs1-4}
 * context payroll is identical
 */
export default function EditRecurringPaymentBox({
  payment,
  context,
  onPaymentOrPaidPage,
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

  const recurringPayment = payment?.recurringPayment;
  // frequency, getting label
  const recurringFrequency = t(
    SPEND_FREQUENCY_LABELS[recurringPayment?.frequency]
  )?.toLocaleLowerCase();

  // recurring cycle end date (formatting)
  const isCycleEnds = recurringPayment?.endDate;
  const endDateFormattedText = () => {
    if (!isCycleEnds) return "";

    const endDateAsText = dateToString(
      recurringPayment?.endDate || new Date(),
      {
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    ); // 25 May 2023
    const [dayText, monthText, yearText] = endDateAsText.split(" "); // TECH_DEBT: add positionifyNumber to date util
    return `${monthText} ${positionifyNumber(+dayText)}, ${yearText}`; // August 5th, 2023
  };

  const editRecurringPaymentHandler = () => {
    // open modal, flow continues from there
    const draftId = payment?.owner?.id || payment?.id; // inbox, approval objects have direct id. payment, paid store draft id in `owner`.
    searchParams.delete(SLIDERS_SEARCH_PARAMS.scrollTo);
    searchParams.append(
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payrollPayments.editBill
        : SLIDERS_SEARCH_PARAMS.payments.editBill,
      draftId
    );

    searchParams.append(
      SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey,
      SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments
    );

    searchParams.append(
      SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider,
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payrollPayments.editBill
        : SLIDERS_SEARCH_PARAMS.payments.editBill
    );

    searchParams.append(
      SLIDERS_SEARCH_PARAMS.scrollTo,
      PAYMENT_SCROLL_TO_KEYS.EDIT_RECURRING
    );

    setSearchParams(searchParams);
  };

  return (
    <Note
      titleText="billPay.bill.common.editRecurringPayment.title"
      descriptionText={t(
        isCycleEnds
          ? "billPay.bill.common.editRecurringPayment.descriptionCycleEnds"
          : "billPay.bill.common.editRecurringPayment.descriptionCycleNoEnd",
        {
          currentPaymentPosition: positionifyNumber(recurringPayment?.position),
          frequency: recurringFrequency,
          recurringCycleEndDate: isCycleEnds ? endDateFormattedText() : null,
        }
      )}
      actionText={
        onPaymentOrPaidPage || !payment?.recurringPayment?.ctas?.length
          ? ""
          : "billPay.bill.common.editRecurringPayment.actionLabel"
      }
      onActionClickHandler={editRecurringPaymentHandler}
      iconName=""
      iconColorClass="text-neutral-500"
      actionTextColorClass="text-primary-500"
      backgroundColorClass="bg-primary-50"
      borderColorClass="border-primary-300"
    />
  );
}

EditRecurringPaymentBox.propTypes = {
  payment: PropTypes.object,
  context: PropTypes.string,
  onPaymentOrPaidPage: PropTypes.bool,
};
