import PropTypes from "prop-types";

import Button from "@/components/core/Button";

/**
 * Footer used by CheckboxDropdown, meant for internal use (by the core component) only
 *
 * @param {?Function} onClear // pass `null` to hide the button
 * @param {?Function} onConfirm // pass `null` to hide the button
 */
export default function CheckboxDropdownFooter({
  onClear = () => {},
  onConfirm = () => {},
  disabledSubmit,
  disabledClear,
}) {
  return (
    <div
      aria-label="checkbox-dropdown-footer"
      className="flex items-center justify-between px-3 py-4 font-medium border-t border-neutral-200"
    >
      {onClear ? (
        <Button
          onClick={onClear}
          disabled={disabledClear}
          variant="tertiary"
          label="misc.clearAll"
          className="h-6 px-1 rounded-sm text-neutral-500 hover:bg-neutral-100"
        />
      ) : null}
      {onConfirm ? (
        <Button
          onClick={onConfirm}
          disabled={disabledSubmit}
          variant="tertiary"
          label="misc.done"
          className="h-6 px-1 rounded-sm text-primary-500 hover:bg-neutral-100"
        />
      ) : null}
    </div>
  );
}

CheckboxDropdownFooter.propTypes = {
  onClear: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onConfirm: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  disabledSubmit: PropTypes.bool,
  disabledClear: PropTypes.bool,
};
