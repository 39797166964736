import PropTypes from "prop-types";
import { useEffect, useId, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { getTooltipFriendlyId, isEllipsisActive } from "@/utils/common";

import LoaderSkeleton from "../LoaderSkeleton";
import "./styles.scss";

/**
 * Input component for handling various input types.
 * @param {object} props - Component props.
 * @param {string} [props.type="text"] - Input type.
 * @param {boolean} [props.required=false] - Whether the input is required.
 * @param {string} [props.name=""] - Input name.
 * @param {string} [props.placeholder=""] - Input placeholder text.
 * @param {string|number} [props.value=""] - Input value.
 * @param {function} [props.onChange=() => {}] - Function to handle input change.
 * @param {string} [props.label=""] - Label text for the input.
 * @param {string} [props.description=""] - Description text for the input.
 * @param {string} [props.descriptionClasses="text-xs text-neutral-500 pl-0"] - Classes for the description.
 * @param {node} [props.rightText=""] - Additional text to display on the right side of the input.
 * @param {string} [props.error=""] - Error message to display.
 * @param {boolean} [props.isFocus=false] - Whether the input is focused.
 * @param {string} [props.classes=""] - Additional classes for the input.
 * @param {boolean} [props.allowPasswordEye=false] - Whether to allow toggling password visibility.
 * @param {boolean} [props.disabled=false] - Whether the input is disabled.
 * @param {boolean} [props.hideLabelAfterValueAdded=false] - Whether to hide the label after a value is added.
 * @param {boolean} [props.hideLabelAfterFocussed=false] - Whether to hide the label after the input is focused.
 * @param {function} [props.onBlur=() => {}] - Function to handle blur event.
 * @param {string} [props.labelExtraClasses="text-neutral-800"] - Extra classes for the label.
 * @param {string} [props.outsideDivClasses=""] - Classes for the outer div.
 * @param {boolean} [props.showOptional=false] - Whether to show optional text.
 * @param {number} [props.maxLength] - Maximum length for the input value.
 * @param {string} [props.fallBackPasswordType="text"] - Fallback type for password input.
 * @param {boolean} [props.maskField] - Whether to mask the input field.
 * @param {boolean} [props.clearable=false] - Whether the input value can be cleared.
 * @param {boolean} [props.clearOnUnmount=false] - Whether to clear the input value on unmount.
 * @param {function} [props.onClearHandler=() => {}] - Function to handle input clearing.
 * @param {boolean} [props.showNumberInvalidError=false] - Whether to show error for invalid number input.
 * @param {string} [props.title=""] - Title text for tooltip.
 * @param {boolean} [props.titleNoTranslate=false] - Whether to translate the title text.
 * @param {boolean} [props.wantInitialFocus=false] - Whether to focus the input initially.
 * @param {number}  [props.minNumber] - pass minimum number value for input component
 * @returns {JSX.Element} - Rendered Input component.
 */
export default function Input(props) {
  const {
    type = "text",
    name = "",
    label = "",
    placeholder = "",
    value = null,
    onChange = () => {},
    rightText = "",
    error = "",
    description = "",
    descriptionClasses = "text-xs text-neutral-500 pl-0",
    onBlur = () => {},
    isFocus = false,
    classes = "text-base font-medium",
    allowPasswordEye = false,
    disabled = false,
    required = false,
    hideLabelAfterValueAdded = false,
    hideLabelAfterFocussed = false,
    labelExtraClasses = "text-neutral-800 font-medium",
    showOptional = false,
    outsideDivClasses = "",
    maxLength,
    fallBackPasswordType = "text",
    clearable = false,
    maskField,
    clearOnUnmount = false,
    onClearHandler = () => {},
    showNumberInvalidError = false,
    title = "",
    titleNoTranslate = false,
    wantInitialFocus = false,
    autoComplete = "new-password",
    minNumber = 0,
    rightOuterClasses = "",
    isLoading = false,
    isRightTextLoading = false,
    ...rest
  } = props;
  const { t } = useTranslation();

  const [isShowLabelTooltip, setIsShowLabelTooltip] = useState(false);
  const id = useId();
  const _id = `tooltips-${id.replaceAll(":", "")}`;

  const inputElement = useRef(null);
  const focusedProgrammatically = useRef(false);
  // in disabled mode when we click input the label gets in focus feels
  // in ui feels like that it is editable added thisto fix that
  const [isFocused, setFocus] = useState(isFocus);
  const [textType, setTextType] = useState("password");
  const [showNumberError, setShowNumberError] = useState("");
  const numberErrorDebounceRef = useRef();

  const toggleShowPassword = (event) => {
    event.stopPropagation();
    if (textType === "password") {
      setTextType(fallBackPasswordType);
    } else if (textType === fallBackPasswordType) {
      setTextType("password");
    }
  };

  const handleFocus = () => {
    inputElement.current.focus();

    if (type === "number") {
      inputElement.current.select();
    }

    setFocus(true);
  };

  const handleFocusOut = () => {
    setFocus(false);
  };

  const createClassForInputChange = () => {
    let classString = "";
    if (error) {
      classString += " border-danger focus:border-danger caret-danger";
    }
    if (hideLabelAfterValueAdded || hideLabelAfterFocussed) {
      classString += " border-b-0";
    } else {
      classString += " border-b-[1px]";
    }
    if (!hideLabelAfterValueAdded && !hideLabelAfterFocussed) {
      classString += " border-b-[1px]";
    }

    if (required) {
      classString += " placeholder:text-danger-500";
    }
    return classString;
  };

  function handleInputChange(e) {
    const targetValue = e.target.value;

    if (
      type === "number" &&
      targetValue &&
      !(parseFloat(minNumber) <= parseFloat(targetValue))
    )
      return;
    const convertedStringValue = targetValue.toString();
    if ((maxLength && convertedStringValue.length <= maxLength) || !maxLength) {
      onChange(e);
    }
  }
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();
    // Refocus immediately, on the next tick (after the current  function is done)

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const innerOnClearHandler = () => {
    onClearHandler({ target: { name, value } });
    handleInputChange({ target: { name, value: "" } });
  };

  useEffect(() => {
    return () => {
      if (clearOnUnmount) innerOnClearHandler();
    };
  }, []);
  useEffect(() => {
    // Check if the input field has a pre-filled value
    if (
      value &&
      inputElement.current &&
      !focusedProgrammatically.current &&
      wantInitialFocus
    ) {
      // Manually focus the input field
      inputElement.current.focus();
      focusedProgrammatically.current = true;
    }
  }, [value]);

  return (
    <div
      onBlur={disabled ? () => {} : handleFocusOut}
      className={`cursor-text relative min-w-[100px] max-w-[950px] ${outsideDivClasses} ${
        disabled ? "opacity-50" : ""
      } ${error ? "mb-4" : ""}`}
    >
      <input
        type={
          allowPasswordEye
            ? maskField
              ? fallBackPasswordType
              : textType
            : type
        }
        autoComplete={autoComplete}
        name={name}
        placeholder={isFocused && !value && value !== 0 ? t(placeholder) : ""}
        value={value ?? ""}
        onChange={handleInputChange}
        onClick={disabled ? () => {} : handleFocus}
        onWheel={numberInputOnWheelPreventChange}
        ref={inputElement}
        disabled={disabled}
        min={type === "number" ? minNumber : null} // making sure '-ve' value cant be scrolled down.
        pattern={type === "number" ? "[0-9]" : null}
        onBlur={onBlur}
        className={`border-x-0 border-t-0 outline-0 transition focus:border-primary-500 ease-in duration-100 border-neutral-300 caret-primary pb-3 pt-2 w-full ${createClassForInputChange()} ${
          fallBackPasswordType && maskField && textType === "password"
            ? "mask-field"
            : ""
        } ${classes} ${
          // for large size texts
          classes
            .split(" ")
            .some((token) => token.startsWith("text") && token.endsWith("xl"))
            ? ""
            : "text-base"
        }`}
        onKeyDown={(e) => {
          if (type !== "number" || !showNumberInvalidError) return;

          return ["e", "E", "+", "-"].includes(e.key) && e.preventDefault(); // making sure '-' can't be entered.
        }}
        onKeyUp={(event) => {
          if (type !== "number" || !showNumberInvalidError) return;

          if (
            (event.key.charCodeAt() >= "0".charCodeAt() &&
              event.key.charCodeAt() <= "9".charCodeAt()) ||
            [
              ".",
              "e",
              "Backspace",
              "Meta",
              "ArrowLeft",
              "ArrowRight",
              "ArrowUp",
              "ArrowDown",
              "Enter",
              ",",
            ].some((key) => event.key.charCodeAt() === key.charCodeAt())
          ) {
            setShowNumberError(false);
          } else {
            setShowNumberError(true);
            clearTimeout(numberErrorDebounceRef.current);
            numberErrorDebounceRef.current = setTimeout(() => {
              setShowNumberError(false);
            }, 5000);
          }
        }}
        {...rest}
        {...(type === "number" ? { step: "any" } : {})}
      />

      {isLoading ? (
        <LoaderSkeleton size={[30, 150]} classes="absolute top-2 left-0" />
      ) : null}

      {!(hideLabelAfterFocussed && isFocused) ? (
        <div onClick={disabled ? () => {} : handleFocus}>
          <label
            htmlFor={name}
            ref={(ref) => {
              setIsShowLabelTooltip(isEllipsisActive(ref));
            }}
            id={_id}
            className={`absolute left-0 top-2 transition-all duration-150 cursor-text !important ${
              disabled
                ? value || value === 0 || isFocused
                  ? "-translate-y-6"
                  : ""
                : value || value === 0 || isFocused
                  ? "-translate-y-6 text-neutral-500 font-semibold"
                  : `${labelExtraClasses}`
            } ${required ? "text-danger-500" : ""} ellipsis-easy-dynamic ${
              isShowLabelTooltip ? "max-w-[90%]" : ""
            }`}
          >
            {!value || isFocused || !hideLabelAfterValueAdded ? (
              typeof label === typeof "" ? (
                <>
                  <Text
                    translationKey={label}
                    classes={`${
                      value || value === 0 || isFocused
                        ? "text-xs"
                        : labelExtraClasses
                    }`}
                  />
                  {showOptional ? (
                    <Text
                      translationKey="misc.optionalInBrackets"
                      classes="text-neutral-400 text-xs font-semibold"
                    />
                  ) : null}
                  {isShowLabelTooltip ? (
                    <span>
                      <Tooltip id={_id} direction="bottom">
                        <Text
                          translationKey={label}
                          classes="text-sm font-medium text-neutral-800"
                        />
                      </Tooltip>
                    </span>
                  ) : null}
                </>
              ) : (
                label
              )
            ) : null}
          </label>
        </div>
      ) : null}

      {!error &&
        description &&
        (typeof description === typeof "" ? (
          <Text classes={descriptionClasses} translationKey={description} />
        ) : (
          <div>{description}</div>
        ))}
      <span
        className={`${rightOuterClasses} absolute top-0 right-0 flex items-center gap-2 pt-2 pb-3 text-right"`}
      >
        {allowPasswordEye && (
          <Icon
            name={textType === fallBackPasswordType ? "Eye" : "SlashEye"}
            className="text-lg bg-white cursor-pointer text-neutral-800"
            handleClick={toggleShowPassword}
          />
        )}
        {clearable && !disabled && value ? (
          <Icon
            name="ClearInput"
            className={`ml-1 ${
              value ? "cursor-pointer text-neutral-600" : "text-neutral-300"
            } text-lg`}
            handleClick={(e) => {
              e?.stopPropagation();
              innerOnClearHandler(e); // event for external code to do cleanup if it needs to
            }}
          />
        ) : null}
        {rightText &&
          !allowPasswordEye &&
          (isRightTextLoading ? (
            <LoaderSkeleton size={[30, 50]} classes="bottom-1" />
          ) : (
            <span className="text-sm text-neutral-800">
              {typeof rightText === typeof {} ? (
                rightText
              ) : (
                <Text translationKey={rightText} />
              )}
            </span>
          ))}
        {title ? (
          <span
            className="cursor-pointer"
            id={getTooltipFriendlyId(`input-tooltip-${name}-${label}`)}
          >
            <Icon name="Info" className="w-4 h-4 text-neutral-500" />
            <Tooltip
              direction="top-right"
              id={getTooltipFriendlyId(`input-tooltip-${name}-${label}`)}
              maxWidth={450}
            >
              <Text translationKey={title} noTranslate={titleNoTranslate} />
            </Tooltip>
          </span>
        ) : null}
      </span>
      <span>
        {error && (
          <Text
            classes="inline-block absolute font-medium text-xs text-danger-600"
            translationKey={error}
          />
        )}
        {!error && showNumberInvalidError && showNumberError ? (
          <Text
            classes="inline-block absolute font-medium text-xs text-danger-600"
            translationKey="numberInputError"
          />
        ) : null}
      </span>
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  description: PropTypes.string,
  descriptionClasses: PropTypes.string,
  rightText: PropTypes.node,
  error: PropTypes.string,
  isFocus: PropTypes.bool,
  classes: PropTypes.string,
  allowPasswordEye: PropTypes.bool,
  disabled: PropTypes.bool,
  hideLabelAfterValueAdded: PropTypes.bool,
  hideLabelAfterFocussed: PropTypes.bool,
  onBlur: PropTypes.func,
  labelExtraClasses: PropTypes.string,
  outsideDivClasses: PropTypes.string,
  showOptional: PropTypes.bool,
  maxLength: PropTypes.number,
  fallBackPasswordType: PropTypes.string,
  maskField: PropTypes.bool,
  clearable: PropTypes.bool,
  clearOnUnmount: PropTypes.string,
  onClearHandler: PropTypes.func,
  showNumberInvalidError: PropTypes.bool,
  title: PropTypes.string,
  titleNoTranslate: PropTypes.bool,
  wantInitialFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  rightOuterClasses: PropTypes.string,
  isLoading: PropTypes.bool,
  isRightTextLoading: PropTypes.bool,
};
