import PropTypes from "prop-types";
import React from "react";

// import "@/assets/styles/theme.scss";

import Text from "../Text";

/**
 *
 * @param {string} type - defaultValue "default" options "default" "success" "error"
 * @param {func} onClickHandler - Have functionality handling click
 * @param {string} variant - defaultValue "primary" options "primary" "secondary"
 * @param {string} text - label of link
 */
export default function VpLink({
  href = "",
  type = "default",
  textTranslationProp,
  onClickHandler,
  variant = "primary",
  text,
  textClasses = "",
  disabled = false,
  anchorTagClasses,
}) {
  const _textClasses = `${textClasses} ${
    disabled
      ? " decoration-neutral-500 cursor-not-allowed underline text-neutral-500"
      : ""
  } `;
  return disabled ? (
    text.includes(" ") ? (
      <span className={_textClasses}>{text}</span>
    ) : (
      <Text
        translationKey={text}
        translationProps={textTranslationProp}
        classes={_textClasses}
      />
    )
  ) : (
    <a
      href={href}
      className={`link link-${variant.trim()} ${type.trim()} ${
        disabled ? "cursor-none " : ""
      } ${anchorTagClasses} `}
      onClick={
        disabled
          ? () => {}
          : (e) => {
              e.preventDefault();
              onClickHandler(e);
            }
      }
    >
      {text.includes(" ") ? (
        <span className={_textClasses}>{text}</span>
      ) : (
        <Text
          translationKey={text}
          translationProps={textTranslationProp}
          classes={_textClasses}
        />
      )}
    </a>
  );
}
VpLink.propTypes = {
  href: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  onClickHandler: PropTypes.func,
  textClasses: PropTypes.string,
  disabled: PropTypes.bool,
  textTranslationProp: PropTypes.object,
  anchorTagClasses: PropTypes.string,
};
