import PropTypes from "prop-types";
import React from "react";

import Badge from "@/components/core/Badge";
import { amountToCurrency } from "@/utils/common";

import { DELTA_PERCENTAGE_MODE } from "@/constants/common";
import {
  BADGE_SPEND_COMPARISION_CARD,
  BADGE_SPEND_COMPARISION_CARD_KEYS,
} from "@/constants/company";

function DeltaPercentagePill({
  percentage,
  value,
  currency,
  mode = DELTA_PERCENTAGE_MODE.PERCENTAGE,
}) {
  const numPercentage = parseInt(percentage, 10);
  let keys = null;
  if (mode === DELTA_PERCENTAGE_MODE.PERCENTAGE)
    keys =
      numPercentage === 0
        ? BADGE_SPEND_COMPARISION_CARD_KEYS.NO_CHANGE
        : numPercentage < 0
          ? BADGE_SPEND_COMPARISION_CARD_KEYS.NEGATIVE
          : BADGE_SPEND_COMPARISION_CARD_KEYS.POSITIVE;
  else
    keys =
      value === 0 || value === "0"
        ? BADGE_SPEND_COMPARISION_CARD_KEYS.NO_CHANGE
        : value < 0
          ? BADGE_SPEND_COMPARISION_CARD_KEYS.NEGATIVE
          : BADGE_SPEND_COMPARISION_CARD_KEYS.POSITIVE;

  const config = BADGE_SPEND_COMPARISION_CARD[keys];
  const isAmountMode = mode === DELTA_PERCENTAGE_MODE.AMOUNT;
  const correctPercentage = Number.isNaN(Number.parseInt(percentage, 10))
    ? "0"
    : (percentage ?? "0");
  return (
    <div>
      <Badge
        classes="px-2"
        textClasses="text-xs font-semibold"
        variant={config?.variant}
        preIcon={config?.preIcon}
        translationKey={`${
          !percentage
            ? (amountToCurrency(value) ?? "0")
            : Math.abs(correctPercentage)
        }${
          (value ? currency : percentage)
            ? isAmountMode
              ? ` ${currency}`
              : "%"
            : ""
        }`}
      />
    </div>
  );
}

export default DeltaPercentagePill;

DeltaPercentagePill.propTypes = {
  currency: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mode: PropTypes.string,
};
