import { createSelector } from "@reduxjs/toolkit";

export const selectSyncAttemptsStore = (store) => store.syncAttempts;

export const syncAttemptsSelector = createSelector(
  selectSyncAttemptsStore,
  (syncAttempts) => syncAttempts.syncAttempts
);
export const syncAttemptsListSelector = createSelector(
  syncAttemptsSelector,
  (syncAttempts) => syncAttempts.list
);
export const isFetchingSyncAttemptsSelector = createSelector(
  syncAttemptsSelector,
  (syncAttempts) => syncAttempts.isFetching
);

export const isFetchingSyncAttemptSingularSelector = createSelector(
  selectSyncAttemptsStore,
  (syncAttempts) => syncAttempts.isFetchingSyncAttempt
);

export const syncAttemptsHasMoreSelector = createSelector(
  syncAttemptsSelector,
  (syncAttempts) => syncAttempts.hasMore
);

export const selectedSyncAttemptSelector = createSelector(
  selectSyncAttemptsStore,
  (syncAttempts) => syncAttempts.selectedSyncAttempt
);

export const lastSyncAttemptSelector = createSelector(
  syncAttemptsSelector,
  (syncAttempts) => syncAttempts.list[0]
);

export const isFetchingLastSyncAttemptSelector = createSelector(
  selectSyncAttemptsStore,
  (syncAttempts) => syncAttempts.isFetchingLastSyncAttempt
);
