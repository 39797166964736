import React from "react";

import CardRequestTable from "@/components/MyVolopay/ActionCentre/CardRequestTable";

import { CARD_MODULE_REQUEST_PAGE } from "@/constants/Cards";
import { ROUTES } from "@/constants/routes";

export default function PendingTab() {
  return (
    <CardRequestTable
      tabKey={ROUTES.cards.requests.pending.pathName}
      pageContext={CARD_MODULE_REQUEST_PAGE}
    />
  );
}
