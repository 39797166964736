import { createSelector } from "@reduxjs/toolkit";

export const selectAccountingStore = (state) => state.accounting;

export const accountingIntegrationsSelector = createSelector(
  selectAccountingStore,
  (accounting) => accounting.accountingIntegrations
);

export const accountingVendorsSelector = createSelector(
  selectAccountingStore,
  (accounting) => accounting.accountingVendors
);

export const isFetchingAccountingVendorsSelector = createSelector(
  selectAccountingStore,
  (accounting) => accounting.isFetchingAccountingVendors
);

export const isFetchedAccountingVendorsSelector = createSelector(
  selectAccountingStore,
  (accounting) => accounting.isFetchedAccountingVendors
);

export const accountingEntityOptionsSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.entityOptions
);

export const accountingAuthURLSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.accountingAuthUrl
);

export const accountingIntegrationBankAccountsSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.bankAccounts
);

export const accountingIntegrationSettingsSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.accountingSettingsList
);

export const accountingIntegrationBankAccountIdSelector = createSelector(
  accountingIntegrationSettingsSelector,
  (accountingIntegrations) => accountingIntegrations.bankAccountId
);

export const accountingIntegrationReimbursementBankAccountIdSelector =
  createSelector(
    accountingIntegrationSettingsSelector,
    (accountingIntegrations) =>
      accountingIntegrations.reimbursementBankAccountId
  );

export const accountingIntegrationSyncFrequencySelector = createSelector(
  accountingIntegrationSettingsSelector,
  (accountingIntegrations) => accountingIntegrations?.syncFrequency
);

export const accountingAutoReconcilationSelector = createSelector(
  accountingIntegrationSettingsSelector,
  (accountingIntegrations) => accountingIntegrations?.autoReconcileEnabled
);

export const accountingIntegrationTransferFeeSyncSelector = createSelector(
  accountingIntegrationSettingsSelector,
  (accountingIntegrations) => accountingIntegrations?.transferFeeSyncEnabled
);

export const accountingIntegrationAutoSyncEnabledSelector = createSelector(
  accountingIntegrationSettingsSelector,
  (accountingSettingsList) => accountingSettingsList?.accountingAutoSyncEnabled
);

export const accountingIntegrationDefaultValuesSelector = createSelector(
  accountingIntegrationSettingsSelector,
  (accountingIntegrations) => accountingIntegrations.defaultAccountingValues
);

export const accountingIntegrationSyncedFieldsSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.syncedFields
);

export const accountingIntegrationImportFileFieldsSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.importFileFields
);

export const accountingIntegrationImportedFilesSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.importedFiles
);

export const accountingIntegrationSyncModeSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.syncMode
);

export const accountingIntegrationContinuousBillSyncSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.continuousBillSync
);

export const accountingIntegrationJournalAutoApprovedSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.journalAutoApproved
);

export const accountingIntegrationSubsidiaryOptionsSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.subsidiaryOptions
);

export const accountingIntegrationExportFormatSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.accountingExportFormatType
);

export const accountingIntegrationExportFormatOptionsSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) =>
    accountingIntegrations.supportedExportFormatOptions
);
export const accountingIntegrationSyncOptionsSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.syncOptions
);

export const tallyAccessTokenPayloadSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.tallyAccessTokenPayload
);

export const isLoadingAccountingIntegrationsSelector = createSelector(
  accountingIntegrationsSelector,
  (accountingIntegrations) => accountingIntegrations.isLoading
);

export const pendingTabCountSelector = createSelector(
  selectAccountingStore,
  (accounting) => accounting.pendingTabCount
);
export const isFetchingPendingCountSelector = createSelector(
  selectAccountingStore,
  (accounting) => accounting.isFetchingPendingCount
);
export const filesSelector = createSelector(
  selectAccountingStore,
  (accounting) => accounting.files
);
export const filesDataSelector = createSelector(
  filesSelector,
  (files) => files?.data
);
export const filesErrorSelector = createSelector(
  filesSelector,
  (files) => files?.error
);
