import {
  COMMON_SLIDERS_TRIGGER_KEYS,
  MCC_SELECTED_CONTROLS_TYPES,
  control,
  entityListTitleConfig,
  merchantAndCategoryControlsFieldKeys,
} from "@/constants/Cards";
import { TAG_FIELD_TYPES } from "@/constants/tags";

// Note: getMccListForPreview is for converting the data into the format of how EntityListView component accepts the props.

export function getMccListForPreview(
  formResponse,
  categoryList,
  merchantList,
  sliderType
) {
  const list = [];
  const controlType = formResponse?.controlType;

  const selectedControlType = formResponse?.selectedControlType;

  const optionsIdsArray =
    formResponse?.[
      merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
    ];

  const parentList =
    controlType === control.CATEGORY ? categoryList : merchantList;

  const entityListTitle =
    entityListTitleConfig[selectedControlType]?.labelObject?.[controlType];

  const entityListTitleClasses =
    entityListTitleConfig[selectedControlType]?.labelObject?.classes;

  const entityListTitlePreIcon =
    entityListTitleConfig[selectedControlType]?.preIconObject?.icon;

  const entityListTitlePreIconClasses =
    entityListTitleConfig[selectedControlType]?.preIconObject?.classes;

  optionsIdsArray?.forEach((id) => {
    const requiredTagObject = parentList?.find((tag) => tag.id === id);
    if (requiredTagObject) {
      list.push({
        leftText: requiredTagObject?.name,
        rightText: "",
        preIconConfig: {
          icon:
            selectedControlType === MCC_SELECTED_CONTROLS_TYPES.ALLOW
              ? "CircleCheck"
              : "Cancel",
          iconClasses:
            selectedControlType === MCC_SELECTED_CONTROLS_TYPES.ALLOW
              ? "text-success-600"
              : "text-danger-600",
        },
      });
    }
  });

  return sliderType === COMMON_SLIDERS_TRIGGER_KEYS.GLOBAL_SETTINGS
    ? list
    : [
        {
          list,
          categoryConfig: {
            textConfig: {
              text: entityListTitle,
              classes: entityListTitleClasses,
            },
            iconConfig: {
              icon: entityListTitlePreIcon,
              classes: entityListTitlePreIconClasses,
            },
          },
        },
      ];
}

// Note: getCardRulesForReview is for converting the data into the format of how EntityListView component accepts the props.

export function getCardRulesForReview(formResponse, tags) {
  const list = [];
  const cardRules = formResponse || {};
  const cardRulesKeys = Object.keys(cardRules);
  const cardRulesValues = Object.values(cardRules);

  cardRulesKeys?.forEach((ruleId, index) => {
    const cardRuleTag = tags?.find((tag) => {
      return tag.id === Number(ruleId);
    });

    if (cardRuleTag) {
      list.push({
        leftText: cardRuleTag?.name,
        rightText:
          cardRuleTag?.fieldType === TAG_FIELD_TYPES.LIST
            ? getTagOptionValue(cardRuleTag?.options, cardRulesValues?.[index])
            : cardRulesValues?.[index],
      });
    }
  });

  return list;
}

function getTagOptionValue(tagOptions, tagId) {
  const tagOptionIndex = tagOptions?.findIndex(
    (tagOption) => tagOption.id === Number(tagId)
  );

  return tagOptionIndex >= 0 ? tagOptions[tagOptionIndex]?.name : "";
}

export function getPayloadForUpdatingMccDetails(formResponse) {
  if (
    formResponse?.[
      merchantAndCategoryControlsFieldKeys?.SELECTED_CONTROL_TYPE_OPTIONS
    ]?.length > 0
  ) {
    if (
      formResponse?.[merchantAndCategoryControlsFieldKeys?.CONTROL] ===
      control.CATEGORY
    ) {
      return {
        category_ids:
          formResponse?.[
            merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
          ],
        mcc_restriction_type:
          formResponse?.[
            merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE
          ],
      };
    }
    return {
      merchant_ids:
        formResponse?.[
          merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
        ],
      merchant_restriction_type:
        formResponse?.[
          merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE
        ],
    };
  }

  return null;
}

export function getPayloadForUploadingCardRules(formResponse = {}) {
  const requiredField = [];
  Object.entries(formResponse)?.forEach((ruleArray) => {
    requiredField.push({
      tag_id: Number(ruleArray?.[0]),
      tag_value_id: ruleArray?.[1],
    });
  });

  return requiredField;
}

// Note: The below functions are for converting the api response data into component acceptable format

export function formatLinkedRulesApiResponse(currentLinkedCardRules) {
  const formattedLinkedCardRules = {};

  currentLinkedCardRules?.forEach((rule) => {
    formattedLinkedCardRules[`${rule?.tagId}`] =
      rule?.textValue && rule?.textValue?.length > 0
        ? rule?.textValue
        : rule?.tagValueId;
  });

  return formattedLinkedCardRules;
}

export function formatLinkedMccRestrictionsApiResponse(
  currentLinkedMccRestrictions
) {
  const initialMerchantInfo = {};

  if (Object.entries(currentLinkedMccRestrictions) <= 0) {
    return;
  }

  const isControlTypeCategories =
    currentLinkedMccRestrictions?.categoryRestrictions?.categories?.length > 0;

  const isMerchantTypeCategories =
    currentLinkedMccRestrictions?.merchantRestrictions?.merchants?.length > 0;

  if (isControlTypeCategories || isMerchantTypeCategories) {
    initialMerchantInfo[merchantAndCategoryControlsFieldKeys.CONTROL] =
      isControlTypeCategories ? control.CATEGORY : control.MERCHANT;

    const restrictionType = isControlTypeCategories
      ? currentLinkedMccRestrictions?.categoryRestrictions
      : currentLinkedMccRestrictions?.merchantRestrictions;

    initialMerchantInfo[
      merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE
    ] = restrictionType?.[MCC_SELECTED_CONTROLS_TYPES.ALLOW]
      ? MCC_SELECTED_CONTROLS_TYPES.ALLOW
      : MCC_SELECTED_CONTROLS_TYPES.BLOCK;

    const mccSelectedOptions =
      restrictionType?.[
        `${isControlTypeCategories ? "categories" : "merchants"}`
      ];

    initialMerchantInfo[merchantAndCategoryControlsFieldKeys.DISABLED_OPTIONS] =
      {};

    mccSelectedOptions
      ?.filter((option) => !option?.editable)
      ?.forEach((option) => {
        initialMerchantInfo.disabledOptions[`${option.id}`] = true;
      });

    initialMerchantInfo[
      merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
    ] = mccSelectedOptions?.map((options) => options?.id);
  } else {
    initialMerchantInfo[merchantAndCategoryControlsFieldKeys.CONTROL] =
      control.CATEGORY;

    initialMerchantInfo[
      merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE
    ] = null;

    initialMerchantInfo[
      merchantAndCategoryControlsFieldKeys.SELECTED_CONTROL_TYPE_OPTIONS
    ] = [];
  }

  initialMerchantInfo.merchantId = currentLinkedMccRestrictions?.id;

  return initialMerchantInfo;
}

export function computeShowWhen(showWhenObject, values) {
  if (!showWhenObject) return true;
  function fieldsComparision(field1, field2, bool) {
    if (bool) {
      return field1 === field2;
    }
    return field1 !== field2;
  }

  const [_andComparatorObj] = Object.values(showWhenObject);

  const showField = _andComparatorObj?.reduce(
    (boolChecker, currentComparatorFieldObj) => {
      const [currentComparedField, condtionBool] = Object.entries(
        currentComparatorFieldObj
      );

      return (
        boolChecker &&
        fieldsComparision(
          values[currentComparedField?.[0]],
          currentComparedField[1],
          condtionBool[1]
        )
      );
    },
    true
  );
  return showField;
}
