import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { checkInvitation, createPassword } from "@/store/reducers/user";

import ChangePasswordForm from "./ChangePasswordForm";

export default function CreatePassword() {
  const [searchParam, setSearchParam] = useSearchParams();
  const tenant_id = window.location.host?.split(".")?.[0];
  const token = searchParam.get("invitation_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      tenant_id,
      invitation_token: token,
    };
    dispatch(checkInvitation({ params, navigate }));
  }, []);
  const handleSubmit = (obj) => {
    dispatch(
      createPassword({
        tenant_id,
        invitation_token: token,
        password: obj?.password,
        confirm_password: obj?.confirm_password,
        navigate,
      })
    );
  };
  return (
    <ChangePasswordForm
      title="Welcome to Volopay"
      subtitle="Please create a new password since this is your first time logging into Volopay"
      btnLabel="company.people.invite.accept"
      onSubmit={handleSubmit}
    />
  );
}
