import PropTypes from "prop-types";

import Text from "@/components/core/Text";

export default function UnSettledClaimCell({ val }) {
  return (
    <div>
      <Text translationKey={val?.unsettledClaims} />
    </div>
  );
}

UnSettledClaimCell.propTypes = {
  val: PropTypes.object,
};
