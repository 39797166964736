import { any, arrayOf, number, oneOfType, shape, string } from "prop-types";

import set from "set-value";
import { USE_FORM_TYPE_OF_VALIDATION } from "@/utils/constantUseForm";

import { VP_ADDRESS_CONFIG } from "@/constants/onboarding";

export const fieldPropType = shape({
  key: string,
  label: string,
  path: string,
  rule: string,
  type: string,
  step: string,
  inline_group: string,
  inlineGroup: string,
  section: string,
  tool_tip: any,
  order: number,
  options: arrayOf(
    shape({
      key: string,
      value: string,
    })
  ),
});

export const inlineGroupFieldPropType = shape({
  type: string,
  inline_group: string,
  inlineGroup: string,
  fields: arrayOf(fieldPropType),
});

export const sectionPropType = shape({
  label: string,
  description: string, // added by me
  key: string,
  fields: arrayOf(oneOfType([fieldPropType, inlineGroupFieldPropType])),
});

export const stepPropType = shape({
  label: any,
  description: string, // added by me
  step: string,
  sections: arrayOf(sectionPropType),
});

export const GENERIC_FORM_PAGE_KEY = "step";

export const SECTIONS_KEY_PLURAL = "sections";
export const SECTION_KEY = "key";

export const FIELDS_KEY_PLURAL = "fields";
export const INLINE_FIELDS_KEY = "fields"; // an array of simple fields
export const INLINE_GROUP_TYPE_NAME = "group"; // example { type: 'group' }
export const INLINE_GROUP_IDENTIFIER_KEY = "inlineGroup"; // example { inlineGroup: 'complete_name' }
export const INLINE_GROUP_IDENTIFIER_KEY_OLD_SNAKE_CASE = "inline_group"; // example { inline_group: 'complete_name' }

export const FIELD_KEY = "field";
export const FIELD_KEY_KEY = "key";
export const FIELD_KEY_VALUE = "value";
export const FIELD_PATH_KEY = "path";
export const FIELD_TYPE_KEY = "type";
export const FIELD_LABEL_KEY = "label";
export const FIELD_TOOLTIP_KEY = "tool_tip";
export const FIELD_REQUIRED_KEY = "required";
export const FIELD_REVIEW_LABEL_KEY = "review_label"; // shown only in review (after form is filled)
export const FIELD_SKIP_KEY = "skip"; // participates in UI, but skipped in payload
export const FIELD_REGEX_KEY = "rule";
export const FIELD_PHONE_NUMBER_KEY = "phoneNumber";
export const FIELD_HIDDEN_KEY = "hidden";
export const FIELD_TYPE_SWITCH = "switch";
export const FIELD_MIN_KEY = "min"; // numerical. length validation is already handled by regex.
export const FIELD_MAX_KEY = "max"; // numerical. length validation is already handled by regex.
export const FIELD_TYPE_NUMBER = "number"; // for things like amount. NOT for card number, phone number - they're strings.
export const FIELD_TYPE_STRING = "string"; // the default, in all form related components, hook. Added for completeness.
export const FIELD_TYPE_SELECT = "select"; // the default, in all form related components, hook. Added for completeness.
export const FIELD_TYPE_ADDRESS = "address"; // the default, in all form related components, hook. Added for completeness.
export const FIELD_TYPE_RADIO = "radio"; // the default, in all form related components, hook. Added for completeness.
export const FIELD_TYPE_PASSOWRD = "password"; // the default, in all form related components, hook. Added for completeness.
export const FIELD_TYPE_MULTISELECT = "multiselect";
export const FIELD_TYPE_DATE = "date"; // the default, in all form related components, hook. Added for completeness.
export const FIELD_TYPE_MOBILE_NUMBER = "mobileNumber";
export const FIELD_TYPE_TEXT = "text";
export const FIELD_TYPE_EMAIL = "email";
export const FIELD_TYPE_CHECKBOX = "checkbox";
export const FIELD_TYPE_STEP_WITH_EXTERNAL_LINK = "stepWithExternalLink";
export const FIELD_TYPE_OTP = "otp";
export const FEILD_DYNAMIC_OPTION_KEY = "dynamicOption";
export const FIELD_OPTION_VISIBLE_KEY = "visible";
export const FIELD_DROPDOWN_OPTIONS_ARRAY_KEY = "options";
export const FIELD_DROPDOWN_OPTION_LABEL_KEY = "value";
export const FIELD_DROPDOWN_OPTION_VALUE_KEY = "key";
export const FIELD_AUTOFILL_SOURCE_KEY = "dependent"; // simple prefilling, copy value from other field.
export const FIELD_AUTOFILL_MAPPING_OBJECT = "dependentMapping"; // used with `dependent`, prefill based on mapping and value of other field
export const FIELD_MAX_LENGTH = "maxLength";
export const FIELD_DISABLED = "disabled";
export const FIELD_BILLING_ADDRESS = "billing_address_attributes";

export const GENERIC_FORM_KEY_SEPARATOR = ".";

export function getCompleteFormKey(key, path) {
  return path ? `${path}${GENERIC_FORM_KEY_SEPARATOR}${key}` : key; // "thePath.myKey" vs "myKey"
}

/**
 * @param {Object} field
 * @param {Object} valueToUpdate
 * @param {Object} existingFormValue a filled instance like (of type) 'initialFormValue' (accepted by useForm)
 *
 * 'initialFormValue' argument accepted by useForm = { keyName: { value: valueString, validate: {...} }
 *
 * @returns {void} in-place update
 *
 * Convert
 *  skeleton field = { key, label, type, rule ...}
 *
 *  TO
 *
 *  useForm "acceptable" field = { value, validate: { required, regex, errorStatement }}
 */
export function injectUseFormValueFromSkeletonField(
  field,
  valueToUpdate,
  existingFormValue = null
) {
  const completePath = getCompleteFormKey(
    field[FIELD_KEY_KEY],
    field[FIELD_PATH_KEY]
  );

  // NOTE: completePath is the "name" prop used by form fields (used by useForm). It'll also be reused to generate payload.

  if (field.type !== FIELD_TYPE_ADDRESS) {
    valueToUpdate[completePath] = {
      value:
        existingFormValue?.[completePath]?.value ??
        field?.[FIELD_KEY_VALUE] ??
        "",
      label: field[FIELD_LABEL_KEY],
      validate: {
        required: field[FIELD_REQUIRED_KEY],
      },
    };

    if (field.compare_config) {
      const compareKeyCompletePath = getCompleteFormKey(
        field.compare_config.compare_key,
        field.compare_config.compare_key_path
      );

      valueToUpdate[completePath].validate.compare = {
        keys: [completePath, compareKeyCompletePath],
        comparatorFunc: (firstValue, secondValue) => firstValue === secondValue,
      };

      valueToUpdate[completePath].errorStatement = {
        compare: field.compare_config.error_statement,
      };
    }

    if (field.type === FIELD_TYPE_MOBILE_NUMBER) {
      valueToUpdate[completePath].validate.phoneNumber =
        field[FIELD_PHONE_NUMBER_KEY];
      valueToUpdate[completePath].errorStatement = {
        phoneNumber: "form.errorMessages.phoneInput.phoneNumber", // TODO: i18n? Get static (atleast) errors from BE
      };
    }
  } else {
    const name = completePath;
    const mandatoryAddressFields = {};

    field?.required_fields?.forEach((addressField) => {
      mandatoryAddressFields[`${name}.${addressField}`] = true;
    });

    valueToUpdate[`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_1}`] = {
      value:
        existingFormValue?.[`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_1}`]
          ?.value ?? "",
      validate: {
        required:
          mandatoryAddressFields[
            `${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_1}`
          ] || false,
      },
      errorStatement: {
        regex: "form.address.addressLine1",
      },
    };

    valueToUpdate[`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_2}`] = {
      value:
        existingFormValue?.[`${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_2}`]
          ?.value ?? "",
      validate: {
        required:
          mandatoryAddressFields[
            `${name}.${VP_ADDRESS_CONFIG.ADDRESS_LINE_2}`
          ] || false,
      },
      errorStatement: {
        regex: "form.address.addressLine2",
      },
    };

    valueToUpdate[`${name}.${VP_ADDRESS_CONFIG.ZIPCODE}`] = {
      value:
        existingFormValue?.[`${name}.${VP_ADDRESS_CONFIG.ZIPCODE}`]?.value ??
        "",
      validate: {
        required:
          mandatoryAddressFields[`${name}.${VP_ADDRESS_CONFIG.ZIPCODE}`] ||
          false,
      },
      errorStatement: {
        regex: "form.address.zipcode",
      },
    };

    valueToUpdate[`${name}.${VP_ADDRESS_CONFIG.CITY}`] = {
      value:
        existingFormValue?.[`${name}.${VP_ADDRESS_CONFIG.CITY}`]?.value ?? "",
      validate: {
        required:
          mandatoryAddressFields[`${name}.${VP_ADDRESS_CONFIG.CITY}`] || false,
      },
      errorStatement: {
        regex: "form.address.city",
      },
    };

    valueToUpdate[`${name}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`] = {
      value:
        existingFormValue?.[`${name}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`]
          ?.value ?? "",
      validate: {
        required:
          mandatoryAddressFields[
            `${name}.${VP_ADDRESS_CONFIG.STATE_OR_PROVINCE}`
          ] || false,
      },
      errorStatement: {
        regex: "form.address.state",
      },
    };

    valueToUpdate[`${name}.${VP_ADDRESS_CONFIG.COUNTRY}`] = {
      value:
        existingFormValue?.[`${name}.${VP_ADDRESS_CONFIG.COUNTRY}`]?.value ??
        "",
      validate: {
        required:
          mandatoryAddressFields[`${name}.${VP_ADDRESS_CONFIG.COUNTRY}`] ||
          false,
      },
      errorStatement: {
        regex: "form.address.country",
      },
    };
  }

  // validators
  if (field[FIELD_REGEX_KEY]) {
    const label = ["account_routing_value1"]?.includes(field?.key)
      ? "Account routing value"
      : field[FIELD_LABEL_KEY];

    valueToUpdate[completePath].validate.regex = field[FIELD_REGEX_KEY];
    valueToUpdate[completePath].errorStatement = {
      regex: `${label} is not in correct format`, // TODO: i18n? Get static (atleast) errors from BE
    };
  }

  if (field[FIELD_MAX_LENGTH]) {
    valueToUpdate[completePath].validate.max = field[FIELD_MAX_LENGTH];
  }

  if (field[FIELD_TYPE_KEY] === FIELD_TYPE_NUMBER) {
    const minPresent = [typeof 0, typeof ""].includes(
      typeof field[FIELD_MIN_KEY]
    );
    if (minPresent) {
      valueToUpdate[completePath].validate[
        USE_FORM_TYPE_OF_VALIDATION.minNumber
      ] = parseFloat(field[FIELD_MIN_KEY], 10);
    }

    const maxPresent = [typeof 0, typeof ""].includes(
      typeof field[FIELD_MIN_KEY]
    );
    if (maxPresent) {
      valueToUpdate[completePath].validate[
        USE_FORM_TYPE_OF_VALIDATION.maxNumber
      ] = parseFloat(field[FIELD_MAX_KEY], 10);
    }
  }
}

/**
 *
 * @param {Object | Array<Object>} pages form skeleton of a page, or an array of them
 * @param {Object}                 existingFormValue a filled instance like (of type) 'initialFormValue' (accepted by useForm)

 * 'initialFormValue' argument accepted by useForm = { keyName: { value: valueString, validate: {...} }
 *
 * @returns {Object}  in the 'initialValue' format accepted by the useForm hook
 */
export function formValueFromSkeleton(
  pages,
  existingFormValue = null,
  retainSkeletonKeys = false // saved in `fieldKeysFromSkeleton`
) {
  if (!Array.isArray(pages)) {
    pages = [pages];
  }

  const returnValue = {};

  pages?.forEach((page) => {
    page?.[SECTIONS_KEY_PLURAL]?.forEach((section) => {
      section[FIELDS_KEY_PLURAL]?.forEach((potentialField) => {
        const isInlineGroup =
          potentialField[FIELD_TYPE_KEY] === INLINE_GROUP_TYPE_NAME;

        if (!isInlineGroup) {
          injectUseFormValueFromSkeletonField(
            potentialField,
            returnValue,
            existingFormValue
          );

          if (retainSkeletonKeys) {
            const completePath = getCompleteFormKey(
              potentialField[FIELD_KEY_KEY],
              potentialField[FIELD_PATH_KEY]
            );
            returnValue[completePath] = {
              ...returnValue[completePath],
              fieldKeysFromSkeleton: potentialField, // { skip, path, key, label, type, inline_group}
            };
          }
        } else {
          const inlineGroupFields = potentialField[INLINE_FIELDS_KEY];
          inlineGroupFields?.forEach((field) => {
            injectUseFormValueFromSkeletonField(
              field,
              returnValue,
              existingFormValue
            );

            if (retainSkeletonKeys) {
              const completePath = getCompleteFormKey(
                field[FIELD_KEY_KEY],
                field[FIELD_PATH_KEY]
              );
              returnValue[completePath] = {
                ...returnValue[completePath],
                fieldKeysFromSkeleton: field, // { skip, path, key, label, type, inline_group}
              };
            }
          });
        }
      });
    });
  });

  return { ...existingFormValue, ...returnValue };
}

/**
 *
 * @param   {Object | Object} values 'values' variable exposed by useForm, or a filled instance of 'initialFormValue' (current use)
 *
 *  'values' variable exposed by useForm =            { keyName: valueString }
 *  'initialFormValue' argument accepted by useForm = { keyName: { value: valueString, validate: {...} }
 *
 *  Note: set CLEAR_EMPTIES to false to debug missing payload values in API calls
 *
 * @returns {Object} payload in correct format, that BE needs
 */
export function generatePayloadFromFormValue(values, CLEAR_EMPTIES = true) {
  const payload = {};
  Object.entries(values ?? {}).forEach(([completeKey, value]) => {
    let finalValue = value?.value ?? value; // handle both argument types

    // trim spaces for strings
    if (typeof finalValue === typeof "") finalValue = finalValue.trim();

    // Payload shouldn't contain empty (unfilled) fields. Send if value is not empty string and exists
    if (CLEAR_EMPTIES) {
      if ([undefined, null, ""].includes(finalValue)) return;
    }

    // convert date objects to ISO timestamp
    if (finalValue instanceof Date) finalValue = finalValue.toLocaleISOString();

    set(payload, completeKey, finalValue, {
      separator: GENERIC_FORM_KEY_SEPARATOR,
    }); // in-place operation
  });

  return payload;
}

/**
  Convert:
    exactValues = {
      key1: val1,
      key2: val2,
    }

   TO

   initialFormValue = {
      key1: {
        value: val1,
        validate: {...}
      },
      key2: {
        value: val2,
        validate: {...}
      }
    }

  @param {Object} exactValues 'values' variable returned by useForm
  @param {Object} initialFormValue 'initialFormValue' accepted by useForm

  @returns {Object} object with initialFormValue type
*/
export function formValueFromExactValue(exactValues, initialFormValue) {
  const returnVal = {};

  Object.keys(exactValues)?.forEach((key) => {
    returnVal[key] = { ...initialFormValue?.[key], value: exactValues?.[key] };
  });

  return returnVal;
}

/**
 * @param {Object} values values i.e. useForm returnage { dotPath1: val1, dotPath2: val2 ...}
 *    or initialFormValue i.e. argument to useForm { {name: dotPath1, validate}, {name: dotPath2, validate} ...}
 * @param {pages}  pages requirements
 *
 * @returns {Object} same format as argument, with fields skipped
 *
 * To generate payload, `generatePayloadFromFormValue(removeSkipFields(values))`
 *  should work. It will remove values which have a `skip` true (as per skeleton)
 */

export function removeSkipFields(values, pages) {
  const isInFormInputStructure = typeof Object.values(values)[0] === typeof {};
  const valuesWithSkeletonFieldsAttached = formValueFromSkeleton(
    pages,
    isInFormInputStructure ? values : formValueFromExactValue(values, {}),
    true
  );
  const retVal = {};
  Object.entries(valuesWithSkeletonFieldsAttached).forEach(([k, v]) => {
    if (v?.fieldKeysFromSkeleton?.[FIELD_SKIP_KEY]) return;

    retVal[k] = values[k];
  });

  return retVal;
}

// Tests,
//   data sets: MOCK_DATA['api/v1/vendors/requirements'],
//     MOCK_DATA['card-activation-requirements'] confirm_pin skipped succesfully
// Variations:
//   Single page | MultiPage
//   exactValues | formInputValue
//   no skip present | some skip present

/**
 * Generate data array for review rows. Meant to be be used with GenericForm.
 *
 * @param {Object} values values i.e. useForm returnage { dotPath1: val1, dotPath2: val2 ...}
 *    or initialFormValue i.e. argument to useForm { {name: dotPath1, validate}, {name: dotPath2, validate} ...}
 * @param {Array<Object>}  pages requirements
 * @param {Boolean}  showSkip    omit fields having `skip` key, from review? Default: false (nothing omitted)
 *
 * @returns {Array<Object>} [{id, label, value, displayValue }, {id, label, value, displayValue }...]
 */
export function getReviewRows(values, pages, showSkip = false) {
  const isInFormInputStructure = typeof Object.values(values)[0] === typeof {};
  const valuesWithSkeletonFieldsAttached = formValueFromSkeleton(
    pages,
    isInFormInputStructure ? values : formValueFromExactValue(values, {}),
    true
  );

  const rows = Object.entries(valuesWithSkeletonFieldsAttached)
    .map(([k, v]) => {
      const field = v?.fieldKeysFromSkeleton;
      if (!showSkip && field?.[FIELD_SKIP_KEY]) return null;

      const label = field?.[FIELD_REVIEW_LABEL_KEY] || field?.[FIELD_LABEL_KEY];

      const isDropdrown =
        !!field?.[FIELD_DROPDOWN_OPTIONS_ARRAY_KEY]?.length &&
        (field?.[FIELD_TYPE_KEY] === FIELD_TYPE_STRING ||
          field?.[FIELD_TYPE_KEY] === FIELD_TYPE_SELECT);
      const dropdownOptions = isDropdrown
        ? field[FIELD_DROPDOWN_OPTIONS_ARRAY_KEY]
        : null;

      const value = values[k]?.value ?? values[k]; // .value is due to useForm. Possibly a code.

      const displayValue =
        dropdownOptions?.find(
          (item) => item[FIELD_DROPDOWN_OPTION_VALUE_KEY] === value
        )?.[FIELD_DROPDOWN_OPTION_LABEL_KEY] ?? value; // renderable value, for dropdowns its the label value

      const id = getCompleteFormKey(
        field?.[FIELD_KEY_KEY] ?? k,
        field?.[FIELD_PATH_KEY] ?? ""
      );

      return {
        id,
        label,
        value,
        displayValue,
        hidden: field?.[FIELD_HIDDEN_KEY],
      }; // id for map(reactNode.key)
    })
    .filter(Boolean);

  return rows;
}

/**
 * Change a single field object in requirements.
 * This works because even though requirements object is nested, the field keys are globally unqiue
 *
 * Usage example: bank dropdown in create vendor couldn't be sent over in requirements, and sent by another API.
 *
 * @param {String}            requirements
 * @param {String | Function} fieldKey
 * @param {any | Function}    newFieldValue
 * @param {Boolean}           inPlace
 */
export function changeFieldInRequirements(
  requirements,
  fieldKey, // key to change, or function whose value is true for an object. Using function becomes
  // important if you don't know exact keys, // or want to change multiple fields at once
  newFieldValue = (originalField) => originalField,
  inPlace = false // kept default false to avoid Redux store mutation, coz we generally store requirments in the store.
) {
  const requirementsClone = inPlace
    ? requirements
    : structuredClone(requirements); // to avoid Redux store mutation

  return requirementsClone?.map((page) => {
    page[SECTIONS_KEY_PLURAL] = page[SECTIONS_KEY_PLURAL].map((section) => {
      section[FIELDS_KEY_PLURAL] = section[FIELDS_KEY_PLURAL].map((field) => {
        const isInlineGroup = field[FIELD_TYPE_KEY] === INLINE_GROUP_TYPE_NAME;

        if (isInlineGroup) {
          const inlineGroupFields = field[INLINE_FIELDS_KEY];

          const updatedInlineGroupFields = inlineGroupFields?.map(
            (inlineGroupField) => {
              if (
                fieldKey === inlineGroupField[FIELD_KEY_KEY] ||
                (typeof fieldKey === typeof (() => {}) &&
                  fieldKey(inlineGroupField))
              ) {
                return typeof newFieldValue === typeof (() => {})
                  ? newFieldValue(inlineGroupField)
                  : newFieldValue;
              }

              return inlineGroupField;
            }
          );

          return { ...field, [INLINE_FIELDS_KEY]: updatedInlineGroupFields };
        }

        if (
          fieldKey === field[FIELD_KEY_KEY] ||
          (typeof fieldKey === typeof (() => {}) && fieldKey(field))
        ) {
          return typeof newFieldValue === typeof (() => {})
            ? newFieldValue(field)
            : newFieldValue;
        }

        return field;
      });
      return section;
    });
    return page;
  });
}
