import { useState } from "react";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import { BILLPAY_SETTINGS_TABS } from "@/utils/constants/payments";

import "./styles.scss";

export default function BillPaySettings() {
  const [selectedTab, setSelectedTab] = useState(BILLPAY_SETTINGS_TABS[0].key);

  return (
    <div className="billpay-settings-page-container">
      <div className="billpay-settings-page-top-section">
        <div aria-label="breadcrumb-and-large-title" className="mt-6">
          <Text
            classes="breadcrumb-text block"
            translationKey="billPay.settings.billPayBreadcrumb"
          />
          <Text
            classes="listing-page-title"
            translationKey="billPay.settings.title"
          />
        </div>
      </div>
      <div className="billpay-settings-page-main-section">
        <Tabs
          items={BILLPAY_SETTINGS_TABS}
          selectedTab={selectedTab?.key}
          setCurrentTab={(tab) => setSelectedTab(tab)}
        />
        {/* for tab pages see <BillPaymentsSettingsRoutes /> */}
      </div>
    </div>
  );
}
