import React from "react";

import Loader from "@/components/core/Loader";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function SpendCreditLoader() {
  return (
    <div className="flex flex-col gap-5 card-wrapper">
      <div className="flex justify-between">
        <div>
          <div className="flex items-center gap-2">
            <LoaderSkeleton size={[20, 80]} />
            <LoaderSkeleton type="circle" size="xs" />
          </div>
          <LoaderSkeleton size={[20, 100]} />
        </div>
        <div>
          <div className="flex items-center gap-2">
            <LoaderSkeleton size={[20, 80]} />
            <LoaderSkeleton type="circle" size="xs" />
          </div>
          <LoaderSkeleton size={[20, 100]} />
        </div>
      </div>
      <LoaderSkeleton size={[20, 700]} />
      {/*  label of progress */}
      <div className="flex gap-5">
        {[1, 2, 3].map((val) => (
          <div
            key={`loader-label-progress-${val}`}
            className="flex items-center gap-1"
          >
            <LoaderSkeleton type="circle" size={[10, 10]} />
            <LoaderSkeleton size={[10, 50]} />
          </div>
        ))}
      </div>
      {/* overview */}
      <div className="flex justify-between bg-neutral-50 py-3 px-2 rounded-[10px]">
        <div className="flex items-center gap-1">
          <LoaderSkeleton size={[20, 200]} />
        </div>
        <LoaderSkeleton size="md" />
      </div>
      <div className="flex justify-between">
        <div className="flex gap-5">
          <LoaderSkeleton size="md" />
          <LoaderSkeleton size="md" />
        </div>
        <LoaderSkeleton size="md" />
      </div>
    </div>
  );
}
