import { useEffect, useState } from "react";

/**
 * Pagination helper
 *
 * @param {Number}   initialPageNum
 * @param {Boolean}  hasMore
 * @param {Function} loadMore
 * @param {Function} onReset
 * @param {Object}   filterOptions
 *
 *
 * @returns {Number} pageNum - current page number
 * @returns {Function} setPageNum - set page number
 */
export default function usePagination({
  initialPageNum = 1,
  hasMore,
  loadMore = () => {},
  onReset = () => {},
  inSlider = false,
  filterOptions = {}, // assumption: max height 2, e.g. { myKey: ['val1', 2, 'hello', true] }
}) {
  const [pageNum, setPageNum] = useState(0);

  const [initialCall, setInitialCall] = useState(false);

  useEffect(() => {
    setPageNum(initialPageNum);
  }, []);

  useEffect(() => {
    const triggerLoadMoreFunc = hasMore && pageNum >= initialPageNum;

    const _triggerLoadMoreFunc =
      (inSlider && triggerLoadMoreFunc) || triggerLoadMoreFunc;

    if (_triggerLoadMoreFunc) {
      loadMore();
      setInitialCall(true);
    }
  }, [pageNum]);

  useEffect(() => {
    onReset();
    if (!inSlider) setInitialCall(true);

    if (pageNum > initialPageNum) {
      setPageNum(initialPageNum);
    } else if (pageNum === initialPageNum && initialCall) {
      loadMore();
    }
  }, [JSON.stringify(filterOptions)]);

  return [pageNum, setPageNum];
}
