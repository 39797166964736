import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import API from "@/api";

const initialMileageRateState = {
  list: [],
  isFetching: false,
};
const mileageRateSlice = createSlice({
  name: "mileageRate",
  initialState: initialMileageRateState,
  reducers: {
    selist(state, action) {
      state.list = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});
export const fetchMileageRateApi = createAsyncThunk(
  "top-count",
  async (params, { dispatch }) => {
    dispatch(setIsFetching(true));
    const [error, response] = await to(API.MileageRate.mileageRateList());
    if (response) {
      dispatch(selist(response));
    }
    dispatch(setIsFetching(false));
  }
);
export const { selist, setIsFetching } = mileageRateSlice.actions;
export default mileageRateSlice.reducer;
