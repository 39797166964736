import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchBankAccounts } from "@/store/reducers/accounting";
import {
  fetchAndSelectAccountingTransaction,
  setBankAccountForAccounting,
} from "@/store/reducers/accounting_transactions";

import { accountingIntegrationBankAccountsSelector } from "@/store/selectors/accounting";
import {
  isFetchingSelectedAccountingTransactionSelector,
  selectedAccountingTransactionSelector,
} from "@/store/selectors/accounting_transactions";
import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";
import { accountingTagsSelector } from "@/store/selectors/tags";

import Badge from "@/components/core/Badge";
import FileUpload from "@/components/core/FileUpload";
import Loader from "@/components/core/Loader";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import AccountingSliderSubmitButton from "@/components/Accounting/Transactions/common/Slider/AccountingSliderSubmitButton";
import SingleColumnDataRows from "@/components/Accounting/Transactions/common/Slider/SingleColumnDataRows";
import {
  amountToCurrency,
  dateToString,
  dateToTimeStampString,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_AMOUNT_SIGNS,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_TYPES,
  OTHER_TRANSACTION_STATUSES,
} from "@/constants/accounting";
import {
  OTHER_TRANSACTION_STATUS_TO_COLOR_MAP,
  OTHER_TYPES_ICON_AND_TITLE_MAP,
} from "@/constants/other";

import SliderAccountingSection from "../../common/Slider/SliderAccountingSection";
import { accountingActionHandler } from "../../common/util";

export default function AccountingTransactionOthersSlider() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const transactionId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.othersId
  );

  const accountingTransaction = useSelector(
    selectedAccountingTransactionSelector
  );
  const isFetchingAccountingTransaction = useSelector(
    isFetchingSelectedAccountingTransactionSelector
  );
  const bankAccountOptions = useSelector(
    accountingIntegrationBankAccountsSelector
  );

  const selectedBankAccount = useMemo(
    () => accountingTransaction?.accountingBankAccount,
    [accountingTransaction]
  );

  const [accountingTag, setAccountingTag] = useState(
    accountingTransaction?.accountingTags
  );

  const accountingTagOptions = useSelector(accountingTagsSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );
  const accountingEnabled = !!accountingIntegrationSoftware;

  useEffect(() => {
    dispatch(
      fetchAndSelectAccountingTransaction({
        accountingId: transactionId,
      })
    );
    dispatch(fetchBankAccounts());
  }, []);

  const accountingForm = {
    accountingTag: {
      value: accountingTag,
      options: accountingTagOptions,
      handleChange: setAccountingTag,
    },
  };

  let transactionDetailsRows = [];
  switch (accountingTransaction?.transactionType) {
    case ACCOUNTING_TRANSACTION_TYPES.CREDIT:
      transactionDetailsRows = [
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.transactionAmount",
          value: amountToCurrency(
            accountingTransaction?.amount?.amount,
            accountingTransaction?.amount?.currency
          ),
        },
        {
          label: "accounting.transactions.slider.transactionDetailsLabels.date",
          value: dateToString(accountingTransaction?.transactionDate),
        },
      ];
      break;
    case ACCOUNTING_TRANSACTION_TYPES.DEBIT:
      transactionDetailsRows = [
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.amount",
          value: amountToCurrency(
            accountingTransaction?.amount?.amount,
            accountingTransaction?.amount?.currency
          ),
        },
        {
          label: "accounting.transactions.slider.transactionDetailsLabels.date",
          value: dateToString(accountingTransaction?.transactionDate),
        },
      ];
      break;
    case ACCOUNTING_TRANSACTION_TYPES.INVOICE_PAYMENT:
    case ACCOUNTING_TRANSACTION_TYPES.CREDIT_BILL_REPAYMENT:
    case ACCOUNTING_TRANSACTION_TYPES.CREDIT_LATE:
      transactionDetailsRows = [
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.transactionAmount",
          value: amountToCurrency(
            accountingTransaction?.amount?.amount,
            accountingTransaction?.amount?.currency
          ),
        },
        {
          label: "accounting.transactions.slider.transactionDetailsLabels.date",
          value: dateToString(accountingTransaction?.transactionDate),
        },
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.debitedFrom",
          value:
            accountingTransaction?.fromAccount?.bankAccount?.accountHolderName,
        },
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.paidTo",
          value:
            accountingTransaction?.toAccount?.bankAccount?.accountHolderName,
        },
      ];
      break;
    case ACCOUNTING_TRANSACTION_TYPES.ADJUSTMENT:
      transactionDetailsRows = [
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.merchant",
          value: accountingTransaction?.merchant?.name,
        },
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.transactionAmount",
          value: amountToCurrency(
            accountingTransaction?.amount?.amount,
            accountingTransaction?.amount?.currency
          ),
        },
        {
          label: "accounting.transactions.slider.transactionDetailsLabels.date",
          value: dateToString(accountingTransaction?.transactionDate),
        },
      ];
      break;
    case ACCOUNTING_TRANSACTION_TYPES.CASHBACK:
      transactionDetailsRows = [
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.redeemedTo",
          value:
            accountingTransaction?.fromAccount?.bankAccount?.accountHolderName,
        },
      ];
      break;
    case ACCOUNTING_TRANSACTION_TYPES.DECLINED:
      transactionDetailsRows = [
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.merchant",
          value: accountingTransaction?.merchant?.name,
        },
      ];
      break;
    case ACCOUNTING_TRANSACTION_TYPES.ALLOCATION:
      transactionDetailsRows = [
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.transactionAmount",
          value: amountToCurrency(
            accountingTransaction?.amount?.amount,
            accountingTransaction?.amount?.currency
          ),
        },
        {
          label: "accounting.transactions.slider.transactionDetailsLabels.date",
          value: dateToString(accountingTransaction?.transactionDate),
        },
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.debitedFrom",
          value:
            accountingTransaction?.toAccount?.bankAccount?.accountHolderName,
        },
      ];
      break;
    case ACCOUNTING_TRANSACTION_TYPES.INTERNAL_CREDIT:
    case ACCOUNTING_TRANSACTION_TYPES.INTERNAL_DEBIT:
      transactionDetailsRows = [
        {
          label:
            "accounting.transactions.slider.transactionDetailsLabels.transactionAmount",
          value: amountToCurrency(
            accountingTransaction?.amount?.amount,
            accountingTransaction?.amount?.currency
          ),
        },
        {
          label: "accounting.transactions.slider.transactionDetailsLabels.date",
          value: dateToString(accountingTransaction?.transactionDate),
        },
        {
          label: "accounting.transactions.slider.transactionDetailsLabels.from",
          value: accountingTransaction?.fromAccount?.name,
        },
        {
          label: "accounting.transactions.slider.transactionDetailsLabels.to",
          value: accountingTransaction?.toAccount?.name,
        },
      ];
      break;

    default:
      break;
  }

  const showCardDetails = [
    ACCOUNTING_TRANSACTION_TYPES.ADJUSTMENT,
    ACCOUNTING_TRANSACTION_TYPES.DECLINED,
  ].includes(accountingTransaction?.transactionType);

  const cardDetailsForDeclined = [
    {
      label: "cards.title.singular",
      value: accountingTransaction?.card?.name,
    },
    {
      label: "accounting.transactions.slider.transactionDetailsLabels.owner",
      value: accountingTransaction?.merchant?.name,
    },
  ];

  const showDescription = ![
    ACCOUNTING_TRANSACTION_TYPES.DEBIT,
    ACCOUNTING_TRANSACTION_TYPES.CREDIT_BILL_REPAYMENT,
    ACCOUNTING_TRANSACTION_TYPES.CREDIT_LATE,
  ].includes(accountingTransaction?.transactionType);

  const showReceipt =
    accountingTransaction?.transactionType ===
    ACCOUNTING_TRANSACTION_TYPES.INVOICE_PAYMENT;
  const [invoiceFiles, setInvoiceFiles] = useState([]);

  const showAccounting = ![
    ACCOUNTING_TRANSACTION_TYPES.CREDIT,
    // ACCOUNTING_TRANSACTION_TYPES.DEBIT,
    ACCOUNTING_TRANSACTION_TYPES.INTERNAL_CREDIT,
    ACCOUNTING_TRANSACTION_TYPES.INTERNAL_DEBIT,
  ].includes(accountingTransaction?.transactionType);

  const showBankAccount = [
    ACCOUNTING_TRANSACTION_TYPES.CREDIT,
    // ACCOUNTING_TRANSACTION_TYPES.DEBIT,
    ACCOUNTING_TRANSACTION_TYPES.CREDIT_BILL_REPAYMENT,
  ].includes(accountingTransaction?.transactionType);

  const showBankAccountHelperText = [
    ACCOUNTING_TRANSACTION_TYPES.DEBIT,
  ].includes(accountingTransaction?.transactionType)
    ? "accounting.transactions.slider.bankAccountDropDownPlaceholderDebit"
    : "accounting.transactions.slider.bankAccountDropDownPlaceholder";

  const getLeftHeaderData = () => {
    return {
      icon: OTHER_TYPES_ICON_AND_TITLE_MAP[
        accountingTransaction?.transactionType
      ]?.icon,

      title:
        OTHER_TYPES_ICON_AND_TITLE_MAP[accountingTransaction?.transactionType]
          ?.title,
      iconStyle: "w-9 h-9 p-2.5 rounded-full bg-neutral-100 text-neutral-500",
    };
  };

  const ref = useLeftHeaderTitle(getLeftHeaderData(), {}, true);

  return !isFetchingAccountingTransaction ? (
    <>
      <div ref={ref} className="slider-content-core">
        <div className="mt-5 mb-6">
          <OthersSummaryShort
            heading={`${amountToCurrency(
              Math.abs(accountingTransaction?.amount?.amount),
              accountingTransaction?.amount?.currency
            )}`}
            headingClasses={
              accountingTransaction?.amount?.amount > 0
                ? "text-success-600"
                : "text-neutral-800"
            }
            status={accountingTransaction?.transactionStatus}
          />

          <Text
            translationKey={dateToString(
              accountingTransaction?.transactionDate,
              false
            )}
            classes="text-sm text-neutral-500 font-medium h-6"
          />
        </div>
        <div className="mb-4 mt-9">
          {showCardDetails ? (
            <div>
              <p className="mb-3">
                <Text
                  translationKey="accounting.transactions.slider.cardDetails"
                  classes="text-lg font-semibold text-neutral-800"
                />
              </p>
              {accountingTransaction?.transactionType ===
              ACCOUNTING_TRANSACTION_TYPES.ADJUSTMENT ? (
                <SingleColumnDataRows
                  dataRows={[
                    {
                      label: "cards.listingFields.card.header",
                      value: accountingTransaction?.card?.name,
                    },
                    {
                      label:
                        "accounting.transactions.slider.transactionDetailsLabels.owner",
                      value: accountingTransaction?.owner?.name,
                    },
                  ]}
                />
              ) : null}
              {accountingTransaction?.transactionType ===
              ACCOUNTING_TRANSACTION_TYPES.DECLINED ? (
                <div>
                  <SingleColumnDataRows dataRows={cardDetailsForDeclined} />
                </div>
              ) : null}
            </div>
          ) : null}

          {transactionDetailsRows.length > 0 ? (
            <div className="mt-8">
              <Text
                classes="text-neutral-800 font-semibold text-lg"
                translationKey="accounting.transactions.cards.slider.transactionDetails"
              />
              <div className="mt-3">
                <SingleColumnDataRows
                  dataRows={transactionDetailsRows}
                  dataRowsx={[
                    {
                      label:
                        "accounting.transactions.slider.transactionDetailsLabels.merchant",
                      value: accountingTransaction?.merchant?.name,
                    },
                    {
                      label:
                        "accounting.transactions.slider.transactionDetailsLabels.transactionAmount",
                      value: amountToCurrency(
                        accountingTransaction?.amount?.amount,
                        accountingTransaction?.amount?.currency
                      ),
                    },
                    {
                      label:
                        "accounting.transactions.slider.transactionDetailsLabels.date",
                      value: dateToString(
                        accountingTransaction?.transactionDate
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          ) : null}

          {showDescription ? (
            <div className="border-b-[1px] border-neutral-300 mt-8">
              <h3>
                <Text
                  translationKey="accounting.transactions.slider.description"
                  classes="text-neutral-500 text-xs font-semibold"
                />
              </h3>
              <div className="pt-1 pb-2 text-base font-medium text-neutral-800">
                {accountingTransaction?.description}
              </div>
            </div>
          ) : null}

          {accountingEnabled && showBankAccount ? (
            <div className="mt-8 mb-8">
              <Text
                translationKey="accounting.transactions.slider.bankAccount"
                classes="text-lg font-semibold text-neutral-800"
              />
              <div className="mt-5">
                <VpSelect
                  label={
                    <Text
                      translationKey={showBankAccountHelperText}
                      classes="text-xs font-semibold"
                    />
                  }
                  placeholder={
                    <Text
                      translationKey={showBankAccountHelperText}
                      classes="text-xs font-semibold"
                    />
                  }
                  value={selectedBankAccount?.id}
                  optionsDisplayKey="name"
                  valueKey="id"
                  options={bankAccountOptions}
                  disabled={!accountingTransaction?.editable}
                  classes={`${
                    accountingTransaction?.syncable ? "opacity-50" : ""
                  }`}
                  handleChange={(bankAccount) => {
                    const params = {
                      bank_account_id: bankAccount?.id,
                      id: accountingTransaction?.id,
                    };
                    dispatch(setBankAccountForAccounting(params));
                  }}
                  menuPosition="absolute"
                />
              </div>
            </div>
          ) : null}

          {accountingEnabled && showAccounting ? (
            <div className="mt-8 mb-15">
              <SliderAccountingSection
                inputs={accountingForm}
                transaction={accountingTransaction}
                page={ACCOUNTING_TRANSACTION_PAGES.OTHER}
                showCustomFields={false}
              />
            </div>
          ) : null}
        </div>
      </div>

      {accountingEnabled ? (
        <div className="slider-footer">
          <div className="flex items-center justify-end h-full px-6 py-4 bg-white">
            <AccountingSliderSubmitButton
              accountingStatus={accountingTransaction?.accountingStatus}
              accountingId={accountingTransaction?.accountingId}
              onClick={accountingActionHandler}
              syncable={accountingTransaction?.syncable}
              verifiable={accountingTransaction?.verifiable}
              page={ACCOUNTING_TRANSACTION_PAGES.OTHERS}
              notActionableReason={accountingTransaction?.notActionableReason}
              accountingCtas={accountingTransaction?.accountingCtas}
            />
          </div>
        </div>
      ) : null}
    </>
  ) : (
    <Loader />
  );
}

function OthersSummaryShort({
  heading,
  headingClasses,
  status = OTHER_TRANSACTION_STATUSES.PENDING,
}) {
  return (
    <div className="flex items-center gap-2">
      <Text
        translationKey={heading}
        classes={`text-3xl font-bold ${headingClasses} `}
      />
      <Badge
        variant={OTHER_TRANSACTION_STATUS_TO_COLOR_MAP[status]?.color}
        translationKey={OTHER_TRANSACTION_STATUS_TO_COLOR_MAP[status]?.text}
        classes="w-[calc(theme(spacing.3)*9)]"
      />
    </div>
  );
}

OthersSummaryShort.propTypes = {
  heading: PropTypes.node,
  headingClasses: PropTypes.string,
  status: PropTypes.oneOf(Object.values(OTHER_TRANSACTION_STATUSES)),
};

function createNavigationHandler(navigate, destination, queryObject) {
  return () =>
    navigate({
      pathname: destination,
      search: createSearchParams(queryObject).toString(),
    });
}
