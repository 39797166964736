import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// reducers

// core components
import Radio from "@/components/core/Radio";
import Switch from "@/components/core/Switch";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import { NETSUITE_SYNC_MODE } from "@/utils/constants/integrations";

// utils, constant file imports

export default function NetsuiteSyncOptionComponent({
  syncMode,
  setSyncMode,
  journalAsApproved,
  setJournalAsApproved,
  continuousBillSync,
  setContinuousBillSync,
}) {
  return (
    <div className="flex flex-col gap-9">
      <div className="flex flex-col gap-5">
        <SliderHeader
          heading="accounting.integrations.netsuite.sync.syncMode.title"
          headingClasses="text-lg font-semibold text-neutral-800"
        />
        <div className="flex flex-col gap-4">
          <Radio
            label="accounting.integrations.netsuite.sync.syncMode.journal"
            isChecked={syncMode === NETSUITE_SYNC_MODE.JOURNAL}
            handleChange={() => setSyncMode(NETSUITE_SYNC_MODE.JOURNAL)}
          />
          <Radio
            label="accounting.integrations.netsuite.sync.syncMode.expense"
            isChecked={syncMode === NETSUITE_SYNC_MODE.EXPENSE}
            handleChange={() => setSyncMode(NETSUITE_SYNC_MODE.EXPENSE)}
          />
        </div>
      </div>

      <div className="flex items-center justify-between">
        <SliderHeader
          heading="accounting.integrations.netsuite.sync.syncJournalasApproved.title"
          subText="accounting.integrations.netsuite.sync.syncJournalasApproved.description"
          headingClasses="text-lg font-semibold text-neutral-800"
          subTextClasses="text-xs font-semibold text-neutral-500"
        />

        <Switch
          value={journalAsApproved}
          handleChange={() => setJournalAsApproved((prev) => !prev)}
        />
      </div>

      <div className="flex items-center justify-between">
        <SliderHeader
          heading="accounting.integrations.continuouseSync.title"
          subText="accounting.integrations.continuouseSync.description"
          headingClasses="text-lg font-semibold text-neutral-800"
          subTextClasses="text-xs font-semibold text-neutral-500"
        />

        <Switch
          value={continuousBillSync}
          handleChange={() => setContinuousBillSync((prev) => !prev)}
        />
      </div>
    </div>
  );
}

NetsuiteSyncOptionComponent.propTypes = {
  syncMode: PropTypes.string,
  setSyncMode: PropTypes.func,
  journalAsApproved: PropTypes.bool,
  setJournalAsApproved: PropTypes.func,
  continuousBillSync: PropTypes.bool,
  setContinuousBillSync: PropTypes.func,
};
