import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

function NameAndDescriptionCommonLoader() {
  return (
    <div className="flex flex-col">
      <LoaderSkeleton size={[20, 100]} />
      <LoaderSkeleton size={[10, 50]} />
    </div>
  );
}

export default NameAndDescriptionCommonLoader;
