import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

function CardSliderMenu({ handleMenuClick, ctasConfig }) {
  return (
    <div className="flex flex-wrap gap-4 mt-4 ">
      {ctasConfig?.map((menu, index) => (
        <div key={index}>
          <Button
            disabled={menu.disabled}
            size="md"
            type="default"
            variant="tertiary"
            classes="h-10 w-16 text-neutral-500"
            label={menu.label}
            onClick={() => handleMenuClick(menu.key)}
            preIcon={menu.icon}
            id={menu?.tooltipConfig?.tooltipId}
          />

          {menu?.tooltipConfig?.hasTooltip && (
            <Tooltip id={menu?.tooltipConfig?.tooltipId} direction="bottom">
              <Text translationKey={menu?.tooltipConfig?.text} />
            </Tooltip>
          )}
        </div>
      ))}
    </div>
  );
}

export default CardSliderMenu;

CardSliderMenu.propTypes = {
  handleMenuClick: PropTypes.func,
  ctasConfig: PropTypes.array,
};

// ctasConfig =  [ {key, disabled, tooltipTipConfig:{ hasToolTip : "", text : " "}, label, preIcon, key},{},{} ] Format of ctasConfig
