import PropTypes from "prop-types";

export default function EmployeeIDCell({ val }) {
  return (
    <div className="flex justify-between">
      <div>{val?.vendor?.employeeId || "-"}</div>
    </div>
  );
}

EmployeeIDCell.propTypes = {
  val: PropTypes.shape({
    id: PropTypes.any,

    vendor: PropTypes.object,
    openSliderHandler: PropTypes.func,
    duplicateBillId: PropTypes.any,

    disabled: PropTypes.bool,
    checkedValue: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
  }),
};
