import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import DateInput from "@/components/core/DateInput";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import {
  dateToString,
  getDateRange,
  getRange,
  validateAndConvertToISO8601,
} from "@/utils/common";

import "./style.scss";

export default function SelectDuration({ onSelect }) {
  const OPTIONS = [
    {
      label: "accounting.ledger.requestStatementSlider.currentMonth",
      value: "currentMonth",
      duration: 1,
      dateObject: getDateRange(),
    },
    {
      label: "accounting.ledger.requestStatementSlider.lastMonth",
      value: "lastMonth",
      duration: 2,
      dateObject: getDateRange(1),
    },
    {
      label: "accounting.ledger.requestStatementSlider.last3Month",
      value: "last3Month",
      duration: 3,
      dateObject: getDateRange(2),
    },
    {
      label: "accounting.ledger.requestStatementSlider.last6Month",
      value: "last6Month",
      duration: 6,
      dateObject: getDateRange(5),
    },
    {
      label: "accounting.ledger.requestStatementSlider.customRange",
      value: "customRange",
      duration: null,
      dateObject: getDateRange(0),
    },
  ];
  const [duration, setDuration] = useState(null);
  const [selected, setSelected] = useState(0);
  const [isOptionVisible, toggleOptionVisible] = useState(false);
  const popRef = useRef();
  const onClickHandle = (val, index) => {
    setDuration([val.dateObject.startDate, val.dateObject.endDate]);
    setSelected(index);
    popRef.current.toggle();
    onSelect(getRange(val.value, []));
  };

  useEffect(() => {
    onSelect(getRange(OPTIONS[0].value, []));
  }, []);
  const handleScroll = (e) => {
    popRef?.current?.close();
  };
  //  TO close popup js when scrolled
  useEffect(() => {
    const sliderComponents = document.querySelectorAll(".slider-content-core");
    if (sliderComponents?.length) {
      sliderComponents?.forEach((sliderComponent) => {
        sliderComponent.addEventListener("scroll", handleScroll);
        return () => {
          sliderComponent.removeEventListener("scroll", handleScroll);
        };
      });
    }
  }, []);
  return (
    <Popup
      ref={popRef}
      trigger={() => (
        <div className="w-full ">
          <Text
            classes="text-xs font-semibold text-neutral-500"
            translationKey="accounting.ledger.requestStatementSlider.selectDuration"
          />
          <div
            className="relative flex items-center justify-between pb-1 border-b cursor-pointer w-max-content border-neutral-200"
            onClick={() => toggleOptionVisible((prev) => !prev)}
          >
            <div className="flex gap-2 font-medium">
              <Text translationKey={OPTIONS[selected].label} />
              <Text translationKey="-" noTranslate />
              <Text
                translationKey={dateToString(
                  duration
                    ? duration[0]
                    : OPTIONS[selected].dateObject.startDate
                )}
              />
              <Text translationKey="accounting.transactions.slider.transactionDetailsLabels.smallTo" />
              <Text
                translationKey={dateToString(
                  duration ? duration[1] : OPTIONS[selected].dateObject.endDate
                )}
              />
            </div>

            <Icon
              name="ChevronDown"
              className="w-3.5 h-3.5 mr-3 text-neutral-500"
            />
          </div>
        </div>
      )}
      className="request-slider"
      closeOnDocumentClick
      repositionOnResize
    >
      <div className="flex flex-col bg-white card-wrapper max-w-[900px] !p-0">
        {OPTIONS.map((val, index) =>
          val.value === OPTIONS[4].value ? (
            <div
              id="duration"
              className="w-full !px-2 hover:bg-neutral-50"
              key={index}
            >
              <DateInput
                content={
                  <div
                    className={`py-3 flex gap-2 ${
                      val && val.value === "customRange"
                        ? "flex justify-between"
                        : ""
                    }`}
                  >
                    <Text translationKey={val.label} />
                    <Icon name="Right" />
                  </div>
                }
                mode="date-range"
                onChange={(e) => {
                  if (e.target.value) {
                    setSelected(index);
                    popRef?.current?.close();
                    setDuration(e.target.value);
                    onSelect(
                      getRange(OPTIONS[4].value, [
                        e.target.value[0],
                        e.target.value[1],
                      ])
                    );
                  }
                }}
                value={
                  duration
                    ? [
                        new Date(validateAndConvertToISO8601(duration[0])),
                        new Date(validateAndConvertToISO8601(duration[1])),
                      ]
                    : [val.dateObject.startDate, val.dateObject.endDate]
                }
              />
            </div>
          ) : (
            <button
              key={index}
              className="flex flex-col w-full  !px-2 py-2 hover:bg-neutral-50"
              onClick={() => onClickHandle(val, index)}
            >
              <Text translationKey={val.label} />
              <div className="text-xs font-medium text-neutral-500">
                <Text translationKey={dateToString(val.dateObject.startDate)} />
                <Text translationKey="-" noTranslate />
                <Text translationKey={dateToString(val.dateObject.endDate)} />
              </div>
            </button>
          )
        )}
      </div>
    </Popup>
  );
}

SelectDuration.propTypes = {
  onSelect: PropTypes.func,
};
