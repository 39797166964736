import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { updateProjectAndLocation } from "@/store/reducers/company";

import {
  departmentsSelector,
  isFetchingDepartmentSelector,
  isFetchingLocationsSelector,
  isLoadingLocationsSelector,
  isLoadingProjectsSelector,
  locationsSelector,
} from "@/store/selectors/company";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";

import { PROJECT_MEMBER_TYPE, PROJECT_TYPE_TITLE } from "@/constants/company";

export default function FirstUserOnboardingModal({ showModal, setShowModal }) {
  const dispatch = useDispatch();
  const onCloseModal = () => {
    setShowModal(false);
  };
  const department = useSelector(departmentsSelector)[0];
  const location = useSelector(locationsSelector)[0];
  const isFetchingDepartment = useSelector(isFetchingDepartmentSelector);
  const isFetchingLocation = useSelector(isFetchingLocationsSelector);
  const isLoadingDepartment = useSelector(isLoadingLocationsSelector);
  const isLoadingLocation = useSelector(isLoadingProjectsSelector);
  const isLoading = isLoadingDepartment || isLoadingLocation;
  const currentUser = useSelector(userSelector);
  const initialValue = {
    department: {
      value: department?.name ?? "",
      validate: {
        required: true,
      },
    },
    location: {
      value: location?.name ?? "",
      validate: {
        required: true,
      },
    },
  };

  const handleSave = () => {
    const onSuccess = () => {
      onCloseModal();
    };
    const departmentPayload = {
      id: department?.id,

      payload: {
        name: values?.department,
        project_members_attributes: [
          {
            user_id: currentUser?.id,
            member_type: PROJECT_MEMBER_TYPE.OWNER,
          },
        ],
        start_date: new Date(),
        budgets_attributes: [],
        department: true,
      },
    };

    const projectOrDeptPayload = departmentPayload;
    const locationPayload = {
      id: location?.id,
      params: { name: values?.location },
    };

    dispatch(
      updateProjectAndLocation({
        projectPayload: projectOrDeptPayload,
        locationPayload,
        onSuccess,
      })
    );
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, () => {
      handleSave();
    });

  return (
    <Modal open={showModal} className="flex items-center justify-center">
      <div className="flex flex-col gap-2 px-6 py-2 bg-white w-134 h-fit rounded-2xl">
        <div className="flex flex-col">
          <Text
            translationKey="myVolopay.welcomeToVolopay"
            classes="text-lg font-bold text-neutral-800 py-2"
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-5 py-2">
            <Text
              translationKey="myVolopay.gettingStarted.firstUserOnboardingModal.gettingStartedOnboarding.title"
              classes="text-xs font-bold"
            />
            <Text
              translationKey="myVolopay.gettingStarted.firstUserOnboardingModal.gettingStartedOnboarding.description"
              classes="text-sm font-semibold text-neutral-500"
            />
          </div>
          <form
            onSubmit={handleSubmit}
            id="first-user-onboarding-modal"
            className="flex justify-start w-full gap-3 py-2"
          >
            <div className="w-1/2">
              <Input
                label="myVolopay.gettingStarted.firstUserOnboardingModal.department"
                name="department"
                value={values?.department}
                error={errors?.department}
                disabled={isFetchingDepartment}
                onChange={handleChange}
              />
            </div>
            <div className="w-1/2">
              <Input
                label="myVolopay.gettingStarted.firstUserOnboardingModal.location"
                name="location"
                value={values?.location}
                error={errors?.location}
                disabled={isFetchingLocation}
                onChange={handleChange}
              />
            </div>
          </form>
          <div className="py-4">
            <Text
              translationKey="myVolopay.gettingStarted.firstUserOnboardingModal.tips"
              classes="text-xs font-medium text-neutral-500"
            />
          </div>
        </div>

        <div className="flex items-center w-full gap-6 py-4">
          <Button
            label="myVolopay.gettingStarted.firstUserOnboardingModal.saveAndContinue"
            classes="px-5 py-3 text-base font-semibold w-full"
            btnType="submit"
            showLoader={isLoading}
            form="first-user-onboarding-modal"
            disabled={isFormButtonDisabled}
          />
        </div>
      </div>
    </Modal>
  );
}

FirstUserOnboardingModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};
