import { createSlice } from "@reduxjs/toolkit";

const initialLoaderError = {
  urlMap: {},
  isFormSubmissionProgress: false,
};

const urlMapSlice = createSlice({
  name: "urlMap",
  initialState: initialLoaderError,
  reducers: {
    setUrlMap(state, action) {
      const { url, data } = action.payload;
      state.urlMap[url] = data;
    },
    clearUrlMap(state, action) {
      const { url } = action.payload;
      state.urlMap[url] = null;
    },
    clearAllUrlMaps(state) {
      state.urlMap = {};
    },
    setIsFormSubmissionProgress(state, action) {
      state.isFormSubmissionProgress = action.payload;
    },
  },
});

// Export the reducer actions
export const {
  setUrlMap,
  clearUrlMap,
  clearAllUrlMaps,
  setIsFormSubmissionProgress,
} = urlMapSlice.actions;
export default urlMapSlice.reducer;
