import {
  PERMISSIONS_ACCESSIBILITY_TYPE,
  PERMISSIONS_SORTING_CONFIG,
  PERMISSIONS_TYPES,
  PERMISSIONS_TYPES_CONFIG,
  SETTING_PERMISSIONS_ACTION_TYPE,
} from "@/constants/company";

const hasMatchingPermissionId = (arr, id) => {
  return arr?.find((obj) => id === obj.permissionId)
    ? PERMISSIONS_ACCESSIBILITY_TYPE.EDIT
    : PERMISSIONS_ACCESSIBILITY_TYPE.VIEW;
};

export const groupPermissions = ({
  permissions,
  roleDetails,
  isNewRole = false,
}) => {
  const groupedPermissions = permissions?.reduce((acc, permission) => {
    const [groupName, permissionName] = permission.name.split(":");
    if (!acc[groupName]) {
      acc[groupName] = {};
    }

    acc[groupName][permissionName] = {
      id: permission.id,
      description: permission.description,
      accessibility: isNewRole
        ? permission.accessibility
        : hasMatchingPermissionId(roleDetails?.rolePermissions, permission.id),
      isEditOnly: permission.isEditOnly,
    };
    return acc;
  }, {});
  return groupedPermissions;
};
export const sortPermissions = (permissions, parent) => {
  const sortingOrder = PERMISSIONS_SORTING_CONFIG[parent] || [];
  const getPriority = (name) => {
    const item = sortingOrder.find((permission) => permission.name === name);
    return item ? item.priority : Infinity;
  };

  const updatedValue = permissions.sort((a, b) => {
    const priorityA = getPriority(a.name);
    const priorityB = getPriority(b.name);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    // Comparing accessibility
    const accessibilityComparison =
      a.accessibility === PERMISSIONS_ACCESSIBILITY_TYPE.VIEW ? 1 : -1;
    if (a.accessibility !== b.accessibility) {
      return accessibilityComparison;
    }

    // Comparing by ID if names and accessibility are the same
    if (a.name === b.name) {
      return a.id - b.id;
    }

    return 0;
  });

  return updatedValue;
};

export const permissionsOrder = PERMISSIONS_TYPES;

export const convertPermissions = (
  data,
  type = SETTING_PERMISSIONS_ACTION_TYPE.ADD,
  selectedPermissionList = []
) => {
  const sortedKeys = Object.keys(data).sort(
    (a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b)
  );

  return sortedKeys.reduce((result, key) => {
    const items = data[key];
    result[key] = {
      permissions: items.map((item, index, arr) => {
        const selectedPermission = arr?.find(
          (_) => _?.id !== item?.id && _?.name === item?.name
        );
        return {
          id: item.id,
          siblingId: selectedPermission?.id,
          description: item.description,
          accessibility: item.accessibility,
          isEditOnly: item.isEditOnly,
          disabled:
            type === SETTING_PERMISSIONS_ACTION_TYPE.ADD ||
            (selectedPermissionList?.length &&
              !selectedPermissionList?.find((selectedItem) =>
                [item.permissionId, item.id].includes(
                  selectedItem?.permissionId
                )
              )),
          name: item.name,
          label: `${item.name.split(":")[1].replace(/_/g, " ")} ${
            !item.name.split(":")[1].split("_")[1]
              ? item.name.split(":")[0].replace("_", " ")
              : ""
          }`,
          parent: key,
        };
      }),
      default: false,
      name: key,
    };
    return result;
  }, {});
};
const allTypesofPermission = PERMISSIONS_TYPES;
export const permissionsSetup = (originalPermissions) => {
  const permissions = {};

  if (originalPermissions?.length)
    originalPermissions?.forEach((item) => {
      const key = allTypesofPermission?.find((typeItem) =>
        item?.name?.includes(typeItem)
      );
      if (key) {
        switch (key) {
          case PERMISSIONS_TYPES_CONFIG.EXPENSE:
            permissions[PERMISSIONS_TYPES_CONFIG.CARD] = [
              ...(permissions[PERMISSIONS_TYPES_CONFIG.CARD]
                ? permissions[PERMISSIONS_TYPES_CONFIG.CARD]
                : []),
              item,
            ];
            break;
          case PERMISSIONS_TYPES_CONFIG.QR_WALLET:
            permissions[PERMISSIONS_TYPES_CONFIG.QRPAY] = [
              ...(permissions[PERMISSIONS_TYPES_CONFIG.QRPAY]
                ? permissions[PERMISSIONS_TYPES_CONFIG.QRPAY]
                : []),
              item,
            ];
            break;
          default:
            permissions[key] = [
              ...(permissions[key] ? permissions[key] : []),
              item,
            ];
        }
      }
    });
  return permissions;
};
