import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { forgotPassword, setLoggingUserCred } from "@/store/reducers/user";

import { isLoadingSelector, userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";
import { REGEX_VALUE } from "@/utils/constantUseForm";

import { ROUTES } from "@/constants/routes";

export default function ForgotPassword() {
  const currentUser = useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleChange, values, errors, handleSubmit } = useForm(
    {
      email: {
        value: currentUser?.email || "",
        validate: {
          required: true,
          regex: REGEX_VALUE.email,
        },
      },
    },
    (e) => {
      dispatch(
        forgotPassword({
          data: {
            email: values?.email,
            redirect_url: "test",
          },
          navigate,
        })
      );
    }
  );
  return (
    <div>
      <button
        onClick={() => {
          navigate({
            pathname: ROUTES.login.base.absolutePath,
            search: searchParams.toString(),
          });
        }}
      >
        <Icon className="h-10 cursor-pointer" name="ChevronLeft" />
      </button>
      <div className="flex flex-col mb-10">
        <Text
          classes="font-bold text-3xl"
          translationKey="login.forgotPasswordLabel"
        />
        <Text
          classes="text-neutral-500 text-sm font-semibold"
          translationKey="login.forgotPasswordMessage"
        />
      </div>
      <form
        className="flex flex-col gap-5 pt-4"
        id="forgot-password"
        onSubmit={handleSubmit}
      >
        <Input
          type="text"
          placeholder="login.enterEmail"
          name="email"
          error={errors?.email}
          value={values?.email}
          label="login.forgotPasswordEmail"
          onChange={(e) => {
            dispatch(setLoggingUserCred({ email: e.target.value }));
            handleChange(e);
          }}
        />
        <Button
          btnType="submit"
          classes="w-full mt-5"
          showLoader={isLoading}
          label="login.sendForgotLink"
          form="forgot-password"
        />
      </form>
    </div>
  );
}
