// react imports
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// reducers
import {
  clearFormData,
  mergeIntoFormData,
  setFormData,
} from "@/store/reducers/onboarding";

import { cardsProvidersListItemSelector } from "@/store/selectors/client";
// selectors
import {
  formDataSelector,
  onboardingSectionSelector,
} from "@/store/selectors/onboarding";

import Icon from "@/components/core/Icon";

// core components
import GenericForm from "@/components/GenericForm";

import { CARD_PROVIDER } from "@/constants/Cards";
// utils, constant file imports
import {
  ONBOARDING_SLIDER_PARAMS,
  ONBOARDING_STEPS,
} from "@/constants/onboarding";

import CommonHeaderComponent from "./CommonHeaderComponent";
import "./style.scss";

function OnboardingForm({
  data,
  setIsFormDisabled,
  onPageSubmit,
  onSubmit = () => {},
  setOuterFooterDisableState,
}) {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentStepParamValue = searchParams.get(ONBOARDING_SLIDER_PARAMS);

  const onboardingSection = useSelector(onboardingSectionSelector);

  const onboardingSectionFields = onboardingSection?.sections?.[0]?.fields;

  const currentCardProvider = useSelector(cardsProvidersListItemSelector)?.[0]
    ?.slug;

  const onboardingSectionIntialFormData = useMemo(() => {
    const initialFormData = {};
    onboardingSectionFields?.forEach((fieldObject) => {
      switch (currentStepParamValue) {
        case ONBOARDING_STEPS.ADDRESS_DETAILS:
          Object.keys(fieldObject?.values || {}).forEach((fieldKey) => {
            initialFormData[`${fieldObject.key}.${fieldKey}`] = {
              value: fieldObject?.values[fieldKey],
            };
          });
          break;

        default:
          setIntialFormDataBasedOnType(fieldObject, initialFormData);
      }
    });

    if (
      currentStepParamValue === ONBOARDING_STEPS.IDENTITY_DETAILS &&
      currentCardProvider === CARD_PROVIDER.NIUM
    ) {
      if (initialFormData["nric.id_number"]?.value) {
        initialFormData.permanent_resident = { value: "Yes" };
      } else {
        if (initialFormData["aadhar.id_number"]?.value) {
          initialFormData.select_id_type = {
            value: "adhaar",
          };
        } else if (initialFormData["pan.id_number"]?.value) {
          initialFormData.select_id_type = {
            value: "pan",
          };
        } else if (initialFormData["passport.id_number"]?.value) {
          initialFormData.select_id_type = {
            value: "passport",
          };
        } else if (initialFormData["fin_id.number"]?.value) {
          initialFormData.select_id_type = {
            value: "fin",
          };
        }
        initialFormData.permanent_resident = { value: "No" };
      }
    }
    return initialFormData;
  }, [onboardingSectionFields, currentStepParamValue, currentCardProvider]);

  useEffect(() => {
    const FLATTEN_OBJ = onboardingSectionIntialFormData;
    dispatch(setFormData(FLATTEN_OBJ));
  }, [onboardingSectionFields, currentStepParamValue, currentCardProvider]);

  function setIntialFormDataBasedOnType(fieldObject, initialFormData) {
    const type = fieldObject?.type;
    if (type === "group") {
      initialFormData = Object.assign(
        initialFormData,
        setGroupedInitialValues(fieldObject)
      );
    } else {
      initialFormData[
        fieldObject?.path
          ? `${fieldObject.path}.${fieldObject.key}`
          : fieldObject.key
      ] = {
        value: fieldObject.value,
      };
    }
  }

  function setGroupedInitialValues(fieldObject) {
    const groupedInitialFormData = {};
    fieldObject?.fields?.forEach((field) => {
      groupedInitialFormData[
        field?.path ? `${field.path}.${field.key}` : field.key
      ] = { value: field.value };
    });
    return groupedInitialFormData;
  }

  return (
    <div className="h-full">
      <GenericForm
        setOuterFooterDisableState={setOuterFooterDisableState}
        backComponent={<Icon name="BackArrow" />}
        globalSliderQueryParam={ONBOARDING_SLIDER_PARAMS}
        sectionHeaderComponent={CommonHeaderComponent}
        showFooter={false}
        inSlider={false}
        headingTextBase
        setIsFormDisabled={setIsFormDisabled}
        onSubmit={() => {}}
        onPageSubmit={onPageSubmit}
        isRunPageSubmitAtFinalSubmit
        pages={data}
        formId="onboardingFrom"
        storeSelector={formDataSelector}
        storeSetterReducer={setFormData}
        storeClearReducer={clearFormData}
        storeMergeReducer={mergeIntoFormData}
        wantCleanUp
      />
    </div>
  );
}

OnboardingForm.propTypes = {
  data: PropTypes.array,
  setIsFormDisabled: PropTypes.func,
  onSubmit: PropTypes.func,
  onPageSubmit: PropTypes.func,
  setOuterFooterDisableState: PropTypes.func,
};
export default OnboardingForm;
