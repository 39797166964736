import PropTypes from "prop-types";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

export default function CompanyOverviewPageTitle({
  isAccountPage = false,
  onButtonClick,
  buttonLabel,
  buttonPreIcon,
}) {
  return (
    <div className="flex justify-between items-center">
      <div>
        <div className="text-sm font-semibold text-neutral-500">
          <Text translationKey="company.title" />
          {isAccountPage ? " / " : ""}
          {isAccountPage ? (
            <Text translationKey="company.overview.title" />
          ) : null}
        </div>
        <Text
          classes="text-neutral-800 text-3xl font-bold"
          translationKey={
            isAccountPage
              ? "company.globalAccounts.title"
              : "company.overview.title"
          }
        />
      </div>
      {buttonLabel ? (
        <Button
          variant="primary"
          label={buttonLabel}
          preIcon={buttonPreIcon}
          classes="w-20 px-5 py-1 text-xs font-bold"
          onClick={onButtonClick}
        />
      ) : null}
    </div>
  );
}

CompanyOverviewPageTitle.propTypes = {
  isAccountPage: PropTypes.bool,
  onButtonClick: PropTypes.func,
  buttonLabel: PropTypes.string,
  buttonPreIcon: PropTypes.string,
};
