import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";

import { CARD_ACTION_SLIDER_CONFIG } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function CardActionCommonSlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const sliderConfig =
    CARD_ACTION_SLIDER_CONFIG[
      searchParams.get(SLIDERS_SEARCH_PARAMS.cards.menuTypeCards)
    ];
  const initialValue = {
    comment: {
      value: "",
      validate: {
        required: true,
      },
    },
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialValue, (e) => {
      console.log(values);
    });

  return (
    <>
      <div className="slider-content-core">
        <div className="flex flex-col">
          <Text
            classes="text-3xl font-bold"
            translationKey={sliderConfig?.heading}
          />
          {sliderConfig?.alert ? (
            <div className="mt-8">
              <Alert
                variant={sliderConfig?.alertVariant}
                title={sliderConfig?.alert}
              />
            </div>
          ) : null}
          {sliderConfig?.subheading ? (
            <Text
              color="neutral-500"
              classes="font-bold"
              translationKey={sliderConfig?.subheading}
            />
          ) : null}
        </div>
        {sliderConfig?.secondaryHeading ? (
          <div className="flex flex-col gap-5 mt-11">
            <Text
              classes="text-lg font-bold"
              translationKey={sliderConfig?.secondaryHeading}
            />
            {sliderConfig?.isForm ? (
              <form onSubmit={handleSubmit} id="action-slider">
                <Input
                  name="comment"
                  value={values?.comment}
                  error={errors?.comment}
                  onChange={handleChange}
                  label="comments.enterComments"
                  placeholder="comments.enterComments"
                />
              </form>
            ) : null}
          </div>
        ) : null}
      </div>
      {sliderConfig?.isForm ? (
        <div className="flex justify-end gap-3 px-6 py-3 slider-footer">
          {[
            {
              type: "default",
              variant: "tertiary",
              label: "misc.cancel",
              classes: "text-neutral-500 w-15.5 h-10",
              btnType: "reset",
              form: "action-slider",
            },
            {
              form: "action-slider",
              type: "default",
              variant: "primary",
              classes: "w-15.5 h-10",
              disable: isFormButtonDisabled,
              label: "misc.submit",
              btnType: "submit",
            },
          ].map((buttonProps, idx) => (
            <Button key={idx} {...buttonProps} />
          ))}
        </div>
      ) : null}
    </>
  );
}

export default CardActionCommonSlider;
