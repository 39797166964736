// utils, constant file imports
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// reducers
import {
  fetchAccountingIntegrationDetails,
  fetchAccountingVendors,
  fetchBankAccounts,
} from "@/store/reducers/accounting";
import { fetchTags } from "@/store/reducers/tags";

// selectors
import {
  accountingIntegrationSettingsSelector,
  accountingVendorsSelector,
} from "@/store/selectors/accounting";
import {
  accountingCategoryTagsSelector,
  accountingNonCategoryTags,
} from "@/store/selectors/tags";

// core components
import Button from "@/components/core/Button";

// page relevant components
import AccountingFieldsTable from "@/components/Accounting/Integrations/common/AccountingFieldsTable";
import AccountingIntegrationsHeader from "@/components/Accounting/Integrations/common/AccountingIntegrationsHeader";
import IntegrationChecklist from "@/components/Accounting/Integrations/common/IntegrationChecklist";
import OtherIntegrationsCarousel from "@/components/Accounting/Integrations/common/OtherIntegrationCarousel";
import { INTEGRATION_ACCOUNTING_FIELDS_TABLE_HEADERS } from "@/utils/constants/integrations";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_SOFTWARES_TAG_TITLES,
  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP,
} from "@/constants/accounting";

export default function IntegrationsPage({ accountingSoftware }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tableHeaders = INTEGRATION_ACCOUNTING_FIELDS_TABLE_HEADERS;

  const [accountingFieldsData, setAccountingFieldsData] = useState([]);
  const dispatch = useDispatch();
  const categoryTags = useSelector(accountingCategoryTagsSelector);
  const accountingVendorOptions = useSelector(accountingVendorsSelector);
  const nonCategoryAccountingTags = useSelector(accountingNonCategoryTags);
  const accountingIntegrationSettings = useSelector(
    accountingIntegrationSettingsSelector
  );

  useEffect(() => {
    dispatch(fetchBankAccounts());
    dispatch(fetchAccountingIntegrationDetails());
    dispatch(fetchTags({ visible: true }));
    dispatch(fetchAccountingVendors());
  }, []);

  useEffect(() => {
    const accountingVendor = {
      name: `${ACCOUNTING_SOFTWARES_TAG_TITLES[accountingSoftware]} vendor`,
      fieldType: "list",
      options: accountingVendorOptions || 0,
    };

    const vendorState =
      accountingVendor?.options?.length > 0 ? [accountingVendor] : [];

    setAccountingFieldsData([
      ...(categoryTags ? [categoryTags] : []),
      ...(nonCategoryAccountingTags && nonCategoryAccountingTags),
      ...vendorState,
    ]);
  }, [categoryTags, nonCategoryAccountingTags, accountingVendorOptions]);

  const authenticated =
    accountingIntegrationSettings?.accountSetupChecklist?.authenticated;
  const creditAccountSelected =
    accountingIntegrationSettings?.accountSetupChecklist?.creditAccountSelected;
  const reimburseAccountSelected =
    accountingIntegrationSettings?.accountSetupChecklist
      ?.reimbursementAccountSelected;

  const setSliderHandler = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.softwareSettings,
      accountingSoftware
    );
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col px-11 pb-15 gap-6">
      <div className="flex">
        <AccountingIntegrationsHeader
          sectionTitleText={
            ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[accountingSoftware]
          }
        />
        <div className="flex items-center">
          <Button
            variant="tertiary"
            label="accounting.settings.title"
            preIcon="Settings"
            iconClasses="w-6 h-6"
            classes="w-fit text-btn-lg font-semibold text-neutral-500 px-5 py-4"
            onClick={() => setSliderHandler()}
          />
        </div>
      </div>
      <div className="flex flex-col gap-9">
        <div>
          <IntegrationChecklist
            authenticated={authenticated}
            creditAccountSelected={creditAccountSelected}
            reimbursementAccountSelected={reimburseAccountSelected}
          />
        </div>

        <AccountingFieldsTable
          accountingFieldsData={accountingFieldsData}
          tableHeaders={tableHeaders}
        />

        <OtherIntegrationsCarousel excludeSoftware={accountingSoftware} />
      </div>
    </div>
  );
}

IntegrationsPage.propTypes = {
  accountingSoftware: PropTypes.string,
};
