import React from "react";

import Text from "@/components/core/Text";
import AadharIcon from "@/assets/images/aadhaar-address-cautionary-message.png";

function AadharNotfier() {
  return (
    <div className="flex flex-col justify-between gap-5 w-115">
      <Text
        translationKey="onboarding.onboardingSteps.livquik.aadharNotifier.title"
        classes="text-lg font-bold text-neutral-800"
      />

      <div className="flex flex-col">
        <Text
          translationKey="onboarding.onboardingSteps.livquik.aadharNotifier.description"
          classes="text-sm font-medium text-neutral-500"
        />

        <Text
          translationKey="onboarding.onboardingSteps.livquik.aadharNotifier.errorDescription"
          classes="text-sm font-medium text-danger-500"
        />
      </div>

      <img src={AadharIcon} alt="AadharImage" />
    </div>
  );
}

export default AadharNotfier;
