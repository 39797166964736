import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchAndSelectAccountingTransaction } from "@/store/reducers/accounting_transactions";

import { selectedAccountingTransactionSelector } from "@/store/selectors/accounting_transactions";
import { accountingEnabledSelector } from "@/store/selectors/client";

import Tabs from "@/components/core/Tabs";

import ActivityTab from "@/components/Accounting/Transactions/BillPay/Slider/ActivityTab";
import PaymentSummaryShort from "@/components/Accounting/Transactions/BillPay/Slider/PaymentSummaryShort";
import AccountingSliderSubmitButton from "@/components/Accounting/Transactions/common/Slider/AccountingSliderSubmitButton";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_TRANSACTION_PAGES } from "@/constants/accounting";
import { TABS } from "@/constants/common";

import BillPayTransactionOverviewTab from "../../BillPay/Slider/BillPayTransactionOverviewTab";
import { accountingActionHandler } from "../../common/util";

const selectedTabs = TABS;

export default function AccountingTransactionPayrollSlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const accountingId = parseInt(
    searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.payroll.id),
    10
  );

  const accountingEnabled = useSelector(accountingEnabledSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchAndSelectAccountingTransaction({
        accountingId,
      })
    );
  }, []);

  const payment = useSelector(selectedAccountingTransactionSelector);

  const [selectedTab, setSelectedTab] = useState(selectedTabs[0]);

  return payment ? (
    <>
      <div className="slider-content-core">
        <div className="mt-5 mb-6">
          <PaymentSummaryShort payment={payment} />
        </div>
        <div className="my-4">
          <Tabs
            items={selectedTabs}
            selectedTab={selectedTab?.key}
            setCurrentTab={(tab) => setSelectedTab(tab)}
            mode
          />
          <div className="mt-8" id="tabContent">
            {selectedTab.key === 1 ? (
              <BillPayTransactionOverviewTab
                payment={payment}
                context={BILL_PAYROLL_CONTEXT.PAYROLL}
              />
            ) : (
              <ActivityTab payment={payment} />
            )}
          </div>
        </div>
      </div>

      {accountingEnabled ? (
        <div className="slider-footer">
          <div className="flex items-center justify-end h-full px-6 py-4 bg-white">
            <AccountingSliderSubmitButton
              accountingStatus={payment?.accountingStatus}
              accountingId={accountingId}
              verifiable={payment?.verifiable}
              syncable={payment?.syncable}
              onClick={accountingActionHandler}
              page={ACCOUNTING_TRANSACTION_PAGES.PAYROLLS}
              accountingCtas={payment?.accountingCtas}
              notActionableReason={payment?.notActionableReason}
            />
          </div>
        </div>
      ) : null}
    </>
  ) : null;
}
