import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

/**
 * A custom checkbox component that supports indeterminate state and custom styles.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {boolean} props.indeterminate - If true, sets the checkbox to an indeterminate state.
 * @param {string} [props.className] - Additional CSS classes to apply to the checkbox.
 * @param {boolean} [props.checked] - If true, the checkbox is checked.
 * @param {boolean} [props.disabled] - If true, the checkbox is disabled.
 * @param {Function} [props.onChange] - Function to call when the checkbox state changes.
 */
function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = !rest?.checked && indeterminate;
    }
  }, [indeterminate]);

  const getLabelBefore = () => {
    return `before:appearance-none before:p-2  before:inline-block  before:align-middle  before:border-[1px] before:rounded-sm before:border-neutral-300 before:relative ${
      rest?.checked
        ? `${
            rest?.disabled
              ? "before:bg-neutral-200 before:border-neutral-200 "
              : "before:bg-primary-500 before:border-primary-500 "
          }`
        : `before:border-neutral-300 ${
            rest?.disabled ? "before:bg-neutral-200" : "before:bg-white "
          }`
    }`;
  };

  const getLabelAfter = () => {
    return `${
      rest?.checked
        ? "after:block after:absolute after:left-1.5 after:bottom-1 after:w-1.5 after:h-[11px] after:mt-1.5 after:border-white after:border-t-0 after:border-r-2 after:border-b-2 after:border-l-0 after:rotate-45"
        : ""
    }`;
  };

  const getCursorClass = () =>
    rest?.disabled ? "cursor-not-allowed" : "cursor-pointer";

  return (
    <label
      className={`relative ${getCursorClass()} ${getLabelBefore()} ${getLabelAfter()} flex gap-3 items-start`}
    >
      <input
        type="checkbox"
        onClick={(e) => e.stopPropagation()}
        ref={ref}
        className="absolute w-5 h-5 opacity-0 cursor-pointer "
        {...rest}
      />
    </label>
  );
}

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default IndeterminateCheckbox;
