import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Button from "@/components/core/Button";
import Progress from "@/components/core/Progress";
import Text from "@/components/core/Text";
import {
  amountToCurrency,
  commaFormattedNumber,
  dateToString,
  dateToTimeStampString,
} from "@/utils/common";

import { CARD_STATUS, ONE_TIME_RECUR_TEXT } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import LinkedToFrame from "../Common/LinkedToFrame";

export default function SpendOverview({
  card,
  showCardLimitBtn,
  showSwitchButton,
}) {
  const id = card?.id;
  const totalSpent = card?.totalSpent;
  const limit = card?.limit;
  const currency = card?.currency;
  const frequency = card?.freqeuncy;
  const available = card?.availableBalance;
  const linkedTo = card?.linkedTo;
  const project = card?.project;
  const cardHolder = card?.cardHolder ?? card?.walletHolder;
  const cardBudgetId = card?.cardBudgetId;
  const nextRecurAt = card?.nextRecurAt;

  const DETAILS_CARD_OVERVIEW = {
    spent: {
      color: "bg-corporate-cards-500",
      label: "cards.spendOverview.spent",
      currency,
      amount: totalSpent,
    },
    limit: {
      color: "bg-neutral-200",
      label: "cards.spendOverview.limit",
      frequency,
      currency,
      amount: limit,
      translationProps: { amountCurrency: amountToCurrency(limit, currency) },
    },
    availableBalance: {
      color: "bg-neutral-200",
      label: "cards.spendOverview.available",
      frequency,
      currency,
      amount: available,
    },
  };
  const configCardHeader = [
    DETAILS_CARD_OVERVIEW.spent,
    DETAILS_CARD_OVERVIEW.availableBalance,
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const showRecurAt = nextRecurAt && nextRecurAt?.length > 1;

  const recurAtTextKeys =
    nextRecurAt === ONE_TIME_RECUR_TEXT
      ? "cards.recurAt.oneTime"
      : "cards.recurAt.nextRecurAt";

  const recruAtTextProps =
    nextRecurAt === ONE_TIME_RECUR_TEXT
      ? {}
      : {
          date: dateToString(nextRecurAt),
          time: dateToTimeStampString(nextRecurAt, false, {
            hour: "numeric",
            minute: "numeric",
            replace: true,
          }),
        };

  const editLimitHandler = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.cards.editLimit, id);
    setSearchParams(searchParams);
  };

  return (
    <div className="p-0 card-wrapper">
      <LinkedToFrame
        linkedTo={linkedTo}
        showSwitchButton={showSwitchButton}
        project={project}
        cardHolder={cardHolder}
        cardBudgetId={cardBudgetId}
      />
      <div className="flex justify-between px-5 pt-5">
        {configCardHeader?.map((cardInfo, index) => (
          <div key={index}>
            <div className="flex items-center gap-3">
              <div
                className={`inline-block w-2 h-2 rounded-full ${cardInfo.color}`}
              />
              <Text
                translationKey={cardInfo.label}
                classes="text-sm text-neutral-800"
              />
            </div>
            <div>
              <span className="mr-1 text-xl font-bold text-neutral-800">
                {amountToCurrency(cardInfo.amount)}
              </span>
              <span className="text-base font-bold text-neutral-500">
                {cardInfo.currency}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="px-5 pt-3 pb-5 mt-2 ">
        <Progress
          classes="mb-6"
          data={{
            total: limit,
            items: [
              {
                variant: "corporate-cards",
                value:
                  card?.status === CARD_STATUS.BLOCKED
                    ? card?.totalSpent
                    : limit - available,
              },
            ],
          }}
          neutralTrack="neutral-track"
        />
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <Text
              translationKey={DETAILS_CARD_OVERVIEW.limit.label}
              translationProps={DETAILS_CARD_OVERVIEW.limit.translationProps}
              classes="text-lg font-medium "
            />

            {showRecurAt ? (
              <Text
                translationKey={recurAtTextKeys}
                translationProps={recruAtTextProps}
                classes="text-sm text-neutral-400"
              />
            ) : null}
          </div>

          {showCardLimitBtn ? (
            <Button
              size="md"
              type="default"
              classes="h-10 w-fit px-6 font-semibold "
              label="cards.listingFields.limit.tooltip"
              onClick={editLimitHandler}
              preIcon="Edit"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

SpendOverview.propTypes = {
  showSwitchButton: PropTypes.bool,
  showCardLimitBtn: PropTypes.bool,
  card: PropTypes.object,
};
