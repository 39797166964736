import React from "react";
import { useSelector } from "react-redux";

import { accountsSelector } from "@/store/selectors/app";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";

function SelectLoginEntity() {
  const entities = useSelector(accountsSelector);

  const handleSetUrlForSelectedEntity = (entity) => {
    if (entity.client_url && entity.client_url !== window.location.host) {
      window.localStorage.setItem(
        "api_subdomain",
        entity.api_subdomain || "api"
      );
      window.localStorage.setItem("client_url", entity.client_url);
      window.location.href = `https://${entity.client_url}/login?email=${entity.email}`;
    }
  };

  return (
    <div className="flex flex-col gap-4 h-[560px] overflow-scroll hide-scroll-bar hide-scroll-y-bar">
      {entities?.length
        ? entities?.map((entity, index) => (
            <div
              key={`select-app-entity-${entity?.subdomain}-${index}`}
              className="flex flex-col gap-4 cursor-pointer"
              onClick={() => handleSetUrlForSelectedEntity(entity)}
            >
              <div className="flex items-center">
                <ProfileWidget
                  name={entity?.subdomain}
                  textClasses="text-base font-semibold"
                />
                <Icon name="RightArrow" className="text-neutral-500" />
              </div>

              {index !== entities.length - 1 ? (
                <hr className="w-full border-t border-neutral-300" />
              ) : null}
            </div>
          ))
        : null}
    </div>
  );
}

export default SelectLoginEntity;
