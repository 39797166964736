export const FILE_MIME = {
  PNG: "image/png",
  JPG: "image/jpg",
  JPEG: "image/jpeg",
  GIF: "image/gif",
  PDF: "application/pdf",
};
export const FILE_VIEWER_TYPE_ICON = {
  [FILE_MIME.PNG]: { iconName: "Png", classes: "text-business-accounts-500" },
  [FILE_MIME.JPEG]: { iconName: "Png", classes: "text-business-accounts-500" },
  [FILE_MIME.JPG]: { iconName: "Png", classes: "text-business-accounts-500" },
  [FILE_MIME.PDF]: { iconName: "Pdf", classes: "text-danger-800" },
};

export const FILE_VIEWER_HEADER = {
  SECONDARY: "SECONDARY",
  PRIMARY: "PRIMARY",
};

export const DEFAULT_ACCEPT_WITH_PDF = {
  "image/jpeg": [".jpeg", ".jpg", ".heic"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "application/pdf": [".pdf"],
};

export const MAX_NUMBER_OF_FILES_ALLOWED = 10;
export const MAX_SIZE_OF_FILE_ALLOWED_MB = 25;
