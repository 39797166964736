import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

// This a UI (dumb) component
export default function StepPaginationButtons({
  classes = "flex w-full justify-end items-center gap-4 mt-3",
  currentPage,
  total,
  limit = 3,
  handleLeftButton = () => {},
  handleRightButton = () => {},
  leftDisabled = true,
  rightDisabled = false,
  showStepPaginationButtons = true,
}) {
  const translationProps = {
    firstItemOnPage: limit * (currentPage - 1) + 1,
    lastItemOnPage: currentPage * limit >= total ? total : limit * currentPage,
    totalItems: total || " ",
  };
  return showStepPaginationButtons ? (
    <div className={classes}>
      <Text
        classes="text-neutral-500 text-sm font-medium"
        translationKey={
          translationProps.firstItemOnPage === translationProps.lastItemOnPage
            ? "billPay.vendors.slider.showingPageXOfY"
            : "billPay.vendors.slider.showingPageX-XOfY"
        }
        translationProps={translationProps}
      />
      <div className="flex gap-4">
        <button onClick={handleLeftButton} disabled={leftDisabled}>
          <Icon
            className={`${
              leftDisabled
                ? "text-neutral-300 cursor-not-allowed"
                : "text-neutral-500 cursor-pointer"
            } `}
            name="LeftArrow"
          />
        </button>
        <button onClick={handleRightButton} disabled={rightDisabled}>
          <Icon
            className={`${
              rightDisabled
                ? "text-neutral-300 cursor-not-allowed"
                : "text-neutral-500 cursor-pointer"
            } `}
            name="RightArrow"
          />
        </button>
      </div>
    </div>
  ) : null;
}

StepPaginationButtons.propTypes = {
  currentPage: PropTypes.number,
  total: PropTypes.number,
  limit: PropTypes.number,
  handleLeftButton: PropTypes.func,
  handleRightButton: PropTypes.func,
  leftDisabled: PropTypes.bool,
  rightDisabled: PropTypes.bool,
  classes: PropTypes.string,
  showStepPaginationButtons: PropTypes.bool,
};
