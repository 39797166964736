import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useEffectSlider from "@/hooks/useEffectSlider";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import {
  clearBankDetailsForm,
  clearVendorCreateForm,
  createVendor,
  fetchAndSelectVendor,
  fetchVendorPaymentMethods,
  mergeIntoVendorCreateForm,
  setBankDetailsForm,
  setBankDetailsFormReviewRows,
  setSelectedVendor,
  setVendorCreateForm,
  setVendorCreateRequirements,
  updateVendor,
} from "@/store/reducers/vendors";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  accountingEnabledSelector,
  currentPaymentProviderSelector,
} from "@/store/selectors/client";
import { selectedPaymentAccountsSelector } from "@/store/selectors/payments";
import { accountingCategoryTagSelector } from "@/store/selectors/tags";
import {
  bankDetailsFormReviewRowsSelector,
  bankDetailsFormSelector,
  isFetchingSelectedVendorSelector,
  isFetchingVendorPaymentMethodsSelector,
  isLoadingSelector,
  isUpdatedBankDetailsSelector,
  selectedVendorSelector,
  vendorCreateFormSelector,
  vendorPaymentMethodsSelector,
} from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import ProfileWidget from "@/components/core/ProfileWidget";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";

import {
  formValueFromExactValue,
  generatePayloadFromFormValue,
} from "@/components/GenericForm/common";
import Footer from "@/components/common/BillPayAndPayroll/VendorOrEmployee/CreateVendor/CreateVendorInit/Footer";
import HelpNote from "@/components/common/BillPayAndPayroll/VendorOrEmployee/CreateVendor/CreateVendorInit/HelpNote";
import InitialInputFields from "@/components/common/BillPayAndPayroll/VendorOrEmployee/CreateVendor/CreateVendorInit/InitialInputFields";
import LinkVendorTo from "@/components/common/BillPayAndPayroll/VendorOrEmployee/CreateVendor/CreateVendorInit/LinkVendorTo";
import ContactInformation from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/ContactInformation";
import { ERROR_VALUE, useForm } from "@/utils/useForm";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import {
  REGEX_VALUE,
  USE_FORM_TYPE_OF_VALIDATION,
} from "@/utils/constantUseForm";
import {
  camelToSnake,
  checkTwoObjectAreExactlySame,
  deepCamelToSnake,
  flattenObject,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { VENDOR_CLASS } from "@/constants/common";
import {
  ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP,
  ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM,
} from "@/constants/company";
import { PAYMENT_PROVIDERS } from "@/constants/provider";
import { TAG_FIELD_TYPES } from "@/constants/tags";
import {
  PAYMENT_PROVIDER_DATA_KEYS,
  VENDOR_CREATE_FINAL_PAYLOAD_PARAMS,
  VENDOR_CREATE_PARAMS,
  VENDOR_CREATE_REQUIREMENTS_PARAMS,
  VENDOR_LINKED_TO_TYPES,
  VENDOR_STATUS,
  VENDOR_TYPES,
  VENDOR_UPDATE_REQUEST_PARAMS,
} from "@/constants/vendors";

import EditVendorConfirmationModal from "../EditVendorConfirmationModal";
import "./index.scss";

/**
 * @param {String} context - enum to detect payroll/payment (BILL_PAYROLL_CONTEXT)
 * @param {String} searchParamKey - last query param (i.e. param Global slider used to mount this component)
 *
 */
export default function CreateVendorInit({
  context,
  searchParamKey,
  setOnClose,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [modalPayload, setModalPayload] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  // for duplicate email detect, to avoid js cyclic code issue,
  // useForm outputs something that I need to use in next render cycle
  const dataRef = useRef();

  const accountingEnabled = useSelector(accountingEnabledSelector);

  const accountingVendorOptions = useSelector(accountingVendorsSelector);

  const vendor = useSelector(selectedVendorSelector);
  const isBeneficiaryDetailsPresent = vendor?.bankDetailsPresent;

  const isVendorVerified =
    vendor && vendor?.status !== VENDOR_STATUS.UNVERIFIED;
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

  const id =
    searchParams.get(SLIDERS_SEARCH_PARAMS.employees.id) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.vendors.id) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.employees.edit) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.vendors.edit);

  const isEditMode = !!(
    searchParams.get(SLIDERS_SEARCH_PARAMS.vendors.edit) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.employees.edit)
  );

  const isOverrideEditMode = !!(
    searchParams.get(SLIDERS_SEARCH_PARAMS.vendors.overrideEdit) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.employees.overrideEdit)
  );

  const createMode =
    searchParams.get(SLIDERS_SEARCH_PARAMS.vendors.create) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.employees.create);
  let newVendorNameFromBillpay =
    searchParams.get(SLIDERS_SEARCH_PARAMS.vendors.create) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.employees.create); // billpay trying to create a new vendor
  newVendorNameFromBillpay =
    newVendorNameFromBillpay === "true" ? "" : newVendorNameFromBillpay;

  const isUpdatedBankDetails = useSelector(isUpdatedBankDetailsSelector);
  const isLoading = useSelector(isLoadingSelector);

  useEffectSlider(
    () => {
      if (isEditMode || isOverrideEditMode) {
        dispatch(
          fetchAndSelectVendor({
            id,
            flag: inPayrollContext,
            not_humanized: true,
          })
        );
      } else dispatch(setSelectedVendor(null));

      return () => {
        dispatch(setSelectedVendor(null));
        dispatch(setBankDetailsFormReviewRows([]));
        dispatch(clearBankDetailsForm());
        dispatch(clearVendorCreateForm());
        dispatch(setBankDetailsForm(null));
        dispatch(setVendorCreateRequirements(null));
      };
    },
    [],
    { searchParamKey, setOnClose }
  );

  const {
    id: tagId = -1,
    fieldType: accountingCategoryType,
    options: accountingCategoryOptions = [],
    textValue: accountingCategoryTextValue = "",
  } = useSelector(accountingCategoryTagSelector) ?? {};
  const accountingCategoryTypeIsText =
    accountingCategoryType === TAG_FIELD_TYPES.TEXT;
  const paymentAccount = useSelector(selectedPaymentAccountsSelector);
  const isFetchingSelectedVendor = useSelector(
    isFetchingSelectedVendorSelector
  );

  const paymentMethods = useSelector(vendorPaymentMethodsSelector);
  const isFetchingPaymentMethods = useSelector(
    isFetchingVendorPaymentMethodsSelector
  );
  const currentPaymentProvider = useSelector(currentPaymentProviderSelector);

  // derived stuff, states for the component
  const inSingaporeWithWallex =
    currentPaymentProvider === PAYMENT_PROVIDERS.WALLEX;
  const storedInitialFormValue = useSelector(vendorCreateFormSelector) ?? {};
  const bankDetailsForm = useSelector(bankDetailsFormSelector);
  const [isBankDetailsOpen, setIsBankDetailsOpen] = useState(false);
  const [manuallyAddBankDetails, setManuallyAddBankDetails] = useState(true);

  const [submitOK, setSubmitOK] = useState(false);
  const [numberOfContacts, setNumberOfContacts] = useState(
    Object.keys(storedInitialFormValue).reduce(
      (accum, k) =>
        accum +
        (k.startsWith(VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY) &&
        k.includes(VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.EMAIL)
          ? 1
          : 0),
      0
    ) || 1
    // divide by 3 because each entry has id, emailKey, nameKey, phoneKey
  );

  // form validation hook
  const initialFormValueLocalVariable = {
    // could be used by accounting vendor <Input /> or VpSelect if creatable was used
    [VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.VENDOR_NAME]: {
      value:
        storedInitialFormValue?.[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.VENDOR_NAME]
          ?.value ??
        (newVendorNameFromBillpay || vendor?.name) ??
        "",
      validate: { required: true },
    },
    [VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.TAX_NUMBER]: {
      value:
        storedInitialFormValue?.[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.TAX_NUMBER]
          ?.value ?? "",
      validate: { required: false },
    },
    ...Array(numberOfContacts)
      .fill(null)
      .reduce((accum, _, index) => {
        const contactId = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.ID}`;
        const emailKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.EMAIL}`;
        const nameKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.NAME}`;
        const phoneKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.PHONE}`;
        const destroyKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${index}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES._DESTROY}`;

        accum = {
          ...accum,
          [contactId]: {
            value:
              storedInitialFormValue?.[contactId]?.value ??
              vendor?.contactDetails[index]?.id ??
              "",
            validate: {
              required: false,
            },
          },
          [emailKey]: {
            value:
              storedInitialFormValue?.[emailKey]?.value ??
              vendor?.contactDetails[index]?.email ??
              "",
            validate: {
              regex: REGEX_VALUE.email,
              required: true,
            },
            errorStatement: {
              regex: ERROR_VALUE.email.regex,
            },
          },
          [nameKey]: {
            value:
              storedInitialFormValue?.[nameKey]?.value ??
              vendor?.contactDetails[index]?.name ??
              "",
            validate: { required: false },
          },
          [phoneKey]: {
            value:
              storedInitialFormValue?.[phoneKey]?.value ??
              vendor?.contactDetails[index]?.phone ??
              "",
            validate: {
              required: false,
              // [USE_FORM_TYPE_OF_VALIDATION.phoneNumber]: "IN", TODO: postponed after testing
            },
          },
          [destroyKey]: {
            value: storedInitialFormValue?.[destroyKey]?.value ?? false,
            validate: {
              required: false,
              // [USE_FORM_TYPE_OF_VALIDATION.phoneNumber]: "IN", TODO: postponed after testing
            },
          },
        };

        return accum;
      }, {}),
    // accountingEnabled
    ...(accountingEnabled
      ? {
          [VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.SELECTED_VENDOR]: {
            value:
              storedInitialFormValue?.[
                VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.SELECTED_VENDOR
              ]?.value ||
              (isEditMode || isOverrideEditMode
                ? vendor?.accountingPayeeId
                  ? vendor?.accountingPayeeId
                  : -1
                : "") || // billpay trying to create a new vendor, this fills the first time, then user actions determine value
              "",
            validate: {
              required: true,
            },
          },
        }
      : {}),
    [VENDOR_UPDATE_REQUEST_PARAMS.VENDOR_TYPE]: {
      value:
        storedInitialFormValue?.[VENDOR_UPDATE_REQUEST_PARAMS.VENDOR_TYPE]
          ?.value ??
        vendor?.type ??
        (inPayrollContext ? VENDOR_TYPES.INDIVIDUAL : VENDOR_TYPES.CORPORATE),
      validate: {
        required: true,
        [USE_FORM_TYPE_OF_VALIDATION.oneOf]: [
          VENDOR_TYPES.CORPORATE,
          VENDOR_TYPES.INDIVIDUAL,
        ],
      },
      [USE_FORM_TYPE_OF_VALIDATION.oneOf]: "errorMessage.notASupportedValue",
    },
    // accountingEnabled
    ...(accountingEnabled
      ? {
          accountingCategory: {
            value:
              storedInitialFormValue?.accountingCategory?.value ||
              (accountingCategoryTypeIsText
                ? vendor?.accountingTags?.[0]?.textValue
                : vendor?.accountingTags?.[0]?.tagValueId) ||
              (accountingCategoryTypeIsText ? accountingCategoryTextValue : ""),
            validate: { required: false },
          },
          accountingCategoryId: {
            // BE key
            value: vendor?.accountingTags?.[0]?.id,
            validate: { required: false },
          },
        }
      : {}),
    [VENDOR_CREATE_PARAMS.VENDOR_OWNER_ID]: {
      value:
        storedInitialFormValue?.[VENDOR_CREATE_PARAMS.VENDOR_OWNER_ID]?.value ??
        vendor?.owner?.id ??
        "",
      validate: { required: true },
    },

    // radio value (one is always selected so no validaion required)
    linkedTo: {
      value: storedInitialFormValue?.projectId?.value
        ? storedInitialFormValue?.linkedTo?.value ===
          VENDOR_LINKED_TO_TYPES.DEPARTMENT
          ? VENDOR_LINKED_TO_TYPES.DEPARTMENT
          : VENDOR_LINKED_TO_TYPES.PROJECT
        : vendor?.projectId === vendor?.owner?.departmentId
          ? VENDOR_LINKED_TO_TYPES.DEPARTMENT
          : VENDOR_LINKED_TO_TYPES.PROJECT,
      // prefill in create, edit mode will be fine. Ok.
      validate: {
        required: true,
      },
    },
    // dropdown, will be hidden if radio type department is selected
    // if visible it is required
    projectId: {
      value: storedInitialFormValue?.projectId?.value || vendor?.projectId, // prefill in create, edit mode will be fine. Ok.
      validate: {
        required: true,
      },
    },
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.SENDER_CURRENCY]: {
      value:
        storedInitialFormValue?.[
          VENDOR_CREATE_REQUIREMENTS_PARAMS.SENDER_CURRENCY
        ]?.value ??
        vendor?.senderCurrency ??
        "",
      validate: { required: true },
    },
    [VENDOR_CREATE_PARAMS.EMPLOYEE_ID]: {
      value:
        storedInitialFormValue?.[VENDOR_CREATE_PARAMS.EMPLOYEE_ID]?.value ??
        vendor?.employeeId ??
        "",
      validate: { required: inPayrollContext },
    },

    ...(inSingaporeWithWallex && bankDetailsForm
      ? {}
      : {
          [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]: {
            value:
              storedInitialFormValue?.[
                VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY
              ]?.value ||
              (isEditMode || isOverrideEditMode
                ? vendor?.beneficiaryCountry
                : ""),
            validate: { required: true },
          },

          [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]: {
            value:
              storedInitialFormValue?.[
                VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY
              ]?.value ||
              (isEditMode || isOverrideEditMode
                ? vendor?.beneficiaryCurrency
                : ""),

            validate: { required: true },
          },
          [VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.PAYMENT_METHOD]: {
            value:
              storedInitialFormValue?.[
                VENDOR_CREATE_REQUIREMENTS_PARAMS.PAYOUT_METHOD_TYPE
              ]?.value ||
              (isEditMode || isOverrideEditMode
                ? vendor?.paymentMethodType
                : ""),

            validate: { required: true },
          },
          [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY]: {
            value:
              storedInitialFormValue?.[
                VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_BANK_COUNTRY
              ]?.value ||
              (isEditMode || isOverrideEditMode
                ? vendor?.beneficiaryBankCountry
                : ""),

            validate: { required: true },
          },
        }),
    ...(bankDetailsForm
      ? {}
      : {
          bankFormFilled: {
            value: vendor?.bankDetailsPresent ?? "",
            validate: { required: manuallyAddBankDetails },
          },
        }),
  };

  const onSubmit = () => {
    // save main form
    dispatch(
      mergeIntoVendorCreateForm(
        formValueFromExactValue(values, storedInitialFormValue)
      )
    );
    setSubmitOK(true);
  };

  const {
    handleChange,
    values,
    errors,
    _setValues: setValues,
    isFormButtonDisabled,
    handleSubmit,
  } = useForm(initialFormValueLocalVariable, onSubmit, {
    isFetchingInitialValue: false,
    formId: "create-vendor-main-slider-form",
    extraErrorConditions: manuallyAddBankDetails && !bankDetailsForm, // form should be filled, bank details filling is not needed for edit flow
  });

  // CTAs
  const discard = () => {
    searchParams.delete(searchParamKey);
    setSearchParams(searchParams);
  };

  // buttons
  const openAddBankDetailsPage = () => {
    // save main form
    dispatch(
      mergeIntoVendorCreateForm(
        formValueFromExactValue(values, storedInitialFormValue)
      )
    );

    searchParams.append(
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.employees.preAddBankDetails
        : SLIDERS_SEARCH_PARAMS.vendors.preAddBankDetails,
      true
    );
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (accountingEnabled) dispatch(fetchAccountingVendors());
  }, [accountingEnabled]);

  const isPrefilledOnce = useRef(false);
  useEffect(() => {
    // ensuring this filling is done only once,
    // and for the rest of the flow, i.e. coming back and moving forward in sliders
    // ensure that user actions determine UI
    if (
      !isPrefilledOnce.current &&
      !bankDetailsForm &&
      !isUpdatedBankDetails &&
      (isEditMode || isOverrideEditMode) &&
      vendor?.bankDetailsNotHumanized &&
      id?.toString() === vendor?.id?.toString() // don't consume old vendor
    ) {
      const bankDetailsValue = {
        [PAYMENT_PROVIDER_DATA_KEYS.REQUEST.BENEFICIARY]:
          vendor?.bankDetailsNotHumanized,
        [PAYMENT_PROVIDER_DATA_KEYS.REQUEST.OTHER]:
          vendor?.otherDetailsNotHumanized,
      };
      dispatch(setBankDetailsForm(bankDetailsValue)); // save structure as if it was like during creation
      isPrefilledOnce.current = true;
    }
  }, [
    isPrefilledOnce.current,
    bankDetailsForm,
    vendor,
    isUpdatedBankDetails,
    isEditMode,
    isOverrideEditMode,
  ]);

  // fetch payment methods
  // declaring outside effect, to observe if all dependencies are present
  const paymentMethodsPayload = generatePayloadFromFormValue({
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY]:
      values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_COUNTRY],
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY]:
      values?.[VENDOR_CREATE_REQUIREMENTS_PARAMS.BENEFICIARY_CURRENCY],
    [VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE]:
      values?.[VENDOR_UPDATE_REQUEST_PARAMS.VENDOR_TYPE],
  });

  useEffect(() => {
    if (values?.beneficiary_country && values?.currency) {
      if (Object.values(paymentMethodsPayload).every((item) => item)) {
        dispatch(
          fetchVendorPaymentMethods({
            context: BILL_PAYROLL_CONTEXT.BILLPAY,
            payload: paymentMethodsPayload,
          })
        );
      }
    }
  }, [values?.beneficiary_country, values?.currency]);

  const scrollRef = useRef();

  useEffect(() => {
    if ((isEditMode || isOverrideEditMode) && isBeneficiaryDetailsPresent) {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [!!scrollRef?.current?.scrollIntoView, isBeneficiaryDetailsPresent]);

  const bankDetailsRowsFromForm = useSelector(
    bankDetailsFormReviewRowsSelector
  );

  // (in edit mode + user filled nothing / user edited something or create mode)
  // used for UI showing only
  const bankDetailsArray =
    (isEditMode || isOverrideEditMode) && bankDetailsRowsFromForm.length === 0
      ? Object.entries(
          flattenObject({
            ...vendor?.bankDetails,
            ...vendor?.otherDetails,
          })
        )
          .map(([k, v]) => ({
            name: k,
            value: v,
            label: t(ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP[k]),
          }))
          .filter((item) => typeof item.value !== typeof {})
      : bankDetailsRowsFromForm?.filter((item) => !item.hidden);

  // show all contacts
  useEffect(() => {
    if (isEditMode || isOverrideEditMode) {
      setNumberOfContacts(vendor?.contactDetails?.length || 1);
    }
  }, [vendor]);
  // final call after submit. Doing this to get the latest store
  useEffect(() => {
    if (submitOK) {
      const isDuplicateEmailPresent =
        dataRef.current.duplicateEmailStates.length &&
        dataRef.current.duplicateEmailStates.some(Boolean);
      if (isDuplicateEmailPresent) {
        setSubmitOK(false);
        return;
      }

      const k = VENDOR_CREATE_FINAL_PAYLOAD_PARAMS;
      // creating copy only for contactDetails deletion, but its needed for other details too
      const copyOfStoredInitialFormValue = structuredClone(
        storedInitialFormValue
      );
      Array(numberOfContacts)
        .fill(null)
        .forEach((_, idx) => {
          const contactId = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${idx}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.ID}`;
          const emailKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${idx}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.EMAIL}`;
          const nameKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${idx}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.NAME}`;
          const phoneKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${idx}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES.PHONE}`;
          const destroyKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${idx}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES._DESTROY}`;

          if (copyOfStoredInitialFormValue[destroyKey].value) {
            delete copyOfStoredInitialFormValue[contactId];
            delete copyOfStoredInitialFormValue[emailKey];
            delete copyOfStoredInitialFormValue[nameKey];
            delete copyOfStoredInitialFormValue[phoneKey];
            delete copyOfStoredInitialFormValue[destroyKey];
          }
        });

      const finalFormValue = {
        // most values as is
        ...copyOfStoredInitialFormValue,
        // department or project id
        // need to send only project_id, type is not needed
        // prefill logic is department if it matches current users department
        [k.LINKED_TO.PROJECT_ID]: storedInitialFormValue?.projectId?.value,
        // if email flow
        ...(manuallyAddBankDetails ? {} : { [k.MAIL_ONBOARDING]: true }),
      };
      delete finalFormValue.projectId;
      delete finalFormValue.linkedTo;

      // 1. add software accounting vendor tags
      const accountingCategoryPayloadValue = accountingEnabled
        ? [
            {
              [k.ACCOUNTING_VENDOR_TAG.TAG_ID]: tagId,
              [k.ACCOUNTING_VENDOR_TAG.TAG_ID]: tagId,
              [accountingCategoryTypeIsText
                ? k.ACCOUNTING_VENDOR_TAG.TAG_TEXT_VALUE
                : k.ACCOUNTING_VENDOR_TAG.TAG_VALUE_ID]:
                values.accountingCategory,
              ...(values?.accountingCategoryId
                ? {
                    [k.ACCOUNTING_VENDOR_TAG.ID]: values?.accountingCategoryId,
                  }
                : {}),
            },
          ]
        : []; // NOTE: multiple tags are possible in the future
      finalFormValue[k.ACCOUNTING_VENDOR_TAG.KEY] =
        accountingCategoryPayloadValue;
      delete finalFormValue.accountingCategory;
      delete finalFormValue.accountingCategoryId;

      // 2. Add payment_provider_data (bank details) - in stringified JSON, i.e. backslashes

      const currentBankDetails = generatePayloadFromFormValue(
        deepCamelToSnake({
          beneficiary: vendor?.bankDetailsNotHumanized,
          sender: vendor?.otherDetailsNotHumanized,
        })
      );

      const storedBankDetails = generatePayloadFromFormValue(
        deepCamelToSnake(bankDetailsForm)
      );

      const flattenedCurrentBankDetails = flattenObject(currentBankDetails);
      const flattenedStoredBankDetails = flattenObject(storedBankDetails);

      const filteredCurrentBankDetails = Object.keys(
        flattenedCurrentBankDetails
      )
        .filter((key) => Object.keys(flattenedStoredBankDetails).includes(key))
        .reduce((obj, key) => {
          obj[key] = flattenedCurrentBankDetails[key];
          return obj;
        }, {});

      if (
        !checkTwoObjectAreExactlySame(
          flattenObject(filteredCurrentBankDetails),
          flattenObject({
            ...storedBankDetails.beneficiary,
            ...storedBankDetails?.sender,
          })
        )
      ) {
        finalFormValue[k.PAYMENT_PROVIDER_DATA] = JSON.stringify(
          generatePayloadFromFormValue(deepCamelToSnake(bankDetailsForm))
        );
      }

      finalFormValue[k.VENDOR_CLASS] = inPayrollContext
        ? VENDOR_CLASS.PAYROLL
        : VENDOR_CLASS.USER_CREATED;

      // 4. Send only one  - alias_name or accounting_payee_id
      // to avoid sending both keys
      //
      // Scenarios:
      // <Input> only, fine.
      //
      // <VpSelect>
      //   nothing created
      //     - existing selected, fine.
      //   created some
      //     - selected created one (both id and name exist now). Remove id.
      //     - selected existing one (both id and name exist now). Remove name.

      // if dropdown
      if (accountingEnabled) {
        if (
          values[
            VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.SELECTED_VENDOR
          ]?.toString() === (-1).toString()
        ) {
          finalFormValue[k.SELECTED_VENDOR] = null;
        }

        // LET both category vendor and alias_name go, i.e. remove nothing
        // const removeAccountingId =
        //   finalFormValue[k.SELECTED_VENDOR]?.value?.toString() ===
        //   (-1).toString(); // creatable has been selected
        //
        // delete finalFormValue[
        //   removeAccountingId ? k.SELECTED_VENDOR : k.VENDOR_NAME
        // ];
      }

      const payload = generatePayloadFromFormValue(finalFormValue);

      const onSuccess = () => {
        discard();

        if (showModal) {
          setShowModal(false);
        }
      };

      const onError = () => {
        setSubmitOK(false);
      };
      if (createMode) {
        dispatch(createVendor({ payload, context, onSuccess, onError }));
      } else if (payload?.vendor_owner_id !== vendor?.owner?.id) {
        setShowModal(true);
        setModalPayload({ payload, context, onSuccess, onError, id });
      } else {
        dispatch(updateVendor({ id, payload, context, onSuccess, onError }));
      }
    }
  }, [submitOK]);

  const sliderTitle =
    isEditMode || isOverrideEditMode
      ? inPayrollContext
        ? "payroll.payrollEmployees.addEmployee.editEmployee"
        : "billPay.vendors.createVendor.editVendor"
      : inPayrollContext // create mode (below)
        ? "payroll.payrollEmployees.addEmployee.title"
        : "billPay.vendors.createVendor.title";

  const sliderDescription =
    isEditMode || isOverrideEditMode
      ? inPayrollContext
        ? "payroll.payrollEmployees.addEmployee.editEmployeeHelperText"
        : "billPay.vendors.createVendor.editVendorHelperText"
      : inPayrollContext // create mode (below)
        ? "payroll.payrollEmployees.addEmployee.infoText"
        : "billPay.vendors.createVendor.infoText";

  const ref = useLeftHeaderTitle({
    title: sliderTitle,
  });

  return (
    <>
      <div className="pb-16 px-9 slider-content-core">
        <form onSubmit={handleSubmit} id="create-vendor-main-slider-form">
          <div className="font-medium">
            {/* Title */}
            <div className="flex flex-col">
              <Text
                refProp={ref}
                translationKey={sliderTitle}
                classes="text-3xl font-bold text-neutral-800"
              />
              <Text
                translationKey={sliderDescription}
                classes="text-sm text-neutral-500"
              />
              {isFetchingSelectedVendor ? (
                <LoaderSkeleton fullWidth size={[24]} />
              ) : null}
            </div>
            {/* Input Fields */}
            <div className="mt-8">
              <InitialInputFields
                {...{
                  values,
                  errors,
                  handleChange,
                  setValues,
                  inPayrollContext,
                  isEditMode: !!(isEditMode || isOverrideEditMode),
                }}
              />
            </div>
            {/* Note Section */}
            {inPayrollContext ? null : (
              <div className="mt-6">
                <HelpNote />
              </div>
            )}

            {/* Contact info */}
            <div className="mt-8">
              <ContactInformation
                {...{
                  values,
                  errors,
                  handleChange,
                  dataRef,
                  numberOfContacts,
                  setNumberOfContacts,
                  inPayrollContext,
                  isEditMode: isEditMode || isOverrideEditMode,
                  disabled: isEditMode || isOverrideEditMode, // as of now, create slider is for bank edits only
                  onAdd: () => {
                    // have to do this, since a useState variable is used inside useForm argument
                    // otherwise, the form values will be reset
                    dispatch(
                      mergeIntoVendorCreateForm(
                        formValueFromExactValue(values, storedInitialFormValue)
                      )
                    );
                    // potentially dirty (not validated) values in Redux
                    // there will be a check before submitting, of course

                    setNumberOfContacts((p) => p + 1);
                  },
                  onRemove: (idx) => {
                    // blank out the form entry
                    const destroyKey = `${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.KEY}.${idx}.${VENDOR_UPDATE_REQUEST_PARAMS.CONTACTS_DETAILS.VALUES._DESTROY}`;

                    const initialFormValueForNextRender =
                      formValueFromExactValue(
                        { ...values, [destroyKey]: true },
                        storedInitialFormValue
                      );

                    dispatch(
                      setVendorCreateForm(initialFormValueForNextRender)
                    );
                    setValues((prev) => ({ ...prev, [destroyKey]: true }));
                  },
                }}
              />
            </div>
            {/* Link vendor to */}

            {inPayrollContext ? null : (
              <div className="mt-8">
                <LinkVendorTo
                  values={values}
                  errors={errors}
                  setValues={setValues}
                  handleChange={handleChange}
                  // isEditMode={isEditMode || isOverrideEditMode}
                  // always editable
                  personId={values[VENDOR_CREATE_PARAMS.VENDOR_OWNER_ID]}
                  isVendorOwnerSelected={
                    !!values[VENDOR_CREATE_PARAMS.VENDOR_OWNER_ID]
                  }
                  metaData={{
                    title: "billPay.vendors.createVendor.linkVendorTitle",
                    desc: "billPay.vendors.createVendor.linkVendorTitleHelperText",
                    departmentHelperText:
                      "billPay.vendors.createVendor.departmentOptionHelperText",
                    projectHelperText:
                      "billPay.vendors.createVendor.projectOptionHelperText",
                    disabledTooltipText:
                      "billPay.vendors.createVendor.disabledProjectTooltipText",
                  }}
                />
              </div>
            )}

            {/* Bank details */}
            <div
              className="mt-8"
              ref={isEditMode || isOverrideEditMode ? scrollRef : null}
            >
              <Text
                translationKey="billPay.vendors.createVendor.bankDetails"
                classes="text-xl font-semibold text-neutral-800"
              />
              {/* Grey box (appears after bank details have been filled manually) */}
              {bankDetailsForm ? (
                <div className="p-4 mt-8 border rounded-lg border-neutral-200">
                  <div
                    className="flex items-center justify-between py-4 cursor-pointer"
                    onClick={() => setIsBankDetailsOpen((prev) => !prev)}
                  >
                    <ProfileWidget
                      name={
                        accountingVendorOptions?.find(
                          (item) =>
                            item.id ===
                            values[
                              VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.SELECTED_VENDOR
                            ]
                        )?.alias ||
                        values[VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.VENDOR_NAME]
                      }
                      showTooltip
                      tooltipClasses="w-120"
                      // TODO, what to print here
                      avatarSize="sm"
                    />
                    <div className="flex items-center justify-end gap-4 text-neutral-500">
                      <Icon
                        name={`${isBankDetailsOpen ? "UpArrow" : "DownArrow"}`}
                        className="transition-all ease-in cursor-pointer duration-600 stroke-neutral-500"
                      />
                    </div>
                  </div>

                  {/* Review rows of label, value */}
                  {isBankDetailsOpen ? (
                    <div className="flex flex-col">
                      {bankDetailsArray.map((row, idx) => {
                        let { label } = row;

                        if (
                          [
                            ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM.ACCOUNT_ROUTE_TYPE_1,
                            ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM.ACCOUNT_ROUTE_TYPE_2,
                          ]?.includes(row?.label)
                        ) {
                          label = "user.bankDetails.accountRoutingType";
                        }

                        if (
                          [
                            ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM.ACCOUNT_ROUTE_VALUE_1,
                            ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM.ACCOUNT_ROUTE_VALUE_2,
                          ]?.includes(row?.label)
                        ) {
                          label = "user.bankDetails.accountRoutingValue";
                        }
                        return (
                          <div
                            className="flex items-center justify-between py-4 accordion-row"
                            key={`bank-detail-key-${row?.id ?? idx}`}
                          >
                            <span className="text-sm text-neutral-500">
                              <Text translationKey={label} />
                            </span>
                            <span className=" w-[60%] max-w-md break-words whitespace-normal text-sm font-medium tracking-wider text-right text-neutral-800">
                              {row?.displayValue || row?.value || "-"}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}

                  {/* Note: delete bank details for editing them */}
                  <div className="note-card">
                    <div className="grid grid-cols-[auto_1fr] gap-x-6 gap-y-3">
                      <Icon name="Alert" className="text-neutral-500" />
                      <Text
                        classes="text-neutral-800 font-semibold text-base"
                        translationKey={
                          inPayrollContext
                            ? "payroll.payrollEmployees.addEmployee.deleteBankDetailsHelper"
                            : "billPay.vendors.createVendor.deleteBankDetailsHelper"
                        }
                      />
                      <p />
                      <span
                        onClick={() => {
                          // save form state
                          dispatch(
                            mergeIntoVendorCreateForm(
                              formValueFromExactValue(
                                values,
                                storedInitialFormValue
                              )
                            )
                          );
                          // clear bank details
                          dispatch(clearBankDetailsForm());
                        }}
                      >
                        <Text
                          translationKey={
                            inPayrollContext
                              ? "payroll.payrollEmployees.addEmployee.deleteBankDetailsText"
                              : "billPay.vendors.createVendor.deleteBankDetailsText"
                          }
                          classes="text-danger-500 font-semibold text-sm cursor-pointer"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* Beneficiary bank account details */}
              {bankDetailsForm ? null : (
                <div className="flex flex-col gap-6 p-4 mt-6 rounded-lg bg-neutral-50">
                  <div className="flex flex-col gap-1">
                    <Text
                      translationKey="billPay.vendors.createVendor.bankAccountDetails"
                      classes="text-base text-neutral-800 font-semibold"
                    />
                    <Text
                      translationKey={
                        inPayrollContext
                          ? "payroll.payrollEmployees.addEmployee.bankAccountDetailsHelperText"
                          : "billPay.vendors.createVendor.bankAccountDetailsHelperText"
                      }
                      classes="text-xs font-semibold text-neutral-500"
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    {isVendorVerified ? null : (
                      <Radio
                        name="bankFormFilled"
                        error={
                          manuallyAddBankDetails ? errors.bankFormFilled : ""
                          // useForm/current-store-logic doesnt update error, so this workaround. Does not impact validation.
                        }
                        value={values.bankFormFilled}
                        isChecked={manuallyAddBankDetails}
                        handleChange={() => {
                          // have to do this, since a useState variable is used inside useForm argument
                          // otherwise, the form values will be reset
                          dispatch(
                            mergeIntoVendorCreateForm(
                              formValueFromExactValue(
                                values,
                                storedInitialFormValue
                              )
                            )
                          );
                          setManuallyAddBankDetails(true);
                        }}
                        label={
                          <div className="flex flex-col ml-1">
                            <Text
                              translationKey="billPay.vendors.createVendor.manuallyAddBankDetails"
                              classes="text-base text-neutral-800 font-semibold"
                            />
                            <Text
                              translationKey="billPay.vendors.createVendor.manuallyAddBankDetailsHelperText"
                              classes="text-sm font-medium text-neutral-500"
                            />
                          </div>
                        }
                      />
                    )}
                    <div>
                      <Button
                        className={`text-primary-600 border border-neutral-300 py-1.5 px-5
                      text-sm font-medium rounded-lg flex items-center gap-2 cursor-pointer ${
                        isFormButtonDisabled || !manuallyAddBankDetails
                          ? "disabled:opacity-50 disabled:cursor-not-allowed"
                          : "bg-white"
                      }`}
                        onClick={openAddBankDetailsPage}
                        disabled={!manuallyAddBankDetails}
                        variant="tertiary"
                        label="billPay.vendors.createVendor.addBankDetails"
                        preIcon="Add"
                        iconClasses="-mr-1"
                        innerClasses="gap-1 font-bold text-xs"
                        error={manuallyAddBankDetails ? "misc.equired" : ""}
                      />
                      {manuallyAddBankDetails && isVendorVerified ? (
                        <Text
                          classes="ml-10 inline-block text-xs text-danger-600"
                          translationKey="misc.required"
                        />
                      ) : null}
                    </div>
                  </div>
                  {isVendorVerified ? null : (
                    <Radio
                      isChecked={!manuallyAddBankDetails}
                      handleChange={() => {
                        // have to do this, since a useState variable is used inside useForm argument
                        // otherwise, the form values will be reset
                        dispatch(
                          mergeIntoVendorCreateForm(
                            formValueFromExactValue(
                              values,
                              storedInitialFormValue
                            )
                          )
                        );
                        setManuallyAddBankDetails(false);
                      }}
                      label={
                        <div className="flex flex-col ml-1">
                          <Text
                            translationKey="billPay.vendors.createVendor.requestViaEmail"
                            classes="text-base text-neutral-800 font-semibold"
                          />
                          <Text
                            translationKey={
                              inPayrollContext
                                ? "payroll.payrollEmployees.addEmployee.requestViaEmailHelperText"
                                : "billPay.vendors.createVendor.requestViaEmailHelperText"
                            }
                            classes="text-sm font-medium text-neutral-500"
                          />
                        </div>
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </form>
        {/* Footer */}
      </div>
      <Footer
        formId="create-vendor-main-slider-form"
        isEditMode={isEditMode || isOverrideEditMode}
        onDiscard={discard}
        inPayrollContext={inPayrollContext}
        isLoading={isLoading}
      />

      {showModal ? (
        <EditVendorConfirmationModal
          setShowModal={setShowModal}
          showModal={showModal}
          payload={modalPayload}
          isLoading={isLoading}
          extraOnCloseFunctionCall={() => {
            setSubmitOK(false);
          }}
        />
      ) : null}
    </>
  );
}

CreateVendorInit.propTypes = {
  context: PropTypes.string,
  searchParamKey: PropTypes.string,
  setOnClose: PropTypes.func,
};
