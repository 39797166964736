import PropTypes from "prop-types";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";

import { isTransactionStatusLoadingSelector } from "@/store/selectors/accounting_transactions";
import {
  accountingContinuousBillSyncSelector,
  accountingIntegrationSoftwareSelector,
} from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import {
  ACCOUNTING_PAGES_TO_PARAMS_MAP,
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_STATUS_CTA_MAP,
  ACCOUNTING_TRANSACTION_CTA_KEYS,
  ACCOUNTING_TRANSACTION_CTA_MAP,
  ACCOUNTING_TRANSACTION_PAGES,
  ACCOUNTING_TRANSACTION_STATUS,
} from "@/constants/accounting";
import { ALL_ALLOWED_CTAS } from "@/constants/expense";

import { getActionAndIcon } from "../util";

export default function AccountingSliderSubmitButton({
  accountingStatus,
  accountingId,
  syncable,
  verifiable,
  onClick,
  page,
  accountingCtas,
  notActionableReason,
}) {
  const dispatch = useDispatch();
  const popupRef = useRef();
  const isLoading = useSelector(isTransactionStatusLoadingSelector);
  const accountingContinuousBillSync = useSelector(
    accountingContinuousBillSyncSelector
  );
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const billPayWithContinousSync =
    page === ACCOUNTING_TRANSACTION_PAGES.BILL_PAY &&
    accountingContinuousBillSync;

  const actionDisabled = [
    ACCOUNTING_TRANSACTION_STATUS.PENDING,
    ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED,
  ].includes(accountingStatus)
    ? !verifiable
    : !syncable;

  const exportable = accountingSoftware === ACCOUNTING_SOFTWARES.UNIVERSAL_CSV;
  const resyncButton =
    billPayWithContinousSync &&
    [
      ACCOUNTING_TRANSACTION_STATUS.VERIFIED,
      ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED,
    ].includes(accountingStatus);

  const [action, _] = getActionAndIcon(
    exportable,
    accountingCtas,
    resyncButton,
    accountingStatus
  );

  const MoreOptionConfig =
    accountingCtas?.includes(ACCOUNTING_TRANSACTION_CTA_KEYS.MARK_AS_SYNCED) &&
    !exportable
      ? [
          ACCOUNTING_TRANSACTION_CTA_MAP[
            ACCOUNTING_TRANSACTION_CTA_KEYS.MARK_AS_SYNCED
          ],
        ]
      : null;
  const transactionType = ACCOUNTING_PAGES_TO_PARAMS_MAP[page];
  const tooltipId = `transaction-slider-no-verify-reason-tooltip-text-${accountingId}`;

  return (
    <div className="flex w-full items-center gap-6 text-base font-semibold">
      <div className="flex items-center gap-6 w-full">
        {accountingStatus === ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED ? (
          <div className="flex items-center gap-1 w-10/12">
            <Text
              translationKey="expenses.transactionUnverified"
              classes="text-sm font-semibold text-neutral-600"
            />
            {/* <div id="sync-failed-accounting-slider-button">
              <Icon name="Info" className="cursor-pointer text-neutral-200" />
            </div>
            <Tooltip direction="top" id="sync-failed-accounting-slider-button">
              <Text
                translationKey="billPay.bill.invoiceInbox.createBill.sections.lineItems.createBillWalletTooltip"
                classes="flex flex-wrap text-sm font-medium text-neutral-800 break-words"
              />
            </Tooltip> */}
          </div>
        ) : null}

        <div
          className={`${
            accountingStatus !== ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED
              ? "flex w-full gap-6 items-center justify-end"
              : ""
          }`}
        >
          {MoreOptionConfig && !resyncButton ? (
            <Popup
              keepTooltipInside="#tooltip-inside"
              ref={popupRef}
              trigger={
                <div>
                  <Button
                    id="more-options-transactions"
                    className="flex items-center gap-2 card-wrapper text-neutral-500"
                    preIcon="MoreVerticle"
                  />
                </div>
              }
              closeOnDocumentClick
              position="top left"
              className="filter-popup"
            >
              <div className="flex flex-col gap-2 w-61 ">
                {MoreOptionConfig?.map((item) => (
                  <button
                    onClick={() =>
                      onClick(dispatch, item?.action, {
                        accounting_id: accountingId,
                        status: ACCOUNTING_TRANSACTION_STATUS.SYNCED,
                        exportable,
                      })
                    }
                    className="flex flex-col justify-start p-5 text-left text-neutral-500 hover:text-neutral-800 hover:bg-neutral-50"
                    key={`${item.key}-accounting-slider-button-popup-item`}
                  >
                    <Text classes="" translationKey={item?.label} />
                    {item?.description ? (
                      <Text
                        classes="text-xs text-neutral-400 rounded-[10px] py-3"
                        translationKey={item?.description}
                        translationProps={{
                          accountingSoftware,
                        }}
                      />
                    ) : null}
                  </button>
                ))}
              </div>
            </Popup>
          ) : null}

          {accountingCtas?.includes(ALL_ALLOWED_CTAS.UNVERIFY) ? (
            <Button
              label="accounting.slider.unverify"
              variant="tertiary"
              classes="w-15.5"
              onClick={() =>
                onClick(dispatch, ALL_ALLOWED_CTAS.UNVERIFY, {
                  accounting_id: accountingId,
                  status: ACCOUNTING_TRANSACTION_STATUS.PENDING,
                  transactionType,
                })
              }
              showLoader={isLoading}
            />
          ) : null}
          <Button
            id={tooltipId}
            label={
              resyncButton
                ? "accounting.transactions.cards.listingFields.action.actions.resync"
                : `accounting.transactions.cards.listingFields.action.actions.${action}`
            }
            disabled={actionDisabled}
            type={
              resyncButton
                ? ACCOUNTING_STATUS_CTA_MAP[
                    ACCOUNTING_TRANSACTION_STATUS.RESYNC
                  ]?.color
                : ACCOUNTING_STATUS_CTA_MAP[accountingStatus]?.color
            }
            classes="w-15.5"
            onClick={() =>
              onClick(
                dispatch,
                resyncButton
                  ? "sync"
                  : ACCOUNTING_STATUS_CTA_MAP[accountingStatus].action,
                { id: accountingId, transactionType, exportable }
              )
            }
            showLoader={isLoading}
          />
          {actionDisabled && notActionableReason ? (
            <Tooltip id={tooltipId} direction="top">
              <Text translationKey={notActionableReason} />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}

AccountingSliderSubmitButton.propTypes = {
  accountingStatus: PropTypes.string,
  accountingId: PropTypes.number,
  syncable: PropTypes.bool,
  verifiable: PropTypes.bool,
  onClick: PropTypes.func,

  page: PropTypes.string,
  accountingCtas: PropTypes.array,
};
