import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { to } from "await-to-js";

import { validateToken } from "@/store/reducers/user";
import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import { downloadFile } from "@/utils/actions";
import vToast from "@/utils/vToast";

import { ROUTES } from "@/constants/routes";
import API from "@/api";

const initialState = {
  isLoading: false,
  locale: "en",
  isDevDemo: true,
  countries: [],
  accounts: [],
  isFetchingCountries: false,
  isFetchingAccounts: false,
  appModal: null, // model enum name
  appModalData: null, // this will be accessible inside on success of the modal, usually set to `selectedCard`/`selectedPayment`
  dataActionLoadingId: null,
  indexApiReload: false,
};

// async thunks
export const init = createAsyncThunk(
  "app/init",
  async ({ navigate }, { dispatch }) => {
    dispatch(setIsLoading(true));
    dispatch(validateToken({ navigate }));
    dispatch(setIsLoading(false));
  }
);

// reducers
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    setLocale: (state, action) => ({
      ...state,
      locale: action.payload,
    }),
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setIsFetchingCountries(state, action) {
      state.isFetchingCountries = action.payload;
    },
    setAccounts(state, action) {
      state.accounts = action.payload;
    },
    setIsFetchingAccounts(state, action) {
      state.isFetchingAccounts = action.payload;
    },
    openAppModal(state, action) {
      state.appModal = action.payload;
    },
    closeAppModal(state) {
      state.appModal = null;
    },
    setAppModalData(state, action) {
      state.appModalData = action.payload;
    },

    setDataActionLoadingId(state, action) {
      state.dataActionLoadingId = action.payload;
    },

    setIndexApiReload(state, action) {
      state.indexApiReload = action.payload;
    },
  },
});
export const fetchCountries = createAsyncThunk(
  "app/fetchCountries",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCountries(true));

    const [err, response] = await to(API.Country.all(params ?? null));
    if (response?.data) {
      dispatch(setCountries(response?.data));
    }
    dispatch(setIsFetchingCountries(false));
  }
);

export const approveRequest = createAsyncThunk(
  "app/approveRequest",
  async (params, { dispatch }) => {
    const { payload, onSuccess } = params;

    dispatch(setDataActionLoadingId(payload?.target_id));
    const [error, response] = await to(API.Misc.approve(payload));

    if (error) {
      vToast(getErrorToastMessage(error?.message));
    }

    if (!error && response) {
      vToast(getSuccessToastMessage(response?.message));
      if (onSuccess) {
        onSuccess();
      }
    }

    dispatch(setDataActionLoadingId(null));
  }
);
// TODO: maybe create and move such shared code to 'misc' store
export const deleteAttachment = createAsyncThunk(
  "app/deleteAttachment",
  async (params) => {
    const { id, onSuccess } = params; // id of main entity, not attachment id
    const [error, response] = await to(API.Misc.deleteAttachment({ id }));

    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    }
  }
);
export const downloadAttachment = createAsyncThunk(
  "app/downloadAttachment",
  async (params) => {
    const { id, file } = params; // id of main entity, not attachment id
    const [error, response] = await to(API.Misc.getAttachementUrl(id));

    if (error) {
      vToast(getErrorToastMessage(error));
    } else if (response?.data) {
      downloadFile(response?.data?.url, file?.fileName, file?.contentType);
    }
  }
);

export const getAccounts = createAsyncThunk(
  "app/getAccounts",
  async ({ navigate }, { dispatch }) => {
    dispatch(setIsFetchingAccounts(true));
    const host = window.location.host.split(".");
    const [err, response] = await to(
      API.Auth.getAccounts({ subdomain: host[0] })
    );

    if (response?.data?.length === 0 && host !== "app") {
      navigate(`/${ROUTES.companyNotFound.base.absolutePath}`);
    } else if (response?.data?.length) {
      if (
        response?.data?.[0]?.client_url &&
        response?.data?.[0]?.client_url !== window.location.host
      ) {
        const { search } = window.location;
        window.location.href = `http://${response?.data?.[0]?.client_url}/login${search}`;
      }
      // save client_url in local storage
      window.localStorage.setItem(
        "client_url",
        response?.data?.[0]?.client_url
      );
      // save api_subdomain in local storage
      window.localStorage.setItem(
        "api_subdomain",
        response?.data?.[0].api_subdomain
      );
      const { pathname, search } = window.location;

      if (pathname !== "/login")
        navigate(`${ROUTES.login.base.absolutePath}${search}`);
    }

    dispatch(setIsFetchingAccounts(false));
  }
);

export const fetchAllEntities = createAsyncThunk(
  "app/fetchAllEntities",
  async (params, { dispatch }) => {
    const { onSuccess, ...rest } = params;
    dispatch(setIsFetchingAccounts(true));
    const [err, response] = await to(API.Auth.getAccounts(rest));
    if (response?.data) {
      dispatch(setAccounts(response.data));
      if (onSuccess) {
        onSuccess();
      }
    } else vToast(getErrorToastMessage(err));
    dispatch(setIsFetchingAccounts(false));
  }
);

// actions
export const {
  setIsLoading,
  setLocale,
  setCountries,
  setIsFetchingCountries,
  setAccounts,
  setIsFetchingAccounts,
  setConnectedAccounts,
  setIsFetchingConnectedAccounts,
  openAppModal,
  closeAppModal,
  setAppModalData,
  setRedirectUrl,
  setDataActionLoadingId,
  setIndexApiReload,
} = appSlice.actions;

export default appSlice.reducer;
