import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { selectedExpenseSelector } from "@/store/selectors/expense";

import CopytoClipboard from "@/components/core/CopyToClipboard";
import Icon from "@/components/core/Icon";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import { capitalizeFirstLetter } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function ExpenseAndQRPaySliderOverviewCards({
  isAccountingSlider,
  userName,
  department,
  location,
  cardName,
  cardNumber,
  cardType,
  merchantName,
  merchantId,
  merchantFullName,
  upiId,
  category,
  LinkedToName,
  LinkedToValue,
  role,
  page,
  budgetAvailableBalance,
  currency,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const merchantSliderHandler = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.expenses.merchantId, merchantId);
    setSearchParams(searchParams);
  };
  return (
    <div className="grid grid-cols-2 gap-5 mt-8">
      {/* USERS */}
      <div className="card-wrapper flex flex-col shadow-md h-14.5  justify-center p-3">
        <Text
          color="neutral-500"
          classes="font-semibold text-sm"
          translationKey="expenses.slider.detailsCard.user"
        />
        <div className="flex items-center gap-2">
          <Text
            color="neutrtal-800"
            classes="font-semibold text-base"
            translationKey={userName}
          />
          <Icon name="NEArrow" />
        </div>
        <span className="flex items-center">
          {role ? (
            <Text
              color="neutral-500"
              classes="font-semibold text-sm"
              translationKey={role}
            />
          ) : null}
          {role && department ? (
            <div className="profile-widget-dot bg-neutral-500" />
          ) : null}
          {department ? (
            <Text
              color="neutral-500"
              classes="font-semibold text-sm"
              translationKey={department}
            />
          ) : null}
          {department && location ? (
            <div className="profile-widget-dot bg-neutral-500" />
          ) : null}
          {location ? (
            <Text
              color="neutral-500"
              classes="font-semibold text-sm"
              translationKey={location}
            />
          ) : null}
        </span>
      </div>
      {page !== QRPAY_EXPENSES_CONTEXT.QRPAY && (
        <div className="card-wrapper flex flex-col h-14.5  justify-center p-3">
          <Text
            color="neutral-500"
            classes="font-semibold text-sm first-letter:capitalize"
            translationKey="expenses.slider.detailsCard.card"
            translationProps={{
              cardType,
              cardNumber,
            }}
          />
          <div className="flex items-center gap-2">
            <Text classes="font-semibold text-base" translationKey={cardName} />
            <Icon name="NEArrow" />
          </div>
        </div>
      )}
      {/* Merchant name */}
      <div
        className="card-wrapper flex flex-col h-14.5  justify-center p-3 cursor-pointer"
        onClick={merchantSliderHandler}
      >
        <Text
          color="neutral-500"
          classes="font-semibold text-sm"
          translationKey="expenses.slider.detailsCard.merchantName"
        />
        <div>
          <Text translationKey={merchantName} />
          <div className="flex items-center">
            <Text
              color="neutral-500"
              classes="text-xs"
              translationKey={upiId ?? merchantFullName}
            />
            <CopytoClipboard
              iconClasses="w-2.5 h-2.5"
              text={upiId ?? merchantFullName}
            />
          </div>
        </div>
      </div>
      {/* Category  */}
      {page !== QRPAY_EXPENSES_CONTEXT.QRPAY && (
        <div className="card-wrapper flex flex-col h-14.5  justify-center p-3">
          <Text
            color="neutral-500"
            classes="text-sm font-semibold"
            translationKey="expenses.slider.detailsCard.category"
          />
          <div>
            {category ? (
              <Text
                classes="font-semibold text-base"
                translationKey={category}
              />
            ) : (
              <LoaderSkeleton size={[20, 100]} />
            )}
          </div>
        </div>
      )}
      {/* Linked to */}
      {LinkedToName && LinkedToValue ? (
        <div className="card-wrapper flex flex-col h-14.5  justify-center p-3">
          <Text
            color="neutral-500"
            classes="text-sm font-semibold"
            translationKey="expenses.slider.detailsCard.linkedTo"
          />
          <div>
            <Text
              classes="font-semibold text-base"
              translationKey="expenses.slider.detailsCard.linkedToValue"
              translationProps={{ LinkedToName, LinkedToValue }}
            />
          </div>
          <span className="flex items-center">
            <Text
              color="neutral-500"
              classes=" text-sm"
              translationKey="QRPay.budgetAvailableOverviewCards"
              translationProps={{ budgetAvailableBalance, currency }}
            />
          </span>
        </div>
      ) : null}
    </div>
  );
}
ExpenseAndQRPaySliderOverviewCards.propTypes = {
  isAccountingSlider: PropTypes.bool,
  userName: PropTypes.string,
  department: PropTypes.string,
  location: PropTypes.string,
  cardName: PropTypes.string,
  merchantName: PropTypes.string,
  merchantFullName: PropTypes.string,
  category: PropTypes.string,
  LinkedToName: PropTypes.string,
  LinkedToValue: PropTypes.string,
  cardNumber: PropTypes.string,
  cardType: PropTypes.string,
  merchantId: PropTypes.number,
  role: PropTypes.string,
  page: PropTypes.string,
  upiId: PropTypes.string,
  budgetAvailableBalance: PropTypes.number,
  currency: PropTypes.string,
};
