import PropTypes from "prop-types";

import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import {
  PERMISSIONS_LABEL_DESCRIPTION_CONFIG,
  SETTING_PERMISSIONS_ACTION_TYPE,
} from "@/constants/company";

export default function RolesPermissionsCheckbox({
  permission,
  selectedPermissionList,
  updateSelectedPermissionList,
  disabled = false,
  componentKey,
  setParentKey,
  type,
}) {
  const isValueSelected = (permissionItem) => {
    return type === SETTING_PERMISSIONS_ACTION_TYPE.ADD
      ? selectedPermissionList?.find((item) => item?.id === permissionItem?.id)
      : selectedPermissionList?.find(
          (item) => item?.permissionId === permissionItem?.id
        );
  };

  const handleParentKeyUpdate = (updatedKey) => {
    setParentKey(updatedKey);
  };

  return (
    <div
      className="flex items-center gap-2 -mt-4 "
      key={`permission-id-${permission?.id}`}
    >
      <div className="flex items-baseline h-full">
        <Checkbox
          disabled={disabled}
          disabledStyles="!bg-primary-200"
          checked={isValueSelected(permission)}
          onClickHandler={(val) => {
            updateSelectedPermissionList(permission, componentKey, val);
            handleParentKeyUpdate(componentKey);
          }}
        />
      </div>

      <div className="flex items-center gap-1 ">
        <Text
          translationKey="company.rolesPermissions.addCustomRole.checkBox.allowEditAccess"
          classes="text-xs font-semibold text-neutral-800"
        />
        <div id={`${componentKey}-${permission?.id}-tooltip`}>
          <Icon
            name="Info"
            className="w-4 h-4 cursor-pointer text-neutral-400"
          />
        </div>
      </div>
      <Tooltip
        direction="top-start"
        id={`${componentKey}-${permission?.id}-tooltip`}
        maxWidth="53%"
      >
        <Text
          translationKey={
            PERMISSIONS_LABEL_DESCRIPTION_CONFIG[permission?.name]?.toolTipText
          }
          classes="text-sm font-medium text-neutral-500"
        />
      </Tooltip>
    </div>
  );
}

RolesPermissionsCheckbox.propTypes = {
  permission: PropTypes.object,
  selectedPermissionList: PropTypes.array,
  updateSelectedPermissionList: PropTypes.func,
  disabled: PropTypes.bool,
  setParentKey: PropTypes.func,
  componentKey: PropTypes.string,
  type: PropTypes.string,
};
