import { createSelector } from "@reduxjs/toolkit";

import {
  ACCOUNTING_TAG_TYPES,
  LINE_ITEM_TAG_CATEGORIES,
  TAG_FIELD_TYPES,
  TAG_MODULE_TITLES,
  TAG_TYPES,
} from "@/constants/tags";

const getMutatedCategoryAccounting = (accountingTag, tag_type) => {
  if (!accountingTag) return null;
  const mutatedCategroyAccounting = accountingTag.find((tag) =>
    tag.name?.includes(tag_type)
  );

  if (mutatedCategroyAccounting)
    mutatedCategroyAccounting.options = mutatedCategroyAccounting?.options?.map(
      (val) => {
        const _val = { ...val };
        _val.tagValueId = _val.id;
        // delete _val.id;
        return _val;
      }
    );
  return mutatedCategroyAccounting;
};

export const selectTagsStore = (store) => store.tags;

export const allTagsSelector = createSelector(
  selectTagsStore,
  (tags) => tags?.tags || []
);

export const tagsSelector = createSelector(allTagsSelector, (allTags) => {
  if (!Array.isArray(allTags)) return [];

  return allTags.reduce((filteredTags, tag) => {
    if (tag.visible) {
      const filteredOptions = tag.options.filter((option) => option.visible);
      const filteredTag = { ...tag, options: filteredOptions };
      filteredTags.push(filteredTag);
    }
    return filteredTags;
  }, []);
});

export const listTypeTagsSelector = createSelector(tagsSelector, (tags) =>
  tags?.filter((tag) => tag.fieldType === TAG_FIELD_TYPES.LIST)
);

export const isFetchingTagsSelector = createSelector(
  selectTagsStore,
  (tags) => tags?.isFetching
);

export const accountingTagsSelector = createSelector(tagsSelector, (tags) => {
  return tags
    ?.filter((tag) => tag.tagType === TAG_TYPES.ACCOUNTING)
    ?.map((val) => {
      return { ...val, tagValueId: val?.id };
    })
    ?.sort((a, b) => {
      if (a.name.includes("category")) return -1;
      if (b.name.includes("category")) return 1;
      return 0;
    });
});

export const userTagsSelector = createSelector(tagsSelector, (tags) => {
  return tags
    ?.filter((tag) => tag.tagType === TAG_TYPES.USER)
    ?.map((val) => {
      return { ...val, tagValueId: val?.id };
    });
});

export const customTagsSelector = createSelector(tagsSelector, (tags) =>
  tags?.filter((tag) => tag.tagType === TAG_TYPES.CUSTOM)
);

export const accountingCategoryTagsSelector = createSelector(
  accountingTagsSelector,
  (accountingTag) => {
    return getMutatedCategoryAccounting(
      accountingTag,
      ACCOUNTING_TAG_TYPES.CATEGORY
    );
  }
);

export const netsuiteExpenseCategoryTagsSelector = createSelector(
  tagsSelector,
  (accountingTag) => {
    return getMutatedCategoryAccounting(
      accountingTag,
      ACCOUNTING_TAG_TYPES.EXPENSE_CATEGORY
    );
  }
);

export const accountingTaxCodeTagsSelector = createSelector(
  accountingTagsSelector,
  (accountingTag) => {
    return getMutatedCategoryAccounting(
      accountingTag,
      ACCOUNTING_TAG_TYPES.TAX_CODE
    );
  }
);

export const accountingNonCategoryTags = createSelector(
  accountingTagsSelector,
  (accountingTag) =>
    accountingTag
      ? accountingTag.filter(
          (tag) => !tag.name?.includes(ACCOUNTING_TAG_TYPES.CATEGORY)
        )
      : []
);

export const accountingCategoryTagSelector = createSelector(
  accountingTagsSelector,
  (tags) =>
    tags?.find((tag) => tag.name.toLowerCase().includes("category")) ?? null
);

export const nonAccountingCategoryTagSelector = createSelector(
  accountingTagsSelector,
  (tags) =>
    tags?.filter((tag) => !tag.name.toLowerCase().includes("category")) ?? null
);

export const cardsCustomTagsSelector = createSelector(
  customTagsSelector,
  (tags) =>
    tags?.filter((tag) => tag.modules.includes(TAG_MODULE_TITLES.CARD)) ?? null
);

export const billPayCustomTagsSelector = createSelector(
  customTagsSelector,
  (tags) =>
    tags?.filter((tag) => tag.modules.includes(TAG_MODULE_TITLES.BILL_PAY)) ??
    null
);

export const billPayCustomTransactionLevelTagsSelector = createSelector(
  customTagsSelector,
  (tags) => {
    return (
      tags?.filter(
        (tag) =>
          tag.modules.includes(TAG_MODULE_TITLES.BILL_PAY) &&
          !LINE_ITEM_TAG_CATEGORIES.includes(tag.category)
      ) ?? []
    );
  }
);

export const reimbursementCustomTagsSelector = createSelector(
  customTagsSelector,
  (tags) =>
    tags?.filter((tag) =>
      tag.modules.includes(TAG_MODULE_TITLES.REIMBURSEMENT)
    ) ?? null
);

export const qrpayCustomTagsSelector = createSelector(
  customTagsSelector,
  (tags) =>
    tags?.filter((tag) => tag.modules.includes(TAG_MODULE_TITLES.QR_PAY)) ??
    null
);

export const payrollCustomTagsSelector = createSelector(
  customTagsSelector,
  (tags) =>
    tags?.filter((tag) => tag.modules.includes(TAG_MODULE_TITLES.PAYROLL)) ??
    null
);

export const getTagByTagIdSelector = createSelector(
  allTagsSelector,
  (tags) => (tagId) => tags.filter((el) => el.id === tagId)[0]
);

export const isTagsFetchedSelector = createSelector(
  selectTagsStore,
  (tags) => tags.isFetched
);
