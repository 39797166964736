import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

export default function ApprovalPendingLoaderHelper() {
  return [...Array(10)].map((val, i) => (
    <tr className="text-center" key={i}>
      <td className="flex items-center gap-3 ml-4">
        <LoaderSkeleton size={[20, 20]} />
        <LoaderSkeleton type="circle" />
        <LoaderSkeleton size={[20, 180]} />
      </td>
      <td className="text-right">
        <LoaderSkeleton size="lg" />
      </td>
      <td>
        <LoaderSkeleton borderRadius="200px" />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
      <td>
        <LoaderSkeleton size={[20, 30]} />
      </td>
      <td>
        <LoaderSkeleton size={[20, 30]} />
      </td>
      <td>
        <LoaderSkeleton />
      </td>
    </tr>
  ));
}
