import Text from "@/components/core/Text";

export default function HelpNote() {
  return (
    <div className="note-card">
      <Text
        translationKey="billPay.vendors.createVendor.pleaseNote"
        classes="font-semibold text-neutral-800"
      />
      <div className="text-neutral-500 text-sm flex flex-col gap-2 mt-2">
        {[
          "billPay.vendors.createVendor.note1",
          "billPay.vendors.createVendor.note2",
        ].map((note) => (
          <span className="pl-1 flex gap-2" key={note}>
            •
            <Text translationKey={note} />
          </span>
        ))}
      </div>
    </div>
  );
}
