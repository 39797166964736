import PropTypes from "prop-types";

import useOnClickOutside from "@/hooks/useClickOutside";

import { Portal } from "@/components/core/Portal";

import PopupContainer from "./PopupContainer";

export default function Popup({
  styles,
  attr,
  popupRef,
  children,
  containerClasses = "",
  noPortaling = false,
}) {
  const content = (
    <div className="z-[10000]" style={styles} {...attr} ref={popupRef}>
      <PopupContainer classes={containerClasses}>{children}</PopupContainer>
    </div>
  );

  return noPortaling ? content : <Portal>{content}</Portal>;
}

Popup.propTypes = {
  styles: PropTypes.object,
  attr: PropTypes.object,
  popupRef: PropTypes.any,
  children: PropTypes.node,
  containerClasses: PropTypes.string,
  noPortaling: PropTypes.bool,
};
