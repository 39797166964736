import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { INVITE_PEOPLE_TYPES_SLIDER_CONFIG } from "@/constants/company";

import { SLIDERS_SEARCH_PARAMS } from "../../../constants/SearchParams";

// TECH DEBT: this depereciated
export default function InvitePeople() {
  const [type, setType] = useState("invite");
  const [from, setFrom] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loaded, setLoaded] = useState(false);

  const handleAdd = () => {
    // console.log("Adding Person");
  };
  useEffect(() => {
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.add)) {
      setType("add");
    } else if (searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.invite)) {
      setType("invite");
    }
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.from)) {
      setFrom(searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.from));
    }
  }, [searchParams]);

  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <div className=" px-9 slider-content">
      <div className="flex flex-col mb-9">
        <span className="text-3xl font-bold">
          <Text translationKey="company.people.invite.title" />{" "}
          <Text translationKey="company.people.title" />
        </span>
        <span className="text-sm font-medium text-neutral-500">
          <Text translationKey="company.people.invite.description" />
        </span>
      </div>
      <div className="flex flex-col gap-6 cursor-pointer">
        {Object.values(INVITE_PEOPLE_TYPES_SLIDER_CONFIG).map(
          (option, index) => (
            <div
              key={`invite-user-map-${index}`}
              className="flex flex-col p-4 border rounded-lg cursor-pointer border-neutral-200 "
              onClick={() => {
                searchParams.append(
                  SLIDERS_SEARCH_PARAMS.company.people.inviteUser,
                  option.type
                );
                setSearchParams(searchParams);
              }}
            >
              <div className="flex gap-4">
                <div>
                  <div className="text-lg font-semibold">
                    <Text translationKey={option.title} />
                  </div>
                  <div className="text-sm font-medium text-neutral-500">
                    <Text translationKey={option.description} />
                  </div>
                </div>
                <div className="flex items-center">
                  <Icon name="ArrowForward" className="text-neutral-500" />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
