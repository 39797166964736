import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { appModalDataSelector } from "@/store/selectors/app";

import Button from "@/components/core/Button";
import Modal from "@/components/core/Modal";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";

/**
 * Modal with list of radio button configs.
 * Flow is: user chooses one and presses submit, the onSubmit then runs with selected option's id
 *
 * show/hide state - prefer using global modal state (see app reducer in store)
 *      using local state is also fine.
 *
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=11743-252155&mode=design&t=4DeyLXcykPfdVAZQ-4}
 */
export default function RadioModal({
  title,
  description,
  configs = [], // radio options
  defaultValue = null, // optional
  showImportantLabel = false,
  classes = "",

  visible,
  onSubmit,
  onClose = () => {},

  submitLabel = "misc.submit",
  cancelLabel = "misc.cancel",
  cancelBtnProps = {},
  submitBtnProps = {},
  onCancel = () => {},
  onOpen = (configs_, defaultValue_) => {}, // for extra API calls, optional to use
  isPersistent = false,
}) {
  const modalData = useSelector(appModalDataSelector);
  const initialValue = defaultValue || configs?.[0]?.id;
  const [selectedId, setSelectedId] = useState(initialValue);

  useEffect(() => {
    onOpen(configs, defaultValue);
  }, []);

  // to avoid pre-selected option on next open issue
  useEffect(() => {
    if (!visible) {
      setSelectedId(initialValue);
    }
  }, [visible, initialValue]);

  return (
    <Modal
      open={visible}
      innerContainerClasses={`w-126 px-6 pt-6 pb-2.5 ${classes}`}
      onClose={isPersistent ? () => {} : onClose}
    >
      {showImportantLabel ? (
        <Text
          translationKey="misc.important"
          classes="mb-2 text-warning-600 text-xs font-bold block"
        />
      ) : null}
      <Text translationKey={title} classes="text-lg font-bold block" />
      <Text
        translationKey={description}
        classes={`${
          configs?.length ? "mt-6" : "mt-4"
        } font-medium text-neutral-500 text-sm block `}
      />

      <div className="flex flex-col mt-2">
        {configs.map((item) => (
          <div key={item.id} className="py-4">
            <Radio
              name={`modal-radio-${item.id}`}
              label={item.label}
              isChecked={selectedId === item.id}
              labelClasses="text-base text-neutral-800 font-semibold ml-4"
              handleChange={() => {
                setSelectedId(item.id);
              }}
            />
          </div>
        ))}
      </div>

      <div className="flex gap-2 py-4">
        <Button
          label={cancelLabel}
          onClick={() => {
            onClose();
            onCancel();
          }}
          variant="tertiary"
          classes="text-neutral-500 font-semibold text-base"
          {...cancelBtnProps}
        />
        <Button
          label={submitLabel}
          onClick={() => onSubmit(selectedId, modalData)}
          variant="primary"
          classes="text-white font-semibold text-base"
          {...submitBtnProps}
        />
      </div>
    </Modal>
  );
}

RadioModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  configs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showImportantLabel: PropTypes.bool,
  classes: PropTypes.string,

  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,

  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  submitBtnProps: PropTypes.object,
  cancelBtnProps: PropTypes.object,
  onCancel: PropTypes.func,
  onOpen: PropTypes.func,
  isPersistent: PropTypes.bool,
};
