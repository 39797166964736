import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { companyAccountBalanceAnalyticsSelector } from "@/store/selectors/analytics";
import { selectedWalletAccountSelector } from "@/store/selectors/company";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import BalanceCard from "@/components/Company/Overview/MyAccounts/shared/BalanceCard";
import { amountToCurrency } from "@/utils/common";

import { SPEND_FILTER_DURATION_VS_LABELS } from "@/constants/company";

export default function ActionCardForAccount({ spendType, duration }) {
  const selectedWallet = useSelector(selectedWalletAccountSelector);
  const analytics = useSelector(companyAccountBalanceAnalyticsSelector);

  const balance = analytics?.balance;
  const currency = selectedWallet?.currency;
  const moneyIn = analytics?.moneyIn;
  const moneyOut = analytics?.moneyOut;
  const balanceDelta = analytics?.balanceDelta;

  return (
    <div className="card-wrapper-lg p-4">
      <div id="upper">
        <BalanceCard
          label={
            <span>
              {currency} <Text translationKey="common.balance" />
            </span>
          }
          amount={balance}
          currency={currency}
          amountFontSizeClass="text-2xl"
        />
        <div className="flex gap-1 items-center">
          <div
            className={`flex gap-1 items-center rounded-[theme(spacing.10)] px-2 py-[2px] text-xs font-semibold ${
              balanceDelta < 0
                ? "text-danger-500 bg-danger-50"
                : "text-success-500 bg-success-50"
            }`}
          >
            <Icon name={balanceDelta < 0 ? "ArrowDownward" : "ArrowUpward"} />
            <span>{amountToCurrency(balanceDelta, currency)}</span>
          </div>
          <Text
            translationKey={SPEND_FILTER_DURATION_VS_LABELS[duration]}
            classes="text-xs font-semibold text-neutral-400"
          />
        </div>
      </div>
      <div
        id="spend-card-types-container"
        className="flex justify-between items-center mt-6 gap-4"
      >
        <div className="p-4 w-full bg-neutral-50 rounded-lg">
          <BalanceCard
            label="company.accountOverview.moneyIn"
            amount={moneyIn}
            currency={currency}
            amountFontSizeClass="text-xl"
            iconComponent={
              <span className="p-4 grid place-items-center rounded-full bg-white">
                <Icon name="MoneyIn" className="text-success-500" />
              </span>
            }
          />
        </div>
        <div className="p-4 w-full bg-neutral-50 rounded-lg">
          <BalanceCard
            label="company.accountOverview.moneyOut"
            amount={-Math.abs(moneyOut)}
            currency={currency}
            amountFontSizeClass="text-xl"
            iconComponent={
              <span className="p-4 grid place-items-center rounded-full bg-white">
                <Icon name="MoneyOut" className="text-danger-400" />
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
}

ActionCardForAccount.propTypes = {
  spendType: PropTypes.string,
  duration: PropTypes.string,
};
