import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchCards,
  resetCardsListAndPagination,
} from "@/store/reducers/cards";

import { indexApiReloadSelector } from "@/store/selectors/app";
import {
  cardsHasMoreSelector,
  cardsListSelector,
  isFetchingCardsListSelector,
  totalCardsSelector,
} from "@/store/selectors/cards";
import { cardProviderWithInternationalCardOrderEnabledSelector } from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";

import Filters from "@/components/core/Filters";

import CardsTable from "@/components/Cards/CardsTable";
import Export from "@/components/Exports";
import { convertFilters } from "@/utils/filters";
import { dateToString } from "@/utils/common";

import {
  CARDS_TABLE_HEADERS,
  CARDS_TABLE_HEADER_KEYS,
  CARD_FREQUENCY_CORE,
  CARD_FREQUENCY_LABEL,
  CARD_PROVIDER,
  CARD_STATUS,
  CARD_TYPES,
  CARD_USAGE_TYPES,
  PHYSICAL_CARD_TAB_STATUS_MAP,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EXPORT_PAGE_TYPE } from "@/constants/exports";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  PHYSICAL_CARDS_ALL_PATH_NAME,
  PHYSICAL_CARDS_BLOCKED_PATH_NAME,
  PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME,
  ROUTES,
} from "@/constants/routes";

import OrderPhysicalCardButton from "./OrderPhysicalCardButton";

export default function PhysicalCardsPageHelper({
  tab = PHYSICAL_CARDS_ALL_PATH_NAME,
  absoluteTabPath,
  filterArray = [],
  params = {},
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cards = useSelector(cardsListSelector);
  const isFetching = useSelector(isFetchingCardsListSelector);
  const totalCardsSelected = useSelector(totalCardsSelector);
  const hasMore = useSelector(cardsHasMoreSelector);
  const appliedFilter = useSelector(appliedFilterSelector);
  const reloadIndexApi = useSelector(indexApiReloadSelector);
  const filterOptions = {
    ...PHYSICAL_CARD_TAB_STATUS_MAP[tab],
    ...convertFilters(appliedFilter),
    ...params,
    reloadIndexApi,
  };
  const hasCardProvidersWithInternationalCardOrder = useSelector(
    cardProviderWithInternationalCardOrderEnabledSelector
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const onReset = () => {
    dispatch(resetCardsListAndPagination());
  };
  const loadMore = () => {
    dispatch(
      fetchCards({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...filterOptions,
        ...params,
      })
    );
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  const handleRowClick = (Id) => {
    if (
      tab !== PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME &&
      !searchParams.get(SLIDERS_SEARCH_PARAMS.cards.id)
    ) {
      searchParams.append(SLIDERS_SEARCH_PARAMS.cards.id, Id);
      setSearchParams(searchParams);
    } else {
      searchParams.append(SLIDERS_SEARCH_PARAMS.cards.activation, Id);
      setSearchParams(searchParams);
    }
  };

  function getEmptyTableInfo(physical_tab) {
    let title = "";
    let description = "";

    let DescriptionComponent = <></>;

    switch (physical_tab) {
      case PHYSICAL_CARDS_BLOCKED_PATH_NAME:
        title = "No cards blocked";
        description = "You can view all blocked cards here";
        break;
      case PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME:
        title = "cards.pCards.table.emptyData.tab.yet_to_activate.title";
        break;
      default:
        title = "No cards available!";
        description = "cards.pCards.table.emptyData.tab.all.description";
        DescriptionComponent = <OrderPhysicalCardButton classes="my-4" />;
        break;
    }

    return { title, description, DescriptionComponent };
  }
  const {
    title: emptyTableTitle,
    description: emptyTableDescription,
    DescriptionComponent: emptyDataChildren,
  } = getEmptyTableInfo(tab);

  const formatDataForTable = (card) =>
    card
      ? {
          id: card.id,
          [CARDS_TABLE_HEADER_KEYS.CARDS]: {
            id: card.id,
            type: card.type,
            name: card.name,
            currency: card.currency,
            status: card.status,
            cardNumber: card.cardNumber,
            isTemporaryLimit: card.isTemporaryLimit,
            topUpRequests: card?.anyEditRequests,
            insufficientFunds: card?.topUpRequests?.insufficientFunds,
            topUpRequestTooltip: {
              amount: card.topUpRequests.amount,
              currency: card.topUpRequests.currency,
              frequency: t(CARD_FREQUENCY_LABEL?.[card?.newFrequency]),
            },
            temporaryLimitTooltip: {
              limit: card?.refreshLimit,
              createdOn: card?.createdAt,
              currency: card.currency,
              freqeuncy: card?.frequency,
            },
            cardUsageType: card?.cardUsageType,
            hasCardProvidersWithInternationalCardOrder,
          },
          [CARDS_TABLE_HEADER_KEYS.CARD_HOLDER]: {
            index: card.id,
            displayName: card.cardHolder.displayName,
            role: card.cardHolder.roles,
            avatarUrl: card.cardHolder.avatarUrl,
            department: card.cardHolder.departmentName,
            location: card.cardHolder.locationName,
            onIconClick: () => handleCardHolderIconClick(card?.cardHolder?.id),
          },
          [CARDS_TABLE_HEADER_KEYS.LIMIT]: {
            amount: card.limit,
            currency: card.currency,
            frequency: card.frequency,
            isEditable: ![CARD_STATUS.BLOCKED, CARD_STATUS.INACTIVE].includes(
              card.status
            ),
            onIconClick: () => handleEditIconOnLimitRowCell(card?.id),
          },
          [CARDS_TABLE_HEADER_KEYS.SPENT]: {
            spent:
              card.frequency === CARD_FREQUENCY_CORE.RECURRING
                ? card.monthlySpent
                : card.totalSpent,
            total: card.limit,
            currency: card.currency,
          },
          [CARDS_TABLE_HEADER_KEYS.AVAILABLE]: {
            availableBalance: card.availableBalance,
            currency: card.currency,
          },
          [CARDS_TABLE_HEADER_KEYS.LINKED_TO]: {
            linkedToType: card?.linkedTo?.type,
            linkedToName: card?.linkedTo?.name,
          },
          [CARDS_TABLE_HEADER_KEYS.CREATED_ON]: {
            translationKey: dateToString(card.createdAt),
          },
        }
      : {};

  const dataArray = useMemo(() => {
    const _dataArray = [];

    if (cards) {
      cards?.forEach((card) => {
        const isPhysicalCardYetToActivate =
          absoluteTabPath ===
          ROUTES.cards.physicalCards.yetToActivate.absolutePath;
        const isInternationalCard =
          card?.cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL;

        const shouldAddCardDetailRow =
          isPhysicalCardYetToActivate &&
          hasCardProvidersWithInternationalCardOrder
            ? !isInternationalCard
            : true;

        if (shouldAddCardDetailRow) {
          _dataArray.push(formatDataForTable(card));
        }
      });
    }

    return _dataArray;
  }, [cards, hasCardProvidersWithInternationalCardOrder]);

  function handleCardHolderIconClick(cardHolderId) {
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id)) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.company.people.id);
    }
    searchParams.append(SLIDERS_SEARCH_PARAMS.company.people.id, cardHolderId);
    setSearchParams(searchParams);
  }

  function handleEditIconOnLimitRowCell(cardId) {
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.cards.editLimit)) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.editLimit);
    }

    searchParams.append(SLIDERS_SEARCH_PARAMS.cards.editLimit, cardId);
    setSearchParams(searchParams);
  }

  return (
    <>
      <div className="flex items-start">
        <div className="flex flex-col flex-1 w-11/12">
          <Filters filters={filterArray} />
        </div>
        <div className="flex justify-end mt-4">
          <Export
            totalExports={totalCardsSelected}
            storeName="cards"
            sectionName="cards"
            additionalFilters={{
              ...filterOptions,
              ...EXPORT_PAGE_TYPE.CARDS,
              tab,
            }}
          />
        </div>
      </div>
      <div className="mt-1">
        <CardsTable
          handleRowClick={handleRowClick}
          handleRefChange={handleRefChange}
          hasMore={hasMore}
          isFetchingData={isFetching}
          data={dataArray}
          headers={CARDS_TABLE_HEADERS[CARD_TYPES.PHYSICAL]}
          emptyDataTitle={emptyTableTitle}
          emptyDataDescription={emptyTableDescription}
          emptyDataChildren={emptyDataChildren}
          classes={
            tab === PHYSICAL_CARDS_BLOCKED_PATH_NAME
              ? "text-neutral-500"
              : "text-neutral-800"
          }
        />
      </div>
    </>
  );
}

PhysicalCardsPageHelper.propTypes = {
  tab: PropTypes.oneOf([
    PHYSICAL_CARDS_ALL_PATH_NAME,
    PHYSICAL_CARDS_BLOCKED_PATH_NAME,
    PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME,
  ]),
  filterArray: PropTypes.array,
  params: PropTypes.object,
  absoluteTabPath: PropTypes.string,
};
