import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function UserProjectRequirements({ data }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="bg-white border border-neutral-300 py-4 px-3 rounded-lg flex flex-col gap-6">
      {data
        ? data?.map((item, index) => (
            <div
              key={`user-requirements-${index}-block-freeze-user`}
              className="flex flex-col gap-6"
            >
              <div
                key={`user-requirements-block-freeze-${index}`}
                className="flex items-center justify-between cursor-pointer"
                onClick={() => {
                  const searchKey =
                    item.type === "Project"
                      ? SLIDERS_SEARCH_PARAMS.company.project.id
                      : SLIDERS_SEARCH_PARAMS.company.department.id;

                  searchParams.append(searchKey, item.id);
                  setSearchParams(searchParams);
                }}
              >
                <div>
                  <Text
                    translationKey={item?.name}
                    classes="text-lg font-semibold text-neutral-800 flex flex-row items-center"
                  />
                  <div className="flex items-center gap-1">
                    <div
                      className="flex flex-row items-center gap-1"
                      key={`${index}-user-requirements-block-freeze-user`}
                    >
                      <Text
                        translationKey={`${item?.type} `}
                        classes="text-sm font-medium text-neutral-500"
                      />
                    </div>
                  </div>
                </div>
                <Icon name="EArrow" className="w-6 h-6 text-neutral-500" />
              </div>
            </div>
          ))
        : null}
    </div>
  );
}

UserProjectRequirements.propTypes = {
  data: PropTypes.array,
};
