import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  approveCardRequest,
  skipApprovalCardRequest,
} from "@/store/reducers/actionCentre";
import { setIndexApiReload } from "@/store/reducers/app";
import {
  resetSliderInfo,
  setMerchantRestrictions,
  setSelectedTabForCardSpecificRequestSliders,
} from "@/store/reducers/cards";
import { resetRuleStore } from "@/store/reducers/rules";

import { indexApiReloadSelector } from "@/store/selectors/app";
import {
  cardSpecificRequestSlidersSelectedTabSelector,
  newCardRequestDetailsSelector,
} from "@/store/selectors/cards";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import QRPayAndExpenseSliderFooter from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayFooter";

import { ACTION_CENTRE_APPROVAL_TYPE } from "@/constants/ActionCentre";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { BE_FE_REQUEST_TYPE_CARD_REQUEST } from "@/constants/company";
import { EXPENSE_CTA_KEYS } from "@/constants/expense";

import OverviewTab from "./OverviewTab";

function VirturalCardRequestSlider({ setOnClose, setOnBack }) {
  const tabs = [
    {
      name: "cards.cardDetails.overview.navigationLabel",
      count: null,
      key: 1,
    },
    {
      name: "cards.cardDetails.overview.history.navigationLabel",
      count: null,
      key: 2,
      showComingSoon: true,
    },
  ];

  const [searchParams, setSerachParams] = useSearchParams();

  const dispatch = useDispatch();

  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);

  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const sliderHeading =
    "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.label";

  const ref = useLeftHeaderTitle(
    {
      title: sliderHeading,
    },
    true
  );

  const selectedTab = useSelector(
    cardSpecificRequestSlidersSelectedTabSelector
  );
  const selectedTabKey = selectedTab?.key;

  useEffect(() => {
    handleTabChange(tabs[0]);
  }, []);

  function handleTabChange(tab) {
    dispatch(setSelectedTabForCardSpecificRequestSliders(tab));
  }
  const id = searchParams.get(
    SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest.virtualCardRequest
  );
  function handleReject() {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest,
      id
    );
    setSerachParams(searchParams);
  }

  const slider = useRef();
  const scrollToComments = () => {
    slider.current.scrollTop = slider.current.scrollHeight;
  };

  useEffect(() => {
    setOnClose((searchParamArray) => resetData(searchParamArray));
    setOnBack((searchParamArray) => resetData(searchParamArray));
  }, []);

  function resetData(searchParamArray) {
    const sliderKey =
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
        .physicalCardRequest;
    const isClosed = !searchParamArray?.includes(sliderKey);

    if (isClosed) {
      dispatch(resetSliderInfo());
      dispatch(resetRuleStore());
      dispatch(setMerchantRestrictions({}));
    }
  }

  const onSucess = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest
    );
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest?.[
        BE_FE_REQUEST_TYPE_CARD_REQUEST[cardRequestDetails?.requestType]
      ]
    );
    dispatch(setIndexApiReload(!reloadIndexApi));

    setSerachParams(searchParams);
  };

  const handleApprove = () => {
    dispatch(
      approveCardRequest({
        target_id: id,
        type: ACTION_CENTRE_APPROVAL_TYPE.card,
        onSucess,
        inSlider: true,
      })
    );
  };

  const handleSkipApprovals = () => {
    dispatch(
      skipApprovalCardRequest({
        id,
        do: "skip_approvals",
        onSucess,
        inSlider: true,
      })
    );
  };

  const footerClickHandler = (key) => {
    switch (key) {
      case EXPENSE_CTA_KEYS.APPROVE:
        handleApprove();
        break;

      case EXPENSE_CTA_KEYS.REJECT:
        handleReject();
        break;

      case EXPENSE_CTA_KEYS.SKIP_APPROVALS:
        handleSkipApprovals();
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="p-9 slider-content-core scroll-smooth" ref={slider}>
        <div ref={ref}>
          <Text
            translationKey={sliderHeading}
            classes="text-4xl font-bold non-italic"
          />
        </div>

        <div className="mt-6">
          <Tabs
            items={tabs}
            selectedTab={selectedTabKey}
            setCurrentTab={handleTabChange}
            mode
          />
        </div>

        {selectedTabKey === 1 ? <OverviewTab /> : null}
      </div>

      <QRPayAndExpenseSliderFooter
        onSubmit={footerClickHandler}
        expenseFooterCtas={cardRequestDetails?.allowedCtas}
        scrollToComments={scrollToComments}
      />
    </>
  );
}

export default VirturalCardRequestSlider;

VirturalCardRequestSlider.propTypes = {
  setOnClose: PropTypes.func,
};
