import { bool, func, string } from "prop-types";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";

export default function DeActivateModal({
  title,
  showModal,
  description,
  setShowModal,
  onSubmitClick,
  onCancelClick,
}) {
  return (
    <Modal open={showModal} innerContainerClasses="w-[30vw] px-6 py-5">
      <div className="w-5 h-5 my-2 text-primary-500">
        <Icon name="Info" className="w-5 h-5 font-bold text-danger-600" />
      </div>
      <div className="py-2 text-lg font-bold">
        <Text translationKey={title} />
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold text-neutral-600">
          <Text translationKey={description} />
        </div>
      </div>
      <div className="flex gap-2 py-4">
        <Button
          variant="tertiary"
          label="Cancel"
          classes="text-neutral-500"
          onClick={() => {
            setShowModal(false);
            onCancelClick();
          }}
        />
        <Button
          type="danger"
          label="company.travel.buttons.deactivateTravel"
          onClick={() => {
            onSubmitClick();
            setShowModal(false);
          }}
        />
      </div>
    </Modal>
  );
}

DeActivateModal.propTypes = {
  title: string,
  description: string,
  showModal: bool,
  setShowModal: func,
  onSubmitClick: func,
  onCancelClick: func,
};
