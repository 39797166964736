import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { dateToTimeStampString } from "@/utils/common";
import {
  TIMELINE_ITEM_CONFIG,
  TYPES_TIMELINE,
} from "@/utils/constants/timeline";

import ApprovalItems from "./approvalItems";

export default function TimeLineItem({
  timelineItem,
  isFirstPending,
  isApprovalPendingActivityType,
  id,
  timelineType,
}) {
  let config = TIMELINE_ITEM_CONFIG?.[timelineItem?.activityType];
  if (typeof config === typeof (() => {}))
    config = config({ timelineType, status: timelineItem?.status });

  return (
    <div className="flex items-center gap-3 select-none h-11">
      <div
        className={`p-3 rounded-full ${
          isFirstPending ? "bg-warning-100" : config?.classes?.iconWrapper
        }`}
      >
        <Icon
          className={
            isFirstPending ? "text-warning-600" : config?.classes?.iconClasses
          }
          name={config?.iconName}
        />
      </div>
      <div>
        {/* Header */}
        <div>
          <Text
            classes={config?.classes?.textClasses}
            translationKey={timelineItem?.text}
          />
          {isApprovalPendingActivityType ? (
            <>
              <span className="mr-2">:</span>
              <Text
                classes={
                  isFirstPending ? "text-warning-600" : "text-neutral-500"
                }
                translationKey="timeline.pending"
              />
            </>
          ) : null}
          {timelineItem?.actionBy?.displayName &&
          !isApprovalPendingActivityType ? (
            <>
              <span className="mr-2">:</span>
              <Text
                classes="text-neutral-800 font-semibold"
                translationKey={timelineItem?.actionBy?.displayName}
              />
            </>
          ) : null}
        </div>
        {/* SubText */}
        {isApprovalPendingActivityType ? (
          <ApprovalItems
            approvers={timelineItem?.approvers}
            id={id}
            isExpense={timelineType === TYPES_TIMELINE.EXPENSE}
          />
        ) : (
          <div>
            <Text
              classes="text-sm text-neutral-500 font-medium"
              translationKey={dateToTimeStampString(timelineItem?.timestamp)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

TimeLineItem.propTypes = {
  id: PropTypes.number,
  timelineItem: PropTypes.object.isRequired,
  isFirstPending: PropTypes.bool.isRequired,
  isApprovalPendingActivityType: PropTypes.bool.isRequired,
  timelineType: PropTypes.oneOf(Object.values(TYPES_TIMELINE)),
};
