import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

export default function AnalyticsTransaction() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.analytics.transactions.cards.absolutePath);
  }, []);
  return <div>AnalyticsTransaction</div>;
}
