// utils, constant file imports
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useOnClickOutside from "@/hooks/useClickOutside";

import { editAccountingPayee } from "@/store/reducers/accounting";
// reducers
import {
  editAccountingField,
  fetchTags,
  updateTag,
  updateTagValueProperty,
} from "@/store/reducers/tags";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
// selectors
import {
  accountingCategoryTagsSelector,
  allTagsSelector,
} from "@/store/selectors/tags";

// core components
import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Switch from "@/components/core/Switch";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import {
  INTEGRATION_ACCOUNTING_FIELDS_CATEGORY_TABLE_HEADERS,
  INTEGRATION_ACCOUNTING_FIELDS_NON_CATEGORY_TABLE_HEADERS,
  INTEGRATION_ACCOUNTING_FIELDS_VENDORS_TABLE_HEADERS,
} from "@/utils/constants/integrations";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import SliderExpansionHandler from "@/GlobalSliders/SliderExpansionHandler";

export default function EditAccountingFieldsSlider() {
  const dispatch = useDispatch();
  const [searchParam, setSearchParams] = useSearchParams();
  const [tagsData, setTagsData] = useState([]);
  const [title, setTitle] = useState("");
  const [aliasInput, setAliasInput] = useState("");
  const accountingTags = useSelector(allTagsSelector);
  const [accountingVendorOptions, setAccountingVendorOptions] = useState(
    useSelector(accountingVendorsSelector)
  );
  const [categoryTags, setCategoryTags] = useState(
    useSelector(accountingCategoryTagsSelector)
  );
  const [isVendor, setIsVendor] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [editSelectors, setEditSelectors] = useState(null);
  const ref = useRef(null);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableColsWidth, setTableColsWidth] = useState([]);

  const toolTipText = isVendor
    ? "accounting.integrations.toolTipText.enablingDisablingVendors"
    : "accounting.integrations.toolTipText.enablingDisablingCategory";

  // useOnClickOutside(ref, () => reset());
  const currentSearchParam = searchParam.get(
    SLIDERS_SEARCH_PARAMS.accounting.integrations.editAccountingFields
  );
  const id = parseInt(currentSearchParam, 10);

  useEffect(() => {
    setIsVendor(currentSearchParam.toLowerCase().includes("vendor"));
    setIsCategory(
      accountingTags
        ?.find((item) => item.id === id)
        ?.name.toLowerCase()
        .includes("category")
    );
  }, []);

  useEffect(() => {
    if (isVendor) setTagsData(accountingVendorOptions);
  }, [isVendor, accountingVendorOptions]);

  useEffect(() => {
    if (!isVendor)
      setTagsData(
        accountingTags?.find((item) => item.id === id)?.options || []
      );
  }, [isVendor, accountingTags]);

  useEffect(() => {
    setTableHeaders(
      isVendor
        ? INTEGRATION_ACCOUNTING_FIELDS_VENDORS_TABLE_HEADERS
        : isCategory
          ? INTEGRATION_ACCOUNTING_FIELDS_CATEGORY_TABLE_HEADERS
          : INTEGRATION_ACCOUNTING_FIELDS_NON_CATEGORY_TABLE_HEADERS
    );
    setTableColsWidth(
      isVendor
        ? [243, 243, 120]
        : isCategory
          ? [120, 200, 200, 120, 120]
          : [200, 250, 120, 120]
    );
    if (isVendor) {
      setTitle(currentSearchParam.replace("_", " "));
    } else {
      setTitle(accountingTags?.find((item) => item.id === id)?.name);
    }
  }, [isVendor, isCategory]);

  useEffect(() => {
    setEditSelectors(tagsData?.map((item) => ({ id: item.id, edit: false })));
  }, [tagsData]);

  const handleToggleEditSelectors = (fieldId) => {
    setAliasInput("");
    setEditSelectors((prev) =>
      prev.map((item) =>
        item.id === fieldId ? { ...item, edit: !item.edit } : item
      )
    );
  };

  const handleEditVisibility = async (val, item) => {
    let thunkToCall = null;
    let parameters = {};
    if (isVendor) {
      thunkToCall = editAccountingPayee;
      parameters = { id: item.id, visible: val };
    } else {
      thunkToCall = editAccountingField;
      parameters = {
        id: parseInt(currentSearchParam, 10),
        values_attributes: [
          {
            id: item?.id,
            visible: val,
          },
        ],
      };
    }

    const action = await dispatch(thunkToCall(parameters));
    if (thunkToCall.fulfilled.match(action))
      onSuccessfulUpdate({
        fieldId: item?.id,
        updatedProperty: { visible: val },
      });
  };

  const handleEditAlias = async (fieldId) => {
    let thunkToCall = null;
    let parameters = {};

    if (isVendor) {
      thunkToCall = editAccountingPayee;
      parameters = { id: fieldId, alias: aliasInput };
    } else {
      thunkToCall = editAccountingField;
      parameters = {
        id,
        values_attributes: [
          {
            id: fieldId,
            alias: aliasInput,
          },
        ],
      };
    }

    const action = await dispatch(thunkToCall(parameters));
    if (thunkToCall.fulfilled.match(action))
      onSuccessfulUpdate({ fieldId, updatedProperty: { alias: aliasInput } });
  };

  const onSuccessfulUpdate = ({ fieldId, updatedProperty }) => {
    setTagsData((prev) =>
      prev?.map((item) =>
        item.id === fieldId ? { ...item, ...updatedProperty } : item
      )
    );

    if (isVendor)
      setAccountingVendorOptions((prev) =>
        prev?.map((vendorData) =>
          vendorData.id === fieldId
            ? { ...vendorData, ...updatedProperty }
            : vendorData
        )
      );
    else dispatch(fetchTags());
  };

  const reset = () => {
    setEditSelectors((prev) => prev?.map((item) => ({ ...item, edit: false })));
  };

  return (
    <div className="slider-content-container pb-11">
      <div className="slider-content-core flex flex-col px-9 gap-6">
        <div className="flex items-center justify-between">
          <SliderHeader
            heading={title}
            headingClasses="text-3xl font-bold text-neutral-800"
            refFlag
          />
          {!isVendor ? (
            <SliderExpansionHandler iconClasses="text-neutral-500" />
          ) : null}
        </div>

        <Table
          numberOfStickyColsRight={1}
          numberOfStickyColsLeft={isCategory ? 1 : 0}
          colWidths={tableColsWidth}
          rightColWidths={[120]}
          headerSticky
        >
          <tr className="text-xs !text-left">
            {tableHeaders.map((item, index) =>
              !item.toLowerCase().includes("visibility") ? (
                <th
                  key={`edit-accounting-fields-slider-table-${index}`}
                  className="font-semibold"
                >
                  <Text translationKey={item} />
                </th>
              ) : (
                <th key={`edit-accounting-fields-slider-table-${index}`}>
                  <div className="flex items-center justify-center gap-2 ">
                    <Text translationKey="accounting.integrations.accountingFields.table.visibility" />
                    <div id="edit-accounting-field-visibility-header">
                      <Icon
                        name="Info"
                        className="w-4 h-4 text-neutral-300 cursor-pointer"
                      />
                    </div>
                    <Tooltip
                      id="edit-accounting-field-visibility-header"
                      direction="top"
                    >
                      <Text
                        translationKey={toolTipText}
                        classes="text-sm font-medium text-neutral-500"
                      />
                    </Tooltip>
                  </div>
                </th>
              )
            )}
          </tr>

          {tagsData?.map((item, index) =>
            isVendor ? (
              <tr
                className="text-sm font-semibold "
                key={`edit-fields-${index}`}
              >
                <td>
                  <Text translationKey={item.name} />
                </td>

                <td ref={ref}>
                  <div
                    className="flex items-center justify-between"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleEditAlias(item.id);
                      }
                    }}
                  >
                    {editSelectors.find(
                      (it) => it.id === item.id && it.edit === true
                    ) ? (
                      <Input
                        value={aliasInput || item.alias}
                        onChange={(e) => setAliasInput(e.target.value)}
                      />
                    ) : (
                      <Text translationKey={item.alias} />
                    )}
                    <Icon
                      name="Edit"
                      className="w-5 h-5 text-neutral-300 cursor-pointer"
                      handleClick={(e) => {
                        e.preventDefault();
                        handleToggleEditSelectors(item.id);
                      }}
                    />
                  </div>
                </td>

                <td>
                  <Text translationKey={item.currency} />
                </td>

                <td className="text-center">
                  <Switch
                    value={item.visible}
                    handleChange={(val) => {
                      handleEditVisibility(val, item);
                    }}
                  />
                </td>
              </tr>
            ) : isCategory ? (
              <tr
                className="text-sm font-semibold"
                key={`edit-fields-${index}`}
              >
                <td>
                  <Text translationKey={item.id} />
                </td>
                <td>
                  <Text translationKey={item.name} />
                </td>
                <td ref={ref}>
                  <div
                    className="flex items-center justify-between"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleEditAlias(item.id);
                      }
                    }}
                  >
                    {editSelectors.find(
                      (it) => it.id === item.id && it.edit === true
                    ) ? (
                      <Input
                        value={aliasInput || item.alias}
                        onChange={(e) => setAliasInput(e.target.value)}
                      />
                    ) : (
                      <Text translationKey={item.alias} />
                    )}
                    <Icon
                      name="Edit"
                      className="w-5 h-5 text-neutral-300 cursor-pointer"
                      handleClick={() => handleToggleEditSelectors(item.id)}
                    />
                  </div>
                </td>

                <td>
                  <Text translationKey={item?.extraData?.type || ""} />
                </td>

                <td className="text-center">
                  <Switch
                    value={item.visible}
                    handleChange={(val) => {
                      handleEditVisibility(val, item);
                    }}
                  />
                </td>
              </tr>
            ) : (
              <tr
                className="text-sm font-semibold "
                key={`edit-fields-${index}`}
              >
                <td>
                  <Text translationKey={item.alias} />
                </td>
                <td ref={ref}>
                  <div
                    className="flex items-center justify-between"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleEditAlias(item.id);
                      }
                    }}
                  >
                    {editSelectors.find(
                      (it) => it.id === item.id && it.edit === true
                    ) ? (
                      <Input
                        value={aliasInput || item.alias}
                        onChange={(e) => setAliasInput(e.target.value)}
                      />
                    ) : (
                      <Text translationKey={item.alias} />
                    )}
                    <Icon
                      name="Edit"
                      className="w-5 h-5 text-neutral-300 cursor-pointer"
                      handleClick={() => handleToggleEditSelectors(item.id)}
                    />
                  </div>
                </td>

                <td>
                  <Text
                    translationKey="accounting.integrations.accountingFields.table.taxRateData"
                    translationProps={{ taxRate: item?.extraData?.taxrate }}
                  />
                </td>

                <td className="text-center">
                  <Switch
                    value={item.visible}
                    handleChange={(val) => {
                      handleEditVisibility(val, item);
                    }}
                  />
                </td>
              </tr>
            )
          )}
        </Table>
      </div>
    </div>
  );
}
