import PropTypes from "prop-types";

import { t } from "i18next";

import MonthAndYearInput from "@/components/core/DateInput/MonthAndYearInput";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { MONTH_DATA } from "@/utils/constants/date";

export default function SalaryDetailsSection({
  values,
  errors,
  setValues,
  handleChange,
  disabled,
  classes = "",
}) {
  const translatedOptions = MONTH_DATA.map((item) => {
    return { full: t(item.full), short: t(item.short), value: item.value };
  });

  return (
    <div className={`mt-12 ${classes}`}>
      <Text
        translationKey="payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.purpose.title"
        classes="text-xl font-bold"
      />

      <div
        className={`${
          disabled ? "opacity-50" : ""
        } relative flex flex-col gap-6 mt-6`}
      >
        <MonthAndYearInput
          name="payrollMonth"
          label="payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.purpose.paymentMonthAndYear"
          value={values?.payrollMonth}
          error={errors?.payrollMonth}
          onChange={handleChange}
          classes="mb-4"
        />

        <Input
          name="memo"
          label="billPay.bill.invoiceInbox.createBill.sections.purpose.memo"
          value={values.memo}
          error={errors.memo}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

SalaryDetailsSection.propTypes = {
  classes: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  disabled: PropTypes.bool,
};
