import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { updateAccountingData } from "@/store/reducers/accounting_transactions";
import { deleteAttachment } from "@/store/reducers/app";
import {
  OCR_RESULTS_SLICE_ATTRIBUTE_KEY,
  fetchOCRResults,
  resetOcrResult,
  setOCRFileUri,
  setOcrResults,
  setUploadedFiles,
} from "@/store/reducers/ocr-results";

import { selectedAccountingTransactionSelector } from "@/store/selectors/accounting_transactions";
import {
  currentOcrProviderSelector,
  ocrRegionSelector,
} from "@/store/selectors/client";
import {
  filesSelector,
  isFetchingOCRResultsSelector,
  isOcrResultsFound,
  ocrErrorSelector,
  ocrFileUriSelector,
} from "@/store/selectors/ocr";

import FileUpload from "@/components/core/FileUpload";
import FileViewer from "@/components/core/FileViewer";
import OCRHeaderRenderer from "@/components/core/FileViewer/DocViewerOverrideComponents/OCRHeaderRenderer";
import Icon from "@/components/core/Icon";
import Progress from "@/components/core/Progress";
import Text from "@/components/core/Text";
import {
  convertToFileInstances,
  createFileFormData,
  getMBs,
  objectToFormData,
} from "@/utils/common";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { FILE_VIEWER_TYPE_ICON } from "@/constants/fileViewer";
import { OCR_EXTRA_HEADERS, OCR_UPLOAD_REQUEST_PARAMS } from "@/constants/ocr";
import { getBaseHostUrl } from "@/api";

import { BILL_RESPONSE_KEYS } from "./common/enums";

/**
 * This should actually be named "left slider"
 *
 * All state of this component is handled via the ocr Redux store.
 * Why? because this appears beside the slider, and slider is a component of its own. Since not in same tree, have to use Redux
 *
 * Who uses this: currently "Create bill" - for OCR mode, normal attachment mode, and for bill preview (i.e. for review action)
 * Note:
 *  - this works in OCR mode
 *  - works as standalone uploader too (remove `ocrFetched` query param, or just hide the I'll do it manually modal, then works fine)
 *  - works fine in preview mode too
 *  - has deletion icon
 */
function OcrRightSideSlider({ setOnClose }) {
  const [files, setFiles] = useState([]);
  const [number, setNumber] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const payment = useSelector(selectedAccountingTransactionSelector);
  const currentOcrProvider = useSelector(currentOcrProviderSelector);
  const ocrRegion = useSelector(ocrRegionSelector);

  const disabled = !!searchParams
    .getAll(SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled)
    .at(
      searchParams.getAll(
        SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider
      ).length - 1
    ); // check right most instance
  const storedFiles = useSelector(filesSelector);
  const error = useSelector(ocrErrorSelector);
  const firstFile = files?.[0]; // only the first file is considered for OCR
  const ocrFileUri = useSelector(ocrFileUriSelector);
  const isFirstFileTheOCRFile =
    files?.length &&
    [files?.[0]?.uri, files?.[0]?.preview].includes(ocrFileUri);

  const isThisFileFetching = useSelector(isFetchingOCRResultsSelector);
  const billOCRSuccess = useSelector(isOcrResultsFound);
  const fileSizeAndunit = Object.values(getMBs(firstFile?.size));

  const [fileSizeValue, fileSizeUnit] = fileSizeAndunit ?? [0, "kbs"];
  const isProcessingFileItem = Number.parseInt(fileSizeValue * number, 10);
  const isOCRMode = searchParams.get(SLIDERS_SEARCH_PARAMS.payments.ocrFetched); // just check existence of OCR mode
  const isOCRModalOpen =
    searchParams.get(SLIDERS_SEARCH_PARAMS.payments.showOCRModal) === "true" &&
    searchParams.get(SLIDERS_SEARCH_PARAMS.payments.ocrFetched) === "false";

  const accountingBillpaySlider = searchParams.has(
    SLIDERS_SEARCH_PARAMS.accounting.billPay.id
  );

  const accountingPayrollSlider = searchParams.has(
    SLIDERS_SEARCH_PARAMS.accounting.payroll.id
  );

  const isUploadDone = billOCRSuccess || (!isOCRMode && files?.length);

  const handleDelete = ({ state }) => {
    setFiles((prev) => {
      const updatedFiles = prev.filter((singleFile, index) => {
        const deleteFileUri =
          state?.currentDocument?.uri || state?.currentDocument?.url;

        if (
          isOCRMode &&
          !searchParams.get(SLIDERS_SEARCH_PARAMS.payments.showOCRModal) &&
          ocrFileUri === deleteFileUri
        ) {
          // can trust URI here, since OCR mode is applicable only during first create, and never again, so no remote URLs

          // open modal again
          dispatch(setOcrResults([]));
          searchParams.set(SLIDERS_SEARCH_PARAMS.payments.showOCRModal, true);
          searchParams.set(SLIDERS_SEARCH_PARAMS.payments.ocrFetched, false);
          setSearchParams(searchParams);
          dispatch(setOCRFileUri(""));
        }

        return state?.currentFileNo === -1
          ? parseInt(state?.currentFileNo, 10) + 1 !== index
          : state?.currentFileNo !== index;
      });

      dispatch(setUploadedFiles(updatedFiles));

      const deletedId =
        state?.currentFileNo === -1
          ? files?.at(parseInt(state?.currentFileNo, 10) + 1)?.id
          : files?.at(state?.currentFileNo)?.id;

      if (deletedId && (accountingBillpaySlider || accountingPayrollSlider)) {
        dispatch(
          deleteAttachment({
            id: deletedId,
          })
        );
      }

      return updatedFiles;
    });
  };

  const onHandleUpload = async (newUploadedBatch) => {
    if (!newUploadedBatch.length) return;

    if (isOCRModalOpen && !isFirstFileTheOCRFile) {
      // set OCR file as first file
      setFiles((prev) => [...newUploadedBatch, ...prev]); // move OCR file batch to first position
      const firstNewUploadedFile = newUploadedBatch.at(0);
      dispatch(
        setOCRFileUri(
          firstNewUploadedFile?.uri || firstNewUploadedFile?.preview
        )
      );
      searchParams.delete(SLIDERS_SEARCH_PARAMS.payments.showOCRModal);
      setSearchParams(searchParams);
    } else {
      setFiles(newUploadedBatch);
      if (accountingBillpaySlider || accountingPayrollSlider) {
        const payload = objectToFormData({
          receipts: await convertToFileInstances([newUploadedBatch?.at(-1)]), // TECH DEBT, what if they do batch upload, do we ignore the initial ones and only send the last?
        });
        dispatch(
          updateAccountingData({
            accounting_id: payment?.accountingId,
            payload,
          })
        );
      }
    }
  };

  // mimicking isFetching of files
  useEffect(() => {
    let interval = null;
    if (isThisFileFetching && firstFile) {
      interval = setInterval(() => {
        setNumber((prev) => {
          if (prev <= 80) return prev + 2;
          clearInterval(interval);
          return prev;
        });
      }, 2000);
    } else clearInterval(interval);

    return () => {
      if (isThisFileFetching) clearInterval(interval);
    };
  }, [isThisFileFetching, firstFile]);

  useEffect(() => {
    if (isOCRMode && !disabled) {
      if (files?.length) {
        const filesToUpload = [files[0]];

        const formPayload = createFileFormData(
          filesToUpload,
          OCR_UPLOAD_REQUEST_PARAMS.ATTACHMENTS
        );
        formPayload.append(
          "prediction_type",
          OCR_UPLOAD_REQUEST_PARAMS.INVOICE_PREDECTION_TYPE
        );
        const extraHeaders = {
          [OCR_EXTRA_HEADERS.VOLO_BE_URL]: (
            getBaseHostUrl() || ""
          ).toLowerCase(),
          // below values may actually be empty, but still pass them
          [OCR_EXTRA_HEADERS.PROVIDER]: (
            currentOcrProvider || ""
          ).toLowerCase(),
          [OCR_EXTRA_HEADERS.REGION]: ocrRegion, // as is
        };
        dispatch(setUploadedFiles(files));

        // avoid re-run when user navigates forward and comes back
        const isOCRRanAlready =
          searchParams.get(SLIDERS_SEARCH_PARAMS.payments.ocrFetched) ===
          "true";
        if (!isOCRRanAlready && isFirstFileTheOCRFile) {
          dispatch(
            fetchOCRResults({
              extraHeaders,
              key: OCR_RESULTS_SLICE_ATTRIBUTE_KEY,
              value: formPayload,
              onSuccess: () => {
                searchParams.set(
                  SLIDERS_SEARCH_PARAMS.payments.ocrFetched,
                  true
                );
                searchParams.delete(
                  SLIDERS_SEARCH_PARAMS.payments.showOCRModal
                ); // hide OCR modal
                setSearchParams(searchParams);
                dispatch(setOCRFileUri(files[0]?.uri || files[0]?.preview));
              },
              onError: () => {
                // hide the OCR modal, i.e. same as pressing "I'll do it manually"
                searchParams.delete(SLIDERS_SEARCH_PARAMS.payments.ocrFetched);
                searchParams.delete(
                  SLIDERS_SEARCH_PARAMS.payments.showOCRModal
                ); // hide OCR modal
                setSearchParams(searchParams);
                dispatch(setOCRFileUri(files[0]?.uri || files[0]?.preview));
              },
            })
          );
        }
      }
    } else {
      // not in ocr mode update only files
      dispatch(setUploadedFiles(files));
    }
  }, [firstFile, isFirstFileTheOCRFile, currentOcrProvider]);

  useEffect(() => {
    setOnClose((searchParm) => {
      const isClosed = !searchParm?.includes(
        SLIDERS_SEARCH_PARAMS.payments.createBillDetails
      );
      if (isClosed) {
        dispatch(resetOcrResult());
      }
    });
  }, []);
  useEffect(() => {
    if (storedFiles?.length) setFiles(storedFiles);
  }, [storedFiles]);

  useEffect(() => {
    if (
      payment?.[BILL_RESPONSE_KEYS.INVOICES]?.length &&
      accountingBillpaySlider
    )
      setFiles(payment?.[BILL_RESPONSE_KEYS.INVOICES] ?? []);
    else if (
      payment?.[BILL_RESPONSE_KEYS.PAYSLIPS]?.length &&
      accountingPayrollSlider
    )
      setFiles(payment?.[BILL_RESPONSE_KEYS.PAYSLIPS] ?? []);
  }, [payment]);

  // TECH_DEBT FileViewer runs multiple times here, first in true-side-of-ternary, then inside of FileUpload

  return isUploadDone ? (
    <FileViewer
      key={JSON.stringify(
        files.map((item) => item.url || item.id || item.name)
      )} // TECH_DEBT, force remount. global slider renders only one instance of OcrRightSlider even if redundant params for it are present
      customHeader={OCRHeaderRenderer}
      isModalView={false}
      files={files}
      handleDelete={handleDelete}
      showDelete={!disabled}
      disabled={disabled}
      showUpload
      onHandleUpload={onHandleUpload}
    />
  ) : (
    <div className="w-full h-full p-5">
      {isThisFileFetching ? (
        <div className="flex flex-col items-center justify-center h-full ">
          <div className="flex items-center justify-center w-10/12 gap-3">
            <Icon name={FILE_VIEWER_TYPE_ICON?.[firstFile?.type]?.iconName} />
            <div>
              <Text
                classes="cursor-pointer text-center inline-block truncate text-neutral-800 font-semibold"
                translationKey={firstFile.name}
              />
              {isThisFileFetching ? (
                <Progress value={number} classes="w-full" />
              ) : null}
              <Text
                classes="cursor-pointer   text-xs  inline-block truncate text-neutral-500 font-normal"
                translationKey={
                  isThisFileFetching
                    ? `${
                        isProcessingFileItem / 100
                      } ${fileSizeUnit}/${fileSizeAndunit?.join(
                        " "
                      )} • ${number}% uploading`
                    : fileSizeAndunit?.join(" ")
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <FileUpload
          outerClasses="w-full h-full"
          middleClasses=" h-full"
          accept={{
            "image/*": [".png", ".gif", ".jpeg", ".jpg"],
            "application/pdf": [".pdf"],
          }}
          acceptText={disabled ? "" : "PDF, PNG, JPG, JPEG upto 10MB"}
          disabledLabel="billPay.bill.payments.invoiceDetails.leftSlider.disabledText"
          files={[]} // this component is used to show pre upload UI, it does not matter if state is absent here.
          error={error}
          isDisabled={disabled}
          handleFileChange={onHandleUpload}
          innerClasses=" h-full border-dashed border-2"
          hideCustomButtonAfterUpload
          showPreview={false}
          isShowErrorToast
          showErrorAtCenter={!disabled} // show at center, since file upload is very large here, and it's difficult to see error at bottom boundary
        />
      )}
    </div>
  );
}

export default OcrRightSideSlider;
