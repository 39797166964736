export const PAGINATION_PARAM_KEYS = {
  LIST: "list", // ignore. just indicates how most of our stores save data
  PAGE: "page",
  LIMIT: "limit",
  TOTAL: "total",
  IS_FETCHING: "isFetching",
  HAS_MORE: "hasMore",
};

export const INITIAL_PAGE_NUM = 1;

export const PAGINATION_PER_REQUEST_LIMIT = 20;
export const TRAVEL_PAGINATION_PER_REQUEST_LIMIT = 100;
export const PAGINATION_PER_REQUEST_LIMIT_SHORT = 10;
export const PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT = 5;
export const CLIENT_ANALYTICS_PAGINATION_PER_REQUEST_LIMIT = 5;

export const INVOICE_SLIDER_TABLE_LIMIT = 3;

// show more tables
export const SHOW_MORE_TABLE_PER_REQUEST_LIMIT = 5;

export const QUERY_PARAMS = {
  SORT_COLUMNS: {
    KEY_NAME: "sort_column",
    VALUES: {
      CREATED_AT: "created_at",
      AMOUNT: "amount",
    },
  },
  SORT_DIRECTION: {
    KEY_NAME: "sort_direction",
    VALUES: {
      ASC: "asc",
      DESC: "desc",
    },
  },
  RECURRING_PAYMENTS: {
    KEY_NAME: "recurring_bills",
    VALUES: {
      TRUE: "true",
      FALSE: "false",
    },
  },
};
