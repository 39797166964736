import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  archiveSubmissionPolicy,
  fetchSubmissionPolicies,
  fetchSubmissionPolicy,
  setCurrentClaimSubmissionType,
  setCurrentPolicies,
  setCurrentPolicy,
  upsertSubmissionPolicyGroup,
} from "@/store/reducers/policy";

import {
  currentClaimSubmissionTypeSelector,
  currentPoliciesSelector,
  currentPolicySelector,
  tempCurrentPolicySelector,
} from "@/store/selectors/policy";
import {
  accountingTagsSelector,
  customTagsSelector,
} from "@/store/selectors/tags";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import DisableConfirmation from "@/components/Settings/Modals/DisableConfirmation";
import { MODULES } from "@/utils/constants/app";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  POLICY_CTA,
  POLICY_LAYER,
  REIMBURSEMENTS_TYPES_TABS,
  SUBMISSION_PAYLOAD_MAPPING,
  SUBMISSION_POLICY_LIST,
  SUBMISSION_POLICY_MODULE,
} from "@/constants/policy";
import { ROUTES } from "@/constants/routes";

import SubmissionRequirements from "../SubmissionRequirements";
import NonEditableSettingSliderFooter from "../common/NonEditableSettingSliderFooter";

export default function SubmissionPolicySlider({ setOnClose, setOnBack }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const curtPolicy = useSelector(currentPolicySelector);
  const tempCurtPolicy = useSelector(tempCurrentPolicySelector);
  const [showDisableModal, setShowDisableModal] = useState(false);

  const selectedType = useSelector(currentClaimSubmissionTypeSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedReimbursementType, setSelectedReimbursementType] = useState(
    REIMBURSEMENTS_TYPES_TABS[0]
  );

  useEffect(() => {
    if (selectedType) {
      setSelectedReimbursementType(selectedType);
    }
  }, [selectedType]);

  const [resetPolicy, setResetPolicy] = useState(false);

  const submissionPolicyType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.submissionPolicy
  );

  const isCompany = submissionPolicyType === POLICY_LAYER.company;

  const addDeptOrProject =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.project.add) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.department.add);

  const selectedModule = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.submissionModule
  );

  const customPolicy = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.customSubmissionModule
  );

  const projectOrDepartmentId =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.department.id) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.project.id);

  const projectOrDepartment = [
    POLICY_LAYER.project,
    POLICY_LAYER.department,
  ].includes(submissionPolicyType);

  const isDepartment = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.department.id
  );

  const submissionPolicyObject = useSelector(currentPoliciesSelector);
  const accountingTags = useSelector(accountingTagsSelector);
  const customTags = useSelector(customTagsSelector);

  const editState = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.editRequirement
  );

  const policyId = searchParams.get(SLIDERS_SEARCH_PARAMS.settings.policyId);

  const closeSlider = (id) => {
    if (editState || resetPolicy) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.settings.editRequirement);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.settings.policyId);
      searchParams.set(
        SLIDERS_SEARCH_PARAMS.settings.policyId,
        id || curtPolicy.id
      );
    } else searchParams.delete(SLIDERS_SEARCH_PARAMS.settings.policyModule);
    setSearchParams(searchParams);

    dispatch(setCurrentClaimSubmissionType(selectedType));

    dispatch(setCurrentPolicies(null));
  };

  const triggerChange = async () => {
    if (editState || resetPolicy) {
      const params = {
        type: POLICY_LAYER.company,
      };

      if (resetPolicy || (projectOrDepartmentId && curtPolicy?.default))
        params.projectId = projectOrDepartmentId;

      let submissionType = SUBMISSION_PAYLOAD_MAPPING[selectedModule];
      if (selectedModule === MODULES.REIMBURSEMENTS)
        submissionType =
          SUBMISSION_PAYLOAD_MAPPING[selectedReimbursementType?.id];
      params.submissionPolicyType = submissionType;

      if (resetPolicy) {
        setShowDisableModal(false);
        await dispatch(archiveSubmissionPolicy({ ...params, closeSlider }));
      } else
        await dispatch(upsertSubmissionPolicyGroup({ ...params, closeSlider }));
    } else {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.settings.editRequirement,
        curtPolicy?.id
      );

      dispatch(setCurrentClaimSubmissionType(selectedReimbursementType));

      const currentPolicy = JSON.parse(JSON.stringify(curtPolicy));

      if (currentPolicy.accountingTags.length !== accountingTags.length) {
        const accountingTagsIds = currentPolicy.accountingTags.map(
          (a) => a.tagId
        );
        accountingTags.forEach((tag) => {
          if (!accountingTagsIds.includes(tag.id)) {
            currentPolicy.accountingTags.push({
              id: null,
              required: false,
              name: tag.name,
              tagId: tag.id,
            });
          }
        });
      }

      if (currentPolicy.customTags.length !== customTags.length) {
        const customTagsIds = currentPolicy.customTags.map((a) => a.tagId);
        customTags.forEach((tag) => {
          if (
            !customTagsIds.includes(tag.id) &&
            tag?.modules?.find(
              (data) => data === SUBMISSION_POLICY_MODULE?.[selectedModule]
            )
          ) {
            currentPolicy.customTags.push({
              id: null,
              required: false,
              name: tag.name,
              tagId: tag.id,
            });
          }
        });
      }

      dispatch(setCurrentPolicy(currentPolicy));
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    const submissionPolicy =
      submissionPolicyObject &&
      submissionPolicyObject[SUBMISSION_POLICY_LIST[selectedModule]];

    if (!editState) {
      const params = {
        submission_policy_type:
          selectedModule !== MODULES.REIMBURSEMENTS
            ? SUBMISSION_PAYLOAD_MAPPING[selectedModule]
            : SUBMISSION_PAYLOAD_MAPPING[selectedReimbursementType?.id],
      };
      if (policyId) params.id = policyId;
      if (projectOrDepartmentId) params.project_id = projectOrDepartmentId;
      if (policyId || selectedModule === MODULES.PAYROLL)
        dispatch(fetchSubmissionPolicy(params));

      if (projectOrDepartmentId && !submissionPolicyObject) {
        dispatch(fetchSubmissionPolicies(params));
      }
    }
    if (!editState) dispatch(setCurrentPolicy(submissionPolicy));
  }, [selectedModule, editState, policyId, submissionPolicyObject]);

  useEffect(() => {
    const submissionPolicy =
      submissionPolicyObject &&
      submissionPolicyObject[SUBMISSION_POLICY_LIST[selectedModule]];

    if (!submissionPolicy) {
      dispatch(fetchSubmissionPolicies());
    }
  }, [selectedModule]);

  useEffect(() => {
    if (projectOrDepartmentId) return;
    const submissionPolicy =
      submissionPolicyObject &&
      submissionPolicyObject[SUBMISSION_POLICY_LIST[selectedModule]];

    if (
      submissionPolicy &&
      !searchParams.get(SLIDERS_SEARCH_PARAMS.settings.policyId)
    ) {
      searchParams.set(
        SLIDERS_SEARCH_PARAMS.settings.policyId,
        submissionPolicy?.id
      );
      setSearchParams(searchParams);
    }
  }, [selectedModule, submissionPolicyObject, projectOrDepartmentId]);

  const changeReimbursementsPolicy = (type) => {
    const submissionPolicy =
      submissionPolicyObject &&
      submissionPolicyObject[SUBMISSION_POLICY_LIST[type?.id]];

    if (submissionPolicy) {
      searchParams.set(
        SLIDERS_SEARCH_PARAMS.settings.policyId,
        submissionPolicy?.id
      );

      setSearchParams(searchParams);
    }

    setSelectedReimbursementType(type);
  };

  const checkPolicyUpdatable = () => {
    return (
      editState &&
      projectOrDepartment &&
      curtPolicy?.default &&
      JSON.stringify(curtPolicy) === JSON.stringify(tempCurtPolicy)
    );
  };

  const sliderHeader = t(
    editState
      ? "settings.spendControls.submissionPolicy.editSubmissionRequirement"
      : "settings.spendControls.submissionPolicy.submissionRequirement",
    {
      MODULE_DISPLAY_NAMES: t(
        `policy.${
          selectedModule === MODULES.REIMBURSEMENTS
            ? selectedReimbursementType?.id
            : selectedModule
        }`
      ),
    }
  );

  const titleRef = useLeftHeaderTitle({ title: sliderHeader });

  const allState = useLoadingErrorInjector({
    apiKey: "SubmissionPolicy:getPolicy",
    showLoader: true,
    error: {
      header: "",
    },
  });

  useEffect(() => {
    setOnClose((searchParamsArray) => {
      if (
        !searchParamsArray.includes(
          SLIDERS_SEARCH_PARAMS.settings.submissionModule
        )
      ) {
        dispatch(setCurrentClaimSubmissionType(null));
        dispatch(setCurrentPolicy(null));
      }
    });
  }, []);

  return (
    <div className="slider-content-container" ref={allState?.attach}>
      <div className="slider-content-core pb-7">
        <div className="flex flex-col gap-1 mb-7">
          <div className="flex items-center">
            <Text
              classes="text-2xl font-semibold mr-4"
              translationKey={sliderHeader}
              refProp={titleRef}
            />
            {projectOrDepartment}
            {projectOrDepartment ? (
              <Badge
                variant={curtPolicy?.default ? "neutral" : "primary"}
                translationKey={
                  curtPolicy?.default ? "common.company" : "common.custom"
                }
                classes="w-13"
              />
            ) : null}
          </div>
          <Text
            classes="text-sm text-neutral-500"
            translationKey={`policy.submissionModules.${selectedModule}.description`}
          />
        </div>

        <div className="flex">
          {selectedModule === MODULES.REIMBURSEMENTS &&
            !customPolicy &&
            !editState &&
            REIMBURSEMENTS_TYPES_TABS.map((type) => {
              return (
                <Button
                  key={type}
                  label={<Text translationKey={type.name} />}
                  classes="w-16 text-sm mr-2 my-6"
                  variant={
                    selectedReimbursementType === type ? "primary" : "tertiary"
                  }
                  onClick={() => changeReimbursementsPolicy(type)}
                />
              );
            })}
        </div>
        {curtPolicy ? <SubmissionRequirements /> : null}
      </div>

      {curtPolicy?.ctas && !addDeptOrProject ? (
        <div className="slider-footer">
          <div
            className={`flex items-center ${
              curtPolicy?.ctas?.[0] === POLICY_CTA.EDIT_GLOBAL_POLICY
                ? ""
                : "justify-end"
            } gap-4 p-4`}
          >
            {editState ? (
              <Button
                label="misc.cancel"
                classes="w-[150px]
            text-neutral-600 text-sm"
                variant="tertiary"
                onClick={() => closeSlider(null)}
              />
            ) : curtPolicy?.ctas?.[0] === POLICY_CTA.EDIT_GLOBAL_POLICY ? (
              <NonEditableSettingSliderFooter
                linkRedirect={`${ROUTES.manage.settings.spendControls.absolutePath}?${SLIDERS_SEARCH_PARAMS.settings.submissionPolicy}=${POLICY_LAYER.company}&${SLIDERS_SEARCH_PARAMS.settings.policyId}=${policyId}&${SLIDERS_SEARCH_PARAMS.settings.policyModule}=${selectedModule}&editState=${POLICY_LAYER.company}`}
              />
            ) : projectOrDepartment &&
              curtPolicy?.ctas?.[0] === POLICY_CTA.EDIT_POLICY ? (
              <Button
                label="settings.spendControls.revertToCompanyPolicy"
                classes="w-20 text-sm"
                variant="tertiary"
                onClick={() => {
                  setResetPolicy(true);
                  setShowDisableModal(true);
                }}
              />
            ) : null}

            {curtPolicy?.ctas?.[0] !== POLICY_CTA.EDIT_GLOBAL_POLICY ||
            editState ? (
              <Button
                label={
                  !editState
                    ? `settings.spendControls.${curtPolicy?.ctas?.[0]}`
                    : "common.save"
                }
                classes="w-16 text-sm"
                variant="primary"
                disabled={checkPolicyUpdatable()}
                // showLoader={isUpdating}
                onClick={() => triggerChange()}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {/* TODO: Translation */}
      <DisableConfirmation
        header="settings.spendControls.submissionPolicy.updatePolicy.header"
        titleBody={
          <ul className="px-8 py-4 font-semibold list-disc">
            <li>
              <Text
                translationKey={
                  isDepartment
                    ? "settings.spendControls.submissionPolicy.confirmDeptMsg1"
                    : "settings.spendControls.submissionPolicy.confirmProjMsg1"
                }
              />
            </li>
            <li className="pt-2">
              <Text translationKey="settings.spendControls.submissionPolicy.confirmMsg2" />
            </li>
          </ul>
        }
        desc="settings.spendControls.submissionPolicy.updatePolicy.desc"
        showModal={showDisableModal}
        setShowModal={setShowDisableModal}
        onSubmitClick={() => {
          triggerChange();
        }}
        labelText="CONFIRM"
        submitBtnType="default"
        submitBtnLabel="Submit"
      />
    </div>
  );
}
