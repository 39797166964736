import PropTypes from "prop-types";
import React, { useState } from "react";

import Button from "@/components/core/Button";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";

export default function ChildrenDownloadOptionPopup({
  onCancel,
  onSubmit,
  refProp,
  data,
  handleData,
  label,
}) {
  return (
    <div className="mt-3" ref={refProp}>
      <Text
        color="neutral-500"
        classes="text-sm  px-3"
        translationKey={label}
      />
      <div className="mt-3 mb-5">
        <div className="flex flex-col gap-2 mb-5 ">
          <div>
            <Text
              color="neutral-500"
              classes="text-sm  pl-3"
              translationKey="expenses.overview.downloadStatement.filters"
            />
            <span className="text-sm text-neutral-500 ">:</span>
          </div>
          {[
            {
              name: "expenses.overview.downloadStatement.withFilters",
              value: "withFilters",
            },
            {
              name: "expenses.overview.downloadStatement.withoutFilters",
              value: "withoutFilters",
            },
          ].map((val, index) => (
            <div
              key={`filters-download-option-${index}`}
              className="flex px-3 py-3 hover:bg-neutral-50 cursor-pointer-50"
            >
              <Radio
                name="filters"
                textClases={
                  data?.filters === val.value
                    ? "text-neutral-800 font-bold"
                    : "text-neutral-500"
                }
                label={val.name}
                value={val.value}
                isChecked={data?.filters === val.value}
                handleChange={(value) =>
                  handleData((prev) => {
                    return { ...prev, filters: value };
                  })
                }
              />
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-2 mb-5">
          <div className="flex">
            <Text
              color="neutral-500"
              classes="text-sm pl-3"
              translationKey="expenses.overview.downloadStatement.downloadFormat"
            />
            <span className="text-sm text-neutral-500">:</span>
          </div>
          {[
            { name: "expenses.overview.downloadStatement.pdf", value: "pdf" },
            { name: "expenses.overview.downloadStatement.csv", value: "csv" },
            {
              name: "expenses.overview.downloadStatement.excel",
              value: "excel",
            },
          ].map((val, index) => (
            <div
              key={`download-format-popup-${index}`}
              className="flex px-3 py-3 cursor-pointer hover:bg-neutral-50"
            >
              <Radio
                name="downloadFormat"
                textClases={
                  data?.downloadFormat === val.value
                    ? "text-neutral-800 font-bold"
                    : "text-neutral-500"
                }
                label={val.name}
                value={val.value}
                isChecked={data?.downloadFormat === val.value}
                handleChange={(value) =>
                  handleData((prev) => {
                    return { ...prev, downloadFormat: value };
                  })
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center justify-end gap-2 px-2 py-3 overflow-hidden popup-footer">
        <Button
          size="sm"
          onClick={() => {
            onCancel();
            handleData({ filters: null, downloadFormat: null });
          }}
          classes="text-neutral-500"
          label="expenses.overview.downloadStatement.cancel"
          variant="tertiary"
        />
        <Button
          onClick={onSubmit}
          size="sm"
          label="expenses.overview.downloadStatement.downloadNow"
          variant="secondary"
        />
      </div>
    </div>
  );
}
ChildrenDownloadOptionPopup.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  refProp: PropTypes.node,
  data: PropTypes.object,
  handleData: PropTypes.func,
  label: PropTypes.string,
};
