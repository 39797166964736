import PropTypes from "prop-types";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function CSVFileUploadPreview({ file, primaryAction }) {
  return (
    <div className="flex items-center gap-5 p-4 border border-neutral-200 rounded-lg">
      <div className="relative">
        <Icon name="CSVFile" className="w- text-success-600 relative" />
        <Icon
          name="CheckCircle"
          className="w-3 h-3 text-success-600 absolute right-0 bottom-0"
        />
      </div>
      <div className="w-full flex flex-col justify-center gap-1">
        <Text
          translationKey={file.file.path}
          classes="text-base font-semibold text-neutral-800"
        />
        <Text
          translationKey={file.file.size}
          classes="text-sm font-medium text-neutral-500"
        />
      </div>
      <div onClick={() => primaryAction?.handler()}>
        <Icon
          name={primaryAction?.icon}
          className="w-8 h-8 p-2 mr-3 rounded-lg cursor-pointer text-neutral-700 bg-neutral-100"
        />
      </div>
    </div>
  );
}

CSVFileUploadPreview.propTypes = {
  file: PropTypes.object,
  primaryAction: PropTypes.object,
};
