import React from "react";

import ReimbursementReportHelper from "@/components/ReimbursementReport/ReimbursementReportHelper";
import { TAB_TYPES } from "@/utils/constants/myVolopay";

function MyVolopayReimbursementReportsHistoryPage() {
  return (
    <div className="mt-2">
      <ReimbursementReportHelper tabType={TAB_TYPES.HISTORY} />
    </div>
  );
}

export default MyVolopayReimbursementReportsHistoryPage;
