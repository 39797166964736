import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/constants/routes";

function MyVolopayReimbursementsPage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.myVolopay.reimbursement.claims.absolutePath);
  }, []);
  return <div />;
}

export default MyVolopayReimbursementsPage;
