import React from "react";

import Text from "@/components/core/Text";
import PanCard from "@/assets/images/Pan.png";

export default function PanNotifier() {
  return (
    <div className="flex flex-col gap-5 w-115">
      <Text
        translationKey="myVolopay.gettingStarted.PANNotifier.title"
        classes="text-lg font-bold text-neutral-800"
      />

      <Text
        translationKey="myVolopay.gettingStarted.PANNotifier.desc"
        classes="text-sm font-medium text-neutral-500"
      />

      <div className="flex justify-center py-8 px-25 bg-neutral-50">
        <img src={PanCard} alt="" className="w-85" />
      </div>
    </div>
  );
}
