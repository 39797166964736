import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  isFetchingMissingDetailsSelector,
  missingDetailsSelector,
} from "@/store/selectors/expense";

import Button from "@/components/core/Button";
import SearchInput from "@/components/core/SearchInput";
import Text from "@/components/core/Text";

import MissingDetailsCard from "../ExpenseSliders/MissingItemsList/MissingDetailsCard";

export default function MissingItemsList() {
  const missingData = useSelector((state) => missingDetailsSelector(state));
  const isFetching = useSelector((state) =>
    isFetchingMissingDetailsSelector(state)
  );

  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  const ref = useLeftHeaderTitle({
    title: "misc.businessMissingDetails.heading",
  });
  return (
    <>
      <div className="slider-content-core pb-[150px]">
        <div className="flex flex-col w-5/6">
          <Text
            refProp={ref}
            translationKey="misc.businessMissingDetails.heading"
            classes="text-3xl font-bold text-neutral-800"
          />
          <Text
            translationKey="misc.businessMissingDetails.description"
            classes="text-sm text-neutral-500 mt-1 font-medium"
          />
        </div>
        <div className="mt-8 mb-9">
          <SearchInput options={[]} />
        </div>
        <div>
          {missingData?.items?.map((item) => (
            <MissingDetailsCard data={item} key={item.id} />
          ))}
        </div>
      </div>
      {missingData?.items?.length > 1 && (
        <div className="px-6 py-3 slider-footer">
          <div className="flex items-center justify-end">
            <Button
              label={`${t("misc.businessMissingDetails.remind")} ${
                missingData?.items.length
              } ${t("misc.businessMissingDetails.people")}`}
              classes="w-[150px] text-sm"
            />
          </div>
        </div>
      )}
    </>
  );
}
