import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
// utils, constant file imports
import { ACCOUNTING_SOFTWARES } from "@/constants/accounting";

// page relevant components
import CommonConnectSlider from "./CommonConnectSlider";
import NetsuiteConnectSlider from "./NetsuiteConnectSlider";
import TallyConnectSlider from "./TallyConnectSlider";
import UCSVConnectSlider from "./UCSVConnectSlider";

export default function AccountingSoftwareConnectSlider() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearchParam = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.integrations.software
  );

  switch (currentSearchParam) {
    case ACCOUNTING_SOFTWARES.XERO:
    case ACCOUNTING_SOFTWARES.QBO:
    case ACCOUNTING_SOFTWARES.DESKERA:
    case ACCOUNTING_SOFTWARES.MYOB:
    case ACCOUNTING_SOFTWARES.ZOHO:
      return <CommonConnectSlider accountingSoftware={currentSearchParam} />;
    case ACCOUNTING_SOFTWARES.TALLY:
      return <TallyConnectSlider />;
    case ACCOUNTING_SOFTWARES.NETSUITE:
      return <NetsuiteConnectSlider />;
    case ACCOUNTING_SOFTWARES.UNIVERSAL_CSV:
      return <UCSVConnectSlider />;
    default:
      return null;
  }
}
