export const APPROVER_LEVELS = {
  FIRST_LEVEL: "1st",
  SECOND_LEVEL: "2nd",
  THIRD_LEVEL: "3rd",
  FOURTH_LEVEL: "4th",
  FIFTH_LEVEL: "5th",
};

export const APPROVER_LEVELS_TO_NUMBER_MAP = {
  [APPROVER_LEVELS.FIRST_LEVEL]: 1,
  [APPROVER_LEVELS.SECOND_LEVEL]: 2,
  [APPROVER_LEVELS.THIRD_LEVEL]: 3,
  [APPROVER_LEVELS.FOURTH_LEVEL]: 4,
  [APPROVER_LEVELS.FIFTH_LEVEL]: 5,
};

export const APPROVERS_TYPE = {
  AUTO_APPROVE: "auto_approve",
  APPROVAL: "approval",
  NOTIFICATION: "notification",
  REVIEW: "review",
};
