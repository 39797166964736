export const CARD_TYPE = {
  PHYSICAL: "physical",
  VIRTUAL: "virtual",
};

export const CARD_TYPE_TITLE = {
  [CARD_TYPE.PHYSICAL]: "Physical",
  [CARD_TYPE.VIRTUAL]: "Virtual",
};
export const CARD_FREQUENCY_BE_MAPPING = {
  monthly: "MONTHLY",
  one_time: "ONE_TIME",
  weekly: "WEEKLY",
  daily: "DAILY",
};
export const CARD_FREQUENCY_CORE = {
  RECURRING: "recurring",
  NON_RECURRING: "non-recurring",
};

export const CARD_FREQUENCY = {
  ONE_TIME: "cards.listingFields.limit.frequency.oneTime.label",
  MONTHLY: "cards.listingFields.limit.frequency.monthly.label",
  WEEKLY: "cards.listingFields.limit.frequency.weekly.label",
  DAILY: "cards.listingFields.limit.frequency.daily.label",
};

export const CARD_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  BLOCKED: "blocked",
  FROZEN: "frozen",
};

export const CREATE_PHYSICAL_CARD_TILDA_LINKS = {
  SG: "https://ww.volopay.co/sg/order-physical-card",
};

export const SUBMISSION_POLICY_STATUSES = {
  MET: "met",
  NOT_MET: "unfulfilled",
};

export const AWAITING_FUNDS =
  "billPay.bill.payments.paymentFooter.sliderStatusText.awaitingFunds";
export const PENDING_APPROVAL = "filters.claimStatus.options.pendingApproval";
