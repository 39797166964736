import PropTypes from "prop-types";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import ProfileWidget from "@/components/core/ProfileWidget";

import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";
import { amountToCurrency } from "@/utils/common";

export default function VendorSliderHeader({
  vendor,
  isFetching,
  inPayrollContext,
}) {
  const ref = useLeftHeaderTitle({ title: vendor.name });
  return (
    <>
      {/* Vendor Name */}
      <div ref={ref}>
        <ProfileWidget
          name={vendor.name}
          img={vendor.avatarUrl}
          textClasses="text-neutral-800 text-2xl font-bold"
        />
      </div>
      {/* Spend Card */}
      <div className="flex items-start px-6 py-5 mt-8 font-medium border rounded-lg border-neutral-300 text-neutral-800">
        {[
          {
            label: inPayrollContext
              ? "payroll.payrollEmployees.tableHeaders.last30Days"
              : "billPay.vendors.slider.last30Days",
            value: amountToCurrency(vendor.last30DaysSpent),
          },
          {
            label: inPayrollContext
              ? "payroll.payrollEmployees.tableHeaders.totalSpend"
              : "billPay.vendors.slider.totalSpend",
            value: amountToCurrency(vendor.totalSpent),
          },
        ].map((item, idx) => (
          <div key={idx} className="flex flex-col w-1/2">
            {isFetching ? (
              <>
                <LoaderSkeleton size={[20, 120]} />
                <LoaderSkeleton size={[44, 250]} />
              </>
            ) : (
              <>
                <div className="flex items-center gap-1">
                  <TextWithTooltipIcon
                    text={item.label}
                    tooltipText={item.tooltipText}
                    textClasses="text-neutral-800 text-xs font-semibold"
                  />
                </div>
                <span className="text-2xl font-bold">{item.value}</span>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
VendorSliderHeader.propTypes = {
  vendor: PropTypes.object,
  isFetching: PropTypes.bool,
  inPayrollContext: PropTypes.bool,
};
