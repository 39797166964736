import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import usePagination from "@/hooks/usePagination";

import {
  fetchAndSelectUserReimbursement,
  fetchAndSelectUserReport,
  setSelectedPaymentsData,
  setSelectedUserReimbursementInitialState,
  setSelectedUserReportInitialState,
  setSelectedUserReportListInitialState,
} from "@/store/reducers/reimbursement";
import { setSliderExpand } from "@/store/reducers/slider";
import {
  defaultCurrencySelector,
  isFetchingClientDetailsSelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";
import {
  isuserReimbursementFetchingSelector,
  reimbursementPaymentHistorySelector,
  selectedUserReimbursementAmountSelector,
  selectedUserReimbursementCreatedBySelector,
  selectedUserReimbursementHasMoreSelector,
  selectedUserReimbursementListSelector,
  selectedUserReimbursementTotalSelector,
  selectedUserReportAmountSelector,
  selectedUserReportCreatedBySelector,
  selectedUserReportHasMoreSelector,
  selectedUserReportListSelector,
  selectedUserReportTotalReportsSelector,
} from "@/store/selectors/reimbursement";
import ReimbursementPaymentDumbComponent from "@/components/Reimbursement/ReimbursementPaymentsSlider/ReimbursementPaymentDumbComponent";
import { getErrorToastMessage } from "@/utils/common";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";
import {
  PAID_SELECTED_TABS,
  PAYMENT_MODES,
  REIMBURSEMENT_PAGE_TYPE,
} from "@/utils/constants/reimbursement";
import {
  SORTING_CATEGORY_REIMBURSEMENT,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";
import vToast from "@/utils/vToast";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  REIMBURSEMENT_DEFAULT_HEADERS,
  REPORT_DEFAULT_HEADERS,
} from "@/constants/reimbursement";

const CONFIG = {
  reimbursement: {
    listSelector: selectedUserReimbursementListSelector,
    amountSelector: selectedUserReimbursementAmountSelector,
    createdBySelector: selectedUserReimbursementCreatedBySelector,
    totalClaimSelector: selectedUserReimbursementTotalSelector,
  },
  report: {
    listSelector: selectedUserReportListSelector,
    amountSelector: selectedUserReportAmountSelector,
    createdBySelector: selectedUserReportCreatedBySelector,
    totalClaimSelector: selectedUserReportTotalReportsSelector,
  },
};

export default function ReimbursementPaymentsSlider({ setOnClose }) {
  const [searchParams, setSearchParam] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(PAID_SELECTED_TABS[0]);
  const [sliderId, setSliderId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [count, updateCount] = useState(0);
  const [reimbursementIds, setReimbursementIds] = useState([]);
  const [type, setType] = useState();

  const [config, setConfig] = useState(CONFIG.reimbursement);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const isFetchingReportEnabledData = useSelector(
    isFetchingClientDetailsSelector
  );

  const {
    listSelector,
    amountSelector,
    totalClaimSelector,
    createdBySelector,
  } = config ?? {};

  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);
  const userReimbursementList = useSelector(listSelector);
  const userReimbursementAmount = useSelector(amountSelector);
  const userReimbursementClaims = useSelector(totalClaimSelector);
  const userReimbursementCreatedBy = useSelector(createdBySelector);
  const userReimbursementReports = useSelector(
    selectedUserReportTotalReportsSelector
  );

  const isUserReimbursementFetching = useSelector(
    isuserReimbursementFetchingSelector
  );
  useEffect(() => {
    if (reportEnabled) {
      setConfig(CONFIG.report);
    }
  }, [reportEnabled]);

  const userReimbursement = {
    reimbursements: userReimbursementList,
    amount: userReimbursementAmount,
    claims: userReimbursementClaims,
    reports: userReimbursementReports,
    createdBy: userReimbursementCreatedBy,
  };

  const paymentHistory = false;
  const reimbursementPaymentHistory = useSelector(
    reimbursementPaymentHistorySelector
  );

  const dispatch = useDispatch();

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
    defaultCategory:
      selectedTab?.key === "2"
        ? null
        : type === REIMBURSEMENT_PAGE_TYPE.payments
          ? SORTING_CATEGORY_REIMBURSEMENT.APPROVED_AT
          : SORTING_CATEGORY_REIMBURSEMENT.SETTLED_AT,
    defaultType: selectedTab?.key === "2" ? null : SORTING_TYPE.DEC,
  });

  useEffect(() => {
    setSliderId(
      Number(searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.id)) ||
        Number(searchParams.get(SLIDERS_SEARCH_PARAMS.reports.userReportId)) ||
        null
    );
    setType(
      searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.pageType) || null
    );
  });

  const hasMoreSelectedUserReimbursement = useSelector(
    reportEnabled
      ? selectedUserReportHasMoreSelector
      : selectedUserReimbursementHasMoreSelector
  );

  const onResetSelectedUserReimbursement = () => {
    if (reportEnabled) {
      dispatch(setSelectedUserReportListInitialState());
    } else {
      dispatch(setSelectedUserReimbursementInitialState());
    }
  };

  const userReimbursementFilterOptions = {
    user: parseInt(sliderId, 10),
    type: REIMBURSEMENT_PAGE_TYPE[type],
    status:
      type === REIMBURSEMENT_PAGE_TYPE.payments
        ? [
            PAYMENT_STATUSES.approved,
            PAYMENT_STATUSES.processing,
            PAYMENT_STATUSES.failed,
          ]
        : [PAYMENT_STATUSES.paid, PAYMENT_STATUSES.settledViaCard],
  };

  const loadMoreSelectedUserReimbursement = () => {
    const payload = {
      page: selectedUserReimbursementPageNum,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      ...userReimbursementFilterOptions,
    };

    const sortingPayload = {
      [SORT_KEYS.COLUMN]: sorting?.category || sorting?.defaultCategory,
      [SORT_KEYS.DIRECTION]: sorting?.type || sorting?.defaultType,
    };

    if (!isFetchingReportEnabledData && sliderId) {
      if (reportEnabled) {
        dispatch(fetchAndSelectUserReport({ payload, sortingPayload }));
      } else {
        dispatch(fetchAndSelectUserReimbursement({ payload, sortingPayload }));
      }
    }
  };

  const [
    selectedUserReimbursementPageNum,
    setSelectedUserReimbursementPageNum,
  ] = usePagination({
    initialPageNum: 1,
    hasMore: hasMoreSelectedUserReimbursement,
    loadMore: loadMoreSelectedUserReimbursement,
    inSlider: true,
    onReset: onResetSelectedUserReimbursement,
    filterOptions: {
      ...userReimbursementFilterOptions,
      config,
      reportEnabled,
      sliderId,
      ...sorting?.type,
    },
  });

  const onScroll = () => {
    setSelectedUserReimbursementPageNum((prev) => prev + 1);
  };

  const dispatchCallFunction = (paymentMode) => {
    dispatch(
      setSelectedPaymentsData({
        amount: totalAmount,
        paymentMode,
        reimbursementIds,
        currency: defaultCurrency,
        totalClaims: count,
        userId: sliderId,
        vendorId: userReimbursement?.createdBy?.vendorId,
      })
    );
  };

  // create asettlement here
  const payViaVolopayCtaHandler = () => {
    dispatchCallFunction(PAYMENT_MODES.payViaVolopay);

    if (userReimbursement?.createdBy?.vendorId) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.reimbursements.paymentPage,
        "true"
      );
      setSearchParam(searchParams);
    } else {
      vToast(
        getErrorToastMessage(
          {},
          "reimbursement.createPayment.claimeeBankDetailMissingTitle",
          "reimbursement.createPayment.claimeeBankDetailMissingDescription"
        )
      );
    }
  };

  const paySettleViaCardCtaHandler = () => {
    dispatchCallFunction(PAYMENT_MODES.settleViaCard); // payment mode in response
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.reimbursements.settleViaCardConfirmation,
      "true"
    );
    setSearchParam(searchParams);
  };

  const payOutsideVolopayCtaHandler = () => {
    dispatchCallFunction(PAYMENT_MODES.payOutsideVolopay);

    searchParams.append(
      SLIDERS_SEARCH_PARAMS.reimbursements.payOutsideVolopay,
      true
    );

    setSearchParam(searchParams);
  };

  const getReimbursementPaymentsSliderTableProps = () => {
    if (
      (selectedTab.key === "1" && type === REIMBURSEMENT_PAGE_TYPE.paid) ||
      (type === REIMBURSEMENT_PAGE_TYPE.payments && !paymentHistory)
    ) {
      return {
        headerSettings: reportEnabled
          ? REPORT_DEFAULT_HEADERS
          : REIMBURSEMENT_DEFAULT_HEADERS,
        type,
        data: userReimbursement?.reimbursements,
        isFetchingData: isUserReimbursementFetching,
        setTotalAmount,
        updateCount,
        onScroll,
        hasMore: hasMoreSelectedUserReimbursement,
        reimbursementIds,
        setReimbursementIds,
        paymentHistory,
        userReimbursementFilterOptions,
        userId: sliderId,
        reportEnabled,
      };
    }
  };

  useEffect(() => {
    setOnClose((searchParamArray) => {
      const isClosed = !searchParamArray?.includes(
        SLIDERS_SEARCH_PARAMS.reimbursements.id
      );

      if (isClosed) {
        dispatch(setSliderExpand(false));
        dispatch(setSelectedUserReportInitialState());
        dispatch(setSelectedUserReimbursementInitialState());
      }
    });
  }, []);

  return (
    !isFetchingReportEnabledData && (
      <ReimbursementPaymentDumbComponent
        userReimbursement={userReimbursement}
        type={type}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        payViaVolopayCtaHandler={payViaVolopayCtaHandler}
        payOutsideVolopayCtaHandler={payOutsideVolopayCtaHandler}
        paySettleViaCardCtaHandler={paySettleViaCardCtaHandler}
        count={count}
        totalAmount={totalAmount}
        onScroll={onScroll}
        reimbursementPaymentHistory={
          type === REIMBURSEMENT_PAGE_TYPE.paid
            ? reimbursementPaymentHistory
            : null
        }
        userId={sliderId}
        getReimbursementPaymentsSliderTableProps={
          getReimbursementPaymentsSliderTableProps
        }
        reportEnabled={reportEnabled}
        sorting={sorting}
        setSorting={setSorting}
      />
    )
  );
}
