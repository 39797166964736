import React from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { openChatWindow } from "@/utils/chatbox";

export default function ChatWithButton({ onClick = () => {} }) {
  return (
    <div
      className="fixed z-[806] bottom-2 p-10 pb-0 pl-0 cursor-pointer left-2  "
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        // console.log(e);
        openChatWindow();
        onClick();
      }}
    >
      <div className="flex flex-row items-center gap-3 px-5 py-3 font-bold text-white bg-primary-500 rounded-3xl">
        <Icon name="Chat" />
        <Text
          translationKey="common.chatWithUs"
          classes="text-xs select-none"
        />
      </div>
    </div>
  );
}
