import { useSearchParams } from "react-router-dom";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function AccountingSettings() {
  const [searchParams, setSearchParams] = useSearchParams();

  const openCustomField = () => {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.accounting.settings.customField,
      "true"
    );
    setSearchParams(searchParams);
  };

  const openRules = () => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.accounting.rules.id, "true");
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col px-11">
      <div className="flex flex-col">
        <Text translationKey="accounting.title" classes="text-neutral-500" />
        <Text
          translationKey="accounting.settings.title"
          classes="text-neutral-800 text-3xl font-bold"
        />
      </div>
      <div className="flex flex-col my-9">
        <div
          className="flex justify-between mb-10 cursor-pointer gap-14"
          onClick={openRules}
        >
          <div className="flex flex-col w-2/5 gap-2">
            <div className="flex items-center gap-2">
              <Text
                translationKey="accounting.settings.accountingRule.title"
                classes="text-lg font-medium"
              />
            </div>
            <Text
              translationKey="accounting.settings.accountingRule.desc"
              classes="text-sm text-neutral-500"
            />
          </div>
          <div className="flex flex-col w-3/5 gap-2">
            <Text
              translationKey="accounting.settings.accountingRule.createRule"
              classes="text-md font-medium"
            />
            <div className="flex items-center justify-between">
              <Text
                translationKey="accounting.settings.accountingRule.createRuleDesc"
                classes="text-sm text-neutral-500"
              />
              <div>
                <Icon name="RightArrow" />
              </div>
            </div>
          </div>
        </div>
        <hr className="border-t-neutral-300" />
        <div
          className="flex justify-between mt-10 cursor-pointer gap-14"
          onClick={openCustomField}
        >
          <div className="flex flex-col w-2/5 gap-2">
            <div className="flex items-center gap-2">
              <Text
                translationKey="accounting.settings.accountingRule.customRuleTitle"
                classes="text-lg font-medium"
              />
            </div>
            <Text
              translationKey="accounting.settings.accountingRule.customRuleDesc"
              classes="text-sm text-neutral-500"
            />
          </div>
          <div className="flex flex-col w-3/5 gap-2">
            <Text
              translationKey="accounting.settings.accountingRule.createCustomRule"
              classes="text-md font-medium"
            />
            <div className="flex items-center justify-between">
              <Text
                translationKey="accounting.settings.accountingRule.createCustomRuleDesc"
                classes="text-sm text-neutral-500"
              />
              <div>
                <Icon name="RightArrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
