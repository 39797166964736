import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { newCardRequestDetailsSelector } from "@/store/selectors/cards";
import {
  activeCardsProviderSelector,
  primaryCardProviderSelector,
} from "@/store/selectors/client";

import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import DateInputTextBox from "@/components/common/DateInputTextBox";
import { dateToString } from "@/utils/common";

import {
  CARD_FREQUENCY_LABEL,
  CARD_PROVIDER,
  CREATE_VIRTUAL_CARD_FIELD_KEYS,
} from "@/constants/Cards";
import { DATEINPUT_MODES } from "@/constants/date";

export default function RequestDetailsForm({
  cardName,
  purposeCard,
  limit,
  currency,
  frequency,
  linkedTo,
  blockingDate,
  transactionAmountLimit,
}) {
  const { t } = useTranslation();
  const cardProvider = useSelector(primaryCardProviderSelector);
  const slug = cardProvider?.slug;

  const FORM_FIELDS = {
    CARD_NAME: "name",
    CARD_FOR: "purposeCard",
    CARD_LIMIT: "limit",
    CARD_FREQUENCY: "frequency",
    LINKED_TO: "linkedTo",
    AUTO_BLOCK_DATE: "blockingDate",
    TRANSACTION_AMOUNT_LIMIT: "transactionAmountLimit",
  };

  const FORM_SCHEMA = [
    {
      name: FORM_FIELDS.CARD_NAME,
      label:
        "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.cardName",
      type: "text",
      value: cardName,
      clasess: "w-full",
    },
    {
      name: FORM_FIELDS.CARD_FOR,
      label:
        "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.cardFor",
      value: purposeCard,
      type: "text",
    },
    {
      name: FORM_FIELDS.CARD_LIMIT,
      label:
        "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.amount",
      type: "number",
      value: limit,
      clasess: "w-1/2 float-left pr-4",
      rightText: currency,
    },
    {
      name: FORM_FIELDS.CARD_FREQUENCY,
      label:
        "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.frequency",
      type: "text",
      value: CARD_FREQUENCY_LABEL[frequency],
      clasess: "w-1/2 float-right pl-4",
    },
    {
      name: FORM_FIELDS.LINKED_TO,
      label:
        "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.linkedTo",
      type: "text",
      value: linkedTo,
      clasess: "float-left w-full",
    },
    {
      name: FORM_FIELDS.AUTO_BLOCK_DATE,
      type: "date-input",
      value: blockingDate,
      label:
        "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.blockingDate.label",
      description:
        "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.blockingDate.description",
    },
    {
      name: FORM_FIELDS.TRANSACTION_AMOUNT_LIMIT,
      type: "number",
      value: transactionAmountLimit,
      rightText: currency,
      label:
        "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.limit.label",
      description:
        "myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.limit.description",
      show: slug !== CARD_PROVIDER.AIRWALLEX,
    },
  ];
  return (
    <form>
      <div>
        {FORM_SCHEMA.map((field, index) => {
          let FieldComponent = <></>;
          const {
            type,
            label,
            name,
            value,
            description,
            clasess,
            rightText,
            show = true,
          } = field;

          switch (type) {
            case "text":
            case "number":
              FieldComponent = (
                <Input
                  name={name}
                  value={t(value) ?? null}
                  label={label}
                  description={description}
                  type={type}
                  rightText={rightText}
                  disabled
                />
              );
              break;

            case "date-input":
              FieldComponent = (
                <DateInputTextBox
                  name={CREATE_VIRTUAL_CARD_FIELD_KEYS.AUTO_BLOCK_DATE}
                  classes="w-full mt-5"
                  description={description}
                  value={dateToString(value)}
                  dateInputExtraProp={{
                    minDate: new Date(),
                  }}
                  disabled
                  label={label}
                  mode={DATEINPUT_MODES.DATE}
                />
              );
              break;

            default:
              break;
          }

          return show ? (
            <div key={name + index} className={`mb-10 ${clasess}`}>
              {name === FORM_FIELDS.AUTO_BLOCK_DATE ? (
                <div className="flex items-center float-left w-full gap-3 mb-4">
                  <Text
                    translationKey="myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.actionControls.label"
                    classes="text-lg font-semibold "
                  />
                  <Text
                    translationKey="myVolopay.actionCentre.sliders.requestSliders.orderCard.virtual.requestDetailsForm.fields.actionControls.rightLabel"
                    classes="text-sm text-neutral-500 font-semibold "
                  />
                </div>
              ) : null}

              {FieldComponent}
            </div>
          ) : null;
        })}
      </div>
    </form>
  );
}
RequestDetailsForm.propTypes = {
  cardName: PropTypes.string,
  purposeCard: PropTypes.string,
  limit: PropTypes.string,
  currency: PropTypes.string,
  frequency: PropTypes.string,
  linkedTo: PropTypes.string,
  blockingDate: PropTypes.string,
  transactionAmountLimit: PropTypes.string,
};
