import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { toggleModal } from "@/store/reducers/onboarding";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function VideoKycProcessModal({
  handleVideoKycForLq,
  getToDashboard,
}) {
  const dispatch = useDispatch();

  function toggleOnboardingModalBool() {
    handleVideoKycForLq();
    dispatch(toggleModal());
  }

  return (
    <div className="flex flex-col m-6 gap-7">
      <Icon name="Info" className="w-10 h-10 text-warning-500" />
      <Text
        translationKey="onboarding.onboardingSteps.livquik.identityDetails.modal.title"
        classes="text-xl not-italic font-bold leading-8 text-neutral-800"
      />
      <Text
        translationKey="onboarding.onboardingSteps.livquik.identityDetails.modal.description"
        classes="text-sm not-italic font-semibold leading-6 text-neutral-600"
      />
      <div className="flex flex-row justify-between">
        <Button
          label="onboarding.onboardingSteps.livquik.identityDetails.modal.buttons.willDoItLater"
          classes="border-neutral-300 border-2  p-4 text-neutral-500 w-60"
          variant=""
          onClick={getToDashboard}
        />
        <Button
          label="onboarding.onboardingSteps.livquik.identityDetails.modal.buttons.continueVideoKyc"
          onClick={toggleOnboardingModalBool}
          classes="w-60"
        />
      </div>
    </div>
  );
}

VideoKycProcessModal.propTypes = {
  handleVideoKycForLq: PropTypes.func,
  getToDashboard: PropTypes.func,
};
