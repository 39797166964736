import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// reducers
import { fetchClient } from "@/store/reducers/client";

// selectors
import { accountingIntegrationSoftwareSelector } from "@/store/selectors/client";

// core components
import Text from "@/components/core/Text";

// page relevant components
import AccountingSoftwareCard from "@/components/Accounting/Integrations/common/AccountingSoftwareCard";
import IntegrationsPage from "@/components/Accounting/Integrations/common/IntegrationsPage";

// utils, constant file imports
import {
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_SOFTWARE_CONFIG,
} from "@/constants/accounting";

export default function Integrations() {
  const dispatch = useDispatch();

  const accountingSoftwareIntegrated = useSelector(
    accountingIntegrationSoftwareSelector
  );

  useEffect(() => {
    dispatch(fetchClient());
  }, []);

  switch (accountingSoftwareIntegrated) {
    case ACCOUNTING_SOFTWARES.XERO:
    case ACCOUNTING_SOFTWARES.MYOB:
    case ACCOUNTING_SOFTWARES.QBO:
    case ACCOUNTING_SOFTWARES.NETSUITE:
    case ACCOUNTING_SOFTWARES.DESKERA:
    case ACCOUNTING_SOFTWARES.ZOHO:
    case ACCOUNTING_SOFTWARES.UNIVERSAL_CSV:
    case ACCOUNTING_SOFTWARES.TALLY:
      return (
        <IntegrationsPage accountingSoftware={accountingSoftwareIntegrated} />
      );
    default:
      return (
        <div className="px-11 flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <Text
                classes="text-sm font-semibold text-neutral-500"
                translationKey="accounting.title"
              />
              <Text
                classes="text-4xl font-bold text-neutral-800"
                translationKey="accounting.integrations.title"
              />
            </div>
          </div>

          <div className="flex flex-wrap gap-4 pb-10">
            {ACCOUNTING_SOFTWARE_CONFIG.map((item, index) => (
              <AccountingSoftwareCard
                key={index}
                icon={item.icon}
                label={item.label}
                description={item.description}
                linkTo={item.linkTo}
                searchParam={item.searchParam}
              />
            ))}
          </div>
        </div>
      );
  }
}
