import PropTypes from "prop-types";
import { Fragment } from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { ROLE_TITLE } from "@/constants/user";

export default function Owner({
  id,
  name,
  department,
  location,
  role,
  showIcon = false,
  className = "",
}) {
  return (
    <div className="flex flex-row items-center justify-between">
      {!name ? (
        "-"
      ) : (
        <div className="flex flex-col overflow-hidden">
          <Text classes={className} translationKey={name} id={`owner-${id}`} />
          <span className="text-xs font-medium text-neutral-500">
            {[role, department, location].filter(Boolean).map((item, index) => {
              const text = <Text translationKey={item} />;
              const delim = <Text translationKey=" • " noTranslate />;

              return (
                <Fragment key={index}>
                  {index === 0 ? null : delim}
                  {text}
                </Fragment>
              );
            })}
          </span>
        </div>
      )}

      {showIcon ? (
        <Icon name="Person" className="w-5 h-5 text-neutral-500" />
      ) : null}
    </div>
  );
}

Owner.propTypes = {
  name: PropTypes.string,
  department: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(Object.keys(ROLE_TITLE)),
  ]),
  location: PropTypes.string,
  role: PropTypes.string,
  showIcon: PropTypes.bool,
  id: PropTypes.any,
};
