import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import StepPaginationButtons from "@/components/core/StepPaginationButtons";

import { CAROUSEL_MODE } from "@/constants/common";

/**
 * Carousel component,by default its in multi view,so no need to pass any props
 * @param {string} children -  defaultValue "a" values user name should be given
 * @param {string} isStepPaginationButtonsDisabled - a boolean flag to decide whether to render StepPaginationButtons or not
 * @param {string} isIndicatorsEnabled - a flag to decide whether to render dot indicators below the carousel items
 * @param {string} mode -  string with values ["singleView","multiView"],which decides the mode in which the Carousel component is to be used
 * @param {string} refProp -  used to provide button handler references in the parent component for custom buttons
 * @return {Component} Carousel component with children passed to Carousel is returned along with buttons to toggle to next and previous items
 */

export default function Carousel({
  children = [],
  isStepPaginationButtonsDisabled = false,
  isIndicatorsEnabled = false,
  mode = CAROUSEL_MODE.MULTI_VIEW,
  refProp,
  getCurrentPage = () => {},
  containerClasses,
}) {
  const [counter, setCounter] = useState(0);
  const [visibleComponents, setVisibleComponents] = useState(0);
  const itemRef = useRef(null);
  const containerRef = useRef(null);

  const calculateNumberOfComponents = () => {
    const itemWidth = itemRef?.current?.getBoundingClientRect()?.width || 0;
    const containerWidth =
      containerRef?.current?.getBoundingClientRect()?.width || 0;
    if (itemWidth === 0 || containerWidth === 0) return 0;
    return Math.floor(containerWidth / itemWidth);
  };

  const handleRightButton = () => {
    setCounter((prev) => (prev === children.length - 1 ? 0 : prev + 1));
  };

  const handleLeftButton = () => {
    setCounter((prev) => (prev === 0 ? children.length - 1 : prev - 1));
  };

  useEffect(() => {
    setVisibleComponents(calculateNumberOfComponents());
    if (isStepPaginationButtonsDisabled)
      refProp.current = {
        handleRightButton,
        handleLeftButton,
      };
  }, []);

  useEffect(() => {
    getCurrentPage(counter);
  }, [counter]);

  return (
    <div
      className={`flex flex-col ${
        mode === CAROUSEL_MODE.SINGLE_VIEW ? "relative overflow-hidden" : ""
      }`}
    >
      {mode === CAROUSEL_MODE.SINGLE_VIEW ? (
        <div className="grid w-full gap-2 transition-transform duration-200">
          {children.map((item, index) => (
            <div
              key={`carousel-item-${index}`}
              className={`${index === counter ? "block" : "hidden"} min-w-full`}
            >
              {item}
            </div>
          ))}
        </div>
      ) : (
        <div
          className={`flex flex-row w-full gap-2 overflow-hidden ${containerClasses}`}
          ref={containerRef}
        >
          {children?.map((item, index) => (
            <div
              ref={itemRef}
              key={`carousel-item-${index}`}
              style={{
                transform: `translateX(-${
                  (itemRef?.current?.clientWidth ?? 0) *
                    (counter * visibleComponents) +
                  8 * (counter * visibleComponents)
                }px)`,
                transition: "transform 0.2s ease-in-out",
              }}
            >
              {item}
            </div>
          ))}
        </div>
      )}

      {!isStepPaginationButtonsDisabled ? (
        <div className="flex justify-end">
          <StepPaginationButtons
            currentPage={counter + 1}
            handleRightButton={() => {
              setCounter((prev) => prev + 1);
            }}
            handleLeftButton={() => {
              setCounter((prev) => prev - 1);
            }}
            limit={visibleComponents}
            total={children?.length}
            rightDisabled={
              (counter + 1) * visibleComponents >= children?.length
            }
            leftDisabled={counter === 0}
          />
        </div>
      ) : null}

      {isIndicatorsEnabled ? (
        <div className="flex justify-center mt-2">
          {children.map((item, index) => (
            <div
              key={index}
              className={`w-2 h-2 mx-1 rounded-full cursor-pointer ${
                index === counter ? "bg-primary-500" : "bg-primary-100"
              }`}
              onClick={() => setCounter(index)}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

Carousel.propTypes = {
  children: PropTypes.node,
  isStepPaginationButtonsDisabled: PropTypes.bool,
  isIndicatorsEnabled: PropTypes.bool,
  mode: PropTypes.string,
  refProp: PropTypes.any,
  getCurrentPage: PropTypes.func,
  containerClasses: PropTypes.string,
};
