import React from "react";

/**
 * Renders a loader component to display a list of child nodes from a given element,
 * excluding those with the "error" class.
 * @param {HTMLElement} element - The HTML element whose child nodes will be rendered.
 * @returns {JSX.Element} - JSX representing the rendered child nodes with skeleton loading effect.
 */
const VPLoaders = ({ element }) => {
  const { childNodes } = element;
  const arrayChildNode = Array.from(childNodes).filter(
    (child) => !child.classList.value.includes("error")
  );

  return (
    <div className={`${element.classList.value} border-none shadow-none gap-2`}>
      {arrayChildNode?.map((childNode, index) => {
        const { width, height } = childNode.getBoundingClientRect();
        return (
          <div
            key={index}
            style={{ width, height }}
            className={`${childNode.classList.value} skeleton`}
          />
        );
      })}
    </div>
  );
};

export default VPLoaders;
