import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useEffectSlider from "@/hooks/useEffectSlider";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  closeAppModal,
  deleteAttachment,
  openAppModal,
  setAppModalData,
} from "@/store/reducers/app";
import {
  fetchAccountWallets,
  fetchPayrollWallet,
  resetPayrollWallet,
  resetWallets,
} from "@/store/reducers/client";
import { setOCRError, setUploadedFiles } from "@/store/reducers/ocr-results";
import {
  clearCreateBillForm,
  fetchAndSelectPaymentApproval,
  fetchAndSelectPayrollApproval,
  fetchLineItems,
  fetchPaymentModes,
  mergeIntoCreateBillForm,
  resetCreateBillFormLineItems,
  resetCreateBillRef,
  resetCreateBillTotalContext,
  resetLineItems,
  resetPaymentModeList,
  setCreateBillForm,
  setCreateBillFormLineItems,
  setCreateBillRef,
  setLineItems,
  setPayment,
  setSelectedPayment,
} from "@/store/reducers/payments";
import {
  PAYROLL_SLICE_ATTRIBUTE_KEY,
  PURCHASE_BILLS_SLICE_ATTRIBUTE_KEY,
  createPurchaseBill,
  fetchBillPayrollCreationSubmissionPolicy,
  fetchDuplicateBill,
  fetchPurchaseBillTaxDetails,
  performActionThunk,
  prependToPurchaseBillsList,
  removePurchaseBill,
  resetPurchaseBillTaxDetails,
  setBillPayrollCreationSubmissionPolicy,
  setDuplicateBill,
  setIsLoading,
  setPurchaseBill,
  setPurchaseBillQuote,
  updatePurchaseBill,
} from "@/store/reducers/purchase-bills";
import { fetchTags, setTags } from "@/store/reducers/tags";
import {
  fetchAndSelectVendor,
  searchAndSelectVendor,
  setSelectedVendor,
} from "@/store/reducers/vendors";

import { appModalDataSelector, appModalSelector } from "@/store/selectors/app";
import {
  accountingIntegrationSoftwareSelector,
  currentPaymentProviderSelector,
  defaultCurrencySelector,
  defaultPayOnceApprovedEnabledSelector,
  isFetchedAccountWalletSelector,
  isFetchedPayrollWalletSelector,
  isFetchingAccountWalletSelector,
  isFetchingPayrollWalletSelector,
  paymentWalletListSelector,
  payrollWalletAsArraySelector,
  submissionPolicyEnabledSelector,
  transactionLevelTagsSetSelector,
} from "@/store/selectors/client";
import {
  filesSelector,
  isFetchingOCRResultsSelector,
  isOcrResultsFound,
  ocrErrorSelector,
  ocrFileUriSelector,
  ocrResults,
} from "@/store/selectors/ocr";
import {
  createBillFormLineItemsSelector,
  createBillFormSelector,
  createBillRefSelector,
  createBillTotalContextSelector,
  isFetchingLineItemsSelector,
  isFetchingPaymentApproversSelector,
  isFetchingPaymentModeSelector,
  isPaymentFetchingSelector,
  lineItemsSelector,
  paymentModeListSelector,
  selectedPaymentSelector,
} from "@/store/selectors/payments";
import {
  additivePurchaseTaxChildrenSelector,
  additivePurchaseTaxParentSelector,
  billPayrollCreationSubmissionPolicySelector,
  isFetchingBillPayrollCreationSubmissionPolicySelector,
  isFetchingDuplicateBillSelector,
  isFetchingPurchaseBillQuoteSelector,
  isFetchingPurchaseBillTaxDetailsSelector,
  isLoadingPurchaseBillSelector,
  isPurchaseBillTaxesReadySelector,
  purchaseBillQuoteSelector,
  recentDuplicateBillSelector,
  subtractivePurchaseTaxChildrenSelector,
  subtractivePurchaseTaxParentSelector,
} from "@/store/selectors/purchase-bills";
import {
  accountingCategoryTagSelector,
  accountingNonCategoryTags,
  billPayCustomTagsSelector,
  isFetchingTagsSelector,
  isTagsFetchedSelector,
  payrollCustomTagsSelector,
} from "@/store/selectors/tags";
import { userSelector } from "@/store/selectors/user";
import {
  isFetchingSelectedVendorSelector,
  selectedVendorSelector,
} from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { generatePayloadFromFormValue } from "@/components/GenericForm/common";
import BillOCRSuccess from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/BillOCRSuccess";
import DuplicatePaymentAlert from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/DuplicatePaymentAlert";
import VendorAlerts from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/VendorAlerts";
import {
  ACC_VALUE,
  BILL_RESPONSE_KEYS,
  CREATE_BILL_FLOW_CONTEXT_KEYS,
  CREATE_BILL_REQUEST_BODY_KEYS,
  CREATE_BILL_TAX_RESPONSE,
  GENERATE_QUOTE_API_BODY_KEYS_RESPONSE,
  LINE_ITEMS_RESPONSE_KEYS,
  LINE_ITEM_KEY,
  LINE_ITEM_KEYS,
  TAX_AT_LINE_ITEM_LEVEL_ADDITIVE,
  TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE,
  TAX_DETAILS_QUERY_PARAM,
  TAX_KEYS,
  TAX_NAMES,
  UPDATE_BILL_REQUEST_BODY_KEYS,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import {
  getInitalFormValues,
  getTagItemWithData,
  getTaxAmount,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/utils";
import ApproversSection from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/ApproversSection";
import AutoRecurringSection from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/AutoRecurringSection";
import LineItemsSection from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/LineItemsSection";
import PurposeSection from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/PurposeSection";
import SalaryDetailsSection from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/SalaryDetailsSection";
import VendorInfo from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/VendorInfo";
import WalletAccountSection from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/WalletAccountSection";
import RadioModal from "@/components/common/RadioModal";
import vToast from "@/utils/vToast";
import { useForm } from "@/utils/useForm";
import { PAYMENT_MODES } from "@/utils/constants/reimbursement";
import {
  BILLPAY_ACTION_PARAMS,
  BILL_PAYROLL_CONTEXT,
} from "@/utils/constants/paymentsStore";
import {
  PAYMENT_SCROLL_TO_KEYS,
  PAYMENT_STATUSES,
  getPaymentSliderInfo,
} from "@/utils/constants/payments";
import { PAY_OUTSIDE_VOLOPAY_CURRENCY } from "@/utils/constants/common";
import { VP_MODALS } from "@/utils/constants/app";
import { USE_FORM_TYPE_OF_VALIDATION } from "@/utils/constantUseForm";
import {
  cloneFormData,
  convertToFileInstances,
  getConstantTimeDate,
  getDateInPattern,
  getTomorrowDate,
  objectToFormData,
  validateAndConvertToISO8601,
} from "@/utils/common";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { FIXED_SIDE } from "@/constants/currency";
import { PAYMENT_PROVIDERS } from "@/constants/provider";
import { TAG_FIELD_TYPES } from "@/constants/tags";
import { VENDOR_LINKED_TO_TYPES, VENDOR_STATUS } from "@/constants/vendors";

export default function CreateBillDetails({
  context,
  searchParamKey,
  setOnClose,
  closeDrawer,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const createBillFlowContext = useRef({}); // all feats look for their specific keys, so initial {} is fine
  const createBillTotalContext = useSelector(createBillTotalContextSelector);
  const amountToBeReceivedByVendor =
    createBillTotalContext?.[CREATE_BILL_FLOW_CONTEXT_KEYS.QUOTE_ARGUMENT] ?? 0;
  const [isOnComeBackPrefill, setIsOnComeBackPrefill] = useState(false);
  const [paymentModeOptions, setPaymentModeOptions] = useState([]);
  const createBillRef = useSelector(createBillRefSelector);

  const appModal = useSelector(appModalSelector);
  const appModalData = useSelector(appModalDataSelector);
  const storedFiles = useSelector(filesSelector);
  const ocrFileUri = useSelector(ocrFileUriSelector);
  // STATE, READS and DERIVED READS

  // need two states. Simple values and line items are handled separately. First line items are filled, then simple values are filled.
  // complete prefilling may happen across multiple renders
  const vendorInfoRef = useRef();

  const [isPrefillingSimpleValues, setIsPrefillingSimpleValues] =
    useState(false);
  const [isPrefillingLineItems, setIsPrefillingLineItems] = useState(false);

  const [isPrefilledSimpleValues, setIsPrefilledSimpleValues] = useState(false);
  const [isPrefilledLineItems, setIsPrefilledLineItems] = useState(false);
  const [isLeftButtonLoading, setIsLeftButtonLoading] = useState(false);

  const isPaymentAddDateMode =
    searchParams.get(SLIDERS_SEARCH_PARAMS.scrollTo) ===
    PAYMENT_SCROLL_TO_KEYS.PAYMENT_DATE;

  const currentProvider = useSelector(currentPaymentProviderSelector);
  const isFetchingPaymentMode = useSelector(isFetchingPaymentModeSelector);
  const vendorDetails = useSelector(selectedVendorSelector);
  const isFetchingVendor = useSelector(isFetchingSelectedVendorSelector);
  const { projectId: vendorProjectOrDepartmentId = null } = vendorDetails ?? {};
  const ownerDepartmentId = vendorDetails?.owner?.departmentId;
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const isLoadingQuoteAmount = useSelector(isFetchingPurchaseBillQuoteSelector);
  const defaultPayOnceApprovedEnabled = useSelector(
    defaultPayOnceApprovedEnabledSelector
  );
  const createMode =
    searchParams.get(SLIDERS_SEARCH_PARAMS.payrollPayments.createSalary) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.payments.createBillDetails);
  let newVendorNameInBillpay =
    searchParams.get(SLIDERS_SEARCH_PARAMS.payrollPayments.createSalary) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.payments.createBillDetails) ||
    searchParams.get(
      SLIDERS_SEARCH_PARAMS.payrollPayments.createEmployeePrompt
    ) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.payments.createVendorPrompt); // billpay trying to create a new vendor
  newVendorNameInBillpay =
    newVendorNameInBillpay === "true" ? "" : newVendorNameInBillpay; // for eager search and prepend newVendorNameFromBillpay in create vendor and come back flow

  const ocrMode = searchParams.get(SLIDERS_SEARCH_PARAMS.payments.ocrFetched);
  const reviewModeId =
    searchParams.get(SLIDERS_SEARCH_PARAMS.payments.reviewBill) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.payrollPayments.reviewSalaryBill);
  const editModeId =
    searchParams.get(SLIDERS_SEARCH_PARAMS.payments.editBill) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.payrollPayments.editBill);
  const reCreateModeId =
    searchParams.get(SLIDERS_SEARCH_PARAMS.payments.reCreateBill) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.payrollPayments.reCreateSalaryBill);
  let isDoPrefill =
    !isOnComeBackPrefill &&
    (!createMode || reviewModeId || ocrMode || reCreateModeId);
  isDoPrefill = isDoPrefill === "false" ? false : !!isDoPrefill;
  // TBD recreate (tax remains)

  const id = reCreateModeId ? reCreateModeId : reviewModeId || editModeId;

  const billOCRSuccess = useSelector(isOcrResultsFound);
  const ocrResult = useSelector(ocrResults);
  const isFetchedOCRResultPresent = !!(Array.isArray(ocrResult)
    ? ocrResult.length
    : ocrResult);
  const isFetchingOCRResults = useSelector(isFetchingOCRResultsSelector);
  const isShowOCRModal = searchParams.get(
    SLIDERS_SEARCH_PARAMS.payments.showOCRModal
  );
  const isShowOCRLoader =
    isFetchingOCRResults || isPrefillingSimpleValues || isPrefillingLineItems;

  const createBillFormValues = useSelector(createBillFormSelector);
  const lineItemsForForm = useSelector(createBillFormLineItemsSelector);

  const paymentModesList = useSelector(paymentModeListSelector);

  const currentUser = useSelector(userSelector);
  const isFetchingWallets = useSelector(
    inPayrollContext
      ? isFetchingPayrollWalletSelector
      : isFetchingAccountWalletSelector
  );
  const isFetchedWallets = useSelector(
    inPayrollContext
      ? isFetchedPayrollWalletSelector
      : isFetchedAccountWalletSelector
  );
  const quoteFromAPI = useSelector(purchaseBillQuoteSelector); // relevant on submit only
  const isAmountZero = !quoteFromAPI?.totalAmount?.value;
  const isFetchingTags = useSelector(isFetchingTagsSelector);

  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = !!accountingSoftware;
  const netSuiteTagsSet = useSelector(transactionLevelTagsSetSelector);
  const _accountingCategoryTag = useSelector(accountingCategoryTagSelector);
  const _customTags = useSelector((state) =>
    inPayrollContext
      ? payrollCustomTagsSelector(state)
      : billPayCustomTagsSelector(state)
  );
  //
  const accountingCategoryTag = useMemo(
    () =>
      netSuiteTagsSet.has(_accountingCategoryTag?.id)
        ? null
        : _accountingCategoryTag,
    [_accountingCategoryTag, netSuiteTagsSet]
  );
  const _nonCategoryTags = useSelector(accountingNonCategoryTags);
  const nonCategoryTags = useMemo(() =>
    _nonCategoryTags.filter(
      (tag) => !netSuiteTagsSet.has(tag.id),
      [_nonCategoryTags, netSuiteTagsSet]
    )
  );
  const customTags = useMemo(
    () => _customTags.filter((tag) => !netSuiteTagsSet.has(tag.id)),
    [_customTags, netSuiteTagsSet]
  );
  //
  const isFetchedTags = useSelector(isTagsFetchedSelector);
  const allTags = useMemo(
    () => [
      ...(accountingEnabled && accountingCategoryTag
        ? [accountingCategoryTag]
        : []),
      ...(accountingEnabled ? nonCategoryTags : []),
      ...customTags,
    ],
    [accountingEnabled, accountingCategoryTag, nonCategoryTags, customTags]
  );

  const isFetchingTaxOptions = useSelector(
    isFetchingPurchaseBillTaxDetailsSelector
  );
  const isPurchaseBillTaxesReady = useSelector(
    isPurchaseBillTaxesReadySelector
  );
  const additiveParentTax = useSelector(additivePurchaseTaxParentSelector);
  const additiveChildrenTax = useSelector(additivePurchaseTaxChildrenSelector);
  const subtractiveParentTax = useSelector(
    subtractivePurchaseTaxParentSelector
  );
  const subtractiveChildrenTax = useSelector(
    subtractivePurchaseTaxChildrenSelector
  );
  const submissionPolicyEnabled = useSelector(submissionPolicyEnabledSelector);
  const submissionPolicy = useSelector(
    billPayrollCreationSubmissionPolicySelector
  );
  const submissionPolicyTags = useMemo(
    () => [
      ...(accountingEnabled ? (submissionPolicy?.accountingTags ?? []) : []),
      // ignore accountingTags is software is not integrated.
      ...(submissionPolicy?.customTags ?? []),
    ],
    [submissionPolicy, accountingEnabled]
  );
  const isFetchingSubmissionPolicy = useSelector(
    isFetchingBillPayrollCreationSubmissionPolicySelector
  );
  const paymentAccounts = useSelector((state) =>
    inPayrollContext
      ? payrollWalletAsArraySelector(state)
      : paymentWalletListSelector(state)
  );
  const isLoading = useSelector(isLoadingPurchaseBillSelector);
  const isFetchingDuplicateBill = useSelector(isFetchingDuplicateBillSelector);
  const duplicateBill = useSelector(recentDuplicateBillSelector);
  const isFetchingApprovers = useSelector(isFetchingPaymentApproversSelector);
  const prefillSource = useSelector(selectedPaymentSelector);
  const prefillSourceFiles = useMemo(
    () =>
      prefillSource?.[
        inPayrollContext
          ? BILL_RESPONSE_KEYS.PAYSLIPS
          : BILL_RESPONSE_KEYS.INVOICES
      ],
    [prefillSource, inPayrollContext]
  );
  const isFetchingPrefillSource = useSelector(isPaymentFetchingSelector);
  // bank details missing or unverified

  const titleText = editModeId
    ? inPayrollContext
      ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.editBillTitle"
      : "billPay.bill.invoiceInbox.createBill.editBillTitle"
    : inPayrollContext // creation/review/ocr modes
      ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.title"
      : "billPay.bill.invoiceInbox.createBill.title";

  const titleRef = useLeftHeaderTitle({
    title: titleText,
  });
  const isFetchingLineItemsFromAPI = useSelector(isFetchingLineItemsSelector);
  const lineItemsFromAPI = useSelector(lineItemsSelector);
  const lineItemsPrefillSource =
    (ocrMode ? ocrResult?.data?.lineItems : lineItemsFromAPI) ?? [];

  // == FORM VALIDATOR OBJECT
  // need to derive this from store (createBillFormValues), since LineItems are in store, and when they change (add), other fields are lost
  // form object
  // for the 'create' mode
  // other modes built on top of this

  const initialFormValue = {
    // ocr accuracy
    [CREATE_BILL_REQUEST_BODY_KEYS.ACCURACY]: {
      value:
        createBillFormValues?.[CREATE_BILL_REQUEST_BODY_KEYS.ACCURACY] ?? "",
      validate: { required: false },
    },

    // vendorInfo
    vendor: {
      value: createBillFormValues?.vendor ?? "", // this is filled based on search
      validate: { required: true },
    },
    vendorContact: {
      value: createBillFormValues?.vendorContact ?? "",
      validate: { required: false },
    },
    employeeId: {
      value: createBillFormValues?.employeeId ?? "",
      validate: { required: inPayrollContext },
    },

    payrollMonth: {
      value: createBillFormValues?.payrollMonth ?? null,
      validate: { required: inPayrollContext },
    },

    // purpose section
    invoiceNumber: {
      value: createBillFormValues?.invoiceNumber ?? "",
      validate: { required: true },
    },
    invoiceDate: {
      value: createBillFormValues?.invoiceDate ?? "",
      validate: { required: true },
    },
    dueDate: {
      value: createBillFormValues?.dueDate ?? "",
      validate: { required: true },
    },
    memo: {
      value: createBillFormValues?.memo ?? "",
      validate: { required: false },
    },

    // LineItems section
    // line item switches
    [TAX_AT_LINE_ITEM_LEVEL_ADDITIVE]: {
      value: createBillFormValues?.[TAX_AT_LINE_ITEM_LEVEL_ADDITIVE] ?? false,
    },
    [TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE]: {
      value:
        createBillFormValues?.[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE] ?? false,
    },
    // if form fields when line item level is OFF
    ...[
      ...(inPayrollContext
        ? [
            {
              parentTax: subtractiveParentTax,
              childrenTax: subtractiveChildrenTax,
            },
          ]
        : [
            {
              parentTax: additiveParentTax,
              childrenTax: additiveChildrenTax,
            },
            {
              parentTax: subtractiveParentTax,
              childrenTax: subtractiveChildrenTax,
            },
          ]),
    ].reduce((accum, { parentTax, childrenTax }) => {
      if (!parentTax) return accum;

      // parent
      const parentKey = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
      const parentId = `${parentKey}-id`;
      const parentDropdownKey = `${parentKey}-dropdown`;
      const parentValueKey = `${parentKey}-value`;
      const parentTagValueKey = `${parentKey}-tag-value-id`;
      const parentCategoryDropdownKey = `${parentKey}-category-dropdown`;
      const parentDropdownLabelKey = `${parentKey}-dropdown-label`;
      accum[parentId] = {
        value: createBillFormValues?.[parentId] ?? "",
        validate: { required: false },
      };
      accum[parentDropdownKey] = {
        value: createBillFormValues?.[parentDropdownKey] ?? "",
        validate: { required: false },
      };
      accum[parentValueKey] = {
        value: createBillFormValues?.[parentValueKey] ?? "",
        validate: { required: false },
      };
      accum[parentTagValueKey] = {
        value: createBillFormValues?.[parentTagValueKey] ?? "",
        validate: { required: false },
      };
      accum[parentCategoryDropdownKey] = {
        value: createBillFormValues?.[parentCategoryDropdownKey] ?? "",
        validate: { required: false },
      }; // optional (not always applicable)
      accum[parentDropdownLabelKey] = {
        value: createBillFormValues?.[parentDropdownLabelKey] ?? "",
        validate: { required: false },
      };

      // children
      childrenTax.forEach((childTax) => {
        const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
        const childId = `${taxKeyName}-id`;
        const childVisibleKey = `${taxKeyName}-visible`;
        const childDropdownKey = `${taxKeyName}-dropdown`;
        const childValueKey = `${taxKeyName}-value`;
        const childTagValueKey = `${taxKeyName}-tag-value-id`;
        const childCategoryDropdownKey = `${taxKeyName}-category-dropdown`;
        const childDropdownLabelKey = `${taxKeyName}-dropdown-label`;
        const optionalSwitchKey = `${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;

        accum[optionalSwitchKey] = {
          value: createBillFormValues?.[optionalSwitchKey] ?? false,
          validate: { required: false },
        };
        accum[childId] = {
          value: createBillFormValues?.[childId] ?? "",
          validate: { required: false },
        };
        accum[childVisibleKey] = {
          value:
            createBillFormValues?.[childVisibleKey] ??
            (() => {
              const isPPNTax =
                parentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME] ===
                TAX_NAMES.PPN;
              if (!isPPNTax) return true;

              const isPPnBMApplicable =
                `${createBillFormValues?.[childDropdownKey]}` === "11" ||
                createBillFormValues?.[childDropdownLabelKey]
                  ?.toString()
                  .replaceAll(" ", "")
                  .includes("11%");

              return isPPnBMApplicable;
            })(), // by default always visible, except PPnBM (that is handled separately)
          validate: { required: false },
        };
        accum[childDropdownKey] = {
          value: createBillFormValues?.[childDropdownKey] ?? "",
          validate: { required: false },
        };
        accum[childValueKey] = {
          value: createBillFormValues?.[childValueKey] ?? "",
          validate: { required: false },
        };
        accum[childTagValueKey] = {
          value: createBillFormValues?.[childTagValueKey] ?? "",
          validate: { required: false },
        };
        accum[childCategoryDropdownKey] = {
          value: createBillFormValues?.[childCategoryDropdownKey] ?? "",
          validate: { required: false },
        }; // optional (not always applicable)
        accum[childDropdownLabelKey] = {
          value: createBillFormValues?.[childDropdownLabelKey] ?? "",
          validate: { required: false },
        };
      });
      return accum;
    }, {}),
    // if form fields when line item level is ON
    // add new form fields (due to 'Add' line item action)

    ...lineItemsForForm?.reduce((accum, lineItem) => {
      const lineItemId = lineItem[LINE_ITEM_KEYS.FEID];
      Object.entries(lineItem).forEach(([k, v]) => {
        if (Array.isArray(v)) {
          // for tags
          v.forEach((obj) => {
            Object.entries(obj).forEach(([innerK, innerV]) => {
              accum[innerK] = {
                value: innerV,
                validate: {
                  required: false,
                },
              };
            }); // example: {innerK: 'lineItem.0.accountingTags.tag-id-165', innerV: '1'}
          });
        } else {
          // for normal (string, number values)
          accum[`${LINE_ITEM_KEY}.${lineItemId}.${k}`] = {
            value: v,
            validate: {
              required: [LINE_ITEM_KEYS.AMOUNT].includes(k), // only amount is required
            },
          };
        }
      });

      return accum;
    }, {}),
    // Payment section
    paymentAccount: {
      value:
        createBillFormValues?.paymentAccount ?? paymentAccounts?.[0]?.id ?? "",
      validate: { required: true },
    },
    paymentAccountCurrency: {
      value:
        createBillFormValues?.paymentAccountCurrency ??
        paymentAccounts?.[0]?.currency ??
        "",
      validate: { required: true },
    },
    paymentMode: {
      value: createBillFormValues?.paymentMode ?? "",
      validate: { required: false },
    },
    payoutMethodType: {
      // passed to quote API only (as payment channel), not needed to be send in final payload (since we send the quote id)
      value: createBillFormValues?.payoutMethodType ?? "",
      validate: { required: true },
    },
    paymentReference: {
      value: createBillFormValues?.paymentReference ?? "",
      validate: { required: false, [USE_FORM_TYPE_OF_VALIDATION.max]: 35 },
    },
    paymentDate: {
      value: createBillFormValues?.paymentDate ?? "",
      validate: {
        required: false,
        [USE_FORM_TYPE_OF_VALIDATION.onOrAfterDate]: getConstantTimeDate(
          new Date()
        ),
      },
    },
    paymentOnApprove: {
      value:
        createBillFormValues?.paymentOnApprove ?? defaultPayOnceApprovedEnabled,
    },
    recurringPayment: {
      value: createBillFormValues?.recurringPayment ?? false,
    },
    // when recurringPayment is true (validation is properly for false case too, since DOM nodes for these are absent if recurringPayment is false)
    paymentFrequency: {
      value: createBillFormValues?.paymentFrequency ?? "",
      validate: { required: true },
    },
    nextInvoiceCreationDate: {
      // BE calls this 'next_generation_date', using it for both payroll and billpay
      value: createBillFormValues?.nextInvoiceCreationDate ?? "",
      validate: {
        required: true,
        [USE_FORM_TYPE_OF_VALIDATION.onOrAfterDate]: getConstantTimeDate(
          new Date()
        ),
      },
    },
    nextDueDate: {
      value: createBillFormValues?.nextDueDate ?? "",
      validate: {
        required: true,
        compare: {
          keys: ["nextDueDate", "nextInvoiceCreationDate"],
          comparatorFunc: (a, b) => new Date(a) >= new Date(b),
        },
        [USE_FORM_TYPE_OF_VALIDATION.onOrAfterDate]: getConstantTimeDate(
          new Date()
        ),
      },
      errorStatement: {
        compare:
          "billPay.bill.invoiceInbox.createBill.overdueRecurringWarningText",
      },
    },
    nextPaymentDate: {
      value: createBillFormValues?.nextPaymentDate ?? "",
      validate: {
        required: true,
        compare: {
          keys: ["nextPaymentDate", "nextInvoiceCreationDate"],
          comparatorFunc: (a, b) =>
            new Date(validateAndConvertToISO8601(a)) >
              getConstantTimeDate(getTomorrowDate()) &&
            new Date(validateAndConvertToISO8601(a)) >
              new Date(validateAndConvertToISO8601(b)),
        },
      },
      errorStatement: {
        compare: inPayrollContext
          ? "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.nextPaymentVsDraftDate"
          : "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.nextPaymentVsInvoiceDate",
      },
    },
    endOfRecurringPaymentCycle: {
      value: createBillFormValues?.endOfRecurringPaymentCycle ?? "",
      validate: {
        required: false,
        compare: {
          keys: ["endOfRecurringPaymentCycle", "nextInvoiceCreationDate"],
          comparatorFunc: (a, b) =>
            new Date(validateAndConvertToISO8601(a)) >
              getConstantTimeDate(getTomorrowDate()) &&
            new Date(validateAndConvertToISO8601(a)) >
              new Date(validateAndConvertToISO8601(b)),
        },
      },
      errorStatement: {
        compare: inPayrollContext
          ? "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.endCycleVsDraftDate"
          : "billPay.bill.invoiceInbox.createBill.sections.paymentDetails.endCycleVsInvoiceDate",
      },
    },
    ...(accountingEnabled && inPayrollContext
      ? { accountingPayeeId: { value: prefillSource?.accountingPayeeId ?? "" } }
      : {}),
    // Payroll non Category
    ...(accountingEnabled &&
    inPayrollContext &&
    nonCategoryTags?.length &&
    accountingCategoryTag
      ? nonCategoryTags
          ?.map((item) => ({
            [`${CREATE_BILL_REQUEST_BODY_KEYS.PAYROLL_TAG_VALUE_ATTRIBUTES}.${ACC_VALUE}${item.id}`]:
              {
                value: (() => {
                  const relatedTagInData = lineItemsPrefillSource?.[0]?.[
                    LINE_ITEMS_RESPONSE_KEYS.LINE_ITEM_TAG_VALUES.selfKey
                  ]?.find((tagData) => tagData.tagId === item.id);

                  return (
                    relatedTagInData?.customTextValue ||
                    relatedTagInData?.tagValueId
                  );
                })(),
              },
          }))
          .reduce((accumulator, currentItem) => {
            const key = Object.keys(currentItem)[0];
            accumulator[key] = currentItem[key];
            return accumulator;
          }, {})
      : {}),
    // Payroll Category
    ...(accountingEnabled && inPayrollContext && accountingCategoryTag
      ? {
          [`${CREATE_BILL_REQUEST_BODY_KEYS.PAYROLL_TAG_VALUE_ATTRIBUTES}.${ACC_VALUE}${accountingCategoryTag.id}`]:
            {
              value: (() => {
                const relatedTagInData = prefillSource?.accountingTags?.find(
                  (tagData) => tagData.tagId === accountingCategoryTag.id
                );

                return (
                  relatedTagInData?.customTextValue ||
                  relatedTagInData?.tagValueId
                );
              })(),
            },
        }
      : {}),
    ...(accountingEnabled && inPayrollContext && customTags?.length
      ? customTags
          ?.map((item) => ({
            [`${CREATE_BILL_REQUEST_BODY_KEYS.PAYROLL_TAG_VALUE_ATTRIBUTES}.cc_${item.id}`]:
              {
                value: (() => {
                  const relatedTagInData = prefillSource?.accountingTags?.find(
                    (tagData) => tagData.tagId === item.id
                  );

                  return (
                    relatedTagInData?.customTextValue ||
                    relatedTagInData?.tagValueId
                  );
                })(),
              },
          }))
          .reduce((accumulator, currentItem) => {
            const key = Object.keys(currentItem)[0];
            accumulator[key] = currentItem[key];
            return accumulator;
          }, {})
      : {}),

    // radio value (one is always selected so no validaion required)
    linkedTo: {
      value: [
        createBillFormValues?.linkedTo,
        vendorDetails?.projectId,
      ].includes(ownerDepartmentId)
        ? VENDOR_LINKED_TO_TYPES.DEPARTMENT
        : VENDOR_LINKED_TO_TYPES.PROJECT,
      // prefill in create, edit mode will be fine. Ok.
      validate: {
        required: true,
      },
    },
    // dropdown, will be hidden if radio type department is selected
    // if visible it is required
    projectId: {
      value: createBillFormValues?.projectId || vendorDetails?.projectId, // prefill in create, edit mode will be fine. Ok.
      validate: {
        required: true,
      },
    },
    projectName: {
      value: createBillFormValues?.projectName || vendorDetails?.projectName,
      validate: {
        required: true,
      },
    },
    ystrSendAmount: {
      value: createBillFormValues?.ystrSendAmount || "",
      validate: {
        required: true,
        minNumber: 0,
      },
      errorStatement: {
        minNumber: "misc.valueShouldBeGTZero",
      },
    },
    isYSTRInCharge: {
      value: createBillFormValues?.isYSTRInCharge || false,
      validate: {
        required: false,
      },
    },
    isYSTREditEnabled: {
      value: createBillFormValues?.isYSTREditEnabled || false,
      validate: {
        required: false,
      },
    },
  };

  useEffect(() => {
    if (vendorDetails?.id)
      dispatch(fetchPaymentModes({ vendor_id: vendorDetails?.id }));
  }, [vendorDetails?.id]);
  //
  useEffect(() => {
    if (isFetchingPaymentMode) return;

    const paymentMode = [paymentModesList]?.find(
      (item) => item?.name === currentProvider
    );

    const amountValue = amountToBeReceivedByVendor || 0;
    setPaymentModeOptions(
      paymentMode?.options?.map((option) => {
        const optionMinValue = option.min || 0;
        const optionMaxValue = option.max || Infinity;

        const currentSelectedValue = values.payoutMethodType;
        const isInRange =
          amountValue >= optionMinValue && amountValue <= optionMaxValue;
        if (!isInRange && option.value === currentSelectedValue) {
          setValues((prev) => ({
            ...prev,
            payoutMethodType: "",
          }));
        }

        return { ...option, disabled: !isInRange };
      })
    );
  }, [amountToBeReceivedByVendor, paymentModesList, isFetchingPaymentMode]);

  useEffect(() => {
    // if ref exists, don't prefill. If coming back from vendor create, prefill.
    setIsOnComeBackPrefill(!newVendorNameInBillpay && !!createBillRef);

    if (createBillRef) {
      createBillFlowContext.current = structuredClone(createBillRef);

      if (createBillFormValues?.vendor) {
        dispatch(
          fetchAndSelectVendor({
            id: createBillFormValues?.vendor, // from initial value stored in Redux
          })
        );
      }
    }
  }, []);
  //= PREFILL
  // A. DATA
  // Prefill: the idea is to change the useForm argument, and then let the edit mode behave just like create

  // B. prefill config
  // specifies prefill config used in edit mode
  // to be used on top of 'create' mode form object
  /**
   * @param {Object} sliderData data response (i.e. slider data)
   * @param {Object} givenTax   tax API response
   *
   * @returns {Object} matching tax object
   */
  const getMatchingTaxData = (sliderData, givenTax) => {
    const taxes = sliderData?.[BILL_RESPONSE_KEYS.TAXES_KEY] ?? [];
    const parentTaxMatchingData = taxes.find(
      (taxItemInData) =>
        givenTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME] ===
        taxItemInData?.[BILL_RESPONSE_KEYS.TAX_ITEM.NAME]
    );
    return parentTaxMatchingData;
  };
  /**
   * @param {String} categoryLabel data response (i.e. slider data)
   * @param {Object} tax   tax API response
   *
   * @returns {Object}  dropdown keys
   */
  const getCategoryAndSuperCategoryKeys = (categoryLabel, tax) => {
    const superCategories = tax[CREATE_BILL_TAX_RESPONSE.CATEGORIES] ?? [];
    let foundSuperCategoryValue = "";
    superCategories.forEach((category) => {
      const nestedOptions =
        category[CREATE_BILL_TAX_RESPONSE.NESTED_CATEGORIES] ?? [];
      const matchingNestedOption = nestedOptions.find((nestedOption) => {
        return nestedOption[CREATE_BILL_TAX_RESPONSE.LABEL] === categoryLabel;
      });

      if (matchingNestedOption) {
        // for nested taxes
        foundSuperCategoryValue =
          category?.[CREATE_BILL_TAX_RESPONSE.VALUE] ?? "";
      }
    });

    return {
      category: categoryLabel,
      superCategory: foundSuperCategoryValue,
    };
  };
  /**
   * @param {String} categoryLabel data response (i.e. slider data)
   * @param {Object} tax   tax API response
   *
   * @returns {Object}  dropdown keys
   */
  const getDropdownKeysBasedOnOCRRate = ({ rate }, tax) => {
    const superCategories = tax[CREATE_BILL_TAX_RESPONSE.CATEGORIES] ?? [];
    let foundCategoryValue = "";
    let foundSuperCategoryValue = "";
    superCategories.forEach((category) => {
      const nestedOptions =
        category[CREATE_BILL_TAX_RESPONSE.NESTED_CATEGORIES] ?? [];
      const matchingNestedOption = nestedOptions.find((nestedOption) => {
        // Dont assume empty to be zero, accept it as missing.
        return (
          rate !== "" &&
          rate !== undefined &&
          rate !== null &&
          Number(nestedOption[CREATE_BILL_TAX_RESPONSE.VALUE]) === Number(rate)
        );
      });

      if (matchingNestedOption) {
        // for nested taxes
        foundSuperCategoryValue =
          category?.[CREATE_BILL_TAX_RESPONSE.VALUE] ?? "";

        foundCategoryValue =
          matchingNestedOption[CREATE_BILL_TAX_RESPONSE.LABEL] ?? "";
      }
      // Dont assume empty rate to be zero, accept it as missing.
      if (
        rate !== "" &&
        rate !== undefined &&
        rate !== null &&
        Number(category?.[CREATE_BILL_TAX_RESPONSE.VALUE]) === Number(rate)
      ) {
        // for simple (non nested tax)
        foundCategoryValue = category?.[CREATE_BILL_TAX_RESPONSE.LABEL];
      }
    });

    return {
      category: foundCategoryValue,
      superCategory: foundSuperCategoryValue,
    };
  };

  const editMap = {
    vendor: (data) => data?.vendor?.id || vendorDetails?.id,
    // OR fixes a bug (draft with line items is opened, and vendor is selected, was blanking out vendorSelected)

    employeeId: "employeeId",
    vendorContact: "",
    invoiceNumber: "invoiceNumber",
    invoiceDate: "invoiceDate",
    dueDate: "dueDate",
    memo: "memo",
    [CREATE_BILL_REQUEST_BODY_KEYS.ACCURACY]: "accuracy", // [0, 100]

    // prefill form fields when line item level is OFF
    ...[
      ...(inPayrollContext
        ? [
            {
              parentTax: subtractiveParentTax,
              childrenTax: subtractiveChildrenTax,
            },
          ]
        : [
            {
              parentTax: additiveParentTax,
              childrenTax: additiveChildrenTax,
            },
            {
              parentTax: subtractiveParentTax,
              childrenTax: subtractiveChildrenTax,
            },
          ]),
    ].reduce((accum, { parentTax, childrenTax }) => {
      // TECH_DEBT: O(n**2)
      if (!parentTax) return accum;

      // helper function
      // parent
      const parentKey = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
      const parentId = `${parentKey}-id`;
      const parentDropdownKey = `${parentKey}-dropdown`;
      const parentValueKey = `${parentKey}-value`;
      const parentTagValueKey = `${parentKey}-tag-value-id`;
      const parentCategoryDropdownKey = `${parentKey}-category-dropdown`;
      const parentDropdownLabelKey = `${parentKey}-dropdown-label`;

      accum[parentId] = (data) =>
        getMatchingTaxData(data, parentTax)?.[BILL_RESPONSE_KEYS.TAX_ITEM.ID] ??
        "";

      accum[parentDropdownKey] = (data) => {
        return (
          getMatchingTaxData(data, parentTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.RATE
          ] ?? ""
        );
      };

      accum[parentValueKey] = (data) =>
        getMatchingTaxData(data, parentTax)?.[
          BILL_RESPONSE_KEYS.TAX_ITEM.VALUE
        ] ?? "";
      accum[parentTagValueKey] = (data) =>
        getMatchingTaxData(data, parentTax)?.[
          BILL_RESPONSE_KEYS.TAX_ITEM.TAG_VALUE_ID
        ] ?? "";

      accum[parentCategoryDropdownKey] = (data) => {
        const parentDropdownLabelKey_ =
          getMatchingTaxData(data, parentTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
          ] ?? "";

        return getCategoryAndSuperCategoryKeys(
          parentDropdownLabelKey_,
          parentTax
        ).superCategory;
      }; // optional (not always applicable)
      accum[parentDropdownLabelKey] = (data) =>
        getMatchingTaxData(data, parentTax)?.[
          BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
        ] ?? "";

      // children
      childrenTax.forEach((childTax) => {
        const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
        const childId = `${taxKeyName}-id`;
        const childVisibleKey = `${taxKeyName}-visible`;
        const childDropdownKey = `${taxKeyName}-dropdown`;
        const childValueKey = `${taxKeyName}-value`;
        const childTagValueKey = `${taxKeyName}-tag-value-id`;
        const childCategoryDropdownKey = `${taxKeyName}-category-dropdown`;
        const optionalSwitchKey = `${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;
        const childDropdownLabelKey = `${taxKeyName}-dropdown-label`;

        accum[optionalSwitchKey] = (data) => {
          const parentTaxMatchingData = getMatchingTaxData(data, childTax);
          return !!(
            parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.RATE] ||
            parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.VALUE] ||
            parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY]
          );
        };

        accum[childId] = (data) =>
          getMatchingTaxData(data, childTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.ID
          ] ?? "";
        accum[childVisibleKey] = (data) => {
          const isPPNTax =
            parentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME] === TAX_NAMES.PPN;
          if (!isPPNTax) return true; // by default always visible, except PPnBM (that is handled separately)

          const relevantTax = getMatchingTaxData(data, parentTax);
          const isPPnBMApplicable =
            `${relevantTax?.[BILL_RESPONSE_KEYS.TAX_ITEM.RATE]}` === "11" ||
            relevantTax?.[BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY]
              ?.toString()
              .replaceAll(" ", "")
              .includes("11%");

          return isPPnBMApplicable;
        };

        accum[childDropdownKey] = (data) => {
          return (
            getMatchingTaxData(data, childTax)?.[
              BILL_RESPONSE_KEYS.TAX_ITEM.RATE
            ] ?? ""
          );
        };

        accum[childValueKey] = (data) =>
          getMatchingTaxData(data, childTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.VALUE
          ] ?? "";

        accum[childTagValueKey] = (data) =>
          getMatchingTaxData(data, childTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.TAG_VALUE_ID
          ] ?? "";

        accum[childCategoryDropdownKey] = (data) => {
          const childDropdownKey_ =
            getMatchingTaxData(data, childTax)?.[
              BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
            ] ?? "";

          return getCategoryAndSuperCategoryKeys(childDropdownKey_, childTax)
            .superCategory;
        }; // optional (not always applicable)}

        accum[childDropdownLabelKey] = (data) =>
          getMatchingTaxData(data, childTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
          ] ?? "";
      });

      return accum;
    }, {}),

    paymentMode: "paymentMode",
    paymentAccount: (data) =>
      paymentAccounts.find(
        (wallet) => wallet.currency === data?.quote?.fromCurrency
      )?.id ?? "",
    paymentAccountCurrency: `quote.${GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.FROM_CURRENCY}`,
    payoutMethodType: `quote.${GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.PAYMENT_CHANNEL}`,
    paymentReference: "paymentReference",
    paymentDate: "paymentDate",
    payrollMonth: "payrollMonth",
    paymentOnApprove: "paymentOnApprove",

    recurringPayment: (data) => !!data.recurringPayment,
    paymentFrequency: "recurringPayment.frequency",
    nextInvoiceCreationDate: inPayrollContext
      ? "recurringPayment.nextPayrollDate"
      : "recurringPayment.nextInvoiceCreationDate",
    nextDueDate: "recurringPayment.nextDueDate",
    nextPaymentDate: "recurringPayment.nextPaymentDate",
    endOfRecurringPaymentCycle: "recurringPayment.endDate",
    linkedTo: "linkedTo.type",
    projectId: "linkedTo.id",
    projectName: "linkedTo.name",

    ystrSendAmount: `quote.${GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.TOTAL_AMOUNT}.value`,
    isYSTRInCharge: (data) =>
      data?.quote?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.FIXED_SIDE] ===
      FIXED_SIDE.SELL,

    ...lineItemsForForm.reduce((accum, lineItem) => {
      const lineItemId = lineItem[LINE_ITEM_KEYS.FEID];
      Object.entries(lineItem).forEach(([k, v]) => {
        accum[`${LINE_ITEM_KEY}.${lineItemId}.${k}`] = () => v; // as per `getInitalFormValues` structure
      });

      return accum;
    }, {}),
  };

  // ocr mode
  const ocrMap = {
    vendor: () => vendorDetails?.id,
    vendorContact: () => vendorDetails?.contactDetails?.[0]?.id,
    invoiceNumber: () => ocrResult?.data?.invoiceNumber,
    invoiceDate: () => ocrResult?.data?.invoiceDate,
    dueDate: () => ocrResult?.data?.paymentDueDate,
    [CREATE_BILL_REQUEST_BODY_KEYS.ACCURACY]: () => ocrResult?.data?.accuracy, // [0, 100]

    // have to do a stricter check in OCR, because the OCR service sometimes sends derived tax as if it was bill level tax
    // (overshadowing line item level tax)
    // so checking w.r.t line item level only, and ignore bill level
    // assumption: all line items are detected fine (or none are), and taking the first as representative
    [TAX_AT_LINE_ITEM_LEVEL_ADDITIVE]: () => {
      const firstLineItem = ocrResult?.data?.lineItems?.[0];
      return (
        (firstLineItem?.[BILL_RESPONSE_KEYS.TAXES_KEY] ?? []).filter(
          (item) => !item?.[BILL_RESPONSE_KEYS.TAX_ITEM.DEDUCTIVE_TAX]
        ).length > 0
      );
    },
    //
    [TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE]: () => {
      const firstLineItem = ocrResult?.data?.lineItems?.[0];

      return (
        (firstLineItem?.[BILL_RESPONSE_KEYS.TAXES_KEY] ?? []).filter(
          (item) => item?.[BILL_RESPONSE_KEYS.TAX_ITEM.DEDUCTIVE_TAX]
        ).length > 0
      );
    },

    // prefill form fields when line item level is OFF
    // this is for OCR, but tax enums are the same (as BE), so reusing them
    ...[
      ...(inPayrollContext
        ? [
            {
              parentTax: subtractiveParentTax,
              childrenTax: subtractiveChildrenTax,
            },
          ]
        : [
            {
              parentTax: additiveParentTax,
              childrenTax: additiveChildrenTax,
            },
            {
              parentTax: subtractiveParentTax,
              childrenTax: subtractiveChildrenTax,
            },
          ]),
    ].reduce((accum, { parentTax, childrenTax }) => {
      // TECH_DEBT: O(n**2)
      if (!parentTax) return accum;

      const { data = null } = ocrResult;
      const parentTaxRate = getMatchingTaxData(data, parentTax)?.[
        BILL_RESPONSE_KEYS.TAX_ITEM.RATE
      ];
      const subTotal = ocrResult?.data?.lineItems.reduce(
        (subTotalAccum, item) => {
          const lineItemAmount =
            item[LINE_ITEMS_RESPONSE_KEYS.AMOUNT]?.value ||
            item[LINE_ITEMS_RESPONSE_KEYS.AMOUNT];

          return subTotalAccum + lineItemAmount;
        },
        0
      );

      // helper function
      // parent
      const parentKey = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
      const parentId = `${parentKey}-id`;
      const parentDropdownKey = `${parentKey}-dropdown`;
      const parentValueKey = `${parentKey}-value`;
      const parentTagValueKey = `${parentKey}-tag-value-id`;
      const parentCategoryDropdownKey = `${parentKey}-category-dropdown`;
      const parentDropdownLabelKey = `${parentKey}-dropdown-label`;

      accum[parentId] = () =>
        getMatchingTaxData(data, parentTax)?.[BILL_RESPONSE_KEYS.TAX_ITEM.ID] ??
        ""; //
      accum[parentDropdownKey] = () => {
        return (
          getMatchingTaxData(data, parentTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.RATE
          ] ?? ""
        );
      };
      accum[parentValueKey] = () =>
        getMatchingTaxData(data, parentTax)?.[
          BILL_RESPONSE_KEYS.TAX_ITEM.VALUE
        ] ??
        (getTaxAmount(subTotal ?? 0, parentTaxRate, true) || "");

      accum[parentTagValueKey] = () =>
        getMatchingTaxData(data, parentTax)?.[
          BILL_RESPONSE_KEYS.TAX_ITEM.TAG_VALUE_ID
        ];

      accum[parentCategoryDropdownKey] = () => {
        const parentDropdownLabelKey_ =
          getMatchingTaxData(data, parentTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
          ] ?? "";

        return getDropdownKeysBasedOnOCRRate(parentDropdownLabelKey_, parentTax)
          .superCategory;
      }; // optional (not always applicable)
      accum[parentDropdownLabelKey] = () =>
        getMatchingTaxData(data, parentTax)?.[
          BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
        ] ?? "";

      // children
      childrenTax.forEach((childTax) => {
        const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
        const childId = `${taxKeyName}-id`;
        const childVisibleKey = `${taxKeyName}-visible`;
        const childDropdownKey = `${taxKeyName}-dropdown`;
        const childValueKey = `${taxKeyName}-value`;
        const childTagValueKey = `${taxKeyName}-tag-value-id`;
        const childCategoryDropdownKey = `${parentKey}-category-dropdown`;
        const optionalSwitchKey = `${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;
        const childDropdownLabelKey = `${taxKeyName}-dropdown-label`;

        accum[optionalSwitchKey] = () => {
          const parentTaxMatchingData = getMatchingTaxData(data, childTax);
          return !!(
            parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.RATE] ||
            parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.VALUE]
          );
        };
        accum[childId] = () =>
          getMatchingTaxData(data, childTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.ID
          ] ?? "";
        accum[childVisibleKey] = () => {
          const isPPNTax =
            parentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME] === TAX_NAMES.PPN;
          if (!isPPNTax) return true; // by default always visible, except PPnBM (that is handled separately)

          const relevantTax = getMatchingTaxData(data, parentTax);
          const isPPnBMApplicable =
            `${relevantTax?.[BILL_RESPONSE_KEYS.TAX_ITEM.RATE]}` === "11" ||
            relevantTax?.[BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY]
              ?.toString()
              .replaceAll(" ", "")
              .includes("11%");

          return isPPnBMApplicable;
        };

        accum[childDropdownKey] = () => {
          return (
            getMatchingTaxData(data, childTax)?.[
              BILL_RESPONSE_KEYS.TAX_ITEM.RATE
            ] ?? ""
          );
        };

        accum[childValueKey] = () =>
          getMatchingTaxData(data, childTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.VALUE
          ] ?? "";

        accum[childTagValueKey] = () =>
          getMatchingTaxData(data, childTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.TAG_VALUE_ID
          ] ?? "";

        accum[childCategoryDropdownKey] = () => {
          const childDropdownKey_ =
            getMatchingTaxData(data, childTax)?.[
              BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
            ] ?? "";

          return getDropdownKeysBasedOnOCRRate(childDropdownKey_, childTax)
            .superCategory;
        }; // optional (not always applicable)

        accum[childDropdownLabelKey] = () =>
          getMatchingTaxData(data, childTax)?.[
            BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
          ] ?? "";
      });

      return accum;
    }, {}),
  };

  const reviewMap = editMap;
  const reCreateMap = editMap;
  // employeeId: "employeeId", // problem (only if creatable used)
  // payoutMethodType: "payoutMethodType", // problem

  // E. misc edit flow changes

  // END of prefilling code

  // == FORM VALIDATOR HOOK
  const onSuccess = (responseData) => {
    // decide as per mode
    if (reviewModeId) {
      searchParams.delete(
        inPayrollContext
          ? SLIDERS_SEARCH_PARAMS.payrollPayments.reviewSalaryBill
          : SLIDERS_SEARCH_PARAMS.payments.reviewBill
      );
    } else if (editModeId) {
      searchParams.delete(
        inPayrollContext
          ? SLIDERS_SEARCH_PARAMS.payrollPayments.editBill
          : SLIDERS_SEARCH_PARAMS.payments.editBill
      );
    } else if (reCreateModeId) {
      searchParams.delete(
        inPayrollContext
          ? SLIDERS_SEARCH_PARAMS.payrollPayments.reCreateSalaryBill
          : SLIDERS_SEARCH_PARAMS.payments.reCreateBill
      );
    } else if (createMode) {
      searchParams.delete(
        inPayrollContext
          ? SLIDERS_SEARCH_PARAMS.payrollPayments.createSalary
          : SLIDERS_SEARCH_PARAMS.payments.createBillDetails
      );
    }
    searchParams.delete(SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey);
    searchParams.delete(
      SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider
    );

    if (responseData.status !== PAYMENT_STATUSES.inDraft) {
      dispatch(removePurchaseBill(id)); // review draft case
      dispatch(setPayment({ id: responseData?.id, value: responseData })); // for approval edit bill case
      dispatch(setSelectedPayment(responseData)); // for approval in slider edit bill case
    }

    // delete files (uploaded files that have been removed)
    const existingFiles = prefillSourceFiles; // all have `id`
    const filesInViewer = storedFiles; // some have `id`, some don't
    const filesInViwerIdSet = new Set(filesInViewer.map((item) => item.id));
    const filesToDelete = existingFiles.filter(
      (item) => !filesInViwerIdSet.has(item.id)
    );
    const onLastFileDeletion = () => {
      setIsLeftButtonLoading(false);
      dispatch(setIsLoading(false));
      setSearchParams(searchParams);
      closeDrawer();

      // close modals, safe op, no harm if no modal exist
      dispatch(closeAppModal());
      dispatch(setAppModalData(null));
    };
    filesToDelete.forEach((item, idx, { length }) => {
      dispatch(
        deleteAttachment({
          id: item.id,
          onSuccess: () => {
            if (idx === length - 1) {
              onLastFileDeletion();
            }
          },
        })
      );
    });
    if (!filesToDelete.length) {
      onLastFileDeletion();
    }
  };
  const onError = () => {
    setIsLeftButtonLoading(false);
    dispatch(setIsLoading(false));
  };
  const isVendorAlert =
    vendorDetails &&
    (!vendorDetails?.bankDetailsPresent ||
      [VENDOR_STATUS.MISSING_VENDOR_DETAILS, VENDOR_STATUS.UNVERIFIED].includes(
        vendorDetails?.status
      )); // alerts for vendor action yet to be done

  // TECH_DEBT useform array validation is not working
  const isReceiptRequired = !!(
    submissionPolicy?.receipt?.required &&
    amountToBeReceivedByVendor > Number(submissionPolicy?.receipt?.amount || 0)
  );
  const isReceiptError = isReceiptRequired && !storedFiles.length;

  const onSubmit = async (
    formEvent,
    latestValues,
    resetValues,
    { fieldsVisibility, setErrors },
    isLeftButtonSubmit = false
  ) => {
    const arrayFiedPayload = generatePayloadFromFormValue(latestValues);

    if (isLeftButtonSubmit) {
      if (inPayrollContext && (isVendorAlert || !latestValues.vendor)) {
        // payroll draft requires atleast active employee
        return;
      }
      // validation is not needed for save draft
      setIsLeftButtonLoading(true);
    } else {
      // do validation like usual

      if (
        isVendorAlert ||
        isAmountZero ||
        ((isMemoError || isReceiptError) && !isPaymentAddDateMode) ||
        (() => {
          // This IFFE checks if atleast one occurence of submission policy custom tags are present (considers all line items)
          const submissionPolicyTagsSet = new Set(
            submissionPolicyTags
              .filter(
                (tag) =>
                  tag.required &&
                  amountToBeReceivedByVendor > Number(tag.amount || 0)
              )
              .map((item) => item.tagId)
          );
          const fullfilledTags = new Set();
          arrayFiedPayload.lineItem.forEach((lineItem) => {
            Object.entries(lineItem.accountingTags ?? {}).forEach(([k, v]) => {
              const tagId = k.split("tag-id-").at(-1);
              const isFulfilledCustomTag = !!(
                tagId &&
                submissionPolicyTagsSet.has(+tagId) &&
                v
              );
              if (isFulfilledCustomTag) fullfilledTags.add(+tagId);
            });
          });

          const isFullFilledRequiredTags =
            JSON.stringify(Array.from(fullfilledTags).toSorted()) ===
            JSON.stringify(Array.from(submissionPolicyTagsSet).toSorted());

          if (!isFullFilledRequiredTags) {
            setErrors((prev) => {
              const missingTagsErrors = Array.from(
                submissionPolicyTagsSet
              ).reduce((accum, tagId) => {
                if (fullfilledTags.has(tagId)) return accum; // filled, ignore

                const formTagKey = `${LINE_ITEM_KEY}.0.${LINE_ITEM_KEYS.ACCCOUNTING_TAGS}.tag-id-${tagId}`; // just show on the first item
                return {
                  ...accum,
                  [formTagKey]: t("misc.required"),
                };
              }, {});

              return {
                ...prev,
                ...missingTagsErrors,
              };
            });
          }

          return !isFullFilledRequiredTags; // return true if violated
        })()
      ) {
        const message = t(
          "billPay.bill.invoiceInbox.createBill.warnings.requirementMissing"
        );
        const desc = t(
          "billPay.bill.invoiceInbox.createBill.warnings.requirementMissingDesc"
        );
        vToast({
          title: message,
          description: desc,
          variant: "warning",
        });
        console.warn(message, {
          isVendorAlert,
          isAmountZero,
          isMemoError,
          isReceiptError,
        });
        onError();
        return;
      }
      dispatch(setIsLoading(true));
    }

    const k = CREATE_BILL_REQUEST_BODY_KEYS;
    const v = arrayFiedPayload;

    const lineItemsLatestFromForm = v[LINE_ITEM_KEY];
    // because the store entity is just for 'Add' operation count

    const payloadObject = {
      [k.ACCURACY]: v[k.ACCURACY],
      // vendorInfo
      [k.VENDOR_ID]: v.vendor,
      [k.VENDOR_CONTACT]: v.vendorContact,
      [k.EMPLOYEE_ID]: v.employeeId,

      // purpose section
      [k.INVOICE_NUMBER]: v.invoiceNumber,
      [k.INVOICE_DATE]: getDateInPattern(v.invoiceDate),
      [k.DUE_DATE]: getDateInPattern(v.dueDate),
      [k.MEMO]: v.memo,

      // line items section
      [k.LINE_ITEMS_ATTRIBUTES.selfKey]: lineItemsLatestFromForm
        ?.map((item, itemIdx) => {
          const k2 = k.LINE_ITEMS_ATTRIBUTES;

          const isIgnoreLineItem =
            !item[LINE_ITEM_KEYS.BEID] && item[LINE_ITEM_KEYS._DESTROY]; // item created in UI, then deleted in UI
          if (isIgnoreLineItem) return null;

          // amount, description
          const lineItemPayload = {
            ...(item[LINE_ITEM_KEYS.BEID] && !reCreateModeId
              ? { [k2.ID]: item[LINE_ITEM_KEYS.BEID] ?? null }
              : {}),

            [k2.AMOUNT]: item[LINE_ITEM_KEYS.AMOUNT],
            [k2.DESCRIPTION]:
              (inPayrollContext
                ? v.memo
                : (item[LINE_ITEM_KEYS.DESCRIPTION] ??
                  item[LINE_ITEM_KEYS.ACCOUNTING_DESCRIPTION])) ?? "",
          };

          // don't add _destroy: false, to avoid possible contradiction and confusion
          if (
            item[LINE_ITEM_KEYS.BEID] &&
            item[LINE_ITEM_KEYS._DESTROY] &&
            !reCreateModeId
          ) {
            lineItemPayload[k2._DESTROY] = true;
          }

          // accounting tags
          lineItemPayload[
            inPayrollContext
              ? k2.ACCOUNTING_TAGS.selfKeyPayroll
              : k2.ACCOUNTING_TAGS.selfKey
          ] = allTags
            // ?.filter((tag) => tag.id !== accountingCategoryTag?.id)
            .map((tag) => {
              const prefix = `${LINE_ITEM_KEY}.${item[LINE_ITEM_KEYS.FEID]}.${
                LINE_ITEM_KEYS.ACCCOUNTING_TAGS
              }`;
              const tagValueKey = `${prefix}.tag-id-${tag.id}`;
              const tagBEIDKey = `${prefix}.tag-be-id-${tag.id}`;

              const isBlank = !latestValues?.[tagValueKey];

              return {
                ...(latestValues[tagBEIDKey]
                  ? {
                      [k2.ACCOUNTING_TAGS.ID]: latestValues[tagBEIDKey],
                      ...(isBlank
                        ? { [k2.ACCOUNTING_TAGS._DESTROY]: isBlank }
                        : {}),
                    }
                  : {}),
                ...(latestValues[tagValueKey]
                  ? {
                      [k2.ACCOUNTING_TAGS.TAG_ID]: tag.id,
                      [tag.fieldType === TAG_FIELD_TYPES.LIST
                        ? k2.ACCOUNTING_TAGS.TAG_VALUE_ID
                        : k2.ACCOUNTING_TAGS.TAG_TEXT_VALUE]:
                        latestValues?.[tagValueKey],
                    }
                  : {}),
              };
            })
            .filter((tagItem) => Object.keys(tagItem).length);

          //  tax (apply at line item ON)
          const lineItemId = itemIdx; // (data going out), make sure there are no middle values. line item id is not important to BE
          const lineItemTaxAttributes = []; // e.g. [{ name: 'GST', tax_rate: 18 }, { name: 'PPnBM', tax_rate: 12.5 },]
          (inPayrollContext
            ? [
                {
                  parentTax: subtractiveParentTax,
                  childrenTax: subtractiveChildrenTax,
                  consider:
                    subtractiveParentTax &&
                    v[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE],
                },
              ]
            : [
                {
                  parentTax: additiveParentTax,
                  childrenTax: additiveChildrenTax,
                  consider:
                    additiveParentTax && v[TAX_AT_LINE_ITEM_LEVEL_ADDITIVE],
                },
                {
                  parentTax: subtractiveParentTax,
                  childrenTax: subtractiveChildrenTax,
                  consider:
                    subtractiveParentTax &&
                    v[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE],
                },
              ]
          ).forEach(({ parentTax, childrenTax, consider }) => {
            if (!parentTax) return;

            // parent
            const parentTaxName = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
            const parentKey = `${LINE_ITEM_KEY}.${lineItemId}.${
              LINE_ITEM_KEYS.TAX
            }.${parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]}`;

            const parentId = `${parentKey}-id`;
            const parentDropdownKey = `${parentKey}-dropdown`;
            const parentValueKey = `${parentKey}-value`;
            const parentTagValueKey = `${parentKey}-tag-value-id`;
            const parentCategoryDropdownKey = `${parentKey}-category-dropdown`;
            const parentDropdownLabelKey = `${parentKey}-dropdown-label`;

            const isIgnoreTaxItem = !latestValues[parentId] && !consider; // item created in UI, then deleted in UI, send nothing
            if (!isIgnoreTaxItem) {
              const isBlank = [latestValues[parentValueKey]].every(
                (val) => !val && val !== 0
              );

              // 1. blank - dont send
              // 2. blanked by user - delete (id exists)
              // 3. filled handled
              // 4. switch off - delete (id exists)

              const taxObj = {
                ...(latestValues[parentId]
                  ? { [k2.TAXES_ATTRIBUTES.ID]: latestValues[parentId] }
                  : {}),
                // if id exists, i.e. BE has it, but the node has gone invisible (due to line item level switch)
                // then, mark is _destroy true
                ...(latestValues[parentId] && (!consider || isBlank)
                  ? { [k2.TAXES_ATTRIBUTES._DESTROY]: true }
                  : {}),
                ...(isBlank
                  ? {}
                  : {
                      [k2.TAXES_ATTRIBUTES.NAME]: parentTaxName,
                      [k2.TAXES_ATTRIBUTES.TAX_RATE]:
                        latestValues[parentDropdownKey] ?? 0,
                      [k2.TAXES_ATTRIBUTES.TAX_VALUE]:
                        latestValues[parentValueKey] ?? 0,
                      //
                      ...(latestValues[parentTagValueKey]
                        ? {
                            [k2.TAXES_ATTRIBUTES.TAG_VALUE_ID]:
                              latestValues[parentTagValueKey],
                          }
                        : {}),
                      [k2.TAXES_ATTRIBUTES.CATEGORY]:
                        latestValues[parentDropdownLabelKey] ?? "",
                    }),
              };
              if (Object.keys(taxObj).length)
                lineItemTaxAttributes.push(taxObj);
            }

            // children
            childrenTax.forEach((childTax) => {
              const childTaxName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];

              const prefix = `${LINE_ITEM_KEY}.${lineItemId}.${LINE_ITEM_KEYS.TAX}`;
              const inputKey = `${prefix}.${childTaxName}`;
              const switchKey = `${inputKey}-${TAX_KEYS.OPTIONAL_ENABLED}`;

              const childId = `${inputKey}-id`;
              const childVisibleKey = `${inputKey}-visible`; // TODO FE key, not used
              const childDropdownKey = `${inputKey}-dropdown`;
              const childValueKey = `${inputKey}-value`;
              const childTagValueKey = `${inputKey}-tag-value-id`;
              const childCategoryDropdownKey = `${inputKey}-category-dropdown`;
              const childDropdownLabelKey = `${inputKey}-dropdown-label`;
              const isIgnoreChildTaxItem = !latestValues[childId] && !consider; // item created in UI, then deleted in UI, send nothing

              if (!isIgnoreChildTaxItem) {
                const optionalSwitchEnabled = latestValues[switchKey];
                const isBlank = [latestValues[childValueKey]].every(
                  (val) => !val && val !== 0
                );

                // 1. blank - dont send
                // 2. blanked by user - delete (id exists)
                // 3. filled handled
                // 4. switch off - delete (id exists)

                const taxObj = {
                  ...(latestValues[childId]
                    ? { [k2.TAXES_ATTRIBUTES.ID]: latestValues[childId] }
                    : {}),
                  ...(latestValues[childId] &&
                  (!consider || !optionalSwitchEnabled || isBlank)
                    ? { [k2.TAXES_ATTRIBUTES._DESTROY]: true }
                    : {}),

                  ...(optionalSwitchEnabled && !isBlank
                    ? {
                        [k2.TAXES_ATTRIBUTES.NAME]: childTaxName,
                        [k2.TAXES_ATTRIBUTES.TAX_RATE]:
                          latestValues[childDropdownKey] ?? 0,
                        [k2.TAXES_ATTRIBUTES.TAX_VALUE]:
                          latestValues[childValueKey] ?? 0,
                        //
                        ...(latestValues[childTagValueKey]
                          ? {
                              [k2.TAXES_ATTRIBUTES.TAG_VALUE_ID]:
                                latestValues[childTagValueKey],
                            }
                          : {}),
                        [k2.TAXES_ATTRIBUTES.CATEGORY]:
                          latestValues[childDropdownLabelKey] ?? "",
                      }
                    : {}),
                };
                if (Object.keys(taxObj).length)
                  lineItemTaxAttributes.push(taxObj);
              }
            });
          });
          lineItemPayload[k2.TAXES_ATTRIBUTES.selfKey] = lineItemTaxAttributes;

          return lineItemPayload;
        })
        .filter((item) => item && item[k.LINE_ITEMS_ATTRIBUTES.AMOUNT]),
      // tax (apply at line item OFF)
      [k.TAXES_ATTRIBUTES.selfKey]: (inPayrollContext
        ? [
            {
              parentTax: subtractiveParentTax,
              childrenTax: subtractiveChildrenTax,
              consider:
                subtractiveParentTax && !v[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE],
            },
          ]
        : [
            {
              parentTax: additiveParentTax,
              childrenTax: additiveChildrenTax,
              consider:
                additiveParentTax && !v[TAX_AT_LINE_ITEM_LEVEL_ADDITIVE],
            },
            {
              parentTax: subtractiveParentTax,
              childrenTax: subtractiveChildrenTax,
              consider:
                subtractiveParentTax && !v[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE],
            },
          ]
      )
        .reduce((taxAttributesAccum, { parentTax, childrenTax, consider }) => {
          if (!parentTax) return taxAttributesAccum;

          // parent
          const parentTaxName = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
          const parentKey = parentTaxName;

          const parentId = `${parentKey}-id`;
          const parentCategoryDropdownKey = `${parentKey}-category-dropdown`;
          const parentDropdownKey = `${parentKey}-dropdown`;
          const parentValueKey = `${parentKey}-value`;
          const parentTagValueKey = `${parentKey}-tag-value-id`;
          const parentDropdownLabelKey = `${parentKey}-dropdown-label`;
          const isIgnoreTaxItem = !latestValues[parentId] && !consider; // item created in UI, then deleted in UI, send nothing

          if (!isIgnoreTaxItem) {
            const isBlank = [latestValues[parentValueKey]].every(
              (val) => !val && val !== 0
            );

            // 1. blank - dont send
            // 2. blanked by user - delete (id exists)
            // 3. filled handled
            // 4. switch off - delete (id exists)

            const taxObj = {
              ...(latestValues[parentId]
                ? {
                    [k.TAXES_ATTRIBUTES.ID]: latestValues[parentId],
                  }
                : {}),
              ...(latestValues[parentId] && (!consider || isBlank)
                ? {
                    [k.TAXES_ATTRIBUTES._DESTROY]: true,
                  }
                : {}),
              ...(isBlank
                ? {}
                : {
                    [k.TAXES_ATTRIBUTES.NAME]: parentTaxName,
                    [k.TAXES_ATTRIBUTES.TAX_RATE]: v[parentDropdownKey] ?? 0,
                    [k.TAXES_ATTRIBUTES.TAX_VALUE]: v[parentValueKey] ?? 0,
                    //
                    ...(v[parentTagValueKey]
                      ? {
                          [k.TAXES_ATTRIBUTES.TAG_VALUE_ID]:
                            v[parentTagValueKey],
                        }
                      : {}),
                    [k.TAXES_ATTRIBUTES.CATEGORY]:
                      v[parentDropdownLabelKey] ?? "",
                  }),
            };
            if (Object.keys(taxObj).length) taxAttributesAccum.push(taxObj);
          }

          // children
          childrenTax.forEach((childTax) => {
            const childTaxName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
            const childKey = childTaxName;
            const optionalSwitchKey = `${childTaxName}-${TAX_KEYS.OPTIONAL_ENABLED}`;

            const childId = `${childKey}-id`;
            const childVisibleKey = `${childKey}-visible`; // TODO FE key, not used
            const childDropdownKey = `${childKey}-dropdown`;
            const childValueKey = `${childKey}-value`;
            const childTagValueKey = `${childKey}-tag-value-id`;
            const childCategoryDropdownKey = `${childKey}-category-dropdown`;
            const childDropdownLabelKey = `${childKey}-dropdown-label`;
            const isIgnoreChildTaxItem = !latestValues[childId] && !consider; // item created in UI, then deleted in UI, send nothing

            if (!isIgnoreChildTaxItem) {
              const optionalSwitchEnabled = v[optionalSwitchKey];
              const isBlank = [latestValues[childValueKey]].every(
                (val) => !val && val !== 0
              );

              // 1. blank - dont send
              // 2. blanked by user - delete (id exists)
              // 3. filled handled
              // 4. switch off - delete (id exists)

              const taxObj = {
                ...(latestValues[childId]
                  ? { [k.TAXES_ATTRIBUTES.ID]: latestValues[childId] }
                  : {}),
                ...(latestValues[childId] &&
                (!consider || !optionalSwitchEnabled || isBlank)
                  ? { [k.TAXES_ATTRIBUTES._DESTROY]: true }
                  : {}),

                ...(optionalSwitchEnabled && !isBlank
                  ? {
                      [k.TAXES_ATTRIBUTES.NAME]: childTaxName,
                      [k.TAXES_ATTRIBUTES.TAX_RATE]: v[childDropdownKey] ?? 0,
                      [k.TAXES_ATTRIBUTES.TAX_VALUE]: v[childValueKey] ?? 0,
                      //
                      ...(v[childTagValueKey]
                        ? {
                            [k.TAXES_ATTRIBUTES.TAG_VALUE_ID]:
                              v[childTagValueKey],
                          }
                        : {}),
                      [k.TAXES_ATTRIBUTES.CATEGORY]:
                        v[childDropdownLabelKey] ?? "",
                    }
                  : {}),
              };
              if (Object.keys(taxObj).length) taxAttributesAccum.push(taxObj);
            }
          });

          return taxAttributesAccum;
        }, [])
        .filter((item) => Object.keys(item || {}).length),

      // payment section
      [k.PAYMENT_DATE]: getDateInPattern(v.paymentDate),
      [k.CURRENCY]: senderCurrency,
      // [k.PAYMENT_METHOD]: v.payoutMethodType, // send indirectly as quote id, don't send here. (since we send the quote id)
      [k.PAYMENT_MODE]: isPayOutsideVolopay
        ? PAYMENT_MODES.payOutsideVolopay
        : PAYMENT_MODES.payViaVolopay,

      // misc
      [k.USER_ID]: currentUser?.id,
      [k.QUOTE_ID]: quoteFromAPI?.[GENERATE_QUOTE_API_BODY_KEYS_RESPONSE.ID],
      ...(inPayrollContext ? {} : { [k.PROJECT_ID]: v.projectId }),
      ...((createMode || reCreateModeId) && isLeftButtonSubmit
        ? { [k.ONLY_DRAFT]: true }
        : {}),
    };
    // if (inPayrollContext) {
    //   delete payloadObject[k.LINE_ITEMS_ATTRIBUTES.selfKey];
    //   payloadObject[k.AMOUNT] =
    //     createBillTotalContext?.[
    //       CREATE_BILL_FLOW_CONTEXT_KEYS.INVOICE_SUBTOTAL
    //     ]; // free from taxes (if have this everything else is calculable)
    // }

    // payment section's optionals
    if (v.paymentOnApprove) {
      payloadObject[k.PAY_ONCE_APPROVED] = v.paymentOnApprove;
      payloadObject[k.PAYMENT_DATE] = null;
    }
    if (v.paymentReference)
      payloadObject[k.PAYMENT_REFERENCE] = v.paymentReference;
    if (
      v?.[CREATE_BILL_REQUEST_BODY_KEYS.PAYROLL_TAG_VALUE_ATTRIBUTES] &&
      inPayrollContext
    ) {
      const tagObjectEnteries = Object.entries(
        v?.[CREATE_BILL_REQUEST_BODY_KEYS.PAYROLL_TAG_VALUE_ATTRIBUTES]
      ).map(([key, value]) => {
        const [, tag_id] = key.split("_");
        return { tag_id: Number.parseInt(tag_id, 10), tag_value_id: value };
      });
      payloadObject[k.PAYROLL_TAG_VALUE_ATTRIBUTES] = tagObjectEnteries;
    }
    if (v.recurringPayment) {
      payloadObject[k.SCHEDULE_FIELDS.selfKey] = {
        [k.SCHEDULE_FIELDS.FREQUENCY]: v.paymentFrequency,
        [k.SCHEDULE_FIELDS.NEXT_GENERATION_DATE]: getDateInPattern(
          v.nextInvoiceCreationDate
        ),
        [k.SCHEDULE_FIELDS.NEXT_DUE_DATE]: getDateInPattern(v.nextDueDate),
        [k.SCHEDULE_FIELDS.NEXT_PAYMENT_DATE]: getDateInPattern(
          v.nextPaymentDate
        ),
        [k.SCHEDULE_FIELDS.END_DATE]: getDateInPattern(
          v.endOfRecurringPaymentCycle
        ),
      };
    }

    if (inPayrollContext && v.payrollMonth) {
      payloadObject[k.PAYROLL_MONTH] = getDateInPattern(v.payrollMonth);
    }
    if (editModeId || reviewModeId) payloadObject[k.ID] = id;

    if (!payloadObject[k.QUOTE_ID] && ![k.ONLY_DRAFT]) {
      const message = t(
        "billPay.bill.invoiceInbox.createBill.warnings.payloadMissingQuote"
      );
      vToast({
        title: message,
        variant: "warning",
      });
      console.warn(message);
      onError();
      return;
    }

    const cleanedPayloadObject = generatePayloadFromFormValue(
      payloadObject,
      false
    );

    const filesInViewer = storedFiles; // some have `id`, some don't
    const filesToUpload = filesInViewer.filter((item) => !item.id); // new files don't have `id`
    const filesPayload = await convertToFileInstances(filesToUpload);

    const payload = objectToFormData({
      ...cleanedPayloadObject,
      [inPayrollContext ? k.PAYSLIPS : k.INVOICES]: filesPayload ?? [],
    });

    // save changes in edit form
    if (editModeId) {
      if (isRecurringPayment && !isPaymentAddDateMode) {
        dispatch(
          openAppModal(
            inPayrollContext
              ? VP_MODALS.RECURRING_PAYMENT_PAYROLL
              : VP_MODALS.RECURRING_PAYMENT_BILLPAY
          )
        );

        dispatch(setAppModalData(cleanedPayloadObject));
        onError(); // stop loaders
      } else if (
        createBillFlowContext?.current?.[
          CREATE_BILL_FLOW_CONTEXT_KEYS.ADD_PAYMENT_DATE
        ]
      ) {
        dispatch(
          performActionThunk({
            id,
            do: BILLPAY_ACTION_PARAMS.DO.ADD_PAYMENT_DATE,
            payment_date: getDateInPattern(v.paymentDate),
            //
            context,
            onSuccess,
            onError,
          })
        );
      } else if (isShowModalDuringSubmit) {
        const isModalOpen =
          createBillFlowContext.current[
            CREATE_BILL_FLOW_CONTEXT_KEYS
              .EDIT_BILL_RESET_APPROVAL_POLICY_MODAL_IS_OPEN
          ];
        if (isModalOpen) {
          dispatch(
            updatePurchaseBill({
              id,
              payload,
              context,
              onSuccess,
              onError,
            })
          );
        } else {
          // modal applicable but not open, so open it
          dispatch(
            openAppModal(
              inPayrollContext
                ? VP_MODALS.EDIT_BILL_RESET_APPROVAL_POLICY_PAYROLL
                : VP_MODALS.EDIT_BILL_RESET_APPROVAL_POLICY_BILLPAY
            )
          );
          onError(); // stop loaders
          // dont't make API, modal submit will run submit again
        }
      } else {
        dispatch(
          updatePurchaseBill({
            id,
            payload,
            context,
            onSuccess,
            onError,
          })
        );
      }
    } else if (reviewModeId) {
      // save changes cta in review slider
      dispatch(
        updatePurchaseBill({
          id,
          payload,
          context,
          noToast: !isLeftButtonSubmit,
          onSuccess: (responseData) => {
            if (isLeftButtonSubmit) {
              onSuccess(responseData);
              dispatch(
                setPurchaseBill({
                  key:
                    context === BILL_PAYROLL_CONTEXT.BILLPAY
                      ? PURCHASE_BILLS_SLICE_ATTRIBUTE_KEY
                      : PAYROLL_SLICE_ATTRIBUTE_KEY,

                  value: {
                    id: responseData?.id,
                    value: responseData,
                  },
                })
              );
            } else {
              dispatch(
                performActionThunk({
                  id: responseData.id,
                  do: BILLPAY_ACTION_PARAMS.DO.VERIFIED,
                  //
                  context,
                  onSuccess: () => {
                    onSuccess(responseData);
                    dispatch(removePurchaseBill(id)); // review draft case
                  },
                  onError,
                })
              );
            }
          },
          onError,
        })
      );
    } else if (createMode || reCreateModeId) {
      // create payment cta
      dispatch(
        createPurchaseBill({
          payload,
          context,
          noToast: !isLeftButtonSubmit,
          onSuccess: (responseData) => {
            if (isLeftButtonSubmit) {
              onSuccess(responseData);
              dispatch(prependToPurchaseBillsList(responseData));
            } else {
              dispatch(
                performActionThunk({
                  id: responseData.id,
                  do: BILLPAY_ACTION_PARAMS.DO.VERIFIED,
                  //
                  context,
                  onSuccess,
                  onError: () => {
                    onError();
                    dispatch(prependToPurchaseBillsList(responseData));
                  },
                })
              );
            }
          },
          onError,
        })
      );
    }
  };

  const isDependentAPIFetching =
    isFetchingVendor ||
    isFetchingPrefillSource ||
    isFetchingLineItemsFromAPI ||
    isFetchingTaxOptions ||
    isFetchingSubmissionPolicy ||
    isFetchingOCRResults ||
    isFetchingTags ||
    isFetchingWallets ||
    isFetchingApprovers;

  const isAllPrefillDone =
    !isDependentAPIFetching && isPrefilledSimpleValues && isPrefilledLineItems;

  const {
    handleChange,
    values,
    errors,
    _setValues: setValues,
    isFormButtonDisabled,
    handleSubmit,
  } = useForm(initialFormValue, onSubmit, {
    isFetchingInitialValue: false,
    formId: "create-bill-details-form",
    retainState: isDoPrefill ? isAllPrefillDone : false, // prevents tax at line item level prefill bugs, by setting this option to false, until prefill finishes
  });

  const isOverDue =
    values?.dueDate &&
    values?.invoiceDate &&
    new Date(values?.dueDate) < new Date(values?.invoiceDate) &&
    new Date(values?.dueDate) !== new Date(values?.invoiceDate);

  useEffect(() => {
    if (values?.paymentAccount)
      if (
        values?.paymentAccount === PAY_OUTSIDE_VOLOPAY_CURRENCY ||
        values?.paymentOnApprove
      ) {
        setValues((prev) => ({
          ...prev,
          paymentDate: "",
          nextPaymentDate: "",
        }));
      }
  }, [values?.paymentAccount, values?.paymentOnApprove]);

  // sender currency - determined by the payment account dropdown, otherwise default
  const isMemoRequired =
    submissionPolicy?.memo?.required &&
    amountToBeReceivedByVendor > Number(submissionPolicy?.memo?.amount || 0);
  const isMemoError = isMemoRequired && !values?.memo;

  const isErrors = Object.keys(errors).length || isMemoError || isReceiptError;
  const saveCtaDisabled =
    values?.paymentDate &&
    values?.payrollMonth &&
    values?.vendor &&
    values?.memo &&
    values?.finalAmount?.value;
  const isPayOutsideVolopay =
    values?.paymentAccount === PAY_OUTSIDE_VOLOPAY_CURRENCY ||
    values?.paymentMode === PAYMENT_MODES.payOutsideVolopay;
  const senderCurrency = isPayOutsideVolopay
    ? defaultCurrency
    : values.paymentAccountCurrency;
  const beneficiaryCurrency = vendorDetails?.beneficiaryCurrency;

  // currencies are diff, selected providers, and more than one line item is filled
  // YSTR UI will be visible if this is true
  const isYSTRPotentiallyApplicable =
    senderCurrency !== beneficiaryCurrency &&
    [PAYMENT_PROVIDERS.WALLEX, PAYMENT_PROVIDERS.AIRWALLEX].includes(
      currentProvider
    );
  const isYSTRApplicable =
    isYSTRPotentiallyApplicable &&
    Object.keys(values || {}).reduce(
      // only one line item should be there
      (accum, k) =>
        accum +
        (k?.startsWith(`${LINE_ITEM_KEY}.`) &&
        k?.endsWith(`.${LINE_ITEM_KEYS.AMOUNT}`) &&
        !values[
          k.replace(`.${LINE_ITEM_KEYS.AMOUNT}`, `.${LINE_ITEM_KEYS._DESTROY}`)
        ] // _destroy should not be true
          ? 1
          : 0),
      0
    ) < 2;

  const vendorSelected = values?.vendor;
  const isRecurringPayment = values?.recurringPayment;

  // D. fetch line items and set uploaded file
  useEffect(() => {
    if (createMode || !prefillSource) return;

    // mock quote (from slider data)
    if (editModeId || reviewModeId || reCreateModeId) {
      // ocr store for file
      if (prefillSourceFiles?.length)
        dispatch(setUploadedFiles(prefillSourceFiles));

      if (prefillSource) {
        dispatch(fetchLineItems({ id, context }));
      }
    }
  }, [
    prefillSource?.id,
    createMode,
    reviewModeId,
    editModeId,
    prefillSourceFiles,
  ]);

  // prefill simple values
  // simple values and line items are kept at different places in store
  useEffect(() => {
    if (
      !isFetchingVendor &&
      !vendorDetails &&
      prefillSource?.vendor?.id &&
      !ocrMode &&
      !isPrefilledSimpleValues &&
      !isPrefilledLineItems
    ) {
      dispatch(setSelectedVendor(prefillSource?.vendor));
    }

    if (
      !isDoPrefill ||
      isDependentAPIFetching ||
      isPrefilledSimpleValues ||
      isPrefilledLineItems ||
      !isFetchedWallets ||
      (ocrMode
        ? // so due date, invoice number are prefilled even without vendor select
          !isFetchedOCRResultPresent
        : !prefillSource || !vendorDetails || !isPurchaseBillTaxesReady)
    )
      return;

    // simple values
    if (isDoPrefill) {
      setIsPrefillingSimpleValues(true);
      const prefillMap = ocrMode
        ? ocrMap
        : reviewModeId
          ? reviewMap
          : reCreateModeId
            ? reCreateMap
            : editModeId
              ? editMap
              : {}; // edit, review, recreate modes

      const simpleValues = getInitalFormValues(prefillSource ?? {}, prefillMap);

      // ocr bolt - 1 (simple values)
      // The idea here is simple: the prefilled values (stored in Redux store) and the validator field key names are the same
      // so after ocr prefill, if the following ref and the value stored in validator match, then it's a successfull OCR detect, so show the bolt
      // Note: line items have a separate prefill useEffect, and the ref is handled accordingly in it
      //  another separated part is vendor detection, that is also handled in the ref in vendor search useEffect
      createBillFlowContext.current[
        CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
      ] = {
        ...createBillFlowContext.current[
          CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
        ],
        ...simpleValues,
      };
      // merge, don't set, as line items effect will fire first, and it sets line items
      dispatch(mergeIntoCreateBillForm(simpleValues));
      setIsPrefillingSimpleValues(false);
      setIsPrefilledSimpleValues(
        !!vendorDetails ||
          (ocrMode && newVendorNameInBillpay) ||
          (prefillSource?.createVendorEmailPrompt &&
            newVendorNameInBillpay &&
            !values?.vendor)
      ); // mark, the second OR fixes the vendorSelected gets blanked issue
    }
  }, [
    prefillSource,
    ocrResult,
    isDependentAPIFetching,
    isPrefilledSimpleValues,
    isPrefilledLineItems,
    isPurchaseBillTaxesReady,
    isFetchedWallets,
    isFetchingTags,
    vendorDetails,
    newVendorNameInBillpay,
  ]);
  // prefill line items (filled after simple values are filled)
  useEffect(() => {
    if (
      !isDoPrefill ||
      isDependentAPIFetching ||
      isPrefilledLineItems ||
      !isPrefilledSimpleValues ||
      !vendorDetails ||
      !isPurchaseBillTaxesReady ||
      isFetchingTags ||
      !isFetchedTags
    )
      return;

    setIsPrefillingLineItems(true);

    // line items
    // unlike simpleValues, we don't use the `getInitialFormValues` util here, because the keys are less and code would be cumbersome
    // so doing it directly (no need of dot paths or callbacks)

    // needed for, we are adding a line item with tax value for now (we dont handle tax for vendors in SG)
    const isAddArtificialTaxLineItem =
      ocrMode && currentProvider === PAYMENT_PROVIDERS.WALLEX;
    const ocrDetectedTaxValue = +(
      ocrResult?.data?.taxes?.[0]?.[BILL_RESPONSE_KEYS.TAX_ITEM.VALUE] ||
      ocrResult?.data?.totalTax ||
      (ocrResult?.data?.invoiceAmount ?? 0) -
        +`${ocrResult?.data?.subTotal}`.replaceAll(",", "") ||
      0
    ).toFixed(2);

    /*
     OCR behaves a little weird: sometimes
     - it sends 'taxes' []
     - it sends 'totalTax'
     - or, sends nothing, so we calculate value from amount differences
     */

    const lineItemsBasicObjects =
      isAddArtificialTaxLineItem && ocrDetectedTaxValue
        ? [
            ...lineItemsPrefillSource,
            ocrMode && currentProvider === PAYMENT_PROVIDERS.WALLEX
              ? {
                  [LINE_ITEM_KEYS.AMOUNT]: ocrDetectedTaxValue,
                  [LINE_ITEMS_RESPONSE_KEYS.DESCRIPTION]: "Tax",
                  [LINE_ITEM_KEYS.IS_ARTIFICIAL]: true,
                }
              : null,
          ]
        : lineItemsPrefillSource;

    const lineItems = lineItemsBasicObjects.map((item, index) => {
      const lineItemAmount =
        item[LINE_ITEMS_RESPONSE_KEYS.AMOUNT]?.value ||
        item[LINE_ITEMS_RESPONSE_KEYS.AMOUNT];

      const lineItem = {
        [LINE_ITEM_KEYS.FEID]: index, // data coming in, maintain sequence. if BE saved line item id, it's not important for us (we never send middle values anyway)
        [LINE_ITEM_KEYS.BEID]: item[LINE_ITEMS_RESPONSE_KEYS.ID] || null, // id from BE
        [LINE_ITEM_KEYS.DESCRIPTION]:
          item[LINE_ITEMS_RESPONSE_KEYS.DESCRIPTION] ?? "",
        [LINE_ITEM_KEYS.ACCOUNTING_DESCRIPTION]:
          item[LINE_ITEM_KEYS.ACCOUNTING_DESCRIPTION] ??
          item[LINE_ITEMS_RESPONSE_KEYS.DESCRIPTION],
        [LINE_ITEM_KEYS.AMOUNT]: lineItemAmount, // direct values come from OCR service
        [LINE_ITEM_KEYS.IS_ARTIFICIAL]: item[LINE_ITEM_KEYS.IS_ARTIFICIAL],
        // tax (apply at line item ON)
        ...[
          ...(inPayrollContext
            ? [
                {
                  parentTax: subtractiveParentTax,
                  childrenTax: subtractiveChildrenTax,
                },
              ]
            : [
                {
                  parentTax: additiveParentTax,
                  childrenTax: additiveChildrenTax,
                },
                {
                  parentTax: subtractiveParentTax,
                  childrenTax: subtractiveChildrenTax,
                },
              ]),
        ].reduce((accum, { parentTax, childrenTax }) => {
          if (!parentTax) return accum;

          // helper function

          // parent
          const parentKey = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
          // TECH_DEBT, line item taxes have a prefix, while non-line item ones don't
          // remove the prefix from here, and component code
          const parentId = `${LINE_ITEM_KEYS.TAX}.${parentKey}-id`;
          const parentDropdownKey = `${LINE_ITEM_KEYS.TAX}.${parentKey}-dropdown`;
          const parentValueKey = `${LINE_ITEM_KEYS.TAX}.${parentKey}-value`;
          const parentTagValueKey = `${LINE_ITEM_KEYS.TAX}.${parentKey}-tag-value-id`;
          const parentCategoryDropdownKey = `${LINE_ITEM_KEYS.TAX}.${parentKey}-category-dropdown`;
          const parentDropdownLabelKey = `${LINE_ITEM_KEYS.TAX}.${parentKey}-dropdown-label`;
          const parentTaxMatchingData = getMatchingTaxData(item, parentTax);
          const parentTaxRate =
            parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.RATE] ?? "";

          const { category, superCategory } = ocrMode
            ? getDropdownKeysBasedOnOCRRate({ rate: parentTaxRate }, parentTax)
            : getCategoryAndSuperCategoryKeys(
                parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY] ??
                  "",
                parentTax
              );

          accum[parentId] =
            parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.ID] ?? "";

          accum[parentDropdownKey] = parentTaxRate;
          accum[parentValueKey] =
            parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.VALUE] ??
            (getTaxAmount(lineItemAmount ?? 0, parentTaxRate, true) || "");
          accum[parentTagValueKey] =
            parentTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.TAG_VALUE_ID];
          accum[parentCategoryDropdownKey] = superCategory;
          accum[parentDropdownLabelKey] = category;

          // children
          childrenTax.forEach((childTax) => {
            // const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
            const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
            // TECH_DEBT, line item taxes have a prefix, while non-line item ones don't
            // remove the prefix from here, and component code
            const childId = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-id`;
            const childVisibleKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-visible`;
            const childDropdownKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-dropdown`;
            const childValueKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-value`;
            const childTagValueKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-tag-value-id`;
            const childCategoryDropdownKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-category-dropdown`;
            const childDropdownLabelKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-dropdown-label`;
            const optionalSwitchKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;
            const childTaxMatchingData = getMatchingTaxData(item, childTax);

            const {
              category: childCategory,
              superCategory: childSuperCategory,
            } = ocrMode
              ? getDropdownKeysBasedOnOCRRate(
                  childTaxMatchingData?.[
                    BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
                  ] ?? "",
                  childTax
                )
              : getCategoryAndSuperCategoryKeys(
                  childTaxMatchingData?.[
                    BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY
                  ] ?? "",
                  childTax
                );

            accum[optionalSwitchKey] = !!(
              childTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.RATE] ||
              childTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.VALUE]
            );

            accum[childId] =
              childTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.ID] ?? "";
            accum[childVisibleKey] = () => {
              const isPPNTax =
                parentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME] ===
                TAX_NAMES.PPN;
              if (!isPPNTax) return true; // by default always visible, except PPnBM (that is handled separately)

              const relevantTax = childTaxMatchingData;
              const isPPnBMApplicable =
                `${relevantTax?.[BILL_RESPONSE_KEYS.TAX_ITEM.RATE]}` === "11" ||
                relevantTax?.[BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY]
                  ?.toString()
                  .replaceAll(" ", "")
                  .includes("11%");

              return isPPnBMApplicable;
            };
            accum[childDropdownKey] = childCategory;
            accum[childValueKey] =
              childTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.VALUE] ?? "";
            accum[childTagValueKey] =
              childTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.TAG_VALUE_ID];
            accum[childCategoryDropdownKey] = childSuperCategory;
            accum[childDropdownLabelKey] =
              childTaxMatchingData?.[BILL_RESPONSE_KEYS.TAX_ITEM.CATEGORY] ??
              "";
          });

          return accum;
        }, {}),

        // tags
        [LINE_ITEM_KEYS.ACCCOUNTING_TAGS]: allTags.map((tag) =>
          getTagItemWithData(item, index)(tag)
        ),
      };

      return lineItem;
    });

    // ocr bolt - 2 (line items)
    createBillFlowContext.current[
      CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_LINE_ITEMS
    ] = lineItems;
    dispatch(setCreateBillFormLineItems(lineItems));
    //
    // only these 2 keys are affected by lineItemsPrefillSource, and we're merging so they're independent
    const firstLineItemTaxes =
      lineItemsPrefillSource?.[0]?.[LINE_ITEM_KEYS.TAX] ?? [];
    dispatch(
      // .taxes should not have any tax nested object of the kind, so line_item has it
      // if no tax is applicable, nothing will be rendered, so safe.
      mergeIntoCreateBillForm({
        [TAX_AT_LINE_ITEM_LEVEL_ADDITIVE]:
          firstLineItemTaxes.filter(
            (item) => !item?.[LINE_ITEMS_RESPONSE_KEYS.TAX_ITEM.DEDUCTIVE_TAX]
          ).length > 0,
        //
        [TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE]:
          firstLineItemTaxes.filter(
            (item) => item?.[LINE_ITEMS_RESPONSE_KEYS.TAX_ITEM.DEDUCTIVE_TAX]
          ).length > 0,
      })
    );
    setIsPrefillingLineItems(false);
    setIsPrefilledLineItems(
      !!vendorDetails ||
        (ocrMode && newVendorNameInBillpay) ||
        (prefillSource?.createVendorEmailPrompt &&
          newVendorNameInBillpay &&
          !values?.vendor)
    ); // mark, the second OR fixes the vendorSelected gets blanked issue
  }, [
    isDependentAPIFetching,
    lineItemsPrefillSource?.length,
    isPrefilledLineItems,
    isPrefilledSimpleValues,
    isFetchingTags,
    isPurchaseBillTaxesReady,
    isFetchedTags,
    vendorDetails,
  ]);
  //
  const showVendorNameCreateOCRQValue = searchParams.get(
    SLIDERS_SEARCH_PARAMS.payments.showCreateVendor
  );
  const [showVendorNameCreate, setShowVendorNameCreate] = useState(false); // got to re-render, so.
  const vendorNameCreate = newVendorNameInBillpay;

  // edit mode fetch the bill/payroll payment
  useEffect(() => {
    if (editModeId || reviewModeId || reCreateModeId) {
      if (inPayrollContext)
        dispatch(fetchAndSelectPayrollApproval({ id, context }));
      else dispatch(fetchAndSelectPaymentApproval({ id }));
    }
    // dispatch(setSelectedVendor(null)); // avoid pre-selection from previous (just closed) flows
  }, []);
  // OCR search and open create box
  const isOCRRanAlready =
    searchParams.get(SLIDERS_SEARCH_PARAMS.payments.ocrFetched) === "true";

  useEffect(() => {
    if (
      ocrMode &&
      !isOCRRanAlready &&
      billOCRSuccess &&
      ocrResult?.data?.vendorName
    ) {
      // old code below
      dispatch(
        searchAndSelectVendor({
          page: 1,
          limit: 1,
          context,
          search: ocrResult?.data?.vendorName,
          localSearch: true,
          onVendorNotFound: () => {
            // for vendor detected by OCR but not present in Volopay
            createBillFlowContext.current[
              CREATE_BILL_FLOW_CONTEXT_KEYS.VENDOR_IDENTIFIED_BUT_NOT_FOUND
            ] = ocrResult?.data?.vendorName;

            searchParams.set(searchParamKey, ocrResult?.data?.vendorName); // save vendor name for comeback
            searchParams.set(SLIDERS_SEARCH_PARAMS.payments.ocrFetched, true); // to avoid upload firing again
            searchParams.set(
              SLIDERS_SEARCH_PARAMS.payments.showCreateVendor,
              true
            );
            setSearchParams(searchParams);
            setShowVendorNameCreate(true); // show box, got to re-render
          },
          onVendorFound: (foundVendor) => {
            dispatch(setSelectedVendor(foundVendor));
            setValues((prev) => ({ ...prev, vendor: foundVendor.id }));
            // ocr bolt - 3 (identified and selected vendor)
            createBillFlowContext.current[
              CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
            ] = {
              ...createBillFlowContext.current[
                CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
              ],
              vendor: foundVendor.id,
            };
            //
            // clear detected vendor name to avoid redirect and reselect issues even if a different vendor is selected now
            searchParams.set(searchParamKey, true);
            searchParams.set(SLIDERS_SEARCH_PARAMS.payments.ocrFetched, true);
            setSearchParams(searchParams);
          },
        })
      );
    }

    // else if (formMode === BILL_FORM_MODES.OCR) {}
  }, [ocrResult, billOCRSuccess]);
  useEffect(() => {
    if (!prefillSource?.vendor && prefillSource?.createVendorEmailPrompt) {
      // old code below
      dispatch(
        searchAndSelectVendor({
          page: 1,
          limit: 1,
          context,
          search: prefillSource?.createVendorEmailPrompt,
          localSearch: true,
          onVendorNotFound: () => {
            // for vendor detected by OCR but not present in Volopay
            createBillFlowContext.current[
              CREATE_BILL_FLOW_CONTEXT_KEYS.VENDOR_IDENTIFIED_BUT_NOT_FOUND
            ] = prefillSource?.createVendorEmailPrompt;

            searchParams.set(
              inPayrollContext
                ? SLIDERS_SEARCH_PARAMS.payments.createVendorPrompt
                : SLIDERS_SEARCH_PARAMS.payrollPayments.createEmployeePrompt,
              prefillSource?.createVendorEmailPrompt
            ); // save vendor name for comeback
            searchParams.set(
              SLIDERS_SEARCH_PARAMS.payments.showCreateVendor,
              true
            );
            setSearchParams(searchParams);
            setShowVendorNameCreate(true); // show box, got to re-render
          },
          onVendorFound: (foundVendor) => {
            dispatch(fetchAndSelectVendor({ id: foundVendor?.id }));
            setValues((prev) => ({ ...prev, vendor: foundVendor.id }));
            // ocr bolt - 3 (identified and selected vendor)
            createBillFlowContext.current[
              CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
            ] = {
              ...createBillFlowContext.current[
                CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_SIMPLE_VALUES
              ],
              vendor: foundVendor.id,
            };
            //
            // clear detected vendor name to avoid redirect and reselect issues even if a different vendor is selected now
            searchParams.delete(
              inPayrollContext
                ? SLIDERS_SEARCH_PARAMS.payments.createVendorPrompt
                : SLIDERS_SEARCH_PARAMS.payrollPayments.createEmployeePrompt
            );
          },
        })
      );
    }

    // else if (formMode === BILL_FORM_MODES.OCR) {}
  }, [prefillSource?.createVendorEmailPrompt]);
  // feat: create vendor in billpay, "comeback phase" to billpay slider flow
  // need to do this because create bill slider is paginated and new vendor, even though added
  // won't show up on its own since its not usually on the first page (20)
  // for normal dropdown create
  useEffect(() => {
    if (
      newVendorNameInBillpay &&
      vendorDetails?.name !== newVendorNameInBillpay
    ) {
      dispatch(
        searchAndSelectVendor({
          page: 1,
          limit: 1,
          context,
          search: newVendorNameInBillpay,
          localSearch: true,
          onVendorNotFound: () => {
            // user went to create vendor but didn't create a new vendor, do nothing
            setShowVendorNameCreate(!!showVendorNameCreateOCRQValue);
          },
          onVendorFound: (foundVendor) => {
            // found newly create vendor
            // dispatch(prependToVendorsList(foundVendor)); // show it at the top // no need, removed pagination from dropdown
            //
            // clear detected vendor name to avoid redirect and reselect issues even if a different vendor is selected now

            dispatch(
              fetchAndSelectVendor({
                id: foundVendor?.id,
                onSuccess: () => {
                  setValues((prev) => ({ ...prev, vendor: foundVendor.id })); // this will cause new vendor to be fetched
                  if (ocrMode) searchParams.set(searchParamKey, true);
                  setSearchParams(searchParams);
                },
              })
            );
          },
        })
      );
    }
  }, []);
  // hide OCR box when trash document is pressed
  useEffect(() => {
    if (!showVendorNameCreateOCRQValue) setShowVendorNameCreate(false);
  }, [showVendorNameCreateOCRQValue]);

  // duplicate check
  const duplicateBillParams = inPayrollContext
    ? {
        ...(id ? { current_id: id } : {}),
        vendor_id: vendorDetails?.id,
        amount:
          createBillTotalContext?.[
            CREATE_BILL_FLOW_CONTEXT_KEYS.QUOTE_ARGUMENT
          ],
        currency: beneficiaryCurrency,
        //
        payroll_month: getDateInPattern(values?.payrollMonth),
      }
    : {
        ...(id ? { current_id: id } : {}),
        vendor_id: vendorDetails?.id,
        amount:
          createBillTotalContext?.[
            CREATE_BILL_FLOW_CONTEXT_KEYS.QUOTE_ARGUMENT
          ],
        currency: beneficiaryCurrency,
        //
        invoice_date: getDateInPattern(values?.invoiceDate),
        invoice_number: values?.invoiceNumber,
      };

  useEffect(() => {
    if (Object.values(duplicateBillParams).every((item) => !!item))
      // avoid early/blank calls
      dispatch(
        fetchDuplicateBill({
          context,
          ...generatePayloadFromFormValue(duplicateBillParams),
        })
      );
    return () => dispatch(setDuplicateBill(null));
  }, [JSON.stringify(duplicateBillParams)]);

  const clearWholeFlowData = (isClosing = false) => {
    // isClosing is needed otherwise slider will never close, query params will keep on setting

    dispatch(inPayrollContext ? resetPayrollWallet() : resetWallets());
    dispatch(setTags([]));
    dispatch(setCreateBillForm(null));
    dispatch(resetCreateBillTotalContext());
    dispatch(resetPaymentModeList());
    dispatch(setCreateBillFormLineItems([]));
    dispatch(setLineItems([]));
    dispatch(setPurchaseBillQuote(null));
    dispatch(setSelectedPayment(null));
    dispatch(setSelectedVendor(null));
    dispatch(resetCreateBillRef());

    setIsOnComeBackPrefill(false);
    setPaymentModeOptions([]);
    setIsPrefillingSimpleValues(false);
    setIsPrefillingLineItems(false);
    setIsPrefilledSimpleValues(false);
    setIsPrefilledLineItems(false);
    setIsLeftButtonLoading(false);
    setShowVendorNameCreate(false);
    if (!isClosing) {
      searchParams.set(searchParamKey, true);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.payments.showCreateVendor);
      setSearchParams(searchParams);
    }
  };

  useEffectSlider(
    () => {
      dispatch(
        inPayrollContext
          ? fetchPayrollWallet({ shallow: true })
          : fetchAccountWallets({ shallow: true })
      );
      dispatch(fetchTags({ visible: true }));

      return () => clearWholeFlowData(true);
    },
    [],
    { searchParamKey, setOnClose }
  );
  // fetch tax list
  useEffect(() => {
    if (vendorDetails?.id && vendorDetails?.bankDetailsPresent) {
      dispatch(
        fetchPurchaseBillTaxDetails({
          [TAX_DETAILS_QUERY_PARAM]: vendorDetails?.id,
        })
      );
      // so prefill happens again if different vendor is selected
      setIsPrefilledSimpleValues(false);
      setIsPrefilledLineItems(false);
    }

    return () => {
      dispatch(resetPurchaseBillTaxDetails());
    };
  }, [vendorDetails?.id]);

  useEffect(() => {
    if (
      submissionPolicyEnabled &&
      vendorProjectOrDepartmentId &&
      context === BILL_PAYROLL_CONTEXT.BILLPAY
    ) {
      dispatch(
        fetchBillPayrollCreationSubmissionPolicy({
          projectId: vendorProjectOrDepartmentId,
          context,
        })
      );
    }

    if (submissionPolicyEnabled && context === BILL_PAYROLL_CONTEXT.PAYROLL) {
      dispatch(
        fetchBillPayrollCreationSubmissionPolicy({
          projectId: vendorProjectOrDepartmentId,
          context,
        })
      );
    }

    return () => {
      dispatch(setBillPayrollCreationSubmissionPolicy(null));
    };
  }, [vendorProjectOrDepartmentId, submissionPolicyEnabled]);
  useEffect(() => {
    dispatch(setOCRError(isReceiptError ? t("misc.required") : ""));

    return () => {
      dispatch(setOCRError(null));
    };
  }, [isReceiptError]);

  // OCR file uploaded then deleted flow
  // Description: when file is deleted, show the modal again but don't clear data
  // if the user uploads yet another file, clear data and prefill new data
  // else user selects do manually, let dat stay
  const ocrError = useSelector(ocrErrorSelector);
  useEffect(() => {
    if (
      ocrMode &&
      isPrefilledSimpleValues &&
      isPrefilledLineItems &&
      ocrFileUri
    ) {
      dispatch(clearCreateBillForm(null));
      dispatch(resetLineItems(null));
      dispatch(resetCreateBillFormLineItems(null));
      setIsPrefilledSimpleValues(false);
      setIsPrefilledLineItems(false);
    }

    // OCR mode file deleted, clear data (except remaining files)
    // dont clear if OCR had failed (i.e. user has filled data by hand, so keep it)
    if (ocrMode && !ocrFileUri && !ocrError) {
      clearWholeFlowData();
    }
  }, [ocrFileUri]);

  // edit bill reset approval policy
  const isResetApprovalPolicyDepsChanged = (() => {
    const quoteDeps = [
      quoteFromAPI?.paymentChannel === prefillSource?.quote?.paymentChannel,
      quoteFromAPI?.toAmount === prefillSource?.quote?.toAmount,
      quoteFromAPI?.fromCurrency === prefillSource?.quote?.fromCurrency,
    ];
    return (
      inPayrollContext
        ? [
            values?.vendor === prefillSource?.vendor?.id,
            getDateInPattern(values?.payrollMonth) ===
              prefillSource?.paymentMonth,
            ...quoteDeps,
          ]
        : [
            values?.vendor === prefillSource?.vendor?.id,
            values?.invoiceNumber === prefillSource?.invoiceNumber,
            getDateInPattern(values?.invoiceDate) ===
              prefillSource?.invoiceDate,
            getDateInPattern(values?.dueDate) === prefillSource?.dueDate,
            ...quoteDeps,
          ]
    ).some((item) => !item);
  })();

  // CTA and callbacks
  const isShowModalDuringSubmit = isResetApprovalPolicyDepsChanged;
  const handleSubmitInner = (...args) => {
    if (isShowModalDuringSubmit) {
      // save handleSubmit, so it can be evenually be called on modal submit
      dispatch(
        setAppModalData({
          handleSubmit: () => {
            handleSubmit(...args);
          },
        })
      );
    }

    handleSubmit(...args); // call form handle submit as usual, so validations may be done
  };
  const discard = () => {
    // dispatch(clearVendorCreateForm());
    if (inPayrollContext) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.payrollPayments.editBill);
    } else {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.payments.editBill);
    }

    setSearchParams(searchParams);
  };
  const saveCompleteForm = () => {
    // save complete form/flow
    dispatch(mergeIntoCreateBillForm(values)); // simple values (form and initialValueFromRedux have same keys, so ok) and, after plus click line item values

    dispatch(
      setCreateBillFormLineItems(
        lineItemsForForm.map((lineItem) => {
          const lineitemId = lineItem[LINE_ITEM_KEYS.FEID];
          const existingEntryWithLatestData = {};
          Object.entries(lineItem).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              existingEntryWithLatestData[key] = value; // tags, save as is
            } else {
              existingEntryWithLatestData[key] =
                values[`${LINE_ITEM_KEY}.${lineitemId}.${key}`];
            }
          });

          return existingEntryWithLatestData;
        })
      )
    );

    // line items - not needed, already in redux
    dispatch(setCreateBillRef(structuredClone(createBillFlowContext.current))); // context ref
  };

  // allow discard in edit mode, otherwise strict
  const isLeftFooterButtonDisabled =
    isLoading ||
    isLeftButtonLoading ||
    isLoadingQuoteAmount ||
    (editModeId
      ? isAmountZero || isErrors || isFormButtonDisabled || isVendorAlert
      : isDependentAPIFetching ||
        (inPayrollContext &&
          (isFetchingVendor || isVendorAlert || !values?.vendor))); // allow saving as draft without validation. for payroll draft, atleast active employee selection is a must

  // always makes API call, so check strictly
  const isRightFooterButtonDisabled =
    isDependentAPIFetching ||
    isLoading ||
    isLeftButtonLoading ||
    isLoadingQuoteAmount ||
    isOverDue ||
    isVendorAlert; // other validations are handled inside onsubmit

  const isFormUIDisabled = isVendorAlert || isLoading || isLeftButtonLoading;

  return (
    <>
      <div className="pb-16 font-medium px-9 slider-content-core text-neutral-800">
        <form
          onSubmit={handleSubmitInner}
          id="create-bill-details-form"
          className={
            isFormUIDisabled || isPaymentAddDateMode
              ? "pointer-events-none"
              : ""
          }
        >
          {/* Title and alert box (conditional) */}
          <div>
            {billOCRSuccess && ocrMode ? (
              <div className="mt-4 mb-8">
                <BillOCRSuccess showBolt={ocrMode} />
              </div>
            ) : null}

            <Text
              refProp={titleRef}
              translationKey={titleText}
              classes="text-3xl font-bold text-neutral-800"
            />

            <VendorAlerts
              vendor={vendorDetails}
              inPayrollContext={inPayrollContext}
              classes="mt-8 pointer-events-auto"
              saveCompleteForm={createMode ? saveCompleteForm : null}
            />
          </div>

          {/* Vendor info */}
          {/* Who are you sending money to? */}
          <VendorInfo
            values={values}
            errors={errors}
            handleChange={handleChange}
            setValues={setValues}
            ref={vendorInfoRef}
            vendorDetails={vendorDetails}
            senderCurrency={senderCurrency}
            context={context}
            classes="pointer-events-auto"
            disabled={isPaymentAddDateMode}
            createBillFlowContext={createBillFlowContext}
            ocrMode={ocrMode}
            billOCRSuccess={billOCRSuccess}
            parentSearchParamKey={searchParamKey}
            showVendorNameCreateOCR={showVendorNameCreate}
            setShowVendorNameCreateOCR={setShowVendorNameCreate}
            vendorNameCreateOCR={vendorNameCreate}
            saveCompleteForm={saveCompleteForm}
          />

          {/* invoice date and info - payroll */}
          {/* What are you sending it for? */}
          {inPayrollContext ? (
            <SalaryDetailsSection
              values={values}
              setValues={setValues}
              errors={errors}
              handleChange={handleChange}
              // vendor alert draft case
              classes={
                isVendorAlert && !inPayrollContext ? "pointer-events-auto" : ""
              }
              disabled={
                (!isVendorAlert || inPayrollContext) &&
                (isFormUIDisabled || isPaymentAddDateMode)
              }
            />
          ) : null}

          {/* Payment account */}
          {vendorSelected && (!isVendorAlert || inPayrollContext) ? (
            <WalletAccountSection
              values={values}
              errors={errors}
              handleChange={handleChange}
              setValues={setValues}
              senderCurrency={senderCurrency}
              beneficiaryCurrency={beneficiaryCurrency}
              inPayrollContext={inPayrollContext}
              isPayOutsideVolopay={isPayOutsideVolopay}
              disabled={isFormUIDisabled || isPaymentAddDateMode}
            />
          ) : null}

          {/* invoice date and info - billpay */}
          {/* What are you sending it for? */}
          {inPayrollContext ? null : (
            <PurposeSection
              values={values}
              errors={errors}
              handleChange={handleChange}
              createBillFlowContext={createBillFlowContext}
              ocrMode={ocrMode}
              billOCRSuccess={billOCRSuccess}
              // vendor alert draft case
              classes={isVendorAlert ? "pointer-events-auto" : ""}
              disabled={
                (isFormUIDisabled && !isVendorAlert) || isPaymentAddDateMode
              }
              isMemoError={isMemoError}
              isOverDue={isOverDue}
            />
          )}

          {/* Line items / payroll amount input */}
          {vendorSelected && (!isVendorAlert || inPayrollContext) ? (
            <>
              {inPayrollContext ||
              !(isDoPrefill && isDoPrefill !== "false") || // TECH_DEBT doing this here since ocrMode has usual value 'false' and it seems to work, making it exactly correct breaks vendor create
              (!isPrefillingLineItems && isPrefilledLineItems) ? (
                // this ternary is important since it prevents 'add-if-zero-items' effect inside LineItemSection to fire prematurely during prefill
                <LineItemsSection
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setValues={setValues}
                  senderCurrency={senderCurrency}
                  beneficiaryCurrency={beneficiaryCurrency}
                  inPayrollContext={inPayrollContext}
                  disabled={isFormUIDisabled || isPaymentAddDateMode}
                  isPayOutsideVolopay={isPayOutsideVolopay}
                  createBillFlowContext={createBillFlowContext}
                  ocrMode={ocrMode}
                  billOCRSuccess={billOCRSuccess}
                  isDependentAPIFetching={isDependentAPIFetching}
                  isYSTRPotentiallyApplicable={isYSTRPotentiallyApplicable}
                  isYSTRApplicable={isYSTRApplicable}
                />
              ) : null}
            </>
          ) : null}

          {/* Duplicate payment alery */}
          {!isFetchingDuplicateBill && duplicateBill ? (
            <DuplicatePaymentAlert
              existingDuplicateId={duplicateBill?.invoiceNumber}
              inPayrollContext={inPayrollContext}
              classes="mt-9 pointer-events-auto"
              handleClick={() => {
                const { sliderKey, sliderValue, sliderType } =
                  getPaymentSliderInfo(duplicateBill, inPayrollContext);

                saveCompleteForm();

                const paramsToAppend = {
                  [sliderKey]: sliderValue,
                  [SLIDERS_SEARCH_PARAMS.payments.sliderType]: [sliderType],
                  [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]:
                    sliderKey,
                  [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
                    SLIDER_LEFT_SIDE_SEARCH_PARAMS.components
                      .bulkUploadPayments,
                  [SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled]: true,
                };
                Object.entries(paramsToAppend).forEach(([k, v]) => {
                  searchParams.append(k, v);
                });
                //
                setSearchParams(searchParams);
              }}
            />
          ) : null}

          {/* Auto payment, frequency (vendor not selected handled inside) */}
          {/* How and when do you want to send it? */}
          {isVendorAlert && !inPayrollContext ? null : (
            <AutoRecurringSection
              values={values}
              errors={errors}
              handleChange={handleChange}
              setValues={setValues}
              vendorInfoRef={vendorInfoRef}
              senderCurrency={senderCurrency}
              beneficiaryCurrency={beneficiaryCurrency}
              inPayrollContext={inPayrollContext}
              isPayOutsideVolopay={isPayOutsideVolopay}
              disabled={isFormUIDisabled}
              isAllPrefillDone={isAllPrefillDone}
              createBillFlowContext={createBillFlowContext}
              ocrMode={ocrMode}
              billOCRSuccess={billOCRSuccess}
              isEditBill={!!editModeId}
              prefillSource={prefillSource}
              paymentModeOptions={paymentModeOptions}
              amountToBeReceivedByVendor={amountToBeReceivedByVendor}
            />
          )}

          {(vendorSelected || inPayrollContext) && !isVendorAlert ? (
            <ApproversSection
              values={values}
              context={context}
              beneficiaryCurrency={beneficiaryCurrency}
              disabled={isFormUIDisabled || isPaymentAddDateMode}
              searchParamKey={searchParamKey}
              setOnClose={setOnClose}
              createBillFlowContext={createBillFlowContext}
              isPayOutsideVolopay={isPayOutsideVolopay}
              saveCompleteForm={saveCompleteForm}
            />
          ) : null}
        </form>

        {/* Modals */}
        <RadioModal
          title="billPay.bill.payments.paymentFooter.recurringPaymentModal.title"
          description={
            prefillSource?.recurringPayment?.position === 1
              ? "billPay.bill.payments.paymentFooter.recurringPaymentModal.firstBillDesc"
              : "billPay.bill.payments.paymentFooter.recurringPaymentModal.description"
          }
          visible={[
            VP_MODALS.RECURRING_PAYMENT_BILLPAY,
            VP_MODALS.RECURRING_PAYMENT_PAYROLL,
          ].includes(appModal)}
          onSubmit={(selectedOptionId) => {
            const appModalDataCopy = cloneFormData(appModalData); // shouldnt directly mutate Redux variable, so doing this
            appModalDataCopy.append(
              UPDATE_BILL_REQUEST_BODY_KEYS.EDIT_ALL,
              selectedOptionId === "all"
            );
            appModalDataCopy.append(
              CREATE_BILL_REQUEST_BODY_KEYS.PAY_ONCE_APPROVED,
              !!values?.paymentOnApprove
            );

            dispatch(
              updatePurchaseBill({
                id,
                payload: appModalDataCopy,
                context,
                onSuccess,
              })
            );
          }}
          submitBtnProps={{ type: inPayrollContext ? "success" : undefined }}
          configs={
            prefillSource?.recurringPayment?.position === 1
              ? []
              : [
                  {
                    id: "all",
                    label:
                      "billPay.bill.payments.paymentFooter.recurringPaymentModal.all",
                  },
                  {
                    id: "selected",
                    label:
                      "billPay.bill.payments.paymentFooter.recurringPaymentModal.current",
                  },
                ]
          }
          onClose={() => dispatch(closeAppModal())}
          submitLabel="misc.saveChanges"
        />

        <RadioModal
          title="billPay.bill.payments.paymentFooter.editBillResetApprovalPolicy.title"
          description="billPay.bill.payments.paymentFooter.editBillResetApprovalPolicy.description"
          visible={[
            VP_MODALS.EDIT_BILL_RESET_APPROVAL_POLICY_BILLPAY,
            VP_MODALS.EDIT_BILL_RESET_APPROVAL_POLICY_PAYROLL,
          ].includes(appModal)}
          onSubmit={(selectedId, modalData) => {
            createBillFlowContext.current[
              CREATE_BILL_FLOW_CONTEXT_KEYS.EDIT_BILL_RESET_APPROVAL_POLICY_MODAL_IS_OPEN
            ] = true;
            dispatch(closeAppModal());
            modalData?.handleSubmit?.(); // call saved handle submit, so form onSubmit gets called again, but this time, makes the API call
          }}
          onClose={() => {
            dispatch(closeAppModal());
            dispatch(setAppModalData(null));
          }}
          submitLabel="misc.confirm"
          showImportantLabel
          classes="max-w-[400px]"
        />

        {/* OCR `I'll do it manually` modal */}
        {/* TODO: ocr mode hard coded check ashish */}
        {ocrMode && !billOCRSuccess && (isShowOCRLoader || isShowOCRModal) ? (
          <div className="fixed w-[688px] h-full bg-[#00000092] z-[804] top-0 right-0">
            {isShowOCRLoader ? (
              <div className="absolute ocr-loading top-1/2 left-1/2" />
            ) : null}

            {isShowOCRModal && !isShowOCRLoader ? (
              <div className="bg-white opacity-100 w-80 flex flex-col justify-between p-5 h-[272px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl">
                <div className="flex items-center gap-3">
                  <Icon className="text-warning-500" name="Bolt" />
                  <Text
                    classes="text-neutral-800 text-lg font-bold"
                    translationKey="billPay.bill.invoiceInbox.createBill.ocrMagicScanTitle"
                  />
                </div>
                <Text
                  classes="text-neutral-500 text-sm font-semibold"
                  translationKey="billPay.bill.invoiceInbox.createBill.ocrMagicScanDescription"
                />
                <Button
                  classes="w-71"
                  label="billPay.bill.invoiceInbox.createBill.ocrMagicScanButtonLabel"
                  labelStyleClasses="font-semibold"
                  onClick={() => {
                    searchParams.delete(
                      SLIDERS_SEARCH_PARAMS.payments.showOCRModal
                    );
                    searchParams.delete(
                      SLIDERS_SEARCH_PARAMS.payments.ocrFetched
                    );
                    setSearchParams(searchParams);
                  }}
                  variant="secondary"
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      {/* Footer */}

      <div className="px-3 py-5 slider-footer">
        {/* CTAs are never disabled even in vendor alert */}
        <div className="flex items-center justify-end gap-3">
          <Button
            label={
              editModeId
                ? "misc.discardChanges"
                : "billPay.bill.invoiceInbox.createBill.ctas.saveChanges"
            }
            classes="text-neutral-500 font-medium w-18 px-2"
            variant="tertiary"
            onClick={
              editModeId ? discard : () => onSubmit({}, values, {}, {}, true)
            }
            disabled={isLeftFooterButtonDisabled}
            showLoader={isLeftButtonLoading}
          />
          <Button
            disabled={isRightFooterButtonDisabled}
            label={
              editModeId
                ? "billPay.bill.invoiceInbox.createBill.ctas.saveChanges"
                : inPayrollContext
                  ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.ctas.submit"
                  : "billPay.bill.invoiceInbox.createBill.buttonText"
            }
            classes="text-white font-medium w-18 px-2"
            variant="primary"
            btnType="submit"
            form="create-bill-details-form"
            showLoader={isLoading}
          />
        </div>
      </div>
    </>
  );
}

CreateBillDetails.propTypes = {
  context: PropTypes.string,
  searchParamKey: PropTypes.string,
  setOnClose: PropTypes.func,
  closeDrawer: PropTypes.func,
};
