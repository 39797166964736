import PropTypes from "prop-types";
import { useRef, useState } from "react";
import Popup from "reactjs-popup";

import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { CONSTANT_LEDGER_HEADER_IDS } from "@/utils/constants/ledger";

export default function CustomizeColumns({ headers, headerIds, applyChange }) {
  const popupRef = useRef();

  const [ids, setIds] = useState(headerIds);

  const changeVisibility = (id, value) => {
    if (!CONSTANT_LEDGER_HEADER_IDS.includes(id))
      setIds((prev) => {
        return {
          ...prev,
          [id]: value,
        };
      });
  };

  const apply = () => {
    applyChange(ids);
    popupRef.current.close();
  };

  const cancelAndReset = () => {
    popupRef.current.close();
    setIds(headerIds);
  };

  const selectedHeaders = () => {
    return Object.keys(ids).filter((id) => ids[id] === true).length;
  };

  const allIdsSelected = () => {
    return Object.values(ids).every((id) => id === true);
  };

  const toggleSelectAll = (value) => {
    const keys = Object.keys(ids);
    keys.map((key) => {
      if (!CONSTANT_LEDGER_HEADER_IDS.includes(key))
        setIds((prev) => {
          return { ...prev, [key]: value };
        });
    });
  };

  return (
    <>
      <Popup
        ref={popupRef}
        trigger={
          <button
            id="customise-columns"
            className="relative flex items-center justify-center w-10 h-10 p-2 card-wrapper"
          >
            <Icon className="text-neutral-500" name="Columns" />
          </button>
        }
        keepTooltipInside="#tooltip-inside"
        closeOnDocumentClick
        position="bottom right"
        className="filter-popup"
      >
        <div className="flex flex-col py-2 w-84">
          <div className="ml-4">
            <Text
              translationKey="accounting.ledger.customiseColumns"
              classes="text-sm font-medium text-neutral-500"
            />
          </div>
          <div className="flex items-center justify-between px-4 py-3 cursor-pointer text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900">
            <div className="flex items-center gap-3">
              <Checkbox
                checked={allIdsSelected()}
                onClickHandler={(value) => toggleSelectAll(value)}
              />
              <Text translationKey="accounting.ledger.selectAll" />
            </div>

            <Text
              translationKey="accounting.ledger.headersSelected"
              classes="text-success-500"
              translationProps={{ selectedHeaders: selectedHeaders() }}
            />
          </div>
          {headers.map((header) => {
            return (
              <div
                className="flex items-center gap-3 py-3 pl-4 cursor-pointer text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900"
                key={header.id}
              >
                <Checkbox
                  checked={ids[header.id]}
                  disabled={header.disableCustomise}
                  onClickHandler={(value) => changeVisibility(header.id, value)}
                />
                <Text translationKey={header.label} />
              </div>
            );
          })}
          <div className="flex items-center justify-end gap-4 px-3 pt-4 pb-2 slider-footer">
            <Button
              label="filter.listButton.cancel"
              type="neutral"
              classes="w-13 h-8 text-sm"
              onClick={() => cancelAndReset()}
            />
            <Button
              label="filter.listButton.apply"
              variant="secondary"
              classes="w-13 h-8 text-sm"
              onClick={() => apply()}
            />
          </div>
        </div>
      </Popup>

      <Tooltip id="customise-columns" direction="bottom">
        <Text translationKey="accounting.ledger.customiseColumns" />
      </Tooltip>
    </>
  );
}

CustomizeColumns.propTypes = {
  headers: PropTypes.array,
  headerIds: PropTypes.object,
  applyChange: PropTypes.func,
};
