import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { downloadPaymentReceipt } from "@/store/reducers/payments";

import { defaultCurrencySelector } from "@/store/selectors/client";
import { isDownloadingPaymentReceiptSelector } from "@/store/selectors/payments";
import {
  selectedUserReimbursementCtaSelector,
  selectedUserReportCtasSelector,
} from "@/store/selectors/reimbursement";

import Button from "@/components/core/Button";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import ReimbursementPaidSliderPaymentHistoryTable from "@/components/Reimbursement/ReimbursementPaymentsSlider/ReimbursementPaidSliderPaymentHistoryTable";
import ReimbursementPaymentsSliderTable from "@/components/Reimbursement/ReimbursementPaymentsSlider/ReimbursementPaymentSliderTable";
import ReimbursementPaymentsSliderFooter from "@/components/Reimbursement/ReimbursementPaymentsSlider/ReimbursementPaymentsSliderFooter";
import ReimbursementPaymentsSliderHeader from "@/components/Reimbursement/ReimbursementPaymentsSlider/ReimbursementPaymentsSliderHeader";
import ReimbursementPaymentsSliderInfo from "@/components/Reimbursement/ReimbursementPaymentsSlider/ReimbursementPaymentsSliderInfo";
import {
  PAID_SELECTED_TABS,
  PAYMENT_MODES,
  REIMBURSEMENT_PAGE_TYPE,
  REIMBURSEMENT_STATUS,
  REPORT_PAID_SELECTED_TABS,
} from "@/utils/constants/reimbursement";
import { PAGE_TYPE } from "@/utils/constants/payments";
import { amountToCurrency, dateToTimeStampString } from "@/utils/common";

export default function ReimbursementPaymentDumbComponent({
  userReimbursement,
  type,
  selectedTab,
  setSelectedTab,
  payViaVolopayCtaHandler,
  payOutsideVolopayCtaHandler,
  paySettleViaCardCtaHandler,
  count,
  totalAmount,
  paymentHistory = false,
  onScroll,
  reimbursementPaymentHistory,
  userId,
  getReimbursementPaymentsSliderTableProps,
  reportEnabled,
  sorting,
  setSorting,
}) {
  const dispatch = useDispatch();
  const defaultCurrency = useSelector(defaultCurrencySelector);

  const isDownloadingPaymentReceipt = useSelector(
    isDownloadingPaymentReceiptSelector
  );
  const ctas = useSelector(
    reportEnabled
      ? selectedUserReportCtasSelector
      : selectedUserReimbursementCtaSelector
  );

  const showCta = ctas?.length;

  const [disablePayCta, setDisablePayCta] = useState(false);

  useEffect(() => {
    if (selectedTab?.key === "2" && type === PAGE_TYPE.paid) {
      setSorting((prev) => ({
        ...prev,
        defaultCategory: null,
        defaultType: null,
      }));
    }
  }, [selectedTab]);

  const downloadHandler = () => {
    dispatch(downloadPaymentReceipt({ id: userReimbursement.paymentId }));
  };

  return userReimbursement &&
    (paymentHistory || userReimbursement?.createdBy) ? (
    <>
      <div className="pb-8 slider-content-core">
        <section className="mb-5">
          {paymentHistory ? (
            <ReimbursementPaymentsSliderHeader
              amount={amountToCurrency(userReimbursement?.settledAmount)}
              noOfClaims={userReimbursement?.settledClaims}
              paymentDate={dateToTimeStampString(
                userReimbursement?.paymentDate
              )}
              status={userReimbursement?.status}
              paymentHistory={paymentHistory}
              data={userReimbursement}
              reportEnabled={reportEnabled}
            />
          ) : (
            <ReimbursementPaymentsSliderHeader
              name={userReimbursement?.createdBy?.displayName}
              userRole={userReimbursement?.createdBy?.roles}
              department={userReimbursement?.createdBy?.departmentName}
              location={userReimbursement?.createdBy?.locationName}
              colorCode={userReimbursement?.createdBy?.colorCode}
              avatarUrl={userReimbursement?.createdBy?.avatarUrl}
            />
          )}
        </section>

        {paymentHistory &&
        userReimbursement?.status === PAYMENT_MODES.payOutsideVolopay ? (
          <div className="p-4 mt-6 mb-8 border-[1px] border-neutral-200 rounded-lg flex flex-col">
            <Text
              translationKey="reimbursement.paid.paymentHistory.paymentMode"
              classes="font-medium text-neutral-500 text-sm"
            />
            <Text
              translationKey="reimbursement.paid.paymentHistory.cash"
              classes="font-medium text-neutral-800 "
            />
          </div>
        ) : null}

        {paymentHistory ? null : (
          <section className="mt-5 mb-8">
            <ReimbursementPaymentsSliderInfo
              type={type}
              reportEnabled={reportEnabled}
              createdBy={userReimbursement?.createdBy}
              amount={userReimbursement?.amount}
              noOfClaims={userReimbursement?.claims}
              noOfReports={userReimbursement?.reports}
            />
          </section>
        )}

        <section className="mb-12">
          {type !== REIMBURSEMENT_PAGE_TYPE.payments ? (
            <Tabs
              items={
                reportEnabled ? REPORT_PAID_SELECTED_TABS : PAID_SELECTED_TABS
              }
              selectedTab={selectedTab?.key}
              setCurrentTab={(tab) => setSelectedTab(tab)}
              mode
            />
          ) : null}

          <div
            className={`${
              type === REIMBURSEMENT_PAGE_TYPE.payments ? "" : "mt-8"
            }`}
            id="tabContent"
          >
            {selectedTab.key === "2" &&
            type === REIMBURSEMENT_PAGE_TYPE.paid ? (
              <ReimbursementPaidSliderPaymentHistoryTable
                data={reimbursementPaymentHistory}
                userId={userId}
                selectedTab={selectedTab}
                reportEnabled={reportEnabled}
                sorting={sorting}
                setSorting={setSorting}
              />
            ) : null}

            {selectedTab.key === "1" &&
            type === REIMBURSEMENT_PAGE_TYPE.paid ? (
              <ReimbursementPaymentsSliderTable
                {...getReimbursementPaymentsSliderTableProps()}
                sorting={sorting}
                setSorting={setSorting}
                setDisablePayCta={setDisablePayCta}
              />
            ) : null}

            {paymentHistory && type === REIMBURSEMENT_PAGE_TYPE.payments ? (
              <ReimbursementPaymentsSliderTable
                {...getReimbursementPaymentsSliderTableProps()}
                sorting={sorting}
                setSorting={setSorting}
                setDisablePayCta={setDisablePayCta}
              />
            ) : null}

            {type === REIMBURSEMENT_PAGE_TYPE.payments && !paymentHistory ? (
              <ReimbursementPaymentsSliderTable
                {...getReimbursementPaymentsSliderTableProps()}
                sorting={sorting}
                setSorting={setSorting}
                hideCheckBox={!showCta}
                setDisablePayCta={setDisablePayCta}
              />
            ) : null}
          </div>
        </section>
      </div>
      {type === REIMBURSEMENT_PAGE_TYPE?.payments &&
      !paymentHistory &&
      showCta ? (
        <div className="px-5 py-5 slider-footer">
          <ReimbursementPaymentsSliderFooter
            payViaVolopayCtaHandler={payViaVolopayCtaHandler}
            payOutsideVolopayCtaHandler={payOutsideVolopayCtaHandler}
            paySettleViaCardCtaHandler={paySettleViaCardCtaHandler}
            count={count}
            totalAmount={Number(totalAmount)?.toFixed(2)}
            currency={defaultCurrency}
            disablePayCta={disablePayCta}
          />
        </div>
      ) : null}

      {paymentHistory &&
      userReimbursement?.status === REIMBURSEMENT_STATUS.paid ? (
        <div className="flex justify-end px-5 py-5 slider-footer">
          <Button
            label="myVolopay.reimbursements.downloadPaymentReceipt"
            preIcon="Download"
            variant="tertiary"
            classes=" w-fit px-5"
            disabled={isDownloadingPaymentReceipt}
            onClick={() => {
              downloadHandler();
            }}
          />
        </div>
      ) : null}
    </>
  ) : (
    <div className="w-full px-8 ">
      <LoaderSkeleton
        size="lg"
        borderRadius="200"
        fullWidth
        count={5}
        classes="py-4 my-2"
      />
    </div>
  );
}

ReimbursementPaymentDumbComponent.propTypes = {
  userReimbursement: PropTypes.object,
  type: PropTypes.string,
  selectedTab: PropTypes.object,
  setSelectedTab: PropTypes.func,
  payViaVolopayCtaHandler: PropTypes.func,
  payOutsideVolopayCtaHandler: PropTypes.func,
  paySettleViaCardCtaHandler: PropTypes.func,
  count: PropTypes.number,
  totalAmount: PropTypes.number,
  paymentHistory: PropTypes.bool,
  reimbursementPaymentHistory: PropTypes.object,
  userId: PropTypes.number,
  onScroll: PropTypes.func,
  getReimbursementPaymentsSliderTableProps: PropTypes.func,
  reportEnabled: PropTypes.bool,
  sorting: PropTypes.object,
  setSorting: PropTypes.func,
};
