import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

import ViewMoreCompoent from "../ViewMoreComponent";

export default function FormSummaryInfo({
  formFields = [],
  labelClasses,
  showSection = true,
  outerSectionClasses,
}) {
  let ValueComponent = () => {};

  const getValueComponent = (formField, index) => {
    const { valueType, show } = formField;

    switch (valueType) {
      case "array":
        ValueComponent = <ViewMoreCompoent renderArray={formField?.value} />;
        break;

      default:
        ValueComponent = (
          <>
            <div className={`${formField?.labelContainerClass} w-full `}>
              <Text
                translationKey={formField.label}
                classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500"
              />
            </div>

            <div
              className={`text-right ${labelClasses} flex justify-end gap-2`}
            >
              {formField?.icon ? formField?.icon : null}
              <Text
                translationKey={formField.value}
                tooltipClasses="w-80"
                classes="truncate text-right text-sm not-italic font-semibold leading-6 text-neutral-800"
                id={`key-${index}`}
                showTooltip
              />
            </div>
          </>
        );
        break;
    }

    return show ? (
      <>
        <div className={`${formField?.labelContainerClass} w-full`}>
          <Text
            translationKey={formField.label}
            classes="text-sm not-italic font-medium leading-6 tracking-[0.28px] text-neutral-500"
          />
        </div>

        {typeof formField?.value === typeof [] ? (
          <ViewMoreCompoent renderArray={formField?.value} />
        ) : (
          <div className={`text-right ${labelClasses} flex justify-end gap-2`}>
            {formField?.icon ? formField?.icon : null}
            <Text
              translationKey={formField.value}
              tooltipClasses="w-80"
              classes="truncate text-right  not-italic  text-sm  font-semibold not-italic leading-6 text-neutral-800"
              id={`key-${index}`}
              showTooltip
            />
          </div>
        )}
      </>
    ) : null;
  };

  return showSection ? (
    <div className={`p-0 border-b-0 card-wrapper ${outerSectionClasses}`}>
      {formFields.map((formField, index) => {
        return (
          formField.value &&
          formField.show && (
            <div
              className="flex flex-row justify-between w-full p-4 border-b border-solid border-b-neutral-200"
              key={`form-summary-value-${JSON.stringify(formField?.value)}-${
                formField.show
              }-${index}`}
            >
              {getValueComponent(formField, index)}
            </div>
          )
        );
      })}
    </div>
  ) : null;
}

FormSummaryInfo.propTypes = {
  formFields: PropTypes.array,
  labelClasses: PropTypes.string,
  showSection: PropTypes.bool,
  outerSectionClasses: PropTypes.string,
};
