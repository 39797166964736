import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchPaymentModes } from "@/store/reducers/payments";
import {
  fetchUserReimbursements,
  fetchUserReport,
  setSelectedPaymentsInitialData,
  settleClaimReimbursement,
} from "@/store/reducers/reimbursement";

import {
  defaultCurrencySelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";
import {
  isLoadingSelector,
  selectedPaymentsSelector,
  selectedUserReimbursementTotalSelector,
  selectedUserReportTotalReportsSelector,
} from "@/store/selectors/reimbursement";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { REIMBURSEMENT_PAGE_TYPE } from "@/utils/constants/reimbursement";
import { getCorrectedKeysObject } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function PaySettleViaCard({ setOnClose }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const selectedReimbursementPayments = useSelector(selectedPaymentsSelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const isLoading = useSelector(isLoadingSelector);

  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  const total = useSelector((state) =>
    reportEnabled
      ? selectedUserReportTotalReportsSelector(state)
      : selectedUserReimbursementTotalSelector(state)
  );

  const cancelHandler = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.reimbursements.settleViaCardConfirmation
    );

    if (total === selectedReimbursementPayments?.reimbursementIds?.length) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.reimbursements.pageType);

      if (reportEnabled) {
        searchParams.delete(SLIDERS_SEARCH_PARAMS.reports.userReportId);
      } else {
        searchParams.delete(SLIDERS_SEARCH_PARAMS.reimbursements.id);
      }
    }

    setSearchParams(searchParams);
  };

  const onSuccess = () => {
    const payload = {
      page: 1,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      type: REIMBURSEMENT_PAGE_TYPE.payments,
    };
    if (reportEnabled) {
      dispatch(fetchUserReport(payload));
    } else {
      dispatch(fetchUserReimbursements(payload));
    }
    cancelHandler();
  };

  const markAsPaidCtaHandler = () => {
    let payload = { ...selectedReimbursementPayments };

    payload = getCorrectedKeysObject(payload);

    dispatch(settleClaimReimbursement({ payload, onSuccess }));
  };

  useEffect(() => {
    if (selectedReimbursementPayments?.vendorId) {
      dispatch(
        fetchPaymentModes({
          vendor_id: selectedReimbursementPayments.vendorId,
        })
      );
    }
  }, [selectedReimbursementPayments?.vendorId]);

  useEffect(() => {
    if (!selectedReimbursementPayments) cancelHandler();

    setOnClose((searchParamArray) => {
      const searchParam =
        SLIDERS_SEARCH_PARAMS.reimbursements.settleViaCardConfirmation;
      const isClosed = !searchParamArray?.includes(searchParam);

      if (isClosed) dispatch(setSelectedPaymentsInitialData());
    });
  }, []);

  return (
    <>
      <div className="slider-content-container">
        <div className="slider-content-core ">
          <div className="mb-10">
            <Text
              classes="payment-slider-heading"
              translationKey="reimbursement.payments.sliderHeadings.confirmPayment"
            />
          </div>
          <div className="flex flex-col gap-8">
            <Input
              label="reimbursement.payments.payOutsideVolopay.reimburseAmountLabel"
              rightText={defaultCurrency}
              value={selectedReimbursementPayments?.amount}
              labelExtraClasses="text-xs text-neutral-400"
              disabled
            />
          </div>
        </div>
        <div className="sticky p-6 slider-footer ">
          <div className="flex justify-end gap-4">
            <Button
              onClick={cancelHandler}
              label="reimbursement.payments.payOutsideVolopay.cancel"
              variant="tertiary"
              classes="w-fit px-10 py-3 text-btn-lg font-semibold"
              disabled={isLoading}
            />

            <Button
              onClick={markAsPaidCtaHandler}
              label="reimbursement.ctas.markAsPaid"
              variant="primary"
              classes="px-5 py-3 text-btn-lg font-semibold w-18"
              showLoader={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
