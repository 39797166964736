import PropTypes from "prop-types";

import BudgetsTab from "@/components/Company/Slider/LocationDepartmentDetails/BudgetsTab";
import HistoryTab from "@/components/Company/Slider/LocationDepartmentDetails/HistoryTab";
import PeopleTab from "@/components/Company/Slider/LocationDepartmentDetails/PeopleTab";
import SpendsTab from "@/components/Company/Slider/LocationDepartmentDetails/SpendsTab";

import { BUDGET, DEPARTMENT_SLIDER_TAB_KEYS } from "@/constants/company";

/**
 *
 * Renders tab content based on prop
 * Reused across all department/project/budget/location sliders
 *
 * @param {String} tab spends | budgets | people | history
 * @param {String} sliderData /${id} API data of parent slider
 * @param {String} parentSliderType current slider env (Tab is rendering as Department/Project/BudgetProject)
 */

export default function TabHelper({
  tab = DEPARTMENT_SLIDER_TAB_KEYS.SPENDS,
  sliderData = null,
  parentSliderType,
}) {
  switch (tab) {
    case DEPARTMENT_SLIDER_TAB_KEYS.SPENDS:
      return (
        <SpendsTab
          sliderData={sliderData}
          parentSliderType={parentSliderType}
          // When budget slider is opened, don't show donut and its title
          {...(parentSliderType === BUDGET
            ? { title: "", showDonut: false }
            : {})}
        />
      );
    case DEPARTMENT_SLIDER_TAB_KEYS.BUDGETS:
      return (
        <BudgetsTab
          sliderData={sliderData}
          parentSliderType={parentSliderType}
        />
      );
    case DEPARTMENT_SLIDER_TAB_KEYS.PEOPLE:
      return (
        <PeopleTab
          sliderData={sliderData}
          parentSliderType={parentSliderType}
        />
      );
    case DEPARTMENT_SLIDER_TAB_KEYS.HISTORY:
      return (
        <HistoryTab
          sliderData={sliderData}
          parentSliderType={parentSliderType}
        />
      );
    default:
      break;
  }
}

TabHelper.propTypes = {
  tab: PropTypes.string,
  sliderData: PropTypes.object,
  parentSliderType: PropTypes.string, // "department" | "project" | "budget" | "location"
};
