import { string } from "prop-types";
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";

import { ROUTES } from "@/constants/routes";
import {
  CONSIDER_INACTIVITY_AFTER,
  LOGOUT_AFTER_INACTIVITY,
} from "@/constants/user";

export default function InactivityConfirmation({ title, subtext }) {
  const [timer, setTimer] = useState(LOGOUT_AFTER_INACTIVITY);
  const [showTimer, setShowTimer] = useState(false);
  const [timeInterval, setTimeInterval] = useState(null);

  const onIdle = () => {
    setTimeInterval(startTimer(LOGOUT_AFTER_INACTIVITY));
  };

  useIdleTimer({
    onIdle,
    timeout: 1_000 * 60 * CONSIDER_INACTIVITY_AFTER, // 30 mins
    crossTab: true,
  });

  const onSubmitClick = () => {
    setShowTimer(false);
    clearInterval(timeInterval);
    setTimeInterval(null);
  };

  const startTimer = (seconds) => {
    let countdown = seconds;

    setShowTimer(true);
    setTimer(LOGOUT_AFTER_INACTIVITY);
    const displayCountdown = () => {
      setTimer(countdown);

      if (countdown > 0) {
        countdown -= 1;
      } else {
        clearInterval(intervalId); // Clear the interval when the countdown reaches 0
        window.location.href = `${window.location.protocol}//${window.location.host}${ROUTES.inactivityLogout.base.absolutePath}`;
      }
    };

    const intervalId = setInterval(displayCountdown, 1000); // Set interval for every 1000 milliseconds
    return intervalId;
  };

  return (
    <Modal open={showTimer} innerContainerClasses="w-80 px-6">
      <div className="flex flex-row items-center justify-between ">
        <Text classes="py-2 font-bold text-lg" translationKey={title} />
        <Chip label={timer} />
      </div>
      {/* <div className="py-2 font-semibold ">{subtitle}</div> */}
      <div className="py-2">
        <div className="text-sm font-semibold text-neutral-400">
          <Text translationKey={subtext} />
        </div>
      </div>
      <div className="flex gap-2 py-4">
        <Button
          variant="primary"
          label="Continue session"
          onClick={onSubmitClick}
        />
      </div>
    </Modal>
  );
}

InactivityConfirmation.propTypes = {
  title: string,
  subtext: string,
};
