import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setCurrentPolicy } from "@/store/reducers/policy";

import { currentPolicySelector } from "@/store/selectors/policy";

import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import { capitalizeFirstLetter } from "@/utils/common";

import {
  AMOUNT_OPTIONS,
  EDIT_TYPE,
  REQUIREMENT_OPTIONS,
  TAGS_TYPES_ARRAY,
} from "@/constants/settings";

export default function EditForm({ name, form, tagType, index }) {
  const dispatch = useDispatch();
  const curtPolicy = useSelector(currentPolicySelector);

  const [selectedTransaction, setSelectedTransaction] = useState(
    form.amount ? AMOUNT_OPTIONS[1] : AMOUNT_OPTIONS[0]
  );

  const updateValue = (option, type) => {
    const currentPolicy = JSON.parse(JSON.stringify(curtPolicy));

    const currentValue = TAGS_TYPES_ARRAY.includes(tagType)
      ? currentPolicy[tagType][index]
      : currentPolicy[name];

    if (type === EDIT_TYPE.amount) {
      currentValue.amount = option;
    } else {
      currentValue.amount = null;
      if (type === EDIT_TYPE.for) setSelectedTransaction(option);
      else currentValue.required = option.value;
    }

    dispatch(setCurrentPolicy(currentPolicy));
  };

  const requirementValue = () => {
    if (TAGS_TYPES_ARRAY.includes(tagType))
      return curtPolicy[tagType][index]?.required;
    return curtPolicy[name] && curtPolicy[name]?.required;
  };
  return (
    <div
      key={form.id}
      className="p-6 pt-4 border border-neutral-200 rounded-md my-2 flex flex-col gap-6"
    >
      <span className="text-md font-medium">
        <Text
          translationKey={capitalizeFirstLetter(name)}
          classes="first-letter:uppercase"
        />
      </span>
      <VpSelect
        label="Requirement"
        placeholder="Requirement"
        options={REQUIREMENT_OPTIONS}
        classes="text-sm"
        menuPosition="absolute"
        valueKey="value"
        value={requirementValue()}
        handleChange={(options) => {
          updateValue(options, "required");
        }}
      />
      {requirementValue() ? (
        <div className="flex items-top gap-6 w-full mt-3">
          <div className="text-neutral-300">
            <Icon name="SubdirectoryArrowRight" />
          </div>
          <div className="w-full">
            <VpSelect
              label="For"
              placeholder="For"
              options={AMOUNT_OPTIONS}
              classes="text-sm"
              menuPosition="absolute"
              valueKey="value"
              value={selectedTransaction.value}
              handleChange={(option) => {
                updateValue(option, "for");
              }}
            />
          </div>
          <div>
            <Input
              placeholder="Amount"
              type="number"
              showNumberInvalidError
              label="Amount"
              value={form?.amount}
              disabled={selectedTransaction.value === "all"}
              onChange={(e) => {
                updateValue(e.target.value, "amount");
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

EditForm.propTypes = {
  name: PropTypes.string,
  form: PropTypes.object,
  tagType: PropTypes.string,
  index: PropTypes.number,
};
