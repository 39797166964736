import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// custom hooks
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

// reducers
import { resetPin, setIsPinReset } from "@/store/reducers/cards";

import { isPinUpdatedStatusSelector } from "@/store/selectors/cards";

// core components
import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import { useForm } from "@/utils/useForm";

//  utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

export default function ChangePin() {
  const ref = useLeftHeaderTitle({
    title: "cards.sliders.changePin.title",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const isPinResetSuccessfully = useSelector(isPinUpdatedStatusSelector);

  const dispatch = useDispatch();

  const cardId = searchParams.get(SLIDERS_SEARCH_PARAMS.cards.changePin);

  const CHANGE_PIN_FIELDS_NAME = {
    NEW_PIN: "newPin",
    CONFIRM_PIN: "confirmPin",
  };

  const initialValues = {
    [CHANGE_PIN_FIELDS_NAME.NEW_PIN]: {
      value: undefined,
      validate: {
        required: true,
        regex: "\\d{6,6}",
      },
      errorStatement: {
        regex: "cards.sliders.changePin.fields.newPin.errorStatement",
      },
    },

    [CHANGE_PIN_FIELDS_NAME.CONFIRM_PIN]: {
      value: undefined,
      validate: {
        required: true,
        compare: {
          keys: [
            CHANGE_PIN_FIELDS_NAME.CONFIRM_PIN,
            CHANGE_PIN_FIELDS_NAME.NEW_PIN,
          ],
          comparatorFunc: (a, b) => a === b,
        },
      },
      errorStatement: {
        compare: "cards.sliders.changePin.fields.confirmPin.errorStatement",
      },
    },
  };

  const formSchema = [
    {
      name: CHANGE_PIN_FIELDS_NAME.NEW_PIN,
      description: "cards.sliders.changePin.fields.newPin.description",
      type: "number",
      label: "cards.sliders.changePin.fields.newPin.label",
      placeholder: "cards.sliders.changePin.fields.newPin.placeholder",
      maxLength: 6,
    },
    {
      name: CHANGE_PIN_FIELDS_NAME.CONFIRM_PIN,
      description: "cards.sliders.changePin.fields.confirmPin.description",
      type: "number",
      label: "cards.sliders.changePin.fields.confirmPin.label",
      placeholder: "cards.sliders.changePin.fields.confirmPin.placeholder",
      maxLength: 6,
    },
  ];

  useEffect(() => {
    if (isPinResetSuccessfully) {
      closeChangePinSlider();
    }

    return () => {
      dispatch(setIsPinReset(false));
    };
  }, [isPinResetSuccessfully]);

  const onSubmit = () => {
    dispatch(
      resetPin({
        payload: { pin: values[CHANGE_PIN_FIELDS_NAME.NEW_PIN] },
        cardId,
      })
    );
  };

  function closeChangePinSlider() {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.changePin);
    setSearchParams(searchParams);
  }

  const { handleChange, values, errors, handleSubmit } = useForm(
    initialValues,
    onSubmit,
    {
      isFetchingInitialValue: true,
    }
  );

  return (
    <>
      <div className="slider-content-core">
        <div ref={ref} className="mb-7">
          <Text
            translationKey="cards.sliders.changePin.title"
            classes="text-3xl not-italic font-bold leading-[56px] tracking-[-0.36px] text-neutral-800"
          />
        </div>

        <form id="change_card_pin" onSubmit={handleSubmit}>
          {formSchema?.map((field, index) => {
            const { type, name, label, placeholder, description, maxLength } =
              field;

            let FieldComponent = <></>;

            switch (type) {
              case "number":
                FieldComponent = (
                  <Input
                    key={`${name}-${index}`}
                    name={name}
                    error={errors[name]}
                    value={values[name]}
                    type={type}
                    onChange={handleChange}
                    label={label}
                    placeholder={placeholder}
                    description={description}
                    maxLength={maxLength}
                  />
                );
                break;

              default:
                break;
            }

            return (
              <div className="mb-8" key={`${index}-${name}`}>
                {FieldComponent}
              </div>
            );
          })}
        </form>
      </div>

      <div className="flex justify-end px-3 py-5 slider-footer">
        <Button
          label="cards.sliders.changePin.buttons.submit"
          classes="w-[160px] text-white font-medium"
          variant="primary"
          btnType="submit"
          form="change_card_pin"
        />
      </div>
    </>
  );
}
