import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

export default function Memo({
  onChange = () => {},
  onKeyUp = () => {},
  children,
  value,
  name = "textArea",
  error = null,
  disabled = false,
  insideForm = false,
  onBlur = () => {},
  placeholderLabel,
  placeholderMemoDescription,
  ...extraProps
}) {
  const [showMemo, toggleShowMemo] = useState(disabled);

  const currRef = useRef(null);
  useEffect(() => {
    if (showMemo && currRef) {
      currRef.current.focus();
    }
  }, [showMemo]);
  const handleMemo = (e) => {
    toggleShowMemo((prev) => !prev);
  };

  return (
    <div className="relative">
      {!showMemo && !value ? (
        <div
          className="border-[1.5px] mt-3 h-[148px] border-neutral-200 rounded-xl p-6 cursor-pointer"
          onClick={(e) => handleMemo(e)}
        >
          {children ? (
            children
          ) : (
            <div className="flex-col ">
              <Icon className="ml-auto mr-auto " name="Memo" />
              <div className="mt-4 font-semibold text-center text-neutral-800">
                <Text
                  translationKey={
                    placeholderLabel ? placeholderLabel : "memo.addMemo"
                  }
                />
              </div>
              <div className="mt-1 text-sm text-center text-neutral-500">
                <Text
                  translationKey={
                    placeholderMemoDescription
                      ? placeholderMemoDescription
                      : "memo.memoDescription"
                  }
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <textarea
          disabled={disabled}
          ref={currRef}
          onBlur={onBlur}
          className="p-6 w-full h-[148px] rounded-xl mt-3 border-[1.5px] border-neutral-200 focus::border-primary-500"
          name={name}
          onChange={(e) =>
            insideForm ? onChange(e) : onChange(e.target.value)
          }
          onKeyUp={(e) => onKeyUp(e)}
          value={value}
          {...extraProps}
        />
      )}
      {error && !value?.length ? (
        <Text
          classes="absolute  bottom-[-20px] left-[0px] text-xs text-danger-600"
          translationKey={error}
        />
      ) : null}
    </div>
  );
}
Memo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onKeyUp: PropTypes.func,
  error: PropTypes.string,
  insideForm: PropTypes.bool,
  onBlur: PropTypes.func,
  placeholderLabel: PropTypes.string,
  placeholderMemoDescription: PropTypes.string,
};
