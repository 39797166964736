import PropTypes from "prop-types";
import React from "react";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

export default function AddCardRulesSection({
  openRulesHandler,
  showRulesSection,
}) {
  return showRulesSection ? (
    <div className=" mb-2 p-3 bg-neutral-50 flex flex-col gap-4 rounded-[theme(spacing.2)]">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex gap-1">
            <Text
              translationKey="cards.vCards.createCardSlider.cardRules.title"
              classes="text-base font-semibold text-neutral-800"
            />
          </div>
          <Text
            translationKey="cards.cardDetails.editCardDetails.cardRules.help"
            classes="text-xs font-semibold text-neutral-500"
          />
        </div>
        <Button
          onClick={openRulesHandler}
          preIcon="Add"
          iconClasses="-mr-2"
          label={
            <Text
              translationKey="cards.vCards.createCardSlider.add"
              classes="text-xs font-bold"
            />
          }
          variant="default"
          classes="w-fit h-fit py-1.5 px-5 bg-neutral-50 border border-neutral-300 text-primary-500 hover:bg-neutral-100 active:bg-neutral-200"
        />
      </div>
    </div>
  ) : null;
}

AddCardRulesSection.propTypes = {
  openRulesHandler: PropTypes.func,
  showRulesSection: PropTypes.bool,
};
