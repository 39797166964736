import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { currentPaymentProviderSelector } from "@/store/selectors/client";

import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import VendorInfoCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/VendorInfoCell";
import contactDropdownOption from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/contactDropdownOption";
import EmployeeDetailsTable from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/employeeDetailsTable";
import InvoiceTable from "@/components/common/BillPayAndPayroll/VendorOrEmployee/InvoiceTable";

import {
  ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP,
  ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM,
} from "@/constants/company";
import { PAYMENT_PROVIDERS } from "@/constants/provider";
import { VENDOR_TYPE_LABELS } from "@/constants/vendors";

export default function VendorSelectedPreview({
  readOnly = true,
  contactsReadOnly,
  showEditButton = false,
  vendor,
  paymentId,
  senderCurrency,
  context,

  // required if readOnly is false
  keyName = "vendorContact",
  clearVendorHandler,
  editVendorHandler,
  values,
  errors,
  handleChange = () => {},
  inPayrollContext,
  setValues,
}) {
  const vendorContactOptions = vendor?.contactDetails ?? [];
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const provider = useSelector(currentPaymentProviderSelector);

  const bankArray = Object.entries(
    vendor?.bankDetails?.bankAccount ??
      vendor?.bankDetails?.bankDetails ??
      vendor?.bankDetails ??
      {}
  )
    ?.map(([k, v]) => ({
      name: k,
      value: v,
      label: t(ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP[k]),
    }))
    .filter(
      (row) =>
        !(
          [
            ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP.country,
            ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP.currency,
            ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP.accountCurrency,
            ACCOUNT_DETAIL_BANK_DETAIL_LABEL_MAP.bankCountryCode,
          ]?.includes(row.name) &&
          [PAYMENT_PROVIDERS.AIRWALLEX, PAYMENT_PROVIDERS.WALLEX].includes(
            provider
          )
        )
    );

  return (
    <div className="card-wrapper">
      {/* Always visible stuff */}
      <div
        className={`flex ${readOnly ? "items-center" : "items-start"} gap-3 `}
      >
        <ProfileWidget
          name={vendor?.name}
          img={vendor?.avatarUrl}
          avatarColor={vendor?.colorCode}
          showTooltip
          tooltipClasses="w-71"
          className="w-full"
          textClasses="text-lg font-bold"
        >
          {readOnly ? null : (
            <span
              onClick={clearVendorHandler}
              className="underline cursor-pointer"
            >
              <Text
                translationKey={
                  inPayrollContext
                    ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.receiver.changeEmployee"
                    : "billPay.bill.invoiceInbox.createBill.sections.receiver.changeVendor"
                }
                classes="pt-1 text-sm  text-neutral-500"
              />
            </span>
          )}
        </ProfileWidget>
        {readOnly && !showEditButton ? null : (
          <div className="grid w-6 h-6 place-items-center text-neutral-500">
            <Icon
              handleClick={editVendorHandler}
              name="Edit"
              className="w-4 h-4 transition-all ease-in cursor-pointer duration-600 stroke-neutral-500"
            />
          </div>
        )}
        <div className="grid w-6 h-6 place-items-center text-neutral-500">
          <Icon
            handleClick={() => setIsOpen((_) => !_)}
            name={`${isOpen ? "UpArrow" : "ExpandMore"}`}
            className="transition-all ease-in cursor-pointer duration-600 stroke-neutral-500 "
          />
        </div>
      </div>
      {/* Expansion UI */}
      <div className={`mt-4 ${isOpen ? "" : "hidden"}`}>
        <div className="card-wrapper">
          {inPayrollContext ? (
            <></>
          ) : (
            <VendorInfoCell
              label="billPay.bill.invoiceInbox.createBill.sections.receiver.vendorOwner"
              value={
                <span className="mb-4 font-medium">
                  <span>{vendor?.owner?.displayName}</span>
                  {vendor?.owner?.departmentName ? (
                    <span className="text-neutral-500">
                      {vendor?.owner?.displayName ? " • " : ""}
                      {vendor?.owner?.departmentName}
                    </span>
                  ) : null}
                </span>
              }
            />
          )}
          <div className="flex justify-between">
            <div className="w-1/2">
              <VendorInfoCell
                label={
                  inPayrollContext
                    ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.receiver.employeeID"
                    : "billPay.bill.invoiceInbox.createBill.sections.receiver.vendorType"
                }
                value={
                  inPayrollContext
                    ? vendor?.employeeId
                    : VENDOR_TYPE_LABELS[vendor?.type]
                }
              />
            </div>
            <div className="w-1/2 ">
              <VendorInfoCell
                label="billPay.bill.invoiceInbox.createBill.sections.receiver.beneficiaryCurrency"
                value={
                  vendor?.beneficiaryCurrency
                    ? vendor?.beneficiaryCurrency
                    : "-"
                }
              />
            </div>
          </div>
        </div>
        {bankArray?.length ? (
          <div className="flex flex-col gap-4 my-4">
            <Text
              translationKey="billPay.vendors.createVendor.accountDetails"
              classes="font-semibold"
            />
            <div className="card-wrapper">
              {bankArray.map((row, idx) => {
                let { label } = row;

                if (
                  [
                    ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM.ACCOUNT_ROUTE_TYPE_1,
                    ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM.ACCOUNT_ROUTE_TYPE_2,
                  ]?.includes(row?.label)
                ) {
                  label = "user.bankDetails.accountRoutingType";
                }

                if (
                  [
                    ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM.ACCOUNT_ROUTE_VALUE_1,
                    ACCOUNT_ROUTE_TYPE_OR_VALUE_ENUM.ACCOUNT_ROUTE_VALUE_2,
                  ]?.includes(row?.label)
                ) {
                  label = "user.bankDetails.accountRoutingValue";
                }
                return (
                  <div
                    className="flex items-center justify-between py-4 accordion-row"
                    key={`bank-detail-key-${row?.id ?? idx}`}
                  >
                    <span className="text-sm text-neutral-500">
                      <Text translationKey={label} />
                    </span>
                    <span className="text-sm font-medium tracking-wider text-right text-neutral-800">
                      {row?.displayValue || row?.value || "-"}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {/* Vendor contact. Component name is semantically incorrect, but UI is correct */}
        <div className="mt-6">
          {inPayrollContext ? (
            <Input
              name={keyName}
              value={[
                vendorContactOptions?.[0]?.email,
                vendorContactOptions?.[0]?.phone,
              ]
                .filter(Boolean)
                .join(" • ")}
              label="payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.receiver.employeeContact"
              labelExtraClasses="text-xs text-neutral-400"
              disabled={readOnly || contactsReadOnly}
            />
          ) : (
            <VpSelect
              name={keyName}
              value={
                readOnly ? vendorContactOptions?.[0]?.id : values?.[keyName]
              }
              label="billPay.bill.invoiceInbox.createBill.sections.receiver.vendorContact"
              placeholder="billPay.bill.invoiceInbox.createBill.sections.receiver.selectVendorContact"
              labelStyleClasses="font-semibold text-xs text-neutral-500"
              error={readOnly ? "" : errors?.vendorContact}
              handleChange={handleChange}
              insideForm
              menuPosition="absolute"
              options={vendorContactOptions}
              customUIForOptionWhenDropdownClosed={contactDropdownOption}
              customUIForOptionWhenDropdownOpen={contactDropdownOption}
              optionsDisplayKey="name"
              valueKey="id"
              isOptionsLoading={false}
              disabled={contactsReadOnly}
            />
          )}
        </div>

        {/* Previous invoice table */}
        <div className="mt-4">
          {inPayrollContext ? (
            <EmployeeDetailsTable
              paymentId={paymentId}
              vendorId={vendor?.id}
              context={context}
            />
          ) : (
            <InvoiceTable
              purchaseBillId={paymentId}
              context={context}
              sliderTable
              vendorId={vendor?.id}
            />
          )}
        </div>
      </div>
    </div>
  );
}

VendorSelectedPreview.propTypes = {
  vendor: PropTypes.object,
  senderCurrency: PropTypes.string,
  readOnly: PropTypes.bool,
  contactsReadOnly: PropTypes.bool, // contacts VpSelect/Input selectively disabled
  context: PropTypes.string,
  paymentId: PropTypes.number,

  // required if readOnly is false
  keyName: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  clearVendorHandler: PropTypes.func,
  editVendorHandler: PropTypes.func,
  inPayrollContext: PropTypes.bool,
};

VendorSelectedPreview.propTypes = {
  handleChange: () => {},
  setValues: () => {},
  clearVendorHandler: () => {},
  editVendorHandler: () => {},
};
