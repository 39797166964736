import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// reducers
import { setSettings } from "@/store/reducers/accounting";
import { fetchClient } from "@/store/reducers/client";

// selectors
import {
  accountingAutoReconcilationSelector,
  accountingIntegrationAutoSyncEnabledSelector,
  accountingIntegrationContinuousBillSyncSelector,
  accountingIntegrationJournalAutoApprovedSelector,
  accountingIntegrationSyncFrequencySelector,
  accountingIntegrationSyncModeSelector,
  accountingIntegrationTransferFeeSyncSelector,
  isLoadingAccountingIntegrationsSelector,
} from "@/store/selectors/accounting";
import { accountingContinuousBillSyncSelector } from "@/store/selectors/client";

// core components
import Button from "@/components/core/Button";
import Radio from "@/components/core/Radio";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

// page relevant components
import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import { useForm } from "@/utils/useForm";
import { INTEGRATION_SYNC_FREQUENCY_OPTIONS } from "@/utils/constants/integrations";
import { capitalizeFirstLetter } from "@/utils/common";

// utils, constant file imports
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP,
} from "@/constants/accounting";

import NetsuiteSyncOptionComponent from "../AccountingSoftwareConnectSliders/NetsuiteSyncOptionComponent";

export default function AccountingAutomation() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [selectedFrequency, setSelectedFrequency] = useState(
    useSelector(accountingIntegrationSyncFrequencySelector)
  );
  const [autoReconcileSelected, setAutoReconcileSelected] = useState(
    useSelector(accountingAutoReconcilationSelector)
  );
  const [syncMode, setSyncMode] = useState(
    useSelector(accountingIntegrationSyncModeSelector)
  );
  const [journalAsApproved, setJournalAsApproved] = useState(
    useSelector(accountingIntegrationJournalAutoApprovedSelector)
  );

  const currentSoftware = searchParams.get(
    SLIDERS_SEARCH_PARAMS.accounting.integrations.softwareSettings
  );

  const [autoSyncSelected, setAutoSyncSelected] = useState(
    useSelector(accountingIntegrationAutoSyncEnabledSelector)
  );

  const [syncTransferFeeSelected, setSyncTransferFeeSelected] = useState(
    useSelector(accountingIntegrationTransferFeeSyncSelector)
  );

  const frequencyOptions = INTEGRATION_SYNC_FREQUENCY_OPTIONS.map((item) => ({
    value: item,
    label: capitalizeFirstLetter(item),
  }));

  const isLoading = useSelector(isLoadingAccountingIntegrationsSelector);
  const [continuousBillSync, setContinuousBillSync] = useState(
    useSelector(accountingIntegrationContinuousBillSyncSelector)
  );

  const handleSave = async () => {
    const action = await dispatch(
      setSettings({
        sync_frequency: values?.syncFrequency,
        auto_reconcile_enabled: values?.autoReconcile,
        accounting_auto_sync_enabled: values?.autoSyncExpenses,
        transfer_fee_sync_enabled: values?.syncTransferFee,
        journal_auto_approved: journalAsApproved,
        sync_mode: syncMode,
        continuous_bill_sync: continuousBillSync,
      })
    );

    if (setSettings.fulfilled.match(action)) {
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.accounting.integrations.accountAutomation
      );
      setSearchParams(searchParams);
      setSelectedFrequency(values?.syncFrequency);
      setAutoReconcileSelected(values?.autoReconcile);
      setAutoSyncSelected(values?.autoSyncExpenses);
      setSyncTransferFeeSelected(values?.syncTransferFee);
      dispatch(fetchClient());
    }
  };

  const handleCancel = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.accounting.integrations.accountAutomation
    );
    setSearchParams(searchParams);
  };

  const initialFormValue = {
    syncFrequency: {
      value: selectedFrequency ?? "",
      validate: {
        required: true,
      },
    },
    autoSyncExpenses: {
      value: autoSyncSelected ?? false,
    },
    autoReconcile: {
      value: autoReconcileSelected ?? false,
    },
    syncTransferFee: {
      value: syncTransferFeeSelected ?? false,
    },
  };

  const { handleChange, values, errors, isFormButtonDisabled, handleSubmit } =
    useForm(initialFormValue, () => {
      handleSave();
    });

  return (
    <div className="slider-content-container">
      <div className="slider-core-container flex flex-col">
        <div className="px-9 flex flex-col gap-8">
          <SliderHeader
            heading="accounting.integrations.integrationSettings.accountingAutomation.title"
            subText="accounting.integrations.integrationSettings.accountingAutomation.description"
            headingClasses="text-3xl font-semibold text-neutral-800"
            subTextClasses="text-sm font-medium text-neutral-500"
          />
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-9"
            id={`accounting-automation-${currentSoftware}`}
          >
            <div className="flex flex-col gap-7.25">
              <div className="flex flex-col gap-1">
                <Text
                  translationKey="accounting.integrations.integrationSettings.syncFrequency.title"
                  classes="text-base font-semibold"
                />
                <Text
                  translationKey="accounting.integrations.integrationSettings.syncFrequency.description"
                  classes="text-sm font-medium text-neutral-500"
                />
              </div>
              <VpSelect
                label="accounting.integrations.integrationSettings.syncFrequency.selectFrequencyLabel"
                placeholder="accounting.integrations.integrationSettings.syncFrequency.selectFrequencyPlaceholder"
                name="syncFrequency"
                optionsDisplayKey="label"
                valueKey="value"
                value={values?.syncFrequency}
                error={errors?.syncFrequency}
                menuPosition="absolute"
                options={frequencyOptions}
                handleChange={handleChange}
                insideForm
              />
            </div>

            <div className="flex flex-col gap-1">
              <div className="flex items-center justify-between">
                <Text
                  translationKey="accounting.integrations.integrationSettings.autoReconcilation.title"
                  classes="text-base font-semibold"
                />
                <Switch
                  name="autoReconcile"
                  value={values?.autoReconcile}
                  handleChange={handleChange}
                  insideForm
                />
              </div>
              <Text
                translationKey="accounting.integrations.integrationSettings.autoReconcilation.description"
                translationProps={{
                  accountingSoftware:
                    ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[currentSoftware],
                }}
                classes="text-sm font-medium text-neutral-500"
              />
            </div>

            <div className="flex flex-col gap-1">
              <div className="flex items-center justify-between">
                <Text
                  translationKey="accounting.integrations.integrationSettings.autoSync.title"
                  classes="text-base font-semibold"
                />

                <Switch
                  name="autoSyncExpenses"
                  value={values?.autoSyncExpenses}
                  handleChange={handleChange}
                  insideForm
                />
              </div>
              <Text
                translationKey="accounting.integrations.integrationSettings.autoSync.description"
                classes="text-sm font-medium text-neutral-500"
              />
            </div>
            {currentSoftware === ACCOUNTING_SOFTWARES.NETSUITE ? (
              <NetsuiteSyncOptionComponent
                syncMode={syncMode}
                setSyncMode={setSyncMode}
                journalAsApproved={journalAsApproved}
                setJournalAsApproved={setJournalAsApproved}
                continuousBillSync={continuousBillSync}
                setContinuousBillSync={setContinuousBillSync}
              />
            ) : null}

            {/* <div className="flex flex-col gap-1 mb-12">
              <div className="flex items-center justify-between">
                <Text
                  translationKey="accounting.integrations.integrationSettings.syncTransferFee.title"
                  classes="text-base font-semibold text-neutral-800"
                />

                <Switch
                  name="syncTransferFee"
                  value={values?.syncTransferFee}
                  handleChange={handleChange}
                  insideForm
                />
              </div>
              <Text
                translationKey="accounting.integrations.integrationSettings.syncTransferFee.description"
                classes="text-sm font-medium text-neutral-500"
              />
            </div> */}
          </form>
        </div>
      </div>
      <div className="slider-footer sticky px-6 py-4">
        <div className="flex justify-end items-center gap-6 font-semibold">
          <Button
            label="accounting.integrations.buttonLabel.cancel"
            variant="tertiary"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            onClick={() => {
              handleCancel();
            }}
          />
          <Button
            label="accounting.integrations.buttonLabel.saveChanges"
            size="md"
            btnType="submit"
            classes="w-fit px-5 py-3 text-btn-lg font-semibold"
            showLoader={isLoading}
            onClick={() => handleSave()}
            disabled={isFormButtonDisabled}
            form={`accounting-automation-${currentSoftware}`}
          />
        </div>
      </div>
    </div>
  );
}
