import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCompanyAlerts } from "@/store/reducers/company";

import {
  alertsSelector,
  isFetchingAlertsSelector,
} from "@/store/selectors/company";

import Loader from "@/components/core/Loader";

import AlertToggleAndAmount from "../AlertToggleAndAmount";

export default function Alerts() {
  const dispatch = useDispatch();
  const alertsData = useSelector(alertsSelector);
  const isFetching = useSelector(isFetchingAlertsSelector);

  useEffect(() => {
    dispatch(fetchCompanyAlerts());
  }, []);

  return isFetching ? (
    <Loader />
  ) : (
    <div className="mt-9 flex flex-col gap-10">
      {Object.entries(alertsData).map(([key, values = []], index) =>
        Object.keys(values)?.length ? (
          <div
            key={`alerts-index-${index}-item-${key}`}
            className="flex flex-col gap-10"
          >
            <AlertToggleAndAmount alert={{ ...values, key }} />
            {index !== values.length - 1 ? (
              <hr className="text-neutral-300" />
            ) : null}
          </div>
        ) : null
      )}
    </div>
  );
}
