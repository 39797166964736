import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setSelectedGlobalAccountLocation } from "@/store/reducers/account";

import {
  globalCountryCurrenciesSelector,
  selectedGlobalAccountCurrencySelector,
} from "@/store/selectors/account";

import Button from "@/components/core/Button";
import Flag from "@/components/core/Flag";
import Radio from "@/components/core/Radio";
import Text from "@/components/core/Text";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { COUNTRY_CURRENCY } from "@/constants/currency";

function AddAccountLocationSlider() {
  const currency = useSelector(selectedGlobalAccountCurrencySelector);
  const globalCountryCurrencies = useSelector(globalCountryCurrenciesSelector);

  const dispatch = useDispatch();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const radioButtons = () => {
    return Object.keys(globalCountryCurrencies[currency]).map((country) => (
      <Radio
        key={`${currency}-${country}`}
        id={`${currency}-${country}`}
        name={country}
        radioButtonClasses="block sticky"
        textClases="flex items-center p-0 mt-4"
        label={
          <div className="flex">
            <Flag
              code={COUNTRY_CURRENCY[country]?.currency}
              size="lg"
              classes="border border-neutral-200 ml-2"
            />
            <div className="flex flex-col ml-3">
              <Text
                translationKey={COUNTRY_CURRENCY[country]?.country}
                classes="font-medium text-neutral-800"
              />
              <span className="text-xs text-neutral-500">
                {globalCountryCurrencies[currency][country].join(", ")}
              </span>
            </div>
          </div>
        }
        value={country}
        handleChange={(e) => {
          setSelectedCountry(e);
        }}
        isChecked={country === selectedCountry}
      />
    ));
  };

  return (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        <div className="flex flex-col">
          <Text
            classes="payment-slider-heading"
            translationKey="company.globalAccounts.addAccountTitle"
          />
          <Text
            classes="text-neutral-500 text-sm"
            translationKey="company.globalAccounts.addAccountDesc"
          />
        </div>

        <div className="flex flex-col mt-10">
          <Text
            classes="text-lg font-semibold"
            translationKey="company.globalAccounts.bankLocation"
          />

          <Text
            classes="text-neutral-500 text-sm mb-3"
            translationKey="company.globalAccounts.bankLocationDesc"
          />

          <div>
            {Object.keys(globalCountryCurrencies).length > 0 && currency
              ? radioButtons()
              : null}
          </div>
        </div>
      </div>

      <div className="sticky p-6 slider-footer ">
        <div className="flex justify-end ">
          <Button
            label="billPay.bill.ctas.review"
            classes="w-18 px-5 py-3 text-base font-semibold"
            id="add-account-button"
            disabled={!selectedCountry}
            onClick={() => {
              dispatch(setSelectedGlobalAccountLocation(selectedCountry));
              searchParams.append(
                SLIDERS_SEARCH_PARAMS.company.reviewGlobalAccount,
                true
              );
              setSearchParams(searchParams);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AddAccountLocationSlider;
