import PropTypes from "prop-types";

import Text from "@/components/core/Text";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";

export default function BeneficiaryCurrCell({ val }) {
  const { context } = val;

  return (
    <Text
      tooltipClasses="w-full"
      id={`BeneficiaryCurrCell-${val?.id}`}
      classes="inline-block w-full" // show tooltip for entire width
      translationKey={val?.vendor?.beneficiaryCurrency || "-"}
      showTooltip={!val?.vendor?.beneficiaryCurrency}
      toolTipText={
        context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? "billPay.vendors.tableCells.noBeneficiaryCurrencyPayroll"
          : "billPay.vendors.tableCells.noBeneficiaryCurrencyBillpay"
      }
      noTranslate
    />
  );
}

BeneficiaryCurrCell.propTypes = {
  val: PropTypes.shape({
    id: PropTypes.any,

    vendor: PropTypes.object,
    openSliderHandler: PropTypes.func,
    duplicateBillId: PropTypes.any,

    disabled: PropTypes.bool,
    checkedValue: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
  }),
};
