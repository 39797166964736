import PropTypes from "prop-types";

import ApprovalProgress from "@/components/core/ApprovalProgress";

export default function ApprovalProgressCell({ val }) {
  return (
    <>
      {val?.bulkProcessing ? (
        <></>
      ) : (
        <ApprovalProgress
          id={val?.id}
          disabled={val?.disabled}
          totalApprovalSteps={val?.approvalLevels ?? val?.approverLevels}
          currentStep={val?.currentApprovalLevel}
          currentStatus={val?.currentApprovalLevelStatus || val?.status}
          previousApprovers={val?.previousApprovers}
        />
      )}
    </>
  );
}

ApprovalProgressCell.propTypes = {
  val: PropTypes.object,
};
