import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  cardRequestDetailsIsFetchingSelector,
  newCardRequestDetailsSelector,
} from "@/store/selectors/cards";

import Alert from "@/components/core/Alert";
import Timeline from "@/components/core/Timeline";
import { TYPES_TIMELINE } from "@/utils/constants/timeline";

import {
  CARD_REQUEST_STATUS,
  CARD_REQUEST_TYPE_CELL_TOOLTIPS_CONFIG,
} from "@/constants/ActionCentre";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import CardsModuleRequestSection from "../../common/CardsModuleRequestSection";
import CardLimitCommentForm from "./CardLimitCommentForm";
import CardLimitRequestOverviewCards from "./CardLimitRequestOverviewCards";
import CardLimitReview from "./CardLimitReview";
import LimitSummaryInfo from "./LimitSummaryInfo";

export default function OverviewTab({
  formSchema,
  handleChange,
  values,
  errors,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);
  const CARD_LIMIT_REQUEST_SLIDER_ALERT_BY_REQUEST_STATUS_CONFIG = {
    [CARD_REQUEST_STATUS.PROCESSING]: {
      description:
        "myVolopay.actionCentre.sliders.requestSliders.limit.alerts.processing",
      descriptionTransalationProp: {
        amount: cardRequestDetails?.newLimit,
        currency: cardRequestDetails?.currency,
      },
      classes: "text-warning-300 bg-warning-50 border-warning-300",
    },
    [CARD_REQUEST_STATUS.AWAITING_FUNDS]: {
      description:
        "myVolopay.actionCentre.sliders.requestSliders.limit.alerts.awaitingFunds",

      classes: "text-warning-300 bg-warning-50 border-warning-300",
    },
    [CARD_REQUEST_STATUS.FAILED]: {
      classes: "rounded-lg text-danger-300 bg-danger-50 border-danger-300",
      description:
        "myVolopay.actionCentre.sliders.requestSliders.limit.alerts.failed",
      descriptionTransalationProp: {
        amount: cardRequestDetails?.newLimit,
        currency: cardRequestDetails?.currency,
      },
    },
  };

  const isFetching = useSelector(cardRequestDetailsIsFetchingSelector);
  const isError = Object.keys(cardRequestDetails ?? {})?.length === 0;

  const { reason } = cardRequestDetails;
  const status = cardRequestDetails?.status;
  const cardRequestStatusToolTipConfig =
    CARD_REQUEST_TYPE_CELL_TOOLTIPS_CONFIG?.[status];

  const {
    description: alertDescription = "",
    descriptionTransalationProp: alertDescriptionTranslationProps,
    classes: alertDescriptionClasses = "",
  } = CARD_LIMIT_REQUEST_SLIDER_ALERT_BY_REQUEST_STATUS_CONFIG?.[status] ?? {};

  return (
    <>
      {!isFetching && !isError ? (
        <div>
          <div className="flex flex-col gap-4 mt-5">
            {cardRequestStatusToolTipConfig ? (
              <Alert
                description={alertDescription}
                descriptionTransalationProp={alertDescriptionTranslationProps}
                classes={alertDescriptionClasses}
              />
            ) : null}

            {cardRequestDetails?.reimbursementSettlementId ? (
              <Alert
                variant="warning"
                wrapperDivClass="items-start"
                title="cards.cardSlider.settleClaimViaCard.title"
                description="cards.cardSlider.settleClaimViaCard.description"
                primaryAction={{
                  callback: (e) => {
                    e?.stopPropagation();
                    searchParams.append(
                      SLIDERS_SEARCH_PARAMS.reimbursements.paymentHistoryId,
                      cardRequestDetails.reimbursementSettlementId
                    );
                    setSearchParams(searchParams);
                  },
                  label: "cards.cardSlider.settleClaimViaCard.linkText",
                  classes: "underline",
                }}
              />
            ) : null}

            <Timeline
              id={cardRequestDetails?.id}
              status={cardRequestDetails?.currentApprovalLevelStatus}
              currentApprovers={cardRequestDetails?.currentApprovers || []}
              rejectedReason={cardRequestDetails?.rejectedReason}
              currentApproverLevel={cardRequestDetails?.currentApprovalLevel}
              totalApprovers={cardRequestDetails?.approvalLevels}
              type={TYPES_TIMELINE.CARD_REQUEST}
            />
          </div>
          <CardLimitRequestOverviewCards
            cardName={cardRequestDetails?.cardName}
            autoBlockDate={cardRequestDetails?.autoBlockDate}
            cardHolder={
              cardRequestDetails?.cardHolder?.length > 1
                ? cardRequestDetails?.cardHolder
                : cardRequestDetails?.cardHolder?.[0]
            }
            cardType={cardRequestDetails?.cardType}
            cardNumber={cardRequestDetails?.cardNumber}
            linkedTo={cardRequestDetails?.linkedTo}
            project={cardRequestDetails?.project}
            cardUsageType={cardRequestDetails?.cardUsageType}
          />
          <CardLimitReview data={cardRequestDetails} />
          <LimitSummaryInfo outerSectionClasses="mt-4" />
          <CardLimitCommentForm
            formSchema={formSchema}
            handleChange={handleChange}
            defaultValue={reason}
            disabled
            values={values}
            errors={errors}
            outerSectionClasses="my-8"
            id="approve-limit-request"
          />
          {/*
      <div className="flex flex-col gap-3 mt-4">
        <Text
          translationKey="Request timeline"
          classes="text-lg font-semibold "
        />

        <Timeline
          data={activityDetails}
          config={REQUEST_TIMELINE_ITEM_TYPE_BASED_UI_CONFIG}
          showApproversOnStatus={APPROVAL_PENDING_STATUS}
        />
      </div> */}
          <CardsModuleRequestSection />
        </div>
      ) : null}
      {isFetching ? "Loading..." : null}
      {isError && !isFetching ? "Error" : null}
    </>
  );
}

OverviewTab.propTypes = {
  formSchema: PropTypes.array,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
};

// Processing and failed comes in Payment page slider, else in Paid.
// Payment history tracks selected rows and paid thing
// http://volodeptsg.lvh.me:3000/reimbursement/payments SLIDERS_SEARCH_PARAMS.reimbursements.paymentHistoryId
// Claim module page

// page: 1
// limit: 20
// user: 216
// type: paid
// status[]: paid
// status[]: settled_via_card
