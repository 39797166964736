import React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { changePassword } from "@/store/reducers/user";

import ChangePasswordForm from "@/components/Auth/ChangePasswordForm";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

function ResetPassword() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.security.resetPassword);
    setSearchParams(searchParams);
  };
  const handleChangePassword = (passwords) => {
    dispatch(
      changePassword({ params: { ...passwords }, onSuccess: handleClose })
    );
  };
  return (
    <div>
      <div className="pb-6 slider-content-core">
        <ChangePasswordForm
          title="Change password"
          btnLabel="Reset password"
          onSubmit={handleChangePassword}
          showCurrentPassword
        />
      </div>
    </div>
  );
}

export default ResetPassword;
