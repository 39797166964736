import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  exportLedger,
  fetchLedgerDetails,
  fetchLedgers,
  resetLedgers,
} from "@/store/reducers/ledgers";

import {
  accountingIntegrationSoftwareSelector,
  defaultCurrencySelector,
  primaryCardProviderSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  hasMoreLedgersSelector,
  isFetchingLedgerDetailsSelector,
  isLedgersFetchingSelectors,
  ledgerDetailsSelector,
  ledgersCreditFilterSelector,
  ledgersCurrencyFilterSelector,
  ledgersListSelector,
  ledgersPayrollFilterSelector,
} from "@/store/selectors/ledgers";
import { enablePayrollSelector } from "@/store/selectors/payments";

import { availableModulesSelector } from "@/store/selectors/user";
import Filters from "@/components/core/Filters";

import CustomizeColumns from "@/components/Accounting/Ledger/CustomizeColumns";
import { formatDataForTable } from "@/components/Accounting/Ledger/LedgerPageHelper/helper";
import LedgerTable from "@/components/Accounting/Ledger/LedgerTable";
import { convertFilters } from "@/utils/filters";
import {
  LEDGER_PAGE_TYPE,
  LEDGER_TABLE_HEADERS,
  LEDGER_TYPE_FILTERS,
} from "@/utils/constants/ledger";
import { AVAILABLE_FILTER_KEYS, FILTER_TYPES } from "@/utils/constants/filters";
import { getObjectKeysWithTrueValues } from "@/utils/common";

import { CARD_PROVIDER } from "@/constants/Cards";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

export default function LedgerPageHelper({ type, tab }) {
  const dispatch = useDispatch();
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const payrollEnabled = useSelector(enablePayrollSelector);
  const currentPageCredit = type === LEDGER_PAGE_TYPE.credit;
  const currentPagePayroll = type === LEDGER_PAGE_TYPE.payroll;

  // Ledgers
  const ledgers = useSelector(ledgersListSelector);
  const isFetchingLedgers = useSelector(isLedgersFetchingSelectors);
  const hasMore = useSelector(hasMoreLedgersSelector);

  // Currency
  const defaultCurrency = useSelector(defaultCurrencySelector);

  // Ledger Details
  const { openingAmount, closingAmount, unsyncedAmount } = useSelector(
    ledgerDetailsSelector
  );
  const isFetchingLedgerDetails = useSelector(isFetchingLedgerDetailsSelector);
  const primaryCardProvider = useSelector(primaryCardProviderSelector);
  const slug = primaryCardProvider?.slug;

  // Filters
  const filterSelector = useSelector(
    currentPageCredit
      ? ledgersCreditFilterSelector
      : currentPagePayroll
        ? ledgersPayrollFilterSelector
        : ledgersCurrencyFilterSelector
  );
  const availableModule = useSelector(availableModulesSelector);
  const filters = useMemo(
    () =>
      filterSelector.map((filter) => {
        if (
          filter.type === FILTER_TYPES.list &&
          filter.props.filterKey === AVAILABLE_FILTER_KEYS.ledgerType
        ) {
          const options = [
            ...filter.props.options,
            ...(payrollEnabled
              ? [
                  {
                    label: "filters.ledgerType.payrollFundAllocation",
                    value: LEDGER_TYPE_FILTERS.payrollFundAllocation,
                  },
                ]
              : []),
          ]?.filter(
            (option) =>
              !option.modules ||
              availableModule?.some((module) =>
                option?.modules?.includes(module)
              )
          );

          const _options =
            slug === CARD_PROVIDER.UOB
              ? options?.filter(
                  (option) =>
                    ![
                      "filters.ledgerType.invoicePayment",
                      "filters.ledgerType.cashbacks",
                    ].includes(option?.label)
                )
              : options;

          return {
            ...filter,
            props: {
              ...filter.props,
              options: _options,
            },
          };
        }

        return filter;
      }),
    [
      JSON.stringify(availableModule),
      JSON.stringify(filterSelector),
      payrollEnabled,
    ]
  );

  const appliedFilters = useSelector(appliedFilterSelector);

  const isDateFilterApplied = () => {
    return Object.prototype.hasOwnProperty.call(
      appliedFilters,
      AVAILABLE_FILTER_KEYS.dateRange
    );
  };

  // Creating headers
  const headers = [
    {
      id: LEDGER_TABLE_HEADERS.ledgerDate,
      label: "accounting.ledger.tableHeader.date",
      classes: "text-left",
      colWidth: 200,
      disableCustomise: true,
    },
    {
      id: LEDGER_TABLE_HEADERS.ledgerType,
      label: "accounting.ledger.tableHeader.ledgerType",
      classes: "text-left capitalize",
      colWidth: 300,
      disableCustomise: true,
    },
    {
      id: LEDGER_TABLE_HEADERS.amount,
      label: "accounting.ledger.tableHeader.amount",
      classes: "text-right",
      colWidth: 200,
      disableCustomise: true,
    },
    {
      id: LEDGER_TABLE_HEADERS.type,
      label: "accounting.ledger.tableHeader.type",
      classes: "text-left",
      colWidth: 200,
    },
    {
      id: LEDGER_TABLE_HEADERS.balance,
      label: `accounting.ledger.tableHeader.${
        currentPageCredit
          ? LEDGER_TABLE_HEADERS.outstanding
          : LEDGER_TABLE_HEADERS.balance
      }`,
      classes: "text-right",
      colWidth: 250,
    },
    {
      id: LEDGER_TABLE_HEADERS.description,
      label: "accounting.ledger.tableHeader.description",
      classes: "text-left",
      colWidth: 300,
    },
  ];

  // Creating object of header ids to map visible headers
  const [headerIds, setHeaderIds] = useState(() => {
    const res = {};
    headers.forEach((header) => {
      res[header.id] = true;
    });
    return res;
  });

  // Updating header ids
  const applyChange = (ids) => {
    setHeaderIds(ids);
  };

  // Formatting data for table
  const formattedLedgers = useMemo(() => {
    return ledgers.map((ledger) => formatDataForTable(ledger));
  }, [ledgers]);

  // Infinite Scroll
  const onReset = () => {
    dispatch(resetLedgers());
  };

  const loadMore = () => {
    if (tab?.accountId)
      dispatch(
        fetchLedgers({
          page: pageNum,
          limit: PAGINATION_PER_REQUEST_LIMIT,
          account_id: tab?.accountId,
          ...convertFilters(appliedFilters),
        })
      );
  };

  // Ledger details
  useEffect(() => {
    if (tab?.accountId)
      dispatch(
        fetchLedgerDetails({
          account_id: tab?.accountId,
          ...convertFilters(appliedFilters),
        })
      );
  }, [appliedFilters, tab?.accountId]);

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: { ...appliedFilters, account_id: tab?.accountId },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  const handleExport = async ({ allColumns, filtersApplied }) => {
    const payload = {};
    if (!allColumns)
      payload.visible_columns = [...getObjectKeysWithTrueValues(headerIds)];

    dispatch(exportLedger(payload));
  };

  const getCurrency = () => {
    return type !== LEDGER_PAGE_TYPE.credit
      ? type.toUpperCase()
      : defaultCurrency;
  };

  // Setting props for table
  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    numberOfStickyColsRight: 0,
    colWidths: headers.map((header) => header.colWidth),
    emptyDataIconSrc: "emptyScreen.svg",
  };

  return (
    <>
      <div className="flex items-start justify-between my-1">
        <div className="flex flex-col">
          <Filters filters={filters} />
        </div>
        <div className="flex items-center gap-4">
          <CustomizeColumns
            headers={headers}
            headerIds={headerIds}
            applyChange={applyChange}
          />
        </div>
      </div>

      <LedgerTable
        type={type}
        tableArgs={tableArgs}
        headers={headers}
        headerIds={headerIds}
        ledgers={formattedLedgers}
        isFetching={isFetchingLedgers}
        hasMore={hasMore}
        handleRefChange={handleRefChange}
      />
    </>
  );
}

LedgerPageHelper.propTypes = {
  type: PropTypes.string,
  tab: PropTypes.object,
};
