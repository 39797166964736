import PropTypes from "prop-types";
import React from "react";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

import { CARDS_TABLE_HEADER_KEYS } from "@/constants/Cards";
import { USER_WALLET_TABLE_HEADER_KEYS } from "@/constants/qrPayments";

import CardCellLoader from "./CardCellLoader";
import CardHolderCellLoader from "./CardHolderCellLoader";
import NameAndDescriptionCommonLoader from "./NameAndDescriptionCommonLoader";

function CardTableLoader({ header, loaderCount = 10 }) {
  const getComponent = (cellId) => {
    switch (cellId) {
      case CARDS_TABLE_HEADER_KEYS.CARDS:
        return <CardCellLoader />;
      case CARDS_TABLE_HEADER_KEYS.CARD_HOLDER:
      case USER_WALLET_TABLE_HEADER_KEYS.WALLET_HOLDER:
        return <CardHolderCellLoader />;
      case CARDS_TABLE_HEADER_KEYS.LIMIT:
      case CARDS_TABLE_HEADER_KEYS.SPENT:
      case CARDS_TABLE_HEADER_KEYS.LINKED_TO:
        return <NameAndDescriptionCommonLoader />;
      case CARDS_TABLE_HEADER_KEYS.CREATED_ON:
      case USER_WALLET_TABLE_HEADER_KEYS.ACTIVATION_DATE:
      case CARDS_TABLE_HEADER_KEYS.AVAILABLE:
      case CARDS_TABLE_HEADER_KEYS.DEPARTMENT:
      case CARDS_TABLE_HEADER_KEYS.LOCATION:
        return <LoaderSkeleton size={[20, 80]} />;

      default:
        break;
    }
  };
  return (
    <>
      {[...Array(loaderCount)].map((value, index) => (
        <tr key={index}>
          {header.map((headercell, id) => (
            <td key={`${id}-${index}`}>{getComponent(headercell?.id)}</td>
          ))}
        </tr>
      ))}
    </>
  );
}

export default CardTableLoader;
CardTableLoader.propTypes = {
  header: PropTypes.object,
  loaderCount: PropTypes.number,
};
