import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";

import { fetchMerchantDetails, updateMerchant } from "@/store/reducers/cards";
import {
  getTopSpenders,
  getTransactions,
  resetTransactions,
} from "@/store/reducers/client_analytics";

// reducers
import { selectedMerchantDetailsSelector } from "@/store/selectors/cards";
import {
  isFetchingTopSpendersSelector,
  isFetchingTransactionsSelector,
  topSpendersListSelector,
  transactionsListHasMoreSelector,
  transactionsSelector,
} from "@/store/selectors/client_analytics";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Switch from "@/components/core/Switch";
import Text from "@/components/core/Text";

import SpendAnalyticsSnapshot from "@/components/ClientAnalytics/SpendAnalyticsSnapshot";
import SpendAnalyticsTable from "@/components/ClientAnalytics/SpendAnalyticsTable";
import TopSpenderList from "@/components/Company/Slider/TopSpenderList";

import { CHECKBOX_CARD_SETTING } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT } from "@/constants/pagination";

function MerchantSlider() {
  const [searchParams, setSearchParams] = useSearchParams();
  const merchantIdSearchParam = searchParams.get(
    SLIDERS_SEARCH_PARAMS.expenses.merchantId
  );
  const dispatch = useDispatch();
  const merchantDetails = useSelector(selectedMerchantDetailsSelector);
  const merchantId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.expenses.merchantId
  );
  const [excludeReciepts, setExcludeReciepts] = useState(
    merchantDetails?.submissionPolicyRequired
  );

  const spendAnalyticsTableTabs = [
    {
      name: "company.department.tabTitles.recentExpenses",
      key: 1,
      listSelector: transactionsSelector,
      handleClick: (pageNum) => {
        dispatch(
          getTransactions({
            merchant_id: merchantIdSearchParam,
            sort_by_value: "transaction_date",
            limit: 5,
            page: pageNum,
          })
        );
      },
      inProgress: isFetchingTransactionsSelector,
      hasMoreSelector: transactionsListHasMoreSelector,
      resetTableData: () => {
        dispatch(resetTransactions());
      },
    },
    {
      name: "company.department.tabTitles.largestExpense",
      key: 2,
      listSelector: transactionsSelector,
      handleClick: (pageNum) => {
        dispatch(
          getTransactions({
            merchant_id: merchantIdSearchParam,
            sort_by_value: "transaction_amount",
            limit: 5,
            page: pageNum,
          })
        );
      },
      hasMoreSelector: transactionsListHasMoreSelector,
      inProgress: isFetchingTransactionsSelector,
      resetTableData: () => {
        dispatch(resetTransactions());
      },
    },
    {
      name: "company.department.tabTitles.topSpenders",
      key: 3,
      listSelector: topSpendersListSelector,
      customTableComponent: TopSpenderList,
      inProgress: isFetchingTopSpendersSelector,
      hasMoreSelector: isFetchingTopSpendersSelector,
      handleClick: (pageNum) => {
        dispatch(
          getTopSpenders({
            limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
            page: pageNum,
            merchant_id: merchantId,
          })
        );
      },
    },
  ];

  const Analytics = useMemo(() => {
    return (
      <>
        <SpendAnalyticsSnapshot
          params={{
            spend_type: CHECKBOX_CARD_SETTING.MERCHANT,
            merchant_id: merchantId,
          }}
        />

        <SpendAnalyticsTable
          tabs={spendAnalyticsTableTabs}
          outerContainerClasses="mt-7"
        />
      </>
    );
  }, [merchantId]);

  useEffect(() => {
    dispatch(fetchMerchantDetails(merchantId));
  }, []);

  const allState = useLoadingErrorInjector({
    apiKey: "Cards:getMerchantDetails",
    showLoader: true,
    error: {
      header: "",
    },
  });

  return (
    <div className="slider-content-core" ref={allState?.attach}>
      <ProfileWidget
        textClasses="text-2xl font-extrabold"
        avatarSize="lg"
        name={merchantDetails?.name}
        img={merchantDetails?.logoUrl}
      />
      <div className="grid grid-cols-2 gap-5 mt-8">
        {/* CATEGORY */}
        <div className="flex flex-col justify-center p-3 card-wrapper">
          <div className="flex items-center gap-2">
            <Text
              color="neutral-500"
              classes="font-semibold text-sm"
              translationKey="expenses.slider.detailsCard.category"
            />
            <Icon name="Flag" className="w-3 h-3 text-neutral-500 " />
          </div>
          <Text
            color="neutrtal-800"
            classes="font-semibold text-base"
            translationKey={merchantDetails?.category || "-"}
            // categories?.find((_) => _?.id === expense?.categoryId)?.name
          />
        </div>
        {/* MERCHANT */}
        <div className="card-wrapper flex flex-col h-14.5  justify-center p-3">
          <div className="flex items-center gap-2">
            <Text
              color="neutral-500"
              classes="font-semibold text-sm"
              translationKey="expenses.slider.detailsCard.merchant"
            />
            <Icon name="Flag" className="w-3 h-3 text-neutral-500 " />
          </div>
          <Text
            classes="font-semibold text-base"
            translationKey={merchantDetails?.name}
          />
        </div>
      </div>
      <div className="my-10">{Analytics}</div>
      <div className="flex flex-col gap-1 my-8 mb-15">
        <Text
          translationKey="expenses.slider.detailsCard.submissionPolicyRequirement"
          classes="py-2 text-lg font-medium"
        />
        <div className="flex items-center gap-2">
          <Switch
            value={!merchantDetails?.submissionPolicyRequired}
            handleChange={(value) => {
              setExcludeReciepts((prev) => !prev);
              dispatch(
                updateMerchant({
                  payload: {
                    id: merchantId,
                    submission_policy_required: value,
                  },
                })
              );
            }}
          />
          <Text
            translationKey="expenses.slider.detailsCard.excludeMemoSwitchText"
            classes="font-medium"
          />
        </div>
        <Text
          translationKey="expenses.slider.detailsCard.changeSubmissionRequirements"
          classes="text-neutral-500"
        />
      </div>
    </div>
  );
}

export default MerchantSlider;
