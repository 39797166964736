import PropTypes from "prop-types";

import Checkbox from "@/components/core/Checkbox";
import {
  __TOGGLE_MODE_GROUP_ENTRY,
  __TOGGLE_MODE_SINGLE_ENTRY,
} from "@/components/core/CheckboxDropdown/utils";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

/**
 * A row of in the checkbox dropdown
 *
 * It has some variations
 * 1. Just checkbox and label
 * 2. Chekbox and label with left and/or right arrow
 */
export default function CheckboxDropdownRow({
  id = "",
  checked = "",
  disabled = false,
  groupKey = "",
  groupSelfKey = "",

  label = "",
  subLabel = "",
  icon = "",
  subLabelClasses = "", // to show '12 selected' in green color, for example.

  showRightArrow = false,

  toggleCheckboxHandler = () => {},
  onRightArrowClick = () => {},
  labelJsx,
  supportExplicitControls,
}) {
  const checkboxHandler = (value) => {
    const isGroupEntry = showRightArrow;
    if (isGroupEntry) {
      toggleCheckboxHandler({
        groupKey: groupSelfKey,
        value,
        toggleMode: __TOGGLE_MODE_GROUP_ENTRY,
      });
    } else {
      // simple entry
      toggleCheckboxHandler({
        id,
        value,
        toggleMode: __TOGGLE_MODE_SINGLE_ENTRY,
      });
    }
  };
  return (
    <div
      aria-label="checkbox-dropdown-row"
      className="flex items-center gap-6 py-2 pl-3 pr-6 cursor-pointer hover:bg-neutral-50"
      onClick={(value) => {
        if (!disabled) checkboxHandler(value);
      }}
    >
      <Checkbox
        checked={checked}
        disabled={disabled}
        onClickHandler={(value) => checkboxHandler(value)}
      />

      <div aria-label="label-sublabel-and-icon" className="grow">
        {/* label and icon */}
        <div className="flex items-center gap-1">
          {/* don't render if it's absent */}
          {/* also, label could be a string or JSX */}
          {labelJsx ? (
            labelJsx
          ) : label ? (
            typeof label === typeof "" ? (
              <Text
                translationKey={label}
                classes="text-neutral-500 font-medium text-base hover:text-neutral-800"
              />
            ) : (
              label
            )
          ) : null}

          {icon ? (
            typeof icon === typeof "" ? (
              <Icon name={icon} className="w-4 h-4" />
            ) : (
              icon
            )
          ) : null}
        </div>
        {/* sublabel */}
        <div
          className={subLabelClasses || "text-neutral-400 font-medium text-xs"}
        >
          {/* don't render if it's absent */}
          {/* also, label could be a string or JSX */}
          {subLabel ? (
            typeof subLabel === typeof "" ? (
              <Text translationKey={subLabel} />
            ) : (
              subLabel
            )
          ) : null}
        </div>
      </div>

      {showRightArrow && !supportExplicitControls ? (
        <div
          className="p-1 rounded-sm cursor-pointer text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900"
          onClick={onRightArrowClick}
        >
          <Icon name="RightArrow" className="w-5 h-5" />
        </div>
      ) : null}
    </div>
  );
}

CheckboxDropdownRow.propTypes = {
  id: PropTypes.any,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  groupKey: PropTypes.string,
  groupSelfKey: PropTypes.string,

  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  subLabelClasses: PropTypes.string,

  showRightArrow: PropTypes.bool,

  toggleCheckboxHandler: PropTypes.func,
  onRightArrowClick: PropTypes.func,
  labelJsx: PropTypes.node,
  supportExplicitControls: PropTypes.bool,
};
