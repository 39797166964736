import PropTypes from "prop-types";
import { useId } from "react";

import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import BoltIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/BoltIcon";

export default function BoltOnTheLeft({
  classes = "",
  children,
  showBolt = true,
  verticalCenter = false,
  isArtificial = false,
}) {
  const id = useId();
  const _id = `tooltips-${id.replaceAll(":", "")}`; // react tooltip trick
  return (
    <div className={`${classes} relative`}>
      {showBolt ? (
        // here, spacing is 8px less compared to design, but its the only way for bolts to be used as a reusable component
        <span
          className={`absolute top-0 bottom-0 translate-x-[calc(-100%)] pt-2.5 ${
            verticalCenter ? "grid place-items-center" : ""
          }`}
          id={_id}
        >
          <BoltIcon isArtificial={isArtificial} />

          <Tooltip id={_id} direction="top-start" offset={4} maxWidth="200">
            <Text
              translationKey={
                isArtificial
                  ? "billPay.bill.invoiceInbox.createBill.sections.artificialLineItemTooltip"
                  : "billPay.bill.invoiceInbox.createBill.sections.ocrSuccess"
              }
              classes="font-semibold"
            />
          </Tooltip>
        </span>
      ) : null}
      {children}
    </div>
  );
}

BoltOnTheLeft.propTypes = {
  classes: PropTypes.string,
  children: PropTypes.any,
  showBolt: PropTypes.any,
  verticalCenter: PropTypes.bool,
  isArtificial: PropTypes.bool,
};
