// react imports
import PropTypes from "prop-types";
import React, { memo } from "react";
import { useSelector } from "react-redux";

// custom hooks

// selectors
import { categoriesListSelector } from "@/store/selectors/categories";
import { selectedExpenseSelector } from "@/store/selectors/expense";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
// core components
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";
import { useForm } from "@/utils/useForm";

function CategoryMerchantReportDetailsModal({ showModal, setShowModal }) {
  const volopayCategoriesOptions = useSelector(categoriesListSelector);
  const expense = useSelector(selectedExpenseSelector);
  const merchant = expense?.merchant?.name;
  const categoryId = expense?.categoryId;

  const initialFormValue = {
    merchant: {
      value: merchant || undefined,
    },
    volopayCategory: {
      value: getValueForSelect("volopayCategory") || undefined,
    },
  };

  const formSchema = [
    {
      name: "merchant",
      type: "text",
      placeholder: "expenses.modals.reportMerchantCategory.fields.merchant",
      label: "expenses.modals.reportMerchantCategory.fields.merchant",
    },
    {
      name: "volopayCategory",
      type: "select",
      placeholder: "expenses.modals.reportMerchantCategory.fields.category",
      label: "expenses.modals.reportMerchantCategory.fields.category",
      options: volopayCategoriesOptions,
      optionsDisplayKey: "name",
      insideForm: true,
      menuPosition: "absolute",
    },
  ];

  const { handleChange, values, errors, handleSubmit } = useForm(
    initialFormValue,
    () => {},
    {
      isFetchingInitialValue: false,
    }
  );

  const onCloseModal = () => {
    setShowModal(false);
  };

  function getValueForSelect(fieldName) {
    switch (fieldName) {
      case "volopayCategory":
        return volopayCategoriesOptions?.find((_) => _?.id === categoryId)
          ?.name;

      default:
        break;
    }
  }

  return (
    <Modal onClose={onCloseModal} open={showModal}>
      <div>
        <Text
          translationKey="expenses.modals.reportMerchantCategory.title"
          classes="text-lg not-italic font-bold leading-8 text-neutral-800"
        />

        <form className="mt-6" onSubmit={handleSubmit}>
          {formSchema.map((field, index) => {
            const { type, name } = field;

            let FieldComponent = <></>;

            switch (type) {
              case "select":
                FieldComponent = VpSelect;
                break;

              case "text":
                FieldComponent = Input;
                break;

              default:
                break;
            }
            return (
              <div className="mb-7" key={`${index + name}`}>
                <FieldComponent
                  value={values[name]}
                  error={errors[name]}
                  onChange={handleChange}
                  handleChange={handleChange}
                  {...field}
                />
              </div>
            );
          })}

          <div className="flex items-center justify-end gap-3">
            <Button
              label="expenses.modals.reportMerchantCategory.buttons.cancel"
              classes="w-40 text-neutral-500 font-medium"
              variant="tertiary"
              onClick={onCloseModal}
            />
            <Button
              label="expenses.modals.reportMerchantCategory.buttons.submitReport"
              classes="w-40 text-white font-medium"
              variant="primary"
              btnType="submit"
              form="create_physical_card"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

CategoryMerchantReportDetailsModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default memo(CategoryMerchantReportDetailsModal);
