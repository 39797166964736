import PropsTypes from "prop-types";

import ProfileWidget from "@/components/core/ProfileWidget";
import { amountToCurrency } from "@/utils/common";

export default function TopSpenderList({ data }) {
  return (
    <div className="border text-neutral-800 border-neutral-200 rounded-lg">
      {data.map((item) => (
        <div
          key={item.name}
          className="flex items-center justify-between py-4 px-4 top-spender-row"
        >
          <ProfileWidget
            name={item.name}
            avatarSize="sm"
            textClasses="font-medium"
          />
          <span className="font-medium">{amountToCurrency(item)}</span>
        </div>
      ))}
    </div>
  );
}

TopSpenderList.propTypes = {
  data: PropsTypes.array,
};
