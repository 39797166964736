import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Text from "@/components/core/Text";

import { ROUTES } from "@/constants/routes";

export default function NonEditableSettingSliderFooter({
  linkText,
  linkRedirect,
}) {
  return (
    <div className="flex items-center py-3">
      <div className="text-sm">
        <Text
          translationKey="settings.editableOnlyInGlobalSettings.normalText"
          classes="text-neutral-600"
        />{" "}
        <Link
          to={linkRedirect ?? ROUTES.manage.settings.spendControls.absolutePath}
        >
          <Text
            translationKey={
              linkText ?? "settings.editableOnlyInGlobalSettings.linkText"
            }
            classes="simple-link-styling text-primary"
          />
        </Link>
      </div>
    </div>
  );
}

NonEditableSettingSliderFooter.propTypes = {
  linkText: PropTypes.string,
  linkRedirect: PropTypes.string,
};
