import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setCurrentGlobalAccount } from "@/store/reducers/account";
import { fetchAccountDetail, setAccountDetails } from "@/store/reducers/client";
import { setSelectedWalletAccount } from "@/store/reducers/company";

import { globalAccountsSelector } from "@/store/selectors/account";
import {
  accountDetailSelector,
  isFetchingClientSelector,
  paymentWalletListSelector,
} from "@/store/selectors/client";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import AccountDetailsSection from "@/components/Company/Overview/AccountOverview/Slider/shared/AccountDetailsSection";
import ShareDetailsSection from "@/components/Company/Overview/AccountOverview/Slider/shared/ShareDetailsSection";
import TopSection from "@/components/Company/Overview/AccountOverview/Slider/shared/TopSection";

import Image from "@/components/core/Image";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAYMENT_OPTION_TYPE } from "@/constants/company";

export default function AddFundsSliderSlider({ setOnClose }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentOptionType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.paymentOption
  );

  const selectedGlobalAccountId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.company.globalAccountId
  );

  const accountDetails = useSelector(accountDetailSelector);
  const curCurrency =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.fundAccount) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.currency);

  const paymentsWallet = useSelector(paymentWalletListSelector);

  const selectedWallet = useMemo(() => {
    return paymentsWallet?.find((a) => a.currency === curCurrency);
  }, [curCurrency, paymentsWallet]);

  const globalAccounts = useSelector(globalAccountsSelector);

  const isFetching = useSelector(isFetchingClientSelector);

  const selectedGlobalAccount = useMemo(() => {
    return globalAccounts?.find(
      (globalAcc) => globalAcc?.id === selectedGlobalAccountId
    );
  }, [selectedGlobalAccountId, globalAccounts]);

  useEffect(() => {
    if (
      selectedWallet?.currency === curCurrency &&
      !Object.keys(selectedGlobalAccount ?? {}).length
    ) {
      dispatch(
        fetchAccountDetail({ id: selectedWallet.id, type: paymentOptionType })
      );
    }
  }, [selectedWallet]);

  useEffect(() => {
    setOnClose((searchParamsArray) => {
      if (
        !searchParamsArray.includes(SLIDERS_SEARCH_PARAMS.company.paymentOption)
      ) {
        dispatch(setSelectedWalletAccount(null));
        dispatch(setCurrentGlobalAccount({}));
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(selectedGlobalAccount ?? {})?.length && !accountDetails) {
      const guidelines = [
        { text: "company.globalAccounts.guideLines.desc1", action: "pace" },
        {
          text: "company.globalAccounts.guideLines.desc2",
          textProp: {
            currency: selectedGlobalAccount.currency,
            paymentMode: selectedGlobalAccount.paymentMethods.join(", "),
          },
          action: "flag",
        },
        {
          text: "company.globalAccounts.guideLines.desc3",
          textProp: { currency: selectedGlobalAccount.currency },
          action: "exchange",
        },
        {
          text: "company.globalAccounts.guideLines.desc4",
          textProp: { currency: selectedGlobalAccount.currency },
          action: "arrows",
        },
      ];

      dispatch(setCurrentGlobalAccount(selectedGlobalAccount));
      dispatch(
        setAccountDetails({
          bankAccount: selectedGlobalAccount,
          guideLines: guidelines,
        })
      );
    }
  }, [selectedGlobalAccount, accountDetails]);

  return (selectedWallet || selectedGlobalAccount) && !isFetching ? (
    <div className="slider-content-container">
      <div className="slider-content-core pb-14">
        <TopSection paymentOptionType={paymentOptionType} />
        {paymentOptionType === PAYMENT_OPTION_TYPE.PAYNOW ? (
          <div className="flex flex-col items-center justify-center pt-10 text-neutral-500">
            <Image
              name="QRcode"
              alt="QR-code"
              className="p-3 mb-4 border rounded-lg border-neutral-200"
            />
            <Text translationKey="company.addFund.scan1" />
            <Text translationKey="company.addFund.scan2" />
          </div>
        ) : (
          <AccountDetailsSection />
        )}
        {paymentOptionType !== PAYMENT_OPTION_TYPE.PAYNOW ? (
          <ShareDetailsSection />
        ) : null}
      </div>
    </div>
  ) : (
    <div className="ml-10 gap-19 ">
      <div className="flex items-center gap-2">
        <LoaderSkeleton type="circle" size={[80, 80]} />
        <LoaderSkeleton size={[80, 500]} />
      </div>
      <div className="mt-5">
        <LoaderSkeleton size={[80, 590]} />
      </div>
    </div>
  );
}
