import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Badge from "@/components/core/Badge";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import { amountToCurrency } from "@/utils/common";

import { CARD_FREQUENCY, CARD_FREQUENCY_BE_MAPPING } from "@/constants/card";
import {
  BE_FE_BUDGET_KEY_MAPPING,
  BUDGET_PILL_CONFIG,
} from "@/constants/common";
import {
  BE_FE_FREQUENCY_MAP,
  BUDGET_FREQUENCY_LABELS,
} from "@/constants/company";

import "./styles.scss";

/**
 * Generates a component based on the input item.
 *
 * @param {any} item - The input item to generate the component from.
 * @return {JSX.Element} The generated component.
 */
function BudgetPill({
  data = [],
  keyForModules = "modules",
  budgetPillClasses = "flex flex-wrap items-center py-0.5  border-1 border-neutral-500 rounded-3xl",
  id,
  currency = "SGD",
  hideInfo = false,
  showTogether = true,
}) {
  // can't use Text component because of nested translation text require
  const { t } = useTranslation();
  const getComponent = (item) => {
    if (item?.length > 1) {
      return (
        <div
          className={` ${
            showTogether ? "bg-neutral-50" : "gap-2"
          } ${budgetPillClasses}`}
        >
          {item.map((configItem, index, arr) => {
            const FE_KEY = BE_FE_BUDGET_KEY_MAPPING[configItem];
            const config = BUDGET_PILL_CONFIG[FE_KEY];
            return (
              <Fragment key={`budget-pill-${id}-${index}`}>
                {showTogether ? (
                  <div
                    className={`max-w-max budget-pill budget-pill--${config?.variant}`}
                  >
                    <Icon name={config?.icon} className="w-3 h-3" />
                    <Text classes="text-xs" translationKey={config?.label} />
                  </div>
                ) : (
                  <Badge
                    key={`budget-pill-${id}-single`}
                    textClasses="font-medium text-xs"
                    translationKey={config?.label}
                    preIcon={config?.icon}
                    variant={config?.variant}
                    classes="px-2 rounded-md"
                  />
                )}
                {index !== arr.length - 1 && showTogether ? (
                  <div
                    key={`budget-pill--dot-${id}-${index}`}
                    className="w-[6px] h-[6px] rounded-full bg-neutral-200"
                  />
                ) : null}
              </Fragment>
            );
          })}
        </div>
      );
    }

    const FE_KEY = BE_FE_BUDGET_KEY_MAPPING[item?.[0]];
    const config = BUDGET_PILL_CONFIG[FE_KEY];

    return config ? (
      <Badge
        key={`budget-pill-${id}-single`}
        classes="px-2 mr-1 my-0.5 rounded-md"
        textClasses="font-semibold text-xs"
        translationKey={config?.label}
        preIcon={config?.icon}
        variant={config?.variant}
      />
    ) : null;
  };

  const getTitleForTooltip = (array) => {
    const items = array.map((item) =>
      t(BUDGET_PILL_CONFIG[BE_FE_BUDGET_KEY_MAPPING[item]]?.label)
    );
    if (items.length > 1) {
      const lastValue = items[items.length - 1];
      const restArray = items.slice(0, items.length - 1);
      const restString = restArray.join(", ");
      if (restArray.length === 1) return `${restString} & ${lastValue}`;
      return `${restString} ${t("Misc.and")} ${lastValue}`;
    }
    return items.toString();
  };

  return (
    <div className="flex items-center gap-1">
      {data.length ? (
        data?.map((item) => {
          return (
            <Fragment key={item?.id}>
              {getComponent(item?.[keyForModules])}
            </Fragment>
          );
        })
      ) : (
        <Badge {...BUDGET_PILL_CONFIG.DISABLED} />
      )}
      {!hideInfo ? (
        <>
          <div id={`budget-pill-${id}`} className="flex items-center">
            <Icon name="Info" className="cursor-pointer text-neutral-300" />
          </div>
          <Tooltip
            id={`budget-pill-${id}`}
            direction="right"
            backgroundColor="#ffffff"
          >
            <div className="flex flex-col gap-5 py-2">
              {data.length ? (
                data.map((dataItem) => {
                  const frequency = t(
                    BUDGET_FREQUENCY_LABELS[
                      BE_FE_FREQUENCY_MAP[dataItem?.frequency]
                    ]
                  );
                  return (
                    <div key={dataItem?.id} className="flex gap-2">
                      <div
                        className={`w-1 h-8 rounded-2xl my-auto  budget-tooltip-side-color--${
                          dataItem?.[keyForModules].length === 1
                            ? BUDGET_PILL_CONFIG[
                                BE_FE_BUDGET_KEY_MAPPING[
                                  dataItem?.[keyForModules][0]
                                ]
                              ]?.variant
                            : "disabled"
                        }`}
                      />
                      <div className="flex flex-col">
                        <div className="text-xs text-neutral-500">
                          <Text
                            translationKey="cards.listingFields.budget.tooltip"
                            translationProps={{
                              title: getTitleForTooltip(
                                dataItem?.[keyForModules]
                              ),
                            }}
                          />
                        </div>
                        <div className="flex items-center gap-2 text-sm font-semibold text-neutral-800">
                          <div>
                            {`${amountToCurrency(
                              dataItem.limit,
                              currency
                            )} ${frequency}`}
                          </div>
                          {dataItem.flexible ? (
                            <Badge
                              variant="disabled"
                              translationKey="filters.flexible"
                              classes="px-5 text-neutral-500 border-[1px] border-neutral-200"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Text translationKey="misc.noBudgetDefined" />
              )}
            </div>
          </Tooltip>
        </>
      ) : null}
    </div>
  );
}

export default BudgetPill;
BudgetPill.propTypes = {
  data: PropTypes.array,
  keyForModules: PropTypes.string,
  budgetPillClasses: PropTypes.string,
  id: PropTypes.any,
  currency: PropTypes.string,
  hideInfo: PropTypes.bool,
  showTogether: PropTypes.bool,
};
