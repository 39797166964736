import PropTypes from "prop-types";

import Avatar from "@/components/core/Avatar";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import { dateToString, validateAndConvertToISO8601 } from "@/utils/common";

export default function HistoryTab({ activities = [] }) {
  return (
    <div className="min-h-[1440px]">
      {activities.map(({ createdAt, items }) => (
        <ActivityComponent
          key={createdAt}
          createdAt={createdAt}
          items={items}
        />
      ))}
    </div>
  );
}

HistoryTab.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          user: PropTypes.shape({
            name: PropTypes.string,
            isApprover: PropTypes.bool,
          }),
          activities: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              type: PropTypes.oneOf(["activated", "approved", "edited"]),
              title: PropTypes.string,
              description: PropTypes.string,
              cardState: PropTypes.shape({
                amount: PropTypes.string,
                frequency: PropTypes.string,
                AutoLock: PropTypes.string,
                transactionAmountLimit: PropTypes.string,
                canSpendOn: PropTypes.string,
                expensePolicy: PropTypes.string,
                reason: PropTypes.string,
              }),
            })
          ),
        })
      ),
    })
  ),
};

const USER_ACTION_TO_LABEL_MAP = {
  activated: "cards.cardDetails.overview.history.userActions.activated",
  edited: "cards.cardDetails.overview.history.userActions.requestEdit",
  approved: "cards.cardDetails.overview.history.userActions.approved",
};

function ActivityComponent({ createdAt, items }) {
  return (
    <div>
      <div className="h-10 px-4 py-3 mb-6 text-base font-semibold border-b text-neutral-800 border-neutral-200">
        {dateToString(createdAt)}
      </div>
      {items.map(({ user, activities }) => (
        <div key={JSON.stringify(activities)}>
          <div className="border-l border-neutral-200" />
          <UserHead time={createdAt} name={user.name} />
          <div className="ml-[calc(theme(spacing.10)+theme(spacing.3))] mt-3">
            {activities.map(({ type, id, cardState }) => (
              <div key={id}>
                <div
                  className={`flex items-center gap-[11px] mb-2 text-base ${
                    user.isApprover ? "text-success-600" : "text-neutral-500"
                  }`}
                >
                  <Icon name={user.isApprover ? "ThumbsUp" : "CreditCard"} />
                  <Text translationKey={USER_ACTION_TO_LABEL_MAP[type]} />
                </div>
                {!user.isApprover && cardState && (
                  <div className="grid grid-cols-2 p-4 bg-neutral-50 gap-x-4 gap-y-2">
                    {[
                      { header: "amount", value: cardState.amount },
                      { header: "frequency", value: cardState.frequency },
                      { header: "AutoLock", value: cardState.AutoLock },
                      {
                        header: "transactionAmountLimit",
                        value: cardState.transactionAmountLimit,
                      },
                      { header: "canSpendOn", value: cardState.canSpendOn },
                      {
                        header: "expensePolicy",
                        value: cardState.expensePolicy,
                      },
                      { header: "reason", value: cardState.reason },
                    ].map(({ header, value }, i) => (
                      <div key={`${header}${i}`}>
                        <Text
                          translationKey={`cards.cardDetails.overview.history.requestDataLabels.${header}`}
                          classes="text-sm text-neutral-500 font-semibold h-6 flex items-center"
                        />
                        <span className="text-base font-semibold text-neutral-800">
                          {value}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

ActivityComponent.propTypes = {
  createdAt: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
};

function UserHead({
  time = new Date().toISOString(),
  avatarUrl = "",
  name = "",
}) {
  return (
    <ProfileWidget
      className="text-sm font-semibold"
      iconComponent={
        avatarUrl ? null : (
          <div
            className="flex items-center justify-center w-10 h-10"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Avatar name={name} />
          </div>
        )
      }
      name=""
    >
      <span className="font-semibold text-neutral-500">
        {new Date(validateAndConvertToISO8601(time)).toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </span>
      <span className="text-base font-semibold text-neutral-800">{name}</span>
    </ProfileWidget>
  );
}

UserHead.propTypes = {
  name: PropTypes.string,
  time: PropTypes.string,
  avatarUrl: PropTypes.string,
};
