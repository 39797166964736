import { Route, Routes } from "react-router-dom";

import GeneralSettings from "@/components/common/BillPayAndPayroll/Settings/General";
import SpendControlsSettings from "@/components/common/BillPayAndPayroll/Settings/SpendControls";

import BillPaySettings from "@/pages/bill-pay/settings";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { ROUTES } from "@/constants/routes";

export default function BillPaymentsSettingsRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={<BillPaySettings />}
        // has the tab UI
      >
        {/* tab page contents */}

        <Route
          path={ROUTES.billpay.settings.general.pathName}
          element={<GeneralSettings context={BILL_PAYROLL_CONTEXT.BILLPAY} />}
        />

        <Route
          path={ROUTES.billpay.settings.spendControls.pathName}
          element={
            <SpendControlsSettings context={BILL_PAYROLL_CONTEXT.BILLPAY} />
          }
        />
      </Route>
    </Routes>
  );
}
