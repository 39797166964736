import { createSelector } from "@reduxjs/toolkit";

import { PAYMENT_ACCOUNT_TYPE_ALIASES } from "@/utils/constants/client";
import { AVAILABLE_FILTERS } from "@/utils/constants/filters";
import {
  ACCOUNT_WALLET_TYPES,
  ALL_ACCOUNTS_TYPES_LIST,
  LEDGER_PAGE_TYPE,
} from "@/utils/constants/ledger";

import { ROUTES } from "@/constants/routes";

export const selectLedgersStore = (store) => store.ledgers;

export const selectClientStore = (store) => store.client;

export const accountWalletSelector = createSelector(
  selectClientStore,
  (client) => client.wallets.list
);

export const ledgersSelector = createSelector(
  selectLedgersStore,
  (ledgers) => ledgers?.ledgers
);
export const totalLedgersSelector = createSelector(
  ledgersSelector,
  (ledgers) => ledgers?.total
);

export const isFetchingSelectedLedgerselector = createSelector(
  selectLedgersStore,
  (ledgers) => ledgers.isFetchingSlected
);
export const selectedLedgerSelector = createSelector(
  selectLedgersStore,
  (ledger) => ledger.selectedLedger
);
export const unSyncNumberSelector = createSelector(
  selectLedgersStore,
  (ledgers) => ledgers?.unSyncStatusNumber
);
export const ledgersListSelector = createSelector(
  ledgersSelector,
  (ledgers) => ledgers?.list
);
export const isLedgersFetchingSelectors = createSelector(
  ledgersSelector,
  (ledgers) => ledgers?.isFetching
);
export const hasMoreLedgersSelector = createSelector(
  ledgersSelector,
  (ledgers) => ledgers.hasMore
);

export const isFetchingLedgerDetailsSelector = createSelector(
  selectLedgersStore,
  (ledgers) => ledgers.isFetchingLedgerDetails
);
export const ledgerDetailsSelector = createSelector(
  selectLedgersStore,
  (ledgers) => {
    return {
      closingAmount: ledgers.closingAmount,
      openingAmount: ledgers.openingAmount,
      unsyncedAmount: ledgers.unsyncedAmount,
    };
  }
);

export const paymentAccountIdSelector = createSelector(
  accountWalletSelector,
  (wallets) => {
    const accountIds = [];
    if (wallets) {
      wallets.map((wallet) => {
        if (ALL_ACCOUNTS_TYPES_LIST.includes(wallet.accountType))
          accountIds.push(wallet.id);
      });
    }
    return accountIds;
  }
);

export const ledgerTabsSelector = createSelector(
  accountWalletSelector,
  (wallets) => {
    const tabs = [];

    if (wallets) {
      wallets.forEach((wallet) => {
        switch (wallet.accountType) {
          case ACCOUNT_WALLET_TYPES.paymentAccount:
            if (wallet?.aliasName === PAYMENT_ACCOUNT_TYPE_ALIASES.PAYMENTS) {
              tabs.push({
                name: PAYMENT_ACCOUNT_TYPE_ALIASES.PAYMENTS,
                count: null,
                path: ROUTES.accounting.ledger.payments.absolutePath,
                key: wallet.id,
                accountId: wallet.id,
              });
            } else {
              tabs.push({
                name: wallet.currency.toUpperCase(),
                count: null,
                path: `${
                  ROUTES.accounting.ledger.absolutePath
                }/${wallet.currency.toLowerCase()}`,
                key: wallet.id,
                accountId: wallet.id,
              });
            }
            break;

          case ACCOUNT_WALLET_TYPES.creditAccount:
            if (wallet.status !== "disabled") {
              tabs.push({
                name: "accounting.ledger.creditPage.title",
                count: null,
                path: ROUTES.accounting.ledger.credit.absolutePath,
                key: wallet.id,
                accountId: wallet.id,
              });
            }
            break;

          case ACCOUNT_WALLET_TYPES.payrollAccount:
            tabs.push({
              name: "accounting.ledger.payroll.title",
              count: null,
              path: ROUTES.accounting.ledger.payroll.absolutePath,
              key: wallet.id,
              accountId: wallet.id,
            });
            break;

          case ACCOUNT_WALLET_TYPES.cardsAccount:
            tabs.push({
              name: PAYMENT_ACCOUNT_TYPE_ALIASES.CARDS,
              count: null,
              path: ROUTES.accounting.ledger.cards.absolutePath,
              key: wallet.id,
              accountId: wallet.id,
            });
            break;

          case ACCOUNT_WALLET_TYPES.internationalAccount:
            tabs.push({
              name: wallet?.aliasName,
              count: null,
              path: ROUTES.accounting.ledger.internationalCards.absolutePath,
              key: wallet.id,
              accountId: wallet.id,
            });
            break;

          default:
            break;
        }
      });
    }

    return tabs;
  }
);

export const totalUnsyncedLedgerSelector = createSelector(
  selectLedgersStore,
  (ledgers) => ledgers?.totalUnsynced
);
export const totalUnsyncedLedgerListSelector = createSelector(
  totalUnsyncedLedgerSelector,
  (ledgers) => ledgers?.list
);
export const totalUnsyncedLedgerTotalSelector = createSelector(
  totalUnsyncedLedgerSelector,
  (ledgers) => ledgers?.total
);
export const totalUnsyncedLedgerIsFetchingSelector = createSelector(
  totalUnsyncedLedgerSelector,
  (ledgers) => ledgers?.isFetching
);
export const unsyncedSliderSelector = createSelector(
  selectLedgersStore,
  (ledgers) => ledgers.unsyncedSlider
);
export const unsyncedSliderpageSelector = createSelector(
  unsyncedSliderSelector,
  (unsyncSlider) => unsyncSlider.page
);
export const unsyncedSliderPageTypeSelector = createSelector(
  unsyncedSliderSelector,
  (unsyncSlider) => unsyncSlider.pageType
);
export const unsyncedSliderNextIdSelector = createSelector(
  unsyncedSliderSelector,
  (unsyncSlider) => unsyncSlider.selectediId
);

export const ledgersCurrencyFilterSelector = createSelector(
  selectLedgersStore,
  (ledgers) => ledgers.filterKeys.currency.map((key) => AVAILABLE_FILTERS[key])
);
export const ledgersAllFilterSelector = createSelector(
  selectLedgersStore,
  (ledgers) => ledgers.filterKeys.all.map((key) => AVAILABLE_FILTERS[key])
);

export const ledgersCreditFilterSelector = createSelector(
  selectLedgersStore,
  (ledgers) =>
    ledgers.filterKeys.creditLedgerType.map((key) => AVAILABLE_FILTERS[key])
);

export const ledgersPayrollFilterSelector = createSelector(
  selectLedgersStore,
  (ledgers) =>
    ledgers.filterKeys.payrollLedgerType.map((key) => AVAILABLE_FILTERS[key])
);
