import PropTypes from "prop-types";
import React from "react";

import Icon from "../Icon";
import Text from "../Text";

export default function StepWithExternalLink({
  label,
  iconName,
  stepName,
  linkText,
  hasBorderBottom,
  done,
  iconClasses,
  stepNameClasses,
  stepStatusName,
  description,
  linkUrl,
}) {
  return (
    <div
      className={`flex flex-row items-center gap-3 ${
        hasBorderBottom ? "border-neutral-100 border-b-2 pb-4" : ""
      }`}
    >
      <Icon
        name={done ? "CheckCircle" : iconName}
        className={iconClasses ? iconClasses : done && "text-success-600"}
      />

      <div className="flex flex-col">
        {label ? (
          <Text
            translationKey={label}
            classes="text-base not-italic font-semibold leading-6"
          />
        ) : null}

        {description ? (
          <Text
            translationKey={description}
            classes="text-sm font-semibold text-neutral-500"
          />
        ) : null}

        {stepName ? (
          <Text
            translationKey={stepName}
            classes="text-neutral-500 font-semibold text-sm"
          />
        ) : null}

        {stepStatusName ? (
          <Text
            translationKey={stepStatusName}
            classes={`text-sm not-italic font-semibold ${stepNameClasses}`}
          />
        ) : null}

        {done ? (
          <Text translationKey="misc.done" classes="text-success-600" />
        ) : null}

        {linkText ? (
          <div className="flex flex-row gap-1">
            <Text translationKey={linkText} />
            <Icon name="ArrowForward" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

StepWithExternalLink.propTypes = {
  label: PropTypes.string,
  iconName: PropTypes.string,
  stepName: PropTypes.string,
  linkText: PropTypes.string,
  hasBorderBottom: PropTypes.bool,
  done: PropTypes.bool,
  iconClasses: PropTypes.string,
  stepNameClasses: PropTypes.string,
  stepStatusName: PropTypes.string,
  description: PropTypes.string,
  linkUrl: PropTypes.string,
};
