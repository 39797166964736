import { createSelector } from "@reduxjs/toolkit";

import { ROLES_TYPE } from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/SettingsType";

import { CARD_TYPE } from "@/constants/Cards";

export const selectUserStore = (store) => store.user;

export const isLoggedInSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.isLoggedIn
);

export const isLoadingSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.isLoading
);

export const userSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.currentUser
);
export const mfaDataSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.mfaData
);

export const userRolesListSelector = createSelector(
  selectUserStore,
  (userRoles) => userRoles.userRolesList
);
export const userRolesListIsFetchingSelector = createSelector(
  userRolesListSelector,
  (userRoles) => userRoles.isFetching
);

export const userAdminRolesSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.userAdminRoleList
);

export const userAdminRoleListSelector = createSelector(
  userAdminRolesSelector,
  (userRoles) => userRoles.list
);

export const userKycStateSelector = createSelector(
  userSelector,
  (currentUser) => currentUser?.kycState
);

export const userIdSelector = createSelector(
  userSelector,
  (currentUser) => currentUser?.id
);

export const userPermissionSelector = createSelector(
  userSelector,
  (currentUser) => currentUser?.permissions
);

export const selectRoles = createSelector(
  userSelector,
  (user) => user?.roles || []
);
export const otpSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.otp
);

export const isAdminSelector = createSelector(selectRoles, (roles) =>
  roles?.includes("Admin")
);

export const isAccountantSelector = createSelector(selectRoles, (roles) =>
  roles?.includes("accountant")
);

export const isExternalAccountantSelector = createSelector(
  selectRoles,
  (roles) => roles?.includes(ROLES_TYPE.EXTERNAL_ACCOUNTANT)
);

export const ownerOfBudgetsSelector = createSelector(
  userSelector,
  (user) => user?.ownerOfBudgets
);

export const isOwnerOfAnyBudgetSelector = createSelector(
  ownerOfBudgetsSelector,
  (budgets) => budgets?.length
);

export const cardsEnabledSelector = createSelector(
  userSelector,
  (user) => user?.cardsEnabled
);

export const paymentsEnabledSelector = createSelector(
  userSelector,
  (user) => user?.paymentsEnabled
);

export const memberOfBudgetsSelector = createSelector(
  userSelector,
  (user) => user?.memberOfBudgets
);

export const isMemberOfAnyBudgetSelector = createSelector(
  memberOfBudgetsSelector,
  (budgets) => budgets?.length
);

export const isPaymentApproverSelector = createSelector(
  userSelector,
  (user) => user?.paymentApprover
);
export const authDataSelector = createSelector(
  selectUserStore,
  (store) => store?.authData
);
export const activeSessionsSelector = createSelector(
  selectUserStore,
  (store) => store?.activeSessions
);
export const quickActionsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.quickActions
);
export const isFetchingQuickActionsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.isFetchingQuickActions
);
export const usersPendingActionsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.pendingActions
);
export const usersIsFetchingPendingActionsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.isFetchingPendingActions
);
export const isGoogleLoadingSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.isGoogleLoginLoading
);
export const signInValidatedSelector = createSelector(
  selectRoles,
  (user) => user.signInValidated
);
export const userDepartmentIdSelector = createSelector(
  userSelector,
  (user) => user?.departmentId
);

export const selectedUserInfoSelector = createSelector(
  selectUserStore,
  (user) => user.selectedUser
);

export const selectedUserSelector = createSelector(
  selectedUserInfoSelector,
  (selectedUser) => selectedUser?.info
);

export const isFetchingSelectedUserSelector = createSelector(
  selectedUserInfoSelector,
  (selectedUser) => selectedUser?.isFetchingSelectedUser
);

export const selectedUserCardListInfoSelector = createSelector(
  selectedUserInfoSelector,
  (selectedUser) => selectedUser?.cardsList
);

export const selectedUserCardsListSelector = createSelector(
  selectedUserCardListInfoSelector,
  (cardInfo) => cardInfo?.list
);

export const selectedUserVirtualCardsListSelector = createSelector(
  selectedUserCardsListSelector,
  (cards) => cards?.filter((card) => card.type === CARD_TYPE.VIRTUAL)
);

export const selectedUserPhysicalCardsListSelector = createSelector(
  selectedUserCardsListSelector,
  (cards) => cards?.filter((card) => card.type === CARD_TYPE.PHYSICAL)
);

export const isFetchingSelectedUserCardList = createSelector(
  selectedUserCardListInfoSelector,
  (cardInfo) => cardInfo?.isFetching
);

export const totalCountSelectedUserCardSelector = createSelector(
  selectedUserCardListInfoSelector,
  (cardInfo) => cardInfo?.total
);

export const selectedUserVirtualCardsTotalCountSelector = createSelector(
  selectedUserVirtualCardsListSelector,
  (vCards) => vCards?.length
);

export const selectedUserPhysicalCardsTotalCountSelector = createSelector(
  selectedUserPhysicalCardsListSelector,
  (pCards) => pCards?.length
);

export const hasMoreSelectedUserCardSelector = createSelector(
  selectedUserCardListInfoSelector,
  (cardInfo) => cardInfo?.hasMore
);

export const selectedUserUpdatedDetailsSelector = createSelector(
  selectUserStore,
  (user) => user.selectedUserEditFormDetails
);

export const isDepartmentChangeConfirmedSelector = createSelector(
  selectUserStore,
  (user) => user.selectedUserDepartmentChangeConfirmation
);

export const isLocationChangeConfirmedSelector = createSelector(
  selectUserStore,
  (user) => user.selectedUserLocationChangeConfirmation
);

export const isRoleChangeConfirmedSelector = createSelector(
  selectUserStore,
  (user) => user.selectedUserRoleChangeConfirmation
);

export const isUserAccessChangedSelector = createSelector(
  selectUserStore,
  (user) => user.isUserAccessChanged.changed
);

export const isUserAccessChangeInprogress = createSelector(
  selectUserStore,
  (user) => user.isUserAccessChanged.inProgress
);

export const isLoggedIntoMultipleDevicesSelector = createSelector(
  userSelector,
  (user) => user?.loggedIntoMultipleDevices
);

export const isMfaSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.isMfa
);

export const mfaMethodSelector = createSelector(
  userSelector,
  (user) => user?.mfaMode
);

export const accountLockedTypeSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.accountLockedType
);

export const inProgressPersonalDetailsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.inProgressPersonalDetails
);

export const inProgressAddressDetailsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.addressDetailsInprogress
);

export const inProgressIdentityDetailsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.identityDetailsInprogress
);

export const userCkycConsentStatusSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.ckycConsentStatus
);

export const userVkycConsentStatusSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.vkycConsentStatus
);

export const kycUpdateStatusSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.cKycUpdateStatus
);

export const userBlockRequirementsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.userBlockRequirements
);

export const userBlockDepartmentsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.userBlockDepartments
);

export const userBlockProjectsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.userBlockProjects
);

export const userBlockRequirementsCountSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.userBlockRequirementsCount
);

export const isFetchingUserBlockRequirementsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.isFetchingUserBlockRequirements
);

export const userDepartmentRequirementsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.userDepartmentRequirements
);

export const userDepartmentRequirementsCountSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.userDepartmentRequirementsCount
);

export const isFetchingUserDepartmentRequirementsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.isFetchingUserDepartmentRequirements
);

export const currentUserPhysicalCardSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.physicalCard
);
export const isFetchingCurrentUserPhysicalCardSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.isFetchingPhysicalCard
);

export const isFetchingLoggedInDevicesSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.isFetchingLoggedInDevices
);

export const isFetchingTrustedDevicesSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.isFetchingTrustedDevices
);

export const trustedDevicesSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.trustedDevices
);

export const loggedInDevicesSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.loggedInDevices
);

export const lastModifiedPasswordSelector = createSelector(
  selectUserStore,
  (user) => user.lastModifiedPassword
);

export const isTrustedDeviceOperationInProgressSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.isTrustedDeviceOperationInProgress
);

export const userNotificationsSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.notifications
);

export const userNotificationsListSelector = createSelector(
  userNotificationsSelector,
  (userNotifications) => userNotifications.list
);

export const userNotificationsPageSelector = createSelector(
  userNotificationsSelector,
  (userNotifications) => userNotifications.page
);

export const userNotificationsHasMoreSelector = createSelector(
  userNotificationsSelector,
  (userNotifications) => userNotifications.hasMore
);

export const userNotificationsTotalSelector = createSelector(
  userNotificationsSelector,
  (userNotifications) => userNotifications.total
);

export const userNotificationsUnreadCountSelector = createSelector(
  userNotificationsSelector,
  (userNotifications) => userNotifications.unreadCount
);

export const isFetchingUserNotificationsSelector = createSelector(
  userNotificationsSelector,
  (userNotifications) => userNotifications.isFetching
);

export const isLoadingUserNotificationsSelector = createSelector(
  userNotificationsSelector,
  (userNotifications) => userNotifications.isLoading
);

export const userNotificationsUnreadIdsSelector = createSelector(
  userNotificationsSelector,
  (userNotifications) => userNotifications.unreadIds
);

export const selectedUserAssignedCopilotsSelector = createSelector(
  selectedUserInfoSelector,
  (selectedUser) => selectedUser?.info?.assignedAsCopilotFor
);

export const connectedAccountsSelector = createSelector(
  selectUserStore,
  (app) => app.connectedAccounts
);

export const isFetchingConnectedAccountsSelector = createSelector(
  selectUserStore,
  (app) => app.isFetchingConnectedAccounts
);

export const navigationConfigSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.navigationConfig
);

export const userAllowedActionsConfigSelector = createSelector(
  selectUserStore,
  (userStore) => userStore.actionsConfig
);

export const unreadNotificationTabsCountSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.unReadNotificationsTabsCount
);

export const unreadNotificationTabsCurrentTabSelector = createSelector(
  selectUserStore,
  (userStore) => userStore?.userNotificationCurrentTab
);

export const bannerRetryCtaLink = createSelector(
  selectUserStore,
  (userStore) => userStore?.bannerRetryCtaLink
);
export const bannerRetryCtaRedirectionLinkSelector = createSelector(
  bannerRetryCtaLink,
  (bannerRetry) => bannerRetry?.vciplink
);

export const isLoadingConnectEntitySelector = createSelector(
  selectUserStore,
  (app) => app.isLoadingConnectEntity
);

export const userKycStatusInfoSelector = createSelector(
  selectUserStore,
  (app) => app.userKycStatusInfo?.data
);

export const isFetchingUserKycInfoSelector = createSelector(
  selectUserStore,
  (app) => app.userKycStatusInfo?.isFetching
);
export const masterDataSelector = createSelector(
  selectUserStore,
  (app) => app?.masterData
);
// TECH_DEBT, name `availableModulesSelector` is present in client selector too.
export const availableModulesSelector = createSelector(
  masterDataSelector,
  (masterData) => masterData?.availableModules
);
export const masterDataDashboardSelector = createSelector(
  masterDataSelector,
  (masterData) => masterData?.dashboard
);
export const masterDataAnalyticsSelector = createSelector(
  masterDataDashboardSelector,
  (dashboardMasterData) => dashboardMasterData?.analytics
);
export const dashboardAnalyticsSpendTypeFilterSelector = createSelector(
  masterDataAnalyticsSelector,
  (analyticsMasterData) => analyticsMasterData?.filters
);
export const dashboardAnalyticsTabsFilterSelector = createSelector(
  masterDataAnalyticsSelector,
  (analyticsMasterData) => analyticsMasterData?.tabs
);

export const masterDataQuickActionsSelector = createSelector(
  masterDataDashboardSelector,
  (dashboardMasterData) => dashboardMasterData?.quickActions
);
